import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMI0508 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    mibMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0508Params {
    stamnetab: string;
    tyrcleint: number;
}

/* @ngInject */
export default function Mi0508Controller($stateParams: IMr0508Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMI0508 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        //Référence
        vm.mibMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'mijcod', largeur: 115},
                {nom: 'mijdsc', largeur: 328},
                {nom: 'mibdat', largeur: 100}
            ],
            formulaire: new Formulaire('LBL_BLC_MIH', [
                new FormulaireItem('mijcleint'),
                new FormulaireItem('vaesysmnetab', { default: $stateParams.stamnetab }),
                new FormulaireItem('mibdat', { largeur: 25 })
            ])
        };
    }
}
