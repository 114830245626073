import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IInfosMonetaires } from "../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller";
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageRr2017 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireChp: IFormulaire;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    dcfMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr2017Controller(Formulaire: IFormulaireClass,
    rr0100Route: IRoute,
    $state: IStateService,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    Menu: IMenuClass,
    Operateur: IOperateurService) {
    const vm: IPageRr2017 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemActionImportation(
                'LBL_CHARGEMENT_TXT_CSV',
                'BOUCHACSV',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_TXT_CSV',
                        largeurFormulaire: 15,
                        formulaire: new Formulaire([
                            new FormulaireItem('chpnumcha', {
                                largeur: 100,
                                default: (data: any) => data.monoOccurrence.chpnumcha
                            })
                        ])
                    },
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    typesFichiers: ['.csv'],
                    iconButton: false
                }
                ),
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                }
                ),
            new MenuItemAction(
                'LBL_BTN_GEN',
                'BOUTRT',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                }
                ),
            new MenuItemAction(
                'LBL_BTN_SUP',
                'BOUSUP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    successAction: (data: any) => {
                        $state.go(rr0100Route.NAME, {
                        });
                    }
                }
            )
        ]
    };


    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireChp = new Formulaire(' ', [
            new FormulaireItem('chpnumcha'),
            new FormulaireItem('usrnomcre', { readonly: true }),
            new FormulaireItemDivider(''),
            new FormulaireItem('chpficsou'),
            new FormulaireItem('chpnbrlig')
        ]);

        vm.dcfMultiOccurrenceOptions = {
            colonnesFixesGauche: [
                { nom: 'eplnummat', largeur: 120 }
            ],
            colonnesVisibles: [
                { nom: 'ppacodtra', largeur: 95 },
                { nom: 'ppacod', largeur: 95 },
                { nom: 'dcfdatdeb', largeur: 100 },
                { nom: 'dcfdatfin', largeur: 100 },
                { nom: 'tagcod', largeur: 89 },
                { nom: 'dcfqte', largeur: 115 },
                { nom: 'dcftau', largeur: 115 },
                { nom: 'dcfmnt', largeur: 115 },
                { nom: 'tpfcodimp', largeur: 150 },
                { nom: 'tpfcodseqcar', largeur: 120 },
                { nom: 'aftcod', largeur: 126 },
                { nom: 'posnumdoc', largeur: 90 },
                { nom: 'lemcod', largeur: 126 },
                { nom: 'urgcod', largeur: 90 },
                { nom: 'strcod', largeur: 90 },
                { nom: 'temcod', largeur: 90 },
                { nom: 'unocod', largeur: 90 },
                { nom: 'dcfimp', largeur: 400 },
                { nom: 'esvcod1', largeur: 131 },
                { nom: 'esvcod2', largeur: 131 },
                { nom: 'esvcod3', largeur: 131 },
                { nom: 'esvcod4', largeur: 131 },
            ],
            colonnesFixesDroite: [
                { nom: 'dcfflgerr', largeur: 75 },
                { nom: 'dcfnbrerr', largeur: 90 }
            ],
            colonnesCachees: ['dcfmsgerr']
        };


    }
}
