import { IAugmentedJQuery, IComponentController, IPromise, IScope } from 'angular';
import { IMonoOccurrence } from '../../../../core/services/mono-occurrence.service';
import { IMenu, IMenuClass } from '../../../../core/services/menu/menu.service';
import { IMenuItemClass } from '../../../../core/services/menu/menu-item.service';
import { ICompteursBadge } from '../../../../core/resources/ecran-details.resource';
import { IFilterLibelle } from '../../../../core/filters/ex-libelle.filter';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IFonctionTransversaleFormulaire } from '../../services/fonction-transversale-formulaire.service';

export interface IComposantPlaquettesFonctionsTransversales extends
    IFonctionTransversaleInfosComplementaires,
    IFonctionTransversaleInfosComplementairesFinance,
    IFonctionTransversalePiecesJointes,
    IFonctionTransversaleNotes,
    IFonctionTransversaleNotesFinance,
    IComponentController {

    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    menuBandeau: IMenu;
    compteurs: ICompteursBadge;
    ICONS: IFonctionsTransversalesIcons;
    displayErrorIfAny: boolean;
    fonctionTransversaleFormulaire: IFonctionTransversaleFormulaire;
    fetchingTitreBlocFonctionTransversale(): IPromise<void>;
}

interface IFonctionsTransversalesIcons {
    NOTES: string;
    PIECES_JOINTES: string;
    INFOS_COMPLEMENTAIRES: string;
    FORMULAIRE: string;
}

interface IFonctionTransversaleNotes {
    lblTitreNotes: string;
    displayingNotes: boolean;
    closeNotes(): void;
}

interface IFonctionTransversaleNotesFinance {
    lblTitreNotes: string;
    displayingNotesFinance: boolean;
    closeNotesFinance(): void;
}

interface IFonctionTransversalePiecesJointes {
    lblTitrePiecesJointes: string;
    displayingPiecesJointes: boolean;
    closePiecesJointes(): void;
}

interface IFonctionTransversaleInfosComplementaires {
    lblTitreInfosComplementaires: string;
    displayingInfosComplementaires: boolean;
    closeInfosComplementaires(): void;
}

interface IFonctionTransversaleInfosComplementairesFinance {
    lblTitreInfosComplementaires: string;
    displayingInfosComplementairesFinance: boolean;
    closeInfosComplementairesFinance(): void;
}

/* @ngInject */
export default function PlaquettesFonctionsTransversalesController(MenuItem: IMenuItemClass,
    $scope: IScope,
    $element: IAugmentedJQuery,
    exLibelleFilter: IFilterLibelle,
    Menu: IMenuClass,
    FonctionTransversaleFormulaire: IFonctionTransversaleFormulaire) {
    const vm: IComposantPlaquettesFonctionsTransversales = this;

    vm.$onInit = $onInit;
    vm.closeNotes = closeNotes;
    vm.closeNotesFinance = closeNotesFinance;
    vm.closePiecesJointes = closePiecesJointes;
    vm.closeInfosComplementaires = closeInfosComplementaires;
    vm.closeInfosComplementairesFinance = closeInfosComplementairesFinance;
    vm.fetchingTitreBlocFonctionTransversale = fetchingTitreBlocFonctionTransversale;

    function $onInit() {
        vm.compteurs = vm.ecranContextCtrl.ecranDetails.compteurs;
        vm.displayingNotes = false;
        vm.displayingNotesFinance = false;
        vm.displayingPiecesJointes = false;
        vm.displayingInfosComplementaires = false;
        vm.displayingInfosComplementairesFinance = false;
        vm.displayErrorIfAny = false;
        vm.ICONS = {
            NOTES: 'note',
            PIECES_JOINTES: 'attach_file',
            INFOS_COMPLEMENTAIRES: 'settings_ethernet',
            FORMULAIRE: 'receipt'
        };

        vm.menuBandeau = vm.menuBandeau || new Menu([]);

        const menuItemFormulaire = new MenuItem('G_LBL_FONC_TRANS_FORMULAIRE', toggleFonctionTransversaleFormulaire, {
            icon: vm.ICONS.FORMULAIRE,
            directionTooltip: 'down',
            fonction: 'BOUFORM',
            hidden: () => !FonctionTransversaleFormulaire.dataList[vm.ecranContextCtrl.ecranDetails.ecrcleint] || !FonctionTransversaleFormulaire.dataList[vm.ecranContextCtrl.ecranDetails.ecrcleint].length
        });

        vm.menuBandeau.add(menuItemFormulaire);

        FonctionTransversaleFormulaire.initListeTraitementsPossible(vm.monoOccurrence, vm.ecranContextCtrl.ecranDetails.ecrcleint);

        if (hasFonctionAcces(`${vm.monoOccurrence.mnemonique}.BOUPIJ`) || hasFonctionAcces(`${vm.monoOccurrence.mnemonique}.BOUPIJ_FIN`)) {
            const piecesJointesMenuItem = new MenuItem('G_LBL_FONC_TRANS_DOCUMENT', togglePiecesJointes, {
                icon: vm.ICONS.PIECES_JOINTES,
                directionTooltip: 'down',
                compteur: vm.compteurs && vm.compteurs.piecesJointes,
                class: () => vm.displayingPiecesJointes ? 'ex-plaquettes-fonctions-transversales-active' : ''
            });

            vm.menuBandeau.add(piecesJointesMenuItem);

            $scope.$watch('vm.compteurs.piecesJointes', (newValue: number, oldValue: number) => {
                if (newValue !== oldValue) {
                    piecesJointesMenuItem.compteur = newValue;
                }
            });
        }

        if (hasFonctionAcces(`${vm.monoOccurrence.mnemonique}.BOUNOT`)) {
            const notesMenuItem = new MenuItem('G_LBL_FONC_TRANS_NOTE', toggleNotes, {
                icon: vm.ICONS.NOTES,
                directionTooltip: 'down',
                compteur: vm.compteurs && vm.compteurs.notes,
                class: () => vm.displayingNotes ? 'ex-plaquettes-fonctions-transversales-active' : ''
            });

            vm.menuBandeau.add(notesMenuItem);

            $scope.$watch('vm.compteurs.notes', (newValue: number, oldValue: number) => {
                if (newValue !== oldValue) {
                    notesMenuItem.compteur = newValue;
                }
            });
        }

        if (hasFonctionAcces(`${vm.monoOccurrence.mnemonique}.BOUNOT_FIN`)) {
            const notesFinanceMenuItem = new MenuItem('G_LBL_FONC_TRANS_NOTE', toggleNotesFinance, {
                icon: vm.ICONS.NOTES,
                directionTooltip: 'down',
                compteur: vm.compteurs && vm.compteurs.notesFinance,
                class: () => vm.displayingNotesFinance ? 'ex-plaquettes-fonctions-transversales-active' : ''
            });

            vm.menuBandeau.add(notesFinanceMenuItem);

            $scope.$watch('vm.compteurs.notesFinance', (newValue: number, oldValue: number) => {
                if (newValue !== oldValue) {
                    notesFinanceMenuItem.compteur = newValue;
                }
            });
        }

        if (hasFonctionAcces(`${vm.monoOccurrence.mnemonique}.BOUINF`)) {
            const infosComplementairesMenuItem = new MenuItem('G_LBL_FONC_TRANS_INFORMATION_COMP', toggleInfosComplementaires, {
                icon: vm.ICONS.INFOS_COMPLEMENTAIRES,
                directionTooltip: 'down',
                compteur: vm.compteurs && vm.compteurs.infosComplementaires,
                class: () => vm.displayingInfosComplementaires ? 'ex-plaquettes-fonctions-transversales-active' : ''
            });

            vm.menuBandeau.add(infosComplementairesMenuItem);

            $scope.$watch('vm.compteurs.infosComplementaires', (newValue: number, oldValue: number) => {
                if (newValue !== oldValue) {
                    infosComplementairesMenuItem.compteur = newValue;
                }
            });
        }

        if (hasFonctionAcces(`${vm.monoOccurrence.mnemonique}.BOUINF_FIN`)) {
            const infosComplementairesFinanceMenuItem = new MenuItem('G_LBL_FONC_TRANS_INFORMATION_COMP', toggleInfosComplementairesFinance, {
                icon: vm.ICONS.INFOS_COMPLEMENTAIRES,
                directionTooltip: 'down',
                compteur: vm.compteurs && vm.compteurs.infosComplementairesFinance,
                class: () => vm.displayingInfosComplementairesFinance ? 'ex-plaquettes-fonctions-transversales-active' : ''
            });

            vm.menuBandeau.add(infosComplementairesFinanceMenuItem);

            $scope.$watch('vm.compteurs.infosComplementairesFinance', (newValue: number, oldValue: number) => {
                if (newValue !== oldValue) {
                    infosComplementairesFinanceMenuItem.compteur = newValue;
                }
            });
        }
    }

    function hasFonctionAcces(nomphy: string) {
        return vm.ecranContextCtrl.ecranDetails.fonctions[nomphy] && vm.ecranContextCtrl.ecranDetails.fonctions[nomphy].flgacc;
    }

    function toggleNotes() {
        fetchingTitreBlocFonctionTransversale().then(() => {
            if (!vm.monoOccurrence.fonctionsTransversalesTitreErreur) {
                if (!vm.displayingNotes) {
                    const lblTitre = exLibelleFilter('G_LBL_NOTES_TITRE');
                    vm.displayingNotes = true;
                    vm.lblTitreNotes = lblTitre + ' ' + vm.monoOccurrence.titreBlocFonctionTransversale;
                }
            }
        });
    }

    function closeNotes() {
        vm.displayingNotes = false;
        allerHautDePage();
    }

    function toggleNotesFinance() {
        fetchingTitreBlocFonctionTransversale().then(() => {
            if (!vm.monoOccurrence.fonctionsTransversalesTitreErreur) {
                if (!vm.displayingNotesFinance) {
                    const lblTitre = exLibelleFilter('G_LBL_NOTES_TITRE');
                    vm.displayingNotesFinance = true;
                    vm.lblTitreNotes = lblTitre + ' ' + vm.monoOccurrence.titreBlocFonctionTransversale;
                }
            }
        });
    }

    function closeNotesFinance() {
        vm.displayingNotesFinance = false;
        allerHautDePage();
    }

    function togglePiecesJointes() {
        fetchingTitreBlocFonctionTransversale().then(() => {
            if (!vm.monoOccurrence.fonctionsTransversalesTitreErreur) {
                if (!vm.displayingPiecesJointes) {
                    const lblTitre = exLibelleFilter('G_LBL_PIECES_JOINTES_POUR');
                    vm.displayingPiecesJointes = true;
                    vm.lblTitrePiecesJointes = lblTitre + ' ' + vm.monoOccurrence.titreBlocFonctionTransversale;
                }
            }
        });
    }

    function closePiecesJointes() {
        vm.displayingPiecesJointes = false;
        allerHautDePage();
    }

    function toggleInfosComplementaires() {
        fetchingTitreBlocFonctionTransversale().then(() => {
            if (!vm.monoOccurrence.fonctionsTransversalesTitreErreur) {
                if (!vm.displayingInfosComplementaires) {
                    const lblTitre = exLibelleFilter('G_LBL_INFORMATIONS_COMPLEMENTAIRES');
                    vm.displayingInfosComplementaires = true;
                    vm.lblTitreInfosComplementaires = lblTitre + ' ' + vm.monoOccurrence.titreBlocFonctionTransversale;
                }
            }
        });
    }

    function closeInfosComplementaires() {
        vm.displayingInfosComplementaires = false;
        allerHautDePage();
    }

    function toggleInfosComplementairesFinance() {
        fetchingTitreBlocFonctionTransversale().then(() => {
            if (!vm.monoOccurrence.fonctionsTransversalesTitreErreur) {
                if (!vm.displayingInfosComplementairesFinance) {
                    const lblTitre = exLibelleFilter('G_LBL_INFORMATIONS_COMPLEMENTAIRES');
                    vm.displayingInfosComplementairesFinance = true;
                    vm.lblTitreInfosComplementaires = lblTitre + ' ' + vm.monoOccurrence.titreBlocFonctionTransversale;
                }
            }
        });
    }

    function closeInfosComplementairesFinance() {
        vm.displayingInfosComplementairesFinance = false;
        allerHautDePage();
    }

    function toggleFonctionTransversaleFormulaire() {
        FonctionTransversaleFormulaire.selectionnerTraitement(vm.ecranContextCtrl.ecranDetails.ecrcleint, vm.monoOccurrence.srccod);
    }

    async function fetchingTitreBlocFonctionTransversale() {
        vm.displayErrorIfAny = true;

        if (!vm.monoOccurrence.fonctionsTransversalesTitreErreur && !vm.monoOccurrence.titreBlocFonctionTransversale) {
            return vm.monoOccurrence.promiseTitreFonctionTransversale;
        }
    }

    function allerHautDePage() {
        $element.closest('[md-scroll-y]').animate({ scrollTop: 0 }, 200);
    }
}
