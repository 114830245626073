import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageRr0119 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0119Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0119 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'strcod', largeur: 90},
            {nom: 'aftcod', largeur: 105},
            {nom: 'tagcod', largeur: 90},
            {nom: 'tagdsc', largeur: 210},
            {nom: 'afpdatdeb', largeur: 100},
            {nom: 'afpdatfin', largeur: 100},
            {nom: 'afpmnt', largeur: 126},
            {nom: 'afpcom', largeur: 262},
            {nom: 'afpsui', largeur: 262},
            {nom: 'urgdsc', largeur: 236},
            {nom: 'strdsc', largeur: 236},
            {nom: 'aftdsc', largeur: 420},
            {nom: 'aftdatdeb', largeur: 100},
            {nom: 'aftdatfin', largeur: 100},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 290},
            {nom: 'tafcod', largeur: 90},
            {nom: 'tafdsc', largeur: 210}
        ],
        colonnesFixesDroite: [
            {nom: 'afpflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('aftcleint'),
            new FormulaireItem('tagcleint', {
                resourceParams: (data: any) => {
                    return {
                        aftdatdeb: data.aftdatdeb,
                        strcleint: data.strcleint,
                        urgcleint: data.urgcleint
                    };
                }
            }),
            new FormulaireItem('afpdatdeb'),
            new FormulaireItem('afpdatfin'),
            new FormulaireItem('afpmnt'),
            new FormulaireItem('afpcom'),
            new FormulaireItem('afpsui'),
            new FormulaireItem('temcleint'),
            new FormulaireItem('unocleint'),
            new FormulaireItem('tafcleint'),
            new FormulaireItem('afpflgact', {default: 1})
        ]),
    };
}
