import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0208',
    ecrans: ['00-2'],
    params: [
        'id',
        '?doccleintgrh',
        '?typtri'
    ]
});
