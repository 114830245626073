import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPagePF0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0026Controller(pf0002Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Operateur: IOperateurService) {
    const vm: IPagePF0026 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pf0002Route.NAME,
        navigateParams: (rowData: any) => getNavigateParams(rowData),
        actionsNouveaux: new Menu([
            new MenuItemForage('LBL_BTN_ACC', pf0002Route.NAME, () =>
                { return {
                    ecran : '00-2',
                    tytcleint: 58 }},
                { icon: '',fonction:'BOUACT'},
                ),
            new MenuItemForage('LBL_BTN_REM', pf0002Route.NAME, () =>
                { return {
                    ecran : '01-2',
                    tytcleint: 59}},
                { icon: '',fonction:'BOUREM' })
        ]),
        filtres: [
            {
                colonne: 'tytcleint',
                operateur: Operateur.EGALE,
                valeur: ''
            },
        ],
        colonnesVisibles: [
            {nom: 'tpccod'      , largeur: 120},
            {nom: 'tpcdsc'      , largeur: 254},
            {nom: 'ttccod'      , largeur: 120},
            {nom: 'ttcdsc'      , largeur: 250},
            {nom: 'ttccodins'   , largeur: 120},
            {nom: 'ttcdscins'   , largeur: 250},
            {nom: 'piscod'      , largeur: 105},
            {nom: 'pisdsc'      , largeur: 262},
            {nom: 'cabcod'      , largeur: 105},
            {nom: 'cabdsc'      , largeur: 262},
            {nom: 'tpcflgmaxemp', largeur: 100},
            {nom: 'rolcod'      , largeur: 120},
            {nom: 'roldscabr'   , largeur: 262}
        ],
        colonnesCachees: [
            'tytcleint'
        ],
        fonctions: {
            rechercheCollapsable: false
        }
    };

    // Fonction pour définir les paramètres de forage, en fonction de la transaction à accéder
    // - le paramètre ecran est défini en gardant uniquement les 4 derniers caractères de la sting ecrcod
    function getNavigateParams(data: any) {
        if ([58].includes(Number(data.tytcleint))) {
            return {
                ecran : '00-2',
                tytcleint: 58
            };
        }
        else if ([59].includes(Number(data.tytcleint))) {
            return {
                ecran : '01-2',
                tytcleint: 59
            };
        }
        else {
            return {
                ecran : '00-2',
                tytcleint: 58
            }
        }
    }
}
