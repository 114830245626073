import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRr0017 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTtl: IFormulaire;
    valeursEcran: any;
}

interface IRr0017Params {
    id: string;
    eplcleint: string;
    trncleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0017Controller($stateParams: IRr0017Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0017 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_TELEPHONE', [
                new FormulaireItem('eplcleint', { default: $stateParams.eplcleint, required: true, readonly: (data) => data.etlcleint || $stateParams.eplcleint }),
                new FormulaireItem('etlflgact', { default: 1, largeur: 25 }),
                new FormulaireItem('ttlcleint', { required: true }),
                new FormulaireItem('etldatvig', { largeur: 25, required: true }),
                new FormulaireItem('etlnumtel'),
                new FormulaireItem('etlnumpos', { largeur: 25 }),
                new FormulaireItem('etladrcou', { largeur: 100 }),
                new FormulaireItem('etldsc', { largeur: 100 })
            ], { largeurDefaut: 75 })
        }
    }
}
