import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGA0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ga0002Controller(ga2000Route: IRoute,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGA0002 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            importation: true
        },        
        navigatePage: ga2000Route.NAME,
        navigateParams: (data:any) => ({id : data.eaucleint}),
        actionsNouveaux: new Menu([
            new MenuItemForage(ga2000Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'gaacod', largeur: 130},
            {nom: 'gaadsc', largeur: 263},
            {nom: 'prucod', largeur: 90},
            {nom: 'prudscl01', largeur: 263},
            {nom: 'vaesysnatctb', largeur: 130},
            {nom: 'vaesysnatctb_dsc', largeur: 200},
            {nom: 'eauflgact', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('elgcleintref'),
            new FormulaireItem('prucleint'),
            new FormulaireItem('vaesysnatctb'),
            new FormulaireItem('eauflgact'),
            new FormulaireItem('vaesyselg')
        ]
    )
    };
}
