import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageCO0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0008Controller(co0006Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCO0008 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: co0006Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(co0006Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'comcod', largeur: 89}
        ],
        colonnesVisibles: [
            {nom: 'comdsc', largeur: 262},
            {nom: 'tyccod', largeur: 110},
            {nom: 'tycdsc', largeur: 260},
            {nom: 'dmncod', largeur: 89},
            {nom: 'dmndsc', largeur: 253},
            {nom: 'comcod_par', largeur: 112},
            {nom: 'comdsc_par', largeur: 280},
            {nom: 'comflgaffweb', largeur: 110, editable: true}
        ],
    };
}
