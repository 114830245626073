import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRr0121 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0121Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRr0121 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'tsadatvig', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'lemcod', largeur: 90},
            {nom: 'geccod', largeur: 90},
            {nom: 'echcod', largeur: 90},
            {nom: 'tsanumdoc', largeur: 131},
            {nom: 'tsdseq', largeur: 65},
            {nom: 'tagcod', largeur: 90},
            {nom: 'tagdscabr', largeur: 262},
            {nom: 'tsdsal', largeur: 125},
            {nom: 'tsdpou', largeur: 115}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('tsadatvig'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('lemcleint', {
                resourceParams: (data: any) => {
                    return {
                        demcleint: data.demcleint,
                        urgcleint: data.urgcleint,
                        strcleint: data.strcleint,
                        tsadatvig: data.tsadatvig
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('geccleint', {
                resourceParams: (data: any) => {
                    return {
                        urgcleint: data.urgcleint
                    };
                }
            }),
            new FormulaireItem('echcleint', {
                resourceParams: (data: any) => {
                    return {
                        geccleint: data.geccleint,
                        tsadatvig: data.tsadatvig
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tafcleint', {
                resourceParams: (data: any) => {
                    return {
                        strcleint: data.strcleint,
                        urgcleint: data.urgcleint
                    };
                }
            }),
            new FormulaireItem('tsacleint', {
                resourceParams: (data: any) => {
                    return {
                        lemcleint: data.lemcleint,
                        geccleint: data.geccleint,
                        echcleint: data.echcleint,
                        tafcleint: data.tafcleint
                    };
                }
            }),
            new FormulaireItem('tsdseq'),
            new FormulaireItem('tagcleint', {
                resourceParams: (data: any) => {
                    return {
                        tsadatvig: data.tsadatvig,
                        urgcleint: data.urgcleint,
                        strcleint: data.strcleint
                    };
                }
            }),
            new FormulaireItem('tsdsal'),
            new FormulaireItem('tsdpou')
        ], {largeurDefaut: 33.33})
    };
}
