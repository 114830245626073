import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';

interface IPageCS0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cs0004Controller(cs0003Route: IRoute,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService,
                                         $stateParams: IStateParamsService) {
    const vm: IPageCS0004 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cs0003Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cs0003Route.NAME)
        ]),
        forcerEtatPredefinisDepart: ($stateParams.employe && 'VIC'),
        etatsPredefinis: [
            {
                nom: 'ACC',
                filtres: [
                    {
                        colonne: 'origine',
                        operateur: Operateur.EGALE,
                        valeur: 'ACC'
                    }
                ],
                colonnesVisibles: [
                    'accnumdoc',
                    'acclieacc',
                    'accdat',
                    'ttccod',
                    'ttcdsc',
                    'ecldsceta',
                    'accdsc',
                    'acccom',
                    'accmescor',
                    'accvisimm',
                    'accrainonvisimm',
                    'accdatenq',
                    'vaccodgra',
                    'vacdscgra',
                    'vaccodpotgra',
                    'vacdscpotgra',
                    'trnflgstaref',
                    'trnflgstafin',
                    'trnflgstafer',
                    'trnflgstaann'
                ]

            }, {
                nom: 'VIC',
                filtres: [
                    {
                        colonne: 'origine',
                        operateur: Operateur.EGALE,
                        valeur: 'VIC'
                    }
                ],
                colonnesVisibles: [
                    'accnumdoc',
                    'acclieacc',
                    'accdat',
                    'eplnummat',
                    'eplnomprn',
                    'vaccodtypacc',
                    'vacdsctypacc',
                    'vaccodfretac',
                    'vacdscfretac',
                    'vaccodtacacc',
                    'vacdsctacacc',
                    'vicdscact',
                    'vicpresoi',
                    'viccom',
                    'vaccodgencon',
                    'vacdscgencon',
                    'vicagtsrccon',
                    'lemcod',
                    'lemdsc',
                    'urgcod',
                    'urgdscabr',
                    'strcod',
                    'strdsc',
                    'aftcod',
                    'aftdsc',
                    'temcod',
                    'temdscabr',
                    'grecod',
                    'gredsc',
                    'dalnumdoc',
                    'daldsc',
                    'unocod',
                    'unodscabr',
                    'litcod',
                    'litdsc',
                    'ttccod',
                    'ttcdsc',
                    'ecldsceta',
                    'accdsc',
                    'acccom',
                    'accmescor',
                    'accvisimm',
                    'accrainonvisimm',
                    'accdatenq',
                    'vaccodgra',
                    'vacdscgra',
                    'vaccodpotgra',
                    'vacdscpotgra',
                    'trnflgstaref',
                    'trnflgstafin',
                    'trnflgstafer',
                    'trnflgstaann'
                ]
            }
        ],
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'accnumdoc', largeur: 90},
            {nom: 'accdat', largeur: 150},
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe},
            {nom: 'dalnumdoc', largeur: 90},
            {nom: 'daldsc', largeur: 90},
            {nom: 'accvisimm', largeur: 90},
            {nom: 'accmescor', largeur: 90},
            {nom: 'accrainonvisimm', largeur: 90},
            {nom: 'vaccodgencon', largeur: 90},
            {nom: 'vacdscgencon', largeur: 90},
            {nom: 'vicagtsrccon', largeur: 90},            
            {nom: 'accdsc', largeur: 262},
            {nom: 'vaccodtypacc', largeur: 95},
            {nom: 'vacdsctypacc', largeur: 262},
            {nom: 'vaccodfretac', largeur: 100},
            {nom: 'vacdscfretac', largeur: 262},
            {nom: 'vaccodtacacc', largeur: 90},
            {nom: 'vacdsctacacc', largeur: 262},
            {nom: 'vicdscact', largeur: 262},
            {nom: 'vicpresoi', largeur: 88},
            {nom: 'viccom', largeur: 262},
            {nom: 'lemcod', largeur: 105},
            {nom: 'lemdsc', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 85},
            {nom: 'strdsc', largeur: 262},
            {nom: 'aftcod', largeur: 105},
            {nom: 'aftdsc', largeur: 262},
            {nom: 'temcod', largeur: 105},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'grecod', largeur: 105},
            {nom: 'gredsc', largeur: 262},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'litcod', largeur: 90},
            {nom: 'litdsc', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'acccom', largeur: 262},
            {nom: 'accdatenq', largeur: 150},
            {nom: 'vaccodgra', largeur: 100},
            {nom: 'vacdscgra', largeur: 262},
            {nom: 'vaccodpotgra', largeur: 100},
            {nom: 'vacdscpotgra', largeur: 262},
            {nom: 'acclieacc', largeur: 184},
            {nom: 'ttccod', largeur: 110},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
