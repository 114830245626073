import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0188 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rarMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0188Params {
    id: number;
}

/* @ngInject */
export default function Mr0188Controller(
    $stateParams: IMr0188Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass
) {
    const vm: IPageMr0188 = this;

        vm.valeursEcran = ecranDetails.valeurs;
        vm.rarMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                rechercheCollapsable: false
            },
            colonnesVisibles: [
                { nom: 'rardatvig', largeur: 100 },
                { nom: 'rsccod', largeur: 115 },
                { nom: 'rscdsc', largeur: 300 },
                { nom: 'rsccodrem', largeur: 115 },
                { nom: 'rscdscrem', largeur: 300 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rardatvig', { largeur: 10 }),
                new FormulaireItem('rsccleint', { largeur: 39 }),
                new FormulaireItem('rsccleintrem', { largeur: 39 }),
                new FormulaireItem('rcecleint', { hidden: true, default: $stateParams.id })
            ])
        };
}
