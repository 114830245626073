import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageCr0046Params {
    scbcleint: string
}

interface IPageCr0046 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0046Controller(Formulaire: IFormulaireClass,
    $stateParams: IPageCr0046Params,
    Operateur: IOperateurService,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0046 = this;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'scbcleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.scbcleint,
                readOnly: true
            }
        ],
        colonnesVisibles: [
            { nom: 'rrccod', largeur: 115 },
            { nom: 'rrcdsc', largeur: 195 },
            { nom: 'frbdat', largeur: 110 },
            { nom: 'frbmnt', largeur: 115 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rrccleint'),
            new FormulaireItem('frbdat'),
            new FormulaireItem('frbmnt', { largeur: 20 }),
            new FormulaireItem('scbcleint', { largeur: 20, hidden: true, default: $stateParams.scbcleint }),
        ], { largeurDefaut: 40 }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
