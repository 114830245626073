import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0258 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0258Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0258 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            selectionCycle: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('flgnonter', {largeur: 33.33, default: 1}),
            new FormulaireItem('vaetytreg', {largeur: 33.33}),
            new FormulaireItem('nbrjrs',  {largeur: 33.33}),
            new FormulaireItem('flgnonfer', {largeur: 33.33, default: 1}),
            new FormulaireItem('urgcleint', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgtrnusr', {largeur: 33.33, default: 1}),
            new FormulaireItem('strcleint', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgsnsrol', {largeur: 33.33, default: 1}),
            new FormulaireItem('grocleint', {largeur: 33.33})
        ], {largeurDefaut: 25}
        ),
        // Colonnes fixe à gauche
        colonnesFixesGauche: [
            {nom: 'trnnumdoc', largeur: 110}
        ],
        colonnesVisibles: [
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 228},
            {nom: 'nom_usr_init', largeur: 223},
            {nom: 'etat', largeur: 180},
            {nom: 'datcre', largeur: 100},
            {nom: 'duree', largeur: 95},
            {nom: 'nom_usr_etape', largeur: 223},
            {nom: 'etat_pro', largeur: 180},
            {nom: 'approb', largeur: 260},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodsc', largeur: 260},
            {nom: 'urgcod', largeur: 94},
            {nom: 'urgdscabr', largeur: 260},
            {nom: 'strcod', largeur: 115},
            {nom: 'strdsc', largeur: 260},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'inderr', largeur: 100}
        ],
        colonnesCachees: [
            'errmsg'
        ]
    };
}
