import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemForage, IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
//import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageAl0002 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dlpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dldMultiOccurrenceOptions: IMultiOccurrenceOptions;
    qsrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dlrmultiOccurrenceOptions: IMultiOccurrenceOptions;
    avimultiOccurrenceOptions: IMultiOccurrenceOptions;
    dfcmultiOccurrenceOptions: IMultiOccurrenceOptions;
    dsemultiOccurrenceOptions: IMultiOccurrenceOptions;
    resmultiOccurrenceOptions: IMultiOccurrenceOptions;
    cpeMenus: Array<IMenuItemForage>;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Al0002Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    cs0001Route: IRoute,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    al0004Route: IRoute,
    al0006Route: IRoute,
    al0003Route: IRoute,
    mr0008Route: IRoute
) {
    const vm: IPageAl0002 = this;
    //Zone message
    vm.formulaireMsg = new Formulaire('zone_msg', [
        new FormulaireItem('zone_msg', {
            largeur: 100,
            enableCount: true,
            disabled: true
        })
    ]);

    vm.cpeMenus = [        
        new MenuItemForage(
            'LBL_DEM',
            cs0001Route.NAME,
            (data: any) => ({
                demcleint: vm.monoOccurrence.data.demcleint,
                //acccleint: data.acccleint,
                efface_criteres: true
            }),
            {
                icon: 'local_hospital',
                iconButton: false,
                compteur: (data: any) => (vm.monoOccurrence.data.nbridagmed > 0 ) ? vm.monoOccurrence.data.nbrdiagmed : undefined,
                secondaire: true
            }
        )
    ];

    vm.formulaireAld = new Formulaire([
        new FormulaireItem('dalnumdoc', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItem('dalseqmodcou', {
            largeur: 33,
            required: false
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('tdlcleint', {
            largeur: 33,
            required: true,
            modifAllow: false
        }),
        new FormulaireItem('ttccleint', {
            largeur: 33,
            required: false,
            modifAllow: false
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('demcleint', {
            largeur: 33,
            required: true,
            modifAllow: false
        }),
        new FormulaireItem('lemcleint', {
            largeur: 33,
            required: true,
            modifAllow: false,
            resourceParams: (data: any) => ({ demcleint: data.demcleint })
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireGroupe('Dates', new Formulaire([
            new FormulaireItem('daldatouv', {
                largeur: 33,
                required: false
            }),
            new FormulaireItem('daldatretprg', {
                largeur: 33,
                required: false,
                disabled: true
            }),
            new FormulaireItem('dalprcdif', {
                largeur: 33,
                required: false,
                disabled: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('daldatdeb', {
                largeur: 33,
                required: false,
                disabled: true
            }),
            new FormulaireItem('daldatrec', {
                largeur: 33,
                required: false,
                disabled: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('daldatret', {
                largeur: 33,
                required: false,
                disabled: true
            }),
            new FormulaireItem('daldatass', {
                largeur: 33,
                required: false,
                disabled: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('daldatfer', {
                largeur: 33,
                required: false
            }),
            new FormulaireItem('dalcom', {
                largeur: 50, hidden: !ecranDetails.valeurs.flgcom,
                enableCount: true,
                disabled: false
            }),
            new FormulaireItem('adresse', {
                hidden: true
            }),
            new FormulaireGroupeAccordeon('Assurance', new Formulaire([
                new FormulaireItem('dalsalann', {
                    largeur: 33,
                    required: false
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('placod', {
                    largeur: 33,
                    required: false,
                    disabled: true
                }),
                new FormulaireItem('pladsc', {
                    largeur: 33,
                    required: false,
                    disabled: true
                }),
                new FormulaireItem('planumref', {
                    largeur: 33,
                    required: false,
                    disabled: true
                }),
                new FormulaireItem('daadatadm', {
                    largeur: 33,
                    required: false,
                    disabled: true
                })
            ])
            )
        ]))

    ]);
    vm.dlpMultiOccurrenceOptions = {
        bloc: 'dlp',
        colonnesVisibles: [
            { nom: 'prfcod', largeur: 130 },
            { nom: 'prfdscabr', largeur: 130 },
            { nom: 'dlpdatdeb', largeur: 130 },
            { nom: 'dlpdatfin', largeur: 130 },
            { nom: 'nbrjrs', largeur: 130 },
            { nom: 'dlpseqmod', largeur: 130 },
            { nom: 'dlpflgact', largeur: 130 }
        ],
        navigatePage: al0003Route.NAME,
        navigateParams: (data: any) => ({
            id: data.dlpcleint,
            dlpcleint: data.dlpcleint,
            dalcleint: data.$ancetre.dalcleint

        }),
    }

    vm.dldMultiOccurrenceOptions = {
        bloc: 'dld',
        colonnesVisibles: [
            { nom: 'dlddat', largeur: 130 },
            { nom: 'prfcod', largeur: 130 },
            { nom: 'prfdscabr', largeur: 130 },
            { nom: 'alinbrann', largeur: 130 },
            { nom: 'alinbrmoi', largeur: 130 },
            { nom: 'alinbrjrs', largeur: 130 },
            { nom: 'alidsc', largeur: 130 },
            { nom: 'vacvalcle', largeur: 130 },
            { nom: 'vacdsc', largeur: 130 },
            { nom: 'dldcom', largeur: 130 },
            { nom: 'dldflgact', largeur: 130 }
        ]
    };

    vm.qsrMultiOccurrenceOptions = {
        bloc: 'qsr',
        colonnesVisibles: [
            { nom: 'grqnom', largeur: 130 },
            { nom: 'qsdnom', largeur: 130 },
            { nom: 'qsrflgon', largeur: 130 },
            { nom: 'qdvtxtval', largeur: 130 },
            { nom: 'qsrtxtaut', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('grqnom', {
                disabled: true,
                largeur: 33
            }),
            new FormulaireItem('qsdcleint', {
                disabled: false,
                largeur: 33,
                resourceParams: (data: any) => ({ tdlcleint: data.$ancetre.tdlcleint })
            }),
            new FormulaireItem('qsrflgon', {
                disabled: false,
                default: 0,
                largeur: 33
            }),
            new FormulaireItem('qdvcleint', {
                disabled: false,
                largeur: 33,
                resourceParams: (data: any) => ({ qsdcleint: data.qsdcleint })
            }),
            new FormulaireItem('qsrtxtaut', {
                disabled: false,
                largeur: 33
            })
        ])
    };

    vm.dlrmultiOccurrenceOptions = {
        bloc: 'dlr',
        colonnesVisibles: [
            { nom: 'dlrdatvig', largeur: 130 },
            { nom: 'rcecod', largeur: 130 },
            { nom: 'rcedscabr', largeur: 130 },
            { nom: 'cotcod', largeur: 130 },
            { nom: 'cotdsc', largeur: 130 },
            { nom: 'dlrseqmod', largeur: 130 },
            { nom: 'dlrflgact', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dlrdatvig', {
                required: true,
                largeur: 25,
            }),
            new FormulaireItem('rcecleint', {
                disabled: false,
                required: true,
                largeur: 25,
                resourceParams: (data: any) => ({
                    tdlcleint: data.$ancetre.tdlcleint,
                    daldatdeb: data.$ancetre.daldatdeb,
                    dalcleint: data.$ancetre.dalcleint,
                    demcleint:data.$ancetre.demcleint
                })
            }),
            new FormulaireItem('cotcleint', {
                disabled: false,
                required: true,
                largeur: 25,
                resourceParams: (data: any) => ({
                    tdlcleint: data.$ancetre.tdlcleint,
                    rcecleint: data.rcecleint
                })
            }),
            new FormulaireItem('dlrflgact', {
                required: false,
                disabled: false,
                default:true,
                largeur: 25
            })

        ])
    };

    vm.avimultiOccurrenceOptions = {
        bloc: 'avi',
        fonctions: {
            edition: false
        },
        navigatePage: mr0008Route.NAME,
        navigateParams: (data: any) => ({
            id: data.apacleint,
            cleintref: data.avicleintpar,
            stamnetab: data.stamnetabpar,
            vaesystypavi: data.vaesystypavi,
            typsai: 'JRS' ,
            supprime:0,
            vaebmtent: data.vaebmtent 
        }),
        colonnesVisibles: [
            { nom: 'avidatavi', largeur: 130 },
            { nom: 'avitxt', largeur: 130 },
            { nom: 'aviflgenv', largeur: 130 },
            { nom: 'aviflgdet', largeur: 130 },
        ],
        colonnesCachees: ['avicleint', 'vaebmtent', 'avicleintref', 'stamnetabpar', 'vaesystypavi','avicleintpar','apacleint']
    }
    vm.dfcmultiOccurrenceOptions = {
        bloc: 'dfc',
        navigatePage: al0006Route.NAME,
        navigateParams: (data: any) => ({
            id: data.dfccleint,
            dfccleint: data.dfccleint,
            dalcleint: data.$ancetre.dalcleint,
            demcleint: data.$ancetre.demcleint
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage(al0006Route.NAME, (data: any) => ({
                demcleint: vm.monoOccurrence.data.demcleint,
                dalcleint: vm.monoOccurrence.data.dalcleint

            }), {
                fonction: 'BOUNOUV',
                icon: ''
            })
        ]),
        colonnesVisibles: [
            { nom: 'dfcdatdeb', largeur: 130 },
            { nom: 'dfcdatfin', largeur: 130 },
            { nom: 'dfcdatenv', largeur: 130 },
            { nom: 'vacvalcle', largeur: 130 },
            { nom: 'vacdsc', largeur: 130 },
            { nom: 'dfcprcabs', largeur: 130 },
            { nom: 'dfcflgemrass', largeur: 130 },
            { nom: 'dfcflgemrfpn', largeur: 130 },
        ],
        colonnesCachees: ['dfccleint', 'eadcleint','dalcleint']
    }

    vm.dsemultiOccurrenceOptions = {
        bloc: 'dse',
        colonnesVisibles: [
            { nom: 'dsedatvig', largeur: 130 },
            { nom: 'dsesal', largeur: 130 },
            { nom: 'unccod', largeur: 130 },
            { nom: 'uncdsc', largeur: 130 },
            { nom: 'dseseqmod', largeur: 130 },
            { nom: 'dseflgact', largeur: 130 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dsedatvig', {
                required: true,
                largeur: 15
            }),
            new FormulaireItem('dsesal', {
                required: true,
                largeur: 15
            }),
            new FormulaireItem('unccleint', {
                required: true,
                largeur: 33
            }),
            new FormulaireItem('dseseqmod', {
                required: false,
                disabled: true,
                largeur: 15,
                default: () => (vm.monoOccurrence.data.dalseqmodcou)
            }),
            new FormulaireItem('dseflgact', {
                required: false,
                default: 1,
                disabled: false,
                largeur: 15
            })

        ])
    }

    vm.resmultiOccurrenceOptions = {
        bloc: 'res',
        navigatePage: al0004Route.NAME,
        navigateParams: (data: any) => ({
            id: data.dlhcleint,
            dlhcleint: data.dlhcleint,
            dlpcleint: data.dlpcleint,
            dalcleint: data.$ancetre.dalcleint

        }),
        colonnesVisibles: [
            { nom: 'prfcod', largeur: 130 },
            { nom: 'prfdscabr', largeur: 190,  menu: [
                new MenuItemForage(
                    'LBL_CUMULATIF',
                    al0003Route.NAME,
                    (rowData: any) => ({
                        id: rowData.dlpcleint,
                        dalcleint: rowData.dalcleint
                    }),
                    {
                        fonction: 'CUMULATIF',
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ] },
            { nom: 'vaedlitypdatald', largeur: 130 },
            { nom: 'dlidatdeb', largeur: 130 },
            { nom: 'dlidatfin', largeur: 130 },
            { nom: 'dlhdatvig', largeur: 130 },
            { nom: 'dlhpctrep', largeur: 130 },
            { nom: 'dhdqteabs', largeur: 130 },
            { nom: 'dhdqtetra', largeur: 130 },
        ],
        colonnesCachees: ['dlhcleint', 'dlpcleint','dalcleint']
    }
}
