import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRr0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0012Controller(Menu: IMenuClass,
                                         mr0238Route: IRoute,
                                         mr0014Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr0012 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_MR0238',
                mr0238Route.NAME,
                (data:any) => ({vaetypfnd: 'TAF',
                    cleintdet: data.tafcleint}),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            )
        ]),
        //colonnesVisibles,
        colonnesVisibles: [
            {nom: 'tafcod', largeur: 110},
            {nom: 'tafdsc', largeur: 262},
            {nom: 'tafflgpri', largeur: 91},
            {nom: 'tafflgttu', largeur: 91},
            {nom: 'tafflgprb', largeur: 95},
            {nom: 'tafflgaft', largeur: 78},            
            {nom: 'tafflgaccanc', largeur: 78},            
            {nom: 'flgvcl', largeur: 110, menu:[
                new MenuItemForage('LBL_FOR_VCL',
                                    mr0014Route.NAME,
                                    (rowData: any) => ({ 
                                        stamnetabcri: 'TAF',
                                        cleintcri: rowData.tafcleint }),
                                    { hidden: (rowData: any) => !(rowData.flgvcl) })]}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('tafcod', {required: true}),
                new FormulaireItem('tafdsc', {required: true, largeur: 60}),
                new FormulaireItem('tafflgpri', {default: 0}),
                new FormulaireItem('tafflgttu', {default: 0}),
                new FormulaireItem('tafflgprb', {default: 0}),
                new FormulaireItem('tafflgaft', {default: 0}),
                new FormulaireItem('tafflgaccanc', {default: 0})
            ], {largeurDefaut: 20}
        )
    };
}
