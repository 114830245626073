import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMR0180 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0180Controller(mr0111Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0180 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0111Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                urgcleint: rowData.urgcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0111Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cvtcod', largeur: 105},
            {nom: 'cvtdsc', largeur: 250},
            {nom: 'urgcod', largeur: 140},
            {nom: 'urgdsc', largeur: 250},
            {nom: 'urgdscabr', largeur: 250},
            {nom: 'urgflgret', largeur: 100},
            {nom: 'urgflgact', largeur: 65}
        ],
        colonnesCachees: [
            'urgcleint'
        ]
    };
}
