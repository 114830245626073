import {IComponentController} from 'angular';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageRr0056 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IPageRr0056Params {
    demcleint: string;
    rcecleintref: string;
    dcocleint: string;
    dchcleint: string;
    dracleint: string;
    employe: number;
}

/* @ngInject */
export default function Rr0056Controller($stateParams: IPageRr0056Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0056 = this;

    const filtres: Array<any> = [];

    if ($stateParams.dracleint) {
        filtres.push(
            {
                colonne: 'dracleint',
                valeur: $stateParams.dracleint,
                visible: false
            }
        )
    }

    vm.$onInit = $onInit;

    function $onInit() {
        vm.multiOccurrenceOptions = {
            filtres,
            fonctions: {
                criteresSuggeresVisibles : false,
                importation : true,
                nouveau: !$stateParams.dracleint
            },
            filtresParametres: [
                {
                    colonne: 'demcleint',
                    affichage: !$stateParams.demcleint
                }
            ],
            criteresSuggeres: new Formulaire([
                new FormulaireItem('ppacodcri')
            ], {largeurDefaut: 25}),
            colonnesCachees: [
                'demcleint'
            ],
            // Colonnes fixe à gauche
            colonnesFixesGauche: [
                {nom: 'eplnummat', largeur: 110},
                {nom: 'eplnomprn', largeur: 275},
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'rcecod',    largeur: 110},
                {nom: 'rcedscabr', largeur: 275},
                {nom: 'ppacod',    largeur: 110},
                {nom: 'ppadatdeb', largeur: 100},
                {nom: 'ppadatfin', largeur: 100},
                {nom: 'dramntemp', largeur: 130},
                {nom: 'dramntemr', largeur: 130},
                {nom: 'draqteadm', largeur: 130},
                {nom: 'dramntadm', largeur: 130},
                {nom: 'dracom', largeur: 300},
                {nom: 'draflgder', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('demcleint', {readonly: Boolean($stateParams.employe || $stateParams.demcleint || $stateParams.dcocleint || $stateParams.dchcleint)}),
                new FormulaireItem('rcecleint',{
                    resourceParams: {
                        dchcleint: ($stateParams.dchcleint)
                    }
                }),
                new FormulaireItem('ppacleint',{
                    resourceParams: (data: any) => {
                        return {
                            demcleint: data.demcleint
                        };
                    }
                }),
                new FormulaireItem('dramntemp',{largeur: 25}),
                new FormulaireItem('dramntemr',{largeur: 25}),
                new FormulaireItem('draqteadm',{largeur: 25}),
                new FormulaireItem('dramntadm',{largeur: 25}),
                new FormulaireItem('dracom', {enableCount: true, largeur: 100})
            ],{largeurDefaut:33.33})
        };
    }
}
