import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IComponentController } from 'angular';

interface IPageCr0004 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0004Controller(Menu: IMenuClass, Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    mc0046Route: IRoute,
    cr0002Route: IRoute,
    $stateParams: any
) {
    const vm: IPageCr0004 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'crpcleint', valeur: $stateParams.crpcleint, visible: false }
        ],
        colonnesCachees: ['enecleint'],
        colonnesVisibles: [
            { nom: 'crpcod', largeur: 115 },
            { nom: 'crpdsc', largeur: 187 },
            { nom: 'vaedsc', largeur: 130 },
            { nom: 'moecod', largeur: 170 },
            { nom: 'moedsc', largeur: 190 },
            {
                nom: 'enecod', largeur: 100,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_AS0081',
                        cr0002Route.NAME,
                        (rowData: any) => {
                            return {
                                enecleint: rowData.enecleint
                            };
                        }
                    )
                ]
            },
            { nom: 'enedsc', largeur: 160 },
            { nom: 'taxcod', largeur: 115 },
            { nom: 'taxcodenc', largeur: 115 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('crpcod', { required: true }),
            new FormulaireItem('crpdsc', { required: true }),
            new FormulaireItem('vaesysdevcod', { required: true }),
            new FormulaireItem('moecleint'),
            new FormulaireItem('enecleint'),
            new FormulaireItem('taxcleint'),
            new FormulaireItem('taxcleintenc'),
            new FormulaireItemSautDeLigne(),

        ]),
        fonctions: {
            rechercheCollapsable: false,
        },

        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => ({
                    vaeusient: 'CRP',
                    uvicleintref: data.crpcleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),
    };
}
