import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageAc0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0012Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    exDateFilter: IFilterExDate,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageAc0012 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleintachcri', {
                disabled: () => (vm.valeursEcran.flgmodach === 0)
            }),
            new FormulaireItem('refcleintcri'),
            new FormulaireItem('cifcleintcri'),
            new FormulaireItem('daccleintcri'),
            new FormulaireItem('datreqcri', {
                required: true,
                default: exDateFilter(new Date())
            }),
            new FormulaireItem('nbrjrscarcri'),
            new FormulaireItem('flgrecparcri'),
            new FormulaireItem('motcleintcri', { lovSelectionMultiple: true })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_CREER_RELANCE',
                'BOUREL',
                {
                    selection: { 
                        nom: 'selection',
                        lblConfirmationTitre: 'LBL_BTN_CREER_RELANCE',
                        largeurFormulaire: 40,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('vecdortyprel', { 
                                largeur: 50, 
                                required: true 
                            }),
                            new FormulaireItem('dordat', { 
                                largeur: 50, 
                                required: true,
                                default: exDateFilter(new Date())
                            }),
                            new FormulaireItem('dornot', { 
                                largeur: 100, 
                                required: true 
                            })
                        ])
                    },
                    fonction: 'BOUREL',
                    successAction: () => { 
                        vm.multiOccurrence.fetchDataList();
                    }
                }),
            new MenuItemSelection(
                'LBL_BTN_RAPPORT_RELANCE',
                'BOURAPREL',
                {
                    selection: { nom: 'selection' },
                    fonction: 'BOURAPREL',
                    successAction: () => { 
                        vm.multiOccurrence.fetchDataList();
                    }
                })

        ]),
        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 140 },
            { nom: 'tydcod', largeur: 100 },
            { nom: 'sysboncmd', largeur: 140 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnomabr', largeur: 180 },
            { nom: 'dacdat', largeur: 126 },
            { nom: 'dacdatreq', largeur: 126 },
            { nom: 'nbrjrsret', largeur: 140 },
            { nom: 'txmdat', largeur: 126 },
            { nom: 'teccod', largeur: 120 },
            { nom: 'tecdscabr', largeur: 180 },
            { nom: 'dornbr', largeur: 120 },
            { nom: 'dordat', largeur: 126 },
            { nom: 'etcnumdoc', largeur: 120 },
            { nom: 'vaeetctyp_dsc', largeur: 150 },
            { nom: 'etrdatdeb', largeur: 126 },
            { nom: 'etrdatfin', largeur: 126 },
            { nom: 'intcodreq', largeur: 120 },
            { nom: 'intnomreq', largeur: 180 },
            { nom: 'radnumtelpos', largeur: 160 },
            { nom: 'intcodach', largeur: 120 },
            { nom: 'intnomach', largeur: 180 }
        ]
    };
}