import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePb0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Pb0005Controller(Formulaire: IFormulaireClass,
    mr0205Route: IRoute,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass) {
    const vm: IPagePb0005 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_CAL',
                'BOUCAL',
                (data: any) => ({
                    apbcleint: data.apbcleint
                }),
                {
                    icon: 'content_copy'
                }
            ), new MenuItemForage(
                'BTN_FOR_VEN',
                mr0205Route.NAME,
                (rowData:any) => ({
                    id: rowData.apbcleint,
                    stamnetab: 'APB'
                    
                }),
                {
                    icon: 'launch_app',
                iconButton: false
                }
            )
        ]),
        colonnesCachees: ['msgerreur', 'msgerreuravr', 'pppcleint', 'apbcleint', 'dnecleint'],
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 120 },
            { nom: 'eplnomprn', largeur: 266 },
            { nom: 'aftcod', largeur: 120 },
            { nom: 'aftdsc', largeur: 200 },
            { nom: 'dnedatdebsim', largeur: 120 },
            { nom: 'dnedatfinsim', largeur: 120 },
            { nom: 'flg_err', largeur: 126 },
            { nom: 'flg_avr', largeur: 126 }            
        ]
    };


}
