import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';


interface IPageGA0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;  
}
/* @ngInject */
export default function Ga0004Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGA0004 = this;
    
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'gcacod'   , largeur: 110},
            {nom: 'gcadsc'   , largeur: 250},
            {nom: 'unacod'   , largeur: 170},
            {nom: 'unadsc'   , largeur: 290},
            {nom: 'gcaflgact', largeur: 110}

        ],
        formulaire: new Formulaire([
            new FormulaireItem('gcacod', {
                largeur: 20
            }),
            new FormulaireItem('gcadsc', {
                largeur: 30
            }),
            new FormulaireItem('unacleint', {
                largeur: 20
            }),
            new FormulaireItem('gcaflgact', {
                largeur: 20, default: 1
            })
        ])
    };
}
