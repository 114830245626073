import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAc2002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac2002Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    ac0010Route: IRoute) {
                                            
    const vm: IPageAc2002 = this; 

    const docAchatRef = new MenuItemForage(
        '',
        ac0010Route.NAME,
        (rowData:any) => ({
            refcleint: rowData.refcleint,
            intcleintreq: rowData.intcleintreq
        }),
        {
            fonction: 'BTNDOCACHREF',
            icon: 'add_circle',
            iconButton: true
        }
    );

    const docAchatEtr = new MenuItemForage(
        '',
        ac0010Route.NAME,
        (rowData:any) => ({
            refcleint: rowData.refcleint,
            intcleintreq: rowData.intcleintreq,
            etrcleint: rowData.etrcleint
        }),
        {
            fonction: 'BTNDOCACHETR',
            icon: 'add_circle',
            iconButton: true,
            hidden: (rowData:any) => (!rowData.etrcleint)
        }
    );

    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable: false,
            effaceCriteres: true,
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleintreqcri', {
                required: true,
                largeur: 50
            }),
            new FormulaireItem('refcleintcri', { largeur: 50 }),
            new FormulaireItem('caacleintcri', { largeur: 50 }),
            new FormulaireItem('tfocleintcri', { largeur: 50 })
        ]),
        colonnesVisibles: [
            {nom: 'refcod', largeur: 120, menu: [docAchatRef]},
            {nom: 'refnom', largeur: 200},
            {nom: 'etcnumdoc', largeur: 150, menu: [docAchatEtr]},
            {nom: 'etcdsc', largeur: 200},
            {nom: 'etcflgitm', largeur: 100},
            {nom: 'caacod', largeur: 100},
            {nom: 'caadsc', largeur: 200},
        ],
        colonnesCachees: [
            'refcleint',
            'etrcleint',
            'caacleint',
            'intcleintreq'
        ]
    };
}