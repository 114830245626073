import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0019Controller(mc0094Route: IRoute,
                                         mc0089Route: IRoute,
                                         cb0010Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMc0019 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'BTN_CPT_BLOC',
                mc0094Route.NAME,
                (data:any) => ({
                    el2cleint: data.el2cleint
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemEcran(
                'BTN_ADM',
                mc0089Route.NAME,
                (data:any) => ({
                    elgcleintref: data.el2cleint,
                    vaesyselg: 'EL2'
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'BTN_ANA',
                cb0010Route.NAME,
                (data: any) => ({
                    id: data.el2cleint,
                    niveau: 2
                }),
                {
                    icon: 'equalizer',
                    iconButton: false,
                    fonction: 'BTNANA'
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'el2cod'             , largeur: 120},
            {nom: 'el2dsc'             , largeur: 300},
            {nom: 'el2dscabr'          , largeur: 200},
            {nom: 'el2datdeb'          , largeur: 130},
            {nom: 'el2datfin'          , largeur: 130},
            {nom: 'el2flgaut'          , largeur: 120},
            {nom: 'inddsc'             , largeur: 200},
            {nom: 'el2prcdep'          , largeur: 126},
            {nom: 'el2mntdep'          , largeur: 126},
            {nom: 'vaesysnivappctr_dsc', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('el2cod', {largeur: 20}),
            new FormulaireItem('el2dsc', {largeur: 40}),
            new FormulaireItem('el2dscabr', {largeur: 40}),
            new FormulaireItem('el2datdeb'),
            new FormulaireItem('el2datfin'),
            new FormulaireItem('el2flgaut'),
            new FormulaireItem('indcleint'),
            new FormulaireItemDivider('LBL_DIV_DEP'),
            new FormulaireItem('el2prcdep'),
            new FormulaireItem('el2mntdep'),
            new FormulaireItem('vaesysnivappctr')
        ], {largeurDefaut: 33})
    };
}
