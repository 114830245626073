import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IComponentController } from 'angular';

interface IPageRr0526 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0526Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: any,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr0526 = this;
    vm.$onInit = $onInit;
    
    function $onInit() {
        vm.multiOccurrenceOptions = {
            ecranDetails: ecranDetails,
            filtresParametres: [
                { colonne: 'demcleint', valeur: $stateParams.demcleint, visible: true }
            ],
            fonctions: {
                importation: true,
                rechercheCollapsable: false,
                edition: (rowData: any) => !(rowData.reuflguti)
            },
            colonnesFixesGauche: [
                { nom: 'eplnummat', largeur: 100 },
                { nom: 'eplnomprn', largeur: 250 }
            ],
            colonnesVisibles: [
                { nom: 'vaereutyp_dsc', largeur: 178 },
                { nom: 'ppacod', largeur: 90 },
                { nom: 'reudatdeb', largeur: 120 },
                { nom: 'reudatfin', largeur: 120 },
                { nom: 'reuqteajt', largeur: 110 },
                { nom: 'reumntajt', largeur: 110 },
                { nom: 'reucom', largeur: 300 }
            ],
            colonnesFixesDroite: [
                { nom: 'reuflguti', largeur: 90 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('reecleint', { default: $stateParams.reecleint, hidden: true }),
                new FormulaireItem('demcleint', { default: $stateParams.demcleint, hidden: (data: any) => $stateParams.demcleint }),
                new FormulaireItem('vaereutyp', { required: true }),
                new FormulaireItem('ppacleint', {
                    resourceParams: (data: any) => ({
                        demcleint: data.demcleint
                    }), required: true
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('reudatdeb', { required: true }),
                new FormulaireItem('reudatfin', { required: true }),
                new FormulaireItem('reuqteajt'),
                new FormulaireItem('reumntajt'),
                new FormulaireItem('reucom', { largeur: 100, enableCount: true })
            ], { largeurDefaut: 25 })
        };
    }
}
