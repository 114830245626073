import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0191 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface Mr0191Params {
    tagcleint: string;
}
/* @ngInject */
export default function Mr0191Controller(
    Operateur: IOperateurService,
    mr0176Route: IRoute,
    mr0187Route: IRoute,
    mr0195Route: IRoute,
    mr0198Route: IRoute,
    $stateParams: Mr0191Params,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageMr0191 = this;
    const filtres = [];

    if ($stateParams.tagcleint) {
        filtres.push(
            ...[
                {
                    colonne: 'tagcleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.tagcleint
                }
            ]
        );
    }

    const forage = {
        FORVNT: new MenuItemEcran(
            '',
            mr0176Route.NAME,
            (rowData: any) => ({
                stamnetab: 'TAG',
                ovdcleintref: rowData.tagcleint
            }),
            {
                icon: 'launch',
                iconButton: false,
                secondaire: true,
                fonction: 'FORVNT'
            }
        ),
         FORRAT: new MenuItemEcran(
            '',
            mr0187Route.NAME,
            (rowData: any) => ({
                id: rowData.tagcleint
            }),
            {
                icon: 'launch',
                iconButton: false,
                secondaire: true,
                fonction: 'FORRAT'
            }
        ),
        FORTAE: new MenuItemForage(
            '',
            mr0198Route.NAME,
            (rowData: any) => ({
                id: rowData.tagcleint
            }),
            {
                icon: 'exit_to_app',
                iconButton: false,
                secondaire: true,
                fonction: 'FORTAE'
            }
        ),
        
        FORTSU: new MenuItemEcran(
            '',
            mr0195Route.NAME,
            (rowData: any) => ({
                id: rowData.tsucleint,
                tagcleint: rowData.tagcleint
            }),
            {
                icon: 'launch',
                iconButton: false,
                secondaire: true,
                fonction: 'FORTSU'
            }
        ) 
    };

    vm.multiOccurrenceOptions = {
        filtres,
        fonctions: {
            rechercheCollapsable: false,
            edition: false,
            supprime: false
        },
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 80 },
            { nom: 'tagdsc', largeur: 300 },
            { nom: 'carcod', largeur: 80 },
            { nom: 'cardsc', largeur: 300 },
            { nom: 'nbr_vnt', largeur: 150, menu: [forage.FORVNT] },
            { nom: 'nbr_rat', largeur: 150, menu: [forage.FORRAT] },
             { nom: 'nbr_tsu', largeur: 150, menu: [forage.FORTSU] },
            { nom: 'nbr_tae', largeur: 150, menu: [forage.FORTAE] }            
        ],
        colonnesCachees: ['tagcleint', 'tsucleint']
    };
}
