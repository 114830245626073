import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IStateService } from 'angular-ui-router';

interface IPageCB0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}


interface ICb0001Params {
    menuId: string;
}

/* @ngInject */
export default function Cb0001Controller(
    Menu: IMenuClass,
    mc0044Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    cb0005Route: IRoute,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    cb0029Route: IRoute,
    cb2000Route: IRoute,
    $state: IStateService,
    $stateParams: ICb0001Params
) {
    const vm: IPageCB0001 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0044Route.NAME,
        navigateParams: (data: any) => ({
            prucleint: data.prucleint
        }),
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_ANA',
                cb0005Route.NAME,
                (rowData: any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false,
                    fonction: 'BOU05'
                }
            ),
            new MenuItemForage(
                'LBL_ANA_SPE',
                cb0029Route.NAME,
                (rowData: any) => ({
                    id: rowData.prucleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false,
                    fonction: 'BOU29'
                }
            )
        ]),
        actionsNouveaux: new Menu([
            new MenuItemForage(
                'LBL_CREER_UBR',
                mc0044Route.NAME,
                () => ({}),
                {
                    icon: '',
                    fonction: 'BOUCRE'
                }
            ),
            new MenuItemAction(
                'LBL_CREER_PDF',
                'BOUCREPDF', () => ({}), {
                icon: '',
                fonction: 'BOUCREPDF',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_CREER_PDF',
                    lblConfirm: 'G_LBL_BTN_EXECUTER',
                    largeurFormulaire: 50,
                    formulaire: new Formulaire(
                        [
                            new FormulaireItem('pdfcleintcre', { largeur: 50, required: true }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('intcodnom', { largeur: 50, readonly: true }),
                            new FormulaireItem('orgcoddsc', { largeur: 50, readonly: true }),
                            new FormulaireItem('unacoddsc', { largeur: 50, readonly: true }),
                            new FormulaireItem('cencoddsc', { largeur: 50, readonly: true })
                        ]
                    )
                },
                successAction: (data: any) => {
                    $state.go(mc0044Route.NAME, {
                        pdfcleintnew: data.pdfcleintnew,
                        menuId: $stateParams.menuId
                    });
                }
            })
        ]),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('grpcleintcri'),
            new FormulaireItem('prjcleintcri'),
            new FormulaireItem('pdfnumrefcri'),
            new FormulaireItem('orgcleintcri'),
            new FormulaireItem('flgexcanncri', { default: 0 })
        ]),
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'prudscl01', largeur: 250 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'prudatdeb', largeur: 120 },
            { nom: 'prudatfin', largeur: 120 },
            { nom: 'vaeprutypbdg_dsc', largeur: 150 },
            { nom: 'ind_gestion', largeur: 200 },
            { nom: 'prunumrefmul', largeur: 200 },
            { nom: 'cifcod', largeur: 140 },
            { nom: 'cifdsc', largeur: 250 },
            { nom: 'unacod', largeur: 160 },
            { nom: 'unadsc', largeur: 250 },
            { nom: 'vecprucatpro_dsc', largeur: 150 },
            { nom: 'pruflgstu', largeur: 120 },
            { nom: 'pructl', largeur: 120 },
            { nom: 'vaeprutypcptbdg_dsc', largeur: 150 },
            { nom: 'orgcod', largeur: 120 },
            { nom: 'orgnom', largeur: 200 },
            { nom: 'orpcod', largeur: 120 },
            { nom: 'prjcod', largeur: 120 },
            { nom: 'pdfcod', largeur: 120 }
        ],
        colonnesFixesDroite: [
            {
                nom: 'prusld',
                largeur: 160,
                menu: [
                    new MenuItemForage(
                        'LBL_DISPONIBILITE',
                        cb2000Route.NAME,
                        (rowData: any) => ({
                            id: rowData.prucleint
                        }),
                        {
                            icon: 'assessment',
                            fonction: 'BOUDIS'
                        }
                    )
                ]
            }
        ],

    };
}
