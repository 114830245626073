import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr0072 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0072Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0072 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'mrscod', largeur: 90 },
            { nom: 'mrsdsc', largeur: 262 },
            { nom: 'vaecomemp_dsc', largeur: 262 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mrscod'), 
            new FormulaireItem('mrsdsc'), 
            new FormulaireItem('vaecomemp')
        ], {largeurDefaut: 33.33})
    };
}
