import {IComponentController, IFilterDate} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcran, IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageRR0075 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    formulaireDrp: IFormulaire;
    formulaireDetjrn: IFormulaire;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dceMultiOccurrenceOptions: IMultiOccurrenceOptions;

  menus: {[key: string]: Array<IMenuItem>};
    // Forage
    foragePerception: IMenuItem;
    foragePerceptionItem: IMenuItem;
    forageCompteRecevoirMenuItem: IMenuItem;
    forageRetenueMenuItem: IMenuItem;

    menuItemPar : IMenuItemEcran;
}

/* @ngInject */
export default function Rr0075Controller(dateFilter: IFilterDate,
                                         Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         Pagination: IPaginationClass,
                                         rr0108Route: IRoute,
                                         mr0205Route: IRoute,
                                         mr0208Route: IRoute,
                                         rr0074Route: IRoute,
                                         mr0176Route: IRoute,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRR0075 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    // Forage pour les demandes de rémunération
    const forageDer = new MenuItemForage(
        'BTN_FORAGE_DER',
        rr0074Route.NAME,
        (data: any) => ({
            id: data.aut__trncleint_der
        }),
        {
            icon: 'exit_to_app',
            iconButton: true,
            hidden: (data: any) => (
                !data.drpcleint
            )
        }
    );

    vm.$onInit = $onInit;

    function $onInit() {
        // Prolongation Demande de rémunération
        vm.monoOccurrenceOptions = {
            infosMonetaires: {
                largeurTexte: 150,
                lblTitre: 'LBL_SOMMAIRE_REMUN',
                noIcons: true,
                blocs: ['dce'],
                afficherAuBas:"oui",
                donnees:
                    [
                        { nom: 'somqte', libelle: 'AUT__SOMQTE' },
                        { nom: 'ecart_qte', libelle: 'AUT__ECART_QTE', flagShowField: ['flgsaisie_dce', 'ecart_qte'] },
                        { nom: 'sommnt', libelle: 'AUT__SOMMNT' },
                        { nom: 'ecart_mnt', libelle: 'AUT__ECART_MNT', flagShowField: ['flgsaisie_dce', 'ecart_mnt'] },
                    ]
            },
            formulaire: new Formulaire('LBL_BLC_DRP', [
            new FormulaireItem('drpnumdoc', {largeur: 20}),
            new FormulaireItem('dercleint', {largeur: 20,  menu: [forageDer]}),
            new FormulaireItem('t_demcleint', {largeur: 40}),
            new FormulaireItemSautDeColonne(),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('drpdatdeb', {largeur: 25}),
            new FormulaireItem('drpdatfin', {largeur: 25}),
            new FormulaireItem('drpflgpro', {largeur: 25, default: 0}),
            new FormulaireItem('drpdatfinori', {largeur: 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('drpqte'),
            new FormulaireItem('drpmnt'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('drpdsc',{
                largeur: 50,
                enableCount: true}),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS',
                new Formulaire([
                    new FormulaireItem('drpdat',{
                        largeur:20,
                        default: dateFilter(new Date(), 'yyyy-MM-dd')}),
                    new FormulaireItem('ttccleint', {
                        nombreLibellesDetailsCacher: 1,
                        largeur: 40
                    })
                ], {largeurDefaut: 33.33}))

        ], {largeurDefaut: 20})};

        // Bouton paramètre d'embauche
        vm.menuItemPar = new MenuItemForage(
            'LBL_PARAM_PAR',
            rr0108Route.NAME,
            (data: any) => ({
                cpecleintref: data.drpcleint,
                stamnetab: 'DRP',
                trncleint: data.trncleint
            }),
            {
                icon: 'subtitles',
                iconButton: false
            }
        ),
        // Bouton Ventilation
        vm.menuItemVtl = new MenuItemForage(
            'LBL_PARAM_VTL',
            mr0205Route.NAME,
            (data: any) => ({
                id: data.drpcleint,
                stamnetab: "DRP"
            }),
            {
                icon: 'subtitles',
                iconButton: false
            }
        ),

        // Bouton Impact budgétaire
        vm.menuItemDoc = new MenuItemForage(
            'LBL_PARAM_DOC',
            mr0208Route.NAME,
            (data: any) => ({
               id: vm.valeursEcran.demcleint,
                doccleintgrh: data.doccleint,
                typtri: 1
            }),
            {
                icon: 'subtitles',
                iconButton: false
            }
        ),

        // Imputation - Demande de rémunération
        vm.ovdMultiOccurrenceOptions = {
            resourceParamsDynamique: (data: any) => {
                return {
                    ovdcleintref: vm.monoOccurrence.data.drpcleint,
                    stamnetab: 'DRP'
                }
            },
            actionsMore: new Menu([
                new MenuItemForage(
                    'G_LBL_VENTILATION',
                    //@TODO à modifier quand le lien vers le portail sera fait
                    'secure.accueil',
                    () => ({}),
                    {
                        icon: 'equalizer',
                        iconButton: false
                    }
                ),
                new MenuItemForage(
                    'G_LBL_IMPACT_BUD',
                    //@TODO à modifier quand le lien vers le portail sera fait
                    'secure.accueil',
                    () => ({}),
                    {
                        icon: 'equalizer',
                        iconButton: false
                    }
                ),
                new MenuItemForage(
                    'G_LBL_ENGAGEMENT',
                    //@TODO à modifier quand le lien vers le portail sera fait
                    'secure.accueil',
                    () => ({}),
                    {
                        icon: 'create',
                        iconButton: false,
                        hidden: (data: any) => (!vm.monoOccurrence.data.aut__trnflgstafin) //(!vm.valeursEcran.trnflgstafin)
                    }
                ),
                new MenuItemForage(
                    'G_LBL_HISTO_ENG',
                    //@TODO à modifier quand le lien vers le portail sera fait
                    'secure.accueil',
                    () => ({}),
                    {
                        icon: 'restore',
                        iconButton: false,
                        hidden: (data: any) => (!vm.monoOccurrence.data.aut__trnflgstafin) // (!vm.valeursEcran.trnflgstafin)
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('BTN_MODIFIER', mr0176Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.ovdcleint,
                        ovdcleintref: vm.valeursEcran.drpcleint,
                        stamnetab: 'DRP'
                    };
                }, {
                    icon: 'launch',
                    fonction: 'BOUOUV',
                    disabled: () => !vm.monoOccurrence.data.drpcleint,
                    onClose() {
                        vm.ovdMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('BTN_NOUVEAU', mr0176Route.NAME, (rowData: any) => {
                    return {
                        ovdcleintref: vm.valeursEcran.drpcleint,
                        stamnetab: 'DRP'
                    };
                }, {
                    disabled: () => !vm.monoOccurrence.data.drpcleint,
                    onClose() {
                        vm.ovdMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'ovd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ovnimp', largeur: 300},
                {nom: 'rsccoddsc', largeur: 100},
                {nom: 'ovddatvig', largeur: 100},
                {nom: 'ovddatfin', largeur: 100}
            ],
            colonnesCachees: [
                'ovdcleint'
            ]
        };

        // Détail journalier
        vm.formulaireDetjrn = new Formulaire(('LBL_BLC_DETJRN'), [
            new FormulaireItemDivider('LBL_DIV_SEM1',{hidden: !vm.valeursEcran.flgaff_sem1}),
            new FormulaireItem('drpqte01'),
            new FormulaireItem('drpqte02'),
            new FormulaireItem('drpqte03'),
            new FormulaireItem('drpqte04'),
            new FormulaireItem('drpqte05'),
            new FormulaireItem('drpqte06'),
            new FormulaireItem('drpqte07'),
            new FormulaireItemDivider('LBL_DIV_SEM2',{hidden: !vm.valeursEcran.flgaff_sem2}),
            new FormulaireItem('drpqte08'),
            new FormulaireItem('drpqte09'),
            new FormulaireItem('drpqte10'),
            new FormulaireItem('drpqte11'),
            new FormulaireItem('drpqte12'),
            new FormulaireItem('drpqte13'),
            new FormulaireItem('drpqte14'),
            new FormulaireItemDivider('LBL_DIV_TOTSEM'),
            new FormulaireItem('aut__total_sem1'),
            new FormulaireItem('aut__total_sem2'),
            new FormulaireItem('aut__total_quantite')
        ], {largeurDefaut: 10});

        // Cédule de paiement
        vm.dceMultiOccurrenceOptions = {
            bloc: 'dce',
            reloadWithBlocs: ['DRP'],
            fonctions: {
                nouveau: () => vm.monoOccurrence.data.aut__flgsaisie_dce,
                edition: () => vm.monoOccurrence.data.aut__flgsaisie_dce,
                supprime: () => vm.monoOccurrence.data.aut__flgsaisie_dce
            },
            colonnesCachees: [
                'dercleint',
                'drpcleint'
            ],
            colonnesVisibles: [
                {nom: 'ppacod', largeur: 90},
                {nom: 'ppadatdeb', largeur: 110},
                {nom: 'ppadatfin', largeur: 110},
                {nom: 'dceqte', largeur: 120, total: true},
                {nom: 'dcemnt', largeur: 120, total: true},
                {nom: 'ppacodtrt', largeur: 90},
                {nom: 'dceflgfer', largeur: 80},
                {nom: 'dceqtecontot', largeur: 130},
                {nom: 'dcemntcontot', largeur: 130}
            ],

            formulaire: new Formulaire([
                new FormulaireItem('dercleint', {default: () => vm.monoOccurrence.data.dercleint}),
                new FormulaireItem('drpcleint', {default: () => vm.monoOccurrence.data.drpcleint}),
                new FormulaireItem('ppacleint', {
                    largeur: 30,
                    resourceParams: () => ({
                        dercleint: vm.monoOccurrence.data.dercleint,
                        drpdatdeb: vm.monoOccurrence.data.drpdatdeb,
                        drpdatfin: vm.monoOccurrence.data.drpdatfin
                    })
                }),
                new FormulaireItem('dceqte'),
                new FormulaireItem('dcemnt')
            ], {largeurDefaut: 20})

        };

    }
}
