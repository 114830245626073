import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageSO0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0002Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSO0002 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tpocod', largeur: 100},
            {nom: 'tpodsc', largeur: 300},
            {nom: 'tpoindposeff', largeur: 112},
            {nom: 'tpoindposope', largeur: 112},
            {nom: 'tpoindposbud', largeur: 112},
            {nom: 'tpoindposaut', largeur: 112}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tpocod', {required: true, largeur: 50}),
            new FormulaireItem('tpodsc', {required: true, largeur: 50}),
            new FormulaireItem('tpoindposeff'),
            new FormulaireItem('tpoindposope'),
            new FormulaireItem('tpoindposbud'),
            new FormulaireItem('tpoindposaut')
        ], {largeurDefaut: 25})
    };
}
