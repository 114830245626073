import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IOperateurService} from "../../../../../core/services/operateur.service";

interface IPageRr0079 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0079Controller(rr2019Route: IRoute,
                                         Menu: IMenuClass,
                                         Operateur: IOperateurService,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0079 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
        },
        navigatePage: rr2019Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(rr2019Route.NAME)
        ]),
        colonnesFixesGauche: [
            {nom: 'faanumcha', largeur: 150},
            {nom: 'faaficsou', largeur: 252}
        ],
        colonnesVisibles: [    
            {nom: 'datcre', largeur: 100},
            {nom: 'usrnomcre', largeur: 160},
            {nom: 'faanbrlig', largeur: 90},
            {nom: 'faanbrerr', largeur: 90},
            {nom: 'faaflgval', largeur: 74},
            {nom: 'faaflgtrt', largeur: 74}
        ]
    };
}
