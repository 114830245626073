import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAS2002 {
    tppMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    tppMultiOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function As2002Controller(ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass) {
    const vm: IPageAS2002 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.tppMultiOccurrenceOptions = {
        bloc: 'tpp',
        actionsMore: new Menu([new MenuItemAction("LBL_BTN_COPIE", "BOUGEN", () => ({}),
            { iconButton: false, 
                icon: "content_copy" ,
                successAction:()=>{vm.monoOccurrence.fetchData()}
            })]),
        colonnesVisibles: [
            { nom: 'ptaper', largeur: 120 },
            { nom: 'ptacod', largeur: 170 },
            { nom: 'ptadatdeb', largeur: 190 },
            { nom: 'ptadatfin', largeur: 140 },
            { nom: 'vaetppstu_desc', largeur: 262 }
        ],
        colonnesCachees: ['ptacleint']
    };
}
