import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IStateParamsService} from 'angular-ui-router';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRR0094 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0094Controller($stateParams: IStateParamsService,
                                         rr0095Route:IRoute) {
    const vm: IPageRR0094 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            selectionCycle: false,
            rechercheCollapsable: false
        },
        navigatePage: rr0095Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.lemcleint,
                lemcleint: rowData.lemcleint,
                demcleint: rowData.demcleint,
            };
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 200, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'lemdatdeb', largeur: 100},
            {nom: 'lemdatfin', largeur: 100},
            {nom: 'urgcod', largeur: 175},
            {nom: 'urgdscabr', largeur: 250},
            {nom: 'strcod', largeur: 125},
            {nom: 'strdsc', largeur: 200},
            {nom: 'lemcod', largeur: 100},
            {nom: 'tlecod', largeur: 100},
            {nom: 'tledsc', largeur: 200},
            {nom: 'tleflgpmn', largeur: 70},
            {nom: 'tleflgpri', largeur: 70},
            {nom: 'lemflgemb', largeur: 110},
            {nom: 'lemdsc', largeur: 200},
            {nom: 'unpcod', largeur: 150},
            {nom: 'unpdsc', largeur: 300}
        ]
    };
}
