import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageGs0033 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0033Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageGs0033 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vecsysinfcom'       , largeur: 120},
            {nom: 'vecsysinfcom_dsc'   , largeur: 200},
            {nom: 'rvilibele'          , largeur: 250},
            {nom: 'vaesysmnetab_dsc'   , largeur: 150},
            {nom: 'rvilonele'          , largeur: 100},
            {nom: 'rviflgfix'          , largeur: 120},
            {nom: 'rviflgjus'          , largeur: 130},
            {nom: 'vaervitypdon_dsc'   , largeur: 130},
            {nom: 'vaervityplet_dsc'   , largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vecsysinfcom'),
            new FormulaireItem('rvilibele'),
            new FormulaireItem('vaesysmnetab'),
            new FormulaireItem('rvilonele'),
            new FormulaireItem('rviflgfix'),
            new FormulaireItem('rviflgjus'),
            new FormulaireItem('vaervitypdon', { default: 3, direction: 'vertical'}),
            new FormulaireItem('vaervityplet', { default: 3, direction: 'vertical'})
        ], {largeurDefaut: 33.33})
    };
}
