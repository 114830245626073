import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';

interface IPageMr0243 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0243Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageMr0243 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true,
            importation: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('unpcleintcri', { required: true }),
            new FormulaireItem('mcfcleintcri'),
            new FormulaireItem('mtfcleintcri'),
            new FormulaireItem('demcleintcri'),
            new FormulaireItem('tagcleintcri'),
            new FormulaireItem('datfercri'),
            new FormulaireGroupe('LBL_ETAT', new Formulaire([
                new FormulaireItem('flgtrtcri', { largeur: 33 }),
                new FormulaireItem('flgexccri', { largeur: 33 }),
                new FormulaireItem('flgerrcri', { largeur: 33 })
            ]), { largeur: 50 })
        ], { largeurDefaut: 33 }),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'eplnomprn', largeur: 262 },
            { nom: 'rfrdatfer', largeur: 110 },
            { nom: 'tagcod', largeur: 110 },
            { nom: 'tagdscabr', largeur: 262 },
            { nom: 'actcod', largeur: 110 },
            { nom: 'actdsc', largeur: 262 },
            { nom: 'mtgflgcalsal', largeur: 100 },
            { nom: 'unpcod', largeur: 150 },
            { nom: 'unpdsc', largeur: 262 },
            { nom: 'lemcod', largeur: 120 },
            { nom: 'lemdsc', largeur: 262 },
            { nom: 'temcod', largeur: 120 },
            { nom: 'temdscabr', largeur: 262 },
            { nom: 'aftcod', largeur: 110 },
            { nom: 'aftdsc', largeur: 262 },
            { nom: 'imputation', largeur: 260 },
            { nom: 'mcfcod', largeur: 130 },
            { nom: 'mcfdsc', largeur: 262 },
            { nom: 'mtfnummth', largeur: 140 },
            { nom: 'mtfdsc', largeur: 262 }
        ],
        colonnesFixesDroite: [
            { nom: 'rfrmnt', largeur: 100 },
            { nom: 'rfrmntaju', largeur: 100 },
            { nom: 'rfrqte', largeur: 100 },
            { nom: 'rfrqteaju', largeur: 100 },
            { nom: 'rfrflgtrt', largeur: 76 },
            { nom: 'rfrflgexc', largeur: 76 },
            { nom: 'rfrflgerr', largeur: 76 },
            { nom: 'rfrflginv', largeur: 76 }
        ],
        colonnesDisponibles: [
            { nom: 'rfrjrs01', largeur: 100 },
            { nom: 'rfrjrs02', largeur: 100 },
            { nom: 'rfrjrs03', largeur: 100 },
            { nom: 'rfrjrs04', largeur: 100 },
            { nom: 'rfrjrs05', largeur: 100 },
            { nom: 'rfrjrs06', largeur: 100 },
            { nom: 'rfrjrs07', largeur: 100 },
            { nom: 'rfrjrs08', largeur: 100 },
            { nom: 'rfrjrs09', largeur: 100 },
            { nom: 'rfrjrs10', largeur: 100 },
            { nom: 'rfrjrs11', largeur: 100 },
            { nom: 'rfrjrs12', largeur: 100 },
            { nom: 'rfrjrs13', largeur: 100 },
            { nom: 'rfrjrs14', largeur: 100 },
            { nom: 'rfrjrs15', largeur: 100 },
            { nom: 'rfrjrs16', largeur: 100 },
            { nom: 'rfrjrs17', largeur: 100 },
            { nom: 'rfrjrs18', largeur: 100 },
            { nom: 'rfrjrs19', largeur: 100 },
            { nom: 'rfrjrs20', largeur: 100 },
            { nom: 'rfrjrs21', largeur: 100 },
            { nom: 'rfrjrs22', largeur: 100 },
            { nom: 'rfrjrs23', largeur: 100 },
            { nom: 'rfrjrs24', largeur: 100 },
            { nom: 'rfrjrs25', largeur: 100 },
            { nom: 'rfrjrs26', largeur: 100 },
            { nom: 'rfrjrs27', largeur: 100 },
            { nom: 'rfrjrs28', largeur: 100 },
            { nom: 'rfrjrs29', largeur: 100 },
            { nom: 'rfrjrs30', largeur: 100 },
            { nom: 'rfrjrs31', largeur: 100 },
            { nom: 'rfrjrs32', largeur: 100 },
            { nom: 'rfrjrs33', largeur: 100 },
            { nom: 'rfrjrs34', largeur: 100 },
            { nom: 'rfrjrs35', largeur: 100 }
        ],
        colonnesCachees: [
            'demcleint'
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_INVERSER',
                'BOUINV',
                {
                    selection: { nom: 'inverser' }
                })
        ]),
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', { largeur: 33 }),
            new FormulaireItem('unpcleint', { largeur: 33 }),
            new FormulaireItem('rfrdatfer', { largeur: 33 }),
            new FormulaireItem('actcleint', { largeur: 33, resourceParams: (data: any) => ({ rfrdatfer: data.rfrdatfer }) }),
            new FormulaireItem('tagcleint', { largeur: 33 }),
            new FormulaireItem('mtgflgcalsal', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lemcleint', { largeur: 33 }),
            new FormulaireItem('temcleint', { largeur: 33 }),
            new FormulaireItem('aftcleint', { largeur: 33 }),
            new FormulaireItem('t_imputation', { largeur: 33 }),
            new FormulaireItem('mcfcleint', { largeur: 33 }),
            new FormulaireItem('mtfcleint', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rfrmnt'),
            new FormulaireItem('rfrmntaju'),
            new FormulaireItem('rfrqte'),
            new FormulaireItem('rfrqteaju'),
            new FormulaireItem('rfrflgexc')
        ], { largeurDefaut: 25 })
    };
}
