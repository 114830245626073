import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireTemplateSlotClass } from "../../../../../core/services/formulaire/formulaire-template-slot.service";
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMR0175 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceAvcOptions: IMultiOccurrenceOptions;
    multiOccurrenceAvpOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mr0175Controller(Menu: IMenuClass,
    $stateParams: any,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageMR0175 = this;

    vm.multiOccurrenceAvpOptions = {
        bloc: 'avp',
        colonnesVisibles: [
            { nom: 'vaeavptyp', largeur: 180 },
            { nom: 'codref', largeur: 110 },
            { nom: 'refdsc', largeur: 250 },
            { nom: 'vaeavpimp', largeur: 180 },
            { nom: 'strcod', largeur: 110 },
            { nom: 'strdsc', largeur: 250 },
            { nom: 'avpvalnum', largeur: 120 },
            { nom: 'unccod', largeur: 110 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeavptyp', {
                largeur: 33
            }),
            new FormulaireItem('avpcleintref', {
                largeur: 33,
                resourceParams: (data: any) => ({ vaeavptyp: data.vaeavptyp }),
            }),
            new FormulaireItem('vaeavpimp', {
                largeur: 33
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('strcleint', {
                largeur: 33
            }),
            new FormulaireItem('avpvalnum', {
                largeur: 33
            }),
            new FormulaireItem('unccleint', {
                largeur: 33
            }),
            new FormulaireItem('vaesysmnetab', {
                hidden: true,
                largeur: 33
            })

        ], {
            largeurDefaut: 25
        })
    };

    vm.multiOccurrenceAvcOptions = {
        bloc: 'avc',
        colonnesVisibles: [
            { nom: 'avcdsc', largeur: 600 },
            { nom: 'avcclausemb', largeur: 200 },
            { nom: 'avcflgact', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('avcdsc'),
            new FormulaireItem('avcclausemb'),
            new FormulaireItem('avcflgact', {
                default: 1
            })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceAvpOptions
    };


    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'avscleint', valeur: $stateParams.reference, visible: false }
        ],
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_GENERER_IMPACT',
                'BOUREC',
                (data: any) => ({ avscleint: data.avscleint }),
                {
                    icon: 'sync',
                    successAction: () => { vm.multiOccurrence.fetchDataList(); },
                    messageConfirmation: 'BOUREC_MESSAGE'
                }
            ),
        ]),

        colonnesVisibles: [
            { nom: 'avsdsc', largeur: 600 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('avsdsc', {
                largeur: 75
            })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceAvcOptions
    };
}
