import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as2011',
    ecrans: ['00-2'],
    params:['?typchg',
            '?ttccleint',
            '?datvig',
            '?datfinout',
            '?usrcleintout',
            '?asgcleintgenout',
            '?ahtcleintgenout'
        ]
});
