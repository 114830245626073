import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePB0004 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ptiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ptiMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Pb0004Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    so0007Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    Pagination: IPaginationClass
) {
    const vm: IPagePB0004 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    vm.ptiMultiOccurrenceOptions = {
        bloc: 'pti',
        pagination: new Pagination({
            nombreElementPage: 6,
            nbElementsPossibles: [3, 6, 15]
        }),
        fonctions: {
            pagination: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 142 },
            { nom: 'eplnomprn', largeur: 160 }
        ],
        colonnesVisibles: [
            { nom: 'ptimntreesal', largeur: 110, total: true },
            { nom: 'ptimntreeavs', largeur: 110, total: true },
            { nom: 'ptimntprosal', largeur: 110, total: true },
            { nom: 'ptimntproavs', largeur: 110, total: true },
            { nom: 'ptimnttotree', largeur: 110, total: true },
            { nom: 'ptimnttotpro', largeur: 150, total: true },
            { nom: 'ptimnttotreepro', largeur: 140, total: true },
            { nom: 'nb_note', largeur: 100, total: true }
        ],
        colonnesCachees: ['pvecleint', 'pvpcleint', 'pppcleint']
    };
}
