import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageIN0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function In0010Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         in0008Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageIN0010 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: in0008Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(in0008Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'fimcod', largeur: 200},
            {nom: 'fimdsc', largeur: 200},
            {nom: 'st1cod', largeur: 200, hidden: (data) => data.$valeurs.flgst1 === 0},
            {nom: 'st1dsc', largeur: 200, hidden: (data) => data.$valeurs.flgst1 === 0},
            {nom: 'st2cod', largeur: 200, hidden: (data) => data.$valeurs.flgst2 === 0},
            {nom: 'st2dsc', largeur: 200, hidden: (data) => data.$valeurs.flgst2 === 0},
            {nom: 'st3cod', largeur: 200, hidden: (data) => data.$valeurs.flgst3 === 0},
            {nom: 'st3dsc', largeur: 200, hidden: (data) => data.$valeurs.flgst3 === 0},
            {nom: 'st4cod', largeur: 200, hidden: (data) => data.$valeurs.flgst4 === 0},
            {nom: 'st4dsc', largeur: 200, hidden: (data) => data.$valeurs.flgst4 === 0},
            {nom: 'st5cod', largeur: 200, hidden: (data) => data.$valeurs.flgst5 === 0},
            {nom: 'st5dsc', largeur: 200, hidden: (data) => data.$valeurs.flgst5 === 0},
            {nom: 'fimdatdeb', largeur: 120},
            {nom: 'fimdatfin', largeur: 120},
            {nom: 'fimqtemin', largeur: 120},
            {nom: 'fimqtemax', largeur: 120}
        ]
    };
}
