import {IComponentController} from 'angular';
import {IFonctionTransversaleService} from '../../../../core/services/fonction-transversale.service';
import {IMonoOccurrence} from '../../../../core/services/mono-occurrence.service';

export interface IComposantFonctionsTransversales extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    fonctionTransversale: IFonctionTransversaleService;
    getTitre(): string;
}

/* @ngInject */
export default function FonctionsTransversalesController(fonctionTransversale: IFonctionTransversaleService) {
    const vm: IComposantFonctionsTransversales = this;

    vm.$onInit = $onInit;
    vm.getTitre = getTitre;

    function $onInit() {
        vm.fonctionTransversale = fonctionTransversale;
    }

    function getTitre() {
        if (typeof vm.fonctionTransversale.fonctionTransversaleChoisie.titre === 'function') {
            return vm.fonctionTransversale.fonctionTransversaleChoisie.titre(vm.monoOccurrence);
        } else {
            return vm.fonctionTransversale.fonctionTransversaleChoisie.titre;
        }
    }
}
