import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageMR0281 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceINROptions: IMultiOccurrenceOptions;
    multiOccurrenceISROptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function MR0281Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Pagination: IPaginationClass) {
    const vm: IPageMR0281 = this;

    vm.multiOccurrenceISROptions = {
        bloc: 'isr',
        colonnesVisibles: [
            {nom: 'srvcod', largeur: 115},
            {nom: 'srvdsc', largeur: 280},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('srvcleint')
        ])
    };

    vm.multiOccurrenceINROptions = {
        bloc: 'inr',
        colonnesVisibles: [
            {nom: 'rolcod',    largeur: 115},
            {nom: 'roldscabr', largeur: 280},
            {nom: 'inrdatdeb', largeur: 115},
            {nom: 'inrdatfin', largeur: 115},
            {nom: 'inrflgres', largeur: 115}
        ],
        pagination: new Pagination({
            nombreElementPage: 4,
            nbElementsPossibles: [4, 10, 25]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('rolcleint'),
            new FormulaireItem('inrdatdeb'),
            new FormulaireItem('inrdatfin'),
            new FormulaireItem('inrflgres')
        ], {
            largeurDefaut: 20
        }),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceISROptions
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'intcod', largeur: 130},
            {nom: 'intnom', largeur: 350}
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('intcod', {
                largeur: 30
            }),
            new FormulaireItem('intnom', {
                largeur: 60
            })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceINROptions
    };
}
