import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageCP0043 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0043Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageCP0043 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_PERIODE'),
            new FormulaireItem('pecdeb', {
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('pecfin', {
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('datdeb', {
                largeur:25
            }),
            new FormulaireItem('datfin', {
                largeur:25
            }),
            new FormulaireItem('indcir', {
                largeur:25,
                default : 0,
                required: true
            }),
            new FormulaireItem('datfix', {
                largeur:25
            }),
            new FormulaireItemDivider('LBL_IMPUTATION'),
            new FormulaireItem('prucleint', {
            }),
            new FormulaireItem('prscleint', {
            }),
            new FormulaireItemDivider('LBL_ENTITE_EXT'),
            new FormulaireItem('rernomleg', {
            }),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                }
            }),
            new FormulaireItem('tfocleint', {
            }),
            new FormulaireItemDivider('LBL_REGLEMENT'),

            new FormulaireItem('vaetypsorfon', {
            }),
            new FormulaireItem('mopcleint', {
            }),
            new FormulaireItem('lorcleint', {
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('regindann', {
                default: ''
            }),
            new FormulaireItem('mntplsgrd', {
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'regnumdoc', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'lotcodnum', largeur: 200},
            {nom: 'vaetypsorfon', largeur: 200},
            {nom: 'etddsc_actuel', largeur: 200},
            {nom: 'rernomleg', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'regdat', largeur: 200},
            {nom: 'rermnt', largeur: 200},
            {nom: 'rermntcan', largeur: 200},
            {nom: 'rermntesc', largeur: 200},
            {nom: 'vaesysdevcod', largeur: 200},
            {nom: 'regnumchq', largeur: 200},
            {nom: 'regdatcci', largeur: 200},
            {nom: 'regdatprm', largeur: 200},
            {nom: 'cptcod', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'docaffnumdoc01', largeur: 200},
            {nom: 'imp', largeur: 200},
            {nom: 'mnt_approx', largeur: 200}
        ]
    };
}
