import {IComponentController} from 'angular';
import {IFormulaire} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';

interface IPageRr0165 extends IComponentController {
    formulaireDaf: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rceMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0165Controller($stateParams: any) {

    const vm: IPageRr0165 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.hasAccesListe1 = $stateParams.type;
        // DÉTAIL DES RCE
        vm.rceMultiOccurrenceOptions = {
            bloc: 'rce',
            colonnesFixesGauche: [
                {nom: 'rcecod', largeur: 100},
                {nom: 'rcedsc', largeur: 200}
            ],
            colonnesVisibles: [
                {nom: 'parmntemp', largeur: 126, total: true},
                {nom: 'parmntemr', largeur: 126, total: true},
                {nom: 'parmntadm', largeur: 126, total: true},
                {nom: 'parqteadm', largeur: 126, total: true},
                {nom: 'parmntarr', largeur: 126, total: true},
                {nom: 'parqtearr', largeur: 126, total: true},
                {nom: 'nomass'   , largeur: 200, hidden: () => (vm.monoOccurrence.data.type === 'DAF')}
            ]
        };
    }
}
