import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageRR0502 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0502Controller(Menu: IMenuClass,
    rr0503Route: IRoute,
    FormulaireItem: IFormulaireItemClass,
    Formulaire: IFormulaireClass,
    $stateParams: IStateParamsService) {
    const vm: IPageRR0502 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('unpcleint', {
                default: vm.multiOccurrence?.ecranDetails.valeurs.unpcleint
            }),
            new FormulaireItem('ppacleint', {
                resourceParams: (data: any) => ({ unpcleint: data.unpcleint }),
            }),
            new FormulaireItem('mopcleint')
        ]),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: !$stateParams.employe,
            effaceCriteres: $stateParams.employe,
            criteresSuggeresVisibles: !$stateParams.employe
        },
        navigatePage: rr0503Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe },
            { nom: 'ppacod', largeur: 90 },
            { nom: 'pammntbru', largeur: 126 },
            { nom: 'pammntrce', largeur: 126 },
            { nom: 'pammntnet', largeur: 126 },
            { nom: 'pamdat', largeur: 100 },
            { nom: 'pamnumdoc', largeur: 135 },
            { nom: 'pamnumdocseq', largeur: 90 },
            { nom: 'pamnumdocoff', largeur: 135 },
            { nom: 'unpcod', largeur: 90 },
            { nom: 'unpdsc', largeur: 262 },
            { nom: 'ppadatdeb', largeur: 100 },
            { nom: 'ppadatfin', largeur: 100 },
            { nom: 'pammntavi', largeur: 126 },
            { nom: 'mopcod', largeur: 94 },
            { nom: 'mopdsc', largeur: 262 },
            { nom: 'pamnumchqphy', largeur: 120 },
            { nom: 'vaepamprv_dsc', largeur: 197 },
            { nom: 'pamnumdoc_ann', largeur: 138 },
            { nom: 'pamnumdocseq_ann', largeur: 110 },
            { nom: 'pamdat_ann', largeur: 110 },
            { nom: 'pamnbrtalchq', largeur: 90 },
            { nom: 'pamflgoff', largeur: 76 },
            { nom: 'pamflgimp', largeur: 91 },
            { nom: 'pamflgtrftfe', largeur: 91 },
            { nom: 'pamflgfinann', largeur: 75 },
            { nom: 'pamdatcon', largeur: 130 },
            { nom: 'pamnumtrfcon', largeur: 130 },
            { nom: 'flag_ann', largeur: 75 },
            { nom: 'pamanncivexc', largeur: 110 }
        ]
    };
}
