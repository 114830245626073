import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageExterneClass } from "../../../../../core/services/menu/menu-item-forage-externe.service";


interface IPageSo0005 extends IComponentController {
    formulaireUno: IFormulaire;
    formulaireInfoFin: IFormulaire;
}

/* @ngInject */
export default function So0005Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForageExterne: IMenuItemForageExterneClass) {

    const vm: IPageSo0005 = this;
    vm.formulaireUno = new Formulaire('LBL_BLC_UNO', [
        new FormulaireItem('tyucleint'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('unocod', { largeur: 33 }),
        new FormulaireItem('unodatdeb', { largeur: 33 }),
        new FormulaireItem('unodatfin', { largeur: 33 }),
        new FormulaireItem('unodsc', {
            historiqueColonne: true,
            modelOptions: {
                updateOn: 'blur'
            },
            required: true
        }),
        new FormulaireItem('unodscabr'),
        new FormulaireItem('cincleint'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('unocleintsup', {
            largeur: 100, resourceParams: (data: any) => {
                return { unocleint: data.unocleint };
            }
        }),
        new FormulaireItem('unoadrweb', { largeur: 100, menu: [new MenuItemForageExterne((data) => data.unoadrweb, {})] }),
        new FormulaireItem('unoadrcou', { largeur: 100, historiqueColonne: true }),
        new FormulaireItem('unosigaut', { largeur: 100 }),
        new FormulaireItem('intcleint', { largeur: 100 })
    ]);

    // Infos financières
    vm.formulaireInfoFin = new Formulaire('LBL_BLC_INF', [
        new FormulaireItem('unacleint'),
        new FormulaireItem('prucleint')
    ], { largeurDefaut: 100 });
}
