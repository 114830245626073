import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageGs0022 extends IComponentController {
    valeursUsr: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireUsr: IFormulaire;
    formulaireUsq: IFormulaire;
    pavMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0022Controller(Formulaire: IFormulaireClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass ) {
    const vm: IPageGs0022 = this;

    vm.$onInit = $onInit;

    vm.valeursUsr = ecranDetails.valeurs;

    // Changement de mot de passe
    vm.actionModifPassword = new MenuItemAction(
        'LBL_BTN_MODIF_PASSWORD',
        'BOUMODPWD',
        () => ({}),
        {
            icon: 'security',
            iconButton: false,
            successAction: () => {
                vm.monoOccurrence.fetchData();
            },
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_MODIF_PASSWORD',
                lblConfirm: 'LBL_BTN_MODIFICATION',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('t_old_pwd', { required: true, maxlength: 128 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('t_new_pwd', { required: true, maxlength: 128 }),
                    new FormulaireItem('t_con_pwd', { required: true, maxlength: 128 }),
                    new FormulaireItem('texte_aide', { largeur: 100, default: vm.valeursUsr.texte_aide })
                ], {largeurDefaut: 50})
            }
        }
    );

    // Changement des questions
    vm.actionModifQuestion = new MenuItemAction(
        'LBL_BTN_QST',
        'BOUMODQST',
        () => ({}),
        {
            icon: 'question_answer',
            iconButton: false,
            successAction: () => {
                vm.monoOccurrence.fetchData();
            },
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_QST',
                lblConfirm: 'LBL_BTN_CNG_QST',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('t_usqcleint1', { default: vm.valeursUsr.t_usqcleint1 }),
                    new FormulaireItem('t_usrrep1', { default: vm.valeursUsr.t_usrrep1 }),
                    new FormulaireItem('t_usqcleint2', { default: vm.valeursUsr.t_usqcleint2 }),
                    new FormulaireItem('t_usrrep2', { default: vm.valeursUsr.t_usrrep2 }),
                    new FormulaireItem('t_usqcleint3', { default: vm.valeursUsr.t_usqcleint3 }),
                    new FormulaireItem('t_usrrep3', { default: vm.valeursUsr.t_usrrep3 })
                ], {largeurDefaut: 50})
            },
            hidden: () => (!vm.valeursUsr.flgqst)
        }
    );

    function $onInit() {
        vm.monoOccurrenceOptions = {
            noId: true,
            formulaire: new Formulaire('LBL_BLC_ID', []
            )
        };

        // Usager
        vm.formulaireUsr = new Formulaire('LBL_BLC_USR', [
            new FormulaireItem('impcleint'),
        //   new FormulaireItem('vaeusrlng'),
            new FormulaireItem('usrsigaut', {largeur: 100, enableCount: true})
        ]);

        // Paramètre système
        vm.pavMultiOccurrenceOptions = {
            bloc: 'pav',
            colonnesVisibles: [
                {nom: 'pasdsc', largeur: 350},
                {nom: 'pavval', largeur: 250}
            ],
            colonnesCachees: [
                'pasnomeleval',
                'pasflgusrmod'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pascleint'),
                new FormulaireItem('pavval'),
                new FormulaireItem('pavvalele', {resourceParams: (data:any) => ({ vaeelecle: data.pasnomeleval }) })
            ], {largeurDefaut: 100})
        };
    }
}
