import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMC0066 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mc0066Controller(Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMC0066 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemAction(
            'G_LBL_BTN_AJOUTER',
            'BOURENUM',
            () => ({}),
            {
                successAction:() => {
                    vm.multiOccurrence.fetchDataList();
                },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_NOUV_RENUMEROTATION',
                    lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                    largeurFormulaire: 75,
                    formulaire: new Formulaire([
                        new FormulaireItem('ssccleint'),
                        new FormulaireItem('sscnumdeb', {largeur: 17}),
                        new FormulaireItem('sscnumfin', {largeur: 17}),
                        new FormulaireItem('sscflgstu'),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('scqnumchqde'),
                        new FormulaireItem('scqnumchqa'),
                        new FormulaireItem('scqnumchqdep')
                    ], {largeurDefaut: 33})
                }
            })
        ]),
        colonnesVisibles: [
            {nom: 'scbfol', largeur: 215},
            {nom: 'sscnumdeb', largeur: 105},
            {nom: 'sscnumfin', largeur: 105},
            {nom: 'sscflgstu', largeur: 65},
            {nom: 'scqnumchqde', largeur: 105},
            {nom: 'scqnumchqa', largeur: 105},
            {nom: 'scqnumchqdep', largeur: 105},
            {nom: 'datcre', largeur: 160},
            {nom: 'usrnom', largeur: 325}
        ]
    };
}
