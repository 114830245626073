import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'do0093',
    ecrans: [
        '00-2',
        '01-2'
    ],
    params: [
        '?offcleint',
        '?ctpcleint',
        '?cvccleint'
    ]
});
