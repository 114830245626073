import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageMr0219 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    MultiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mr0219Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0219 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            selectionCycle: false
        },
        colonnesVisibles: [
            { nom: 'hypseq', largeur: 100 },
            { nom: 'hyplib', largeur: 190 },
            { nom: 'hypurl', largeur: 190 },
            { nom: 'vaedsc', largeur: 130 }
        ],
        colonnesCachees: ['hyppcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('hypseq', { largeur: 10 }),
            new FormulaireItem('hyplib', { largeur: 30 }),
            new FormulaireItem('hypurl', { default: 'http://', largeur: 30 }),
            new FormulaireItem('vaehypregaff', { largeur: 20 })
        ])
    };
}
