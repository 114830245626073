import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
//import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCC0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0016Controller(
    Menu: IMenuClass,
    //cc0017Route: IRoute
    ) {
    const vm: IPageCC0016 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: cc0017Route.NAME,
        actionsNouveaux: new Menu([]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cadcod', largeur: 89},
            {nom: 'pagcod', largeur: 120},
            {nom: 'pagdsc', largeur: 210},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 210},
            {nom: 'dmenomprn', largeur: 210},
            {nom: 'cadcom', largeur: 262}
        ]
    };
}
