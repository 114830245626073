import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0102 extends IComponentController {
    formulaireCvt: IFormulaire,
    daeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cssMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cstMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cvbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0102Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    mr0305Route: IRoute,
    mr0238Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0102 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            actionsMore: new Menu([
                new MenuItemForage(
                    'LBL_MR0238',
                    mr0238Route.NAME,
                    (data: any) => ({
                        vaetypfnd: 'CVT',
                        cleintdet: data.cvtcleint
                    }),
                    {
                        icon: 'assessment',
                        iconButton: false
                    }
                )
            ]),
            formulaire: new Formulaire('LBL_BLC_CVT', [
                new FormulaireItem('cvtcod', { largeur: 25 }),
                new FormulaireItem('cvtdsc', { largeur: 50 }),
                new FormulaireItem('cvtflgact', { largeur: 25, default: 1 }),
                new FormulaireItem('vaecvttypech', { largeur: 25 }),
                new FormulaireItem('local', { largeur: 25 }),
                new FormulaireItem('cvtdscdet', { largeur: 100 })
            ])
        };

        // DATE ÉCHELLE (PM2)
        vm.daeMultiOccurrenceOptions = {
            bloc: 'dae',
            fonctions: {
                pagination: true,
                importation: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),

            colonnesVisibles: [
                { nom: 'daedatvig', largeur: 100 },
                { nom: 'daedsc', largeur: 100 }
            ],
            formulaire: new Formulaire('LBL_BLC_DAE', [
                new FormulaireItem('daedatvig', { largeur: 50 }),
                new FormulaireItem('daedsc', { largeur: 50 })
            ])
        };

        // STATUT CONVENTION (PM2)
        vm.cssMultiOccurrenceOptions = {
            bloc: 'css',
            fonctions: {
                pagination: true,
                importation: true
            },
            pagination: new Pagination({
                nombreElementPage: 4,
                nbElementsPossibles: [4, 10, 25]
            }),

            colonnesVisibles: [
                { nom: 'strcod', largeur: 100 },
                { nom: 'strdsc', largeur: 100 },
                { nom: 'cssflgdef', largeur: 100 },
                { nom: 'cssflgact', largeur: 100 },
                { nom: 'vaecsstypdsc', largeur: 100 }
            ],
            colonnesCachees: [
                'vaecssdsc'
            ],
            formulaire: new Formulaire('LBL_BLC_CSS', [
                new FormulaireItem('strcleint'),
                new FormulaireItem('vaecsstyp', { largeur: 50 }),
                new FormulaireItem('cssflgdef', { largeur: 50 }),
                new FormulaireItem('cssflgact', { largeur: 50, default: 1 })

            ])
        };

        // TYPE AFFECTATION (PM1)
        vm.cstMultiOccurrenceOptions = {
            bloc: 'cst',
            fonctions: {
                importation: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'strcod', largeur: 80 },
                { nom: 'strdsc', largeur: 250 },
                { nom: 'tafcod', largeur: 140 },
                { nom: 'tafdsc', largeur: 250 },
                { nom: 'vaedsc', largeur: 175 }
            ],

            formulaire: new Formulaire([
                new FormulaireItem('strcleint', {
                    largeur: 33.3, required: true,
                    resourceParams: () => {
                        return { cvtcleint: vm.monoOccurrence.data.cvtcleint };
                    }
                }),
                new FormulaireItem('tafcleint', { largeur: 33.3, required: true }),
                new FormulaireItem('vaecsttyp', { largeur: 33.3, required: true }),
            ])
        };

        // BANQUE (PM2)
        vm.cvbMultiOccurrenceOptions = {
            bloc: 'cvb',
            navigatePage: mr0305Route.NAME,
            actionsNouveaux: new Menu([
                new MenuItemForage(mr0305Route.NAME, () => {
                    return {
                        cvtcleint: vm.monoOccurrence.data.cvtcleint
                    };
                })
            ]),
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 10,
                nbElementsPossibles: [10, 15, 25]
            }),

            colonnesVisibles: [
                { nom: 'cvbcoddsc', largeur: 100 },
                { nom: 'datdebfin', largeur: 100 },
                { nom: 'cvbqte', largeur: 100 },
                { nom: 'uncdsc', largeur: 100 }
            ]
        };
    }
}
