import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageGb0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0003Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass
) {
    const vm: IPageGb0003 = this;

    vm.multiOccurrenceOptions = {
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_TRANS', 'BOUTRANS', {
                selection: {
                    nom: 'transfert',
                    lblConfirmationTitre: 'LBL_BTN_TRANS',
                    largeurFormulaire: 40,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('intcleintdeptra',   {largeur: 100, required: true}),
                        new FormulaireItem('lolcleinttra',      {largeur: 100, required: true}),
                        new FormulaireItem('unacleinttra',      {largeur: 100, required: true}),
                        new FormulaireItem('gbpcleinttra',      {largeur: 100, required: true}),
                        new FormulaireItem('gebcom',            {largeur: 100, required: true})
                    ], {largeurDefaut: 50})
                }
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleintdep'),
            new FormulaireItem('unacleint'),
            new FormulaireItem('lolcleint'),
            new FormulaireItem('gbpcleintcri')
        ]),
        colonnesFixesGauche: [
            { nom: 'gebcod', largeur: 80}
        ],
        colonnesVisibles: [
            { nom: 'gebdsc', largeur: 280 },
            { nom: 'intcoddep', largeur: 110 },
            { nom: 'intnomdep', largeur: 150 },
            { nom: 'lolnumloc', largeur: 80 },
            { nom: 'loldsc', largeur: 140 },
            { nom: 'unacod', largeur: 80 },
            { nom: 'unadsc', largeur: 200 },
            { nom: 'gbpcod', largeur: 130 },
            { nom: 'gbpdsc', largeur: 210 }
        ]
    };
}
