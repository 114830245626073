import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAc0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0009Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {

                                            
    const vm: IPageAc0009 = this; 
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'teccod', largeur: 70},
            {nom: 'tecdsclng', largeur:350},
            {nom: 'tecdscabr', largeur: 162},
            {nom: 'tecflgman', largeur: 100},
            {nom: 'tecflgmag', largeur: 88},
            {nom: 'tecflgfac', largeur: 106}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('teccod', {largeur: 20}),
            new FormulaireItem('tecdsclng', {largeur: 40}),
            new FormulaireItem('tecdscabr', {largeur: 40}),
            new FormulaireItem('tecflgman', {default: 0, largeur: 20}),
            new FormulaireItem('tecflgmag', {default: 0, largeur: 20}),
            new FormulaireItem('tecflgfac', {default: 0, largeur: 20})
        ]),
        fonctions:{
            rechercheCollapsable: false
        }
    };
}