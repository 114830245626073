import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCr1003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr1003Controller(
    $stateParams: any,
    Formulaire: IFormulaireClass,
    cr1007Route: IRoute,
    cr1004Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr1003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtresParametres: [
            { colonne: 'ccecleint', valeur: $stateParams.ccecleint, visible: false }
        ],
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_SUIVI',
                cr1007Route.NAME,
                (data: any) => ({
                    coccleint: data.coccleint
                }),
                {
                    icon: 'info_outline',
                    fonction: 'BOUHIS'
                }
            ),
            new MenuItemForage(
                'LBL_EVEN',
                cr1004Route.NAME,
                (data: any) => ({
                    coccleint: data.coccleint
                }),
                {
                    icon: 'info_outline',
                    fonction: 'BOUEVE'
                }
            )
        ]),
        formulaire: new Formulaire([
            new FormulaireItem('ccocleint'),
            new FormulaireItem('cocdat'),
            new FormulaireItem('intcleint'),
            new FormulaireItem('coccom'),
            new FormulaireItem('ccecleint', { hidden: true, default: $stateParams.ccecleint })
        ], { largeurDefaut: 25 }),
        colonnesVisibles: [
            { nom: 'ccocod', largeur: 100 },
            { nom: 'flglib', largeur: 100 },
            { nom: 'ccodsc', largeur: 190 },
            { nom: 'cocdat', largeur: 130 },
            { nom: 'intcod', largeur: 110 },
            { nom: 'intnom', largeur: 180 },
            { nom: 'coccom', largeur: 200 }
        ],
        colonnesCachees: ['coccleint'],
    };
}
