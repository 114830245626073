import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAc0025 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dorMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dorMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Ac0025Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    exDateFilter: IFilterExDate ) {
    const vm: IPageAc0025 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            exportation: false,
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_RELANCE',
                'BOUREL',
                () => ({
                }),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    fonction: 'BOUREL',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_GENERE',
                        lblConfirm: 'G_LBL_EXECUTER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('vecdortyprelnew', { 
                                largeur: 50, 
                                required: true 
                            }),
                            new FormulaireItem('dordatnew', { 
                                largeur: 50, 
                                required: true,
                                default: exDateFilter(new Date())
                            }),
                            new FormulaireItem('dornotnew', { 
                                largeur: 100, 
                                required: true 
                            })
                        ])
                    },
                    disabled: () => !vm.valeursEcran.flgrel,
                    successAction: () => { 
                        vm.dorMultiOccurrence.fetchDataList();
                    }
                }
            ),
        ]
    };

    vm.dorMultiOccurrenceOptions = {
        bloc: 'dor',
        colonnesVisibles: [
            { nom: 'dordat', largeur: 120 },
            { nom: 'dornot', largeur: 250 },
            { nom: 'usrcod', largeur: 126 },
            { nom: 'usrnom', largeur: 180 },
            { nom: 'vecdsc', largeur: 150 },
            { nom: 'dorseqaff', largeur: 120 }
        ]
    };
}
