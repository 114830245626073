import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';


interface IPageAC0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0026Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass
) {
    const vm: IPageAC0026 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleint', { largeur: 50 }),
            new FormulaireItem('vecdaccatcri', { largeur: 50 }),
            new FormulaireItem('intcodachnum', { largeur: 50 }),
            new FormulaireItem('tydcleintcri', { largeur: 50 }),
            new FormulaireItem('intcodreqnum', { largeur: 50 }),
            new FormulaireItem('caacleint', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_CRI_RCG'),
            new FormulaireItem('recuhcdsc', { largeur: 50 }),
            new FormulaireItem('recuhcnumcat', { largeur: 50 })
        ]),
        fonctions: {
            criteresSuggeresVisibles: true,
            criteresRechercheCollapsable: false,
            criteresRechercheOpened : true,
            rechercheCollapsable: false,
            effaceCriteres : true,
            edition: false,
            supprime: false
        },
        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 200 },
            { nom: 'dacnumdocseq', largeur: 100 },
            { nom: 'sysboncmd', largeur: 200 },
            { nom: 'tydcod', largeur: 100 },
            { nom: 'uhcdat', largeur: 200 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'refcod', largeur: 200 },
            { nom: 'refnom', largeur: 300 },
            { nom: 'etcnumdoc', largeur: 200 },
            { nom: 'etcnumdocseq', largeur: 70 },
            { nom: 'caadsc', largeur: 200 },
            { nom: 'intcodreq', largeur: 100 },
            { nom: 'intnomreq', largeur: 200 },
            { nom: 'intcodach', largeur: 100 },
            { nom: 'intnomach', largeur: 200 },
            { nom: 'vecdaccat', largeur: 70 }
        ]
    };
};
