import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IController } from 'angular';
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMr2018 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceOvnOptions: IMultiOccurrenceOptions;
    multioccurrence: IMultiOccurrence;
}
interface IPageMr2018Params {
    ovdcleintref: string;
}

/* @ngInject */
export default function Mr2018Controller(
    $stateParams: IPageMr2018Params,
    mr0176Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemEcran: IMenuItemEcranClass,
    Menu: IMenuClass,
) {
    const vm: IPageMr2018 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;
    function $onInit() {
        vm.multiOccurrenceOptions.fonctions.recherche = !$stateParams.ovdcleintref;
    };

    vm.multiOccurrenceOvnOptions = {
        bloc: 'ovn',
        colonnesVisibles: [
            { nom: 'rsccod', largeur: 100 },
                { nom: 'imp', largeur: 250 },
                { nom: 'ovnprc', largeur: 100 },
                { nom: 'ovnmnt', largeur: 120 }
        ]
    };

    vm.multiOccurrenceOptions = {

        filtresParametres: [{
            colonne: 'ovdcleintref',
            visible: false
        }],
        actionsNouveaux: new Menu([
            new MenuItemEcran('', mr0176Route.NAME, () => ({
                ovdcleintref:  $stateParams.ovdcleintref,
                stamnetab: 'RCE'
            }), {

                onClose() {
                    if (vm.multiOccurrence){vm.multioccurrence.fetchDataList()}
                    
                }
            })
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_IMPUTATION',
                mr0176Route.NAME,
                (data: any) => {
                    return {
                        stamnetab: 'RCE',
                        ovdcleintref: data.ovdcleintref,
                        id: data.ovdcleint
                    };
                },
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),

        colonnesVisibles: [
            { nom: 'intcod', largeur: 8 },
            { nom: 'intnom', largeur: 120 },
            { nom: 'ovddatvig', largeur: 10 },
            { nom: 'ovddatfin', largeur: 10 }
        ],
        colonnesCachees:[
            'ovdcleint',
            'ovdcleintref'
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceOvnOptions
    };
}
