import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAs0058 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0058Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAs0058 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'unpcod', largeur: 91},
            {nom: 'unpdsc', largeur: 262},
            {nom: 'ptacod', largeur: 80}
        ]
    };
}
