import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGS2001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs2001Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGS2001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tpicod', largeur: 100 },
            { nom: 'tpidsc', largeur: 200 },
            { nom: 'tpiflgsec', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem("tpicod"),
            new FormulaireItem("tpidsc"),
            new FormulaireItem("tpiflgsec")
        ])
    }
}
