import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMr0071 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0071Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass)
{
    const vm: IPageMr0071 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'swccod', largeur: 120}
        ],
        colonnesVisibles: [
            {nom: 'swecod', largeur: 180},
            {nom: 'swedsc', largeur: 250},
            {nom: 'swcdsc', largeur: 250},
            {nom: 'vaeswctyp_dsc', largeur: 130},
            {nom: 'swcenp', largeur: 200},
            {nom: 'swcmth', largeur: 200},
            {nom: 'swcnsp', largeur: 200},
            {nom: 'swcxpa', largeur: 200},
            {nom: 'swcusr', largeur: 130},
            {nom: 'swcpwd', largeur: 130},
            {nom: 'swcclesec', largeur: 180},
            {nom: 'swcurlsec', largeur: 200},
            {nom: 'swccom', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('swecleint', {largeur: 25, modifAllow: false}),
            new FormulaireItem('swccod', {largeur: 25}),
            new FormulaireItem('swcdsc', {largeur: 50}),
            new FormulaireItem('vaeswctyp'),
            new FormulaireItem('swcenp'),
            new FormulaireItem('swcmth'),
            new FormulaireItem('swcnsp'),
            new FormulaireItem('swcxpa'),
            new FormulaireItem('swcusr'),
            new FormulaireItem('swcpwd'),
            new FormulaireItem('swcclesec'),
            new FormulaireItem('swcurlsec'),
            new FormulaireItem('swccom', {largeur: 100, enableCount: true}),
        ])
    };
}
