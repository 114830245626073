import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0003 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0003Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0003 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaesysnatctb', largeur: 115},
            {nom: 'vaesysnatctb_dsc', largeur: 157},
            {nom: 'nacborinf', largeur: 115},
            {nom: 'nacborsup', largeur: 115},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesysnatctb'),
            new FormulaireItem('nacborinf'),
            new FormulaireItem('nacborsup')
        ], {largeurDefaut: 25})
    };
}
