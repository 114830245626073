import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPagePD0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0013Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPagePD0013 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pmicleint', {
                required: false
            }),
            new FormulaireItem('loccleint', {
                required: false
            }),
            new FormulaireItem('raicleint', {
                required: false
            }),
            new FormulaireItem('intcleint', {
                required: false
            }),
            new FormulaireItem('dsclng', {
                required: false
            }),
            new FormulaireItem('vaeraitypsrc', {
                required: false
            }),
            new FormulaireItem('flgeli', {
                default: '',
                required: true
            }),
            new FormulaireItem('typerec', {
                default: 'REC',
                required: true
            }),
            new FormulaireItemDivider('LBL_DATE_ACQ'),
            new FormulaireItem('datdeb', {
                required: false
            }),
            new FormulaireItem('datfin', {
                required: false
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'phinumdoc', largeur: 200}
        ],

        // Colonnes fixe à droite
        colonnesFixesDroite: [
            {nom: 'phidateta', largeur: 200},
            {nom: 'phiflgeli', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'numcat', largeur: 200},
            {nom: 'dsclng', largeur: 200},
            {nom: 'raicod', largeur: 200},
            {nom: 'vaeraitypsrc_dsc', largeur: 200},
            {nom: 'raidsc', largeur: 200},
            {nom: 'loccod', largeur: 200},
            {nom: 'locdsc', largeur: 200},
            {nom: 'pmicod', largeur: 200},
            {nom: 'pmidsc', largeur: 200},
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'phiqteactree', largeur: 200},
            {nom: 'phiqteactmax', largeur: 200},
            {nom: 'phidatacq', largeur: 200},
            {nom: 'phinumser', largeur: 200},
            {nom: 'phifab', largeur: 200},
            {nom: 'phimod', largeur: 200}
        ]
    };
}
