import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageGl0044 extends IComponentController {
    valeursEcran: any;
    eflFormulaire: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    eveMultiOccurrence: IMultiOccurrence;
    eveMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IGl0044Params {
    id: string;
    eficleint: number;
}

/* @ngInject */
export default function Gl0044Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IGl0044Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageGl0044 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Ligne / saisie
        vm.eflFormulaire = new Formulaire('LBL_SAISIE_LIGNE', [
            new FormulaireItem('eflnumlig', { largeur: 20 }),
            new FormulaireItem('vaeefltyp', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('efltxt', { largeur: 60 }),
            new FormulaireItem('vaeefltypimp', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eflfor', { largeur: 80, enableCount: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eficleint', { hidden: true, default: $stateParams.eficleint })
        ], {
            largeurDefaut: 20
        });

        // imputation
        vm.eveMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'eveciecoddeb', largeur: 120 },
                { nom: 'impdeb', largeur: 260 },
                { nom: 'eveciecodfin', largeur: 120 },
                { nom: 'impfin', largeur: 260 },
                { nom: 'eveope', largeur: 120 },
                { nom: 'eveflgcif', largeur: 70, hidden: (data) => data.$valeurs.flgcif===0},
                { nom: 'eveflgcpt', largeur: 70, hidden: (data) => data.$valeurs.flgcpt===0},
                { nom: 'eveflgel1', largeur: 70, hidden: (data) => data.$valeurs.flgel1===0},
                { nom: 'eveflgel2', largeur: 70, hidden: (data) => data.$valeurs.flgel2===0},
                { nom: 'eveflgel3', largeur: 70, hidden: (data) => data.$valeurs.flgel3===0},
                { nom: 'eveflgprs', largeur: 70, hidden: (data) => data.$valeurs.flgprs===0},
                { nom: 'eveflgpru', largeur: 70, hidden: (data) => data.$valeurs.flgpru===0},
                { nom: 'eveflguna', largeur: 70, hidden: (data) => data.$valeurs.flguna===0}
            ],
            colonnesCachees: [
                'eflcleint'
            ],
            formulaire: new Formulaire('LBL_SAISIE_VENTILATIONS', [
                new FormulaireItemDivider('LBL_DE'),
                new FormulaireItem('eveciecoddeb', { largeur: 15 }),
                new FormulaireItem('impdeb', { largeur: 35 }),
                new FormulaireItemDivider('LBL_A'),
                new FormulaireItem('eveciecodfin', { largeur: 15 }),
                new FormulaireItem('impfin', { largeur: 35 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eveope', { largeur: 35, default: '+' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eveflgcif', { largeur: 10 }),
                new FormulaireItem('eveflgcpt', { largeur: 10 }),
                new FormulaireItem('eveflgel1', { largeur: 10 }),
                new FormulaireItem('eveflgel2', { largeur: 10 }),
                new FormulaireItem('eveflgel3', { largeur: 10 }),
                new FormulaireItem('eveflgprs', { largeur: 10 }),
                new FormulaireItem('eveflgpru', { largeur: 10 }),
                new FormulaireItem('eveflguna', { largeur: 10 })
            ], { largeurDefaut: 25 })
        };
    }
}
