import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';

interface IPageGS0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
interface IGs0020Params {
    
}
/* @ngInject */
export default function Gs0020Controller(gs0122Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    $stateParams: IGs0020Params) {
    const vm: IPageGS0020 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0122Route.NAME,
        navigateParams: () => {
            return {
                aplcod: ecranDetails.params.p_aplcod
            };
        },
        actionsMore:new Menu([
                new MenuItem('TELECHARGER_PATRON', () => {
                    downloadManager.directDownload('/gs0020/patronpsf', {}, $stateParams, ecranDetails);
                }, {
                    icon: 'file_download'
                })
            ]),
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0122Route.NAME, () => {
                return {
                    aplcod: ecranDetails.params.p_aplcod
                };
            })]),
        colonnesFixesGauche: [
            { nom: 'pradsc', largeur: 250 }
        ],
        colonnesVisibles: [
            { nom: 'pradscmnu', largeur: 250 },
            { nom: 'praflgemp', largeur: 100 },
            { nom: 'pradscemp', largeur: 250 },
            { nom: 'praindpri', largeur: 100 },
            { nom: 'pradscpri', largeur: 250 }
        ],
        colonnesFixesDroite: [
            { nom: 'vaesysapp_dsc', largeur: 160 }
        ]
    };
}
