import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0146 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0146Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0146 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'pysnomfra', largeur: 180},
            {nom: 'etacod', largeur: 65},
            {nom: 'etanomfra', largeur: 180},
            {nom: 'etanomang', largeur: 180},
            {nom: 'etaflgdef', largeur: 76},
            {nom: 'etaflgvil', largeur: 76}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pyscleint', {largeur: 25, required: true}),
            new FormulaireItem('etacod'   , {largeur: 25, required: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etanomfra', {largeur: 25, required: true}),
            new FormulaireItem('etanomang', {largeur: 25}),
            new FormulaireItem('etaflgdef', {largeur: 25}),
            new FormulaireItem('etaflgvil', {largeur: 25})
            ]
        )
    };
}
