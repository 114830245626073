import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageSO0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0022Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSO0022 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'sefdsc', largeur: 355},
            {nom: 'sefflgact', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('sefdsc', {required: true, largeur: 75}),
            new FormulaireItem('sefflgact', {required: true, largeur: 25, default: 1})
        ])
    };
}
