import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGS0068 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0068Controller(gs0069Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0068 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0069Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0069Route.NAME)
        ]),
        colonnesVisibles: [
            { nom: 'avedatavi', largeur: 100 },
            { nom: 'avesuj', largeur: 200 },
            { nom: 'avetxt', largeur: 400 },
            { nom: 'aveflgpor', largeur: 78 },
            { nom: 'aveflgcou', largeur: 82 },
            { nom: 'aveflgdom', largeur: 92 },
            { nom: 'aveflgprtenv', largeur: 85 }
        ]
    };
}
