import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0002Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0002 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'anscod', largeur: 115 },
            { nom: 'ansdatdeb', largeur: 115 },
            { nom: 'ansdatfin', largeur: 115 },
            { nom: 'ansdatmod', largeur: 289 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('anscod'),
            new FormulaireItem('ansdatdeb'),
            new FormulaireItem('ansdatfin'),
            new FormulaireItem('ansdatmod')
        ], {
            largeurDefaut: 25
        }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
