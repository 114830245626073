import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRr0547 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0547Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         $stateParams: IStateParamsService) {
    const vm: IPageRr0547 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            suiviModification: false,
            criteresSuggeresVisibles: !$stateParams.employe
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('flg_exc', {default: 1 }),
            new FormulaireItem('flg_lec', {default: 2 })
        ]),
        // Colonnes fixe à gauche
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],

        // Colonnes fixe à droite
        colonnesFixesDroite: [
            {nom: 'polflgact', largeur: 65},
            {nom: 'pomflgacc', largeur: 45}
        ],
        colonnesVisibles: [
            {nom: 'polcod', largeur: 90},
            {nom: 'poldsc', largeur: 333},
            {nom: 'poldatdif', largeur: 100},
            {nom: 'poldatina', largeur: 115}
        ]
    };
}
