import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMr0212 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ovdMultiOccurrence: IMultiOccurrence;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface Mr0212Params {
    mobcleint: number;
    mobcleintref: number;
    stamnetab: string;
}

/* @ngInject */
export default function Mr0212Controller(mr0176Route: IRoute,
    mr0205Route: IRoute,
    $stateParams: Mr0212Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    Pagination: IPaginationClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0212 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            menus: [new MenuItemForage("Ventilations", mr0205Route.NAME, (data) => ({
                id: data.mobcleint,
                stamnetab: "MOB"
            }), {
                icon: 'subtitles',
                iconButton: false,
                secondaire: true,
                hidden: !vm.valeursEcran.flgdne
            })],
            cycleErrorReloadBlocs: ['mob'],
            formulaire: new Formulaire('LBL_CHANGEMENT', [
                new FormulaireItem('mobcleint', { hidden: true, default: $stateParams.mobcleint }),
                new FormulaireItem('mobcleintref', { hidden: true, default: $stateParams.mobcleintref }),
                new FormulaireItem('stamnetab', { hidden: true, default: $stateParams.stamnetab }),
                new FormulaireItem('flact', { hidden: true }),
                new FormulaireItem('nom', { readonly: true, default: vm.valeursEcran.nom_cont, largeur: 50}),
                new FormulaireItem('eplmatnom', { readonly: true, default: vm.valeursEcran.eplmatnom, largeur: 50}),
                new FormulaireItem('datdeb', { readonly: true, default: vm.valeursEcran.datdeb, largeur: 50}),
                new FormulaireItem('datfin', { readonly: true, default: vm.valeursEcran.datfin, largeur: 50}),
                new FormulaireItemDivider(''),
                new FormulaireItem('mobnumdoc'),
                new FormulaireItemSautDeLigne,
                new FormulaireItem('tydcleint', { resourceParams: () => ({ stamnetab: $stateParams.stamnetab }), largeur: 50}),
                new FormulaireItem('mobdat'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('mobdsc', { required: true, largeur: 100 })
            ], { largeurDefaut: 33.33 })
        };

        vm.ovdMultiOccurrenceOptions = {
            resourceParams: {
                ovdcleintref: vm.valeursEcran.aftcleint,
                stamnetab: 'AFT'
            }, actionsRangeeDroite: new Menu([
                new MenuItemEcran('BTN_MODIFIER', mr0176Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.ovdcleint,
                        ovdcleintref: vm.monoOccurrence.data.mobcleint,
                        stamnetab: 'MOB'
                    };
                }, {
                    icon: 'launch',
                    onClose: () => vm.ovdmultiOccurrence.fetchDataList()
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('G_LBL_BTN_NOUVEAU', mr0176Route.NAME, () => ({
                    ovdcleintref: vm.monoOccurrence.data.mobcleint,
                    stamnetab: 'MOB'
                }), {
                    onClose: () => vm.ovdmultiOccurrence.fetchDataList(),
                    disabled: () => !vm.monoOccurrence.data.flgact
                })
            ]),
            bloc: 'ovd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            colonnesVisibles: [
                { nom: 'ovnimp', largeur: 300 },
                { nom: 'rsccoddsc', largeur: 100 },
                { nom: 'ovddatvig', largeur: 100 },
                { nom: 'ovddatfin', largeur: 100 }
            ],
            colonnesCachees: [
                'ovdcleint'
            ]
        };
    }
}
