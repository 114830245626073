import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
interface IPageMr0204 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0204Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0204 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            importation: true
        },
        colonnesVisibles: [
            { nom: 'prucod', largeur: 90 },
            { nom: 'descrip', largeur: 300 },
            { nom: 'pvsdatvig', largeur: 100 },
            { nom: 'vaeovnmthbmg', largeur: 262 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('prucleint', { largeur: 40 }),
            new FormulaireItem('pvsdatvig', { largeur: 10 }),
            new FormulaireItem('vaeovnmthbmg', { largeur: 20 })
        ])
    };
}
