import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageAc0082 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0082Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         exDateFilter: IFilterExDate,
                                         ac0028Route: IRoute) {
    const vm: IPageAc0082 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: true
        },

        navigatePage: ac0028Route.NAME,
            navigateParams: (rowData: any) => ({
                apocleint: rowData.apocleint,
                doccleint: rowData.doccleint
            }),

        criteresSuggeres: new Formulaire([
            new FormulaireItem('apodatfincri', {
                required: true,
                default: exDateFilter(new Date())
            }),
        ]),
        colonnesVisibles: [
            {nom: 'aponumdoc', largeur: 30},
            {nom: 'apodsc', largeur: 100},
            {nom: 'solde_non_consomme', largeur: 40},
        ],

        formulaire: new Formulaire([
            new FormulaireItem('aponumdoc',  { largeur: 30 }),
            new FormulaireItem('apodsc',  { largeur: 100 }),
            new FormulaireItem('solde_non_consomme',  { largeur: 40 }),
        ]),

        }
    };

