import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMR0242 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0242Controller(mr0338Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0242 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0338Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0338Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'mcfcod', largeur: 100},
            {nom: 'mcfdsc', largeur: 500}
        ]
    };
}
