import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageRr0064 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDbe: IFormulaire;
    valeursEcran: any;
}

interface IRr0064Params {
    id: string;
    demcleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0064Controller($stateParams: IRr0064Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr0064 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.formulaireDbe = new Formulaire('LBL_BAC', [
            new FormulaireItem('demcleint', {
                default: $stateParams.demcleint,
                largeur: 66.66
            }),
            new FormulaireItem('dbeflgact', {
                default: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('bqecleint'),
            new FormulaireItem('dbscleintref1', {
                resourceParams: (data:any) => {
                    return {
                        bqecleint: data.bqecleint
                    };
                }
            }),
            new FormulaireItem('dbscleintref2', {
                resourceParams: (data:any) => {
                    return {
                        bqecleint: data.bqecleint
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dbedatdeb'),
            new FormulaireItem('dbedatfin'),
            new FormulaireItem('dbeqteeva'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dbecom', {
                largeur: 100
            }),            
        ], {
            largeurDefaut: 33.33
        });
    }
}
