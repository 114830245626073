import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGl0003 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireEcj: IFormulaire;
    ecvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceDOIOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gl0003Controller(gl0038Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGl0003 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            cycleErrorReloadBlocs: ['ecv'],
            infosMonetaires: {
                blocs: ['ecv'],
                donnees:
                    [
                        { nom: 'dbt', libelle: 'G_LBL_DBT' },
                        { nom: 'crt', libelle: 'G_LBL_CRT', lastOfGroup: true },
                        { nom: 'ecart', libelle: 'G_LBL_ECART', expectedValue: 0 }
                    ]
            },
            infosFinancieres: {
                disponibilite: ecranDetails.valeurs.flgdpo,
                impactFinancier: ecranDetails.valeurs.flgbouimpfin,
                impactBudgetaire: ecranDetails.valeurs.flgbouimpbud
            },
            menus: [
                new MenuItemForage(
                    'G_LBL_PARAMS_ANNULATION',
                    gl0038Route.NAME,
                    (data: any) => ({ id: data.aut__ecacleint, ecjcleint: data.ecjcleint, intcleintreq: data.intcleintreq }),
                    {
                        icon: 'settings',
                        iconButton: false,
                        hidden: (data: any) => (data.tydcleint !== 57)
                    }
                )
            ]
        };

        vm.formulaireEcj = new Formulaire('LBL_BLC_ECJ', [
            new FormulaireItem('ecjnumdoc', { largeur: 33.33 }),
            new FormulaireItem('ecjnumdocseq', { largeur: 33.33, readonly: true }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tydcleint', { largeur: 33.33, modifAllow: false }),
            new FormulaireItem('sstcleint', { largeur: 33.33, modifAllow: false, required: (data: any) => data.sttflgacc === 1 }),
            new FormulaireItem('vececjcat', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('sstcleint', {
                largeur: 33.33,
                hidden: ecranDetails.valeurs.el2act === 0
                }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ecjdat', { largeur: 33.33 }),
            new FormulaireItem('peccleint', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('intcleintreq', { largeur: 33.33 }),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                },
                largeur: 33.33
            }),
            new FormulaireItem('ecjflgautrep', {
                largeur: 33.33,
                disabled: () => {
                    return ecranDetails.flgcen === 0;
                }
            }),

            new FormulaireItem('ecjdsc', { largeur: 66.66, enableCount: true }),
            new FormulaireItem('ecjflgpij', { largeur: 33.33 }),

            new FormulaireItem('peccleintecjren', { largeur: 33.33, nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('peccleintecjfin', { largeur: 33.33, nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('ecjnbrper', {
                largeur: 33.33,
                modelOptions: {
                    updateOn: 'blur'
                }
            })
        ], {
            largeurDefaut: 33.33
        });

        vm.ecvMultiOccurrenceOptions = {
            bloc: 'ecv',
            editionRapideActive: true,
            fonctions: {
                editionRapide: true,
                editionStandard: true,
                agrandirMultiOccurrence: true
            },
            colonnesFixesGauche: [
                { nom: 'pdfcod', largeur: 115 },
                { nom: 'ecvimp', largeur: 310 }
                // @TODO À REMETTRE LORSQUE L'ÉCRAN DE FORAGE (MC0044) SERA FAIT.
                //                {nom: 'ecvimp', largeur: 289,
                //                    menu: [
                //                        new MenuItemForage(
                //                            'LBL_FORAGE_UBR',
                //                            mc0044Route.NAME,
                //                            (data: any) => {
                //                                return {
                //                                    ecran: '00-2',
                //                                    id: data.prucleint
                //                                };
                //                            },
                //                            {
                //                                disabled: () => {
                //                                    return ecranDetails.flgcen === 0;
                //                                }
                //                            }
                //                        )
                //                    ]
                //                }
            ],
            colonnesFixesDroite: [
                { nom: 'ecvflgmsg', largeur: 100 }
            ],
            colonnesVisibles: [
                { nom: 'ecvmntdbt', largeur: 126 },
                { nom: 'ecvmntcrt', largeur: 126 },
                { nom: 'ecvdsc', largeur: 386 },
                { nom: 'refcod', largeur: 90 },
                { nom: 'refnom', largeur: 312 }
            ],
            colonnesEditionRapide: [
                { nom: 'ecvimp', largeur: 400 },
                { nom: 'ecvmntdbt', largeur: 126 },
                { nom: 'ecvmntcrt', largeur: 126 },
                { nom: 'ecvdsc', largeur: 300 },
                { nom: 'refcleint', largeur: 200 },
                { nom: 'ecvflgmsg', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pdfcleint', { disabled: data => data.$ancetre.tydcleint !== 62 && data.$ancetre.tydcleint !== 69 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ecvimp', { largeur: 50, isValidatingAtLoad: (data) => data.ecvflgmsg, required: true }),
                new FormulaireItem('ecvmntdbt', { largeur: 25, isValidatingAtLoad: (data) => data.ecvflgmsg }),
                new FormulaireItem('ecvmntcrt', { largeur: 25, isValidatingAtLoad: (data) => data.ecvflgmsg }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('refcleint', { isValidatingAtLoad: (data) => data.ecvflgmsg }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ecvdsc', { largeur: 66 }),
                new FormulaireItem('ecvflgmsg', { readonly: true, required: false, titre: " " })
            ], { largeurDefaut: 33.33 }),
            colonnesCachees: [
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint',
                'ecvmsg'
            ]
        };
    }
}
