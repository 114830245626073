import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IData} from '../../../../../core/services/data-linker.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMi0500 {
    valeursMif: any;
    valeursEcran: any;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    nedMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mi0500Controller(Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMi0500 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.nedMultiOccurrenceOptions = {
        bloc: 'ned',
        colonnesVisibles: [
            {nom: 'nedcod'         , largeur: 120},
            {nom: 'neddsc'         , largeur: 236},
            {nom: 'neddsclng'      , largeur: 393},
            {nom: 'nedcodpar'      , largeur: 100},
            {nom: 'nedflgdet'      , largeur: 100},
            {nom: 'nedflgadm'      , largeur: 100},
            {nom: 'nedprc'         , largeur: 139},
            {nom: 'nedflgact'      , largeur: 100},

        ],
        formulaire: new Formulaire('LBL_BLC_NED', [
            new FormulaireItem('nedcod'       , {largeur: 20}),
            new FormulaireItem('neddsc'       , {largeur: 20,
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('neddsclng'    , {largeur: 40}),
            new FormulaireItem('nedcleintpar' , {largeur: 20}),
            new FormulaireItem('nedflgdet'    , {default: 1, largeur: 25}),
            new FormulaireItem('nedflgadm'    , {largeur: 25}),
            new FormulaireItem('nedprc'       , {largeur: 25}),
            new FormulaireItem('nedflgact'    , {default: 1, largeur: 25}),
            new FormulaireItem('mifcleint'    , {default: (data: IData) => data.$blocs.mif.mifcleint})
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'mifcod'          , largeur: 100},
            {nom: 'mifdsc'          , largeur: 315},
            {nom: 'mifflgact'       , largeur: 100}
        ],
        formulaire: new Formulaire('LBL_BLC_MIF', [
            new FormulaireItem('mifcod'      , {largeur: 20}),
            new FormulaireItem('mifdsc'      , {largeur: 60}),
            new FormulaireItem('mifflgact'   , {largeur: 20, default: 1})
        ]),
        multiOccurrenceOptionsEnfant: vm.nedMultiOccurrenceOptions
    };
}
