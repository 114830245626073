import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAe0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ae0004Controller(Formulaire: IFormulaireClass,
    $stateParams: any,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAe0004 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'icncleint', valeur: $stateParams.reference, visible: false }
        ],
        colonnesVisibles: [
            { nom: 'stamnetab_cod', largeur: 90 },
            { nom: 'stamnetab_dsc', largeur: 325 },
            { nom: 'ccpcod', largeur: 90 },
            { nom: 'ccpdsc', largeur: 325 },
            { nom: 'vaetypicn_dsc', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('icncleintref'),
            new FormulaireItem('ccpcleint'),
            new FormulaireItem('vaetypicn')
        ], { largeurDefaut: 33.33 }),
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/ae0004/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
    };
}
