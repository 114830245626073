import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemClass } from "../../../../../core/services/menu/menu-item.service";

interface IPageMr0185 extends IComponentController {
  
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;

    salMultiOccurrenceOptions: IMultiOccurrenceOptions;
    avsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    avaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    remMultiOccurrenceOptions: IMultiOccurrenceOptions;
    arrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ajtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ddsMultiOccurrenceOptions: IMultiOccurrenceOptions;

    salMultiOccurrence: IMultiOccurrence;
    avsMultiOccurrence: IMultiOccurrence;
    avaMultiOccurrence: IMultiOccurrence;
    remMultiOccurrence: IMultiOccurrence;
    arrMultiOccurrence: IMultiOccurrence;
    ajtMultiOccurrence: IMultiOccurrence;
    ddsMultiOccurrence: IMultiOccurrence;

    visualMessage: boolean;
}

/* @ngInject */
export default function Mr0185Controller( Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass,
                                          FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,   
                                          MenuItem: IMenuItemClass) 
                                          {
    const vm: IPageMr0185 = this;

    vm.monoOccurrenceOptions = {  
        formulaire:  new Formulaire('', [   
            new FormulaireItem('demcleint'),
            new FormulaireItem('flg_err',{ largeur: 15,
                menu: [
                    new MenuItem('', 
                    () => { vm.visualMessage = !vm.visualMessage},
                    {
                        iconButton: true,
                        icon: 'search',
                        class: 'md-raised ex-button-action', 
                        disabled: (data: any) => !data.flg_err,
                        
                    }
                    )
                ] 
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unpcleint'),
            new FormulaireItem('ppacleinttrt')            
    ], {largeurDefaut: 33}) 
    };

    // Message
    vm.ddsMultiOccurrenceOptions = {
        bloc: 'dds',
        colonnesVisibles: [{ nom: 'ddsmsg', largeur: 200 }]
    };
    //Dépense salaire
    vm.salMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'peccod', largeur: 100 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'tagcod', largeur: 80 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'lemcod', largeur: 100 },
            { nom: 'imp', largeur: 245 }                
        ],
        colonnesFixesDroite: [
            { nom: 'datmnt', largeur: 126, total: true },
            { nom: 'flg_err', largeur: 100 },
            { nom: 'ddsmsg', largeur: 100 }
        ]
    };
    // Avantages sociaux
    vm.avsMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'peccod', largeur: 100 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'tagcod', largeur: 80 },
            { nom: 'rcecod', largeur: 80 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'imp', largeur: 245 }
        ],
        colonnesFixesDroite: [
            { nom: 'datmntavs', largeur: 126, total: true },
            { nom: 'flg_err', largeur: 100 },
            { nom: 'ddsmsg', largeur: 100 }
        ],
        colonnesCachees: ['errmess']
    };

    // Avance
    vm.avaMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'peccod', largeur: 140 },
            { nom: 'ppacod', largeur: 150 },
            { nom: 'tagcod', largeur: 80 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'lemcod', largeur: 150 },
            { nom: 'imp', largeur: 245 }
        ],
        colonnesFixesDroite: [
            { nom: 'datmnt', largeur: 126, total: true },
            { nom: 'flg_err', largeur: 100 },
            { nom: 'ddsmsg', largeur: 100 }
        ],
        colonnesCachees: ['errmess']
    };
    //Remise
    vm.remMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'peccod', largeur: 100 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'rcecod', largeur: 80 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'lemcod', largeur: 100 },
            { nom: 'imp', largeur: 245 }
        ],
        colonnesFixesDroite: [
            { nom: 'datmnt', largeur: 126, total: true },
            { nom: 'flg_err', largeur: 100 },
            { nom: 'ddsmsg', largeur: 100 }
        ],
        colonnesCachees: ['errmess']
    };
    //Arrérage
    vm.arrMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'peccod', largeur: 100 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'rcecod', largeur: 80 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'lemcod', largeur: 100 },
            { nom: 'imp', largeur: 245 }
        ],
        colonnesFixesDroite: [
            { nom: 'datmnt', largeur: 126, total: true },
            { nom: 'flg_err', largeur: 100 },
            { nom: 'ddsmsg', largeur: 100 }
        ],
        colonnesCachees: ['errmess']
    };
    //Ajustement
    vm.ajtMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'peccod', largeur: 100 },
            { nom: 'ppacod', largeur: 140 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'dernumdoc', largeur: 130 },
            { nom: 'drpnumdoc', largeur: 130 },
            { nom: 'imp', largeur: 245 }
        ],
        colonnesFixesDroite: [
            { nom: 'datmnt', largeur: 126, total: true },
            { nom: 'datmntavs', largeur: 126, total: true },
            { nom: 'flg_err', largeur: 100 },
            { nom: 'ddsmsg', largeur: 100 }
        ],
        colonnesCachees: ['errmess']
    };
}
