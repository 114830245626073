import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {INotificationHandler} from '../../../../../core/services/utils/notification-handler.service';

interface IPageIn0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function In0053Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Menu: IMenuClass,
                                         MenuItem: IMenuItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         cp0005Route: IRoute,
                                         notificationHandler: INotificationHandler
                                         // ac0003Route: IRoute,
                                         // ac0005Route: IRoute,
                                         // in0043Route: IRoute,
                                         ) {
    const vm: IPageIn0053 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    // @TODO ajouter la route de forage une fois que l'écran est fait
    const ac0003Route = {NAME: 'secure.accueil'};
    const ac0005Route = {NAME: 'secure.accueil'};
    const in0043Route = {NAME: 'secure.accueil'};

    const forageCategorie = new MenuItemForage('BTN_CATEG',
        ac0003Route.NAME,
        () => ( { } ),
        { fonction: 'BTN_CATEG' }
    );

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([ forageCategorie ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_intcleintreq'),
            new FormulaireItem('t_refcleint', {
                resourceParams: {
                    type: 'FOU'
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_caacleint'),
            new FormulaireItem('t_tfocleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_etcdsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_magcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_procleint')
        ]),
        colonnesVisibles: [
            {nom: 'refcod',    largeur:130},
            {nom: 'refnom', largeur: 350, menu: [
                    new MenuItemForage(
                        'BTN_FOURNI',
                        cp0005Route.NAME,
                        (data: any) => ({
                            id: data.foucleint
                        }), {
                            disabled: (data: any) => !data.foucleint,
                            fonction: 'BTN_FOURNI'
                        }
                    ),
                    new MenuItemForage(
                        'BTN_ENTNUL',
                        in0043Route.NAME,
                        (data: any) => ({
                            id: data.refcleint,
                            intcleint: data.t_intcleintreq,
                            caacleint: data.caacleint
                        }), {
                            icon: 'launch',
                            iconButton: true,
                            disabled: (data: any) => !data.foucleint,
                            fonction: 'BTN_ENTNUL'
                        }
                    )
                ]
            },
            {nom: 'etcnumdoc', largeur:130},
            {nom: 'etcdsc', largeur: 300, menu: [
                    new MenuItemForage(
                        'BTN_ENTENTE',
                        ac0005Route.NAME,
                        (data: any) => ({
                            id: data.etccleint
                        }), {
                        hidden: (data: any) => !data.etccleint,
                        fonction: 'BTN_ENTENTE'
                    }),
                    new MenuItem('LBL_XML',
                        () => {
                            notificationHandler.erreur({
                                lblMessage: vm.valeursEcran.msgxml
                            });
                        },
                        {
                            hidden: (data: any) => !data.etccleint || data.etcindb2b === 0,
                            icon: 'launch',
                            fonction: 'BTN_LBLXML'
                        }
                    ),
                    new MenuItemForage(
                        'BTN_ENTOUI',
                        in0043Route.NAME,
                        (data: any) => ({
                            id: data.refcleint,
                            intcleint: data.t_intcleintreq,
                            caacleint: data.caacleint,
                            etrcleint: data.etrcleint
                        }),
                        {
                            icon: 'launch',
                            iconButton: true,
                            hidden: (data: any) => !data.etccleint || data.etcindb2b === 1,
                            fonction: 'BTN_ENTOUI'

                            //
                            // Lors de la création de IN0043, il faudrait faire apparaitre le
                            // message d'instruction (etcinsdacmsg) si il est présent
                            //
                            // lblTitreValidation: vm.valeursEcran.msgins,
                            // validation(data: any) {
                            //     if (data.etcinsdacmsg) {
                            //         return data.etcinsdacmsg;
                            //     }
                            // }
                        }
                    )
                ]
            },
            {nom: 'etcflgitm', largeur:100},
            {nom: 'caacod',    largeur:120},
            {nom: 'caadsc',    largeur:300}
        ],
        colonnesCachees: [
            'foucleint',
            'etcindb2b',
            'etcinsdacmsg',
            'etccleint'
        ]
    };

}
