import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { ICriteresSuggeresOptions } from '../../../../../core/components/ex-criteres-suggeres/ex-criteres-suggeres.controller';

interface IPageMC0072 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    dataValidationAffichage: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireCie: IFormulaire;
    docMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dovMultiOccurrenceOptions: IMultiOccurrenceOptions;
    famMultiOccurrenceOptions: IMultiOccurrenceOptions;
    docMultiOccurrence: IMultiOccurrence;
    //famMultiOccurrence: IMultiOccurrence;
    optionsCriteresSuggeres: ICriteresSuggeresOptions;
}

/* @ngInject */
export default function Mc0072Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    mc2015Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    profil: IProfil,
    MenuItemSelection: IMenuItemSelectionClass
) {
    const vm: IPageMC0072 = this;
    vm.valeursEcran = ecranDetails.params;
    vm.monoOccurrenceOptions = {
        noId: true,
        id: profil.preferences.ciecleint,
        fonctions: {
            suiviModification: false,
            exportation: false
        }
    };
    vm.optionsCriteresSuggeres = {
        showFiltresInline: true,
        showAnnuler: false,
        iconFab: false
    };
    const flgacc = (ecranDetails.valeurs.flgacc) ? new Menu(
        [
            new MenuItemSelection('LBL_BTN', 'BOUEXE', {
                selection: {
                    nom: 'executer'
                }
            }),
            new MenuItemSelection('LBL_BTN_TRANS', 'BOUTRF', {
                selection: {
                    nom: 'transferer'
                }
            }),
            new MenuItemSelection('LBL_BTN_FERM', 'BOUFER', {
                selection: {
                    nom: 'fermer'
                }
            }),
            ,
            new MenuItemSelection('LBL_BTN_INS', 'BOUINS', {
                selection: {
                    nom: 'inscrire'
                }
            })
        ],
        {
            disabled: (rowData: any) => rowData.flgtrf === 0 && rowData.flgferaut === 0
        }
    ): null

    const docAff = new MenuItemForage(
        'LBL_BTN_DOC',
        (rowData: any) => `secure.${rowData.ecrcodoc}.ecran`,
        (rowData: any) => ({
            id: rowData.ecrcledoc
        }),
        {
            icon: 'exit_to_app',
            disabled: (rowData:any) => !(rowData.ecrcodoc)
        }
    );

    vm.docMultiOccurrenceOptions = { 
      //  autoFetch: false,
        navigatePage: mc2015Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                dccleint: rowData.dccleint
            };
        },        
        colonnesCachees: [
            'ecrcodoc',
            'ecrcledoc'
        ],
        fonctions: {
           // customCriteresSuggeres: true,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire(
            'LBL_RECHERCHE',
            [
                new FormulaireItem('pecann', { required: true }),
                new FormulaireItem('flgtrf'),
                new FormulaireItem('flgferaut'),
                new FormulaireItem('tydcleint', { required: false }),
                new FormulaireItem('flgnoncpl'),
                new FormulaireItem('flgco'),
                new FormulaireItem('cifcleint'),
                new FormulaireItem('flgeng')
            ],
            {
                largeurDefaut: 33.33
            }
        ),
        actionsSelection: flgacc,
        colonnesVisibles: [
            { nom: 'docaffnumdoc01', largeur: 170, menu: [docAff] },
            { nom: 'tydcod', largeur: 68 },
            { nom: 'entite_externe', largeur: 160 },
            { nom: 'mnt_original', largeur: 156 },
            { nom: 'trbsld', largeur: 139 },
            { nom: 'flgtrf', largeur: 100 },
            { nom: 'flgferaut', largeur: 100 },
            { nom: 'flgnoncpl', largeur: 100 },
            { nom: 'reception', largeur: 110 },
            { nom: 'nb_reception', largeur: 110 },
            { nom: 'tech_reception', largeur: 140 },
            { nom: 'docaffdat01', largeur: 110 },
            { nom: 'peccod', largeur: 90 },
            { nom: 'etdcod', largeur: 100 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'docaffdsc01', largeur: 320 },
            { nom: 'date_debut_contrat', largeur: 150 },
            { nom: 'date_fin_contrat', largeur: 130 },
            { nom: 'mnt_recep_non_fact', largeur: 175 },
            { nom: 'mnt_tot', largeur: 126 },
            { nom: 'en_cour', largeur: 90 },
            { nom: 'flgcou', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('docaffnumdoc01', { readonly: true }),
            new FormulaireItem('flgcou', { readonly: true })
        ])
    };
    vm.dovMultiOccurrenceOptions = {
        bloc: 'dov',
        colonnesVisibles: [
            { nom: 'imp', largeur: 200 },
            { nom: 'dovmnt', largeur: 125 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('imp', { readonly: true }),
            new FormulaireItem('dovmnt')
        ])
    };

    vm.famMultiOccurrenceOptions = {
        autoFetch: false,
        fonctions: {
            customCriteresSuggeres: true
        },
        criteresSuggeres: vm.formulaireCie,
        colonnesVisibles: [
            { nom: 'fammsg', largeur: 330 }
        ]
    };

}
