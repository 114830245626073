import * as angular from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {
    IFormulaireItem,
    IFormulaireItemClass,
    IFormulaireItemOptions
} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IComponentController, IFormController, IScope} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemTexteClass} from '../../../../../core/services/formulaire/formulaire-item-texte.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IApiConfig} from '../../../../../core/interfaces/api-config.interface';
import {IParametresSecuriteService} from '../../../../../core/services/parametres-securite.service';
import IResourceService = angular.resource.IResourceService;
import {ILovDataTypeClass} from '../../../../../core/services/data-types/lov-data-type.service';
import {IBooleanDataTypeClass} from '../../../../../core/services/data-types/boolean-data-type.service';
import {IDateDataTypeClass} from '../../../../../core/services/data-types/date-data-type.service';
import {INumberDataTypeClass} from '../../../../../core/services/data-types/number-data-type.service';
import {IIntervalleDataTypeClass} from '../../../../../core/services/data-types/intervalle-data-type.service';
import {IDataType, IRestriction} from '../../../../../core/services/data-types/data-type.service';
import {IDateHeureDataTypeClass} from '../../../../../core/services/data-types/date-heure-data-type.service';
import {IParametreMultipleDataTypeClass} from '../../../../../core/services/data-types/parametre-multiple-data-type.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import IStateService = angular.ui.IStateService;
import {INotificationHandler} from "../../../../../core/services/utils/notification-handler.service";
import {IFormulaireTabSectionsClass} from "../../../../../core/services/formulaire/formulaire-tab-sections.service";
import {IFormulaireItemSautDeColonneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service";
import IResourceClass = angular.resource.IResourceClass;
import {IErrorTypes} from '../../../../../core/constants/error-types.constant';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IProfil } from '../../../../../core/resources/profil.resource';

interface IPageGS0090 extends IComponentController  {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tusMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireSur: IFormulaire;
    formulaireTrt: IFormulaire;
    formulaireImp: IFormulaire;
    formulaireExe: IFormulaire;
    formulaireFin: IFormulaire;
    valeursEcran: any;
    formCtrl: IFormController;
    libelles: any;
    initError: boolean;
    fetchingParametres: boolean;
    parametresOptions: Array<IParametreOptions>;
    initFormulaireParametres(): void;
}

interface IParametreOptions {
    ptlaid: string;
    islov: boolean;
    isobl: boolean;
    ismulti: boolean;
    isinter: boolean;
    istypcar: boolean;
    istypnum: boolean;
    istypdate: boolean;
    istypdatehre: boolean;
    ismajmix: boolean;
    ismajmaj: boolean;
    ismajmin: boolean;
    ismajna: boolean;
    isflag: boolean;
    hauteur: number;
    ptlcleint: number;
    ptlcod: string;
    ptldecdon: number;
    ptlflgintexc: boolean;
    ptllab: string;
    ptllngdon: number;
    ptlmas: string;
    ptltyp: string;
    ptltypcas: number;
    ptltypdon: string;
    srpcle: number;
    srpnompar: string;
    srptyp: string;
    taille: number;
    trlcleint: number;
    trpval: string;
    valdef: string;
    valdefdsc: string;
    restrictions: Array<string>;
}

interface IGS0090Params {
    surcleint: string;
    menuId: string;
}

/* @ngInject */
export default function GS0090Controller($stateParams: IGS0090Params,
                                         $resource: IResourceService,
                                         $scope: IScope,
                                         $state: IStateService,
                                         ApiConfig: IApiConfig,
                                         BooleanDataType: IBooleanDataTypeClass,
                                         DateDataType: IDateDataTypeClass,
                                         DateHeureDataType: IDateHeureDataTypeClass,
                                         NumberDataType: INumberDataTypeClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireItemTexte: IFormulaireItemTexteClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         ErrorTypes: IErrorTypes,
                                         FormulaireTabSections: IFormulaireTabSectionsClass,
                                         IntervalleDataType: IIntervalleDataTypeClass,
                                         LovDataType: ILovDataTypeClass,
                                         Menu: IMenuClass,
                                         MenuItem: IMenuItemClass,
                                         ParametreMultipleDataType: IParametreMultipleDataTypeClass,
                                         accueilRoute: IRoute,
                                         profilResolver: IProfil,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         notificationHandler: INotificationHandler,
                                         parametresSecurite: IParametresSecuriteService) {
    const vm: IPageGS0090 = this;
    const DATA_TYPES = {
        BOOLEAN: new BooleanDataType(),
        DATE: new DateDataType(),
        DATE_HEURE: new DateHeureDataType(),
        LOV: (restrictions: Array<string>) => new LovDataType({
            schemaItem: {},
            params: {
                source: 'gs0090-ptl',
                description: ['code', 'description'],
                restrictions: getLovRestrictions(restrictions)
            }
        })
    };

    const OPTIONS_SOURCE_LOV = {
        srccod: 'lov-gs0090-ptl',
        srccodref: 'gs0090'
    };

    vm.$onInit = $onInit;
    vm.initFormulaireParametres = initFormulaireParametres;

    function $onInit() {
        vm.libelles = {};
        vm.valeursEcran = ecranDetails.valeurs;
        initFormulaireParametres();

        vm.tusMultiOccurrenceOptions = {
            bloc: 'tus',
            parentId: vm.valeursEcran.trlcod,
            actionsRangeeDroite: new Menu([
                new MenuItem('G_LBL_SELECTED', selectTus, {
                    icon: 'check_circle',
                    iconButton: true,
                    directionTooltip: 'bottom'
                })
            ]),
            pagination: {
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            },
            colonnesVisibles: [
                {nom: 'tusdsc', largeur: 300}
            ]
        }
    }

    function selectTus(e: Event, data: {tuscleint: number}) {
        const tupResource = $resource(`${ApiConfig.ROOT}/gs0090/:trlcod/tup`, {
            ...parametresSecurite($stateParams)
        }) as IResourceClass<any>;

        tupResource.query({
            tuscleint: data.tuscleint,
            trlcod: vm.valeursEcran.trlcod
        }).$promise.then((tupResult: {valeur: any}[]) => {
            for (const tup of tupResult) {
                Object.assign(vm.monoOccurrence.data, tup.valeur);
            }
        }).catch((err) => {
            showNotificationError(err);
        });

        const tusResource = $resource(`${ApiConfig.ROOT}/gs0090/:trlcod/tus/:id`, {
            ...parametresSecurite($stateParams)
        });

        tusResource.get({
            id: data.tuscleint,
            trlcod: vm.valeursEcran.trlcod
        }).$promise.then((tusResult) => {
            vm.monoOccurrence.data.surdsclan = tusResult.tusdsc;

            vm.monoOccurrence.data.surnbrcop = tusResult.tusnbrcop;
            vm.monoOccurrence.data.impcleint = tusResult.impcleint;
            vm.monoOccurrence.data.impcleint__impdsc = tusResult.impdsc;

            vm.monoOccurrence.data.surfintrt = tusResult.tusfintrt;
            vm.monoOccurrence.data.surfintrt__vaedsc = tusResult.tusfintrtdsc;
            vm.monoOccurrence.data.surtypficimp = tusResult.tustypficimp;
            vm.monoOccurrence.data.surtypficimp__vaedsc = tusResult.tustypficimpdsc;
            vm.monoOccurrence.data.surflgnot = tusResult.tusflgnot;
        }).catch((err) => {
            showNotificationError(err);
        });
    }

    function initFormulaireParametres() {
        vm.fetchingParametres = true;
        fetchParametresLot()
            .then((formulaireItems: Array<IFormulaireItem>) => {
                vm.initError = false;
                if (!angular.equals(vm.libelles, {})) {
                    $scope.$watch('::vm.monoOccurrence', () => {
                        if (vm.monoOccurrence) {
                            Object.assign(vm.monoOccurrence.libelles, vm.libelles);
                            Object.assign(vm.monoOccurrence.libelles.$libelles, vm.libelles);
                        }
                    });
                }

                vm.formulaireSur = new Formulaire('LBL_BLC_SUR', formulaireItems);
                vm.monoOccurrenceOptions = {
                    resetEtatDialog: true,
                    fonctions: {
                        suiviModification: false,
                        exportation: false,
                        boutonEnregistrerHaut: false
                    },
                    menus: [
                        new MenuItem(
                            'G_LBL_LANCER',
                            lancerTraitement,
                            {
                                icon: 'print',
                                iconButton: false,
                                class: (data: any) => !vm.formCtrl || !vm.formCtrl.$valid || (data.grpoptexe === 'C' && !data.cedhrelan) ? "ex-disabled--disabled" : ""
                            }
                        )
                    ]
                };

                vm.formulaireTrt = new Formulaire('LBL_BLC_TRT', [
                    new FormulaireItem('surdsclan', {largeur: 100}),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('flgenr'),
                    new FormulaireItem('ciecleint',{
                        default:profilResolver.compagnie.ciecleint,
                        hidden: true
                    })
                ]);

                vm.formulaireImp = new Formulaire('LBL_BLC_IMP', [
                    new FormulaireItem('impcleint', {largeur: 60}),
                    new FormulaireItem('surnbrcop', {largeur: 40})
                ]);

                vm.formulaireExe = new Formulaire('LBL_BLC_EXE', [
                    new FormulaireItem('grpoptexe', {
                        default: 'M',
                        required: true,
                        titre: ' '
                    }),
                    new FormulaireItemTexte('LBL_AFF_MAINTENANT', {hidden: (data: any) => data.grpoptexe !== 'M'}),
                    new FormulaireGroupe(new Formulaire([
                        new FormulaireItem('surdatdif')
                    ], {largeurDefaut: 100}), {hidden: (data: any) => data.grpoptexe !== 'T'}),
                    new FormulaireGroupe(new Formulaire([
                        new FormulaireItem('ceddatdeb'),
                        new FormulaireItem('cedhrelan'),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('ceddatfin'),
                        new FormulaireTabSections('ongletact', [
                                {
                                    lblTitre: 'LBL_CEDULE_HEB',
                                    icon: 'view_day',
                                    formulaire: new Formulaire([
                                        new FormulaireItem('cedflgdim', {minWidth: 120}),
                                        new FormulaireItem('cedflglun', {minWidth: 120}),
                                        new FormulaireItem('cedflgmar', {minWidth: 120}),
                                        new FormulaireItem('cedflgmer', {minWidth: 120}),
                                        new FormulaireItem('cedflgjeu', {minWidth: 120}),
                                        new FormulaireItem('cedflgven', {minWidth: 120}),
                                        new FormulaireItem('cedflgsam', {minWidth: 120}),
                                        new FormulaireItemSautDeColonne({minWidth: 120})
                                    ], {largeurDefaut: 25})
                                },
                                {
                                    lblTitre: 'LBL_CEDULE_MEN',
                                    icon: 'view_comfy',
                                    formulaire: new Formulaire([
                                        new FormulaireItem('jour1', {minWidth: 50}),
                                        new FormulaireItem('jour2', {minWidth: 50}),
                                        new FormulaireItem('jour3', {minWidth: 50}),
                                        new FormulaireItem('jour4', {minWidth: 50}),
                                        new FormulaireItem('jour5', {minWidth: 50}),
                                        new FormulaireItem('jour6', {minWidth: 50}),
                                        new FormulaireItem('jour7', {minWidth: 50}),
                                        new FormulaireItem('jour8', {minWidth: 50}),
                                        new FormulaireItem('jour9', {minWidth: 50}),
                                        new FormulaireItem('jour10', {minWidth: 50}),
                                        new FormulaireItem('jour11', {minWidth: 50}),
                                        new FormulaireItem('jour12', {minWidth: 50}),
                                        new FormulaireItem('jour13', {minWidth: 50}),
                                        new FormulaireItem('jour14', {minWidth: 50}),
                                        new FormulaireItem('jour15', {minWidth: 50}),
                                        new FormulaireItem('jour16', {minWidth: 50}),
                                        new FormulaireItem('jour17', {minWidth: 50}),
                                        new FormulaireItem('jour18', {minWidth: 50}),
                                        new FormulaireItem('jour19', {minWidth: 50}),
                                        new FormulaireItem('jour20', {minWidth: 50}),
                                        new FormulaireItem('jour21', {minWidth: 50}),
                                        new FormulaireItem('jour22', {minWidth: 50}),
                                        new FormulaireItem('jour23', {minWidth: 50}),
                                        new FormulaireItem('jour24', {minWidth: 50}),
                                        new FormulaireItem('jour25', {minWidth: 50}),
                                        new FormulaireItem('jour26', {minWidth: 50}),
                                        new FormulaireItem('jour27', {minWidth: 50}),
                                        new FormulaireItem('jour28', {minWidth: 50}),
                                        new FormulaireItem('jour29', {minWidth: 50}),
                                        new FormulaireItem('jour30', {minWidth: 50}),
                                        new FormulaireItem('jour31', {minWidth: 50}),
                                        new FormulaireItem('jour32', {largeur: 4 * (100 / 7), minWidth: 200})
                                    ], {largeurDefaut:  (100 / 7)})
                                },
                                {
                                    lblTitre: 'LBL_CEDULE_PER',
                                    icon: 'view_quilt',
                                    formulaire: new Formulaire([
                                        new FormulaireItem('cedtypfre', {largeur: 50}),
                                        new FormulaireItemSautDeColonne({largeur: 50}),
                                        new FormulaireItem('cedfrelan', {largeur: 50}),
                                        new FormulaireItem('cedhrelanfin', {largeur: 50}),
                                        new FormulaireItemSautDeLigne(),
                                        new FormulaireItem('cedflgdim', {minWidth: 120}),
                                        new FormulaireItem('cedflglun', {minWidth: 120}),
                                        new FormulaireItem('cedflgmar', {minWidth: 120}),
                                        new FormulaireItem('cedflgmer', {minWidth: 120}),
                                        new FormulaireItem('cedflgjeu', {minWidth: 120}),
                                        new FormulaireItem('cedflgven', {minWidth: 120}),
                                        new FormulaireItem('cedflgsam', {minWidth: 120}),
                                        new FormulaireItemSautDeColonne({
                                            minWidth: 120,
                                            hidden: (data: any) => !['PAG_HEB'].includes(data.ongletact) && data.cedtypfre !== 'MIN'
                                        })
                                    ], {largeurDefaut: 25})
                                }
                            ],
                            {
                                largeur: 100,
                                valuesMap: {
                                    0: 'PAG_HEB',
                                    1: 'PAG_MEN',
                                    2: 'PAG_PER'
                                }
                            })
                    ], {largeurDefaut: 50}), {hidden: (data: any) => data.grpoptexe !== 'C'}),
                    new FormulaireItemTexte((data: any) => data.ceddscgen, {
                        largeur: 100,
                        hidden: (data: any) => data.grpoptexe !== 'C',
                        customClass: 'ex-grey-200-bg p-h-8 p-t-8 ex-rounded-corners'
                    })
                ], {largeurDefaut: 100});

                vm.formulaireFin = new Formulaire('LBL_BLC_FIN', [
                    new FormulaireItem('surtypficimp'),
                    new FormulaireItem('surfintrt'),
                    new FormulaireItem('surflgnot')
                ]);
            })
            .catch(() => {
                vm.initError = true;
            })
            .finally(() => {
                vm.fetchingParametres = false;
            });
    }

    function fetchParametresLot() {
        const parametresResource = $resource(`${ApiConfig.ROOT}/gs0090/params/:trlcod`, {
            ...parametresSecurite($stateParams)
        });

        const params: any = {
            trlcod: vm.valeursEcran.trlcod
        };

        return parametresResource.query(params).$promise
            .then((result: any) => {
                vm.parametresOptions = result;
                return result
                    .map((row: IParametreOptions) => getFormulaireItem(row))
                    .reduce((formulaireItems: Array<IFormulaireItem>, value: IFormulaireItem|Array<IFormulaireItem>) => {
                        return formulaireItems.concat(Array.isArray(value) ? value : [value]);
                    }, []);
            });
    }

    function getFormulaireItem(row: IParametreOptions): IFormulaireItem|Array<IFormulaireItem> {
        const nomChamp = `${row.ptlcod.toLowerCase()}${row.srpcle}`;
        const options = getFormulaireItemOptions(row);

        if (row.islov && row.valdef) {
            return [
                new FormulaireItem(nomChamp, options),
                new FormulaireItem(`${nomChamp}__code`, {hidden: true, default: row.valdef}),
                new FormulaireItem(`${nomChamp}__description`, {hidden: true, default: row.valdefdsc})
            ];
        } else {
            return new FormulaireItem(nomChamp, options);
        }
    }

    function getFormulaireItemOptions(row: IParametreOptions) {
        const options: IFormulaireItemOptions = {
            largeur: row.taille,
            titre: row.ptllab,
            default: row.valdef,
            required: row.isobl,
            resourceParams: {}
        };

        options.dataType = getFormulaireItemDataType(row);

        // On ajoute les options spécifiques au type du champ
        if (row.ismulti) {
            // Champ multiple
            options.resourceParams = {...options.resourceParams, ptlcleint: row.ptlcleint};
        }

        if (row.isinter && options.default && !Array.isArray(options.default)) {
            options.default = options.default.split('|');
        }

        if (row.islov) {
            options.resourceParams = (data: any) =>  (Object.assign({}, data, {
                ...options.resourceParams,
                ...OPTIONS_SOURCE_LOV,
                srpcle: row.srpcle,
                trlcleint: row.trlcleint,
                ptlcleint: row.ptlcleint,
                ptlcod: row.ptlcod,
                ptlaid: row.ptlaid
            }));

        } else if (row.isflag) {
            options.switch = true;
            options.default = (row.valdef === 'O') ? 1 : 0;
        } else if (row.istypdatehre) {
            if (row.isinter) {
                options.titre = row.ptllab;
            } else {
                const libelle = row.ptllab.replace(/ +/g, '_').toUpperCase();
                options.titre = libelle;
                // On ajuste les libellés des champs date heure
                const customLibelles = {[libelle]: row.ptllab, [`${libelle}_HEURE`]: row.ptllab};
                Object.assign(vm.libelles, customLibelles);
            }
        } else {
            options.masque = row.ptlmas;
            options.maxlength = row.ptllngdon;
        }

        return options;
    }

    function getLovRestrictions(restrictions: Array<string>): { [key: string]: IRestriction} {
        if (restrictions) {
            const restrictionsSrp: Array<string> = restrictions.map((col: string) => {
                const parametre = vm.parametresOptions.find((param: IParametreOptions) => param.ptlcod.toLowerCase() === col.toLowerCase());

                if (parametre) {
                    return parametre.srpcle.toString();
                } else {
                    return '';
                }
            });

            return restrictions
                .reduce((result: { [key: string]: IRestriction}, colRestriction: string, index: number) => {
                    const itemName = `${colRestriction}${restrictionsSrp[index]}`;
                    result[itemName] = {
                        nom: colRestriction,
                        skipFiltre: true
                    };

                    return result;
                }, {});
        }
    }

    function getFormulaireItemDataType(row: IParametreOptions, isMultiDataType: boolean = false, isIntervalleDataType?: boolean): IDataType {
        if (!isMultiDataType && row.ismulti) {
            return new ParametreMultipleDataType({dataTypeChamps: getFormulaireItemDataType(row, true)});
        } else if (!isIntervalleDataType && row.isinter) {
            return new IntervalleDataType({dataTypeChamps: getFormulaireItemDataType(row, isMultiDataType, true)});
        } else if (row.islov) {
            return DATA_TYPES.LOV((row.restrictions || []).map((col: string) => col.toLowerCase()));
        } else if (row.isflag) {
            return DATA_TYPES.BOOLEAN;
        } else if (row.istypdate) {
            return DATA_TYPES.DATE;
        } else if (row.istypdatehre) {
            const dataType = {...DATA_TYPES.DATE_HEURE};
            dataType.params.minutes = true;
            dataType.params.secondes = false;
            return dataType;
        } else if (row.istypnum) {
            const dataType = new NumberDataType({params: {separateurMilliers: ''}});
            dataType.params.decimals = row.ptldecdon || 0;
            dataType.params.precision = row.ptllngdon;

            return dataType;
        }
    }

    function lancerTraitement(ev: MouseEvent, formData: any) {
        ev.preventDefault();
        vm.formCtrl.$setSubmitted();

        if (vm.formCtrl.$valid) {
            const resource = $resource(`${ApiConfig.ROOT}/gs0090/lancer-traitement/:trlcod`, {
                ...parametresSecurite($stateParams)
            });

            const params: any = {
                trlcod: vm.valeursEcran.trlcod
            };

            resource.save(params, formData).$promise
                .then((result: any) => {
                    const msg = result.msg;

                    if (!result.typmsg) {
                        notificationHandler.succes(msg);
                    } else if (result.typmsg === ErrorTypes.INFORMATION) {
                        notificationHandler.info({
                            lblMessage: msg
                        });
                    } else if (result.typmsg === ErrorTypes.AVERTISSEMENT) {
                        notificationHandler.avertissement({
                            lblMessage: msg
                        });
                    } else if (result.typmsg === ErrorTypes.ERREUR) {
                        notificationHandler.erreur({
                            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                            lblMessage: msg
                        });
                    }

                    if (result.typmsg !== ErrorTypes.ERREUR) {
                        $state.transitionTo(accueilRoute.NAME, {verifierChangements: false}, {
                            location: 'replace'
                        });
                    }
                })
                .catch(showNotificationError);
        }
    }

    function showNotificationError(error: any) {
        if (Array.isArray(error.data)) {
            error.data = error.data[0];
        }

        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: error.data && error.data.message || 'G_MSG_ERREUR_ACTION'
        });
    }
}
