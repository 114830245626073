import {IComponentController, IFormController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageMR0119 extends IComponentController {
    valeursEcran: any;
    formCtrl: IFormController;
    formulaireTag: IFormulaire;
    formulaireTagDat: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tgpMultiOccurrence: IMultiOccurrence;
    tgpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    btgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tgeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tpsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    atgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tgcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    admMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0119Controller(mr0052Route: IRoute,
                                         mr0238Route: IRoute,
                                         mr0273Route: IRoute,
                                         mr0274Route: IRoute,
                                         mr0313Route: IRoute,
                                         mr0014Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0119 = this;

    const forageMtg = new MenuItemForage(
        'LBL_FOR_MTG',
        mr0052Route.NAME,
        (data: any) => ({ id: data.mtgcleint }),
        { hidden: (data: any) => !(data.$id && data.mtgcleint) }
    );

    const forageVcl = new MenuItemForage(
        'LBL_FOR_VCL',
        mr0014Route.NAME,
        (data:any) => ({
            stamnetabcri: 'TAG',
            cleintcri: data.tagcleint
        }),
        { hidden: (data: any) => !(data.$id && data.aut__tagflgvcl) }
    )
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                zoneDroiteVisibleCreation: true
            },
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_FORAGE_COPIE',
                    mr0273Route.NAME,
                    (data: any) => ({
                        id: data.tagcleint,
                        tagcleint: data.tagcleint,
                        ciecleint: data.ciecleint
                    }),
                    { icon: 'content_copy' }
                ),
                new MenuItemForage(
                    'G_LBL_MR0238',
                    mr0238Route.NAME,
                    (data:any) => ({
                        vaetypfnd: 'TAG',
                        cleintdet: data.tagcleint
                    }),
                    {
                        icon: 'assessment',
                        iconButton: false
                    }
                )
            ])
        };

        vm.formulaireTag = new Formulaire('LBL_BLC_TAG', [
            new FormulaireItem('tagcod', { largeur: 10 }),
            new FormulaireItem('tagdscabr', { largeur: 40, modelOptions: { updateOn: 'blur' } }),
            new FormulaireItem('tagdsc', { largeur: 40 }),
            new FormulaireItem('vaetagtyp'),
            new FormulaireItem('mtgcleint', { menu: [forageMtg] }),
            //new FormulaireItem('t_flgaffunc', { default: (data: any) => data.$valeurs.flgaffunc }),
            //new FormulaireItem('t_flgaffuncsal', { default: (data: any) => data.$valeurs.flgaffuncsal }),
            //new FormulaireItem('t_flgaffcol', { default: (data: any) => data.$valeurs.flgaffcol }),
            //new FormulaireItem('t_flgafftagava', { default: (data: any) => data.$valeurs.flgafftagava }),
            //new FormulaireItem('t_flgaffsalsup', { default: (data: any) => data.$valeurs.flgaffsalsup }),
           // new FormulaireItem('t_flgaffcolsalbas', { default: (data: any) => data.$valeurs.flgaffcolsalbas }),
           // new FormulaireItem('t_flgaffrce', { default: (data: any) => data.$valeurs.flgaffrce }),
            new FormulaireGroupeAccordeon('LBL_PARAM_GLOB',
                new Formulaire([
                    new FormulaireItem('unccleint'),
                    new FormulaireItem('unccleintsal'),
                    new FormulaireItem('colcleint'),
                    new FormulaireItem('rcecleint'),
                    new FormulaireItem('tagcleintava'),
                    new FormulaireItem('vaetagsalsup'),
                    new FormulaireItem('colcleintsalbas')
                ]),
                {
                    hidden: (data: any) =>
                        (
                            (!data.t_flgaffunc) &&
                            (!data.t_flgaffuncsal) &&
                            (!data.t_flgaffcol) &&
                            (!data.t_flgafftagava) &&
                            (!data.t_flgaffsalsup) &&
                            (!data.t_flgaffcolsalbas) &&
                            (!data.t_flgaffrce)
                        )
                }
            ),
            new FormulaireGroupeAccordeon('LBL_IMPRESSION',
                new Formulaire([
                    new FormulaireItem('tagindimp', { default: 1 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('tagcleintref', { largeur: 50 }),
                    new FormulaireItem('vaetagtauimp', { largeur: 50 })
                ], { largeurDefaut: 25 })
            ),
            new FormulaireGroupeAccordeon('LBL_AUTRE_INFOS',
                new Formulaire([
                    new FormulaireItem('carcleint'),
                    new FormulaireItem('vactagcat'),
                    new FormulaireItem('tgncleint'),
                    new FormulaireItem('aut__tagflgvcl', {readonly: true, menu: [forageVcl]}),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('tagdoc', { largeur: 100, enableCount: true })
                ])
            )
        ]);

        vm.formulaireTagDat = new Formulaire('', [
            new FormulaireItem('tagdatdeb'),
            new FormulaireItem('tagdatfin'),
            new FormulaireItem('tagprical'),
            new FormulaireItem('tagseqaff')
        ]);

        // Paramètres
        vm.tgpMultiOccurrenceOptions = {
            bloc: 'tgp',
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_BTN_NOUVEAU',
                    mr0313Route.NAME,
                    () => ({
                        tagcleint: vm.monoOccurrence.data.tagcleint,
                        mtgcleint: vm.monoOccurrence.data.mtgcleint,
                        vaetagtyp: vm.monoOccurrence.data.vaetagtyp
                    }),
                    {
                        //disabled: () => !vm.valeursEcran.flginsert,
                        onClose() { vm.tgpMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            actionsMoreLigne: new Menu([
                new MenuItemEcran(
                    'LBL_FORAGE_COPIE_PARAM',
                    mr0274Route.NAME,
                    (data: any) => ({
                        id: vm.monoOccurrence.data.tagcleint,
                        tagcleint_ori:vm.monoOccurrence.data.tagcleint,
                        tgncleint: vm.monoOccurrence.data.tgncleint,
                        tgpcleint: data.tgpcleint
                    }),
                    { icon: 'file_copy' }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'BTN_OUVRIR',
                    mr0313Route.NAME,
                    (data: any) => ({
                        id: data.tgpcleint,
                        tagcleint: vm.monoOccurrence.data.tagcleint,
                        mtgcleint: vm.monoOccurrence.data.mtgcleint,
                        vaetagtyp: vm.monoOccurrence.data.vaetagtyp
                    }),
                    {
                        icon: 'launch',
                        onClose() { vm.tgpMultiOccurrence.fetchDataList(); }
                    }
                ),
            ]),
            //colonnesCachees: ['tgpcleint'],
            colonnesFixesGauche: [
                {nom: 'urgcod', largeur: 89}
            ],
            colonnesVisibles: [
                {nom: 'strcod'         , largeur: 89},
                {nom: 'tgpdatvig'      , largeur: 140},
                {nom: 'tgpflgact'      , largeur: 74},
                {nom: 'tgpfct'         , largeur: 128, hidden: () => !vm.valeursEcran.tgpfct},
                {nom: 'tgpmnt'         , largeur: 140, hidden: () => !vm.valeursEcran.tgpmnt},
                {nom: 'unccodmnt'      , largeur: 89,  hidden: () => !vm.valeursEcran.unccleintmnt},
                {nom: 'urgdscabr'      , largeur: 242},
                {nom: 'strdsc'         , largeur: 242},
                {nom: 'tgpprctaudem'   , largeur: 120, hidden: () => !vm.valeursEcran.tgpprctaudem},
                {nom: 'tgpmntmax'      , largeur: 140, hidden: () => !vm.valeursEcran.tgpmntmax},
                {nom: 'bqecodsld'      , largeur: 140, hidden: () => !vm.valeursEcran.bqecleint},
                {nom: 'bqedscabrsld'   , largeur: 262, hidden: () => !vm.valeursEcran.bqecleint},
                {nom: 'bqecodtauqte'   , largeur: 140, hidden: () => !vm.valeursEcran.bqecleinttauqte},
                {nom: 'bqedscabrtauqte', largeur: 262, hidden: () => !vm.valeursEcran.bqecleinttauqte},
                {nom: 'bqecodtaumnt'   , largeur: 140, hidden: () => !vm.valeursEcran.bqecleinttaumnt},
                {nom: 'bqedscabrtaumnt', largeur: 262, hidden: () => !vm.valeursEcran.bqecleinttaumnt}
            ]
        };


        // Banque
        vm.btgMultiOccurrenceOptions = {
            bloc: 'btg',                    
            colonnesVisibles: [
                {nom: 'bqecod'         , largeur: 110},
                {nom: 'bqedscabr'      , largeur: 280},
                {nom: 'vaebtgope_dsc'  , largeur: 160},
                {nom: 'btgfct'         , largeur: 140}
            ]
        };
        // Déclencheur
        vm.tgeMultiOccurrenceOptions = {
            bloc: 'tge',                    
            colonnesVisibles: [
                {nom: 'tagcod'         , largeur: 110},
                {nom: 'tagdscabr'      , largeur: 280}
            ]
        };        

        // Ald
        vm.atgMultiOccurrenceOptions = {
            bloc: 'atg',
            colonnesVisibles: [
                {nom: 'prfcod'      , largeur: 150},
                {nom: 'prfdscabr'   , largeur: 328}
            ]
        };

        // TAG ajouter
        vm.tgcMultiOccurrenceOptions = {
            bloc: 'tgc',
            colonnesVisibles: [
                {nom: 'tagcod'      , largeur: 110},
                {nom: 'tagdscabr'   , largeur: 280}
            ]
        };
        // TPS
        vm.tpsMultiOccurrenceOptions = {
            bloc: 'tps',
            colonnesVisibles: [
                {nom: 'typ'      , largeur: 110},
                {nom: 'rghcod'   , largeur: 180},
                {nom: 'rghdsc'   , largeur: 280},
                {nom: 'tpscod'   , largeur: 180},
                {nom: 'tpsdsc'   , largeur: 280},
                {nom: 'datdeb'   , largeur: 110},
                {nom: 'datfin'   , largeur: 110},
                {nom: 'ttgqte'   , largeur: 110},
                {nom: 'ttgflgsnsremaut', largeur: 110},
                {nom: 'ttgflgmaxtem'  , largeur: 110},
                {nom: 'ttgqtemaxsem'   , largeur: 110}
            ]
        };

        // Admissibilités
        vm.admMultiOccurrenceOptions = {
            bloc: 'adm',
            colonnesVisibles: [
                {nom: 'colcod'      , largeur: 130},
                {nom: 'coldsc'      , largeur: 328},
                {nom: 'cdtdatvig'   , largeur: 140},
                {nom: 'maxcdtdatvig', largeur: 140}
            ]
        };
    }
}
