import {
    IAugmentedJQuery,
    IComponentController,
    IFilterDate,
    IPromise
} from 'angular';
import {IMonoOccurrence} from '../../../../core/services/mono-occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceOptions
} from '../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../core/services/formulaire/formulaire-item.service';
import {IEcranContextController} from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IParametresSecuriteService} from '../../../../core/services/parametres-securite.service';
import {IApiConfig} from '../../../../core/interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import {IEcranStateParamsService} from '../../../../core/services/utils/ecran-state-params.service';

interface IComposantNotes extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    erreurInit: boolean;
    initialized: boolean;
    initializing: boolean;
    isModificationPermise: boolean;
    init(): void;
    onSaveNewAction(savedData: any): void;
}

/* @ngInject */
export default function NotesController(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass,
                                        $element: IAugmentedJQuery,
                                        dateFilter: IFilterDate,
                                        $resource: IResourceService,
                                        ApiConfig: IApiConfig,
                                        parametresSecurite: IParametresSecuriteService,
                                        ecranStateParams: IEcranStateParamsService) {

    const vm: IComposantNotes = this;

    vm.$onInit = $onInit;
    vm.init = init;

    function $onInit() {
        $element.closest('md-content.ex-mono-occurrence-ecran-content').animate({
            scrollTop: $element.prop('offsetTop') - 8
        }, 200);

        vm.init();
    }

    function init() {
        vm.initializing = true;
        vm.monoOccurrence.data.$promise
            .then(() => getFonctionsEdition())
            .then((isModificationPermise) => {
                vm.isModificationPermise = isModificationPermise;
                vm.erreurInit = false;
                vm.initialized = true;

                const cleint = vm.monoOccurrence.cleint;
                const demcleint = vm.monoOccurrence.data.demcleint;

                vm.multiOccurrenceOptions = {
                    srccod: 'mc1025',
                    stateParams: vm.ecranContextCtrl.stateParams,
                    parentId: vm.monoOccurrence.data[cleint],
                    colonnesVisibles: [
                        'notseq',
                        'notdat',
                        'gnodsc',
                        'tnodsc',
                        'usrcodcre',
                        'usrnomcre',
                        'notdsc',
                        'origine',
                        'reference_origine',
                        'nbrpj'
                    ],
                    resourceParams: {
                        notcleref: vm.monoOccurrence.data[vm.monoOccurrence.cleint],
                        ecrcleint: vm.ecranContextCtrl.ecranDetails.ecrcleint,
                        srccod: vm.ecranContextCtrl.stateParams.srccod,
                        demcleint: demcleint
                    },
                    formulaire: new Formulaire([
                        new FormulaireItem('gtacleint', {required: true, readonly:(data: any) =>  data.$id && !isModificationPermise, resourceParams: {
                            srccodref: vm.ecranContextCtrl.stateParams.srccod,
                            ecrcleint: vm.ecranContextCtrl.ecranDetails.ecrcleint
                        }}),
                        new FormulaireItem('notdat', {required: true, readonly:(data: any) =>  data.$id && !isModificationPermise , default: dateFilter(new Date(), 'yyyy-MM-dd')}),
                        new FormulaireItem('tnocleint', {required: true, readonly:(data: any) =>  data.$id && !isModificationPermise, resourceParams: {srccodref: vm.ecranContextCtrl.stateParams.srccod}}),
                        new FormulaireItem('notdsc', {required: true, largeur: 100, readonly:(data: any) =>  data.$id && !isModificationPermise})
                    ], {
                        piecesJointes: true
                    }),
                    fonctions: {
                        nouveau: true,
                        supprime: isModificationPermise,
                        recherche: true,
                        rechercheParColonne: true,
                        filtrer: true,
                        enregistrerUnEtat: false,
                        selectionnerUnEtat: true,
                        exportation: true,
                        pagination: true,
                        importation: false,
                        selectionnerDesColonnes: false,
                        selectionnerTri: false,
                        reinitialiser: false,
                        saveEtatTemporaire: false,
                        afficherMenuSettingDansEntete: true,
                        avisEmploye: false
                    }
                };
            })
            .catch(() => {
                vm.erreurInit = true;
            })
            .finally(() => {
                vm.initializing = false;
            });
    }

    vm.onSaveNewAction = function onSaveNewAction(savedData: any): void {
        // Comme il n'est pas possible d'ajouter des pièces jointes durant la création, on ouvre la note après
        // sa création pour qu'on puisse ajouter les pièces jointes aussi facilement que possible
        vm.multiOccurrence.once('dataListUpdate', () => {
            openEditedNote(savedData.id || savedData.$id);
        });
        vm.compteur++
    }

    function toggleRow(rowDetails: any): void {
        rowDetails.$opened = !rowDetails.$opened;
    }

    function openEditedNote(id: number) {
        const rowDetails = vm.multiOccurrence.dataList.find((item) => item[vm.multiOccurrence.cleint] === id);
        if (rowDetails) {
            toggleRow(rowDetails);
        }
    }

    function getFonctionsEdition(): IPromise<boolean> {
        const id = vm.monoOccurrence.data[vm.monoOccurrence.cleint];
        const srccod = vm.ecranContextCtrl.stateParams.srccod;
        const requete = $resource(`${ApiConfig.ROOT}/mc1025/fonctions-edition/:notcleref`, {
            notcleref: id,
            srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails),
            ...ecranStateParams(vm.ecranContextCtrl.stateParams)
        });

        return requete.get().$promise
            .then((result: any) => {
                return !!result.isModificationPermise;
            });
    }
}
