import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IStateService } from 'angular-ui-router';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionImportationClass } from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageIn0055 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function IN0055Controller(
    in0028Route: IRoute,
    $state: IStateService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    Operateur: IOperateurService,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    FormulaireItemDivider: IFormulaireItemDividerClass
) {
    const vm: IPageIn0055 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true,
            importation: false
        },
        menus: [
            new MenuItemAction('LBL_BTN_DET', 'BOUDET', () => ({}), {
                saveFirst: true,
                iconButton: false,
                class: 'md-raised ex-button-secondary',
                successAction: () => {
                    $state.go(in0028Route.NAME);
                }
            }),
            new MenuItemAction('LBL_BTN_IMP', 'BOUIMP', () => ({}), {
                saveFirst: true,
                iconButton: false,
                class: 'md-raised ex-button-action',
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                hidden: () => vm.monoOccurrence.data.aut__decflg || !vm.monoOccurrence.data.aut__nbrlig,
                disabled: () => vm.monoOccurrence.data.aut__nbrenr && vm.monoOccurrence.data.decflg
            }),
            new MenuItemActionImportation('LBL_BTN_CHARGEMENT', 'BOUCHA', () => ({}), {
                iconButton: false,
                class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_CHARGEMENT',
                    largeurFormulaire: 15,
                    formulaire: new Formulaire([
                        new FormulaireItem('decnumchg', {
                            largeur: 100,
                            default: (data: any) => data.monoOccurrence.decnumchg
                        })
                    ])
                },
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                typesFichiers: ['.csv', '.txt'],
                hidden: () => vm.monoOccurrence.data.aut__nbrlig
            })
        ]
    };

    vm.menuBandeau = new Menu([]);
    vm.formulaire = new Formulaire('LBL_BLC_DET', [
        new FormulaireItem('decnumchg', { readonly: (data) => data.$id }),
        new FormulaireItem('decsrc', { readonly: true }),
        new FormulaireItemDivider('LBL_DIV_SOM'),
        new FormulaireItem('aut__nbrlig', { readonly: true })
    ]);
    vm.detMultiOccurrenceOptions = {
        fonctions: {
            editionRapide: true,
            edition: (data: any) => data.$blocs.dec.aut__decflg === 0,
            importation: false
        },
        filtres: [
            {
                colonne: 'decflgerr',
                operateur: Operateur.EGALE,
                valeur: '1'
            }
        ],
        colonnesFixesGauche: [
            { nom: 'decnumlig', largeur: 70 },
            { nom: 'decflgerrs', largeur: 100 }
        ],
        bloc: 'det',
        colonnesVisibles: [
            { nom: 'decmagcod', largeur: 90 },
            { nom: 'decmalcod', largeur: 120 },
            { nom: 'decprocod', largeur: 328 },
            { nom: 'decforcod', largeur: 80 },
            { nom: 'decqtephy', largeur: 197 }
        ],
        colonnesCachees: ['decmess'],
        colonnesEditionRapide: [
            { nom: 'decnumlig', largeur: 89 },
            { nom: 'decmagcod', largeur: 150 },
            { nom: 'decmalcod', largeur: 150 },
            { nom: 'decprocod', largeur: 220 },
            { nom: 'decforcod', largeur: 150 },
            { nom: 'decqtephy', largeur: 200 }
        ],
        formulaire: new Formulaire(
            [
                new FormulaireItem('decnumlig', { readonly: true }),
                new FormulaireItem('decmagcod'),
                new FormulaireItem('decmalcod'),
                new FormulaireItem('decprocod'),
                new FormulaireItem('decforcod'),
                new FormulaireItem('decqtephy')
            ],
            { largeurDefaut: 33 }
        )
    };

}
