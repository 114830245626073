import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr0068 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0068Controller(mr0067Route: IRoute) {
    const vm: IPageMr0068 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0067Route.NAME,
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false
        },
        colonnesVisibles: [
            { nom: 'cyrcod', largeur: 100 },
            { nom: 'cyrdsc', largeur: 280 },
            { nom: 'cyrcom', largeur: 480 },
            { nom: 'flgdefcli', largeur: 75 },
            { nom: 'flgdefsys', largeur: 85 }
        ]
    };
}
