import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";

interface IPageMc0000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mc0000Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMc0000 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false,
            supprime: false
        },
        formulaire: new Formulaire(
            [
                new FormulaireItem('vecvalcle'),
                new FormulaireItem('vecdsc', { largeur: 50 }),
                new FormulaireItem('vecflgact', { default: 1 }),
                new FormulaireItem('vecflgvar', { default: 0, titre: vm.valeursEcran.titrevar })
            ],
            { largeurDefaut: 25 }
        ),
        colonnesVisibles: [
            { nom: 'vecvalcle', largeur: 75 },
            { nom: 'vecdsc', largeur: 268 },
            { nom: 'vecflgact', largeur: 100 },
            { nom: 'vecflgvar', largeur: 137, titre: vm.valeursEcran.titrevar }
        ]
    };
}
