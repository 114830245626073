import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';


interface IMc1011Params {
    refcleint: string;
}
interface IPageMc1011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePdtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc1011Controller(Menu: IMenuClass,
                                         $stateParams: IMc1011Params,
                                         Formulaire: IFormulaireClass,
                                         Operateur: IOperateurService,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc1011 = this;

    vm.multiOccurrenceOptions = {
        filtres: [{
            colonne: 'refcleint',
            operateur: Operateur.EGALE,
            valeur: $stateParams.refcleint,
            visible: false
        }],
        colonnesVisibles: [
            {nom: 'vecsyscodret_dsc', largeur: 190},
            {nom: 'rredscret', largeur: 200},
            {nom: 'vecsyscodrel_dsc', largeur: 175},
            {nom: 'rredscrel', largeur: 200}
        ],
        colonnesCachees: [
            'refcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', {default: $stateParams.refcleint}),
            new FormulaireItem('vecsyscodret', {largeur: 25}),
            new FormulaireItem('rredscret', {largeur: 25}),
            new FormulaireItem('vecsyscodrel', {largeur: 25}),
            new FormulaireItem('rredscrel', {largeur: 25})

        ]),
    };
}
