import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IStateParamsService} from 'angular-ui-router';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRr0037 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceTPAOptions: IMultiOccurrenceOptions;
    tprMultiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0037Controller(mr0008Route: IRoute,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         $stateParams: IStateParamsService,
                                         Operateur: IOperateurService) {
    const vm: IPageRr0037 = this;

    vm.multiOccurrenceTPAOptions = {
        bloc: 'tpa',

        navigatePage: mr0008Route.NAME,
        navigateParams: (rowData: any) => (
            {
                id: rowData.apacleint,
                cleintref: rowData.tpacleint,
                stamnetab: 'TPA',
                vaesystypavi: 'PROB',
                supprime: 1,
                vaebmtent: 'TPA',
                typsai: vm.tprMultiOccurrence.getDataActiveRow().tprflgbqe === 1 ? 'BQE' : 'JRS',
                bqecleint: vm.tprMultiOccurrence.getDataActiveRow().tprflgbqe === 1 ? vm.tprMultiOccurrence.getDataActiveRow().bqecleint : null,
                tprcleint: vm.tprMultiOccurrence.getDataActiveRow().tprcleint
            }
        ),
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0008Route.NAME, (rowData: any) => {
                return {
                    cleintref: -1,
                    stamnetab: 'TPA',
                    vaesystypavi: 'PROB',
                    supprime: 1,
                    vaebmtent: 'TPA',
                    typsai: vm.tprMultiOccurrence.getDataActiveRow().tprflgbqe === 1 ? 'BQE' : 'JRS',
                    bqecleint: vm.tprMultiOccurrence.getDataActiveRow().tprflgbqe === 1 ? vm.tprMultiOccurrence.getDataActiveRow().bqecleint : null,
                    tprcleint: vm.tprMultiOccurrence.getDataActiveRow().tprcleint
                };
            })
        ]),

        colonnesVisibles: [
            {nom: 'apacib', largeur: 110},
            {nom: 'apatxt', largeur: 500}
        ],
        colonnesCachees: [
            'apacleint'
        ]
    };

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'tprcleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.tprcleint,
                visible: false
            }
        ],
        colonnesCachees: [
            'bqecleint',
            'tprcleint'
        ],
        colonnesFixesGauche: [
            {nom: 'tprcod', largeur: 90}
        ],
        colonnesVisibles: [
            {nom: 'tprdscabr', largeur: 263},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 263},
            {nom: 'tprflgpos', largeur: 100},
            {nom: 'tprflgbqe', largeur: 90},
            {nom: 'bqecod', largeur: 90},
            {nom: 'bqedscabr', largeur: 263},
            {nom: 'tprsoldecible', largeur: 150},
            {nom: 'esycod', largeur: 150},
            {nom: 'esydsc', largeur: 180},
            {nom: 'eclcod', largeur: 150},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'tafcodprb', largeur: 105},
            {nom: 'tafdscprb', largeur: 263},
            {nom: 'tafcodfinprb', largeur: 105},
            {nom: 'tafdscfinprb', largeur: 263},
            {nom: 'ttccod', largeur: 120},
            {nom: 'ttcdsc', largeur: 263},
            {nom: 'vaccodrai', largeur: 125},
            {nom: 'vacdscrai', largeur: 263},
            {nom: 'tprdsclng', largeur: 394}
        ],
        formulaire: new Formulaire('LBL_TITRE_TPR', [
            new FormulaireItem('tprcod'         , {required: true}),
            new FormulaireItem('tprdscabr'      , {required: true, modelOptions: {
                    updateOn: 'blur'
                }}),
            new FormulaireItem('urgcleint'      , {largeur: 50, required: true}),
            new FormulaireItem('tprflgpos'      , {required: true, default: 0}),
            new FormulaireItem('tprflgbqe'      , {required: true, default: 0}),
            new FormulaireItem('bqecleint'      , {nombreLibellesDetailsCacher: 1}),
            new FormulaireItem('tprsoldecible'),
            new FormulaireItem('esycleint'      ),
            new FormulaireItem('eclcleint'),
            new FormulaireItem('tafcleintprb'   ),
            new FormulaireItem('tafcleintfinprb'),
            new FormulaireItem('ttccleint'      ),
            new FormulaireItem('vactsmrai'      ),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tprdsclng'      , {largeur: 50})
        ], {largeurDefaut: 25}),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceTPAOptions
    };
}
