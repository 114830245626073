import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IMonoOccurrence } from "../../../../../core/services/mono-occurrence.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";

interface IPageRr0166 extends IComponentController {
    monoOccurrence: IMonoOccurrence
    durMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rdaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0166Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: any,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass) {

    const vm: IPageRr0166 = this;

    vm.$onInit = function $onInit() {
        vm.durMultiOccurrenceOptions = {
            actionsSelection: vm.listeAssures,
            rangeesSelectionnables: true,
            bloc: 'dur',
            colonnesVisibles: [
                { nom: 'durnom', largeur: 100 },
                { nom: 'durprn', largeur: 100 },
                { nom: 'durdatnai', largeur: 100 },
                { nom: 'vacdurass_dsc', largeur: 100 },
                { nom: 'durcondsc', largeur: 100 },
                { nom: 'durfumdsc', largeur: 100 }
            ],
            colonnesCachees: ["durflgrda"],
            actionsRangeeDroite: new Menu([
                new MenuItemAction(
                    'LBL_SELECTION_DIRECTE',
                    'BOUSELDIR',
                    () => ({}),
                    {
                        icon: 'check_circle_outline',
                        iconButton: true,
                        class:"ex-menu-item-visible",
                        successAction: () => vm.monoOccurrence.fetchData(),
                        hidden: (data: any) => data.durflgrda || $stateParams.flgstafin
                    }
                )
            ]),
            formulaire: new Formulaire([
                new FormulaireItem('durnom', { required: true }),
                new FormulaireItem('durprn', { required: true }),
                new FormulaireItem('durdatnai', { required: true }),
                new FormulaireItem('vaesyssex'),
                new FormulaireItem('durrang'),
                new FormulaireItem('vacdurass'),
                new FormulaireItem('durflgcon'),
                new FormulaireItem('durflgfum', { hidden: (data) => !ecranDetails.valeurs.asoflgfum || !data.durflgcon })
            ], { largeurDefaut: 33 })
        };

        // Assuré de la couverture
        vm.rdaMultiOccurrenceOptions = {
            bloc: 'rda',
            colonnesVisibles: [
                { nom: 'durnom', largeur: 100 },
                { nom: 'durprn', largeur: 100 },
                { nom: 'durdatnai', largeur: 100 },
                { nom: 'vaesyssex_dsc', largeur: 100 },
                { nom: 'rdaflgact', largeur: 100 },
                { nom: 'durcondsc', largeur: 100 },
                { nom: 'durfumdsc', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rdaflgact')
            ], { largeurDefaut: 33 })
        };
    }
}
