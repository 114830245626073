import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IMc0133Params {
    refcleint: number;
}

interface IPageMc0133 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireDnb: IFormulaire;
    formulaireBeneficiaire: IFormulaire;
    formulaireDonnebancaires: IFormulaire;
    formulaireBanqueintermediaire: IFormulaire;
}

/* @ngInject */
export default function Mc0133Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IMc0133Params,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc0133 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireDnb = new Formulaire('LBL_BLC_DNB', [
            new FormulaireItem('refcleint', {default: $stateParams.refcleint}),
            new FormulaireItem('dnbintins'),
            new FormulaireItem('dnbcodins'),
            new FormulaireItem('dnbtrsins'),
            new FormulaireItem('dnbintadrl01', {largeur: 100}),
            new FormulaireItem('dnbintadrl02', {largeur: 100}),
            new FormulaireItem('dnbintvil'),
            new FormulaireItem('dnbintpay'),
            new FormulaireItem('dnbintpro'),
            new FormulaireItem('dnbintzip'),
            new FormulaireItem('dnbinttel'),
            new FormulaireItemSautDeLigne()
        ], {largeurDefaut: 50});

        vm.formulaireBeneficiaire = new Formulaire('LBL_BLC_BEN', [
            new FormulaireItem('dnbnomben'),
            new FormulaireItem('dnbcptben'),
            new FormulaireItem('dnbadrl01ben', {largeur: 100}),
            new FormulaireItem('dnbadrl02ben', {largeur: 100}),
            new FormulaireItem('dnbvilben'),
            new FormulaireItem('dnbpayben'),
            new FormulaireItem('dnbproben'),
            new FormulaireItem('dnbzipben'),
            new FormulaireItem('dnbnumtelben')
        ], {largeurDefaut: 50});

        vm.formulaireDonnebancaires = new Formulaire('LBL_BLC_DON', [
            new FormulaireItem('dnbintbic'),
            new FormulaireItem('dnbintiba'),
            new FormulaireItem('dnbintaba'),
            new FormulaireItem('dnbintmod'),
            new FormulaireItem('dnbintaut', {largeur: 100})
        ], {largeurDefaut: 50});

        vm.formulaireBanqueintermediaire = new Formulaire('LBL_BLC_BAN', [
            new FormulaireItem('dnbnombqi'),
            new FormulaireItem('dnbadrl01bqi', {largeur: 100}),
            new FormulaireItem('dnbadrl02bqi', {largeur: 100}),
            new FormulaireItem('dnbvilbqi'),
            new FormulaireItem('dnbprobqi'),
            new FormulaireItem('dnbpaybqi'),
            new FormulaireItem('dnbzipbqi'),
            new FormulaireItem('dnbbicbqi')
        ], {largeurDefaut: 50});
    }
}
