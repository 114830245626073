import {IComponentController} from 'angular';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMr0128 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireAld: IFormulaire;
    formulaireInd: IFormulaire;
    aliMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aliMultiOccurrence: IMultiOccurrence;
}

interface IMr0128Params {
    menuId: number;
}

/* @ngInject */
export default function Mr0128Controller($stateParams: IMr0128Params,
                                         $state: IStateService,
                                         mr0128Route: IRoute,
                                         mr0314Route: IRoute,
                                         mr0315Route: IRoute,
                                         mr0316Route: IRoute,
                                         mr0317Route: IRoute,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass) {
    const vm: IPageMr0128 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_COPIE_ALD',
                    'BOUCOP',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE_ALD',
                            lblConfirm: 'G_LBL_BTN_OK',
                            largeurFormulaire: 25,
                            formulaire: new Formulaire([
                                new FormulaireItem('prfcleintcop'),
                                new FormulaireItemSautDeLigne(),

                                new FormulaireItem('urgcleintcop'),
                                new FormulaireItemSautDeLigne(),

                                new FormulaireItem('strcleintcop'),
                                new FormulaireItemSautDeLigne(),

                                new FormulaireItem('datevigueurcop'),
                                new FormulaireItemSautDeLigne(),

                                new FormulaireItem('aldflgsalcop',{default:0}),
                            ],{largeurDefaut: 100})
                        },
                        successAction: (retour:any) => {
                            $state.go(mr0128Route.NAME, {
                                menuId: $stateParams.menuId,
                                id: retour.newaldcleint
                            });
                        }
                    }
                )
            ])
        };

        const hasAnyAli = () => Boolean(vm.aliMultiOccurrence && vm.aliMultiOccurrence.dataList && vm.aliMultiOccurrence.dataList.length)

        // Absence longue durée
        vm.formulaireAld = new Formulaire( 'LBL_BLC_ALD', [
            new FormulaireItem('prfcleint'   , {largeur: 40, readonly: hasAnyAli}),
            new FormulaireItem('alddatvig'   , {largeur: 20}),
            new FormulaireItem('alddatfin'   , {largeur: 20}),
            new FormulaireItem('aldflgact'   , {largeur: 20, default : 1}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('urgcleint', {largeur: 40, readonly: hasAnyAli}),
            new FormulaireItem('strcleint', {largeur: 40, readonly: hasAnyAli}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('alddoc'   , {largeur: 100, enableCount: true}),
        ],{largeurDefaut: 20});

        // Section indicateur
        vm.formulaireInd = new Formulaire( 'LBL_BLC_IND', [
            new FormulaireItem('aldflgsal'   , {default : 0})
        ],{largeurDefaut: 100});


        vm.aliMultiOccurrenceOptions = {
            bloc: 'ali',
            fonctions: {
                importation: true
            },
            colonnesCachees: [
                'alicleint'
            ],
            colonnesFixesGauche: [
                { nom: 'alinbrann', largeur: 70 },
                { nom: 'alinbrmoi', largeur: 70 },
                { nom: 'alinbrjrs', largeur: 70 }
            ],
            colonnesVisibles: [
                {nom: 'vaealidatref_dsc', largeur: 150},
                {nom: 'vaccod'          , largeur: 120},
                {nom: 'vacdsc'          , largeur: 250},
                {nom: 'alidsc'          , largeur: 300}
            ],
            colonnesFixesDroite: [
                { nom: 'aliflgerr', largeur: 84 },
                { nom: 'flgatg'   , largeur: 84,
                    menu: [
                        new MenuItemEcran(
                            'BTN_MR0314',
                            mr0314Route.NAME,
                            (data: any) => ({
                                alicleint: data.alicleint
                            }),
                            {   icon: 'launch',
                                iconButton: true,
                                disabled: (data: any) => (data.aliflgerr),
                                onClose: () => vm.aliMultiOccurrence.fetchDataList()
                            }
                        )
                    ]
                },
                { nom: 'flgalg'   , largeur: 84,
                    menu: [
                        new MenuItemEcran(
                            'BTN_MR0315',
                            mr0315Route.NAME,
                            (data: any) => ({
                                alicleint: data.alicleint
                            }),
                            {   icon: 'launch',
                                iconButton: true,
                                disabled: (data: any) => (data.aliflgerr),
                                onClose: () => vm.aliMultiOccurrence.fetchDataList()
                            }
                        )
                    ]
                },
                { nom: 'flgalb'   , largeur: 84,
                    menu: [
                        new MenuItemEcran(
                            'BTN_MR0316',
                            mr0316Route.NAME,
                            (data: any) => ({
                                alicleint: data.alicleint
                            }),
                            {   icon: 'launch',
                                iconButton: true,
                                disabled: (data: any) => (data.aliflgerr),
                                onClose: () => vm.aliMultiOccurrence.fetchDataList()
                            }
                        )
                    ]
                },
                { nom: 'flgalr'   , largeur: 84,
                    menu: [
                        new MenuItemEcran(
                            'BTN_MR0317',
                            mr0317Route.NAME,
                            (data: any) => ({
                                alicleint: data.alicleint
                            }),
                            {   icon: 'launch',
                                iconButton: true,
                                disabled: (data: any) => (data.aliflgerr),
                                onClose: () => vm.aliMultiOccurrence.fetchDataList()
                            }
                        )
                    ]
                },
                { nom: 'aliflgexcrcedal'   , largeur: 94}
            ],

            formulaire: new Formulaire([
                new FormulaireItem('alinbrann'),
                new FormulaireItem('alinbrmoi'),
                new FormulaireItem('alinbrjrs'),
                new FormulaireItem('aliflgerr',{default: 0}),
                new FormulaireItem('aliflgexcrcedal',{default: 0}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaealidatref',{largeur: 20}),
                new FormulaireItem('vacalityp',{largeur: 30}),
                new FormulaireItem('alidsc',{largeur: 50})
            ], {largeurDefaut: 10})
        };
    }
}
