import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageRr0091 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    derMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Rr0091Controller(Formulaire: IFormulaireClass,
                                         Menu: IMenuClass,
                                         rr0093Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0091 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        resourceParams: {
            numref: vm.valeursEcran.numref
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage('G_LBL_DOSSIER_EMPLOYE',
                rr0093Route.NAME,
                (data: any) => ({
                    id: data.eplcleint
                }),
                {
                    icon: 'view_list',
                    fonction: 'BOUEMP',
                    iconButton: false
                })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('eplcleint', {required: false, resourceParams: {numref: vm.valeursEcran.numref}}),
            new FormulaireItem('ppaannperdeb'),
            new FormulaireItem('ppaannperfin'),
            new FormulaireItem('niv', {default: 1, direction: 'vertical'} )
        ]),
        colonnesCachees: [
            'eplcleint'
        ],
        colonnesVisibles: [
            {nom: 'eplnumref',largeur: 100},
            {nom: 'eplnomprn',largeur: 246},
            {nom: 'tagcod',largeur: 80},
            {nom: 'tagdsc',largeur: 246},
            {nom: 'ppacod',largeur: 85},
            {nom: 'patmntcal',largeur: 126}
        ]
    };
    vm.derMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 80},
            {nom: 'tagdsc', largeur: 304},
            {nom: 'dcemnt', largeur: 126}
        ]
    };
}
