import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IPaginationClass } from "../../../../../core/services/pagination.service";
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRr2005 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    adlMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function rr2005Controller(Pagination: IPaginationClass,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemSelection: IMenuItemSelectionClass,
    rr0030Route: IRoute,
    rr0031Route: IRoute) {
    const vm: IPageRr2005 = this;

    vm.adlMultiOccurrenceOptions = {
        navigatePage: rr0031Route.NAME,
        navigateParams: (data: any) => ({
            id: data.trncleint,
            demcleint: data.demcleint
        }),
        bloc: 'adl',
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 50, 100]
        }),
        fonctions: {
            libellesDynamiques: true,
            selectionCycle: true,
            edition: (data: any) => data.trncleint
        },
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_TAURED', 'BOUTAURED', {
                selection: {
                    nom: 'tauxred'
                },
                fonction: 'BOUTAURED'
            }),
            new MenuItemSelection('LBL_BTN_TAUREG', 'BOUTAUREG', {
                selection: {
                    nom: 'tauxreg'
                },
                fonction: 'BOUTAUREG'
            }),
            new MenuItemSelection('LBL_BTN_GEN', 'BOUTGEN', {
                selection: {
                    nom: 'bougen'
                },
                fonction: 'BOUTGEN'
            })
        ]),
        colonnesCachees: [
            'demcleint'
        ],
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'BTN_ADH',
                rr0030Route.NAME,
                (data: any) => ({
                    demcleint: data.demcleint,
                    efface_criteres: true
                })
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'adlqte', largeur: 100 },
            { nom: 'adlpredatdeb', largeur: 130 },
            { nom: 'placod_daa', largeur: 130 },
            { nom: 'pladsc_daa', largeur: 220 },
            { nom: 'aspcod_daa', largeur: 130 },
            { nom: 'aspdsc_daa', largeur: 220 },
            { nom: 'ecldsceta', largeur: 140 },
            { nom: 'placod_aut', largeur: 130 },
            { nom: 'pladsc_aut', largeur: 220 },
            { nom: 'aspcod_aut', largeur: 130 },
            { nom: 'aspdsc_aut', largeur: 220 },
            { nom: 'adlqtetrm', largeur: 130 },
            { nom: 'adldatdebmin', largeur: 110 },
            { nom: 'adlflggen', largeur: 110 },
            { nom: 'flgmsgerr', largeur: 110 },
            { nom: 'adlflgadhaut', largeur: 110 },
            { nom: 'adlflgtaured', largeur: 110, editable: (data: any) => data.trncleint },
            { nom: 'adlflgtaureg', largeur: 130, editable: (data: any) => data.trncleint }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('adlqte'),
            new FormulaireItem('adlpredatdeb'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('adlflgadhaut'),
            new FormulaireItem('adlflgtaured'),
            new FormulaireItem('adlflgtaureg'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('adlmsg', { largeur: 100 })
        ], { largeurDefaut: 33 }),
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 25, 50]
        }),
        colonnesVisibles: [
            { nom: 'aadcleint', largeur: 110 },
            { nom: 'aaddsc', largeur: 240 },
            { nom: 'ppacod', largeur: 90 },
            { nom: 'ppadatdeb', largeur: 130 },
            { nom: 'ppadatfin', largeur: 120 },
            { nom: 'usrnomcre', largeur: 262 },
            { nom: 'datcre', largeur: 160 },
            { nom: 'derdatdeb', largeur: 120 },
            { nom: 'derdatfin', largeur: 120 },
            { nom: 'trmdsc', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('aaddsc')
        ], { largeurDefaut: 100 }),
        multiOccurrenceOptionsEnfant: vm.adlMultiOccurrenceOptions
    };
}
