import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeConditionnelClass } from '../../../../../core/services/formulaire/formulaire-groupe-conditionnel.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageAc0011 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Ac0011Controller(Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForage: IMenuItemForageClass,
    exDateFilter: IFilterExDate,
    MenuItemAction: IMenuItemActionClass,
    ac0005Route: IRoute) {

    const vm: IPageAc0011 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres:
            new Formulaire([
                new FormulaireItem('caacleintcri', { largeur: 25 }),
                new FormulaireItem('intcleintachcri', { largeur: 25, required: true }),
                new FormulaireItem('etcdsccri', { largeur: 25 }),
                new FormulaireItem('datechcri', { largeur: 25, default: exDateFilter(new Date()), required: true })
            ]),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_ETC',
                ac0005Route.NAME,
                (rowData: any) => ({
                    id: rowData.etccleint
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUETC',
                    iconButton: true
                }
            )
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_COPIE',
                'BOUCOP',
                () => ({}),
                {
                    icon: 'change_circle',
                    iconButton: true,
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_PARAM_COPIE',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 50,
                        formulaire: new Formulaire([
                            new FormulaireItemDivider('LBL_PLAGE_RENOUV'),
                            new FormulaireItem('datdebren', {
                                largeur: 50,
                                required: true
                            }),
                            new FormulaireItem('datfinren', {
                                largeur: 50,
                                required: true
                            }),
                            new FormulaireItem('flgaffcondat', { hidden: true }),
                            new FormulaireItem('flgcondat', {
                                hidden: (data:any) => (data.flgaffcondat !== '1'),
                                largeur: 100
                            }),
                            new FormulaireItemDivider(),
                            new FormulaireItem('flgmntetr'),
                            new FormulaireItem('flgrefetc'),
                            new FormulaireItem('flgimpetc'),
                            new FormulaireItem('flgartetc'),
                            new FormulaireGroupeConditionnel(
                                'flgartetr',
                                'LBL_ART_ETR',
                                new Formulaire([
                                    new FormulaireItem('flgprxnegetr'),
                                    new FormulaireItem('flgmntalletr'),
                                    new FormulaireItem('flgqtealletr'),
                                    new FormulaireItem('flgtoletr')
                                ])
                                , { switch: true })
                        ]),
                    },  
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnomabr', largeur: 200 },
            { nom: 'etcnumdoc', largeur: 100 },
            { nom: 'etcdsc', largeur: 250 },
            { nom: 'etcnumdocseq', largeur: 80 },
            { nom: 'vaeetctyp_dsc', largeur: 120 },
            { nom: 'etcdatdeb', largeur: 120 },
            { nom: 'etcdatfin', largeur: 120 },
            { nom: 'etrdatdeb', largeur: 120 },
            { nom: 'etrdatfin', largeur: 120 },
            { nom: 'caacod', largeur: 140 },
            { nom: 'caadsc', largeur: 200 },
            { nom: 'intcodach', largeur: 120 },
            { nom: 'intnomach', largeur: 200 },
            { nom: 'intcodreq', largeur: 120 },
            { nom: 'intnomreq', largeur: 200 },
            { nom: 'etrmntetc', largeur: 126 },
            { nom: 'etrmntcon', largeur: 126 },
            { nom: 'etcflgitm', largeur: 100 },
            { nom: 'etcflgresitm', largeur: 100 }
        ],
        colonnesCachees: ['etccleint']
    };
}
