import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageAs0014 extends IComponentController {
    asfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    hreMultiOccurrenceOptions: IMultiOccurrenceOptions;
    exeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    resMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ralMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aatMultiOccurrenceOptions: IMultiOccurrenceOptions;
    exeMultiOccurrenceOpened: boolean;
    valeursEcran: any;
}

/* @ngInject */
export default function As0014Controller(Formulaire: IFormulaireClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: any) {
    const vm: IPageAs0014 = this;
    $stateParams.flginfor = parseInt($stateParams.flginfor);

    vm.valeursEcran = ecranDetails.valeurs;
    vm.exeMultiOccurrenceOpened = Boolean(vm.valeursEcran.flgexe);

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor
            },
            colonnesCachees: ['vaeasgmodhorrem'],
            formulaire: new Formulaire('LBL_BLC_ACY', [
                new FormulaireItem('eplnummat', { hidden: !$stateParams.demcleint, readonly: true }),
                new FormulaireItem('ahtcleint', { readonly: true, default: $stateParams.ahtcleint }),
                new FormulaireItemDivider('', { hidden: (data: any) => data.vaeahtman !== 'NON' }),
                new FormulaireItemDivider('LBL_TITRE_AHTMAN', { hidden: (data: any) => data.vaeahtman === 'NON' }),
                new FormulaireItem('ahthresem', { largeur: 25, readonly: true, default: $stateParams.ahthresem, required: true }),
                new FormulaireItem('unccleint', { largeur: 75, readonly: true, default: $stateParams.unccleint }),
                new FormulaireItem('tcycleint', { largeur: 50, required: true, modifAllow: false }),
                new FormulaireItem('acydatvig', { largeur: 25, required: true }),
                new FormulaireItem('acydursem', { largeur: 25, required: true, modifAllow: false }),
                new FormulaireItem('acydsc', { largeur: 100, required: true }),
                new FormulaireItem('acytmptrajrs', { largeur: 25, hidden: (data: any) => data.flgdal === 'DAL' }),
                new FormulaireItem('acytmpabssem', { largeur: 25, hidden: (data: any) => data.flgdal !== 'DAL', readonly: true }),
                new FormulaireItem('acytmptrasem', { largeur: 25, readonly: true }),
                new FormulaireItem('acytmpremsem', { largeur: 25, hidden: (data: any) => data.flgdal === 'DAL', modifAllow: false }),
                new FormulaireItem('acytmptot', { largeur: 25, hidden: (data: any) => data.flgdal !== 'DAL', readonly: true })
            ])
        };

        // Plage d'heure
        vm.asfMultiOccurrenceOptions = {
            bloc: 'asf',
            fonctions: {
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor,
                importation: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'asfnumsem', largeur: 100 },
                { nom: 'aqtcod1_jr1', largeur: 100 },
                { nom: 'aqtcod1_jr2', largeur: 100 },
                { nom: 'aqtcod1_jr3', largeur: 100 },
                { nom: 'aqtcod1_jr4', largeur: 100 },
                { nom: 'aqtcod1_jr5', largeur: 100 },
                { nom: 'aqtcod1_jr6', largeur: 100 },
                { nom: 'aqtcod1_jr7', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('asfnumsem', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('asfcleqr1jr1', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr1jr2', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr1jr3', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr1jr4', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr1jr5', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr1jr6', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr1jr7', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr2jr1', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr2jr2', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr2jr3', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr2jr4', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr2jr5', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr2jr6', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfcleqr2jr7', { resourceParams: () => ({ acycleint: $stateParams.id }) })
            ], { largeurDefaut: 100 / 7 })
        };

        // Plage d'heure 2
        vm.hreMultiOccurrenceOptions = {
            bloc: 'hre',
            fonctions: {
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor,
                importation: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'asfnumsem', largeur: 100 },
                { nom: 'aqtcod1_jr1', largeur: 100 },
                { nom: 'asfhredeb11', largeur: 100 },
                { nom: 'asfhrefin11', largeur: 100 },
                { nom: 'aqtcod1_jr2', largeur: 100 },
                { nom: 'asfhredeb21', largeur: 100 },
                { nom: 'asfhrefin21', largeur: 100 },
                { nom: 'aqtcod1_jr3', largeur: 100 },
                { nom: 'asfhredeb31', largeur: 100 },
                { nom: 'asfhrefin31', largeur: 100 },
                { nom: 'aqtcod1_jr4', largeur: 100 },
                { nom: 'asfhredeb41', largeur: 100 },
                { nom: 'asfhrefin41', largeur: 100 },
                { nom: 'aqtcod1_jr5', largeur: 100 },
                { nom: 'asfhredeb51', largeur: 100 },
                { nom: 'asfhrefin51', largeur: 100 },
                { nom: 'aqtcod1_jr6', largeur: 100 },
                { nom: 'asfhredeb61', largeur: 100 },
                { nom: 'asfhrefin61', largeur: 100 },
                { nom: 'aqtcod1_jr7', largeur: 100 },
                { nom: 'asfhredeb71', largeur: 100 },
                { nom: 'asfhrefin71', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('asfnumsem', { required: true, largeur: 100 }),
                new FormulaireItem('asfcleqr1jr1', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredeb1'),
                new FormulaireItem('asfhrefin1'),
                new FormulaireItem('asfcleqr1jr2', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredeb2'),
                new FormulaireItem('asfhrefin2'),
                new FormulaireItem('asfcleqr1jr3', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredeb3'),
                new FormulaireItem('asfhrefin3'),
                new FormulaireItem('asfcleqr1jr4', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredeb4'),
                new FormulaireItem('asfhrefin4'),
                new FormulaireItem('asfcleqr1jr5', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredeb5'),
                new FormulaireItem('asfhrefin5'),
                new FormulaireItem('asfcleqr1jr6', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredeb6'),
                new FormulaireItem('asfhrefin6'),
                new FormulaireItem('asfcleqr1jr7', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredeb7'),
                new FormulaireItem('asfhrefin7'),
                new FormulaireItem('asfcleqr2jr1', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredebqr2jr1'),
                new FormulaireItem('asfhrefinqr2jr1'),
                new FormulaireItem('asfcleqr2jr2', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredebqr2jr2'),
                new FormulaireItem('asfhrefinqr2jr2'),
                new FormulaireItem('asfcleqr2jr3', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredebqr2jr3'),
                new FormulaireItem('asfhrefinqr2jr3'),
                new FormulaireItem('asfcleqr2jr4', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredebqr2jr4'),
                new FormulaireItem('asfhrefinqr2jr4'),
                new FormulaireItem('asfcleqr2jr5', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredebqr2jr5'),
                new FormulaireItem('asfhrefinqr2jr5'),
                new FormulaireItem('asfcleqr2jr6', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredebqr2jr6'),
                new FormulaireItem('asfhrefinqr2jr6'),
                new FormulaireItem('asfcleqr2jr7', { resourceParams: () => ({ acycleint: $stateParams.id }) }),
                new FormulaireItem('asfhredebqr2jr7'),
                new FormulaireItem('asfhrefinqr2jr7')
            ], { largeurDefaut: 100 / 3 })
        };

        // Plage d'heure
        vm.exeMultiOccurrenceOptions = {
            bloc: 'exe',
            fonctions: {
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor,
                importation: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'asfnumsem', largeur: 100 },
                { nom: 'asfseqaff', largeur: 100 },
                { nom: 'actcod1', largeur: 100 },
                { nom: 'asfhredeb1', largeur: 100 },
                { nom: 'asfhrefin1', largeur: 100 },
                { nom: 'actcod2', largeur: 100 },
                { nom: 'asfhredeb2', largeur: 100 },
                { nom: 'asfhrefin2', largeur: 100 },
                { nom: 'actcod3', largeur: 100 },
                { nom: 'asfhredeb3', largeur: 100 },
                { nom: 'asfhrefin3', largeur: 100 },
                { nom: 'actcod4', largeur: 100 },
                { nom: 'asfhredeb4', largeur: 100 },
                { nom: 'asfhrefin4', largeur: 100 },
                { nom: 'actcod5', largeur: 100 },
                { nom: 'asfhredeb5', largeur: 100 },
                { nom: 'asfhrefin5', largeur: 100 },
                { nom: 'actcod6', largeur: 100 },
                { nom: 'asfhredeb6', largeur: 100 },
                { nom: 'asfhrefin6', largeur: 100 },
                { nom: 'actcod7', largeur: 100 },
                { nom: 'asfhredeb7', largeur: 100 },
                { nom: 'asfhrefin7', largeur: 100 },
                { nom: 'total', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('asfnumsem', { required: true, largeur: 50 }),
                new FormulaireItem('asfseqaff', { largeur: 50 }),
                new FormulaireItem('asfcleref1', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('asfhredeb1'),
                new FormulaireItem('asfhrefin1'),
                new FormulaireItem('asfcleref2', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('asfhredeb2'),
                new FormulaireItem('asfhrefin2'),
                new FormulaireItem('asfcleref3', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('asfhredeb3'),
                new FormulaireItem('asfhrefin3'),
                new FormulaireItem('asfcleref4', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('asfhredeb4'),
                new FormulaireItem('asfhrefin4'),
                new FormulaireItem('asfcleref5', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('asfhredeb5'),
                new FormulaireItem('asfhrefin5'),
                new FormulaireItem('asfcleref6', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('asfhredeb6'),
                new FormulaireItem('asfhrefin6'),
                new FormulaireItem('asfcleref7', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('asfhredeb7'),
                new FormulaireItem('asfhrefin7')
            ], { largeurDefaut: 100 / 3 })
        };

        // Résumé du cycle
        vm.resMultiOccurrenceOptions = {
            bloc: 'res',
            fonctions: {
                importation: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'asfnumsem', largeur: 100 },
                { nom: 'aqtcod1_jr1', largeur: 100 },
                { nom: 'aqtcod1_jr2', largeur: 100 },
                { nom: 'aqtcod1_jr3', largeur: 100 },
                { nom: 'aqtcod1_jr4', largeur: 100 },
                { nom: 'aqtcod1_jr5', largeur: 100 },
                { nom: 'aqtcod1_jr6', largeur: 100 },
                { nom: 'aqtcod1_jr7', largeur: 100 },
                { nom: 'total', largeur: 100 }
            ]
        };

        // Résumé du cycle ald
        vm.ralMultiOccurrenceOptions = {
            bloc: 'ral',
            fonctions: {
                importation: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'asfnumsem', largeur: 100 },
                { nom: 'qtetra1', largeur: 100 },
                { nom: 'qteabs1', largeur: 100 },
                { nom: 'qtetra2', largeur: 100 },
                { nom: 'qteabs2', largeur: 100 },
                { nom: 'qtetra3', largeur: 100 },
                { nom: 'qteabs3', largeur: 100 },
                { nom: 'qtetra4', largeur: 100 },
                { nom: 'qteabs4', largeur: 100 },
                { nom: 'qtetra5', largeur: 100 },
                { nom: 'qteabs5', largeur: 100 },
                { nom: 'qtetra6', largeur: 100 },
                { nom: 'qteabs6', largeur: 100 },
                { nom: 'qtetra7', largeur: 100 },
                { nom: 'qteabs7', largeur: 100 },
                { nom: 'totaltra', largeur: 100 },
                { nom: 'totalabs', largeur: 100 },
                { nom: 'total', largeur: 100 }
            ]
        };

        // TAG
        vm.aatMultiOccurrenceOptions = {
            bloc: 'aat',
            fonctions: {
                importation: true,
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'aatseqaff', largeur: 100 },
                { nom: 'tagcod1', largeur: 100 },
                { nom: 'aatqtejr1', largeur: 100 },
                { nom: 'aatmntjr1', largeur: 100 },
                { nom: 'tagcod2', largeur: 100 },
                { nom: 'aatqtejr2', largeur: 100 },
                { nom: 'aatmntjr2', largeur: 100 },
                { nom: 'tagcod3', largeur: 100 },
                { nom: 'aatqtejr3', largeur: 100 },
                { nom: 'aatmntjr3', largeur: 100 },
                { nom: 'tagcod4', largeur: 100 },
                { nom: 'aatqtejr4', largeur: 100 },
                { nom: 'aatmntjr4', largeur: 100 },
                { nom: 'tagcod5', largeur: 100 },
                { nom: 'aatqtejr5', largeur: 100 },
                { nom: 'aatmntjr5', largeur: 100 },
                { nom: 'tagcod6', largeur: 100 },
                { nom: 'aatqtejr6', largeur: 100 },
                { nom: 'aatmntjr6', largeur: 100 },
                { nom: 'tagcod7', largeur: 100 },
                { nom: 'aatqtejr7', largeur: 100 },
                { nom: 'aatmntjr7', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('aatseqaff', { readonly: true, largeur: 100 }),
                new FormulaireItem('aatcleref1'),
                new FormulaireItem('aatqtejr1'),
                new FormulaireItem('aatmntjr1'),
                new FormulaireItem('aatcleref2'),
                new FormulaireItem('aatqtejr2'),
                new FormulaireItem('aatmntjr2'),
                new FormulaireItem('aatcleref3'),
                new FormulaireItem('aatqtejr3'),
                new FormulaireItem('aatmntjr3'),
                new FormulaireItem('aatcleref4'),
                new FormulaireItem('aatqtejr4'),
                new FormulaireItem('aatmntjr4'),
                new FormulaireItem('aatcleref5'),
                new FormulaireItem('aatqtejr5'),
                new FormulaireItem('aatmntjr5'),
                new FormulaireItem('aatcleref6'),
                new FormulaireItem('aatqtejr6'),
                new FormulaireItem('aatmntjr6'),
                new FormulaireItem('aatcleref7'),
                new FormulaireItem('aatqtejr7'),
                new FormulaireItem('aatmntjr7')
            ], { largeurDefaut: 100 / 3 })
        };
    }
}
