import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigne} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFilterLibelle} from '../../../../../core/filters/ex-libelle.filter';

interface IPageMr0167 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0167Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         exLibelleFilter: IFilterLibelle,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigne,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageMr0167 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tadcod', largeur: 90},
            {nom: 'ttlcod', largeur: 95},
            {nom: 'taddsc', largeur: 370},
            {nom: 'tadflgdef', largeur: 78}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tadcod', {required: true}),
            new FormulaireItem('ttlcleint'),
            new FormulaireItem('taddsc', {required: true}),
            new FormulaireItem('tadflgdef')
            ],
            {largeurDefaut: 25}
        )
    };
}
