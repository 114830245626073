import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0148 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mr0148Controller(mr0224Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0148 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0224Route.NAME,
        navigateParams: (data: any) => {
            return {
                carra: data.flgcarra,
                ecran: $stateParams.ecran
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0224Route.NAME,(data: any) => {
                return {
                    carra: vm.valeursEcran.flgcarra,
                    ecran: $stateParams.ecran
                };
            })
        ]),
        colonnesVisibles: [
            {nom: 'mpdcod', largeur: 110},
            {nom: 'mpddsc', largeur: 300},
            {nom: 'mpedsc', largeur: 300},
            {nom: 'mpemsg', largeur: 300},
            {nom: 'mpddoc', largeur: 400},
            {nom: 'mpedoc', largeur: 400}
        ],
        colonnesCachees: [
            'flgcarra'
        ]
    };
}
