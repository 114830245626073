import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageMr0265 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions
}

/* @ngInject */
export default function Mr0265Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0265 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        
        vm.monoOccurrenceOptions = {
            noId: true,
            formulaire: new Formulaire('LBL_BLC_PHE', [
                new FormulaireItem('pheflgtaggen', {required: true}),
                new FormulaireItem('pheflgcolgen', {required: true}),
                new FormulaireItem('pheflgbqegen', {required: true}),
                new FormulaireItem('pheflgurggen', {required: true}),
                new FormulaireItem('pheflgstrgen', {required: true}),
                new FormulaireItem('pheflgtemgen', {required: true}),
                new FormulaireItem('pheflgtdagen', {required: true}),
                new FormulaireItem('pheflgrcegen', {required: true})
            ], {largeurDefaut: 100})
        };
    }
}
