import { IComponentController, IScope } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';

interface IPageAs0008 extends IComponentController {
    acyMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */
export default function As0008Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    $stateParams: any,
    as0024Route: IRoute,
    as0014Route: IRoute,
    as0009Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    $scope: IScope) {
    const vm: IPageAs0008 = this;
    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_AHT', [
            new FormulaireItem('eplnummat', { largeur: 20, hidden: !$stateParams.demcleint, readonly: true }),
            new FormulaireItem('asgcleint', { readonly: true, largeur: 40, default: $stateParams.asgcleint }),
            new FormulaireItemDivider('', { hidden: (data: any) => data.vaeahtman !== 'NON' }),
            new FormulaireItemDivider('LBL_TITRE_AHTMAN', { hidden: (data: any) => data.vaeahtman === 'NON' }),
            new FormulaireItem('ahtcod'),
            new FormulaireItem('ahtdsc'),
            new FormulaireItem('ahthresem'),
            new FormulaireItem('unccleint'),
            new FormulaireItem('afecleint'),
            new FormulaireItem('vaeahtman'),
            new FormulaireItem('modges', { hidden: true })
        ])
    };
    const removeWatch = $scope.$watch("vm.monoOccurrence.data.modges", () => {
        if (!vm.monoOccurrence || !vm.monoOccurrence.data || !vm.monoOccurrence.data.modges) return;

        removeWatch();
        let forage = new MenuItemForage(
            'G_LBL_HEB',
            as0024Route.NAME,
            (data: any) => ({
                id: data.acycleint,
                ahtcleint: data.ahtcleint,
                flginfor: $stateParams.flginfor,
                demcleint: $stateParams.demcleint,
                unccleint: vm.monoOccurrence.data.unccleint
            })
        );

        if (vm.monoOccurrence.data.modges === 'JRS') {
            forage = new MenuItemForage(
                'BTN_FORAGE_JRS',
                as0009Route.NAME,
                (data: any) => ({
                    id: data.acycleint,
                    ahtcleint: data.ahtcleint,
                    flginfor: $stateParams.flginfor,
                    demcleint: $stateParams.demcleint,
                    unccleint: vm.monoOccurrence.data.unccleint
                })
            )
        } else if (vm.monoOccurrence.data.modges === 'PFX') {
            forage = new MenuItemForage(
                'G_LBL_PFX',
                as0014Route.NAME,
                (data: any) => ({
                    id: data.acycleint,
                    ahtcleint: data.ahtcleint,
                    asgcleint: data.asgcleint,
                    flginfor: $stateParams.flginfor,
                    demcleint: $stateParams.demcleint,
                    unccleint: vm.monoOccurrence.data.unccleint
                })
            )
        }

        let actionNouveaux = new Menu([
            new MenuItemForage(as0024Route.NAME, (data: any) => ({
                ahtcleint: vm.monoOccurrence.data.ahtcleint,
                ahthresem: vm.monoOccurrence.data.ahthresem,
                unccleint: vm.monoOccurrence.data.unccleint
            }), {
                fonction: 'BOUNOUV',
                icon: '',
                disabled:($stateParams.flg!=0?false:true)
            })
        ]);

        if (vm.monoOccurrence.data.modges === 'JRS') {
            actionNouveaux = new Menu([
                new MenuItemForage(as0009Route.NAME, (data: any) => ({
                    ahtcleint: vm.monoOccurrence.data.ahtcleint,
                    ahthresem: vm.monoOccurrence.data.ahthresem,
                    unccleint: vm.monoOccurrence.data.unccleint
                }), {
                    fonction: 'BOUNOUV',
                    icon: '',
                    disabled:($stateParams.flg!=0?false:true)
                })
            ])
        } else if (vm.monoOccurrence.data.modges === 'PFX') {
            actionNouveaux = new Menu([
                new MenuItemForage(as0014Route.NAME, (data: any) => ({
                    ahtcleint: vm.monoOccurrence.data.ahtcleint,
                    ahthresem: vm.monoOccurrence.data.ahthresem,
                    unccleint: vm.monoOccurrence.data.unccleint,
                    asgcleint: data.asgcleint,
                }), {
                    fonction: 'BOUNOUV',
                    icon: '',
                    disabled:($stateParams.flg!=0?false:true)
                })
            ])
        }
        vm.acyMultiOccurrenceOptions = {
            bloc: 'acy',
            actionsNouveaux: actionNouveaux,
            fonctions: {
                importation: true
            },
            actionsRangeeDroite: new Menu([
                forage
            ]),
            colonnesCachees: ['acycleint', 'ahtcleint', 'asgcleint'],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'acydsc', largeur: 200 },
                { nom: 'tcydsc', largeur: 200 },
                { nom: 'acytmptrasem', largeur: 100 },
                { nom: 'acytmpremsem', largeur: 100 },
                { nom: 'acydatvig', largeur: 140 },
                { nom: 'acydursem', largeur: 100 }
            ]
        };
    })
}
