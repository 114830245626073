import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";

interface IPagePb0027 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulairePbl: IFormulaire;
    pbjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pbjMultiOccurrence: IMultiOccurrence;
    preMultiOccurrenceOptions: IMultiOccurrenceOptions;
    annpreMultiOccurrenceOptions: IMultiOccurrenceOptions;
    anncouMultiOccurrenceOptions: IMultiOccurrenceOptions;
    actionCopieSce1Sce2: IMenuItem;
    actionCopieSce1Sce3: IMenuItem;
    actionCopieSce2Sce3: IMenuItem;
    getLibelles(data:any): string;
}


/* @ngInject */
export default function Pb0027Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPagePb0027 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            updateDataWith: ['pbj'],
            fonctions: {
                exportation: false
            }
        };

        // Justifications
        vm.pbjMultiOccurrenceOptions = {
            bloc: 'pbj',
            successActionSauvegarde: () => {
                vm.monoOccurrence && vm.monoOccurrence.fetchData();
            },
        entetesReplacedValues: {
                ent_sce1: vm.valeursEcran.entpbj1,
                ent_sce2: vm.valeursEcran.entpbj2,
                ent_sce3: vm.valeursEcran.entpbj3,
                ent_annpre: vm.valeursEcran.lblentpre
            },
            fonctions: {
                editionRapide: true
            },
            colonnesVisibles: [
                {nom: 'pbjdsc'          , largeur: 300},
                {nom: 'el1cod'          , largeur: 130, hidden: (data:any) => !data.$valeurs.flgel1sai},
                {nom: 'el1dsc'          , largeur: 200, hidden: (data:any) => !data.$valeurs.flgel1sai},
                {nom: 'pbjmntpre'       , largeur: 120},
                {nom: 'pbjmntnetpre'    , largeur: 120},
                {nom: 'pbjmntsce1aff'   , largeur: 120},
                {nom: 'pbjmntnetsce1aff', largeur: 120},
                {nom: 'pbjmntsce2aff'   , largeur: 120},
                {nom: 'pbjmntnetsce2aff', largeur: 120},
                {nom: 'pbjmntsce3aff'   , largeur: 120},
                {nom: 'pbjmntnetsce3aff', largeur: 120},
                {nom: 'pbjflgobl'       , largeur: 120},
            ],
            colonnesEditionRapide: [
                {nom: 'pbjdsc'       , largeur: 300},
                {nom: 'el1cleint'    , largeur: 250, hidden: (data:any) => !data.$valeurs.flgel1sai },
                {nom: 'aut__pbjmntpre'    , largeur: 120},
                {nom: 'aut__pbjmntnetpre' , largeur: 120},
                {nom: 'pbjmntsce1'   , largeur: 120},
                {nom: 'pbjmntnetsce1', largeur: 120},
                {nom: 'pbjmntsce2'   , largeur: 120},
                {nom: 'pbjmntnetsce2', largeur: 120},
                {nom: 'pbjmntsce3'   , largeur: 120},
                {nom: 'pbjmntnetsce3', largeur: 120},
                {nom: 'pbjflgobl'    , largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pbjdsc', {largeur: 75}),
                new FormulaireItem('pbjflgobl', {largeur: 25}),
                new FormulaireItem('el1cleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemDivider(vm.valeursEcran.entpbj1),
                new FormulaireItem('pbjmntsce1'),
                new FormulaireItem('pbjmntnetsce1'),
                new FormulaireItemDivider(vm.valeursEcran.entpbj2),
                new FormulaireItem('pbjmntsce2'),
                new FormulaireItem('pbjmntnetsce2'),
                new FormulaireItemDivider(vm.valeursEcran.entpbj3),
                new FormulaireItem('pbjmntsce3'),
                new FormulaireItem('pbjmntnetsce3'),
            ], {largeurDefaut: 50})
        };

        // Action Copier version 1 vers version 2
        vm.actionCopieSce1Sce2 = new MenuItemAction(
            getLibelles(1),
            'BOUCOPSCE1SCE2',
            () => ({}),
            {
                icon: 'content_copy',
                iconButton: false,
                secondaire: true,
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        );

        // Action Copier version 1 vers version 3
        vm.actionCopieSce1Sce3 = new MenuItemAction(
            getLibelles(2),
            'BOUCOPSCE1SCE3',
            () => ({}),
            {
                icon: 'content_copy',
                iconButton: false,
                secondaire: true,
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        );

        // Action Copier version 2 vers version 3
        vm.actionCopieSce2Sce3 = new MenuItemAction(
            getLibelles(3),
            'BOUCOPSCE2SCE3',
            () => ({}),
            {
                icon: 'content_copy',
                iconButton: false,
                secondaire: true,
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        );

        // UBR de la Préparation budgétaire municipale
        vm.formulairePbl = new Formulaire('LBL_BLC_PBL', [
            new FormulaireItem('prucoddsc'),
            new FormulaireItem('pbgann'),
            new FormulaireItem('cptcoddsc'),
            new FormulaireItem('prscoddsc'),
            new FormulaireItem('unacoddsc'),
            new FormulaireItem('cifcoddsc'),
            new FormulaireItem('taxcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pblcom', {largeur: 100, enableCount: true})
        ], {largeurDefaut: 50});

        // Montants prévisionnels de l'année précédente
        vm.preMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'pvlannseq', largeur: 100},
                {nom: 'vldmntnet', largeur: 100}
            ]
        };

        // Montants de disponibilité de l'année précédente à l'année précédente de l'année budgétaire en cours
        vm.annpreMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tyhdsc'   , largeur: 100},
                {nom: 'ctvmntsum', largeur: 100}
            ]
        };

        // Montants de disponibilité de l'année précédente de l'année budgétaire en cours
        vm.anncouMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tyhdsc'   , largeur: 100},
                {nom: 'ctvmntsum', largeur: 100}
            ]
        };
    }

    function getLibelles(data:any) {
        if (data === 1) {
            return vm.valeursEcran.pbglblcop1;
        } else if (data === 2) {
            return vm.valeursEcran.pbglblcop2;
        } else if (data === 3) {
            return vm.valeursEcran.pbglblcop3;
        }
    }

}
