import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageCC0010 {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    usageimp: IMenuItem;
}
/* @ngInject */
export default function CC0010Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    mr0176Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    ecranDetails: IEcranDetailsResourcesEntite,
) {
    const vm: IPageCC0010 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire( [
            new FormulaireItem('unocleint'),
            // Bouton imputation
            new FormulaireItemDivider('LBL_PDA'),
            new FormulaireItem('uopflghabprf', { largeur: 20 }),
            new FormulaireItem('uopflgrsqann', { largeur: 20 }),
            new FormulaireItem('uopflgaff', { default: 1, largeur: 20 }),
            new FormulaireItem('uopflgavinoudel', { largeur: 20 }),
            new FormulaireItemDivider('LBL_BLC_AFF'),
            new FormulaireItem('nircleintaff'),
            new FormulaireItemDivider('LBL_PRF'),
            new FormulaireItem('postitabraff',{ default: ecranDetails.valeurs.postitabraff, 
                                                disabled: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eplnomprnaff',{ default: ecranDetails.valeurs.eplnomprnaff,
                                                disabled: true}),
            new FormulaireItem('epladrcouaff',{ default: ecranDetails.valeurs.epladrcouaff,
                                                disabled: true}),
            new FormulaireItem('etlnumtelaff',{ default: ecranDetails.valeurs.etlnumtelaff,
                                                largeur: 30, disabled: true}),
            new FormulaireItem('etlnumposaff',{ default: ecranDetails.valeurs.etlnumposaff,
                                                largeur: 20, disabled: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demcleintmsg'),
            new FormulaireItem('epladrcoumsg',{ default: ecranDetails.valeurs.epladrcoumsg, 
                                                disabled: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etlnumtelmsg',{ default: ecranDetails.valeurs.etlnumtelmsg,
                                                largeur: 30, disabled: true }),
            new FormulaireItem('etlnumposmsg',{ default: ecranDetails.valeurs.etlnumposmsg,
                                                largeur: 20, disabled: true }),
            new FormulaireItem('uopadrcouaff',{ largeur: 40, historiqueColonne: true  }),
            new FormulaireItemDivider('LBL_BLC_ATR'),
            new FormulaireItem('urgcleint', { largeur: 40 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tarcleint', { largeur: 40 }),
            new FormulaireItem('uopcomatr', { largeur: 100 })
        ])
    };

        // Usage d'imputation
        vm.usageimp = new MenuItemEcran(
            'LBL_USAGE_IMPUTATION',
            mr0176Route.NAME,
            () => ({
                stamnetab: 'UOP',
                ovdcleintref: vm.monoOccurrence.id
            }),
            {
                icon: 'view_list',
                iconButton: false
            }
        );
    }
