import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageZz0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceZchOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Zz0001Controller(Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageZz0001 = this;

    vm.multiOccurrenceZchOptions = {
        bloc: 'zch',
        fonctions: {
            criteresSuggeresVisibles: false
        },
        navigatePage: `secure.${ecranDetails.params.p_btn_det_lig.substring(0, 6).toLowerCase()}.ecran`,
        navigateParams: (data: any) => ({
            zchcleint: data.zchcleint
        }),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_IMP',
                'BOUIMP',
                (data: any) => ({
                    zsocleint: data.zsocleint,
                    usrcleint: data.usrcleint,
                    ciecleint: data.ciecleint,
                    zsonom: data.zsonom,
                    zchnumchg: data.zchnumchg
                }),
                {
                    icon: 'file_upload',
                    hidden: (data: any) => data.zchsta === 9,
                    successAction: () => vm.multiOccurrence.fetchDataList()
                }
            ),
            new MenuItemAction(
                'LBL_DET',
                'BOUDET',
                (data: any) => ({
                    usrcleint: data.usrcleint,
                    ciecleint: data.ciecleint,
                    zsocleint: data.zsocleint,
                    zsonom: data.zsonom,
                    zchnumchg: data.zchnumchg
                }),
                {
                    icon: 'delete',
                    hidden: (data: any) => data.zchsta === 9,
                    successAction: () => vm.multiOccurrence.fetchDataList()
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'zchnumchg', largeur: 230 },
            { nom: 'zchsta', largeur: 162 },
            { nom: 'zchdsc', largeur: 230 },
            { nom: 'nbrerr', largeur: 130 },
            { nom: 'nbrtot', largeur: 130 }
        ]
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'zsonom', largeur: 280 },
            { nom: 'zsodesc', largeur: 380 }
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceZchOptions
    };
}
