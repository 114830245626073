import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';

interface IPageMr2008 extends IController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRsdOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr2008Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: any
) {
    const vm: IPageMr2008 = this;

    vm.multiOccurrenceRsdOptions = {
        bloc: 'rsd',
        colonnesVisibles: [
            { nom: 'rsaord', largeur: 25 },
            { nom: 'stadsc', largeur: 25 },
            { nom: 'rsaflgbas', largeur: 25 },
            { nom: 'rsacom', largeur: 25 },
            { nom: 'vaersareg_dsc', largeur: 25 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rsaord', { largeur: 20 }),
            new FormulaireItem('objcleint', {
                largeur: 40, resourceParams: () => ({
                    eldcleint: vm.multiOccurrence.getDataActiveRow().eldcleint,
                    rsscleint: $stateParams.rsscleint
                })
            }),
            new FormulaireItem('rsaflgbas', { largeur: 20 }),
            new FormulaireItem('rsacom', { largeur: 60 }),
            new FormulaireItem('vaersareg', { largeur: 30 })
        ], { largeurDefaut: 25 })
    };

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'rsscleint', valeur: $stateParams.rsscleint, visible: false }
        ],
        colonnesVisibles: [
            { nom: 'ordre', largeur: 110 },
            { nom: 'eldcod', largeur: 130 },
            { nom: 'eldnom', largeur: 190 },
            { nom: 'sdecom', largeur: 190 },
        ],
        colonnesCachees: [
            'eldcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('sdeord', { largeur: 20 }),
            new FormulaireItem('rsscleint', { hidden: true, default: $stateParams.rsscleint }),
            new FormulaireItem('eldcleint', { largeur: 20, resourceParams: () => ({ rsscleint: $stateParams.rsscleint }) }),
            new FormulaireItem('sdecom', { largeur: 60 })
        ]),
        fonctions: {
            rechercheCollapsable: false,
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceRsdOptions
    };
}
