import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {INotificationHandler} from '../../../../../core/services/utils/notification-handler.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageIn0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0003Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         notificationHandler: INotificationHandler) {
    const vm: IPageIn0003 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    if (vm.valeursEcran.mesina) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesina
        });
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'st1cod', largeur: 90},
            {nom: 'st1dscabr', largeur: 230},
            {nom: 'st2cod', largeur: 90},
            {nom: 'st2dsc', largeur: 230},
            {nom: 'st2dscabr', largeur: 230}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('st1cleint', { largeur: 17}),
            new FormulaireItem('st2cod',    { largeur: 17}),
            new FormulaireItem('st2dsc',    { largeur: 33}),
            new FormulaireItem('st2dscabr', { largeur: 33})
        ])
    };
}
