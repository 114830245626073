import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0045 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0045Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0045 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'rrccod', largeur: 115 },
            { nom: 'rrcdsc', largeur: 272 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rrccod', { largeur: 25 }),
            new FormulaireItem('rrcdsc', { largeur: 75 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
