import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IOperateurService} from "../../../../../core/services/operateur.service";

interface IPageMc0095 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}
/* @ngInject */
export default function Mc0095Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: any,
                                         Operateur: IOperateurService) {
    const vm: IPageMc0095 = this;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'el3cleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.el3cleint,
                visible: false
            }
        ],
        colonnesVisibles: [
            {nom: 'blocod'   , largeur: 120},
            {nom: 'blodsc'   , largeur: 300},
            {nom: 'e3bprcdep', largeur: 160},
            {nom: 'e3bmntdep', largeur: 160}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('el3cleint', {
                default: $stateParams.el3cleint,
                hidden: true
            }),
            new FormulaireItem('blocleint'),
            new FormulaireItem('e3bprcdep'),
            new FormulaireItem('e3bmntdep')
        ], {largeurDefaut: 33})
    };
}
