import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
interface IPageCb0049 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    mntMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0049Controller(ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCb0049 = this;
    vm.$onInit = $onInit;
    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.mntMultiOccurrenceOptions = {
            fonctions: {
                suiviModification: false
            },
            bloc: 'mnt',
            colonnesVisibles: [
                { nom: 'tyhdsc', largeur: 383 },
                { nom: 'ctbmntsum', largeur: 126 }
            ],
            colonnesCachees: ['indcleint', 'vaesysnivappctr']
        };
    }
}
