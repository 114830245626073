import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCC0013 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pgtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pguMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0013Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass
) {
    const vm: IPageCC0013 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('', [
            new FormulaireItem('pagcleint'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('pagdsclng', { largeur: 100, enableCount: true }),
            new FormulaireItem('pagflgpubporemp'),
            new FormulaireGroupeAccordeon('LBL_PRE', new Formulaire([
                new FormulaireItem('pagdatpredeb', { largeur: 20 }),
                new FormulaireItem('pagdatprefin', { largeur: 20 }),
                new FormulaireItem('pagdatprefincen', { largeur: 20 }),
                new FormulaireItem('pagflgpreexcatr', { largeur: 10 })
            ], { largeurDefaut: 50 })),
            new FormulaireGroupeAccordeon('LBL_AFF', new Formulaire([
                new FormulaireItem('pagdataffdeb', { largeur: 20 }),
                new FormulaireItem('pagdatafffin', { largeur: 20 })
            ], { largeurDefaut: 50 })),
            new FormulaireGroupeAccordeon('LBL_ATR', new Formulaire([
                new FormulaireItem('pagdatatrdeb', { largeur: 20 }),
                new FormulaireItem('pagdatatrpub', { largeur: 20 }),
                new FormulaireItem('pagdatatrlimref', { largeur: 20 })
            ], { largeurDefaut: 50 })),
            new FormulaireGroupeAccordeon('LBL_DAT', new Formulaire([
                new FormulaireItem('pagdatanc', { largeur: 20 })
            ], { largeurDefaut: 50 })),
        ], { largeurDefaut: 50 })
    };

    // Trimestre à afficher
    vm.pgtMultiOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('trmcleint', { largeur: 50 })
        ]),
        colonnesVisibles: [
            { nom: 'trmcod', largeur: 40 },
            { nom: 'trmdsc', largeur: 130 }
        ]
    };

    // Unité admin.
    vm.pguMultiOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('unocleint', { largeur: 50 }),
            new FormulaireItem('pguflgavinoudel', { largeur: 20 }),
            new FormulaireItem('pguflgaffnou', { largeur: 20 })
        ]),
        colonnesVisibles: [
            { nom: 'unocod', largeur: 40 },
            { nom: 'unodscabr', largeur: 130 },
            { nom: 'pguflgavinoudel', largeur: 30 },
            { nom: 'pguflgaffnou', largeur: 30 }
        ]
    };
}
