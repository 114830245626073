import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageCp0013 extends IComponentController {
    formulaireReq: IFormulaire;
    driMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dovMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
}

/* @ngInject */
export default function Cp0013Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCp0013 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            selectionCycle: true
        },
        infosMonetaires: {
            blocs: ['dov'],
            afficherAuBas: "oui",
            donnees:
                [
                    { nom: 'req', libelle: 'LBL_MONTANT' },
                    { nom: 'sldvnt', libelle: 'LBL_MNTVNT' },
                    { nom: 'mntpay', libelle: 'LBL_MNTPAY' },
                    { nom: 'sldpay', libelle: 'LBL_SLDPAY' },
                    { nom: 'devise', libelle: 'LBL_DEVISE' }
                ]
        },
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        }
    };

    vm.formulaireReq = new Formulaire('LBL_BLC_REQ', [
        new FormulaireItem('refcleint'),
        new FormulaireItem('radcleint'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('reqnumdoc'),
        new FormulaireItem('reqnumdocseq'),
        new FormulaireItem('reqflgpij'),
        new FormulaireItem('tydcleint'),
        new FormulaireItem('aut__nbraju'),
        new FormulaireItem('reqindpaiuni'),
        new FormulaireItem('reqnumref'),
        new FormulaireItem('foccleint'),
        new FormulaireItem('reqnumphy'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('peccleint'),
        new FormulaireItem('reqdat'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('intcleintreq'),
        new FormulaireItem('intcleintdem'),
        new FormulaireItem('reqdsc', { enableCount: true, largeur: 100 })
    ], { largeurDefaut: 33.33 });

    vm.ReqCycFormulaire = new Formulaire('LBL_BLC_CYC', [
        new FormulaireItem('refcleint'),
    ]);

    vm.driMultiOccurrenceOptions = {
        bloc: 'dri',
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 140 },
            { nom: 'tydcod', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'docaffsysboncmd01', largeur: 140 },
            { nom: 'docsldcoudac', largeur: 126 },
            { nom: 'docsldcou', largeur: 126 },
            { nom: 'drimntutl', largeur: 126 },
            { nom: 'drimntdev', largeur: 126 },
            { nom: 'drimntret', largeur: 126 },
            { nom: 'drimntpayava', largeur: 126 }
        ],
        colonnesFixesDroite: [
            { nom: 'driflgfer', largeur: 126 },
            { nom: 'dacflgfer', largeur: 126 },
            { nom: 'inddis', largeur: 126 }
        ]
    };

    vm.dovMultiOccurrenceOptions = {
        bloc: 'dov',
        colonnesFixesGauche: [
            { nom: 'indrefint', largeur: 100 },
            { nom: 'dovimp', largeur: 260 },
            { nom: 'dovmnt', largeur: 126 }
        ],
        colonnesVisibles: [
            { nom: 'taxcod', largeur: 100 },
            { nom: 'remcod', largeur: 100 },
            { nom: 'dovnumrefint', largeur: 160 },
            { nom: 'dovmnttaxfed', largeur: 126 },
            { nom: 'dovmnttaxpro', largeur: 126 },
            { nom: 'dovmntremfed', largeur: 126 },
            { nom: 'dovmntrempro', largeur: 126 },
            { nom: 'dovmntdepnet', largeur: 126 },
            { nom: 'dovqte', largeur: 126 },
            { nom: 'unmcod', largeur: 120 },
            { nom: 'unmdsc', largeur: 180 },
            { nom: 'dovdsc', largeur: 180 },
            { nom: 'dovmntnetutl', largeur: 126 }
        ],
        colonnesFixesDroite: [
            { nom: 'dovflgref', largeur: 120 },
            { nom: 'dovflgmsg', largeur: 120 }
        ]
    };
}
