import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageAc0066 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
}

interface IAc0066Params {
    daccleint: string;
}

/* @ngInject */
export default function Ac0066Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IAc0066Params
) {

    const vm: IPageAc0066 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_LCOP', [
            new FormulaireItem('daccleint', { default: $stateParams.daccleint }),
            new FormulaireItem('aalnumdoc'),
            new FormulaireItem('aalflgren'),
            new FormulaireItem('aalmnt'),
            new FormulaireItem('aalmntpro'),
            new FormulaireItem('molcleint'),
            new FormulaireItem('vecmotoct'),
            new FormulaireItem('vecchofou'),
            new FormulaireItem('vecregenc'),
            new FormulaireItem('vecattarq'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aalseao'),
            new FormulaireItem('aaldatseao')
        ], { largeurDefaut: 33.33 })
    };
}

