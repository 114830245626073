import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageZz0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}
/* @ngInject */
export default function Zz0005Controller(zz0003Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageZz0005 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: zz0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                zlicleint: rowData.zlicleint
            };
        },
        colonnesFixesGauche: [
            {nom: 'nbrerr', largeur: 100},
            {nom: 'zliexc', largeur: 100},
            {nom: 'zrereqdsc', largeur: 270},
            {nom: 'zrereqdat', largeur: 150}
        ],
        colonnesVisibles: [          
            {nom: 'zrepeccod', largeur: 130},
            {nom: 'zretydcod', largeur: 130},
            {nom: 'zrereqcat', largeur: 123},
            {nom: 'zreetdcod', largeur: 123},
            {nom: 'zreentcod', largeur: 180},
            {nom: 'zremopcod', largeur: 130},
            {nom: 'zretyacod', largeur: 130},
            {nom: 'zreintcodreq', largeur: 135},
            {nom: 'zreintcoddem', largeur: 150},
            {nom: 'zreintcoddep', largeur: 150},
            {nom: 'zrerefcod', largeur: 130},
            {nom: 'zrereftyp', largeur: 230},
            {nom: 'zredevcod', largeur: 200},
            {nom: 'zreradnom', largeur: 160},
            {nom: 'zreradnomabr', largeur: 200},
            {nom: 'zreradadrl01', largeur: 150},
            {nom: 'zreradadrl02', largeur: 150},
            {nom: 'zreradvil', largeur: 220},
            {nom: 'zreradcodpos', largeur: 160},
            {nom: 'zreradnumtel', largeur: 160},
            {nom: 'zreradnumfax', largeur: 160},
            {nom: 'zregrpreq', largeur: 130},
            {nom: 'zreprucod', largeur: 130},
            {nom: 'zreprscod', largeur: 130},
            {nom: 'zrecifcod', largeur: 130},
            {nom: 'zreunacod', largeur: 130},
            {nom: 'zrecptcod', largeur: 130},
            {nom: 'zreel1cod', largeur: 130},
            {nom: 'zreel2cod', largeur: 130},
            {nom: 'zreel3cod', largeur: 130},
            {nom: 'zrereqmnt', largeur: 130},
            {nom: 'zreradcod', largeur: 130},
            {nom: 'zreradpro', largeur: 130},
            {nom: 'zreradpay', largeur: 130},
            {nom: 'zreradmod', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('zrereqdsc'),
            new FormulaireItem('zrereqdat'),
            new FormulaireItem('zrepeccod'),
            new FormulaireItem('zretydcod'),
            new FormulaireItem('zrereqcat'),
            new FormulaireItem('zreetdcod'),
            new FormulaireItem('zreentcod'),
            new FormulaireItem('zremopcod'),
            new FormulaireItem('zretyacod'),
            new FormulaireItem('zreintcodreq'),
            new FormulaireItem('zreintcoddem'),
            new FormulaireItem('zreintcoddep'),
            new FormulaireItem('zrerefcod'),
            new FormulaireItem('zrereftyp'),
            new FormulaireItem('zredevcod'),
            new FormulaireItem('zreradnom'),
            new FormulaireItem('zreradnomabr'),
            new FormulaireItem('zreradadrl01'),
            new FormulaireItem('zreradadrl02'),
            new FormulaireItem('zreradvil'),
            new FormulaireItem('zreradcodpos'),
            new FormulaireItem('zreradnumtel'),
            new FormulaireItem('zreradnumfax'),
            new FormulaireItem('zregrpreq'),
            new FormulaireItem('zreprucod'),
            new FormulaireItem('zreprscod'),
            new FormulaireItem('zrecifcod'),
            new FormulaireItem('zreunacod'),
            new FormulaireItem('zrecptcod'),
            new FormulaireItem('zreel1cod'),
            new FormulaireItem('zreel2cod'),
            new FormulaireItem('zreel3cod'),
            new FormulaireItem('zrereqmnt'),
            new FormulaireItem('zreradcod'),
            new FormulaireItem('zreradpro'),
            new FormulaireItem('zreradpay'),
            new FormulaireItem('zreradmod')
        ])
    };
}
