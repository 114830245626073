import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGs1003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs1003Controller() {
    const vm: IPageGs1003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false
        },
        colonnesVisibles: [
            { nom: 'apenom_modl', largeur: 100 },
            { nom: 'apedate_err', largeur: 100 },
            { nom: 'apeparam_modl', largeur: 200 },
            { nom: 'apedesc_err', largeur: 500 }
        ]
    };
}
