import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCr0033 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0033Controller(Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    mr0176Route: IRoute,
    mr0239Route: IRoute,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCr0033 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'chgcod', largeur: 126 }
        ],
        colonnesVisibles: [
            { nom: 'chgdsc', largeur: 190 },
            { nom: 'chgdatdeb', largeur: 110 },
            { nom: 'chgdatfin', largeur: 110 },
            { nom: 'chgflgt2202a', largeur: 110 },
            { nom: 'chgflgrel8', largeur: 110 },
            { nom: 'chgflgtax', largeur: 110 },
            { nom: 'chgflgdon', largeur: 110 },
            { nom: 'chgflgbou', largeur: 110 },
            { nom: 'taxcod', largeur: 110 },
            { nom: 'taxdscfed', largeur: 140 },
            { nom: 'taxdscpro', largeur: 140 },
            { nom: 'crgcod', largeur: 110 },
            { nom: 'crgdsc', largeur: 190 },
            { nom: 'rrvcod', largeur: 140 },
            { nom: 'rrvdsc', largeur: 190 },
            { nom: 'chgflgenn', largeur: 110 },
            { nom: 'flgrat', largeur: 130 }
        ],
        actionsMoreLigne: new Menu([
            new MenuItemForage('G_LBL_IMPUTATION',
                mr0176Route.NAME,
                (data: any) => ({
                    stamnetab: 'CHG',
                    ovdcleintref: data.chgcleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                }),
                new MenuItemForage('LBL_RATT',
                mr0239Route.NAME,
                (data: any) => ({
                    chgcleint: data.chgcleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                })
        ]),
        formulaire: new Formulaire([
            new FormulaireItem('chgcod', { largeur: 20 }),
            new FormulaireItem('chgdsc', { largeur: 40 }),
            new FormulaireItem('chgdatdeb', { largeur: 20 }),
            new FormulaireItem('chgdatfin', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('chgflgt2202a', { largeur: 2 }),
            new FormulaireItem('chgflgrel8', { largeur: 20 }),
            new FormulaireItem('chgflgtax', { largeur: 20 }),
            new FormulaireItem('chgflgdon', { largeur: 20 }),
            new FormulaireItem('chgflgbou', { largeur: 20 }),
            new FormulaireItem('taxcleint', { largeur: 20 }),
            new FormulaireItem('crgcleint', { largeur: 20 }),
            new FormulaireItem('rrvcleint', { largeur: 20 }),
            new FormulaireItem('chgflgenn', { largeur: 20 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
