import { IRoute } from '../../../../../core/interfaces/route.interface';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageTF0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0024Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass,
                                         tf0011Route: IRoute
                                         ) {
    const vm: IPageTF0024 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
         navigatePage: tf0011Route.NAME,
         actionsNouveaux: new Menu([
             new MenuItemForage(tf0011Route.NAME)        ]),
        colonnesFixesGauche: [
            {nom: 'efacod', largeur: 150},
            {nom: 'efanomprn', largeur: 225}
        ],       
        colonnesVisibles: [            
            {nom: 'folann', largeur: 110},
            {nom: 'folcod', largeur: 110},
            {nom: 'foldsc', largeur: 184},
            {nom: 'rfecod', largeur: 147},
            {nom: 'rfenom', largeur: 228},
            {nom: 'vaesyscodprv', largeur: 158},
            {nom: 'vaeftxtyp', largeur: 76},
            {nom: 'codref', largeur: 95},
            {nom: 'dscref', largeur: 158},
            {nom: 'ftxmnt', largeur: 150},
            {nom: 'ftxmntemr', largeur: 160},
            {nom: 'ftxcom', largeur: 262},
            {nom: 'ftxflguti', largeur: 76}
        ]
    };
}
