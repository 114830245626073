import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGs0029 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0029Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0029 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'elicod', largeur: 150},
            {nom: 'elidsc', largeur: 340},
            {nom: 'vaeelitypseqdsc', largeur: 130},
            {nom: 'eliflglib', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('elicod'),
            new FormulaireItem('elidsc'),
            new FormulaireItem('vaeelitypseq'),
            new FormulaireItem('eliflglib')
        ], {largeurDefaut: 25})
    };
}
