import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import IStateService = angular.ui.IStateService;

interface IPageRc2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IRc200Params {
    menuId: string;
}

/* @ngInject */
export default function Rc2000Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    rc2001Route: IRoute,
    $state: IStateService,
    $stateParams: IRc200Params,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRc2000 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            criteresSuggeresVisibles: true
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_RECEPTION',
                'BOUREC',
                () => ({}),
                {
                    icon: 'receipt',
                    iconButton: false,
                    successAction: (rowData: any) => $state.go(rc2001Route.NAME,
                        { prpcleint: rowData.prpcleint, daccleint: rowData.daccleint, vaesysmodcod: rowData.vaesysmodcod, menuId: $stateParams.menuId })
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_CRI'),
            new FormulaireItem('refcleint'),
            new FormulaireItem('tydcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintreq'),
            new FormulaireItem('magcleint', { hidden: true }),
            new FormulaireItem('docref', {
                resourceParams: (data: any) => ({
                    refcleint: data.refcleint,
                    magcleint: data.magcleint
                })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintach', { default: () => vm.valeursEcran.intcleintach }),
            new FormulaireItem('docsysboncmd', {
                resourceParams: (data: any) => ({
                    refcleint: data.refcleint,
                    magcleint: data.magcleint
                })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cifcleintsup'),
            new FormulaireItem('procleint')
        ]),
        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 140 },
            { nom: 'tydcod', largeur: 100 },
            { nom: 'sysboncmd', largeur: 150 },
            { nom: 'refcod', largeur: 126 },
            { nom: 'refnomabr', largeur: 180 },
            { nom: 'dacdat', largeur: 130 },
            { nom: 'txmdat', largeur: 130 },
            { nom: 'etcnumdoc', largeur: 140 },
            { nom: 'etcnumdocseq', largeur: 126 },
            { nom: 'vaeetctyp_dsc', largeur: 140 },
            { nom: 'etrdatdeb', largeur: 110 },
            { nom: 'etrdatfin', largeur: 110 },
            { nom: 'intcodreq', largeur: 126 },
            { nom: 'intnomreq', largeur: 180 },
            { nom: 'radnumtelpos', largeur: 160 },
            { nom: 'intcodach', largeur: 120 },
            { nom: 'intnomach', largeur: 180 }
        ],
        colonnesCachees: [
            'vaesysmodcod',
            'daccleint'
        ]
    };
}
