import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCo0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCo0004 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'ittcod', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'ittdsc', largeur: 245},
            {nom: 'ittdscabr', largeur: 185},            
            {nom: 'vacitttyp_cod', largeur: 135},
            {nom: 'vacitttyp_dsc', largeur: 245},
            {nom: 'ittadrl01', largeur: 146},
            {nom: 'ittadrl02', largeur: 146},
            {nom: 'ittadrl03', largeur: 146},
            {nom: 'ittvil', largeur: 160},
            {nom: 'pyscod', largeur: 80},
            {nom: 'pysnomfra', largeur: 160},
            {nom: 'etacod', largeur: 100},
            {nom: 'etanomfra', largeur: 160},
            {nom: 'ittcodpos', largeur: 100},
            {nom: 'ittnumtel', largeur: 100}
        ],
        colonnesCachees: [
            'vacitttyp_cod'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ittcod', {required: true}),
            new FormulaireItem('ittdsc', {required: true}),
            new FormulaireItem('ittdscabr'),
            new FormulaireItem('vacitttyp', {required: true}),
            new FormulaireItem('ittadrl01'),
            new FormulaireItem('ittadrl02'),
            new FormulaireItem('ittadrl03'),
            new FormulaireItem('ittvil', {largeur: 25}),
            new FormulaireItem('pyscleint', {largeur: 25}),
            new FormulaireItem('etacleint', {largeur: 25}),
            new FormulaireItem('ittcodpos', {largeur: 25}),
            new FormulaireItem('ittnumtel', {largeur: 25})
        ], {largeurDefaut: 33.33}),
        fonctions: {
            edition: true,
            importation: true,
            nouveau: true,
            supprime: true,
            suiviModification: true,
            rechercheCollapsable: false
        }
    };
}
