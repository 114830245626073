import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';


interface IPageAc0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0006Controller(
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    ac0005Route: IRoute ) {
    const vm: IPageAc0006 = this;
    

    vm.multiOccurrenceOptions = {
        fonctions: {  
            rechercheCollapsable: false
        },        
        navigatePage: ac0005Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(ac0005Route.NAME)        ]),

        colonnesFixesGauche: [
            {nom: 'etcnumdoc', largeur: 110}
        ],
        colonnesVisibles: [            
            {nom: 'vaeetcstu', largeur: 120},
            {nom: 'vaeetctyp', largeur: 180},
            {nom: 'refcod', largeur: 150},
            {nom: 'refnomabr', largeur: 220},
            {nom: 'founomabr', largeur: 190},
            {nom: 'etcdsc', largeur: 230},
            {nom: 'vaesysdevcod', largeur: 80},
            {nom: 'mnttot', largeur: 150},
            {nom: 'etcnumref', largeur: 150},
            {nom: 'intcodach', largeur: 150},
            {nom: 'intnomach', largeur: 190},
            {nom: 'aponumdoc', largeur: 120},
            {nom: 'etcnumao', largeur: 120},
            {nom: 'etcrefint', largeur: 160},
            {nom: 'entcod', largeur: 180},
            {nom: 'entdsc', largeur: 200},
            {nom: 'etcflgstu', largeur: 80},
            {nom: 'etcdatdeb', largeur: 120},
            {nom: 'etcdatfin', largeur: 120},
            {nom: 'etcdatmax', largeur: 120},
            {nom: 'etcdatimp', largeur: 120},
            {nom: 'srvcod', largeur: 120},
            {nom: 'srvdscabr', largeur: 180},
            {nom: 'etcflgitm', largeur: 120},
            {nom: 'usrcod', largeur: 180},
            {nom: 'nibcod', largeur: 120},
            {nom: 'nibnom', largeur: 120}
        ]
    };
}