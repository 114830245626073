import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageRr0033 extends IComponentController {
    formulaireDco: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dbfMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0033Controller( Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass ) {

    const vm: IPageRr0033 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.monoOccurrenceOptions = {
            updateDataWith: ['dbf']
        };

        vm.dbfMultiOccurrenceOptions = {
            bloc: 'dbf',
            colonnesVisibles: [
                {nom: 'dbfnomprn'       , largeur: 100},
                {nom: 'dbfflgact'       , largeur: 100},
                {nom: 'dbfflgrev'       , largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('dbfnom'),
                new FormulaireItem('dbfprn'),
                new FormulaireItem('vacliepar'),
                new FormulaireItem('vaesyssex'),
                new FormulaireItem('dbfflgact', {default: 1, largeur: 33}),
                new FormulaireItem('dbfflgrev', {default: 1, largeur: 33})
            ], {largeurDefaut: 50})
        };
    }
}
