import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemAction, IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMR0277 extends IComponentController {
    valeursEcran: any;
    menuItemCopie: IMenuItemAction;
    formulaireRcp: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rvaMultiOccurrence: IMultiOccurrence;
    rvaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pmtMultiOccurrence: IMultiOccurrence;
    pmtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mtaMultiOccurrence: IMultiOccurrence;
    mtaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mrvMultiOccurrence: IMultiOccurrence;
    mrvMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0277Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0277 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions= {
            fonctions: {
                suiviModification: false,
                exportation: false,
                boutonEnregistrerHaut: false,
                afficherMenusDansEntete: true
            }
        };

        vm.menuItemCopie =
            new MenuItemAction(
                'LBL_BTN_COPIE',
                'BOUCOP',
                () => ({}),
                {
                    iconButton: false,
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_COPIER',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('flgcoprgn', {
                                largeur: 100,
                                default: 0
                            })
                        ])
                    },
                    successAction: () => vm.mrvMultiOccurrence.fetchDataList()
                }
            );

        vm.formulaireRcp = new Formulaire([
            new FormulaireItem('rcecoddsc',{largeur: 25}),
            new FormulaireItem('ciecodnom',{largeur: 25}),
            new FormulaireItem('rcpdatvig')
        ],{largeurDefaut: 10});

        // Valeur RCE
        vm.rvaMultiOccurrenceOptions = {
            bloc: 'rva',
            colonnesVisibles: [
                {nom: 'rvanumval', largeur: 90},
                {nom: 'rvadsc', largeur: 280},
                {nom: 'rvaval', largeur: 120}
            ]
        };

        // Compagnies
        vm.pmtMultiOccurrenceOptions = {
            bloc: 'pmt',
            fonctions: {
                editionRapide: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('pmtcleintref'),
                new FormulaireItem('schcleint', { default: () => vm.valeursEcran.schcleint })
            ]),

            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ciecod',       largeur: 100},
                {nom: 'cienom',       largeur: 200}
            ],
            colonnesEditionRapide: [
                {nom: 'pmtcleintref', largeur: 400}
            ],
        };

        // Message du traitement
        vm.mrvMultiOccurrenceOptions = {
            bloc: 'mrv',
            colonnesVisibles: [
                {nom: 'mrvmsg', largeur: 1000}
            ]
        };

    }
}
