import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IScope, IComponentController } from 'angular';

interface IPageGL0016 extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Gl0016Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $scope: IScope) {
    const vm: IPageGL0016 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.$onInit = $oninit;

    vm.multiOccurrenceOptions = {
        resourceParamsDynamique: () => ({
            annee: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.annee,
            perdeb: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.perdeb,
            perfin: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.perfin
        }),
        fonctions: {
            rechercheCollapsable: false,
            importation: true,
            criteresSuggeresVisibles: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('annee', {
                required: true
            }),
            new FormulaireItem('perdeb', {
                required: true,
                default: 1
            }),
            new FormulaireItem('perfin', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            
            new FormulaireItem('obdcleint', {
                required: true
            }),
            new FormulaireItem('tymcleint', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),            
            new FormulaireItem('radio', {
                default: '0',
                required: true
            }),
            new FormulaireItem('impde'),
            new FormulaireItem('impa'),
        ]),
        colonnesVisibles: [
            {nom: 'v_val01', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL01},
            {nom: 'v_val02', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL02},
            {nom: 'v_val03', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL03},
            {nom: 'v_val04', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL04},
            {nom: 'v_val05', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL05},
            {nom: 'v_val06', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL06},
            {nom: 'v_val07', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL07},
            {nom: 'v_val08', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL08},
            {nom: 'v_val09', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL09},
            {nom: 'v_val10', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL10},
            {nom: 'v_val11', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL11},
            {nom: 'v_val12', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL12},
            {nom: 'v_val13', largeur: 100, hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.V_VAL13},
        ],
        colonnesFixesGauche: [
            {nom: 'imp', largeur: 250}
        ],
        colonnesFixesDroite: [
            {nom: 'v_solde', largeur: 100}
        ]
    };

    function $oninit() {
        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.annee', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });

        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.perdeb', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });

        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.perfin', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });
    }
}
