import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageTF0042 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0042Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageTF0042 = this;

    const filtres = [];

    if (ecranDetails.valeurs.tfhann) {
        filtres.push({
            colonne: 'tfhann',
            valeur: ecranDetails.valeurs.tfhann,
            readOnly: true
        });
    }

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres,
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'tfhann', largeur: 75},
            {nom: 'fpncod', largeur: 100},
            {nom: 'tibdsc', largeur: 225},
            {nom: 'tqival', largeur: 225},
            {nom: 'fpndsc', largeur: 262},
            {nom: 'tqicom', largeur: 262},
            {nom: 'tqiflggen', largeur: 80},
            {nom: 'tqiflguti', largeur: 76}
        ]
    };
}
