import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRr0109 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    chiMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0109Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass
                                        ) {

    const vm: IPageRr0109 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.multiOccurrenceOptions = {
            criteresSuggeres: new Formulaire([
                new FormulaireItem('cvtcleint',{
                    required: false
                }),
                new FormulaireItem('cvbcleint',{
                    required: false
                })
            ]),
            fonctions:{
                rechercheCollapsable:false
            },
            colonnesVisibles: [
                {nom: 'cvbcod', largeur:150},
                {nom: 'cvbdscabr', largeur:273},
                {nom: 'unccod', largeur:125},
                {nom: 'uncdsc', largeur:273},
                {nom: 'cvbqte', largeur:105,total:true},
                {nom: 'qte_utl', largeur:105,total:true},
                {nom: 'qte_sld', largeur:105,total:true}
            ]
        };

        vm.chiMultiOccurrenceOptions = {
            bloc: 'chi',
            colonnesVisibles : [
                {nom: 'chidat', largeur:105 },
                {nom: 'chiqte', largeur:105 },
                {nom: 'eplnummat', largeur:105 },
                {nom: 'eplnomprn', largeur:262 },
                {nom: 'tagcod', largeur:79 },
                {nom: 'tagdscabr', largeur:273 }
            ]
        };
    }
    
}