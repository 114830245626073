import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0262 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0262Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0262 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'bgncod'   , largeur: 160},
            {nom: 'bgndsc'   , largeur: 300},
            {nom: 'bgndscabr', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bgncod'),
            new FormulaireItem('bgndsc'),
            new FormulaireItem('bgndscabr')
        ], {largeurDefaut: 33})
    };
}
