import { IComponentController } from "angular";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";

interface IPageCp2007 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp2007Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm = this as IPageCp2007

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: "zczcod", largeur: 150 },
            { nom: "zczdsc", largeur: 300 },
            { nom: "vecreqcat", largeur: 100 },
            { nom: "zczflgact", largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem("zczcod"),
            new FormulaireItem("zczdsc"),
            new FormulaireItem("vecreqcat"),
            new FormulaireItem("zczflgact", { default: 0 })
        ])
    }
}