import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageGR0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gr0005Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         MenuItem: IMenuItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageGR0005 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItem('BTN_GRIEF', () => {}),
            new MenuItem('BTN_DEMANDE', () => {})
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'gsicod', largeur: 100},
            {nom: 'artcod', largeur: 158},
            {nom: 'artdscabr', largeur: 262},
            {nom: 'grfnumdoc', largeur: 90},
            {nom: 'grfdat', largeur: 100},
            {nom: 'vaetypgrf_dsc', largeur: 158},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'vaccod_nat', largeur: 80},
            {nom: 'vacdsc_nat', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'unocodrh', largeur: 110},
            {nom: 'unodscabrrh', largeur: 262},
            {nom: 'grfdsc', largeur: 262},
            {nom: 'gsidsc', largeur: 262},
            {nom: 'intcod', largeur: 110},
            {nom: 'grfnomdem', largeur: 262},
            {nom: 'grfrep', largeur: 262},
            {nom: 'intcod_rep', largeur: 100},
            {nom: 'intnom_rep', largeur: 262},
            {nom: 'grfdatrep', largeur: 100},
            {nom: 'gainumdoc', largeur: 120},
            {nom: 'gaidscabr', largeur: 262},
            {nom: 'mesemp', largeur: 262},
            {nom: 'mesdsc', largeur: 262},
            {nom: 'grfcom', largeur: 262},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75},
            {nom: 'flgerr', largeur: 75}
        ]
    };
}
