import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IStateParamsService} from 'angular-ui-router';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0238 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0238Controller(ae0004Route: IRoute, ae0005Route: IRoute, as0045Route: IRoute, do0053Route: IRoute, mr0016Route: IRoute, mr0134Route: IRoute,
                                         mr0101Route: IRoute, mr0102Route: IRoute, mr0103Route: IRoute, mr0111Route: IRoute, mr0114Route: IRoute, mr0152Route: IRoute,
                                         mr0116Route: IRoute, mr0119Route: IRoute, mr0121Route: IRoute, mr0122Route: IRoute, mr0126Route: IRoute,
                                         mr0164Route: IRoute, mr0175Route: IRoute, mr0193Route: IRoute, mr0211Route: IRoute, mr0222Route: IRoute,
                                         rr0037Route: IRoute, rr0112Route: IRoute, tf0010Route: IRoute, tf0016Route: IRoute, mr0163Route: IRoute, mr0128Route: IRoute,
                                         mr0110Route: IRoute, mr0156Route: IRoute, mr0003Route: IRoute, mr0182Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0238 = this;

    // à enlever quand tous les appels seront présent
    const accueilRoute = {NAME: 'secure.accueil'};

    const forageEcran = new MenuItemForage(
        'LBL_FOR_TYP',
        (data: any) => getForage(data),
        (data: any) => getParams(data),
        { hidden: (data: any) => data.ecrcod === null }
    );

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'vaetypfnd'},
            {colonne: 'cleintdet',visible: false}
        ],
        fonctions: {
            suiviModification: false,
            criteresSuggeresVisibles: true,
            rechercheCollapsable: false
        },

        criteresSuggeres: new Formulaire([

            new FormulaireItem('vaetypfnd', {
                required: true,
                default: $stateParams.vaetypfnd,
                largeur : 30,
                ignoreEtat: $stateParams.vaetypfnd,
                hidden: $stateParams.vaetypfnd
            }),
            new FormulaireItem('fnddatvig', {
                largeur : 30
            })
        ]),

        colonnesCachees:['cleintmai',
                         'cleintdet',
                         'ecrcod'],

        colonnesVisibles: [
            {nom: 'codrefdet'   , largeur: 150},
            {nom: 'dscrefdet'   , largeur: 250},
            {nom: 'ecrdsc'      , largeur: 250, menu: [forageEcran]},
            {nom: 'codrefmai'   , largeur: 120},
            {nom: 'dscrefmai'   , largeur: 250},
            {nom: 'codcle1'     , largeur: 110},
            {nom: 'codcle2'     , largeur: 110},
            {nom: 'datdebmai'   , largeur: 110}
        ]
    };



    function getForage(data: any): string {
        if (data.ecrcod === 'AE0004-00') {
            return ae0004Route.NAME;
        } else if (data.ecrcod === 'AE0005-00') {
            return ae0005Route.NAME;
        } else if (data.ecrcod === 'AS0045-00') {
            return as0045Route.NAME;
        } else if (data.ecrcod === 'DO0053-00') {
            return do0053Route.NAME;
        } else if (data.ecrcod === 'MR0016-00' ||
                   data.ecrcod === 'MR0016-01' ||
                   data.ecrcod === 'MR0016-02' ||
                   data.ecrcod === 'MR0016-03' ||
                   data.ecrcod === 'MR0016-04' ||
                   data.ecrcod === 'MR0016-05') {
            return mr0016Route.NAME;
        } else if (data.ecrcod === 'MR0101-00') {
            return mr0101Route.NAME;
        } else if (data.ecrcod === 'MR0102-00') {
            return mr0102Route.NAME;
        } else if (data.ecrcod === 'MR0103-00') {
            return mr0103Route.NAME;
        } else if (data.ecrcod === 'MR0111-00') {
            return mr0111Route.NAME;
        } else if (data.ecrcod === 'MR0114-00') {
            return mr0114Route.NAME;
        } else if (data.ecrcod === 'MR0116-00') {
            return mr0116Route.NAME;
        }  else if (data.ecrcod === 'MR0119-00') {
            return mr0119Route.NAME;
        } else if (data.ecrcod === 'MR0121-00') {
            return mr0121Route.NAME;
        } else if (data.ecrcod === 'MR0122-00') {
            return mr0122Route.NAME;
        } else if (data.ecrcod === 'MR0126-00') {
            return mr0126Route.NAME;
        } else if (data.ecrcod === 'MR0134-00') {
            return mr0134Route.NAME;
        } else if (data.ecrcod === 'MR0152-00') {
            return mr0152Route.NAME;
        } else if (data.ecrcod === 'MR0164-00') {
            return mr0164Route.NAME;
        } else if (data.ecrcod === 'MR0175-00') {
            return mr0175Route.NAME;
        } else if (data.ecrcod === 'MR0193-00') {
            return mr0193Route.NAME;
        } else if (data.ecrcod === 'MR0211-00') {
            return mr0211Route.NAME;
        } else if (data.ecrcod === 'MR0222-00') {
            return mr0222Route.NAME;
        } else if (data.ecrcod === 'RR0037-00') {
            return rr0037Route.NAME;
        } else if (data.ecrcod === 'RR0112-00') {
            return rr0112Route.NAME;
        } else if (data.ecrcod === 'TF0010-00') {
            return tf0010Route.NAME;
        } else if (data.ecrcod === 'TF0016-00') {
            return tf0016Route.NAME;
        } else if (data.ecrcod === 'MR0163-00') {
            return mr0163Route.NAME;
        } else if (data.ecrcod === 'MR0128-00') {
            return mr0128Route.NAME;
        } else if (data.ecrcod === 'MR0110-00') {
            return mr0110Route.NAME;
        } else if (data.ecrcod === 'MR0003-00') {
            return mr0003Route.NAME;
        } else if (data.ecrcod === 'MR0182-00') {
            return mr0182Route.NAME;
        } else if (data.ecrcod === 'MR0156-00') {
            return mr0156Route.NAME;
        } else {
            return accueilRoute.NAME;
        }
    }

    function getParams(data: any) {
        const MONOS = ['MR0111-00', 'MR0193-00', 'MR0101-00', 'MR0102-00', 'MR0114-00', 'MR0116-00',
            'MR0119-00', 'MR0126-00', 'MR0128-00', 'MR0134-00', 'MR0152-00', 'MR0110-00',
            'MR0156-00', 'MR0003-00', 'MR0182-00'];
        if (data.ecrcod === 'MR0016-00') {
            return { reference: data.cleintmai, ecran: '00-2' };  // AALD
        } if (data.ecrcod === 'MR0016-01') {
            return { reference: data.cleintmai, ecran: '01-2' };  // ADMP
        } if (data.ecrcod === 'MR0016-02') {
            return { reference: data.cleintmai, ecran: '02-2' };  // ATYP
        } if (data.ecrcod === 'MR0016-03') {
            return { reference: data.cleintmai, ecran: '03-2' };  // SDMP
        } if (data.ecrcod === 'MR0016-04') {
            return { reference: data.cleintmai, ecran: '04-2' };  // SHOR
        } else if (data.ecrcod === 'MR0016-05') {
            return { reference: data.cleintmai, ecran: '05-2' };  // CECT
        } else if (data.ecrcod === 'RR0037-00') {
            return { tprcleint: data.cleintmai };
        } else if (data.ecrcod === 'MR0103-00') {
            return { geccleint: data.cleintmai };
        } else if (MONOS.includes(data.ecrcod)) {
            return { id: data.cleintmai };
        } else {
            return { reference: data.cleintmai };
        }
    }
}
