import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0064 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0064Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0064 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false,
            nouveau: ecranDetails.valeurs.flgmod,
            edition: ecranDetails.valeurs.flgmod,
            supprime: ecranDetails.valeurs.flgmod,
            boutonDupliquer: ecranDetails.valeurs.flgmod
        },
        colonnesVisibles: [
            {nom: 'valcod', largeur: 105},
            {nom: 'valdsc', largeur: 175},
            {nom: 'clscod', largeur: 110},
            {nom: 'clsnom', largeur: 160},
            {nom: 'valcom', largeur: 340}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('valcod', {largeur: 10}),
            new FormulaireItem('valdsc', {largeur: 38}),
            new FormulaireItem('clscleint', {largeur: 30}),
            new FormulaireItem('valcom', {largeur: 81})
        ])
    };
}
