import {IComponentOptions, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import NotesFinanceController from './ex-notes-finance.controller';

export default module('app.components.ex-notes-finance', []).component('exNotesFinance', NotesFinanceFactory());

function NotesFinanceFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            {
                monoOccurrence: '<',
                lblTitre: '<?',
                closeAction: '<?',
                compteur: '=?',
                multiOccurrence: '=?multiOccurrenceOut',
                resourceParams: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: NotesFinanceController,
        controllerAs: 'vm',
        template: require('./ex-notes-finance.html')
    };
}
