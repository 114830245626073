import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'db2002',
    ecrans: ['00-2'],
    params: ['peccoddeb',
             'peccodfin',
             '?prucleint',
             '?cptcleint',
             '?el1cleint',
             '?el2cleint',
             '?el3cleint',
             '?unacleint',
             '?prscleint',
             '?cifcleint',
             'statut',
             'type_bdg',
             'det',
             '?blocleint',
             '?intcleint',
             '?sfucleint',
             '?vecprucatpro',
             '?grpcleint',
             '?soncleint',
             '?unacleintsup',
             '?vaesysnatctb'
    ]
});
