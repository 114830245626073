import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAs0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0021Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAs0021 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tcycod', largeur: 112 },
            { nom: 'tcydsc', largeur: 330 },
            { nom: 'tcyflgdef', largeur: 74 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tcycod', {required: true}),
            new FormulaireItem('tcydsc', {required: true}),
            new FormulaireItem('tcyflgdef')
        ])
    };
}
