import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCb2002 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    prbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tymMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb2002Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Pagination: IPaginationClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cb0050Route: IRoute,
    cb2001Route: IRoute,
    cb0026Route: IRoute,
    cb0031Route: IRoute,
    cb0004Route: IRoute,
    $stateParams: IStateParamsService
) {
    const vm: IPageCb2002 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const forageCB0050 = new MenuItemForage(
        'LBL_RESERVE',
        cb0050Route.NAME,
        (rowData: any) => ({
            id: $stateParams.prucleint,
            prucleint: $stateParams.prucleint,
            vaesysnatctb: vm.valeursEcran.vaesysnatctbcri,
            annfindeb: vm.valeursEcran.pecanncri,
            menuId: $stateParams.menuId
        }),
        {
            fonction: 'RESERVES',
            icon: 'exit_to_app',
            iconButton: true,
            hidden: (rowData: any) => rowData.mntrsv === null
        }
    )

    vm.tymMultiOccurrenceOptions = {
        bloc: 'tym',
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 250 },
            { nom: 'ctbmntsum', largeur: 130 },
            { nom: 'ctbmntimp', largeur: 120, total: true, hidden: $stateParams.niveau },
            { nom: 'mntrsv', largeur: 120, hidden: $stateParams.niveau, menu: [forageCB0050] }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        })
    };

    vm.prbMultiOccurrenceOptions = {
        bloc: 'prb',
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0004',
                cb0004Route.NAME,
                (rowData:any) => ({
                    id: $stateParams.prucleint,
                    prucleint: $stateParams.prucleint,
                    prbcleint: rowData.prbcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    pecann: $stateParams.pecann,
                    indcleint: $stateParams.indcleint,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0004',
                    icon: 'exit_to_app'
                }

            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0026',
                cb0026Route.NAME,
                (rowData:any) => ({
                    id: $stateParams.prucleint,
                    typapp: 'PRB',
                    typbdg: 'DB',
                    prucleint: $stateParams.prucleint,
                    prbcleint: rowData.prbcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    pecann: $stateParams.pecann,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0026',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0031',
                cb0031Route.NAME,
                (rowData:any) => ({
                    id: $stateParams.prucleint,
                    typapp: 'PRB',
                    typbdg: 'DB',
                    prucleint: $stateParams.prucleint,
                    prbcleint: rowData.prbcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    pecann: $stateParams.pecann,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0031',
                    icon: 'exit_to_app'
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pecanncri', {
                default: vm.valeursEcran.pecanncri,
                readonly: true,
                hidden: $stateParams.niveau
            }),
            new FormulaireItem('indcleintcri', {
                default: vm.valeursEcran.indcleintcri,
                readonly: true
            }),
            new FormulaireItem('vaesysnatctbcri', {
                default: vm.valeursEcran.vaesysnatctb,
                readonly: true,
                hidden: $stateParams.niveau
            }),
            new FormulaireItem('flgexczer', {
                default: 0
            })
        ]),
        colonnesVisibles: [
            { nom: 'prscod', largeur: 100, hidden: $stateParams.niveau },
            { nom: 'prsdscl01', largeur: 250, hidden: $stateParams.niveau },
            { nom: 'blocod', largeur: 100 }, 
            { nom: 'blodsc', largeur: 100, titre: ' ', hidden: !$stateParams.niveau  },           
            { nom: 'blodscabr', largeur: 250, hidden: $stateParams.niveau },
            { nom: 'mntsum', largeur: 130, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        multiOccurrenceOptionsEnfant: vm.tymMultiOccurrenceOptions
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cb2001Route.NAME,
                (rowData: any) => ({
                    type: 'PRB',
                    prucleint: $stateParams.prucleint,
                    prbcleint: rowData.prbcleint,
                    vaesysnatctb: vm.valeursEcran.vaesysnatctbcri,
                    tymcleint: rowData.tymcleint,
                    tydcleint: rowData.tydcleint,
                    pecann: vm.valeursEcran.pecanncri,
                    elecleint: $stateParams.elecleint,
                    niveau: $stateParams.niveau,
                    annper_deb: $stateParams.annper_deb,
                    annper_fin: $stateParams.annper_fin
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 130 },
            { nom: 'ctbmntsum', largeur: 120 }
        ],
        colonnesCachees: [
            'prbcleint',
            'tymcleint',
            'tydcleint'
        ]
    };
}
