import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0017Controller(gs0124Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0017 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0124Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0124Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'stanom', largeur: 210},
            {nom: 'stadsc', largeur: 300},
            {nom: 'stamnetab', largeur: 100}
        ]
    };
}
