import { IController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';


interface IPageCR0024 extends IController {
    formulaireCcr: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ccfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ccvMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0024Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    cr0007Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageCR0024 = this;

    vm.monoOccurrenceOptions = {
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_GENERE_IMP',
                'BOUGENIMP',
                () => ({}),
                {
                    icon: 'view_list',
                    fonction: 'BTN_GENIMP',
                    messageConfirmation: 'BOUDET_CONFIRM',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },

                }
            ),
        ]),
        formulaire: new Formulaire('LBL_BLC_CCR', [
            new FormulaireItem('refcleint', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ccrnumdoc', { largeur: 33.33 }),
            new FormulaireItem('ccrnumdocseq', { largeur: 33.33, readonly: true }),
            new FormulaireItem('etdcleint', { hidden: true, default: ecranDetails.valeurs.etdcleint }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tydcleint', { hidden: true, default: ecranDetails.valeurs.tydcleint }),
            new FormulaireItem('t_tydcod', { default: ecranDetails.valeurs.tydcod, largeur: 33.33, readonly: true }),
            new FormulaireItem('t_tyddsc', { default: ecranDetails.valeurs.tyddsc, largeur: 33.33, readonly: true }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vecccrcat', { largeur: 33.33 }),

            new FormulaireItem('peccleint', { largeur: 33.33 }),
            new FormulaireItem('ccrdat', { required: true, largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('intcleintreq', { largeur: 33.33 }),
            new FormulaireItem('ccrnumref', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ccrdsc', { largeur: 66.6, enableCount: true }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ccrmnt', { required: true, largeur: 33.33 }),
            new FormulaireItem('sldvnt', { largeur: 33.33, readonly: true, default: ecranDetails.valeurs.sldvnt }),
            new FormulaireItem('vaesysdevcod', { largeur: 33.33, readonly: true }),
        ])
    };

    vm.ccfMultiOccurrenceOptions = {
        bloc: 'ccf',
        colonnesVisibles: [
            {
                nom: 'sysfacnum', largeur: 200,
                menu: [
                    new MenuItemForage(
                        'G_LBL_FONC_TRANS_DOCUMENT',
                        cr0007Route.NAME,
                        (rowData: any) => {
                            return {
                                id: rowData.doccleintref
                            };
                        }
                    )
                ]
            },
            { nom: 'fcedatdue', largeur: 100 },
            { nom: 'fcesld', largeur: 100 },
            { nom: 'ccfmnt', largeur: 100 },
            { nom: 'facdat', largeur: 100 },
            { nom: 'facmnt', largeur: 100 },
            { nom: 'facdsc', largeur: 200 }
        ],
        colonnesCachees: [
            'doccleintref'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('fcecleint', {
                largeur: 25, required: true, resourceParams: () => {
                    return { refcleint: vm.monoOccurrence.data.refcleint }
                }
            }),
            new FormulaireItem('fcedatdue', { largeur: 15, readonly: true }),
            new FormulaireItem('fcesld', { largeur: 10, readonly: true }),
            new FormulaireItem('ccfmnt', { largeur: 15, required: true }),
            new FormulaireItem('facdat', { largeur: 10, readonly: true }),
            new FormulaireItem('facmnt', { largeur: 10, readonly: true }),
            new FormulaireItem('facdsc', { largeur: 100, readonly: true })
        ])
    };

    vm.ccvMultiOccurrenceOptions = {
        bloc: 'ccv',
        colonnesVisibles: [
            { nom: 'sysfacnum', largeur: 200 },
            { nom: 'ccvimp', largeur: 200 },
            { nom: 'ccvmnt', largeur: 200 },
            { nom: 'taxcod', largeur: 200 },
            { nom: 'ccvmnttaxfed', largeur: 200 },
            { nom: 'ccvmnttaxpro', largeur: 200 },
            { nom: 'ccvmntnet', largeur: 200 },
            { nom: 'ccvqte', largeur: 200 },
            { nom: 'ccvdsc', largeur: 200 },
            { nom: 'ccvflgmsg', largeur: 100 }
        ],
        colonnesCachees: [
            'prucleint',
            'ccvmsg'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ccfcleint', {
                largeur: 15, required: true, resourceParams: () => {
                    return { ccrcleint: vm.monoOccurrence.data.ccrcleint }
                }
            }),
            new FormulaireItem('ccvimp', { largeur: 50, required: true }),
            new FormulaireItem('ccvmnt', { required: true, largeur: 10 }),
            new FormulaireItem('taxcleint', { required: true, largeur: 10, default: ecranDetails.valeurs.defaulttaxcleint }),
            new FormulaireItem('ccvmnttaxfed', { required: true, largeur: 10 }),
            new FormulaireItem('ccvmnttaxpro', { required: true, largeur: 10 }),
            new FormulaireItem('ccvmntnet', { required: true, largeur: 10, readonly: true }),
            new FormulaireItem('ccvqte', { largeur: 10 }),
            new FormulaireItem('ccvdsc', { largeur: 10 }),
            new FormulaireItem('ccvflgmsg', { readonly: true, required: false, titre: " " })
        ])
    };

}
