import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0016',
    ecrans: [
            '00-2',
            "01-2",
            "02-2",
            "03-2",
            "05-2"
    ],
    params: [
        '?reference'
    ]
});

