import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr0040 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    inrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    posMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cepMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aviMultiOccurrenceOptions: IMultiOccurrenceOptions;
    secMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rpoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    afrMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0040Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0040 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('rolcod', {largeur: 60}),
            new FormulaireItem('rolflgact', {largeur: 40}),
            new FormulaireItem('roldscabr', {largeur: 60}),
            new FormulaireItem('rolflgunoobl', {largeur: 40}),
            new FormulaireItem('roldsc', {largeur: 60}),
            new FormulaireItem('rolflgstr', {largeur: 40})
        ])
    };

    vm.inrMultiOccurrenceOptions = {
        bloc: 'inr',
        fonctions: {
            suiviModification: false
        },
        colonnesVisibles: [
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 250 },
            { nom: 'inrdatdeb', largeur: 100 },
            { nom: 'inrdatfin', largeur: 100 },
            { nom: 'inrflgresuno', largeur: 100 },
            { nom: 'inrflgresdocrh', largeur: 100 },
            { nom: 'inrflgdel', largeur: 100 },
            { nom: 'inrsusavi_dsc', largeur: 200 }
        ]
    };

    vm.posMultiOccurrenceOptions = {
        bloc: 'pos',
        fonctions: {
            suiviModification: false
        },
        colonnesFixesGauche: [
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'postitabr', largeur: 210 }
        ],
        colonnesVisibles: [
            { nom: 'nomtit', largeur: 210 },
            { nom: 'unocodrh', largeur: 130 },
            { nom: 'unodscabrrh', largeur: 300 },
            { nom: 'tpocod', largeur: 130 },
            { nom: 'tpodsc', largeur: 210 },
            { nom: 'posdatdeb', largeur: 120 },
            { nom: 'posdatfin', largeur: 120 }
        ]
    };

    vm.cepMultiOccurrenceOptions = {
        bloc: 'cep',
        fonctions: {
            suiviModification: false
        },
        colonnesFixesGauche: [
            { nom: 'cyrcod', largeur: 100 },
            { nom: 'cyrdsc', largeur: 210 }
        ],
        colonnesVisibles: [
            { nom: 'cepflgcycbas', largeur: 100 },
            { nom: 'eclcod', largeur: 180 },
            { nom: 'ecldsceta', largeur: 250 },
            { nom: 'ecldscact', largeur: 250 },
            { nom: 'cepflgstaref', largeur: 100 },
            { nom: 'cepflgstafin', largeur: 100 },
            { nom: 'cepflgstafer', largeur: 100 },
            { nom: 'cepflgstaann', largeur: 100 },
            { nom: 'eclcom', largeur: 350 }
        ]
    };

    vm.aviMultiOccurrenceOptions = {
        bloc: 'avi',
        fonctions: {
            suiviModification: false
        },
        colonnesFixesGauche: [
            { nom: 'typ', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'texref', largeur: 250 },
            { nom: 'tex', largeur: 400 },
            { nom: 'stsdscini', largeur: 300 },
            { nom: 'stsdsccon', largeur: 300 },
            { nom: 'com', largeur: 300 },
            { nom: 'desflgcou', largeur: 100 },
            { nom: 'desflgtab', largeur: 100 }
        ]
    };

    vm.secMultiOccurrenceOptions = {
        bloc: 'sec',
        fonctions: {
            suiviModification: false
        },
        colonnesVisibles: [
            { nom: 'description', largeur: 459 }
        ]
    };

    vm.rpoMultiOccurrenceOptions = {
        bloc: 'rpo',
        fonctions: {
            suiviModification: false
        },
        colonnesFixesGauche: [
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'postitabr', largeur: 210 }
        ],
        colonnesVisibles: [
            { nom: 'nomtit', largeur: 210 },
            { nom: 'unocodrh', largeur: 130 },
            { nom: 'unodscabrrh', largeur: 300 },
            { nom: 'tpocod', largeur: 130 },
            { nom: 'tpodsc', largeur: 210 },
            { nom: 'rpodatdeb', largeur: 120 },
            { nom: 'rpodatfin', largeur: 120 }
        ]
    };

    vm.afrMultiOccurrenceOptions = {
        bloc: 'afr',
        fonctions: {
            suiviModification: false
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 210 }
        ],
        colonnesVisibles: [
            { nom: 'aftcod', largeur: 130 },
            { nom: 'aftdsc', largeur: 300 },
            { nom: 'lemcod', largeur: 130 },
            { nom: 'lemdsc', largeur: 300 },
            { nom: 'posnumdoc', largeur: 130 },
            { nom: 'postitabr', largeur: 300 },
            { nom: 'unocodrh', largeur: 130 },
            { nom: 'unodscabrrh', largeur: 300 },
            { nom: 'afrdatdeb', largeur: 120 },
            { nom: 'afrdatfin', largeur: 120 }
        ]
    };

}
