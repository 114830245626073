import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageCP0060 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0060Controller() {
    const vm: IPageCP0060 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        colonnesVisibles: [
            {nom: 'reqnumdoc', largeur: 135},
            {nom: 'reqnumdocseq', largeur: 65},
            {nom: 'refcod', largeur: 105},
            {nom: 'refnom', largeur: 234},
            {nom: 'tydcod', largeur: 65},
            {nom: 'drimntsai', largeur: 126},
            {nom: 'reqdsc', largeur: 236},
            {nom: 'reqdat', largeur: 100},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 220}
        ]
    };
}
