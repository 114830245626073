import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'gb0009',
    ecrans: ['00-2'],
    params: [
        '?gebcleint'
    ]
});

