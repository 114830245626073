import {IComponentOptions, IDirective, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import MultiOccurrenceMaitreDetailsEcranController from './ex-multi-occurrence-maitre-details-ecran.controller';
import {ITemplateService} from '../../../../core/services/template.service';

export default module('app.components.ex-multi-occurrence-maitre-details-ecran', [])
    // Double déclaration pour la prise en charge des templates
    .directive('exMultiOccurrenceMaitreDetailsEcran', MultiOccurrenceMaitreDetailsEcranTemplateFactory)
    .component('exMultiOccurrenceMaitreDetailsEcran', MultiOccurrenceMaitreDetailsEcranFactory());

/* @ngInject */
function MultiOccurrenceMaitreDetailsEcranTemplateFactory(template: ITemplateService): IDirective {
    return template.directive;
}

function MultiOccurrenceMaitreDetailsEcranFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            {
                lblTitre: '@?',
                multiOccurrence: '=?multiOccurrenceOut'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: {
            haut: '?exMultiOccurrenceMaitreDetailsEcranHaut',
            cardContentDroite: '?exMultiOccurrenceMaitreDetailsEcranCardContentDroite'
        },
        controller: MultiOccurrenceMaitreDetailsEcranController,
        controllerAs: 'vm',
        template: require('./ex-multi-occurrence-maitre-details-ecran.html')
    };
}
