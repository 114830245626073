import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRR0114 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0114Controller(Menu: IMenuClass,
                                         rr0005Route: IRoute,
                                         rr0022Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         Pagination: IPaginationClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         dateFilter: IFilterDate,
                                         MenuItemForage: IMenuItemForageClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0114 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: (rowData: any) => (rowData.nbrtrn > 1 ? rr0005Route.NAME : rr0022Route.NAME),
        navigateParams: (rowData: any) => {
                if (rowData.nbrtrn > 1) {
                    return {
                        id: rowData.lsacleint
                    };
                } else {
                    return {
                        id: rowData.trncleint
                    };
            };
        },
        colonnesCachees: [
            'nbrtrn',
            'lsacleint',
            'trncleint'
        ],
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0005Route.NAME)
        ]),
        fonctions: {
            criteresSuggeresVisibles: !$stateParams.employe,
            effaceCriteres: $stateParams.employe,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('dateactif', {
                default: dateFilter(new Date(), 'yyyy-MM-dd')                
            }),
            new FormulaireItem('eplnomprn'),
            new FormulaireItem('flglemact', {
                default: '1'
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('crieplcleint'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaetsatypsal'),            
            new FormulaireItem('vaetsatypprv')
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'lemcod', largeur: 126},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'tsadatvig', largeur: 100},
            {nom: 'geccod', largeur: 197},
            {nom: 'gecdsc', largeur: 262},
            {nom: 'geccat', largeur: 95},
            {nom: 'geccodcla', largeur: 90},
            {nom: 'typsaldsc', largeur: 197},
            {nom: 'echcod', largeur: 90},
            {nom: 'unccod', largeur: 90},
            {nom: 'tsasal', largeur: 126},
            {nom: 'tsdsal', largeur: 126},
            {nom: 'tsatot', largeur: 126},
            {nom: 'tsahreheb', largeur: 138},
            {nom: 'daedatvig', largeur: 100},
            {nom: 'lsaflgaugman', largeur: 120},
            {nom: 'lsadataugfor', largeur: 130},
            {nom: 'flgderech', largeur: 90},
            {nom: 'tsacom', largeur: 262},
            {nom: 'typprvdsc', largeur: 197},
            {nom: 'tagcod', largeur: 126},
            {nom: 'tagdscabr', largeur: 262},
            {nom: 'tagflgplus', largeur: 126}
         ]
    };
}
