import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';


interface IPageMc0150 {

    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0150Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0150 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vaetypdoc', largeur: 200},
            {nom: 'vaetypdoc_dsc', largeur: 200},
            {nom: 'atmtaimax', largeur: 200}

        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaetypdoc', {largeur: 50}),
            new FormulaireItem('atmtaimax', {largeur: 50})
        ])
    };
}
