import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCp2014 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dovMultiOccurrenceOptions: IMultiOccurrenceOptions;
    driMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cmpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp2014Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    mc0102Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageCp2014 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.monoOccurrenceOptions = {
        fonctions: {
            selectionCycle: true
        },
        infosMonetaires: {
            blocs: ['req'],
            afficherAuBas: "oui",
            donnees:
                [
                    { nom: 'req', libelle: 'LBL_MONTANT' },
                    { nom: 'sldvnt', libelle: 'LBL_MNTVNT' },
                    { nom: 'mntpay', libelle: 'LBL_MNTPAY' },
                    { nom: 'sldpay', libelle: 'LBL_SLDPAY', lastOfGroup: true  },
                    { nom: 'devise', libelle: 'LBL_DEVISE' }
                ]
        },
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        },
        menus: [
            new MenuItemForage(
                'G_LBL_COR_IMP',
                mc0102Route.NAME,
                (data: any) => ({ doccleintref: data.doccleint }),
                {
                    icon: 'settings',
                    compteur: (data: any) => (data && ((data.nbraju ?? 0) > 0)) ? data.nbraju : undefined,
                    iconButton: false,
                    fonction: 'BOUCORIMP',
                    disabled: (data: any) => !data.$id || ecranDetails.valeurs.flgre
                }
            )
        ]
    };
    vm.formulaireReq = new Formulaire('LBL_BLC_REQ', [
        new FormulaireItem('refcleint'),
        new FormulaireItem('radcleint'),
        new FormulaireItem('radnom'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('reqflgpij'),
        new FormulaireItem('reqflgverfin'),
        new FormulaireItem('reqflgref'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('reqnumdoc',),
        new FormulaireItem('tydcleint'),
        new FormulaireItem('tyacleint'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('reqnumref'),
        new FormulaireItem('reqflgpayuni'),
        new FormulaireItem('peccleint'),
        new FormulaireItem('reqdat'),
        new FormulaireItem('intcleintreq'),
        new FormulaireItem('intcleintdem'),
        new FormulaireItem('reqdatdep'),
        new FormulaireItem('reqdatret'),
        new FormulaireItem('reqnbrjrsext'),
        new FormulaireItem('reqdsc',{
            largeur:100
        }),
        new FormulaireGroupeAccordeon('Autres détails', new Formulaire([
            new FormulaireItem('lcpcleint'),
            new FormulaireItem('empcleintdep'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vecreqcat'),
            new FormulaireItem('mopcleint'),
            new FormulaireItem('reqnumrescd'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lotcleint'),
            new FormulaireItem('reqnumresca'),
            new FormulaireItem('reqdestination'),
            new FormulaireItem('reqvoyageur')
        ]))
    ], { largeurDefaut: 33.33 });

    vm.dovMultiOccurrenceOptions = {
        bloc: 'dov',
        colonnesFixesGauche: [
            { nom: 'imp', largeur: 250 }
        ],
        colonnesVisibles: [
            { nom: 'dovmnt', largeur: 110 },
            { nom: 'dovqte', largeur: 110 },
            { nom: 'dovmntdepnet', largeur: 110 },
            { nom: 'dovdsc', largeur: 110 },
            { nom: 'numrefint', largeur: 110 },
            { nom: 'dovmntnetutl', largeur: 110 },
            { nom: 'flgref', largeur: 110 },
            { nom: 'boumsg', largeur: 110 }
        ]
    }


    vm.driMultiOccurrenceOptions = {
        bloc: 'dri',
        colonnesVisibles: [
            { nom: 'docaffnumdoc01', largeur: 190 },
            { nom: 'docaffdsc01', largeur: 190 },
            { nom: 'tydcod', largeur: 190 },
            { nom: 'docaffsysboncmd01', largeur: 190 },
            { nom: 'docsldcou', largeur: 190 },
            { nom: 'drimntutl', largeur: 190 }
        ]
    };
    vm.cmpMultiOccurrenceOptions = {
        bloc: 'cmp',
        colonnesFixesGauche: [
            { nom: 'tydcod', largeur: 200 },
            { nom: 'docaffnumdoc', largeur: 200 },
            { nom: 'docaffnumdocseq', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'docaffmnt', largeur: 180 },
            { nom: 'peccod', largeur: 180 },
            { nom: 'etddsc', largeur: 180 },
            { nom: 'refcod', largeur: 180 },
            { nom: 'refnomabr', largeur: 180 },
            { nom: 'intcodreq', largeur: 180 },
            { nom: 'intnomreq', largeur: 180 },
            { nom: 'intcoddem', largeur: 180 },
            { nom: 'intnomdem', largeur: 180 },
            { nom: 'docnumrescd', largeur: 180 },
            { nom: 'docnumresca', largeur: 180 }
        ]
    };
}
