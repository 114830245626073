import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0337',
    ecrans: ['00-2'],
    params: [
        'bqecleint',
        'bpacleint'
    ]
});
