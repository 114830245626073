import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';

interface IPageCP0045 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0045Controller(Menu: IMenuClass,
                                         MenuItem: IMenuItemClass) {
    const vm: IPageCP0045 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage:???Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItem(() => {})
        ]),
        colonnesVisibles: [
            {nom: 'gcmnumdoc', largeur: 185},
            {nom: 'gcmdsc', largeur: 410},
            {nom: 'vaegcmstu', largeur: 105},
            {nom: 'nbrdoc', largeur: 105},
            {nom: 'mntdoc', largeur: 126}
        ]
    };
}
