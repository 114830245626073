import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {INotificationHandler} from '../../../../../core/services/utils/notification-handler.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageIN0042 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function In0042Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         notificationHandler: INotificationHandler,
                                         // in0043Route: IRoute,
                                         in0053Route: IRoute
                                         ) {
    const vm: IPageIN0042 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    // @TODO ajouter la route de forage une fois que l'écran est fait
    const in0043Route = {NAME: 'secure.accueil'};

    if (vm.valeursEcran.mesmag) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesmag
        });
        vm.multiOccurrence.disabledRow = () => true;
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup'),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        navigatePage: in0043Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(in0053Route.NAME)  // Critères de création du document d'achat
        ]),
        colonnesVisibles: [
            {nom: 'dacnumdoc',    largeur: 150},
            {nom: 'dacnumdocseq', largeur: 80},
            {nom: 'sysboncmd',    largeur: 150},
            {nom: 'dacseqave',    largeur: 120},
            {nom: 'tydcod',       largeur: 100},
            {nom: 'dacdsc',       largeur: 200},
            {nom: 'etddsc',       largeur: 150},
            {nom: 'magcod',       largeur: 90},
            {nom: 'magnom',       largeur: 150},
            {nom: 'refcod',       largeur: 120},
            {nom: 'refnom',       largeur: 200},
            {nom: 'founomabr',    largeur: 200},
            {nom: 'flgcou',       largeur: 100},
            {nom: 'flgnonrec',    largeur: 100},
            {nom: 'peccod',       largeur: 80},
            {nom: 'vaesysdevcod', largeur: 80},
            {nom: 'dacmnt',       largeur: 126},
            {nom: 'caacod',       largeur: 150},
            {nom: 'caadsc',       largeur: 150},
            {nom: 'etcnumdoc',    largeur: 200},
            {nom: 'etcnumdocseq', largeur: 80},
            {nom: 'etcdsc',       largeur: 200},
            {nom: 'intcodach',    largeur: 150},
            {nom: 'intnomach',    largeur: 200},
            {nom: 'intcodadem',   largeur: 150},
            {nom: 'intnomdem',    largeur: 200},
            {nom: 'intcodreq',    largeur: 150},
            {nom: 'intnomreq',    largeur: 200},
            {nom: 'vecdaccat',    largeur: 110},
            {nom: 'lipcod',       largeur: 100},
            {nom: 'reacod',       largeur: 100},
            {nom: 'readsc',       largeur: 200},
            {nom: 'flgerr_b2b',   largeur: 70},
            {nom: 'flgadhoc',     largeur: 110},
            {nom: 'intcodcib',    largeur: 200},
            {nom: 'intnomcib',    largeur: 200},
            {nom: 'usrcod',       largeur: 100},
            {nom: 'motcod',       largeur: 120},
            {nom: 'motdsc',       largeur: 200},
            {nom: 'flgtrans',     largeur: 90},
            {nom: 'flgimp',       largeur: 90},
            {nom: 'dacdat',       largeur: 110},
            {nom: 'dacdatbc',     largeur: 110},
            {nom: 'dacdatreq',    largeur: 110},
            {nom: 'dacdatree',    largeur: 110},
            {nom: 'dacdatdeb',    largeur: 120},
            {nom: 'dacdatfin',    largeur: 100},
            {nom: 'sldeng',       largeur: 126},
            {nom: 'dacnumcon',    largeur: 200},
            {nom: 'dpfnumdoc',    largeur: 200},
            {nom: 'aaonumdoc',    largeur: 120},
            {nom: 'aaotit',       largeur: 200},
            {nom: 'docnumref1', largeur: 200,
                                hidden: (data) => vm.valeursEcran.flgst1 !== 1,
                                replacedValue: () => (vm.valeursEcran.libst1)},
            {nom: 'docnumref2', largeur: 200,
                                hidden: (data) => vm.valeursEcran.flgst2 !== 1,
                                replacedValue: () => (vm.valeursEcran.libst2)},
            {nom: 'docnumref3', largeur: 200,
                                hidden: (data) => vm.valeursEcran.flgst3 !== 1,
                                replacedValue: () => (vm.valeursEcran.libst3)},
            {nom: 'docnumref4', largeur: 200,
                                    hidden: (data) => vm.valeursEcran.flgst4 !== 1,
                                    replacedValue: () => (vm.valeursEcran.libst4)},
            {nom: 'docnumref5', largeur: 200,
                                hidden: (data) => vm.valeursEcran.flgst5 !== 1,
                                replacedValue: () => (vm.valeursEcran.libst5)}
        ]
    };
}
