import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPagePr0025 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulairePdf: IFormulaire;    
}

/* @ngInject */
export default function Pr0025Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0025 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulairePdf = new Formulaire('LBL_BLC_GEN', [
            new FormulaireItem('refcleint', {largeur: 75}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etbcleint', {largeur: 75}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleintfin', {largeur: 75}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orpcleintfin', {largeur: 75}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_TITRES_TRAN'),
            new FormulaireItem('pdfflgrecu', {
                onValueChange: (data: any) => {
                    if (data.pdfflgrecu) {
                        data.pdfflgtran = 0;
                    }
                    if (!data.pdfflgtran && !data.pdfflgrecu ) {
                        data.pdfmntsub = '';
                    } 
                }
            }),
            new FormulaireItem('pdfflgtran', {
                onValueChange: (data: any) => {
                    if (data.pdfflgtran) {
                        data.pdfflgrecu = 0;
                    }
                    if (!data.pdfflgtran && !data.pdfflgrecu ) {
                        data.pdfmntsub = '';
                    }                    
                }
            }),
            new FormulaireItem('pdfmntsub'),
            new FormulaireItemDivider(''),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pdfflgpri'),
            new FormulaireItem('pdfflgsldfdsnonrst'),
            new FormulaireItem('pdfflgrecsld'),
            new FormulaireItem('peccleintsld')
        ], {largeurDefaut: 25});
    }      
}
