import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0232 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0232Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0232 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'temcod', largeur: 90}
        ],
        colonnesVisibles: [
            {nom: 'temdscabr', largeur: 262},
            {nom: 'geccod', largeur: 90},
            {nom: 'gecdsc', largeur: 262},
            {nom: 'tgrdatfin', largeur: 100},
            {nom: 'geccat', largeur: 100},
            {nom: 'geccodcla', largeur: 90},
            {nom: 'unccod', largeur: 90},
            {nom: 'uncdsc', largeur: 210}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('temcleint'   , {
                    resourceParams: (data: any) => {
                        return {
                            geccleint: data.geccleint
                        };
                    },
                    modifAllow:false, largeur: 50}),
                new FormulaireItem('geccleint'   , {
                    resourceParams: (data: any) => {
                        return {
                            temcleint: data.temcleint
                        };
                    },
                    modifAllow:false, largeur: 50}),
                new FormulaireItem('tgrdatfin'   , {largeur: 20}),
                new FormulaireItem('t_geccat'      , {readonly: true, modifAllow:false, largeur: 20}),
                new FormulaireItem('t_geccodcla'   , {readonly: true, modifAllow:false, largeur: 20}),
                new FormulaireItem('t_unccod'      , {readonly: true, modifAllow:false, largeur: 20}),
                new FormulaireItem('t_uncdsc'      , {readonly: true, modifAllow:false, largeur: 20})
            ]
        )
    };
}
