import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMr0111 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    formulaireUrg: IFormulaire;
    formulaireParam: IFormulaire;
    uteMultiOccurrenceOptions: IMultiOccurrenceOptions;
    uacMultiOccurrenceOptions: IMultiOccurrenceOptions;
    uarMultiOccurrenceOptions: IMultiOccurrenceOptions;
    uraMultiOccurrenceOptions: IMultiOccurrenceOptions;
    uflMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0111Controller(mr0238Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageMr0111 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            actionsMore: new Menu([
                new MenuItemForage(
                    'LBL_MR0238',
                    mr0238Route.NAME,
                    (data:any) => ({vaetypfnd: 'URG',
                                    cleintdet: data.urgcleint}),
                    {
                        icon: 'assessment',
                        iconButton: false
                    }
                )
            ])
        };

        // Unité de regroupement
        vm.formulaireUrg = new Formulaire('LBL_BLC_URG', [
            new FormulaireItem('cvtcleint'),
            new FormulaireItem('urgflgact', {
                default: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcod'),
            new FormulaireItem('urgdsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgdscabr'),
            new FormulaireItem('urgadrcou')
        ]);

        // Paramètres
        vm.formulaireParam = new Formulaire('LBL_BLC_PRM', [
            new FormulaireItem('urgflggthobl', {largeur: 100}),
            new FormulaireItem('urgflgaftobl', {largeur: 100}),
            new FormulaireItem('urgflgret', {largeur: 100, default: 0})
        ]);

        // Titre emploi
        vm.uteMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'temcod', largeur: 110},
                {nom: 'temdsc', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('temcleint', {
                    resourceParams: () => ({
                        cqscleint: vm.monoOccurrence.data.cqscleint
                    }),
                    largeur: 100
                })
            ]),
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_COPIE_UTE',
                    'BOUCOP',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        successAction: () => {
                            vm.uteMultiOccurrence.fetchDataList();
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE_UTE',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 40,
                            actionsSecondaires: [
                                new MenuItemAction('LBL_VAL_UTE', 'BOUVAL', () => ({}), {
                                    iconButton: false
                                })
                            ],
                            formulaire: new Formulaire([
                                new FormulaireItem('temcleintori'),
                                new FormulaireItem('temcleintdst')
                            ])
                        }
                    }
                )
            ])
        };

        // Avantages sociaux
        vm.uacMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesFixesGauche: [
                {nom: 'avsdsc', largeur: 300}
            ],
            colonnesVisibles: [
                {nom: 'vaeuacapp_desc', largeur: 200},
                {nom: 'strcod', largeur: 150},
                {nom: 'strdsc', largeur: 300},
                {nom: 'tdmcod', largeur: 215},
                {nom: 'tdmdsc', largeur: 350},
                {nom: 'vaeuactyp_desc', largeur: 200},
                {nom: 'avcdsc', largeur: 250}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('avscleint', {largeur: 100}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaeuacapp'),
                new FormulaireItem('strcleint', {
                    resourceParams: () => ({
                        urgcleint: vm.monoOccurrence.data.urgcleint
                    })
                }),
                new FormulaireItem('tdmcleint', {largeur: 100}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaeuactyp'),
                new FormulaireItem('avccleintdef', {
                    resourceParams: (data:any) => {
                        return {
                            avscleint: data.avscleint
                        };
                    }
                })
            ])
        };

        // RCE automatique
        vm.uraMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'rcecod', largeur: 110},
                {nom: 'rcedsc', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rcecleint', {largeur: 100})
            ])
        };

        // Arrêt rémunération automatique
        vm.uarMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'uardatdeb', largeur: 100},
                {nom: 'uardatfin', largeur: 100},
                {nom: 'tagcod', largeur: 100},
                {nom: 'tagdscabr', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('uardatdeb', {largeur: 25}),
                new FormulaireItem('uardatfin', {largeur: 25}),
                new FormulaireItem('tagcleint', {largeur: 50})
            ])
        };

        // Fermeture le
        vm.uflMultiOccurrenceOptions = {
            bloc: 'ufl',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'strcod', largeur: 100},
                {nom: 'strdsc', largeur: 300},
                {nom: 'ttccod', largeur: 120},
                {nom: 'ttcdsc', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('strcleint', {
                    resourceParams: () => ({
                        urgcleint: vm.monoOccurrence.data.urgcleint
                    })
                }),
                new FormulaireItem('ttccleint', {largeur: 50})
            ], {
                largeurDefaut: 50
            })
        };

    }
}
