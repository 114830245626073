import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCr0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}
/* @ngInject */
export default function Cr0003Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: any) {
    const vm: IPageCr0003 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'moecleint', valeur: $stateParams.moecleint, visible: false }
        ],
        colonnesFixesGauche: [
            { nom: 'moecod', largeur: 70 }
        ],
        colonnesVisibles: [
            { nom: 'moedsc', largeur: 224 },
            { nom: 'vaemoeentfon', largeur: 147 },
            { nom: 'moeflgdep', largeur: 90 },
            { nom: 'scbfol', largeur: 152 },
            { nom: 'cptcod', largeur: 152 },
            { nom: 'vaesysdevcod', largeur: 125 },
            { nom: 'moeflgrefnum', largeur: 85 },
            { nom: 'moeflgrefdat', largeur: 70 },
            { nom: 'moeflgdet', largeur: 70 },
            { nom: 'moeflgmul', largeur: 70 },
            { nom: 'moeflgstu', largeur: 70 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('moecod', { required: true, largeur: 20 }),
            new FormulaireItem('moedsc', { required: true, largeur: 20 }),
            new FormulaireItem('vaemoeentfon', { required: true, largeur: 20 }),
            new FormulaireItem('moeflgdep', { largeur: 20 }),
            new FormulaireItem('scbcleint', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('moeflgrefnum', { largeur: 20 }),
            new FormulaireItem('moeflgrefdat', { largeur: 20 }),
            new FormulaireItem('moeflgdet', { largeur: 20 }),
            new FormulaireItem('moeflgmul', { largeur: 20 }),
            new FormulaireItem('moeflgstu', { largeur: 20 })
        ]),
        fonctions: {
            rechercheCollapsable: false,
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor)
        }
    };
}
