import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IFormulaireTemplateSlotClass} from "../../../../../core/services/formulaire/formulaire-template-slot.service";

interface IPageCS0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceSSSOptions: IMultiOccurrenceOptions;
    multiOccurrenceSCAOptions: IMultiOccurrenceOptions;    
}
/* @ngInject */
export default function Cs0005Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCS0005 = this;
    
    vm.multiOccurrenceSCAOptions = {
        bloc: 'sca',
        colonnesVisibles: [
            {nom: 'scacod', largeur: 110},
            {nom: 'scadsc', largeur: 548},
            {nom: 'scaflgpreaut', largeur: 95}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('scacod'),
            new FormulaireItem('scadsc', {
                largeur: 40
            }),
            new FormulaireItem('scaflgpreaut', {
                default: 0
            }),
            new FormulaireTemplateSlot('restrictions')
        ], {
            largeurDefaut: 20
        })
    };

    vm.multiOccurrenceSSSOptions = {
        bloc: 'sss',
        colonnesVisibles: [
            {nom: 'ssscod', largeur: 110},
            {nom: 'sssdsc', largeur: 606}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ssscod'),
            new FormulaireItem('sssdsc'),
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceSCAOptions
    };


    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'seccod', largeur: 110},
            {nom: 'secdsc', largeur: 350}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('seccod', {
                largeur: 20
            }),
            new FormulaireItem('secdsc', {
                largeur: 30
            })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceSSSOptions
    };
}
