import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMR0194 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0194Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0194 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'mchnummth', largeur: 30},
            {nom: 'vaedsc', largeur: 100},
            {nom: 'mchdsc', largeur: 100},
            {nom: 'mchdoc', largeur: 800}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mchnummth', {largeur: 30}),
            new FormulaireItem('vaemchtyp', {largeur: 30}),
            new FormulaireItem('mchdsc', {largeur: 50}),
            new FormulaireItem('mchdoc', {largeur: 90})
        ])
    };
}
