import {IComponentController} from 'angular';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {ISourceDetails} from '../../../../../core/resources/source-details.resource';

interface IPageMr0008 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireApa: IFormulaire;
    desMultiOccurrenceOptions: IMultiOccurrenceOptions;
    desMultiOccurrence: IMultiOccurrence;
}

interface IMr0008Params {
    cleintref : number;
    stamnetab : string;
    vaesystypavi: string;
    typsai : string;
    supprime : number;
    vaebmtent : string;
    apacleint : number;
    bqecleint : number;
    trpcleint : number;
}

/* @ngInject */
export default function Mr0008Controller($stateParams: IMr0008Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranSourceDetails: ISourceDetails) {
    const vm: IPageMr0008 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        ecranSourceDetails.dataTypes.apatxt.params.vaebmtent = $stateParams.vaebmtent;
        // Paramètres d'avis
        vm.formulaireApa = new Formulaire( 'LBL_BLC_APA', [
            // Champ qui ne seront jamais affiché... Hidden à true dans le BE
            new FormulaireItem('stamnetab', {default: $stateParams.stamnetab}),
            new FormulaireItem('apacleintref', {default: $stateParams.cleintref}),

            // Début des champs potentiellement affichés
            new FormulaireItem('vaesystypavi', {
                default: $stateParams.vaesystypavi,
                largeur : 40}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('apasujavi'   , {largeur: 100}),
            new FormulaireItem('apadelavi', {
                titre: ($stateParams.typsai === 'JRS' ? 'LBL_APADELAVI_JOUR' : 'LBL_APADELAVI_SEMAINE'),
                largeur : 40}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('bqecleint'   , {
                default: $stateParams.bqecleint,
                largeur: 40}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('apabqecib', {largeur: 33.33}),
            new FormulaireItem('apabqeavi', {largeur: 33.33}),
            new FormulaireItem('apabqesld', {largeur: 33.33}),

            new FormulaireItem('apatxt'   , {largeur: 100, enableCount: true}),

        ],{largeurDefaut: 33.33});


        vm.desMultiOccurrenceOptions = {
            bloc: 'des',
            colonnesVisibles: [
                {nom: 'vaedestyp_dsc', largeur: 200},
                {nom: 'descod'       , largeur: 120},
                {nom: 'desdsc'       , largeur: 350},
                {nom: 'desflgtab'    , largeur: 144},
                {nom: 'desflgcou'    , largeur: 100}
            ],

            formulaire: new Formulaire([
                // Ne sera pas affiché
                new FormulaireItem('descleintref',{default : () => vm.monoOccurrence.data.$id}), // apacleint

                new FormulaireItem('vaedestyp',{largeur : 50}),
                new FormulaireItem('desflgtab',{default: 1, largeur: 25}),
                new FormulaireItem('desflgcou',{default: 0, largeur: 25}),
                new FormulaireItem('intcleint',{largeur : 50}),
                new FormulaireItem('rolcleint',{largeur : 50}),
                new FormulaireItem('nircleint',{largeur : 50})
            ], {largeurDefaut: 25})


        };


    }
}
