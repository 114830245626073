import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRR0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0030Controller(rr0031Route: IRoute,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    parametresRecherche: IParametresRechercheService,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0030 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: rr0031Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0031Route.NAME,
                () => ({
                    flgmod: 0,
                    demcleint: $stateParams.employe
                })
            )
        ]),
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: $stateParams.efface_criteres
        },
        filtres: parametresRecherche(ecranDetails),
        filtresParametres: [
            { colonne: 'demcleint', affichage: false }
        ],
        colonnesDisponibles: [
            { nom: 'v_op1', largeur: 225 },
            { nom: 'v_op2', largeur: 225 },
            { nom: 'v_op3', largeur: 225 },
            { nom: 'v_op4', largeur: 225 },
            { nom: 'v_op5', largeur: 225 },
            { nom: 'v_op6', largeur: 225 },
            { nom: 'v_op7', largeur: 225 },
            { nom: 'v_op8', largeur: 225 },
            { nom: 'v_op9', largeur: 225 },
            { nom: 'v_op10', largeur: 225 }
        ],
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 220, hidden: $stateParams.employe },
            { nom: 'daanumdoc', largeur: 120 },
            { nom: 'ttccod', largeur: 120 },
            { nom: 'ecldsceta', largeur: 195 },
            { nom: 'daadatadm', largeur: 120 },
            { nom: 'daadatdeb', largeur: 120 },
            { nom: 'daadatfin', largeur: 120 },
            { nom: 'daadatfinrem', largeur: 120 },
            { nom: 'placod', largeur: 100 },
            { nom: 'pladsc', largeur: 270 },
            { nom: 'plccod', largeur: 100 },
            { nom: 'plcdsc', largeur: 270 },
            { nom: 'daaidt', largeur: 185 },
            { nom: 'daacom', largeur: 405 },
            { nom: 'dsasal', largeur: 126 },
            { nom: 'unpcod', largeur: 150 },
            { nom: 'unpdsc', largeur: 270 },
            { nom: 'trnflgstafer', largeur: 80 },
            { nom: 'trnflgstaann', largeur: 80 }
        ]
    };
}
