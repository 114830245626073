import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";

interface IPageMc0161 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTax: IFormulaire;
    formulaireCalcul: IFormulaire;
    usaimp: IMenuItem;
    tatMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0161Controller(mc0046Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageMc0161 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Usage d'imputation
        vm.usaimp = new MenuItemEcran('LBL_BLC_UVI', mc0046Route.NAME, () => {
            return {
                vaeusient: 'TAX',
                uvicleintref: vm.monoOccurrence.data.taxcleint
            };
        }, {
            icon: 'view_list',
            iconButton: false,
            secondaire: true
        });

        vm.formulaireTax = new Formulaire('LBL_BLC_TAX', [
            new FormulaireItem('taxcod'),
            new FormulaireItemDivider('LBL_DIV_FED'),
            new FormulaireItem('taxdscfed'),
            new FormulaireItem('vaetaxmodfed'),
            new FormulaireItemDivider('LBL_DIV_PRO'),
            new FormulaireItem('taxdscpro'),
            new FormulaireItem('vaetaxmodpro')
        ]);

        vm.formulaireCalcul = new Formulaire('LBL_BLC_CALCUL', [
            new FormulaireItem('vaetaxcal')
        ]);

        vm.tatMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tatdatvig'    , largeur: 140},
                {nom: 'tattauappfed' , largeur: 130},
                {nom: 'tattauapppro' , largeur: 130}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tatdatvig'),
                new FormulaireItem('tattauappfed'),
                new FormulaireItem('tattauapppro')
            ], {largeurDefaut: 33})
        };

    }
}
