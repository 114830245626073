import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
interface IPageDB0003 {
    menuBandeau: IMenu;
    formulaireMof: IFormulaire;
    ddeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    depMultiOccurrenceOptions: IMultiOccurrenceOptions;
    engMultiOccurrenceOptions: IMultiOccurrenceOptions;
    denMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function Db0003Controller(
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    MenuItemAction: IMenuItemActionClass,
    $stateParams: any
) {
    const vm: IPageDB0003 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_MOF', [
            new FormulaireItem('mofcod', { largeur: 25, maxlength: 3 }),
            new FormulaireItem('mofdsc', { largeur: 50 })]),
        fonctions: {
            boutonDupliquer: false
        }
    };

    // Détail dépense
    vm.ddeMultiOccurrenceOptions = {
        bloc: 'dde',
        colonnesVisibles: [
            { nom: 'cptcod', largeur: 84 },
            { nom: 'cptdsc', largeur: 395 },
            { nom: 'mdcflgavasoc', largeur: 90 },
            { nom: 'mdcflgsal', largeur: 90 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cptcleint', { largeur: 33 }),
            new FormulaireItem('mdcflgavasoc', { largeur: 20 }),
            new FormulaireItem('mdcflgsal', { largeur: 20 })
        ]),
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5]
        }),
        fonctions: {
            rechercheCollapsable: false
        }
    };

    // Lignes
    vm.depMultiOccurrenceOptions = {
        bloc: 'dep',
        colonnesVisibles: [
            { nom: 'mdllignum', largeur: 105 },
            { nom: 'mdldsc', largeur: 275 },
            { nom: 'mdlref1', largeur: 117 },
            { nom: 'mdlflgtrfint', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mofcleint', { hidden: true, default: $stateParams.id }),
            new FormulaireItem('mdllignum', { largeur: 15 }),
            new FormulaireItem('mdldsc', { largeur: 33 }),
            new FormulaireItem('mdlref1', { largeur: 20 }),
            new FormulaireItem('mdlflgtrfint', { largeur: 20 })
        ]),
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5]
        }),
        fonctions: {
            rechercheCollapsable: false
        },
        multiOccurrenceOptionsEnfant: vm.ddeMultiOccurrenceOptions
    };

    // Détail de l'engagement
    vm.denMultiOccurrenceOptions = {
        bloc: 'den',
        colonnesVisibles: [
            { nom: 'cptcod', largeur: 84 },
            { nom: 'cptdsc', largeur: 433 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cptcleint')]),
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5]
        }),
        fonctions: {
            rechercheCollapsable: false
        }
    };

    // Engagement
    vm.engMultiOccurrenceOptions = {
        bloc: 'eng',
        colonnesVisibles: [{ nom: 'mdldsc', largeur: 275 }],
        formulaire: new Formulaire([
            new FormulaireItem('mdldsc')]),
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5]
        }),
        fonctions: {
            rechercheCollapsable: false
        },
        multiOccurrenceOptionsEnfant: vm.denMultiOccurrenceOptions
    };
}
