import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMc1026 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc1026Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc1026 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'tnocod', largeur: 115 },
            { nom: 'tnodsc', largeur: 260 },
            { nom: 'tnoflgact', largeur: 80 }
        ],
        colonnesVisibles: [
            { nom: 'stadsc', largeur: 260 },
            { nom: 'stanom', largeur: 196 },
            { nom: 'gnodsc', largeur: 288 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tnocod'),
            new FormulaireItem('tnodsc', { enableCount: true }),
            new FormulaireItem('tnoflgact', { default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('stacleint'),
            new FormulaireItem('gnocleint', {
                resourceParams: (data: any) => {
                    return {
                        stacleint: data.stacleint
                    };
                }
            })
        ])
    };
}

