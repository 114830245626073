import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMc0057 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    prbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prbMultiOccurrence: IMultiOccurrence;
    prtMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0057Controller(
    Menu: IMenuClass,
    FormulaireItem: IFormulaireItemClass,
    Formulaire: IFormulaireClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    $stateParams: any,
    Pagination: IPaginationClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    MenuItemEcran: IMenuItemEcranClass,
    mc2016Route: IRoute
) {
    const vm: IPageMc0057 = this;

    vm.menuBandeau = new Menu([]);
    vm.valeursEcran = ecranDetails.valeurs;

    vm.prtMultiOccurrenceOptions = {
        bloc: 'prt',
        fonctions: {
            pagination: true,
            criteresSuggeresVisibles: false,
            importation: true
        },
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 20, 30]
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaesysnatctbcri', { required: true })
        ]),
        colonnesVisibles: [
            { nom: 'vaesysnatctb_dsc', largeur: 130 },
            { nom: 'cifcod', largeur: 120 },
            { nom: 'cptcod', largeur: 120 },
            { nom: 'cptdscabr', largeur: 220 },
            { nom: 'unacod', largeur: 120 },
            { nom: 'prtdatdeb', largeur: 126 },
            { nom: 'prtdatfin', largeur: 126 },
            { nom: 'prtprcdep', largeur: 140 },
            { nom: 'prtmntdep', largeur: 140 },
            { nom: 'prtflgbdgcmp', largeur: 120 },
            { nom: 'remcod', largeur: 140 },
            { nom: 'vaesyscodadm_dsc', largeur: 150 },
            { nom: 'rgaind01', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff01) },
            { nom: 'rgaind02', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff02) },
            { nom: 'rgaind03', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff03) },
            { nom: 'rgaind04', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff04) },
            { nom: 'rgaind05', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff05) },
            { nom: 'rgaind06', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff06) },
            { nom: 'rgaind07', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff07) },
            { nom: 'rgaind08', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff08) },
            { nom: 'rgaind09', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff09) },
            { nom: 'rgaind10', largeur: 120, hidden: () => !(vm.valeursEcran.rgaflgaff10) },
            { nom: 'prtflgepr', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('prucleint', { default: $stateParams.prucleint, hidden: true }),
            new FormulaireItem('prscleint', { default: $stateParams.id, hidden: true }),
            new FormulaireItem('cifcleint', { default: vm.valeursEcran.cifcleintdef }),
            new FormulaireItem('vaesysnatctb', {
                required: true,
                resourceParams: (data: any) => ({
                    prucleint: data.prucleint
                })
            }),
            new FormulaireItem('cptcleint', {
                resourceParams: (data: any) => ({
                    prucleint: data.prucleint,
                    vaesysnatctb: data.vaesysnatctb
                })
            }),
            new FormulaireItem('unacleint', { default: vm.valeursEcran.unacleintdef }),
            new FormulaireItem('prtdatdeb'),
            new FormulaireItem('prtdatfin'),
            new FormulaireItem('prtprcdep'),
            new FormulaireItem('prtmntdep'),
            new FormulaireItem('prtflgbdgcmp'),
            new FormulaireItem('remcleint'),
            new FormulaireItem('vaesyscodadm'),
            new FormulaireItem('prtflgepr'),
            new FormulaireItemDivider('LBL_DIV_REG'),
            new FormulaireItem('rgaind01', { hidden: () => !(vm.valeursEcran.rgaflgaff01) }),
            new FormulaireItem('rgaind02', { hidden: () => !(vm.valeursEcran.rgaflgaff02) }),
            new FormulaireItem('rgaind03', { hidden: () => !(vm.valeursEcran.rgaflgaff03) }),
            new FormulaireItem('rgaind04', { hidden: () => !(vm.valeursEcran.rgaflgaff04) }),
            new FormulaireItem('rgaind05', { hidden: () => !(vm.valeursEcran.rgaflgaff05) }),
            new FormulaireItem('rgaind06', { hidden: () => !(vm.valeursEcran.rgaflgaff06) }),
            new FormulaireItem('rgaind07', { hidden: () => !(vm.valeursEcran.rgaflgaff07) }),
            new FormulaireItem('rgaind08', { hidden: () => !(vm.valeursEcran.rgaflgaff08) }),
            new FormulaireItem('rgaind09', { hidden: () => !(vm.valeursEcran.rgaflgaff09) }),
            new FormulaireItem('rgaind10', { hidden: () => !(vm.valeursEcran.rgaflgaff10) }),
        ], { largeurDefaut: 25 })
    }

    vm.prbMultiOccurrenceOptions = {
        bloc: 'prb',
        fonctions: {
            importation: true
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_TRANSFERT_COMPTE',
                'BTNTRFCPT',
                (data: any) => ({}),
                {
                    icon: 'mode_edit',
                    /*successAction: () => {
                        vm.prbMultiOccurrence && vm.prbMultiOccurrence.fetchDataList();
                    },*/
                    messageConfirmation: 'BOU_CONFIRM',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_TRANSFERT_COMPTE',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('prbcleinttrf', { default: (rowData: any) => rowData.prbcleint, hidden: true }),
                            new FormulaireItem('cptcleinttrf', {
                                required: true,
                                resourceParams: (rowData: any) => ({
                                    prbcleint: rowData.prbcleint
                                })
                            }),
                            new FormulaireItem('unacleinttrf', {
                                resourceParams: (rowData: any) => ({
                                    prbcleint: rowData.prbcleint,
                                    cptcleint: rowData.cptcleinttrf
                                })
                            }),
                            new FormulaireItem('cifcleinttrf', {
                                resourceParams: (rowData: any) => ({
                                    prbcleint: rowData.prbcleint,
                                    cptcleint: rowData.cptcleinttrf
                                })
                            }),
                            new FormulaireItem('prbcleintdes', {
                                required: true,
                                resourceParams: (rowData: any) => ({
                                    prscleint: rowData.prscleint,
                                    prbcleint: rowData.prbcleint
                                })
                            })
                        ], { largeurDefaut: 100 })
                    }
                }),
            new MenuItemEcran(
                'LBL_HISTO_TRF',
                mc2016Route.NAME,
                (rowData: any) => ({
                    prbcleint: rowData.prbcleint
                }),
                {
                    fonction: 'BTNHISTRF',
                    icon: 'view_list',
                    iconButton: false
                }
            )

        ]),
        colonnesVisibles: [
            { nom: 'blocod', largeur: 130 },
            { nom: 'blodscabr', largeur: 262 },
            { nom: 'prbprcdep', largeur: 115 },
            { nom: 'prbmntdep', largeur: 140 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('prscleint', { default: $stateParams.id, hidden: true }),
            new FormulaireItem('blocleint', { largeur: 50 }),
            new FormulaireItem('prbprcdep', { largeur: 25 }),
            new FormulaireItem('prbmntdep', { largeur: 25 })
        ]),
        colonnesCachees: ['blocleint', 'prscleint'],
        multiOccurrenceOptionsEnfant: vm.prtMultiOccurrenceOptions
    };
}
