import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import IStateService = angular.ui.IStateService;
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMC0156 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireGci: IFormulaire;
    gasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gasMultiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Mc0156Controller(mc0156Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $state: IStateService,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass) {

    const vm: IPageMC0156 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Gestion du bouton pour la copie
        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BOUMOD',
                    'BOUMOD',
                    (data: any) => ({ gcicleint: data.gcicleint }),
                    {
                        icon: 'create',
                        successAction: (data:any) => {
                            $state.go(mc0156Route.NAME, {id: data.gcicleint});
                        },
                        lblMessageSuccess: 'G_LBL_MSG_VALIDER',
                        hidden: (data: any) => {
                            return !( (data.gcicleint) &&
                                     (data.gciseq  === data.$valeurs.seqmax) &&
                                     (data.$valeurs.fusion===1)
                                    )
                        }
                    }
                )
            ])
        };

        vm.formulaireGci = new Formulaire('LBL_BLC_GCI', [
            new FormulaireItem('gcicod'),
            new FormulaireItem('gciseq', { default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gcinom'),
            new FormulaireItem('gcinomabr'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ciecleintmai'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gcidatdeb'),
            new FormulaireItem('gcidatfin')
        ], {largeurDefaut: 50});

        vm.gasMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'ciedsc', largeur: 200}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ciecleintass')
                ], { largeurDefaut: 50 }
            )
        };
    }
}
