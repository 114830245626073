import IStateService = angular.ui.IStateService;
import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItem, IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPagePb0024 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulairePbg: IFormulaire;
    forageSommaireDesMontants: IMenuItem;
    actionProduireEcriture: IMenuItem;
    pbuMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menuSce: IMenuItem;
    getLibelles(data:any): string;
}

interface IPb0024Params {
    id: number;
    menuId: string;

}

/* @ngInject */
export default function Pb0024Controller(pb0024Route: IRoute,
                                         pb0025Route: IRoute,
                                         pb0026Route: IRoute,
                                         $state: IStateService,
                                         $stateParams: IPb0024Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItem: IMenuItemClass,
                                         Menu: IMenuClass) {
    const vm: IPagePb0024 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Forage au sommaire des montants
        vm.forageSommaireDesMontants = new MenuItemEcran('LBL_BTN_SOMMAIRE',
            pb0025Route.NAME,
            (data:any) => ({
                id: data.pbgcleint
            }),
            {
                icon: 'assessment',
                iconButton: false,
                secondaire: true,
                optionsDialog: {
                    hauteur: 73,
                    largeur: 100
                }
            }
        );

        // Action de production de l'écriture
        vm.actionProduireEcriture = new MenuItemAction(
            'LBL_BTN_PRODUIRE_ECR',
            'BOUPROECR',
            () => ({}),
            {
                icon: 'autorenew',
                iconButton: false,
                secondaire: true,
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_PRODUCTION_ECR',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('intcleintreq', {
                            required: true,
                            largeur: 100
                        })
                    ])
                }
            }
        );

        vm.menuSce = new MenuItemAction(
            'LBL_BTN_GERER_SCENARIO',
            'BOUGER',
            () => ({}),
            {
                icon: 'settings',
                iconButton: true,
                saveFirst: true,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => {return ecranDetails.flgcen === 0;},
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_GERER_SCENARIO',
                    lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                    largeurFormulaire: 60,
                    formulaire: new Formulaire([
                        new FormulaireItem('pbgmntnetarrtmp', {
                            default: (data:any) => { return data.pbgmntnetarr; }
                        }),
                        new FormulaireItem('taxcleintdeftmp', {
                            default: (data:any) => { return data.taxcleintdef; }
                        }),
                        new FormulaireItem('taxcleintdeftmp__taxcod', {
                            default: (data:any) => { return data.aut__taxcod; },
                            hidden: true
                        }),
                        new FormulaireItem('taxcleintdeftmp__taxdscfed', {
                            default: (data:any) => { return data.aut__taxdscfed; },
                            hidden: true
                        }),
                        new FormulaireItem('taxcleintdeftmp__taxdscpro', {
                            default: (data:any) => { return data.aut__taxdscpro; },
                            hidden: true
                        }),
                        new FormulaireItem('pbgflgnegtmp', {
                            default: (data:any) => { return data.pbgflgneg; }
                        }),
                        new FormulaireItemDivider('LBL_DIV_SCENARIO1'),
                        new FormulaireItem('pbglblsce1tmp', {
                            default: (data:any) => { return data.pbglblsce1; },
                            maxlength: 20
                        }),
                        new FormulaireItem('vaepbgsaisce1tmp',{
                            default: (data:any) => { return data.vaepbgsaisce1; }
                        }),
                        new FormulaireItem('vaepbgsaisce1tmp__vaedsc',{
                            default: (data:any) => { return data.aut__vaepbgsaisce1_dsc; },
                            hidden: true
                        }),
                        new FormulaireItem('pbgflgecbsce1tmp',{
                            default: (data:any) => { return data.pbgflgecbsce1; }
                        }),
                        new FormulaireItemDivider('LBL_DIV_SCENARIO2'),
                        new FormulaireItem('pbglblsce2tmp', {
                            default: (data:any) => { return data.pbglblsce2; },
                            maxlength: 20
                        }),
                        new FormulaireItem('vaepbgsaisce2tmp',{
                            default: (data:any) => { return data.vaepbgsaisce2; }
                        }),
                        new FormulaireItem('vaepbgsaisce2tmp__vaedsc',{
                            default: (data:any) => { return data.aut__vaepbgsaisce2_dsc; },
                            hidden: true
                        }),
                        new FormulaireItem('pbgflgecbsce2tmp',{
                            default: (data:any) => { return data.pbgflgecbsce2; }
                        }),
                        new FormulaireItemDivider('LBL_DIV_SCENARIO3'),
                        new FormulaireItem('pbglblsce3tmp', {
                            default: (data:any) => { return data.pbglblsce3; },
                            maxlength: 20
                        }),
                        new FormulaireItem('vaepbgsaisce3tmp',{
                            default: (data:any) => { return data.vaepbgsaisce3; }
                        }),
                        new FormulaireItem('vaepbgsaisce3tmp__vaedsc',{
                            default: (data:any) => { return data.aut__vaepbgsaisce3_dsc; },
                            hidden: true
                        }),
                        new FormulaireItem('pbgflgecbsce3tmp',{
                            default: (data:any) => { return data.pbgflgecbsce3; }
                        })
                    ], {largeurDefaut: 33})
                }
            }
        );

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false,
                boutonEnregistrerHaut: false,
                afficherMenusDansEntete: true,
                afficherMenusCreation: true
            },
            menus: [
                new MenuItemAction(
                    'G_LBL_BTN_ENREGISTRER',
                    'BOUINS',
                    (data: any) => ({
                        pbgann: data.pbgann,
                        ciecleint: data.$ciecleint
                    }),
                    {
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE_TAXE',
                            conditionAffichage: (monoOccurrence: IMonoOccurrence, data: any) => (data.t_annpre !== null),
                            largeurFormulaire: 25,
                            formulaire: new Formulaire([
                                new FormulaireItem('t_copietaxe', {
                                    largeur: 100,
                                    required: true,
                                    default: 1
                                })
                            ])
                        },
                        icon: '',
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: (data: any) => {
                            $state.go(pb0024Route.NAME, {
                                verifierChangements: false,
                                id: data.pbgcleintnew,
                                menuId: $stateParams.menuId
                            });
                        },
                        hidden: () => vm.monoOccurrence && vm.monoOccurrence.id
                    }
                )
            ],
            actionsMore: new Menu([
                new MenuItemAction(
                    getLibelles(1),
                    'BOUCOPSCE1SCE2',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        iconButton: false,
                        successAction: () => { vm.monoOccurrence.fetchData(); },
                        hidden: () => {return ecranDetails.flgcen === 0;}
                    }
                ),
                new MenuItemAction(
                    getLibelles(2),
                    'BOUCOPSCE1SCE3',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        iconButton: false,
                        successAction: () => { vm.monoOccurrence.fetchData(); },
                        hidden: () => {return ecranDetails.flgcen === 0;}
                    }
                ),
                new MenuItemAction(
                    getLibelles(3),
                    'BOUCOPSCE2SCE3',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        iconButton: false,
                        successAction: () => { vm.monoOccurrence.fetchData(); },
                        hidden: () => {return ecranDetails.flgcen === 0;}
                    }
                )
            ])
        };

        // Préparation budgétaire municipale
        vm.formulairePbg = new Formulaire('LBL_BLC_PBG', [
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pbgann', {largeur: 100})
            ]), {largeur: 50}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('aut__pbglblsce1', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.aut__pbgflgsai1 === 0 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.aut__pbgflgsai1 === 0 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg'
                        })
                    ]}),
                new FormulaireItem('aut__pbglblsce2', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.aut__pbgflgsai2 === 0 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.aut__pbgflgsai2 === 0 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg'
                        })
                    ]}),
                new FormulaireItem('aut__pbglblsce3', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.aut__pbgflgsai3 === 0 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.aut__pbgflgsai3 === 0 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg'
                        })
                    ]})
            ]), {largeur: 50})
        ]);

        vm.pbuMultiOccurrenceOptions = {
            bloc: 'pbu',
            actionsRangeeDroite: new Menu([
                new MenuItemAction(
                    'LBL_ACT_CREATION',
                    'BOUCREPBU',
                    (rowData:any) => ({
                        pbgcleint: rowData.pbgcleint,
                        prucleint: rowData.prucleint
                    }),
                    {
                        icon: 'exit_to_app',
                        successAction: (retour:any) => {
                            $state.go(pb0026Route.NAME, {
                                menuId: $stateParams.menuId,
                                id: retour.pbucleint,
                                prucleint: retour.prucleint,
                                pbgcleint: retour.pbgcleint
                            });
                        }
                    }
                )
            ]),
            entetesReplacedValues: {
                ent_sce1: vm.valeursEcran.pbgentsce1,
                ent_sce2: vm.valeursEcran.pbgentsce2,
                ent_sce3: vm.valeursEcran.pbgentsce3
            },
            colonnesFixesGauche: [
                {nom: 'prucod'       , largeur: 130},
                {nom: 'prudscl01'    , largeur: 300}
            ],
            colonnesVisibles: [
                {nom: 'pbumntobl'    , largeur: 130},
                {nom: 'pbumntnetsce1', largeur: 130},
                {nom: 'pbuflgfinsce1', largeur: 120},
                {nom: 'pbumntnetsce2', largeur: 130},
                {nom: 'pbuflgfinsce2', largeur: 120},
                {nom: 'pbumntnetsce3', largeur: 130},
                {nom: 'pbuflgfinsce3', largeur: 120}
            ],
            colonnesDisponibles: [
                {nom: 'pbucodres', largeur: 120},
                {nom: 'pbunomres', largeur: 262}
            ],
            colonnesCachees: [
                'pbgcleint',
                'prucleint'
            ]
        };
    }

    function getLibelles(data:any) {
        if (data === 1) {
            return vm.valeursEcran.pbglblcop1;
        } else if (data === 2) {
            return vm.valeursEcran.pbglblcop2;
        } else if (data === 3) {
            return vm.valeursEcran.pbglblcop3;
        }
    }

}
