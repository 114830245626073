import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterDate } from 'angular';

interface IPageGS0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface IGs0027Params {
    lcrcleint: string;
    menuId: string;
}
/* @ngInject */
export default function Gs0027Controller(gs0009Route: IRoute,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    MenuItem: IMenuItemClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    dateFilter: IFilterDate,
    downloadManager: IDownloadManagerService,
    $stateParams: IGs0027Params) {
    const vm: IPageGS0027 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0009Route.NAME)
        ]),
        navigatePage: gs0009Route.NAME,
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON_GUS', () => {
                downloadManager.directDownload('/gs0027/patrongus', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            }),
            new MenuItem('TELECHARGER_PATRON_PAV', () => {
                downloadManager.directDownload('/gs0027/patronpav', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            }),
            new MenuItem('TELECHARGER_PATRON_GUP', () => {
                downloadManager.directDownload('/gs0027/patrongup', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            }),
            new MenuItem('TELECHARGER_PATRON_USS', () => {
                downloadManager.directDownload('/gs0027/patronuss', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            }),
            new MenuItem('TELECHARGER_PATRON_SRH', () => {
                downloadManager.directDownload('/gs0027/patronsrh', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('usrcod'),
            new FormulaireItem('usrnom'),
            new FormulaireItem('datsel',{
                default:dateFilter(new Date(), 'yyyy-MM-dd')
            }),
        ]),
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 144 },
            { nom: 'usrnom', largeur: 262 },
            { nom: 'usrdatfin', largeur: 100 },
            { nom: 'intcod', largeur: 144 },
            { nom: 'intnom', largeur: 262 },
            { nom: 'empcod', largeur: 144 },
            { nom: 'empnom', largeur: 262 },
            { nom: 'usradrele', largeur: 368 },
            { nom: 'pradsc_saf', largeur: 262 },
            { nom: 'pradsc_sof', largeur: 262 },
            { nom: 'pradsc_emp', largeur: 262 },
            { nom: 'pradsc_sow', largeur: 262 },
            { nom: 'impdsc', largeur: 262 },
            { nom: 'usrflgsecpru', largeur: 60 },
            { nom: 'usrflgsecuna', largeur: 58 },
            { nom: 'usrflgsecmag', largeur: 65 },
            { nom: 'usrflgsecsrv', largeur: 58 },
            { nom: 'usrflgsecgeb', largeur: 58 },
            { nom: 'usrflgsectyd', largeur: 60 },
            { nom: 'usrflgsecurg', largeur: 58 },
            { nom: 'usrflgsecpos', largeur: 58 },
            { nom: 'usrflgsecuno', largeur: 58 },
            { nom: 'usrflgverfingrh', largeur: 58 },
            { nom: 'usrcodgid', largeur: 144 },
            { nom: 'gidcod', largeur: 144 }
        ]
    };
}
