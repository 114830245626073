import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0139 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0139Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0139 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'tpfdsc', largeur: 210},
            {nom: 'vaetpffep_dsc', largeur: 183}  
        ],
        colonnesVisibles: [
            {nom: 'tpfflgpaiuni', largeur: 91},
            {nom: 'tpfflgaut', largeur: 100},
            {nom: 'tpfflgdeffep', largeur: 100},
            {nom: 'tpfflgdeffepava', largeur: 100},
            {nom: 'tpfflgexccoumop', largeur: 100},
            {nom: 'tpfflgcom', largeur: 100},
            {nom: 'mopcod', largeur: 160},
            {nom: 'mopdsc', largeur: 236},
            {nom: 'tpfcodimp', largeur: 145},
            {nom: 'tpfcodseq', largeur: 100},
            {nom: 'tpfprical', largeur: 80}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tpfdsc', {largeur: 50}),
            new FormulaireItem('vaetpffep', {largeur: 50}),
            new FormulaireItem('tpfflgpaiuni'),
            new FormulaireItem('tpfflgaut'),
            new FormulaireItem('tpfflgdeffep'),
            new FormulaireItem('tpfflgdeffepava'),
            new FormulaireItem('tpfflgexccoumop'),
            new FormulaireItem('mopcleint', {largeur: 40}),
            new FormulaireItem('tpfcodimp'),
            new FormulaireItem('tpfcodseq'),
            new FormulaireItem('tpfprical')
        ],{largeurDefaut: 20})
    };
}
