import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageAC0046 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0046Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageAC0046 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                },
                required: false
            }),
            new FormulaireItem('caacleint', {
                required: false
            }),
            new FormulaireItem('procleint', {
                required: false
            }),
            new FormulaireItem('daidsc', {
                required: false
            }),
            new FormulaireItem('dainumcat', {
                required: false
            }),
            new FormulaireItem('lipcleint', {
                required: false
            }),
            new FormulaireGroupe( 'LBL_SYS_HARMONISE',
                new Formulaire([
                    new FormulaireItem('pays', {
                        required: false
                    }),
                    new FormulaireItem('class', {
                        required: false
                    })        
                ]), { largeur: 50 }
            ),
            new FormulaireGroupe( 'LBL_DATE_ACHAT',
                new Formulaire([
                    new FormulaireItem('datdeb', {
                        required: false
                    }),
                    new FormulaireItem('datfin', {
                        required: false
                    })
                ]), { largeur: 50 }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true,
            reinitialiserOuvrirCriteres: true
        },
        // Colonnes fixe à gauche
        colonnesFixesGauche: [
            {nom: 'dacnumdoc',largeur: 150},
            {nom: 'sysboncmd',largeur: 150},
            {nom: 'refcod',largeur: 120},
            {nom: 'refnom',largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'procod',largeur: 200},
            {nom: 'daidsc',largeur: 200},
            {nom: 'caacod',largeur: 100},
            {nom: 'dainumcat',largeur: 140},
            {nom: 'forcod',largeur: 100},
            {nom: 'daiqte',largeur: 126},
            {nom: 'daiprxuni',largeur: 126},
            {nom: 'dacdatreq',largeur: 126},
            {nom: 'lipcod',largeur: 160},
            {nom: 'daipayori',largeur: 140},
            {nom: 'daiclash',largeur: 140},
            {nom: 'intcod',largeur: 120},
            {nom: 'intnom',largeur: 200},
            {nom: 'usrcod',largeur: 120},
            {nom: 'usrnom',largeur: 200}
        ]
    };
}
