import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as0014',
    ecrans: ['00-2'],
    params: ['id',
        '?flginfor',
        '?ahtcleint',
        '?asgcleint',
        '?demcleint',
        '?unccleint',
        '?ahthresem']
});
