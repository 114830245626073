import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPagePt0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt0022Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPagePt0022 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        fonctions: {
            nouveau: false
        },
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 180},
            {nom: 'pdlancsld', largeur: 120},
            {nom: 'pdlnumdoc', largeur: 100},
            {nom: 'ecldsceta', largeur: 120},
            {nom: 'hopnumdoc', largeur: 120},
            {nom: 'hopdscfmt', largeur: 160},
            {nom: 'pdldatdeb', largeur: 126},
            {nom: 'pdldatfin', largeur: 126},
            {nom: 'ttccod', largeur: 120},
            {nom: 'ttcdsc', largeur: 160},
            {nom: 'pdlcom', largeur: 160},
            {nom: 'flgmsg', largeur: 100},
            {nom: 'trnflgstaref', largeur: 100},
            {nom: 'trnflgstafin', largeur: 100},
            {nom: 'trnflgstafer', largeur: 100},
            {nom: 'trnflgstaann', largeur: 100}
        ]
    };
}
