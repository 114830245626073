import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageAc0023 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    daiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    daiMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Ac0023Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    MenuItemAction: IMenuItemActionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService ) {
    const vm: IPageAc0023 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            exportation: false,
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_GENERE',
                'BOUGEN',
                () => ({
                    dtmcleint: $stateParams.dtmcleint,
                    daccleint: $stateParams.daccleint
                }),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    fonction: 'BOUGEN',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_GENERE',
                        lblConfirm: 'G_LBL_EXECUTER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('refcleintnew', { required: true }),
                            new FormulaireItem('etrcleintnew', {
                                resourceParams: {
                                    intcleintreq: vm.valeursEcran.intcleintreq
                                }
                            }),
                            new FormulaireItem('rad_action', { default: 1 }),
                            new FormulaireItem('type', { default: $stateParams.type, hidden: true })
                        ], { largeurDefaut: 100 })
                    },
                    successAction: (retour: any) => (retour.dacnumdocdst),
                    champsRechargement: ['dacnumdocdst']
                }
            ),
        ]
    };

    vm.daiMultiOccurrenceOptions = {
        bloc: 'dai',
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: { 
                        nom: 'selection',
                        lblConfirmationTitre: 'LBL_BTN_SELECTION'
                    },
                    fonction: 'BOUSEL',
                    successAction: () => { 
                        vm.daiMultiOccurrence.fetchDataList();
                    }
                }),
        ]),
        colonnesVisibles: [
            { nom: 'dailig', largeur: 100 },
            { nom: 'daidsc', largeur: 200 },
            { nom: 'daidsclng', largeur: 300 },
            { nom: 'dainumcat', largeur: 150 },
            { nom: 'daiflghis', largeur: 120 },
            { nom: 'dtmflgcre', largeur: 120 }
        ]
    };
}
