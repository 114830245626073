import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPageRe2006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re2006Controller(parametresRecherche: IParametresRechercheService,
                                       ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe2006 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ppacod', largeur: 120},
            {nom: 'rietauhoranc', largeur: 120},
            {nom: 'rietauhornou', largeur: 120}
        ],colonnesCachees: [
            'rslcleint'
        ]
        
    };
}
