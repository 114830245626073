import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0069 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0069Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMc0069 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'bamcod', largeur: 30 },
            { nom: 'bammsg', largeur: 400 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bamcod', { required: true, modifAllow: false, largeur: 50 }),
            new FormulaireItem('bammsg', { required: true, largeur: 150 })
        ])
    };
}
