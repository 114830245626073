import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAc0061 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Ac0061Controller(
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    ac0062Route: IRoute
) {
    const vm: IPageAc0061 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false
        },
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'LBL_BTN_ACTION',
                ac0062Route.NAME,
                (rowData: any) => ({
                    id: rowData.aflcleint,
                    foucleint: rowData.foucleint
                }),
                {
                    icon: 'launch',
                    fonction: 'BOUDOC',
                    iconButton: true,
                    onClose: () => vm.multiOccurrence.fetchDataList()
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'foucod', largeur: 120 },
            { nom: 'founom', largeur: 300 }
        ],
        colonnesVisibles: [
            { nom: 'fouflglco', largeur: 110 },
            { nom: 'aflflgact', largeur: 100 },
            { nom: 'founomabr', largeur: 250 },
            { nom: 'vecsysregadm', largeur: 130 },
            { nom: 'vecsysregadm_dsc', largeur: 180 },
            { nom: 'fouflgact', largeur: 120 },
            { nom: 'vecforjur', largeur: 130 },
            { nom: 'vecforjur_dsc', largeur: 180 },
            { nom: 'aflneq', largeur: 150 },
            { nom: 'usrcod', largeur: 130 }
        ],
        colonnesCachees: ['foucleint', 'aflcleint']
    };
}
