import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePB0003 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pppMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pppMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Pb0003Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    so0007Route: IRoute,
    pb0004Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    Pagination: IPaginationClass
) {
    const vm: IPagePB0003 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    vm.pppMultiOccurrenceOptions = {
        bloc: 'ppp',
        pagination: new Pagination({
            nombreElementPage: 6,
            nbElementsPossibles: [3, 6, 15]
        }),
        navigatePage: pb0004Route.NAME,
        navigateParams: (data: any) => {
            return {
                pvecleint: data.pvecleint,
                pvpcleint: data.pvpcleint,
                pppcleint: data.pppcleint
            };
        },
        fonctions: {
            pagination: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'grecod', largeur: 142 },
            { nom: 'gredsc', largeur: 160 },
            {
                nom: 'posnumdoc',
                largeur: 112,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_POS',
                        so0007Route.NAME,
                        (rowData: any) => ({ id: rowData.trncleint })
                    )
                ]
            }
        ],
        colonnesVisibles: [
            { nom: 'pppmntpresal', largeur: 110, total: true },
            { nom: 'pppmntpreavs', largeur: 110, total: true },
            { nom: 'pppmntpretot', largeur: 110, total: true },
            { nom: 'pppmntreesal', largeur: 110, total: true },
            { nom: 'pppmntreeavs', largeur: 110, total: true },
            { nom: 'pppmntreetot', largeur: 110, total: true },
            { nom: 'pppmntprosal', largeur: 110, total: true },
            { nom: 'pppmntproavs', largeur: 110, total: true },
            { nom: 'pppmntprotot', largeur: 110, total: true },
            { nom: 'pppmnttotreepro', largeur: 150, total: true },
            { nom: 'pppmntectsal', largeur: 120, total: true },
            { nom: 'pppmntectavs', largeur: 140, total: true },
            { nom: 'postit', largeur: 240 },
            { nom: 'pppmntectsalavs', largeur: 100, total: true },
            { nom: 'nb_note', largeur: 100, total: true },
            { nom: 'flgerr', largeur: 100 },
            { nom: 'flgavr', largeur: 100 }
        ],
        colonnesCachees: ['pvecleint', 'trncleint', 'pvpcleint', 'pppcleint', 'avrmsg', 'errmsg']
    };
}
