import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';

interface IPageMr0070 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0070Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0070 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cexcod', largeur: 30},
            {nom: 'cexdsc', largeur: 60},
            {nom: 'clscod', largeur: 30},
            {nom: 'clsnom', largeur: 60},
            {nom: 'cexcom', largeur: 200}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cexcod', {largeur: 30}),
            new FormulaireItem('cexdsc', {largeur: 60}),
            new FormulaireItem('clscleint', {largeur: 60}),
            new FormulaireItem('cexcom', {largeur: 90})
        ])
    };
}
