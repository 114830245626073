import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMc0044 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    prrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prrMultiOccurrence: IMultiOccurrence;
    prsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prsMultiOccurrence: IMultiOccurrence;
    rmmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pmeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pdfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pdfMultiOccurrence: IMultiOccurrence;
    formulairesuf: IFormulaire;
}

/* @ngInject */
export default function Mc0044Controller(
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    $stateParams: any,
    mc0057Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass,
    db0009Route: IRoute,
    mc2045Route: IRoute
) {
    const vm: IPageMc0044 = this;

    vm.menuBandeau = new Menu([]);
    vm.valeursEcran = ecranDetails.valeurs;

    const changeResp = new MenuItemAction(
        'LBL_BTN_MOD_INTERV',
        'MODINT',
        (data: any) => ({
            prucleint: vm.monoOccurrence.data.$id
        }),
        {
            icon: 'edit',
            iconButton: true,
            successAction: () => {
                vm.monoOccurrence.fetchData();
            },
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_MOD_INTERV',
                lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                largeurFormulaire: 30,
                formulaire: new Formulaire([new FormulaireItem('intcleint'), new FormulaireItem('prrflgsai')], { largeurDefaut: 100 })
            },
            hidden: (data: any) => !(vm.monoOccurrence.id)
        }
    );

    const budgetProg = new MenuItemForage(
        'LBL_ANA_SPE',
        db0009Route.NAME,
        (data: any) => ({
            id: data.pbpcleint,
            prucleint: data.prucleint
        }),
        {
            icon: 'exit_to_app',
            iconButton: true
        }
    );

    vm.monoOccurrenceOptions = {
        fonctions: {
           boutonDupliquer: false
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_COP',
                'BOUCOP',
                (data: any) => ({
                    prucleint: vm.monoOccurrence.data.$id
                }),
                {
                    icon: 'content_copy',
                    iconButton: false,
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_COP',
                        lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('prucleintcop', {
                                required: true,
                                resourceParams: (data: any) => ({
                                    prucleint: $stateParams.id ? data.prucleint : 0
                                })
                            })
                        ], { largeurDefaut: 100 })
                    }
                }
            )
        ]),
        formulaire: new Formulaire(
            'LBL_BLC_UBR', [
            new FormulaireItem('prucod', { largeur: 33, titre: vm.valeursEcran.lblpru, modifAllow: false }),
            new FormulaireItem('vaeprutypcptbdg', { largeur: 33 }),
            new FormulaireItem('vaeprutypbdg', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('prudscl01', { largeur: 50 }),
            new FormulaireItem('prudscl02', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('prudatdeb', { largeur: 33 }),
            new FormulaireItem('prudatfin', { largeur: 33 }),
            new FormulaireItem('pruflgstu', { largeur: 33, default: true }),
            new FormulaireItem('intcleintrsp', { modifAllow: false, required: true, menu: [changeResp] }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon(
                'LBL_AUT_INF',
                new Formulaire([
                    new FormulaireItem('vecprucatpro', { largeur: 50, titre: vm.valeursEcran.lblvecpru }),
                    new FormulaireItemDivider('LBL_DIV_MAN'),
                    new FormulaireItem('cifcleintmanbdg', {
                        largeur: 50,
                        required: true,
                        titre: vm.valeursEcran.lblcif
                    }),
                    new FormulaireItem('unacleintmanbdg', {
                        largeur: 50,
                        required: true,
                        titre: vm.valeursEcran.lbluna
                    }),
                    new FormulaireItem('sfucleintfc', { largeur: 50, required: true }),
                    new FormulaireItem('pruflgexc', { largeur: 50 }),
                    new FormulaireItemDivider('LBL_DIV_VAL_DEF'),
                    new FormulaireItem('cifcleintbdg', { largeur: 50, titre: vm.valeursEcran.lblcif }),
                    new FormulaireItem('pruflgcifbdg', { largeur: 50, default: true, titre: () => vm.valeursEcran.lblcif.concat(' ', 'exclusif') }),
                    new FormulaireItem('prscleintbdg', {
                        largeur: 50,
                        titre: vm.valeursEcran.lblprs,
                        resourceParams: (data: any) => ({
                            prucleint: $stateParams.id ? data.prucleint : 0
                        })
                    }),
                    new FormulaireItem('pruflgprsbdg', { largeur: 50, titre: () => vm.valeursEcran.lblprs.concat(' ', 'exclusif') }),
                    new FormulaireItem('unacleintbdg', { largeur: 50, titre: vm.valeursEcran.lbluna }),
                    new FormulaireItem('pruflgunabdg', { largeur: 50, default: true, titre: () => vm.valeursEcran.lbluna.concat(' ', 'exclusif') }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('el1cleintbdg', {
                        largeur: 50,
                        titre: vm.valeursEcran.lblel1,
                        resourceParams: (data: any) => ({
                            prucleint: $stateParams.id ? data.prucleint : 0
                        }),
                        hidden: () => !(vm.valeursEcran.flgaffel1)
                    }),
                    new FormulaireItem('pruflgel1bdg', {
                        largeur: 25,
                        titre: () => vm.valeursEcran.lblel1.concat(' ', 'exclusif'),
                        hidden: () => !(vm.valeursEcran.flgaffel1)
                    }),
                    new FormulaireItem('pruflgutlel1', {
                        largeur: 25,
                        hidden: () => !(vm.valeursEcran.flgaffel1)
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('el2cleintbdg', {
                        largeur: 50,
                        titre: vm.valeursEcran.lblel2,
                        hidden: () => !(vm.valeursEcran.flgaffel2)
                    }),
                    new FormulaireItem('pruflgel2bdg', {
                        largeur: 25,
                        titre: () => vm.valeursEcran.lblel2.concat(' ', 'exclusif'),
                        hidden: () => !(vm.valeursEcran.flgaffel2)
                    }),
                    new FormulaireItem('pruflgutlel2', {
                        largeur: 25,
                        hidden: () => !(vm.valeursEcran.flgaffel2)
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('el3cleintbdg', {
                        largeur: 50,
                        titre: vm.valeursEcran.lblel3,
                        hidden: () => !(vm.valeursEcran.flgaffel3)
                    }),
                    new FormulaireItem('pruflgel3bdg', {
                        largeur: 25,
                        titre: () => vm.valeursEcran.lblel3.concat(' ', 'exclusif'),
                        hidden: () => !(vm.valeursEcran.flgaffel3)
                    }),
                    new FormulaireItemDivider('LBL_DIV_FIN'),
                    new FormulaireItem('sfucleintua'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('aut__orgcoddsc', { readonly: true }),
                    new FormulaireItem('aut__orpcoddsc', { readonly: true })
                ]),
                { largeur: 100 }
            )
        ], { largeurDefaut: 50 }
        )
    };

    vm.formulairedis = new Formulaire('LBL_DIS_BUD', [
        new FormulaireItem('indcleint', { largeur: 100, required: true }),
        new FormulaireItem('pruprcdepbdg', { largeur: 50 }),
        new FormulaireItem('prumntdepbdg', { largeur: 50 }),
        new FormulaireItem('vaesysnivappctr', { largeur: 50 }),
        new FormulaireItem('ecbnumdoc', { largeur: 50, readonly: true, menu: [budgetProg] })
    ]);

    vm.formulairefrsadm = new Formulaire('LBL_FRS_ADM', [
        new FormulaireItem('pruflgfrsadm', { largeur: 50 }),
        new FormulaireItem('pruflgfraadm', { largeur: 50 }),
        new FormulaireItem('mrpcleint', { largeur: 50 })
    ]);

    vm.formulaireind = new Formulaire('LBL_IND_CAT', [
        new FormulaireItem('pruflgsaibdg', { largeur: 50 }),
        new FormulaireItem('pruflgexcpb', { largeur: 50 }),
        new FormulaireItem('pruflgimm', { largeur: 50 }),
        new FormulaireItem('pruflgemp', { largeur: 50 }),
        new FormulaireItem('pruflgtrsengbdg', { largeur: 50 }),
        new FormulaireItem('pruflgmuldem', { largeur: 50 })
    ]);

    vm.rmmMultiOccurrenceOptions = {
        bloc: 'rmm',
        fonctions: {
            recherche: false,
            afficherMenuSettingDansEntete: false,
            pagination: false
        },
        colonnesVisibles: [
            { nom: 'vaermmtyp_dsc', largeur: 328 },
            { nom: 'rmmmsg', largeur: 328 }
        ]
    };

    vm.prrMultiOccurrenceOptions = {
        fonctions: {
             pagination: true,
             boutonDupliquer: false
        },
        pagination: {
            nombreElementPage: 3,
            nombreEnregistrementInMenuMore: true
        },
        colonnesVisibles: [
            { nom: 'intcod', largeur: 200 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'prrflgsai', largeur: 200 },
            { nom: 'prrflgdef', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcleintrsp', { largeur: 50 }),
            new FormulaireItem('prrflgdef', { largeur: 50 }),
            new FormulaireItem('prrflgsai', { largeur: 50 })])
    };

    vm.prsMultiOccurrenceOptions = {
        navigatePage: mc0057Route.NAME,
        navigateParams: (rowData: any) => ({
            id: rowData.prscleint,
            prucleint: rowData.prucleint
        }),
        fonctions: {
            boutonDupliquer: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_COP',
                'BOUCOPPRS',
                (rowData: any) => ({
                    prucleint: vm.monoOccurrence.data.$id,
                    prscleint: rowData.prscleint
                }),
                {
                    icon: 'content_copy',
                    iconButton: false,
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_COP',
                        lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('prucleintcop', {
                                required: true,
                                resourceParams: (data: any) => ({
                                    prucleint: $stateParams.id ? data.prucleint : 0
                                })
                            }),
                            new FormulaireItem('prscleintcop', {
                                required: true
                            })
                        ], { largeurDefaut: 100 })
                    }
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'prscod', largeur: 100 },
            { nom: 'prsdscl01', largeur: 280 }
        ],
        colonnesVisibles: [
            { nom: 'intcod', largeur: 126 },
            { nom: 'intnom', largeur: 262 },
            { nom: 'sfucod', largeur: 65 },
            { nom: 'sfudsc', largeur: 280 },
            { nom: 'cifcod', largeur: 79 },
            { nom: 'cifdscabr', largeur: 280 },
            { nom: 'prsflgcifbdg', largeur: 130 },
            { nom: 'unacod', largeur: 110 },
            { nom: 'unadsc', largeur: 280 },
            { nom: 'prsflgunabdg', largeur: 160 },
            { nom: 'prsdscl02', largeur: 280 },
            { nom: 'remcod', largeur: 110 },
            { nom: 'vecprsreg', largeur: 130 },
            { nom: 'vecprsreg_dsc', largeur: 280 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('prscod'),
            new FormulaireItem('prsdscl01'),
            new FormulaireItem('prsdscl02'),
            new FormulaireItem('intcleintrsp', { required: true }),
            new FormulaireItem('sfucleintbdg'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cifcleintprs'),
            new FormulaireItem('prsflgcifbdg', { readonly: (data) => (data.$ancetre.pruflgcifbdg) }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unacleintprs'),
            new FormulaireItem('prsflgunabdg', { readonly: (data) => (data.$ancetre.pruflgunabdg) }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('remcleint'),
            new FormulaireItem('vecprsreg')
        ], { largeurDefaut: 33.33 }),
        colonnesCachees: ['prucleint']
    };

    vm.pmeMultiOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        }, 
       colonnesVisibles: [
            { nom: 'mthcod', largeur: 100 },
            { nom: 'mthdsc', largeur: 280 },
            { nom: 'mrpcod', largeur: 100 },
            { nom: 'mrpdsc', largeur: 280 },
            { nom: 'pmeprc', largeur: 115 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'rgaind', largeur: 120 },
            { nom: 'rgadsc', largeur: 280 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mthcleint', { modifAllow: false }),
            new FormulaireItem('mrpcleint'),
            new FormulaireItem('pmeprc'),
            new FormulaireItem('prupecvig'),
            new FormulaireItem('rgacleint', { modifAllow: false })
        ], { largeurDefaut: 33.33 })
    };

    vm.pdfMultiOccurrenceOptions = {
        bloc: 'pdf',
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_DETACHER',
                'BOUDETACHER',
                (data: any) => ({
                    prucleint: data.prucleint,
                    pdfcleint: data.$blocs.pdf.pdfcleint,
                    el1cleintbdg: data.el1cleintbdg
                }),
                {
                    iconButton: false,
                    icon: 'link_off',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    messageConfirmation: {
                        message: 'BOUINT_CONFIRM'
                    }
                }
            )
        ]),
        navigatePage: mc2045Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.pdfcleint
            };
        },

        colonnesFixesGauche: [
            { nom: 'prjcod', largeur: 114 },//, menu: [forage.FORVNT] },
            { nom: 'prjdscabr', largeur: 280 }
        ],
        colonnesVisibles: [
            { nom: 'pdfcod', largeur: 126 },
            { nom: 'etbcod', largeur: 126 },
            { nom: 'etbnom', largeur: 262 },
            { nom: 'orgcod', largeur: 100 },
            { nom: 'orgnomabr', largeur: 262 },
            { nom: 'orpcod', largeur: 100 },
            { nom: 'orpdscabr', largeur: 280 },
            { nom: 'pdfflgpri', largeur: 120 }, // Indicateur demande principale
            { nom: 'clinom', largeur: 240 }  // Nom du client
        ]
    };

}
