import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageCP1010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ICp1010Params extends IStateParamsService {
    entcleint: string;
}

/* @ngInject */
export default function Cp1010Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: ICp1010Params) {
    const vm: IPageCP1010 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'entcleint',
                valeur: $stateParams.entcleint,
                readOnly: true
            }
        ],

        colonnesVisibles: [
            {nom: 'tercod', largeur: 90},
            {nom: 'terdsc', largeur: 262},
            {nom: 'vaetercat', largeur: 100},
            {nom: 'vaedsc', largeur: 262},
            {nom: 'etsdatvig', largeur: 130}
        ]
    };
}
