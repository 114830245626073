import {IComponentController} from 'angular';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRR0133 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    formulaireOdi: IFormulaire;
    formulaireMod: IFormulaire;
}

/* @ngInject */
export default function Rr0133Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRR0133 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireOdi = new Formulaire('LBL_BLC_ODI', [
            new FormulaireItem('eplcleint'),
            new FormulaireItem('ttccleint'),
            new FormulaireItem('ttlcleint'),
            new FormulaireItem('demcleint',{
                default: (data: any) => (data.$valeurs.demcleint)
            })
       ]);

        vm.formulaireMod = new Formulaire('LBL_BLC_MOD', [
            new FormulaireItem('odiflgcor', {largeur: 50}),
            new FormulaireItem('odidatvig', {largeur: 50}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('odinumtel',   {largeur: 50}),
            new FormulaireItem('odinumpos',   {largeur: 50}),

            new FormulaireItem('odicom', {largeur: 100})

        ]);

    }
}
