import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRR0059 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0059Controller(rr0060Route: IRoute,
                                         Menu: IMenuClass,
                                         $stateParams: IStateParamsService,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0059 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: rr0060Route.NAME,
        navigateParams: (rowData: any) => ({
            id: rowData.aidcleint,
            demcleint: rowData.demcleint
        }),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0060Route.NAME, () => {
                return {
                    demcleint: $stateParams.employe
                };
            })
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprnusu', largeur: 262, hidden: $stateParams.employe},
            {nom: 'tagcod', largeur: 90},
            {nom: 'tagdscabr', largeur: 262},
            {nom: 'aiddatdeb', largeur: 100},
            {nom: 'aiddatfin', largeur: 100},
            {nom: 'aidflgact', largeur: 65},
            {nom: 'aidmnt', largeur: 126}
        ],
        colonnesCachees: [
            'aidcleint',
            'demcleint'
        ]
    };
}
