import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'ga2001',
    ecrans: ['00-2'],
    params: ['?peccoddeb',
             '?peccodfin',
             '?prucleint',
             '?el2cleint',
             '?cptcleint',
             '?nature']
});
