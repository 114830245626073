import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTP0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tp0015Controller(Menu: IMenuClass) {
    const vm: IPageTP0015 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'ktjcod', largeur: 200},
            {nom: 'trmcod', largeur: 200},
            {nom: 'trmdsc', largeur: 200},
            {nom: 'crscod', largeur: 200},
            {nom: 'crsdsc', largeur: 200},
            {nom: 'ktjflgval', largeur: 200},
            {nom: 'ktjflgtrt', largeur: 200}
        ]
    };
}
