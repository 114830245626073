import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IStateService } from 'angular-ui-router';

interface IGs0009Params {
    id: number;
    menuId: string;
}
interface IPageGs0009 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireUsr: IFormulaire;
    formulaireDonneesFin: IFormulaire;
    formulaireDonneesGrh: IFormulaire;
    formulairePas: IFormulaire;
    actionModifPassword: IMenuItem;
    gusMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pavMultiOccurrenceOptions: IMultiOccurrenceOptions;
    usvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    uscMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gupMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ussMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srhMultiOccurrenceOptions: IMultiOccurrenceOptions;
    groMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0009Controller(gs0122Route: IRoute,
    gs0066Route: IRoute,
    gs0067Route: IRoute,
    Formulaire: IFormulaireClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    $stateParams: IGs0009Params,
    $state: IStateService) {
    const vm: IPageGs0009 = this;

    vm.$onInit = $onInit;

    vm.valeursEcran = ecranDetails.valeurs;

    // Profil d'accès
    const forageProfilAcces = new MenuItemForage(
        'BTN_FORAGE_GS0122',
        gs0122Route.NAME,
        (data: any) => ({
            id: data.pracleint,
            indfor: 1
        })
    );

    // Action de production de l'écriture
    vm.actionModifPassword = new MenuItemAction(
        'LBL_BTN_MODIF_PASSWORD',
        'BOUMODPWD',
        () => ({}),
        {
            icon: 'security',
            iconButton: false,
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_MODIF_PASSWORD',
                lblConfirm: 'LBL_BTN_MODIFICATION',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('t_new_pwd', { required: true, maxlength: 128 }),
                    new FormulaireItem('t_con_pwd', { required: true, maxlength: 128 }),
                    new FormulaireItem('t_flgchgpwd', { largeur: 100 }),
                    new FormulaireItem('texte_aide', { largeur: 100, default: vm.valeursEcran.texte_aide })
                ], { largeurDefaut: 50 })
            }
        }
    );

    function $onInit() {
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_USR', [
                new FormulaireItem('usrcod', { onBlur: data => data.usrcod = data.usrcod.toUpperCase() }),
                new FormulaireItem('usrnom'),
                new FormulaireItem('usradrele', { largeur: 33.33, historiqueColonne: true, }),
                new FormulaireItem('usrsigaut', { largeur: 66.66, enableCount: true }),
                new FormulaireItem('usrdatfin', {
                    largeur: 33.33,
                    menu: [
                        new MenuItemAction( 
                            '',
                            'BOUMODDAT',
                            (data:any) => ({
                                usrcleintref: data.usrcleint
                            }),
                            {
                                icon: 'create',
                                successAction: (data: any) => {
                                    $state.go(gs0067Route.NAME, {
                                        menuId: $stateParams.menuId,
                                        usrcleintref: vm.monoOccurrence.data.usrcleint
                                    });
                                } 
                            }
                        ),
                        new MenuItemForage(
                            gs0066Route.NAME,
                            (data) => ({
                                usrcleintref: data.usrcleint
                            }),
                            {
                                icon: 'ballot'
                            }
                        )
                    ]
                }),
                new FormulaireItem('impcleint', { largeur: 33.33 }),
                new FormulaireItem('vaeusrlng', { largeur: 33.33, hidden: true }),
                new FormulaireItem('motdepasse', { largeur: 55, hidden: (data) => data.usrcleint, required: true, maxlength: 128 }),
                new FormulaireItemDivider("LBL_GESTION_IDENTITES"),
                new FormulaireItem("usrcodgid"),
                new FormulaireItem("gidcleint"),
                new FormulaireItem("usrcomptbd")
            ])
        };

        // Accès données finance
        vm.formulaireDonneesFin = new Formulaire('LBL_BLC_FIN', [
            new FormulaireItem('usrflgsecpru'),
            new FormulaireItem('usrflgsecuna'),
            new FormulaireItem('usrflgsecmag'),
            new FormulaireItem('usrflgsecsrv'),
            new FormulaireItem('usrflgsecgeb'),
            new FormulaireItem('usrflgsectyd')
        ], { largeurDefaut: 50 });

        // Accès donnée GRH
        vm.formulaireDonneesGrh = new Formulaire('LBL_BLC_GRH', [
            new FormulaireItem('usrflgsecurg'),
            new FormulaireItem('usrflgsecpos'),
            new FormulaireItem('usrflgsecuno'),
            new FormulaireItem('usrflgverfingrh')
        ], { largeurDefaut: 50 });

        // Groupe d'usager
        vm.gusMultiOccurrenceOptions = {
            bloc: 'gus',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'grucod', largeur: 100 },
                { nom: 'grudsc', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('grucleint', { largeur: 100 })
            ])
        };

        // Paramètre système
        vm.pavMultiOccurrenceOptions = {
            bloc: 'pav',
            fonctions: {
                importation: true,
                pagination: true
            },
            colonnesVisibles: [
                { nom: 'pasdsc', largeur: 100 },
                { nom: 'pavval', largeur: 100 },
            ],
            colonnesCachees: [
                'pasnomeleval'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pascleint'),
                new FormulaireItem('pavval'),
                new FormulaireItem('pavvalele', { resourceParams: (data: any) => ({ vaeelecle: data.pasnomeleval }) })
            ], { largeurDefaut: 100 })
        };

        // Compagnie
        vm.uscMultiOccurrenceOptions = {
            bloc: 'usc',
            colonnesVisibles: [
                { nom: 'ciecod', largeur: 130 },
                { nom: 'cienomabr', largeur: 200 },
                { nom: 'intcod', largeur: 130 },
                { nom: 'intnom', largeur: 200 },
                { nom: 'empcod', largeur: 130 },
                { nom: 'empnomprn', largeur: 200 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ciecle', { readonly: data => data.usrcleint }),
                new FormulaireItem('intcleint'),
                new FormulaireItem('empcleint')
            ], { largeurDefaut: 33 })
        };

        // Profil d'accès
        vm.gupMultiOccurrenceOptions = {
            bloc: 'gup',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'gupcle', largeur: 100 },
                { nom: 'pradsc', largeur: 300, menu: [forageProfilAcces] },
                { nom: 'vaesysapp_dsc', largeur: 120 },
                { nom: 'gupdscmnu', largeur: 300 },
                { nom: 'gupdatdeb', largeur: 126 },
                { nom: 'gupdatfin', largeur: 126 },
                { nom: 'gupflgouv', largeur: 100 },
                { nom: 'gupindact', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gupcle'),
                new FormulaireItem('pracleint'),
                new FormulaireItem('vaesysapp_dsc'),
                new FormulaireItem('gupdscmnu'),
                new FormulaireItem('gupdatdeb'),
                new FormulaireItem('gupdatfin'),
                new FormulaireItem('gupflgouv', { default: 1 }),
                new FormulaireItem('gupindact', { default: 1 })
            ], { largeurDefaut: 33 }),
            colonnesCachees: [
                'pracleint'
            ]
        };

        // Accès données
        vm.ussMultiOccurrenceOptions = {
            bloc: 'uss',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'usstypdsc', largeur: 250 },
                { nom: 'ciecod', largeur: 120 },
                { nom: 'cienom', largeur: 250 },
                { nom: 'usscod', largeur: 120 },
                { nom: 'ussdsc', largeur: 250 },
                { nom: 'ussnbrref', largeur: 180 }
            ],
            colonnesCachees: [
                'usstyp'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('usstyp'),
                new FormulaireItem('ciecle', {required: true}),
                new FormulaireItem('usscod'),
                new FormulaireItem('prucleint', {hidden: (data) => !(data.usstyp === 'PRU')}),
                new FormulaireItem('magcleint', {hidden: (data) => !(data.usstyp === 'MAG')}),
                new FormulaireItem('gbpcleint', {hidden: (data) => !(data.usstyp === 'GEB')})
            ], { largeurDefaut: 33 })
        };


        // Accès Service
        vm.usvMultiOccurrenceOptions = {
            bloc: 'usv',
            colonnesVisibles: [
                { nom: 'ciecod', largeur: 120 },
                { nom: 'cienom', largeur: 250 },
                { nom: 'srvcod', largeur: 120 },
                { nom: 'srvdscabr', largeur: 450 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ciecle'),
                new FormulaireItem('srvcleint')
            ])
        };

        // Accès données RH
        vm.srhMultiOccurrenceOptions = {
            bloc: 'srh',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'stadsc', largeur: 180 },
                { nom: 'ciecod', largeur: 120 },
                { nom: 'cienomabr', largeur: 250 },
                { nom: 'srhcod', largeur: 120 },
                { nom: 'srhdsc', largeur: 350 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stamnetab'),
                new FormulaireItem('srhcod'),
                new FormulaireItem('ciecle'),
                new FormulaireItem('urgcleint'),
                new FormulaireItem('unocleint')
            ], { largeurDefaut: 33 })
        };

        // Accées donnée Groupe d'UA RH
        vm.groMultiOccurrenceOptions = {
            bloc: 'gro',
            colonnesVisibles: [
                { nom: 'ciecod', largeur: 120 },
                { nom: 'cienomabr', largeur: 250 },
                { nom: 'srhcod', largeur: 150 },
                { nom: 'srhdsc', largeur: 350 },
                { nom: 'srhdatdeb', largeur: 126 },
                { nom: 'srhdatfin', largeur: 126 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ciecle'),
                new FormulaireItem('srhcleintref'),
                new FormulaireItem('srhdatdeb'),
                new FormulaireItem('srhdatfin')
            ], { largeurDefaut: 25 })
        };
    }
}
