import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePI0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0018Controller(Menu: IMenuClass) {
    const vm: IPagePI0018 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'monnumdoc', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'mondatvig', largeur: 200},
            {nom: 'pipnumdoc', largeur: 200},
            {nom: 'pipnumref', largeur: 200},
            {nom: 'pipdscabr', largeur: 200},
            {nom: 'mondsc', largeur: 200},
            {nom: 'moncom', largeur: 200}
        ]
    };
}
