import { IComponentController } from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IData } from '../../../../../core/services/data-linker.service';

interface IPageRe0006 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireRto: IFormulaire,
    formulaire: IFormulaire;
    tcsMultiOccurrence: IMultiOccurrence;
    tcsMultiOccurrenceOptions: IMultiOccurrenceOptions;  
    tpgMultiOccurrence: IMultiOccurrence;
    tpgMultiOccurrenceOptions: IMultiOccurrenceOptions;  
    tajMultiOccurrence: IMultiOccurrence;
    tajMultiOccurrenceOptions: IMultiOccurrenceOptions; 
    tpbMultiOccurrence: IMultiOccurrence;
    tpbMultiOccurrenceOptions: IMultiOccurrenceOptions;        
}

/* @ngInject */
export default function Re0006Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         re2002Route: IRoute,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {

    const vm: IPageRe0006 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                supprime : () => vm.monoOccurrence.data.vaesysprvret != 'PROD' && vm.monoOccurrence.data.vaertotyp != '02',
                afficherMenusDansEntete: true
            }
        };
        vm.formulaire = new Formulaire('LBL_BLC_RTO', [
            new FormulaireItem('rtocleint', {largeur: 40, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tagcleint', {largeur: 40, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint', {largeur: 40, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD', resourceParams: (data: IData) => ({rtocleint: data.rtocleint})}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('strcleint', {largeur: 40, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tpmdatvig', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),            
            new FormulaireItem('tpmflgact', {largeur: 20, readonly: true, default: 1}), 
            new FormulaireItemDivider(' '),             
            new FormulaireItem('tpmfct', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}), 
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tpmmnt', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItem('unccleintmnt', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tpmprctaudem', {largeur: 20, readonly: (data) => data.vaertotyp === '02'}),
            new FormulaireItem('tpmmntmax', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}), 
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('bqecleint', {largeur: 40, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),           
            new FormulaireItem('bqecleinttauqte', {largeur: 40, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('bqecleinttaumnt', {largeur: 40, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('vaesysprvret', {largeur: 20, readonly: true, default: 'RETR'}),
            new FormulaireItem('tpmflgina', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'RETR'}),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('tpmprcapp', {largeur: 20, readonly: (data) => data.vaertotyp === '02'})

        ]);

        // Onglets Corr. taux saisie
        vm.tcsMultiOccurrenceOptions = {
            bloc: 'tcs',
            fonctions: {
                supprime : () => vm.monoOccurrence.data.vaesysprvret != 'PROD' && vm.monoOccurrence.data.vaertotyp != '02',
                importation: true
            },
            colonnesVisibles: [
                {nom: 'tcsanctau', largeur: 100},
                {nom: 'tcsnoutau', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tcsanctau'   , {largeur: 10}),
                new FormulaireItem('tcsnoutau'   , {largeur: 41})
            ])
        }

        // Onglets TAG à générer
        vm.tpgMultiOccurrenceOptions = {
            bloc: 'tpg',
            fonctions: {
                importation: true,
                supprime : () => vm.monoOccurrence.data.vaesysprvret != 'PROD' && vm.monoOccurrence.data.vaertotyp != '02'
            },
            colonnesVisibles: [
                {nom: 'tagcleintdsc', largeur: 10},
                {nom: 'tpgflginvval', largeur: 100}
            ],
            colonnesCachees: [
                'vaertotyp',
                'vaesysprvret'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleintgen'   , {largeur: 10, readonly: () => vm.monoOccurrence.data.vaertotyp == '02' || vm.monoOccurrence.data.vaesysprvret === 'PROD'}),
                new FormulaireItem('tpgflginvval'   , {largeur: 41, readonly: () => vm.monoOccurrence.data.vaertotyp == '02' || vm.monoOccurrence.data.vaesysprvret === 'PROD'})
            ])
        }
        // Onglets TAG à ajouter
        vm.tajMultiOccurrenceOptions = {
            bloc: 'taj',
            fonctions: {
                supprime : () => vm.monoOccurrence.data.vaesysprvret != 'PROD' && vm.monoOccurrence.data.vaertotyp != '02',
                importation: true
            },
            colonnesVisibles: [
                {nom: 'tagcleintdsc', largeur: 100},
                {nom: 'vaesystgaqted', largeur: 120},
                {nom: 'vaesystgamntd', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint'   , {largeur: 100, readonly: () => vm.monoOccurrence.data.vaertotyp == '02' || vm.monoOccurrence.data.vaesysprvret === 'PROD' }),
                new FormulaireItemSautDeLigne(), 
                new FormulaireItem('vaesystgaqte'   , {largeur: 41, readonly: () => vm.monoOccurrence.data.vaertotyp == '02' || vm.monoOccurrence.data.vaesysprvret === 'PROD'}),
                new FormulaireItem('vaesystgamnt'   , {largeur: 41, readonly: () => vm.monoOccurrence.data.vaertotyp == '02' || vm.monoOccurrence.data.vaesysprvret === 'PROD'})
            ])
        }
         // Onglets Borne
         vm.tpbMultiOccurrenceOptions = {
            bloc: 'tpb',
            fonctions: {
                importation: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    re2002Route.NAME,
                    () => ({
                        id: vm.monoOccurrence.data.tpmcleint
                    })
                )
            ]),  
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'BTN_MOD',
                    re2002Route.NAME,
                    () => ({
                        id: vm.monoOccurrence.data.tpmcleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => vm.efcMultiOccurrence.fetchDataList()
                    }
                )
            ]),         
            colonnesVisibles: [
                {nom: 'tpbborinf', largeur: 100},
                {nom: 'tpbtau', largeur: 120}
            ]
        }
      

    }
}
