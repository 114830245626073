import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFilterLibelle} from '../../../../../core/filters/ex-libelle.filter';

interface IPageMr0166 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0166Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         exLibelleFilter: IFilterLibelle) {
    const vm: IPageMr0166 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'ttlcod', largeur: 89},
            {nom: 'ttldsc', largeur: 180},
            {nom: 'ttlflgdom', largeur: 91},
            {nom: 'ttlflgrap', largeur: 75},
            {nom: 'ttlflgbur', largeur: 76},
            {nom: 'ttlflgfax', largeur: 105},
            {nom: 'ttlflgcel', largeur: 91},
            {nom: 'ttlflgurg', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ttlcod', {required: true}),
            new FormulaireItem('ttldsc', {required: true, largeur: 50}),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ttlflgdom'),
            new FormulaireItem('ttlflgrap'),
            new FormulaireItem('ttlflgbur'),
            new FormulaireItem('ttlflgfax'),
            new FormulaireItem('ttlflgcel'),
            new FormulaireItem('ttlflgurg')
            ],
            {largeurDefaut: 15}
        )
    };
}
