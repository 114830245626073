import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IComponentController } from 'angular';

interface IPageTF0015 extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function TF0015Controller(Pagination: IPaginationClass) {
    const vm: IPageTF0015 = this;
    vm.detMultiOccurrenceOptions = {
        bloc: 'emd',
        colonnesVisibles: [
            { nom: 'foacod', largeur: 105 },
            { nom: 'foadsc', largeur: 280 },
            { nom: 'emdmnt_imp', largeur: 144 },
            { nom: 'emdmnt_non_imp', largeur: 146 },
            { nom: 'emdmnt', largeur: 144 }
        ],
        colonnesCachees: ['foacleint', 'folcleint', 'rfecleint'],
        pagination: new Pagination({
            nombreElementPage: 15,
            nbElementsPossibles: [15, 25, 50]
        })
    };
    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'folcod', largeur: 110 },
            { nom: 'foldsc', largeur: 280 },
            { nom: 'folann', largeur: 75 }
        ],
        colonnesVisibles: [
            { nom: 'rfecod', largeur: 156 },
            { nom: 'rfenom', largeur: 280 },
            { nom: 'nbrreleve_imp', largeur: 135 },
            { nom: 'nbrreleve_non_imp', largeur: 115 }
        ],
        colonnesCachees: ['rfecleint'],
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        multiOccurrenceOptionsEnfant: vm.detMultiOccurrenceOptions
    };
}
