import { IComponentController } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCb0010 extends IComponentController {
    menus: { [key: string]: Array<IMenuItem> },
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    depMultiOccurrence: IMultiOccurrence;
    revMultiOccurrence: IMultiOccurrence;
    actMultiOccurrence: IMultiOccurrence;
    pasMultiOccurrence: IMultiOccurrence;
    avoMultiOccurrence: IMultiOccurrence;
    depMultiOccurrenceOptions: IMultiOccurrenceOptions;
    revMultiOccurrenceOptions: IMultiOccurrenceOptions;
    actMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    avoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0010Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    cb2001Route: IRoute,
    cb2002Route: IRoute,
    cb0011Route: IRoute,
    cb0013Route: IRoute,
    cb0015Route: IRoute,
    cb2007Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageCb0010 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('elecleint', { default: ($stateParams.id), hidden: true }),
        new FormulaireItem('anncivfin', {
            titre: ' ',
            default: 'CIVILE',
            required: true
        }),
        new FormulaireGroupe('', new Formulaire([
            //new FormulaireItem('anncivfin', { titre: ' ',
            //  default: 'CIVILE',
            // required: true
            //}),            
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('pecannperdeb', { readonly: (data: any) => data.anncivfin === 'FINANCIERE' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('pecannperfin', { readonly: (data: any) => data.anncivfin === 'FINANCIERE' })
            ]), { largeur: 30 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('peccoddeb', { readonly: (data: any) => data.anncivfin === 'CIVILE' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('peccodfin', { readonly: (data: any) => data.anncivfin === 'CIVILE' })
            ]), { largeur: 30 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('indcleintcri', { required: true })
            ]), { largeur: 40 }),
            new FormulaireItem('annper_deb', { hidden: true }),
            new FormulaireItem('annper_fin', { hidden: true })
        ]), { largeur: 95 })
    ]);

    // Onglet "Depenses"
    vm.depMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2002',
                cb2002Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'DEP',
                    niveau: $stateParams.niveau,
                    annper_deb: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_deb,
                    annper_fin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_fin,
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_FORAGE_CB2002',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0011',
                cb0011Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'DEP',
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0011',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0013',
                cb0013Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'DEP',
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0013',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0015',
                cb0015Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'DEP',
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0015',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData: any) => ({
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'DEP',
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            effaceCriteres: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130, titre: ' ' },
            { nom: 'mntsum', largeur: 120, titre: ' ' },
            { nom: 'mntrsv', largeur: 120, titre: ' ' },
            { nom: 'ctbmntimp', largeur: 120, total: true }
        ],
        colonnesCachees: [
            'elecleint', 'vaesysnatctb', 'tymcleint'
        ]
    }

    // Onglet "Revenus"
    vm.revMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2002',
                cb2002Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'REV',
                    niveau: $stateParams.niveau,
                    annper_deb: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_deb,
                    annper_fin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_fin,
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_FORAGE_CB2002',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0011',
                cb0011Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'REV',
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB0011',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0013',
                cb0013Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'REV',
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB0013',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0015',
                cb0015Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'REV',
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB0015',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData: any) => ({
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'REV',
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.revMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            effaceCriteres: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130, titre: ' ' },
            { nom: 'mntsum', largeur: 120, titre: ' ' },
            { nom: 'mntrsv', largeur: 120, titre: ' ' },
            { nom: 'ctbmntimp', largeur: 120, total: true }
        ],
        colonnesCachees: [
            'elecleint', 'vaesysnatctb', 'tymcleint'
        ]
    };

    // Onglet "Actifs"
    vm.actMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2002',
                cb2002Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'ACT',
                    niveau: $stateParams.niveau,
                    annper_deb: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_deb,
                    annper_fin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_fin,
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_FORAGE_CB2002',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0011',
                cb0011Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'ACT',
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0011',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0013',
                cb0013Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'ACT',
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0013',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0015',
                cb0015Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'ACT',
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0015',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData: any) => ({
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'ACT',
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.actMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            effaceCriteres: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130, titre: ' ' },
            { nom: 'mntsum', largeur: 120, titre: ' ' },
            { nom: 'mntrsv', largeur: 120, titre: ' ' },
            { nom: 'ctbmntimp', largeur: 120, total: true }
        ],
        colonnesCachees: [
            'elecleint', 'vaesysnatctb', 'tymcleint'
        ]
    };

    // Onglet "Passifs"
    vm.pasMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2002',
                cb2002Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'PAS',
                    niveau: $stateParams.niveau,
                    annper_deb: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_deb,
                    annper_fin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_fin,
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_FORAGE_CB2002',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0011',
                cb0011Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'PAS',
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB0011',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0013',
                cb0013Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'PAS',
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB0013',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0015',
                cb0015Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'PAS',
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB0015',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData: any) => ({
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'PAS',
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            effaceCriteres: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130, titre: ' ' },
            { nom: 'mntsum', largeur: 120, titre: ' ' },
            { nom: 'mntrsv', largeur: 120, titre: ' ' },
            { nom: 'ctbmntimp', largeur: 120, total: true }
        ],
        colonnesCachees: [
            'elecleint', 'vaesysnatctb', 'tymcleint'
        ]
    };

    // Onglet "Avoirs"
    vm.avoMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2002',
                cb2002Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'AVO',
                    niveau: $stateParams.niveau,
                    annper_deb: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_deb,
                    annper_fin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.annper_fin,
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_FORAGE_CB2002',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0011',
                cb0011Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'AVO',
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB0011',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0013',
                cb0013Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'AVO',
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB0013',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0015',
                cb0015Route.NAME,
                (rowData: any) => ({
                    id: rowData.elecleint,
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'AVO',
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB0015',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData: any) => ({
                    niveau: $stateParams.niveau,
                    elecleint: rowData.elecleint,
                    vaesysnatctb: 'AVO',
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    anncivfin: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin,
                    peccoddeb: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperdeb :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb ),
                    peccodfin: (  vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.anncivfin !== 'FINANCIERE' ? 
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.pecannperfin :
                                        vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            effaceCriteres: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130, titre: ' ' },
            { nom: 'mntsum', largeur: 120, titre: ' ' },
            { nom: 'mntrsv', largeur: 120, titre: ' ' },
            { nom: 'ctbmntimp', largeur: 120, total: true }
        ],
        colonnesCachees: [
            'elecleint', 'vaesysnatctb', 'tymcleint'
        ]
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cb2001Route.NAME,
                (rowData: any) => ({
                    type: 'EL',
                    elecleint: rowData.elecleint,
                    vaesysnatctb: rowData.vaesysnatctb,
                    tymcleint: rowData.tymcleint,
                    tydcleint: rowData.tydcleint,
                    niveau: $stateParams.niveau,
                    annper_deb: rowData.annper_deb,
                    annper_fin: rowData.annper_fin,
                    cummntsum: rowData.cummntsum

                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 130, titre: ' ' },
            { nom: 'cummntsum', largeur: 120, titre: ' ' }
        ],
        colonnesCachees: [
            'elecleint',
            'vaesysnatctb',
            'tymcleint',
            'tydcleint',
            'annper_deb',
            'annper_fin'
        ]
    };

}
