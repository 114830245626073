import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageRr0018 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTtl: IFormulaire;
    valeursEcran: any;
}

interface IRr0018Params {
    id: string;
    demcleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0018Controller($stateParams: IRr0018Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr0018 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.formulaireDpr = new Formulaire('LBL_PARAM_REMUN', [
            new FormulaireItem('dprdatvig'),
            new FormulaireItem('vaesyscodprv'),
            new FormulaireItem('dprflgact', {
                default: 1
            }),
            new FormulaireItem('rfecleintfed'),
            new FormulaireItem('rfecleintprv', {required: (data:any) => (data.vaesyscodprv === 'QC')}),
            new FormulaireItem('rfecleintcss'),
            new FormulaireItem('demcleint', {
                default: $stateParams.demcleint
            })
        ], {
            largeurDefaut: 33.33
        });
    }
}
