import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCC0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0009Controller(
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cc0010Route: IRoute
) {
    const vm: IPageCC0009 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cc0010Route.NAME,     
        actionsNouveaux: new Menu([
            new MenuItemForage(cc0010Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        }, 
        colonnesFixesGauche: [
            { nom: 'unocod', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'unodscabr', largeur: 270 },
            { nom: 'uopflghabprf', largeur: 110 },
            { nom: 'uopflgrsqann', largeur: 110 },
            { nom: 'uopflgaff', largeur: 140 },
            { nom: 'uopflgavinoudel', largeur: 110 },
            { nom: 'nirniv', largeur: 90 },
            { nom: 'nirdscabr', largeur: 300 },
            { nom: 'eplnummat', largeur: 140 },
            { nom: 'eplnomprn', largeur: 262 },
            { nom: 'uopadrcouaff', largeur: 262 },
            { nom: 'urgcod', largeur: 122 },
            { nom: 'urgdscabr', largeur: 262 },
            { nom: 'tarcod', largeur: 108 },
            { nom: 'tardsc', largeur: 262 },
            { nom: 'uopcomatr', largeur: 262 }
        ]
    };
}
