import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageGs0034 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Gs0034Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite
                                         ) {
    const vm: IPageGs0034 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'ustcod',             largeur: 40},
            {nom: 'ustdsc',             largeur: 80},
            {nom: 'ustdscabr',          largeur: 80},
            {nom: 'vaeustentdsc',          largeur: 40},
            {nom: 'vaeustprcprxdsc',       largeur: 40},
            {nom: 'vaeustprcqtedsc',       largeur: 40},
            {nom: 'vaeustqtedsc',          largeur: 40},
            {nom: 'vaeustprcmntdsc',       largeur: 40},
            {nom: 'vaeustmntdsc',          largeur: 40},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ustcod'),
            new FormulaireItem('ustdsc'),
            new FormulaireItem('ustdscabr'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeustent'),
            new FormulaireItem('vaeustprcprx'),
            new FormulaireItem('vaeustprcqte'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeustqte'),
            new FormulaireItem('vaeustprcmnt'),
            new FormulaireItem('vaeustmnt'),


        ], {largeurDefaut: 33.33})
    };
}

