import IStateService = angular.ui.IStateService;
import {IComponentController, IFilterDate} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMR0104 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireGec: IFormulaire;
    echMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IMr0104Params {
    id: string;
    menuId: string;
}

/* @ngInject */
export default function Mr0104Controller(mr0104Route: IRoute,
                                         $stateParams: IMr0104Params,
                                         $state: IStateService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         dateFilter: IFilterDate) {
    const vm: IPageMR0104 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                boutonDupliquer: false
            },
            actionsMore: new Menu([
                    new MenuItemAction(
                        'LBL_BTN_COPIER',
                        'BOUCOP',
                        (data: any) => ({
                            id: data.eslcleint
                        }),
                        {
                            icon: 'content_copy',
                            fonction: 'FN_BTN_COPIER',
                            saveFirst: true,
                            successAction: (data: any) => {
                                $state.go(mr0104Route.NAME, {id: data.eslcleintnew, menuId: $stateParams.menuId});
                            },
                            formulaireConfirmationAction: {
                                lblTitre: 'LBL_BTN_COPIER',
                                lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                                largeurFormulaire: 30,
                                formulaire: new Formulaire([
                                    new FormulaireItem('datvigcop', {
                                        required: true,
                                        resourceParams: (data: any) => {
                                            return {
                                                cvtcod: data.cvtcodcop || data.cvtcod,
                                                geccleint: data.geccleint
                                            };
                                        },
                                        nombreLibellesDetailsCacher: 1
                                    }),
                                    new FormulaireItem('geccleintcop', {
                                        required: true,
                                        default: (data:any) => {
                                            return data.geccleint;
                                        },
                                        resourceParams: (data: any) => {
                                            return {
                                                cvtcleint: data.cvtcleint,
                                                unccleint: data.unccleint,
                                                vaegectypech: data.vaegectypech
                                            };
                                        },
                                        nombreLibellesDetailsCacher: 1
                                    }),
                                    new FormulaireItem('eslsalbascop', {
                                        hidden: (data) => (data.vaegectypech !== '3'),
                                        nombreLibellesDetailsCacher: 1
                                    }),
                                    new FormulaireItem('eslprcaugcop', {
                                        hidden: (data) => (data.vaegectypech == '3'),
                                        nombreLibellesDetailsCacher: 1
                                    }),
                                    new FormulaireItem('nbrdeccop', {
                                        required: true,
                                        default: (data:any) => {
                                            if (data.ucdcodcop === 'HRE' || data.ucdcod === 'HRE') {
                                                return 2
                                            } else {
                                                return 0
                                            }
                                        },
                                        nombreLibellesDetailsCacher: 1
                                    })
                                ], {
                                    largeurDefaut: 100
                                })
                            }
                        }
                    )
                ])
        };

        vm.formulaireEsl = new Formulaire('LBL_ECHELLE_SAL', [
            new FormulaireItem('geccleint',{
                largeur: 66.66
            }),
            new FormulaireItem('eslflgact', {
                largeur: 33.33,
                default: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('daedatvig', {
                default: dateFilter(new Date(), 'yyyy-MM-dd'),
                largeur: 50,
                nombreLibellesDetailsCacher: 1,
                resourceParams: (data: any) => {
                    return {
                        cvtcod: data.cvtcod
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eslprcaug'),
            new FormulaireItem('eslsalbas')
        ]);

        vm.formulaireGec = new Formulaire('LBL_BLC_GEC', [
            new FormulaireItem('geccodcla'),
            new FormulaireItem('vaegectypech_dsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cvtcoddsc'),
            new FormulaireItem('uncdsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gecflgechmar'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cqacoddsc'),
            new FormulaireItem('cqocoddsc'),
        ]);

        vm.echMultiOccurrenceOptions = {
            bloc: 'ech',
            fonctions: {
                pagination: true,
                importation: true
            },
            colonnesFixesGauche: [
                {nom: 'echcod', largeur: 91}
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'echsal', largeur: 126},
                {nom: 'echsalmin', largeur: 126},
                {nom: 'echsalmax', largeur: 126},
                {nom: 'echfct', largeur: 126}

            ],
            formulaire: new Formulaire([
                new FormulaireItem('echcod'),
                new FormulaireItem('echsal'),
                new FormulaireItem('echfct'),
                new FormulaireItem('echsalmin'),
                new FormulaireItem('echsalmax')
            ], {largeurDefaut: 33.33})                                
        };        
    }
}
