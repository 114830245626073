import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePD0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0021Controller(Menu: IMenuClass) {
    const vm: IPagePD0021 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'loccod', largeur: 200},
            {nom: 'locdsc', largeur: 200},
            {nom: 'veclocnivcon', largeur: 200},
            {nom: 'vecpmiendaut', largeur: 200},
            {nom: 'vecpmiendautdsc', largeur: 200},
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'locdatdeb', largeur: 200},
            {nom: 'locdatfin', largeur: 200},
            {nom: 'veclocniv', largeur: 200},
            {nom: 'locdatderchg', largeur: 200},
            {nom: 'locdatimpr', largeur: 200},
            {nom: 'locaut', largeur: 200}
        ]
    };
}
