import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';

interface IPageRr0143 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireErc: IFormulaire;
    
}

/* @ngInject */
export default function Rr0143Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageRr0143 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireErc = new Formulaire('', [
            new FormulaireItem('employe'),
            new FormulaireItem('rcecoddscabr'),
            new FormulaireItem('darqtearr'),
            new FormulaireItem('darmntarr'),
            new FormulaireItem('darmntmaxrec')            
        ], {
            largeurDefaut: 33.33
        });
      
    }

}
