import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IComponentController} from "angular";
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';

interface IPageGs0123 extends IComponentController {
    formulaireCls: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    clmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gsdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0123Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0123 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Client d'application
        vm.formulaireCls = new Formulaire('LBL_BLC_CLS', [
            new FormulaireItem('clscod'),
            new FormulaireItem('clsnom', { largeur: 40 }),
            new FormulaireItem('vaeclstyp', { largeur: 40 })
        ], { largeurDefaut: 20 });

        // Module
        vm.clmMultiOccurrenceOptions = {
            bloc: 'clm',
            colonnesVisibles: [
                {nom: 'vaesysmodsof_dsc', largeur: 194},
                {nom: 'clmdatins'       , largeur: 140}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vaesysmodsof', {largeur: 66}),
                new FormulaireItem('clmdatins')
            ], { largeurDefaut: 33 })
        };

        // Passerelle
        vm.gsdMultiOccurrenceOptions = {
            bloc: 'gsd',
            colonnesVisibles: [
                {nom: 'gsdcod', largeur: 100},
                {nom: 'gsddsc', largeur: 328}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gsdcod', { maxlength: 8 }),
                new FormulaireItem('gsddsc', { largeur: 66 })
            ], { largeurDefaut: 33 })
        };
    }
}
