import ecran from '../../../../../core/injectors/ecran.injector';
import Mr0193Controller from './mr0193.controller';

export default ecran({
    source: 'mr0193',
    ecrans: ['00-2'],
    params: ['id', '?chscleint'],
    template: require('./mr0193.html'),
    controller: Mr0193Controller
});

