import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageDB0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0010Controller(Menu: IMenuClass, MenuItemForage: IMenuItemForageClass, $stateParams: IStateParamsService, db0011Route: IRoute) {
    const vm: IPageDB0010 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: db0011Route.NAME,
        navigateParams: (data: any) => {
            return {
                id: data.rfocleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(db0011Route.NAME)
        ]),
        colonnesVisibles: [
            { nom: 'rfonumdoc', largeur: 150 },
            { nom: 'prucod', largeur: 120 },
            { nom: 'prudscl01', largeur: 220 },
            { nom: 'vaesysnivctr', largeur: 120 },
            { nom: 'vaedscnivctr', largeur: 180 },
            { nom: 'vaerfotyputl', largeur: 140 },
            { nom: 'vaedsctyputl', largeur: 190 },
            { nom: 'vaerfostudsc', largeur: 140 },
            { nom: 'non_utilise', largeur: 120 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 180 },
            { nom: 'rfomntrsv', largeur: 140 },
            { nom: 'rfomntutl', largeur: 130 },
            { nom: 'rfomntann', largeur: 130 },
            { nom: 'rfodsc', largeur: 200 }
        ],
        colonnesCachees: ['rfocleint'],
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
