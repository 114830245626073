import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageDb0058 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0058Controller(
    db0003Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass
) {
    const vm: IPageDb0058 = this;

    vm.multiOccurrenceOptions = {
        actionsMoreLigne: new Menu([
            new MenuItemAction('LBL_BTN_COPIER', 'BOUCOP', () => ({}), {
                icon: 'content_copy',
                saveFirst: true,
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_COPIER',
                    lblConfirm: 'G_LBL_BTN_OK',
                    largeurFormulaire: 60,
                    formulaire: new Formulaire('LBL_MODEL_DESTINATION', [
                        new FormulaireItem('mofcodcp', { largeur: 30, maxlength: 3 }),
                        new FormulaireItem('mofdsccp', { largeur: 30 })
                    ])
                }
            })
        ]),
        navigatePage: db0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.mofcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(db0003Route.NAME, () => ({}), {
                fonction: 'BOUNOU'
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'mofcod', largeur: 100 },
            { nom: 'mofdsc', largeur: 280 }
        ],
        colonnesCachees: ['mofcleint']
    };
}
