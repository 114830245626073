import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageCO0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0024Controller(rr0031Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
) {

    const vm: IPageCO0024 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'urgcod', largeur: 110}
        ],
        colonnesVisibles: [
            {nom: 'urgdsc', largeur: 270},
            {nom: 'tafcod', largeur:100},
            {nom: 'tafdsc', largeur: 262},
            {nom: 'tafcodnou', largeur:120},
            {nom: 'tafdscnou', largeur:262},
            {nom: 'ttccod', largeur:120},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'vaccodrai', largeur:120},
            {nom: 'vacdscrai', largeur:262},
            {nom: 'pcqdsclng', largeur: 394}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('tafcleint'),
            new FormulaireItem('tafcleintnou'),
            new FormulaireItem('ttccleint'),
            new FormulaireItem('vactsmrai'),
            new FormulaireItem('pcqdsclng',  { enableCount: true} ),
        ])
    };
}










