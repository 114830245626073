import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageDo0028 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    apcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    thoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    thhMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tteMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}


/* @ngInject */
export default function Do0028Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    MenuItemEcran: IMenuItemEcranClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    mr0008Route: IRoute,
    FormulaireItemDivider: IFormulaireItemDividerClass
) {
    const vm: IPageDo0028 = this;
    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BESN',[
            new FormulaireItem('tbpcod', {
                largeur: 50
            }),
            new FormulaireItem('tbpflgasscre', {
                largeur: 20
            }),
            new FormulaireItem('tbpdsc', {
                largeur: 20
            }),
            new FormulaireItem('vaemodsai', {
                largeur: 50
            }),
            new FormulaireItem('tbpflgoffemp', {
                largeur: 50
            }),
            new FormulaireItem('ttccleint', {
                largeur: 50
            }),
            new FormulaireItem('ttccleintpro', {
                largeur: 50
            }),
            new FormulaireItem('ttccleintpropas', {
                largeur: 50
            }),
            new FormulaireItem('ttccleintrem', {
                largeur: 50
            }),
            // Saisie 
            new FormulaireGroupeAccordeon('LBL_AUTRES_SAI', new Formulaire([
                new FormulaireItemDivider('LBL_BLC_SAI'),
                new FormulaireItem('pfccleint', {
                    largeur: 100
                }),
                new FormulaireItem('tbpdatfinobl', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflglitobl', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgpossupobl', {
                    largeur: 33
                }),

                new FormulaireItem('tbpflgintdemobl', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgintperobl', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgpiejoireq', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgcomreq', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgtacres', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgmodremun', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgoblsup', {
                    largeur: 50
                }),
                new FormulaireItem('vaesystypsup', {
                    largeur: 50
                }),
                new FormulaireItemDivider('LBL_BLC_DEC'),
                new FormulaireItem('tbpflgoridec', {
                    largeur: 33,
                    default: 1
                }),
                new FormulaireItem('tbpflgempsoudec', {
                    largeur: 33,
                    default: 1
                }),
                new FormulaireItem('tbpflgechindaccdec', {
                    largeur: 33,
                    default: 1
                }),
                new FormulaireItem('tbpflgfsoaccdec', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgposaccdec', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgongrhaccdec', {
                    largeur: 33,
                    default: 1
                }),
                new FormulaireItem('tbpflgpopaccdec', {
                    largeur: 33
                }),
                new FormulaireItem('tbpflgongecsaccdec', {
                    largeur: 33,
                    default: 1
                }),
                new FormulaireItem('tbpflgcanpresel', {
                    largeur: 33
                }),
                new FormulaireItemDivider('LBL_BLC_VAL'),
                new FormulaireItem('tbpflgvaldat', {
                    largeur: 33
                })
            ])),
            // Demande rémun.
            new FormulaireGroupeAccordeon('LBL_DEM_REM', new Formulaire([
                new FormulaireItem('tdmcleint', {
                    largeur: 50
                }),
                new FormulaireItem('tagcleint', {
                    largeur: 50,
                    resourceParams: (data: any) => ({ tdmcleint: data.tdmcleint })
                })
            ])),
            // Offre d'emploi
            new FormulaireGroupeAccordeon('LBL_OFR_EMP', new Formulaire([
                new FormulaireItem('rglcleintdmp', {
                    largeur: 33,
                    resourceParams: (data: any) => ({ pfccleint: data.pfccleint })
                }),
                new FormulaireItem('rglcleintoff', {
                    largeur: 33,
                    resourceParams: (data: any) => ({ pfccleint: data.pfccleint })
                }),
                new FormulaireItem('ttccleintoff', {
                    largeur: 33
                }),
                new FormulaireItem('tbpdscclatra', {
                    largeur: 100
                }),
                new FormulaireItem('tbpdscfinaff', {
                    largeur: 100
                }),
                new FormulaireItem('tbpdscreghor', {
                    largeur: 80
                }),
                new FormulaireItem('tbpflghreoff', {
                    largeur: 20
                })
            ]))
        ], { largeurDefaut: 100 })
    }
    // Avis 
    vm.apcMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 130 },
            { nom: 'pfcdsc', largeur: 130 },
            {
                nom: 'indflgavi', largeur: 130, menu: [
                    new MenuItemEcran(
                        'LBL_CUMULATIF',
                        mr0008Route.NAME,
                        (rowData: any) => ({
                            id: rowData.apacleint,
                            cleintref: rowData.apccleint,
                            stamnetab: 'APC',
                            vaesystypavi: rowData.vaesystypavi,
                            typsai: 'JRS',
                            supprime: 1,
                            vaebmtent: '-1'
                        }),
                        {
                            fonction: 'CUMULATIF',
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'txtavi', largeur: 130 }
        ],
        colonnesCachees: ['vaesystypavi', 'apccleint', 'apccleintref', 'apacleint'],
        formulaire: new Formulaire([
            new FormulaireItem('vaesystypavi'),
            new FormulaireItem('pfccleint'),
        ], { largeurDefaut: 33 })
    }
    // Horaire / Temps
    vm.thhMultiOccurrenceOptions = {
        bloc: 'thh',
        criteresSuggeres: new Formulaire([
            new FormulaireItem('tbpflgtouhorper', {
                default: (data: any) => (
                    data.$ancetre.tbpflgtouhorper
                ),
                readonly: true
            })
        ]),
        colonnesVisibles: [
            { nom: 'thhflgact', largeur: 120 },
            { nom: 'asgcod', largeur: 120 },
            { nom: 'asgdsc', largeur: 120 },
            { nom: 'ahtcod', largeur: 120 },
            { nom: 'ahtdsc', largeur: 120 },
            { nom: 'ahthresem', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('thhflgact', {
                largeur: 33
            }),
            new FormulaireItem('asgcleint', {
                largeur: 33
            }),
            new FormulaireItem('ahtcleint', {
                largeur: 33,
                resourceParams: (data: any) => ({ asgcleint: data.asgcleint })
            })
        ])
    };

    // Titre d'emploi 
    vm.tteMultiOccurrenceOptions = {
        bloc: 'tte',
        criteresSuggeres: new Formulaire([
            new FormulaireItem('tbpflgtoutemper', {
                default: (data: any) => (
                    data.$ancetre.tbpflgtoutemper
                ),
                readonly: true
            })
        ]),
        colonnesVisibles: [
            { nom: 'tteflgact', largeur: 120 },
            { nom: 'temcod', largeur: 120 },
            { nom: 'temdscabr', largeur: 120 },
            { nom: 'temhreheb', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tteflgact'),
            new FormulaireItem('temcleint')
        ])
    };
}
