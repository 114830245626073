import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mc0162',
    ecrans: ['00-2'],
    params: [
        'vaesysdevcod',
        'vaedevtyptau'
    ]
});
