import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IComponentController } from 'angular';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePB0002 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pvpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pvpMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Pb0002Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    pb0003Route: IRoute,
    profil: IProfil,
    Pagination: IPaginationClass
) {
    const vm: IPagePB0002 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true,
        id: profil.preferences.usrcleint
    };

    vm.pvpMultiOccurrenceOptions = {
        bloc: 'pvp',
        pagination: new Pagination({
            nombreElementPage: 6,
            nbElementsPossibles: [3, 6, 15]
        }),
        navigatePage: pb0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                pvecleint: rowData.pvecleint,
                pvpcleint: rowData.pvpcleint
            };
        },
        fonctions: {
            pagination: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 112 }
        ],
        colonnesVisibles: [            
            { nom: 'vecprucatpro_dsc', largeur: 150 },
            { nom: 'pvpmntpresal', largeur: 110, total: true },
            { nom: 'pvpmntpreavs', largeur: 110, total: true },
            { nom: 'pvpmntpretot', largeur: 110, total: true },
            { nom: 'pvpmntreesal', largeur: 110, total: true },
            { nom: 'pvpmntreeavs', largeur: 110, total: true },
            { nom: 'pvpmntreetot', largeur: 110, total: true },
            { nom: 'pvpmntprosal', largeur: 110, total: true },
            { nom: 'pvpmntproavs', largeur: 110, total: true },
            { nom: 'pvpmntprotot', largeur: 110, total: true },
            { nom: 'pvpmnttotreepro', largeur: 150, total: true },
            { nom: 'pvpmntectsal', largeur: 120, total: true },
            { nom: 'pvpmntectavs', largeur: 140, total: true },
            { nom: 'prudscl01', largeur: 240 },
            { nom: 'prudatdeb', largeur: 120 },
            { nom: 'prudatfin', largeur: 120 },
            { nom: 'pvpmntectsalavs', largeur: 100, total: true },
            { nom: 'nbs_pos', largeur: 100, total: true },
            { nom: 'flgavr', largeur: 100 },
            { nom: 'flgerr', largeur: 100 }
        ],
        colonnesCachees: ['pvecleint', 'pvpcleint', 'avrmsg', 'errmsg']
    };
}
