import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0309 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface IMr0309Params {
    ovncleintper: number;
    ovdcleint: number;
    rsccleint: number;
    stamnetab: string;
    ovddatvig: number;
    flgmod: boolean;
    intcleint: number;
}

/* @ngInject */
export default function Mr0309Controller($stateParams: IMr0309Params,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0309 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        bloc: 'imp',
        fonctions: {
            editionRapide: $stateParams.flgmod,
            edition: false,
            nouveau: () => vm.multiOccurrence.editionRapideActive
        },
        editionRapideActive: $stateParams.flgmod,
        colonnesVisibles: [
            {nom: 'imp', largeur: 250},
            {nom: 'ovnprc_car', largeur: 131},
            {nom: 'ovncom', largeur: 500}
        ],
        colonnesEditionRapide: [
            {nom: 'ovdcleint', largeur: 300, default: $stateParams.ovdcleint, hidden: true},
            {nom: 'rsccleint', largeur: 300, default: $stateParams.rsccleint, hidden: true},
            {nom: 'ovncleintper', largeur: 300, default: $stateParams.ovncleintper, hidden: true},
            {nom: 'imp', largeur: 250},
            {nom: 'ovnprc', largeur: 131, default: 100},
            {nom: 'ovncom', largeur: 500}
        ]
    };
}
