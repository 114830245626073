import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMR0209 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0209Controller(mr0104Route: IRoute,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         dateFilter: IFilterDate,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0209 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        navigatePage: mr0104Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.eslcleint
            };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('flgact', {
                required: true,
                default: 1
            }),

            new FormulaireItem('datvig', {
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            }),

            new FormulaireItem('flghisto', {
                required: true,
                default: 0
            }),
        ]),
        colonnesFixesGauche: [
            {nom: 'geccod', largeur: 90},
            {nom: 'gecdsc', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'geccat', largeur: 100},
            {nom: 'geccodcla', largeur: 90},
            {nom: 'cvtcod', largeur: 115},
            {nom: 'cvtdsc', largeur: 262},
            {nom: 'unccod', largeur: 105},
            {nom: 'daedatvig', largeur: 100},
            {nom: 'eslprcaug', largeur: 90},
            {nom: 'eslsalbas', largeur: 126},
            {nom: 'eslflgact', largeur: 65},            
            {nom: 'echcod', largeur: 90},
            {nom: 'echsal', largeur: 126},
            {nom: 'echsalmin', largeur: 126},
            {nom: 'echsalmax', largeur: 126},
            {nom: 'echfct', largeur: 90}            
            ],
        colonnesCachees: [
          'eslcleint'
        ],
        formulaire: new Formulaire('LBL_BLC_ECH', [
            // Indicateur toujours caché pour savoir si on crée ou non.
            new FormulaireItem('flgcreation',{
                default : (data:any) => !data.$id
            }),

            new FormulaireItem('geccleint'),
            new FormulaireItem('daedatvig', {
                resourceParams: (data: any) => {
                    return {
                        cvtcod: data.cvtcod,
                        geccleint: data.geccleint
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('echcod'),
            new FormulaireItem('echdsc'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('echsal',{
                largeur : 25}),
            new FormulaireItem('echsalmin',  {
                largeur : 25
            }),
            new FormulaireItem('echsalmax',  {
                largeur : 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('echfct')
        ], {
            largeurDefaut: 33.33
        })
    };
}
