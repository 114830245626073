import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRr0529 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    edlMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function rr0529Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0529 = this;

    vm.edlMultiOccurrenceOptions = {
        bloc: 'edl',
        pagination: {
            nombreElementPage: 10,
            nbElementsPossibles: [10, 50, 100]
        },
        fonctions: {
            editionRapide: true
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 250 }
        ],
        colonnesVisibles: [
            { nom: 'edlqte', largeur: 100 },
            { nom: 'edlmnt', largeur: 100 },
            { nom: 'edladh', largeur: 100 },
            { nom: 'edlaut', largeur: 100 },
            { nom: 'edlqua', largeur: 100 },
            { nom: 'edladm', largeur: 120 },
            { nom: 'edlflgtrt', largeur: 100 },
            { nom: 'edldatnai', largeur: 100 },
            { nom: 'edldatdep', largeur: 100 },
            { nom: 'edldatret', largeur: 100 },
            { nom: 'edldatdec', largeur: 100 },
            { nom: 'eplnas', largeur: 110 },
            { nom: 'edlcom', largeur: 300 },
        ],
        colonnesEditionRapide: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 250 },
            { nom: 'edlqte', largeur: 100 },
            { nom: 'edlmnt', largeur: 100 },
            { nom: 'edladh', largeur: 100 },
            { nom: 'edlaut', largeur: 100 },
            { nom: 'edlqua', largeur: 100 },
            { nom: 'edladm', largeur: 120 },
            { nom: 'edlflgtrt', largeur: 100 },
            { nom: 'edldatnai', largeur: 100 },
            { nom: 'edldatdep', largeur: 100 },
            { nom: 'edldatret', largeur: 100 },
            { nom: 'edldatdec', largeur: 100 },
            { nom: 'eplnas', largeur: 110 },
            { nom: 'edlcom', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('edlflgtrt', { largeur: 25 }),
            new FormulaireItem('edlcom', { largeur: 75 })
        ], { largeurDefaut: 100 })
    };
    vm.multiOccurrenceOptions = {
        fonctions: {
            pagination: true
        },
        pagination: {
            nombreElementPage: 3,
            nbElementsPossibles: [3, 25, 50]
        },
        colonnesVisibles: [
            { nom: 'earann', largeur: 100 },
            { nom: 'fpncod', largeur: 100 },
            { nom: 'fpndsc', largeur: 225 },
            { nom: 'colcod', largeur: 110 },
            { nom: 'coldsc', largeur: 225 },
            { nom: 'earqtequa', largeur: 126 },
            { nom: 'earmntqua', largeur: 126 },
            { nom: 'earfctprb', largeur: 126 },
            { nom: 'datcre', largeur: 200 }
        ],
        multiOccurrenceOptionsEnfant: vm.edlMultiOccurrenceOptions
    };
}
