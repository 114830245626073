import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageRr0128 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0128Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0128 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 110},
            {nom: 'eplnomprn', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 95},
            {nom: 'rcedsc', largeur: 250},
            {nom: 'mntarr', largeur: 150},
            {nom: 'vaeecxmth_dsc', largeur: 150},
            {nom: 'ppacod', largeur: 90},
            {nom: 'ppadatdeb', largeur: 100},
            {nom: 'ppadatfin', largeur: 100},
            {nom: 'ecxmnt', largeur: 90},
            {nom: 'ecxprc', largeur: 90},
            {nom: 'ecxflggensys', largeur: 90}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('rcecleint'),
            new FormulaireItem('vaeecxmth'),
            new FormulaireItem('ppaannper'),
            new FormulaireItem('ecxmnt'),
            new FormulaireItem('ecxprc'),
            new FormulaireItem('ecxflggensys'),
            new FormulaireItem('t_unpcleint'),
            new FormulaireItem('t_vaevalcle')
        ],{largeurDefaut: 20})
    };
}
