import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service'
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';

interface IPagePR0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    pddMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pddMultiOccurrence: IMultiOccurrence;
    pchMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pchMultiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Pr0024Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass
) {
    const vm: IPagePR0024 = this;


    vm.multiOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_GRP', [
            new FormulaireTemplateSlot('pddDate'),
            new FormulaireTemplateSlot('pchChaire')
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('prjcleint'),
            new FormulaireItem('orgcleint'),
            new FormulaireItem('intcleint'),
            new FormulaireItem('tri', {
                default: 1
            }),
            new FormulaireItem('annoct'),
            new FormulaireItem('annee'),
            new FormulaireItem('typcleint')
        ]),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true,
            pagination: true
        },
        pagination: {
            nombreElementPage: 25,
            nbElementsPossibles: [25, 50, 100]
        },
        colonnesFixesGauche: [
            { nom: 'prjcod', largeur: 150 },
            { nom: 'pdfcod', largeur: 250 }
        ],
        colonnesVisibles: [
            { nom: 'prjdsc', largeur: 300 },
            { nom: 'intcod_rsp', largeur: 150 },
            { nom: 'intnom_rsp', largeur: 150 },
            { nom: 'prjdatdeb', largeur: 150 },
            { nom: 'prjdatfin', largeur: 150 },
            { nom: 'prjflgpro', largeur: 150 },
            { nom: 'orgcod', largeur: 150 },
            { nom: 'orgnom', largeur: 450 },
            { nom: 'ficmntoct_tot', largeur: 150 },
            { nom: 'datfin', largeur: 150 },
            { nom: 'mntdem_an1', largeur: 150 },
            { nom: 'mntdem_an2', largeur: 150 },
            { nom: 'mntdem_an3', largeur: 150 },
            { nom: 'mntdem_an4', largeur: 150 },
            { nom: 'mntdem_an5', largeur: 150 },
            { nom: 'mntdem_an6', largeur: 150 },
            { nom: 'mntdem_an7', largeur: 150 },
            { nom: 'mntoct_an1', largeur: 150 },
            { nom: 'mntoct_an2', largeur: 150 },
            { nom: 'mntoct_an3', largeur: 150 },
            { nom: 'mntoct_an4', largeur: 150 },
            { nom: 'mntoct_an5', largeur: 150 },
            { nom: 'mntoct_an6', largeur: 150 },
            { nom: 'mntoct_an7', largeur: 150 },
            { nom: 'typcod', largeur: 150 },
            { nom: 'typdsc', largeur: 250 },
            { nom: 'etbcod', largeur: 150 },
            { nom: 'etbnom', largeur: 250 },
            { nom: 'cencodext', largeur: 150 },
            { nom: 'cencodint', largeur: 150 },
            { nom: 'cendscint', largeur: 150 },
            { nom: 'liecodadm', largeur: 150 },
            { nom: 'liedscadm', largeur: 250 },
            { nom: 'caocod', largeur: 150 },
            { nom: 'caodsc', largeur: 290 },
            { nom: 'orpcod', largeur: 150 },
            { nom: 'orpdsc', largeur: 220 },
            { nom: 'tyfcod', largeur: 150 },
            { nom: 'tyfdsc', largeur: 350 },
            { nom: 'toccod', largeur: 150 },
            { nom: 'tocdsc', largeur: 290 },
            { nom: 'cetact', largeur: 150 },
            { nom: 'pdfnumref', largeur: 150 },
            { nom: 'ficmntdemtot', largeur: 150 },
            { nom: 'pdfcom', largeur: 150 },
            { nom: 'pdfcodproint', largeur: 150 },
            { nom: 'pdfdatdebree', largeur: 150 },
            { nom: 'pdfdatfinree', largeur: 150 },
            { nom: 'mntrecsru_an1', largeur: 150 },
            { nom: 'mntrecsru_an2', largeur: 150 },
            { nom: 'mntrecsru_an3', largeur: 150 },
            { nom: 'mntrecsru_an4', largeur: 150 },
            { nom: 'mntrecsru_an5', largeur: 150 },
            { nom: 'mntrecsru_an6', largeur: 150 },
            { nom: 'mntrecsru_an7', largeur: 150 },
            { nom: 'mntrecorg_an1', largeur: 150 },
            { nom: 'mntrecorg_an2', largeur: 150 },
            { nom: 'mntrecorg_an3', largeur: 150 },
            { nom: 'mntrecorg_an4', largeur: 150 },
            { nom: 'mntrecorg_an5', largeur: 150 },
            { nom: 'mntrecorg_an6', largeur: 150 },
            { nom: 'mntrecorg_an7', largeur: 150 },
            { nom: 'pdfannoct', largeur: 150 },
            { nom: 'ficdurpro', largeur: 150 }
        ]

    };

    vm.pddMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'vecpddtyprap', largeur: 150 },
            { nom: 'vecdsc', largeur: 150 },
            { nom: 'pdddat', largeur: 150 },
            { nom: 'pddcom', largeur: 150 }
        ]
    }

    vm.pchMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'pchnom', largeur: 150 },
            { nom: 'pchdep', largeur: 150 },
            { nom: 'pchdatemb', largeur: 150 },
            { nom: 'pchdatcon', largeur: 150 },
            { nom: 'pchflgembpro', largeur: 150 }
        ]
    }
}

