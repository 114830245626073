import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IComponentController} from 'angular';

interface IPageMc0145 extends IComponentController{
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    ulsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0145Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc0145 = this;

    vm.$onInit = $onInit; 

    function $onInit(): void {
        vm.multiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'stcnom', largeur: 126},
                {nom: 'stcdsc', largeur: 200},
                {nom: 'tydcod', largeur: 126},
                {nom: 'tyddsc', largeur: 200},
                {nom: 'utldsc', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stcnom', {largeur: 50}),
                new FormulaireItem('tydcleint', {largeur: 50}),
                new FormulaireItem('utldsc', {largeur: 50})
            ])
        };
    
        vm.ulsMultiOccurrenceOptions = {
            bloc: 'uls',
            colonnesVisibles: [
                {nom: 'ulsseq', largeur: 126},
                {nom: 'stanom', largeur: 200},
                {nom: 'stadsc', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ulsseq', {largeur: 50}),
                new FormulaireItem('stacleint', {largeur: 50})
    
            ])
        };

    }
    
}
