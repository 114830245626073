import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCb0050 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    mntMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0050Controller(Menu: IMenuClass, 
        MenuItemForage: IMenuItemForageClass, 
        ecranDetails: IEcranDetailsResourcesEntite, 
        db0011Route: IRoute) {
    const vm: IPageCb0050 = this;
    vm.$onInit = $onInit;
    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.mntMultiOccurrenceOptions = {
            bloc: 'mnt',
            fonctions: {
                suiviModification: true
            },
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'LBL_INFO_FINANC',
                    db0011Route.NAME,
                    (rowData: any) => {
                        return {
                            id: rowData.rfocleint
                        };
                    },
                    {
                        icon: 'launch',
                        fonction: 'BOUOUV'
                    }
                )
            ]),
            colonnesFixesGauche: [{ nom: 'rfonumdoc', largeur: 163 }],
            colonnesVisibles: [
                { nom: 'rfomntrsv', largeur: 163 },
                { nom: 'rfomntutl', largeur: 163 },
                { nom: 'rfomntann', largeur: 163 }
            ],
            colonnesFixesDroite: [{ nom: 'prusolde', largeur: 163, total: true }],
            colonnesCachees: ['rfocleint', 'vaesysnivappctr']
        };
    }
}
