import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRR0557 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0557Controller($stateParams: IStateParamsService) {
    const vm: IPageRR0557= this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ppacod'       , largeur: 90},
            {nom: 'vaedgvval_dsc', largeur: 300, hidden: !$stateParams.selection },
            {nom: 'rcecod'       , largeur: 100, hidden:  $stateParams.selection },
            {nom: 'rcedscabr'    , largeur: 250, hidden:  $stateParams.selection },
            {nom: 'rcflibbdg'    , largeur: 250, hidden:  $stateParams.selection },
            {nom: 'valnum'       , largeur: 130},
            {nom: 'valdat'       , largeur: 104},
            {nom: 'valcar'       , largeur: 150}
        ]
    };
}
