import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPagePI0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0007Controller(Menu: IMenuClass,
                                         Operateur: IOperateurService) {
    const vm: IPagePI0007 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesVisibles: [
            {nom: 'pphnumdoc', largeur: 200},
            {nom: 'pipnumdoc', largeur: 200},
            {nom: 'pipnumref', largeur: 200},
            {nom: 'pphdscl01', largeur: 200},
            {nom: 'prucod', largeur: 200},
            {nom: 'ecldsceta', largeur: 200},
            {nom: 'pphdatdeb', largeur: 200},
            {nom: 'pphdatfin', largeur: 200},
            {nom: 'pphflgstu', largeur: 200},
            {nom: 'tphcod', largeur: 200},
            {nom: 'tphdsc', largeur: 200},
            {nom: 'pipdscabr', largeur: 200},
            {nom: 'cifcod', largeur: 200},
            {nom: 'cifdscabr', largeur: 200},
            {nom: 'unacod', largeur: 200},
            {nom: 'unadscabr', largeur: 200},
            {nom: 'pphprcret', largeur: 200},
            {nom: 'trnflgstaref', largeur: 200},
            {nom: 'trnflgstafin', largeur: 200},
            {nom: 'trnflgstafer', largeur: 200},
            {nom: 'trnflgstaann', largeur: 200}
        ]
    };
}
