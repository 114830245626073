import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageCP0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0023Controller(cp0025Route: IRoute,
    cp0033Route: IRoute,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemEcran: IMenuItemEcranClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0023 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cp0025Route.NAME,
        navigateParams: (rowData: any) => ({
            lorcleint: (rowData.lorcleint)
        }),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_REPORTER',
                'BOUREP',
                () => ({}),
                {
                    icon: 'check_circle_outline',
                    disabled: (rowData: any) => !(rowData.lorflgrep)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_IMPRIMER',
                'BOUIMP',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_IMPRIMER',
                        largeurFormulaire: 25,
                        formulaire: new Formulaire([
                            new FormulaireItem('pronumchq', {required: true, maxlength: 10, largeur: 100}),
                        ])
                    },
                    icon: 'print',
                    disabled: (rowData: any) => (rowData.tydcleint === 78),
                    hidden: (rowData:any) => (!rowData.scbflgpronum)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_IMPRIMER',
                'BOUIMP',
                () => ({}),
                {
                    icon: 'print',
                    disabled: (rowData: any) => (rowData.tydcleint === 78),
                    hidden: (rowData:any) => (rowData.scbflgpronum)
                }
            ),
            new MenuItemEcran(
                'LBL_BTN_CONF_TAUX',
                cp0033Route.NAME,
                (data:any) => ({ lorcleint: data.lorcleint }),
                {
                    icon: 'check_circle_outline',
                    disabled: (rowData: any) => !(rowData.tydcleint === 79 || rowData.tydcleint === 89)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_PROD_FIC',
                'BOUTFE',
                () => ({}),
                {
                    icon: 'file_upload',
                    disabled: (rowData: any) => !(rowData.tydcleint === 78)
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'lotcod', largeur: 89 },
            { nom: 'lorcodseq', largeur: 60 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'tydcod', largeur: 65 },
            { nom: 'tyddsc', largeur: 244 },
            { nom: 'lorflgstu', largeur: 65 },
            { nom: 'lorflgrep', largeur: 91 },
            { nom: 'lordatrep', largeur: 150 },
            { nom: 'nbr', largeur: 82 },
            { nom: 'regmnttot', largeur: 126 },
            { nom: 'mopcod', largeur: 95 },
            { nom: 'mopdsc', largeur: 196 },
            { nom: 'lordsc', largeur: 207 }
        ],
        colonnesCachees: [
            'tydcleint',
            'lorcleint',
            'scbflgpronum'
        ]
    };
}
