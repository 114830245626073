import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCb2005 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Cb2005Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    cb0036Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
) {
    const vm: IPageCb2005 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('prucleintcri', {
                    default: $stateParams.prucleint,
                    disabled: true
                }),
                new FormulaireItem('vaesysnatctbcri', {
                    default: $stateParams.vaesysnatctb,
                    disabled: true
                }),
                new FormulaireItem('indcleintcri', {
                    default: $stateParams.indcleint,
                    disabled: true
                })
            ], {largeurDefaut: 33}
        ),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0036',
                cb0036Route.NAME,
                (rowData: any) => ({
                    id: rowData.el1cleint,
                    el1cleint: rowData.el1cleint,
                    prucleint: $stateParams.prucleint,
                    pecann: vm.valeursEcran.pecanncri
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BTN_FORAGE_CB0036',
                    iconButton: true
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'el1cod', largeur: 160 },
            { nom: 'el1dsc', largeur: 220 },
        ],
        colonnesVisibles: [
            { nom: 'orgcod', largeur: 160 },
            { nom: 'orgnom', largeur: 200 },
            { nom: 'orpcod', largeur: 160 },
            { nom: 'orpdsc', largeur: 200 },
            { nom: 'el1datdeb', largeur: 126 },
            { nom: 'el1datfin', largeur: 126 }
        ],
        colonnesFixesDroite: [
            { nom: 'cegmntsum', largeur: 120 }
        ]
    };
}
