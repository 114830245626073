import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';

interface IPageAL0010 {
    monoOccurrenceOptions:IMonoOccurrenceOptions;
    dliMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dlhMultiOccurrenceOptions: IMultiOccurrenceOptions;
}



/* @ngInject */
export default function Al0010Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageAL0010 = this;

    vm.monoOccurrenceOptions={

        formulaire: new Formulaire( [
            new FormulaireItem('prfcod',{largeur: 30}),
            new FormulaireItem('prfdscabr',{largeur: 45}),
            new FormulaireItem('dlpseqmod',{largeur: 25}),
            new FormulaireItem('dlpdatdeb',{largeur: 30}),
            new FormulaireItem('dlpdatfin',{largeur: 45}),
            new FormulaireItem('dlpflgact',{largeur: 25})
        ])
    }

    vm.dliMultiOccurrenceOptions = {
        bloc: 'dli',
        colonnesVisibles: [
            { nom: 'vaedlitypdatald_desc', largeur: 105 },
            { nom: 'dlidatdeb', largeur: 105 },
            { nom: 'dlidatfin', largeur: 262 },
            { nom: 'nbjrs', largeur: 100 },
            { nom: 'dlicom', largeur: 100 },
            { nom: 'dliseqmod', largeur: 100 },
            { nom: 'dliflgact', largeur: 100 }
        ] 
    },
    vm.dlhMultiOccurrenceOptions = {
        bloc: 'dlh',
        colonnesVisibles: [
            { nom: 'dlhdatvig', largeur: 105 },
            { nom: 'dlhpctrep', largeur: 105 },
            { nom: 'dlhcom', largeur: 300 },
            { nom: 'dlhtauhor', largeur: 100 },
            { nom: 'dlhseqmod', largeur: 50 },
            { nom: 'dlhflgact', largeur: 20 }
        ] 
    }
}
