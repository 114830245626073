import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from 'angular';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCP0027 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    payMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    lteMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any
}

/* @ngInject */
export default function Cp0027Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Operateur: IOperateurService,
    MenuItemForage: IMenuItemForageClass,
    cp2008Route: IRoute
) {

    const vm: IPageCP0027 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.menuItemSupportPaiement = new MenuItemAction(
        'LBL_BTN_SUPPORT_PAIEMENT',
        'BOUSUPPAI',
        () => ({}),
        {
            iconButton: false,
            icon: 'print',
            saveFirst: true,
            disabled: () => !(vm.valeursEcran.lotflgsuppai)
        }
    );

    vm.menuItemProductionFichier = new MenuItemAction(
        'LBL_BTN_PRODUCTION_FICHIER',
        'BOUPROFIC',
        () => ({}),
        {
            iconButton: false,
            icon: 'attach_file',
            saveFirst: true,
            hidden: () => !(vm.valeursEcran.flgprdfic)
        }
    );

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_LOT', [
            new FormulaireItem('ttccleint', { hidden: true }),
            new FormulaireItem('lotcod'),
            new FormulaireItem('lotdsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('peccleint'),
            new FormulaireItem('lotdatdeb'),
            new FormulaireItem('lotnumreftrf'),
            new FormulaireItemDivider("LBL_DIV_CRITERES"),
            new FormulaireItem('scbcleint', { required: true }),
            new FormulaireItem('vaelotcodesc'),
            new FormulaireItem('lotdatref'),
            new FormulaireItem('mopcleint'),
            new FormulaireItem('fogcleint'),
            new FormulaireItem('refcleint'),
            new FormulaireItem('grpcleint')
        ], { largeurDefaut: 33.33 })
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_SUPPRIMER',
                'BOUSUP',
                () => ({}),
                {
                    icon: '',
                    iconButton: true,
                    successAction: () => { vm.monoOccurrence.fetchData() }
                }
            ),
        ]),
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 80 },
            { nom: 'tyddsc', largeur: 180 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tydcleint', { largeur: 100 })
        ])
    };

    vm.lteMultiOccurrenceOptions = {
        bloc: 'lte',
        colonnesVisibles: [
            { nom: 'refcod', largeur: 80 },
            { nom: 'refnom', largeur: 180 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', { largeur: 100 })
        ])
    };

    vm.payMultiOccurrenceOptions = {
        bloc: 'pay',
        fonctions: {
            rechercheCollapsable: false
        },
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'BTN_TRANS_DET',
                cp2008Route.NAME,
                (rowData: any) => (
                    {
                        id: rowData.lotcleint,
                        lotcleint: rowData.lotcleint,
                        vuetyp: rowData.vuetyp,
                        refcleint: rowData.refcleint,
                        mopcleint: rowData.mopcleint,
                        radcleint: rowData.radcleint,
                        empcleintdep: rowData.empcleintdep,
                        paycleint: rowData.paycleint
                    }
                )
            )
        ]),
        forcerEtatPredefinisDepart: 'REF',
        etatsPredefinis: [
            {
                nom: 'REF',
                filtres: [
                    {
                        colonne: 'vuetyp',
                        operateur: Operateur.EGALE,
                        valeur: 'REF'
                    }
                ],
                colonnesVisibles: [
                    'refcod',
                    'refnomabr',
                    'retmnt',
                    'retmntesc',
                    'rejmnt',
                    'rejmntesc'
                ]
            },
            {
                nom: 'MOP',
                filtres: [
                    {
                        colonne: 'vuetyp',
                        operateur: Operateur.EGALE,
                        valeur: 'MOP'
                    }
                ],
                colonnesVisibles: [
                    'mopcod',
                    'mopdsc',
                    'retmnt',
                    'retmntesc',
                    'rejmnt',
                    'rejmntesc'
                ]
            },
            {
                nom: 'REG',
                filtres: [
                    {
                        colonne: 'vuetyp',
                        operateur: Operateur.EGALE,
                        valeur: 'REG'
                    }
                ],
                colonnesVisibles: [
                    'refcod',
                    'refnomabr',
                    'retmnt',
                    'retmntesc',
                    'rejmnt',
                    'rejmntesc'
                ]
            }
        ],
        colonnesVisibles: [
            { nom: 'refcod', largeur: 100 },
            { nom: 'refnomabr', largeur: 200 },
            { nom: 'mopcod', largeur: 100 },
            { nom: 'mopdsc', largeur: 200 },
            { nom: 'retmnt', largeur: 126, total: true },
            { nom: 'retmntesc', largeur: 126, total: true },
            { nom: 'rejmnt', largeur: 126, total: true },
            { nom: 'rejmntesc', largeur: 126, total: true }
        ],
        colonnesCachees: [
            'lotcleint',
            'vuetyp',
            'refcleint',
            'mopcleint',
            'radcleint',
            'empcleintdep',
            'paycleint'
        ]
    }
}
