import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0117{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0117Controller(Menu: IMenuClass,
                                         mr0128Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0117 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0128Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mr0128Route.NAME,
        colonnesVisibles: [
            {nom: 'prfcod', largeur: 90},
            {nom: 'prfdscabr', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'alddatvig', largeur: 100},
            {nom: 'alddatfin', largeur: 100},
            {nom: 'aldflgact', largeur: 65},
            {nom: 'aldflgsal', largeur: 100},
            {nom: 'aldflgimp', largeur: 100}
        ]
    };
}
