import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";

interface IPageGs0015 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireTrl: IFormulaire;
    trpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fomMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0015Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageGs0015 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulaireTrl = new Formulaire('LBL_BLC_TRL', [
            new FormulaireItem('trlcod', {largeur: 25, modelOptions:{updateOn: 'blur'}}),
            new FormulaireItem('trldsc', {largeur: 75}),
            new FormulaireItem('trldscmen'),
            new FormulaireItem('srccleint', {largeur: 33}),
            new FormulaireItem('clscleint', {largeur: 33}),
            new FormulaireItem('frmcleint', {largeur: 33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('trltypficimp', {largeur: 25}),
            new FormulaireItem('trlflgpub', {largeur: 25}),
            new FormulaireItem('trlflgcyc', {largeur: 25}),
            new FormulaireItem('trlflgsui', {largeur: 25}),
            new FormulaireGroupeAccordeon('LBL_ACC_PUB', new Formulaire([
                new FormulaireItem('vaetrltyppub'),
                new FormulaireItem('vaedstlev'),
                new FormulaireItem('trlnomtab'),
                new FormulaireItem('trlnomcle'),
                new FormulaireItem('trlnomblb'),
                new FormulaireItem('trlnomext')
            ]))
        ], {largeurDefaut: 100});
        vm.trpMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'srpcle', largeur: 100},
                {nom: 'ptlcod', largeur: 180},
                {nom: 'trpval', largeur: 110}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('srpcle'),
                new FormulaireItem('trpval'),
                new FormulaireItem('t_srccleint', {default: (data: any) => data.$ancetre.srccleint})
            ], {largeurDefaut: 25})
        };

        vm.menMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'vaesysenttrt_dsc', largeur: 120},
                {nom: 'mendsc', largeur: 300},
                {nom: 'mendscmen', largeur: 300}
            ]
        };

        vm.fomMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'srccod', largeur: 120},
                {nom: 'srcdsc', largeur: 250}
            ]
        };
    }
}
