import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageGl0045 extends IComponentController {
    valeursEcran: any;
    formulaire: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    efrMultiOccurrence: IMultiOccurrence;
    efrMultiOccurrenceOptions: IMultiOccurrenceOptions;
}




/*

    Attention, cet écran est maintenant inutilisé...

    Il a été intégré dans la modale GL0043

*/




/* @ngInject */
export default function Gl0045Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGl0045 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.efrMultiOccurrenceOptions = {
            editionRapideActive: true,
            fonctions: {
                importation: true,
                editionRapide: true
            },
            colonnesVisibles: [
                { nom: 'eflnumligdet', largeur: 70 },
                { nom: 'efltxtdet', largeur: 400 },
                { nom: 'eflnumligtot', largeur: 70 },
                { nom: 'efltxttot', largeur: 400 }
            ],
            colonnesCachees: [
                'eficleint'
            ],
            colonnesEditionRapide: [
                {nom: 'eflnumligdet', largeur: 150},
                {nom: 'efltxtdet', largeur: 300},
                {nom: 'eflcleinttot', largeur: 300, resourceParams: (data: any) => ({ eficleint: data.eficleint })}
            ],
            formulaire: new Formulaire('LBL_SAISIE_RATIO', [
                new FormulaireItem('eflcleinttot', {
                    largeur: 35,
                    resourceParams: (data: any) => ({ eficleint: data.eficleint })
                })
            ], { largeurDefaut: 25 })
        };
    }
}
