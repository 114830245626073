import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';

interface IPageAs0049 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0049Controller(as0048Route: IRoute,
    Menu: IMenuClass,
    $state: IStateService,
    MenuItemAction: IMenuItemActionClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Operateur: IOperateurService,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    exLibelleFilter: IFilterLibelle,
    MenuItem: IMenuItemClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    downloadManager: IDownloadManagerService,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass) {
    const vm: IPageAs0049 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: as0048Route.NAME,
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/as0049/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        actionsNouveaux: new Menu([
            new MenuItemForage(as0048Route.NAME)
        ]),
        colonnesVisibles: [
            { nom: 'ftinumcha', largeur: 136 },
            { nom: 'ftificsou', largeur: 200 },
            { nom: 'datcre', largeur: 110 },
            { nom: 'usrnom', largeur: 180 },
            { nom: 'ftinbrlig', largeur: 120 },
            { nom: 'ftinbrerr', largeur: 120 },
            { nom: 'ftinbrsansaft', largeur: 120 },
            { nom: 'ftinbravecaft', largeur: 120 },
            { nom: 'ftiflgval', largeur: 126 },
            { nom: 'ftiflggen', largeur: 126 }
        ],
        colonnesCachees: ['fticleint']
    };
}
