import { IComponentController, IFilterDate } from 'angular';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForage, IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
interface IPageCr0039 extends IComponentController {
    formulaireCld: IFormulaire;
    btnMenus: Array<IMenuItemForage>;
    iccMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cchMultiOccurrenceOptions: IMultiOccurrenceOptions;
    msaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cesMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cdsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cltMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cdmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ecoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    nndMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sltMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ainMultiOccurrenceOptions: IMultiOccurrenceOptions;
    somMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0039Controller(cr0004Route: IRoute,
    cr2003Route: IRoute,
    cr0041Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    profil: IProfil,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    exDateFilter: IFilterExDate,
    dateFilter: IFilterDate) {
    const vm: IPageCr0039 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('cldcod', { largeur: 25 }),
            new FormulaireItem('flgchqpos', { largeur: 20, readonly: true }),
            new FormulaireItem('flgentente', { largeur: 20, readonly: true }),
            new FormulaireItem('cldflgstu', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cldnom'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cldprn'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cldnomabr'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cldsld')
        ])
    };

    vm.iccMultiOccurrenceOptions = {
        bloc: 'icc',
        colonnesVisibles: [
            { nom: 'rvilibele', largeur: 120 },
            { nom: 'iccval', largeur: 100 }
        ]
    };

    // Charges
    vm.cchMultiOccurrenceOptions = {
        bloc: 'cch',
        colonnesFixesGauche: [
            { nom: 'cchdat', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'chgcod', largeur: 110 },
            { nom: 'chgdsc', largeur: 190 },
            { nom: 'cchmnt', largeur: 110, total: true },
            { nom: 'cchdsc', largeur: 180 },
            { nom: 'cchcodref1', largeur: 160 },
            { nom: 'cchcodref2', largeur: 160 },
            { nom: 'cchcodref3', largeur: 160 },
            { nom: 'ennnumdoc', largeur: 140 },
            { nom: 'cchdatimpfin', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('chgcleint',),
            new FormulaireItem('cchdat', { default: exDateFilter(new Date()) }),
            new FormulaireItem('cchmnt'),
            new FormulaireItem('cchdsc', { largeur: 100 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cchcleintref1', {
                resourceParams: {
                    eldnumseq: 1
                }
            }),
            new FormulaireItem('cchcleintref2', {
                resourceParams: {
                    eldnumseq: 2
                }
            }),
            new FormulaireItem('cchcleintref3', {
                resourceParams: {
                    eldnumseq: 3
                }
            })
        ], { largeurDefaut: 33 })
    };

    // Encaissements
    vm.cesMultiOccurrenceOptions = {
        bloc: 'ces',
        fonctions: {
            edition: (rowData: any) => (rowData.flgmodces)
        },
        colonnesFixesGauche: [
            { nom: 'cesnumdoc', largeur: 130 },
            { nom: 'cesdat', largeur: 110 }
        ],
        colonnesCachees: [
            'flgmodces'
        ],
        colonnesVisibles: [
            { nom: 'etycod', largeur: 110 },
            { nom: 'etydsc', largeur: 180 },
            { nom: 'cesdsc', largeur: 240 },
            { nom: 'moecod', largeur: 110 },
            { nom: 'moedsc', largeur: 190 },
            { nom: 'cesmnt', largeur: 110 },
            { nom: 'cesnumref', largeur: 150 },
            { nom: 'chqdat', largeur: 110 },
            { nom: 'vecmaicre', largeur: 110 },
            { nom: 'vecmaicre_dsc', largeur: 180 },
            { nom: 'lencod', largeur: 110 },
            { nom: 'cesdatimpfin', largeur: 110, menu: [
                new MenuItemEcran(
                    'LBL_RECU',
                    cr2003Route.NAME,
                    (data: any) => ({
                        id: data.cescleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ] }
        ],
        formulaire: new Formulaire([
            new FormulaireItemDivider('LBL_AUT_INS'), 
            new FormulaireItem('trmcleint', { largeur: 50, modifAllow: false }),
            new FormulaireItemDivider('LBL_ENC'), 
            new FormulaireItem('etycleint', { modifAllow: false }),
            new FormulaireItem('moecleint', { modifAllow: false }),
            new FormulaireItem('flgdat', { hidden: true, modifAllow: false }),
            new FormulaireItem('flgmaicre', { hidden: true, modifAllow: false }),
            new FormulaireItem('flgnum', { hidden: true, modifAllow: false }),
            new FormulaireItem('cesdat', { hidden: true, readonly: true, default: dateFilter(new Date(), 'yyyy-MM-ddTHH:mm:ss') }),
            new FormulaireItem('cesmnt', { readonly: (data: any) => data.flgmodcesmnt }),
            new FormulaireItem('cesdsc', { largeur: 100 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_RES_SUP', {
                hidden: (data: any) => {
                    return !data.flgnum && !data.flgmaicre && !data.flgdat;
                }
            }),
            new FormulaireItem('cesnumref', {
                hidden: (data: any) => {
                    return !data.flgnum;
                }
            }),
            new FormulaireItem('vecmaicre', {
                hidden: (data: any) => {
                    return !data.flgmaicre;
                }
            }),
            new FormulaireItem('chqdat', {
                hidden: (data: any) => {
                    return !data.flgdat;
                }, default: dateFilter(new Date(), 'yyyy-MM-ddTHH:mm:ss')
            })
        ], { largeurDefaut: 25 })
    };

    // Adresses
    vm.cdsMultiOccurrenceOptions = {
        bloc: 'cds',
        colonnesFixesGauche: [
            { nom: 'vaecdstyp_dsc', largeur: 170 }
        ],
        colonnesVisibles: [
            { nom: 'cdslig1', largeur: 180 },
            { nom: 'cdslig2', largeur: 180 },
            { nom: 'cdscodpos', largeur: 130 },
            { nom: 'cdsmun', largeur: 190 },
            { nom: 'cdsprv', largeur: 190 },
            { nom: 'cdspay', largeur: 190 },
            { nom: 'cdsdatdeb', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaecdstyp'),
            new FormulaireItem('cdslig1'),
            new FormulaireItem('cdslig2'),
            new FormulaireItem('cdscodpos'),
            new FormulaireItem('cdsmun'),
            new FormulaireItem('cdsprv'),
            new FormulaireItem('cdspay'),
            new FormulaireItem('cdsdatdeb')
        ], { largeurDefaut: 33 })
    };

    // Téléphones
    vm.cltMultiOccurrenceOptions = {
        bloc: 'clt',
        colonnesVisibles: [
            { nom: 'vaeclttyp', largeur: 110 },
            { nom: 'cltindreg', largeur: 130 },
            { nom: 'clttel', largeur: 130 },
            { nom: 'cltpos', largeur: 110 },
            { nom: 'cltindint', largeur: 110 },
            { nom: 'cltdatdeb', largeur: 110 },
            { nom: 'cltdatfin', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeclttyp'),
            new FormulaireItem('cltindreg'),
            new FormulaireItem('clttel'),
            new FormulaireItem('cltpos'),
            new FormulaireItem('cltindint'),
            new FormulaireItem('cltdatdeb'),
            new FormulaireItem('cltdatfin')
        ], { largeurDefaut: 20 })
    };

    // Messages
    vm.cdmMultiOccurrenceOptions = {
        bloc: 'cdm',
        colonnesFixesGauche: [
            { nom: 'flgcdmstu', largeur: 110 },
            { nom: 'msacod', largeur: 110 }
        ],
        colonnesVisibles: [
            { nom: 'msadsc', largeur: 190 },            
            { nom: 'vecmsatyp', largeur: 150 },
            { nom: 'vecmsasev', largeur: 150 },
            { nom: 'vaemsaeff', largeur: 150 },
            { nom: 'cdmdatdeb', largeur: 120 },
            { nom: 'cdmdatfin', largeur: 110 },
            { nom: 'usrnom', largeur: 190 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('msacleint', { modifAllow: false, largeur: 75 }),
            new FormulaireItem('cdmdatdeb', { modifAllow: false, required: true, default: exDateFilter(new Date()) }),
            new FormulaireItem('cdmdatfin'),
            new FormulaireItem('usrcleint', { readonly: true, default: profil.preferences.usrcleint }),
        ], { largeurDefaut: 26 })
    };

    // Ententes
    vm.nndMultiOccurrenceOptions = {
        bloc: 'nnd',
        colonnesVisibles: [
            {
                nom: 'ennnumdoc', largeur: 120,
                menu: [
                    new MenuItemForage(
                        '',
                        cr0041Route.NAME,
                        (data: any) => {
                            return {
                                id: data.doccleint
                            };
                        },
                        {
                            disabled: (data: any) => {
                                return !data.ennnumdoc;
                            }
                        }
                    )
                ]
            },
            { nom: 'etddsc', largeur: 140 },
            { nom: 'enndat', largeur: 110 },
            { nom: 'enncmt', largeur: 280 }
        ]
    };

    // Autorisations
    vm.ainMultiOccurrenceOptions = {
        bloc: 'ain',
        colonnesVisibles: [
            { nom: 'trmcod', largeur: 130 },
            { nom: 'trmdsc', largeur: 180 },
            { nom: 'aindat', largeur: 110 },
            { nom: 'ainflgpas', largeur: 110 },
            { nom: 'aindatpas', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('trmcleint'),
            new FormulaireItem('aindat', { readonly: true, default: exDateFilter(new Date()) })
        ], { largeurDefaut: 33 })
    };

    // Historique des transactions
    vm.somMultiOccurrenceOptions = {
        bloc: 'som',
        colonnesVisibles: [
            { nom: 'dat', largeur: 110 },
            { nom: 'chgcod', largeur: 120 },
            { nom: 'detail', largeur: 170 },
            { nom: 'mnt', largeur: 100 },
            { nom: 'trmcod', largeur: 180 },
            { nom: 'moecod', largeur: 180 },
            { nom: 'chqdat', largeur: 140 },
            { nom: 'dsc', largeur: 180 },
            { nom: 'econumdoc', largeur: 130 },
            { nom: 'ennnumdoc', largeur: 180 }
        ]
    };

    vm.msaMultiOccurrenceOptions = {
        bloc: 'msa',
        colonnesVisibles: [
            { nom: 'msacod', largeur: 100 },
            { nom: 'msadsc', largeur: 100 },
            { nom: 'cdmdatdeb', largeur: 100 },
            { nom: 'msatyp', largeur: 100 },
            { nom: 'msaeff', largeur: 100 }
        ]
    };

    // Sommaire par trimestre
    // Sommaire
    vm.sltMultiOccurrenceOptions = {
        bloc: 'slt',
        colonnesVisibles: [
            { nom: 'trmcod', largeur: 100 },
            { nom: 'trmdsc', largeur: 200 },
            { nom: 'sltmntcch', largeur: 100 },
            { nom: 'sltmntces', largeur: 100 },
            { nom: 'sltsld', largeur: 100 }
        ]
    };

    vm.msaMultiOccurrenceOptions = {
        bloc: 'msa',
        colonnesVisibles: [
            { nom: 'msacod', largeur: 100 },
            { nom: 'msadsc', largeur: 100 },
            { nom: 'cdmdatdeb', largeur: 100 },
            { nom: 'msatyp', largeur: 100 },
            { nom: 'msaeff', largeur: 100 }
        ]
    };

    // Relevés de compte
    vm.ecoMultiOccurrenceOptions = {
        bloc: 'eco',
        colonnesVisibles: [
            { nom: 'ecodat', largeur: 110 },
            { nom: 'econumdoc', largeur: 140 },
            { nom: 'ecomnt', largeur: 150 },
            { nom: 'ecodatech', largeur: 140 }
        ]
    };


}
