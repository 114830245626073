import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaire } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";

interface IPagePf0024 {
    formulaireSld: IFormulaire;
    urgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    strMultiOccurrenceOptions: IMultiOccurrenceOptions;
    nirMultiOccurrenceOptions: IMultiOccurrenceOptions;
    temMultiOccurrenceOptions: IMultiOccurrenceOptions;
    unoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    greMultiOccurrenceOptions: IMultiOccurrenceOptions;
    litMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eplMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceOptions: IMonoOccurrenceOptions
    monoOccurrence: IMonoOccurrence
}
/* @ngInject */
export default function Pf0024Controller(Formulaire: IFormulaireClass, $stateParams: any,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePf0024 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_COND', [
            new FormulaireItem('rdmnumseq', { largeur: 10 }),
            new FormulaireItem('vaerdmcon', { largeur: 65 }),
            new FormulaireItem('suacleint', { hidden: true, default: $stateParams.suacleint })
        ])
    }

    vm.urgMultiOccurrenceOptions = {
        bloc: 'urg',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleinturg', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 180 },
            { nom: 'urgdsc', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleinturg', { largeur: 75, required: true })
        ])
    };

    vm.eplMultiOccurrenceOptions = {
        bloc: 'epl',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleintepl', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 180 },
            { nom: 'eplnomprn', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleintepl', { largeur: 75, required: true })
        ])
    };

    vm.strMultiOccurrenceOptions = {
        bloc: 'str',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleintstr', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'strcod', largeur: 160 },
            { nom: 'strdsc', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleintstr', { largeur: 75, required: true })
        ])
    };

    vm.nirMultiOccurrenceOptions = {
        bloc: 'nir',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleintnir', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'nirniv', largeur: 160 },
            { nom: 'nirdscabr', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleintnir', { largeur: 75, required: true })
        ])
    };

    vm.temMultiOccurrenceOptions = {
        bloc: 'tem',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleinttem', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'temcod', largeur: 160 },
            { nom: 'temdsc', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleinttem', { largeur: 75, required: true })
        ])
    };

    vm.unoMultiOccurrenceOptions = {
        bloc: 'uno',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleintuno', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'unocod', largeur: 160 },
            { nom: 'unodscabr', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleintuno', { largeur: 75, required: true })
        ])
    };

    vm.greMultiOccurrenceOptions = {
        bloc: 'gre',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleintgre', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'grecod', largeur: 160 },
            { nom: 'gredsc', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleintgre', { largeur: 75, required: true })
        ])
    };

    vm.litMultiOccurrenceOptions = {
        bloc: 'lit',
        editionRapideActive: true,
        fonctions: {
            pagination: true,
            editionRapide: true
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesEditionRapide: [
            { nom: 'vrdcleintlit', largeur: 700 }
        ],
        colonnesVisibles: [
            { nom: 'litcod', largeur: 160 },
            { nom: 'litdsc', largeur: 393 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vrdcleintlit', { largeur: 75, required: true })
        ])
    };

    vm.formulaireSld = new Formulaire('LBL_BLC_SLD', [
        new FormulaireItem('vrdcleintsld', { largeur: 50 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('vaevrdope', { largeur: 10 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('vrdvalnum', { largeur: 10 })
    ]);
}
