import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateService } from 'angular-ui-router';

interface IPageCr0043 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function Cr0043Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    dateFilter: IFilterDate,
    accueilRoute: IRoute,
    $state: IStateService,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass) {
    const vm: IPageCr0043 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('ecodatech', { required: true }),
            new FormulaireItem('max', { default: 1000, required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecodat', { required: true }),
            new FormulaireItem('intdif', { default: 5, required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('sldmin'),
            new FormulaireItem('datdif', { largeur: 30, required: true, default: ecranDetails.valeurs.dathre }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('sldmax', { hidden: ecranDetails.valeurs.flgsldmax }),
            new FormulaireItemSautDeColonne({ hidden: !ecranDetails.valeurs.flgsldmax }),
            new FormulaireItemMenuItem(new MenuItemAction(
                'G_LBL_BTN_OK',
                'BOUOK',
                () => ({}),
                {
                    iconButton: false,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: (data: any) => {
                        $state.transitionTo(accueilRoute.NAME, { verifierChangements: false }, {
                            location: 'replace'
                        })
                    }
                }
            ))
        ], {
            largeurDefaut: 25
        }),
        fonctions: {
            boutonEnregistrerHaut: false
        }
    };

}
