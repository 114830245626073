import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAc0065 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Ac0065Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    ac0062Route: IRoute,
    ac0005Route: IRoute,
    ac0064Route: IRoute,
    ac0066Route: IRoute
) {
    const vm: IPageAc0065 = this;

    const lcopFou = new MenuItemEcran(
        'LBL_BTN_ACTION',
        ac0062Route.NAME,
        (rowData: any) => ({
            id: rowData.aflcleint,
            foucleint: rowData.foucleint
        }),
        {
            icon: 'launch',
            fonction: 'BOUAFL',
            iconButton: true,
            onClose: () => vm.multiOccurrence.fetchDataList()
        }
    );

    const forageEtc = new MenuItemForage(
        'LBL_BTN_FORAGE_ETC',
        ac0005Route.NAME,
        (rowData: any) => ({
            id: rowData.etccleint
        }),
        {
            icon: 'exit_to_app',
            fonction: 'BOUETC',
            iconButton: true,
            hidden: (rowData: any) =>!(rowData.etccleint)
        }
    );

    const forageAel = new MenuItemEcran(
        'LBL_BTN_ACTION',
        ac0064Route.NAME,
        (rowData: any) => ({
            id: rowData.aelcleint,
            etccleint: rowData.etccleint
        }),
        {
            icon: 'launch',
            fonction: 'BOUAEL',
            iconButton: true,
            onClose: () => vm.multiOccurrence.fetchDataList(),
            hidden: (rowData: any) =>!(rowData.etccleint)
        }
    );

    const forageAal = new MenuItemEcran(
        'LBL_BTN_ACTION',
        ac0066Route.NAME,
        (rowData: any) => ({
            id: rowData.aalcleint,
            daccleint: rowData.daccleint
        }),
        {
            icon: 'launch',
            fonction: 'BOUAAL',
            iconButton: true,
            onClose: () => vm.multiOccurrence.fetchDataList(),
            hidden: (rowData: any) => ( !(rowData.daccleint) || !(rowData.aflcleint) )
        }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datdebcri', { required: true }),
            new FormulaireItem('datfincri', { required: true }),
            new FormulaireItem('vaesysdevcodcri'),
            new FormulaireItem('mntdebcri'),
            new FormulaireItem('mntfincri'),
            new FormulaireItem('foucleintcri'),
            new FormulaireItem('fouflgstucri', { default: 2 }),
            new FormulaireItem('aflflgactcri', { default: 2 }),
            new FormulaireItem('aelflglcocri', { default: 2 }),
            new FormulaireItem('aalflglcocri', { default: 2 })
        ]),        
        colonnesFixesGauche: [
            { nom: 'foucod', largeur: 120 },
            { nom: 'flglcopfou', largeur: 80 },
            { nom: 'aflflgact', largeur: 80, menu: [lcopFou] },
            { nom: 'sysboncmd', largeur: 120 },
            { nom: 'dacseqave', largeur: 120 },
            { nom: 'dacdsc', largeur: 300 }
        ],
        colonnesVisibles: [
            { nom: 'etcnumdoc', largeur: 120, menu: [forageEtc] },
            { nom: 'flglcopetc', largeur: 120, menu: [forageAel] },
            { nom: 'flglcopdac', largeur: 120, menu: [forageAal] },
            { nom: 'founom', largeur: 300 },
            { nom: 'founomabr', largeur: 200 },
            { nom: 'fouflgstu', largeur: 100 },
            { nom: 'datcde', largeur: 126 },
            { nom: 'nibcod', largeur: 120 },
            { nom: 'dacmnt', largeur: 120 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'mnt', largeur: 130 },
            { nom: 'flgren', largeur: 130 },
            { nom: 'mntpro', largeur: 130 },
            { nom: 'molcod', largeur: 120 },
            { nom: 'vecmotoct', largeur: 120 },
            { nom: 'vecchofou', largeur: 120 },
            { nom: 'vecregenc', largeur: 120 },
            { nom: 'vecattarq', largeur: 120 },
            { nom: 'seao', largeur: 120 },
            { nom: 'datseao', largeur: 126 }
        ],
        colonnesCachees: ['foucleint', 'aflcleint', 'etccleint', 'aelcleint', 'aalcleint']
    };
}
