import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageRe2002 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireRto: IFormulaire,
    formulaire: IFormulaire;
    tpbMultiOccurrence: IMultiOccurrence;
    tpbMultiOccurrenceOptions: IMultiOccurrenceOptions;        
}

/* @ngInject */
export default function Re2002Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageRe2002 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                supprime : () => vm.monoOccurrence.data.vaesysprvret != 'PROD' && vm.monoOccurrence.data.vaertotyp != '02',
                afficherMenusDansEntete: true
            }
        };
        vm.formulaire = new Formulaire('', [
            new FormulaireItem('rtocleint', {largeur: 60, readonly: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tagcleint', {largeur: 60, readonly: true}),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('vaesyssldbor', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('tpmflgborinc', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'}),            
            new FormulaireItem('tpmflgboraff', {largeur: 20, readonly: (data) => data.vaertotyp === '02' || data.vaesysprvret === 'PROD'})

        ]);

       
         // Onglets Borne
         vm.tpbMultiOccurrenceOptions = {
            bloc: 'tpb',
            fonctions: {
                supprime : () => vm.monoOccurrence.data.vaesysprvret != 'PROD' && vm.monoOccurrence.data.vaertotyp != '02',
                importation: true
            },
            colonnesVisibles: [
                {nom: 'tpbborinf', largeur: 100},
                {nom: 'tpbtau', largeur: 120}
            ],
            colonnesCachees: [
                'vaertotyp',
                'vaesysprvret'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tpbborinf', {largeur: 10, readonly: () => vm.monoOccurrence.data.vaertotyp == '02' || vm.monoOccurrence.data.vaesysprvret === 'PROD'}),
                new FormulaireItem('tpbtau'   , {largeur: 41, readonly: () => vm.monoOccurrence.data.vaertotyp == '02' || vm.monoOccurrence.data.vaesysprvret === 'PROD'})
            ])
        }
      

    }
}
