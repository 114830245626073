import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IData} from '../../../../../core/services/data-linker.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRR0140 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0140Controller(Operateur: IOperateurService,
                                         rr0004Route: IRoute,
                                         rr0006Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageRR0140 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: (data: IData) => getForage(data),
        navigateParams: (rowData: any) => {
            return {
                id: rowData.trncleint
            };
        },
        fonctions: {
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datact', {
                required: false
            })
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'ciecod', largeur: 110},
            {nom: 'cienomabr', largeur: 210},
            {nom: 'aftdatdeb', largeur: 100},
            {nom: 'aftdatfin', largeur: 100},
            {nom: 'egncod', largeur: 120},
            {nom: 'egndsc', largeur: 262},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'posnumdoc', largeur: 90},
            {nom: 'postitabr', largeur: 262},
            {nom: 'aftcod', largeur: 100},
            {nom: 'tafcod', largeur: 90},
            {nom: 'tafdsc', largeur: 262},
            {nom: 'aftflgpri', largeur: 72},
            {nom: 'aftflgttu', largeur: 65},
            {nom: 'tafflgprb', largeur: 72},
            {nom: 'tafflgaft', largeur: 76},
            {nom: 'tafflgaccanc', largeur: 75},
            {nom: 'aftdatdebadm', largeur: 130},
            {nom: 'aftdatfinadm', largeur: 130},
            {nom: 'aftflgpro', largeur: 65},
            {nom: 'aftflgfincon', largeur: 65},
            {nom: 'aftdatfinpai', largeur: 110},
            {nom: 'poscodsup', largeur: 100},
            {nom: 'postitabrsup', largeur: 262},
            {nom: 'nomsup', largeur: 262},
            {nom: 'lemcod', largeur: 89},
            {nom: 'ugncod', largeur: 130},
            {nom: 'ugndsc', largeur: 262},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdsc', largeur: 262},
            {nom: 'sgncod', largeur: 130},
            {nom: 'sgndsc', largeur: 262},
            {nom: 'strcod', largeur: 89},
            {nom: 'strdsc', largeur: 262},
            {nom: 'aftdsc', largeur: 262},
            {nom: 'unocodrh', largeur: 110},
            {nom: 'unodscabrrh', largeur: 262},
            {nom: 'unocodvac', largeur: 110},
            {nom: 'unodscabrvac', largeur: 262},
            {nom: 'litcod', largeur: 89},
            {nom: 'litdsc', largeur: 262},
            {nom: 'grecod', largeur: 89},
            {nom: 'gredsc', largeur: 262},
            {nom: 'aftpostitfer', largeur: 262},
            {nom: 'vacvalcle', largeur: 100},
            {nom: 'vacdsc', largeur: 262},
            {nom: 'aftcom', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ],
        colonnesCachees: [
            'trncleint',
            'tsecleintori',
            'tytcleint'
        ]
    };

    function getForage(data: IData) {
        if (data.tsecleintori) {
            return rr0006Route.NAME;
        } else if ([4,5,73].includes(data.tytcleint)) {
            return rr0006Route.NAME;
        } else {
            return rr0004Route.NAME;
        }
    }
}
