import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageGS2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs2000Controller(gs0047Route: IRoute) {
    const vm: IPageGS2000 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0047Route.NAME,
        colonnesVisibles: [
            { nom: 'ecrcod', largeur: 100 },
            { nom: 'ecrdsc', largeur: 200 }
        ]
    }
}
