import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCb2003 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Cb2003Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    mc0099Route: IRoute,
) {
    const vm: IPageCb2003 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('typappcri', {
                    default: $stateParams.typapp,
                    hidden: true
                }),
                new FormulaireItem('typreccri', {
                    default: $stateParams.typrec,
                    hidden: true
                }),
                new FormulaireItem('demcleintcri', {
                    default: $stateParams.demcleint,
                    disabled: $stateParams.demcleint
                }),
                new FormulaireItem('poscleintcri', {
                    default: $stateParams.poscleint,
                    disabled: $stateParams.poscleint
                }),
                new FormulaireItem('prucleintcri', {
                    default: $stateParams.prucleint,
                    disabled: $stateParams.prucleint
                }),
                new FormulaireItem('prbcleintcri', {
                    default: $stateParams.prbcleint,
                    disabled: $stateParams.prbcleint
                }),
                new FormulaireItem('prvcleintcri', {
                    default: $stateParams.prvcleint,
                    disabled: $stateParams.prvcleint
                }),
                new FormulaireItem('el1cleintcri', {
                    default: $stateParams.el1cleint,
                    disabled: true
                }),
                new FormulaireItem('vaesysnatctbcri', {
                    default: $stateParams.vaesysnatctb,
                    disabled: $stateParams.vaesysnatctb
                }),
                new FormulaireItem('tymcleintcri', {
                    default: $stateParams.tymcleint,
                    disabled: $stateParams.tymcleint
                }),
                new FormulaireItem('tydcleintcri', {
                    default: $stateParams.tydcleint,
                    disabled: $stateParams.tydcleint
                }),
                new FormulaireItem('pecanncri', {
                    default: $stateParams.pecann,
                    disabled: $stateParams.pecann
                }),
                new FormulaireItem('peccleintdebcri', {
                    default: $stateParams.peccleintdeb,
                    hidden: true
                }),
                new FormulaireItem('peccleintfin', {
                    default: $stateParams.peccleintfin,
                    hidden: true
                })
            ], {largeurDefaut: 33}
        ),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_INFO_FINANC',
                (rowData: any) => getForageItem(rowData),
                (rowData: any) => getParamItem(rowData),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUOUV'
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 180 },
            { nom: 'docaffnumdoc', largeur: 130 },
            { nom: 'docaffnumdocext', largeur: 130 },
            { nom: 'docrefcod', largeur: 120 },
            { nom: 'docrefnom', largeur: 200 },
            { nom: 'docmntsum', largeur: 126 },
            { nom: 'docaffmnt', largeur: 126 },
            { nom: 'docaffdsc', largeur: 250 },
            { nom: 'docaffdat', largeur: 120 },
            { nom: 'docreqcod', largeur: 130 },
            { nom: 'docreqnom', largeur: 180 },
            { nom: 'docaffdatdeb', largeur: 126 },
            { nom: 'docaffdatfin', largeur: 126 }
        ],
        colonnesCachees: ['vaesysnatctb', 'ecrcod', 'docaffcleint', 'doccleintgrh', 'doccleintfin', 'tydcleint']
    };

    function getForageItem(rowData: any): string {
        if (rowData.ecrcod === 'MC0099-00-2') {
            return mc0099Route.NAME;
        } 
    }
    function getParamItem(rowData: any) {
        if (rowData.ecrcod === 'MC0099-00-2') {
            return { id: rowData.doccleintgrh, indfor: 1 };
        }
    }

}
