import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPagePb0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Pb0012Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite
                                         ) {
    const vm: IPagePb0012 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cobcod', largeur: 100},
            {nom: 'cobdsc', largeur: 200},
            {nom: 'cobcom', largeur: 200},
            {nom: 'vaecobtyp_dsc', largeur: 200}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cobcod'),
            new FormulaireItem('cobdsc'),
            new FormulaireItem('cobcom'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaecobtyp')
        ], {largeurDefaut: 33.33})
    };
}
