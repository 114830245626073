import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import IStateService = angular.ui.IStateService;
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRC0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IRc2000Params {
    menuId: string;
}

/* @ngInject */
export default function Rc0004Controller(Menu: IMenuClass,
    rc0001Route: IRoute,
    $state: IStateService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IRc2000Params,
    MenuItemAction: IMenuItemActionClass,
    rc2000Route: IRoute) {
    const vm: IPageRC0004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemAction(
                'G_LBL_BTN_NOUVEAU',
                'SIMULER_PROCEDURE',
                () => ({}),
                {
                    fonction: 'BOUNOU',
                    successAction: (data: any) => {
                        $state.go(rc2000Route.NAME, { menuId: $stateParams.menuId });
                    }
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('flgip', { default: 1 }),
            new FormulaireItem('flgfr', { default: 1 }),           
            new FormulaireItem('flgan', { default: 1 }) 
        ]),
        navigatePage: rc0001Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.doccleint
            };
        },
        colonnesVisibles: [
            { nom: 'txmnumdoc', largeur: 140 },
            { nom: 'txmnumdocseq', largeur: 100 },
            { nom: 'tydcod', largeur: 120 },
            { nom: 'docaffnumdoc01', largeur: 140 },
            { nom: 'tydcodref', largeur: 120 },
            { nom: 'tyddscref', largeur: 180 },
            { nom: 'docaffsysboncmd01', largeur: 150 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 180 },
            { nom: 'txmnumref', largeur: 120 },
            { nom: 'txmdat', largeur: 110 },
            { nom: 'txmmnt', largeur: 120 },
            { nom: 'lipcod', largeur: 120 },
            { nom: 'vecdsc', largeur: 180 },
            { nom: 'txmdsc', largeur: 180 },
            { nom: 'etddsc', largeur: 180 },
            { nom: 'peccod', largeur: 120 },
            { nom: 'intcodreq', largeur: 120 },
            { nom: 'intnomreq', largeur: 180 }
        ]
    };
}
