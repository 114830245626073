import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";


interface IPageRe0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re0003Controller(Menu: IMenuClass,
                                         re0004Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe0003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },     
        //navigatePage: ???Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        navigatePage: re0004Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(re0004Route.NAME)
        ]), 
        colonnesVisibles: [
            {nom: 'rtocod', largeur: 120},
            {nom: 'rtodsc', largeur: 180},
            {nom: 'geccod', largeur: 120},
            {nom: 'gecdsc', largeur: 180},
            {nom: 'cvtcod', largeur: 120},
            {nom: 'esadatvig', largeur: 126},
            {nom: 'vaesysprvret_dsc', largeur: 140},
            {nom: 'esaflgina', largeur: 100}
        ]
    };
}
