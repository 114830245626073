import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0174 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0174Controller(mc0025Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0174 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mc0025Route.NAME,
        navigateParams: (rowData: any) => ({
            id: rowData.intcleint
        }),
        colonnesVisibles: [
            {nom: 'intcod', largeur: 150},
            {nom: 'intnom', largeur: 250},
            {nom: 'intflggesmanapp', largeur: 250}
        ]
    };
}
