import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IStateParamsService} from 'angular-ui-router';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageRR0069 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0069Controller(rr0070Route: IRoute,
                                         rr0143Route: IRoute,
                                         $stateParams: IStateParamsService,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0069 = this;

    const forageDar = new MenuItemEcran(
        'LBL_FOR_DAR',
        rr0143Route.NAME,
        (rowData: any) => (
            {
                id: rowData.erccleint,
                demcleint: rowData.demcleint,
                rcecleint: rowData.rcecleint
            }
        ),
        {
            icon: 'launch_app',
            iconButton: true
        }
    );

    vm.multiOccurrenceOptions = {

        navigatePage: rr0070Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.erccleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0070Route.NAME, () => {
                return {
                    demcleint: $stateParams.employe
                };
            })
        ]),
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datact')
        ]),
        colonnesCachees: ['rcecleint'],
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 280, hidden: $stateParams.employe},
        ],
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 100},
            {nom: 'rcedsc', largeur: 250},
            {nom: 'flgrcedec', largeur: 120},
            {nom: 'darmntarr', largeur: 150, menu: [forageDar]},
            {nom: 'ercnumref', largeur: 150},
            {nom: 'unpcod', largeur: 160},
            {nom: 'unpdsc', largeur: 250}
        ]
    };
}
