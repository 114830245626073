import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';

interface IPageRe0002 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
    rtcMultiOccurrence: IMultiOccurrence;
    rtcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    risMultiOccurrence: IMultiOccurrence;
    risMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rtiMultiOccurrence: IMultiOccurrence;
    rtiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rinMultiOccurrence: IMultiOccurrence;
    rinMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rhaMultiOccurrence: IMultiOccurrence;
    rhaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rmsMultiOccurrence: IMultiOccurrence;
    rmsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rdeMultiOccurrence: IMultiOccurrence;
    rdeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Re0002Controller(re2000Route: IRoute,
                                         re2001Route: IRoute,
                                         exLibelleFilter: IFilterLibelle,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {

    const vm: IPageRe0002 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                afficherMenusDansEntete: true
            },
            menus: [  
                new MenuItemAction(
                    'LBL_BTN_EXT',
                    'BOUEXT',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        }
                    }
                ),
                new MenuItemAction(
                    'LBL_BTN_MAJ',
                    'BOUMAJ',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_FORMULAIRE_INDEXATION',
                            lblConfirm: 'G_LBL_BTN_OK',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('rad_salaire', {default: 0, largeur: 100}),
                                new FormulaireItem('rad_ech', {default: 0, largeur: 100}),
                                new FormulaireItem('new_date', {largeur: 50}),
                                new FormulaireItem('old_date', {largeur: 50}),
                                new FormulaireItem('pct_aug', {largeur: 100}),
                                new FormulaireItem('nbrdec_hor', {default: '2', largeur: 100}),
                                new FormulaireItem('nbrdec_ann', {default: '0', largeur: 100}),
                                new FormulaireItem('tagsup1', {largeur: 100}),
                                new FormulaireItem('pct_aug_2', {largeur: 100}),
                                new FormulaireItem('tagsup2', {largeur: 100})
                            ], { largeurDefaut: 40 })
                        }
                    }
                ),
                new MenuItemAction(
                    'LBL_BTN_CAL',
                    'BOUCAL',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_FORMULAIRE_CALCUL',
                            lblConfirm: exLibelleFilter('LBL_CALCULER', vm.libelles),
                            largeurFormulaire: 25,
                            formulaire: new Formulaire([
                                new FormulaireItem('demcleint', {largeur: 100})                               
                            ], { largeurDefaut: 40 })
                        }
                    }
                ),
                new MenuItemAction(
                    'LBL_BTN_INT',
                    'BOUINT',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        }
                    }
                ),
                new MenuItemAction(
                    'LBL_BTN_GEN',
                    'BOUGEN',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        }
                    }
                ),
                new MenuItemAction(
                    'LBL_BTN_ANN',
                    'BOUANN',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_FORMULAIRE_QUESTION',
                            lblConfirm: 'G_LBL_OUI',
                            largeurFormulaire: 25,
                            formulaire: new Formulaire([
                                new FormulaireItem('question', {titre: 'Ce traitement annulera la rétro. Voulez-vous continuer ?', largeur: 100, readonly: true})                               
                            ], { largeurDefaut: 40 })
                        }
                    }
                )
            ],
        };

        vm.formulaire = new Formulaire('LBL_ETAT_RTO', [
            new FormulaireItem('rtocod', {largeur: 20}),
            new FormulaireItem('rtodsc', {largeur: 40}),
            new FormulaireItem('vaertotyp', {largeur: 20}),            
            new FormulaireItem('vaertostu', {largeur: 20, default: 'INI'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rtodatdeb', {largeur: 20}),
            new FormulaireItem('rtodatfin', {largeur: 20}),            
            new FormulaireItem('rtodatpai', {largeur: 20}),
            new FormulaireItem('ttccleint', {largeur: 20}),            
            new FormulaireItem('tpfcleint', {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rtocom', {largeur: 100})
        ]);

        // Onglets Convention
        vm.rtcMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'cvtcod', largeur: 150},
                {nom: 'cvtdsc', largeur: 400}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cvtcleint', {largeur: 60})
            ])
        }

        // Onglets Supplément salarial
        vm.risMultiOccurrenceOptions = {
            bloc: 'ris',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'tagcod', largeur: 100},
                {nom: 'tagdscabr', largeur: 120},
                {nom: 'vaerisimp_dsc', largeur: 80}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint', {largeur: 40}),
                new FormulaireItem('vaerisimp', {largeur: 40})
            ])
        }

        // Onglets Intervalle paiement
        vm.rtiMultiOccurrenceOptions = {
            bloc: 'rti',
            fonctions: {
                importation: true
            },
            navigatePage: re2001Route.NAME,
            navigateParams: (rowData: any) => {
            return {
                rticleint: rowData.rticleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(re2001Route.NAME)
        ]),
            colonnesVisibles: [
                {nom: 'rtidatdeb', largeur: 100},
                {nom: 'rtidatfin', largeur: 100},
                {nom: 'tagcodret', largeur: 200},
                {nom: 'tagdscabrret', largeur: 200},
                {nom: 'rtiflgarr', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rtidatdeb', {largeur: 10}),
                new FormulaireItem('rtidatfin', {largeur: 10}),
                new FormulaireItem('tagcleintret', {largeur: 40}),
                new FormulaireItem('rtiflgarr', {largeur: 10, default: 1})
            ])
        }

        // Onglets Interet
        vm.rinMultiOccurrenceOptions = {
            bloc: 'rin',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'rindatdeb', largeur: 100},
                {nom: 'rindatfin', largeur: 120},
                {nom: 'vaerintyp_dsc', largeur: 120},
                {nom: 'tagcod', largeur: 100},
                {nom: 'tagdscabr', largeur: 200},
                {nom: 'rinprcint', largeur: 140}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rindatdeb', {largeur: 20}),
                new FormulaireItem('rindatfin', {largeur: 20}),
                new FormulaireItem('vaerintyp', {largeur: 20}),
                new FormulaireItem('tagcleint', {largeur: 30}),
                new FormulaireItem('rinprcint', {largeur: 10})
            ])
        }

        // Onglets Historique
        vm.rhaMultiOccurrenceOptions = {
            bloc: 'rha',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'datcre', largeur: 140},
                {nom: 'usrcod', largeur: 120},
                {nom: 'usrnomcre', largeur: 200},
                {nom: 'vaerhaact_dsc', largeur: 200}
            ]
        }

        // Onglets Message
        vm.rmsMultiOccurrenceOptions = {
            bloc: 'rms',
            fonctions: {
                importation: true
            },
            navigatePage: re2000Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    rtocleint: rowData.rtocleint,
                    demcleint: rowData.demcleint
                };
            },
            colonnesVisibles: [
                {nom: 'eplnummat', largeur: 100},
                {nom: 'eplnomprn', largeur: 120},
                {nom: 'vaermstyp_dsc', largeur: 80}
            ]
        }

        // Onglets Employés
        vm.rdeMultiOccurrenceOptions = {
            bloc: 'rde',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'eplnummat', largeur: 150},
                {nom: 'eplnomprn', largeur: 400}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('demcleint', {largeur: 60})
            ])
        }
    }
}
