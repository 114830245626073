import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcran, IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageMC0130 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    formulaireTvi: IFormulaire;
    formulaireInfoAff: IFormulaire;
    tveMultiOccurrenceOptions: IMultiOccurrenceOptions;

    menus: {[key: string]: Array<IMenuItem>};
    // Forage
    foragePerception: IMenuItem;
    foragePerceptionItem: IMenuItem;
    forageCompteRecevoirMenuItem: IMenuItem;
    forageRetenueMenuItem: IMenuItem;

    menuItemPar : IMenuItemEcran;
}

/* @ngInject */
export default function Mc0130Controller(Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         mc0183Route: IRoute,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMC0130 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.$onInit = $onInit;

    const forageEcran = new MenuItemForage(
        'BTN_FORAGE_DOC',
        (data: any) => (`secure.${data.aut__code}.ecran`),
        (data: any) => ({
            id: data.doccleint,
            ecran: data.aut__detail
        })
    );

    function $onInit() {
        // Transaction AVI
        vm.formulaireTvi = new Formulaire('LBL_BLC_TVI', [
            new FormulaireItem('aut__document', { menu: [forageEcran] }),
            new FormulaireItem('aut__type_document'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('aut__entite_externe'),
            new FormulaireItem('aut__requerant'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ttccleint'),
            new FormulaireItem('tvidat'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tvicom',{enableCount: true})

        ], {largeurDefaut: 50});

        // Carte date et montant
        vm.formulaireInfoAff = new Formulaire('LBL_MONTANTS', [
                new FormulaireItem('aut__docaffmnt01', { titre: ' ', libellesContour: { gauche: 'LBL_MONTANT' }, alignRight: true, largeur: 60}),
                new FormulaireItemSautDeColonne(),

                new FormulaireItem('tvimntadm', { titre: ' ', libellesContour: { gauche: 'LBL_MONTANT_ADM' }, alignRight: true, largeur: 60}),
                new FormulaireItemSautDeColonne(),

                new FormulaireItem('aut__solde', { titre: ' ', libellesContour: { gauche: 'LBL_SOLDE' }, alignRight: true, largeur: 60 })
            ], {largeurDefaut: 30});

        // Employé
        vm.tveMultiOccurrenceOptions = {
            bloc: 'tve',

            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'NO_LABEL',
                    mc0183Route.NAME,
                    (rowData: any) => ({
                        id: rowData.tvecleint
                    }),
                    {
                        icon: 'launch',
                        onClose() {
                            vm.tveMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),

            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'NO LABEL',
                    mc0183Route.NAME,
                    () => ({
                        tvicleint: vm.monoOccurrence.data.tvicleint
                    }),
                    {
                        onClose() {
                            vm.tveMultiOccurrence.fetchDataList();
                        },
                        disabled: () => (!vm.monoOccurrence.data.$valeurs.flgcreemp)
                    }
                )
            ]),

           colonnesCachees: [
                'tvicleint'
            ],
            colonnesVisibles: [
                {nom: 'employe', largeur: 100},
                {nom: 'tvenom', largeur: 250},
                {nom: 'tvecom', largeur: 270},
                {nom: 'tvepct', largeur: 118},
                {nom: 'tvemnt', largeur: 126}
            ]

        };
    }
}
