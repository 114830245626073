import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGs0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0030Controller(gs0127Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGs0030 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gs0127Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0127Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'elicod'              , largeur: 150},
            {nom: 'elidsc'              , largeur: 350},
            {nom: 'revlib'              , largeur: 200},
            {nom: 'vaerevval_dsc'       , largeur: 150},
            {nom: 'revlonele'           , largeur: 100},
            {nom: 'vaerevseqaut_dsc'    , largeur: 200},
            {nom: 'revmassai'           , largeur: 200},
            {nom: 'revflgfix'           , largeur: 120},
            {nom: 'revflgjus'           , largeur: 130},
            {nom: 'revbormin'           , largeur: 130},
            {nom: 'vaervitypdon_dsc'    , largeur: 130},
            {nom: 'vaervityplet_dsc'    , largeur: 100}
        ]
    };
}
