import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemEcran, IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeColonneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service";

interface IPageMr0182 extends IComponentController {
    formulaireTdm: IFormulaire;
    formulaireInd: IFormulaire;
    tduMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tduMultiOccurrence: IMultiOccurrence;
    tdtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tdtMultiOccurrence: IMultiOccurrence;
    tdgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tdgMultiOccurrence: IMultiOccurrence;
    tdvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tdvMultiOccurrence: IMultiOccurrence;
    menuItemPar: IMenuItemEcran;
    menuItemSig: IMenuItemEcran;
}

/* @ngInject */
export default function Mr0182Controller(rr0108Route: IRoute,
    rr0113Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMr0182 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Condition d'admissibilité
        vm.formulaireTdm = new Formulaire('LBL_BLC_TDM', [

            // Utile pour mes lovs TSE- Ne s'affiche pas
            new FormulaireItem('t_tytcod_epl', { default: 'TSEEPL' }),
            new FormulaireItem('t_tytcod_dem', { default: 'TSEDEM' }),
            new FormulaireItem('t_tytcod_lem', { default: 'TSELEM' }),

            new FormulaireItem('tdmcod', { largeur: 25 }),
            new FormulaireItem('tdmdsc', { largeur: 75 }),

            new FormulaireItem('vaetdmmodsai'),
            new FormulaireItem('tdmflgdal', { default: 0 }),
            new FormulaireItem('tdmflgpaicnv', { default: 0 }),
            new FormulaireItem('tdmflgdscobldec', { default: 0 }),

            new FormulaireGroupe(new Formulaire([
                new FormulaireItemDivider('LBL_GEN_FEUILLE'),
                new FormulaireItem('tpfcleint'),
                new FormulaireItem('vaetdmgenfep')
            ]), { largeur: 50 }),

            new FormulaireGroupe(new Formulaire([
                new FormulaireItemDivider('LBL_DETAIL_JOURNALIER'),
                new FormulaireItem('tdmflgdetjrn', { default: 0 }),
                new FormulaireItem('vaesysjrssem'),
            ]), { largeur: 50 }),

            new FormulaireGroupeAccordeon('LBL_GRP_AUTRES_INFOS',
                new Formulaire([
                    new FormulaireItem('tdmflglem', { default: 0 }),
                    new FormulaireItem('tdmflgpossup', { default: 0 }),
                    new FormulaireItem('tdmlibpossup', { largeur: 50 }),

                    new FormulaireItem('tdmflgaft', { default: 0 }),
                    new FormulaireItem('tdmflgsup', { default: 0 }),
                    new FormulaireItem('tdmflgesc1', { default: 0 }),
                    new FormulaireItem('tdmflgesc4', { default: 0 }),

                    new FormulaireItem('tdmflgtem', { default: 0 }),
                    new FormulaireItem('tdmflguno', { default: 0 }),
                    new FormulaireItem('tdmflgesc2', { default: 0 }),
                    new FormulaireItem('tdmflgesc5', { default: 0 }),

                    new FormulaireItemSautDeColonne({ largeur: 25 }),
                    new FormulaireItem('tdmflgintdem', { default: 0 }),
                    new FormulaireItem('tdmflgesc3', { default: 0 }),
                    new FormulaireItemSautDeColonne({ largeur: 25 }),

                    new FormulaireItemSautDeColonne({ largeur: 25 }),
                    new FormulaireItem('tdmflgintref', { default: 0 })
                ], { largeurDefaut: 25 })
            ),

            new FormulaireGroupeAccordeon('LBL_GRP_TTC',
                new Formulaire([
                    new FormulaireItem('ttccleint', { nombreLibellesDetailsCacher: 1 }),
                    new FormulaireItemSautDeLigne(),

                    new FormulaireItem('ttccleintpro', { nombreLibellesDetailsCacher: 1 }),
                    new FormulaireItem('ttccleintepl', { nombreLibellesDetailsCacher: 1 }),

                    new FormulaireItem('ttccleintdem', { nombreLibellesDetailsCacher: 1 }),
                    new FormulaireItem('ttccleintlem', { nombreLibellesDetailsCacher: 1 })
                ], { largeurDefaut: 50 })
            ),


            new FormulaireGroupeAccordeon('LBL_BLC_INF',
                new Formulaire([
                    new FormulaireItem('atscleint', { largeur: 50 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('vaesyscaptem', { hidden: (data) => !data.$valeurs.flgcaptem, largeur: 50 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireGroupe(new Formulaire([
                        new FormulaireItemDivider('LBL_REPER'),
                        new FormulaireItem('tdmflgrepetu', { default: 0 }),
                        new FormulaireItem('tdmflgadrcourepetu', { default: 0 }),
                        new FormulaireItem('tdmflgcreemp', { default: 0 }),
                        new FormulaireItem('vaetypcourepetu', { readonly: data => !data.tdmflgadrcourepetu })
                    ]), { largeur: 75 }),

                ])
            )

        ], { largeurDefaut: 33.33 });

        // Indicateur
        vm.formulaireInd = new Formulaire('LBL_BLC_IND', [
            new FormulaireItem('tdmflgact', { default: 1 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tdmflgcen', { default: 0 }),

        ], { largeurDefaut: 100 });

        // Bouton paramètre d'embauche
        vm.menuItemPar = new MenuItemEcran(
            'LBL_PARAM_PAR',
            rr0108Route.NAME,
            (data: any) => ({
                cpecleintref: data.tdmcleint,
                stamnetab: 'TDM'
            }),
            {
                icon: 'subtitles',
                iconButton: false
            }
        )

        // Bouton paramètre d'embauche
        vm.menuItemSig = new MenuItemEcran(
            'LBL_PARAM_SIG',
            rr0113Route.NAME,
            (data: any) => ({
                specleintref: data.tdmcleint,
                stamnetab: 'TDM'
            }),
            {
                icon: 'person_pin',
                iconButton: false
            }
        )

        // URG
        vm.tduMultiOccurrenceOptions = {
            bloc: 'tdu',
            colonnesVisibles: [
                { nom: 'urgcod', largeur: 140 },
                { nom: 'urgdscabr', largeur: 350 },
                { nom: 'asgcod', largeur: 150 },
                { nom: 'asgdsc', largeur: 250 },
                { nom: 'tduflgact', largeur: 140 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint', { largeur: 60 }),
                new FormulaireItem('asgcleint', { hidden: (data) => !data.$valeurs.flgcaptem, largeur: 60 }),
                new FormulaireItem('tduflgact', { largeur: 20, default: 1 })
            ])
        };

        // Titre d'emploi
        vm.tdtMultiOccurrenceOptions = {
            bloc: 'tdt',
            colonnesVisibles: [
                { nom: 'temcod', largeur: 140 },
                { nom: 'temdscabr', largeur: 350 },
                { nom: 'tdtflgact', largeur: 140 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('temcleint', { largeur: 60 }),
                new FormulaireItem('tdtflgact', { largeur: 20, default: 1 })
            ])
        };

        // TAG
        vm.tdgMultiOccurrenceOptions = {
            bloc: 'tdg',
            colonnesVisibles: [
                { nom: 'tagcod', largeur: 120 },
                { nom: 'tagdscabr', largeur: 400 },
                { nom: 'tdgflgafftau', largeur: 100 },
                { nom: 'tdgdscporemp', largeur: 400 },
                { nom: 'actcod', hidden: (data) => !data.$valeurs.flgcaptem, largeur: 120 },
                { nom: 'actdsc', hidden: (data) => !data.$valeurs.flgcaptem,largeur: 200 },
                { nom: 'tdgflgact', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint', { largeur: 40 }),
                new FormulaireItem('tdgflgafftau', { largeur: 20, default: 0 }),
                new FormulaireItem('actcleint', { hidden: (data) => !data.$valeurs.flgcaptem, largeur: 20, modifAllow: false }),
                new FormulaireItem('tdgflgact', { largeur: 20, default: 1 }),

                new FormulaireItem('tdgdscporemp', { largeur: 50, enableCount: true })

            ])
        };

        // Valeur
        vm.tdvMultiOccurrenceOptions = {
            bloc: 'tdv',
            colonnesVisibles: [
                { nom: 'urgcod', largeur: 110 },
                { nom: 'urgdscabr', largeur: 310 },
                { nom: 'tagcod', largeur: 110 },
                { nom: 'tagdscabr', largeur: 310 },
                { nom: 'tdvdatvig', largeur: 100 },
                { nom: 'tdvflgcalqte', largeur: 100, default: 0 },
                { nom: 'tdvtaufix', largeur: 110 },
                { nom: 'unccod', largeur: 100 },
                { nom: 'tdvmntfix', largeur: 120 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('t_tdmcleint', { default: () => (vm.monoOccurrence.id) }),

                new FormulaireItem('urgcleint', { largeur: 30 }),
                new FormulaireItem('tagcleint', { largeur: 30 }),
                new FormulaireItem('tdvflgcalqte', { largeur: 20 }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('tdvdatvig', { largeur: 20 }),
                new FormulaireItem('tdvtaufix', { largeur: 20 }),
                new FormulaireItem('tdvmntfix', { largeur: 20 })

            ], { largeurDefaut: 20 })
        };

    }
}
