import IThemeHues = angular.material.IThemeHues;
import {module} from 'angular';

export interface IPalette {
    NAME: string;
    HUES: IThemeHues;
}

export interface IThemeConfig {
    PRIMARY: IPalette,
    ACCENT: IPalette,
    WARN: IPalette,
    BACKGROUND: IPalette
}

export interface IThemesConfig {
    DEFAULT: IThemeConfig,
    CYAN: IThemeConfig,
    DEEP_PURPLE: IThemeConfig,
    TEAL: IThemeConfig,
    BLUE: IThemeConfig,
    GREY: IThemeConfig
}

const Themes: IThemesConfig = {
    DEFAULT: {
        PRIMARY: {
            NAME: 'blue-grey',
            HUES: {
                'default': '700',
                'hue-1': '500',
                'hue-2': 'A400',
                'hue-3': 'A100'
            }
        },
        ACCENT: {
            NAME: 'cyan',
            HUES: {
                'default': '700',
                'hue-1': '500',
                'hue-2': 'A400',
                'hue-3': 'A100'
            }
        },
        WARN: {
            NAME: 'red',
            HUES: {
                'default': '500',
                'hue-1': '300',
                'hue-2': '700',
                'hue-3': '50'
            }
        },
        BACKGROUND: {
            NAME: 'grey',
            HUES: {
                'default': 'A100',
                'hue-1': '100',
                'hue-2': '50',
                'hue-3': '300'
            }
        }
    },
    CYAN: {
        PRIMARY: {
            NAME: 'cyan',
            HUES: {
                'default': '700',
                'hue-1': '400',
                'hue-2': '600',
                'hue-3': '800'
            }
        },
        ACCENT: {
            NAME: 'deep-orange',
            HUES: {
                'default': '500',
                'hue-1': '500',
                'hue-2': '800',
                'hue-3': '200'
            }
        },
        WARN: {
            NAME: 'red',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '600',
                'hue-3': '700'
            }
        },
        BACKGROUND: {
            NAME: 'grey',
            HUES: {
                'default': 'A100',
                'hue-1': '100',
                'hue-2': '50',
                'hue-3': '300'
            }
        }
    },
    DEEP_PURPLE: {
        PRIMARY: {
            NAME: 'deep-purple',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '500',
                'hue-3': '800'
            }
        },
        ACCENT: {
            NAME: 'pink',
            HUES: {
                'default': '500',
                'hue-1': '300',
                'hue-2': '400',
                'hue-3': '100'
            }
        },
        WARN: {
            NAME: 'red',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '600',
                'hue-3': '700'
            }
        },
        BACKGROUND: {
            NAME: 'grey',
            HUES: {
                'default': 'A100',
                'hue-1': '100',
                'hue-2': '50',
                'hue-3': '300'
            }
        }
    },
    TEAL: {
        PRIMARY: {
            NAME: 'teal',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '600',
                'hue-3': '800'
            }
        },
        ACCENT: {
            NAME: 'orange',
            HUES: {
                'default': '500',
                'hue-1': '800',
                'hue-2': '900',
                'hue-3': '300'
            }
        },
        WARN: {
            NAME: 'red',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '600',
                'hue-3': '700'
            }
        },
        BACKGROUND: {
            NAME: 'grey',
            HUES: {
                'default': 'A100',
                'hue-1': '100',
                'hue-2': '50',
                'hue-3': '300'
            }
        }
    },
    BLUE: {
        PRIMARY: {
            NAME: 'blue',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '500',
                'hue-3': '700'
            }
        },
        ACCENT: {
            NAME: 'red',
            HUES: {
                'default': '500',
                'hue-1': '600',
                'hue-2': '900',
                'hue-3': '100'
            }
        },
        WARN: {
            NAME: 'red',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '600',
                'hue-3': '700'
            }
        },
        BACKGROUND: {
            NAME: 'grey',
            HUES: {
                'default': 'A100',
                'hue-1': '100',
                'hue-2': '50',
                'hue-3': '300'
            }
        }
    },
    GREY: {
        PRIMARY: {
            NAME: 'blue-grey',
            HUES: {
                'default': '900',
                'hue-1': '800',
                'hue-2': '500',
                'hue-3': '700'
            }
        },
        ACCENT: {
            NAME: 'blue',
            HUES: {
                'default': '500',
                'hue-1': '500',
                'hue-2': '700',
                'hue-3': '100'
            }
        },
        WARN: {
            NAME: 'red',
            HUES: {
                'default': '500',
                'hue-1': '400',
                'hue-2': '600',
                'hue-3': '700'
            }
        },
        BACKGROUND: {
            NAME: 'grey',
            HUES: {
                'default': 'A100',
                'hue-1': '100',
                'hue-2': '50',
                'hue-3': '300'
            }
        }
    }
};

export default module('app.constants.themes', []).constant('Themes', Themes);
