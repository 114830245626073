import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
    import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageDo2007 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireStd: IFormulaire;
    formulaireCof: IFormulaire;
    bmoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cofMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tbpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tarMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tdmbmoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tdmcofMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Do2007Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass
) {
    const vm: IPageDo2007 = this;

    vm.monoOccurrenceOptions = {
        id: ecranDetails.valeurs.zcicleint,
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire( 'LBL_BLC_BMO', [
            new FormulaireItem('ttccleintepl'),
            new FormulaireItem('ttccleintdem'),
            new FormulaireItem('ttccleintlem'),
            new FormulaireItem('ttccleintaft')
        ], {largeurDefaut: 25})
    };

    vm.formulaireStd = new Formulaire('LBL_BLC_STD', [
        new FormulaireItem( 'ttccleintbmo' ),
        new FormulaireGroupeAccordeon('LBL_IND_STD', new Formulaire([
            new FormulaireItemDivider('LBL_DIV_MOT_CRE'),
            new FormulaireItem( 'zciflgmotcreaff' ),
            new FormulaireItem( 'zciflgmotcreobl' ),
            new FormulaireItemDivider('LBL_DIV_DSC'),
            new FormulaireItem( 'zciflgdscaff' ),
            new FormulaireItem( 'zciflgdscobl' ),
            new FormulaireItemDivider('LBL_DIV_DATDEB'),
            new FormulaireItem( 'zciflgdatdebaff' ),
            new FormulaireItem( 'zciflgdatdebobl' ),
            new FormulaireItemDivider('LBL_DIV_DATFIN'),
            new FormulaireItem( 'zciflgdatfinaff' ),
            new FormulaireItem( 'zciflgdatfinobl' ),
            new FormulaireItemDivider('LBL_DIV_URG'),
            new FormulaireItem( 'zciflgurgaff' ),
            new FormulaireItem( 'zciflgurgobl' ),
            new FormulaireItemDivider('LBL_DIV_UNO'),
            new FormulaireItem( 'zciflgunoaff' ),
            new FormulaireItem( 'zciflgunoobl' ),
            new FormulaireItemDivider('LBL_DIV_GRE'),
            new FormulaireItem( 'zciflggreaff' ),
            new FormulaireItem( 'zciflggreobl' ),
            new FormulaireItemDivider('LBL_DIV_TEM'),
            new FormulaireItem( 'zciflgtemaff' ),
            new FormulaireItem( 'zciflgtemobl' ),
            new FormulaireItemDivider('LBL_DIV_SCO'),
            new FormulaireItem( 'zciflgscoaff' ),
            new FormulaireItem( 'zciflgscoobl' ),
            new FormulaireItemDivider('LBL_DIV_EXP'),
            new FormulaireItem( 'zciflgexpaff' ),
            new FormulaireItem( 'zciflgexpobl' ),
            new FormulaireItemDivider('LBL_DIV_TAC'),
            new FormulaireItem( 'zciflgtacaff' ),
            new FormulaireItem( 'zciflgtacobl' ),
            new FormulaireItemDivider('LBL_DIV_LIT'),
            new FormulaireItem( 'zciflglitaff' ),
            new FormulaireItem( 'zciflglitobl' ),
            new FormulaireItemDivider('LBL_DIV_POSSUP'),
            new FormulaireItem( 'zciflgpossupaff' ),
            new FormulaireItem( 'zciflgpossupobl' ),
            new FormulaireItemDivider('LBL_DIV_INTSUP'),
            new FormulaireItem( 'zciflgintsupaff' ),
            new FormulaireItem( 'zciflgintsupobl' ),
            new FormulaireItemDivider('LBL_DIV_MOD_SAI'),
            new FormulaireItem( 'zciflgmodsaiaff' ),
            new FormulaireItem( 'zciflgmodsaiobl' ),
            new FormulaireItemDivider('LBL_DIV_SAL'),
            new FormulaireItem( 'zciflgsalaff' ),
            new FormulaireItem( 'zciflgsalobl' ),
            new FormulaireItemDivider('LBL_DIV_NBR_CAN'),
            new FormulaireItem( 'zciflgnbrcanaff' ),
            new FormulaireItem( 'zciflgnbrcanobl' ),
            new FormulaireItemDivider('LBL_DIV_PIJ'),
            new FormulaireItem( 'zciflgpijaff' ),
            new FormulaireItem( 'zciflgpijobl' )
        ])),
        new FormulaireTemplateSlot('blocTbp'),
        new FormulaireTemplateSlot('blocTds'),
        new FormulaireTemplateSlot('blocBmo')
    ]);

    vm.formulaireCof = new Formulaire('LBL_BLC_COF', [
        new FormulaireItem( 'ttccleintcof' ),
        new FormulaireTemplateSlot('blocTar'),
        new FormulaireTemplateSlot('blocTdc'),
        new FormulaireTemplateSlot('blocCof')
    ]);
    
    vm.bmoMultiOccurrenceOptions = {
        bloc: 'bmo',
        fonctions: {
            importation: true,
            boutonDupliquer: false
        },
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 100},
            {nom: 'urgdsc', largeur: 150},
            {nom: 'temcod', largeur: 100},
            {nom: 'temdsc', largeur: 150}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('temcleint')
        ])
    };

    vm.tbpMultiOccurrenceOptions = {
        bloc: 'tbp',
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            {nom: 'tbpcod', largeur: 200},
            {nom: 'tbpdsc', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('zcvcleintref', {largeur: 100})
        ])
    };

    vm.tdmbmoMultiOccurrenceOptions = {
        bloc: 'tdmbmo',
        fonctions: { 
            boutonDupliquer: false
        },
        colonnesVisibles: [
            {nom: 'tdmcod', largeur: 200},
            {nom: 'tdmdsc', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('zcvcleintref', {largeur: 100})
        ])
    };

    vm.cofMultiOccurrenceOptions = {
        bloc: 'cof',
        fonctions: {
            importation: true,
            boutonDupliquer: false
        },
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 100},
            {nom: 'urgdsc', largeur: 150},
            {nom: 'temcod', largeur: 100},
            {nom: 'temdsc', largeur: 150}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('temcleint')
        ])
    };

    vm.tarMultiOccurrenceOptions = {
        bloc: 'tar',
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            {nom: 'tarcod', largeur: 150},
            {nom: 'tardsc', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('zcvcleintref', {largeur: 100})
        ])
    };

    vm.tdmcofMultiOccurrenceOptions = {
        bloc: 'tdmcof',
        fonctions: { 
            boutonDupliquer: false
        },
        colonnesVisibles: [
            {nom: 'tdmcod', largeur: 200},
            {nom: 'tdmdsc', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('zcvcleintref', {largeur: 100})
        ])
    };

}
