import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageIf0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function If0013Controller(if0004Route: IRoute) {
    const vm: IPageIf0013 = this;

    vm.multiOccurrenceOptions = {
       navigatePage: if0004Route.NAME,
       navigateParams: (rowData: any) => {
               return {
                   immcleint: rowData.immcleint
               };
       },
        filtresParametres: [
            { colonne: 'peccod', affichage: true },
            { colonne: 'catcod', affichage: true },
            { colonne: 'catdsc', affichage: true }
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        
        colonnesFixesGauche: [
            { nom: 'immcod', largeur: 200 },
            { nom: 'immdsc', largeur: 300 }
        ],
        colonnesVisibles: [
            { nom: 'hitmnttrsini', largeur: 200, total: true },
            { nom: 'hitmnttrsamo', largeur: 200, total: true },
            { nom: 'hitmntnet', largeur: 200, total: true },
            { nom: 'hitmnttrsrev', largeur: 200, total: true },
            { nom: 'hitmnttrsgap', largeur: 200, total: true },
            { nom: 'immvalres', largeur: 200, total: true }
        ]
    };
}
