import {IComponentController} from 'angular';
import {IFormulaire} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';

interface IPageRr0164 extends IComponentController {
    formulaireDaf: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rceMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0164Controller($stateParams: any) {

    const vm: IPageRr0164 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.hasAccesListe1 = $stateParams.type;
        // DÉTAIL DES RCE
        vm.rceMultiOccurrenceOptions = {
            bloc: 'rce',
            colonnesVisibles: [
                {nom: 'rcecod'   , largeur: 120},
                {nom: 'rcedsc'   , largeur: 250},
                {nom: 'ptpmntemp', largeur: 140, total: true},
                {nom: 'ptpmntemr', largeur: 140, total: true},
                {nom: 'parmntadm', largeur: 140, total: true},
                {nom: 'parqteadm', largeur: 140, total: true},               
                {nom: 'nomass'   , largeur: 200, hidden: () => (vm.monoOccurrence.data.type === 'DAF')}
            ]
        };
    }
}
