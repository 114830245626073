import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageGA0006 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function GA0006Controller(ga2001Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageGA0006 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {        
        fonctions: {
            criteresSuggeresVisibles: true,
            rechercheCollapsable: false
        },               
        navigatePage: ga2001Route.NAME,
        navigateParams: (rowData: any, multiOccurrence: IMultiOccurrence) => {
            return {
                peccoddeb: multiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: multiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: rowData.prucleint,
                el2cleint: rowData.el2cleint,
                cptcleint: rowData.cptcleint,
                nature: multiOccurrence.etatSelected.criteresSuggeresData.nature
            };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider(' '),
            new FormulaireItem('peccoddeb', { largeur: 25, required: true }),
            new FormulaireItem('peccodfin', { largeur: 25, required: true }),
            new FormulaireItem('nature'   , { default: vm.valeursEcran.nature, largeur: 25, required: true }),  
            new FormulaireItem('nivdet'   , { default: vm.valeursEcran.nivdet, largeur: 25, required: true }),          
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('prucleint', { largeur: 25, lovSelectionMultiple: true}),
            new FormulaireItem('cptcleint', { largeur: 25, lovSelectionMultiple: true}),
            new FormulaireItem('el2cleint', { largeur: 25, lovSelectionMultiple: true, required: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unacleint', { largeur: 25, lovSelectionMultiple: true}),
            new FormulaireItem('gcacleint', { largeur: 25 })
        ]), 
        colonnesFixesGauche: [
            {nom: 'prucod', largeur: 110},
            {nom: 'prudscl01', largeur: 210},
            {nom: 'el2cod', largeur: 110},
            {nom: 'el2dsc', largeur: 210}
        ],  
        colonnesCachees: ["prucleint", "cptcleint"], 
        colonnesVisibles: [
            {nom: 'mnt_bud_ini', largeur: 140},
            {nom: 'mnt_bud_rev', largeur: 140},
            {nom: 'mnt_rab', largeur: 140},
            {nom: 'mnt_gb', largeur: 140},
            {nom: 'mnt_rar', largeur: 140},
            {nom: 'mnt_re', largeur: 140},
            {nom: 'mnt_pe', largeur: 140},
            {nom: 'mnt_eng', largeur: 140},
            {nom: 'mnt_reel', largeur: 140},
            {nom: 'mnt_gs', largeur: 140},
            {nom: 'mnt_pb', largeur: 170},
            {nom: 'dispo', largeur: 230}
            ]
    };
    
}
