import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAc0038 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0038Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAc0038 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccleintcri'),
            new FormulaireItem('dacdatdebcri'),
            new FormulaireItem('dacdatfincri')
        ]),
        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 130 },
            { nom: 'dacnumdocseq', largeur: 100 },
            { nom: 'sysboncmd', largeur: 150 },
            { nom: 'dacseqave', largeur: 100 },
            { nom: 'dacdsc', largeur: 250 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 180 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'flgcou', largeur: 100 },
            { nom: 'flgnonrec', largeur: 100 },
            { nom: 'dacmnt', largeur: 120 },
            { nom: 'caacod', largeur: 120 },
            { nom: 'caadsc', largeur: 180 },
            { nom: 'intcodach', largeur: 120 },
            { nom: 'intnomach', largeur: 180 },
            { nom: 'intcoddem', largeur: 120 },
            { nom: 'intnomdem', largeur: 180 },
            { nom: 'intcodreq', largeur: 120 },
            { nom: 'intnomreq', largeur: 180 },
            { nom: 'usrcod', largeur: 180 },
            { nom: 'lipcod', largeur: 120 },
            { nom: 'flgerr_b2b', largeur: 100 },
            { nom: 'motcod', largeur: 120 },
            { nom: 'motdsc', largeur: 180 },
            { nom: 'flgtrans', largeur: 100 },
        ]
    };
}