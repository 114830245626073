import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc2002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc2002Controller(mc2001Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc2002 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc2001Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc2001Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'sstcod', largeur: 120},
            {nom: 'sstdscabr', largeur: 230},
            {nom: 'sstdsc', largeur: 230},
            {nom: 'sstflgact', largeur: 100},
            {nom: 'sstdatdeb', largeur: 120},
            {nom: 'sstdatfin', largeur: 120}
        ]
    };
}
