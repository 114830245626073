import {IComponentController, IFormController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {
    IFormulaire,
    IFormulaireClass
} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';

interface IPageMr0200 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formCtrl: IFormController;
    formulaireEnx: IFormulaire;
    formulaireTpt: IFormulaire;    
    edxMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0200Controller(mr2004Route: IRoute,
                                         mc0099Route: IRoute,
                                         FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0200 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.dataLsaoff = {...vm.valeursEcran};
        vm.sectionRatioVisible = (vm.valeursEcran.ratio || vm.valeursEcran.ratmin || vm.valeursEcran.ratmil || vm.valeursEcran.ratmax);

        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonEnregistrerHaut: false,
                suiviModification: false
            },
            menus: [
                new MenuItemForage(
                    'G_LBL_DOC_FIN',
                    mc0099Route.NAME,
                    (data) => ({
                       id: data.doccleint
                    }), {
                        icon: 'subtitles',
                        iconButton: false,
                        secondaire: true
                    }
                ),
                new MenuItemForage(
                    'LBL_MSG',
                    mr2004Route.NAME,
                    (data: any) => ({
                       enxcleint: data.enxcleint,
                       vaeemxtyp: 'DBG'
                    }), {
                        iconButton: false,
                        icon: '',
                        class: 'md-raised ex-button-secondary'
                    }
                )
            ],
            infosFinancieres: {
                disponibilite: true
            }
        };

        vm.formulaireEnx = new Formulaire('LBL_BLC_PRE', [
            new FormulaireItem('enxcod', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tptdatdeb', {largeur: 25}),
            new FormulaireItem('tptdatfin', {largeur: 25})
        ]);

        // Projection //
        vm.formulaireTpt = new Formulaire('LBL_TPT', [
            new FormulaireGroupe('LBL_PROJ_PROJ', new Formulaire([
            new FormulaireItem('tptmntsal', {largeur: 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tptmntadd', {largeur: 25}),
            new FormulaireItem('tptpctadd', {largeur: 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tptmntavs', {largeur: 25}),
            new FormulaireItem('tptpctavs', {largeur: 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('total', {largeur: 25}),
            new FormulaireItem('tptdatfinpre', {largeur: 25})
        ]), {largeur: 33.33}),
            new FormulaireGroupe('LBL_PROJ_DOC', new Formulaire([
                new FormulaireItem('tptmntsaldoc'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tptmntadddoc'),
                new FormulaireItem('tptpctadddoc'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tptmntavsdoc'),
                new FormulaireItem('tptpctavsdoc'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('total_doc'),
                new FormulaireItem('tptdatfindoc')
            ]), {largeur: 33.33}),
            new FormulaireGroupe('LBL_PROJ_ECT', new Formulaire([
                new FormulaireItem('ectsal'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ectadd'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ectavs'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('total_ect')
            ]), {largeur: 33.33}),
            new FormulaireItemMenuItem(new MenuItemAction(
                'LBL_BTN_SIM',
                'BOUSIM',
                (data: any) => ({}),
                {
                    iconButton: false,
                    saveFirst: true,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }                    
                }
            ))
        ]);

        // Ventilation
        vm.edxMultiOccurrenceOptions = {
            bloc: 'edx',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                nouveau: false
            },
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_MSG',
                    mr2004Route.NAME,
                    (data: any) => ({
                       enxcleint: data.enxcleint,
                       edxcleint: data.edxcleint,
                       vaeemxtyp: 'DBG'
                    }), {
                        iconButton: false,
                        icon: '',
                        class: 'md-raised ex-button-secondary',
                        disabled: (data: any) => (
                            !data.flg_err )
                    }
                )
            ]),
             // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'typvnt_dsc', largeur: 180},
                {nom: 'imp', largeur: 200},
                {nom: 'edxmnt', largeur: 120, total: true},
                {nom: 'edxmntavs', largeur: 139, total: true},
                {nom: 'flg_err', largeur: 140}
            ],
            colonnesCachees: [
                'edxcleint'
            ]
        };
    }
  
}
