import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0032Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0032 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'crgcod', largeur: 89 },
            { nom: 'crgdsc', largeur: 225 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('crgcod', { required: true, largeur: 25 }),
            new FormulaireItem('crgdsc', { required: true }),
        ]),
        fonctions: Object.assign({
            edition: true,
            rechercheCollapsable: false,
            nouveau: true

        })
    };
}
