import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemTexteClass } from '../../../../../core/services/formulaire/formulaire-item-texte.service';
import { ISourceDetails } from '../../../../../core/resources/source-details.resource';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0217 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireApa: IFormulaire;
    desMultiOccurrenceOptions: IMultiOccurrenceOptions;
    acnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    adeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0217Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemTexte: IFormulaireItemTexteClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranSourceDetails: ISourceDetails,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0217 = this;

    ecranSourceDetails.dataTypes.apatxt.params.vaebmtent = ecranDetails.valeurs.stamnetabbmt;

    vm.formulaireApa = new Formulaire('LBL_BLC_APA', [
        new FormulaireItem('apacleintref', {modifAllow: false, disabled: (data) => (data.apacleintref)}),
        new FormulaireItem('apasujavi'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('apadsc'),
        new FormulaireItem('pfccleint'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('sqlini', { hidden: true }),
        new FormulaireItem('apasqlini', {
            largeur: 30,
            resourceParams: (data: any) => {
                return {
                    apacleintref: data.apacleintref
                };
            }
        }),
        new FormulaireItemTexte('LBL_ENONCE', {
            largeur: 20,
            menu: [new MenuItemAction(
                'BOUOK',
                () => ({}),
                {
                    icon: 'ballot',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_ENONCE',
                        lblConfirm: 'G_LBL_BTN_OK',
                        largeurFormulaire: 50,
                        formulaire: new Formulaire([
                            new FormulaireItem('sqlini', { readonly: true })
                        ])
                    }
                }
            )]
        }),
        new FormulaireItem('sqlcon', { hidden: true }),
        new FormulaireItem('apasqlcon', {
            largeur: 30,
            resourceParams: (data: any) => {
                return {
                    apacleintref: data.apacleintref
                };
            }
        }),
        new FormulaireItemTexte('LBL_ENONCE', {
            largeur: 20,
            menu: [new MenuItemAction(
                'BOUOK',
                () => ({}),
                {
                    icon: 'ballot',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_ENONCE',
                        lblConfirm: 'G_LBL_BTN_OK',
                        largeurFormulaire: 50,
                        formulaire: new Formulaire([
                            new FormulaireItem('sqlcon', { readonly: true })
                        ])
                    }
                }
            )]
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('apaparcon1', { enableCount: true }),
        new FormulaireItem('apaparcon2', { enableCount: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('stamne', {hidden: true}),
        new FormulaireItem('apatxt', { required: true, enableCount: true, largeur: 100, hidden: (data) => !data.$id}),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('apadelavi', { default: 0, required: true, largeur: 20 }),
        new FormulaireItem('jour1', { titre: ' ', largeur: 20, default: 'Jour(s)', readonly: true }),
        new FormulaireItem('apaaviuniobj', { largeur: 20 }),
        new FormulaireItem('apaflgmsgpur', { largeur: 20 }),
        new FormulaireItem('apaflgafftra', { largeur: 20 })
    ], { largeurDefaut: 50 });

    // Destinataire
    vm.desMultiOccurrenceOptions = {
        bloc: 'des',
        colonnesVisibles: [
            { nom: 'vaedestyp_dsc', largeur: 100 },
            { nom: 'descod', largeur: 100 },
            { nom: 'desdsc', largeur: 100 },
            { nom: 'desflgtab', largeur: 100 },
            { nom: 'desflgcou', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaedestyp'),
            new FormulaireItem('rolcleint', {
                hidden: (data) =>
                    !data.vaedestyp ||
                    data.vaedestyp === 'SUP' ||
                    data.vaedestyp === 'SUPV' ||
                    data.vaedestyp === 'ETU' ||
                    data.vaedestyp === 'NIV' ||
                    data.vaedestyp === 'INT' ||
                    data.vaedestyp === 'EPL' ||
                    data.vaedestyp === 'DEM' ||
                    data.vaedestyp === 'REF' ||
                    data.vaedestyp === 'INI' ||
                    data.vaedestyp === 'URG',
                required: (data) => data.vaedestyp === 'ROL'
            }),
            new FormulaireItem('nircleint', {
                hidden: (data) =>
                    !data.vaedestyp ||
                    data.vaedestyp === 'ROL' ||
                    data.vaedestyp === 'SUP' ||
                    data.vaedestyp === 'SUPV' ||
                    data.vaedestyp === 'ETU' ||
                    data.vaedestyp === 'INT' ||
                    data.vaedestyp === 'EPL' ||
                    data.vaedestyp === 'DEM' ||
                    data.vaedestyp === 'REF' ||
                    data.vaedestyp === 'INI' ||
                    data.vaedestyp === 'URG',
                required: (data) => data.vaedestyp === 'NIV'
            }),
            new FormulaireItem('intcleint', {
                hidden: (data) =>
                    !data.vaedestyp ||
                    data.vaedestyp === 'ROL' ||
                    data.vaedestyp === 'SUP' ||
                    data.vaedestyp === 'SUPV' ||
                    data.vaedestyp === 'ETU' ||
                    data.vaedestyp === 'NIV' ||
                    data.vaedestyp === 'EPL' ||
                    data.vaedestyp === 'DEM' ||
                    data.vaedestyp === 'REF' ||
                    data.vaedestyp === 'INI' ||
                    data.vaedestyp === 'URG',
                required: (data) => data.vaedestyp === 'INT'
            }),
            new FormulaireItem('desflgtab', { largeur: 25 }),
            new FormulaireItem('desflgcou', { largeur: 25 })
        ])
    };

    // Étape de création
    vm.acnMultiOccurrenceOptions = {
        bloc: 'acn',
        colonnesVisibles: [
            { nom: 'cyrcod', largeur: 100 },
            { nom: 'cyrdsc', largeur: 200 },
            { nom: 'ecldsceta', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cepcleint', {
                resourceParams: (data: any) => {
                    return {
                        apacleintref: data.$ancetre.apacleintref
                    };
                }
            })
        ])
    };

    // Étape de destruction
    vm.adeMultiOccurrenceOptions = {
        bloc: 'ade',
        colonnesVisibles: [
            { nom: 'cyrcod', largeur: 100 },
            { nom: 'cyrdsc', largeur: 200 },
            { nom: 'ecldsceta', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cepcleint', {
                resourceParams: (data: any) => {
                    return {
                        apacleintref: data.$ancetre.apacleintref
                    };
                }
            })
        ])
    };
}
