import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMr0176 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ovnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovnMultiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface IMr0176Params {
    ovdcleintref: number;
    stamnetab: string;
}

/* @ngInject */
export default function Mr0176Controller($stateParams: IMr0176Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemEcran: IMenuItemEcranClass,
    exDateFilter: IFilterExDate,
    mr0212Route: IRoute,
    mr0213Route: IRoute,
    mr0309Route: IRoute) {
    const vm: IPageMr0176 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.flgcen = ecranDetails.flgcen;

        const optionsMontantMaxJour = {
            icon: 'launch_app',
            successAction: () => {
                vm.ovnMultiOccurrence.fetchDataList();
            },
            formulaireConfirmationAction: {
                lblTitre: 'LBL_CALCUL_MONTANT_MAX_JOUR',
                lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                largeurFormulaire: 60,
                formulaire: new Formulaire([
                    new FormulaireItem('montant', {
                        largeur: 10,
                        readonly: true,
                        default: (data: any) => data.ovnmnt,
                        libellesContour: { droite: '/  (' }
                    }),
                    new FormulaireItem('nbrjrssub', {
                        largeur: 10,
                        readonly: true,
                        default: (data: any) => data.nbrjrssub,
                        libellesContour: { droite: 'X' }
                    }),
                    new FormulaireItem('nbrjrsbas', {
                        largeur: 10,
                        default: (data: any) => data.ovnnbrjrssubbas,
                        libellesContour: { droite: '/' }
                    }),
                    new FormulaireItem('nbrjrsper', {
                        largeur: 10,
                        default: (data: any) => data.ovnnbrjrssubppa,
                        libellesContour: { droite: ')=' }
                    }),
                    new FormulaireItem('ovnmntjrs', { largeur: 20, readonly: true })
                ], { largeurDefaut: 20 })
            },
            champsRechargement: ['nbrjrsbas', 'nbrjrsper']
        }

        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonEnregistrerHaut: vm.valeursEcran.flgmod,
                supprime: vm.valeursEcran.flgmod
            },
            menus: [
                new MenuItemEcran(
                    'G_LBL_CHGT_ENGAGEMENT',
                    mr0212Route.NAME,
                    (data: any) => ({
                        id: vm.valeursEcran.mobcleint_act,
                        mobcleintref: data.ovdcleintref,
                        stamnetab: $stateParams.stamnetab
                    }),
                    {
                        icon: 'create',
                        iconButton: false,
                        secondaire: true,
                        fonction: 'BOUENG',
                        hidden: () => !vm.valeursEcran.flgbtn
                    }
                ),
                new MenuItemEcran(
                    'G_LBL_HISTO_CHGT',
                    mr0213Route.NAME,
                    (data: any) => ({
                        mobcleintref: data.ovdcleintref,
                        stamnetab: $stateParams.stamnetab
                    }),
                    {
                        icon: 'view_list',
                        iconButton: false,
                        secondaire: true,
                        hidden: () => !vm.valeursEcran.flgbtn
                    }
                )
            ],

            formulaire: new Formulaire('LBL_BLC_OVD', [
                new FormulaireItem('intcleint', {
                    largeur: 50, default: vm.valeursEcran.intcleint, readonly: () => {
                        return !vm.valeursEcran.flgmod || vm.ovnMultiOccurrence && vm.ovnMultiOccurrence.dataListReady && vm.ovnMultiOccurrence.dataList.length > 0;
                    }
                }),
                new FormulaireItem('ovddatvig', {
                    largeur: 25,
                    default: vm.valeursEcran.datdeb
                }),
                new FormulaireItem('ovddatfin', {
                    largeur: 25
                }),
                new FormulaireItem('ovdcleintref', { default: $stateParams.ovdcleintref }),
                new FormulaireItem('stamnetab', { default: $stateParams.stamnetab })
            ])
        };

        // Imputation
        vm.ovnMultiOccurrenceOptions = {
            bloc: 'ovn',
            fonctions: {
                editionRapide: vm.valeursEcran.flgmod,
                edition: () => vm.ovnMultiOccurrence.editionRapideActive,
                nouveau: () => vm.ovnMultiOccurrence.editionRapideActive
            },
            editionRapideActive: vm.valeursEcran.flgmod,
            actionsMoreLigne: new Menu([new MenuItemAction(
                'LBL_CALCUL_MONTANT_MAX_JOUR',
                'BOUENRG',
                () => ({}),
                optionsMontantMaxJour
            )]),
            colonnesVisibles: [
                { nom: 'rsccod', largeur: 100 },
                { nom: 'imp', largeur: 250 },
                { nom: 'ovnprc', largeur: 100, hidden: !vm.valeursEcran.flgprc },
                {
                    nom: 'ovnmnt', largeur: 120, hidden: !vm.valeursEcran.flgmnt, menu: [new MenuItemAction(
                        'LBL_CALCUL_MONTANT_MAX_JOUR',
                        'BOUOUV',
                        () => ({}),
                        {
                            ...optionsMontantMaxJour,
                            iconButton: true,
                            hidden: (data: any) => !data.ovnmnt
                        }
                    )]
                },
                { nom: 'ovndatdebsub', largeur: 140, hidden: !vm.valeursEcran.flgmnt },
                { nom: 'ovndatfinsub', largeur: 130, hidden: !vm.valeursEcran.flgmnt },
                {
                    nom: 'vaeovnmthbmg_dsc', largeur: 120, menu: [new MenuItemEcran(
                        '',
                        mr0309Route.NAME,
                        (data: any) => ({
                            id: data.ovncleint,
                            ovncleintper: data.ovncleint,
                            ovdcleint: data.ovdcleint,
                            rsccleint: data.rsccleint,
                            stamnetab: $stateParams.stamnetab,
                            ovddatvig: exDateFilter(vm.monoOccurrence.data.ovddatvig),
                            flgmod: vm.valeursEcran.flgmod,
                            intcleint: vm.monoOccurrence.data.intcleint,
                            ovdcleintref: $stateParams.ovdcleintref
                        }),
                        {
                            icon: 'create',
                            onClose() {
                                vm.ovnMultiOccurrence.fetchDataList();
                            },
                            hidden: (data: any) => !data.ovncleint
                        }
                    )]
                },
                { nom: 'ovnflgcom', largeur: 100 }
            ],
            colonnesEditionRapide: [
                { nom: 'rsccleint', largeur: 300 },
                { nom: 'imp', largeur: 250 },
                { nom: 'ovnprc', largeur: 100, hidden: !vm.valeursEcran.flgprc, default: (data: any) => data.ovnmnt ? undefined : 100, },
                { nom: 'ovnmnt', largeur: 100, hidden: !vm.valeursEcran.flgmnt },
                { nom: 'ovndatdebsub', largeur: 165, hidden: !vm.valeursEcran.flgmnt },
                { nom: 'ovndatfinsub', largeur: 165, hidden: !vm.valeursEcran.flgmnt },
                { nom: 'ovncom', largeur: 500 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem("ovndatdebsub", { required: (data) => data.ovnmnt }),
                new FormulaireItem("ovndatfinsub", { required: (data) => data.ovnmnt }),
                new FormulaireItem("ovnmnt", { disabled: (data) => data.ovnprc }),
                new FormulaireItem("ovnprc", { disabled: (data) => data.ovnmnt })
            ]),
            colonnesCachees: [
                'nbrjrssub', 'ovnnbrjrssubbas', 'ovnnbrjrssubppa', 'ovnmntjrs', 'ovdcleint', 'rsccleint', "ovdcleintref"
            ]
        };
    }
}
