import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageGs0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0010Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGs0010 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'types',
                valeur: 'TOUS'
            }
        ],
        colonnesCachees:[
            'types'
        ],
        colonnesFixesGauche:[
            {nom: 'pasdsc', largeur: 250}
        ],
        colonnesVisibles: [
            {nom: 'pasdsclng', largeur: 400},
            {nom: 'pasvaldev', largeur: 200},
            {nom: 'pasnomeleval', largeur: 150},
            {nom: 'vaepascatdsc', largeur: 140},
            {nom: 'srccod', largeur: 100},
            {nom: 'srcdsc', largeur: 150}
        ],
        colonnesFixesDroite: [
            {nom: 'pasflgusr', largeur: 100},
            {nom: 'pasflgusrmod', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pasdsc', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pasdsclng', {largeur: 100, enableCount: true}),
            new FormulaireItem('pasvaldev'),
            new FormulaireItem('pasnomeleval'),
            new FormulaireItem('vaepascat'),
            new FormulaireItem('srccleint'),
            new FormulaireItem('pasflgusr'),
            new FormulaireItem('pasflgusrmod')
        ], {largeurDefaut: 25})
    };
}
