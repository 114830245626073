import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAc0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Ac0013Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageAc0013 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false
        },
        actionsSelection: new Menu([new MenuItemSelection(
            'LBL_BTN_INSCRIT_ACHETEUR',
            'BOUINSACH', {
            selection: {
                nom: 'actionsInscrit',
                lblConfirmationTitre: 'LBL_BTN_INSCRIT_ACHETEUR',
                largeurFormulaire: 30,
                confirmationFormulaire: new Formulaire([
                    new FormulaireItem('intcleintnew', { largeur: 100 }),
                ])
            },
            successAction: (data: any) => { 
                vm.multiOccurrence.fetchDataList();
            }
        }),
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('caacleintcri', { largeur: 25 }),
            new FormulaireItem('unacleintcri', { largeur: 25 }),
            new FormulaireItem('intcleintreqcri', { largeur: 25 }),
            new FormulaireItem('etdcleintcri', { largeur: 25 })
        ]),
        colonnesVisibles: [
            { nom: 'docnumdoc', largeur: 100 },
            { nom: 'etcnumdoc', largeur: 180 },
            { nom: 'caacod', largeur: 130 },
            { nom: 'caadsc', largeur: 225 },
            { nom: 'intcodreq', largeur: 100 },
            { nom: 'intnomreq', largeur: 225 },
            { nom: 'intcoddem', largeur: 120 },
            { nom: 'intnomdem', largeur: 225 },
            { nom: 'tydcod', largeur: 120 },
            { nom: 'tyddsc', largeur: 180 },
            { nom: 'refcod', largeur: 90 },
            { nom: 'refnom', largeur: 260 }
        ]
    };
}