import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0056 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0056Controller(mc0164Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0056 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0164Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0164Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'remcod'          , largeur: 120},
            {nom: 'remdscfed'       , largeur: 300},
            {nom: 'remdscpro'       , largeur: 300}
        ]
    };
}
