import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0034 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0034Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0034 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'msacod', largeur: 115 }
        ],
        colonnesVisibles: [
            { nom: 'msadsc', largeur: 420 },
            { nom: 'vecmsatyp', largeur: 170 },
            { nom: 'vecmsasev', largeur: 170 },
            { nom: 'vaemsaeff', largeur: 180 },
            { nom: 'rrccleint', largeur: 140 },
            { nom: 'chgcleint', largeur: 140 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('msacod', { largeur: 20 }),
            new FormulaireItem('msadsc', { largeur: 80 }),
            new FormulaireItem('vecmsatyp', { largeur: 20 }),
            new FormulaireItem('vecmsasev', { largeur: 20 }),
            new FormulaireItem('vaemsaeff', { largeur: 20 }),
            new FormulaireItem('rrccleint', { largeur: 20 }),
            new FormulaireItem('chgcleint', { largeur: 20 })
        ]),
        fonctions: Object.assign({
            edition: true,
            rechercheCollapsable: false,
            nouveau: true

        })
    };
}
