import {IAugmentedJQuery, IComponentController, IPromise, ITimeoutService} from 'angular';
import IResourceService = angular.resource.IResourceService;
import {IApiConfig} from '../../../../core/interfaces/api-config.interface';
import {IParametresSecuriteService} from '../../../../core/services/parametres-securite.service';
import {IEcranContextController} from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {INotificationHandler} from '../../../../core/services/utils/notification-handler.service';

interface IEcranQlik extends IComponentController {
    titrePage: string;
    app: string;
    sheet: string;
    urlContent: string;
    ecranContextCtrl: IEcranContextController;
    ecranReady: boolean;
    erreurChargement: boolean;
    fetchinginfos: boolean;
    initEcran(): void;
}

interface IEcranQlikDetails {
    titreEcran: string;
    token: string;
    src: string;
    con: string;
    proxy: string;
}

/** @ngInject */
export default function EcranQlikController($resource: IResourceService,
                                            $element: IAugmentedJQuery,
                                            $timeout: ITimeoutService,
                                            notificationHandler: INotificationHandler,
                                            parametresSecurite: IParametresSecuriteService,
                                            ApiConfig: IApiConfig) {
    const vm: IEcranQlik = this;
    let authorizationHeader: string;

    vm.$onInit = $onInit;
    vm.initEcran = initEcran;

    function $onInit() {
        initEcran();
    }

    function initEcran() {
        vm.fetchinginfos = true;
        getQlikEcranDetails()
            .then((ecranDetails: IEcranQlikDetails) => {
                vm.titrePage = ecranDetails.titreEcran;
                vm.erreurChargement = false;
                vm.ecranReady = true;
                const xrfkey = generateXrfKey();
                const host=ecranDetails.con.replace(/(^\w+:|^)\/\//, '')
                const proxy=ecranDetails.proxy

                if (proxy !== 'single') {
                    $.ajax({
                        type: 'GET',
                        url: `https://${host}/${proxy}/qrs/about?XrfKey=${xrfkey}`,
                        xhrFields: {
                            withCredentials: true
                        },
                        beforeSend: function (xhr: any) {
                            xhr.setRequestHeader('Authorization', authorizationHeader);
                            xhr.setRequestHeader('X-Qlik-Xrfkey', xrfkey);

                            authorizationHeader = null;
                        },
                        success: function (data) {
                            const iframe = $element.find('iframe.ex-ecran-qlik-content');
                            iframe.attr('src', ecranDetails.src);
                        },
                        error: function(err) {
                            if (err.responseText) {
                                notificationHandler.erreur({lblMessage: err.responseText, lblTitre: 'G_LBL_ERREUR'});
                            }
                        }
                    });
                } else {
                    $timeout(() => {
                        const iframe = $element.find('iframe.ex-ecran-qlik-content');
                        iframe.attr('src', ecranDetails.src);
                    });
                }
            })
            .catch(() => {
                vm.erreurChargement = true;
            })
            .finally(() => {
                vm.fetchinginfos = false;
            });
    }

    function generateXrfKey(): string {
        let result = '';
        const length = 16;
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    function getQlikEcranDetails(): IPromise<IEcranQlikDetails> {
        const resource = $resource(`${ApiConfig.ROOT}/ecran-qlik/:app/:sheet`, {
            app: vm.app,
            sheet: vm.sheet,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        }, {
            get: {
                method: 'GET',
                transformResponse: (data: string, headers: Function) => {
                    authorizationHeader = headers().authorization;
                    return JSON.parse(data);
                }
            }
        });

        return resource.get().$promise;
    }
}
