import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageDb0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0019Controller(
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    Operateur: IOperateurService,
    Formulaire: IFormulaireClass,
    db0056Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemSelection: IMenuItemSelectionClass
) {
    const vm: IPageDb0019 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: db0056Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(db0056Route.NAME, () => ({}), {
                fonction: 'BOUNOU'
            })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_DETRUIRE', 'BOUDET', {
                selection: {
                    nom: 'destruction',
                    lblConfirmationTitre: 'LBL_MOTIF_DEST',
                    confirmationFormulaire: new Formulaire([new FormulaireItem('option', { default: 'MNT' }),
                    new FormulaireItem('datdif', { required: (data) => data.option === 'ULT', hidden: (data) => data.option === 'MNT' }),
                    new FormulaireItem('hredif', { required: (data) => data.option === 'ULT', hidden: (data) => data.option === 'MNT' })])
                }
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('flgencour', {
                default: 1
            }),
        ]),
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/db0019/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'dbpnumchg', largeur: 130 },
            { nom: 'dbpsrc', largeur: 232 },
            { nom: 'dbpflg', largeur: 120 },
            { nom: 'ddeflgecberr', largeur: 120 },
            { nom: 'ddeflgebverr', largeur: 120 },
            { nom: 'ddeflgdoierr', largeur: 120 },
            { nom: 'ddenbrerr', largeur: 90 },
            { nom: 'ddenbrtot', largeur: 126 },
            { nom: 'ddedoimntdbt', largeur: 126 },
            { nom: 'ddedoimntcrt', largeur: 126 }
        ],
        colonnesCachees: ['dbpcleint']
    };
}
