import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import IFilterDate = angular.IFilterDate;

interface IPageTh0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0032Controller(Formulaire: IFormulaireClass,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         dateFilter: IFilterDate,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTh0032 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('ttccleint', {required: false} )
        ]),

        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 160},
            {nom: 'ftpnumdoc', largeur: 140},
            {nom: 'tothrereg', largeur: 100},
            {nom: 'tothresup', largeur: 100},
            {nom: 'tothreadd', largeur: 100},
            {nom: 'sldbqe', largeur: 100},
            {nom: 'ecldsceta', largeur: 140},
            {nom: 'pxtnumdoc', largeur: 100},
            {nom: 'pxtdsc', largeur: 160},
            {nom: 'rghcod', largeur: 100},
            {nom: 'rghdsc', largeur: 160},
            {nom: 'vaeftptyp_dsc', largeur: 120},
            {nom: 'ttccod', largeur: 100},
            {nom: 'ttcdsc', largeur: 140},
            {nom: 'unocod', largeur: 100},
            {nom: 'unodscabr', largeur: 140},
            {nom: 'posnumdoc', largeur: 120},
            {nom: 'postitabr', largeur: 160},
            {nom: 'lemcod', largeur: 100},
            {nom: 'lemdsc', largeur: 160},
            {nom: 'aftcod', largeur: 100},
            {nom: 'aftdsc', largeur: 140},
            {nom: 'temcod', largeur: 100},
            {nom: 'temdscabr', largeur: 140},
            {nom: 'usrcod', largeur: 100},
            {nom: 'ftpcom', largeur: 160},
            {nom: 'flgmsg', largeur: 100},
            {nom: 'ftpflgrefexcbac', largeur: 100},
            {nom: 'trnflgstaref', largeur: 100},
            {nom: 'trnflgstafin', largeur: 100},
            {nom: 'trnflgstafer', largeur: 100},
            {nom: 'trnflgstaann', largeur: 100}
        ]
    };
}
