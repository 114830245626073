import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCP0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0017Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0017 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('cfdcod'),
            new FormulaireItem('cfddsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cfdindcon'),
            new FormulaireItem('cfdindesc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cfdlibcol01'),
            new FormulaireItem('cfdmaxcol01'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cfdlibcol02'),
            new FormulaireItem('cfdmaxcol02'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cfdlibcol03'),
            new FormulaireItem('cfdmaxcol03'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cfdlibcol04'),
            new FormulaireItem('cfdmaxcol04'),


        ], {largeurDefaut: 25}),
        colonnesVisibles: [
            {nom: 'cfdcod', largeur: 65},
            {nom: 'cfddsc', largeur: 300},
            {nom: 'cfdindcon', largeur: 130},
            {nom: 'cfdindesc', largeur: 145},
            {nom: 'cfdlibcol01', largeur: 145},
            {nom: 'cfdmaxcol01', largeur: 105},
            {nom: 'cfdlibcol02', largeur: 145},
            {nom: 'cfdmaxcol02', largeur: 105},
            {nom: 'cfdlibcol03', largeur: 145},
            {nom: 'cfdmaxcol03', largeur: 105},
            {nom: 'cfdlibcol04', largeur: 145},
            {nom: 'cfdmaxcol04', largeur: 105}
        ]
    };
}

