import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0004Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0004 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tyfcod', largeur: 115 },
            { nom: 'tyfdsc', largeur: 215 },
            { nom: 'vaedsc', largeur: 155 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyfcod'),
            new FormulaireItem('tyfdsc', {required: true, largeur: 50}),
            new FormulaireItem('vaetyfreg')
        ],
         {
            largeurDefaut: 25
        }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
