import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageIn0052 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireMag: IFormulaire;
    usaimp: IMenuItem;
    tol: IMenuItem;
    lieu: IMenuItem;
    malMultiOccurrenceOptions: IMultiOccurrenceOptions;
    malMultiOccurrence: IMultiOccurrence;
    maiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mitMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function In0052Controller(mc0046Route: IRoute,
    mc1014Route: IRoute,
    Formulaire: IFormulaireClass,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageIn0052 = this;

    // Usage d'imputation
    vm.usaimp = new MenuItemEcran(
        'LBL_BOU_IMP', 
        mc0046Route.NAME, 
        () => {
            return {
                vaeusient: 'MAG',
                uvicleintref: vm.monoOccurrence.data.magcleint
            };
        }, 
        {
            icon: 'view_list',
            iconButton: false,
            secondaire: true,
            fonction: "BOUIMP"
        }
    );

    // Tolérance
    vm.tol = new MenuItemEcran(
        'LBL_BOU_TOL', 
        mc1014Route.NAME, 
        () => {
            return {
                vaeustent: 'MAG',
                tolcleintref: vm.monoOccurrence.data.magcleint
            };
        }, 
        {
            icon: 'data_usage',
            iconButton: false,
            secondaire: true,
            fonction: "BOUTOL"
        }
    );

    // Lieu physique
    const forageLieu = new MenuItemAction(
        'LBL_BTN_LIEU_PHYSIQUE',
        'BOULIEPHY',
        () => ({
            cleintref: vm.monoOccurrence.data.magcleint,
            type: 'MAG'
        }),
        {
            icon: 'add_circle',
            fonction: 'BOULIEPHY',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('lipcodnew', { required: true }),
                    new FormulaireItem('lipflgadrfacnew'),
                    new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                    new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                    new FormulaireItem('intcleintnew'),
                    new FormulaireItemDivider('LBL_LIP_ADR'),
                    new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                    new FormulaireItem('lipvilnew', { maxlength: 40 }),
                    new FormulaireItem('lippronew', { maxlength: 40 }),
                    new FormulaireItem('lippaynew', { maxlength: 40 }),
                    new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                    new FormulaireItem('lipflgquarecnew'),
                    new FormulaireItemDivider(''),
                    new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                    new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                    new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                    new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                    new FormulaireItem('lipcleintrefnew')
                ], {
                    largeurDefaut: 50
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );
    
    // Lieu physique (localisation)
    const forageLieuLoc = new MenuItemAction(
        'LBL_BTN_LIEU_PHYSIQUE',
        'BOULIEPHY',
        (rowData:any) => ({
            cleintref: rowData.malcleint,
            type: 'MAL'
        }),
        {
            icon: 'add_circle',
            fonction: 'BOULIEPHYMAL',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('lipcodnew', { required: true }),
                    new FormulaireItem('lipflgadrfacnew'),
                    new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                    new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                    new FormulaireItem('intcleintnew'),
                    new FormulaireItemDivider('LBL_LIP_ADR'),
                    new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                    new FormulaireItem('lipvilnew', { maxlength: 40 }),
                    new FormulaireItem('lippronew', { maxlength: 40 }),
                    new FormulaireItem('lippaynew', { maxlength: 40 }),
                    new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                    new FormulaireItem('lipflgquarecnew'),
                    new FormulaireItemDivider(''),
                    new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                    new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                    new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                    new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                    new FormulaireItem('lipcleintrefnew')
                ], {
                    largeurDefaut: 50
                }),
            },
            successAction: () => { vm.malMultiOccurrence.fetchDataList(); }
        }
    );

    vm.monoOccurrenceOptions = {
        successActionSauvegarde: () => vm.monoOccurrence.fetchData(),
        formulaire: new Formulaire('LBL_BLC_MAG', [
            new FormulaireItem('magcod'),
            new FormulaireItem('magnom'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('magdatdeb'),
            new FormulaireItem('magdatfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('magflgsec', { default: 0 }),
            new FormulaireItem('magflgdep', { default: 0 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lipcleint', { largeur: 50 ,menu: [forageLieu] })
        ])
    };

    // Onglet Localisation
    vm.malMultiOccurrenceOptions = {
        fonctions: {
            editionRapide: true,
            consultationStandard: true,
            editionStandard: true,
            importation: true
        },
        editionRapideActive: false,
        colonnesVisibles: [
            { nom: 'malcod', largeur: 100 },
            { nom: 'maldsc', largeur: 200 },
            { nom: 'malflgstu', largeur: 120 },
            { nom: 'malflgdef', largeur: 120 },
            { nom: 'lipcod', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('malcod'),
            new FormulaireItem('maldsc'),
            new FormulaireItem('malflgstu', { default: 1 }),
            new FormulaireItem('malflgdef'),
            new FormulaireItem('lipcleint', { largeur: 100, menu: [forageLieuLoc]})
        ]),
        colonnesEditionRapide: [
            { nom: 'malcod', largeur: 140 },
            { nom: 'maldsc', largeur: 130 },
            { nom: 'malflgstu', largeur: 130 },
            { nom: 'malflgdef', largeur: 130 },
            { nom: 'lipcleint', largeur: 350 }
        ]
    };

    // Onglet Magasinier
    vm.mitMultiOccurrenceOptions = {
        fonctions: {
            editionStandard: true,
            editionRapide: true
        },
        editionRapideActive: false,
        colonnesVisibles: [
            { nom: 'intcod', largeur: 150 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'mitflgpri', largeur: 140 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcleint'),
            new FormulaireItem('mitflgpri')
        ]),
        colonnesEditionRapide: [
            { nom: 'intcleint', largeur: 300 },
            { nom: 'mitflgpri', largeur: 150 }
        ]
    };

    // Onglet Restriction requérant
    vm.maiMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'intcod', largeur: 140 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'maidatfin', largeur: 100 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcleint'),
            new FormulaireItem('maidatfin')
        ], { largeurDefaut: 100 })
    };
}
