import { IComponentController } from "angular";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';


interface IPageTf0014 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    tagMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rceMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aviMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bacMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tagMultiOccurrence: IMultiOccurrence;
    rceMultiOccurrence: IMultiOccurrence;
    aviMultiOccurrence: IMultiOccurrence;
    bacMultiOccurrence: IMultiOccurrence;
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Tf0014Controller(Formulaire: IFormulaireClass, 
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTf0014 = this;

    vm.monoOccurrenceOptions = {
        noId: true
    }

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('crianrann', {  largeur: 10, required: true }),        
        new FormulaireItem('criflgnumemr', { largeur: 30 }),       
        new FormulaireItem('cricomulatif', { default: 1, largeur: 40 }),
        new FormulaireItem('cridemcleint', { largeur: 50 })
    ],{largeurDefaut: 25});

    vm.tagMultiOccurrenceOptions = {
        fonctions: {   
            customCriteresSuggeres: true,            
            enregistrerUnEtat: false,  
            effaceCriteres: true,
            recherche: true, 
            reinitialiserPastillefiltre: true,
            avisEmploye: false
        },
        criteresSuggeres,   
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 50},
            {nom: 'tagdsc', largeur: 150},
            {nom: 'patqtecal', largeur: 70},
            {nom: 'patmntcal', largeur: 70},
            {nom: 'rfecod', largeur: 100}
        ]
    };

    vm.rceMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,            
            enregistrerUnEtat: false,  
            effaceCriteres: true,
            recherche: true, 
            reinitialiserPastillefiltre: true,
            avisEmploye: false  
        },        
        criteresSuggeres,
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 50},
            {nom: 'rcedsc', largeur: 150},
            {nom: 'parmntemp', largeur: 70},
            {nom: 'parmntemr', largeur: 70},
            {nom: 'rcerfecod', largeur: 100}
        ]
    };

    vm.aviMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,            
            enregistrerUnEtat: false,  
            effaceCriteres: true,
            recherche: true, 
            reinitialiserPastillefiltre: true,
            avisEmploye: false  
        },        
        criteresSuggeres,
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 50},
            {nom: 'tagdscabr', largeur: 150},
            {nom: 'daumnt', largeur: 70}
        ]
    };

    vm.bacMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,            
            enregistrerUnEtat: false,  
            effaceCriteres: true,
            recherche: true, 
            reinitialiserPastillefiltre: true,
            avisEmploye: false
        },        
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: "bqecod", largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: "bqedscabr", largeur: 200 },
            { nom: "dbscod1", largeur: 100 },
            { nom: "dbsdsc1", largeur: 200 },
            { nom: "dbscod2", largeur: 100 },
            { nom: "dbsdsc2", largeur: 200 },
            { nom: "urgcod", largeur: 100 },
            { nom: "dbcqte", largeur: 100 },
            { nom: "dbcqteaff", largeur: 120 },
            { nom: "bqeafflib", largeur: 100 }
        ]
    };

}
