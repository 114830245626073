import {IComponentController} from 'angular';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMR0159 extends IComponentController {
    formulaireQsd: IFormulaire;
    qdvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0159Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: any,
                                        FormulaireGroupe: IFormulaireGroupeClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMR0159 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireQsd = new Formulaire([
            new FormulaireItem('qsdseqaff', {required: true}),
            new FormulaireItem('qsdflgact'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('qsdnom', {required: true, largeur: 100}),
            new FormulaireItem('qsdtxtqst', {required: true, largeur: 100}),
            new FormulaireItem('qsdtxtint', {largeur: 80}),
            new FormulaireItem('vaetypsys', {largeur: 20}),
            new FormulaireItem('grqcleint', { hidden: true, default: $stateParams.grqcleint }),
            new FormulaireGroupe('GRP_OUI_NON', new Formulaire([
                new FormulaireItem('qsdflgon', { largeur: 15, default: 0 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('qsdflgnepasrep', { largeur: 15, default: 0 }),
                new FormulaireItem('qsdlibnepasrep', { largeur: 55 })
            ])),
            new FormulaireGroupe('GRP_PRECISEZ', new Formulaire([
                new FormulaireItem('qsdtxtintval'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('qsdflgaut', { largeur: 15 }),
                new FormulaireItem('qsdtxtaut', { largeur: 55 })
            ]))
        ], {largeurDefaut: 28});

        // valeurs possibles
        vm.qdvMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'qdvseq', largeur: 110},
                {nom: 'qdvtxtval', largeur: 280},
                {nom: 'qdvtxtexp', largeur: 600}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('qdvseq'   , {required: true, largeur: 20}),
                new FormulaireItem('qdvtxtval', {required: true, largeur: 30}),
                new FormulaireItem('qdvtxtexp', {largeur: 50})
            ])
        }
    }
}
