import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
interface IPageMr0189 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0189Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0189 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            importation: true
        },
        formulaire: new Formulaire([
            new FormulaireItem('racdatvig', { largeur: 15 }),
            new FormulaireItem('carcleint', { largeur: 42 }),
            new FormulaireItem('rsccleint', { largeur: 42 })
        ]),
        colonnesVisibles: [
            { nom: 'carcod', largeur: 100 },
            { nom: 'cardsc', largeur: 280 },
            { nom: 'racdatvig', largeur: 130 },
            { nom: 'rsccod', largeur: 140 },
            { nom: 'rscdsc', largeur: 280 }
        ]
    };
}
