import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import { IFilterDate } from 'angular';


interface IPageAs0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0002Controller(
    as0059Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    dateFilter: IFilterDate
) {
    const vm: IPageAs0002 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: as0059Route.NAME,
        navigateParams: (rowData: any) => ({
            afecleint: (rowData.afecleint),
            datdeb: dateFilter(new Date(), 'yyyy-MM-dd')
        }),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'afecod', largeur: 70 },
            { nom: 'afedsc', largeur: 280 },
            { nom: 'madnummth', largeur: 100 },
            { nom: 'maddsc', largeur: 286 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('afecod'),
            new FormulaireItem('afedsc'),
            new FormulaireItem('madcleint'),
        ], { largeurDefaut: 33.33 })
    };
}