import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGs1005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceGasOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs1005Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs1005 = this;

    vm.multiOccurrenceGasOptions = {
        bloc: 'gas',
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 150 },
            { nom: 'gasconurl', largeur: 462 }
        ],
        pagination: {
            nombreElementPage: 10,
            nbElementsPossibles: [10, 25, 50]
        },
        formulaire: new Formulaire([
            new FormulaireItem('vaevalcle'),
            new FormulaireItem('gasconurl')
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'gidcod', largeur: 110 },
            { nom: 'gidvalurl', largeur: 380 },
            { nom: 'gidconurl', largeur: 380 },
            { nom: 'giddecurl', largeur: 380 },
            { nom: 'vaetypsso', largeur: 140 },
            { nom: 'giddomain', largeur: 140 },
            { nom: 'gidflgact', largeur: 110 },
            { nom: 'gidflgdef', largeur: 110 },
            { nom: 'gidflgrnw', largeur: 200 }
        ],
        pagination: {
            nombreElementPage: 10,
            nbElementsPossibles: [10, 25, 50]
        },
        formulaire: new Formulaire([
            new FormulaireItem('gidcod'),
            new FormulaireItem('gidvalurl', { largeur: 75 }),
            new FormulaireItem('vaetypsso'),
            new FormulaireItem('gidconurl', { largeur: 75 }),
            new FormulaireItem('giddomain'),
            new FormulaireItem('giddecurl', { largeur: 75 }),
            new FormulaireItem('gidflgact'),
            new FormulaireItem('gidflgrnw'),
            new FormulaireItem('gidflgdef')
        ], { largeurDefaut: 25 }),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceGasOptions
    };
}
