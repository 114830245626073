import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
// import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageRr2019 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    fadMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fadMultiOccurrence: IMultiOccurrence 
}

/* @ngInject */
export default function Rr2019Controller(
    Formulaire: IFormulaireClass,
    rr0079Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    $state: IStateService,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass
    ) {
    const vm: IPageRr2019 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemActionImportation(
                'LBL_CHARGEMENT_TXT',
                'BOUCHA',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_TXT',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('faanumcha', {
                                largeur: 100,
                                default: (data: any) => data.monoOccurrence.faanumcha
                            }),
                            new FormulaireItem('ppacleint', {
                                largeur: 100
                            }),
                            new FormulaireItem('tagcleint', {
                                largeur: 100
                            })
                        ])
                    },
                    successAction: () => vm.monoOccurrence.fetchData(),
                    class: 'md-raised ex-button-action',
                    typesFichiers: ['.csv'],
                    iconButton: false,
                    disabled: () => vm.monoOccurrence.data.faanbrlig
                }
            ),
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                        vm.monoOccurrence.once("dataUpdate", () => vm.fddMultiOccurrence.fetchDataList())
                    },
                    disabled: () => (vm.monoOccurrence.data.faaflgval)
                }
            ),           
            new MenuItemAction(
                'LBL_BTN_TRA',
                'BOUTRT',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => vm.monoOccurrence.fetchData(),
                    disabled: () => (vm.monoOccurrence.data.faaflgtrt)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_DEL',
                'BOUDEL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    successAction: () => $state.go(rr0079Route.NAME, {})
                }
            ),
        ],
        formulaire: new Formulaire('LBL_BLC_FAA', [
            new FormulaireItem('faanumcha'),
            new FormulaireItem('faaficsou'),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('faanbrlig')
        ])
    };

    vm.fadMultiOccurrenceOptions = {
        editionRapideActive: false,
        fonctions: {
            editionRapide: () => !(vm.monoOccurrence.data.faaflgtrt),            
            boutonDupliquer: () => !(vm.monoOccurrence.data.faaflgtrt),          
            edition: () => !(vm.monoOccurrence.data.faaflgtrt),
            supprime: () => !(vm.monoOccurrence.data.faaflgtrt),
            nouveau: () => !(vm.monoOccurrence.data.faaflgtrt)
        },
        colonnesFixesGauche: [
            { nom: 'eplnummatr', largeur: 120 },
            { nom: 'eplnomprn', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 100 },
            { nom: 'tagdsc', largeur: 100 },
            { nom: 'ppacod', largeur: 100 },            
            { nom: 'fadmnt', largeur: 60 },
            { nom: 'fadflgerr', largeur: 60 },
            { nom: 'fadmsgerr', largeur: 180 }
        ],
        colonnesEditionRapide: [
            { nom: 'demcleint', largeur: 100 },
            { nom: 'eplnummat', largeur: 100, hidden: true },
            { nom: 'tagcleint', largeur: 100 },
            { nom: 'tagcod', largeur: 100, hidden: true },
            { nom: 'ppacleint', largeur: 100 },
            { nom: 'ppacod', largeur: 100, hidden: true },
            { nom: 'fadmnt', largeur: 100 },
            { nom: 'fadmsgerr', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('tagcleint'), 
            new FormulaireItem('ppacleint'),              
            new FormulaireItem('fadmnt'), 
            FormulaireItemSautDeLigne,   
            new FormulaireItem('fadmsgerr', {largeur: 50})        
        ], { largeurDefaut: 20 })
    };
}
