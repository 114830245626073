import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';


interface IPageTF0025 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence :IMultiOccurrence;
}
/* @ngInject */
export default function Tf0025Controller(Menu: IMenuClass,                                        
                                         MenuItemForage: IMenuItemForageClass,
                                         tf0023Route: IRoute) {
    const vm: IPageTF0025 = this;

    vm.multiOccurrenceOptions = {

        fonctions: {
            rechercheCollapsable: false,
            supprime: (data: any) => data.flgactibloc
        },
         navigatePage: tf0023Route.NAME,
         actionsNouveaux: new Menu([
             new MenuItemForage(tf0023Route.NAME)  ]),
        colonnesFixesGauche: [
            {nom: 'efacod', largeur: 110},
            {nom: 'efanomprn', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'folann', largeur: 75},
            {nom: 'folcod', largeur: 100},
            {nom: 'foldsc', largeur: 262},
            {nom: 'rfecod', largeur: 180},
            {nom: 'rfenomabr', largeur: 262},
            {nom: 'foacod', largeur: 90},
            {nom: 'foadsc', largeur: 262},
            {nom: 'fovcod', largeur: 90},
            {nom: 'fovdsc', largeur: 131},
            {nom: 'tfsvaltxt', largeur: 131},
            {nom: 'tfsflguti', largeur: 76}
        ],
        colonnesCachees: ['flgactibloc']
    };
}
