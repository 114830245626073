import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAc0063 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Ac0063Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    ac0062Route: IRoute,
    ac0005Route: IRoute,
    ac0028Route: IRoute,
    ac0064Route: IRoute
) {
    const vm: IPageAc0063 = this;

    const lcopFou = new MenuItemEcran(
        'LBL_BTN_ACTION',
        ac0062Route.NAME,
        (rowData: any) => ({
            id: rowData.aflcleint,
            foucleint: rowData.foucleint
        }),
        {
            icon: 'launch',
            fonction: 'BOUAFL',
            iconButton: true,
            onClose: () => vm.multiOccurrence.fetchDataList()
        }
    );

    const forageEtc = new MenuItemForage(
        'LBL_BTN_FORAGE_ETC',
        ac0005Route.NAME,
        (rowData: any) => ({
            id: rowData.etccleint
        }),
        {
            icon: 'exit_to_app',
            fonction: 'BOUETC',
            iconButton: true
        }
    );

    const forageApo = new MenuItemForage(
        'LBL_BTN_FORAGE_APO',
        ac0028Route.NAME,
        (rowData: any) => ({
            id: rowData.doccleintapo
        }),
        {
            icon: 'exit_to_app',
            fonction: 'BOUAPO',
            iconButton: true,
            hidden: (rowData: any) => !(rowData.doccleintapo)
        }
    );

    const forageAel = new MenuItemEcran(
        'LBL_BTN_ACTION',
        ac0064Route.NAME,
        (rowData: any) => ({
            id: rowData.aelcleint,
            etccleint: rowData.etccleint
        }),
        {
            icon: 'launch',
            fonction: 'BOUDOC',
            iconButton: true,
            onClose: () => vm.multiOccurrence.fetchDataList(),
            hidden: (rowData: any) =>!(rowData.aflcleint)
        }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datdebcri', { required: true }),
            new FormulaireItem('datfincri', { required: true }),
            new FormulaireItem('vaesysdevcodcri'),
            new FormulaireItem('mntdebcri'),
            new FormulaireItem('mntfincri'),
            new FormulaireItem('foucleintcri'),
            new FormulaireItem('fouflgstucri', { default: 2 }),
            new FormulaireItem('aflflgactcri', { default: 2 }),
            new FormulaireItem('aelflglcocri', { default: 2 })
        ]),
        colonnesFixesGauche: [
            { nom: 'foucod', largeur: 120 },
            { nom: 'flglcopfou', largeur: 80, menu: [lcopFou] },
            { nom: 'aflflgact', largeur: 80 },
            { nom: 'founomabr', largeur: 200 },
            { nom: 'etcnumdoc', largeur: 120, menu: [forageEtc] },
            { nom: 'flglcopetc', largeur: 120, menu: [forageAel] },
        ],
        colonnesVisibles: [
            { nom: 'founom', largeur: 300 },
            { nom: 'etcdsc', largeur: 300 },
            { nom: 'etcdatimp', largeur: 126 },
            { nom: 'etcmnt', largeur: 120 },
            { nom: 'vaesysdevcod', largeur: 120 },
            { nom: 'aponumdoc', largeur: 120, menu: [forageApo] },
            { nom: 'aelmnt', largeur: 120 },
            { nom: 'aelflgren', largeur: 120 },
            { nom: 'aelmntpro', largeur: 120 },
            { nom: 'molcod', largeur: 120 },
            { nom: 'vecmotoct', largeur: 120 },
            { nom: 'vecchofou', largeur: 120 },
            { nom: 'vecregenc', largeur: 120 },
            { nom: 'vecattarq', largeur: 120 },
            { nom: 'aelseao', largeur: 120 },
            { nom: 'aeldatseao', largeur: 126 }
        ],
        colonnesCachees: ['aflcleint', 'foucleint', 'etccleint', 'doccleintapo', 'aelcleint']
    };
}
