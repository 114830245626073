import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';


interface IPageCO0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0022Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
) {

    const vm: IPageCO0022 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur:274},
            {nom: 'corseqaff', largeur: 110},
            {nom: 'comcod', largeur:120},
            {nom: 'comdsc', largeur:274},
            {nom: 'vaecorimp_dsc', largeur:100},
            {nom: 'corflgobl', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('corcleintref',{required: true}),
            new FormulaireItem('comcleint'),
            new FormulaireItem('corseqaff',  {largeur: 25}),
            new FormulaireItem('vaecorimp',{required: true,largeur:25}),
            new FormulaireItem('corflgobl', {largeur: 50}),
        ]),
    };
}










