import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IMc0087Params {
    refcleint: string;
}
interface IPageMc0087 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePdtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0087Controller(Menu: IMenuClass,
                                         $stateParams: IMc0087Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0087 = this;

    vm.multiOccurrenceOptions = {
        filtres:[{
            colonne: 'refcleint',
        valeur: $stateParams.refcleint,
        readOnly: true
        }],
        colonnesVisibles: [
            {nom: 'rcuann', largeur: 100},
            {nom: 'cptcod', largeur: 100},
            {nom: 'cptdsc', largeur: 170},
            {nom: 'fmlcod', largeur: 120},
            {nom: 'fmldsc', largeur: 190},
            {nom: 'rcumntcum', largeur: 100},
            {nom: 'rcumntaju', largeur: 140}
        ],
        colonnesCachees: [
          'refcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', {default: $stateParams.refcleint}),
            new FormulaireItem('rcuann', {largeur: 25}),
            new FormulaireItem('fmccleint', {largeur: 25}),
            new FormulaireItem('rcumntaju', {largeur: 25})

        ]),
    };
}
