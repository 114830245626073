import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMonoOccurrence} from "../../../../../core/services/mono-occurrence.service";
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCP0071 extends IComponentController {
    formulaireFtn: IFormulaire;
    monoOccurrence: IMonoOccurrence;
}
interface ICp0071Params {
    id: string;
    tsfcleint: string;
    trncleint: string;
}

/* @ngInject */
export default function Cp0071Controller($stateParams: ICp0071Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageCP0071 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireFtn = new Formulaire('LBL_CONTACTS', [
            new FormulaireItem('tsfcleint', {
                default: $stateParams.tsfcleint,hidden:true
            }),
            new FormulaireItem('ftntit'),
            new FormulaireItem('ftnnom'),
            new FormulaireItem('ftnflgstu',{default: 1}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ftnnumtel'),
            new FormulaireItem('ftnnumtelpos'),
            new FormulaireItem('ftnnumfax'),
            new FormulaireItem('ftnnumref'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vecsyscodtel'),
            new FormulaireItem('ftnnumtelaut'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ftnadrcou', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ftncom', {largeur: 100, enableCount: true})
        ], {
            largeurDefaut: 25
        });
    }
}
