import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageGb0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0007Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageGb0007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'gfgnumchg', largeur: 50 },
            { nom: 'gfgsrc', largeur: 70 },
            { nom: 'gfgflg', largeur: 10 },
            { nom: 'gfgflgerr', largeur: 10 },
            { nom: 'gfgnbrerr', largeur: 30},
            { nom: 'gfgnbrtot', largeur: 30}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gfgnumchg', { largeur: 40 }),
            new FormulaireItem('gfgsrc', { largeur: 60 }),
            new FormulaireItem('gfgflg', { largeur: 20 }),
            new FormulaireItem('gfgflgerr', { largeur: 20 }),
            new FormulaireItem('gfgnbrerr', { largeur: 30 }),
            new FormulaireItem('gfgnbrtot', { largeur: 30 })
        ])
    };
}
