import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRr0101 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0101Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageRr0101 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            rechercheCollapsable: false

        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_unpcleint', { resourceParams: (data: any) => ({ t_percleint: data.t_percleint }) }),
            new FormulaireItem('t_ppacleint', { required: true, resourceParams: (data: any) => ({ t_unpcleint: data.t_unpcleint }) }),
            new FormulaireItem('t_percleint', { hidden: true }),
            new FormulaireItem('t_type', { default: 'TAG' })
        ]),
        colonnesFixesGauche: [
            { nom: 'unpcod', largeur: 150 },
            { nom: 'unpdsc', largeur: 260 }
        ],
        colonnesVisibles: [
            { nom: 'tagcod_tag', largeur: 110, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'RCE' },
            { nom: 'tagdscabr_tag', largeur: 230, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'RCE' },
            { nom: 'tagcod_avi', largeur: 110, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'RCE' },
            { nom: 'tagdscabr_avi', largeur: 230, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'RCE' },
            { nom: 'patqtecal', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'RCE' },
            { nom: 'patmntcal', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'RCE' },
            { nom: 'paimnt', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'RCE' },
            { nom: 'rcecod', largeur: 140, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' },
            { nom: 'rcedscabr', largeur: 140, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' },
            { nom: 'parmntemp', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' },
            { nom: 'parmntemr', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' },
            { nom: 'parqteadm', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' },
            { nom: 'parmntadm', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' },
            { nom: 'parqtearr', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' },
            { nom: 'parmntarr', largeur: 140, total: true, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'TAG' || vm.multiOccurrence.etatSelected.criteresSuggeresData.t_type === 'AVI' }
        ]
    };
}
