import { IComponentController } from 'angular';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IStateParamsService, IStateService } from 'angular-ui-router';

interface IPageRr0082 extends IComponentController {
    formulaireRee: IFormulaire;
    formulaireDat: IFormulaire;
    formulaireRemAss: IFormulaire;
    formulaireVac: IFormulaire;
    formulaireFer: IFormulaire;
    formulaireConSpe: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rmmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rezMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ppaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Rr0082Controller(rr0081Route: IRoute,
    rr0526Route: IRoute,
    rr0527Route: IRoute,
    rr0082Route:IRoute,
    $state: IStateService,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    Pagination: IPaginationClass,
    $stateParams: IStateParamsService) {

    const vm: IPageRr0082 = this;

    vm.$onInit = $onInit;
    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.btnCalculer = new MenuItemAction(
            'BTN_CALCULER',
            'BOUCAL',
            (data: any) => ({
                tmtcleint: data.tmtcleint
            }),
            {
                iconButton: false,
                icon: 'refresh',
                saveFirst: true,
                disabled: (data: any) => data.reeflggen,
                successAction: (data:any) => {  
                    $state.transitionTo(rr0082Route.NAME, { id: $stateParams.id,  menuId: $stateParams.menuId}) 
                },
                reloadOnError: true
            }
        );

        vm.btnGenere = new MenuItemAction(
            'BTN_GEN_XML',
            'BOUGENXML',
            (data: any) => ({
                reecleint: data.reecleint
            }),
            {
                iconButton: false,
                icon: 'upload_file',
                saveFirst: true,
                disabled: (data: any) => data.reeflggen
            }
        )
        vm.btnAnnuler = new MenuItemAction(
            'BTN_ANNULER',
            'BOUANN',
            (data: any) => ({
                tmtcleint: data.tmtcleint,
                reecleint: data.reecleint
            }),
            {
                iconButton: false,
                icon: 'highlight_off',
                saveFirst: true,
                disabled: (data: any) => !data.reeflggen,
                fonction: 'FCTANN',
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        );

        vm.btnAmender = new MenuItemAction(
            'BTN_AMENDER',
            'BOUAME',
            (data: any) => ({
                tmtcleint: data.tmtcleint
            }),
            {
                iconButton: false,
                saveFirst: true,
                icon: 'content_copy'
            }
        );

        vm.btnAjustement = new MenuItemForage(
            'LBL_BTN_AJUSTEMENT',
            rr0526Route.NAME,
            (data: any) => ({
                demcleint: data.demcleint,
                reecleint: data.reecleint
            }),
            {
                icon: 'edit_note',
                iconButton: false,
                fonction: 'BOUAJU'
            }
        );

        vm.btnHistorique = new MenuItemForage(
            'LBL_BTN_HISTORIQUE',
            rr0527Route.NAME,
            (data: any) => ({
                demcleint: data.demcleint,
                reecleint: data.reecleint
            }),
            {
                icon: 'history',
                iconButton: false,
                fonction: 'BOUHIS'
            }
        );

        vm.monoOccurrenceOptions = {
            pageRetourSuppression: rr0081Route.NAME,
            fonctions: {
                zoneDroiteVisibleCreation: true,
                supprime: (data: any) => !data.reeflggen
            }
        };
        vm.formulaireRee = new Formulaire('LBL_BLC_REE', [
            new FormulaireItem('reenumref'),
            new FormulaireItem('reenumrefmod'),
            new FormulaireItem('reecledat', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('demcleint', { readonly: ($stateParams.id==="" || $stateParams.id===undefined) ? false : true, default: $stateParams.demcleint }),
            new FormulaireItem('reehreass', { readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reemntass', { readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reeflgret', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reeflgdat', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reedatrap', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('vaereemot', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('sigcleint', { required: true, readonly: (data) => data.reeflggen, resourceParams: (data: any) => ({ demcleint: data.demcleint }) }),
            new FormulaireItem('reeproemp', {
                readonly: (data) => data.reeflggen,
                menu: [new MenuItemAction(
                    'LBL_MODIF_TEM',
                    'BOUTEM',
                    (data: any) => ({
                        demcleint: data.demcleint,
                        reedatemb: data.reedatemb
                    }),
                    {
                        icon: 'rate_review',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_MODIF_TEM',
                            lblConfirm: 'G_LBL_BTN_OK',
                            largeurFormulaire: 25,
                            formulaire: new Formulaire([
                                new FormulaireItem('temcleint', {
                                    largeur: 100,
                                    resourceParams: (data: any) => ({
                                        demcleint: data.demcleint,
                                        reedatemb: data.reedatemb
                                    })
                                })
                            ])
                        },
                        successAction: (retour: any) => retour.reeproemp,
                        champsRechargement: ['reeproemp'],
                        disabled: (data: any) => data.reeflggen || (!data.demcleint || !data.reedatemb)
                    }
                )]
            }),
            new FormulaireItem('reeobs', { readonly: (data) => data.reeflggen, largeur: 66.66 }),
            new FormulaireItem('rdwcleint'),
            new FormulaireItem('reecom', { largeur: 100, enableCount: true })
        ], { largeurDefaut: 33.33 });

        vm.formulaireDat = new Formulaire('LBL_BLC_DAT', [
            new FormulaireItem('reedatemb', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reedatderjrs', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reedatderpep', { required: true, readonly: (data) => data.reeflggen }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('reeflggen', { readonly: true })
        ]);

        vm.formulaireRemAss = new Formulaire('LBL_BLC_REMASS', [
            new FormulaireItem('reerem01', { hidden: !vm.valeursEcran.flgaffrem01, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem02', { hidden: !vm.valeursEcran.flgaffrem02, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem03', { hidden: !vm.valeursEcran.flgaffrem03, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem04', { hidden: !vm.valeursEcran.flgaffrem04, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem05', { hidden: !vm.valeursEcran.flgaffrem05, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem06', { hidden: !vm.valeursEcran.flgaffrem06, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem07', { hidden: !vm.valeursEcran.flgaffrem07, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem08', { hidden: !vm.valeursEcran.flgaffrem08, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem09', { hidden: !vm.valeursEcran.flgaffrem09, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem10', { hidden: !vm.valeursEcran.flgaffrem10, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem11', { hidden: !vm.valeursEcran.flgaffrem11, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem12', { hidden: !vm.valeursEcran.flgaffrem12, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem13', { hidden: !vm.valeursEcran.flgaffrem13, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem14', { hidden: !vm.valeursEcran.flgaffrem14, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem15', { hidden: !vm.valeursEcran.flgaffrem15, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem16', { hidden: !vm.valeursEcran.flgaffrem16, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem17', { hidden: !vm.valeursEcran.flgaffrem17, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem18', { hidden: !vm.valeursEcran.flgaffrem18, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem19', { hidden: !vm.valeursEcran.flgaffrem19, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem20', { hidden: !vm.valeursEcran.flgaffrem20, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem21', { hidden: !vm.valeursEcran.flgaffrem21, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem22', { hidden: !vm.valeursEcran.flgaffrem22, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem23', { hidden: !vm.valeursEcran.flgaffrem23, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem24', { hidden: !vm.valeursEcran.flgaffrem24, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem25', { hidden: !vm.valeursEcran.flgaffrem25, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem26', { hidden: !vm.valeursEcran.flgaffrem26, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem27', { hidden: !vm.valeursEcran.flgaffrem27, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem28', { hidden: !vm.valeursEcran.flgaffrem28, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem29', { hidden: !vm.valeursEcran.flgaffrem29, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem30', { hidden: !vm.valeursEcran.flgaffrem30, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem31', { hidden: !vm.valeursEcran.flgaffrem31, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem32', { hidden: !vm.valeursEcran.flgaffrem32, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem33', { hidden: !vm.valeursEcran.flgaffrem33, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem34', { hidden: !vm.valeursEcran.flgaffrem34, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem35', { hidden: !vm.valeursEcran.flgaffrem35, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem36', { hidden: !vm.valeursEcran.flgaffrem36, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem37', { hidden: !vm.valeursEcran.flgaffrem37, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem38', { hidden: !vm.valeursEcran.flgaffrem38, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem39', { hidden: !vm.valeursEcran.flgaffrem39, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem40', { hidden: !vm.valeursEcran.flgaffrem40, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem41', { hidden: !vm.valeursEcran.flgaffrem41, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem42', { hidden: !vm.valeursEcran.flgaffrem42, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem43', { hidden: !vm.valeursEcran.flgaffrem43, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem44', { hidden: !vm.valeursEcran.flgaffrem44, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem45', { hidden: !vm.valeursEcran.flgaffrem45, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem46', { hidden: !vm.valeursEcran.flgaffrem46, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem47', { hidden: !vm.valeursEcran.flgaffrem47, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem48', { hidden: !vm.valeursEcran.flgaffrem48, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem49', { hidden: !vm.valeursEcran.flgaffrem49, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem50', { hidden: !vm.valeursEcran.flgaffrem50, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem51', { hidden: !vm.valeursEcran.flgaffrem51, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem52', { hidden: !vm.valeursEcran.flgaffrem52, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) }),
            new FormulaireItem('reerem53', { hidden: !vm.valeursEcran.flgaffrem53, readonly: (data: any) => (!vm.valeursEcran.flgmodmnt || data.reeflggen) })
        ], { largeurDefaut: 5 });

        vm.formulaireVac = new Formulaire('LBL_BLC_VAC', [
            new FormulaireItemDivider("LBL_DIV_VACANCES"),
            new FormulaireItem('vaereevac1', { readonly: (data) => data.reeflggen, largeur: 35, libellesContour: { gauche: { largeur: 80, texte: 'VAC1' } } }),
            new FormulaireItem('reedatdebvac1', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reedatfinvac1', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reemntvac1', { readonly: (data) => data.reeflggen }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaereevac2', { readonly: (data) => data.reeflggen, largeur: 35, libellesContour: { gauche: { largeur: 80, texte: 'VAC2' } }, titre: " " }),
            new FormulaireItem('reedatdebvac2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfinvac2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemntvac2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaereevac3', { readonly: (data) => data.reeflggen, largeur: 35, libellesContour: { gauche: { largeur: 80, texte: 'VAC3' } }, titre: " " }),
            new FormulaireItem('reedatdebvac3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfinvac3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemntvac3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaereevac4', { readonly: (data) => data.reeflggen, largeur: 35, libellesContour: { gauche: { largeur: 80, texte: 'VAC4' } }, titre: " " }),
            new FormulaireItem('reedatdebvac4', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfinvac4', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemntvac4', { readonly: (data) => data.reeflggen, titre: " " }),

            new FormulaireItemDivider("LBL_DIV_AUTRES"),
            new FormulaireItem('vaereedsc1', { readonly: (data) => data.reeflggen, largeur: 35, libellesContour: { gauche: { largeur: 80, texte: 'AUT1' } } }),
            new FormulaireItem('reedatdeb1', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reedatfin1', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reemnt1', { readonly: (data) => data.reeflggen }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaereedsc2', { readonly: (data) => data.reeflggen, largeur: 35, libellesContour: { gauche: { largeur: 80, texte: 'AUT2' } }, titre: " " }),
            new FormulaireItem('reedatdeb2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfin2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemnt2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaereedsc3', { readonly: (data) => data.reeflggen, largeur: 35, libellesContour: { gauche: { largeur: 80, texte: 'AUT3' } }, titre: " " }),
            new FormulaireItem('reedatdeb3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfin3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemnt3', { readonly: (data) => data.reeflggen, titre: " " })
        ], { largeurDefaut: 20 });

        vm.formulaireFer = new Formulaire('LBL_BLC_FER', [
            new FormulaireItem('reedatfer1', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER1' } } }),
            new FormulaireItem('reemntfer1', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reedatfer2', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER2' } }, titre: " " }),
            new FormulaireItem('reemntfer2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer3', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER3' } }, titre: " " }),
            new FormulaireItem('reemntfer3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer4', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER4' } }, titre: " " }),
            new FormulaireItem('reemntfer4', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer5', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER5' } }, titre: " " }),
            new FormulaireItem('reemntfer5', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer6', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER6' } }, titre: " " }),
            new FormulaireItem('reemntfer6', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer7', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER7' } }, titre: " " }),
            new FormulaireItem('reemntfer7', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer8', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER8' } }, titre: " " }),
            new FormulaireItem('reemntfer8', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer9', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER9' } }, titre: " " }),
            new FormulaireItem('reemntfer9', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfer10', { readonly: (data) => data.reeflggen, largeur: 25, libellesContour: { gauche: { largeur: 55, texte: 'FER10' } }, titre: " " }),
            new FormulaireItem('reemntfer10', { readonly: (data) => data.reeflggen, titre: " " })
        ], { largeurDefaut: 25 });

        vm.formulaireConSpe = new Formulaire('LBL_BLC_CONSPE', [
            new FormulaireItem('vaereetyp', { readonly: (data) => data.reeflggen, largeur: 30, libellesContour: { gauche: { largeur: 150, texte: 'COG1' } } }),
            new FormulaireItem('reedatcog', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reedatfincog', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('reemntcog', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('vaereejrs', { readonly: (data) => data.reeflggen }),
            new FormulaireItem('vaereetyp2', { readonly: (data) => data.reeflggen, largeur: 30, libellesContour: { gauche: { largeur: 150, texte: 'COG2' } }, titre: " " }),
            new FormulaireItem('reedatcog2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfincog2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemntcog2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('vaereejrs2', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('vaereetyp3', { readonly: (data) => data.reeflggen, largeur: 30, libellesContour: { gauche: { largeur: 150, texte: 'COG3' } }, titre: " " }),
            new FormulaireItem('reedatcog3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfincog3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemntcog3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('vaereejrs3', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('vaereetyp4', { readonly: (data) => data.reeflggen, largeur: 30, libellesContour: { gauche: { largeur: 150, texte: 'COG4' } }, titre: " " }),
            new FormulaireItem('reedatcog4', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reedatfincog4', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('reemntcog4', { readonly: (data) => data.reeflggen, titre: " " }),
            new FormulaireItem('vaereejrs4', { readonly: (data) => data.reeflggen, titre: " " })
        ], { largeurDefaut: 15 });

        vm.rmmMultiOccurrenceOptions = {
            bloc: 'rmm',
            fonctions: {
                recherche: false,
                afficherMenuSettingDansEntete: false
            },
            colonnesVisibles: [
                { nom: 'rmmmsg', largeur: 500 }
            ]
        };

        vm.rezMultiOccurrenceOptions = {
            bloc: 'rez',
            fonctions: {
                pagination: true,
                suiviModification: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                'vaereehis_dsc',
                'reenumref',
                'usrnomcre',
                'datcre'
            ]
        };

        vm.ppaMultiOccurrenceOptions = {
            bloc: 'ppa',
            colonnesVisibles: [
                { nom: 'ppacodori', largeur: 100 },
                { nom: 'patdatdeb', largeur: 110 },
                { nom: 'patdatfin', largeur: 110 },
                { nom: 'tagcod', largeur: 110 },
                { nom: 'tagdscabr', largeur: 357 },
                { nom: 'patqtecal', largeur: 100 },
                { nom: 'patmntcal', largeur: 140 },
                { nom: 'ppacod', largeur: 100 }
            ],
            colonnesFixesDroite: [
                { nom: 'flghreadm', largeur: 74 },
                { nom: 'flgmntadm', largeur: 74 }
            ]
        };
    }
}

