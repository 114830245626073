import {IComponentController} from 'angular';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePb0025 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePbg: IFormulaire;
}


/* @ngInject */
export default function Pb0025Controller() {
    const vm: IPagePb0025 = this;

    vm.$onInit = $onInit;

    function $onInit() {
    }
}
