import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCR0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0006Controller(Menu: IMenuClass,    
                                         cr0005Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass ) {
    const vm: IPageCR0006 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cr0005Route.NAME,
        navigateParams: () => (
            {
                fonction: 'BOUOUV'
            }
        ),
        actionsNouveaux: new Menu([
            new MenuItemForage(cr0005Route.NAME,
                () => ({}),
            {
                fonction: 'BOUNOUV'
            })            
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'clicod', largeur: 90},
            {nom: 'clinom', largeur: 300},
            {nom: 'clinomabr', largeur: 200},
            {nom: 'clycod', largeur: 80},
            {nom: 'clydsc', largeur: 200},
            {nom: 'crpcod', largeur: 80},
            {nom: 'crpdsc', largeur: 200},
            {nom: 'vaesysdevcod_dsc', largeur: 120},
            {nom: 'vecsyslng_dsc', largeur: 120},
            {nom: 'cliflgstu', largeur: 90},
            {nom: 'clidatdeb', largeur: 126},
            {nom: 'clidatfin', largeur: 126},
            {nom: 'vectypapp', largeur: 100},
            {nom: 'vectypapp_dsc', largeur: 180},
            {nom: 'climntcar', largeur: 120},
            {nom: 'climntnap', largeur: 120}
        ]
    };
}
