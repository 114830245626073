import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';

interface IPageCH0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Ch0009Controller(Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass,) {
    const vm: IPageCH0009 = this;

    vm.multiOccurrenceOptions = {
        actionsSelection: new Menu([
            // Action Envoyer appel
            new MenuItemSelection('G_LBL_ENVOYER_APPEL', 'BOUENVAPP', {
                selection: {
                    nom: 'envoyerAppel'
                }, successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            }),
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 260},
            {nom: 'haeflgempinf', largeur: 160},
            {nom: 'ntnflglu', largeur: 160},
            {nom: 'tel_cel', largeur: 140},
            {nom: 'tel_dom', largeur: 140}
        ],
        colonnesCachees: [
            'demcleint'
        ]
    };
}
