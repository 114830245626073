import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFilterExDate} from '../../../../../core/filters/ex-date.filter';

interface IPageRr0550{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    rprMultiOccurrence: IMultiOccurrence;

}
/* @ngInject */
export default function Rr0550Controller(rr0549Route: IRoute,
                                         Menu: IMenuClass,
                                         exDateFilter: IFilterExDate,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageRr0550 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: rr0549Route.NAME,
        navigateParams: (data: any) => ({
            pgpcleint: data.pgpcleint,
            rprdatpai: exDateFilter(data.rprdatpai)
        }),
        fonctions: {
            suiviModification: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0549Route.NAME)
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_SUPPRIMER', 'BOUSUP', {
                selection: {nom: 'supprimer'},
                successAction: () => {
                    vm.rprMultiOccurrence.fetchDataList();
                }
            })
        ]),
        colonnesCachees: ['pgpcleint'],
        colonnesVisibles: [
            {nom: 'pgpcod', largeur: 100},
            {nom: 'pgpdsc', largeur: 470},
            {nom: 'vaedsc', largeur: 160},
            {nom: 'rprdatpai', largeur: 130}
        ]
    };
}
