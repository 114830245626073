import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePd0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0016Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePd0016 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'tybcod', largeur: 112},
            {nom: 'tybdsc', largeur: 330},
            {nom: 'tybnbrmoi', largeur: 92},
            {nom: 'tybflgeli', largeur: 74}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tybcod', {required: true}),
            new FormulaireItem('tybdsc', {required: true}),
            new FormulaireItem('tybnbrmoi', {required: true}),
            new FormulaireItem('tybflgeli')
        ])
    };
}
