import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass, MenuElement} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';
import {IStateService} from 'angular-ui-router';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0114 extends IComponentController {
    btnAction: Array<MenuElement>;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireCdt: IFormulaire;
    valeursEcran: any;
    tagMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cdcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceTag: IMultiOccurrence;
}

interface IMr0114Params {
    id: string;
    menuId: string;
}

/* @ngInject */
export default function Mr0114Controller(mr0114Route: IRoute,
                                         mr0238Route: IRoute,
                                         mr0312Route: IRoute,
                                         $stateParams: IMr0114Params,
                                         $state: IStateService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass) {

    const vm: IPageMr0114 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.btnAction = [
            new MenuItemRecuperation({
                nom: 'recuperation_tag',
                lblTitre: 'RECUPERATION_TAG',
                icon: 'content_copy',
                iconButton: false,
                secondaire: true,
                colonnesVisibles: [
                    {nom: 'tagcod'   , largeur: 110},
                    {nom: 'tagdscabr', largeur: 262}
                ],
                resourceParams: {
                    cdtcleint: $stateParams.id
                },
                successAction: () => {
                    vm.multiOccurrenceTag.fetchDataList();
                }
            }),
            new MenuItemAction(
                'LBL_COPIE',
                'BOUCOP',
                () => ({}),
                {
                    icon: 'content_copy',
                    iconButton: false,
                    secondaire: true,
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_COPIE',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_colcleint', { largeur: 100 }),
                            new FormulaireItem('t_cdtdatvig'),
                            new FormulaireItem('t_criadd'),
                            new FormulaireItem('t_cdtdoc', { largeur: 100, enableCount: true })
                        ])
                    },
                    successAction: (retour: any) => {
                        $state.go(mr0114Route.NAME, {
                            id: retour.seq_cdtcleint,
                            menuId: $stateParams.menuId
                        });
                    },
                }
            ),
            new MenuItemEcran(
                'LBL_COMPARE',
                mr0312Route.NAME,
                () => ({}),
                {
                    icon: 'assessment',
                    iconButton: false,
                    secondaire: true
                }
            )
        ];

        vm.monoOccurrenceOptions = {
            pageRetourSuppression: mr0238Route.NAME
        };

        vm.formulaireCdt = new Formulaire('LBL_BLC_CDT', [
            new FormulaireItem('colcleint', { largeur: 50 }),
            new FormulaireItem('cdtdatvig'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cdtdoc'   , { largeur: 100, enableCount: true })
        ], { largeurDefaut: 25 });

        // TAG
        vm.tagMultiOccurrenceOptions = {
            bloc: 'tag',
            colonnesVisibles: [
                {nom: 'tagcod'   , largeur: 100},
                {nom: 'tagdscabr', largeur: 275},
                {nom: 'tagprical', largeur: 100},
                {nom: 'admflgmnt', largeur: 100},
                {nom: 'admflgqte', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint', { largeur: 40, resourceParams: () => ({ cdtcleint: $stateParams.id }) }),
                new FormulaireItem('admflgmnt', { default: 1 }),
                new FormulaireItem('admflgqte', { default: 1 })
            ], { largeurDefaut: 20 })
        };

        // Critères additionnels
        vm.cdcMultiOccurrenceOptions = {
            bloc: 'cdc',
            colonnesVisibles: [
                {nom: 'urgcod'   , largeur: 130},
                {nom: 'urgdscabr', largeur: 236},
                {nom: 'strcod'   , largeur: 130},
                {nom: 'strdsc'   , largeur: 236},
                {nom: 'tafcod'   , largeur: 130},
                {nom: 'tafdsc'   , largeur: 236}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint'),
                new FormulaireItem('strcleint'),
                new FormulaireItem('tafcleint')
            ])
        };
    }

}
