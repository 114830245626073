import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCc0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cc0027Controller(
    accueilRoute: IRoute,
    cc0021Route: IRoute,
    /* cc0026Route: IRoute, */
    cc0028Route: IRoute,
    $stateParams: IStateParamsService,
    exDateFilter: IFilterExDate,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageCc0027 = this;

    vm.multiOccurrenceOptions = {
        filtres: [
            { colonne: 'crscleint', valeur: $stateParams.crscleint,
               visible: false }
            ],
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('date', {
                    default: exDateFilter(new Date()),
                    ignoreEtat: true,
                    required: true
                }),
                new FormulaireItem('demcleint'),
                new FormulaireItem('urgcleint')
            ],
            { largeurDefaut: 33 }
        ),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 262 }
        ],
        colonnesVisibles: [
            { nom: 'crscod', largeur: 120 },
            { nom: 'crsdsc', largeur: 262 },
            { nom: 'flg_fer', largeur: 72 },
            {
                nom: 'flg_qualif',
                largeur: 100,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_CC0021',
                        cc0021Route.NAME,
                        (data: any) => {
                            return {
                                ecran: '00-2',
                                demcleint: data.demcleint
                            };
                        },
                        {
                            fonction: 'BOUQUA'
                        }
                    )
                ]
            },
            { nom: 'anc_crs_hre', largeur: 126 },
            {
                nom: 'anc_crs_uni',
                largeur: 126,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_CC0026',
                        accueilRoute.NAME,
                        (data: any) => {
                            return {
                                ecran: '00-2',
                                demcleint: data.demcleint,
                                crscleint: data.crscleint,
                                urgcleint: data.urgcleint
                            };
                        },
                        {
                            fonction: 'BOUMOU'
                        }
                    )
                ]
            },
            { nom: 'anc_global_hre', largeur: 126 },
            { nom: 'anc_global_uni', largeur: 126 },
            {
                nom: 'flg_trf',
                largeur: 100,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_CC0028',
                        cc0028Route.NAME,
                        (data: any) => {
                            return {
                                ecran: '00-2',
                                crscleintori: data.crscleint
                            };
                        },
                        {
                            fonction: 'BOUTRF'
                        }
                    )
                ]
            },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdscabr', largeur: 230 }
        ],
        colonnesCachees: ['demcleint', 'crscleint', 'urgcleint']
    };
}
