import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0120{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0120Controller(mr0114Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0120 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0114Route.NAME)
        ]),
        navigatePage: mr0114Route.NAME,
        colonnesVisibles: [
            {nom: 'colcod', largeur: 125},
            {nom: 'coldsc', largeur: 262},
            {nom: 'cdtdatvig', largeur: 100}
        ]
    };
}
