import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IInfosMonetaires } from '../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePf0006 extends IComponentController {
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    menus: Array<IMenuItem>;
    ruoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rubMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ruoMultiOccurrence: IMultiOccurrence;
    rubMultiOccurrence: IMultiOccurrence;
    isMenuShown: boolean;
    isMenuCardHidden(): boolean;
}

/* @ngInject */
export default function Pf0006Controller(pf0015Route: IRoute,
    pf0016Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPagePf0006 = this;

    vm.$onInit = $onInit;

    vm.menus = [
        new MenuItemAction(
            'LBL_BTN_GENUNO',
            'BOUGENUNO',
            () => ({}),
            {
                icon: 'sync',
                iconButton: false,
                saveFirst: true,
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        ),
        new MenuItemAction(
            'LBL_BTN_GENCAB',
            'BOUGENCAB',
            () => ({}),
            {
                icon: 'sync',
                iconButton: false,
                saveFirst: true,
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        ),
        new MenuItemAction(
            'LBL_BTN_REPUNO',
            'BOUREPUNO',
            () => ({}),
            {
                icon: 'sync',
                iconButton: false,
                saveFirst: true,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_REPARTIR_UNO',
                    lblConfirm: 'G_LBL_BTN_EXECUTER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItem('recalcul', { default: 'PCT', largeur: 100 })
                    ])
                }
            }
        ),
        new MenuItemAction(
            'LBL_BTN_REPCAB',
            'BOUREPCAB',
            () => ({}),
            {
                icon: 'sync',
                iconButton: false,
                saveFirst: true,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_REPARTIR_CAB',
                    lblConfirm: 'G_LBL_BTN_EXECUTER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItem('recalcul', { default: 'PCT', largeur: 100 })
                    ])
                }
            }
        )
    ];

    function $onInit() {
        // Information monétaire
        vm.monoOccurrenceInfosMonetaire = {
            largeurTexte: 60,
            blocs: ['rub', 'ruo'],
            donnees:
                [
                    { nom: 'uno', libelle: 'LBL_UNO_TOTAL' },
                    { nom: 'cab', libelle: 'LBL_CAB_TOTAL' }
                ]
        };

        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonDupliquer: false
            },
            formulaire: new Formulaire('LBL_BLC_RBU', [
                new FormulaireItem('rbuannfin'),
                new FormulaireItem('urgcleint'),
                new FormulaireItem('rbumnttot'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rbudatdeb'),
                new FormulaireItem('rbudatfin')
            ], { largeurDefaut: 50 })
        }
    }

    // Répartition unité administrative RH
    vm.ruoMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_ANALYSE_RUO',
                pf0015Route.NAME,
                (data: any) => ({
                    rbucleintpar: data.rbucleint
                }),
                {
                    icon: 'assessment',
                    fonction: 'BOUANARUO',
                    iconButton: false
                }
            )
        ]),
        bloc: 'ruo',
        fonctions: {
            editionRapide: true
        },
        editionRapideActive: true,
        colonnesVisibles: [
            { nom: 'unocod', largeur: 150 },
            { nom: 'unodscabr', largeur: 220 },
            { nom: 'ruopctbdg', largeur: 100 },
            { nom: 'ruomntbdg', largeur: 126 }
        ],
        colonnesEditionRapide: [
            { nom: 'unocleint', largeur: 400 },
            { nom: 'ruopctbdg', largeur: 100 },
            { nom: 'ruomntbdg', largeur: 126 }
        ],
        colonnesCachees: [ 'rbucleint' ],
        formulaire: new Formulaire([
            new FormulaireItem('unocleint', { largeur: 100 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ruopctbdg'),
            new FormulaireItem('ruomntbdg')
        ])
    };

    // Répartition Catégorie budgétaire
    vm.rubMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_ANALYSE_CAB',
                pf0016Route.NAME,
                (data: any) => ({
                    rbucleintpar: data.rbucleint
                }),
                {
                    icon: 'assessment',
                    fonction: 'BOUANACAB',
                    iconButton: false
                }
            )
        ]),
        bloc: 'rub',
        fonctions: {
            editionRapide: true
        },
        editionRapideActive: true,
        colonnesVisibles: [
            { nom: 'cabcod', largeur: 150 },
            { nom: 'cabdsc', largeur: 220 },
            { nom: 'rubpctbdg', largeur: 100 },
            { nom: 'rubmntbdg', largeur: 126 }
        ],
        colonnesEditionRapide: [
            { nom: 'cabcleint', largeur: 400 },
            { nom: 'rubpctbdg', largeur: 100 },
            { nom: 'rubmntbdg', largeur: 126 }
        ],
        colonnesCachees: [ 'rbucleint' ],
        formulaire: new Formulaire([
            new FormulaireItem('cabcleint', { largeur: 100 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rubpctbdg'),
            new FormulaireItem('rubmntbdg')
        ])
    };
}
