import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IPaginationClass } from "../../../../../core/services/pagination.service";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";

interface IPageSo0011 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    hieMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function So0011Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    so0007Route: IRoute,
    $stateParams: any,
    Pagination: IPaginationClass,
    MenuItemForage: IMenuItemForageClass) {

    const vm: IPageSo0011 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_STP', [
                new FormulaireItem('poscleint', { largeur: 66.66, readonly: true }),
                new FormulaireItem('nircleint', { largeur: 50, readonly: true }),
                new FormulaireItem('stpcom', { largeur: 66.66 }),
            ])
        }

        // Postes liés
        vm.hieMultiOccurrenceOptions = {
            bloc: 'hie',
            editionRapideActive: true,
            fonctions: {
                pagination: true,
                editionRapide: true
            },
            pagination: new Pagination({
                nombreElementPage: 10,
                nbElementsPossibles: [10, 20, 50]
            }),
            formulaire: new Formulaire([
                new FormulaireItem('poscleintsup', { default: () => vm.monoOccurrence.data.poscleint, hidden: true }),
                new FormulaireItem('stocleint', { default: $stateParams.stocleint, hidden: true }),
                new FormulaireItem('poscleint', {
                    largeur: 50,
                    readonly: (data) => data.stpcleint,
                    resourceParams: () => ({ stocleint: $stateParams.stocleint, poscleintsup: vm.monoOccurrence.data.poscleint })
                }),
                new FormulaireItem('nircleint', { largeur: 50, readonly: (data) => data.stpcleint, resourceParams: () => ({ nirniv: vm.monoOccurrence.data.nirniv }) }),
                new FormulaireItem("titulaire", { largeur: 100, readonly: true }),
                new FormulaireItem('stpcom', {
                    largeur: 100
                })
            ]),
            colonnesEditionRapide: [
                { nom: 'poscleintsup', largeur: 300, hidden: true, default: () => vm.monoOccurrence.data.poscleint },
                { nom: 'stocleint', largeur: 300, hidden: true, default: $stateParams.stocleint },
                { nom: 'poscleint', largeur: 300 },
                { nom: 'nircleint', largeur: 300 },
                { nom: "titulaire", largeur: 200 },
                { nom: 'stpcom', largeur: 200 }
            ],
            colonnesVisibles: [
                {
                    nom: 'posnumdoc',
                    largeur: 100,
                    menu: [
                        new MenuItemForage(
                            'LBL_FORAGE_POS',
                            so0007Route.NAME,
                            (data: any) => ({ id: data.trncleint_pos }),
                            {
                                disabled: (data: any) => !data.posnumdoc
                            }
                        )
                    ]
                },
                { nom: 'postitabr', largeur: 200 },
                { nom: 'nirniv', largeur: 100 },
                { nom: 'nirdsc', largeur: 200 },
                { nom: "titulaire", largeur: 200 },
                { nom: 'stpcom', largeur: 200 }
            ],
            colonnesCachees: [
                'poscleint',
                'trncleint_pos'
            ]
        };
    }
}
