import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IComponentController} from 'angular';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";

interface IPageIF0014 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    itdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    itdMultiOccurrence: IMultiOccurrence;
}

interface IIf0014Params {
    itdcleintgen: string;
    itscleint: string;
}

/* @ngInject */
export default function If0014Controller($stateParams: IIf0014Params,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageIF0014 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Le filtre dépend si l'écran est appelé par la sélection pour le forage
        let filtre;
        if ($stateParams.itdcleintgen) {
            filtre = [
                {
                    colonne: 'itdcleint',
                    operateur: Operateur.EGALE,
                    valeur: ($stateParams.itdcleintgen).split(','),
                    visible: false
                }
            ]
        }
        else {
            filtre = [
                {
                    colonne: 'itscleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.itscleint,
                    visible: false
                }
            ];
        }

        vm.itdMultiOccurrenceOptions = {
            filtres: filtre,
            actionsNouveaux: new Menu([
                new MenuItemAction(
                    'BTN_CREER',
                    'BOUCRE',
                    () => ({
                        itscleint: $stateParams.itscleint
                    }),
                    {
                        icon: '',
                        formulaireConfirmationAction: {
                            lblTitre: 'BTN_CREER',
                            lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                            largeurFormulaire: 50,
                            formulaire: new Formulaire([
                                new FormulaireItem('immcodnew'),
                                new FormulaireItemSautDeLigne(),
                                new FormulaireItem('catcleint'),
                                new FormulaireItem('vecimmtypacq'),
                                new FormulaireItemSautDeLigne(),
                                new FormulaireItem('itddsc', {largeur: 100}),
                                new FormulaireItemSautDeLigne(),
                                new FormulaireItem('itsmnt', {default: vm.valeursEcran.itsmntref}),
                                new FormulaireItem('datmes'),
                                new FormulaireItem('iopcleint')
                            ])
                        },
                        successAction: () => {
                            vm.itdMultiOccurrence.fetchDataList();
                        }
                    }
                ),
                new MenuItemAction(
                    'BTN_ASSOCIER',
                    'BOUASS',
                    () => ({
                        itscleint: $stateParams.itscleint
                    }),
                    {
                        icon: '',
                        formulaireConfirmationAction: {
                            lblTitre: 'BTN_ASSOCIER',
                            lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                            largeurFormulaire: 50,
                            formulaire: new Formulaire([
                                new FormulaireItem('immcleint'),
                                new FormulaireItem('itddsc'),
                                new FormulaireItemSautDeLigne(),
                                new FormulaireItem('itsmnt', {default: vm.valeursEcran.itsmntref}),
                                new FormulaireItem('datmes')
                            ])
                        },
                        successAction: () => {
                            vm.itdMultiOccurrence.fetchDataList();
                        }
                    }
                ),
                new MenuItemAction(
                    'BTN_ANNULER',
                    'BOUANN',
                    () => ({
                        itscleint: $stateParams.itscleint
                    }),
                    {
                        icon: '',
                        formulaireConfirmationAction: {
                            lblTitre: 'BTN_ANNULER',
                            lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                            largeurFormulaire: 50,
                            formulaire: new Formulaire([
                                new FormulaireItem('itddsc'),
                                new FormulaireItem('itsmnt', {default: vm.valeursEcran.itsmntref})
                            ])
                        },
                        successAction: () => {
                            vm.itdMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'vaeitdact_desc', largeur: 90},
                {nom: 'immcleintcod', largeur: 130},
                {nom: 'immdsc', largeur: 225},
                {nom: 'catcod', largeur: 100},
                {nom: 'catdsc', largeur: 225},
                {nom: 'itdmnt', largeur: 120},
                {nom: 'itddatmes', largeur: 100},
                {nom: 'vecimmtypacqcod', largeur: 120},
                {nom: 'vecimmtypacq_dsc', largeur: 225},
                {nom: 'iopcod', largeur: 125},
                {nom: 'iopdscabr', largeur: 225},
                {nom: 'itddsc', largeur: 225}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('immcleint'),
                new FormulaireItem('immcleintcod'),
                new FormulaireItem('catcleint'),
                new FormulaireItem('itdmnt'),
                new FormulaireItem('itddatmes'),
                new FormulaireItem('vecimmtypacq'),
                new FormulaireItem('iopcleint'),
                new FormulaireItem('itddsc')
            ]),
            colonnesCachees: [
                'itdcleint',
                'itscleint'
            ]
        };
    }
}
