import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageAl0012 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireSrc: IFormulaire;
    formulaireSecuriteAccess: IFormulaire;
    prfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rceMultiOccurrenceOptions: IMultiOccurrenceOptions;
    apcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Al0012Controller(Menu: IMenuClass,
                                         mr0008Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAl0012 = this;

    vm.$onInit = $onInit;

    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulaireSrc = new Formulaire('LBL_BLC_TDL', [
            new FormulaireItem('tdlcod'),
            new FormulaireItem('tdldsc'),
            new FormulaireItem('vaetdltyp'),
            new FormulaireItem('qstcleint'),
            new FormulaireItem('ttccleint'),
            new FormulaireItem('flgvcl', {largeur: 33}),
            new FormulaireItem('tdlflgabs', {largeur: 33}),
            new FormulaireItem('tdlflgact', {largeur: 33})
        ], {largeurDefaut: 50});

        vm.prfMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
            },
            colonnesVisibles: [
                {nom: 'prfcod', largeur: 100},
                {nom: 'prfdsc', largeur: 180}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('prfcleint', {
                    resourceParams: (data: any) => ({vaetdltyp: data.$ancetre.vaetdltyp})
                })
            ], {largeurDefaut: 33})
        };

        vm.rceMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'rcecod', largeur: 100},
                {nom: 'rcedsc', largeur: 170}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rcecleint')
            ], {largeurDefaut: 25})
        };

        vm.apcMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            navigatePage: mr0008Route.NAME,
            navigateParams: (rowData: any) => (
                {
                    id: rowData.apacleint,
                    cleintref:  rowData.apccleint,
                    stamnetab:  'APC',
                    vaesystypavi: rowData.vaesystypavi,
                    supprime: 1,
                    vaebmtent: 'DAL',
                    typsai: 'JRS'
                }
            ),
            actionsNouveaux: new Menu([
                new MenuItemForage(mr0008Route.NAME, (rowData: any) => {
                    return {
                        cleintref: vm.monoOccurrence.data.apccleint,
                        stamnetab: 'APC',
                        vaesystypavi: vm.monoOccurrence.data.vaesystypavi,
                        supprime: 1,
                        vaebmtent: 'DAL',
                        typsai: 'JRS'
                    };
                })
            ]),
            bloc: 'apc',
            colonnesVisibles: [
                {nom: 'vaedsc', largeur: 200},
                {nom: 'pfcdsc', largeur: 180},
                {nom: 'vacalityp_cle', largeur: 140},
                {nom: 'vacalityp_dsc', largeur: 210},
                {nom: 'indflgavi', largeur: 74},
                {nom: 'txtavi', largeur: 300}
            ],
            colonnesCachees: ['vaesystypavi', 'apacleint'],
            formulaire: new Formulaire([
                new FormulaireItem('vaesystypavi'),
                new FormulaireItem('pfccleint'),
                new FormulaireItem('vacalityp'),
                new FormulaireItem('indflgavi'),
                new FormulaireItem('txtavi')
            ], { largeurDefaut: 50 })
        };
    }
}
