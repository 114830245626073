import { IComponentController } from 'angular';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IStateService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAc2006 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    daiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    daiMultiOccurrence: IMultiOccurrence;
    somMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dcvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    conMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IAc2006Params {
    refcleint: string;
    intcleintreq: string;
    etrcleint: string;
    id: number;
    indfor: number;
    menuId: string;
}

/* @ngInject */
export default function Ac2006Controller(
    $stateParams: IAc2006Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    exDateFilter: IFilterExDate,
    ecranDetails: IEcranDetailsResourcesEntite,
    $state: IStateService,
    cp2010Route: IRoute,
    ac0032Route: IRoute,
    ac0041Route: IRoute,
    mc1012Route: IRoute,
    ac0022Route: IRoute,
    mc0044Route: IRoute,
    ac0073Route: IRoute) {

    const vm: IPageAc2006 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    const actionMsg = [
        new MenuItemAction(
            'LBL_BTN_DACMSG',
            'BOUDACMSG',
            () => ({}),
            {
                icon: 'description',
                class: (data: any) => data.aut__flgdacmsg === 1 ? 'ex-teal-500-bg' : 'ex-primary-hue-3-fg',
                fonction: 'BOUDACMSG',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_DACMSG',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('dacmsgnew', {
                            default: (data: any) => data.aut__dacmsg,
                            readonly: (data: any) => (!data.aut__dacflgmodpos),
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.$id)
            }
        ),
        new MenuItemAction(
            'LBL_BTN_DACMSGCOU',
            'BOUDACMSGCOU',
            () => ({}),
            {
                icon: 'mail',
                class: (data: any) => data.aut__flgmsgcou === 1 ? 'ex-teal-500-bg' : 'ex-primary-hue-3-fg',
                fonction: 'BOUDACMSGCOU',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_DACMSGCOU',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('dacmsgcounew', {
                            default: (data: any) => data.aut__dacmsgcou,
                            readonly: (data: any) => (!data.aut__dacflgmodpos),
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.aut__motflgcou)
            }
        )
    ];

    const forageDpf = [
        new MenuItemForage(
            'BTN_FORAGE_DPF',
            ac0041Route.NAME,
            (data: any) => ({
                id: data.aut__doccleintdpf
            }),
            {
                hidden: (data: any) => (!data.aut__doccleintdpf || !data.$id),
                fonction: 'BOUDPF',
                icon: 'exit_to_app'
            }
        )
    ];

    const docConsomme = [
        new MenuItemForage(
            'BTN_DOC_CONSOMME',
            (rowData: any) => getForageItem(rowData),
            (rowData: any) => getParamItem(rowData),
            {
                icon: 'exit_to_app',
                iconButton: true,
                fonction: 'BOUDOCCON'
            }
        )
    ];

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        infosMonetaires: {
            blocs: ['dai', 'dcv'],
            largeurTexte: 150,
            donnees:
                [
                    { nom: 'dai', libelle: 'LBL_MNT_DAI' },
                    { nom: 'dcv', libelle: 'LBL_MNT_DCV' },
                    { nom: 'net', libelle: 'LBL_MNT_NET' },
                    { nom: 'eng', libelle: 'LBL_MNT_ENG' },
                    { nom: 'dev', libelle: 'LBL_TAU_DEV' },
                ]
        },
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: false,
            impactBudgetaire: true
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_GEN_VENT',
                'BOUGENVNT',
                () => ({}),
                {
                    iconButton: false,
                    icon: 'refresh',
                    saveFirst: true,
                    fonction: 'BOUGENVNT',
                    messageConfirmation: 'BOUGENVEN_MESSAGE',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_GEN_VENT',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 40,
                        formulaire: new Formulaire([
                            new FormulaireItem('flgsupvnt', {
                                default: 1
                            })
                        ], {
                            largeurDefaut: 100
                        })
                    },
                    successAction: () => { vm.monoOccurrence.fetchData(); },
                    hidden: () => (!vm.monoOccurrence.data.aut__dacflggenimp)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_REO',
                'BOUREO',
                () => ({}),
                {
                    iconButton: false,
                    icon: 'file_open',
                    saveFirst: true,
                    fonction: 'BOUREO',
                    successAction: () => { vm.monoOccurrence.fetchData(); },
                    hidden: (data: any) => (vm.monoOccurrence.data.aut__dacflgreo || !data.$id)
                }
            ),
            new MenuItemEcran(
                'LBL_BTN_HISTO_IMP',
                ac0022Route.NAME,
                (data: any) => ({
                    dhicleintref: data.doccleint,
                    dhityp: 'DOC'
                }),
                {
                    icon: 'launch',
                    iconButton: false,
                    fonction: 'BOUHISIMP',
                    hidden: (data: any) => (!data.$id)
                }
            )
        ]),
        menus: [
            new MenuItemAction(
                'LBL_BTN_FORMULAIRE_CEN',
                'BOUFORCEN',
                () => ({}),
                {
                    iconButton: false,
                    icon: 'print',
                    fonction: 'BOUFORCEN',
                    secondaire: true,
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_FORMULAIRE_CEN',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('trlcodcen', { required: true })
                        ], {
                            largeurDefaut: 100
                        })
                    }
                }
            ),
            new MenuItemAction(
                'LBL_BTN_CREER_AVENANT',
                'BOUCREAVE',
                () => ({}),
                {
                    saveFirst: true,
                    icon: 'exit_to_app',
                    iconButton: false,
                    secondaire: true,
                    successAction: (retour: any) => {
                        $state.go(ac0073Route.NAME, {
                            id: retour.dcncleintnew,
                            menuId: $stateParams.menuId
                        });
                    },
                    hidden: () => (vm.monoOccurrence.data.aut__dacflgave !== 1)
                }
            )
        ],
        formulaire: new Formulaire('LBL_BLC_DAC', [
            new FormulaireItem('dacnumdoc'),
            new FormulaireItem('dacnumdocseq'),
            new FormulaireItem('sysboncmd'),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('dacdatbc'),
            new FormulaireItem('dacseqave'),
            new FormulaireItem('peccleint'),
            new FormulaireItem('dacdat', { default: exDateFilter(new Date()) }),
            new FormulaireItem('dacdatreq'),
            new FormulaireItem('refcleint', { default: $stateParams.refcleint, largeur: 66.66 }),
            new FormulaireItem('etrcleint', { default: $stateParams.etrcleint }),
            new FormulaireItem('intcleintreq', { default: $stateParams.intcleintreq }),
            new FormulaireItem('intcleintdem'),
            new FormulaireItem('dacrefint'),
            new FormulaireItem('dacdsc', { largeur: 66.66, enableCount: true, menu: actionMsg }),
            new FormulaireItem('dacsou'),
            new FormulaireItem('caacleint', {
                resourceParams: (data: any) => ({
                    refcleint: data.refcleint
                })
            }),
            new FormulaireItem('aut__dpfnumdoc', { menu: forageDpf }),
            new FormulaireItem('dacflgpij'),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireItem('devtaucou'),
            new FormulaireGroupeAccordeon('LBL_GRP_ADR',
                new Formulaire([
                    new FormulaireItem('radcleint', {
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            dacdat: data.dacdat
                        })
                    }),
                    new FormulaireItem('lipcleint'),
                    new FormulaireItem('lipcleintfac'),
                    new FormulaireItem('dacattext'),
                    new FormulaireItem('dacatt')
                ], { largeurDefaut: 33.33 })
            ),
            new FormulaireGroupeAccordeon('LBL_GRP_MOD',
                new Formulaire([
                    new FormulaireItemDivider('LBL_DIV_MODACH'),
                    new FormulaireItem('cfncleint', {
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            dacdat: data.dacdat
                        })
                    }),
                    new FormulaireItem('intcleintach'),
                    new FormulaireItem('apocleint', {
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint
                        })
                    }),
                    new FormulaireItem('iaccleint'),
                    new FormulaireItem('intcleintins'),
                    new FormulaireItem('dacins'),
                    new FormulaireItem('motcleint'),
                    new FormulaireItemDivider('LBL_DIV_MODFAC'),
                    new FormulaireItem('vecsysmodtra'),
                    new FormulaireItem('dacindfrsacc', { default: 1 }),
                    new FormulaireItem('teccleint'),
                    new FormulaireItem('aut__fcanum'),
                    new FormulaireItem('mopcleint'),
                    new FormulaireItem('entcleint'),
                    new FormulaireItemDivider('LBL_DIV_AUTRES'),
                    new FormulaireItem('vecdaccat'),
                    new FormulaireItem('dacnumrescd', {
                        menu: [
                            new MenuItemEcran('LBL_HISTO_RESOL',
                                mc1012Route.NAME,
                                (data: any) => ({ doccleintref: data.daccleint }),
                                {
                                    icon: 'launch',
                                    iconButton: true,
                                    fonction: 'BOUHISRES'
                                }
                            )
                        ]
                    }
                    ),
                    new FormulaireItem('dacnumresca'),
                    new FormulaireItem('vecsystercom'),
                    new FormulaireItem('dacdes'),
                    new FormulaireItem('lolcleint'),
                    new FormulaireItem('dacdatree'),
                    new FormulaireItem('dacdatdeb'),
                    new FormulaireItem('dacdatfin'),
                    new FormulaireItem('intcleint_averti'),
                    new FormulaireItem('aut__founomabr'),
                    new FormulaireItemSautDeLigne,
                    new FormulaireItem('dacdetexp', { largeur: 100, enableCount: true })
                ], { largeurDefaut: 33.33 })
            )
        ], { largeurDefaut: 33.33 })
    };

    vm.daiMultiOccurrenceOptions = {
        bloc: 'dai',
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_RENUM',
                'BOURENUM',
                () => ({}),
                {
                    iconButton: false,
                    icon: 'refresh',
                    fonction: 'BOURENUM',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_RENUM',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('pasde', {
                                required: true,
                                default: 1
                            })
                        ])
                    },
                    successAction: () => { vm.daiMultiOccurrence.fetchDataList(); },
                    hidden: () => !(vm.monoOccurrence.data.aut__dacflgmodpos)
                }
            ),
            new MenuItemForage(
                'LBL_BTN_SOMMAIRE_ITEM',
                ac0032Route.NAME,
                (data: any) => ({
                    daccleint: data.daccleint
                }),
                {
                    fonction: 'BOUSOMITE',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            editionStandard: true,
            agrandirMultiOccurrence: true,
        },
        colonnesFixesGauche: [
            { nom: 'dailig', largeur: 100 },
            { nom: 'dailigseq', largeur: 65 },
            { nom: 'daiseqave', largeur: 65 },
            { nom: 'daidsc', largeur: 300 }
        ],
        colonnesVisibles: [
            { nom: 'daiprxuni', largeur: 120 },
            { nom: 'daiqte', largeur: 120 },
            { nom: 'taxcod', largeur: 80 },
            { nom: 'daimnt', largeur: 120 },
            { nom: 'daimnttaxfed', largeur: 130 },
            { nom: 'daimnttaxpro', largeur: 130 },
            { nom: 'caacod', largeur: 140 },
            { nom: 'caadsc', largeur: 180 },
            { nom: 'dainumcat', largeur: 150 },
            { nom: 'forcod', largeur: 120 },
            { nom: 'fordsc', largeur: 180 },
            { nom: 'unmcod', largeur: 130 },
            { nom: 'unmdsc', largeur: 180 },
            { nom: 'daiclash', largeur: 140 },
            { nom: 'daipayori', largeur: 140 },
            { nom: 'lolnumloc', largeur: 120 },
            { nom: 'loldsc', largeur: 180 },
            { nom: 'daiflggeb', largeur: 120 }
        ],
        colonnesFixesDroite: [
            { nom: 'daiflghis', largeur: 80 },
            { nom: 'flgimp', largeur: 80 },
            { nom: 'daiflgimp', largeur: 110 }
        ]
    };

    vm.dcvMultiOccurrenceOptions = {
        bloc: 'dcv',
        colonnesFixesGauche: [
            {
                nom: 'dcvimp', largeur: 300, menu: [
                    new MenuItemEcran(
                        '',
                        mc0044Route.NAME,
                        (rowData: any) => ({
                            id: rowData.prucleint,
                            prucleint: rowData.prucleint
                        }),
                        {
                            icon: 'launch',
                            iconButton: false,
                            secondaire: true,
                            fonction: 'BOUPOS'
                        })
                ]
            },
            { nom: 'dcvmnt', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'dcvqte', largeur: 100 },
            { nom: 'taxcod', largeur: 100 },
            { nom: 'remcod', largeur: 100 },
            { nom: 'dcvmnttaxfed', largeur: 130 },
            { nom: 'dcvmnttaxpro', largeur: 130 },
            { nom: 'dcvmntremfed', largeur: 130 },
            { nom: 'dcvmntrempro', largeur: 130 },
            { nom: 'dcvmntdepnet', largeur: 130 },
            { nom: 'dcvseqave', largeur: 100 },
            { nom: 'dcvdsc', largeur: 180 }
        ],
        colonnesFixesDroite: [
            { nom: 'dcvflgmsg', largeur: 100 }
        ],
        colonnesCachees: [
            'dcvmsg',
            'prucleint'
        ]
    };

    vm.somMultiOccurrenceOptions = {
        reloadWithBlocs: ['DAI'],
        colonnesFixesGauche: [
            { nom: 'dailig', largeur: 100 },
            { nom: 'daidsc', largeur: 250 }
        ],
        colonnesVisibles: [
            { nom: 'daidsclng', largeur: 250 },
            { nom: 'daiprxuni', largeur: 120 },
            { nom: 'daiqte', largeur: 120 },
            { nom: 'taxcod', largeur: 80 },
            { nom: 'daimnt', largeur: 120 },
            { nom: 'daimnttaxfed', largeur: 130 },
            { nom: 'daimnttaxpro', largeur: 130 },
            { nom: 'caacod', largeur: 140 },
            { nom: 'caadsc', largeur: 180 },
            { nom: 'dainumcat', largeur: 150 },
            { nom: 'forcod', largeur: 120 },
            { nom: 'fordsc', largeur: 180 },
            { nom: 'unmcod', largeur: 130 },
            { nom: 'unmdsc', largeur: 180 },
            { nom: 'daiclash', largeur: 140 },
            { nom: 'daipayori', largeur: 140 },
            { nom: 'lolnumloc', largeur: 120 },
            { nom: 'loldsc', largeur: 180 },
            { nom: 'daiflggeb', largeur: 120 }
        ]
    };

    vm.conMultiOccurrenceOptions = {
        bloc: 'con',
        colonnesFixesGauche: [
            { nom: 'docnumdoc', largeur: 150, menu: docConsomme }
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 100 },
            { nom: 'tyddsc', largeur: 200 },
            { nom: 'docdat', largeur: 126 },
            { nom: 'docmnt', largeur: 120 },
            { nom: 'docmntcon', largeur: 120 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'reqcod', largeur: 120 },
            { nom: 'reqnom', largeur: 200 }
        ],
        colonnesCachees: [
            'ecrcod',
            'doccleintcon'
        ]
    };

    function getForageItem(rowData: any): string {
        if (rowData.ecrcod === 'CP0013-00-2') {
            return cp2010Route.NAME;
        }
    }
    function getParamItem(rowData: any) {
        if (rowData.ecrcod === 'CP0013-00-2') {
            return { id: rowData.doccleintcon };
        }
    }

}
