import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPagePf0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

interface IPf0015Params {
    unocleintrh: string;
    rbucleintpar: string;
    cabcleintpar: string;
}

/* @ngInject */
export default function Pf0015Controller(pf0016Route: IRoute,
                                         pf0014Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService,
                                         $stateParams: IPf0015Params,
                                         Menu: IMenuClass,
                                         Operateur: IOperateurService,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass ) {
    const vm: IPagePf0015 = this;

    const filtresParametres = [];

    if ($stateParams.rbucleintpar) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'rbucleintpar',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.rbucleintpar,
                    affichage: true
                },
                {
                    colonne: 'cabcleintpar',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.cabcleintpar,
                    affichage: true
                }
            ]
        );
    }

    let criteresSuggeres;

    if (!$stateParams.rbucleintpar && !$stateParams.cabcleintpar) {
        criteresSuggeres = new Formulaire([
            new FormulaireItem('rbucleintcri', {
                default: $stateParams.rbucleintpar,
                required: true
             }),
             new FormulaireItem('cabcleintcri',{
                 default: $stateParams.cabcleintpar
             })
    ])
    }
    const resourceParams: any = {};



    if (typeof $stateParams.cabcleintpar !== 'undefined') {
        resourceParams.cabcleintcri = $stateParams.cabcleintpar;
    }

    if (typeof $stateParams.rbucleintpar !== 'undefined') {
        resourceParams.rbucleintcri = $stateParams.rbucleintpar;
    }

    if (typeof $stateParams.unocleintrh !== 'undefined') {
        resourceParams.unocleintrh = $stateParams.unocleintrh;
    }

    vm.multiOccurrenceOptions = {
        resourceParams,
        criteresSuggeres,
        filtresParametres,
        fonctions: {
            criteresSuggeresVisibles: !$stateParams.rbucleintpar,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_DET_CAT',
                pf0016Route.NAME,
                (data: any) => ({
                    rbucleintpar: data.rbucleint,
                    unocleintpar: data.unocleintrh
                }),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'LBL_DET_EMP',
                pf0014Route.NAME,
                (data: any) => ({
                    axe: 'EMP',
                    rbucleint: data.rbucleint,
                    cabcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.cabcleintcri,
                    unocleintrh: data.unocleintrh
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),
        colonnesCachees: [
            'dpmannfin',
            'rbucleint',
            'unocleintrh',
            'cabcleint'
        ],
        colonnesFixesGauche: [
            {nom: 'unocod', largeur: 120},
            {nom: 'unodscabr', largeur: 250}
        ],
        colonnesVisibles: [
            {nom: 'nbremp', largeur: 120, total: true},
            {nom: 'mntbdg', largeur: 120, total: true},
            {nom: 'mntacc', largeur: 120, total: true},
            {nom: 'mntreel', largeur: 120, total: true},
            {nom: 'mntdispo', largeur: 120, total: true},
            {nom: 'salaire', largeur: 125, total: true},
            {nom: 'durhtt', largeur: 120, total: true},
            {nom: 'durhht', largeur: 120, total: true}
        ]
    };
}
