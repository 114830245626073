import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0062 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0062Controller(Menu: IMenuClass,
        Formulaire: IFormulaireClass,
        FormulaireItem: IFormulaireItemClass,
    ) {
    const vm: IPageMr0062 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'swecod', largeur: 60 },
            { nom: 'swedsc', largeur: 120 },
            { nom: 'clscod', largeur: 30 },
            { nom: 'clsnom', largeur: 120 },
            { nom: 'swecom', largeur: 300 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('swecod', {largeur: 30}),
            new FormulaireItem('swedsc', {largeur: 30}),
            new FormulaireItem('clscleint', {largeur: 30}),
            new FormulaireItem('swecom', {largeur: 100})
        ])
    };
}
