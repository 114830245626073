import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageRr0162 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    fddMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fddMultiOccurrence: IMultiOccurrence
}

/* @ngInject */
export default function Rr0162Controller(Formulaire: IFormulaireClass,
    rr0519Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    $state: IStateService,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    Operateur: IOperateurService) {
    const vm: IPageRr0162 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemActionImportation(
                'LBL_CHARGEMENT_TXT',
                'BOUCHA',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_TXT',
                        largeurFormulaire: 15,
                        formulaire: new Formulaire([
                            new FormulaireItem('fdrnumcha', {
                                largeur: 100,
                                default: (data: any) => data.monoOccurrence.fdrnumcha
                            })
                        ])
                    },
                    successAction: () => vm.monoOccurrence.fetchData(),
                    class: 'md-raised ex-button-action',
                    typesFichiers: ['.csv'],
                    iconButton: false,
                    disabled: () => vm.monoOccurrence.data.fdrnbrlig
                }
            ),
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                        vm.monoOccurrence.once("dataUpdate", () => vm.fddMultiOccurrence.fetchDataList())
                    },
                    disabled: () => (vm.monoOccurrence.data.fdrflggen)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_GEN',
                'BOUGEN',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => vm.monoOccurrence.fetchData(),
                    disabled: () => !(vm.monoOccurrence.data.fdrflgval && !vm.monoOccurrence.data.fdrflggen)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_SUP',
                'BOUSUP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    successAction: () => $state.go(rr0519Route.NAME, {})
                }
            )
        ],
        formulaire: new Formulaire('LBL_BLC_FDR', [
            new FormulaireItem('fdrnumcha'),
            new FormulaireItem('fdrficsou'),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('fdrnbrlig')
        ])
    };

    vm.fddMultiOccurrenceOptions = {
        editionRapideActive: false,
        fonctions: {
            editionRapide: true
        },
        filtres: [
            {
                colonne: 'fddflgerr',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesFixesGauche: [
            { nom: 'fddflgerr', largeur: 75 },
            { nom: 'fddnbrerr', largeur: 90 },
            { nom: 'eplnummat', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'fddind', largeur: 100 },
            { nom: 'tdmcod', largeur: 100 },
            { nom: 'fdddat', largeur: 100 },
            { nom: 'urgcod', largeur: 90 },
            { nom: 'tagcod', largeur: 90 },
            { nom: 'fdddatdeb', largeur: 100 },
            { nom: 'fdddatfin', largeur: 100 },
            { nom: 'fddflgpro', largeur: 85 },
            { nom: 'fdddatfinori', largeur: 100 },
            { nom: 'fdddsc', largeur: 110 },
            { nom: 'fddtauhor', largeur: 120 },
            { nom: 'fddqte', largeur: 100 },
            { nom: 'fddqte01', largeur: 100 },
            { nom: 'fddqte02', largeur: 90 },
            { nom: 'fddqte03', largeur: 90 },
            { nom: 'fddqte04', largeur: 90 },
            { nom: 'fddqte05', largeur: 90 },
            { nom: 'fddqte06', largeur: 90 },
            { nom: 'fddqte07', largeur: 90 },
            { nom: 'fddqte08', largeur: 100 },
            { nom: 'fddqte09', largeur: 90 },
            { nom: 'fddqte10', largeur: 90 },
            { nom: 'fddqte11', largeur: 90 },
            { nom: 'fddqte12', largeur: 90 },
            { nom: 'fddqte13', largeur: 90 },
            { nom: 'fddqte14', largeur: 90 },
            { nom: 'fddmnt', largeur: 140 },
            { nom: 'temcod', largeur: 90 },
            { nom: 'unocod', largeur: 110 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'intcodref', largeur: 120 },
            { nom: 'intcoddem', largeur: 110 },
            { nom: 'lemcod', largeur: 120 },
            { nom: 'aftcod', largeur: 110 },            
            { nom: 'intcodreq', largeur: 100 },
            { nom: 'litcod', largeur: 110 },
            { nom: 'fdddatvig', largeur: 130 },
            { nom: 'fdddatfineng', largeur: 110 },
            { nom: 'rsccod', largeur: 90 },
            { nom: 'fddimp', largeur: 300 },
            { nom: 'fddovnprc', largeur: 120 },
            { nom: 'fddovnmnt', largeur: 140 },
            { nom: 'vaeovnmthbmg', largeur: 100 },
            { nom: 'fdddatdebsub', largeur: 100 },
            { nom: 'fdddatfinsub', largeur: 100 },
            { nom: 'fddovnmntcum', largeur: 140 },
            { nom: 'fddnbrjrssubbas', largeur: 120 },
            { nom: 'fddnbrjrssubppa', largeur: 120 },
            { nom: 'fddovnmntper', largeur: 140 },
            { nom: 'fddovnmntjrs', largeur: 140 },
            { nom: 'fddcom', largeur: 300 },
            { nom: 'esvcod1', largeur: 130, hidden: !vm.valeursEcran.flgesvcod1 },
            { nom: 'esvcod2', largeur: 130, hidden: !vm.valeursEcran.flgesvcod2 },
            { nom: 'esvcod3', largeur: 130, hidden: !vm.valeursEcran.flgesvcod3 },
            { nom: 'esvcod4', largeur: 130, hidden: !vm.valeursEcran.flgesvcod4 },
            { nom: 'esvcod5', largeur: 130, hidden: !vm.valeursEcran.flgesvcod5 }
        ],
        colonnesEditionRapide: [
            { nom: 'eplnummat', largeur: 120 },
            { nom: 'fddind', largeur: 100 },
            { nom: 'tdmcod', largeur: 100 },
            { nom: 'fdddat', largeur: 100 },
            { nom: 'urgcod', largeur: 90 },
            { nom: 'tagcod', largeur: 90 },
            { nom: 'fdddatdeb', largeur: 100 },
            { nom: 'fdddatfin', largeur: 100 },
            { nom: 'fddflgpro', largeur: 85 },
            { nom: 'fdddatfinori', largeur: 100 },
            { nom: 'fdddsc', largeur: 110 },
            { nom: 'fddtauhor', largeur: 120 },
            { nom: 'fddqte', largeur: 100 },
            { nom: 'fddqte01', largeur: 100 },
            { nom: 'fddqte02', largeur: 90 },
            { nom: 'fddqte03', largeur: 90 },
            { nom: 'fddqte04', largeur: 90 },
            { nom: 'fddqte05', largeur: 90 },
            { nom: 'fddqte06', largeur: 90 },
            { nom: 'fddqte07', largeur: 90 },
            { nom: 'fddqte08', largeur: 100 },
            { nom: 'fddqte09', largeur: 90 },
            { nom: 'fddqte10', largeur: 90 },
            { nom: 'fddqte11', largeur: 90 },
            { nom: 'fddqte12', largeur: 90 },
            { nom: 'fddqte13', largeur: 90 },
            { nom: 'fddqte14', largeur: 90 },
            { nom: 'fddmnt', largeur: 140 },
            { nom: 'temcod', largeur: 90 },
            { nom: 'unocod', largeur: 110 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'intcodref', largeur: 120 },
            { nom: 'intcoddem', largeur: 110 },
            { nom: 'lemcod', largeur: 120 },
            { nom: 'aftcod', largeur: 110 },            
            { nom: 'intcodreq', largeur: 100 },
            { nom: 'litcod', largeur: 110 },
            { nom: 'fdddatvig', largeur: 130 },
            { nom: 'fdddatfineng', largeur: 110 },
            { nom: 'rsccod', largeur: 90 },
            { nom: 'fddimp', largeur: 300 },
            { nom: 'fddovnprc', largeur: 120 },
            { nom: 'fddovnmnt', largeur: 140 },
            { nom: 'vaeovnmthbmg', largeur: 100 },
            { nom: 'fdddatdebsub', largeur: 100 },
            { nom: 'fdddatfinsub', largeur: 100 },
            { nom: 'fddovnmntcum', largeur: 140 },
            { nom: 'fddnbrjrssubbas', largeur: 120 },
            { nom: 'fddnbrjrssubppa', largeur: 120 },
            { nom: 'fddovnmntper', largeur: 140 },
            { nom: 'fddovnmntjrs', largeur: 140 },
            { nom: 'fddcom', largeur: 300 },
            { nom: 'esvcod1', largeur: 130, hidden: !vm.valeursEcran.flgesvcod1 },
            { nom: 'esvcod2', largeur: 130, hidden: !vm.valeursEcran.flgesvcod2 },
            { nom: 'esvcod3', largeur: 130, hidden: !vm.valeursEcran.flgesvcod3 },
            { nom: 'esvcod4', largeur: 130, hidden: !vm.valeursEcran.flgesvcod4 },
            { nom: 'esvcod5', largeur: 130, hidden: !vm.valeursEcran.flgesvcod5 }
        ],
        colonnesCachees: ['fddmsgerr'],
        formulaire: new Formulaire([
            new FormulaireItem("fddnbrerr", { readonly: true, hidden: () => !vm.fddMultiOccurrence.editionRapideActive }),
            new FormulaireItem('eplnummat'),
            new FormulaireItem('fddind'),
            new FormulaireItem('tdmcod'),
            new FormulaireItem('fdddat'),
            new FormulaireItem('urgcod'),
            new FormulaireItem('tagcod'),
            new FormulaireItem('fdddatdeb'),
            new FormulaireItem('fdddatfin'),
            new FormulaireItem('fddflgpro'),
            new FormulaireItem('fdddatfinori'),
            new FormulaireItem('fdddsc', { enableCount: true }),
            new FormulaireItem('fddtauhor'),
            new FormulaireItem('fddqte'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fddqte01'),
            new FormulaireItem('fddqte02'),
            new FormulaireItem('fddqte03'),
            new FormulaireItem('fddqte04'),
            new FormulaireItem('fddqte05'),
            new FormulaireItem('fddqte06'),
            new FormulaireItem('fddqte07'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fddqte08'),
            new FormulaireItem('fddqte09'),
            new FormulaireItem('fddqte10'),
            new FormulaireItem('fddqte11'),
            new FormulaireItem('fddqte12'),
            new FormulaireItem('fddqte13'),
            new FormulaireItem('fddqte14'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fddmnt'),
            new FormulaireItem('temcod'),
            new FormulaireItem('unocod'),
            new FormulaireItem('posnumdoc'),
            new FormulaireItem('intcodref'),
            new FormulaireItem('intcoddem'),
            new FormulaireItem('lemcod'),
            new FormulaireItem('aftcod'),            
            new FormulaireItem('intcodreq'),
            new FormulaireItem('litcod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fdddatvig'),
            new FormulaireItem('fdddatfineng'),
            new FormulaireItem('rsccod'),
            new FormulaireItem('fddimp', { largeur: 40 }),
            new FormulaireItem('fddovnprc'),
            new FormulaireItem('fddovnmnt'),
            new FormulaireItem('vaeovnmthbmg'),
            new FormulaireItem('fdddatdebsub'),
            new FormulaireItem('fdddatfinsub'),
            new FormulaireItem('fddovnmntcum'),
            new FormulaireItem('fddnbrjrssubbas'),
            new FormulaireItem('fddnbrjrssubppa'),
            new FormulaireItem('fddovnmntper'),
            new FormulaireItem('fddovnmntjrs'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fddcom', { largeur: 40, enableCount: true }),
            new FormulaireItem('esvcod1', { hidden: !vm.valeursEcran.flgesvcod1 }),
            new FormulaireItem('esvcod2', { hidden: !vm.valeursEcran.flgesvcod2 }),
            new FormulaireItem('esvcod3', { hidden: !vm.valeursEcran.flgesvcod3 }),
            new FormulaireItem('esvcod4', { hidden: !vm.valeursEcran.flgesvcod4 }),
            new FormulaireItem('esvcod5', { hidden: !vm.valeursEcran.flgesvcod5 })
        ], { largeurDefaut: 20 })
    };
}
