import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageDO0089 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0089Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageDO0089 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'dpxnumdoc', largeur: 105},
            {nom: 'dpxdat', largeur: 100},
            {nom: 'tbpcod', largeur: 90},
            {nom: 'tbpdsc', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'posnumdoc', largeur: 90},
            {nom: 'postitabr', largeur: 262},
            {nom: 'intcod', largeur: 105},
            {nom: 'dpxnomdem', largeur: 262},
            {nom: 'intcodrep', largeur: 115},
            {nom: 'intnomrep', largeur: 262},
            {nom: 'dpxcom', largeur: 441},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
