import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMenuItemFactoryClass } from '../../../../../core/services/menu/menu-item-factory.service';

interface IPageRc0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}


/* @ngInject */
export default function Rc0010Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    MenuItemFactory: IMenuItemFactoryClass,
    rc2003Route: IRoute,
    rc2004Route: IRoute,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageRc0010 = this;

    vm.multiOccurrenceOptions = {
        actionsMoreLigne: new Menu([
            new MenuItemForage('LBL_FAC',
                rc2003Route.NAME,
                (data: any) => ({
                    txmcleint: data.txmcleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                }),
            new MenuItemForage('LBL_ITEM_REC',
                rc2004Route.NAME,
                (data: any) => ({
                    txmcleint: data.txmcleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                })
        ]),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: false,
            effaceCriteres: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_CRI'),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('refcleint', { required: false, largeur: 110 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('tydcleint', { required: false, largeur: 110 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('intcleintreq', { required: false, largeur: 110 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('lrtcleint', {
                        largeur: 110,
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            tydcleint: data.tydcleint
                        })
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('txmcleint', {
                        required: false, largeur: 110,
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            lrtcleint: data.lrtcleint,
                            tydcleint: data.tydcleint
                        })
                    })
                ]), { largeur: 31 }
            ),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('docref', {
                        largeur: 65,
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            lrtcleint: data.lrtcleint,
                            tydcleint: data.tydcleint,
                            txmcleint: data.txmcleint
                        })
                    }),
                    new FormulaireItem('datdeb', { largeur: 10 }),
                    new FormulaireItem('sysboncmd', {
                        largeur: 65,
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            lrtcleint: data.lrtcelint,
                            tydcleint: data.tydcleint,
                            txmcleint: data.txmcleint
                        })
                    }),
                    new FormulaireItem('datfin', { largeur: 10 }),
                    new FormulaireItem('optrup', {
                        largeur: 20,
                        default: 3
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('optruprec', {
                        largeur: 20,
                        default: 3
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('optfac', {
                        largeur: 20,
                        default: 1
                    }),
                ], {largeurDefaut: 20}), { largeur: 52 }
            ),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('flg_fr', { default: 1 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('flg_an', { default: 1 })
                ]), { largeur: 15 })                
        ]),

        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]),
        colonnesCachees: [
            'ecrcodd',
            'ecrcodt',
            'daccleint',
            'txmcleint',
            'doccleintref'
        ],
        colonnesVisibles: [
            { nom: 'txmnumdoc', largeur: 100 },
            { nom: 'txmnumdocseq', largeur: 100 },
            { nom: 'txmnumref', largeur: 150 },
            {
                nom: 'dacnumdoc', largeur: 146,
                menu: [
                    new MenuItemForage(
                        '',
                        (data: any) => `secure.${data.ecrcodd}.ecran`,
                        (data: any) => ({
                            id: data.daccleint,
                            indfor: 1
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'refcod', largeur: 130 },
            { nom: 'refnom', largeur: 180 },
            { nom: 'tydcod', largeur: 150 },
            { nom: 'tyddsc', largeur: 190 },
            { nom: 'etddsc', largeur: 130 },
            { nom: 'peccod', largeur: 120 },
            { nom: 'txmdat', largeur: 110 },
            { nom: 'intcodreq', largeur: 110 },
            { nom: 'intnomreq', largeur: 180 },
            { nom: 'lipcod', largeur: 140 },
            { nom: 'lipnom', largeur: 160 }

        ],
        colonnesFixesDroite: [
            { nom: 'txmflgrup', largeur: 100 },
            { nom: 'txmflgruprec', largeur: 100 },
            { nom: 'complet', largeur: 100 },
            { nom: 'nbrfac', largeur: 100 }
        ],
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcodt}.ecran`),
            (data: any) => ({
                id: data.doccleintref,
                indfor: 1
            })
        )
    }
}
