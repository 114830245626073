import { IComponentController, IScope } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IMenuItemRecuperationClass } from '../../../../../core/services/menu/menu-item-recuperation.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';


interface IPageSo0007 extends IComponentController {
    ppxcod: IMenuItem;
    forageStr: IMenuItem;
    valeursEcran: any;
    formulairePos: IFormulaire;
    formulaireAut: IFormulaire;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    hhsMultiOccurrence: IMultiOccurrence;
    corMultiOccurrence: IMultiOccurrence;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions; //Imputation
    hhsMultiOccurrenceOptions: IMultiOccurrenceOptions; //Rattachements horaire
    popMultiOccurrenceOptions: IMultiOccurrenceOptions; //Primes
    aftMultiOccurrenceOptions: IMultiOccurrenceOptions; //Affectations sur le poste
    dptMultiOccurrenceOptions: IMultiOccurrenceOptions; //Demandes de modification
    hchMultiOccurrenceOptions: IMultiOccurrenceOptions; //Historique de changement de poste
    pvaMultiOccurrenceOptions: IMultiOccurrenceOptions; //Postes vacant
    tchMultiOccurrenceOptions: IMultiOccurrenceOptions; //Tâches
    corMultiOccurrenceOptions: IMultiOccurrenceOptions; //Compétences requises
    rpoMultiOccurrenceOptions: IMultiOccurrenceOptions; //Rôle
}

/* @ngInject */
export default function So0007Controller(as0023Route: IRoute,
    co0009Route: IRoute,
    do0037Route: IRoute,
    so0017Route: IRoute,
    mr0176Route: IRoute,
    so0010Route: IRoute,
    rr0006Route: IRoute,
    $scope: IScope,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemRecuperation: IMenuItemRecuperationClass,
    Pagination: IPaginationClass) {

    const vm: IPageSo0007 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    const do0046Route = { NAME: 'secure.accueil' };

    const forageDo0046 = new MenuItemForage(
        'BTN_FORAGE_DO0046',
        do0046Route.NAME,
        () => ({}),
        { hidden: true }
    );

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.menus = {
            stodscabr: [
                new MenuItemForage(
                    'BTN_FORAGE_ATF',
                    so0010Route.NAME,
                    () => ({ id: vm.monoOccurrence.data.stocleint })
                )
            ],
            stodscabrtete: [
                new MenuItemForage(
                    'BTN_FORAGE_ATF',
                    so0010Route.NAME,
                    () => ({ id: vm.monoOccurrence.data.stocleint_tete })
                )
            ]
        };
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        // Formulaire principal
        vm.formulairePos = new Formulaire('LBL_BLC_POS', [
            new FormulaireItem('posnumdoc', { largeur: 50 }),
            new FormulaireItem('tpocleint', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_INFOS_BASE', new Formulaire([
                new FormulaireItem('urgcleint', { required: true, historiqueColonne: true, largeur: 50 }),
                new FormulaireItem('strcleint', {
                    required: true, historiqueColonne: true, largeur: 50,
                    resourceParams: (data: any) => ({ urgcleint: data.urgcleint })
                }),
                new FormulaireItem('temcleint', {
                    required: true, historiqueColonne: true, largeur: 50,
                    resourceParams: (data: any) => ({ urgcleint: data.urgcleint })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ttccleint', { largeur: 50 }),
                new FormulaireItem('dptcleintref', { menu: [forageDo0046], largeur: 50 })
            ])),
            new FormulaireItem('postit', { required: true, historiqueColonne: true, largeur: 75 }),
        ], { largeurDefaut: 25 });

        vm.formulaireAut = new Formulaire('LBL_AUT_INFOS', [
            new FormulaireItem('posdatdeb'),
            new FormulaireItem('posdatfin'),
            new FormulaireItem('sefcleint', {
                resourceParams: (data: any) => {
                    return {
                        posdatdeb: data.posdatdeb,
                        posdatfin: data.posdatfin
                    };
                },
                historiqueColonne: true
            }),
            new FormulaireItem('unocleintrh', {
                historiqueColonne: true,
                resourceParams: (data: any) => {
                    return {
                        posdatdeb: data.posdatdeb,
                        posdatfin: data.posdatfin
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('posdsc', { largeur: 100, enableCount: true }),
            new FormulaireItem('posinfoadd', { largeur: 100 }),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRE_INFOS', new Formulaire([
                new FormulaireItem('litcleint'),
                new FormulaireItem('unocleintvac'),
                new FormulaireItem('rolcleint'),
                new FormulaireItem('vaeposloi95', { historiqueColonne: true }),
                new FormulaireItem('geccleint', { historiqueColonne: true }),
                new FormulaireItem('posassaut'),
                new FormulaireItem('posflghie', { default: 1 }),
                new FormulaireItemDivider('LBL_TITRES_DESC'),
                new FormulaireItem('postitabr', { historiqueColonne: true }),
                new FormulaireItemMenuItem(new MenuItemForage(
                    'BTN_FORAGE_TRN',
                    so0017Route.NAME,
                    undefined, {
                    iconButton: false,
                    icon: '',
                    class: 'md-raised ex-button-action'
                }
                )),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('postitfem'),
                new FormulaireItem('postitmas'),
                new FormulaireItem('posdscfon', { largeur: 100, enableCount: true })
            ])),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_INFO_PAEE', new Formulaire([
                new FormulaireItem('rgecleint'),
                new FormulaireItem('rgecleintpro'),
                new FormulaireItem('vacsyszonrec')
            ]))
        ]);

        //Rôles
        vm.rpoMultiOccurrenceOptions = {
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                'rolcod',
                'roldscabr',
                'unocod',
                'unodscabr',
                'rpodatdeb',
                'rpodatfin'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rolcleint'),
                new FormulaireItem('unocleintrh'),
                new FormulaireItem('rpodatdeb'),
                new FormulaireItem('rpodatfin')
            ], { largeurDefaut: 50 })
        };

        const monoOccurrenceListener = $scope.$watch('vm.monoOccurrence.data.poscleint', (value) => {
            if (!value) return;

            monoOccurrenceListener();

            ///////////////////
            // Imputation
            ///////////////////
            vm.ovdMultiOccurrenceOptions = {
                navigatePage: mr0176Route.NAME,
                navigateParams: (data: any) => (
                    {
                        ovdcleintref: vm.monoOccurrence.data.poscleint,
                        stamnetab: 'POS',
                        id: data.ovdcleint
                    }
                ),
                actionsNouveaux: new Menu([
                    new MenuItemForage(mr0176Route.NAME, () => {
                        return {
                            ovdcleintref: vm.monoOccurrence.data.poscleint,
                            stamnetab: 'POS'
                        };
                    })
                ]),
                resourceParams: {
                    ovdcleintref: vm.monoOccurrence.data.poscleint,
                    stamnetab: 'POS'
                },
                bloc: 'ovd',
                fonctions: {
                    pagination: true,
                    criteresSuggeresVisibles: false
                },
                pagination: new Pagination({
                    nombreElementPage: 3,
                    nbElementsPossibles: [3, 6, 15]
                }),
                criteresSuggeres: new Formulaire([
                    new FormulaireItem('selection', {
                        largeur: 100,
                        default: 'VF',
                        direction: 'vertical'
                    })
                ]),
                // Affichage des colonnes visibles
                colonnesVisibles: [
                    { nom: 'ovnimp', largeur: 300 },
                    { nom: 'rsccoddsc', largeur: 100 },
                    { nom: 'ovddatvig', largeur: 100 },
                    { nom: 'ovddatfin', largeur: 100 }
                ]
            };

        });

        //Rattachements horaire
        vm.hhsMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemForage('BTN_OUVRIR', as0023Route.NAME, (data: any) => {
                    return {
                        ecran: '00-2',
                        id: data.trncleint,
                        hhscleintref: vm.monoOccurrence.data.poscleint,
                        poscleint_param: vm.monoOccurrence.data.poscleint,
                        demcleint: data.$params.demcleint,
                        onClose() {
                            vm.hhsMultiOccurrence.fetchDataList();
                        }
                    };
                }, {
                    icon: 'launch',
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage('', as0023Route.NAME, () => {
                    return {
                        ecran: '00-2',
                        hhscleintref: vm.monoOccurrence.data.poscleint,
                        poscleint_param: vm.monoOccurrence.data.poscleint,
                        demcleint: vm.monoOccurrence.data.$params.demcleint,
                        onClose() {
                            vm.hhsMultiOccurrence.fetchDataList();
                        }
                    };
                }),
            ]),
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('annule', {
                    largeur: 50,
                    default: 'N',
                    direction: 'vertical'
                }),
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            colonnesCachees: ['ahtcleint'],
            colonnesVisibles: [
                { nom: 'ahtcod', largeur: 100 },
                { nom: 'ahtdsc', largeur: 100 },
                { nom: 'hhsetc', largeur: 100 },
                { nom: 'etc_calcule', largeur: 100 },
                { nom: 'trnflgstafin', largeur: 100 },
                { nom: 'trnflgstaann', largeur: 100 },
                { nom: 'ecldsceta', largeur: 100 },
                { nom: 'hhsflgdef', largeur: 100 },
                { nom: 'indtmppar', largeur: 100 },
                { nom: 'flgahtman', largeur: 100 },
                { nom: 'asgdsc', largeur: 100 },
                { nom: 'hhsdatdeb', largeur: 100 },
                { nom: 'hhsdatfin', largeur: 100 },
                { nom: 'aeqnom', largeur: 200 },
                { nom: 'etpdscabr', largeur: 200 },
                { nom: 'hhsdsc', largeur: 200 }
            ]
        };

        //Primes
        vm.popMultiOccurrenceOptions = {
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            colonnesVisibles: [
                'tagcod',
                'tagdscabr',
                'popdatdeb',
                'popdatfin',
                'tagpar',
                'popcom'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint', {
                    largeur: 66.66,
                    resourceParams: () => ({
                        urgcleint: vm.monoOccurrence && vm.monoOccurrence.data.urgcleint,
                        strcleint: vm.monoOccurrence && vm.monoOccurrence.data.strcleint
                    })
                }),
                new FormulaireItem('popflgact', { default: 1 }),
                new FormulaireItem('popdatdeb'),
                new FormulaireItem('popdatfin'),
                new FormulaireItem('popcom', { largeur: 100 })
            ], { largeurDefaut: 33.33 })
        };


        //Affectations sur le poste
        vm.aftMultiOccurrenceOptions = {
            navigatePage: rr0006Route.NAME,
            navigateParams: (data: any) => (
                {
                    id: data.trncleint
                }
            ),
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('titulaire', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            colonnesCachees: ['trncleint'],
            colonnesVisibles: [
                'eplnummat',
                'eplnomprn',
                'aftflgttu',
                'aftdatdeb',
                'aftdatfin'
            ]
        };

        //Demandes de modification
        vm.dptMultiOccurrenceOptions = {
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                'dptnumdoc',
                'dptdatvig',
                'ecldsceta'
            ]
        };

        //Historique de changement
        vm.hchMultiOccurrenceOptions = {
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                { nom: 'mhcdatvig', largeur: 120 },
                { nom: 'typ', largeur: 160 },
                { nom: 'nouval', largeur: 200 },
                { nom: 'ancval', largeur: 160 },
                { nom: 'mhccom', largeur: 180 }
            ],
            colonnesFixesDroite: [
                { nom: 'mhcflgact', largeur: 74 },
                { nom: 'mhcflgini', largeur: 74 }
            ]
        };

        //Poste vacant
        vm.pvaMultiOccurrenceOptions = {
            navigatePage: do0037Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    trncleint: rowData.trncleint
                };
            },
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    'G_LBL_COMREQ',
                    do0037Route.NAME,
                    (rowData: any) => ({
                        poscleint: vm.monoOccurrence.data.poscleint
                    })
                )
            ]),
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                'pvanumdoc',
                'pvadat',
                'ecldsceta',
                'pvacom'
            ]
        };

        //Tâches
        vm.tchMultiOccurrenceOptions = {
            bloc: 'tch',
            fonctions: {
                pagination: true
            },
            actionsMore: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_tch',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItemDivider('G_LBL_SO0007'),
                        new FormulaireItem('offcleint_tch'),
                        new FormulaireItem('poscleint_tch'),
                        new FormulaireItem('temcleint_tch')
                    ]),
                    colonnesVisibles: [
                        { nom: 'gradsc', largeur: 210 },
                        { nom: 'tchseqaff', largeur: 130 },
                        { nom: 'tchdsc', largeur: 414 }
                    ]
                })
            ]),
            colonnesVisibles: [
                'gracod',
                'gradsc',
                'tchseqaff',
                'tchdsc'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gracleint', { largeur: 60 }),
                new FormulaireItem('tchseqaff'),
                new FormulaireItem('tchdsc', { largeur: 100 })
            ], { largeurDefaut: 40 })
        };

        // Compétences requises
        vm.corMultiOccurrenceOptions = {
            bloc: 'cor',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    () => ({
                        stamnetab: 'POS',
                        corcleintref: vm.monoOccurrence.data.poscleint
                    }),
                    {
                        onClose() {
                            vm.corMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    (data: any) => ({
                        id: data.corcleint,
                        stamnetab: 'POS',
                        corcleintref: data.poscleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ]),
            actionsMore: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_cor',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItemDivider('G_LBL_SO0007'),
                        new FormulaireItem('offcleint_cor'),
                        new FormulaireItem('poscleint_cor'),
                        new FormulaireItem('temcleint_cor'),
                        new FormulaireItem('urgcleint_cor')
                    ]),
                    colonnesVisibles: [
                        { nom: 'grcdsc', largeur: 262 },
                        { nom: 'corseqaff', largeur: 130 },
                        { nom: 'comcod', largeur: 120 },
                        { nom: 'comdsc', largeur: 262 },
                        { nom: 'corflgpae', largeur: 91 }
                    ]
                })
            ]),
            colonnesVisibles: [
                'grcdsc',
                'corseqaff',
                'comdsc',
                'vacdsc',
                'corfacimp',
                'corflgidecan'
            ]
        };
    }
}
