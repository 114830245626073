import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0005 {
multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0005Controller(mc0161Route: IRoute,
                                        Menu: IMenuClass,
                                        MenuItemForage: IMenuItemForageClass) {
const vm: IPageMc0005 = this;

vm.multiOccurrenceOptions = {
    navigatePage: mc0161Route.NAME,
    actionsNouveaux: new Menu([
        new MenuItemForage(mc0161Route.NAME)
    ]),
    fonctions: {
        rechercheCollapsable: false
    },
    colonnesVisibles: [
        {nom: 'taxcod'          , largeur: 120},
        {nom: 'taxdscfed'       , largeur: 230},
        {nom: 'vaetaxmodfed_dsc', largeur: 160},
        {nom: 'taxdscpro'       , largeur: 230},
        {nom: 'vaetaxmodpro_dsc', largeur: 160},
        {nom: 'vaetaxcal_dsc'   , largeur: 160}
    ]
};
}
