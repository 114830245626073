import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageMR0052 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireMtg: IFormulaire;
    formulaireFlg: IFormulaire;
}

/* @ngInject */
export default function Mr0052Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMR0052 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireMtg = new Formulaire('LBL_BLC_MTG', [

            new FormulaireItem('mtgnummth',{largeur:25}),
            new FormulaireItem('mtgdsc',{largeur:40}),
            new FormulaireItem('vaemtgtagavi',{largeur:30}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('mtgdoc',{
                enableCount: true,
                largeur:100 })
        ]);

        vm.formulaireFlg = new Formulaire('LBL_BLC_FLG', [

            new FormulaireGroupe('LBL_DIV_PARTAG', new Formulaire([
                new FormulaireItem('mtgflgtagcol',    {default: 0}),
                new FormulaireItem('mtgflgtagcolbas', {default: 0}),
                new FormulaireItem('mtgflgtagsalsup', {default: 0}),
                new FormulaireItem('mtgflgtagunc',    {default: 0}),
                new FormulaireItem('mtgflgtagunctau', {default: 0}),
                new FormulaireItem('mtgflgtagava',    {default: 0}),
                new FormulaireItem('mtgflgtagrce',    {default: 0})
            ],{largeurDefaut: 100}), {largeur: 25}),

            new FormulaireGroupe('LBL_DIV_PARDET', new Formulaire([
                new FormulaireItem('mtgflgpartem',       {default: 0}),
                new FormulaireItem('mtgflgparfct',       {default: 0}),
                new FormulaireItem('mtgflgparmnt',       {default: 0}),
                new FormulaireItem('mtgflgparunc',       {default: 0}),
                new FormulaireItem('mtgflgparprctaudem', {default: 0}),
                new FormulaireItem('mtgflgparmax',       {default: 0}),
                new FormulaireItem('mtgflgparbqe',       {default: 0}),
                new FormulaireItem('mtgflgparbor',       {default: 0}),
                new FormulaireItem('mtgflgparbqeqte',    {default: 0}),
                new FormulaireItem('mtgflgparbqemnt',    {default: 0})
            ],{largeurDefaut: 100}), {largeur: 25}),


            new FormulaireGroupe('LBL_DIV_SUPSAL', new Formulaire([
                new FormulaireItem('mtgflgtrssalsupmnt', {default: 0}),
                new FormulaireItem('mtgflgtrssalsupprc', {default: 0}),

                new FormulaireItemDivider('LBL_DIV_PRIAFT'),
                new FormulaireItem('mtgflgtrspriaftmnt', {default: 0}),

                new FormulaireItemDivider('LBL_DIV_CALREMUN'),
                new FormulaireItem('mtgflgcalsal', {default: 0})
            ],{largeurDefaut: 100}), {largeur: 25}),

             new FormulaireGroupe('LBL_DIV_INFOTRN', new Formulaire([
                new FormulaireItem('mtgflgtrsqte',    {default: 0}),
                new FormulaireItem('mtgflgtrsmnt',    {default: 0}),
                new FormulaireItem('mtgflgtrstau',    {default: 0}),
                new FormulaireItem('mtgflgtrstemaft', {default: 0}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemSautDeLigne(),

                new FormulaireItemDivider('LBL_DIV_SAISIETRN'),
                new FormulaireItem('mtgflgtrsfepava', {default: 0}),
                new FormulaireItem('mtgflgtrsfep',    {default: 0}),
                new FormulaireItem('mtgflgtrsder',    {default: 0}),

                 new FormulaireItemDivider('LBL_DIV_DEMREMUN'),
                 new FormulaireItem('mtgflgtdrcalmnt', {default: 0})
            ],{largeurDefaut: 100}), {largeur: 25}),

        ],{largeurDefaut: 14.28});

    }

}
