
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageTF0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0005Controller(tf2005Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {

    const vm: IPageTF0005 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: tf2005Route.NAME,
        navigateParams: (data: any) => ({
            id: data.ptxcleint
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage('', tf2005Route.NAME, () => ({}
            ), {
                fonction: 'BOUNOUV'
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'folcod', largeur: 33 },
            { nom: 'folann', largeur: 33 },
            { nom: 'foldsc', largeur: 33 },
            { nom: 'vaeptxtyppre', largeur: 33 },
            { nom: 'vaeptxprorel', largeur: 33 },
            { nom: 'ptxmntmin', largeur: 33 },
            { nom: 'ptxcptfic', largeur: 33 },
            { nom: 'ptxidedec', largeur: 33 },
            { nom: 'ptxinfnom', largeur: 33 },
            { nom: 'ptxctbnom', largeur: 33 }
        ]
    }
}


