import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageGS0109 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAtb: IFormulaire;
    formulaireAtbinf: IFormulaire;
    formulaireAtbdonnum: IFormulaire;
    formulaireAtbbalxml: IFormulaire;
    formulaireAtbReqSql: IFormulaire;
    tbvMultiOccurrenceOptions: IMultiOccurrenceOptions;/*
    formulaireAtb: IFormulaire;/*
    gtrMultiOccurrenceOptions: IMultiOccurrenceOptions;*/
}

/* @ngInject */
export default function Gs0109Controller(gs0110Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageGS0109 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            pageRetourSuppression: gs0110Route.NAME
        };

        vm.formulaireAtb = new Formulaire('LBL_BLC_ATB', [
            new FormulaireItem('atbcod'),
            new FormulaireItem('atbflgnonpre'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('atblib'),
            new FormulaireItem('vaeatbtypdon'),
            new FormulaireItem('atblngdon'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('atbfrmdat'),
            new FormulaireItem('atbsufaff'),
            new FormulaireItem('atbmas'),
            new FormulaireItem('atbmasaff'),
            new FormulaireItemDivider('LBL_STATBT_ATB'),
            new FormulaireItem('atbflgact', {default: 1, largeur: 33.33}),
            new FormulaireItem('atbdatdeb', {required: true, largeur: 33.33}),
            new FormulaireItem('atbdatfin', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('atbtyplet', {default: '3', minWidth: 350 }),
            new FormulaireItem('atblovaff', {default: 'COD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('stacleint'),
            new FormulaireItem('stccleint'),
            new FormulaireItem('stacleintrec'),
            new FormulaireItem('stccleintrec'),
            new FormulaireItem('atbaid', { largeur: 100 }),
            new FormulaireItem('atbdsc', { largeur: 100, enableCount: true })
        ]);

        vm.formulaireAtbinf = new Formulaire('LBL_DIV_ATBINF', [
            new FormulaireItem('atbflgobl'),
            new FormulaireItem('atbflglngfix'),
            new FormulaireItem('atbflggrpdon'),
            new FormulaireItem('atbflgfil'),
            new FormulaireItem('atbflglstval'),
            new FormulaireItem('atbflgattmul'),
            new FormulaireItem('atbflgsup'),
            new FormulaireItem('atbflgbasfac')
        ], { largeurDefaut: 33.33 });

        vm.formulaireAtbdonnum = new Formulaire('LBL_DIV_ATBDONNUM', [
            new FormulaireItem('atbnbrdec'),
            new FormulaireItem('atbflgzero'),
            new FormulaireItem('atbvalmin'),
            new FormulaireItem('atbvalmax'),
        ]);

        vm.formulaireAtbbalxml = new Formulaire('LBL_DIV_ATBBALXML', [
            new FormulaireItem('atbbalrol'),
            new FormulaireItem('atbbalcerava'),
            new FormulaireItem('atbbalcerapr'),
            new FormulaireItem('atbflgsupbalapr')
        ], { largeurDefaut: 50 });

        vm.formulaireAtbReqSql = new Formulaire('LBL_DIV_ATBREQSQL', [
            new FormulaireItem('atbreqsql', { largeur: 100, enableCount: true })
        ]);

        vm.tbvMultiOccurrenceOptions = {
            bloc: 'tbv',
            fonctions: {
                importation: true,
                pagination: true
            },
            colonnesVisibles: [
                'tbvcod',
                'tbvdsc'
            ],
            formulaire: new Formulaire('LBL_BLC_TBV', [
                new FormulaireItem('tbvcod', {maxlength: 6, largeur: 33.33}),
                new FormulaireItem('tbvdsc', {largeur: 66.66})
            ])
        };
    }
}
