import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
interface IPageRR0096 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0096Controller(rr0097Route:IRoute,
                                         Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0096 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            selectionCycle: false,
            rechercheCollapsable: false
        },
        navigatePage: rr0097Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.aftcleint,
                trncleint: rowData.trncleint
            };
        },
        filtresParametres: [            
            { colonne: 'demcleint', affichage: false },
            { colonne: 'lemcleint', affichage: false }
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datlim', { default: null })
        ]),
        colonnesCachees: ['eplcleint', 'trncleint', 'flgpossupaft', 'flgunorhaft', 'flgunovacaft', 'flglitaft'],
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100},
            { nom: 'eplnomprn', largeur: 250}
        ],
        colonnesVisibles: [
            {nom: 'aftdatdeb'   , largeur: 100},
            {nom: 'aftdatfin'   , largeur: 100},
            {nom: 'temcod'      , largeur: 115},
            {nom: 'temdscabr'   , largeur: 285},
            {nom: 'temhreheb'   , largeur: 120},
            {nom: 'posnumdoc'   , largeur: 115},
            {nom: 'postitabr'   , largeur: 285},
            {nom: 'aftcod'      , largeur: 115},
            {nom: 'tafcod'      , largeur: 115},
            {nom: 'tafdsc'      , largeur: 285},
            {nom: 'aftflgpri'   , largeur: 74},
            {nom: 'aftflgttu'   , largeur: 74},
            {nom: 'tafflgprb'   , largeur: 74},
            {nom: 'tafflgaft'   , largeur: 94},
            {nom: 'tafflgaccanc', largeur: 94},
            {nom: 'aftdatdebadm', largeur: 100},
            {nom: 'aftdatfinadm', largeur: 100},
            {nom: 'aftflgpro'   , largeur: 74},
            {nom: 'aftflgfincon', largeur: 110},
            {nom: 'aftdatfinpai', largeur: 160},
            {nom: 'poscodsup'   , largeur: 135},
            {nom: 'postitabrsup', largeur: 280},
            {nom: 'nomsup'      , largeur: 200},
            {nom: 'lemcod'      , largeur: 115},
            {nom: 'lemdsc'      , largeur: 280},
            {nom: 'lemdatdeb'   , largeur: 100},
            {nom: 'lemdatfin'   , largeur: 100},
            {nom: 'aftdsc'      , largeur: 280},
            {nom: 'unocodrh'    , largeur: 120},
            {nom: 'unodscabrrh' , largeur: 280},
            {nom: 'unocodvac'   , largeur: 120},
            {nom: 'unodscabrvac', largeur: 280},
            {nom: 'litcod'      , largeur: 115},
            {nom: 'litdsc'      , largeur: 280},
            {nom: 'aftpostitfer', largeur: 280},
            {nom: 'aftcom'      , largeur: 280},
            {nom: 'unpcod'      , largeur: 115},
            {nom: 'unpdsc'      , largeur: 280}
        ]
    };
}
