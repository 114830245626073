// Module
var code = `<ex-mono-occurrence-ecran mono-occurrence-out="vm.monoOccurrence" mono-occurrence-options="vm.monoOccurrenceOptions" largeur-formulaire="65"> <ex-mono-occurrence-ecran-maitre> <ex-card-content> <div layout="row" layout-xs="column"> <ex-data-list flex="50" flex-xs="100" largeur-texte="30" cols="::['eplnummat', 'eplnom', 'eplprn', 'eplnomnai', 'epladrcou', 'vaeepllng']"> </ex-data-list> <ex-data-list flex="50" flex-xs="100" largeur-texte="30" menus="::vm.menuSiru" cols="::['eplsrucod', 'eplnomusu', 'eplprnusu']"> </ex-data-list> </div> <div layout="row"> <ex-data flex="50" col="eplnumref1" ng-if="vm.valeursEcran.eplnumref1_flg"></ex-data> <ex-data flex="50" col="eplnumref2" ng-if="vm.valeursEcran.eplnumref2_flg"></ex-data> </div> <div layout="row"> <ex-data flex="50" col="eplnumref3" ng-if="vm.valeursEcran.eplnumref3_flg"></ex-data> <ex-data flex="50" col="eplnumref4" ng-if="vm.valeursEcran.eplnumref4_flg"></ex-data> </div> </ex-card-content> </ex-mono-occurrence-ecran-maitre> <ex-mono-occurrence-ecran-droite> <ex-card lbl-titre="TITRE_MSG"> <ex-card-actions> <ex-menu-item menu-item="::vm.forageEditionZoneMessage"></ex-menu-item> </ex-card-actions> <ex-card-content> <ex-case alignment="none" col="message"></ex-case> </ex-card-content> </ex-card> </ex-mono-occurrence-ecran-droite> <ex-mono-occurrence-ecran-bas> <ex-dashboard-card> <ex-dashboard-card-content-left> <ex-card lbl-titre="TITRE_LIEN_EPL" ex-bloc="lem"> <ex-card-content> <ex-data-list flex="100" largeur-texte="20" menus="::vm.menus" cols="::['lemcleint']"> </ex-data-list> <ex-data-list largeur="50" largeur-texte="40" cols="::[
                                               {nom: 'lemdatdebfin', secondaire: true, titre: ' '},
                                               {nom: 'lemflgmul', secondaire: true, libelleValeur: true, alignmentLibelle: 'right'}
                                              ]"> </ex-data-list> <ex-data-list flex="100" largeur-texte="20" menus="::vm.menus" cols="::['urgcleint', 'strcleint']"> </ex-data-list> <ex-data-list largeur="50" largeur-texte="40" cols="::['tlecleint',
                                               {nom: 'tleflgpri', secondaire: true, libelleValeur: true}
                                              ]"> </ex-data-list> </ex-card-content> </ex-card> <ex-card-multi-template ex-bloc="ead" opened="false" lbl-titre="LBL_BLC_EAD" libelles="::vm.monoOccurrence.libelles" multi-occurrence-options="::vm.eadMultiOccurrenceOptions"> <ex-card-multi-template-row> <ex-case col="eadadrfmt"></ex-case> <ex-case alignment="right"> <ex-case col="eaddatvig" secondaire="true"></ex-case> <ex-case clear="true" ng-if="data.eadflgchq"> <ex-case-coche col="eadflgchq" class="p-l-8"></ex-case-coche> </ex-case> <ex-case clear="true" ng-if="data.eadflgfinann"> <ex-case-coche col="eadflgfinann" class="p-l-8"></ex-case-coche> </ex-case> </ex-case> </ex-card-multi-template-row> </ex-card-multi-template> <ex-card-multi-template ex-bloc="ide" opened="false" lbl-titre="LBL_BLC_IDE" libelles="::vm.monoOccurrence.libelles" multi-occurrence-options="::vm.ideMultiOccurrenceOptions "> <ex-card-multi-template-row> <ex-case cols="::['comcod', 'comdsc']" ng-if="!(data.dipcod && data.dipdsc)"></ex-case> <ex-case cols="::['dipcod', 'dipdsc']" hide-vide="true"></ex-case> <ex-case col="ideannobt" secondaire="true" alignment="right" ng-if="::data.ideannobt"> <ex-libelle libelle="LBL_ANNOBT"></ex-libelle>: {{::data.ideannobt}} </ex-case> <ex-case col="scldscprg" secondaire="true" hide-vide="true"></ex-case> </ex-card-multi-template-row> </ex-card-multi-template> </ex-dashboard-card-content-left> <ex-dashboard-card-content-right> <ex-card lbl-titre="TITRE_AFT" ex-bloc="aft"> <ex-card-content> <ex-data-list flex="100" largeur-texte="20" menus="::vm.menus" cols="::['aftcoddscfmt']"></ex-data-list> <ex-data-list largeur="50" largeur-texte="40" cols="::[{nom: 'unocoddscabr', secondaire: true, titre: ' '}]"></ex-data-list> <ex-data-list largeur="50" largeur-texte="40" cols="::[
                                           {nom: 'aftdatdebfin', secondaire: true, titre: ' '},
                                           {nom: 'aftflgmul', secondaire: true, libelleValeur: true, alignmentLibelle: 'right'}
                                          ]"> </ex-data-list> <ex-data-list flex="100" largeur-texte="20" cols="::['temcoddscabr']"></ex-data-list> <ex-data-list largeur="50" largeur-texte="40" hide-vide="true" cols="::['posnumdoctitabr',
                                           {nom: 'aftflgttu', secondaire: true, libelleValeur: true, alignmentLibelle: 'right'}
                                          ]"> </ex-data-list> <ex-data-list flex="100" largeur-texte="20" cols="::['nomsup']"></ex-data-list> </ex-card-content> </ex-card> <ex-card-multi-template ex-bloc="etl" opened="false" lbl-titre="LBL_BLC_ETL" libelles="::vm.monoOccurrence.libelles" multi-occurrence-out="vm.etlMultiOccurrence" multi-occurrence-options="::vm.etlMultiOccurrenceOptions"> <ex-card-multi-template-row> <ex-case col="etlnumtel"></ex-case> <ex-case col="etldatvig" alignment="right" secondaire="true"></ex-case> <ex-case ng-if="data.etladrcou" col="etladrcou" clear="true"></ex-case> <ex-case ng-if="data.etldsc" col="etldsc" secondaire="true" clear="true"></ex-case> <ex-case col="ttldsc" secondaire="true" clear="true"></ex-case> </ex-card-multi-template-row> </ex-card-multi-template> </ex-dashboard-card-content-right> </ex-dashboard-card> </ex-mono-occurrence-ecran-bas> </ex-mono-occurrence-ecran> `;
// Exports
module.exports = code;