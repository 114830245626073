import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0013 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDda: IFormulaire;
    valeursEcran: any;
}

interface IRr0013Params {    id: string;
    demcleint: string;
    trncleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0013Controller($stateParams: IRr0013Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr0013 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireDda = new Formulaire('LBL_DATES', [
            new FormulaireItem('tdacleint', {
                largeur: 50
            }),
            new FormulaireItem('aut__tdaflgman'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ddadatref', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ddacom', {
                largeur: 100
            }),
            new FormulaireItem('demcleint', {
                default: $stateParams.demcleint
            })
        ]);
    }
}
