import {module} from 'angular';
import EcranQlikController from './ex-ecran-qlik.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-ecran-qlik', []).component('exEcranQlik', {
    bindings: Binding(
        {
            sheet: '@sheet',
            app: '@'
        }
    ),
    require: {
        ecranContextCtrl: '^^exEcranContext'
    },
    controller: EcranQlikController,
    controllerAs: 'vm',
    template: require('./ex-ecran-qlik.html')
});
