import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageRr0145 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireSci: IFormulaire;
    scxMultiOccurrenceOptions: IMultiOccurrenceOptions;

}

/* @ngInject */
export default function Rr0145Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0145 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            noId: true,
        };

        vm.formulaireSci = new Formulaire('LBL_BLC_SCI', [
                new FormulaireItem('tagcleint', {largeur: 100}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('sciflgsaircedec'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('sciflgaffrcedec')
            ]
        );

        vm.scxMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'vaetypsimpaiweb', largeur: 241},
                {nom: 'scxflgdec', largeur: 130}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vaetypsimpaiweb'),
                new FormulaireItem('scxflgdec')
            ])
        };

    }
}
