import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageCO0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0003Controller(co0025Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageCO0003 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: co0025Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(co0025Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'tyccod'   , largeur: 90},
            {nom: 'tycdsc'   , largeur: 245},
            {nom: 'vacvalcle'   , largeur: 100},
            {nom: 'vacdsc'   , largeur: 240},
            {nom: 'tycflgdatobt'   , largeur: 80},
            {nom: 'tycflgdatderutl'   , largeur: 90},
            {nom: 'tycflgdatexp'   , largeur: 70},
            {nom: 'tycflginfscl', largeur: 70},
            {nom: 'tycflginfexp', largeur: 70}
        ],
    };
}
