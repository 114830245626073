import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0557',
    ecrans: ['00-2'],
    params: [
        'axe',
        'selection',
        'pamcleint',
        'rcecleint',
        'ppacleint'
    ]
});
