import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageMr0228 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0228Params {
    doccleintgrh: string;
}
/* @ngInject */
export default function Mr0228Controller($stateParams: IMr0228Params,
                                         Operateur: IOperateurService) {
    const vm: IPageMr0228 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        }, 
        colonnesVisibles: [
            {nom: 'dvadat', largeur: 150},
            {nom: 'dvadateva', largeur: 150},
            {nom: 'dvadsc', largeur: 300},
            {nom: 'usrcod', largeur: 150},
            {nom: 'usrnom', largeur: 300}
        ]
    };
}
