import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";

interface IPageMc0109 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    formulaireAjf: IFormulaire;
    ajvMultiOccurrence: IMultiOccurrence;
    ajvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    copievent: IMenuItem;
}
interface IPageMc0109Params {
    doccleintref: number;
}
/* @ngInject */
export default function Mc0109Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IPageMc0109Params,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageMc0109 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Usage d'imputation
        vm.copievent = new MenuItemAction(
            'LBL_BOU_COP',
            'BOUCOP',
            (data: any) => ({ajfcleint: vm.monoOccurrence.data.ajfcleint}),
            {
                icon: 'file_copy',
                iconButton: false,
                messageConfirmation: 'BOUCOP_CONFIRM',
                secondaire: true,
                successAction() { vm.ajvMultiOccurrence.fetchDataList() },
                disabled: (data: any) => {
                    return !data.flgmodifpermise
                }
            });

        vm.monoOccurrenceOptions = {
            infosMonetaires: {
                blocs: ['ajv'],
                donnees:
                    [
                        {nom:'mnt', libelle: 'G_LBL_MNT'},
                        {nom:'vnt', libelle: 'G_LBL_VNT', lastOfGroup: true},
                        {nom:'solde', libelle: 'G_LBL_SOLDE', expectedValue: 0, skipLine: true},
                        {nom:'devise', libelle: 'G_LBL_DEVISE'}
                    ]
            },
            infosFinancieres: {
                disponibilite: true,
                impactFinancier: true,
                impactBudgetaire: true
            }
        };

        vm.formulaireAjf = new Formulaire('LBL_BLC_AJF', [
            new FormulaireItem('ajfnumdoc', {largeur: 33}),
            new FormulaireItem('ajfnumdocseq', {largeur: 33, readonly:true}),
            new FormulaireItem('doccleintref', { hidden: true, default: $stateParams.doccleintref }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tydcleint', {largeur: 33, modifAllow: false}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('peccleint', {largeur: 33}),
            new FormulaireItem('ajfdat', {largeur: 33}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('intcleintreq', {largeur: 33, longueurFixe: 6}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ajfdsc', {largeur: 67,  required: true, enableCount: true}),
        ]);

        vm.ajvMultiOccurrenceOptions = {
            fonctions: {
                editionRapide: true
            },
            editionRapideActive: true,
            colonnesFixesDroite: [
                {nom: 'ajvflgmsg', largeur: 100}
            ],
            colonnesFixesGauche: [
                {nom: 'ajvimp', largeur: 315,
                    //TODO::L'ecran n'est pas développé
                    /*menu: [
                        new MenuItemForage(
                            'LBL_FORAGE_UBR',
                            mc0044Route.NAME,
                            (data: any) => {
                                return {
                                    ecran: '00-2',
                                    id: data.prucleint
                                };
                            }
                        )
                    ]*/
                },
                {nom: 'ajvmntajt', largeur: 126}
            ],
            colonnesVisibles: [
                {nom: 'taxcod', largeur: 72},
                {nom: 'remcod', largeur: 75},
                {nom: 'ajvmnttaxfed', largeur: 126},
                {nom: 'ajvmnttaxpro', largeur: 126},
                {nom: 'ajvmntremfed', largeur: 126},
                {nom: 'ajvmntrempro', largeur: 126},
                {nom: 'ajvmntnet', largeur: 126},
                {nom: 'ajvdsc', largeur: 262},
                {nom: 'refcod', largeur: 90},
                {nom: 'refnom', largeur: 262}
            ],
            colonnesEditionRapide: [
                {nom: 'ajvimp', largeur: 50},
                {nom: 'ajvmntajt', largeur: 20},
                {nom: 'taxcleint', largeur: 250},
                {nom: 'remcleint', largeur: 150},
                {nom: 'ajvmnttaxfed', largeur: 100},
                {nom: 'ajvmnttaxpro', largeur: 100},
                {nom: 'ajvmntremfed', largeur: 100},
                {nom: 'ajvmntrempro', largeur: 100},
                {nom: 'ajvmntnet', largeur: 120},
                {nom: 'ajvdsc', largeur: 250},
                {nom: 'refcleint', largeur: 150},
                {nom: 'ajvflgmsg', largeur: 150},
                {nom: 'ajvflgref', largeur: 100, default: '0', hidden: true}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ajvimp', {required: true}),
                new FormulaireItem('ajvmntajt', {required: true}),
                new FormulaireItem('ajvmntnet', {readonly: true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('taxcleint'),
                new FormulaireItem('ajvmnttaxfed'),
                new FormulaireItem('ajvmnttaxpro'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('remcleint', {readonly: true}),
                new FormulaireItem('ajvmntremfed', {readonly: true}),
                new FormulaireItem('ajvmntrempro', {readonly: true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('refcleint', {
                    resourceParams: {
                        type: 'CLI'
                    },
                    disabled: (data: any) => {
                        return vm.valeursEcran.cliindentmlt !== 1 && data.refcleint === null

                    }
                }),
                new FormulaireItem('ajvdsc', {largeur: 67}),
                new FormulaireItem('ajvflgmsg', { readonly: true, required: false, titre: " " })
            ],{
                largeurDefaut: 33
            }),
            colonnesCachees: [
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint',
                'ajvmsg'
            ]
        };
    }
}
