import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0286 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePdtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0286Controller(Menu: IMenuClass,
                                         $stateParams: any,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0286 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 105},
            {nom: 'rcedscabr', largeur: 236},
            {nom: 'urgcod', largeur: 175},
            {nom: 'urgdscabr', largeur: 256},
            {nom: 'cxddatvig', largeur: 115},
            {nom: 'cxdvaldef', largeur: 139},
            {nom: 'cxdflgact', largeur: 74}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rcecleint', {largeur: 20}),
            new FormulaireItem('urgcleint', {largeur: 20}),
            new FormulaireItem('cxddatvig', {largeur: 20}),
            new FormulaireItem('cxdvaldef', {largeur: 20}),
            new FormulaireItem('cxdflgact', {largeur: 20, default: 1})
        ])
    };
}
