import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMC2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc2000Controller(mc0083Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMC2000 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0083Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0083Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'srvcod', largeur: 130},
            {nom: 'srvdsc', largeur: 400},
            {nom: 'srvdscabr', largeur: 400},
            {nom: 'srvindact', largeur: 100},
            {nom: 'srvdatdeb', largeur: 130},
            {nom: 'srvdatfin', largeur: 130}
        ]
    };
}
