import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'pf0015',
    ecrans: ['00-2',
             '01-2'],
    params: ['?rbucleintpar',
             '?cabcleintpar',
             '?unocleintrh' ]
});
