import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0280 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function mr0280Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0280 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'tdlcod', largeur: 100},
            {nom: 'tdldsc', largeur: 315},
            {nom: 'prfcod', largeur: 100},
            {nom: 'prfdsc', largeur: 315},
            {nom: 'mfaseq', largeur: 100},
            {nom: 'mfaflgimp', largeur: 100}
        ],
        formulaire: new Formulaire('LBL_TITRE',[
            new FormulaireItem('tdlcleint'),
            new FormulaireItem('prfcleint'),
            new FormulaireItem('mfaseq'),
            new FormulaireItem('mfaflgimp')
        ], {largeurDefaut: 25})
    };
}
