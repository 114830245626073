import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCP0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0022Controller(
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCP0022 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup'),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        colonnesFixesGauche: [
            { nom: 'connumdoc', largeur: 89 }
        ],
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 105},
            {nom: 'refcod', largeur: 105},
            {nom: 'refnom', largeur: 231},
            {nom: 'condatdeb', largeur: 100},
            {nom: 'condatfin', largeur: 100},
            {nom: 'founomabr', largeur: 100},
            {nom: 'vaeconstu', largeur: 91},
            {nom: 'conflgnonren', largeur: 105},
            {nom: 'srvcod', largeur: 89},
            {nom: 'srvdscabr', largeur: 209},
            {nom: 'coaann', largeur: 76},
            {nom: 'condsc', largeur: 266},
            {nom: 'connumref', largeur: 165},
            {nom: 'conmnt', largeur: 126},
            {nom: 'vaesysdevcod', largeur: 121},
            {nom: 'vecconcat_dsc', largeur: 142},
            {nom: 'conmntcon', largeur: 126},
            {nom: 'usrcod', largeur: 110},
            {nom: 'nibnom', largeur: 206},
            {nom: 'etcnumdoc', largeur: 120},
            {nom: 'etcdsc', largeur: 206}
        ]
    };
}
