import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageCS0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cs0008Controller(cs0007Route: IRoute,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageCS0008 = this;
    vm.multiOccurrenceOptions = {
        navigatePage: cs0007Route.NAME,
        fonctions: {
          nouveau: false
        },
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'dcsnumdoc', largeur: 120},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'dcscssnum', largeur: 100},
            {nom: 'dcscssdatenv', largeur: 105},
            {nom: 'dcscssflgopp', largeur: 100},
            {nom: 'dcscssdatadm', largeur: 126},
            {nom: 'dcscssflgadm', largeur: 86},
            {nom: 'dcsdpcdatdem', largeur: 120},
            {nom: 'dcsrdcraddatdem', largeur: 120},
            {nom: 'dcsrdcbemdatdem', largeur: 105},
            {nom: 'dcsrdcclpdatdem', largeur: 105},
            {nom: 'dcsrpcraddatdem', largeur: 120},
            {nom: 'dcsrpcclpdatdem', largeur: 110},
            {nom: 'dcsdatcon', largeur: 120},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'ecldsceta', largeur: 183},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75} ]
    };
}
