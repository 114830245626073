import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IMenuItemClass } from "../../../../../core/services/menu/menu-item.service";

interface IPageMc1016 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireImp: IFormulaire
}
/* @ngInject */
export default function Mc1016Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItem: IMenuItemClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass) {
    const vm: IPageMc1016 = this;
    //colwidths
    const [cw1, cw2, cw3, cw4, cw4a, cw5] = [25, 5, 15, 15, 15, 15];

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            //Ligne 1
            new FormulaireItemSautDeColonne({ largeur: cw1 }),
            new FormulaireItemSautDeColonne({ largeur: cw2 }),
            new FormulaireItem('mntbrut', { largeur: cw3 }),
            new FormulaireItem('vaesysdevcodbru', { largeur: cw4a }),
            new FormulaireItem('devtaucoubru', { hidden: true }),
            new FormulaireItem('devtaucounet', { hidden: true }),
            new FormulaireItemSautDeLigne(),
            //Ligne 2
            new FormulaireItem('taxcleint', { largeur: cw1 }),
            new FormulaireItem('tattauappfed', { titre: " ", largeur: cw2, disabled: true }),
            new FormulaireItem('mnttaxfed', { largeur: cw3, disabled: true }),
            new FormulaireItemSautDeLigne(),
            //Ligne 3
            new FormulaireItemSautDeColonne({ largeur: cw1 }),
            new FormulaireItem('tattauapppro', { titre: " ", largeur: cw2, disabled: true }),
            new FormulaireItem('mnttaxpro', { largeur: cw3, disabled: true }),
            new FormulaireItemSautDeLigne(),
            //Ligne 4
            new FormulaireItem('remcleint', { largeur: cw1 }),
            new FormulaireItem('rtataufed', { titre: " ", largeur: cw2, disabled: true }),
            new FormulaireItem('mntremfed', { largeur: cw3, disabled: true }),
            new FormulaireItem('rtataucti', { titre: " ", largeur: cw4, disabled: true }),
            new FormulaireItem('mntremcti', { largeur: cw5, disabled: true }),
            new FormulaireItemSautDeLigne(),
            //Ligne5
            new FormulaireItemSautDeColonne({ largeur: cw1 }),
            new FormulaireItem('rtataupro', { titre: " ", largeur: cw2, disabled: true }),
            new FormulaireItem('mntrempro', { largeur: cw3, disabled: true }),
            new FormulaireItem('rtataurti', { titre: " ", largeur: cw4, disabled: true }),
            new FormulaireItem('mntremrti', { largeur: cw5, disabled: true }),
            new FormulaireItemSautDeLigne(),
            //Ligne 6
            new FormulaireItemSautDeColonne({ largeur: cw1 }),
            new FormulaireItemSautDeColonne({ largeur: cw2 }),
            new FormulaireItem('mntnet', { largeur: cw3 }),
            new FormulaireItem('vaesysdevcodnet', { largeur: cw4a }),
            new FormulaireItemSautDeLigne(),
            //Ligne 7, Boutons calculer et effacer les montants
            new FormulaireItemSautDeColonne({ largeur: cw1 }),
            new FormulaireItemMenuItem(new MenuItemAction("LBL_BOUCAL", "CALCULER", () => ({}), {
                iconButton: false,
                class: 'ex-accent-hue-1-bg',
                successAction: (result, occurrence, params, data) => {
                    data.mntbrut = result.mntbrut;
                    data.mntnet = result.mntnet;
                    data.mnttaxfed = result.mnttaxfed;
                    data.mnttaxpro = result.mnttaxpro;
                    data.mntremfed = result.mntremfed;
                    data.mntrempro = result.mntrempro;
                    data.mntremcti = result.mntremcti;
                    data.mntremrti = result.mntremrti;
                },
            }), { largeur: cw2 }),
            new FormulaireItemSautDeColonne({ largeur: cw3 }),
            new FormulaireItemMenuItem(new MenuItem("LBL_EFFACER_MONTANTS", (event: Event, data: any) => {
                data.taxcleint = undefined;
                data.taxcleint__coddscfedpro = undefined;
                data.remcleint__remcod = undefined;
                data.remcleint__remdscfed = undefined;
                data.remcleint__remdscpro = undefined;
                data.remcleint = undefined;
                data.mntbrut = undefined;
                data.mntnet = undefined;
                data.mnttaxfed = undefined;
                data.mnttaxpro = undefined;
                data.mntremfed = undefined;
                data.mntrempro = undefined;
                data.mntremcti = undefined;
                data.mntremrti = undefined;
                data.rtataufed = undefined;
                data.rtataupro = undefined;
                data.tattauappfed = undefined;
                data.tattauapppro = undefined;
            }, { iconButton: false, icon: "", class: "ex-accent-hue-1-bg" }), { largeur: cw4 }),
        ]),
        fonctions: {
            boutonEnregistrerHaut: false,
            skipChangementsNonSauvegardes: true
        }
    };

    vm.formulaireImp = new Formulaire("LBL_IMPUTATION", [
        new FormulaireItem("imp", { largeur: 50, required: true }),
        new FormulaireItem("pecann", { required: true }),
        new FormulaireItem("vaesysnivctr", { required: true }),
        new FormulaireItemMenuItem(new MenuItemAction("LBL_DISPONIBILITE", "DISPONIBILITE", () => ({}), {
            iconButton: false,
            class: "ex-accent-hue-1-bg",
            successAction: (result, occurrence, params, data) => {
                data.solde = result.solde
                data.remcod = result.remcod
            },
        })),
        new FormulaireItem("solde", { disabled: true }),
        new FormulaireItem("remcod", { disabled: true })
    ], { largeurDefaut: 25 })
}
