import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMonoOccurrence} from "../../../../../core/services/mono-occurrence.service";

interface IMr0170Params {
    id: string;
    menuId: string;
    fpncleint: number;
    apacleint: number;
}

interface IPageMr0170 extends IComponentController {
    formulaireFps: IFormulaire;
    formulaireAutres: IFormulaire;
    monoOccurrence: IMonoOccurrence;
}

/* @ngInject */
export default function Mr0170Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         mr0008Route: IRoute,
                                         $stateParams: IMr0170Params) {
    const vm: IPageMr0170 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Forage au sommaire des montants
        vm.actionParamAvis = new MenuItemEcran(
            'LBL_PARAM_AVI',
            mr0008Route.NAME,
            (data: any) => ({
                id: $stateParams.apacleint,
                cleintref:  data.fpscleint,
                stamnetab:  'FPS',
                vaesystypavi: 'ART',
                supprime: 0,
                typsai:  data.vaesysconadm === 'IMM' || data.vaesysconadm === 'DEL' ? 'JRS' : 'BQE'
            }),
            {
                icon: 'alarm',
                iconButton: false,
                hidden: (data: any) => data.vaesysconadm === 'EXC' || data.vaesysconadm === 'MAN'
            }
        );

        // Condition d'admissibilité
        vm.formulaireFps = new Formulaire('LBL_BLC_FPS', [
            new FormulaireItem('fpncleint', {default: $stateParams.fpncleint}),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('vaesysconadm')
        ], {largeurDefaut: 50});

        vm.formulaireAutres = new Formulaire('LBL_BLC_FPS', [
            new FormulaireItem('fpsvaldel'),
            new FormulaireItem('bqecleint'),
            new FormulaireItem('fpsvalbqe'),
        ], {largeurDefaut: 100})
    }
}
