import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPagePb0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0008Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,                                       
                                         pb0009Route:IRoute
    ) {
    const vm: IPagePb0008 = this;
    

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false            
        },        
        criteresSuggeres: new Formulaire([
            new FormulaireItem('percleint', { required: true, largeur: 25 }),
            new FormulaireItem('intcleint'),
            new FormulaireItem('poscleint'),
            new FormulaireItem('flgequ', { largeur: 25 }),            
            new FormulaireItem('type_poste', { default: 'TOUS', titre: ' Type de poste', largeur: 50 })
        ]),
        colonnesCachees: ['msgerreur', 'msgerreuravr'],
        colonnesVisibles: [
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 236 },
            { nom: 'nbrpos_avec_pre', largeur: 80,  
                menu: [
                    new MenuItemForage(
                        '',
                        pb0009Route.NAME,
                        (rowData: any) => ({
                            id: rowData.ptccleint,
                            flgprevu: '1'
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'nbrpos_sans_pre', largeur: 80,
                menu: [
                    new MenuItemForage(
                        '',
                        pb0009Route.NAME,
                        (rowData: any) => ({
                            id: rowData.ptccleint,
                            flgprevu: '0'
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            }
        ]
    };

}
