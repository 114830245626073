import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";

interface IPageMc0166 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireScb: IFormulaire;
    formulaireIndicateurs: IFormulaire;
    usaimp: IMenuItem;
    usafrb: IMenuItem;
    sscMultiOccurrenceOptions: IMultiOccurrenceOptions;
    frbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0166Controller(mc0046Route: IRoute,
                                         cr0046Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0166 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Usage d'imputation
        vm.usaimp = new MenuItemEcran('LBL_BLC_UVI', mc0046Route.NAME, () => {
            return {
                vaeusient: 'SCB',
                uvicleintref: vm.monoOccurrence.data.scbcleint
            };
        }, {
            icon: 'view_list',
            iconButton: false,
            secondaire: true
        });

        // Usage d'imputation
        vm.usafrb = new MenuItemEcran('LBL_BLC_FRB', cr0046Route.NAME, () => {
            return {
                scbcleint: vm.monoOccurrence.data.scbcleint
            };
        }, {
            icon: 'view_list',
            iconButton: false,
            secondaire: true
        });

        vm.formulaireScb = new Formulaire('LBL_BLC_SCB', [
            new FormulaireItem('inscleint'),
            new FormulaireItem('succleint'),
            new FormulaireItem('scbfol'),
            new FormulaireItem('scbfoltfe'),
            new FormulaireItem('scbcentra'),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireItem('scbdateff'),
            new FormulaireItem('cptcleint')
        ], {largeurDefaut: 50});

        vm.formulaireIndicateurs = new Formulaire('LBL_BLC_IND', [
            new FormulaireItem('scbflgemichq', {default: 1}),
            new FormulaireItem('scbflgpronum', {default: 1})
        ]);

        vm.sscMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'sscnumdeb'   , largeur: 160},
                {nom: 'sscnumfin'   , largeur: 160},
                {nom: 'sscnumpro'   , largeur: 160},
                {nom: 'sscnumlng'   , largeur: 120},
                {nom: 'sscflgaffzer', largeur: 120},
                {nom: 'sscflgstu'   , largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('sscnumdeb'),
                new FormulaireItem('sscnumfin'),
                new FormulaireItem('sscnumpro'),
                new FormulaireItem('sscnumlng'),
                new FormulaireItem('sscflgaffzer'),
                new FormulaireItem('sscflgstu')
            ], {largeurDefaut: 25})
        };

        vm.frbMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'rrccod', largeur: 160},
                {nom: 'rrcdsc', largeur: 300},
                {nom: 'frbdat', largeur: 120},
                {nom: 'frbmnt', largeur: 126}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rrccleint'),
                new FormulaireItem('frbdat'),
                new FormulaireItem('frbmnt')
            ], {largeurDefaut: 33})
        }
    }
}
