import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageAs0026 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireAgt: IFormulaire;
    formulaireMessage: IFormulaire;
    multiOccurrenceOptionsAgt: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0026Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageAs0026 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },

        formulaire: new Formulaire('LBL_BLC_APG', [
            new FormulaireItem('tagcleint', {
                largeur: 50,
                modifAllow: false
            }),
        ], { largeurDefaut: 50 })
    }

    vm.formulaireMessage = new Formulaire('LBL_MSG', [
        new FormulaireItem('apgseqaffemp', { largeur: 20, maxlength: 3 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('apgdscemp', { largeur: 100 } ),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('apgexpemp', { largeur: 100 }),
        new FormulaireItem('apgflgcomobl', { largeur: 40 }),
    ], { largeurDefaut: 30 });

    vm.multiOccurrenceOptionsAgt = {
        bloc: 'agt',
        colonnesVisibles: [
            { nom: 'agtcod', largeur: 30 },
            { nom: 'agtdsc', largeur: 50 },
            { nom: 'agtflgact', largeur: 50 },
            { nom: 'agtflgbal', largeur: 50 },
            { nom: 'agtflgatf', largeur: 50 },
        ],
        colonnesCachees: [
            'tagcleint'
        ],

        formulaire: new Formulaire('LBL_BLC_AGT', [
            new FormulaireItem('asgcleint', { largeur: 33 }),
            new FormulaireItem('tagcleint', {
                largeur: 33,
                hidden: true,
                default: (data: any) => vm.monoOccurrence.data.tagcleint
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('agtflgact', { largeur: 33 }),
            new FormulaireItem('agtflgbal', { largeur: 33 }),
            new FormulaireItem('agtflgatf', { largeur: 33 }),

        ], { largeurDefaut: 100 })
    };
}
