import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';

interface IPageAs0051 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0051Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService,
                                         $stateParams: IStateParamsService) {
    const vm: IPageAs0051 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 210, hidden: $stateParams.employe},
            {nom: 'mplnumdoc', largeur: 110},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 252},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'mpldatdeb', largeur: 100},
            {nom: 'mpldatfin', largeur: 100},
            {nom: 'cxpcod', largeur: 105},
            {nom: 'cxpdsc', largeur: 262},
            {nom: 'etpcod', largeur: 89},
            {nom: 'etpdscabr', largeur: 262},
            {nom: 'aftcod', largeur: 105},
            {nom: 'aftdsc', largeur: 262},
            {nom: 'temcod', largeur: 89},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'asgcod', largeur: 128},
            {nom: 'asgdsc', largeur: 262},
            {nom: 'aeqcod', largeur: 94},
            {nom: 'aeqnom', largeur: 262},
            {nom: 'ahtcod', largeur: 89},
            {nom: 'ahtdsc', largeur: 262},
            {nom: 'actcod', largeur: 89},
            {nom: 'actdsc', largeur: 262},
            {nom: 'tslnumdoc', largeur: 131},
            {nom: 'mplcom', largeur: 499},
            {nom: 'mplflgnonmineff', largeur: 55},
            {nom: 'trnflgmsg', largeur: 63},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
