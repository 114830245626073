import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageTs0020 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tpxMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ts0020Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         ts0005Route:IRoute) {
    const vm: IPageTs0020 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.tpxMultiOccurrenceOptions = {
            fonctions: {
                suiviModification: false
            },
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_DEC_IND',
                    ts0005Route.NAME,
                    (data:any) => ({
                        eplnomprn:data.eplnomprn,
                        demcleint:data.demcleint
                    }),
                    {
                        icon: 'event_busy',
                        fonction:'BTN_DEC'
                    }
                )
            ]),
            bloc: 'tpx',
            colonnesCachees: ['tpxcleint','demcleint'],
            colonnesVisibles: [
                {nom: 'eplnummat', largeur: 100},
                {nom: 'eplnomprn', largeur: 262},
                {nom: 'aeqcod'   , largeur: 100},
                {nom: 'teldom'   , largeur: 120},
                {nom: 'telcel'   , largeur: 110},
                {nom: 'disp'     , largeur: 120}
            ]
        };
    }
}
