import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFilterDate } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';


interface IPageSo0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0015Controller(Formulaire: IFormulaireClass,
    so0007Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    dateFilter: IFilterDate,
    $stateParams: any) {
    const vm: IPageSo0015 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('stocleint', {
                required: true,
                default: $stateParams.stocleint,
                ignoreEtat: $stateParams.stocleint,
                readonly: $stateParams.stocleint
            }),
            new FormulaireItem('dattitvig', {
                required: true,
                ignoreEtat: true,
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            })
        ]),
        colonnesVisibles: [
            { nom: 'hierarchie', largeur: 525 },
            { nom: 'unocod', largeur: 110 },
            { nom: 'unodscabr', largeur: 263 },
            { nom: 'nirniv', largeur: 76 },
            { nom: 'nirdscabr', largeur: 263 },
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 263 },
            {
                nom: 'posnumdoc', largeur: 160,
                menu: [new MenuItemForage(
                    'LBL_FORAGE_SO0007',
                    so0007Route.NAME,
                    (data: any) => {
                        return {
                            ecran: '00-2',
                            id: data.trncleint
                        };
                    }
                )]
            },
            { nom: 'postitabr', largeur: 263 },
            { nom: 'posdatdeb', largeur: 110 },
            { nom: 'posdatfin', largeur: 110 },
            { nom: 'trnflgstafin', largeur: 110 },
            { nom: 'trnflgstafer', largeur: 110 },
            { nom: 'tpocod', largeur: 110 },
            { nom: 'tpodsc', largeur: 200 },
            { nom: 'litcod', largeur: 90 },
            { nom: 'litdsc', largeur: 263 },
            { nom: 'temcod', largeur: 90 },
            { nom: 'temdscabr', largeur: 263 },
            { nom: 'urgcod', largeur: 90 },
            { nom: 'urgdscabr', largeur: 263 },
            { nom: 'strcod', largeur: 90 },
            { nom: 'strdsc', largeur: 263 },
            { nom: 'ind_level', largeur: 105 },
            { nom: 'posnumdoc_sup', largeur: 100 },
            { nom: 'postitabr_sup', largeur: 263 }
        ]
    };
}
