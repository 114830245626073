import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0176',
    ecrans: ['00-2'],
    params: [
        'ovdcleintref',
        'stamnetab',
        'id',
        '?ovncleint'
    ]
});
