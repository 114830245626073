import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageAC0076 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0076Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    ac2003Route: IRoute,
    ac2004Route: IRoute,
    ac2005Route: IRoute ) {
    
    const vm: IPageAC0076 = this;
    
    const histoDac = new MenuItemEcran(
        'LBL_BTN_HISTO_DAC',
        ac2003Route.NAME,
        (data: any) => ({ 
            dhacleint: data.dhacleint
        }), 
        {
            icon: 'launch',
            fonction: 'BOUDAC',
            hidden: (rowData: any) => !(rowData.flgdac)
        }
    );

    const histoDai = new MenuItemEcran(
        'LBL_BTN_HISTO_DAI',
        ac2004Route.NAME,
        (data: any) => ({ 
            dhacleint: data.dhacleint
        }), 
        {
            icon: 'launch',
            fonction: 'BOUDAI',
            hidden: (rowData: any) => !(rowData.flgdai)
        }
    );

    const histoDhv = new MenuItemEcran(
        'LBL_BTN_HISTO_DHV',
        ac2005Route.NAME,
        (data: any) => ({ 
            dhacleint: data.dhacleint
        }), 
        {
            icon: 'launch',
            fonction: 'BOUDHV',
            hidden: (rowData: any) => !(rowData.flgdcv)
        }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresRechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('daccleintcri', { default: $stateParams.daccleint, readonly: true }),
        ], {largeurDefaut: 100}),
        colonnesVisibles: [
            {nom: 'dhaseqave', largeur: 100},
            {nom: 'usrcod', largeur: 120},
            {nom: 'usrnom', largeur: 200},
            {nom: 'datcre', largeur: 126},
            {nom: 'flgdac', largeur: 120, menu: [histoDac] },
            {nom: 'flgdai', largeur: 120, menu: [histoDai] },
            {nom: 'flgdcv', largeur: 120, menu: [histoDhv] }
        ],
        colonnesCachees: ['dhacleint']
    };
}
