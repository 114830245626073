import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPagePR0029 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function PR0029Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePR0029 = this;
    
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleint', { largeur: 30}),
            new FormulaireItem('datdeb', {largeur: 15}),
            new FormulaireItem('nivdet', {default: 'ann', largeur: 15}),
            new FormulaireItem('unacleint', {largeur: 25}),            
            new FormulaireItem('datfin', {largeur: 15})
        ]),        
        colonnesVisibles: [
            { nom: 'prjcod', largeur: 110 },
            { nom: 'prjdsc', largeur: 190 },
            { nom: 'pdfcod', largeur: 140},
            { nom: 'intcod', largeur: 160},
            { nom: 'intnomrsp', largeur: 180},
            { nom: 'prucod', largeur: 120 },
            { nom: 'prudscl01', largeur: 220 },
            { nom: 'liecod', largeur: 166 },
            { nom: 'liedsc', largeur: 236 },
            { nom: 'orgnom', largeur: 186 },
            { nom: 'typcod', largeur: 126 },
            { nom: 'typdsc', largeur: 216 },
            { nom: 'tyfcod', largeur: 166 },
            { nom: 'tyfdsc', largeur: 236 },
            { nom: 'toccod', largeur: 116 },
            { nom: 'tocdsc', largeur: 186 },
            { nom: 'ficmntoct', largeur: 136 },
            { nom: 'ficdurpro', largeur: 116 },
            { nom: 'ficmntocttot', largeur: 156 },
            { nom: 'ficfinann', largeur: 116 },
            { nom: 'sondsc', largeur: 186 },
            { nom: 'cptcod', largeur: 116 },
            { nom: 'mntdep_rel', largeur: 136 },
            { nom: 'mntdep_eng', largeur: 136 },
            { nom: 'mntrev_rel', largeur: 136 },
            { nom: 'mntrev_eng', largeur: 136 }
        ]
        };
}
