import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMr0310{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0310Controller(mr0311Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0310 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0311Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0311Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'pgpcod', largeur: 100},
            {nom: 'pgpdsc', largeur: 600},
            {nom: 'vaedsc', largeur: 160}
        ]
    };
}
