import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageGL0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0021Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageGL0021 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_ANNEE_FIN'),
            new FormulaireItem('annfin', {
                required: true
            }),
            new FormulaireItem('perdeb', {
                required: true,
                default: 1
            }),
            new FormulaireItem('perfin', {
                required: true
            }),
            new FormulaireItem('tymcleint', {
                required: true
            }),
            new FormulaireItemDivider('LBL_COMPTE'),
            new FormulaireItem('imput', {default: true}),
            new FormulaireItem('vaesysnatctb', {
                required: true
            }),
            new FormulaireItem('soncleint', {
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('grpcleint', {
            }),
            new FormulaireItemDivider('LBL_REF_EXTERNE'),
            new FormulaireItem('typref', {
            }),
            new FormulaireItem('ref', {
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vectypapp', {
            }),
            new FormulaireItemDivider('LBL_MONTANT'),
            new FormulaireItem('mntmin', {
                required: true,
                default: -999999999.99
            }),
            new FormulaireItem('mntmax', {
                required: true,
                default: 999999999.99
            })
        ]),
        colonnesVisibles: [
            {nom: 'peccod'            , largeur: 80},
            {nom: 'tydcod'            , largeur: 89},
            {nom: 'docaffdat01'       , largeur: 100},
            {nom: 'docaffnumdoc01'    , largeur: 184},
            {nom: 'docaffnumdoc01ori' , largeur: 189},
            {nom: 'tydcodori'         , largeur: 89},
            {nom: 'docaffnumdoc01_dac', largeur: 150},
            {nom: 'multi'             , largeur: 65},
            {nom: 'docaffdsc01_dac'   , largeur: 262},
            {nom: 'imputation'        , largeur: 340},
            {nom: 'docrefcod02'       , largeur: 130},
            {nom: 'docrefnom02'       , largeur: 262},
            {nom: 'mapcod'            , largeur: 110},
            {nom: 'mapnom'            , largeur: 226},
            {nom: 'docaffdsc01'       , largeur: 262},
            {nom: 'docreqcod03'       , largeur: 100},
            {nom: 'docreqnom03'       , largeur: 262},
            {nom: 'trfqte'            , largeur: 140}
        ],
        colonnesFixesDroite: [
            {nom: 'docaffmnt01'       , largeur: 126},
            {nom: 'impact'            , largeur: 126}
        ]
    };
}
