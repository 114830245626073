import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageMc0169 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireHld: IFormulaire;    
}

/* @ngInject */
export default function Mc0169Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageMc0169 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireHld = new Formulaire('LBL_BLC_HLD', [
            new FormulaireItem('hlddsc'),
            new FormulaireItem('vaehldtypimp'),
            new FormulaireItem('vaesysdevcod', {required: true, readonly: (data) => data.hldcleint}),
            new FormulaireItemDivider('LBL_DIV_GRL'),
            new FormulaireItem('hldnbrperctbgl'),
            new FormulaireItemDivider('LBL_DIV_IMP'),
            new FormulaireItem('hldentimpprt'),
            new FormulaireItem('hldentimp', {required: true}),
            new FormulaireItemDivider('LBL_DIV_AUT'),
            new FormulaireItem('hldflgeqibdgdb'),
            new FormulaireItem('hldsepimp'),
            new FormulaireItemDivider('LBL_DIV_ADM'),
            new FormulaireItem('hldflgel1admpru'),
            new FormulaireItem('hldflgel2admpru'),
            new FormulaireItem('hldflgel3admpru')
        ], {largeurDefaut: 25});
    }
}
