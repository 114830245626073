import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0001 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'pydcod', largeur: 65},
            {nom: 'pyddsc', largeur: 237},
            {nom: 'pydflgdatdeb', largeur: 118},
            {nom: 'pydflgdatfin', largeur: 100},
            {nom: 'pydflgdatrec', largeur: 133},
            {nom: 'pydflgpdi', largeur: 54},
            {nom: 'pydflgact', largeur: 62}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pydcod'),
            new FormulaireItem('pyddsc', {required: true, largeur: 66.66}),
            new FormulaireItem('pydflgdatdeb'),
            new FormulaireItem('pydflgdatfin'),
            new FormulaireItem('pydflgdatrec'),
            new FormulaireItem('pydflgpdi'),
            new FormulaireItem('pydflgact', {default: 1}),
        ], {largeurDefaut: 33.33}),
        fonctions: Object.assign({
            edition: true,
            nouveau: true

        })
    };
}
