import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0003Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0003 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'typcod', largeur: 115 },
            { nom: 'typdsc', largeur: 115 },
            { nom: 'vectypcodpro_dsc', largeur: 155 },
            { nom: 'srvcod', largeur: 125 },
            { nom: 'srvdscabr', largeur: 175 },
            { nom: 'flgint', largeur: 110 },
            { nom: 'flgfin', largeur: 110 },
            { nom: 'typflgcha', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('typcod'),
            new FormulaireItem('typdsc', { largeur: 40 }),
            new FormulaireItem('vectypcodpro'),
            new FormulaireItem('srvcleint'),
            new FormulaireItem('typflgint'),
            new FormulaireItem('typflgfin'),
            new FormulaireItem('typflgcha')
        ],
            {
                largeurDefaut: 20
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
