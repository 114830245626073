import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0061 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0061Controller(Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IStateParamsService) {
    const vm: IPageRR0061 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: false,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe }
        ],
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 100 },
            { nom: 'tagdscabr', largeur: 262 },
            { nom: 'ppacod', largeur: 80 },
            { nom: 'ppadatdeb', largeur: 100 },
            { nom: 'ppadatfin', largeur: 100 },
            { nom: 'dajmnt', largeur: 126 },
            { nom: 'flgact', largeur: 72 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', { required: true }),
            new FormulaireItem('tagcleint', { required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ppacleint', { resourceParams: (data: any) => ({ demcleint: data.demcleint }) }),
            new FormulaireItem('dajmnt')
        ], {
            largeurDefaut: 50,
            piecesJointes: true
        })
    }
}
