import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0154 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mr0154Controller(mr0052Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0154 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {

        navigatePage: mr0052Route.NAME,
        navigateParams: (data: any) => {
            return {
                id: data.mtgcleint
            };
        },

        colonnesCachees: [
            'mtgcleint',
        ],

        colonnesFixesGauche: [
            { nom: 'mtgnummth', largeur: 86 },
            { nom: 'mtgdsc', largeur: 250 }
        ],
        colonnesVisibles: [
            {nom: 'vaemtgtagavi_dsc', largeur: 175},
            {nom: 'mtgdoc', largeur: 400},
            // Paraméetre TAG
            {nom: 'mtgflgtagcol',    largeur: 84},
            {nom: 'mtgflgtagcolbas', largeur: 84},
            {nom: 'mtgflgtagsalsup', largeur: 84},
            {nom: 'mtgflgtagunc',    largeur: 90},
            {nom: 'mtgflgtagunctau', largeur: 90},
            {nom: 'mtgflgtagava',    largeur: 84},
            {nom: 'mtgflgtagrce',    largeur: 84},
            // Paramètre détail
            {nom: 'mtgflgpartem',       largeur: 84},
            {nom: 'mtgflgparfct',       largeur: 84},
            {nom: 'mtgflgparmnt',       largeur: 84},
            {nom: 'mtgflgparunc',       largeur: 84},
            {nom: 'mtgflgparprctaudem', largeur: 90},
            {nom: 'mtgflgparmax',       largeur: 98},
            {nom: 'mtgflgparbqe',       largeur: 84},
            {nom: 'mtgflgparbor',       largeur: 84},
            {nom: 'mtgflgparbqeqte',    largeur: 88},
            {nom: 'mtgflgparbqemnt',    largeur: 88},
            // Supplément salarial
            {nom: 'mtgflgtrssalsupmnt', largeur: 84},
            {nom: 'mtgflgtrssalsupprc', largeur: 84},
            // Prime affectation
            {nom: 'mtgflgtrspriaftmnt', largeur: 84},
            // Calcul rémunération
            {nom: 'mtgflgcalsal', largeur: 120},
            // Info saisie sur transaction
            {nom: 'mtgflgtrsqte',    largeur: 88},
            {nom: 'mtgflgtrsmnt',    largeur: 84},
            {nom: 'mtgflgtrstau',    largeur: 84},
            {nom: 'mtgflgtrstemaft', largeur: 110},
            // Saisie sur transaction
            {nom: 'mtgflgtrsfepava', largeur: 84},
            {nom: 'mtgflgtrsfep',    largeur: 84},
            {nom: 'mtgflgtrsder',    largeur: 116},
            // Demande de rémunération
            {nom: 'mtgflgtdrcalmnt', largeur: 120}
        ]
    };
}
