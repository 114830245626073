import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMr0184 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mr0184Controller(
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemSelection: IMenuItemSelectionClass,
    FormulaireItem: IFormulaireItemClass,
    rr0502Route: IRoute,
    mr0185Route: IRoute,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageMr0184 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true

        },
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_SELECTION', 'BOUSIMULER', {
                selection: { nom: 'Simuler' },
                successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('unpcleint_cri', { required: true }),
            new FormulaireItem('perclecod_cri', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ppacleinttrt_cri', { required: true }),
            new FormulaireItem('eplnummat_cri',),
            new FormulaireItem('paiemt', { default: 3, required: false, largeur: 100 })
        ]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 121 },
            { nom: 'eplnomprn', largeur: 200 },
            {
                nom: 'nbr_pam', largeur: 165,
                menu: [
                    new MenuItemForage(
                        rr0502Route.NAME,
                        (rowdata: any) => ({
                            demcleint: rowdata.demcleint,
                            ppacleint: rowdata.ppacleinttrt
                        }),
                        {
                            fonction: 'BOUNBR'
                        }
                    )
                ]
            },
            {
                nom: 'flg_compt', largeur: 200,
                menu: [
                    new MenuItemForage('LBL_FORAGE_DED',
                        mr0185Route.NAME, (rowdata: any) =>
                    ({
                        id: rowdata.dedcleint,
                        dedcleint: rowdata.dedcleint
                    }), {
                        fonction: 'BOUPAI'
                    })
                ]
            },
            { nom: 'flg_err', largeur: 70 },
            { nom: 'errmess', largeur: 200 }
        ],
        colonnesCachees: ['demcleint', 'ppacleinttrt']
    };
}
