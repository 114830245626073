import {IComponentController} from 'angular';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageSO0013 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    formulaireGro: IFormulaire;
    grdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function So0013Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass) {
    const vm: IPageSO0013 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireGro = new Formulaire('LBL_BLC_GRO', [
            new FormulaireItem('grocod', {
                largeur: 40
            }),
            new FormulaireItem('groflgfil', {
                largeur: 20
            }),
            new FormulaireItem('groflgsec', {
                largeur: 20
            }),        
            new FormulaireItem('groflgcc', {
                largeur: 20
            }),   
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('grodsc',{largeur: 40}),
            new FormulaireItem('vacgrotyp',{largeur: 40}),
            
            new FormulaireItemSautDeColonne({largeur: 20})
        ]);

        vm.grdMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'unocod', largeur: 110},
                {nom: 'unodscabr', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('unocleint')
            ])
        };
    }
}
