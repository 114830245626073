import IState = angular.ui.IState;
import IStateProvider = angular.ui.IStateProvider;
import { module } from 'angular';
import { IExceptions } from '../../../core/constants/exceptions.constant';
import { ILibellesClass } from '../../../core/services/libelles.service';
import { IProfil } from '../../../core/resources/profil.resource';

export default module('app.configs.state', ['core.layouts.content-only', 'core.layouts.vertical-navigation']).config(StateConfig);

/* @ngInject */
function StateConfig($stateProvider: IStateProvider, ContentOnlyLayout: IState, VerticalNavigationLayout: IState) {
    // Configuration de la vue de base
    $stateProvider.state('unsecure', {
        abstract: true,
        resolve: {
            libelleGlobauxResolver
        },
        views: {
            pages: {
                template: ContentOnlyLayout.template
            }
        }
    });

    $stateProvider.state('secure', {
        abstract: true,
        resolve: {
            libelleGlobauxResolver, profilResolver
        },
        views: {
            pages: {
                template: VerticalNavigationLayout.template
            }
        }
    });

    /* @ngInject */
    function profilResolver(profil: ng.resource.IResource<IProfil>, Exceptions: IExceptions) {
        return profil.$get().catch(() => Promise.reject(Exceptions.ERREUR_CHARGEMENT_APP));
    }

    /* @ngInject */
    function libelleGlobauxResolver(Libelles: ILibellesClass, Exceptions: IExceptions) {
        return Libelles.initLibellesGlobaux().catch(() => Promise.reject(Exceptions.ERREUR_CHARGEMENT_APP));
    }
}
