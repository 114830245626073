import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageRR0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Rr0020Controller(co0010Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0020 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: co0010Route.NAME,
        navigateParams: (data: any) => {
            return {
                id: data.idecleint,
                idecleintref: data.idecleintref,
                stamnetab: data.stamnetab,
                comcleint: data.comcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(co0010Route.NAME, () => ({
                idecleintref: vm.valeursEcran.eplcleint,
                stamnetab: 'EPL'
            }))
        ]),
        colonnesVisibles: [
            { nom: 'comcod', largeur: 115 },
            { nom: 'comdsc', largeur: 315 },
            { nom: 'ideval', largeur: 100 },
            { nom: 'idedatval', largeur: 131 },
            { nom: 'nivdsc', largeur: 185 },
            { nom: 'indrev', largeur: 90 },
            { nom: 'tycflginfexp', largeur: 90 },
            { nom: 'expflgverrh', largeur: 90 },
            { nom: 'tycflginfscl', largeur: 90 },
            { nom: 'sclflgverrh', largeur: 90 },
            { nom: 'ideannobt', largeur: 100 },
            { nom: 'idedatexp', largeur: 131 },
            { nom: 'ideannderutl', largeur: 100 },
            { nom: 'idecom', largeur: 360 }
        ],
        colonnesCachees: [
            'idecleint',
            'idecleintref',
            'comcleint',
            'stamnetab'
        ]
    };
}
