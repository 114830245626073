import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageRC0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rc0008Controller(Menu: IMenuClass) {
    const vm: IPageRC0008 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'txmnumdoc', largeur: 200},
            {nom: 'txmnumdocseq', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'docaffnumdoc01', largeur: 200},
            {nom: 'tydcodref', largeur: 200},
            {nom: 'tyddscref', largeur: 200},
            {nom: 'etddsc', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnom', largeur: 200},
            {nom: 'intcodreq', largeur: 200},
            {nom: 'intnomreq', largeur: 200},
            {nom: 'txmnumret', largeur: 200},
            {nom: 'flgadhoc', largeur: 200},
            {nom: 'intcodcib', largeur: 200},
            {nom: 'intnomcib', largeur: 200},
            {nom: 'vecdsc', largeur: 200},
            {nom: 'usrcod', largeur: 200},
            {nom: 'usrnom', largeur: 200}
        ]
    };
}
