import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'co0011',
    ecrans: ['00-2'],
    params: [
        'id',
        '?idecleint',
        '?stamnetab',
        '?idecleintref',
        '?comcleint',
        '?stamnetabcor',
        '?corcleintref'

    ]
});
