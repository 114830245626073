import { IComponentController, ITimeoutService } from "angular";
import { IFilterLibelle } from "../../../../../core/filters/ex-libelle.filter";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMenuItemMultiOccurrenceClass } from "../../../../../core/services/menu/menu-item-multi-occurrence.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageRR0511 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence
    tagMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rceMultiOccurrenceOptions: IMultiOccurrenceOptions
    aviMultiOccurrenceOptions: IMultiOccurrenceOptions
    bacMultiOccurrenceOptions: IMultiOccurrenceOptions
    tagMultiOccurrence: IMultiOccurrence
    rceMultiOccurrence: IMultiOccurrence
    aviMultiOccurrence: IMultiOccurrence
    bacMultiOccurrence: IMultiOccurrence
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Rr0511Controller(Formulaire: IFormulaireClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemMultiOccurrence: IMenuItemMultiOccurrenceClass,
    Menu: IMenuClass,
    rr0503Route: IRoute,
    $stateParams: any,
    exLibelleFilter: IFilterLibelle,
    $timeout: ITimeoutService) {
    const vm: IPageRR0511 = this;

    vm.monoOccurrenceOptions = {
        noId: true
    }

        const criteresSuggeres = new Formulaire([
            new FormulaireItem('demcleint', {
                default: $stateParams.employe,
                readonly: $stateParams.employe,   
                 onValueChange: data => vm.monoOccurrence.data.demcleint = data.demcleint
            }),
            new FormulaireItem('ppacleintdeb'), //
            new FormulaireItem('ppacleintfin'), //
            new FormulaireItem('annann'),
            new FormulaireItem('flgpercal'),
            new FormulaireItemDivider('LBL_BANQUE'),
            new FormulaireItem('datsldbqcdeb', { largeur: 10 }),
            new FormulaireItem('datsldbqcfin', { largeur: 10 }), //
            new FormulaireItem('flgfer', { largeur: 20 }),
            new FormulaireItem('flgferbqe'),
            new FormulaireItem('demcleint__eplnummat'),
            new FormulaireItem('demcleint__eplnomprn'),
            new FormulaireItem('ppacleintdeb__ppacod'),
            new FormulaireItem('ppacleintdeb__ppadatdeb'),
            new FormulaireItem('ppacleintdeb__ppadatfin'),
            new FormulaireItem('ppacleintfin__ppacod'),
            new FormulaireItem('ppacleintfin__ppadatdeb', { hidden: true }),
            new FormulaireItem('ppacleintfin__ppadatfin', { hidden: true }),
            new FormulaireItem('unpcleint', { hidden: true })
        ])

        vm.tagMultiOccurrenceOptions = {
            autoFetch: Boolean($stateParams.employe),
            fonctions: {
                customCriteresSuggeres: true,
                criteresSuggeresVisibles: !$stateParams.employe,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false, 
                effaceCriteres: true,               
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiser: false,
                reinitialiserPastillefiltre: true

            },
            criteresSuggeres,
            colonnesVisibles: [
                { nom: "tagcod", largeur: 100 },
                { nom: "tagdscabr", largeur: 100 },
                { nom: "dtcqte", largeur: 100 },
                { nom: "dtcmnt", largeur: 100, total: true }
            ],
            actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_TAG_CUM", (data, occurrence: IMultiOccurrence) => ({
                bloc: "tcum",
                multiOccurrenceParent: occurrence,
                parentId: data.tagcleint,
                fonctions: {
                    enregistrerUnEtat: false,
                    selectionnerUnEtat: false,
                    recherche: false,
                    filtrer: false,
                    selectionnerDesColonnes: false,
                    selectionnerTri: false,
                    avisEmploye: false
                },
                resourceParamsDynamique: () => ({ flgpercal: occurrence.etatSelected.criteresSuggeresData.flgpercal }),
                colonnesVisibles: [
                    { nom: "ppacod", largeur: 100 },
                    { nom: "dtcqte", largeur: 100 },
                    { nom: "dtcmnt", largeur: 100 }
                ],
                colonnesCachees: ["ppadatdeb", "ppadatfin"],
                actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_TAG_CUM_DET", (data, occurrence: IMultiOccurrence) => ({
                    bloc: "tdet",
                    cleint: "rn",
                    multiOccurrenceParent: occurrence,
                    parentId: data.ppacleint,
                    navigatePage: data => data.pamcleint ? rr0503Route.NAME : undefined,
                    navigateParams: data => ({ id: data.pamcleint }),
                    fonctions: {
                        recherche: false,
                        filtrer: false,
                        selectionnerTri: false,
                        avisEmploye: false
                    },
                    colonnesFixesGauche: [
                        { nom: "pamnumdoc", largeur: 150 }
                    ],
                    colonnesVisibles: [
                        { nom: "aftcod", largeur: 120 },
                        { nom: "poscod", largeur: 120 },
                        { nom: "lemcod", largeur: 120 },
                        { nom: "temcod", largeur: 120 },
                        { nom: "dernumdoc", largeur: 150 },
                        { nom: "drpnumdoc", largeur: 120 },
                        { nom: "patdatdeb", largeur: 120 },
                        { nom: "patdatfin", largeur: 120 },
                        { nom: "patqtecal", largeur: 120 },
                        { nom: "patmntcal", largeur: 120 },
                        { nom: "pattaucal", largeur: 120 },
                        { nom: "aftdsc", largeur: 200 },
                        { nom: "postit", largeur: 200 },
                        { nom: "lemdsc", largeur: 200 },
                        { nom: "temdsc", largeur: 200 },
                        { nom: "derdsc", largeur: 200 },
                        { nom: "drpdsc", largeur: 200 }
                    ],
                    colonnesCachees: ["pamcleint"]
                }), {
                    iconButton: true, icon: "launch", largeur: 80, titreDialog: (data, occurrence: IMultiOccurrence) => {
                        const dataParent = occurrence.multiOccurrenceParent.getDataActiveRow()
                        return `${exLibelleFilter("LBL_BLC_TAG_CUM_DET", occurrence.libelles)} - ${dataParent.tagcod} ${dataParent.tagdscabr} - ${exLibelleFilter("ppacod", occurrence.libelles)} ${data.ppacod} (de ${data.ppadatdeb} à ${data.ppadatfin})`
                    }
                })])
            }), { iconButton: true, icon: "launch", largeur: 50, titreDialog: (data, occurrence) => `${exLibelleFilter("LBL_BLC_TAG_CUM", occurrence.libelles)} - ${data.tagcod} ${data.tagdscabr}` })])
        }

        vm.rceMultiOccurrenceOptions = {
            autoFetch: Boolean($stateParams.employe),
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                criteresSuggeresVisibles: true,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesVisibles: [
                { nom: "rcecod", largeur: 100 },
                { nom: "rcedscabr", largeur: 100 },
                { nom: "druqteadm", largeur: 100 },
                { nom: "drumntadm", largeur: 100 },
                { nom: "drumntemp", largeur: 100 },
                { nom: "drumntemr", largeur: 100 }
            ],
            actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_RCE_CUM", (data, occurrence: IMultiOccurrence) => ({
                bloc: "rcum",
                multiOccurrenceParent: occurrence,
                parentId: data.rcecleint,
                fonctions: {
                    enregistrerUnEtat: false,
                    selectionnerUnEtat: false,
                    recherche: false,
                    filtrer: false,
                    selectionnerDesColonnes: false,
                    selectionnerTri: false,
                    avisEmploye: false
                },
                resourceParamsDynamique: () => ({ flgpercal: occurrence.etatSelected.criteresSuggeresData.flgpercal }),
                colonnesVisibles: [
                    { nom: "ppacod", largeur: 100 },
                    { nom: "druqteadm", largeur: 100 },
                    { nom: "drumntadm", largeur: 100 },
                    { nom: "drumntemp", largeur: 100 },
                    { nom: "drumntemr", largeur: 100 }
                ],
                colonnesCachees: ["ppadatdeb", "ppadatfin"],
                actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_RCE_CUM_DET", (data, occurrence: IMultiOccurrence) => ({
                    bloc: "rdet",
                    cleint: "rn",
                    multiOccurrenceParent: occurrence,
                    parentId: data.ppacleint,
                    navigatePage: data => data.pamcleint ? rr0503Route.NAME : undefined,
                    navigateParams: data => ({ id: data.pamcleint }),
                    fonctions: {
                        enregistrerUnEtat: false,
                        selectionnerUnEtat: false,
                        recherche: false,
                        filtrer: false,
                        selectionnerDesColonnes: false,
                        selectionnerTri: false,
                        avisEmploye: false
                    },
                    colonnesFixesGauche: [
                        { nom: "pamnumdoc", largeur: 150 }
                    ],
                    colonnesVisibles: [
                        { nom: "cotcod", largeur: 100 },
                        { nom: "parqteadm", largeur: 100 },
                        { nom: "parmntadm", largeur: 100 },
                        { nom: "parmntemp", largeur: 100 },
                        { nom: "parmntemr", largeur: 100 },
                        { nom: "cotdsc", largeur: 200 }
                    ],
                    colonnesCachees: ["pamcleint"]
                }), {
                    iconButton: true, icon: "launch", largeur: 80, titreDialog: (data, occurrence: IMultiOccurrence) => {
                        const dataParent = occurrence.multiOccurrenceParent.getDataActiveRow()
                        return `${exLibelleFilter("LBL_BLC_RCE_CUM_DET", occurrence.libelles)} - ${dataParent.rcecod} ${dataParent.rcedscabr} - ${exLibelleFilter("ppacod", occurrence.libelles)} ${data.ppacod} (de ${data.ppadatdeb} à ${data.ppadatfin})`
                    }
                })])
            }), { iconButton: true, icon: "launch", largeur: 80, titreDialog: (data, occurrence) => `${exLibelleFilter("LBL_BLC_RCE_CUM", occurrence.libelles)} - ${data.rcecod} ${data.rcedscabr}` })])
        }

        vm.aviMultiOccurrenceOptions = {
            autoFetch: Boolean($stateParams.employe),
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                effaceCriteres: true,
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesVisibles: [
                { nom: "tagcod", largeur: 100 },
                { nom: "tagdscabr", largeur: 100 },
                { nom: "daumnt", largeur: 100 }
            ],
            actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_AVI_CUM", (data, occurrence: IMultiOccurrence) => ({
                bloc: "acum",
                multiOccurrenceParent: occurrence,
                parentId: data.tagcleint,
                fonctions: {
                    enregistrerUnEtat: false,
                    selectionnerUnEtat: false,
                    recherche: false,
                    filtrer: false,
                    selectionnerDesColonnes: false,
                    selectionnerTri: false,
                    avisEmploye: false
                },
                resourceParamsDynamique: () => ({ flgpercal: occurrence.etatSelected.criteresSuggeresData.flgpercal }),
                colonnesVisibles: [
                    { nom: "ppacod", largeur: 100 },
                    { nom: "daumnt", largeur: 100 }
                ],
                colonnesCachees: ["ppadatdeb", "ppadatfin"],
                actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_AVI_CUM_DET", (data, occurrence: IMultiOccurrence) => ({
                    bloc: "adet",
                    cleint: "rn",
                    multiOccurrenceParent: occurrence,
                    parentId: data.ppacleint,
                    navigatePage: data => data.pamcleint ? rr0503Route.NAME : undefined,
                    navigateParams: data => ({ id: data.pamcleint }),
                    fonctions: {
                        enregistrerUnEtat: false,
                        selectionnerUnEtat: false,
                        recherche: false,
                        filtrer: false,
                        selectionnerDesColonnes: false,
                        selectionnerTri: false,
                        avisEmploye: false
                    },
                    colonnesVisibles: [
                        { nom: "pamnumdoc", largeur: 150 },
                        { nom: "paimnt", largeur: 100 }
                    ],
                    colonnesCachees: ["pamcleint"]
                }), {
                    iconButton: true, icon: "launch", largeur: 50, titreDialog: (data, occurrence: IMultiOccurrence) => {
                        const dataParent = occurrence.multiOccurrenceParent.getDataActiveRow()
                        return `${exLibelleFilter("LBL_BLC_AVI_CUM_DET", occurrence.libelles)} - ${dataParent.tagcod} ${dataParent.tagdscabr} - ${exLibelleFilter("ppacod", occurrence.libelles)} ${data.ppacod} (de ${data.ppadatdeb} à ${data.ppadatfin})`
                    }
                })])
            }), { iconButton: true, icon: "launch", largeur: 50, titreDialog: (data, occurrence) => `${exLibelleFilter("LBL_BLC_AVI_CUM", occurrence.libelles)} - ${data.tagcod} ${data.tagdscabr}` })])
        }

        vm.bacMultiOccurrenceOptions = {
            autoFetch: Boolean($stateParams.employe),
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                effaceCriteres: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesFixesGauche: [
                { nom: "bqecod", largeur: 100 }
            ],
            colonnesVisibles: [
                { nom: "bqedscabr", largeur: 200 },
                { nom: "dbscod1", largeur: 100 },
                { nom: "dbsdsc1", largeur: 200 },
                { nom: "dbscod2", largeur: 100 },
                { nom: "dbsdsc2", largeur: 200 },
                { nom: "urgcod", largeur: 100 },
                { nom: "dbcqte", largeur: 100 },
                { nom: "dbcqteaff", largeur: 120 },
                { nom: "bqeafflib", largeur: 100 }
            ],
            actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_BAC_CUM", (data, occurrence: IMultiOccurrence) => ({
                bloc: "bcum",
                multiOccurrenceParent: occurrence,
                parentId: data.bqecleint,
                fonctions: {
                    enregistrerUnEtat: false,
                    selectionnerUnEtat: false,
                    recherche: false,
                    filtrer: false,
                    selectionnerDesColonnes: false,
                    selectionnerTri: false,
                    avisEmploye: false
                },
                resourceParamsDynamique: () => ({ flgpercal: occurrence.etatSelected.criteresSuggeresData.flgpercal }),
                colonnesVisibles: [
                    { nom: "ppacod", largeur: 100 },
                    { nom: "dbcqte", largeur: 100 },
                    { nom: "sldprog", largeur: 100 }
                ],
                colonnesCachees: ["ppadatdeb", "ppadatfin"],
                actionsRangeeDroite: new Menu([new MenuItemMultiOccurrence("LBL_BLC_BAC_CUM_DET", (data, occurrence: IMultiOccurrence) => ({
                    bloc: "bdet",
                    cleint: "rn",
                    multiOccurrenceParent: occurrence,
                    parentId: data.ppacleint,
                    navigatePage: data => data.pamcleint ? rr0503Route.NAME : undefined,
                    navigateParams: data => ({ id: data.pamcleint }),
                    fonctions: {
                        enregistrerUnEtat: false,
                        selectionnerUnEtat: false,
                        recherche: false,
                        filtrer: false,
                        selectionnerDesColonnes: false,
                        selectionnerTri: false,
                        avisEmploye: false
                    },
                    colonnesFixesGauche: [
                        { nom: "numdoc", largeur: 150 }
                    ],
                    colonnesVisibles: [
                        { nom: "eplnummat", largeur: 100 },
                        { nom: "bqecod", largeur: 100 },
                        { nom: "dbscod1", largeur: 100 },
                        { nom: "dbscod2", largeur: 100 },
                        { nom: "ppacod", largeur: 100 },
                        { nom: "stamnetab", largeur: 100 },
                        { nom: "dattrs", largeur: 100 },
                        { nom: "vaesystrsbqedsc", largeur: 100 },
                        { nom: "qtetrs", largeur: 100 },
                        { nom: "sldcum", largeur: 100 }
                    ],
                    colonnesCachees: ["pamcleint"]
                }), {
                    iconButton: true, icon: "launch", largeur: 80, titreDialog: (data, occurrence: IMultiOccurrence) => {
                        const dataParent = occurrence.multiOccurrenceParent.getDataActiveRow()
                        return `${exLibelleFilter("LBL_BLC_BAC_CUM_DET", occurrence.libelles)} - ${dataParent.bqecod} ${dataParent.bqedscabr} - ${exLibelleFilter("ppacod", occurrence.libelles)} ${data.ppacod} (de ${data.ppadatdeb} à ${data.ppadatfin})`
                    }
                })])
            }), { iconButton: true, icon: "launch", largeur: 50, titreDialog: (data, occurrence) => `${exLibelleFilter("LBL_BLC_BAC_CUM", occurrence.libelles)} - ${data.bqecod} ${data.bqedscabr}` })])
        }
        $timeout(() => {
            if($stateParams.employe){
                vm.tagMultiOccurrence.etatSelected.criteresSuggeresData.demcleint=$stateParams.employe
                vm.monoOccurrence.data.demcleint = vm.tagMultiOccurrence.etatSelected.criteresSuggeresData.demcleint
                vm.tagMultiOccurrence.reinitialiser();
            }
            if (!vm?.monoOccurrence?.data?.demcleint) {
                vm.monoOccurrence.data.demcleint = vm.tagMultiOccurrence.etatSelected.criteresSuggeresData.demcleint
            }
        }, 900);

 }
