import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../core/services/multi-occurrence.service';
import { IDefaultsService } from '../../../../core/services/utils/defaults.service';
import { IAugmentedJQuery, IPromise, IQService, IScope, ITimeoutService } from 'angular';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { ILocalDataListFunction } from "../../../../core/services/menu/menu-item-multi-occurrence.service";
import { IComposantDialog } from '../../../../core/components/ex-dialog/ex-dialog.controller';

export interface IDialogMultiOccurrence extends IComposantDialog {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    largeur: number;
    lblTitre: string;
    icon: string;
    datalist: Array<any> | ILocalDataListFunction;
    useLocalData: boolean;
    ecranContext: IEcranContextController;
    onClose?: () => void;
    saveLocalAction(savedData: any, index?: number): IPromise<any>;
    deleteLocalAction(idLigne: string | number): IPromise<any>;
    closeRow(data: any): void;
}

export interface IDialogMultiOccurrenceOptions {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    largeur: number;
    lblTitre: string;
    lblCancel: string;
    icon: string;
    datalist: Array<any> | Function;
    useLocalData: boolean;
    ecranContext: IEcranContextController;
    onClose?: () => void;
}

/* @ngInject */
export default function DialogMultiOccurrenceController(defaults: IDefaultsService,
    $q: IQService,
    $scope: IScope,
    $element: IAugmentedJQuery,
    $timeout: ITimeoutService) {
    const vm: IDialogMultiOccurrence = this;
    vm.$onInit = function $onInit() {
        defaults(vm, {
            referenceParent: getReferenceParent(),
            datalist: [],
            lblCancel: 'G_LBL_BTN_ANNULER'
        });

        vm.saveLocalAction = saveLocalAction;
        vm.deleteLocalAction = deleteLocalAction;
        vm.closeRow = closeRow;

        setDialogWidth();

        vm.multiOccurrenceOptions.fonctions = Object.assign({
            afficherMenuSettingDansEntete: true
        }, vm.multiOccurrenceOptions.fonctions);

        if (vm.useLocalData) {
            vm.multiOccurrenceOptions.localDataList = true;
            initLocalDataList();
        }
    }

    function initLocalDataList() {

        $scope.$watch('::vm.multiOccurrence', () => {
            if (vm.multiOccurrence) {
                if (vm.multiOccurrence?.etatSelectedOriginal && vm.multiOccurrence?.resetEtatDialog) {
                    vm.multiOccurrence.etatSelected = vm.multiOccurrence.etatSelectedOriginal
                }
                vm.multiOccurrence.fetchingDataList = true;
                vm.multiOccurrence.ready.then(() => {
                    vm.multiOccurrence.fetchingDataList = true;
                    $timeout(() => {
                        vm.multiOccurrence.fetchingDataList = false
                        let datalist: any = [];

                        if (vm.datalist instanceof Function) {
                            datalist = vm.datalist();
                        } else if (vm.datalist) {
                            datalist = vm.datalist;
                        }

                        vm.datalist = datalist;
                        vm.multiOccurrence.dataList = datalist;
                        if (vm.multiOccurrence.dataList.length) {
                            // on doit laisser le temps à la grille de "render"
                            vm.multiOccurrence.emit('dataListUpdate');
                        } else {
                            const multiOccurrenceAdvancedCtrl = $element.find('ex-multi-occurrence-advanced').controller('exMultiOccurrenceAdvanced');
                            if (multiOccurrenceAdvancedCtrl.newMonoOccurrence) {
                                multiOccurrenceAdvancedCtrl.openNew();
                            } else {
                                const removeListener = $scope.$watch(
                                    () => multiOccurrenceAdvancedCtrl.newMonoOccurrence,
                                    () => {
                                        if (multiOccurrenceAdvancedCtrl.newMonoOccurrence) {
                                            multiOccurrenceAdvancedCtrl.openNew();
                                            removeListener();
                                        }
                                    }
                                )
                            }
                        }
                    }, 500)
                });
            }
        })
    }

    function saveLocalAction(savedData: any, index?: number): IPromise<any> {
        if (typeof index !== 'undefined') {
            closeRow(savedData);

            Object.assign(vm.multiOccurrence.dataList[index], savedData);
            vm.multiOccurrence.emit('exMenuItemActionDataUpdate', savedData);
        } else {
            vm.multiOccurrence.dataList.push(savedData);
        }

        callUpdateDataList()
        return $q.resolve()
    }

    function deleteLocalAction(idLigne: string | number): IPromise<any> {
        //quand il s'agit d'un valeur dynamique localDataList (parametre_valeur gs0090) on elimine dans un object
        if (vm?.multiOccurrence?.dataList != null && typeof vm.multiOccurrence.dataList === 'object') {
            Object.values(vm.multiOccurrence.dataList).forEach((el, id) => {
                //on compare le parametre_valeur ou l'intervalle
                if (typeof idLigne === "number") {
                    if (el.trlcleint === idLigne) {
                        vm.multiOccurrence.dataList.splice(id, 1)
                    }
                } else {
                    //s'il s'agit d'un object on compare par hashkey
                    if (el.$$hashKey === idLigne) {
                        vm.multiOccurrence.dataList.splice(id, 1)
                    }
                }
            })
        }
        else {
            const index = vm.multiOccurrence.dataList.indexOf((row: any) => row[vm.multiOccurrence.cleint] == idLigne);
            vm.multiOccurrence.dataList.splice(index, 1);
        }
        vm.multiOccurrence.emit('dataListUpdate');

        callUpdateDataList()
        return $q.resolve();

    }

    function updateLocalDataList() {
        vm.datalist = vm.multiOccurrence.dataList;
    }
    function callUpdateDataList() {
        updateLocalDataList();
        vm.multiOccurrence.emit('dataListUpdate');
    }
    function setDialogWidth(): void {
        const dialogElement = $element.find('.dialog-multi-occurrence');
        let cssText = 'width: auto;';
        // Si c'est un nombre sans unité, on ajoute "%"
        if (vm.largeur) {
            cssText = `width: calc(${vm.largeur}vw - 32px) !important;`;
        }

        dialogElement.css('cssText', cssText);
    }

    function getReferenceParent() {
        let cleint;
        let parentId;

        if (vm.multiOccurrenceOptions.multiOccurrenceParent) {
            cleint = vm.multiOccurrenceOptions.multiOccurrenceParent.cleint;
            parentId = vm.multiOccurrenceOptions.multiOccurrenceParent.activeRowCleint;
        } else if (vm.multiOccurrenceOptions.monoOccurrenceParent) {
            cleint = vm.multiOccurrenceOptions.monoOccurrenceParent.cleint;
            parentId = vm.multiOccurrenceOptions.monoOccurrenceParent.id;
        }

        return {
            [cleint]: vm.multiOccurrenceOptions.parentId || parentId
        };
    }

    function closeRow(data: any) {
        data.$opened = false;
    }
}
