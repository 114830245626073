import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemAction} from '../../../../../core/services/menu/menu-item-action.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';

interface IPageMR0274 extends IComponentController {
    valeursEcran: any;
    menuItemCopie: IMenuItemAction;
    formulaireTag: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pmtMultiOccurrence: IMultiOccurrence;
    tgpMultiOccurrence: IMultiOccurrence;
    mtpMultiOccurrence: IMultiOccurrence;
    pmtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tgpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mtpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0274Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0274 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions= {
            fonctions: {
                suiviModification: false,
                exportation: false,
                boutonEnregistrerHaut: false,
                afficherMenusDansEntete: true
            }
        };

        // Valeur RCE
        vm.tgpMultiOccurrenceOptions = {
            bloc: 'tgp',
            reloadWithBlocs: ['PMT'],
            actionsSelection: new Menu([
                new MenuItemSelection('LBL_BTN_COPIER', 'BOUCOP', {
                    selection: {nom: 'copier'},
                    successAction: () => {
                        vm.tgpMultiOccurrence.fetchDataList();
                        vm.mtpMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            colonnesCachees: ['ciecleintref', 'pmtcleint'],
            colonnesFixesGauche: [
                {nom: 'ciecod',   largeur: 110}
            ],
            colonnesVisibles: [
                {nom: 'tagcod',   largeur: 100},
                {nom: 'tagdscabr',largeur: 242},
                {nom: 'urgcod',   largeur: 100},
                {nom: 'strcod',   largeur: 100},
                {nom: 'temcod',   largeur: 100},
                {nom: 'urgdscabr',largeur: 242},
                {nom: 'strdsc',   largeur: 242},
                {nom: 'temdscabr',largeur: 242}
            ],
            colonnesFixesDroite: [
                {nom: 'flgexi',largeur: 74}
            ],
            details: {
                lblTitre: 'LBL_BLC_CIE',
                multiOccurrenceOptions: {
                    bloc: 'cie',
                    colonnesVisibles: [
                        {nom: 'ciecod', largeur: 110},
                        {nom: 'cienom', largeur: 378}
                    ]
                }
            }
        };

        // Compagnies
        vm.pmtMultiOccurrenceOptions = {
            bloc: 'pmt',
            fonctions: {
                editionRapide: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('pmtcleintref'),
                new FormulaireItem('schcleint', { default: () => vm.valeursEcran.schcleint })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ciecod',       largeur: 100},
                {nom: 'cienom',       largeur: 200}
            ],
            colonnesEditionRapide: [
                {nom: 'pmtcleintref', largeur: 400}
            ]
        };

        // Message du traitement
        vm.mtpMultiOccurrenceOptions = {
            bloc: 'mtp',
            colonnesVisibles: [
                {nom: 'mtpmsg', largeur: 1000}
            ]
        };
    }
}
