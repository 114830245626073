import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAc0052 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    acgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    souMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0052Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    ac0053Route: IRoute,
    Menu: IMenuClass) {

    const vm: IPageAc0052 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        formulaire: new Formulaire('LBL_BLC_ACL', [
            new FormulaireItem('aclcod'),
            new FormulaireItem('acldsc', { required: true }),
            new FormulaireItem('datcre', { readonly: true }),
            new FormulaireItem('vecsyslng'),
            new FormulaireItem('vectyputl'),
            new FormulaireItem('datmod', { readonly: true }),
            new FormulaireItem('aclcom', { enableCount: true, largeur: 100 }),
            new FormulaireItem('acltxt', { enableCount: true, largeur: 100 }),
        ], {largeurDefaut: 33.33})
    }

    vm.souMultiOccurrenceOptions = {
        bloc: 'sou',
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'souseq', largeur: 100},
            {nom: 'soutxt', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('souseq', {largeur: 33}),
            new FormulaireItem('soutxt', {largeur: 66})
        ])
    }
    vm.acgMultiOccurrenceOptions = {
        bloc: 'acg',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'BTN_FORAGE_DOC',
                ac0053Route.NAME,
                (rowData: any) => ({
                    agacleint: rowData.agacleint
                })
            )
        ]),
        colonnesVisibles: [
            {nom: 'agacod', largeur: 100},
            {nom: 'agadsc', largeur: 100},
            {nom: 'acgflgstu', largeur: 100}
        ],
        colonnesCachees: ['agacleint']
    }
}

