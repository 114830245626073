import {IComponentController} from 'angular';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IStateParamsService} from "angular-ui-router";

interface IPageLs0004 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireLs: IFormulaire;
    lscMultiOccurrenceOptions: IMultiOccurrenceOptions;
    atfMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ls0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageLs0004 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireLs = new Formulaire('LBL_BLC_LS', [
            new FormulaireItem('lsxcleint'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('dlsdatdeb',{largeur: 25,required:true}),
            new FormulaireItem('dlshredeb',{largeur: 25,required:false}),
            new FormulaireItem('datdebtex',{titre:' ',largeur:25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dlsdatfin',{largeur: 25,required:true}),
            new FormulaireItem('dlshrefin',{largeur: 25,required:false}),
            new FormulaireItem('datfintex',{titre:' ',largeur:25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dlsdur'),
            new FormulaireItem('demcleint',{ default:$stateParams.employe || $stateParams.demcleint, resourceParams: (data:any) => ({
                    urgcleint: data.urgcleint,
                    dlsdatdeb: data.dlsdatdeb,
                    dlsdatfin: data.dlsdatfin
                })
            }),
            new FormulaireItem('aftcleint',{  resourceParams:(data: any) => ({
                    urgcleint: data.urgcleint,
                    dlsdatdeb: data.dlsdatdeb,
                    dlsdatfin: data.dlsdatfin,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('intcleintdem'),
            new FormulaireItem('dlscom',{enableCount: true,largeur:100}),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS',
                new Formulaire([
                    new FormulaireItem('dlsnumdoc'),
                    new FormulaireItem('dlsdat'),
                    new FormulaireItem('ttccleint')
                ])
            )
        ]);
        vm.lscMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'lscqte1', largeur: 100},
                {nom: 'lscqte2', largeur: 80},
                {nom: 'lscqte3', largeur: 80},
                {nom: 'lscqte4', largeur: 90},
                {nom: 'lscqte5', largeur: 80},
                {nom: 'lscqte6', largeur: 90},
                {nom: 'lscqte7', largeur: 90},
                {nom: 'lsccom', largeur: 262},
                {nom: 'actdsc', largeur: 262}
            ],
            colonnesFixesGauche:[
                {nom: 'actcod', largeur: 100},
                {nom: 'lscdatdeb', largeur: 100},
                {nom: 'lscdatfin', largeur: 100}
            ],
            colonnesFixesDroite:[{nom: 'total', largeur: 100,total:true}],
            formulaire: new Formulaire([
                new FormulaireItem('actcleint',{largeur: 50,
                    resourceParams: () => ({
                        aftcleint:vm.monoOccurrence.data.aftcleint,
                        dlsdat:vm.monoOccurrence.data.dlsdat
                    }) }),
                new FormulaireItem('lscdatdeb',{largeur: 10, default: () => vm.monoOccurrence.data.dlsdatdeb}),
                new FormulaireItem('lscdatfin',{largeur: 10}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('lscqte1',{largeur: 10}),
                new FormulaireItem('lscqte2',{largeur: 10}),
                new FormulaireItem('lscqte3',{largeur: 10}),
                new FormulaireItem('lscqte4',{largeur: 10}),
                new FormulaireItem('lscqte5',{largeur: 10}),
                new FormulaireItem('lscqte6',{largeur: 10}),
                new FormulaireItem('lscqte7',{largeur: 10}),
                new FormulaireItem('lsccom',{enableCount: true})
            ])
        };
        vm.atfMultiOccurrenceOptions = {
            /*  navigatePage: ...Route.NAME, */
            colonnesVisibles: [
                {nom: 'atfnumdoc',largeur:100},
                {nom: 'trndat',largeur:100},
                {nom: 'ecldsceta',largeur:100}
            ]
        }
    }
}
