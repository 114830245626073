import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IInfosMonetaires } from "../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller";
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";

interface IPageAs0048 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    fdaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    geeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0048Controller(as0049Route: IRoute,
    $state: IStateService,
    $stateParams: any,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    Operateur: IOperateurService,
    exLibelleFilter: IFilterLibelle,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass) {
    const vm: IPageAs0048 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemActionImportation(
                'LBL_BTN_CHARGER',
                'BOUCHAR',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_CSV',
                        largeurFormulaire: 15,
                        formulaire: new Formulaire([
                            new FormulaireItem('ftinumcha', {
                                largeur: 100,
                                default: (data: any) => data.monoOccurrence.ftinumcha,
                                readonly: true
                            })
                        ], { libelleUpload: exLibelleFilter('G_LBL_BTN_UPLOAD_BOX_CSV') })
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    typesFichiers: ['.csv'],
                    iconButton: false,
                    class: 'md-raised ex-button-action'
                }
            ),
            new MenuItemAction(
                'G_LBL_BTN_VALIDER',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }
            ),
            new MenuItemAction(
                'LBL_BTN_GENER',
                'BOUGEN',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }
            ),
            new MenuItemAction(
                'LBL_BTN_DET',
                'BOUDET',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    messageConfirmation: 'BOUGENFOR_MESSAGE',
                    successAction: () => {
                        $state.go(as0049Route.NAME);
                    }
                }
            )
        ],
        formulaire: new Formulaire('LBL_BLC_CHA', [
            new FormulaireItem('ftinumcha', { largeur: 20, readonly: data => data.fticleint }),
            new FormulaireItem('ftificsou', { largeur: 80, default: 'Pour sélectionner un fichier cliquer sur le bouton CHARGER après avoir enregistrer', readonly: true })
        ])
    };

    vm.fdaMultiOccurrenceOptions = {
        fonctions: {
            editionRapide: true,
            edition: true
        },
        pagination:{
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'ciecod', largeur: 100 },
            { nom: 'eplnummat', largeur: 120 }
        ],
        colonnesVisibles: [
            // Écriture
            { nom: 'temcod', largeur: 130 },
            { nom: 'aftcod', largeur: 120 },
            { nom: 'lemcod', largeur: 120 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'strcod', largeur: 120 },
            { nom: 'asgcod', largeur: 150 },
            { nom: 'fdadat', largeur: 110 },
            { nom: 'aqtcod', largeur: 120 },
            { nom: 'actcod', largeur: 120 },
            { nom: 'fdahredeb', largeur: 120 },
            { nom: 'fdahrefin', largeur: 120 },
            { nom: 'fdaqte', largeur: 120 },
            { nom: 'tagcod', largeur: 120 },
            { nom: 'fdatagqte', largeur: 120 },
            { nom: 'fdatagmnt', largeur: 120 },
            { nom: 'aeqcod', largeur: 120 },
            { nom: 'posnumdoc', largeur: 120 },
            { nom: 'unocod', largeur: 120 },
            { nom: 'dernumdoc', largeur: 120 },
            { nom: 'drpnumdoc', largeur: 120 },
            { nom: 'fdacom', largeur: 180 },
            { nom: 'fdaimp', largeur: 180 }
        ],
        colonnesFixesDroite: [
            { nom: 'fdanbrerr', largeur: 100 },
            { nom: 'fdaflgerr', largeur: 100 }
        ],
        colonnesCachees: [
            'fdaerr',
            'prucleint',
            'prscleint',
            'cifcleint',
            'unacleint',
            'cptcleint',
            'el1cleint',
            'el2cleint',
            'el3cleint'
        ],
        colonnesEditionRapide: [
            { nom: 'ciecleintref', largeur: 100 },
            { nom: 'demcleint', largeur: 130 },
            { nom: 'temcleint', largeur: 120 },
            { nom: 'aftcleint', largeur: 120 },
            { nom: 'lemcleint', largeur: 120 },
            { nom: 'urgcleint', largeur: 120 },
            { nom: 'strcleint', largeur: 120 },
            { nom: 'asgcleint', largeur: 100 },
            { nom: 'fdadat', largeur: 110 },
            { nom: 'aqtcleint', largeur: 120 },
            { nom: 'actcleint', largeur: 120 },
            { nom: 'fdahredeb', largeur: 120 },
            { nom: 'fdahredatfin', largeur: 120 },
            { nom: 'fdaqte', largeur: 110 },
            { nom: 'tagcleint', largeur: 120 },
            { nom: 'fdatagqte', largeur: 120 },
            { nom: 'fdatagmnt', largeur: 120 },
            { nom: 'aeqcleint', largeur: 120 },
            { nom: 'poscleint', largeur: 120 },
            { nom: 'unocleintrh', largeur: 120 },
            { nom: 'dercleint', largeur: 120 },
            { nom: 'drpcleint', largeur: 120 },
            { nom: 'fdacom', largeur: 180 },
            { nom: 'fdaimp', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ciecleintref', { default: null }),
            new FormulaireItem('ciecod', { hidden: true }),
            new FormulaireItem('fticleint', { hidden: true, default: $stateParams.id }),
            new FormulaireItem('demcleint'),
            new FormulaireItem('eplnummat', { hidden: true }),
            new FormulaireItem('temcleint'),
            new FormulaireItem('temcod', { hidden: true }),
            new FormulaireItem('aftcleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('aftcod', { hidden: true }),
            new FormulaireItem('lemcleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('lemcod', { hidden: true }),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('urgcod', { hidden: true }),
            new FormulaireItem('strcleint'),
            new FormulaireItem('strcod', { hidden: true }),
            new FormulaireItem('asgcleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('asgcod', { hidden: true }),
            new FormulaireItem('fdadat'),
            new FormulaireItem('aqtcleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('aqtcod', { hidden: true }),
            new FormulaireItem('actcleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('actcod', { hidden: true }),
            new FormulaireItem('fdahredeb'),
            new FormulaireItem('fdahrefin'),
            new FormulaireItem('fdaqte'),
            new FormulaireItem('tagcleint'),
            new FormulaireItem('tagcod', { hidden: true }),
            new FormulaireItem('fdatagqte'),
            new FormulaireItem('fdatagmnt'),
            new FormulaireItem('aeqcleint'),
            new FormulaireItem('aeqcod', { hidden: true }),
            new FormulaireItem('poscleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('posnumdoc', { hidden: true }),
            new FormulaireItem('unocleintrh', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('unocod', { hidden: true }),
            new FormulaireItem('dercleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('dernumdoc', { hidden: true }),
            new FormulaireItem('drpcleint', {
                resourceParams: (data: any) => ({
                    fdadat: data.fdadat,
                    demcleint: data.demcleint
                })
            }),
            new FormulaireItem('drpnumdoc', { hidden: true }),
            new FormulaireItem('fdacom'),
            new FormulaireItem('fdaimp')
        ], { largeurDefaut: 33 })
    };

    vm.geeMultiOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            filtrer: false
        },
        colonnesVisibles: [
            { nom: 'geemsg', largeur: 500 }
        ]
    };
}
