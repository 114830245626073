import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageDb0009 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    bppMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bppMultiOccurrence: IMultiOccurrence;
    bpvMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Db0009Controller(
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    mc1012Route: IRoute,
    db0005Route: IRoute,
    $stateParams: any
) {
    const vm: IPageDb0009 = this;

   const menuItemActionCopier = new MenuItemAction(
    'LBL_BTN_COPIER',
    'BOUCOP',
    () => ({}),
    {
        formulaireConfirmationAction: {
            lblTitre: 'LBL_BTN_COPIER',
            largeurFormulaire: 25,
            formulaire: new Formulaire([
                new FormulaireItem('peccodsou'),
                new FormulaireItem('peccleintcib'),
                new FormulaireItem('flgmnt', {default: 1, largeur: 100})
            ], {largeurDefaut: 100})
        },
        icon: 'content_copy',
        saveFirst: true,
        successAction: () => { vm.bppMultiOccurrence.fetchDataList() },
        hidden: (rowData: any) => (rowData.pbpflgann)
    }
)

    const actions = {
        COPIER:  new Menu([menuItemActionCopier])
    };

    vm.menuBandeau = new Menu([]);

    const actionAnnulerBudgProg = new MenuItemAction(
        'LBL_BTN_ANN_BUD_PRO',
        'BOUCHGFLGANN',
        () => ({
        }),
        {
            icon: 'edit',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_CONFIRMATION_ACTION',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('t_message'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('t_reponse', {default: 1})
                ], {
                    largeurDefaut: 50
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer:false
        },
        formulaire: new Formulaire('LBL_BLC_PBP', [
            new FormulaireItem( 'prucleint', { default: $stateParams.prucleint } ),
            new FormulaireItem( 'intcleint'),
            new FormulaireItem( 'ecbnumdoc'),
            new FormulaireItem( 'pbpmntoct'),
            new FormulaireItem( 'pbpref'),
            new FormulaireItem( 'pbpdsc')
        ], { largeurDefaut: 50 })
    };

    vm.formulaireInd = new Formulaire('LBL_INDICATEUR', [
        new FormulaireItem('pbpflgret'),
        new FormulaireItem('pbpflgann'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('t_annuler', {largeur: 60, menu: [actionAnnulerBudgProg]})
    ], { largeurDefaut: 50 });


    vm.formulaireRes = new Formulaire('LBL_RESOLUTION', [
        new FormulaireItem('pbpnumrescd'),
        new FormulaireItem('pbpnumresca', {
            menu: [
                new MenuItemEcran('LBL_HISTO_RESOL',
                    mc1012Route.NAME,
                    (data: any) => ({ doccleintref: data.prucleint }), {
                    icon: 'launch',
                    iconButton: true
                })
            ]            
        })
    ], { largeurDefaut: 100 });

    vm.bppMultiOccurrenceOptions = {
        bloc: 'bpp',
        actionsMoreLigne : actions.COPIER,
        colonnesVisibles: [
            {nom: 'peccod', largeur: 100},
            {nom: 'ecbnumdoc', largeur: 140, 
                menu: [
                    new MenuItemForage('LBL_BOUTON_ECB',
                        db0005Route.NAME,
                        (rowData: any) => ({ id: rowData.doccleintref }), 
                        {
                            icon: 'exit_to_app',
                            disabled: (rowData:any) => !(rowData.doccleintref)
                        }
                    )
                ]            },
            {nom: 'bppmnttot', largeur: 126},
            {nom: 'bppdsc', largeur: 300},
            {nom: 'bppflgret', largeur: 100},
            {nom: 'bppflgann', largeur: 100}
        ],
        colonnesCachees: ['bppnumdocseq', 'doccleintref', 'peccodsou'],
        formulaire: new Formulaire([
            new FormulaireItem('peccleint'),
            new FormulaireItem('bppmnttot'),
            new FormulaireItem('bppdsc', {largeur: 100}),
            new FormulaireItem('bppflgret'),
            new FormulaireItem('bppflgann')
        ])
    };

    vm.bpvMultiOccurrenceOptions = {
        bloc: 'bpv',
        fonctions: {
            nouveau: (rowData:any) => !(rowData.bppnumdocseq  || rowData.bppflgann),
            edition: (rowData:any) => !(rowData.bppnumdocseq  || rowData.bppflgann),
            supprime: (rowData:any) => !(rowData.bppnumdocseq || rowData.bppflgann)
        },
        colonnesVisibles: [
            {nom: 'bpvimp', largeur: 400},
            {nom: 'bpvmntdbt', largeur: 120},
            {nom: 'bpvmntcrt', largeur: 120},
            {nom: 'bpvqte', largeur: 120},
            {nom: 'bpvdsc', largeur: 200}
        ],
        colonnesCachees: ['bppnumdocseq'],
        formulaire: new Formulaire([
            new FormulaireItem('bpvimp'),
            new FormulaireItem('bpvmntdbt', {largeur: 25}),
            new FormulaireItem('bpvmntcrt', {largeur: 25}),
            new FormulaireItem('bpvqte', {largeur: 25}),
            new FormulaireItem('bpvdsc', {largeur: 75})
        ])
    };
}
