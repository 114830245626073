import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0007 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTav: IFormulaire;
    desMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0007Controller(Formulaire: IFormulaireClass, FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass, FormulaireItem: IFormulaireItemClass, FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass) {
    const vm: IPageMr0007 = this;

    vm.formulaireTav = new Formulaire('LBL_BLC_TAV', [
        new FormulaireItem('vaesystypavi', { largeur: 50, nombreLibellesDetailsCacher: 1 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('tavsujavi', { largeur: 50, enableCount: true }),
        new FormulaireItem('tavdeldef', { largeur: 25 }),
        new FormulaireItem('tavvaldef', { largeur: 25 }),
        new FormulaireItem('tavtexavi', { enableCount: true }),
        new FormulaireItem('tavcom', { enableCount: true })
    ]);
    vm.desMultiOccurrenceOptions = {
        bloc: 'des',
        colonnesVisibles: [
            { nom: 'vaedestyp_dsc', largeur: 100 },
            { nom: 'descod', largeur: 100 },
            { nom: 'desdsc', largeur: 100 },
            { nom: 'desflgtab', largeur: 100 },
            { nom: 'desflgcou', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaedestyp'),
            new FormulaireItem('rolcleint', {
                hidden: (data) =>
                    !data.vaedestyp ||
                    data.vaedestyp === 'SUP' ||
                    data.vaedestyp === 'NIV' ||
                    data.vaedestyp === 'INT' ||
                    data.vaedestyp === 'EPL' ||
                    data.vaedestyp === 'DEM' ||
                    data.vaedestyp === 'REF' ||
                    data.vaedestyp === 'INI' ||
                    data.vaedestyp === 'URG',
                required: (data) => data.vaedestyp === 'ROL'
            }),
            new FormulaireItem('nircleint', {
                hidden: (data) =>
                    !data.vaedestyp ||
                    data.vaedestyp === 'ROL' ||
                    data.vaedestyp === 'SUP' ||
                    data.vaedestyp === 'INT' ||
                    data.vaedestyp === 'EPL' ||
                    data.vaedestyp === 'DEM' ||
                    data.vaedestyp === 'REF' ||
                    data.vaedestyp === 'INI' ||
                    data.vaedestyp === 'URG',
                required: (data) => data.vaedestyp === 'NIV'
            }),
            new FormulaireItem('intcleint', {
                hidden: (data) =>
                    !data.vaedestyp ||
                    data.vaedestyp === 'ROL' ||
                    data.vaedestyp === 'SUP' ||
                    data.vaedestyp === 'NIV' ||
                    data.vaedestyp === 'EPL' ||
                    data.vaedestyp === 'DEM' ||
                    data.vaedestyp === 'REF' ||
                    data.vaedestyp === 'INI' ||
                    data.vaedestyp === 'URG',
                required: (data) => data.vaedestyp === 'INT'
            }),
            new FormulaireItem('desflgtab', { largeur: 25 }),
            new FormulaireItem('desflgcou', { largeur: 25 })
        ])
    };

}
