import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGA0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePRUOptions: IMultiOccurrenceOptions;    
}
/* @ngInject */
export default function Ga0001Controller(Formulaire: IFormulaireClass,
                                         cb0010Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGA0001 = this;
    
    vm.multiOccurrencePRUOptions = {
        bloc: 'pru',
        fonctions:({
            importation: true
        }),       
        colonnesVisibles: [
            {nom: 'prucod'      , largeur: 110},
            {nom: 'prudscl01'   , largeur: 225},
            {nom: 'nature'      , largeur: 150},
            {nom: 'eauflgact'   , largeur: 120},
            {nom: 'intcodrsp'   , largeur: 225},
            {nom: 'intnomrsp'   , largeur: 275},
            {nom: 'unacodmanbdg', largeur: 225},
            {nom: 'unadscmanbdg', largeur: 275},
            {nom: 'pruflgstu'   , largeur: 110},
            {nom: 'prudatdeb'   , largeur: 130},
            {nom: 'prudatfin'   , largeur: 120}
        ]
    };


    vm.multiOccurrenceOptions = {
        fonctions:({
            importation: true,
            supprime: (data:any) => (data.flgsup)
        }), 
        actionsMoreLigne: new Menu([            
            new MenuItemForage(
                'BTN_ANA',
                cb0010Route.NAME,
                (data: any) => ({
                    id: data.el2cleint,
                    niveau: 2
                }),
                {
                    icon: 'equalizer',
                    iconButton: false,
                    fonction: 'BTNANA'
                }
            )
        ]),      
        colonnesVisibles: [
            {nom: 'gaacod'   , largeur: 110},
            {nom: 'gaadsc'   , largeur: 250},
            {nom: 'gaadscabr', largeur: 250},
            {nom: 'gcacod'   , largeur: 110},
            {nom: 'gcadsc'   , largeur: 250},
            {nom: 'unacod'   , largeur: 170},
            {nom: 'unadsc'   , largeur: 250},
            {nom: 'gaadatdeb', largeur: 130},
            {nom: 'gaadatfin', largeur: 110}

        ],
        colonnesCachees: [
            'el2cleint',
            'flgsup'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gaacod', {
                largeur: 20
            }),
            new FormulaireItem('gaadsc', {
                largeur: 30
            }),
            new FormulaireItem('gaadscabr', {
                largeur: 20
            }),
            new FormulaireItem('gcacleint', {
                largeur: 20
            }),
            new FormulaireItem('unacleint', {
                largeur: 20
            }),
            new FormulaireItem('gaadatdeb', {
                largeur: 20
            }),
            new FormulaireItem('gaadatfin', {
                largeur: 20
            })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrencePRUOptions
    };
}
