import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageZz0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;    
}
/* @ngInject */
export default function Zz0003Controller() {
    const vm: IPageZz0003 = this;
    
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'mesg_err', largeur: 680}
        ]
    };    
}
