import { IFormulaireClass, IFormulaire } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemTexteClass } from "../../../../../core/services/formulaire/formulaire-item-texte.service";
import { IComponentController, IScope } from "angular";
import { IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrenceOptions, IMultiOccurrence } from "../../../../../core/services/multi-occurrence.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IInfosMonetaires } from "../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller";
import { IMenuItemClass } from "../../../../../core/services/menu/menu-item.service";

interface IPageCp2000 extends IComponentController {
    formulaire: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    rlaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    avdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rivMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRla: IMultiOccurrence;
    multiOccurrenceAvd: IMultiOccurrence;
    multiOccurrenceRiv: IMultiOccurrence;
}

/* @ngInject */
export default function Cp2000Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemTexte: IFormulaireItemTexteClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $scope: IScope, 
                                         MenuItem: IMenuItemClass) {
    const vm = this as IPageCp2000;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaire = new Formulaire([
            new FormulaireItemTexte("LBL_INFO_RLD", { customClass: 'titre-imprimable' }),
            new FormulaireItem('rldjusdep'),
            new FormulaireItem('zcrcleintdsc'),
            new FormulaireItem('rlddatdep'),
            new FormulaireItem('rlddatret')
        ], { largeurDefaut: 100 });

        vm.monoOccurrenceOptions = {
            fonctions: {
                afficherMenusDansEntete: true,
                hideActionsCycle: true
            },
            menus: [
                new MenuItem('LBL_BTN_IMPRIMER', () => { 
                    window.print();
                },
                {
                    iconButton: false,
                    icon: '',
                    class: 'md-raised ex-button-action',
                    hidden: (data: any) => !data || !data.$id
                })
            ]
        };

        vm.rlaMultiOccurrenceOptions = {
            bloc: 'rla',
            pagination: {
                nbElementsPossibles: [999999],
                nombreElementPage: 999999
            },
            colonnesVisibles: [
                { nom: 'rladat', largeur: 100 },
                { nom: 'cpadsc', largeur: 150 },
                { nom: 'rladscfou', largeur: 150 },
                { nom: 'rlamntrclcan', largeur: 150, total: true },
                { nom: 'rlacom', largeur: 300 }
            ]
        };

        vm.avdMultiOccurrenceOptions = {
            bloc: 'avd',
            pagination: {
                nbElementsPossibles: [999999],
                nombreElementPage: 999999
            },
            colonnesVisibles: [
                { nom: 'reqnumdoc', largeur: 150 },
                { nom: 'reqnumref', largeur: 150 },
                { nom: 'reqmnt', largeur: 150, total: true },
                { nom: 'reqdsc', largeur: 400 }
            ]
        };

        vm.rivMultiOccurrenceOptions = {
            bloc: 'riv',
            pagination: {
                nbElementsPossibles: [999999],
                nombreElementPage: 999999
            },
            colonnesVisibles: [
                { nom: 't_imp', largeur: 300 },
                { nom: 'rivmntbrut', largeur: 121 },
                { nom: 'rivmnttaxfed', largeur: 76 },
                { nom: 'rivmnttaxpro', largeur: 76 },
                { nom: 'rivmntremfed', largeur: 76 },
                { nom: 'rivmntrempro', largeur: 76 },
                { nom: 'rivmntnet', largeur: 75 }
            ]
        };

        vm.monoOccurrenceInfosMonetaire = {
            donnees: [
                { nom: 'avd', libelle: 'LBL_TOTAL_AVD', lastOfGroup: true },
                { nom: 'total', libelle: 'LBL_TOTAL_RLD', important: true }
            ],
            noIcons: true
        };

        // Permet de s'assurrer que les 3 multiOccurrences sont chargés correctement avant de lancer l'impression
        const multiOccurrencesCharges = [false, false, false];
        const watchmultiOccurrences = $scope.$watchGroup(['vm.multiOccurrenceAvd', 'vm.multiOccurrenceRla', 'vm.multiOccurrenceRiv'], (multiOccurrences: Array<IMultiOccurrence>) => {
            if (multiOccurrences.every(m => m !== undefined)) {
                multiOccurrences.forEach((multiOccurrence, index) => {
                    multiOccurrence.once('dataListUpdate', () => {
                        multiOccurrencesCharges[index] = true;
                        if (multiOccurrencesCharges.every(m => m)) {
                            $scope.$applyAsync(() => {
                                window.print();
                            });
                        }
                    });
                })
                
                watchmultiOccurrences();
            }
        });
    }
}
