//import { IController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageCP2011 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    duiMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface ICp2011Params {
    docaffnumdoc01: string;
    intcod: string;
    intnom: string;
}

/* @ngInject */
export default function Cp2011Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: ICp2011Params,
) {
    const vm: IPageCP2011 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_DOC', [
            new FormulaireItem('docaffnumdoc01', { default: $stateParams.docaffnumdoc01, readonly: true,  largeur: 20 }),
            new FormulaireItem('refcod', { default: $stateParams.intcod, readonly: true,  largeur: 20 }),
            new FormulaireItem('refnom', { default: $stateParams.intnom, readonly: true,  largeur: 20 }),
        ], { largeurDefaut: 25 })
    };

   vm.duiMultiOccurrenceOptions = {
        bloc: 'dui',
        colonnesVisibles: [
            {nom: 'docaffnumdoc01', largeur: 100},
            {nom: 'docaffdsc01', largeur: 200},
            {nom: 'peccod', largeur: 60},
            {nom: 'reqdatdep', largeur: 60},
            {nom: 'reqdatret', largeur: 60},
            {nom: 'refcod', largeur: 60},
            {nom: 'refnom', largeur: 100},
            {nom: 'intcod', largeur: 60},
            {nom: 'intnom', largeur: 100},
        ],
        colonnesFixesDroite: [
            {nom: 'duvmnt', largeur: 150}
        ],
    };
}
