import { IComponentController } from "angular";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireClass, IFormulaire } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass, IMenuItemForage } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMultiOccurrenceOptions, IMultiOccurrence } from "../../../../../core/services/multi-occurrence.service";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IPaginationClass } from "../../../../../core/services/pagination.service";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IFormulaireTemplateSlotClass } from "../../../../../core/services/formulaire/formulaire-template-slot.service";
import { IFilterExDate } from "../../../../../core/filters/ex-date.filter";

interface IPageRr2004 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAft: IFormulaire;
    menus: IMenuItemForage[];
    formulaireLsaOff: IFormulaire;
    formulaireLsa: IFormulaire;
    hhsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    derMultiOccurrenceOptions: IMultiOccurrenceOptions;
    afpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovdposMultiOccurrenceOptions: IMultiOccurrenceOptions;
    hhsMultiOccurrence: IMultiOccurrence;
    derMultiOccurrence: IMultiOccurrence;
    afpMultiOccurrence: IMultiOccurrence;
    dasMultiOccurrence: IMultiOccurrence;
    menusEmp: { [key: string]: IMenuItemForage[]; };
    menusLem: { [key: string]: IMenuItemForage[]; };
    menuLsadsc: IMenuItemForage[];
    menuItemClassification: IMenuItemForage;
}

/* @ngInject */
export default function Rr2004Controller(as0023Route: IRoute,
                                        mr0176Route: IRoute,
                                        mr0208Route: IRoute,
                                        mr0205Route: IRoute,
                                        rr0073Route: IRoute,
                                        rr0005Route: IRoute,
                                        rr0022Route: IRoute,
                                        rr0093Route: IRoute,
                                        rr0095Route: IRoute,
                                        rr0156Route: IRoute,
                                        exDateFilter: IFilterExDate,
                                        Menu: IMenuClass,
                                        MenuItemForage: IMenuItemForageClass,
                                        MenuItemEcran: IMenuItemEcranClass,
                                        MenuItemAction: IMenuItemActionClass,
                                        ecranDetails: IEcranDetailsResourcesEntite,
                                        Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass,
                                        FormulaireItemDivider: IFormulaireItemDividerClass,
                                        FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                        FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                        FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                        Pagination: IPaginationClass) {

    const vm: IPageRr2004 = this;
    vm.$onInit = function $onInit() {
        const [eplnummat, eplnom, eplprn] = ecranDetails.valeurs.employe.replace(',', '').replace('  ', ' ').split(' ');

        vm.valeursEcran = { ...ecranDetails.valeurs, eplnummat, eplnom, eplprn };
        vm.dataLsaoff = { ...vm.valeursEcran };

        vm.monoOccurrenceOptions = {
            fonctions: {
                afficherMenusDansEntete: true,
                boutonEnregistrerHaut: false
            },
            menus: [
                new MenuItemForage(
                    'G_LBL_IMPACT_BUD',
                    mr0208Route.NAME,
                    () => ({
                        id: vm.valeursEcran.demcleint,
                        doccleintgrh: vm.monoOccurrence.data.doccleint_aft,
                        typtri: 1
                    }),
                    {
                        fonction: 'BTN_OUVBUD',
                        icon: 'equalizer',
                        iconButton: false
                    }
                ),
                new MenuItemForage(
                    'G_LBL_VENTILATION',
                    mr0205Route.NAME,
                    () => ({
                        id: vm.monoOccurrence.data.aftcleint,
                        stamnetab: "AFT"
                    }),
                    {
                        fonction: 'BTN_OUVVENT',
                        icon: 'subtitles',
                        iconButton: false
                    }
                ),
                new MenuItemForage(
                    'G_LBL_DER',
                    rr0073Route.NAME,
                    () => ({
                        demcleint: vm.valeursEcran.demcleint,
                        aftcleint: vm.valeursEcran.aftcleint,
                        efface_criteres: true
                    }),
                    {
                        fonction: 'BTN_OUVDER',
                        icon: 'view_list',
                        iconButton: false
                    }
                )
            ]
        };

        vm.menusEmp = {
            employe: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0093Route.NAME,
                    () => ({
                        id: vm.valeursEcran.eplcleint
                    })
                )
            ]
        };
        vm.menusLem = {
            lemdscfmt: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0095Route.NAME,
                    () => ({
                        id: vm.valeursEcran.lemcleint,
                        lemcleint: vm.valeursEcran.lemcleint,
                        demcleint: vm.valeursEcran.demcleint,
                    })
                )
            ]
        };

        vm.formulaireAft = new Formulaire('LBL_AFFECTATION', [
            new FormulaireItem('aftcleint'),
            new FormulaireItem('aftflgfincon'),
            new FormulaireItem('aftdatdeb'),
            new FormulaireItem('aftdatfin'),
            new FormulaireItem('aftcom', { largeur: 100 }),
            new FormulaireItem('tafcleint'),
            new FormulaireItem('tafflgpri', { largeur: 20 }),
            new FormulaireItem('tafflgttu', { largeur: 20 }),
            new FormulaireItem('temcoddscabr'),
            new FormulaireItem('temhreheb'),
            new FormulaireItem('posnumtit'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaesyscaptem'),
            new FormulaireItem('aftflgtagaut',{largeur: 21,                
            }),
            new FormulaireItem('aftdecftp'),
            new FormulaireItem('aftdecaut'),
            
            new FormulaireGroupeAccordeon('LBL_AUTRES_INFOS', new Formulaire([
                new FormulaireItemDivider('LBL_HERITE_POSTE'),
                new FormulaireItem('unocoddscrh'),
                new FormulaireItem('unocoddscvac'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dscpos', {
                    titre: " ",
                    largeur: 100
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__superieur'),
                new FormulaireItem('litcoddsc'),
                new FormulaireItemDivider('LBL_SUITE_SUP'),
                new FormulaireItem('intcleintsup', {
                    resourceParams: (data: any) => {
                        return {
                            aftdatdeb: data.aftdatdeb,
                            aftdatfin: data.aftdatfin
                        };
                    },
                    historiqueColonne: true
                }),
                new FormulaireItemDivider('LBL_VALEURS_DIFF_AFT'),
                new FormulaireItem('unocleintrh'),
                new FormulaireItem('unocleintvac'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('poscleintsup'),
                new FormulaireItem('litcleint'),
                new FormulaireItemDivider('LBL_DATES_RENOUV'),
                new FormulaireItem('aftdatdebadm'),
                new FormulaireItem('aftdatfinadm'),
                new FormulaireItem('aftflgpro'),
                new FormulaireItem('aftdatfinpai'),
            ]))
        ]);

        vm.hhsMultiOccurrenceOptions = {
            bloc: 'hhs',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('annule', {
                    largeur: 50,
                    default: 'N',
                    direction: 'vertical'
                }),
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('BTN_OUVRIR', as0023Route.NAME, (data: any) => {
                    return {
                        id: data.trncleint,
                        hhscleintref: vm.monoOccurrence.data.aftcleint
                    };
                }, {
                        ecran: '01-2',
                        icon: 'launch',
                        disabled: (data: any) => data.indpos,
                        onClose() {
                            vm.hhsMultiOccurrence.fetchDataList();
                        }
                    }),
            ]),
            colonnesFixesGauche: [
                { nom: 'hhsdatdeb', largeur: 150 },
                { nom: 'hhsdatfin', largeur: 150 },
                { nom: 'ahtcod', largeur: 150 }
            ],
            colonnesVisibles: [
                { nom: 'ahtdsc', largeur: 200 },
                { nom: 'ecldsceta', largeur: 200 },
                { nom: 'hhsasg', largeur: 200 },
                { nom: 'ahthresem', largeur: 200 },
                { nom: 'hhsunc', largeur: 200 },
                { nom: 'hhsetc', largeur: 100 }
            ],
            colonnesFixesDroite: [
                { nom: 'indpos', largeur: 125 },
                { nom: 'hhsflgdef', largeur: 125 },
                { nom: 'indtmppar', largeur: 125 },
                { nom: 'flgahtman', largeur: 125 }
            ],
            colonnesCachees: [
                'trncleint'
            ]
        };

        // vm.derMultiOccurrenceOptions = {
        //     bloc: 'der',
        //     navigatePage: rr0074Route.NAME,
        //     colonnesFixesGauche: [
        //         { nom: 'dernumdoc', largeur: 125 }
        //     ],
        //     colonnesVisibles: [
        //         { nom: 'ecldsceta', largeur: 125 },
        //         { nom: 'derdatdeb', largeur: 125 },
        //         { nom: 'derdatfin', largeur: 125 },
        //         { nom: 'derqte', largeur: 125 },
        //         { nom: 'unccodqte', largeur: 125 },
        //         { nom: 'dertauhor', largeur: 125 },
        //         { nom: 'unccodtau', largeur: 125 },
        //         { nom: 'dermnt', largeur: 125 },
        //         { nom: 'dertmd', largeur: 300 },
        //         { nom: 'dertag', largeur: 300 },
        //         { nom: 'derdsc', largeur: 125 }
        //     ]
        // };

        // Salaire lorsque transaction officiel //
        vm.formulaireLsaOff = new Formulaire('LBL_LSA_OFF', [
            new FormulaireTemplateSlot('autresInfosLsaOff'),
            new FormulaireItemDivider(),
            new FormulaireItem('t_datref', {
                default: exDateFilter(vm.valeursEcran.t_datref),
                securityCycle: false,
                menu: [
                    new MenuItemAction(
                        'LBL_CALCUL_SALAIRE',
                        'CALCUL',
                        () => ({
                            echelon: vm.valeursEcran.echelon,
                            uncdsc: vm.valeursEcran.uncdsc,
                            salclas: vm.valeursEcran.salclas,
                            saltot: vm.valeursEcran.saltot,
                            suppsal: vm.valeursEcran.suppsal,
                            primeaft: vm.valeursEcran.primeaft,
                            primepos: vm.valeursEcran.primepos,
                            avasoc: vm.valeursEcran.avasoc,
                            totsel: vm.valeursEcran.totsel,
                            salhor: vm.valeursEcran.salhor,
                            saljrs: vm.valeursEcran.saljrs,
                            salheb: vm.valeursEcran.salheb,
                            salann: vm.valeursEcran.salann,
                            tmptra: vm.valeursEcran.tmptra,
                            ratio: vm.valeursEcran.ratio,
                            ratmar: vm.valeursEcran.ratmar,
                            ratmin: vm.valeursEcran.ratmin,
                            ratmil: vm.valeursEcran.ratmil,
                            ratmax: vm.valeursEcran.ratmax
                        }),
                        {
                            icon: 'refresh',
                            champsRechargement: [
                                'avasoc',
                                't_datref',
                                'echelon',
                                'primeaft',
                                'primepos',
                                'ratio',
                                'ratmar',
                                'ratmax',
                                'ratmil',
                                'ratmin',
                                'salclas',
                                'salann',
                                'salheb',
                                'salhor',
                                'saljrs',
                                'saltot',
                                'suppsal',
                                'tmptra',
                                'totsel',
                                'uncdsc'
                            ]
                        }
                    )
                ]
            })
        ], {
                largeurDefaut: 20,
                menu: new Menu([
                    new MenuItemEcran(
                        'LBL_DETAIL_SALAIRE',
                        rr0156Route.NAME,
                        (data: any) => ({
                            aftcleint: data.aftcleint,
                            datref: vm.valeursEcran.t_datref
                        }), {
                            icon: 'assessment'
                        }
                    )
                ])
            });

        // Salaire //
        vm.formulaireLsa = new Formulaire('LBL_LSA', [
            new FormulaireItem('lsacleint', {
                resourceParams: (data: any) => {
                    return {
                        aftcleint: data.aftcleint,
                        lemcleint: data.lemcleint,
                        temcleint: data.temcleint,
                        tafcleint: data.tafcleint,
                        urgcleint: data.urgcleint,
                        strcleint: data.strcleint
                    };
                },
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_LSA',
                        (vm.valeursEcran.nbrlsa > 1) ?
                            rr0005Route.NAME :
                            rr0022Route.NAME,
                        () => {
                            if (vm.valeursEcran.nbrlsa > 1) {
                                return {
                                    id: vm.valeursEcran.lsacleint
                                };
                            } else {
                                return {
                                    id: vm.valeursEcran.trncleinttsa
                                };
                            }
                        },
                        { hidden: () => !vm.valeursEcran.lsacleint }
                    )
                ]
            }),
            new FormulaireTemplateSlot('boutonClassification')
        ]);

        vm.menuLsadsc = [
            new MenuItemForage(
                'BTN_FORAGE_LSA',
                (vm.valeursEcran.nbrlsa > 1) ?
                    rr0005Route.NAME :
                    rr0022Route.NAME,
                () => {
                    if (vm.valeursEcran.nbrlsa > 1) {
                        return {
                            id: vm.valeursEcran.lsacleint
                        };
                    } else {
                        return {
                            id: vm.valeursEcran.trncleinttsa
                        };
                    }
                },
                {
                    icon: 'exit_to_app',
                    iconButton: true,
                    hidden: () => !vm.valeursEcran.lsacleint
                }
            )
        ];

        // Bouton forage Classification
        vm.menuItemClassification = new MenuItemForage(
            'LBL_CLASSIFICATION',
            rr0022Route.NAME,
            () => ({
                tsecleint: vm.monoOccurrence.data.tsecleint,
                flgrr0005: 0
            }),
            {
                icon: 'add_circle',
                iconButton: false,
                saveFirst: true,
                hidden: (data: any) => data.lsacleint
            }
        );

        vm.afpMultiOccurrenceOptions = {
            bloc: 'afp',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 100,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            colonnesFixesGauche: [
                { nom: 'afptag', largeur: 200 },
                { nom: 'tagpar', largeur: 200 },
                { nom: 'afpdatdeb', largeur: 150 },
                { nom: 'afpdatfin', largeur: 150 },
                { nom: 'afpmnt', largeur: 150 }
            ],
            colonnesVisibles: [
                { nom: 'unccod', largeur: 150 },
                { nom: 'afppou', largeur: 150 },
                { nom: 'afpcom', largeur: 250 },
                { nom: 'afpsui', largeur: 250 }
            ],
            colonnesFixesDroite: [
                { nom: 'afpflgact', largeur: 150 }
            ]
        };

        vm.dasMultiOccurrenceOptions = {
            bloc: 'das',
            colonnesVisibles: [
                { nom: 'avsdsc', largeur: 400 },
                { nom: 'dasdatvig', largeur: 200 },
                { nom: 'avcdsc', largeur: 300 },
                { nom: 'dasflgact', largeur: 150 }
            ],
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 100,
                    default: 'O',
                    direction: 'vertical'
                })
            ])
        };

        vm.ovdMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('BTN_MODIFIER', mr0176Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.ovdcleint,
                        ovdcleintref: rowData.aftcleint,
                        stamnetab: 'AFT'
                    };
                }, {
                    icon: 'launch',
                    fonction: 'BOUOUV'
                })
            ]),
            bloc: 'ovd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'ovnimp', largeur: 300 },
                { nom: 'rsccoddsc', largeur: 100 },
                { nom: 'ovddatvig', largeur: 100 },
                { nom: 'ovddatfin', largeur: 100 }
            ],
            colonnesCachees: [
                'ovdcleint',
                'aftcleint'
            ]
        };

        // Imputation poste
        vm.ovdposMultiOccurrenceOptions = {
            navigatePage: mr0176Route.NAME,
            navigateParams: (rowData: any) => (
                {
                    ovdcleintref: vm.monoOccurrence.data.poscleint,
                    stamnetab: 'POS',
                    id: rowData.ovdcleint,
                    ovncleint: rowData.ovncleint
                }
            ),
            resourceParams:{
                    ovdcleintref: vm.valeursEcran.poscleint,
                    stamnetab: 'POS'
            },
            bloc: 'ovdpos',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles:false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ovnimp', largeur: 300},
                {nom: 'rsccoddsc', largeur: 100},
                {nom: 'ovddatvig', largeur: 100},
                {nom: 'ovddatfin', largeur: 100}
            ],
            colonnesCachees: ['ovdcleint']
        };

    }
}
