import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageAc2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}

/* @ngInject */
export default function Ac2000Controller( $stateParams: any,
    Operateur: IOperateurService ) {
    const vm: IPageAc2000 = this;

    const filtresParametres = [];
    if ($stateParams.etrcleint) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'etrcleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.etrcleint,
                    affichage: false
                }
            ]
        );
    }

    if ($stateParams.erdcleint) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'erdcleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.erdcleint,
                    affichage: false
                }
            ]
        );
    }

    vm.multiOccurrenceOptions = {
        filtresParametres,
        colonnesVisibles: [
            {nom: 'datcreaff', largeur: 130},
            {nom: 'usrcod', largeur: 130},
            {nom: 'usrnom', largeur: 130},
            {nom: 'erhmntetc', largeur: 130, hidden: () => $stateParams.erdcleint },
            {nom: 'erhitmprxneg', largeur: 130, hidden: () => !$stateParams.erdcleint },
            {nom: 'erhitmmnt', largeur: 130, hidden: () => !$stateParams.erdcleint }
        ]
    };

}
