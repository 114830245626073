import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItem, IMenuItemClass } from "../../../../../core/services/menu/menu-item.service";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IFormulaireGroupeClass } from "../../../../../core/services/formulaire/formulaire-groupe.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';

interface IPageGs0122 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
    foragePra: IMenuItem;
    forageUsr: IMenuItem;
    peaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    psfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    assMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gupMultiOccurrenceOptions: IMultiOccurrenceOptions;
    psdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pgnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gtnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gptMultiOccurrenceOptions: IMultiOccurrenceOptions;
    peaMultiOccurrence: IMultiOccurrence;
    cssprefix: string;
}

interface IGs0122Params {
    aplcod: string;
    indfor: string;
}

/* @ngInject */
export default function Gs0122Controller(ecranDetails: IEcranDetailsResourcesEntite,
    gs0122Route: IRoute,
    gs0009Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: IGs0122Params,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemSelection: IMenuItemSelectionClass,
    MenuItem: IMenuItemClass) {
    const vm: IPageGs0122 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.cssprefix = "ex-data-text-bold-black"
    // Forage vers le profil associé
    vm.foragePra = new MenuItemForage(
        'LBL_FOR_PRA',
        gs0122Route.NAME,
        (rowData: any) => {
            return {
                id: rowData.pracleintass,
                aplcod: $stateParams.aplcod
            };
        }
    );

    // Forage vers l'usager
    vm.forageUsr = new MenuItemForage(
        'LBL_FOR_PRA',
        gs0009Route.NAME,
        (rowData: any) => {
            return {
                id: rowData.usrcle
            };
        }
    );

    vm.monoOccurrenceOptions = {
        fonctions: {
            edition: !($stateParams.indfor),
            supprime: (data: any) => !(data.praflgass) && !($stateParams.indfor),
            boutonDupliquer: false
        },
        resourceParams: {
            aplcod: $stateParams.aplcod
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_COPIE',
                'BOUCOP',
                () => ({}),
                {
                    icon: 'content_copy',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_COPIE_PROFIL_ACCESS',
                        formulaire: new Formulaire([
                            new FormulaireItem('pradsc_new', { required: true, largeur: 100 }),
                            new FormulaireItem('pradscmnu_new', { required: true, largeur: 100 })
                        ])
                    },
                    hidden: () => ($stateParams.indfor)
                }
            )
        ]),
        formulaire: new Formulaire('LBL_BLC_PRA', [
            new FormulaireItem('pradsc',{
                largeur: 33
            }),
            new FormulaireItem('pradscmnu',{
                largeur: 33
            }),
            new FormulaireItem('vaesysapp', { 
                required: true ,
                largeur: 33
            }),
                new FormulaireItem('praflgemp', {   largeur: 33, disabled: (data) => data.$params.aplcod === 'FINANCE' }),
                new FormulaireItem('pracleintemp', {   largeur: 33, disabled: (data) => data.$params.aplcod === 'FINANCE' }),
           new FormulaireItem('praindpri',{
                    largeur: 33
           }),
                new FormulaireItem('pracleintref',{
                    largeur: 33
            }),
            new FormulaireItem('pradoc', { largeur: 67, enableCount: true })
        ])
    };

    // Profils associés
    vm.assMultiOccurrenceOptions = {
        bloc: 'ass',
        fonctions: {
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor),
            pagination: true
        },
        colonnesVisibles: [
            { nom: 'pradsc', largeur: 100 }
        ],
        colonnesCachees: [
            'pracleintass'
        ]
    };

    // Usager
    vm.gupMultiOccurrenceOptions = {
        bloc: 'gup',
        fonctions: {
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            pagination: true
        },
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 100 },
            { nom: 'usrnom', largeur: 100 },
            { nom: 'gupdat', largeur: 100, hidden: true },
            { nom: 'gupdatdeb', largeur: 100 },
            { nom: 'gupdatfin', largeur: 100 }
        ],
        colonnesCachees: [
            'usrcle'
        ]
    };

    // Restriction par document
    vm.psdMultiOccurrenceOptions = {
        bloc: 'psd',
        fonctions: {
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor),
            pagination: true
        },
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tydcleint')
        ], { largeurDefaut: 100 })
    };

    // Groupe de notes
    vm.pgnMultiOccurrenceOptions = {
        bloc: 'pgn',
        fonctions: {
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor),
            pagination: true
        },
        colonnesVisibles: [
            { nom: 'gnodsc', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gnocleint')
        ], { largeurDefaut: 100 })
    };

    // Note finances
    vm.gtnMultiOccurrenceOptions = {
        bloc: 'gtn',
        fonctions: {
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor),
            pagination: true
        },
        colonnesVisibles: [
            { nom: 'vecdsc', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vecvalcle')
        ], { largeurDefaut: 100 })
    };

    // Bloc hie Affichage hiérarchique
    vm.hieMultiOccurrenceOptions = {
        brisNiveaux: 'niveau',
        bloc: 'hie',
        fonctions: {
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor),
            recherche: false
        },
        colonnesVisibles: [
            {
                nom: 'meddscmen',
                largeur: 100,
                menu: [
                    new MenuItem(() => { }, {
                        icon: 'not_interested',
                        hidden: (rowData: any) => !rowData.flgacces,
                        iconButton: true,
                        noAction: true
                    })
                ]
            }
        ],
        colonnesCachees: ['medval', 'medcleintref', 'flgacces', 'vaesysenttrt', 'newline', 'sec'],
        actionsRangeeDroite: new Menu([
            new MenuItemAction('LBL_ENLEVER_ACCES', 'BOUENL', (rowData: any) => ({ medcle: rowData.medcleintref, vaesysenttrt: rowData.vaesysenttrt }), {
                icon: '',
                iconButton: false,
                hidden: (rowData: any) => rowData.flgacces || $stateParams.indfor,
                successAction: () => {
                    vm.hieMultiOccurrence.fetchDataList();
                }
            }),
            new MenuItemAction('LBL_DONNER_ACCES', 'BOUDON', (rowData: any) => ({ medcle: rowData.medcleintref, vaesysenttrt: rowData.vaesysenttrt }), {
                icon: '',
                iconButton: false,
                hidden: (rowData: any) => !rowData.flgacces || $stateParams.indfor,
                successAction: () => {
                    vm.hieMultiOccurrence.fetchDataList();
                }
            })
        ])
    };

    // Menu
    vm.prmMultiOccurrenceOptions = {
        bloc: 'prm',
        fonctions: {
            supprime: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            edition: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            nouveau: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            boutonDupliquer: !($stateParams.indfor)
        },
        colonnesVisibles: [
            { nom: 'prmcle', largeur: 60 },
            { nom: 'vaesysenttrt_dsc', largeur: 120 },
            { nom: 'dscprg', largeur: 350 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('prmcle'),
            new FormulaireItem('vaesysenttrt'),
            new FormulaireItem('prmcleintref', {
                resourceParams: (data: any) => ({
                    vaesysapp: data.$ancetre.vaesysapp,
                    aplcod: $stateParams.aplcod
                }),
                largeur: 100
            })
        ], { largeurDefaut: 50 })
    };

    // Exclusions
    vm.peaMultiOccurrenceOptions = {
        bloc: 'pea',
        fonctions: {
            supprime: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            edition: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            nouveau: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            boutonDupliquer: !($stateParams.indfor)
        },
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_SUPPRIMER',
                'BOUSUP',
                {
                    selection: { nom: 'destruction' },
                    fonction: 'BOUSUP',
                    successAction: () => {
                        vm.peaMultiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'vaesysenttrt_dsc', largeur: 100 },
            { nom: 'dscprg', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesysenttrt'),
            new FormulaireItem('peacleintref', {
                resourceParams: (data: any) => ({
                    vaesysapp: data.$ancetre.vaesysapp,
                    aplcod: $stateParams.aplcod
                })
            })
        ], { largeurDefaut: 50 })
    };

    // Sécurité fonction
    vm.psfMultiOccurrenceOptions = {
        bloc: 'psf',
        fonctions: {
            supprime: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            edition: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            nouveau: (data: any) => !(data.$ancetre.pracleintref) && !($stateParams.indfor),
            importation: true,
            boutonDupliquer: !($stateParams.indfor)
        },
        colonnesVisibles: [
            { nom: 'ecrcod', largeur: 150 },
            { nom: 'ecrdscmen', largeur: 250 },
            { nom: 'srfdsc', largeur: 250 },
            { nom: 'trldsc', largeur: 200 },
            { nom: 'psfflgacc', largeur: 120 },
            { nom: 'psfflgins', largeur: 120 },
            { nom: 'psfflgmod', largeur: 120 },
            { nom: 'psfflgsup', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ecrcleintref', {
                largeur: 33,
                resourceParams: (data: any) => ({
                    vaesysapp: data.$ancetre.vaesysapp,
                    aplcod: $stateParams.aplcod
                })
            }),
            new FormulaireItem('srfcle', { largeur: 33 }),
            new FormulaireItem('trlcleint', { largeur: 33 }),
            new FormulaireItem('psfflgacc'),
            new FormulaireItem('psfflgins'),
            new FormulaireItem('psfflgmod'),
            new FormulaireItem('psfflgsup')
        ], { largeurDefaut: 25 })
    };

    vm.gptMultiOccurrenceOptions = {
        bloc: 'gpt',
        fonctions: {
            supprime: () => !($stateParams.indfor),
            edition: () => !($stateParams.indfor),
            nouveau: () => !($stateParams.indfor),
            boutonDupliquer: !($stateParams.indfor)
        },
        colonnesVisibles: [
            { nom: 'tpicod', largeur: 150 },
            { nom: 'tpidsc', largeur: 250 },
            { nom: 'gptflgajt', largeur: 120 },
            { nom: 'gptflgmod', largeur: 120 },
            { nom: 'gptflgsup', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tpicleint'),
            new FormulaireItem('gptflgajt'),
            new FormulaireItem('gptflgmod'),
            new FormulaireItem('gptflgsup')
        ], { largeurDefaut: 25 })
    };
}
