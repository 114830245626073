import {module} from 'angular';
import {IDialogClass} from '../../../../core/services/dialog.service';
import DialogRecuperationController from '../../dialogs/dialog-recuperation/dialog-recuperation.controller';

export default module('app.dialogs.dialog-recuperation', [
    'core.services.dialog'
]).factory('DialogRecuperation', DialogRecuperationFactory);

/* @ngInject */
function DialogRecuperationFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogRecuperationController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-recuperation.html'),
        focusOnOpen: false,
        autoWrap: true,
        multiple: true,
        fullscreen: true
    });
}
