import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAc0007 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    erdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    conMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mntFormulaire: IFormulaire;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0007Controller(
    $stateParams: any,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    mc0046Route: IRoute,
    mc1014Route: IRoute,
    ac2000Route: IRoute,
    ac0022Route: IRoute ) {

    const vm: IPageAc0007 = this;

        vm.valeursEcran = ecranDetails;
    
        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonDupliquer: false
            },
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_IMPUTATION',
                    mc0046Route.NAME,
                    (data: any) => ({
                        vaeusient: 'ETR',
                        uvicleintref: vm.monoOccurrence.data.etrcleint
                    }),
                    {
                        fonction: 'BOUIMP',
                        icon: 'view_list',
                        iconButton: false
                    }
                ), new MenuItemEcran(
                    'LBL_TOLERANCE',
                    mc1014Route.NAME,
                    () => ({
                        tolcleintref: vm.monoOccurrence.data.etrcleint,
                        vaeustent: 'ETR'
                    }),
                    {
                        fonction: 'BOUTOL',
                        icon: 'data_usage',
                        iconButton: false,
                        secondaire: true
                    }
                ), new MenuItemEcran(
                    'LBL_HISTO',
                    ac2000Route.NAME,
                    () => ({
                        etrcleint: vm.monoOccurrence.data.etrcleint
                    }),
                    {
                        fonction: 'BOUHIS',
                        icon: 'open_in_new'
                    }
                ),
                new MenuItemEcran(
                    'LBL_BTN_HISTO_IMP',
                    ac0022Route.NAME,
                    (data: any) => ({ 
                        dhicleintref: data.doccleint,
                        dhityp: 'DOC'
                    }), 
                    {
                        icon: 'launch',
                        iconButton: false,
                        fonction: 'BOUHISIMP'
                    }
                )
            ]),
            formulaire: new Formulaire('LBL_BLC_ETR', [
                new FormulaireItem('etccleint', { default: $stateParams.etccleint }),
                new FormulaireItem('tydcleint'),
                new FormulaireItem('aut__etcnumdoc'),
                new FormulaireItem('aut__etcdsc', { largeur: 66.66 }),
                new FormulaireItem('etcnumdocseq'),
                new FormulaireItem('aut__refcodnom'),
                new FormulaireItem('etrflgact', { default: 1 }),
                new FormulaireItem('etrdatdeb'),
                new FormulaireItem('etrdatfin'),
                new FormulaireItem('intcleintreq'),
                new FormulaireItem('etrmntetc'),
                new FormulaireItem('taxcleint'),
                new FormulaireItem('etrnumref'),
                new FormulaireItem('etrflgaut')
            ], {largeurDefaut: 33.33})
        };

        vm.mntFormulaire = new Formulaire('',[
            new FormulaireItem('etrmntcon'),
            new FormulaireItem('etrmntcondoc'),
            new FormulaireItem('etrmntpay')
        ]);

        vm.erdMultiOccurrenceOptions = {
            bloc: 'erd',
            actionsMoreLigne: new Menu([
                new MenuItemEcran(
                    'LBL_TOLERANCE',
                    mc1014Route.NAME,
                    (rowData:any) => ({
                        tolcleintref: rowData.erdcleint,
                        vaeustent: 'ERD'
                    }),
                    {
                        fonction: 'BOUTOL',
                        icon: 'data_usage',
                        iconButton: false,
                        secondaire: true
                    }
                ), new MenuItemEcran(
                    'LBL_HISTO',
                    ac2000Route.NAME,
                    (rowData:any) => ({
                        etrcleint: vm.monoOccurrence.data.etrcleint,
                        erdcleint: rowData.erdcleint
                    }),
                    {
                        fonction: 'BOUHIS',
                        icon: 'open_in_new'
                    }
                )
            ]),
                colonnesFixesGauche: [
                {nom: 'edeitmdsc', largeur: 350},
            ],
            colonnesVisibles: [
                {nom: 'edeitmnumcat', largeur: 140},
                {nom: 'erditmprxneg', largeur: 126},
                {nom: 'erditmmnt', largeur: 126},
                {nom: 'erditmmntcon', largeur: 126},
                {nom: 'erditmqte', largeur: 126},
                {nom: 'erditmqtecon', largeur: 126}
            ],
            colonnesCachees: ['erdcleint'],
            formulaire: new Formulaire([
                new FormulaireItem('edecleint', { 
                    resourceParams: () => ({ etccleint: $stateParams.etccleint })
                }),
                new FormulaireItemSautDeLigne,
                new FormulaireItem('erditmprxneg'),
                new FormulaireItem('erditmmnt'),
                new FormulaireItem('erditmqte')
            ], {largeurDefaut: 33.33})
        };

        vm.conMultiOccurrenceOptions = {
            bloc: 'con',
            colonnesFixesGauche: [
                {nom: 'dacnumdoc', largeur: 160}
            ],
            colonnesVisibles: [
                {nom: 'tydcod', largeur: 140},
                {nom: 'tyddsc', largeur: 220},
                {nom: 'conmnt', largeur: 126},
                {nom: 'docaffmnt01', largeur: 126},
                {nom: 'etddsc', largeur: 180},
                {nom: 'peccod', largeur: 100},
                {nom: 'docaffdat01', largeur: 120},
                {nom: 'docreqnom03', largeur: 180},
                {nom: 'intnom', largeur: 180}
            ]
        };
}

