import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2005',
    ecrans: ['00-2'],
    params: [
        '?prucleint', 
        '?vaesysnatctb', 
        '?indcleint',
        '?pecann',
        '?peccleint']
});
