import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageAc0068 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0068Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass
) {
    const vm: IPageAc0068 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true,
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleintcri', { required: true }),
            new FormulaireItem('etccleintcri'),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe(
                'CRIDATENT',
                new Formulaire(
                    [
                        new FormulaireItem('etcdatdebcri', { largeur: 30 }),
                        new FormulaireItem('etcdatfincri', { largeur: 30 }),
                    ],
                    { largeurDefaut: 50 }
                ),
                { largeur: 50 }
            ),
        ]),

        colonnesFixesGauche: [
            { nom: 'etcnumdoc', largeur: 130 },
            { nom: 'refcod', largeur: 130 },
            { nom: 'refdsc', largeur: 200 },
        ],

        colonnesVisibles: [
            { nom: 'etcdatdeb', largeur: 126 },
            { nom: 'etcdatfin', largeur: 126 },
            { nom: 'etcdsc', largeur: 300 },
            { nom: 'etcrefint', largeur: 160 },
            { nom: 'mnttot', largeur: 140 },

            { nom: 'etcnumdocseq', largeur: 60 },
            { nom: 'etrmntetc', largeur: 130 },
            { nom: 'etrmntcon', largeur: 130 },
            { nom: 'etrimp', largeur: 250 },
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'etrdatdeb', largeur: 130 },
            { nom: 'etrdatfin', largeur: 130 },
        ],
    };
}
