import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
interface IPageMc2100 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function Mc2100Controller(
    Menu: IMenuClass
) {
    const vm: IPageMc2100 = this;

        vm.menuBandeau = new Menu([]);
        vm.multiOccurrenceOptions = {
            fonctions: {
                suiviModification: true,
                edition: false,
                supprime: false,
                exportation: false,
                selectionCycle: false
            },
            colonnesVisibles: [
                { nom: 'docaffnumdoc01', largeur: 300 },
                { nom: 'tydcod', largeur: 120 }
            ],
            colonnesCachees: ['ecrcod']
        };

}
