import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePc0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pc0019Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePc0019 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 115 },
            { nom: 'urgdscabr', largeur: 215 },
            { nom: 'strcod', largeur: 125 },
            { nom: 'strdsc', largeur: 265 },
            { nom: 'tafcod', largeur: 115 },
            { nom: 'tafdsc', largeur: 215 }
        ],
        colonnesFixesDroite: [
            { nom: 'vacvalcle', largeur: 135 },
            { nom: 'vacdsc', largeur: 265 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaccrecst')
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
