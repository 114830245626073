import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterLibelle} from '../../../../../core/filters/ex-libelle.filter';

interface IPageMr0267 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0267Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         exLibelleFilter: IFilterLibelle,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0267 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'egncod', largeur: 90},
            {nom: 'egndscabr', largeur: 300},
            {nom: 'egndsc', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('egncod', {required: true}),
            new FormulaireItem('egndscabr', {required: true}),
            new FormulaireItem('egndsc', {largeur: 50, required: true})
            ],
            {largeurDefaut: 25}
        )
    };
}
