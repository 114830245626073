import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from "angular-ui-router";
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageRR0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0010Controller(rr0002Route: IRoute,
    rr0006Route: IRoute,
    Menu: IMenuClass,
    Pagination: IPaginationClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0010 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: !$stateParams.employe,
            effaceCriteres: $stateParams.employe,
            criteresSuggeresVisibles: !$stateParams.employe
        },
        navigatePage: rr0006Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.trncleint,
                trncleint: rowData.trncleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0002Route.NAME, () => {
                return {
                    tytcleint: 5,
                    demcleint: $stateParams.employe
                };
            })
        ]),
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        filtres: [
            ...parametresRecherche(ecranDetails),
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datlim', { default: null }),
            new FormulaireItem("eplnummat", { required: false }),
            new FormulaireItem("eplnomprn", { required: false }),
            new FormulaireItem("eclcleint", { required: false }),
            new FormulaireItem("poscleint", { required: false }),
            new FormulaireItem("temcleint", { required: false }),
            new FormulaireItem("tafcleint", { required: false }),
            new FormulaireItem("unocleint", { required: false }),
            new FormulaireItem("grecleint", { required: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint', { required: false }),
            new FormulaireItem('strcleint', { required: false })
        ]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe },
            { nom: 'ecldsceta', largeur: 180 },
            { nom: 'aftdatdeb', largeur: 100 },
            { nom: 'aftdatfin', largeur: 100 },
            { nom: 'aftcod', largeur: 100 },
            { nom: 'temcod', largeur: 90 },
            { nom: 'temdscabr', largeur: 262 },
            { nom: 'posnumdoc', largeur: 90 },
            { nom: 'postitabr', largeur: 262 },
            { nom: 'codref', largeur: 90 },
            { nom: 'tafcod', largeur: 90 },
            { nom: 'tafdsc', largeur: 262 },
            { nom: 'aftflgpri', largeur: 72 },
            { nom: 'aftflgttu', largeur: 65 },
            { nom: 'tafflgprb', largeur: 72 },
            { nom: 'tafflgaft', largeur: 72 },
            { nom: 'tafflgaccanc', largeur: 72 },
            { nom: 'aftdatdebadm', largeur: 140 },
            { nom: 'aftdatfinadm', largeur: 130 },
            { nom: 'aftflgpro', largeur: 72 },
            { nom: 'aftflgfincon', largeur: 72 },
            { nom: 'aftdatfinpai', largeur: 120 },
            { nom: 'poscodsup', largeur: 100 },
            { nom: 'postitabrsup', largeur: 262 },
            { nom: 'nomsup', largeur: 262 },
            { nom: 'superviseur', largeur: 240 },
            { nom: 'lemcod', largeur: 90 },
            { nom: 'urgcod', largeur: 90 },
            { nom: 'urgdsc', largeur: 262 },
            { nom: 'strcod', largeur: 90 },
            { nom: 'strdsc', largeur: 262 },
            { nom: 'aftdsc', largeur: 262 },
            { nom: 'unocodrh', largeur: 110 },
            { nom: 'unodscabrrh', largeur: 262 },
            { nom: 'unocodvac', largeur: 110 },
            { nom: 'unodscabrvac', largeur: 262 },
            { nom: 'litcod', largeur: 90 },
            { nom: 'litdsc', largeur: 262 },
            { nom: 'grecod', largeur: 90 },
            { nom: 'gredsc', largeur: 262 },
            { nom: 'aftpostitfer', largeur: 236 },
            { nom: 'vacvalcle', largeur: 100 },
            { nom: 'vacdsc', largeur: 262 },
            { nom: 'aftcom', largeur: 236 },
            { nom: 'unpcod', largeur: 90 },
            { nom: 'unpdsc', largeur: 262 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 76 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'trnflgstaann', largeur: 75 }
        ],
        colonnesCachees: [
            'trncleint',
            'tsecleintori',
            'tytcleint'
        ]
    };
}
