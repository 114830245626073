import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';


interface IPageTF0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;    
}
/* @ngInject */
export default function Tf0006Controller(Menu: IMenuClass,
                                         tf0003Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite
                                         ) {
    const vm: IPageTF0006 = this;


    vm.multiOccurrenceOptions = {
        navigatePage: tf0003Route.NAME,
        actionsNouveaux: new Menu([
        new MenuItemForage(tf0003Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
       colonnesFixesGauche: [
        {nom: 'efacod', largeur: 130},
        {nom: 'efanomprn', largeur: 220}
       ],
        colonnesVisibles: [           
            {nom: 'vaeefatyp', largeur: 150},
            {nom: 'efaini', largeur: 80},                 
            {nom: 'efanas', largeur: 100,  hidden:   !ecranDetails.valeurs.flgnas},
            {nom: 'efadatnai', largeur: 150, hidden: !ecranDetails.valeurs.flgdatnai},
            {nom: 'efaadr1', largeur: 180},
            {nom: 'efaadr2', largeur: 180},
            {nom: 'efaadr3', largeur: 180},
            {nom: 'munnomfra', largeur: 130},
            {nom: 'etanomfra', largeur: 130},
            {nom: 'pysnomfra', largeur: 130},
            {nom: 'efacodpos', largeur: 110},
            {nom: 'cincod', largeur: 100},
            {nom: 'vaeindpai', largeur: 76}, 
            {nom: 'efaflgemf', largeur: 72},
            {nom: 'efaflgimp', largeur: 75}
        ]
    };
}
