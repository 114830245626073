import IStateService = angular.ui.IStateService;
import {
    element,
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    IPromise,
    IScope,
    ITimeoutService
} from 'angular';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceOptions
} from '../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../core/services/menu/menu.service';
import { IMenuItem, IMenuItemClass } from '../../../../core/services/menu/menu-item.service';
import { IMenuMoreClass, IMenuMoreTypes, IMenuMoreTypesOptions } from '../../../../core/services/menu/menu-more.service';
import { IDialog } from '../../../../core/services/dialog.service';
import { IFocusService } from '../../../../core/behaviors/ex-focus/ex-focus.behavior';
import { IMonoOccurrence, IMonoOccurrenceClass } from '../../../../core/services/mono-occurrence.service';
import { IApiError } from '../../../../core/interfaces/api-error.interface';
import { INotificationHandler } from '../../../../core/services/utils/notification-handler.service';
import { ITemplateController } from '../../../../core/services/template.service';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IDataSourceController } from '../../../../core/components/ex-data-source/ex-data-source.component';
import { IChampsTransactionnels } from '../../../../core/resources/champs-transactionnels.resource';
import { IComposantMonoOccurrence } from '../../../../core/components/ex-mono-occurrence/ex-mono-occurrence.controller';
import { IChangementManager } from '../../../../core/services/changement-manager.service';
import { IComposantMonoOccurrenceEcran } from '../../../../core/components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IDataLinker } from '../../../../core/services/data-linker.service';
import { KeyCodes } from '../../../../core/constants/key-codes.constant';
import { IFilterLibelle } from '../../../../core/filters/ex-libelle.filter';

export interface IComposantMultiOccurrenceMaitreDetails extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    champsTransactionnelsOriginals: IChampsTransactionnels;
    dataSourceCtrl: IDataSourceController;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceEnfant: IMultiOccurrence;
    newMonoOccurrence: IMonoOccurrence;
    newOpened: boolean;
    newFormCtrl: IFormController;
    multiOccurrenceMaitreDetailsCtrl: IComposantMultiOccurrenceMaitreDetails;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    templateCtrl: ITemplateController;
    resourceUrl: string;
    containerFab: IAugmentedJQuery;
    updatedRowId: number;
    btnActionFermer: IMenuItem;
    hasAccesEnfant(): boolean;
    isMultiOccurrenceEnfantVisible(): boolean;
    clickEdit(event: MouseEvent, data: any): void;
    clickRow(event: MouseEvent, id: number, data: any): void;
    doubleClickRow(event: MouseEvent, data: any): void;
    closeNew(): void;
    closeRow(data: any): void;
    saveRowAction(monoOccurrence: IMonoOccurrence, savedData: any): IPromise<any>;
    saveNewAction(savedData: any): IPromise<any>;
    saveNewAndNextAction(savedData: any): IPromise<any>;
    retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence): void;
    retryValeursDefautErreur(monoOccurrence: IMonoOccurrence): void;
    displayNewFormulaire(): boolean;
    hasTranscludeDetails(): boolean;
    onTabOut(): void;
    hasBoutonFermer(data: any): boolean;
    keydownEditionRapide(event: KeyboardEvent): void;
    keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence): void
}

/* @ngInject */
export default function MultiOccurrenceMaitreDetailsController(
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    MultiOccurrence: IMultiOccurrenceClass,
    $state: IStateService,
    dataLinker: IDataLinker,
    $scope: IScope,
    DialogConfirm: IDialog,
    exFocus: IFocusService,
    $element: IAugmentedJQuery,
    $timeout: ITimeoutService,
    notificationHandler: INotificationHandler,
    DialogSuiviModification: IDialog,
    MenuMore: IMenuMoreClass,
    MonoOccurrence: IMonoOccurrenceClass,
    changementManager: IChangementManager,
    exLibelleFilter: IFilterLibelle) {
    const vm: IComposantMultiOccurrenceMaitreDetails = this;
    const newAction = new MenuItem('G_LBL_BTN_NOUVEAU', openNew, {
        disabled() {
            return vm.newOpened && !vm.multiOccurrence.editionRapideActive;
        }
    });

    const DEFAULT_LINKED_DATA: any = {};
    const stopListening = changementManager.watchStateChange($element);

    vm.$onInit = $onInit;
    vm.hasAccesEnfant = hasAccesEnfant;
    vm.isMultiOccurrenceEnfantVisible = isMultiOccurrenceEnfantVisible;
    vm.clickEdit = clickEdit;
    vm.clickRow = clickRow;
    vm.doubleClickRow = doubleClickRow;
    vm.closeNew = closeNew;
    vm.closeRow = closeRow;
    vm.saveRowAction = saveRowAction;
    vm.saveNewAction = saveNewAction;
    vm.saveNewAndNextAction = saveNewAndNextAction;
    vm.retryMonoOccurrenceErreur = retryMonoOccurrenceErreur;
    vm.retryValeursDefautErreur = retryValeursDefautErreur;
    vm.displayNewFormulaire = displayNewFormulaire;
    vm.hasTranscludeDetails = hasTranscludeDetails;
    vm.onTabOut = onTabOut;
    vm.hasBoutonFermer = hasBoutonFermer;
    vm.keydownEditionRapide = keydownEditionRapide;

    function $onInit() {
        dataLinker.link($element, DEFAULT_LINKED_DATA, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

        vm.containerFab = $element.find('ex-multi-occurrence:first');

        vm.multiOccurrenceOptions.pagination = {
            nombreElementPage: 3,
            nbElementsPossibles: [3, 25, 50],
            ...vm.multiOccurrenceOptions.pagination
        };

        vm.multiOccurrenceOptions.fonctions = {
            importation: false,
            afficherMenuSettingDansEntete: true,
            suiviModification: true,
            ...vm.multiOccurrenceOptions.fonctions
        };

        if (vm.multiOccurrenceOptions.actionsSelection) {
            vm.multiOccurrenceOptions.rangeesSelectionnables = true;
        }

        if (vm.monoOccurrenceCtrl && !vm.multiOccurrenceMaitreDetailsCtrl) {
            vm.monoOccurrenceCtrl.monoOccurrence.data.$promise.then(() => {
                // Il faut attendre que les données soit chargées. Le id à utilisé est possiblement différent de celui
                // qui est utilisé pour le forage
                const cleint = vm.monoOccurrenceCtrl.monoOccurrence.cleint;
                vm.multiOccurrenceOptions.parentId = vm.monoOccurrenceCtrl.monoOccurrence.data[cleint];
                const cycleCleint = vm.monoOccurrenceCtrl.monoOccurrence.cycleCleint;
                vm.multiOccurrenceOptions.cycleId = vm.monoOccurrenceCtrl.monoOccurrence.data[cycleCleint];

                // On charge le multi seulement en mode édition
                if (vm.multiOccurrenceOptions.parentId) {
                    initMultiOccurrence();
                }
            });
        } else {
            initMultiOccurrence();
        }

        vm.accesEnfant = vm.hasAccesEnfant();
        $scope.$watch(() => vm.hasAccesEnfant(), (newValue: boolean, oldValue: boolean) => {
            if (newValue !== oldValue) {
                vm.accesEnfant = newValue;
            }
        });
        vm.btnActionFermer = new MenuItem('G_LBL_BTN_FERMER', (ev: MouseEvent, data: any) => {
            vm.closeRow(data);
        }, {
            class: 'md-raised ex-button ex-button-secondary',
            iconButton: false
        });

        $scope.$watch('vm.multiOccurrenceEnfant', () => {
            if (vm.multiOccurrence) {
                vm.multiOccurrence.multiOccurrenceEnfant = vm.multiOccurrenceEnfant;
            }
        });
    }

    vm.$onDestroy = () => {
        stopListening();
        vm.multiOccurrence.removeAllListeners();
    }

    function initMultiOccurrence(editionRapideActive = vm.multiOccurrenceOptions.editionRapideActive) {
        if (editionRapideActive) {
            initMultiOccurrenceEditionRapide()
            return
        }
        const menuMoreTypes: IMenuMoreTypes = {};
        vm.multiOccurrence = new MultiOccurrence({
            ...vm.multiOccurrenceOptions,
            hasGridElement: true,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            autoFetch: !vm.multiOccurrenceMaitreDetailsCtrl,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            multiOccurrenceParent: vm.multiOccurrenceMaitreDetailsCtrl && vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence,
            actionsRangeeDroite: new Menu([]),
            editionRapideActive: false
        });

        if (vm.multiOccurrence.fonctions.editionRapide) {
            vm.multiOccurrence.on('editionRapideActiveUpdate', initMultiOccurrenceEditionRapide);
        }

        vm.multiOccurrence.once('ready', () => {
            if (vm.monoOccurrenceEcranCtrl && vm.multiOccurrence.bloc) {
                vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(vm.multiOccurrence);
            }

            const menuMoreTypesOptions: IMenuMoreTypesOptions = {};
            updateAccesNouveau();

            if (vm.multiOccurrence.fonctions.nouveau && !vm.multiOccurrenceOptions.actionsNouveaux) {
                if (vm.multiOccurrence.actionsNouveaux) {
                    vm.multiOccurrence.actionsNouveaux.add(newAction);
                } else {
                    vm.multiOccurrence.actionsNouveaux = new Menu([newAction]);
                }
            }

            if (vm.multiOccurrence.fonctions.edition && !vm.multiOccurrenceOptions.multiOccurrenceOptionsEnfant && vm.multiOccurrence.fonctions.editionStandard) {
                menuMoreTypes.edition = (event: MouseEvent, rowDetails: any) => {
                    if (vm.multiOccurrence.hasEdition(rowDetails)) {
                        toggleRow(rowDetails);
                    }
                };

                menuMoreTypesOptions.edition = {
                    disabled: (data: any) => data.$opened
                };

                if (vm.multiOccurrence.fonctions.edition instanceof Function) {
                    menuMoreTypesOptions.edition.visible = vm.multiOccurrence.fonctions.edition;
                }
            }

            menuMoreTypes.dossierEmploye = vm.multiOccurrence.hasAccesMenuEmploye;

            if (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) {
                menuMoreTypes.suiviModification = (event: any, rowDetails: any) => {
                    afficherSuiviModification(event, rowDetails);
                };
            }

            if (vm.multiOccurrence.fonctions.supprime && vm.multiOccurrence.fonctions.editionStandard) {
                menuMoreTypes.supprime = (event: MouseEvent, rowDetails: any) => {
                    if (vm.multiOccurrence.hasSuppression(rowDetails)) {
                        afficherConfirmerSuppression(event, () => {
                            if (vm.multiOccurrence.isView) {
                                return deleteRowEdition(rowDetails);
                            } else {
                                return deleteRow(rowDetails);
                            }
                        });
                    }
                };

                if (vm.multiOccurrence.fonctions.supprime instanceof Function) {
                    menuMoreTypesOptions.supprime = {
                        visible: vm.multiOccurrence.fonctions.supprime
                    };
                }
            }

            if ((vm.multiOccurrence.fonctions.editionStandard && (vm.multiOccurrence.fonctions.supprime || vm.multiOccurrence.fonctions.edition)) ||
                (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) ||
                (vm.multiOccurrence.actionsMoreLigne && vm.multiOccurrence.actionsMoreLigne.listeMenuItem.length)) {
                vm.multiOccurrence.creerBoutonDupliquer(() => vm.newMonoOccurrence, () => vm.newOpened = true)
                const menuMore = new MenuMore(menuMoreTypes, vm.multiOccurrence.actionsMoreLigne, menuMoreTypesOptions);
                vm.multiOccurrence.actionsRangeeDroite.add(menuMore);
            }

            if (vm.multiOccurrence.navigatePage) {
                vm.multiOccurrence.actionsRangeeDroite.add(new MenuItem('G_LBL_BTN_OUVRIR', getPageLink, {
                    icon: 'exit_to_app',
                    link: true,
                    fonction: `${vm.multiOccurrence.mnemonique}.BOUOUV`,
                    directionTooltip: 'bottom'
                }));
            }

            if (vm.multiOccurrence.multiOccurrenceOptionsEnfant && vm.multiOccurrence.fonctions.edition && vm.multiOccurrence.fonctions.editionStandard) {
                // Action edition secondaire
                vm.multiOccurrence.actionsRangeeDroite.add(new MenuItem('G_LBL_BTN_OUVRIR', clickEdit, {
                    icon: 'mode_edit',
                    iconButton: true,
                    directionTooltip: 'bottom',
                    hidden: (data: any) => vm.multiOccurrence.fonctions.edition instanceof Function && !vm.multiOccurrence.fonctions.edition(data)
                }));
            } else if ((vm.multiOccurrence.details || vm.hasTranscludeDetails()) && vm.multiOccurrence.multiOccurrenceOptionsEnfant) {
                const lblTitre = (vm.multiOccurrenceOptions.details && vm.multiOccurrenceOptions.details.lblTitre) || 'G_LBL_BTN_OUVRIR';
                vm.multiOccurrence.actionsRangeeDroite.add(new MenuItem(lblTitre, toggleRowAction, {
                    icon: 'keyboard_arrow_down',
                    iconButton: true,
                    directionTooltip: 'bottom'
                }));
            }

            addParentListeners()
        });

        vm.multiOccurrence.on('dataListUpdate', () => {
            const currentRow = vm.updatedRowId ? vm.multiOccurrence.dataList.find((row: any) => row[vm.multiOccurrence.cleint] === vm.updatedRowId) : vm.multiOccurrence.dataList[0];

            if (currentRow) {
                vm.multiOccurrence.activeRowCleint = currentRow[vm.multiOccurrence.cleint];
                vm.updatedRowId = null;
            }
        });

        $scope.$on('$destroy', () => vm.multiOccurrence.removeAllListeners());
    }

    function addParentListeners() {
        if (vm.multiOccurrenceMaitreDetailsCtrl) {
            vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence.on('dataListUpdate', () => {
                updateWithParent();
            });

            let resettingRowCleint = false;

            $scope.$watch('vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence.activeRowCleint', (newActiveRowCleint: number, oldActiveRowCleint: number) => {
                if (newActiveRowCleint && !resettingRowCleint) {
                    changementManager.confirmerQuitterPage($element).then(() => {
                        vm.multiOccurrence.etatSelected.pagination.pageCourante = 1;
                        vm.multiOccurrence.fetchDataList();

                        $timeout(() => {
                            updateAccesNouveau();
                        });
                    }).catch(() => {
                        resettingRowCleint = true;
                        vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence.activeRowCleint = oldActiveRowCleint;
                    });
                } else {
                    resettingRowCleint = false;
                }
            });

            $scope.$watch(() => vm.multiOccurrenceMaitreDetailsCtrl.isMultiOccurrenceEnfantVisible(), (isVisible: boolean) => {
                if (isVisible) {
                    $scope.$broadcast('exGridReajusterLargeurColonne');
                }
            });

            if (vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence.fonctions.editionRapide) {
                $scope.$watch('vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence.editionRapideActive', (editionRapideActive: number) => {
                    if (editionRapideActive) {
                        vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence.activeRowCleint = null;
                    }
                });
            }
        }
    }

    function updateWithParent() {
        vm.multiOccurrence.etatSelected.pagination.pageCourante = 1;
        vm.multiOccurrence.fetchDataList();

        $timeout(updateAccesNouveau);
    }

    function initMultiOccurrenceEditionRapide() {
        if (vm.multiOccurrence && !vm.multiOccurrence.editionRapideActive) {
            initMultiOccurrence(false)
            return
        }

        vm.newOpened = false;
        newAction.disabled = false;
        vm.multiOccurrence = new MultiOccurrence({
            ...vm.multiOccurrenceOptions,
            filtres: undefined,
            editionRapideActive: true,
            hasGridElement: true,
            modeEditionRapide: true,
            colonnesVisibles: vm.multiOccurrenceOptions.colonnesEditionRapide,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            multiOccurrenceParent: vm.multiOccurrenceMaitreDetailsCtrl && vm.multiOccurrenceMaitreDetailsCtrl.multiOccurrence,
            actionsNouveaux: new Menu([newAction]),
            autoFetch: true,
            raccourcisRangee: {
                'CTRL+DELETE': (event, data) => supprimerRangeeEditionRapide(event, data)
            },
            actionsRangeeDroite: new Menu([
                new MenuItem('G_LBL_BTN_SUPPRIMER', (event: MouseEvent, data: any) => {
                    supprimerRangeeEditionRapide(event, data);
                }, {
                    iconButton: true,
                    tabindex: -1,
                    icon: (data: any) => {
                        if (!data[vm.multiOccurrence.cleint]) {
                            return 'clear';
                        } else {
                            return 'delete';
                        }
                    },
                    hidden: (data: any) => data[vm.multiOccurrence.cleint] && (!vm.multiOccurrence.hasSuppression(data) || data.$deleted)
                })
            ]),
            fonctions: {
                recherche: false,
                ...vm.multiOccurrenceOptions.fonctions
            }
        });

        vm.multiOccurrence.on('editionRapideActiveUpdate', initMultiOccurrenceEditionRapide);
        vm.multiOccurrence.once('ready', () => {
            if (vm.monoOccurrenceEcranCtrl && vm.multiOccurrence.bloc) {
                vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(vm.multiOccurrence);
            }

            if (!vm.multiOccurrence.hasChampsModifiables(true, {})) {
                // Si on a pas d'édition, on ne peut pas lancer l'éditionRapide.
                vm.multiOccurrence.toggleEditionRapide(false);
            }
            addParentListeners()
        })

        // Si aucune données n'est présente au délenchement de l'édition rapide, on crée une nouvelle ligne
        vm.multiOccurrence.once('dataListUpdate', () => {
            if (!vm.multiOccurrence.dataList.length && hasAccesNouveau(vm.multiOccurrence, DEFAULT_LINKED_DATA)) {
                vm.multiOccurrence.pushRangeeEditionRapide();
            }
        });
        vm.multiOccurrence.startWatching($scope);
    }

    function supprimerRangeeEditionRapide(event: MouseEvent, data: any) {
        event.preventDefault();

        if (!data[vm.multiOccurrence.cleint]) {
            vm.multiOccurrence.deleteRangeeEditionRapide(data.$$index);
        } else {
            afficherConfirmerSuppression(event, () => {
                data.$deleted = true;

                if (data === vm.multiOccurrence.rangeeEditionRapide) {
                    vm.multiOccurrence.rangeeEditionRapide = null;
                    vm.multiOccurrence.indexEditionRapide = null;
                }
            });
        }
    }

    function hasAccesEnfant() {
        const data = vm.multiOccurrence && vm.multiOccurrence.getDataActiveRow() && vm.multiOccurrence.getDataActiveRow().$linked ? vm.multiOccurrence.getDataActiveRow() : DEFAULT_LINKED_DATA;
        return vm.multiOccurrenceEnfant && !vm.multiOccurrence.editionRapideActive && (
            vm.multiOccurrenceEnfant.hasEdition(data) ||
            hasAccesNouveau(vm.multiOccurrenceEnfant, data) ||
            (!vm.multiOccurrenceEnfant.dataListReady && !vm.multiOccurrence.fonctions.criteresSuggeresVisibles) || (
                vm.multiOccurrenceEnfant.etatSelected &&
                (vm.multiOccurrenceEnfant.etatSelected.pagination.total > 0 || (vm.multiOccurrenceEnfant.etatSelected.filtres && vm.multiOccurrenceEnfant.etatSelected.filtres.length > 0))
            )
        );
    }

    function hasAccesNouveau(multiOccurrence: IMultiOccurrence, data: any) {
        if (multiOccurrence.fonctions.nouveau instanceof Function) {
            return multiOccurrence.fonctions.nouveau(data);
        } else {
            return multiOccurrence.fonctions.nouveau;
        }
    }

    function isMultiOccurrenceEnfantVisible() {
        return Boolean(!vm.multiOccurrence.fetchingDataList && !vm.multiOccurrence.dataListError && vm.multiOccurrence.activeRowCleint);
    }

    function getPageLink(data: any) {
        const params = vm.multiOccurrence.navigateParams ?
            vm.multiOccurrence.navigateParams(data, vm.multiOccurrence) :
            {};

        const pageName = vm.multiOccurrence.navigatePage instanceof Function ? vm.multiOccurrence.navigatePage(data) : vm.multiOccurrence.navigatePage;

        return $state.href(pageName, {
            id: data[vm.multiOccurrence.forageCleint],
            menuId: vm.ecranContextCtrl.stateParams.menuId, ...params
        });
    }

    function clickEdit(event: MouseEvent, data: any) {
        toggleRow(data);
    }

    function toggleRowAction(event: MouseEvent, rowDetails: any) {
        const id = rowDetails[vm.multiOccurrence.cleint];
        vm.multiOccurrence.activeRowCleint = id;
        toggleRow(rowDetails);
    }

    function toggleRow(rowDetails: any) {
        rowDetails.$opened = !rowDetails.$opened;
    }

    function clickRow(event: MouseEvent, id: number, data: any) {
        if (vm.multiOccurrenceOptions.multiOccurrenceOptionsEnfant) {
            vm.multiOccurrence.activeRowCleint = id;
        } else if ((vm.multiOccurrence.hasEdition(data) && vm.multiOccurrence.fonctions.editionStandard) || vm.multiOccurrence.details || vm.hasTranscludeDetails()) {
            const target = element(event.target);
            // On ne ferme pas si le clique est dans l'accordéon.
            if (!target.closest('.ex-grid-row-more-content').length && target.closest('html').length) {
                vm.multiOccurrence.activeRowCleint = id;
                toggleRow(data);
            }
        } else {
            vm.multiOccurrence.activeRowCleint = id;
        }
    }

    function doubleClickRow(event: MouseEvent, data: any) {
        vm.multiOccurrence.doubleClickRow(event, data, vm.ecranContextCtrl.stateParams);
    }

    function openNew(event?: MouseEvent) {
        if (vm.multiOccurrence instanceof MultiOccurrence && vm.multiOccurrence.fonctions.editionRapide && vm.multiOccurrence.editionRapideActive) {
            vm.multiOccurrence.unshiftRangeeEditionRapide();

            (<any>event).originalEvent.exGridRowClick = true;

            setTimeout(() => {
                exFocus($element.find('.ex-grid-row:first'));
            });
        } else {
            vm.newMonoOccurrence.resetData();
            vm.newOpened = true;
            newAction.disabled = true;
        }
    }

    function closeNew() {
        vm.newOpened = false;
        newAction.disabled = false;
    }

    function onTabOut() {
        if (hasAccesNouveau(vm.multiOccurrence, DEFAULT_LINKED_DATA)) {
            vm.multiOccurrence.pushRangeeEditionRapide();

            setTimeout(() => {
                exFocus($element.find('.ex-grid-row:last'));
            });
        }
    }

    function closeRow(data: any) {
        data.$opened = false;
    }

    function afficherConfirmerSuppression(event: MouseEvent, confirmAction: Function) {
        DialogConfirm.show({
            targetEvent: event,
            locals: {
                lblTitre: 'G_LBL_BTN_SUPP_ENR',
                lblDescription: 'G_LBL_SUPP_ENR',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                lblMessage: 'G_MSG_SUPP_ENR',
                icon: 'delete',
                ecranContext: vm.ecranContextCtrl,
                confirmAction
            }
        });
    }

    function deleteRow(rowDetails: any) {
        return vm.multiOccurrence.deleteRowDetails(rowDetails).then(() => {
            notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');
        });
    }

    function afficherSuiviModification(ev: any, rowDetails: any) {
        DialogSuiviModification.show({
            targetEvent: ev,
            locals: {
                data: rowDetails,
                ecranContext: vm.ecranContextCtrl
            }
        });
    }

    function afficherConfirmerSauvegarde(action: () => {}, error: IApiError) {
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblConfirm: 'G_LBL_BTN_APPLIQUER',
            lblMessage: 'G_MSG_SAUV_ENR',
            confirmAction() {
                return action();
            }
        });
    }

    function saveRowAction(monoOccurrence: IMonoOccurrence, savedData: any) {
        return saveRowEdition(monoOccurrence, savedData).catch((error: IApiError) => {
            const confirmAction = () => saveRowEdition(monoOccurrence, savedData);
            afficherConfirmerSauvegarde(confirmAction, error);
        });
    }

    function saveRowEdition(monoOccurrence: IMonoOccurrence, savedData: any) {
        return monoOccurrence.save(savedData).then(() => {
            vm.updatedRowId = savedData[vm.multiOccurrence.cleint];
            // La liste utilise un service différent, alors il faut toujours rafraichir même lors des updates
            vm.multiOccurrence.fetchDataList();

            notificationHandler.succes();

            if (savedData.$opened) {
                closeRow(savedData);
            }
        });
    }

    function saveNewAction(savedData: any) {
        return saveNewEdition(savedData).catch((error: IApiError) => {
            const confirmAction = () => saveNewEdition(savedData);
            afficherConfirmerSauvegarde(confirmAction, error);
        });
    }

    function saveNewEdition(savedData: any) {
        return saveRowEdition(vm.newMonoOccurrence, savedData).then(() => {
            vm.closeNew();
        });
    }

    function saveNewAndNextAction(savedData: any) {
        return saveNewAndNextEdition(savedData).catch((error: IApiError) => {
            const confirmAction = () => saveNewAndNextEdition(savedData);
            afficherConfirmerSauvegarde(confirmAction, error);
        });
    }

    function saveNewAndNextEdition(savedData: any) {
        return saveRowEdition(vm.newMonoOccurrence, savedData).then(() => {
            vm.newFormCtrl.$setPristine();
            vm.newFormCtrl.$setUntouched();
            vm.newMonoOccurrence.resetData();
            exFocus($element.find('ex-edition'));
        });
    }

    function deleteRowEdition(rowDetails: any) {
        const monoOccurrenceSupression = new MonoOccurrence({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            multiOccurrenceParent: vm.multiOccurrence,
            srccod: vm.multiOccurrence.srccod,
            isEdition: true
        });
        return monoOccurrenceSupression.delete(rowDetails[vm.multiOccurrence.cleint]).then(() => {
            notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');
            vm.multiOccurrence.fetchDataList();
        });
    }

    function retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchData();
        }
    }

    function retryValeursDefautErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchValeursDefaut();
        }
    }

    function displayNewFormulaire() {
        return vm.newOpened && vm.multiOccurrence.schema && vm.newMonoOccurrence.schema && !vm.newMonoOccurrence.fetchingValeursDefaut && !vm.newMonoOccurrence.valeursDefautError
    }

    function hasTranscludeDetails() {
        const bloc = (vm.multiOccurrence.bloc || '$NONE$').toUpperCase();
        return Boolean(vm.templateCtrl && vm.templateCtrl.templates[bloc] && vm.templateCtrl.templates[bloc].details)
    }

    function updateAccesNouveau() {
        if (vm.multiOccurrence.fonctions.nouveau instanceof Function) {
            const data = (vm.dataSourceCtrl && vm.dataSourceCtrl.data) ? vm.dataSourceCtrl.data : (vm.monoOccurrenceCtrl && !vm.multiOccurrenceMaitreDetailsCtrl) ? vm.monoOccurrenceCtrl.monoOccurrence.data : DEFAULT_LINKED_DATA;

            if (!vm.multiOccurrence.fonctions.nouveau(data)) {
                newAction.disabled = true;
            } else {
                newAction.disabled = false;
            }
        }
    }

    function hasBoutonFermer(data: any) {
        return Boolean(!vm.multiOccurrence.hasEdition(data) && (vm.hasTranscludeDetails() || vm.multiOccurrence.rowHasError(data) || vm.multiOccurrence.details));
    }

    function keydownEditionRapide(event: KeyboardEvent) {
        if (vm.multiOccurrence.editionRapideActive && event.ctrlKey && event.keyCode === KeyCodes.S) {
            event.preventDefault();
            saveEditionRapide();
        }
    }

    function saveEditionRapide() {
        if (vm.isSaving) return;
        if (vm.multiOccurrence.dataList.every(data => data.$editionRapideCtrl.$valid)) {
            vm.isSaving = true;

            return vm.multiOccurrence.saveAll().then(() => {
                notificationHandler.succes();

                //On notifie sur le multiOccurrence principal que la liste a été mise à jour.
                vm.multiOccurrence.emit('dataListUpdate');

                return vm.multiOccurrence.fetchValeursDefaut();
            }).catch((error: IApiError) => {
                let showConfirmAction;

                if (error.data && error.data.details && error.data.details.row !== undefined) {
                    const enregistrementEnErreur = vm.multiOccurrence.dataList[error.data.details.row];

                    if (enregistrementEnErreur) {
                        enregistrementEnErreur.$messagesErreur = [getMessage(error)];
                    }

                    showConfirmAction = false;
                }

                afficherValidationErreur(error, showConfirmAction);
            }).finally(() => {
                vm.isSaving = false;
            });
        }
    }

    function afficherValidationErreur(error: IApiError, showConfirmAction?: boolean) {
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: getMessage(error),
            confirmAction: showConfirmAction ? () => saveEditionRapide() : null
        });
    }

    function getMessage(error: IApiError) {
        return error.data && error.data.code && (error.data.code.startsWith('SOFE-') || error.data.code === 'ERREUR_DE_VALIDATION') ? error.data.message : exLibelleFilter('G_MSG_SAUV_ENR', vm.multiOccurrence.libelles);
    }

    vm.keydownEdition = function keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence) {
        if (event.shiftKey && event.key === "F6" && vm.multiOccurrence.initialized && hasAccesNouveau(vm.multiOccurrence, DEFAULT_LINKED_DATA) && vm.multiOccurrence.isEveryChampsRequisModifiables(false) && vm.multiOccurrence.fonctions.editionStandard && !vm.multiOccurrence.editionRapideActive) {
            event.preventDefault()
            vm.newMonoOccurrence.data = { ...monoOccurrence.data, [monoOccurrence.cleint]: undefined, usrcre: undefined, usrmod: undefined, datmod: undefined, datcre: undefined }
            vm.newMonoOccurrence.id = undefined
            vm.newMonoOccurrence.emit("dataUpdate")
            vm.newOpened = true
        }
    }
}
