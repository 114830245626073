import { IApiConfig } from "../../../../../core/interfaces/api-config.interface";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMenuItemForageExterneClass } from "../../../../../core/services/menu/menu-item-forage-externe.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";

interface IPageGS0047 {
    monoOccurrenceOptions: IMonoOccurrenceOptions
    monoOccurrence: IMonoOccurrence
    eucMultiOccurrenceOptions: IMultiOccurrenceOptions
    edcMultiOccurrenceOptions: IMultiOccurrenceOptions
}
/* @ngInject */
export default function Gs0047Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass, Menu: IMenuClass, MenuItemForageExterne: IMenuItemForageExterneClass, ApiConfig: IApiConfig) {
    const vm: IPageGS0047 = this

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem("ecrcod", { largeur: 25 }),
            new FormulaireItem("ecrdsc", { largeur: 75 })
        ])
    }

    vm.edcMultiOccurrenceOptions = {
        bloc: "edc",
        colonnesVisibles: [
            { nom: "vacedctyp", largeur: 150 },
            { nom: "edcdsc", largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem("vacedctyp"),
            new FormulaireItem("edcdsc")
        ], { uploadFichier: data => !data.edccleint }),
        actionsRangeeDroite: new Menu([new MenuItemForageExterne(data => `${ApiConfig.ROOT}/gsecr-docum-client/${data.edccleint}`, { iconButton: true, icon: "attach_file" })])
    }

    vm.eucMultiOccurrenceOptions = {
        bloc: "euc",
        fonctions: {
            editionRapide: true
        },
        colonnesVisibles: [
            { nom: "vaceuctyp", largeur: 100 },
            { nom: "eucdsc", largeur: 200 },
            { nom: "eucurl", largeur: 300 }
        ],
        colonnesEditionRapide: [
            { nom: "vaceuctyp", largeur: 100 },
            { nom: "eucdsc", largeur: 200 },
            { nom: "eucurl", largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem("vaceuctyp"),
            new FormulaireItem("eucdsc", { required: true }),
            new FormulaireItem("eucurl", { default: "https://" })
        ])
    }
}
