import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc0023 extends IComponentController {
  multiOccurrenceOptions: IMultiOccurrenceOptions;
  retMultiOccurenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0023Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
  const vm: IPageMc0023 = this;

  vm.multiOccurrenceOptions = {
    fonctions: {
      rechercheCollapsable: false
    },
    colonnesVisibles: [
        {nom: 'redcod', largeur:70},
        {nom: 'reddsc', largeur:210 },
        {nom: 'vaeredtyp_dsc', largeur:175 },
        {nom: 'redflgsaiperdec', largeur:130 }
    ],
    formulaire: new Formulaire([
        new FormulaireItem('redcod'),
        new FormulaireItem('reddsc'),
        new FormulaireItem('vaeredtyp'),
        new FormulaireItem('redflgsaiperdec')
  ], {largeurDefaut: 25})
  };

  vm.retMultiOccurrenceOptions = {
    bloc: 'ret',
    colonnesVisibles: [
        {nom: 'tydcod', largeur: 120},
        {nom: 'tyddsc', largeur: 500},
        {nom: 'retflgcen', largeur: 150}
    ],
    formulaire: new Formulaire([
        new FormulaireItem('tydcleint'),
        new FormulaireItem('retflgcen')
    ])
};
}
