import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcran, IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IStateParamsService, IStateService } from "angular-ui-router";

interface IPageRR0074 extends IComponentController {
    monoOccurrence: IMonoOccurrence
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDetjrn: IFormulaire;
    dasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    drpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dceMultiOccurrenceOptions: IMultiOccurrenceOptions;
    etuMultiOccurrenceOptions: IMultiOccurrenceOptions;

    menus: { [key: string]: Array<IMenuItem> };
    // Forage
    foragePerception: IMenuItem;
    foragePerceptionItem: IMenuItem;
    forageCompteRecevoirMenuItem: IMenuItem;
    forageRetenueMenuItem: IMenuItem;

    menuItemPar: IMenuItemEcran;
}

/* @ngInject */
export default function Rr0074Controller(Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    Pagination: IPaginationClass,
    rr2012Route: IRoute,
    mr0205Route: IRoute,
    mr0208Route: IRoute,
    rr0108Route: IRoute,
    rr0071Route: IRoute,
    mr0176Route: IRoute,
    rr0004Route: IRoute,
    rr0006Route: IRoute,
    rr0074Route: IRoute,
    rr0075Route: IRoute,
    rr0076Route: IRoute,
    rr0003Route: IRoute,
    rr0093Route: IRoute,
    rr0095Route: IRoute,
    rr0097Route: IRoute,
    mr2006Route: IRoute,
    mr0212Route: IRoute,
    mr0213Route: IRoute,
    $stateParams: IStateParamsService,
    $state: IStateService,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageRR0074 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.$onInit = function $onInit() {
        // Demande de rémunération
        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonDupliquer: false
            },
            cycleErrorReloadBlocs: ['dce', 'der'],
            infosMonetaires: {
                largeurTexte: 150,
                lblTitre: 'LBL_SOMMAIRE_REMUN',
                noIcons: true,
                blocs: ['dce'],
                afficherAuBas: "oui",
                donnees:
                    [
                        { nom: 'somqte', libelle: 'AUT__SOMQTE' },
                        { nom: 'ecart_qte', libelle: 'AUT__ECART_QTE', flagShowField: ['flgsaisie_dce', 'ecart_qte'] },
                        { nom: 'sommnt', libelle: 'AUT__SOMMNT' },
                        { nom: 'ecart_mnt', libelle: 'AUT__ECART_MNT', flagShowField: ['flgsaisie_dce', 'ecart_mnt'] },
                    ]
            },
            actionsMore: new Menu([
                new MenuItemAction("G_LBL_COPIE", "BOUCOP", () => ({}), {
                    icon: 'content_copy',
                    iconButton: false,
                    successAction: ({ trncleintnew }) => $state.go(rr0074Route.NAME, { id: trncleintnew, menuId: $stateParams.menuId })
                })
            ]),
            formulaire: new Formulaire('LBL_BLC_DER', [
                new FormulaireItem('dernumdoc'),
                new FormulaireItem('tdmcleint'),
                new FormulaireItem('derdat', { default: vm.valeursEcran.datjour }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('derdatdeb'),
                new FormulaireItem('derdatfin'),
                new FormulaireItem('derdatfinori', { readonly: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('derflgpro', { default: 0, largeur: 33.33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('demcleint', {
                    menu: [
                        new MenuItemForage(
                            'BTN_FORAGE_RR0003',
                            rr0003Route.NAME,
                            (data: any) => ({
                                id: data.aut__trncleint_dem
                            }),
                            {
                                icon: 'exit_to_app',
                                iconButton: true,
                                hidden: (data: any) => !data.$ancetre.demcleint || !data.$flgcen
                            }
                        ),
                        new MenuItemForage(
                            'BTN_FORAGE_RR0093',
                            rr0093Route.NAME,
                            (data: any) => ({
                                id: data.aut__eplcleint
                            }),
                            {
                                icon: 'exit_to_app',
                                iconButton: true,
                                hidden: (data: any) => !data.$ancetre.demcleint || data.$flgcen
                            }
                        )
                    ],
                    nombreLibellesDetailsCacher: 4,
                    minWidth: 1 // Pour éviter que le champ s'agrandisse trop à cause des menus
                }),
                new FormulaireItem('urgcleint', {
                    resourceParams: (data: any) => ({ tdmcleint: data.tdmcleint })
                }),
                new FormulaireItem('tagcleint', {
                    resourceParams: (data: any) => ({ urgcleint: data.urgcleint, tdmcleint: data.tdmcleint })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dmecleint', {
                    readonly: true, menu: [
                        new MenuItemForage(
                            'BTN_FORAGE_RR0003',
                            rr0003Route.NAME,
                            (data: any) => ({
                                id: data.aut__trncleint_dem
                            }),
                            {
                                icon: 'accessibility_new',
                                iconButton: true,
                                hidden: (data: any) => data.aut__flgcredme === 1 || !data.dmecleint
                            }
                        ),
                        new MenuItemAction(
                            'LBL_BTN_REPETU',
                            'BOUREPETU',
                            (data: any) => ({
                                stamnetab: 'DER',
                                cleintref: data.dercleint,
                                temcleint: data.temcleint
                            }),
                            {
                                icon: 'accessibility_new',
                                iconButton: true,
                                hidden: (data: any) => !data.aut__flgbourepetu,
                                formulaireConfirmationAction: {
                                    lblTitre: 'LBL_BTN_RECHERCHE',
                                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                                    largeurFormulaire: 25,
                                    formulaire: new Formulaire([
                                        new FormulaireItem('codperm', {
                                            default: (data: any) => data.aut__codepermdef,
                                            required: true,
                                            disabled: (data: any) => (data.dmecleint),
                                            largeur: 100
                                        }),
                                        new FormulaireItem('datdem', {
                                            required: true,
                                            default: (data: any) => data.derdatdeb,
                                            disabled: (data: any) => (data.dmecleint),
                                            largeur: 100
                                        })
                                    ])
                                },
                                successAction: (data: any) => {
                                    $state.go(mr2006Route.NAME, {
                                        menuId: $stateParams.menuId,
                                        id: data.et2cleint
                                    });
                                }
                            }
                        ),
                        new MenuItemEcran(
                            'BTN_FORAGE_RR0076',
                            rr0076Route.NAME,
                            (data: any) => ({
                                id: data.dmecleint,
                                stamnetab: 'DER',
                                cleintref: data.dercleint,
                                dmpcleint: data.dmpcleint
                            }),
                            {
                                icon: 'add_circle',
                                iconButton: true,
                                onClose() {
                                    vm.monoOccurrence.fetchData();
                                },
                                hidden: (data: any) => !data.aut__flgbouadddme
                            }
                        ),
                        new MenuItemEcran(
                            'BTN_FORAGE_DME',
                            rr0076Route.NAME,
                            (data: any) => ({
                                id: data.dmecleint,
                                stamnetab: 'DER',
                                cleintref: data.dercleint,
                                dmpcleint: data.dmpcleint
                            }),
                            {
                                icon: 'launch',
                                iconButton: true,
                                onClose() {
                                    vm.monoOccurrence.fetchData();
                                },
                                hidden: (data: any) => !data.aut__flgboucondme
                            }
                        )
                    ],
                    minWidth: 1 // Pour éviter que le champ s'agrandisse trop à cause des menus
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('derdsc', { largeur: 100, enableCount: true }),
                new FormulaireItemDivider(vm.valeursEcran.t_mode_saisie),
                new FormulaireItem('dertauhor', { largeur: 20 }),
                new FormulaireItem('aut__t_tagtau', { largeur: 20 }),
                new FormulaireItem('aut__t_uncdsctau', {
                    largeur: 20,
                    titre: ' '
                }),
                new FormulaireItem('derqte', { largeur: 20 }),
                new FormulaireItem('t_uncdscqte', {
                    largeur: 20,
                    titre: ' '
                }),
                new FormulaireItem('dermnt', { largeur: 20 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__t_avasoc', { titre: ' ', hidden: (data: any) => !(data.aut__t_avasoc), largeur: 33.33 }),
                // Accordéon Info. RH
                new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS',
                    new Formulaire([
                        new FormulaireItem('ttccleint', {
                            nombreLibellesDetailsCacher: 1
                        }),
                        // new FormulaireItem('aut__t_num_chargement'),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('lemcleint', {
                            largeur: 40, menu: [new MenuItemForage(
                                rr0004Route.NAME,
                                (data: any) => ({
                                    id: data.trncleintlem
                                }), {
                                fonction: 'BOULEM',
                                hidden: (data: any) => !data.trncleintlem || !data.$flgcen
                            }
                            ),
                            new MenuItemForage(
                                'BTN_FORAGE_RR0095',
                                rr0095Route.NAME,
                                (data: any) => ({
                                    id: data.lemcleint,
                                    lemcleint: data.lemcleint,
                                    demcleint: data.demcleint
                                }),
                                {
                                    icon: 'exit_to_app',
                                    iconButton: true,
                                    fonction: 'BOULEMD',
                                    hidden: (data: any) => !data.$ancetre.demcleint || data.$flgcen
                                }
                            )


                            ]
                        }),
                        new FormulaireItem('t_lemdatdeb', { largeur: 20 }),
                        new FormulaireItem('t_lemdatfin', { largeur: 20 }),
                        new FormulaireItemSautDeColonne({ largeur: 20 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('aftcleint', {
                            largeur: 40, menu: [new MenuItemForage(
                                rr0006Route.NAME,
                                (data: any) => ({
                                    id: data.trncleintaft
                                }), { hidden: (data: any) => !data.trncleintaft || !data.$flgcen }
                            ),
                            new MenuItemForage(
                                'BTN_FORAGE_RR0093',
                                rr0097Route.NAME,
                                (data: any) => ({
                                    id: data.aftcleint
                                }),
                                {
                                    icon: 'exit_to_app',
                                    iconButton: true,
                                    hidden: (data: any) => !data.$ancetre.demcleint || data.$flgcen
                                }
                            )
                            ]
                        }),
                        new FormulaireItem('t_aftdatdeb', { largeur: 20 }),
                        new FormulaireItem('t_aftdatfin', { largeur: 20 }),
                        new FormulaireItemSautDeColonne({ largeur: 20 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('dmpcleint', { largeur: 40, readonly: true }),
                        new FormulaireItem('aut__ofadsc', { largeur: 40, readonly: true }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('intcleint', { largeur: 40, hidden: (!vm.valeursEcran.tdmflgsup) }),
                        new FormulaireItem('litcleint', { largeur: 40 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('aut__t_echelon', { largeur: 15, readonly: true }),
                        new FormulaireItem('aut__t_salaire', { largeur: 15, readonly: true }),
                        new FormulaireItem('aut__t_uncdsc', { largeur: 15, readonly: true, titre: ' ' })
                    ], { largeurDefaut: 33.33 })),

                // Accordéon Autres info
                new FormulaireGroupeAccordeon('LBL_BLC_INFCOM',
                    new Formulaire([
                        new FormulaireItem('temcleint', { required: vm.valeursEcran.tdmflgtem, resourceParams: (data: any) => ({ tdmcleint: data.tdmcleint }) }),
                        new FormulaireItem('unocleint', {
                            resourceParams: (data: any) => ({
                                derdatdeb: data.derdatdeb,
                                derdatfin: data.derdatfin
                            }),
                            required: vm.valeursEcran.tdmflguno
                        }),
                        new FormulaireItem('poscleintsup', { titre: vm.valeursEcran.tdmlibpossup, required: vm.valeursEcran.tdmflgpossup }),
                        new FormulaireItem('nomtit'),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('intcleintref', { required: vm.valeursEcran.tdmflgintref }),
                        new FormulaireItem('adrcou_poste'),
                        new FormulaireItem('intcleintdem', { required: vm.valeursEcran.tdmflgintdem }),
                        new FormulaireItem('esvcleint1', {
                            titre: vm.valeursEcran.esclib1, required: vm.valeursEcran.flgesv1aff,
                            resourceParams: (data: any) => ({
                                esvcleint1: data.esvcleint1,
                                esvcleint2: data.esvcleint2,
                                esvcleint3: data.esvcleint3,
                                esvcleint4: data.esvcleint4,
                                esvcleint5: data.esvcleint5
                            }),
                            menu: [
                                new MenuItemAction(
                                    '',
                                    'BOUADDESV1',
                                    () => ({}),
                                    {
                                        icon: 'add_circle',
                                        iconButton: false,
                                        hidden: (data: any) => (!vm.monoOccurrence.isChampModifiable('esvcleint1', true) || !vm.valeursEcran.escflbouton1),
                                        formulaireConfirmationAction: {
                                            lblTitre: 'esvcleint1',
                                            formulaire: new Formulaire([
                                                new FormulaireItem('esvcod_new', { required: true, largeur: 55 })
                                            ])
                                        },
                                        successAction: (data) => {
                                            setTimeout(() => {
                                                vm.monoOccurrence.data.esvcleint1 = data.esvcleint;
                                                vm.monoOccurrence.data.esvcleint1__esvcod = data.esvcod_new;
                                                vm.monoOccurrence.data.esvcleint1__esvcodmai = null;
                                                vm.monoOccurrence.data.esvcleint1__esvdsc = data.esvdsc
                                            }, 200);
                                        }
                                    })
                            ]
                        }),
                        new FormulaireItem('esvcleint2', {
                            titre: vm.valeursEcran.esclib2,
                            resourceParams: (data: any) => ({
                                esvcleint1: data.esvcleint1,
                                esvcleint2: data.esvcleint2,
                                esvcleint3: data.esvcleint3,
                                esvcleint4: data.esvcleint4,
                                esvcleint5: data.esvcleint5
                            }),
                            required: vm.valeursEcran.flgesv2aff,
                            menu: [
                                new MenuItemAction(
                                    '',
                                    'BOUADDESV2',
                                    () => ({}),
                                    {
                                        icon: 'add_circle',
                                        iconButton: false,
                                        hidden: (data: any) => (!vm.monoOccurrence.isChampModifiable('esvcleint2', true) || !vm.valeursEcran.escflbouton2),
                                        formulaireConfirmationAction: {
                                            lblTitre: 'esvcleint2',
                                            formulaire: new Formulaire([
                                                new FormulaireItem('esvcod_new', { required: true, largeur: 55 })
                                            ])
                                        },
                                        successAction: (data) => {
                                            setTimeout(() => {
                                                vm.monoOccurrence.data.esvcleint2 = data.esvcleint;
                                                vm.monoOccurrence.data.esvcleint2__esvcod = data.esvcod_new;
                                                vm.monoOccurrence.data.esvcleint2__esvcodmai = null;
                                                vm.monoOccurrence.data.esvcleint2__esvdsc = data.esvdsc
                                            }, 200);
                                        }
                                    })
                            ]
                        }),
                        new FormulaireItem('esvcleint3', {
                            titre: vm.valeursEcran.esclib3,
                            resourceParams: (data: any) => ({
                                esvcleint1: data.esvcleint1,
                                esvcleint2: data.esvcleint2,
                                esvcleint3: data.esvcleint3,
                                esvcleint4: data.esvcleint4,
                                esvcleint5: data.esvcleint5
                            }),
                            required: vm.valeursEcran.flgesv3aff,
                            menu: [
                                new MenuItemAction(
                                    '',
                                    'BOUADDESV3',
                                    () => ({}),
                                    {
                                        icon: 'add_circle',
                                        iconButton: false,
                                        hidden: (data: any) => (!vm.monoOccurrence.isChampModifiable('esvcleint3', true) || !vm.valeursEcran.escflbouton3),
                                        formulaireConfirmationAction: {
                                            lblTitre: 'esvcleint3',
                                            formulaire: new Formulaire([
                                                new FormulaireItem('esvcod_new', { required: true, largeur: 55 })
                                            ])
                                        },
                                        successAction: (data) => {
                                            setTimeout(() => {
                                                vm.monoOccurrence.data.esvcleint3 = data.esvcleint;
                                                vm.monoOccurrence.data.esvcleint3__esvcod = data.esvcod_new;
                                                vm.monoOccurrence.data.esvcleint3__esvcodmai = null;
                                                vm.monoOccurrence.data.esvcleint3__esvdsc = data.esvdsc
                                            }, 200);
                                        }
                                    })
                            ]
                        }),
                        new FormulaireItem('esvcleint4', {
                            titre: vm.valeursEcran.esclib4,
                            resourceParams: (data: any) => ({
                                esvcleint1: data.esvcleint1,
                                esvcleint2: data.esvcleint2,
                                esvcleint3: data.esvcleint3,
                                esvcleint4: data.esvcleint4,
                                esvcleint5: data.esvcleint5
                            }),
                            required: vm.valeursEcran.flgesv4aff,
                            menu: [
                                new MenuItemAction(
                                    '',
                                    'BOUADDESV4',
                                    () => ({}),
                                    {
                                        icon: 'add_circle',
                                        iconButton: false,
                                        hidden: (data: any) => (!vm.monoOccurrence.isChampModifiable('esvcleint4', true) || !vm.valeursEcran.escflbouton4),
                                        formulaireConfirmationAction: {
                                            lblTitre: 'esvcleint4',
                                            formulaire: new Formulaire([
                                                new FormulaireItem('esvcod_new', { required: true, largeur: 55 })
                                            ])
                                        },
                                        successAction: (data) => {
                                            setTimeout(() => {
                                                vm.monoOccurrence.data.esvcleint4 = data.esvcleint;
                                                vm.monoOccurrence.data.esvcleint4__esvcod = data.esvcod_new;
                                                vm.monoOccurrence.data.esvcleint4__esvcodmai = null;
                                                vm.monoOccurrence.data.esvcleint4__esvdsc = data.esvdsc;
                                            }, 200);
                                        }
                                    })
                            ]
                        }),
                        new FormulaireItem('esvcleint5', {
                            titre: vm.valeursEcran.esclib5,
                            resourceParams: (data: any) => ({
                                esvcleint1: data.esvcleint1,
                                esvcleint2: data.esvcleint2,
                                esvcleint3: data.esvcleint3,
                                esvcleint4: data.esvcleint4,
                                esvcleint5: data.esvcleint5
                            }),
                            required: vm.valeursEcran.flgesv5aff,
                            menu: [
                                new MenuItemAction(
                                    '',
                                    'BOUADDESV5',
                                    () => ({}),
                                    {
                                        icon: 'add_circle',
                                        iconButton: false,
                                        hidden: (data: any) => (!vm.monoOccurrence.isChampModifiable('esvcleint5', true) || !vm.valeursEcran.escflbouton5),
                                        formulaireConfirmationAction: {
                                            lblTitre: 'esvcleint5',
                                            formulaire: new Formulaire([
                                                new FormulaireItem('esvcod_new', { required: true, largeur: 55 })
                                            ])
                                        },
                                        successAction: (data) => {
                                            setTimeout(() => {
                                                vm.monoOccurrence.data.esvcleint5 = data.esvcleint;
                                                vm.monoOccurrence.data.esvcleint5__esvcod = data.esvcod_new;
                                                vm.monoOccurrence.data.esvcleint5__esvcodmai = null;
                                                vm.monoOccurrence.data.esvcleint5__esvdsc = data.esvdsc
                                            }, 200);
                                        }
                                    })
                            ]
                        }),
                        new FormulaireItem('fdrnumcha', { largeur: 40, readonly: true, hidden: (data: any) => (data.fdrnumcha === null || data.fdrnumcha === undefined) })
                    ], { largeurDefaut: 33.33 }), { hidden: !vm.valeursEcran.flgesv1aff && !vm.valeursEcran.flgesv2aff && !vm.valeursEcran.flgesv3aff && !vm.valeursEcran.flgesv4aff && !vm.valeursEcran.flgesv5aff && !vm.valeursEcran.tdmflgintdem && !vm.valeursEcran.tdmflgintref && !vm.valeursEcran.tdmflgpossup && !vm.valeursEcran.tdmflguno && !vm.valeursEcran.tdmflgtem && ((data: any) => !(data.fdrnumcha)), opened: true })
            ], { largeurDefaut: 33.33 })
        };
        // Bouton paramètre d'embauche
        vm.menuItemPar = new MenuItemForage(
            'LBL_PARAM_PAR',
            rr0108Route.NAME,
            (data: any) => ({
                cpecleintref: data.dercleint,
                stamnetab: 'DER',
                trncleint: data.trncleint
            }),
            {
                icon: 'subtitles',
                iconButton: false
            }
        )

        // Bouton RCE
        vm.menuItemRce = new MenuItemForage(
            'LBL_PARAM_RCE',
            rr2012Route.NAME,
            (data: any) => ({
                dercleint: data.dercleint
            }),
            {
                icon: 'subtitles',
                iconButton: false,
                hidden: (data: any) => !data.aut__flgder013
            }
        )

        // Bouton Ventilation
        vm.menuItemVtl = new MenuItemForage(
            'LBL_PARAM_VTL',
            mr0205Route.NAME,
            (data: any) => ({
                id: data.dercleint,
                stamnetab: "DER"
            }),
            {
                icon: 'subtitles',
                disabled: (data: any) => !data.aut__flgven,
                iconButton: false
            }
        )

        // Bouton Impact budgétaire
        vm.menuItemDoc = new MenuItemForage(
            'LBL_PARAM_DOC',
            mr0208Route.NAME,
            (data: any) => ({
                id: data.demcleint,
                doccleintgrh: (data.doccleint) ? data.doccleint : -1,
                typtri: 1
            }),
            {
                icon: 'subtitles',
                iconButton: false
            }
        )

        // Imputation - Demande de rémunération
        vm.ovdMultiOccurrenceOptions = {
            resourceParamsDynamique: () => ({
                ovdcleintref: vm.monoOccurrence.data.dercleint,
                stamnetab: 'DER'
            }),
            actionsMore: new Menu([
                new MenuItemForage(
                    'G_LBL_IMPACT_BUD',
                    mr0208Route.NAME,
                    (data: any) => ({
                        id: data.demcleint,
                        doccleintgrh: (data.doccleint) ? data.doccleint : -1,
                        typtri: 1
                    }),
                    {
                        icon: 'equalizer',
                        iconButton: false
                    }
                ),
                new MenuItemForage(
                    'G_LBL_ENGAGEMENT',
                    mr0212Route.NAME,
                    (data) => ({
                        id: vm.valeursEcran.doccleintmob,
                        mobcleintref: data.dercleint,
                        stamnetab: "DER"
                    }),
                    {
                        icon: 'create',
                        iconButton: false,
                        fonction: 'BOUENG',
                        hidden: () => !vm.monoOccurrence.data.aut__trnflgstafin
                    }
                ),
                new MenuItemForage(
                    'G_LBL_HISTO_ENG',
                    mr0213Route.NAME,
                    (data) => ({
                        mobcleintref: data.dercleint,
                        stamnetab: "DER"
                    }),
                    {
                        icon: 'restore',
                        iconButton: false,
                        hidden: () => !vm.monoOccurrence.data.aut__trnflgstafin
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('BTN_MODIFIER', mr0176Route.NAME, (rowData: any) => ({
                    id: rowData.ovdcleint,
                    ovdcleintref: vm.valeursEcran.dercleint,
                    stamnetab: 'DER'
                }), {
                    icon: 'launch',
                    fonction: 'BOUOUV',
                    disabled: () => !vm.monoOccurrence.data.dercleint,
                    onClose() {
                        vm.ovdMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', mr0176Route.NAME, () => ({
                    ovdcleintref: vm.valeursEcran.dercleint,
                    stamnetab: 'DER'
                }), {
                    disabled: () => !vm.monoOccurrence.data.dercleint || vm.valeursEcran.trnflgstafin === 1,
                    onClose() {
                        vm.ovdMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            bloc: 'ovd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'intcodnom', largeur: 100 },
                { nom: 'ovnimp', largeur: 300 },
                { nom: 'rsccoddsc', largeur: 100 },
                { nom: 'ovddatvig', largeur: 100 },
                { nom: 'ovddatfin', largeur: 100 }
            ],
            colonnesCachees: [
                'ovdcleint'
            ]
        };

        // Avantages sociaux
        vm.dasMultiOccurrenceOptions = {
            parentId: vm.valeursEcran.dercleint,
            // Ouverture en modale
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0071Route.NAME, (data: any) => ({
                    id: data.dascleint
                }), {
                    icon: 'launch',
                    fonction: 'BOUOUV',
                    appliquer: true,
                    disabled: () => !vm.valeursEcran.dercleint,
                    onClose() {
                        vm.dasMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0071Route.NAME, () => ({
                    demcleint: vm.monoOccurrence.data.demcleint,
                    dercleint: vm.monoOccurrence.data.dercleint,
                    trncleint: vm.monoOccurrence.data.trncleint
                }), {
                    appliquer: true,
                    onClose() {
                        vm.dasMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            bloc: 'das',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'avsdsc', largeur: 300 },
                { nom: 'dasdatvig', largeur: 131 },
                { nom: 'avcdsc', largeur: 131 },
                { nom: 'dasflgact', largeur: 131 }
            ]
        };

        // Prolongation / Ajustement
        vm.drpMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemForage('LBL_FORAGE_DRP',
                    rr0075Route.NAME,
                    (data: any) => ({
                        id: data.trncleint
                    }),
                    {
                        icon: 'exit_to_app',
                        fonction: 'BOUOUV'
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage(rr0075Route.NAME,
                    () => ({
                        dercleint: vm.monoOccurrence.data.dercleint
                    }),
                    {
                        disabled: !vm.valeursEcran.trnflgstafin || !vm.valeursEcran.ttccleintpro
                    })
            ]),
            bloc: 'drp',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'ecldsceta', largeur: 150 },
                { nom: 'drpdatdeb', largeur: 150 },
                { nom: 'drpdatfin', largeur: 150 },
                { nom: 'drpqte', largeur: 150 },
                { nom: 'drpmnt', largeur: 150 }
            ]
        };

        // Détail journalier
        vm.formulaireDetjrn = new Formulaire(('LBL_BLC_DETJRN'), [
            new FormulaireItemDivider('LBL_DIV_SEM1', { hidden: (data: any) => typeof data.t_flgaff_derqte01 !== 'undefined' ? !data.t_flgaff_derqte01 : !data.aut__t_flgaff_sem1 }),
            new FormulaireItem('derqte01'),
            new FormulaireItem('derqte02'),
            new FormulaireItem('derqte03'),
            new FormulaireItem('derqte04'),
            new FormulaireItem('derqte05'),
            new FormulaireItem('derqte06'),
            new FormulaireItem('derqte07'),

            new FormulaireItemDivider('LBL_DIV_SEM2', { hidden: (data: any) => typeof data.t_flgaff_derqte08 !== 'undefined' ? !data.t_flgaff_derqte08 : !data.aut__t_flgaff_sem2 }),
            new FormulaireItem('derqte08'),
            new FormulaireItem('derqte09'),
            new FormulaireItem('derqte10'),
            new FormulaireItem('derqte11'),
            new FormulaireItem('derqte12'),
            new FormulaireItem('derqte13'),
            new FormulaireItem('derqte14'),

            new FormulaireItemDivider('LBL_DIV_TOTSEM'),
            new FormulaireItem('aut__total_sem1'),
            new FormulaireItem('aut__total_sem2'),
            new FormulaireItem('aut__total_quantite')
        ], { largeurDefaut: 10 });

        // Cédule de paiement
        vm.dceMultiOccurrenceOptions = {
            bloc: 'dce',
            reloadWithBlocs: ['DER'],
            fonctions: {
                nouveau: () => vm.monoOccurrence.data.aut__flgsaisie_dce,
                edition: () => vm.monoOccurrence.data.aut__flgsaisie_dce,
                supprime: () => vm.monoOccurrence.data.aut__flgsaisie_dce
            },
            colonnesVisibles: [
                { nom: 'ppacod', largeur: 90 },
                { nom: 'ppadatdeb', largeur: 110 },
                { nom: 'ppadatfin', largeur: 110 },
                { nom: 'drpnumdoc', largeur: 150 },
                { nom: 'dceqte', largeur: 120, total: true, enleverZeroNonSig: true },
                { nom: 'dcemnt', largeur: 120, total: true },
                { nom: 'ppacodtrt', largeur: 90 },
                { nom: 'dceflgfer', largeur: 80 },
                { nom: 'dceqtecontot', largeur: 130, hidden: !ecranDetails.valeurs.flgaffdce },
                { nom: 'dcemntcontot', largeur: 130, hidden: !ecranDetails.valeurs.flgaffdce }
            ],
            onDelete: () => vm.monoOccurrence.softUpdateData(),

            formulaire: new Formulaire([
                new FormulaireItem('ppacleint', {
                    largeur: 30,
                    resourceParams: () => ({
                        demcleint: vm.monoOccurrence.data.demcleint,
                        derdatdeb: vm.monoOccurrence.data.derdatdeb,
                        derdatfin: vm.monoOccurrence.data.derdatfin
                    })
                }),
                new FormulaireItem('dceqte', {
                    required: () => vm.monoOccurrence.data.aut__t_flgobl_dceqte,
                    hidden: () => !vm.monoOccurrence.data.aut__t_flgaff_dceqte,
                    disabled: () => vm.monoOccurrence.data.aut__t_flgdis_dceqte
                }),
                new FormulaireItem('dcemnt', {
                    required: () => vm.monoOccurrence.data.aut__t_flgobl_dcemnt,
                    hidden: () => !vm.monoOccurrence.data.aut__t_flgaff_dcemnt,
                    disabled: () => vm.monoOccurrence.data.aut__t_flgdis_dcemnt
                })
            ], { largeurDefaut: 20 })
        };

        // Statut étudiant //
        vm.etuMultiOccurrenceOptions = {
            bloc: 'etu',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('t_flgetu_tous', {
                    largeur: 50,
                    default: '0',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'trimestre', largeur: 300 },
                { nom: 'etuflgtmpcom', largeur: 126 },
                { nom: 'unocoddsc', largeur: 126 },
                { nom: 'trmdatdeb', largeur: 126 },
                { nom: 'trmdatfin', largeur: 126 },
                { nom: 'tvacom', largeur: 126 },
                { nom: 'tvaflgignvalint', largeur: 126 },
                { nom: 'tvaflgignvalimp', largeur: 126 }
            ]
        };
    }
}
