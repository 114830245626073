import { IComponentController } from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
interface IPageAC0004 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0004Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    ac0003Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageAC0004 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails;
        vm.multiOccurrenceOptions = {
            fonctions:{
                supprime:false,
                edition:false,
                exportation:false,
                rechercheCollapsable: false
            },
            navigatePage: ac0003Route.NAME,
            navigateParams: () => (
                {
                    fonction: 'BOUOUV'
                }
            ),
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    ac0003Route.NAME,
                    () => ({}),
                    {
                        fonction: 'BTN_CEDULE'
                    }
                ),
            ]),
            colonnesVisibles: [
                { nom: 'caacod', largeur: 110 },
                { nom: 'caadsc', largeur: 260 },
                { nom: 'intcod', largeur: 100 },
                { nom: 'intnom', largeur: 220 },
                { nom: 'ecvimp', largeur: 260 },
                { nom: 'flgtol', largeur: 100 }
            ]
        };
    }
}
