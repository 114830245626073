import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageCO0010 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIde: IFormulaire;
    apaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    expMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sclMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sclMultiOccurrence: IMultiOccurrence;
}

interface ICo0010Params {
    id: string;
    stamnetab: string;
    idecleintref: number;
    comcleint: number;
    stamnetabcor: string;
    corcleintref: number;
}

/* @ngInject */
export default function Co0010Controller(mr0008Route: IRoute,
    co0011Route: IRoute,
    co0012Route: IRoute,
    $stateParams: ICo0010Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCO0010 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        const readonly = ecranDetails.valeurs.trnflgstafer || (!ecranDetails.valeurs.trnflgstafer && ecranDetails.valeurs.trnflgstafin && ecranDetails.valeurs.comdemutl)

        vm.formulaireIde = new Formulaire('LBL_INFOS_COMP', [
            new FormulaireItem('comcleint', { default: $stateParams.comcleint, resourceParams: () => ({ eplcleint: $stateParams.idecleintref }), required: true, readonly: data => data.comcleint }),
            new FormulaireItemDivider('LBL_ANNEE_DATE'),
            new FormulaireItem('ideannobt', { readonly, hidden: data => !data.comcleint || !data.t_tycflgdatobt }),
            new FormulaireItem('idedatval'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ideannderutl', { readonly, hidden: data => !data.comcleint || !data.t_tycflgdatderutl }),
            new FormulaireItem('idedatexp', { readonly, hidden: data => !data.comcleint || !data.t_tycflgdatexp }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItemDivider('LBL_NIVEAU', { hidden: data => !data.comcleint }),
            new FormulaireItem('nivcleint', { readonly, hidden: data => !data.comcleint || !data.t_sainiv }),
            new FormulaireItem('ideval', { readonly, hidden: data => !data.comcleint || data.t_sainiv }),
            new FormulaireItem('t_unmdsc', {
                default: vm.valeursEcran.unmdsc,
                titre: ' ',
                readonly: true,
                hidden: data => !data.comcleint || data.t_sainiv
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('nivcleintrev', {
                readonly: ecranDetails.valeurs.trnflgstafer,
                hidden: data => !data.comcleint || !data.t_sainiv
            }),
            new FormulaireItem('idevalrev', {
                readonly: ecranDetails.valeurs.trnflgstafer,
                hidden: data => !data.comcleint || data.t_sainiv
            }),
            new FormulaireItem('unmdsc', {
                default: vm.valeursEcran.unmdsc,
                titre: ' '
            }),
            new FormulaireItemDivider('LBL_COMMENTAIRE'),
            new FormulaireItem('idecom', { readonly, largeur: 100 }),
            new FormulaireItem('idecomrev', { largeur: 100, readonly: ecranDetails.valeurs.trnflgstafer }),
        ]);

        vm.apaMultiOccurrenceOptions = {
            bloc: 'apa',
            fonctions: {
                suiviModification: true
            },
            navigatePage: mr0008Route.NAME,
            navigateParams: (rowData: any) => ({ 
                id: rowData.apacleint,
                cleintref:  rowData.idecleint,
                stamnetab:  'IDE',
                vaesystypavi: 'IDE',
                supprime: 0,
                typsai:  'JRS'                    
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage(mr0008Route.NAME, () => { 
                    return {
                        cleintref: vm.monoOccurrence.data.idecleint,
                        stamnetab: 'IDE',
                        vaesystypavi: 'IDE',
                        supprime: 1,
                        vaebmtent: 'IDE',
                        typsai: 'JRS'
                    };                
                })
            ]),
            actionsMoreLigne: new Menu([
                new MenuItemAction(
                    'LBL_SUPPRIME_AVIS',
                    'BOUSUPAVI',
                    () => ({}),
                    {
                        icon: 'delete',
                        successAction: () => vm.monoOccurrence.fetchData()
                    }),
                new MenuItemAction(
                    'LBL_GENERE_AVIS',
                    'BOUGENAVI',
                    () => ({
                        demcleint: vm.valeursEcran.demcleint,
                        idecleint: $stateParams.id
                    }),
                    {
                        icon: 'autorenew',
                        successAction: () => vm.monoOccurrence.fetchData()
                    })
            ], { positionFirst: true }),
            colonnesCachees: [
                'apacleint',
                'idecleint',
                'stamnetab',
                'apacleintref'
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'apasujavi', largeur: 262 },
                { nom: 'avidatavi', largeur: 126 },
                { nom: 'vaesystypavi_dsc', largeur: 158 },
                { nom: 'apadelavi', largeur: 80 }
            ]
        };

        vm.expMultiOccurrenceOptions = {
            bloc: 'exp',
            fonctions: {
                suiviModification: true
            },
            navigatePage: co0012Route.NAME,
            navigateParams: (rowData: any) => ({
                id: rowData.expcleint,
                idecleint: $stateParams.id,
                stamnetab: $stateParams.stamnetab,
                idecleintref: $stateParams.idecleintref,
                comcleint: $stateParams.comcleint,
                stamnetabcor: $stateParams.stamnetabcor,
                corcleintref: $stateParams.corcleintref
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage(co0012Route.NAME, () => ({
                    idecleint: $stateParams.id,
                    stamnetab: $stateParams.stamnetab,
                    idecleintref: $stateParams.idecleintref,
                    comcleint: $stateParams.comcleint,
                    stamnetabcor: $stateParams.stamnetabcor,
                    corcleintref: $stateParams.corcleintref
                }))
            ]),
            colonnesCachees: [
                'expcleint'
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'expent', largeur: 262 },
                { nom: 'expflgperver', largeur: 120 },
                { nom: 'expflgver', largeur: 100 },
                { nom: 'expflgverrh', largeur: 100 },
                { nom: 'expdatdeb', largeur: 126 },
                { nom: 'expdatfin', largeur: 126 }
            ],
        };

        vm.sclMultiOccurrenceOptions = {
            bloc: 'scl',
            fonctions: {
                suiviModification: true
            },
            navigatePage: co0011Route.NAME,
            navigateParams: (rowData: any) => ({
                id: rowData.sclcleint,
                idecleint: $stateParams.id,
                stamnetab: $stateParams.stamnetab,
                idecleintref: $stateParams.idecleintref,
                comcleint: $stateParams.comcleint,
                stamnetabcor: $stateParams.stamnetabcor,
                corcleintref: $stateParams.corcleintref,
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage(co0011Route.NAME, () => ({
                    idecleint: $stateParams.id,
                    stamnetab: $stateParams.stamnetab,
                    idecleintref: $stateParams.idecleintref,
                    comcleint: $stateParams.comcleint,
                    stamnetabcor: $stateParams.stamnetabcor,
                    corcleintref: $stateParams.corcleintref,
                }), {
                    disabled: () => !vm.sclMultiOccurrence || !vm.sclMultiOccurrence.dataList || vm.sclMultiOccurrence.dataList.length
                })
            ]),
            colonnesCachees: [
                'sclcleint'
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'institution', largeur: 262 },
                { nom: 'sclflgverrh', largeur: 100 },
                { nom: 'scldscprg', largeur: 262 }
            ]
        };
    }
}
