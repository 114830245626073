import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGS0110 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0110Controller(gs0109Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0110 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gs0109Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0109Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'atbcod', largeur: 144},
            {nom: 'atblib', largeur: 262},
            {nom: 'vaeatbtypdon_dsc', largeur: 140},
            {nom: 'atblngdon', largeur: 100},
            {nom: 'atbnbrdec', largeur: 110},
            {nom: 'atbflgact', largeur: 90},
            {nom: 'atbflgnonpre', largeur: 130},
            {nom: 'atbdsc', largeur: 262}
        ]
    };
}
