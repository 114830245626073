import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mc0001',
    ecrans: [
        '01-2',
        '02-2',
        '03-2',
        '04-2',
        '05-2',
        '06-2',
        '07-2',
        '08-2',
        '09-2',
        '10-2',
        '11-2',
        '12-2',
        '13-2',
        '14-2',
        '16-2',
        '17-2',
        '18-2',
        '19-2',
        '20-2',
        '21-2',
        '22-2',
        '23-2',
        '24-2',
        '25-2',
        '26-2',
        '27-2',
        '28-2',
        '29-2',
        '30-2',
        '31-2',
        '32-2',
        '33-2',
        '35-2',
        '40-2',
        '41-2',
        '42-2',
        '43-2',
        '44-2',
        '45-2',
        '47-2',
        '48-2',
        '50-2',
        '51-2',
        '52-2',
        '53-2',
        '54-2',
        '55-2',
        '56-2',
        '57-2',
        '58-2',
        '59-2',
        '60-2',
        '61-2',
        '62-2',
        '63-2',
        '64-2',
        '65-2',
        '66-2',
        '67-2',
        '68-2',
        '69-2',
        '70-2',
        '71-2',
        '73-2',
        '74-2',
        '75-2',
        '76-2',
        '77-2',
        '78-2',
        '79-2',
        '80-2',
        '81-2',
        '82-2',
        '83-2',
        '84-2',
        '85-2',
        '86-2',
        '87-2',
        '88-2',
        '90-2',
        '91-2',
        '92-2',
        '93-2',
        '94-2',
        '95-2',
        '96-2',
        '97-2',
        '98-2',
        '99-2',
        '100-2',
        '101-2',
        '103-2'
    ]
});
