import {IComponentController, IFilterDate} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {
    IMenuItemActionTelechargementClass
} from "../../../../../core/services/menu/menu-item-action-telechargement.service";
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMenuSectionClass} from "../../../../../core/services/menu/menu-section.service";
import {IStateParamsService} from "angular-ui-router";

interface IPagePf0012 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireGpe: IFormulaire;
    formulaireDates: IFormulaire;
    valeursEcran: any;
    gcoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gemMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gpfMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0012Controller(rr0003Route: IRoute,
                                         pf0030Route: IRoute,
                                         pf0029Route: IRoute,
                                         $stateParams: IStateParamsService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         MenuSection: IMenuSectionClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         dateFilter: IFilterDate) {
    const vm: IPagePf0012 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    // Lien vers le dossier employé
    const forageDem = new MenuItemForage(
        'G_LBL_FONC_TRANS_DOCUMENT',
        rr0003Route.NAME,
        (data: any) => {
            return {
                employe: data.demcleint
            };
        },
        { fonction:'EMPLOYE' }
    );

    // Téléchargement du calendrier généré
    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            supprime: (data:any) => (data.aut__gpeflgsup)
        },
        pageRetourSuppression: pf0029Route.NAME,
        paramsRetourSuppression: (data: any) => ({id: data.aut__trncleint}),
        actionsMore: new Menu([
            new MenuItemActionTelechargement(
                'LBL_GENERE_ICS',
                'BOUICS',
                () => ({}),
                {
                    iconButton: true,
                    icon: 'event'
                }
            )
        ])
    };

    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireGpe = new Formulaire('LBL_BLC_GPE', [
            new FormulaireItem('gpenumdoc'),
            new FormulaireItem('gpenbrmaxins'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dpmcleint', { default : $stateParams.dpmcleint }),
            new FormulaireItem('aut__dpmnumdoc'),
            new FormulaireItem('acecleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aut__dpmacedsc'),
            new FormulaireItem('gpelieu')
        ]);

        vm.formulaireDates = new Formulaire('LBL_BLC_DATEHEURE', [
            new FormulaireItem('gpedatdeb', {default: dateFilter(new Date(), 'yyyy-MM-dd')}),
            new FormulaireItem('gpehredeb'),
            new FormulaireItem('gpedatfin', {default: dateFilter(new Date(), 'yyyy-MM-dd')}),
            new FormulaireItem('gpehrefin'),
            new FormulaireItem('gpedurhtt'),
            new FormulaireItem('gpedurhht')
        ]);

        vm.gcoMultiOccurrenceOptions = {
            bloc: 'gco',
            colonnesVisibles: [
                {nom: 'comcod', largeur: 120},
                {nom: 'comdsc', largeur: 220},
                {nom: 'nivcod', largeur: 120},
                {nom: 'nivdsc', largeur: 220},
                {nom: 'gcodatexp', largeur: 120},
                {nom: 'gcoindobl', largeur: 110},
                {nom: 'gcodscobl', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gcodatexp')
            ])
        };

        vm.gemMultiOccurrenceOptions = {
            bloc: 'gem',
            reloadWithBlocs: ['GPE'],
            actionsSelection: new Menu([
                new MenuSection('LBL_MARQUER'),
                new MenuItemSelection('LBL_BTN_PRE', 'BOUPRE', {
                    selection: {nom: 'presence'},
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }),
                new MenuItemSelection('LBL_BTN_REU', 'BOUREU', {
                    selection: {nom: 'reussite'},
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }),
                new MenuItemSelection('LBL_BTN_PREREU', 'BOUPREREU', {
                    selection: {nom: 'prereu'},
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }),
                new MenuSection('LBL_DEPLACER'),
                new MenuItemSelection('LBL_BTN_DEPLACER', 'BOUDEPLACER', {
                    selection: {
                        nom: 'deplacer',
                        lblConfirmationTitre: 'LBL_DEPLACER_GROUPE',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('gpecleintnou'),
                            new FormulaireItem('dpmcleint', {
                                default: () => {
                                    return vm.monoOccurrence.data.dpmcleint;
                                },
                                hidden: true
                            })
                        ])
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                })
            ]),
            actionsMoreLigne: new Menu([
                new MenuItemEcran(
                    'LBL_LEM_AFT',
                    pf0030Route.NAME,
                    (data:any) => ({id: data.demcleint}),
                    {
                        icon: 'view_list',
                        iconButton: false
                    }
                )
            ]),
            fonctions: {
                editionRapide: true
            },
            colonnesFixesGauche: [
                {nom: 'eplnummat'   , largeur: 130},
                {nom: 'eplnomprn'   , largeur: 250, menu: [forageDem] }
            ],
            colonnesVisibles: [
                {nom: 'gemflgpre'   , largeur: 100},
                {nom: 'gemflgreu'   , largeur: 100},
                {nom: 'nbrpcs'      , largeur: 130},
                {nom: 'gemcom'      , largeur: 250},
                {nom: 'gemdurhtt'   , largeur: 126},
                {nom: 'gemdurhht'   , largeur: 126},
                {nom: 'gemtauhre'   , largeur: 126},
                {nom: 'taux_classif', largeur: 126, hidden: (data:any) => !data.$valeurs.flgvisibilite},
                {nom: 'unocod'      , largeur: 130},
                {nom: 'unodscabr'   , largeur: 250},
                {nom: 'epladrcou'   , largeur: 250}
            ],
            colonnesEditionRapide: [
                {nom: 'demcleint'     , largeur: 400},
                {nom: 'unocleintrh'   , largeur: 400},
                {nom: 'gemtauhre'     , largeur: 200},
                {nom: 'aut__taux_clas', largeur: 200}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gemcleintref', {default: () => vm.monoOccurrence.data.dpmcleint }),
                new FormulaireItem('demcleint'),
                new FormulaireItem('unocleintrh'),
                new FormulaireItem('gemtauhre'),
                new FormulaireItem('aut__taux_clas'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('gemflgpre', {largeur: 25}),
                new FormulaireItem('gemflgreu', {largeur: 25}),
                new FormulaireItem('gemdurhtt', {largeur: 25}),
                new FormulaireItem('gemdurhht', {largeur: 25}),
                new FormulaireItem('gemcom', {largeur: 100, enableCount: true})
            ],{largeurDefaut: 50}),
            colonnesCachees: [ 'trncleintdem' ]
        };

        vm.gpfMultiOccurrenceOptions = {
            bloc: 'gpf',
            colonnesFixesGauche: [
                {nom: 'gpfseq'      , largeur: 100},
                {nom: 'eplnummat'   , largeur: 130},
                {nom: 'eplnomprn'   , largeur: 250}
            ],
            colonnesVisibles: [
                {nom: 'unocod'      , largeur: 130},
                {nom: 'unodscabr'   , largeur: 250},
                {nom: 'taux_clas'   , largeur: 126, hidden: (data:any) => !data.$valeurs.flgvisibilite},
                {nom: 'gpfdurhttfor', largeur: 126},
                {nom: 'gpfdurhhtfor', largeur: 126},
                {nom: 'gpftauforhre', largeur: 126},
                {nom: 'gpfdurhttpre', largeur: 126},
                {nom: 'gpfdurhhtpre', largeur: 126},
                {nom: 'gpftauprehre', largeur: 126}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gpfseq'        , {largeur: 25}),
                new FormulaireItem('demcleint'     , {largeur: 25}),
                new FormulaireItem('unocleintrh'   , {largeur: 25}),
                new FormulaireItem('aut__taux_clas', {largeur: 25}),
                new FormulaireItemDivider('LBL_DIV_FOR'),
                new FormulaireItem('gpfdurhttfor'),
                new FormulaireItem('gpfdurhhtfor'),
                new FormulaireItem('gpftauforhre'),
                new FormulaireItemDivider('LBL_DIV_PRE'),
                new FormulaireItem('gpfdurhttpre'),
                new FormulaireItem('gpfdurhhtpre'),
                new FormulaireItem('gpftauprehre'),
            ], {largeurDefaut: 33})
        };
    }
}
