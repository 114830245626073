import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageIn0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0005Controller(in0052Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageIn0009 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: in0052Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(in0052Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'magcod'          , largeur: 120},
            {nom: 'magnom'          , largeur: 230},
            {nom: 'magdatdeb'       , largeur: 150},
            {nom: 'magdatfin'       , largeur: 150},
            {nom: 'lipcod'          , largeur: 150},
            {nom: 'magflgsec'       , largeur: 100},
            {nom: 'magflgdep'       , largeur: 100}
        ]
    };
}
