import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IStateParamsService} from 'angular-ui-router';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMR0224 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireMpe: IFormulaire;
    formulaireDoc: IFormulaire;
    peeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mprMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0224Controller(mr0150Route: IRoute,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMR0224 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        const titre = ($stateParams.ecran === '00-2') ? 'LBL_BLC_MPE0' : 'LBL_BLC_MPE1';

        vm.formulaireMpe = new Formulaire(titre, [

            new FormulaireItem('mpdcleint', {
                resourceParams: (data:any) => ({
                    flgcarra: $stateParams.carra
                }),
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('mpedsc'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('mpemsg',{largeur : 100}),
            new FormulaireItemSautDeLigne(),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_MPDDOC', new Formulaire([
                new FormulaireItem('t_mpddoc',{largeur : 100}),
            ])),

        ]);

        // Restriction du type de date
        vm.formulaireDoc = new Formulaire('LBL_BLC_DOC', [
            new FormulaireItem('mpedoc',{largeur : 100})
        ]);

        // PM1- Employé à exclure
        vm.peeMultiOccurrenceOptions = {
            bloc: 'pee',
            formulaire: new Formulaire([
                new FormulaireItem('demcleint',{largeur: 100}),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('peedatexc')
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'eplnummat', largeur: 100},
                {nom: 'eplnomprn', largeur: 260},
                {nom: 'peedatexc', largeur: 110}
            ]
        };

        // PM1 - Paramètres
        vm.mprMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', mr0150Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.mprcleint,
                        mpecleint: vm.monoOccurrence.data.mpecleint,
                        mpdcleint: vm.monoOccurrence.data.mpdcleint
                    };
                }, {
                    icon: 'launch',
                    onClose() {
                        vm.mprMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', mr0150Route.NAME, (rowData: any) => {
                    return {
                        mpecleint: vm.monoOccurrence.data.mpecleint,
                        mpdcleint: vm.monoOccurrence.data.mpdcleint
                    };
                }, {
                    onClose() {
                        vm.mprMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'mpr',
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'mpvnum', largeur: 55},
                {nom: 'mpvlib', largeur: 320},
                {nom: 'valeur', largeur: 320}
            ]
        };
    }

}
