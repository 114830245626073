import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IComponentController } from 'angular';

interface IPageMc0110 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mc0110Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0110 = this;


    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.multiOccurrenceOptions = {
            colonnesFixesGauche: [
                {nom: 'uimcod',    largeur: 100},
                {nom: 'uimdsc',    largeur: 160},
                {nom: 'sujord',    largeur: 100},
                {nom: 'stadsc',    largeur: 180}
            ],
            colonnesVisibles: [
                {nom: 'prudsc',    largeur: 150, hidden: () => vm.valeursEcran.flgpru === 0 },
                {nom: 'cptdsc',    largeur: 150, hidden: () => vm.valeursEcran.flgcpt === 0 },
                {nom: 'prsdsc',    largeur: 150, hidden: () => vm.valeursEcran.flgprs === 0 },
                {nom: 'unadsc',    largeur: 150, hidden: () => vm.valeursEcran.flguna === 0 },
                {nom: 'cifdsc',    largeur: 150, hidden: () => vm.valeursEcran.flgcif === 0 },
                {nom: 'el1dsc',    largeur: 150, hidden: () => vm.valeursEcran.flgel1 === 0 },
                {nom: 'el2dsc',    largeur: 150, hidden: () => vm.valeursEcran.flgel2 === 0 },
                {nom: 'el3dsc',    largeur: 150, hidden: () => vm.valeursEcran.flgel3 === 0 },
            ],
            colonnesFixesDroite: [
                {nom: 'sujcom',    largeur: 170},
            ],
            colonnesCachees: [
                'uimcleint'
            ],
            formulaire: new Formulaire('LBL_EDITION', [
                new FormulaireItem('uimcleint', { largeur : 15 }),
                new FormulaireItem('sujord',    { largeur : 15 }),
                new FormulaireItem('stamnetab', { largeur : 20 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaesujpru', { largeur : 15, hidden: () => !vm.valeursEcran.flgpru, default: 'FAC' }),
                new FormulaireItem('vaesujcpt', { largeur : 15, hidden: () => !vm.valeursEcran.flgcpt, default: 'FAC' }),
                new FormulaireItem('vaesujprs', { largeur : 15, hidden: () => !vm.valeursEcran.flgprs, default: 'FAC' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaesujuna', { largeur : 15, hidden: () => !vm.valeursEcran.flguna, default: 'FAC' }),
                new FormulaireItem('vaesujcif', { largeur : 15, hidden: () => !vm.valeursEcran.flgcif, default: 'FAC' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaesujel1', { largeur : 15, hidden: () => !vm.valeursEcran.flgel1 }),
                new FormulaireItem('vaesujel2', { largeur : 15, hidden: () => !vm.valeursEcran.flgel2 }),
                new FormulaireItem('vaesujel3', { largeur : 15, hidden: () => !vm.valeursEcran.flgel3 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('sujcom',    { largeur : 65 })
            ])
        };
    }
}
