import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageTF0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0030Controller(Menu: IMenuClass,
    tf0029Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageTF0030 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: tf0029Route.NAME,
        navigateParams: (rowData: any) => ({ id: rowData.tfvcleint }),
        actionsNouveaux: new Menu([
            new MenuItemForage(tf0029Route.NAME)
        ]),
        colonnesFixesGauche: [
            { nom: 'efacod', largeur: 180 },
            { nom: 'efanomprn', largeur: 225 },
            { nom: 'folann', largeur: 75 },
            { nom: 'folcod', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'foldsc', largeur: 184 },
            { nom: 'rfecod', largeur: 147 },
            { nom: 'rfenom', largeur: 228 },
            { nom: 'vaesyscodprv', largeur: 158 },
            { nom: 'foacod', largeur: 90 },
            { nom: 'foadsc', largeur: 236 },
            { nom: 'tfvmnt', largeur: 126 },
            { nom: 'ctrcodnot', largeur: 90 },
            { nom: 'ctrnot', largeur: 262 },
            { nom: 'tfvcom', largeur: 289 },
            { nom: 'tfvflguti', largeur: 76 }
        ],
        colonnesCachees: ['tfvcleint']
    };
}
