import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'zz0001',
    ecrans: [
        '00-2',
        '01-2',
        '02-2',
        '03-2',
        '13-2',
        '20-2',
        '21-2',
        '27-2',
        '28-2',
        '29-2',
        '30-2',
        '31-2',
        '32-2',
        '33-2',
        '34-2',
        '36-2',
        '37-2',
        '40-2',
        '41-2',
        '42-2',
        '43-2',
        '44-2',
        '47-2',
        '48-2',
        '63-2',
        '64-2',
        '66-2',
        '67-2',
        '68-2',
        '69-2',
        '70-2',
        '72-2',
        '77-2',
        '80-2',
        '86-2',
        '91-2'
    ],
    params: [
        '?reference'
    ]
});

