import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMR0241 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0241Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0241 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'mtfnummth', largeur: 30},
            {nom: 'mtfdsc', largeur: 100},
            {nom: 'mtfdoc', largeur: 800}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mtfnummth', {largeur: 30}),
            new FormulaireItem('mtfdsc', {largeur: 50}),
            new FormulaireItem('mtfdoc', {largeur: 100})
        ])
    };
}
