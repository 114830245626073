import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageAS0075 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0075Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,) {
    const vm: IPageAS0075 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('date', {
                required: false
            })
        ]),
        colonnesVisibles: [
            {nom: 'alhnumdoc', largeur: 136},
            {nom: 'usrcod', largeur: 136},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'alhcom', largeur: 541},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
