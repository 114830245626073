import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";


interface IPageAc0083 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0083Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ac0028Route: IRoute) {
    const vm: IPageAc0083 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: ac0028Route.NAME,
        navigateParams: (rowData: any) => ({
            apocleint: rowData.apocleint,
            doccleint: rowData.doccleint
        }),

        colonnesVisibles: [

            {nom: 'aponumdoc', largeur: 40},
            {nom: 'apodsc', largeur: 100},
            {nom: 'mnt_tot_doc_refere', largeur: 30},
            {nom: 'mnt_tot_declaration', largeur: 30}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('aponumdoc'),
            new FormulaireItem('apodsc'),
            new FormulaireItem('mnt_tot_doc_refere'),
            new FormulaireItem('mnt_tot_declaration')
        ]),
    };
}
