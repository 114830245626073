import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';

interface IPageAs0037 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireMessage: IFormulaire;
    formulaireModalite: IFormulaire;
    formulaireArrGrp: IFormulaire;
    formulaireArrHre: IFormulaire;
    formulaireArrVal: IFormulaire;
    multiOccurrenceOptionsGrp: IMultiOccurrenceOptions;
    multiOccurrenceOptionsHre: IMultiOccurrenceOptions;
    multiOccurrenceOptionsVal: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0037Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    as2010Route: IRoute,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageAs0037 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },

        formulaire: new Formulaire('LBL_BLC_RGV', [
            new FormulaireItem('rgvnumseq', { maxlength: 3 }),
            new FormulaireItem('rgvflgexc'),
            new FormulaireItem('rgvflgact'),
            new FormulaireItem('rgvcod'),
            new FormulaireItem('rgvdsc', { largeur: 66.66 }),
            new FormulaireItem('rgvcom', { largeur: 100, enableCount: true }),
            new FormulaireItem('rgvdatdeb'),
            new FormulaireItem('rgvdatfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaergvmod'),
            new FormulaireItem('vaergvtyp'),
            new FormulaireItem('vaergvseq', { required: true }),
        ], { largeurDefaut: 33.33 })
    }

    vm.formulaireMessage = new Formulaire('LBL_MSG', [
        new FormulaireItem('rgvmsg', { largeur: 100, enableCount: true }),
        new FormulaireItem('rgvflgcen'),
        new FormulaireItem('rgvflgporem'),
        new FormulaireItem('rgvflgporges')
    ], { largeurDefaut: 50 });

    vm.formulaireArrGrp = new Formulaire('LBL_BLC_GRP', [
        new FormulaireItem('vaergvmodadmgrp', { largeur: 33.33 }),
        new FormulaireTemplateSlot('grpDetail')
    ]);

    vm.formulaireArrHre = new Formulaire('LBL_BLC_HRE', [
        new FormulaireItem('vaergvmodadmhre', { largeur: 33.33 }),
        new FormulaireTemplateSlot('hreDetail')
    ]);

    vm.formulaireArrVal = new Formulaire('LBL_BLC_VAL', [
        new FormulaireItem('vaergvmodadmval', { largeur: 33.33 }),
        new FormulaireTemplateSlot('valDetail')
    ]);

    vm.multiOccurrenceOptionsGrp = {
        colonnesVisibles: [
            { nom: 'arrnumseq', largeur: 5 },
            { nom: 'maddsc', largeur: 130 },
            { nom: 'arrval', largeur: 150 },
            { nom: 'arrindexc', largeur: 20 },
            { nom: 'arrindval', largeur: 20 },
        ],
        colonnesCachees: ['arrcleint', 'stamnetab', 'madcleint', 'flgmul'],
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'LBL_RESERVE',
                as2010Route.NAME,
                (rowData: any) => ({
                    arrcleint: rowData.arrcleint,
                    madcleint: rowData.madcleint,
                    stamnetab: rowData.stamnetab
                }),
                {
                    fonction: 'RESERVES',
                    icon: 'launch',
                    iconButton: true,
                    hidden: (rowData: any) => !(rowData.flgmul === 1)
                }
            )
        ]),
        formulaire: new Formulaire('LBL_BLC_GRP', [
            new FormulaireItem('arrnumseq'),
            new FormulaireItem('madcleint'),
            new FormulaireItem('arrindexc'),
            new FormulaireItem('madnummth', { hidden: true }),
            new FormulaireItem('vaevaumodges', { hidden: (data: any) => !(data.madnummth === 105) }),
            new FormulaireItem('vaevauon', { hidden: (data: any) => !(data.madnummth === 106 || data.madnummth === 110) })
        ], { largeurDefaut: 33.33 })
    };

    vm.multiOccurrenceOptionsHre = {
        colonnesVisibles: [
            { nom: 'arrnumseq', largeur: 80 },
            { nom: 'maddsc', largeur: 130 },
            { nom: 'arrval', largeur: 150 },
            { nom: 'arrindexc', largeur: 100 },
            { nom: 'arrindval', largeur: 100 }
        ],
        colonnesCachees: ['arrcleint', 'stamnetab', 'madcleint', 'flgmul'],
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'LBL_RESERVE',
                as2010Route.NAME,
                (rowData: any) => ({
                    arrcleint: rowData.arrcleint,
                    madcleint: rowData.madcleint,
                    stamnetab: rowData.stamnetab
                }),
                {
                    fonction: 'RESERVES',
                    icon: 'launch',
                    iconButton: true,
                    hidden: (rowData: any) => !(rowData.flgmul === 1)
                }
            )
        ]),
        formulaire: new Formulaire('LBL_BLC_HRE', [
            new FormulaireItem('arrnumseq'),
            new FormulaireItem('madcleint'),
            new FormulaireItem('arrindexc'),
            new FormulaireItem('madnummth', { hidden: true }),
            // Méthode 3
            new FormulaireItem('vauflgjrsfer3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vaesysjrssem3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauvaldat3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauflgdim3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauflglun3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauflgmar3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauflgmer3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauflgjeu3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauflgven3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            new FormulaireItem('vauflgsam3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
            // Méthode 4
            new FormulaireItem('vauvalnum4', { hidden: (rowData:any) => !(rowData.madnummth === 4) }),
            // Méthode 10
            new FormulaireItem('vaevauope10', { hidden: (rowData:any) => !(rowData.madnummth === 10) }),
            new FormulaireItem('vauvalnum10', { hidden: (rowData:any) => !(rowData.madnummth === 10) }),
            // Méthode 11
            new FormulaireItem('vaudatdeb11', { hidden: (rowData:any) => !(rowData.madnummth === 11) }),
            new FormulaireItem('vaudatfin11', { hidden: (rowData:any) => !(rowData.madnummth === 11) }),
            // Méthode 12
            new FormulaireItem('vauvalnum12', { hidden: (rowData:any) => !(rowData.madnummth === 12) }),
            new FormulaireItem('vaudatdeb12', { hidden: (rowData:any) => !(rowData.madnummth === 12) }),
            new FormulaireItem('vaudatfin12', { hidden: (rowData:any) => !(rowData.madnummth === 12) }),
            // Méthode 14
            new FormulaireItem('vaevauope14', { hidden: (rowData:any) => !(rowData.madnummth === 14) }),
            new FormulaireItem('vauvalnum14', { hidden: (rowData:any) => !(rowData.madnummth === 14) }),
            // Méthode 24
            new FormulaireItem('bqecleint24', { hidden: (rowData:any) => !(rowData.madnummth === 24), required: (rowData:any) => (rowData.madnummth === 24) }),
            new FormulaireItem('vaevauope24', { hidden: (rowData:any) => !(rowData.madnummth === 24) }),
            new FormulaireItem('vauvalnum24', { hidden: (rowData:any) => !(rowData.madnummth === 24) }),
            // Méthode 27
            new FormulaireItem('vauvalnum27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vaudatdeb27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vaudatfin27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItemDivider( 'LBL_DIV_JOUR', { hidden: (rowData:any) => !(rowData.madnummth === 27) } ),
            new FormulaireItem('vauflgjrsfer27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauvaldat27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vaesysjrssem27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauflgdim27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauflglun27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauflgmar27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauflgmer27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauflgjeu27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauflgven27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
            new FormulaireItem('vauflgsam27', { hidden: (rowData:any) => !(rowData.madnummth === 27) }),
        ], { largeurDefaut: 33.33 })
    };

    vm.multiOccurrenceOptionsVal = {
        colonnesVisibles: [
            { nom: 'arrnumseq', largeur: 80 },
            { nom: 'maddsc', largeur: 130 },
            { nom: 'arrval', largeur: 150 },
            { nom: 'arrindval', largeur: 100 }
        ],
        colonnesCachees: ['arrcleint', 'stamnetab', 'madcleint', 'flgmul'],
        formulaire: new Formulaire('LBL_BLC_VAL', [
            new FormulaireItem('arrnumseq'),
            new FormulaireItem('madcleint'),
            new FormulaireItem('madnummth', { hidden: true }),
            new FormulaireItemSautDeLigne(),
            // Méthode 200
            new FormulaireItem('vauflgjrsfer200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vaesysjrssem200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauvaldat200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauflgdim200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauflglun200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauflgmar200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauflgmer200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauflgjeu200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauflgven200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            new FormulaireItem('vauflgsam200', { hidden: (rowData:any) => !(rowData.madnummth === 200) }),
            // Méthode 201, 203...
            new FormulaireItem('vaevauope', { 
                hidden: (rowData:any) => !(    rowData.madnummth === 201 
                                            || rowData.madnummth === 203
                                            || rowData.madnummth === 204
                                            || rowData.madnummth === 207
                                            || rowData.madnummth === 208
                                            || rowData.madnummth === 211
                                            || rowData.madnummth === 212
                                            || rowData.madnummth === 213) }),
            new FormulaireItem('vauvalnum', { 
                hidden: (rowData:any) => !(    rowData.madnummth === 201 
                                            || rowData.madnummth === 203
                                            || rowData.madnummth === 204
                                            || rowData.madnummth === 207
                                            || rowData.madnummth === 208
                                            || rowData.madnummth === 211
                                            || rowData.madnummth === 212
                                            || rowData.madnummth === 213) }),
            // Méthode 209
            new FormulaireItem('vaudatdeb209', { hidden: (rowData:any) => !(rowData.madnummth === 209) }),
            new FormulaireItem('vaudatfin209', { hidden: (rowData:any) => !(rowData.madnummth === 209) }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaevauope209', { hidden: (rowData:any) => !(rowData.madnummth === 209) }),
            new FormulaireItem('vauvalnum209', { hidden: (rowData:any) => !(rowData.madnummth === 209) }),
            // Méthode 210
            new FormulaireItem('vaumin210', { hidden: (rowData:any) => !(rowData.madnummth === 210) }),
            new FormulaireItem('vaevauope210', { required: true, hidden: (rowData:any) => !(rowData.madnummth === 210) }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaumax210', { hidden: (rowData:any) => !(rowData.madnummth === 210) }),
            new FormulaireItem('vaevauope2210', { required: true, hidden: (rowData:any) => !(rowData.madnummth === 210) }),
            // Méthode 214
            new FormulaireItem('bqecleint214', { hidden: (rowData:any) => !(rowData.madnummth === 214), required: (rowData:any) => (rowData.madnummth === 214) }),
            new FormulaireItem('vaevauope214', { hidden: (rowData:any) => !(rowData.madnummth === 214) }),
            new FormulaireItem('vauvalnum214', { hidden: (rowData:any) => !(rowData.madnummth === 214) }),
            // Méthode 215
            new FormulaireItem('vaevauope215', { hidden: (rowData:any) => !(rowData.madnummth === 215) }),
            new FormulaireItem('vaevautyptps215', { hidden: (rowData:any) => !(rowData.madnummth === 215) })
        ], { largeurDefaut: 33.33 }),
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'LBL_RESERVE',
                as2010Route.NAME,
                (rowData: any) => ({
                    arrcleint: rowData.arrcleint,
                    madcleint: rowData.madcleint,
                    stamnetab: rowData.stamnetab
                }),
                {
                    fonction: 'RESERVES',
                    icon: 'launch',
                    iconButton: true,
                    hidden: (rowData: any) => !(rowData.flgmul === 1)
                }
            )
        ]),
    };
}