import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMC0073 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0073Controller(mc0051Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMC0073 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0051Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0051Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'empcod', largeur: 100},
            {nom: 'empnom', largeur: 262},
            {nom: 'empprn', largeur: 262},
            {nom: 'unacod', largeur: 120},
            {nom: 'unadsc', largeur: 226},
            {nom: 'emploc', largeur: 110},
            {nom: 'unpcod', largeur: 160},
            {nom: 'unpdsc', largeur: 230},
            {nom: 'empflgstu', largeur: 100},
            {nom: 'empflgloi95', largeur: 100}
        ]
    };
}
