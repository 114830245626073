import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0016Controller(Menu: IMenuClass,
    $stateParams: any,
    mr2017Route: IRoute,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0016 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            rechercheCollapsable: false
        },
        filtresParametres: [
            { colonne: 'pfccleint', valeur: $stateParams.reference, visible: false }
        ],
        navigatePage: mr2017Route.NAME,
        navigateParams: (data: any) => ({ id: data.pfccleint }),
        actionsNouveaux: new Menu([
            new MenuItemForage(mr2017Route.NAME, () => ({}), {
                fonction: 'BOUNOUV'
            })
        ]),
        colonnesVisibles: [
            { nom: 'pfcdsc', largeur: 180 },
            { nom: 'pfccom', largeur: 262 }
        ],
        colonnesCachees: ['pfccleint'],
        formulaire: new Formulaire([
            new FormulaireItem('pfcdsc', { required: true }),
            new FormulaireItem('pfccom'),
        ])
    };
}
