import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import { IStateService } from 'angular-ui-router';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageRr0097 extends IComponentController {
    menus: {[key: string]: Array<IMenuItem>};
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovdposMultiOccurrenceOptions: IMultiOccurrenceOptions;
    hhsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    derMultiOccurrenceOptions: IMultiOccurrenceOptions;
    
}

/* @ngInject */
export default function Rr0097Controller($stateParams: any,
                                         as0023Route: IRoute,
                                         mr0176Route: IRoute,
                                         rr0093Route: IRoute,
                                         rr0095Route: IRoute,
                                         rr0074Route: IRoute,
                                         rr0075Route: IRoute,
                                         mr0208Route: IRoute,
                                         mr0212Route: IRoute,
                                         mr0213Route: IRoute,
                                         MenuItemAction: IMenuItemActionClass,
                                         $state: IStateService,                                     
                                         Pagination: IPaginationClass,
                                         Operateur: IOperateurService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0097 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.menus = {
            eplemp: [
                new MenuItemForage(
                    'BTN_FORAGE_TE0004',
                    rr0093Route.NAME,
                    (data: any) => ({ id: data.eplcleint }),
                    { hidden: (data: any) => !data.eplcleint }
                )
            ],
            lemcoddsc: [
                new MenuItemForage(
                    'BTN_FORAGE_CP0013',
                    rr0095Route.NAME,
                    (data: any) => ({ id: data.lemcleint }),
                    { hidden: (data: any) => !data.lemcleint }
                )
            ]
        };

        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_LEM', [])
        };

        // Horaire
        vm.hhsMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemForage('BTN_OUVRIR', as0023Route.NAME, (data: any) => {
                    return {
                        id: data.trncleint,
                        ecran: '01-2',
                        hhscleintref: vm.monoOccurrence.data.aftcleint
                    };
                }, {
                    hidden: (data: any) => data.flgposte
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage('', as0023Route.NAME, () => {
                    return {
                        ecran: '01-2',
                        demcleint: vm.monoOccurrence.data.demcleint,
                        lemcleint: vm.monoOccurrence.data.lemcleint,
                        hhscleintref: vm.monoOccurrence.data.aftcleint
                    };
                }, {
                    fonction: 'BOUNOUV',
                })
            ]),
            criteresSuggeres: new Formulaire([                
                new FormulaireItem('annule', {
                    largeur: 50,
                    default: 'N'
                }),
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF'
                })
            ]),
            colonnesVisibles: [
                'ahtcod',
                'ahtdsc',
                'flgposte',
                'trnflgstafin',
                'flgahtman',
                'aeqcod',
                'aeqnom',
                'hhsdatfin',
                'hhsdatdeb',
                'asgcod',
                'asgdsc',
                'hhsdsc'
            ],
            colonnesCachees: ['trncleint', 'hhscleintref'],
            fonctions: {
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            }
        };

        // Avantages sociaux
        vm.dasMultiOccurrenceOptions = {
            bloc: 'das',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF'
                })
            ]),
            filtres: [
                {
                    colonne: 'dasflgact',
                    operateur: Operateur.EGALE,
                    valeur: '1'
                }
            ],
            colonnesVisibles: [
                {nom: 'avcdsc'   , largeur: 206},
                {nom: 'dasdatvig', largeur: 126},
                {nom: 'avsdsc'   , largeur: 206},
                {nom: 'dasflgact', largeur: 100}
            ]
        };

        // Imputation affectation
        vm.ovdMultiOccurrenceOptions = {
            navigatePage: mr0176Route.NAME,
            navigateParams: (rowData: any) => (
                {
                    ovdcleintref: vm.monoOccurrence.data.aftcleint,
                    stamnetab: 'AFT',
                    id: rowData.ovdcleint,
                    ovncleint: rowData.ovncleint
                }
            ),
            actionsMore: new Menu([
                new MenuItemForage(
                    'G_LBL_IMPACT_BUD',
                    mr0208Route.NAME,
                    () => ({
                        id: vm.monoOccurrence.data.demcleint,
                        doccleintgrh: vm.monoOccurrence.data.doccleint,
                        typtri: 1
                    }),
                    {
                        icon: 'equalizer',
                        iconButton: false,
                        fonction: 'BOUOUV'
                    }
                ),
                new MenuItemForage(
                    'G_LBL_ENGAGEMENT',
                    mr0212Route.NAME,
                    (data) => ({
                        id: vm.valeursEcran.mobcleint_act,
                        mobcleintref: data.aftcleint,
                        stamnetab: "AFT"
                    }),
                    {
                        icon: 'create',
                        iconButton: false,
                        fonction: 'BOUCHEN'
                    }
                ),
                new MenuItemForage(
                    'G_LBL_HISTO_ENG',
                    mr0213Route.NAME,
                    (data) => ({
                        mobcleintref: data.aftcleint,
                        stamnetab: "AFT"
                    }),
                    {
                        icon: 'restore',
                        iconButton: false
                    }
                )
            ]),
            resourceParams: {
                ovdcleintref: $stateParams.id,
                stamnetab: 'AFT'
            },
            bloc: 'ovd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles:false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ovnimp', largeur: 300},
                {nom: 'rsccoddsc', largeur: 100},
                {nom: 'ovddatvig', largeur: 100},
                {nom: 'ovddatfin', largeur: 100}
            ],
            colonnesCachees: ['ovdcleint']
        };

        // Imputation poste
        vm.ovdposMultiOccurrenceOptions = {
            navigatePage: mr0176Route.NAME,
            navigateParams: (rowData: any) => (
                {
                    ovdcleintref: vm.monoOccurrence.data.poscleint,
                    stamnetab: 'POS',
                    id: rowData.ovdcleint,
                    ovncleint: rowData.ovncleint
                }
            ),
            resourceParams:{
                    ovdcleintref: vm.valeursEcran.poscleint,
                    stamnetab: 'POS'
            },
            bloc: 'ovdpos',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles:false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ovnimp', largeur: 300},
                {nom: 'rsccoddsc', largeur: 100},
                {nom: 'ovddatvig', largeur: 100},
                {nom: 'ovddatfin', largeur: 100}
            ],
            colonnesCachees: ['ovdcleint']
        };

        vm.derMultiOccurrenceOptions = {
            bloc: 'der',
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'T'
                })
                ,
                new FormulaireItem('selection_axv', {
                    largeur: 100,
                    default: 'ALL'
                })
            ]
            ),
            actionsRangeeDroite: new Menu([
                new MenuItemForage('BTN_OUVRIR'
                    , (data: any) => { return (data.prv === "DER") ? rr0074Route.NAME : rr0075Route.NAME }
                    , (data: any) => {
                        return { id: data.trncleint };
                    }, {
                    fonction: 'BOUDRM',
                })
            ]),
            colonnesVisibles: [
                { nom: 'dernumdoc', largeur: 90 },
                { nom: 'drpnumdoc', largeur: 110 },
                { nom: 'tdmcod', largeur: 110 },
                { nom: 'ecldsceta', largeur: 80 },
                { nom: 'tagcod', largeur: 80 },
                { nom: 'tagdscabr', largeur: 150 },
                { nom: 'derdatdeb', largeur: 110 },
                { nom: 'derdatfin', largeur: 120 },
                { nom: 'derdatfinori', largeur: 120 },
                { nom: 'tdmdsc', largeur: 200 }
            ],
            colonnesCachees: ['aftcleint', 'ciecleint', 'prv', 'trncleint'],
            fonctions: {
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                editionRapide: false
            },
            actionsNouveaux: new Menu([
                new MenuItemAction(
                    'G_LBL_BTN_NOUVEAU',
                    'SIMULER_PROCEDURE',
                    () => ({}),
                    {
                        saveFirst: true,
                        fonction: 'BOUNOU',
                        successAction: (data: any) => {
                            $state.go(rr0074Route.NAME, {
                                menuId: $stateParams.menuId,
                                tdmcleint: data.tdmcleintnew,
                                t_employe: vm.monoOccurrence.data.demcleint,
                                lemcleint: vm.monoOccurrence.data.lemcleint,
                                aftcleint: vm.monoOccurrence.data.aftcleint
                            });
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_DER_DEMANDE_DER',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 50,
                            formulaire: new Formulaire([
                                new FormulaireItem('tdmcleint', {
                                    required: true, largeur: 100
                                })
                            ], {
                                securityCycle: false
                            })
                        }
                    }
                )
            ]),
        }        
    }
}
