import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc2045 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
}


/* @ngInject */
export default function Mc2045Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite) {

    const vm: IPageMc2045 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            boutonDupliquer: false,
            exportation: false
        },


        formulaire: new Formulaire('LBL_BLC_PDF_CLI', [
            new FormulaireItem('prjcleint', { modifAllow: false, largeur: 40 }),
            new FormulaireItem('pdfcod', { modifAllow: false, largeur: 10 }),
            new FormulaireItem('pdfcom', { modifAllow: false, largeur: 30 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireGroupe('LBL_GEN', new Formulaire([
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etbcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleintfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orpcleintfin'),
            new FormulaireItemSautDeLigne()
            ]), { largeur: 60 }),

            new FormulaireGroupe('LBL_TRNETA', new Formulaire([
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pdfflgrecu'),
            new FormulaireItem('peccleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pdfflgtran'),
            new FormulaireItem('pdfmntsub',{ disabled : data => (!data.pdfflgrecu && !data.pdfflgtran),
                                             required: data => (data.pdfflgrecu || data.pdfflgtran)}),
            new FormulaireItemSautDeLigne(),
            ]), { largeur: 60 }),

            new FormulaireGroupe('', new Formulaire([
            new FormulaireItem('pdfflgpri'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pdfflgsldfdsnonrst'),
            new FormulaireItem('pdfflgrecsld'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('peccleintsld'),
            new FormulaireItem('pdfflggesman')
            ]), { largeur: 60 }),
        ], {largeurDefaut: 80})
    };
}

