import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPagePI0045 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0045Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePI0045 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('annpdi', {
                required: true
            })
        ]),
        colonnesVisibles: [
            {nom: 'pipnumdoc', largeur: 116},
            {nom: 'pipnumref', largeur: 131},
            {nom: 'pivann', largeur: 79},
            {nom: 'vaedsc_ver', largeur: 105},
            {nom: 'vaedsc_sit', largeur: 131},
            {nom: 'pipdscabr', largeur: 315}
        ]
    };
}
