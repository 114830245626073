import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageTf2006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf2006Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTf2006 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'folann', largeur: 100},
            {nom: 'btpdatpub', largeur: 135},
            {nom: 'btpcom', largeur: 492},
            {nom: 'btpflgact', largeur: 74}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('folann'),
            new FormulaireItem('btpdatpub'),
            new FormulaireItem('btpcom', {largeur: 55}),
            new FormulaireItem('btpflgact', {default: 1})
        ], {largeurDefaut: 15}),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
