import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'co0010',
    ecrans: ['00-2'],
    params: [
        'id',
        'stamnetab',
        'idecleintref',
        '?comcleint',
        '?stamnetabcor',
        '?corcleintref'
    ]
});
