import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRe0008 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;        
}

/* @ngInject */
export default function Re0008Controller(Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageRe0008 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                supprime : () => vm.monoOccurrence.data.vaesysprvret != 'PROD',
                afficherMenusDansEntete: true
            }
        };
        vm.formulaire = new Formulaire('', [
            new FormulaireItem('rtocleint', {largeur: 40, readonly: (data) => data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demcleint', {largeur: 40, readonly: $stateParams.indinfo === '3'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aftcleint', {largeur: 40, readonly: (data) => data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tagcleint', {largeur: 40, readonly: (data) => data.vaesysprvret === 'PROD'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('elpdatdeb', {largeur: 20, readonly: (data) => data.vaesysprvret === 'PROD'}), 
            new FormulaireItem('elpmnt', {largeur: 20, readonly: (data) => data.vaesysprvret === 'PROD'}), 
            new FormulaireItemSautDeLigne(),          
            new FormulaireItem('elpdatfin', {largeur: 20}),  
            new FormulaireItem('elppou', {largeur: 20, readonly: (data) => data.vaesysprvret === 'PROD'}), 
            new FormulaireItemSautDeLigne(),        
            new FormulaireItem('elpflgact', {largeur: 20, default: 1, readonly: true}), 
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaesysprvret', {largeur: 20, readonly: true, default: 'RETR'}),
            new FormulaireItem('elpflgina', {largeur: 20, readonly: (data) => data.vaesysprvret === 'RETR'})           
        ]);
        
      

    }
}
