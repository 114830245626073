import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0131 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0131Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0131 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'etfnumeme', largeur: 162},
            {nom: 'etfnumtrf', largeur: 160},
            {nom: 'vaesysreftfe_dsc', largeur: 210}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('etfnumeme', {largeur: 20}),
            new FormulaireItem('etfnumtrf', {largeur: 20}),
            new FormulaireItem('vaesysreftfe', {largeur: 40})
        ])
    };
}
