import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireTemplateSlotClass} from '../../../../../core/services/formulaire/formulaire-template-slot.service';

interface IPageMr0329 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
    formulaireAll: IFormulaire;
    formulaireBpa: IFormulaire;
    formulaireMcb: IFormulaire;
    bvaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bboMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bvdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bvlminMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bvlmaxMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0329Params {
    bqecleint: number;
}

/* @ngInject */
export default function Mr0329Controller(mr0329Route: IRoute,
                                         mr0335Route: IRoute,
                                         mr0337Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IMr0329Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Menu: IMenuClass) {
    const vm: IPageMr0329 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_COPIE',
                    mr0337Route.NAME,
                    (data:any) => ({
                        bqecleint: $stateParams.bqecleint,
                        bpacleint: data.bpacleint
                    }),
                    {
                        icon: 'file_copy',
                        iconButton: true
                    }
                )
            ])
        };

        // Aller à
        vm.formulaireAll = new Formulaire('', [
            new FormulaireItem('t_bpacleint', {
                menu: [ new MenuItemForage(
                    'LBL_REFRESH',
                    mr0329Route.NAME,
                    (data: any) => ({
                        id: data.t_bpacleint,
                        bqecleint: $stateParams.bqecleint
                    }), {
                        icon: 'refresh',
                        disabled: (data:any) => !data.t_bpacleint
                    }
                )
                ]})
        ]);


        // Paramètres de banque
        vm.formulaireBpa = new Formulaire('LBL_BLC_BPA', [
            new FormulaireItem('bqecleint', {default: $stateParams.bqecleint}),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint')
        ], {largeurDefaut: 25});

        // Méthodes
        vm.formulaireMcb = new Formulaire('LBL_BLC_MCB', [
            new FormulaireGroupeAccordeon('LBL_MCBEVA', new Formulaire([
                new FormulaireItem('mcbcleinteva', {largeur: 50}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('colcleinteva', {largeur: 50}),
                new FormulaireItem('tdacleinteva', {largeur: 50}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bpaevajrsmoi'),
                new FormulaireItem('bpaevaflgratact', {largeur: 50}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bpaevaflgmoi01'),
                new FormulaireItem('bpaevaflgmoi02'),
                new FormulaireItem('bpaevaflgmoi03'),
                new FormulaireItem('bpaevaflgmoi04'),
                new FormulaireItem('bpaevaflgmoi05'),
                new FormulaireItem('bpaevaflgmoi06'),
                new FormulaireItem('bpaevaflgmoi07'),
                new FormulaireItem('bpaevaflgmoi08'),
                new FormulaireItem('bpaevaflgmoi09'),
                new FormulaireItem('bpaevaflgmoi10'),
                new FormulaireItem('bpaevaflgmoi11'),
                new FormulaireItem('bpaevaflgmoi12')
            ], {largeurDefaut: 33.33}), {largeur: 50}),
            new FormulaireGroupeAccordeon('LBL_MCBAUG', new Formulaire([
                new FormulaireItem('mcbcleintaug', {largeur: 50}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bqecleintaugbor', {largeur: 50}),
                new FormulaireItem('vaesyssldbor', {largeur: 50}),
                new FormulaireItem('bpaflgprchoraug'),
                new FormulaireItem('bpaflgborinc'),
                new FormulaireItem('bpaflgboraff'),
                new FormulaireTemplateSlot('multiBornes', {largeur: 100}),
                new FormulaireTemplateSlot('profilAld', {largeur: 100})
            ]), {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_MCBMIN', new Formulaire([
                new FormulaireItem('mcbcleintmin'),
                new FormulaireTemplateSlot('limiteMin', {largeur: 100})
            ]), {largeur: 50}),
            new FormulaireGroupeAccordeon('LBL_MCBMAX', new Formulaire([
                new FormulaireItem('mcbcleintmax'),
                new FormulaireTemplateSlot('limiteMax', {largeur: 100})
            ]), {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_MCBCRE', new Formulaire([
                new FormulaireItem('mcbcleintcre', {largeur: 50}),
                new FormulaireItem('bpaflgforcre'),
                new FormulaireTemplateSlot('intervalleDate', {largeur: 100})
            ], {largeurDefaut: 33.33}), {largeur: 50}),
            new FormulaireGroupeAccordeon('LBL_MCBSAN', new Formulaire([
                new FormulaireItem('mcbcleintsan'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bqecleintsantrf'),
                new FormulaireItem('tagcleintsanpay')
            ]), {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_MCBUTI', new Formulaire([
                new FormulaireItem('mcbcleintuti'),
                new FormulaireItem('bpautiflgratact')
            ]), {largeur: 50}),
        ], {largeurDefaut: 50});

        // Valeurs
        vm.bvaMultiOccurrenceOptions = {
            bloc: 'bva',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('bpacleint', {default: () => vm.monoOccurrence.id}),
                new FormulaireItem('bvadatvig'),
                new FormulaireItem('temcleint', {largeur: 30}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bvaaugval'),
                new FormulaireItem('bvaaugvalmaxann'),
                new FormulaireItem('unccleintaugval'),
                new FormulaireItem('bvaaugvalmin'),
                new FormulaireItem('bvaevafctcol'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('unccleintcre', {largeur: 30}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('unccleintminval', {largeur: 30}),
                new FormulaireItem('unccleintmaxval', {largeur: 30}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bvamaxtrf'),
                new FormulaireItem('unccleintmaxtrf', {largeur: 30}),
                new FormulaireItem('bvaprctrf')
            ], {largeurDefaut: 20}),
            colonnesVisibles: [
                {nom: 'bvadatvig', largeur: 131},
                {nom: 'temcod', largeur: 120},
                {nom: 'temdscabr', largeur: 260},
                {nom: 'bvaaugval', largeur: 131},
                {nom: 'bvaaugvalmaxann', largeur: 151},
                {nom: 'unccod_aug', largeur: 120},
                {nom: 'bvaaugvalmin', largeur: 141},
                {nom: 'bvaevafctcol', largeur: 131},
                {nom: 'unccod_cre', largeur: 120},
                {nom: 'unccod_min', largeur: 120},
                {nom: 'unccod_max', largeur: 130},
                {nom: 'bvamaxtrf', largeur: 131},
                {nom: 'unccod_trf', largeur: 120},
                {nom: 'bvaprctrf', largeur: 131}
            ],
            colonnesCachees: [
                'bvacleint'
            ]
        };

        // Bornes
        vm.bboMultiOccurrenceOptions = {
            bloc: 'bbo',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('t_datvigbbo', {
                    required: true,
                    default: vm.valeursEcran.datvigbbo,
                    resourceParams: () => ({ bpacleint: vm.monoOccurrence.id })
                })
            ]),
            // Formulaire de saisie
            formulaire: new Formulaire([
                new FormulaireItem('bvacleint', {
                    largeur: 100,
                    resourceParams: () => ({ bpacleint: vm.monoOccurrence.id })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaebbotyp'),
                new FormulaireItem('bboborinf'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bboval'),
                new FormulaireItem('bbofct')
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'vaebbotyp_dsc', largeur: 240},
                {nom: 'bboborinf', largeur: 131},
                {nom: 'bboval', largeur: 131},
                {nom: 'bbofct', largeur: 131},
                {nom: 'flgexc', largeur: 91},
                {nom: 'temcoddscabr', largeur: 240},
            ],
            colonnesCachees: [
                'bbocleint'
            ]
        };

        // Intervalle de dates
        vm.bvdMultiOccurrenceOptions = {
            bloc: 'bvd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('t_datvigbvd', {
                    required: true,
                    default: vm.valeursEcran.datvigbvd,
                    resourceParams: () => ({ bpacleint: vm.monoOccurrence.id })
                })
            ]),
            // Formulaire de saisie
            formulaire: new Formulaire([
                new FormulaireItem('bvacleint', {
                    largeur: 100,
                    resourceParams: () => ({ bpacleint: vm.monoOccurrence.id })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bvddatdeb'),
                new FormulaireItem('bvddatfin'),
                new FormulaireItem('bvdval')
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'bvddatdeb', largeur: 126},
                {nom: 'bvddatfin', largeur: 126},
                {nom: 'bvdval', largeur: 131},
                {nom: 'temcoddscabr', largeur: 240}
            ],
            colonnesCachees: [
                'bvdcleint'
            ]
        };

        
        // Limite minimum
        vm.bvlminMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    '',
                    mr0335Route.NAME,
                    (data: any) => ({
                        id: data.bvlcleint,
                        bpacleint: vm.monoOccurrence.id,
                        bqecleint: $stateParams.bqecleint,
                        vaebvltyplim: 'MIN'
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_FORAGE_BVL',
                    mr0335Route.NAME, () => {
                        return {
                            bpacleint: vm.monoOccurrence.id,
                            bqecleint: $stateParams.bqecleint,
                            vaebvltyplim: 'MIN'
                        }
                    }
                )
            ]),
            bloc: 'bvlmin',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('t_datvigmin', {
                    required: true,
                    default: vm.valeursEcran.datvigmin,
                    resourceParams: () => ({ bpacleint: vm.monoOccurrence.id })
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'bvlval', largeur: 120},
                {nom: 'vaebvltyplig_dsc', largeur: 240},
                {nom: 'bvlmsg', largeur: 240},
                {nom: 'tagcod', largeur: 120},
                {nom: 'tagdscabr', largeur: 240},
                {nom: 'flgexc', largeur: 91},
                {nom: 'temcoddscabr', largeur: 240},
            ],
            colonnesCachees: [
                'bvlcleint'
            ]
        };

        // Limite maximum
        vm.bvlmaxMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    '',
                    mr0335Route.NAME,
                    (data: any) => ({
                        id: data.bvlcleint,
                        bpacleint: vm.monoOccurrence.id,
                        bqecleint: $stateParams.bqecleint,
                        vaebvltyplim: 'MAX'
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_FORAGE_BVL',
                    mr0335Route.NAME, () => {
                        return {
                            bpacleint: vm.monoOccurrence.id,
                            bqecleint: $stateParams.bqecleint,
                            vaebvltyplim: 'MAX'
                        }
                    }
                )
            ]),
            bloc: 'bvlmax',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('t_datvigmax', {
                    required: true,
                    default: vm.valeursEcran.datvigmax,
                    resourceParams: () => ({ bpacleint: vm.monoOccurrence.id })
                }),
                new FormulaireItem('t_datvigmax__datvig', {hidden: true, default: vm.valeursEcran.datvigmax})
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'bvlval', largeur: 120},
                {nom: 'vaebvltyplig_dsc', largeur: 240},
                {nom: 'bvlmsg', largeur: 240},
                {nom: 'tagcod', largeur: 120},
                {nom: 'tagdscabr', largeur: 240},
                {nom: 'flgexc', largeur: 91},
                {nom: 'temcoddscabr', largeur: 240}
            ],
            colonnesCachees: [
                'bvlcleint'
            ]
        };
    }
}
