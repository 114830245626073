import { IComponentOptions, module } from 'angular';
import ButtonFabSelectionController from './ex-button-fab-selection.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-button-fab-selection', []).component('exButtonFabSelection', ButtonFabSelectionFactory());

function ButtonFabSelectionFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                // Soit un menu, soit un menuItem
                action: '<',
                container: '<?',
                enregistrements: '<'
            }
        ),
        require: {
            cardController: '?^^exCard',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: ButtonFabSelectionController,
        controllerAs: 'vm',
        template: require('./ex-button-fab-selection.html')
    };
}
