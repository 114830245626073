import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';

interface IPageMR0157 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceGRQOptions: IMultiOccurrenceOptions;
    multiOccurrenceQSDOptions: IMultiOccurrenceOptions;
    forageAnalyse: IMenuItem;
}

/* @ngInject */
export default function Mr0157Controller(mr0159Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0157 = this;

    vm.multiOccurrenceQSDOptions = {
        bloc: 'qsd',
        navigatePage: mr0159Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.qsdcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0159Route.NAME, (data:any) => {
                return {
                    grqcleint: data.$parent.$id
                };
            }
            )
        ]),
        colonnesCachees: [
            'qsdcleint'
        ],
        colonnesVisibles: [
            {nom: 'qsdseqaff', largeur: 110},
            {nom: 'qsdnom', largeur: 275},
            {nom: 'qsdtxtqst', largeur: 504},
            {nom: 'qsdflgact', largeur: 74}
        ]
    };

    vm.multiOccurrenceGRQOptions = {
        bloc: 'grq',
        colonnesVisibles: [
            {nom: 'grqseqaff', largeur: 110},
            {nom: 'grqnom', largeur: 275},
            {nom: 'grqtxtint', largeur: 504},
            {nom: 'grqflgexc', largeur: 85}
        ],
        colonnesCachees: [
            'grqcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('grqseqaff', {largeur: 15}),
            new FormulaireItem('grqnom', {largeur: 20}),
            new FormulaireItem('grqtxtint', {largeur: 40}),
            new FormulaireItem('grqflgexc', {largeur: 15})
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceQSDOptions
    };


    vm.multiOccurrenceOptions = {
        navigateParams: (rowData: any) => {
            return {
                qstcleint: rowData.qstcleint
            };
        },
        colonnesVisibles: [
            {nom: 'qstcod', largeur: 110},
            {nom: 'qstdsc', largeur: 275},
            {nom: 'qsttxtint', largeur: 504},
            {nom: 'qstflgdef', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('qstcod', {largeur: 10}),
            new FormulaireItem('qstdsc', {largeur: 30}),
            new FormulaireItem('qsttxtint', {largeur: 50}),
            new FormulaireItem('qstflgdef', {largeur: 10})
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceGRQOptions
    };
}
