import { IComponentController, IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageAs0012 extends IComponentController {
    menus: { [key: string]: Array<IMenuItem> },
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    aaaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    agtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aeqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dcyMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sugMultiOccurrenceOptions: IMultiOccurrenceOptions;
    httMultiOccurrenceOptions: IMultiOccurrenceOptions;
    agbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    asgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ahtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aqtmultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}


/* @ngInject */
export default function As0012Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    dateFilter: IFilterDate,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    as0008Route: IRoute,
    as2002Route: IRoute,
    as0025Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAs0012 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_ASG', [
            new FormulaireItem('asgcod', {
                largeur: 50,
                required: true
            }),
            new FormulaireItem('asgdsc', {
                largeur: 50,
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unpcleint', {
                largeur: 50,
                required: true,
                modifAllow: false
            }),
            new FormulaireItem('afecleint', {
                largeur: 50
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeasgmodges', {
                largeur: 50,
                required: true,
                modifAllow: false
            }),
            new FormulaireItem('vaeasgmodhorrem', {
                largeur: 50,
                required: false,
                disabled: (data: any) => !(data.vaeasgmodges === 'PFX')
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('asgflgresurg', {
                largeur: 50
            }),
            new FormulaireItem('asgflgrestem', {
                largeur: 50
            }),
            new FormulaireGroupeAccordeon('LBL_AUTRES_ASG', new Formulaire([
                new FormulaireItem('vaeasgdebrot', {
                    largeur: 50,
                    required: true
                }),
                new FormulaireItem('vaeasgtecbal', {
                    largeur: 50
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemDivider('LBL_BLC_VAR'),

                new FormulaireItem('asgflghorvar', {
                    largeur: 50
                }),
                new FormulaireItem('bqecleinthorvar', {
                    largeur: 50
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemDivider('LBL_BLC_FRS'),
                new FormulaireItem('frscleintalt', {
                    largeur: 50
                }),
                new FormulaireItem('frscleintemp', {
                    largeur: 50
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('frscleintabs', {
                    largeur: 50
                }),
                new FormulaireItem('frscleintdep', {
                    largeur: 50
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemDivider('LBL_BLC_TTC'),
                new FormulaireItem('ttccleintatf', {
                    largeur: 50
                }),
                new FormulaireItem('ttccleintabs', {
                    largeur: 50
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('asgflgpreeca', {
                    largeur: 33,
                    disabled: (data: any) => !(data.vaeasgmodges === 'PFX')
                }),
                new FormulaireItem('asgflgprehor', {
                    largeur: 33
                }),
                new FormulaireItem('asgflgaffsecsld', {
                    largeur: 33
                })
            ]))
        ], { largeurDefaut: 100 })
    }

    vm.btnMenus = [
        new MenuItemEcran(
            'LBL_BTN_TPP',
            as2002Route.NAME,
            (data: any) => ({ 
                id: data.asgcleint, 
                asgcleint: data.asgcleint, 
                unpcleint: data.unpcleint }),
            {
                icon: '',
                fonction: 'BOUIMP',
                iconButton: false,
                secondaire: true
            }
        ),
        new MenuItemForage(
            'LBL_BTN_RAT',
            as0025Route.NAME,
            () => {
                return {
                    asgcleint: vm.monoOccurrence.data.asgcleint
                }
            },
            {
                iconButton: false,
                secondaire: true,
                icon: '',
            }
        )
    ];

    vm.aaaMultiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesFixesGauche: [
            { nom: 'actcod', largeur: 130 },
            { nom: 'actdsc', largeur: 230 },
        ],
        colonnesVisibles: [
            { nom: 'aaaflgact', largeur: 130 },
            { nom: 'aaaflgpri', largeur: 130 },
            { nom: 'aaaflgatf', largeur: 130 },
            { nom: 'aaaflgvercon', largeur: 130 },
            { nom: 'aaaflgabsaut', largeur: 130 },
            { nom: 'aaaflghredf', largeur: 130 },
            { nom: 'aaaflggenpor', largeur: 130 },
            { nom: 'aaaflggh_uni', largeur: 130 },
            { nom: 'aaaflgsaiact', largeur: 130 },
            { nom: 'ttccodft', largeur: 130 },
            { nom: 'ttccodda', largeur: 130 },
            { nom: 'aaaflgsaipjb', largeur: 130 },
            { nom: 'aaaflgpjbobl', largeur: 130 },
            { nom: 'tpicod', largeur: 130 },
            { nom: 'tpidsc', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('actcleint', {
                required: true
            }),
            new FormulaireItem('aaaflgact', { default: 1 }),
            new FormulaireItem('aaaflgpri'),
            new FormulaireItem('aaaflgatf'),
            new FormulaireItem('aaaflgvercon'),
            new FormulaireItem('aaaflgabsaut'),

            new FormulaireItem('aaaflghredf', {
                disabled: (data: any) => (data.$ancetre.vaeasgmodges === 'PFX')
            }),
            new FormulaireItem('aaaflggenpor'),
            new FormulaireItem('aaaflggh_uni', {
                disabled: (data: any) => (data.$ancetre.vaeasgmodges !== 'PFX')
            }),
            new FormulaireItem('aaaflgsaiact'),
            new FormulaireItem('aaattcft'),
            new FormulaireItem('aaattcda'),
            new FormulaireItem('aaaflgsaipjb'),
            new FormulaireItem('aaaflgpjbobl'),
            new FormulaireItem('tpicleint')
        ], { largeurDefaut: 33 })
    }

    vm.agtMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 100 },
            { nom: 'tagdsc', largeur: 180 },
            { nom: 'agtflgact', largeur: 100 },
            { nom: 'agtflgbal', largeur: 100 },
            { nom: 'agtflgatf', largeur: 100 }
        ],
        colonnesCachees: ['agtcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('tagcleint', {
                required: true
            }),
            new FormulaireItem('agtflgact', { default: 1 }),
            new FormulaireItem('agtflgbal'),
            new FormulaireItem('agtflgatf')
        ])
    };


    vm.aeqMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'aeqcod', largeur: 120 },
            { nom: 'aeqnom', largeur: 180 },
            { nom: 'posnumdoc', largeur: 120 },
            { nom: 'postitabr', largeur: 180 },
            { nom: 'aeqflgact', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('aeqcod'),
            new FormulaireItem('aeqnom'),
            new FormulaireItem('poscleintsup'),
            new FormulaireItem('aeqflgact', { default: 1 })
        ])
    };


    vm.dcyMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tcycod', largeur: 120 },
            { nom: 'tcydsc', largeur: 150 },
            { nom: 'dcydatdeb', largeur: 126 },
            { nom: 'dcydatfin', largeur: 126 },
            { nom: 'tcyflgdef', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tcycleint'),
            new FormulaireItem('dcydatdeb'),
            new FormulaireItem('dcydatfin')
        ])
    };

    vm.sugMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdscabr', largeur: 180 },
            { nom: 'strcod', largeur: 120 },
            { nom: 'strdsc', largeur: 180 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint', {
                resourceParams: (data: any) => ({ urgcleint: data.urgcleint })
            })
        ])
    };

    vm.httMultiOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'temcoddsc', largeur: 130 },
            { nom: 'httflgact', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('temcleint', {
                required: true,
                largeur: 100
            }),
            new FormulaireItem('httflgact', {
                default: 1,
                largeur: 100
            })
        ])
    };

    vm.agbMultiOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'bqecoddsc', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bqecleint', {
                required: true,
                largeur: 100
            })
        ])
    };

    vm.ahtMultiOccurrenceOptions = {
        navigatePage: as0008Route.NAME,
        navigateParams:()=>({flg:1}),
        actionsNouveaux: new Menu([
            new MenuItemForage(as0008Route.NAME,
                () => {
                    return {
                        asgcleint: vm.monoOccurrence.data.asgcleint,
                    }
                })
        ]),
        colonnesVisibles: [
            { nom: 'ahtcod', largeur: 120 },
            { nom: 'ahtdsc', largeur: 170 },
            { nom: 'ahthresem', largeur: 190 },
            { nom: 'unccod', largeur: 140 },
            { nom: 'uncdsc', largeur: 262 },
            { nom: 'vaeahtmandsc', largeur: 155 }
        ],
        colonnesCachees: ['ahtcleint']
    };

    vm.aqtmultiOccurrenceOptions = {
        bloc: 'aqt',
        colonnesFixesGauche: [
            { nom: 'aqtcod', largeur: 100 },
            { nom: 'aqtdsc', largeur: 262 }
        ],
        colonnesVisibles: [
            { nom: 'aqtcodabr', largeur: 50 },
            { nom: 'aqthredeb', largeur: 95 },
            { nom: 'aqthrefin', largeur: 95 },
            { nom: 'aqtnbrhre', largeur: 115 },
            { nom: 'aqtflgfix', largeur: 59 },
            { nom: 'actcod', largeur: 120 },
            { nom: 'actdsc', largeur: 190 },
            { nom: 'aqtdatdebact', largeur: 120 },
            { nom: 'aqtdatfinact', largeur: 120 }
        ],
        colonnesCachees: ['asgcleint'],
        formulaire: new Formulaire('LBL_BLC_CLI', [
            new FormulaireItem('aqtcod', {
                largeur: 33
            }),
            new FormulaireItem('aqtdsc', {
                largeur: 33
            }),
            new FormulaireItem('aqtcodabr', {
                largeur: 33
            }),
            new FormulaireItem('aqthredeb', {
                largeur: 33
            }),
            new FormulaireItem('aqthrefin', {
                largeur: 33
            }),
            new FormulaireItem('aqtnbrhre', {
                largeur: 33
            }),
            new FormulaireItem('aqtflgfix', {
                largeur: 33
            }),
            new FormulaireItem('actcleint', {
                largeur: 33,
                resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aqtdatdebact', {
                largeur: 33
            }),
            new FormulaireItem('aqtdatfinact', {
                largeur: 33
            })
        ])
    };
}
