import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0080 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0080Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAc0080 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'ncocod', largeur: 70 },
            { nom: 'ncodsc', largeur: 280 },
            { nom: 'ncoflgact', largeur: 70 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ncocod', { required: true }),
            new FormulaireItem('ncodsc', { required: true }),
            new FormulaireItem('ncoflgact', { default: 1 })
        ])
    };
}
