import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireGroupeConditionnelClass} from '../../../../../core/services/formulaire/formulaire-groupe-conditionnel.service';
import {IData} from '../../../../../core/services/data-linker.service';

interface IPageDO0002 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    forageDo0001: IMenuItem;
    formulaireCan: IFormulaire;
    formulaireCandidature: IFormulaire;
    formulaireSelection: IFormulaire;
    formulaireEvaluation: IFormulaire;
    formulaireDerCan: IFormulaire;
    dcaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    corMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cvhMultiOccurrenceOptions: IMultiOccurrenceOptions;
    qsdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    historiqueEtapesMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
    menus: {[key: string]: Array<IMenuItem>};
    getIndicateurRevise(data: any): any;
    getExperienceVerifiee(data: any): any;
    getScolariteVerifiee(data: any): any;
}

interface IDo0002Params {
    id: string;
    menuId: string;
    offcleint: number;
    eplcleint: number;
}

/* @ngInject */
export default function Do0002Controller(do0001Route: IRoute,
                                         do0002Route: IRoute,
                                         co0010Route: IRoute,
                                         rr0003Route: IRoute,
                                         $stateParams: IDo0002Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Pagination: IPaginationClass) {
    const vm: IPageDO0002 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.getExperienceVerifiee = getExperienceVerifiee;
    vm.getScolariteVerifiee = getScolariteVerifiee;

    vm.menus = {
        offnumdoc_dsc: [
            new MenuItemForage(
                'BTN_FORAGE_OFF',
                do0001Route.NAME,
                (data: any) => ({
                    id: vm.valeursEcran.cycle_trn,
                    offcleint: $stateParams.offcleint
                })
            )
        ]
    };

    vm.$onInit = $onInit;

    function $onInit() {
        vm.forageDo0001 = new MenuItemForage('LBL_FORAGE_DO0001',
            do0001Route.NAME,
            (data: any) => {
                return {
                    ecran: '00-2',
                    id: data.trncleint,
                    offcleint: data.offcleint
                };
            }
        );

        vm.formulaireCan = new Formulaire('LBL_CANDIDAT', [
            new FormulaireItem('offcleint', {default: $stateParams.offcleint}),
            new FormulaireItem('ctpcleint', {default: vm.valeursEcran.ctpcleint}),
            new FormulaireItem('demcleint'),
            new FormulaireItem('eplcleint', { menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_EMPLOYE',
                        rr0003Route.NAME,
                        (data: any) => ({
                            id: data.trncleint_dem
                        }),
                        {hidden: (data:any) => !data.trncleint_dem}
                    )
                ]
            }),
            new FormulaireItem('aut__canflginddem'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cannom'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('canpre'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aut__empact', {
                default: vm.valeursEcran.empact
            }),
            new FormulaireItem('t_canflgindlemmul', {
                default: vm.valeursEcran.t_canflgindlemmul
            }),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_COORDONNEES', new Formulaire([
                new FormulaireItem('canadrcou'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cannumtel'),
                new FormulaireItem('cannumfax'),
                new FormulaireItem('cannumcel'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vacsysreg'),

                new FormulaireItemDivider('LBL_ACCCOR_ADRCOR'),
                new FormulaireItem('canadrl01'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('canadrl02'),
                new FormulaireItem('canadrl03'),
                new FormulaireItem('canpay'),
                new FormulaireItem('canpro', { maxlength: 2 }),
                new FormulaireItem('canpro_lov'),
                new FormulaireItem('canvil'),
                new FormulaireItem('canvil_lov'),
                new FormulaireItem('cancodpos'),

                new FormulaireGroupeConditionnel('flgadrper', 'LBL_ACCCOR_ADRPER', new Formulaire([
                    new FormulaireItem('canadr201'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('canadr202'),
                    new FormulaireItem('canadr203'),
                    new FormulaireItem('canpay2'),
                    new FormulaireItem('canpro2', { maxlength: 2 }),
                    new FormulaireItem('canpro2_lov'),
                    new FormulaireItem('canvil2'),
                    new FormulaireItem('canvil2_lov'),
                    new FormulaireItem('cancodpos2')
                ]), {default: vm.valeursEcran.flgadrper})
            ])),
            new FormulaireGroupeAccordeon(
                'LBL_ACCORDEON_ETUDIANT',
                new Formulaire([
                    new FormulaireItem('canmatetu'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('aut__cansexetu'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('cannasetu')
                ]),
                {hidden: (data: any) => !data.aut__flgindetu}
            )
        ]);

        vm.formulaireCandidature = new Formulaire('LBL_CANDIDATURE', [
            new FormulaireItem('cancleint', {
                largeur: 33.33
            }),
            new FormulaireItem('candatsou', {
                largeur: 33.33
            }),
            new FormulaireItem('aut__offdatfinaff', {
                largeur: 33.33
            }),
            new FormulaireItem('cancom', {
                largeur: 100,
                enableCount: true
            }),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_ELESUP', new Formulaire([
                new FormulaireItem('esvcleint1'),
                new FormulaireItem('esvcleint2'),
                new FormulaireItem('esvcleint3'),
                new FormulaireItem('esvcleint4'),
                new FormulaireItem('esvcleint5')
            ]), {
                hidden: (data: IData) => data.$valeurs.compteur === 0
            }),

            new FormulaireItem('canflgdescan'),
            new FormulaireItem('canflgdes'),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_DES', new Formulaire([
                new FormulaireItem('candatdes'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('candscdes', {
                    largeur: 100,
                    enableCount: true
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('candatdescan'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('candscdescan', {
                    largeur: 100,
                })
            ]), {
                disabled: (data: any) => (
                    (!data.canflgdes && !data.canflgdescan) ||
                    (data.canflgdes === 0 && data.canflgdescan === 0)
                )
            })
        ]);

        vm.formulaireSelection = new Formulaire('LBL_SELECTION', [
            new FormulaireItem('canflgpresel'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('canflgsel'),
            new FormulaireItem('canflgrej'),
            new FormulaireItem('candatnom'),
            new FormulaireItem('vaccanrej')
        ]);

        vm.formulaireEvaluation = new Formulaire('LBL_EVALUATION', [
            new FormulaireItem('aut__anciennete'),
            new FormulaireItem('aut__score'),
            new FormulaireItem('grgcleint'),
            new FormulaireItem('canseqeva'),
            new FormulaireItemDivider('LBL_APPRECIATION'),
            new FormulaireItem('cancotapp'),
            new FormulaireItem('candscapp'),
            new FormulaireItem('cancomint', {
                largeur: 100
            })
        ]);

        vm.dcaMultiOccurrenceOptions = {
            bloc: 'dca',
            navigatePage: do0002Route.NAME,
            navigateParams: (data: any) => {
                return {
                    ecran: '00-2',
                    id: data.cancleint,
                    offcleint: data.offcleint
                };
            },
            colonnesVisibles: [
                'offnumdocdsc',
                'offnumdoc',
                'offdsc',
                'candatsou',
                'ctpdscabr',
                'canflgrej',
                'canflgsel'
            ],
            colonnesCachees: [
                'offcleint',
            ],
            formulaire: new Formulaire([
                new FormulaireItem('offnumdoc'),
                new FormulaireItem('offdsc')
            ]),
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            })
        };

        vm.corMultiOccurrenceOptions = {
            navigatePage: co0010Route.NAME,
            navigateParams: (data: any) => {
                return {
                    id: data.idecleint,
                    idecleintref: data.cancleint,
                    stamnetab: 'CAN',
                    comcleint: data.comcleint,
                    stamnetabcor: 'OFF',
                    corcleintref: data.corcleintref
                };
            },
            colonnesVisibles: [
                'comdsc',
                'ideval',
                'nivdsc',
                'idecom',
                'idedat',
                'indrev',
                'tycflginfexp',
                'expflgverrh',
                'tycflginfscl',
                'sclflgverrh',
                'ideannobt',
                'ideannderutl'
            ],
            colonnesCachees: [
                'conforme',
                'cancleint',
                'offcleint',
                'eplcleint',
                'idecleint',
                'comcleint',
                'corcleintref'
            ]
        };

        vm.cvhMultiOccurrenceOptions = {
            colonnesVisibles: [
                'cvccleint',
                'cvcdsc',
                'cvclie',
                'cvhdat',
                'cvhcom'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cvccleint'),
                new FormulaireItem('cvhdat'),
                new FormulaireItem('cvhcom')
            ], {largeurDefaut: 100}),
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            })
        };

        vm.qsdMultiOccurrenceOptions = {
            colonnesVisibles: [
                'grqnom',
                'qsdnom',
                'qsrflgon',
                'qdvtxtval',
                'qsrtxtaut'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vue__qsdnom'),
                new FormulaireItem('qsrflgon', {
                    default: null
                }),
                new FormulaireItem('qdvcleint', {
                    largeur: 100,
                    nombreLibellesDetailsCacher: 1
                }),
                new FormulaireItem('qsrtxtaut', {
                    largeur: 100,
                }),
            ], {largeurDefaut: 33.33}),
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            })
        };
    }

    function getExperienceVerifiee(data: any) {
        return (data.indrev === 0 && data.expflgverrh && !data.sclflgverrh);
    }

    function getScolariteVerifiee(data: any) {
        return (data.indrev === 0 && !data.expflgverrh && data.sclflgverrh);
    }
}
