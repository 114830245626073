import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0165 extends IComponentController {
    formulaireTda: IFormulaire,
    mtmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mtlMultiOccurrenceOptions: IMultiOccurrenceOptions;        
}

/* @ngInject */
export default function Mr0165Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Pagination: IPaginationClass,
                                         mr0238Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0165 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemForage(
                    'LBL_MR0238',
                    mr0238Route.NAME,
                    (data:any) => ({vaetypfnd: 'CVT',
                                    cleintdet: data.cvtcleint}),
                    {
                        icon: 'assessment',
                        iconButton: false
                    }
                )
            ])
        };

        // type de date
        vm.formulaireTda = new Formulaire('LBL_BLC_TITRE', [
            new FormulaireItem('tdadsc'      , {largeur : 25}),
            new FormulaireItem('vaetdatyp'      , {largeur : 50}),
            new FormulaireItem('tdaflgmsg'   , {largeur : 25})
            ]
        );

        // Mise à jour
        vm.mtmMultiOccurrenceOptions = {
            bloc:'mtm',
            fonctions: {
                pagination: true,
                importation: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),

            colonnesVisibles: [
                {nom: 'vactsmrai_code', largeur: 90},
                {nom: 'vactsmrai_desc', largeur: 262},
                {nom: 'vaemtmact_desc', largeur: 105},
                {nom: 'vaemtmdat_desc', largeur: 105},
                {nom: 'vaemtmcom_desc', largeur: 131}
            ],
            formulaire: new Formulaire('LBL_BLC_MTM',[
                new FormulaireItem('vactsmrai'),
                new FormulaireItem('vaemtmact'),
                new FormulaireItem('vaemtmdat'),
                new FormulaireItem('vaemtmcom')
            ])
        };
        
        // Maj selon lien d'emploi
        vm.mtlMultiOccurrenceOptions = {
            bloc:'mtl',
            // Affichage des colonnes visibles
            colonnesVisibles: [
			    {nom: 'urgcod', largeur: 130},
                {nom: 'urgdscabr', largeur: 250}, 
                {nom: 'strcod', largeur: 120},
                {nom: 'strdsc', largeur: 250},
                {nom: 'vaemtlact_desc', largeur: 140},
                {nom: 'vaemtldat_desc', largeur: 140}
            ],    
            colonnesCachees: [
                'tdacleint'
            ],        
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint',{largeur:25,
                    resourceParams: () => {
                        return {tdacleint: vm.monoOccurrence.data.tdacleint};}}), 
                new FormulaireItem('strcleint',{largeur:25, resourceParams: () => {
                        return {tdacleint: vm.monoOccurrence.data.tdacleint};}}), 
                new FormulaireItem('vaemtlact',{largeur:25}),
                new FormulaireItem('vaemtldat',{largeur:25}),                
            ])
        };
            
    }
}
