import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageIn0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceFIPOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function In0007Controller(Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass) {
    const vm: IPageIn0007 = this;

    vm.multiOccurrenceFIPOptions = {
        bloc: 'fip',
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'forcod', largeur: 140 },
            { nom: 'fordsc', largeur: 300 },
            { nom: 'fipflgstu', largeur: 90 },
            { nom: 'fipfac', largeur: 200 }
        ],
        colonnesCachees: [
            'forcleint',
            'forcleintimp'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('forcleintimp', {
                largeur: 35,
                resourceParams: (data: any) => ({ forcleint: data.forcleint })
            }),
            new FormulaireItem('fipflgstu', { largeur: 25, default: 1 }),
            new FormulaireItem('fipfac', { largeur: 25 })
        ]),
    };

    vm.multiOccurrenceOptions = {
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'forcod', largeur: 110 },
            { nom: 'fordsc', largeur: 300 },
            { nom: 'forflgstu', largeur: 90 },
            { nom: 'unmcod', largeur: 140 },
            { nom: 'unmdsc', largeur: 200 }
        ],
        colonnesCachees: [
            'unmcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('forcod', { largeur: 20 }),
            new FormulaireItem('fordsc', { largeur: 40 }),
            new FormulaireItem('forflgstu', { largeur: 20, default: 1 }),
            new FormulaireItem('unmcleint', { largeur: 20 })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceFIPOptions
    };

}
