import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr2013 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    cecMultiOccurrenceOptions: IMultiOccurrenceOptions;
    vtaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    vafMultiOccurrenceOptions: IMultiOccurrenceOptions;
    vdaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function mr2013Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr2013 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_VCL', [
            new FormulaireItem('vclcod'),
            new FormulaireItem('evscleint', {modifAllow: false}),
            new FormulaireItem('vacvclreg'),
            new FormulaireItem('stscleint'),
            new FormulaireItem('stscleintabr'),
            new FormulaireItem('stscleintmnt')
        ], { largeurDefaut: 33.33 })
    };

    vm.cecMultiOccurrenceOptions = {
        bloc: 'cec',
        colonnesVisibles: [
            {nom: 'cyrcod', largeur: 100},
            {nom: 'cyrdsc', largeur: 180},
            {nom: 'ecldsc', largeur: 150}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cepcleint', {
                largeur: 100,
                resourceParams: () => {
                    return { evscleint: vm.monoOccurrence.data.evscleint }
                }
            })
        ])
    };

    vm.vtaMultiOccurrenceOptions = {
        bloc: 'vta',
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 100},
            {nom: 'tagdsc', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tagcleint', { largeur: 100 })
        ])
    };

    vm.vafMultiOccurrenceOptions = {
        bloc: 'vaf',
        colonnesVisibles: [
            {nom: 'tafcod', largeur: 100},
            {nom: 'tafdsc', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tafcleint', { largeur: 100 })
        ])
    };

    vm.vdaMultiOccurrenceOptions = {
        bloc: 'vda',
        colonnesVisibles: [
            {nom: 'tdlcod', largeur: 100},
            {nom: 'tdldsc', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tdlcleint', { largeur: 100 })
        ])
    };

}