import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageDO0029 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0029Controller(Menu: IMenuClass,
                                         MenuItem: IMenuItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         do0028Route:IRoute) {
    const vm: IPageDO0029 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: do0028Route.NAME,
        navigateParams: (rowData: any) => 
            (
              {  id : rowData.tbpcleint}
            ),
            actionsNouveaux: new Menu([
                new MenuItemForage('', do0028Route.NAME, () => ({}), {})
            ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tbpcod', largeur: 150},
            {nom: 'tbpdsc', largeur: 300},
            {nom: 'ttccod', largeur: 200},
            {nom: 'ttcdsc', largeur: 200},
            {nom: 'ttccodpro', largeur: 250},
            {nom: 'ttcdscpro', largeur: 300},
            {nom: 'ttccodpropas', largeur: 200},
            {nom: 'ttcdscpropas', largeur: 300},
            {nom: 'ttccodrem', largeur: 200},
            {nom: 'ttcdscrem', largeur: 300}
        ]
    };
}
