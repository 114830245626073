import { IComponentController, } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageMR0336 extends IComponentController {
    formulaireRcp: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rctMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0336Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageMR0336 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonEnregistrerHaut: false,
                afficherMenusDansEntete: true
            },
            menus: [new MenuItemAction("LBL_BTN_TST", "BOUTST", () => ({}), {
                iconButton: false,
                class: 'md-raised ex-button-action',
                lblMessageSuccess: "LBL_MSG_TST"
            })]
        };

        vm.formulaireRcp = new Formulaire('LBL_BLC_RCP', [
            new FormulaireItem('t_mntemp'),
            new FormulaireItem('t_mntemr'),
            new FormulaireItem('t_mntadm'),
            new FormulaireItem('t_qteadm'),
            new FormulaireItem('t_msg', { largeur: 100 })
        ], { largeurDefaut: 25 });

        vm.rctMultiOccurrenceOptions = {
            bloc: 'rct',
            fonctions: {
                suiviModification: false
            },
            colonnesVisibles: [
                { nom: 'rcfnumlig', largeur: 80 },
                { nom: 'rvanumval', largeur: 90 },
                { nom: 'rctdsc', largeur: 200 },
                { nom: 'tdrdscpar', largeur: 400 },
                { nom: 'rctval', largeur: 150 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rctval', { largeur: 33.33 })
            ])
        };
    }
}
