import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass, MenuElement } from '../../../../../core/services/menu/menu.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageMc0101 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    formulaireAjf: IFormulaire;
    ajvMultiOccurrence: IMultiOccurrence;
    ajvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menusEmp: { [col: string]: Array<MenuElement> };

}
interface IPageMc0101Params {
    doccleintref: number;
}
/* @ngInject */
export default function Mc0101Controller(mr0208Route: IRoute,
    rr0003Route: IRoute,
    $stateParams: IPageMc0101Params,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0101 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;


        vm.monoOccurrenceOptions = {
            infosFinancieres: {
                disponibilite: true,
                impactFinancier: true,
                impactBudgetaire: true
            },            
            menus: [
                new MenuItemAction(
                    'LBL_BTN_CTR',
                    'BOUCTR',
                    () => ({}),
                    {
                        iconButton: false,
                        icon: 'subtitles',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        },
                    }
                )
            ],
            formulaire: new Formulaire('LBL_BLC_AJF', [
                new FormulaireItem('ajfnumdoc', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tydcleint', { largeur: 33, readonly: true }),
                new FormulaireItem('doccleintref', { hidden: true, default: $stateParams.doccleintref }),
                new FormulaireItem('tydcleintref', { default: vm.valeursEcran.tydcleintref, hidden: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ajfdat', { largeur: 33 }),
                new FormulaireItem('peccleint', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ppacleint', { largeur: 33 }),
                new FormulaireItem('ajfflgpij', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('intcleintreq', { largeur: 33, longueurFixe: 6 }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('ajfdsc', { largeur: 67, enableCount: true }),
            ])
        }

        vm.menusEmp = {
            refref: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0003Route.NAME,
                    () => ({
                        id: vm.valeursEcran.trncleint
                    })
                )
            ]
        };

        vm.ajvMultiOccurrenceOptions = {
            colonnesVisibles: [
                { nom: 'ajvflgref', largeur: 72 },
                { nom: 'orgmoifin', largeur: 75 },
                { nom: 'ajvimp', largeur: 230 },
                { nom: 'ajvflgavs', largeur: 126 },
                { nom: 'ajvmntctr', largeur: 126, total: true },
                { nom: 'ajvmntajt', largeur: 126, total: true },
                { nom: 'ajvdsc', largeur: 200 },
                { nom: 'dommsg', largeur: 200 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ajvimp', { required: true }),
                new FormulaireItem('ajvflgavs'),
                new FormulaireItem('ajvmntajt', { required: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ajvdsc', { largeur: 67 })
            ], {
                largeurDefaut: 33
            }),
            colonnesCachees: [
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint'
            ]
        };
    }
}
