import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCC0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0018Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageCC0018 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'raacod', largeur: 25 },
            { nom: 'raadsc', largeur: 600 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('raacod', { largeur: 25, required: true}),
            new FormulaireItem('raadsc', { largeur: 75, required: true})
        ]),
    };
}
