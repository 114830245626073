import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageGS0069 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAve: IFormulaire;
    aeeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gtrMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0069Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    $stateParams: any,
    MenuItemAction: IMenuItemActionClass) {

    const vm: IPageGS0069 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            menus: [new MenuItemAction("LBL_BTN_ENV", "BOUENV", () => ({}), {
                iconButton: false,
                class: 'md-raised ex-button-action',
                saveFirst: true,
                lblMessageSuccess: 'LBL_MSG_ENV',
                successAction: () => vm.monoOccurrence.fetchData(),
                disabled: () => vm.monoOccurrence.data.aveflgprtenv
            })],
            fonctions: {
                afficherMenusDansEntete: true
            }
        };

        //Avis
        vm.formulaireAve = new Formulaire('LBL_BLC_AVE', [
            // cachées
            new FormulaireItem('xyzcleint', { default: $stateParams.xyzcleint }),
            new FormulaireItem('aveflgprtenv', { default: 0 }),
            // visible
            new FormulaireItem('avedatavi'),
            new FormulaireItem('aveflgpor', { default: 1 }),
            new FormulaireItem('aveflgcou', { default: 0 }),
            new FormulaireItem('aveflgdom', { default: 0 }),
            new FormulaireItem('avesuj'),
            new FormulaireItem('avetxt', { largeur: 50 }),
        ], { largeurDefaut: 25 });

        vm.aeeMultiOccurrenceOptions = {
            bloc: 'aee',
            pagination: new Pagination({
                nombreElementPage: 50,
                nbElementsPossibles: [20, 50, 100]
            }),
            fonctions: {
                editionRapide: true,
                importation: true
            },
            colonnesVisibles: [
                { nom: 'employe', largeur: 150 },
                { nom: 'eplnomprn', largeur: 250 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('demcleint', { largeur: 100 })
            ]),
            colonnesEditionRapide: [
                { nom: 'demcleint', largeur: 450 }
            ]
        };

        // vm.gtrMultiOccurrenceOptions = {
        //     bloc: 'gtr',
        //     colonnesVisibles: [
        //         //@TODO à compléter quand la transversale sera développé
        //     ]
        // };
    }
}
