import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'gl0038',
    ecrans: ['00-2'],
    params: [
        'id',
        'ecjcleint',
        'intcleintreq'
    ]
});

