import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCp0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0015Controller(Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    mc0046Route: IRoute) {
    const vm: IPageCp0015 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tyacod', largeur: 65 },
            { nom: 'tyadsc', largeur: 210 },
            { nom: 'tyaflgdef', largeur: 76 },
            { nom: 'tyaflgdat', largeur: 76 },
            { nom: 'tyaflgdest', largeur: 76 },
            { nom: 'tyaflgvoy', largeur: 76 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyacod', { largeur: 25, required: true }),
            new FormulaireItem('tyadsc', { largeur: 55, required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tyaflgdef'),
            new FormulaireItem('tyaflgdat'),
            new FormulaireItem('tyaflgdest'),
            new FormulaireItem('tyaflgvoy')
        ], { largeurDefaut: 20 }),
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => {
                    return {
                        vaeusient: 'TYA',
                        uvicleintref: data.tyacleint
                    };
                },
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
