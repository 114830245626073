import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTH0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0022Controller(Menu: IMenuClass) {
    const vm: IPageTH0022 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rghcod', largeur: 200},
            {nom: 'rghdsc', largeur: 200},
            {nom: 'horcod', largeur: 200},
            {nom: 'hordsc', largeur: 200},
            {nom: 'unccod', largeur: 200},
            {nom: 'uncdsc', largeur: 200},
            {nom: 'horflghcl', largeur: 200},
            {nom: 'horflghclmul', largeur: 200},
            {nom: 'gfrcod', largeur: 200},
            {nom: 'gfrdsc', largeur: 200},
            {nom: 'horflgman', largeur: 200},
            {nom: 'tpscod', largeur: 200},
            {nom: 'tpsdsc', largeur: 200},
            {nom: 'horflgaccanc', largeur: 200},
            {nom: 'horflgaccftp', largeur: 200}
        ]
    };
}
