import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';

interface IPageMr0053 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceMbpOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0053Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0053 = this;

    vm.multiOccurrenceMbpOptions = {
        bloc: 'mbp',
        colonnesVisibles: [
            { nom: 'mbpdsc', largeur: 60 },
            { nom: 'mbppar', largeur: 60 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mbpdsc', {largeur: 50}),
            new FormulaireItem('mbppar', {largeur: 30})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'mcbnummth', largeur: 60},
            {nom: 'vaemcbtyp_dsc', largeur: 100},
            {nom: 'mcbdsc', largeur: 100},
            {nom: 'mcbdoc', largeur: 200}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceMbpOptions,

       formulaire: new Formulaire([
            new FormulaireItem('mcbnummth', {largeur: 30}),
            new FormulaireItem('vaemcbtyp', {largeur: 30}),
            new FormulaireItem('mcbdsc', {largeur: 60}),
            new FormulaireItem('mcbdoc', {largeur: 200})
        ])
    };
}
