import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { FormulaireElement, IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForage, IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IIsForageValideService } from "../../../../../core/services/is-forage-valide.service";
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IStateService } from 'angular-ui-router';
interface IPageRr0004 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireLem: IFormulaire;
    formulaireLsa: IFormulaire;
    formulaireAft: IFormulaire;
    formulaireRem: IFormulaire;
    formulaireAss: IFormulaire;
    formulaireAssMul: IFormulaire;
    menuItemClassification: IMenuItem;
    menusTsm: { [col: string]: Array<FormulaireElement> };
    tseMenus: Array<IMenuItemForage>;
    multiOccurrenceLSMOptions: IMultiOccurrenceOptions;
    dasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovdMultiOccurrence: IMultiOccurrence;
    lsaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aftMultiOccurrenceOptions: IMultiOccurrenceOptions;
    derMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cpeMenus: Array<IMenuItemForage>;
}

/* @ngInject */
export default function Rr0004Controller(rr0002Route: IRoute,
    rr0003Route: IRoute,
    rr0005Route: IRoute,
    rr0006Route: IRoute,
    rr0022Route: IRoute,
    rr0023Route: IRoute,
    rr0024Route: IRoute,
    rr0074Route: IRoute,
    rr0075Route: IRoute,
    mr0176Route: IRoute,
    rr0071Route: IRoute,
    rr0155Route: IRoute,
    mr0208Route: IRoute,
    //$stateParams: IRr0004Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    MenuItemAction: IMenuItemActionClass,
    $state: IStateService,  
    $stateParams: any,                                   
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemEcran: IMenuItemEcranClass,
    isForageValide: IIsForageValideService) {
    const vm: IPageRr0004 = this;

    const multiOccurrenceOptionsLsm: IMultiOccurrenceOptions = {
        bloc: 'lsm',
        fonctions: {
            suiviModification: true
        },
        colonnesVisibles: [
            { nom: 'lsmdat', largeur: 100 },
            { nom: 'lsmsldbqe', largeur: 160 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'lsmmsg', largeur: 300 }
        ]
    };

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                suiviModification: false,
                refreshForageIdWithCycle: true,
                boutonDupliquer: false
            }
        };

        vm.tseMenus = [
            new MenuItemForage(
                'LBL_TSE_EMPLOYE',
                rr0003Route.NAME,
                () => ({
                    id: vm.valeursEcran.trncleint_dem
                }),
                {
                    icon: 'create',
                    iconButton: false,
                    hidden: vm.valeursEcran.tytcleint === 3 ||
                        vm.valeursEcran.tytcleint === 4 ||
                        vm.valeursEcran.tytcleint === 5 ||
                        vm.valeursEcran.tytcleint === 73
                }
            ),
            new MenuItemForage(
                'LBL_TSE_PROBATION',
                rr0155Route.NAME,
                () => ({
                    id: vm.valeursEcran.tsecleint
                }),
                {
                    icon: vm.valeursEcran.tprcleint ? 'create' : 'add_circle',
                    iconButton: false,
                    hidden: vm.valeursEcran.flggespro === 3,
                    disabled: (data: any) => !vm.valeursEcran.tseflgprb && vm.valeursEcran.flggespro != 2 || !data.lemcleint
                }
            ),
            new MenuItemForage(
                'LBL_AFFECTATION',
                rr0006Route.NAME,
                () => {
                    if (!vm.monoOccurrence.data.trncleint_aft) {
                        return {
                            trncleint: vm.monoOccurrence.data.trncleint
                        };
                    } else {
                        return { id: vm.monoOccurrence.data.trncleint_aft };
                    }
                },
                {
                    icon: vm.valeursEcran.aftcleint ? 'create' : 'add_circle',
                    iconButton: false,
                    hidden: vm.valeursEcran.trnflgstaann || vm.valeursEcran.trnflgstafer,
                    disabled: () => !vm.monoOccurrence.data.lemcleint
                }
            )
        ];


        vm.menus = {
            ttcdscnumdoc: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0002Route.NAME,
                    () => ({
                        id: $stateParams.id
                    }),
                    {
                        fonction: 'BOUTSE',
                        hidden: !vm.valeursEcran.ttcdscnumdoc
                    }
                )
            ],

            numdocref: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    `secure.${vm.valeursEcran.ecrcodref}.ecran`,
                    () => {
                        if (vm.valeursEcran.vaetseori === 'IFT') {
                            return {
                                fatcleint: vm.valeursEcran.fatcleint,
                                fdacleint: vm.valeursEcran.fdacleint
                            };
                        } else if (vm.valeursEcran.vaetseori === 'AFT') {
                            return { aftcleint: vm.valeursEcran.aftcleintfor };
                        } else {
                            return { id: vm.valeursEcran.trncleintref };
                        }
                    },
                    { hidden: (data: any) => !data.numdocref || (vm.valeursEcran.ecrcodref && !isForageValide.isForageValide('rr0006', vm.valeursEcran.ecrcod)) }
                )
            ]
        };

        vm.menusEmp = {
            employe: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0003Route.NAME,
                    () => ({
                        employe: vm.valeursEcran.demcleint
                    }),
                    { hidden: !vm.valeursEcran.employe }
                )
            ]
        };

        vm.menusAft = {
            aftdscfmt: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0006Route.NAME,
                    (data) => ({
                        id: data.trncleint_aft,
                        trncleint: data.trncleint_aft
                    }),
                    {
                        fonction: 'BOUAFT',
                        hidden: (data: any) => !data.aftdscfmt
                    }
                )
            ]
        };

        vm.cpeMenus = [
            new MenuItemForage(
                'G_LBL_IMPACT_BUD',
                mr0208Route.NAME,
                (data) => ({
                    id: vm.valeursEcran.demcleint,
                    doccleintgrh: data.doccleintlem,
                    typtri: 1
                }),
                {
                    icon: 'equalizer',
                    fonction: 'BOUIMPBUD',
                    iconButton: false,
                    secondaire: true
                }
            )
        ]

        vm.formulaireLem = new Formulaire('LBL_LIEN', [
            new FormulaireItem('demcleint', { default: vm.valeursEcran.demcleint }),
            new FormulaireItem('trncleint', { default: $stateParams.trncleint }),
            new FormulaireItem('tsecleint', { default: vm.valeursEcran.tsecleint }),
            new FormulaireItem('lemcod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lemdatdeb', {
                largeur: 33.33,
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '01-2',
                            lemcleint: data.lemcleint,
                            tytcleint: 19
                        }),
                        {
                            icon: 'create',
                            hidden: validateEtat(2, vm.valeursEcran)
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '06-2',
                            demcleint: data.demcleint,
                            cleintref: data.lemcleint,
                            tytcleint: 19
                        }),
                        {
                            icon: 'ballot',
                            hidden: validateEtat(1, vm.valeursEcran)
                        }
                    )
                ]
            }),
            new FormulaireItem('lemdatfin', {
                largeur: 33.33,
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '01-2',
                            lemcleint: data.lemcleint,
                            tytcleint: 20
                        }),
                        {
                            icon: 'create',
                            hidden: validateEtat(2, vm.valeursEcran)
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '06-2',
                            demcleint: data.demcleint,
                            cleintref: data.lemcleint,
                            tytcleint: 20
                        }),
                        {
                            icon: 'ballot',
                            hidden: validateEtat(1, vm.valeursEcran)
                        }
                    )
                ]
            }),
            new FormulaireItem('lemflgemb', {
                largeur: 33.33,
                default: 0
            }),
            new FormulaireItem('lemflgfincon', {
                largeur: 33.33,
                default: 0
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tlecleint', {
                largeur: 66.66,
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '01-2',
                            lemcleint: data.lemcleint,
                            tytcleint: 18
                        }),
                        {
                            icon: 'create',
                            fonction: "BOUMAJTLE",
                            hidden: validateEtat(2, vm.valeursEcran)
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '06-2',
                            demcleint: data.demcleint,
                            cleintref: data.lemcleint,
                            tytcleint: 18
                        }),
                        {
                            icon: 'ballot',
                            fonction: "BOUHISTLE",
                            hidden: validateEtat(1, vm.valeursEcran)
                        }
                    )
                ]
            }),
            new FormulaireItem('tleflgpri', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lemdsc', {
                largeur: 100
            }),
        ], { largeurDefaut: 50 });

        vm.menusTsm = {
            nbrtsm: [
                new MenuItemForage(
                    rr0023Route.NAME,
                    (data: any) => ({
                        ecran: '01-2',
                        demcleint: data.demcleint,
                        cleintref: data.lemcleint
                    }),
                    {
                        fonction: 'BOUTM',
                        hidden: (data: any) => !data.nbrtsm
                    }
                )
            ]
        };

        // Affectation - Lien d'emploi Officiel
        vm.aftMultiOccurrenceOptions = {
            bloc: 'aft',
            navigatePage: rr0006Route.NAME,
            navigateParams: (rowData: any) => ({
                id: rowData.trncleint_aft,
                trncleint: rowData.trncleint_aft
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage('G_LBL_BTN_MODIFIER', rr0002Route.NAME, () => ({
                    eplcleint: vm.monoOccurrence.data.eplcleint,
                    demcleint: vm.monoOccurrence.data.demcleint,
                    lemcleint: vm.monoOccurrence.data.lemcleint,
                    tytcleint: 5
                }), { fonction: 'BOUNOUV' })
            ]),

            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'aftdscfmt', largeur: 300 },
                { nom: 'aftdatdeb', largeur: 126 },
                { nom: 'aftdatfin', largeur: 126 }
            ],
            colonnesCachees: [
                'trncleint_aft'
            ]
        };

        // Classification(s) - Lien d'emploi Officiel
        vm.lsaMultiOccurrenceOptions = {
            bloc: 'lsa',
            details: { lblTitre: 'LBL_BLC_LSM', multiOccurrenceOptions: multiOccurrenceOptionsLsm },
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            navigatePage: vm.valeursEcran.nbrlsa > 1 ? rr0005Route.NAME : rr0022Route.NAME,
            navigateParams: (data) => vm.valeursEcran.nbrlsa > 1 ? { id: data.lsacleint } : { id: data.trncleint },
            colonnesVisibles: [
                { nom: 'tsadatvig', largeur: 126 },
                { nom: 'tsadsc', largeur: 300 },
                { nom: 'lsadataugformm', largeur: 160, hidden: ([4, 9, 10, 11].includes(Number(vm.valeursEcran.mchnummth))) },
                { nom: 'lsadataugforyy', largeur: 160, hidden: (![4, 9, 10, 11].includes(Number(vm.valeursEcran.mchnummth))) },
                { nom: 'lsaflgaugman', largeur: 220 }
            ],
            colonnesCachees: [
                'lsacleint',
                'trncleint'
            ],
            formulaire: new Formulaire([
                new FormulaireItemDivider('LBL_LSAPARAM'),
                new FormulaireItem('lsaflgaugman'),
                new FormulaireItem('t_datmm', {
                    default: (data: any) => data.lsadataugfor
                }),
                new FormulaireItem('t_datyy', {
                    default: (data: any) => data.lsadataugfor
                })
            ])
        };

        ///////////////////
        // Imputation - Lien
        ///////////////////
        vm.ovdMultiOccurrenceOptions = {
            resourceParams: {
                ovdcleintref: vm.valeursEcran.lemcleint,
                stamnetab: 'LEM'
            },
            actionsMore: new Menu([
                new MenuItemForage(
                    'G_LBL_IMPACT_BUD',
                    mr0208Route.NAME,
                    (data) => ({
                        id: vm.valeursEcran.demcleint,
                        doccleintgrh: data.doccleintlem,
                        typtri: 1
                    }),
                    {
                        icon: 'equalizer',
                        fonction: 'BOUBUD',
                        hidden: !vm.valeursEcran.flgimpbud,
                        iconButton: false
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('BTN_MODIFIER', mr0176Route.NAME, (rowData: any) => ({
                    id: rowData.ovdcleint,
                    ovdcleintref: vm.valeursEcran.lemcleint,
                    stamnetab: 'LEM'
                }), {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    disabled: !vm.valeursEcran.lemcleint,
                    onClose() {
                        vm.ovdMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('G_LBL_BTN_NOUVEAU', mr0176Route.NAME, () => ({
                    ovdcleintref: vm.valeursEcran.lemcleint,
                    stamnetab: 'LEM'
                }), {
                    disabled: !vm.valeursEcran.lemcleint,
                    fonction: 'BOUNOUV',
                    onClose() {
                        vm.ovdMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            bloc: 'ovd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'ovnimp', largeur: 300 },
                { nom: 'rsccoddsc', largeur: 250 },
                { nom: 'ovddatvig', largeur: 126 },
                { nom: 'ovddatfin', largeur: 126 }
            ],
            colonnesCachees: [
                'ovdcleint'
            ]
        };

        // Demande de rémunération
        vm.derMultiOccurrenceOptions = {
            bloc: 'der',
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'T'
                })
                ,
                new FormulaireItem('selection_axv', {
                    largeur: 100,
                    default: 'ALL'
                })
            ]
            ),
            actionsRangeeDroite: new Menu([
                new MenuItemForage('BTN_OUVRIR'
                    , (data: any) => { return (data.prv === "DER") ? rr0074Route.NAME : rr0075Route.NAME }
                    , (data: any) => {
                        return { id: data.trncleint };
                    }, {
                    fonction: 'BOUDRM',
                })
            ]),
            colonnesVisibles: [
                { nom: 'dernumdoc', largeur: 90 },
                { nom: 'drpnumdoc', largeur: 110 },
                { nom: 'tdmcod', largeur: 110 },
                { nom: 'ecldsceta', largeur: 80 },
                { nom: 'tagcod', largeur: 80 },
                { nom: 'tagdscabr', largeur: 150 },
                { nom: 'derdatdeb', largeur: 110 },
                { nom: 'derdatfin', largeur: 120 },
                { nom: 'derdatfinori', largeur: 120 },
                { nom: 'tdmdsc', largeur: 200 }
            ],
            colonnesCachees: ['aftcleint', 'ciecleint', 'prv', 'trncleint'],
            fonctions: {
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                editionRapide: false
            },
            actionsNouveaux: new Menu([
                new MenuItemAction(
                    'G_LBL_BTN_NOUVEAU',
                    'SIMULER_PROCEDURE',
                    () => ({}),
                    {
                        saveFirst: true,
                        fonction: 'BOUNOU',
                        successAction: (data: any) => {
                            $state.go(rr0074Route.NAME, {
                                menuId: $stateParams.menuId,
                                tdmcleint: data.tdmcleint,
                                t_employe: $stateParams.employe
                            });
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_DER_DEMANDE_DER',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 50,
                            formulaire: new Formulaire([
                                new FormulaireItem('tdmcleint', {
                                    required: true, largeur: 100
                                })
                            ], {
                                securityCycle: false
                            })
                        }
                    }
                )
            ]),
        }; 

        // Choix avantages sociaux - Lien
        vm.dasMultiOccurrenceOptions = {
            // Ouverture en modale
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0071Route.NAME, (data: any) => {
                    return {
                        id: data.dascleint
                    };
                }, {
                    icon: 'filter_none',
                    appliquer: true,
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.dasMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0071Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint,
                        lemcleint: vm.monoOccurrence.data.lemcleint,
                        trncleint: vm.monoOccurrence.data.trncleint
                    };
                }, {
                    appliquer: true,
                    fonction: 'BOUNOUV',
                    onClose() {
                        vm.dasMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            bloc: 'das',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'avsdsc', largeur: 300 },
                { nom: 'dasdatvig', largeur: 131 },
                { nom: 'avcdsc', largeur: 250 }
            ]
        };
    }
    /*
    on valida l'etat d'accord à la logique du SIGA-43624
                typeIcon:1 historique
                typeIcon:2 trx modification
    Flag qui s'utilise=> trnflgstaann: annulé, trnflgstafer: fermé, trnflgstafin: final, trnflgstaref: référable
    */
    function validateEtat(typeIcon: number, valeursEcran: any) {
        //référable
        if (valeursEcran.trnflgstaref) {
            return false;
        }
        else {
            if (typeIcon === 1 && valeursEcran.trnflgstafer){
                return false;
            }
            else{
                return true;
            }
        }
    }
}
