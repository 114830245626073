import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRr0066 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    socMultiOccurrence: IMultiOccurrence;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0066Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService) {
    const vm: IPageRr0066 = this;
    const criteresSuggeresVisibles = !$stateParams.employe;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles,
            rechercheCollapsable: false,
            edition:false,
            supprime:false
        },

        criteresSuggeres: new Formulaire([
            new FormulaireItem('ppacleint', {largeur: 33.33}),
            new FormulaireItem('peccleint', {largeur: 33.33})
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 110, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 275, hidden: $stateParams.employe},
            {nom: 'carcod', largeur: 150},
            {nom: 'cardsc', largeur: 275},
            {nom: 'socmnt', largeur: 150, total:true}
        ],
        colonnesCachees: [
            'carcleint',
            'demcleint'
        ]
    };

     vm.detMultiOccurrenceOptions = {
       bloc: 'det',
       fonctions:{
        edition:false,
        supprime:false,
        nouveau:false
       },
       colonnesFixesGauche: [
           {nom: 'socdattrs'       , largeur: 100},
           {nom: 'socnumref'       , largeur: 120}
       ],
       colonnesVisibles: [
           {nom: 'vaesoctyptrs_dsc', largeur: 160},
           {nom: 'socmnt'          , largeur: 110},
           {nom: 'ppacod'          , largeur: 100},
           {nom: 'peccod'          , largeur: 100},
           {nom: 'dalnumdoc'       , largeur: 120},
           {nom: 'vaesysmnetab_dsc', largeur: 160},
           {nom: 'soccom'          , largeur: 250},
           {nom: 'pamnumdoc'       , largeur: 120},
           {nom: 'pamnumdocseq'    , largeur: 150}
     ]
    };


}
