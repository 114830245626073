import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr2002',
    ecrans: ['00-2'],
    params: ['id',
             '?ppacleint',
             '?ppacod',
             '?vaedtitypvnt',
             '?typvnt_dsc',
             '?imp',
             '?dtidatfineng',
             '?prucleint',
             '?prscleint',
              '?cifcleint',
              '?unacleint',
              '?cptcleint',
              '?el1cleint',
              '?el2cleint',
              '?el3cleint'
            ]
});

