import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0023Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0023 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable:false
        },
        colonnesVisibles: [
            {nom: 'gafcod', largeur: 160},
            {nom: 'gafdsc', largeur: 320},
            {nom: 't_cod_par', largeur: 180},
            {nom: 't_dsc_par', largeur: 280},
            {nom: 'gafflginfsclobl', largeur: 140},
            {nom: 'gafflgcvtfac', largeur: 140},
            {nom: 'gafseqaff', largeur: 115}
        ],
        colonnesCachees:['gafcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('gafcod', {required: true}),
            new FormulaireItem('gafdsc', {required: true}),
            new FormulaireItem('gafcleintref',{
                resourceParams: (data: any) => ({
                    gafcleint: data.gafcleint
                })
            }),
            new FormulaireItem('gafflginfsclobl'),
            new FormulaireItem('gafflgcvtfac', {required: true}),
            new FormulaireItem('gafseqaff')
        ])
    };
}
