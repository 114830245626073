import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFilterDate } from 'angular';

interface IPageMc0070 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0070Controller(
    Formulaire: IFormulaireClass,
    dateFilter: IFilterDate,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMc0070 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 100 },
            { nom: 'tyddsc', largeur: 150 },
            { nom: 'tmsdatdeb', largeur: 100 },
            { nom: 'tmsdatfin', largeur: 100 },
            { nom: 'tmsmsg', largeur: 500 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tydcleint', { modifAllow: false, largeur: 50 }),
            new FormulaireItem('tmsdatdeb', { modifAllow: false, default: dateFilter(new Date(), 'yyyy-MM-dd'), largeur: 25 }),
            new FormulaireItem('tmsdatfin', { largeur: 25 }),
            new FormulaireItem('tmsmsg', { largeur: 100 })
        ])
    };
}
