import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0025 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireInt: IFormulaire;
    iapMultiOccurrence: IMultiOccurrence;
    iapMultiOccurrenceOptions: IMultiOccurrenceOptions;
    hieMultiOccurrence: IMultiOccurrence;
    hieMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0025Controller(Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    mc2008Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass) {

    const vm: IPageMc0025 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false  // L'exportation Excel est faite dans MC0174
            }
        };

        vm.formulaireInt = new Formulaire('LBL_ENT_REQ', [
            new FormulaireTemplateSlot('requerant', { largeur: 50 }),
            new FormulaireItem('intflggesmanapp')
        ]);

        // utilitaire
        vm.boutonUtilitaire = new MenuItemAction(
            'LBL_BTN_UTILITAIRE',
            'BOUUTL',
            () => ({
            }),
            {
                fonction: 'BTN_UTIL',
                icon: 'sync',
                iconButton: false,
                saveFirst: true,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_FORMULAIRE_UTL',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 50,
                    formulaire: new Formulaire([
                        new FormulaireItem('vaesysutlhie'),

                        new FormulaireItemSautDeLigne(),

                        // On affiche et on cache les champs selon la fonction demandée
                        // Voir le get comportement dans mc0025.source.js

                        // Premier étage
                        new FormulaireItem('intcleint_aajo_ori'),
                        new FormulaireItem('intcleint_arem_rem'),
                        new FormulaireItem('intcleint_aret_ret'),
                        new FormulaireItem('intcleint_creq_ori'),
                        new FormulaireItem('intcleint_eajo_ori'),
                        new FormulaireItem('intcleint_erem_rem'),
                        new FormulaireItem('intcleint_eret_ret'),
                        new FormulaireItem('t_usager'),

                        new FormulaireItemSautDeLigne(),

                        // 2e étage
                        new FormulaireItem('intcleint_aajo_cre'),
                        new FormulaireItem('intcleint_arem_nou'),
                        new FormulaireItem('intcleint_creq_cre'),
                        new FormulaireItem('intcleint_eajo_cre'),
                        new FormulaireItem('intcleint_erem_nou'),
                        new FormulaireItem('t_redcleint'),

                        new FormulaireItemSautDeLigne(),

                        // 3e étage
                        new FormulaireItem('t_type'),
                        new FormulaireItem('t_intcleintapp'),

                        new FormulaireItemSautDeLigne(),

                        // 4e étage
                        new FormulaireItem('t_mntreq', { largeur: 30 }),
                        new FormulaireItem('t_mntapp', { largeur: 30 }),
                        new FormulaireItem('t_mntmin', { largeur: 30 })

                    ], { largeurDefaut: 40 })
                }
            }
        );

        // Bloc IAP  Approbateur
        vm.iapMultiOccurrenceOptions = {
            bloc: 'iap',
            colonnesVisibles: [
                { nom: 'reddsc', largeur: 250 },
                { nom: 'vaeiaptyp_dsc', largeur: 150 },
                { nom: 'intcodapr', largeur: 150 },
                { nom: 'intnomapr', largeur: 200 },
                { nom: 'iapmntreq', largeur: 130 },
                { nom: 'iapmntapr', largeur: 130 },
                { nom: 'iapmntmin', largeur: 130 },
                { nom: 'iapdatdeb', largeur: 130 },
                { nom: 'iapdatfin', largeur: 130 },
                { nom: 'iapflgexccou', largeur: 130 },
            ],
            colonnesCachees: [
                'intcleint',
                'vaeredtyp',
                'iapflgret'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('redcleint', { largeur: 33 }),
                new FormulaireItem('vaeiaptyp', { largeur: 33 }),
                new FormulaireItem('intcleintapr', {
                    largeur: 33,
                    resourceParams: (rowData: any) => ({ redcleint: rowData.redcleint })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('iapmntreq', { largeur: 33 }),
                new FormulaireItem('iapmntapr', { largeur: 33 }),
                new FormulaireItem('iapmntmin', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('iapdatdeb', { largeur: 33 }),
                new FormulaireItem('iapdatfin', { largeur: 33 }),
                new FormulaireItem('intcleint', { largeur: 0, default: () => vm.monoOccurrence.data.intcleint }),
                new FormulaireItem('iapflgexccou'),
            ], {
                largeurDefaut: 30
            }),
            fonctions: {
                importation: true
            }
        };

        // Bloc INE  Équivalent
        vm.ineMultiOccurrenceOptions = {
            bloc: 'ine',
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_FORAGE_INH',
                    mc2008Route.NAME,
                    (data: any) => {
                        return {
                            id: data.inecleint
                        };
                    },
                    {
                        icon: 'launch',
                        iconButton: false
                    }
                )
            ]),
            colonnesVisibles: [
                { nom: 'intcodequ', largeur: 120 },
                { nom: 'intnomequ', largeur: 250 },
                { nom: 'inedatdeb', largeur: 130 },
                { nom: 'inedatfin', largeur: 130 },
                { nom: 'ineflgexccou', largeur: 130 },
            ],
            colonnesCachees: [
                'intcleint',
                'inecleint',
                'intcleintequ'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('intcleintequ', { largeur: 60 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('inedatdeb', { largeur: 30 }),
                new FormulaireItem('inedatfin', { largeur: 30 }),
                new FormulaireItem('intcleint', { largeur: 0, default: () => vm.monoOccurrence.data.intcleint }),
                new FormulaireItem('ineflgexccou'),
            ], {
                largeurDefaut: 30
            }),
            fonctions: {
                importation: true
            }
        };

        // Bloc hie   Affichage hiérarchique
        vm.hieMultiOccurrenceOptions = {
            bris: 'intcleint',
            brisNiveaux: 'ahaniv',
            bloc: 'hie',
            fonctions: {
                suiviModification: false
            },
            colonnesVisibles: ['ahadsc'],
            colonnesCachees: ['redcleint', 'ahaniv'],
            actionsMore: new Menu([
                new MenuItemAction('LBL_AFF_HIE', 'BOUHIE', (data: any) => ({ intcleint: data.intcleint }), {
                    icon: 'autorenew',
                    successAction: () => {
                        vm.hieMultiOccurrence.fetchDataList();
                    }
                })
            ])
        };
    }
}
