import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageDO0045 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0045Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDO0045 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([ 
            new FormulaireItem('critrnflgstaann', {
                largeur: 25
            }),       
            new FormulaireItem('critrnflgstafer', {
                  largeur: 25
            }),          
            new FormulaireItem('crityp', {
                largeur: 50,
                default: 'DPTC'
            })       
        ]),
        colonnesFixesGauche: [
            { nom: 'unocodrh', largeur: 150 },
            {nom: 'unodscabrrh', largeur: 250}
        ],
        colonnesVisibles: [           
            {nom: 'posnumdoc', largeur: 110},
            {nom: 'postitabr', largeur: 236},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'dptnumdoc', largeur: 100},
            {nom: 'dptdatvig', largeur: 100},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'temcod', largeur: 130},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'geccod', largeur: 150},
            {nom: 'gecdsc', largeur: 236},
            {nom: 'posnumdocsup', largeur: 100},
            {nom: 'postitabrsup', largeur: 210},
            {nom: 'unocodvac', largeur: 150},
            {nom: 'unodscabrvac', largeur: 216},
            {nom: 'urgcod', largeur: 110},
            {nom: 'urgdscabr', largeur: 210},
            {nom: 'dptinfhor', largeur: 300},
            {nom: 'dptinfpri', largeur: 300},
            {nom: 'dptcom', largeur: 300},
            {nom: 'vaedpttyp', largeur: 200},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
