import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0192 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0192Controller(mr0193Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0192 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0193Route.NAME,
        navigateParams: (rowData: any) => ({ chscleint: rowData.chscleint }),
        actionsNouveaux: new Menu([new MenuItemForage(mr0193Route.NAME)]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdscabr', largeur: 220 },
            { nom: 'strcod', largeur: 90 },
            { nom: 'strdsc', largeur: 220 },
            { nom: 'mchnummth', largeur: 95 },
            { nom: 'mchdsc', largeur: 315 },
            { nom: 'bqecodaug', largeur: 120 },
            { nom: 'bqedscabraug', largeur: 220 },
            { nom: 'chssldbqeaug', largeur: 120 },
            { nom: 'unccodaug', largeur: 100 },
            { nom: 'chsnbrperdecaug', largeur: 100 },
            { nom: 'chsnbrmoi', largeur: 100 },
            { nom: 'chsnbrechexc2x', largeur: 160 },
            { nom: 'ttccodaug', largeur: 120 },
            { nom: 'ttcdscaug', largeur: 220 },
            { nom: 'ttccodech', largeur: 120 },
            { nom: 'ttcdscech', largeur: 250 }
        ],
        colonnesCachees: ['chscleint']
    };
}
