import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';


interface IPageDb0025 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    prjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prjMultiOccurrence: IMultiOccurrence;
    pdfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pdfMultiOccurrence: IMultiOccurrence;
    pruMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pruMultiOccurrence: IMultiOccurrence;
    refMultiOccurrenceOptions: IMultiOccurrenceOptions;
    refMultiOccurrence: IMultiOccurrence;
    prsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prsMultiOccurrence: IMultiOccurrence;
    bloMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bloMultiOccurrence: IMultiOccurrence;
    cptMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cptMultiOccurrence: IMultiOccurrence
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Db0025Controller(Formulaire: IFormulaireClass, 
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass ) {
    const vm: IPageDb0025 = this;
    const [paddingLeft] = [6];

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    const criteresSuggeres = new Formulaire([
                new FormulaireItem('peccleintdebcri', { largeur: 12, required: true }),
                new FormulaireItem('peccleintfincri', { largeur: 13, required: true }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('statutcri', { largeur: 25 }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('typbdgcri', { largeur: 25}),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('prucleint', { largeur: 25, lovSelectionMultiple: true }),   
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('cptcleint', { largeur: 25, lovSelectionMultiple: true }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('el1cleint', { largeur: 25, lovSelectionMultiple: true }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('prscleintcri', { largeur: 25 }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('unacleint', { largeur: 25, lovSelectionMultiple: true }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('cifcleint', { largeur: 25, lovSelectionMultiple: true }),

                new FormulaireItem('el2cleintcri', { hidden: () => (vm.valeursEcran.flgaffel2 === 0) }),

                new FormulaireItem('prjcleintcri', { largeur: 25}),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('orgcleint', { largeur: 25, lovSelectionMultiple: true }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('orpcleint', { largeur: 25, lovSelectionMultiple: true }),

                new FormulaireItem('vectypapp', { largeur: 25 }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('intcleint', { largeur: 25, lovSelectionMultiple: true }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('grpcleint', { largeur: 25, lovSelectionMultiple: true }),

                new FormulaireItem('blocleint', { largeur: 25, lovSelectionMultiple: true }),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('typoctcri', { largeur: 25}),
                new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                new FormulaireItem('orgacccri', { largeur: 25})
    ], { largeurDefaut: 33.33 });

    vm.multiOccurrenceOptions = {
        isTab: true,
        autoFetch: false,
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true
        },
        criteresSuggeres: criteresSuggeres,
        colonnesVisibles: [
            { nom: 'peccleintdebcri', largeur: 16 },
            { nom: 'peccleintfincri', largeur: 17 },
            { nom: 'statutcri', largeur: 17 },
            { nom: 'typbdgcri', largeur: 17 },
            { nom: 'prucleint', largeur: 17 },
            { nom: 'cptcleint', largeur: 17 },
            { nom: 'el1cleint', largeur: 17 },
            { nom: 'prscleintcri', largeur: 17 },
            { nom: 'unacleint', largeur: 17 },
            { nom: 'cifcleint', largeur: 17 },
            { nom: 'el2cleintcri', largeur: 17 },
            { nom: 'prjcleintcri', largeur: 17 },
            { nom: 'orgcleint', largeur: 17 },
            { nom: 'orpcleint', largeur: 17 },
            { nom: 'vectypapp', largeur: 17 },
            { nom: 'intcleint', largeur: 17 },
            { nom: 'grpcleint', largeur: 17 },
            { nom: 'blocleint', largeur: 17 },
            { nom: 'typoctcri', largeur: 17 },
            { nom: 'orgcleint', largeur: 17 }
        ]
    }

    vm.prjMultiOccurrenceOptions = {
        isTab: true,
        tabSelected: true,
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200, 300]
        },
        colonnesFixesGauche: [
            {nom: 'prjcod', largeur: 100},
            {nom: 'prjdscabr', largeur: 250}
        ],
        colonnesVisibles: [
            {nom: 'solde_deb_rev', largeur: 130},
            {nom: 'mnt_bdg_rev', largeur: 130},
            {nom: 'mnt_eng_rev', largeur: 130},
            {nom: 'mnt_reel_rev', largeur: 130},
            {nom: 'solde_fin_rev', largeur: 130},
            {nom: 'solde_deb_dep', largeur: 130},
            {nom: 'mnt_bdg_dep', largeur: 130},
            {nom: 'mnt_eng_dep', largeur: 130},
            {nom: 'mnt_reel_dep', largeur: 130},
            {nom: 'solde_fin_dep', largeur: 130}
        ]
    };

    vm.pdfMultiOccurrenceOptions = {
        isTab: true,
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200, 300]
        },
        colonnesFixesGauche: [
            {nom: 'prjcod', largeur: 100},
            {nom: 'prjdscabr', largeur: 200},
            {nom: 'el1cod', largeur: 130}
        ],
        colonnesVisibles: [
            {nom: 'solde_deb_rev', largeur: 130},
            {nom: 'mnt_bdg_rev', largeur: 130},
            {nom: 'mnt_eng_rev', largeur: 130},
            {nom: 'mnt_reel_rev', largeur: 130},
            {nom: 'solde_fin_rev', largeur: 130},
            {nom: 'solde_deb_dep', largeur: 130},
            {nom: 'mnt_bdg_dep', largeur: 130},
            {nom: 'mnt_eng_dep', largeur: 130},
            {nom: 'mnt_reel_dep', largeur: 130},
            {nom: 'solde_fin_dep', largeur: 130},
            {nom: 'orgcod', largeur: 120},
            {nom: 'orgnomabr', largeur: 180},
            {nom: 'orgflgsiracr', largeur: 120},
            {nom: 'orpcod', largeur: 100},
            {nom: 'orpdscabr', largeur: 180},
            {nom: 'sfucod', largeur: 100},
            {nom: 'sfudsc', largeur: 180}
        ]
    };

    vm.pruMultiOccurrenceOptions = {
        isTab: true,
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200, 300]
        },
        colonnesFixesGauche: [
            {nom: 'prjcod', largeur: 100},
            {nom: 'prjdscabr', largeur: 200},
            {nom: 'el1cod', largeur: 130},
            {nom: 'prucod', largeur: 130}
        ],
        colonnesVisibles: [
            {nom: 'solde_deb_rev', largeur: 130},
            {nom: 'mnt_bdg_rev', largeur: 130},
            {nom: 'mnt_eng_rev', largeur: 130},
            {nom: 'mnt_reel_rev', largeur: 130},
            {nom: 'solde_fin_rev', largeur: 130},
            {nom: 'solde_deb_dep', largeur: 130},
            {nom: 'mnt_bdg_dep', largeur: 130},
            {nom: 'mnt_eng_dep', largeur: 130},
            {nom: 'mnt_reel_dep', largeur: 130},
            {nom: 'solde_fin_dep', largeur: 130},
            {nom: 'orgcod', largeur: 120},
            {nom: 'orgnomabr', largeur: 180},
            {nom: 'orgflgsiracr', largeur: 120},
            {nom: 'orpcod', largeur: 100},
            {nom: 'orpdscabr', largeur: 180},
            {nom: 'sfucod', largeur: 100},
            {nom: 'sfudsc', largeur: 180},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 180},
            {nom: 'prudscl01', largeur: 250},
            {nom: 'unacodmanbdg', largeur: 120},
            {nom: 'unadscmanbdg', largeur: 180},
            {nom: 'unacodsup', largeur: 120},
            {nom: 'unadscsup', largeur: 180}
        ]
    };

    vm.refMultiOccurrenceOptions = {
        isTab: true,
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200, 300]
        },
        colonnesFixesGauche: [
            {nom: 'prjcod', largeur: 100},
            {nom: 'prjdscabr', largeur: 200},
            {nom: 'el1cod', largeur: 130},
            {nom: 'prucod', largeur: 130},
            {nom: 'refcod', largeur: 130},
        ],
        colonnesVisibles: [
            {nom: 'solde_deb_rev', largeur: 130},
            {nom: 'mnt_bdg_rev', largeur: 130},
            {nom: 'mnt_eng_rev', largeur: 130},
            {nom: 'mnt_reel_rev', largeur: 130},
            {nom: 'solde_fin_rev', largeur: 130},
            {nom: 'solde_deb_dep', largeur: 130},
            {nom: 'mnt_bdg_dep', largeur: 130},
            {nom: 'mnt_eng_dep', largeur: 130},
            {nom: 'mnt_reel_dep', largeur: 130},
            {nom: 'solde_fin_dep', largeur: 130},
            {nom: 'orgcod', largeur: 120},
            {nom: 'orgnomabr', largeur: 180},
            {nom: 'orgflgsiracr', largeur: 120},
            {nom: 'orpcod', largeur: 100},
            {nom: 'orpdscabr', largeur: 180},
            {nom: 'sfucod', largeur: 100},
            {nom: 'sfudsc', largeur: 180},
            {nom: 'refnomabr', largeur: 200},
            {nom: 'indapp', largeur: 100},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 180},
            {nom: 'prudscl01', largeur: 250},
            {nom: 'unacodmanbdg', largeur: 120},
            {nom: 'unadscmanbdg', largeur: 180},
            {nom: 'unacodsup', largeur: 120},
            {nom: 'unadscsup', largeur: 180}
        ]
    };

    vm.prsMultiOccurrenceOptions = {
        isTab: true,
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200, 300]
        },
        colonnesFixesGauche: [
            {nom: 'prjcod', largeur: 100},
            {nom: 'prjdscabr', largeur: 200},
            {nom: 'el1cod', largeur: 130},
            {nom: 'prucod', largeur: 130},
            {nom: 'prscod', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'solde_deb_rev', largeur: 130},
            {nom: 'mnt_bdg_rev', largeur: 130},
            {nom: 'mnt_eng_rev', largeur: 130},
            {nom: 'mnt_reel_rev', largeur: 130},
            {nom: 'solde_fin_rev', largeur: 130},
            {nom: 'solde_deb_dep', largeur: 130},
            {nom: 'mnt_bdg_dep', largeur: 130},
            {nom: 'mnt_eng_dep', largeur: 130},
            {nom: 'mnt_reel_dep', largeur: 130},
            {nom: 'solde_fin_dep', largeur: 130},
            {nom: 'orgcod', largeur: 120},
            {nom: 'orgnomabr', largeur: 180},
            {nom: 'orgflgsiracr', largeur: 120},
            {nom: 'orpcod', largeur: 100},
            {nom: 'orpdscabr', largeur: 180},
            {nom: 'sfucod', largeur: 100},
            {nom: 'sfudsc', largeur: 180},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 180},
            {nom: 'prudscl01', largeur: 250},
            {nom: 'unacodmanbdg', largeur: 120},
            {nom: 'unadscmanbdg', largeur: 180},
            {nom: 'unacodsup', largeur: 120},
            {nom: 'unadscsup', largeur: 180},
            {nom: 'prsdscl01', largeur: 180}
        ]
    };

    vm.bloMultiOccurrenceOptions = {
        isTab: true,
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200, 300]
        },
        colonnesFixesGauche: [
            {nom: 'prjcod', largeur: 100},
            {nom: 'prjdscabr', largeur: 200},
            {nom: 'el1cod', largeur: 130},
            {nom: 'prucod', largeur: 130},
            {nom: 'prscod', largeur: 100},
            {nom: 'blocod', largeur: 120}
        ],
        colonnesVisibles: [
            {nom: 'solde_deb_rev', largeur: 130},
            {nom: 'mnt_bdg_rev', largeur: 130},
            {nom: 'mnt_eng_rev', largeur: 130},
            {nom: 'mnt_reel_rev', largeur: 130},
            {nom: 'solde_fin_rev', largeur: 130},
            {nom: 'solde_deb_dep', largeur: 130},
            {nom: 'mnt_bdg_dep', largeur: 130},
            {nom: 'mnt_eng_dep', largeur: 130},
            {nom: 'mnt_reel_dep', largeur: 130},
            {nom: 'solde_fin_dep', largeur: 130},
            {nom: 'orgcod', largeur: 120},
            {nom: 'orgnomabr', largeur: 180},
            {nom: 'orgflgsiracr', largeur: 120},
            {nom: 'orpcod', largeur: 100},
            {nom: 'orpdscabr', largeur: 180},
            {nom: 'sfucod', largeur: 100},
            {nom: 'sfudsc', largeur: 180},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 180},
            {nom: 'prudscl01', largeur: 250},
            {nom: 'unacodmanbdg', largeur: 120},
            {nom: 'unadscmanbdg', largeur: 180},
            {nom: 'unacodsup', largeur: 120},
            {nom: 'unadscsup', largeur: 180},
            {nom: 'prsdscl01', largeur: 180},
            {nom: 'blodscabr', largeur: 180}
        ]
    };

    vm.cptMultiOccurrenceOptions = {
        isTab: true,
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200, 300]
        },
        colonnesFixesGauche: [
            {nom: 'prjcod', largeur: 100},
            {nom: 'prjdscabr', largeur: 200},
            {nom: 'el1cod', largeur: 130},
            {nom: 'prucod', largeur: 130},
            {nom: 'prscod', largeur: 100},
            {nom: 'blocod', largeur: 120},
            {nom: 'cptcod', largeur: 120}
        ],
        colonnesVisibles: [
            {nom: 'solde_deb_rev', largeur: 130},
            {nom: 'mnt_bdg_rev', largeur: 130},
            {nom: 'mnt_eng_rev', largeur: 130},
            {nom: 'mnt_reel_rev', largeur: 130},
            {nom: 'solde_fin_rev', largeur: 130},
            {nom: 'solde_deb_dep', largeur: 130},
            {nom: 'mnt_bdg_dep', largeur: 130},
            {nom: 'mnt_eng_dep', largeur: 130},
            {nom: 'mnt_reel_dep', largeur: 130},
            {nom: 'solde_fin_dep', largeur: 130},
            {nom: 'orgcod', largeur: 120},
            {nom: 'orgnomabr', largeur: 180},
            {nom: 'orgflgsiracr', largeur: 120},
            {nom: 'orpcod', largeur: 100},
            {nom: 'orpdscabr', largeur: 180},
            {nom: 'sfucod', largeur: 100},
            {nom: 'sfudsc', largeur: 180},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 180},
            {nom: 'prudscl01', largeur: 250},
            {nom: 'unacodmanbdg', largeur: 120},
            {nom: 'unadscmanbdg', largeur: 180},
            {nom: 'unacodsup', largeur: 120},
            {nom: 'unadscsup', largeur: 180},
            {nom: 'prsdscl01', largeur: 180},
            {nom: 'blodscabr', largeur: 180},
            {nom: 'cptdscabr', largeur: 180}
        ]
    };
}
