import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageMC0155 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0155Controller(mc0156Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMC0155 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0156Route.NAME,
        // navigateParams: (rowData: any) => {
        //     return {
        //         id: rowData.gcicleint,
        //         gcicleint: rowData.gcicleint
        //     };
        // },
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0156Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'gcicod', largeur: 90},
            {nom: 'gciseq', largeur: 65},
            {nom: 'gcinom', largeur: 236},
            {nom: 'gcinomabr', largeur: 197},
            {nom: 'ciecod', largeur: 105},
            {nom: 'cienom', largeur: 236},
            {nom: 'gcidatdeb', largeur: 100},
            {nom: 'gcidatfin', largeur: 100},
        ]
    };
}
