import {IComposantDialog} from '../../../../core/components/ex-dialog/ex-dialog.controller';
import {ISchemas} from '../../../../core/interfaces/schemas.interface';
import { ITexteDataTypeClass } from '../../../../core/services/data-types/texte-data-type.service';
import { IDateDataTypeClass } from '../../../../core/services/data-types/date-data-type.service';
import { ILovDataTypeClass } from '../../../../core/services/data-types/lov-data-type.service';

interface IDialogDonneesEnregistrement extends IComposantDialog {
    schema: ISchemas;
    dataTypes: any;
    getCols(): Array<string>;
    getDataTypes(): any;
}

/* @ngInject */
export default function DialogDonneesEnregistrementController(LovDataType: ILovDataTypeClass,
                                                              DateDataType: IDateDataTypeClass,
                                                              TexteDataType: ITexteDataTypeClass) {
    const vm: IDialogDonneesEnregistrement = this;

    vm.getCols = getCols;
    vm.getDataTypes = getDataTypes;

    function getCols(): Array<string> {
        return Object.keys(vm.schema).sort();
    }

    function getDataTypes() {
        // Permet de remplacer les cleint par des NumberDataType pour enlever les séparateurs de milliers
        const colsDataType = Object.keys(vm.dataTypes);
        
        for (const colDataType of colsDataType) {
            if (!(vm.dataTypes[colDataType] instanceof LovDataType) && !(vm.dataTypes[colDataType] instanceof DateDataType) && vm.dataTypes[colDataType].params.separateurMilliers === undefined) {
                vm.dataTypes[colDataType] = new TexteDataType(vm.dataTypes[colDataType]);
            }
        }

        for (const schemaItem of Object.keys(vm.schema)) {
            if (!vm.dataTypes[schemaItem]) {
                if (vm.schema[schemaItem].type === "date") {
                    vm.dataTypes[schemaItem] = new DateDataType({});
                } else {
                    vm.dataTypes[schemaItem] = new TexteDataType({});
                }
            }
        }

        return vm.dataTypes;
    }
}
