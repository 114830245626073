import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMr0155{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0155Controller(mr0156Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass) {
    const vm: IPageMr0155 = this;

    vm.multiOccurrenceOptions = {
        resourceParams: {
            ecrcleint: ecranDetails.ecrcleint
        },
        navigatePage: mr0156Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0156Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 90},
            {nom: 'tagdsc', largeur: 262},
            {nom: 'tagdscabr', largeur: 262},
            {nom: 'tagdatdeb', largeur: 100},
            {nom: 'tagdatfin', largeur: 100},
            {nom: 'tagcod_ref', largeur: 105},
            {nom: 'tagdscabr_ref', largeur: 262},
            {nom: 'mtgnummth', largeur: 90},
            {nom: 'mtgdsc', largeur: 262},
            {nom: 'tagindimp', largeur: 90},
            {nom: 'vacvalcle', largeur: 100},
            {nom: 'vacdsc', largeur: 262},
            {nom: 'rcecod', largeur: 90},
            {nom: 'rcedscabr', largeur: 262},
            {nom: 'vaesyscodprv_dsc', largeur: 200}
        ]
    };
}
