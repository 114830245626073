import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageMc0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mc0004Controller(mc0160Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    $stateParams: IStateParamsService,
    downloadManager: IDownloadManagerService,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0004 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        navigatePage: mc0160Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(
                mc0160Route.NAME,
                () => ({}),
                {
                    fonction: 'BOUNOUV'
                })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/mc0004/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),                    
        colonnesVisibles: [
            { nom: 'cptcod', largeur: 120 },
            { nom: 'cptdsc', largeur: 230 },
            { nom: 'cptdscabr', largeur: 230 },
            { nom: 'vaesysnatctb_dsc', largeur: 80 },
            { nom: 'cptdatact', largeur: 120 },
            { nom: 'cptdatina', largeur: 120 },
            { nom: 'remcod', largeur: 120 },
            { nom: 'vaecpttypcpt_dsc', largeur: 100 },
            { nom: 'cptflgdet', largeur: 100 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'vaesysdevcod_dsc', largeur: 184 },
            { nom: 'cptcod_lie', largeur: 120 },
            { nom: 'cptdsc_lie', largeur: 184 },
            { nom: 'cptflgdis', largeur: 120 },
            { nom: 'soncod', largeur: 120 },
            { nom: 'sondsc', largeur: 184 },
            { nom: 'unmcod', largeur: 140 },
            { nom: 'unmdsc', largeur: 220 },
            { nom: 'cptflgits', largeur: 100 },
            { nom: 'cptflggeb', largeur: 100 },
            { nom: 'cptflgt4a', largeur: 100 },
            { nom: 'cod01', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff01 },
            { nom: 'cod02', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff02 },
            { nom: 'cod03', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff03 },
            { nom: 'cod04', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff04 },
            { nom: 'cod05', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff05 },
            { nom: 'cod06', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff06 },
            { nom: 'cod07', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff07 },
            { nom: 'cod08', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff08 },
            { nom: 'cod09', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff09 },
            { nom: 'cod10', largeur: 100, hidden: !vm.valeursEcran.rgaflgaff10 }
        ]
    };
}
