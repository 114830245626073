import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0216 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0216Controller(mr0217Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass) {
    const vm: IPageMR0216 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0217Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0217Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'stacod', largeur: 90 },
            { nom: 'stadsc', largeur: 262 },
            { nom: 'apasujavi', largeur: 262 },
            { nom: 'apadsc', largeur: 262 },
            { nom: 'pfcdsc', largeur: 184 },
            { nom: 'pfccom', largeur: 315 },
            { nom: 'apasqlini', largeur: 262 },
            { nom: 'apasqlcon', largeur: 262 },
            { nom: 'apaparcon1', largeur: 158 },
            { nom: 'apaparcon2', largeur: 158 },
            { nom: 'apatxt', largeur: 390 },
            { nom: 'apadelavi', largeur: 72 }
        ]
    };
}
