import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0079 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0079Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAc0079 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tnecod', largeur: 50 },
            { nom: 'tnedsc', largeur: 187 },
            { nom: 'tneflgact', largeur: 70 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tnecod', { required: true }),
            new FormulaireItem('tnedsc', { required: true }),
            new FormulaireItem('tneflgact', { default: 1 })
        ])
    };
}
