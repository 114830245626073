import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPageRe0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re0014Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe0014 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'rtocod', largeur: 120},
            {nom: 'rtodsc', largeur: 160},
            {nom: 'rcecod', largeur: 120},
            {nom: 'rcedsc', largeur: 160},
            {nom: 'rpmdatvig', largeur: 126}
        ]
    };
}
