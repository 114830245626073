import {IComponentController} from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMonoOccurrenceOptions, IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';

interface IPageMc1044 extends IComponentController {
    monoOccurrence: IMonoOccurrence
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    hieMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc1044Controller() {

    const vm: IPageMc1044 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.monoOccurrenceOptions = {
        };

        vm.hieMultiOccurrenceOptions = {
            fonctions: {
                afficherMenuSettingDansEntete: false,
                supprime: false,
                edition: false,
                nouveau: false,
                suiviModification: false,
                recherche: false
            },
            bloc: 'hie',
            bris: 'unacleint',
            brisNiveaux: 'niveau',
            colonnesVisibles: [
                { nom: 'unadsc', largeur: 250 }
            ],
            colonnesCachees: [
                'unacleint',
                'etat',
                'niveau',
                'unacleintniv'
            ]
        };
    }
}
