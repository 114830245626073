import {IComponentController} from 'angular';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRR0132 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    formulaireOdi: IFormulaire;
    formulaireMod: IFormulaire;
}

/* @ngInject */
export default function Rr0132Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRR0132 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireOdi = new Formulaire('LBL_BLC_ODI', [
            new FormulaireItem('eplcleint'),
            new FormulaireItem('ttccleint'),
            new FormulaireItem('tadcleint'),
            new FormulaireItem('demcleint',{
                default: (data: any) => (data.$valeurs.demcleint)
            })
       ]);

        vm.formulaireMod = new Formulaire('LBL_BLC_MOD', [
            new FormulaireItem('odiflgcor', {largeur: 33.33}),
            new FormulaireItem('odidatvig', {largeur: 33.33}),
            new FormulaireItem('odiflgact', {largeur: 33.33}),

            new FormulaireItem('odiadr1',   {largeur: 100}),
            new FormulaireItem('odiadr2',   {largeur: 100}),
            new FormulaireItem('odiadr3',   {largeur: 100}),

            new FormulaireItem('odicodpos', {largeur: 25}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('pyscod', {largeur: 50}),
            new FormulaireItem('odipysman', {largeur: 50}),

            new FormulaireItem('etacod', {largeur: 50}),
            new FormulaireItem('odietaman', {largeur: 50}),

            new FormulaireItem('muncod', {largeur: 50}),
            new FormulaireItem('odimunman', {largeur: 50}),

            new FormulaireItem('odicom', {largeur: 100}),

            new FormulaireItem('odiflgchq', {largeur: 33.33}),
            new FormulaireItem('odiflgfinann', {largeur: 33.33}),
            new FormulaireItem('odiflgerr', {largeur: 33.33})
        ]);

    }
}
