import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPagePb0014 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    cbeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cybFormulaire: IFormulaire;
}

/* @ngInject */
export default function Pb0014Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPagePb0014 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_CYB', [
            new FormulaireItem('cybann'),
            new FormulaireItem('peccleint'),
            new FormulaireItem('pbscleint'),
            new FormulaireItem('cybcom2'),            
            new FormulaireItem('cybcom',{ largeur: 66 })            
        ], { largeurDefaut: 33.33 })
    };

    vm.cybFormulaire = new Formulaire('',[
        new FormulaireItem('cbeenc', {default: ecranDetails.valeurs.cbeenc})
    ]);

    vm.cbeMultiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            edition: ecranDetails.valeurs.flgcbe,
            nouveau: ecranDetails.valeurs.flgcbe,
            supprime: ecranDetails.valeurs.flgcbe,            
            boutonDupliquer: false,
        },
        colonnesFixesGauche: [
            { nom: 'cbeseq', largeur: 100 },
            { nom: 'cobcod', largeur: 120 },
            { nom: 'cobdsc', largeur: 250 },
        ],
        colonnesVisibles: [           
            { nom: 'cbeflgcen', largeur: 120 },
            { nom: 'cbeflgdec', largeur: 120 },
            { nom: 'vaecbeimpbdg', largeur: 160 },
            { nom: 'tyddsc', largeur: 250 },
            { nom: 'rcbcodfin', largeur: 140 },
            { nom: 'rcbdscfin', largeur: 250 },
            { nom: 'rcbcodgrh', largeur: 140 },
            { nom: 'rcbdscgrh', largeur: 250 },
            { nom: 'cbeflgpln', largeur: 120 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cbeseq', {largeur: 33}),
            new FormulaireItem('cobcleint',{largeur: 33}),
            new FormulaireItem('vaecbeimpbdg',{largeur: 33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint',{largeur: 33}),  
            new FormulaireItem('cbeflgcen',{largeur: 33}),
            new FormulaireItem('cbeflgdec',{largeur: 33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rcbcleintfin',{largeur: 33}),
            new FormulaireItem('rcbcleintgrh',{largeur: 33}),
            new FormulaireItem('cbeflgpln',{largeur: 33})
        ], { largeurDefaut: 25 })
    };
}