import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPagePD0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0020Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPagePD0020 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pmicleint', {
                required: false
            }),
            new FormulaireItem('intcleint', {
                required: false
            }),
            new FormulaireItem('vecpritypins', {
                required: false
            }),
            new FormulaireItemDivider('LBL_DATE_INSP'),
            new FormulaireItem('datdeb', {
                required: false
            }),
            new FormulaireItem('datfin', {
                required: false
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'pmicod', largeur: 200}
        ],

        // Colonnes fixe à droite
        colonnesFixesDroite: [
            {nom: 'nbr_pij', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'pmidsc', largeur: 200},
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'pridatins', largeur: 200},
            {nom: 'vecpritypins', largeur: 200},
            {nom: 'vecpritypinsdsc', largeur: 200},
            {nom: 'priresins', largeur: 200},
            {nom: 'pricom', largeur: 200}
        ]
    };
}
