import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0122 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0122Controller(Menu: IMenuClass,
                                         rr0503Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         dateFilter: IFilterDate,
                                         MenuItemForage: IMenuItemForageClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRR0122 = this;

    const rr0068Route = {NAME: 'secure.accueil'};

    const forageEcran = new MenuItemForage(
        'LBL_FOR_TYP',
        (data: any) => getForage(data),
        (data: any) => getParams(data)
    );

    vm.multiOccurrenceOptions = {

        criteresSuggeres: new Formulaire([
            new FormulaireItem('carcleint', {
                required: true, largeur: 50
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ppadatdeb', {
                required: true
            }),
            new FormulaireItem('ppadatfin', {
                required: true
            }),
            new FormulaireItem('datcal')
        ]),

        colonnesCachees: ['pamcleint',
                          'soccleint',
                          'vaesysmnetab'],
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'unpcod', largeur: 90},
            {nom: 'unpdsc', largeur: 190},
            {nom: 'ppacod', largeur: 90},
            {nom: 'socmnt', largeur: 126,menu: [forageEcran]},
            {nom: 'socmntrmb', largeur: 136},
            {nom: 'vaedsc', largeur: 90},
            {nom: 'rfenomabrfed', largeur: 200},
            {nom: 'rfenomabrprv', largeur: 200}
         ]
    };

    function getForage(data: any): string {
        if (data.vaesysmnetab === 'PAT' || data.vaesysmnetab === 'PAR' || data.vaesysmnetab === 'PTS') {
            return rr0503Route.NAME;
        } else if (data.vaesysmnetab === 'SOC') {
            return rr0068Route.NAME;
        }
    }

    function getParams(data: any) {
        if (data.vaesysmnetab === 'PAT' || data.vaesysmnetab === 'PAR' || data.vaesysmnetab === 'PTS') {
            return { id: data.pamcleint };
        } else if (data.vaesysmnetab === 'SOC') {
            return { id: data.soccleint };
        }
    }

}
