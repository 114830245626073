import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IComponentController} from 'angular';


interface IPageMc0137 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mc0137Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0137 = this;


    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.multiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'aihcod', largeur: 100},
                {nom: 'aihdsc', largeur: 325},
                {nom: 'aihflgcat', largeur: 70},
                {nom: 'aihflgcif', largeur: 70, hidden: () => vm.valeursEcran.flgcif === 0},
                {nom: 'aihflgcpt', largeur: 70, hidden: () => vm.valeursEcran.flgcpt === 0},
                {nom: 'aihflgprs', largeur: 70, hidden: () => vm.valeursEcran.flgprs === 0},
                {nom: 'aihflgpru', largeur: 70, hidden: () => vm.valeursEcran.flgpru === 0},
                {nom: 'aihflguna', largeur: 70, hidden: () => vm.valeursEcran.flguna === 0},
                {nom: 'aihflgel1', largeur: 70, hidden: () => vm.valeursEcran.flgel1 === 0},
                {nom: 'aihflgel2', largeur: 70, hidden: () => vm.valeursEcran.flgel2 === 0},
                {nom: 'aihflgel3', largeur: 70, hidden: () => vm.valeursEcran.flgel3 === 0},
            ],
            formulaire: new Formulaire([
                new FormulaireItem('aihdsc'),
                new FormulaireItem('aihflgcat'),
                new FormulaireItem('aihflgcif', {largeur: 15}),
                new FormulaireItem('aihflgcpt', {largeur: 15}),
                new FormulaireItem('aihflgprs', {largeur: 15}),
                new FormulaireItem('aihflgpru', {largeur: 15}),
                new FormulaireItem('aihflguna', {largeur: 15}),
                new FormulaireItem('aihflgel1', {largeur: 15}),
                new FormulaireItem('aihflgel2', {largeur: 15}),
                new FormulaireItem('aihflgel3', {largeur: 15})
            ])
        };
    }
}
