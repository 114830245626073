import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMC0100 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mc0100Controller(Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    mc0101Route: IRoute,
    mc0099Route: IRoute,
    rr0003Route: IRoute,
    MenuItemForage: IMenuItemForageClass
    ) {

    const vm: IPageMC0100 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    const forageDoc = { FORDOC: new MenuItemForage(
            '',
            mc0099Route.NAME, (rowData:any) => ({id: rowData.doccleint01})
        )
    };

    const forageEmp = { FOREMP: new MenuItemForage(
        '',
        rr0003Route.NAME, (rowData:any) => ({employe: rowData.docaffdemcleint01})
    )
};

    vm.multiOccurrenceOptions = {
        fonctions: {
            effaceCriteres: false,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
        },
        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },
        actionsRangeeDroite: new Menu([
                    new MenuItemForage(
                        '',
                        mc0101Route.NAME,
                        (rowData: any) => ({
                                id: rowData.doccleint01
                            }),
                        {
                            icon: 'exit_to_app',
                            hidden: (rowData: any) => (rowData.ajfcleint === null)
                        }
                    )
                ]),
            criteresSuggeres: new Formulaire([
            new FormulaireItem('redcleint', {
                largeur: 33, required: true
            }),
            new FormulaireItem('docaffnumdoc01', {
                largeur: 33
            }),
            new FormulaireItem('eplcleint', {
                largeur: 33
            }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cifcleint', {
                largeur: 33
            }),
            new FormulaireItem('unacleint', {
                largeur: 33
            }),
            new FormulaireItem('grpcleint', {
                largeur: 33
            }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider(''),
            new FormulaireItem('typdoc', {
                largeur: 100
            }),

            new FormulaireGroupe( '',
                new Formulaire([
                    new FormulaireItem('appint', {
                        largeur: 50
                    }),
                    new FormulaireItem('insfds', {
                        largeur: 50
                    })
                ])
            ),
        ]),

        colonnesCachees: [
            'doccleint01', 'ajfcleint', 'docaffdemcleint01'
        ],
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc02', largeur: 160, menu: [forageDoc.FORDOC] },
        ],

        colonnesVisibles: [
            { nom: 'tydcod', largeur: 145},
            { nom: 'eplnummat', largeur: 145, menu: [forageEmp.FOREMP] },
            { nom: 'eplnomprn', largeur: 145},
            { nom: 'docmatetu05', largeur: 145},
            { nom: 'docaffdatdeb01', largeur: 145},
            { nom: 'docaffdatfin01', largeur: 145},
            { nom: 'docaffmnt01', largeur: 145},
            { nom: 'temcod', largeur: 145},
            { nom: 'temdscabr', largeur: 300},
            { nom: 'docreqcod03', largeur: 145},
            { nom: 'docreqnom03', largeur: 145},
            { nom: 'urgcod', largeur: 145},
            { nom: 'urgdscabr', largeur: 300},
            { nom: 'cifcod', largeur: 145},
            { nom: 'unacod', largeur: 145},
            { nom: 'docflgmsggrh', largeur: 145}
        ],
    };
}
