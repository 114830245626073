import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAs0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0004Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAs0004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'taycod', largeur: 115 },
            { nom: 'taydsc', largeur: 395 },
            { nom: 'tayflgtra', largeur: 128 },
            { nom: 'tayflgett', largeur: 148 },
            { nom: 'tayflgabs', largeur: 140 },
            { nom: 'tayflgadd', largeur: 168 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('taycod', { required: true }),
            new FormulaireItem('taydsc', { required: true }),
            new FormulaireItem('tayflgtra', { largeur: 25 }),
            new FormulaireItem('tayflgett', { largeur: 25 }),
            new FormulaireItem('tayflgabs', { largeur: 25 }),
            new FormulaireItem('tayflgadd', { largeur: 25 })
        ])
    };
}
