import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';

interface IPageTs0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ts0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         co0010Route: IRoute,
                                         $stateParams : IStateParamsService) {
    const vm: IPageTs0004 = this;
    
    vm.multiOccurrenceOptions = {
        navigatePage: co0010Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.idecleint,
                comcleint: rowData.comcleint,
                stamnetab: 'EPL',
                idecleintref: rowData.idecleintref
            };
        },
        formulaire: new Formulaire([
            new FormulaireItem('idecleintref'),
            new FormulaireItem('comcleint')
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur:100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur:262, hidden: $stateParams.employe},
            {nom: 'comcod', largeur:125},
            {nom: 'comdsc', largeur:315}
        ],
        colonnesCachees: [
            'idecleint',
            'comcleint',
            'idecleintref'
        ]
    };
}