import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pr0032Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0032 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'oppcod', largeur: 100 },
            { nom: 'oppdsc', largeur: 190 },
            { nom: 'oppflgact', largeur: 120 },
            { nom: 'oppflgadrrec', largeur: 120 },
            { nom: 'oppflgadrfin', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('oppcod', { largeur: 15 }),
            new FormulaireItem('oppdsc', { largeur: 35 }),
            new FormulaireItem('oppflgact', { largeur: 15 }),
            new FormulaireItem('oppflgadrrec', { largeur: 15 }),
            new FormulaireItem('oppflgadrfin', { largeur: 15 })
        ])
    };
}
