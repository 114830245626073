import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0074',
    ecrans: [
        '00-2'
    ],
    params: [
        'id',
        '?trncleint',
        '?tdmcleint',
        '?t_employe',
        '?lemcleint',
        '?aftcleint'
    ]
});
