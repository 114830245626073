import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';


interface IPageMc0054 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mc0054Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass) {
    const vm: IPageMc0054 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaeprutypbdgcri', { required: true, largeur: 25 }),
            new FormulaireItem('cifcleintcri', { required: true, largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unacleintdebcri', { largeur: 25 }),
            new FormulaireItem('unacleintfincri', { largeur: 25 }),
            new FormulaireItem('foncleintcri', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleintcri', { largeur: 25 }),
            new FormulaireItem('orpcleintcri', { largeur: 25 }),
            new FormulaireItem('blocleintcri', { largeur: 25 }),
            new FormulaireItem('cptcleintcri', { largeur: 25 })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_VALDEF_FONDS',
                'BOUVALDEFFDS', {
                selection: {
                    nom: 'selection',
                    lblConfirmationTitre: 'LBL_BTN_VALDEF_FONDS',
                    largeurFormulaire: 50,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('cifcleintdef', { default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.cifcleintcri, hidden: true }),
                        new FormulaireItem('blocleintdef', { default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.blocleintcri, hidden: true }),
                        new FormulaireItem('cptcleintdef', { default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.cptcleintcri, hidden: true }),
                        new FormulaireItem('flgmajpru', { largeur: 33.33, default: 1 }),
                        new FormulaireItem('flgmajprs', { largeur: 33.33, default: 1 }),
                        new FormulaireItem('flgmajven', { largeur: 33.33, default: 1 })
                    ])
                },
                successAction: (data: any) => {
                    vm.multiOccurrence.fetchDataList();
                }
            }),
            new MenuItemSelection(
                'LBL_BTN_VALGLO_AUTO',
                'BOUVALGLOAUTO', {
                selection: {
                    nom: 'selection',
                    lblConfirmationTitre: 'LBL_BTN_VALGLO_AUTO',
                    largeurFormulaire: 50,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('blocleintdef', { default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.blocleintcri, hidden: true }),
                        new FormulaireItem('cptcleintdef', { default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.cptcleintcri, hidden: true }),
                        new FormulaireItemDivider('LBL_DIV_PRU'),
                        new FormulaireItem('prcdeppru', { largeur: 33.33 }),
                        new FormulaireItem('mntdeppru', { largeur: 33.33 }),
                        new FormulaireItem('flgmavpru', { largeur: 33.33 }),
                        new FormulaireItemDivider('LBL_DIV_PRS'),
                        new FormulaireItem('prcdepprs', { largeur: 33.33 }),
                        new FormulaireItem('mntdepprs', { largeur: 33.33 }),
                        new FormulaireItem('flgmavprs', { largeur: 33.33 }),
                        new FormulaireItemDivider('LBL_DIV_VEN'),
                        new FormulaireItem('prcdepven', { largeur: 33.33 }),
                        new FormulaireItem('mntdepven', { largeur: 33.33 }),
                        new FormulaireItem('flgmavven', { largeur: 33.33 })
                    ])
                },
                successAction: (data: any) => {
                    vm.multiOccurrence.fetchDataList();
                }
            })
        ]),
        champsNonSauvergarderEtat: ['blocleintdef', 'cptcleintdef', 'prcdeppru', 'mntdeppru', 'flgmavpru'],
        colonnesVisibles: [
            { nom: 'prucod', largeur: 120 },
            { nom: 'prudscl01', largeur: 300 },
            { nom: 'prudatfin', largeur: 126 },
            { nom: 'pruprcdepbdg', largeur: 126 },
            { nom: 'prumntdepbdg', largeur: 126 }
        ]
    };

}
