import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCS0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cs0009Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         cs0003Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCS0009 = this;
    const forageNumAcc = new MenuItemForage('G_LBL_FONC_TRANS_DOCUMENT', cs0003Route.NAME, function (data: any) {
            return {
                ecran: '00-2',
                id: data.trncleint_acc
            };
        },
        {fonction: 'BTN_BOUOUV'});

    vm.multiOccurrenceOptions = {
        colonnesCachees:[
            'trncleint_acc'
        ],
        colonnesFixesGauche: [
            {nom: 'accnumdoc', largeur: 140, menu: [forageNumAcc]},
            {nom: 'accdat', largeur: 100},
            {nom: 'amenum', largeur: 90}
        ],
        colonnesFixesDroite: [
            {nom: 'ameflgrej', largeur: 80},
            {nom: 'ameflgrea', largeur: 85}
        ],
        colonnesVisibles: [
            {nom: 'amedsc', largeur: 272},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'amedatech', largeur: 105},
            {nom: 'amedatfin', largeur: 105},
            {nom: 'unocodrh', largeur: 110},
            {nom: 'unodscabrrh', largeur: 262},
            {nom: 'accdsc', largeur: 262}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ameflgrej'),
            new FormulaireItem('ameflgrea'),
            new FormulaireItem('amedatfin')
        ], {
            largeurDefaut: 33.33
        }),
        fonctions: {
            edition: true,
            suiviModification: true
        }
    };
}
