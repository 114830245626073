import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0149 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0149Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0149 = this;
    
    vm.$onInit = $onInit;

    function $onInit() {

        vm.multiOccurrenceOptions = { 
            colonnesFixesGauche: [
                {nom: 'usidsc', largeur:225 },
                {nom: 'usient', largeur:141 }
            ],
            colonnesVisibles: [
                {nom: 'prudsc', largeur:144 },
                {nom: 'cptdsc', largeur:144 },
                {nom: 'prsdsc', largeur:144 },
                {nom: 'unadsc', largeur:144 },
                {nom: 'cifdsc', largeur:144 }
            ],
            formulaire: new Formulaire('LBL_EDITION', [
                new FormulaireItem('usicleint',    { largeur : 30}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaeusipru', { largeur : 15}),
                new FormulaireItem('vaeusicpt', { largeur : 15}),
                new FormulaireItem('vaeusiprs', { largeur : 15}),  
                new FormulaireItem('vaeusiuna', { largeur : 15}),
                new FormulaireItem('vaeusicif', { largeur : 15}) 
            ])
        };    
    }
    
}