import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageRr0157 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0157Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0157 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 180},
            {nom: 'urgdscabr', largeur: 250},
            {nom: 'vaevpacat_dsc', largeur: 180},
            {nom: 'vaevpaannref_dsc', largeur: 200},
            {nom: 'vpadatanc', largeur: 200}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('vaevpacat'),
            new FormulaireItem('vaevpaannref'),
            new FormulaireItem('vpadatanc')
        ],{largeurDefaut: 25})
    };
}
