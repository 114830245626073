import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPagePR0040 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function PR0040Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePR0040 = this;
    
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('prjcleint', {required: false}),
            new FormulaireItem('pdcflgrec', {largeur: 15}),
            new FormulaireItem('datede', {largeur: 15}),
            new FormulaireItem('datea', {largeur: 15}),
            new FormulaireItem('orgcleint', {required: false}),
            new FormulaireItem('orpcleint'),
            new FormulaireItem('intcleint', {required: false}),
            new FormulaireItem('vecpdctypcer', {required: false}),
            new FormulaireItem('pdfcleint',  {required: false,  resourceParams: (data: any) => ({ prjcleint: data.prjcleint })})
        ]),        
        colonnesVisibles: [
            { nom: 'intcod', largeur: 150},
            { nom: 'intnom', largeur: 180},
            { nom: 'prjcod', largeur: 110 },
            { nom: 'prjdscabr', largeur: 190 },
            { nom: 'pdfcod', largeur: 140},
            { nom: 'orgcod', largeur: 120 },
            { nom: 'orgnom', largeur: 220 },
            { nom: 'orpcod', largeur: 116 },
            { nom: 'orpdscabr', largeur: 216 },
            { nom: 'pdcdat', largeur: 186 },
            { nom: 't_vecpdctypcer', largeur: 186 },
            { nom: 'vecpdctypcer_dsc', largeur: 216 },
            { nom: 'pdccom', largeur: 216 },
            { nom: 'cetactabr', largeur: 146 },
            { nom: 'pdcflgrec', largeur: 116 },
            { nom: 'prjdatfin', largeur: 116 },
            { nom: 'code_ubr', largeur: 116 },
            { nom: 'desc_ubr', largeur: 216 },
            { nom: 'date_fin_ubr', largeur: 116 },
            { nom: 'statut_ubr', largeur: 116 }
        ]
        };
}
