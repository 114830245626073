import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";

interface IPageMr1010 {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    desMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr1010Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm = this as IPageMr1010;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_AVIS_MANUEL', [
            new FormulaireItem('reference', { readonly: true, default: ecranDetails.valeurs.reference }),
            new FormulaireItem('avidatavi', { largeur: 25, required: true, readonly: (data: any) => data.aviflgenv }),
            new FormulaireItem('aviflgenv', { readonly: true, largeur: 25 }),
            new FormulaireItem('avitxt', { largeur: 100, required: true, readonly: (data: any) => data.aviflgenv })
        ]),
        fonctions: {
            supprime: (data: any) => !data.aviflgenv,
            edition: (data: any) => !data.aviflgenv
        }
    }

    vm.desMultiOccurrenceOptions = {
        bloc: 'des',
        colonnesVisibles: [
            { nom: 'vaedestyp', largeur: 200 },
            { nom: 'vaedsc', largeur: 200 },
            { nom: 'descod', largeur: 110 },
            { nom: 'desdsc', largeur: 200 },
            { nom: 'desflgtab', largeur: 150 },
            { nom: 'desflgcou', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaedestyp', {onValueChange: (data) => {
                data.rolcleint = undefined;
                data.rolcleint__rolcod = undefined;
                data.rolcleint__roldscabr = undefined;
                data.nircleint = undefined;
                data.nircleint__nirniv = undefined;
                data.nircleint__nirdscabr = undefined;
                data.intcleint = undefined;
                data.intcleint__intcod = undefined;
                data.intcleint__intnom = undefined;
            }}),
            new FormulaireItem('rolcleint', { hidden: (data) => data.vaedestyp !== 'ROL', required: (data) => data.vaedestyp === 'ROL' }),
            new FormulaireItem('nircleint', { hidden: (data) => data.vaedestyp !== 'NIV', required: (data) => data.vaedestyp === 'NIV' }),
            new FormulaireItem('intcleint', { hidden: (data) => data.vaedestyp !== 'INT', required: (data) => data.vaedestyp === 'INT' }),
            new FormulaireItem('employe', { hidden: (data) => data.vaedestyp !== 'EPL', readonly: true, default: ecranDetails.valeurs.employe }),
            new FormulaireItem('demcleint', { hidden: true,  default: ecranDetails.valeurs.demcleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('desflgtab', {default: 1}),
            new FormulaireItem('desflgcou')
        ], {readonly: () => vm.monoOccurrence.data.aviflgenv}),
        fonctions: {
            supprime: () => !vm.monoOccurrence.data.aviflgenv,
            nouveau: () => !vm.monoOccurrence.data.aviflgenv
        }
    }
}