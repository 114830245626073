import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageMr0311 extends IComponentController {
    formulairePgp: IFormulaire;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    pgbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0311Controller(mr0310Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMr0311 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            pageRetourSuppression: mr0310Route.NAME
        };

        vm.formulairePgp = new Formulaire('LBL_BLC_PGP', [
            new FormulaireItem('pgpcod'),
            new FormulaireItem('pgpdscabr', {
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('pgpdsc', { largeur: 50 }),
            new FormulaireItem('vaepaipriaut'),
            new FormulaireItem('unpcleint'),
            new FormulaireItem('pfccleint', { nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('colcleint'),
            new FormulaireItem('colcleintver'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ppacleintdeb'),
            new FormulaireItem('ppacleintfin'),
            new FormulaireItem('tagcleint'),
            new FormulaireItem('unccleint'),
            new FormulaireItem('pgpdatpai'),
            new FormulaireItem('ttccleint', { nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('bqecleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pgpdatdebbqe'),
            new FormulaireItem('pgpdatfinbqe'),
            new FormulaireItem('pgpsigbora'),
            new FormulaireItem('pgpsigborb'),
            new FormulaireItem('pgpcom', { largeur: 100, enableCount: true  }),
        ], { largeurDefaut: 25 });

        // Borne
        vm.pgbMultiOccurrenceOptions = {
            bloc: 'pgb',
            colonnesVisibles: [
                {nom: 'pgbvala', largeur: 140},
                {nom: 'pgbvalb', largeur: 140},
                {nom: 'pgbhrepro', largeur: 140},
                {nom: 'pgbvalgen', largeur: 140}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pgbvala'),
                new FormulaireItem('pgbvalb'),
                new FormulaireItem('pgbhrepro'),
                new FormulaireItem('pgbvalgen')
            ], { largeurDefaut: 25 })
        };
    }
}
