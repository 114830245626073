import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageDO0086 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions
}

/* @ngInject */
export default function Do0086Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageDO0086 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            id: ecranDetails.valeurs.alpcleint,
            formulaire: new Formulaire('LBL_BLC_ALP', [
                new FormulaireItem('alpdel', {largeur: 25}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('alpsuj', {required: true, largeur: 75}),
                new FormulaireItem('alptxt', {required: true, largeur: 100})
            ])
        };
    }
}
