import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageGS0107 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0107Controller(Menu: IMenuClass) {
    const vm: IPageGS0107 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'lohcod', largeur: 200},
            {nom: 'lohdsc', largeur: 200},
            {nom: 'lohdoc', largeur: 200},
            {nom: 'nbrlhd', largeur: 200}
        ]
    };
}
