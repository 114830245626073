import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0092 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0092Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAc0092 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'melcod', largeur: 90},
            {nom: 'meldsc', largeur: 268},
            {nom: 'vecsysquamel', largeur: 90},
            {nom: 'vecsysquamel_dsc', largeur: 237},
            {nom: 'melflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('melcod'),
            new FormulaireItem('meldsc'),
            new FormulaireItem('vecsysquamel', {largeur: 50}),
            new FormulaireItem('melflgact', {default: 1})
        ]),
        fonctions: {
           importation: true
        }
    };
}
