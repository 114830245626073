import {IPromise, IQService} from 'angular';
import {IApiConfig} from '../../../../core/interfaces/api-config.interface';
import {IColonne, IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../core/services/multi-occurrence.service';
import {IDefaultsService} from '../../../../core/services/utils/defaults.service';
import {IFormulaire} from '../../../../core/services/formulaire/formulaire.service';
import {IFiltreOptions} from '../../../../core/services/filtre.service';
import {IDataType} from '../../../../core/services/data-types/data-type.service';
import {IMonoOccurrence} from '../../../../core/services/mono-occurrence.service';
import {INotificationHandler} from '../../../../core/services/utils/notification-handler.service';
import {IApiError} from '../../../../core/interfaces/api-error.interface';
import {IFormulaireItem} from '../../../../core/services/formulaire/formulaire-item.service';
import {IEcranContextController} from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComposantDialog } from '../../../../core/components/ex-dialog/ex-dialog.controller';

export interface IDialogRecuperation extends IComposantDialog {
    lblTitre: string;
    nom: string;
    srccod: string;
    srccodref: string;
    dataType: IDataType;
    destination: IMonoOccurrence;
    criteresSuggeres: IFormulaire;
    filtres?: Array<IFiltreOptions>;
    colonnesVisibles: Array<IColonne>;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    resourceParams: {};
    datmod: string;
    initMultiOccurrence(): void;
    confirm(event: any, data: { refcleint: number; refcod: string; refnom: string; }): void;
    isDisabled(): boolean;
}

export interface IDialogRecuperationOptions {
    nom: string;
    lblTitre?: string;
    srccod: string;
    datmod: string;
    srccodref: string;
    dataType: IDataType;
    destination: IMonoOccurrence;
    criteresSuggeres?: IFormulaire;
    filtres?: Array<IFiltreOptions>;
    resourceParams?: {};
    colonnesVisibles: Array<IColonne>;
    libelles: any;
    ecranContext: IEcranContextController;
}

/* @ngInject */
export default function DialogRecuperationController(defaults: IDefaultsService,
                                                     ApiConfig: IApiConfig,
                                                     $q: IQService,
                                                     notificationHandler: INotificationHandler) {
    const vm: IDialogRecuperation = this;
    const LBL_TITRE = 'G_LBL_RECUPERER';
    const lblConfirm = 'G_LBL_BTN_APPLIQUER';

    vm.confirm = confirm;
    vm.isDisabled = isDisabled;
    vm.$onInit = function $onInit(){
        init();
    }

    function init() {
        defaults(vm, {
            icon: 'content_copy',
            lblTitre: LBL_TITRE,
            lblConfirm: lblConfirm,
            loading: true,
            loadingError: false,
            initialized: false,
            resourceParams: {}
        });

        vm.multiOccurrenceOptions = {
            srccodref: vm.srccodref,
            rangeesSelectionnables: true,
            fonctions: {
                supprime: false,
                nouveau: false,
                edition: false,
                suiviModification: false,
                exportation: false,
                importation: false,
                enregistrerUnEtat: false,
                saveEtatTemporaire: false,
                selectionnerDesColonnes: false,
                reinitialiser: false,
                routeParamsCriteres: false,
                // Si un critère est passé, on montre la liste directement
                criteresSuggeresVisibles: vm.criteresSuggeres && !vm.criteresSuggeres.liste.some((formulaireElement: IFormulaireItem) => formulaireElement !== undefined && formulaireElement.default instanceof Function ? formulaireElement.default(vm.destination.data) : formulaireElement.default)
            },
            srccod: `lov-${vm.srccod}`,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${vm.srccod}`,
            resourceParams: {
                ...vm.resourceParams,
                srccod: vm.srccodref
            },
            filtres: vm.filtres,
            criteresSuggeres: vm.criteresSuggeres,
            colonnesVisibles: vm.colonnesVisibles
        };
    }

    function isDisabled() {
        return (
            !vm.multiOccurrence ||
            vm.multiOccurrence.fonctions.criteresSuggeresVisibles ||
            !vm.multiOccurrence.getSelectedRows().length
        );
    }

    function confirm(): IPromise<any> {

        return save()
            .catch(afficherConfirmerSauvegarde)
    }

    function save() {
        if (vm.dataType.params.multipleValeurs) {
            return saveValeursMultiples();
        } else {
            return saveValeurUnique();
        }
    }

    function saveValeursMultiples(): IPromise<any> {
        const data = {
            [vm.nom]: vm.multiOccurrence.getSelectedRows(),
            datmod: vm.datmod
        };

        const params = {
            cols: vm.nom
        };

        return vm.destination.save(data, params);
    }

    function saveValeurUnique(): IPromise<any> {
        const selectedRow = vm.multiOccurrence.getSelectedRows()[0];

        const cols = Object.keys(vm.dataType.params.colonnesCopie);

        const data = cols.reduce((result: any, col: string) => {
            const lovCol = vm.dataType.params.colonnesCopie[col];
            result[col] = selectedRow[lovCol];
            return result;
        }, {});

        const params = {
            cols
        };

        return vm.destination.save(data, params);
    }

    function afficherConfirmerSauvegarde(error: IApiError): IPromise<IApiError> {
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: 'G_MSG_SAUV_ENR',
            confirmAction() {
                return save();
            }
        });

        return $q.reject(error);
    }
}
