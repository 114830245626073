import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageCP0051 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0051Controller(Menu: IMenuClass) {
    const vm: IPageCP0051 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'connumdoc', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnom', largeur: 200},
            {nom: 'pipnumdoc', largeur: 200},
            {nom: 'pipnumref', largeur: 200},
            {nom: 'pipdsc', largeur: 200},
            {nom: 'pplnumdoc', largeur: 200},
            {nom: 'ppldscl01', largeur: 200},
            {nom: 'condatdeb', largeur: 200},
            {nom: 'condatfin', largeur: 200},
            {nom: 'connumref', largeur: 200},
            {nom: 'conmntbrut', largeur: 200},
            {nom: 'conmntnet', largeur: 200},
            {nom: 'conmntcon', largeur: 200},
            {nom: 'condsc', largeur: 200}
        ]
    };
}
