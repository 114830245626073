import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGs002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0002Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs002 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'meaprvdsc', largeur: 150},
            {nom: 'meanumcle', largeur: 100},
            {nom: 'meatypmsgdsc', largeur: 150},
            {nom: 'meatypaffdsc', largeur: 140},
            {nom: 'meatxt', largeur: 450}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('meaprvcle'),
            new FormulaireItem('meanumcle'),
            new FormulaireItem('meatypmsg'),
            new FormulaireItem('meatypaff'),
            new FormulaireItem('meatxt', {largeur: 100})
        ], {largeurDefaut: 25})
    };
}
