import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMc2012 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    ieqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    invMultiOccurrenceOptions: IMultiOccurrenceOptions;
    incMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc2012Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2012 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_INI', [
            new FormulaireItem('intcleintrsp', {modifAllow: false}),
            new FormulaireItem('redcleint', {modifAllow: false}),
            new FormulaireItem('tincleint', {modifAllow: false})
        ], {largeurDefaut: 33.33})
    };

    vm.ieqMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'intcod', largeur: 80},
            {nom: 'intnom', largeur: 180},
            {nom: 'ieqdatdeb', largeur: 100},
            {nom: 'ieqdatfin', largeur: 100},
            {nom: 'ieqflgexccou', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcleintequ'),
            new FormulaireItem('ieqflgexccou'),
            new FormulaireItem('ieqdatdeb'),
            new FormulaireItem('ieqdatfin')
        ])
    };

    vm.invMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'invimp', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tincleinttmp', {default: (data:any) => vm.monoOccurrence.data.tincleint, hidden: true}),
            new FormulaireItem('invimp', {largeur: 100})
        ])
    };

    vm.incMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'caacod', largeur: 100},
            {nom: 'caadsc', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('caacleint', {largeur: 100})
        ])
    };
}
