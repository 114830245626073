import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IProfil} from "../../../../../core/resources/profil.resource";
import IStateService = angular.ui.IStateService;

interface IPageGs0119 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireSrc: IFormulaire;
    srfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fomMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fopMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    chcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    schMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ppoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    stdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srmMultiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Gs0119Controller(Menu: IMenuClass,
                                         gs0119Route: IRoute,
                                         $state: IStateService,
                                         profil: IProfil,
                                         Formulaire: IFormulaireClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGs0119 = this;

    vm.$onInit = $onInit;

    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
        };

        vm.formulaireSrc = new Formulaire('LBL_BLC_SRC', [
            new FormulaireItem('srccod'),
            new FormulaireItem('srcdsc', {largeur: 50}),
            new FormulaireItem('srctyp'),
            new FormulaireItem('srcflggra'),
            new FormulaireItem('srcflgsec'),
            new FormulaireItem('srcindgst'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('srccleintori', {largeur: 33}),
            new FormulaireItem('vaesysapp', {largeur: 33}),
            new FormulaireItem('vaesysmodsof', {largeur: 33})
        ], {largeurDefaut: 25});

        vm.srfMultiOccurrenceOptions = {
            bloc: 'srf',
            fonctions: {
                importation: true,
                editionRapide: true
            },
            colonnesVisibles: [
                {nom: 'srfcle', largeur: 100},
                {nom: 'srfdsc', largeur: 180},
                {nom: 'srfnomphy', largeur: 180},
                {nom: 'srfflgacc', largeur: 180},
                {nom: 'srfflgins', largeur: 180},
                {nom: 'srfflgmod', largeur: 180},
                {nom: 'srfflgsup', largeur: 180}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('srfcle', {largeur: 33}),
                new FormulaireItem('srfdsc', {largeur: 33}),
                new FormulaireItem('srfnomphy', {largeur: 33}),
                new FormulaireItem('srfflgacc', {default: 1}),
                new FormulaireItem('srfflgins', {default: 1}),
                new FormulaireItem('srfflgmod', {default: 1}),
                new FormulaireItem('srfflgsup', {default: 1})
            ], {largeurDefaut: 20}),
            colonnesEditionRapide: [
                {nom: 'srfcle', largeur: 100},
                {nom: 'srfdsc', largeur: 180},
                {nom: 'srfnomphy', largeur: 180},
                {nom: 'srfflgacc', largeur: 180},
                {nom: 'srfflgins', largeur: 180},
                {nom: 'srfflgmod', largeur: 180},
                {nom: 'srfflgsup', largeur: 180}
            ]
        };

        vm.srpMultiOccurrenceOptions = {
            bloc: 'srp',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'srpcle', largeur: 100},
                {nom: 'srptyp_dsc', largeur: 170},
                {nom: 'ptlcod', largeur: 140},
                {nom: 'srpnompar', largeur: 170}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('srpcle'),
                new FormulaireItem('srptyp'),
                new FormulaireItem('ptlcleint'),
                new FormulaireItem('srpnompar')
            ], {largeurDefaut: 25})
        };

        vm.fopMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'fop',
            colonnesVisibles: [
                {nom: 'ptlcod', largeur: 150},
                {nom: 'foptypsai_vrai_dsc', largeur: 120},
                {nom: 'fopvalsai_vrai', largeur: 150},
                {nom: 'foptypsai_faux_dsc', largeur: 120},
                {nom: 'fopvalsai_faux', largeur: 150},
                {nom: 'foptyptra_vrai_dsc', largeur: 120},
                {nom: 'fopvaltra_vrai', largeur: 150},
                {nom: 'foptyptra_faux_dsc', largeur: 120},
                {nom: 'fopvaltra_faux', largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ptlcleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemDivider('LBL_DIV_SAI'),
                new FormulaireItem('foptypsai_vrai'),
                new FormulaireItem('fopvalsai_vrai'),
                new FormulaireItem('foptypsai_faux'),
                new FormulaireItem('fopvalsai_faux'),
                new FormulaireItemDivider('LBL_DIV_TRA'),
                new FormulaireItem('foptyptra_vrai'),
                new FormulaireItem('fopvaltra_vrai'),
                new FormulaireItem('foptyptra_faux'),
                new FormulaireItem('fopvaltra_faux')
            ], { largeurDefaut: 25 })
        };

        vm.fomMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'fom',
            colonnesVisibles: [
                {nom: 'trlcod', largeur: 150},
                {nom: 'trldsc', largeur: 200},
                {nom: 'trcfintrt_vrai_dsc', largeur: 140},
                {nom: 'trcfintrt_faux_dsc', largeur: 140},
                {nom: 'fomflgcen', largeur: 75},
                {nom: 'fomflgdec', largeur: 75},
                {nom: 'fomflgdef', largeur: 75}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('trlcleint'),
                new FormulaireItemDivider('LBL_DIV_VRAI_FAUX'),
                new FormulaireItem('trcfintrt_vrai'),
                new FormulaireItem('trcfintrt_faux'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fomflgcen', {default: 1}),
                new FormulaireItem('fomflgdec', {default: 1}),
                new FormulaireItem('fomflgdef', {default: 1})
            ], { largeurDefaut: 25 }),
            multiOccurrenceOptionsEnfant: vm.fopMultiOccurrenceOptions
        };

        vm.srtMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                editionRapide: true
            },
            colonnesVisibles: [
                {nom: 'stanom', largeur: 170},
                {nom: 'stadsc', largeur: 230},
                {nom: 'srtblc', largeur: 120},
                {nom: 'srtcolref', largeur: 160},
                {nom: 'srtflgtabpri', largeur: 100},
                {nom: 'srtflgref', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stacleint'),
                new FormulaireItem('srtblc'),
                new FormulaireItem('srtcolref'),
                new FormulaireItem('srtflgtabpri'),
                new FormulaireItem('srtflgref')
            ], {largeurDefaut: 25}),
            colonnesEditionRapide: [
                {nom: 'stacleint', largeur: 400},
                {nom: 'srtblc', largeur: 120},
                {nom: 'srtcolref', largeur: 250},
                {nom: 'srtflgtabpri', largeur: 130},
                {nom: 'srtflgref', largeur: 100}
            ]
        };

        vm.srmMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                editionRapide: true
            },
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_COPIER',
                    'BOUCOPMOD',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        successAction: () => {
                            vm.srmMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'srmnom', largeur: 180},
                {nom: 'srmdsc', largeur: 150},
                {nom: 'srmflgdef', largeur: 120},
                {nom: 'srmflgsel', largeur: 200},
                {nom: 'vaesrmtyp_dsc', largeur: 170}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('srmnom'),
                new FormulaireItem('srmdsc'),
                new FormulaireItem('srmflgdef'),
                new FormulaireItem('srmflgsel'),
                new FormulaireItem('vaesrmtyp')
            ], {largeurDefaut: 25}),
            colonnesEditionRapide: [
                {nom: 'srmnom', largeur: 180},
                {nom: 'srmdsc', largeur: 150},
                {nom: 'srmflgdef', largeur: 120},
                {nom: 'srmflgsel', largeur: 200},
                {nom: 'vaesrmtyp', largeur: 170}
            ]
        };

        vm.srrMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                editionRapide: true
            },
            colonnesVisibles: [
                {nom: 'srrnomphy', largeur: 200},
                {nom: 'elicod', largeur: 170},
                {nom: 'srrflgsai', largeur: 140},
                {nom: 'srrnomseqann', largeur: 200},
                {nom: 'vaedeicle_dsc', largeur: 170},
                {nom: 'vaedspcle_dsc', largeur: 170}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('srrnomphy', {modelOptions:{updateOn: 'blur'}}),
                new FormulaireItem('elicleint'),
                new FormulaireItem('srrflgsai'),
                new FormulaireItem('srrnomseqann'),
                new FormulaireItem('vaedeicle'),
                new FormulaireItem('vaedspcle')
            ], {largeurDefaut: 25}),
            colonnesEditionRapide: [
                {nom: 'srrnomphy', largeur: 200},
                {nom: 'elicleint', largeur: 350},
                {nom: 'srrflgsai', largeur: 140},
                {nom: 'srrnomseqann', largeur: 200},
                {nom: 'vaedeicle', largeur: 250},
                {nom: 'vaedspcle', largeur: 250}
            ]
        };

        vm.chcMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                editionRapide: true
            },
            colonnesVisibles: [
                {nom: 'chcdsc', largeur: 150},
                {nom: 'chcnomphy', largeur: 170},
                {nom: 'chcflgmodobl', largeur: 90},
                {nom: 'chcflgobl', largeur: 75},
                {nom: 'chcflgmod', largeur: 75},
                {nom: 'chcflgdef', largeur: 75},
                {nom: 'chcnompar', largeur: 150},
                {nom: 'vaechcnomgen', largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('chcdsc', {largeur: 25}),
                new FormulaireItem('chcnomphy', {largeur: 25}),
                new FormulaireItem('vaechcnomgen'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('chcflgmodobl'),
                new FormulaireItem('chcflgobl'),
                new FormulaireItem('chcflgmod'),
                new FormulaireItem('chcflgdef'),
                new FormulaireItem('chcnompar')
            ], {largeurDefaut: 20}),
            colonnesEditionRapide: [
                {nom: 'chcdsc', largeur: 300},
                {nom: 'chcnomphy', largeur: 300},
                {nom: 'chcflgmodobl', largeur: 90},
                {nom: 'chcflgobl', largeur: 75},
                {nom: 'chcflgmod', largeur: 75},
                {nom: 'chcflgdef', largeur: 75},
                {nom: 'chcnompar', largeur: 150},
                {nom: 'vaechcnomgen', largeur: 150}
            ]
        };
        vm.schMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                editionRapide: true
            },
            colonnesVisibles: [
                {nom: 'schdsc', largeur: 200},
                {nom: 'schnomphy', largeur: 165},
                {nom: 'stamnetab', largeur: 130},
                {nom: 'vaesystypdon_dsc', largeur: 130},
                {nom: 'schnbrdec', largeur: 150},
                {nom: 'schflglov', largeur: 75},
                {nom: 'schflgmul', largeur: 90},
                {nom: 'schflglon', largeur: 80},
                {nom: 'schflgint', largeur: 80}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('schdsc'),
                new FormulaireItem('schnomphy'),
                new FormulaireItem('stamnetab', {modelOptions:{updateOn: 'blur'}}),
                new FormulaireItem('vaesystypdon'),
                new FormulaireItem('schnbrdec'),
                new FormulaireItem('schflglov'),
                new FormulaireItem('schflgmul'),
                new FormulaireItem('schflglon'),
                new FormulaireItem('schflgint')
            ], {largeurDefaut: 25}),
            colonnesEditionRapide: [
                {nom: 'schdsc', largeur: 300},
                {nom: 'schnomphy', largeur: 300},
                {nom: 'stamnetab', largeur: 130},
                {nom: 'vaesystypdon', largeur: 250},
                {nom: 'schnbrdec', largeur: 150},
                {nom: 'schflglov', largeur: 75},
                {nom: 'schflgmul', largeur: 90},
                {nom: 'schflglon', largeur: 80},
                {nom: 'schflgint', largeur: 80}
            ]
        };

        vm.ppoMultiOccurrenceOptions = {
            bloc: 'ppo',
            fonctions: {
                importation: true,
                editionRapide: true
            },
            colonnesVisibles: [
                {nom: 'vaeppotyp_dsc', largeur: 100},
                {nom: 'ppodsc', largeur: 200},
                {nom: 'pponomphy', largeur: 220},
                {nom: 'ppodscent', largeur: 150},
                {nom: 'ppovaldev', largeur: 220},
                {nom: 'ppovaldevang', largeur: 220},
                {nom: 'ppodsclng', largeur: 400}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vaeppotyp'),
                new FormulaireItem('ppodsc'),
                new FormulaireItem('pponomphy'),
                new FormulaireItem('ppocleintref', {
                    resourceParams: () => ({srccleint: vm.monoOccurrence && vm.monoOccurrence.data.srccleint}),
                }),
                new FormulaireItem('ppovaldev'),
                new FormulaireItem('ppovaldevang'),
                new FormulaireItem('ppodsclng', {largeur: 50, enableCount: true})
            ], {largeurDefaut: 25}),
            colonnesEditionRapide: [
                {nom: 'vaeppotyp_dsc', largeur: 300},
                {nom: 'ppodsc', largeur: 255},
                {nom: 'pponomphy', largeur: 240},
                {nom: 'ppocleintref', largeur: 240},
                {nom: 'ppovaldev', largeur: 240},
                {nom: 'ppovaldevang', largeur: 240},
                {nom: 'ppodsclng', largeur: 350}
            ]
        };

        vm.stdMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'stdnom', largeur: 150},
                {nom: 'stdnomphy', largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stdnom'),
                new FormulaireItem('stdnomphy')
            ], {largeurDefaut: 50})
        };
    }
}
