import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMc0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0009Controller(mc0169Route: IRoute) {
    const vm: IPageMc0009 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0169Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'hlddsc', largeur: 260},
            {nom: 'vaehldtypimp_dsc', largeur: 160},
            {nom: 'vaesysdevcod', largeur: 150},
            {nom: 'vaesysdevcod_dsc', largeur: 220},
            {nom: 'hldnbrperctbgl', largeur: 190},
            {nom: 'hldentimpprt', largeur: 315},
            {nom: 'hldentimp', largeur: 315},
            {nom: 'hldflgeqibdgdb', largeur: 160},
            {nom: 'hldsepimp', largeur: 150},
            {nom: 'hldflgel1admpru', largeur: 74},
            {nom: 'hldflgel2admpru', largeur: 74},
            {nom: 'hldflgel3admpru', largeur: 74}
        ]
    };
}
