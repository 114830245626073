import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGl0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0012Controller(gl0042Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGl0012 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gl0042Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gl0042Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'eficod', largeur: 100},
            {nom: 'efidsc', largeur: 250},
            {nom: 'foedsc', largeur: 250},
            {nom: 'efimntarr', largeur: 100},
            {nom: 'efiflgarrsom', largeur: 100}
        ]
    };
}
