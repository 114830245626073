import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0001Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0001 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'etbcod', largeur: 125 },
            { nom: 'etbnom', largeur: 215 },
            { nom: 'etbdatmod', largeur: 115 },
            { nom: 'etbref1', largeur: 185 },
            { nom: 'etbifrcod', largeur: 155 },
            { nom: 'etbflgtrf', largeur: 110 },
            { nom: 'etbflgstu', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('etbcod', { maxlength: 8 } ),
            new FormulaireItem('etbnom', { largeur: 42 }),
            new FormulaireItem('etbdatmod'),
            new FormulaireItem('etbref1'),
            new FormulaireItem('etbifrcod'),
            new FormulaireItem('etbflgtrf'),
            new FormulaireItem('etbflgstu')
        ],
            {
                largeurDefaut: 20
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
