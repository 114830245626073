import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGA0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceEAUOptions: IMultiOccurrenceOptions;    
}
/* @ngInject */
export default function Ga0003Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGA0003 = this;
    
    vm.multiOccurrenceEAUOptions = {
        bloc: 'eau',
        colonnesVisibles: [
            {nom: 'gaacod'      , largeur: 110},
            {nom: 'gaadsc'   , largeur: 225},
            {nom: 'gcacod'      , largeur: 150},
            {nom: 'gcadsc'   , largeur: 220},
            {nom: 'unacod'   , largeur: 170},
            {nom: 'unadsc'   , largeur: 275},
            {nom: 'vaesysnatctb', largeur: 165},
            {nom: 'vaesysnatctb_dsc', largeur: 250},
            {nom: 'eauflgact'   , largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('elgcleintref', {
                largeur: 50
            }),
            new FormulaireItem('vaesysnatctb', {
                largeur: 20
            }),
            new FormulaireItem('eauflgact', {
                largeur: 20, default: 1
            })
        ])
    };


    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'prucod'   , largeur: 110},
            {nom: 'prudscl01'   , largeur: 250},
            {nom: 'intcodrsp', largeur: 150},
            {nom: 'intnomrsp'   , largeur: 250},
            {nom: 'unacodmanbdg'   , largeur: 165},
            {nom: 'unadscmanbdg'   , largeur: 250},
            {nom: 'cifcodmanbdg'   , largeur: 150},
            {nom: 'cifdscmanbdg', largeur: 250},
            {nom: 'pruflgstu', largeur: 110},
            {nom: 'prudatdeb', largeur: 130},
            {nom: 'prudatfin', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gaacod', {
                largeur: 20
            }),
            new FormulaireItem('gaadsc', {
                largeur: 30
            }),
            new FormulaireItem('gaadscabr', {
                largeur: 20
            }),
            new FormulaireItem('gcacleint', {
                largeur: 20
            }),
            new FormulaireItem('unacleint', {
                largeur: 20
            }),
            new FormulaireItem('gaadatdeb', {
                largeur: 20
            }),
            new FormulaireItem('gaadatfin', {
                largeur: 20
            })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceEAUOptions
    };
}
