import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import {  IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageTf0003 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;      
}

/* @ngInject */
export default function Tf0003Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageTf0003 = this;    

    vm.monoOccurrenceOptions = {             
        
        formulaire: new Formulaire([
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('efacod',{ 
                                    largeur: 33, 
                                    modifAllow: false
                                })
            ]), { largeur: 50 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('vaeefatyp',{ 
                                   largeur: 100, 
                                   modifAllow: false,
                                   required: true                                   
                                  })
            ]), { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe('', new Formulaire([            
            new FormulaireItem('demcleint', {
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp !== 'EMP'  )
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('efanom', { 
                largeur: 50, 
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),            
            new FormulaireItem('efaprn', { 
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            })
            ]), { largeur: 100 }),
            new FormulaireItemSautDeLigne(), 
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('efaini',{ 
                    largeur: 30,
                    disabled:  (data: any) => ( data.vaeefatyp === 'EMP'  )
                })
            ]), { largeur: 49 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('efanas',{ 
                    largeur: 25,
                    disabled:  (data: any) => ( data.vaeefatyp === 'EMP'  )
                })
            ]), { largeur: 33 }),
            new FormulaireItemSautDeLigne(), 
            new FormulaireGroupe('', new Formulaire([ 
            new FormulaireItem('efaadr1', { 
                largeur: 100,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('efaadr2', { 
                largeur: 100,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('efaadr3', { 
                largeur: 100,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pyscod', {
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),             
            new FormulaireItem('etacod', {                
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP'  )
            }),  
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('muncod', {
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),
            new FormulaireItem('efacodpos', { 
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),
            new FormulaireItemSautDeLigne(),  
            new FormulaireItem('vaesyscodprv', { 
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),
            new FormulaireItem('cincleint', {
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),                 
            new FormulaireItemSautDeLigne(),
            ]), { largeur: 100 }),           
            new FormulaireItem('vaeindpai', {
                largeur: 50,
                disabled:  (data: any) => ( data.vaeefatyp === 'EMP' )
            }),
            new FormulaireItem('efaflgimp', {
                largeur: 50
            })
        ], {
            largeurDefaut: 100
        },
        )
    };

    
    };


