import {IComponentController, IFilterDate} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageRr0548 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    exdMultiOccurrence: IMultiOccurrence;
    formulaireEx1: IFormulaire;
    exdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0548Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         dateFilter: IFilterDate) {
    const vm: IPageRr0548 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Exportation des adresses
        vm.formulaireEx1 = new Formulaire('LBL_BLC_EXA', [
            new FormulaireItem('usrcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('exacodref'),
            new FormulaireItem('exadatenv', {default: dateFilter(new Date(), 'yyyy-MM-dd'),}),
            new FormulaireItemDivider('LBL_DIV_ADR'),
            new FormulaireItem('exaflgchq', {default: 1}),
            new FormulaireItem('exaflgfinann')
        ]);

        vm.exdMultiOccurrenceOptions = {
            criteresSuggeres: new Formulaire([
                new FormulaireItem('indcodpos', {default: 0}),
                new FormulaireItem('indlngblc', {default: 0}),
                new FormulaireItem('indafftel', {default: 0}),
                new FormulaireItem('ttlcleint', {
                    disabled: (data:any) => { return !data.indafftel; }
                })
            ]),
            fonctions: {
                criteresSuggeresVisibles: false,
                editionRapide: true
            },
            colonnesFixesGauche: [
                {nom: 'eplnummat'    , largeur: 100}
            ],
            colonnesVisibles: [
                {nom: 'ttldsc'       , largeur: 194, hidden: () => !vm.exdMultiOccurrence.etatSelected.criteresSuggeresData.indafftel},
                {nom: 'etlnumtel'    , largeur: 105, hidden: () => !vm.exdMultiOccurrence.etatSelected.criteresSuggeresData.indafftel},
                {nom: 'etlnumpos'    , largeur: 90, hidden: () => !vm.exdMultiOccurrence.etatSelected.criteresSuggeresData.indafftel},
                {nom: 'etladrcou'    , largeur: 210, hidden: () => !vm.exdMultiOccurrence.etatSelected.criteresSuggeresData.indafftel},
                {nom: 'etldsc'       , largeur: 194, hidden: () => !vm.exdMultiOccurrence.etatSelected.criteresSuggeresData.indafftel},
                {nom: 'eplnom'       , largeur: 195},
                {nom: 'eplprn'       , largeur: 195},
                {nom: 'vaeeplsex_dsc', largeur: 80},
                {nom: 'eadadrl01'    , largeur: 215},
                {nom: 'eadadrl02'    , largeur: 215},
                {nom: 'eadadrl03'    , largeur: 215},
                {nom: 'eadadrl04'    , largeur: 215},
                {nom: 'eadadrl05'    , largeur: 215},
                {nom: 'eadadrl06'    , largeur: 215},
                {nom: 'cincod'       , largeur: 95},
                {nom: 'epladrcou'    , largeur: 468}
            ],
            formulaire: new Formulaire('LBL_BLC_EXD', [
                new FormulaireItem('demcleint', {largeur: 25})
            ]),
            colonnesEditionRapide: [
                {nom: 'demcleint', largeur: 400}
            ]
        };
    }

}
