import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';


interface IPageCR0025 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0025Controller(Menu: IMenuClass,
    cr0024Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCR0025 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'ccrnumdoc', largeur: 75 },
            { nom: 'ccrnumdocseq', largeur: 50 },
            { nom: 'tydcod', largeur: 60 },
            { nom: 'refcod', largeur: 75 },
            { nom: 'refnom', largeur: 120 },
            { nom: 'ccrdat', largeur: 80 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'ccrmnt', largeur: 90 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'ccrnumref', largeur: 100 },
            { nom: 'vecccrcat', largeur: 100 },
            { nom: 'vecdsc', largeur: 200 },
            { nom: 'ccrdsc', largeur: 200 },
            { nom: 'usrcod', largeur: 200 }
        ],
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup', {hidden: () => ecranDetails.valeurs.vaehldtypimp !== 1}),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        navigatePage: cr0024Route.NAME,

        actionsNouveaux: new Menu([
            new MenuItemForage(cr0024Route.NAME)
        ]),
    };

}
