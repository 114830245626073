import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IDialogAllStatus } from '../../../../../core/constants/dialog.constant';
import { IApiError } from '../../../../../core/interfaces/api-error.interface';
import { IFormController } from 'angular';
import IDialogService = angular.material.IDialogService;
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IDialog } from "../../../../../core/services/dialog.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";

interface IPageMr1001 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formCtrl: IFormController;
    aftMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr1001Controller($stateParams: any,
    $mdDialog: IDialogService, ecranDetails: IEcranDetailsResourcesEntite,
    DialogStatus: IDialogAllStatus,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemEcran: IMenuItemEcranClass,
    mr2005Route: IRoute,
    MenuItemAction: IMenuItemActionClass,
    DialogInfo: IDialog) {
    const vm: IPageMr1001 = this;

    vm.monoOccurrenceOptions = {
        menus: [new MenuItemAction("BTN_CYCLE", "BOUCYC", () => ({}), {
            iconButton: false,
            lblMessageSuccess: 'G_MSG_ACTION_SUCCES',
            disabled: () => !vm.formCtrl || vm.formCtrl.$invalid,
            successAction() {
                $mdDialog.hide(DialogStatus.APPLIQUER);
            },
            errorAction(response: IApiError) {
                if (response.data) {
                    if (response.data.code === 'SOFE-20103') {
                        if (response.data.message.includes("APP-00666")) {
                            DialogInfo.show({
                                locals: {
                                    lblTitre: 'G_LBL_CYCLE_TRANS_TITRE',
                                    lblDescription: response.data.message.replace("ORA-20103: ", "")
                                }
                            })
                            return $mdDialog.hide(DialogStatus.APPLIQUER)
                        } else {
                            return $mdDialog.cancel({
                                lblTitre: 'G_LBL_CYCLE_TRANS_TITRE',
                                lblMessage: response.data.message,
                                error: response
                            });
                        }
                    } else if (response.data.code === 'SOFE-20110') {
                        return new MenuItemEcran("REPERER", mr2005Route.NAME, () => ({ id: vm.monoOccurrence.data.tsecleint, ajoutIndividu: true }), { appliquer: true }).action()
                    }
                } else {
                    return $mdDialog.cancel({
                        lblTitre: 'G_LBL_FSA_CYCLE_EVENT',
                        lblMessage: 'G_MSG_ERREUR_ACTION',
                        error: response
                    });
                }
            }
        })],
        icon: 'device_hub',
        fonctions: {
            boutonEnregistrerHaut: false,
            exportation: false,
            suiviModification: false,
            afficherMenusDansEntete: true,
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_TITRE', [
            new FormulaireItem('numcol', { default: $stateParams.numcol, hidden: true }),
            new FormulaireItem('eclcleint', { default: $stateParams.eclcleint, hidden: true }),

            // VAEESYFENPAR = 1
            new FormulaireItem('trmcleint', { resourceParams: () => ({ trncleint: vm.monoOccurrence.data.trncleint }) }),
            // VAEESYFENPAR = 2 ou 13
            new FormulaireItem('datfin'),
            // VAEESYFENPAR = 3
            new FormulaireItem('stocleint', { resourceParams: () => ({ trncleint: vm.monoOccurrence.data.trncleint }) }),
            new FormulaireItem('poscleint', { resourceParams: (data: any) => ({ stocleint: data.stocleint }) }),
            new FormulaireItem('nircleint', {
                resourceParams: (data: any) => ({
                    stocleint: data.stocleint,
                    poscleint: data.poscleint
                })
            }),
            // VAEESYFENPAR = 4
            new FormulaireItem('datvig'),

            new FormulaireItem("eplnom", { hidden: data => data.vaeesyfenpar !== '8', required: data => data.vaeesyfenpar === '8' }),
            new FormulaireItem("eplprn", { hidden: data => data.vaeesyfenpar !== '9', required: data => data.vaeesyfenpar === '9' }),
            new FormulaireItem("epldatnai", { hidden: data => data.vaeesyfenpar !== '10', required: data => data.vaeesyfenpar === '10' }),
            new FormulaireItem("eplnas", { hidden: data => data.vaeesyfenpar !== '11', required: data => data.vaeesyfenpar === '11' }),
            // VAEESYFENPAR = 12
            new FormulaireItem('acccleint', { resourceParams: () => ({ trncleint: vm.monoOccurrence.data.trncleint }) }),
            // VAEESYFENPAR = 13
            new FormulaireItem('datfinrem'),
            // VAEESYFENPAR = 16
            new FormulaireItem('poscleintatf'),
            // VAEESYFENPAR = 17
            new FormulaireItem('eplnummat', { maxlength: 8 }),
            // VAEESYFENPAR = 20
            new FormulaireItem('dpxcleint'),
            new FormulaireItem('dxecleint'),
            new FormulaireItem('nbrhre'),
            // VAEESYFENPAR = 21
            new FormulaireItem('vaetyptsl'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tsldatdeb'),
            new FormulaireItem('tsldatfin'),
            // VAEESYFENPAR = 22
            new FormulaireItem('peccleint'),
            // VAEESYFENPAR = 23
            new FormulaireItem('gpecleint', { resourceParams: () => ({ trncleint: vm.monoOccurrence.data.trncleint }) }),
            // VAEESYFENPAR = 24
            new FormulaireItem('fewnumdoc'),
            // VAEESYFENPAR = 50
            new FormulaireItem('rldindrevint', {
                direction: 'vertical',
                default: 1,
                largeur: 100,
                raised: true,
                disabled: !ecranDetails.fonctions["TRN.SKIPINT"].flgacc,
                formulairesConditionnels: [{
                    valeur: 1,
                    formulaire: new Formulaire([
                        new FormulaireItem('intcleintcib', { largeur: 50, required: true, resourceParams: { aftflgact: 1 } })
                    ])
                }]
            }),
            // VAESYSFENPAR = 51 (Référence interne pour Atlas)
            new FormulaireItem('tseintref'),
            // Commentaire (commun à tous)
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('com', { largeur: 100 })
        ]),
    };

    vm.aftMultiOccurrenceOptions = {
        bloc: 'aft',
        colonnesVisibles: [
            { nom: 'aftdsc', largeur: 300 },
            { nom: 'tafdsc', largeur: 200 },
            { nom: 'aftdatdeb', largeur: 100 },
            { nom: 'aftdatfin', largeur: 100 },
            { nom: 'tafflgttu', largeur: 80 }
        ]
    }
}
