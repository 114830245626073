import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0211 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0211Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: any) {
    const vm: IPageMr0211 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'adccleint', valeur: $stateParams.reference, visible: false}
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('vaeadctra', {largeur: 50, nombreLibellesDetailsCacher: 1}),
            new FormulaireItem('colcleint', {largeur: 50})
        ]),
        colonnesVisibles: [
            {nom: 'vaeadctra_dsc', largeur: 200},
            {nom: 'colcod',        largeur: 130},
            {nom: 'coldsc',        largeur: 400}
        ]
    };
}
