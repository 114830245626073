import { IController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFilterDate } from 'angular';
import { IData } from '../../../../../core/services/data-linker.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCr0021 extends IController {
    formulaireCcr: IFormulaire;
    dpeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrenceDpd: IMultiOccurrence;
    multiOccurrenceDpe: IMultiOccurrence;
}

/* @ngInject */
export default function Cr0021Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    dateFilter: IFilterDate,
    cr0013Route: IRoute,
    cr2004Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService) {
    const vm: IPageCr0021 = this;

    vm.monoOccurrenceOptions = {
        infosFinancieres: {
            impactFinancier: ecranDetails.valeurs.flgbouimpfin,
            impactBudgetaire: ecranDetails.valeurs.flgbouimpbud
        },
        menus: [
            new MenuItemForage(
                'LBL_SELECTION',
                cr2004Route.NAME,
                (data: any) => ({
                    dpocleint: data.dpocleint,
                    loecleint: $stateParams.loecleint
                }),
                {
                    icon: 'attach_money',
                    iconButton: false,
                    fonction: 'BTN_SELECTION',
                    hidden: () => !(ecranDetails.valeurs.flgselrap)
                }
            )
        ],
        formulaire: new Formulaire('LBL_BLC_DPO', [
            new FormulaireItem('scbcleint', { largeur: 33.33 }),
            new FormulaireItem('dpoflgverfin', { readonly: true, largeur: 10 }),
            new FormulaireItem('dpoflgref', { readonly: true, default: 0, largeur: 10 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dponumdoc', { largeur: 10, readonly: true }),
            new FormulaireItem('dponumdocseq', { titre: ' ', largeur: 10, readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('peccleint', { required: true, largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dpodat', { default: dateFilter(new Date(), 'yyyy-MM-dd'), required: true, largeur: 10 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('vaesysdevcod', { readonly: true, largeur: 10 }),
            new FormulaireItem('devtaucou', { readonly: true, titre: ' ', largeur: 10 }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dpodsc', { largeur: 70, required: () => (ecranDetails.valeurs.flgdscdpo) }),
            new FormulaireItem('totdpo', { readonly: true, default: ecranDetails.valeurs.total, largeur: 10 }),
            new FormulaireItem('etdcleint', { readonly: true, default: ecranDetails.valeurs.etdcleint, hidden: true })
        ])
    };

    vm.dpeMultiOccurrenceOptions = {
        bloc: 'dpe',
        fonctions: {
            editionRapide: true
        },
        colonnesVisibles: [
            {
                nom: 'encnumdoc', largeur: 200,
                menu: [
                    new MenuItemForage(
                        '',
                        cr0013Route.NAME,
                        (data: any) => {
                            return {
                                id: data.doccleintref
                            };
                        }
                    )
                ]
            },
            { nom: 'encmnt', largeur: 100 },
            { nom: 'vaesysdevcod', largeur: 100, titre: ' ' },
            { nom: 'encmntrevnet', largeur: 100 },
            { nom: 'encdat', largeur: 200 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'chqflgnsf', largeur: 100 },
            { nom: 'chqdatnsf', largeur: 110 }
        ],
        colonnesCachees: [
            'doccleintref'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('enccleint', {
                resourceParams: (data: IData) => (
                    {
                        vaesysdevcod: vm.monoOccurrence.data.vaesysdevcod
                    }
                )
            })
        ]),
        colonnesEditionRapide: [
            { nom: 'enccleint', largeur: 200 }
        ],
        successActionSauvegarde: () => {
            vm.multiOccurrenceDpd.fetchDataList();
        },
        onDelete: () => {
            vm.multiOccurrenceDpd.fetchDataList();
        }
    };

    vm.dpdMultiOccurrenceOptions = {
        bloc: 'dpd',
        colonnesVisibles: [
            { nom: 'vaesysdevcod_dsc', largeur: 200 },
            { nom: 'dpdmnt', largeur: 180 },
            { nom: 'dpdmntdevhld', largeur: 180 },
            { nom: 'devtaucou', largeur: 180 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesysdevcod_dsc', { readonly: true }),
            new FormulaireItem('dpdmnt', { readonly: true }),
            new FormulaireItem('dpdmntdevhld'),
            new FormulaireItem('devtaucou', { readonly: true })
        ]),
        successActionSauvegarde: () => {
            vm.multiOccurrenceDpe.fetchDataList();
        },
        onDelete: () => {
            vm.multiOccurrenceDpe.fetchDataList();
        }
    };

}
