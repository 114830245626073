import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPageRe2003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re2003Controller(parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe2003 = this;

    vm.multiOccurrenceOptions = {
        //filtres: parametresRecherche(ecranDetails),
        colonnesFixesGauche: [
            {nom: 'tagcod', largeur: 120}
        ],
        colonnesVisibles: [
            {nom: 'tagdscabr', largeur: 120},
            {nom: 't_imp', largeur: 220},
            {nom: 'aftcod', largeur: 120},
            {nom: 'posnumdoc', largeur: 120},
            {nom: 'lemcod', largeur: 120},
            {nom: 'temcod', largeur: 126},
            {nom: 'unocod', largeur: 120},
            {nom: 'dernumdoc', largeur: 120},
            {nom: 'drpnumdoc', largeur: 120}
        ],
        colonnesFixesDroite: [
            {nom: 'rsimntcal', largeur: 120}
        ],
        
    };
}
