import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageRR0512 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0512Controller(
    FormulaireItem: IFormulaireItemClass,
    rr0500Route: IRoute,
    Formulaire: IFormulaireClass) {
    const vm: IPageRR0512 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('unpcleint'),
            new FormulaireItem('ppacleint'),
            new FormulaireItem('demcleint'),
            new FormulaireItem('rad_epl', { largeur: 33, default: 1 }),
            new FormulaireItem('flgexccou'),
            new FormulaireItem('flgexccanncou'),
        ]),
        navigatePage: rr0500Route.NAME,
        navigateParams: (rowData:any) => (
            {
                demcleint: rowData.demcleint
            }
        ),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true
        },
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 262 },
            { nom: 'nbr_fep', largeur: 90 },
            { nom: 'nbr_cal', largeur: 90 },
            { nom: 'flg_err', largeur: 90 },
            { nom: 'flg_avr', largeur: 90 }
        ]
    };
}
