import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCC0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0005Controller(
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cc0008Route: IRoute
) {
    const vm: IPageCC0005 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cc0008Route.NAME,
        navigateParams: data => ({ id: data.tarcleint }),
        actionsNouveaux: new Menu([
            new MenuItemForage(cc0008Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false,
            edition: false,
            supprime: false
        },
        colonnesVisibles: [
            { nom: 'tarcod', largeur: 89 },
            { nom: 'tardsc', largeur: 262 },
            { nom: 'urgcod', largeur: 100 },
            { nom: 'urgdscabr', largeur: 262 },
            { nom: 'tarflgaccanc', largeur: 60 },
            { nom: 'tarflgcbl', largeur: 76 },
            { nom: 'tarflgenseigncc', largeur: 120 },
            { nom: 'tarflgtravopratiq', largeur: 120 },
            { nom: 'temcod', largeur: 89 },
            { nom: 'temdscabr', largeur: 262 },
            { nom: 'ttccod', largeur: 105 },
            { nom: 'ttcdsc', largeur: 262 }
        ]
    };
}
