import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageAS0039 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0039Controller(as0040Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageAS0039 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: as0040Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(as0040Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'athcod', largeur: 90},
            {nom: 'athdsc', largeur: 262},
            {nom: 'athcom', largeur: 520},
            {nom: 'athflgact', largeur: 65}
        ]
    };
}
