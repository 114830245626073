import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRR0141 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0141Controller(rr0013Route: IRoute) {
    const vm: IPageRR0141 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: rr0013Route.NAME,
        navigateParams: (rowData: any) => ({
            id: rowData.ddacleint,
            demcleint: rowData.demcleint,
            trncleint: rowData.trncleint
        }),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'ciecod', largeur: 120},
            {nom: 'cienomabr', largeur: 210},
            {nom: 'agndsc', largeur: 189},
            {nom: 'ddadatref', largeur: 100},
            {nom: 'ddacom', largeur: 236},
            {nom: 'tdaflgman', largeur: 91},
            {nom: 'tdadsc', largeur: 189}
        ]
    };
}
