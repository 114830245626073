import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageCC0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0028Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageCC0028 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'tconumdoc', largeur: 105},
            {nom: 'crscod', largeur: 115},
            {nom: 'crsdsc', largeur: 210},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'tcoflgfer', largeur: 72},
            {nom: 'tcoflgtrf', largeur: 95},
            {nom: 'tcoflgtrftouemp', largeur: 100},
            {nom: 'tcodat', largeur: 100},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 210},
            {nom: 'tcocom', largeur: 262},
            {nom: 'trnflgstaann', largeur: 75},
            {nom: 'trnflgstafer', largeur: 72}
        ]
    };
}
