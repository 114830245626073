import {module} from 'angular';
import HistoriqueColonneController from './dialog-historique-colonne.controller';
import {IDialogClass} from '../../../../core/services/dialog.service';

export default module('app.dialogs.dialog-historique-colonne', []).factory('DialogHistoriqueColonne', DialogHistoriqueColonneFactory);

/* @ngInject */
function DialogHistoriqueColonneFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: HistoriqueColonneController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-historique-colonne.html'),
        locals: {
            icon: 'ballot',
            lblTitre: 'G_LBL_HISTORIQUE_COLONNE',
            lblCancel: 'G_LBL_BTN_FERMER'
        }
    });
}
