import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
interface IPageDb0012 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    domMultiOccurenceOptions: IMultiOccurrenceOptions;
    envMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0012Controller(Formulaire: IFormulaireClass) {
    const vm: IPageDb0012 = this;
    vm.monoOccurrenceOptions = {
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        },
        fonctions: {
            suiviModification: false,
            boutonEnregistrerHaut: false
        },
        formulaire: new Formulaire('TITRE_ENP', [])
    };
    vm.domMultiOccurenceOptions = {
        colonnesVisibles: ['dommsg']
    };
    vm.envMultiOccurrenceOptions = {
        bloc: 'env',
        colonnesVisibles: [
            { nom: 't_imp', largeur: 228 },
            { nom: 'envmnteng', largeur: 126 },
            { nom: 'envdsc', largeur: 245 }
        ],
        colonnesFixesDroite: [{ nom: 'envflgmsg', largeur: 100 }],
        colonnesCachees: ['doccleint']
    };
}
