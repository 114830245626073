import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {
    FormulaireElement, IFormulaire,
    IFormulaireClass
} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForage, IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IComponentController} from 'angular';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageExterneClass} from '../../../../../core/services/menu/menu-item-forage-externe.service';
import {IFormulaireTemplateSlotClass} from '../../../../../core/services/formulaire/formulaire-template-slot.service';

interface IPageCP0005 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    menusCrp: {[col: string]: Array<FormulaireElement>};
    formulaireFou: IFormulaire;
    formulairePfl: IFormulaire;
    formulaireDac: IFormulaire;
    formulaireCom: IFormulaire;
    formulaireCrp: IFormulaire;
    btnMenus: Array<IMenuItemForage>;
    barMenus: {[col: string]: Array<IMenuItemForage>};
    radMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fcnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cfnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    scbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ccaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    focMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rtxMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0005Controller(cp0007Route: IRoute,
                                         cp0009Route: IRoute,
                                         cp1010Route: IRoute,
                                         cp0035Route: IRoute,
                                         cp0072Route: IRoute,
                                         cp0073Route: IRoute,
                                         cp0074Route: IRoute,
                                         cr0004Route: IRoute,
                                         mc0046Route: IRoute,
                                         mc0133Route: IRoute,
                                         mc0146Route: IRoute,
                                         mc1010Route: IRoute,
                                         mc1011Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                         Menu: IMenuClass,
                                         MenuItemForageExterne: IMenuItemForageExterneClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass)  {

    const vm: IPageCP0005 = this;

    vm.$onInit = $onInit;
    const tf0003Route = {NAME: 'secure.accueil'};
    const cp9999Route = {NAME: 'secure.accueil'};
    const cr9999Route = {NAME: 'secure.accueil'};
    const menuItemSiteWeb = new MenuItemForageExterne(() => vm.monoOccurrence.data.fousitint, {hidden: () => !vm.monoOccurrence.data.fousitint});
    const menuItemBottin =  new MenuItemForageExterne(() => vm.monoOccurrence.data.foubot,{hidden: () => !vm.monoOccurrence.data.foubot});

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Menu des boutons
        vm.btnMenus = [
            new MenuItemEcran(
                'LBL_FOR_T4A',
                tf0003Route.NAME,
                () => ({refcleint: vm.monoOccurrence.id}),
                {
                    icon: 'folder',
                    iconButton: false,
                    hidden: () => (!vm.monoOccurrence.data.foucleint),
                    fonction: 'BOUT4A'
                }
            ),
            new MenuItemEcran(
                'LBL_FOR_RETENUE',
                mc1011Route.NAME,
                () => ({refcleint: vm.monoOccurrence.id}),
                {
                    icon: 'assessment',
                    iconButton: false,
                    hidden: () => (!vm.monoOccurrence.data.foucleint),
                    fonction: 'BOURET'
                }
            )
        ];

        // Menu pour les barres de totaux
        vm.barMenus = {
            sldcap: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    cp9999Route.NAME,
                    () => ({refcleint: vm.monoOccurrence.id}),
                    {
                        icon: 'exit_to_app',
                        iconButton: true,
                        fonction:'CAP'
                    }
                )
            ],
            mntava: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    cp9999Route.NAME,
                    () => ({refcleint: vm.monoOccurrence.id}),
                    {
                        icon: 'exit_to_app',
                        iconButton: true,
                        fonction:'AVA'
                    }
                )
            ],
            sldcar: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    cr9999Route.NAME,
                    () => ({refcleint: vm.monoOccurrence.id}),
                    {
                        icon: 'exit_to_app',
                        iconButton: true,
                        fonction:'CAR'
                    }
                )
            ]
        };

        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_SOLDE_CIE',
                    mc0146Route.NAME,
                    () => ({
                        refcleint:vm.monoOccurrence.data.refcleint
                    }),
                    {
                        icon: 'assessment',
                        iconButton: false,
                        fonction:'BTN_BOUSOLD'
                    }
                )
            ])
        };

        // Fournisseur
        vm.formulaireFou = new Formulaire('LBL_FOU', [
            new FormulaireItem('foucleint', {hidden: true}),
            new FormulaireItem('foucod'),
            new FormulaireItem('founom', {modelOptions: {updateOn: 'blur'}}),
            new FormulaireItem('founomabr'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('founumneq'),
            new FormulaireItem('founumtvq'),
            new FormulaireItem('founumtps'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tfocleint'),
            new FormulaireItem('fousitint', {menu: [menuItemSiteWeb]}),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES', new Formulaire([
                new FormulaireItem('vaesyslng', {largeur: 33.33}),
                new FormulaireItem('fogcleint', {largeur: 33.33}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fouindfrsacc'),
                new FormulaireItem('fouflgimptfe'),
                new FormulaireItem('fouindadrpai'),
                new FormulaireItem('fouflgdou'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ciecleintint', {largeur: 33.33}),
                new FormulaireItem('vectypapp', {largeur: 33.33}),
                new FormulaireItem('foubot', {largeur: 33.33,menu: [menuItemBottin]}),
                new FormulaireItem('fouboturl', {largeur: 33.33,menu: [menuItemBottin],hidden:true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fouflginv'),
                new FormulaireItem('fouflgcom'),
                new FormulaireItem('fouflgexc'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemDivider('LBL_STATUT'),
                new FormulaireItem('fouflgstu'),
                new FormulaireItem('foudatdeb'),
                new FormulaireItem('foudatfin')
            ], {largeurDefaut: 25}))
        ], {largeurDefaut: 33.33});

        const foragePfl = [
            new MenuItemForage(
                'BTN_FORAGE_PFL',
                cp0007Route.NAME,
                () => ({
                    pflcleint: vm.monoOccurrence.data.pflcleint,
                    ecran : '01-2',
                }),
                { hidden: () => !vm.monoOccurrence.data.pflcleint,
                    fonction:'BOUCAP'}
            )
        ];
        const forageMop = [
            new MenuItemForage(
                'BTN_FORAGE_PFL',
                cp0009Route.NAME,
                () => ({
                    mopcleint: vm.monoOccurrence.data.mopcleint,
                    ecran : '01-2',
                }),
                {
                    fonction:'BOUPAI',
                icon:'create'}
            )
        ];
        const forageEnt = [
            new MenuItemForage(
                'BTN_FORAGE_PFL',
                cp1010Route.NAME,
                () => ({
                    entcleint: vm.monoOccurrence.data.entcleint,
                    ecran:'01-2',
                }),
                { hidden: () => !vm.monoOccurrence.data.entcleint,
                    fonction:'BOUENTPAI',
                icon:'create'}
            )
        ];
        const forageTax = [
            new MenuItemForage(
                'BTN_FORAGE_TAX',
                cp0007Route.NAME,
                () => ({
                    pflcleint: vm.monoOccurrence.data.pflcleint,
                    ecran:'01-2',
                }),
                { hidden: () => !vm.monoOccurrence.data.taxcleint,
                    fonction:'BOUENTPAI',
                    icon:'create'}
            )
        ];
        // Profil CAP
        vm.formulairePfl = new Formulaire('LBL_PFL', [
            new FormulaireItem('pflcleint', {largeur: 50, menu: foragePfl}),
            new FormulaireItemSautDeLigne(),
            // New FormulaireItem('t_mopcoddsc', {menu: forageMpf}),
            new FormulaireItem('mopcleint', {menu: forageMop}),
            // New FormulaireItem('t_entcoddsc', {menu: forageEnf}),
            new FormulaireItem('entcleint', {menu: forageEnt}),
            // New FormulaireItem('t_taxdscfmt'),
            new FormulaireItem('taxcleint',{menu: forageTax})
        ], {largeurDefaut: 33.33, menu: new Menu([
                new MenuItemEcran(
                    'LBL_IMPUTATION',
                    mc0046Route.NAME,
                    (data: any) => ( {vaeusient: 'FOU', uvicleintref: data.refcleint}),
                    {
                        icon: 'view_list',
                        fonction:'BOUIMP',
                        iconButton: false,
                        disabled: (data:any) => !data.pflcleint
                    }
                )
            ])});
        // Adresse
        vm.radMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_ADRESSE',
                    cp0072Route.NAME,
                    (data:any) => ({
                        id: data.radcleint,
                        refcleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: 'launch',
                        fonction:'BOUOUV',
                        onClose() {
                            vm.radMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    '',
                    cp0072Route.NAME, () => {
                        return {
                            refcleint: vm.monoOccurrence.data.refcleint
                        };
                    },
                    {  onClose() {
                            vm.radMultiOccurrence.fetchDataList();
                        } }
                )
            ]),
            bloc: 'rad',
            fonctions: {
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                pagination: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'radfmt', largeur: 200},
                {nom: 'radflgpai', largeur: 91},
                {nom: 'radflgach', largeur: 91},
                {nom: 'radflgdef', largeur: 91},
                {nom: 'radflgfac', largeur: 91},
                {nom: 'radflgetacpt', largeur: 91},
                {nom: 'radadrcou', largeur: 240},
                {nom: 'radnumtel', largeur: 100}
            ],
            colonnesCachees: [
                'radcleint'
            ]
        };
        // Contacts
        vm.fcnMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_CONTACTS',
                    cp0073Route.NAME,
                    (data:any) => ({
                        id: data.fcncleint,
                        refcleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: 'launch',
                        fonction:'BOUOUV',
                        onClose() {
                            vm.fcnMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    '',
                    cp0073Route.NAME, () => {
                        return {
                            refcleint: vm.monoOccurrence.data.refcleint
                        };
                    },
                    {  onClose() {
                            vm.fcnMultiOccurrence.fetchDataList();
                        } }
                )
            ]),
            bloc: 'fcn',
            fonctions: {
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                pagination: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'fcnnom', largeur: 300},
                {nom: 'fcnnumtel', largeur: 131},
                {nom: 'fcntit', largeur: 131},
                {nom: 'fcnadrcou', largeur: 131}
            ],
            colonnesCachees: [
                'fcncleint'
            ]
        };

        // Profil D'achat
        vm.formulaireDac = new Formulaire('LBL_DAC', [
            new FormulaireItem('teccleint'),
            new FormulaireItem('motcleint'),
            new FormulaireItem('iaccleint'),
            new FormulaireItem('vecsysmodtra'),
            new FormulaireItem('vecsystercom'),
            new FormulaireItem('foudes'),
            new FormulaireItem('foumntlimcmd'),
            new FormulaireItem('founbrjrs'),
            new FormulaireItem('fouflgent'),
            new FormulaireItem('vecsysregadm'),
            new FormulaireItem('vaesysdacxml'),
            new FormulaireItemDivider('LBL_CARTE_ACHAT'),
            new FormulaireItem('fouindpaicac'),
            new FormulaireItem('foumntmaxcac'),
            new FormulaireItem('teccleintdef'),
            new FormulaireItem('foccleintdef'),
            new FormulaireItem('fcacleintdef'),
            new FormulaireItem('t_fcanomdet'),
            new FormulaireItem('founomrel')
        ], {largeurDefaut: 33.33});

        // Nibs
        vm.cfnMultiOccurrenceOptions = {
            bloc: 'cfn',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('nibcleint',{largeur: 50}),
                new FormulaireItem('cfndatdeb',{largeur: 25}),
                new FormulaireItem('cfndatfin',{largeur: 25})
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'nibcod', largeur: 100},
                {nom: 'nibnom', largeur: 260},
                {nom: 'cfndatdeb', largeur: 126},
                {nom: 'cfndatfin', largeur: 126},
                {nom: 'nibdsc', largeur: 300}
            ]
        };

        // Comptes bancaires
        vm.scbMultiOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemEcran('LBL_DNB',
                    mc0133Route.NAME,
                    () => ({
                        id: vm.valeursEcran.dnbcleint,
                        refcleint: vm.monoOccurrence.id
                    }),
                    {
                        fonction:'BTN_INFO',
                        icon: 'account_balance'
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    '',
                    cp0074Route.NAME,
                    (data:any) => ({
                        id: data.scbcleint,
                        refcleint: vm.monoOccurrence.id,
                        vaesysdevcod: vm.monoOccurrence.data.vaesysdevcod,
                        vaesysprocom: 'FOU'
                    }),
                    {
                        fonction:'BOUOUV',
                        icon: 'launch',
                        onClose() {
                            vm.scbMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    '',
                    cp0074Route.NAME, () => {
                        return {
                            refcleint: vm.monoOccurrence.data.refcleint,
                            vaesysdevcod: vm.monoOccurrence.data.vaesysdevcod,
                            vaesysprocom: 'FOU'
                        };
                    },{
                        onClose() {
                            vm.scbMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            bloc: 'scb',
            fonctions: {
                pagination: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'scbcod', largeur: 300},
                {nom: 'scbdsc', largeur: 300},
                {nom: 'scbdateff', largeur: 126}

            ],
            colonnesCachees: [
                'scbcleint'
            ]
        };

        // Catégorie d'achat
        vm.ccaMultiOccurrenceOptions = {
            bloc: 'cca',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('caacleint'),
                new FormulaireItem('ccaflgpri', {default: 0})
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'caacod', largeur: 100},
                {nom: 'caadsc', largeur: 260},
                {nom: 'ccaflgpri', largeur: 91}
            ]
        };

        // Communication
        vm.formulaireCom = new Formulaire('LBL_COMMUNICATION', [
            new FormulaireItem('fouadrcou'),
            new FormulaireItem('adrcoumsg'),
            new FormulaireItem('foudacmsg'),
            new FormulaireItem('fouinsdacmsg')
        ], {
            largeurDefaut: 100,
            menu: new Menu([
                new MenuItemEcran('LBL_MSG_CHEQUE',
                    mc1010Route.NAME,
                    (data: any) => ( {refcleint: data.refcleint}),
                    {
                        icon: 'message',
                        fonction:'BTN_BOUMESS'
                    }
                )
            ])
        });
        vm.menusCrp = {
            t_enecoddsc: [
                new MenuItemForage('LBL_ENE',
                    cr0004Route.NAME,
                    (data: any) => ({
                        id: data.t_enecleint,
                        crpcleint: vm.monoOccurrence.data.crpcleint,
                        ecran:'01-2',
                    }),
                    {
                        icon: 'exit_to_app',
                        fonction:'BOUCAR',
                        hidden: (data: any) => !data.t_enecleint
                    }
                )
            ]
        };

        // Profil CAR
        vm.formulaireCrp = new Formulaire('LBL_PROFIL_CAR', [
            new FormulaireItem('crpcleint'),
            new FormulaireTemplateSlot('infosCrp')
        ], {largeurDefaut: 100});

        // Carte d'achat
        vm.focMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_CARTE',
                    cp0035Route.NAME,
                    (data:any) => ({
                        id: data.foccleint,
                        refcleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: 'launch',
                        fonction:'BOUOUV',
                        onClose() {
                            vm.focMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    '',
                    cp0035Route.NAME, () => {
                        return {
                            refcleint: vm.monoOccurrence.data.refcleint
                        };
                    },
                    {
                        onClose() {
                            vm.focMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            bloc: 'foc',
            fonctions: {
                pagination: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'focnom', largeur: 200},
                {nom: 'intcodnom', largeur: 260},
                {nom: 'focdatexp', largeur: 126},
                {nom: 'focflgstu', largeur: 91}
            ],
            colonnesCachees: [
                'foccleint'
            ]
        };

        // Types de document exclus
        vm.rtxMultiOccurrenceOptions = {
            bloc: 'rtx',
            fonctions: {
                pagination: true,
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('tydcleint')
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'tydcoddsc', largeur: 300}
            ],
            colonnesCachees: [
                'rtxcleint'
            ]
        };

    }
}
