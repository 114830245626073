import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageSO0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0014Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         dateFilter: IFilterDate,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSO0014 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'posnumdoc', largeur: 115}
        ],
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 90},
            {nom: 'tagdscabr', largeur: 262},
            {nom: 'popdatdeb', largeur: 100},
            {nom: 'popdatfin', largeur: 100},
            {nom: 'popcom', largeur: 340},
            {nom: 'tpocod', largeur: 90},
            {nom: 'tpodsc', largeur: 262},
            {nom: 'postitabr', largeur: 262},
            {nom: 'posdatdeb', largeur: 108},
            {nom: 'posdatfin', largeur: 108},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 262}
            ],
        colonnesFixesDroite: [
            {nom: 'popflgact', largeur: 65}
        ],

        formulaire: new Formulaire('LBL_BLC_POP', [
            new FormulaireItem('poscleint'),
            new FormulaireItem('tagcleint', {
                resourceParams: (data: any) => {
                    return {
                        posdatdeb: data.posdatdeb
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('popdatdeb'),
            new FormulaireItem('popdatfin'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('popcom'),
            new FormulaireItem('popflgact',{
                default : 1
            })
        ], {
            largeurDefaut: 33.33
        })
    };
}
