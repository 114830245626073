import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';

interface IPageMr0103 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0103Params {
    geccleint: string;
}

/* @ngInject */
export default function Mr0103Controller($stateParams: IMr0103Params,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    parametresRecherche: IParametresRechercheService,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0103 = this;

    const filtres = [];

    if ($stateParams.geccleint) {
        filtres.push(
            {
                colonne: 'geccleint',
                valeur: $stateParams.geccleint,
                visible: false
            }
        )
    }

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        filtres,
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/mr0103/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        colonnesFixesDroite: [
            { nom: 'gecflgact', largeur: 65 }
        ],
        colonnesFixesGauche: [
            { nom: 'geccod', largeur: 89 },
            { nom: 'gecdsc', largeur: 262 }
        ],
        colonnesVisibles: [
            { nom: 'geccat', largeur: 100 },
            { nom: 'geccodcla', largeur: 89 },
            { nom: 'cvtcod', largeur: 105 },
            { nom: 'cvtdsc', largeur: 262 },
            { nom: 'unccod', largeur: 89 },
            { nom: 'uncdsc', largeur: 262 },
            { nom: 'vaegectypech_dsc', largeur: 197 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('geccod'),
            new FormulaireItem('gecdsc', { largeur: 50 }),
            new FormulaireItem('geccat'),
            new FormulaireItem('geccodcla', { required: true }),
            new FormulaireItem('cvtcleint', { largeur: 50, modifAllow: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unccleint'),
            new FormulaireItem('vaegectypech', { required: true }),
            new FormulaireItem('gecflgact', { default: 1 })
        ], { largeurDefaut: 25 }
        )
    };
}
