import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageGb0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0005Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageGb0005 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'gbpcod', largeur: 10 },
            { nom: 'gbpdsc', largeur: 70 },
            { nom: 'gbpflgdef', largeur: 10 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gbpcod', { largeur: 10, maxlength: 4 }),
            new FormulaireItem('gbpdsc', { largeur: 60 }),
            new FormulaireItem('gbpflgdef', { largeur: 10 })
        ])
    };
}
