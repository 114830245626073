import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageTf0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0010Controller(Formulaire: IFormulaireClass,
    $stateParams: any,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTf0010 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'frfcleint', valeur: $stateParams.reference, visible: false }
        ],
        colonnesFixesGauche: [
            { nom: 'folcod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'foldesc', largeur: 262 },
            { nom: 'folann', largeur: 100 },
            { nom: 'rfecod', largeur: 184 },
            { nom: 'rfenomabr', largeur: 265 },
            { nom: 'frfdscweb', largeur: 265 },
            { nom: 'unpcod', largeur: 120 },
            { nom: 'unpdsc', largeur: 265 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('folcleint', { largeur: 33.33, required: true }),
            new FormulaireItem('rfecleint', { largeur: 33.33, required: true }),
            new FormulaireItem('frfdscweb', { largeur: 33.33 }),
            new FormulaireItem('unpcleint', { largeur: 33.33, required: true })
        ]),

        fonctions: {
            rechercheCollapsable: false
        }
    };
}
