import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0080 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0080Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0080 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'gcrcod', largeur: 150},
            {nom: 'gcrdsc', largeur: 357},
            {nom: 'gcrcom', largeur: 357},
            {nom: 'gcrseqaff', largeur: 115},
            {nom: 'gcrflgact', largeur: 80}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gcrcod', {required: true}),
            new FormulaireItem('gcrdsc', {required: true}),
            new FormulaireItem('gcrcom'),
            new FormulaireItem('gcrseqaff'),
            new FormulaireItem('gcrflgact', {default: 1})
        ]),
        fonctions: Object.assign({
            edition: true,
            nouveau: true

        })
    };
}
