import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";


interface IPageMc0043 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0043Controller(mc0170Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu:IMenuClass) {
    const vm: IPageMc0043 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0170Route.NAME),
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mc0170Route.NAME,
        colonnesVisibles: [
            {nom: 'reicod'   , largeur: 120},
            {nom: 'reidsc'   , largeur: 400},
            {nom: 'reidatdeb'  , largeur: 130},
            {nom: 'reidatfin' , largeur: 130}
        ]
    };
}

