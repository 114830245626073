import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMr2006 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
    ed2MultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function mr2006Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    rr0076Route: IRoute,
    rr0074Route: IRoute,
    $stateParams: IStateParamsService,
    $state: IStateService,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr2006 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    // Bouton de recherche
    vm.menuItemRecherche = new MenuItemAction(
        'LBL_BTN_RECHERCHE',
        'BOUREC',
        (data: any) => {
            return {
                stamnetab: data.stamnetab,
                cleintref: data.et2cleintref,
                et2cleint: data.et2cleint
            }
        },
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_RECHERCHE',
                lblConfirm: 'G_LBL_BTN_CONFIRMER',
                largeurFormulaire: 25,
                formulaire: new Formulaire([
                    new FormulaireItem('codperm', {
                        required: true,
                        largeur: 100
                    }),
                    new FormulaireItem('datdem', {
                        required: true,
                        default: (data: any) => data.datder,
                        largeur: 100
                    })
                ])
            },
            hidden: (data: any) => ((data.dmecleint) || ((data.demcleint) && (data.lemcleint))),
            successAction: () => vm.monoOccurrence.fetchData()
        }
    );

    // Bouton de mise à jour
    vm.menuItemMajNas = new MenuItemAction(
        'LBL_BTN_MAJNAS',
        'BOUMAJNAS',
        () => { return {} },
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_MAJNAS',
                lblConfirm: 'G_LBL_BTN_CONFIRMER',
                largeurFormulaire: 25,
                formulaire: new Formulaire([
                    new FormulaireItem('nas', {
                        default: (data: any) => vm.monoOccurrence.data.et2nas,
                        largeur: 100
                    })
                ])
            },
            hidden: (data: any) => data.et2affbtn !== 'NAS',
            successAction: () => vm.monoOccurrence.fetchData()
        }
    );

    // Bouton Créé demande employé
    vm.menuItemVerif = new MenuItemAction(
        'LBL_BTN_VERIF',
        'BOUVERIF',
        () => ({}),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            successAction: (data: any) => {
                $state.go(rr0076Route.NAME, {
                    menuId: $stateParams.menuId,
                    id: data.dmecleint,
                    stamnetab: 'DER'
                })
            },
            hidden: (data: any) => data.et2affbtn !== 'EPL' || vm.valeursEcran.flgverif
        }
    );

    // Bouton Modif demande employé
    vm.menuItemVerifModif = new MenuItemAction(
        'LBL_BTN_VERIF_MODIF',
        'BOUVERIF',
        () => ({}),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            successAction: (data: any) => {
                $state.go(rr0076Route.NAME, {
                    menuId: $stateParams.menuId,
                    id: data.dmecleint,
                    stamnetab: 'DER'
                })
            },
            hidden: (data: any) => data.et2affbtn !== 'EPL' || vm.valeursEcran.flgverif
        }
    );

    // Bouton Ok
    vm.menuItemOk = new MenuItemAction(
        'LBL_BTN_OK',
        'BOUOK',
        () => ({}),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            hidden: (data: any) => data.et2affbtn !== 'OK',
            successAction: (data: any) => {
                $state.go(rr0074Route.NAME, {
                    menuId: $stateParams.menuId,
                    id: vm.monoOccurrence.data.trncleintref
                })
            }
        }
    );

    // Création Employé
    vm.menuItemEpl = new MenuItemAction(
        'LBL_BTN_EPL',
        'BOUEPL',
        (data: any) => ({
            et2cleint: data.et2cleint
        }),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            successAction: (data: any) => {
                $state.go(rr0074Route.NAME, {
                    menuId: $stateParams.menuId,
                    id: vm.monoOccurrence.data.trncleintref
                })
            },
            hidden: (data: any) => data.et2affbtn !== 'EPL' || data.demcleint,
            disabled: (data: any) => data.et2affbtn === 'EPL' && vm.valeursEcran.flgverif
        }
    );

    // Création lien d'emploi
    vm.menuItemLem = new MenuItemAction(
        'LBL_BTN_LEM',
        'BOULEM',
        () => ({}),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            successAction: () => vm.monoOccurrence.fetchData(),
            hidden: (data: any) => data.et2affbtn !== 'LEM' || data.lemcleint
        }
    );

    // MAJ du lien d'emploi
    vm.menuItemMajLem = new MenuItemAction(
        'LBL_BTN_MAJLEM',
        'BOUMAJLEM',
        () => ({}),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            successAction: () => vm.monoOccurrence.fetchData(),
            hidden: (data: any) => data.et2affbtn !== 'MLEM'
        }
    );

    // MAJ de l'employé
    vm.menuItemMajEpl = new MenuItemAction(
        'LBL_BTN_MAJEPL',
        'BOUMAJEPL',
        () => ({}),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            successAction: () => vm.monoOccurrence.fetchData(),
            hidden: (data: any) => data.et2affbtn !== 'MEPL'
        }
    );

    // Recherche employé
    vm.menuItemRecEpl = new MenuItemAction(
        'LBL_BTN_RECHEPL',
        'BOURECHEPL',
        () => ({}),
        {
            iconButton: false,
            saveFirst: true,
            class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_MAJNAS',
                lblConfirm: 'G_LBL_BTN_CONFIRMER',
                largeurFormulaire: 25,
                formulaire: new Formulaire([
                    new FormulaireItem('nas', {
                        required: true,
                        largeur: 100
                    })
                ])
            },
            successAction: () => vm.monoOccurrence.fetchData(),
            hidden: (data: any) => data.et2affbtn !== 'REPL'
        }
    );

    vm.monoOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            exportation: false
        },
        formulaire: new Formulaire('LBL_BLC_ET2', [
            new FormulaireItem('eplnummat'),
            new FormulaireItem('et2nometu'),
            new FormulaireItem('et2prnetu'),
            new FormulaireItem('et2nas'),
            new FormulaireItem('et2adrcou', { largeur: 66.66 }),
            new FormulaireItem('et2flgetr'),
            new FormulaireItem('et2datexp'),
            new FormulaireItem('et2codpern', { largeur: 50 })
        ], { largeurDefaut: 33.33 })
    };

    vm.ed2MultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'ed2codprg', largeur: 120 },
            { nom: 'ed2nomprg', largeur: 250 },
            { nom: 'ed2stu', largeur: 160 },
            { nom: 'ed2datstu', largeur: 126 },
            { nom: 'ed2cyc', largeur: 135 },
            { nom: 'ed2reg', largeur: 125 },
            { nom: 'ed2datdip', largeur: 160 },
            { nom: 'ed2credit', largeur: 160 }
        ]
    };
}