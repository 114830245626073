import { IComponentController } from "angular";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0071 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    conMultiOccurrenceOptions: IMultiOccurrenceOptions;
    etcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dacMultiOccurrenceOptions: IMultiOccurrenceOptions;
    reqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pdfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ecbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    apoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    criMultiOccurrenceOptions: IMultiOccurrenceOptions;
    conMultiOccurrence: IMultiOccurrence;
    etcMultiOccurrence: IMultiOccurrence;
    dacMultiOccurrence: IMultiOccurrence;
    reqMultiOccurrence: IMultiOccurrence;
    pdfMultiOccurrence: IMultiOccurrence;
    ecbMultiOccurrence: IMultiOccurrence;
    apoMultiOccurrence: IMultiOccurrence;
    criMultiOccurrence: IMultiOccurrence;
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Mc0071Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    ac1000Route: IRoute,
    cp1011Route: IRoute,
    db0005Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMc0071 = this;

    vm.monoOccurrenceOptions = {
        noId: true
    }

    const forageEtc = new MenuItemEcran(
        'LBL_BTN_HISTO_IMP',
        ac1000Route.NAME,
        (rowData: any) => ({
            etccleint: rowData.etccleint
        }),
        {
            icon: 'launch',
            iconButton: true,
            fonction: 'BOUHISIMP'
        }
    );

    const forageCon = new MenuItemEcran(
        'LBL_BTN_HISTO_CON',
        cp1011Route.NAME,
        (rowData: any) => ({
            concleint: rowData.concleint
        }),
        {
            icon: 'launch',
            iconButton: true,
            fonction: 'BOUHISCON'
        }
    );


    const criteresSuggeres = new Formulaire([
        new FormulaireItem('vaeetcstucri', { largeur: 25 }),
        new FormulaireItem('numdoccri', { largeur: 25 }),
        new FormulaireItem('etdcleintcri', { largeur: 25 }),
        new FormulaireItem('mntmincri', { largeur: 25 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('flgrescdcri', { largeur: 25 }),
        new FormulaireItem('flgrescacri', { largeur: 25 }),
        new FormulaireItem('flgincinacri', { largeur: 25 }),
        new FormulaireItem('pdfcodcri', { largeur: 25 }),
    ], { largeurDefaut: 25 })

    vm.criMultiOccurrenceOptions = {
        srccod:"mc0071",//important afin d'obtenir les datatypes et les libelles
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'vaeetcstucri', largeur: 25 },
            { nom: 'numdoccri', largeur: 25 },
            { nom: 'etdcleintcri', largeur: 25 },
            { nom: 'mntmincri', largeur: 25 },
            { nom: 'flgrescdcri', largeur: 25 },
            { nom: 'flgrescacri', largeur: 25 },
            { nom: 'flgincinacri', largeur: 25 },
            { nom: 'pdfcodcri', largeur: 25 },
        ]
    }

    vm.conMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            criteresSuggeresVisibles: true,
            effaceCriteres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'tydcod', largeur: 100 },
            { nom: 'connumdoc', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'vaeconstu_dsc', largeur: 180, menu: [forageCon] },
            { nom: 'connumresca', largeur: 150 },
            { nom: 'connumrescd', largeur: 150 },
            { nom: 'refcod', largeur: 140 },
            { nom: 'refnom', largeur: 250 },
            { nom: 'condatdeb', largeur: 126 },
            { nom: 'condatfin', largeur: 126 },
            { nom: 'conmnt', largeur: 100 },
            { nom: 'vaesysdevcod', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeconstu', { required: true })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: {
                        nom: 'selection',
                        lblConfirmationTitre: 'G_LBL_BTN_SELECTION',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('numrescanew', { largeur: 100, maxlength: 20 }),
                            new FormulaireItem('numrescdnew', { largeur: 100, maxlength: 20 })
                        ])
                    },
                    successAction: () => {
                        vm.conMultiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };

    vm.etcMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'etcnumdoc', largeur: 130 },
            { nom: 'vaeetctyp_dsc', largeur: 150 }
        ],
        colonnesVisibles: [
            { nom: 'vaeetcstu_dsc', largeur: 180, menu: [forageEtc] },
            { nom: 'etcnumresca', largeur: 150 },
            { nom: 'etcnumrescd', largeur: 150 },
            { nom: 'refcod', largeur: 140 },
            { nom: 'refnom', largeur: 250 },
            { nom: 'etcdatdeb', largeur: 126 },
            { nom: 'etcdatfin', largeur: 126 },
            { nom: 'etcmnt', largeur: 100 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeetcstu', { required: true }),
            new FormulaireItem('vaeetctyp', { required: true })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: {
                        nom: 'selection',
                        lblConfirmationTitre: 'G_LBL_BTN_SELECTION',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('numrescanew', { largeur: 100, maxlength: 20 }),
                            new FormulaireItem('numrescdnew', { largeur: 100, maxlength: 20 })
                        ])
                    },
                    successAction: () => {
                        vm.etcMultiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };

    vm.dacMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'tydcod', largeur: 120 },
            { nom: 'dacnumdoc', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'dacnumresca', largeur: 150 },
            { nom: 'dacnumrescd', largeur: 150 },
            { nom: 'refcod', largeur: 140 },
            { nom: 'refnom', largeur: 250 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'dacdat', largeur: 126 },
            { nom: 'dacmnt', largeur: 100 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 250 }
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: {
                        nom: 'selection',
                        lblConfirmationTitre: 'G_LBL_BTN_SELECTION',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('numrescanew', { largeur: 100, maxlength: 20 }),
                            new FormulaireItem('numrescdnew', { largeur: 100, maxlength: 20 })
                        ])
                    },
                    successAction: () => {
                        vm.dacMultiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };

    vm.reqMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'tydcod', largeur: 120 },
            { nom: 'reqnumdoc', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'reqnumresca', largeur: 150 },
            { nom: 'reqnumrescd', largeur: 150 },
            { nom: 'refcod', largeur: 140 },
            { nom: 'refnom', largeur: 250 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'reqdat', largeur: 126 },
            { nom: 'reqmnt', largeur: 100 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 250 }
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: {
                        nom: 'selection',
                        lblConfirmationTitre: 'G_LBL_BTN_SELECTION',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('numrescanew', { largeur: 100, maxlength: 20 }),
                            new FormulaireItem('numrescdnew', { largeur: 100, maxlength: 20 })
                        ])
                    },
                    successAction: () => {
                        vm.reqMultiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };

    vm.pdfMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'tyfdsc', largeur: 120 },
            { nom: 'pdfcod', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'pdfnumresca', largeur: 150 },
            { nom: 'pdfnumrescd', largeur: 150 },
            { nom: 'orgcod', largeur: 140 },
            { nom: 'orgnom', largeur: 250 },
            { nom: 'prjcod', largeur: 140 },
            { nom: 'prjdscabr', largeur: 250 },
            { nom: 'prucod', largeur: 140 },
            { nom: 'prudscl01', largeur: 250 }
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: {
                        nom: 'selection',
                        lblConfirmationTitre: 'G_LBL_BTN_SELECTION',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('numrescanew', { largeur: 100, maxlength: 20 }),
                            new FormulaireItem('numrescdnew', { largeur: 100, maxlength: 20 })
                        ])
                    },
                    successAction: () => {
                        vm.pdfMultiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };

    vm.ecbMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        navigatePage: db0005Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                ecbcleint: rowData.ecbcleint,
                doccleint: rowData.doccleint
            };
        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'tydcod', largeur: 120 },
            { nom: 'ecbnumdoc', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'ecbnumresca', largeur: 150 },
            { nom: 'ecbnumrescd', largeur: 150 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'ecbdat', largeur: 126 },
            { nom: 'ecbmnttot', largeur: 100 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 250 }
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: {
                        nom: 'selection',
                        lblConfirmationTitre: 'G_LBL_BTN_SELECTION',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('numrescanew', { largeur: 100, maxlength: 20 }),
                            new FormulaireItem('numrescdnew', { largeur: 100, maxlength: 20 })
                        ])
                    },
                    successAction: () => {
                        vm.ecbMultiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };

    vm.apoMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 120 },
            { nom: 'aponumdoc', largeur: 130 },
            { nom: 'apodatfer', largeur: 126 },
            { nom: 'apodsc', largeur: 250 },
            { nom: 'aponumresca', largeur: 150 },
            { nom: 'aponumrescd', largeur: 150 },
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: {
                        nom: 'selection',
                        lblConfirmationTitre: 'G_LBL_BTN_SELECTION',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('numrescanew', { largeur: 100, maxlength: 20 }),
                            new FormulaireItem('numrescdnew', { largeur: 100, maxlength: 20 })
                        ])
                    },
                    successAction: () => {
                        vm.apoMultiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };

}
