import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';

interface IPageAC0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    dahMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0032Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Pagination: IPaginationClass,
                                         $stateParams: IStateParamsService,
                                         cp2010Route: IRoute) {
    const vm: IPageAC0032 = this;

    // @TODO ajouter les routes de forage quand les écrans seront faits
    const ac0010Route = {NAME: 'secure.accueil'};
    const ac0037Route = {NAME: 'secure.accueil'};
    const ac0070Route = {NAME: 'secure.accueil'};
    const in0043Route = {NAME: 'secure.accueil'};
    const pd0006Route = {NAME: 'secure.accueil'};
    const rc0001Route = {NAME: 'secure.accueil'};
    const rc0002Route = {NAME: 'secure.accueil'};
    const rc0006Route = {NAME: 'secure.accueil'};
    const rc0007Route = {NAME: 'secure.accueil'};

    // Forage du détail
    const forageDetail = new MenuItemForage(
        '',
        (rowData: any) => getForage(rowData),
        (rowData: any) => ({
            id: (rowData.vaedahtyp === 'REQ' ? rowData.t_clereq : rowData.syscleintref)
        }),
        {
            hidden: (rowData: any) => !rowData.t_numdoc,
            fonction: 'BOUDOC'
        }
    );

    vm.dahMultiOccurrenceOptions = {
        bloc: 'dah',
        fonctions: {
            suiviModification: false
        },
        colonnesCachees: [
            'syscleintref',
            'vaedahtyp',
            't_clereq'
        ],
        colonnesVisibles: [
            {nom: 't_datcre', largeur: 160},
            {nom: 't_type', largeur: 141},
            {nom: 't_numdoc', largeur: 150, menu: [forageDetail]},
            {nom: 'dahqte', largeur: 100},
            {nom: 'dahprxuni', largeur: 126},
            {nom: 'dahcom', largeur: 340}
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        })
    };

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                },
                required: false,
                readonly: () => ($stateParams.daccleint)
            }),
            new FormulaireItem('t_typdoc', {
                hidden: true
            }),
            new FormulaireItem('t_type', {
                readonly: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('daccleint', {
                required: true,
                default: $stateParams.daccleint,
                readonly: () => ($stateParams.daccleint)
            }),
            new FormulaireItem('t_techn', {
                readonly: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('sysboncmd', {
                required: false,
                readonly: () => ($stateParams.daccleint)
            }),
            new FormulaireItem('dacdsc', {
                readonly: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgindfer', {
                default: 0,
                required: true,
                readonly: () => ($stateParams.daccleint)
            })
        ]),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: !$stateParams.daccleint,
            criteresSuggeresVisibles: !$stateParams.daccleint
        },
        colonnesFixesGauche: [
            {nom: 'dailigcar', largeur: 90}
        ],
        colonnesVisibles: [
            {nom: 'dainumcat',   largeur: 136},
            {nom: 'procod',      largeur: 110, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_typdoc !== 2 },
            {nom: 'daidsc',      largeur: 200 },
            {nom: 'daiseqave',   largeur: 90  },
            {nom: 'taxcod',      largeur: 70  },
            {nom: 'daiprxuni',   largeur: 126 }
        ],
        colonnesFixesDroite: [
            {nom: 'dahqtecmd',   largeur: 126 },
            {nom: 'dahqterec',   largeur: 126 },
            {nom: 'dahqtefac',   largeur: 126 },
            {nom: 'dahqteret',   largeur: 126 }
        ],
        multiOccurrenceOptionsEnfant: vm.dahMultiOccurrenceOptions
    };


    // Fonction d'identification de l'écran pour le forage
    function getForage(data: any): string {

        if (!data.t_numdoc) {
            return null;
        }

        if (data.vaedahtyp === 'REQ') {
            return cp2010Route.NAME;
        } else if (data.ecrcod === 'AC0010-02') {
            return ac0010Route.NAME;
        } else if (data.ecrcod === 'AC0010-00') {
            return ac0010Route.NAME;
        } else if (data.ecrcod === 'AC0037-00') {
            return ac0037Route.NAME;
        } else if (data.ecrcod === 'AC0070-00') {
            return ac0070Route.NAME;
        } else if (data.ecrcod === 'AC0070-01') {
            return ac0070Route.NAME;
        } else if (data.ecrcod === 'IN0043-00') {
            return in0043Route.NAME;
        } else if (data.ecrcod === 'PD0006-00') {
            return pd0006Route.NAME;
        } else if (data.ecrcod === 'RC0001-00') {
            return rc0001Route.NAME;
        } else if (data.ecrcod === 'RC0002-00') {
            return rc0002Route.NAME;
        } else if (data.ecrcod === 'RC0006-00') {
            return rc0006Route.NAME;
        } else if (data.ecrcod === 'RC0007-00') {
            return rc0007Route.NAME;
        }

        return null;
    }

}
