import { IComponentController } from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
interface IPageAc0051 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0051Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    ac0052Route: IRoute) {
    const vm: IPageAc0051 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: ac0052Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(ac0052Route.NAME)
        ]),
        colonnesVisibles: [
            { nom: 'aclcod', largeur: 110 },
            { nom: 'acldsc', largeur: 300 },
            { nom: 'vecsyslng', largeur: 110 },
            { nom: 'vecsyslng_dsc', largeur: 200 },
            { nom: 'vectyputl', largeur: 110 },
            { nom: 'vectyputl_dsc', largeur: 200 }
        ]
    };
}
