import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";

interface IPageRr0551 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
interface IRr0551Params {
    socmnt: string;
    carcleint: string;
    demcleint: string;
}

/* @ngInject */
export default function Rr0551Controller($stateParams: IRr0551Params,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0551 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'socdattrs', largeur: 100},
            {nom: 'socnumref', largeur: 100},
            {nom: 'vaesoctyptrs', largeur: 100},
            {nom: 'socmnt', largeur: 100},
            {nom: 'ppacod', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'dalnumdoc', largeur: 150},
            {nom: 'vaesysmnetab', largeur: 150},
            {nom: 'soccom', largeur: 200},
            {nom: 'pamnumdoc', largeur: 100},
            {nom: 'pamnumdocseq', largeur: 100}
        ],

        filtresParametres: [
            {colonne: 'demcleint'  , affichage: true},
            {colonne: 'carcleint'  , affichage: true},
            {colonne: 'socmnt'  , affichage: true}

        ]

    };

}
