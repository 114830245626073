import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';


interface IPageTh0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0019Controller(parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite)
{
    const vm: IPageTh0019 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'rghcod', largeur: 180},
            {nom: 'rghdsc', largeur: 230},
            {nom: 'pxtdatdeb', largeur: 126},
            {nom: 'pxtdatfin', largeur: 126},
            {nom: 'pxtnumdoc', largeur: 120},
            {nom: 'ttccod', largeur: 120},
            {nom: 'ttcdsc', largeur: 140},
            {nom: 'ecldsceta', largeur: 120},
            {nom: 'pxtmespor', largeur: 160},
            {nom: 'pxtflgfinref', largeur: 100},
            {nom: 'trnflgstaref', largeur: 100},
            {nom: 'trnflgstafin', largeur: 100},
            {nom: 'trnflgstafer', largeur: 100},
            {nom: 'trnflgstaann', largeur: 100}
        ]
    };
}
