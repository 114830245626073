import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCb0002 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Cb0002Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    cb0049Route: IRoute,
    cb0050Route: IRoute,
    cb0051Route: IRoute,
    cb0003Route: IRoute,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemSelection: IMenuItemSelectionClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    $state: IStateService
) {
    const vm: IPageCb0002 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false,
            effaceCriteres: !($stateParams.prucleint)
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('vaesysnatctb', {
                    default: $stateParams.vaesysnatctb ? $stateParams.vaesysnatctb : vm.valeursEcran.defvaesysnatctb,
                    largeur: 50,
                    required: true,
                    ignoreEtat: !!$stateParams.vaesysnatctb
                }),
                new FormulaireItem('indcleint', {
                    largeur: 50,
                    required: true
                }),
                new FormulaireItem('vecprucatpro', {
                    largeur: 50
                }),
                new FormulaireItem('prucleint', {
                    default: $stateParams.prucleint,
                    required: false,
                    largeur: 50,
                    ignoreEtat: !!$stateParams.prucleint,
                    disabled: $stateParams.prucleint,
                    resourceParams: (data: any) => {
                        return {
                            vaesysnatctb: data.vaesysnatctb
                        };
                    }
                }),
                new FormulaireItem('intcleintrsp', {
                    largeur: 50,
                    required: false
                }),
                new FormulaireItem('org', {
                    largeur: 50
                }),
                new FormulaireItem('cifcodselsup', {
                    largeur: 50
                }),
                new FormulaireItem('unacodselsup', {
                    largeur: 50
                }),
                new FormulaireItem('pruflg', {
                    largeur: 33,
                    default: 2
                }),
                new FormulaireItem('vaeprutypcptbdg', {
                    largeur: 33,
                    required: false,
                    default: 'T'
                }),
                new FormulaireItem('vaeprutypbdg', {
                    largeur: 33,
                    required: false,
                    default: 'A'
                }),
                new FormulaireItem('annfindeb'), {
                    largeur: 33
                }
            ],
            { largeurDefaut: 33 }
        ),
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_TRANSACTIONS', 'BOUTRA', {
                selection: {
                    nom: 'selection',
                    icon: 'assessment'
                },
                successAction: (retour: any) => {
                    $state.go(cb0051Route.NAME, {
                        pruseq: retour.pruseq,
                        vaesysnivappctr: vm.multiOccurrence.etatSelected.criteresSuggeresData.vaesysnatctb,
                        t_prucleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.prucleint,
                        annfindeb: vm.multiOccurrence.etatSelected.criteresSuggeresData.annfindeb,
                        indcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.indcleint,
                        vaeprutypbdg: vm.multiOccurrence.etatSelected.criteresSuggeresData.vaeprutypbdg,
                        menuId: $stateParams.menuId
                    });
                }
            }),
            new MenuItemSelection('LBL_BTN_BLOCS', 'BOUBLC', {
                selection: {
                    nom: 'selection'
                },
                successAction: (retour: any) => {
                    $state.go(cb0003Route.NAME, {
                        pruseq: retour.pruseq,
                        vaesysnatctb: vm.multiOccurrence.etatSelected.criteresSuggeresData.vaesysnatctb,
                        indcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.indcleint,
                        vaeprutypbdg: vm.multiOccurrence.etatSelected.criteresSuggeresData.vaeprutypbdg,
                        annfindeb: vm.multiOccurrence.etatSelected.criteresSuggeresData.annfindeb,
                        menuId: $stateParams.menuId
                    });
                 }
            })/*,
            new MenuItemSelection('LBL_BTN_COMPTE', 'BOUCPT', {
                selection: {
                    nom: 'selection'
                },
                successAction: (retour: any) => {
                    const forageCb0004 = new MenuItemEcran('', cb0004Route.NAME, () => ({}));
                }
            })*/
        ]),
        colonnesFixesGauche: [{ nom: 'prucod', largeur: 90, hidden: () => $stateParams.prucleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.prucleint }],
        colonnesFixesDroite: [
            {
                nom: 'dispo',
                largeur: 180,
                total: true,
                menu: [
                    new MenuItemEcran(
                        'LBL_CUMULATIF',
                        cb0049Route.NAME,
                        (rowData: any) => ({
                            id: rowData.prucleint,
                            vaesysnatctb: rowData.vaesysnatctb,
                            annfindeb: vm.multiOccurrence.etatSelected.criteresSuggeresData.annfindeb,
                            indcleint: rowData.indcleint
                        }),
                        {
                            fonction: 'CUMULATIF',
                            icon: 'launch',
                            iconButton: true,
                            disabled: (rowData: any) => !rowData.dispo
                        }
                    )
                ]
            }
        ],
        colonnesVisibles: [
            { nom: 'prudscl01', largeur: 280, hidden: () => $stateParams.prucleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.prucleint },
            { nom: 'vaesysnatctb_dsc', largeur: 170 },
            { nom: 'imppos', largeur: 126, total: true },
            { nom: 'impneg', largeur: 140, total: true },
            {
                nom: 'cumul',
                largeur: 142,
                menu: [
                    new MenuItemForage(
                        'LBL_RESERVE',
                        cb0050Route.NAME,
                        (rowData: any) => ({
                            id: rowData.prucleint,
                            prucleint: rowData.prucleint,
                            vaesysnatctb: rowData.vaesysnatctb,
                            annfindeb: vm.multiOccurrence.etatSelected.criteresSuggeresData.annfindeb,
                            menuId: $stateParams.menuId
                        }),
                        {
                            fonction: 'RESERVES',
                            icon: 'launch',
                            iconButton: true,
                            disabled: (rowData: any) => rowData.cumul === null
                        }
                    )
                ]
            },
            { nom: 'vaeprutypcptbdg_dsc', largeur: 150 },
            { nom: 'vaeprutypbdg_dsc', largeur: 150 },
            { nom: 'pruprcdepbdg', largeur: 150 },
            { nom: 'prumntdepbdg', largeur: 156 },
            { nom: 'vecprucatpro_dsc', largeur: 178 },
            { nom: 'prudatdeb', largeur: 120 },
            { nom: 'prudatfin', largeur: 120 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 170 },
            { nom: 'cifcod', largeur: 164 },
            { nom: 'cifdsc', largeur: 280 },
            { nom: 'unacod', largeur: 138 },
            { nom: 'unadsc', largeur: 305 }
        ],
        colonnesCachees: ['vaesysnatctb', 'indcleint', 'prucleint']
    };
}
