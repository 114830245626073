import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";

interface IPageMc2001 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireSst: IFormulaire;
    formulaireCalcul: IFormulaire;
    usaimp: IMenuItem;
    stgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sslMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc2001Controller(mc2003Route: IRoute,
                                         mc2004Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2001 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        
        vm.formulaireSst = new Formulaire('LBL_BLC_SST', [
            new FormulaireItem('sstcod'),
            new FormulaireItem('sstdscabr'),
            new FormulaireItem('sstdsc'),
            new FormulaireItem('sstflgact', {default: 1}),
            new FormulaireItem('sstdatdeb'),
            new FormulaireItem('sstdatfin')
        ]);

        vm.stgMultiOccurrenceOptions = {
            navigatePage: (rowData: any) => (rowData.vaestgtyp === 'EQU' ? mc2004Route.NAME : mc2003Route.NAME),
            navigateParams: (rowData) => rowData.vaestgtyp === 'EQU' ? {} : {vaestgtyp: rowData.vaestgtyp},
            colonnesCachees:['vaestgtyp'],
            colonnesVisibles: [
                {nom: 'stgord'        , largeur: 140},
                {nom: 'stgnom'        , largeur: 230},
                {nom: 'vaestgtyp_dsc' , largeur: 180},
                {nom: 'stgdatdeb'     , largeur: 130},
                {nom: 'stgdatfin'     , largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stgord'),
                new FormulaireItem('stgnom'),
                new FormulaireItem('vaestgtyp', { modifAllow: false }),
                new FormulaireItem('stgdatdeb'),
                new FormulaireItem('stgdatfin')
            ], {largeurDefaut: 33})
        };

        vm.sslMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tydcod'        , largeur: 180},
                {nom: 'tyddsc'        , largeur: 260},
                {nom: 'sslflgren'     , largeur: 200}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('sslcleintref'),
                new FormulaireItem('sslflgren')
            ], {largeurDefaut: 33})
        };

    }
}
