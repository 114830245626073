import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageRr0144 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0144Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0144 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 185},
            {nom: 'aftcod'   , largeur: 130},
            {nom: 'tsadatvig', largeur: 126},
            {nom: 'echcod'   , largeur: 100},
            {nom: 'tsasal'   , largeur: 120},
            {nom: 'unccod'   , largeur: 140},
            {nom: 'echdsc'   , largeur: 180},
            {nom: 'lemcod'   , largeur: 140},
            {nom: 'urgcod'   , largeur: 200},
            {nom: 'urgdsc'   , largeur: 200},
            {nom: 'strcod'   , largeur: 140},
            {nom: 'strdsc'   , largeur: 200}
        ]
    };
}
