import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageRR0531 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pewMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dmnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tycMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tdmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    escMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eliMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rolMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pedMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr051Controller(FormulaireItem: IFormulaireItemClass, Formulaire: IFormulaireClass) {
    const vm: IPageRR0531 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = { noId: true };

        vm.pewMultiOccurrenceOptions = {
            bloc: 'pew',
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint'),
                new FormulaireItem('strcleint'),
                new FormulaireItem('bqecleint')
            ]),
            colonnesVisibles: [
                { nom: 'urgcod', largeur: 170 },
                { nom: 'urgdscabr', largeur: 200 },
                { nom: 'strcod', largeur: 155 },
                { nom: 'strdsc', largeur: 200 },
                { nom: 'bqecod', largeur: 180 },
                { nom: 'bqedscabr', largeur: 200 }
            ]
        };
        vm.dmnMultiOccurrenceOptions = {
            bloc: 'dmn',
            formulaire: new Formulaire([
                new FormulaireItem('peqcleintref')
            ]),
            colonnesVisibles: [
                { nom: 'dmncod', largeur: 150 },
                { nom: 'dmndsc', largeur: 200 }
            ]
        };
        vm.tycMultiOccurrenceOptions = {
            bloc: 'tyc',
            formulaire: new Formulaire([
                new FormulaireItem('peqcleintref')
            ]),
            colonnesVisibles: [
                { nom: 'tyccod', largeur: 150 },
                { nom: 'tycdsc', largeur: 200 }
            ]
        };
        vm.tdmMultiOccurrenceOptions = {
            bloc: 'tdm',
            formulaire: new Formulaire([
                new FormulaireItem('peqcleintref')
            ]),
            colonnesVisibles: [
                { nom: 'tdmcod', largeur: 200 },
                { nom: 'tdmdsc', largeur: 240 }
            ]
        };
        vm.escMultiOccurrenceOptions = {
            bloc: 'esc',
            formulaire: new Formulaire([
                new FormulaireItem('peqcleintref')
            ]),
            colonnesVisibles: [
                { nom: 'escseq', largeur: 180 },
                { nom: 'esclib', largeur: 240 }
            ]
        };
        vm.eliMultiOccurrenceOptions = {
            bloc: 'eli',
            formulaire: new Formulaire([
                new FormulaireItem('peqcleintref')
            ]),
            colonnesVisibles: [
                { nom: 'elicod', largeur: 200 },
                { nom: 'elidsc', largeur: 200 }
            ]
        };
        vm.rolMultiOccurrenceOptions = {
            bloc: 'rol',
            formulaire: new Formulaire([
                new FormulaireItem('peqcleintref')
            ]),
            fonctions: {
                nouveau: () => vm.rolMultiOccurrence && vm.rolMultiOccurrence.dataList && !vm.rolMultiOccurrence.dataList.length
            },
            colonnesVisibles: [
                { nom: 'rolcod', largeur: 200 },
                { nom: 'roldsc', largeur: 200 }
            ]
        };
        vm.pedMultiOccurrenceOptions = {
            bloc: 'ped',
            fonctions: {
                nouveau: false
            },
            formulaire: new Formulaire([
                new FormulaireItem('pedflganc'),
                new FormulaireItem('pedflgrrh'),
                new FormulaireItem('pedflgcom'),
                new FormulaireItem('pedflgsal')
            ]),
            colonnesVisibles: [
                { nom: 'pedflganc', largeur: 180 },
                { nom: 'pedflgrrh', largeur: 180 },
                { nom: 'pedflgcom', largeur: 180 },
                { nom: 'pedflgsal', largeur: 180 }
            ]
        };
    }
}
