import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFilterExDate} from '../../../../../core/filters/ex-date.filter';

interface IPageRr0518 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    patMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface IRr0518Params {
    id: string;
    employe: string
}

/* @ngInject */
export default function Rr0518Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         exDateFilter: IFilterExDate,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $stateParams: IRr0518Params) {
    const vm: IPageRr0518 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {

        colonnesFixesGauche: [
            {nom: 'ppacod', largeur: 80},
            {nom: 'ppacod_cal', largeur: 84},
            {nom: 'ppacod_ori', largeur: 80},
            {nom: 'psadatdeb', largeur: 96},
            {nom: 'psadatfin', largeur: 96},
        ],
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 110},
            {nom: 'tagdsc', largeur: 175},
            {nom: 'psaqte', largeur: 110},
            {nom: 'unccod', largeur: 60},
            {nom: 'psatauhor', largeur: 110},
            {nom: 'psamnt', largeur: 110},
            {nom: 'aftcod', largeur: 110},
            {nom: 'posnumdoc', largeur: 120},
            {nom: 'lemcod', largeur: 110},
            {nom: 'temcod', largeur: 110},
            {nom: 'unocod', largeur: 110},
            {nom: 'dernumdoc', largeur: 150},
            {nom: 'drpnumdoc', largeur: 150},
            {nom: 'psacom', largeur: 175},
            {nom: 'aftdsc', largeur: 175},
            {nom: 'postit', largeur: 175},
            {nom: 'lemdsc', largeur: 175},
            {nom: 'temdsc', largeur: 175},
            {nom: 'unodsc', largeur: 175},
            {nom: 'derdsc', largeur: 175},
            {nom: 'drpdsc', largeur: 175},
            {nom: 'esvcod3', largeur: 110},
            {nom: 'esvcod4', largeur: 110},
            {nom: 'esvcod5', largeur: 110},
            {nom: 'vaesysprvfed_dsc', largeur: 120},
            {nom: 'tpfdsc', largeur: 175},
            {nom: 'pamnumdocoff', largeur: 150},
            {nom: 'usrcodcre', largeur: 150},
            {nom: 'usrnomcre', largeur: 150},
            {nom: 'datcre', largeur: 120},
            {nom: 'usrcodmod', largeur: 150},
            {nom: 'usrnommod', largeur: 150},
            {nom: 'datmod', largeur: 120},
            {nom: 'sutcod', largeur: 120}
        ],
        colonnesCachees: [
            'demcleint',
            'psacleint',
            'padcleint'
        ], 
        fonctions: {
            criteresSuggeresVisibles: !$stateParams.employe,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_demcleint', {
                hidden: !!$stateParams.employe,
                required: true,
                default: $stateParams.employe}),
            new FormulaireItem('t_ppacleintdeb', {
                default: vm.valeursEcran.ppacleintdebdef,
                required: true,
                resourceParams: (data: any) => ({t_demcleint: data.t_demcleint})
            }),
            new FormulaireItem('t_ppacleintfin', {
                default: vm.valeursEcran.ppacleintfindef,
                required: true,
                resourceParams: (data: any) => ({t_demcleint: data.t_demcleint})
            })

        ], {largeurDefaut: 33.33}),
    };

    vm.patMultiOccurrenceOptions = {
        bloc: 'pat',
        colonnesVisibles: [
            {nom: 'tagcod' , largeur: 120},
            {nom: 'tagdscabr' , largeur: 300},
            {nom: 'patqtecal' , largeur: 150},
            {nom: 'patmntcal' , largeur: 150}
        ]
    };

}
