import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageTF0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeurEcran: any;
}
/* @ngInject */
export default function Tf0020Controller(ecranDetails: IEcranDetailsResourcesEntite, Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTF0022 = this;

    vm.valeurEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'efacod', largeur: 135 },
            { nom: 'efanomprn', largeur: 262 }
        ],
        colonnesVisibles: [
            { nom: 'tfhann', largeur: 90 },
            { nom: 'fpncod', largeur: 150 }, //
            { nom: 'fpndsc', largeur: 220 }, //
            { nom: 'vaetypdetdsc', largeur: 80 },
            { nom: 'codref', largeur: 100 },
            { nom: 'dscref', largeur: 205 }, //
            { nom: 'vaetfgepodsc', largeur: 160, hidden: vm.valeurEcran.cieflgrruq === 0 },
            { nom: 'vaetfgmoddsc', largeur: 160, hidden: vm.valeurEcran.cieflgrruq === 0 },
            { nom: 'tfgmntadm', largeur: 155 },
            { nom: 'tfgmnt', largeur: 150 },
            { nom: 'tfgmntemr', largeur: 155 },
            { nom: 'tfgqte', largeur: 126 },
            { nom: 'tfgcom', largeur: 262 },
            { nom: 'tfgflguti', largeur: 75 }
        ],
        colonnesCachees: ['tfgflgruq'],
        formulaire: new Formulaire([
            new FormulaireItem('efacleint', { largeur: 33 }),
            new FormulaireItem('tfhann', { largeur: 33 }),
            new FormulaireItem('fpncleint', { largeur: 33 }),
            new FormulaireItem('vaetypdet', {
                largeur: 33,
                required: true
            }),
            new FormulaireItem('vaetfgepo', {
                largeur: 33,
                required: true
            }),
            new FormulaireItem('vaetfgmod', {
                largeur: 33,
                required: true
            }),
            new FormulaireItem('tfgcleintref', {
                largeur: 33,
                required: true,
                resourceParams: (data: any) => ({ vaetypdet: data.vaetypdet }),
            }),
            new FormulaireItem('tfgmntadm', { largeur: 33 }),
            new FormulaireItem('tfgmnt', { largeur: 33 }),
            new FormulaireItem('tfgmntemr', { largeur: 33 }),
            new FormulaireItem('tfgqte', { largeur: 33 }),
            new FormulaireItem('tfgcom', { largeur: 100, enableCount: true }),
            new FormulaireItem('tfgflguti', { largeur: 33 })
        ])
    };
}
