import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageDO0033 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0033Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageDO0033 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true                        
        },
        criteresSuggeres: new Formulaire([ 
            new FormulaireItem('crityp', {
                default: 'DMP',
                required: true,
                largeur: 60
            })                    
        ]),        
        filtres: [
            ...parametresRecherche(ecranDetails)
        ],
        colonnesFixesGauche: [
            { nom: 'dmpnumdoc', largeur: 250 }
        ],
        colonnesVisibles: [
            {nom: 'dplnumdoc',  largeur: 150, 
                                hidden: ()=> vm.multiOccurrence.etatSelected.criteresSuggeresData.crityp == 'DMP' ||
                                             vm.multiOccurrence.etatSelected.criteresSuggeresData.crityp == 'DRM' },
            {nom: 'drmnumdoc', largeur: 135, 
                                hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.crityp == 'DMP' ||
                                              vm.multiOccurrence.etatSelected.criteresSuggeresData.crityp == 'DPL' },
            {nom: 'datdeb', largeur: 126},
            {nom: 'datfin', largeur: 126},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'aftdatfin', largeur: 140},
            {nom: 'eplnummatsou', largeur: 100},
            {nom: 'eplnomprnsou', largeur: 262},
            {nom: 'bedflgselemp', largeur: 126},
            {nom: 'bedflgcanext', largeur: 126},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'temcod', largeur: 120},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'unocod', largeur: 120},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'comdsc', largeur: 390},
            {nom: 'tbpcod', largeur: 120},
            {nom: 'tbpdsc', largeur: 350},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'dmpnbrhremax', largeur: 180},
            {nom: 'nbrhremoysem', largeur: 180},
            {nom: 'ahtcodhor', largeur: 80},
            {nom: 'dmpqtesem', largeur: 180},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75},
            {nom: 'usrcod', largeur: 110},
            {nom: 'usrnom', largeur: 262}
        ]
    };
}