import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageMc0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0020Controller(mc0095Route: IRoute,
                                         mc0089Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMc0020 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'BTN_CPT_BLOC',
                mc0095Route.NAME,
                (data:any) => ({
                    el3cleint: data.el3cleint
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemEcran(
                'BTN_ADM',
                mc0089Route.NAME,
                (data:any) => ({
                    elgcleintref: data.el3cleint,
                    vaesyselg: 'EL3'
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'el3cod'             , largeur: 120},
            {nom: 'el3dsc'             , largeur: 300},
            {nom: 'el3dscabr'          , largeur: 200},
            {nom: 'el3datdeb'          , largeur: 130},
            {nom: 'el3datfin'          , largeur: 130},
            {nom: 'el3flgaut'          , largeur: 120},
            {nom: 'inddsc'             , largeur: 200},
            {nom: 'el3prcdep'          , largeur: 126},
            {nom: 'el3mntdep'          , largeur: 126},
            {nom: 'vaesysnivappctr_dsc', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('el3cod'),
            new FormulaireItem('el3dsc'),
            new FormulaireItem('el3dscabr'),
            new FormulaireItem('el3datdeb'),
            new FormulaireItem('el3datfin'),
            new FormulaireItem('el3flgaut'),
            new FormulaireItem('indcleint'),
            new FormulaireItemDivider('LBL_DIV_DEP'),
            new FormulaireItem('el3prcdep'),
            new FormulaireItem('el3mntdep'),
            new FormulaireItem('vaesysnivappctr')
        ], {largeurDefaut: 20})
    };
}
