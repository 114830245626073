import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageDB2002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db2002Controller($stateParams: IStateParamsService) {
    const vm: IPageDB2002 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 't_imp', largeur: 240 },
            { nom: 'tydcod', largeur: 120 },
            { nom: 'docaffnumdoc01', largeur: 180 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 180 },
            { nom: 'docaffdsc01', largeur: 240 },
            { nom: 'pecann', largeur: 120 },
            { nom: 'pecper', largeur: 140 },
            { nom: 'drenumref', largeur: 180 },
            { nom: 'categ', largeur: 120 },
            { nom: 'categ_desc', largeur: 180 },
            { nom: 'mntbi', largeur: 140 },
            { nom: 'mntbu', largeur: 130 },
            { nom: 'mntgb', largeur: 130 },
            { nom: 'mntrg', largeur: 130 },
            { nom: 'mntrb', largeur: 130 },
            { nom: 'mntrr', largeur: 130 },
            { nom: 'mntpe', largeur: 130 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 130 },
            { nom: 'mntgs', largeur: 130 },
            //{ nom: 'mntib', largeur: 130 },
            //{ nom: 'mntbb', largeur: 130 },
            { nom: 'mntbr', largeur: 130 },
            { nom: 'mntpb', largeur: 160 },
            //{ nom: 'mntce', largeur: 130 },
            //{ nom: 'mntcr', largeur: 130 },
            //{ nom: 'mntrf', largeur: 130 }
        ],
        colonnesCachees: ['rfocleint'],
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
