import {IComponentController, IAugmentedJQuery, IScope, ITimeoutService} from 'angular';
import {IDefaultsService} from '../../../../core/services/utils/defaults.service';
import {IParametresSecuriteService} from '../../../../core/services/parametres-securite.service';
import {IMenuNavigationItem} from '../../../../core/interfaces/menu-navigation-item.interface';
import {IProfil, IProfilAcces} from '../../../../core/resources/profil.resource';
import {IComposantMonoOccurrenceEcran} from '../../../../core/components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import {IComposantMultiOccurrenceMaitreDetailsEcran} from '../ex-multi-occurrence-maitre-details-ecran/ex-multi-occurrence-maitre-details-ecran.controller';
import {IComposantMultiOccurrenceAnalyse} from '../ex-multi-occurrence-analyse/ex-multi-occurrence-analyse.controller';
import {IMultiOccurrence} from '../../../../core/services/multi-occurrence.service';
import {IEcranContextController} from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IEcranDetailsResourcesEntite} from '../../../../core/resources/ecran-details.resource';
import isMobile from "../../../../core/constants/mobile.constant";

export interface IComposantPanneauPage extends IComponentController {
    opened: boolean;
    ecranContextCtrl: IEcranContextController;
    hasPanneauPage: boolean;
    hasAutresColonnes: boolean;
    hasSommaire: boolean;
    hasMenuEmploye: boolean;
    menusEmploye: Array<IMenuNavigationItem>;
    multiOccurrence: IMultiOccurrence;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrenceAnalyseCtrl: IComposantMultiOccurrenceAnalyse;
    multiOccurrenceMaitreDetailsEcranCtrl: IComposantMultiOccurrenceMaitreDetailsEcran;
    colonnesVisibles: Array<string>;
    indexDefaut: number;
    indexMenuEmploye: number;
}


/* @ngInject */
export default function PanneauPageController($scope: IScope,
                                              $element: IAugmentedJQuery,
                                              $timeout: ITimeoutService,
                                              defaults: IDefaultsService,
                                              profil: IProfil,
                                              ecranDetails: IEcranDetailsResourcesEntite,
                                              parametresSecurite: IParametresSecuriteService) {
    const vm: IComposantPanneauPage = this;

    vm.$onInit = function $onInit() {
        defaults(vm, {
            // Le sommaire ne s'affiche pas dans les modales écrans
            hasSommaire: Boolean(vm.monoOccurrenceEcranCtrl && ecranDetails.ecrcleint === vm.ecranContextCtrl.ecranDetails.ecrcleint),
            hasAutresColonnes: Boolean(vm.multiOccurrenceAnalyseCtrl)
        });

        $scope.$watch(('vm.multiOccurrence.etatSelected.colonnesVisibles'), (colonnesVisibles: Array<string>) => {
            if (colonnesVisibles) {
                vm.colonnesVisibles = colonnesVisibles.filter((col) => !isColonneHidden(col));
                vm.colonnesVisibles= vm.colonnesVisibles.filter((value,index)=> vm.colonnesVisibles.indexOf(value)===index)
            }
        });

        initMenusEmploye();

        vm.hasPanneauPage = vm.hasSommaire || vm.hasAutresColonnes || vm.hasMenuEmploye;
    
        if (!isMobile) {
            $timeout(initResize);
        }
    }

    function initMenusEmploye() {
        const multiOccurrenceComposant = vm.multiOccurrenceAnalyseCtrl || vm.multiOccurrenceMaitreDetailsEcranCtrl;
        const hasCleEmploye = (
            (
                multiOccurrenceComposant &&
                multiOccurrenceComposant.multiOccurrence.schema &&
                (
                    multiOccurrenceComposant.multiOccurrence.schema.demcleint ||
                    multiOccurrenceComposant.multiOccurrence.schema.eplcleint
                )
            ) ||
            (
                vm.monoOccurrenceEcranCtrl &&
                vm.monoOccurrenceEcranCtrl.monoOccurrence.schema && 
                (
                    vm.monoOccurrenceEcranCtrl.monoOccurrence.schema.demcleint ||
                    vm.monoOccurrenceEcranCtrl.monoOccurrence.schema.eplcleint || vm.monoOccurrenceEcranCtrl.monoOccurrence.dataTypes.demcleint
                )
            )
        );

        if (hasCleEmploye) {
            const {pracleint} = parametresSecurite(vm.ecranContextCtrl.stateParams);
            const profilAcces = profil.profilsAcces
                .find((profilAcces: IProfilAcces) => {
                    return (profilAcces.pracleint === pracleint);
                });

            if (profilAcces) {
                vm.menusEmploye = profilAcces.menusEmploye;
            }
        }

        vm.hasMenuEmploye = Boolean(vm.menusEmploye);

        $scope.$watch('::vm.indexMenuEmploye', (value: number) => {
            if (value !== undefined) {
                if (vm.hasMenuEmploye && vm.monoOccurrenceEcranCtrl && vm.ecranContextCtrl.stateParams.employe && vm.opened) {
                    vm.indexDefaut = vm.indexMenuEmploye;
                }
            }
        });
    }

    function isColonneHidden(col: string): boolean {
        if (!vm.multiOccurrence.colonnesParametres[col]) {
            return false;
        }

        const hidden = vm.multiOccurrence.colonnesParametres[col].hidden;

        if (typeof hidden === 'function') {
            return hidden(vm.multiOccurrence.getDataActiveRow() || {});
        } else {
            return Boolean(hidden);
        }
    }

    function initResize() {
        const sidenav = $element[0].getElementsByTagName('md-content')[0] as HTMLElement;
        const tabsContent = $element[0].getElementsByClassName('ex-tabs-content')[0] as HTMLElement;
        const resizer = $element[0].getElementsByClassName('navigation-resizer')[0];
        if (resizer) {
            resizer.addEventListener('mousedown', initResize, false);
        }

        // Utilisation de la largeur précédemment utilisée pour le menu
        const savedSizeSelectorRightMenu = 'navigation-right-menu-size';
        const savedSize = localStorage.getItem(savedSizeSelectorRightMenu);
        if (savedSize) {
            sidenav.style.width = savedSize;
            tabsContent.style.width = Number(savedSize.replace('px', '')) - 3 + 'px';
        }

        function initResize(e: DragEvent) {
            e.stopPropagation();
            //Empêche de sélectionner du texte ou une image pendant le redimensionnement du menu
            document.getElementsByTagName('body')[0].style.userSelect = 'none';

            window.addEventListener('mousemove', resize, false);
            window.addEventListener('mouseup', stopResize, false);
        }

        function resize(e: DragEvent) {
            sidenav.style.width = (window.innerWidth - e.clientX) + 'px';
            tabsContent.style.width = (window.innerWidth - e.clientX - 3) + 'px';
        }

        function stopResize(e: DragEvent) {
            e.stopPropagation();
            document.getElementsByTagName('body')[0].style.userSelect = null;
            window.removeEventListener('mousemove', resize, false);
            window.removeEventListener('mouseup', stopResize, false);
            localStorage.setItem(savedSizeSelectorRightMenu, sidenav.style.width);

            // S'assure que tous les éléments à droite du menu sont informés du redimensionnement
            $scope.$parent.$broadcast('exGridReajusterLargeurColonne');
        }
    }
}
