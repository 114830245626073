import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageMc0165 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireFon: IFormulaire;
    fosMultiOccurrenceOptions: IMultiOccurrenceOptions;
    frcMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0165Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass ) {
    const vm: IPageMc0165 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireFon = new Formulaire('LBL_BLC_FON', [
            new FormulaireItem('foncod'),
            new FormulaireItem('fonflgpro', {default: 1}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fondsc'),
            new FormulaireItem('fondscabr')
        ], {largeurDefaut: 50});

        vm.fosMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'fosann'       , largeur: 80},
                {nom: 'sfucod'       , largeur: 100},
                {nom: 'sfudsc'       , largeur: 160}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fosann'),
                new FormulaireItem('sfucleint')
            ])
        };

        vm.frcMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'cptcod'   , largeur: 130},
                {nom: 'cptdsc'   , largeur: 300},
                {nom: 'frcdatdeb', largeur: 130},
                {nom: 'frcdatfin', largeur: 130}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cptcleint'),
                new FormulaireItem('frcdatdeb', {largeur: 20}),
                new FormulaireItem('frcdatfin')
            ], {largeurDefaut: 40})
        }
    }
}
