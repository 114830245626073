import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0039 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIpr: IFormulaire;
    formulaireEnt: IFormulaire;
    ipgMultiOccurrence: IMultiOccurrence;
    ipgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ippMultiOccurrence: IMultiOccurrence;
    ippMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ipuMultiOccurrence: IMultiOccurrence;
    ipuMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc0039Params {
    intcleint: number,
    id: number
}

/* @ngInject */
export default function Mc0039Controller($stateParams: IMc0039Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass) {

    const vm: IPageMc0039 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;  // voir prb_obtenir_valeurs

        vm.monoOccurrenceOptions = {
            resourceParams: {
                iprcleint: () => {
                    return $stateParams.id;
                }
            }
        };

        vm.formulaireEnt = new Formulaire([
                new FormulaireTemplateSlot('entete',  {largeur: 50}),
                new FormulaireItem('t_intflgsecpru', {default: vm.valeursEcran.t_intflgsecpru}),
                new FormulaireItem('t_intflgsecuna', {default: vm.valeursEcran.t_intflgsecuna})
            ], {
            largeurDefaut: 25
            }
        );

        vm.formulaireIpr = new Formulaire('LBL_BLC_IPR', [
            new FormulaireItem('iprcod', { largeur: 25 }),
            new FormulaireItem('iprdsc', { largeur: 35 }),
            new FormulaireItem('iprflgaut', { largeur: 15 }),
            new FormulaireItem('iprflgclo', { largeur: 15 }),
            new FormulaireItem('intcleint', {default: $stateParams.intcleint})
        ], { largeurDefaut: 33 });


        // Bloc IPG (GRU)
        vm.ipgMultiOccurrenceOptions = {
            bloc: 'ipg',
            colonnesVisibles: [
                { nom: 'grudsc', largeur: 210 }                
            ],
            formulaire: new Formulaire([
                new FormulaireItem('grucleint', { largeur: 60 }),
                new FormulaireItem('intcleint', {default: $stateParams.intcleint}),
                new FormulaireItem('iprcleint', {default: $stateParams.id})
            ]),
            fonctions: {
                pagination: true
            }
        };

        // Bloc IPP (UBR)
        vm.ippMultiOccurrenceOptions = {
            bloc: 'ipp',
            colonnesVisibles: [
                { nom: 'prucod', largeur: 20 },
                { nom: 'prudsc', largeur: 30 },
                { nom: 'ippflgexc', largeur: 40 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('prucleint', { largeur: 60 }),
                new FormulaireItem('ippflgexc', { largeur: 40 }),
                new FormulaireItem('intcleint', {default: $stateParams.intcleint}),
                new FormulaireItem('iprcleint', {default: $stateParams.id})
            ]),
            fonctions: {
                pagination: true
            }
        };

        // Bloc IPU (UNA)
        vm.ipuMultiOccurrenceOptions = {
            bloc: 'ipu',
            colonnesVisibles: [
                { nom: 'unacod', largeur: 30 },
                { nom: 'unadsc', largeur: 60 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('unacleint', { largeur: 60 }),
                new FormulaireItem('intcleint', {default: $stateParams.intcleint}),
                new FormulaireItem('iprcleint', {default: $stateParams.id})
            ]),
            fonctions: {
                pagination: true
            }
        };

    }
}
