import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageMc0096 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mc0096Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    mc0052Route: IRoute,
    mc2046Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0096 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres:true
        },        
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleintcri', { largeur: 50, readonly: true }),
            new FormulaireItem('redcleintcri', { largeur: 50 })
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemForage('LBL_BTN_BOURES',
                mc2046Route.NAME,
                (data: any) => ({
                    id: data.doccleint
                }),
                {
                    icon: 'assessment'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_DOC',
                (rowData: any) => `secure.${rowData.ecrcodoc}.ecran`,
                (rowData: any) => ({
                    id: rowData.doccleint
                }),
                {
                    icon: 'launch'
                }
            ),
            new MenuItemEcran(
                'LBL_BTN_IMP',
                mc0052Route.NAME,
                (rowData: any) => ({
                    id: rowData.doccleint,
                    docaffnumdoc01: rowData.docaffnumdoc01,
                    tydcleint: rowData.tydcleint
                }),
                {
                    icon: 'view_list',
                    fonction: 'BOUIMP',
                    onClose: () => vm.multiOccurrence.fetchDataList(),
                    iconButton: false
                }
            )      
        ]),   
        colonnesCachees: ['doccleint', 'ecrcodoc', 'docaffnumdoc01', 'tydcleint'],     
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 120 },
            { nom: 'tydcod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'docrefcod02', largeur: 120 },
            { nom: 'docreqcod03', largeur: 120 },
            { nom: 'intnomapr', largeur: 200 },
            { nom: 'docaffmntdepnet01', largeur: 126 },
            { nom: 'docaffmnt01', largeur: 126 },
            { nom: 'docaffdsc01', largeur: 300 }
        ]
    };
}
