import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageRr0060 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDci: IFormulaire;
    valeursEcran: any;
}

interface IRr0060Params {
    id: string;
    demcleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0051Controller($stateParams: IRr0060Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr0060 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.formulaireAid = new Formulaire('LBL_AVANTAGE_IMP', [
            new FormulaireItem('demcleint', {
                default: $stateParams.demcleint
            }),
            new FormulaireItem('aidflgact', {
                default: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tagcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aiddatdeb'),
            new FormulaireItem('aiddatfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aidmnt')
        ]);
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };
    }
}
