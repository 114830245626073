import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';

interface IPageMr0143 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    formulaireCyt: IFormulaire;
    ctpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0143Controller(gl0002Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMr0143 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireCyt = new Formulaire('LBL_BLC_CYT', [
            new FormulaireItem('cytcod'),
            new FormulaireItem('cytdsc'),
            new FormulaireItem('stamnetab'),
            new FormulaireItem('ctpcleintini'),
            new FormulaireItem('cytcom', { largeur: 100, enableCount: true })
        ], { largeurDefaut: 25 });
        vm.ctpMultiOccurrenceOptions = {
            colonnesFixesGauche: [
                { nom: 'ctpseqaff', largeur: 95 }
            ],
            colonnesVisibles: [
                { nom: 'ctpdscabr', largeur: 262 },
                { nom: 'ctpdsc', largeur: 400 },
                { nom: 'ctptxtcou', largeur: 150 },
                { nom: 'ctptxtcouuno', largeur: 150 },
                { nom: 'ctptxtcouoff', largeur: 150 },
                { nom: 'ctptxtcouurg', largeur: 150 }
            ],
            colonnesFixesDroite: [
                { nom: 'ctpflgcou', largeur: 126 },
                { nom: 'ctpflgcouuno', largeur: 126 },
                { nom: 'ctpflgcouoff', largeur: 126 },
                { nom: 'ctpflgcouurg', largeur: 150 },
                { nom: 'ctpflgpresel', largeur: 110 },
                { nom: 'ctpflgsel', largeur: 105 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ctpseqaff', { largeur: 25, required: true }),
                new FormulaireItem('ctpdscabr', { largeur: 25 }),
                new FormulaireItem('ctpdsc', { largeur: 50, enableCount: true }),
                new FormulaireItem('ctpflgcou'),
                new FormulaireGroupeAccordeon(
                    'ctpflgcou',
                    // Formulaire accordéon
                    new Formulaire([
                        new FormulaireItem('ctptxtcou')
                    ], { largeurDefaut: 100 }),
                    // Options accordéon
                    {
                        opened: true,
                        hidden: (data: any) => !data.ctpflgcou
                    }
                ),
                new FormulaireItem('ctpflgcouuno', { titre: 'LBL_COUR_UNI_PARENT' }),
                new FormulaireGroupeAccordeon(
                    'LBL_COUR_UNI_PARENT',
                    // Formulaire accordéon
                    new Formulaire([
                        new FormulaireItem('ctptxtcouuno')
                    ], { largeurDefaut: 100 }),
                    // Options accordéon
                    {
                        opened: true,
                        hidden: (data: any) => !data.ctpflgcouuno
                    }
                ),
                new FormulaireItem('ctpflgcouoff', { titre: 'LBL_COUR_OFF_EMPLOI' }),
                new FormulaireGroupeAccordeon(
                    'LBL_COUR_OFF_EMPLOI',
                    // Formulaire accordéon
                    new Formulaire([
                        new FormulaireItem('ctptxtcouoff')
                    ], { largeurDefaut: 100 }),
                    // Options accordéon
                    {
                        opened: true,
                        hidden: (data: any) => !data.ctpflgcouoff
                    }
                ),
                new FormulaireItem('ctpflgcouurg', { titre: 'LBL_COUR_URG' }),
                new FormulaireGroupeAccordeon(
                    'LBL_COUR_URG',
                    // Formulaire accordéon
                    new Formulaire([
                        new FormulaireItem('ctptxtcouurg')
                    ], { largeurDefaut: 100 }),
                    // Options accordéon
                    {
                        opened: true,
                        hidden: (data: any) => !data.ctpflgcouurg
                    }
                ),
                new FormulaireItem('ctpflgpresel'),
                new FormulaireItem('ctpflgsel')
            ], { largeurDefaut: 20 })
        };
    }
}
