import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageCH0003 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIte: IFormulaire;
    ithMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Ch0003Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCH0003 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireIte = new Formulaire('LBL_SITE', [
            new FormulaireItem('itecod', {
                required: true
            }),
            new FormulaireItem('itedsc', {
                required: true
            }),
            new FormulaireItem('iteflgact', {
                required: true,
                default: 1
            }),
            new FormulaireItem('iteflgche', {
                required: true,
                default: 0
            }),
            new FormulaireItem('rolcleint'),
            new FormulaireItem('iteflgpubhoraut', {
                required: true,
                default: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('esvcleint1', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5,
                })
            }),
            new FormulaireItem('esvcleint2', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5,
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esvcleint3', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5,
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esvcleint4', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5,
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esvcleint5', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5,
                }),
                nombreLibellesDetailsCacher: 1
            })
        ], {
            largeurDefaut: 33.33
        });

        // Horodateur
        vm.ithMultiOccurrenceOptions = {
            bloc: 'ith',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'hdtcod', largeur: 131},
                {nom: 'hdtdsc', largeur: 300},
                {nom: 'ithflgact', largeur: 91}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('hdtcleint', {
                    largeur: 50
                }),
                new FormulaireItem('ithflgact', {
                    default: 1,
                    largeur: 50
                })
            ])
        };
    }
}
