import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRe0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re0009Controller(Menu: IMenuClass,
                                         re0010Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe0009 = this;

    vm.multiOccurrenceOptions = {
        filtres: parametresRecherche(ecranDetails),
        navigatePage: re0010Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(re0010Route.NAME)
        ]), 
        colonnesVisibles: [
            {nom: 'rtocod', largeur: 120},
            {nom: 'rtodsc', largeur: 160},
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 160},
            {nom: 'tsidatvig', largeur: 126},
            {nom: 'tsisal', largeur: 126},
            {nom: 'uncdsc', largeur: 160},
            {nom: 'geccod', largeur: 120},
            {nom: 'gecdsc', largeur: 160},
            {nom: 'eeccod', largeur: 100},
            {nom: 'tsidsc', largeur: 140},
        ]
    };
}
