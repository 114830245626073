import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFilterDate} from 'angular';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPageCH0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ch0006Controller(ch0001Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         dateFilter: IFilterDate,
                                         Operateur: IOperateurService,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCH0006 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: ch0001Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(ch0001Route.NAME)
        ]),
        filtres: [
            {
                colonne: 'itpdatfin',
                operateur: Operateur.PLUS_GRAND_EGALE,
                valeur: dateFilter(new Date(), 'yyyy-MM-dd')
            }
        ],

        colonnesVisibles: [
            {nom: 'itecod', largeur: 90},
            {nom: 'itedsc', largeur: 262},
            {nom: 'itpdatdeb', largeur: 100},
            {nom: 'itpdatfin', largeur: 100}
        ],
        colonnesCachees: [
            'itpcleint'
        ]
    };
}
