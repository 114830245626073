import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFiltreOptions} from '../../../../../core/services/filtre.service';

interface IPageRr0113 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IRr0113Params {
    stamnetab: string;
    specleintref: number;
}

/* @ngInject */
export default function Rr0113Controller($stateParams: IRr0113Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0113 = this;
    let filtre: IFiltreOptions;

    vm.valeursEcran = ecranDetails.valeurs;

    if ($stateParams.stamnetab === 'CPP') {
        filtre = {
            colonne: 'codecpp',
            valeur: vm.valeursEcran.dsc,
            affichage: true,
            readOnly: true
        }
    } else {
        filtre = {
            colonne: 'codetdm',
            valeur: vm.valeursEcran.dsc,
            affichage: true,
            readOnly: true
        }
    }

    vm.multiOccurrenceOptions = {
        filtres: [
            filtre
        ],
        colonnesVisibles: [
            {nom: 'vaespepos_dsc', largeur: 131},
            {nom: 'spetxtinf', largeur: 393},
            {nom: 'spetxtsup', largeur: 393},
            {nom: 'speflgdat', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaespepos', {largeur: 20}),
            new FormulaireItem('spetxtinf',{largeur: 30}),
            new FormulaireItem('spetxtsup', {largeur: 30}),
            new FormulaireItem('speflgdat', {largeur: 20}),
            new FormulaireItem('stamnetab'   , { hidden: true, default: $stateParams.stamnetab}),
            new FormulaireItem('specleintref', { hidden: true, default: $stateParams.specleintref})
        ])
    };
}
