import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageMr0173 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
}

/* @ngInject */
export default function Mr0173Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
    
    ) {

    const vm: IPageMr0173 = this;
   
    vm.monoOccurrenceOptions = {        
      
         formulaire: new Formulaire([
            new FormulaireItem('stacleint', { disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false), largeur: 75 }),
            new FormulaireItem('vaedexmne', { disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false), largeur: 25 }),
            new FormulaireItem('t_stadsc', { disabled: true, largeur: 100 }),
            new FormulaireItemDivider('LBL_CHA'), 
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('objelecle', { largeur: 100, disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) } ),
            new FormulaireItem('objelecod', { largeur: 100, disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),            
            new FormulaireItem('objeledsc', { largeur: 100, disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),            
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_COM'), 
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('objflgseg',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgmulocc',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgprc',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgmnt',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgtym',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) })
            ]), { largeur: 50 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('objflgbas',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgrscbas',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgindmthbmg',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgrem',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('objflgcar',{ disabled: ()=>(vm.monoOccurrence.data.systdevel === 0 ? true:false) })
            ]), { largeur: 50 }),
            new FormulaireItem('objcom', { largeur: 100, disabled: () => (vm.monoOccurrence.data.systdevel === 0 ? true:false) })
        ], {
            largeurDefaut: 100
        },
        )
    }; 
    
    };


