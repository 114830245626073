import { IComponentController, IFilterDate } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import {
    IFormulaire,
    IFormulaireClass,
} from "../../../../../core/services/formulaire/formulaire.service";
import {
    IMonoOccurrence,
    IMonoOccurrenceOptions,
} from "../../../../../core/services/mono-occurrence.service";
import {
    IMultiOccurrence,
    IMultiOccurrenceOptions,
} from "../../../../../core/services/multi-occurrence.service";
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IFormulaireGroupeClass } from "../../../../../core/services/formulaire/formulaire-groupe.service";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import {
    IMenuItemForage,
    IMenuItemForageClass,
} from "../../../../../core/services/menu/menu-item-forage.service";
import { IPaginationClass } from "../../../../../core/services/pagination.service";
import { IStateParamsService } from "angular-ui-router";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageAs0035 extends IComponentController {
    menus: { [key: string]: Array<IMenuItem> };
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    dtjresMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dtjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    atfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ttjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bqeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    objMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bdcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
    cpeMenus: Array<IMenuItemForage>;
}

/* @ngInject */
export default function As0035Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    dateFilter: IFilterDate,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemEcran: IMenuItemEcranClass,
    Menu: IMenuClass,
    as0046Route: IRoute,
    as0038Route: IRoute,
    as0041Route: IRoute,
    rr0006Route: IRoute,
    rr0074Route: IRoute,
    as2003Route: IRoute,
    $stateParams: IStateParamsService,
    Pagination: IPaginationClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAs0035 = this;
    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            avisEmploye: false,
            supprime: false,
            refreshForageIdWithCycle: true,
        },
        cycleErrorReloadBlocs: ["DTJ", "ATF"],
        formulaire: new Formulaire("LBL_ATF", [
            new FormulaireItem("demcleint", {
                largeur: 33,
                default: $stateParams.demcleint,
                readonly: $stateParams.demcleint,
                required: true,
                modifAllow: false,
            }),
            new FormulaireItem("vaeatftyp", {
                largeur: 33,
                default: $stateParams.vaeatftyp,
                disabled: true,
                required: false,
            }),
            new FormulaireItem("ttccleint", {
                largeur: 33,
                required: true,
                modifAllow: false,
                resourceParams: (data: any) => ({ vaeatftyp: data.vaeatftyp }),
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("asgcleint", {
                largeur: 33,
                required: true,
                modifAllow: false,
                resourceParams: (data: any) => ({
                    demcleint: data.demcleint,
                    ptadatdeb: data.ptadatdeb,
                    ptadatfin: data.ptadatfin,
                }),
            }),
            new FormulaireItem("ptacleint", {
                largeur: 33,
                required: true,
                modifAllow: false,
                resourceParams: (data: any) => ({ unpcleint: data.unpcleint }),
            }),
            new FormulaireItem("ppacod", {
                largeur: 33,
                readonly: true,
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("atfnumdoc", {
                largeur: 33,
                required: false,
            }),
            new FormulaireItem("vaeatfori", {
                largeur: 33,
                readonly: true,
                required: false,
            }),
            new FormulaireItem("atfnumdocref", {
                largeur: 33,
                readonly: true,
            }),
            new FormulaireItem("unpcleint", {
                largeur: 33,
                hidden: true,
            }),
            new FormulaireItem("unorhcod", {
                largeur: 33,
                readonly: true,
            }),
            new FormulaireItem("posnumdoc", {
                largeur: 33,
                readonly: true,
            }),
            new FormulaireItem("nomsupervi", {
                largeur: 33,
                readonly: true,
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem("atfcom", {
                largeur: 100,
                enableCount: true,
            })
        ]),
        actionsMore: new Menu([
            new MenuItemForage(
                "LBL_BTN_ASS",
                as0046Route.NAME,
                (data: any) => ({
                    demcleint: data.demcleint,
                    datdeb: vm.monoOccurrence.ecranDetails.valeurs.datdeb,
                    datfin: vm.monoOccurrence.ecranDetails.valeurs.datfin,
                    criteremultipledeftay: '1',
                    criteremultiplesrccod: "tay",
                    criteremultiplecol: "taycleint"
                }),
                {
                    icon: "launch_app",
                    fonction: "BOUIMP",
                    iconButton: false,
                    secondaire: false,
                }
            ),
            new MenuItemForage(
                "LBL_BTN_STM",
                as0041Route.NAME,
                (data: any) => ({
                    demcleint: data.demcleint,
                    datdeb: data.ptadatdeb,
                }),
                {
                    icon: "launch_app",
                    fonction: "BOUIMP",
                    iconButton: false,
                    secondaire: false,
                }
            ),
        ]),
        infosMonetaires: {
            blocs: ['dtj', 'atf', 'dtjres'],
            largeurTexte: 150,
            afficherAuBas: "oui",
            noIcons: true,
            donnees:
                [
                    { nom: 'tothreet', libelle: 'tothreet' },
                    { nom: 'tothreabs', libelle: 'tothreabs' },
                    { nom: 'tothreadd', libelle: 'tothreadd' }
                ]
        },
    };

    vm.formulaireMsg = new Formulaire("zone_message", [
        new FormulaireItem("message", {
            largeur: 100,
            enableCount: true,
            readonly: true,
        }),
    ]);
    // LISTE DES BUTTONS
    vm.cpeMenus = [
        //Générer Horaire
        new MenuItemAction("LBL_BTN_HOR", "BOUGEN", () => ({}), {
            icon: "edit_note",
            iconButton: false,
            secondaire: true,
            fonction: "BOUGEN",
            hidden: (data: any) => vm.monoOccurrence.data.unique_hhs !== null,
            disabled: (data: any) => data.$ancetre.ecldsceta !== "Initial",
            successAction: () => {
                vm.monoOccurrence.fetchData();
            },
        }),
        //Générer Horaire type gen
        new MenuItemAction("LBL_BTN_HOR", "BOUGEN", () => ({}), {
            icon: "edit_note",
            iconButton: false,
            secondaire: true,
            fonction: "BOUGEN",
            hidden: (data: any) => vm.monoOccurrence.data.unique_hhs === null,
            disabled: (data: any) => data.$ancetre.ecldsceta !== "Initial",
            formulaireConfirmationAction: {
                lblTitre: "LBL_BTN_HOR",
                lblConfirm: "G_LBL_BTN_APPLIQUER",
                largeurFormulaire: 30,
                formulaire: new Formulaire([
                    new FormulaireItem("type_gen", {
                        largeur: 100,
                        default: "HOR",
                    }),
                    new FormulaireItem("t_aftcleint", {
                        largeur: 100,
                        resourceParams: (data: any) => ({
                            demcleint: data.$ancetre.demcleint,
                            ptadatfin: data.$ancetre.ptadatfin,
                            ptadatdeb: data.$ancetre.ptadatdeb,
                            usrcleint: data.$ancetre.usrcleint,
                        }),
                    }),
                ]),
            },
            successAction: () => {
                vm.monoOccurrence.fetchData();
            },
        }),
        new MenuItemAction("LBL_BTN_TMP", "BOUTMP", () => ({}), {
            iconButton: false,
            icon: "edit_note",
            secondaire: true,
            disabled: (data: any) => data.$ancetre.ecldsceta !== "Initial",
            formulaireConfirmationAction: {
                lblTitre: "LBL_BTN_TMP",
                lblConfirm: "G_LBL_LBL_BTN_TMP",
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem("t_occleint", {
                        largeur: 100,
                        required: true,
                        default: ecranDetails.valeurs.occleint,
                        resourceParams: (data: any) => ({
                            asgcleint: data.$ancetre.asgcleint,
                            vaeatftyp: data.$ancetre.vaeatftyp,
                        }),
                    }),
                    new FormulaireItem("t_aftcleint_tmp", {
                        largeur: 100,
                        required: true,
                        resourceParams: (data: any) => ({
                            demcleint: data.$ancetre.demcleint,
                            ptadatdeb: data.$ancetre.ptadatdeb,
                            ptadatfin: data.$ancetre.ptadatfin,
                            usrcleint: data.usrcleint,
                        }),
                    }),
                    new FormulaireItem("t_actcleint", {
                        largeur: 100,
                        required: true,
                        resourceParams: (data: any) => ({
                            demcleint: data.$ancetre.demcleint,
                            ptadatdeb: data.$ancetre.ptadatdeb,
                            ptadatfin: data.$ancetre.ptadatfin,
                            vaeatftyp: data.$ancetre.vaeatftyp,
                            asgcleint: data.$ancetre.asgcleint,
                        }),
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem("type_gen_tmp", {
                        largeur: 100,
                        default: "SPE",
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem("t_datedeb", {
                        largeur: 33,
                    }),
                    new FormulaireItem("t_datfin", {
                        largeur: 33,
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem("dtjhredeb_jr1", {
                        largeur: 33,
                    }),
                    new FormulaireItem("dtjhrefin_jr1", {
                        largeur: 33,
                    }),
                    new FormulaireItem("dtjhredeb_jr2", {
                        largeur: 33,
                    }),
                    new FormulaireItem("dtjhrefin_jr2", {
                        largeur: 33,
                    }),
                ]),
            },
            successAction: () => {
                vm.monoOccurrence.fetchData();
            },
        }),
        // INCDENT
        new MenuItemEcran(
            "LBL_BTN_INC",
            as0038Route.NAME,
            (data: any) => ({
                atfcleint: data.atfcleint,
                stamnetab: "ATF",
            }),
            {
                icon: "warning",
                fonction: "BOUIMP",
                iconButton: false,
                secondaire: true,
                compteur: (data: any) =>
                    data.inc_nbr > 0 ? data.inc_nbr : undefined,
            }
        ),
    ];
    vm.dtjresMultiOccurrenceOptions = {
        bloc: "dtjres",
        fonctions: {
            edition: false,
        },
        colonnesVisibles: [
            { nom: "dtjnumsem", largeur: 65 },
            { nom: "dtjdatjr1", largeur: 150, changeCellColor: true },
            {
                nom: "1",
                largeur: 15,
                menu: [
                    new MenuItemEcran(
                        "",
                        as2003Route.NAME,
                        (rowData: any) => ({
                            datdeb: (rowData.dtjnumsem = 1
                                ? rowData.ptadatdeb
                                : rowData.ptadatdeb7),
                            id: rowData.atfcleint,
                            atfcleint: rowData.atfcleint,
                            asgcleint: rowData.asgcleint,
                            vaeatftyp: rowData.vaeatftyp,
                            ecldsceta: rowData.$ancetre.ecldsceta,
                            demcleint: rowData.demcleint,
                            ptadatfin: rowData.$ancetre.ptadatfin,
                            ttccleint: rowData.$ancetre.ttccleint
                        }),
                        {
                            fonction: "RESERVES",
                            icon: "launch",
                            iconButton: true,
                            onClose: () => vm.monoOccurrence.fetchData(),
                        }
                    ),
                ],
            },
            { nom: "dtjdatjr2", largeur: 150, changeCellColor: true },
            {
                nom: "2",
                largeur: 15,
                menu: [
                    new MenuItemEcran(
                        "",
                        as2003Route.NAME,
                        (rowData: any) => ({
                            datdeb: (rowData.dtjnumsem = 1
                                ? rowData.ptadatdeb1
                                : rowData.ptadatdeb8),
                            id: rowData.atfcleint,
                            atfcleint: rowData.atfcleint,
                            asgcleint: rowData.asgcleint,
                            vaeatftyp: rowData.vaeatftyp,
                            ecldsceta: rowData.$ancetre.ecldsceta,
                            demcleint: rowData.demcleint,
                            ptadatfin: rowData.$ancetre.ptadatfin,
                            ttccleint: rowData.$ancetre.ttccleint
                        }),
                        {
                            fonction: "RESERVES",
                            icon: "launch",
                            iconButton: true,
                            onClose: () => vm.monoOccurrence.fetchData(),
                        }
                    ),
                ],
            },
            { nom: "dtjdatjr3", largeur: 150, changeCellColor: true },
            {
                nom: "3",
                largeur: 15,
                menu: [
                    new MenuItemEcran(
                        "",
                        as2003Route.NAME,
                        (data: any) => ({
                            datdeb: (data.dtjnumsem = 1
                                ? data.ptadatdeb2
                                : data.ptadatdeb9),
                            id: data.atfcleint,
                            atfcleint: data.atfcleint,
                            asgcleint: data.asgcleint,
                            vaeatftyp: data.vaeatftyp,
                            ecldsceta: data.$ancetre.ecldsceta,
                            demcleint: data.demcleint,
                            ptadatfin: data.$ancetre.ptadatfin,
                            ttccleint: data.$ancetre.ttccleint
                        }),
                        {
                            fonction: "RESERVES",
                            icon: "launch",
                            iconButton: true,
                            onClose: () => vm.monoOccurrence.fetchData(),
                        }
                    ),
                ],
            },
            { nom: "dtjdatjr4", largeur: 150, changeCellColor: true },
            {
                nom: "4",
                largeur: 15,
                menu: [
                    new MenuItemEcran(
                        "",
                        as2003Route.NAME,
                        (rowData: any) => ({
                            datdeb: (rowData.dtjnumsem = 1
                                ? rowData.ptadatdeb3
                                : rowData.ptadatdeb10),
                            id: rowData.atfcleint,
                            atfcleint: rowData.atfcleint,
                            asgcleint: rowData.asgcleint,
                            vaeatftyp: rowData.vaeatftyp,
                            ecldsceta: rowData.$ancetre.ecldsceta,
                            demcleint: rowData.demcleint,
                            ptadatfin: rowData.$ancetre.ptadatfin,
                            ttccleint: rowData.$ancetre.ttccleint
                        }),
                        {
                            fonction: "RESERVES",
                            icon: "launch",
                            iconButton: true,
                            onClose: () => vm.monoOccurrence.fetchData(),
                        }
                    ),
                ],
            },
            { nom: "dtjdatjr5", largeur: 150, changeCellColor: true },
            {
                nom: "5",
                largeur: 15,
                menu: [
                    new MenuItemEcran(
                        "",
                        as2003Route.NAME,
                        (rowData: any) => ({
                            datdeb: (rowData.dtjnumsem = 1
                                ? rowData.ptadatdeb4
                                : rowData.ptadatdeb11),
                            id: rowData.atfcleint,
                            atfcleint: rowData.atfcleint,
                            asgcleint: rowData.asgcleint,
                            vaeatftyp: rowData.vaeatftyp,
                            ecldsceta: rowData.$ancetre.ecldsceta,
                            demcleint: rowData.demcleint,
                            ptadatfin: rowData.$ancetre.ptadatfin,
                            ttccleint: rowData.$ancetre.ttccleint
                        }),
                        {
                            fonction: "RESERVES",
                            icon: "launch",
                            iconButton: true,
                            onClose: () => vm.monoOccurrence.fetchData(),
                        }
                    ),
                ],
            },
            { nom: "dtjdatjr6", largeur: 150, changeCellColor: true },
            {
                nom: "6",
                largeur: 15,
                menu: [
                    new MenuItemEcran(
                        "",
                        as2003Route.NAME,
                        (rowData: any) => ({
                            datdeb: (rowData.dtjnumsem = 1
                                ? rowData.ptadatdeb5
                                : rowData.ptadatdeb12),
                            id: rowData.atfcleint,
                            atfcleint: rowData.atfcleint,
                            asgcleint: rowData.asgcleint,
                            vaeatftyp: rowData.vaeatftyp,
                            ecldsceta: rowData.$ancetre.ecldsceta,
                            demcleint: rowData.demcleint,
                            ptadatfin: rowData.$ancetre.ptadatfin,
                            ttccleint: rowData.$ancetre.ttccleint
                        }),
                        {
                            fonction: "RESERVES",
                            icon: "launch",
                            iconButton: true,
                            onClose: () => vm.monoOccurrence.fetchData(),
                        }
                    ),
                ],
            },
            { nom: "dtjdatjr7", largeur: 150, changeCellColor: true },
            {
                nom: "7",
                largeur: 15,
                menu: [
                    new MenuItemEcran(
                        "",
                        as2003Route.NAME,
                        (rowData: any) => ({
                            datdeb: (rowData.dtjnumsem = 1
                                ? rowData.ptadatdeb6
                                : rowData.ptadatdeb13),
                            id: rowData.atfcleint,
                            atfcleint: rowData.atfcleint,
                            asgcleint: rowData.asgcleint,
                            vaeatftyp: rowData.vaeatftyp,
                            ecldsceta: rowData.$ancetre.ecldsceta,
                            demcleint: rowData.demcleint,
                            ptadatfin: rowData.$ancetre.ptadatfin,
                            ttccleint: rowData.$ancetre.ttccleint
                        }),
                        {
                            fonction: "RESERVES",
                            icon: "launch",
                            iconButton: true,
                            onClose: () => vm.monoOccurrence.fetchData(),
                        }
                    ),
                ],
            },
        ],
        colonnesCachees: [
            "atfcleint",
            "ptadatdeb",
            "ptadatdeb1",
            "ptadatdeb2",
            "ptadatdeb3",
            "ptadatdeb4",
            "ptadatdeb5",
            "ptadatdeb6",
            "ptadatdeb7",
            "ptadatdeb8",
            "ptadatdeb9",
            "ptadatdeb10",
            "ptadatdeb11",
            "ptadatdeb12",
            "ptadatdeb13",
            "asgcleint",
            "demcleint",
            "vaeatftyp",
            "color",
        ],
        formulaire: new Formulaire([], { largeurDefaut: 33 }),
    };
    vm.dtjMultiOccurrenceOptions = {
        bloc: "dtj",
        fonctions: {
            editionRapide: true,
            avisEmploye: false,
        },
        pagination: new Pagination({
            nombreElementPage: 50,
        }),
        colonnesFixesGauche: [
            { nom: "flgerr", largeur: 20, changeColor: true },
            { nom: "icon", largeur: 20, changeColor: true },
            { nom: "dtjdatjr", largeur: 100, changeColor: true },
            { nom: "actcod", largeur: 160, changeColor: true },
            { nom: "hrdebaff", largeur: 100, changeColor: true },
            { nom: "hrfinaff", largeur: 100, changeColor: true },
            { nom: "qtehor", largeur: 70, changeColor: true },
            { nom: "dtjqte", largeur: 90, changeColor: true },
            { nom: "comm", largeur: 40, changeColor: true },
        ],
        colonnesVisibles: [
            { nom: "occupation", largeur: 130, changeColor: true },
            { nom: "aftcod", largeur: 160, changeColor: true },
            { nom: "dernumdoc", largeur: 160, changeColor: true },
            { nom: "drpnumdoc", largeur: 160, changeColor: true },
            { nom: "imp", largeur: 190, changeColor: true },
            { nom: "lemcod", largeur: 130, changeColor: true },
            { nom: "temcod", largeur: 130, changeColor: true },
            { nom: "posnumdoc", largeur: 130, changeColor: true },
            { nom: "unocod", largeur: 130, changeColor: true },
        ],
        colonnesEditionRapide: [
            { nom: "jour", largeur: 10 },
            { nom: "dtjdat", largeur: 130 },
            {
                nom: "occcleint",
                largeur: 130,
                resourceParams: (data: any) => ({
                    asgcleint: data.$ancetre.asgcleint,
                    vaeatftyp: data.$ancetre.vaeatftyp,
                }),
            },
            {
                nom: "aftcleint",
                largeur: 160,
                resourceParams: (data: any) => ({
                    demcleint: data.$ancetre.demcleint,
                    asgcleint: data.$ancetre.asgcleint,
                    dtjdat: data.dtjdat,
                    occcleint: data.occcleint,
                }),
            },
            {
                nom: "dercleint",
                largeur: 190,
                resourceParams: (data: any) => ({
                    demcleint: data.$ancetre.demcleint,
                    dtjdat: data.dtjdat,
                    asgcleint: data.$ancetre.asgcleint,
                }),
            },
            {
                nom: "drpcleint",
                largeur: 190,
                resourceParams: (data: any) => ({
                    dercleint: data.dercleint,
                    dtjdat: data.dtjdat,
                }),
            },
            {
                nom: "actcleint",
                largeur: 210,
                resourceParams: (data: any) => ({
                    demcleint: data.$ancetre.demcleint,
                    ptadatfin: data.$ancetre.ptadatfin,
                    asgcleint: data.$ancetre.asgcleint,
                    vaeatftyp: data.$ancetre.vaeatftyp,
                    dtjdat: data.dtjdat,
                }),
            },
            { nom: "hrdeb", largeur: 30 },
            { nom: "ldmhrdeb", largeur: 30 },
            { nom: "hrfin", largeur: 30 },
            { nom: "ldmhrfin", largeur: 30 },
            { nom: "qtehor", largeur: 10 },
            { nom: "dtjqte", largeur: 10 },
            { nom: "dtjflgcor", largeur: 45 },
            { nom: "dtjcom", largeur: 95 },
            { nom: "dtjhredeb", largeur: 10, hidden: true },
            { nom: "dtjhrefin", largeur: 95, hidden: true },
        ],
        colonnesCachees: [
            "urgcleint",
            "esvcod1",
            "esvcod2",
            "esvcod3",
            "esvcod4",
            "esvcod5",
            "prucleint",
            "esvcleint1",
            "esvcleint2",
            "esvcleint3",
            "esvcleint4",
            "esvcleint5",
            "el3cleint",
            "flgcom",
            "color",
            'vaeoccaft',
            'vaeoccder'
        ],
        formulaire: new Formulaire(
            [
                new FormulaireItem("occcleint", {
                    default: ecranDetails.valeurs.occleint,
                    resourceParams: (data: any) => ({
                        asgcleint: data.$ancetre.asgcleint,
                        vaeatftyp: data.$ancetre.vaeatftyp,
                    }),
                    largeur: 25,
                }),
                new FormulaireItem("dtjdat", {
                    required: true,
                    resourceParams: (data: any) => ({
                        ptadatfin: data.$ancetre.ptadatfin,
                        ptadatdeb: data.$ancetre.ptadatdeb,
                    }),
                    largeur: 15,
                }),
                new FormulaireItem("jour", {
                    readonly: true,
                    largeur: 15,
                }),
                new FormulaireItem("hrdeb", {
                    required: false,
                    disabled: false,
                    largeur: 20,
                }),
                new FormulaireItem("ldmhrdeb", {
                    required: false,
                    disabled: false,
                    largeur: 20,
                }),
                new FormulaireItem("aftcleint", {
                    resourceParams: (data: any) => ({
                        demcleint: data.$ancetre.demcleint,
                        asgcleint: data.$ancetre.asgcleint,
                        dtjdat: data.dtjdat,
                        occcleint: data.occcleint,
                    }),
                    largeur: 25,
                    disabled: (data: any) => (data.vaeoccaft !== "OBL"),
                    hidden: (data: any) => (data.vaeoccaft !== 'OBL'),
                    required: (data: any) => (data.vaeoccaft === 'OBL')
                }),
                new FormulaireItem("dercleint", {
                    resourceParams: (data: any) => ({
                        demcleint: data.$ancetre.demcleint,
                        dtjdat: data.dtjdat,
                        asgcleint: data.$ancetre.asgcleint,
                    }),
                    largeur: 25,
                    hidden: (data: any) => (data.vaeoccder !== "OBL"),
                    disabled: (data: any) => (data.vaeoccder !== "OBL"),
                    required: (data: any) => (data.vaeoccder === 'OBL')
                }),
                new FormulaireItem("actcleint", {
                    resourceParams: (data: any) => ({
                        demcleint: data.$ancetre.demcleint,
                        ptadatfin: data.$ancetre.ptadatfin,
                        asgcleint: data.$ancetre.asgcleint,
                        vaeatftyp: data.$ancetre.vaeatftyp,
                        dtjdat: data.dtjdat,
                    }),
                    largeur: 30,
                }),
                new FormulaireItem("hrfin", {
                    required: false,
                    disabled: false,
                    largeur: 20,
                }),
                new FormulaireItem("ldmhrfin", {
                    required: false,
                    disabled: false,
                    largeur: 20,
                }),
                new FormulaireItem("drpcleint", {
                    resourceParams: (data: any) => ({
                        dercleint: data.dercleint,
                        dtjdat: data.dtjdat,
                    }),
                    disabled: (data: any) => data.vaeoccder !== "OBL",
                    largeur: 25,
                }),
                new FormulaireItem("dtjqte", {
                    disabled: true,
                    largeur: 15,
                }),
                new FormulaireItem("qtehor", {
                    disabled: true,
                    largeur: 15,
                }),
                new FormulaireItem("dtjflgcor", {
                    default: 0,
                    largeur: 20,
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem("imp", { largeur: 25 }),
                new FormulaireItem("dtjcom", {
                    enableCount: true,
                    disabled: false,
                    largeur: 100,
                }),
                new FormulaireItem("dtjhredeb", {
                    hidden: true,
                    largeur: 20,
                }),
                new FormulaireItem("dtjhrefin", {
                    hidden: true,
                    largeur: 20,
                }),
            ],
            { largeurDefaut: 33 }
        ),
    };
    vm.ttjMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: "ttjdat", largeur: 100 },
            { nom: "aftcod", largeur: 190 },
            { nom: "lemcod", largeur: 95 },
            { nom: "temcod", largeur: 95 },
            { nom: "posnumdoc", largeur: 95 },
            { nom: "unocod", largeur: 95 },
            { nom: "dernumdoc", largeur: 95 },
            { nom: "drpnumdoc", largeur: 95 },
            { nom: "imp", largeur: 95 },
            { nom: "tagcod", largeur: 95 },
        ],
        colonnesFixesDroite: [
            { nom: "flgcom", largeur: 95 },
            { nom: "ttjcom", largeur: 95 },
            { nom: "ttjqte", largeur: 95 },
            { nom: "ttjmnt", largeur: 95 },
            { nom: "flgerr", largeur: 70 },
        ],
        colonnesCachees: ["urgcleint"],
        formulaire: new Formulaire([
            new FormulaireItem("ttjdat", {
                required: true,
                largeur: 33,
            }),
            new FormulaireItem("aftcleint", {
                largeur: 33,
                resourceParams: (data: any) => ({
                    demcleint: data.$ancetre.demcleint,
                    ttjdat: data.ttjdat,
                    asgcleint: data.$ancetre.asgcleint,
                }),
            }),
            new FormulaireItem("lemcleint", {
                resourceParams: (data: any) => ({
                    demcleint: data.$ancetre.demcleint,
                    ttjdat: data.ttjdat,
                    ptadatfin: data.$ancetre.ptadatfin,
                    ptadatdeb: data.$ancetre.ptadatdeb,
                }),
                required: false,
                largeur: 33,
            }),
            new FormulaireItem("temcleint", {
                resourceParams: (data: any) => ({
                    urgcleint: data.urgcleint,
                    lemcleint: data.lemcleint,
                    asgcleint: data.$ancetre.asgcleint,
                }),
                required: false,
                largeur: 33,
            }),
            new FormulaireItem("poscleint", {
                resourceParams: (data: any) => ({
                    ttjdat: data.ttjdat,
                    demcleint: data.$ancetre.demcleint,
                    aftcleint: data.aftcleint,
                }),
                required: false,
                largeur: 33,
            }),
            new FormulaireItem("unocleintrh", {
                resourceParams: (data: any) => ({
                    ptadatfin: data.$ancetre.ptadatfin,
                    ptadatdeb: data.$ancetre.ptadatdeb,
                }),
                required: false,
                largeur: 33,
            }),
            new FormulaireItem("dercleint", {
                resourceParams: (data: any) => ({
                    demcleint: data.$ancetre.demcleint,
                    ttjdat: data.ttjdat,
                    asgcleint: data.$ancetre.asgcleint,
                }),
                required: false,
                largeur: 33,
            }),
            new FormulaireItem("drpcleint", {
                resourceParams: (data: any) => ({
                    dercleint: data.dercleint,
                    ttjdat: data.ttjdat,
                }),
                required: false,
                largeur: 33,
            }),
            new FormulaireItem("imp", {
                largeur: 33,
            }),
            new FormulaireItem("tagcleint", {
                resourceParams: (data: any) => ({
                    asgcleint: data.$ancetre.asgcleint,
                }),
                required: true,
                largeur: 33,
            }),
            new FormulaireItem("ttjcom", {
                largeur: 33,
            }),
            new FormulaireItem("ttjqte", {
                largeur: 33,
            }),
            new FormulaireItem("ttjmnt", {
                largeur: 33,
            }),
            new FormulaireItem("flgerr", {
                largeur: 33,
                disabled: true,
            }),
        ]),
    };

    vm.bdcMultiOccurrenceOptions = {
        bloc: "bdc",
        colonnesVisibles: [
            { nom: 'dbcdat', largeur: 20 },
            { nom: 'dbcqte', largeur: 20 },
            { nom: 'vaesysprvbqe', largeur: 20 },
            { nom: 'vaesystrsbqe_dsc', largeur: 20 },
            { nom: 'dbscod1', largeur: 20 },
            { nom: 'dbsdsc1', largeur: 20 },
            { nom: 'dbscod2', largeur: 20 },
            { nom: 'dbsdsc2', largeur: 20 },
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25],
        }),
    };
    vm.bqeMultiOccurrenceOptions = {
        bloc: "bqe",
        colonnesFixesGauche: [{ nom: "bqecod", largeur: 130 }],
        colonnesVisibles: [
            { nom: "bqedscabr", largeur: 130 },
            { nom: "slddebpxt", largeur: 130 },
            { nom: "sumbqaqte", largeur: 130 },
            { nom: "sumbqaqteglo", largeur: 130 },
            { nom: "totqte", largeur: 130 },
            { nom: "dbsqte", largeur: 130 },
            { nom: "unccod", largeur: 130 },
        ],
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 20, 30],
        }),
        multiOccurrenceOptionsEnfant: vm.bdcMultiOccurrenceOptions,
    };
    vm.objMultiOccurrenceOptions = {
        navigatePage: (data) => getForageItem(data),
        navigateParams: (rowData: any) => ({
            id: rowData.trncleintref,
        }),
        colonnesFixesGauche: [
            { nom: "mnemonique", largeur: 130 },
            { nom: "code", largeur: 130 },
        ],
        colonnesVisibles: [
            { nom: "datdeb", largeur: 130 },
            { nom: "datfin", largeur: 130 },
            { nom: "temcod", largeur: 130 },
            { nom: "temdsc", largeur: 130 },
            { nom: "typcod", largeur: 130 },
            { nom: "typdsc", largeur: 130 },
            { nom: "urgcod", largeur: 130 },
            { nom: "urgdsc", largeur: 130 },
            { nom: "strcod", largeur: 130 },
            { nom: "strdsc", largeur: 130 },
            { nom: "unocod", largeur: 130 },
            { nom: "unodsc", largeur: 130 },
        ],
        colonnesFixesDroite: [
            { nom: "mntdisp", largeur: 130 },
            { nom: "qtedisp", largeur: 130 },
        ],
        colonnesCachees: ["cleint", "trncleintref"],
    };
    function getForageItem(rowData: any): string {
        if (rowData.mnemonique === "AFT") {
            return rr0006Route.NAME;
        }
        if (rowData.mnemonique === "DER") {
            return rr0074Route.NAME;
        }
    }
}
