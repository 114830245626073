import {IComponentController} from 'angular';
import {IEcranContextController} from '../../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';


interface IPageMc2048 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;    
    finMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ecranContextCtrl: IEcranContextController;   
}

/* @ngInject */
export default function Mc2048Controller()  {
    const vm: IPageMc2048 = this;

    vm.monoOccurrenceOptions = {};
 
        // Multi Étapes
        vm.finMultiOccurrenceOptions = {
            fonctions: {
                rechercheCollapsable: false
            },
            colonnesFixesGauche:[
                { nom: 'peccod', largeur: 120 },
                { nom: 'cifcod', largeur: 120 }                
            ],
            colonnesVisibles: [
                { nom: 'cptcod', largeur: 120 },
                { nom: 'cptdsc', largeur: 180 },
                { nom: 'unacod', largeur: 120 },
                { nom: 'unadsc', largeur: 260 },
                { nom: 'tyhdsc', largeur: 140 },
                { nom: 'trfdsc', largeur: 180 },
                { nom: 'trfmntdbt', largeur: 100, total: true },
                { nom: 'trfmntcrt', largeur: 100, total: true }
            ]         
        };

}
