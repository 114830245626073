import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuItemFactoryClass} from "../../../../../core/services/menu/menu-item-factory.service";
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';

interface IPageGL0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePEROptions: IMultiOccurrenceOptions;
    multiOccurrenceCPTDOCOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0004Controller(gl0006Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemFactory: IMenuItemFactoryClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         isForageValide: IIsForageValideService) {
    const vm: IPageGL0004 = this;
    const detailsMultiOccurrenceOptions: IMultiOccurrenceOptions = {
        bloc: 'det',
        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]),
        fonctions:{
            nouveau: false,
            edition: false,
            supprime: false,
            suiviModification: false
        },
        colonnesVisibles: [
            {nom: 'peccod', largeur: 131},
            {nom: 'tydcod', largeur: 89},
            {nom: 'docaffdat01', largeur: 100},
            {nom: 'docaffnumdoc01', largeur: 184},
            {nom: 'docaffnumdoc01ori', largeur: 189},
            {nom: 'tydcodori', largeur: 89},
            {nom: 'docrefcod02', largeur: 131},
            {nom: 'docrefnom02', largeur: 263},
            {nom: 'docaffdsc01', largeur: 263},
            {nom: 'docreqcod03', largeur: 89},
            {nom: 'docreqnom03', largeur: 263},
            {nom: 'trfqte', largeur: 139},
            {nom: 'reqdatdep', largeur: 120},
            {nom: 'reqdatret', largeur: 120}
        ],
        colonnesFixesDroite: [
            {nom: 'docaffmnt01', largeur: 126},
            {nom: 'impact', largeur: 126}
        ],
        colonnesCachees: [
            'ecrcod'
        ]
    };

    vm.multiOccurrenceCPTDOCOptions = {
        bloc: 'cptdoc',
        details: {lblTitre: 'G_LBL_DETAIL_DOC', multiOccurrenceOptions: detailsMultiOccurrenceOptions},
        fonctions:{
            nouveau: false,
            edition: false,
            supprime: false,
            suiviModification: false
        },
        colonnesFixesGauche: [
            {nom: 'tyddsc', largeur: 500}
        ],
        colonnesVisibles: [
            {nom: 'cummnt', largeur: 126},
            {nom: 'cumqte', largeur: 126}
        ],
        colonnesCachees: [
            'pecper'
        ]
    };

    vm.multiOccurrencePEROptions = {
        bloc: 'per',
        details: {lblTitre: 'G_LBL_DETAIL_DOC', multiOccurrenceOptions: detailsMultiOccurrenceOptions},
        fonctions:{
            nouveau: false,
            edition: false,
            supprime: false,
            suiviModification: false
        },
        colonnesFixesGauche:[
            {nom: 'pecper', largeur: 500}
        ],
        colonnesVisibles: [
            {nom: 'cummnt', largeur: 126},
            {nom: 'cumqte', largeur: 126}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCPTDOCOptions
    };

    vm.multiOccurrenceOptions = {
        navigatePage: gl0006Route.NAME,
        navigateParams: (rowData: any, multiOccurrence: IMultiOccurrence) => (
            Object.assign({}, multiOccurrence.etatSelected.criteresSuggeresData, {cptcleint: rowData.cptcleint})
        ),
        fonctions:{
            nouveau: false,
            edition: false,
            supprime: false,
            suiviModification: false,
            criteresSuggeresVisibles: localStorage.getItem('forageFromMenu') === 'oui',
            rechercheCollapsable: false
        },
    criteresSuggeres: new Formulaire([
        new FormulaireItemDivider('LBL_ANNEE_FIN'),
        new FormulaireItem('annfin', {
            required: true
        }),
        new FormulaireItem('perdeb', {
            required: true,
            default: 1
        }),
        new FormulaireItem('perfin', {
            required: true
        }),
        new FormulaireItem('tymcleint', {
            required: true
        }),
        new FormulaireItemDivider('LBL_COMPTE'),
        new FormulaireItem('imput', {default: true}),
        new FormulaireItem('vaesysnatctb', {
            required: true,
            disabled: (data: any) => {
                return data.cptcleint;
            }
        }),
        new FormulaireItem('soncleint', {
        }),
    ]),
        colonnesFixesGauche:[
            {nom: 'cptcod', largeur: 110},
            {nom: 'cptdsc', largeur: 390}
        ],
        colonnesVisibles: [
            {nom: 'cummnt_deb', largeur: 126},
            {nom: 'cummnt_var', largeur: 126},
            {nom: 'cummnt_fin', largeur: 126},
            {nom: 'cumqte_deb', largeur: 126},
            {nom: 'cumqte_var', largeur: 126},
            {nom: 'cumqte_fin', largeur: 126}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrencePEROptions
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcod}.ecran`),
            (data: any) => ({
                id: data.doccleint
            }),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('gl0004', data.ecrcod);
                }
            }
        )
    }
}
