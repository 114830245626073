import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCp2013 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAutresDetails: IFormulaire;
    coaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp2013Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    cp2012Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass
    ) {
    const vm: IPageCp2013 = this;


    vm.monoOccurrenceOptions = {
        infosMonetaires: {
            blocs: ['coa'],
            largeurTexte: 150,
            donnees:
                [
                    { nom: 'mnt', libelle: 'LBL_MONTANT' },
                    { nom: 'dev', libelle: 'LBL_DEVISE' }
                ]
        },
        formulaire: new Formulaire('LBL_BLC_CON', [
            new FormulaireItem('refcleint'),
            new FormulaireItem('radcleint'),
            new FormulaireItem('connumdoc'),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('connumref'),
            new FormulaireItem('conflgpij', { largeur: 25 }),
            new FormulaireItem('conflgnonren', { largeur: 25 }),
            new FormulaireItem('condatdeb'),
            new FormulaireItem('condatfin'),
            new FormulaireItem('vaeconstu'),
            new FormulaireItem('vecconcat'),
            new FormulaireItem('etccleint'),
            new FormulaireItem('cfncleint'),
            new FormulaireItem('condsc', { largeur: 100, enableCount: true })
        ])
    };

    vm.formulaireAutresDetails = new Formulaire('LBL_AUTRES_DETAILS', [
        new FormulaireItem('mopcleint'),
        new FormulaireItem('entcleint'),
        new FormulaireItem('empcleintdep'),
        new FormulaireItem('srvcleint'),
        new FormulaireItem('apocleint'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('connumrescd'),
        new FormulaireItem('connumresca')
    ], { largeurDefaut: 50 });

    vm.coaMultiOccurrenceOptions = {
        bloc: 'coa',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cp2012Route.NAME,
                (rowData: any) => ({
                    id: rowData.coacleint
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'connumdocseq', largeur: 100},
            {nom: 'coaann', largeur: 120},
            {nom: 'coamnt', largeur: 126},
            {nom: 'coamntcon', largeur: 126},
            {nom: 'coaseqave', largeur: 100},
            {nom: 'coamntdepnet', largeur: 126},
            {nom: 'intcodreq', largeur: 120},
            {nom: 'intnomreq', largeur: 200},
            {nom: 'sysboncmd', largeur: 150},
            {nom: 'coaflgini', largeur: 100},
            {nom: 'coadateff', largeur: 126},
            {nom: 'peccod', largeur: 120}
        ]
    }
}
