import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAc0072 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    daoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    daoMultiOccurrence: IMultiOccurrence;
    dvnMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0072Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite ) {

    const vm: IPageAc0072 = this;

    const changeType = new MenuItemAction(
        'LBL_BTN_CHANGE_TYPE',
        'BOUCHGTYP',
        () => ({}),
        {
            icon: '',
            iconButton: false,
            fonction: 'BOUCHGTYP',
            successAction: () => { vm.monoOccurrence.fetchData(); },
            disabled: !(ecranDetails.valeurs.flgmod)
        }
    );

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true,
            boutonDupliquer: false,
            suiviModification: false,
            exportation: false
        },
        menus: [
            new MenuItemAction(
                'LBL_BOUTON_GEN_IMP',
                'BOUGENIMP',
                () => ({}),
                {
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    fonction: 'BOUGENIMP',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BOUTON_GEN_IMP',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('flgdelimp', { required: true, largeur: 100 }),
                        ])
                    },        
                    successAction: () => {
                        vm.monoOccurrence.fetchData(); },
                    disabled: !(ecranDetails.valeurs.flgmod)
                })
        ],        
        formulaire: new Formulaire('', [
            new FormulaireItem('dacnumdoc'),
            new FormulaireItem('vaedactypvnt', { largeur: 33.33, menu: [changeType]}),
        ], { largeurDefaut: 50 })
    };

    vm.dvnMultiOccurrenceOptions = {
        bloc: 'dvn',
        editionRapideActive: true,
        fonctions: {
            editionRapide: true,
            editionStandard: true,
            nouveau: ecranDetails.valeurs.flgmod,
            edition: ecranDetails.valeurs.flgmod,
            supprime: ecranDetails.valeurs.flgmod
        },
        colonnesFixesGauche: [
            {nom: 'impcod', largeur: 320},
            {nom: 'dvnprc', largeur: 120, total: true}
        ],
        colonnesVisibles: [
            {nom: 'remcod', largeur: 100},
            {nom: 'dvnmntsantax', largeur: 126, total: true},
            {nom: 'dvnmnttaxfed', largeur: 126, total: true},
            {nom: 'dvnmntrmbfed', largeur: 126, total: true},
            {nom: 'dvnmnttaxpro', largeur: 126, total: true},
            {nom: 'dvnmntrmbpro', largeur: 126, total: true},
            {nom: 'dvnmsgerr', largeur: 400 },
        ],
        colonnesFixesDroite: [
            {nom: 'dvnmntdepnet', largeur: 126, total: true},
            {nom: 'dvnflgmsg', largeur: 126}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('impcod'),
            new FormulaireItem('dvnprc'),
            new FormulaireItem('remcleint'),
            new FormulaireItem('dvnmntsantax'),
            new FormulaireItem('dvnmnttaxfed'),
            new FormulaireItem('dvnmnttaxpro'),
            new FormulaireItem('dvnmntrmbfed'),
            new FormulaireItem('dvnmntrmbpro'),
            new FormulaireItem('dvnmntdepnet')
        ], { largeurDefaut: 33.33 }),
        colonnesEditionRapide: [
            {nom: 'impcod', largeur: 280},
            {nom: 'dvnprc', largeur: 120},
            {nom: 'remcleint', largeur: 130},
            {nom: 'dvnmntsantax', largeur: 126},
            {nom: 'dvnmnttaxfed', largeur: 126},
            {nom: 'dvnmntrmbfed', largeur: 126},
            {nom: 'dvnmnttaxpro', largeur: 126},
            {nom: 'dvnmntrmbpro', largeur: 126},
            {nom: 'dvnmntdepnet', largeur: 126}
        ]
    }

    vm.daoMultiOccurrenceOptions = {
        bloc: 'dao',
        colonnesVisibles: [
            {nom: 'dailig', largeur: 110, hidden: (data:any) => (data.$ancetre.typvnt === 'DAC')},
            {nom: 'daidsc', largeur: 200, hidden: (data:any) => (data.$ancetre.typvnt === 'DAC')},
            {nom: 'cptcod', largeur: 110},
            {nom: 'cptdsc', largeur: 200, hidden: (data:any) => (data.$ancetre.typvnt === 'DAI')},
            {nom: 'daoqte', largeur: 126, hidden: (data:any) => (data.$ancetre.typvnt === 'DAC')},
            {nom: 'daonbrite', largeur: 100, hidden: (data:any) => (data.$ancetre.typvnt === 'DAI')},
            {nom: 'daomntsantax', largeur: 126, total: true},
            {nom: 'daomnttaxfed', largeur: 126, total: true},
            {nom: 'daomnttaxpro', largeur: 126, total: true},
            {nom: 'daomntavetax', largeur: 126, total: true},
            {nom: 'daomsgerr'   , largeur: 400}
        ],
        colonnesFixesDroite: [
            {nom: 'daoflgmsg'   , largeur: 120}
        ],
        multiOccurrenceOptionsEnfant: vm.dvnMultiOccurrenceOptions
    };

}
