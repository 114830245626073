import IThemingProvider = angular.material.IThemingProvider;
import {module} from 'angular';

import {IThemesConfig} from '../constants/themes.constant';

export default module('app.configs.theme', [
    'app.constants.themes'
]).config(ThemeConfig);

/* @ngInject */
function ThemeConfig($mdThemingProvider: IThemingProvider,
                     Themes: IThemesConfig) {

    // Configuration du thème
    $mdThemingProvider.theme('default')
        .primaryPalette(Themes.DEFAULT.PRIMARY.NAME, Themes.DEFAULT.PRIMARY.HUES)
        .accentPalette(Themes.DEFAULT.ACCENT.NAME, Themes.DEFAULT.ACCENT.HUES)
        .warnPalette(Themes.DEFAULT.WARN.NAME, Themes.DEFAULT.WARN.HUES)
        .backgroundPalette(Themes.DEFAULT.BACKGROUND.NAME, Themes.DEFAULT.BACKGROUND.HUES);

    $mdThemingProvider.theme('cyan')
        .primaryPalette(Themes.CYAN.PRIMARY.NAME, Themes.CYAN.PRIMARY.HUES)
        .accentPalette(Themes.CYAN.ACCENT.NAME, Themes.CYAN.ACCENT.HUES)
        .warnPalette(Themes.CYAN.WARN.NAME, Themes.CYAN.WARN.HUES)
        .backgroundPalette(Themes.CYAN.BACKGROUND.NAME, Themes.CYAN.BACKGROUND.HUES);

    $mdThemingProvider.theme('deep-purple')
        .primaryPalette(Themes.DEEP_PURPLE.PRIMARY.NAME, Themes.DEEP_PURPLE.PRIMARY.HUES)
        .accentPalette(Themes.DEEP_PURPLE.ACCENT.NAME, Themes.DEEP_PURPLE.ACCENT.HUES)
        .warnPalette(Themes.DEEP_PURPLE.WARN.NAME, Themes.DEEP_PURPLE.WARN.HUES)
        .backgroundPalette(Themes.DEEP_PURPLE.BACKGROUND.NAME, Themes.DEEP_PURPLE.BACKGROUND.HUES);

    $mdThemingProvider.theme('teal')
        .primaryPalette(Themes.TEAL.PRIMARY.NAME, Themes.TEAL.PRIMARY.HUES)
        .accentPalette(Themes.TEAL.ACCENT.NAME, Themes.TEAL.ACCENT.HUES)
        .warnPalette(Themes.TEAL.WARN.NAME, Themes.TEAL.WARN.HUES)
        .backgroundPalette(Themes.TEAL.BACKGROUND.NAME, Themes.TEAL.BACKGROUND.HUES);

    $mdThemingProvider.theme('blue')
        .primaryPalette(Themes.BLUE.PRIMARY.NAME, Themes.BLUE.PRIMARY.HUES)
        .accentPalette(Themes.BLUE.ACCENT.NAME, Themes.BLUE.ACCENT.HUES)
        .warnPalette(Themes.BLUE.WARN.NAME, Themes.BLUE.WARN.HUES)
        .backgroundPalette(Themes.BLUE.BACKGROUND.NAME, Themes.BLUE.BACKGROUND.HUES);

    $mdThemingProvider.theme('grey')
        .primaryPalette(Themes.GREY.PRIMARY.NAME, Themes.GREY.PRIMARY.HUES)
        .accentPalette(Themes.GREY.ACCENT.NAME, Themes.GREY.ACCENT.HUES)
        .warnPalette(Themes.GREY.WARN.NAME, Themes.GREY.WARN.HUES)
        .backgroundPalette(Themes.GREY.BACKGROUND.NAME, Themes.GREY.BACKGROUND.HUES);
}
