import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IData} from '../../../../../core/services/data-linker.service';

interface IPageAs0081 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    newOpened: boolean;
}
interface IPageAs0081Params {
    eplcleint: number;
    unpcleint: number;
    datede: string;
    datea: string;
    creation: string;
}
/* @ngInject */
export default function As0081Controller(Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         Formulaire: IFormulaireClass,
                                         $stateParams: IPageAs0081Params,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageAs0081 = this;

    vm.newOpened = !!$stateParams.creation;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: (!$stateParams.unpcleint && !$stateParams.eplcleint && !$stateParams.datede && !$stateParams.datea)
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_POINTAGE'),
            new FormulaireItem('unpcleint', {
                required: true,
                readonly: (data: IData) => data.$params.unpcleint,
                default: $stateParams.unpcleint,
                ignoreEtat: !!$stateParams.unpcleint
            }),
            new FormulaireItem('datede', {
                required: true,
                readonly: (data: IData) => data.$params.datede,
                default: $stateParams.datede,
                ignoreEtat: !!$stateParams.datede
            }),
            new FormulaireItem('datea', {
                required: false,
                readonly: (data: IData) => data.$params.datea,
                default: $stateParams.datea,
                ignoreEtat: !!$stateParams.datea
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eplcleint', {
                required: false,
                readonly: (data: IData) => data.$params.eplcleint,
                default: $stateParams.eplcleint,
                ignoreEtat: !!$stateParams.eplcleint
            }),
            new FormulaireItem('vaepodtyptrs', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('correction', {
                default: ''
            }),
            new FormulaireItem('ignore', {
                default: ''
            }),
            new FormulaireItem('interprete', {
                default: ''
            }),
            new FormulaireItem('erreur', {
                default: ''
            }),
            new FormulaireItemDivider('LBL_HORODATEUR'),
            new FormulaireItem('itecleint', {
                required: false
            }),
            new FormulaireItem('hdtcleint', {
                required: false
            })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_IGNORE',
                'BOUIGN', {
                    selection: {
                        nom: 'ignore'
                    }
                }),
            new MenuItemSelection(
                'LBL_BTN_NEPAS_IGNORE',
                'BOUNEPIGN', {
                    selection: {
                        nom: 'nepasignore'
                    }
                })
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'hdtcod', largeur: 100},
            {nom: 'itecoddsc', largeur: 200},
            {nom: 'poddathre', largeur: 140},
            {nom: 'vaepodtyptrs_dsc', largeur: 140},
            {nom: 'poddathrecor_aff', largeur: 140},
            {nom: 'vaepodtyptrscor_dsc', largeur: 140},
            {nom: 'podflgignman', largeur: 140},
            {nom: 'podflgignsys', largeur: 140},
            {nom: 'podindint', largeur: 140},
            {nom: 'podflgerr', largeur: 100}
        ],
        colonnesCachees: [
            'unpcleint',
            'poepoderr'
        ],
        formulaire: new Formulaire([
                new FormulaireItem('eplcleint', {default: $stateParams.eplcleint}),
                new FormulaireItem('unpcleint', {
                    default: () => (
                        vm.multiOccurrence.etatSelected.criteresSuggeresData &&
                        vm.multiOccurrence.etatSelected.criteresSuggeresData.unpcleint
                    )
                }),
                new FormulaireItem('podflgignman'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('itecleintcor'),
                new FormulaireItemDivider('LBL_DIV_HOR'),
                new FormulaireItem('poddathrecor', {default: $stateParams.datede}),
                new FormulaireItem('vaepodtyptrscor')
            ], {
            largeurDefaut: 25
            }
        )
    };
}
