/**
 * Composant représentant une card contenant les informations financières .
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import CardInfosFinancieresController from './ex-card-infos-financieres.controller';

export default module('app.components.ex-card-infos-financieres', []).component('exCardInfosFinancieres', CardInfosFinancieresFactory());

function CardInfosFinancieresFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MONO_OCCURRENCE,
            {
                infosFinancieresOptions: '<'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: CardInfosFinancieresController,
        controllerAs: 'vm',
        template: require('./ex-card-infos-financieres.html')
    };
}
