import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageMr0201 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePen: IFormulaire;
}

/* @ngInject */
export default function Mr0201Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageMr0201 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        id: vm.valeursEcran.pencleint,
        fonctions: {
            boutonDupliquer: false
        },
    };

    vm.formulairePen = new Formulaire('', [
        new FormulaireGroupe('LBL_GRP_COM', new Formulaire([
            new FormulaireItem('penflgvalimp', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('penflgvalche', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('penflgderobl', { largeur: 33.33 }),
            new FormulaireItem('penflgposobl', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pennbrjrssubbas', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pennbrjrssubppa', { largeur: 33.33 })
        ]), { largeur: 100 }),
        new FormulaireGroupe('LBL_GRP_PRE_ENG', new Formulaire([
            new FormulaireItem('pentauemrae', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pentauemrcsst', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaesystecsalpre', { largeur: 33.33 }), // Choix du salaire demande de pers.
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pennbrmoiubract', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
        ]), { largeur: 100 }),
        new FormulaireGroupe('LBL_GRP_ENG', new Formulaire([
            new FormulaireItem('penflgenghoraff', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('penflgresorg', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('penengmaxnbrmoi', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('penengposnbrper', { largeur:66.66 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eclcleintdplree', { largeur: 100 }),
            new FormulaireItemSautDeLigne()
        ]), { largeur: 100 }),
        new FormulaireGroupe('LBL_GRP_PB', new Formulaire([
            new FormulaireItem('vaesystecsalvac', { largeur: 33.33 }),  // Choix du salaire poste vacant
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rsccleintvac', { largeur: 33.33 })
        ]), { largeur: 100 })
    ]);
}
