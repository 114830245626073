import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMonoOccurrence} from "../../../../../core/services/mono-occurrence.service";
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCP0070 extends IComponentController {
    formulaireToa: IFormulaire;
    monoOccurrence: IMonoOccurrence;
}
interface ICp0070Params {
    id: string;
    tsfcleint: string;
    trncleint: string;
}
/* @ngInject */
export default function Cp0070Controller($stateParams: ICp0070Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageCP0070 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireToa = new Formulaire('LBL_ADRESSE', [
            new FormulaireItem('tsfcleint', {
                default: $stateParams.tsfcleint,hidden:true
            }),
            new FormulaireItem('toacod'),
            new FormulaireItem('toadatdeb'),
            new FormulaireItem('toaflgpai'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('toaadrl01', {largeur: 50}),
            new FormulaireItem('toaadrl02', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('toavil'),
            new FormulaireItem('toapro'),
            new FormulaireItem('toapay'),
            new FormulaireItem('toacodpos'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('toaadrcou', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('toacodced'),
            new FormulaireItem('toanumtel'),
            new FormulaireItem('toanumtelpos'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('toanumfax'),
            new FormulaireItem('toafaxcodspe', {largeur: 50}),
            new FormulaireItem('toaflgfaxint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('toanom'),
            new FormulaireItem('toanumcli')
        ], {
            largeurDefaut: 25
        });
    }
}
