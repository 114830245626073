import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import IStateService = angular.ui.IStateService;
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageGL0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Gl0002Controller(gl0003Route: IRoute,
    $state: IStateService,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageGL0002 = this;
    vm.valeursEcran = ecranDetails;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup', { hidden: () => vm.valeursEcran.valeurs.vaehldtypimp !== 1 }),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        filtresParametres: [
            {
                colonne: 'ecjcleint'
            }
        ],
        navigatePage: gl0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                ecjcleint: rowData.ecjcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(gl0003Route.NAME)
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_COPIE_ECRITURE',
                'BOUCOP',
                (rowData: any) => ({ ecjcleint: rowData.ecjcleint }),
                {
                    icon: 'content_copy',
                    successAction: (rowData: any) => {
                        $state.go(gl0003Route.NAME, { id: rowData.doccleintnew, menuId: $stateParams.menuId });
                    },
                    hidden: (rowData: any) => (!rowData.ecjflgcop)
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'ecjnumdoc', largeur: 136, hidden: $stateParams.ecjcleint },
            { nom: 'ecjnumdocseq', largeur: 65 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'tydcod', largeur: 65 },
            { nom: 'vececjcat', largeur: 95 },
            { nom: 'ecjdsc', largeur: 181 },
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 186 },
            { nom: 'etddsc', largeur: 180 },
            { nom: 'ecjflgref', largeur: 70 },
            { nom: 'ecjflgverfin', largeur: 105 },
            { nom: 'ecjflgmsg', largeur: 100 },
            { nom: 'flgadhoc', largeur: 110 },
            { nom: 'intcodcib', largeur: 110 },
            { nom: 'intnomcib', largeur: 186 },
            { nom: 'usrcodcre', largeur: 95 },
            { nom: 'ecjflgautrep', largeur: 85 },
            { nom: 'ecjflgpij', largeur: 105 },
            { nom: 'ecjmnttot', largeur: 126 }
        ],
        colonnesDisponibles: [
            { nom: 'etddatcre', largeur: 140 },
            { nom: 'flgcou', largeur: 70 },
            { nom: 'sstcod', largeur: 70 },
            { nom: 'sstdscabr', largeur: 140 }
        ],
        colonnesCachees: [
            'ecjcleint',
            'ecjflgcop',
            'doccleintnew'
        ]
    };

}
