import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRT0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rt0011Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRT0011 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'rmpdatgbl', largeur: 140},
            {nom: 'rmpdatpub', largeur: 140},
            {nom: 'rmpdsc', largeur: 250},
            {nom: 'rmpflgact', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rmpdatgbl', {largeur: 10}),
            new FormulaireItem('rmpdatpub', {largeur: 10}),
            new FormulaireItem('rmpdsc', {largeur: 50}),
            new FormulaireItem('rmpflgact', { default: 1 , largeur: 10})
        ])
    };
}
