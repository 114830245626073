import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IStateService } from "angular-ui-router";

interface IPageCp0063 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0063Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass, MenuItemAction: IMenuItemActionClass,
    cp0066Route: IRoute, $state: IStateService,
    cp2001Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService) {
    const vm: IPageCp0063 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cp0066Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cp0066Route.NAME)
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemForage("G_LBL_PIECES_JOINTES", cp2001Route.NAME, data => ({ rldcleint: data.rldcleint })),
            new MenuItemAction("LBL_BTN_COPIE", "BOUCOP", () => ({}), { iconButton: false, icon: "content_copy", successAction: ({ trncleint }) => $state.go(cp0066Route.NAME, { id: trncleint }), hidden: (data: any) => !data.rldcleint })
        ]),
        fonctions: {
            selectionCycle: false,
            rechercheCollapsable: false
        },
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            { nom: 'rldnumdoc', largeur: 100 },
            { nom: 'refcod', largeur: 100 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'rlddatdep', largeur: 100 },
            { nom: 'rlddatret', largeur: 100 },
            { nom: 'rldjusdep', largeur: 200 },
            { nom: 'mntrclcan', largeur: 102 },
            { nom: 'mntdifcan', largeur: 100 },
            { nom: 'ecldsceta', largeur: 200 },
            { nom: 'mntavd', largeur: 140 },
            { nom: 'totalrcl', largeur: 108 },
            { nom: 'zcrcleint_dsc', largeur: 190 },
            { nom: 'rldflgmodint', largeur: 95 },
            { nom: 'rldindrev', largeur: 100 },
            { nom: 'intcodcib', largeur: 120 },
            { nom: 'intnomcib', largeur: 160 },
            { nom: 'usrnomcre', largeur: 160 },
            { nom: 'ttcdsc', largeur: 160 },
            { nom: 'trnflgstaref', largeur: 100 },
            { nom: 'trnflgstafin', largeur: 100 },
            { nom: 'trnflgstafer', largeur: 100 },
            { nom: 'trnflgstaann', largeur: 100 },
            { nom: 'ecldsceta2', largeur: 200 }
        ],
        colonnesDisponibles: [
            { nom: "datini", largeur: 150 },
            { nom: "datder", largeur: 150 }
        ]
    };
}
