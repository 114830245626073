import { IComponentController } from 'angular';
import { IMonoOccurrence } from '../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../core/services/formulaire/formulaire-item.service';
import { IFonctionTransversaleService } from '../../../../core/services/fonction-transversale.service';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IProfil } from '../../../../core/resources/profil.resource';

interface IComposantCardInfosFinancieres extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    infosFinancieresOptions: IInfosFinancieresOptions;
    infosFinancieres: Array<IInfoFinanciere>;

    open(index: number): void;
    shouldShowButton(button: string): boolean;
    hasAnyButton(): boolean;
}

export interface IInfosFinancieresOptions {
    [onglet: string]: boolean;
    impactBudgetaire?: boolean;
    impactFinancier?: boolean;
    disponibilite?: boolean;
    imputation?: boolean;
    voirImpact?: boolean;
    depensesRevenus?: boolean;
    ventilation?: boolean;
}

export interface IInfoFinanciere {
    lblTitre: string;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    fonction: string;
}

interface IOngletsInfosFinancieres {
    [onglet: string]: IOngletInfosFinancieres;
}

interface IOngletInfosFinancieres {
    ordreAffichage: number;
    lblTitre: string;
    fonction: string;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function CardInfosFinancieresController(profil: IProfil,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    fonctionTransversale: IFonctionTransversaleService) {
    const vm: IComposantCardInfosFinancieres = this;

    vm.$onInit = $onInit;
    vm.open = open;
    vm.shouldShowButton = shouldShowButton;

    function $onInit(): void {
        const onglets = initOnglets();

        vm.infosFinancieres = Object.keys(vm.infosFinancieresOptions)
            .filter((onglet: string) => { return vm.infosFinancieresOptions[onglet] && onglets[onglet]; })//On garde seulement les onglets désirés et ceux auquels on a droit.
            .sort((onglet1: string, onglet2: string) => {
                return onglets[onglet1].ordreAffichage - onglets[onglet2].ordreAffichage;
            })//On s'assure que les onglets sont dans le bon ordre
            .reduce((infosFinancieres: Array<IInfoFinanciere>, onglet: string) => {
                infosFinancieres.push({
                    lblTitre: onglets[onglet].lblTitre,
                    fonction: onglets[onglet].fonction,
                    multiOccurrenceOptions: onglets[onglet].multiOccurrenceOptions
                });
                return infosFinancieres;
            }, []);
    }

    function open(index: number): void {
        fonctionTransversale.openInfosFinancieres(index, vm.infosFinancieres);
    }

    function initOnglets(): IOngletsInfosFinancieres {
        const IMPACT_BUDGETAIRE_FONCTION = 'IMPBDG';
        const IMPACT_FINANCIER_FONCTION = 'IMPFIN';
        const DISPONIBILITE_FONCTION = 'BOUDSP';
        const doccleint = vm.monoOccurrence.cycleCleint === 'doccleint' ? vm.monoOccurrence.id : vm.monoOccurrence.data.doccleint;
        const onglets: IOngletsInfosFinancieres = {};

        if (vm.shouldShowButton(IMPACT_BUDGETAIRE_FONCTION)) {
            onglets.impactBudgetaire = {
                lblTitre: 'G_LBL_IMPACT_BUD',
                ordreAffichage: 2,
                fonction: IMPACT_BUDGETAIRE_FONCTION,
                multiOccurrenceOptions: {
                    srccod: 'mc1002',
                    fonctions: {
                        selectionnerDesColonnes: true,
                        selectionnerTri: true,
                        reinitialiser: true,
                        exportation: true,
                        supprime: false,
                        nouveau: false,
                        edition: false,
                        suiviModification: false,
                        importation: false,
                        enregistrerUnEtat: false
                    },
                    resourceParams: {
                        doccleint,
                        srccod: vm.ecranContextCtrl.stateParams.srccod
                    },
                    colonnesVisibles: [
                        { nom: 'peccod', largeur: 80 },
                        { nom: 'imputation', largeur: 289 },
                        { nom: 'tyhdsc', largeur: 158 },
                        { nom: 'trbmnt', largeur: 126, total: true },
                        { nom: 'prudscl01', largeur: 150 },
                        { nom: 'cptdsc', largeur: 162 }
                    ]
                }
            };
        }

        if (vm.shouldShowButton(IMPACT_FINANCIER_FONCTION)) {
            onglets.impactFinancier = {
                lblTitre: 'G_LBL_IMPACT_FIN',
                ordreAffichage: 3,
                fonction: IMPACT_FINANCIER_FONCTION,
                multiOccurrenceOptions: {
                    srccod: 'mc1000',
                    fonctions: {
                        selectionnerDesColonnes: true,
                        selectionnerTri: true,
                        reinitialiser: true,
                        exportation: true,
                        supprime: false,
                        nouveau: false,
                        edition: false,
                        suiviModification: false,
                        importation: false,
                        enregistrerUnEtat: false
                    },
                    resourceParams: {
                        doccleint,
                        srccod: vm.ecranContextCtrl.stateParams.srccod
                    },
                    colonnesVisibles: [
                        { nom: 'peccod', largeur: 80 },
                        { nom: 'cifcod', largeur: 80 },
                        { nom: 'cptcod', largeur: 89 },
                        { nom: 'cptdsc', largeur: 152 },
                        { nom: 'unacod', largeur: 89 },
                        { nom: 'unadsc', largeur: 152 },
                        { nom: 'tyhdsc', largeur: 152 },
                        { nom: 'trfdsc', largeur: 278 }
                    ],
                    colonnesFixesDroite: [
                        { nom: 'trfmntdbt', largeur: 126, total: true },
                        { nom: 'trfmntcrt', largeur: 126, total: true }
                    ]
                }
            };
        }

        if (vm.shouldShowButton(DISPONIBILITE_FONCTION)) {
            onglets.disponibilite = {
                lblTitre: 'G_LBL_DISPONIBILITE',
                ordreAffichage: 4,
                fonction: DISPONIBILITE_FONCTION,
                multiOccurrenceOptions: {
                    srccod: 'mc1005',
                    fonctions: {
                        selectionnerDesColonnes: true,
                        selectionnerTri: true,
                        reinitialiser: true,
                        exportation: true,
                        suiviModification: true,
                        supprime: false,
                        nouveau: false,
                        importation: false,
                        enregistrerUnEtat: false
                    },
                    disableSave: () => !profil.accesUsager.verificationFinGrh,
                    resourceParams: {
                        doccleint,
                        srccod: vm.ecranContextCtrl.stateParams.srccod
                    },
                    formulaire: new Formulaire([new FormulaireItem("dncflgautdsp", { required: true })]),
                    colonnesVisibles: [
                        { nom: 'vaesysnivctr_dsc', largeur: 194 },
                        { nom: 'dnccod', largeur: 361 },
                        { nom: 'vaesysnatctb_dsc', largeur: 84 },
                        { nom: 'mntdispo', largeur: 126 },
                        { nom: 'mntresv', largeur: 126 },
                        { nom: 'mntdispr', largeur: 126 },
                        { nom: 'dncmntreq', largeur: 126 },
                        { nom: 'mntmanq', largeur: 126 },
                        { nom: 'dncflgerr', largeur: 76 },
                        { nom: 'dncflgavr', largeur: 76 },
                        { nom: 'dncflgautdsp', largeur: 91, editable: profil.accesUsager.verificationFinGrh }
                    ],
                    details: {
                        lblTitre: 'G_LBL_RESERVE_FONDS',
                        multiOccurrenceOptions: {
                            bloc: 'rfu',
                            cycleId: doccleint,
                            resourceParams: {
                                srccod: vm.ecranContextCtrl.stateParams.srccod,
                                doccleint
                            },
                            formulaire: new Formulaire([
                                new FormulaireItem('rfocleint', {
                                    largeur: 20,
                                    required: true,
                                    modifAllow: false
                                }),
                                new FormulaireItem('rfoann', { readonly: true, largeur: 20 }),
                                new FormulaireItem('rfomntdsp', { readonly: true, largeur: 20 }),
                                new FormulaireItem('rfumntutl', { largeur: 20 }),
                                new FormulaireItem('rfudsc', { largeur: 20 })
                            ]),
                            fonctions: {
                                selectionnerDesColonnes: true,
                                selectionnerTri: true,
                                suiviModification: true,
                                reinitialiser: true,
                                exportation: true,
                                importation: false,
                                enregistrerUnEtat: false
                            },
                            colonnesVisibles: [
                                { nom: 'rfonumdoc', largeur: 152 },
                                { nom: 'rfoann', largeur: 76 },
                                { nom: 'rfomntdsp', largeur: 126 },
                                { nom: 'rfumntutl', largeur: 126 },
                                { nom: 'rfudsc', largeur: 194 }
                            ]
                        }
                    }
                }
            };
        }
        return onglets;
    }

    function shouldShowButton(button: string): boolean {
        const fonction = vm.ecranContextCtrl.ecranDetails.fonctions[`${vm.ecranContextCtrl.ecranSourceDetails.mnemonique.toUpperCase()}.${button}`];
        return typeof fonction === 'undefined' || fonction.flgacc === 1;
    }
}
