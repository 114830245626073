import {IComponentController, IFilterDate} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageRr0024 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireTsm: IFormulaire;
}

interface IRr0024Params {
    id: number;
    tytcleint: number;
    lemcleint: number;
    aftcleint: number;
    lsacleint: number;
    rrhcleintref: number;
}

/* @ngInject */
export default function Rr0024Controller(rr0108Route: IRoute,
                                         $stateParams: IRr0024Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         dateFilter: IFilterDate) {

    const vm: IPageRr0024 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.paramsEcran = ecranDetails.params;
        vm.libelleEnTete = ecranDetails.params.p_type_tsm === 'AFT' ? 'LBL_ENTETE_AFT' : 'LBL_ENTETE_LEM';

        vm.monoOccurrenceOptions = {
            menus: [
                new MenuItemEcran(
                    'LBL_FORAGE_PAR_EMB',
                    rr0108Route.NAME,
                    (data: any) => ({
                        stamnetab: 'TSM',
                        cpecleintref: data.tsmcleint,
                        trncleint: data.trncleint
                    }),
                    {
                        icon: 'subtitles',
                        iconButton: false,
                        secondaire: true,
                        hidden: !vm.valeursEcran.boucpe_flg
                    }
                )
            ]
        };

        vm.formulaireTsm = new Formulaire(ecranDetails.params.p_type_tsm === 'AFT' ? 'LBL_BLC_TSM_AFT' : ecranDetails.params.p_type_tsm === 'LEM' ? 'LBL_BLC_TSM_LEM' : 'LBL_BLC_TSM_TEM', [
            new FormulaireItem('tsmancdat', { default: vm.valeursEcran.ancdat }),         // Ancienne date
            new FormulaireItem('tsmdatvig', {
                default: !vm.valeursEcran.tsmdatvig && !vm.valeursEcran.tsmdatvig_flg ? dateFilter(new Date(), 'yyyy-MM-dd') : null  // Pour recréer le pre_insert dans Form
            }),
            new FormulaireItem('aftflgprinou', { default: vm.valeursEcran.aftflgpri }),   // Principale
            new FormulaireItem('tsmflgtagaut', { default: vm.valeursEcran.aftflgtagaut }),// Rémun. auto.
            new FormulaireItemSautDeLigne(),
            //new FormulaireItem('anc', { largeur: 100, default: vm.valeursEcran.anc }),// Ancien titre d'emploi                                 // Commentaire
            new FormulaireItem('tlecleintanc', { default: vm.valeursEcran.tlecleintanc }),// Ancien type                                           // Commentaire
            new FormulaireItem('temcleintanc', { largeur: 100, default: vm.valeursEcran.temcleintanc }),// Ancien titre                                           // Commentaire
            new FormulaireItemSautDeColonne({
                hidden: () => vm.valeursEcran.tlecleintanc_flg || vm.valeursEcran.aftflgprinou_flg
                           || vm.valeursEcran.tsmflgtagaut_flg || vm.valeursEcran.temcleintanc_flg
            }),
            new FormulaireItem('vactsmrai', { largeur: 50 }), //Raison
            new FormulaireItemSautDeLigne(),
            // Dans le cas où le type de transaction est une correction de date de fin, Départ définitif est aligné complètement à droite
            new FormulaireItem('tsmnoudat', { largeur: 50 }),                                            // Commentaire
            new FormulaireItemSautDeColonne({ largeur: 50,
                hidden: () => vm.valeursEcran.vaesyscaptem_flg || vm.valeursEcran.tlecleintnou_flg
                           || vm.valeursEcran.temcleintnou_flg
                           || vm.valeursEcran.tsmdatdebadm_flg || vm.valeursEcran.tsmdatfinadm_flg
                           || vm.valeursEcran.vaesyssaiftp_flg || vm.valeursEcran.tsmnoudat_flg
            }),
            new FormulaireItem('tsmdatdebadm'),                                           // Début renouvellement
            new FormulaireItem('tsmdatfinadm'),                                           // Fin renouvellement
            new FormulaireItem('vaesyscaptem', { largeur: 50 }),                          // Mode de capture
            new FormulaireItem('temcleintnou', { largeur: 50 }),                          // Nouveau titre
            new FormulaireItem('tlecleintnou', { largeur: 50 }),                          // Nouveau type
            new FormulaireItem('vaesyssaiftp', { largeur: 50 }),                          // Feuille de temps
            new FormulaireItem('tsmcom', { largeur: 50, enableCount: true }),             // Commentaire
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tafcleintanc', {largeur: 66, resourceParams: (data: any) => ({lemcleint: data.lemcleint}), default: vm.valeursEcran.tafcleintanc}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tafcleintnou',  { largeur: 50,
                resourceParams: () => ({ lemcleint: vm.valeursEcran.lemcleintaft })
            }),
            new FormulaireItem('tsmflgfinemp'),                                           // Confirmation date fin
            new FormulaireItem('tsmflgmajlem'),                                           // MAJ du lien d'emploi
            new FormulaireItem('tsmflgferrqf'),                                           // Fermer reconn. qualif.
            new FormulaireItem('tsmflgpospro', { default: vm.valeursEcran.tsmflgpospro }),// Possibilité de prolongation
            new FormulaireItem('tsmflgmazanc'),                                           // MàZ ancienn. CC.
            new FormulaireItem('tsmflgfinlem'),                                           // Départ définitif
            new FormulaireItemSautDeLigne(),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_TRANSACTION', new Formulaire([
                new FormulaireItem('tsmnumdoc'),
                new FormulaireItem('tsmdat', { default: dateFilter(new Date(), 'yyyy-MM-dd') }),
                new FormulaireItem('ttccleint', {
                    default: vm.valeursEcran.ttccleint,
                    resourceParams: () => {
                        return {
                            type_tsm: ecranDetails.params.p_type_tsm,
                            tytcleint: $stateParams.tytcleint
                        };
                    },
                }),
                new FormulaireItem('demcleint', { default: vm.valeursEcran.demcleint }),
                new FormulaireItem('lemcleint', { default: vm.valeursEcran.lemcleint }),
                new FormulaireItem('aftcleint', { default: $stateParams.aftcleint })
            ]))
        ], { largeurDefaut: 25 });
    }
}
