import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageAs0078 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0078Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageAs0078 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'asgcod', largeur: 125},
            {nom: 'asgdsc', largeur: 180}
        ],
        colonnesVisibles: [
            {nom: 'ahtcod', largeur: 100},
            {nom: 'ahtdsc', largeur: 262},
            {nom: 'ahthresem', largeur: 120},
            {nom: 'unccod', largeur: 89},
            {nom: 'uncdsc', largeur: 150},
            {nom: 'afecod', largeur: 100},
            {nom: 'afedsc', largeur: 180},
            {nom: 'vaeahtman_dsc', largeur: 89},
            {nom: 'cgacleint_dsc', largeur: 120},
            {nom: 'vaeahtreg_dsc', largeur: 157},
            {nom: 'tcycod', largeur: 89},
            {nom: 'tcydsc', largeur: 168},
            {nom: 'acydsc', largeur: 262},
            {nom: 'acydatvig', largeur: 100},
            {nom: 'acydursem', largeur: 72},
            {nom: 'acytmptrajrs', largeur: 91},
            {nom: 'acytmpremsem', largeur: 100},
            {nom: 'flgdethor', largeur: 76}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('asgcleint'),
            new FormulaireItem('ahtcod'   ),
            new FormulaireItem('ahtdsc'   ),
            new FormulaireItem('ahthresem'),
            new FormulaireItem('unccleint'),
            new FormulaireItem('afecleint'),
            new FormulaireItem('vaeahtman'),
            new FormulaireItem('cgacleint'),
            new FormulaireItem('vaeahtreg'),
        ], { largeurDefaut: 33.33 })
    };
}
