import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMr001 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0015Controller(rr0003Route: IRoute,
                                         rr0006Route: IRoute,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr001 = this;

    // Forage pour l'employé
    const forageDem = new MenuItemForage(
        rr0003Route.NAME,
        (data: any) => ({
            id: data.trncleint_dem
        })
    );

    // Forage vers la référence
    const forageRef = new MenuItemForage(
        'LBL_FOR_REF',
        (data: any) => (`secure.${data.ecrcod}.ecran`),
        (data:any) => ({
            id: data.cleint,
            demcleint: data.ecrcod === 'rr0014' ? data.demcleint : null
        }),
        { hidden: (data: any) => !data.ehvcleintref || data.stamnetabref === 'EFD' }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            criteresSuggeresVisibles:false
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'BTN_REGEN',
                'BOUGEN',
                () => ({}),
                {
                    icon: 'loop',
                    successAction: () => { vm.multiOccurrence.fetchDataList(); }
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_vclcleint')
        ]),
        colonnesCachees: [
            'trncleint_dem', 'ehvcleintref', 'ecrcod', 'cleint', 'stamnetabref',
            'aut__urgdsc', 'aut__unodsc', 'aut__strdsc', 'aut__postit', 'aut__temdsc'
        ],
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 120, menu: [forageDem]},
            {nom: 'eplnomprn', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'vaccod'    , largeur: 130},
            {nom: 'vacdsc'    , largeur: 262},
            {nom: 'ehvdatdeb' , largeur: 100},
            {nom: 'ehvdatfin' , largeur: 100},
            {nom: 'ehvdscabr' , largeur: 252},
            {nom: 'ehvdsclng' , largeur: 525},
            {nom: 'ehvqtemnt1', largeur: 140},
            {nom: 'ehvqtemnt2', largeur: 140},
            {nom: 'ehvqtemnt3', largeur: 140},
            {nom: 'ehvqtemnt4', largeur: 140},
            {nom: 'ehvqtemnt5', largeur: 140},
            {nom: 'dscref'    , largeur: 288, menu:[forageRef]},
            {nom: 'ehvcodurg' , largeur: 155},
            {nom: 'urgdsc'    , largeur: 362},
            {nom: 'ehvcoduno' , largeur: 126},
            {nom: 'unodsc'    , largeur: 362},
            {nom: 'ehvcodstr' , largeur: 126},
            {nom: 'strdsc'    , largeur: 362},
            {nom: 'ehvcodpos' , largeur: 126},
            {nom: 'postit'    , largeur: 362},
            {nom: 'ehvcodtem' , largeur: 126},
            {nom: 'temdsc'    , largeur: 362},
            {nom: 'vclcod'    , largeur: 110},
            {nom: 'evsdsc'    , largeur: 262}
        ],
        colonnesFixesDroite: [
            {nom: 'ind_ald', largeur: 74}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vclcleint', { largeur: 40 }),
            new FormulaireItem('t_vacvclreg'),
            new FormulaireItem('eplcleint', { largeur: 40, menu: [
                    new MenuItemForage(
                        rr0003Route.NAME,
                        (data: any) => ({
                            id: data.t_trncleintdem
                        }),
                        {
                            hidden: (data: any) => !data.t_trncleintdem
                        }
                    )]
            }),
            new FormulaireItem('demcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ehvdatdeb'),
            new FormulaireItem('ehvdatfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ehvdscabr', { largeur: 40 }),
            new FormulaireItem('ehvdsclng', { enableCount: true, largeur: 60 }),
            new FormulaireItem('ehvqtemnt1'),
            new FormulaireItem('ehvqtemnt2'),
            new FormulaireItem('ehvqtemnt3'),
            new FormulaireItem('ehvqtemnt4'),
            new FormulaireItem('ehvqtemnt5'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ehvcodurg', {
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('ehvcoduno', {
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('ehvcodstr', {
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('ehvcodpos', {
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('ehvcodtem', {
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_urgdsc', { default: (data: any) => data.aut__urgdsc }),
            new FormulaireItem('t_unodsc', { default: (data: any) => data.aut__unodsc }),
            new FormulaireItem('t_strdsc', { default: (data: any) => data.aut__strdsc }),
            new FormulaireItem('t_postit', { default: (data: any) => data.aut__postit }),
            new FormulaireItem('t_temdsc', { default: (data: any) => data.aut__temdsc })
        ], { largeurDefaut: 20 })
    };
}
