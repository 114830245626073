import {IComponentController} from 'angular';
import {IExAugmentedEvent} from '../../../../core/interfaces/event.interface';
import {IDialog} from '../../../../core/services/dialog.service';
import {IMultiOccurrenceOptions} from '../../../../core/services/multi-occurrence.service';
import {IComposantMonoOccurrence} from '../../../../core/components/ex-mono-occurrence/ex-mono-occurrence.controller';
import {IComposantMultiOccurrence} from '../../../../core/components/ex-multi-occurrence/ex-multi-occurrence.controller';
import {IEcranContextController} from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IDialogOptions = angular.material.IDialogOptions;

interface IComposantButtonHistorique extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    id: number;
    lblTitre: string;
    fonction: string;
    largeurModale: number;
    simple: boolean;
    icon: string;
    ouvreHistorique(event: IExAugmentedEvent): void;
    hasAcces(): boolean;
}

/* @ngInject */
export default function ButtonHistoriqueController(DialogMultiOccurrence: IDialog) {
    const vm: IComposantButtonHistorique = this;

    vm.ouvreHistorique = ouvreHistorique;
    vm.hasAcces = hasAcces;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.icon = (vm.simple) ? 'ballot' : 'history';
    }

    function ouvreHistorique(event: IExAugmentedEvent) {
        // On n'utilise pas 'stopPropagation' à cause des effets secondaires indésirables, comme la prévention
        // de la fermeture des menus
        event.originalEvent.exExpandablePreventAction = true;
        if (vm.multiOccurrenceOptions.multiOccurrenceParent && vm.id) {
            vm.multiOccurrenceOptions.multiOccurrenceParent.activeRowCleint = vm.id;
        }

        const dialogOptions: IDialogOptions = {
            locals: {
                lblTitre: vm.lblTitre,
                largeur: vm.largeurModale,
                icon: vm.icon,
                libelles: vm.monoOccurrenceCtrl.monoOccurrence.libelles,
                multiOccurrenceOptions: Object.assign({}, vm.multiOccurrenceOptions, {
                    parentId: vm.monoOccurrenceCtrl.monoOccurrence.id,
                    fonctions: Object.assign({
                        enregistrerUnEtat: false,
                        reinitialiser: false,
                        suiviModification: true,
                        recherche: true,
                        rechercheParColonne: false,
                        filtrer: false,
                        selectionnerTri: false,
                        selectionnerDesColonnes: false
                    }, vm.multiOccurrenceOptions.fonctions)
                }),
                ecranContext: vm.ecranContextCtrl
            }
        };

        return DialogMultiOccurrence.show(dialogOptions);
    }

    function hasAcces(): boolean {
        const mnemoniqueParent = (vm.multiOccurrenceOptions && vm.multiOccurrenceOptions.multiOccurrenceParent) ?
            vm.multiOccurrenceOptions.multiOccurrenceParent.bloc || vm.multiOccurrenceOptions.multiOccurrenceParent.mnemonique :
            vm.monoOccurrenceCtrl.monoOccurrence.mnemonique;
        const fonction = vm.ecranContextCtrl.ecranDetails.fonctions[`${mnemoniqueParent}.${vm.fonction}`.toUpperCase()];
        return Boolean(!fonction || fonction.flgacc);
    }
}
