import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IComponentController} from 'angular';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageMc0159 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    noeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    noiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    nomMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0159Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItem: IFormulaireItemClass)  {

    const vm: IPageMc0159 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Mono
        vm.formulaireNoc = new Formulaire('LBL_NOC', [
            new FormulaireItem('noccod', { largeur: 20 }),
            new FormulaireItem('nocnom', { largeur: 50 }),
            new FormulaireItem('nocflgstu', { largeur: 20, default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('nocobj', { largeur: 77 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('nocmsg', { largeur: 100, enableCount: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('redcleint', {largeur: 30}),
            new FormulaireItem('etdcleint', {
                largeur: 30,
                resourceParams: (data: any) => {
                    return {
                        redcleint: data.redcleint
                    };
                }
            }),
            new FormulaireGroupe('LBL_INDICATEURS',
                new Formulaire([
                    new FormulaireItem('nocflgusrcre', { largeur: 15 }),
                    new FormulaireItem('nocflgach',    { largeur: 15 }),
                    new FormulaireItem('nocflgreq',    { largeur: 15 }),
                    new FormulaireItem('nocflgcarach', { largeur: 33 }),
                    new FormulaireItem('flgcycach')
                ])
            )
        ]);

        // Onglet Étape
        vm.noeMultiOccurrenceOptions = {
            bloc: 'noe',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'etdcod', largeur: 130},
                {nom: 'etddsc', largeur: 300},
                {nom: 'noeflgall', largeur: 130}
            ],
            colonnesCachees: [
                'noccleint'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('etdcleint', {
                    resourceParams: (data: any) => {
                        return {
                            redcleint: data.$ancetre.redcleint
                        };
                    }
                }),
                new FormulaireItem('noeflgall')
            ])
        };

        // Onglet Intervenant
        vm.noiMultiOccurrenceOptions = {
            bloc: 'noi',
            fonctions: {
                pagination: true
            },
            colonnesFixesGauche: [
                {nom: 'intcod', largeur: 110}
            ],
            colonnesVisibles: [
                {nom: 'intnom', largeur: 262},
                {nom: 'usrcod', largeur: 100},
                {nom: 'usrnom', largeur: 262},
                {nom: 'usradrele', largeur: 270},
                {nom: 'empcod', largeur: 100},
                {nom: 'empnom', largeur: 262},
                {nom: 'empadrcou', largeur: 270}
            ],
            colonnesFixesDroite: [
                {nom: 'typcoudsc', largeur: 140}
            ],
            colonnesCachees: [
                'noccleint'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('intcleint'),
                new FormulaireItem('vaenoitypcou')
            ])
        };

        // Onglet Courriel
        vm.nomMultiOccurrenceOptions = {
            bloc: 'nom',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'nomadrele', largeur: 450},
                {nom: 'nomcom', largeur: 450}
            ],
            colonnesCachees: [
                'noccleint'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('nomadrele'),
                new FormulaireItem('nomcom')
            ])
        };

    }
}
