import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';

interface IPageCO0012 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireExp: IFormulaire;
    formulaireRh: IFormulaire;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Co0012Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageCO0012 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireExp = new Formulaire('LBL_EXPERIENCE', [
            new FormulaireItem('expent'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('expdatdeb', {
                largeur: 33.33,
            }),
            new FormulaireItem('expdatfin', {
                largeur: 33.33,
            }),
            new FormulaireItem('expflgperver', {
                largeur: 33.33,
            }),
            new FormulaireGroupeAccordeon('LBL_ADRESSE', new Formulaire([
                new FormulaireItem('expadrl01'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('expadrl02'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('expadrl03'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('expvil'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('exppro'),
                new FormulaireItem('exppay'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('expcodpos'),
                new FormulaireItem('expnumtel'),
            ]), {
                opened: (data: any) => !data.expcleint
            }),
            new FormulaireItem('expcom', {
                largeur: 100
            }),
        ]);

        vm.formulaireRh = new Formulaire('LBL_RH', [
            new FormulaireItem('expflgver'),
            new FormulaireItem('expflgverrh'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('expcomverrh', {
                largeur: 100
            }),
        ]);

        vm.detMultiOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_DET', [
                new FormulaireItem('detfon'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('detdatdeb', {
                    largeur: 25,
                    default: () => vm.monoOccurrence.data.expdatdeb,
                }),
                new FormulaireItem('detdatfin', {
                    largeur: 25,
                }),
                new FormulaireItemDivider('LBL_CONTACT'),
                new FormulaireItem('detcntnom'),
                new FormulaireItem('detcnttit'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('detcntnumtel'),
                new FormulaireItem('detcntadrcou'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('detcom'),
            ]),
            colonnesVisibles: [
                {nom: 'detfon', largeur: 315},
                {nom: 'detdatdeb', largeur: 126},
                {nom: 'detdatfin', largeur: 126},
                {nom: 'detcntnom', largeur: 262},
                {nom: 'detcnttit', largeur: 262},
                {nom: 'detcntnumtel', largeur: 131},
                {nom: 'detcntadrcou', largeur: 262},
                {nom: 'detcom', largeur: 400},
            ]
        };

    }
}
