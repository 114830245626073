import { IComponentController, IFilterDate } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageDo0037 extends IComponentController {
    valeursEcran: any;
    formulairePva: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
interface IDo0037Params {
    id: string;
    poscleint: number;
}

/* @ngInject */
export default function do0037Controller($stateParams: IDo0037Params,
    so0007Route: IRoute,
    rr0003Route: IRoute,
    rr0006Route: IRoute,
    accueilRoute: IRoute,
    Formulaire: IFormulaireClass,
    dateFilter: IFilterDate,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageDo0037 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_PVA', [
                new FormulaireItem('pvanumdoc', { largeur: 50 }),
                new FormulaireItem('pvadat', { default: dateFilter(new Date(), 'yyyy-MM-dd'), largeur: 50 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ttccleint', { default: vm.valeursEcran.ttccleint, largeur: 50 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('poscleint', {
                    default: $stateParams.poscleint, largeur: 50,
                    menu: [new MenuItemForage(
                        'LBL_FORAGE_SO0007',
                        so0007Route.NAME,
                        () => ({
                            ecran: '00-2',
                            id: vm.valeursEcran.trncleint_pos
                        })
                    )]
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('urgcod', { readonly: true, largeur: 50 }),
                new FormulaireItem('urgdscabr', { readonly: true, default: vm.valeursEcran.urgdscabr, largeur: 50 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('unocodrh', { readonly: true, default: vm.valeursEcran.unocodrh, largeur: 50 }),
                new FormulaireItem('unodscabrrh', { readonly: true, default: vm.valeursEcran.unodscabrrh, largeur: 50 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('intcleintrh', {
                    largeur: 50,
                    resourceParams: (data: any) => ({
                        pvadat: data.pvadat
                    })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItemDivider('LBL_TITRE_ORIGINE'),
                new FormulaireItem('vaepvaori'),
                new FormulaireItem('vacraitdm'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('demcleint', {
                    largeur: 50,
                    readonly: (data: any) => data.vaepvaori === 'CPO',
                    default: vm.valeursEcran.demcleint,
                    resourceParams: (data: any) => ({
                        poscleint: data.poscleint
                    }),
                    menu: [
                        new MenuItemForage(
                            'BTN_FORAGE_EMPLOYE',
                            rr0003Route.NAME,
                            () => ({ id: vm.valeursEcran.trncleint_dem }),
                            { hidden: (data: any) => !data.demcleint }
                        )
                    ]
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('pvacleintref', {
                    largeur: 50,
                    readonly: (data: any) => data.vaepvaori === 'CPO',
                    resourceParams: (data: any) => ({
                        vaepvaori: data.vaepvaori
                    }),
                    menu: [
                        new MenuItemForage(
                            'LBL_FOR_TYP',
                            (data: any) => getForage(data),
                            (data: any) => getParams(data),
                            { hidden: (data: any) => !data.pvacleintref }
                        )
                    ]
                }),
                new FormulaireItem('datref', { readonly: true, default: vm.valeursEcran.datref }),
                new FormulaireItem('aftflgpro', { readonly: true, default: vm.valeursEcran.aftflgpro }),
                new FormulaireItemSautDeLigne(),
                new FormulaireGroupe('LBL_VAC', new Formulaire([
                    new FormulaireItem('pvadatvacest', { required: true, largeur: 20 }),
                    new FormulaireItem('pvadatlimdec', { largeur: 20 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('pvadatvac', { largeur: 20 }),
                ]), { largeur: 50 }),
                new FormulaireGroupe('LBL_RF', new Formulaire([
                    new FormulaireItem('dmpnumdoc', { readonly: true, largeur: 25, default: vm.valeursEcran.dmpnumdoc }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('vaedmpdec', { readonly: true, largeur: 25, default: vm.valeursEcran.vaedmpdec }),
                ]), { largeur: 50 }),
                new FormulaireItem('pvacom', { largeur: 75 }),
            ], { largeurDefaut: 25 })
        }
    }

    function getForage(data: any): string {
        if (data.vaepvaori === 'RET') {
            return accueilRoute.NAME;
        } else {
            return rr0006Route.NAME;
        }
    }

    function getParams(data: any) {
        if (data.vaesysmnetab === 'RET') {
            return { id: vm.valeursEcran.trncleint_ald };
        } else {
            return { id: vm.valeursEcran.trncleint_aft }
        }
    }
}
