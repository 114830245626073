import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IPaginationClass} from "../../../../../core/services/pagination.service";


interface IPageRr0136 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    adlMultiOccurrenceOptions: IMultiOccurrenceOptions;

}
/* @ngInject */
export default function rr0136Controller(Pagination: IPaginationClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         rr0031Route: IRoute) {
    const vm: IPageRr0136 = this;

    const forageDeb = new MenuItemForage(
        'LBL_FOR_REF',
        rr0031Route.NAME,
        (data: any) => {
            return {
                id: data.trncleint_daadeb
            };
        },
        {disabled: (data: any) => !data.adladhdeb,fonction:'BTN_BOUADHDEB'}
    );

    const forageFin = new MenuItemForage(
        'LBL_FOR_REF',
        rr0031Route.NAME,
        (data: any) => {
            return {
                id: data.trncleint_daafin
            };
        },
        {disabled: (data: any) => !data.adladhfin,fonction:'BTN_BOUADHFIN'}
    );
    vm.adlMultiOccurrenceOptions = {
        navigatePage: rr0031Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.trncleintref,
                demcleint: rowData.demcleint,flgmod:'1',
                ppadatdeb:rowData.$ancetre.ppadatdeb,flgadh:'0'
            };
        },
        bloc: 'adl',
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 50, 100]
        }),
        fonctions: {
            libellesDynamiques: true
        },
        colonnesCachees: [
            'demcleint'
        ],
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'adlqte1', largeur: 100,replacedValue: (multiOccurrence: IMultiOccurrence) => multiOccurrence.multiOccurrenceParent.getDataActiveRow().banque1},
            {nom: 'adlqte2', largeur: 100,replacedValue: (multiOccurrence: IMultiOccurrence) => multiOccurrence.multiOccurrenceParent.getDataActiveRow().banque2},
            {nom: 'hresemm2', largeur: 120,replacedValue: (multiOccurrence: IMultiOccurrence) => multiOccurrence.multiOccurrenceParent.getDataActiveRow().ppacodsemm2},
            {nom: 'hresemm1', largeur: 120,replacedValue: (multiOccurrence: IMultiOccurrence) => multiOccurrence.multiOccurrenceParent.getDataActiveRow().ppacodsemm1},
            {nom: 'hresemref', largeur: 120, replacedValue: (multiOccurrence: IMultiOccurrence) => multiOccurrence.multiOccurrenceParent.getDataActiveRow().ppacod},
            {nom: 'hresemp1', largeur: 120, replacedValue: (multiOccurrence: IMultiOccurrence) => multiOccurrence.multiOccurrenceParent.getDataActiveRow().ppacodsemp1},
            {nom: 'hresemp2', largeur: 120, replacedValue: (multiOccurrence: IMultiOccurrence) => multiOccurrence.multiOccurrenceParent.getDataActiveRow().ppacodsemp2},
            {nom: 'adhesion', largeur: 100},
            {nom: 'adladhdeb', largeur: 100, menu: [forageDeb]},
            {nom: 'adladhfin', largeur: 100, menu: [forageFin]},
            {nom: 'urgcod', largeur: 100},
            {nom: 'strcod', largeur: 100},
            {nom: 'lemcod', largeur: 100},
            {nom: 'lemdatdeb', largeur: 100},
            {nom: 'lemdatfin', largeur: 100},
            {nom: 'tafcod', largeur: 160}
        ]
    };
    vm.multiOccurrenceOptions = {
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 25, 50]
        }),
        colonnesVisibles: [
            {nom: 'aadcleint', largeur: 100},
            {nom: 'aaddsc', largeur: 240},
            {nom: 'ppacod', largeur: 90},
            {nom: 'ppadatdeb', largeur: 130},
            {nom: 'ppadatfin', largeur: 120},
            {nom: 'usrnomcre', largeur: 262},
            {nom: 'datcre', largeur: 160} // 140 lorsqu'on est sur la ligne, résultat sur 2 lignes
        ],
        colonnesCachees: [
            'ppacodsemm1',
            'ppacodsemm2',
            'ppacodsemp1',
            'ppacodsemp2',
            'trncleint_daadeb',
            'trncleint_daafin',
            'banque1',
            'banque2',
            'trncleintref'
        ],
        multiOccurrenceOptionsEnfant: vm.adlMultiOccurrenceOptions
    };
}
