import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageCH0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Ch0002Controller(ch0003Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCH0002 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: ch0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                itecleint: rowData.itecleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(ch0003Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'itecod', largeur: 100},
            {nom: 'itedsc', largeur: 260},
            {nom: 'rolcod', largeur: 100},
            {nom: 'roldscabr', largeur: 260},
            {nom: 'iteflgact', largeur: 91},
            {nom: 'iteflgche', largeur: 180},
            {nom: 'iteflgpubhoraut', largeur: 200},
            {nom: 'esvcoddsc1', largeur: 240, hidden: data => !vm.valeursEcran.flgesv1aff},
            {nom: 'esvcoddsc2', largeur: 240, hidden: data => !vm.valeursEcran.flgesv2aff},
            {nom: 'esvcoddsc3', largeur: 240, hidden: data => !vm.valeursEcran.flgesv3aff},
            {nom: 'esvcoddsc4', largeur: 240, hidden: data => !vm.valeursEcran.flgesv4aff},
            {nom: 'esvcoddsc5', largeur: 240, hidden: data => !vm.valeursEcran.flgesv5aff}
        ],
        colonnesCachees: [
            'itecleint'
        ]
    };
}
