import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMi0510 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mi0510Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMi0510 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'mijcod', largeur: 118},
            {nom: 'mijdsc', largeur: 328},
            {nom: 'mijnumpri', largeur: 100},
            {nom: 'mijflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mijcod'   , { largeur: 20, required: true}),
            new FormulaireItem('mijdsc'   , { largeur: 40, required: true}),
            new FormulaireItem('mijnumpri', { largeur: 20, required: true}),
            new FormulaireItem('mijflgact', { largeur: 20, default: 1})
        ])
    };
}


