import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRr0549{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    rprMultiOccurrence: IMultiOccurrence;

}

interface IRr0549Params {
    pgpcleint: string;
    rprdatpai: string;
    id: number;
}

/* @ngInject */
export default function Rr0549Controller($stateParams: IRr0549Params,
                                         Operateur: IOperateurService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageRr0549 = this;
    const criteresSuggeresVisibles = !$stateParams.pgpcleint && !$stateParams.rprdatpai;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles
        },
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_GENERER', 'BOUGEN', {
                selection: {nom: 'generer'},
                successAction: () => {
                    vm.rprMultiOccurrence.fetchDataList();
                }
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_pgpcleint', { default: $stateParams.pgpcleint, required: true, ignoreEtat: true }),
            new FormulaireItem('t_rprdatpai', { default: $stateParams.rprdatpai, required: true, ignoreEtat: true })
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 120}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'aftcod'   , largeur: 120},
            {nom: 'temcod'   , largeur: 130},
            {nom: 'temdscabr', largeur: 240},
            {nom: 'asgcod'   , largeur: 130},
            {nom: 'asgdsc'   , largeur: 240},
            {nom: 'tagcod'   , largeur: 100},
            {nom: 'tagdscabr', largeur: 240},
            {nom: 'ttccod'   , largeur: 100},
            {nom: 'ttcdsc'   , largeur: 240},
            {nom: 'rprnbr'   , largeur: 140},
            {nom: 'rprbqesld', largeur: 140},
            {nom: 'rprqtecal', largeur: 140},
            {nom: 'rprqtever', largeur: 140}
        ],
        colonnesFixesDroite: [
            {nom: 'rprqte'   , largeur: 100},
            {nom: 'unccod'   , largeur: 70},
            {nom: 'rprflgerr', largeur: 74},
            {nom: 'rprflgtrt', largeur: 74}
        ],
        colonnesCachees: ['mrpmsg'],

           formulaire: new Formulaire([
            new FormulaireItem('pgpcleint',{default: () => vm.rprMultiOccurrence.etatSelected.criteresSuggeresData.t_pgpcleint}),
            new FormulaireItem('pgpcleint__pgpcod',{default: () => vm.rprMultiOccurrence.etatSelected.criteresSuggeresData.t_pgpcleint__pgpcod}),
            new FormulaireItem('pgpcleint__pgpdscabr',{default: () => vm.rprMultiOccurrence.etatSelected.criteresSuggeresData.t_pgpcleint__pgpdscabr}),
            new FormulaireItem('rprdatpai', {default: () => vm.rprMultiOccurrence.etatSelected.criteresSuggeresData.t_rprdatpai}),
            new FormulaireItem('demcleint'),
            new FormulaireItem('aftcleint'),
            new FormulaireItem('t_temcoddscabr', { default: (data: any) => data.aut__temcoddscabr }),
            new FormulaireItem('asgcleint', { resourceParams: (data: any) => ({ demcleint: data.demcleint }) }),
            new FormulaireItem('tagcleint'),
            new FormulaireItem('ttccleint', { nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('rprnbr'),
            new FormulaireItem('rprbqesld'),
            new FormulaireItem('rprqtecal'),
            new FormulaireItem('rprqtever'),
            new FormulaireItem('rprqte'),
            new FormulaireItem('unccleint')
        ], { largeurDefaut: 25 })
    }
};
