import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0098 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;

}
/* @ngInject */
export default function Ac0098Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAc0098 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'upccod', largeur: 50},
            {nom: 'upcdsc', largeur: 304}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('upccod', {required: true}),
            new FormulaireItem('upcdsc', {required: true})
        ]),
        fonctions: Object.assign({
            edition: true,
            nouveau: true

        })
    };
}
