import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageAe0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ae0006Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageAe0006 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'stamnetab', valeur:'POS', visible: false}
        ],
        colonnesVisibles: [
            {nom: 'posnumdoc', largeur: 90},
            {nom: 'postitabr', largeur: 245},
            {nom: 'rgecod', largeur: 120},
            {nom: 'rgedsc', largeur: 220},
            {nom: 'rgecodpro', largeur: 120},
            {nom: 'rgedscpro', largeur: 245},
            {nom: 'vaccod', largeur: 110},
            {nom: 'vacdsc', largeur: 202}
        ],
        colonnesCachees: ['stamnetab'],
        formulaire: new Formulaire([
            new FormulaireItem('ipacleintref', {largeur: 60, nombreLibellesDetailsCacher: 3}),
            new FormulaireItem('vacsyszonrec' , {largeur: 30}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rgecleint', {largeur: 40}  ),
            new FormulaireItem('rgecleintpro', {largeur: 40})
        ]),
        fonctions: {
            importation: true
        }
    };
}
