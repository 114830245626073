import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuItemFactoryClass} from "../../../../../core/services/menu/menu-item-factory.service";
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';

interface IPageGL0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePEROptions: IMultiOccurrenceOptions;
    multiOccurrenceCPTDOCOptions: IMultiOccurrenceOptions;
}
interface IPageGL0006Params {
    [col: string]: number|string;
    annfin: number,
    perdeb: string,
    perfin: string,
    tymcleint: number,
    imput: string,
    prucleint: number,
    prscleint: number,
    cifcleint: number,
    cptcleint: number,
    unacleint: number,
    el1cleint: number,
    el2cleint: number,
    el3cleint: number,
    unmcleint: number,
    vaesysnatctb: string,
    soncleint: number
}

/* @ngInject */
export default function Gl0006Controller(Menu: IMenuClass,
                                         gl0007Route: IRoute,
                                         MenuItemFactory: IMenuItemFactoryClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         $stateParams: IPageGL0006Params,
                                         isForageValide: IIsForageValideService) {
    const vm: IPageGL0006 = this;
    const detailsMultiOccurrenceOptions: IMultiOccurrenceOptions = {
        bloc: 'det',
        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]),
        fonctions:{
            suiviModification: false
        },
        colonnesVisibles: [
            {nom: 'peccod', largeur: 131},
            {nom: 'tydcod', largeur: 89},
            {nom: 'docaffdat01', largeur: 100},
            {nom: 'docaffnumdoc01', largeur: 184},
            {nom: 'docrefcod02', largeur: 131},
            {nom: 'docrefnom02', largeur: 263},
            {nom: 'docaffdsc01', largeur: 263},
            {nom: 'docreqcod03', largeur: 89},
            {nom: 'docreqnom03', largeur: 263},
            {nom: 'trfqte', largeur: 139},
            {nom: 'reqdatdep', largeur: 120},
            {nom: 'reqdatret', largeur: 120}
        ],
        colonnesFixesDroite: [
            {nom: 'docaffmnt01', largeur: 126},
            {nom: 'impact', largeur: 126}
        ],
        colonnesCachees: [
            'ecrcod'
        ]
    };

    vm.multiOccurrenceCPTDOCOptions = {
        bloc: 'cptdoc',
        details: {lblTitre: 'G_LBL_DETAIL_DOC', multiOccurrenceOptions: detailsMultiOccurrenceOptions},
        fonctions:{
            suiviModification: false
        },
        colonnesFixesGauche:[
            {nom: 'tyddsc', largeur: 500}
        ],
        colonnesVisibles: [
            {nom: 'cummnt', largeur: 126},
            {nom: 'cumqte', largeur: 126}
        ],
        colonnesCachees: [
            'pecper'
        ]
    };

    vm.multiOccurrencePEROptions = {
        bloc: 'per',
        details: {lblTitre: 'G_LBL_DETAIL_DOC', multiOccurrenceOptions: detailsMultiOccurrenceOptions},
        fonctions:{
            suiviModification: false
        },
        colonnesFixesGauche: [
            {nom: 'peccod', largeur: 500}
        ],
        colonnesVisibles: [
            {nom: 'cummnt', largeur: 126},
            {nom: 'cumqte', largeur: 126}
        ],
        colonnesCachees: [
            'unacleint',
            'pecper'
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCPTDOCOptions
    };

    vm.multiOccurrenceOptions = {
        navigatePage: gl0007Route.NAME,
        navigateParams: (rowData: any) => (
            Object.assign({}, $stateParams, {unacleint: rowData.unacleint})
        ),
        fonctions:{
            suiviModification: false,
            rechercheCollapsable: false
        },
        filtresParametres: [
            {colonne: 'annfin', affichage: true},
            {colonne: 'perdeb', affichage: true},
            {colonne: 'perfin', affichage: true},
            {colonne: 'tymcleint', affichage: true},
            {colonne: 'imput', affichage: true},
            {colonne: 'vaesysnatctb', affichage: true},
            {colonne: 'soncleint', affichage: true},
            {colonne: 'unmcleint', affichage: true},
            {colonne: 'cptcleint', affichage: true},
        ],
        colonnesFixesGauche: [
            {nom: 'cptcod', largeur: 110},
            {nom: 'unacod', largeur: 390}
        ],
        colonnesVisibles: [
            {nom: 'cummnt_deb', largeur: 126},
            {nom: 'cummnt_var', largeur: 126},
            {nom: 'cummnt_fin', largeur: 126},
            {nom: 'cumqte_deb', largeur: 126},
            {nom: 'cumqte_var', largeur: 126},
            {nom: 'cumqte_fin', largeur: 126}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrencePEROptions
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcod}.ecran`),
            (data: any) => ({
                id: data.doccleint
            }),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('gl0006', data.ecrcod);
                }
            }
        )
    }
}
