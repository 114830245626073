import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMr0234 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0234Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0234 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cvtcod', largeur: 105},
            {nom: 'cvtdsc', largeur: 262},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'tafcod', largeur: 110},
            {nom: 'tafdsc', largeur: 185},
            {nom: 'vaecsttyp_dsc', largeur: 116}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('cvtcleint', {required: true, modifAllow:false, largeur: 33.33}),
                new FormulaireItem('strcleint', {required: true, modifAllow:false, largeur: 33.33}),
                new FormulaireItem('tafcleint', {required: true, modifAllow:false, largeur: 33.33}),
                new FormulaireItem('vaecsttyp', {required: true, largeur: 25})
            ]
        )
    };
}
