import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import { IFilterDate } from "angular";

interface IPageSO0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0006Controller(so0007Route: IRoute,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         dateFilter: IFilterDate) {
    const vm: IPageSO0006 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: so0007Route.NAME,
        navigateParams: (data: any) => ({
            poscleint: data.poscleint
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage(so0007Route.NAME)
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('trnflgstafer', { default: 0 }),
            new FormulaireItem('trnflgstaann', { default: 0 }),
            new FormulaireItem('selact', { default: 0 }),
            new FormulaireItem('datsel', {
                required: true,
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            })            
        ]),
        colonnesVisibles: [
            {nom: 'posnumdoc', largeur: 90},
            {nom: 'ttccod', largeur: 105},
            {nom: 'postitabr', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'sefdsc', largeur: 200},
            {nom: 'posdatdeb', largeur: 100},
            {nom: 'posdatfin', largeur: 100},
            {nom: 'titulaire', largeur: 262},
            {nom: 'nontitulaire', largeur: 262},
            {nom: 'horhresem', largeur: 162},
            {nom: 'rrhetc', largeur: 162},
            {nom: 'rghcod', largeur: 162},
            {nom: 'rghdsc', largeur: 262},
            {nom: 'horcod', largeur: 162},
            {nom: 'hordsc', largeur: 262},
            {nom: 'tpocod', largeur: 90},
            {nom: 'tpodsc', largeur: 144},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'unocodrh', largeur: 110},
            {nom: 'unodscabrrh', largeur: 262},
            {nom: 'unocodvac', largeur: 110},
            {nom: 'unodscabrvac', largeur: 262},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 85},
            {nom: 'strdsc', largeur: 262},
            {nom: 'litcod', largeur: 85},
            {nom: 'litdsc', largeur: 262},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75},
            {nom: 'rgecod', largeur: 125},
            {nom: 'rgedsc', largeur: 262},
            {nom: 'zonreccod', largeur: 110},
            {nom: 'zonrecdsc', largeur: 262},
            {nom: 'ccpcod', largeur: 85},
            {nom: 'ccpdsc', largeur: 262},
            {nom: 'posassaut', largeur: 125}
        ]
    };
}
