import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageGs0071 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireGal: IFormulaire;
    gapMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fomMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0071Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGs0071 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireGal = new Formulaire('LBL_BLC_GAL', [
            new FormulaireItem('vaegaltyp'),
            new FormulaireItem('galseqaff'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('galsuj', {largeur: 50}),
            new FormulaireItem('galtxt', {largeur: 100})
        ], {largeurDefaut: 25});

        vm.gapMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'gapdathredeb', largeur: 170},
                {nom: 'gapdathrefin', largeur: 170},
                {nom: 'gapflgsof', largeur: 110},
                {nom: 'gapflgsow', largeur: 120},
                {nom: 'gapflgemp', largeur: 140},
                {nom: 'gapflgpei', largeur: 160},
                {nom: 'gapflgaux', largeur: 160}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gapdatdeb', { largeur: 50 }),
                new FormulaireItem('gapdatfin', { largeur: 50 }),
                new FormulaireItem('gapflgsof'),
                new FormulaireItem('gapflgsow'),
                new FormulaireItem('gapflgemp'),
                new FormulaireItem('gapflgpei'),
                new FormulaireItem('gapflgaux'),
            ], {largeurDefaut: 20})
        };
    }
}
