import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRR0050 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0050Controller(rr0051Route: IRoute,
                                         Menu: IMenuClass,
                                         $stateParams: IStateParamsService,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0050 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: rr0051Route.NAME,
        navigateParams: (rowData: any) => ({
            id: rowData.dcicleint,
            demcleint: rowData.demcleint
        }),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0051Route.NAME, () => {
                return {
                    demcleint: $stateParams.employe
                };
            })
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe},
            {nom: 'vaedcityp_dsc', largeur: 131},
            {nom: 'dciannciv', largeur: 75},
            {nom: 'dcidatvig', largeur: 100},
            {nom: 'dcicrtmntbas', largeur: 126},
            {nom: 'dcicrtflgapp', largeur: 100},
            {nom: 'dciflgact', largeur: 65},
            {nom: 'dcicrtpac', largeur: 126},
            {nom: 'dcidedmntreg', largeur: 126},
            {nom: 'dcimntimpadd', largeur: 126},
            {nom: 'dciprcexerev', largeur: 126},
            {nom: 'dcicrtannaut', largeur: 126},
            {nom: 'dcidedannaut', largeur: 126},
            {nom: 'dciflgexoimp', largeur: 76},
            {nom: 'dciflgexorrq', largeur: 76},
            {nom: 'dciflgexoase', largeur: 76},
            {nom: 'dciflgexorqa', largeur: 76},
            {nom: 'dciflgexotxs', largeur: 116},
            {nom: 'dciflgexocne', largeur: 82}
        ],
        colonnesCachees: [
            'dcicleint',
            'demcleint'
        ]
    };
}
