import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTF0034 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0034Controller(Menu: IMenuClass) {
    const vm: IPageTF0034 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'ranannref', largeur: 91},
            {nom: 'nbravr', largeur: 91},
            {nom: 'nbrerr', largeur: 91},
            {nom: 'nbr', largeur: 91}
        ]
    };
}
