import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageMR0324 extends IComponentController {
    valeursEcran: any;
    formulairePfp: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ppbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0324Params {
    province: string;
    pafcleint: number;
}

/* @ngInject */
export default function Mr0324Controller($stateParams: IMr0324Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMR0324 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulairePfp = new Formulaire('LBL_BLC_PFP', [
            new FormulaireItem('vaesyscodprv', { default: $stateParams.province }),
            new FormulaireItem('pfptd1mntbas', { largeur: 10 }),
            new FormulaireItem('pfpimftaut3', { largeur: 10 }),
            new FormulaireItem('pafcleint', { default: $stateParams.pafcleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_ASSURANCE_EMP',
                new Formulaire([
                    new FormulaireItem('pfpasemga'),
                    new FormulaireItem('pfpasetau'),
                    new FormulaireItem('pfpasefctemrreg'),
                    new FormulaireItem('pfpasefctemrred')
                ], { largeurDefaut: 25 }), { largeur: 100 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_CSSIAT',
                new Formulaire([
                    new FormulaireItem('pfpcssmga')
                ]), { largeur: 50 }
            ),
            new FormulaireGroupeAccordeon('LBL_GRATIFIC',
                new Formulaire([
                    new FormulaireItem('pfpgrarevmin'),
                    new FormulaireItem('pfpgrataumin')
                ]), { largeur: 50 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_PARAM',
                new Formulaire([
                    new FormulaireItemDivider('LBL_V1'),
                    new FormulaireGroupe('',
                        new Formulaire([
                            // LIGNE 1
                            new FormulaireItem('ponmnt001v1', { titre: ' ', minWidth: 150 }),
                            new FormulaireItemSautDeColonne({ largeur: 75 }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 2
                            new FormulaireItem('ponprc001v1', { titre: ' ', minWidth: 150 }),
                            new FormulaireItem('t_ponmnt002v1', { titre: ' ', minWidth: 150,
                                libellesContour: {
                                    gauche: 'LBL_OPEDEBV1',
                                    droite: {
                                        largeur: 25,
                                        texte: 'LBL_OPEFIN',
                                        alignment: 'left'
                                    }
                                },
                                default: () => vm.monoOccurrence.data.ponmnt002v1
                            }),
                            new FormulaireItemSautDeColonne({ largeur: 50 }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 3
                            new FormulaireItem('t_ponprc001v1', { titre: ' ', minWidth: 150,
                                default: () => vm.monoOccurrence.data.ponprc001v1
                            }),
                            new FormulaireItem('t_ponmnt002v1', { titre: ' ', minWidth: 150,
                                libellesContour: {
                                    gauche: 'LBL_OPEDEBV1',
                                    droite: {
                                        largeur: 25,
                                        texte: 'LBL_OPEFINV1',
                                        alignment: 'left'
                                    }
                                },
                                default: () => vm.monoOccurrence.data.ponmnt002v1
                            }),
                            new FormulaireItem('ponprc002v1', { titre: ' ', minWidth: 150 }),
                            new FormulaireItem('t_ponmnt003v1', { titre: ' ', minWidth: 150,
                                libellesContour: {
                                    gauche: 'LBL_OPEDEBV1',
                                    droite: 'LBL_OPEFIN'
                                },
                                default: () => vm.monoOccurrence.data.ponmnt003v1
                            }),

                        ], { largeurDefaut: 25}), { largeur: 66 }
                    ),
                    new FormulaireGroupe('',
                        new Formulaire([
                            // LIGNE 1
                            new FormulaireItem('ponmnt002v1', { titre: ' ', minWidth: 150,
                                libellesContour: {
                                    gauche: {
                                        largeur: 55,
                                        texte: 'LBL_T4INFEGA',
                                        alignment: 'left'
                                    }
                                }
                            }),
                            new FormulaireItemSautDeColonne({ minWidth: 150, libellesContour: { droite: 'LBL_OU' } }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 2
                            new FormulaireItem('t_ponmnt002v1', { titre: ' ', minWidth: 150,
                                libellesContour: {
                                    gauche: {
                                        largeur: 55,
                                        texte: 'LBL_T4SUP',
                                        alignment: 'left'
                                    }
                                },
                                default: () => vm.monoOccurrence.data.ponmnt002v1
                            }),
                            new FormulaireItem('ponmnt003v1', { titre: ' ', minWidth: 150,
                                libellesContour: {
                                    gauche: 'LBL_INFEGA',
                                    droite: 'LBL_OU'
                                }
                            }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 3
                            new FormulaireItem('t_ponmnt003v1', { titre: ' ', minWidth: 150,
                                libellesContour: {
                                    gauche: {
                                        largeur: 55,
                                        texte: 'LBL_T4SUP',
                                        alignment: 'left'
                                    }
                                },
                                default: () => vm.monoOccurrence.data.ponmnt003v1
                            }),
                            new FormulaireItemSautDeColonne({ minWidth: 150 })
                        ], { largeurDefaut: 50}), { largeur: 33 }
                    ),

                    new FormulaireItemDivider('LBL_V2'),
                    new FormulaireGroupe('',
                        new Formulaire([
                            // LIGNE 1
                            new FormulaireItem('ponmnt001v2', { titre: ' ', minWidth: 150 }),
                            new FormulaireItemSautDeColonne({ largeur: 75 }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 2
                            new FormulaireItem('ponmnt003v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OU' } }),
                            new FormulaireItemSautDeColonne({ minWidth: 150 }),
                            new FormulaireItem('ponprc001v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEBV2' } }),
                            new FormulaireItem('t_ponmnt002v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEFIN' },
                                default: () => vm.monoOccurrence.data.ponmnt002v2
                            }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 3
                            new FormulaireItem('ponmnt005v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OU' } }),
                            new FormulaireItem('t_ponmnt003v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEB2V2' },
                                default: () => vm.monoOccurrence.data.ponmnt003v2
                            }),
                            new FormulaireItem('t_ponprc001v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEBV2' },
                                default: () => vm.monoOccurrence.data.ponprc001v2
                            }),
                            new FormulaireItem('t_ponmnt004v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEFINV2' },
                                default: () => vm.monoOccurrence.data.ponmnt004v2
                            }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 4
                            new FormulaireItem('ponmnt007v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OU' } }),
                            new FormulaireItem('t_ponmnt005v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEB2V2' },
                                default: () => vm.monoOccurrence.data.ponmnt005v2
                            }),
                            new FormulaireItem('ponprc002v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEBV2' } }),
                            new FormulaireItem('t_ponmnt006v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEFINV2' },
                                default: () => vm.monoOccurrence.data.ponmnt006v2
                            }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 5
                            new FormulaireItem('ponmnt009v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OU' } }),
                            new FormulaireItem('t_ponmnt007v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEB2V2' },
                                default: () => vm.monoOccurrence.data.ponmnt007v2
                            }),
                            new FormulaireItem('t_ponprc002v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEBV2' },
                                default: () => vm.monoOccurrence.data.ponprc002v2
                            }),
                            new FormulaireItem('t_ponmnt008v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEFINV2' },
                                default: () => vm.monoOccurrence.data.ponmnt008v2
                            }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 6
                            new FormulaireItem('ponmnt011v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OU' } }),
                            new FormulaireItem('t_ponmnt009v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEB2V2' },
                                default: () => vm.monoOccurrence.data.ponmnt009v2
                            }),
                            new FormulaireItem('t_ponprc002v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEDEBV2' },
                                default: () => vm.monoOccurrence.data.ponprc002v2
                            }),
                            new FormulaireItem('t_ponmnt010v2', { titre: ' ', minWidth: 150, libellesContour: { droite: 'LBL_OPEFINV2' },
                                default: () => vm.monoOccurrence.data.ponmnt010v2
                            }),
                            new FormulaireItemSautDeLigne()
                        ], { largeurDefaut: 25}), { largeur: 66 }
                    ),
                    new FormulaireGroupe('',
                        new Formulaire([
                            // LIGNE 1
                            new FormulaireItem('ponmnt002v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_AINF' } }),
                            new FormulaireItemSautDeColonne({ minWidth: 150, libellesContour: { droite: 'LBL_OU' } }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 2
                            new FormulaireItem('t_ponmnt002v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_ASUP' },
                                default: () => vm.monoOccurrence.data.ponmnt002v2
                            }),
                            new FormulaireItem('ponmnt004v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_INFEGA', droite: 'LBL_OU' } }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 3
                            new FormulaireItem('t_ponmnt004v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_ASUP' },
                                default: () => vm.monoOccurrence.data.ponmnt004v2
                            }),
                            new FormulaireItem('ponmnt006v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_INFEGA', droite: 'LBL_OU' } }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 4
                            new FormulaireItem('t_ponmnt006v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_ASUP' },
                                default: () => vm.monoOccurrence.data.ponmnt006v2
                            }),
                            new FormulaireItem('ponmnt008v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_INFEGA', droite: 'LBL_OU' } }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 5
                            new FormulaireItem('t_ponmnt008v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_ASUP' },
                                default: () => vm.monoOccurrence.data.ponmnt008v2
                            }),
                            new FormulaireItem('ponmnt010v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_INFEGA', droite: 'LBL_OU' } }),
                            new FormulaireItemSautDeLigne(),
                            // LIGNE 6
                            new FormulaireItem('t_ponmnt010v2', { titre: ' ', minWidth: 150, libellesContour: { gauche: 'LBL_ASUP' },
                                default: () => vm.monoOccurrence.data.ponmnt010v2
                            }),
                            new FormulaireItemSautDeColonne({ minWidth: 150 })
                        ], { largeurDefaut: 50}), { largeur: 33 }
                    ),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireGroupe('LBL_S',
                        new Formulaire([
                            new FormulaireItem('ponfct001s', { titre: ' ', libellesContour: { gauche: 'LBL_OPEDEB_S'} }),
                            new FormulaireItem('ponmnt001s', { titre: ' ', libellesContour: { gauche: 'LBL_OPEDEB2_S', droite: 'LBL_OPEFIN_S' } })
                        ]), { largeur: 50 }
                    ),
                    new FormulaireGroupe('LBL_LCP',
                        new Formulaire([
                            new FormulaireItem('ponlimlcp'),
                            new FormulaireItem('ponprclcp', { libellesContour: { gauche: 'LBL_OU' }  })
                        ]), { largeur: 50 }
                    )
                ]), { largeur: 100 }
            )
        ], { largeurDefaut: 25 });

        // Borne
        vm.ppbMultiOccurrenceOptions = {
            bloc: 'ppb',
            colonnesVisibles: [
                {nom: 'ppbborinf', largeur: 120},
                {nom: 'ppbtau'   , largeur: 85},
                {nom: 'ppbval'   , largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ppbborinf'),
                new FormulaireItem('ppbtau'),
                new FormulaireItem('ppbval')
            ], { largeurDefaut: 33.33 })
        };

        // Taux AE réduit
        vm.pfsMultiOccurrenceOptions = {
            bloc: 'pfs',
            colonnesVisibles: [
                {nom: 'rfecod'      , largeur: 150},
                {nom: 'rfenomabr'   , largeur: 241},
                {nom: 'pfsfctemrred', largeur: 135},
                {nom: 'pfsflg512'   , largeur: 105},
                {nom: 'bqecod'      , largeur: 89},
                {nom: 'bqedscabr'   , largeur: 262}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rfecleint', { largeur: 50 }),
                new FormulaireItem('pfsfctemrred'),
                new FormulaireItem('pfsflg512'),
                new FormulaireItem('bqecleint', { largeur: 50,
                    resourceParams: (data: any) => ({ pfsflg512: data.pfsflg512 })
                })
            ], { largeurDefaut: 25 })
        };
    }
}
