import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb0007',
    ecrans: ['00-2'],
    params: ['id',
            '?prucleint',
            '?prbcleint',
            '?vaesysnatctb',
            '?peccleintdeb',
            '?peccleintfin',
            '?indcleint']
});
