import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTH0062 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0062Controller(Menu: IMenuClass) {
    const vm: IPageTH0062 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rvlcod', largeur: 200},
            {nom: 'rvldsc', largeur: 200},
            {nom: 'rvlseq', largeur: 200},
            {nom: 'rvldatdeb', largeur: 200},
            {nom: 'rvldatfin', largeur: 200},
            {nom: 'vaervltyp_dsc', largeur: 200},
            {nom: 'rvlflgftpall', largeur: 200}
        ]
    };
}
