import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageGs0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceILVOptions: IMultiOccurrenceOptions;
}

interface IGs0024Params {
    icocleint: string;
}

/* @ngInject */
export default function Gs0024Controller(Formulaire: IFormulaireClass, 
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IGs0024Params,
    Operateur: IOperateurService) {
    const vm: IPageGs0024 = this;

    vm.multiOccurrenceILVOptions = {
        bloc: 'ilv',
        fonctions: { editionRapide: true },
        editionRapideActive: true,
        colonnesVisibles: [
            { nom: 'ilvval', largeur: 300 }
        ],
        colonnesEditionRapide: [
            { nom: 'ilvval', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ilvval')
        ])
    };

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'icocleint', valeur: $stateParams.icocleint, visible: false }
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'icolib', largeur: 89 },
            { nom: 'icodsc', largeur: 247 },
            { nom: 'ticdsc', largeur: 247 }
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceILVOptions
    };
}
