import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageRR0125 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0125Controller(Menu: IMenuClass) {
    const vm: IPageRR0125 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        //navigatePage: ???Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 263},
            {nom: 'tdadsc', largeur: 189},
            {nom: 'ddadatref', largeur: 100},
            {nom: 'ddacom', largeur: 263},
            {nom: 'tdaflgman', largeur: 91}
        ]
    };
}
