import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
interface IPageAs0057 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0057Controller(Menu: IMenuClass,
    parametresRecherche: IParametresRechercheService,
    Formulaire: IFormulaireClass,
    as0014Route: IRoute,
    as0009Route: IRoute,
    as0024Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass) {
    const vm: IPageAs0057 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        }, actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_INFO_FINANC',
                (rowData: any) => getForageItem(rowData),
                (rowData: any) => getParamItem(rowData),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUOUV'
                }
            )
        ]),
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        colonnesFixesGauche: [
            {nom:'vaeasgmodges',largeur:100},
            { nom: 'asgcod', largeur: 190 },
            { nom: 'asgdsc', largeur: 270 }
        ],
        colonnesVisibles: [
            { nom: 'ahtcod', largeur: 120 },
            { nom: 'ahtdsc', largeur: 262 },
            { nom: 'ahthresem', largeur: 150 },
            { nom: 'unccod', largeur: 150 },
            { nom: 'uncdsc', largeur: 150 },
            { nom: 'afecod', largeur: 200 },
            { nom: 'afedsc', largeur: 200 },
            { nom: 'vaeahtman_desc', largeur: 200 },
            { nom: 'cgacleint', largeur: 110 },
            { nom: 'tcycod', largeur: 200 },
            { nom: 'tcydsc', largeur: 200 },
            { nom: 'acydsc', largeur: 262 },
            { nom: 'acydatvig', largeur: 110 },
            { nom: 'acydursem', largeur: 90 },
            { nom: 'acytmptrajrs', largeur: 91 },
            { nom: 'acytmpremsem', largeur: 100 },
            { nom: 'flgdethor', largeur: 76 }
        ],
        colonnesCachees: ['ahtcleint', 'asgcleint', 'type_scr', 'acycleint'],
        formulaire: new Formulaire([
            new FormulaireItem('asgcleint', { largeur: 33.33, required: true }),
            new FormulaireItem('ahtcleint', { largeur: 33.33 }),
            new FormulaireItem('tcycleint', { largeur: 33.33 }),
            new FormulaireItem('acydsc', { largeur: 66.66, required: false }),
            new FormulaireItem('acydatvig', {
                required: true
            }),
            new FormulaireItem('acydursem', {
                required: true
            }),
            new FormulaireItem('acytmptrajrs', { required: true }),
            new FormulaireItem('acytmpremsem')
        ], { largeurDefaut: 25 })
    };
    function getForageItem(rowData: any): string {
        if (rowData.type_scr === 'JRS') {
            return as0009Route.NAME;
        } else if (rowData.type_scr === 'PFX') {
            return as0014Route.NAME;
        } else if (rowData.type_scr === 'HEB') {
            return as0024Route.NAME;
        }
    }
    function getParamItem(rowData: any) {
        if (rowData.type_scr === 'JRS') {
            return { id: rowData.acycleint, ahtcleint: rowData.ahtcleint };
        } else if (rowData.type_scr === 'PFX') {
            return { id: rowData.acycleint, ahtcleint: rowData.ahtcleint, asgcleint: rowData.asgcleint };
        } else if (rowData.type_scr === 'HEB') {
            return { id: rowData.acycleint, ahtcleint: rowData.ahtcleint };
        }

    }
}
