import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCP0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0032Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0032 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaetypsorfonc', {
            }),
            new FormulaireItem('pecdeb', { required: true, 
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('pecfin', { required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('refnomleg', {
            }),
            new FormulaireItem('datdeb', {
            }),
            new FormulaireItem('datfin', {
            }),
            new FormulaireItem('mopcleint', {
            }),
            new FormulaireItem('regindann'),
            new FormulaireItem('indcir', {
                default : 0,
                required: true
            }),
            new FormulaireItem('lorcleint', {
            }),
            new FormulaireItem('mntplsgrd', {
            }),
            new FormulaireItem('datfix', {
            }),
            new FormulaireItem('refcleint', {resourceParams: { type: 'FOU'} }),                                              
            new FormulaireItem('peccleint', {
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'regnumdoc', largeur:200}
        ],
        colonnesVisibles: [
            {nom: 'lotcodnum', largeur:200},
            {nom: 'vaetypsorfon', largeur:200},
            {nom: 'etddsc_actuel', largeur:200},
            {nom: 'refcod', largeur:200},
            {nom: 'rernomleg', largeur:200},
            {nom: 'peccod', largeur:200},
            {nom: 'regdat', largeur:200},
            {nom: 'rermnt', largeur:200},
            {nom: 'rermntesc', largeur:200},
            {nom: 'vaesysdevcod', largeur:200},
            {nom: 'regnumchq', largeur:200},
            {nom: 'regdatcci', largeur:200},
            {nom: 'cptcod', largeur:200}
        ]
    };
}
