import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb0026',
    ecrans: ['00-2'],
    params: [
        'id', 
        '?typapp', 
        '?typbdg', 
        '?prucleint', 
        '?prbcleint', 
        '?prvcleint', 
        '?el1cleint',
        '?vaesysnatctb', 
        '?pecann', 
        '?peccleintdeb',
        '?peccleintfin']
});
