import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0123 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0123Controller(mr0100Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0123 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0100Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0100Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'paqdatvig',    largeur: 96},
            {nom: 'paqtp1mntbas', largeur: 96},
            {nom: 'paqimpfcte01', largeur: 85},
            {nom: 'paqimpfctq',   largeur: 85},
            {nom: 'paqimpfctq01', largeur: 85},
            {nom: 'paqimpmaxq',   largeur: 100},
            {nom: 'paqimpfcth',   largeur: 100},
            {nom: 'paqimpmaxh',   largeur: 110},
            {nom: 'paqgrarevmin', largeur: 110},
            {nom: 'paqgrataumin', largeur: 100},
            {nom: 'paqrqamga',    largeur: 110},
            {nom: 'paqrqatauemp', largeur: 100},
            {nom: 'paqrqatauemr', largeur: 100},
            {nom: 'paqrrqmga',    largeur: 100},
            {nom: 'paqrrqexm',    largeur: 100},
            {nom: 'paqrrqtauemp', largeur: 125},
            {nom: 'paqrrqtaubas', largeur: 125},
            {nom: 'paqcstmga',    largeur: 110},
            {nom: 'paqcstprcind', largeur: 125},
            {nom: 'paqcntmga',    largeur: 110},
            {nom: 'paqcnttauemr', largeur: 132}
        ]
    };
}
