import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAS0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0011Controller(Menu: IMenuClass,
                                         as0007Route:IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageAS0011 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: as0007Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(as0007Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'sutnumseqreg', largeur: 120},
            {nom: 'sutnumseq', largeur: 130},
            {nom: 'sutcod', largeur: 94},
            {nom: 'sutdsc', largeur: 262},
            {nom: 'sutflgact', largeur: 65},
            {nom: 'vaesuttyp_dsc', largeur: 134},
            {nom: 'sutdatdeb', largeur: 120},
            {nom: 'sutdatfin', largeur: 100},
            {nom: 'vaesuteva_dsc', largeur: 170},
            {nom: 'vaesutimp_dsc', largeur: 160}
        ]
    };
}
