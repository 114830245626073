import { IComposantDialog } from '../../../../core/components/ex-dialog/ex-dialog.controller';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IDataType } from '../../../../core/services/data-types/data-type.service';
import { IScope } from 'angular';
import { ILovDataTypeClass } from "../../../../core/services/data-types/lov-data-type.service";

interface IDialogHistoriqueColonne extends IComposantDialog {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    srccod: string;
    col: string;
    dataType: IDataType;
    resourceParams: any;
    libelle: string;
    cleintref: number;
    readonly: boolean;
    reloadData: boolean;
}

/* @ngInject */
export default function HistoriqueColonneController(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    LovDataType: ILovDataTypeClass,
    $scope: IScope,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IDialogHistoriqueColonne = this;
    let requiredField = true;

    vm.$onInit = function $onInit() {
        if (vm.col === 'epladrcou' || vm.col === 'unocleintrh' || vm.col === 'intcleintsup') {
            requiredField = false;
        }
        const formulaireItems = [
            new FormulaireItem('mhccleintref', { hidden: true, default: vm.cleintref }),
            new FormulaireItem('mhccol', { hidden: true, default: vm.col }),
            new FormulaireItem('mhcdatvig', { disabled: (data) => data.mhcflgini, modifAllow: false }),
            new FormulaireItem('mhcdatvigfin', { hidden: vm.col !== 'vaeposloi95' }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('mhcvalref', { titre: vm.libelle, required: requiredField,  dataType: vm.dataType, resourceParams: (data: any) => ({ ...vm.resourceParams, srccod: vm.srccod, ...data }) }),
            new FormulaireItem('mhcflgact', { default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('mhccom', { largeur: 100 })
        ];

        if (vm.dataType instanceof LovDataType) {
            //On doit formatter nous-même la description du champ quand c'est une lov
            vm.dataType.params.description.forEach((colDescription, index) => formulaireItems.push(new FormulaireItem(`mhcvalref__${colDescription}`, {
                hidden: true,
                default: (data: any) => data.mhcvalref_aff && data.mhcvalref_aff.split('-')[index]
            })))
        }

        vm.multiOccurrenceOptions = {
            srccod: 'mc1023',
            resourceParams: {
                srccod: vm.srccod,
                mhccleintref: vm.cleintref,
                stcnom: vm.col
            },
            colonnesVisibles: [
                { nom: 'mhcdatvig', largeur: 20 },
                { nom: 'mhcdatvigfin', largeur: 20, hidden: vm.col !== 'vaeposloi95' },
                { nom: 'mhcvalref_aff', largeur: 20, titre: vm.libelle },
                { nom: 'mhccom', largeur: 20 },
                { nom: 'mhcflgact', largeur: 20 }
            ],
            colonnesCachees: [
                'mhcflgini'
            ],
            fonctions: {
                supprime: false,
                recherche: false
            },
            formulaire: new Formulaire(formulaireItems)
        };

        $scope.$watch('vm.multiOccurrence', () => {
            if (vm.multiOccurrence) {
                //On simule un cycle qui suit la modification permise sur le champ cible.
                vm.multiOccurrence.once('ready', () => {
                    vm.multiOccurrence.champsTransactionnels.vaeceptypmod = (vm.readonly) ? 'AUC' : 'TOUS';
                });
            }
        })
    }
}
