import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';

interface IMr0332Params {
    id: string;
    menuId: string;
    plccleint: number;
    vaeasscat: string;
    ecran: string;
}

interface IPageMR0332 extends IComponentController{
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePcz: IFormulaire;
    valeursEcran: any;
    zsoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePus: IMultiOccurrence;
}

/* @ngInject */
export default function Mr0332Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         $stateParams: IMr0332Params,
                                         MenuItemRecuperation: IMenuItemRecuperationClass) {
    const vm: IPageMR0332 = this;

    const titrePcz = ($stateParams.ecran === '00-2') ? 'LBL_BLC_PCZ1' : 'LBL_BLC_PCZ2';

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulairePcz = new Formulaire(titrePcz, [
            new FormulaireItem('plccleint', {default: $stateParams.plccleint}),
            new FormulaireItem('vaeasscat', {default: $stateParams.vaeasscat}),

            new FormulaireItem('pczcod', {largeur: 25, titre: titrePcz}),
            new FormulaireItem('pczdsc', {largeur: 75})
        ] );

        vm.zsoMultiOccurrenceOptions = {
            //actionsMore: new Menu([
            actionsNouveaux: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_zso',
                    lblTitre: 'LBL_SAISIE_RAPIDE',
                    resourceParams: {
                        vaeasscat: $stateParams.vaeasscat,
                        pczcleint: $stateParams.id,
                        flgsairap: 1
                    },
                    colonnesVisibles: [
                        {nom: 'aspcod', largeur: 150},
                        {nom: 'aspdsc', largeur: 350},
                        {nom: 'asodsc', largeur: 350}
                    ]
                })
            ]),
            colonnesVisibles: [
                {nom: 'aspcod', largeur: 140},
                {nom: 'aspdsc', largeur: 400},
                {nom: 'asodsc', largeur: 400},
            ],

            formulaire: new Formulaire([
                new FormulaireItem('aspcleint', {
                    largeur: 50,
                    resourceParams: (data: any) => {
                        return {
                            pczcleint: vm.monoOccurrence.data.pczcleint,
                            vaeasscat: $stateParams.ecran === '00-2' ? 'CAT1' : 'CAT2',
                            flgsairap: 0
                        };
                    }})
            ])

        };

    }

}
