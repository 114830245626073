import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageIN0049 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function In0049Controller(//in0014Route: IRoute,
                                         in0050Route: IRoute,
                                         notificationHandler: INotificationHandler,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItem: IMenuItemClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageIN0049 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    // @TODO ajouter la route de forage une fois que l'écran est fait
    const in0014Route = {NAME: 'secure.accueil'};

    vm.multiOccurrenceOptions = {
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_BOUSOR',
                in0014Route.NAME,
                (data: any) => ({
                    procleint: data.procleint
                }),
                {
                    icon: 'assessment',
                    fonction: 'BOUSOR'
                }
            ),
            new MenuItem('LBL_BOUTRS', (rowData: any) => {
                const msg = vm.valeursEcran.mespro;
                notificationHandler.erreur({
                    lblTitre: 'G_LBL_ERREUR',
                    lblMessage: msg
                });
                }, {
                // Nombre de magasins
                hidden: (data: any) => data.nbrmag > 1,
                icon: 'error_outline',
                fonction: 'BOUTRS'
            }),
            new MenuItem('LBL_BOUTRS', (rowData: any) => {
                const msg = vm.valeursEcran.mesqua;
                notificationHandler.erreur({
                    lblTitre: 'G_LBL_ERREUR',
                    lblMessage: msg
                });
            }, {
                // Quantité en inventaire
                hidden: (data: any) => data.nbrmag <= 1  || data.cqmqteinv !== 0,
                icon: 'error_outline',
                fonction: 'BOUTRS'
            }),
            new MenuItemEcran(
                'LBL_BOUTRS',
                in0050Route.NAME,
                (data: any) => ({
                    id: data.fimcleint,
                    fimcleint: data.fimcleint,
                    procleint: data.procleint
                }),
                {
                    hidden: (data: any) => data.cqmqteinv === 0 || data.nbrmag <= 1,
                    icon: 'autorenew',
                    fonction: 'BOUTRS',
                    onClose() {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('fimcleint', { largeur: 40, required:true, supprimable:false })
        ]),
        colonnesFixesGauche: [
            {nom: 'magcod', largeur: 110},
            {nom: 'magnom', largeur: 250},
            {nom: 'procod', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'prodsc', largeur: 300},
            {nom: 'cqmqteinv', largeur: 120},
            {nom: 'cqmqtedsp', largeur: 130},
            {nom: 'cqmprxmoy', largeur: 120},
            {nom: 'forcod', largeur: 100},
            {nom: 'fordsc', largeur: 300}
        ],
        colonnesCachees: [
            'fimcleint',
            'nbrmag'
        ]
    };

}
