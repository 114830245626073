import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0136 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0136Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0136 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'ceacod'   , largeur: 162},
            {nom: 'ceadsc'   , largeur: 300},
            {nom: 'ceaflgact', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ceacod', {largeur: 20}),
            new FormulaireItem('ceadsc'),
            new FormulaireItem('ceaflgact'),
        ], {largeurDefaut: 40})
    };
}
