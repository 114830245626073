import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageSo0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0019Controller(so0017Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageSo0019 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: so0017Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(so0017Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tundatvig'    , largeur: 110},
            {nom: 'tuncom'       , largeur: 250},
            {nom: 'unocod_rh'    , largeur: 110},
            {nom: 'unodscabr_rh' , largeur: 250},
            {nom: 'unocod_vac'   , largeur: 110},
            {nom: 'unodscabr_vac', largeur: 250},
            {nom: 'stodscabr'    , largeur: 250},
            {nom: 'tunflgconf'  , largeur: 80}
        ]
    };
}
