import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mc1014',
    ecrans: ['00-2'],
    params: [
        'tolcleintref',
        'vaeustent'      // UNA =untiéadm  CIE =compagnie  MAG=Magasin
        // indicateur de forage à developper 'indfor'         // Indicateur de forage     0 =En ecriture     1 =en lecture
    ]
});
