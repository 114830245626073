import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAe0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ae0007Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAe0007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'posnumdoc', largeur: 90},
            {nom: 'postitabr', largeur: 300},
            {nom: 'ccpcod', largeur: 100},
            {nom: 'ccpdsc', largeur: 325},
            {nom: 'vaetypicn_dsc', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('icncleintref', {largeur: 40, nombreLibellesDetailsCacher: 1}),
            new FormulaireItem('ccpcleint', {largeur: 40}),
            new FormulaireItem('vaetypicn', {largeur: 20})
        ])
    };
}


