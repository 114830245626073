import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageIN0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function In0024Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    notificationHandler: INotificationHandler
) {
    const vm: IPageIN0024 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    if (vm.valeursEcran.mesmag) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesmag
        });
        vm.multiOccurrence.disabledRow = () => true;
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'reacod', largeur: 100 },
            { nom: 'readsc', largeur: 300 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'vaedsc', largeur: 100 },
            { nom: 'readatref', largeur: 100 },
            { nom: 'rpsnbrret', largeur: 150 },
            { nom: 'rpsprxest', largeur: 150 }
        ]
    };
}
