import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';

interface IPageAS2003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function As2003Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IStateParamsService) {
    const vm: IPageAS2003 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        bloc: 'dtj',
        fonctions: {
            nouveau: $stateParams.ecldsceta == 'Initial'
        },
        colonnesFixesGauche: [
            { nom: 'dtjhredeb_jr1', largeur: 70, changeColor: true },
            { nom: 'dtjhrefin_jr1', largeur: 70, changeColor: true },
            { nom: 'dtjhredeb_jr2', largeur: 70, changeColor: true },
            { nom: 'dtjhrefin_jr2', largeur: 70, changeColor: true },
            { nom: 'dtjflgcor', largeur: 100, changeColor: true },
            { nom: 'dtjqte', largeur: 100, changeColor: true },
            { nom: 'actcod', largeur: 120, changeColor: true }
        ],
        colonnesVisibles: [
            { nom: 'occupation', largeur: 110, changeColor: true },
            { nom: 'aftcod', largeur: 110, changeColor: true },
            { nom: 'dernumdoc', largeur: 110, changeColor: true },
            { nom: 'drpnumdoc', largeur: 110, changeColor: true },
            { nom: 'imp', largeur: 190, changeColor: true },
            { nom: 'posnumdoc', largeur: 110, changeColor: true },
            { nom: 'unocod', largeur: 110, changeColor: true },
            { nom: 'lemcod', largeur: 110, changeColor: true },
            { nom: 'temcod', largeur: 110, changeColor: true }
        ],
        colonnesFixesDroite: [
            { nom: 'flgcom', largeur: 80, changeColor: true },
            { nom: 'dtjcom', largeur: 80, changeColor: true },
            { nom: 'flgerr', largeur: 80, changeColor: true }
        ],
        colonnesCachees: ['dtjcleint', 'color'],
        formulaire: new Formulaire([
            new FormulaireItem('hrdeb', {
                largeur: 5
            }),
            new FormulaireItem('ldmhrdeb', {
                largeur: 20
            }),
            new FormulaireItem('hrfin', {
                largeur: 5
            }),
            new FormulaireItem('ldmhrfin', {
                largeur: 20
            }),
            new FormulaireItem('dtjqte', {
                largeur: 5
            }),
            new FormulaireItem('dtjflgcor', {
                default: 0,
                largeur: 10
            }),
            new FormulaireItem('occcleint', {
                default: ecranDetails.valeurs.occcleint,
                largeur: 5,
                resourceParams: (data: any) => ({ asgcleint: $stateParams.asgcleint, vaeatftyp: $stateParams.vaeatftyp })
            }),
            new FormulaireItem('aftcleint', {
                resourceParams: (data: any) => ({ demcleint: $stateParams.demcleint, asgcleint: $stateParams.asgcleint, dtjdat: $stateParams.datdeb, occcleint: data.occcleint }),
                largeur: 5
            }),
            new FormulaireItem('actcleint', {
                resourceParams: (data: any) => ({ demcleint: $stateParams.demcleint, dtjdat: data.dtjdat, ptadatfin: $stateParams.ptadatfin, asgcleint: $stateParams.asgcleint, vaeatftyp: $stateParams.vaeatftyp }),
                largeur: 5
            }),
            new FormulaireItem('lemcleint', {
                resourceParams: (data: any) => ({ demcleint: $stateParams.demcleint, dtjdat: $stateParams.datdeb, ptadatfin: data.dtjhrefin, ptadatdeb: data.dtjhredeb }),
                largeur: 5
            }),
            new FormulaireItem('temcleint', {
                largeur: 5
            }),
            new FormulaireItem('poscleint', {
                largeur: 5
            }),
            new FormulaireItem('unocleintrh', {
                largeur: 5
            }),
            new FormulaireItem('dercleint', {
                resourceParams: (data: any) => ({ demcleint: $stateParams.demcleint, dtjdat: $stateParams.datdeb, asgcleint: $stateParams.asgcleint }),
                largeur: 5
            }),
            new FormulaireItem('drpcleint', {
                resourceParams: (data: any) => ({ dercleint: data.dercleint, dtjdat: $stateParams.datdeb }),
                largeur: 5
            }),
            new FormulaireItem('imp', {
                largeur: 40
            }),
            new FormulaireItem('flgcom', {
                largeur: 5
            }),
            new FormulaireItem('flgerr', {
                largeur: 5
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dtjcom', {
                largeur: 80
            }),
            new FormulaireItem('dtjhredeb', {
                largeur: 20
            }),
            new FormulaireItem('dtjhrefin', {
                largeur: 20
            }),
            new FormulaireItem('dtjdat', {
                default: $stateParams.datdeb
            }),
            new FormulaireItem('atfcleint', {
                default: $stateParams.atfcleint
            })
        ])

    };
}

