import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";

interface IPageMr0171 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    valeursEcran: any;
    rssMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rsaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0171Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    mr2008Route: IRoute,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite) {

    const vm: IPageMr0171 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('rsccod', { largeur: 25 }),
            new FormulaireItem('vaersceleanc', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rscdsc', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rsccom', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cgccleint', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe('LBL_TYPE', new Formulaire([
                new FormulaireItem('vaersctyp', { direction: 'vertical' })
            ]), { largeur: 50 }),
            new FormulaireGroupe('LBL_SPE', new Formulaire([
                new FormulaireItem('rscflgimpbas'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rscflgpri'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rscflgpriadm'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rscflgdefpri'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rscflgctbsal')
            ]), { largeur: 50 })
        ], {
            largeurDefaut: 100
        })
    };

    vm.rssMultiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            { nom: 'rssord', largeur: 80 },
            { nom: 'vaedeicle_dsc', largeur: 180 },
            { nom: 'vaersstec_dsc', largeur: 180 },
            { nom: 'rssflgexcact', largeur: 80 }
        ],
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_DEFDEC',
                mr2008Route.NAME,
                (data: any) => {
                    return {
                        rsscleint: data.rsscleint
                    };
                },
                {
                    icon: 'view_list',
                    iconButton: false,
                    hidden: (data: any) => data.flgdec === 0
                }
            )
        ]),
        colonnesCachees: [
            "flgdec"
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rssord'),
            new FormulaireItem('vaedeicle'),
            new FormulaireItem('vaersstec'),
            new FormulaireItem('rssflgexcact')
        ], {
            largeurDefaut: 25
        })
    }
    vm.rsaMultiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            { nom: 'rsaord', largeur: 110 },
            { nom: 'stadsc', largeur: 180 },
            { nom: 'rsaflgbas', largeur: 110 },
            { nom: 'rsaflgaff', largeur: 110 },
            { nom: 'rsaflgtym', largeur: 110 },
            { nom: 'rsaflgexc', largeur: 110 },
            { nom: 'rsacom', largeur: 200 },
            { nom: 'vaersareg_dsc', largeur: 150 },
            { nom: 'rsccoddefpri', largeur: 150 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rsaord', { largeur: 10 }),
            new FormulaireItem('objcleint', {
                largeur: 40,
                resourceParams: () => {
                    return {
                        rscflgimpbas: vm.monoOccurrence.data.rscflgimpbas,
                        vaersctyp: vm.monoOccurrence.data.vaersctyp
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rsaflgbas', { largeur: 20 }),
            new FormulaireItem('rsaflgaff', { largeur: 20 }),
            new FormulaireItem('rsaflgtym', { largeur: 20 }),
            new FormulaireItem('rsaflgexc', { largeur: 20 }),
            new FormulaireItem('rsacom', { largeur: 100 }),
            new FormulaireItem('vaersareg', { largeur: 40 }),
            new FormulaireItem('rsccleintdefpri', { largeur: 40 })
        ], {
            largeurDefaut: 20
        })
    }


}
