import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IData } from '../../../../../core/services/data-linker.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageRR0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0001Controller(
    rr0003Route: IRoute,
    rr0093Route: IRoute,
    rr0002Route: IRoute,
    Menu: IMenuClass,
    Operateur: IOperateurService,
    Pagination: IPaginationClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: any
) {
    const vm: IPageRR0001 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: (data: IData) => getForage(data),
        navigateParams: (data: any) => ({ id: data.$flgcen ? data.trncleint : data.eplcleint }),
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0002Route.NAME)
        ]),
        /*classItems: { SIGA-47433
            row: (data: any) => {
                 if (ecranDetails.valeurs.phlflgaffcoueplina === 1 && data.inactif) {
                    return 'ex-ligne-inactive';
                }

                if (data.inactif) {
                    return 'ex-ligne-inactive';
                }
            }
        },*/
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            criteresSuggeresVisibles: localStorage.getItem('forageFromMenu') === 'oui',
            nouveau: $stateParams.ecran === "00-2",
            selectionCycle: $stateParams.ecran === "00-2"
        },
        filtres: [
            { ...parametresRecherche(ecranDetails),
                colonne: 'ann', 
                operateur: Operateur.EGALE_PAS,
                valeur: '1'}
           ],
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_CRI_EMP'),
            new FormulaireItem('eplnummat', { largeur: 15,  required: false }),
            new FormulaireItem('eplnomprn'),
            new FormulaireItem('eplnas', { largeur: 15 }),
            new FormulaireItem('nascri', { default: 'T' }),
            new FormulaireItem('unpcleint'),
            new FormulaireItemDivider('LBL_CRI_LEM'),
            new FormulaireItem('datau'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('tlecleint'),
            new FormulaireItemDivider('LBL_CRI_AFT'),
            new FormulaireItem('datauaft'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('poscleint'),
            new FormulaireItem('temcleint'),
            new FormulaireItem('tafcleint'),
            new FormulaireItem('unocleintrh'),
            new FormulaireItem('unocleintvac'),
            new FormulaireItem('grecleint'),
            new FormulaireItemDivider('LBL_CRI_LSA'),
            new FormulaireItem('geccleint'),
            new FormulaireItem('vaetsatypsal'),
            new FormulaireItemDivider("LBL_CRI_REF", { hidden: !ecranDetails.valeurs.flgaffref1 && !ecranDetails.valeurs.flgaffref2 && !ecranDetails.valeurs.flgaffref3 && !ecranDetails.valeurs.flgaffref4 }),
            new FormulaireItem('eplnumref1', { hidden: !ecranDetails.valeurs.flgaffref1 }),
            new FormulaireItem('eplnumref2', { hidden: !ecranDetails.valeurs.flgaffref2 }),
            new FormulaireItem('eplnumref3', { hidden: !ecranDetails.valeurs.flgaffref3 }),
            new FormulaireItem('eplnumref4', { hidden: !ecranDetails.valeurs.flgaffref4 })
        ]),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnom', largeur: 162 },
            { nom: 'eplprn', largeur: 162 }
        ],
        colonnesVisibles: [
            { nom: 'ecldsceta', largeur: 180 },
            { nom: 'eplini', largeur: 90 },
            { nom: 'vaeeplsex_dsc', largeur: 157 },
            { nom: 'flgnas', largeur: 157 },
            { nom: 'eplnas', largeur: 115, hidden: !ecranDetails.flgcen || !ecranDetails.valeurs.flgnas },
            { nom: 'epldatnai', largeur: 100, hidden: !ecranDetails.flgcen },
            { nom: 'eplflgimm', largeur: 94 },
            { nom: 'vaeepllng_dsc', largeur: 120 },
            { nom: 'epladrcou', largeur: 393 },
            { nom: 'eplnam', largeur: 160, hidden: !ecranDetails.flgcen },
            { nom: 'eplsrucod', largeur: 120 },
            { nom: 'eplnumref1', largeur: 183, hidden: !ecranDetails.valeurs.flgaffref1 },
            { nom: 'eplnumref2', largeur: 183, hidden: !ecranDetails.valeurs.flgaffref2 },
            { nom: 'eplnumref3', largeur: 183, hidden: !ecranDetails.valeurs.flgaffref3 },
            { nom: 'eplnumref4', largeur: 183, hidden: !ecranDetails.valeurs.flgaffref4 },
            { nom: 'lemcod', largeur: 105 },
            { nom: 'lemdsc', largeur: 262 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdsc', largeur: 262 },
            { nom: 'strcod', largeur: 120 },
            { nom: 'strdsc', largeur: 262 },
            { nom: 'datemb', largeur: 110 },
            { nom: 'datdepdef', largeur: 130 },
            { nom: 'unpcod', largeur: 150 },
            { nom: 'unpdsc', largeur: 262 },
            { nom: 'eplnomnai', largeur: 162 },
            { nom: 'eplnomusu', largeur: 162 },
            { nom: 'eplprnusu', largeur: 162 },
            { nom: 'idesexdsc', largeur: 162 },
            { nom: 'eplben', largeur: 200 },
            { nom: 'rfecod_fed', largeur: 200 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 85 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'ann', largeur: 75 }
        ],
        colonnesCachees: [
            'eplcleint',
            'eplnomprn',
            'epicleint',
            'vaeeplsex',
            'demcleint',
            'message',
            'inactif'
        ],
        colonnesDisponibles: [
            { nom: 'demage', largeur: 110 }
        ]
    };

    function getForage(data: IData) {
        if (data.$flgcen) {
            return rr0003Route.NAME;
        } else {
            return rr0093Route.NAME;
        }
    }
}
