import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenu, IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IComponentController, IScope} from 'angular';
import {IMenuItem, IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireGroupeConditionnelClass} from '../../../../../core/services/formulaire/formulaire-groupe-conditionnel.service';
import {IPagination, IPaginationClass} from '../../../../../core/services/pagination.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {IFilterLibelle} from '../../../../../core/filters/ex-libelle.filter';
import {IDefaultsService} from '../../../../../core/services/utils/defaults.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IDialog} from '../../../../../core/services/dialog.service';
import {IFiltre, IFiltreClass} from '../../../../../core/services/filtre.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {ISourceDetails} from '../../../../../core/resources/source-details.resource';
import {IFilterExDate} from '../../../../../core/filters/ex-date.filter';
import {IEtatsResourcesEntite} from "../../../../../core/resources/etat.resource";
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCH0001 extends IComponentController {
    menuPeriode: IMenu;
    valeursEcran: any;
    periodeCourante: IPeriode;
    periodes: Array<IPeriode>;
    pagination: IPagination;
    formulaireItp: IFormulaire;
    menuItemPublication: IMenuItem;
    menuItemAppel: IMenuItem;
    monoOccurrence: IMonoOccurrence;
    multiOccurrence: IMultiOccurrence;
    ieeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    getDatePeriode(): string;
}

interface IPeriode {
    itycleint: number;
    itycleint__itydatdeb: string;
    itycleint__itydatfin: string;
    itycleint__publication: string;
}

interface ICh0001Params {
    srccod: string;
    itncleint: string;
}

/* @ngInject */
export default function Ch0001Controller(ch0007Route: IRoute,
                                         ch0011Route: IRoute,
                                         $stateParams: ICh0001Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
                                         DialogPlanificationEmploye: IDialog,
                                         defaults: IDefaultsService,
                                         Operateur: IOperateurService,
                                         Filtre: IFiltreClass,
                                         Menu: IMenuClass,
                                         Pagination: IPaginationClass,
                                         $scope: IScope,
                                         MenuItem: IMenuItemClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranSourceDetails: ISourceDetails,
                                         exLibelleFilter: IFilterLibelle,
                                         exDateFilter: IFilterExDate) {
    const vm: IPageCH0001 = this;
    const DATE_FORMAT = 'YYYY-MM-DD';
    const LONGUEUR_PERIODE = 7;

    vm.$onInit = $onInit;
    vm.getDatePeriode = getDatePeriode;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        defaults(vm, {
            periodes: []
        });
        // Bouton forage Publication
        vm.menuItemPublication = new MenuItemEcran(
            'LBL_PUBLICATION',
            ch0011Route.NAME,
            (data: any) => ({
                id: vm.monoOccurrence.data.itpcleint
            }),
            {
                icon: 'cloud_done',
                iconButton: false,
                fonction: 'BTN_PUBLICATION',
                //hidden: (data:any) => vm.valeursEcran.iteflgpubhoraut
                onClose: () => vm.monoOccurrence.fetchData()
            }
        );

        // Bouton forage Appel
        vm.menuItemAppel = new MenuItemForage(
            'LBL_Appel',
            ch0007Route.NAME,
            (data: any) => ({
                itpcleint: vm.monoOccurrence.data.itpcleint
            }),
            {
                icon: 'insert_invitation',
                iconButton: false,
                fonction: 'BTN_APPEL_DISPO'
            }
        );

        vm.formulaireItp = new Formulaire('LBL_SITE', [
            new FormulaireItem('itecleint', {largeur: 25, modifAllow: false}),
            new FormulaireItem('itpdatdeb', {largeur: 25, modifAllow: false}),
            new FormulaireItem('itpdatfin', {largeur: 25, modifAllow: false, minDate: (data: any) => data.itpdatdeb})
        ]);

        $scope.$watch('vm.monoOccurrence.dataReady', (dataReady: boolean) => {
            if (dataReady && !vm.pagination) {
                vm.periodes = vm.monoOccurrence.data.cycles;
                if (vm.periodes.length) {
                    vm.periodeCourante = vm.periodes[0];

                    vm.pagination = new Pagination({
                        total: vm.periodes.length,
                        nombreElementPage: 1,
                        onChange: onChangePeriode
                    });

                    vm.menuPeriode = new Menu(() => buildStringDatePeriode(moment(vm.periodeCourante.itycleint__itydatdeb), moment(vm.periodeCourante.itycleint__itydatfin), vm.periodeCourante.itycleint__publication), [], {
                        disabledIcon: true
                    });

                    vm.periodes.forEach((ele: IPeriode, i: number) => {
                        const page = i + 1;
                        vm.menuPeriode.add(new MenuItem(buildStringDatePeriode(moment(ele.itycleint__itydatdeb), moment(ele.itycleint__itydatfin), ele.itycleint__publication, page), () => {
                            vm.pagination.setPage(page);
                        }));
                    });

                    const menuJours = [new MenuItem((event: MouseEvent, data: any, col: string) => {
                        vm.multiOccurrence.activeRowCleint = data.ieecleint;
                        const options = {
                            icon: 'mode_edit',
                            lblTitre: 'LBL_PLANIFICATION_EMPLOYE',
                            libelles: vm.multiOccurrence.libelles,
                            isCreation: Boolean(!data[col]),
                            data: Object.assign(data, {
                                debutPeriode: vm.periodeCourante.itycleint__itydatdeb,
                                itnnumjrs: Number(col.slice(-1))
                            }),
                            ecranContext: {
                                stateParams: $stateParams,
                                ecranDetails,
                                ecranSourceDetails
                            }
                        };

                        DialogPlanificationEmploye.show({locals: options})
                            .finally(() => {
                                vm.multiOccurrence.fetchDataList();
                            });
                    })];

                    vm.ieeMultiOccurrenceOptions = {
                        bris: 'etiquette',
                        filtres: [
                            getFiltreOptionsPeriode()
                        ],
                        fonctions: {
                            selectionnerDesColonnes: false,
                            selectionnerTri: false,
                            libellesDynamiques: true
                        },
                        actionsMore:  new Menu([
                            new MenuItemAction(
                                'LBL_BTN_COPIER',
                                'BOUCOPCYC',
                                (data: any) => ({
                                    srccod: $stateParams.srccod,
                                    itycleint: vm.periodeCourante.itycleint,
                                    periode_courante: getDatePeriode()
                                }),
                                {
                                    mnemonique: ecranSourceDetails.mnemonique,
                                    icon: 'content_copy',
                                    formulaireConfirmationAction: {
                                        lblTitre: 'LBL_COPIE',
                                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                                        largeurFormulaire: 30,
                                        formulaire: new Formulaire([
                                            new FormulaireItem('itecleint', {largeur: 55, readonly: true}),
                                            new FormulaireItem('periode_courante', {largeur: 55, readonly: true}),
                                            new FormulaireItem('datde', {
                                                largeur: 50,
                                                required: true,
                                                default: () => getNextPeriodeFirstDate(),
                                                minDate: () => getNextPeriodeFirstDate()
                                            }),
                                            new FormulaireItem('nbrsem', {largeur: 50, required: true, default: 1})
                                        ])
                                    }
                                }
                            )
                        ]),
                        actionsMoreLigne: new Menu([
                            new MenuItemAction(
                                'LBL_MODIFICATION_ETIQUETTE',
                                'BOUMODETQ',
                                (data: any) => ({
                                    id: data.ieecleint,
                                    itycleint: vm.periodeCourante.itycleint,
                                    periode_courante: getDatePeriode()
                                }),
                                {
                                    icon: 'mode_edit',
                                    successAction: () => {
                                        vm.multiOccurrence.fetchDataList();
                                    },
                                    formulaireConfirmationAction: {
                                        lblTitre: 'LBL_MODIFICATION_ETIQUETTE',
                                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                                        largeurFormulaire: 30,
                                        formulaire: new Formulaire([
                                            new FormulaireItem('etiquette', {readonly: true, largeur: 55, titre: 'etqcleint'}),
                                            new FormulaireItem('eplnomprn', {readonly: true, largeur: 55}),
                                            new FormulaireItem('periode_courante', {readonly: true, largeur: 55}),
                                            new FormulaireItemDivider('LBL_MODIFIER_POUR'),
                                            new FormulaireItem('etqcleint_new', {
                                                titre: 'etqcleint',
                                                required: true,
                                                largeur: 60
                                            })
                                        ])
                                    }
                                }),
                            new MenuItemAction(
                                'LBL_MODIFICATION_EMPLOYE',
                                'BOUMODEMP',
                                (data: any) => ({
                                    id: data.ieecleint,
                                    itycleint: vm.periodeCourante.itycleint,
                                    periode_courante: getDatePeriode()
                                }),
                                {
                                    icon: 'mode_edit',
                                    successAction: () => {
                                        vm.multiOccurrence.fetchDataList();
                                    },
                                    formulaireConfirmationAction: {
                                        lblTitre: 'LBL_MODIFICATION_EMPLOYE',
                                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                                        largeurFormulaire: 30,
                                        formulaire: new Formulaire([
                                            new FormulaireItem('etiquette', {readonly: true, largeur: 55, titre: 'etqcleint'}),
                                            new FormulaireItem('eplnomprn', {readonly: true, largeur: 55}),
                                            new FormulaireItem('periode_courante', {readonly: true, largeur: 55}),
                                            new FormulaireItemDivider('LBL_MODIFIER_POUR'),
                                            new FormulaireItem('demcleint_new', {
                                                titre: 'demcleint',
                                                required: true,
                                                largeur: 60,
                                                resourceParams: (data: any) => ({etqcleint: data.etqcleint, itecleint: data.itecleint})
                                            })
                                        ])
                                    }
                                }),
                            new MenuItemAction(
                                'LBL_BTN_COPIER',
                                'BOUCOPEMP',
                                (data: any) => ({
                                    id: data.ieecleint,
                                    itycleint: vm.periodeCourante.itycleint,
                                    itpdatfin: vm.monoOccurrence.data.itpdatfin,
                                    periode_courante: getDatePeriode()
                                }),
                                {
                                    icon: 'content_copy',
                                    successAction: () => {
                                        vm.multiOccurrence.fetchDataList();
                                    },
                                    formulaireConfirmationAction: {
                                        lblTitre: 'LBL_COPIE',
                                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                                        largeurFormulaire: 30,
                                        formulaire: new Formulaire([
                                            new FormulaireItem('etiquette', {readonly: true, largeur: 55, titre: 'etqcleint'}),
                                            new FormulaireItem('eplnomprn', {readonly: true, largeur: 55}),
                                            new FormulaireItem('periode_courante', {readonly: true, largeur: 55}),
                                            new FormulaireItemDivider('LBL_COPIER_VERS'),
                                            new FormulaireItem('demcleint_new', {
                                                titre: 'demcleint',
                                                required: true,
                                                largeur: 60,
                                                resourceParams: (data: any) => ({etqcleint: data.etqcleint, itecleint: data.itecleint})
                                            }),
                                            new FormulaireItemSautDeLigne(),
                                            new FormulaireItem('datde', {
                                                default: () => getNextPeriodeFirstDate(),
                                                minDate: () => vm.periodeCourante.itycleint__itydatdeb,
                                                required: true,
                                                largeur: 30
                                            }),
                                            new FormulaireItem('nbrsem', {required: true, largeur: 30, default: 1})
                                        ])
                                    }
                                })
                        ], {positionFirst: true}),
                        actionsNouveaux: new Menu([
                            new MenuItemAction(
                                '',
                                'BOUCREEMP',
                                (data: any) => ({
                                    srccod: $stateParams.srccod,
                                    itecleint: vm.monoOccurrence && vm.monoOccurrence.data.itecleint,
                                    itycleint: vm.periodeCourante.itycleint
                                }),
                                {
                                    successAction: () => {
                                        vm.multiOccurrence.fetchDataList();
                                    },
                                    formulaireConfirmationAction: {
                                        lblTitre: 'LBL_AJOUT_EMPLOYE',
                                        lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                                        lblConfirmAndNext: 'G_LBL_BTN_ENREGISTRER_SUIVANT',
                                        customClass: 'ch0001-dialog-ajout-employe',
                                        largeurFormulaire: 60,
                                        formulaire: new Formulaire([
                                            new FormulaireItem('etqcleint', {required: true, largeur: 50}),
                                            new FormulaireItem('demcleint', {
                                                required: true,
                                                largeur: 50,
                                                resourceParams: (data: any) => ({etqcleint: data.etqcleint, itecleint: data.itecleint})
                                            }),
                                            new FormulaireItem('date', {
                                                minDate: () => vm.periodeCourante.itycleint__itydatdeb,
                                                maxDate: () => vm.periodeCourante.itycleint__itydatfin,
                                                required: true,
                                                largeur: 50
                                            }),
                                            new FormulaireItem('hredeb', {required: true, largeur: 25}),
                                            new FormulaireItem('hrefin', {required: true, largeur: 25}),
                                            new FormulaireItem('description', {
                                                largeur: 100,
                                                enableCount: true
                                            }),
                                            new FormulaireGroupeConditionnel('repete', '', new Formulaire([
                                                new FormulaireItem('jrs1', {largeur: 10, titre: 'JRS_1_ABR'}),
                                                new FormulaireItem('jrs2', {largeur: 10, titre: 'JRS_2_ABR'}),
                                                new FormulaireItem('jrs3', {largeur: 10, titre: 'JRS_3_ABR'}),
                                                new FormulaireItem('jrs4', {largeur: 10, titre: 'JRS_4_ABR'}),
                                                new FormulaireItem('jrs5', {largeur: 10, titre: 'JRS_5_ABR'}),
                                                new FormulaireItem('jrs6', {largeur: 10, titre: 'JRS_6_ABR'}),
                                                new FormulaireItem('jrs7', {largeur: 10, titre: 'JRS_7_ABR'}),
                                                new FormulaireItemSautDeLigne(),
                                                new FormulaireItem('nbrsem', {required: true, largeur: 10, default: 1})
                                            ]), {default: 0, switch: true})
                                        ])
                                    }
                                }
                            )
                        ]),
                        // Colonnes fixe à gauche
                        colonnesFixesGauche: [
                            {nom: 'eplnomprn', largeur: 135},
                            {nom: 'eplnummat', largeur: 100}
                        ],
                        // Colonnes fixe à droite
                        colonnesFixesDroite: [
                            {nom: 'ieetotetq', largeur: 100},
                            {nom: 'ieetotdem', largeur: 100}
                        ],
                        colonnesCachees: [
                            'itecleint',
                            'itpcleint',
                            'etiquette',
                            'itycleint',
                            'etqcleint',
                            'demcleint',
                            'itndat',
                            'errdis_1',
                            'errdis_2',
                            'errdis_3',
                            'errdis_4',
                            'errdis_5',
                            'errdis_6',
                            'errdis_7'
                        ],
                        // Affichage des colonnes visibles
                        colonnesVisibles: [
                            {nom: 'jrs_1', largeur: 165, menu: menuJours},
                            {nom: 'jrs_2', largeur: 165, menu: menuJours},
                            {nom: 'jrs_3', largeur: 165, menu: menuJours},
                            {nom: 'jrs_4', largeur: 165, menu: menuJours},
                            {nom: 'jrs_5', largeur: 165, menu: menuJours},
                            {nom: 'jrs_6', largeur: 165, menu: menuJours},
                            {nom: 'jrs_7', largeur: 165, menu: menuJours}]
                    };

                    const unwatchDataReady = $scope.$watch('vm.monoOccurrence.dataReady', (dataReady: boolean) => {
                        if (dataReady) {
                            updatePeriodes()
                            vm.monoOccurrence.on('dataUpdate', updatePeriodes);
                            unwatchDataReady();
                        }
                    });
                }
            }
        });

        const unwatchInitialized = $scope.$watch('vm.multiOccurrence.initialized', (initialized: boolean) => {
            if (initialized) {
                initPeriodes();
                vm.multiOccurrence.on('selectedEtatUpdate', (selectedEtat: IEtatsResourcesEntite) => {
                    const newFiltrePeriode = selectedEtat.filtres.find((filtre: IFiltre) => filtre.colonne === 'itycleint');
                    if (newFiltrePeriode && Number(newFiltrePeriode.getValeur()) !== vm.periodeCourante.itycleint) {
                        vm.multiOccurrence.preventDataListUpdate = true;
                        // On conserve la même période au changement d'état
                        const filtreNouveauPeriode = new Filtre(getFiltreOptionsPeriode());

                        let indexFiltreCourant = vm.multiOccurrence.etatSelected.filtres.findIndex((filtre:IFiltre)=>filtre.colonne === 'itycleint');
                        selectedEtat.filtres[indexFiltreCourant] = filtreNouveauPeriode;

                        vm.multiOccurrence.updateSelectedEtat(selectedEtat)
                            .finally(() => {
                                vm.multiOccurrence.preventDataListUpdate = false;
                            });
                    }
                });

                unwatchInitialized();
            }
        });
    }

    function updatePeriodes() {
        vm.periodes = vm.monoOccurrence.data.cycles;
        if (vm.periodes.length) {
            vm.periodeCourante = vm.periodes[0];

            vm.pagination = new Pagination({
                total: vm.periodes.length,
                nombreElementPage: 1,
                onChange: onChangePeriode
            });

            vm.menuPeriode = new Menu(() => buildStringDatePeriode(moment(vm.periodeCourante.itycleint__itydatdeb), moment(vm.periodeCourante.itycleint__itydatfin), vm.periodeCourante.itycleint__publication), [], {
                disabledIcon: true
            });

            vm.periodes.forEach((ele: IPeriode, i: number) => {
                const page = i + 1;
                vm.menuPeriode.add(new MenuItem(buildStringDatePeriode(moment(ele.itycleint__itydatdeb), moment(ele.itycleint__itydatfin), ele.itycleint__publication, page), () => {
                    vm.pagination.setPage(page);
                }));
            });
        }
    }

    function initPeriodes() {
        const filtrePeriode = vm.multiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === 'itycleint');
        const indexPageDepart = (filtrePeriode) ?
            vm.periodes.findIndex((periode: IPeriode) => periode.itycleint === Number(filtrePeriode.getValeur())) : 0;

        vm.periodeCourante = vm.periodes[indexPageDepart];

        buildLibellesDynamiques();

        vm.pagination = new Pagination({
            total: vm.periodes.length,
            nombreElementPage: 1,
            onChange: onChangePeriode,
            pageCourante: indexPageDepart + 1
        });

        vm.menuPeriode = new Menu(() => buildStringDatePeriode(moment(vm.periodeCourante.itycleint__itydatdeb), moment(vm.periodeCourante.itycleint__itydatfin), vm.periodeCourante.itycleint__publication), [], {
            disabledIcon: true
        });

        vm.periodes.forEach((ele: IPeriode, i: number) => {
            const page = i + 1;
            vm.menuPeriode.add(new MenuItem(buildStringDatePeriode(moment(ele.itycleint__itydatdeb), moment(ele.itycleint__itydatfin), ele.itycleint__publication, page), () => {
                vm.pagination.setPage(page);
            }));
        });
    }

    function getFiltreOptionsPeriode() {
        return {
            colonne: 'itycleint',
            operateur: Operateur.EGALE,
            valeur: `${vm.periodeCourante.itycleint}`,
            visible: false
        };
    }

    function onChangePeriode() {
        vm.periodeCourante = vm.periodes[vm.pagination.pageCourante - 1];
        buildLibellesDynamiques();

        const filtreNouveauPeriode = new Filtre(getFiltreOptionsPeriode());

        let indexFiltreCourant = vm.multiOccurrence.etatSelected.filtres.findIndex((filtre:IFiltre)=>filtre.colonne === 'itycleint');
        vm.multiOccurrence.etatSelected.filtres[indexFiltreCourant] = filtreNouveauPeriode;
    }

    function buildStringDatePeriode(dateDebut: Moment, dateFin: Moment, publication: string, page?: number): string {
        const nbrPage = page ? page : vm.pagination.pageCourante;
        return `${exLibelleFilter('LBL_SEMAINE', vm.monoOccurrence.libelles)} ${nbrPage} (${dateDebut.format(DATE_FORMAT)} ${exLibelleFilter('LBL_AU', vm.monoOccurrence.libelles)} ${dateFin.format(DATE_FORMAT)}) - ${publication}`;
    }

    function getDatePeriode(): string {
        return buildStringDatePeriode(moment(vm.periodeCourante.itycleint__itydatdeb), moment(vm.periodeCourante.itycleint__itydatfin), vm.periodeCourante.itycleint__publication);
    }

    function buildLibellesDynamiques(): void {
        Array.from(Array(LONGUEUR_PERIODE).keys()).forEach((i) => {
            const nameDay = moment(vm.periodeCourante.itycleint__itydatdeb).add(i, 'day').format('ddd');
            const numberDay = moment(vm.periodeCourante.itycleint__itydatdeb).add(i, 'day').format('DD');
            setLibelle((`JRS_${i + 1}`), `${nameDay}</br>${numberDay}`);
            //On ajoute l'abréviation pour l'utiliser dans les modales
            setLibelle((`JRS_${i + 1}_ABR`), nameDay);
        });
    }

    function setLibelle(col: string, val: string): void {
        vm.multiOccurrence.libelles[col] = val;
        vm.multiOccurrence.libelles.$libelles[col] = val;
    }

    function getNextPeriodeFirstDate() {
        const dateDebut = new Date(vm.periodeCourante.itycleint__itydatdeb);
        dateDebut.setDate(dateDebut.getDate() + LONGUEUR_PERIODE);
        return exDateFilter(dateDebut);
    }
}
