import { IComponentController } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCb0005 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    depMultiOccurrence: IMultiOccurrence;
    revMultiOccurrence: IMultiOccurrence;
    actMultiOccurrence: IMultiOccurrence;
    pasMultiOccurrence: IMultiOccurrence;
    avoMultiOccurrence: IMultiOccurrence;
    depMultiOccurrenceOptions: IMultiOccurrenceOptions;
    revMultiOccurrenceOptions: IMultiOccurrenceOptions;
    actMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    avoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0005Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IStateParamsService,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cb2004Route: IRoute,
    cb0026Route: IRoute,
    cb0031Route: IRoute,
    cb0050Route: IRoute,
    cb0006Route: IRoute,
    cb0034Route: IRoute,
    cb2005Route: IRoute,
    cb2007Route: IRoute
) {
    const vm: IPageCb0005 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const forageCB0050 = new MenuItemForage(
        'LBL_RESERVE',
        cb0050Route.NAME,
        (rowData: any) => ({
            id: rowData.prucleint,
            prucleint: rowData.prucleint,
            vaesysnatctb: rowData.vaesysnatctb,
            annfindeb: rowData.pecann,
            menuId: $stateParams.menuId
        }),
        {
            fonction: 'RESERVES',
            icon: 'exit_to_app',
            iconButton: true,
            hidden: (rowData: any) => rowData.mntrsv === null
        }
    );

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('prucleint', {default: ($stateParams.id), hidden: true}),
        new FormulaireItem('peccleintcivdeb', { 
            hidden: () => (vm.valeursEcran.vaeprutypbdg === 'A')
        }),
        new FormulaireItem('peccleintcivfin', { 
            hidden: () => (vm.valeursEcran.vaeprutypbdg === 'A')
        }),
        new FormulaireItem('peccleintfindeb', { 
            hidden: () => (vm.valeursEcran.vaeprutypbdg === 'P')
        }),
        new FormulaireItem('peccleintfinfin', { 
            hidden: () => (vm.valeursEcran.vaeprutypbdg === 'P')
        }),
        new FormulaireItem('indcleintcri')
    ])

    // Onglet "Depenses"
    vm.depMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0034',
                cb0034Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'DEP',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0034',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0006',
                cb0006Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'DEP',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0006',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0026',
                cb0026Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'DEP',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0026',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0031',
                cb0031Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'DEP',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0031',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2005',
                cb2005Route.NAME,
                (rowData:any) => ({
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'DEP',
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleint: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB2005',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData:any) => ({
                    niveau: 'PRU',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'DEP',
                    indcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.depMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            effaceCriteres: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 200 },
            { nom: 'ctbmntsum', largeur: 130 },
            { nom: 'ctbmntimp', largeur: 130, total:true },
            { nom: 'mntrsv', largeur: 130, menu: [forageCB0050] }
        ],
        colonnesCachees: [
            'prucleint', 'vaesysnatctb', 'pecann', 'tymcleint'
        ]
    }

    // Onglet "Revenus"
    vm.revMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0034',
                cb0034Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'REV',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB0034',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0006',
                cb0006Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'REV',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB0006',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0026',
                cb0026Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'REV',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB0026',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0031',
                cb0031Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'REV',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB0031',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2005',
                cb2005Route.NAME,
                (rowData:any) => ({
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'REV',
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleint: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB2005',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData:any) => ({
                    niveau: 'PRU',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'REV',
                    indcleint: vm.revMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.revMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_REV_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 200 },
            { nom: 'ctbmntsum', largeur: 130 },
            { nom: 'ctbmntimp', largeur: 130, total:true },
            { nom: 'mntrsv', largeur: 130, menu: [forageCB0050] }
        ],
        colonnesCachees: [
            'prucleint', 'vaesysnatctb', 'pecann', 'tymcleint'
        ]
    };

    // Onglet "Actifs"
    vm.actMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0034',
                cb0034Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'ACT',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0034',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0006',
                cb0006Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'ACT',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0006',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0026',
                cb0026Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'ACT',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0026',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0031',
                cb0031Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'AVT',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0031',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2005',
                cb2005Route.NAME,
                (rowData:any) => ({
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'ACT',
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleint: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB2005',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData:any) => ({
                    niveau: 'PRU',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'ACT',
                    indcleint: vm.actMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.actMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 200 },
            { nom: 'ctbmntsum', largeur: 130 },
            { nom: 'ctbmntimp', largeur: 130, total:true },
            { nom: 'mntrsv', largeur: 130, menu: [forageCB0050] }
        ],
        colonnesCachees: [
            'prucleint', 'vaesysnatctb', 'pecann', 'tymcleint'
        ]
    };

    // Onglet "Passifs"
    vm.pasMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0034',
                cb0034Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'PAS',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB0034',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0006',
                cb0006Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'PAS',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB0006',
                    icon: 'exit_to_app'
                }
            ),            
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0026',
                cb0026Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'PAS',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB0026',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0031',
                cb0031Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'PAS',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB0031',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2005',
                cb2005Route.NAME,
                (rowData:any) => ({
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'PAS',
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleint: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB2005',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData:any) => ({
                    niveau: 'PRU',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'PAS',
                    indcleint: vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.pasMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_PAS_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )            
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 200 },
            { nom: 'ctbmntsum', largeur: 130 },
            { nom: 'ctbmntimp', largeur: 130, total:true },
            { nom: 'mntrsv', largeur: 130, menu: [forageCB0050] }
        ],
        colonnesCachees: [
            'prucleint', 'vaesysnatctb', 'pecann', 'tymcleint'
        ]
    };

    // Onglet "Avoirs"
    vm.avoMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0034',
                cb0034Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'AVO',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB0034',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0006',
                cb0006Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'AVO',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB0006',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0026',
                cb0026Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'AVO',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB0026',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0031',
                cb0031Route.NAME,
                (rowData:any) => ({
                    id: rowData.prucleint,
                    typapp: 'PRU',
                    typbdg: 'AN',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'AVO',
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB0031',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2005',
                cb2005Route.NAME,
                (rowData:any) => ({
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'AVO',
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleint: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB2005',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB2007',
                cb2007Route.NAME,
                (rowData:any) => ({
                    niveau: 'PRU',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: 'AVO',
                    indcleint: vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.indcleintcri,
                    peccleintdeb: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfindeb :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivdeb ) ,
                    peccleintfin: ( vm.valeursEcran.vaeprutypbdg === 'A' ? vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfinfin :
                            vm.avoMultiOccurrence.etatSelected.criteresSuggeresData.peccleintcivfin ),
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_AVO_FORAGE_CB2007',
                    icon: 'exit_to_app'
                }
            )  
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 200 },
            { nom: 'ctbmntsum', largeur: 130 },
            { nom: 'ctbmntimp', largeur: 130, total:true },
            { nom: 'mntrsv', largeur: 130, menu: [forageCB0050] }
        ],
        colonnesCachees: [
            'prucleint', 'vaesysnatctb', 'pecann', 'tymcleint'
        ]
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cb2004Route.NAME,
                (rowData: any) => ({
                    type: 'PRU',
                    prucleint: rowData.prucleint,
                    vaesysnatctb: rowData.vaesysnatctb,
                    tymcleint: rowData.tymcleint,
                    tydcleint: rowData.tydcleint,
                    peccleintdeb: rowData.peccleintdeb,
                    peccleintfin: rowData.peccleintfin
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'tyddsc', largeur: 300},
            {nom: 'ctbmntsum', largeur: 120}
        ],
        colonnesCachees: [
            'prucleint',
            'vaesysnatctb',
            'tymcleint',
            'tydcleint',
            'peccleintdeb',
            'peccleintfin'
        ]
    };

}
