import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0147 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0147Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0147 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'etanomfra', largeur: 262 },
            { nom: 'muncod', largeur: 71 },
            { nom: 'munnomfra', largeur: 262 },
        ],
        colonnesVisibles: [
            { nom: 'vacsysreg_cod', largeur: 89 },
            { nom: 'vacsysreg_dsc', largeur: 262 }
        ],
        colonnesDisponibles: [
            {nom: 'muntopcod', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('etacleint', { required: true }),
            new FormulaireItem('muncod', { required: true }),
            new FormulaireItem('munnomfra'),
            new FormulaireItem('muntopcod'),
            new FormulaireItem('munnomang'),
            new FormulaireItem('vacsysreg')
        ])
    };
}
