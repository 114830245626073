import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0083 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0083Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0083 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'posnumcod', largeur: 90},
            {nom: 'postitabr', largeur: 230},
            {nom: 'crxcod', largeur: 130},
            {nom: 'crxdsc', largeur: 230},
            {nom: 'gcrcod', largeur: 125},
            {nom: 'gcrdsc', largeur: 230},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ocrcleintref', {required: true}),
            new FormulaireItem('crxcleint', {required: true})
        ])
    };
}
