import { IComponentController } from "angular";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPagePR0022 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence
    invMultiOccurrenceOptions: IMultiOccurrenceOptions;
    orgMultiOccurrenceOptions: IMultiOccurrenceOptions
    facMultiOccurrenceOptions: IMultiOccurrenceOptions
    invMultiOccurrence: IMultiOccurrence
    orgMultiOccurrence: IMultiOccurrence
    facMultiOccurrence: IMultiOccurrence
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Pr0022Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePR0022 = this;

    vm.monoOccurrenceOptions = {
        noId: true
    }

        const criteresSuggeres = new Formulaire([
            new FormulaireItem('prjcleint', { required: false, largeur:25 }), 
            new FormulaireItem('orgcleint', { required: false, largeur: 25 }),
            new FormulaireItem('unacleintde', { largeur: 25 }),
            new FormulaireItem('unacleinta', { largeur: 25}),
            new FormulaireItem('tyfcleint', { required: false, largeur: 25 }),             
            new FormulaireItem('toccleint', { largeur: 25 }),
            new FormulaireItem('intcleint', { largeur: 25 }), 
            new FormulaireItem('typcleint', { required: false, largeur: 25 }),
            new FormulaireItem('datede', { largeur: 25 }),
            new FormulaireItem('datea', { largeur: 25 }),
            new FormulaireItem('annde', { largeur: 25 }),                           
            new FormulaireItem('anna', { largeur: 25 })
        ])

        vm.invMultiOccurrenceOptions = {
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false, 
                effaceCriteres: true,               
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiser: false,
                reinitialiserPastillefiltre: true

            },
            criteresSuggeres,
            colonnesVisibles: [
                { nom: "pdfcod", largeur: 140 },
                { nom: "vaedsc", largeur: 100 },
                { nom: "prjcod", largeur: 120 },
                { nom: "typcod", largeur: 120},
                { nom: "typdsc", largeur: 200},
                { nom: "intcod_rsp", largeur: 140},
                { nom: "intnom_rsp", largeur: 200},
                { nom: "unacod_rsp", largeur: 140},
                { nom: "unanom_rsp", largeur: 250},
                { nom: "tyfcod", largeur: 160},
                { nom: "tyfdsc", largeur: 240},
                { nom: "toccod", largeur: 140},
                { nom: "tocdsc", largeur: 190},
                { nom: "intcod_res", largeur: 160},
                { nom: "intnom_res", largeur: 220},
                { nom: "liecod_eff", largeur: 160},
                { nom: "liedsc_eff", largeur: 220},
                { nom: "liecod_adm", largeur: 170},
                { nom: "liedsc_adm", largeur: 240},
                { nom: "orgcod", largeur: 120},
                { nom: "orgnom", largeur: 190},
                { nom: "orpcod", largeur: 140},
                { nom: "orpdsc", largeur: 190},
                { nom: "prjdsc", largeur: 200},
                { nom: "pdfnumref", largeur: 160},
                { nom: "pdfnumfac", largeur: 160},
                { nom: "crucod", largeur: 100},
                { nom: "crucodsec", largeur: 100},
                { nom: "etape", largeur: 200},
                { nom: "date_etape", largeur: 130},
                { nom: "an_obtention", largeur: 120},
                { nom: "ficmntoct_tot", largeur: 120},
                { nom: "versement", largeur: 130},
                { nom: "ficmntoct_cour", largeur: 130}
            ] }

        vm.orgMultiOccurrenceOptions = {
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                criteresSuggeresVisibles: true,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesVisibles: [
                { nom: "pdfcod", largeur: 140 },
                { nom: "prjcod", largeur: 120 },
                { nom: "orgcod", largeur: 120 },
                { nom: "orgnom", largeur: 190 },
                { nom: "ficmntoct_tot", largeur: 140 },
                { nom: "oadvil", largeur: 190 },
                { nom: "vecsysregadm", largeur: 100 },
                { nom: "vecdsc", largeur: 190 },
                { nom: "unacod_rsp", largeur: 140 },
                { nom: "unadsc_rsp", largeur: 250 },
                { nom: "tyfcod", largeur: 160 },
                { nom: "tyfdsc", largeur: 240 },
                { nom: "toccod", largeur: 140 },
                { nom: "tocdsc", largeur: 190 },
                { nom: "ficmntoct_cour", largeur: 140 }
            ]}

        vm.facMultiOccurrenceOptions = {
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                effaceCriteres: true,
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesVisibles: [
                { nom: "pdfnumfac", largeur: 130 },
                { nom: "orgcod", largeur: 120 },
                { nom: "orgnom", largeur: 190 },
                { nom: "ficmntoct_tot", largeur: 140 },
                { nom: "intcod_rsp", largeur: 140 },
                { nom: "intnom_rsp", largeur: 200 },
                { nom: "pdfcod", largeur: 140 },
                { nom: "prjcod", largeur: 120 },
                { nom: "pdfdatfin", largeur: 100 },
                { nom: "intcod_ress", largeur: 160 },
                { nom: "intnom_ress", largeur: 200 },
                { nom: "ficmntoct_cour", largeur: 140 }
            ]}
 }
