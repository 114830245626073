import { IMenuItemEcranClass } from '../../../../core/services/menu/menu-item-ecran.service';

import { IMultiOccurrence } from '../../../../core/services/multi-occurrence.service';
import { IMenu, IMenuClass, MenuElement } from '../../../../core/services/menu/menu.service';
import { IMenuItem, IMenuItemClass } from '../../../../core/services/menu/menu-item.service';
import { IMenuMessage, IMenuMessageClass } from '../../../../core/services/menu/menu-message.service';
import { IMenuSectionClass } from '../../../../core/services/menu/menu-section.service';
import { IMenuDividerClass } from '../../../../core/services/menu/menu-divider.service';
import { IMenuErreurClass } from '../../../../core/services/menu/menu-erreur.service';
import { IApiError } from '../../../../core/interfaces/api-error.interface';
import {
    IActionPossibleRh,
    ICycleRhClass,
    IProchainEtatRh,
    ITacheRh
} from '../../../../core/resources/cycle-rh.resource';
import { IParametresSecuriteService } from '../../../../core/services/parametres-securite.service';
import { IDialogAllStatus } from '../../../../core/constants/dialog.constant';
import { IPromise, IQService, module } from 'angular';
import { IRoute } from '../../../../core/interfaces/route.interface';
import { IDialogFormulaireOptions } from '../../../../core/dialogs/dialog-formulaire/dialog-formulaire.controller';
import { IMonoOccurrenceClass } from '../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../core/services/formulaire/formulaire-item.service';
import { IDialog } from '../../../../core/services/dialog.service';
import IResourceArray = ng.resource.IResourceArray;
import { IFilterLibelle } from "../../../../core/filters/ex-libelle.filter";

export default module('app.services.menu-actions-rh', []).factory('MenuActionsRh', MenuActionsRhFactory);

export interface IMenuActionsRhService {
    new(multiOccurrence: IMultiOccurrence): IMenuActionsRh;
}

export interface IMenuActionsRh {
    getMenu(): IMenu;
    fetchProchainEtat(): IPromise<IMenuItem | IMenuMessage>;
    fetchAutresActionsPossibles(): IPromise<Array<IMenuItem | IMenuMessage>>;
    fetchTaches(): IPromise<Array<IMenuItem | IMenuMessage>>;
}

/* @ngInject */
function MenuActionsRhFactory($q: IQService,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    MenuSection: IMenuSectionClass,
    MenuDivider: IMenuDividerClass,
    MenuMessage: IMenuMessageClass,
    MenuErreur: IMenuErreurClass,
    CycleRh: ICycleRhClass,
    parametresSecurite: IParametresSecuriteService,
    MenuItemEcran: IMenuItemEcranClass,
    mr1001Route: IRoute,
    DialogStatus: IDialogAllStatus,
    MonoOccurrence: IMonoOccurrenceClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    DialogFormulaire: IDialog,
    exLibelleFilter: IFilterLibelle) {

    class MenuActionsRh implements IMenuActionsRh {
        private readonly multiOccurrence: IMultiOccurrence;
        private readonly actionsMenuElements: Array<MenuElement> = [];

        private selectedRows: Array<any>;
        private firstSelectedRowsTrncleint: number;
        private selectedRowsTrncleint: Array<number>;
        private prochainEtat: IProchainEtatRh = null;
        private actionsPossibles: IResourceArray<IActionPossibleRh> = null;
        private taches: IResourceArray<ITacheRh> = null;

        constructor(multiOccurrence: IMultiOccurrence) {
            this.multiOccurrence = multiOccurrence;
        }

        getMenu() {
            return new Menu('G_LBL_BTN_ACTIONS', this.actionsMenuElements, {
                icon: 'device_hub',
                // Le menu est bâtit dynamiquement lorsque le menu s'ouvre
                onOpen: () => {
                    return this.batirMenuActions();
                }
            });
        }

        private batirMenuActions() {
            this.selectedRows = this.multiOccurrence.getSelectedRows();
            this.selectedRowsTrncleint = this.selectedRows.map(row => row.trncleint);
            this.firstSelectedRowsTrncleint = this.selectedRowsTrncleint[0];

            return this.validerSelection()
                .then(() => this.obtenirMenuData())
                .then((result: { prochainEtat: MenuElement, actionsPossibles: Array<MenuElement>, taches: Array<MenuElement> }) => {
                    // On met à jour les actions en place
                    const menuItems = this.getMenuElements(result.prochainEtat, result.actionsPossibles, result.taches);
                    // On met à jour le menu en place
                    this.actionsMenuElements.splice(0, this.actionsMenuElements.length, ...menuItems);
                });
        }

        private validerSelection() {
            if (!this.selectedRowsTrncleint.length) {
                return $q.reject({
                    lblTitre: 'G_LBL_CYCLE_TRANS_TITRE',
                    lblMessage: exLibelleFilter(this.multiOccurrence.messageErreurSelection, this.multiOccurrence.libelles)
                })
            }

            const params: any = {
                ...this.multiOccurrence.getResourceParamsValues(),
                ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                selections: this.selectedRowsTrncleint.join(','),
                srccod: this.multiOccurrence.getNomSourceDetails(),
                ...parametresSecurite(this.multiOccurrence.stateParams)
            };

            const ids = this.multiOccurrence.getParentIds();
            if (ids.length) {
                //On enlève volontairement le dernier id qui représenterait la ligne sélectionné
                ids.pop();
                params.ids = ids.join(',')
            }

            return CycleRh.validateEtats(params).$promise
                .catch((error: IApiError) => {
                    return $q.reject({
                        error,
                        lblTitre: 'G_LBL_CYCLE_TRANS_TITRE',
                        lblMessage: 'G_MSG_ERREUR_VALIDATION_SELECTION'
                    });
                })
                .then((result: { valid: boolean }) => {
                    if (!result.valid) {
                        return $q.reject({
                            lblTitre: 'G_LBL_CYCLE_TRANS_TITRE',
                            lblMessage: 'G_MSG_ERREUR_SELECTION_ETAT',
                            // Dans le cas de cette erreur, il ne sert à rien de réessayer puisqu'il y a des erreurs sur la
                            // transaction.
                            confirmAction: null
                        })
                    }
                });
        }

        /**
         * Obtient les données du menu du serveur afin de bâtir le menu
         */
        private obtenirMenuData() {
            return $q.all({
                prochainEtat: this.fetchProchainEtat(),
                actionsPossibles: this.fetchAutresActionsPossibles(),
                taches: this.fetchTaches()
            })
        }

        /**
         * Retourne les éléments du menu
         */
        private getMenuElements(prochainEtat: MenuElement, actionsPossibles: Array<MenuElement>, taches: Array<MenuElement>) {
            return [
                new MenuSection('G_LBL_PROCHAIN_ETAT'),
                prochainEtat,
                new MenuDivider(),
                new MenuSection('G_LBL_AUTRES_ACTIONS_POSS'),
                ...actionsPossibles,
                new MenuDivider(),
                new MenuSection('G_LBL_TACHES'),
                ...taches
            ];
        }

        /**
         * Obtient le prochain état du serveur et retourne le menu item
         */
        fetchProchainEtat() {
            const params = this.getCycleParams();

            return CycleRh.getProchainEtat(params).$promise
                .then((prochainEtat: IProchainEtatRh) => {
                    this.prochainEtat = prochainEtat;

                    return this.getProchainEtatMenuItem();
                })
                .catch(() => new MenuErreur('G_MSG_ERREUR_CHARG_PROCHAIN_ETAT'));
        }

        /**
         * Retourne l'élément du menu pour le prochain état
         */
        private getProchainEtatMenuItem() {
            if (this.prochainEtat && this.prochainEtat.cleintpro && this.prochainEtat.acces !== 0 && this.prochainEtat.approbation !== 0) {
                return new MenuItem(this.prochainEtat.dscact, (event: MouseEvent, hideMenu: () => void) => {
                    hideMenu();
                    return this.executeProchainEtat();
                }, { emphase: true });
            } else {
                return new MenuMessage('G_MSG_AUCUN_PROCHAIN_ETAT');
            }
        }

        private showModalConfirmation(eclcleint: number) {
            const menuItem = new MenuItemEcran('LBL_TITRE', mr1001Route.NAME, () => ({
                id: this.selectedRowsTrncleint[0],
                trncleint: this.selectedRowsTrncleint[0],
                eclcleint,
                flgselmul: this.selectedRowsTrncleint.length > 1 ? 1 : 0,
                numcol: this.selectedRowsTrncleint,
            }), {
                appliquer: true,
                showActionsInDialog: true,
                optionsDialog: {
                    hauteur: 54,
                    largeur: 50
                }
            });

            return menuItem.action(null, {});
        }

        private executeProchainEtat() {
            const eclcleint = this.prochainEtat.cleintpro;

            return this.showModalConfirmation(eclcleint)
                .then((status: number) => {
                    if (status === DialogStatus.APPLIQUER) {
                        this.multiOccurrence.fetchDataList();
                    }
                });
        }

        fetchAutresActionsPossibles() {
            const params = this.getCycleParams();

            return CycleRh.queryActionsPossibles(params).$promise
                .then((actionsPossibles: IResourceArray<IActionPossibleRh>) => {
                    this.actionsPossibles = actionsPossibles;

                    return this.getAutresActionsPossiblesMenuItems();
                })
                .catch(() => [new MenuErreur('G_MSG_ERREUR_CHARG_ACTIONS_POSSIBLES')]);
        }

        /**
         * Retourne les éléments du menu pour les actions possibles
         */
        private getAutresActionsPossiblesMenuItems() {
            if (this.actionsPossibles.length) {
                return this.actionsPossibles.map((actionPossible: IActionPossibleRh) => {
                    return new MenuItem(actionPossible.ecldscact, (event: MouseEvent, hideMenu: () => void) => {
                        hideMenu();
                        return this.executeAutreActionPossible(actionPossible)
                    });
                });
            } else {
                return [new MenuMessage('G_MSG_AUCUNE_ACTION_POSSIBLE')];
            }
        }

        private executeAutreActionPossible(actionPossible: IActionPossibleRh) {
            const eclcleint = actionPossible.eclcleintcib;

            return this.showModalConfirmation(eclcleint)
                .then((status: number) => {
                    if (status === DialogStatus.APPLIQUER) {
                        this.multiOccurrence.fetchDataList();
                    }
                });
        }

        fetchTaches() {
            const params = this.getCycleParams();

            return CycleRh.queryTaches(params).$promise
                .then((taches: IResourceArray<ITacheRh>) => {
                    this.taches = taches;

                    return this.getTachesMenuItems();
                })
                .catch(() => [new MenuErreur('G_MSG_ERREUR_CHARG_TACHES')]);
        }

        /**
         * Retourne les paramètres de base pour une requête du cycle
         * @returns {any}
         */
        private getCycleParams(): any {
            const params: any = {
                ...this.multiOccurrence.getResourceParamsValues(),
                ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                trncleint: this.firstSelectedRowsTrncleint,
                srccod: this.multiOccurrence.getNomSourceDetails(),
                ...parametresSecurite(this.multiOccurrence.stateParams),
            };

            const ids = this.multiOccurrence.getParentIds();
            //On enlève volontairement le dernier id qui représenterait la ligne sélectionné
            ids.pop();
            if (ids.length) {
                params.ids = ids.join(',')
            }

            return params;
        }

        private getExecutionParams(): any {
            const params: any = {
                ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                srccod: this.multiOccurrence.getNomSourceDetails(),
                ...parametresSecurite(this.multiOccurrence.stateParams)
            };

            const ids = this.multiOccurrence.getParentIds();
            //On enlève volontairement le dernier id qui représenterait la ligne sélectionné
            ids.pop();
            if (ids.length) {
                params.ids = ids.join(',')
            }

            return params;
        }

        /**
         * Retourne les éléments du menu pour les tâches
         */
        private getTachesMenuItems() {
            if (this.taches.length) {
                return this.taches.map((tache: ITacheRh) => {
                    return new MenuItem(tache.ctadsc, (event: MouseEvent, hideMenu: () => void) => {
                        hideMenu();
                        return this.executeTache(tache);
                    }, { tooltip: tache.ctacom });
                });
            } else {
                return [new MenuMessage('G_MSG_AUCUNE_TACHE')];
            }
        }

        private executeTache(tache: ITacheRh) {
            const params: any = this.getExecutionParams();

            return this.showModalConfirmationCycleTaches((data: any) => {
                const action = {
                    trncleint: this.selectedRowsTrncleint,
                    ctacleint: tache.ctacleint,
                    com: data.com,
                    ...parametresSecurite(this.multiOccurrence.stateParams)
                };

                return CycleRh.executeTache(params, action).$promise
                    .then(() => {
                        // On ne retourne pas afin d'éviter de mettre l'action en erreur
                        this.multiOccurrence.fetchDataList();
                    })
            })
                .catch((error: any) => {
                    if (error !== DialogStatus.FERMER) {
                        return $q.reject({
                            error,
                            lblTitre: 'G_LBL_CYCLE_TRANS_TITRE',
                            lblMessage: 'G_MSG_ERREUR_TACHE'
                        });
                    }
                });
        }

        private showModalConfirmationCycleTaches(confirmAction: (data?: any) => IPromise<any>) {
            const dialogFormulaireOptions: IDialogFormulaireOptions = {
                lblTitre: 'LBL_TITRE',
                icon: 'device_hub',
                data: {},
                confirmAction,
                occurrence: new MonoOccurrence({
                    bloc: 'NOOP',
                    stateParams: this.multiOccurrence.stateParams,
                    ecranDetails: this.multiOccurrence.ecranDetails,
                    ecranSourceDetails: this.multiOccurrence.ecranSourceDetails,
                    srccod: 'mr1001'
                }),
                showConfirmAction: true,
                largeur: 40,
                formulaire: new Formulaire([
                    new FormulaireItem('com', { largeur: 100 })
                ], {
                    largeurDefaut: 33
                })
            };
            const dialogOptions = {
                locals: dialogFormulaireOptions,
                multiple: true
            };
            return DialogFormulaire.show(dialogOptions)
        }
    }

    return MenuActionsRh;
}
