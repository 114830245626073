import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCp2012 extends IComponentController {
    formulaireCoa: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    covMultiOccurrenceOptions: IMultiOccurrenceOptions;
    conMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cp2012Controller($stateParams: any,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    cp2013Route: IRoute) {
    const vm: IPageCp2012 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const docCon = new MenuItemForage(
        'LBL_BTN_CON',
        cp2013Route.NAME,
        (data: any) => ({
            id: data.concleint
        }),
        {
            fonction: 'BTN_CON',
            icon: 'exit_to_app'
        }
    );

    vm.monoOccurrenceOptions = {
        fonctions: {
            selectionCycle: true
        },
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        },
        infosMonetaires: {
            blocs: ['cov'],
            afficherAuBas: "oui",
            largeurTexte: 150,
            donnees:
                [
                    { nom: 'mnt', libelle: 'LBL_MONTANT' },
                    { nom: 'mntcon', libelle: 'LBL_MNTCON' },
                    { nom: 'depnet', libelle: 'LBL_DEPNET' }
                ]
        }
    };

    // Contrat annuel
    vm.formulaireCoa = new Formulaire('LBL_BLC_COA', [
        new FormulaireItem('refcleint', { readonly: true, default: vm.valeursEcran.refcleint }),
        new FormulaireItem('tydcleint', { readonly: true, default: vm.valeursEcran.tydcleint }),
        new FormulaireItem('connumref', { readonly: true, default: vm.valeursEcran.connumref }),
        new FormulaireItem('condatdeb', { readonly: true, default: vm.valeursEcran.condatdeb }),
        new FormulaireItem('condatfin', { readonly: true, default: vm.valeursEcran.condatfin }),
        new FormulaireItemDivider('LBL_CON_ANNUEL'),
        new FormulaireItem('connumdoc', { readonly: true, default: vm.valeursEcran.connumdoc, menu: [docCon] }),
        new FormulaireItem('connumdocseq', { readonly: true }),
        new FormulaireItem('coaann', { readonly: true }),
        new FormulaireItem('coaseqave', { readonly: true }),
        new FormulaireItem('intcleintreq'),
        new FormulaireItem('sysboncmd'),
        new FormulaireItem('coadateff'),
        new FormulaireItem('peccleint'),
        new FormulaireItem('coaflgini')
    ], { largeurDefaut: 33.33 }
    );

    vm.covMultiOccurrenceOptions = {
        bloc: 'cov',
        colonnesFixesGauche: [
            { nom: 'covimp', largeur: 260 },
            { nom: 'covmnt', largeur: 126 }
        ],
        colonnesVisibles: [
            { nom: 'covqte', largeur: 100 },
            { nom: 'taxcod', largeur: 80 },
            { nom: 'remcod', largeur: 80 },
            { nom: 'covmnttaxfed', largeur: 140 },
            { nom: 'covmnttaxpro', largeur: 140 },
            { nom: 'covmntremfed', largeur: 140 },
            { nom: 'covmntrempro', largeur: 140 },
            { nom: 'covseqave', largeur: 80},
            { nom: 'covdsc', largeur: 180 },
            { nom: 'covmntdepnet', largeur: 126 },
            { nom: 'covsldeng', largeur: 126 }
        ],
        colonnesFixesDroite: [
            { nom: 'covflgref', largeur: 120 },
            { nom: 'covflgmsg', largeur: 120 }
        ]
    };

    vm.conMultiOccurrenceOptions = {
        bloc: 'con',
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 100 },
            { nom: 'tyddsc', largeur: 250 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'drimntutl', largeur: 126 },
            { nom: 'docaffmnt01', largeur: 126 },
            { nom: 'peccod', largeur: 120 },
            { nom: 'docaffdat01', largeur: 120 },
            { nom: 'docrefnom02', largeur: 120 },
            { nom: 'docaffnumref01', largeur: 150 },
            { nom: 'drinumrefext', largeur: 150 }
        ]
    }
}
