import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IMenuItemActionImportationClass } from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageCp0061 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    menuImage: Array<IMenuItem>;
}
/* @ngInject */
export default function Cp0061Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageCp0061 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'aapcod', largeur: 100 },
            { nom: 'aapdsc', largeur: 300 },
            { nom: 'aapflgassweb', largeur: 152 },
            { nom: 'aapflgact', largeur: 158 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('aapcod', { required: true }),
            new FormulaireItem('aapdsc', { required: true }),
            new FormulaireItem('aapflgassweb', { required: true }),
            new FormulaireTemplateSlot('photo', { largeur: 25, hidden: (data) => !data.aapcleint }),
            new FormulaireItem('aapflgact', { required: true, default: 1 })
        ], { largeurDefaut: 33.33, }), //, uploadFichier: data => !data.aapcleint, uploadFichierRequired: false }), Remettre lorsque le bogue d'insertion sera corrigé (Composant)
        fonctions: {
            suiviModification: true,
            rechercheCollapsable: false
        }
    };

    vm.menuImage = [
        new MenuItemActionImportation(
            'LBL_IMPORT_IMAGE',
            'BOUIMPORT',
            () => ({}),
            {
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_IMPORT_IMAGE',
                    lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                    formulaire: new Formulaire([])
                },
                successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                },
                typesFichiers: ['.png', '.gif', '.jpg', '.jpeg'],
                icon: 'add_a_photo',
                directionTooltip: 'bottom',
                disabled: (data: any) => !data.aapcleint
            }
        ),
        new MenuItemAction('BOUIMPORT', (data: any) => {
            data.aapdta = null
            return {
                flgdelimg: 1
            }
        }, {
            icon: "delete",
            successAction: () => {
                vm.multiOccurrence.fetchDataList();
            },
            hidden: (data: any) => !data.aapdta,
            disabled: (data: any) => !data.aapcleint
        })
    ];
}
