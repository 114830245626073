import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageAc2004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac2004Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass
) {
                                            
    const vm: IPageAc2004 = this; 

    vm.multiOccurrenceOptions = {
        fonctions:{
            criteresRechercheCollapsable: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('dhacleint', {
                default: $stateParams.dhacleint,
                disabled: true
            })
        ]),
        colonnesVisibles: [
            {nom: 'dhmlig', largeur: 120},
            {nom: 'dhmdsc', largeur: 350},
            {nom: 'procod', largeur: 120},
            {nom: 'prodsc', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireGroupe('LBL_BLC_ANC',
                new Formulaire([
                    new FormulaireItem('dhmdscanc'),
                    new FormulaireItem('procodanc'),
                    new FormulaireItem('dhmdsclnganc'),
                    new FormulaireItem('dhmprxunianc', {largeur: 50}),
                    new FormulaireItem('dhmqteanc', {largeur: 50}),
                    new FormulaireItem('dhmmntanc', {largeur: 50}),
                    new FormulaireItem('taxcodanc', {largeur: 50}),
                    new FormulaireItem('dhmmnttaxfedanc', {largeur: 50}),
                    new FormulaireItem('dhmmnttaxproanc', {largeur: 50}),
                    new FormulaireItem('caacodanc'),
                    new FormulaireItem('dhmnumcatanc'),
                    new FormulaireItem('forcodanc'),
                    new FormulaireItem('dhmclashanc', {largeur: 50}),
                    new FormulaireItem('dhmpayorianc', {largeur: 50}),
                    new FormulaireItem('lolnumlocanc', {largeur: 50}),
                    new FormulaireItem('dhmflggebanc', {largeur: 50})
                ], {largeurDefaut: 100}), {largeur: 50},
            ),
            new FormulaireGroupe('LBL_BLC_NOU',
                new Formulaire([
                    new FormulaireItem('dhmdscnou'),
                    new FormulaireItem('procodnou'),
                    new FormulaireItem('dhmdsclngnou'),
                    new FormulaireItem('dhmprxuninou', {largeur: 50}),
                    new FormulaireItem('dhmqtenou', {largeur: 50}),
                    new FormulaireItem('dhmmntnou', {largeur: 50}),
                    new FormulaireItem('taxcodnou', {largeur: 50}),
                    new FormulaireItem('dhmmnttaxfednou', {largeur: 50}),
                    new FormulaireItem('dhmmnttaxpronou', {largeur: 50}),
                    new FormulaireItem('caacodnou'),
                    new FormulaireItem('dhmnumcatnou'),
                    new FormulaireItem('forcodnou'),
                    new FormulaireItem('dhmclashnou', {largeur: 50}),
                    new FormulaireItem('dhmpayorinou', {largeur: 50}),
                    new FormulaireItem('lolnumlocnou', {largeur: 50}),
                    new FormulaireItem('dhmflggebnou', {largeur: 50})
               ], {largeurDefaut: 100}), {largeur: 50}
            )
        ])
    };
}