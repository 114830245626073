import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageRR0130 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0130Controller(rr0031Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageRR0130 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0031Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.trncleintref
            };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('placleint', {
                required: false
            }),
            new FormulaireItem('urgcleint', {
                required: false
            }),
            new FormulaireItem('strcleint', {
                required: false
            }),
            new FormulaireItem('flgsdatadm', {
                default: 1,
                required: true
            }),
            new FormulaireItem('flgcompl', {
                default: 0,
                required: true
            }),
            new FormulaireItemDivider('LBL_DATE_ADM'),
            new FormulaireItem('datdeb', {
                required: false
            }),
            new FormulaireItem('datfin', {
                required: false
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'urgcod', largeur:90},
            {nom: 'urgdscabr', largeur: 236},
            {nom: 'strcod', largeur:85},
            {nom: 'strdsc', largeur: 236},
            {nom: 'dscava', largeur: 236},
            {nom: 'numrefava', largeur: 105},
            {nom: 'datadmava', largeur: 160},
            {nom: 'datdebava', largeur: 100},
            {nom: 'datfinava', largeur: 100}
        ],
        colonnesCachees:[
            'trncleintref'
        ]
    };
}
