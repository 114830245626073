import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IParametresSecuriteService } from '../../../../../core/services/parametres-securite.service';
import { IUploadManagerService } from '../../../../../core/services/upload-manager.service';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";

interface IPageTF2000 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function Tf2000Controller(Formulaire: IFormulaireClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    MenuItem: IMenuItemClass,
    uploadManager: IUploadManagerService,
    parametresSecurite: IParametresSecuriteService,
    $stateParams: any,
    notificationHandler: INotificationHandler) {
    const vm: IPageTF2000 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireGroupeAccordeon("2023", new Formulaire([
                new FormulaireItemMenuItem(new MenuItem('T2200 (fédéral 2023)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2023/t2200`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError);
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 't2200_2023',
                            inputPattern: '.csv'
                        }
                    })),
                new FormulaireItemMenuItem(new MenuItem('TP64.3 (provincial 2023)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2023/tp64.3`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError)
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 'tp64_3_2023',
                            inputPattern: '.csv'
                        }
                    }))
            ])),
            new FormulaireGroupeAccordeon("2022", new Formulaire([
                new FormulaireItemMenuItem(new MenuItem('T2200 (fédéral 2022)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2022/t2200`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError);
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 't2200_2022',
                            inputPattern: '.csv'
                        }
                    })),
                new FormulaireItemMenuItem(new MenuItem('T2200S (fédéral 2022)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2022/t2200s`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError);
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 't2200s_2022',
                            inputPattern: '.csv'
                        }
                    })),
                new FormulaireItemMenuItem(new MenuItem('TP64.3 (provincial 2022)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2022/tp64.3`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError)
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 'tp64_3_2022',
                            inputPattern: '.csv'
                        }
                    }))
            ]), { opened: false }),
            new FormulaireGroupeAccordeon("2021", new Formulaire([
                new FormulaireItemMenuItem(new MenuItem('T2200 (fédéral 2021)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2021/t2200`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError);
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 't2200_2021',
                            inputPattern: '.csv'
                        }
                    })),
                new FormulaireItemMenuItem(new MenuItem('T2200S (fédéral 2021)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2021/t2200s`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError);
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 't2200s_2021',
                            inputPattern: '.csv'
                        }
                    })),
                new FormulaireItemMenuItem(new MenuItem('TP64.3 (provincial 2021)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2021/tp64.3`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError)
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 'tp64_3_2021',
                            inputPattern: '.csv'
                        }
                    }))
            ]), { opened: false }),
            new FormulaireGroupeAccordeon("2020", new Formulaire([
                new FormulaireItemMenuItem(new MenuItem('T2200S (fédéral 2020)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2020/t2200`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError);
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 't2200s_2020',
                            inputPattern: '.csv'
                        }
                    })),
                new FormulaireItemMenuItem(new MenuItem('TP64.3 (provincial 2020)',
                    (event: Event, data: any) => {
                        uploadManager.upload(`/tf2000/2020/tp64.3`, data, { ...parametresSecurite($stateParams) }, $stateParams).then(showSuccess).catch(showError)
                    },
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        importation: {
                            nom: 'tp64_3_2020',
                            inputPattern: '.csv'
                        }
                    }))
            ]), { opened: false })
        ])
    }

    function showError() {
        notificationHandler.erreur({
            lblMessage: 'G_MSG_ERREUR_ACTION',
            lblTitre: 'G_LBL_ERREUR',
        });
    }

    function showSuccess(result: { surcle: number }) {
        notificationHandler.succes(`APP-00304: La requête (${result.surcle}) a été soumise avec succès.`)
    }
}
