import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mc0047',
    ecrans: [
        '00-2',
        '01-2',
        '02-2',
        '03-2',
        '04-2',
        '05-2',
        '06-2',
        '07-2'
    ]
});
