import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";

interface IPageRr0552 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireDxc: IFormulaire;
    dxtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dxaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dxrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dxbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0552Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0552 = this;

    vm.formulaireDxc = new Formulaire('LBL_BLC_DXC', [
        new FormulaireItem('demcleint'),
        new FormulaireItem('unpcleint'),
        new FormulaireItem('ppaannper'),
        new FormulaireItem('ppacleint'),
        new FormulaireItem('dxcflgall')
    ], { largeurDefaut: 25 });

    vm.dxtMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 150 },
            { nom: 'tagdsc', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tagcleint')
        ], { largeurDefaut: 35 })
    };

    vm.dxaMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 170 },
            { nom: 'tagdsc', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tagcleint')
        ], { largeurDefaut: 35 })
    };

    vm.dxrMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'rcecod', largeur: 150 },
            { nom: 'rcedsc', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rcecleint')
        ], { largeurDefaut: 35 })
    };

    vm.dxbMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'bqecod', largeur: 150 },
            { nom: 'bqedsc', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bqecleint')
        ], { largeurDefaut: 35 })
    };
}
