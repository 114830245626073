import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPagePi0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0024Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPagePi0024 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'ptncod', largeur: 80}
        ],
        colonnesVisibles: [
            {nom: 'ptndsc', largeur: 236},
            {nom: 'ptndscabr', largeur: 210},
            {nom: 'vaeptntyp_dsc', largeur: 120},
            {nom: 'vecptncat', largeur: 125},
            {nom: 'vecptncat_dsc', largeur: 215},
            {nom: 'ptnflgact', largeur: 64},
            {nom: 'ptndatdeb', largeur: 100},
            {nom: 'ptndatfin', largeur: 100},
            {nom: 'ptnadrl01', largeur: 210},
            {nom: 'ptnadrl02', largeur: 210},
            {nom: 'ptnvil', largeur: 158},
            {nom: 'ptnpro', largeur: 158},
            {nom: 'ptnpay', largeur: 158},
            {nom: 'ptncodpos', largeur: 106},
            {nom: 'ptnnumtel', largeur: 132},
            {nom: 'ptnnumtelpos', largeur: 70},
            {nom: 'ptnnumfax', largeur: 132},
            {nom: 'ptncon', largeur: 184},
            {nom: 'ptnadrcou', largeur: 236}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ptncod', {largeur: 20}),
            new FormulaireItem('ptndsc', {required: true, largeur: 60}),
            new FormulaireItem('ptndscabr', {required: true, largeur: 20}),
            new FormulaireItem('vaeptntyp', {largeur: 20}),
            new FormulaireItem('vecptncat', {largeur: 20}),
            new FormulaireItem('ptndatdeb', {largeur: 20}),
            new FormulaireItem('ptndatfin', {largeur: 20}),
            new FormulaireItem('ptnflgact', {default: 1, largeur: 20}),
            new FormulaireItem('ptnadrl01', {largeur: 50}),
            new FormulaireItem('ptnadrl02', {largeur: 50}),
            new FormulaireItem('ptnvil'),
            new FormulaireItem('ptnpro'),
            new FormulaireItem('ptnpay'),
            new FormulaireItem('ptncodpos'),
            new FormulaireItem('ptnnumtel'),
            new FormulaireItem('ptnnumtelpos'),
            new FormulaireItem('ptnnumfax'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ptncon'),
            new FormulaireItem('ptnadrcou')
        ], {largeurDefaut: 25}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
