import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGs1004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence
}
/* @ngInject */
export default function Gs1004Controller(Formulaire: IFormulaireClass ,   
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs1004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false
        },
        cleint: "rn",
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 100 },
            { nom: 'usrnom', largeur: 190 },
            { nom: 'debprv', largeur: 100 },
            { nom: 'deblig', largeur: 100 },
            { nom: 'debmsg', largeur: 600 },
            { nom: 'datcre', largeur: 100 }
        ]
    };
}
