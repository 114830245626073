import {IComponentController} from "angular";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePb0009 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */
export default function Pb0009Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         so0007Route: IRoute,
                                         pb0010Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPagePb0009 = this;

        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_TITRE', [
                new FormulaireItem('pveann'),
                new FormulaireItem('pvedatdeb',{default: ecranDetails.valeurs.pvedatdeb}),
                new FormulaireItem('pvedatfin',{default: ecranDetails.valeurs.pvedatfin}),
                new FormulaireItem('intcod'),
                new FormulaireItem('intnom')
            ], {largeurDefaut: 33})
        };

        vm.ptcMultiOccurrenceOptions = {
            fonctions: {
                rechercheCollapsable: false
            },
            navigatePage: pb0010Route.NAME,
            navigateParams: (rowData: any) => ({
                id: rowData.refptccleint 
            }),
            colonnesCachees: ['refptccleint'],
            colonnesVisibles: [
                {nom: 'prucod', largeur: 110},
                {nom: 'prudscl01', largeur: 350},
                {nom: 'posnumdoc', largeur: 120,
                    menu: [
                        new MenuItemForage(
                            '',
                            so0007Route.NAME,
                            (rowData: any) => ({
                                id: rowData.trncleint
                            }),
                            {
                                icon: 'launch',
                                iconButton: true
                            }
                        )
                    ]
                },
                {nom: 'ptccousyspre', largeur: 120, total: true},
                {nom: 'ptccousysrel', largeur: 120, total: true},
                {nom: 'ptccousyseng', largeur: 120, total: true},
                {nom: 'ecartotal', largeur: 120, total: true}            
            ]
        };

}
