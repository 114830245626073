import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRr0150 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence:IMultiOccurrence;
    valeursEcran: any;
    }

/* @ngInject */
export default function rr0150Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         rr0035Route: IRoute,
                                         rr0036Route: IRoute,
                                         Operateur: IOperateurService,
                                         MenuItemAction: IMenuItemActionClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass
                                         ) {
    const vm: IPageRr0150 = this;

    const forageRef = new MenuItemForage(
        'LBL_FOR_REF',
        (data: any) => getForage(data),
        (data: any) => getParams(data),
        {
            hidden: (data:any) => !data.dgcflggen
        }
    );

    vm.multiOccurrenceOptions ={
        actions: new Menu([
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            ),

            new MenuItemAction(
                'LBL_BTN_GEN',
                'BOUGEN',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            ),
        ]),
        fonctions: {
            importation: true
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262}
        ],
        colonnesFixesDroite: [
            {nom: 'dgcflggen', largeur: 100,
                menu: [forageRef]
            },
            {nom: 'dgcflgerr', largeur: 80}
        ],
        colonnesVisibles: [
            {nom: 'fpncod', largeur: 90},
            {nom: 'fpndsc', largeur: 262},
            {nom: 'fpocod', largeur: 100},
            {nom: 'fpodsc', largeur: 262},
            {nom: 'fsscod', largeur: 110},
            {nom: 'fssdsc', largeur: 262},
            {nom: 'dgcval', largeur: 140},
            {nom: 'dgcdatdeb', largeur: 120},
            {nom: 'dgcflgadhmul', largeur: 100},
            {nom: 'dgcdatdebgen', largeur: 160},
            {nom: 'dgcdatfingen', largeur: 160}
        ],
        colonnesCachees:[
            'dgmmsg',
            'dafcleint_trn',
            'demcleint'
        ],
        filtres: [
            {
                colonne: 'dgcflggen',
                operateur: Operateur.EGALE_PAS,
                valeur: '1',

            },
            {
                colonne: 'dgcflgerr',
                operateur: Operateur.EGALE_PAS,
                valeur: ''
            }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('fpncleint'),
            new FormulaireItem('fpocleint'),
            new FormulaireItem('fsscleint'),
            new FormulaireItem('dgcval',{largeur:25}),
            new FormulaireItem('dgcdatdeb',{largeur:25}),
            new FormulaireItem('dgcdatdebgen',{largeur:25}),
            new FormulaireItem('dgcdatfingen',{largeur:25})
        ])
    };

    function getForage(data: any): string {
        if (data.dgcflgadhmul) {
            return rr0035Route.NAME;
        } else {
            return rr0036Route.NAME;
        }
    }

    function getParams(data: any) {
        if (data.dgcflgadhmul) {
            return { demcleint: data.demcleint };
        } else {
            return { id: data.dafcleint_trn };
        }
    }

}
