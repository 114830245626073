import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
//import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr2023 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrenceOptionsMpv: IMultiOccurrenceOptions;
    multiOccurrenceOptionsSub: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr2023Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMr2023 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_MPD', [
            new FormulaireItem('mpdcod'),
            new FormulaireItem('mpddsc'),
            new FormulaireItem('mpddoc'),
            new FormulaireItem('mpdflgdatexc'),

        ], { largeurDefaut: 50 })
    }


    /* Plaquette parametre */
    vm.multiOccurrenceOptionsMpv = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'mpvnum', largeur: 90 },
            { nom: 'mpvlib', largeur: 150 },
            { nom: 'vaempvtyp_dsc', largeur: 150 },
            { nom: 'mpvflgmul', largeur: 100 }
        ],
        formulaire: new Formulaire('LBL_BLC_MPV', [
            new FormulaireItem('mpvnum'),
            new FormulaireItem('mpvlib'),
            new FormulaireItem('vaempvtyp'),
            new FormulaireItem('mpvflgmul')
        ], { largeurDefaut: 50 })
    };

    /* Plaquette substitution */
    vm.multiOccurrenceOptionsSub = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'subcod', largeur: 100 },
            { nom: 'subdsc', largeur: 200 }
        ],
        formulaire: new Formulaire('LBL_BLC_SUB', [
            new FormulaireItem('subcod'),
            new FormulaireItem('subdsc')
        ], { largeurDefaut: 50 })
    };
}
