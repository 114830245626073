import { IComponentController, IScope, IFormController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IInfosMonetaires } from '../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller';
import { IData } from '../../../../../core/services/data-linker.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IMenuItemActionClass, IMenuItemAction } from '../../../../../core/services/menu/menu-item-action.service';
import { IApiError } from '../../../../../core/interfaces/api-error.interface';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";

interface IPageCp0066 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrenceRifOptions: IMonoOccurrenceOptions;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    rlaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    avdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    zrdMultiOccurrenceOptions: IMultiOccurrenceOptions
    somMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menuItemEmploye: IMenuItem;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceRif: IMonoOccurrence;
    multiOccurrenceRla: IMultiOccurrence;
    multiOccurrenceAvd: IMultiOccurrence;
    menuItemGenererVentilation: IMenuItemAction;
    menuItemGenererVentilationMultiple: IMenuItemAction;
    rivMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rivMultiOccurrenceOpened: boolean;
    multiOccurrenceVentilation: IMultiOccurrence;
    formCtrlRld2: IFormController;
    formDataRld2: any;
    saving: boolean;

    isDisabled(formCtrl: IFormController): boolean;
    hasAccesSave(monoOccurrence: IMonoOccurrence): boolean;
    save($event: MouseEvent | KeyboardEvent, formData: any, monoOccurrence: IMonoOccurrence, formCtrl: IFormController): void;
    getTotalAvancesSelectionnees(): number;
}

/* @ngInject */
export default function Cp0066Controller(cp2000Route: IRoute, cp2001Route: IRoute, cp0066Route: IRoute,
    mc0046Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    $scope: IScope, $state: IStateService,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    notificationHandler: INotificationHandler,
    Menu: IMenuClass) {
    const vm: IPageCp0066 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.rivMultiOccurrenceOpened = Boolean(vm.valeursEcran.flgriv);

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true,
            boutonDupliquer: false
        },
        menus: [
            new MenuItemForage('G_LBL_PIECES_JOINTES', cp2001Route.NAME, (data) => ({ rldcleint: data.rldcleint }), {
                iconButton: false,
                icon: '',
                class: 'md-raised ex-button-secondary',
                hidden: (data: any) => !data || !data.rldcleint
            }),
            new MenuItemForage('LBL_BTN_IMPRIMER', cp2000Route.NAME, () => ({ id: $stateParams.id }), {
                iconButton: false,
                icon: '',
                class: 'md-raised ex-button-secondary',
                hidden: (data: any) => !data || !data.rldcleint
            })
        ],
        actionsMore: new Menu([new MenuItemAction("LBL_BTN_COPIE", "BOUCOP", () => ({}), { iconButton: false, icon: "content_copy", successAction: ({ trncleint }) => $state.go(cp0066Route.NAME, { id: trncleint }), hidden: (data: any) => !data.rldcleint })]),
        formulaire: new Formulaire('LBL_INFOS_GENERALES', [
            new FormulaireItem('rldnumdoc', { required: false, hidden: (data) => !data.rldnumdoc }),
            new FormulaireItem('zcrcleint', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rlddatdep', {
                onValueChange: (data) => {
                    if (data.rlddatdep && !data.rlddatret) {
                        data.rlddatret = data.rlddatdep;
                    }
                }
            }),
            new FormulaireItem('rlddatret'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rldjusdep', { largeur: 100 }),
            new FormulaireItem('refcleint', { hidden: true, default: vm.valeursEcran.refcleint })
        ], {
            piecesJointes: true,
            largeurDefaut: 25
        }),
        successActionSauvegarde: () => {
            if (vm.monoOccurrenceRif) {
                vm.monoOccurrenceRif.fetchData();
            }
        }
    }

    vm.$onInit = function $onInit() {
        const monoOccurrenceListener = $scope.$watch('vm.monoOccurrence.data.$resolved', (value) => {
            if (!value) return;

            monoOccurrenceListener();

            vm.monoOccurrenceRifOptions = {
                id: vm.monoOccurrence.id,
                monoOccurrenceParentId: vm.monoOccurrence.data.rldcleint,
                champsTransactionnels: vm.monoOccurrence.champsTransactionnels,
                bloc: 'rif',
                srccodref: 'cp0066-rif',
                formulaire: new Formulaire('LBL_BLC_IMPUTATION', [
                    new FormulaireItem('rifcleint', { hidden: true }),
                    new FormulaireItem('vaerifubr', {
                        titre: ' ', direction: 'vertical', default: 0, largeur: 100, onValueChange: () => {
                            vm.monoOccurrence.emit('refreshSommaire', vm.monoOccurrenceRif.libelles);
                        }
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireGroupeAccordeon('LBL_UBR_UNIQUE', new Formulaire([
                        new FormulaireItem('imp', { largeur: 50 })
                    ]), { hidden: (data: any) => !(data.vaerifubr === 'UNI'), opened: true }),
                    new FormulaireGroupeAccordeon('LBL_UBR_MULTIPLE', new Formulaire([
                        new FormulaireItem('imp1', { largeur: 40 }),
                        new FormulaireItem('riuprc1', { titre: 'LBL_POURCENTAGE' }),
                        new FormulaireItem('riumnt1', { titre: 'LBL_MONTANT' }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('imp2', { largeur: 40, hidden: (data) => !vm.monoOccurrenceRif.isChampModifiable('imp2') && !data.imp2 }),
                        new FormulaireItem('riuprc2', { titre: 'LBL_POURCENTAGE', hidden: (data) => !vm.monoOccurrenceRif.isChampModifiable('imp2', true) && !data.imp2 }),
                        new FormulaireItem('riumnt2', { titre: 'LBL_MONTANT', hidden: (data) => !vm.monoOccurrenceRif.isChampModifiable('imp2') && !data.imp2 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('imp3', { largeur: 40, hidden: (data) => !vm.monoOccurrenceRif.isChampModifiable('imp3') && !data.imp3 }),
                        new FormulaireItem('riuprc3', { titre: 'LBL_POURCENTAGE', hidden: (data) => !vm.monoOccurrenceRif.isChampModifiable('imp3') && !data.imp3 }),
                        new FormulaireItem('riumnt3', { titre: 'LBL_MONTANT', hidden: (data) => !vm.monoOccurrenceRif.isChampModifiable('imp3') && !data.imp3 })
                    ], { largeurDefaut: 30 }), { hidden: (data) => !(data.vaerifubr === 'MUL'), opened: true }),
                    new FormulaireGroupe('LBL_SPECIFICATIONS', new Formulaire([
                        new FormulaireItem('rldinsfin')
                    ]))
                ]),
                successActionSauvegarde: () => {
                    if (vm.monoOccurrenceRif.data.vaerifubr === "UNI") {
                        vm.menuItemGenererVentilation.action(null, vm.monoOccurrenceRif.data, vm.monoOccurrenceRif)
                    } else if (vm.monoOccurrenceRif.data.vaerifubr === "MUL") {
                        vm.menuItemGenererVentilationMultiple.action(null, vm.monoOccurrenceRif.data, vm.monoOccurrenceRif)
                    }
                }
            };
        });

        vm.menuItemGenererVentilation = new MenuItemAction(
            'LBL_GENERER_VENT',
            'BOUGEN',
            () => ({}),
            {
                iconButton: false,
                class: 'md-raised ex-button-action',
                messageConfirmation: {
                    message: 'BOUGEN_CONFIRM',
                    condition: () => {
                        return Boolean(
                            vm.multiOccurrenceVentilation &&
                            vm.multiOccurrenceVentilation.compteur
                        );
                    }
                },
                successAction: () => {
                    vm.rivMultiOccurrenceOpened = true;
                    if (vm.multiOccurrenceVentilation) {
                        vm.multiOccurrenceVentilation.fetchDataList();
                    }
                },
                disabled: (data: IData) => (
                    !data.imp ||
                    (vm.monoOccurrenceRif && !vm.monoOccurrenceRif.hasChampsModifiables(true, vm.monoOccurrenceRif.data))
                )
            });

        vm.menuItemGenererVentilationMultiple = new MenuItemAction(
            'LBL_GENERER_VENT',
            'BOUGENMUL',
            () => ({}), {
            iconButton: false,
            class: 'md-raised ex-button-action',
            messageConfirmation: {
                message: 'BOUGEN_CONFIRM',
                condition: () => {
                    return Boolean(
                        vm.multiOccurrenceVentilation &&
                        vm.multiOccurrenceVentilation.compteur
                    );
                }
            },
            successAction: () => {
                if (!vm.multiOccurrenceVentilation) {
                    vm.rivMultiOccurrenceOpened = true;
                } else {
                    vm.multiOccurrenceVentilation.fetchDataList();
                }
            },
            disabled: (data: IData) => {
                return (
                    ((data.imp1 && Number(data.riuprc1)) || 0) + ((data.imp2 && Number(data.riuprc2)) || 0) + ((data.imp3 && Number(data.riuprc3)) || 0) !== 100
                ) ||
                    (vm.monoOccurrenceRif && !vm.monoOccurrenceRif.hasChampsModifiables(true, vm.monoOccurrenceRif.data));
            }
        });

        vm.rlaMultiOccurrenceOptions = {
            bloc: 'rla',
            optionsNouveau: {
                lblAide: 'LBL_AJOUT_DEPENSE',
                selectionSource: 'aapcleint',
                selectionSourceImgExt: 'aapext'
            },
            actionsMoreLigne: new Menu([
                new MenuItemForage('G_LBL_IMPUTATION',
                    mc0046Route.NAME,
                    (data: any) => ({
                        vaeusient: 'RLA',
                        uvicleintref: data.rlacleint
                    }),
                    {
                        icon: 'view_list',
                        iconButton: false
                    })
                ,
                new MenuItemAction(
                    'LBL_COPIER',
                    'BOUCOP',
                    (data: any) => {
                        return {
                            rlacleint: data.rlacleint
                        };
                    },
                    {
                        icon: 'content_copy',
                        iconButton: false,
                        hidden: (data: any) => (data.vaeceptypmod !== 'TOUS'),
                        successAction: () => {
                            vm.multiOccurrenceRla.fetchDataList();
                        }

                    }
                )
            ]),
            fonctions: {
                afficherMenuSettingDansEntete: false,
                recherche: false
            },
            formulaire: new Formulaire([
                new FormulaireGroupe(new Formulaire([
                    new FormulaireItem('aapcleint', { readonly: true }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('rladat', { default: (data: IData) => (data.rladat_def) ? data.rladat_def : vm.monoOccurrence.data.rlddatdep }),
                    new FormulaireItem('cpacleint_mul', {
                        largeur: 100,
                        resourceParams: (data: IData) => ({ aapcleint: data.aapcleint, rladat: data.rladat })
                    })
                ]), { hidden: (data: IData) => !data.aapflgassweb || data.rlacleint }),
                new FormulaireGroupe(new Formulaire([
                    new FormulaireItem('aapcleint', { readonly: true }),
                    new FormulaireItem('rladat', { default: (data: IData) => (data.rladat_def) ? data.rladat_def : vm.monoOccurrence.data.rlddatdep }),
                    new FormulaireItem('cpacleint', { required: true, largeur: 100, resourceParams: (data: any) => ({ aapcleint: data.aapcleint }) }),
                    new FormulaireItem('rladscfou'),
                    new FormulaireItem('rlaqte', { hidden: data => !data.cpaflgsaiqte && !data.cpaprx }),
                    new FormulaireItem('rlaprx', { readonly: data => !data.cpaflgsaiqte || data.cpaprx, hidden: data => !data.cpaflgsaiqte && !data.cpaprx, default: (data: any) => data.cpaprx }),
                    new FormulaireItem('rlamntfor', { hidden: true }),
                    new FormulaireItem('rlamnt', { readonly: data => data.cpaflgsaiqte || data.cpaprx }),
                    new FormulaireItem('vaesysdevcod', { readonly: data => data.cpa_vaesysdevcod }),
                    new FormulaireItem('rlamntrclcan'),
                    new FormulaireItem('rlacom', { enableCount: true, largeur: 100 })
                ]), { hidden: (data: IData) => data.aapflgassweb && !data.rlacleint })
            ], {
                piecesJointes: true
            }),
            colonnesVisibles: [
                'rlanumlig',
                'rladat',
                'aapdsc',
                'cpadsc',
                'rladscfou',
                { nom: 'rlamntrclcan', largeur: 200, total: true },
                'nbrpj',
                'rlacom',
                'rlamnt',
                'vaesysdevcod',
                { nom: 'mntcan', largeur: 70, total: true },
                'mntdifcan',
                'dopnot'
            ],
            colonnesCachees: ['vaeceptypmod'],
            successActionSauvegarde: () => {
                if (vm.somMultiOccurrenceOptions) {
                    vm.monoOccurrenceRif.fetchData();
                }
            }
        };

        vm.monoOccurrenceInfosMonetaire = {
            blocs: ['rla', 'avd'],
            donnees: [
                { nom: 'cpa', libelle: 'LBL_TOTAL_CPA', lastOfGroup: true },
                { nom: 'avd', libelle: 'LBL_TOTAL_AVD', lastOfGroup: true },
                { nom: 'total', libelle: 'LBL_TOTAL_RLD', important: true }
            ],
            noIcons: true,
            lblTitre: 'LBL_MONTANT_TOTAL',
            paddingActions: true
        };

        vm.avdMultiOccurrenceOptions = {
            bloc: 'avd',
            selectionPersistante: 'nb_avd',
            fonctions: {
                filtrer: false,
                recherche: false,
                exportation: false,
                selectionnerTri: false
            },
            colonnesVisibles: [
                'reqnumdoc',
                'reqdsc',
                'reqnumref',
                'reqmnt',
                'reqnomdem',
                'nb_avd',
                'refcod',
                'refnom'
            ]
        };

        vm.zrdMultiOccurrenceOptions = {
            bloc: 'zrd',
            selectionPersistante: 'nb_zrd',
            fonctions: {
                filtrer: false,
                recherche: false,
                exportation: false,
                selectionnerTri: false
            },
            colonnesVisibles: [
                'dpmnumdoc',
                "dpmdatdeb",
                "dpmdatfin",
                "dpmacedsc",
                "tpcdsc",
                'nb_zrd'
            ]
        };

        vm.rivMultiOccurrenceOptions = {
            bloc: 'riv',
            fonctions: {
                filtrer: false,
                exportation: true
            },
            colonnesFixesGauche: [
                { nom: 'rivflgref', largeur: 70 },
                { nom: 't_imp', largeur: 260 },
                { nom: 'rivmnt', largeur: 200 },
                { nom: 'taxcod', largeur: 65 },
                { nom: 'remcod', largeur: 95 }
            ],
            colonnesVisibles: [
                { nom: 'rivmnttaxfed', largeur: 126 },
                { nom: 'rivmnttaxpro', largeur: 146 },
                { nom: 'rivmntremfed', largeur: 126 },
                { nom: 'rivmntrempro', largeur: 176 },
                { nom: 'rivcom', largeur: 260 },
            ],
            colonnesFixesDroite: [
                { nom: 'rivmntnet', largeur: 126 },
                { nom: 'rivflgmsg', largeur: 126 }
            ],
            colonnesCachees: [
                'rifcleint'
            ],
            formulaire: new Formulaire('LBL_VENTILATION', [
                new FormulaireItem('rifcleint', { default: (data: any) => data.rifcleint, hidden: true }),
                new FormulaireItem('t_imp'),
                new FormulaireItem('rivmnt'),
                new FormulaireItem('rivmntnet'),
                new FormulaireItem('taxcleint'),
                new FormulaireItem('rivmnttaxfed'),
                new FormulaireItem('rivmnttaxpro'),
                new FormulaireItem('remcleint'),
                new FormulaireItem('rivmntremfed'),
                new FormulaireItem('rivmntrempro'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rivcom', { largeur: 66.66 }),
                new FormulaireItem('vaeubr', { default: () => vm.monoOccurrenceRif.data.vaerifubr })
            ], { largeurDefaut: 30 })
        }
    }

    vm.isDisabled = function isDisabled(formCtrl: IFormController): boolean {
        return (formCtrl && formCtrl.$invalid) || !vm.monoOccurrence.isSaveable();
    }

    vm.hasAccesSave = function hasAccesSave(monoOccurrence: IMonoOccurrence) {
        const fonction = ecranDetails.fonctions[monoOccurrence.mnemonique];
        return Boolean(
            !fonction || (
                (fonction.flgmod && vm.monoOccurrence.id) ||
                (fonction.flgins && !vm.monoOccurrence.id)
            )
        );
    }

    vm.save = function save($event: MouseEvent | KeyboardEvent, formData: any, monoOccurrence: IMonoOccurrence, formCtrl: IFormController) {
        $event.preventDefault();
        if (vm.saving) return;

        if (!vm.isDisabled(formCtrl)) {
            vm.saving = true;
            monoOccurrence.save(formData).then(() => {
                notificationHandler.succes();
                formCtrl.$setSubmitted();
                formCtrl.$setPristine();
                this.saving = false;
            })
                .catch((err) => {
                    this.saving = false;
                    afficherValidationErreur(err);
                });
        }
    }

    function afficherValidationErreur(error: IApiError) {
        const message = (error.data && error.data.code && error.data.code.startsWith('SOFE-')) ? error.data.message : 'G_MSG_SAUV_ENR';
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: message,
            confirmAction: () => {
                return vm.monoOccurrence.save(vm.formData);
            }
        });
    }

    vm.getTotalAvancesSelectionnees = function getTotalAvancesSelectionnees() {
        if (!vm.multiOccurrenceAvd || !vm.multiOccurrenceAvd.dataList || !vm.multiOccurrenceAvd.dataList.length) return 0
        const avancesSelectionnees = vm.multiOccurrenceAvd.dataList.filter((d: any) => d.nb_avd).map((d: any) => d.reqmnt)
        return avancesSelectionnees.length ? avancesSelectionnees.reduce((a: number, b: number) => a + b) : 0
    }
}
