/**
 * Composant représentant les plaquettes de fonctions transversale (Pièces jointes, Notes, Infos complémentaires)
 *
 */
import {IComponentOptions, module} from 'angular';
import PlaquettesFonctionsTransversalesController from './ex-plaquettes-fonctions-transversales.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('core.components.ex-plaquettes-fonctions-transversales', []).component('exPlaquettesFonctionsTransversales', PlaquettesFonctionsTransversalesFactory());

function PlaquettesFonctionsTransversalesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                menuBandeau: '<',
                monoOccurrence: '<'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: PlaquettesFonctionsTransversalesController,
        controllerAs: 'vm',
        template: require('./ex-plaquettes-fonctions-transversales.html')
    };
}
