import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageMr0101 extends IComponentController {
    formulaireTem: IFormulaire;
    formulaireEtu: IFormulaire;
    corMultiOccurrence: IMultiOccurrence;    
    etvMultiOccurrence: IMultiOccurrence;    
    tgrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tchMultiOccurrenceOptions: IMultiOccurrenceOptions;
    corMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ipaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    icnMultiOccurrenceOptions: IMultiOccurrenceOptions;    
    etvMultiOccurrenceOptions: IMultiOccurrenceOptions;    
}

/* @ngInject */
export default function Mr0101Controller(co0009Route: IRoute,
                                         mr0255Route: IRoute,
                                         do0021Route: IRoute,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         mr0238Route: IRoute) {

    const vm: IPageMr0101 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemForage(
                    'LBL_MR0238',
                    mr0238Route.NAME,
                    (data:any) => ({vaetypfnd: 'TEM',
                                    cleintdet: data.temcleint}),
                    {
                        icon: 'assessment',
                        iconButton: false
                    }
                )
            ])
        };

        // Formulaire principal (Titre d'emploi)
        vm.formulaireTem = new Formulaire('LBL_BLC_TEM', [
            new FormulaireItem('temcod'),
            new FormulaireItem('temflgact', {default: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temdsc', { historiqueColonne: true , required: true,
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('temdscabr'),
            new FormulaireItem('temdscfem'),
            new FormulaireItem('temdscmsc'),
            new FormulaireItem('grecleint'),
            new FormulaireItem('temhreheb'),
            new FormulaireItem('temcleintref'),
            new FormulaireItem('temflgprfper'),
            new FormulaireItem('temflgfdsres', {largeur: 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temdscfon', {
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE',
                        do0021Route.NAME,
                        (data: any) => ({ id: data.temcleint })
                    )
                ]
            })
        ]);

        // Traduction
        /*
         *  À compléter
         */

        // Étudiant
        vm.formulaireEtu = new Formulaire('LBL_BLC_ETU', [
            new FormulaireItem('temflgetu'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaenivsco'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temflguno')
        ]);
        
        // Tâches
        vm.tchMultiOccurrenceOptions = {
            bloc: 'tch',
            fonctions: {
                pagination: true,
                importation: true
            },
            actionsMore: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_tch',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItemDivider('G_LBL_MR0101_1'),
                        new FormulaireItem('offcleint_tch'),
                        new FormulaireItem('poscleint_tch'),
                        new FormulaireItem('temcleint_tch')
                    ]),
                    colonnesVisibles: [
                        {nom: 'gradsc', largeur: 300},
                        {nom: 'tchseqaff', largeur: 120},
                        {nom: 'tchdsc', largeur: 360}
                    ]
                })
            ]),
            colonnesVisibles: [
                {nom: 'gracod', largeur: 131},
                {nom: 'gradsc', largeur: 300},
                {nom: 'tchdsc', largeur: 360},
                {nom: 'tchseqaff', largeur: 131},
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gracleint', {
                    largeur: 50
                }),
                new FormulaireItem('tchseqaff', {
                    largeur: 50
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tchdsc', {
                    largeur: 100
                })
            ])
        };

        // Compétences requises
        vm.corMultiOccurrenceOptions = {
            bloc: 'cor',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    () => ({
                        stamnetab: 'TEM',
                        corcleintref: vm.monoOccurrence.data.temcleint
                    }),
                    {
                        onClose() {
                            vm.corMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    (data: any) => ({
                        id: data.corcleint,
                        stamnetab: 'TEM',
                        corcleintref: vm.monoOccurrence.data.temcleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ]),
            actionsMore: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_cor',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItemDivider('G_LBL_MR0101_1'),
                        new FormulaireItem('offcleint_cor'),
                        new FormulaireItem('poscleint_cor'),
                        new FormulaireItem('temcleint_cor'),
                        new FormulaireItem('urgcleint_cor')
                    ]),
                    colonnesVisibles: [
                        {nom: 'grcdsc', largeur: 300},
                        {nom: 'corseqaff', largeur: 300},
                        {nom: 'comcod', largeur: 91},
                        {nom: 'comdsc', largeur: 300},
                        {nom: 'corflgpae', largeur: 91}
                    ]
                })
            ]),
            colonnesVisibles: [
                'grcdsc',
                'corseqaff',
                'comdsc',
                'vacdsc',
                'corfacimp',
                'corflgidecan'
            ]
        };

        // PAEE
        vm.ipaMultiOccurrenceOptions = {
            bloc: 'ipa',
            fonctions: {
                pagination: true,
                importation: true
            },
            colonnesVisibles: [
                {nom: 'rgecod', largeur: 120},
                {nom: 'rgedsc', largeur: 120},
                {nom: 'rgecodpro', largeur: 120},
                {nom: 'rgedscpro', largeur: 120},
                {nom: 'vacsyszonrec_cod', largeur: 120},
                {nom: 'vacsyszonrec_dsc', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rgecleint', {largeur: 100}),
                new FormulaireItem('rgecleintpro', {largeur: 100}),
                new FormulaireItem('vacsyszonrec', {largeur: 100})
            ])
        };

        // Code CNP
        vm.icnMultiOccurrenceOptions = {
            bloc: 'icn',
            fonctions: {
                pagination: true,
                importation: true
            },
            colonnesVisibles: [
                {nom: 'ccpcod', largeur: 120},
                {nom: 'ccpdscabr', largeur: 120},
                {nom: 'vaetypicn_dsc', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ccpcleint', {largeur: 100}),
                new FormulaireItem('vaetypicn', {largeur: 100})
            ])
        };

        // Évaluation
        vm.etvMultiOccurrenceOptions = {
            bloc: 'etv',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_ETVREQ',
                    mr0255Route.NAME,
                    () => ({
                        trncleint: vm.monoOccurrence.data.trncleint
                    }),
                    {
                        onClose() {
                            vm.etvMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_ETVREQ',
                    mr0255Route.NAME,
                    (data: any) => ({
                        id: data.trncleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ]),
            colonnesVisibles: [
                'etvnumdoc',
                'etvdatvig',
                'evycod',
                'ecldsceta'
            ]
        };

        // Groupe échelle
        vm.tgrMultiOccurrenceOptions = {
            bloc: 'tgr',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'geccod', largeur: 130},
                {nom: 'gecdsc', largeur: 225},
                {nom: 'cvtcod', largeur: 125},
                {nom: 'cvtdsc', largeur: 200},
                {nom: 'tgrdatfin', largeur: 105}               
            ],
            colonnesCachees: [
                'temcleint',
                'geccleint'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('geccleint', {
                    resourceParams: () => {
                        return {
                            temcleint: vm.monoOccurrence.data.temcleint,
                            cqocleint: vm.monoOccurrence.data.cqocleint
                        };
                    }
                }),
                new FormulaireItem('tgrdatfin', {largeur: 33.33})
            ])
        };
    }
}
