import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageAC0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0019Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageAC0019 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleintcri', {
                resourceParams: {
                    type: 'FOU'
                },
                required: false
            }),
            new FormulaireItem('vecsysregadmcri', {
                required: false
            }),
            new FormulaireItem('tfocleintcri', {
                required: false
            }),
            new FormulaireItem('fogcleintcri', {
                required: false
            }),
            new FormulaireItem('pflcleintcri', {
                required: false
            }),
            new FormulaireItem('intcleintreqcri', {
                required: false
            }),
            new FormulaireItem('intcleintdemcri', {
                required: false
            }),
            new FormulaireItem('etdcleintcri', {
                required: false
            }),
            new FormulaireItem('etccleintcri', {
                required: false
            }),
            new FormulaireItem('motclecaacri', {
                required: false
            }),
            new FormulaireItem('flgrecparcri', {
                required: true
            }),
            new FormulaireItem('flgreccomcri', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cmdsattcri', {
                default: 2,
                required: true
            }),
            new FormulaireItem('moiscivcri', {
                required: false
            }),
            new FormulaireItemDivider('LBL_MONTANT'),
            new FormulaireItem('mntmincri', {
                required: false
            }),
            new FormulaireItem('mntmaxcri', {
                required: false
            }),
            new FormulaireItemDivider('LBL_PERIODE'),
            new FormulaireItem('peccleintdebcri', {
                required: false,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('peccleintfincri', {
                required: false,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemDivider('LBL_CATEGORIE_ACHAT'),
            new FormulaireItem('caacleintdebcri', {
                required: false
            }),
            new FormulaireItem('caacleintfincri', {
                required: false
            }),
            new FormulaireItemDivider('LBL_DATE_ACHAT'),
            new FormulaireItem('datdebcri', {
                required: false
            }),
            new FormulaireItem('datfincri', {
                required: false
            }),
            new FormulaireItemDivider('LBL_UNITE_ADM'),
            new FormulaireItem('unacleintdebcri', {
                required: false
            }),
            new FormulaireItem('unacleintfincri', {
                required: false
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'refnom', largeur: 200},
            {nom: 'refcod', largeur: 120}
        ],
        colonnesFixesDroite: [
            {nom: 'dacmnt', largeur: 150}
        ],
        colonnesVisibles: [
            {nom: 'dacnumdoc', largeur: 130},
            {nom: 'sysboncmd', largeur: 130},
            {nom: 'etcnumdoc', largeur: 140},
            {nom: 'vaeetctyp_dsc', largeur: 150},
            {nom: 'peccod', largeur: 80},
            {nom: 'dacdat', largeur: 126},
            {nom: 'dacdatreq', largeur: 126},
            {nom: 'dacdsc', largeur: 250},
            {nom: 'etdcod', largeur: 100},
            {nom: 'etddsc', largeur: 180},
            {nom: 'caacod', largeur: 120},
            {nom: 'caadsc', largeur: 180},
            {nom: 'tfocod', largeur: 120},
            {nom: 'tfodsc', largeur: 180},
            {nom: 'dacmntdepnet', largeur: 126},
            {nom: 'recstu', largeur: 150}
        ]
    };
}
