import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0053Controller(Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0053 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'dtqcleint', valeur: $stateParams.reference, visible: false}
        ],
        colonnesVisibles: [
            {nom: 'temcod', largeur: 89},
            {nom: 'temdscabr', largeur: 281},
            {nom: 'qstcod', largeur: 120},
            {nom: 'qstdsc', largeur: 435},
            {nom: 'dtqflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('temcleint', {required: true, modifAllow: false}),
            new FormulaireItem('qstcleint', {required: true, modifAllow: false}),
            new FormulaireItem('dtqflgact', {default: true})
        ], {largeurDefaut: 33.33})
    };
}
