import { IComponentController, IFormController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageGl0038 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    formulaireEca: IFormulaire;
    ecsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ecsMultiOccurrence: IMultiOccurrence;
    formCtrl: IFormController;
    watching: boolean;
    formulaireCardOpened: boolean;
}

/* @ngInject */
export default function Gl0038Controller(gl0003Route: IRoute,
    $state: IStateService,
    $stateParams: any,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageGl0038 = this;

    vm.$onInit = function $onInit() {
        const valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                afficherMenusDansEntete: true
            },
            menus: [new MenuItemAction("LBL_BTN_GEN", "BOUGEN", () => ({}), {
                iconButton: false,
                secondaire: true,
                class: 'md-raised ex-button-secondary',
                lblMessageSuccess: 'LBL_MSG_GEN',
                successAction: () => $state.go(gl0003Route.NAME, { id: valeursEcran.doccleint, menuId: $stateParams.menuId, ecjcleint: $stateParams.ecjcleint }),
                disabled: (data: any) => !vm.ecsMultiOccurrence || !vm.ecsMultiOccurrence.dataList || !vm.ecsMultiOccurrence.dataList.length || !vm.monoOccurrence.hasChampsModifiables(data.ecacleint, data)
            }), new MenuItemAction("LBL_BTN_SEL", "BOUSEL", () => ({intcleintreq: $stateParams.intcleintreq}), {
                iconButton: false,
                secondaire: true,
                class: 'md-raised ex-button-secondary',
                lblMessageSuccess: 'LBL_MSG_SEL',
                saveFirst: true,
                successAction: () => {
                    vm.formulaireCardOpened = false;
                    vm.ecsMultiOccurrence.fetchDataList();
                },
                disabled: (data: any) => (vm.formCtrl && vm.formCtrl.$invalid) || !vm.monoOccurrence.hasChampsModifiables(data.ecacleint, data)
            })]
        };

        vm.formulaireEca = new Formulaire('LBL_BLC_ECA', [
            new FormulaireItem('vaeecanivctl', { required: true }),
            new FormulaireItem('vaeprutypbdg', { required: true }),
            new FormulaireItem('cifcleintmanbdg', { required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecaann'),
            new FormulaireItem('peccleint'),
            new FormulaireItem('ecaannver'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ubrcoddeb'),
            new FormulaireItem('ubrcodfin'),
            new FormulaireItem('ecaindstu', { default: 3 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('prbcoddeb'),
            new FormulaireItem('prbcodfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecaunacoddeb'),
            new FormulaireItem('ecaunacodfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleint'),
            new FormulaireItem('orpcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tyfcleint'),
            new FormulaireItem('foncleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecasldref'),
            new FormulaireItem('ecaflgannaut')
        ], {
            largeurDefaut: 33.33
        });

        vm.ecsMultiOccurrenceOptions = {
            actionsSelection: new Menu([
                new MenuItemSelection(
                    'LBL_BTN_ANNULER',
                    'BOUANN',
                    {
                        selection: {
                            nom: 'actionAnnuler',
                            lblConfirmationTitre: 'LBL_EXECUTER'
                        },
                        successAction: () => { vm.ecsMultiOccurrence.fetchDataList(); }
                    }
                ),
                new MenuItemSelection(
                    'LBL_BTN_RENV_ANNULER',
                    'BOURENANN',
                    {
                        selection: {
                            nom: 'actionRenvAnnuler',
                            lblConfirmationTitre: 'LBL_EXECUTER'
                        },
                        successAction: () => { vm.ecsMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            colonnesFixesDroite: [
                { nom: 'ecsflgstu', largeur: 100 },
                { nom: 'ecsflgope', largeur: 100 },
                { nom: 'ecsmntann', largeur: 126 },
                { nom: 'vaesysnatctb_dsc', largeur: 126 },
                { nom: 'flgengfraadm', largeur: 131 },
                { nom: 'flgengcou', largeur: 131 },
                { nom: 'ecsflgann', largeur: 100 }
            ],
            colonnesVisibles: [
                { nom: 'prucod', largeur: 126 },
                { nom: 'prudscl01', largeur: 250 },
                { nom: 'prscod', largeur: 126 },
                { nom: 'prsdscl01', largeur: 250 },
                { nom: 'blocod', largeur: 126 },
                { nom: 'blodsc', largeur: 250 }
            ]
        };
    }
}
