import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMc0154 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mc0154Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageMc0154 = this;

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_DETRUIRE',
                'BOUDET',
                () => ({}),
                {
                    icon: 'delete',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    },
                    messageConfirmation: 'BOUDET_CONFIRM'
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaecnvexp_cri', {
                largeur: 25,
                required:true
            })
        ]),
        colonnesVisibles: [
            {nom: 'exccodsof'   , largeur: 400},
            {nom: 'excdscsof'   , largeur: 250},
            {nom: 'exccodout'   , largeur: 400},
            {nom: 'excdscout'   , largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaecnvexp', {default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaecnvexp_cri}),
            new FormulaireItem('exccodsof'),
            new FormulaireItem('excdscsof'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('exccodout'),
            new FormulaireItem('excdscout')
        ], {largeurDefaut: 33}),
        fonctions: {
            importation: true
        }
    };
}
