import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0011 {
    multiOccurrence: IMultiOccurrence,
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc0011Params {
    id: string;
}

/* @ngInject */
export default function Mc0011Controller(mc0163Route: IRoute,
                                         $stateParams: IMc0011Params,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0011 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0163Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0163Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_RSP',
                'BOURSP',
                (rowData:any) => ({
                    cifcleint:    rowData.cifcleint,
                    ciecleintcif: rowData.ciecleint
                }),
                {
                    disabled: (rowData: any) => { return rowData.cifcod_rsp; },
                    icon: 'launch',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    },
                    messageConfirmation: 'BOURSP_CONFIRM'
                }
            )
        ]),
        colonnesCachees: [
            'ciecleint'
        ],
        colonnesVisibles: [
            {nom: 'cifcod'   , largeur: 120},
            {nom: 'cifdsc'   , largeur: 230},
            {nom: 'cifdscabr', largeur: 200},
            {nom: 'cifnbrseq', largeur: 150},
            {nom: 'cifnumpri', largeur: 150},
            {nom: 'cifflgsai', largeur: 120},
            {nom: 'cifcod_rsp', largeur: 160}
        ]
    };
}
