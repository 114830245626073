import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageRC2003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rc2003Controller($stateParams: any) {
    const vm: IPageRC2003 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'txmcleint', valeur: $stateParams.txmcleint, visible: false }
        ],
        colonnesVisibles: [
            { nom: 'reqnumdoc', largeur: 120 },
            { nom: 'reqdsc', largeur: 200 },
            { nom: 'reqmnt', largeur: 120 },
            { nom: 'reqdat', largeur: 110 }
        ]
    };
}
