import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCR0029 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0029Controller(Menu: IMenuClass,
    cr0016Route: IRoute,
    cr2001Route: IRoute,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCR0029 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([new MenuItemForage(cr0016Route.NAME)]),
        navigatePage: cr2001Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.doccleintref
            };
        },
        fonctions: {
            rechercheCollapsable: false
        },                
        colonnesVisibles: [
            {nom: 'cclnumdoc', largeur: 120},
            {nom: 'cacnumdocseq', largeur: 80},
            {nom: 'cacann', largeur: 100},
            {nom: 'cacmnt', largeur: 120},
            {nom: 'tydcod', largeur: 130},
            {nom: 'refcod', largeur: 130},
            {nom: 'refnomabr', largeur: 200},
            {nom: 'etddsc', largeur: 150},
            {nom: 'ccldatdeb', largeur: 126},
            {nom: 'ccldatfin', largeur: 126},
            {nom: 'peccod', largeur: 126},
            {nom: 'cacdateff', largeur: 126},
            {nom: 'cifcod', largeur: 100},
            {nom: 'cifdsc', largeur: 250},
            {nom: 'ccldsc', largeur: 350},
            {nom: 'cclnumref', largeur: 120},
            {nom: 'cclmnt', largeur: 120},
            {nom: 'vaesysdevcod_dsc', largeur: 130},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 180},
            {nom: 'usrcod', largeur: 130}
        ],
        colonnesCachees: [
            'doccleintref'
        ]
    };
}
