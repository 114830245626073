import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
interface IPageAs0007 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    sacFormulaire: IFormulaire;
    sddFormulaire: IFormulaire;
    grpFormulaire: IFormulaire;
    hreFormulaire: IFormulaire;
}
/* @ngInject */
export default function As0007Controller(
    as0016Route: IRoute,
    as2005Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAs0007 = this;
    vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire('LBL_BLC_SUT', [
                new FormulaireItem('sutnumseqreg'),
                new FormulaireItem('sutnumseq'),
                new FormulaireItem('vaesuttyp'),
                new FormulaireItem('sutflgact'),
                new FormulaireItem('sutcod'),
                new FormulaireItem('sutdsc', { largeur: 75 }),
                new FormulaireItem('sutcom', { largeur: 100, enableCount: true }),
                new FormulaireItem('sutdatdeb'),
                new FormulaireItem('sutdatfin'),
                new FormulaireItem('vaesuteva'),
                new FormulaireItem('vaesutimp', { required: true })    
            ], {largeurDefaut: 25})
        };

        //Impact sur le temps
        vm.sacFormulaire = new Formulaire('LBL_BLC_SUTSAC', [
            new FormulaireItem('vaesutmodimp', { largeur: 33 }),
            new FormulaireItem('actcleint', { largeur: 66 }),
            new FormulaireItem('vaesutactimp', { largeur: 66 }),
            new FormulaireItem('sutactfct', { largeur: 33 }),
            new FormulaireTemplateSlot('sacDetail')
        ]);

        //Impact sur la rémuniration
        vm.sddFormulaire = new Formulaire('LBL_BLC_SUTSDD', [
            new FormulaireItem('vaesutmodint', { largeur: 33 }),
            new FormulaireItem('tagcleint', { largeur: 66 }),
            new FormulaireItem('vaesuttagimp', { largeur: 66 }),
            new FormulaireItem('suttagqte', { largeur: 33 }),
            new FormulaireTemplateSlot('sddDetail')
        ]);

        // Admissibilité du groupe
        vm.grpFormulaire = new Formulaire('LBL_BLC_GRP', [
            new FormulaireItem('vaesutmodadmgrp', { largeur: 33.33 }),
            new FormulaireTemplateSlot('grpDetail')
        ]);

        // Admissibilité du temps
        vm.hreFormulaire = new Formulaire('LBL_BLC_HRE', [
            new FormulaireItem('vaesutmodadmhre', { largeur: 33.33 }),
            new FormulaireTemplateSlot('hreDetail')
        ]);

        vm.grpMultiOccurrenceOptions = {
            bloc: 'grp',
            colonnesVisibles: [
                { nom: 'ramnumseqaff', largeur: 75 },
                { nom: 'maddsc', largeur: 290 },
                { nom: 'ramval', largeur: 262 },
                { nom: 'ramindexc', largeur: 90 },
                { nom: 'indval', largeur: 90 }
            ],
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_RESERVE',
                    as2005Route.NAME,
                    (rowData: any) => ({
                        ramcleint: rowData.ramcleint,
                        madcleint: rowData.madcleint,
                        stamnetab: rowData.stamnetab
                    }),
                    {
                        fonction: 'RESERVES',
                        icon: 'launch',
                        iconButton: true,
                        hidden: (data: any) => !(data.flgmul === 1)
                    }
                )
            ]),
            colonnesCachees: ['ramcleint', 'vaeramtyp', 'sutcleint', 'madcleint', 'flgmul', 'stamnetab'],
            formulaire: new Formulaire([
                new FormulaireItem('ramnumseq', { largeur: 33, required: true }),
                new FormulaireItem('madcleint', { largeur: 33, required: true }),
                new FormulaireItem('madnummth', { hidden: true }),
                new FormulaireItem('ramindexc', { largeur: 33 }),
                new FormulaireItem('vaeramtyp', {
                    default: 'GRP',
                    largeur: 33,
                    hidden: true
                }),
                new FormulaireItem('vaevammodges', { largeur: 33, hidden: (data: any) => !(data.madnummth === 105) }),
                new FormulaireItem('vaevamon', { largeur: 33, hidden: (data: any) => !(data.madnummth === 106 || data.madnummth === 110) })
            ])
        };

        vm.hreMultiOccurrenceOptions = {
            bloc: 'hre',
            colonnesVisibles: [
                { nom: 'ramnumseqaff', largeur: 75 },
                { nom: 'maddsc', largeur: 290 },
                { nom: 'ramval', largeur: 262 },
                { nom: 'ramindexc', largeur: 90 },
                { nom: 'indval', largeur: 90 }
            ],
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_RESERVE',
                    as2005Route.NAME,
                    (rowData: any) => ({
                        ramcleint: rowData.ramcleint,
                        madcleint: rowData.madcleint,
                        stamnetab: rowData.stamnetab
                    }),
                    {
                        fonction: 'RESERVES',
                        icon: 'launch',
                        iconButton: true,
                        hidden: (data: any) => !(data.flgmul === 0)
                    }
                )
            ]),
            colonnesCachees: ['ramcleint', 'madcleint', 'flgmul', 'stamnetab', 'vaevalcle', 'vamvalnum', 'madnummth'],
            formulaire: new Formulaire([
                new FormulaireItem('ramnumseq', { required: true }),
                new FormulaireItem('madcleint', { required: true }),
                new FormulaireItem('ramindexc'),
                new FormulaireItem('madnummth', { hidden: true }),
                // Méthode 3
                new FormulaireItem('vamflgjrsfer3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vaesysjrssem3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamvaldat3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamflgdim3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamflglun3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamflgmar3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamflgmer3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamflgjeu3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamflgven3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                new FormulaireItem('vamflgsam3', { hidden: (rowData:any) => !(rowData.madnummth === 3) }),
                // Méthode 4
                new FormulaireItem('vamvalnum4', { hidden: (rowData:any) => !(rowData.madnummth === 4) }),
                // Méthode 10
                new FormulaireItem('vaevamope10', { hidden: (rowData:any) => !(    rowData.madnummth === 10 
                                                                                || rowData.madnummth === 14 
                                                                                || rowData.madnummth === 15 
                                                                                || rowData.madnummth === 20 
                                                                                || rowData.madnummth === 22 ) }),
                new FormulaireItem('vamvalnum10', { hidden: (rowData:any) => !(    rowData.madnummth === 10 
                                                                                || rowData.madnummth === 14 
                                                                                || rowData.madnummth === 15 
                                                                                || rowData.madnummth === 20 
                                                                                || rowData.madnummth === 22 ) }),
                // Méthode 11, 26
                new FormulaireItem('vamdatdeb11', { hidden: (rowData:any) => !(rowData.madnummth === 11 || rowData.madnummth === 26) }),
                new FormulaireItem('vamdatfin11', { hidden: (rowData:any) => !(rowData.madnummth === 11 || rowData.madnummth === 26) }),
                // 12
                new FormulaireItemDivider('LBL_DIV_VAL', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamvalnum12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamdatdeb12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamdatfin12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItemDivider('LBL_DIV_JOUR', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflgjrsfer12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamvaldat12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vaesysjrssem12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflgdim12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflglun12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflgmar12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflgmer12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflgjeu12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflgven12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                new FormulaireItem('vamflgsam12', { hidden: (rowData:any) => !(rowData.madnummth === 12 || rowData.madnummth === 27) }),
                // 13
                new FormulaireItem('vamvalnum13', { hidden: (rowData:any) => !(rowData.madnummth === 13) }),
                // Méthode 24
                new FormulaireItem('bqecleint24', { hidden: (rowData:any) => !(rowData.madnummth === 24), required: (rowData:any) => (rowData.madnummth === 24) }),
                new FormulaireItem('vaevamope24', { hidden: (rowData:any) => !(rowData.madnummth === 24) }),
                new FormulaireItem('vamvalnum24', { hidden: (rowData:any) => !(rowData.madnummth === 24) })
            ], { largeurDefaut:33.33 })
        };

        vm.sacMultiOccurrenceOptions = {
            bloc: 'sac',
            navigatePage: as0016Route.NAME,
            navigateParams: (data: any) => (
                {
                    sutcleint: data.$ancetre.sutcleint,
                    vaesegdec: 'ACT',
                    indfor: 0,
                    sutcod: data.$ancetre.sutcod
                }),
            colonnesVisibles: [
                { nom: 'sacnumseq', largeur: 75 },
                { nom: 'sddeddnom', largeur: 290 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('sacnumseq',
                    { largeur: 25 }),
                new FormulaireItem('eddcleint',
                    { largeur: 50 })
            ])
        };

        vm.sddMultiOccurrenceOptions = {
            bloc: 'sdd',
            navigatePage: as0016Route.NAME,
            navigateParams: (data: any) => (
                {
                    sutcleint: data.$ancetre.sutcleint,
                    vaesegdec: 'TAG',
                    indfor: 0,
                    sutcod: data.$ancetre.sutcod
                }),
            colonnesVisibles: [
                { nom: 'sddnumseq', largeur: 75 },
                { nom: 'eddnom', largeur: 290 }
            ],
            colonnesCachees: ['sutcleint'],
            formulaire: new Formulaire([
                new FormulaireItem('sddnumseq', { largeur: 25 }),
                new FormulaireItem('eddcleint', { largeur: 50 })
            ])
        };

}
