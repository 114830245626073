import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import IDialogService = angular.material.IDialogService;

interface IPageIF0017 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    itdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    itdMultiOccurrence: IMultiOccurrence;
}

interface IIf0017Params {
    itdcleintgen: string;
    itdcleintnew: string;
    itscleint: string;
}

/* @ngInject */
export default function If0017Controller($stateParams: IIf0017Params,
    Operateur: IOperateurService,
    profil: IProfil,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $mdDialog: IDialogService) {
    const vm: IPageIF0017 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Le filtre dépend si l'écran est appelé par la sélection pour le forage
        let filtre;
        if ($stateParams.itdcleintgen) {
            filtre = [
                {
                    colonne: 'itdcleint',
                    operateur: Operateur.EGALE,
                    valeur: ($stateParams.itdcleintgen).split(','),
                    visible: false
                }
            ]
        }
        else {
            filtre = [
                {
                    colonne: 'itscleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.itscleint,
                    visible: false
                }
            ];
        }

        vm.monoOccurrenceOptions = {
            noId: true,
            id: profil.preferences.ciecleint,
            fonctions: {
                afficherMenusDansEntete: true,
                afficherMenusCreation: true
            },
            menus: [
                new MenuItemAction('LBL_BTN_ATRAITER',
                    'BOUATRT',
                    () => ({
                        itdcleintgen: $stateParams.itdcleintgen
                    }),
                    {
                        iconButton: false,
                        icon: '',
                        class: 'md-raised ex-button-action',
                        lblMessageSuccess: 'LBL_MSG_TRANS',
                        disabled: () => vm.valeursEcran.itsflgnontrt,
                        successAction: () => { $mdDialog.hide(); }
                    }
                )
            ]
        };

        vm.itdMultiOccurrenceOptions = {
            filtres: filtre,
            bloc: 'itd',
            colonnesVisibles: [
                { nom: 'vaeitsstutrs_dsc', largeur: 120 },
                { nom: 'tydcod', largeur: 120 },
                { nom: 'docaffnumdoc01', largeur: 150 },
                { nom: 'itsdatref', largeur: 100 },
                { nom: 'peccod', largeur: 120 },
                { nom: 'imp', largeur: 230 },
                { nom: 'itdmnt', largeur: 126 }
            ],
            colonnesCachees: [
                'itdcleint',
                'itscleint'
            ]
        };
    }
}
