import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagerr0151 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function rr0151Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagerr0151 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'urgcod',largeur: 140},
            {nom: 'urgdsc',largeur: 270},
            {nom: 'gaucod', largeur: 150},
            {nom: 'gaudsc', largeur: 320},
            {nom: 'gauprc', largeur: 120},
            {nom: 'gaudoc', largeur: 300},
            {nom: 'gauflgdef',largeur: 100},
            {nom: 'gauflgact',largeur: 80}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('gauflgdef',{largeur:25}),
            new FormulaireItem('gauflgact', {largeur:25,default:1}),
            new FormulaireItem('gaucod',{largeur:25} ),
            new FormulaireItem('gaudsc'),
            new FormulaireItem('gauprc',{largeur:25}),
            new FormulaireItem('gaudoc',{enableCount:true,largeur:100}),
        ])
    };
}
