import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0024',
    ecrans: [
        '00-2',
        '01-2',
        '02-2'
    ],
    params: ['id',
             '?lemcleint',
             '?aftcleint',
             '?tytcleint',
             '?lsacleint',
             '?trncleint',
             '?rrhcleintref']
});
