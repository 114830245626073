import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
interface IPageAs0003 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePtaOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0003Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Pagination: IPaginationClass,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass
) {
    const vm: IPageAs0003 = this;
    vm.multiOccurrencePtaOptions = {
        bloc: 'pta',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 30,
            nbElementsPossibles: [30, 60]
        }),
        colonnesVisibles: [
            { nom: 'percod', largeur: 85 },
            { nom: 'ptacod', largeur: 90 },
            { nom: 'ptadatdeb', largeur: 100 },
            { nom: 'ptadatfin', largeur: 100 },
            { nom: 'vaetppstu_desc', largeur: 100 }
        ]
    };
    vm.multiOccurrenceOptions = {
        fonctions: {
            pagination: true,
            supprime: (data: any) => data.snrflgmodper,
            edition: (data: any) => (data.snrflgmodper)
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        actionsMoreLigne: new Menu([
            new MenuItemAction('LBL_BTN_GEN', 'BOUGEN', () => ({}), {
                icon: 'sync',
                successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            })
        ]),
        colonnesVisibles: [
            { nom: 'percod', largeur: 130 },
            { nom: 'perdsc', largeur: 300 },
            { nom: 'snrann', largeur: 120 },
            { nom: 'snrdatdeb', largeur: 100 },
            { nom: 'snrdatfin', largeur: 100 },
            { nom: 'snrnbrper', largeur: 180 }
        ],
        colonnesCachees: ['snrflgmodper'],
        formulaire: new Formulaire(
            [
                new FormulaireItem('percleint'),
                new FormulaireItem('snrann'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('snrdatdeb'),
                new FormulaireItem('snrdatfin'),
                new FormulaireItem('snrnbrper')
            ],
            { largeurDefaut: 33 }
        ),
        multiOccurrenceOptionsEnfant: vm.multiOccurrencePtaOptions
    };
}
