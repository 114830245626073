import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
interface IPageMr0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mr0006Controller(Menu: IMenuClass, MenuItemEcran: IMenuItemEcranClass, mr0007Route: IRoute) {
    const vm: IPageMr0006 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([new MenuItemEcran('Nouveau', mr0007Route.NAME, () => ({}))]),
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                '',
                mr0007Route.NAME,
                (data: any) => {
                    return {
                        id: data.tavcleint,
                        descleintref: data.descleintref
                    };
                },
                {
                    icon: 'launch',
                    fonction: 'BOUOUV',
                    onClose: () => vm.multiOccurrence.fetchDataList()
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'vaedsc', largeur: 210 }
        ],
        colonnesVisibles: [            
            { nom: 'tavdeldef', largeur: 100 },
            { nom: 'tavvaldef', largeur: 139 },
            { nom: 'tavsujavi', largeur: 262 },
            { nom: 'tavtexavi', largeur: 525 },
            { nom: 'tavcom', largeur: 525 },
        ]
    };
}
