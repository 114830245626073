import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0136 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0136Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0136 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaebmtent_dsc', largeur: 150},
            {nom: 'bmtvalrem', largeur: 100},
            {nom: 'bmtnompab', largeur: 150},
            {nom: 'bmtnomcle', largeur: 100}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('vaebmtent', {largeur: 50}),
                new FormulaireItem('bmtvalrem', {largeur: 50}),
                new FormulaireItem('bmtnompab', {largeur: 50}),
                new FormulaireItem('bmtnomcle', {largeur: 50})
            ]
        )
    };
}
