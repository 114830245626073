import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IFonctionTransversaleFormulaire } from '../../../services/fonction-transversale-formulaire.service';

interface IPageGS0115 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0115Controller(Menu: IMenuClass,
                                         MenuItem: IMenuItemClass,
                                         FonctionTransversaleFormulaire: IFonctionTransversaleFormulaire) {
    const vm: IPageGS0115 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            recherche: false,
            rechercheUnique: false,
            filtrer: false,
            suiviModification: false,
            selectionnerUnEtat: false,
            rechercheParColonne: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            reinitialiser: false,
            criteresRechercheCollapsable: false,
            criteresSuggeresVisibles: false,
            saveEtatTemporaire: false,
            afficherMenuSettingDansEntete: false
        },
        actionsMoreLigne: new Menu([
            new MenuItem(
                'LBL_LANCEMENT',
                (event: any, data: any) => {
                    FonctionTransversaleFormulaire.lancerTraitement(data);
                },
                {
                    icon: 'print',
                    iconButton: false
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'trldsc', largeur: 500},
            {nom: 'trlcod', largeur: 200}
        ],
        colonnesCachees: [
            'trlcleint',
            'ecrcleint',
            'srccod'
        ]
    };
}
