import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePI0033 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0033Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass) {
    const vm: IPagePI0033 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesVisibles: [
            'slodatvig',
            'pipnumdoc',
            'pipnumref',
            'pipdsc',
            'pplnumdoc',
            'connumdoc',
            'condsc',
            'refcod',
            'refnom',
            'ecldsceta',
            'slocom',
            'trnflgstaref',
            'trnflgstafin',
            'trnflgstafer',
            'trnflgstaann'

        ]
    };
}
