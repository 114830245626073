import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGs0118 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    medMultiOccurrenceOptions: IMultiOccurrenceOptions;
    medMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Gs0118Controller(Menu: IMenuClass,
    gs0118Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGs0118 = this;

    const accueilRoute = { NAME: 'secure.accueil' };

    vm.monoOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_COPIER',
                'BOUCOP',
                () => ({}),
                {
                    icon: 'content_copy',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_COPIER',
                        lblConfirm: 'G_LBL_BTN_COPIER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('mencleintcop', { default: (data: any) => data.$id, hidden: true }),
                            new FormulaireItem('debut', { required: true })
                        ], { largeurDefaut: 100, }
                        )
                    }
                }
            )
        ]),
        formulaire: new Formulaire('LBL_BLC_MEN', [
            new FormulaireItem('mendsc'),
            new FormulaireItem('mendscmen'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaesysapp', { required: true }),
            new FormulaireItem('vaesysmodsof', { required: true }),
            new FormulaireItem('menflgcen')
        ], { largeurDefaut: 33.33 })
    };

    // Bloc hie Affichage hiérarchique
    vm.hieMultiOccurrenceOptions = {
        brisNiveaux: 'niveau',
        bloc: 'hie',
        fonctions: {
            nouveau: false,
            edition: false,
            supprime: false,
            recherche: false
        },
        colonnesVisibles: [
            {
                nom: 'meddscmen',
                largeur: 100
            }
        ],
        colonnesCachees: ['medcleintref', 'vaesysenttrt', 'newline', 'sec'],
    };

    // Bloc hie Affichage hiérarchique parents
    vm.hprMultiOccurrenceOptions = {
        brisNiveaux: 'niveau',
        bloc: 'hpr',
        fonctions: {
            nouveau: false,
            edition: false,
            supprime: false,
            recherche: false
        },
        colonnesVisibles: [
            {
                nom: 'meddscmen',
                largeur: 100
            }
        ],
        colonnesCachees: ['medcleintref', 'vaesysenttrt', 'newline', 'sec'],
    };

    vm.medMultiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_FOR_TYP',
                (data: any) => getForage(data),
                (data: any) => ({ id: data.medcleintref }),
                { hidden: (data: any) => data.vaesysenttrt !== 'MEN' }
            )]),
        colonnesCachees: [
            'medcleintref',
            'vaesysenttrt'
        ],
        colonnesVisibles: [
            { nom: 'medcle', largeur: 100 },
            { nom: 'vaesysenttrt_dsc', largeur: 200 },
            { nom: 'meddsc', largeur: 300 },
            { nom: 'medcod', largeur: 150 }

        ],
        formulaire: new Formulaire([
            new FormulaireItem('medcle'),
            new FormulaireItem('vaesysenttrt'),
            new FormulaireItem('medcleintref', {
                largeur: 50,
                resourceParams: (data: any) => (
                    {
                        vaesysapp: vm.monoOccurrence.data.vaesysapp
                    })
            })
        ], { largeurDefaut: 25 })
    };

    function getForage(data: any): string {
        if (data.vaesysenttrt === 'MEN') {
            return gs0118Route.NAME;
        } else {
            return accueilRoute.NAME;
        }
    };
}
