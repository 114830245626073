import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGA0005 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function GA0005Controller(FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageGA0005 = this;

    vm.multiOccurrenceOptions = {        
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_CRI'),
            new FormulaireItem('peccoddeb', { largeur: 33 }),
            new FormulaireItem('peccodfin', { largeur: 33 }),
            new FormulaireItem('tydcleint',{ largeur: 33, lovSelectionMultiple: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('el2cleint', { largeur: 33, lovSelectionMultiple: true,  required: isRequired}),
            new FormulaireItem('prucleint', { largeur: 33, lovSelectionMultiple: true}),
            new FormulaireItem('gcacleint', { largeur: 33, required: isRequired})
        ]),    
        colonnesVisibles: [
            {nom: 'gaacod', largeur: 110},
            {nom: 'gaadsc', largeur: 210},
            {nom: 'prucod', largeur: 110},
            {nom: 'prudscl01', largeur: 210},
            {nom: 'cptcod', largeur: 110},
            {nom: 'cptdsc', largeur: 210},
            {nom: 'pdfcod', largeur: 170},
            {nom: 'prscod', largeur: 110},
            {nom: 'unacod', largeur: 190},
            {nom: 'unadsc', largeur: 250},
            {nom: 'cifcod', largeur: 110},
            {nom: 'docrefcod02', largeur: 130},
            {nom: 'docrefnom02', largeur: 210},
            {nom: 'tydcod', largeur: 110},
            {nom: 'docaffnumdoc01', largeur: 230},
            {nom: 'docaffdsc01', largeur: 210},
            {nom: 'docaffdat01', largeur: 110},
            {nom: 'peccod', largeur: 110},
            {nom: 'docaffnumref01', largeur: 262},
            {nom: 'docaffmnt01', largeur: 170},
            {nom: 'mdvmnt', largeur: 170},
            {nom: 'taxcod', largeur: 110},
            {nom: 'mdvmnttaxfed', largeur: 130},
            {nom: 'mdvmnttaxpro', largeur: 150},
            {nom: 'mdvmntremfed', largeur: 130},
            {nom: 'mdvmntrempro', largeur: 150},
            {nom: 'mdvmntnet', largeur: 162}
            ],
            colonnesCachees: [
                'ecrcod'
            ]
    };

    function isRequired(data: any) {
        return !data.el2cleint && !data.gcacleint;
    }
}
