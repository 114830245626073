import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface ICr2002Params {
    enacleint: number;
    indfor: number;
}
interface IPageCr2002 extends IComponentController {
}

/* @ngInject */
export default function Mr0176Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    $stateParams: ICr2002Params,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr2002 = this;

    vm.$onInit = function $onInit() {
        vm.flgcen = ecranDetails.flgcen;

        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_ENA', [
                new FormulaireItem('docaffnumdoc01'),
                new FormulaireItem('sldava'),
                new FormulaireItem('enamnt'),
                new FormulaireItem('sldavautl'),
                new FormulaireItem('enamntutl')
            ], { largeurDefaut: 20 })
        };

        // Imputation
        vm.evtMultiOccurrenceOptions = {
            bloc: 'evt',
            fonctions: {
                nouveau: $stateParams.indfor === 1,
                supprime: $stateParams.indfor === 1
            },
            colonnesVisibles: [
                { nom: 'imp', largeur: 250 },
                { nom: 'duvsld', largeur: 100 },
                { nom: 'evtmnt', largeur: 120 },
                { nom: 'evtdsc', largeur: 240 },
                { nom: 'evtflgmsg', largeur: 130 }
            ],
            colonnesCachees: [
                'evtmsg'
            ],
            formulaire: new Formulaire([
                new FormulaireItem("imp", { modifAllow: false, largeur: 25 }),
                new FormulaireItem("evtmnt", { largeur: 25 }),
                new FormulaireItem("evtdsc", { largeur: 50 })
            ])
        };
    }
}
