import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    pcmMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0012Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0012 = this;


    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'peccod'   , largeur: 126},
            {nom: 'pecann'   , largeur: 126},
            {nom: 'pecper'   , largeur: 126},
            {nom: 'pecdatdeb', largeur: 126},
            {nom: 'pecdatfin', largeur: 126}
        ]
    };

    vm.pcmMultiOccurrenceOptions = {
        bloc: 'pcm',
        colonnesVisibles: [
            {nom: 'vaesysmodcod_dsc', largeur: 200},
            {nom: 'pcmdatfer'       , largeur: 160},
            {nom: 'vaepcmstu_dsc'   , largeur: 200}
        ]
    };
}
