import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRR0139 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0139Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageRR0139 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        actionsNouveaux: new Menu([]),
        fonctions: {
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datact', {
                required: false
            })
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'ciecod', largeur: 115},
            {nom: 'cienomabr', largeur: 262},
            {nom: 'lemdatdeb', largeur: 100},
            {nom: 'lemdatfin', largeur: 100},
            {nom: 'ugncod', largeur: 100},
            {nom: 'ugndsc', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'sgncod', largeur: 130},
            {nom: 'sgndsc', largeur: 262},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'lemcod', largeur: 90},
            {nom: 'tlecod', largeur: 100},
            {nom: 'tledsc', largeur: 320},
            {nom: 'tleflgpmn', largeur: 70},
            {nom: 'tleflgpri', largeur: 70},
            {nom: 'lemflgemb', largeur: 70},
            {nom: 'lemdsc', largeur: 393},
            {nom: 'ecldsceta', largeur: 183},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
