import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IComponentController } from 'angular';


interface IPageMc0152 extends IComponentController{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Mc0152Controller( ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMc0152 = this;
        
        vm.$onInit = $onInit;

        function $onInit(): void {
            vm.valeursEcran = ecranDetails.valeurs;
            vm.multiOccurrenceOptions = {
                fonctions: {
                    suiviModification: false,
                    libellesDynamiques: true
                },
                colonnesVisibles: [
                    {nom: 'peccod1', largeur: 100, hidden: () => !vm.valeursEcran.libelle1, replacedValue: () => vm.valeursEcran.libelle1},
                    {nom: 'peccod2', largeur: 100, hidden: () => !vm.valeursEcran.libelle2, replacedValue: () => vm.valeursEcran.libelle2},
                    {nom: 'peccod3', largeur: 100, hidden: () => !vm.valeursEcran.libelle3, replacedValue: () => vm.valeursEcran.libelle3},
                    {nom: 'peccod4', largeur: 100, hidden: () => !vm.valeursEcran.libelle4, replacedValue: () => vm.valeursEcran.libelle4},
                    {nom: 'peccod5', largeur: 100, hidden: () => !vm.valeursEcran.libelle5, replacedValue: () => vm.valeursEcran.libelle5},
                    {nom: 'peccod6', largeur: 100, hidden: () => !vm.valeursEcran.libelle6, replacedValue: () => vm.valeursEcran.libelle6},
                    {nom: 'peccod7', largeur: 100, hidden: () => !vm.valeursEcran.libelle7, replacedValue: () => vm.valeursEcran.libelle7},
                    {nom: 'peccod8', largeur: 100, hidden: () => !vm.valeursEcran.libelle8, replacedValue: () => vm.valeursEcran.libelle8},
                    {nom: 'peccod9', largeur: 100, hidden: () => !vm.valeursEcran.libelle9, replacedValue: () => vm.valeursEcran.libelle9},
                    {nom: 'peccod10', largeur: 100, hidden: () => !vm.valeursEcran.libelle10, replacedValue: () => vm.valeursEcran.libelle10},
                    {nom: 'peccod11', largeur: 100, hidden: () => !vm.valeursEcran.libelle11, replacedValue: () => vm.valeursEcran.libelle11},
                    {nom: 'peccod12', largeur: 100, hidden: () => !vm.valeursEcran.libelle12, replacedValue: () => vm.valeursEcran.libelle12}
                ],
                colonnesFixesGauche:[
                    {nom: 'cienomabr', largeur: 200}
                ],
                colonnesCachees:[
                   'ciecleint_cie'
                ]   
            };
        }
       
}
