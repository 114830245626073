import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';
import { IMenuItemFactoryClass } from '../../../../../core/services/menu/menu-item-factory.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMc0102 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface IMc0102Params {
    doccleintref: string;
}
/* @ngInject */
export default function Mc0102Controller(Menu: IMenuClass,
    mc0109Route: IRoute,
    $stateParams: IMc0102Params,
    isForageValide: IIsForageValideService,
    MenuItemFactory: IMenuItemFactoryClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0102 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]),
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0109Route.NAME, () => {
                return {
                    doccleintref: $stateParams.doccleintref
                };
            })
        ]),
        colonnesVisibles: [
            { nom: 'ajfnumdoc', largeur: 140 },
            { nom: 'ajfnumdocseq', largeur: 100 },
            { nom: 'tydcod', largeur: 120 },
            { nom: 'tyddsc', largeur: 200 },
            { nom: 'etddsc', largeur: 120 },
            { nom: 'ajfdat', largeur: 120 },
            { nom: 'ajfdsc', largeur: 200 }
        ],
        colonnesCachees: [
            'ajfcleint',
            'doccleintref',
            'ecrcod'
        ]
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcod}.ecran`),
            (data: any) => ({
                id: data.doccleint
                // ajfcleint: data.ajfcleint,
                //doccleintref: data.doccleintrref,
                // indfor: 1
            }),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('mc0102', data.ecrcod);
                }
            }
        )
    }
}
