import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IMenuClass, MenuElement} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRr0095 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    menus: any;
    forageAft: Array<MenuElement>;
    forageAftAct: IMenuItem;
    formulaireLsc: IFormulaire;
    lsaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aftMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0095Controller(rr0096Route:IRoute,
                                         rr0097Route:IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0095 = this;
    
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.menus = {
            aftcoddsc: [
                new MenuItemForage(
                    rr0097Route.NAME,
                    (data: any) => ({
                        id: data.aftcleint
                    }),
                    {   fonction: 'BOUAFTACT',
                        hidden: (data: any) => !data.aftcleint}
                )
            ]
        };

        vm.forageAft = [
            new MenuItemForage(
                'BTN_FORAGE_',
                rr0097Route.NAME,
                (data: any) => ({
                    id: data.aftcleint
                }),
                {   fonction: 'BOUAFT',
                    hidden: (data: any) => !data.aftcleint }
            )
        ];

       vm.forageAftAct = new MenuItemForage('LBL_FORAGE_DO0001',
            rr0097Route.NAME,
           (data: any) => ({
                id: data.aftcleint
            }),
            {   fonction: 'BOUAFTACT',
                hidden: (data: any) => !data.aftcleint }
        );

        vm.formulaireLsc = new Formulaire('LBL_BLC_LSC', [
            new FormulaireItem('lemcod'),
            new FormulaireItem('lemflgemb'),
            new FormulaireItem('urgcoddsc'),
            new FormulaireItem('strcoddsc'),
            new FormulaireItem('lemdatdeb'),
            new FormulaireItem('lemdatfin'),
            new FormulaireItem('lemflgfincon'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tlecoddsc'),
            new FormulaireItem('tleflgpri'),
            new FormulaireItem('lemdsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unocoddsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aftdatdebfin'),
            new FormulaireItem('aftflgmul'),
            new FormulaireItem('temcoddsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('poscoddsc'),
            new FormulaireItem('aftflgttu'),
            new FormulaireItem('nomsup')
        ]);

        vm.lsaMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tsadsc'   , largeur: 50},
                {nom: 'tsadatvig', largeur: 126}
            ]
        };

        vm.dasMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'avcdsc'   , largeur: 206},
                {nom: 'dasdatvig', largeur: 126},
                {nom: 'avsdsc'   , largeur: 206},
                {nom: 'dasflgact', largeur: 100}
            ]
        };

        vm.aftMultiOccurrenceOptions = {
            navigatePage: rr0097Route.NAME,
            actionsMore: new Menu([
                new MenuItemForage(
                    'LBL_VOIR_AFT',
                    rr0096Route.NAME,
                    (data: any) => ({
                        eplnummat: data.eplnummat,
                        lemcleint: data.lemcleint,
                        demcleint: data.demcleint
                    }),
                    {
                        icon: 'view_list',
                        iconButton: false
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'aftdsc'   , largeur: 186},
                {nom: 'tafdsc'   , largeur: 186},
                {nom: 'aftdebfin', largeur: 126},
                {nom: 'tafflgttu', largeur: 126}
            ]
        };
    }
}
