import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {
    IEcranDetailsResourcesEntite
} from '../../../../../core/resources/ecran-details.resource';

interface IPageCS0003 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAcc: IFormulaire;
    formulaireSta: IFormulaire;
    formulaireEnq: IFormulaire;
    vicMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ateMultiOccurrenceOptions: IMultiOccurrenceOptions;
    apeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aenMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ameMultiOccurrenceOptions: IMultiOccurrenceOptions;
    acaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cs0003Controller(cs0006Route: IRoute,
    cs0007Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCS0003 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                zoneDroiteVisibleCreation: true
            }
        };
        vm.formulaireAcc = new Formulaire('LBL_BLC_ACC', [
            new FormulaireItem('accnumdoc'),
            new FormulaireItem('ttccleint', { modifAllow: false, nombreLibellesDetailsCacher: 1 }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeacctyp', { required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('acclieacc', { enableCount: true }),
            new FormulaireItem('accdat'),

            // Champ prennant 100% de l'espace horizontale
            new FormulaireItem('accdsc', { enableCount: true }),

            // Champ conditionnel
            new FormulaireItem('accflgbrimat', { largeur: 25 }),
            new FormulaireItem('accexpbrimat', { largeur: 25, hidden: data => !data.accflgbrimat }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('acccom', { largeur: 100, enableCount: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unocleintrh', { default: vm.valeursEcran.unocleintrh }),
            new FormulaireItem('poscleintsup', { default: vm.valeursEcran.poscleintsup }),
        ]);

        vm.formulaireSta = new Formulaire('LBL_DIV_STA', [
            new FormulaireItem('vacaccgra'),
            new FormulaireItem('vacaccpotgra')
        ]);

        vm.formulaireEnq = new Formulaire('LBL_DIV_ENQ', [
            new FormulaireItem('accdatenq', { largeur: 100 })
        ]);

        vm.vicMultiOccurrenceOptions = {
            navigatePage: cs0006Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    id: rowData.viccleint,
                    acccleint: vm.monoOccurrence.data.acccleint,
                };
            },
            actionsNouveaux: new Menu([
                new MenuItemForage(cs0006Route.NAME, () => {
                    return {
                        acccleint: vm.monoOccurrence.data.acccleint
                    };
                }, {
                    disabled: () => (!vm.monoOccurrence.data.$valeurs.flgcrevic)
                })
            ]),
            // Colonnes fixes à gauche
            colonnesFixesGauche: [
                { nom: 'eplnummat', largeur: 100 },
                { nom: 'eplnomprn', largeur: 262 }
            ],

            // Colonnes fixe à droite
            colonnesFixesDroite: [
                {
                    nom: 'flgdcs',
                    largeur: 100,
                    menu: [
                        new MenuItemForage(
                            'G_LBL_FONC_TRANS_DOCUMENT',
                            cs0007Route.NAME,
                            (data: any) => {
                                return {
                                    ecran: '00-2',
                                    id: data.trncleint_dcs,
                                    viccleint: data.viccleint
                                };
                            },
                            {
                                validation(data: any) {
                                    if (!data.trncleint_dcs && data.trnflgstaref_acc === 0) {
                                        return 'MSG_TRNFLGSTAREF_ACC';
                                    }
                                },
                                fonction: 'VIC.BOUDCS'
                            }
                        )
                    ]
                }
            ],
            colonnesCachees: [
                'trnflgstaref_acc'
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'lemcod', largeur: 105 },
                { nom: 'lemdsc', largeur: 262 },
                { nom: 'aftcod', largeur: 105 },
                { nom: 'aftdsc', largeur: 262 },
                { nom: 'unocod', largeur: 112 },
                { nom: 'unodscabr', largeur: 262 },
                { nom: 'litcod', largeur: 90 },
                { nom: 'litdsc', largeur: 262 },
                { nom: 'vicdscact', largeur: 262 },
                { nom: 'dalnumdoc', largeur: 90 },
                { nom: 'daldsc', largeur: 262 }
            ]
        };

        vm.ateMultiOccurrenceOptions = {
            colonnesVisibles: [
                { nom: 'atenomprn', largeur: 525 },
                { nom: 'ateflgact', largeur: 100 }
            ],
            valeursDefaut: {
                ateflgact: 1
            },
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire('LBL_BLC_ATE', [
                new FormulaireItem('atenomprn', {
                    required: true,
                    largeur: 65
                }),
                new FormulaireItem('ateflgact', {
                    largeur: 20
                })
            ])
        };

        vm.apeMultiOccurrenceOptions = {
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {
                    nom: 'apepartic',
                    largeur: 600,
                }
            ],
            formulaire: new Formulaire('LBL_BLC_ACC', [
                new FormulaireItem('apepartic', { required: true, largeur: 100 })
            ]),
        };

        vm.aenMultiOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_ACC', [
                new FormulaireItem('aenfait', { required: true, largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aencomtra'),
                new FormulaireItem('aenflgano')
            ]),
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                { nom: 'aenfait', largeur: 240 },
                { nom: 'aencomtra', largeur: 140 },
                { nom: 'aenflgano', largeur: 100 }
            ]
        };

        vm.ameMultiOccurrenceOptions = {
            fonctions: {
                editionRapide: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'amenum', largeur: 90 },
                { nom: 'amedsc', largeur: 425 },
                { nom: 'eplnomprn', largeur: 262 },
                { nom: 'amedatech', largeur: 110 },
                { nom: 'ameflgrej', largeur: 80 },
                { nom: 'ameflgrea', largeur: 87 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('amenum'),
                new FormulaireItem('amedsc'),
                new FormulaireItem('demcleint'),
                new FormulaireItem('amedatech'),
                new FormulaireItem('ameflgrej'),
                new FormulaireItem('ameflgrea')
            ]),
            colonnesEditionRapide: [
                { nom: 'amenum', largeur: 120 },
                { nom: 'amedsc', largeur: 300 },
                { nom: 'demcleint', largeur: 200 },
                { nom: 'amedatech', largeur: 180 },
                { nom: 'ameflgrej', largeur: 120 },
                { nom: 'ameflgrea', largeur: 120 }
            ]
        };

        vm.acaMultiOccurrenceOptions = {
            fonctions: {
                editionRapide: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'seccod', largeur: 131 },
                { nom: 'ssscod', largeur: 131 },
                { nom: 'scacod', largeur: 131 },
                { nom: 'scadsc', largeur: 328 },
                { nom: 'acapreaut', largeur: 225 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('seccleint', { resourceParams: (data: any) => ({ acccleint: data.$ancetre.acccleint }) }),
                new FormulaireItem('ssscleint', { resourceParams: (data: any) => ({ acccleint: data.$ancetre.acccleint }) }),
                new FormulaireItem('scacleint', { resourceParams: (data: any) => ({ acccleint: data.$ancetre.acccleint }) }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('acapreaut', {
                    largeur: 100, disabled: (data: any) => {
                        return !data.scaflgpreaut;
                    }
                })
            ]),
            colonnesEditionRapide: [
                { nom: 'seccleint', largeur: 200 },
                { nom: 'ssscleint', largeur: 200 },
                { nom: 'scacleint', largeur: 200 },
                { nom: 'acapreaut', largeur: 328 }
            ]
        }
    }
}
