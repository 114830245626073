import {IStateService} from 'angular-ui-router';
import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";


interface IPageCP0068 extends IComponentController {
    formulaireEnt: IFormulaire;
    etsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0068Controller($state: IStateService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0068 = this;
    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireEnt = new Formulaire('LBL_FORMULAIRE_ENTENTE', [
            new FormulaireItem('entcod', { largeur: 15}),
            new FormulaireItem('entdsc', { enableCount: true }),
            new FormulaireItem('entflgstu', {largeur: 15, default: 1})
        ], {
            largeurDefaut: 50
        });

        vm.etsMultiOccurrenceOptions = {
            bloc: 'ets',
            formulaire: new Formulaire([
                new FormulaireItem('tercleint'),
                new FormulaireItem('etsdatvig')
            ], {largeurDefaut: 50}),
            colonnesVisibles: [
                {nom: 'tercod'         , largeur: 89},
                {nom: 'terdsc'         , largeur: 200},
                {nom: 'vaetercat'      , largeur: 100},
                {nom: 'etsdatvig'      , largeur: 150}
            ]
        };

    }
}
