import {IStateParamsService} from 'angular-ui-router';
import IDialogService = angular.material.IDialogService;
import {IDialogAllStatus} from "../../../../../core/constants/dialog.constant";
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IComponentController} from 'angular';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageIn0050 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0050Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         $stateParams: IStateParamsService,
                                         DialogStatus: IDialogAllStatus,
                                         $mdDialog: IDialogService,
                                         MenuItemAction: IMenuItemActionClass)  {

    const vm: IPageIn0050 = this;


    $stateParams.fimcleint = $stateParams.id;
    vm.$onInit = $onInit;


    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            icon: 'add_shopping_cart',
            menus: [
                new MenuItemAction(
                    'G_LBL_BTN_EXECUTER',
                    'BOUEXE',
                    () => ({}),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction() {
                            $mdDialog.hide(DialogStatus.APPLIQUER);
                        }
                    }
                ),
                new MenuItemAction(
                    'G_LBL_BTN_ANNULER',
                    'BOUANN',
                    () => {return {};},
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction() {
                            $mdDialog.cancel();
                        }
                    }
                )
            ],
            fonctions: {
                suiviModification: false,
                boutonEnregistrerHaut: false
            }
        };

        // Mono : Fichier maitre
        vm.formulaireFim = new Formulaire('LBL_FIM', [
            new FormulaireItem('fimcod'),
            new FormulaireItem('fimdsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('typdsc'),
            new FormulaireGroupe('LBL_ORIGINE',
                new Formulaire([
                    new FormulaireItem('magcod'),
                    new FormulaireItem('magnom'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('malcleintori', {
                        largeur: 40,
                        resourceParams: () => {
                            return {
                                procleint: $stateParams.procleint
                            };
                        }

                    }),
                    new FormulaireItem('forcleintpro', {
                        largeur: 40,
                        resourceParams: () => {
                            return {
                                procleint: $stateParams.procleint
                            };
                        }
                    })
                ])
            ),
            new FormulaireGroupe('LBL_DESTINATION',
                new Formulaire([
                    new FormulaireItem('magcleintdes', {
                        largeur: 40
                    }),
                    new FormulaireItem('malcleintdes', {
                        largeur: 40,
                        resourceParams: (data:any) => {
                            return {
                                procleint: data.procleintdes
                            };
                        }
                    })
                ])
            ),
            new FormulaireItem('quantite'),
            new FormulaireItem('prixmoyen', { default: vm.valeursEcran.trsprxmoy }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('commentaire'),
        ]);
    }
}
