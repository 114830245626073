import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFilterDate } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
interface IPageCr1002 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    cceMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ICr1002Params {
    id: string;
    caccleint: number;
}

/* @ngInject */
export default function Cr1002Controller($stateParams: ICr1002Params,
    cr1003Route: IRoute,
    Formulaire: IFormulaireClass,
    dateFilter: IFilterDate,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Pagination: IPaginationClass) {
    const vm: IPageCr1002 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_INFOS', [
            new FormulaireItem('cclnumdoc', { largeur: 20, readonly: true }),
            new FormulaireItem('refnom', { largeur: 20, readonly: true }),
            new FormulaireItem('ccldat', { largeur: 20, readonly: true }),
            new FormulaireItemMenuItem(new MenuItemAction(
                'G_LBL_BTN_GENERER',
                'BOUGEN',
                () => ({}),
                {
                    iconButton: false,
                    saveFirst: true,
                    formulaireConfirmationAction: {
                        lblTitre: 'G_LBL_BTN_APPLIQUER',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('datdeb', { largeur: 25, default: dateFilter(new Date(), 'yyyy-MM-dd'), required: true }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('nbrocc', { required: true, largeur: 25 }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('vecvalcle', { largeur: 25, required: true }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('optmod', { largeur: 25, default: 'trimestrielle', direction: 'vertical' })
                        ], { largeurDefaut: 30 })
                    },
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }
            )),
            new FormulaireItemMenuItem(new MenuItemAction(
                'LBL_BTN_DET',
                'BOUDET',
                () => ({}),
                {
                    iconButton: false,
                    saveFirst: true,
                    fonction: 'BOUDET',
                    formulaireConfirmationAction: {
                        lblTitre: 'G_LBL_BTN_SUPPRIMER',
                        lblConfirm: 'G_LBL_BTN_SUPPRIMER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('btnsup', { readonly: true, largeur: 100 })
                        ], { largeurDefaut: 30 })
                    },
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }
            )),
        ], {
            largeurDefaut: 20
        })

    }

    // Cédule
    vm.cceMultiOccurrenceOptions = {
        bloc: 'cce',
        fonctions: {
            pagination: true,
            edition: (rowData: any) => (rowData.cacflgmod),
            supprime: (rowData: any) => (rowData.cacflgmod),
            boutonDupliquer: (rowData: any) => (rowData.cacflgmod)
        },
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                '',
                cr1003Route.NAME,
                (data: any) => ({
                    ccecleint: data.ccecleint
                }),
                {
                    icon: 'exit_to_app',
                    iconButton: true
                }
            )
        ]),
        formulaire: new Formulaire([
            new FormulaireItem('ccedatdue', { required: true }),
            new FormulaireItem('caccleint', { resourceParams: () => ({ cclcleint: $stateParams.id }), required: true }),
            new FormulaireItem('vecccetypfac', { required: true }),
            new FormulaireItem('cceflguni'),
            new FormulaireItem('vaeccetypcal', {default: 'MNT'}),
            new FormulaireItem('ccemntfac', { disabled: (rowData:any) => (rowData.vaeccetypcal === 'PRC') }),
            new FormulaireItem('cceprcfac', {disabled: (rowData:any) => (rowData.vaeccetypcal === 'MNT') }),
            new FormulaireItem('cceprcfaccon', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ccedscfac', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ccenumfacext', { largeur: 50 }),
            new FormulaireItem('ccemntfacext'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ccedatsigint'),
            new FormulaireItem('ccedatposorg'),
            new FormulaireItem('cceflgsuirapfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ccecom', { largeur: 50 })
        ], {
            largeurDefaut: 25
        }),
        colonnesCachees: ['cclcleint', 'caccleint', 'cacflgmod'],
        // Affichage des colonnes visibles
        colonnesFixesGauche: [
            { nom: 'ccedatdue', largeur: 140 },
            { nom: 'cacnumdocseq', largeur: 80 },
            { nom: 'cacann', largeur: 110 },
            { nom: 'cceprcfac', largeur: 110 },
            { nom: 'cceprcfaccon', largeur: 130 },
            { nom: 'ccemntfac', largeur: 160, total: true }
        ],
        colonnesVisibles: [
            { nom: 'vecccetypfac', largeur: 150 },
            { nom: 'vecdsc', largeur: 200 },
            { nom: 'cceflguni', largeur: 130 },
            { nom: 'sysfacnum', largeur: 150 },
            { nom: 'datcre', largeur: 130 },
            { nom: 'facdat', largeur: 130 },
            { nom: 'perfac', largeur: 130 },
            { nom: 'ccedscfac', largeur: 200 },
            { nom: 'ccenumfacext', largeur: 180 },
            { nom: 'ccemntfacext', largeur: 130 },
            { nom: 'ccedatsigint', largeur: 130 },
            { nom: 'ccedatposorg', largeur: 130 },
            { nom: 'ccecom', largeur: 180 },
            { nom: 'cceflgsuirapfin', largeur: 130 },
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 130 },
            { nom: 'unacod', largeur: 160 },
            { nom: 'unadscabr', largeur: 230 },
            { nom: 'intcodrsp', largeur: 250 },
            { nom: 'intnomrsp', largeur: 180 }
        ]
    };
}
