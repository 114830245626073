import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from 'angular';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';

interface IPageIn0008 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    proMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fftMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fqpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function In0008Controller(mc0046Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    MenuItemEcran: IMenuItemEcranClass) {

    const vm: IPageIn0008 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    // menu des boutons
    vm.btnMenus = [
        new MenuItemEcran(
            'LBL_IMPUTATION',
            mc0046Route.NAME,
            (data: any) => {
                return {
                    vaeusient: 'FIC',
                    uvicleintref: data.fimcleint
                };
            },
            {
                icon: 'view_list',
                iconButton: false,
                fonction: 'BOUIMP'
            }
        )
    ];

    // Mono : Fichier maitre
    vm.formulaireFim = new Formulaire('LBL_FIM', [
        new FormulaireItem('fimcod'),
        new FormulaireItem('fimdsc', { largeur: 60, enableCount: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('fimdsclng', { largeur: 100, enableCount: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('fimdatdeb'),
        new FormulaireItem('fimdatfin'),
        new FormulaireItem('forcleint'),
        new FormulaireGroupeAccordeon('LBL_COMPORTEMENT',
            new Formulaire([
                new FormulaireItem('vaesysinvdat'),                
                new FormulaireItem('vaesysinvqte', {default: true }),
                new FormulaireItem('vaesysinvfor'),
                new FormulaireItem('vaesysinvqteper')
            ]), { opened: true }),
        new FormulaireGroupeAccordeon('LBL_QUANTITE',
            new Formulaire([
                new FormulaireItem('fimqtemin', { largeur: 25 }),
                new FormulaireItem('fimqtemax', { largeur: 25 })
            ]), { opened: true })
    ], { largeurDefaut: 33.33 });

    // Mono : Struc
    vm.formulaireStruc = new Formulaire('LBL_STRUCTURE_FIM', [
        new FormulaireItem('st1cleint', { largeur: 25, hidden: !vm.valeursEcran.flgst1 }),
        new FormulaireItem('st2cleint', { largeur: 25, hidden: !vm.valeursEcran.flgst1 }),
        new FormulaireItem('st3cleint', { largeur: 25, hidden: !vm.valeursEcran.flgst1 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('st4cleint', { largeur: 25, hidden: !vm.valeursEcran.flgst1 }),
        new FormulaireItem('st5cleint', { largeur: 25, hidden: !vm.valeursEcran.flgst1 })
    ]);

    // Produits associés
    vm.proMultiOccurrenceOptions = {
        bloc: 'pro',
        fonctions: {
            suiviModification: false,
            pagination: true
        },
        colonnesVisibles: [
            { nom: 'magcod', largeur: 120 },
            { nom: 'magnom', largeur: 230 },
            { nom: 'procod', largeur: 140 },
            { nom: 'prodsc', largeur: 230 },
            { nom: 'fordsc', largeur: 230 }
        ]
    };

    // Format toléré
    vm.fftMultiOccurrenceOptions = {
        bloc: 'fft',
        colonnesVisibles: [
            { nom: 'forcod', largeur: 80 },
            { nom: 'fordsc', largeur: 120 },
            { nom: 'fftflgstu', largeur: 80 },
            { nom: 'fftflgach', largeur: 80 },
            { nom: 'fftflgsor', largeur: 80 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('forcleint', { largeur: 60 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fftflgstu', { largeur: 30 }),
            new FormulaireItem('fftflgach', { largeur: 30 }),
            new FormulaireItem('fftflgsor', { largeur: 30 })
        ])
    };

    // Quantité par période
    vm.fqpMultiOccurrenceOptions = {
        bloc: 'fqp',
        colonnesVisibles: [
            { nom: 'debdsc', largeur: 90 },
            { nom: 'findsc', largeur: 90 },
            { nom: 'fqpqtemin', largeur: 110 },
            { nom: 'fqpqtemax', largeur: 110 },
            { nom: 'fqpindsug', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaefqpperdeb'),
            new FormulaireItem('vaefqpperfin'),
            new FormulaireItem('fqpqtemin'),
            new FormulaireItem('fqpqtemax'),
            new FormulaireItem('fqpindsug')
        ], { largeurDefaut: 20 })
    };


}
