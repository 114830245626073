import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageMr0066 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    rttMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rttMultiOccurrence: IMultiOccurrence;
    cepMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cepMultiOccurrence: IMultiOccurrence;
    acpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ctaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ctaMultiOccurrence: IMultiOccurrence;
    cecMultiOccurrenceOptions: IMultiOccurrenceOptions;
    apaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0066Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Pagination: IPaginationClass,
    mr0069Route: IRoute,
    mr0008Route: IRoute,
    mr2020Route: IRoute,
    mr2021Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageMr0066 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const avisCep = new MenuItemForage(
        'LBL_COND_ADM',
        mr0008Route.NAME,
        (rowData: any) => ({
            id: rowData.apacleint,
            cleintref:  rowData.cepcleint,
            stamnetab:  'CEP',
            vaesystypavi: 'CEP',
            supprime: 1,
            typsai: 'JRS'
        }),
        {
            icon: 'exit_to_app',
            iconButton: true,
            class: 'ex-menu-item-button--secondaire'
        }
    );

    const cepModif = new MenuItemEcran(
        'LBL_CEP_MODIFICATION',
        mr2021Route.NAME,
        (rowData: any) => ({
            id: rowData.cepcleint
        }),
        {
            icon: 'exit_to_app',
            iconButton: true,
            class: 'ex-menu-item-button--secondaire',
            onClose: () => {vm.cepMultiOccurrence.fetchDataList();}
        }
    );

    const cepSpec = new MenuItemEcran(
        'LBL_CEP_SPECIAL',
        mr2020Route.NAME,
        (rowData: any) => ({
            id: rowData.cepcleint,
            cyrcleint: $stateParams.id
        }),
        {
            icon: 'exit_to_app',
            iconButton: true,
            class: 'ex-menu-item-button--secondaire',
            onClose: () => {vm.cepMultiOccurrence.fetchDataList();}
        }
    );

    vm.monoOccurrenceOptions = {
        pageRetourSuppression: mr0069Route.NAME,
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_CYR', [
            new FormulaireItem('cyrcod', { largeur: 25 }),
            new FormulaireItem('cyrdsc', { largeur: 75 }),
            new FormulaireItem('cyrcom', { largeur: 100, enableCount: true })
        ])
    };

    vm.acpMultiOccurrenceOptions = {
        resourceParams: () => ({ cepcleint: vm.cepMultiOccurrence.getDataActiveRow().cepcleint }),
        bloc: 'acp',
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        colonnesVisibles: [
            { nom: 'acpseqaff', largeur: 100 },
            { nom: 'seqeta', largeur: 87 },
            { nom: 'ecldsc', largeur: 236 },
            { nom: 'esydsc', largeur: 236 },
            { nom: 'acpflgsur', largeur: 75 },
            { nom: 'acpflgaccactpre', largeur: 100 },
            { nom: 'acpflgrepaff', largeur: 100 },
            { nom: 'cepseqetareppivaff', largeur: 87 },
            { nom: 'ecldscreppivaff', largeur: 236 }
        ],
        colonnesCachees: ['cepcleintcib', 'cepcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('acpseqaff', { largeur: 25 }),
            new FormulaireItem('cepcleintcib', { largeur: 25, nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('acpflgsur', { largeur: 25 }),
            new FormulaireItem('acpflgaccactpre', { largeur: 25 }),
            new FormulaireItemDivider('LBL_REP_CYC'),
            new FormulaireItem('acpflgrep', { largeur: 25 }),
            new FormulaireItem('cepcleintreppiv', { largeur: 25, nombreLibellesDetailsCacher: 1 }),
            new FormulaireItemSautDeColonne({ largeur: 50 }),
            new FormulaireItem('cepcleint', {
                largeur: 25,
                default: () => {
                    return vm.cepMultiOccurrence.getDataActiveRow().cepcleint;
                }
            }),
            new FormulaireItem('cyrcleint', { default: $stateParams.id })
        ])
    };

    vm.cepMultiOccurrenceOptions = {
        bloc: 'cep',
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 30, 50]
        }),
        colonnesVisibles: [
            { nom: 'cepflgcycbas', largeur: 80 },
            { nom: 'seqeta', largeur: 84 },
            { nom: 'ecldsceta', largeur: 200 },
            { nom: 'valdsc', largeur: 130 },
            { nom: 'esydsc', largeur: 130 },
            { nom: 'cepflgtrtcta', largeur: 100 },
            { nom: 'cepflgactaccecr', largeur: 90 },
            { nom: 'cepflgaccepl', largeur: 90 },
            { nom: 'cepflgstarefaff', largeur: 90 },
            { nom: 'cepflgstafinaff', largeur: 90 },
            { nom: 'cepflgstaferaff', largeur: 90 },
            { nom: 'cepflgstaannaff', largeur: 90 },
            { nom: 'flgapp', largeur: 120 },
            { nom: 'flgmod', largeur: 120, menu: [cepModif] },
            { nom: 'flgspe', largeur: 120, menu: [cepSpec] },
            { nom: 'flgavi', largeur: 120, menu: [avisCep] }
        ],
        colonnesCachees: ['apacleint'],
        formulaire: new Formulaire([
            new FormulaireItem('cepflgcycbas'),
            new FormulaireItem('cepseqeta'),
            new FormulaireItem('eclcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('valcleint', {
                resourceParams: () => ({
                    cyrcleint: $stateParams.id
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esycleint', {
                resourceParams: () => ({
                    cyrcleint: $stateParams.id
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cepflgtrtcta'),
            new FormulaireItem('cepflgactaccecr'),
            new FormulaireItem('cepflgaccepl'),
            new FormulaireItemDivider('LBL_DIV_STATUT'),
            new FormulaireItem('cepflgstaref'),
            new FormulaireItem('cepflgstafin'),
            new FormulaireItem('cepflgstafer'),
            new FormulaireItem('cepflgstaann'),
            new FormulaireItemDivider('LBL_DIV_APPROB'),
            new FormulaireItem('vaeceptypapp', {
                resourceParams: (data:any) => ({
                    cyrcleint: $stateParams.id,
                    cepflgcycbas: data.cepflgcycbas
                })
            }),
            new FormulaireItem('nircleint'),
            new FormulaireItem('rolcleint')
        ], { largeurDefaut: 25 }),
        multiOccurrenceOptionsEnfant: vm.acpMultiOccurrenceOptions
    };

    vm.rttMultiOccurrenceOptions = {
        bloc: 'rtt',
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            'ttccoddsc', 
            'tytcoddsc', 
            'ttccom',
            'vaetytreg_dsc'
        ],
        colonnesCachees: [
            'cyrcleint', 
            'tytcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ttccleint', { nombreLibellesDetailsCacher: 1, largeur: 100 })
        ])
    };

    vm.ctaMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'ctaseqaff', largeur: 100 },
            { nom: 'ctadsc', largeur: 262 },
            { nom: 'ecrcod', largeur: 110 },
            { nom: 'ecrdsc', largeur: 262 },
            { nom: 'ctacom', largeur: 262 },
            { nom: 'valdsc', largeur: 164 },
            { nom: 'esydsc', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ctaseqaff', { largeur: 33.33 }),
            new FormulaireItem('ctadsc', { largeur: 66.66 }),
            new FormulaireItem('ecrcleint', { largeur: 33.33 }),
            new FormulaireItem('ctacom', { largeur: 66.66, enableCount: true }),
            new FormulaireItem('valcleint', {
                largeur: 33.33,
                resourceParams: () => ({
                    cyrcleint: $stateParams.id
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esycleint', {
                largeur: 33.33,
                resourceParams: () => ({
                    cyrcleint: $stateParams.id
                }),
                nombreLibellesDetailsCacher: 1
            })
        ])
    };

    vm.cecMultiOccurrenceOptions = {
        bloc: 'cec',
        fonctions: {
            suiviModification: false
        },
        colonnesFixesGauche: [
            { nom: 'cepseqeta', largeur: 100 },
            { nom: 'ecldsceta', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'evsdsc', largeur: 200 },
            { nom: 'vacvalcle', largeur: 140 },
            { nom: 'vacdsc', largeur: 200 },
            { nom: 'sql', largeur: 250 },
            { nom: 'sqlabr', largeur: 250 },
            { nom: 'sqlmnt', largeur: 250 }
        ]
    };

    vm.apaMultiOccurrenceOptions = {
        bloc: 'apa',
        fonctions: {
            suiviModification: false
        },
        colonnesFixesGauche: [
            { nom: 'cepseqetaaff', largeur: 100 },
            { nom: 'ecldsceta', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'cepavityp', largeur: 160 },
            { nom: 'vaedsc', largeur: 150 },
            { nom: 'stadsc', largeur: 150 },
            { nom: 'apatxt', largeur: 250 },
            { nom: 'sqlinidsc', largeur: 250 },
            { nom: 'sqlcondsc', largeur: 250 }
        ]
    };
}
