import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTH0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0026Controller(Menu: IMenuClass) {
    const vm: IPageTH0026 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'gfrcod', largeur: 200},
            {nom: 'gfrdsc', largeur: 200},
            {nom: 'pfcdsc', largeur: 200},
            {nom: 'pfccom', largeur: 200}
        ]
    };
}
