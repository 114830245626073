import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import AxesAffichage from '../../../../../core/classes/axe-affichage.class';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageCH0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface ICh0007Params {
    itpcleint: string;
}

/* @ngInject */
export default function Ch0007Controller(ch0008Route: IRoute,
    ch0009Route: IRoute,
    ch0010Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: ICh0007Params,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageCH0007 = this;

    const forage_appel = new MenuItemEcran(
        'LBL_APPEL',
        ch0009Route.NAME,
        (rowData: any) => ({ hadcleint: rowData.hadcleint }), {
        icon: 'launch',
        iconButton: true,
        onClose: () => vm.multiOccurrence.fetchDataList()
    }
    );

    const forage_dispo = new MenuItemEcran(
        'LBL_DISPO',
        ch0010Route.NAME,
        (rowData: any) => ({ hadcleint: rowData.hadcleint }), {
        icon: 'launch',
        iconButton: true,
        onClose: () => vm.multiOccurrence.fetchDataList()
    }
    );

    vm.multiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'BTN_NOU',
                ch0008Route.NAME,
                (rowData: any) => ({
                    id: rowData.hadcleint,
                    itpcleint: $stateParams.itpcleint
                }),
                {
                    icon: 'launch',
                    iconButton: true,
                    onClose: () => vm.multiOccurrence.fetchDataList()
                })
        ]),
        actionsNouveaux: new Menu([
            new MenuItemEcran(
                'BTN_NOU',
                ch0008Route.NAME,
                (data: any) => ({
                    itpcleint: $stateParams.itpcleint
                }),
                {
                    icon: '',
                    iconButton: false,
                    onClose: () => vm.multiOccurrence.fetchDataList()
                })
        ]),
        fonctions: {
            axesAffichageVisible: true
        },
        axesAffichage: {
            axes: [
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.LIST,
                    icon: 'view_list',
                    lblNom: 'G_LBL_LIST'
                },
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.CALENDAR,
                    icon: 'calendar_today',
                    lblNom: 'G_LBL_CALENDAR',
                    options: {
                        description: ['hredebfin', 'haddsc'],
                        colonneDateDebut: 'hadhredeb',
                        colonneDateFin: 'hadhrefin',
                        colonneHeureDebut: 'hadhredeb',
                        colonneHeureFin: 'hadhrefin',
                        navigatePage: ch0008Route.NAME,
                        navigateParams: (rowData: any) => {
                            return {
                                id: rowData.hadcleint,
                                itpcleint: $stateParams.itpcleint
                            };
                        }
                    }
                }
            ]
        },

        criteresSuggeres: new Formulaire([
            new FormulaireItem('itpcleint', {
                required: true,
                readonly: true,
                ignoreEtat: true,
                default: $stateParams.itpcleint
            }),
            new FormulaireItem('t_itycleint', {
                resourceParams: (data: any) => {
                    return {
                        itpcleint: $stateParams.itpcleint
                    };
                },
                ignoreEtat: true
            }),
            new FormulaireItem('t_etqcleint', {
                ignoreEtat: true,
            }),
            new FormulaireItem('t_statut', {
                required: true,
                ignoreEtat: true,
                default: 'T'
            }),
        ]),
        classItems: {
            row: (data: any) => data.flg_rouge ? 'ex-ligne-erreur'
                : data.flg_orange ? 'ex-ligne-en-attente'
                    : data.flg_vert ? 'ex-ligne-en-cours' : ''
        },
        colonnesVisibles: [
            { nom: 'etqcod', largeur: 100, hidden: (data: any) => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_etqcleint },
            { nom: 'etqdsc', largeur: 240, hidden: (data: any) => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_etqcleint },
            { nom: 'horaire', largeur: 200 },
            { nom: 'hadqte', largeur: 100 },
            { nom: 'haddsc', largeur: 200 },
            { nom: 'dathretom', largeur: 160 },
            { nom: 'hadnbrdsp', largeur: 110, menu: [forage_dispo] },
            { nom: 'hadnbrapp', largeur: 100, menu: [forage_appel] },
            { nom: 'hadnbrass', largeur: 100 }
        ],
        colonnesCachees: [
            'hadcleint',
            'itpcleint',
            'itycleint',
            'hadhredeb',
            'hadhrefin',
            'flg_rouge',
            'flg_orange',
            'flg_vert'
        ]
    };
}
