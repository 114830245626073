import {module} from 'angular';
import DialogDonneesEnregistrementController from './dialog-donnees-enregistrement.controller';
import {IDialogClass} from '../../../../core/services/dialog.service';

export default module('app.dialogs.dialog-donnees-enregistrement', [
    'core.services.dialog'
]).factory('DialogDonneesEnregistrement', DialogDonneesEnregistrementFactory);

/* @ngInject */
function DialogDonneesEnregistrementFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogDonneesEnregistrementController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-donnees-enregistrement.html'),
        locals: {
            icon: 'settings_applications',
            lblTitre: 'G_LBL_OUTILS_DONNEES_ENR',
            lblCancel: 'G_LBL_BTN_OK'
        }
    });
}
