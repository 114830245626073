import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRR0098 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    aftDetMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0098Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0098 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('unpcleint', {
                required: true
            }),
            new FormulaireItem('ppacleint', {
                required: true
            }),
            new FormulaireItem('urgcleint', {
                required: true
            }),
            new FormulaireItem('typass', {
                default: 1,
                required: true
            }),
            new FormulaireItem('typannser', {
                default: 1,
                required: true
            }),
            new FormulaireItem('flgassfut', {
                required: true
            }),
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 250}
        ],
        colonnesVisibles: [
            {nom: 'atphresemm2',
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemm2
            },
            {nom: 'atphresemm1',
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemm1
            },
            {nom: 'atphresemref',
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodref
            },
            {nom: 'atphresemp1',
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemp1
            },
            {nom: 'atphresemp2',
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemp2
            },
            {nom: 'atpadhdeb', largeur: 100},
            {nom: 'atpadhfin', largeur: 100},
            {nom: 'lemcod', largeur: 100},
            {nom: 'lemdatdeb', largeur: 100},
            {nom: 'lemdatfin', largeur: 100},
            {nom: 'afttyp', largeur: 300}
        ]
    };

    // Détail des affectation
    vm.aftDetMultiOccurrenceOptions = {
        colonnesFixesGauche:[
            {nom: 'aftcod'      , largeur: 100},
            {nom: 'aftdatdeb'   , largeur: 100},
            {nom: 'aftdatfin'   , largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'rrhdatdeb'   , largeur: 130},
            {nom: 'atphresemm2',
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemm2
            },
            {nom: 'atphresemm1' ,
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemm1
            },
            {nom: 'atphresemref',
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodref
            },
            {nom: 'atphresemp1' ,
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemp1
            },
            {nom: 'atphresemp2' ,
                largeur: 90,
                replacedValue: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacodsemp2
            },
            {nom: 'tafcod'      , largeur: 150},
            {nom: 'tafdsc'      , largeur: 300},
            {nom: 'temcod'      , largeur: 130},
            {nom: 'temdsc'      , largeur: 300}
        ]
    };
}
