import {IComponentOptions, module} from 'angular';

import PageController from './ex-panneau-page.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-panneau-page', []).component('exPanneauPage', PanneauPageComponent());

function PanneauPageComponent(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.OPENED,
            Binding.MULTI_OCCURRENCE,
            {
                hasPanneauPage: '=?hasPanneauPageOut',
                nbColsAffichable: '<?'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '?^^exMonoOccurrenceEcran',
            multiOccurrenceAnalyseCtrl: '?^^exMultiOccurrenceAnalyse',
            multiOccurrenceMaitreDetailEcranCtrl: '?^^exMultiOccurrenceMaitreDetailEcran',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: PageController,
        controllerAs: 'vm',
        template: require('./ex-panneau-page.html')
    };
}
