import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0018Controller(mc0093Route: IRoute,
                                         mc0089Route: IRoute,
                                         cb0010Route: IRoute,
                                         pr0025Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMc0018 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'BTN_CPT_BLOC',
                mc0093Route.NAME,
                (data:any) => ({
                    el1cleint: data.el1cleint
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemEcran(
                'BTN_CPT_INF',
                pr0025Route.NAME,
                (data:any) => ({
                    id: data.syscleintref
                }),
                {
                    icon: 'launch',
                    iconButton: false,
                    hidden: (data: any) => !data.syscleintref
                }
            ),
            new MenuItemEcran(
                'BTN_ADM',
                mc0089Route.NAME,
                (data:any) => ({
                    elgcleintref: data.el1cleint,
                    vaesyselg: 'EL1'
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'BTN_ANA',
                cb0010Route.NAME,
                (data: any) => ({
                    id: data.el1cleint,
                    niveau: 1
                }),
                {
                    icon: 'equalizer',
                    iconButton: false,
                    fonction: 'BTNANA'
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'el1cod'             , largeur: 120},
            {nom: 'el1dsc'             , largeur: 300},
            {nom: 'el1dscabr'          , largeur: 200},
            {nom: 'el1datdeb'          , largeur: 130},
            {nom: 'el1datfin'          , largeur: 130},
            {nom: 'el1flgaut'          , largeur: 120},
            {nom: 'inddsc'             , largeur: 200},
            {nom: 'el1prcdep'          , largeur: 126},
            {nom: 'el1mntdep'          , largeur: 126},
            {nom: 'vaesysnivappctr_dsc', largeur: 130}
        ],
        colonnesCachees: [
            'el1cleint',
            'syscleintref'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('el1cod'),
            new FormulaireItem('el1dsc'),
            new FormulaireItem('el1dscabr'),
            new FormulaireItem('el1datdeb'),
            new FormulaireItem('el1datfin'),
            new FormulaireItem('el1flgaut'),
            new FormulaireItem('indcleint'),
            new FormulaireItemDivider('LBL_DIV_DEP'),
            new FormulaireItem('el1prcdep'),
            new FormulaireItem('el1mntdep'),
            new FormulaireItem('vaesysnivappctr')
        ], {largeurDefaut: 33})
    };
}
