import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePb0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         pb0002Route: IRoute) {
    const vm: IPagePb0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true,
            supprime: () => ecranDetails.flgcen
        },
        navigatePage: pb0002Route.NAME,
        navigateParams: (data: any) => {
            return {
                pvecleint: data.pvecleint
            };
        },        
        criteresSuggeres: new Formulaire([
            new FormulaireItem('criintcleint', { required: true }),            
            new FormulaireItem('cripveann', { required: true }),
            new FormulaireItem('cripbscleint'),
            new FormulaireItem('criflgequ', { largeur: 50 })
        ]),
        colonnesCachees: ['msgerreur', 'msgerreuravr', 'pvecleint'],
        colonnesFixesGauche:[
            { nom: 'pveann', largeur: 100 },
            { nom: 'intcod', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'intnom', largeur: 236 },
            { nom: 'pvenumver', largeur: 120 },
            { nom: 'pvedsc', largeur: 126 },
            { nom: 'peccod', largeur: 126 },
            { nom: 'pvedatcre', largeur: 126 },
            { nom: 'pvedatmaj', largeur: 126 },
            { nom: 'pbscod', largeur: 140 },
            { nom: 'pbsdsc', largeur: 250 }
        ],
        colonnesFixesDroite:[
            { nom: 'nbrno', largeur: 126 },
            { nom: 'pveflgaut', largeur: 126 },
            { nom: 'flgerr', largeur: 126 },
            { nom: 'flgavr', largeur: 120 }
        ]
    };
}
