import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCr2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr2000Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    cr0007Route: IRoute,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCr2000 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datdeb'),
            new FormulaireItem('datfin'),
            new FormulaireItem('indcar', { default: 'N' })
        ]),
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 120 },
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 80 },
            {
                nom: 'docaffsysfacnum01',
                largeur: 120,
                menu: [
                    new MenuItemForage(
                        'G_LBL_FONC_TRANS_DOCUMENT',
                        cr0007Route.NAME,
                        (rowData: any) => {
                            return {
                                id: rowData.doccleint
                            };
                        }
                    )
                ]
            },
            { nom: 'docaffnumref01', largeur: 150 },
            { nom: 'docaffdat01', largeur: 126 },
            { nom: 'dsrmntfac', largeur: 130 },
            { nom: 'dsrmntrec', largeur: 130 }
        ],
        colonnesFixesDroite: [
            { nom: 'dsrmntsld', largeur: 130 }
        ]
    };
}
