import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMR0298 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;

}
/* @ngInject */
export default function Mr0298Controller(Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass) {

    const vm: IPageMR0298 = this;


    vm.multiOccurrenceOptions = {

        criteresSuggeres: new Formulaire([
            new FormulaireItem('cqocleint'),
            new FormulaireItem('cqpcleint'),
            new FormulaireItem('cqacleint'),
            new FormulaireItem('cqzcleint'),
            new FormulaireItem('cqscleint'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('cqucleint'),
            new FormulaireItem('local', {readonly: true})

        ]),

        colonnesFixesGauche: [
            {nom: 'cqocod'      , largeur: 75},
            {nom: 'cqpcod'      , largeur: 75},
            {nom: 'cqacod'      , largeur: 91},
            {nom: 'cqzcod'      , largeur: 75},
            {nom: 'cqscod'      , largeur: 80},
            {nom: 'cqucod'      , largeur: 91},
            {nom: 'cqulocsyndic', largeur: 75},
            {nom: 'cqx_datetaux', largeur: 100}
        ],

        colonnesVisibles: [
            {nom: 'cqx_datemajtaux'             , largeur: 100},
            {nom: 'cqx_txsalairehorregulier'    , largeur: 90},
            {nom: 'cqx_txsalairehordemi'        , largeur: 90},
            {nom: 'cqx_txsalairehordouble'      , largeur: 90},
            {nom: 'cqx_avsocretraitepsalarie'   , largeur: 105},
            {nom: 'cqx_avsocretraitepempr'      , largeur: 130},
            {nom: 'cqx_avsocassurpempr'         , largeur: 130},
            {nom: 'cqx_avsoctotpartempr'        , largeur: 100},
            {nom: 'cqx_avsoctotal'              , largeur: 125},
            {nom: 'cqx_avantimposable'          , largeur: 105},
            {nom: 'cqx_fondsqualif'             , largeur: 115},
            {nom: 'cqx_fondsformation'          , largeur: 95},
            {nom: 'cqx_equipsecurite'           , largeur: 115},
            {nom: 'cqx_contribsectorielle'      , largeur: 115},
            {nom: 'cqx_taxassurance'            , largeur: 100},
            {nom: 'cqx_congesjoursferies'       , largeur: 140},
            {nom: 'cqx_fondsindemnisation'      , largeur: 130},
            {nom: 'cqx_fraisparticipvolsalarie' , largeur: 90},
            {nom: 'cqx_fraisparticipvolempr'    , largeur: 100},
            {nom: 'cqx_prelevpartsalarie'       , largeur: 90},
            {nom: 'cqx_prelevpartempr'          , largeur: 100},
            {nom: 'cqx_prelevminimum'           , largeur: 95},
            {nom: 'cqx_fondspatronalhoraire'    , largeur: 120},
            {nom: 'cqx_fondspatronalminimum'    , largeur: 130},
            {nom: 'cqx_fondspatronalannuel'     , largeur: 130},
            {nom: 'cqx_tps'                     , largeur: 100},
            {nom: 'cqx_tvq'                     , largeur: 100},
            {nom: 'cqx_cotsyndmnthorregulier'   , largeur: 130},
            {nom: 'cqx_cotsyndmnthordemi'       , largeur: 120},
            {nom: 'cqx_cotsyndmnthordouble'     , largeur: 135},
            {nom: 'cqx_cotsyndmntsemaine'       , largeur: 110},
            {nom: 'cqx_cotsyndmntmois'          , largeur: 90},
            {nom: 'cqx_cotsyndpourcsalbrut'     , largeur: 120},
            {nom: 'cqx_cotsyndautresfrais'      , largeur: 120},
            {nom: 'cqx_cotsyndremarque'         , largeur: 250},
            {nom: 'cqodsc'                      , largeur: 250},
            {nom: 'cqpdsc'                      , largeur: 250},
            {nom: 'cqadsc'                      , largeur: 250},
            {nom: 'cqzdsc'                      , largeur: 250},
            {nom: 'cqsdsc'                      , largeur: 250},
            {nom: 'cqudsc'                      , largeur: 250}
        ]

    };

}
