import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPagePf0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}


interface IPf0016Params {
    rbucleintpar: string;
    unocleintpar: string;
}
/* @ngInject */
export default function Pf0016Controller(pf0015Route: IRoute,
                                         pf0014Route: IRoute,
                                         $stateParams: IPf0016Params,
                                         Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Operateur: IOperateurService,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass ) {
    const vm: IPagePf0016 = this;

    const filtresParametres = [];
    let criteresSuggeres;

    if (!$stateParams.rbucleintpar || !$stateParams.unocleintpar) {
        criteresSuggeres = new Formulaire([
            new FormulaireItem('rbucleintcri', {
                required: true,
                default: $stateParams.rbucleintpar,
                hidden: (data) => data.$params.rbucleintpar
            }),
            new FormulaireItem('unocleintcri', {
                default: $stateParams.unocleintpar,
                hidden: (data) => data.$params.unocleintpar
            })
        ])
    }

    if ($stateParams.rbucleintpar) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'rbucleintpar',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.rbucleintpar,
                    affichage: true
                },
                {
                    colonne: 'unocleintpar',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.unocleintpar,
                    affichage: true
                }
            ]
        );
    }

    vm.multiOccurrenceOptions = {
        filtresParametres,
        criteresSuggeres,
        fonctions: {
            criteresSuggeresVisibles: !$stateParams.rbucleintpar,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_DET_UNO',
                pf0015Route.NAME,
                (data: any) => ({
                    ecran: '01-2',
                    rbucleintpar: data.rbucleint,
                    cabcleintpar: data.cabcleint,
                    unocleintrh: vm.multiOccurrence.etatSelected.criteresSuggeresData.unocleintcri
                }),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'LBL_DET_EMP',
                pf0014Route.NAME,
                (data: any) => ({
                    axe: 'EMP',
                    rbucleint: data.rbucleint,
                    cabcleint: data.cabcleint,
                    unocleintrh: vm.multiOccurrence.etatSelected.criteresSuggeresData.unocleintcri
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),
        colonnesCachees: [
            'rbuannfin',
            'rbucleint',
            'cabcleint'
        ],
        colonnesFixesGauche: [
            {nom: 'cabcod', largeur: 175},
            {nom: 'cabdsc', largeur: 250}
        ],
        colonnesVisibles: [
            {nom: 'nbremp', largeur: 120, total: true},
            {nom: 'mntbdg', largeur: 120, total: true},
            {nom: 'mntacc', largeur: 120, total: true},
            {nom: 'mntreel', largeur: 120, total: true},
            {nom: 'mntdispo', largeur: 120, total: true},
            {nom: 'salaire', largeur: 125, total: true},
            {nom: 'durhtt', largeur: 120, total: true},
            {nom: 'durhht', largeur: 120, total: true},
            {nom: 'durjtt', largeur: 120, total: true},
            {nom: 'durjht', largeur: 120, total: true}
        ]
    };
}
