import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePD0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0009Controller(Menu: IMenuClass) {
    const vm: IPagePD0009 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'raicod', largeur: 200},
            {nom: 'raidsc', largeur: 200},
            {nom: 'vaesyspdatyp_dsc', largeur: 200},
            {nom: 'vaeraitypsrc_dsc', largeur: 200},
            {nom: 'raiindact', largeur: 200},
            {nom: 'rainumref', largeur: 200},
            {nom: 'raijrsdemvie', largeur: 200},
            {nom: 'raiqtelimeli', largeur: 200},
            {nom: 'raiqtelimpos', largeur: 200},
            {nom: 'raiqtelimact', largeur: 200},
            {nom: 'raiactreel', largeur: 200},
            {nom: 'raidatdeb', largeur: 200},
            {nom: 'raidatfin', largeur: 200}
        ]
    };
}
