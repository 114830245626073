import { IComponentController } from 'angular';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IFormulaireGroupeConditionnelClass } from "../../../../../core/services/formulaire/formulaire-groupe-conditionnel.service";
import { IFormulaireTemplateSlotClass } from "../../../../../core/services/formulaire/formulaire-template-slot.service";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePf0002 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireDefaut: IFormulaire;
    formulaireSecurite: IFormulaire;
    formulairePortail: IFormulaire;
    tmiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tcdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tytcleint: number;
}

interface IPf0002Params {
    id: string;
    tytcleint: string;
}

/* @ngInject */
export default function Pf0002Controller($stateParams: IPf0002Params,
    pf0018Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPagePf0002 = this;

    // Forage vers la règle d'admissibilité
    const forageSua = new MenuItemForage(
        'LBL_FOR_IEX',
        pf0018Route.NAME,
        (data: any) => {
            return {
                id: data.suacleint
            };
        },
        { fonction: 'BOUREG' }
    );

    vm.tytcleint = Number($stateParams.tytcleint)

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonDupliquer: false
            },
            formulaire: new Formulaire(vm.tytcleint === 58 ? 'LBL_BLC_ACT' : 'LBL_BLC_REM', [
                new FormulaireItem('tpccod', { largeur: 50 }),
                new FormulaireItem('tpcdsc', { largeur: 50 }),
                new FormulaireItem('vaetpctypsys', { largeur: 50 }),
                new FormulaireItem('ttccleint', {
                    largeur: 50, nombreLibellesDetailsCacher: 1,
                    resourceParams: () => ({ tytcleint: vm.tytcleint })
                }),
                new FormulaireItem('ttccleintins', { largeur: 50, nombreLibellesDetailsCacher: 1 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tpctyptemps', { largeur: 50 }),
                new FormulaireItem('tpcflgdep', { largeur: 25 }),
                new FormulaireItem('tpcflgmaxemp', { largeur: 25 }),
                new FormulaireItemDivider('LBL_DIV_ADM'),
                new FormulaireItem('tpcmsgadm', { largeur: 100 })
            ])
        }

        vm.formulaireDefaut = new Formulaire('LBL_BLC_DEF', [
            new FormulaireItem('piscleint', { largeur: 100 }),
            new FormulaireItem('cabcleint', { largeur: 100 }),
            new FormulaireItem('vaedpmexo', { largeur: 100 })
        ]);

        vm.formulaireSecurite = new Formulaire('LBL_BLC_SEC', [
            new FormulaireItem('rolcleint', { largeur: 100 })
        ]);
        if (vm.tytcleint === 59) {
            vm.formulairePortail = new Formulaire('LBL_BLC_POR', [
                new FormulaireGroupeConditionnel('tpcflgpor', '', new Formulaire([
                    new FormulaireItem('tpccom', { largeur: 100 }),
                    new FormulaireItem('tpcflglieu'),
                    new FormulaireItem('tpcflglien'),
                    new FormulaireItem('tpcflghre'),
                    new FormulaireItem('tpcflgjrs'),
                    new FormulaireItem('tpcflgdurobl'),
                    new FormulaireItem('tpcflgint'),
                    new FormulaireItem('tpcflgpis'),
                    new FormulaireItem('tpcflgpisaut'),
                    new FormulaireItem('tpcflgact'),
                    new FormulaireItem('tpcflgcomobl'),
                    new FormulaireItem('tpcflgpij'),
                    new FormulaireItem('tpcflgpijobl'),
                    new FormulaireGroupeAccordeon('LBL_DIV_EXO', new Formulaire([
                        new FormulaireItem('tpcflgses'),
                        new FormulaireItem('tpcflgsem'),
                        new FormulaireItem('tpcflgpro'),
                        new FormulaireItem('tpcflgniv'),
                        new FormulaireItem('tpcflglib'),
                        new FormulaireItem('tpcflgcours'),
                        new FormulaireItem('tpcflgdependant'),
                        new FormulaireItem('tpcflgdem'),
                    ], { largeurDefaut: 33 }
                    ), { hidden: (data: any) => data.vaetpctypsys !== 'EXO' }),
                    new FormulaireGroupeAccordeon('LBL_DIV_FOR', new Formulaire([
                        new FormulaireGroupeConditionnel('tpcflgfor', '', new Formulaire([
                            new FormulaireItem('tpccomfor'),
                            new FormulaireItem('tpccomforg1'),
                            new FormulaireItem('tpccomforg2'),
                        ], { largeurDefaut: 100 }), { switch: true })
                    ])),
                    new FormulaireTemplateSlot('blocTcd')
                ],
                    { largeurDefaut: 33 }
                ), { switch: true }),
            ]);
        } else {
            vm.formulairePortail = new Formulaire('LBL_BLC_POR', [
                new FormulaireItem("tpcflgpor", { switch: true })
            ])
        }

        // Règle d'admissibilité
        vm.tmiMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'tmi',
            colonnesVisibles: [
                { nom: 'suacod', largeur: 105, menu: [forageSua] },
                { nom: 'suadsc', largeur: 295 },
                { nom: 'suadatdeb', largeur: 100 },
                { nom: 'suadatfin', largeur: 100 },
                { nom: 'suaflgact', largeur: 100 },
                { nom: 'suacom', largeur: 295 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('suacleint', { largeur: 75 })
            ]),
            colonnesCachees: [
                'suacleint'
            ]
        };

        // Type de dépense
        vm.tcdMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'tcd',
            colonnesVisibles: [
                { nom: 'tdscod', largeur: 100 },
                { nom: 'tdsdsc', largeur: 200 },
                { nom: 'tcdflgaff', largeur: 100 },
                { nom: 'tcdflgsaimntdem', largeur: 100 },
                { nom: 'tcdflgsaimntree', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tdscleint', { largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tcdflgaff'),
                new FormulaireItem('tcdflgsaimntdem'),
                new FormulaireItem('tcdflgsaimntree')
            ])
        };
    }
}
