import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageIn0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0004Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    notificationHandler: INotificationHandler) {
    const vm: IPageIn0004 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    if (vm.valeursEcran.mesina) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesina
        });
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'st1cod', largeur: 110 },
            { nom: 'st2cod', largeur: 110 },
            { nom: 'st3cod', largeur: 110 },
            { nom: 'st3dsc', largeur: 231 }
        ],
        colonnesVisibles: [
            { nom: 'st3dscabr', largeur: 231 },
            { nom: 'st1dscabrx', largeur: 231 },
            { nom: 'st2dscabrx', largeur: 231 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('st1coddsc', { readonly: true }),
            new FormulaireItem('st2cleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('st3cod'),
            new FormulaireItem('st3dsc'),
            new FormulaireItem('st3dscabr'),

        ], { largeurDefaut: 33.33 })
    };
}
