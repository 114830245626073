import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageDO0057 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0057Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDO0057 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 200},
            {nom: 'rglcod', largeur: 120},
            {nom: 'rgldscabr', largeur: 200},
            {nom: 'rdddatvig', largeur: 110},
            {nom: 'vacrgpcod', largeur: 100},
            {nom: 'rdpflgdim', largeur: 61},
            {nom: 'rdpflglun', largeur: 60},
            {nom: 'rdpflgmar', largeur: 60},
            {nom: 'rdpflgmer', largeur: 60},
            {nom: 'rdpflgjeu', largeur: 60},
            {nom: 'rdpflgven', largeur: 60},
            {nom: 'rdpflgsam', largeur: 64}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vue__demcleint', {required: true, largeur: 50, modifAllow: false}),
            new FormulaireItem('vue__rglcleint', {required: true, largeur: 50,modifAllow: false}),
            new FormulaireItem('rddcleint', {required: true, largeur: 50, modifAllow: false}),
            new FormulaireItem('vacrgpcod', {required: true, largeur: 50}),
            new FormulaireItem('rdpflgdim'),
            new FormulaireItem('rdpflglun'),
            new FormulaireItem('rdpflgmar'),
            new FormulaireItem('rdpflgmer'),
            new FormulaireItem('rdpflgjeu'),
            new FormulaireItem('rdpflgven'),
            new FormulaireItem('rdpflgsam'),
        ], {largeurDefaut: 14.28}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
