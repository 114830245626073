import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageTF0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Tf0027Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTF0027 = this;
    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('tfhann', {
                required: true,
                largeur: 50
            }),
            new FormulaireItem('fpncleint', {
                required: true,
                largeur: 50,
                resourceParams: (data: any) => {
                    return {
                        tfhann: data.tfhann
                    };
                }
            }),
            new FormulaireItem('typaff', {
                default: 1
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'efacod', largeur: 100 }
        ],
        colonnesCachees: [
            'tfblib1',
            'tfblib2',
            'tfblib3',
            'tfblib4',
            'tfblib5',
            'tfblib6',
            'tfblib7',
            'tfblib8',
            'tfblib9',
            'tfblib10',
            'tfblib11',
            'tfblib12',
            'tfblib13',
            'tfblib14',
            'tfblib15',
            'tfblib16',
            'tfblib17',
            'tfblib18',
            'tfblib19',
            'tfblib20',
            'tfblib21',
            'tfblib22',
            'tfblib23',
            'tfblib24',
            'tfblib25',
            'tfblib26',
            'tfblib27',
            'tfblib28',
            'tfblib29',
            'tfblib30',
            'tfblib31',
            'tfblib32',
            'tfblib33',
            'tfblib34',
            'tfblib35',
            'tfblib36',
            'tfblib37',
            'tfblib38',
            'tfblib39',
            'tfblib40',
            'tfblib41',
            'tfblib42',
            'tfblib43',
            'tfblib44',
            'tfblib45',
            'tfblib46',
            'tfblib47',
            'tfblib48',
            'tfblib49',
            'tfblib50',
            'tfblib51',
            'tfblib52',
            'tfblib53'
        ],
        colonnesVisibles: [
            { nom: 'efanomprn', largeur: 200 },
            { nom: 'efaflgdaa', largeur: 100 },
            { nom: 'tfbval01', largeur: 141, replacedValue: () => getParams(1), hidden: () => getParams(1) === null },
            { nom: 'tfbval02', largeur: 141, replacedValue: () => getParams(2), hidden: () => getParams(2) === null },
            { nom: 'tfbval03', largeur: 141, replacedValue: () => getParams(3), hidden: () => getParams(3) === null },
            { nom: 'tfbval04', largeur: 141, replacedValue: () => getParams(4), hidden: () => getParams(4) === null },
            { nom: 'tfbval05', largeur: 141, replacedValue: () => getParams(5), hidden: () => getParams(5) === null },
            { nom: 'tfbval06', largeur: 141, replacedValue: () => getParams(6), hidden: () => getParams(6) === null },
            { nom: 'tfbval07', largeur: 141, replacedValue: () => getParams(7), hidden: () => getParams(7) === null },
            { nom: 'tfbval08', largeur: 141, replacedValue: () => getParams(8), hidden: () => getParams(8) === null },
            { nom: 'tfbval09', largeur: 141, replacedValue: () => getParams(9), hidden: () => getParams(9) === null },
            { nom: 'tfbval10', largeur: 141, replacedValue: () => getParams(10), hidden: () => getParams(10) === null },
            { nom: 'tfbval11', largeur: 141, replacedValue: () => getParams(11), hidden: () => getParams(11) === null },
            { nom: 'tfbval12', largeur: 141, replacedValue: () => getParams(12), hidden: () => getParams(12) === null },
            { nom: 'tfbval13', largeur: 141, replacedValue: () => getParams(13), hidden: () => getParams(13) === null },
            { nom: 'tfbval14', largeur: 141, replacedValue: () => getParams(14), hidden: () => getParams(14) === null },
            { nom: 'tfbval15', largeur: 141, replacedValue: () => getParams(15), hidden: () => getParams(15) === null },
            { nom: 'tfbval16', largeur: 141, replacedValue: () => getParams(16), hidden: () => getParams(16) === null },
            { nom: 'tfbval17', largeur: 141, replacedValue: () => getParams(17), hidden: () => getParams(17) === null },
            { nom: 'tfbval18', largeur: 141, replacedValue: () => getParams(18), hidden: () => getParams(18) === null },
            { nom: 'tfbval19', largeur: 141, replacedValue: () => getParams(19), hidden: () => getParams(19) === null },
            { nom: 'tfbval20', largeur: 141, replacedValue: () => getParams(20), hidden: () => getParams(20) === null },
            { nom: 'tfbval21', largeur: 141, replacedValue: () => getParams(21), hidden: () => getParams(21) === null },
            { nom: 'tfbval22', largeur: 141, replacedValue: () => getParams(22), hidden: () => getParams(22) === null },
            { nom: 'tfbval23', largeur: 141, replacedValue: () => getParams(23), hidden: () => getParams(23) === null },
            { nom: 'tfbval24', largeur: 141, replacedValue: () => getParams(24), hidden: () => getParams(24) === null },
            { nom: 'tfbval25', largeur: 141, replacedValue: () => getParams(25), hidden: () => getParams(25) === null },
            { nom: 'tfbval26', largeur: 141, replacedValue: () => getParams(26), hidden: () => getParams(26) === null },
            { nom: 'tfbval27', largeur: 141, replacedValue: () => getParams(27), hidden: () => getParams(27) === null },
            { nom: 'tfbval28', largeur: 141, replacedValue: () => getParams(28), hidden: () => getParams(28) === null }
        ]
    };

    function getParams(num: number): string {
        let tfbval = null;
        if (vm.multiOccurrence.dataList && vm.multiOccurrence.dataList[0]) {
            const obj: {} = vm.multiOccurrence.dataList[0];
            type ObjectKey = keyof typeof obj;
            const myKey = `tfblib${num}` as ObjectKey;
            tfbval = obj[myKey];
        }
        return tfbval;
    }
}
