import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemActionImportationClass } from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0163 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    multiOccurrenceImf: IMultiOccurrence;
    formulaireCif: IFormulaire;
    usaimp: IMenuItem;
    valeursEcran: any;
    menuImage: Array<IMenuItem>;
    cisMultiOccurrenceOptions: IMultiOccurrenceOptions;
    imfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    quaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0163Controller(mc0046Route: IRoute,
    Formulaire: IFormulaireClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMc0163 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        // Usage d'imputation
        vm.usaimp = new MenuItemEcran('LBL_BLC_UVI', mc0046Route.NAME, () => {
            return {
                vaeusient: 'CIF',
                uvicleintref: vm.monoOccurrence.data.cifcleint
            };
        }, {
            icon: 'view_list',
            iconButton: false,
            fonction: 'BOUIMP',
            secondaire: true
        });
        // Fonds responsable
        vm.boursp = new MenuItemAction(
            'LBL_BTN_RSP',
            'BOURSP',
            (data: any) => ({
                cifcleint: data.cifcleint,
                ciecleintcif: data.ciecleint
            }),
            {
                icon: 'launch',
                iconButton: false,
                saveFirst: true,
                successAction: () => { vm.monoOccurrence.fetchData() },
                messageConfirmation: 'BOURSP_CONFIRM'
            }
        );

        vm.formulaireCif = new Formulaire('LBL_BLC_CIF', [
            new FormulaireItem('cifcod'),
            new FormulaireItem('cifdsc'),
            new FormulaireItem('cifdscabr'),
            new FormulaireItem('cifflgsai', { default: 0 }),
            new FormulaireItem('cifnbrseq', { default: 3, largeur: 50 }),
            new FormulaireItem('cifflgcap'),
            new FormulaireItem('cifnumpri'),
            new FormulaireItem('cifcom'),
            new FormulaireItem('lipcleintfac', { largeur: 100 }),
            new FormulaireItem('cifnumtvq'),
            new FormulaireItem('cifnumtps'),
            new FormulaireItem('cifcomfact', { largeur: 100 }),
            new FormulaireItem('ciftrfbqefact', { largeur: 100 }),
            new FormulaireItem('ciecleint', {
                default: vm.valeursEcran.ciecleintcif,
                hidden: true
            }),
            new FormulaireGroupeAccordeon('LBL_ACC_REG', new Formulaire([
                new FormulaireItemDivider('LBL_DIV_PRU'),
                new FormulaireItem('cifprumntdep'),
                new FormulaireItem('cifpruprcdep'),
                new FormulaireItemDivider('LBL_DIV_PRB'),
                new FormulaireItem('cifprbmntdep'),
                new FormulaireItem('cifprbprcdep'),
                new FormulaireItemDivider('LBL_DIV_PRT'),
                new FormulaireItem('cifprtmntdep'),
                new FormulaireItem('cifprtprcdep')
            ]))
        ], { largeurDefaut: 50 });
        vm.cisMultiOccurrenceOptions = {
            bloc: 'cis',
            colonnesVisibles: [
                { nom: 'cisann', largeur: 80 },
                { nom: 'sfucod', largeur: 100 },
                { nom: 'sfudsc', largeur: 200 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cisann'),
                new FormulaireItem('sfucleint')
            ])
        };

        vm.quaMultiOccurrenceOptions = {
            bloc: 'qua',
            colonnesVisibles: [
                { nom: 'quacod', largeur: 150 },
                { nom: 'quadsc', largeur: 150 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('quacod'),
                new FormulaireItem('quadsc')
            ]),
            fonctions: {
                suiviModification: true,
                rechercheCollapsable: false
            }
        };

        vm.imfMultiOccurrenceOptions = {
            bloc: 'imf',
            colonnesVisibles: [
                { nom: 'vaeimgtyp_dsc', largeur: 150 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vaeimgtyp'),
                new FormulaireTemplateSlot('photo', { largeur: 50, hidden: (data) => !data.imfcleint })
            ]),
            fonctions: {
                suiviModification: true,
                rechercheCollapsable: false
            }
        };

        vm.menuImage = [
            new MenuItemActionImportation(
                'LBL_IMPORT_IMAGE',
                'BOUIMPORT',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_IMPORT_IMAGE',
                        lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                        formulaire: new Formulaire([])
                    },
                    successAction: () => {
                        vm.multiOccurrenceImf.fetchDataList();
                    },
                    typesFichiers: ['.png', '.gif', '.jpg', '.jpeg', '.bmp'],
                    icon: 'add_a_photo',
                    directionTooltip: 'bottom',
                    disabled: (data: any) => !data.imfcleint
                }
            )
        ];
    }
}
