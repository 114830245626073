import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageDB0059 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function DB0059Controller(db0002Route: IRoute, Menu: IMenuClass, MenuItemForage: IMenuItemForageClass) {
    const vm: IPageDB0059 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: db0002Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(db0002Route.NAME)]),
        colonnesVisibles: [
            { nom: 'mthcod', largeur: 100 },
            { nom: 'mthdsc', largeur: 315 },
            { nom: 'mthflgstu', largeur: 80 },
            { nom: 'mthflgfrsfix', largeur: 120 },
            { nom: 'cptcod', largeur: 120 },
            { nom: 'cptdscabr', largeur: 294 }
        ]
    };
}
