import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'pf0010',
    ecrans: [
        '00-2'
    ],
    params: [
        'id',
        '?trncleint',
        '?dpmcleint',
        '?demcleint'
    ]
});
