import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IMc0088Params {
    refcleint: number;
}
interface IPageMc0088 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    ecranDetails: IEcranDetailsResourcesEntite,
    formulaireRri: IFormulaire;
}

/* @ngInject */
export default function Mc0088Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IMc0088Params) {
    const vm: IPageMc0088 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireRri = new Formulaire('LBL_BLC_RRI', [
            new FormulaireItem('refcleint', {default: $stateParams.refcleint}),
            new FormulaireItem('rricodpay'),
            new FormulaireItem('rrinas'),
            new FormulaireItem('rrinumrelpro'),
            new FormulaireItem('rrinumrelder'),
            new FormulaireItem('rricreimpfed'),
            new FormulaireItem('rriretsupfed'),
            new FormulaireItem('rricreimpque'),
            new FormulaireItem('rriretsupque'),
            new FormulaireItem('unpcoddsc', {default: vm.valeursEcran.unpcoddsc})
        ], {largeurDefaut: 100});
    }
}
