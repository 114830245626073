import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCC0020 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function CC0020Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageCC0020 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('', [
            new FormulaireItem('rqfnumdoc', { largeur: 15 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ttccleint', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demcleint', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('crscleint', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rqfdatdeb', { largeur: 15 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rqfdatfin', { largeur: 15 }),
            //new FormulaireItem('vaedsc', { largeur: 40 }), a ajouter avec cycle plus tard
            //new FormulaireItem('tconumdoc', { largeur: 30 }), a ajouter avec cycle plus tard
            new FormulaireItem('vaerqfori', { default: 'MAN', largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rqfcom', { largeur: 100, enableCount: true })
        ], { largeurDefaut: 50 })
    };
}
