import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMR0337 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0337Controller(Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass) {

    const vm: IPageMR0337 = this;

    vm.multiOccurrenceOptions = {
        actionsSelection: new Menu([
            // Action Copier
            new MenuItemSelection('G_LBL_COPIER', 'BOUCOP', {
                selection: {
                    nom: 'copier'
                }, successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            })
        ]),
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 180},
            {nom: 'urgdsc', largeur: 280},
            {nom: 'strcod', largeur: 140},
            {nom: 'strdsc', largeur: 280}
        ],
        colonnesCachees: [
            'urgcleint',
            'strcleint'
        ]
    };
}
