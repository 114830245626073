import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMr0109{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0109Controller(mr0110Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0109 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0110Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0110Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'fpncod', largeur: 90},
            {nom: 'fpndsc', largeur: 500},
            {nom: 'fpnref', largeur: 158},
            {nom: 'fpnflgact', largeur: 65}
        ]
    };
}
