import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IScope, IComponentController } from 'angular';

interface IPageCP0038 extends IComponentController{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Cp0038Controller(Formulaire: IFormulaireClass,
                                         $scope: IScope,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0038 = this;
    vm.$onInit = $oninit;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        resourceParamsDynamique: () => ({
            annfindeb: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.annfindeb,
            annfinfin: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.annfinfin
        }),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleint', {
                required: false,
                resourceParams: {
                    type: 'FOU'
                }
            }),
            new FormulaireItem('vaereftyp', {
                default: '',
                required: false
            }),
            new FormulaireItem('refeta', {
                default: '',
                required: false
            }),
            new FormulaireItem('tfocleint', {
            }),
            new FormulaireItem('vecsysregadm', {
            }),
            new FormulaireItem('fogcleint', {
            }),
            new FormulaireItem('mntmin', {
            }),
            new FormulaireItem('mntmax', {
            }),
            new FormulaireItem('typmnt', {
                default: 'A'
            }),
            new FormulaireItem('annfindeb'),
            new FormulaireItem('annfinfin')
        ]),
        colonnesFixesGauche: [
            {nom: 'refnom', largeur:218},
            {nom: 'refcod', largeur:90}
        ],
        colonnesVisibles: [
            {nom: 'refmnt_01', largeur:126,
            hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.REFMNT_01},
            {nom: 'refmnt_02', largeur:126,
            hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.REFMNT_02},
            {nom: 'refmnt_03', largeur:126, 
            hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.REFMNT_03},
            {nom: 'refmnt_04', largeur:126, 
            hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.REFMNT_04},
            {nom: 'refmnt_05', largeur:126,
            hidden: (data: any, multiOccurrence: IMultiOccurrence) => multiOccurrence && multiOccurrence.libelles && !multiOccurrence.libelles.REFMNT_05},
            {nom: 'radadrl01', largeur:290},
            {nom: 'radadrl02', largeur:290},
            {nom: 'radvil', largeur:240},
            {nom: 'radpro', largeur:121},
            {nom: 'radcodpos', largeur:106},
            {nom: 'radpay', largeur:131},
            {nom: 'radnumtel', largeur:131}
        ],
        colonnesFixesDroite: [
            {nom: 'refmnt_tot', largeur:126}        ]    };

            function $oninit() {
                $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.annfindeb', (newValue) => {
                    if (newValue) {
                        vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
                    }
                });
        
                $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.annfinfin', (newValue) => {
                    if (newValue) {
                        vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
                    }
                });        
            }
}


