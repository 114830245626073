import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IMr0004Params {
    id: string;
    menuId: string;
    placleint: number;

}

interface IPageMR0004 extends IComponentController{
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePlc: IFormulaire;
    valeursEcran: any;
    pusMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePus: IMultiOccurrence;
}

/* @ngInject */
export default function Mr0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IMr0004Params) {
    const vm: IPageMR0004 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulairePlc = new Formulaire('LBL_BLC_PLC', [
            new FormulaireItem('placleint', {default: $stateParams.placleint}),
            new FormulaireItem('plccod', {largeur: 25}),
            new FormulaireItem('plcdsc', {largeur: 75})
        ] );

        vm.pusMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'urgcod', largeur: 140},
                {nom: 'urgdscabr', largeur: 350},
                {nom: 'strcod', largeur: 140},
                {nom: 'strdsc', largeur: 350}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint', {largeur: 10}),
                new FormulaireItem('strcleint', {largeur: 55})
            ])
        };
    }

}
