import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMC0048 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0048Controller(mc0013Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMC0048 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0013Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0013Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'unacod', largeur: 90},
            {nom: 'unadsc', largeur: 184},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 184},
            {nom: 'achcod', largeur: 90},
            {nom: 'achnom', largeur: 184},
            {nom: 'foncod', largeur: 110},
            {nom: 'fondsc', largeur: 180},
            {nom: 'grpcod', largeur: 130},
            {nom: 'grpdsc', largeur: 184},
            {nom: 'posnumdoc', largeur: 110},
            {nom: 'postitabr', largeur: 184},
            {nom: 'unacodsup', largeur: 120},
            {nom: 'unadscsup', largeur: 184}
        ]
    };
}
