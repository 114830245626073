import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageMr0299 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePdtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0299Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         mr0238Route: IRoute,
                                         $stateParams: any,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0299 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'ifiorgno', largeur: 109},
            {nom: 'ifiorgnom', largeur: 295}
        ],
        colonnesVisibles: [
            {nom: 'ifiorgabr', largeur: 177},
            {nom: 'inscod', largeur: 95},
            {nom: 'insdsc', largeur: 262},
            {nom: 'succod', largeur: 101},
            {nom: 'sucdsc', largeur: 262},
            {nom: 'ificptretour', largeur: 178},
            {nom: 'ifinextseq', largeur: 158},
            {nom: 'ififlgact', largeur: 74}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ifiorgno', {largeur: 20}),
            new FormulaireItem('ifiorgnom', {largeur: 40}),
            new FormulaireItem('ifiorgabr', {largeur: 40}),
            new FormulaireItem('inscleint', {largeur: 20}),
            new FormulaireItem('succleint', {largeur: 20}),
            new FormulaireItem('ificptretour', {largeur: 20}),
            new FormulaireItem('ifinextseq', {largeur: 20}),
            new FormulaireItem('ififlgact', {largeur: 20})
        ])
    };
}
