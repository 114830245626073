import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IComponentController} from 'angular';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';


interface IPageIn0044 extends IComponentController{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    ioaMultiOccurrenceOptions:IMultiOccurrenceOptions;
}
/* @ngInject */
export default function In0044Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageIn0044 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.multiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'ocpcod', largeur: 100},
                {nom: 'ocpdsc', largeur: 241}

            ],
            formulaire: new Formulaire([
                new FormulaireItem('ocpcod'),
                new FormulaireItem('ocpdsc')

            ])
        };

        vm.ioaMultiOccurrenceOptions = {
            bloc:'ioa',
            colonnesVisibles: [
                {nom: 'ioadsc', largeur: 300},
                {nom: 'ioares', largeur: 400}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ioadsc'),
                new FormulaireItem('ioares')

            ])


        }

    }
}
