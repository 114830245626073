import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPagePi0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceATVOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0021Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0021 = this;

    vm.multiOccurrenceATVOptions = {
        bloc: 'atv',
        colonnesVisibles: [
            {nom: 'atvcod', largeur: 89},
            {nom: 'atvdscabr', largeur: 230},
            {nom: 'atvdsc', largeur: 410}
        ],
        fonctions: Object.assign({
            edition: false,
            supprime: false,
            nouveau: false

        })
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'gaccod', largeur: 90},
            {nom: 'gacdscabr', largeur: 230},
            {nom: 'gacdsc', largeur: 365},
            {nom: 'gacflgact', largeur: 63},
            {nom: 'gacflgadmddc', largeur: 98}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gaccod'),
            new FormulaireItem('gacdscabr', {required: true}),
            new FormulaireItem('gacdsc', {required: true}),
            new FormulaireItem('gacflgact', {default: 1, largeur: 25}),
            new FormulaireItem('gacflgadmddc', {default: 1, largeur: 25})
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceATVOptions
    };
}
