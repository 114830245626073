import { IComponentController, IFilterDate } from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IStateParamsService } from "angular-ui-router";

import { IRoute } from "../../../../../core/interfaces/route.interface";
import AxesAffichage from '../../../../../core/classes/axe-affichage.class';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';


interface IPageAs0059 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function As0059Controller(as0060Route: IRoute,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    Formulaire: IFormulaireClass,
    dateFilter: IFilterDate,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService) {
    const vm: IPageAs0059 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemEcran(
                '',
                as0060Route.NAME,
                () => ({
                    afecleint: ($stateParams.afecleint)
                }),
                {
                    optionsDialog: {
                        largeur: 50,
                        hauteur: 30
                    }
                }
            ),
        ]),        
        navigatePage: as0060Route.NAME,
        navigateParams: (data:any) => ({
            id: data.fjrcleint,
            afecleint: ($stateParams.afecleint)
        }),
        fonctions: {
            axesAffichageVisible: true,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('afecleintcri', {disabled: true}),
            new FormulaireItem('anneecri', { 
                largeur: 33,
                default: vm.valeursEcran.annee,
                required: true
            })
        ]),
        axesAffichage: {
            axes: [
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.CALENDAR,
                    icon: 'calendar_today',
                    lblNom: 'G_LBL_CALENDAR',
                    options: {
                        description: ['actcod','fjrcom'],
                        colonneDateDebut: 'fjrdat',
                        colonneDateFin: 'fjrdatfin',
                        colonneHeureDebut: 'fjrhrdeb',
                        colonneHeureFin: 'fjrhrfin',
                        hideHours: true
                    }
                },
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.LIST,
                    icon: 'view_list',
                    lblNom: 'G_LBL_LIST'
                }
            ]
        },
        colonnesVisibles: [
            { nom: 'fjrdat', largeur: 160 },
            { nom: 'fjrcom', largeur: 120 },
            { nom: 'actcod', largeur: 120 },
            { nom: 'actdsc', largeur: 120 }
        ],
        colonnesCachees: [
            'actcleint'
        ],
    };
}
