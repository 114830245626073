import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController, IFilterDate } from "angular";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IGs0067Params {
    usrcleintref: string;
}

interface IPageGs0067 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */
export default function Gs0067Controller($stateParams: IGs0067Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    dateFilter: IFilterDate,
    FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageGs0067 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_GSU', [
            new FormulaireItem('gsunumdoc'),
            new FormulaireItem('gsudat', { default: dateFilter(new Date(), 'yyyy-MM-dd') }),
            new FormulaireItem('usrcleint', { default: $stateParams.usrcleintref, readonly: true }),
            new FormulaireItem('ttccleint', { modifAllow: false }),
            new FormulaireItem('gsucom', { largeur: 100 }),
            new FormulaireItemDivider('LBL_DIV_DAT'),
            new FormulaireItem('gsuancdat', { readonly: true }),
            new FormulaireItem('gsunoudat')
        ])
    };
}
