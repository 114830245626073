import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr2011',
    ecrans: ['00-2'],
    params: [
        '?date_tmp',
        "?emprech"
    ]
});
