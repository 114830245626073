import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCr1001 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    fceMultiOccurrenceOptions: IMultiOccurrenceOptions
}



/* @ngInject */
export default function Cr1001Controller(
    Formulaire: IFormulaireClass,
    cr0007Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Pagination: IPaginationClass) {
    const vm: IPageCr1001 = this;



    vm.monoOccurrenceOptions = {
        fonctions: {
            edition: false,
            nouveau: false,
            supprime: false,
            suiviModification: false
        },
        formulaire: new Formulaire('LBL_INFOS', [
            new FormulaireItem('faccleint', { hidden: true }),
            new FormulaireItem('facnumdoc', {
                largeur: 25, readonly: true,
                menu: [
                    new MenuItemForage(
                        'G_LBL_FONC_TRANS_DOCUMENT',
                        cr0007Route.NAME,
                        (rowData: any) => {
                            return {
                                id: rowData.facdoc
                            };
                        }
                    )
                ]
            }),
            new FormulaireItem('refnom', { largeur: 25, readonly: true }),
            new FormulaireItem('facdat', { largeur: 25, readonly: true }),
            new FormulaireItem('facmnt', { largeur: 25, readonly: true }),
        ])

    }

    // Cédule
    vm.fceMultiOccurrenceOptions = {
        bloc: 'fce',
        fonctions: {
            pagination: true,
            nouveau: (data: any) => (data.$ancetre.etdcod !== 'RE'),
            editionRapide: true,
            editionStandard: false,
            supprime: ecranDetails.valeurs.flgacc
        },
        editionRapideActive: true,
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('fcedat', { readonly: !ecranDetails.valeurs.flgacc, required: true }),
            new FormulaireItem('fcemnt', { readonly: !ecranDetails.valeurs.flgacc }),
            new FormulaireItem('fcemntenc', { readonly: true })
        ], {
            largeurDefaut: 20
        }),
        // Affichage des colonnes visibles
        colonnesVisibles: [
            { nom: 'fcedat', largeur: 110 },
            { nom: 'fcemnt', largeur: 130 },
            { nom: 'fcemntenc', largeur: 130 }
        ],
        colonnesEditionRapide: [
            { nom: 'fcedat', largeur: 110 },
            { nom: 'fcemnt', largeur: 130, total: true },
            { nom: 'fcemntenc', largeur: 130, total: true }
        ]
    };
}

