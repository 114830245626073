import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRR0138 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0138Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRR0138 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnom', largeur: 162},
            {nom: 'eplprn', largeur: 162}
        ],
        colonnesVisibles: [
            {nom: 'ciecod', largeur: 105},
            {nom: 'cienomabr', largeur: 210},
            {nom: 'eplini', largeur: 91},
            {nom: 'vacepletaciv_dsc', largeur: 158},
            {nom: 'vaeeplsex_dsc', largeur: 158},
            {nom: 'eplnas', largeur: 116, hidden: !ecranDetails.flgcen},
            {nom: 'epldatnai', largeur: 126},
            {nom: 'eplflgimm', largeur: 91},
            {nom: 'vaeepllng_dsc', largeur: 158},
            {nom: 'epladrcou', largeur: 262},
            {nom: 'eplnam', largeur: 165},
            {nom: 'eplnumref1', largeur: 184},
            {nom: 'eplnumref2', largeur: 184},
            {nom: 'eplnumref3', largeur: 184},
            {nom: 'eplnumref4', largeur: 184},
            {nom: 'lemcod', largeur: 131},
            {nom: 'lemdsc', largeur: 262},
            {nom: 'ugncod', largeur: 190},
            {nom: 'ugndsc', largeur: 262},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdsc', largeur: 262},
            {nom: 'sgncod', largeur: 135},
            {nom: 'sgndsc', largeur: 262},
            {nom: 'strcod', largeur: 89},
            {nom: 'strdsc', largeur: 262},
            {nom: 'datemb', largeur: 126},
            {nom: 'datdepdef', largeur: 126},
            {nom: 'eplnomnai', largeur: 200},
            {nom: 'eplnomusu', largeur: 200},
            {nom: 'eplprnusu', largeur: 200},
            {nom: 'eplben', largeur: 200},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ],
        colonnesCachees: [
            'eplcleint',
            'message'
        ]
    };
}
