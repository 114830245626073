import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageGa0007 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireGar: IFormulaire;
    valeursEcran: any;
    domMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ggvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ggdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceDOIOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ga0007Controller(Formulaire: IFormulaireClass,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass) {
    const vm: IPageGa0007 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            cycleErrorReloadBlocs: ['ecv'],

            infosFinancieres: {
                disponibilite: true,
                impactBudgetaire: true
            },
            actionsMore: new Menu([
                new MenuItemAction('LBL_BTN_CTRL', ('BOUCTRL'), (data: any) => ({
                    garcleint: data.garcleint,
                    usrcleint: data.usrcleint
                }), {
                    icon: 'done_all',
                    formulaireConfirmationAction: {
                        lblTitre: 'G_LBL_QUESTION',
                        lblConfirm: '',
                        conditionAffichage: (monoOccurrence: IMonoOccurrence, data: any) => (data.aut__actinact),
                        largeurFormulaire: 38,
                        formulaire: new Formulaire([
                            new FormulaireItem('msg', { readonly: true, titre: ecranDetails.valeurs.msg })
                        ], { largeurDefaut: 100 })
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                })
            ])
        };

        vm.formulaireGar = new Formulaire(' ', [
            new FormulaireItem('garnumdoc', { largeur: 33.33 }),
            new FormulaireItem('garnumdocseq', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tydcleint', { largeur: 33.33, modifAllow: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gardat', { largeur: 25 }),
            new FormulaireItem('garflgpij', { largeur: 25 }),
            new FormulaireItem('garflgverfin', { largeur: 25 }),
            new FormulaireItem('garflgref', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('intcleintreq', { largeur: 33.33 }),
            new FormulaireItem('gardsc', { largeur: 66 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_SEP'),

            new FormulaireItem('el2cleintsrc', { largeur: 33.33 }),
            new FormulaireItem('peccleint_de', { largeur: 33.33 }),
            new FormulaireItem('peccleint_a', { largeur: 33.33 }),

            new FormulaireItem('intcleintreqcri', { largeur: 33.33 }),
            new FormulaireItem('tydcleintref', { largeur: 33.33 }),
            new FormulaireItem('doccleintref', {
                largeur: 33.33,
                resourceParams: (data: any) => ({ intcleintreqcri: data.intcleintreqcri, tydcleintref: data.tydcleintref })
            }),

            new FormulaireItem('prucleint', { largeur: 33.33 }),
            new FormulaireItem('garflgpeccib', { default: 0, largeur: 10 }),
            new FormulaireItem('peccleintcib', { disabled: (data: any) => (data.garflgpeccib != 1), largeur: 27 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('el2cleintcib', { largeur: 33.33 }),
            new FormulaireItem('garflgtyp', { largeur: 33.33, default: 1 })
        ], {
            largeurDefaut: 33.33
        });

        vm.ggvMultiOccurrenceOptions = {
            editionRapideActive: true,
            fonctions: {
                editionRapide: true,
                editionStandard: true,
                edition: () => vm.multiOccurrence.isModifiablePartiel() || vm.multiOccurrence.isModifiableTous()
            },
            colonnesVisibles: [
                { nom: 'ggvflgref', largeur: 126 },
                { nom: 'peccod', largeur: 160 },
                { nom: 'ggvimp', largeur: 310 },
                { nom: 'ggvmntctr', largeur: 90, total: true },
                { nom: 'ggvmnt', largeur: 90, total: true },
                { nom: 'ggvcom', largeur: 300 },
                { nom: 'ggvflgmsg', largeur: 90 }

            ],
            colonnesEditionRapide: [
                { nom: 'ggvflgref', largeur: 100 },
                { nom: 'peccleint', largeur: 160 },
                { nom: 'imput', largeur: 400 },
                { nom: 'ggvmntctr', largeur: 90, total: true },
                { nom: 'ggvmnt', largeur: 126, total: true },
                { nom: 'ggvcom', largeur: 300 },
                { nom: 'ggvflgmsg', largeur: 200 },
                { nom: 'aut__ggvmsgrap', largeur: 300 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ggvflgref'),
                new FormulaireItem('peccleint', { modifAllow: false }),
                new FormulaireItem('imput', { largeur: 50, modifAllow: false }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ggvmnt', { largeur: 25 }),
                new FormulaireItem('ggvcom', { largeur: 25 }),
                new FormulaireItem('ggvflgmsg', { readonly: true, required: false, titre: " " })
            ], { largeurDefaut: 33.33 }),
            colonnesCachees: [
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint',
                'ggvmsg'
            ]
        };

        vm.ggdMultiOccurrenceOptions = {
            colonnesVisibles: [
                { nom: 'peccod', largeur: 126 },
                { nom: 'docaffnumdoc01', largeur: 160 },
                { nom: 'tydcod', largeur: 140 },
                { nom: 'docaffdsc01', largeur: 190 },
                { nom: 'docaffdat01', largeur: 110 },
                { nom: 'ggdimp', largeur: 300 },
                { nom: 'ggdmnt', largeur: 90 }
            ],
            colonnesCachees: [
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint'
            ]
        };

        vm.domMultiOccurrenceOptions = {
            colonnesVisibles: [
                { nom: 'dommsg', largeur: 295 }
            ]
        };
    }
}
