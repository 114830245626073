import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as2010',
    ecrans: ['00-2'],
    params:['?arrcleint',
            '?madcleint',
            '?stamnetab',
            '?vaearrtyp',
            '?vaudatdeb',
            '?vaevauope',
            '?vaudatfin',
            '?vauvalnum']
});
