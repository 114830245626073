import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0164 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0164Controller(Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0164 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'lvtcleint', valeur: $stateParams.reference, visible: false}
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaelvttyp_dsc', largeur: 200},
            {nom: 'tagcod'       , largeur: 160},
            {nom: 'tagdscabr'    , largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaelvttyp'),
            new FormulaireItem('tagcleint')
        ])
    };
}
