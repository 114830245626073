import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageAc0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0022Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: any
) {
    const vm: IPageAc0022 = this;
    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('dhicleintrefcri', {readonly: true}),
            new FormulaireItem('dhitypcri', { 
                default: $stateParams.dhityp,
                hidden: true
            }),
        ]),
        colonnesVisibles: [
            {nom: 'datcreaff', largeur: 130},
            {nom: 'motcod', largeur: 120},
            {nom: 'motdsc', largeur: 150},
            {nom: 'usrcod', largeur: 120},
            {nom: 'usrnom', largeur: 150},
            {nom: 'dacseqave', largeur: 120},
            {nom: 'dhiflgerr', largeur: 100},
            {nom: 'dhicom', largeur: 300}
        ]
    };
}
