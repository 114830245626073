import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageSO0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0009Controller(so0010Route: IRoute,
                                         Menu: IMenuClass,
                                         Operateur: IOperateurService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageSO0009 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: so0010Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(so0010Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'stoflgann',
                operateur: ecranDetails.valeurs.pmrflgexcann ? Operateur.EGALE_PAS : Operateur.EGALE,
                valeur: ecranDetails.valeurs.pmrflgexcann ? '1' : ''
            },
            {
                colonne: 'stoflgrev',
                operateur: ecranDetails.valeurs.pmrflgexcann ? Operateur.EGALE_PAS : Operateur.EGALE,
                valeur: ecranDetails.valeurs.pmrflgexcfer ? '1' : ''
            }
        ],
        colonnesVisibles: [
            {nom: 'stodsc', largeur: 210},
            {nom: 'stodatvig', largeur: 100},
            {nom: 'stoflgoff', largeur: 86},
            {nom: 'stoflgenrev', largeur: 91},
            {nom: 'stoflgrev', largeur: 76},
            {nom: 'stoflgann', largeur: 75},
            {nom: 'stodatrev', largeur: 100},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 210}
        ]
    };
}
