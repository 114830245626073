import { IComponentController, IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageMr2017 extends IComponentController {
    menus: { [key: string]: Array<IMenuItem> },
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    cyrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pdtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Mr2017Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    dateFilter: IFilterDate,
    Menu: IMenuClass,
    $stateParams: IStateParamsService,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageMr2017 = this;

    vm.formulairePrf = new Formulaire([
        new FormulaireItem('pfcdsc', {
            largeur: 33,
            modifAllow: true
        }),
        new FormulaireItem('pfccom', {
            largeur: 33,
            modifAllow: true
        }),
        new FormulaireItem('vaepfctyp', {
            largeur: 33,
            modifAllow: true
        })
    ]);
    // Cycle transactionnel
    vm.cyrMultiOccurrenceOptions = {
        bloc: 'cyr',
        fonctions: {
            pagination: true,
            nouveau: false,
            supprime: false,
            edition: false
        },
        colonnesVisibles: [
            { nom: 'cyrcod', largeur: 10 },
            { nom: 'cyrdsc', largeur: 90 },
            { nom: 'cyrcom', largeur: 90 }
        ]
    }
    // Détail
    vm.pdtMultiOccurrenceOptions = {
        bloc: 'pdt',
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 130 },
            { nom: 'urgdsc', largeur: 262 },
            { nom: 'strcod', largeur: 89 },
            { nom: 'strdsc', largeur: 262 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint', { required: true }),
            new FormulaireItem('strcleint')
        ])
    };
}
