import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPagePI0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0031Controller(Menu: IMenuClass,
                                         Operateur: IOperateurService) {
    const vm: IPagePI0031 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesVisibles: [
            {nom: 'odcnumdoc', largeur: 200},
            {nom: 'odcdsc', largeur: 200},
            {nom: 'odcdat', largeur: 200},
            {nom: 'ecldsceta', largeur: 200},
            {nom: 'pipnumdoc', largeur: 200},
            {nom: 'pipnumref', largeur: 200},
            {nom: 'pipdsc', largeur: 200},
            {nom: 'pplnumdoc', largeur: 200},
            {nom: 'ppldscl01', largeur: 200},
            {nom: 'trnflgstaref', largeur: 200},
            {nom: 'trnflgstafin', largeur: 200},
            {nom: 'trnflgstafer', largeur: 200},
            {nom: 'trnflgstaann', largeur: 200}
        ]
    };
}
