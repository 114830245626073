import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemActionTelechargementClass } from '../../../../../core/services/menu/menu-item-action-telechargement.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';
import { IFiltre } from "../../../../../core/services/filtre.service";
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRr0503 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulairePam: IFormulaire;
    phaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pdeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    paiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    paidetMultiOccurrenceOptions: IMultiOccurrenceOptions;
    patMultiOccurrenceOptions: IMultiOccurrenceOptions;
    patMultiOccurrence: IMultiOccurrence;
    patdetMultiOccurrenceOptions: IMultiOccurrenceOptions;
    parMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pardetMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pbqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pbqdetMultiOccurrenceOptions: IMultiOccurrenceOptions;
    psaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eacMultiOccurrenceOptions: IMultiOccurrenceOptions;

    valeursEcran: any;

}

/* @ngInject */
export default function Rr0503Controller(Menu: IMenuClass,
    Pagination: IPaginationClass,
    rr0557Route: IRoute,
    rr2031Route: IRoute,
    Operateur: IOperateurService,
    ecranDetails: IEcranDetailsResourcesEntite,
    exLibelleFilter: IFilterLibelle,
    Formulaire: IFormulaireClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0503 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    // Permet d'obtenir le bouton pour visualiser le relevé
    vm.menus = {
        flgreleve: [
            new MenuItemActionTelechargement(
                'BTN_ACTION_RAPDEP',
                'BOURELEVE',
                () => ({}),
                {
                    icon: 'attach_file',
                    iconButton: true
                }
            )
        ]
    };

    vm.monoOccurrenceOptions = {
        fonctions: {
            avisEmploye: true
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_CONVERSION_DEPOT',
                'BOUCONVERSION',
                (data: any) => ({
                    pamcleint: data.pamcleint,
                    pamcleintann: data.pamcleintann,
                    vaepamprv: data.vaepamprv,
                    mopcleint: data.mopcleint,
                    unpcleint: data.unpcleint,
                    ppacleint: data.ppacleint
                }),
                {
                    icon: 'sync',
                    successAction: () => { vm.monoOccurrence.fetchData(); },
                    messageConfirmation: 'BOUCON_MESSAGE',
                    disabled: true
                }
            ),
            new MenuItemAction(
                'LBL_CHANGE_DATE',
                'BOUCHGDATE',
                (data: any) => ({
                    pamcleint: data.pamcleint,
                    pamcleintann: data.pamcleintann,
                    vaepamprv: data.vaepamprv,
                    mopcleint: data.mopcleint,
                    unpcleint: data.unpcleint,
                    ppacleint: data.ppacleint
                }),
                {
                    icon: 'date_range',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_FEN_CHANGEMENT_DATE',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_pamdat')
                        ], { largeurDefaut: 100 })
                    },
                    disabled: true,// (data: any) => !data.aut__flgchgdatchq,
                    successAction: () => { vm.monoOccurrence.fetchData(); }
                }
            ),
            new MenuItemAction(
                'LBL_DUPLICATA',
                'BOUDUPLICATA',
                (data: any) => ({ pamcleint: data.pamcleint }),
                {
                    icon: 'file_copy',
                    successAction: () => { vm.monoOccurrence.fetchData(); },
                    messageConfirmation: 'BOUDUPLICATA_MESSAGE',
                    disabled: true
                }
            ),
            new MenuItemAction(
                'LBL_ANNULATION',
                'BOUANNULATION',
                (data: any) => ({ pamcleint: data.pamcleint }),
                {
                    icon: 'clear',
                    disabled: true,
                    formulaireConfirmationAction: {
                        conditionAffichage: (monoOccurrence: IMonoOccurrence, data: any) => (data.aut__flgfenaff),
                        lblTitre: 'LBL_ANNULATION',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_flgann_proc', { titre: () => `${exLibelleFilter('LBL_FLGANN', vm.monoOccurrence.libelles)} ${vm.monoOccurrence.data.aut__annee}` }),
                        ], { largeurDefaut: 100 })
                    },
                    messageConfirmation: {
                        message: 'BOUANNULATION_MESSAGE',
                        condition: (data: any) => {
                            return Boolean(!data.aut__flgfenaff);
                        }
                    },
                    successAction: () => { vm.monoOccurrence.fetchData(); }
                },

            ),
            new MenuItemEcran(
                'LBL_BTN_HIS',
                rr2031Route.NAME,
                (data: any) => ({
                    pamcleint: data.pamcleint                   
                }),
                {
                    icon: 'launch_app',
                    fonction: 'BOUIMP',
                    iconButton: false,
                    secondaire: false
                }
            )
        ])
    };

    vm.formulairePam = new Formulaire('LBL_BLC_PAM', [
        new FormulaireTemplateSlot('infos')
    ], { largeurDefaut: 20 });

    // Onglet avantage imposable
    vm.paiMultiOccurrenceOptions = {
        bloc: 'pai',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 25,
            nbElementsPossibles: [25, 50, 100]
        }),
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 120 },
            { nom: 'tagdscabr', largeur: 300 },
            { nom: 'paimnt', largeur: 120 }
        ]
    };

    // Onglet DÉTAIL de avantage imposable
    vm.paidetMultiOccurrenceOptions = {
        bloc: 'paidet',
        colonnesVisibles: [
            { nom: 'ppacod', largeur: 90 },
            { nom: 'paimnt', largeur: 140 },
            { nom: 'paiflganc', largeur: 100 },
            { nom: 'vaepaityp_dsc', largeur: 200 }
        ]
    };

    // Onglet BRUT
    vm.patMultiOccurrenceOptions = {
        bloc: 'pat',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 25,
            nbElementsPossibles: [25, 50, 100]
        }),
         resourceParamsDynamique: () => {
            const filtreAxe = vm.patMultiOccurrence && vm.patMultiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === 'axe');

            return {
                axe: filtreAxe && filtreAxe.valeur
            }
        }, 
        forcerEtatPredefinisDepart: 'IMP',
        etatsPredefinis: [
            {
                nom: 'AFT',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'AFT'
                    }
                ], 
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'FED',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'FED'
                    }
                ],
                colonnesVisibles: [
                    'refcod',
                    'patqtecal',
                    'patmntcal'
                ]
            },           
            {
                nom: 'PRO',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'PRO'
                    }
                ],
                colonnesVisibles: [
                    'refcod',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'CAL',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'CAL'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'ORI',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'ORI'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'POS',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'POS'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'TAG',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'TAG'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'IMP',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'IMP'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'TEM',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'TEM'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'UNO',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'UNO'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
            {
                nom: 'DER',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        visible: false,
                        valeur: 'DER'
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'patqtecal',
                    'patmntcal'
                ]
            },
        ],
        colonnesCachees: [
            'axe',
            'tagcleint'
        ],
        colonnesVisibles: [
            { nom: 'refcod', largeur: 470 },
            { nom: 'vuecod', largeur: 120 },
            { nom: 'vuedsc', largeur: 350 },
            { nom: 'patqtecal', largeur: 146 },
            { nom: 'patmntcal', largeur: 146 }
        ]
    };

    // Onglet DÉTAIL du brut
    vm.patdetMultiOccurrenceOptions = {
        bloc: 'patdet',
        resourceParamsDynamique: () => {
            const filtreAxe = vm.patMultiOccurrence && vm.patMultiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === 'axe');

            return {
                axe: filtreAxe && filtreAxe.valeur
            }
        },
        resourceParams: {
            tagcleint: vm.patMultiOccurrence && vm.patMultiOccurrence.getDataActiveRow().tagcleint
        },
        colonnesFixesGauche: [
            { nom: 'ppacod_cal', largeur: 88 },
            { nom: 'ppacod_ori', largeur: 88 },
            { nom: 'tagcod', largeur: 90 },
            { nom: 'patqtecal', largeur: 110 },
            { nom: 'pattaucal', largeur: 110 },
            { nom: 'patmntcal', largeur: 110 }
        ],
        colonnesVisibles: [
            { nom: 'patsal', largeur: 130 },
            { nom: 'patdatdeb', largeur: 104 },
            { nom: 'patdatfin', largeur: 100 },
            { nom: 'temcod', largeur: 100 },
            { nom: 'temdsc', largeur: 250 },
            { nom: 'aftcod', largeur: 110 },
            { nom: 'aftdsc', largeur: 250 },
            { nom: 'lemcod', largeur: 100 },
            { nom: 'lemdsc', largeur: 250 },
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'postit', largeur: 250 },
            { nom: 'dernumdoc', largeur: 100 },
            { nom: 'derdsc', largeur: 250 },
            { nom: 'drpnumdoc', largeur: 116 },
            { nom: 'drpdsc', largeur: 250 },
            { nom: 'unocod', largeur: 116 },
            { nom: 'unodscabr', largeur: 250 },
            { nom: 'tagdscabr', largeur: 250 },
            { nom: 'patflganc', largeur: 90 },
            { nom: 'esvcod1', largeur: 150, hidden: !vm.valeursEcran.flgesv1aff },
            { nom: 'esvcod2', largeur: 150, hidden: !vm.valeursEcran.flgesv2aff },
            { nom: 'esvcod3', largeur: 150, hidden: !vm.valeursEcran.flgesv3aff },
            { nom: 'esvcod4', largeur: 150, hidden: !vm.valeursEcran.flgesv4aff },
            { nom: 'esvcod5', largeur: 150, hidden: !vm.valeursEcran.flgesv5aff }
        ]
    };

    // Onglet RCE
    vm.parMultiOccurrenceOptions = {
        bloc: 'par',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 25,
            nbElementsPossibles: [25, 50, 100]
        }),
        /*           actionsMoreLigne: new Menu([
                      new MenuItemEcran(
                          'LBL_FORAGE_RR0557',
                          rr0557Route.NAME,
                          (data: any) => ({ axe:       data.axe_rce,
                                            selection: data.selection,
                                            pamcleint: data.pamcleint,
                                            rcecleint: data.rcecleint,
                                            ppacleint: data.ppacleintcal}),
                          {
                             // icon: 'launch_app'//,
                              iconButton: true
                          }
                      )
                  ]), */
        resourceParamsDynamique: () => {
            const filtreAxe = vm.parMultiOccurrence && vm.parMultiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === 'axe_rce');

            return {
                axe_rce: filtreAxe && filtreAxe.valeur
            }
        },
        forcerEtatPredefinisDepart: 'RCE',
        etatsPredefinis: [
            {
                nom: 'FED',
                filtres: [
                    {
                        colonne: 'axe_rce',
                        operateur: Operateur.EGALE,
                        valeur: 'FED',
                        visible: false
                    }
                ],
                colonnesVisibles: [
                    'rfecod',
                    'parqteadm',
                    'parmntadm',
                    'parmntemp',
                    'parmntemr',
                    'parqtearr',
                    'parmntarr',
                ]
            },
            {
                nom: 'PRO',
                filtres: [
                    {
                        colonne: 'axe_rce',
                        operateur: Operateur.EGALE,
                        valeur: 'PRO',
                        visible: false
                    }
                ],
                colonnesVisibles: [
                    'rfecod',
                    'parqteadm',
                    'parmntadm',
                    'parmntemp',
                    'parmntemr',
                    'parqtearr',
                    'parmntarr',
                ]
            },
            {
                nom: 'PPA',
                filtres: [
                    {
                        colonne: 'axe_rce',
                        operateur: Operateur.EGALE,
                        valeur: 'PPA',
                        visible: false
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'parqteadm',
                    'parmntadm',
                    'parmntemp',
                    'parmntemr',
                    'parqtearr',
                    'parmntarr',
                ]
            },
            {
                nom: 'RCE',
                filtres: [
                    {
                        colonne: 'axe_rce',
                        operateur: Operateur.EGALE,
                        valeur: 'RCE',
                        visible: false
                    }
                ],
                colonnesVisibles: [
                    'vuecod',
                    'vuedsc',
                    'parqteadm',
                    'parmntadm',
                    'parmntemp',
                    'parmntemr',
                    'parqtearr',
                    'parmntarr',
                ]
            }
        ],
        colonnesCachees: [
            'axe_rce',
            'selection',
            'pamcleint',
            'rcecleint',
            'ppacleintcal'
        ],
        colonnesVisibles: [
            { nom: 'rfecod', largeur: 470 },
            { nom: 'vuecod', largeur: 120 },
            { nom: 'vuedsc', largeur: 350 },
            { nom: 'parqteadm', largeur: 134 },
            { nom: 'parmntadm', largeur: 116 },
            { nom: 'parmntemp', largeur: 116 },
            { nom: 'parmntemr', largeur: 116 },
            { nom: 'parqtearr', largeur: 116 },
            { nom: 'parmntarr', largeur: 116 }
        ]
    };

    // Onglet DÉTAIL des RCE
    vm.pardetMultiOccurrenceOptions = {
        bloc: 'pardet',
        resourceParamsDynamique: () => {
            const filtreAxe = vm.parMultiOccurrence && vm.parMultiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === 'axe_rce');

            return {
                axe_rce: filtreAxe && filtreAxe.valeur
            }
        },
        colonnesVisibles: [
            { nom: 'ppacod', largeur: 90 },
            { nom: 'rcecod', largeur: 90 },
            { nom: 'cotcod', largeur: 100 },
            { nom: 'parqteadm', largeur: 110 },
            { nom: 'parmntadm', largeur: 110 },
            { nom: 'parmntemp', largeur: 110 },
            { nom: 'parmntemr', largeur: 110 },
            { nom: 'parqtearr', largeur: 110 },
            { nom: 'parmntarr', largeur: 110 },
            { nom: 'parflganc', largeur: 98 },
            { nom: 'vaepartyp_dsc', largeur: 175 },
            { nom: 'rcedscabr', largeur: 250 },
            { nom: 'cotdsc', largeur: 250 }
        ]
    };

    // Onglet BAC
    vm.pbqMultiOccurrenceOptions = {
        bloc: 'pbq',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 25, 50]
        }),
        colonnesVisibles: [
            { nom: 'bqecod', largeur: 110 },
            { nom: 'bqedscabr', largeur: 300 },
            { nom: 'pbqqte', largeur: 130 },
            { nom: 'dbscod1', largeur: 140 },
            { nom: 'dbscod2', largeur: 140 }
        ]
    };

    // Onglet détail BAC
    vm.pbqdetMultiOccurrenceOptions = {
        bloc: 'pbqdet',
        colonnesVisibles: [
            { nom: 'ppacod', largeur: 90 },
            { nom: 'pbqdatreg', largeur: 140 },
            { nom: 'pbqdattrs', largeur: 140 },
            { nom: 'vaesystrsbqe_dsc', largeur: 175 },
            { nom: 'pbqqte', largeur: 120 },
            { nom: 'pbqflganc', largeur: 100 }
        ]
    };

    // Onglet Détail du temps
    vm.psaMultiOccurrenceOptions = {
        bloc: 'psa',
        colonnesCachees: [
            'psacleint'
        ],
        colonnesFixesGauche: [
            { nom: 'ppacod', largeur: 84 },
            { nom: 'tagcod', largeur: 96 }
        ],
        colonnesVisibles: [
            { nom: 'aftcod', largeur: 105 },
            { nom: 'posnumdoc', largeur: 110 },
            { nom: 'lemcod', largeur: 110 },
            { nom: 'temcod', largeur: 110 },
            { nom: 'unocod', largeur: 105 },
            { nom: 'dernumdoc', largeur: 144 },
            { nom: 'drpnumdoc', largeur: 130 },
            { nom: 'psadatdeb', largeur: 106 },
            { nom: 'psadatfin', largeur: 100 },
            { nom: 'psaqte', largeur: 110 },
            { nom: 'psatauhor', largeur: 110 },
            { nom: 'psamnt', largeur: 110 },
            { nom: 'tagdscabr', largeur: 250 },
            { nom: 'tpfdsc', largeur: 250 },
            { nom: 'aftdsc', largeur: 250 },
            { nom: 'postit', largeur: 250 },
            { nom: 'lemdsc', largeur: 250 },
            { nom: 'temdscabr', largeur: 250 },
            { nom: 'unodscabr', largeur: 250 },
            { nom: 'derdsc', largeur: 250 },
            { nom: 'drpdsc', largeur: 250 },
            { nom: 'psacom', largeur: 250 },
            { nom: 'usrcodcre', largeur: 120 },
            { nom: 'datcre', largeur: 110 },
            { nom: 'usrcodmod', largeur: 120 },
            { nom: 'datmod', largeur: 110 },
            { nom: 'esvcod1', largeur: 140, hidden: !vm.valeursEcran.flgesv1aff },
            { nom: 'esvcod2', largeur: 140, hidden: !vm.valeursEcran.flgesv2aff },
            { nom: 'esvcod3', largeur: 140, hidden: !vm.valeursEcran.flgesv3aff },
            { nom: 'esvcod4', largeur: 140, hidden: !vm.valeursEcran.flgesv4aff },
            { nom: 'esvcod5', largeur: 140, hidden: !vm.valeursEcran.flgesv5aff },
            { nom: 'imp', largeur: 300 },
            { nom: 'imp_avs', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('imp'),
            new FormulaireItem('imp_avs', { titre: 'imp_avs' })
        ])
    };

    // Onglet Imputation AVS
    vm.eacMultiOccurrenceOptions = {
        bloc: 'eac',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 25, 50]
        }),
        colonnesCachees: [
            'eaccleintref'
        ],
        colonnesVisibles: [
            { nom: 'eactypcod', largeur: 110 },
            { nom: 'eaccodref', largeur: 110 },
            { nom: 'eacdscabr', largeur: 275 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'imp', largeur: 275 },
            { nom: 'eaccom', largeur: 350 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pamdat', { default: () => vm.monoOccurrence.data.pamdat }),

            new FormulaireItem('eactypcod', { default: 'TAG', largeur: 33.33 }),
            new FormulaireItem('t_tagcleint', {
                largeur: 33.33,
                resourceParams: () => ({ pamcleint: vm.monoOccurrence.data.$id }),
                default: (data: any) => (data.aut__tagcleint)
            }),
            new FormulaireItem('t_tagcleint__tagcod', {
                default: (data: any) => (data.aut__tagcod)
            }),
            new FormulaireItem('t_tagcleint__tagdscabr', {
                default: (data: any) => (data.aut__tagdscabr)
            }),
            new FormulaireItem('t_rcecleint', {
                largeur: 33.33,
                resourceParams: () => ({ pamcleint: vm.monoOccurrence.data.$id }),
                default: (data: any) => (data.aut__rcecleint)
            }),
            new FormulaireItem('t_rcecleint__rcecod', {
                default: (data: any) => (data.aut__rcecod)
            }),
            new FormulaireItem('t_rcecleint__rcedscabr', {
                default: (data: any) => (data.aut__rcedscabr)
            }),
            new FormulaireItem('ppacleint', {
                largeur: 33.33,
                resourceParams: (data: any) => ({
                    pamcleint: vm.monoOccurrence.data.$id,
                    eaccleintref: data.eaccleintref,
                    eactypcod: data.eactypcod
                })
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('imp', { largeur: 33.33 }),
            new FormulaireItem('eaccom', { largeur: 66.66, enableCount: true })
        ])
    };
    // Historique d'action(PM2)
    vm.phaMultiOccurrenceOptions = {
        bloc: 'pha',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 25,
            nbElementsPossibles: [25, 50, 100]
        }),
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 100 },
            { nom: 'usrnom', largeur: 100 },
            { nom: 'datcre', largeur: 100 }
        ]
    };

    // Onglet dépôt
    vm.pdeMultiOccurrenceOptions = {
        bloc: 'pde',
        colonnesVisibles: [
            { nom: 'inscod', largeur: 110 },
            { nom: 'insdsc', largeur: 275 },
            { nom: 'succod', largeur: 110 },
            { nom: 'pdecptbqe', largeur: 130 },
            { nom: 'pdemnt', largeur: 120 }
        ]
    };
    console.log(vm.monoOccurrence)
}
