import { IComponentController, ISCEService, IScope } from "angular";
import IResourceService = angular.resource.IResourceService;
import { IMultiOccurrenceOptions, IMultiOccurrence } from "../../../../../core/services/multi-occurrence.service";
import { IApiConfig } from "../../../../../core/interfaces/api-config.interface";
import { IParametresSecuriteService } from "../../../../../core/services/parametres-securite.service";
import { IStateParamsService } from "angular-ui-router";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMenuItemClass } from "../../../../../core/services/menu/menu-item.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IDialog } from "../../../../../core/services/dialog.service";
import { IFilterLibelle } from "../../../../../core/filters/ex-libelle.filter";

interface IPageCp2002 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    url: string;
    isImageUrl: boolean;
}

/* @ngInject */
export default function Cp2002Controller($sce: ISCEService,
    $scope: IScope, DialogInfo: IDialog,
    exLibelleFilter: IFilterLibelle,
    $stateParams: IStateParamsService,
    $resource: IResourceService,
    ApiConfig: IApiConfig,
    parametresSecurite: IParametresSecuriteService,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    cp0066Route: IRoute) {
    const vm = this as IPageCp2002;

    const cp2002Resource = $resource(`${ApiConfig.ROOT}/cp2002/`, {
        ...parametresSecurite($stateParams)
    }, {
        update: {
            method: 'PUT',
            url: `${ApiConfig.ROOT}/cp2002/:id`
        }
    }) as any;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs
        vm.multiOccurrenceOptions = {
            actions: new Menu([
                new MenuItem("LBL_BTN_DOUBLONS", () => {
                    DialogInfo.show({
                        locals: {
                            lblTitre: exLibelleFilter('LBL_BTN_DOUBLONS', vm.multiOccurrence.libelles),
                            lblDescription: ecranDetails.valeurs.reqrlarlddbl
                        }
                    })
                }, { iconButton: false, hidden: !ecranDetails.valeurs.reqrlarlddbl }),
                new MenuItemForage('LBL_BTN_CONSULTER', cp0066Route.NAME, () => {
                    return {
                        id: ecranDetails.valeurs.trncleint
                    }
                }, {
                    iconButton: false,
                    hidden: () => !ecranDetails.valeurs.trncleint || !vm.multiOccurrence || !vm.multiOccurrence.dataList || !vm.multiOccurrence.dataList.length
                })
            ]),
            fonctions: {
                nouveau: false,
                edition: false,
                supprime: false,
                suiviModification: false,
                enfantCollapsable: false
            },
            pagination: {
                nombreElementPage: 10,
                nbElementsPossibles: [10, 25, 50]
            },
            colonnesVisibles: [
                { nom: 'dopdsc', largeur: 150 },
                { nom: 'dopmnt', largeur: 150 },
                { nom: 'dopmntrclcan', largeur: 200 },
                { nom: 'dopdat', largeur: 150 },
                { nom: 'dopcom', largeur: 150 }
            ],
            colonnesCachees: [
                'dopflgaprfin',
                'bloc',
                'dopcleint'
            ],
            actionsRangeeGauche: ecranDetails.flgcen ? new Menu([
                new MenuItem('DOPFLGAPRFIN', (event: MouseEvent, data: any) => {
                    data.dopflgaprfin = !data.dopflgaprfin;
                    cp2002Resource.update({ id: data.dopcleint }, { ...data, dopflgaprfin: Number(data.dopflgaprfin) });
                }, {
                    icon: (data) => data.dopflgaprfin ? 'check_box' : 'check_box_outline_blank',
                    iconButton: true,
                    class: 'cp2002-icon-visible',
                    hidden: (data: any) => data.bloc === 'REQ'
                })
            ]) : undefined,
            multiOccurrenceOptionsEnfant: {
                bloc: 'pij',
                fonctions: {
                    recherche: false,
                },
                pagination: {
                    nombreElementPage: 10,
                    nbElementsPossibles: [10, 25, 50]
                },
                colonnesVisibles: [
                    { nom: 'pijdsc', largeur: 150 }
                ],
                colonnesCachees: [
                    'pijnomphy'
                ]
            }
        };

        $scope.$watch('vm.multiOccurrence.multiOccurrenceEnfant.activeRowCleint', (activeRowCleint) => {
            if (activeRowCleint) {
                const params = $.param({
                    cleintref: vm.multiOccurrence.getDataActiveRow().id,
                    srccod: 'cp2002',
                    screen: true,
                    doccleint: $stateParams.doccleint,
                    ...parametresSecurite($stateParams)
                });

                vm.url = $sce.trustAsResourceUrl(`${ApiConfig.ROOT}/gs0018/edition/${activeRowCleint}/pijdat?${params}&a#toolbar=0&navpanes=0`);
                vm.isImageUrl = /\.(png|PNG|jpg|JPG|gif|GIF)$/.test(vm.multiOccurrence.multiOccurrenceEnfant.getDataActiveRow().pijnomphy)
            } else {
                vm.url = undefined;
                vm.isImageUrl = false;
            }
        })
    }
}
