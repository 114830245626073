import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageTF0012 {
   multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function TF0012Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    tf0004Route: IRoute
) {
    const vm: IPageTF0012 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('crifolcleint', {
                required: true,
                largeur: 33
            }),
            new FormulaireItem('crimercleint', {
                largeur: 33
            }),
        ]),
        navigatePage: tf0004Route.NAME,
        pagination: {
            nombreElementPage: 25
        },
        colonnesFixesGauche: [{ nom: 'efacod', largeur: 103 }],
        colonnesVisibles: [
            { nom: 'eplnomprn', largeur: 200 },
            { nom: 'rfecod', largeur: 179 },
            { nom: 'merflgerr', largeur: 114 },
            { nom: 'mercod', largeur: 140 },
            { nom: 'merdsc', largeur: 140 }            
            
        ]        
    };

}
