import IStateService = angular.ui.IStateService;
import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMR0131 extends IComponentController {
    valeursEcran: any;
    formulairePaf: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pfpMultiOccurrence: IMultiOccurrence;
    pfbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0131Params {
    menuId: string;
}

/* @ngInject */
export default function Mr0131Controller($state: IStateService,
                                         $stateParams: IMr0131Params,
                                         mr0131Route: IRoute,
                                         mr0318Route: IRoute,
                                         mr0319Route: IRoute,
                                         mr0320Route: IRoute,
                                         mr0321Route: IRoute,
                                         mr0322Route: IRoute,
                                         mr0323Route: IRoute,
                                         mr0324Route: IRoute,
                                         mr0325Route: IRoute,
                                         mr0326Route: IRoute,
                                         mr0327Route: IRoute,
                                         mr0328Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMR0131 = this;

    const OPTIONS = { onClose: () => { vm.pfpMultiOccurrence.fetchDataList(); } };

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            pageRetourSuppression: mr0318Route.NAME,
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_COPIE',
                    'BOUCOP',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            formulaire: new Formulaire([
                                new FormulaireItem('t_pafdatvig')
                            ])
                        },
                        successAction: (data: any) => {
                            $state.go(mr0131Route.NAME, {
                                id: data.newpafcleint,
                                menuId: $stateParams.menuId
                            });
                        },
                    }
                )
            ])
        };

        vm.formulairePaf = new Formulaire('LBL_BLC_PAF', [
            new FormulaireItem('pafdatvig'),
            new FormulaireItem('paftd1mntbas', { largeur: 10 }),
            new FormulaireItem('paftd1mntrpl', { largeur: 10 }),
            new FormulaireItem('pafmntrn', { largeur: 10 }),
            new FormulaireItem('pafmntrnmax', { largeur: 10 }),
            new FormulaireItem('pafmntcreemp', { largeur: 10 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_LCF',
                new Formulaire([
                    new FormulaireItem('pafimflimlcf'),
                    new FormulaireItem('pafimfprclcf')
                ], { largeurDefaut: 33.33 }), { largeur: 50 }
            ),
            new FormulaireGroupeAccordeon('LBL_RPC',
                new Formulaire([
                    new FormulaireItem('pafrpctau'),
                    new FormulaireItem('pafrpcexm'),
                    new FormulaireItem('pafrpcmga')
                ], { largeurDefaut: 33.33 }), { largeur: 50 }
            )
        ], { largeurDefaut: 25 });

        // Borne
        vm.pfbMultiOccurrenceOptions = {
            bloc: 'pfb',
            colonnesVisibles: [
                {nom: 'pfbborinf', largeur: 140},
                {nom: 'pfbtauimp', largeur: 85},
                {nom: 'pfbval'   , largeur: 140}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pfbborinf'),
                new FormulaireItem('pfbtauimp'),
                new FormulaireItem('pfbval')
            ], { largeurDefaut: 33.33 })
        };

        

        // Province
        vm.pfpMultiOccurrenceOptions = {
            bloc: 'pfp',
            actionsNouveaux: new Menu([
                //new MenuItemEcran('LBL_PRV_AB', mr0319Route.NAME, () => ({ province: 'AB', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Alberta
               // new MenuItemEcran('LBL_PRV_ZZ', mr0319Route.NAME, () => ({ province: 'ZZ', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Autre
                //new MenuItemEcran('LBL_PRV_BC', mr0327Route.NAME, () => ({ province: 'BC', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Colombie-Britannique
                //new MenuItemEcran('LBL_PRV_XX', mr0319Route.NAME, () => ({ province: 'XX', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Hors-Canada
                //new MenuItemEcran('LBL_PRV_PE', mr0322Route.NAME, () => ({ province: 'PE', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Île-du-Prince-Édouard
                //new MenuItemEcran('LBL_PRV_MB', mr0325Route.NAME, () => ({ province: 'MB', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Manitoba
                new MenuItemEcran('LBL_PRV_QC', mr0319Route.NAME, () => ({ province: 'QC', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Québec
                new MenuItemEcran('LBL_PRV_NR', mr0319Route.NAME, () => ({ province: 'NR', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS) // Non résident du Canada
                //new MenuItemEcran('LBL_PRV_NB', mr0323Route.NAME, () => ({ province: 'NB', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Nouveau-Brunswick
                //new MenuItemEcran('LBL_PRV_NS', mr0321Route.NAME, () => ({ province: 'NS', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Nouvelle-Écosse
                //new MenuItemEcran('LBL_PRV_NU', mr0319Route.NAME, () => ({ province: 'NU', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Nunavut
                //new MenuItemEcran('LBL_PRV_ON', mr0324Route.NAME, () => ({ province: 'ON', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Ontario                
                //new MenuItemEcran('LBL_PRV_SK', mr0326Route.NAME, () => ({ province: 'SK', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Saskatchewan
                //new MenuItemEcran('LBL_PRV_NL', mr0320Route.NAME, () => ({ province: 'NL', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Terre-Neuve et Labrador
                //new MenuItemEcran('LBL_PRV_NT', mr0319Route.NAME, () => ({ province: 'NT', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS), // Territoires du Nord-Ouest
                //new MenuItemEcran('LBL_PRV_YT', mr0328Route.NAME, () => ({ province: 'YT', pafcleint: vm.monoOccurrence.data.pafcleint }), OPTIONS)  // Yukon
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'BTN_OUVRIR',
                    getForageRoute,
                    (data: any) => ({
                        id: data.pfpcleint,
                        pafcleint: vm.monoOccurrence.data.pafcleint
                    }),
                    {
                        icon: 'launch',
                        onClose: () => { vm.pfpMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            colonnesCachees: ['pfpcleint'],
            colonnesFixesGauche: [
                {nom: 'vaesyscodprv_dsc', largeur: 180}
            ],
            colonnesVisibles: [
                {nom: 'pfptd1mntbas'   , largeur: 170},
                {nom: 'pfpimftaut3'    , largeur: 110}
            ]
        };

        function getForageRoute(data: any): string {
            if (data.vaesyscodprv === 'NL') { return mr0320Route.NAME; }
            else if (data.vaesyscodprv === 'NS') { return mr0321Route.NAME; }
            else if (data.vaesyscodprv === 'PE') { return mr0322Route.NAME; }
            else if (data.vaesyscodprv === 'NB') { return mr0323Route.NAME; }
            else if (data.vaesyscodprv === 'ON') { return mr0324Route.NAME; }
            else if (data.vaesyscodprv === 'MB') { return mr0325Route.NAME; }
            else if (data.vaesyscodprv === 'SK') { return mr0326Route.NAME; }
            else if (data.vaesyscodprv === 'BC') { return mr0327Route.NAME; }
            else if (data.vaesyscodprv === 'YT') { return mr0328Route.NAME; }
            else { return mr0319Route.NAME; }
        }
    }
}
