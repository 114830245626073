import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterDate } from 'angular';

interface IPageCC0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0030Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    dateFilter: IFilterDate
    //cc0021Route: IRoute,
    //cc0022Route: IRoute
) {
    const vm: IPageCC0030 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('dat', {
                required: true,
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            })
        ]),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 125 },
            { nom: 'eplnomprn', largeur: 220 }
        ],
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 150 },
            { nom: 'urgdscabr', largeur: 220 },
            { nom: 'lemdatdeb', largeur: 126 },
            { nom: 'lemdatfin', largeur: 126 },
            { nom: 'nbruni', largeur: 140 },
            { nom: 'trmcodder', largeur: 115 },
            { nom: 'trmcodavader', largeur: 115 },
            { nom: 'nbrcou', largeur: 130 },
            { nom: 'strcod', largeur: 140 },
            { nom: 'strdsc', largeur: 220 },
            { nom: 'tafcod', largeur: 140 },
            { nom: 'tafdsc', largeur: 220 },
            { nom: 'flgmulaft', largeur: 150 }
        ]
    };
}
