import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageDO0036 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0036Controller(do0037Route: IRoute,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageDO0036 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: do0037Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                trncleint: rowData.trncleint
            };
        },        
        actionsNouveaux: new Menu([
            new MenuItemForage(do0037Route.NAME)
        ]),
               
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'pvanumdoc', largeur: 136},
            {nom: 'eplnummat_nou', largeur: 100},
            {nom: 'eplnomprn_nou', largeur: 262},
            {nom: 'pvadat', largeur: 126},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'posnumdoc', largeur: 100},
            {nom: 'postitabr', largeur: 262},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'ttccod', largeur: 105},
            {nom: 'vaepvaori_dsc', largeur: 200},
            {nom: 'vacraidsc', largeur: 184},
            {nom: 'codref', largeur: 136},
            {nom: 'dscref', largeur: 340},
            {nom: 'pvadatvacest', largeur: 140},
            {nom: 'pvadatvac', largeur: 126},
            {nom: 'pvadatlimdec', largeur: 140},
            {nom: 'dmpnumdoc', largeur: 136},
            {nom: 'vaedmpdec_dsc', largeur: 200},
            {nom: 'pvacom', largeur: 400},
            {nom: 'intcodrh', largeur: 100},
            {nom: 'intnomrh', largeur: 240},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
