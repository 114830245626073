import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageGl0040 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    multiOccurrence: IMultiOccurrence;
    formulaire: IFormulaire;
    valeursEcran: any;
    entMultiOccurrenceOptions: IMultiOccurrenceOptions;
    basMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gl0040Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {

    const vm: IPageGl0040 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaire = new Formulaire('LBL_EDITION_FORMAT', [
            new FormulaireItem('foedsc', {largeur: 50 }),
            new FormulaireItem('foelngpag', {largeur: 10}),
            new FormulaireItem('foelarpag', {largeur: 10}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('foeespcol', {largeur: 10}),
            new FormulaireItem('foelardsc', {largeur: 10}),
            new FormulaireItem('foesiggau', {largeur: 10}),
            new FormulaireItem('foesigdro', {largeur: 10}),
            new FormulaireItem('foesepmil', {largeur: 10}),
            new FormulaireItem('foesepdec', {largeur: 10})
        ], {
            largeurDefaut: 25
        });

        vm.entMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'fodnumlig', largeur: 80},
                {nom: 'vaefodalg_dsc', largeur: 200},
                {nom: 'fodnumcol', largeur: 80},
                {nom: 'vaefodtypinf_dsc', largeur: 200},
                {nom: 'fodlib', largeur: 200},
                {nom: 'fodtxt', largeur: 200}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fodnumlig', {largeur: 10}),
                new FormulaireItem('vaefodalg', {largeur: 20}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fodnumcol', {largeur: 10}),
                new FormulaireItem('vaefodtypinf', {largeur: 20}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fodlib', {largeur: 40}),
                new FormulaireItem('fodtxt', {largeur: 40})
            ], {
                largeurDefaut: 20
            })
        }
        vm.basMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'fodnumlig', largeur: 80},
                {nom: 'vaefodalg_dsc', largeur: 200},
                {nom: 'fodnumcol', largeur: 80},
                {nom: 'vaefodtypinf_dsc', largeur: 200},
                {nom: 'fodlib', largeur: 200},
                {nom: 'fodtxt', largeur: 200}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fodnumlig', {largeur: 10}),
                new FormulaireItem('vaefodalg', {largeur: 20}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fodnumcol', {largeur: 10}),
                new FormulaireItem('vaefodtypinf', {largeur: 20}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fodlib', {largeur: 40}),
                new FormulaireItem('fodtxt', {largeur: 40})
            ], {
                largeurDefaut: 20
            })
        }

    }
}
