import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageAS0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0032Controller(Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    Operateur: IOperateurService,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    as0033Route: IRoute,
    parametresRecherche: IParametresRechercheService,
    Pagination: IPaginationClass) {
    const vm: IPageAS0032 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        navigatePage: as0033Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(as0033Route.NAME, () => {
                return {
                    fonction: 'BOUNOUV'
                };
            })
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesFixesGauche: [{ nom: 'altnumdoc', largeur: 136 }],
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 136 },
            { nom: 'ecldsceta', largeur: 157 },
            { nom: 'asgcod', largeur: 200 },
            { nom: 'asgdsc', largeur: 262 },
            { nom: 'ptacod', largeur: 160 },
            { nom: 'ptadsc', largeur: 262 },
            { nom: 'aeqcod', largeur: 150 },
            { nom: 'aeqnom', largeur: 262 },
            { nom: 'ttccod', largeur: 150 },
            { nom: 'ttcdsc', largeur: 262 },
            { nom: 'altcom', largeur: 540 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 76 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'trnflgstaann', largeur: 75 }
        ],
        colonnesCachees: ['altcleint', 'trncleint']
    };
}
