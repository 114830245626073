import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IStateService } from "angular-ui-router";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageCp2005 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireRef: IFormulaire;
    mdvMultiOccurrence: IMultiOccurrence;
    mdvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    zrvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
interface ICp2005Params {
    menuId: string;
}
/* @ngInject */
export default function Cp2005Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    $state: IStateService,
    $stateParams: ICp2005Params,
    MenuItemAction: IMenuItemActionClass,
    cp2005Route: IRoute) {
    const vm = this as IPageCp2005

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_COPIE_PAIMENT',
                'BOUCOP',
                (rowData: any) => ({ zracleint: rowData.zracleint }),
                {
                    icon: 'content_copy',
                    fonction: 'BOUACT',
                    successAction: (rowData: any) => {
                        $state.go(cp2005Route.NAME, { id: rowData.zracleintnew, menuId: $stateParams.menuId });
                    }
                }
            )]),
        fonctions: {
            boutonDupliquer: false
        },
        successActionSauvegarde: () => vm.monoOccurrence.fetchData(),
        formulaire: new Formulaire('LBL_TITRE', [
            new FormulaireItem("zranumdoc"),
            new FormulaireItem("zczcleint"),
            new FormulaireItem("refcleint", { hidden: (vm.valeursEcran.type === 1) }),
            new FormulaireItem("zrafounom", { hidden: (vm.valeursEcran.type === 0) }),
            new FormulaireItem("doccleintref", { onValueChange: () => vm.mdvMultiOccurrence.fetchDataList(), hidden: (vm.valeursEcran.type === 1) }),
            new FormulaireItem("zradocref", { hidden: (vm.valeursEcran.type === 0) }),
            new FormulaireItem("zraadr", { hidden: (vm.valeursEcran.type === 0) }),
            new FormulaireItem("zranas", { hidden: (vm.valeursEcran.type === 0) }),
            new FormulaireItem("radcleint", { hidden: (ecranDetails.flgcen === 0 || vm.valeursEcran.type === 1) ? true : false }),
            new FormulaireItem("zranumref", { required: true }),
            new FormulaireItem("zradat"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("zramnttot"),
            new FormulaireItem("vaesysdevcod", { readonly: (data: any) => (vm.valeursEcran.type === 0 || (data.refcleint)) }),
            new FormulaireItem("zradsc", { largeur: 100 }),
            new FormulaireItem("zradscext", { largeur: 100 }),
            new FormulaireItem("flgcen", { default: ecranDetails.flgcen, hidden: true }),
            new FormulaireItem("isportail", { default: 0, hidden: true })
        ], { piecesJointes: true })
    }

    vm.formulaireRef = new Formulaire('LBL_INFO', [
        new FormulaireItem("zrafounom"),
        new FormulaireItem("zradocref"),
        new FormulaireItem("zraadr"),
        new FormulaireItem("zranas")
    ], { largeurDefaut: 100 });

    vm.zrvMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: "zrvimp", largeur: 200 },
            { nom: "zrvcom", largeur: 200 }
        ],
        colonnesCachees: ["prucleint", "cptcleint", "cifcleint", "prscleint", "unacleint", "el1cleint", "el2cleint", "el3cleint"],
        formulaire: new Formulaire([
            new FormulaireItem("zrvimp"),
            new FormulaireItem("zrvcom")
        ])
    }

    vm.mdvMultiOccurrenceOptions = {
        resourceParamsDynamique: () => ({
            zracleint: vm.monoOccurrence.data.zracleint,
            doccleint: vm.monoOccurrence.data.doccleintref
        }),
        colonnesVisibles: [
            { nom: "mdvimp", largeur: 200 },
            { nom: "mdvdsc", largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem("mdvdsc")
        ])
    }
}