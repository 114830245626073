import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageCc0007 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    usageimp: IMenuItem;
    usageexg: IMenuItem;
    usageepm: IMenuItem;
    usageatt: IMenuItem;
}

/* @ngInject */
export default function Cc0007Controller(
    mr0176Route: IRoute,
    cc0024Route: IRoute,
    cc0027Route: IRoute,
    cc0022Route: IRoute,
    Formulaire: IFormulaireClass,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCc0007 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_CIE', [
            new FormulaireItem('crscod', { largeur: 25 }),
            new FormulaireItem('crsdsc', { largeur: 65 }),
            new FormulaireItem('unocleint', { largeur: 91 }),
            new FormulaireItem('crscleintpoi', { largeur: 91 }),
            new FormulaireItemDivider('LBL_SEP_ATT'),
            new FormulaireItem('tarcleint', { largeur: 91 }),
            new FormulaireItem('urgcleint', { largeur: 91 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('statut', { readonly: true }),
            new FormulaireItem('employe_qualif', { readonly: true })
        ], { largeurDefaut: 33.33 })
    };
    // Usage d'imputation
    vm.usageimp = new MenuItemEcran(
        'LBL_USAGE_IMPUTATION',
        mr0176Route.NAME,
        (data: any) => ({
            stamnetab: 'CRS',
            ovdcleintref:  vm.monoOccurrence.id
        }),
        {
            icon: 'view_list',
            iconButton: false
        }
    );

    // Usage exigences
    vm.usageexg = new MenuItemEcran(
        'LBL_USAGE_EXIGENCE',
        cc0024Route.NAME,
        () => ({
            crscleint: vm.monoOccurrence.id
        }),
        {
            icon: 'view_list',
            iconButton: false
        }
    );
    // Usage détail employé
    vm.usageemp = new MenuItemEcran(
        'LBL_USAGE_EMPLOYE',
        cc0027Route.NAME,
        () => ({
            crscleint: vm.monoOccurrence.id
        }),
        {
            icon: 'view_list',
            iconButton: false
        }
    );
    // Usage attribution
    vm.usageatt = new MenuItemEcran(
        'LBL_USAGE_ATTRIBUTION',
        cc0022Route.NAME,
        () => ({
            crscleint: vm.monoOccurrence.id
        }),
        {
            icon: 'view_list',
            iconButton: false
        }
    );

}
