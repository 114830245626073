import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMC0129 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0129Controller(mc0130Route: IRoute) {

    const vm: IPageMC0129 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0130Route.NAME,

        colonnesVisibles: [
            {nom: 'docnumdoc', largeur: 136},
            {nom: 'docnumdocseq', largeur: 60},
            {nom: 'peccod', largeur: 100},
            {nom: 'tydcod', largeur: 65},
            {nom: 'docrefcod02', largeur: 120},
            {nom: 'docrefnom02', largeur: 350},
            {nom: 'radcod', largeur: 70},
            {nom: 'docaffnumref01', largeur: 155},
            {nom: 'etddsc', largeur: 165},
            {nom: 'ecldsceta', largeur: 200},
            {nom: 'trndsc', largeur: 262},
            {nom: 'docaffmnt01', largeur: 126},
            {nom: 'tvimntadm', largeur: 126},
            {nom: 'dccmntpay', largeur: 126},
            {nom: 'tvinbremp', largeur: 95}
        ]
    };
}
