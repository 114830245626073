import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageAc0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0015Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageAc0015 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('refcleintcri'),
                    new FormulaireItem('daccleintcri', {
                        resourceParams: (data: any) => {
                            return {
                                refcleint: data.refcleintcri,
                                teccleint: data.teccleintcri
                            };
                    }}),
                    new FormulaireItem('teccleintcri', { required: true })
                ], {largeurDefaut: 100  }), {largeur: 33.33}
            ),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('intcleintachcri'),
                    new FormulaireItem('intcleintreqcri'),
                    new FormulaireItem('intcleintdemcri')
                ], {largeurDefaut: 100  }), {largeur: 33.33}
            ),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItemDivider('LBL_DATE'),
                    new FormulaireItem('datdebcri'),
                    new FormulaireItem('datfincri'),
                    new FormulaireItemDivider('LBL_PERIODE'),
                    new FormulaireItem('peccleintdebcri', {largeur: 100}),
                    new FormulaireItem('peccleintfincri', {largeur: 100})
                ], {largeurDefaut: 50  }), {largeur: 33.33}
            )
        ], { largeurDefaut: 33.33 }),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_GENERE',
                'BOUGEN',
                {
                    selection: { 
                        nom: 'selection',
                        lblConfirmationTitre: 'LBL_BTN_GENERE',
                        largeurFormulaire: 30,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('teccleintnew', { 
                                largeur: 100, 
                                required: true 
                            })
                        ])
                    },
                    fonction: 'BOUGEN'
                })
        ]),
        colonnesFixesGauche: [
            { nom: 'refnom', largeur: 220 },
            { nom: 'refcod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 130 },
            { nom: 'sysboncmd', largeur: 130 },
            { nom: 'dacdsc', largeur: 350 },
            { nom: 'etddsc', largeur: 130 },
            { nom: 'dacdat', largeur: 126 },
            { nom: 'peccod', largeur: 120 }            
        ]
    };
}