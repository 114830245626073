import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPagePb0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0031Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePb0031 = this;

    vm.multiOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('soncleint'),
            new FormulaireItem('rgiflgact', {default: 1})
        ], {largeurDefaut: 50}),
        colonnesVisibles: [
            {nom: 'soncod'          , largeur: 150},
            {nom: 'sondsc'          , largeur: 350},
            {nom: 'vaesysnatctb_dsc', largeur: 200},
            {nom: 'rgiflgact'       , largeur: 120}
        ]
    };
}
