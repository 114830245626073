import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageZz0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}
/* @ngInject */
export default function Zz0004Controller(zz0003Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageZz0004 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: zz0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                zlicleint: rowData.zlicleint
            };
        },
        colonnesFixesGauche: [
            {nom: 'nbrerr', largeur: 100},
            {nom: 'zliexc', largeur: 100},
            {nom: 'fcocrscod', largeur: 130},
            {nom: 'fcocrsdsc', largeur: 180}
        ],
        colonnesVisibles: [          
            {nom: 'fcotrmcod', largeur: 130},
            {nom: 'fcotrmann', largeur: 130},
            {nom: 'fcotrmdatdeb', largeur: 123},
            {nom: 'fcotrmdatfin', largeur: 123},
            {nom: 'fcotrmdsc', largeur: 180},
            {nom: 'fcocofgrp', largeur: 130},
            {nom: 'fcocofnbrcre', largeur: 130},
            {nom: 'fcocofnbretumax', largeur: 135},
            {nom: 'fcocofnbrhre', largeur: 130},
            {nom: 'fcounocod', largeur: 135},
            {nom: 'fcolitcod', largeur: 130},
            {nom: 'fcocofdsc', largeur: 230},
            {nom: 'fcocofdscinfadd', largeur: 200},
            {nom: 'fcounocodcrs', largeur: 160},
            {nom: 'fcocofflgact', largeur: 100},
            {nom: 'fcocofflgatrprf', largeur: 130},
            {nom: 'fcoeplnummat', largeur: 120},
            {nom: 'vaefcojrssem', largeur: 120},
            {nom: 'vaefcoplg', largeur: 130},
            {nom: 'fcohredeb', largeur: 130},
            {nom: 'fcohrefin', largeur: 130},
            {nom: 'fcocofnbretu', largeur: 130},
            {nom: 'fcocofdatdeb', largeur: 130},
            {nom: 'fcocofdatfin', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('nbrerr'),
            new FormulaireItem('zliexc'),
            new FormulaireItem('fcocrscod'),
            new FormulaireItem('fcocrsdsc'),
            new FormulaireItem('fcotrmcod'),
            new FormulaireItem('fcotrmann'),
            new FormulaireItem('fcotrmdatdeb'),
            new FormulaireItem('fcotrmdatfin'),
            new FormulaireItem('fcotrmdsc'),
            new FormulaireItem('fcocofgrp'),
            new FormulaireItem('fcocofnbrcre'),
            new FormulaireItem('fcocofnbretumax'),
            new FormulaireItem('fcocofnbrhre'),
            new FormulaireItem('fcounocod'),
            new FormulaireItem('fcolitcod'),
            new FormulaireItem('fcocofdsc'),
            new FormulaireItem('fcocofdscinfadd'),
            new FormulaireItem('fcounocodcrs'),
            new FormulaireItem('fcocofflgact'),
            new FormulaireItem('fcocofflgatrprf'),
            new FormulaireItem('fcoeplnummat'),
            new FormulaireItem('vaefcojrssem'),
            new FormulaireItem('vaefcoplg'),
            new FormulaireItem('fcohredeb'),
            new FormulaireItem('fcohrefin'),
            new FormulaireItem('fcocofnbretu'),
            new FormulaireItem('fcocofdatdeb'),
            new FormulaireItem('fcocofdatfin')
        ])
    };
}
