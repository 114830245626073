import {IComponentOptions, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import InputLovRequerantController from './ex-input-lov-requerant.controller';

export default module('core.components.ex-input-lov-requerant', []).component('exInputLovRequerant', InputLovRequerantFactory());

function InputLovRequerantFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.DATA,
            Binding.VALUE,
            Binding.SCHEMA_ITEM,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.OCCURRENCE.OPTIONAL,
            {
                titre: '@',
                required: '<?ngRequired',
                resourceParams: '<?',
                nombreLibellesDetailsCacher: '<?',
                longueurFixe: '<?',
                readonly: '<?exReadonly',
                disabled: '<?exDisabled',
                srccodref: '@?'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '^exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: InputLovRequerantController,
        template: require('./ex-input-lov-requerant.html')
    };
}
