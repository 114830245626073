import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0051 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0051Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0051 = this;

    vm.multiOccurrenceOptions = {
       
        colonnesVisibles: [
            {nom: 'dpjcod', largeur: 100},
            {nom: 'dpjdsc', largeur: 204},
            {nom: 'dpjtxt', largeur: 200}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dpjcod', {required: true}),
            new FormulaireItem('dpjdsc', {required: true}),
            new FormulaireItem('dpjtxt')
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
