import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IStateParamsService} from 'angular-ui-router';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPageRr0156 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0156Controller($stateParams: IStateParamsService,
                                         Operateur: IOperateurService) {
    const vm: IPageRr0156 = this;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'aftcleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.aftcleint,
                visible: false
            }
        ],
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 120},
            {nom: 'tagdsc', largeur: 180},
            {nom: 'vaeaprprv_dsc', largeur: 160},
            {nom: 'aprmnt', largeur: 160, total: true},
            {nom: 'aprmntprctra', largeur: 160, total: true}
        ]
    };
}
