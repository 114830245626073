import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageTf0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0013Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageTf0013 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'mercod', largeur: 100 },
            { nom: 'merdsc', largeur: 400 },
            { nom: 'merflgerr', largeur: 100 },
            { nom: 'merflgr1', largeur: 100 },
            { nom: 'merflgr2', largeur: 100 },
            { nom: 'merflgr3', largeur: 100 },
            { nom: 'merflgt4', largeur: 100 },
            { nom: 'merflgt4a', largeur: 100 },
            { nom: 'merflgt4anr', largeur: 100 },
            { nom: 'merflgt5', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mercod', {largeur: 30}),
            new FormulaireItem('merdsc', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('merflgerr', {largeur: 10}),
            new FormulaireItem('merflgr1', {largeur: 10}),
            new FormulaireItem('merflgr2', {largeur: 10}),
            new FormulaireItem('merflgr3', {largeur: 10}),
            new FormulaireItem('merflgt4', {largeur: 10}),
            new FormulaireItem('merflgt4a', {largeur: 10}),
            new FormulaireItem('merflgt4anr', {largeur: 10}),
            new FormulaireItem('merflgt5', {largeur: 10})
        ])
    };
}
