import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPagePc0003 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceLcvOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pc0003Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass,
                                        FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPagePc0003 = this;

    vm.multiOccurrenceSisOptions = {
        bloc: 'lcv',
        colonnesVisibles: [
            { nom: 'lcvcod', largeur: 60 },
            { nom: 'lcvlib', largeur: 60 },
            { nom: 'lcvdsc', largeur: 60 },
            { nom: 'vaecnvide_dsc', largeur: 60 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('lcvcod', {largeur: 30}),
            new FormulaireItem('lcvlib', {largeur: 70}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lcvdsc', {largeur: 30}),
            new FormulaireItem('vaecnvide', {largeur: 70})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'scvunitrt', largeur: 60},
            {nom: 'scvcod', largeur: 60},
            {nom: 'scvseq', largeur: 60},
            {nom: 'scvdsc', largeur: 100}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceSisOptions,

        formulaire: new Formulaire([
            new FormulaireItem('scvunitrt', {largeur: 20}),
            new FormulaireItem('scvcod', {largeur: 20}),
            new FormulaireItem('scvseq', {largeur: 20}),
            new FormulaireItem('scvdsc', {largeur: 40})
        ])
    };
}
