import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMC0029 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc0029Params {
    intcleint: number;
}

/* @ngInject */
export default function mc0029Controller(
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    $stateParams: IMc0029Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    mc2046Route: IRoute
) {

    const vm: IPageMC0029 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres:true,
            selectionCycle: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleint', {
                largeur: 33.33, 
                required: true, 
                default: $stateParams.intcleint || vm.valeursEcran.usrintcleint, 
                readonly: () => !(vm.valeursEcran.flgintequ)
            })
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemForage('LBL_BTN_BOURES',
                mc2046Route.NAME,
                (data: any) => ({
                    id: data.doccleint
                }),
                {
                    icon: 'assessment'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_DOC',
                (rowData: any) => `secure.${rowData.ecrcodoc}.ecran`,
                (rowData: any) => ({
                    id: rowData.ecrcledoc
                }),
                {
                    icon: 'launch'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_IMP',
                (rowData: any) => `secure.${rowData.ecrcodimp}.ecran`,
                (rowData: any) => ({
                    id: rowData.doccleint,
                    docaffnumdoc01: rowData.docaffnumdoc01,
                    tydcleint: rowData.tydcleint
                }),
                {
                    icon: 'view_list'
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 131 },
            { nom: 'tydcod', largeur: 80 }
        ],
        colonnesVisibles: [
            { nom: 'docrefcod02', largeur: 126 },
            { nom: 'docreqcod03num', largeur: 126 },
            { nom: 'docaffmntdepnet01', largeur: 120 },
            { nom: 'docaffmnt01', largeur: 120 },
            { nom: 'docaffdsc01', largeur: 190 }
        ],
        colonnesCachees: [
            'doccleint', 
            'ecrcodoc', 
            'ecrcledoc',
            'ecrcodimp', 
            'docaffnumdoc01', 
            'tydcleint'
        ]
    };
}
