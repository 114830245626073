import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0028Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRR0028 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprnusu', largeur: 263 },
            { nom: 'vaeefstyp_dsc', largeur: 132 },
            { nom: 'efsprc', largeur: 132 },
            { nom: 'efsdatvig', largeur: 125 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('eplcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('efsdatvig', { largeur: 25 }),
            new FormulaireItem('vaeefstyp', { largeur: 50 }),
            new FormulaireItem('efsprc', { largeur: 25 })
        ])
    };
}
