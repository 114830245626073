import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCC0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Cc0004Controller(Menu: IMenuClass, MenuItemForage: IMenuItemForageClass, cc0003Route: IRoute, ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageCC0004 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: cc0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.cofcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(cc0003Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'crscod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'crsdsc', largeur: 180 },
            { nom: 'trmcod', largeur: 120 },
            { nom: 'trmdsc', largeur: 180 },
            { nom: 'cofgrp', largeur: 120 },
            { nom: 'cofdscinfadd', largeur: 180 },
            { nom: 'cofdsc', largeur: 180 },
            { nom: 'unocod', largeur: 120 },
            { nom: 'unodscabr', largeur: 180 },
            { nom: 'litcod', largeur: 120 },
            { nom: 'litdsc', largeur: 180 },
            { nom: 'cofnbrcre', largeur: 140 },
            { nom: 'cofnbrhre', largeur: 140 },
            { nom: 'cofnbretumax', largeur: 140 },
            { nom: 'vaecofjrssem_dsc', largeur: 120, hidden: () => vm.valeursEcran.flgccoff === 1 },
            { nom: 'vaecofplg_dsc', largeur: 120, hidden: () => vm.valeursEcran.flgccoff === 1 },
            { nom: 'cofdatdeb', largeur: 110, hidden: () => vm.valeursEcran.flgccoff === 1 },
            { nom: 'cofdatfin', largeur: 110, hidden: () => vm.valeursEcran.flgccoff === 1 },
            { nom: 'cofhredeb', largeur: 110, hidden: () => vm.valeursEcran.flgccoff === 1 },
            { nom: 'cofhrefin', largeur: 110, hidden: () => vm.valeursEcran.flgccoff === 1 },
            { nom: 'cofnbretu', largeur: 120, hidden: () => vm.valeursEcran.flgccoff === 1 },
            { nom: 'cofflgact', largeur: 110 },
            { nom: 'cofflgatrprf', largeur: 120 },
            { nom: 'eplnummat', largeur: 140 },
            { nom: 'eplnomprn', largeur: 180 },
            { nom: 'atrprc', largeur: 120 }
        ]
    };
}
