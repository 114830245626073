import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFilterDate } from 'angular';

interface IPageAS0046 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0046Controller(Operateur: IOperateurService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    as0008Route: IRoute,
    as0017Route: IRoute,
    as0035Route: IRoute,
    dateFilter: IFilterDate,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IStateParamsService) {
    const vm: IPageAS0046 = this;
    const filtresParametres = [];

    if ($stateParams.paramdem) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'paramdem',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.paramdem,
                    readOnly: true
                }
            ]
        );
    }

    if ($stateParams.paramaft) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'paramaft',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.paramaft,
                    readOnly: true
                }
            ]
        );
    }
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: !$stateParams.demcleint,
            effaceCriteres: (!$stateParams.employe || !$stateParams.demcleint),
            criteresSuggeresVisibles: !$stateParams.employe || !$stateParams.demcleint
        },
        filtresParametres,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleint',
                {
                    readonly: ($stateParams.employe || $stateParams?.demcleint),
                    default: $stateParams?.demcleint || $stateParams?.employe,
                    hidden: $stateParams?.employe
                }),
            new FormulaireItem('actcleint', { lovSelectionMultiple: true, largeur: 20 }),
            new FormulaireItem('taycleint', { required: false, lovSelectionMultiple: true, largeur: 20 }),
            new FormulaireItem('athcleint', { required: false, lovSelectionMultiple: true, largeur: 20 }),
            new FormulaireItem('datdeb', {
                required: true,
                default: dateFilter($stateParams?.datdeb, 'yyyy-MM-dd'),
                ignoreEtat: $stateParams.datdeb
            }),
            new FormulaireItem('datfin', {
                required: true,
                default: dateFilter($stateParams?.datfin, 'yyyy-MM-dd'),
                ignoreEtat: $stateParams.datfin
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dim', { default: '1', largeur: 25 }),
            new FormulaireItem('lun', { default: '1', largeur: 25 }),
            new FormulaireItem('mar', { default: '1', largeur: 25 }),
            new FormulaireItem('mer', { default: '1', largeur: 25 }),
            new FormulaireItem('jeu', { default: '1', largeur: 25 }),
            new FormulaireItem('ven', { default: '1', largeur: 25 }),
            new FormulaireItem('sam', { default: '1', largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaesttprv'),
            new FormulaireItem('ppacleint'),
        ]),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 200 },
            { nom: 'eplnomprn', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'ppacod', largeur: 200 },
            { nom: 'sttdatdeb', largeur: 200 },
            { nom: 'sttjournee', largeur: 200 },
            { nom: 'actcod', largeur: 200 },
            { nom: 'actdsc', largeur: 200 },
            { nom: 'stthredeb', largeur: 200 },
            { nom: 'stthrefin', largeur: 200 },
            { nom: 'sttqte', largeur: 200 },
            { nom: 'lemcod', largeur: 200 },
            { nom: 'lemdsc', largeur: 200 },
            { nom: 'urgcod', largeur: 200 },
            { nom: 'urgdsc', largeur: 200 },
            { nom: 'strcod', largeur: 200 },
            { nom: 'strdsc', largeur: 200 },
            { nom: 'aftcod', largeur: 200 },
            { nom: 'aftdsc', largeur: 200 },
            { nom: 'tafcod', largeur: 200 },
            { nom: 'posnumdoc', largeur: 200 },
            { nom: 'postit', largeur: 200 },
            { nom: 'temcod', largeur: 200 },
            { nom: 'temdsc', largeur: 200 },
            { nom: 'unocod', largeur: 200 },
            { nom: 'unodsc', largeur: 200 },
            { nom: 'geccod', largeur: 200 },
            { nom: 'geccodcla', largeur: 200 },
            { nom: 'echcod', largeur: 200 },
            {
                nom: 'atfnumdoc', largeur: 200,
                menu: [
                    new MenuItemForage(
                        'LBL_RESERVE',
                        (Data: any) => getForageItem(Data),
                        (Data: any) => ({
                            id: Data.trncleintatf,
                            atfcleint: Data.atfcleint,
                            vaeatftyp: Data.vaeatftyp,
                            demcleint: Data.demcleint,
                            asgcleint: Data.asgcleint,
                            flginfor: 1
                        }),
                        {
                            fonction: 'RESERVES',
                            icon: 'launch',
                            iconButton: true,
                            hidden: (Data: any) => !Data.atfnumdoc
                        }
                    )
                ]
            },
            { nom: 'dalnumdoc', largeur: 200 },
            {
                nom: 'ahtcod', largeur: 200,
                menu: [
                    new MenuItemForage(
                        'LBL_RESERVE',
                        as0008Route.NAME,
                        (Data: any) => ({
                            id: Data.ahtcleint,
                            flginfor: 1,
                            asgcleint: Data.asgcleint,
                        }),
                        {
                            fonction: 'RESERVES',
                            icon: 'launch',
                            iconButton: true,
                            hidden: (Data: any) => !Data.ahtcod
                        }
                    )
                ]
            }
        ],
        colonnesCachees: ['asgcleint', 'atfcleint', 'vaeatftyp', 'trncleintatf', 'ahtcleint', 'athcleint']
    };

    function getForageItem(rowData: any): string {
        if (rowData.vaeatftyp === 'JR' || rowData.vaeatftyp === 'AJR') {
            return as0017Route.NAME
        }
        if (rowData.vaeatftyp === 'PHR' || rowData.vaeatftyp === 'APHR') {
            return as0035Route.NAME
        }
    }
}
