import { IComponentController } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCb0026 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    demMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tymMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0026Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    Pagination: IPaginationClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cb2003Route: IRoute
) {
    const vm: IPageCb0026 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.tymMultiOccurrenceOptions = {
        bloc: 'tym',
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 250 },
            { nom: 'crbmntsal', largeur: 130 },
            { nom: 'crbmntavs', largeur: 130 },
            { nom: 'crbqte', largeur: 130 },
            { nom: 'crbmntsalimp', largeur: 130, total: true },
            { nom: 'crbmntavsimp', largeur: 130, total: true },
            { nom: 'crbqteimp', largeur: 130, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        })
    };

    vm.demMultiOccurrenceOptions = {
        bloc: 'dem',
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('typappcri', {
                default: $stateParams.typapp,
                hidden: true
            }),
            new FormulaireItem('typbdgcri', {
                default: $stateParams.typbdg,
                hidden: true
            }),
            new FormulaireItem('pecanncri', {
                default: vm.valeursEcran.pecanncri,
                hidden: () => ($stateParams.typbdg === 'AN'),
                readonly: true
            }),
            new FormulaireItem('vaesysnatctbcri', {
                default: vm.valeursEcran.vaesysnatctb,
                readonly: true
            }),
            new FormulaireItem('peccleintfindeb', {
                default: $stateParams.peccleintdeb,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A' && $stateParams.typbdg === 'AN'),
                readonly: true
            }),
            new FormulaireItem('peccleintfinfin', {
                default: $stateParams.peccleintfin,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A' && $stateParams.typbdg === 'AN'),
                readonly: true
            }),
            new FormulaireItem('peccleintcivdeb', {
                default: $stateParams.peccleintdeb,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P' && $stateParams.typbdg === 'AN'),
                readonly: true
            }),
            new FormulaireItem('peccleintcivfin', {
                default: $stateParams.peccleintdeb,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P' && $stateParams.typbdg === 'AN'),
                readonly: true
            })
        ]),
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 120 },
            { nom: 'eplnomprn', largeur: 300 },
            { nom: 'crbmntsal', largeur: 126 },
            { nom: 'crbmntavs', largeur: 126 },
            { nom: 'crbqte', largeur: 126 }
        ],
        multiOccurrenceOptionsEnfant: vm.tymMultiOccurrenceOptions
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cb2003Route.NAME,
                (rowData: any) => ({
                    typrec: 'DEM',
                    typapp: $stateParams.typapp,
                    prucleint: $stateParams.prucleint,
                    prbcleint: $stateParams.prbcleint,
                    prvcleint: $stateParams.prvcleint,
                    el1cleint: $stateParams.el1cleint,
                    demcleint: rowData.demcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    tymcleint: rowData.tymcleint,
                    tydcleint: rowData.tydcleint,
                    pecann: $stateParams.pecann,
                    peccleintdeb: $stateParams.peccleintdeb,
                    peccleintfin: $stateParams.peccleintfin
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 130 },
            { nom: 'crbmntsal', largeur: 120 },
            { nom: 'crbmntavs', largeur: 120 }
        ],
        colonnesCachees: [
            'tymcleint',
            'tydcleint',
            'demcleint'
        ]
    };
}
