import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0316 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0316Params {
    alicleint: string;
}

/* @ngInject */
export default function Mr0316Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IMr0316Params,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0316 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            importation:true,
            boutonDupliquer:false
        },
        filtres: [{
            colonne: 'alicleint',
            valeur: $stateParams.alicleint,
            visible: false
        }],
        colonnesVisibles: [
            {nom: 'bqecod', largeur: 150},
            {nom: 'bqedscabr', largeur: 400}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('alicleint',{
                default: $stateParams.alicleint,
                hidden:true}),
            new FormulaireItem('bqecleint')
        ])
    };
}
