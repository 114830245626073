import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageAs0063 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0063Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageAs0063 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'tesnumdoc', largeur: 160},
            {nom: 'tesdat', largeur: 120},
            {nom: 'usrcod', largeur: 160},
            {nom: 'esvcod4', largeur: 140},
            {nom: 'esvdsc4', largeur: 200},
            {nom: 'esvcod1', largeur: 140},
            {nom: 'esvdsc1', largeur: 200},
            {nom: 'aqtcod', largeur: 160},
            {nom: 'aqtdsc', largeur: 200},
            {nom: 'esvcod2', largeur: 140},
            {nom: 'esvdsc2', largeur: 200},
            {nom: 'ecldsceta', largeur: 200},
            {nom: 'ttccod', largeur: 160},
            {nom: 'ttcdsc', largeur: 200},
            {nom: 'tescom', largeur: 200},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
