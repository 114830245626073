import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageCP0033 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ICp0033Params {
    lorcleint: string;
}

/* @ngInject */
export default function Cp0033Controller(Operateur: IOperateurService,
    $stateParams: ICp0033Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass
) {
    const vm: IPageCP0033 = this;

    const lorFiltresParametres = [];

    if ($stateParams.lorcleint) {
        lorFiltresParametres.push(
            ...[
                {
                    colonne: 'lorcleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.lorcleint
                }
            ]
        );
    }

    vm.multiOccurrenceOptions = {
        filtresParametres: lorFiltresParametres,
        fonctions: {
            rechercheCollapsable: false
        },
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_CONFIRMER',
                'BOUCON',
                {
                    selection: { nom: 'confirmer' }
                })
        ]),
        colonnesVisibles: [
            { nom: 'regnumdoc', largeur: 100 },
            { nom: 'regnumdocseq', largeur: 80 },
            { nom: 'regnomleg', largeur: 250 },
            { nom: 'regdat', largeur: 126 },
            { nom: 'regmnt', largeur: 100 },
            { nom: 'vaesysdevcod', largeur: 80 },
            { nom: 'regpay', largeur: 150 }
        ],
        colonnesFixesDroite: [
            { nom: 'regnumtra', largeur: 120 },
            { nom: 'devtaucou', largeur: 100 },
            { nom: 'regmnttra', largeur: 100 }
        ],
        colonnesCachees: [
            'tydcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('regnumtra', {readonly: (data:any) => (data.tydcleint === 89), required: (data:any) => (data.tydcleint === 79) }),
            new FormulaireItem('regmnttra', {required: true})
        ], { largeurDefaut: 25 }),
    };
}
