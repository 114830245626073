import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRR0081 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0081Controller(rr0082Route: IRoute,
    $stateParams: IStateParamsService,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0081 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: rr0082Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0082Route.NAME, () => {
                    return {
                        demcleint: $stateParams.employe
                    }
                })
        ]),        
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 130, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 270, hidden: $stateParams.employe }
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('eplnummat', { required: false }),
            new FormulaireItem('eplnomprn'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cledat', { default: null }),
            new FormulaireItem("numref")
        ]),
        colonnesVisibles: [
            { nom: 'reenumref', largeur: 142 },
            { nom: 'reenumrefmod', largeur: 142 },
            { nom: 'reecledat', largeur: 130 },
            { nom: 'reeseqrel', largeur: 90 },
            { nom: 'reehreass', largeur: 150 },
            { nom: 'reemntass', largeur: 150 },
            { nom: 'reedatemb', largeur: 160 },
            { nom: 'reedatderjrs', largeur: 160 },
            { nom: 'reedatderpep', largeur: 160 },
            { nom: 'vaereemot_dsc', largeur: 180 },
            { nom: 'reeflgret', largeur: 135 },
            { nom: 'reeflgdat', largeur: 125 },
            { nom: 'reedatrap', largeur: 150 },
            { nom: 'reeflggen', largeur: 125 },
            { nom: 'reeflgmsg', largeur: 125 },
            { nom: 'vaereevac1_dsc', largeur: 180 },
            { nom: 'reedatdebvac1', largeur: 150 },
            { nom: 'reedatfinvac1', largeur: 145 },
            { nom: 'reemntvac1', largeur: 150 },
            { nom: 'vaereevac2_dsc', largeur: 180 },
            { nom: 'reedatdebvac2', largeur: 150 },
            { nom: 'reedatfinvac2', largeur: 145 },
            { nom: 'reemntvac2', largeur: 150 },
            { nom: 'vaereevac3_dsc', largeur: 180 },
            { nom: 'reedatdebvac3', largeur: 150 },
            { nom: 'reedatfinvac3', largeur: 145 },
            { nom: 'reemntvac3', largeur: 150 },
            { nom: 'vaereevac4_dsc', largeur: 180 },
            { nom: 'reedatdebvac4', largeur: 150 },
            { nom: 'reedatfinvac4', largeur: 145 },
            { nom: 'reemntvac4', largeur: 150 },
            { nom: 'reedatfer1', largeur: 126 },
            { nom: 'reemntfer1', largeur: 150 },
            { nom: 'reedatfer2', largeur: 126 },
            { nom: 'reemntfer2', largeur: 150 },
            { nom: 'reedatfer3', largeur: 126 },
            { nom: 'reemntfer3', largeur: 150 },
            { nom: 'reedatfer4', largeur: 126 },
            { nom: 'reemntfer4', largeur: 150 },
            { nom: 'reedatfer5', largeur: 126 },
            { nom: 'reemntfer5', largeur: 150 },
            { nom: 'reedatfer6', largeur: 126 },
            { nom: 'reemntfer6', largeur: 150 },
            { nom: 'reedatfer7', largeur: 126 },
            { nom: 'reemntfer7', largeur: 150 },
            { nom: 'reedatfer8', largeur: 126 },
            { nom: 'reemntfer8', largeur: 150 },
            { nom: 'reedatfer9', largeur: 126 },
            { nom: 'reemntfer9', largeur: 150 },
            { nom: 'reedatfer10', largeur: 126 },
            { nom: 'reemntfer10', largeur: 150 },
            { nom: 'vaereedsc1_dsc', largeur: 207 },
            { nom: 'reedatdeb1', largeur: 126 },
            { nom: 'reedatfin1', largeur: 126 },
            { nom: 'reemnt1', largeur: 150 },
            { nom: 'vaereedsc2_dsc', largeur: 180 },
            { nom: 'reedatdeb2', largeur: 126 },
            { nom: 'reedatfin2', largeur: 126 },
            { nom: 'reemnt2', largeur: 180 },
            { nom: 'vaereedsc3_dsc', largeur: 180 },
            { nom: 'reedatdeb3', largeur: 126 },
            { nom: 'reedatfin3', largeur: 126 },
            { nom: 'reemnt3', largeur: 150 },
            { nom: 'vaereetyp_dsc', largeur: 180 },
            { nom: 'reedatcog', largeur: 140 },
            { nom: 'reedatfincog', largeur: 140 },
            { nom: 'reemntcog', largeur: 170 },
            { nom: 'vaereejrs_dsc', largeur: 130 },
            { nom: 'vaereetyp2_dsc', largeur: 180 },
            { nom: 'reedatcog2', largeur: 140 },
            { nom: 'reedatfincog2', largeur: 140 },
            { nom: 'reemntcog2', largeur: 170 },
            { nom: 'vaereejrs2_dsc', largeur: 130 },
            { nom: 'vaereetyp3_dsc', largeur: 180 },
            { nom: 'reedatcog3', largeur: 140 },
            { nom: 'reedatfincog3', largeur: 140 },
            { nom: 'reemntcog3', largeur: 170 },
            { nom: 'vaereejrs3_dsc', largeur: 130 },
            { nom: 'vaereetyp4_dsc', largeur: 180 },
            { nom: 'reedatcog4', largeur: 140 },
            { nom: 'reedatfincog4', largeur: 140 },
            { nom: 'reemntcog4', largeur: 170 },
            { nom: 'vaereejrs4_dsc', largeur: 130 },
            { nom: 'reeobs', largeur: 270 },
            { nom: 'rdwdsc', largeur: 270 },
            { nom: 'reerem01', largeur: 150 },
            { nom: 'reerem02', largeur: 150 },
            { nom: 'reerem03', largeur: 150 },
            { nom: 'reerem04', largeur: 150 },
            { nom: 'reerem05', largeur: 150 },
            { nom: 'reerem06', largeur: 150 },
            { nom: 'reerem07', largeur: 150 },
            { nom: 'reerem08', largeur: 150 },
            { nom: 'reerem09', largeur: 150 },
            { nom: 'reerem10', largeur: 150 },
            { nom: 'reerem11', largeur: 150 },
            { nom: 'reerem12', largeur: 150 },
            { nom: 'reerem13', largeur: 150 },
            { nom: 'reerem14', largeur: 150 },
            { nom: 'reerem15', largeur: 150 },
            { nom: 'reerem16', largeur: 150 },
            { nom: 'reerem17', largeur: 150 },
            { nom: 'reerem18', largeur: 150 },
            { nom: 'reerem19', largeur: 150 },
            { nom: 'reerem20', largeur: 150 },
            { nom: 'reerem21', largeur: 150 },
            { nom: 'reerem22', largeur: 150 },
            { nom: 'reerem23', largeur: 150 },
            { nom: 'reerem24', largeur: 150 },
            { nom: 'reerem25', largeur: 150 },
            { nom: 'reerem26', largeur: 150 },
            { nom: 'reerem27', largeur: 150 },
            { nom: 'reerem28', largeur: 150 },
            { nom: 'reerem29', largeur: 150 },
            { nom: 'reerem30', largeur: 150 },
            { nom: 'reerem31', largeur: 150 },
            { nom: 'reerem32', largeur: 150 },
            { nom: 'reerem33', largeur: 150 },
            { nom: 'reerem34', largeur: 150 },
            { nom: 'reerem35', largeur: 150 },
            { nom: 'reerem36', largeur: 150 },
            { nom: 'reerem37', largeur: 150 },
            { nom: 'reerem38', largeur: 150 },
            { nom: 'reerem39', largeur: 150 },
            { nom: 'reerem40', largeur: 150 },
            { nom: 'reerem41', largeur: 150 },
            { nom: 'reerem42', largeur: 150 },
            { nom: 'reerem43', largeur: 150 },
            { nom: 'reerem44', largeur: 150 },
            { nom: 'reerem45', largeur: 150 },
            { nom: 'reerem46', largeur: 150 },
            { nom: 'reerem47', largeur: 150 },
            { nom: 'reerem48', largeur: 150 },
            { nom: 'reerem49', largeur: 150 },
            { nom: 'reerem50', largeur: 150 },
            { nom: 'reerem51', largeur: 150 },
            { nom: 'reerem52', largeur: 150 },
            { nom: 'reerem53', largeur: 150 }
        ]
    };
}
