import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0317 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0317Params {
    alicleint: string;
}

/* @ngInject */
export default function Mr0317Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IMr0317Params,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0317 = this;

    vm.multiOccurrenceOptions = {
        filtres: [{
            colonne: 'alicleint',
            valeur: $stateParams.alicleint,
            visible: false
        }],
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 120},
            {nom: 'rcedscabr', largeur: 300},
            {nom: 'cotcod', largeur: 120},
            {nom: 'cotdsc', largeur: 300},
            {nom: 'vaealrsalbas_dsc', largeur: 175}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('alicleint',{
                default: $stateParams.alicleint,
                hidden:true}),
            new FormulaireItem('rcecleint',{largeur: 40}),
            new FormulaireItem('cotcleint',{largeur: 40}),
            new FormulaireItem('vaealrsalbas')
        ],{largeurDefaut: 20})
    };
}
