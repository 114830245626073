import {module} from 'angular';
import {ICouleurThemeService} from '../../../core/services/couleur-theme.service';

export default module('app.runs.colors', []).run(run);

/* @ngInject */
function run(couleurTheme: ICouleurThemeService) {
    couleurTheme.changerCouleur('cyan');
}

