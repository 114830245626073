import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0148 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0148Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0148 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'bltann'   , largeur: 130},
            {nom: 'tydcod'   , largeur: 150},
            {nom: 'tyddsc'   , largeur: 250},
            {nom: 'bltdatapp', largeur: 150}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bltann'),
            new FormulaireItem('tydcleint', {largeur: 50}),
            new FormulaireItem('bltdatapp')
        ], {largeurDefaut: 25})
    };
}
