import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageRr2031 extends IComponentController {
    phaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr2031Controller(
    Pagination: IPaginationClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr2031 = this;

    // Historique d'action(PM2)
    vm.phaMultiOccurrenceOptions = {
        fonctions: {
            pagination: true,
            rechercheCollapsable: false
        },
        pagination: new Pagination({
            nombreElementPage: 25,
            nbElementsPossibles: [25, 50, 100]
        }),
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 100 },
            { nom: 'usrnom', largeur: 100 },
            { nom: 'datcre', largeur: 100 }
        ]
    };

}
