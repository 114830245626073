import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGs0038 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0038Controller(gs0123Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGs0038 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gs0123Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0123Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'clscod', largeur: 100},
            {nom: 'clsnom', largeur: 520},
            {nom: 'vaeclstyp_dsc', largeur: 118}
        ]
    };
}
