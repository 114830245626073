import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';


interface IPageAS0038 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function As0038Controller(Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,) {
    const vm: IPageAS0038 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            edition: false,
            supprime: false
        },
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_AUTHORISE',
                'BOUGEN',
                {
                    selection: { nom: 'actionsGenerer' },
                    successAction: () => vm.multiOccurrence.fetchDataList()
                })
        ]),
        colonnesVisibles: [
            { nom: 'rgvnumseq', largeur: 130 },
            { nom: 'msvmsg', largeur: 160 },
            { nom: 'msvflgerr', largeur: 262 },
            { nom: 'msvflgavt', largeur: 65 },
            { nom: 'msvflgaut', largeur: 100 },
            { nom: 'msvdat', largeur: 100 },
            { nom: 'actcod', largeur: 100 },
            { nom: 'msvqte', largeur: 100 },
        ]
    };
}
