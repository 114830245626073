import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAS0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0019Controller(Menu: IMenuClass,
                                         as0012Route:IRoute,
                                         MenuItemForage: IMenuItemForageClass  ) {
    const vm: IPageAS0019 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: as0012Route.NAME,
        fonctions:{
            rechercheCollapsable:false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(as0012Route.NAME, () => {
                return {
                  
                    fonction: 'BOUNOUV'
                };
            })
        ]),
        colonnesFixesGauche:[
            {nom: 'asgcod', largeur: 190},
            {nom: 'asgdsc', largeur: 300}
        ],
        colonnesVisibles: [
            {nom: 'vaeasgmodges_desc', largeur: 200},
            {nom: 'vaeasgdebrot_desc', largeur: 200},
            {nom: 'afecod', largeur: 150},
            {nom: 'afedsc', largeur: 262},
            {nom: 't_flgaeq', largeur: 150},
            {nom: 'asgflgresurg', largeur: 190},
            {nom: 'asgflgrestem', largeur: 190},
            {nom: 'unpcod', largeur: 150},
            {nom: 'unpdsc', largeur: 262}
        ]
    };
}
