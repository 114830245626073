import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";

interface IPageRr0051 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDci: IFormulaire;
    valeursEcran: any;
}

interface IRr0051Params {
    id: string;
    demcleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0051Controller($stateParams: IRr0051Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageRr0051 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.formulaireDci = new Formulaire('LBL_CREDIT_IMPOT', [
            new FormulaireItem('demcleint', {
                default: $stateParams.demcleint,
                largeur: 50
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaedcityp'),
            new FormulaireItem('dciannciv'),
            new FormulaireItem('dcidatvig'),
            new FormulaireItem('dciflgact', {
                default: 1
            }),
            //new FormulaireItemDivider('LBL_CREDITS'),
            new FormulaireItem('dcicrtmntbas'),
            new FormulaireItem('dcicrtflgapp', {
                default: 1
            }),
            new FormulaireItem('dcicrtpac'),
            new FormulaireItem('dcidedmntreg'),
            new FormulaireItem('dcimntimpadd'),
            new FormulaireItem('dciprcexerev'),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe('LBL_ANNUEL', new Formulaire([
                new FormulaireItem('dcicrtannaut'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dcidedannaut'),
            ]), {largeur: 23}),
            new FormulaireGroupe('LBL_EXONERATION', new Formulaire([
                new FormulaireItem('dciflgexoimp', {largeur:15}),
                new FormulaireItem('dciflgexorrq', {largeur:15}),
                new FormulaireItem('dciflgexotxs', {largeur:15}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dciflgexoase', {largeur:15}),
                new FormulaireItem('dciflgexorqa', {largeur:15}),
                new FormulaireItem('dciflgexocne', {largeur:15}),
            ]), {largeur: 77})
        ], {
            largeurDefaut: 25
        });
    }
}
