import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";

interface IPageMr0205 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dtiMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0205Controller(mr2003Route: IRoute,
    mr2002Route: IRoute,
    mc0099Route: IRoute,
    mr0228Route: IRoute,
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0205 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_VENT', [
                new FormulaireItem('eplnummat', { largeur: 25 }),
                new FormulaireItem('eplnomprn', { largeur: 75 }),
                new FormulaireItem('dnedsc', { largeur: 25 }),
                new FormulaireItem('dnedatdebsim', { largeur: 25 }),
                new FormulaireItem('dnedatfinsim', { largeur: 25 })
            ]),
            fonctions: ({
                boutonEnregistrerHaut: false
            }),
            menus: [
                new MenuItemForage(
                    'LBL_MSG',
                    mr2003Route.NAME,
                    (data: any) => ({
                        dnecleint: data.dnecleint
                    }), {
                    icon: 'subtitles',
                    iconButton: false,
                    secondaire: true,
                    compteur: ecranDetails.valeurs.nbmsg
                }),
                new MenuItemForage(
                    'G_LBL_DOC_FIN',
                    mc0099Route.NAME,
                    (data: any) => ({
                        id: data.doccleint
                    }), {
                    icon: 'subtitles',
                    iconButton: false,
                    secondaire: true
                }
                ),
                new MenuItemForage(
                    'G_LBL_DOC_REE',
                    mr0228Route.NAME,
                    (data: any) => ({
                        doccleintgrh: data.doccleint
                    }), {
                    icon: 'subtitles',
                    iconButton: false,
                    secondaire: true
                })
            ],
            infosFinancieres: {
                disponibilite: true
            }
        };

        vm.dtiMultiOccurrenceOptions = {
            navigatePage: mr2002Route.NAME,
            navigateParams: (data: any) => ({
                id: data.cle_unique,
                ppacleint: data.ppacleint,
                ppacod: data.ppacod,
                vaedtitypvnt: data.vaedtitypvnt,
                typvnt_dsc: data.typvnt_dsc,
                imp: data.imp,
                dtidatfineng: data.dtidatfineng,
                prucleint: data.prucleint,
                prscleint: data.prscleint,
                cifcleint: data.cifcleint,
                unacleint: data.unacleint,
                cptcleint: data.cptcleint,
                el1cleint: data.el1cleint,
                el2cleint: data.el2cleint,
                el3cleint: data.el3cleint
            }),
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_MSG',
                    mr2003Route.NAME,
                    (data: any) => ({
                        dnecleint: data.dnecleint,
                        ppacleint: data.ppacleint,
                        vaedtitypvnt: data.vaedtitypvnt,
                        prucleint: data.prucleint,
                        prscleint: data.prscleint,
                        cifcleint: data.cifcleint,
                        unacleint: data.unacleint,
                        cptcleint: data.cptcleint,
                        el1cleint: data.el1cleint,
                        el2cleint: data.el2cleint,
                        el3cleint: data.el3cleint
                    }), {
                    iconButton: false,
                    icon: '',
                    hidden: (data: any) => !data.flg_err
                })
            ]),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('grpvnt', { default: 'TOUS' }),
            ]),
            colonnesVisibles: [
                { nom: 'ppacod', largeur: 126 },
                { nom: 'typvnt_dsc', largeur: 140 },
                { nom: 'imp', largeur: 200 },
                { nom: 'dtimnt', largeur: 120, total: true },
                { nom: 'dtimntposexe', largeur: 139, total: true },
                { nom: 'dtidatfineng', largeur: 140 },
                { nom: 'flg_err', largeur: 140 }
            ],
            colonnesCachees: [
                'dnecleint',
                'ppacleint',
                'vaedtitypvnt',
                'ppacod',
                'typvnt_dsc',
                'imp',
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint'
            ]
        };
    }
}
