import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0061 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCyrOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0061Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMr0061 = this;

    vm.multiOccurrenceCyrOptions = {
        bloc: 'cyr',
        fonctions: {
            pagination: true,
            nouveau: false,
            supprime: false,
            edition: false
        },
        colonnesVisibles: [
            { nom: 'cyrcod', largeur: 10 },
            { nom: 'cyrdsc', largeur: 90 },
            { nom: 'cyrcom', largeur: 90 }
        ]
    };
    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'eclcod', largeur: 100 },
            { nom: 'ecldsceta', largeur: 260 },
            { nom: 'ecldscact', largeur: 260 },
            { nom: 'eclcom', largeur: 652 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('eclcod'),
            new FormulaireItem('ecldsceta'),
            new FormulaireItem('ecldscact'),
            new FormulaireItem('eclcom', { enableCount: true })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCyrOptions
    };
}
