import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IController } from 'angular';

interface IPageAs0006 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRgvOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0006Controller() {
    const vm: IPageAs0006 = this;

    vm.multiOccurrenceRgvOptions = {
        bloc: 'rgv',
        colonnesVisibles: [
            { nom: 'stamnetab_dsc', largeur: 100 },
            { nom: 'codref', largeur: 70 },
            { nom: 'dscref', largeur: 180 },
            { nom: 'flgact', largeur: 40 }
        ],
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'madnummth', largeur: 80 },
            { nom: 'maddsc', largeur: 170 },
            { nom: 'vaemadtyp_dsc', largeur: 130 },
            { nom: 'maddoc', largeur: 260 }
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceRgvOptions
    };
}
