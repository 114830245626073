import { IComponentController } from "angular";
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageDb0030 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    cptMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cptMultiOccurrence: IMultiOccurrence;
    cenMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cenMultiOccurrence: IMultiOccurrence;
    cepMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cepMultiOccurrence: IMultiOccurrence;
    refMultiOccurrenceOptions: IMultiOccurrenceOptions;
    coeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ceiMultiOccurrenceOptions: IMultiOccurrenceOptions;

}

/* @ngInject */
export default function Db0030Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         cp2010Route: IRoute,
                                         cr0007Route: IRoute,
                                         cr0013Route: IRoute,
                                         cr0021Route: IRoute,
                                         cr0024Route: IRoute,
                                         cr0041Route: IRoute,
                                         cr2001Route: IRoute,
                                         db0005Route: IRoute,
                                         db0012Route: IRoute,
                                         gl0003Route: IRoute,
                                         if0008Route: IRoute,                                         
                                         mc0099Route: IRoute,
                                         mc0101Route: IRoute,
                                         mc0109Route: IRoute,                                         
                                         mr0212Route: IRoute,                                         
                                         rr0004Route: IRoute
                                        ) {
    const vm: IPageDb0030 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('peccleintdebcri', { largeur: 16.5,
            required: true,
            nombreLibellesDetailsCacher: 1
        }),
        new FormulaireItem('peccleintfincri', { largeur: 16.5,
            required: true,
            nombreLibellesDetailsCacher: 1
        }),
        new FormulaireItem('cifcleintcri'),
        new FormulaireItem('naccleintcri'),

        new FormulaireItem('prjcleintcri'),
        new FormulaireItem('orgcleintcri'),
        new FormulaireItem('intcleintcri'),

        new FormulaireItem('prucleintcri'),
        new FormulaireItem('prscleintcri'),
        new FormulaireItem('blocleintcri'),

        new FormulaireItem('vectypappcri'),
        new FormulaireItem('mapcodcri'),
        new FormulaireItemSautDeLigne(),

        new FormulaireItem('refcleintcri', {
            resourceParams: {
                type: 'FOU'
            },
        }),
        new FormulaireItem('grpcleintcri'),
        new FormulaireItem('unacleintcri'),

        new FormulaireItem('cptcleintcri'),
        new FormulaireItem('vaeprutypbdgcri', {
            default: 'P'
        }),
        new FormulaireItem('pruflgstucri', {
            default: 2
        })
    ], { largeurDefaut: 33.33 });

    vm.cptMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },
        colonnesFixesGauche: [
            {nom: 'cptcod', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'cptdsc', largeur: 288},
            {nom: 'nature', largeur: 115}
        ],
        colonnesCachees: [
            'peccleint',
            'cptcleint',
            'doccleint01'
        ],
        colonnesFixesDroite: [
            {nom: 'mnt_nonapp', largeur: 126},
            {nom: 'mnt_app', largeur: 126},
            {nom: 'mnttot', largeur: 126}
        ]
    };


vm.cenMultiOccurrenceOptions = {
    bloc: 'cen',
    fonctions: {
        customCriteresSuggeres: true,
        rechercheCollapsable: false,
        effaceCriteres: true,
        pagination: true
    },
    criteresSuggeres,

     pagination: {
        nbElementsPossibles: [100, 200, 300],
        nombreElementPage: 100
    },
    colonnesFixesGauche: [
        {nom: 'cptcod', largeur: 100}
    ],
    colonnesVisibles: [
        {nom: 'cptdsc', largeur: 288},
        {nom: 'nature', largeur: 115},
        {nom: 'docrefcod02', largeur: 100},
        {nom: 'docrefnom02', largeur: 288},
        {nom: 'mapcod', largeur: 110},
        {nom: 'mapnom', largeur: 315}
    ],
    colonnesFixesDroite: [
        {nom: 'mnt_nonapp', largeur: 126},
        {nom: 'mnt_app', largeur: 126},
        {nom: 'mnttot', largeur: 126}
    ]
};

vm.cepMultiOccurrenceOptions = {
    bloc: 'cep',
    fonctions: {
        customCriteresSuggeres: true,
        rechercheCollapsable: false,
        effaceCriteres: true,
        pagination: true
    },
    criteresSuggeres,

    pagination: {
        nbElementsPossibles: [100, 200, 300],
        nombreElementPage: 100
    },
    colonnesFixesGauche: [
        {nom: 'cptcod', largeur: 100}
    ],    
    colonnesVisibles: [
        {nom: 'cptdsc', largeur: 288},
        {nom: 'nature', largeur: 115},
        {nom: 'docrefcod02', largeur: 100},
        {nom: 'docrefnom02', largeur: 288},
        {nom: 'mapcod', largeur: 110},
        {nom: 'mapnom', largeur: 315},
        {nom: 'docaffnumdoc01', largeur: 130},
        {nom: 'tydcod', largeur: 80},
        {nom: 'tyddsc', largeur: 274},
        {nom: 'peccod', largeur: 90},
        {nom: 'docaffdat01', largeur: 130},
        {nom: 'docaffdsc01', largeur: 370},
        {nom: 'trbdsc', largeur: 262},
        {nom: 'prucod', largeur: 90},
        {nom: 'prudscl01', largeur: 262}
    ],
    colonnesFixesDroite: [
        {nom: 'mnt_nonapp', largeur: 126},
        {nom: 'mnt_app', largeur: 126},
        {nom: 'mnttot', largeur: 126}
    ]
};

vm.refMultiOccurrenceOptions = {
    bloc: 'ref',
    actionsRangeeDroite: new Menu([
        new MenuItemForage(
            'LBL_INFO_FINANC',
            (rowData: any) => getForageItem(rowData),
            (rowData: any) => getParamItem(rowData),
            {
                icon: 'exit_to_app',
                fonction: 'BOUOUV',
                disabled: (rowData: any) => rowData.tydflgdocgrh === 1 && rowData.tydcleintref !== 200 && rowData.tydcleintref !== 301
            }
        )
    ]),
    colonnesVisibles: [
        {nom: 'docrefcod02', largeur: 100},
        {nom: 'docrefnom02', largeur: 200},
        {nom: 'docaffnumdoc01', largeur: 100},
        {nom: 'tydcod', largeur: 120},
        {nom: 'tyddsc', largeur: 180},
        {nom: 'peccod', largeur: 120},
        {nom: 'docaffdat01', largeur: 120},
        { nom: 'mapcod', largeur: 100 },
        { nom: 'mapnom', largeur: 200 },
        { nom: 'mnt_nonapp', largeur: 130 },
        { nom: 'mnt_app', largeur: 130 },
        { nom: 'mnttot', largeur: 130 }
    ],
    colonnesCachees: [
        'doccleint01',
        'ecrcod',
        'reqcleint'
    ]
};

vm.coeMultiOccurrenceOptions = {
    bloc: 'coe',
    actionsRangeeDroite: new Menu([
        new MenuItemForage(
            'LBL_INFO_FINANC',
            (rowData: any) => getForageItem(rowData),
            (rowData: any) => getParamItem(rowData),
            {
                icon: 'exit_to_app',
                fonction: 'BOUOUV',
                disabled: (rowData: any) => rowData.tydflgdocgrh === 1 && rowData.tydcleintref !== 200 && rowData.tydcleintref !== 301
            }
        )
    ]),
    colonnesVisibles: [
        { nom: 'docaffnumdoc01', largeur: 100 },
        { nom: 'tydcod', largeur: 100 },
        { nom: 'tyddsc', largeur: 200 },
        { nom: 'peccod', largeur: 100 },
        { nom: 'docaffdat01', largeur: 100 },
        { nom: 'docrefcod02', largeur: 100 },
        { nom: 'docrefnom02', largeur: 200 },
        { nom: 'mapcod', largeur: 110 },
        { nom: 'mapnom', largeur: 200 },
        { nom: 'mnt_nonapp', largeur: 130 },
        { nom: 'mnt_app', largeur: 130 },
        { nom: 'mnttot', largeur: 130 }
    ],
    colonnesCachees: [
        'doccleint01'
    ]
};

vm.ceiMultiOccurrenceOptions = {
    bloc: 'cei',
    actionsRangeeDroite: new Menu([
        new MenuItemForage(
            'LBL_INFO_FINANC',
            (rowData: any) => getForageItem(rowData),
            (rowData: any) => getParamItem(rowData),
            {
                icon: 'exit_to_app',
                fonction: 'BOUOUV',
                disabled: (rowData: any) => rowData.tydflgdocgrh === 1 && rowData.tydcleintref !== 200 && rowData.tydcleintref !== 301
            }
        )
    ]),
    colonnesVisibles: [
        { nom: 'docaffnumdoc01', largeur: 100 },
        { nom: 'tyddsc', largeur: 130 },
        { nom: 'peccod', largeur: 100 },
        { nom: 'docaffdat01', largeur: 100 },
        { nom: 'docrefcod02', largeur: 100 },
        { nom: 'docrefnom02', largeur: 130 },
        { nom: 'mapcod', largeur: 100 },
        { nom: 'mapnom', largeur: 130 },
        { nom: 'mnt_nonapp', largeur: 130 },
        { nom: 'mnt_app', largeur: 130 },
        { nom: 'mnttot', largeur: 130 }
    ],
    colonnesCachees: [
        'doccleint01'
    ]
};

function getForageItem(rowData: any): string {
    if (rowData.ecrcod === 'IF0008-00-2') {
        return if0008Route.NAME;
    } else if (rowData.ecrcod === 'CP0013-00-2') {
        return cp2010Route.NAME;       
    } else if (rowData.ecrcod === 'CR0007-00-2') {
        return cr0007Route.NAME;
    } else if (rowData.ecrcod === 'CR0013-00-2') {
        return cr0013Route.NAME;
    } else if (rowData.ecrcod === 'CR0021-00-2') {
        return cr0021Route.NAME;
    } else if (rowData.ecrcod === 'CR0024-00-2') {
        return cr0024Route.NAME;
    } else if (rowData.ecrcod === 'CR0041-00-2') {
        return cr0041Route.NAME;
    } else if (rowData.ecrcod === 'CR2001-00-2') {
        return cr2001Route.NAME;
    } else if (rowData.ecrcod === 'DB0005-00-2') {
        return db0005Route.NAME;
    } else if (rowData.ecrcod === 'DB0012-00-2') {
        return db0012Route.NAME;
    } else if (rowData.ecrcod === 'GL0003-00-2') {
        return gl0003Route.NAME;                
    } else if (rowData.ecrcod === 'MC0099-00-2') {
        return mc0099Route.NAME;
    } else if (rowData.ecrcod === 'MC0101-00-2') {
        return mc0101Route.NAME;
    } else if (rowData.ecrcod === 'MC0109-00-2') {
        return mc0109Route.NAME;    
    } else if (rowData.ecrcod === 'MR0212-00-2') {
        return mr0212Route.NAME;        
    } else if (rowData.ecrcod === 'RR0004-00-2') {
        return rr0004Route.NAME;        
    } /*else if (data.ecrcod === 'CB0053-00') {
        return cb0053Route.NAME;
    }*/
}

function getParamItem(rowData: any) {
    const MONOS = [
        'PD0006-00',
        'GL0020-00',
        'IF0008-00-2',
        'CP0029-00',
        'AC0070-00',
        'AC0070-01',
        'CR0013-00-2',
        'CR0024-00-2',
        'IN0014-00',
        'GT0010-00',
        'GA0007-00-2',
        'DB0005-00-2',
        'CP0014-00',
        'CP0040-00',
        'DB0012-00-2',
        'IN0043-00-2',
        'AC0037-00',
        'AC0010-02',
        'MC0109-00-2',
        'CP0013-01',
        'CP0013-00',
        'IN0035-00',
        'MR0212-00-2',
        'CR0021-00-2',
        'CR0016-00-2',
        'GL0003-00-2',
        'CP0018-00',
        'CR0007-00-2',
        'CP0026-00',
        'DB0023-00'
    ];
    if (rowData.ecrcod === 'CP0013-00-2') {
        return { id: rowData.reqcleint };        
    } else if  (rowData.ecrcod === 'CR0007-00-2') {
        return { id: rowData.doccleint01};
    } else if (rowData.ecrcod === 'CR0013-00-2') {
        return { id: rowData.doccleint01};        
    } else if (rowData.ecrcod === 'CR0021-00-2') {
        return { id: rowData.doccleint01};        
    } else if (rowData.ecrcod === 'CR0024-00-2') {
        return { id: rowData.doccleint01};        
    } else if (rowData.ecrcod === 'CR0041-00-2') {
        return { id: rowData.doccleint01};        
    } else if (rowData.ecrcod === 'CR2001-00-2') {
        return { id: rowData.doccleint01};        
    } else if (rowData.ecrcod === 'DB0005-00-2') {
        return { id: rowData.doccleint01};        
    } else if (rowData.ecrcod === 'DB0012-00-2') {
        return { id: rowData.doccleint01};        
    } else if (rowData.ecrcod === 'GL0003-00-2') {
        return { id: rowData.doccleint01};    
    } else if (rowData.ecrcod === 'MC0099-00') {
        return { id: rowData.doccleintref, prucleint: rowData.prucleint, vaesysnatctb: rowData.vaesysnatctb };
    } else if (rowData.ecrcod === 'MC0101-00-2') {
        return { id: rowData.doccleint01, doccleintref: rowData.doccleint01 };
    } else if (rowData.ecrcod === 'MC0109-00-2') {
        return { id: rowData.doccleint01, doccleintref: rowData.doccleint01 };        
    } else if (rowData.ecrcod === 'MR0212-00-2') {
        return { id: rowData.doccleint01 };
    } else if (rowData.ecrcod === 'RR0004-00-2') {
        return { id: rowData.prucleint };
    } else if (MONOS.includes(rowData.ecrcod) && rowData.tydcleintref !== 135) {
        return { id: rowData.doccleintref };
    }
}
}
