import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import {  IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageTf0023 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions; 
}

/* @ngInject */
export default function Tf0023Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,                                         
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageTf0023 = this;

    vm.monoOccurrenceOptions={

        formulaire: new Formulaire( [
            new FormulaireItem('efacleint',{largeur: 66, 
                                            required: (data) => data.flgactbloc,
                                            disabled: (data) => !data.flgactbloc}),
            new FormulaireItemSautDeLigne(),
             new FormulaireItem('folcleint',{largeur: 66, 
                                            required: (data) => data.flgactbloc,
                                            disabled: (data) => !data.flgactbloc}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('rfecleint',{largeur: 66, 
                                            required: (data) => data.flgactbloc,
                                            disabled: (data) => !data.flgactbloc}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('foacleint',{largeur: 66, 
                                             required: (data) => data.flgactbloc,
                                             disabled: (data) => !data.flgactbloc}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('fovcleint',{largeur: 66, 
                                            required: (data) => data.flgactfovcl,
                                            disabled: (data) => !data.flgactfovcl}),
             new FormulaireItemSautDeLigne(),  
              new FormulaireItem('tfsvaltxt',{largeur: 100, 
                                            required: (data) => data.flgactvaltxt, 
                                            disabled: (data) => !data.flgactvaltxt}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('tfsflguti',{largeur: 50, disabled: true, default: ecranDetails.valeurs.tfsflguti}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('flgactfovcl',{hidden: true, largeur: 66, default: ecranDetails.valeurs.flgactfovcl}), 
             new FormulaireItem('flgactvaltxt',{hidden:true, largeur: 66, default: ecranDetails.valeurs.flgactvaltxt}), 
             new FormulaireItem('flgactbloc' ,{hidden:true, largeur: 66, default: ecranDetails.valeurs.flgactbloc}),            
        ])
    }   
};


