import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr2012 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    espMultiOccurrenceOptions: IMultiOccurrenceOptions;
    esvMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function mr2012Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr2012 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_ESC', [
            new FormulaireItem('escseq'),
            new FormulaireItem('escdsc'),
            new FormulaireItem('esclib'),
            new FormulaireItem('esdcleint', { modifAllow: false }),
            new FormulaireItem('esccleintmai', { modifAllow: false }),
            new FormulaireItem('escflgcreval')
        ], { largeurDefaut: 33.33 })
    };

    vm.espMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'vaeesptrt_dsc', largeur: 200 },
            { nom: 'espflgobl', largeur: 120 },
            { nom: 'espflgoblmai', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeesptrt'),
            new FormulaireItem('espflgobl'),
            new FormulaireItem('espflgoblmai')
        ])
    };

    vm.esvMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'esvcodmai', largeur: 180 },
            { nom: 'esvcod', largeur: 180 },
            { nom: 'esvdsc', largeur: 250 },
            { nom: 'esvdatdeb', largeur: 126 },
            { nom: 'esvdatfin', largeur: 126 },
            { nom: 'esvflgact', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('esvcleintmai',
                { resourceParams: (data: any) => ({esccleint: data.$ancetre.esccleintmai}) }),
            new FormulaireItem('esvcod'),
            new FormulaireItem('esvdsc'),
            new FormulaireItem('esvdatdeb'),
            new FormulaireItem('esvdatfin'),
            new FormulaireItem('esvflgact')
        ], { largeurDefaut: 33.33 })
    };
}