import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import {IComponentController} from "angular";
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';


interface IPageTf2001 extends IComponentController {    
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireEmd: IFormulaire;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Tf2001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
        const vm: IPageTf2001 = this;

        vm.formulaireEmd = new Formulaire('', [
            new FormulaireItem('foacod')
        ], {largeurDefaut: 66});

        vm.detMultiOccurrenceOptions = {
            bloc: 'det',            
            colonnesFixesGauche: [
                {nom: 'vaedcatyp', largeur: 80},
                {nom: 'refcod', largeur: 100},
                {nom: 'refdsc', largeur: 250}  
            ],
            colonnesVisibles: [               
                {nom: 'dcamnt', largeur: 100},
                {nom: 'dcamntcal', largeur: 140},
                {nom: 'dcacodnot', largeur: 100},
                {nom: 'dcanot', largeur: 100},
                {nom: 'dcaindaju', largeur: 100}
            ],
            fonctions: {
                supprime: ecranDetails.valeurs.flgactblocemd,
                edition:  ecranDetails.valeurs.flgactblocemd,
                nouveau:  ecranDetails.valeurs.flgactblocemd
            },
            formulaire: new Formulaire([
                new FormulaireItem('vaedcatyp',{largeur: 33}),
                new FormulaireItem('ctrcleintcodref', {largeur: 33}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dcamnt',{largeur: 33}),
                new FormulaireItem('dcamntcal',{largeur: 33}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dcacodnot',{largeur: 33}),
                new FormulaireItem('dcanot',{largeur: 33}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('flgactblocemd',{ default: ecranDetails.valeurs.flgactblocemd}),
                new FormulaireItem('dcacleint',{ largeur: 33})
            ])
        };
}