import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IPaginationClass} from "../../../../../core/services/pagination.service";


interface IPageRr0533 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    dagMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function rr0533Controller(Pagination: IPaginationClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         rr0031Route: IRoute) {
    const vm: IPageRr0533 = this;

    const forageAdh = new MenuItemForage(
        'LBL_FOR_REF',
        rr0031Route.NAME,
        (data: any) => {
            return {
                id: data.trncleintref
            }
        },
        {hidden: (data: any) => !data.daanumdoc,fonction:'BTN_BOUADH'}
    );
    vm.dagMultiOccurrenceOptions = {
        bloc: 'dag',
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 50, 100]
        }),
        colonnesCachees: [
            'demcleint',
            'trncleintref'
        ],
        colonnesFixesGauche: [
            {nom: 'placod', largeur: 100},
            {nom: 'pladsc', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'daanumdoc', largeur: 160,menu: [forageAdh]},
            {nom: 'plccod', largeur: 100},
            {nom: 'plcdsc', largeur: 240},
            {nom: 'dagdatmod', largeur: 120},
            {nom: 'dakcodcli', largeur: 120},
            {nom: 'asocod', largeur: 80},
            {nom: 'asodsc', largeur: 240},
            {nom: 'aspcod', largeur: 80},
            {nom: 'aspdsc', largeur: 240},
            {nom: 'dagmntsal', largeur: 126},
            {nom: 'dagdcovalold', largeur: 160},
            {nom: 'dagdcovalnew', largeur: 160},
            {nom: 'dabdatprd', largeur: 120},
            {nom: 'dabtit', largeur: 340}
        ]
    };
    vm.multiOccurrenceOptions = {
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 25, 50]
        }),
        colonnesVisibles: [
            {nom: 'unpcod', largeur: 120},
            {nom: 'unpdsc', largeur: 285},
            {nom: 'ppacod', largeur: 100},
            {nom: 'ppadatdeb', largeur: 100},
            {nom: 'ppadatfin', largeur: 100},
            {nom: 'dabdatprd', largeur: 120}

        ],
        multiOccurrenceOptionsEnfant: vm.dagMultiOccurrenceOptions
    };
}
