import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePF0035 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0035Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         pf0034Route:IRoute) {
    const vm: IPagePF0035 = this;

    // Forage pour l'inscription
    const forageProfil = new MenuItemForage(
        pf0034Route.NAME,
        (data: any) => ({
            id: data.xppcleint
        }),
    { fonction:'BOUCOD' }

    );

    vm.multiOccurrenceOptions = {
        colonnesCachees: ['xppcleint'],
        colonnesFixesGauche: [
            {nom: 'xppcod', largeur: 160 , menu: [forageProfil]},
            {nom: 'xppdsc', largeur: 300},
        ],
        colonnesVisibles: [
            {nom: 'xppseqaff', largeur: 90},
            {nom: 'comcod', largeur: 112},
            {nom: 'comdsc', largeur: 275},
            {nom: 'nivdsc', largeur: 275},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 275},
            {nom: 'posnumdoc', largeur: 90},
            {nom: 'postitabr', largeur: 275},
            {nom: 'litcod', largeur: 100},
            {nom: 'litdsc', largeur: 275}
        ],
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
