import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2003',
    ecrans: ['00-2'],
    params: [
        '?typrec',
        '?typapp',
        '?prucleint',
        '?prbcleint',
        '?prvcleint', 
        '?el1cleint', 
        '?demcleint', 
        '?poscleint', 
        '?vaesysnatctb', 
        '?tymcleint', 
        '?tydcleint', 
        '?pecann',
        '?peccleintdeb',
        '?peccleintfin'
    ]
});
