import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageCS0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cs0002Controller(cs0001Route: IRoute) {
    const vm: IPageCS0002 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cs0001Route.NAME,

        fonctions: {
            nouveau: false
        },
        navigateParams: (rowData: any) => {
            return {
                demcleint: rowData.demcleint
            };
        },

        colonnesCachees: [
            'demcleint'
        ],
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'dalnumdoc', largeur: 90},
            {nom: 'tdlcod', largeur: 90},
            {nom: 'diadat', largeur: 105},
            {nom: 'vacvalcle', largeur: 90},
            {nom: 'vacdsc', largeur: 156},
            {nom: 'diaconmed', largeur: 262},
            {nom: 'lemcod', largeur: 105},
            {nom: 'lemdsc', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'unocod', largeur: 112},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'grecod', largeur: 90},
            {nom: 'gredsc', largeur: 262},
            {nom: 'lemcod_deb', largeur: 105},
            {nom: 'lemdsc_deb', largeur: 262},
            {nom: 'urgcod_deb', largeur: 90},
            {nom: 'urgdscabr_deb', largeur: 262},
            {nom: 'strcod_deb', largeur: 90},
            {nom: 'strdsc_deb', largeur: 262},
            {nom: 'unocod_deb', largeur: 112},
            {nom: 'unodscabr_deb', largeur: 262},
            {nom: 'temcod_deb', largeur: 90},
            {nom: 'temdscabr_deb', largeur: 262},
            {nom: 'grecod_deb', largeur: 90},
            {nom: 'gredsc_deb', largeur: 262},
            {nom: 'epldatnai', largeur: 105},
            {nom: 'vaeeplsex_dsc', largeur: 100},
            {nom: 'daldatdeb', largeur: 105},
            {nom: 'daldatret', largeur: 105},
            {nom: 'daldatretprg', largeur: 105}
        ]
    };
}
