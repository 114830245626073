import { IComponentController, ISCEService, IScope } from "angular";
import { IMultiOccurrenceOptions, IMultiOccurrence } from "../../../../../core/services/multi-occurrence.service";
import { IApiConfig } from "../../../../../core/interfaces/api-config.interface";
import { IParametresSecuriteService } from "../../../../../core/services/parametres-securite.service";
import { IStateParamsService } from "angular-ui-router";

interface IPageCp2002 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    url: string;
    isImageUrl: boolean;
}

/* @ngInject */
export default function Cp2001Controller($sce: ISCEService,
    $scope: IScope,
    $stateParams: IStateParamsService,
    ApiConfig: IApiConfig,
    parametresSecurite: IParametresSecuriteService) {
    const vm = this as IPageCp2002;

    vm.$onInit = function $onInit() {
        vm.multiOccurrenceOptions = {
            fonctions: {
                nouveau: false,
                edition: false,
                supprime: false,
                suiviModification: false,
                enfantCollapsable: false
            },
            pagination: {
                nombreElementPage: 10,
                nbElementsPossibles: [10, 25, 50]
            },
            colonnesVisibles: [
                { nom: 'rladsc', largeur: 150 },
                { nom: 'rlamnt', largeur: 150 },
                { nom: 'rladat', largeur: 150 },
                { nom: 'rlacom', largeur: 150 }
            ],
            colonnesCachees: [
                'bloc',
                'rldcleint'
            ],
            multiOccurrenceOptionsEnfant: {
                bloc: 'pjb',
                fonctions: {
                    recherche: false,
                },
                pagination: {
                    nombreElementPage: 10,
                    nbElementsPossibles: [10, 25, 50]
                },
                colonnesVisibles: [
                    { nom: 'pjbdsc', largeur: 150 }
                ],
                colonnesCachees: [
                    'pjbext'
                ]
            }
        };

        $scope.$watch('vm.multiOccurrence.multiOccurrenceEnfant.activeRowCleint', (activeRowCleint) => {
            if (activeRowCleint) {
                const params = $.param({
                    cleintref: vm.multiOccurrence.getDataActiveRow().id,
                    srccod: 'cp2001',
                    screen: true,
                    rldcleint: $stateParams.rldcleint,
                    ...parametresSecurite($stateParams)
                });

                vm.url = $sce.trustAsResourceUrl(`${ApiConfig.ROOT}/gs0044/edition/${activeRowCleint}/pjbdat?${params}&a#toolbar=0&navpanes=0`);
                vm.isImageUrl = /(png|PNG|jpg|JPG|gif|GIF)$/.test(vm.multiOccurrence.multiOccurrenceEnfant.getDataActiveRow().pjbext)
            } else {
                vm.url = undefined;
                vm.isImageUrl = false;
            }
        })
    }
}
