import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageRr0108 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    cpbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IRr0108Params {
    stamnetab: string;
    cpecleintref: number;
    trncleint: number;
}

/* @ngInject */
export default function Rr0108Controller($stateParams: IRr0108Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemEcran: IMenuItemEcranClass,
    Formulaire: IFormulaireClass,
    rr2013Route: IRoute,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageRr0108 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.cpbMultiOccurrenceOptions = {
        bloc: 'cpb',
        colonnesVisibles: [
            { nom: 'cpbordaff', largeur: 100 },
            { nom: 'cpbtxt', largeur: 600 },
            { nom: 'cpbflgaff', largeur: 84 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cpbordaff'),
            new FormulaireItem('cpbtxt', { largeur: 50 }),
            new FormulaireItem('cpbflgaff', { default: 1 })
        ], { largeurDefaut: 25 })
    };

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'cpeparaff',
                valeur: vm.valeursEcran.dsc,
                affichage: true,
                readOnly: true
            }
        ],
        actionsMore: new Menu([
            new MenuItemAction(
                'G_LBL_APERCU',
                'BOUURLPOR',
                () => ({ trncleint: $stateParams.trncleint }),
                {
                    icon: 'visibility',
                    iconButton: false,
                    successAction(response) {
                        location.href = response.url;
                    },
                    hidden: !$stateParams.trncleint
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'cpeordaff', largeur: 100 },
            { nom: 'cpeent', largeur: 600 },
            { nom: 'cpeflgaff', largeur: 84 },
            { nom: 'cpeflgaffcon', largeur: 120 },
            { nom: 'cpeflgappobl', largeur: 120 },
            { nom: 'tnocod', largeur: 110, hidden: vm.valeursEcran.hidden === 1},
            { nom: 'tnodsc', largeur: 180, hidden: vm.valeursEcran.hidden === 1 },
            {
                nom: 'flgavs', largeur: 100, hidden: vm.valeursEcran.hidden === 1,
                menu: [
                    new MenuItemEcran(
                        'RR2013',
                        rr2013Route.NAME,
                        (data: any) => ({
                            cpecleint: data.cpecleint
                        }),
                        {
                            icon: 'launch',
                            iconButton: true,
                            hidden: vm.valeursEcran.hidden === 1
                        }
                    )
                ]
            },
            { nom: 'cpeflgacc', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cpeordaff'),
            new FormulaireItem('cpeent', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cpeflgaff', { default: 1 }),
            new FormulaireItem('cpeflgaffcon', { default: 0 }),
            new FormulaireItem('cpeflgappobl', { default: 1 }),
            new FormulaireItem('cpeflgacc', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tnocleint', { hidden: vm.valeursEcran.hidden === 1 }),
            //new FormulaireItem('cpeflgacc', { readonly: true }),
            new FormulaireItem('stamnetab', { default: $stateParams.stamnetab }),
            new FormulaireItem('cpecleintref', { default: $stateParams.cpecleintref }),
        ], { largeurDefaut: 25 }),
        multiOccurrenceOptionsEnfant: vm.cpbMultiOccurrenceOptions
    };
}
