import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0055 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0055Controller(mc0166Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0055 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0166Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0166Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'inscod'          , largeur: 120},
            {nom: 'insdsc'          , largeur: 300},
            {nom: 'succod'          , largeur: 120},
            {nom: 'sucdsc'          , largeur: 300},
            {nom: 'scbfol'          , largeur: 180},
            {nom: 'scbfoltfe'       , largeur: 180},
            {nom: 'scbcentra'       , largeur: 180},
            {nom: 'scbdateff'       , largeur: 130},
            {nom: 'vaesysdevcod_dsc', largeur: 130},
            {nom: 'scbflgemichq'    , largeur: 130},
            {nom: 'scbflgpronum'    , largeur: 130},
            {nom: 'cptcod'          , largeur: 150},
            {nom: 'cptdsc'          , largeur: 250}
        ]
    };
}
