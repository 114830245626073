import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageMR0206 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

interface Mr0206Params {
    rcecleint: string;
}

/* @ngInject */
export default function Mr0206Controller(
    Operateur: IOperateurService,    
    mr2018Route: IRoute,
    mr0188Route: IRoute,
    mr0225Route: IRoute,
    mr0190Route: IRoute,
    $stateParams: Mr0206Params,
    MenuItemEcran: IMenuItemEcranClass
) {
    const vm: IPageMR0206 = this;

    const filtres = [];

    if ($stateParams.rcecleint) {
        filtres.push(
            ...[
                {
                    colonne: 'rcecleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.rcecleint
                }
            ]
        );
    }
    
    const forage = {
        FORRAS: new MenuItemEcran(
            '',
            mr0190Route.NAME,
            (rowData: any) => ({
                id: rowData.rcecleint
            }),
            {
                icon: 'launch',
                iconButton: false,
                secondaire: true,
                fonction: 'FORRAS'
            }
        ),
        FORVNT: new MenuItemEcran(
            '',
            mr2018Route.NAME,
            (rowData: any) => ({
                ovdcleintref: rowData.rcecleint
            }),
            {
                icon: 'launch',
                iconButton: false,
                secondaire: true,
                fonction: 'FORVNT'
            }
        ),
        FORRAR: new MenuItemEcran(
            '',
            mr0188Route.NAME,
            (rowData: any) => ({
                id: rowData.rcecleint
            }),
            {
                icon: 'launch',
                iconButton: false,
                secondaire: true,
                fonction: 'FORRAR'
            }
        ),
        FORRPC: new MenuItemEcran(
            '',
            mr0225Route.NAME,
            (rowData: any) =>
                rowData.rpccleint
                    ? {
                          id: rowData.rpccleint,
                          rcecleint: rowData.rcecleint
                      }
                    : {
                          rcecleint: rowData.rcecleint
                      },
            {
                icon: 'launch',
                iconButton: false,
                secondaire: true,
                fonction: 'FORRPC',
                onClose() {
                    vm.multiOccurrence.fetchDataList();
                }
            }
        )
    };

    vm.multiOccurrenceOptions = {
        filtres,
        colonnesVisibles: [
            { nom: 'rcecod', largeur: 90 },
            { nom: 'rcedsc', largeur: 300 },
            { nom: 'carcod', largeur: 90 },
            { nom: 'cardsc', largeur: 300 },
            { nom: 'flg_vnt', largeur: 150 , menu: [forage.FORVNT] },
            { nom: 'flg_rar', largeur: 150 , menu: [forage.FORRAR] },
            { nom: 'flg_ras', largeur: 150 , menu: [forage.FORRAS]},
            { nom: 'flg_rpc', largeur: 150 , menu: [forage.FORRPC] }
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesCachees: ['ciecleint', 'rcecleint', 'rpccleint', 'carcleint']
    };
}
