import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCc0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0002Controller($stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCc0002 = this;
    const filtres = [];

    if ($stateParams.trmcleint) {
        filtres.push({
            colonne: 'trmcleint',
            valeur: $stateParams.trmcleint
        });
    }
    vm.multiOccurrenceOptions = {
        filtres,
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'trmcod', largeur: 90 },
            { nom: 'trmann', largeur: 85 },
            { nom: 'trmdsc', largeur: 231 },
            { nom: 'trmdatdeb', largeur: 120 },
            { nom: 'trmdatfin', largeur: 100 },
            { nom: 'vaetrmsai', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('trmcod'),
            new FormulaireItem('trmann'),
            new FormulaireItem('trmdsc', { largeur: 50 }),
            new FormulaireItem('trmdatdeb'),
            new FormulaireItem('trmdatfin'),
            new FormulaireItem('vaetrmsai')
        ], { largeurDefaut: 25 })
    };
}
