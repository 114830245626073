import { ICouleurThemeService } from '../../../../core/services/couleur-theme.service';
import { IComponentController, ITimeoutService, IAugmentedJQuery } from 'angular';
import { IProfil } from '../../../../core/resources/profil.resource';
import IResourceService = angular.resource.IResourceService;
import { IApiConfig } from '../../../../core/interfaces/api-config.interface';
import { IRoute } from '../../../../core/interfaces/route.interface';
import { IStateService } from 'angular-ui-router';
import { IAppTitreHelper } from '../../../../core/services/app-titre-helper.service';

interface IPageAccueil extends IComponentController {
    carteHeureEtDate: any;
    messages: Array<IMessageAccueil>;
    currentMessage: IMessageAccueil;
    setCurrentMessage(index: number): void;
    setNextMessage(): void;
    setPreviousMessage(): void;
}

interface IMessageAccueil {
    galsuj: string;
    galtxt: string;
    vaegaltyp: 'IMP' | 'REM'
}

/* @ngInject */
export default function AccueilController($resource: IResourceService,
    couleurTheme: ICouleurThemeService,
    profilResolver: IProfil,
    ApiConfig: IApiConfig,
    accueilRoute: IRoute,
    $state: IStateService,
    appName: string,
    $timeout: ITimeoutService,
    $element: IAugmentedJQuery,
    AppTitreHelper: IAppTitreHelper) {
    const vm: IPageAccueil = this;

    vm.$onInit = $onInit;
    vm.setCurrentMessage = setCurrentMessage;
    vm.setNextMessage = setNextMessage;
    vm.setPreviousMessage = setPreviousMessage;

    function $onInit() {

        couleurTheme.changerCouleur(profilResolver.preferences.theme);

        fetchMessages();

        if ($state.current.name === accueilRoute.NAME) {
            AppTitreHelper.setTitrePage({ titreApp: AppTitreHelper.getNomAppTitre(appName), cienomabr: profilResolver.compagnie.cienomabr });
        }

        const apiURL = `${ApiConfig.URL}/mccie-image-public/051P/${profilResolver.compagnie.ciecleint ? profilResolver.compagnie.ciecleint : ''}`;

        const accueilImgElement = $element.find('.accueil');
        accueilImgElement.css(`background-image`, `url(${apiURL})`)
    }

    function fetchMessages() {
        const accueilResource = $resource(ApiConfig.ROOT + '/accueil/');

        accueilResource.query({ gapflgsof: 1 }).$promise.then((result: any) => {
            vm.messages = result;
            if (vm.messages.length) {
                setNextMessage();
            }
        });
    }

    function setNextMessage() {
        if (!vm.currentMessage || vm.currentMessageNumber === vm.messages.length - 1) {
            setCurrentMessage(0);
        } else {
            setCurrentMessage(vm.currentMessageNumber + 1);
        }
    }

    function setCurrentMessage(index: number) {
        vm.currentMessageNumber = index;
        vm.currentMessage = vm.messages[index];
        restartTimeout();
    }

    function restartTimeout() {
        if (vm.timeout) {
            $timeout.cancel(vm.timeout);
        }
        vm.timeout = $timeout(setNextMessage, 20000);
    }

    function setPreviousMessage() {
        if (vm.currentMessageNumber === 0) {
            setCurrentMessage(vm.messages.length - 1);
        } else {
            setCurrentMessage(vm.currentMessageNumber - 1);
        }
    }
}
