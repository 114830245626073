import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFilterLibelle} from '../../../../../core/filters/ex-libelle.filter';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageMR0313 extends IComponentController {
    valeursEcran: any;
    formulaireTgp: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tgeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tgaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tgbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0313Params {
    tagcleint: number;
    mtgcleint: number;
    vaetagtyp: string;
}

/* @ngInject */
export default function Mr0313Controller($stateParams: IMr0313Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         exLibelleFilter: IFilterLibelle,
                                         Formulaire: IFormulaireClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMR0313 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulaireTgp = new Formulaire('LBL_BLC_TGP', [
            new FormulaireItem('tagcleint', { default: $stateParams.tagcleint }),
            new FormulaireItem('urgcleint', { largeur: 10 }),
            new FormulaireItem('tgpdatvig', { largeur: 10 }),
            new FormulaireItem('tgpflgact', { largeur: 10, default: 1 }),
            new FormulaireItem('tgpflgenlqte', { largeur: 10, default: 0 }),
            new FormulaireItem('tgpflgenltaux', { largeur: 10, default: 0 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('strcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tgpfct'),
            new FormulaireItem('tgpmnt'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unccleintmnt'),
            new FormulaireItem('tgpprctaudem'),
            new FormulaireItem('tgpmntmax'),
            new FormulaireItem('bqecleint'),
            new FormulaireItem('bqecleinttauqte'),
            new FormulaireItem('bqecleinttaumnt'),
            new FormulaireItem('tgpflgdjautl'),            
            new FormulaireItemDivider('LBL_INDICATEUR', { hidden: (data: any) => !data.$valeurs.mtgflgparbor }),
            new FormulaireItem('vaesyssldbor'),
            new FormulaireItem('tgpflgborinc', { largeur: 15 }),
            new FormulaireItem('tgpflgboraff', { largeur: 15 }),
            new FormulaireItemSautDeLigne()
        ], { largeurDefaut: 33.33 });

        // TAG à générer
        vm.tgeMultiOccurrenceOptions = {
            bloc: 'tge',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'tagcod'      , largeur: 110},
                {nom: 'tagdscabr'   , largeur: 262},
                {nom: 'tgeflginvval', largeur: 105}
            ],
            formulaire: new Formulaire('LBL_BLC_TGE', [
                new FormulaireItem('tagcleintgen'),
                new FormulaireItem('tgeflginvval')
            ], { largeurDefaut: 100 })
        };

        // TAG à ajouter
        vm.tgaMultiOccurrenceOptions = {
            bloc: 'tga',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'tagcod'          , largeur: 110},
                {nom: 'tagdscabr'       , largeur: 262},
                {nom: 'vaesystgaqte_dsc', largeur: 147},
                {nom: 'vaesystgamnt_dsc', largeur: 147}
            ],
            formulaire: new Formulaire('LBL_BLC_TGA', [
                new FormulaireItem('tagcleint', { largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaesystgaqte'),
                new FormulaireItem('vaesystgamnt')
            ])
        };

        // Borne
        vm.tgbMultiOccurrenceOptions = {
            bloc: 'tgb',
            fonctions: {
                importation: true,
                libellesDynamiques: true
            },
            colonnesVisibles: [
                {nom: 'tgbborinf', largeur: 140,
                    replacedValue: (multiOccurrence: IMultiOccurrence) => {
                        return (vm.formCtrl && vm.formCtrl.tgpflgborinc.$modelValue)
                            ? exLibelleFilter('LBL_SUPEGL', multiOccurrence.libelles, true)
                            : exLibelleFilter('LBL_SUPA', multiOccurrence.libelles, true);
                    }
                },
                {nom: 'tgbtau'   , largeur: 140}
            ],
            formulaire: new Formulaire('LBL_BLC_TGB', [
                new FormulaireGroupe(new Formulaire([
                    new FormulaireItem('tgbborinf', {
                        titre: 'LBL_SUPEGL'
                    }),
                    new FormulaireItem('tgbtau')
                ]), {hidden: () => !(vm.formCtrl && vm.formCtrl.tgpflgborinc.$modelValue)}),
                new FormulaireGroupe(new Formulaire([
                    new FormulaireItem('tgbborinf', {
                        titre: 'LBL_SUPA'
                    }),
                    new FormulaireItem('tgbtau')
                ]), {hidden: () => (vm.formCtrl && vm.formCtrl.tgpflgborinc.$modelValue)})
            ])
        };
    }
}
