import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPagerr0076 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDme: IFormulaire;
    formulaireInfoCom: IFormulaire;
    formulaireEtranger: IFormulaire;
    eadMultiOccurrenceOptions: IMultiOccurrenceOptions;
    etlMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ideMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pebMultiOccurrenceOptions: IMultiOccurrenceOptions;

    menus: { [key: string]: Array<IMenuItem> };
}




/* @ngInject */
export default function rr0076Controller($stateParams: any,
    Pagination: IPaginationClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagerr0076 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.staparam = $stateParams;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                nouveau: vm.valeursEcran.flgins_dme,
                edition: vm.valeursEcran.flgmaj_dme,
                supprime: vm.valeursEcran.flgdel_dme
            }
        };

        vm.formulaireDme = new Formulaire('LBL_BLC_DME', [
            new FormulaireItem('demcleint'),
            new FormulaireItem('stamnetab', { default: $stateParams.stamnetab }),
            new FormulaireItem('cleintref', { default: $stateParams.cleintref }),
            new FormulaireItem('dmenom'),
            new FormulaireItem('dmeprn'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dmenas', { largeur: 30 }),
            new FormulaireItem('dmeini', { largeur: 20 }),
            new FormulaireItem('dmedatnai', { largeur: 30 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dmeadrcou'),
            new FormulaireItem('vaeeplsex'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vacepletaciv'),
            new FormulaireItem('cincleint')
        ], { largeurDefaut: 50 }
        );

    }
    // Carte Infos complmentaire
    vm.formulaireInfoCom = new Formulaire('LBL_BLC_INFO_COM', [
        new FormulaireItem('dmenumref1'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dmenumref2'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dmenumref3'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dmenumref4'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dmeordprf'),
    ], { largeurDefaut: 100 });

    // Onglet étranger
    vm.formulaireEtranger = new Formulaire('LBL_BLC_ETRANGER', [
        new FormulaireItem('dmeflgetr', { default: 0 }),
        new FormulaireItem('dmeflgnonetr', { default: 0 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dmenasimm', { largeur: 33.33 }),
        new FormulaireItem('pyscleint', { largeur: 33.33 }),
        new FormulaireItem('dmedatobtcit', { largeur: 33.33 }),
        new FormulaireItemDivider('LBL_LIEU_NAISSANCE'),
        new FormulaireItem('pyscleintnai', { largeur: 33.33 }),
        new FormulaireItem('etacleintnai', { largeur: 33.33 }),
        new FormulaireItemDivider('LBL_PERMIS'),
        new FormulaireItem('vaetypepe'),
        new FormulaireItem('dmedatper'),
        new FormulaireItem('dmedatexpper'),
        new FormulaireItem('dmedatexpnas'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dmecomper', { largeur: 50 })
    ], { largeurDefaut: 25 });

    // Adresses employé
    vm.eadMultiOccurrenceOptions = {
        bloc: 'ead',
        fonctions: {
            pagination: true,
            nouveau: vm.valeursEcran.flgins_dme,
            edition: vm.valeursEcran.flgmaj_dme,
            supprime: vm.valeursEcran.flgdel_dme
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        // Affichage des colonnes visibles
        colonnesVisibles: [
            { nom: 'dadadrfmt', largeur: 300 },
            { nom: 'dadflgfinann', largeur: 91 },
            { nom: 'dadflgchq', largeur: 91 },
            { nom: 'taddsc', largeur: 300 }
        ],

        formulaire: new Formulaire([
            new FormulaireItem('tadcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dadflgchq', { largeur: 50, default: 1 }),
            new FormulaireItem('dadflgfinann', { largeur: 50, default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pyscod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etacleint'),
            new FormulaireItem('etacod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('muncod', {
                largeur: 60
            }),
            new FormulaireItem('munnomfra', {
                largeur: 60
            }),
            new FormulaireItem('dadcodpos', {
                largeur: 30
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dadadr1'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dadadr2'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dadadr3')
        ], { largeurDefaut: 100 })
    };


    // Téléphones employé
    vm.etlMultiOccurrenceOptions = {
        bloc: 'etl',
        fonctions: {
            pagination: true,
            nouveau: vm.valeursEcran.flgins_dme,
            edition: vm.valeursEcran.flgmaj_dme,
            supprime: vm.valeursEcran.flgdel_dme
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        // Affichage des colonnes visibles
        colonnesVisibles: [
            { nom: 'dmtnumtel', largeur: 300 },
            { nom: 'ttldsc', largeur: 300 }
        ],

        formulaire: new Formulaire([
            new FormulaireItem('ttlcleint'),
            new FormulaireItem('dmtnumtel'),
            new FormulaireItem('dmtnumpos')

        ])

    };

    // Diplôme
    vm.ideMultiOccurrenceOptions = {
        bloc: 'ide',
        fonctions: {
            pagination: true,
            nouveau: vm.valeursEcran.flgins_dme,
            edition: vm.valeursEcran.flgmaj_dme,
            supprime: vm.valeursEcran.flgdel_dme
        },
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        }),
        // Affichage des colonnes visibles
        colonnesVisibles: [
            { nom: 'comcod', largeur: 120 },
            { nom: 'comdsc', largeur: 230 },
            { nom: 'ittcod', largeur: 136 },
            { nom: 'ittdsc', largeur: 230 },
            { nom: 'scldscitt', largeur: 230 },
            { nom: 'dipcod', largeur: 120 },
            { nom: 'dipdsc', largeur: 230 },
            { nom: 'scldscdip', largeur: 230 },
            { nom: 'vacvalcle', largeur: 150 },
            { nom: 'scldsccyc', largeur: 230 },
            { nom: 'ideannobt', largeur: 100 },
            { nom: 'sclcom', largeur: 250 }
        ],

        formulaire: new Formulaire([
            new FormulaireItem('comcleint', {
                resourceParams: {
                    stamnetab: $stateParams.stamnetab
                },
                largeur: 33.33
            }),
            new FormulaireItem('ittcleint', { largeur: 33.33 }),
            new FormulaireItem('scldscitt', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dipcleint', { largeur: 30 }),
            new FormulaireItem('t_cycle', { largeur: 15 }),
            new FormulaireItem('scldscdip', { largeur: 30 }),
            new FormulaireItem('scldsccyc', { largeur: 15 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ideannobt'),
            new FormulaireItem('sclcom', { largeur: 40 })
        ], { largeurDefaut: 20 })

    };

    vm.pebMultiOccurrenceOptions = {
        bloc: 'peb',
        fonctions: {
            pagination: true,
            nouveau: vm.valeursEcran.flgins_peb,
            edition: vm.valeursEcran.flgmaj_peb,
            supprime: vm.valeursEcran.flgdel_peb
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        colonnesVisibles: [
            { nom: 'qdvtxtval', largeur: 300 },
            { nom: 'qsdnom', largeur: 300 },
            { nom: 'pebpaeondsc', largeur: 300 }
        ]
    };

}
