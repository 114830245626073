import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMC0114 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0114Controller(Menu: IMenuClass) {
    const vm: IPageMC0114 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'eltcod', largeur: 200},
            {nom: 'eltdsc', largeur: 200},
            {nom: 'eltdscabr', largeur: 200},
            {nom: 'eltflgact', largeur: 200}
        ]
    };
}
