import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCR0236 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    clnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    capMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ctlMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ctiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ccmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eamMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0236Controller(FormulaireItem: IFormulaireItemClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass) {
    const vm: IPageCR0236 = this;

    vm.monoOccurrenceOptions = { noId: true };
    // Clients
    vm.clnMultiOccurrenceOptions = {
        bloc: 'cln',
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SUPPRIMER',
                'BOUSUPC',
                {
                    selection: { nom: 'supprimer' }
                })
        ]),
        colonnesVisibles: [
            { nom: 'clnmat', largeur: 140 },
            { nom: 'clnnom', largeur: 180 },
            { nom: 'clnprn', largeur: 180 },
            { nom: 'clnnomabr', largeur: 180 },
            { nom: 'datcre', largeur: 145 },
            { nom: 'clnmsgerr', largeur: 250 }
        ]
    };
    // Adresses
    vm.capMultiOccurrenceOptions = {
        bloc: 'cap',
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SUPPRIMER',
                'BOUSUPA',
                {
                    selection: { nom: 'supprimer' }
                })
        ]),
        colonnesVisibles: [
            { nom: 'capcod', largeur: 140 },
            { nom: 'caplig1', largeur: 180 },
            { nom: 'caplig2', largeur: 180 },
            { nom: 'capcodpos', largeur: 150 },
            { nom: 'capvil', largeur: 180 },
            { nom: 'cappro', largeur: 180 },
            { nom: 'cappay', largeur: 180 },
            { nom: 'datcre', largeur: 145 },
            { nom: 'capmsgerr', largeur: 250 }
        ]
    };
    // Téléphones
    vm.ctlMultiOccurrenceOptions = {
        bloc: 'ctl',
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SUPPRIMER',
                'BOUSUPT',
                {
                    selection: { nom: 'supprimer' }
                })
        ]),
        colonnesVisibles: [
            { nom: 'ctlcod', largeur: 140 },
            { nom: 'ctlindreg', largeur: 110 },
            { nom: 'ctltel', largeur: 120 },
            { nom: 'ctlpos', largeur: 120 },
            { nom: 'ctlindint', largeur: 110 },
            { nom: 'datcre', largeur: 145 },
            { nom: 'ctlmsgerr', largeur: 250 }
        ]
    };
    // Informations complémentaire
    vm.ctiMultiOccurrenceOptions = {
        bloc: 'cti',
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SUPPRIMER',
                'BOUSUPI',
                {
                    selection: { nom: 'supprimer' }
                })
        ]),
        colonnesVisibles: [
            { nom: 'cticod', largeur: 140 },
            { nom: 'ctilib', largeur: 140 },
            { nom: 'ctival', largeur: 120 },
            { nom: 'datcre', largeur: 145 },
            { nom: 'ctimsgerr', largeur: 250 }
        ]
    };
    // Charges
    vm.ccmMultiOccurrenceOptions = {
        bloc: 'ccm',
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SUPPRIMER',
                'BOUSUPM',
                {
                    selection: { nom: 'supprimer' }
                })
        ]),
        colonnesVisibles: [
            { nom: 'ccmcod', largeur: 140 },
            { nom: 'ccmdat', largeur: 110 },
            { nom: 'ccmchgcod', largeur: 120 },
            { nom: 'ccmmnt', largeur: 120 },
            { nom: 'ccmcodref1', largeur: 120 },
            { nom: 'ccmcodref2', largeur: 120 },
            { nom: 'ccmcodref3', largeur: 120 },
            { nom: 'ccmcodref4', largeur: 120 },
            { nom: 'ccmcodref5', largeur: 120 },
            { nom: 'ccmcodref6', largeur: 120 },
            { nom: 'ccmcodref7', largeur: 120 },
            { nom: 'ccmcodref8', largeur: 120 },
            { nom: 'datcre', largeur: 145 },
            { nom: 'ccmmsgerr', largeur: 250 }
        ]
    };
    // Encaissements
    vm.eamMultiOccurrenceOptions = {
        bloc: 'eam',
        actionsSelection: new Menu([
            new MenuItemSelection(
                'G_LBL_BTN_SUPPRIMER',
                'BOUSUPE',
                {
                    selection: { nom: 'supprimer' }
                })
        ]),
        colonnesVisibles: [
            { nom: 'eamcod', largeur: 140 },
            { nom: 'eammnt', largeur: 120 },
            { nom: 'datcre', largeur: 145 },
            { nom: 'eammsgerr', largeur: 250 }
        ]
    };
}
