import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';

interface IPageMr0060 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0060Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0060 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'esycod', largeur: 60},
            {nom: 'esydsc', largeur: 60},
            {nom: 'vaeesyfenpar_dsc', largeur: 60},
            {nom: 'esycom', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('esycod', {largeur: 30}),
            new FormulaireItem('esydsc', {largeur: 60}),
            new FormulaireItem('vaeesyfenpar', {largeur: 40}),
            new FormulaireItem('esycom', {largeur: 90})
        ])
    };
}
