import {IComponentController} from 'angular';
import {IEcranContextController} from '../../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';


interface IPageMc2046 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    dncMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ecranContextCtrl: IEcranContextController;   
}

/* @ngInject */
export default function Mc2046Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass)  {
    const vm: IPageMc2046 = this;

    vm.monoOccurrenceOptions = {};
 
        // Multi Étapes
        vm.dncMultiOccurrenceOptions = {
            fonctions: {
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                reinitialiser: true,
                exportation: true,
                suiviModification: true,
                supprime: false,
                nouveau: false,
                importation: false,
                enregistrerUnEtat: false                
            },
            disableSave: (data: any) => !data.dncmodflgautdsp,
            formulaire: new Formulaire([new FormulaireItem("dncflgautdsp", { required: true  
                                            })
            ]),
            colonnesFixesGauche:[
                { nom: 'vaesysnivctr_dsc', largeur: 120 },
                { nom: 'dnccod', largeur: 200 },
                { nom: 'mntdispo', largeur: 120 }
            ],
            colonnesVisibles: [
                { nom: 'vaesysnatctb_dsc', largeur: 84 },
                { nom: 'mntresv', largeur: 126 },
                { nom: 'mntdispr', largeur: 126 },
                { nom: 'dncmntreq', largeur: 126 },
                { nom: 'mntmanq', largeur: 126 },
                { nom: 'dncflgerr', largeur: 76 },
                { nom: 'dncflgavr', largeur: 76 },
                { nom: 'dncflgautdsp', largeur: 91 }
            ],
            details: {
                lblTitre: 'G_LBL_RESERVE_FONDS',
                multiOccurrenceOptions: {
                    bloc: 'rfu',
                    formulaire: new Formulaire([
                        new FormulaireItem('rfocleint', {
                            largeur: 20,
                            required: true,
                            modifAllow: false
                        }),
                        new FormulaireItem('rfoann', { readonly: true, largeur: 20 }),
                        new FormulaireItem('rfomntdsp', { readonly: true, largeur: 20 }),
                        new FormulaireItem('rfumntutl', { largeur: 20 }),
                        new FormulaireItem('rfudsc', { largeur: 20 })
                    ]),
                    fonctions: {
                        selectionnerDesColonnes: true,
                        selectionnerTri: true,
                        suiviModification: true,
                        reinitialiser: true,
                        exportation: true,
                        importation: false,
                        enregistrerUnEtat: false
                    },
                    colonnesVisibles: [
                        { nom: 'rfonumdoc', largeur: 152 },
                        { nom: 'rfoann', largeur: 76 },
                        { nom: 'rfomntdsp', largeur: 126 },
                        { nom: 'rfumntutl', largeur: 126 },
                        { nom: 'rfudsc', largeur: 194 }
                    ]
                }
            }            
        };

}
