import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMr2001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr2001Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr2001 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'rcecod'  , largeur: 100},
            {nom: 'rcedscabr', largeur: 240}
        ],
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'raudatvig', largeur: 110},
            {nom: 'foucod', largeur: 110},
            {nom: 'founomabr', largeur: 200},
            {nom: 'entcod', largeur: 185},
            {nom: 'entdsc', largeur: 262},
            {nom: 'vecreqcat', largeur: 190},
            {nom: 'vecreqcat_dsc', largeur: 262}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rcecleint'),
            new FormulaireItem('flginsert', {hidden: true}),
            new FormulaireItem('demcleint', {readonly: (data) => !data.flginsert}),
            new FormulaireItem('raudatvig'),
            new FormulaireItem('foucleint'),
            new FormulaireItem('entcleint'),
            new FormulaireItem('vecreqcat')
        ]),
    };
}
