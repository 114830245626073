import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTH0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0017Controller(Menu: IMenuClass) {
    const vm: IPageTH0017 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rghcod', largeur: 200},
            {nom: 'rghdsc', largeur: 200},
            {nom: 'tpscod', largeur: 200},
            {nom: 'tpsdsc', largeur: 200},
            {nom: 'tpscodabr', largeur: 200},
            {nom: 'tpsseqaff', largeur: 200},
            {nom: 'tpsdatdeb', largeur: 200},
            {nom: 'tpsdatfin', largeur: 200},
            {nom: 'ttpcod', largeur: 200},
            {nom: 'ttpdsc', largeur: 200},
            {nom: 'unccod', largeur: 200},
            {nom: 'uncdsc', largeur: 200},
            {nom: 'tpsflgdcx', largeur: 200},
            {nom: 'tpsflgvercon', largeur: 200},
            {nom: 'tpsflgabs', largeur: 200},
            {nom: 'tpsflggenpau', largeur: 200},
            {nom: 'tpsflgbalhor', largeur: 200},
            {nom: 'pfcdsc', largeur: 200},
            {nom: 'tpsflgaccemp', largeur: 200},
            {nom: 'tpsexp', largeur: 200},
            {nom: 'tpsflgqtrper', largeur: 200},
            {nom: 'tpsflgqtrobl', largeur: 200},
            {nom: 'tpsflgqtefix', largeur: 200},
            {nom: 'vaetpstypsai_dsc', largeur: 200},
            {nom: 'vaetpstypsaisnsqtr_dsc', largeur: 200},
            {nom: 'tpsflgcon', largeur: 200},
            {nom: 'tpscodcon', largeur: 200},
            {nom: 'tpsdsccon', largeur: 200}
        ]
    };
}
