import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMc2014 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    treMultiOccurrenceOptions: IMultiOccurrenceOptions;
    retMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc2014Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc2014 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            boutonDupliquer: false,
            exportation: false
        },
        formulaire: new Formulaire('LBL_BLC_TYD', [
            new FormulaireItem('tydcod'),
            new FormulaireItem('tyddsc')
        ])
    };

    vm.treMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 180},
            {nom: 'tyddsc', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tydcleintrefpar')
        ])
    };

    vm.retMultiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },
        colonnesVisibles: [
            {nom: 'redcod', largeur: 180},
            {nom: 'reddsc', largeur: 130}
        ]
    };

}
