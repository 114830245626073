import {IComponentOptions, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import NotesController from './ex-notes.controller';

export default module('app.components.ex-notes', []).component('exNotes', NotesFactory());

function NotesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            {
                monoOccurrence: '<',
                lblTitre: '<?',
                closeAction: '<?',
                compteur: '=?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: NotesController,
        controllerAs: 'vm',
        template: require('./ex-notes.html')
    };
}
