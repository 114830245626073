import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAs0055 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAci: IFormulaire;
}

/* @ngInject */
export default function As0055Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageAs0055 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            id: vm.valeursEcran.acicleint,
            fonctions: {
                boutonDupliquer: false
            },
        };

        vm.formulaireAci = new Formulaire('LBL_BLC_CIE', [
            new FormulaireItem('aciflgimpafturg', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('acichmchrdef', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aci_ftpatron', { largeur: 33.33 })
        ]
        );
    }
}
