import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0187 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ratMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0187Params {
    id: number;
}

/* @ngInject */
export default function Mr0187Controller(
    $stateParams: IMr0187Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass
) {
    const vm: IPageMr0187 = this;

    vm.menuBandeau = new Menu([]);
    vm.valeursEcran = ecranDetails.valeurs;
    vm.ratMultiOccurrenceOptions = {
        fonctions: {
            importation: false
        },

        colonnesVisibles: [
            { nom: 'ratdatvig', largeur: 100 },
            { nom: 'rsccod', largeur: 95 },
            { nom: 'rscdsc', largeur: 300 },
            { nom: 'rsccoddefpri', largeur: 220 },
            { nom: 'ratflgexcact', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ratdatvig', { largeur: 10 }),
            new FormulaireItem('rsccleint', { largeur: 30 }),
            new FormulaireItem('rsccleintdefpri', { largeur: 30 }),
            new FormulaireItem('ratflgexcact', { largeur: 10 }),
            new FormulaireItem('tagcleint', { hidden: true, default: $stateParams.id })
        ])
    };
}
