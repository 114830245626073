import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';


interface IPageAc0039 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0039Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAc0039 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: true,
            criteresRechercheCollapsable: false,
            criteresRechercheOpened : true,
            rechercheCollapsable: false,
            effaceCriteres : true,
            edition: false,
            supprime: false
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('refcleint'),
                new FormulaireItem('usrcleintsrc'),
                new FormulaireItem('usrcleintdst'),
                new FormulaireItem('datdeb', { largeur: 10 }),
                new FormulaireItem('datfin', { largeur: 10 })
            ], {largeurDefaut: 33}
        ),
        colonnesVisibles: [
            {nom: 'dacnumdoc', largeur: 180},
            {nom: 'dacnumdocseq', largeur: 80},
            {nom: 'sysboncmd', largeur: 150},
            {nom: 'tydcod', largeur: 70},
            {nom: 'uhcdat', largeur: 120},
            {nom: 'peccod', largeur: 100},
            {nom: 'usrcod', largeur: 100},
            {nom: 'refcod', largeur: 120},
            {nom: 'refnom', largeur: 180},
            {nom: 'etcnumdoc', largeur: 180},
            {nom: 'etcnumdocseq', largeur: 150},
            {nom: 'caadsc', largeur: 150},
            {nom: 'intcodreq', largeur: 120},
            {nom: 'intnomreq', largeur: 180},
            {nom: 'intcodach', largeur: 100},
            {nom: 'intnomach', largeur: 180},
            {nom: 'vecdaccat', largeur: 80}
        ]
    };
}