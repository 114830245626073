import { IComponentController, IRootScopeService, IScope } from 'angular';
import { IProfil, IProfilAcces } from '../../../../core/resources/profil.resource';
import { IParametresSecuriteService } from '../../../../core/services/parametres-securite.service';
import { IComposantMonoOccurrenceEcran } from '../../../../core/components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IComposantMultiOccurrenceAnalyse } from '../ex-multi-occurrence-analyse/ex-multi-occurrence-analyse.controller';
import { IComposantMultiOccurrenceMaitreDetailsEcran } from '../ex-multi-occurrence-maitre-details-ecran/ex-multi-occurrence-maitre-details-ecran.controller';
import { IMultiOccurrence } from '../../../../core/services/multi-occurrence.service';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IResourceService = angular.resource.IResourceService;
import { IApiConfig } from "../../../../core/interfaces/api-config.interface";
import { IEcranDetailsResourcesEntite } from "../../../../core/resources/ecran-details.resource";
import { IMenuNavigationItem } from "../../../../core/interfaces/menu-navigation-item.interface";
import { IFavorisMenu } from "../../../../core/interfaces/favoris-menu.interface";
import { IStateService } from "angular-ui-router";

interface IComposantMenuEmploye extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    profilsAcces: Array<IProfilAcces>;
    profilsAffiches: Array<IProfilAcces>
    rendering: boolean;
    activeRow: any;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrenceAnalyseCtrl: IComposantMultiOccurrenceAnalyse;
    multiOccurrenceMaitreDetailsEcranCtrl: IComposantMultiOccurrenceMaitreDetailsEcran;
    queryParams: any;
    infosEmploye: string;
    cleFavoris: string
}

/* @ngInject */
export default function MenuEmployeController(profil: IProfil,
    $scope: IScope, $rootScope: IRootScopeService, $state: IStateService,
    parametresSecurite: IParametresSecuriteService,
    $resource: IResourceService,
    ApiConfig: IApiConfig,
    $stateParams: any,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IComposantMenuEmploye = this;
    const resource = $resource(`${ApiConfig.ROOT}/liste-valeurs/epl-dem`);

    vm.vaesysapp = "SOF2"
    vm.cleFavoris = "ex-menu-employe-favoris"
    vm.$onInit = function $onInit() {
        const { pracleint } = parametresSecurite(vm.ecranContextCtrl.stateParams);
        const currentProfilAcces = profil.profilsAcces
            .find((profilAcces: IProfilAcces) => {
                return (profilAcces.pracleint === pracleint);
            });

        vm.profilsAcces = [{ ...currentProfilAcces, dscmnu: '', menus: currentProfilAcces.menusEmploye }];

        vm.queryParams = {};

        if (vm.monoOccurrenceEcranCtrl) {
            const data = vm.monoOccurrenceEcranCtrl.monoOccurrence.data;

            vm.queryParams.employe = data.demcleint || data.employe_demcleint || $stateParams.employe;

            $scope.$watch("vm.monoOccurrenceEcranCtrl.monoOccurrence.data.demcleint", (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    vm.queryParams.employe = newValue
                    vm.activeRow = vm.monoOccurrenceEcranCtrl.monoOccurrence.data;
                    fetchEmploye()
                }
            })

            vm.activeRow = vm.monoOccurrenceEcranCtrl.monoOccurrence.data;
            fetchEmploye()
        } else {
            vm.rendering = false;
            const multiOccurrenceComposant = vm.multiOccurrenceAnalyseCtrl || vm.multiOccurrenceMaitreDetailsEcranCtrl;

            if (multiOccurrenceComposant &&
                (
                    multiOccurrenceComposant.multiOccurrence.schema &&
                    (multiOccurrenceComposant.multiOccurrence.schema.demcleint || multiOccurrenceComposant.multiOccurrence.schema.eplcleint)
                )
            ) {
                const multiOccurrence = multiOccurrenceComposant.multiOccurrence;

                if (multiOccurrence.dataList) {
                    if (!multiOccurrenceComposant.criteresSuggeresVisibles) {
                        vm.rendering = true;
                    }
                    watchActiveRowChange(multiOccurrence);
                } else {
                    multiOccurrence.once('dataListUpdate', () => {
                        if (!multiOccurrenceComposant.criteresSuggeresVisibles) {
                            vm.rendering = true;
                        }
                        watchActiveRowChange(multiOccurrence);
                    });
                }
            }
        }
        $scope.$watch("vm.profilsAffiches", () => {
            if (vm.profilsAffiches) {
                vm.favoris = localStorage.getItem(vm.cleFavoris) ? prepareFavoris(JSON.parse(localStorage.getItem(vm.cleFavoris))) : [];
            }
        })
        $rootScope.$on(vm.cleFavoris, (event, favoris) => {
            vm.favoris = prepareFavoris(favoris);
        })
    }

    function watchActiveRowChange(multiOccurrence: IMultiOccurrence) {
        $scope.$watch(() => multiOccurrence.activeRowCleint, () => {
            vm.activeRow = multiOccurrence.getDataActiveRow();

            if (!vm.activeRow || (!vm.activeRow.demcleint && !vm.activeRow.employe_demcleint)) {
                vm.queryParams.employe = null;
                vm.rendering = false;
            } else if (!vm.queryParams.employe || (vm.queryParams.employe !== vm.activeRow.demcleint && vm.queryParams.employe !== vm.activeRow.employe_demcleint)) {
                vm.rendering = false;
                vm.queryParams.employe = vm.activeRow && (vm.activeRow.demcleint || vm.activeRow.employe_demcleint)
                fetchEmploye()
            }
        });
    }

    function fetchEmploye() {
        resource.query({
            usrcleint: profil.preferences.usrcleint,
            srccodref: $stateParams.srccod,
            filter: `demcleint{:}${vm.queryParams.employe}`,
            cols: "eplnummat,eplnomprnusu",
            ...parametresSecurite($stateParams, ecranDetails)
        }).$promise.then((rows: any[]) => {
            if (!rows || !rows.length) return
            vm.infosEmploye = `${rows[0].eplnummat} - ${rows[0].eplnomprnusu}`
            // Permet de faire fonctionner RR0149, le paramètre est enlevé pour les autres écrans
            vm.queryParams.emprech = `${rows[0].eplnummat} ${rows[0].eplnomprnusu}`
        });
    }

    function prepareFavoris(favoris: IFavorisMenu[]) {
        return favoris.map(favori => {
            const profil = vm.profilsAffiches.find(p => p.pracleint === favori.pracleint)
            if (profil) {
                return findItemInProfil(favori, profil.menus)
            }
        }).filter(favori => favori)
    }

    function findItemInProfil(favori: IFavorisMenu, navigationItems: IMenuNavigationItem[]): any {
        for (const navigationItem of navigationItems) {
            if (navigationItem.medcleintref === favori.medcleintref && navigationItem.mencleint === favori.mencleint && navigationItem.vaesysenttrt === favori.vaesysenttrt) return { ...favori, navigationItem }
            if (navigationItem.items) {
                const foundItem = findItemInProfil(favori, navigationItem.items)
                if (foundItem) return foundItem
            }
        }
    }
}
