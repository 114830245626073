import { IComponentController, IFilterDate } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPagePF0010 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDic: IFormulaire;
    formulaireAutres: IFormulaire;
}

/* @ngInject */
export default function Ue0045Controller(dateFilter: IFilterDate,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    $stateParams: IStateParamsService,
    MenuItemForage: IMenuItemForageClass,
    pf0029Route: IRoute) {
    const vm: IPagePF0010 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                boutonDupliquer: false
            }
        };

        vm.formulaireDic = new Formulaire('LBL_BLC_DIC', [
            new FormulaireItem('dpmcleint', {
                default: $stateParams.dpmcleint, menu: [new MenuItemForage(pf0029Route.NAME, data => ({ id: data.trncleintdpm }), { hidden: (data: any) => !data.trncleintdpm || !data.diccleint })]
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('demcleint', {
                default: $stateParams.demcleint
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('unocleintrh'),
            new FormulaireItem('poscleintsup', {
                resourceParams: (data: any) => {
                    return {
                        unocleintrh: data.unocleintrh
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('diccomdem'),
            new FormulaireItem('diccomcen'),
            new FormulaireItemSautDeLigne(),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS', new Formulaire([
                new FormulaireItem('dicnumdoc'),
                new FormulaireItem('dicdat', {
                    default: dateFilter(new Date(), 'yyyy-MM-dd')
                }),
                new FormulaireItem('ttccleint', {
                    nombreLibellesDetailsCacher: 1

                })
            ], { largeurDefaut: 33.33 })),

        ], { largeurDefaut: 50 });

        vm.formulaireAutres = new Formulaire('LBL_BLC_AUT', [
            new FormulaireItem('gpecleintsou'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gpecleint')
        ], { largeurDefaut: 100 });


    }
}
