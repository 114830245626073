import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';

interface IMr0334Params {
    id: string;
    menuId: string;
    asocleint: number;
}

interface IPageMR0334 extends IComponentController{
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAsp: IFormulaire;
    ascMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rvaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceAsc: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0334Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $stateParams: IMr0334Params,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMR0334 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireAsp = new Formulaire("LBL_BLC_ASP", [
            new FormulaireItem('asocleint', {default: $stateParams.asocleint}),

            new FormulaireItem('aspcod'),
            new FormulaireItem('aspdsc', {largeur: 50}),
            new FormulaireItem('aspflgact',{default : 1}),

            new FormulaireItem('rcecleint', {largeur: 50}),
            new FormulaireItem('aspflgchx',{default : 0}),
            new FormulaireItemSautDeLigne(),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS',
                new Formulaire([
                    new FormulaireItem('aspflgasscjt',{default : 0}),
                    new FormulaireItem('aspflgcjt',{default : 0}),
                    new FormulaireItem('aspflgenf',{default : 0}),                
                    new FormulaireItem('aspflgsantrtaut',{default: 0})
                ], {largeurDefaut: 25})
            )

        ],{largeurDefaut:25} );

        // Choix possible (PM1)
        vm.ascMultiOccurrenceOptions = {
            bloc:'asc',
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ascdsc', largeur: 290},
                {nom: 'ascval', largeur: 120},
                {nom: 'ascflgact', largeur: 90}
            ],

            formulaire: new Formulaire([
                new FormulaireItem('ascdsc',{largeur:60}),
                new FormulaireItem('ascflgact',{largeur:20, default : 1}),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('ascval',{largeur:20})

            ]),
        };

        // Historique des paramètres RCE
        vm.rvaMultiOccurrenceOptions = {
            bloc:'rva',
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'rcpdatvig', largeur: 130},
                {nom: 'rvanumval', largeur: 100},
                {nom: 'rvadsc', largeur: 300},
                {nom: 'rvaval', largeur: 120},
                {nom: 'vaedsc', largeur: 200}
            ]
        };
        
    }

}
