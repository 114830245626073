import IStateService = angular.ui.IStateService;
import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";
import {IMenuItem, IMenuItemClass} from "../../../../../core/services/menu/menu-item.service";
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPagePb0029 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireVlp: IFormulaire;
    actionCompleter: IMenuItem;
    actionRouvrir: IMenuItem;
    vldMultiOccurrenceOptions: IMultiOccurrenceOptions;
    annbdgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    preMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IPb0029Params {
    menuId: string;
}

/* @ngInject */
export default function Pb0029Controller(pb0030Route: IRoute,
                                         $state: IStateService,
                                         $stateParams: IPb0029Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItem: IMenuItemClass,
                                         Menu: IMenuClass) {
    const vm: IPagePb0029 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false,
                boutonEnregistrerHaut: false
            },
            successActionSauvegarde: () => vm.vldMultiOccurrence && vm.vldMultiOccurrence.fetchDataList(),
            updateDataWith: ['vld']
        };

        // Action Compléter
        vm.actionCompleter = new MenuItemAction(
            'LBL_BTN_COMPLETER',
            'BOUCMP',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                secondaire: true,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                }
            }
        );

        // Action Compléter
        vm.actionRouvrir = new MenuItemAction(
            'LBL_BTN_ROUVRIR',
            'BOUROU',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                secondaire: true,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                hidden: (data:any) => !data.$flgcen
            }
        );

        // Prévisionnel / UBR
        vm.formulaireVlp = new Formulaire('LBL_BLC_VLP', [
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pvlseq'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('prucoddsc')
            ], {largeurDefaut: 100}), {largeur: 50}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pvllbl', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.pvlflgter === 1 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.pvlflgter === 1 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg'
                        })
                    ]})
            ]), {largeur: 50})
        ]);

        vm.vldMultiOccurrenceOptions = {
            bloc: 'vld',
            fonctions: {
                editionRapide: true
            },
            actionsRangeeDroite: new Menu([
                new MenuItemAction(
                    'LBL_ACT_CREATION',
                    'BOUCREVLD',
                    (rowData:any) => ({
                        vlpcleint: rowData.vlpcleint,
                        prtcleint: rowData.prtcleint
                    }),
                    {
                        icon: 'exit_to_app',
                        successAction: (retour:any) => {
                            $state.go(pb0030Route.NAME, {
                                menuId: $stateParams.menuId,
                                id: retour.vldcleint,
                                vlpcleint: retour.vlpcleint,
                                prtcleint: retour.prtcleint
                            });
                        }
                    }
                )
            ]),
            entetesReplacedValues: {
                ent_bdg: vm.valeursEcran.vlplblent
            },
            colonnesFixesGauche: [
                {nom: 'cptcod', largeur: 240}
            ],
            colonnesVisibles: [
                {nom: 'prscod', largeur: 120},
                {nom: 'prsdscl01', largeur: 220},
                {nom: 'blocod', largeur: 120},
                {nom: 'blodscabr', largeur: 220},
                {nom: 'unacod', largeur: 120},
                {nom: 'unadscabr', largeur: 220},
                {nom: 'cifcod', largeur: 120},
                {nom: 'cifdscabr', largeur: 220},
                {nom: 'vldcom', largeur: 350},
                {nom: 'vldmntnetbdg', largeur: 120}
            ],
            colonnesFixesDroite: [
                {nom: 'vdlbdgini' , largeur: 113},
                {nom: 'vldmntnet'    , largeur: 113}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vldmntnet', {largeur: 25}),
                new FormulaireItem('vldcom', {largeur: 75})
            ]),
            colonnesEditionRapide: [
                {nom: 'cptcod'       , largeur: 200},
                {nom: 'blocod'       , largeur: 200},
                {nom: 'unacod'       , largeur: 200},
                {nom: 'cifcod'       , largeur: 200},
                {nom: 'vdlbdgini'    , largeur: 126},
                {nom: 'vldmntnet'    , largeur: 126},
                {nom: 'vldcom'       , largeur: 300}
            ],
            colonnesCachees: [
                'vlpcleint',
                'prtcleint'
            ]
        };

        // Montants de disponibilité de l'année précédente de l'année budgétaire en cours
        vm.annbdgMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tyhdsc'   , largeur: 100},
                {nom: 'ctbmntsum', largeur: 100}
            ]
        };

        // Montants prévisionnels de l'année précédente
        vm.preMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'pvlannseq', largeur: 100},
                {nom: 'vldmntnet', largeur: 100}
            ]
        };

    }
}
