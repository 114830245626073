import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePc0016 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pc0016Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePc0016 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('crecleint', { largeur: 75, required: true })
        ]),
        colonnesVisibles: [
            { nom: 'codsof', largeur: 145 },
            { nom: 'codsofdsc', titre: ' ', largeur: 255 },
            { nom: 'codcrq', largeur: 145 },
            { nom: 'codcrqdsc', titre: ' ', largeur: 255 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('crecleint', { hidden: true, default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.crecleint }),
            new FormulaireItem('ccqcleintsof', {
                required: true,
                resourceParams: () => (
                    {
                        crecleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.crecleint
                    }
                )
            }),
            new FormulaireItem('ccqcleintcrq', {
                required: true,
                resourceParams: () => (
                    {
                        crecleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.crecleint
                    }
                )
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
