import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageAs0024 extends IComponentController {
    asfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    achMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aceMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aatMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0024Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    $stateParams: any) {
    const vm: IPageAs0024 = this;
    $stateParams.flginfor = parseInt($stateParams.flginfor);

    vm.$onInit = function $onInit() {

        vm.monoOccurrenceOptions = {
            infosMonetaires: {
                largeurTexte: 150,
                noIcons: true,
                donnees:
                    [
                        { nom: 'ach', libelle: 'LBL_MNT_ACH' },
                        { nom: 'ace', libelle: 'LBL_MNT_ACE' },
                        { nom: 'tot', libelle: 'LBL_MNT_TOT' }
                    ]
            },
            fonctions: {
                importation: true,
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor
            },
            formulaire: new Formulaire([
                new FormulaireItem('ahtcleint', { readonly: true, default: $stateParams.ahtcleint }),
                new FormulaireItemDivider('', { hidden: (data: any) => data.vaeahtman != 'NON' }),
                new FormulaireItemDivider('LBL_TITRE_AHTMAN', { hidden: (data: any) => data.vaeahtman === 'NON' }),
                new FormulaireItem('ahthresem', { largeur: 25, default: $stateParams.ahthresem, readonly: true }),
                new FormulaireItem('unccleint', { largeur: 75, readonly: true, default: $stateParams.unccleint }),
                new FormulaireItem('tcycleint', { required: true }),
                new FormulaireItem('acydatvig', { required: true, modifAllow: false }),
                new FormulaireItem('acydsc', { largeur: 100, required: true }),
                new FormulaireItem('acytmptrajrs', { largeur: 25 }),
                new FormulaireItem('acytmpremsem', { largeur: 25, modifAllow: false }),
                new FormulaireItem('acydursem', {
                    default: 7,
                    hidden: true
                })
            ])
        };


        // Détail journalier
        vm.achMultiOccurrenceOptions = {
            bloc: 'ach',
            reloadWithBlocs: ['ACY'],
            fonctions: {
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor,
                importation: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'achqtejrs1', largeur: 100 },
                { nom: 'achqtejrs2', largeur: 100 },
                { nom: 'achqtejrs3', largeur: 100 },
                { nom: 'achqtejrs4', largeur: 100 },
                { nom: 'achqtejrs5', largeur: 100 },
                { nom: 'achqtejrs6', largeur: 100 },
                { nom: 'achqtejrs7', largeur: 100 },
                { nom: 'total', largeur: 100 }
            ],
            colonnesCachees: ['acycleint'],
            formulaire: new Formulaire([
                new FormulaireItem('achqtejrs1'),
                new FormulaireItem('achqtejrs2'),
                new FormulaireItem('achqtejrs3'),
                new FormulaireItem('achqtejrs4'),
                new FormulaireItem('achqtejrs5'),
                new FormulaireItem('achqtejrs6'),
                new FormulaireItem('achqtejrs7')
            ], { largeurDefaut: 10 })
        };

        // Détail journalier exception
        vm.aceMultiOccurrenceOptions = {
            bloc: 'ace',
            fonctions: {
                importation: true,
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'actcod1', largeur: 100 },
                { nom: 'achqtejrs1', largeur: 100 },
                { nom: 'actcod2', largeur: 100 },
                { nom: 'achqtejrs2', largeur: 100 },
                { nom: 'actcod3', largeur: 100 },
                { nom: 'achqtejrs3', largeur: 100 },
                { nom: 'actcod4', largeur: 100 },
                { nom: 'achqtejrs4', largeur: 100 },
                { nom: 'actcod5', largeur: 100 },
                { nom: 'achqtejrs5', largeur: 100 },
                { nom: 'actcod6', largeur: 100 },
                { nom: 'achqtejrs6', largeur: 100 },
                { nom: 'actcod7', largeur: 100 },
                { nom: 'achqtejrs7', largeur: 100 }
            ],

            formulaire: new Formulaire([
                new FormulaireItem('achcleref1'),
                new FormulaireItem('achqtejrs1'),
                new FormulaireItem('achcleref2'),
                new FormulaireItem('achqtejrs2'),
                new FormulaireItem('achcleref3'),
                new FormulaireItem('achqtejrs3'),
                new FormulaireItem('achcleref4'),
                new FormulaireItem('achqtejrs4'),
                new FormulaireItem('achcleref5'),
                new FormulaireItem('achqtejrs5'),
                new FormulaireItem('achcleref6'),
                new FormulaireItem('achqtejrs6'),
                new FormulaireItem('achcleref7'),
                new FormulaireItem('achqtejrs7')
            ])
        };

        // TAG
        vm.aatMultiOccurrenceOptions = {
            bloc: 'aat',
            fonctions: {
                importation: true,
                nouveau: !$stateParams.flginfor,
                edition: !$stateParams.flginfor,
                supprime: !$stateParams.flginfor
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'aatseqaff', largeur: 100 },
                { nom: 'tagcod1', largeur: 100 },
                { nom: 'aatqtejr1', largeur: 100 },
                { nom: 'aatmntjr1', largeur: 100 },
                { nom: 'tagcod2', largeur: 100 },
                { nom: 'aatqtejr2', largeur: 100 },
                { nom: 'aatmntjr2', largeur: 100 },
                { nom: 'tagcod3', largeur: 100 },
                { nom: 'aatqtejr3', largeur: 100 },
                { nom: 'aatmntjr3', largeur: 100 },
                { nom: 'tagcod4', largeur: 100 },
                { nom: 'aatqtejr4', largeur: 100 },
                { nom: 'aatmntjr4', largeur: 100 },
                { nom: 'tagcod5', largeur: 100 },
                { nom: 'aatqtejr5', largeur: 100 },
                { nom: 'aatmntjr5', largeur: 100 },
                { nom: 'tagcod6', largeur: 100 },
                { nom: 'aatqtejr6', largeur: 100 },
                { nom: 'aatmntjr6', largeur: 100 },
                { nom: 'tagcod7', largeur: 100 },
                { nom: 'aatqtejr7', largeur: 100 },
                { nom: 'aatmntjr7', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('aatseqaff', { largeur: 100, readonly: (data: any) => data.achseqaff }),
                new FormulaireItem('aatcleref1'),
                new FormulaireItem('aatqtejr1'),
                new FormulaireItem('aatmntjr1'),
                new FormulaireItem('aatcleref2'),
                new FormulaireItem('aatqtejr2'),
                new FormulaireItem('aatmntjr2'),
                new FormulaireItem('aatcleref3'),
                new FormulaireItem('aatqtejr3'),
                new FormulaireItem('aatmntjr3'),
                new FormulaireItem('aatcleref4'),
                new FormulaireItem('aatqtejr4'),
                new FormulaireItem('aatmntjr4'),
                new FormulaireItem('aatcleref5'),
                new FormulaireItem('aatqtejr5'),
                new FormulaireItem('aatmntjr5'),
                new FormulaireItem('aatcleref6'),
                new FormulaireItem('aatqtejr6'),
                new FormulaireItem('aatmntjr6'),
                new FormulaireItem('aatcleref7'),
                new FormulaireItem('aatqtejr7'),
                new FormulaireItem('aatmntjr7')
            ], { largeurDefaut: 100 / 3 })
        };
    }
}
