import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pr0026Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0026 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'crucod', largeur: 115 },
            { nom: 'crudsc', largeur: 230 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('crucod', { largeur: 25 }),
            new FormulaireItem('crudsc', { largeur: 50 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
    };
}
