import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'ac0010',
    ecrans: ['00-2'],
    params:[
        'id',
        '?refcleint',
        '?intcleintreq',
        '?etrcleint',
        '?indfor'
    ]
});

