import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr0244 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0244Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0244 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'unocod', largeur: 100 },
            { nom: 'unodsc', largeur: 260 },
            { nom: 'ticcod', largeur: 100 },
            { nom: 'ticdsc', largeur: 260 },
            { nom: 'icodsc', largeur: 260 },
            { nom: 'icvvaluni', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('icvcleintref'),
            new FormulaireItem('ticcleint', { required: true }),
            new FormulaireItem('icocleint'),
            new FormulaireItem('icvvaluni')
        ])
    };
}
