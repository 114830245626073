import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageMc0107 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCLPOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0107Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Pagination: IPaginationClass) {
    const vm: IPageMc0107 = this;


    vm.multiOccurrenceCLPOptions = {
        bloc: 'clp',
        fonctions: {
            editionRapide: true
        },
        colonnesVisibles: [
            {nom: 'el1cod', largeur: 200},
            {nom: 'el1dsc', largeur: 300},
            {nom: 'el1datdeb', largeur: 130},
            {nom: 'el1datfin', largeur: 130},
            {nom: 'clpprc', largeur: 130, total: true}
        ],
        pagination: new Pagination({
            nombreElementPage: 8,
            nbElementsPossibles: [8, 16, 32]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('el1cleint'),
            new FormulaireItem('clpprc')
        ], {
            largeurDefaut: 30
        })
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'clecod', largeur: 130},
            {nom: 'cledsc', largeur: 400},
            {nom: 'cledatdeb', largeur: 130},
            {nom: 'cledatfin', largeur: 130},
            {nom: 'cleindact', largeur: 90},
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('clecod', {largeur: 20}),
            new FormulaireItem('cledsc', {largeur: 80}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cledatdeb', {largeur: 20}),
            new FormulaireItem('cledatfin', {largeur: 20}),
            new FormulaireItem('cleindact', {largeur: 30})
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCLPOptions
    };
}
