import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCR0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Cr0020Controller(Menu: IMenuClass,
    cr0021Route: IRoute,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCR0020 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cr0021Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(
                '',
                cr0021Route.NAME,
                () => ({}))
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'dponumdoc', largeur: 120 },
            { nom: 'dponumdocseq', largeur: 120 },
            { nom: 'tydcod', largeur: 120 },
            { nom: 'scbfol', largeur: 120 },
            { nom: 'peccod', largeur: 120 },
            { nom: 'dpodat', largeur: 120 },
            { nom: 'etddsc', largeur: 160 },
            { nom: 'dpodsc', largeur: 200 },
            { nom: 'usrcod', largeur: 200 }
        ]
    };
}
