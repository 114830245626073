import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IComponentController } from 'angular';

interface IPageAe0002 extends IComponentController{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ae0002Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAe0002 = this;

    vm.$onInit = $onInit;

    function $onInit():void {

        vm.multiOccurrenceOptions = {
            fonctions:({
                importation: true
            }),
            colonnesVisibles: [
                {nom: 'ccpcod', largeur: 100},
                {nom: 'ccpdsc', largeur: 400},
                {nom: 'ccpdscabr', largeur: 300},
                {nom: 'ccpflgact', largeur: 70}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ccpcod', {required: true, largeur:15}),
                new FormulaireItem('ccpdsc', {required: true,largeur:35}),
                new FormulaireItem('ccpdscabr', {required: true,largeur:25}),
                new FormulaireItem('ccpflgact', {default: 1,largeur:25})
            ])
        };

    }

}
