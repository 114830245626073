import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePI0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0003Controller(Menu: IMenuClass) {
    const vm: IPagePI0003 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'tphcod', largeur: 200},
            {nom: 'tphdsc', largeur: 200},
            {nom: 'vaeprutypcptbdg', largeur: 200},
            {nom: 'vaeprutypbdg', largeur: 200},
            {nom: 'vecprucatpro', largeur: 200},
            {nom: 'vecprucatpro_dsc', largeur: 200},
            {nom: 'inddsc', largeur: 200},
            {nom: 'vaesysnivappctr', largeur: 200},
            {nom: 'tphflgcifexc', largeur: 200},
            {nom: 'tphflgunaexc', largeur: 200}
        ]
    };
}
