import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCR0232 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0232Controller(
) {
    const vm: IPageCR0232 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'cesnumdoc', largeur: 120 },
            { nom: 'cesdat', largeur: 110 },
            { nom: 'cldcod', largeur: 110 },
            { nom: 'cldnomprn', largeur: 180 },
            { nom: 'cesmnt', largeur: 110 },
            { nom: 'cesdsc', largeur: 180 },
            { nom: 'cesnumref', largeur: 110 },
            { nom: 'etycod', largeur: 110 },
            { nom: 'etydsc', largeur: 180 },
            { nom: 'moecod', largeur: 110 },
            { nom: 'moedsc', largeur: 180 },
            { nom: 'maicre', largeur: 150 },
            { nom: 'lencod', largeur: 110 },
            { nom: 'usrnom', largeur: 180 },
            { nom: 'econumdoc', largeur: 130 },
            { nom: 'encnumdoc', largeur: 150 }
        ]
    };
}
