import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGl0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceOBIOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0015Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGl0015 = this;

    vm.multiOccurrenceOBIOptions = {
        bloc: 'obi',
        colonnesVisibles: [
            {nom: 'impdeb', largeur: 250},
            {nom: 'impfin', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('impdeb'),
            new FormulaireItem('impfin')
        ], {largeurDefaut: 50})
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'obdcod', largeur: 89},
            {nom: 'obddsc', largeur: 247}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('obdcod'),
            new FormulaireItem('obddsc')
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceOBIOptions
    };
}
