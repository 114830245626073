import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass, IFormulaire } from '../../../../../core/services/formulaire/formulaire.service';

import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import IDialogService = angular.material.IDialogService;
import { IDialogAllStatus } from '../../../../../core/constants/dialog.constant';
import { ITimeoutService } from 'angular';
interface IPageAs0091 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireSeg: IFormulaire;
    params: any;
    valeursEcran: any;
}
/* @ngInject */
export default function As0091Controller(
    Formulaire: IFormulaireClass,
    $stateParams: any,
    $mdDialog: IDialogService,
    DialogStatus: IDialogAllStatus,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    $timeout: ITimeoutService
) {
    const vm: IPageAs0091 = this;
    vm.params = $stateParams;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        successActionSauvegarde: () => {
            $timeout(() => {
                $mdDialog.cancel(DialogStatus.FERMER);
            }, 500);
        }
    };
    vm.formulaireSeg = new Formulaire('LBL_BLC_SEG', [
        new FormulaireItem('sutcleint', { largeur: 50, default: $stateParams.sutcleint }),
        new FormulaireItem('vaesegdec', { largeur: 50, default: $stateParams.vaesegdec }),
        new FormulaireItem('intcoddsc', { largeur: 50, default: vm.valeursEcran.intcoddsc }),
        new FormulaireItem('sutdatdeb', { largeur: 25, default: vm.valeursEcran.sutdatdeb }),
        new FormulaireItem('sutdatfin', { largeur: 25, default: vm.valeursEcran.sutdatfin }),
        new FormulaireItem('actcod01', { largeur: 50 }),
        new FormulaireItem('asgcod', { largeur: 50 }),
        new FormulaireItem('ramcod', { largeur: 50 }),
        new FormulaireItem('urgcod', { largeur: 50 }),
        new FormulaireItem('strcod', { largeur: 50 }),
        new FormulaireItem('tafcod', { largeur: 50 }),
        new FormulaireItem('temcod', { largeur: 50 }),
        new FormulaireItem('actcleint', { largeur: 50, setOrdre: 13 }),
        new FormulaireItem('tagcleint', { largeur: 50, setOrdre: 14 })
    ], { modifierOrdre: { initialPosition: 5 } });
}
