import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0014Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0014 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'pnecod', largeur: 74},
            {nom: 'pnedsc', largeur: 262},
            {nom: 'pneflgpru', largeur: 62},
            {nom: 'pneflgcpt', largeur: 62},
            {nom: 'pneflgprs', largeur: 62},
            {nom: 'pneflguna', largeur: 62},
            {nom: 'pneflgcif', largeur: 62},
            {nom: 'pneflgact', largeur: 64}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pnecod'),
            new FormulaireItem('pnedsc', {required: true}),
            new FormulaireItem('pneflgpru'),
            new FormulaireItem('pneflgcpt'),
            new FormulaireItem('pneflgprs'),
            new FormulaireItem('pneflguna'),
            new FormulaireItem('pneflgcif'),
            new FormulaireItem('pneflgact')
        ])
    };
}
