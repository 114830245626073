import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2006',
    ecrans: ['00-2'],
    params: [
        '?niveau',
        '?type', 
        '?elecleint', 
        '?unacleint',
        '?soncleint',
        '?cptcleint',
        '?blocleint',
        '?vaesysnatctb', 
        '?tymcleint', 
        '?tydcleint', 
        '?peccleintdeb', 
        '?peccleintfin']
});
