import { IComponentController } from 'angular';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageSp0005 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireConRh: IFormulaire;
    msuMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ISp0005Params {
    id: string;
    demcleint: string;
}

/* @ngInject */
export default function Sp0005Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: ISp0005Params,
    FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageSp0005 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_MES', [
            new FormulaireItem('mesnumdoc'),
            new FormulaireItem('tmecleint', {modifAllow: false}),
            new FormulaireItem('ttccleint', {readonly: true}),
            new FormulaireItem('mesmotif', {largeur: 66.66}),
            new FormulaireItem('mesdatmes'),
            new FormulaireItemDivider('LBL_DIV_INFO'),
            new FormulaireItem('demcleint', { largeur: 50, default: $stateParams.demcleint }),
            new FormulaireItem('lemcleint', { largeur: 50 }),
            new FormulaireItem('aftcleint', { largeur: 50 }),
            new FormulaireItem('unocleintrh', { largeur: 50 }),
            new FormulaireItem('mescom', {largeur: 100}),
            new FormulaireItemDivider('LBL_DIV_SUP'),
            new FormulaireItem('poscleintsup', {largeur: 50}),
            new FormulaireItem('demcleintsup', {largeur: 50})
        ], {largeurDefaut: 33.33})
    };

    vm.formulaireConRh = new Formulaire('LBL_BLC_CONRH',[
        new FormulaireItem('intcleintrh', {largeur: 100}),
        new FormulaireItem('mesintnumtel'),
        new FormulaireItem('mesintnumpos')
    ]);

    vm.msuMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'msudat', largeur: 100 },
            { nom: 'msucom', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('msudat', {largeur: 33.33}),
            new FormulaireItem('msucom', {largeur: 66.66})
        ])
    };
}
