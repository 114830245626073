import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IComponentController } from "angular";
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
//import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageGb0002 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurence: IMonoOccurrence;
    valeursEcran: any;
    transfer: IMenuItem;
    formulaireSpe: IFormulaire;
    formulaireIde: IFormulaire;
    formulaireTec: IFormulaire;    
    ghbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ghbMultiOccurrence: IMultiOccurrence;
    ivdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    iveMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0002Controller(
    gb0009Route: IRoute,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass
) {
    const vm: IPageGb0002 = this;
    

    vm.$onInit = function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_FDB', [
                new FormulaireItem('gebcod', { largeur: 30 }),
                new FormulaireItemSautDeColonne({ largeur: 10 }),
                new FormulaireItem('gebflgact', { largeur: 10 }),
                new FormulaireItem('gebnumref', { largeur: 30 }),
                new FormulaireItem('gebdsc', { largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('intcleintdep'),
                new FormulaireItem('lolcleint'),
                new FormulaireItem('unacleint'),
                new FormulaireItem('gbpcleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('refcleint'),
                new FormulaireItem('gebfon'),
                new FormulaireItem('gebmod'),
                new FormulaireGroupeAccordeon('LBL_ACCORDEON_SERVICE', new Formulaire([
                    new FormulaireItem('gebflgcon', { largeur: 20 }),
                    new FormulaireItem('gebdatfin', { largeur: 50 }),
                    new FormulaireItem('concleint', { largeur: 100 })
                ], { largeurDefaut: 50 })),
            ], { largeurDefaut: 50 })
        };

        // Transférer
        /* vm.transfer = new MenuItemEcran(
            'LBL_TRANSFERT',
            cp0021Route.NAME,
            () => ({
                vaeusient: 'CIE',
                uvicleintref: vm.monoOccurrence.id
            }),
            {
                ecran: '01-2',
                icon: 'view_list',
                iconButton: false,
                secondaire: true
            }
        ); */

        // Specifications
        vm.formulaireSpe = new Formulaire('LBL_ACCORDEON_SPECIFICATIONS', [
            new FormulaireItem('gebannacq', { largeur: 20 }),
            new FormulaireItem('gebmnt', { largeur: 10 }),
            new FormulaireItem('gebnumser'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gcbcleint'),
            new FormulaireItem('vecgebfreutl'),
            new FormulaireItem('vecgebcon'),
            new FormulaireItem('intcleinttec')
        ]);

        // Historique
        vm.ghbMultiOccurrenceOptions = {
            fonctions: {
                nouveau: false,
                edition: false,
                supprime: false
            },
            colonnesVisibles: [
                { nom: 'datcre', largeur: 70 },
                { nom: 'usrnom', largeur: 155 },
                { nom: 'intcoddep', largeur: 70 },
                { nom: 'intnomdep', largeur: 125 },
                { nom: 'lolnumloc', largeur: 70 },
                { nom: 'loldsc', largeur: 125 },
                { nom: 'unacod', largeur: 70 },
                { nom: 'unadsc', largeur: 125 },
                { nom: 'gbpcod', largeur: 70 },
                { nom: 'gbpdsc', largeur: 125 },
                { nom: 'ghbcom', largeur: 125 }
            ]
        };

        // Identification
        vm.formulaireIde = new Formulaire('LBL_BLC_IDE', [
            new FormulaireItem('gebnomart'),
            new FormulaireItem('gebprnart'),
            new FormulaireItem('gebtit'),
            new FormulaireItem('gebdatcre'),
            new FormulaireItemDivider('LBL_IDE_DRT_AUT'),
            new FormulaireItem('vaedrtaut', { largeur: 100 }),
            new FormulaireItem('gebcomaut'),
            new FormulaireItemDivider('LBL_IDE_DRT_EXP'),
            new FormulaireItem('vaedrtexp', { largeur: 100 }),
            new FormulaireItem('gebcomexp')
        ]);

        // Technique
        vm.formulaireTec = new Formulaire('LBL_BLC_TEC', [
            new FormulaireItem('gebmat'),
            new FormulaireItem('gebtec'),
            new FormulaireItem('gebedt'),
            new FormulaireItem('vecgebacq'),
            new FormulaireItem('gebmntval', { menu: [
                new MenuItemForage(
                    gb0009Route.NAME,
                    (data: any) => ({
                        gebcleint: data.gebcleint
                    }),
                    {
                        iconButton: false
                    }
                )
            ] }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_TEC_DIM_AVC'),
            new FormulaireItem('gebhaucad', { largeur: 60 }),
            new FormulaireItem('unmcleinthca', { largeur: 10 }),
            new FormulaireItem('gebloncad', { largeur: 60 }),
            new FormulaireItem('unmcleintlca', { largeur: 10 }),
            new FormulaireItem('gebprocad', { largeur: 60 }),
            new FormulaireItem('unmcleintpca', { largeur: 10 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_TEC_DIM_SAC'),
            new FormulaireItem('gebhausan', { largeur: 60 }),
            new FormulaireItem('unmcleinthsc', { largeur: 10 }),
            new FormulaireItem('geblonsan', { largeur: 60 }),
            new FormulaireItem('unmcleintlsc', { largeur: 10 }),
            new FormulaireItem('gebprosan', { largeur: 60 }),
            new FormulaireItem('unmcleintpsc', { largeur: 10 })
        ]);

        // Donateurs
        vm.ivdMultiOccurrenceOptions = {
            formulaire: new Formulaire([
                new FormulaireItem('ivdnom', { largeur: 50 }),
                new FormulaireItem('ivdprn', { largeur: 50 }),
                new FormulaireItem('ivdmen', { largeur: 100 })
            ]),
            colonnesVisibles: [
                { nom: 'ivdnom', largeur: 70 },
                { nom: 'ivdprn', largeur: 70 },
                { nom: 'ivdmen', largeur: 120 }
            ]
        };

        // Entretien
        vm.iveMultiOccurrenceOptions = {
            formulaire: new Formulaire([
                new FormulaireItem('ivedat', { largeur: 20 }),
                new FormulaireItem('ivecon', { largeur: 60 }),
                new FormulaireItem('iveindsec', { largeur: 10 })
            ]),
            colonnesVisibles: [
                { nom: 'ivedat', largeur: 10 },
                { nom: 'ivecon', largeur: 90 },
                { nom: 'iveindsec', largeur: 10 }
            ]
        };
    }
}
