import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageGs0128 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
}

/* @ngInject */
export default function Gs0128Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageGs0128 = this;

    vm.monoOccurrenceOptions = {
        noId: true,
        fonctions: {
            boutonDupliquer: false
        }
    };

    vm.formulaire = new Formulaire('LBL_BLC_QPR', [
        new FormulaireItem('qprurlcon', { largeur: 50 }),
        new FormulaireItem('qprproxy', { largeur: 50 }),
        new FormulaireItemSautDeColonne({ largeur: 33.33 }),
        new FormulaireGroupe('LBL_INFOS_CONNEXION',
            new Formulaire([
                new FormulaireItem('qprusradm')
            ])
        )
    ]);

}
