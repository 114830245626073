import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageTF0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0007Controller(Menu: IMenuClass,                                         
                                         $stateParams: IStateParamsService,
                                         MenuItemForage: IMenuItemForageClass,
                                         tf0004Route: IRoute) {
    const vm: IPageTF0007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: tf0004Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(tf0004Route.NAME) ]),
        colonnesFixesGauche: [
            {nom: 'efacod', largeur: 140, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 243, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'folcod', largeur: 140},
            {nom: 'foldsc', largeur: 210},
            {nom: 'folann', largeur: 80},
            {nom: 'vaeemftyp', largeur: 110},
            {nom: 'rfecod', largeur: 140},
            {nom: 'rfenom', largeur: 262},
            {nom: 'vaesyscodprv', largeur: 160},
            {nom: 'emfnumrel', largeur: 140},
            {nom: 'emfnumrelxml', largeur: 210},
            {nom: 'emfnumrelori', largeur: 160},
            {nom: 'emfnumrelref', largeur: 160},
            {nom: 'emfcptfic', largeur: 125},
            {nom: 'emfflgimp', largeur: 125},
            {nom: 'emfflgimpemr', largeur: 65},
            {nom: 'emfindgen', largeur: 80},
            {nom: 'flgpubweb', largeur: 65},
            {nom: 'flgfrmzero', largeur: 76},
            {nom: 'flgpdflu', largeur: 100}           
        ]
    };
}
