import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMR0137 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0137Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMR0137 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'litcod', largeur: 95 }
        ],
        colonnesVisibles: [
            { nom: 'litdsc', largeur: 275 },
            { nom: 'vacsysreg_code', largeur: 120, hidden: ecranDetails.params.p_module === 'RT' },
            { nom: 'vacsysreg_desc', largeur: 250, hidden: ecranDetails.params.p_module === 'RT' },
            { nom: 'litdscrt1', largeur: 275, hidden: ecranDetails.params.p_module === 'MR' },
            { nom: 'muncod', largeur: 150, hidden: ecranDetails.params.p_module === 'RT' },
            { nom: 'munnomfra', largeur: 200, hidden: ecranDetails.params.p_module === 'RT' },
            { nom: 'vacreglit_code', largeur: 125, hidden: ecranDetails.params.p_module === 'MR' },
            { nom: 'vacreglit_desc', largeur: 250, hidden: ecranDetails.params.p_module === 'MR' }
        ],
        formulaire: new Formulaire('LBL_BLC_LIT', [
            new FormulaireItem('litcod'),
            new FormulaireItem('litdsc'),
            new FormulaireItem('litdscrt1', { hidden: (data) => data.$paramsEcran.p_module === 'MR' }),
            new FormulaireItem('vacsysreg', {
                hidden: (data) => data.$paramsEcran.p_module === 'RT',
                required: true
            }),
            new FormulaireItem('muncleint', { hidden: (data) => data.$paramsEcran.p_module === 'RT' }),
            new FormulaireItem('vacreglit', {
                hidden: (data) => data.$paramsEcran.p_module === 'MR'
            })
        ], { largeurDefaut: 25 })
    };
}
