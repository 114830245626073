import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0233 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0233Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0233 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cvtcod'   , largeur: 110},
            {nom: 'cvtdsc'   , largeur: 288},
            {nom: 'daedatvig', largeur: 110},
            {nom: 'daedsc'   , largeur: 340}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cvtcleint', { modifAllow: false }),
            new FormulaireItem('daedatvig', { modifAllow: false }),
            new FormulaireItem('daedsc'   , { largeur: 50 })
        ], { largeurDefaut: 25 })
    };
}
