import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0203 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0203Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMr0203 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 90 },
            { nom: 'urgdscabr', largeur: 230 },
            { nom: 'strcod', largeur: 94 },
            { nom: 'strdsc', largeur: 230 },
            { nom: 'sadcod', largeur: 90 },
            { nom: 'saddsc', largeur: 230 },
            { nom: 'sadflgsal', largeur: 91 },
            { nom: 'sadflgavs', largeur: 91 }
        ],
        colonnesFixesDroite: [
            { nom: 'urppct', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint', { largeur: 50 }),
            new FormulaireItem('strcleint', { largeur: 50 }),
            new FormulaireItem('sadcleint', { largeur: 50 }),
            new FormulaireItem('sadflgsal', { largeur: 25 }),
            new FormulaireItem('sadflgavs', { largeur: 25 }),
            new FormulaireItem('urppct', { largeur: 50 })
        ])
    };
}
