import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';

interface IPageTf0080 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tfkMultiOccurrenceOptions: IMultiOccurrenceOptions;
    varMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Tf0080Controller(Menu: IMenuClass, Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTf0080 = this;
    
        vm.menuBandeau = new Menu([]);
        vm.monoOccurrenceOptions = {            
        };
        vm.formulaireTfh = new Formulaire(' ', [
            new FormulaireItem('tfhann', { largeur: 20 }),
            new FormulaireItem('fpncleint', { largeur: 40 }),
            new FormulaireItem('femcleint', { largeur: 40 })
        ]);

        // Bloc Constante FE
        vm.tfkMultiOccurrenceOptions = {
            bloc: 'tfk',
            colonnesVisibles: [
                { nom: 'fesdsc', largeur: 193 },
                { nom: 'dscunical', largeur: 124 },
                { nom: 'tfkval', largeur: 111 }
            ],
            fonctions: {
                rechercheCollapsable: false
            },
            formulaire: new Formulaire([
                new FormulaireItem('fescleint', {
                    largeur: 50,
                    resourceParams: (data: any) => ({
                        femcleint: vm.monoOccurrence.data.femcleint
                    })
                }),
                new FormulaireItem('tfkval', { largeur: 50 })
            ])
        };
        // Bloc Variable FE
        vm.varMultiOccurrenceOptions = {
            bloc: 'var',
            colonnesVisibles: [
                { nom: 'tfkcod', largeur: 160 },
                { nom: 'tfkdsc', largeur: 180 },
                { nom: 'fesdsc', largeur: 180 },                                
                { nom: 'dscunical', largeur: 160 }
            ],
            fonctions: {
                rechercheCollapsable: false
            },
            formulaire: new Formulaire([
                new FormulaireItem('tfkcod', { largeur: 30 }),
                new FormulaireItem('tfkdsc', { largeur: 30 }),
                new FormulaireItem('fescleint', {
                    largeur: 35,
                    resourceParams: (data: any) => ({
                        femcleint: vm.monoOccurrence.data.femcleint
                    })
                })
                
            ])
        };
       
}
