/**
 * Composant représentant un multi-occurrence maitre details (ex-card-multi-maitre-details).
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import MultiOccurrenceMaitreDetailsController from './ex-multi-occurrence-maitre-details.controller';

export default module('app.components.ex-multi-occurrence-maitre-details', []).component('exMultiOccurrenceMaitreDetails', MultiOccurrenceMaitreDetailsFactory());

function MultiOccurrenceMaitreDetailsFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            {
                lblTitreEdition: '@',
                multiOccurrence: '=?multiOccurrenceOut',
                multiOccurrenceMaitreDetailsFormCtrl: '=?formCtrl'
            }
        ),
        controller: MultiOccurrenceMaitreDetailsController,
        require: {
            multiOccurrenceMaitreDetailsCtrl: '^^?exMultiOccurrenceMaitreDetails',
            monoOccurrenceEcranCtrl: '^^?exMonoOccurrenceEcran',
            monoOccurrenceCtrl: '^^?exMonoOccurrence',
            templateCtrl: '?^exTemplate',
            ecranContextCtrl: '^^exEcranContext',
            dataSourceCtrl: '^^?exDataSource'
        },
        controllerAs: 'vm',
        template: require('./ex-multi-occurrence-maitre-details.html')
    };
}
