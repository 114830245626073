import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageMc0083 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireSrv: IFormulaire;
    scoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    usvMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0083Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0083 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {};

        vm.formulaireSrv = new Formulaire('LBL_BLC_SRV', [
            new FormulaireItem('srvcod'),
            new FormulaireItem('srvdsc'),
            new FormulaireItem('srvdscabr'),
            new FormulaireItem('srvindact'),
            new FormulaireItem('srvdatdeb'),
            new FormulaireItem('srvdatfin')
        ], {largeurDefaut: 33});

        vm.scoMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'cyocod', largeur: 130},
                {nom: 'cyodscabr', largeur: 500},
                {nom: 'vaedsc', largeur: 350}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cyocleint')
            ])
        };

        vm.usvMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'usrcod', largeur: 130},
                {nom: 'usrnom', largeur: 500}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('usrcleint')
            ])
        };
    }
}
