import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'in0050',
    ecrans: ['00-2'],
    params: ['id',
             'fimcleint',
             'procleint'
            ]
});
