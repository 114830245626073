import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageIN0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function In0016Controller(Menu: IMenuClass,
                                         MenuItem: IMenuItemClass) {
    const vm: IPageIN0016 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItem('BTN_RECEPTION', () => {}),
            new MenuItem('BTN_RETOUR', () => {})
        ]),
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 200},
            {nom: 'dinnumdoc', largeur: 200},
            {nom: 'dinnumdocseq', largeur: 200},
            {nom: 'docaffnumdoc01', largeur: 200},
            {nom: 'tydcodref', largeur: 200},
            {nom: 'dinnumref', largeur: 200},
            {nom: 'txmnumret', largeur: 200},
            {nom: 'docaffsysboncmd01', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnomabr', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'etddsc', largeur: 200},
            {nom: 'dinmnt', largeur: 200},
            {nom: 'magcod', largeur: 200},
            {nom: 'magnom', largeur: 200},
            {nom: 'intcodreq', largeur: 200},
            {nom: 'intnomreq', largeur: 200},
            {nom: 'intcoddem', largeur: 200},
            {nom: 'intnomdem', largeur: 200},
            {nom: 'intcodmag', largeur: 200},
            {nom: 'intnommag', largeur: 200},
            {nom: 'flgadhoc', largeur: 200},
            {nom: 'intcodcib', largeur: 200},
            {nom: 'intnomcib', largeur: 200},
            {nom: 'usrcod', largeur: 200}
        ]
    };
}
