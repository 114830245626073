import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IMenuItemFactoryClass} from '../../../../../core/services/menu/menu-item-factory.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0556 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    rceCumMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rceDetMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0556Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         rr0503Route: IRoute,
                                         MenuItemFactory: IMenuItemFactoryClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         dateFilter: IFilterDate,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageRR0556 = this;

    const criteresSuggeresVisibles = !$stateParams.employe;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            criteresSuggeresVisibles
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_demcleint', {
                required: true,
                hidden:  $stateParams.employe,
                ignoreEtat: $stateParams.employe,
                default: $stateParams.employe

            }),
            new FormulaireItem('t_ppacleintdeb', {
                required: true,
                resourceParams: (data: any) => ({t_unpcleint: data.t_unpcleint})
            }),
            new FormulaireItem('t_ppacleintfin', {
                required: true,
                resourceParams: (data: any) => ({t_unpcleint: data.t_unpcleint})
            }),

            new FormulaireItemSautDeLigne(),

            new FormulaireItem('t_vaesystypcum',{
                direction: 'vertical',
                default: '1'
            }),

            new FormulaireItem('t_anrann')

        ],{largeurDefaut: 33.33}),

        colonnesVisibles: [
            {nom: 'rcecod'   , largeur: 120},
            {nom: 'rcedscabr', largeur: 400},
            {nom: 'druqteadm', largeur: 130},
            {nom: 'drumntadm', largeur: 130},
            {nom: 'drumntemp', largeur: 130},
            {nom: 'drumntemr', largeur: 130}

        ],
        colonnesCachees: ['rcecleint']

    };

    vm.rceCumMultiOccurrenceOptions = {
        bloc: 'rcecum',

        fonctions: {
            suiviModification: false
        },
        colonnesVisibles: [
            {nom: 'rcecod'   , largeur: 120},
            {nom: 'rcedscabr', largeur: 320},
            {nom: 'ppacod'   , largeur: 120},
            {nom: 'druqteadm', largeur: 135},
            {nom: 'drumntadm', largeur: 135},
            {nom: 'drumntemp', largeur: 135},
            {nom: 'drumntemr', largeur: 135}
        ],
        colonnesCachees: ['ppacleint',
                          'rcecleint']
    };

    vm.rceDetMultiOccurrenceOptions = {
        bloc: 'rcedet',
        navigatePage: rr0503Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.pamcleint
            };
        },
        fonctions: {
            suiviModification: false
        },
        colonnesCachees:['pamcleint'],
        colonnesVisibles: [
            {nom: 'parqteadm' , largeur: 135},
            {nom: 'parmntadm' , largeur: 135},
            {nom: 'parmntemp' , largeur: 135},
            {nom: 'parmntemr'    , largeur: 135},
            {nom: 'cotcod' , largeur: 115},
            {nom: 'cotdsc' , largeur: 300},
            {nom: 'pamnumdoc' , largeur: 180}
        ]
    };

}
