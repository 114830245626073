import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageCH0008 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireHad: IFormulaire;
    formulaireTom: IFormulaire;
    valeursEcran: any;
}

interface ICh0008Params {
    id: string;
    itpcleint: number;
    menuId: string;
}

/* @ngInject */
export default function Ch0008Controller(ch0009Route: IRoute,
                                         ch0010Route: IRoute,
                                         $stateParams: ICh0008Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageCH0008 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.menuApp = new MenuItemEcran(
            'LBL_APPEL_EMP',
            ch0009Route.NAME,
            (data:any) => ({
                hadcleint: vm.monoOccurrence.data.hadcleint
            }),
            {
                icon: 'announcement',
                iconButton: false,
                fonction: 'BTN_APPEL_EMPLOYE',
                hidden: (data:any) => !vm.monoOccurrence || !vm.monoOccurrence.data.hadcleint
            }
        );

        vm.menuDisp = new MenuItemEcran(
            'LBL_DISPO',
            ch0010Route.NAME,
            (data:any) => ({
                hadcleint: vm.monoOccurrence.data.hadcleint
            }),
            {
                icon: 'group',
                iconButton: false,
                fonction: 'BTN_DISPONIBILITE',
                hidden: (data:any) => !vm.monoOccurrence || !vm.monoOccurrence.data.hadcleint
            }
        );

        vm.formulaireHad = new Formulaire('LBL_APPEL', [
            new FormulaireItem('itpcleint', {largeur: 100, default: $stateParams.itpcleint}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('itycleint', {
                largeur: 50,
                resourceParams: (data:any) => {
                    return {
                        itpcleint: $stateParams.itpcleint
                    };
                }
            }),
            new FormulaireItem('etqcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('haddat', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('hadhredeb'),
            new FormulaireItem('hadhrefin'),
            new FormulaireItem('hadqte'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('haddsc', {largeur: 100, enableCount: true})
        ], {
            largeurDefaut: 33.33
        });

        vm.formulaireTom = new Formulaire('LBL_TOM', [
            new FormulaireItem('haddattom', {largeur: 100})
        ]);
    }
}
