import { IComponentController } from 'angular';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';

interface IPageSp0003 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireInfoDem: IFormulaire;
    formulaireConRh: IFormulaire;
    formulaireProbation: IFormulaire;
}
interface ISp0003Params {
    id: string;
    demcleint: string;
    tsecleint: string;
}

/* @ngInject */
export default function Sp0003Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: ISp0003Params,
    FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageSp0003 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        formulaire: new Formulaire('LBL_BLC_EVA', [
            new FormulaireItem('evanumdoc'),
            new FormulaireItem('tevcleint', { modifAllow: false }),
            new FormulaireItem('ttccleint', { readonly: true }),
            new FormulaireItemDivider('LBL_DIV_ORI'),
            new FormulaireItem('vaeevatyp'),
            new FormulaireItem('evaraison', { largeur: 66.66 }),
            new FormulaireItem('demcleint', { largeur: 66.66 , default: $stateParams.demcleint }),
            new FormulaireItem('tsecleint', { default: $stateParams.tsecleint,
                resourceParams: (data: any) => ({ demcleint: data.demcleint, tsecleint: $stateParams.tsecleint } )
            }),
            new FormulaireItemDivider('LBL_DIV_EVA'),
            new FormulaireItem('evadatdeb', { largeur: 25 }),
            new FormulaireItem('evadatfinprv', { largeur: 25 }),
            new FormulaireItem('evadatenvdoc', { largeur: 25 }),
            new FormulaireItem('evadateva', { largeur: 25 }),
            new FormulaireItem('coocleint', { largeur: 50 }),
            new FormulaireItem('poscleintsup', { largeur: 50 }),
            new FormulaireItem('demcleintsup', {
                largeur: 50,  resourceParams: (data: any) => ({ evadatdeb: data.evadatdeb })
            }),
            new FormulaireItem('evademcleint', {
                largeur: 50,
                resourceParams: (data: any) => ({ evadatdeb: data.evadatdeb })
            }),
            new FormulaireItem('evacom', { largeur: 100 })
        ], { largeurDefaut: 33.33 })
    };

    vm.formulaireInfoDem = new Formulaire('LBL_BLC_INFO', [
        new FormulaireItem('lemcleint', { readonly: (data: any) => (data.tsecleint) }),
        new FormulaireItem('aftcleint', { readonly: (data: any) => (data.tsecleint) }),
        new FormulaireItem('unocleintrh'),
    ], { largeurDefaut: 100 });

    vm.formulaireConRh = new Formulaire('LBL_BLC_CONRH', [
        new FormulaireItem('intcleintrh', { largeur: 100 }),
        new FormulaireItem('evaintnumtel'),
        new FormulaireItem('evaintnumpos')
    ]);

    vm.formulaireProbation = new Formulaire('LBL_BLC_PRO', [
        new FormulaireItem('tprcoddsc', { readonly: true, largeur: 100 }),
        new FormulaireItem('tsedatdebprb', { readonly: true }),
        new FormulaireItem('tsedatfinprb', { readonly: true }),
        new FormulaireItem('tsesldbaccre', { readonly: true }),
        new FormulaireItem('tsecomprb', { readonly: true, largeur: 100 })
    ], { largeurDefaut: 33.33 })
}
