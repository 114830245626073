import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageGs0052 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0052Controller(Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0052 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false,
            nouveau: ecranDetails.valeurs.flgmod,
            edition: ecranDetails.valeurs.flgmod,
            supprime: ecranDetails.valeurs.flgmod,
            boutonDupliquer: ecranDetails.valeurs.flgmod
        },
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 215 },
            { nom: 'tvsdsc', largeur: 215 },
            { nom: 'tvsflgsupmeg', largeur: 110 },
            { nom: 'tvscom', largeur: 280 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesystypavi', { largeur: 25 }),
            new FormulaireItem('tvsdsc', { largeur: 55 }),
            new FormulaireItem('tvsflgsupmeg', { largeur: 25 }),
            new FormulaireItem('tvscom', { largeur: 55 })
        ])
    };
}
