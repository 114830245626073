import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGb0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0004Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageGb0004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('gebcodnum'),
            new FormulaireItem('intcleintdep'),
            new FormulaireItem('lolcleint'),
            new FormulaireItem('unacleint'),
            new FormulaireItem('gbpcleintcri'),
            new FormulaireItem('intcleinttec'),
            new FormulaireItem('gcbcleint'),
            new FormulaireItem('vecgebconcri'),
            new FormulaireItem('gebmntcri'),
            new FormulaireItem('gebnomartcri'),
            new FormulaireItem('gebprnartcri'),
            new FormulaireItem('gebdatcrecri'),
            new FormulaireItem('gebdatfincri'),
            new FormulaireItem('inadatcri'),
            new FormulaireItemSautDeLigne,
            new FormulaireItem('statut', {default: 'TOUS'})
        ]),
        colonnesFixesGauche: [
            { nom: 'gebcod', largeur: 80}
        ],
        colonnesVisibles: [
            { nom: 'gebdsc', largeur: 280 },
            { nom: 'gebflgact', largeur: 60 },
            { nom: 'intcoddep', largeur: 110 },
            { nom: 'intnomdep', largeur: 150 },
            { nom: 'lolnumloc', largeur: 80 },
            { nom: 'loldsc', largeur: 140 },
            { nom: 'unacod', largeur: 80 },
            { nom: 'unadsc', largeur: 200 },
            { nom: 'gbpcod', largeur: 130 },
            { nom: 'gbpdsc', largeur: 210 },
            { nom: 'gebfon', largeur: 180 },
            { nom: 'gebmod', largeur: 190 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'gebnumser', largeur: 180 },
            { nom: 'gebmnt', largeur: 170 },
            { nom: 'intcodtec', largeur: 110 },
            { nom: 'intnomtec', largeur: 150 },
            { nom: 'gebflgcon', largeur: 100 },
            { nom: 'connumdoc', largeur: 130 },
            { nom: 'condsc', largeur: 180 },
            { nom: 'gebdatfin', largeur: 140 },
            { nom: 'gebannacq', largeur: 130 },
            { nom: 'frecod', largeur: 130 },
            { nom: 'fredsc', largeur: 160 },
            { nom: 'gcbcod', largeur: 130 },
            { nom: 'gcbdsc', largeur: 180 },
            { nom: 'vecgebcon', largeur: 150 },
            { nom: 'gebcondsc', largeur: 170 },
            { nom: 'gebnomart', largeur: 170 },
            { nom: 'gebprnart', largeur: 170 },
            { nom: 'gebdatcre', largeur: 130 },
            { nom: 'gebtit', largeur: 190 },
            { nom: 'gebautdsc', largeur: 130 },
            { nom: 'gebcomaut', largeur: 170 },
            { nom: 'gebexpdsc', largeur: 140 },
            { nom: 'gebcomexp', largeur: 170 },
            { nom: 'gebmat', largeur: 140 },
            { nom: 'gebtec', largeur: 140 },
            { nom: 'gebedt', largeur: 140 },
            { nom: 'gebmoddsc', largeur: 140 },
            { nom: 'gebmntval', largeur: 150 },
            { nom: 'gebhaucad', largeur: 150 },
            { nom: 'unmcodhca', largeur: 60 },
            { nom: 'gebloncad', largeur: 150 },
            { nom: 'unmcodlca', largeur: 60 },
            { nom: 'gebprocad', largeur: 150 },
            { nom: 'unmcodpca', largeur: 60 },
            { nom: 'gebhausan', largeur: 150 },
            { nom: 'unmcodhsc', largeur: 60 },
            { nom: 'geblonsan', largeur: 150 },
            { nom: 'unmcodlsc', largeur: 60 },
            { nom: 'gebprosan', largeur: 150 },
            { nom: 'unmcodpsc', largeur: 60 },
            { nom: 'ivdnom', largeur: 140 },
            { nom: 'ivdprn', largeur: 140 },
            { nom: 'ivdmen', largeur: 140 },
            { nom: 'ivecon', largeur: 170 },
            { nom: 'iveindsec', largeur: 120 },
        ]
    };
}
