import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IDialogAllStatus } from "../../../../../core/constants/dialog.constant";
import IDialogService = angular.material.IDialogService;

interface IPageMR2005 extends IComponentController {
    multiOccurrenceRep: IMultiOccurrence;
    repMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function MR2005Controller($stateParams: any,
    MenuItemAction: IMenuItemActionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    DialogStatus: IDialogAllStatus,
    $mdDialog: IDialogService) {
    const vm: IPageMR2005 = this;
    vm.$onInit = function $onInit() {
        vm.ajoutIndividu = $stateParams.ajoutIndividu
        vm.reperer = new MenuItemAction(
            'BTN_REPERER',
            'BOUREPERER',
            () => ({ tsecleint: $stateParams.id }),
            {
                icon: 'replay',
                iconButton: false,
                secondaire: true,
                successAction: () => {
                    vm.multiOccurrenceRep.fetchDataList()
                },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_REPERER',
                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                    largeurFormulaire: 25,
                    formulaire: new Formulaire([
                        new FormulaireItem('codperm', {
                            required: true,
                            largeur: 100,
                            default: (data: any) => data.codperm
                        })
                    ])
                }
            }
        );

        const successAction = () => {
            if ($stateParams.ajoutIndividu) {
                $mdDialog.hide(DialogStatus.APPLIQUER);
            } else {
                window.history.back()
            }
        }

        // bouton avec adresse
        vm.bouavecadr = new MenuItemAction(
            'BTN_AVECADR',
            'BOUAVECADR',
            () => ({ tsecleint: $stateParams.id }),
            {
                icon: 'replay',
                iconButton: false,
                secondaire: true,
                successAction,
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_AVCADR',
                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                    largeurFormulaire: 25,
                    formulaire: new Formulaire([
                        new FormulaireItem('com', { largeur: 100 })
                    ])
                }
            }
        );

        // bouton sans adresse
        vm.bousansadr = new MenuItemAction(
            'BTN_SANSADR',
            'BOUSANSADR',
            () => ({ tsecleint: $stateParams.id }),
            {
                icon: 'replay',
                iconButton: false,
                secondaire: true,
                successAction,
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_SANSADR',
                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                    largeurFormulaire: 25,
                    formulaire: new Formulaire([
                        new FormulaireItem('com', { largeur: 100 })
                    ])
                }
            }
        );

        vm.boutonAjoutIndividu = new MenuItemAction(
            'BTN_AJOUT_INDIVIDU',
            'BOUAJOUTINDIVIDU',
            () => ({ tsecleint: $stateParams.id }),
            {
                icon: 'replay',
                iconButton: false,
                secondaire: true,
                successAction
            }
        );

        // bouton continuer sans création
        vm.bousanscreer = new MenuItemAction(
            'BTN_SANSCREER',
            'BOUSANSCREER',
            () => ({ tsecleint: $stateParams.id }),
            {
                icon: 'replay',
                iconButton: false,
                secondaire: true,
                successAction,
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_SANSCREER',
                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                    largeurFormulaire: 25,
                    formulaire: new Formulaire([
                        new FormulaireItem('com', { largeur: 100 })
                    ])
                }
            }
        );

        // bouton annuler
        vm.bouannuler = new MenuItemAction(
            'BTN_ANNULER',
            'BOUANNULER',
            () => ({ tsecleint: $stateParams.id }),
            {
                icon: 'replay',
                iconButton: false,
                secondaire: true,
                successAction
            }
        );

        vm.repMultiOccurrenceOptions = {
            resourceParams: { tsecleint: $stateParams.id },
            fonctions: {
                editionStandard: false
            },
            colonnesVisibles: [
                { nom: 'iutflgsel', largeur: 110, editable: true },
                { nom: 'eplnom', largeur: 150 },
                { nom: 'eplprn', largeur: 160 },
                { nom: 'vaeeplsex_dsc', largeur: 135 },
                { nom: 'eplnas', largeur: 125 },
                { nom: 'epldatnai', largeur: 160 },
                { nom: 'eadadr1', largeur: 160 },
                { nom: 'munnomfra', largeur: 160 },
                { nom: 'muncod', largeur: 160 },
                { nom: 'etacod', largeur: 130 },
                { nom: 'pyscod', largeur: 160 },
                { nom: 'eadcodpos', largeur: 130 },
                { nom: 'iutmatalp', largeur: 150 },
                { nom: 'eplprnusu', largeur: 160 }
            ]
        };
    }
}
