import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pr0027Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0027 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'pficod', largeur: 80 },
            { nom: 'pfidsc', largeur: 120 },
            { nom: 'pfisrucod', largeur: 120 },
            { nom: 'pfiflgact', largeur: 74 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pficod', { required: true, largeur: 15 }),
            new FormulaireItem('pfidsc', { required: true, largeur: 35 }),
            new FormulaireItem('pfisrucod', { largeur: 35 }),
            new FormulaireItem('pfiflgact', { largeur: 15, default: 1 })
        ])
    };
}
