import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IComponentController } from 'angular';

interface IPageCb2009 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Cb2009Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
) {
    const vm: IPageCb2009 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false,            
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('type', {
                    default: $stateParams.type,
                    hidden: true
                }),
                new FormulaireItem('prucleintcri', {
                    default: $stateParams.prucleint,
                    disabled: true
                }),
                new FormulaireItem('prbcleintcri', {
                    default: $stateParams.prbcleint,
                    disabled: true
                }),
                new FormulaireItem('prvcleintcri', {
                    default: $stateParams.prvcleint,
                    disabled: true
                }),
                new FormulaireItem('soncleintcri', {
                    default: $stateParams.soncleint,
                    disabled: true
                }),
                new FormulaireItem('cptcleintcri', {
                    default: $stateParams.cptcleint,
                    disabled: true
                }),
                new FormulaireItem('vaesysnatctbcri', {
                    default: $stateParams.vaesysnatctb,
                    disabled: $stateParams.vaesysnatctb
                }),
                new FormulaireItem('tymcleintcri', {
                    default: $stateParams.tymcleint,
                    disabled: $stateParams.tymcleint
                }),
                new FormulaireItem('doccleintrefcri', {
                    default: $stateParams.doccleintref,
                    hidden: true
                }),
                new FormulaireItem('pecanncri', {
                    default: $stateParams.pecann,
                    disabled: $stateParams.pecann
                }),
                new FormulaireItem('niveaucri', {
                    default: $stateParams.niveau,
                    hidden: true, 
                    disabled: $stateParams.niveau
                }),
                new FormulaireItem('peccleintdebcri', {
                    default: $stateParams.peccleintdeb,
                    disabled: $stateParams.peccleintdeb,
                    hidden: () => (vm.valeursEcran.vaeprutypbdg !== 'A')
                }),
                new FormulaireItem('peccleintfincri', {
                    default: $stateParams.peccleintfin,
                    disabled: $stateParams.peccleintfin,
                    hidden: () => (vm.valeursEcran.vaeprutypbdg !== 'A')
                }),
                new FormulaireItem('peccleintdebcivcri', {
                    default: $stateParams.peccleintdeb,
                    disabled: $stateParams.peccleintdeb,
                    hidden: () => (vm.valeursEcran.vaeprutypbdg !== 'P')
                }),
                new FormulaireItem('peccleintfincivcri', {
                    default: $stateParams.peccleintfin,
                    disabled: $stateParams.peccleintfin,
                    hidden: () => (vm.valeursEcran.vaeprutypbdg !== 'P')
                }),
                new FormulaireItem('elecleintcri', {
                    default: $stateParams.elecleint,
                    hidden:true,
                    disabled: $stateParams.elecleint
                })
            ], {largeurDefaut: 33}
        ),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 250 },
            { nom: 'mntsal', largeur: 130, total: true },
            { nom: 'mntavs', largeur: 130, total: true },
            { nom: 'mnttot', largeur: 130, total: true }
        ]
    };

}
