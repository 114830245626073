import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0022',
    ecrans: [
        '00-2',
        '01-2',
        '02-2'
    ],
    params: [
        'id',
        '?trncleint',
        '?lsacleint',
        '?tsacleintori',
        '?tsecleint',
        '?rrhcleintref',
        '?ecrcleint_appel',
        '?flgrr0005'
    ]
});
