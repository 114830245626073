import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGs0040 extends IComponentController {
  multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0040Controller(Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass) {
  const vm: IPageGs0040 = this;
  
  vm.multiOccurrenceOptions = {
    fonctions: {
      rechercheCollapsable: false
    },
    colonnesVisibles: [
      {nom: 'gprdscabr', largeur:200 },
      {nom: 'srccod', largeur:100 },
      {nom: 'gvcval', largeur:300 },
      {nom: 'gprdsc', largeur:300 },
      {nom: 'gprvaldev', largeur:300 }
    ],
    formulaire: new Formulaire([
      new FormulaireItem('gprcleint', { largeur: 33}),
      new FormulaireItem('gprdsc', { largeur: 33, readonly: true}),
      new FormulaireItem('gprvaldev', { largeur: 33, readonly: true}),
      new FormulaireItem('srccleint'),
      new FormulaireItem('gvcval')
    ])
  };
}