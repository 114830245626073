import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMr0186 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    dtsMultiOccurenceOptions: IMultiOccurrenceOptions;
    flgdbg: boolean;
}
/* @ngInject */
export default function Mr0186Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    mr0205Route: IRoute,
    mr0208Route: IRoute,
    rr0074Route: IRoute,
    rr0075Route: IRoute,
    rr0006Route: IRoute
) {
    const vm: IPageMr0186 = this;

    vm.flgdbg = false;
    const actionsMoreLigne = new Menu([
        new MenuItemForage(
            'BTN_FOR_VEN',
            mr0205Route.NAME,
            (rowData: any) => (
                {
                    stamnetab: rowData.stamnetab,
                    dnecleintref: rowData.dnecleintref,
                    id: rowData.dnecleintref
                }
            ),
            {
                icon: 'launch_app',
                iconButton: false
            }
        ),
        new MenuItemForage(
            'BTN_FOR_IMP',
            mr0208Route.NAME,
            (rowData: any) => (
                {
                    doccleintgrh: rowData.doccleintgrh,
                    id: rowData.demcleint
                }
            ),
            {
                icon: 'launch_app',
                iconButton: false
            }
        ),
        new MenuItemAction('LBL_BTN_REE', 'BOUREE', () => ({}), {
            icon: 'refresh',
            fonction: 'BOUREE'
        })
    ])
    vm.multiOccurrenceOptions = {
        actionsMoreLigne,
        navigatePage: (Data) => getForageItem(Data),
        navigateParams: (Data) => getForageParams(Data),
        fonctions: {
            reinitialiserOuvrirCriteres: true,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('unpcleint', { largeur: 50 }),
            new FormulaireItem('cifcleint', { largeur: 50 }),
            new FormulaireItem('demcleint', { largeur: 50, required: false }),
            new FormulaireItem('unacleint', { largeur: 50 }),
            new FormulaireItem('t_docaffnumdoc01', { largeur: 50 }),
            new FormulaireItem('grpcleint', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_grpsta', { largeur: 50, default: 'TOUS' }),
            new FormulaireItem('t_grptypobj', { largeur: 50, default: 'TOUS' }),
            new FormulaireItem('t_flgdnc', { largeur: 50, default: 2 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_DATE_ENG'),
            new FormulaireItem('datfinengdeb', { largeur: 50 }),
            new FormulaireItem('datfinengfin', { largeur: 50 })
        ]),
        colonnesVisibles: [
            { nom: 'dnelib', largeur: 194 },
            { nom: 'dnecod', largeur: 110 },
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 262 },
            { nom: 'dnedatfinengfin', largeur: 120 },
            { nom: 'dnedatdebsim', largeur: 120 },
            { nom: 'dnedatfinsim', largeur: 100 }
        ],
        colonnesFixesDroite: [
            { nom: 'flg_err', largeur: 100 },
            { nom: 'flg_avr', largeur: 80 },
            { nom: 'flg_dva', largeur: 80 }
        ],
        colonnesCachees: ['dnecleint', 'stamnetab', 'doccleintgrh', 'dnecleintref', 'dercleint', 'trncleintder', 'trncleintaff', 'trncleintdrp'],
        actionsMore: new Menu([
            new MenuItemAction('LBL_BTN_REE', 'BOUREE', () => ({}), {
                icon: 'refresh',
                fonction: 'BOUREE'
            })
        ])

    };
    vm.dtsMultiOccurenceOptions = {
        bloc: 'dts',
        colonnesVisibles: ['vaedtstyp', 'dtsmsg']
    };

    function getForageItem(rowData: any): string {
        if (rowData.stamnetab === 'DER') {
            return rr0074Route.NAME
        }
        if (rowData.stamnetab === 'DRP') {
            return rr0075Route.NAME
        }
        if (rowData.stamnetab === 'AFT') {
            return rr0006Route.NAME
        }
    }

    function getForageParams(rowData: any): any {
        if (rowData.stamnetab === 'DER') {
            return ({ id: rowData.trncleintder })
        }
        if (rowData.stamnetab === 'DRP') {
            return ({ id: rowData.trncleintdrp })
        }
        if (rowData.stamnetab === 'AFT') {
            return ({ id: rowData.trncleintaff })
        }
    }
}
