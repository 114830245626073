import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IStateParamsService } from "angular-ui-router";

interface IPageMC1010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRmdOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc1010Controller($stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass) {
    const vm: IPageMC1010 = this;

    vm.multiOccurrenceRmdOptions = {
        bloc: 'rmd',
        fonctions: {
            nouveau: $stateParams.ecran === "00-2",
            supprime: $stateParams.ecran === "00-2",
            edition: $stateParams.ecran === "00-2"
        },
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [5, 10, 25]
        }),
        colonnesVisibles: [
            { nom: 'rmdnumlig', largeur: 100 },
            { nom: 'rmdmsg', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rmdnumlig', { largeur: 25 }),
            new FormulaireItem('rmdmsg', { enableCount: true, largeur: 75 })
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            nouveau: $stateParams.ecran === "00-2",
            supprime: $stateParams.ecran === "00-2",
            edition: $stateParams.ecran === "00-2"
        },
        colonnesVisibles: [
            { nom: 'rmedatdeb', largeur: 100 },
            { nom: 'rmedatfin', largeur: 100 }
        ],
        colonnesCachees: [
            'refcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', { default: $stateParams.refcleint, hidden: true }),
            new FormulaireItem('rmedatdeb'),
            new FormulaireItem('rmedatfin')
        ], { largeurDefaut: 25 }),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceRmdOptions
    };
}
