import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0069 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0069Controller(
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass, 
    mr0066Route: IRoute) {
    const vm: IPageMr0069 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mr0066Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0066Route.NAME, () => ({}), {
                fonction: 'BOUNOU'
            })
        ]),
        colonnesVisibles: [
            { nom: 'cyrcod', largeur: 25 },
            { nom: 'cyrdsc', largeur: 250 },
            { nom: 'cyrcom', largeur: 800 }
        ]
    };
}
