import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import IStateService = angular.ui.IStateService;
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRc2001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Rc2001Controller($state: IStateService,
    Menu: IMenuClass,
    rc0001Route: IRoute,
    $stateParams: any,
    MenuItemSelection: IMenuItemSelectionClass,
    exDateFilter: IFilterExDate,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemAction: IMenuItemActionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRc2001 = this;
    vm.valeursEcran = ecranDetails;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_COPIE_ECRITURE',
                'BOUCOP',
                (rowData: any) => ({ ecjcleint: rowData.ecjcleint }),
                {
                    icon: 'content_copy',
                }
            )
        ]),
        filtresParametres: [
            { colonne: 'prpcleint', valeur: $stateParams.prpcleint, visible: false }
        ],
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_OK', 'BOUOK', {
                selection: {
                    nom: 'ok',
                    lblConfirmationTitre: 'LBL_BTN_CREER_ENT',
                    largeurFormulaire: 50,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('intcleintmag'),
                        new FormulaireItem('txmnbrcolrec'),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('txmnumref', { largeur: 25, required: true }),
                        new FormulaireItem('txmdat', { default: exDateFilter(new Date()), largeur: 25 }),
                        new FormulaireItem('txmnbrcolliv', { largeur: 25 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('txmnomtra', { largeur: 50 }),
                        new FormulaireItem('txmdsc', { default: 'Réception de marchandise', largeur: 50 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('txmnumemb')
                    ], { largeurDefaut: 50 })
                },
                successAction: (data: any) => {
                    $state.go(rc0001Route.NAME,
                        {
                            menuId: $stateParams.menuId,
                            id: data.doccleint
                        });
                },
                fonction: 'BOUOK'
            })
        ]),
        colonnesFixesGauche: [
            { nom: 'prplig', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'prpnumcat', largeur: 160 },
            { nom: 'procod', largeur: 140, hidden: ecranDetails.valeurs.flgpro },
            { nom: 'prodsc', largeur: 180 },
            { nom: 'forachcod', largeur: 130 },
            { nom: 'forachdsc', largeur: 200 },
            { nom: 'forinvcod', largeur: 150, hidden: ecranDetails.valeurs.flgforinv },
            { nom: 'forinvdsc', largeur: 200, hidden: ecranDetails.valeurs.flgforinv }
        ],
        colonnesFixesDroite: [
            { nom: 'diiprxunimov', largeur: 120 },
            { nom: 'qtedspmov', largeur: 120 },
            { nom: 'diiqtemovfor', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('diiqtemovfor')
        ], { largeurDefaut: 33 }),
        colonnesCachees: [
            'prpcleint',
            'vaesysmodcod'
        ]
    };
}
