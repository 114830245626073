import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IComponentController } from "angular";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCc0008 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurence: IMonoOccurrence;
    tamMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cc0008Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageCc0008 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_FDB', [
            new FormulaireItem('tarcleint', { largeur: 50 }),
            new FormulaireItem('tarflgaccanc', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint', { largeur: 50 }),
            new FormulaireItem('tarflgcbl', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ttccleint', { largeur: 50 }),
            new FormulaireItem('tarflgenseigncc', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temcleint', { largeur: 50 }),
            new FormulaireItem('tarflgtravopratiq', { largeur: 20 })
        ], { largeurDefaut: 50 })
    };

    // Type demande rémun.
    vm.tamMultiOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('tdmcleint', { largeur: 45 }),
            new FormulaireItem('tagcleint', { largeur: 45 }),
            new FormulaireItem('tamflghre', { largeur: 15 }),
            new FormulaireItem('tamflgnbr', { largeur: 15 }),
            new FormulaireItem('tamflggenfer', { largeur: 15 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gfrcleint', { largeur: 20 }),
            new FormulaireItem('tamqtefer', { largeur: 20 })
        ]),
        colonnesVisibles: [
            { nom: 'tdmcod', largeur: 20 },
            { nom: 'tdmdsc', largeur: 60 },
            { nom: 'tagcod', largeur: 20 },
            { nom: 'tagdsc', largeur: 60 },
            { nom: 'tamflghre', largeur: 20 },
            { nom: 'tamflgnbr', largeur: 20 },
            { nom: 'tamflggenfer', largeur: 20 },
            { nom: 'gfrcod', largeur: 50 },
            { nom: 'tamqtefer', largeur: 50 }
        ]
    };
}
