import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IComponentController} from 'angular';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageIF0016 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    disMultiOccurrenceOptions: IMultiOccurrenceOptions;
    disMultiOccurrence: IMultiOccurrence;
}

interface IIf0016Params {
    discleintgen: string;
    vecdistypdis_def_dispo: string;
    disdat: string;
}

/* @ngInject */
export default function If0016Controller($stateParams: IIf0016Params,
                                         Operateur: IOperateurService,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageIF0016 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.stateParams = $stateParams;

        // Exécuter
        vm.executer = new MenuItemAction(
            'BTN_EXECUTER',
            'BOUEXECUTER',
            () => ({
                discleintgen: $stateParams.discleintgen
            }),
            {
                icon: 'done_all',
                iconButton: false,
                successAction: () => {
                    vm.disMultiOccurrence && vm.disMultiOccurrence.fetchDataList();
                }
            }
        );
        // Renverser
        vm.renverser = new MenuItemAction(
            'BTN_RENVERSER',
            'BOURENVERSER',
            () => ({
                discleintgen: $stateParams.discleintgen
            }),
            {
                icon: 'replay',
                iconButton: false,
                secondaire: true,
                successAction: () => {
                    vm.disMultiOccurrence && vm.disMultiOccurrence.fetchDataList();
                },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_RENVERSER',
                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                    largeurFormulaire: 25,
                    formulaire: new Formulaire([
                        new FormulaireItem('date_renv', {
                            largeur: 100,
                            default: (data: any) => data.disdat
                        })
                    ])
                }
            }
        );

        vm.disMultiOccurrenceOptions = {
            filtres: [
                {
                    colonne: 'discleint',
                    operateur: Operateur.EGALE,
                    valeur: ($stateParams.discleintgen).split(','),
                    visible: false
                }
            ],
            fonctions: {
                editionRapide: true,
                consultationStandard: false,
                editionStandard: false
            },
            editionRapideActive: true,
            colonnesEditionRapide: [
                {nom: 'disdattra', largeur: 110},
                {nom: 'immcod', largeur: 140},
                {nom: 'immdsc', largeur: 175},
                {nom: 'vaeimmstu_dsc', largeur: 125},
                {nom: 'vecdistypdis', largeur: 120},
                {nom: 'disdat', largeur: 100},
                {nom: 'dismnt', largeur: 130}
            ],
            colonnesVisibles: [
                {nom: 'disdattra', largeur: 125},
                {nom: 'immcod', largeur: 140},
                {nom: 'immdsc', largeur: 250},
                {nom: 'vaeimmstu_dsc', largeur: 150},
                {nom: 'vecdistypdis', largeur: 120},
                {nom: 'disdat', largeur: 100},
                {nom: 'dismnt', largeur: 125}
            ]
        };
    }
}
