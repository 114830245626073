import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMC0118 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0118Controller(mc0109Route: IRoute,
                                         Menu: IMenuClass) {
    const vm: IPageMC0118 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0109Route.NAME,
        fonctions: {
            nouveau: false,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'ajfnumdoc', largeur: 152},
            {nom: 'ajfnumdocseq', largeur: 60},
            {nom: 'tydcod', largeur: 65},
            {nom: 'docaffnumdoc01', largeur: 152},
            {nom: 'tydcodref', largeur: 110},
            {nom: 'peccod', largeur: 80},
            {nom: 'etddsc', largeur: 180},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 262},
            {nom: 'ajfdsc', largeur: 268},
            {nom: 'usrcod', largeur: 110}
        ]
    };
}
