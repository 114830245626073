import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0044 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0044Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0044 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'pfncod', largeur: 84},
            {nom: 'pfndsc', largeur: 315},
            {nom: 'pfnflgpdi', largeur: 54},
            {nom: 'pfnflgact', largeur: 64}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pfncod', {required: true}),
            new FormulaireItem('pfndsc', {required: true}),
            new FormulaireItem('pfnflgpdi'),
            new FormulaireItem('pfnflgact', {default: 1})
        ]),
        fonctions:{
            supprime: false
        }
    };
}
