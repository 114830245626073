import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";

interface IPageRr0525 {
    multiOccurrence: IMultiOccurrence
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0525Controller(Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0525 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_SELECTION', 'BOUSEL', {
                selection: { nom: 'selectionner' },
                successAction: () => {
                    vm.multiOccurrence && vm.multiOccurrence.fetchDataList();
                }
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datlan'),
            new FormulaireItem('seqlan'),
            new FormulaireItem('dsclan'),
            new FormulaireItem('unpcleintcri'),
            new FormulaireItem('ppacleintcri'),
            new FormulaireItem('vaetyplancri'),
            new FormulaireItem('reocri', { default: 'Tous' })
        ], { largeurDefaut: 33 }),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 91 },
            { nom: 'eplnomprn', largeur: 190 }
        ],
        colonnesVisibles: [
            { nom: 'reecledat', largeur: 120 },
            { nom: 'ppacod', largeur: 150 },
            { nom: 'ppadatfin', largeur: 120 },
            { nom: 'reodelpai', largeur: 100 },
            { nom: 'origine', largeur: 336 },
            { nom: 'datdeb', largeur: 150 },
            { nom: 'datfin', largeur: 100 },
            { nom: 'vaereemot_dsc', largeur: 160 },
            { nom: 'reodatret', largeur: 120 },
            { nom: 'reodatdep', largeur: 120 },
            { nom: 'reodatrer', largeur: 120 },
            { nom: 'urgcod', largeur: 190 },
            { nom: 'urgdsc', largeur: 250 },
            { nom: 'strcod', largeur: 150 },
            { nom: 'strdsc', largeur: 200 },
            { nom: 'lemcod', largeur: 150 },
            { nom: 'lemdsc', largeur: 270 },
            { nom: 'aftcod', largeur: 150 },
            { nom: 'aftdsc', largeur: 250 }
        ],
        colonnesFixesDroite: [
            { nom: 'reoflggen', largeur: 120 },
            { nom: 'reoflgree', largeur: 120 }
        ]
    };
}
