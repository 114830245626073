import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateService} from "angular-ui-router";
import {IFilterExDate} from "../../../../../core/filters/ex-date.filter";

interface IPageIF0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence
    valeursEcran: any;
}

interface IPageIf0003Params {
    doccleint: number;
}

/* @ngInject */
export default function If0003Controller(if0004Route: IRoute,
                                         if0005Route: IRoute,
                                         if0003Route: IRoute,
                                         $state: IStateService,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         exDateFilter: IFilterExDate,
                                         $stateParams: IPageIf0003Params) {

    const vm: IPageIF0003 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    let actionsSelection;
    if (!$stateParams.doccleint) {
        actionsSelection = new Menu([
            new MenuItemSelection('LBL_BTN_CALCUL', 'BOUCAL', {
                selection: {
                    nom: 'calcul',
                    lblConfirm: 'G_LBL_BTN_OK',
                    lblConfirmationTitre: 'G_LBL_CALCUL',
                    largeurFormulaire: 20,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('peccleint', {
                            default: () => {
                                return vm.valeursEcran.peccleint;
                            },
                            largeur: 75
                        })
                    ]),
                    icon: 'sync'
                }
            }),
            new MenuItemSelection(
                'LBL_BTN_DISPOSER',
                'BOUDIS',
                {
                    selection: {
                        nom: 'disposer',
                        lblConfirm: 'G_LBL_BTN_OK',
                        lblConfirmationTitre: 'LBL_DISPOSER',
                        largeurFormulaire: 50,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('vecdistypdiscri'),
                            new FormulaireItem('disdatcri'),
                            new FormulaireItem('dismntcri'),
                            new FormulaireItem('flgexcdis')
                        ], {largeurDefaut: 50}),
                        icon: 'archive'
                    },
                    successAction: (data) => {
                        if ( data.flgexcdis )
                        {
                            $state.go(if0003Route.NAME);
                        }
                        else {
                            $state.go(if0005Route.NAME,
                                {
                                    vecdistypdis: data.vecdistypdiscri,
                                    disdat: exDateFilter(data.disdatcri)
                                });
                        }
                    }
                },
            ),
            new MenuItemSelection(
                'LBL_BTN_RENVERSER',
                'BOUREN',
                {
                    selection: {
                        nom: 'renverser',
                        lblConfirm: 'G_LBL_BTN_OK',
                        lblConfirmationTitre: 'LBL_BTN_RENVERSER',
                        largeurFormulaire: 30,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('t_datren', { required: true })
                        ], {largeurDefaut: 50}),
                        icon: 'unarchive'
                    },
                    successAction: () => { vm.multiOccurrence.fetchDataList(); }
                }
            )
        ]);
    }

    vm.multiOccurrenceOptions = {
        navigatePage: if0004Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(if0004Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsSelection,
        colonnesVisibles: [
            {nom: 'immcod', largeur: 100},
            {nom: 'immdsc', largeur: 250},
            {nom: 'vaeimmstu', largeur: 150},
            {nom: 'catcod', largeur: 100},
            {nom: 'catdsc', largeur: 250},
            {nom: 'peramo', largeur: 100},
            {nom: 'immdatacq', largeur: 140},
            {nom: 'immdatser', largeur: 140},
            {nom: 'iopcod', largeur: 140},
            {nom: 'iopdscabr', largeur: 250},
            {nom: 'cafcod', largeur: 100},
            {nom: 'cafdscpro', largeur: 200},
            {nom: 'cafdscfed', largeur: 200},
            {nom: 'vecimmtypimm', largeur: 140},
            {nom: 'vecimmtypimm_dsc', largeur: 250},
            {nom: 'vaeimmprv', largeur: 200},
            {nom: 'vecimmtypacq', largeur: 125},
            {nom: 'vecimmtypacq_desc', largeur: 250},
            {nom: 'vecimmloc', largeur: 120},
            {nom: 'vecimmloc_desc', largeur: 250},
            {nom: 'immvalres', largeur: 200},
            {nom: 'immnbrperamo', largeur: 125},
            {nom: 'immnumeti', largeur: 200},
            {nom: 'immqtetot', largeur: 200},
            {nom: 'unmcod', largeur: 125},
            {nom: 'unmdsc', largeur: 250},
            {nom: 'immcoutot', largeur: 130}
        ],
        colonnesDisponibles: [
            {nom: 'mntamo', largeur: 150},
            {nom: 'mntama', largeur: 150},
            {nom: 'mntamotot', largeur: 150},
            {nom: 'mntcouini', largeur: 150},
            {nom: 'mntcouina', largeur: 150},
            {nom: 'mntcouind', largeur: 150},
            {nom: 'nbr_per', largeur: 150}
        ]
    };
}
