import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence} from "../../../../../core/services/mono-occurrence.service";
import {IStateParamsService} from 'angular-ui-router';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCP0074 extends IComponentController {
    formulaireScb: IFormulaire;
    monoOccurrence: IMonoOccurrence;
}

/* @ngInject */
export default function Cp0074Controller($stateParams: IStateParamsService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCP0074 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireScb = new Formulaire('LBL_SCB', [
            new FormulaireItem('refcleint',{default: $stateParams.refcleint,hidden:true}),
            new FormulaireItem('vaesysdevcod', {default: $stateParams.vaesysdevcod}),
            new FormulaireItem('vaesysprocom', {default: $stateParams.vaesysprocom}),
            new FormulaireItem('scbdateff'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('inscleint'),
            new FormulaireItem('succleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('scbfol'),
        ], {
            largeurDefaut: 50
        });
    }
}
