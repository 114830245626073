import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPagePD0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0014Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePD0014 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('loccleint', {
                required: false
            }),
            new FormulaireItem('pmicleint', {
                required: false
            }),
            new FormulaireItem('raicleint', {
                required: false
            }),
            new FormulaireItem('phinumdoc', {
                required: false
            }),
            new FormulaireItem('pmicleinttra', {
                required: true
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'phinumdoc', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'raicod', largeur: 200},
            {nom: 'raidsc', largeur: 200},
            {nom: 'pmicod', largeur: 200},
            {nom: 'pmidsc', largeur: 200},
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'loccod', largeur: 200},
            {nom: 'locdsc', largeur: 200},
            {nom: 'phiqteactmax', largeur: 200},
            {nom: 'phiqteactree', largeur: 200}
        ]
    };
}
