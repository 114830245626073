import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRR0025 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0025Controller(rr0016Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0025 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0016Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                eadcleint: rowData.eadcleint,
                eplcleint: rowData.eplcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0016Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 130},
            {nom: 'eplnomprnusu', largeur: 250},
            {nom: 'tadcod', largeur: 200},
            {nom: 'taddsc', largeur: 200},
            {nom: 'eadadr1', largeur: 200},
            {nom: 'munnomfra', largeur: 200},
            {nom: 'etacod', largeur: 200},
            {nom: 'eadcodpos', largeur: 200},
            {nom: 'eaddatvig', largeur: 200},
            {nom: 'eadflgchq', largeur: 200},
            {nom: 'eadflgfinann', largeur: 200},
            {nom: 'eadflgact', largeur: 200}
        ],
        colonnesCachees: [
            'eadcleint',
            'eplcleint'
        ]
    };
}
