import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageRr0527 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0527Controller(
    $stateParams: any) {
    const vm: IPageRr0527 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'reecleint', valeur: $stateParams.reecleint, visible: false }
        ],
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false
        },
        colonnesVisibles: [
            { nom: 'vaereehis_dsc', largeur: 178 },
            { nom: 'reenumref', largeur: 90 },
            { nom: 'usrnomcre', largeur: 120 },
            { nom: 'datcre', largeur: 120 }
        ]
    };
}
