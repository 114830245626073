import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCC0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0021Controller(
    parametresRecherche: IParametresRechercheService,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    cc0020Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageCC0021 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cc0020Route.NAME,
        navigateParams: data => ({ id: data.trncleint }),
        actionsNouveaux: new Menu([
            new MenuItemForage(cc0020Route.NAME)
        ]),
        filtres: parametresRecherche(ecranDetails),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesCachees: [
            'trncleint'
        ],
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 220 }
        ],
        colonnesVisibles: [
            { nom: 'crscod', largeur: 180 },
            { nom: 'crsdsc', largeur: 272 },
            { nom: 'ecldsceta', largeur: 167 },
            { nom: 'rqfdatdeb', largeur: 126 },
            { nom: 'rqfdatfin', largeur: 126 },
            { nom: 'rqfnumdoc', largeur: 167 },
            { nom: 'ttccod', largeur: 140 },
            { nom: 'ttcdsc', largeur: 272 },
            { nom: 'vaerqfori', largeur: 165 },
            { nom: 'tconumdoc', largeur: 180 },
            { nom: 'rqfcom', largeur: 272 },
            { nom: 'trnflgstaann', largeur: 115 },
            { nom: 'trnflgstafer', largeur: 115 }]
    };
}
