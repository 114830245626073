import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';


interface IPageDb0002 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    mttMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mtcMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Db0002Controller(
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageDb0002 = this;

    vm.menuBandeau = new Menu([]);
    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: false
        }
    };
    vm.formulaireEad = new Formulaire('LBL_BLC_Ead', [
        new FormulaireItem('mthcod', { largeur: 20, required: true }),
        new FormulaireItem('mthdsc', { largeur: 40, required: true }),
        new FormulaireItem('mthflgstu', { largeur: 20, default: 1 }),
        new FormulaireItem('mthflgfrsfix', { largeur: 20, default: 1 }),
        new FormulaireItemDivider('LBL_TITRE_DEP'),
        new FormulaireItem('cptcleintdepfrs', { largeur: 50, required: true })
    ]);
    vm.mttMultiOccurrenceOptions = {
        bloc: 'mtt',
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 300 },
            { nom: 'mttflgadm', largeur: 100, default: 1 },
            { nom: 'mttflgded', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tymcleint', { largeur: 48 }),
            new FormulaireItem('mttflgadm', { largeur: 10 }),
            new FormulaireItem('mttflgded', { largeur: 10 })
        ])
    };
    vm.mtcMultiOccurrenceOptions = {
        bloc: 'mtc',
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            { nom: 'cptcod', largeur: 100 },
            { nom: 'cptdscabr', largeur: 307 },
            { nom: 'vaesyscodadmdsc', largeur: 160 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cptcleint', { largeur: 50 }),
            new FormulaireItem('vaesyscodadm', { largeur: 50 })
        ])
    };

}
