import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageMR0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mr0031Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass) {
    const vm: IPageMR0031 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: (data: any) => getForage(data),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_POS',
                'BOUMODPOS',
                {
                    selection: {
                        nom: 'modifier',
                        lblConfirmationTitre: 'LBL_BTN_POS',
                        largeurFormulaire: 30,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('poscleintsup_tmp', {largeur: 100})
                        ])
                    },
                    successAction: () => { vm.multiOccurrence.fetchDataList(); }
                }),
            new MenuItemSelection(
                'LBL_BTN_ROL',
                'BOUMODROL',
                {
                    selection: {
                        nom: 'modifier',
                        lblConfirmationTitre: 'LBL_BTN_ROL',
                        largeurFormulaire: 30,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('rolcleint_tmp', {largeur: 100})
                        ])
                    },
                    successAction: () => { vm.multiOccurrence.fetchDataList(); }
                }),
            new MenuItemSelection(
                'LBL_BTN_UNO',
                'BOUMODUNO',
                {
                    selection: {
                        nom: 'modifier',
                        lblConfirmationTitre: 'LBL_BTN_UNO',
                        largeurFormulaire: 30,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('unocleintrh_tmp', {largeur: 100})
                        ])
                    },
                    successAction: () => { vm.multiOccurrence.fetchDataList(); }
                })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('ttccleintcri', { nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('tapflgerrcri', { default: 'T' })
        ]),
        colonnesCachees: ['msgerreur', 'tytcleint', 'ecrcod', 'vaeceptypapp'],
        colonnesFixesGauche: [
            { nom: 'trnnumdoc', largeur: 150 },
            { nom: 'ttccod', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'posnumdoc', largeur: 100 },
            { nom: 'postit', largeur: 236 },
            { nom: 'rolcod', largeur: 120 },
            { nom: 'roldsc', largeur: 236 },
            { nom: 'unocod', largeur: 150 },
            { nom: 'unodsc', largeur: 256 },
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'nom', largeur: 262 },
            { nom: 'trndat', largeur: 100 },
            { nom: 'ecldsceta', largeur: 140 },
            { nom: 'trndsc', largeur: 445 }
        ],
        colonnesFixesDroite: [
            { nom: 'tapflgerr', largeur: 100 }
        ]
    };

    function getForage(data: any) {
        // Cas spécial pour la séparation de PF0011 (SOFE 1) en PF0011 et PF0029 en SOFE 2.
        let ecranForage = data.ecrcod;

        // Il se pourrait qu'on doive forer vers pf0029 !
        if (ecranForage === 'pf0011' && data.tytcleint !== 59) {
            ecranForage = 'pf0029';
        }

        return `secure.${ecranForage}.ecran`;
    };
}
