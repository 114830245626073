import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageMr0107 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ptdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulairePpa: IFormulaire;
}

/* @ngInject */
export default function Mr0107Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0107 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false,
                suiviModification: false
            }
        };

        vm.formulairePpa = new Formulaire('LBL_BLC_PPA', [
            new FormulaireItem('ppacod'),
            new FormulaireItem('ppadatdeb'),
            new FormulaireItem('ppadatfin')
        ], {largeurDefaut: 33});

        vm.ptdMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'cifcod'          , largeur: 130},
                {nom: 'cifdsc'          , largeur: 250},
                {nom: 'vaeppatecdem_dsc', largeur: 230},
                {nom: 'ptdretnbrper'    , largeur: 180},
                {nom: 'ptdorgmoi'       , largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cifcleint'),
                new FormulaireItem('vaeppatecdem'),
                new FormulaireItem('ptdretnbrper'),
                new FormulaireItem('ptdorgmoi')
            ])
        };
    }

}
