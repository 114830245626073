import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0060 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0060Controller(Menu: IMenuClass,
                                         gs0061Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0060 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0061Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0061Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'ecrcod', largeur: 140},
            {nom: 'ecrdsc', largeur: 300}         ]
    };
}
