import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0097 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0097Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0097 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'lolnumloc', largeur: 145},
            {nom: 'loldsc', largeur: 315},
            {nom: 'veclolcatloc', largeur: 130},
            {nom: 'vecdsc', largeur: 210},
            {nom: 'loldatdeb', largeur: 110},
            {nom: 'loldatfin', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('lolnumloc', {largeur: 20}),
            new FormulaireItem('loldsc', {largeur: 20}),
            new FormulaireItem('veclolcatloc', {largeur: 20}),
            new FormulaireItem('loldatdeb', {largeur: 20}),
            new FormulaireItem('loldatfin', {largeur: 20})
        ])
    };
}
