import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPagePc0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pc0015Controller() {
    const vm: IPagePc0015 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'credsc', largeur: 110 },
            { nom: 'crelovsof', largeur: 240 },
            { nom: 'cretitsof', largeur: 240 },
            { nom: 'crelovcrq', largeur: 240 },
            { nom: 'cretitcrq', largeur: 240 }
        ]
    };
  
}

