import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGL0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0017Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGL0017 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccoddeb', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('peccodfin', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cptcleint', {
                required: false
            }),
            new FormulaireItem('cifcleint', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'EMP'
                },
                required: false
            }),
            new FormulaireItem('orgcleint', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint', {
                required: false
            })
        ]),
        colonnesVisibles: [
            { nom: 'peccod', largeur: 80 },
            { nom: 'cptcod', largeur: 105 },
            { nom: 'cptdscabr', largeur: 262 },
            { nom: 'docrefcod02', largeur: 105 },
            { nom: 'docrefnom02', largeur: 262 },
            { nom: 'tydcod', largeur: 80 },
            { nom: 'document', largeur: 140 },
            { nom: 'docaffdat01', largeur: 100 },
            { nom: 'docaffdsc01', largeur: 262 },
            { nom: 'montant', largeur: 126 }
        ]
    };
}
