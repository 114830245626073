import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePI0036 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0036Controller(Menu: IMenuClass) {
    const vm: IPagePI0036 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'dacnumdoc', largeur: 200},
            {nom: 'dacnumdocseq', largeur: 200},
            {nom: 'sysboncmd', largeur: 200},
            {nom: 'dacseqave', largeur: 200},
            {nom: 'pipnumdoc', largeur: 200},
            {nom: 'pipnumref', largeur: 200},
            {nom: 'pplnumdoc', largeur: 200},
            {nom: 'dacsou', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'dacdsc', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnom', largeur: 200},
            {nom: 'etddsc', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'dacmnt', largeur: 200},
            {nom: 'caacod', largeur: 200},
            {nom: 'caadsc', largeur: 200},
            {nom: 'etcnumdoc', largeur: 200},
            {nom: 'etcnumdocseq', largeur: 200},
            {nom: 'etcdsc', largeur: 200},
            {nom: 'intcodach', largeur: 200},
            {nom: 'intnomach', largeur: 200},
            {nom: 'intcoddem', largeur: 200},
            {nom: 'intnomdem', largeur: 200},
            {nom: 'intcodreq', largeur: 200},
            {nom: 'intnomreq', largeur: 200},
            {nom: 'dacrefint', largeur: 200},
            {nom: 'vecdaccat', largeur: 200},
            {nom: 'lipcod', largeur: 200},
            {nom: 'usrcod', largeur: 200},
            {nom: 'motcod', largeur: 200},
            {nom: 'motdsc', largeur: 200},
            {nom: 'nibcod', largeur: 200},
            {nom: 'nibnom', largeur: 200}
        ]
    };
}
