import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0249 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePdtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0249Controller(Menu: IMenuClass,
                                         $stateParams: any,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0249 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'bqpcod', largeur: 115},
            {nom: 'bqpdsc', largeur: 383}
        ],
        colonnesVisibles: [
            {nom: 'bqecod001', largeur: 100},
            {nom: 'bqedsc001', largeur: 215},
            {nom: 'bqecod002', largeur: 100},
            {nom: 'bqedsc002', largeur: 215},
            {nom: 'bqecod003', largeur: 100},
            {nom: 'bqedsc003', largeur: 215},
            {nom: 'bqecod004', largeur: 100},
            {nom: 'bqedsc004', largeur: 215},
            {nom: 'bqecod005', largeur: 100},
            {nom: 'bqedsc005', largeur: 215},
            {nom: 'bqecod006', largeur: 100},
            {nom: 'bqedsc006', largeur: 215},
            {nom: 'bqecod007', largeur: 100},
            {nom: 'bqedsc007', largeur: 215},
            {nom: 'bqecod008', largeur: 100},
            {nom: 'bqedsc008', largeur: 215},
            {nom: 'bqecod009', largeur: 100},
            {nom: 'bqedsc009', largeur: 215},
            {nom: 'bqecod010', largeur: 100},
            {nom: 'bqedsc010', largeur: 215},
            {nom: 'rolcodsow', largeur: 105},
            {nom: 'roldscabrsow', largeur: 246},
            {nom: 'bqpflgaffsow', largeur: 82},
            {nom: 'bqpflgdefsow', largeur: 74}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bqpcod', {largeur: 25}),
            new FormulaireItem('bqpdsc', {largeur: 75}),
            new FormulaireItem('bqecleint001', {largeur: 25}),
            new FormulaireItem('bqedsc001', {largeur: 25}),
            new FormulaireItem('bqecleint002', {largeur: 25}),
            new FormulaireItem('bqedsc002', {largeur: 25}),
            new FormulaireItem('bqecleint003', {largeur: 25}),
            new FormulaireItem('bqedsc003', {largeur: 25}),
            new FormulaireItem('bqecleint004', {largeur: 25}),
            new FormulaireItem('bqedsc004', {largeur: 25}),
            new FormulaireItem('bqecleint005', {largeur: 25}),
            new FormulaireItem('bqedsc005', {largeur: 25}),
            new FormulaireItem('bqecleint006', {largeur: 25}),
            new FormulaireItem('bqedsc006', {largeur: 25}),
            new FormulaireItem('bqecleint007', {largeur: 25}),
            new FormulaireItem('bqedsc007', {largeur: 25}),
            new FormulaireItem('bqecleint008', {largeur: 25}),
            new FormulaireItem('bqedsc008', {largeur: 25}),
            new FormulaireItem('bqecleint009', {largeur: 25}),
            new FormulaireItem('bqedsc009', {largeur: 25}),
            new FormulaireItem('bqecleint010', {largeur: 25}),
            new FormulaireItem('bqedsc010', {largeur: 25}),
            new FormulaireItem('rolcleintsow', {largeur: 20}),
            new FormulaireItem('bqpflgaffsow', {largeur: 20}),
            new FormulaireItem('bqpflgdefsow', {largeur: 20}),

        ]),
    };
}
