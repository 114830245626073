import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMI0507 extends IComponentController {
    valeursEcran: any;
    formulaireFdi: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    mihMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0507Params {
    cleintref: number;
    stamnetab: string;
    tyrcleint: string;
}

/* @ngInject */
export default function Mi0507Controller($stateParams: IMr0507Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMI0507 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        const filtres = [];

        if ($stateParams.tyrcleint) {
            filtres.push({
                colonne: 'tyrcleint',
                valeur: $stateParams.tyrcleint,
                visible: false
            });
        }

        //Référence
        vm.mihMultiOccurrenceOptions = {
            filtres,
            colonnesVisibles: [
                {nom: 'tyrcod'   , largeur: 105},
                {nom: 'tyrdsc'   , largeur: 328},
                {nom: 'mihnumref', largeur: 170},
                {nom: 'mihdsc'   , largeur: 328}
            ],
            formulaire: new Formulaire('LBL_BLC_MIH', [
                new FormulaireItem('tyrcleint', { default: () => $stateParams.tyrcleint }),
                new FormulaireItem('vaesysmnetab', { default: $stateParams.stamnetab }),
                new FormulaireItem('mihnumref'),
                new FormulaireItem('mihnumref_lov', { default: (data: any) => data.mihnumref }),
                new FormulaireItem('mihdsc'),
                new FormulaireItem('mihnumref_lov__mihnumref', { default: (data: any) => data.mihnumref }),
                new FormulaireItem('mihnumref_lov__mihdsc', { default: (data: any) => data.mihdsc }),
            ], {largeurDefaut: 33.33})
        };
    }
}
