import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCP2008 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    retMultiOccurrenceOptions: IMultiOccurrenceOptions;
    retMultiOccurrence: IMultiOccurrence;
    rejMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rejMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Cp2008Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass
) {

    const vm: IPageCP2008 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            exportation: false
        },
        formulaire: new Formulaire('LBL_BLC_LOT', [
            new FormulaireItem('lotcod'),
            new FormulaireItem('lotdsc'),
            new FormulaireItem('refcod', { hidden: (data: any) => !data.refcleint }),
            new FormulaireItem('refnomabr', { hidden: (data: any) => !data.refcleint }),
            new FormulaireItem('mopcod', { hidden: (data: any) => !data.mopcleint }),
            new FormulaireItem('mopdsc', { hidden: (data: any) => !data.mopcleint })
        ], { largeurDefaut: 25 })
    };

    vm.retMultiOccurrenceOptions = {
        bloc: 'ret',
        fonctions: {
            edition: () => (vm.monoOccurrence.data.vaeceptypmod !== 'AUC')
        },
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BOUTON_REJETER',
                'BOUREJ',
                {
                    selection: {
                        nom: 'rejeter'
                    },
                    successAction: () => {
                        vm.retMultiOccurrence.fetchDataList();
                        vm.rejMultiOccurrence.fetchDataList();
                    },
                    hidden: () => (vm.monoOccurrence.data.vaeceptypmod === 'AUC')
                }
            ),
            new MenuItemSelection(
                'LBL_BOUTON_COMPENSATION',
                'BOUCOM',
                {
                    selection: {
                        nom: 'compensation'
                    },
                    successAction: () => {
                        vm.retMultiOccurrence.fetchDataList();
                        vm.rejMultiOccurrence.fetchDataList();
                    },
                    hidden: () => (!vm.monoOccurrence.data.affcmpblo || vm.monoOccurrence.data.vaeceptypmod === 'AUC')
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'refnomabr', largeur: 230 },
            { nom: 'radcod', largeur: 80 }
        ],
        colonnesVisibles: [
            { nom: 'mopcod', largeur: 100 },
            { nom: 'tydcod', largeur: 80 },
            { nom: 'docaffnumdoc01', largeur: 120 },
            { nom: 'paynumref', largeur: 150 }
        ],
        colonnesFixesDroite: [
            { nom: 'paydatdue', largeur: 126 },
            { nom: 'paymnt', largeur: 120, total: true },
            { nom: 'paymntesc', largeur: 120, total: true },
            { nom: 'payinduni', largeur: 100 }
        ],
        colonnesDisponibles: [
            { nom: 'empcod', largeur: 100 },
            { nom: 'empdsc', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('radcleint'),
            new FormulaireItem('mopcleint'),
            new FormulaireItem('empcleintdep'),
            new FormulaireItem('paymnt'),
            new FormulaireItem('paymntesc'),
            new FormulaireItem('payinduni')
        ], { largeurDefaut: 33.33 })
    };

    vm.rejMultiOccurrenceOptions = {
        bloc: 'rej',
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BOUTON_RETENIR',
                'BOURET',
                {
                    selection: {
                        nom: 'retenir'
                    },
                    successAction: () => {
                        vm.retMultiOccurrence.fetchDataList();
                        vm.rejMultiOccurrence.fetchDataList();
                    },
                    hidden: () => (vm.monoOccurrence.data.vaeceptypmod === 'AUC')
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'refnomabr', largeur: 230 },
            { nom: 'radcod', largeur: 80 }
        ],
        colonnesVisibles: [
            { nom: 'mopcod', largeur: 100 },
            { nom: 'tydcod', largeur: 80 },
            { nom: 'docaffnumdoc01', largeur: 120 },
            { nom: 'paynumref', largeur: 150 }
        ],
        colonnesDisponibles: [
            { nom: 'empcod', largeur: 100 },
            { nom: 'empdsc', largeur: 150 }
        ],
        colonnesFixesDroite: [
            { nom: 'paydatdue', largeur: 126 },
            { nom: 'paymnt', largeur: 120, total: true },
            { nom: 'paymntesc', largeur: 120, total: true },
            { nom: 'payindretpai', largeur: 100 },
            { nom: 'payflgmsg', largeur: 100 }
        ],
        colonnesCachees: [
            'paymsg'
        ]
    };
}
