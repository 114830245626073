import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";

interface IPageAC0060 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0060Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass
) {
    const vm: IPageAC0060 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true,
            effaceCriteres: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcleintcri'),
            new FormulaireItem("grpcleintcri"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("prucleintdebcri"),
            new FormulaireItem("prucleintfincri")
        ]),
        colonnesVisibles: [
            { nom: "dacnumdoc", largeur: 100 },
            { nom: "dacnumdocseq", largeur: 100 },
            { nom: "sysboncmd", largeur: 120 },
            { nom: "dacseqave", largeur: 80 },
            { nom: "dacsou", largeur: 120 },
            { nom: "tydcod", largeur: 100 },
            { nom: "dacdsc", largeur: 500 },
            { nom: "refcod", largeur: 130 },
            { nom: "refnom", largeur: 200 },
            { nom: "founomabr", largeur: 200 },
            { nom: "etddsc", largeur: 100 },

            { nom: "flgcou", largeur: 100 },
            { nom: "flgnonrec", largeur: 100 },
            { nom: "flgrecpar", largeur: 120 },
            { nom: "flgfca", largeur: 100 },
            { nom: "peccod", largeur: 100 },
            { nom: "vaesysdevcod", largeur: 100 },
            { nom: "dacmnt", largeur: 100 },
            { nom: 'caacleint', largeur: 120},  // Référence interne à vérifier
            { nom: "caacod", largeur: 140 },
            { nom: "caadsc", largeur: 200 },

            { nom: "etcnumdoc", largeur: 200 },
            { nom: "etcnumdocseq", largeur: 100 },
            { nom: "etcdsc", largeur: 200 },
            { nom: "intcodach", largeur: 100 },
            { nom: "intnomach", largeur: 200 },
            { nom: "intcoddem", largeur: 100 },
            { nom: "intnomdem", largeur: 200 },
            { nom: "intcodreq", largeur: 100 },
            { nom: "intnomreq", largeur: 200 },
            { nom: "vecdaccat", largeur: 100 },

            { nom: "lipcod", largeur: 100 },
            { nom: "flgerr_b2b", largeur: 100 },
            { nom: "usrcod", largeur: 100 },
            { nom: "motcod", largeur: 130 },
            { nom: "motdsc", largeur: 200 },
            { nom: "flgtrans", largeur: 100 },
            { nom: "flgimp", largeur: 100 },
            { nom: "dacdat", largeur: 100 },
            { nom: "dacdatreq", largeur: 100 },
            { nom: "dateofficiel", largeur: 100 },

            { nom: "dacdatree", largeur: 130 },
            { nom: "dacdatdeb", largeur: 140 },
            { nom: "dacdatfin", largeur: 100 },
            { nom: "nibcod", largeur: 100 },
            { nom: "nibnom", largeur: 200 }
        ]
    };
}
