import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";

interface IPageCc0003 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    usageimp: IMenuItem;
}

/* @ngInject */
export default function Cc0003Controller(
    mr0176Route: IRoute,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCc0003 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_COF', [
            new FormulaireItem('crscleint', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('trmcleint', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cofgrp', { largeur: 50 }),
            new FormulaireItem('cofflgact', { default: 1, largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cofdscinfadd', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cofdsc', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unocleint', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('litcleint', { largeur: 75 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cofnbrcre'),
            new FormulaireItem('cofnbretu', { hidden: () => vm.valeursEcran.flgccoff === 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cofnbrhre'),
            new FormulaireItem('cofnbretumax'),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_ATT', new Formulaire([
                new FormulaireItem('cofflgatrprf'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('demcleint')
            ], { largeurDefaut: 50 })),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_HOR', new Formulaire([
                new FormulaireItem('cofdatdeb'),
                new FormulaireItem('vaecofplg'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cofdatfin'),
                new FormulaireItem('cofhredeb'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaecofjrssem'),
                new FormulaireItem('cofhrefin')
            ], { largeurDefaut: 50 }), { hidden: () => vm.valeursEcran.flgccoff === 1 }),
        ], { largeurDefaut: 33.33 })
    };

    // Usage d'imputation
    vm.usageimp = new MenuItemEcran(
        'LBL_USAGE_IMPUTATION',
        mr0176Route.NAME,
        () => ({
            stamnetab: 'COF',
            ovdcleintref: vm.monoOccurrence.id
        }),
        {
            icon: 'view_list',
            iconButton: false
        }
    );


}
