import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMc2011 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    gusMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ipgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gtrMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc2011Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2011 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_GRU', [
            new FormulaireItem('grucod'),
            new FormulaireItem('grudsc', {largeur: 50}),
            new FormulaireItem('gruflgclo', {readonly: true, hidden: (data:any) => !data.$id})
        ], {largeurDefaut: 25})
    };

    // Usager
    vm.gusMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'usrcod', largeur: 130},
            {nom: 'usrnom', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('usrcleint', {largeur: 100})
        ])
    };

    // Intervenant-profils
    vm.ipgMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'cienom', largeur: 200},
            {nom: 'iprcod', largeur: 120},
            {nom: 'iprdsc', largeur: 200},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 200}
        ]
    }

    // Traitement en lot
    vm.gtrMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'trlcod', largeur: 100},
            {nom: 'trldsc', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('trlcleint', {largeur: 100}),
        ])
    };
}
