import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageIC0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ic0001Controller(Menu: IMenuClass) {
    const vm: IPageIC0001 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'icinumdoc', largeur: 200},
            {nom: 'icidscabr', largeur: 200},
            {nom: 'ttccod', largeur: 200},
            {nom: 'ecldsceta', largeur: 200},
            {nom: 'iciann', largeur: 200},
            {nom: 'vecprsreg', largeur: 200},
            {nom: 'icidatinc', largeur: 200},
            {nom: 'connumdoc', largeur: 200},
            {nom: 'coaann', largeur: 200},
            {nom: 'immcod', largeur: 200},
            {nom: 'immdsc', largeur: 200},
            {nom: 'prucod', largeur: 200},
            {nom: 'prudscl01', largeur: 200},
            {nom: 'prscod', largeur: 200},
            {nom: 'icicom', largeur: 200},
            {nom: 'empcodrsp', largeur: 200},
            {nom: 'empnomrsp', largeur: 200},
            {nom: 'accnumdoc', largeur: 200},
            {nom: 'accdsc', largeur: 200},
            {nom: 'vectypcar', largeur: 200},
            {nom: 'vecmareta', largeur: 200},
            {nom: 'veccauinc', largeur: 200},
            {nom: 'lipcod', largeur: 200},
            {nom: 'lipnomabr', largeur: 200},
            {nom: 'icimntest', largeur: 200},
            {nom: 'icimntded', largeur: 200},
            {nom: 'icimntrfu', largeur: 200},
            {nom: 'cumdep', largeur: 200},
            {nom: 'cumrev', largeur: 200},
            {nom: 'sldrec', largeur: 200},
            {nom: 'icircl', largeur: 200}
        ]
    };
}
