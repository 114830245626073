import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageMr2020 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurence: IMonoOccurrence;
    mdpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr2020Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass
) {
    const vm: IPageMr2020 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire( 'LBL_BLC_CEP', [
            new FormulaireItem('vaeceptypmod')
        ])
    };

    vm.mdpMultiOccurrenceOptions = {
        bloc: 'mdp',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 50,
            nbElementsPossibles: [50, 100, 200]
        }),
        colonnesVisibles: [
            { nom: 'ecrcod', largeur: 130 },
            { nom: 'ecrdsc', largeur: 250 },
            { nom: 'chcdsc', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ecrcleint'),
            new FormulaireItem('chccleint', {
                nombreLibellesDetailsCacher: 1
            })
        ])
    };
}
