import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IController } from 'angular';
interface IPageCr0008 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0008Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    mc0046Route: IRoute) {
    const vm: IPageCr0008 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'sercod', largeur: 80 },
            { nom: 'serdscabr', largeur: 225 },
            { nom: 'serdsc', largeur: 358 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('sercod', { required: true, largeur: 12 }),
            new FormulaireItem('serdscabr', {
                required: true, largeur: 25,
                onBlur: (data: any) => { data.serdsc = data.serdsc && data.serdsc !== '' ? data.serdsc : data.serdscabr }
            }),
            new FormulaireItem('serdsc', { required: true, largeur: 50 })
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => ({
                    vaeusient: 'SER',                    
                    uvicleintref: data.sercleint
                }),
                {
                    icon: 'view_list',
                    fonction: 'BOUIMP',
                    iconButton: false
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
    };
}
