import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'db2005',
    ecrans: ['00-2'],
    params: [
        '?peccodcleint_de',
        '?peccodcleint_a',
        '?intcodnumcri',
        '?sfucleint',
        '?prucleint',
        '?prscleint',
        '?cifcleint',
        '?cptcleint',
        '?unacleint',
        '?el1cleint',
        '?el2cleint',
        '?el3cleint',
        '?grpcleint',
        '?soncleint',
        '?orgcleint',
        '?blocleint',
        '?prudatfin_de',
        '?prudatfin_a',
        '?unacleintsup',
        '?naturecri',
        '?det'
        //'?blocleint'
        ]
});
