import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageCO0016 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    corMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0016Controller(co0009Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCO0016 = this;

    vm.corMultiOccurrenceOptions = {
        bloc: 'cor',
        actionsNouveaux: new Menu([
            new MenuItemEcran(
                'G_LBL_COMREQ',
                co0009Route.NAME,
                () => ({
                    stamnetab: 'URG',
                    corcleintref: vm.multiOccurrence.getDataActiveRow().urgcleint
                }),
                {
                    onClose() {
                        vm.multiOccurrence.details.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'G_LBL_COMREQ',
                co0009Route.NAME,
                (data: any) => ({
                    id: data.corcleint,
                    stamnetab: 'URG',
                    corcleintref: data.urgcleint
                }),
                {
                    icon: 'launch',
                    iconButton: true
                }
            )
        ]),
        actionsMore: new Menu([
            new MenuItemRecuperation({
                nom: 'recuperation_cor',
                criteresSuggeres: new Formulaire([
                    new FormulaireItem('offcleint_cor'),
                    new FormulaireItem('poscleint_cor'),
                    new FormulaireItem('temcleint_cor'),
                    new FormulaireItem('urgcleint_cor')
                ]),
                colonnesVisibles: [
                    {nom: 'grcdsc', largeur: 300},
                    {nom: 'corseqaff', largeur: 110},
                    {nom: 'comcod', largeur: 115},
                    {nom: 'comdsc', largeur: 300},
                    {nom: 'corflgpae', largeur: 91}
                ]
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'grccod', largeur: 150},
            {nom: 'grcdsc', largeur: 274}
        ],
        colonnesVisibles: [
            {nom: 'corseqaff'   , largeur: 100},
            {nom: 'comcod'      , largeur: 120},
            {nom: 'comdsc'      , largeur: 303},
            {nom: 'vacvalcle'   , largeur: 120},
            {nom: 'vacdsc'      , largeur: 135},
            {nom: 'corfacimp'   , largeur: 110},
            {nom: 'nivcod'      , largeur: 80},
            {nom: 'nivdsc'      , largeur: 236},
            {nom: 'corvalcib'   , largeur: 110},
            {nom: 'corcom'      , largeur: 315},
            {nom: 'cordscweb'   , largeur: 315},
            {nom: 'corflgaffweb', largeur: 74},
            {nom: 'corflgidecan', largeur: 88},
            {nom: 'corflgpae'   , largeur: 74}
        ],
        colonnesCachees: ['corcleint', 'urgcleint']
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'cvtcod', largeur: 105},
            {nom: 'cvtdsc', largeur: 304},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 262}
        ],
        multiOccurrenceOptionsEnfant: vm.corMultiOccurrenceOptions
    };
}
