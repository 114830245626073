import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMi0503 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mi0503Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMi0503 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'gimdsc', largeur: 274},
            {nom: 'vaegimimpsen_dsc', largeur: 100},
            {nom: 'gimflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gimdsc'   , { required: true, largeur: 50}),
            new FormulaireItem('vaegimimpsen'   , { required: true, largeur: 25}),
            new FormulaireItem('gimflgact', { default: 1, largeur: 25})
        ])
    };
}


