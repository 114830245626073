import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageIf0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function If0019Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Operateur: IOperateurService) {
    const vm: IPageIf0019 = this;

    vm.multiOccurrenceOptions = {
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_VALIDER',
                'BOUVAL',
                {
                    selection: { nom: 'valider' }
                }),
            new MenuItemSelection(
                'LBL_BTN_TRAITER',
                'BOUTRA',
                {
                    selection: {
                        nom: 'traiter',
                        lblConfirm: 'G_LBL_EXECUTER',
                        lblConfirmationTitre: 'LBL_BTN_TRAITER',
                        largeurFormulaire: 50,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('peccleint', { required: true }),
                            new FormulaireItem('cptcleinttra', { required: true }),
                            new FormulaireItem('intcleintreq', { required: true })
                        ], { largeurDefaut: 33 }),
                        icon: 'sync'
                    }
                }),
            new MenuItemSelection(
                'LBL_BTN_SUPPRIME',
                'BOUSUP',
                {
                    selection: { nom: 'supprimer' }
                })
        ]),
        filtres: [
            {
                colonne: 'imiflgtra',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            }
        ],
        colonnesFixesGauche: [
            { nom: 'immcod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'immdsc', largeur: 250 },
            { nom: 'immdsclng', largeur: 300 },
            { nom: 'catcod', largeur: 150 },
            { nom: 'immnbrperamo', largeur: 160 },
            { nom: 'cafcod', largeur: 150 },
            { nom: 'vecimmtypimm', largeur: 140 },
            { nom: 'vecimmtypacq', largeur: 150 },
            { nom: 'vecimmloc', largeur: 120 },
            { nom: 'iopcod', largeur: 120 },
            { nom: 'vaeimmstu', largeur: 120 },
            { nom: 'immdatacq', largeur: 120 },
            { nom: 'immdatser', largeur: 120 },
            { nom: 'immvalres', largeur: 150 },
            { nom: 'imicou', largeur: 150 },
            { nom: 'imiamo', largeur: 150 },
            { nom: 'imiseqrep', largeur: 100 },
            { nom: 'prucod', largeur: 120 },
            { nom: 'prscod', largeur: 120 },
            { nom: 'cifcod', largeur: 120 },
            { nom: 'unacod', largeur: 120 },
            { nom: 'el1cod', largeur: 120 },
            { nom: 'el2cod', largeur: 120 },
            { nom: 'el3cod', largeur: 120 },
            { nom: 'imiprc', largeur: 130 },
            { nom: 'immannfab', largeur: 150 },
            { nom: 'immmqe', largeur: 150 },
            { nom: 'immmdl', largeur: 150 },
            { nom: 'immnumser', largeur: 150 },
            { nom: 'immnumeti', largeur: 150 },
            { nom: 'immcappds', largeur: 150 },
            { nom: 'immdte', largeur: 150 },
            { nom: 'immcom', largeur: 300 },
            { nom: 'iminot', largeur: 300 }
        ],
        colonnesFixesDroite: [
            { nom: 'imiflgerr', largeur: 100 },
            { nom: 'imiflgval', largeur: 100 },
            { nom: 'imiflgtra', largeur: 100 }
        ],
        colonnesCachees: [
            'imimsg'
        ],
        fonctions: {
            importation: true
        },
        formulaire: new Formulaire([
            new FormulaireItem('immcod'),
            new FormulaireItem('immdsc'),
            new FormulaireItem('immdsclng'),
            new FormulaireItem('catcod'),
            new FormulaireItem('immnbrperamo'),
            new FormulaireItem('cafcod'),
            new FormulaireItem('vecimmtypimm'),
            new FormulaireItem('vecimmtypacq'),
            new FormulaireItem('vecimmloc'),
            new FormulaireItem('iopcod'),
            new FormulaireItem('vaeimmstu'),
            new FormulaireItem('immdatacq'),
            new FormulaireItem('immdatser'),
            new FormulaireItem('immvalres'),
            new FormulaireItem('imicou'),
            new FormulaireItem('imiamo'),
            new FormulaireItemDivider('LBL_DIV_REP'),
            new FormulaireItem('imiseqrep'),
            new FormulaireItem('prucod'),
            new FormulaireItem('prscod'),
            new FormulaireItem('cifcod'),
            new FormulaireItem('unacod'),
            new FormulaireItem('el1cod'),
            new FormulaireItem('el2cod'),
            new FormulaireItem('el3cod'),
            new FormulaireItem('imiprc'),
            new FormulaireItemDivider('LBL_DIV_AUTRE_INFO'),
            new FormulaireItem('immannfab'),
            new FormulaireItem('immmqe'),
            new FormulaireItem('immmdl'),
            new FormulaireItem('immnumser'),
            new FormulaireItem('immnumeti'),
            new FormulaireItem('immcappds'),
            new FormulaireItem('immdte'),
            new FormulaireItem('immcom'),
            new FormulaireItem('iminot')
        ], { largeurDefaut: 33.33 })
    };
}
