import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0122 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0122Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         mr0238Route: IRoute,
                                         $stateParams: any,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0122 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'carcleint', valeur: $stateParams.reference, visible: false}
        ],
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_MR0238',
                mr0238Route.NAME,
                (data:any) => ({vaetypfnd: 'CAR',
                    cleintdet: data.carcleint}),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('carcod',            {largeur: 25}),
            new FormulaireItem('cardsc',            {largeur: 50}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('rcecleint',         {largeur: 37.5}),
            new FormulaireItem('vaecartyparr',      {largeur: 37.5}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('colcleintarr',      {largeur: 37.5}),
            new FormulaireItem('unccleintqtearr',   {largeur: 37.5})            
        ]),

        colonnesFixesGauche: [
            {nom: 'carcod',           largeur: 110},
            {nom: 'cardsc',           largeur: 275}
        ],
        colonnesVisibles: [
            {nom: 'rcecod',           largeur: 110},
            {nom: 'rcedscabr',        largeur: 275},
            {nom: 'vaecartyparr_dsc', largeur: 180},
            {nom: 'unccod',           largeur: 110},
            {nom: 'uncdsc',           largeur: 275},
            {nom: 'colcod',           largeur: 120},
            {nom: 'coldsc',           largeur: 275}
        ]
    };
}
