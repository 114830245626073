import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRR0112 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0112Controller(MenuItemForage: IMenuItemForageClass,
                                         $stateParams: any,
                                         rr0113Route: IRoute,
                                         rr0108Route: IRoute) {

    const vm: IPageRR0112 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtresParametres: [
            {colonne: 'cppcleint', valeur: $stateParams.reference, visible: false}
        ],
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 184},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 152},
            {nom: 'tafcod', largeur: 110},
            {nom: 'tafdsc', largeur: 216},
            {nom: 'flgaft', largeur: 100,
                 menu: [
                     new MenuItemForage(
                         'LBL_FORAGE_AFT',
                         rr0108Route.NAME,
                         (data: any) => ({
                             stamnetab: 'CPP',
                             cpecleintref: data.cppcleint
                         })
                     )
                 ]
            },
            {nom: 'flgprl', largeur: 100,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_PRL',
                        rr0108Route.NAME,
                        (data: any) => ({
                            stamnetab: 'TSP',
                            cpecleintref: data.cppcleint
                        })
                    )
                ]
            },
            {nom: 'flgsig', largeur: 100,
                 menu: [
                     new MenuItemForage(
                         'LBL_FORAGE_SIG',
                          rr0113Route.NAME,
                         (data: any) => ({
                             stamnetab: 'CPP',
                             specleintref: data.cppcleint
                         })
                     )
                 ]
            }
        ]
    };
}
