import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPagePF0033 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0033Controller(pf0034Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {

    const vm: IPagePF0033 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pf0034Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(pf0034Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'xppcod'      , largeur: 160},
            {nom: 'xppdsc'      , largeur: 300},
            {nom: 'xppseqaff'   , largeur: 126},
            {nom: 'rolcod'      , largeur: 160},
            {nom: 'roldscabr'   , largeur: 300}
        ]
    };
}
