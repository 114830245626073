import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAc0001 = this;
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'iaccod', largeur: 50},
            {nom: 'iacdsc', largeur: 185},
            {nom: 'iacflgint', largeur: 70},
            {nom: 'iacflgins', largeur: 70}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('iaccod', {required: true}),
            new FormulaireItem('iacdsc', {required: true}),
            new FormulaireItem('iacflgint'),
            new FormulaireItem('iacflgins')
        ]),
        fonctions:{
            rechercheCollapsable: false
        }
    };
}
