import { IComponentController } from "angular";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";


interface IPageMr0261 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pgaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pgrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mr0261Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass, 
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMr0261 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        id: vm.valeursEcran.pgbcleint,
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_PGB', [
            new FormulaireItem('ciecleint'),
            new FormulaireItem('vaesowdefsld', { largeur: 33 }),
            new FormulaireGroupeAccordeon('LBL_BQE_CONGE', new Formulaire([
                new FormulaireItem('aut__ligne_c1', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c1'),
                new FormulaireItem('pgbbqecleint_c1'),
                new FormulaireItem('pgbbqeaffdec_c1'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c2', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c2', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c2', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c2', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c3', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c3', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c3', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c3', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c4', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c4', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c4', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c4', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c5', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c5', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c5', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c5', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c6', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c6', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c6', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c6', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c7', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c7', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c7', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c7', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c8', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c8', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c8', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c8', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c9', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c9', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c9', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c9', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c10', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c10', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c10', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c10', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c11', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c11', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c11', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c11', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c12', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c12', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c12', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c12', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c13', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c13', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c13', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c13', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c14', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c14', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c14', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c14', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_c15', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_c15', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_c15', { titre: ' ' }),
                new FormulaireItem('pgbbqeaffdec_c15', { titre: ' ' })
            ],{ largeurDefaut: 25 })),
            new FormulaireGroupeAccordeon('LBL_BQE_ANCIEN', new Formulaire([
                new FormulaireItem('aut__ligne_a1', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_a1'),
                new FormulaireItem('pgbbqecleint_a1'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_a2', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_a2', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_a2', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_a3', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_a3', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_a3', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_a4', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_a4', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_a4', { titre: ' ' }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('aut__ligne_a5', { titre: ' ', readonly: true, largeur: 5 }),
                new FormulaireItem('pgbbqelib_a5', { titre: ' ' }),
                new FormulaireItem('pgbbqecleint_a5', { titre: ' ' })
            ],{ largeurDefaut: 40 }))
        ])
    }

    vm.pgaMultiOccurrenceOptions = {
        bloc: 'pga',
        colonnesVisibles: [
            {nom: 'pganumseq', largeur: 100},
            {nom: 'pgataglib', largeur: 180},
            {nom: 'tagcod', largeur: 120 },
            {nom: 'tagdsc', largeur: 180 },
            {nom: 'tagcodreg', largeur: 120 },
            {nom: 'tagdscreg', largeur: 180 },
            {nom: 'pgaaffdec', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pganumseq'),
            new FormulaireItem('pgataglib'),
            new FormulaireItem('tagcleint'),
            new FormulaireItem('tagcleintreg'),
            new FormulaireItem('pgaaffdec')
        ])
    };

    vm.pgrMultiOccurrenceOptions = {
        bloc: 'pgr',
        colonnesVisibles: [
            {nom: 'ecrcod', largeur: 120},
            {nom: 'ecrdscmen', largeur: 200},
            {nom: 'pgrflgrolsup', largeur: 120 },
            {nom: 'rolcod', largeur: 120},
            {nom: 'roldsc', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ecrcleint'),
            new FormulaireItem('pgrflgrolsup'),
            new FormulaireItem('rolcleint')
        ], { largeurDefaut: 33.33 })
    }
}
