import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';

interface IPageDb0052 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceMrvOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0052Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageDb0052 = this;

    vm.multiOccurrenceMrvOptions = {
        bloc: 'mrv',
        fonctions: {
            rechercheCollapsable: false,
            editionRapide: true
        },
        colonnesVisibles: [
            { nom: 'mrvimp', largeur: 25 },
            { nom: 'mrvflgeng', largeur: 25 },
            { nom: 'mrvprc', largeur: 25, total: true }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mrvimp', { required: true, largeur: 35 }),
            new FormulaireItem('mrvflgeng', { largeur: 25 }),
            new FormulaireItem('mrvprc', { largeur: 40 })
        ]),
        colonnesEditionRapide: [
            { nom: 'mrvimp', largeur: 180 },
            { nom: 'mrvflgeng', largeur: 140 },
            { nom: 'mrvprc', largeur: 140 }
        ]
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'mrpcod', largeur: 100 },
            { nom: 'mrpdsc', largeur: 180 },
            { nom: 'peccod', largeur: 120 },
            { nom: 'mrpflgstu', largeur: 100 },
            { nom: 'flgint', largeur: 100 },
            { nom: 'flginteng', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mrpcod', { largeur: 10 }),
            new FormulaireItem('mrpdsc', { largeur: 50 }),
            new FormulaireItem('mrppecvig', { largeur: 20 }),
            new FormulaireItem('mrpflgstu', { largeur: 10 }),
            new FormulaireItem('mrpflgint', { largeur: 30 }),
            new FormulaireItem('mrpflginteng', { largeur: 30 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceMrvOptions
    };
}
