import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageCP0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0004Controller(cp0005Route: IRoute,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCP0004 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cp0005Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.foucleint,
                refcleint: rowData.refcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(cp0005Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('radnomsup', { required: false })

        ]),
        colonnesFixesGauche: [
            { nom: 'foucod', largeur: 110 },
            { nom: 'founom', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'founomabr', largeur: 180 },
            { nom: 'founumneq', largeur: 140 },
            { nom: 'pflcod', largeur: 66 },
            { nom: 'pfldsc', largeur: 186 },
            { nom: 'vaesysdevcod_dsc', largeur: 105 },
            { nom: 'vecsyslng_dsc', largeur: 113 },
            { nom: 'fouflgstu', largeur: 72 },
            { nom: 'foudatdeb', largeur: 100 },
            { nom: 'foudatfin', largeur: 100 },
            { nom: 'fouinarena', largeur: 100 },
            { nom: 'fouinarenaman', largeur: 100 },
            { nom: 'foudatrena', largeur: 110 },
            { nom: 'fouindhisto', largeur: 100 },
            { nom: 'fogcod', largeur: 116 },
            { nom: 'fognom', largeur: 228 },
            { nom: 'fousitint', largeur: 315 },
            { nom: 'vectypapp', largeur: 100 },
            { nom: 'vecdsc', largeur: 210 },
            { nom: 'radnumtel', largeur: 158 }
        ],
        colonnesCachees: [
            'foucleint'
        ]
    };
}
