import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";

interface IPageGs0016 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireTrc: IFormulaire;
    trdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    trtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gspMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0016Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0016 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_COPIER',
                    'BOUCOP',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_BTN_COPIER',
                            lblConfirm: 'G_LBL_BTN_VALIDER',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                    new FormulaireItem('t_trlcleint')
                                ], {largeurDefaut: 100}
                            )
                        }
                    }
                )
            ])
        };

        vm.formulaireTrc = new Formulaire('LBL_BLC_TRC', [
            new FormulaireItem('trlcleint'),
            new FormulaireItem('frmdsc'),
            new FormulaireItem('trcflgpub'),
            new FormulaireItem('trcflgint', {default: 1}),
            new FormulaireItem('fiacleint'),
            new FormulaireItem('trcfintrt'),
            new FormulaireItem('vaeresreq'),
            new FormulaireItem('trcparimp'),
            new FormulaireItem('trcflgenvid', {largeur: 20}),
            new FormulaireItem('trcflgvarprt', {largeur: 20}),
            new FormulaireItem('trcflgfiximp', {largeur: 20}),
            new FormulaireItem('impcleint', {largeur: 25}),
            new FormulaireItem('trcnbrcop', {largeur: 15})
        ], {largeurDefaut: 25});
        vm.trtMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'trtnumpla', largeur: 100},
                {nom: 'trtnompla', largeur: 180}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('trtnumpla'),
                new FormulaireItem('trtnompla', {enableCount: true})
            ], {largeurDefaut: 25})
        };

        vm.trdMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'vaedsttyp_dsc', largeur: 90},
                {nom: 'vaedstfmt_dsc', largeur: 100},
                {nom: 'vaedstlev_dsc', largeur: 110},
                {nom: 'trddstnam', largeur: 220},
                {nom: 'trddstto', largeur: 220},
                {nom: 'trddstfrm', largeur: 220},
                {nom: 'trddstsub', largeur: 220},
                {nom: 'vaedstbod_dsc', largeur: 130},
                {nom: 'trddstbod', largeur: 220}
            ],
            colonnesFixesDroite: [
                {nom: 'trdflgdef', largeur: 75},
                {nom: 'trdflgimp', largeur: 75},
                {nom: 'trdflggrp', largeur: 75},
                {nom: 'trdflgsec', largeur: 75},
                {nom: 'trdflgpij', largeur: 75}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vaedsttyp'),
                new FormulaireItem('vaedstfmt'),
                new FormulaireItem('vaedstlev'),
                new FormulaireItem('trddstnam'),
                new FormulaireItem('trddstto'),
                new FormulaireItem('trddstfrm'),
                new FormulaireItem('trddstsub'),
                new FormulaireItem('vaedstbod'),
                new FormulaireItem('trddstbod'),
                new FormulaireItem('trdflgdef', {largeur: 20}),
                new FormulaireItem('trdflgimp', {largeur: 20}),
                new FormulaireItem('trdflggrp', {largeur: 20}),
                new FormulaireItem('trdflgsec', {largeur: 20}),
                new FormulaireItem('trdflgpij', {largeur: 20})
            ], {largeurDefaut: 33})
        };

        vm.gspMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'papdsc', largeur: 180},
                {nom: 'vaepaptyp_dsc', largeur: 150},
                {nom: 'gspvalcli', largeur: 180},
                {nom: 'papvaldev', largeur: 250},
                {nom: 'papdsclng', largeur: 3000}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gspvalcli')
            ], {largeurDefaut: 50})
        };
    }
}
