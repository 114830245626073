import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCR0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0030Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCR0030 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccleintdeb', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('peccleintfin', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('refcleint', { resourceParams: { type: 'CLI' } }),
            new FormulaireItem('prucleint', {}),
            new FormulaireItem('pdfcodcri', {}),
            new FormulaireItem('prjcleint', {}),
            new FormulaireItem('cptcleint', {})
        ]),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        colonnesVisibles: [
            { nom: 'prjcod', largeur: 105 },
            { nom: 'prjdscabr', largeur: 262 },
            { nom: 'pdfcod', largeur: 105 },
            { nom: 'orgnomabr', largeur: 262 },
            { nom: 'prucod', largeur: 105 },
            { nom: 'prudscl01', largeur: 262 },
            { nom: 'refcod', largeur: 105 },
            { nom: 'refnom', largeur: 262 },
            { nom: 'tydcod', largeur: 65 },
            { nom: 'document', largeur: 196 },
            { nom: 'doc_dat', largeur: 100 },
            { nom: 'doc_desc', largeur: 262 },
            { nom: 'tydcod_enc', largeur: 65 },
            { nom: 'encnumdoc', largeur: 196 },
            { nom: 'encdat', largeur: 100 },
            { nom: 'mnteng', largeur: 126 },
            { nom: 'mntnet', largeur: 126 }
        ]
    };
}
