import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0135',
    ecrans: [
        '00-2',
        '01-2',
        '02-2',
        '03-2'
    ],
    params:[
        '?eplcleint',
        '?cleintref'
    ]
});
