import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0042 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0042Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0042 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaesgctypref_dsc', largeur: 200},
            {nom: 'tydcod'          , largeur: 160},
            {nom: 'tyddsc'          , largeur: 250},
            {nom: 'vaesgccatcpt_dsc', largeur: 200},
            {nom: 'soncod'          , largeur: 130},
            {nom: 'sondsc'          , largeur: 250},
            {nom: 'sgcflgexc'       , largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesgctypref'),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('vaesgccatcpt'),
            new FormulaireItem('soncleint'),
            new FormulaireItem('sgcflgexc'),
        ], {largeurDefaut: 33})
    };
}
