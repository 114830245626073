import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import {
    FormulaireElement,
    IFormulaire,
    IFormulaireClass
} from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForage, IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireGroupeClass } from "../../../../../core/services/formulaire/formulaire-groupe.service";
import { IFormulaireTemplateSlotClass } from "../../../../../core/services/formulaire/formulaire-template-slot.service";
import { IMenuItemForageExterneClass } from "../../../../../core/services/menu/menu-item-forage-externe.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IFormulaireItemTexteClass } from "../../../../../core/services/formulaire/formulaire-item-texte.service";
import { IData } from "../../../../../core/services/data-linker.service";
import { IIsForageValideService } from "../../../../../core/services/is-forage-valide.service";

interface IPageRr0003 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireEpl: IFormulaire;
    menusTsm: { [col: string]: Array<FormulaireElement> };
    menusDia: { [col: string]: Array<FormulaireElement> };
    menusLemAft: { [col: string]: Array<FormulaireElement> };
    formulaireLemAft: IFormulaire;
    tseMenus: Array<IMenuItemForage>;
    menuPhoto: Array<IMenuItem>;
    efoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dciMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dprMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ercMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aidMultiOccurrenceOptions: IMultiOccurrenceOptions;
    daaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dafMultiOccurrenceOptions: IMultiOccurrenceOptions;
    epeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eadMultiOccurrenceOptions: IMultiOccurrenceOptions;
    etlMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ddaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dbeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ideMultiOccurrenceOptions: IMultiOccurrenceOptions;
    // qsrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    lemMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aftMultiOccurrenceOptions: IMultiOccurrenceOptions;
    efoMultiOccurrence: IMultiOccurrence;
    // qsrMultiOccurrence: IMultiOccurrence;
    dafMultiOccurrence: IMultiOccurrence;

    getImageLink(data: IData): string
}

interface IRr0003Params {
    id: string;
    trncleint: string;
    tsecleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0003Controller(co0010Route: IRoute,
    cs0001Route: IRoute,
    rr0002Route: IRoute,
    rr0003Route: IRoute,
    rr0004Route: IRoute,
    rr0006Route: IRoute,
    rr0013Route: IRoute,
    rr0014Route: IRoute,
    rr0016Route: IRoute,
    rr0017Route: IRoute,
    rr0018Route: IRoute,
    rr0023Route: IRoute,
    rr0051Route: IRoute,
    rr0052Route: IRoute,
    rr0060Route: IRoute,
    rr0064Route: IRoute,
    rr0070Route: IRoute,
    rr0155Route: IRoute,
    rr0031Route: IRoute,
    rr0036Route: IRoute,
    $stateParams: IRr0003Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemTexte: IFormulaireItemTexteClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    Pagination: IPaginationClass,
    Menu: IMenuClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemForageExterne: IMenuItemForageExterneClass,
    MenuItemEcran: IMenuItemEcranClass,
    isForageValide: IIsForageValideService) {
    const vm: IPageRr0003 = this;

    vm.getImageLink = getImageLink;
    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                refreshForageIdWithCycle: true,
                boutonDupliquer: false
            }
        };

        vm.menuPhoto = [
            new MenuItemForageExterne('LBL_ENVOI_COURRIEL', (data: any) => {
                return `mailto:${data.epladrcou}`
            },
                {
                    icon: 'mail',
                    disabled: (data: any) => !data || !data.epladrcou,
                    directionTooltip: 'bottom'
                }),
            new MenuItemActionImportation(
                'LBL_IMPORT_FICHIER',
                'BOUIMPORT',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_IMPORT_FICHIER',
                        formulaire: new Formulaire([
                            new FormulaireItemTexte('LBL_AIDE_FORMULAIRE_PHOTO', { noPadding: true })
                        ])
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    typesFichiers: ['.png', '.gif', '.jpg', '.jpeg'],
                    icon: 'add_a_photo',
                    directionTooltip: 'bottom'
                }
            )
        ];

        vm.tseMenus = [
            new MenuItemForage(
                'LBL_TSE_EMPLOYE',
                rr0003Route.NAME,
                () => {
                    if (vm.valeursEcran.eplcleint) {
                        return {
                            id: vm.valeursEcran.trncleint
                        };
                    } else {
                        return {
                            trncleint: vm.valeursEcran.trncleint
                        };
                    }
                },
                {
                    courant: true,
                    icon: 'edit_location',
                    iconButton: false,
                    hidden: vm.valeursEcran.tytcleint === 3 ||
                        vm.valeursEcran.tytcleint === 4 ||
                        vm.valeursEcran.tytcleint === 5 ||
                        vm.valeursEcran.tytcleint === 73
                }
            ),
            new MenuItemForage(
                'LBL_TSE_LEMAFT',
                rr0004Route.NAME,
                () => {
                    if (vm.valeursEcran.lemcleint) {
                        return {
                            id: vm.valeursEcran.trncleint
                        };
                    } else {
                        return {
                            trncleint: vm.valeursEcran.trncleint
                        };
                    }
                },
                {
                    icon: vm.valeursEcran.lemcleint ? 'create' : 'add_circle',
                    iconButton: false,
                    disabled: (data: any) => (
                        !data.eplcleint
                    ),
                    hidden: vm.valeursEcran.tytcleint === 4 || vm.valeursEcran.tytcleint === 73
                }
            ),
            new MenuItemForage(
                'LBL_TSE_PROBATION',
                rr0155Route.NAME,
                () => ({
                    id: $stateParams.tsecleint
                }),
                {
                    icon: vm.valeursEcran.tprcleint ? 'create' : 'add_circle',
                    iconButton: false,
                    disabled: (data: any) => (!vm.valeursEcran.tseflgprb && vm.valeursEcran.flggespro != 2) || !data.lemcleint,
                    hidden: vm.valeursEcran.flggespro === 3
                }
            ),
            new MenuItemForage(
                'LBL_TSE_AFT',
                rr0006Route.NAME,
                () => {
                    if (vm.valeursEcran.lemcleint) {
                        return {
                            id: vm.valeursEcran.trncleint
                        };
                    } else {
                        return {
                            trncleint: vm.valeursEcran.trncleint
                        };
                    }
                },
                {
                    icon: vm.valeursEcran.lemcleint ? 'create' : 'add_circle',
                    iconButton: false,
                    disabled: (data: any) => (
                        !data.eplcleint || !data.lemcleint
                    ),
                    hidden: vm.valeursEcran.tytcleint === 4 || vm.valeursEcran.tytcleint === 73
                }
            )
        ];

        vm.menus = {
            ttcdscnumdoc: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0002Route.NAME,
                    () => ({
                        id: vm.valeursEcran.trncleint
                    }),
                    {
                        fonction: 'BOUTSE',
                        hidden: !vm.valeursEcran.ttcdscnumdoc
                    }
                )
            ],

            numdocref: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    `secure.${vm.valeursEcran.ecrcodref}.ecran`,
                    () => {
                        if (vm.valeursEcran.vaetseori === 'IFT') {
                            return {
                                fatcleint: vm.valeursEcran.fatcleint,
                                fdacleint: vm.valeursEcran.fdacleint
                            }
                        } else if (vm.valeursEcran.vaetseori === 'AFT') {
                            return { aftcleint: vm.valeursEcran.aftcleint }
                        } else {
                            return { id: vm.valeursEcran.trncleintref }
                        }
                    },
                    { hidden: (data: any) => !data.numdocref || (vm.valeursEcran.ecrcod && !isForageValide.isForageValide('rr0006', vm.valeursEcran.ecrcod)) }
                )
            ]
        };

        vm.formulaireEpl = new Formulaire('LBL_EMPLOYE', [
            new FormulaireGroupe(new Formulaire([
                new FormulaireItem('eplnummat', { largeur: 30 }),
                new FormulaireItem('eplnas', {
                    largeur: 30,
                    default: vm.valeursEcran.eplnas,
                    //si nous sommes dans une nouvelle saisie on permet l'édition sinon on valide l'acces
                    readonly: (data) => {
                        return ((!data?.$id ? false : !vm?.valeursEcran?.nas_acces))
                    },
                    titre: (data) => {
                        return ((!data?.$id ? undefined : (!vm?.valeursEcran?.nas_acces && !data?.eplnas) ? " " : undefined))
                    },
                }),
                new FormulaireItem('epldatnai',  { largeur: 30 }),

                new FormulaireItem('eplnom', {
                    largeur: 30,
                    default: vm.valeursEcran.eplnom
                }),
                new FormulaireItem('eplprn', {
                    largeur: 30,
                    default: vm.valeursEcran.eplprn
                }),
                new FormulaireItem('vaeeplsex', {
                    largeur: 30,
                    default: vm.valeursEcran.vaeeplsex
                }),

                new FormulaireItem('eplnomusu', {largeur: 30}),
                new FormulaireItem('eplprnusu', {largeur: 30}),
                new FormulaireItem('mrscleint', {largeur: 30}),

                new FormulaireItem('pfrcleint', {
                    default: vm.valeursEcran.pfrcleint,
                    menu: [
                        new MenuItemEcran('', rr0052Route.NAME, () => {
                            return {
                                tsecleint: vm.valeursEcran.tsecleint
                            };
                        }, {
                            icon: 'ballot',
                            disabled: (data: any) => !data.eplcleint
                        }),
                    ]
                }),
                new FormulaireItem('eplcleint')
            ]), { largeur: 75 }),
            new FormulaireTemplateSlot('photo', { largeur: 25 }),
            // Champs cahcées pour assignation valeur défaut
            new FormulaireItem('trncleint_epl', { default: $stateParams.trncleint }),
            new FormulaireItem('trncleint_dem', { default: $stateParams.trncleint }),
            new FormulaireItem('trncleint', { default: $stateParams.trncleint, hidden: true }),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES', new Formulaire([
                new FormulaireItem('eplini', {largeur: 30}),
                new FormulaireItem('vacepletaciv', {largeur: 30}),
                new FormulaireItem('eplnomnai', {largeur: 30}),
                new FormulaireItem('vaeepllng', {largeur: 30}),
                new FormulaireItem('epladrcou', { largeur: 30, historiqueColonne: true }),
                new FormulaireItem('eplnam', {largeur: 30}),
                new FormulaireItem('eplsrucod', {largeur: 30}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eplnumref1', {largeur: 20}),
                new FormulaireItem('eplnumref2', {largeur: 20}),
                new FormulaireItem('eplnumref3', {largeur: 20}),
                new FormulaireItem('eplnumref4', {largeur: 20}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eplmsg', {
                    largeur: 50
                }),
                new FormulaireItem('eicchpcar1', {largeur: 30}),
                new FormulaireItem('eicflgnum1', {largeur: 30}),
                new FormulaireItem('eicchpcar2', {largeur: 30}),
                new FormulaireItem('eicflgnum2', {largeur: 30}),
                new FormulaireItem('eicchpcar3', {largeur: 30}),
                new FormulaireItem('eicflgnum3', {largeur: 30}),
                new FormulaireItem('eicchpcar4', {largeur: 30}),
                new FormulaireItem('eicflgnum4', {largeur: 30}),
                new FormulaireItem('eicchpcar5', {largeur: 30}),
                new FormulaireItem('eicflgnum5', {largeur: 30}),
                new FormulaireItem('eicchpcar6', {largeur: 30}),
                new FormulaireItem('eicflgnum6', {largeur: 30}),
                new FormulaireItem('eicchpcar7', {largeur: 30}),
                new FormulaireItem('eicflgnum7', {largeur: 30}),
                new FormulaireItem('eicchpcar8', {largeur: 30}),
                new FormulaireItem('eicflgnum8', {largeur: 30}),
                new FormulaireItem('eicchpcar9', {largeur: 30}),
                new FormulaireItem('eicflgnum9', {largeur: 30}),
                new FormulaireItem('eicchpcar10', {largeur: 30}),
                new FormulaireItem('eicflgnum10', {largeur: 30}),
                new FormulaireItem('eicchpcar11', {largeur: 30}),
                new FormulaireItem('eicflgnum11', {largeur: 30}),
                new FormulaireItem('eicchpcar12', {largeur: 30}),
                new FormulaireItem('eicflgnum12', {largeur: 30}),
                new FormulaireItem('eicchpcar13', {largeur: 30}),
                new FormulaireItem('eicflgnum13', {largeur: 30}),
                new FormulaireItem('eicchpcar14', {largeur: 30}),
                new FormulaireItem('eicflgnum14', {largeur: 30}),
                new FormulaireItem('eicchpcar15', {largeur: 30}),
                new FormulaireItem('eicflgnum15', {largeur: 30}),
                new FormulaireItem('eicchpcar16', {largeur: 30}),
                new FormulaireItem('eicflgnum16', {largeur: 30}),
                new FormulaireItem('eicchpcar17', {largeur: 30}),
                new FormulaireItem('eicflgnum17', {largeur: 30}),
                new FormulaireItem('eicchpcar18', {largeur: 30}),
                new FormulaireItem('eicflgnum18', {largeur: 30}),
                new FormulaireItem('eicchpcar19', {largeur: 30}),
                new FormulaireItem('eicflgnum19', {largeur: 30}),
                new FormulaireItem('eicchpcar20', {largeur: 30}),
                new FormulaireItem('eicflgnum20', {largeur: 30}),
                new FormulaireItem('eicchpcar21', {largeur: 30}),
                new FormulaireItem('eicflgnum21', {largeur: 30}),
                new FormulaireItem('eicchpcar22', {largeur: 30}),
                new FormulaireItem('eicflgnum22', {largeur: 30}),
                new FormulaireItem('eicchpcar23', {largeur: 30}),
                new FormulaireItem('eicflgnum23', {largeur: 30}),
                new FormulaireItem('eicchpcar24', {largeur: 30}),
                new FormulaireItem('eicflgnum24', {largeur: 30}),
                new FormulaireItem('eicchpcar25', {largeur: 30}),
                new FormulaireItem('eicflgnum25', {largeur: 30}),
                new FormulaireItem('eicchpcar26', {largeur: 30}),
                new FormulaireItem('eicflgnum26', {largeur: 30}),
                new FormulaireItem('eicchpcar27', {largeur: 30}),
                new FormulaireItem('eicflgnum27', {largeur: 30}),
                new FormulaireItem('eicchpcar28', {largeur: 30}),
                new FormulaireItem('eicflgnum28', {largeur: 30}),
                new FormulaireItem('eicchpcar29', {largeur: 30}),
                new FormulaireItem('eicflgnum29', {largeur: 30}),
                new FormulaireItem('eicchpcar30', {largeur: 30}),
                new FormulaireItem('eicflgnum30', {largeur: 30}),
                new FormulaireItemDivider('LBL_COURRIER_INTERNE', {
                    hidden: !vm.valeursEcran.affseccouint
                }),
                new FormulaireItem('cincleint', {largeur: 30}),
                new FormulaireItem('demindcinmod', {largeur: 30}),
                new FormulaireItem('demcindatina', {largeur: 30}),
                new FormulaireItem('demcinrai', {largeur: 30}),
                new FormulaireItemDivider('LBL_INFORMATION_ETRANGER', {
                    hidden: !vm.valeursEcran.affsecetr
                }),
                new FormulaireItem('eplflgimm', {largeur: 30}),
                new FormulaireItem('pyscleint', {largeur: 30}),
                new FormulaireItem('epldatobtcit', {largeur: 30}),
                new FormulaireItem('eplnasimm', {largeur: 30}),
                new FormulaireItem('pyscleintnai', {largeur: 30}),
                new FormulaireItem('etacleintnai', {largeur: 30})
            ], {
                largeurDefaut: 50
            })),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_PAIEMENT', new Formulaire([
                new FormulaireItem('unpcleint', {
                    largeur : 30,
                    default: vm.valeursEcran.unpcleint
                }),
                new FormulaireItem('mopcleint', {
                    largeur: 30,
                    default: vm.valeursEcran.mopcleint
                }),
                new FormulaireItem('eplben', {largeur: 30}),
                new FormulaireItem('eplflgrelpap', { largeur: 30 }),
                new FormulaireItem('demcleint', {largeur: 30})
            ], {
                largeurDefaut: 50
            })),
        ]);

        vm.menusTsm = {
            nbrtsm: [
                new MenuItemForage(
                    rr0023Route.NAME,
                    (data: any) => ({
                        ecran: '03-2',
                        demcleint: data.demcleint
                    }),
                    {
                        fonction: 'BOUTM',
                        hidden: (data: any) => !data.nbrtsm
                    }
                ),
            ]
        };

        // Diagnostics médicaux
        vm.menusDia = {
            nbrsante: [
                new MenuItemForage(
                    cs0001Route.NAME,
                    (data: any) => ({
                        ecran: '00-2',
                        demcleint: data.demcleint
                    }),
                    {
                        fonction: 'BOUSANTE',
                        hidden: (data: any) => !data.nbrsante,
                        disabled: !vm.valeursEcran.secdia
                    }
                ),
            ]
        };

        // Infos bancaires
        vm.efoMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0014Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.efocleint,
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.efoMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0014Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint,
                        trncleint: $stateParams.trncleint
                    };
                }, {
                    onClose() {
                        vm.efoMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'efo',
            fonctions: {
                pagination: true,
                exportation: false,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'efdfmt', largeur: 300 },
                { nom: 'efodatvig', largeur: 131 }
            ]
        };

        // Crédits d'impôts personnels
        vm.dciMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0051Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.dcicleint,
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.dciMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0051Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    onClose() {
                        vm.dciMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'dci',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'dciannciv_typ', largeur: 300 },
                { nom: 'dcidatvig', largeur: 131 }
            ]
        };

        // Paramètres de rémun
        vm.dprMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0018Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.dprcleint,
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.dprMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0018Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    onClose() {
                        vm.dprMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'dpr',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'prv_rfecodfed', largeur: 300 },
                { nom: 'dprdatvig', largeur: 131 }
            ]
        };

        // RCE employé
        vm.ercMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0070Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.erccleint,
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.ercMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0070Route.NAME, () => ({ demcleint: vm.monoOccurrence.data.demcleint }), {
                    onClose() {
                        vm.ercMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'erc',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('datact')
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'rcecoddsc', largeur: 300 },
                { nom: 'rceflgdec', largeur: 91 },
                { nom: 'mnt_arr', largeur: 126 },
                { nom: 'ercnumref', largeur: 300 },
            ]
        };

        // Avantages imposables
        vm.aidMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0060Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.aidcleint,
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    icon: 'filter_none',
                    appliquer: true,
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.aidMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0060Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    appliquer: true,
                    onClose() {
                        vm.aidMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'aid',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'tagcoddsc', largeur: 300 },
                { nom: 'aidmnt', largeur: 91 },
                { nom: 'aiddatdeb', largeur: 126 },
                { nom: 'aiddatfin', largeur: 300 },
            ]
        };

        // Assurance collective
        vm.daaMultiOccurrenceOptions = {
            bloc: 'daa',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    rr0031Route.NAME,
                    (rowData: any) => ({
                        id: rowData.trncleint
                    }),
                    {
                        fonction: 'BOUOUV'
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage(rr0031Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                })
            ]),
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('annule', {
                    largeur: 50,
                    default: 'N',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'placoddsc', largeur: 300 },
                { nom: 'ecldsceta', largeur: 200 },
                { nom: 'datdeb', largeur: 126 },
                { nom: 'datfin', largeur: 126 },
                { nom: 'daadatadm', largeur: 126 }
            ]
        };

        // Régime de retraite
        vm.dafMultiOccurrenceOptions = {
            bloc: 'daf',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    rr0036Route.NAME,
                    (rowData: any) => ({
                        id: rowData.trncleint
                    }),
                    {
                        fonction: 'BOUOUV'
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage(rr0036Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                })
            ]),
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('annule', {
                    largeur: 50,
                    default: 'N',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'fpncoddsc', largeur: 300 },
                { nom: 'datdeb', largeur: 126 },
                { nom: 'datfin', largeur: 126 },
                { nom: 'dafdatadm', largeur: 126 }
            ]
        };

        // Permis étranger
        vm.epeMultiOccurrenceOptions = {
            bloc: 'epe',
            fonctions: {
                pagination: true,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            formulaire: new Formulaire([
                new FormulaireItem('vaetypepe'),
                new FormulaireItem('epedatper'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('epedatexp'),
                new FormulaireItem('epedatexpnas'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('epecom', {
                    largeur: 100
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'permis', largeur: 300 },
                { nom: 'epecom', largeur: 300 },
                { nom: 'epedatexpnas', largeur: 131 }
            ]
        };

        // Adresses employé
        vm.eadMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0016Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.eadcleint,
                        eplcleint: vm.monoOccurrence.data.eplcleint
                    };
                }, {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.eadMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0016Route.NAME, () => {
                    return {
                        eplcleint: vm.monoOccurrence.data.eplcleint,
                        trncleint: $stateParams.trncleint
                    };
                }, {
                    onClose() {
                        vm.eadMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'ead',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'eadadrfmt', largeur: 300 },
                { nom: 'eaddatvig', largeur: 131 },
                { nom: 'eadflgfinann', largeur: 91 },
                { nom: 'eadflgchq', largeur: 91 },
                { nom: 'taddsc', largeur: 300 }
            ]
        };

        // Téléphones employé
        vm.etlMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0017Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.etlcleint,
                        eplcleint: vm.monoOccurrence.data.eplcleint
                    };
                }, {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    appliquer: true,
                    onClose() {
                        vm.etlMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0017Route.NAME, () => {
                    return {
                        eplcleint: vm.monoOccurrence.data.eplcleint,
                        trncleint: $stateParams.trncleint
                    };
                }, {
                    appliquer: true,
                    onClose() {
                        vm.etlMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'etl',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'etlnumtel', largeur: 300 },
                { nom: 'etldatvig', largeur: 131 },
                { nom: 'etladrcou', largeur: 300 },
                { nom: 'etldsc', largeur: 300 },
                { nom: 'ttldsc', largeur: 300 }
            ]
        };

        // Dates
        vm.ddaMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0013Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.ddacleint,
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    icon: 'filter_none',
                    appliquer: true,
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.ddaMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0013Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint,
                        trncleint: $stateParams.trncleint
                    };
                }, {
                    appliquer: true,
                    onClose() {
                        vm.ddaMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'dda',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'ddadat', largeur: 300 },
                { nom: 'tdaflgman', largeur: 91 },
                { nom: 'ddacom', largeur: 300 }
            ]
        };

        // Rattachements de BAC
        vm.dbeMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0064Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.dbecleint,
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    icon: 'filter_none',
                    fonction: 'BOUOUV',
                    onClose() {
                        vm.dbeMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0064Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint
                    };
                }, {
                    onClose() {
                        vm.dbeMultiOccurrence.fetchDataList();
                    }
                }),
            ]),
            bloc: 'dbe',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'bqecoddsc', largeur: 300 },
                { nom: 'dbeqteeva', largeur: 120 },
                { nom: 'dbscoddsc1', largeur: 300 },
                { nom: 'dbscoddsc2', largeur: 300 },
                { nom: 'dbedatdeb', largeur: 131 }
            ]
        };

        // Compétences
        vm.ideMultiOccurrenceOptions = {
            bloc: 'ide',
            fonctions: {
                pagination: true,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            navigatePage: co0010Route.NAME,
            navigateParams: (data: any) => {
                return {
                    id: data.idecleint,
                    idecleintref: data.eplcleint,
                    stamnetab: 'EPL',
                    comcleint: data.comcleint
                };

            },
            actionsNouveaux: new Menu([
                new MenuItemForage(co0010Route.NAME, () => {
                    return {
                        idecleintref: vm.valeursEcran.eplcleint,
                        stamnetab: 'EPL'
                    };
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'comniv', largeur: 300 },
                { nom: 'idedatexp', largeur: 126 },
                { nom: 'indrev', largeur: 91 },
                { nom: 'sclflgverrh', largeur: 91 },
                { nom: 'expflgverrh', largeur: 91 },
                { nom: 'ideannobt', largeur: 90 },
                { nom: 'ideannderutl', largeur: 90 },
                { nom: 'idecom', largeur: 300 }
            ],
            colonnesCachees: [
                'eplcleint',
                'comcleint',
                'idecleint'
            ]
        };

        // // PAEE
        // vm.qsrMultiOccurrenceOptions = {
        //     bloc: 'qsr',
        //     fonctions: {
        //         pagination: true,
        //         criteresRechercheOpened: false
        //     },
        //     pagination: new Pagination({
        //         nombreElementPage: 5,
        //         nbElementsPossibles: [5, 10, 25]
        //     }),
        //     actionsMore: new Menu([
        //         new MenuItemAction(
        //             'LBL_BTN_GENERER',
        //             'BOUGEN',
        //             () => ({}),
        //             {
        //                 icon: 'content_copy',
        //                 saveFirst: true,
        //                 successAction: () => {
        //                     vm.qsrMultiOccurrence.fetchDataList()
        //                 },
        //                 formulaireConfirmationAction: {
        //                     lblTitre: 'LBL_BTN_GENERER',
        //                     lblConfirm: 'G_LBL_BTN_ENREGISTRER',
        //                     largeurFormulaire: 30,
        //                     formulaire: new Formulaire([
        //                         new FormulaireItem('qstcleint', {
        //                             required: true,
        //                             nombreLibellesDetailsCacher: 1
        //                         })
        //                     ], {
        //                         largeurDefaut: 100,
        //                         securityCycle: false
        //                     })
        //                 }
        //             }
        //         )
        //     ]),
        //     formulaire: new Formulaire([
        //         new FormulaireItem('qstcleint', {
        //             largeur: 75
        //         }),
        //         new FormulaireItemSautDeLigne(),
        //         new FormulaireItem('qsdcleint', {
        //             largeur: 75
        //         }),
        //         new FormulaireItemSautDeLigne(),
        //         new FormulaireItem('qsrflgon', {
        //             default: null
        //         }),
        //         new FormulaireItemSautDeLigne(),
        //         new FormulaireItem('qdvcleint', {
        //             largeur: 75
        //         }),
        //         new FormulaireItemSautDeLigne(),
        //         new FormulaireItem('qsrtxtaut', {
        //             largeur: 75
        //         }),
        //         // Champs non affichés
        //         new FormulaireItem('stamnetab', {
        //             default: 'EPL'
        //         }),
        //         new FormulaireItem('qsrcleintref', {
        //             default: vm.valeursEcran.eplcleint
        //         })
        //     ]),
        //     // Affichage des colonnes visibles
        //     colonnesVisibles: [
        //         {nom: 'reponse', largeur: 300},
        //         {nom: 'qsdnom', largeur: 200}
        //     ]
        // };

        // Forage lien d'emploi principal et Affectation principale
        vm.menusLemAft = {
            aut__lemdscfmt: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0004Route.NAME,
                    (data: any) => ({
                        id: data.aut__trncleint_lem
                    }),
                    {
                        fonction: 'BOULEMAFT',
                        hidden: (data: any) => !data.aut__lemdscfmt
                    }
                )],
            aut__aftdscfmt: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0006Route.NAME,
                    (data: any) => ({
                        id: data.aut__trncleint_aft
                    }),
                    { hidden: (data: any) => !data.aut__aftdscfmt }
                )]
        };

        // Lien d'emploi
        vm.lemMultiOccurrenceOptions = {
            bloc: 'lem',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    rr0004Route.NAME,
                    (data: any) => ({
                        id: data.trncleint_lem
                    }),
                    { fonction: 'BOUOUV' }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    '',
                    (vm.valeursEcran.creer_lem) ?
                        rr0004Route.NAME :
                        rr0002Route.NAME,
                    () => {
                        if (vm.valeursEcran.creer_lem) {
                            return {
                                trncleint: vm.monoOccurrence.data.trncleint,
                                tsecleint: vm.monoOccurrence.data.tsecleint
                            };

                        } else {
                            return {
                                eplcleint: vm.monoOccurrence.data.eplcleint,
                                demcleint: vm.monoOccurrence.data.demcleint,
                                tytcleint: 3
                            };
                        }
                    },
                    {
                        fonction: 'BOUNOULEM'
                    }

                ),
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'lemdscfmt', largeur: 300 },
                { nom: 'lemdatdeb', largeur: 126 },
                { nom: 'lemdatfin', largeur: 126 }
            ],
            colonnesCachees: [
                'lemcleint',
                'trncleint_lem'
            ]
        };
        // Affectation
        vm.aftMultiOccurrenceOptions = {
            bloc: 'aft',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    rr0006Route.NAME,
                    (data: any) => ({
                        id: data.trncleint_aft
                    }),
                    { fonction: 'BOUOUV' }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    '',
                    (vm.valeursEcran.creer_aft) ?
                        rr0006Route.NAME :
                        rr0002Route.NAME,
                    () => {
                        if (vm.valeursEcran.creer_aft) {
                            return {
                                trncleint: vm.monoOccurrence.data.trncleint,
                                tsecleint: vm.monoOccurrence.data.tsecleint,
                                lemcleint: vm.monoOccurrence.data.aut__lemcleint
                            };
                        } else {
                            return {
                                eplcleint: vm.monoOccurrence.data.eplcleint,
                                demcleint: vm.monoOccurrence.data.demcleint,
                                lemcleint: vm.monoOccurrence.data.aut__lemcleint,
                                tytcleint: 5
                            };
                        }
                    },
                    {
                        fonction: 'BOUNOUAFT'
                    }
                ),
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'aftdscfmt', largeur: 300 },
                { nom: 'aftdatdeb', largeur: 126 },
                { nom: 'aftdatfin', largeur: 126 }
            ],
            colonnesCachees: [
                'trncleint_aft'
            ]
        };
    }

    function getImageLink(data: IData): string {
        if (data && data.vaeeplsex) {
            return `assets/images/avatars/employe-${data.vaeeplsex}.png`
        } else {
            return `assets/images/logos/no-image1.png`
        }
    }
}
