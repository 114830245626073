import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr2017 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pdtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Mr2017Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
) {
    const vm: IPageMr2017 = this;

    vm.formulairePrf = new Formulaire([
        new FormulaireItem('pfcdsc', {
            largeur: 33,
            modifAllow: true
        }),
        new FormulaireItem('pfccom', {
            largeur: 33,
            modifAllow: true
        }),
        new FormulaireItem('vaepfctyp', {
            largeur: 33,
            modifAllow: true
        })
    ]);
    // Détail
    vm.pdtMultiOccurrenceOptions = {
        bloc: 'pdt',
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 130 },
            { nom: 'urgdsc', largeur: 262 },
            { nom: 'strcod', largeur: 89 },
            { nom: 'strdsc', largeur: 262 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint', { required: true }),
            new FormulaireItem('strcleint')
        ])
    };
}
