import { IComponentController } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMC0198 extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function MC0198Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    mc0199Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageMC0198 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemEcran(
                '',
                mc0199Route.NAME,
                () => ({
                    prucleint: $stateParams.prucleint ? $stateParams.prucleint : null,
                    prscleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.prucleint ? $stateParams.prscleint : null
                }),
                {
                    iconButton: true,
                    fonction: 'BOUNOU',
                    onClose: () => vm.multiOccurrence.fetchDataList()
                }
            )
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                '',
                mc0199Route.NAME,
                (data: any) => ({
                    id: data.prtcleint,
                    prucleint: data.prucleint,
                    vaesysnatctb: data.vaesysnatctb
                }),
                {
                    icon: 'edit',
                    iconButton: true,
                    fonction: 'BOUEDIT',
                    onClose: () => vm.multiOccurrence.fetchDataList()
                }
            )
        ]),
        fonctions: {
            criteresSuggeresVisibles: !($stateParams.prucleint || $stateParams.prscleint || $stateParams.blocleint)
        },
        filtresParametres: [{ colonne: 'prucleint' }, { colonne: 'prscleint' }, { colonne: 'blocleint' }],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('prucleint', {
                default: $stateParams.prucleint ? $stateParams.prucleint : null,
                ignoreEtat: $stateParams.prucleint,
                hidden: $stateParams.prucleint
            }),
            new FormulaireItem('prscleint', {
                default: $stateParams.prscleint ? $stateParams.prscleint : null,
                ignoreEtat: $stateParams.prscleint,
                hidden: $stateParams.prscleint
            }),
            new FormulaireItem('blocleint', {
                default: $stateParams.blocleint ? $stateParams.blocleint : null,
                ignoreEtat: $stateParams.blocleint,
                hidden: $stateParams.blocleint
            })
        ]),
        colonnesFixesGauche: [{ nom: 'prucod', largeur: 90, hidden: () => $stateParams.prucleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.prucleint }],
        colonnesVisibles: [
            { nom: 'prscod', largeur: 90, hidden: () => $stateParams.prscleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.prscleint },
            { nom: 'blocod', largeur: 131, hidden: () => $stateParams.blocleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.blocleint },
            { nom: 'cptcod', largeur: 113 },
            { nom: 'vaesysnatctb_dsc', largeur: 150 },
            { nom: 'cifcod', largeur: 100 },
            { nom: 'unacod', largeur: 200 },
            { nom: 'prtdatdeb', largeur: 100 },
            { nom: 'prtdatfin', largeur: 100 },
            { nom: 'prtflgbdgcmp', largeur: 100 },
            { nom: 'remcod', largeur: 125 },
            { nom: 'prtprcdep', largeur: 157 },
            { nom: 'prtmntdep', largeur: 126 },
            { nom: 'prtflgepr', largeur: 100 },
            { nom: 'vaesyscodadm', largeur: 381 },
            { nom: 'rgaind01', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb01.toString() },
            { nom: 'rgaind02', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb02.toString() },
            { nom: 'rgaind03', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb03.toString() },
            { nom: 'rgaind04', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb04.toString() },
            { nom: 'rgaind05', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb05.toString() },
            { nom: 'rgaind06', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb06.toString() },
            { nom: 'rgaind07', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb07.toString() },
            { nom: 'rgaind08', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb08.toString() },
            { nom: 'rgaind09', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb09.toString() },
            { nom: 'rgaind10', largeur: 100, replacedValue: () => vm.valeursEcran.rgalibb10.toString() },
            { nom: 'prudsc', largeur: 280, hidden: () => $stateParams.prucleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.prucleint },
            { nom: 'prsdsc', largeur: 280, hidden: () => $stateParams.prscleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.prscleint },
            { nom: 'blodsc', largeur: 280, hidden: () => $stateParams.blocleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.blocleint },
            { nom: 'cptdsc', largeur: 280 },
            { nom: 'cifdsc', largeur: 280 },
            { nom: 'unadsc', largeur: 280 }
        ],
        colonnesCachees: ['vaesysnatctb', 'prucleint']
    };
}
