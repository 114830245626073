import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageAc0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
interface IAc0021Params {
    demcleint: string;
    aftcleint: string;
    employe: string;
    menuId: string;
    efface_criteres: boolean;
}

/* @ngInject */
export default function Ac0021Controller(
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IAc0021Params,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    ac0010Route: IRoute,
    ac2002Route: IRoute
) {
    const vm: IPageAc0021 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: !$stateParams.employe,
            effaceCriteres: $stateParams.efface_criteres,
            edition: false,
            supprime: false
        },
        navigatePage: ac0010Route.NAME,
        navigateParams: (rowData:any) => (
            {
                id: rowData.doccleintref
            }
        ),
        actionsNouveaux: new Menu([
            new MenuItemEcran( 
                '', 
                ac2002Route.NAME,
                () => ({}),
                {
                    iconButton: false,
                    icon: 'add_circle',
                }
            )
            //new MenuItemForage(ac2002Route.NAME)
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('daccleint', { required: false }),
            new FormulaireItem("refcleintcri"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("peccleint", { required: false }),
            new FormulaireItem("sysboncmd"),
            new FormulaireItem("tydcleint", { required: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etdcleint', { default: null, required: false }),
            new FormulaireItem("dacdatdeb"),
            new FormulaireItem("dacdatfin"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("intcleintreq", { required: false }),
            new FormulaireItem("intcleintdem", { required: false }),
            new FormulaireItem("usrcre", { required: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("cifcleint", { required: false }),
            new FormulaireItem("unacleint", { required: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("prucleintdeb", { required: false }),
            new FormulaireItem("prucleintfin", { required: false }),

        ]),
        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 120 },
            { nom: 'dacnumdocseq', largeur: 100 },
            { nom: 'sysboncmdtxt', largeur: 120 },
            { nom: 'dacseqave', largeur: 100 },
            { nom: 'dacsou', largeur: 150 },
            { nom: 'tydcod', largeur: 100 },
            { nom: 'dacdsc', largeur: 300 },
            { nom: 'refcod', largeur: 100 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'refnomabr', largeur: 200 },
            { nom: 'etddsc', largeur: 300 },
            { nom: 'flgcou', largeur: 100 },
            { nom: 'flgnonrec', largeur: 100 },
            { nom: 'flgrecpar', largeur: 100 },
            { nom: 'flgfca', largeur: 100 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'dacmnt', largeur: 100 },
            { nom: 'dacrefint', largeur: 100 },
            { nom: 'caacod', largeur: 120 },
            { nom: 'caadsc', largeur: 300 },
            { nom: 'etcnumdoc', largeur: 100 },
            { nom: 'etcdsc', largeur: 300 },
            { nom: 'aponumdoc', largeur: 100 },
            { nom: 'intcodach', largeur: 100 },
            { nom: 'intnomach', largeur: 150 },
            { nom: 'intcoddem', largeur: 100 },
            { nom: 'intnomdem', largeur: 150 },
            { nom: 'intcodreq', largeur: 100 },
            { nom: 'intnomreq', largeur: 150 },
            { nom: 'vecdaccat', largeur: 100 },
            { nom: 'lipcod', largeur: 100 },
            { nom: 'flgerr_b2b', largeur: 100 },
            { nom: 'usrcod', largeur: 120 },
            { nom: 'motdsc', largeur: 150 },
            { nom: 'flgtran', largeur: 150 },
            { nom: 'flgimp', largeur: 150 },
            { nom: 'dacdat', largeur: 150 },
            { nom: 'dacdatbc', largeur: 150 },
            { nom: 'dacdatreq', largeur: 150 },
            { nom: 'dateofficiel', largeur: 150 },
            { nom: 'dacdatree', largeur: 150 },
            { nom: 'dacdatdeb', largeur: 150 },
            { nom: 'dacdatfin', largeur: 150 },
            { nom: 'sldeng', largeur: 150 },
            { nom: 'nibcod', largeur: 150 },
            { nom: 'nibnom', largeur: 300 }
        ],
        colonnesCachees: [
            'doccleintref'
        ]
    };
}
