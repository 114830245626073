import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPageRR2010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;    
}
/* @ngInject */
export default function Rr2010Controller(rr0040Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Operateur: IOperateurService,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageRR2010 = this;
   
    vm.multiOccurrenceOptions = {
       fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0040Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                employe: rowData.demcleint
            };
        },
        
        filtres: [
            ...parametresRecherche(ecranDetails),
            {
                colonne: 'trnflgstaref',
                operateur: Operateur.EGALE,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafin',
                operateur: Operateur.EGALE,
                valeur: '1'
            }
        ],        
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_CRI_EMP'),
            new FormulaireItem('eplnummat', {required: false }),
            new FormulaireItem('eplnomprn'),
            new FormulaireItem('eplnas'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unpcleint'),
            new FormulaireItem('eplflgimm')
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnom', largeur: 162},
            {nom: 'eplprn', largeur: 162}
        ],
        colonnesVisibles: [
            {nom: 'epldatnai', largeur: 120},
            {nom: 'eplnasimm', largeur: 110},
            {nom: 'eplflgimm', largeur: 157},
            {nom: 'obtdat', largeur: 150},
            {nom: 'pyscod', largeur: 115},
            {nom: 'pysnomfra', largeur: 200},
            {nom: 'pyscodnai', largeur: 94},
            {nom: 'pysnomfranai', largeur: 200},
            {nom: 'etacod', largeur: 130},
            {nom: 'etanomfra', largeur: 200},
            {nom: 'vaetypepe_dsc', largeur: 160},
            {nom: 'epedatper', largeur: 160},
            {nom: 'epedatexp', largeur: 110},
            {nom: 'epedatexpnas', largeur: 130}
        ],
        colonnesCachees: [
            'eplcleint',
            'eplnomprn'
        ]
    };   
}
