import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageRr0131 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function rr0131Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         rr0036Route: IRoute,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRr0131 = this;

    vm.multiOccurrenceOptions = {
       // Forage pour le régime de retraite
        navigatePage: rr0036Route.NAME,
        navigateParams: (data: any) => {
            return {
                id: data.cleintdos
            };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('fpncleintcri'),
            new FormulaireItem('urgcleintcri'),
            new FormulaireItem('strcleintcri'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgsandatadmcri', {
                default: 1
            }),
            new FormulaireItem('flgcomplcri', {
                default: 0
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('datadmdecri'),
            new FormulaireItem('datadmacri')
        ]),

        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 200}
        ],

        colonnesVisibles: [
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'strcod', largeur: 126},
            {nom: 'strdsc', largeur: 262},
            {nom: 'codava', largeur: 126},
            {nom: 'dscava', largeur: 262},
            {nom: 'numrefava', largeur: 120},
            {nom: 'datadmava', largeur: 120},
            {nom: 'datdebava', largeur: 100},
            {nom: 'datfinava', largeur: 100}
        ],

        colonnesCachees: [
            'cleintdos'
        ]
    };
}
