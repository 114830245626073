import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";

interface IPagePb0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0023Controller(pb0024Route: IRoute,
                                         pb0028Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass) {
    const vm: IPagePb0023 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: (rowData: any) => (rowData.pbgtyp === 'PBG') ? pb0024Route.NAME : pb0028Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(
                'BTN_NOU_PBG',
                pb0024Route.NAME,
                ()=>({}),
                {
                    icon: '',
                    iconButton: false,
                    fonction: 'NOUPBG'
                }),
            new MenuItemForage(
                'BTN_NOU_PVL',
                pb0028Route.NAME,
                ()=>({}),
                {
                    icon: '',
                    iconButton: false,
                    fonction: 'NOUPVL'
                })
        ]),
        colonnesVisibles: [
            {nom: 'pbgann'   , largeur: 120},
            {nom: 'pbgtyppbg', largeur: 300}
        ],
        colonnesCachees: [
            'pbgtyp'
        ]
    };
}
