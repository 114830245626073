import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('vacvalcle'),            
            new FormulaireItem('vacdsc', {largeur: 50}),
            new FormulaireItem('vacflgact', {default: 1})

        ], {largeurDefaut: 25}),
        colonnesVisibles: [
            {nom: 'vacvalcle', largeur: 105},
            {nom: 'vacdsc', largeur: 280},
            {nom: 'vacflgact', largeur: 80}
        ]
    };
}
