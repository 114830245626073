import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2004',
    ecrans: ['00-2'],
    params: [
        '?type', 
        '?prucleint', 
        '?prbcleint', 
        '?prvcleint', 
        '?el1cleint',
        '?soncleint',
        '?cptcleint',
        '?vaesysnatctb', 
        '?tymcleint', 
        '?tydcleint', 
        '?peccleintdeb', 
        '?peccleintfin']
});
