import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageDb0054 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dbrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dbrMultiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Db0054Controller(
    profil: IProfil,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass
) {
    const vm: IPageDb0054 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.menuSimulation = {
        ciesimnbrgrptot: [
            new MenuItemAction(
                'BOUSIM',
                () => ({}),
                {
                    icon: 'sync',
                    iconButton: true,
                    formulaireConfirmationAction: {
                        lblTitre: 'G_LBL_SIMULER',
                        lblConfirm: 'G_LBL_SIMULER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('nbrrspgrp', {
                                required: true
                            })
                        ], { largeurDefaut: 100 })
                    },
                    successAction: (retour: any) => (retour.ciesimnbrgrpdeb, retour.ciesimnbrgrpfin, retour.ciesimnbrgrptot),
                    champsRechargement: ['ciesimnbrgrpdeb', 'ciesimnbrgrpfin', 'ciesimnbrgrptot']
                }
            )
        ]
    };

    vm.menuExecuter = {
        cienbrrsp: [
            new MenuItemAction(
                'BOUEXE',
                () => ({}),
                {
                    icon: 'sync',
                    iconButton: true,
                    formulaireConfirmationAction: {
                        lblTitre: 'G_LBL_EXECUTER',
                        lblConfirm: 'G_LBL_EXECUTER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('nbrrspgrp', {
                                required: true
                            })
                        ], { largeurDefaut: 100 })
                    },
                    successAction: (retour: any) => {
                        (retour.ciesimnbrgrpdeb, retour.ciesimnbrgrpfin, retour.ciesimnbrgrptot),
                        vm.dbrMultiOccurrence.fetchDataList();
                    },
                    champsRechargement: ['ciesimnbrgrpdeb', 'ciesimnbrgrpfin', 'ciesimnbrgrptot']
                }
            )
        ]
    };

    vm.monoOccurrenceOptions = {
        id: profil.preferences.ciecleint,
        noId: true,
        fonctions: {
            suiviModification: false
        }
    };

    vm.dbrMultiOccurrenceOptions = {
        bloc: 'dbr',
        fonctions: {
            suiviModification: false,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'dbrgrpnum', largeur: 100 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 400 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dbrgrpnum')
        ])
    };
}
