import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IData} from "../../../../../core/services/data-linker.service";
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0051 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrenceOptionsformulaireDossierRRhd:IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireSecuriteAccess: IFormulaire;
    formulaireEmp: IFormulaire;
    formulaireProfilemploye: IFormulaire;
    formulaireDossierfinance: IFormulaire;
    formulaireDossierrh: IFormulaire;
    formulaireDossierrhd:IFormulaire;
    formulaireParticularites: IFormulaire;
    formulaireGestionmanuelle: IFormulaire;
    formulaireReleve27: IFormulaire;
    scbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    emlMultiOccurrenceOptions: IMultiOccurrenceOptions;
    emrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    forageDossieremploye: IMenuItem;
    forageRetenue: IMenuItem;
    forageCumulatif: IMenuItem;
    valeursEcran: any;
}

/* @ngInject */
export default function Mc0051Controller(mc0088Route: IRoute,
                                         mc1011Route: IRoute,
                                         mc0087Route: IRoute,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMc0051 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.forageDossieremploye = new MenuItemEcran('LBL_BTN_DOSSIER',
            mc0088Route.NAME,
            (data:any) => (
                {
                    id: data.aut__rricleint,
                    demcleint: data.demcleint,
                    refcleint: data.refcleint
                }),
            {
                icon: 'folder',
                iconButton: false,
                secondaire: true,
                onClose: () => vm.monoOccurrence.fetchData(),
                disabled: (data: IData) => !data.$id,
            }
        );

        vm.valeursEcran = ecranDetails.valeurs;

        vm.forageRetenue = new MenuItemEcran('LBL_BTN_RETENUE',
            mc1011Route.NAME,
            (data:any) => (
                {
                    id: data.aut__rrecleint,
                    refcleint: data.refcleint
                }),
            {
                icon: 'view_list',
                iconButton: false,
                secondaire: true,
                disabled: (data: IData) => !data.$id,
            }
        );

        vm.forageCumulatif = new MenuItemEcran('LBL_BTN_CUMULATIF',
            mc0087Route.NAME,
            (data:any) => ({ refcleint: data.refcleint }),
            {
                icon: 'view_list',
                iconButton: false,
                secondaire: true,
                disabled: (data: IData) => !data.$id,
            }
        );

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulaireEmp = new Formulaire('LBL_BLC_EMP', [
            new FormulaireItem('empcod'),
            new FormulaireItem('empdatdeb'),
            new FormulaireItem('empnom'),
            new FormulaireItem('empprn'),
            new FormulaireItem('empadrcou', {historiqueColonne: true}),
            new FormulaireItem('unacleint'),
            new FormulaireItem('emploc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_DIV_GES'),
            new FormulaireItem('precleint', { readonly: () => !vm.valeursEcran.flgemp}),
            new FormulaireItem('pflcleint', { readonly: () => !vm.valeursEcran.flgcap}),
            new FormulaireItem('crpcleint', { readonly: () => !vm.valeursEcran.flgcar}),
            new FormulaireItem('lipcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_ACC_COR', new Formulaire([
                new FormulaireItem('radnomabr', { readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('radadrl01', { readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radadrl02', { readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radvil', { readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radpro', { readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radpay', { readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radcodpos', { readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radnumtel', {largeur: 33, readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radnumtelpos', {largeur: 33, readonly: () => !vm.valeursEcran.flgmod}),
                new FormulaireItem('radnumfax', {largeur: 33,  readonly: () => !vm.valeursEcran.flgmod}),
            ]),{ hidden: () => !vm.valeursEcran.flgacc})
        ], {largeurDefaut: 50});

        vm.scbMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            actionsMoreLigne: new Menu([
                new MenuItemAction('Approuver', 'BOUUSRAPR', () => ({}), {
                    icon: 'check',
                    hidden: (data: any) => !data.scbflgaprreq
                })
            ]),
            colonnesVisibles: [
                {nom: 'inscod', largeur: 100},
                {nom: 'insdsc', largeur: 180},
                {nom: 'succod', largeur: 110},
                {nom: 'sucdsc', largeur: 180},
                {nom: 'scbfol', largeur: 100},
                {nom: 'scbdateff', largeur: 130},
                {nom: 'scbflgaprreq', largeur: 110}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('refcleint', {default: () =>vm.monoOccurrence.data.refcleint}),
                new FormulaireItem('inscleint', {largeur: 40}),
                new FormulaireItem('succleint', {largeur: 40}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('scbfol'),
                new FormulaireItem('scbdateff')
            ], {largeurDefaut: 25})
        };

        vm.formulaireProfilemploye = new Formulaire('LBL_BLC_PRO', [
            new FormulaireItem('empindfeu', {largeur: 25}),
            new FormulaireItem('empnbrhre', {largeur: 25}),
            new FormulaireItem('emtbenmar', {largeur: 25}),
            new FormulaireItem('emttaumoy', {largeur: 25}),
            new FormulaireItem('emttauhor', {largeur: 25}),
            new FormulaireItemDivider('LBL_DIV_PLG'),
            new FormulaireItem('empflgdim'),
            new FormulaireItem('empflglun'),
            new FormulaireItem('empflgmar'),
            new FormulaireItem('empflgmer'),
            new FormulaireItem('empflgjeu', {largeur: 25}),
            new FormulaireItem('empflgven', {largeur: 25}),
            new FormulaireItem('empflgsam', {largeur: 25}),
            new FormulaireItem(' ', {readonly: true, largeur: 0})
        ], {largeurDefaut: 10});

        vm.formulaireDossierfinance = new Formulaire('LBL_BLC_DOS', [
            new FormulaireItem('empflgstu'),
            new FormulaireItem('empdatfin')
        ], {largeurDefaut: 50});

        vm.formulaireDossierrh = new Formulaire('LBL_BLC_DRH', [
            new FormulaireItem('empdatfinrh')
        ], {largeurDefaut: 50});
        
        vm.formulaireParticularites = new Formulaire('LBL_BLC_PAR', [
                new FormulaireItem('empflgloi95'),
                new FormulaireItem('empflgimptfe')
            ], {largeurDefaut: 50
            }
            );

        vm.formulaireGestionmanuelle = new Formulaire('LBL_BLC_GES', [
            new FormulaireItem('empflgman'),
            new FormulaireItem('empindmajpfl'),
            new FormulaireItem('empindmajadr'),
            new FormulaireItem('empindmajscb'),
            new FormulaireItem('empindmajtel'),
            new FormulaireItem('empindmajtfe'),
            new FormulaireItem('empindmajadrcou'),
            new FormulaireItem(' ', {readonly: true, largeur: 0})
        ], {largeurDefaut: 10});

        vm.emlMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'emldatdeb', largeur: 120},
                {nom: 'emldatfin', largeur: 105},
                {nom: 'posnumdoc', largeur: 95},
                {nom: 'vaeemlnivdirloi95_dsc', largeur: 150},
                {nom: 'mhcdatdebloi95', largeur: 95},
                {nom: 'mhcdatfinloi95', largeur: 95}
            ]            
        };

        vm.formulaireReleve27 = new Formulaire('LBL_BLC_REL', [
            new FormulaireItem('empflgr27'),
            new FormulaireItem('vaesystypben'),
            new FormulaireItem('vaesystypcas'),
            new FormulaireItem('empid'),
            new FormulaireItem('empneq'),
            new FormulaireItem('empcon')
        ], {largeurDefaut: 100});
    }
}
