import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0118 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0118Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0118 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'clrnum'          , largeur: 130},
            {nom: 'clrdsc'          , largeur: 300},
            {nom: 'vaeclrtypcal_dsc', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('clrnum', {largeur: 20}),
            new FormulaireItem('clrdsc'),
            new FormulaireItem('vaeclrtypcal')
        ], {largeurDefaut: 40})
    };
}
