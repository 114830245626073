import {IComponentController, ITranscludeFunction} from 'angular';
import {IMultiOccurrenceOptions} from '../../../../core/services/multi-occurrence.service';
import {IComposantMonoOccurrenceEcran} from '../../../../core/components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';

interface IComposantCardMultiOccurrenceMaitreDetails extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    libelles: any;
    hasTranscludeSlot(slot: string): boolean
}

/* @ngInject */
export default function CardMultiMaitreDetailsController($transclude: ITranscludeFunction) {
    const vm: IComposantCardMultiOccurrenceMaitreDetails = this;

    vm.$onInit = $onInit;
    vm.hasTranscludeSlot = hasTranscludeSlot;

    function $onInit() {
        vm.libelles = vm.libelles ||
         (vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.monoOccurrence && vm.monoOccurrenceEcranCtrl.monoOccurrence.libelles) ||
         vm.ecranContextCtrl.ecranSourceDetails.libelles;

        vm.multiOccurrenceOptions.fonctions = Object.assign({
            importation: false,
            afficherMenuSettingDansEntete: true,
            suiviModification: true
        }, vm.multiOccurrenceOptions.fonctions);
    }

    function hasTranscludeSlot(slot: string) : boolean {
        return $transclude.isSlotFilled(slot);
    }
}
