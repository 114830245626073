import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";


interface IPageAS0036 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0036Controller(as0037Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageAS0036 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: as0037Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(as0037Route.NAME)]),
        fonctions: {
            rechercheCollapsable: false
            },
        colonnesFixesGauche: [
            { nom: 'rgvnumseq', largeur: 130 },
            { nom: 'rgvcod', largeur: 130 },
            {nom: 'rgvdsc', largeur: 262}
        ],    
        colonnesVisibles: [            
            {nom: 'rgvflgact', largeur: 65},
            {nom: 'vaergvtyp_dsc', largeur: 100},
            {nom: 'rgvdatdeb', largeur: 115},
            {nom: 'rgvdatfin', largeur: 100},
            {nom: 'vaergvmod_dsc', largeur: 158},
            {nom: 'vaergvseq_dsc', largeur: 158},
            {nom: 'rgvflgexc', largeur: 65}
        ]
    };
}
