import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPagePc0020 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rugMultiOccurrenceOptions: IMultiOccurrenceOptions;
    semMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ppqMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pc0020Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService
) {
    const vm: IPagePc0020 = this;

    vm.formulairePuq = new Formulaire('LBL_ATF', [
        new FormulaireItem('puqcodreg', {
            largeur: 33
        }),
        new FormulaireItem('cincleint', {
            largeur: 33
        }),
        new FormulaireItem('fpncleint', {
            largeur: 33
        })
    ]);

    vm.rugMultiOccurrenceOptions = {
        bloc: 'rug',
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 190 },
            { nom: 'urgdscabr', largeur: 190 },
            { nom: 'rugetbcod', largeur: 190 },
            { nom: 'rugperadm', largeur: 190 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint', {
                largeur: 33
            }),
            new FormulaireItem('rugetbcod', {
                largeur: 33
            }),
            new FormulaireItem('rugperadm', {
                largeur: 33
            })
        ])
    };

    vm.semMultiOccurrenceOptions = {
        bloc: 'sem',
        colonnesFixesGauche: [
            { nom: 'urgcod', largeur: 250 },
            { nom: 'urgdscabr', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'strcod', largeur: 150 },
            { nom: 'strdsc', largeur: 220 },
            { nom: 'tafcod', largeur: 150 },
            { nom: 'tafdsc', largeur: 220 },
            { nom: 'temcod', largeur: 150 },
            { nom: 'temdscabr', largeur: 220 },
            { nom: 'semflgtmppardesc', largeur: 150 },
            { nom: 'bqecod', largeur: 150 },
            { nom: 'bqedsc', largeur: 220 },
            { nom: 'semstaemp', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint', {
                largeur: 33
            }),
            new FormulaireItem('strcleint', {
                largeur: 33,
                resourceParams: (data: any) => ({
                    urgcleint: data.urgcleint
                })
            }),
            new FormulaireItem('tafcleint', {
                largeur: 33,
                resourceParams: (data: any) => ({
                    urgcleint: data.urgcleint,
                    strcleint: data.strcleint
                })
            }),
            new FormulaireItem('temcleint', {
                largeur: 33,
                resourceParams: (data: any) => ({
                    urgcleint: data.urgcleint
                })
            }),
            new FormulaireItem('semflgtmppar', {
                largeur: 33
            }),
            new FormulaireItem('bqecleint', {
                largeur: 33
            }),
            new FormulaireItem('semstaemp', {
                largeur: 33
            }),
            new FormulaireItem('puqcleint', {
                default: $stateParams.id
            })
        ])
    };

    vm.ppqMultiOccurrenceOptions = {
        bloc: 'ppq',
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 130 },
            { nom: 'tagdscabr', largeur: 130 },
            { nom: 'vaetypexo', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tagcleint', {
                largeur: 33
            }),
            new FormulaireItem('vaetypexo', {
                largeur: 33
            })
        ])
    }
}
