import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRR0035 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0035Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         parametresRecherche: IParametresRechercheService,
                                         rr0036Route: IRoute) {
    const vm: IPageRR0035 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0036Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0036Route.NAME, () => {
                return {
                    demcleint: $stateParams.employe
                }
            })
        ]),
        filtres: parametresRecherche(ecranDetails),
        filtresParametres: [
            {colonne: 'demcleint', affichage: false}
        ],
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 125, hidden: $stateParams.employe },
            {nom: 'eplnomprn', largeur: 270, hidden: $stateParams.employe },
            {nom: 'dafnumdoc', largeur: 120},
            {nom: 'ttccod', largeur: 120},
            {nom: 'ecldsceta', largeur: 193},
            {nom: 'dafdatdeb', largeur: 120},
            {nom: 'dafdatadm', largeur: 120},
            {nom: 'dafdatfin', largeur: 120},
            {nom: 'dafdatfinrem', largeur: 140},
            {nom: 'fpncod', largeur: 115},
            {nom: 'fpndsc', largeur: 270},
            {nom: 'dafcom', largeur: 400},
            {nom: 'unpcod', largeur: 120},
            {nom: 'unpdsc', largeur: 360},
            {nom: 'cdfcod', largeur: 160},
            {nom: 'cdfdsc', largeur: 325},
            {nom: 'ddfflghis', largeur: 125},
            {nom: 'trnflgstafer', largeur: 115},
            {nom: 'trnflgstaann', largeur: 125}
        ],
        colonnesCachees: [
            'demcleint'
        ]
    };
}
