import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageRr0554 extends IComponentController {
    formulaireRes: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ecdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0554Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    MenuItemAction: IMenuItemActionClass) {

    const vm: IPageRr0554 = this;

    vm.$onInit = function $onInit() {
        vm.flgresultat = false;

        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonEnregistrerHaut: false,
                skipChangementsNonSauvegardes: true
            },
            formulaire: new Formulaire('LBL_BLC_ECE', [
                new FormulaireItem('demcleint', { largeur: 50, readonly: true }),
                new FormulaireItem('rcecleint', { largeur: 50, readonly: true }),
                new FormulaireItem('ppacleintdeb', { largeur: 50, default: ecranDetails.valeurs.ppacleintdeb, required: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ppacleintfin', { largeur: 50, libellesContour: { droite: 'LBL_OU' } }),
                new FormulaireItem('nbrper'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('mntper', { libellesContour: { droite: 'LBL_OU' } }),
                new FormulaireItem('mnttot'),
            ], { largeurDefaut: 25 })
        };

        vm.validation = new MenuItemAction(
            'LBL_BTN_VAL',
            'BOUVAL',
            () => ({
                ppacleintdeb: vm.monoOccurrence.data.ppacleintdeb,
                ppacleintfin: vm.monoOccurrence.data.ppacleintfin,
                nbrper: vm.monoOccurrence.data.nbrper,
                mntper: vm.monoOccurrence.data.mntper,
                mnttot: vm.monoOccurrence.data.mnttot
            }),
            {
                icon: '',
                iconButton: false,
                disabled: (data: any) => !data.ppacleintdeb,
                successAction(result) {
                    vm.flgresultat = result.flgresultat
                    vm.monoOccurrence.data.total = result.total
                    vm.monoOccurrence.data.nbrper_res = result.nbrper_res
                    vm.monoOccurrence.data.ppacod_deb = result.ppacod_deb
                    vm.monoOccurrence.data.mntper_deb = result.mntper_deb
                    vm.monoOccurrence.data.ppacod_fin = result.ppacod_fin
                    vm.monoOccurrence.data.mntper_fin = result.mntper_fin
                }
            }
        );

        vm.repartition = new MenuItemAction(
            'LBL_BTN_REP',
            'BOUREP',
            () => vm.monoOccurrence.data,
            {
                icon: 'settings',
                iconButton: false,
                disabled: () => !vm.flgresultat
            }
        );

        vm.formulaireRes = new Formulaire('LBL_BLC_RES', [
            new FormulaireItem('nbrper_res', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ppacod_deb', { readonly: true }),
            new FormulaireItem('mntper_deb', { readonly: true }),
            new FormulaireItem('ppacod_fin', { readonly: true }),
            new FormulaireItem('mntper_fin', { readonly: true }),
            new FormulaireItemSautDeColonne(),
            new FormulaireItem('total', { readonly: true })
        ]);
    }
}
