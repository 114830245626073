import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0105 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0105Controller(Formulaire: IFormulaireClass,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageRr0105 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: [
            ...parametresRecherche(ecranDetails),
            {
                colonne: 'trnflgstafin',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesVisibles: [
            {nom: 'rghcod', largeur: 160},
            {nom: 'rghdsc', largeur: 210},
            {nom: 'horcod', largeur: 120},
            {nom: 'hordsc', largeur: 160},
            {nom: 'rrhdatdeb', largeur: 126},
            {nom: 'rrhdatfin', largeur: 126},
            {nom: 'ecldsceta', largeur: 140},
            {nom: 'prv_dsc', largeur: 140},
            {nom: 'trnflgstaannrat', largeur: 100},
            {nom: 'posnumdoc', largeur: 120},
            {nom: 'postitabr', largeur: 140},
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 140},
            {nom: 'aftcod', largeur: 120},
            {nom: 'aftdsc', largeur: 140},
            {nom: 'rrhnumdoc', largeur: 120},
            {nom: 'ttccod', largeur: 120},
            {nom: 'ttcdsc', largeur: 140},
            {nom: 'rrhqtesem', largeur: 100},
            {nom: 'unccod', largeur: 120},
            {nom: 'uncdsc', largeur: 140},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 140},
            {nom: 'strcod', largeur: 120},
            {nom: 'strdsc', largeur: 140},
            {nom: 'posnumdocsup', largeur: 120},
            {nom: 'postitabrsup', largeur: 140},
            {nom: 'temcod', largeur: 120},
            {nom: 'temdscabr', largeur: 140},
            {nom: 'unocod', largeur: 120},
            {nom: 'unodscabr', largeur: 140},
            {nom: 'rrhflgdef', largeur: 100},
            {nom: 'rrhetc', largeur: 100},
            {nom: 'rrhnumref', largeur: 120},
            {nom: 'rrhseqaff', largeur: 100},
            {nom: 'rrhdsc', largeur: 140},
            {nom: 'tafcod', largeur: 120},
            {nom: 'tafdsc', largeur: 140},
            {nom: 'trnflgstaref', largeur: 100},
            {nom: 'trnflgstafin', largeur: 100},
            {nom: 'trnflgstafer', largeur: 100},
            {nom: 'trnflgstaann', largeur: 100}
        ]
    };
}
