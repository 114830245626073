import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAs0045 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0045Controller(Menu: IMenuClass,
    $stateParams: any,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAs0045 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'vlacleint', valeur: $stateParams.reference, visible: false }
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdsc', largeur: 230 },
            { nom: 'strcod', largeur: 89 },
            { nom: 'strdsc', largeur: 230 },
            { nom: 'tprcod', largeur: 140 },
        ],
        colonnesFixesDroite: [
            { nom: 'vaesyscaptem_dsc', largeur: 155 },
            { nom: 'vlaflgcapfix', largeur: 65 },
            { nom: 'vlaflgtagaut', largeur: 91 },
            { nom: 'vlaflgdecftp', largeur: 91 },
            { nom: 'vladecftp', largeur: 91 },
            { nom: 'vlaflgdec', largeur: 91 },
            { nom: 'vladecaut', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tprcleint'),
            new FormulaireItem('vaesyscaptem'),
            new FormulaireItem('vlaflgcapfix'),
            new FormulaireItem('vlaflgtagaut'),
            new FormulaireItem('vlaflgdecftp'),
            new FormulaireItem('vladecftp'),
            new FormulaireItem('vlaflgdec'),
            new FormulaireItem('vladecaut'),
        ], { largeurDefaut: 25 }
        )
    };
}
