import { IComponentController, IScope } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';


interface IPageIn0020 extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0020Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    $scope: IScope
) {
    const vm: IPageIn0020 = this;
    vm.$onInit = $oninit;

    vm.multiOccurrenceOptions = {
        resourceParamsDynamique: () => ({
            criannee: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.criannee,
            crimoisdeb: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.crimoisdeb
        }),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true,
            libellesDynamiques: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('criannee', {
                required: true,
                largeur: 22,
                ignoreEtat: true
            }),
            new FormulaireItem('crimoisdeb', {
                required: true,
                largeur: 22,
                ignoreEtat: true
            }),
            new FormulaireItem('crimoisfin', {
                largeur: 22,
                ignoreEtat: true
            }),
            new FormulaireItem('crityp', {
                required: true,
                largeur: 33,
                default: 'VAL',
                ignoreEtat: true
            }),
            new FormulaireItem('crimagcleint', {
                largeur: 33
            }),
            new FormulaireItem('crifimcleint', {
                largeur: 33,
                ignoreEtat: true
            }),
            new FormulaireItem('criprocleint', {
                largeur: 33,
                ignoreEtat: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_STRPRO'),
            new FormulaireItem('crist1cleint', {
                largeur: 33,
                ignoreEtat: true
            }),
            new FormulaireItemSautDeLigne(),
            
        ]),
        colonnesFixesGauche: [{ nom: 'procod', largeur: 150 }],
        colonnesVisibles: [
            { nom: 'prodsc', largeur: 300 },
            { nom: 'sld_deb', largeur: 150, total: true },
            { nom: 'var01', largeur: 150, total: true },
            {
                nom: 'var02', largeur: 150, total: () => false,
                hidden: (data: any) => (vm.multiOccurrence.etatSelected.criteresSuggeresData.flgvar02 == '1')
            },
            {
                nom: 'var03', largeur: 179, total: true,
                hidden: (data: any) => (vm.multiOccurrence.etatSelected.criteresSuggeresData.flgvar03 == '1')
            },
            {
                nom: 'var04', largeur: 150, total: true,
                hidden: (data: any) => (vm.multiOccurrence.etatSelected.criteresSuggeresData.flgvar04 == '1')
            },
            {
                nom: 'var05', largeur: 150, total: true,
                hidden: (data: any) => (vm.multiOccurrence.etatSelected.criteresSuggeresData.flgvar05 == '1')
            },
            {
                nom: 'var06', largeur: 150, total: true,
                hidden: (data: any) => (vm.multiOccurrence.etatSelected.criteresSuggeresData.flgvar06 == '1')
            },
            { nom: 'fimcod', largeur: 150 },
            { nom: 'fimdsc', largeur: 200 },
            { nom: 'magcod', largeur: 150 },
            { nom: 'magnom', largeur: 300 },
        ],
        colonnesFixesDroite: [
            { nom: 'sld_fin', largeur: 150, total: true },
        ]
    };
    function $oninit() {
        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.crimoisdeb', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });

        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.criannee', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });
    }
}
