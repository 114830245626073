import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IData} from '../../../../../core/services/data-linker.service';

interface IPageMr0255 extends IComponentController {
    valeursEcran: any;
    formulaireEtv: IFormulaire;
    on1MultiOccurrence: IMultiOccurrence;    
    on1MultiOccurrenceOptions: IMultiOccurrenceOptions;
    on2MultiOccurrence: IMultiOccurrence;    
    on2MultiOccurrenceOptions: IMultiOccurrenceOptions;
    on3MultiOccurrence: IMultiOccurrence;    
    on3MultiOccurrenceOptions: IMultiOccurrenceOptions;
    on4MultiOccurrence: IMultiOccurrence;    
    on4MultiOccurrenceOptions: IMultiOccurrenceOptions;
}


/* @ngInject */
export default function Mr0255Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMr0255 = this;
    vm.$onInit = $onInit;

    
    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        // Formulaire principal (Titre d'emploi)
        vm.formulaireEtv = new Formulaire('LBL_BLC_ETV', [
            new FormulaireItem('etvnumdoc'),
            new FormulaireItem('etvdatvig'),
            new FormulaireItem('evycleint'),
            new FormulaireItem('ttccleint'),
            new FormulaireItem('temcleint', {resourceParams: (data: IData) => (
                {
                    trncleint: data.trncleint,
                    evycleint: data.evycleint
                }
                )}),
            new FormulaireItem('aut__cnpcod'),
            new FormulaireItem('etvptstot'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('evpcleint'),            
            new FormulaireItem('vacetvprf'),
            new FormulaireItem('aut__essnum'),
            new FormulaireItem('geccleint', {resourceParams: (data: IData) => (
                {
                    trncleint: data.trncleint,
                    temcleint: data.temcleint,
                    evycleint: data.evycleint,
                    etvdatvig: data.etvdatvig
                }
                )}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etvcom')
        ]);
        
        // Onglet 1
        vm.on1MultiOccurrenceOptions = {
            bloc: 'on1',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'eyddsc'   , largeur: 130},
                {nom: 'etvnivfac', largeur: 130},
                {nom: 'etvptsfac', largeur: 130, total: true},
                {nom: 'etvdscfac', largeur: 225}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('eyddsc'   , {largeur: 20} ),
                new FormulaireItem('etvnivfac', {largeur: 20}),
                new FormulaireItem('etvptsfac', {largeur: 20}),
                new FormulaireItem('etvdscfac', {largeur: 40})
            ], {largeurDefaut: 25})
        };

        // Onglet 2
        vm.on2MultiOccurrenceOptions = {
        bloc: 'on2',
        fonctions: {
          importation: true
        },
        colonnesVisibles: [
            {nom: 'eyddsc'   , largeur: 130},
            {nom: 'etvnivfac', largeur: 130},
            {nom: 'etvptsfac', largeur: 130, total: true},
            {nom: 'etvdscfac', largeur: 225}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('eyddsc'   , {largeur: 20}),
                new FormulaireItem('etvnivfac', {largeur: 20}),
                new FormulaireItem('etvptsfac', {largeur: 20}),
                new FormulaireItem('etvdscfac', {largeur: 40})
           ])
        };

    // Onglet 3
    vm.on3MultiOccurrenceOptions = {
        bloc: 'on3',
        fonctions: {
          importation: true
        },
        colonnesVisibles: [
            {nom: 'eyddsc'   , largeur: 130 },
            {nom: 'etvnivfac', largeur: 130},
            {nom: 'etvptsfac', largeur: 130, total: true},
            {nom: 'etvdscfac', largeur: 225}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('eyddsc'   , {largeur: 20}),
                new FormulaireItem('etvnivfac', {largeur: 20}),
                new FormulaireItem('etvptsfac', {largeur: 20}),
                new FormulaireItem('etvdscfac', {largeur: 40})
            ])
        };

    // Onglet 4
    vm.on4MultiOccurrenceOptions = {
        bloc: 'on4',
        fonctions: {
          importation: true
        },
        colonnesVisibles: [
            {nom: 'eyddsc'   , largeur: 130},
            {nom: 'etvnivfac', largeur: 130},
            {nom: 'etvptsfac', largeur: 130, total: true},
            {nom: 'etvdscfac', largeur: 225}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('eyddsc'   , {largeur: 20}),
                new FormulaireItem('etvnivfac', {largeur: 20}),
                new FormulaireItem('etvptsfac', {largeur: 20}),
                new FormulaireItem('etvdscfac', {largeur: 40})
            ])
        };

    }
}
