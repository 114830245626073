import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr2003 extends IComponentController {
    formulaireCld: IFormulaire;
}

/* @ngInject */
export default function Cr2003Controller(
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr2003 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('cienomabr', { largeur: 100, titre: ' ' }),
            new FormulaireItem('cieadr1', { largeur: 100, titre: ' ', hidden: (data: any) => !data.cieadr1 }),
            new FormulaireItem('cieadr2', { largeur: 100, titre: ' ', hidden: (data: any) => !data.cieadr2 }),
            new FormulaireItem('cieadr3', { largeur: 100, titre: ' ', hidden: (data: any) => !data.cieadr3 }),
            new FormulaireItem('cieadr4', { largeur: 100, titre: ' ', hidden: (data: any) => !data.cieadr4 }),
            new FormulaireItem('cldnomprn'),
            new FormulaireItem('cldcod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cesmnt'),
            new FormulaireItem('cesdat_2'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('moedsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etydsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cesnumdoc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cesdsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('signature'),
            new FormulaireItem('sysdat')
        ])
    };

}
