import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageas2011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As2011Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    MenuItemForage: IMenuItemForageClass,
    rr0006Route: IRoute) {
    const vm: IPageas2011 = this;

    // Forage vers l'affectation
    const forageAft = new MenuItemForage(
        'LBL_FOR_AFT',
        rr0006Route.NAME,
        (data:any) => ({
            id: data.trncleint_aft
        }),
        { hidden: (data: any) => !data.trncleint_aft,
            fonction:'AFFECT' }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: 
            new Formulaire([
                new FormulaireItem('typchgcri', { default: $stateParams.typchg, readonly: true }),
                new FormulaireItem('ttccleintcri', { default: $stateParams.ttccleint, hidden: true }),
                new FormulaireItem('datvigcri', { default: $stateParams.datvig, hidden: true })
        ]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 130 },
            { nom: 'aftcod', largeur: 130, menu:[forageAft] },
            { nom: 'temcod', largeur: 130 },
            { nom: 'posnumdoc', largeur: 130 },
            { nom: 'ttccod', largeur: 190 },
            { nom: 'datdebfin', largeur: 180 },
            { nom: 'vaesyscaptem_dsc', largeur: 150, hidden: () => $stateParams.typchg === 'AFT' },
            { nom: 'hhsdatdeb', largeur: 130, hidden: () => $stateParams.typchg === 'ASH' },
            { nom: 'hhsdatfin', largeur: 130, hidden: () => $stateParams.typchg === 'ASH' },
            { nom: 'numtrs', largeur: 150 },
            { nom: 'ecldsceta', largeur: 130 },
            { nom: 'msg', largeur: 300 , hidden: () => $stateParams.typchg === 'ASH'}
        ],
        colonnesCachees: ['trncleint_aft']
    };
}

