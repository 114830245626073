import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';

interface IPageAs0005 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    formulaireAct: IFormulaire;
    formulaireParam: IFormulaire;
    formulaireEmp: IFormulaire;
    uteMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aaaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    uarMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aqbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    uauMultiOccurrenceOptions: IMultiOccurrenceOptions;
    athMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0005Controller(
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass
) {
    const vm: IPageAs0005 = this;

    vm.menuBandeau = new Menu([]);
    vm.valeursEcran = ecranDetails.valeurs;
    vm.valeursEcran.flgesv1aff;
    vm.valeursEcran.flgesv2aff;
    vm.valeursEcran.flgesv3aff;
    vm.valeursEcran.flgesv4aff;
    vm.valeursEcran.flgesv5aff;
    // Code de temps
    vm.formulaireAct = new Formulaire([
        new FormulaireItem('actcod', { largeur: 33 }),
        new FormulaireItem('actdsc', { largeur: 33 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('actcodabr', { largeur: 33 }),
        new FormulaireItem('actdscabr', { largeur: 33 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('taycleint', {
            required: true,
            largeur: 33
        }),
        new FormulaireItem('unccleint', { largeur: 33 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItemSautDeLigne(),
        new FormulaireGroupeAccordeon(
            'LBL_BLC_EMP',
            new Formulaire([
                new FormulaireItem('actseqaffemp', { largeur: 33 }),
                new FormulaireItem('actflgcomobl', {
                    largeur: 33
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('actdscemp', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('actexpemp', {
                    largeur: 100
                })
            ])
            , { opened: true }),
    ]);
    // Dates
    vm.formulaireParam = new Formulaire([
        new FormulaireItem('actdatdeb'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('actdatfin')
    ]);
    // Présentation solde de banque
    vm.aqbMultiOccurrenceOptions = {
        fonctions: {
            // importation: true
        },
        colonnesVisibles: [
            { nom: 'bqecod', largeur: 120 },
            { nom: 'bqedsc', largeur: 650 }
        ],
        colonnesCachees: ['bqecleint'],
        formulaire: new Formulaire([
            new FormulaireItem('bqecleint', {
                largeur: 50,
                nombreLibellesDetailsCacher: 2
            })
        ])
    };
    // Regroupement d'horaire
    vm.aaaMultiOccurrenceOptions = {
        colonnesFixesGauche: [{ nom: 'asgcod', largeur: 185 }],
        colonnesVisibles: [
            { nom: 'asgdsc', largeur: 365 },
            { nom: 'aaaflgact', largeur: 65 },
            { nom: 'aaaflgpri', largeur: 80 },
            { nom: 'aaaflgatf', largeur: 130 },
            { nom: 'aaaflgvercon', largeur: 130 },
            { nom: 'aaaflgabsaut', largeur: 150 },
            { nom: 'aaaflgsaiact', largeur: 150 },
            { nom: 'aaaflgsaipjb', largeur: 150 },
            { nom: 'aaaflgpjbobl', largeur: 150 },
            { nom: 'tpicod', largeur: 115 },
            { nom: 'tpidsc', largeur: 115 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('asgcleint', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aaaflgact', {
                default: 1,
                largeur: 33
            }),
            new FormulaireItem('aaaflgpri', {
                largeur: 33
            }),
            new FormulaireItem('aaaflgatf', {
                largeur: 33
            }),
            new FormulaireItem('aaaflgvercon', {
                largeur: 33
            }),
            new FormulaireItem('aaaflgabsaut', {
                largeur: 33
            }),
            new FormulaireItem('aaaflgsaiact', {
                largeur: 33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aaaflgsaipjb', {
                largeur: 33
            }),
            new FormulaireItem('aaaflgpjbobl', {
                largeur: 33
            }),
            new FormulaireItem('tpicleint', { largeur: 33 }),
        ])
    };

    // Théme de temps
    vm.athMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'athcod', largeur: 185 },
            { nom: 'athdsc', largeur: 365 },
            { nom: 'vaeuthtra_desc', largeur: 250 }
        ]
    };
}
