import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
interface IPageMr0197 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0197Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0197 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'stadsc', largeur: 250 },
            { nom: 'raedatvig', largeur: 150 },
            { nom: 'rsccleint', largeur: 240 },
            { nom: 'raeflgexcact', largeur: 25 },
            { nom: 'rsccleintavs', largeur: 240 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('stamnetab', { largeur: 50 }),
            new FormulaireItem('raedatvig', { largeur: 50 }),
            new FormulaireItem('rsccleint', { largeur: 50 }),
            new FormulaireItem('rsccleintavs', { largeur: 50 })
        ])
    };
}
