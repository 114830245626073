import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";

interface IPageGL0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0011Controller(gl0039Route: IRoute,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Operateur: IOperateurService,
    MenuItemForage: IMenuItemForageClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass) {
    const vm: IPageGL0011 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gl0039Route.NAME,
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/gl0011/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        actionsMoreLigne: new Menu([new MenuItemActionTelechargement(
            'LBL_FICHIER',
            'BOUTELPIJ',
            () => ({}),
            {
                iconButton: true,
                icon: 'attach_file',
                hidden: (data: any) => !data.pijcleint
            }
        )]),
        actionsNouveaux: new Menu([
            new MenuItemForage(gl0039Route.NAME)
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_DETRUIRE', 'BOUDET', {
                selection: {
                    nom: 'destruction'
                },
                fonction: 'BOUDET'
            })
        ]),
        filtres: [
            {
                colonne: 'pejflgcou',
                operateur: Operateur.EGALE,
                valeur: '1'
            }
        ],
        colonnesVisibles: [
            { nom: 'gfenumchg', largeur: 136 },
            { nom: 'gfesrc', largeur: 300 },
            { nom: 'gfeflg', largeur: 120 },
            { nom: 'gfeflgecjerr', largeur: 120 },
            { nom: 'gfeflgecverr', largeur: 120 },
            { nom: 'gfeflgdoierr', largeur: 120 },
            { nom: 'gfenbrerr', largeur: 126 },
            { nom: 'gfenbrtot', largeur: 126 },
            { nom: 'gfemntdbt', largeur: 126 },
            { nom: 'gfemntcrt', largeur: 126 }
        ],
        colonnesCachees: ['pejcleint', "pijcleint"]
    };
}
