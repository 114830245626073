import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0037 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0037Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0037 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'dexcod', largeur: 120 },
            { nom: 'dexdsc', largeur: 180 },
            { nom: 'dexdatdeb', largeur: 126 },
            { nom: 'dexdatfin', largeur: 126 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dexcod', { largeur: 20 }),
            new FormulaireItem('dexdsc', { largeur: 40 }),
            new FormulaireItem('dexdatdeb', { largeur: 20 }),
            new FormulaireItem('dexdatfin', { largeur: 20 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
