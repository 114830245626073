import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0306 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0306Controller(mr0101Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite) {
        
    const vm: IPageMr0306 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0101Route.NAME,
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/mr0306/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0101Route.NAME)
        ]),
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'temcod', largeur: 100 },
            { nom: 'temdsc', largeur: 250 },
            { nom: 'temdscabr', largeur: 200 },
            { nom: 'temdscfem', largeur: 200 },
            { nom: 'temdscmsc', largeur: 200 },    
            { nom: 'grecod', largeur: 120 },
            { nom: 'gredsc', largeur: 250 },
            { nom: 'temhreheb', largeur: 150 },
            { nom: 'temcodref', largeur: 130 },
            { nom: 'temdscabrref', largeur: 250 },
            { nom: 'temflgprfper', largeur: 150 },
            { nom: 'temflgetu', largeur: 100 },
            { nom: 'vaenivsco', largeur: 100 },
            { nom: 'vaenivsco_desc', largeur: 175 },
            { nom: 'temflguno', largeur: 110 },
            { nom: 'temflgfdsres', largeur: 105 },
            { nom: 'flgfnc', largeur: 100 },
            { nom: 'flgtch', largeur: 100 },
            { nom: 'flgcom', largeur: 120 },
            { nom: 'flgpae', largeur: 100 },
            { nom: 'flgcnp', largeur: 100 },
            { nom: 'temflgact', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('temcod'),
            new FormulaireItem('temdsc'),
            new FormulaireItem('temdscabr'),
            new FormulaireItem('temdscfem'),
            new FormulaireItem('temhreheb'),
            new FormulaireItem('grecleint'),
            new FormulaireItem('temdscmsc'),
            new FormulaireItem('temflgact'),
            new FormulaireItem('temflgetu'),
            new FormulaireItem('vaenivsco'),
            new FormulaireItem('temflguno'),
            new FormulaireItem('temflgfdsres'),
        ]),
    };
}
