import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageCP0052 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0052Controller(Menu: IMenuClass,
                                         MenuItem: IMenuItemClass) {
    const vm: IPageCP0052 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([
            new Menu('G_LBL_BTN_NOUVEAU', [
                new MenuItem('BTN_DEMANDE_PAIEMENT', function() {}, {
                    icon: 'add_box'
                }),
                new MenuItem('BTN_LIB_RET', function() {}, {
                    icon: 'add_box'
                }),
                new MenuItem('BTN_LIB_RET_SPEC', function() {}, {
                    icon: 'add_box'
                })
            ])
        ]),
        colonnesVisibles: [
            {nom: 'reqnumdoc', largeur: 200},
            {nom: 'reqnumdocseq', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnomabr', largeur: 200},
            {nom: 'radcod', largeur: 200},
            {nom: 'pipnumdoc', largeur: 200},
            {nom: 'pipnumref', largeur: 200},
            {nom: 'pplnumdoc', largeur: 200},
            {nom: 'reqnumref', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'reqdat', largeur: 200},
            {nom: 'etddsc', largeur: 200},
            {nom: 'docref_int', largeur: 200},
            {nom: 'flgmul', largeur: 200},
            {nom: 'docaffflgattrep01', largeur: 200},
            {nom: 'reqflgmsg', largeur: 200},
            {nom: 'flgencours', largeur: 200},
            {nom: 'flgdu', largeur: 200},
            {nom: 'nbrajt', largeur: 200},
            {nom: 'lcpcod', largeur: 200},
            {nom: 'lcpdsc', largeur: 200},
            {nom: 'vaesysdevcod', largeur: 200},
            {nom: 'reqmnt', largeur: 200},
            {nom: 'dccmntpay', largeur: 200},
            {nom: 'intcodreq', largeur: 200},
            {nom: 'intnomreq', largeur: 200},
            {nom: 'intcoddem', largeur: 200},
            {nom: 'intnomdem', largeur: 200},
            {nom: 'pdfcod', largeur: 200},
            {nom: 'prjdscabr', largeur: 200},
            {nom: 'vecreqcat', largeur: 200},
            {nom: 'reqnumrescd', largeur: 200},
            {nom: 'reqnumresca', largeur: 200},
            {nom: 'reqdsc', largeur: 200},
            {nom: 'usrcod', largeur: 200},
            {nom: 'reqnomleg1', largeur: 200},
            {nom: 'reqnomleg2', largeur: 200}
        ]
    };
}
