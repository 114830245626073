
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';

interface IPageIn0045 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function In0045Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,) {
    const vm: IPageIn0045 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccoddeb'),
            new FormulaireItem('peccodfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('magcleint'),
            new FormulaireItem('tyvcleint', { lovSelectionMultiple: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintreq', { lovSelectionMultiple: true }),
            new FormulaireItem('intcleintdem', { lovSelectionMultiple: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint'),
            new FormulaireItem('procleint'),
            new FormulaireItemDivider('LBL_BLC_VAR'),
            new FormulaireItem('st1cod')
        ]),
        colonnesFixesGauche: [
            { nom: 'dinnumdoc', largeur: 100 },
            { nom: 'dinnumdocseq', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 150 },
            { nom: 'tyddsc', largeur: 150 },
            { nom: 'peccod', largeur: 150 },
            { nom: 'magcod', largeur: 150 },
            { nom: 'magnom', largeur: 150 },
            { nom: 'tyvdsc', largeur: 150 },
            { nom: 'refcod', largeur: 150 },
            { nom: 'refnom', largeur: 150 },
            { nom: 'intcodreq', largeur: 150 },
            { nom: 'intnomreq', largeur: 150 },
            { nom: 'intcoddem', largeur: 150 },
            { nom: 'intnomdem', largeur: 150 },
            { nom: 'dinmnt', largeur: 150 },
            { nom: 'dinnumseq', largeur: 150 },
            { nom: 'procod', largeur: 150 },
            { nom: 'prodsc', largeur: 150 },
            { nom: 'diiqtemov', largeur: 150 },
            { nom: 'diiprxunimov', largeur: 150 },
            { nom: 'forcod', largeur: 150 },
            { nom: 'fordsc', largeur: 150 },
            { nom: 'diiprxtot', largeur: 150 }
        ],
        fonctions: {
            rechercheCollapsable: false
        }
    };
}