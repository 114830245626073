import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageRr0515 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    patMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}



/* @ngInject */
export default function Rr0515Controller(Menu: IMenuClass, MenuItem: IMenuItemClass) {
    const vm: IPageRr0515 = this;


    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'unpcod', largeur: 150 }
        ],
        colonnesFixesDroite: [
            { nom: 'nbr_feuille', largeur: 150 },
            { nom: 'nbr_total', largeur: 84  },
            { nom: 'nbr_annule', largeur: 80 },
            { nom: 'nbr_non_officiel', largeur: 96 },
            { nom: 'nbr_imprime', largeur: 96 },
            { nom: 'nbr_non_imprime_0', largeur: 96  },
            { nom: 'nbr_tfe', largeur: 96 },
            { nom: 'nbr_non_tfe_0', largeur: 96 }
        ],
        colonnesVisibles: [
            { nom: 'unpdsc', largeur: 250 },
            { nom: 'mopcod', largeur: 150 },
            { nom: 'mopdsc', largeur: 200 },
            { nom: 'ppacod', largeur: 150 }

        ],
        colonnesCachees: [
            'ciecleint',
            'unpcleint',
            'ppacleint',
            'mopcleint'
        ]


    };



}
