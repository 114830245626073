import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTP0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tp0006Controller(Menu: IMenuClass) {
    const vm: IPageTP0006 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'ktycod', largeur: 200},
            {nom: 'ktytit', largeur: 200},
            {nom: 'vaektytyp_dsc', largeur: 200},
            {nom: 'ktyseqaff', largeur: 200},
            {nom: 'ktyflgact', largeur: 200},
            {nom: 'ktyflgtrm', largeur: 200},
            {nom: 'ktyflgtrmobl', largeur: 200},
            {nom: 'ktyflgcrs', largeur: 200},
            {nom: 'ktyflgcrsobl', largeur: 200},
            {nom: 'ktyflgcof', largeur: 200},
            {nom: 'ktyflgcofobl', largeur: 200},
            {nom: 'ktyflgetu', largeur: 200},
            {nom: 'ktyflgetuobl', largeur: 200},
            {nom: 'ktyflgter', largeur: 200},
            {nom: 'ktyflgseq', largeur: 200},
            {nom: 'ktyflgprg', largeur: 200},
            {nom: 'ktyflgincpas', largeur: 200}
        ]
    };
}
