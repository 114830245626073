import {IComponentController} from 'angular';
import {IEcranContextController} from '../../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';


interface IPageMc2047 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    trsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ecranContextCtrl: IEcranContextController;   
}

/* @ngInject */
export default function Mc2047Controller()  {
    const vm: IPageMc2047 = this;

    vm.monoOccurrenceOptions = {};
 
        // Multi Étapes
        vm.trsMultiOccurrenceOptions = {
            fonctions: {
                rechercheCollapsable: false
            },            
            colonnesVisibles: [
                { nom: 'peccod'   , largeur: 84 },
                { nom: 't_imp'    , largeur: 240 },
                { nom: 'tyhdsc'   , largeur: 140 },
                { nom: 'trbmnt'   , largeur: 126, total: true },
                { nom: 'prudscl01', largeur: 230},
                { nom: 'cptdsc'   , largeur: 230}
            ]        
        };

}
