import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';

interface IPageRr0100 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0100Controller(rr2017Route: IRoute,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    Operateur: IOperateurService,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0100 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr2017Route.NAME)
        ]), navigatePage: rr2017Route.NAME,
        colonnesVisibles: [
            { nom: 'chpnumcha', largeur: 150 },
            { nom: 'chpficsou', largeur: 150 },
            { nom: 'datcre', largeur: 120 },
            { nom: 'usrnomcre', largeur: 200 },
            { nom: 'chpnbrlig', largeur: 120 },
            { nom: 'chpnbrerr', largeur: 120 },
            { nom: 'chpflgval', largeur: 100 },
            { nom: 'chpflggen', largeur: 100 }
        ]
    };
}
