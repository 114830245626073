import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageAC0048 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Ac0048Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass
) {
    const vm: IPageAC0048 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            supprime: false
        },
        colonnesVisibles: [
            { nom: 'nibcod', largeur: 90 },
            { nom: 'nibnom', largeur: 262 },
            { nom: 'nibdatdeb', largeur: 100 },
            { nom: 'nibdatfin', largeur: 100 },
            { nom: 'nibdsc', largeur: 410 }
        ],
        colonnesCachees: ['nibcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('nibcod'),
            new FormulaireItem('nibnom'),
            new FormulaireItem('nibdatdeb'),
            new FormulaireItem('nibdatfin'),
            new FormulaireItem('nibdsc', { enableCount: true })
        ], { largeurDefaut: 50 }),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_ANNULER',
                'BOUNIBANN',
                () => ({}),
                {
                    icon: 'cancel',
                    iconButton: true,
                    messageConfirmation: 'BOUANN_CONFIRM',
                    successAction: () => {
                        vm.multiOccurrence && vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ])
    };
}
