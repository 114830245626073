import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
interface IPageMr0335 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
    formulaireBvl: IFormulaire;
    bteMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0335Params {
    bpacleint: number;
    bqecleint: number;
    vaebvltyplim: string;
}

/* @ngInject */
export default function Mr0335Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IMr0335Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMr0335 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        const lblTitreBloc = ($stateParams.vaebvltyplim === 'MIN') ? 'LBL_BLC_BVLMIN' : 'LBL_BLC_BVLMAX';

        // Limite
        vm.formulaireBvl = new Formulaire(lblTitreBloc, [
            new FormulaireItem('vaebvltyplim', {default: $stateParams.vaebvltyplim}),
            new FormulaireItem('bvacleint', {largeur: 100}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('bvlval', {largeur: 20}),
            new FormulaireItem('vaebvltyplig'),
            new FormulaireItem('tagcleint', {
                resourceParams: (data:any) => {
                    return {
                        bqecleint: $stateParams.bqecleint
                    };
                }
            }),
            new FormulaireItem('bvlmsg', {largeur:100, enableCount: true})
        ], {largeurDefaut: 30});

        // Correspondance TAG d'exception
        vm.bteMultiOccurrenceOptions = {
            bloc: 'bte',
            fonctions: {
                pagination: true
            },
            // Formulaire de saisie
            formulaire: new Formulaire([
                new FormulaireItem('bvlcleint', {default: () => vm.monoOccurrence.id }),
                new FormulaireItem('tagcleintori', {
                    resourceParams: (data:any) => {
                        return {
                            bqecleint: $stateParams.bqecleint
                        };
                    }
                }),
                new FormulaireItem('tagcleintrpl'),
                new FormulaireItem('tagcleintexc'),
                new FormulaireItem('btefct', {default: 1})
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'tagcod_ori', largeur: 120},
                {nom: 'tagdscabr_ori', largeur: 240},
                {nom: 'tagcod_rpl', largeur: 160},
                {nom: 'tagdscabr_rpl', largeur: 300},
                {nom: 'tagcod_exc', largeur: 140},
                {nom: 'tagdscabr_exc', largeur: 240},
                {nom: 'btefct', largeur: 131},
            ]
        };
    }
}
