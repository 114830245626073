import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterDate } from 'angular';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
interface IPageCR1007 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCSCOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr1007Controller(Menu: IMenuClass,
    $stateParams: any,
    Formulaire: IFormulaireClass,
    MenuItemAction: IMenuItemActionClass,
    dateFilter: IFilterDate,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCR1007 = this;

    vm.multiOccurrenceCSCOptions = {
        bloc: 'csc',
        fonctions: {
            nouveau: false
        },               
        colonnesVisibles: [
            { nom: 'vaedhcpro_dsc', largeur: 130 },
            { nom: 'cscadrcou', largeur: 202 },
            { nom: 'intcod', largeur: 150 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'usrcoda', largeur: 150 },
            { nom: 'usrnoma', largeur: 200 }
        ],
        colonnesCachees: ['coscleint']
    };

    vm.multiOccurrenceOptions = {
        colonnesCachees: ['coscleint', 'coccleint'],
        filtresParametres: [
            { colonne: 'coccleint', valeur: $stateParams.coccleint, visible: false }
        ],
        fonctions: {
            rechercheCollapsable: false
        },        
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_SUIVI',
                'BOUSUI',
                (data: any) => ({
                    coccleint: $stateParams.coccleint
                }),
                {
                    icon: 'library_add',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_SUIVI',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('vaetypcos', { required: true }),
                            new FormulaireItem('cosnot', { required: true }),
                            new FormulaireItem('cosdatsui', { largeur: 25, default: dateFilter(new Date(), 'yyyy-MM-dd'), required: true }),
                            new FormulaireItem('cosdatprosui', { largeur: 25, required: true }),
                            new FormulaireItem('flgini', { largeur: 25 }),
                            new FormulaireItem('flgreq', { largeur: 25 }),
                            new FormulaireItem('intcleint'),
                            new FormulaireItem('adrcou')
                        ], { largeurDefaut: 100 })
                    },
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'cosnumseq', largeur: 89 },
            { nom: 'cosdatsui', largeur: 110 },
            { nom: 'cosnot', largeur: 165 },
            { nom: 'flgcou', largeur: 110 },
            { nom: 'usrcod', largeur: 89 },
            { nom: 'usrnom', largeur: 218 },
            { nom: 'vaetypcos_dsc', largeur: 160 },
            { nom: 'cosdatprosui', largeur: 110 }
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCSCOptions
    };
}
