import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageRr0520 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0520Controller(
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    rr0552Route: IRoute
) {
    const vm: IPageRr0520 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0552Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(rr0552Route.NAME)]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 190 },
            { nom: 'unpcod', largeur: 90 },
            { nom: 'ppacod', largeur: 90 },
            { nom: 'ppadatdeb', largeur: 100 },
            { nom: 'ppadatfin', largeur: 100 },
            { nom: 'dxcflgall', largeur: 95 }
        ]
    };
}
