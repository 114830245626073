import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGs0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0003Controller(  Formulaire: IFormulaireClass ,   
                                           FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 80 },
            { nom: 'usrnom', largeur: 80 },
            { nom: 'suedat', largeur: 80 },
            { nom: 'sueprv', largeur: 150 },
            { nom: 'suedsc', largeur: 780 }
        ]
    };
}
