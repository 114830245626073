import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAe0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ae0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAe0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions:({
            importation: true
        }),       
        colonnesVisibles: [
            {nom: 'rgecod', largeur: 120},
            {nom: 'rgedsc', largeur: 350},
            {nom: 'rgeflgact', largeur: 80}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rgecod', {largeur: 25, required: true}),
            new FormulaireItem('rgedsc', {largeur: 50, required: true}),
            new FormulaireItem('rgeflgact', {largeur: 25, default: 1})
        ])
    };
}
