import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IStateParamsService} from "angular-ui-router";
import {IOperateurService} from "../../../../../core/services/operateur.service";

interface IPageGS0114 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0114Controller($stateParams: IStateParamsService,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass) {
    const vm: IPageGS0114 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },
        filtres: [
            {
                colonne: 'surcleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.id,
                visible: false
            }
        ],
        colonnesVisibles: [
            {nom: 'suudscerr', largeur: 800}
        ]
    };
}
