import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCR0038 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0038Controller($stateParams: any,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    cr0039Route: IRoute) {
    const vm: IPageCR0038 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cr0039Route.NAME,
        fonctions: {
            rechercheCollapsable: false,
        },
        filtresParametres: [
            { colonne: 'clicleint', valeur: $stateParams.clicleint, visible: false }
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cldcod', {required: false}),
            new FormulaireItem('cldnomprn'),
            new FormulaireItem('cldnomabr', {required: false})
        ]),
        colonnesVisibles: [
            { nom: 'cldcod', largeur: 110 },
            { nom: 'cldnom', largeur: 180 },
            { nom: 'cldprn', largeur: 180 },
            { nom: 'cldnomabr', largeur: 180 },
            { nom: 'cldflgstu', largeur: 100 },
            { nom: 'clicod', largeur: 110 },
            { nom: 'clinom', largeur: 180 },
            { nom: 'econumdoc', largeur: 140 }
        ]
    };
}
