import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGs0117 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireEcr: IFormulaire;
    ecpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    efgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0117Controller(Menu: IMenuClass,
                                         gs0118Route: IRoute,
                                         gs0121Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGs0117 = this;

    const accueilRoute = {NAME: 'secure.accueil'};

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {}

        vm.formulaireEcr = new Formulaire('LBL_BLC_ECR', [
            new FormulaireItem('ecrcod', {largeur: 20, modelOptions:{updateOn: 'blur'}}),
            new FormulaireItem('srccleint', {largeur: 30}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecrdsc'),
            new FormulaireItem('ecrdscmen'),
            new FormulaireItem('clscleint')
        ], {largeurDefaut: 50});

        vm.ecpMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'ecpcle', largeur: 100},
                {nom: 'ecpval', largeur: 180}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ecpcle'),
                new FormulaireItem('ecpval')
            ])
        };

        vm.menMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'LBL_FOR_TYP',
                    (data: any) => getForage(data),
                    (data: any) => ({id:data.mencleint})
                )]),
            colonnesCachees: ['mencleint', 'vaesysenttrt'],
            colonnesVisibles: [
                {nom: 'vaesysenttrt_dsc', largeur: 100},
                {nom: 'mendsc', largeur: 250},
                {nom: 'mendscmen', largeur: 250},
            ]
        };

        vm.efgMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'ecrcod', largeur: 110},
                {nom: 'ecrdsc', largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ecrcleintfor')
            ])
        };
    }

    function getForage(data: any): string {
        if (data.vaesysenttrt === 'MEN') {
            return gs0118Route.NAME;
        } else if (data.vaesysenttrt === 'MEC') {
            return gs0121Route.NAME;
        } else {
            return accueilRoute.NAME;
        }
    }
}
