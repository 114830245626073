import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0002Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAc0002 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'motcod', largeur: 50},
            {nom: 'motdsc', largeur: 187},
            {nom: 'motflgfax', largeur: 70},
            {nom: 'motflgxml', largeur: 70},
            {nom: 'motflgcou', largeur: 70}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('motcod', {required: true}),
            new FormulaireItem('motdsc', {required: true}),
            new FormulaireItem('motflgfax'),
            new FormulaireItem('motflgxml'),
            new FormulaireItem('motflgcou')
        ]),
        fonctions: {
            edition: true,
            nouveau: true,
            rechercheCollapsable: false
        }
    };
}
