import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCh0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ch0005Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCh0005 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        colonnesVisibles: [
            {nom: 'etqdsc', largeur: 262},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'actcod', largeur: 90},
            {nom: 'actdsc', largeur: 262},
            {nom: 'etqflgprs', largeur: 91},
            {nom: 'etqflgpub', largeur: 91}
        ],
        colonnesFixesGauche: [
            {nom: 'etqseq', largeur: 72},
            {nom: 'etqcod', largeur: 90}
        ],
        colonnesFixesDroite: [
            {nom: 'etqflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('etqseq', {
                required: true,
                largeur: 25
            }),
            new FormulaireItem('etqflgact', {
                default: 1,
                largeur: 25
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etqcod', {
                required: true,
                largeur: 25
            }),
            new FormulaireItem('etqdsc', {
                required: true,
                largeur: 25
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temcleint', {largeur: 50}),
            new FormulaireItem('actcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etqflgprs', {
                default: 0,
                largeur: 25
            }),
            new FormulaireItem('etqflgpub', {
                default: 0,
                largeur: 25
            })
            ]
        )
    };
}
