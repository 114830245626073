import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {
    IFormulaire,
    IFormulaireClass
} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForage, IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IComponentController} from 'angular';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageExterneClass} from '../../../../../core/services/menu/menu-item-forage-externe.service';

interface IPageCP0054 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireTse: IFormulaire;
    formulairePfl: IFormulaire;
    formulaireDac: IFormulaire;
    formulaireCom: IFormulaire;
    toaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ftnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ctnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fccMultiOccurrenceOptions: IMultiOccurrenceOptions;
    btnMenus: Array<IMenuItemForage>;
}
interface ICp0054Params {
    id: string;
    menuId: string;
    trncleint: string;
}


/* @ngInject */
export default function Cp0054Controller(cp0005Route: IRoute,
                                         cp0007Route: IRoute,
                                         cp0009Route: IRoute,
                                         cp0070Route: IRoute,
                                         cp0071Route: IRoute,
                                         mc0046Route: IRoute,
                                         mc0144Route: IRoute,
                                         $stateParams: ICp0054Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Menu: IMenuClass,
                                         MenuItemForageExterne: IMenuItemForageExterneClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass)  {

    const vm: IPageCP0054 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

            vm.btnMenus = [
                new MenuItemEcran('LBL_BTN_DONNEES',
                    mc0144Route.NAME,
                    () => ({
                        id: vm.valeursEcran.dfbcleint,
                        tsfcleint: vm.monoOccurrence.data.tsfcleint
                    }),
                    {
                        icon: 'account_balance',
                        fonction:'BTN_DONNEES',
                        iconButton: false,
                        hidden: () => !vm.monoOccurrence.data.tsfcleint
                    }
                )
            ]
        ;

        const forageFou = [
            new MenuItemForage(
                'BTN_FORAGE_FOU',
                cp0005Route.NAME,
                () => ({
                    id: vm.valeursEcran.refcleint
                }),
                { hidden: () => !vm.valeursEcran.foucod }
            )
        ];
        const menuItemSiteWeb = new MenuItemForageExterne(() => vm.monoOccurrence.data.tsfsitint, {hidden: () => !vm.monoOccurrence.data.tsfsitint});

        // Transaction fournisseur
        vm.formulaireTsf = new Formulaire('LBL_TSF', [
            new FormulaireItem('tsfnumdoc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_foucod', {default: vm.valeursEcran.foucod, menu: forageFou}),
            new FormulaireItem('tsfnom'),
            new FormulaireItem('tsffounomabr'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tsfnumneq'),
            new FormulaireItem('tsfnumtvq'),
            new FormulaireItem('tsfnumtps'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tfocleint'),
            new FormulaireItem('tsfsitint', {menu: [menuItemSiteWeb]}),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES', new Formulaire([
                new FormulaireItem('vaesyslng'),
                new FormulaireItem('fogcleint'),
                new FormulaireItem('ttccleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsfindfrsacc'),
                new FormulaireItem('tsfflgimptfe'),
                new FormulaireItem('tsfindadrpai'),
                new FormulaireItem('tsfnomdem'),
                new FormulaireItem('tsfdatdeb')
            ], {largeurDefaut: 33.33}))
        ], {largeurDefaut: 33.33});

        const foragePfl = [
            new MenuItemForage(
                'BTN_FORAGE_PFL',
                cp0007Route.NAME,
                () => ({
                    pflcleint: vm.monoOccurrence.data.pflcleint
                }),
                { hidden: () => !vm.monoOccurrence.data.pflcleint,
                fonction:'PROFIL_CAP'}
            )
        ];
        const forageMop = [
            new MenuItemForage(
                'BTN_FORAGE_PFL',
                cp0009Route.NAME,
                () => ({
                    mopcleint: vm.monoOccurrence.data.mopcleint
                }),
                { hidden: () => !vm.monoOccurrence.data.mopcleint,
                    fonction:'PAIE',
                    icon:'create'}
            )
        ];
        const forageMpf = [
            new MenuItemForage(
                'BTN_FORAGE_PFL',
                cp0009Route.NAME,
                (data: any) => ({
                    mopcleint: data.t_mopcleint
                }),
                { hidden: () => (vm.monoOccurrence.data.mopcleint || !vm.monoOccurrence.data.pflcleint),
                fonction:'PAIE',
                    icon:'create'}
            )
        ];
        const forageTax = [
            new MenuItemForage(
                'BTN_FORAGE_PFL',
                cp0007Route.NAME,
                () => ({
                    pflcleint: vm.monoOccurrence.data.pflcleint
                }),
                {
                    fonction:'TAXE',
                icon:'create'}
            )
        ];
        // Profil CAP
        vm.formulairePfl = new Formulaire('LBL_PFL', [
            new FormulaireItem('pflcleint', {menu: foragePfl}),
            new FormulaireItem('t_mopcoddsc', {menu: forageMpf}),
            new FormulaireItem('mopcleint', {menu: forageMop}),
            new FormulaireItem('t_taxdscfmt',{menu: forageTax}),
            new FormulaireItem('taxcleint')
        ], {largeurDefaut: 33.33, menu: new Menu([
                new MenuItemEcran(
                    'LBL_IMPUTATION',
                    mc0046Route.NAME,
                    (data: any) => {
                        return {
                            vaeusient: 'PFL',
                            uvicleintref: data.pflcleint
                        };
                    },
                    {
                        icon: 'view_list',
                        iconButton: false,
                        fonction:'BOUIMP'
                    }
                )
            ])});
        // Adresse
        vm.toaMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    '',
                    cp0070Route.NAME,
                    (rowData:any) => ({
                        id: rowData.toacleint,
                        tsfcleint: vm.monoOccurrence.data.tsfcleint
                    }),
                    {
                        icon: 'launch',
                        fonction:'BOUOUV',
                        onClose() {
                            vm.toaMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    '',
                    cp0070Route.NAME, () => {
                        return {
                            tsfcleint: vm.monoOccurrence.data.tsfcleint,
                            trncleint: $stateParams.trncleint
                        };
                    },
                    {
                        onClose() {
                            vm.toaMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            bloc: 'toa',
            fonctions: {
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                pagination: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'toafmt', largeur: 200},
                {nom: 'toaflgpai', largeur: 91},
                {nom: 'toaadrcou', largeur: 240}
            ],
            colonnesCachees: [
                'toacleint',
                'toacod'
            ]
        };
        // Contacts
        vm.ftnMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    '',
                    cp0071Route.NAME,
                    (rowData:any) => ({
                        id: rowData.ftncleint,
                        tsfcleint: vm.monoOccurrence.data.tsfcleint
                    }),
                    {
                        icon: 'launch',
                        fonction:'BOUOUV',
                        onClose() {
                            vm.ftnMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    '',
                    cp0071Route.NAME, () => {
                        return {
                            tsfcleint: vm.monoOccurrence.data.tsfcleint,
                            trncleint: $stateParams.trncleint
                        };
                    }, {
                        onClose() {
                            vm.ftnMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            bloc: 'ftn',
            fonctions: {
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                pagination: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'ftnnom', largeur: 300},
                {nom: 'ftnnumtel', largeur: 131},
                {nom: 'ftntit', largeur: 131},
                {nom: 'ftnadrcou', largeur: 131}
            ],
            colonnesCachees: [
                'ftncleint'
            ]
        };

        // Profil D'achat
        vm.formulaireDac = new Formulaire('LBL_DAC', [
            new FormulaireItem('teccleint'),
            new FormulaireItem('motcleint'),
            new FormulaireItem('iaccleint'),
            new FormulaireItem('vecsysmodtra'),
            new FormulaireItem('vecsystercom'),
            new FormulaireItem('tsfdes'),
            new FormulaireItem('tsfmntlimcmd'),
            new FormulaireItem('tsfnbrjrs'),
            new FormulaireItem('tsfflgent'),
            new FormulaireItem('vecsysregadm'),
            new FormulaireItem('vaesysdacxml'),
            new FormulaireItemDivider('LBL_CARTE_ACHAT'),
            new FormulaireItem('tsfindpaicac'),
            new FormulaireItem('tsfmntmaxcac'),
            new FormulaireItem('teccleintdef'),
            new FormulaireItem('foccleintdef'),
            new FormulaireItem('fcacleintdef'),
            new FormulaireItem('t_fcanomdet'),
            new FormulaireItem('tsfnomrel')
        ], {largeurDefaut: 33.33});

        // Nibs
        vm.ctnMultiOccurrenceOptions = {
            bloc: 'ctn',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('nibcleint', {largeur: 50}),
                new FormulaireItem('ctndatdeb',{required: true,largeur:25}),
                new FormulaireItem('ctndatfin', {largeur: 25})
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'nibcod', largeur: 100},
                {nom: 'nibnom', largeur: 260},
                {nom: 'ctndatdeb', largeur: 126},
                {nom: 'ctndatfin', largeur: 126},
                {nom: 'nibdsc', largeur: 300}
            ]
        };

        // Catégorie d'achat
        vm.fccMultiOccurrenceOptions = {
            bloc: 'fcc',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('caacleint'),
                new FormulaireItem('fccflgpri', {default: 0})
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'caacod', largeur: 100},
                {nom: 'caadsc', largeur: 260},
                {nom: 'fccflgpri', largeur: 91}
            ]
        };

        // Communication
        vm.formulaireCom = new Formulaire('LBL_COMMUNICATION', [
            new FormulaireItem('tsfadrcou'),
            new FormulaireItem('adrcoumsg'),
            new FormulaireItem('tsfdacmsg'),
            new FormulaireItem('tsfinsdacmsg')
        ],
            {largeurDefaut: 100}

            );
    }
}
