import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageAc2005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac2005Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass
) {
                                            
    const vm: IPageAc2005 = this; 

    vm.multiOccurrenceOptions = {
        fonctions:{
            criteresRechercheCollapsable: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('dhacleint', {
                default: $stateParams.dhacleint,
                disabled: true
            })
        ]),
        colonnesVisibles: [
            {nom: 'dailig', largeur: 100},
            {nom: 'dhvimp', largeur: 300},
            {nom: 'dhvmnt', largeur: 130},
            {nom: 'taxcod', largeur: 100},
            {nom: 'remcod', largeur: 100},
            {nom: 'dhvmnttaxfed', largeur: 130},
            {nom: 'dhvmntremfed', largeur: 130},
            {nom: 'dhvmnttaxpro', largeur: 130},
            {nom: 'dhvmntrempro', largeur: 130},
            {nom: 'dhvmntdepnet', largeur: 130}
        ]
    };
}