import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePb0102 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0102Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         pb2000Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPagePb0102 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            boutonDupliquer: false
        },
        navigatePage: pb2000Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(pb2000Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'csaann'          , largeur: 120},
            {nom: 'csacom'          , largeur: 400}            
        ]
    };
}
