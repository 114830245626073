import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGR0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gr0004Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGR0004 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('recherche', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gsicleint', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('artcleint', {
                required: false
            })
        ]),
        colonnesVisibles: [
            {nom: 'gsicod', largeur: 99},
            {nom: 'gsidsc', largeur: 262},
            {nom: 'artcod', largeur: 158},
            {nom: 'artdscabr', largeur: 262},
            {nom: 'gainumdoc', largeur: 89},
            {nom: 'gaidat', largeur: 100},
            {nom: 'cvtcod', largeur: 103},
            {nom: 'cvtdsc', largeur: 262},
            {nom: 'gaidscabr', largeur: 262},
            {nom: 'gaidsc', largeur: 540},
            {nom: 'usrnom', largeur: 262},
            {nom: 'nbr_pjb', largeur: 54}
        ]
    };
}
