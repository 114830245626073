import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0067 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0067Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAc0067 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'molcod', largeur: 70 },
            { nom: 'moldsc', largeur: 280 },
            { nom: 'vaesysmodoct', largeur: 100 },
            { nom: 'molflgact', largeur: 70 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('molcod'),
            new FormulaireItem('moldsc'),
            new FormulaireItem('vaesysmodoct'),
            new FormulaireItem('molflgact', { default: 1 })
        ])
    };
}
