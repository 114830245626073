import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0001Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc001 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            supprime: false,
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('vecvalcle'),
            new FormulaireItem('vecdsc', {largeur: 50}),
            new FormulaireItem('vecflgact', {default: 1})
        ], {largeurDefaut: 25}),
        colonnesVisibles: [
            {nom: 'vecvalcle', largeur: 100},
            {nom: 'vecdsc', largeur: 262},
            {nom: 'vecflgact', largeur: 70},
        ]
    };
}
