import * as angular from 'angular';
import {IAugmentedJQuery, IDocumentService, module} from 'angular';
import {ISchemas} from '../../../core/interfaces/schemas.interface';
import {IDataTypeMap} from '../../../core/services/data-types/data-type.service';
import {IDialog} from '../../../core/services/dialog.service';
import {IInjectStyleService} from '../../../core/services/inject-style.service';
import IDialogOptions = angular.material.IDialogOptions;
import { IMonoOccurrence } from '../../../core/services/mono-occurrence.service';
import { ISourceDetailsManager } from '../../../core/services/source-details-manager.service';
import { IMultiOccurrence } from '../../../core/services/multi-occurrence.service';

export interface IDonneesEnregistrementService {
    active: boolean;
    activate(): void;
    deactivate(): void;
    toggleActivation(): void;
}

export interface IDonneesEnregistrementDetails {
    data: any;
    schema: ISchemas;
    dataTypes: IDataTypeMap;
    table?: string;
}

export default module('app.services.donnees-enregistrement', []).factory('donneesEnregistrement', DonneesEnregistrementFactory);

/* @ngInject */
function DonneesEnregistrementFactory(DialogDonneesEnregistrement: IDialog,
                                      injectStyle: IInjectStyleService,
                                      $document: IDocumentService,
                                      sourceDetailsManager: ISourceDetailsManager): IDonneesEnregistrementService {
    injectStyle(`.ex-donnees-enregistrement-active * {
        cursor: help !important;
    }
    
    .ex-donnees-enregistrement-active ex-card-cycle>ex-card>md-card md-card-content:hover,
    .ex-donnees-enregistrement-active ex-card-content>ex-formulaire>form:hover,
    .ex-donnees-enregistrement-active ex-saisie-content>ex-formulaire>form:hover,
    .ex-donnees-enregistrement-active .ex-multi-occurrence-accordeon-bloc:hover,
    .ex-donnees-enregistrement-active ex-data-list:hover,
    .ex-donnees-enregistrement-active [multi-occurrence] .ex-grid-row:hover .ex-grid-col {
        background: rgb(255, 255, 200) !important;
    }`);

    class DonneesEnregistrement implements IDonneesEnregistrementService {
        active: boolean = false;

        activate() {
            this.active = true;

            angular.element(document.body)
                .addClass('ex-donnees-enregistrement-active');

            $document.on('click.donneesEnregistrement', 'ex-saisie-content>ex-formulaire, ex-card-content>ex-formulaire', (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                
                const el: IAugmentedJQuery = angular.element(<HTMLElement>event.currentTarget);
                const monoOccurrence = el.controller('exMonoOccurrence').monoOccurrence as IMonoOccurrence;
                sourceDetailsManager.fetch(monoOccurrence.getNomSourceDetails()).then((sourceDetails) => {
                    this.open(event, {
                        data: monoOccurrence.data,
                        dataTypes: {...monoOccurrence.dataTypes},
                        schema: monoOccurrence.schema,
                        table: sourceDetails.table
                    });
                });
            });

            $document.on('click.donneesEnregistrement', '[multi-occurrence] .ex-grid-row, [multi-occurrence] .ex-multi-occurrence-accordeon-bloc', (event: any) => {
                event.preventDefault();
                event.stopPropagation();

                const el: IAugmentedJQuery = angular.element(<HTMLElement>event.currentTarget);
                const multiOccurrence = el.closest('ex-multi-occurrence').controller('exMultiOccurrence').multiOccurrence as IMultiOccurrence;

                const index = el.index();

                sourceDetailsManager.fetch(multiOccurrence.getNomSourceDetails()).then((sourceDetails) => {
                    this.open(event, {
                        data: multiOccurrence.dataList[index],
                        dataTypes: {...multiOccurrence.dataTypes},
                        schema: multiOccurrence.schema,
                        table: sourceDetails.table
                    });
                });
            });

            $document.on('click.donneesEnregistrement', 'ex-card-cycle>ex-card>md-card md-card-content', (event: any) => {
                event.preventDefault();
                event.stopPropagation();

                const el: IAugmentedJQuery = angular.element(<HTMLElement>event.currentTarget);
                const ctrl = el.closest('ex-card-cycle').controller('exCardCycle');

                const data = {...ctrl.monoOccurrence.etatActuel};
                delete data.$promise;
                delete data.$resolved;

                this.open(event, {
                    data: data,
                    dataTypes: {...ctrl.dataTypes},
                    schema: data
                });
            });

            $document.on('click.donneesEnregistrement', 'ex-data-list', (event: any) => {
                event.preventDefault();
                event.stopPropagation();

                const el: IAugmentedJQuery = angular.element(<HTMLElement>event.currentTarget);
                const ctrl = el.controller('exDataList') || el.controller('exDataListAccordeon');
                const multiOccurrence = el.closest('ex-multi-occurrence').controller('exMultiOccurrence').multiOccurrence as IMultiOccurrence;

                if (multiOccurrence) {
                    sourceDetailsManager.fetch(multiOccurrence.getNomSourceDetails()).then((sourceDetails) => {
                        this.open(event, {
                            data: ctrl.data,
                            dataTypes: {...ctrl.dataTypes},
                            schema: ctrl.schemas,
                            table: sourceDetails.table
                        });
                    });
                } else {
                    this.open(event, {
                        data: ctrl.data,
                        dataTypes: {...ctrl.dataTypes},
                        schema: ctrl.schemas
                    });
                }
            });
        }

        deactivate() {
            $document.off('.donneesEnregistrement');
            angular.element(document.body).removeClass('ex-donnees-enregistrement-active');
            this.active = false;
        }

        toggleActivation() {
            if (this.active) {
                this.deactivate();
            } else {
                this.activate();
            }
        }

        private open(event: MouseEvent, details: IDonneesEnregistrementDetails) {
            const dialogOptions: IDialogOptions = {
                targetEvent: event,
                locals: details
            };

            DialogDonneesEnregistrement.show(dialogOptions);

            this.deactivate();
        }
    }

    return new DonneesEnregistrement();
}
