import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageAS0060 extends IComponentController{
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireSua: IFormulaire;
}

/* @ngInject */
export default function As0060Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageAS0060 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireAfj = new Formulaire('LBL_BLC_AFJ', [
            new FormulaireItem('afecleint', { default: $stateParams.afecleint, readonly: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fjrdat', {largeur: 50}),
            new FormulaireItem('actcleint', {largeur: 50}),
            new FormulaireItem('fjrcom', {largeur: 100})
        ] );
    }
}