import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";

interface IPageMr0110 extends IComponentController {
    formulaireFpn: IFormulaire;
    monoOccurrence: IMonoOccurrence,
    fpsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fsaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fnrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fauMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fpoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fpsMultiOccurrence: IMultiOccurrence;
    fpoMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mr0110Controller(mr0170Route: IRoute,
                                         mr0330Route: IRoute,
                                         mr0008Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Menu: IMenuClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMr0110 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Régime de retraite
        vm.formulaireFpn = new Formulaire('LBL_BLC_FPN', [
            new FormulaireItem('fpncod'   , {largeur: 33}),
            new FormulaireItem('fpnflgact', {default: 1, largeur: 33}),
            new FormulaireItem('fpnref'   , {largeur: 33}),
            new FormulaireItem('fpndsc'   , {largeur: 50}),
            new FormulaireGroupeAccordeon('LBL_GRP_AUTRES_INFOS',
                new Formulaire([
                    new FormulaireItem('fpnflgben', {largeur: 50}),                    
                    new FormulaireItem('ttccleint', {largeur: 50}),                    
                    new FormulaireItemDivider('LBL_DIV_AGE'),
                    new FormulaireItem('fpnagemax', {largeur: 33}),
                    new FormulaireItem('vaefpnagemax', {largeur: 33})
                ], {largeurDefaut: 33})
            )
        ], {largeurDefaut: 50});

        // Condition d'admissiblité
        vm.fpsMultiOccurrenceOptions = {
            bloc: 'fps',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_COND_ADM',
                    mr0170Route.NAME, () => {
                        return {
                            fpncleint: vm.monoOccurrence.data.fpncleint
                        };
                    },
                    {
                        iconButton: true,
                        icon: "add_circle",
                        onClose: () => { vm.fpsMultiOccurrence.fetchDataList(); }
                    }),
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_PARAM_AVI',
                    mr0008Route.NAME,
                    (data: any) => ({
                        id: data.apacleint,
                        cleintref:  data.fpscleint,
                        stamnetab:  'FPS',
                        vaesystypavi: 'ART',
                        supprime: 0,
                        typsai:  data.vaesysconadm === 'IMM' || data.vaesysconadm === 'DEL' ? 'JRS' : 'BQE'
                    }),
                    {
                        icon: 'alarm',
                        iconButton: true,
                        class: 'ex-menu-item-button--secondaire',
                        hidden: (data: any) => data.vaesysconadm === 'EXC' || data.vaesysconadm === 'MAN'
                    }
                ),
                new MenuItemEcran(
                    'LBL_COND_ADM',
                    mr0170Route.NAME,
                    (data: any) => ({
                        id: data.fpscleint,
                        fpncleint: vm.monoOccurrence.data.fpncleint,
                        apacleint: data.apacleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => { vm.fpsMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'urgcod'          , largeur: 100},
                {nom: 'urgdsc'          , largeur: 100},
                {nom: 'vaesysconadm_dsc', largeur: 100},
                {nom: 'strcod'          , largeur: 100},
                {nom: 'strdsc'          , largeur: 100}
            ],
            colonnesCachees: [
                'fpscleint',
                'vaesysconadm',
                'apacleint'
            ]
        };

        // Salaire moyen
        vm.fsaMultiOccurrenceOptions = {
            bloc: 'fsa',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'codaff'   , largeur: 100, hidden: true},
                {nom: 'fsaann'   , largeur: 100},
                {nom: 'urgcod'   , largeur: 100},
                {nom: 'urgdscabr', largeur: 100},
                {nom: 'grecod'   , largeur: 100},
                {nom: 'gredsc'   , largeur: 100},
                {nom: 'fsasalmoy', largeur: 100},
                {nom: 'urggredsc', largeur: 100, hidden: true}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fsaann'),
                new FormulaireItem('urgcleint'),
                new FormulaireItem('grecleint'),
                new FormulaireItem('fsasalmoy')
            ], {largeurDefaut: 100})
        };

        // Rétro
        vm.fnrMultiOccurrenceOptions = {
            bloc: 'fnr',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'tagcoddsc', largeur: 100, hidden: true},
                {nom: 'fnrdat'   , largeur: 100, hidden: true},
                {nom: 'tagcod'   , largeur: 100},
                {nom: 'tagdscabr', largeur: 100},
                {nom: 'fnrdatdeb', largeur: 100},
                {nom: 'fnrdatfin', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint', {largeur: 100}),
                new FormulaireItem('fnrdatdeb'),
                new FormulaireItem('fnrdatfin')
            ], {largeurDefaut: 50})
        };

        // Création automatique
        vm.fauMultiOccurrenceOptions = {
            bloc: 'fau',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'fpncod', largeur: 100},
                {nom: 'fpndsc', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fpncleintref',{
                    resourceParams: () => {
                        return {
                            fpncleint: vm.monoOccurrence.data.fpncleint
                        };
                    }
                })
            ])
        };

        // Options / Sous-options
        vm.fpoMultiOccurrenceOptions = {
            bris: 'fpocleint',
            brisNiveaux: 'niveau',
            bloc: 'fpo',
            colonnesVisibles: [
                'fpofsscod'
            ],
            colonnesFixesDroite: [
                'rcecoddsc'
            ],
            colonnesCachees: [
                'fpocleint',
                'fpoflgsui'
            ],
            fonctions: {
                recherche: false,
                suiviModification: (data: any) => data.fpoflgsui
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_OPTIONS',
                    mr0330Route.NAME, () => {
                        return {
                            fpncleint: vm.monoOccurrence.data.fpncleint
                        };
                    }, {
                        iconButton: true,
                        icon: "add_circle",
                        onClose: () => vm.fpoMultiOccurrence.fetchDataList()
                    })
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_OPTIONS',
                    mr0330Route.NAME,
                    (data: any) => ({
                        id: data.fpocleint,
                        fpncleint: vm.monoOccurrence.data.fpncleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        hidden: (data: any) => !data.fpoflgsui,
                        onClose: () => { vm.fpoMultiOccurrence.fetchDataList(); }
                    }
                )
            ])
        }
    }
}
