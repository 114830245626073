import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
interface IPageCR0040 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0040Controller(Menu: IMenuClass,
    cr0041Route: IRoute,
    MenuItemForage: IMenuItemForageClass
    ) {
    const vm: IPageCR0040 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cr0041Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cr0041Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'ennnumdoc', largeur: 200 },
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 200 },
            { nom: 'tyddsc', largeur: 200 },
            { nom: 'etddsc', largeur: 200 },
            { nom: 'enndat', largeur: 200 },
            { nom: 'intcod', largeur: 200 },
            { nom: 'intnom', largeur: 200 }
        ]
    };
}
