import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IStateParamsService } from 'angular-ui-router';




interface IPagerr0152 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function rr0152Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         $stateParams : IStateParamsService) {
    const vm: IPagerr0152 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdsc', largeur: 360},
            {nom: 'gaedatdeb', largeur: 120},
            {nom: 'gaedatfin', largeur: 120},
            {nom: 'gaucod', largeur: 160},
            {nom: 'gaudsc', largeur: 300},
            {nom: 'gaeannanc', largeur: 102},
            {nom: 'gaecom', largeur: 400}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint',{largeur:40}),
            new FormulaireItem('urgcleint',{largeur:40}),
            new FormulaireItemSautDeColonne({largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gaedatdeb', {largeur:20}),
            new FormulaireItem('gaedatfin',{largeur:20}),
            new FormulaireItem('gaucleint',{largeur:40}),
            new FormulaireItem('gaeannanc',{largeur:20}),
            new FormulaireItem('gaecom',{enableCount:true,largeur:100})
        ])
    };
}
