import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMC0158 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0158Controller(mc0159Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMC0158 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0159Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.noccleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0159Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'redcod'      , largeur: 130},
            {nom: 'reddsc'      , largeur: 170},
            {nom: 'etdcod'      , largeur: 110},
            {nom: 'etddsc'      , largeur: 170},
            {nom: 'nocflgstu'   , largeur: 80},
            {nom: 'nocobj'      , largeur: 400},
            {nom: 'nocflgusrcre', largeur: 90},
            {nom: 'nocflgreq'   , largeur: 100},
            {nom: 'nocflgach'   , largeur: 90},
            {nom: 'flgetd'      , largeur: 90},
            {nom: 'flgint'      , largeur: 105},
            {nom: 'flgman'      , largeur: 90}
        ],
        colonnesCachees: [
            'noccleint'
        ]
    };

}
