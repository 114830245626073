import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPagePt0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt0013Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPagePt0013 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'hopnumdoc', largeur: 120},
            {nom: 'lrmdatdeb', largeur: 126},
            {nom: 'lrmdatfin', largeur: 126},
            {nom: 'lrmnumdoc', largeur: 120},
            {nom: 'ecldsceta', largeur: 120},
            {nom: 'ttccod', largeur: 140},
            {nom: 'ttcdsc', largeur: 140},
            {nom: 'lrmdsc', largeur: 140},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
