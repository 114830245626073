import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageMc0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0002Controller(mc0168Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0002 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        navigatePage: mc0168Route.NAME,
        colonnesVisibles: [
            {nom: 'peccod', largeur: 120},
            {nom: 'pecann', largeur: 150},
            {nom: 'pecper', largeur: 120},
            {nom: 'pecflgreg', largeur: 135},
            {nom: 'pecdatdeb', largeur: 180},
            {nom: 'pecdatfin', largeur: 180}
        ],

        formulaire: new Formulaire([
            new FormulaireItem('peccod'),
            new FormulaireItem('pecann', { maxlength: 4 }),
            new FormulaireItem('pecper'),
            new FormulaireItem('pecflgreg'),
            new FormulaireItem('pecdatdeb'),
            new FormulaireItem('pecdatfin')
        ], {largeurDefaut: 25})
    };
}
