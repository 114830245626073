import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0266 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0266Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0266 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'agndsc', largeur: 170}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('agndsc', {required: true, largeur: 50})
        ]),
        fonctions: Object.assign({
            edition: true,
            nouveau: true

        })
    };
}
