import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageSO0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0012Controller(so0013Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageSO0012 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: so0013Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                grocleint: rowData.grocleint
            };
        },
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(so0013Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'grocod', largeur: 120},
            {nom: 'grodsc', largeur: 400},
            {nom: 'vacvalcle', largeur: 180},
            {nom: 'vacdsc', largeur: 250},
            {nom: 'groflgfil', largeur: 75},
            {nom: 'groflgsec', largeur: 91},
            {nom: 'groflgcc', largeur: 91}
        ],
        colonnesCachees: [
            'grocleint'
        ]
    };
}
