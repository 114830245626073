
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';




interface IPageIn0043 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function In0043Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageIn0043 = this;
   vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'tyvcod',    largeur: 20},
            {nom: 'tyvdscabr',    largeur: 25},
            {nom: 'tyvdsc',    largeur: 40},
            {nom: 'tyvflgact',    largeur: 5},
            {nom: 'tyvflgrefobl',    largeur: 5},
            {nom: 'tyvflgext',    largeur: 5},
            {nom: 'taxcod',    largeur: 25},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyvcod',  { largeur: 10 }),            
            new FormulaireItem('tyvdscabr', { largeur: 25 }),
            new FormulaireItem('tyvdsc', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tyvflgact', { default: 1, largeur: 10 }),
            new FormulaireItem('tyvflgrefobl', { largeur: 10 }),
            new FormulaireItem('tyvflgext', { largeur: 10 }),
            new FormulaireItem('taxcleint', { largeur: 25 })
        ]),
        fonctions:{
            rechercheCollapsable: false
        }
    };
}