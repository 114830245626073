import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IController } from 'angular';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageCr0044 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCesOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0044Controller(
    MenuItemAction: IMenuItemActionClass,
    cr0239Route: IRoute,
    Menu: IMenuClass
) {
    const vm: IPageCr0044 = this;

    vm.multiOccurrenceCesOptions = {        
        bloc: 'ces',
        colonnesFixesGauche: [
            { nom: 'cesnumdoc', largeur: 130 },
            { nom: 'cesdat', largeur: 110 },
            { nom: 'cldcod', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'cldnom', largeur: 150 },
            { nom: 'cldprn', largeur: 150 },
            { nom: 'cesmnt', largeur: 125 },
            { nom: 'etycod', largeur: 125 },
            { nom: 'etydsc', largeur: 180 },
            { nom: 'moecod', largeur: 130 },
            { nom: 'moedsc', largeur: 180 },
            { nom: 'chqdat', largeur: 110 },
            { nom: 'cesnumref', largeur: 150 },
            { nom: 'maicre', largeur: 130 },
            { nom: 'cesdsc', largeur: 180 }
        ]
    };

    vm.multiOccurrenceOptions = {
        navigatePage: cr0239Route.NAME,
        navigateParams: (rowData: any) => (
            {
                id: rowData.lencleint
            }
        ),
        colonnesFixesGauche: [
            { nom: 'lencod', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 110 },
            { nom: 'lendat', largeur: 110 },
            { nom: 'lenstu', largeur: 110 },
            { nom: 'nbr_ces', largeur: 110 },
            { nom: 'tot_ces', largeur: 110 },
            { nom: 'lendsc', largeur: 180 },
            { nom: 'loecod', largeur: 110 },
            { nom: 'peccod', largeur: 110 },
        ],
        colonnesCachees: [
            "vaelenstu"
        ],
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_VALIDER',
                'BOUVAL',
                (data: any) => ({ lencleint: data.lencleint }),
                {
                    hidden: (data:any) => (data.vaelenstu != 'FERM'),                    
                    icon: 'content_copy'
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false,
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCesOptions
    };
}
