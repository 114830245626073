import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCp0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0006Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCp0006 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'fogcod', largeur: 115},
            {nom: 'fognom', largeur: 540}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('fogcod'),
            new FormulaireItem('fognom')
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
