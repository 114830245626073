import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMc1014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IPageMc1014Params {
    tolcleintref: number,
    vaeustent: string
    // indicateur de forage à developper  indfor: number
}

/* @ngInject */
export default function Mc1014Controller($stateParams: IPageMc1014Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc1014 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ustdscabr',    largeur: 250},
            {nom: 'tolprcectprx', largeur: 160},
            {nom: 'tolqteprcdep', largeur: 160},
            {nom: 'tolqtedep',    largeur: 160},
            {nom: 'tolmntprcdep', largeur: 160},
            {nom: 'tolmntdep',    largeur: 160}
        ],
        formulaire: new Formulaire('LBL_EDITION', [
            new FormulaireItem('ustcleint', { largeur : 60 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tolprcectprx', { largeur : 20 }),
            new FormulaireItem('tolqteprcdep', { largeur : 20 }),
            new FormulaireItem('tolqtedep',    { largeur : 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tolmntprcdep', { largeur : 20 }),
            new FormulaireItem('tolmntdep',    { largeur : 20 }),
            new FormulaireItem('vaetolmnetab', { largeur : 0, default: $stateParams.vaeustent }),
            new FormulaireItem('tolcleintref', { largeur : 0, default: $stateParams.tolcleintref })
        ])
    };
}
