import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import IStateService = angular.ui.IStateService;
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRr0073 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
interface IRr0073Params {
    demcleint: string;
    aftcleint: string;
    employe: string;
    menuId: string;
    efface_criteres: boolean;
}

/* @ngInject */
export default function Rr0073Controller(Operateur: IOperateurService,
    rr0074Route: IRoute,
    rr0075Route: IRoute,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $state: IStateService,
    parametresRecherche: IParametresRechercheService,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IRr0073Params) {
    const vm: IPageRr0073 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const filtresParametres = [];
    const filtres = [
        ...parametresRecherche(ecranDetails),
        {
            colonne: 'prv',
            operateur: Operateur.EGALE,
            valeur: 'ALL'
        }
    ];

    if ($stateParams.demcleint) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'demcleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.demcleint,
                    readOnly: true
                },
                {
                    colonne: 'aftcleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.aftcleint,
                    readOnly: true
                }
            ]
        );
    }

    vm.multiOccurrenceOptions = {
        filtres,
        filtresParametres,
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: !$stateParams.employe,
            effaceCriteres: $stateParams.efface_criteres
        },
        pagination: {
            nbElementsPossibles: [100, 250, 500],
            nombreElementPage: 100
        },
        navigatePage: (rowData: any) => (!rowData.drpcleint) ? rr0074Route.NAME : rr0075Route.NAME,
        actionsMoreLigne: new Menu([
            new MenuItemAction("G_LBL_COPIE", "BOUCOP", () => ({}), {
                icon: 'content_copy',
                iconButton: false,
                successAction: ({ trncleintnew }) => $state.go(rr0074Route.NAME, { id: trncleintnew, menuId: $stateParams.menuId })
            })
        ]),
        actionsNouveaux: new Menu([
            new MenuItemAction(
                'G_LBL_BTN_NOUVEAU',
                'SIMULER_PROCEDURE',
                () => ({}),
                {
                    saveFirst: true,
                    fonction: 'BOUNOU',
                    successAction: (data: any) => {
                        $state.go(rr0074Route.NAME, {
                            menuId: $stateParams.menuId,
                            tdmcleint: data.tdmcleintc,
                            t_employe: $stateParams.employe || $stateParams.demcleint
                        });
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_DEMANDE_TDM',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 50,
                        formulaire: new Formulaire([
                            new FormulaireItem('tdmcleintc', {
                                required: true, largeur: 100
                            })
                        ], {
                            securityCycle: false
                        })
                    }
                }
            )
        ]),

        colonnesCachees: [
            'drpcleint'
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datlim', { default: null }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("dercleint"),
            new FormulaireItem("eplnummat"),
            new FormulaireItem("eplnomprn"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('derdat', { default: null }),
            new FormulaireItem("derdatdeb"),
            new FormulaireItem("derdatfin"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("eclcleint"),
            new FormulaireItem("tdmcleint"),
            new FormulaireItem("urgcleint"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("unocleint"),
            new FormulaireItem("temcleint"),
            new FormulaireItem("tagcleint"),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("usrcleint"),
            new FormulaireItem("intcleintdem"),
            new FormulaireItem('fdrnumcha')
        ]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 120, hidden: Boolean($stateParams.employe) },
            { nom: 'eplnomprn', largeur: 160, hidden: Boolean($stateParams.employe) },
            { nom: 'derdatdeb', largeur: 126 },
            { nom: 'derdatfin', largeur: 126 },
            { nom: 'dernumdoc', largeur: 120 },
            { nom: 'drpnumdoc', largeur: 120 },
            { nom: 'derdat', largeur: 126 },
            { nom: 'drpdatfin', largeur: 126 },
            { nom: 'ecldsceta', largeur: 190 },
            { nom: 'ecldscetapro', largeur: 175 },
            { nom: 'tdmcod', largeur: 120 },
            { nom: 'tdmdsc', largeur: 257 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdscabr', largeur: 252 },
            { nom: 'temcod', largeur: 120 },
            { nom: 'temdscabr', largeur: 252 },
            { nom: 'tagcod', largeur: 120 },
            { nom: 'tagdsc', largeur: 252 },
            { nom: 'derqte', largeur: 100 },
            { nom: 'unccodqte', largeur: 130 },
            { nom: 'dertauhor', largeur: 100 },
            { nom: 'unccodtau', largeur: 100 },
            { nom: 'dermnt', largeur: 100 },
            { nom: 'dceppacodmax', largeur: 100 },
            { nom: 'dceppacodtrtmax', largeur: 100 },
            { nom: 'dceqtetot', largeur: 100 },
            { nom: 'dceqtetottrt', largeur: 100 },
            { nom: 'dceqtetotntrt', largeur: 120 },
            { nom: 'dcemnttot', largeur: 120 },
            { nom: 'dcemnttottrt', largeur: 120 },
            { nom: 'dcemnttotntrt', largeur: 120 },
            { nom: 'derflgpro', largeur: 120 },
            { nom: 'derdsc', largeur: 252 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 252 },
            { nom: 'unocod', largeur: 120 },
            { nom: 'unodscabr', largeur: 252 },
            { nom: 'dmpnumdoc', largeur: 120 },
            { nom: 'dmpcom', largeur: 252 },
            { nom: 'litcod', largeur: 120 },
            { nom: 'litdsc', largeur: 252 },
            { nom: 'derflgignval', largeur: 100 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 90 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'trnflgstaann', largeur: 75 },
            { nom: 'flgerr', largeur: 74 },
            { nom: 'usrcod', largeur: 110 },
            { nom: 'usrnom', largeur: 174 },
            { nom: 'esvcod1', largeur: 170, hidden: !vm.valeursEcran.flgesv1aff },
            { nom: 'esvcod2', largeur: 170, hidden: !vm.valeursEcran.flgesv2aff },
            { nom: 'esvcod3', largeur: 170, hidden: !vm.valeursEcran.flgesv3aff },
            { nom: 'esvcod4', largeur: 170, hidden: !vm.valeursEcran.flgesv4aff },
            { nom: 'esvcod5', largeur: 170, hidden: !vm.valeursEcran.flgesv5aff },
            { nom: 'fdrnumcha', largeur: 150 }
        ],
        colonnesDisponibles: [
            { nom: 'liste_ubr', largeur: 190 }
        ]
    };
}
