import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';

interface IPageRR0124 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0124Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         dateFilter: IFilterDate,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0124 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datvig', {
                required: true,
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'efodatvig', largeur: 100},
            {nom: 'inscod', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'insdsc', largeur: 262},
            {nom: 'succod', largeur: 110},
            {nom: 'sucdsc', largeur: 262},
            {nom: 'efdseq', largeur: 65},
            {nom: 'efdcptbqe', largeur: 116},
            {nom: 'efdprc', largeur: 71},
            {nom: 'efdmnt', largeur: 126}
            ]
    };
}
