import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageRr0523 extends IComponentController {
    valeursEcran: any;
    formulaireReb: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    sigMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rewMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rejMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rekMultiOccurrenceOptions: IMultiOccurrenceOptions;
    relMultiOccurrenceOptions: IMultiOccurrenceOptions;
    renMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rdwMultiOccurrenceOptions: IMultiOccurrenceOptions;
}


/* @ngInject */
export default function Rr0523Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRr0523 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        id: vm.valeursEcran.rebcleint
    };

    vm.formulaireReb = new Formulaire('LBL_BLC_REB', [
        new FormulaireItem('pciunccleint', { required: true }),
        new FormulaireItemSautDeColonne(),
        new FormulaireItem('coladmdschre', {default: vm.valeursEcran.coladmdschre,readonly: true}),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('rebflgpar'),
        new FormulaireItem('rebflgprsres'),
        new FormulaireItem('coladmdscmnt', { default: vm.valeursEcran.coladmdscmnt, readonly: true, minWidth: 260 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('rebdelpai', { required: true }),
        new FormulaireItem('rebmntmin'),
        new FormulaireItem('coladmdscaut', {default: vm.valeursEcran.coladmdscaut,readonly: true})
    ], { largeurDefaut: 33 });

    vm.sigMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'unpcod', largeur: 130 },
            { nom: 'unpdsc', largeur: 231 },
            { nom: 'sigrelnom', largeur: 246 },
            { nom: 'sigrelprn', largeur: 189 },
            { nom: 'sigindreg', largeur: 74 },
            { nom: 'sigrelnumtel', largeur: 100 },
            { nom: 'signumpos', largeur: 100 },
            { nom: 'vaeusrlng_dsc', largeur: 155 }
        ],
        formulaire: new Formulaire('LBL_BLC_SIG', [
            new FormulaireItem('unpcleint'),
            new FormulaireItem('sigrelnom'),
            new FormulaireItem('sigrelprn'),
            new FormulaireItem('sigindreg'),
            new FormulaireItem('sigrelnumtel'),
            new FormulaireItem('signumpos'),
            new FormulaireItem('vaeusrlng')
        ], { largeurDefaut: 33 })
    };

    vm.rewMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 110 },
            { nom: 'tagdsc', largeur: 401 },
            { nom: 'vaeautmnt_dsc', largeur: 196 }
        ],
        formulaire: new Formulaire('LBL_BLC_REW', [
            new FormulaireItem('tagcleint', { required: true }),
            new FormulaireItem('vaeautmnt')
        ], { largeurDefaut: 100 })
    };

    vm.rejMultiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'urgcod', largeur: 130 },
            { nom: 'urgdsc', largeur: 250 }
        ],
        colonnesVisibles: [
            { nom: 'strcod', largeur: 110 },
            { nom: 'strdsc', largeur: 245 },
            { nom: 'rejdelpai', largeur: 74 },
            { nom: 'rejflgaut', largeur: 115 },
            { nom: 'vaereemot_dsc', largeur: 152 },
            { nom: 'rdwdsc', largeur: 262 }
        ],
        formulaire: new Formulaire('LBL_BLC_REJ', [
            new FormulaireItem('urgcleint', { required: true, largeur: 30 }),
            new FormulaireItem('strcleint', { largeur: 30 }),
            new FormulaireItem('rejdelpai'),
            new FormulaireItem('rejflgaut'),
            new FormulaireItem('vaereemot', { required: true, largeur: 30 }),
            new FormulaireItem('rdwcleint', { largeur: 30 })
        ], { largeurDefaut: 10 })
    };

    vm.rekMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tdmcod', largeur: 140 },
            { nom: 'tdmdsc', largeur: 200 },
            { nom: 'rekdelpai', largeur: 65 },
            { nom: 'rekflgaut_aff', largeur: 70 },
            { nom: 'vaereemot_dsc', largeur: 160 }
        ],
        formulaire: new Formulaire('LBL_BLC_REK', [
            new FormulaireItem('tdmcleint', { required: true }),
            new FormulaireItem('rekdelpai'),
            new FormulaireItem('rekflgaut'),
            new FormulaireItem('vaereemot', { required: true })
        ], { largeurDefaut: 50 })
    };

    vm.relMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tafcod', largeur: 140 },
            { nom: 'tafdsc', largeur: 200 },
            { nom: 'reldelpai', largeur: 65 },
            { nom: 'relflgaut_aff', largeur: 70 },
            { nom: 'vaereemot_dsc', largeur: 160 }
        ],
        formulaire: new Formulaire('LBL_BLC_REL', [
            new FormulaireItem('tafcleint', { required: true }),
            new FormulaireItem('reldelpai'),
            new FormulaireItem('relflgaut'),
            new FormulaireItem('vaereemot', { required: true })
        ], { largeurDefaut: 50 })
    };

    vm.renMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tdlcod', largeur: 150 },
            { nom: 'tdldsc', largeur: 245 },
            { nom: 'prfcod', largeur: 150 },
            { nom: 'prfdsc', largeur: 245 },
            { nom: 'renflgaut', largeur: 115 },
            { nom: 'vaereemot_dsc', largeur: 195 },
            { nom: 'vaerecretprv_dsc', largeur: 195 }
        ],
        formulaire: new Formulaire('LBL_BLC_REN', [
            new FormulaireItem('tdlcleint', { required: true }),
            new FormulaireItem('prfcleint'),
            new FormulaireItem('renflgaut'),
            new FormulaireItem('vaereemot', { required: true }),
            new FormulaireItem('vaerecretprv', { required: true })
        ], { largeurDefaut: 33 })
    };

    vm.rdwMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'rdwdsc', largeur: 245 }
        ],
        formulaire: new Formulaire('LBL_BLC_RDW', [
            new FormulaireItem('rdwdsc', { largeur: 100, required: true })
        ])
    };
}
