import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMR0319 extends IComponentController {
    valeursEcran: any;
    formulairePfp: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ppbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0319Params {
    province: string;
    pafcleint: number;
}

/* @ngInject */
export default function Mr0319Controller($stateParams: IMr0319Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMR0319 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulairePfp = new Formulaire('LBL_BLC_PFP', [
            new FormulaireItem('vaesyscodprv', { default: $stateParams.province }),
            new FormulaireItem('pfptd1mntbas', { largeur: 10 }),
            new FormulaireItem('pfpimftaut3', { largeur: 10 }),
            new FormulaireItem('pafcleint', { default: $stateParams.pafcleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_ASSURANCE_EMP',
                new Formulaire([
                    new FormulaireItem('pfpasemga'),
                    new FormulaireItem('pfpasetau'),
                    new FormulaireItem('pfpasefctemrreg'),
                    new FormulaireItem('pfpasefctemrred')
                ], { largeurDefaut: 25 }), { largeur: 100 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_CSSIAT',
                new Formulaire([
                    new FormulaireItem('pfpcssmga')
                ]), { largeur: 50 }
            ),
            new FormulaireGroupeAccordeon('LBL_GRATIFIC',
                new Formulaire([
                    new FormulaireItem('pfpgrarevmin'),
                    new FormulaireItem('pfpgrataumin')
                ]), { largeur: 50 }
            )
        ], { largeurDefaut: 25 });

        // Borne
        vm.ppbMultiOccurrenceOptions = {
            bloc: 'ppb',
            colonnesVisibles: [
                {nom: 'ppbborinf', largeur: 120},
                {nom: 'ppbtau'   , largeur: 85},
                {nom: 'ppbval'   , largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ppbborinf'),
                new FormulaireItem('ppbtau'),
                new FormulaireItem('ppbval')
            ], { largeurDefaut: 15 })
        };
       
    }
}
