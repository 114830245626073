import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMonoOccurrence} from "../../../../../core/services/mono-occurrence.service";
import {IStateParamsService} from 'angular-ui-router';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCP0073 extends IComponentController {
    formulaireFcn: IFormulaire;
    monoOccurrence: IMonoOccurrence;
}

/* @ngInject */
export default function Cp0073Controller($stateParams: IStateParamsService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageCP0073 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireFcn = new Formulaire('LBL_CONTACTS', [
            new FormulaireItem('refcleint',{default: $stateParams.refcleint,hidden:true}),
            new FormulaireItem('fcntit'),
            new FormulaireItem('fcnnom'),
            new FormulaireItem('fcnflgstu',{
                default: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fcnnumtel'),
            new FormulaireItem('fcnnumtelpos'),
            new FormulaireItem('fcnnumfax'),
            new FormulaireItem('fcnnumref'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vecsyscodtel'),
            new FormulaireItem('fcnnumtelaut'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fcnadrcou', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fcncom', {largeur: 100, enableCount: true})
        ], {
            largeurDefaut: 25
        });
    }
}
