import IStateProvider = angular.ui.IStateProvider;
import IUrlRouterProvider = angular.ui.IUrlRouterProvider;
import {module} from 'angular';
import AccueilController from './accueil.controller';
import {IRoute} from '../../../../core/interfaces/route.interface';

const route: IRoute = {
    NAME: 'secure.accueil',
    URL: '/accueil'
};

export default module('app.secure.accueil', [
    'core.layouts.vertical-navigation',
    'core.services.filtre',
    'core.services.selecteur'
]).config(config).constant('accueilRoute', route);

/* @ngInject */
function config($stateProvider: IStateProvider, $urlRouterProvider: IUrlRouterProvider) {
    const template = require('./accueil.html');

    $urlRouterProvider.when('/', route.URL);

    $stateProvider.state(route.NAME, {
        url: route.URL,
        params: {
            route,
            verifierChangements: null
        },
        views: {
            content: {
                template,
                controller: AccueilController,
                controllerAs: 'vm'
            }
        }
    });
}
