import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";

interface IPageRr0506 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireFep: IFormulaire;
    fedMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursFep: any;
}

/* @ngInject */
export default function Rr0506Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRr0506 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursFep = ecranDetails.valeurs;

        vm.formulaireFep = new Formulaire('LBL_BLC_FEP', [
            new FormulaireItem('fepcod', { readonly: true }),
            new FormulaireItem('tpfcleint', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demcleint', { modifAllow: false }),
            new FormulaireItem('unpcleint', { modifAllow: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ppacleint', { modifAllow: false }),
            new FormulaireItem('percleint', { readonly: true }),
            new FormulaireItem('mopcleint', { modifAllow: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fepnbrppaava', { modifAllow: false }),
            new FormulaireItem('fepflgcal'), { readonly: true }
        ], { largeurDefaut: 33 });
        // Détail des feuilles de paie
        vm.fedMultiOccurrenceOptions = {
            bloc: 'fed',
            colonnesVisibles: [
                { nom: 'tagcod', largeur: 100 },
                { nom: 'tagdsc', largeur: 230 },
                { nom: 'aftcod', largeur: 80 },
                { nom: 'lemcod', largeur: 126 },
                { nom: 'unccod', largeur: 126 },
                { nom: 'fedqte', largeur: 126 },
                { nom: 'fedmnt', largeur: 130 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint', { resourceParams: (data: any) => ({ ppacleint: data.$ancetre.ppacleint, tpfcleint: data.$ancetre.tpfcleint }) }),
                new FormulaireItem('aftcleint', { resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint }) }),
                new FormulaireItem('lemcleint'),
                new FormulaireItem('unocleintrh', { disabled: true }),
                new FormulaireItem('fedqte', { largeur: (66.66 / 3) }),
                new FormulaireItem('fedmnt', { largeur: (66.66 / 3) }),
                new FormulaireItem('feddatdeb', { hidden: true })

            ], { largeurDefaut: 33.33 })
        };
    }
}
