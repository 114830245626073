import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCp0026 extends IComponentController {
    formulaireCor: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rdoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dovMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rerMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0026Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    mc0102Route: IRoute,
    mc0109Route: IRoute) {
    const vm: IPageCp0026 = this;

    vm.monoOccurrenceOptions = {
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        },
        formulaire: new Formulaire( 'LBL_BLC_REG', [
            new FormulaireItem('regnomleg'),
            new FormulaireItem('radcod'),
            new FormulaireItemSautDeLigne,
            new FormulaireItem('regnumdoc'),
            new FormulaireItem('regnumdocseq'),
            new FormulaireItem('lotcod'),
            new FormulaireItem('tydcod'),
            new FormulaireItem('peccod'),
            new FormulaireItem('regdat'),
            new FormulaireItem('mopcod'),
            new FormulaireItem('intcodreq'),
            new FormulaireItem('intcoddem'),
            new FormulaireItem('regmnt'),
            new FormulaireItem('regdevtau'),
            new FormulaireGroupe('LBL_BLC_CHQ',
                new Formulaire([
                    new FormulaireItem('regnumchq'),
                    new FormulaireItem('regdatcci'),
                    new FormulaireItem('regflgchqimp'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('regnumtra'),
                    new FormulaireItem('regmnttra')
                ]), { largeur: 50 }
            ),
            new FormulaireGroupe('LBL_BLC_ADR',
                new Formulaire([
                    new FormulaireItem('regadrl01'),
                    new FormulaireItem('regadrl02', { titre: ' ' }),
                    new FormulaireItem('regvil'),
                    new FormulaireItem('regpro'),
                    new FormulaireItem('regpay'),
                    new FormulaireItem('regcodpos')
                ]), { largeur: 50 }
            )
        ], { largeurDefaut: 33.33 })
    };

    // Correction
    vm.formulaireCor = new Formulaire('LBL_BLC_COR', [
        new FormulaireItem('nbrcorimp', {
            largeur: 70,
            readonly: true, menu: [
                new MenuItemForage(
                    mc0109Route.NAME,
                    (data: any) => ({
                        doccleintref: data.doccleint
                    }),
                    {
                        iconButton: true,
                        icon: 'add',
                        fonction: 'BOUAJF',
                        disabled: () => (ecranDetails.valeurs.flgre)
                    }
                ),
                new MenuItemForage(
                    mc0102Route.NAME,
                    (data: any) => ({
                        doccleintref: data.doccleint
                    }),
                    {
                        iconButton: true,
                        icon: 'search',
                        fonction: 'BOUHIS'
                    }
                )
            ],
            minWidth: 1 // Pour éviter que le champ s'agrandisse trop à cause des menus
        })
    ]);

    vm.rdoMultiOccurrenceOptions = {
        bloc: 'rdo',
        colonnesVisibles: [
            { nom: 'rernomleg', largeur: 160 },
            { nom: 'refcod', largeur: 100 },
            { nom: 'docaffnumdoc01', largeur: 100 },
            { nom: 'tydcod', largeur: 80 },
            { nom: 'rdomnt', largeur: 100 },
            { nom: 'rdomntesc', largeur: 100 },
            { nom: 'docaffdat01', largeur: 126 },
            { nom: 'docaffmnt01', largeur: 140 },
            { nom: 'docaffdsc01', largeur: 300 },
            { nom: 'rerflgimptfe', largeur: 100 }
        ]
    };

    vm.dovMultiOccurrenceOptions = {
        bloc: 'dov',
        colonnesFixesGauche: [
            { nom: 'dovimp', largeur: 260 },
            { nom: 'dovmnt', largeur: 130 },
            { nom: 'taxcod', largeur: 80 },
            { nom: 'remcod', largeur: 80 }
        ],
        colonnesVisibles: [
            { nom: 'dovmntdepnet', largeur: 130 },
            { nom: 'dovmnttaxfed', largeur: 130 },
            { nom: 'dovmnttaxpro', largeur: 130 },
            { nom: 'dovmntremfed', largeur: 130 },
            { nom: 'dovmntrempro', largeur: 130 },
            { nom: 'dovqte', largeur: 100 },
            { nom: 'dovdsc', largeur: 300 },
            { nom: 'docaffnumdoc01', largeur: 140 },
            { nom: 'dovmntnetutl', largeur: 130 }
        ],
        colonnesFixesDroite: [
            { nom: 'dovflgmsg', largeur: 100 }
        ],
        colonnesCachees: [
            'dovmsg'
        ]
    };

    vm.rerMultiOccurrenceOptions = {
        bloc: 'rer',
        colonnesVisibles: [
            { nom: 'rernomleg', largeur: 250 },
            { nom: 'rermnt', largeur: 130 },
            { nom: 'rermntesc', largeur: 130 },
            { nom: 'refcod', largeur: 100 },
            { nom: 'rerflgimptfe', largeur: 100 },
            { nom: 'radcod', largeur: 100 },
            { nom: 'empcod', largeur: 130 },
            { nom: 'empdsc', largeur: 250 }
        ]
    };
}