import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0268 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0268Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0268 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'rgncod'   , largeur: 160},
            {nom: 'rgndscabr', largeur: 250},
            {nom: 'rgndsc'   , largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rgncod'),
            new FormulaireItem('rgndscabr'),
            new FormulaireItem('rgndsc')
        ], {largeurDefaut: 33})
    };
}
