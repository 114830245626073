interface IErreurAccesEcran {
    lblMessage: string;
    lblRetour: string;
    retourAction(): void;
}

/* @ngInject */
export default function ErreurAccesEcranController($stateParams: any) {
    const vm: IErreurAccesEcran = this;
    vm.lblMessage = ($stateParams.error) ? $stateParams.error.message : 'G_MSG_ACCES';
    vm.lblRetour = 'G_LBL_BTN_OK';
    vm.retourAction = retourAction;

    function retourAction() {
        if ($stateParams.returnurl) {
            location.href = $stateParams.returnurl;
        } else {
            history.back();
        }
    }
}
