import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageGs0420 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireGno: IFormulaire;
    gtaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0420Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0420 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireGno = new Formulaire('LBL_BLC_GNO', [
            new FormulaireItem('gnocod'),
            new FormulaireItem('gnodsc',{enableCount:true}),
            new FormulaireItem('gnoflggbl'),
            new FormulaireItem('gnoflgsec')
        ]);
        vm.gtaMultiOccurrenceOptions = {
            bloc: 'gta',
            colonnesVisibles: [
                {nom: 'stadsc', largeur: 100},
                {nom: 'stanom', largeur: 100},
                {nom: 'gtaflgact', largeur: 100}

            ],
            formulaire: new Formulaire([
                new FormulaireItem('stacleint'),
                new FormulaireItem('gtaflgact',{default: 1})
            ])
        };
    }
}
