import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';



interface IPageAC0077 {  
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0077Controller() {
        
    const vm: IPageAC0077 = this;    
    // const ac0005Route = {NAME: 'secure.accueil'};    
    vm.multiOccurrenceOptions = {    
        fonctions: {                    
            rechercheCollapsable: false
        },        
        colonnesFixesGauche: [
            {nom: 'etcnumdoc', largeur: 150},
            {nom: 'etcnumdocseq', largeur: 100}
        ],
        colonnesVisibles: [            
            {nom: 'etcdsc', largeur: 220},
            {nom: 'etddsc', largeur: 140},
            {nom: 'vaedscstu', largeur: 130},
            {nom: 'refcod', largeur: 230},
            {nom: 'refnom', largeur: 200},
            {nom: 'founomabr', largeur: 200},
            {nom: 'etcdatdeb', largeur: 180},
            {nom: 'etcdatfin', largeur: 150},
            {nom: 'etrmntetc', largeur: 200},
            {nom: 'etrmntcon', largeur: 150},
            {nom: 'intcod', largeur: 262},
            {nom: 'intnom', largeur: 136},
            {nom: 'etrdatdeb', largeur: 180},
            {nom: 'etrdatfin', largeur: 150},
            {nom: 'aponumdoc', largeur: 150},
            {nom: 'etcnumao', largeur: 100},
            {nom: 'motcod', largeur: 180},
            {nom: 'motdsc', largeur: 250},
            {nom: 'flgtrans', largeur: 100},
            {nom: 'flgimp', largeur: 100},
            {nom: 'connumdoc', largeur: 110}
        ]
    };

}
