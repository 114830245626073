import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageRr0080 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceEmpOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0080Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0080 = this;


    vm.multiOccurrenceEmpOptions = {
        bloc: 'mdm',
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 90 },
            { nom: 'eplnomprn', largeur: 170 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint')
        ], { largeurDefaut: 50 })
    };
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'unpcod', largeur: 100 },
            { nom: 'unpdsc', largeur: 100 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'ppadatdeb', largeur: 110 },
            { nom: 'ppadatfin', largeur: 110 },
            { nom: 'vaempatyp', largeur: 100 },
            { nom: 'mpamsg', largeur: 400 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('unpcleint', {
                required: true,
                largeur: 33
            }),
            new FormulaireItem('ppacleint', {
                resourceParams: (data: any) => ({ unpcleint: data.unpcleint }),
                required: true,
                largeur: 33
            }),
            new FormulaireItem('vaempatyp', {
                required: true,
                largeur: 33
            }),
            new FormulaireItem('mpamsg', {
                required: true,
                largeur: 33
            })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceEmpOptions,
    };
}
