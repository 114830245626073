import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMC0098 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0098Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: any) {
    const vm: IPageMC0098 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'lipcleint', valeur: $stateParams.lipcleint, visible: false }
        ],
        colonnesFixesGauche: [
            { nom: 'lipcod', largeur: 70 }
        ],
        colonnesVisibles: [
            {nom: 'lipnom', largeur: 380},
            {nom: 'lipnomabr', largeur: 190},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 260},
            {nom: 'lipadrl01', largeur: 300},
            {nom: 'lipadrl02', largeur: 300},
            {nom: 'lipvil', largeur: 240},
            {nom: 'lippro', largeur: 240},
            {nom: 'lippay', largeur: 240},
            {nom: 'lipcodpos', largeur: 110},
            {nom: 'lipnumtel', largeur: 140},
            {nom: 'lipnumtelpos', largeur: 80},
            {nom: 'lipnumfax', largeur: 144},
            {nom: 'lipadrcou', largeur: 325},
            {nom: 'lipcodref', largeur: 200},
            {nom: 'lipcodrefadr', largeur: 400}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('lipcod', { required: true, largeur: 20 }),
            new FormulaireItem('lipflgadrfac',{largeur: 70}),
            new FormulaireItem('lipnom', { required: true, largeur: 20 }),
            new FormulaireItem('lipnomabr', { required: true, largeur: 20 }),
            new FormulaireItem('intcleint',{largeur: 80}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lipadrl01', { largeur: 30 }),
            new FormulaireItem('lipadrl02', { largeur: 30 }),
            new FormulaireItem('lipvil', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lippro', { largeur: 20 }),
            new FormulaireItem('lippay', { largeur: 20 }),
            new FormulaireItem('lipcodpos', { largeur: 20 }),
            new FormulaireItem('lipflgquarec',{largeur: 70}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lipnumtel', { largeur: 20 }),
            new FormulaireItem('lipnumtelpos', { largeur: 20 }),
            new FormulaireItem('lipnumfax', { largeur: 20 }),
            new FormulaireItem('lipadrcou', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lipcleintref',{largeur: 80}),
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}