import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMC0035 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Mc0035Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageMC0035 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        fonctions: {
            selectionCycle: true,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: localStorage.getItem('forageFromMenu') === 'oui',
            criteresSuggeresVisibles: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleint', {
                readonly: () => !(vm.valeursEcran.flgintequ),  
                required: true,
                default: vm.valeursEcran.intcleint,
                nombreLibellesDetailsCacher: 1
            })
        ]),
        colonnesVisibles: [
            { nom: 'reddsc', largeur: 168 },
            { nom: 'nbrapp', largeur: 63, menu: [
                new MenuItemForage(
                    '',
                    (data: any) => `secure.${data.ecrcod}.ecran`,
                    (data: any) => ({
                        intcleint: data.intcleintapr
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ] }
        ],
        colonnesCachees: ['dapcleint', 'docaffnumdoc01', 'vaedaptypapr', 'ecrcod', 'intcleintapr']
    };    
}