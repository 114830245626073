import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCo0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0005Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCo0005 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'dtpcod', largeur: 100},
            {nom: 'dtpdsc', largeur: 325},
            {nom: 'vacvalcle', largeur: 120},
            {nom: 'vacdsc', largeur: 160}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dtpcod', {required: true}),
            new FormulaireItem('dtpdsc', {required: true}),
            new FormulaireItem('vacdtpcyc')
        ], {largeurDefaut: 33.33}
        ),
        fonctions: Object.assign({
            edition: true,
            nouveau: true,
            rechercheCollapsable: false
        })
    };
}
