import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0016Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0016 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'intcod', largeur: 125 },
            { nom: 'intnom', largeur: 215 },
            { nom: 'orgcod', largeur: 115 },
            { nom: 'orgnom', largeur: 185 },
            { nom: 'nipcod', largeur: 155 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcleint', { largeur: 30 }),
            new FormulaireItem('orgcleint', { largeur: 40 }),
            new FormulaireItem('nipcod')
        ],
            {
                largeurDefaut: 20
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
