import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageGT0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gt0007Controller(Menu: IMenuClass) {
    const vm: IPageGT0007 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'prucod', largeur: 200},
            {nom: 'prudscl01', largeur: 200},
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'orgcod', largeur: 200},
            {nom: 'orgnom', largeur: 200},
            {nom: 'orpcod', largeur: 200},
            {nom: 'prjcod', largeur: 200},
            {nom: 'pdfcod', largeur: 200},
            {nom: 'vaeprutypcptbdg', largeur: 200},
            {nom: 'vaeprutypbdg', largeur: 200},
            {nom: 'cifcod', largeur: 200},
            {nom: 'cifdsc', largeur: 200},
            {nom: 'unacod', largeur: 200},
            {nom: 'unadsc', largeur: 200},
            {nom: 'pruflgstu', largeur: 200},
            {nom: 'pruflgctl', largeur: 200},
            {nom: 'prudatdeb', largeur: 200},
            {nom: 'prudatfin', largeur: 200},
            {nom: 'prusld', largeur: 200}
        ]
    };
}
