import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPagePf0005 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireAutres: IFormulaire;
    corMultiOccurrenceOptions: IMultiOccurrenceOptions;
    xapMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0005Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPagePf0005 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonDupliquer: false
            },
            formulaire: new Formulaire('LBL_BLC_ACE', [
                new FormulaireItem('acecod', { largeur: 33.33 }),
                new FormulaireItem('acedsc', { largeur: 66.66 }),
                new FormulaireItem('tpccleint'),
                new FormulaireItem('piscleint'),
                new FormulaireItem('cabcleint')
            ])
        }

        vm.formulaireAutres = new Formulaire('LBL_BLC_PRE', [
            new FormulaireItem('vaeaceadmpre', { largeur: 100 })
        ]);

        vm.corMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'comcod', largeur: 150 },
                { nom: 'comdsc', largeur: 150 },
                { nom: 'nivcod', largeur: 150 },
                { nom: 'nivdsc', largeur: 150 },
                { nom: 'corcom', largeur: 150 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('comcleint'),
                new FormulaireItem('nivcleintcib', {
                    resourceParams: (data: any) => {
                        return {
                            tyccleint: data.tyccleint
                        };
                    }
                }),
                new FormulaireItem('corcom', { largeur: 100 })
            ])
        };
        vm.xapMultiOccurrenceOptions = {
            bloc: 'xap',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'acecod', largeur: 100 },
                { nom: 'acedsc', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('acecleintref', { largeur: 100 })
            ])
        }
    }
}
