import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPageRe2004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re2004Controller(parametresRecherche: IParametresRechercheService,
                                       ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe2004 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ppacod', largeur: 120},
            {nom: 'tagcod', largeur: 120},
            {nom: 'tagdscabr', largeur: 220},
            {nom: 'aftcod', largeur: 120},
            {nom: 'posnumdoc', largeur: 120},
            {nom: 'lemcod', largeur: 120},
            {nom: 'temcod', largeur: 126},
            {nom: 'unocod', largeur: 120},
            {nom: 'dernumdoc', largeur: 120},
            {nom: 'drpnumdoc', largeur: 120},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 220},
            {nom: 'strcod', largeur: 120},
            {nom: 'strdsc', largeur: 220},
            {nom: 'geccodanc', largeur: 120},
            {nom: 'gecdscanc', largeur: 220},
            {nom: 'eeccodanc', largeur: 140},
            {nom: 'rstsalanc', largeur: 120},
            {nom: 'gecodnou', largeur: 140},
            {nom: 'gecdscnou', largeur: 220},
            {nom: 'eeccodnou', largeur: 120},
            {nom: 'rstsalnou', largeur: 120}
        ],
        colonnesFixesDroite: [
            {nom: 'rstmntcal', largeur: 100},
            {nom: 'rstflgexc', largeur: 100},
            {nom: 'rstflgarr', largeur: 100}
        ],
        colonnesCachees: [
            'rslcleint'
        ]
        
    };
}
