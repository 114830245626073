import { IComponentController, IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IStateParamsService } from 'angular-ui-router';
import AxesAffichage from '../../../../../core/classes/axe-affichage.class';

interface IPageAs0041 extends IComponentController {
    menus: { [key: string]: Array<IMenuItem> },
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    jrsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function As0041Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    dateFilter: IFilterDate,
    $stateParams: IStateParamsService,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAs0041 = this;

    vm.jrsMultiOccurrenceOptions = {
        fonctions: {
            axesAffichageVisible: true,
            effaceCriteres: $stateParams.employe || $stateParams.demcleint,
            criteresSuggeresVisibles: true
        },
        axesAffichage: {
            axes: [
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.CALENDAR,
                    icon: 'calendar_today',
                    lblNom: 'G_LBL_CALENDAR',
                    options: {
                        description: ['actdscaff'],
                        colonneDateDebut: 'sttdatdebfmt',
                        colonneDateFin: 'sttdatfin',
                        colonneHeureDebut: 'stthredeb',
                        colonneHeureFin: 'stthrefin',
                        setDate: true,
                        hideHours: true

                    }
                },
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.LIST,
                    icon: 'view_list',
                    lblNom: 'G_LBL_LIST'
                }
            ]
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleint', {
                largeur: 33,
                default: $stateParams.demcleint || $stateParams.employe,
                hidden: $stateParams.employe,
                readonly: $stateParams.demcleint || $stateParams.employe,
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('date', {
                largeur: 33,
                default: dateFilter(ecranDetails.valeurs.datdef, 'yyyy-MM-dd'),
                required: true
            }),
        ]),
        colonnesVisibles: [
            { nom: 'sttdatdebjourname', largeur: 130 },
            { nom: 'sttdatdebfmt', largeur: 130 },
            { nom: 'actcod', largeur: 130 },
            { nom: 'actdsc', largeur: 150 },
            { nom: 'sttqte', largeur: 130 },
            { nom: 'vaesttprv', largeur: 130 },
            { nom: 'aftcod', largeur: 130 },
            { nom: 'aftdsc', largeur: 250 },
            { nom: 'dernumdoc', largeur: 130 },
            { nom: 'derdsc', largeur: 200 },
            { nom: 'drpnumdoc', largeur: 130 },
            { nom: 'drpdsc', largeur: 200 },
            { nom: 'strcod', largeur: 130 },
            { nom: 'strdsc', largeur: 200 },
            { nom: 'unocod', largeur: 130 },
            { nom: 'unodscabr', largeur: 200 },
            { nom: 'imp', largeur: 300 },
            { nom: 'temcod', largeur: 130 },
            { nom: 'temdsc', largeur: 200 },
            { nom: 'lemcod', largeur: 130 },
            { nom: 'lemdsc', largeur: 200 },
            { nom: 'posnumdoc', largeur: 130 },
            { nom: 'postit', largeur: 200 }
        ],
        colonnesCachees: ['sttdatdebfmt', 'sttdatfin', 'actdscaff']
    }
}
