import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0181 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mc0181Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc0181 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        actions: new Menu([

            new MenuItemAction(
                'LBL_BTN_DETRUIRE',
                'BOUDET',
                () => ({}),
                {
                    iconButton: false,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    },
                    saveFirst: true,
                    messageConfirmation: 'BOUDET_CONFIRM'
                }
            )

        ]),
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'cnvnumlig', largeur: 100},
            {nom: 'cnvcha1', largeur: 120},
            {nom: 'cnvcha2', largeur: 300},
            {nom: 'cnvcha3', largeur: 300},
            {nom: 'cnvcha4', largeur: 150},
            {nom: 'cnvcha5', largeur: 100},
            {nom: 'cnvcha6', largeur: 130},
            {nom: 'cnvcha7', largeur: 200},
            {nom: 'cnvcha8', largeur: 150},
            {nom: 'cnvcha9', largeur: 130},
            {nom: 'cnvcha10', largeur: 150},
            {nom: 'cnvcha11', largeur: 120},
            {nom: 'cnvcha12', largeur: 450}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cnvnumlig', {largeur: 20}),
            new FormulaireItem('cnvcha1',  {largeur: 20}),
            new FormulaireItem('cnvcha2',  {largeur: 20}),
            new FormulaireItem('cnvcha3',  {largeur: 20}),
            new FormulaireItem('cnvcha4',  {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cnvcha5',  {largeur: 20}),
            new FormulaireItem('cnvcha6',  {largeur: 20}),
            new FormulaireItem('cnvcha7',  {largeur: 20}),
            new FormulaireItem('cnvcha8',  {largeur: 20}),
            new FormulaireItem('cnvcha9',  {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cnvcha10', {largeur: 20}),
            new FormulaireItem('cnvcha11', {largeur: 20}),
            new FormulaireItem('cnvcha12', {largeur: 20})
        ])
    };
}
