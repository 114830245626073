import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0174 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceEloOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0174Controller(Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMr0174 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    
    vm.multiOccurrenceEloOptions = {
        bloc: 'elo',
        fonctions: {
            nouveau: vm.valeursEcran.flgmod,
            edition: vm.valeursEcran.flgmod,
            supprime: vm.valeursEcran.flgmod
        },
        colonnesVisibles: [
            { nom: 'stanom', largeur: 25 },
            { nom: 'vaedexmne', largeur: 25 },
            { nom: 'stadsc', largeur: 25 },
            { nom: 'eloelecle', largeur: 25 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('objcleint', { largeur: 35 }),
            new FormulaireItem('eloelecle', { largeur: 35 })
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            nouveau: vm.valeursEcran.flgmod,
            edition: vm.valeursEcran.flgmod,
            supprime: vm.valeursEcran.flgmod
        },
         colonnesVisibles: [
            { nom: 'eldcod', largeur: 8 },
            { nom: 'eldnom', largeur: 120 },
            { nom: 'eldcom', largeur: 120 },
            { nom: 'vaedexmne', largeur: 10 },
            { nom: 'eldnumseq', largeur: 10 },
            { nom: 'eldflgtae', largeur: 10 },
            { nom: 'eldflgengsuj', largeur: 10 },
            { nom: 'eldreqsql', largeur: 10 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('eldcod', { largeur: 20 }),
            new FormulaireItem('eldnom', { largeur: 30 }),
            new FormulaireItem('eldcom', { largeur: 40 }),
            new FormulaireItem('vaedexmne', { largeur: 10 }),
            new FormulaireItem('eldnumseq', { largeur: 10 }),
            new FormulaireItem('eldflgtae', { largeur: 10 }),
            new FormulaireItem('eldflgengsuj'),
            new FormulaireItem('eldreqsql', { largeur: 100 })
        ]),        
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceEloOptions
    };
}
