import {
    IMultiOccurrence,
    IMultiOccurrenceOptions,
} from "../../../../../core/services/multi-occurrence.service";
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IStateParamsService } from "angular-ui-router";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRr0514 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0514Controller(
    Operateur: IOperateurService,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageRr0514 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
        },

        colonnesFixesGauche: [
            { nom: "eplnummat", largeur: 100 },
            { nom: "eplnomprn", largeur: 210 },
        ],
        colonnesVisibles: [
            { nom: "dpadatvig", largeur: 100 },
            { nom: "ppacodtrt", largeur: 140 },
            { nom: "vaedpaact", largeur: 160 },
            { nom: "vaedpareg", largeur: 160 },
            { nom: "reference", largeur: 200 },
            { nom: "usrcod", largeur: 140 },
            { nom: "usrnom", largeur: 80 },
            { nom: "datcre", largeur: 130 },
            { nom: "dpacom", largeur: 160 },
        ],
        colonnesFixesDroite: [
            { nom: "dpaflgman", largeur: 80 },
            { nom: "dpaflgann", largeur: 80 },
            { nom: "dpaflgmassive", largeur: 140 },
        ],
        colonnesCachees: ["dpacleint"],
        formulaire: new Formulaire([
            new FormulaireItem("demcleint", {
                readonly: true,
                default: $stateParams.demcleint,
                required: $stateParams.demcleint === undefined ? true : false,
            }),
            new FormulaireItem("dpadatvig"),
            new FormulaireItem("vaedpaact", {
                disabled: true,
            }),
            new FormulaireItem("vaedpareg", {
                disabled: true,
            }),
            new FormulaireItem("datcre", {
                readonly: true,
            }),
            new FormulaireItem("dpacom"),
            new FormulaireItem("dpaflgman", {
                default: true,
            }),
            new FormulaireItem("dpaflgann"),
            new FormulaireItem("dpaflgmassive"),
            new FormulaireItem("vaesysmnetab", {
                hidden: true,
            }),
            new FormulaireItem("dpacleintref", {
                hidden: true,
            }),
        ]),
    };
}
