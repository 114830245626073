import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMC0134 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0134Controller(mc0135Route: IRoute,
                                         Menu: IMenuClass) {
    const vm: IPageMC0134 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0135Route.NAME,
        actionsNouveaux: new Menu([]),
        fonctions:{
            nouveau: false,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'peccod', largeur: 80},
            {nom: 'cifcod', largeur: 89},
            {nom: 'cifdsc', largeur: 262},
            {nom: 'el1cod', largeur: 95},
            {nom: 'orgcod', largeur: 100},
            {nom: 'orgnomabr', largeur: 210},
            {nom: 'refcod', largeur: 90},
            {nom: 'refnomabr', largeur: 274},
            {nom: 'bfiflgsldfdsnonrst', largeur: 125},
            {nom: 'bfiflgspcapprep', largeur: 110},
            {nom: 'cptcod_app', largeur: 100},
            {nom: 'cptdscabr_app', largeur: 210},
            {nom: 'cptcod_car', largeur: 95},
            {nom: 'cptdscabr_car', largeur: 210},
            {nom: 'cptcod_rev', largeur: 210},
            {nom: 'cptdscabr_rev', largeur: 210},
            {nom: 'prulst', largeur: 210},
            {nom: 'cptlst', largeur: 210},
            {nom: 'bfimntrevtot', largeur: 126},
            {nom: 'bfimntcar', largeur: 126},
            {nom: 'bfimntdeptot', largeur: 126},
            {nom: 'ecart', largeur: 126},
            {nom: 'bfimntrevtotann', largeur: 126},
            {nom: 'bfimntdeptotann', largeur: 126},
            {nom: 'bfimntdepcapann', largeur: 126},
            {nom: 'bfiflgexcecjpre', largeur: 80},
            {nom: 'bfiflgexcecjcap', largeur: 80}
        ]
    };
}
