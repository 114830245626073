import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0106 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0106Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0106 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'percod', largeur: 90},
            {nom: 'perdsc', largeur: 262},
            {nom: 'vaesysperind', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('percod'      , {largeur: 33.33, required: true}),
            new FormulaireItem('perdsc'      , {largeur: 33.33, required: true}),
            new FormulaireItem('vaesysperind', {largeur: 33.33, required: true})
        ]),
        fonctions: Object.assign({
            edition: true,
            nouveau: true,
            rechercheCollapsable: false
        })
    };
}
