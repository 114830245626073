import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cp0004',
    ecrans: [
        '00-2',
        '01-2',
    ]
});

