import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {
    FormulaireElement,
    IFormulaire,
    IFormulaireClass
} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRR0093 extends IComponentController {
    menus: {[key: string]: Array<IMenuItem>};
    menuSiru: {[col: string]: Array<FormulaireElement>};
    valeursEcran: any;
    formulaireEpl: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    etlMultiOccurrence: IMultiOccurrence;
    eadMultiOccurrenceOptions: IMultiOccurrenceOptions;
    etlMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ideMultiOccurrenceOptions : IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0093Controller(co0021Route: IRoute,
                                         rr0016Route: IRoute,
                                         rr0017Route: IRoute,
                                         rr0095Route: IRoute,
                                         rr0097Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Pagination: IPaginationClass) {
    const vm: IPageRR0093 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.menuSiru = {
            eplsrucod: [
                new MenuItemAction(
                    'LBL_EDIT_SIRU',
                    'BOUEDTSIRU',
                    () => ({
                        eplcleint: vm.monoOccurrence.data.eplcleint
                    }),
                    {
                        icon: 'edit',
                        champsRechargement: ['eplsrucod'],
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_EDIT_SIRU',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 40,
                            formulaire: new Formulaire([
                                new FormulaireItem('edit_siru', { default: () => vm.monoOccurrence.data.eplsrucod, largeur: 100, maxlength: 8 })
                            ])
                        }
                    }
                )
            ]
        };

        vm.forageEditionZoneMessage = new MenuItemAction(
            'LBL_EDIT_MSG',
            'BOUEDTMSG',
            () => ({
                eplcleint: vm.monoOccurrence.data.eplcleint
            }),
            {
                icon: 'edit',
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_EDIT_MSG',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItem('edit_msg', { default: () => vm.monoOccurrence.data.eplmsg, largeur: 100 })
                    ])
                }
            }
        );

        vm.forageEditionCom = new MenuItemAction(
            'LBL_EDIT_COM',
            'BOUEDTCOM',
            () => ({
                eplcleint: vm.monoOccurrence.data.eplcleint}),
            {
                icon: 'edit',
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_EDIT_COM',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItem('edit_com', { default: () => vm.monoOccurrence.data.eplcomtps, largeur: 100 })
                    ])
                }
            }
        );

        vm.menus = {
            lemcleint: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0095Route.NAME,
                    (data: any) => ({
                        id: data.lemcleint,
                        lemcleint: data.lemcleint,
                        demcleint: data.demcleint
                    }),
                    { hidden: (data: any) => !data.lemcleint,
                        fonction: 'BOULEM' }
                )
            ],
            aftcoddscfmt: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0097Route.NAME,
                    (data: any) => ({
                        id: data.aftcleint
                    }),
                    { hidden: (data: any) => !data.aftcoddscfmt,
                      fonction: 'BOUAFT' }
                )
            ]
        };

        vm.monoOccurrenceOptions = {
            fonctions: {
                suiviModification: false,
                boutonEnregistrerHaut: false
            },
            formulaire: new Formulaire('TITRE_DETAIL_EPL', [])
        };

        // Adresse
        vm.eadMultiOccurrenceOptions = {
            bloc: 'ead',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    rr0016Route.NAME,
                    () => {
                        return {
                            eplcleint: vm.monoOccurrence.data.eplcleint
                        }
                    },
                    {fonction: 'BOUNOUV'}
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    rr0016Route.NAME,
                    (data: any) => ({
                        id: data.eadcleint
                    }),
                    {
                        icon: 'filter_none',
                        iconButton: true,
                        fonction: 'BOUADR'
                    }
                )
            ]),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                'eadadrfmt',
                'eaddatvig',
                'eadflgchq',
                'eadflgfinann'
            ]
        };

        // Compétence scolaire
        vm.ideMultiOccurrenceOptions = {
            bloc: 'ide',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    'G_LBL_COMREQ',
                    co0021Route.NAME,
                    () => ({
                        idecleintref: vm.monoOccurrence.data.eplcleint,
                        stamnetab: 'EPL'
                    })
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'G_LBL_COMREQ',
                    co0021Route.NAME,
                    (data: any) => ({
                        id: data.idecleint,
                        stamnetab: data.stamnetab,
                        idecleintref: data.idecleintref,
                        comcleint: data.comcleint
                    })
                )
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                'comcod', 'comdsc', 'dipcod', 'dipdsc', 'scldscprg', 'ideannobt'
            ],
            colonnesCachees: [
                'stamnetab', 'idecleintref', 'comcleint', 'sclcleint'
            ]
        };

        // Téléphone
        vm.etlMultiOccurrenceOptions = {
            bloc: 'etl',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O'
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    rr0017Route.NAME,
                    () => ({
                        eplcleint: vm.monoOccurrence.data.eplcleint
                    }),
                    {
                        fonction: 'BOUNOUV',
                        onClose() {
                            vm.etlMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    rr0017Route.NAME,
                    (data: any) => ({
                        id: data.etlcleint
                    }),
                    {
                        icon: 'filter_none',
                        iconButton: true,
                        fonction: 'BOUTEL',
                        onClose() {
                            vm.etlMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                'etlnumtel',
                'etladrcou',
                'etldsc',
                'etldatvig',
                'ttldsc',
            ]
        };
    }
}
