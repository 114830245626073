import {IComponentController, IPromise, IQService, IScope} from 'angular';
import {IDialog} from '../../../../core/services/dialog.service';
import {IDataType} from '../../../../core/services/data-types/data-type.service';
import {ISchemaItem} from '../../../../core/interfaces/schemas.interface';
import {IMultiOccurrence} from '../../../../core/services/multi-occurrence.service';
import {IApiConfig} from '../../../../core/interfaces/api-config.interface';
import {IRequerant} from '../../../../core/dialogs/dialog-requerant/dialog-requerant.controller';
import {IFilterLibelle} from '../../../../core/filters/ex-libelle.filter';
import {IApiError} from '../../../../core/interfaces/api-error.interface';
import {IComposantMonoOccurrenceEcran} from '../../../../core/components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import {INotificationHandler} from '../../../../core/services/utils/notification-handler.service';
import {IParametresSecuriteService} from '../../../../core/services/parametres-securite.service';
import {IEcranContextController} from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IResourceService = angular.resource.IResourceService;
import IDialogOptions = angular.material.IDialogOptions;
import {IKeyCodes} from '../../../../core/constants/key-codes.constant';

interface IComposantInputLovRequerant extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    data: any;
    dataType: IDataType;
    schemaItem: ISchemaItem;
    col: string;
    readonly: boolean;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    nonModifiable: boolean;
    loading: boolean;
    listenedBloc: string;
    watchedBlocInitialized: boolean;
    onKeydown(event: MouseEvent): void;
    openEdition(event: MouseEvent): void;
    isReadonly(): boolean;
}

/* @ngInject */
export default function InputLovRequerantController(DialogRequerant: IDialog,
                                                    exLibelleFilter: IFilterLibelle,
                                                    $q: IQService,
                                                    $resource: IResourceService,
                                                    notificationHandler: INotificationHandler,
                                                    ApiConfig: IApiConfig,
                                                    $scope: IScope,
                                                    parametresSecurite: IParametresSecuriteService,
                                                    keyCodes: IKeyCodes) {
    const vm: IComposantInputLovRequerant = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.openEdition = openEdition;
    vm.isReadonly = isReadonly;
    vm.onKeydown = onKeydown;

    function $onInit() {
        initWatcher();
    }

    function $onDestroy() {
        if (vm.listenedBloc) {
            //On s'assure de retirer le listener
            vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[vm.listenedBloc.toUpperCase()].removeListener('dataListUpdate', updateNonModifiable);
        }
    }

    function onKeydown(event: MouseEvent) {
        switch (event.which) {
            case keyCodes.DOWN:
            case keyCodes.UP:
                openEdition(event);
                break;
        }
    }

    function openEdition(event: MouseEvent): void {
        if (!vm.monoOccurrenceEcranCtrl.monoOccurrence.hasChangementsNonSauvegardes(vm.monoOccurrenceEcranCtrl.formData)) {
            const dialogOptions: IDialogOptions = {
                targetEvent: event,
                locals: {
                    icon: 'edit',
                    lblTitre: 'G_LBL_MOD_REQUERANT',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    data: vm.data,
                    dataType: vm.dataType,
                    schemaItem: vm.schemaItem,
                    col: vm.col,
                    cleint: vm.monoOccurrenceEcranCtrl.monoOccurrence.cleint,
                    srccodref: vm.srccodref,
                    stateParams: vm.ecranContextCtrl.stateParams,
                    ecranContext: vm.ecranContextCtrl
                }
            };

            DialogRequerant.show(dialogOptions)
                .then((newValue: IRequerant) => {
                    vm.loading = true;
                    //À la sortie de la modale, on enregistre directement le changement effectué
                    const updatedValues = Object.assign({}, vm.monoOccurrenceEcranCtrl.formData, newValue);
                    vm.monoOccurrenceEcranCtrl.monoOccurrence.save(updatedValues)
                        .then(() => {
                            Object.assign(vm.monoOccurrenceEcranCtrl.formData, updatedValues);

                            notificationHandler.succes();
                        })
                        .catch((err) => {
                            afficherConfirmerSauvegarde(err, updatedValues);
                        })
                        .finally(() => {
                            vm.loading = false;
                        })
                });
        } else {
            afficherErreurModifParent();
        }
    }

    function isReadonly(): boolean {
        return vm.readonly || vm.nonModifiable || vm.loading;
    }

    function initWatcher(): void {
        getNombreEnregistrementsBloc()
            .then((nombreEnregistrementsChargement: number) => {
                vm.nonModifiable = Boolean(nombreEnregistrementsChargement);

                const bloc = vm?.dataType?.params?.bloc?.toUpperCase();
                const binding = `::vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants.${bloc}`;

                $scope.$watch(binding, (multiOccurrence: IMultiOccurrence) => {
                    if (multiOccurrence) {
                        vm.listenedBloc = multiOccurrence.bloc;
                        multiOccurrence.on('dataListUpdate', updateNonModifiable);
                    }
                });

            });
    }

    function updateNonModifiable() {
        if (vm.watchedBlocInitialized) {
            getNombreEnregistrementsBloc()
                .then((nombreEnregistrements: number) => {
                    vm.nonModifiable = Boolean(nombreEnregistrements);
                });
        } else {
            vm.watchedBlocInitialized = true;
        }
    }

    function getNombreEnregistrementsBloc(): IPromise<number> {
        const cleint = vm.monoOccurrenceEcranCtrl.monoOccurrence.cleint;
        if (vm.monoOccurrenceEcranCtrl.monoOccurrence.data[cleint]) {
            vm.loading = true;
            const id = vm.monoOccurrenceEcranCtrl.monoOccurrence.data[cleint];
            const srccod = vm.monoOccurrenceEcranCtrl.monoOccurrence.srccod;

            const requete = $resource(`${ApiConfig.ROOT}/actions-bouton-requerant/get-nombre-enreg-bloc/:id`, {
                id,
                srccod,
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            });

            return requete.get().$promise
                .then((result: any) => {
                    return result.nombreEnregistrements;
                })
                .finally(() => {
                    vm.loading = false;
                });
        } else {
            return $q.resolve(0);
        }
    }

    function afficherErreurModifParent(): IPromise<any> {
        return notificationHandler.erreur({
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: 'G_MSG_ENREG_MODIF_PARENT'
        });
    }

    function afficherConfirmerSauvegarde(error: IApiError, data: any): IPromise<any> {
        return notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: 'G_MSG_SAUV_ENR',
            confirmAction: () => {
                return vm.monoOccurrenceEcranCtrl.monoOccurrence.save(data)
                    .then(() => {
                        Object.assign(vm.monoOccurrenceEcranCtrl.formData, data);
                    });
            }
        });
    }
}
