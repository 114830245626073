import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCr0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0015Controller(cr0011Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageCr0015 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'lcrflgcmp',
                operateur: Operateur.EGALE,
                valeur: '1'
            }
        ],        
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_COMPLETER',
                'BOUCOM',
                (data:any) => ({ lcrcleint: data.lcrcleint }),
                {
                    hidden: (data:any) => (!data.lcrindcom),
                    fonction: 'BOUCOM',
                    icon: 'update'
                }
            ),
            new MenuItemAction(
                'LBL_BNT_REPORTER',
                'BOUREP',
                (data:any) => ({ lcrcleint: data.lcrcleint }),
                {
                    hidden: (data:any) => (!data.lcrindrep),
                    fonction: 'BOUREP',
                    icon: 'update'
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'lcrcod', largeur: 100},
            {nom: 'peccod', largeur: 100},
            {nom: 'lcrstu', largeur: 100},
            {nom: 'usrcod', largeur: 130},
            {nom: 'lcrflgstu', largeur: 100},
            {nom: 'lcrindcom', largeur: 100},
            {nom: 'lcrindrep', largeur: 100},
            {nom: 'lcrdatrep', largeur: 126},
            {nom: 'lcrnbrfac', 
                    largeur: 130, 
                    menu: [
                        new MenuItemForage(
                            'G_LBL_FONC_TRANS_DOCUMENT',
                            cr0011Route.NAME,
                            (rowData: any) => {
                                return {
                                    lcrcleint: rowData.lcrcleint
                                };
                            }
                        )
                    ]},
            {nom: 'lcrnbrfacrep', largeur: 130},
            {nom: 'lcrmntfac', largeur: 126},
            {nom: 'lcrdsc', largeur: 250}
        ],
        colonnesCachees: [
            'lcrcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('lcrcod'),
            new FormulaireItem('peccleint'),
            new FormulaireItem('lcrflgstu', {default: 1}),
            new FormulaireItem('lcrdsc', {largeur: 100})
        ], {largeurDefaut:33})
    };
}
