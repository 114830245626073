import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0239 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0239Params {
    chgcleint: string;
}

/* @ngInject */
export default function Mr0239Controller(Formulaire: IFormulaireClass,
    $stateParams: IMr0239Params,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0239 = this;

    vm.multiOccurrenceOptions = {
        filtres: [{
            colonne: 'chgcleint',
            valeur: $stateParams.chgcleint,
            readOnly: true
        }],
        colonnesVisibles: [
            { nom: 'rahdatvig', largeur: 110 },
            { nom: 'rsccod', largeur: 120 },
            { nom: 'rscdsc', largeur: 190 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rahdatvig', { largeur: 25 }),
            new FormulaireItem('rsccleint', { largeur: 50 }),
            new FormulaireItem('chgcleint', { hidden: true, default: $stateParams.chgcleint }),
        ]),
        fonctions:
            { rechercheCollapsable: false }
    };
}
