import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageRr0102 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function rr0102Controller(Formulaire: IFormulaireClass,
                                         rr0127Route: IRoute,
                                         Operateur: IOperateurService,
                                         FormulaireItem: IFormulaireItemClass,
                                         rr0036Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRr0102 = this;

    // Forage pour la compétence
    const forageDaf = new MenuItemForage(
        rr0036Route.NAME ,
        (data: any) => ({
            id: data.dafcleint_trn
        }),
        {
            hidden: (data: any) => !data.dafcleint_trn
        }
    );
    vm.multiOccurrenceOptions = {
        fonctions: {
          selectionnerUnEtat: false,
          rechercheCollapsable: false
        },
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_FORAGE_RR0127',
                rr0127Route.NAME,
                (data: any) => ({
                    anrann: vm.multiOccurrence.etatSelected.criteresSuggeresData.adrann
                }),
                {
                    icon: 'alarm',
                    fonction: 'BOUAVI'
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('adrann', {
                required: true
            }),
            new FormulaireItem('urgcleintcri', ),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('qteadmde'),
            new FormulaireItem('qteadma'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('mntadmde'),
            new FormulaireItem('mntadma')
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 200},
            {nom: 'adrqteadmtot', largeur: 126},
            {nom: 'adrmntadmtot', largeur: 126},
            {nom: 'adrflgadm', largeur: 120},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'adrqteadm', largeur: 126},
            {nom: 'adrmntadm', largeur: 126},
            {nom: 'adrdatret', largeur: 100},
            {nom: 'adrtypaft', largeur: 131},
            {nom: 'adrflgabsdatnai', largeur: 140},
            {nom: 'ddadatref', largeur: 100},
            {nom: 'vacvalcle', largeur: 100},
            {nom: 'vacdsc', largeur: 262}
        ],
        colonnesFixesDroite: [
            {nom: 'adrflgtrt', largeur: 120},
            {nom: 'adrflgdaf', largeur: 120, menu: [forageDaf]}
        ],
        colonnesCachees: [
            'adrann',
            'dafcleint_trn',
            'vaesystypavi'
        ]
    };
}
