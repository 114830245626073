import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";

interface IPageMc0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mc0007Controller(mc0162Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMc0007 = this;

    const forageDevAch = new MenuItemEcran(
        'G_LBL_FONC_TRANS_DOCUMENT',
        mc0162Route.NAME,
        (rowData: any) => {
            return {
                vaesysdevcod: rowData.vaesysdevcod,
                vaedevtyptau: 'ACH'
            };
        },
        {
            icon: 'launch',
            onClose: () => vm.multiOccurrence.fetchDataList()
        }
    );

    const forageDevVen = new MenuItemEcran(
        'G_LBL_FONC_TRANS_DOCUMENT',
        mc0162Route.NAME,
        (rowData: any) => {
            return {
                vaesysdevcod: rowData.vaesysdevcod,
                vaedevtyptau: 'VEN'
            };
        },
        {
            icon: 'launch',
            onClose: () => vm.multiOccurrence.fetchDataList()
        }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaesysdevcod'    , largeur: 120},
            {nom: 'vaesysdevcod_dsc', largeur: 301},
            {nom: 'devtaucou_ach'   , largeur: 140},
            {nom: 'devdateff_ach'   , largeur: 160, menu: [forageDevAch]},
            {nom: 'devtaucou_ven'   , largeur: 140},
            {nom: 'devdateff_ven'   , largeur: 160, menu: [forageDevVen]}
        ]
    };
}
