import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMC0196 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function MC0196Controller() {
    const vm: IPageMC0196 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
           // colonnesDefilables: true
        },
        colonnesFixesGauche: [
            { nom: 'cptcod', largeur: 150 },
            { nom: 'cptdscabr', largeur: 280 }
        ],
        colonnesVisibles: [
            { nom: 'unacodori', largeur: 160 },
            { nom: 'unadscori', largeur: 280 },
            { nom: 'cifcodori', largeur: 130 },
            { nom: 'cifdscori', largeur: 280 },
            { nom: 'blocodori', largeur: 175 },
            { nom: 'blodscori', largeur: 300 },
            { nom: 'blocoddst', largeur: 210 },
            { nom: 'blodscdst', largeur: 310 },
            { nom: 'usrnom', largeur: 164 },
            { nom: 'datcre', largeur: 100 }
        ]
    };
}
