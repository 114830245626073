import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMR0067 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    cliMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sysMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0067Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0067 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('cyrcod'),
            new FormulaireItem('cyrdsc'),
            new FormulaireItem('cyrcom', { largeur: 100 })
        ], { largeurDefaut: 50 })
    };

    vm.cliMultiOccurrenceOptions = {
        bloc: 'cli',
        colonnesVisibles: [
            {nom: 'ctaseqaff', largeur: 100},
            {nom: 'ctadsc', largeur: 200},
            {nom: 'ctacom', largeur: 200}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ctaseqaff', {largeur: 33.33}),
            new FormulaireItem('ctadsc', {largeur: 66}),
            new FormulaireItem('ctacom', {largeur: 100, enableCount: true}),
        ])
    };

    vm.sysMultiOccurrenceOptions = {
        bloc: 'sys',
        colonnesVisibles: [
            {nom: 'ctaseqaff', largeur: 100},
            {nom: 'ctadsc', largeur: 200},
            {nom: 'ctacom', largeur: 200}
        ]
    };
}
