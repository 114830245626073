import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IComponentController } from 'angular';


interface IPageMc0034 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    mccMultiOccurrenceOptions: IMultiOccurrenceOptions;

}
/* @ngInject */
export default function Mc0034Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0034 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'ecrcod', largeur: 300 },
            { nom: 'ecrdsc', largeur: 300 }
        ]
    };

    vm.mccMultiOccurrenceOptions = {
        bloc: 'mcc',
        colonnesVisibles: [
            { nom: 'chcdsc', largeur: 200 },
            { nom: 'mccindobl', largeur: 100 },
            { nom: 'mccindmod', largeur: 100 },
            { nom: 'mccvaldef', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('chccleint', { resourceParams: (data: any) => { return { ecrcleintmcc: data.$blocs.ecr.ecrcleint }; } }),
            new FormulaireItem('mccindobl'),
            new FormulaireItem('mccindmod', { default: 1 }),
            new FormulaireItem('mccvaldef')
        ])
    };

}
