import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import { IMenuItemFactoryClass } from '../../../../../core/services/menu/menu-item-factory.service';
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc2006 {
    errMultiOccurrenceOptions: IMultiOccurrenceOptions;
    trsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function Mc2006Controller(Menu: IMenuClass,
                                         MenuItemFactory: IMenuItemFactoryClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         isForageValide: IIsForageValideService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2006 = this;

    vm.errMultiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]),
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 120},
            {nom: 'docaffnumdoc01', largeur: 300},
            {nom: 'errdsc', largeur: 160}
        ],
        colonnesCachees: [
            'ecrcod'
        ]
    };

    vm.trsMultiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]),
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 120},
            {nom: 'docaffnumdoc01', largeur: 300},
            {nom: 'etddsc', largeur: 160},
            {nom: 'etddscpro', largeur: 160},
            {nom: 'mctdatanc', largeur: 160},
            {nom: 'mctdatnou', largeur: 160}
        ],
        colonnesCachees: [
            'ecrcod'
        ]
    };

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_PCM', [
            new FormulaireItem('peccod'),
            new FormulaireItem('pecann'),
            new FormulaireItem('pecper'),
            new FormulaireItem('pecdatdeb'),
            new FormulaireItem('pecdatfin'),
            new FormulaireItem('pecflgreg'),
            new FormulaireItem('pcmdatfer'),
            new FormulaireItem('vaepcmstu'),
        ], {largeurDefaut: 33})
    }

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => {
                return `secure.${data.ecrcod.replace('-00-2', '').replace('-00', '').toLowerCase()}.ecran`;
            },
            (data: any) => ({
                id: data.doccleint
            }),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('mc2006', data.ecrcod.replace('-00-2', '').replace('-00', ''));
                }
            }
        );
    }
}
