import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';

interface IPageMC0124 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0124Controller(mc0123Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         isForageValide: IIsForageValideService) {
    const vm: IPageMC0124 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccodde', {largeur: 20,
                                            nombreLibellesDetailsCacher: 1}),
            new FormulaireItem('peccoda', {largeur: 20,
                                            nombreLibellesDetailsCacher: 1}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                },
                largeur: 33.33
            }),
            new FormulaireItem('intcleintreq', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('docaffdsc01_cri', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('doccleint'),
            new FormulaireItem('mfocleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vecmfonaf_cri'),
            new FormulaireItem('vecmfogre_cri'),
            new FormulaireItem('vecmfotyp_cri'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('grpflgtra', {default: '2'})
        ], {
            largeurDefaut: 33.33
        }),
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 95},
            {nom: 'docaffnumdoc01', largeur: 135, menu: [getForageItem()]},
            {nom: 'docaffdesc01', largeur: 230},
            {nom: 'peccod', largeur: 90},
            {nom: 'docaffmnt01', largeur: 155},
            {nom: 'mdfflgtra', largeur: 70},
            {nom: 'mfonumcod', largeur: 115, menu: [new MenuItemForage(
                'LBL_FORAGE_FORMATION',
                mc0123Route.NAME,
                (data: any) => ({
                    id: data.mfocleint
                }),
                {
                    hidden: (data: any) => !data.mfocleint
                }
            )]},
            {nom: 'mfodesc', largeur: 135},
            {nom: 'vecmfonaf', largeur: 115},
            {nom: 'vecdscnaf', largeur: 165},
            {nom: 'vecmfogre', largeur: 115},
            {nom: 'vecdscgre', largeur: 165},
            {nom: 'vecmfotyp', largeur: 115},
            {nom: 'vecdsctyp', largeur: 165},
            {nom: 'dfdmnt', largeur: 155}
        ],
        colonnesCachees: [
            'ecrcod',
            'docaffcleint01'
        ]
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcod}.ecran`),
            (data: any) => ({
                id: data.docaffcleint01
            }),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('mc0124', data.ecrcod);
                }
            }
        )
    }
}
