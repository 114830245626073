import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageRr0524 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0524Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0524 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 262 }
        ],
        colonnesVisibles: [
            { nom: 'lemcod', largeur: 120 },
            { nom: 'lemdsc', largeur: 250 },
            { nom: 'epxdatdeb', largeur: 120 },
            { nom: 'epxdatfin', largeur: 100 },
            { nom: 'epxflgint', largeur: 130 },
            { nom: 'epxflgaut', largeur: 150 },
            { nom: 'epxcom', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', { required: true }),
            new FormulaireItem('lemcleint', { required: true }),
            new FormulaireItem('epxdatdeb'),
            new FormulaireItem('epxdatfin'),
            new FormulaireItem('epxflgint'),
            new FormulaireItem('epxflgaut', { default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('epxcom', { largeur: 100, enableCount: true })
        ], { largeurDefaut: 25 })
    };
}
