import {module} from 'angular';
import {IAccesManager} from '../../core/services/acces-manager.service';

export default module('app.run', []).run(RunApp);

/* @ngInject */
function RunApp(accesManager: IAccesManager) {

    //Exécute les accès de l'application
    accesManager.start();
}
