import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageTp0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tp0003Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTp0003 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vaektgcat', largeur: 120},
            {nom: 'ktgtit', largeur: 330},
            {nom: 'ktgflgact', largeur: 64}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaektgcat', {required: true}),
            new FormulaireItem('ktgtit', {required: true, largeur: 50}),
            new FormulaireItem('ktgflgact', {default: 1})
        ], {largeurDefaut: 25})
    };
}
