import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCo0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0007Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCo0007 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'dipcod', largeur: 90},
            {nom: 'dipdsc', largeur: 265},
            {nom: 'dtpcod', largeur: 90},
            {nom: 'dtpdsc', largeur: 280},
            {nom: 'clacod', largeur: 80},
            {nom: 'cladsc', largeur: 242}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dipcod', {required: true}),
            new FormulaireItem('dipdsc', {required: true}),
            new FormulaireItem('dtpcleint', {required: true}),
            new FormulaireItem('clacleint')
        ]),
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
    };
}
