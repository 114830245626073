import { IComponentController, IFilterDate } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { ISourceDetails } from '../../../../../core/resources/source-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IStateParamsService, IStateService } from 'angular-ui-router';

interface IPageRr0129 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireEpl: IFormulaire;
}

/* @ngInject */
export default function Rr0129Controller(dateFilter: IFilterDate,
    ecranSourceDetails: ISourceDetails,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    rr0149Route: IRoute,
    MenuItem: IMenuItemClass,
    $stateParams: IStateParamsService,
    $state: IStateService) {
    const vm: IPageRr0129 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.colsAut = ['eicchpcar1', 'eicchpcar2', 'eicchpcar3', 'eicchpcar4', 'eicchpcar5',
            'eicchpcar6', 'eicchpcar7', 'eicchpcar8', 'eicchpcar9', 'eicchpcar10',
            'eicchpcar11', 'eicchpcar12', 'eicchpcar13', 'eicchpcar14', 'eicchpcar15',
            'eicchpcar16', 'eicchpcar17', 'eicchpcar18', 'eicchpcar19', 'eicchpcar20',
            'eicchpcar21', 'eicchpcar22', 'eicchpcar23', 'eicchpcar24', 'eicchpcar25',
            'eicchpcar26', 'eicchpcar27', 'eicchpcar28', 'eicchpcar29', 'eicchpcar30',
            'eicflgnum1', 'eicflgnum2', 'eicflgnum3', 'eicflgnum4', 'eicflgnum5',
            'eicflgnum6', 'eicflgnum7', 'eicflgnum8', 'eicflgnum9', 'eicflgnum10',
            'eicflgnum11', 'eicflgnum12', 'eicflgnum13', 'eicflgnum14', 'eicflgnum15',
            'eicflgnum16', 'eicflgnum17', 'eicflgnum18', 'eicflgnum19', 'eicflgnum20',
            'eicflgnum21', 'eicflgnum22', 'eicflgnum23', 'eicflgnum24', 'eicflgnum25',
            'eicflgnum26', 'eicflgnum27', 'eicflgnum28', 'eicflgnum29', 'eicflgnum30'].filter((col: string) => {
                return ecranSourceDetails.libelles.$libelles[col.toUpperCase()] !== '-';
            });

        vm.monoOccurrenceOptions = {
            fonctions: {
                boutonEnregistrerHaut: false,
                skipChangementsNonSauvegardes: true,
                boutonDupliquer: false,
                exportation: false
            },
            formulaire: new Formulaire('LBL_BLC_EMP', [
                new FormulaireTemplateSlot('cartePrincipale')
            ])
        };

        vm.formulaireEpl = new Formulaire('LBL_CRITERES', [
            new FormulaireItem("emprech", {
                required: true, onValueChange: (data) => {
                    vm.monoOccurrence.emit("forceInitLovs")
                    data.demcleint = undefined
                    data.demcleint__eplnummat = undefined
                    data.demcleint__eplnomprn = undefined
                }, default: $stateParams.emprech
            }),
            new FormulaireItem('demcleint', { disabled: data => !data.emprech, resourceParams: (data: any) => ({ emprech: data.emprech }),
            nombreLibellesDetailsCacher: 8,
            minWidth: 1 // Pour éviter que le champ s'agrandisse trop à cause des menus 
        }),
            new FormulaireItem('date_tmp', {
                default: $stateParams.date_tmp || dateFilter(new Date(), 'yyyy-MM-dd'),
                menu: [
                    new MenuItem('G_LBL_BAR_RECHERCHE', (event: Event, data: any) => {
                        $state.go(rr0149Route.NAME, {
                            employe: data.demcleint,
                            date_tmp: data.date_tmp,
                            emprech: data.emprech
                        });
                    }, {
                        icon: "",
                        iconButton: false,
                        class: "md-raised ex-button-action"
                    })
                ]
            })
        ], { largeurDefaut: 100 / 3 });
    }
}
