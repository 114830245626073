import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IStateParamsService} from 'angular-ui-router';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageRr0536 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Rr0536Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $stateParams: IStateParamsService,
                                         MenuItemForage: IMenuItemForageClass,
                                         Pagination: IPaginationClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         rr0003Route: IRoute, rr0065Route: IRoute, as0023Route: IRoute, rr0002Route: IRoute,
                                         rr0022Route: IRoute, rr0024Route: IRoute, rr0006Route: IRoute, rr0060Route: IRoute,
                                         rr0064Route: IRoute, rr0051Route: IRoute, rr0018Route: IRoute, rr0056Route: IRoute,
                                         rr0014Route: IRoute, so0007Route: IRoute, rr0071Route: IRoute, rr0070Route: IRoute,
                                         rr0074Route: IRoute, rr0075Route: IRoute, rr0503Route: IRoute, rr0031Route: IRoute) {
    const vm: IPageRr0536 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const criteresSuggeresVisibles = !$stateParams.employe && !$stateParams.demcleint;

    // à enlever quand tous les appels seront présent
    const accueilRoute = {NAME: 'secure.accueil'};

    const forageEcran = new MenuItemForage(
        'LBL_FOR_TYP',
        (data: any) => getForage(data),
        (data: any) => getParams(data),
        { hidden: (data: any) => data.reference === null }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles,
            rechercheCollapsable: false,
            effaceCriteres: true, 
            importation : true
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 200]
        }),
        criteresSuggeres: new Formulaire([

            new FormulaireItem('t_unpcleint', {
                required: true,
                readonly: $stateParams.employe || vm.valeursEcran.t_demcleint,
                ignoreEtat: vm.valeursEcran.t_demcleint || $stateParams.employe,
                default: vm.valeursEcran.t_unpcleint
            }),

            new FormulaireItem('t_demcleint', {
                hidden:  $stateParams.employe,
                readonly: vm.valeursEcran.t_demcleint,
                ignoreEtat: vm.valeursEcran.t_demcleint || $stateParams.employe,
                default: vm.valeursEcran.t_demcleint || $stateParams.employe
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('t_statut',{
                direction: 'vertical',
                readonly: $stateParams.statut, 
                default: $stateParams.statut ? $stateParams.statut : '1'
            }),

            new FormulaireItem('t_periode',{
                direction: 'vertical',
                default: '1'
            }),

            new FormulaireItem('t_flgoff', {
                direction: 'vertical',
                default: '1'
            })

        ], {largeurDefaut: 33.33}),

        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 260, hidden: $stateParams.employe}
        ],

        colonnesVisibles: [
            {nom: 'datvig', largeur: 100},
            {nom: 'ppacodori', largeur: 85},
            {nom: 'vaeact_dsc', largeur: 170},
            {nom: 'vaereg_dsc', largeur: 200},
            {nom: 'reference', largeur: 500, menu: [forageEcran]},
            {nom: 'usrcodcre', largeur: 120},
            {nom: 'usrnomcre', largeur: 260},
            {nom: 'datcreaff', largeur: 180},
            {nom: 'commentaire', largeur: 400}
        ],

        colonnesFixesDroite: [
            {nom: 'flgman', largeur: 80},
            {nom: 'flgann', largeur: 80},
            {nom: 'flgtraite', largeur: 80}
        ],

        colonnesCachees: [
            'employe_eplcleint',
            'dpacleint',
            'dpacleintref',
            'trncleintref',
            'vaesysmnetab',
            'cleintref',
            'vaereg',
            'demcleint'
        ],

        formulaire: new Formulaire([
            new FormulaireItem('t_unpcleint', {default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_unpcleint, hidden: true}),
            new FormulaireItem('demcleint', {default: () =>  vm.multiOccurrence.etatSelected.criteresSuggeresData.t_demcleint, hidden: true }),
            new FormulaireItem('dpadatvig'),
            new FormulaireItem('dpaflgman',{default: 1}),
            new FormulaireItem('dpaflgann',{default: 0}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaedpaact',{default: '0010'}),
            new FormulaireItem('vaedpareg',{default: 'DPA'}),
            new FormulaireItem('dpacom',{largeur: 50})

        ],{largeurDefaut:25})

    };

    function getForage(data: any): string {
        if (data.vaesysmnetab === 'EPL' || data.vaesysmnetab === 'DEM') {
            return rr0003Route.NAME;
        } else if (data.vaesysmnetab === 'BQA') {
            return rr0065Route.NAME;
        } else if (data.vaesysmnetab === 'HHS') {
            return as0023Route.NAME;
        } else if (data.vaesysmnetab === 'TSE') {
            return rr0002Route.NAME;
        } else if (data.vaesysmnetab === 'TSA') {
            return rr0022Route.NAME;
        } else if (data.vaesysmnetab === 'TSM') {
            return rr0024Route.NAME;
        } else if (data.vaesysmnetab === 'AFP' || data.vaesysmnetab === 'AFT' || data.vaesysmnetab === 'SUS') {
            return rr0006Route.NAME;
        } else if (data.vaesysmnetab === 'AID') {
            return rr0060Route.NAME;
        } else if (data.vaesysmnetab === 'DBE') {
            return rr0064Route.NAME;
        } else if (data.vaesysmnetab === 'DCI') {
            return rr0051Route.NAME;
        } else if (data.vaesysmnetab === 'DPR') {
            return rr0018Route.NAME;
        } else if (data.vaesysmnetab === 'DRA') {
            return rr0056Route.NAME;
        } else if (data.vaesysmnetab === 'EFD') {
            return rr0014Route.NAME;
        } else if (data.vaesysmnetab === 'POP') {
            return so0007Route.NAME;
        } else if (data.vaesysmnetab === 'DAS') {
            return rr0071Route.NAME;
        } else if ((data.vaesysmnetab === 'DRD' && data.vaereg === 'DRD1') || data.vaesysmnetab === 'ECE' || data.vaesysmnetab === 'ECD') {
            return rr0070Route.NAME;
        } else if (data.vaesysmnetab === 'DRD' && data.vaereg === 'DRD2') {
            return rr0031Route.NAME;
        } else if (data.vaesysmnetab === 'DER') {
            return rr0074Route.NAME;
        } else if (data.vaesysmnetab === 'DRP') {
            return rr0075Route.NAME;
        } else if (data.vaesysmnetab === 'PAM') {
            return rr0503Route.NAME;
        } else {
            return accueilRoute.NAME;
        }
    }

    function getParams(data: any) {
        const MONOS_TRN = ['EPL','DEM','TSE','TSA','AFP','AFT','SUS','POP','DER','DRP'];
        const MONOS = ['AID','DBE','DCI','DAS','PAM'];

        if (data.vaesysmnetab === 'BQA') {
            return { bqacleint: data.cleintref };
        } else if (data.vaesysmnetab === 'HHS' && data.vaereg === 'HHS1') {
            return { id: data.trncleintref, ecran: '01-2' };
        } else if (data.vaesysmnetab === 'HHS' && data.vaereg === 'HHS2') {
            return { id: data.trncleintref };
        } else if (data.vaesysmnetab === 'TSM' && data.vaereg === 'TSM2') {
            return { id: data.trncleintref, ecran: '01-2' };
        } else if (data.vaesysmnetab === 'TSM' && data.vaereg === 'TSM1') {
            return { id: data.trncleintref };
        } else if (data.vaesysmnetab === 'DPR') {
            return { id: data.cleintref, demcleint: data.demcleint };
        } else if (data.vaesysmnetab === 'DRA') {
            return { dracleint: data.cleintref };
        } else if (data.vaesysmnetab === 'EFD') {
            return { id: data.trncleintref, demcleint: data.demcleint };
        } else if ((data.vaesysmnetab === 'DRD' && data.vaereg === 'DRD1' ) ||
                   (data.vaesysmnetab === 'DRD' && data.vaereg === 'DRD2' ) ||
                  data.vaesysmnetab === 'ECE' ||
                  data.vaesysmnetab === 'ECD') {
            return {id: data.trncleintref};
        } else if (MONOS_TRN.includes(data.vaesysmnetab)) {
            return { id: data.trncleintref }
        } else if (MONOS.includes(data.vaesysmnetab)) {
            return { id: data.cleintref }
        } else {
            return { reference: data.dpacleintref }
        }
    }
}
