import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFilterDate} from 'angular';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageCH0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

interface ICh0010Params {
    hadcleint: string;
}
/* @ngInject */
export default function Ch0010Controller(ch0009Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         dateFilter: IFilterDate,
                                         $stateParams: ICh0010Params,
                                         Operateur: IOperateurService,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageCH0010 = this;

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemEcran(
                'LBL_APPEL_EMP',
                ch0009Route.NAME,
                (data: any) => ({
                    hadcleint: $stateParams.hadcleint
                }),
                {
                    icon: 'announcement',
                    iconButton: false
                })
            ]
        ),
        actionsSelection: new Menu([
            // Assigner
            new MenuItemSelection('G_LBL_ASSIGNER', 'BOUASS', {
                selection: {
                    nom: 'assigner'
                },
                successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            }),
            // Assigner et notifier
            new MenuItemSelection('G_LBL_ASSIGNER_NOTIFIER', 'BOUASSNOT', {
                selection: {
                    nom: 'assigNotifie'
                },
                successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            })
        ], {
            disabled: (rowData:any) => rowData.vaedemstatut === 'ASS',
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_vaedemstatut', {
                required: true,
                default: 'DPA'
            }),
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 260},
            {nom: 'vaedemstatut_dsc', largeur: 160},
            {nom: 'tel_cel', largeur: 140},
            {nom: 'tel_dom', largeur: 140}
        ],
        colonnesCachees: [
            'demcleint',
            'vaedemstatut',
            'haecleint'
        ]
    };
}
