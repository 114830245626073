import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';


interface IPageAs0025 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0025Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService,
    as0023Route: IRoute,
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass
) {
    const vm: IPageAs0025 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('asgcleintcri', {
                disabled: $stateParams.asgcleint,
                hidden: !$stateParams.asgcleint
            })
        ]),
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        navigatePage: as0023Route.NAME,
        navigateParams: (data: any) => getParams(data),
        actionsNouveaux: new Menu([
            new MenuItemForage(
                'LBL_POSTE',
                as0023Route.NAME, () => {
                    return {
                        ecran: '00-2',
                        demcleint: $stateParams.employe,
                        asgcleint: $stateParams.asgcleint
                    }
                },
                { icon: '' }
            ),
            new MenuItemForage(
                'LBL_AFFECTATION',
                as0023Route.NAME, () => {
                    return {
                        ecran: '01-2',
                        demcleint: $stateParams.employe,
                        asgcleint: $stateParams.asgcleint
                    }
                },
                { icon: '' }
            )
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesFixesGauche:[
            { nom: 'asgcod', largeur: 160, hidden: $stateParams.asgcleint },
            { nom: 'asgdsc', largeur: 272, hidden: $stateParams.asgcleint }
        ],
        colonnesVisibles: [
            { nom: 'ahtcod_cod', largeur: 120 },
            { nom: 'ahtdsc', largeur: 272 },
            { nom: 'flgahtman', largeur: 120 },
            { nom: 'eplnummat', largeur: 120 },
            { nom: 'eplnomprn_usu', largeur: 262 },
            { nom: 'hhsdatdeb', largeur: 126 },
            { nom: 'hhsdatfin', largeur: 126 },
            { nom: 'ecldsceta', largeur: 142 }, // état client
            { nom: 'prvdsc', largeur: 123 },
            { nom: 'trnflgstaannrat', largeur: 120 },
            { nom: 'posnumdoc', largeur: 120 },
            { nom: 'postitabr', largeur: 300 },
            { nom: 'aftcod', largeur: 140 },
            { nom: 'aftdsc', largeur: 272 },  // description d'affectation
            { nom: 'aeqcod', largeur: 130 },
            { nom: 'aeqnom', largeur: 272 },
            { nom: 'hhsnumdoc', largeur: 120 },
            { nom: 'ttccod', largeur: 140 },  // type de transaction
            { nom: 'ttcdsc', largeur: 272 },
            { nom: 'ahthresem', largeur: 130 },
            { nom: 'unccod', largeur: 140 },
            { nom: 'uncdsc', largeur: 220 },
            { nom: 'urgcod', largeur: 140 },
            { nom: 'urgdscabr', largeur: 246 },
            { nom: 'strcod', largeur: 140 },
            { nom: 'strdsc', largeur: 272 },
            { nom: 'temcod', largeur: 140 },
            { nom: 'temdscabr', largeur: 272 },
            { nom: 'hhsflgdef', largeur: 110 },
            { nom: 'hhsetc', largeur: 120 },
            { nom: 'hhsdsc', largeur: 272 },
            { nom: 'trnflgstaref', largeur: 130 },
            { nom: 'trnflgstafin', largeur: 110 },
            { nom: 'trnflgstafer', largeur: 110 },
            { nom: 'trnflgstaann', largeur: 130 }
        ],
        colonnesCachees: ['stamnetab']
    };

    function getParams(data: any) {
        if (data.stamnetab === 'AFT') {
            return {
                ecran: '01-2',
                trncleint: data.trncleint,
                demcleint: $stateParams.employe,
                asgcleint: $stateParams.asgcleint
            };
        } else {
            return {
                ecran: '00-2',
                trncleint: data.trncleint,
                demcleint: $stateParams.employe,
                asgcleint: $stateParams.asgcleint
            };
        }
    }
}
