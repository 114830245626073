import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';

interface IPageMR0150 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireMpr: IFormulaire;
    mpmMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0150Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0150 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireMpr = new Formulaire('LBL_BLC_MPR', [

            new FormulaireItem('mpecleint', {
                default: $stateParams.mpecleint
            }),

            new FormulaireItem('mpvcleint', {
                resourceParams: (data:any) => ({
                    mpdcleint: $stateParams.mpdcleint
                })
            }),
            new FormulaireItem('mprvalnum'),
            new FormulaireItem('mprvaldat'),
            new FormulaireItem('mprvalvar')
        ]);

        // PM1- Paramètre multiple
        vm.mpmMultiOccurrenceOptions = {
            bloc: 'mpm',
            fonctions: {
                editionRapide: true
            },
            editionRapideActive: true,
            // Edition un seul
            formulaire: new Formulaire([
                new FormulaireItem('mpmvaldat',{largeur: 350}),
                new FormulaireItem('mpmvalcar',{largeur: 350}),
                new FormulaireItem('mpmvalnum',{largeur: 350})
            ]),
            // Edition massive (rapide)
            colonnesEditionRapide: [
                {nom: 'mpmvaldat', largeur: 350, hidden: () => vm.monoOccurrence.data && vm.monoOccurrence.data.t_vaempvtyp !== 'DAT'},
                {nom: 'mpmvalnum', largeur: 350, hidden: () => vm.monoOccurrence.data && vm.monoOccurrence.data.t_vaempvtyp !== 'NUM'},
                {nom: 'mpmvalcar', largeur: 350, hidden: () => vm.monoOccurrence.data && vm.monoOccurrence.data.t_vaempvtyp !== 'CAR'}
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'mpmvaldat',
                    largeur: 350,
                    hidden:   () => vm.monoOccurrence.data && vm.monoOccurrence.data.t_vaempvtyp !== 'DAT'
                },
                {nom: 'mpmvalnum',
                    largeur: 350,
                    hidden:   () => vm.monoOccurrence.data && vm.monoOccurrence.data.t_vaempvtyp !== 'NUM'
                },
                {nom: 'mpmvalcar',
                    largeur: 250,
                    hidden:   () => vm.monoOccurrence.data && vm.monoOccurrence.data.t_vaempvtyp !== 'CAR'
                }
            ]
        };
    }

}
