import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0250 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0250Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0250 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vaesoptyp_dsc', largeur: 200},
            {nom: 'urgcod'       , largeur: 180},
            {nom: 'urgdscabr'    , largeur: 350},
            {nom: 'bqecod'       , largeur: 180},
            {nom: 'bqedsc'       , largeur: 350}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesoptyp'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('bqecleint')
        ], {largeurDefaut: 33})
    };
}
