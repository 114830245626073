import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageGl0043 extends IComponentController {
    valeursEcran: any;
    efcFormulaire: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    efvMultiOccurrence: IMultiOccurrence;
    efvMultiOccurrenceOptions: IMultiOccurrenceOptions;    
}

interface IGl0043Params {
    id: string;
    eficleint: number;
}

/* @ngInject */
export default function Gl0043Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IGl0043Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageGl0043 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Colonne / saisie
        vm.efcFormulaire = new Formulaire('LBL_SAISIE_COLONNE', [
            new FormulaireItem('efcnumcol', {largeur: 20}),
            new FormulaireItem('vaeefctyp', {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('efcann', {largeur: 20}),
            new FormulaireItem('vaeefcper', {largeur: 20}),
            new FormulaireItem('efcflgcum', {largeur: 20}),
            new FormulaireItem('tymcleint', {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('efclarcol', {largeur: 20}),
            new FormulaireItem('efcdscent', {largeur: 40}),
            new FormulaireItem('efcratfct', {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('efcfor'   , {largeur: 80, enableCount: true}),
            new FormulaireItem('eficleint', { hidden: true, default: $stateParams.eficleint })
        ], {
            largeurDefaut: 20
        })

        // ventilations
        vm.efvMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'efvciecoddeb', largeur: 120 },
                { nom: 'impdeb', largeur: 250 },
                { nom: 'efvciecodfin', largeur: 120 },
                { nom: 'impfin', largeur: 250 }
            ],
            formulaire: new Formulaire('LBL_SAISIE_VENTILATIONS', [
                new FormulaireItemDivider('LBL_DE'),
                new FormulaireItem('efvciecoddeb', { largeur: 25 }),
                new FormulaireItem('impdeb', { largeur: 35 }),
                new FormulaireItemDivider('LBL_A'),
                new FormulaireItem('efvciecodfin', { largeur: 25 }),
                new FormulaireItem('impfin', { largeur: 35 })
            ], { largeurDefaut: 25 })
        };
        
    }
}
