import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageDO0039 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    formulairePro: IFormulaire;
    formulaireCon: IFormulaire;
    formulaireRea: IFormulaire;
    formulairePos: IFormulaire;
    formulaireOff: IFormulaire;
}

/* @ngInject */
export default function Do0039Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDO0039 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            id: ecranDetails.valeurs.pcdcleint,
            formulaire: new Formulaire('LBL_BLC_PCD', [
                new FormulaireItem('vaccanrejaff'),
                new FormulaireItem('vaccanrejald'),
                new FormulaireItem('vaccarraiqua'),
                new FormulaireItem('pcdnbrjrsdis'),
            ])
        };

        vm.formulairePro = new Formulaire('LBL_DIV_PRO', [
            new FormulaireItem('vactsmrai'),
            new FormulaireItem('ttccleinttsm'),
            new FormulaireItem('ttccleinthorhhs'),
            new FormulaireItem('ttccleinthorrrh')
        ]);

        vm.formulaireCon = new Formulaire('LBL_DIV_CON', [
            new FormulaireItem('vactsmraidmp'),
            new FormulaireItem('ttccleintdmp')
        ]);

        vm.formulaireRea = new Formulaire('LBL_DIV_REA', [
            new FormulaireItem('pcdflgaffdotaut'),
            new FormulaireItem('pcdflgaffdatdebdot'),
            new FormulaireItem('pcdflgcopdatreatse')
        ], {largeurDefaut: 100});

        vm.formulairePos = new Formulaire('LBL_DIV_POS', [
            new FormulaireItem('pcdnbrjrsajtdatest'),
        ]);

        vm.formulaireOff = new Formulaire('LBL_DIV_OFF', [
            new FormulaireItem('pcdadrcouoff', {largeur: 100, historiqueColonne: true}),
            new FormulaireItem('tadcleintcor'),
            new FormulaireItem('tadcleintper'),
            new FormulaireItem('ttlcleintmai'),
            new FormulaireItem('ttlcleintcel'),
            new FormulaireItem('pcdflgalerte'),
            new FormulaireItem('vaeporoff')
        ]);
    }
}
