import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMc0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0027Controller(MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    mc2012Route: IRoute) {

    const vm: IPageMc0027 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc2012Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc2012Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 250 },
            { nom: 'redcod', largeur: 120 },
            { nom: 'reddsc', largeur: 250 },
            { nom: 'tincod', largeur: 120 },
            { nom: 'tindsc', largeur: 250 }
        ]
    };
}
