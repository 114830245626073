import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageRe2009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re2009Controller() {
    const vm: IPageRe2009 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 245}
        ],        
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 120},
            {nom: 'tagdscabr', largeur: 220},
            {nom: 't_imp', largeur: 220},
            {nom: 'aftcod', largeur: 120},
            {nom: 'posnumdoc', largeur: 120},
            {nom: 'lemcod', largeur: 120},
            {nom: 'temcod', largeur: 126},
            {nom: 'unocod', largeur: 120},
            {nom: 'dernumdoc', largeur: 130},
            {nom: 'drpnumdoc', largeur: 120}            
        ],
        colonnesFixesDroite: [
            {nom: 'rsimntcal', largeur: 100}
        ],
        colonnesCachees: [
            'rslcleint'
        ]
    };
}
