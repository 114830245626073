import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageCp0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0007Controller(Menu: IMenuClass,
                                         $stateParams: any,
                                         cp1010Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         mc0046Route: IRoute) {
    const vm: IPageCp0007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            nouveau: () => !($stateParams.pflcleint),
            edition: () => !($stateParams.pflcleint),
            supprime: () => !($stateParams.pflcleint)
        },
        filtresParametres: [
            {colonne: 'pflcleint', valeur: $stateParams.pflcleint, visible: false}
        ],
        colonnesCachees: [
            'entcleint',
            'pflcleint'
        ],
        colonnesFixesGauche: [
            {nom: 'pflcod', largeur: 80},
            {nom: 'pfldsc', largeur: 220}
        ],
        colonnesVisibles: [
            {nom: 'vaesysdevcod_dsc', largeur: 100},
            {nom: 'mopcod', largeur: 95},
            {nom: 'mopdsc', largeur: 152},
            {nom: 'entcod', largeur: 89},
            {
                nom: 'entdsc', largeur: 340, menu: [
                    new MenuItemForage(
                        '',
                        cp1010Route.NAME,
                        (data: any) => ({
                            entcleint: data.entcleint
                        }),
                        {
                            icon: 'launch',
                            fonction: 'BOUCED',
                            iconButton: true
                        }
                    )
                ]
            },
            {nom: 'taxcodcap', largeur: 65},
            {nom: 'taxdsccap', largeur: 250},
            {nom: 'taxcodach', largeur: 65},
            {nom: 'taxdscach', largeur: 250},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pflcod', {required: true}),
            new FormulaireItem('pfldsc', {required: true, largeur: 75}),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireItem('mopcleint', {required: true}),
            new FormulaireItem('entcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('taxcleint'),
            new FormulaireItem('taxcleintach')
        ], {largeurDefaut: 25}),
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => {
                    return {
                        vaeusient: 'PFL',
                        uvicleintref: data.pflcleint
                    };
                },
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ])
    };
}
