import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
interface IPageIn0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function In0028Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    in0055Route: IRoute,
    Operateur: IOperateurService,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageIn0028 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        navigatePage: in0055Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(in0055Route.NAME, () => ({}), {
                fonction: 'BOUNOU'
            })
        ]),
        filtres: [
            {
                colonne: 'decflgcou',
                operateur: Operateur.EGALE,
                valeur: '1'
            }
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'decnumchg', largeur: 131 },
            { nom: 'decsrc', largeur: 288 },
            { nom: 'datcre', largeur: 100 },
            { nom: 'usrnomcre', largeur: 262 },
            { nom: 'decflg', largeur: 100 },
            { nom: 'decflgerr', largeur: 110 },
            { nom: 'nbrligerr', largeur: 90 },
            { nom: 'nbrlig', largeur: 70 }
        ]
    };
}
