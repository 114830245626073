import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGL0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0022Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageGL0022 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccoddeb', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('peccodfin', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cptcleint', {
            }),
            new FormulaireItem('cifcleint', {
            }),
            new FormulaireItem('grpcleint', {
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'EMP'
                }
            }),
            new FormulaireItem('orgcleint', {
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint', {
            }),
        ]),
        colonnesVisibles: [
            {nom: 'peccod'     , largeur: 80},
            {nom: 'cptcod'     , largeur: 105},
            {nom: 'cptdscabr'  , largeur: 262},
            {nom: 'docrefcod02', largeur: 105},
            {nom: 'docrefnom02', largeur: 262},
            {nom: 'tydcod'     , largeur: 66},
            {nom: 'document'   , largeur: 131},
            {nom: 'docaffdat01', largeur: 100},
            {nom: 'docaffdsc01', largeur: 262},
            {nom: 'montant'    , largeur: 126},
            {nom: 'grpcod'     , largeur: 120},
            {nom: 'grpdsc'     , largeur: 262}
        ]
    };
}
