import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMr0134 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    formulaireRce: IFormulaire;
    rcpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rcpMultiOccurrence: IMultiOccurrence;
    rcgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rcaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rtfMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0134Controller(mr0144Route: IRoute,
                                         mr0238Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Pagination: IPaginationClass) {
    const vm: IPageMr0134 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            actionsMore: new Menu([
                new MenuItemForage(
                    'G_LBL_MR0238',
                    mr0238Route.NAME,
                    (data:any) => ({
                        vaetypfnd: 'RCE',
                        cleintdet: data.rcecleint
                    }),
                    {
                        icon: 'assessment',
                        iconButton: false
                    }
                )
            ])
        };

        // RCE
        vm.formulaireRce = new Formulaire('LBL_BLC_RCE', [
            new FormulaireItem('rcecod', {largeur:20}),
            new FormulaireItem('rcedscabr', {largeur:55, modelOptions: { updateOn: 'blur' }}),
            new FormulaireItem('rcedatdeb', {largeur:25}),
            new FormulaireItem('rcedatfin', {largeur:25}),
            new FormulaireItem('rcedsc', {largeur: 50}),            
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('farcleint'),
            new FormulaireItem('vaercetyp'),
            new FormulaireGroupeAccordeon('LBL_SUITE', new Formulaire([
                new FormulaireItemDivider('LBL_CALCUL'),
                new FormulaireItem('vaercecalant'),
                new FormulaireItem('vaerceeffcal'),
                new FormulaireItem('vaercedatpar'),
                new FormulaireItem('vaercechgper'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rceflgcrtimp'),
                new FormulaireItem('rceflgcalmul'),
                new FormulaireItem('rceflgaju'),
                new FormulaireItem('vaerceexctpf'),
                new FormulaireItem('rceprical'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('clrnum', {largeur: 50}),
                new FormulaireItem('carcleint', {largeur: 50}),
                new FormulaireItemDivider('LBL_AUTRES_INFOS'),
                new FormulaireItem('rcecleintref', {largeur:50}),
                new FormulaireItem('rceflgimp'),                
                new FormulaireItem('rceseqaff'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vacrcecat', {largeur: 50}),
                new FormulaireItemDivider('LBL_ASSCOL'),
                new FormulaireItem('vaercetyprep', {largeur: 75}),
                new FormulaireItem('rceascrepord', {largeur: 25}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rcedoc', {largeur: 100, enableCount: true})
            ],{largeurDefaut: 25}))
        ]);

        // Paramètres - Date en vigueur
        vm.rcpMultiOccurrenceOptions = {
            navigatePage: mr0144Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    rcpcleint: rowData.rcpcleint,
                    rcecleint: vm.monoOccurrence.id
                };
            },
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    'G_LBL_BTN_NOUVEAU',
                    mr0144Route.NAME, () => {
                        return {
                            rcecleint: vm.monoOccurrence.id
                        }
                    }
                )
            ]),
            actionsMoreLigne: new Menu([
                new MenuItemAction(
                    'LBL_COPIE',
                    'BOUCOP',
                    (data: any) => ({ rcpcleint: data.rcpcleint }),
                    {
                        icon: 'file_copy',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE',
                            lblConfirm: 'G_LBL_BTN_COPIER',
                            largeurFormulaire: 20,
                            formulaire: new Formulaire([
                                new FormulaireItem('t_rcpdatvig', { largeur: 100 })
                            ])
                        },
                        successAction: () => { vm.rcpMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            bloc: 'rcp',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 15]
            }),
            colonnesVisibles: [
                {nom: 'rcpdatvig', largeur: 100}
            ],
            colonnesCachees: ['rcpcleint']
        };

        // RCE à générer
        vm.rcgMultiOccurrenceOptions = {
            bloc: 'rcg',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('rcecleintgen'),
                new FormulaireItem('rfecleint'),
                new FormulaireItem('vaesyscodprv')
            ],{largeurDefaut: 33}),
            colonnesVisibles: [
                {nom: 'rcecodgen', largeur: 120},
                {nom: 'rcedscabrgen', largeur: 240},
                {nom: 'rfecod', largeur: 160},
                {nom: 'rfenomabr', largeur: 240},
                {nom: 'vaesyscodprv_dsc', largeur: 160}
            ]
        };

        // RCE applicable
        vm.rcaMultiOccurrenceOptions = {
            bloc: 'rca',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('rcecleintapp')
            ]),
            colonnesVisibles: [
                {nom: 'rcecod', largeur: 120},
                {nom: 'rcedscabr', largeur: 240}
            ]
        };

        // Exception type de feuille
        vm.rtfMultiOccurrenceOptions = {
            bloc: 'rtf',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('tpfcleint')
            ]),
            colonnesVisibles: [
                {nom: 'tpfdsc', largeur: 200}
            ]
        };
    }
}
