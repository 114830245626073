import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as0046',
    ecrans: ['00-2'],
    params: [
        '?demcleint',
        '?paramaft',
        '?datdeb',
        '?datfin',
        '?criteremultipledeftay',
        '?criteremultiplecol',
        '?criteremultiplesrccod'
    ]
});
