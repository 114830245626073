import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IStateService } from 'angular-ui-router';

interface IPageCR0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Cr0022Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    exDateFilter: IFilterExDate,
    Menu: IMenuClass,
    accueilRoute: IRoute,
    $state: IStateService,
    cr1003Route: IRoute,
    cr0016Route: IRoute,
    cr1002Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageCR0022 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: true,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('ann'),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'CLI'
                }
            }),
            new FormulaireItem('datref', { default: exDateFilter(new Date()), required: true }),
            new FormulaireItem('pdfcleint'),
            new FormulaireItem('intcleintreq'),
            new FormulaireItem('cifcleint'),
            new FormulaireItem('vecccetypfac_cri'),
            new FormulaireItem('datprosui'),
            new FormulaireItem('indcon', { default: 'T' })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_GENERER',
                'BOUGEN',
                {
                    selection: { nom: 'generer' },
                    successAction: () => {
                        vm.multiOccurrence && vm.multiOccurrence.fetchDataList();
                    },
                    fonction: 'BOUGEN'
                })
        ]),
        colonnesFixesGauche: [
            {
                nom: 'cclnumdoc', largeur: 160, menu: [
                    new MenuItemForage(
                        '',
                        cr0016Route.NAME,
                        (rowdata: any) => ({
                            id: rowdata.cclcleint
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            }
        ],
        colonnesVisibles: [
            { nom: 'cacann', largeur: 80 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 250 },
            { nom: 'cceflguni', largeur: 100 },
            { nom: 'ccedatdue', largeur: 106 },
            {
                nom: 'ccemntfac', largeur: 150, menu: [
                    new MenuItemForage(
                        '',
                        cr1002Route.NAME,
                        (data: any) => ({
                            id: data.cclcleint
                        }),
                        {
                            icon: 'launch',
                            fonction: 'BOUCED',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'cacmsgave', largeur: 250 },
            { nom: 'vecccetypfac', largeur: 120 },
            { nom: 'vecccetypfac_dsc', largeur: 180 },
            { nom: 'ccenbrcon', largeur: 100 },
            {
                nom: 'ccenbrconcmb', largeur: 100, menu: [
                    new MenuItemEcran(
                        '',
                        cr1003Route.NAME,
                        (data: any) => ({
                            ccecleint: data.ccecleint
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'prucod', largeur: 100 },
            { nom: 'prudscl01', largeur: 230 },
            { nom: 'unacod', largeur: 100 },
            { nom: 'unadscabr', largeur: 230 },
            { nom: 'cifcod', largeur: 80 },
            { nom: 'cifdsc', largeur: 230 },
            { nom: 'intcodrsp', largeur: 140 },
            { nom: 'intnomrsp', largeur: 230 },
            { nom: 'pdfcod', largeur: 120 },
            { nom: 'prjdscabr', largeur: 230 },
            { nom: 'intcodreq', largeur: 100 },
            { nom: 'intnomreq', largeur: 230 },
            { nom: 'ccedatsigint', largeur: 126 },
            { nom: 'ccedatposorg', largeur: 126 },
            { nom: 'ccecom', largeur: 300 },
            { nom: 'cosdatsui', largeur: 126 },
            { nom: 'cosdatprosui', largeur: 126 }
        ],
        colonnesCachees: [
            "ccecleint",
            "cclcleint"
        ]
    };
}
