import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController, IFilterDate } from 'angular';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageMR0305 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    caqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bttMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IPageMR0305Params {
    cvtcleint: number;
}

/* @ngInject */
export default function Mr0305Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    dateFilter: IFilterDate,
    Pagination: IPaginationClass,
    $stateParams: IPageMR0305Params) {
    const vm: IPageMR0305 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire('LBL_BLC_CVB', [
                new FormulaireItem('cvbcod', { largeur: 10, required: true }),
                new FormulaireItem('cvbdscabr', { largeur: 70, required: true }),
                new FormulaireItem('cvbdatdeb', { required: true, readonly: data => data.cvbcleint }),
                new FormulaireItem('cvbdatfin'),
                new FormulaireItem('cvbqte', { readonly: true }),
                new FormulaireItem('unccleint', { required: true, readonly: data => data.cvbcleint }),
                new FormulaireItem('vaecvbtypmsg', { required: true }),
                new FormulaireItem('cvbdsc', { largeur: 90 }),
                new FormulaireItem('cvtcleint', { default: $stateParams.cvtcleint, hidden: true })
            ])
        };

        vm.caqMultiOccurrenceOptions = {
            bloc: 'caq',
            fonctions: {
                pagination: true,
                importation: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                { nom: 'caqdat', largeur: 95 },
                { nom: 'caqqte', largeur: 90, total: true },
                { nom: 'caqcom', largeur: 350 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('caqdat', { largeur: 25, default: dateFilter(new Date(), 'yyyy-MM-dd'), required: true, readonly: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('caqqte', { largeur: 25, required: true, readonly: data => data.caqcleint }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('caqcom', { largeur: 100 })
            ])
        };

        vm.bttMultiOccurrenceOptions = {
            bloc: 'btt',
            fonctions: {
                pagination: true,
                importation: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                { nom: 'tagcod', largeur: 100 },
                { nom: 'tagdscabr', largeur: 100 },
                { nom: 'bttdatdeb', largeur: 100 },
                { nom: 'bttdatfin', largeur: 100 },
            ],
            colonnesCachees: [
                'cvbcleint'
            ],
            formulaire: new Formulaire('LBL_BLC_BTT', [
                new FormulaireItem('bttcleintref', { largeur: 80 }),
                new FormulaireItem('bttdatdeb', { largeur: 30 }),
                new FormulaireItem('bttdatfin', { largeur: 30 })
            ])
        };

    }
}
