import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageCP0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceETSOptions: IMultiOccurrenceOptions;
}

interface IPageCp0003Params {
    entcleint: string;
}

/* @ngInject */
export default function Cp0003Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    $stateParams: IPageCp0003Params ) {
    const vm: IPageCP0003 = this;

    vm.multiOccurrenceETSOptions = {
        bloc: 'ets',
        colonnesVisibles: [
            { nom: 'tercod', largeur: 50 },
            { nom: 'terdsc', largeur: 100 },
            { nom: 'vaetercat', largeur: 10 },
            { nom: 'etsdatvig', largeur: 50 }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('tercleint', { largeur: 50 }),
            new FormulaireItem('etsdatvig', { largeur: 50 })
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtresParametres: [{
            colonne: 'entcleint',
            visible: false
        }],
        colonnesVisibles: [
            { nom: 'entcod', largeur: 10 },
            { nom: 'entdsc', largeur: 70 },
            { nom: 'entflgstu', largeur: 10 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('entcod', { largeur: 10 }),
            new FormulaireItem('entdsc', { largeur: 60 }),
            new FormulaireItem('entflgstu', { largeur: 10 })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceETSOptions
    };
}
