import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPagePf0003 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePfe: IFormulaire;
    xptMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0003Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPagePf0003 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            id: vm.valeursEcran.pfecleint
        };

        vm.formulairePfe = new Formulaire('LBL_BLC_PFE', [
                new FormulaireItem('nircleint'   , {largeur: 33.33}),
                new FormulaireItem('pfeflgincfor', {largeur: 33.33}),
                new FormulaireItem('pfeflgactemp', {largeur: 33.33}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaepfetrigrp', {largeur: 33.33}),
                new FormulaireItem('vaepfetriemp', {largeur: 33.33}),
                new FormulaireItemSautDeColonne({largeur: 33.33}),
                new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_DIV_BUD'),
                new FormulaireItem('pfeflgrepcat', {largeur: 33.33}),
                new FormulaireItem('pfeflgrepuno', {largeur: 33.33}),
                new FormulaireItemSautDeColonne({largeur: 33.33})
            ]
        );

        vm.xptMultiOccurrenceOptions = {
            bloc: 'xpt',
            colonnesVisibles: [
                {nom: 'tafcod', largeur: 160},
                {nom: 'tafdsc', largeur: 250}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tafcleint')
            ])
        };

    }
}
