import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IData} from '../../../../../core/services/data-linker.service';

interface IPageDO0093 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface IPageDO0093Params {
    offcleint: number;
    ctpcleint: number;
    cvccleint: number;
}
/* @ngInject */
export default function Do0093Controller(do0001Route: IRoute,
                                         do0002Route: IRoute,
                                         $stateParams: IPageDO0093Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageDO0093 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            nouveau: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('offcleint', {
                readonly: (data: IData) => data.$params.offcleint,
                largeur: 50,
                required: true,
                default: $stateParams.offcleint,

            }),
            new FormulaireItem('grgcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('comcleint', {
                largeur: 50,
                resourceParams: {
                    axe_vis: ($stateParams.offcleint) ? 'OFF' : 'GBL',
                    offcleint: ecranDetails.valeurs.offcleint
                }
            }),
            new FormulaireItem('nivcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cvccleint', {
                readonly: (data: IData) => data.$params.cvccleint,
                largeur: 50,
                required: true,
                default: $stateParams.cvccleint,

            }),
            new FormulaireItem('ctpcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cri_cannom', {largeur: 50}),
            new FormulaireItemDivider('LBL_DAT_CAN'),
            new FormulaireItem('candatde', {largeur: 20}),
            new FormulaireItem('candata', {largeur: 20}),
            new FormulaireItem('typsel', {required: true, default: 'T', direction: 'vertical', largeur: 20}),
            new FormulaireItem('orderby', {required: true, default: 'G', direction: 'vertical', largeur: 20}),
            new FormulaireItem('typcan', {required: true, default: 'TOUS', direction: 'vertical', largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rectxt', {largeur: 100})
        ]),
        colonnesCachees:['offcleint', 'cycle_trn'],
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 120,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_DO0001',
                        do0001Route.NAME,
                        (data: any) => {
                            return {
                                id: data.cycle_trn,
                                offcleint: data.offcleint
                            };
                        },
                        {
                            hidden: (data: any) => {
                                return !data.eplnummat || data.eplnummat === 0;
                            }
                        }
                    )
                ]
            },
            {nom: 'cannom', largeur: 220,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_DO0002',
                        do0002Route.NAME,
                        (data: any) => {
                            return {
                                id: data.cancleint,
                                offcleint: data.offcleint
                            };
                        }
                    )
                ]}
        ],
        colonnesVisibles: [
            {nom: 'cannumtel', largeur: 157},
            {nom: 'cannumpos', largeur: 98},
            {nom: 'cvhdat', largeur: 100},
            {nom: 'cvhhre', largeur: 100},
            {nom: 'cvhcom', largeur: 200},
            {nom: 'ctpdscabr', largeur: 200},
            {nom: 'grgdscabr', largeur: 200},
            {nom: 'canseqeva', largeur: 98},
            {nom: 'cananc', largeur: 132},
            {nom: 'score', largeur: 100},
            {nom: 'cancotapp', largeur: 105},
            {nom: 'candscapp', largeur: 157},
            {nom: 'offnumdoc', largeur: 147,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_DO0001',
                        do0001Route.NAME,
                        (data: any) => {
                            return {
                                ecran: '00-2',
                                id: data.cycle_trn,
                                offcleint: data.offcleint
                            };
                        },
                        {
                            disabled: (data: any) => {
                                return !data.offnumdoc;
                            }
                        }
                    )
                ]
            },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cvhdat'),
            new FormulaireItem('cvhcom')
        ])
    };
}
