import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageRr0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    paramsEcran: any;
    valeursEcran: any;
}
interface IRr0023Params {
    demcleint: string;
    cleintref: string;
    tytcleint: string;
    employe: string;
    ecran: string;
}
/* @ngInject */
export default function Rr0023Controller(rr0024Route: IRoute,
    rr0022Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    rr0023Route: IRoute,
    Pagination: IPaginationClass,
    $stateParams: IRr0023Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0023 = this;
    vm.paramsEcran = ecranDetails.params;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: (rowData: any) => getForage(rowData),
        navigateParams: (rowData: any) => getNavigateParams(rowData),
        actionsMore: new Menu([
            new MenuItemForage('LBL_BTN_HISTO', rr0023Route.NAME, () => {
                return {
                    ecran: vm.paramsEcran.p_bouton,
                    demcleint: $stateParams.demcleint,
                    cleintref: $stateParams.cleintref
                };
            }, {
                icon: 'view_list',
                hidden: !ecranDetails.params.p_bouton
            }),
        ]),
        criteresSuggeres: $stateParams.ecran === '04-2' ? new Formulaire([
            new FormulaireItem('eplnummat', { largeur: 10 }),
            new FormulaireItem('datlim', { default: null }),
            new FormulaireItem('prv', { default: 'DEM' })
        ]) : undefined,
        filtresParametres: [{
            colonne: 'demcleint'
        }],
        colonnesCachees: [
            'tytcleint',
            'lsacleint'
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100, hidden: Boolean($stateParams.employe || $stateParams.demcleint) },
            { nom: 'eplnomprn', largeur: 262, hidden: Boolean($stateParams.employe || $stateParams.demcleint) },
            { nom: 'ttccod', largeur: 100 },
            { nom: 'ttcdsc', largeur: 210 },
            { nom: 'datvig', largeur: 100 },
            { nom: 'typanc', largeur: 262 },
            { nom: 'typnou', largeur: 262 },
            { nom: 'ecldsceta', largeur: 180 },
            { nom: 'tsmflgfinlem', largeur: 80 },
            { nom: 'tsmflgfinemp', largeur: 80 },
            { nom: 'tsmflgpospro', largeur: 80 },
            { nom: 'tsmflgmajlem', largeur: 90 },
            { nom: 'tsmflgferrqf', largeur: 80 },
            { nom: 'tsmflgmazanc', largeur: 90 },
            { nom: 'tsmraicod', largeur: 89 },
            { nom: 'tsmraidsc', largeur: 180 },
            { nom: 'lemcod', largeur: 105 },
            { nom: 'lemdsc', largeur: 262 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'aftdsc', largeur: 262 },
            { nom: 'tsmdatdebadm', largeur: 100 },
            { nom: 'tsmdatfinadm', largeur: 100 },
            { nom: 'numdoc', largeur: 136 },
            { nom: 'datref', largeur: 100 },
            { nom: 'comref', largeur: 262 }
        ]
    };

    // Fonction d'identification de l'écran pour le forage
    function getForage(data: any) {
        if ([14, 23, 24].includes(Number(data.tytcleint))) {
            return rr0022Route.NAME;
        } else {
            return rr0024Route.NAME;
        }
    }

    // Fonction pour définir les paramètres de forage, en fonction de la transaction à accéder
    // - le paramètre ecran est défini en gardant uniquement les 4 derniers caractères de la sting ecrcod
    function getNavigateParams(data: any) {
        if ([14, 23, 24].includes(Number(data.tytcleint))) {
            return {
                ecran: '00-2',
                id: data.trncleint,
                lsacleint: data.lsacleint
            };
        }
        else if ([18, 19, 20, 40, 51].includes(Number(data.tytcleint))) {
            return {
                ecran: '01-2',
                id: data.trncleint
            };
        }
        else if ([74].includes(Number(data.tytcleint))) {
            return {
                ecran: '02-2',
                id: data.trncleint,
                trncleint: data.trncleint
            };
        }
        else {
            return {
                ecran: '00-2',
                id: data.trncleint
            }
        }
    }
}
