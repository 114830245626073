import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCp0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0009Controller($stateParams: any,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCp0009 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtresParametres: [
            {colonne: 'mopcleint', valeur: $stateParams.mopcleint, visible: false}
        ],
        colonnesCachees: [
            'mopcleint'
        ],
        colonnesFixesGauche: [
            {nom: 'mopcod', largeur: 80}
        ],
        colonnesVisibles: [
            {nom: 'mopdsc', largeur: 190},
            {nom: 'vaetypsorfon_dsc', largeur: 150},
            {nom: 'scbfol', largeur: 130},
            {nom: 'cptcod', largeur: 130},
            {nom: 'vaesysdevcod', largeur: 105},
            {nom: 'foucod', largeur: 110},
            {nom: 'founomabr', largeur: 160},
            {nom: 'mopnumeme', largeur: 230},
            {nom: 'mopnumtrf', largeur: 200},
            {nom: 'vaesysreftfe', largeur: 200},
            {nom: 'mopflggrh', largeur: 90},
            {nom: 'mopflgdefgrh', largeur: 100},
            {nom: 'mopflgdot', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mopcod', {maxlength: 3}),
            new FormulaireItem('mopdsc', {largeur: 50}),
            new FormulaireItem('vaetypsorfon'),
            new FormulaireItem('scbcleint'),
            new FormulaireItem('refcleint'),
            new FormulaireItem('mopnumeme'),
            new FormulaireItem('mopnumtrf'),
            new FormulaireItem('vaesysreftfe'),
            new FormulaireItem('mopflggrh'),
            new FormulaireItem('mopflgdefgrh'),
            new FormulaireItem('mopflgdot'),
        ], {largeurDefaut: 25})
    };
}
