/**
 * (PM1) Composant représentant une card contenant un multi-occurrence.
 */
import {IComponentOptions, module} from 'angular';
import CardMultiMaitreDetailsController from './ex-card-multi-maitre-details.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-card-multi-maitre-details', []).component('exCardMultiMaitreDetails', CardMultiMaitreDetailsFactory());

function CardMultiMaitreDetailsFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            Binding.OPENED.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            {
                lblTitre: '@',
                multiOccurrence: '=?multiOccurrenceOut'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '^^?exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: {
            contentDroite: '?exCardMultiMaitreDetailsContentDroite'
        },
        controller: CardMultiMaitreDetailsController,
        controllerAs: 'vm',
        template: require('./ex-card-multi-maitre-details.html')
    };
}
