import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCP0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0001Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('tfocod', {largeur: 10, maxlength: 3}),
            new FormulaireItem('tfodsc', {largeur: 40}),
            new FormulaireItem('tfoflgres', {largeur: 10}),
            new FormulaireItem('tfoflgnonutl', {largeur: 10})
        ]),
        colonnesVisibles: [
            {nom: 'tfocod', largeur: 115},
            {nom: 'tfodsc', largeur: 270},
            {nom: 'tfoflgres', largeur: 130},
            {nom: 'tfoflgnonutl', largeur: 120}
        ]
    };
}
