import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";


interface IPageIN0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function In0017Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageIN0017 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable:false, 
            criteresSuggeresVisibles: true                       
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('magcleint'),
            new FormulaireItem('malcleint'),
            new FormulaireItem('fimcleint'),
            new FormulaireItem('procleint'),
            new FormulaireItem('intcleintreq'),
            new FormulaireItem('intcleintdem'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('datdeb', {largeur: 16}),
            new FormulaireItem('datfin',  {largeur: 17}), 
            new FormulaireItem('st1cleint'),
            new FormulaireItem('typmov', {
                default: 3,
                required: true
            })
        ]),
        colonnesCachees: [
            'ecrcodd',
            'docaffcleint01'
        ],
        colonnesFixesGauche: [
            {nom: 'procod', largeur: 115}
        ],
        colonnesVisibles: [
            {nom: 'prodsc', largeur: 236},
            {nom: 'docaffnumdoc01', largeur: 158,
                menu: [
                    new MenuItemForage(
                        '',
                        (data: any) => `secure.${data.ecrcodd}.ecran`,
                        (data: any) => ({
                            id: data.docaffcleint01,
                            indfor: 1
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            {nom: 'tydcod', largeur: 115},
            {nom: 'tyddsc', largeur: 250},
            {nom: 'intcodreq', largeur: 120},
            {nom: 'intnomreq', largeur: 250},
            {nom: 'intcoddem', largeur: 120},
            {nom: 'intnomdem', largeur: 184},
            {nom: 'datcre', largeur: 120},
            {nom: 'tmodsc', largeur: 200},
            {nom: 'vaetmoimpinv', largeur: 120},
            {nom: 'triqte', largeur: 126},
            {nom: 'triprx', largeur: 126},
            {nom: 'forcod', largeur: 120},
            {nom: 'fordsc', largeur: 236},
            {nom: 'fimcod', largeur: 218},
            {nom: 'fimdsc', largeur: 236},
            {nom: 'magcod', largeur: 100},
            {nom: 'magnom', largeur: 210},
            {nom: 'malcod', largeur: 110},
            {nom: 'maldsc', largeur: 236}        
        ]
    };

}
