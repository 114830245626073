import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageGs0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0053Controller(gs2002Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,) {
        
    const vm: IPageGs0053 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(gs2002Route.NAME)
        ]),
        navigatePage: gs2002Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'ecrcod', largeur: 100 },
            { nom: 'ecrdsc', largeur: 250 },
            { nom: 'gfcnbrfor', largeur: 120 }
        ]
    };
}
