import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGb0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0001Controller(
    gb0002Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageGb0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gb0002Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.gebcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(gb0002Route.NAME)
        ]),
        colonnesFixesGauche: [
            { nom: 'gebcod', largeur: 100}
        ],
        colonnesVisibles: [
            { nom: 'gebdsc', largeur: 250 },
            { nom: 'gebnumref', largeur: 100 },
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 120 },
            { nom: 'lolnumloc', largeur: 100 },
            { nom: 'loldsc', largeur: 140 },
            { nom: 'unacod', largeur: 100 },
            { nom: 'unadsc', largeur: 140 },
            { nom: 'gbpcod', largeur: 150 },
            { nom: 'gbpdsc', largeur: 170 },
            { nom: 'gebfon', largeur: 150 },
            { nom: 'gebmod', largeur: 150 },
            { nom: 'refcod', largeur: 150 },
            { nom: 'refnom', largeur: 150 },
            { nom: 'gebnumser', largeur: 100 },
            { nom: 'gebannacq', largeur: 150 },
            { nom: 'gebmnt', largeur: 120 },
            { nom: 'frecod', largeur: 120 },
            { nom: 'fredsc', largeur: 160 },
            { nom: 'gcbcod', largeur: 160 },
            { nom: 'gcbdsc', largeur: 160 },
            { nom: 'vecgebcon', largeur: 160 },
            { nom: 'gebcondsc', largeur: 160 },
            { nom: 'gebflgact', largeur: 80 }
        ]
    };
}
