import {IHttpProvider, module} from 'angular';
import packageJson from "../../../../package.json";

export default module('app.configs.http', [
    'core.services.interceptors.database-debug-active-interceptor',
    'core.services.interceptors.error-handling-interceptor',
    'core.services.interceptors.authentification-interceptor'
]).config(HttpConfig);

/* @ngInject */
function HttpConfig($httpProvider: IHttpProvider) {
    $httpProvider.interceptors.push('errorHandlingInterceptor', 'authentificationInterceptor', "databaseDebugActiveInterceptor");
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.common["X-Version"] = packageJson.version
}
