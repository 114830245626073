import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageTS0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ts0006Controller(ts0007Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageTS0006 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: ts0007Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(ts0007Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'grrcod', largeur: 90},
            {nom: 'grrdscabr', largeur: 350},
            {nom: 'grrflginspos', largeur: 110},
            {nom: 'grrflginsdec', largeur: 110},
            {nom: 'grrflgdespos', largeur: 130},
            {nom: 'grrflgdesdec', largeur: 130},
            {nom: 'vaegrrtyptri_dsc', largeur: 189}
        ]
    };
}
