import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageAL0011 {
    monoOccurrenceOptions:IMonoOccurrenceOptions;
    dhdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}



/* @ngInject */
export default function Al0011Controller(
    MenuItemSelection: IMenuItemSelectionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageAL0011 = this;

    vm.monoOccurrenceOptions={
        formulaire: new Formulaire([
            new FormulaireItem('prfcod',{largeur: 30}),
            new FormulaireItem('prfdscabr',{largeur: 40}),
            new FormulaireItem('dlhseqmod',{largeur: 30}),
            new FormulaireItem('dlhdatvig',{largeur: 30}),
            new FormulaireItem('dlhflgact',{largeur: 70}),
            new FormulaireItem('dlhpctrep',{largeur: 30}),
            new FormulaireItem('dlhtauhor',{largeur: 27}),
            new FormulaireItem('dlhcom',{largeur: 70}),
            new FormulaireItem('dlhflgautftp',{largeur: 30})
        ])
    }
    vm.dhdMultiOccurrenceOptions = {
        bloc: 'dhd',
        colonnesVisibles: [
            { nom: 'aftcod', largeur: 105 },
            { nom: 'aftdscfmt', largeur: 250 },
            { nom: 'vaesysjrssem_desc', largeur: 79 },
            { nom: 'dhddursem', largeur: 79 },
            { nom: 'dhdqtetra', largeur: 100 },
            { nom: 'dhdqteabs', largeur: 100 },
            { nom: 'totqte', largeur: 100 },
            { nom: 'prctra', largeur: 100 }
        ] 
    }
}
