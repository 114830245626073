import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import AxesAffichage from "../../../../../core/classes/axe-affichage.class";


interface IPagePf0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Pf0014Controller(pf0011Route: IRoute,
    pf0012Route: IRoute,
    pf0029Route: IRoute,
    Menu: IMenuClass,
    Operateur: IOperateurService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: any) {
    const vm: IPagePf0014 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            axesAffichageVisible: true,
            rechercheCollapsable: false
        },
        axesAffichage: {
            axes: [
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.LIST,
                    icon: 'view_list',
                    lblNom: 'G_LBL_LIST'
                },
                {
                    axeNom: AxesAffichage.AXES_AFFICHAGE.CALENDAR,
                    icon: 'calendar_today',
                    lblNom: 'G_LBL_CALENDAR',
                    options: {
                        description: ['acedsc', 'dpmacedsc'],
                        colonneDateDebut: 'dpmdatdeb',
                        colonneDateFin: 'dpmdatfin',
                        colonneHeureDebut: 'dpmhredeb',
                        colonneHeureFin: 'dpmhrefin'
                    }
                }
            ]
        },
        navigatePage: (rowData: any) => (rowData.type_act === 'REM') ? pf0011Route.NAME : pf0029Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(
                'BTN_NOU_PER',
                pf0029Route.NAME,
                () => ({}),
                {
                    icon: '', iconButton: false,
                    fonction: 'NOUPER',
                    disabled: () => !vm.valeursEcran.dpmflgsof2
                }),
            new MenuItemForage(
                'BTN_NOU_REM',
                pf0011Route.NAME,
                () => ({}),
                {
                    icon: '', iconButton: false,
                    fonction: 'NOUREM',
                    disabled: () => !vm.valeursEcran.dpmflgremsof2
                })
        ]),
        filtresParametres: [
            { colonne: 'rbucleint', affichage: true },
            { colonne: 'unocleintrh', affichage: true },
            { colonne: 'cabcleint', affichage: true },
            { colonne: 'demcleint', affichage: true },
            { colonne: 'comcleint', affichage: true }
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('axe', { required: true, default: 'ACT', largeur: 100 }),
        ]),
        filtres: [
            {
                colonne: 'type_act',
                operateur: Operateur.EGALE,
                valeur: '',
                nonSupprimable: true
            },
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesDisponibles: [
            { nom: 'mntsal', largeur: 126, hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR') },
            { nom: 'coutfor', largeur: 126, hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'EMP') }
        ],
        colonnesVisibles: [
            { nom: 'dpmnumdoc', largeur: 120 },
            { nom: 'acecod', largeur: 130, hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR') },
            { nom: 'acedsc', largeur: 250, hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR') },
            { nom: 'dpmacedsc', largeur: 250, hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR') },
            { nom: 'eplnummat', largeur: 120, hidden: () => $stateParams.employe || $stateParams.demcleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'eplnomprn', largeur: 200, hidden: () => $stateParams.employe || $stateParams.demcleint || vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'gemflgpre', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'gemflgreu', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'eplnbrpij', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            {
                nom: 'gpenumdoc', largeur: 130, 
                    hidden: () => ( vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'ACT' || vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'EMP' ),
                    menu: [new MenuItemForage( 
                        'G_LBL_FONC_TRANS_DOCUMENT',
                        pf0012Route.NAME,
                        (rowData: any) => {
                            return {
                                id: rowData.gpecleint
                            };
                        }
                )]
            },
            { nom: 'eplnummatfor', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'FOR' },
            { nom: 'eplnomprnfor', largeur: 200, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'FOR' },
            { nom: 'dpmdatdeb', largeur: 120 },
            { nom: 'dpmhredeb', largeur: 120 },
            { nom: 'dpmdatfin', largeur: 120 },
            { nom: 'dpmhrefin', largeur: 120 },
            { nom: 'ecldsceta', largeur: 180 },
            { nom: 'piscod', largeur: 100 },
            { nom: 'pisdsc', largeur: 200 },
            { nom: 'dpmpisdsc', largeur: 200 },
            { nom: 'dpmcomdem', largeur: 200 },
            { nom: 'dpmcomcen', largeur: 200 },
            { nom: 'dpmflginspub', largeur: 100 },
            { nom: 'dpmdatdebins', largeur: 100 },
            { nom: 'dpmdatfinins', largeur: 100 },
            { nom: 'cabcod', largeur: 120 },
            { nom: 'cabdsc', largeur: 250 },
            { nom: 'dpmannfinaff', largeur: 120 },
            { nom: 'mntdem', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR' },
            { nom: 'mntacc', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR' },
            { nom: 'mntreel', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR' },
            { nom: 'htt_hre', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR' },
            { nom: 'hht_hre', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'FOR' },
            { nom: 'httpre', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'EMP' },
            { nom: 'hhtpre', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'EMP' },
            { nom: 'httfor', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'EMP' },
            { nom: 'hhtfor', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'EMP' },
            { nom: 'unocodrhdem', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'unodscrhdem', largeur: 250, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'temcod', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'temdscabr', largeur: 250, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'urgcod', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'urgdscabr', largeur: 250, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'strcod', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'strdsc', largeur: 250, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'depense_ann_tot', largeur: 126, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe !== 'EMP' },
            { nom: 'tpccod', largeur: 120 },
            { nom: 'tpcdsc', largeur: 250 },
            { nom: 'pfgcod', largeur: 120 },
            { nom: 'pfgdsc', largeur: 200 },
            { nom: 'ttccod', largeur: 120 },
            { nom: 'ttcdsc', largeur: 200 },
            { nom: 'unocod', largeur: 140 },
            { nom: 'unodscabr', largeur: 250 },
            { nom: 'posnumdoc', largeur: 120 },
            { nom: 'postitabr', largeur: 200 },
            { nom: 'dpmdat', largeur: 100 },
            { nom: 'trnflgstaref', largeur: 100 },
            { nom: 'trnflgstafin', largeur: 100 },
            { nom: 'trnflgstafer', largeur: 100 },
            { nom: 'trnflgstaann', largeur: 100 }
        ],
        colonnesCachees: ['type_act', 'gpecleint']
    };
}
