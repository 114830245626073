import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageAl0004 extends IComponentController {
    monoOccurrence: IMonoOccurrence;    
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dhdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Al0004Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageAl0004 = this;

    vm.formulaireDLH = new Formulaire([
        new FormulaireItem('prfcleint', {
            largeur: 33,
            default: $stateParams.prfcleint
        }),
        new FormulaireItem('dlpcleint', {
            default: $stateParams.dlpcleint
        }),
        new FormulaireItem('dlhseqmod', {
            largeur: 15            
        }),        
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dlhdatvig', {
            largeur: 33
        }),
        new FormulaireItem('dlhflgact', {
            largeur: 15,
            default: 1
        }),
        new FormulaireItemMenuItem(new MenuItemAction(
            'LBL_BTN_GEN',
            'BOUGEN',
            (data: any) => ({}),
            {
                iconButton: false,
                saveFirst: true,
                class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                disabled: (data: any) => !data.dlhcleint
            }
        )),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dlhpctrep', {
            largeur: 33
        }),
        new FormulaireItem('dlhtauhor', {
            largeur: 33
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dlhcom', {
            largeur: 33
        }),
        new FormulaireItem('dlhflgautftp', {
            largeur: 33,
            default: 0
        })        
    ]);
    // Detail Horaire du dossier d'absence
    vm.dhdMultiOccurrenceOptions = {
        bloc: 'dhd',
        colonnesVisibles: [
            { nom: 'aftcod', largeur: 130 },
            { nom: 'aftdscfmt', largeur: 130 },
            { nom: 'vaesysjrssem', largeur: 130 },
            { nom: 'dhddursem', largeur: 130 },
            { nom: 'dhdqtetra', largeur: 130 },
            { nom: 'dhdqteabs', largeur: 130 },
            { nom: 'totqte', largeur: 130 },
            { nom: 'prctra', largeur: 130 }
        ]
    }

}
