import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCr0010 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    redMultiOccurrenceOptions: IMultiOccurrenceOptions;
    memMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0010Controller(cr0012Route: IRoute,
    cr0021Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0010 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        // Bouton Encaissement
        vm.forageEnc = new MenuItemForage(
            'BTN_FORAGE_ENC',
            cr0012Route.NAME,
            (rowData: any) => {
                return {
                    loecleint: rowData.loecleint,
                    efface_criteres: true
                };
            },
            {
                icon: '',
                iconButton: false,
                fonction: 'BOUENC'
            }
        );

        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_LOE', [
                new FormulaireItem('loecod'),
                new FormulaireItem('peccleint'),
                new FormulaireItem('loedsc', { largeur: 100 })
            ])
        };

        // Regroupement d'encaissement
        vm.redMultiOccurrenceOptions = {
            bloc: 'red',
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_DEPOT',
                    cr0021Route.NAME,
                    (data: any) => ({ 
                        loecleint: data.loecleint }),
                    {
                        icon: 'attach_money',
                        iconButton: false,
                        fonction: 'BTN_DEPOT',
                        hidden: (rowData:any) => !(rowData.flgprtdep)
                    }
                )                
            ]),
            colonnesVisibles: [
                { nom: 'reddsc', largeur: 200 },
                { nom: 'nbrenc', largeur: 100 },
                { nom: 'mntenc', largeur: 100 },
                { nom: 'nbrencann', largeur: 100 },
                { nom: 'nbrdep', largeur: 100 },
                { nom: 'mntdep', largeur: 100 },
                { nom: 'flgprtcpl', largeur: 100 },
                { nom: 'flgprtoff', largeur: 100 },
                { nom: 'flgprtrep', largeur: 100 },
                { nom: 'flgprtdep', largeur: 100 }
            ],
            colonnesCachees: [
                'loecleint'
            ]
        };

        // Mode d'encaissement
        vm.memMultiOccurrenceOptions = {
            bloc: 'mem',
            colonnesVisibles: [
                { nom: 'moecod', largeur: 120 },
                { nom: 'moedsc', largeur: 300 },
                { nom: 'nbrdoc', largeur: 120, total: true },
                { nom: 'montant', largeur: 120, total: true },
                { nom: 'montantcad', largeur: 120, total: true }
            ]
        };
    };
}
