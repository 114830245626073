import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0011Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,  
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0011 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('tlecod', {largeur: 25}),
            new FormulaireItem('tledsc', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tleflgpmn', {largeur: 12.5, modifAllow: false}),
            new FormulaireItem('tleflgpri', {largeur: 12.5, modifAllow: false}),
            new FormulaireItem('tleflgdef', {largeur: 12.5}),
            new FormulaireItem('tleflgact', {largeur: 12.5, default: 1})
        ]),
        colonnesVisibles: [
            {nom: 'tlecod', largeur: 90},
            {nom: 'tledsc', largeur: 262},
            {nom: 'tleflgpmn', largeur: 76},
            {nom: 'tleflgpri', largeur: 76},
            {nom: 'tleflgdef', largeur: 76},
            {nom: 'tleflgact', largeur: 76}
        ]
    };
}
