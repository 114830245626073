import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'pd0003',
    ecrans: [
        '00-2',
        '01-2',
        '02-2'
    ]
});
