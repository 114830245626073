import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';

interface IPageMr0297 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireCqc: IFormulaire;
}

/* @ngInject */
export default function Mr0297Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMr0297 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            noId: true,
        };

        vm.formulaireCqc = new Formulaire('LBL_BLC_CQC', [
            new FormulaireItem('unccleint', {
                largeur: 100
            })
        ]);
    }
}
