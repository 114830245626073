import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageAF0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Af0002Controller(Menu: IMenuClass) {
    const vm: IPageAF0002 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'adfnumdoc', largeur: 136},
            {nom: 'adfnumdocseq', largeur: 60},
            {nom: 'tydcod', largeur: 63},
            {nom: 'refcod', largeur: 105},
            {nom: 'refnomabr', largeur: 231},
            {nom: 'radcod', largeur: 76},
            {nom: 'foubot', largeur: 132},
            {nom: 'peccod', largeur: 91},
            {nom: 'etddsc', largeur: 180},
            {nom: 'flgcou', largeur: 72},
            {nom: 'adfflgverfin', largeur: 91},
            {nom: 'adfflgref', largeur: 91},
            {nom: 'adfmntdem', largeur: 126},
            {nom: 'adfmntoct', largeur: 126},
            {nom: 'intcodreq', largeur: 102},
            {nom: 'intnomreq', largeur: 220},
            {nom: 'intcoddem', largeur: 105},
            {nom: 'intnomdem', largeur: 220},
            {nom: 'adfdsc', largeur: 236},
            {nom: 'usrcod', largeur: 100},
            {nom: 'vecsyscodins', largeur: 110},
            {nom: 'vecsysdscins', largeur: 252},
            {nom: 'adfdatdec', largeur: 100},
            {nom: 'adfinfsup', largeur: 656}
        ]
    };
}
