import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCb0003 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    sbsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sbsMultiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0003Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    profil: IProfil,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageCb0003 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        id: profil.preferences.ciecleint,
        noId: true,
        fonctions: {
            suiviModification: false
        }
    };

    vm.sbsMultiOccurrenceOptions = {
        bloc: 'sbs',
        fonctions: {
            criteresSuggeresVisibles: false,
            suiviModification: false,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('blocleintcri', {
                largeur: 50,
                ignoreEtat: true
            }),
            new FormulaireItem('prscleintcri', {
                largeur: 50,
                ignoreEtat: true
            }),
            new FormulaireItem('indprs', {
                largeur: 50,
                default: 2,
                ignoreEtat: true
            })
        ]),
        colonnesVisibles: [
            { nom: 'prscod', largeur: 100, hidden: () => (vm.sbsMultiOccurrence.etatSelected.criteresSuggeresData.indprs !== 2) },
            { nom: 'prsdscl01', largeur: 280, hidden: () => (vm.sbsMultiOccurrence.etatSelected.criteresSuggeresData.indprs !== 2)  },
            { nom: 'blodsc', largeur: 280 },
            { nom: 'imppos', largeur: 139 },
            { nom: 'impneg', largeur: 139 },
            { nom: 'mntsum', largeur: 139 }
        ]
    };
}
