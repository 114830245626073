import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageMc0167 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireLip: IFormulaire;
    formulaireCommunication: IFormulaire;
    formulaireLpf: IFormulaire;
}

/* @ngInject */
export default function Mc0167Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass ) {
    const vm: IPageMc0167 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };


        vm.formulaireLip = new Formulaire('LBL_BLC_LIP', [
            new FormulaireItem('lipcod'),
            new FormulaireItem('lipflgadrfac'),
            new FormulaireItem('lipnom'),
            new FormulaireItem('lipnomabr'),
            new FormulaireItem('intcleint'),
            new FormulaireItemDivider('LBL_DIV_ADR'),
            new FormulaireItem('lipadrl01'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lipadrl02'),
            new FormulaireItem('lipflgquarec'),
            new FormulaireItem('lipvil'),
            new FormulaireItem('lippro'),
            new FormulaireItem('lippay'),
            new FormulaireItem('lipcodpos')
        ], {largeurDefaut: 50});

        vm.formulaireCommunication = new Formulaire('LBL_BLC_COM', [
            new FormulaireItem('lipnumtel'),
            new FormulaireItem('lipnumtelpos'),
            new FormulaireItem('lipnumfax'),
            new FormulaireItem('lipadrcou', {largeur: 100})
        ]);

        vm.formulaireLpf = new Formulaire('LBL_BLC_LPF', [
            new FormulaireItem('lipcleintref', {titre: ' '}),
            new FormulaireItem('lipadrfmt', {titre: ' ', readonly: true})
        ]);
    }
}
