import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";

interface IPageGs0041 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function gs0041Controller(Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         gs0420Route: IRoute)
{
    const vm: IPageGs0041 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemEcran(
                'Nouveau',
                gs0420Route.NAME,
                () => ({
                })
            )
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemEcran('', gs0420Route.NAME, (data:any) => {
                return {
                    id: data.gnocleint
                };
            }, {
                icon: 'launch',
                fonction: 'BOUOUV',
                onClose: () => vm.multiOccurrence.fetchDataList()

            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'gnocod', largeur: 144},
            {nom: 'gnodsc', largeur: 420},
            {nom: 'gnoflggbl', largeur: 80},
            {nom: 'gnoflgsec', largeur: 85}
        ]
    };
}
