import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0076',
    ecrans: ['00-2'],
    params: [
        'id',
        '?stamnetab',
        '?cleintref',
        '?dmpcleint'
    ]
});
