import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAe0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ae0008Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAe0008 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'stamnetab_cod', largeur: 90},
            {nom: 'stamnetab_dsc', largeur: 300},
            {nom: 'comcod', largeur: 120},
            {nom: 'comdsc', largeur: 380},
            {nom: 'corseqaff', largeur: 80},
            {nom: 'corcom', largeur: 400}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('corcleintref', {required: true}),
            new FormulaireItem('comcleint', {required: true}),
            new FormulaireItem('corseqaff', {required: true}),
            new FormulaireItem('corcom', {largeur: 100})
        ], {largeurDefaut: 33.33}),
        fonctions: {
            importation:true
        }
    };
}
