import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPagePD0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,) {
    const vm: IPagePD0004 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pmicleint', {
                required: false
            }),
            new FormulaireItem('intcleinttit', {
                required: false
            }),
            new FormulaireItem('intcleint', {
                required: false
            }),
            new FormulaireItem('vecpepsta', {
                required: false
            }),
            new FormulaireItem('vaesyspdatyp', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fordatdeb', {
                required: false
            }),
            new FormulaireItem('fordatfin', {
                required: false
            }),
            new FormulaireItemDivider('LBL_INTERV'),
            new FormulaireItem('intdatdeb_de', {
                required: false
            }),
            new FormulaireItem('intdatdeb_a', {
                required: false
           }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intdatfin_de', {
                required: false
            }),
            new FormulaireItem('intdatfin_a', {
                required: false
            }),
            new FormulaireItem('flgexcdatfin', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('person', {
                default: 'T',
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('contact', {
                required: true
            }),
            new FormulaireItem('autorisee', {
                required: true
            }),
            new FormulaireItem('associee', {
                required: true
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'intcodpep', largeur: 110},
            {nom: 'intnompep', largeur: 185},
            {nom: 'vecpepsta_dsc', largeur: 147}
        ],
        colonnesVisibles: [
            {nom: 'empadrcou', largeur: 220},
            {nom: 'radnumtel', largeur: 131},
            {nom: 'radnumtelpos', largeur: 75},
            {nom: 'pmicod', largeur: 116},
            {nom: 'intcodtit', largeur: 116},
            {nom: 'intnomtit', largeur: 184},
            {nom: 'pepdatforini', largeur: 100},
            {nom: 'pepdatforrev', largeur: 100},
            {nom: 'pepdatdeb', largeur: 110},
            {nom: 'pepdatfin', largeur: 110},
            {nom: 'pepflgcon', largeur: 91},
            {nom: 'pepflgaut', largeur: 100},
            {nom: 'pepflgass', largeur: 91}
        ]
    };
}
