import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageSo0010 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    stpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function So0010Controller(so0011Route: IRoute,
    so0015Route: IRoute,
    so0007Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageSo0010 = this;
    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                afficherMenusDansEntete: true
            },
            menus: [
                new MenuItemForage("LBL_BTN_ANALYSE", so0015Route.NAME, (data) => ({ stocleint: data.stocleint }), {
                    icon: "",
                    iconButton: false,
                    class: 'md-raised ex-button-secondary'
                }),
                new MenuItemAction('LBL_BTN_VALIDER',
                    'BOUVAL',
                    () => ({}),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-secondary',
                        successAction: () => vm.monoOccurrence.fetchData(),
                        hidden: (data: any) => data.stoflgoff || data.stoflgann || data.stoflgrev || data.stoflgenrev
                    }
                ),
                new MenuItemAction('LBL_BTN_OFFICIEL',
                    'BOUOFF',
                    () => ({}),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_OFF',
                            lblConfirm: 'G_LBL_OUI',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('questionoff', { largeur: 100, readonly: true })
                            ], { largeurDefaut: 30 })
                        },
                        successAction: () => vm.monoOccurrence.fetchData(),
                        hidden: (data: any) => data.stoflgoff || data.stoflgann || data.stoflgrev || data.stoflgenrev
                    }
                ),
                new MenuItemAction('LBL_BTN_REVISION',
                    'BOUREV',
                    () => ({}),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-secondary',                        
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_REV',
                            lblConfirm: 'G_LBL_OUI',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('questionrev', { largeur: 100, readonly: true })
                            ], { largeurDefaut: 30 })
                        },
                        successAction: () => vm.monoOccurrence.fetchData(),
                        hidden: (data: any) => !data.stoflgoff
                    }
                ),
                new MenuItemAction('LBL_BTN_ANNULE',
                    'BOUANN',
                    () => ({}),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-secondary',
                        successAction: () => vm.monoOccurrence.fetchData(),
                        formulaireConfirmationAction: {
                            lblTitre: 'G_LBL_QUESTION',
                            lblConfirm: 'G_LBL_OUI',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('question', { largeur: 100, readonly: true })
                            ], { largeurDefaut: 30 })
                        },
                        hidden: (data: any) => data.stoflgann || data.stoflgrev || data.stoflgenrev
                    }
                )
            ],
            formulaire: new Formulaire('LBL_BLC_STO', [
                new FormulaireItem('stodatvig', { largeur: 33 }),
                new FormulaireItem('unocleint', { largeur: 66 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('stodscabr', {
                    largeur: 33,
                    modelOptions: {
                        updateOn: 'blur'
                    }
                }),
                new FormulaireItem('stodsc', { largeur: 66 }),
                new FormulaireItemDivider('LBL_DIV_ENT', { hidden: (data) => data.stocleint }),
                new FormulaireItem('poscleint'),
                new FormulaireItem('nircleint'),
                new FormulaireItem('stostpcom')
            ])
        };

        const hasEtat = () => vm.monoOccurrence.data.stoflgoff || vm.monoOccurrence.data.stoflgrev || vm.monoOccurrence.data.stoflgenrev || vm.monoOccurrence.data.stoflgann

        vm.stpMultiOccurrenceOptions = {
            fonctions: {
                exportation: true,
                supprime: false,
                edition: false,
                nouveau: false
            },
            bloc: 'stp',
            bris: 'poscleint',
            brisNiveaux: 'niveau',
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    'G_LBL_BTN_NOUVEAU',
                    so0011Route.NAME,
                    () => ({
                        stocleint: vm.monoOccurrence.data.stocleint,
                    }),
                    {
                        hidden: hasEtat
                    }
                )
            ]),
            navigatePage: so0007Route.NAME,
            navigateParams: data => ({ id: data.trncleintpos }),
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'G_LBL_BTN_MODIFIER',
                    so0011Route.NAME,
                    (data: any) => ({
                        id: data.stpcleint,
                        stocleint: data.stocleint
                    }),
                    {
                        icon: 'create',
                        iconButton: false,
                        hidden: hasEtat
                    }
                )
            ]),
            colonnesVisibles: [
                'libelle',
                'stpcom'
            ],
            colonnesCachees: [
                'niveau',
                'stpcleint',
                'stocleint',
                'poscleint',
                "trncleintpos"
            ]
        }
    }
}
