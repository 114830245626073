import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFilterDate} from 'angular';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import { IData } from '../../../../../core/services/data-linker.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageMR1012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any; 
}
/* @ngInject */
export default function Mr1012Controller(rr0006Route: IRoute,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,                                         
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         dateFilter: IFilterDate,
                                         Formulaire: IFormulaireClass,
                                         $stateParams: IStateParamsService,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR1012= this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: !$stateParams.employe,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleint'),
            new FormulaireItem('dateinit', {
                required: true,
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            }),
            new FormulaireItem('flgaftact'),
            new FormulaireItemDivider('LBL_SEP'),              
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleinttrans', {resourceParams: (data: IData) => ({intcleint: data.intcleint})}),
            new FormulaireItem('datetrans')
        ]),
        navigatePage: rr0006Route.NAME,
            navigateParams: (data: any) => (
                {
                    trncleint: data.trncleint
                }
            ),
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_TRANS', 'BOUTRANS', {
                selection: {nom: 'transfert'}
            })
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe},
            {nom: 'aftcod', largeur: 105},
            {nom: 'aftdatdeb', largeur: 110},
            {nom: 'aftdatfin', largeur: 110},
            {nom: 'temcod', largeur: 89},
            {nom: 'ecldsceta', largeur: 126},
            {nom: 'intnomsup', largeur: 220}
        ]
    };
}
