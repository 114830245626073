import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageDO0085 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions
}

/* @ngInject */
export default function Do0085Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,) {
    const vm: IPageDO0085 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            id: ecranDetails.valeurs.alecleint,
            formulaire: new Formulaire('LBL_BLC_ALE', [
                new FormulaireItem('alesuj', {required: true}),
                new FormulaireItem('aletxt', {required: true})
            ], {largeurDefaut: 100})
        };
    }
}
