import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePR0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0018Controller(   Menu: IMenuClass ,
                                            pr0011Route:IRoute,
                                            MenuItemForage: IMenuItemForageClass,) {
    const vm: IPagePR0018 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: pr0011Route.NAME,
        navigateParams: (rowData: any) => ({
            id:rowData.orgcleint ,
            orgmoifin: rowData.orgmoifin
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage(
                pr0011Route.NAME,
                (rowData: any) => ( {}),
                {
                    icon: '',
                    iconButton: false
                }
            ),
        ]),
        colonnesVisibles: [
            { nom: 'orgcod', largeur: 200 },
            { nom: 'orgnom', largeur: 200 },
            { nom: 'caocod', largeur: 200 },
            { nom: 'caodsc', largeur: 200 },
            { nom: 'sfucod', largeur: 200 },
            { nom: 'sfudsc', largeur: 200 },
            { nom: 'refcod', largeur: 200 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'mofcod', largeur: 200 },
            { nom: 'mofdsc', largeur: 200 },
            { nom: 'orgmoifin', largeur: 200 },
            { nom: 'oppdsc', largeur: 200 },
            { nom: 'vaesysindrec_dsc', largeur: 200 },
            { nom: 'srvcod', largeur: 200 },
            { nom: 'srvdscabr', largeur: 200 }
        ]
    };
}
