import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mc0024Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0024 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tincod'   , largeur: 120},
            {nom: 'tindsc'   , largeur: 300},
            {nom: 'tinpri'   , largeur: 110},
            {nom: 'tinflgpru', largeur: 110, hidden: () => vm.valeursEcran.flgpru === 0},
            {nom: 'tinflgcpt', largeur: 110, hidden: () => vm.valeursEcran.flgcpt === 0},
            {nom: 'tinflgprs', largeur: 110, hidden: () => vm.valeursEcran.flgprs === 0},
            {nom: 'tinflguna', largeur: 110, hidden: () => vm.valeursEcran.flguna === 0},
            {nom: 'tinflgcif', largeur: 110, hidden: () => vm.valeursEcran.flgcif === 0},
            {nom: 'tinflgel1', largeur: 110, hidden: () => vm.valeursEcran.flgel1 === 0},
            {nom: 'tinflgel2', largeur: 110, hidden: () => vm.valeursEcran.flgel2 === 0},
            {nom: 'tinflgel3', largeur: 110, hidden: () => vm.valeursEcran.flgel3 === 0},
            {nom: 'tinflgcat', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tincod', {largeur: 33.33}),
            new FormulaireItem('tindsc', {largeur: 33.33}),
            new FormulaireItem('tinpri', {largeur: 33.33}),
            new FormulaireItem('tinflgpru', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flgpru}),
            new FormulaireItem('tinflgcpt', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flgcpt}),
            new FormulaireItem('tinflgprs', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flgprs}),
            new FormulaireItem('tinflguna', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flguna}),
            new FormulaireItem('tinflgcif', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flgcif}),
            new FormulaireItem('tinflgel1', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flgel1}),
            new FormulaireItem('tinflgel2', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flgel2}),
            new FormulaireItem('tinflgel3', {largeur: 20, default: 0, hidden: (data) => !data.$valeurs.flgel3}),
            new FormulaireItem('tinflgcat', {largeur: 20, default: 0})
        ])
    };
}
