import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0008 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0008Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0008 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'cifrsp',
                valeur: vm.valeursEcran.cifrsp,
                affichage: true,
                readOnly: true
            }
        ],
        colonnesVisibles: [
            {nom: 'vaeciinatbdg', largeur: 115},
            {nom: 'cifcod', largeur: 115},
            {nom: 'ciiimp', largeur: 289},
            {nom: 'cptdsc', largeur: 155},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeciinatbdg', {required: true}),
            new FormulaireItem('cifcleintint', {required: true}),
            new FormulaireItem('ciiimp', {largeur: 33, required: true})
        ]),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true,
            rechercheCollapsable: false
        }
    };
}
