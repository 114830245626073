import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTS0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ts0009Controller(Menu: IMenuClass) {
    const vm: IPageTS0009 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rrlcod', largeur: 90},
            {nom: 'rrldscabr', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'rrlflgunoobl', largeur: 110},
            {nom: 'rrlflgrepzer', largeur: 80}
        ]
    };
}
