import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0099 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0099Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0099 = this;


    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('eplcleint'),
            new FormulaireItem('qstcleint')
        ]),
        formulaire: new Formulaire([
            new FormulaireItem('qsrcleintref', { hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.eplcleint, default: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.eplcleint) }),
            new FormulaireItem('qstcleint', { hidden: true, default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.qstcleint }),
            new FormulaireItem('qsdcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_flgon', {
                largeur: 75
            }),
            new FormulaireItem('t_flgnrep', {
                largeur: 75
            }),
            new FormulaireItem('qdvcleint'),
            new FormulaireItem('qsrtxtaut'),
            //Colonnes cachées
            new FormulaireItem('stamnetab', { default: 'EPL' }),
            new FormulaireItem('qsrflgon')
        ], { largeurDefaut: 33.33 }),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 262 },
        ],
        colonnesVisibles: [
            { nom: 'qstcod', largeur: 120 },
            { nom: 'qstdsc', largeur: 262 },
            { nom: 'grqnom', largeur: 157 },
            { nom: 'qsdnom', largeur: 274 },
            { nom: 'qsdtxtqst', largeur: 274 },
            { nom: 'reponse', largeur: 86 },
            { nom: 'qdvtxtval', largeur: 364 },
            { nom: 'qsrtxtaut', largeur: 343 }
        ]
    };

}
