import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageDO0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0018Controller(do0001Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         do0015Route: IRoute,
                                         do0093Route: IRoute,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageDO0018 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: do0001Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                offcleint: rowData.offcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(do0001Route.NAME)
        ]),

        etatsPredefinis: [
            {
                nom: 'TOUS',
                filtres: [
                    {
                        colonne: 'axe_vis',
                        operateur: Operateur.EGALE,
                        valeur: 'TOUS'
                    }
                ],
                colonnesVisibles: [
                     'offnumdoc',
                     'ttccod',
                     'offdsc',
                     'ecldsceta',
                     'nbr_cand',
                     'nbr_conv',
                     'vaeofftyp_dsc',
                     'canselnom',
                     'aftdatdeb',
                     'vaeoffori_dsc',
                     'codref',
                     'dscref',
                     'urgcod',
                     'urgdscabr',
                     'unocod',
                     'unodscabr',
                     'posnumdoc',
                     'postitabr',
                     'temcod',
                     'temdscabr',
                     'strcod',
                     'strdsc',
                     'litcod',
                     'litdsc',
                     'poscod_sup',
                     'postitabr_sup',
                     'offdatdebemp',
                     'offdatfinemp',
                     'rglcod',
                     'rgldscabr',
                     'qstcod',
                     'qstdsc',
                     'offdatdebaff',
                     'offdatfinaff',
                     'intcod',
                     'intnom',
                     'gafcod',
                     'gafdsc',
                     'trnflgstaref',
                     'trnflgstafin',
                     'trnflgstafer',
                     'trnflgstaann'
                ]
            },
            {
                nom: 'OFFRE',
                filtres: [
                    {
                        colonne: 'axe_vis',
                        operateur: Operateur.EGALE,
                        valeur: 'OFF'
                    }
                ],
                colonnesVisibles: [
                     'offnumdoc',
                     'ttccod',
                     'offdsc',
                     'ecldsceta',
                    'nbr_cand',
                    'nbr_conv',
                     'vaeofftyp_dsc',
                     'canselnom',
                     'aftdatdeb',
                     'vaeoffori_dsc',
                     'codref',
                     'dscref',
                     'urgcod',
                     'urgdscabr',
                     'unocod',
                     'unodscabr',
                     'posnumdoc',
                     'postitabr',
                     'temcod',
                     'temdscabr',
                     'strcod',
                     'strdsc',
                     'litcod',
                     'litdsc',
                     'poscod_sup',
                     'postitabr_sup',
                     'offdatdebemp',
                     'offdatfinemp',
                     'rglcod',
                     'rgldscabr',
                     'qstcod',
                     'qstdsc',
                     'offdatdebaff',
                     'offdatfinaff',
                     'intcod',
                     'intnom',
                     'gafcod',
                     'gafdsc',
                    'trnflgstaref',
                    'trnflgstafin',
                    'trnflgstafer',
                    'trnflgstaann'
                ]
            },
            {
                nom: 'BANQUE',
                filtres: [
                    {
                        colonne: 'axe_vis',
                        operateur: Operateur.EGALE,
                        valeur: 'BQE'
                    }
                ],
                colonnesVisibles: [
                     'offnumdoc',
                     'ttccod',
                     'offdsc',
                     'ecldsceta',
                     'nbr_cand',
                     'nbr_conv',
                     'vaeofftyp_dsc',
                     'vaeoffori_dsc',
                     'codref',
                     'dscref',
                     'urgcod',
                     'urgdscabr',
                     'unocod',
                     'unodscabr',
                     'temcod',
                     'temdscabr',
                     'strcod',
                     'strdsc',
                     'litcod',
                     'litdsc',
                     'poscod_sup',
                     'postitabr_sup',
                     'rglcod',
                     'rgldscabr',
                     'qstcod',
                     'qstdsc',
                     'offdatdebaff',
                     'offdatfinaff',
                     'intcod',
                     'intnom',
                     'gafcod',
                     'gafdsc',
                    'trnflgstaref',
                    'trnflgstafin',
                    'trnflgstafer',
                    'trnflgstaann'
                ]
            }
        ],
        filtres: parametresRecherche(ecranDetails),
        colonnesCachees:[
            'offcleint'
        ],
        colonnesVisibles: [
            {nom: 'offnumdoc', largeur: 136},
            {nom: 'ttccod', largeur: 100},
            {nom: 'offdsc', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'nbr_cand', largeur: 115,
               menu: [
                   new MenuItemForage(
                       'LBL_FORAGE_DO0015',
                       do0015Route.NAME,
                       (data: any) => {
                           return {
                               ecran: '01-2',
                               offcleint: data.offcleint
                           };
                       },
                       {
                           disabled: (data: any) => {
                               return !data.nbr_cand;
                           }
                       }
                   )
               ]
            },
            {nom: 'nbr_conv', largeur: 115,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_DO0093',
                        do0093Route.NAME,
                        (data: any) => {
                            return {
                                ecran: '01-2',
                                offcleint: data.offcleint
                            };
                        },
                        {
                            disabled: (data: any) => {
                                return !data.nbr_conv;
                            }
                        }
                    )
                ]
            },
            {nom: 'vaeofftyp_dsc', largeur: 131},
            {nom: 'canselnom', largeur: 262},
            {nom: 'aftdatdeb', largeur: 110},
            {nom: 'vaeoffori_dsc', largeur: 131},
            {nom: 'codref', largeur: 110},
            {nom: 'dscref', largeur: 310},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'posnumdoc', largeur: 100},
            {nom: 'postitabr', largeur: 226},
            {nom: 'temcod', largeur: 100},
            {nom: 'temdscabr', largeur: 226},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 262},
            {nom: 'litcod', largeur: 90},
            {nom: 'litdsc', largeur: 262},
            {nom: 'poscod_sup', largeur: 130},
            {nom: 'postitabr_sup', largeur: 262},
            {nom: 'offdatdebemp', largeur: 120},
            {nom: 'offdatfinemp', largeur: 100},
            {nom: 'rglcod', largeur: 90},
            {nom: 'rgldscabr', largeur: 262},
            {nom: 'qstcod', largeur: 110},
            {nom: 'qstdsc', largeur: 262},
            {nom: 'offdatdebaff', largeur: 110},
            {nom: 'offdatfinaff', largeur: 110},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 262},
            {nom: 'gafcod', largeur: 125},
            {nom: 'gafdsc', largeur: 262},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
