import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr2011 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    irdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function mr2011Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr2011 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('intcodnom'),
            new FormulaireItem('rolcoddsc')
        ])
    };

    vm.irdMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'irdtytreg_dsc', largeur: 200},
            {nom: 'irddatdeb', largeur: 126},
            {nom: 'irddatfin', largeur: 126}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('irdtytreg'),
            new FormulaireItem('irddatdeb'),
            new FormulaireItem('irddatfin')
        ], {largeurDefaut: 33.33})
    };
}