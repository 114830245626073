import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import {IStateParamsService} from 'angular-ui-router';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
interface IPageTs0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Ts0005Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Operateur: IOperateurService,
                                         $stateParams:IStateParamsService,
                                         ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageTs0005 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'eplnomprn', valeur:$stateParams.eplnomprn,readOnly:true}
        ],
        colonnesCachees:['demcleint'],
        colonnesFixesGauche : [
            {nom: 'eplnummat', largeur:100, hidden:  $stateParams.employe},
            {nom: 'eplnomprn', largeur:262, hidden: $stateParams.employe},
            {nom: 'mrrcod', largeur:150}
        ],
        colonnesVisibles: [
            {nom: 'mrrdsc', largeur:300},
            {nom: 'diddatdeb', largeur:140},
            {nom: 'didhredeb', largeur:100},
            {nom: 'diddatfin', largeur:140},
            {nom: 'didhrefin', largeur:100},
            {nom: 'didflgact', largeur:100},
            {nom: 'didflggen', largeur:100},
            {nom: 'vaedidori_dsc', largeur:173}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', {largeur:50,default: () => $stateParams.demcleint}),
            new FormulaireItem('mrrcleint',{largeur:50}),
            new FormulaireItem('diddatdeb',{largeur:33.33}),
            new FormulaireItem('diddatfin',{largeur:33.33}),
            new FormulaireItem('didflgact',{largeur:33.33, default: 1})
        ]),
        fonctions: {
            importation: true
        },
        filtres: [
            {
                colonne: 'didflgann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1',
                visible : false
            }
        ]
    };
}
