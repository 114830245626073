import {IComponentController} from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import {IInfosMonetaires} from "../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller";
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IMenuItemActionImportationClass} from "../../../../../core/services/menu/menu-item-action-importation.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";

interface IPageRr0159 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireFaa: IFormulaire;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    fadMultiOccurrenceOptions: IMultiOccurrenceOptions;
    geeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0159Controller(Formulaire: IFormulaireClass,
                                         rr0079Route: IRoute,
                                         $state: IStateService,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemActionImportation: IMenuItemActionImportationClass,
                                         Operateur: IOperateurService) {
    const vm: IPageRr0159 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_SUP',
                'BOUSUP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    successAction: (data: any) => {
                        $state.go(rr0079Route.NAME, {
                        });
                    }
                }
            ),
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    hidden: () => !((vm.monoOccurrence.data.faaflgtrt && !vm.monoOccurrence.data.faanbrlig) ||
                                   (!vm.monoOccurrence.data.faaflgtrt && vm.monoOccurrence.data.faanbrlig))
                }
            ),
            new MenuItemAction(
                'LBL_BTN_TRT',
                'BOUTRT',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    hidden: () => !(vm.monoOccurrence.data.faaflgval && !vm.monoOccurrence.data.faaflgtrt)
                }
            ),
            new MenuItemActionImportation(
                'LBL_CHARGEMENT_TXT',
                'BOUCHA',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_TXT',
                        largeurFormulaire: 15,
                        formulaire: new Formulaire([
                            new FormulaireItem('faanumcha', {
                                largeur: 100,
                                default: (data:any) => data.monoOccurrence.faanumcha
                            })
                        ])
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    typesFichiers: ['.csv'],
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    hidden: () => (
                        vm.monoOccurrence.data.faanbrlig
                    )
                }
            )
        ]
    };


    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireFaa = new Formulaire('LBL_BLC_FAA', [
            new FormulaireItem('faanumcha'),
            new FormulaireItem('faaficsou'),
            new FormulaireItem('ppacleint'),
            new FormulaireItem('tagcleint'),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('faanbrlig')
        ]);

        vm.fadMultiOccurrenceOptions = {
            filtres: [
                {
                    colonne: 'fadflgerr',
                    operateur: Operateur.EGALE,
                    valeur: ''
                }
            ],
            colonnesFixesGauche: [
                {nom: 'fadflgerr'   , largeur: 75}
            ],
            colonnesVisibles: [
                {nom: 'eplnummat'   , largeur: 120},
                {nom: 'eplnomprn'   , largeur: 190},
                {nom: 'tagcod'      , largeur: 120},
                {nom: 'tagdsc'      , largeur: 220},
                {nom: 'ppacod'      , largeur: 120},
                {nom: 'fadmnt'      , largeur: 120}
            ],
            colonnesCachees:['fadmsgerr'],
            formulaire: new Formulaire([
                new FormulaireItem('demcleint'),
                new FormulaireItem('tagcleint'),
                new FormulaireItem('ppacleint'),
                new FormulaireItem('fadmnt')
            ], {largeurDefaut: 33})
        };


    }
}
