import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";

interface IPageRr0501 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireFep: IFormulaire;
    formulaireAutres: IFormulaire;
    fedMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fedmanMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursFep: any;
}

interface IRr0501Params {
    typsai: string;
    demcleint: string;
}

/* @ngInject */
export default function Rr0501Controller(Menu: IMenuClass,
                                         rr0506Route: IRoute,
                                         $stateParams: IRr0501Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRr0501 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursFep = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_AJUSTEMENT_MANUEL',
                    rr0506Route.NAME,
                    (data: any) => {
                        return {
                            id: data.fepcleint,
                            typsai: $stateParams.typsai
                        };
                    },
                    {
                        icon: 'launch_app',
                        iconButton: false,
                        hidden: () => ($stateParams.typsai === 'A'),
                        onClose: () => { vm.monoOccurrence.fetchData() }
                    }
                )
            ])
        };

        vm.formulaireFep = new Formulaire('LBL_BLC_FEP', [
            new FormulaireItem('demcleint', { default: $stateParams.demcleint }),
            new FormulaireItem('tpfcleint', { resourceParams: () => ({ typsai: $stateParams.typsai }) }),
            new FormulaireItem('fepcod'),
            new FormulaireItem('ppacleint'),
            new FormulaireItem('unpcleint'),
            new FormulaireItem('percleint'),
            new FormulaireItem('mopcleint'),
            new FormulaireItem('fepcom', {largeur: 66, enableCount: true})
        ], {largeurDefaut: 33});

        vm.formulaireAutres = new Formulaire('LBL_BLC_AUT', [
            new FormulaireItem('fepnbrppaava'),
            new FormulaireItem('aut__fepflgcal'),
            new FormulaireItem('ppacleintcalfor', { resourceParams: (data:any) => ({ ppacleint: data.ppacleint }) }),
            new FormulaireItem('fepflgexccou', {largeur: 50}),
            new FormulaireItem('fepflgexcanncou', {largeur: 50})
        ], {largeurDefaut: 100});

        // Détail des feuilles de paie
        vm.fedMultiOccurrenceOptions = {
            bloc: 'fed',
            fonctions: {
                editionRapide: true
            },
            colonnesFixesGauche: [
                {nom: 'ppacod'   , largeur: 130, hidden: $stateParams.typsai === 'A'},
                {nom: 'feddatdeb', largeur: 100, hidden: $stateParams.typsai === 'A'},
                {nom: 'feddatfin', largeur: 100, hidden: $stateParams.typsai === 'A'},
                {nom: 'tagcod'   , largeur: 100}
            ],
            colonnesVisibles: [
                {nom: 'tagdsc'          , largeur: 230},
                {nom: 'tagpar'          , largeur: 150, hidden: $stateParams.typsai === 'A'},
                {nom: 'unccod'          , largeur: 80},
                {nom: 'fedqte'          , largeur: 126},
                {nom: 'fedtauhor'       , largeur: 126, hidden: $stateParams.typsai === 'A'},
                {nom: 'fedmnt'          , largeur: 126},
                {nom: 'aftcod'          , largeur: 130},
                {nom: 'lemcod'          , largeur: 130, hidden: () => !vm.valeursFep.pciflgsailemfep },
                {nom: 'posnumdoc'       , largeur: 130, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiposfep) },
                {nom: 'temcod'          , largeur: 130, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaitemfep) },
                {nom: 'unocodrh'        , largeur: 130, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiunofep) },
                {nom: 'dernumdoc'       , largeur: 130, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiderfep) },
                {nom: 'drpnumdoc'       , largeur: 130, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaidrpfep) },
                {nom: 'echcod'          , largeur: 130, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiechfep)  },
                {nom: 'vaesysprvfed_dsc', largeur: 160, hidden: $stateParams.typsai === 'A'},
                {nom: 'sutcod'          , largeur: 130, hidden: !vm.valeursFep.sutflgaff || $stateParams.typsai === 'A'},
                {nom: 'esvcod1'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff1 || $stateParams.typsai === 'A'},
                {nom: 'esvcod2'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff2 || $stateParams.typsai === 'A'},
                {nom: 'esvcod3'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff3 || $stateParams.typsai === 'A'},
                {nom: 'esvcod4'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff4 || $stateParams.typsai === 'A'},
                {nom: 'esvcod5'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff5 || $stateParams.typsai === 'A'},
                {nom: 'fedimp'          , largeur: 250, hidden: $stateParams.typsai === 'A'},
                {nom: 'fedimpavs'       , largeur: 250, hidden: $stateParams.typsai === 'A'},
                {nom: 'fedflgannvbq'    , largeur: 130, hidden: $stateParams.typsai === 'A'}
            ],
            colonnesEditionRapide: [
                {nom: 'aut__demcleint', largeur: 100, hidden: true},
                {nom: 'ppacleintcal' , largeur: 280, hidden: $stateParams.typsai === 'A'},
                {nom: 'feddatdeb'    , largeur: 160, hidden: $stateParams.typsai === 'A'},
                {nom: 'feddatfin'    , largeur: 160, hidden: $stateParams.typsai === 'A'},
                {nom: 'tagcleint'    , largeur: 300, resourceParams: (data:any) => (
                        {
                            ppacleint: data.$ancetre.ppacleint,
                            tpfcleint: data.$ancetre.tpfcleint
                        })
                },
                {nom: 'fedqte'       , largeur: 120},
                {nom: 'fedtauhor'    , largeur: 120, hidden: $stateParams.typsai === 'A'},
                {nom: 'fedmnt'       , largeur: 120},
                {nom: 'aftcleint'    , largeur: 300, resourceParams: (data:any) => ({demcleint: data.$ancetre.demcleint})},
                {nom: 'lemcleint'    , largeur: 300, hidden: () => !vm.valeursFep.pciflgsailemfep},
                {nom: 'poscleint'    , largeur: 300, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiposfep) },
                {nom: 'temcleint'    , largeur: 300, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiunofep)  },
                {nom: 'unocleintrh'  , largeur: 300, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiunofep) },
                {nom: 'dercleint'    , largeur: 300, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiderfep) },
                {nom: 'drpcleint'    , largeur: 300, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaidrpfep) },
                {nom: 'echcleint'    , largeur: 300, hidden: () => ($stateParams.typsai === 'A' || !vm.valeursFep.pciflgsaiechfep) },
                {nom: 'esvcleint1'   , largeur: 300, hidden: !vm.valeursFep.esvflgaff1 || $stateParams.typsai === 'A'},
                {nom: 'esvcleint2'   , largeur: 300, hidden: !vm.valeursFep.esvflgaff2 || $stateParams.typsai === 'A'},
                {nom: 'esvcleint3'   , largeur: 300, hidden: !vm.valeursFep.esvflgaff3 || $stateParams.typsai === 'A'},
                {nom: 'esvcleint4'   , largeur: 300, hidden: !vm.valeursFep.esvflgaff4 || $stateParams.typsai === 'A'},
                {nom: 'esvcleint5'   , largeur: 300, hidden: !vm.valeursFep.esvflgaff5 || $stateParams.typsai === 'A'},
                {nom: 'fedimp'       , largeur: 330, hidden: $stateParams.typsai === 'A'}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('aut__demcleint', { default: () => vm.monoOccurrence.data.demcleint }),
                new FormulaireItem('ppacleintcal', {
                    resourceParams: (data:any) => (
                        {
                            ppacleint: data.$ancetre.ppacleint,
                            unpcleint: data.$ancetre.unpcleint
                        })
                }),
                new FormulaireItem('feddatdeb'),
                new FormulaireItem('feddatfin'),
                new FormulaireItem('tagcleint', {
                    resourceParams: (data:any) => (
                        {
                            ppacleint: data.$ancetre.ppacleint,
                            tpfcleint: data.$ancetre.tpfcleint
                        })
                }),
                new FormulaireItem('t_mtgflgtrstemaft'),
                new FormulaireItem('fedqte', {largeur: (66.66 / 3)}),
                new FormulaireItem('fedtauhor', {largeur: (66.66 / 3)}),
                new FormulaireItem('fedmnt', {largeur: (66.66 / 3)}),
                new FormulaireItem('aftcleint', {resourceParams: (data:any) => ({demcleint: data.$ancetre.demcleint})}),
                new FormulaireItem('lemcleint'),
                new FormulaireItem('poscleint'),
                new FormulaireItem('temcleint', {
                    resourceParams: (rowData:any) => (
                        {
                            aftcleint: rowData.aftcleint,
                            lemcleint: rowData.lemcleint
                        })
                }),
                new FormulaireItem('unocleintrh', {
                    resourceParams: (rowData:any) => (
                        {
                            feddatdeb: rowData.feddatdeb,
                            feddatfin: rowData.feddatfin
                        })
                }),
                new FormulaireItem('dercleint'),
                new FormulaireItem('drpcleint'),
                new FormulaireItem('echcleint', {resourceParams: (rowData:any) => ({aftcleint: rowData.aftcleint})}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('esvcleint1'),
                new FormulaireItem('esvcleint2'),
                new FormulaireItem('esvcleint3'),
                new FormulaireItem('esvcleint4'),
                new FormulaireItem('esvcleint5'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fedflgannvbq'),
                new FormulaireItemDivider('LBL_DIV_IMP', { hidden: (data) => data.$params.typsai === 'A' }),
                new FormulaireItem('fedimp'),
                new FormulaireItem('fedimpavs', {titre: 'fedimpavs'})
            ], {largeurDefaut: 33.33})
        };

        // Détail des feuilles de paie
        vm.fedmanMultiOccurrenceOptions = {
            bloc: 'fedman',
            colonnesFixesGauche: [
                {nom: 'ppacod'   , largeur: 130},
                {nom: 'feddatdeb', largeur: 100},
                {nom: 'feddatfin', largeur: 100},
                {nom: 'tagcod'   , largeur: 100}
            ],
            colonnesVisibles: [
                {nom: 'tagdsc'          , largeur: 230},
                {nom: 'unccod'          , largeur: 80},
                {nom: 'fedqte'          , largeur: 126},
                {nom: 'fedtauhor'       , largeur: 126},
                {nom: 'fedmnt'          , largeur: 126},
                {nom: 'aftcod'          , largeur: 130},
                {nom: 'lemcod'          , largeur: 130, hidden: () => !vm.valeursFep.pciflgsailemfep },
                {nom: 'posnumdoc'       , largeur: 130, hidden: () => !vm.valeursFep.pciflgsaiposfep },
                {nom: 'temcod'          , largeur: 130, hidden: () => !vm.valeursFep.pciflgsaitemfep },
                {nom: 'unocodrh'        , largeur: 130, hidden: () => !vm.valeursFep.pciflgsaiunofep },
                {nom: 'esvcod1'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff1},
                {nom: 'esvcod2'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff2},
                {nom: 'esvcod3'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff3},
                {nom: 'esvcod4'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff4},
                {nom: 'esvcod5'         , largeur: 130, hidden: !vm.valeursFep.esvflgaff5},
                {nom: 'fedimp'          , largeur: 250},
                {nom: 'fedimpavs'       , largeur: 250}
            ]
        };
    }
}
