import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAS0056 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0056Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAS0056 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleint', {
                required: false
            }),
            new FormulaireItem('flgatt', {
                default: '',
                required: true
            }),
            new FormulaireItem('flgerr', {
                default: '',
                required: true
            })
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 200},
            {nom: 'eplnomprn', largeur: 200},
            {nom: 'aicdatdeb', largeur: 200},
            {nom: 'aicdatfin', largeur: 200},
            {nom: 'aicflgerr', largeur: 200},
            {nom: 'aicflgatt', largeur: 200}
        ]
    };
}
