import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaire } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageCr0050 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    formulaireSession1: IFormulaire;
    formulaireSession2: IFormulaire;
    formulaireSession3: IFormulaire;
    formulaireSession4: IFormulaire;
    valeursEcran: any;
}

/* @ngInject */
export default function Cr050Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageCr0050 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            edition: ecranDetails.valeurs.flgiud
        },
        formulaire: new Formulaire(' ',[
            new FormulaireItem('cldcleint', { largeur: 25 }),
            new FormulaireItem('dofcodperm', { largeur: 25 }),
            new FormulaireItem('dofann', { largeur: 25 }),
            new FormulaireItem('dofcoduni', { default: 'POL', largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dofnomprnetu', { largeur: 50 }),
            new FormulaireItem('dofcodrel', { default: 'R', largeur: 25 }),
            new FormulaireItem('dofcoddes', { default: 'W', largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dofadrl1a', { largeur: 50 }),
            new FormulaireItem('dofdatoff', { readonly: true, largeur: 25 }),
            new FormulaireItem('dofnument', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('dofadrl1b', { titre: ' ', largeur: 67 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofadrl2', { largeur: 67 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofadrl3', { largeur: 67 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofadrl4', { largeur: 67 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofcodpos', { largeur: 25 }),
                new FormulaireItem('dofnas', { largeur: 25 }),
            ]), { largeur: 74 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('dofnumrel'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofnumrelimp'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofnumreltsm')
            ]), { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('dofnompro', { largeur: 75 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofgraetu', { largeur: 25 }),
                new FormulaireItem('doftptc', { default: 'P', largeur: 35 })
            ]), { largeur: 74 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('dofmnttotfra', { default: 0 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofmntpossec', { default: 0 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('dofmntdon', { default: 0 })
            ]), { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('doftottc', { default: 0, largeur: 25 }),
            new FormulaireItem('doftottp', { default: 0, largeur: 25 }),
            new FormulaireItemMenuItem(new MenuItemAction(
                'LBL_BTN_AME',
                'BOUAME',
                (data: any) => ({}),
                {
                    iconButton: false,
                    disabled: ecranDetails.valeurs.flgbtname,
                    saveFirst: true,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }
            ))
        ], {
            largeurDefaut: 100
        })
    };

    vm.formulaireSession1 = new Formulaire('LBL_SES1', [
        new FormulaireItem('dofmoideb1'),
        new FormulaireItem('dofmoifin1'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('doffra1'),
        new FormulaireItem('doftc1'),
        new FormulaireItem('doftp1')
    ], { largeurDefaut: 10 });

    vm.formulaireSession2 = new Formulaire('LBL_SES2', [
        new FormulaireItem('dofmoideb2'),
        new FormulaireItem('dofmoifin2'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('doffra2'),
        new FormulaireItem('doftc2'),
        new FormulaireItem('doftp2')
    ], { largeurDefaut: 10 });

    vm.formulaireSession3 = new Formulaire('LBL_SES3', [
        new FormulaireItem('dofmoideb3'),
        new FormulaireItem('dofmoifin3'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('doffra3'),
        new FormulaireItem('doftc3'),
        new FormulaireItem('doftp3')
    ], { largeurDefaut: 10 });

    vm.formulaireSession4 = new Formulaire('LBL_SES4', [
        new FormulaireItem('dofmoideb4'),
        new FormulaireItem('dofmoifin4'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('doffra4'),
        new FormulaireItem('doftc4'),
        new FormulaireItem('doftp4')
    ], { largeurDefaut: 10 });

}
