import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import {  IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageTf0011 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions; 
    valeursEcran: any;     
}

/* @ngInject */
export default function Tf0011Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {

    const vm: IPageTf0011 = this;   
    vm.valeursEcran = ecranDetails.valeurs;
    vm.monoOccurrenceOptions={

        formulaire: new Formulaire( [
            new FormulaireItem('efacleint',{largeur: 30, disabled: (data) => data.flguti}),
            new FormulaireItemSautDeLigne(),
             new FormulaireItem('folcleint',{largeur: 50, disabled: (data) => data.flguti}),             
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('rfecleint',{largeur: 50, disabled: (data) => data.flguti}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('vaesyscodprv',{largeur: 50, disabled: (data) => data.flguti, required: true}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('vaeftxtyp',{largeur: 25, disabled: (data) => data.flguti}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('ftxcleintcodref',{largeur: 50, disabled: (data) => data.flguti, required: (data) => !(data.vaeftxtyp === 'FE')}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('ftxmnt',{largeur: 50, disabled: (data) => data.flguti}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('ftxmntemr',{largeur: 50, disabled: true}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('ftxcom',{largeur: 50, disabled: (data) => data.flguti}),
             new FormulaireItemSautDeLigne(),
             new FormulaireItem('flguti',{largeur: 50, disabled: true, default: ecranDetails.valeurs.flguti })         
             
        ])
    }    
   
};


