import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';

interface IPageRr0065 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IRr0065Params {
    bqacleint: string;
    employe: string
}

/* @ngInject */
export default function Rr0065Controller($stateParams: IRr0065Params,
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0065 = this;

    const filtres: Array<any> = [];

    if ($stateParams.bqacleint) {
        filtres.push(
            {
                colonne: 'bqacleint',
                valeur: $stateParams.bqacleint,
                visible: false
            }
        )
    }

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        filtres,
        fonctions: {
            importation: Boolean(vm.valeursEcran.flgimp),
            rechercheCollapsable: false
        },
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/rr0065/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleint', { hidden: Boolean($stateParams.employe), required: false }),
            new FormulaireItem('bqecleint', { required: false }),
            new FormulaireItem('dattrs', { required: false })
        ]),

        colonnesFixesGauche: [
            { nom: 'bqanumdoc', largeur: 130 },
            { nom: 'eplnummat', largeur: 110, hidden: !!$stateParams.employe },
            { nom: 'eplnomprn', largeur: 190, hidden: !!$stateParams.employe }
        ],
        colonnesVisibles: [
            { nom: 'bqecod', largeur: 120 },
            { nom: 'bqedscabr', largeur: 200 },
            { nom: 'bqadattrs', largeur: 140 },
            { nom: 'bqaqte', largeur: 110 },
            { nom: 'unccod', largeur: 130 },
            { nom: 'vaebqaprv_dsc', largeur: 150 },
            { nom: 'bqadsc', largeur: 230 },
            { nom: 'dbscod1', largeur: 120 },
            { nom: 'dbsdsc1', largeur: 180 },
            { nom: 'dbscod2', largeur: 120 },
            { nom: 'dbsdsc2', largeur: 180 }
        ],
        colonnesFixesDroite: [
            { nom: 'nbrpij', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bqanumdoc'),
            new FormulaireItem('demcleint', { readonly: (data: any) => data.$id || $stateParams.employe, default: $stateParams.employe, required: (data: any) => !data.$id && !$stateParams.employe }),
            new FormulaireItem("demcleint__eplnummat", { hidden: true, default: ecranDetails.employe && ecranDetails.employe.eplnummat }),
            new FormulaireItem("demcleint__eplnomprn", { hidden: true, default: ecranDetails.employe && ecranDetails.employe.eplnomprn }),
            new FormulaireItem('bqecleint', { readonly: (data: any) => data.$id }),
            new FormulaireItem('bqadattrs', { readonly: (data: any) => data.$id }),
            new FormulaireItem('bqaqte', { readonly: (data: any) => data.$id }),
            new FormulaireItem('dbscleintref1', { readonly: (data: any) => data.$id, resourceParams: (data: any) => ({ demcleint: data.demcleint, numref: 1 }) }),
            new FormulaireItem('dbscleintref2', { readonly: (data: any) => data.$id, resourceParams: (data: any) => ({ demcleint: data.demcleint, numref: 2 }) }),
            new FormulaireItem("unccod", { readonly: true }),
            new FormulaireItem('bqadsc', { enableCount: true, largeur: 100 }),
            new FormulaireItem('vaebqaprv', { readonly: true })
        ], { largeurDefaut: 25 })
    };
}
