import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCR0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0009Controller(Menu: IMenuClass,
                                         cr0010Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCR0009 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cr0010Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cr0010Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'loecod'   , largeur: 120},
            {nom: 'peccod'   , largeur: 120},
            {nom: 'usrcod'   , largeur: 120},
            {nom: 'usrnomcre', largeur: 200},
            {nom: 'loedsc'   , largeur: 200}
        ]
    };
}
