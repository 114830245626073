import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';


interface IPageAc0028 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireApo: IFormulaire;
    aosMultiOccurrenceOptions: IMultiOccurrenceOptions;
    docMultiOccurrenceOptions: IMultiOccurrenceOptions;
    docMultiOccurrence: IMultiOccurrence;

    adoMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0028Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
   ecranDetails: IEcranDetailsResourcesEntite,
   MenuItemAction: IMenuItemActionClass,
) {
    const vm: IPageAc0028 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.monoOccurrenceOptions = {
            infosMonetaires: {
                blocs: ['APO'],
                largeurTexte: 150,
                donnees:
                    [
                        { nom: 'apomnt', libelle: 'MNTBASE' },
                        { nom: 'apomntbdg', libelle: 'MNTPROL', lastOfGroup: true },
                        { nom: 'docref', libelle: 'DOCREF' },
                        { nom: 'mntdec', libelle: 'MNTDEC' },
                        { nom: 'mntpai', libelle: 'MNTPAI', lastOfGroup: true },
                        { nom: 'fouinv', libelle: 'FOUINV' },
                        { nom: 'fouao', libelle: 'FOUAO' },
                        { nom: 'soumis', libelle: 'SOUMIS' }
                    ]
            },

            fonctions: {
                importation: true,
                supprime: false,
                boutonDupliquer: false
            }
        };

        vm.formulaireApo = new Formulaire('LBL_BLC_APO', [
            new FormulaireItem('aponumdoc', { largeur: 30 }),
            new FormulaireItem('apoindreg10', { largeur: 30 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tydcleint', {required: true,  largeur: 30 }),
            new FormulaireItem('vaeapoon', {default: 2, required: true, largeur: 30}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('aponumref', { required: true, largeur: 30 }),
            new FormulaireItem('aponumseao', { largeur: 15 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('apodatpub', { largeur: 30 }),
            new FormulaireItem('apodatfer', { largeur: 30 }),
            new FormulaireItem('apoflgpij', { largeur: 20 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('apodatred', { largeur: 30 }),
            new FormulaireItem('apodatfin', { largeur: 30 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ncocleint', {largeur: 60 }),
            new FormulaireItem('apomnt', {largeur: 30 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tnecleint', { largeur: 30 }),
            new FormulaireItem('vecapomodadj', { largeur: 30 }),
            new FormulaireItem('apomntbdg', {largeur: 30 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('intcleintreq', { required: true, largeur: 45 }),
            new FormulaireItem('intcleintach', { required: true, largeur: 30 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('apodsc', { required: true, largeur: 60, enableCount: true })

        ], { largeurDefaut: 80 })
    }

    vm.aosMultiOccurrenceOptions = {
        bloc: 'aos',  // Fournisseurs
        fonctions: {
            boutonDupliquer: false,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'refcod', largeur: 30 },
            { nom: 'refnom', largeur: 30 },
            { nom: 'radcod', largeur: 30 },
            { nom: 'adrdsc', largeur: 30 },
            { nom: 'radnom', largeur: 30 },
            { nom: 'vecdscinv', largeur: 30 },
            { nom: 'vecdscrep', largeur: 30 },
            { nom: 'aoscom', largeur: 100 },
            { nom: 'aosindadj', largeur: 30 }
        ],

        formulaire: new Formulaire('LBL_BLC_AOS', [
            new FormulaireItem('refcleint'),
            new FormulaireItem('radcleint'),
            new FormulaireItem('vecaosinv'),
            new FormulaireItem('vecaosrep'),
            new FormulaireItem('aosindadj'),
            new FormulaireItem('aoscom')
        ], { largeurDefaut: 60 })
    };

    vm.docMultiOccurrenceOptions = {
        bloc: 'doc', // Document référé
        fonctions: {
            boutonDupliquer: false,
            nouveau: true,
            edition: false,
            supprime: true,
            rechercheCollapsable: false
        },

    actionsNouveaux: new Menu([
        new MenuItemAction(
            'LBL_BTN_AJT_DOC',
            'BOUAJTDOC',
            () => ({}),
            {
                icon: 'add_circle',
                fonction: 'BOUAJTDOC',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_AJT_DOC',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('doccleintajtdoc', { required: true, maxlength: 300 })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => {
                    vm.docMultiOccurrence.fetchDataList();
                }
            }
        )
    ]),

        colonnesVisibles: [
            { nom: 'docaffnumdoc01', largeur: 30 },
            { nom: 'docaffsysboncmd01', largeur: 30 },
            { nom: 'tydcod', largeur: 30 },
            { nom: 'tyddsc', largeur: 500 },
            { nom: 'docaffmnt01_atcan', largeur: 30 },
            { nom: 'docaffmnt01', largeur: 30 },
            { nom: 'refcod', largeur: 30 },
            { nom: 'refnom', largeur: 30 },
            { nom: 'docaffdsc01', largeur: 500 }
        ]
    };

    vm.adoMultiOccurrenceOptions = {
        bloc: 'ado', // Déclatation SEAO
        fonctions: {
            boutonDupliquer: false,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'adodat', largeur: 30 },
            { nom: 'tdocod', largeur: 30 },
            { nom: 'tdodsc', largeur: 30 },
            { nom: 'adomnt', largeur: 30 },
            { nom: 'adoconfirmation', largeur: 60 },
            { nom: 'adoflgann', largeur: 30 }
        ],

        formulaire: new Formulaire('LBL_BLC_ADO', [
            new FormulaireItem('adodat'),
            new FormulaireItem('tdocleint'),
            new FormulaireItem('adomnt'),
            new FormulaireItem('adoconfirmation'),
            new FormulaireItem('adoflgann')
        ], { largeurDefaut: 30 })
    };

    // Plaquette Autres détails
    vm.formulaireAutresDetails = new Formulaire('LBL_BLC_AUT', [
        new FormulaireItem('nibcleint'),
        new FormulaireItem('vecapoouvpub'),
        new FormulaireItem('apodatdeb'),
        new FormulaireItem('aponumrescd', { readonly: true }),
        new FormulaireItem('aponumresca', { readonly: true }),
    ], { largeurDefaut: 50 });
}
