import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageTf0039 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0039Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTf0039 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ttoann', largeur: 100},
            {nom: 'tagcod', largeur: 130},
            {nom: 'tagdsc', largeur: 330}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ttoann', {required: true}),
            new FormulaireItem('tagcleint', {required: true})
        ])
    };
}
