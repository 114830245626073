import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageAl0006 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    davMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dcqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    darMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Al0006Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    $stateParams: IStateParamsService,
    MenuItemAction: IMenuItemActionClass,
) {
    const vm: IPageAl0006 = this;
    //Calcule des avantages
    vm.btnMenus = [
        new MenuItemAction("LBL_BTN_CAL", "BOUCALAV", () => ({}),
            {
                iconButton: false,
                icon: "",
                secondaire: true,
                successAction: () => { vm.monoOccurrence.fetchData() }
            })
    ];
    vm.monoOccurrenceOptions = {
        fonctions: {
            exportation: true
        }
    }
    vm.formulaireDfc = new Formulaire('Facturation ALD des avantages', [
        new FormulaireItem('dalcleint', {
            largeur: 33,
            required: false,
            default: $stateParams.dalcleint,
            disabled: true,
            hidden: true
        }),
        new FormulaireItem('demcleint', {
            largeur: 33,
            required: false,
            default: $stateParams.demcleint,
            disabled: true
        }),
        new FormulaireItem('eadcleint', {
            largeur: 33,
            required: false,
            disabled: false,
            resourceParams: (data: any) => ({ demcleint: $stateParams.demcleint }),
            default: ecranDetails.valeurs.eadcleint
        }),
        new FormulaireGroupe('Assume la part employeur', new Formulaire([
            new FormulaireItem('dfcflgemrass', {
                largeur: 33,
                required: false
            }),
            new FormulaireItem('dfcflgemrfpn', {
                largeur: 33,
                required: false
            })
        ])),
        new FormulaireItemSautDeLigne(),
        new FormulaireGroupe('Inclure la part employé', new Formulaire([
            new FormulaireItem('dfcflgempass', {
                largeur: 33,
                required: false
            }),
            new FormulaireItem('dfcflgempfpn', {
                largeur: 33,
                required: false
            })
        ])),
        new FormulaireItemSautDeLigne(),
        new FormulaireGroupe('Dates', new Formulaire([
            new FormulaireItem('dfcdatdeb', {
                largeur: 33,
                required: true
            }),
            new FormulaireItem('dfcdatfin', {
                largeur: 33,
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dfcdatenv', {
                largeur: 33,
                required: false,
                default: ecranDetails.valeurs.dfcdatenv
            }),
            new FormulaireItem('dfcprcabs', {
                largeur: 33,
                required: true
            })
        ])),
        new FormulaireItemSautDeLigne(),
        new FormulaireGroupe(new Formulaire([
            new FormulaireItem('vaedfchor', {
                largeur: 33,
                required: true,
                default: ecranDetails.valeurs.vaedfchor
            }),
            new FormulaireItem('tagcleint', {
                largeur: 33,
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vacdfcrmb', {
                largeur: 33,
                required: false
            })
        ]))
    ]);
    vm.davMultiOccurrenceOptions = {
        bloc: 'dav',
        fonctions: {
            edition: false
        },
        colonnesVisibles: [
            { nom: 'cod_option', largeur: 130 },
            { nom: 'desc_option', largeur: 130 },
            { nom: 'davflggar', largeur: 130 },
            { nom: 'davsaladm', largeur: 130 },
            { nom: 'davmntemp', largeur: 130 },
            { nom: 'davmntemr', largeur: 130 },
            { nom: 'davmntempppa', largeur: 130 },
            { nom: 'davmntemptot', largeur: 130 },
            { nom: 'cod_s_option', largeur: 130 },
            { nom: 'desc_s_option', largeur: 130 }
        ]
    }

    vm.dcqMultiOccurrenceOptions = {
        bloc: 'dcq',
        colonnesVisibles: [
            { nom: 'dcqnumref', largeur: 130 },
            { nom: 'dcqmnt', largeur: 130 },
            { nom: 'dcqdat', largeur: 130 },
            { nom: 'dcqdattrt', largeur: 130 }
        ],
        colonnesCachees: ['dfccleint'],
        formulaire: new Formulaire([
            new FormulaireItem('dcqnumref', {
                largeur: 33,
                required: true,
            }),
            new FormulaireItem('dcqmnt', {
                largeur: 33,
                required: true,
            }),
            new FormulaireItem('dcqdat', {
                largeur: 33,
                required: true,
            }),
            new FormulaireItem('dcqdattrt', {
                largeur: 33,
                required: false,
                disabled: true
            })
        ])
    };

    vm.darMultiOccurrenceOptions = {
        bloc: 'dar',
        colonnesVisibles: [
            { nom: 'rcecod', largeur: 130 },
            { nom: 'rcedscabr', largeur: 130 },
            { nom: 'darmntarr', largeur: 130 },
            { nom: 'darmntmaxrec', largeur: 130 }
        ]
    };
}
