import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";
import {IMenuItem, IMenuItemClass} from "../../../../../core/services/menu/menu-item.service";
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IStateService} from "angular-ui-router";

interface IPagePb0028 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulairePvl: IFormulaire;
    actionTerminePvl: IMenuItem;
    actionSaisieMontant: IMenuItem;
    vlpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IPb0028Params {
    menuId: string;
}

/* @ngInject */
export default function Pb0028Controller(pb0029Route: IRoute,
                                         $state: IStateService,
                                         $stateParams: IPb0028Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItem: IMenuItemClass,
                                         Menu: IMenuClass) {
    const vm: IPagePb0028 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false
            },
            successActionSauvegarde: () => vm.vlpMultiOccurrence && vm.vlpMultiOccurrence.fetchDataList(),
            updateDataWith: ['vlp']
        };

        // Action Terminé
        vm.actionTerminePvl = new MenuItemAction(
            'LBL_BTN_TERMINE',
            'BOUTERSAI',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                secondaire: true,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                }
            }
        );

        // Action Permettre la saisie des montants
        vm.actionSaisieMontant = new MenuItemAction(
            'LBL_BTN_SAISIE_MONTANT',
            'BOUTERSAI',
            () => ({}),
            {
                icon: 'autorenew',
                iconButton: false,
                secondaire: true,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                }
            }
        );

        // Prévisionnel
        vm.formulairePvl = new Formulaire('LBL_BLC_PVL', [
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pbgcleint'),
                new FormulaireItem('aut__pbgseq')
            ]), {largeur: 50}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('aut__pvllbl', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.aut__pvlflgter === 1 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.aut__pvlflgter === 1 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg',
                            hidden: (data:any) => !vm.monoOccurrence.id
                        })
                    ]})
            ]), {largeur: 50}),
            new FormulaireItem('pvldat', {largeur: 30}),
            new FormulaireItem('pvldsc', {largeur: 70, enableCount: true})
        ]);

        vm.vlpMultiOccurrenceOptions = {
            bloc: 'vlp',
            actionsRangeeDroite: new Menu([
                new MenuItemAction(
                    'LBL_ACT_CREATION',
                    'BOUCREVLP',
                    (rowData:any) => ({
                        pvlcleint: rowData.pvlcleint,
                        prucleint: rowData.prucleint
                    }),
                    {
                        icon: 'exit_to_app',
                        successAction:
                            (retour:any) => {
                            $state.go(pb0029Route.NAME, {
                                menuId: $stateParams.menuId,
                                id: retour.vlpcleint
                            });
                        }
                    }
                )
            ]),
            entetesReplacedValues: {
                ent_pbg: vm.valeursEcran.pvllblentpbg
            },
            colonnesVisibles: [
                {nom: 'prucod'      , largeur: 150},
                {nom: 'prudscl01'   , largeur: 350},
                {nom: 'pbgmnttot'   , largeur: 150},
                {nom: 'pbgmntnettot', largeur: 150},
                {nom: 'pvlmntnettot', largeur: 150},
                {nom: 'vlpflgfin'   , largeur: 120}
            ],
            colonnesDisponibles: [
                {nom: 'pbucodres', largeur: 120},
                {nom: 'pbunomres', largeur: 262}
            ],
            colonnesCachees: [
                'pvlcleint',
                'prucleint'
            ]
        };
    }
}
