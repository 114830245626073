import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IInfosMonetaires } from '../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';


interface IPageDB0011 extends IComponentController {
    formulaireRfu: IFormulaire;
    formulaireInfosMonetaire: IFormulaire;
    formulaireStatut: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    rfuMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Db0011Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    gl0003Route: IRoute
) {
    const vm: IPageDB0011 = this;
    const mc0044Route = { NAME: 'secure.accueil' };

    // Information monétaire
    vm.monoOccurrenceInfosMonetaire = {
        blocs: ['rfo'],
        donnees: [
            { nom: 'rfomntrsv', libelle: 'G_LBL_RFOMNTRSV' },
            { nom: 'rfomntutl', libelle: 'G_LBL_RFOMNTUTL' },
            { nom: 'rfomntsld', libelle: 'G_LBL_RFOMNTSLD' }
        ]
    };

    vm.monoOccurrenceOptions = {
        successActionSauvegarde: () => {
            vm.monoOccurrence && vm.monoOccurrence.fetchData();
        },
        fonctions: {
            afficherMenusDansEntete: true,
            boutonDupliquer: false,
            edition: (data: any) => data.vaerfostu !== 'A'
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_ANNULER',
                'BTNANN',
                (data: any) => ({
                    rfocleint: data.rfocleint
                }),
                {
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    messageConfirmation: 'BOUANN_CONFIRM',
                    successAction: () => {
                        vm.monoOccurrence && vm.monoOccurrence.fetchData();
                    },
                    disabled: (data: any) => data.vaerfostu === 'A'
                }
            )
        ]
    };
    vm.formulaireRfu = new Formulaire('G_LBL_RFU', [
        new FormulaireItem('rfonumdoc', { largeur: 25 }),
        new FormulaireItem('rfoann', { readonly: (data) => data.rfonumdoc, largeur: 25, default: ecranDetails.valeurs.rfoann }),
        new FormulaireItem('vaesysnivctr', { readonly: (data) => data.rfonumdoc, largeur: 25 }),
        new FormulaireItem('vaerfotyputl', { readonly: (data) => data.rfonumdoc, largeur: 25 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('intcleint', { readonly: (data) => data.rfonumdoc, largeur: 50 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prucleint02', {
            largeur: 50,
            hidden: (data) => data.vaesysnivctr === '3',
            readonly: (data) => data.rfonumdoc || data.vaesysnivctr === '3',
            resourceParams: (data: any) => ({
                intcleint: data.intcleint
            })
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prscleint', {
            largeur: 50,
            hidden: (data) => data.vaesysnivctr !== '2',
            required: (data) => data.vaesysnivctr === '2',
            readonly: (data) => data.rfonumdoc,
            default: ecranDetails.valeurs.prscleint,
            resourceParams: (data: any) => ({
                prucleint02: data.prucleint02
            })
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prbcleint', {
            largeur: 50,
            hidden: (data) => data.vaesysnivctr !== '2',
            required: (data) => data.vaesysnivctr === '2',
            readonly: (data) => data.rfonumdoc,
            default: ecranDetails.valeurs.prbcleint,
            resourceParams: (data: any) => ({
                prucleint02: data.prucleint02,
                prscleint: data.prscleint
            })
        }),
        new FormulaireItem('imp', {
            hidden: (data) => data.vaesysnivctr !== '3',
            required: (data) => data.vaesysnivctr == '3',
            readonly: (data) => data.rfonumdoc || data.vaesysnivctr !== '3',
            default: ecranDetails.valeurs.imp,
            largeur: 50,
            menu: [
                new MenuItemForage(mc0044Route.NAME, () => ({}), {
                    hidden: (data: any) => ecranDetails.flgcen === 0 || !data.rfocleint
                })
            ]
        }),
        new FormulaireItem('cifcleint', {
            hidden: true,
            readonly: true, default: ecranDetails.valeurs.cifcleint
        }),
        new FormulaireItem('cptcleint', {
            hidden: true,
            readonly: true, default: ecranDetails.valeurs.cptcleint
        }),
        new FormulaireItem('unacleint', {
            hidden: true,
            readonly: true, default: ecranDetails.valeurs.unacleint
        }),
        new FormulaireItem('el1cleint', {
            hidden: true,
            readonly: true, default: ecranDetails.valeurs.el1cleint
        }),
        new FormulaireItem('el2cleint', {
            hidden: true,
            readonly: true, default: ecranDetails.valeurs.el2cleint
        }),
        new FormulaireItem('el3cleint', {
            hidden: true,
            readonly: true, default: ecranDetails.valeurs.el3cleint
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('rfodsc', { readonly: (data) => data.rfonumdoc, largeur: 50, enableCount: true }),
        new FormulaireItem('vaesysnatctb', { readonly: (data) => data.rfonumdoc, largeur: 25 }),
        new FormulaireItem('rfomntrsv', {
            required: true,
            readonly: (data) => data.vaerfostu === 'A', largeur: 25
        }),
        new FormulaireItem('rfocleintref', {
            hidden: true,
            readonly: true, largeur: 50
        }),
        new FormulaireItem('rfodat', {
            hidden: true,
            readonly: true, default: ecranDetails.valeurs.rfodat
        })
    ]);

    vm.formulaireStatut = new Formulaire('LBL_RFU_STATUT', [
        new FormulaireItem('vaerfostu', { readonly: true, largeur: 50, default: 'E' }),
        new FormulaireItem('rfodatann', { readonly: true, hidden: (data) => !(data.rfomntann != null && data.rfomntann > 0), largeur: 50 })]);

    vm.rfuMultiOccurrenceOptions = {
        bloc: 'rfu',
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 63 },
            { nom: 'docaffnumdoc01', largeur: 131 },
            { nom: 'docrefcod02', largeur: 102 },
            { nom: 'docrefnom02', largeur: 144 },
            { nom: 'rfumntutl', largeur: 126 },
            { nom: 'peccod', largeur: 63 },
            { nom: 'datcre', largeur: 100 },
            { nom: 'rfuref', largeur: 178 }
        ],
        colonnesCachees: ['ecjcleint', 'doccleint'],
        navigatePage: gl0003Route.NAME,
        navigateParams: (data: any) => {
            return {
                id: data.doccleint,
                ecjcleint: data.ecjcleint
            };
        }
    };

}
