import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageIn0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0013Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite
                                         ) {
    const vm: IPageIn0013 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tmocleint',          largeur: 30},
            {nom: 'tmocod',             largeur: 40},
            {nom: 'tmodsc',             largeur: 40},
            {nom: 'tmoordaff',          largeur: 40},
            {nom: 'vaetmoimpinv',       largeur: 40},
            {nom: 'vaetmoimpdsp',       largeur: 40},
            {nom: 'tmoflgmajprxmoy',    largeur: 40},

        ],
        formulaire: new Formulaire([
            new FormulaireItem('tmocleint'),
            new FormulaireItem('tmocod'),
            new FormulaireItem('tmodsc'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tmoordaff'),
            new FormulaireItem('vaetmoimpinv'),
            new FormulaireItem('vaetmoimpdsp'),
            new FormulaireItem('tmoflgmajprxmoy'),

        ], {largeurDefaut: 33.33})
    };
}
