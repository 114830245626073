import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageAL0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Al0001Controller(Menu: IMenuClass,
    al2000Route: IRoute,
    mr0014Route: IRoute,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageAL0001 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: al2000Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(al2000Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false,
        },
        colonnesFixesGauche: [
            { nom: 'tdlcod', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'tdldsc', largeur: 200 },
            { nom: 'vaetdltyp_dsc', largeur: 100 },
            { nom: 'ttccod', largeur: 200 },
            { nom: 'ttcdsc', largeur: 266 },
            {
                nom: 'flgvcl', largeur: 100, menu: [
                    new MenuItemForage(
                        'LBL_CUMULATIF',
                        mr0014Route.NAME,
                        (rowData: any) => ({
                            cleintcri: rowData.tdlcleint,
                            stamnetabcri: 'TDL'
                        }),
                        {
                            fonction: 'CUMULATIF',
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'tdlflgabs', largeur: 100 },
            { nom: 'tdlabscom', largeur: 250 },
            { nom: 'tdlflgcen', largeur: 100 },
            { nom: 'tdlflgdec', largeur: 100 },
        ],
        colonnesFixesDroite: [
            { nom: 'tdlflgact', largeur: 74 }
        ],
    };
}
