import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
interface IPageTf0021 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    formulaire: IFormulaire;
    tfcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tfbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Tf0021Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    tf0020Route: IRoute,
    $state: IStateService,
    $stateParams: IStateParamsService
) {
    const vm: IPageTf0021 = this;

    vm.menuBandeau = new Menu([]);
    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_GEN',
                'BOUGEN',
                (data: any) => ({
                    tfaflgoff: data.tfaflgoff
                }),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    messageConfirmation: 'BOUGEN_CONFIRM',
                    successAction: () => {
                        $state.go(tf0020Route.NAME, {
                            ecran: $stateParams.type === 1 ? '00-2' : '01-2'
                        });
                    }
                }
            )
        ]
    };
    vm.formulaire = new Formulaire(
        'LBL_BLC',
        [
            new FormulaireItem('tfhann', { largeur: 33 }),
            new FormulaireItem('efacleint', { largeur: 50 }),
            new FormulaireItem('fpncleint', { largeur: 33 }),
            new FormulaireItem('femcod', { largeur: 50, readonly: true }),
            new FormulaireItem('tfamnttfe', { largeur: 33 }),
            new FormulaireItem('tfacotvol', { largeur: 33 }),
            new FormulaireItem('total', { largeur: 33 }),
            new FormulaireItem('tfamnttferet', { largeur: 33 }),
            new FormulaireItem('flgparam_fe', { largeur: 33 })
        ],
        { largeurDefaut: 30 }
    );
    vm.formulaireaju = new Formulaire('', [
        new FormulaireItem('tfaflgoff', { largeur: 50 }),
        new FormulaireItem('tfaflguti', { largeur: 50 }),
        new FormulaireItem('tfaflgces', { largeur: 50 }),
        new FormulaireItem('tfaflgtrf', { largeur: 50 })
    ]);
    vm.tfcMultiOccurrenceOptions = {
        bloc: 'tfc',
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 84 },
            { nom: 'codref', largeur: 100 },
            { nom: 'dscref', largeur: 280 },
            { nom: 'cotcod', largeur: 100 },
            { nom: 'cotdsc', largeur: 280 },
            { nom: 'tfcval', largeur: 126 },
            { nom: 'tfcvalaju', largeur: 126 }
        ]
    };
    vm.tfbMultiOccurrenceOptions = {
        bloc: 'tfb',
        colonnesVisibles: [
            { nom: 'tfkcod', largeur: 140 },
            { nom: 'tfkdsc', largeur: 280 },
            { nom: 'vaefesudm', largeur: 171 },
            { nom: 'tfbval', largeur: 131 },
            { nom: 'tfbvalaju', largeur: 131 },
            { nom: 'total', largeur: 131 }
        ],
        multiOccurrenceOptionsEnfant: vm.tfcMultiOccurrenceOptions
    };
}
