import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCS0006 extends IComponentController {
    acccleint: string;
    valeursEcran: any;
    formulaireVic: IFormulaire;
    formulaireSta: IFormulaire;
    formulaireAut: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    vblMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menus: {[key: string]: Array<IMenuItem>},
}

/* @ngInject */
export default function Cs0006Controller(cs0001Route: IRoute,
                                         cs0007Route: IRoute,
                                         rr0004Route: IRoute,
                                         rr0006Route: IRoute,
                                         $stateParams: any,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCS0006 = this;

    vm.menus = {
        aut__dcsnumdoc: [
            new MenuItemForage(
                'BTN_FORAGE_CSST',
                cs0007Route.NAME,
                (data: any) => ({
                    id: data.aut__trncleint_dcs,
                    viccleint: $stateParams.id
                }),
                {
                    hidden: () => !$stateParams.id,
                    validation(data: any) {
                        if (!data.aut__trncleint_dcs && vm.valeursEcran.trnflgstaref_acc === 0) {
                            return 'MSG_TRNFLGSTAREF_ACC';
                        }
                    }
                }
            )
        ],
        aut__nbrdia: [
            new MenuItemForage(
                'BTN_FORAGE_DIAGNOSTICS',
                cs0001Route.NAME,
                (data: any) => ({
                    demcleint: data.demcleint,
                    acccleint: data.acccleint
                }),
                {hidden: (data: any) => !data.demcleint && !data.acccleint}
            )
        ]
    };

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireVic = new Formulaire('LBL_BLC_VIC', [
            new FormulaireItem('vicdatdec', {required: true}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('demcleint', {required: true, longueurFixe: 7}),
            new FormulaireItem('lemcleint', {
                required: true,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_LEM',
                        rr0004Route.NAME,
                        (data: any) => ({
                            id: data.$valeurs.trncleint_lem
                        }),
                        { hidden: (data: any) => !data.lemcleint }
                    )
                ],
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aftcleint', {
                resourceParams: {acccleint: $stateParams.acccleint},
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_AFT',
                        (vm.valeursEcran.trncleint_lem === vm.valeursEcran.trncleint_aft) ?
                            rr0004Route.NAME :
                            rr0006Route.NAME,
                        (data: any) => ({
                            id: data.$valeurs.trncleint_aft
                        }),
                        { hidden: (data: any) => !data.aftcleint }
                    )
                ],
            }),
            new FormulaireItem('poscleintsup'),
            new FormulaireItemSautDeLigne(),
            
            new FormulaireItem('unocleintrh'),
            new FormulaireItem('litcleint'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItemDivider('LBL_DIV_DESCR'),
            new FormulaireItem('vicdscact'),
            new FormulaireItem('vacvictacacc'),
            new FormulaireItem('vicpresoi'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('viccom')
        ]);

        vm.formulaireSta = new Formulaire('LBL_DIV_STAT', [
            new FormulaireItem('vacvictypacc'),
            new FormulaireItem('vacvicfretac')
        ]);
        
        vm.vblMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'vbldsc', largeur: 289},
                {nom: 'vacvblnatble_cle', largeur: 95},
                {nom: 'vacvblnatble_dsc', largeur: 286},
                {nom: 'vacvblpartou_cle', largeur: 140},
                {nom: 'vacvblpartou_dsc', largeur: 286},
                {nom: 'vblpre', largeur: 262}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('vbldsc', {
                    largeur: 40
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vacvblnatble', {
                    required: true
                }),
                new FormulaireItem('vacvblpartou', {
                    largeur: 40,
                    required: true
                }),
                new FormulaireItem('vblpre', {
                    largeur: 40
                })
            ], {
                largeurDefaut: 20
            })
        }
    }
}
