import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCp2003 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp2003Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    mc0046Route: IRoute) {
    const vm: IPageCp2003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            supprime: false,
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('zcrdsc', { required: true }),
            new FormulaireItem('zcrflgstu', { default: 1 })
        ]),
        colonnesVisibles: [
            { nom: 'zcrdsc', largeur: 262 },
            { nom: 'zcrflgstu', largeur: 70 },
        ],
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'G_LBL_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => {
                    return {
                        vaeusient: 'ZCR',
                        uvicleintref: data.zcrcleint
                    };
                },
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ])
    };
}
