import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCb0030 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
    detMultiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0030Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageCb0030 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.detMultiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            effaceCriteres: true
        },
        criteresSuggeres:new Formulaire([
            new FormulaireItem('pecanncri', { 
                default: $stateParams.pecann,
                disabled: true 
            }),
            new FormulaireItem('cifcleintcri', { 
                default: $stateParams.cifcleint,
                disabled: true
            }),
            new FormulaireItem('unacleintcri', { 
                default: $stateParams.unacleint,
                disabled: true
            }),
            new FormulaireItem('vaesysnatctbcri', { 
                default: $stateParams.vaesysnatctb,
                disabled: true
            }),
            new FormulaireItem('tymcleintcri', {
                default: $stateParams.tymcleint,
                disabled: true,
                hidden: !$stateParams.tymcleint
            })
        ]),
        colonnesVisibles: [
            {nom: 'cifcod', largeur: 80},
            {nom: 'cifdscabr', largeur: 200},
            {nom: 'cptcod', largeur: 100},
            {nom: 'cptdscabr', largeur: 200},
            {nom: 'unacod', largeur: 100},
            {nom: 'unadscabr', largeur: 200},
            {nom: 'ctvmntbdgini', largeur: 120, hidden: () => !(vm.valeursEcran.flgmntbdgini)},
            {nom: 'ctvmntbdg', largeur: 120, hidden: () => !(vm.valeursEcran.flgmntbdg)},
            {nom: 'ctvmnteng', largeur: 120, hidden: () => !(vm.valeursEcran.flgmnteng)},
            {nom: 'ctvmntree', largeur: 120, hidden: () => !(vm.valeursEcran.flgmntree)},
            {nom: 'ctvmntrevapp', largeur: 120, hidden: () => !(vm.valeursEcran.flgmntrevapp)},
            {nom: 'ctvmntreveng', largeur: 120, hidden: () => !(vm.valeursEcran.flgmntreveng)}
        ]
    };
}
