import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0161 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0161Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMr0161 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'frevalusr', largeur: 120 },
            { nom: 'frevalora', largeur: 120 },
            { nom: 'fredsc', largeur: 120 },
            { nom: 'freaid', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('frevalusr', {largeur: 50}),
            new FormulaireItem('frevalora', {largeur: 50}),
            new FormulaireItem('fredsc', {largeur: 50}),
            new FormulaireItem('freaid', {largeur: 50})
        ]
    )
    };
}
