import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePB0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0013Controller(Menu: IMenuClass,
                                        pb0014Route: IRoute,
                                        MenuItemForage: IMenuItemForageClass
) {
    const vm: IPagePB0013 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: pb0014Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(pb0014Route.NAME)
        ]),
        colonnesFixesGauche: [
            {nom: 'cybann', largeur: 150},
            {nom: 'pbscod', largeur: 150},
            {nom: 'pbsdsc', largeur: 300}
        ],
        colonnesVisibles: [            
            {nom: 'peccod', largeur: 150},
            {nom: 'cobcod', largeur: 150},
            {nom: 'cobdsc', largeur: 300},
            {nom: 'cybcom', largeur: 300}
        ]
    };
}
