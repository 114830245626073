import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageRr0120 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0120Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0120 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'urgcod', largeur: 90},
            {nom: 'strcod', largeur: 90},
            {nom: 'aftcod', largeur: 105},
            {nom: 'aftcod_sus', largeur: 105},
            {nom: 'aftdsc_sus', largeur: 262},
            {nom: 'qtrcod', largeur: 90},
            {nom: 'qtrdsc', largeur: 262},
            {nom: 'urgdsc', largeur: 262},
            {nom: 'strdsc', largeur: 262},
            {nom: 'aftdsc', largeur: 262},
            {nom: 'aftdatdeb', largeur: 100},
            {nom: 'aftdatfin', largeur: 100},
            {nom: 'temcod', largeur: 90},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'tafcod', largeur: 105},
            {nom: 'tafdsc', largeur: 262}
        ],
        colonnesFixesDroite: [
            {nom: 'susflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('aftcleint'),
            new FormulaireItem('aftcleintref', {
                resourceParams: (data: any) => {
                    return {
                        demcleint: data.demcleint,
                        aftcleint: data.aftcleint,
                        aftdatdeb: data.aftdatdeb
                    };
                }
            }),
            new FormulaireItem('qtrcleint', {
                resourceParams: (data: any) => {
                    return {
                        aftdatdeb: data.aftdatdeb,
                        aftdatfin: data.aftdatfin
                    };
                }
            }),
            new FormulaireItem('susflgact')
        ]),
    };
}
