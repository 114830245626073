import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCP0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0031Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0031 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('tydcleint', {
                required: false
            }),
            new FormulaireItem('tyacleint', {
            }),
            new FormulaireItem('refcleint', {
                required: false
            }),
            new FormulaireItem('peccoddeb', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('peccodfin', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('reqret', {
                required: true
            }),
            new FormulaireItem('datdeb', {
            }),
            new FormulaireItem('datfin', {
            }),
            new FormulaireItem('cedret', {
                required: true
            }),
            new FormulaireItem('vecreqcatcri', {
            }),
            new FormulaireItem('mntplsgrd', {
            })
        ]),
        colonnesFixesGauche: [
            { nom: 'reqnumdoc', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 200 },
            { nom: 'refcod', largeur: 200 },
            { nom: 'refnomabr', largeur: 200 },
            { nom: 'tyacod', largeur: 200 },
            { nom: 'peccod', largeur: 200 },
            { nom: 'reqdat', largeur: 200 },
            { nom: 'reqmnt', largeur: 200 },
            { nom: 'vaesysdevcod', largeur: 200 },
            { nom: 'reqdsc', largeur: 200 },
            { nom: 'intcod', largeur: 200 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'lcpcod', largeur: 200 },
            { nom: 'reqnumref', largeur: 200 },
            { nom: 'retenue_req', largeur: 200 },
            { nom: 'relance_req', largeur: 200 },
            { nom: 'dccmnt', largeur: 200 },
            { nom: 'dccmntpay', largeur: 200 },
            { nom: 'retenue_ced', largeur: 200 },
            { nom: 'relance_ced', largeur: 200 },
            { nom: 'vecreqcat', largeur: 200 },
            { nom: 'vecdsc', largeur: 200 }
        ]
    };
}
