import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCR1005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr1005Controller($stateParams: any,
    cr0013Route: IRoute,
    cr0007Route: IRoute,
    cr0024Route: IRoute,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCR1005 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'faccleint', valeur: $stateParams.faccleint, visible: false }
        ],
        fonctions:{
            rechercheCollapsable: false
        },        
        colonnesVisibles: [
            {
                nom: 'docaffnumdoc01', largeur: 200, menu: [
                    new MenuItemForage(
                        '',
                        (data: any) => getForage(data),
                        (data: any) => ({
                            id: data.doccleint
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'peccod', largeur: 110 },
            { nom: 'loecod', largeur: 110 },
            { nom: 'tydcod', largeur: 110 },
            { nom: 'encdat', largeur: 110 },
            { nom: 'enfmnt', largeur: 160 },
            { nom: 'enfmntesc', largeur: 160 },
            { nom: 'enfmntfrs', largeur: 160 },
            { nom: 'encmnt', largeur: 160 },
            { nom: 'vaesysdevcod', largeur: 110 },
            { nom: 'etddsc', largeur: 160 },
            { nom: 'encnumref', largeur: 160 },
            { nom: 'intcod', largeur: 110 },
            { nom: 'intnom', largeur: 160 },
            { nom: 'moecod', largeur: 140 },
            { nom: 'moedsc', largeur: 160 }
        ],
        colonnesCachees: [
            'tydcleint'
        ]
    };
    function getForage(data: any): string {
        if (data.tydcleint === 17) {
            return cr0024Route.NAME;
        }
        else if (data.tydcleint === 18) {
            return cr0007Route.NAME;
        }
        else {
            return cr0013Route.NAME;
        }
    }

}
