import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageDO0071 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0071Controller(Menu: IMenuClass) {
    const vm: IPageDO0071 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rglcod', largeur: 90},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'vaergltyp_dsc', largeur: 168},
            {nom: 'rgldscabr', largeur: 262}
        ]
    };
}
