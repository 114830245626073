import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMR0207 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0207Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         mr0119Route: IRoute) {
    const vm: IPageMR0207 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0119Route.NAME,
        navigateParams: (rowData: any) => ({ id: rowData.tagcleint }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datvig'),
            new FormulaireItem('colcleintcri', {
                disabled: (data: any) => {
                    return !data.datvig
                },
                resourceParams: (data: any) => {
                    return {
                        datvig: data.datvig
                    };
                }
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesCachees: ['tagcleint'],
        colonnesFixesGauche: [
            {nom: 'tagcod', largeur: 90},
            {nom: 'tagdscabr', largeur: 210},
        ],
        colonnesVisibles: [
            {nom: 'tagdatdeb', largeur: 100},
            {nom: 'tagdatfin', largeur: 100},
            {nom: 'urgcod', largeur: 90},
            {nom: 'urgdsc', largeur: 263},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 263},
            {nom: 'tgpdatvig', largeur: 100},
            {nom: 'tgpflgact', largeur: 65},
            {nom: 'tgpfct', largeur: 90},
            {nom: 'tgpmnt', largeur: 126},
            {nom: 'unccodmnt', largeur: 90},
            {nom: 'tgpprctaudem', largeur: 115},
            {nom: 'tgpmntmax', largeur: 126},
            {nom: 'bqecod', largeur: 90},
            {nom: 'bqedsc', largeur: 263},
            {nom: 'bqecodtauqte', largeur: 90},
            {nom: 'bqedsctauqte', largeur: 263},
            {nom: 'bqecodtaumnt', largeur: 90},
            {nom: 'bqedsctaumnt', largeur: 263},
            {nom: 'vaesyssldbor_desc', largeur: 168},
            {nom: 'tgpflgborinc', largeur: 78},
            {nom: 'tgpflgboraff', largeur: 110},
            {nom: 'tagdsc', largeur: 239},
            {nom: 'mtgnummth', largeur: 90},
            {nom: 'mtgdsc', largeur: 263},
            {nom: 'unccod', largeur: 90},
            {nom: 'unccodsal', largeur: 90},
            {nom: 'vaetagtyp_desc', largeur: 92},
            {nom: 'colcod', largeur: 115},
            {nom: 'coldsc', largeur: 263},
            {nom: 'vaetagsalsup_desc', largeur: 158},
            {nom: 'colcodsalbas', largeur: 90},
            {nom: 'coldscsalbas', largeur: 263},
            {nom: 'tagprical', largeur: 90},
            {nom: 'tagcodava', largeur: 90},
            {nom: 'tagdscava', largeur: 263},
            {nom: 'tagindimp', largeur: 91},
            {nom: 'tagseqaff', largeur: 100},
            {nom: 'carcod', largeur: 90},
            {nom: 'cardsc', largeur: 263},
            {nom: 'tagcodref', largeur: 90},
            {nom: 'tagdscref', largeur: 263},
            {nom: 'vactagcatcod', largeur: 100},
            {nom: 'vactagcatdsc', largeur: 263},
            {nom: 'tgncod', largeur: 100},
            {nom: 'tgndsc', largeur: 263},
            {nom: 'tagflgvcl', largeur: 115}
        ]
    };
}
