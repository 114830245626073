import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

import { IController } from 'angular';

interface IPageAc0050 extends IController {
    multiOccurrenceDetOptions: IMultiOccurrenceOptions;
    multiOccurrenceOptions: IMultiOccurrenceOptions;    
}

/* @ngInject */
export default function Ac0050Controller( FormulaireItem: IFormulaireItemClass,
                                          Formulaire: IFormulaireClass,
                                          FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass, ) {

    const vm: IPageAc0050 = this;

vm.multiOccurrenceDetOptions = {
    bloc: 'det',
    fonctions: {
        rechercheCollapsable: false
    },
    colonnesFixesGauche: [
        {nom: 'docaffnumdoc01',largeur: 150},
        {nom: 'tydcod',largeur: 150}
    ],
    colonnesVisibles: [
        { nom: 'tyddsc', largeur: 200 },
        { nom: 'peccod', largeur: 140 },
        { nom: 'docaffdat01', largeur: 140 },
        { nom: 'etcnumdoc', largeur: 180 },
        { nom: 'docaffmnt01', largeur: 180 }
    ]
};

    vm.multiOccurrenceOptions = {        
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([            
            new FormulaireItem('nibcod1cri',  {required: (data: any) => data.nibcod1cri || data.nibcod2cri}),
            new FormulaireItem('nibcod2cri',  {required: (data: any) => data.nibcod1cri || data.nibcod2cri}),    
            new FormulaireItemSautDeLigne(),        
            new FormulaireItem('nibdatdebcri'),
            new FormulaireItem('nibdatfincri'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                },
                required: false
            }),            
            new FormulaireItem('refstatutcri', {
                largeur: 33,
                default: 2
            })
        ]),        
        colonnesFixesGauche: [
            {nom: 'nibcod',largeur: 100},
            {nom: 'nibnom',largeur: 210}
        ],
        colonnesVisibles: [
            {nom: 'nibdatdeb',largeur: 100},
            {nom: 'nibdatfin',largeur: 100},
            {nom: 'foucod',largeur: 110},
            {nom: 'founomabr',largeur: 210},
            {nom: 'cfndatdeb',largeur: 150},
            {nom: 'cfndatfin',largeur: 150},
            {nom: 'datderutl',largeur: 150},
            {nom: 'nbdoc',largeur: 110},
            {nom: 'mntdocsum',largeur: 140}],
            
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceDetOptions
    };

}
