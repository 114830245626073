import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0259 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0259Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0259 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            selectionCycle: true
        },
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('ttccleint', {required: false}),
            new FormulaireItem('vaetytreg', {required: false}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('grocleint', {required: false}),
            new FormulaireItem('urgcleint', {required: false}),
            new FormulaireItem('strcleint', {required: false}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('usrcleint', {required: false})
        ],
            {largeurDefaut: 33.33}
        ),
        // Colonnes fixe à gauche
        colonnesFixesGauche: [
            {nom: 'trnnumdoc', largeur: 110}
        ],
        colonnesVisibles: [
            {nom: 'ttccod', largeur: 110},
            {nom: 'ttcdsc', largeur: 230},
            {nom: 'ctadsc', largeur: 190},
            {nom: 'indecr', largeur: 100},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 260},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodsc', largeur: 260},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 260},
            {nom: 'strcod', largeur: 115},
            {nom: 'strdsc', largeur: 260}
        ]
    };
}

