import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMc0037 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0037Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         mc2011Route: IRoute) {
    const vm: IPageMc0037 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc2011Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc2011Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'grucod', largeur: 120},
            {nom: 'grudsc', largeur: 300},
            {nom: 'gruflgclo', largeur: 120}
        ]
    };
}
