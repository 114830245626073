import { IComponentController } from "angular";
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
//import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
//import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageDb0030 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMonoOccurrence;
    pruMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pruMultiOccurrence: IMultiOccurrence;
    prsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prsMultiOccurrence: IMultiOccurrence;
    pccMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pccMultiOccurrence: IMultiOccurrence;
    pbcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pbcMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Db0030Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Menu: IMenuClass
                                        ) {
    const vm: IPageDb0030 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('peccleintdebcri', { largeur: 16.5,
            required: true,
            nombreLibellesDetailsCacher: 1
        }),
        new FormulaireItem('peccleintfincri', { largeur: 16.5,
            required: true,
            nombreLibellesDetailsCacher: 1
        }),
        new FormulaireItem('vaeprutypbdgcri', { largeur: 22,
            default: 'P'
        }),
        new FormulaireItem('pruflgstucri', { largeur: 22,
            default: 2
        }),
        new FormulaireItem('flgorgacccri', { largeur: 22,
            default: 2
        }),

        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prucleintcri'),
        new FormulaireItem('cptcleintcri'),
        new FormulaireItem('el1cleintcri'),

        new FormulaireItem('prscleintcri'),
        new FormulaireItem('unacleintcri'),
        new FormulaireItem('cifcleintcri', {required: true}),


        new FormulaireItem('prjcleintcri'),
        new FormulaireItem('orgcleintcri'),
        new FormulaireItem('vectypappcri'),

        new FormulaireItem('intcleintcri'),
        new FormulaireItem('grpcleintcri'),
        new FormulaireItem('blocleintcri')
    ], { largeurDefaut: 30 });

    vm.multiOccurrenceOptions = {
        srccod: "db0031",
        isTab: true,
        autoFetch: false,
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true
        },
        criteresSuggeres: criteresSuggeres,
        colonnesVisibles: [
            { nom: 'prucleint', largeur: 17 }
        ]
    };

    vm.pruMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true
        },
        isTab: true,
        tabSelected: true,
        criteresSuggeres,
        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },
        colonnesVisibles: [
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 200},
            {nom: 'prucod', largeur: 100},
            {nom: 'prudscl01', largeur: 200},
            {nom: 'solde_deb_rev', largeur: 120},
            {nom: 'mnt_bdg_rev', largeur: 120},
            {nom: 'mnt_eng_rev', largeur: 120},
            {nom: 'mnt_reel_rev', largeur: 120},
            {nom: 'solde_fin_rev', largeur: 120},
            {nom: 'solde_deb_dep', largeur: 120},
            {nom: 'mnt_bdg_dep', largeur: 120},
            {nom: 'mnt_eng_dep', largeur: 120},
            {nom: 'mnt_reel_dep', largeur: 120},
            {nom: 'solde_fin_dep', largeur: 120}
        ]
    };

    vm.prsMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true
        },
        isTab: true,
        criteresSuggeres,
        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },
        colonnesVisibles: [
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 200},
            {nom: 'prucod', largeur: 100},
            {nom: 'prudscl01', largeur: 200},
            {nom: 'prscod', largeur: 200},
            {nom: 'prsdscl01', largeur: 300},
            {nom: 'solde_deb_rev', largeur: 120},
            {nom: 'mnt_bdg_rev', largeur: 120},
            {nom: 'mnt_eng_rev', largeur: 120},
            {nom: 'mnt_reel_rev', largeur: 120},
            {nom: 'solde_fin_rev', largeur: 120},
            {nom: 'solde_deb_dep', largeur: 120},
            {nom: 'mnt_bdg_dep', largeur: 120},
            {nom: 'mnt_eng_dep', largeur: 120},
            {nom: 'mnt_reel_dep', largeur: 120},
            {nom: 'solde_fin_dep', largeur: 120}
        ]
    };

    vm.pccMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true
        },
        isTab: true,
        criteresSuggeres,
        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },
        colonnesVisibles: [
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 200},
            {nom: 'prucod', largeur: 100},
            {nom: 'prudscl01', largeur: 200},
            {nom: 'prscod', largeur: 200},
            {nom: 'prsdscl01', largeur: 300},
            {nom: 'blocod', largeur: 200},
            {nom: 'blodscabr', largeur: 300},
            {nom: 'solde_deb_rev', largeur: 120},
            {nom: 'mnt_bdg_rev', largeur: 120},
            {nom: 'mnt_eng_rev', largeur: 120},
            {nom: 'mnt_reel_rev', largeur: 120},
            {nom: 'solde_fin_rev', largeur: 120},
            {nom: 'solde_deb_dep', largeur: 120},
            {nom: 'mnt_bdg_dep', largeur: 120},
            {nom: 'mnt_eng_dep', largeur: 120},
            {nom: 'mnt_reel_dep', largeur: 120},
            {nom: 'solde_fin_dep', largeur: 120}
        ]
    };

    vm.pbcMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true
        },
        isTab: true,
        criteresSuggeres,
        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },
        colonnesVisibles: [
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 200},
            {nom: 'prucod', largeur: 100},
            {nom: 'prudscl01', largeur: 200},
            {nom: 'prscod', largeur: 200},
            {nom: 'prsdscl01', largeur: 300},
            {nom: 'blocod', largeur: 200},
            {nom: 'blodscabr', largeur: 300},
            {nom: 'cptcod', largeur: 200},
            {nom: 'cptdscabr', largeur: 300},
            {nom: 'solde_deb_rev', largeur: 120},
            {nom: 'mnt_bdg_rev', largeur: 120},
            {nom: 'mnt_eng_rev', largeur: 120},
            {nom: 'mnt_reel_rev', largeur: 120},
            {nom: 'solde_fin_rev', largeur: 120},
            {nom: 'solde_deb_dep', largeur: 120},
            {nom: 'mnt_bdg_dep', largeur: 120},
            {nom: 'mnt_eng_dep', largeur: 120},
            {nom: 'mnt_reel_dep', largeur: 120},
            {nom: 'solde_fin_dep', largeur: 120}
        ]
    };
}
