import { IComponentController } from 'angular';
import { IMonoOccurrence } from '../../../../core/services/mono-occurrence.service';
import { IDataType } from '../../../../core/services/data-types/data-type.service';
import { IDialog } from '../../../../core/services/dialog.service';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';

interface IComposantButtonHistoriqueColonne extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    col: string;
    readonly: boolean;
    data: any;
    occurrence: IMonoOccurrence;
    dataType: IDataType;
    resourceParams: any;
    reloadData: any;
    openHistoriqueColonne(event: MouseEvent): void;
}

/* @ngInject */
export default function ButtonHistoriqueColonneController(DialogHistoriqueColonne: IDialog) {
    const vm: IComposantButtonHistoriqueColonne = this;

    vm.openHistoriqueColonne = function openHistoriqueColonne(event: MouseEvent) {
        DialogHistoriqueColonne.show({
            targetEvent: event,
            locals: {
                srccod: vm.occurrence.srccod,
                cleintref: vm.data[vm.occurrence.cleint],
                col: vm.col,
                dataType: vm.dataType,
                libelle: (vm.occurrence.libelles as any)[vm.col.toUpperCase()],
                readonly: vm.readonly,
                ecranContext: vm.ecranContextCtrl,
                resourceParams: vm.resourceParams instanceof Function ? vm.resourceParams(vm.data) : vm.resourceParams,
                reloadData: vm?.reloadData || false
            }
        }).finally(() => vm.occurrence.softUpdateData());
    }
}
