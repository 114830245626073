import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0133{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0133Controller(mr0134Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0133 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0134Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.rcecleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0134Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 100},
            {nom: 'rcedsc', largeur: 262},
            {nom: 'rcedscabr', largeur: 262},
            {nom: 'farcod', largeur: 90},
            {nom: 'fardsc', largeur: 262},
            {nom: 'vaercetyp_dsc', largeur: 105},
            {nom: 'rcedatdeb', largeur: 100},
            {nom: 'rcedatfin', largeur: 100},
            {nom: 'rcecodref', largeur: 90},
            {nom: 'rcedscabrref', largeur: 262},
            {nom: 'clrnum', largeur: 105},
            {nom: 'clrdsc', largeur: 140},
            {nom: 'vacrcecat_val', largeur: 100},
            {nom: 'vacrcecat_dsc', largeur: 262},
            {nom: 'rceprical', largeur: 90},
            {nom: 'vaercecalant_dsc', largeur: 184},
            {nom: 'vaercedatpar_dsc', largeur: 200},
            {nom: 'rceflgimp', largeur: 85},
            {nom: 'rceflgcrtimp', largeur: 76},
            {nom: 'vaerceeffcal_dsc', largeur: 194},
            {nom: 'vaercechgper_dsc', largeur: 194},
            {nom: 'vaerceexctpf_dsc', largeur: 194},
            {nom: 'carcod', largeur: 90},
            {nom: 'cardsc', largeur: 262},
            {nom: 'rcedoc', largeur: 375},
            {nom: 'datcre', largeur: 100}
        ],
        colonnesCachees: [
            'rcecleint'
        ]
    };
}
