import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireTemplateSlotClass } from "../../../../../core/services/formulaire/formulaire-template-slot.service";
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMonoOccurrenceOptions, IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCC0011 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireCip: IFormulaire;    
    ciuMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ciuMultiOccurrence: IMultiOccurrence;
    cirMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cirMultiOccurrence: IMultiOccurrence;
    cicMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cicMultiOccurrence: IMultiOccurrence;
    cidMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cidMultiOccurrence: IMultiOccurrence; 
}

/* @ngInject */
export default function CC0011Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,    
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,    
    ecranDetails: IEcranDetailsResourcesEntite,    
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    mr0176Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
) {
    const vm: IPageCC0011 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.$onInit = $onInit;

    function $onInit() {


        const forage = {
            FORVNT: new MenuItemEcran(
                'LBL_IMPUTATION',
                mr0176Route.NAME,
                (rowData: any) => ({
                    stamnetab: 'CIP',
                    ovdcleintref: rowData.cipcleint
                }),
                {
                    icon: 'launch',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'FORVNT'
                }
            )
        }

        vm.monoOccurrenceOptions = {
                id: vm.valeursEcran.cipcleint,   
                fonctions: {
                boutonDupliquer: false                               
            },
            formulaire: new Formulaire('LBL_TITRE', [
                new FormulaireItem('nircleintapp', { largeur: 40, menu: [forage.FORVNT]  }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cipadrcou', { required: true, largeur: 40, historiqueColonne: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('poscleint', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireGroupeAccordeon('LBL_BLC_AFF', new Formulaire([
                    new FormulaireItem('nircleintaff'),
                    new FormulaireItem('qstcleint')
                ]))
            ], { largeurDefaut: 100 })
        };
        // Formulaire Principale
        vm.formulaireCip = new Formulaire('', [
            new FormulaireGroupeAccordeon('LBL_BLC_ATR', new Formulaire([
                new FormulaireItem('tarcleint',{ largeur: 33 }),
                new FormulaireItem('cipnbrjrsref',{ largeur: 33 }),  
                new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('cipequhrecrs',{ largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cipnbrmaxcrstrm',{ largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cipnbrmaxcrsann',{ largeur: 100 }),
                ]),{largeur: 33}),
                new FormulaireGroupe('LBL_BLC_CRE_EMP', new Formulaire([
                    new FormulaireItem('ttccleintepl',{ largeur: 100 }),
                    new FormulaireItem('ttccleintdem',{ largeur: 100 }),
                    new FormulaireItem('ttccleintlem',{ largeur: 100 }),
                    new FormulaireItem('ttccleinttsa',{ largeur: 100 }),
                    new FormulaireItem('ttccleintaft',{ largeur: 100 }),
                ]),{largeur: 48}),
                new FormulaireGroupe('LBL_BLC_ETA', new Formulaire([
                    new FormulaireItem('eclcleintconatr',{ largeur: 100 }),
                    new FormulaireItem('eclcleintprecon',{ largeur: 100 }),
                    new FormulaireItem('eclcleintconrem',{ largeur: 100 }),
                ]),{largeur: 48}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('icocleint'),
            ])),
            new FormulaireGroupeAccordeon('LBL_BLC_ANC', new Formulaire([
                new FormulaireItem('bqecleintanc'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cipjrsdatanc'),
                new FormulaireItem('cipflgimpret'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ttccleintfinaft'),
                new FormulaireItem('cipflgdatancsec'),
            ])),
            new FormulaireGroupeAccordeon('LBL_BLC_EXI', new Formulaire([
                new FormulaireItem('cipdip',{ largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cipexp',{ largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cipordprf',{ largeur: 100 }),
            ])),
            new FormulaireGroupeAccordeon('LBL_BLC_CIU', new Formulaire([                    
            ])),
            new FormulaireTemplateSlot('blocCiu'),
            new FormulaireGroupeAccordeon('LBL_BLC_CIR', new Formulaire([                    
            ])),
            new FormulaireTemplateSlot('blocCir'),
        ], { largeurDefaut: 25 });


        // Formulaire 
        vm.formulaireTrt = new Formulaire('', [
            new FormulaireGroupeAccordeon('LBL_BLC_TRM', new Formulaire([   
                new FormulaireItem('cipdattrmhiv'),
                new FormulaireItem('cipdattrmete'),
                new FormulaireItem('cipdattrmaut'),                 
            ])),
            new FormulaireGroupeAccordeon('LBL_BLC_CIC', new Formulaire([   
                new FormulaireItem('cipflgcom')              
            ])),
            new FormulaireTemplateSlot('blocCic'),
            new FormulaireGroupeAccordeon('LBL_BLC_CID', new Formulaire([   
                new FormulaireItem('cipflgdom')              
            ])),
            new FormulaireTemplateSlot('blocCid'),
        ], { largeurDefaut: 25 });


        // // Onglet 5 : U.reg./Titre emploi
        vm.ciuMultiOccurrenceOptions = {
            bloc: 'ciu',
            fonctions: {
                pagination: false
            },
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint'),
                new FormulaireItem('ciuflgurgdef'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('temcleint'),
                new FormulaireItem('ciuflgtemdef'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('strcleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tlecleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tafcleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('geccleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('pfrcleint'),
                new FormulaireItemSautDeLigne(),           
                new FormulaireItem('ciuflgrecquaper'),
                new FormulaireItem('ciuflgvaldatancsec')
            ], { largeurDefaut: 50 }),
            colonnesFixesGauche: [
                { nom: 'urgcod', largeur: 140 }
            ],
            colonnesVisibles: [
                { nom: 'urgdscabr', largeur: 250 },
                { nom: 'ciuflgurgdef', largeur: 50 },
                { nom: 'temcod', largeur: 120 },
                { nom: 'temdscabr', largeur: 250 },
                { nom: 'ciuflgtemdef', largeur: 50 },
                { nom: 'strcod', largeur: 140 },
                { nom: 'strdsc', largeur: 250 },
                { nom: 'tlecod', largeur: 140 },
                { nom: 'tledsc', largeur: 250 },
                { nom: 'tafcod', largeur: 140 },
                { nom: 'tafdsc', largeur: 250 },
                { nom: 'geccod', largeur: 140 },
                { nom: 'gecdsc', largeur: 250 },
                { nom: 'pfrdsc', largeur: 300 },
                { nom: 'ciuflgrecquaper', largeur: 80 },
                { nom: 'ciuflgvaldatancsec', largeur: 80 }
            ]
        };

        vm.cirMultiOccurrenceOptions = {
            bloc: 'cir',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('rolcleint')
            ]),
            colonnesVisibles: [
                { nom: 'rolcod', largeur: 50 },
                { nom: 'roldscabr', largeur: 50 },
            ]
        };

        vm.cicMultiOccurrenceOptions = {
            bloc: 'cic',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('tyccleint')
            ]),
            colonnesVisibles: [
                { nom: 'tyccod', largeur: 40 },
                { nom: 'tycdsc', largeur: 100 }
            ]
        };

        vm.cidMultiOccurrenceOptions = {
            bloc: 'cid',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('dmncleint')
            ]),
            colonnesVisibles: [
                { nom: 'dmncod', largeur: 40 },
                { nom: 'dmndsc', largeur: 100 }
            ]
        };
    }

}
