import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageMc2003 extends IComponentController {
    vaestgtyp: string;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTmp: IFormulaire;
    sgpMultiOccurrenceOptions: IMultiOccurrenceOptions;    
}

/* @ngInject */
export default function Mc2003Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IPageMc2003,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2003 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        
        vm.formulaireTmp = new Formulaire('LBL_BLC_TMP', [
            new FormulaireItem('impdeb'),
            new FormulaireItem('impfin')
        ]);

        vm.sgpMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'impdebsgp'        , largeur: 250},
                {nom: 'impfinsgp'        , largeur: 250}
            ],
            colonnesCachees:['vaestgtyp'],
            formulaire: new Formulaire([
                new FormulaireItem('impdebsgp'),
                new FormulaireItem('impfinsgp'),
                new FormulaireItem('vaesgptyp', {hidden: true, default: $stateParams.vaestgtyp})
            ], {largeurDefaut: 50})
        };
        
    }
}
