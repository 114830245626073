import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageTF0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    ctrMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0002Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,) {
    const vm: IPageTF0002 = this;

    vm.ctrMultiOccurrenceOptions = {
        bloc: 'ctr',
        colonnesVisibles: [
            { nom: 'vaectr', largeur: 130 },
            { nom: 'vaedsc', largeur: 197 },
            { nom: 'ctrcodref', largeur: 98 },
            { nom: 'ctrdscref', largeur: 236 },
            { nom: 'ctrcodnot', largeur: 66 },
            { nom: 'ctrnot', largeur: 252 }
        ],
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 25, 50]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('vaectrope', { largeur: 33.33 }),
            new FormulaireItem('vaectrtyp', { largeur: 33.33 }),
            new FormulaireItem('ctrcleintcodref', {
                largeur: 33,
                resourceParams: (data: any) => {
                    return {
                        vaectrtyp: data.vaectrtyp
                    };
                }
            }),
            new FormulaireItem('ctrcodnot', { largeur: 33.33 }),
            new FormulaireItem('ctrnot', { largeur: 33.33 })
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'folcod', largeur: 130 },
            { nom: 'foldsc', largeur: 200 },
            { nom: 'foacod', largeur: 100 },
            { nom: 'foadsc', largeur: 200 },
            { nom: 'fvmnumcas', largeur: 150 },
            { nom: 'fvmnot', largeur: 100 },
            { nom: 'fvmvalmax', largeur: 115 },
            { nom: 'vaefoatypdsc', largeur: 197 },
            { nom: 'folann', largeur: 89 }
        ],
        colonnesCachees: ['vaefoatyp'],
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('folcleint', { largeur: 33 }),
            new FormulaireItem('folann', { largeur: 33, readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('foacleint', { largeur: 33 }),
            new FormulaireItem('vaefoatyp', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fvmnumcas', { largeur: 33 }),
            new FormulaireItem('fvmvalmax', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fvmnot', { largeur: 33 })
        ]),
        multiOccurrenceOptionsEnfant: vm.ctrMultiOccurrenceOptions
    };
}
