import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageTf0017 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceFesOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Tf0017Controller(Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,    
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageTf0017 = this;

    vm.multiOccurrenceFesOptions = {
        bloc: 'fes',
        fonctions: {
            rechercheCollapsable: false,
            nouveau: ecranDetails.valeurs.flgmod,
            edition: ecranDetails.valeurs.flgmod,
            supprime: ecranDetails.valeurs.flgmod,
            boutonDupliquer: ecranDetails.valeurs.flgmod
        },
        colonnesVisibles: [
            { nom: 'fescod', largeur: 25 },
            { nom: 'fesdsc', largeur: 25 },
            { nom: 'vaedscudm', largeur: 25 },
            { nom: 'vaedsctyp', largeur: 25 },
            { nom: 'vaedscass', largeur: 25 },
            { nom: 'fesflgres', largeur: 25 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('fescod'   , { required: true, largeur: 10 }),
            new FormulaireItem('fesdsc'   , { largeur: 28 }),
            new FormulaireItem('vaefesudm', { required: true, largeur: 15 }),
            new FormulaireItem('vaefestyp', { required: true,  largeur: 15 }),
            new FormulaireItem('vaefesass', { largeur: 15 }),
            new FormulaireItem('fesflgres', { largeur: 10 })
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            nouveau: ecranDetails.valeurs.flgmod,
            edition: ecranDetails.valeurs.flgmod,
            supprime: ecranDetails.valeurs.flgmod,
            boutonDupliquer: ecranDetails.valeurs.flgmod
        },
        colonnesVisibles: [
            { nom: 'femcod', largeur: 8 },
            { nom: 'femdsc', largeur: 190 },
            { nom: 'femcom', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('femcod', { largeur: 10, required: true }),
            new FormulaireItem('femdsc', { largeur: 35 }),
            new FormulaireItem('femcom', { largeur: 50 })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceFesOptions
    };
}
