import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCP0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0030Controller(Menu: IMenuClass,
    Operateur: IOperateurService,
    cp0027Route: IRoute,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCP0030 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        navigatePage: cp0027Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cp0027Route.NAME)
        ]),
        colonnesVisibles: [
            { nom: 'lotcod', largeur: 89 },
            { nom: 'lotdsc', largeur: 262 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'ecldsceta', largeur: 180 },
            { nom: 'lotdatdeb', largeur: 100 },
            { nom: 'paymnttot', largeur: 126 },
            { nom: 'paymnttotesc', largeur: 126 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 76 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'trnflgstaann', largeur: 75 }
        ]
    };
}
