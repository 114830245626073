import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IProfil} from '../../../../../core/resources/profil.resource';


interface IPageLs0002 extends IComponentController {
    formulaireCie: IFormulaire;
    lcuMultiOccurrenceOptions: IMultiOccurrenceOptions;
    lcaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */
export default function Ls0002Controller(profil: IProfil,
                                         FormulaireItem: IFormulaireItemClass,
                                         Formulaire: IFormulaireClass) {

    const vm: IPageLs0002 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            id: profil.compagnie.ciecleint
        };


        vm.lcuMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'urgcod', largeur: 60},
                {nom: 'urgdsc', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint')
            ]),
            fonctions: {
                pagination: true
            }
        };


        vm.lcaMultiOccurrenceOptions= {
            colonnesVisibles: [
                {nom: 'actcod', largeur: 60},
                {nom: 'actdsc', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('actcleint')
            ]),
            fonctions: {
                pagination: true
            }
        };


    }

}
