import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageMc0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0031Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0031 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'grpcod', largeur: 120 },
            { nom: 'grpdsc', largeur: 150 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('grpcod', {
                modifAllow: false
            }),
            new FormulaireItem('grpdsc'),
            new FormulaireItem('intcleint')
        ], { largeurDefaut: 33.33 })
    };
}
