import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0016Controller(mc0165Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0016 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0165Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0165Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'foncod'   , largeur: 120},
            {nom: 'fondsc'   , largeur: 300},
            {nom: 'fondscabr', largeur: 200},
            {nom: 'fonflgpro', largeur: 170}
        ]
    };
}
