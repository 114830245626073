import { IComponentController } from "angular";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";

interface IPagePR0030 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence
    pdfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prjMultiOccurrenceOptions: IMultiOccurrenceOptions
    datMultiOccurrenceOptions: IMultiOccurrenceOptions
    depMultiOccurrenceOptions: IMultiOccurrenceOptions
    pdfMultiOccurrence: IMultiOccurrence
    prjMultiOccurrence: IMultiOccurrence
    datMultiOccurrence: IMultiOccurrence
    depMultiOccurrence: IMultiOccurrence
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Pr0030Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPagePR0030 = this;

    vm.monoOccurrenceOptions = {
        noId: true
    }

        const criteresSuggeres = new Formulaire([
            new FormulaireItem('prjcleint', { required: false }), 
            new FormulaireItem('intcleint'), 
            new FormulaireItem('unacleint', { titre: ecranDetails.valeurs.lbluna}),
            new FormulaireItem('orgcleint', { required: false }),
            new FormulaireItem('orpcleint'),
            new FormulaireItem('typcleint', { required: false }),
            new FormulaireItem('tyfcleint', { required: false }),
            new FormulaireItem('toccleint'),
            new FormulaireItem('grp_int', { default: 1 }),
            new FormulaireItemDivider('LBL_DATE_BUT'),
            new FormulaireItem('datbutdeb', { largeur: 10 }),
            new FormulaireItem('datbutfin', { largeur: 10 }),
            new FormulaireItem('vecpddtyprap', { largeur: 30 }),             
            new FormulaireItemDivider('LBL_DATE_FIN'),
            new FormulaireItem('annde', { largeur: 10 }),
            new FormulaireItem('anna', { largeur: 10 }),
            new FormulaireItem('datdeb', { largeur: 10 }),
            new FormulaireItem('datfin', { largeur: 10 }),
            new FormulaireItem('datdebfin', { largeur: 10 }),
            new FormulaireItem('datfinfin', { largeur: 10 })                        
        ])

        vm.pdfMultiOccurrenceOptions = {
            fonctions: {
                customCriteresSuggeres: true,
                criteresSuggeresVisibles: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false, 
                effaceCriteres: true,               
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiser: false,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesVisibles: [
                { nom: "prjcod", largeur: 100 },
                { nom: "prucod", largeur: 100 },
                { nom: "rspcod", largeur: 120 },
                { nom: "rspnom", largeur: 160 },
                { nom: "typcod", largeur: 120 },
                { nom: "typdsc", largeur: 200 },
                { nom: "etbcod", largeur: 130 },
                { nom: "etbnom", largeur: 300 },
                { nom: "cencod", largeur: 150 },
                { nom: "cendsc", largeur: 180 },
                { nom: "pdfcod", largeur: 100 },
                { nom: "pdfdatdebree", largeur: 100 },
                { nom: "pdfdatfinree", largeur: 100 },
                { nom: "el1datdeb", largeur: 100 },
                { nom: "el1datfin", largeur: 100 },
                { nom: "orgcod", largeur: 120 },
                { nom: "orgnom", largeur: 350 },
                { nom: "orpcod", largeur: 120 },
                { nom: "orpdsc", largeur: 180 },
                { nom: "prjdsc", largeur: 180 },
                { nom: "tyfcod", largeur: 170 },
                { nom: "tyfdsc", largeur: 200 },
                { nom: "toccod", largeur: 130 },
                { nom: "tocdsc", largeur: 190 },
                { nom: "cetactabr", largeur: 160 },
                { nom: "pdfnumref", largeur: 140 },
                { nom: "pdfcodproint", largeur: 140 },
                { nom: "prjnumtrfifr", largeur: 100 },
                { nom: "prjflgifr", largeur: 100 },
                { nom: "ficmntdem_1", largeur: 130 },
                { nom: "ficmntdem_2", largeur: 130 },                
                { nom: "ficmntdem_3", largeur: 130 },
                { nom: "ficmntdem_4", largeur: 130 },
                { nom: "ficmntdem_5", largeur: 130 },
                { nom: "ficmntdem_6", largeur: 130 },
                { nom: "ficmntdem_7", largeur: 130 },
                { nom: "ficmntdem_tot", largeur: 130 },
                { nom: "ficmntoct_1", largeur: 130 },
                { nom: "ficmntoct_2", largeur: 130 },
                { nom: "ficmntoct_3", largeur: 130 },
                { nom: "ficmntoct_4", largeur: 130 },
                { nom: "ficmntoct_5", largeur: 130 },
                { nom: "ficmntoct_6", largeur: 130 },
                { nom: "ficmntoct_7", largeur: 130 },
                { nom: "ficmntoct_tot", largeur: 130 },
                { nom: "ficmntoctcor_1", largeur: 140 },
                { nom: "ficmntoctcor_2", largeur: 140 },
                { nom: "ficmntoctcor_3", largeur: 140 },
                { nom: "ficmntoctcor_4", largeur: 140 },
                { nom: "ficmntoctcor_5", largeur: 140 },
                { nom: "ficmntoctcor_6", largeur: 140 },
                { nom: "ficmntoctcor_7", largeur: 140 },
                { nom: "ficmntoctcor_tot", largeur: 140 },
                { nom: "ficmntrecorg_1", largeur: 130 },
                { nom: "ficmntrecorg_2", largeur: 130 },
                { nom: "ficmntrecorg_3", largeur: 130 },
                { nom: "ficmntrecorg_4", largeur: 130 },
                { nom: "ficmntrecorg_5", largeur: 130 },
                { nom: "ficmntrecorg_6", largeur: 130 },
                { nom: "ficmntrecorg_7", largeur: 130 },
                { nom: "ficmntrecorg_tot", largeur: 130 },
                { nom: "ficmntrecsru_1", largeur: 130 },
                { nom: "ficmntrecsru_2", largeur: 130 },
                { nom: "ficmntrecsru_3", largeur: 130 },
                { nom: "ficmntrecsru_4", largeur: 130 },
                { nom: "ficmntrecsru_5", largeur: 130 },
                { nom: "ficmntrecsru_6", largeur: 130 },
                { nom: "ficmntrecsru_7", largeur: 130 },
                { nom: "ficmntrecsru_tot", largeur: 130 },
                { nom: "t_info_compl_pole", largeur: 180 },
                { nom: "prjintpay", largeur: 180 },
                { nom: "caocod", largeur: 180 },
                { nom: "caodsc", largeur: 300 },
                { nom: "liecod", largeur: 170 },
                { nom: "liedsc", largeur: 180 },
                { nom: "pdfcom", largeur: 280 }
            ]            
        }

        vm.prjMultiOccurrenceOptions = {
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                criteresSuggeresVisibles: true,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesFixesGauche: [
                { nom: "prjcod", largeur: 100 }
            ],
            colonnesVisibles: [                
                { nom: "prjdatdeb", largeur: 130 },
                { nom: "prjdatfin", largeur: 130 },
                { nom: "rspcod", largeur: 120 },
                { nom: "rspnom", largeur: 160 },
                { nom: "etbcod", largeur: 120 },
                { nom: "etbnom", largeur: 300 },
                { nom: "cencod", largeur: 150 },
                { nom: "cendsc", largeur: 180 },
                { nom: "prjintpay", largeur: 180 },
                { nom: "prjnumtrfifr", largeur: 100 },
                { nom: "prjdsc", largeur: 220 },
                { nom: "typcod", largeur: 170 },
                { nom: "typdsc", largeur: 200 },                
                { nom: "ficmntdem_1", largeur: 150 },
                { nom: "ficmntdem_2", largeur: 150 },                
                { nom: "ficmntdem_3", largeur: 150 },
                { nom: "ficmntdem_4", largeur: 150 },
                { nom: "ficmntdem_5", largeur: 150 },
                { nom: "ficmntdem_6", largeur: 150 },
                { nom: "ficmntdem_7", largeur: 150 },
                { nom: "ficmntdem_tot", largeur: 140 },
                { nom: "ficmntoct_1", largeur: 130 },
                { nom: "ficmntoct_2", largeur: 130 },
                { nom: "ficmntoct_3", largeur: 130 },
                { nom: "ficmntoct_4", largeur: 130 },
                { nom: "ficmntoct_5", largeur: 130 },
                { nom: "ficmntoct_6", largeur: 130 },
                { nom: "ficmntoct_7", largeur: 130 },
                { nom: "ficmntoct_tot", largeur: 130 },
                { nom: "ficmntoctcor_1", largeur: 140 },
                { nom: "ficmntoctcor_2", largeur: 140 },
                { nom: "ficmntoctcor_3", largeur: 140 },
                { nom: "ficmntoctcor_4", largeur: 140 },
                { nom: "ficmntoctcor_5", largeur: 140 },
                { nom: "ficmntoctcor_6", largeur: 140 },
                { nom: "ficmntoctcor_7", largeur: 140 },
                { nom: "ficmntoctcor_tot", largeur: 140 }
            ]
        }

        vm.datMultiOccurrenceOptions = {
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                effaceCriteres: true,
                recherche: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesFixesGauche: [
                { nom: "prjcod", largeur: 100 },
                { nom: "pdfcod", largeur: 100 }
            ],
            colonnesVisibles: [                
                { nom: "rspcod", largeur: 120 },
                { nom: "rspnom", largeur: 160 },
                { nom: "etbcod", largeur: 120 },
                { nom: "etbnom", largeur: 160 },
                { nom: "liecod", largeur: 170 },
                { nom: "liedsc", largeur: 240 },
                { nom: "orgcod", largeur: 120 },
                { nom: "orgnom", largeur: 160 },
                { nom: "orpcod", largeur: 120 },
                { nom: "orpdsc", largeur: 180 },
                { nom: "pdfnumref", largeur: 140 },                
                { nom: "typcod", largeur: 170 },
                { nom: "typdsc", largeur: 200 },                
                { nom: "tyfcod", largeur: 170 },
                { nom: "tyfdsc", largeur: 240 },                
                { nom: "toccod", largeur: 130 },
                { nom: "tocdsc", largeur: 190 },
                { nom: "pdddat", largeur: 110 },
                { nom: "vecdsc", largeur: 180 },
                { nom: "pddcom", largeur: 200 },
                { nom: "emi", largeur: 100 },
                { nom: "pub", largeur: 100 }
            ]}

        vm.depMultiOccurrenceOptions = {
            fonctions: {
                customCriteresSuggeres: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: false,
                effaceCriteres: true,
                filtrer: true,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                avisEmploye: false,
                reinitialiserPastillefiltre: true
            },
            criteresSuggeres,
            colonnesFixesGauche: [
                { nom: "prjcod", largeur: 100 },
                { nom: "pdfcod", largeur: 100 },
                { nom: "prucod", largeur: 100 }
            ],
            colonnesVisibles: [                
                { nom: "rspcod", largeur: 120 },
                { nom: "rspnom", largeur: 160 },
                { nom: "liecod", largeur: 170 },
                { nom: "liedsc", largeur: 240 },
                { nom: "orgcod", largeur: 120 },
                { nom: "orgnom", largeur: 160 },
                { nom: "typcod", largeur: 170 },
                { nom: "typdsc", largeur: 200 },                
                { nom: "tyfcod", largeur: 170 },
                { nom: "tyfdsc", largeur: 240 },                
                { nom: "toccod", largeur: 130 },
                { nom: "tocdsc", largeur: 190 },
                { nom: "ficmntoct_1", largeur: 140 },
                { nom: "ficmntoct_2", largeur: 140 },
                { nom: "ficmntoct_3", largeur: 140 },
                { nom: "ficmntoct_4", largeur: 140 },
                { nom: "ficmntoct_5", largeur: 140 },
                { nom: "ficmntoct_6", largeur: 140 },
                { nom: "ficmntoct_7", largeur: 140 },
                { nom: "ficmntoct_tot", largeur: 140 },
                { nom: "ficdurpro", largeur: 100 },
                { nom: "ficfinann", largeur: 140 },
                { nom: "deprel", largeur: 140 },
                { nom: "depeng", largeur: 160 },
                { nom: "revrel", largeur: 140 },
                { nom: "reveng", largeur: 140 }
            ]
         }        

 }
