import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0536',
    ecrans: ['00-2'],
    params: [
        '?demcleint',
        '?unpcleint',
        '?statut'
    ]
});
