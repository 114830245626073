import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as0023',
    ecrans: [
        '00-2',
        '01-2'
    ],
    params: [
        'id',
        '?trncleint',
        '?asgcleint',
        '?demcleint',
        '?poscleint_param',
        '?hhscleintref'
    ]
});
