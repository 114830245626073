import IStateProvider = angular.ui.IStateProvider;
import {module} from 'angular';
import {IRoute} from '../../../../core/interfaces/route.interface';

const route: IRoute = {
    NAME: 'secure.introuvable',
};

export default module('app.secure.introuvable', [
    'core.layouts.vertical-navigation'
]).config(config).constant('introuvableRoute', route);

/* @ngInject */
function config($stateProvider: IStateProvider) {
    const template = require('./introuvable.html');

    $stateProvider.state(route.NAME, {
        params: {
            route
        },
        views: {
            content: {
                template
            }
        }
    });
}
