import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageMc0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0021Controller(mc0095Route: IRoute,
                                         mc0089Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMc0021 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaedeicle_dsc', largeur: 120},
            {nom: 'deilib', largeur: 120},
            {nom: 'deiordaff', largeur: 80},
            {nom: 'vaedeival_dsc', largeur: 180},
            {nom: 'deilibabr', largeur: 100},
            {nom: 'deilng', largeur: 80},
            {nom: 'deiflglngfix', largeur: 120},
            {nom: 'deiflgcplzer', largeur: 120},
            {nom: 'deitypdon_dsc', largeur: 120},
            {nom: 'deityplet_dsc', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaedeicle'),
            new FormulaireItem('deilib'),
            new FormulaireItem('deiordaff'),
            new FormulaireItem('deilibabr'),
            new FormulaireItem('vaedeival'),
            new FormulaireItem('deilng'),
            new FormulaireItem('deiflglngfix'),
            new FormulaireItem('deiflgcplzer'),
            new FormulaireItem('deitypdon', {largeur: 50}),
            new FormulaireItem('deityplet', {largeur: 50})
        ], {largeurDefaut: 25})
    };
}
