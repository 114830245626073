import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0017Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0017 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'ccocod', largeur: 80 },
            { nom: 'ccodsc', largeur: 310 },
            { nom: 'ccoflgdat', largeur: 70 },
            { nom: 'ccoflgint', largeur: 90 },
            { nom: 'ccoflgact', largeur: 70 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ccocod', { required: true }),
            new FormulaireItem('ccodsc', { required: true }),
            new FormulaireItem('ccoflgdat', { required: false }),
            new FormulaireItem('ccoflgint', { required: false }),
            new FormulaireItem('ccoflgact', { required: false, default: 1 }),
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
