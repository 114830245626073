import {IComposantDialog} from '../../../../core/components/ex-dialog/ex-dialog.controller';
import {IDataType} from '../../../../core/services/data-types/data-type.service';
import {ISchemaItem} from '../../../../core/interfaces/schemas.interface';
import {IFilterLibelle} from '../../../../core/filters/ex-libelle.filter';

interface IDialogAideTechnique extends IComposantDialog {
    col: string;
    dataType: IDataType;
    libelle: string;
    shemaItem: ISchemaItem;
}

/* @ngInject */
export default function AideTechniqueController(exLibelleFilter: IFilterLibelle) {
    const vm: IDialogAideTechnique = this;
    vm.$onInit = function $onInit() {
        vm.lblTitre = `${exLibelleFilter('G_LBL_AIDE_TECH_PROPRIETES_ELEMENT')} "${vm.col}"`;
    }
}
