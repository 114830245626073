import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0050 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mr0050Controller(Operateur: IOperateurService,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMr0050 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'farcod', largeur: 110 },
            { nom: 'fardsc', largeur: 240 },
            { nom: 'farprical', largeur: 100 },
            { nom: 'farpripre', largeur: 100 },
            { nom: 'farflgsaipar', largeur: 100 },
            { nom: 'farflgsaigvn', largeur: 100 },
            { nom: 'farflgman', largeur: 100 },
            { nom: 'farflgneg', largeur: 100 }
        ]
    };
}

