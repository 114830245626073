import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageAc1000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac1000Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAc1000 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable: false,
            effaceCriteres: true,
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('etccleintcri', {readonly: true})
        ]),
        colonnesVisibles: [
            {nom: 'datcreaff', largeur: 130},
            {nom: 'usrcod', largeur: 120},
            {nom: 'usrnom', largeur: 200},
            {nom: 'vaeetcstudsc', largeur: 200}
        ]
    };
}
