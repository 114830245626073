import { IComponentController } from "angular";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from "angular-ui-router";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";

interface IPagePc0018 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    resMultiOccurrenceOptions: IMultiOccurrenceOptions;
    idcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    epcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sccMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dclMultiOccurrenceOptions: IMultiOccurrenceOptions;
    criMultiOccurrenceOptions: IMultiOccurrenceOptions;
    resMultiOccurrence: IMultiOccurrence;
    idcMultiOccurrence: IMultiOccurrence;
    epcMultiOccurrence: IMultiOccurrence;
    sccMultiOccurrence: IMultiOccurrence;
    dclMultiOccurrence: IMultiOccurrence;
    criMultiOccurrence: IMultiOccurrence;
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Pc0018Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $state: IStateParamsService ) {
    const vm: IPagePc0018 = this;

    vm.monoOccurrenceOptions = {
        noId: true
    }
    
    const criteresSuggeres = new Formulaire([
        new FormulaireItem('ann', { largeur: 15,  default: ecranDetails.valeurs.ann }),
        new FormulaireItem('datdeb', { largeur: 25, default: ecranDetails.valeurs.datdeb }),
        new FormulaireItem('datfin', { largeur: 25, default: ecranDetails.valeurs.datfin }),
        new FormulaireItem('demcleint', { largeur: 25, default: $state.params.employe, hidden: true })
    ], { largeurDefaut: 25 })

    vm.criMultiOccurrenceOptions = {
        srccod:"pc0018",//important afin d'obtenir les datatypes et les libelles
        fonctions: {
            customCriteresSuggeres: true,
            reinitialiserOuvrirCriteres: !$state.params.employe,
            effaceCriteres: true,
            criteresSuggeresVisibles: !$state.params.employe,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'ann', largeur: 15 },
            { nom: 'datdeb', largeur: 25 },
            { nom: 'datfin', largeur: 25 },
            { nom: 'demcleint', largeur: 25 }
        ]
    }

    vm.resMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            criteresSuggeresVisibles: true,
            effaceCriteres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: true,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 290 }
        ],
        colonnesVisibles: [
            { nom: 'idccod', largeur: 130 },
            { nom: 'vacemr', largeur: 130 },
            { nom: 'dscemr', largeur: 250 },
            { nom: 'vaclic', largeur: 110 },
            { nom: 'dsclic', largeur: 250 },
            { nom: 'vacscl', largeur: 126 },
            { nom: 'dscscl', largeur: 186 },
            { nom: 'clacod', largeur: 100 },
            { nom: 'cladsc', largeur: 260 },
            { nom: 'dcldatvig', largeur: 110 }
        ]        
    };

    vm.idcMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: true,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false,
            edition: false,
            nouveau: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 190 },
            { nom: 'idccod', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', {largeur: 35}),
            new FormulaireItem('idccod', {largeur: 25})
        ])
    };

    vm.epcMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: true,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 190 },
            { nom: 'vacemr', largeur: 110 },
            { nom: 'dscemr', largeur: 250 },
            { nom: 'vaclic', largeur: 110 },
            { nom: 'dsclic', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', {largeur: 35}),
            new FormulaireItem('vaccreemr', {largeur: 25}),
            new FormulaireItem('vaccrelic', {largeur: 25})
        ])        
    };

    vm.sccMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: true,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 190 },
            { nom: 'vacscl', largeur: 140 },
            { nom: 'dscscl', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', {largeur: 35}),
            new FormulaireItem('vaccrescl', {largeur: 25})
        ])
    };

    vm.dclMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: true,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 190 },
            { nom: 'clacod', largeur: 110 },
            { nom: 'cladsc', largeur: 250 },
            { nom: 'dcldatvig', largeur: 140 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', {largeur: 35}),
            new FormulaireItem('clacleint', {largeur: 35}),
            new FormulaireItem('dcldatvig', {largeur: 25})
        ])        
    };
    
}
