import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0212',
    ecrans: ['00-2'],
    params: ['id',
             '?mobcleintref',
             '?stamnetab']
});

