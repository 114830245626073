import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageTF0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0020Controller(Menu: IMenuClass,
    Formulaire: IFormulaireClass, 
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    tf0021Route:IRoute,
    $stateParams: IStateParamsService) {

    const vm: IPageTF0020 = this;

    const filtres = [];

    if (ecranDetails.valeurs.tfhann) {
        filtres.push({
            colonne: 'tfhann',            
            valeur: ecranDetails.valeurs.tfhann,
            visible: false,
            readOnly: true
        });
    }

    

    vm.multiOccurrenceOptions = {
        navigatePage:tf0021Route.NAME,
        navigateParams: (rowData: any) => ({
            id: rowData.tfacleint
        }),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: false,
            criteresSuggeresVisibles: !ecranDetails.valeurs.tfhann
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('criann', {  largeur: 10, default: ecranDetails.valeurs.tfhann, disabled: ecranDetails.valeurs.tfhann}),
            new FormulaireItem('criefacod', {  largeur: 20, 
                resourceParams: (data: any) => {
                    return {
                        tfhann: data.criann
                    };
            }
            }),        
            new FormulaireItem('criefanomprn', {  largeur: 30,
                resourceParams: (data: any) => {
                    return {
                        tfhann: data.criann
                    };
            }
            })                    
        ],{largeurDefaut: 25}),

        filtres,
        colonnesFixesGauche: [
            { nom: 'efacod', largeur: 150, hidden: $stateParams.employe },
            { nom: 'efanomprn', largeur: 262, hidden: $stateParams.employe },
        ],  
        colonnesVisibles: [            
            { nom: 'tfhann', largeur: 75 },
            { nom: 'fpncod', largeur: 100 },
            { nom: 'fpndsc', largeur: 262 },
            { nom: 'femcod', largeur: 140 },
            { nom: 'femdsc', largeur: 262 },
            { nom: 'tfamnttfe', largeur: 126 },
            { nom: 'tfamnttferet', largeur: 146 },
            { nom: 'tfacotvol', largeur: 126 },
            { nom: 'total', largeur: 126 },
            { nom: 'tfaflguti', largeur: 76 },
            { nom: 'tfaflgoff', largeur: 76 },
            { nom: 'tsaflgces', largeur: 76 },
            { nom: 'tfaflgtrf', largeur: 76 }
        ],
        colonnesCachees:['tfacleint']
    };
}
