import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr2009',
    ecrans: ['00-2'],
    params: [
        'id',
        '?demcleint',
        '?bqecleint',
        '?dbscleintref1',
        '?dbscleintref2',
        '?datdeb',
        '?datfin',
        '?flgfer'
    ]
});
