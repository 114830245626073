import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageGs0036 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceFoiOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0036Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass
) {
    const vm: IPageGs0036 = this;

    vm.multiOccurrenceFoiOptions = {
        bloc: 'foi',
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesFixesGauche: [
            { nom: 'frmdsc', largeur: 180 },
            { nom: 'foicodfor', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'foiseqesc', largeur: 250 },
            { nom: 'foiseqescres', largeur: 180 },
            { nom: 'foinomphy', largeur: 200 },
            { nom: 'foiflgcnv', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('frmcleint'),
            new FormulaireItem('foicodfor'),
            new FormulaireItem('foiseqesc'),
            new FormulaireItem('foiseqescres'),
            new FormulaireItem('foinomphy'),
            new FormulaireItem('foiflgcnv')
        ], { largeurDefaut: 33.33 })
    };

    vm.multiOccurrenceOptions = {
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_COPIER',
                'BOUCOP',
                (rowData: any) => ({ impcleint: rowData.impcleint }),
                {
                    icon: 'content_copy',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_COPIER',
                        lblConfirm: 'G_LBL_BTN_VALIDER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('impdscnew', { required: true, maxlength: 30 }),
                            new FormulaireItem('nomphynew', { maxlength: 60 })
                        ], { largeurDefaut: 100 }
                        )
                    }
                }
            )
        ]),
        pagination: {
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 15]
        },
        colonnesVisibles: [
            { nom: 'impdsc', largeur: 200 },
            { nom: 'impflggra', largeur: 120 },
            { nom: 'impparimp', largeur: 120 },
            { nom: 'impflgcou', largeur: 10 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('impdsc'),
            new FormulaireItem('impflggra'),
            new FormulaireItem('impparimp'),
            new FormulaireItem('impflgcou')
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceFoiOptions
    };
}
