import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IInfosMonetaires } from "../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller";
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";

interface IPageGl0039 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePej: IFormulaire;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    gfeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    geeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gl0039Controller(gl0011Route: IRoute,
    $state: IStateService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    Operateur: IOperateurService,
    exLibelleFilter: IFilterLibelle,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass) {
    const vm: IPageGl0039 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemActionTelechargement(
                'LBL_FICHIER',
                'BOUTELPIJ',
                () => ({}),
                {
                    iconButton: false,
                    class: "md-raised ex-button-secondary",
                    icon: 'attach_file',
                    hidden: (data: any) => !data.pijcleint
                }
            ),
            new MenuItemAction(
                'LBL_BTN_DET',
                'BOUDET',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        $state.go(gl0011Route.NAME);
                    }
                }
            ),
            new MenuItemActionImportation(
                'LBL_BTN_CHARGER',
                'BOUCHAR',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_CSV',
                        largeurFormulaire: 15,
                        formulaire: new Formulaire([
                            new FormulaireItem('gfenumchg', {
                                largeur: 100,
                                default: (data: any) => data.monoOccurrence.gfenumchg
                            })
                        ], { libelleUpload: exLibelleFilter('G_LBL_BTN_UPLOAD_BOX_CSV') })
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    typesFichiers: ['.csv'],
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    hidden: () => (
                        vm.monoOccurrence.data.pejflgchg
                    )
                }
            ),
            new MenuItemAction(
                'LBL_BTN_IMP',
                'BOUIMP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    disabled: () => vm.monoOccurrence.data.aut__gfeflgtrt
                }
            )
        ],
        formulaire: new Formulaire('LBL_BLC_PEJ', [
            new FormulaireItem('gfenumchg', { readonly: data => data.pejcleint }),
            new FormulaireItem('gfesrc', { readonly: true }),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('aut__nbrenr', { readonly: true })
        ])
    };


    vm.$onInit = function $onInit() {
        // Information monétaire
        vm.monoOccurrenceInfosMonetaire = {
            largeurTexte: 60,
            blocs: ['gfe'],
            donnees:
                [
                    { nom: 'dbt', libelle: 'G_LBL_DBT' },
                    { nom: 'crt', libelle: 'G_LBL_CRT', lastOfGroup: true },
                    { nom: 'ecart', libelle: 'G_LBL_ECART', expectedValue: 0 }
                ]
        };

        vm.gfeMultiOccurrenceOptions = {
            fonctions: {
                editionRapide: true
            },
            filtres: [
                {
                    colonne: 'gfeflgerr',
                    operateur: Operateur.EGALE,
                    valeur: ''
                }
            ],
            colonnesFixesGauche: [
                { nom: 'gfenumlig', largeur: 100 },
                { nom: 'gfeflgecjerr', largeur: 120 },
                { nom: 'gfeflgecverr', largeur: 120 },
                { nom: 'gfeflgdoierr', largeur: 120 }
            ],
            colonnesVisibles: [
                // Écriture
                { nom: 'gfeecjdat', largeur: 130 },
                { nom: 'gfetydcod', largeur: 120 },
                { nom: 'gfepeccod', largeur: 120 },
                { nom: 'gfeecjcat', largeur: 120 },
                { nom: 'gfeecjdsc', largeur: 250 },
                { nom: 'gferefcod', largeur: 150 },
                { nom: 'gfeintcod', largeur: 100 },
                { nom: 'gfegrpecj', largeur: 160 },
                // Ventilation
                { nom: 'gfegrpecv', largeur: 160 },
                { nom: 'gfeprucod', largeur: 130 },
                { nom: 'gfeprscod', largeur: 130 },
                { nom: 'gfecifcod', largeur: 130 },
                { nom: 'gfeunacod', largeur: 130 },
                { nom: 'gfecptcod', largeur: 130 },
                { nom: 'gfeel1cod', largeur: 130 },
                { nom: 'gfeel2cod', largeur: 130 },
                { nom: 'gfeel3cod', largeur: 130 },
                { nom: 'gfeecvdsc', largeur: 250 },
                { nom: 'gfedoimntdbt', largeur: 126 },
                { nom: 'gfedoimntcrt', largeur: 126 },
                { nom: 'gfedoiqte', largeur: 126 },
                { nom: 'gferefcodecv', largeur: 150 },
                // Référence
                { nom: 'gfer01', largeur: 120 },
                { nom: 'gfer02', largeur: 120 },
                { nom: 'gfer03', largeur: 120 },
                { nom: 'gfer04', largeur: 120 },
                { nom: 'gfer05', largeur: 120 },
                { nom: 'gfer06', largeur: 120 },
                { nom: 'gfer07', largeur: 120 },
                { nom: 'gfer08', largeur: 120 },
                { nom: 'gfer09', largeur: 120 },
                { nom: 'gfer10', largeur: 120 },
                { nom: 'gfepeccodrenv', largeur: 150 }
            ],
            colonnesCachees: [
                'gfemess'
            ],
            colonnesEditionRapide: [
                { nom: 'gfenumlig', largeur: 100 },
                { nom: 'gfeecjdat', largeur: 130 },
                { nom: 'gfetydcod', largeur: 120 },
                { nom: 'gfepeccod', largeur: 120 },
                { nom: 'gfeecjcat', largeur: 120 },
                { nom: 'gfeecjdsc', largeur: 250 },
                { nom: 'gferefcod', largeur: 150 },
                { nom: 'gfeintcod', largeur: 100 },
                { nom: 'gfegrpecj', largeur: 160 },
                // Ventilation
                { nom: 'gfegrpecv', largeur: 160 },
                { nom: 'gfeprucod', largeur: 130 },
                { nom: 'gfeprscod', largeur: 130 },
                { nom: 'gfecifcod', largeur: 130 },
                { nom: 'gfeunacod', largeur: 130 },
                { nom: 'gfecptcod', largeur: 130 },
                { nom: 'gfeel1cod', largeur: 130 },
                { nom: 'gfeel2cod', largeur: 130 },
                { nom: 'gfeel3cod', largeur: 130 },
                { nom: 'gfeecvdsc', largeur: 250 },
                { nom: 'gfedoimntdbt', largeur: 126 },
                { nom: 'gfedoimntcrt', largeur: 126 },
                { nom: 'gfedoiqte', largeur: 126 },
                { nom: 'gferefcodecv', largeur: 150 },
                // Référence
                { nom: 'gfer01', largeur: 120 },
                { nom: 'gfer02', largeur: 120 },
                { nom: 'gfer03', largeur: 120 },
                { nom: 'gfer04', largeur: 120 },
                { nom: 'gfer05', largeur: 120 },
                { nom: 'gfer06', largeur: 120 },
                { nom: 'gfer07', largeur: 120 },
                { nom: 'gfer08', largeur: 120 },
                { nom: 'gfer09', largeur: 120 },
                { nom: 'gfer10', largeur: 120 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gfenumlig'),
                new FormulaireItemDivider('LBL_DIV_ECJ'),
                new FormulaireItem('gfeecjdat'),
                new FormulaireItem('gfetydcod'),
                new FormulaireItem('gfepeccod'),
                new FormulaireItem('gfeecjcat'),
                new FormulaireItem('gfeecjdsc'),
                new FormulaireItem('gfeintcod'),
                new FormulaireItem('gfegrpecj'),
                new FormulaireItemDivider('LBL_DIV_ECV'),
                new FormulaireItem('gfegrpecv'),
                new FormulaireItem('gfeprucod'),
                new FormulaireItem('gfeprscod'),
                new FormulaireItem('gfecifcod'),
                new FormulaireItem('gfeunacod'),
                new FormulaireItem('gfecptcod'),
                new FormulaireItem('gfeel1cod'),
                new FormulaireItem('gfeel2cod'),
                new FormulaireItem('gfeel3cod'),
                new FormulaireItem('gfeecvdsc'),
                new FormulaireItem('gfedoimntdbt'),
                new FormulaireItem('gfedoimntcrt'),
                new FormulaireItem('gfedoiqte'),
                new FormulaireItem('gferefcodecv'),
                new FormulaireItemDivider('LBL_DIV_REF'),
                new FormulaireItem('gfer01'),
                new FormulaireItem('gfer02'),
                new FormulaireItem('gfer03'),
                new FormulaireItem('gfer04'),
                new FormulaireItem('gfer05'),
                new FormulaireItem('gfer06'),
                new FormulaireItem('gfer07'),
                new FormulaireItem('gfer08'),
                new FormulaireItem('gfer09'),
                new FormulaireItem('gfer10')
            ], { largeurDefaut: 33 })
        };

        vm.geeMultiOccurrenceOptions = {
            fonctions: {
                suiviModification: false,
                filtrer: false
            },
            colonnesVisibles: [
                { nom: 'geemsg', largeur: 500 }
            ]
        };
    }
}
