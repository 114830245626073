import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateService } from 'angular-ui-router';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IAs2011Params {
    menuId: string;
}

interface IPageAS0047 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0047Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    exDateFilter: IFilterExDate,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    as2011Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    rr0006Route: IRoute,
    al0002Route: IRoute,
    $stateParams: IAs2011Params,
    $state: IStateService) {
    const vm: IPageAS0047 = this;

    // Forage vers l'affectation
    const forageAft = new MenuItemForage(
        'LBL_FOR_AFT',
        rr0006Route.NAME,
        (data:any) => ({
            id: data.trncleint_aft
        }),
        { hidden: (data: any) => !data.trncleint_aft,
            fonction:'AFFECT' }
    );

    // Forage vers dossier ALD
    const forageAld = new MenuItemForage(
        'LBL_FOR_ALD',
        al0002Route.NAME,
        (data:any) => ({
            id: data.trncleint_dal
        }),
        { hidden: (data: any) => !data.trncleint_dal,
            fonction:'BOUALD' }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_GENERER',
                'BOUGEN',
                {
                    selection: { 
                        nom: 'generer',
                        lblConfirmationTitre: 'LBL_BTN_GENERER',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('ttccleintgen', { 
                                required: true,
                                resourceParams: () => ({ typchg: vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri })
                            }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('datdebgen', { required: true, default: exDateFilter(new Date()) }),
                            new FormulaireItem('datfingen', { hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri === 'ASH') }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('asgcleintgen', { 
                                required: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri === 'AFT'),
                                hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri === 'ASH') 
                            }),
                            new FormulaireItem('ahtcleintgen', { 
                                required: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri === 'AFT'),
                                hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri === 'ASH') 
                            }),
                            new FormulaireItem('vaesyscaptemgen', { 
                                required: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri === 'ASH'),
                                hidden: () => (vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri === 'AFT') 
                            })
                        ])    
                    },
                    successAction: (retour: any) => {
                        $state.go(as2011Route.NAME, {
                            menuId: $stateParams.menuId,
                            typchg: vm.multiOccurrence.etatSelected.criteresSuggeresData.typchgcri,
                            ttccleint: retour.ttccleintout,
                            datvig: retour.datvigout,
                            datfinout: retour.datfinout,
                            usrcleintout: retour.usrcleintout,
                            asgcleintgenout: retour.asgcleintgenout,
                            ahtcleintgenout: retour.ahtcleintgenout
                        });
                    },
                    fonction: 'BOUGEN'
                })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem( 'typchgcri', { required: true, default: 'AFT' }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem( 'datvigcri', { required: true, default: exDateFilter(new Date()) }),
            new FormulaireItem( 'remautcri', { default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem( 'asgcleintcri', { required: true }),
            new FormulaireItem( 'ahtcleintcri'),
            new FormulaireItem( 'vaesyscaptemcri', { disabled: (data:any) => data.typchgcri === 'AFT' })
        ]),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 120 },
            { nom: 'eplnomprn', largeur: 260 }
        ],
        colonnesVisibles: [
            { nom: 'aftcod', largeur: 160, menu:[forageAft]  },
            { nom: 'aftdatdeb', largeur: 120 },
            { nom: 'aftdatfin', largeur: 120 },
            { nom: 'ecldsceta', largeur: 142 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'strcod', largeur: 120 },
            { nom: 'tafcod', largeur: 120 },
            { nom: 'temcod', largeur: 120 },
            { nom: 'posnumdoc', largeur: 120 },
            { nom: 'hhsnumdoc', largeur: 160 },
            { nom: 'ttccod', largeur: 260 },
            { nom: 'datdebfin', largeur: 180 },
            { nom: 'asgcod', largeur: 150 },
            { nom: 'ahtcod', largeur: 120 },
            { nom: 'dalnumdoc', largeur: 150, menu: [forageAld] },
            { nom: 'trnflgstaref', largeur: 130 },
            { nom: 'trnflgstafin', largeur: 110 },
            { nom: 'trnflgstafer', largeur: 110 },
            { nom: 'trnflgstaann', largeur: 130 }
        ],
        colonnesCachees: ['trncleint_aft', 'trncleint_dal']
    };
}
