import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageGs0124 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireSta: IFormulaire;
    stcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ttaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    sreMultiOccurrenceOptions: IMultiOccurrenceOptions;
    shcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    stsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0124Controller(Menu: IMenuClass,
                                         gs0119Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0124 = this;

    const accueilRoute = {NAME: 'secure.accueil'};

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulaireSta = new Formulaire('LBL_BLC_STA', [
            new FormulaireItem('stanom', {modelOptions:{updateOn: 'blur'}}),
            new FormulaireItem('stadsc'),
            new FormulaireItem('stadscang'),
            new FormulaireItem('stamnetab', {modelOptions:{updateOn: 'blur'}})
        ], {largeurDefaut: 25});

        vm.stcMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'stcnom', largeur: 140},
                {nom: 'stcdsc', largeur: 300},
                {nom: 'vaesystypdon_dsc', largeur: 110},
                {nom: 'stclng', largeur: 110},
                {nom: 'stcdec', largeur: 110},
                {nom: 'stcflgnotnul', largeur: 90},
                {nom: 'stcflgtra', largeur: 90},
                {nom: 'stcflgpreutl', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stcnom', {largeur: 25, modelOptions:{updateOn: 'blur'}}),
                new FormulaireItem('stcdsc', {largeur: 25, enableCount: true}),
                new FormulaireItem('vaesystypdon', {largeur: 25}),
                new FormulaireItem('stclng'),
                new FormulaireItem('stcdec'),
                new FormulaireItem('stcflgnotnul'),
                new FormulaireItem('stcflgtra'),
                new FormulaireItem('stcflgpreutl')
            ], {largeurDefaut: 20})
        };

        vm.srtMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'LBL_FOR_TYP',
                    (data: any) => getForage(data),
                    (data: any) => ({id:data.srccleint}),
                )]),
            colonnesVisibles: [
                {nom: 'srccod', largeur: 110},
                {nom: 'srcdsc', largeur: 300},
                {nom: 'srtflgtabpri', largeur: 200},
                {nom: 'srtflgref', largeur: 110}

            ],
            colonnesCachees: [
                'srccleint'
            ]
        };

        vm.ttaMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'ticcod', largeur: 110},
                {nom: 'ticdsc', largeur: 200}
            ]
        };

        vm.sreMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'srereqsql', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('srereqsql', {enableCount: true})
            ])
        };

        vm.shcMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'stcnom', largeur: 130},
                {nom: 'stcdsc', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stccleint'),
                new FormulaireItem('t_stacleint', {default:(data:any) => data.$ancetre.stacleint})
            ])
        };

        vm.stsMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'stsdsc', largeur: 200},
                {nom: 'stsreqsql', largeur: 500},
                {nom: 'vaetypdonret_dsc', largeur: 200},
                {nom: 'stsnbrinc', largeur: 200}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('stsdsc'),
                new FormulaireItem('stsreqsql', {enableCount: true}),
                new FormulaireItem('vaetypdonret'),
                new FormulaireItem('stsnbrinc')
            ], {largeurDefaut: 25})
        };
    }

    function getForage(data: any): string {
        if (true) {
            return gs0119Route.NAME;
        } else {
            return accueilRoute.NAME;
        }
    }
}
