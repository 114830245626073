import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageMr2020 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr2020Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageMr2020 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire( 'LBL_BLC_CEP', [
            new FormulaireGroupe('LBL_DIV_AVANCE_AUTO', new Formulaire([
                new FormulaireItem('eclcleintcen', {
                    resourceParams: () => ({
                        cyrcleint: $stateParams.cyrcleint
                    }),
                    nombreLibellesDetailsCacher: 1
                }),
                new FormulaireItem('eclcleintdec', {
                    resourceParams: () => ({
                        cyrcleint: $stateParams.cyrcleint
                    }),
                    nombreLibellesDetailsCacher: 1
                })    
            ], {largeurDefaut: 100}), {largeur: 50}),
            new FormulaireGroupe('LBL_DIV_IMP', new Formulaire([
                new FormulaireItem('cepflgignimppas'),
            ]), {largeur: 50}),
            new FormulaireGroupe('LBL_DIV_COND_EXEC', new Formulaire([
                new FormulaireItem('cexcleint', {
                    resourceParams: () => ({
                        cyrcleint: $stateParams.cyrcleint
                    }),
                    nombreLibellesDetailsCacher: 1
                }),
                new FormulaireItem('cepflggotfau'),
                new FormulaireItem('cepqtemin'),    
                new FormulaireItem('cepqtemax'),    
                new FormulaireItem('pfccleint', {
                    nombreLibellesDetailsCacher: 1
                }),    
                new FormulaireItem('cepflgincavg')
            ]), {largeur: 100}),
            new FormulaireGroupe('LBL_DIV_APPEL_SPEC', new Formulaire([
                new FormulaireItem('swccleint', {
                    nombreLibellesDetailsCacher: 1
                }),
                new FormulaireItem('ecrcleint'),
                new FormulaireItem('trlcleint'),
                new FormulaireItem('trlcleintspe')
            ]), {largeur: 100}),
            new FormulaireGroupe('LBL_DIV_GEST_FIN', new Formulaire([
                new FormulaireItem('cepflgcredoc'),
                new FormulaireItem('cepflglogeva')
            ]), {largeur: 100}),
            new FormulaireGroupe('LBL_DIV_APPEL_FIN', new Formulaire([
                new FormulaireItem('cyccleint'),
                new FormulaireItemSautDeLigne,
                new FormulaireItem('cepetdcleintdep'),
                new FormulaireItem('cepvaecypactdep'),
                new FormulaireItem('cepetdcleintret'),
                new FormulaireItem('eclcleintreffin', {
                    resourceParams: () => ({
                        cyrcleint: $stateParams.cyrcleint
                    }),
                    nombreLibellesDetailsCacher: 1
                })
            ]), {largeur: 100}),
        ])
    };
}
