import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0112 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0112Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0112 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tgncod'   , largeur: 130},
            {nom: 'tgndscabr', largeur: 200},
            {nom: 'tgndsc'   , largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tgncod'),
            new FormulaireItem('tgndscabr'),
            new FormulaireItem('tgndsc')
        ], {largeurDefaut: 33})
    };
}
