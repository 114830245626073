import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as0017',
    ecrans: ['00-2'],
    params:['id',
            '?demcleint',
            '?asgcleint',
            '?vaeatftyp',
            '?atfcleint',
            '?flginfor'
        ]
});
