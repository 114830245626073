import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageGs0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0013Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0013 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'fiadsc', largeur: 130},
            {nom: 'fianomphy', largeur: 100},
            {nom: 'fiahredeb', largeur: 130},
            {nom: 'fiahrefin', largeur: 130},
            {nom: 'fianbrtrt', largeur: 150},
            {nom: 'fiadurmax', largeur: 150},
            {nom: 'fiastudsc', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('fiadsc'),
            new FormulaireItem('fianomphy'),
            new FormulaireItem('fiahredeb'),
            new FormulaireItem('fiahrefin'),
            new FormulaireItem('fianbrtrt'),
            new FormulaireItem('fiadurmax'),
            new FormulaireItem('fiastu')
        ],{largeurDefaut: 20})
    };
}
