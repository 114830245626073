import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCR0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0031Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCR0031 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcleint', {
            }),
            new FormulaireItem('flgincfer', {
                required: true
            })
        ]),
        colonnesVisibles: [
            {nom: 'cclnumdoc'      , largeur:131},
            {nom: 'refcod'         , largeur:89},
            {nom: 'refnom'         , largeur:236},
            {nom: 'ccldatdeb'      , largeur:100},
            {nom: 'ccldatfin'      , largeur:100},
            {nom: 'cclmnt'         , largeur:126},
            {nom: 'cclmntcontaxinc', largeur:126},
            {nom: 'cacnumdocseq'   , largeur:45},
            {nom: 'cacann'         , largeur:47},
            {nom: 'etddsc'         , largeur:131},
            {nom: 'cacmnt'         , largeur:126},
            {nom: 'cacmntcontaxinc', largeur:126}
        ]
    };
}
