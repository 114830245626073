import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageDo2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do2000Controller(
    do2001Route: IRoute,
    Operateur: IOperateurService) {
    const vm: IPageDo2000 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: do2001Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'xfdflggen',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            }
        ],
        colonnesVisibles: [
            { nom: 'xfdnumcha', largeur: 100 },
            { nom: 'xfdficsou', largeur: 250 },
            { nom: 'datcre', largeur: 120 },
            { nom: 'usrnomcre', largeur: 130 },
            { nom: 'xfdnbrlig', largeur: 100 },
            { nom: 'xfdnbrerr', largeur: 100 },
            { nom: 'xfdflgval', largeur: 100 },
            { nom: 'xfdflggen', largeur: 100 }
        ]
    };
}
