import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMr0065 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0065Controller(Formulaire: IFormulaireClass,
                                         mr0066Route: IRoute,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass)
{
    const vm: IPageMr0065 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            importation: true
        },
        colonnesFixesDroite:[
            {nom: 'cyrcod', largeur: 140,menu: [new MenuItemForage(
                    mr0066Route.NAME,
                    (rowData: any) => ({ id: rowData.cyrcleint}),
                    {
                        fonction:'BTN_CYCLE'
                    }
                )]},
            {nom: 'cyrdsc', largeur: 275}
        ],
        colonnesVisibles: [
            {nom: 'ttccod', largeur: 95},
            {nom: 'tytcod', largeur: 160},
            {nom: 'tytdsc', largeur: 236},
            {nom: 'ttcdsc', largeur: 275},
            {nom: 'ttcflgact', largeur: 100},
            {nom: 'ttcflgcen', largeur: 100},
            {nom: 'ttcflgsecfin', largeur: 100},
            {nom: 'ttcflgsecgrh', largeur: 100},
            {nom: 'ttcflgdef', largeur: 100},
            {nom: 'ttcflggenaut', largeur: 100},
            {nom: 'ttccom', largeur: 600}
        ],
        colonnesCachees: [
            'cyrcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tytcleint',{largeur:25, modifAllow: false}),
            new FormulaireItem('ttccod',{largeur:25}),
            new FormulaireItem('ttcdsc',{largeur:50}),
            new FormulaireItem('ttcflgact',{largeur:25}),
            new FormulaireItem('ttcflgdef',{largeur:25}),
            new FormulaireItem('ttcflgcen',{largeur:25}),
            new FormulaireItem('ttcflgsecfin',{largeur:25}),
            new FormulaireItem('ttcflgsecgrh',{largeur:25}),
            new FormulaireItem('ttcflggenaut',{largeur:25}),
            new FormulaireItem('ttccom',{enableCount: true,largeur:100})
        ])
    };
}
