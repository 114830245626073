import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";

interface IPageMC0085 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0085Controller(mc0173Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu:IMenuClass) {
    const vm: IPageMC0085 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0173Route.NAME)
        ]),
        navigatePage: mc0173Route.NAME,
        colonnesVisibles: [
            {nom: 'fmlcod', largeur: 90},
            {nom: 'vaefmlpal_desc', largeur: 147},
            {nom: 'fmldsc', largeur: 404},
            {nom: 'fmlnumrelpro', largeur: 115}
        ]
    };
}
