import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from 'angular';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageDB0028 extends IComponentController {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Db0028Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    isForageValide: IIsForageValideService,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageDB0028 = this;
    vm.valeursEcran = ecranDetails.params;
    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_redcleint', { required: true }),
            new FormulaireItem('t_cifcleint'),
            new FormulaireItem('peccleint', { required: true }),
            new FormulaireItem('t_flgtrf'),
            {
                largeurDefaut: 25
            }
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {
                nom: 'docaffnumdoc01',
                largeur: 154, menu: [getForageItem()]
            }
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 75 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'docaffdat01', largeur: 100 },
            { nom: 'docaffmnt01', largeur: 126 },
            { nom: 'trbmntcum', largeur: 126 },
            { nom: 't_imp', largeur: 339 },
            { nom: 'etddsc', largeur: 147 },
            { nom: 't_reception', largeur: 105 },
            { nom: 'docaffdsc01', largeur: 307 },
            { nom: 'docrefcod02', largeur: 130 },
            { nom: 'docrefnom02', largeur: 252 }
        ],
        colonnesCachees: [
            'ecrcod',
            'docaffcleint01'
        ]
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcod}.ecran`),
            (data: any) => ({
                id: data.docaffcleint01
            }),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('db0028', data.ecrcod);
                }
            }
        )
    }
}
