import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGa0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ga0008Controller(ga0007Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGa0008 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: ga0007Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                doccleint: rowData.doccleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(ga0007Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'garnumdoc', largeur: 150},
            {nom: 'garnumdocseq', largeur: 110},
            {nom: 'tydcod', largeur: 110},
            {nom: 'gardsc', largeur: 250},
            {nom: 'intcodreq', largeur: 140},
            {nom: 'intnomreq', largeur: 190},
            {nom: 'gardat', largeur: 110},
            {nom: 'etddsc', largeur: 190},
            {nom: 'el2cod', largeur: 130},
            {nom: 'el2dsc', largeur: 190},
            {nom: 'garmnt', largeur: 120}
        ]
    };
}
