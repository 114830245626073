import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageCO0011 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireScl: IFormulaire;
}

interface ICo0011Params {
    id: string;
    idecleint: number;
    menuId: string;
    stamnetab: string;
    idecleintref: number;
    comcleint: number;
    stamnetabcor: string;
    corcleintref: number;
}

/* @ngInject */
export default function Co0011Controller($stateParams: ICo0011Params,
                                         Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageCO0011 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireScl = new Formulaire('LBL_SCOLARITE', [
            new FormulaireItem('ittcleint'),
            new FormulaireItem('scldscitt'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dipcleint'),
            new FormulaireItem('scldscdip'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('scldscprg'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('scldsccyc'),

            new FormulaireItemDivider('LBL_DETAIL'),
            new FormulaireItem('scldipnbrcre'),
            new FormulaireItem('sclcom'),

            new FormulaireItemDivider('LBL_RH'),
            new FormulaireItem('scldatrecrh'),
            new FormulaireItem('sclflgverrh'),
            new FormulaireItem('sclcomverrh'),
            new FormulaireItem('idecleint', {
                hidden: true,
                default: $stateParams.idecleint
            }),
        ]);
    }
}
