import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRE2001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;    
}

/* @ngInject */
export default function Re2001Controller(Menu: IMenuClass,                                        
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRE2001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        
        colonnesVisibles: [
            {nom: 'tagcodori', largeur: 100},
            {nom: 'tagdscabrori', largeur: 363},
            {nom: 'tagcodret', largeur: 100},
            {nom: 'tagdscabrret', largeur: 363}
        ]
    };
}
