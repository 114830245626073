import { module, IPromise } from 'angular';
import IResourceService = angular.resource.IResourceService;
import { IApiConfig } from '../../../core/interfaces/api-config.interface';
import { IParametresSecuriteService } from '../../../core/services/parametres-securite.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMonoOccurrence } from '../../../core/services/mono-occurrence.service';
import { INotificationHandler } from '../../../core/services/utils/notification-handler.service';
import { IEcranDetailsResourcesClass, IEcranDetailsResourcesEntite } from '../../../core/resources/ecran-details.resource';
import { IMenuItemEcranClass } from '../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../core/interfaces/route.interface';

export interface IFonctionTransversaleFormulaire {
    initListeTraitementsPossible(monoOccurrence: IMonoOccurrence, ecrcleint: number): IPromise<void>;
    selectionnerTraitement(ecrcleint: number, srccod: string): void;
    lancerTraitement(fom: IFom): void;
    dataList: { [key: number]: Array<any> };
}

interface IParametresTraitementLot {
    fopcleint: number;
    fomcleint: number;
    foptypsai_vrai: TypeParam;
    fopvalsai_vrai: any;
    foptyptra_vrai: TypeParam;
    fopvaltra_vrai: any;
}

interface IFom {
    fomcleint: number;
    trlcleint: number;
    trlcod: string;
    ecrcleint: number;
    srccod: string;
}

enum TypeParam {
    Champ = 'C',
    Texte = 'T'
}

export default module('core.services.fonctionTransversaleFormulaire', []).factory('FonctionTransversaleFormulaire', FonctionTransversaleFormulaireFactory);

/* @ngInject */
function FonctionTransversaleFormulaireFactory($resource: IResourceService,
                                                ApiConfig: IApiConfig,
                                                parametresSecurite: IParametresSecuriteService,
                                                $stateParams: IStateParamsService,
                                                notificationHandler: INotificationHandler,
                                                EcranDetailsResource: IEcranDetailsResourcesClass,
                                                MenuItemEcran: IMenuItemEcranClass,
                                                gs0115Route: IRoute,
                                                ecranDetails: IEcranDetailsResourcesEntite): IFonctionTransversaleFormulaire {

    class FonctionTransversaleFormulaire {
        private monoOccurrence: IMonoOccurrence;
        private ecrcleintGS0115: number;

        public dataList: { [key: number]: Array<any> } = {};

        public initListeTraitementsPossible(monoOccurrence: IMonoOccurrence, ecrcleint: number) {
            this.monoOccurrence = monoOccurrence;

            return this.fetchEcranDetails('gs0115-00-2').then((ecranDetails: IEcranDetailsResourcesEntite) => {
                this.ecrcleintGS0115 = ecranDetails.ecrcleint
                const gs0115Resource = $resource(`${ApiConfig.ROOT}/gs0115/`, {
                    ...parametresSecurite($stateParams),
                    ecrcleint: this.ecrcleintGS0115
                });

                return gs0115Resource.query({
                    ecrcleintpar: ecrcleint,
                    srccodpar: monoOccurrence.srccod
                }).$promise.then((result: any) => {
                    this.dataList[ecrcleint] = result;
                }).catch(this.showNotificationError);
            });
        }

        public selectionnerTraitement(ecrcleint: number, srccod: string) {
            if (!this.dataList[ecrcleint]) {
                this.showNotificationError('G_MSG_ERREUR_ACTION');
            } else if (this.dataList[ecrcleint].length === 1) {
                this.lancerTraitement(this.dataList[ecrcleint][0]);
            } else {
                new MenuItemEcran(
                    'G_LBL_FONC_TRANS_FORMULAIRE',
                    gs0115Route.NAME,
                    (data: any) => ({
                        ecrcleintpar: ecrcleint,
                        srccodpar: srccod,
                        fonctionTransversaleFormulaire: { lancerTraitement: this.lancerTraitement }
                    }),
                    {
                        icon: 'print',
                        iconButton: false
                    }
                ).action();
            }
        }

        public lancerTraitement(fom: IFom) {
            this.getParametresTraitementLot(fom).then((params) => {
                const gs0115Resource = $resource(`${ApiConfig.ROOT}/gs0115/lancer-traitement/:id`, {
                    ...parametresSecurite($stateParams),
                    ecrcleint: this.ecrcleintGS0115,
                    medcleintref: fom.trlcleint,
                    cleint: this.monoOccurrence.data[this.monoOccurrence.cleint]
                });

                gs0115Resource.save({
                    id: fom.fomcleint,
                    fomcleint: fom.fomcleint,
                    ecrcleintpar: fom.ecrcleint,
                    srccodpar: fom.srccod
                }, params).$promise
                    .then((result: any) => {
                        const msg = result.msg;

                        if (!result.typmsg) {
                            notificationHandler.succes(msg);
                        } else if (result.typmsg === 'I') {
                            notificationHandler.info({
                                lblMessage: msg
                            });
                        } else if (result.typmsg === 'A') {
                            notificationHandler.avertissement({
                                lblMessage: msg
                            });
                        } else if (result.typmsg === 'E') {
                            notificationHandler.erreur({
                                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                                lblMessage: msg
                            });
                        }
                    })
                    .catch(this.showNotificationError);
            }).catch(this.showNotificationError);
        }

        private fetchEcranDetails(ecrcod: string) {
            const menuId = $stateParams.menuId || '';
            const [pracleint, menucleref] = menuId.split('-');

            const params = {
                ecrcod,
                pracleint,
                menucleref
            };

            return EcranDetailsResource.get(params).$promise;
        }

        private showNotificationError(error: any) {
            if (Array.isArray(error.data)) {
                error.data = error.data[0];
            }

            notificationHandler.erreur({
                error,
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage: 'G_MSG_ERREUR_ACTION'
            });
        }

        private getParametresTraitementLot(fom: IFom) {
            const gs0115ParamsResource = $resource(`${ApiConfig.ROOT}/gs0115/${fom.fomcleint}/fop/`, {
                ...parametresSecurite($stateParams),
                ecrcleint: this.ecrcleintGS0115
            });

            return gs0115ParamsResource.query({
                ecrcleintpar: fom.ecrcleint,
                srccodpar: fom.srccod,
                fomcleint: fom.fomcleint
            }).$promise.then((params: any) => {
                for (const param of params as IParametresTraitementLot[]) {
                    if (param.foptypsai_vrai === TypeParam.Champ) {
                        param.fopvalsai_vrai = this.getValeurChamp(param.fopvalsai_vrai);
                    }

                    if (param.foptyptra_vrai === TypeParam.Champ) {
                        param.fopvaltra_vrai = this.getValeurChamp(param.fopvaltra_vrai);
                    }
                }

                return params;
            });
        }

        private getValeurChamp(valParam: string): any {
            const champs = valParam.split(' || ');

            let valeurFinale = '';

            for (const champ of champs) {
                if (champ[0] === '\'') {
                    valeurFinale += champ.substring(1, champ.length - 1);
                } else {
                    const nomsChamps = champ.split('.');

                    let valeur: { [key: string]: any } = {};

                    if (nomsChamps.shift() === 'valeursEcran') {
                        valeur = ecranDetails.valeurs;
                    } else {
                        valeur = this.monoOccurrence.data;
                    }

                    for (const nomChamp of nomsChamps) {
                        valeur = valeur[nomChamp];
                    }

                    // Important de mettre == et pas === car on veut gérer le cas du null
                    if (valeur != undefined) {
                        valeurFinale += valeur.toString().replace('T00:00:00', '');
                    }
                }
            }

            return valeurFinale;
        }
    }

    return new FonctionTransversaleFormulaire();
}
