import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0063 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0063Controller(gs0015Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0063 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0015Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0015Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'trlcod', largeur: 120},
            {nom: 'trldsc', largeur: 320},
            {nom: 'srccod', largeur: 110},
            {nom: 'srcdsc', largeur: 320},
            {nom: 'trldscmen', largeur: 320},
            {nom: 'frmdsc', largeur: 173},
            {nom: 'trltypficimp', largeur: 64},
            {nom: 'trlflgpub', largeur: 62},
            {nom: 'trlflgcyc', largeur: 60},
            {nom: 'trlflgsui', largeur: 65},
            {nom: 'clscod', largeur: 89},
            {nom: 'clsnom', largeur: 315},
            {nom: 'vaeclstyp', largeur: 89}
        ]
    };
}
