import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageCp2015 extends IComponentController {
    formulaireReq: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    driMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dovMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dopMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp2015Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    mc0102Route: IRoute,
    mc0044Route: IRoute,
    cp2014Route: IRoute,
    cp2002Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCp2015 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            selectionCycle: true
        },
        infosMonetaires: {
            blocs: ['dov'],
            afficherAuBas: "oui",
            donnees:
                [
                    { nom: 'mntreq', libelle: 'LBL_MONTANT' },
                    { nom: 'sldvnt', libelle: 'LBL_MNTVNT' },
                    { nom: 'mntpay', libelle: 'LBL_MNTPAY' },
                    { nom: 'sldpay', libelle: 'LBL_SLDPAY', lastOfGroup: true  },
                    { nom: 'devise', libelle: 'LBL_DEVISE' }
                ]
        },
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        },
        menus: [
            new MenuItemForage(
                'G_LBL_COR_IMP',
                mc0102Route.NAME,
                (data: any) => ({ doccleintref: data.doccleint }),
                {
                    icon: 'settings',
                    compteur: (data: any) => data.aut__nbraju > 0 ? data.aut__nbraju : undefined,
                    iconButton: false,
                    fonction: 'BOUCORIMP',
                    disabled: (data: any) => !data.$id || ecranDetails.valeurs.flgre
                }
            ),
            new MenuItemForage(
                'LBL_PIJ_DEP',
                cp2002Route.NAME,
                (data: any) => ({ doccleint: data.doccleint }),
                {
                    icon: 'attach_file',
                    iconButton: false,
                    fonction: 'BOUPIJDEP',
                    secondaire: true,
                    hidden: () => !(vm.valeursEcran.flgpjdep)
                }
            )
        ]
    };

    vm.formulaireReq = new Formulaire('LBL_BLC_REQ', [
        new FormulaireItem('refcleint'),
        new FormulaireItem('radcleint'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('reqnumdoc'),
        new FormulaireItem('reqnumdocseq'),
        new FormulaireItem('reqflgpij'),
        new FormulaireItem('tydcleint'),
        new FormulaireItem('reqindpaiuni'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('reqnumref'),
        new FormulaireItem('peccleint'),
        new FormulaireItem('reqdat'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('intcleintreq'),
        new FormulaireItem('intcleintdem'),
        new FormulaireItem('reqdsc', { enableCount: true, largeur: 100 }),
        new FormulaireItem('reqdatdep'),
        new FormulaireItem('reqdatret'),
        new FormulaireItem('reqnbrjrsext'),
        new FormulaireGroupeAccordeon('LBL_AUTRES_DETAILS', new Formulaire([
            new FormulaireItem('lcpcleint'),
            new FormulaireItem('vecreqcat'),
            new FormulaireItem('empcleintdep'),
            new FormulaireItem('mopcleint'),
            new FormulaireItem('reqdestination'),
            new FormulaireItem('reqvoyageur'),
            new FormulaireItem('aut__lotcleint'),
            new FormulaireItem('reqnumrescd'),
            new FormulaireItem('reqnumresca')
        ], { largeurDefaut: 33.33 })),
    ], { largeurDefaut: 33.33 });

    vm.driMultiOccurrenceOptions = {
        bloc: 'dri',
        colonnesVisibles: [
            { nom: 'docaffnumdoc01', largeur: 140, menu: [
                new MenuItemForage(
                    '', 
                    cp2014Route.NAME, 
                    (rowData: any) => ({ 
                        id: rowData.docaffcleint01 }),
                    {
                    icon: 'launch',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'BOUAVA'
                })
            ] },
            { nom: 'docaffdsc01', largeur: 180 },
            { nom: 'tydcod', largeur: 100 },
            { nom: 'docaffsysboncmd01', largeur: 140 },
            { nom: 'docsldcou', largeur: 126 },
            { nom: 'drimntutl', largeur: 126 },
        ],
        colonnesFixesDroite: [
            { nom: 'driflgfer', largeur: 126 },
            { nom: 'inddis', largeur: 126 }
        ],
        colonnesCachees: ['docaffcleint01']
    };

    vm.dovMultiOccurrenceOptions = {
        bloc: 'dov',
        colonnesFixesGauche: [
            { nom: 'indrefint', largeur: 100 },
            { nom: 'dovimp', largeur: 300, menu: [
                new MenuItemEcran(
                    '', 
                    mc0044Route.NAME, 
                    (rowData: any) => ({ 
                        id: rowData.prucleint, 
                        prucleint: rowData.prucleint }),
                    {
                    icon: 'launch',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'BOUUBR'
                })
            ] },
            { nom: 'dovmnt', largeur: 126 }
        ],
        colonnesVisibles: [
            { nom: 'taxcod', largeur: 100 },
            { nom: 'remcod', largeur: 100 },
            { nom: 'dovnumrefint', largeur: 160 },
            { nom: 'dovmnttaxfed', largeur: 126 },
            { nom: 'dovmnttaxpro', largeur: 126 },
            { nom: 'dovmntremfed', largeur: 126 },
            { nom: 'dovmntrempro', largeur: 126 },
            { nom: 'dovmntdepnet', largeur: 126 },
            { nom: 'dovqte', largeur: 126 },
            { nom: 'unmcod', largeur: 120 },
            { nom: 'unmdsc', largeur: 180 },
            { nom: 'dovdsc', largeur: 180 },
            { nom: 'dovmntnetutl', largeur: 126 }
        ],
        colonnesFixesDroite: [
            { nom: 'dovflgref', largeur: 120 },
            { nom: 'dovflgmsg', largeur: 120 }
        ],
        colonnesCachees: [ 'prucleint' ]
    };

    vm.dopMultiOccurrenceOptions = {
        bloc: 'dop',
        colonnesFixesGauche: [
            {nom: 'dopnumlig', largeur: 100},
            {nom: 'dopdat', largeur: 126},
            {nom: 'dopdsc', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'cpacod', largeur: 100},
            {nom: 'vaesysdevcod_dsc', largeur: 100},
            {nom: 'dopprx', largeur: 120},
            {nom: 'dopmntfor', largeur: 120},
            {nom: 'dopqte', largeur: 120},
            {nom: 'dopmnt', largeur: 120},
            {nom: 'dopmntrclcan', largeur: 140},
            {nom: 'dopmntrmbcan', largeur: 140},
            {nom: 'taxcod', largeur: 80},
            {nom: 'remcod', largeur: 80},
            {nom: 'dopmnttaxfed', largeur: 120},
            {nom: 'dopmnttaxpro', largeur: 120},
            {nom: 'dopmntremfed', largeur: 120},
            {nom: 'dopmntrempro', largeur: 120},
            {nom: 'dopmntdepnet', largeur: 120},
            {nom: 'dopcom', largeur: 250},
            {nom: 'dopnot', largeur: 200},
            {nom: 'aapcod', largeur: 100},
            {nom: 'aapdsc', largeur: 180}
        ]
    };
}
