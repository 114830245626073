import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePD0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0011Controller(Menu: IMenuClass) {
    const vm: IPagePD0011 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'barcod', largeur: 200},
            {nom: 'barnumref', largeur: 200},
            {nom: 'bardsc', largeur: 200},
            {nom: 'vecpmiendaut', largeur: 200},
            {nom: 'vecpmiendautdsc', largeur: 200},
            {nom: 'bardatouv', largeur: 200},
            {nom: 'bardateli', largeur: 200},
            {nom: 'bardatprveli', largeur: 200},
            {nom: 'tybcod', largeur: 200},
            {nom: 'tybdsc', largeur: 200},
            {nom: 'barqtecaptot', largeur: 200},
            {nom: 'barqterestot', largeur: 200},
            {nom: 'barqtepoires', largeur: 200}
        ]
    };
}
