import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
interface IPageMc2044 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function Mc2044Controller(
    Menu: IMenuClass,
    cp2010Route: IRoute,
    gl0003Route: IRoute,
    mc0109Route: IRoute,
    db0005Route: IRoute,
    cr0013Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const cp0014Route = { NAME: 'secure.accueil' };
    const gl0020Route = { NAME: 'secure.accueil' };
    const cp0029Route = { NAME: 'secure.accueil' };
    const in0014Route = { NAME: 'secure.accueil' };
    const in0043Route = { NAME: 'secure.accueil' };
    const cp0040Route = { NAME: 'secure.accueil' };
    const cr0016Route = { NAME: 'secure.accueil' };
    const cp0018Route = { NAME: 'secure.accueil' };
    const af0001Route = { NAME: 'secure.accueil' };
    const ac0070Route = { NAME: 'secure.accueil' };
    const vm: IPageMc2044 = this;
    
        vm.menuBandeau = new Menu([]);
        vm.multiOccurrenceOptions = {
            fonctions: {
                suiviModification: true,
                edition: false,
                supprime: false,
                exportation: false,
                selectionCycle: false
            },
            navigatePage: (data: any) => getForage(data),
            navigateParams: (data: any) => getParams(data),
            colonnesVisibles: [
                { nom: 'docaffnumdoc01', largeur: 300 },
                { nom: 'tydcod', largeur: 120,
                    menu: [
                        new MenuItemForage(
                            'LBL_FORAGE_CP2011',
                            cr0013Route.NAME,
                            (rowData: any) => {
                                return {
                                    id: rowData.doccleint,
                                };
                            },
                            {
                                hidden: (data:any) => 0
                            }
                        )
                    ]
                 }
            ],
            colonnesCachees: ['ecrcod', 'docaffcleint01']
        };
        function getForage(data: any): string {
            if (data.ecrcod === 'GL0020-00') {
                return gl0020Route.NAME;
            } else if (data.ecrcod === 'AC0070-00') {
                return ac0070Route.NAME;
            } else if (data.ecrcod === 'CP0029-00') {
                return cp0029Route.NAME;
            } else if (data.ecrcod === 'IN0014-00') {
                return in0014Route.NAME;
            } else if (data.ecrcod === 'CP0014-00') {
                return cp0014Route.NAME;
            } else if (data.ecrcod === 'DB0005-00') {
                return db0005Route.NAME;
            } else if (data.ecrcod === 'IN0043-00') {
                return in0043Route.NAME;
            } else if (data.ecrcod === 'MC0109-00') {
                return mc0109Route.NAME;
            } else if (data.ecrcod === 'CP0040-00') {
                return cp0040Route.NAME;
            } else if (data.ecrcod === 'CR0016-00') {
                return cr0016Route.NAME;
            } else if (data.ecrcod === 'GL0003-00') {
                return gl0003Route.NAME;
            } else if (data.ecrcod === 'CP0018-00') {
                return cp0018Route.NAME;
            } else if (data.ecrcod === 'CP0013-00') {
                return cp2010Route.NAME;
            } else if (data.ecrcod === 'AF0001-00') {
                return af0001Route.NAME;
            }
        }
        function getParams(data: any): any {
            if (data.ecrcod === 'CP0013-00') {
                { id: data.docaffcleint01 }
            }
            return { id: data.doccleint };
        }
    
}
