import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePF0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0017Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         pf0018Route: IRoute) {
    const vm: IPagePF0017 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pf0018Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(pf0018Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'suanumseq', largeur: 150},
            {nom: 'suacod', largeur: 115},
            {nom: 'suadsc', largeur: 338},
            {nom: 'suadatdeb', largeur: 100},
            {nom: 'suadatfin', largeur: 100},
            {nom: 'vaesuamod_dsc', largeur: 193},
            {nom: 'suaflgact', largeur: 100}
        ]
    };
}
