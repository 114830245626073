import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageTf0019 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceTflOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0019Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass
) {
    const vm: IPageTf0019 = this;
    vm.multiOccurrenceTflOptions = {
        bloc: 'tfl',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 30,
            nbElementsPossibles: [30, 60]
        }),
        colonnesVisibles: [
            { nom: 'opevaedsc', largeur: 110 },
            { nom: 'typvaedsc', largeur: 200 },
            { nom: 'epovaedsc', largeur: 110 },
            { nom: 'modvaedsc', largeur: 200 },
            { nom: 'tflcod', largeur: 110 },
            { nom: 'cotcod', largeur: 140 },
            { nom: 'tfldsc', largeur: 250 },
            { nom: 'cotdsc', largeur: 250 },
        ],
        formulaire: new Formulaire(
            [
                new FormulaireItem('vaectrope'),
                new FormulaireItem('vaetfltyp'),
                new FormulaireItem('vaetflepo'),
                new FormulaireItem('vaetflmod'),
                new FormulaireItem('tflcleintref', {
                    resourceParams: (rowData:any) => (
                        {
                            vaetfltyp: rowData.vaetfltyp
                        })
                }),
                new FormulaireItem('cotcleint'),
            ],
            { largeurDefaut: 33 }
        ),
    };
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            pagination: true,
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),        
        colonnesVisibles: [
            { nom: 'tfhann', largeur: 110 },
            { nom: 'fpncod', largeur: 150 },
            { nom: 'fpndsc', largeur: 300 },
            { nom: 'tfkcod', largeur: 110 },
            { nom: 'tfkdsc', largeur: 300 }
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceTflOptions
    };
}
