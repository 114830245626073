import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0307 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0307Controller(mr0126Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0307 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0126Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0126Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'unpcod', largeur: 110 },
            { nom: 'unpdsc', largeur: 250 },
            { nom: 'percod', largeur: 110 },
            { nom: 'percodtth', largeur: 110 },
            { nom: 'unpflgdef', largeur: 110 },
            { nom: 'unpflgact', largeur: 110 },
            { nom: 'vaesyscodprv_dsc', largeur: 150 },
            { nom: 'rfecodfedreg', largeur: 250 },
            { nom: 'rfenomabrfedreg', largeur: 250 },
            { nom: 'rfecodfedred', largeur: 250 },
            { nom: 'rfenomabrfedred', largeur: 250 },
            { nom: 'rfecodfednonres', largeur: 250 },
            { nom: 'rfenomabrfednonres', largeur: 250 },
            { nom: 'rfecodprv', largeur: 250 },
            { nom: 'rfenomabrprv', largeur: 250 },
            { nom: 'rfecodcss', largeur: 250 },
            { nom: 'rfenomabrcss', largeur: 250 },
            { nom: 'unpetacod', largeur: 125 },
            { nom: 'ptxnument', largeur: 175 },
            { nom: 'lipcod', largeur: 175 },
            { nom: 'lipnomabr', largeur: 250 }
        ]
    };
}
