import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRr0107 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
    detaviMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0107Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageRr0107 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('demcleint', {
                    required: true,
                    default: $stateParams.employe,
                    hidden:  $stateParams.employe,
                    ignoreEtat: $stateParams.employe}),
                new FormulaireItem('unpcleint', { required: true }),
                new FormulaireItem('colcleintqte', { required: true }),
                new FormulaireItem('colcleintmnt', { required: true })
            ]), { largeur: 80 }),
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('datdebsel', { required: true }),
                new FormulaireItem('datfinsel', { required: true })
            ]), { largeur: 20 })
        ]), fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'ppaannper'   , largeur: 110},
            {nom: 'dppdatdebsem', largeur: 130},
            {nom: 'dppdatfinsem', largeur: 110},
            {nom: 'dppqte'      , largeur: 90, total: true},
            {nom: 'dppmnt'      , largeur: 140, total: true},
            {nom: 'dppqteori'   , largeur: 140, total: true},
            {nom: 'dppmntori'       , largeur: 140, total: true},
            {nom: 'dppqterep'       , largeur: 140, total: true},
            { nom: 'dppmntrep'       , largeur: 140, total: true}
        ],
        colonnesCachees: ['ppacleint', 'dppnumsem', 'detsemdebfin','detavisemdebfin']
    };

    vm.detMultiOccurrenceOptions = {
        bloc: 'det',
        resourceParamsDynamique: () => ({
            ppacleint: vm.multiOccurrence && vm.multiOccurrence.getDataActiveRow().ppacleint,
            dppnumsem: vm.multiOccurrence && vm.multiOccurrence.getDataActiveRow().dppnumsem,
        }),
        filtres: [
            {
                colonne: 'ppaannpertrt',
                valeur: () => vm.multiOccurrence && vm.multiOccurrence.getDataActiveRow().ppaannper,
                affichage: true,
                readOnly: true
            },
            {
                colonne: 'detsemdebfin',
                valeur: () => vm.multiOccurrence && vm.multiOccurrence.getDataActiveRow().detsemdebfin,
                affichage: true,
                readOnly: true
            }
        ],
        colonnesFixesGauche:[
            {nom: 'dppdatdeb'   , largeur: 130},
            {nom: 'dppdatfin'   , largeur: 110},
            {nom: 'tagdscabr'   , largeur: 278}
        ],
        colonnesVisibles: [
            {nom: 'dppqte'      , largeur: 140},
            {nom: 'dppmnt'      , largeur: 140},
            {nom: 'dppqteori', largeur: 140},
            {nom: 'dppmntori', largeur: 140},
            {nom: 'dppqterep', largeur: 140},
            {nom: 'dppmntrep', largeur: 140},
            {nom: 'ppaannpertrt', largeur: 140}
        ]
    };
}
