import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageMc0038 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireInt: IFormulaire;
    formulaireSir: IFormulaire;
    inpMultiOccurrence: IMultiOccurrence;
    inpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    inmMultiOccurrence: IMultiOccurrence;
    inmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    inuMultiOccurrence: IMultiOccurrence;
    inuMultiOccurrenceOptions: IMultiOccurrenceOptions;
    iprMultiOccurrence: IMultiOccurrence;
    iprMultiOccurrenceOptions: IMultiOccurrenceOptions;
    inaMultiOccurrence: IMultiOccurrence;
    inaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0038Controller(mc0025Route: IRoute,
                                         mc0039Route: IRoute,
                                         mc0046Route: IRoute,
                                         Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMc0038 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire('LBL_BLC_INT', [
                new FormulaireItem('intcod', { largeur: 30 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('empcleint', {
                    largeur: 20,
                    resourceParams: (data: any) => ({ empcleint: data.empcleint }),
                    modelOptions: {
                        updateOn: 'blur'
                    }
                }),
                new FormulaireItem('intnom', { largeur: 40 }),
                new FormulaireItem('intflggesmannom', { largeur: 30 }),
                new FormulaireItemDivider('LBL_SECURITE'),
                new FormulaireItem('intflgsecpru', { largeur: 33 }),
                new FormulaireItem('intflgsecuna', { largeur: 33 }),
                new FormulaireItem('intflgsecmag', { largeur: 33 }),
                new FormulaireItemDivider('LBL_APPROBATEUR'),
                new FormulaireItem('intflgreq', { largeur: 33 }),
                new FormulaireItem('intflgapr', { default: 0, largeur: 33 }),
                new FormulaireItem('intflgaprint', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('intflgappreq', { largeur: 33 }),
                new FormulaireItemDivider('LBL_FONCTION'),
                new FormulaireItem('intflgrsp', { largeur: 33 }),
                new FormulaireItem('intflgche', { largeur: 33 }),
                new FormulaireItem('intflgpar', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('intflgach', { largeur: 33 }),
                new FormulaireItem('intflgachpri', { largeur: 33 }),
                new FormulaireItem('intflgdem', { largeur: 33 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('intflgmag', { largeur: 33 }),
                new FormulaireItem('intflggesmanapp', { largeur: 33 }),
                new FormulaireItem('intflgintsys', { largeur: 33 })
            ], { largeurDefaut: 33 })
        }

        // Génération UBR
        vm.boutonGen = new MenuItemAction(
            'LBL_BTN_GEN',
            'BOUGEN',
            () => ({}),
            {
                icon: 'sync',
                iconButton: false,
                saveFirst: true,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_TITRE_GEN',
                    lblConfirm: 'G_LBL_BTN_EXECUTER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItem('responsable', { largeur: 100 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('ubrdeb', { largeur: 50 }),
                        new FormulaireItem('ubrfin', { largeur: 50 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('cifdeb', { largeur: 50 }),
                        new FormulaireItem('ciffin', { largeur: 50 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('unadeb', { largeur: 50 }),
                        new FormulaireItem('unafin', { largeur: 50 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('fondeb', { largeur: 50 }),
                        new FormulaireItem('fonfin', { largeur: 50 })
                    ])
                }
            }
        );

        // Usage d'imputation
        vm.boutonUsage = new MenuItemEcran(
            'LBL_BLC_IMP',
            mc0046Route.NAME,
            () => {
                return {
                    vaeusient: 'INT',
                    uvicleintref: vm.monoOccurrence.data.intcleint
                };
            },
            {
            icon: 'view_list',
            iconButton: false,
            secondaire: true,
            disabled: () => !vm.monoOccurrence.data.intflgreq
            }
        );

        // Hiérarchie d'approbation
        vm.boutonHierar = new MenuItemForage(
            'LBL_BLC_IAP',
            mc0025Route.NAME,
            (data: any) => {
                return {
                    id: vm.monoOccurrence.data.intcleint
                };
            },
            {
                icon: 'supervisor_account',
                iconButton: false,
                secondaire: true,
                disabled: () => !vm.monoOccurrence.data.intflgreq
            }
        );

        // Bloc UBR
        vm.inpMultiOccurrenceOptions = {
            bloc: 'inp',
            colonnesVisibles: [
                { nom: 'prucod',    largeur: 150 },
                { nom: 'prudscl01', largeur: 350 },
                { nom: 'inpflggen', largeur: 200 },
                { nom: 'inpflgsai', largeur: 200 },
                { nom: 'inpnbrref', largeur: 200 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('prucleint', { largeur: 30 }),
                new FormulaireItem('inpflgsai', { largeur: 20 }),
                new FormulaireItem('inpnbrref', { largeur: 0 , default: 1 })
            ]),
            actionsMoreLigne: new Menu([
                new MenuItemAction(
                    'LBL_BTN_SUP_UBR',
                    'BOUSUPUBR',
                    (data:any) => ({
                        inpcleint: data.inpcleint,
                        inpflggen: data.inpflggen,
                        inpnbrref: data.inpnbrref
                    }),
                    {
                        icon: 'delete',
                        successAction: () => { vm.inpMultiOccurrence.fetchDataList() }

                    }
                )
            ]),
            fonctions: {
                importation: true
            }
        };

        // Bloc Automatisme UBR
        vm.inaMultiOccurrenceOptions = {
            bloc: 'ina',
            colonnesVisibles: [
                { nom: 'intcod',    largeur: 150 },
                { nom: 'intnom',    largeur: 250 },
                { nom: 'unacod',    largeur: 150 },
                { nom: 'unadsc',    largeur: 250 },
                { nom: 'cifcod',    largeur: 150 },
                { nom: 'cifdsc',    largeur: 250 },
                { nom: 'inaflgclo',    largeur: 150 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('intcleintrsp', { largeur: 25 }),
                new FormulaireItem('unacleintmanbdg', { largeur: 25 }),
                new FormulaireItem('cifcleintmanbdg', { largeur: 25 })
            ]),
            fonctions: {
                importation: true
            }
        };

        // Attribution sécurité
        vm.iprMultiOccurrenceOptions = {
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_BTN_NOUVEAU',
                    mc0039Route.NAME,
                    () => ({
                        intcleint: vm.monoOccurrence.data.intcleint
                    }),
                    {
                        onClose() {
                            vm.iprMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'NO_LABEL',
                    mc0039Route.NAME,
                    (rowData: any) => ({
                        id: rowData.iprcleint,
                        intcleint: vm.monoOccurrence.data.intcleint
                    }),
                    {
                        icon: 'launch',
                        onClose() {
                            vm.iprMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            bloc: 'ipr',
            colonnesVisibles: [
                { nom: 'iprcod',    largeur: 100 },
                { nom: 'iprdsc',    largeur: 250 },
                { nom: 'iprflgaut', largeur: 100 },
                { nom: 'iprflgclo', largeur: 100 }
            ],
            colonnesCachees: [
                'iprcleint'
            ],
            fonctions: {
                pagination: true
            }
        };


        // Bloc UA
        vm.inuMultiOccurrenceOptions = {
            bloc: 'inu',
            colonnesVisibles: [
                { nom: 'unacod', largeur: 90 },
                { nom: 'unadsc', largeur: 250 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('unacleint', { largeur: 60 })
            ]),
            fonctions: {
                pagination: true,
                importation: true
            }
        };

        // Bloc Magasin
        vm.inmMultiOccurrenceOptions = {
            bloc: 'inm',
            colonnesVisibles: [
                { nom: 'magcod', largeur: 90 },
                { nom: 'magnom', largeur: 250 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('magcleint', { largeur: 100 })
            ]),
            fonctions: {
                pagination: true,
                importation: true
            }
        };

        // Bloc Information recherche et SIRU
        vm.formulaireSir = new Formulaire('LBL_BLC_SIR', [
            new FormulaireItem('intsrucod', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etbcleint', {
                largeur: 50,
                resourceParams: (data:any) => ({etbcleint: data.etbcleint})
            })
          ]
        );

    }
}
