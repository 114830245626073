// Module
var code = `<ex-mono-occurrence-ecran mono-occurrence-options="vm.monoOccurrenceOptions" largeur-formulaire="65"> <ex-mono-occurrence-ecran-maitre> <ex-card-content> <ex-data-list data-type="vm.monoOccurrence.dataType" largeur="33" cols="['enpnumdoc' ,
                     'tydcoddsc',
                     'enpmnteng',
                     'peccod',
                     'datcre',
                     'datmod',
                     'empcodnom',
                     'intcodnom',
                     'enpdsc']"> </ex-data-list> </ex-card-content> </ex-mono-occurrence-ecran-maitre> <ex-template bloc="env" slot="details" class="p-h-24"> <ex-card-multi-template ex-bloc="dom" libelles="libelles" lbl-titre="LBL_BLC_DOM" multi-occurrence-options="vm.domMultiOccurenceOptions"> <ex-card-multi-template-row> <ex-case> <md-icon ng-class="{'ex-avertissement': data.vaedtstyp === 'AVR', 'ex-erreur': data.vaedtstyp === 'ERR'}"> warning</md-icon> </ex-case> <ex-case largeur="auto" col="dommsg"></ex-case> </ex-card-multi-template-row> </ex-card-multi-template> </ex-template> <ex-mono-occurrence-ecran-bas> <ex-card-multi-advanced ex-bloc="env" lbl-titre="LBL_BLC_ENV" multi-occurrence-options="vm.envMultiOccurrenceOptions"> </ex-card-multi-advanced> </ex-mono-occurrence-ecran-bas> </ex-mono-occurrence-ecran>`;
// Exports
module.exports = code;