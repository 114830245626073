/**
 * Composant représentant un multi-occurrence de analyse (MAN)
 *
 */
import {IComponentOptions, IDirective, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import MultiOccurrenceAnalyseController from './ex-multi-occurrence-analyse.controller';
import {ITemplateService} from '../../../../core/services/template.service';

export default module('app.components.ex-multi-occurrence-analyse', [])
// Double déclaration pour la prise en charge des templates
    .directive('exMultiOccurrenceAnalyse', MultiOccurrenceAnalyseTemplateFactory)
    .component('exMultiOccurrenceAnalyse', MultiOccurrenceAnalyseFactory());

/* @ngInject */
function MultiOccurrenceAnalyseTemplateFactory(template: ITemplateService): IDirective {
    return template.directive;
}

function MultiOccurrenceAnalyseFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            Binding.ICON,
            {
                multiOccurrence: '=?multiOccurrenceOut',
                newOpened: '<?'
            }
        ),
        require: {
            templateCtrl: '?^exTemplate',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: MultiOccurrenceAnalyseController,
        controllerAs: 'vm',
        template: require('./ex-multi-occurrence-analyse.html')
    };
}
