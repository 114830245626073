import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageRr0071 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDas: IFormulaire;
    valeursEcran: any;
}

interface IRr0071Params {
    id: number;
    demcleint: number;
    lemcleint: number;
    aftcleint: number;
    dercleint: number;
    trncleint: number;
}

/* @ngInject */
export default function Rr0071Controller($stateParams: IRr0071Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRr0071 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.formulaireDas = new Formulaire('LBL_BLC_DAS', [
            // Champs qui ne sont pas affichés
            new FormulaireItem('demcleint', {
                default: $stateParams.demcleint,
                hidden : true
            }),
            new FormulaireItem('lemcleint', {
                default: $stateParams.lemcleint,
                hidden : true
            }),
            new FormulaireItem('aftcleint', {
                default: $stateParams.aftcleint,
                hidden : true
            }),
            new FormulaireItem('dercleint', {
                default: $stateParams.dercleint,
                hidden : true
            }),
            new FormulaireItem('trncleint', {
                default: $stateParams.trncleint,
                hidden : true
            }),
            new FormulaireItem('uaccleint', {
                hidden : true
            }),

            // Champs affichés
            new FormulaireItem('avscleint', {
                largeur: 35,
                resourceParams: {
                    dascleint: $stateParams.id,
                    demcleint: $stateParams.demcleint,
                    dercleint: $stateParams.dercleint,
                    aftcleint: $stateParams.aftcleint,
                    lemcleint: $stateParams.lemcleint
                }
            }),
            new FormulaireItem('dasdatvig', {
                default: vm.valeursEcran.aut__datvig

            }),
            new FormulaireItem('dasflgact', {
                default: 1

            }),
            new FormulaireItem('avccleint', {
                largeur: 35
            })

        ], {
            largeurDefaut: 15
        });
    }
}
