import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageRR0034 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0034Controller( rr0031Route: IRoute,
                                          rr0036Route: IRoute,
                                          Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass,
                                          FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                          FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageRR0034 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: (rowData: any) => (rowData.typeavant === 'A') ? rr0031Route.NAME : rr0036Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.trncleintref
            };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('placleint', {
                required: false
            }),
            new FormulaireItem('fpncleint', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint', {
                required: false
            }),
            new FormulaireItem('strcleint', {
                required: false
            }),
            new FormulaireItem('demcleint', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('assreg', {
                default:'ASS',
                required: true
            }),
            new FormulaireItemDivider('LBL_DATE_ADM'),
            new FormulaireItem('datdeb', {
                required: false
            }),
            new FormulaireItem('datfin', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgsdatadm', {
                default: 1,
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgcompl', {
                default: 0,
                required: true
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 100},
            {nom: 'urgdscabr', largeur: 270},
            {nom: 'strcod', largeur: 100},
            {nom: 'strdsc', largeur: 250},
            {nom: 'dscava', largeur: 270},
            {nom: 'numrefava', largeur: 120},
            {nom: 'datadmava', largeur: 140},
            {nom: 'datdebava', largeur: 100},
            {nom: 'datfinava', largeur: 100}
        ],
        colonnesCachees:[
            'typeavant',
            'trncleintref'
        ]
    };
}
