import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IProfil} from '../../../../../core/resources/profil.resource';
import {ICriteresSuggeresOptions} from '../../../../../core/components/ex-criteres-suggeres/ex-criteres-suggeres.controller';
import {IFilterExDate} from '../../../../../core/filters/ex-date.filter';

interface IPageIF0005 extends IComponentController {
    valeursEcran: any;
    dataValidationAffichage: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    disMultiOccurrenceOptions: IMultiOccurrenceOptions;
    disMultiOccurrence: IMultiOccurrence;
    optionsCriteresSuggeres: ICriteresSuggeresOptions;
    menuItem: IMenuItem;
}

interface IIf0005Params {
    vecdistypdis: string;
    disdat: string;
}

/* @ngInject */
export default function If0005Controller($stateParams: IIf0005Params,
                                         if0004Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         profil: IProfil,
                                         exDateFilter: IFilterExDate,
                                         MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageIF0005 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.dataValidationAffichage = {};
        vm.monoOccurrenceOptions = {
            noId: true,
            id: profil.preferences.ciecleint,
            fonctions: {
                suiviModification: false,
                exportation: false,
                afficherMenusDansEntete: true,
                afficherMenusCreation: true
            },
            resourceParams: {
                t_vecdistypdis: $stateParams.vecdistypdis
            },
            menus: [
                new MenuItemAction(
                    'LBL_BOUTON_EXECUTER',
                    'BOUEXECTOUT',
                    () => ({
                        immcleintcri: vm.disMultiOccurrence.etatSelected.criteresSuggeresData.t_immcleint,
                        vecdistypdiscri: vm.disMultiOccurrence.etatSelected.criteresSuggeresData.t_vecdistypdis,
                        disdatcri: vm.disMultiOccurrence.etatSelected.criteresSuggeresData.t_datdis
                    }),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => {
                            vm.disMultiOccurrence.fetchDataList(); }
                    })
            ]
        };

        vm.valeursEcran = ecranDetails.valeurs;

        vm.disMultiOccurrenceOptions = {
            navigatePage: if0004Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    id: rowData.immcleint
                };
            },
            autoFetch: false,
            fonctions: {
                editionRapide: true,
                saveEtatTemporaire: false
            },
            actionsMoreLigne: new Menu([
                new MenuItemAction(
                    'LBL_BOUTON_RENV',
                    'BOUREN',
                    (data: any) => ({ discleint: data.discleint }),
                    {
                        icon: 'unarchive',
                        formulaireConfirmationAction: {
                            lblTitre: 'BTN_RENV_DISPOSITION',
                            lblConfirm: 'BTN_EXEC_RENV',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('t_datren', { required: true })
                            ], {largeurDefaut: 100})
                        },
                        successAction: () => { vm.disMultiOccurrence.fetchDataList(); },
                        hidden: (data: any) => (!data.disdattra)
                    }
                )
            ]),
            actionsSelection: new Menu([
                new MenuItemSelection(
                    'LBL_BOUTON_EXECUTER',
                    'BOUEXECSEUL',
                    {
                        selection: {
                            nom: 'actionsExecuter',
                            lblConfirmationTitre: 'LBL_EXECUTER'
                        },
                        successAction: () => { vm.disMultiOccurrence.fetchDataList(); }
                    }
                ),
                new MenuItemSelection(
                    'LBL_BOUTON_SUPPRIMER',
                    'BOUSUP',
                    {
                        selection: {
                            nom: 'actionsSupprimer',
                            lblConfirmationTitre: 'LBL_BOUTON_SUPPRIMER'
                        },
                        successAction: () => { vm.disMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('t_traitee', {
                    default: 'NON',
                    required: true,
                    largeur: 25
                }),
                new FormulaireItem('t_vecdistypdis', {
                    largeur: 25,
                    default: $stateParams.vecdistypdis,
                    ignoreEtat: !!$stateParams.vecdistypdis}),
                new FormulaireItem('t_immcleint', {largeur: 25}),
                new FormulaireItem('t_datdis', {
                    largeur: 25,
                    default: exDateFilter($stateParams.disdat),
                    ignoreEtat: !!$stateParams.disdat})
            ]),

            editionRapideActive: vm.valeursEcran.affgri,
            colonnesVisibles: [
                {nom: 'immcod'          , largeur: 140},
                {nom: 'immdsc'          , largeur: 250},
                {nom: 'vecdistypdis'    , largeur: 160},
                {nom: 'vecdistypdis_dsc', largeur: 225},
                {nom: 'disdat'          , largeur: 100},
                {nom: 'dismnt'          , largeur: 125},
                {nom: 'immmntcou'       , largeur: 125}
            ],
            colonnesFixesDroite: [
                {nom: 'disdattra', largeur: 126}
            ],
            colonnesEditionRapide: [
                {nom: 'immcleint'   , largeur: 340},
                {nom: 'vecdistypdis', largeur: 280},
                {nom: 'disdat'      , largeur: 130},
                {nom: 'dismnt'      , largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('immcleint'),
                new FormulaireItem('vecdistypdis'),
                new FormulaireItem('disdat'),
                new FormulaireItem('dismnt')
            ], {largeurDefaut: 25}),
            colonnesCachees: [
                'immcleint'
            ]
        };
    }
}
