import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';

interface IPageMc2008 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIne: IFormulaire;
    inhMultiOccurrence: IMultiOccurrence;
    inhMultiOccurrenceOptions: IMultiOccurrenceOptions;    
}

/* @ngInject */
export default function Mc2008Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc2008 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false  // L'exportation Excel est faite dans MC0174
            }
        };

        vm.formulaireIne = new Formulaire('', [
            new FormulaireItem('intnom'),
            new FormulaireItem('intnomequ')
        ]);

        
        // Bloc IAP  Approbateur
        vm.inhMultiOccurrenceOptions = {
            bloc: 'inh',
            colonnesVisibles: [
                { nom: 'reddsc',        largeur: 200 },
                { nom: 'inhmntmin', largeur: 120 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('redcleint', { largeur: 30 }),
                new FormulaireItem('inhmntmin', { largeur: 20 })
            ], {
                largeurDefaut: 30
            }),
            fonctions: {
                importation: true
            }
        };

    }
}
