import {module} from 'angular';
import ToolbarController from './toolbar.controller';
import Binding from '../../../core/helpers/binding.helper';

export default module('app.toolbar', [
    'core.providers.acces-manager',
    'core.resources.profil'
]).component('appToolbar', {
    bindings: Binding(
        {
            navigationOpened: '=',
            ecranSpinner: '='
        }
    ),
    template: require('./toolbar.html'),
    controller: ToolbarController,
    controllerAs: 'vm'
});
