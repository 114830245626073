import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';

interface IPageAC0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0031Controller(
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    MenuItemForage: IMenuItemForageClass,
    ac2001Route: IRoute,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite
) {
    const vm: IPageAC0031 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false
        },
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/ac0031/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        actionsNouveaux: new Menu([
            new MenuItemForage(ac2001Route.NAME)
        ]),
        navigatePage: ac2001Route.NAME,
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_DETRUIRE', 'BOUDET', {
                selection: {
                    nom: 'destruction'
                },
                fonction: 'BOUDET'
            })
        ]),
        colonnesVisibles: [
            { nom: 'eccnumchg', largeur: 120 },
            { nom: 'eccsrc', largeur: 250 },
            { nom: 'datcre', largeur: 126 },
            { nom: 'usrnomcre', largeur: 180 },
            { nom: 'eccflg', largeur: 120 },
            { nom: 'eccflgerr', largeur: 120 },
            { nom: 'eccnbrerr', largeur: 120 },
            { nom: 'eccnbrlig', largeur: 120 }
        ]
    };
}
