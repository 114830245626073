import {
    IMultiOccurrence,
    IMultiOccurrenceFonctionsOptions,
    IMultiOccurrenceOptions
} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuSectionClass} from '../../../../../core/services/menu/menu-section.service';
import {IMenuDividerClass} from '../../../../../core/services/menu/menu-divider.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuSelectionEtapes} from '../../../services/menu/menu-selection-etapes.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IData} from '../../../../../core/services/data-linker.service';
import { ILogService } from 'angular';

interface IPageDO0015 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface IPageDO0015Params {
    offcleint: number
}
/* @ngInject */
export default function Do0015Controller(do0001Route: IRoute,
                                         do0002Route: IRoute,
                                         do0015Route: IRoute,
                                         rr0003Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IPageDO0015Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuDivider: IMenuDividerClass,
                                         MenuItem: IMenuItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         MenuSection: IMenuSectionClass,
                                         MenuSelectionEtapes: IMenuSelectionEtapes,
                                         $log: ILogService) {
    const vm: IPageDO0015 = this;
    const fonctions: IMultiOccurrenceFonctionsOptions = {};
    const colonnesVisibles = [];

    if (ecranDetails.params.p_axevis === 'OFF') {
        fonctions.criteresSuggeresVisibles = false;
        colonnesVisibles.push(
            {nom: 'canflgrej', largeur: 95},
            {nom: 'canflgdes', largeur: 140},
            {nom: 'ctpdscabr', largeur: 200},
            {nom: 'cananc', largeur: 132},
            {nom: 'score', largeur: 100},
            {nom: 'cancotapp', largeur: 105},
            {nom: 'candscapp', largeur: 157},
            {nom: 'candatsou', largeur: 100},
            {nom: 'cannumtel', largeur: 157},
            {nom: 'cannumpos', largeur: 98},
            {nom: 'cannumcel', largeur: 157},
            {nom: 'cannumfax', largeur: 157},
            {nom: 'canadrcou', largeur: 300},
            {nom: 'eplemploi', largeur: 240},
            {nom: 'tafflgprb', largeur: 157},
            {nom: 'unodscfmt', largeur: 315},
            {nom: 'cancleint', largeur: 130},
            {nom: 'grgdscabr', largeur: 187},
            {nom: 'canseqeva', largeur: 98},
            {nom: 'adresse', largeur: 315},
            {nom: 'adresse2', largeur: 315},
            // @TODO Éléments supplémentaires
            {nom: 'cancomint', largeur: 220},
            {nom: 'cancom', largeur: 200}
        )
    } else {
        fonctions.criteresSuggeresVisibles = true;
        colonnesVisibles.push(
            {nom: 'canflgrej', largeur: 95},
            {nom: 'canflgdes', largeur: 140},
            {nom: 'offnumdoc', largeur: 147,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_DO0001',
                        do0001Route.NAME,
                        (data: any) => {
                            return {
                                ecran: '00-2',
                                id: data.cycle_trn,
                                offcleint: data.offcleint
                            };
                        },
                        {
                            disabled: (data: any) => {
                                return !data.offnumdoc;
                            }
                        }
                    )
                ]
            },
            {nom: 'offdsc', largeur: 220},
            {nom: 'ctpdscabr', largeur: 200},
            {nom: 'cananc', largeur: 132},
            {nom: 'score', largeur: 100},
            {nom: 'cancotapp', largeur: 105},
            {nom: 'candscapp', largeur: 157},
            {nom: 'candatsou', largeur: 100},
            {nom: 'cannumtel', largeur: 157},
            {nom: 'cannumpos', largeur: 98},
            {nom: 'cannumcel', largeur: 157},
            {nom: 'cannumfax', largeur: 157},
            {nom: 'canadrcou', largeur: 300},
            {nom: 'eplemploi', largeur: 240},
            {nom: 'tafflgprb', largeur: 157},
            {nom: 'unodscfmt', largeur: 315},
            {nom: 'cancleint', largeur: 130},
            {nom: 'grgdscabr', largeur: 187},
            {nom: 'canseqeva', largeur: 98},
            {nom: 'adresse', largeur: 315},
            {nom: 'adresse2', largeur: 315},
            // @TODO Éléments supplémentaires
            {nom: 'cancomint', largeur: 220},
            {nom: 'cancom', largeur: 200}
        )
    }

    vm.multiOccurrenceOptions = {
        navigatePage: do0002Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.cancleint,
                offcleint : rowData.offcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(do0002Route.NAME,
                () => {
                    return {
                        offcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.offcleint
                    };
                },
                {
                    disabled: () => !vm.multiOccurrence.etatSelected.criteresSuggeresData.offcleint
                })
        ]),
        fonctions,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('offcleint', {
                readonly: (data: IData) => data.$params.offcleint,
                largeur: 50,
                required: (data: IData) => (data.$paramsEcran.p_axevis !== 'GBL'),
                default: $stateParams.offcleint,

            }),
            new FormulaireItem('grgcleint', {
                largeur: 50,
                resourceParams: {
                    axe_vis: ($stateParams.offcleint) ? 'OFF' : 'GBL',
                    offcleint: ($stateParams.offcleint) ? ecranDetails.valeurs.offcleint : null,
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('comcleint', {
                largeur: 50,
                resourceParams: {
                    axe_vis: ($stateParams.offcleint) ? 'OFF' : 'GBL',
                    offcleint: ecranDetails.valeurs.offcleint
                }
            }),
            new FormulaireItem('nivcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cri_cannom', {largeur: 50}),
            new FormulaireItem('ctpcleint', {
                largeur: 50,
                resourceParams: {
                    axe_vis: ($stateParams.offcleint) ? 'OFF' : 'GBL',
                    offcleint: ecranDetails.valeurs.offcleint
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('candatde', {largeur: 20}),
            new FormulaireItem('candata', {largeur: 20}),
            new FormulaireItem('typsel', {required: true, default: 'T', direction: 'vertical', largeur: 20}),
            new FormulaireItem('orderby', {required: true, default: 'G', direction: 'vertical', largeur: 20}),
            new FormulaireItem('typcan', {required: true, default: 'TOUS', direction: 'vertical', largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rectxt', {largeur: 100}),
        ]),
        actionsSelection: new Menu([
            new MenuSelectionEtapes(),
            new MenuDivider(),
            new MenuSection('G_LBL_REJETER'),
            new MenuItemSelection('LBL_BTN_REJETER', 'BOUREJ', {
                selection: {
                    nom: 'rejeter',
                    lblConfirmationTitre: 'G_LBL_REJET',
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('vaccanrej'),
                        new FormulaireItem('flgcou'),
                        //TODO: Ajouter l'envoi de courriel lorsque la modale d'envoi de courriels sera développé
                    ])
                }
            }),
            new MenuDivider(),
            new MenuSection('Courriel'),
            new MenuItem('Envoi de courriel au(x) candidat(s)', () => {$log.debug('test')})
        ]),
        colonnesCachees:['offcleint', 'cycle_trn', 'eplcleint', 'trncleint_dem', 'ciecleint'],
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 140,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_RR0003',
                        rr0003Route.NAME,
                        (data: any) => {
                            return {
                                id: data.trncleint_dem
                            };
                        },
                        {
                            hidden: (data: any) => {
                                return !data.trncleint_dem;
                            }
                        }
                    )
                ]
            },
            {nom: 'cannom', largeur: 220}
        ],
        colonnesVisibles,
        colonnesFixesDroite: [
            {nom: 'nbrpij', largeur: 148}
        ],
        formulaire: new Formulaire([], {
            piecesJointes: true
        })
    };
}
