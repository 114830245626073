import { IComponentOptions, module } from 'angular';
import ButtonHistoriqueColonneController from './ex-button-historique-colonne.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-button-historique-colonne', []).component('exButtonHistoriqueColonne', ButtonHistoriqueColonneFactory());

function ButtonHistoriqueColonneFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA_TYPE.OPTIONAL,
            {
                occurrence: '<',
                col: '<',
                data: '=',
                readonly: '<?exReadonly',
                resourceParams: '<?',
                reloadData: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: ButtonHistoriqueColonneController,
        controllerAs: 'vm',
        template: require('./ex-button-historique-colonne.html')
    };
}
