import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';

interface IPageRr0535 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/*
interface IRr0535Params {
    id: string;
    employe: string
}
*/
/* @ngInject */
export default function Rr0535Controller(Formulaire: IFormulaireClass,
    rr2009Route: IRoute,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IStateParamsService) {
    const vm: IPageRr0535 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'bqecod', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'bqedscabr', largeur: 200 },
            { nom: 'dbscod1', largeur: 120 },
            { nom: 'dbscod2', largeur: 120 },
            { nom: 'dbsdsc1', largeur: 200 },
            { nom: 'dbsdsc2', largeur: 200 }
        ],
        colonnesFixesDroite: [
            { nom: 'urgcod', largeur: 100 },
            { nom: 'dbcqte', largeur: 150 },
            { nom: 'dbcqteaff', largeur: 150 },
            { nom: 'bqeafflib', largeur: 100 }
        ],
        colonnesCachees: [
            'demcleint',
            'dbscleintref1',
            'dbscleintref2',
            "bqecleint"
        ],
        navigatePage: rr2009Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.demcleint,
                demcleint: rowData.demcleint,
                bqecleint: rowData.bqecleint,
                dbscleintref1: rowData.dbscleintref1,
                dbscleintref2: rowData.dbscleintref2,
                datdeb: vm.multiOccurrence.etatSelected.criteresSuggeresData.datdeb,
                datfin: vm.multiOccurrence.etatSelected.criteresSuggeresData.datfin,
                flgfer: vm.multiOccurrence.etatSelected.criteresSuggeresData.flgfer
            };
        },
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false,
            reinitialiserOuvrirCriteres: !$stateParams.employe,
            effaceCriteres: true,
            criteresSuggeresVisibles: !$stateParams.employe
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleint', { hidden: Boolean($stateParams.employe), largeur: 60, required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('datdeb', { largeur: 20}),
            new FormulaireItem('datfin', { required: true, default: vm.valeursEcran.datfin, largeur: 20 }),
            new FormulaireItem('flgfer', { default: 0, largeur: 20 }),
            new FormulaireItem('flgferbqe', { default: 0 })
        ], { largeurDefaut: 15 }),
    };

}
