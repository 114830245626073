import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0315 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0315Params {
    alicleint: string;
}

/* @ngInject */
export default function Mr0315Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IMr0315Params,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0315 = this;

    vm.multiOccurrenceOptions = {
        filtres: [{
            colonne: 'alicleint',
            valeur: $stateParams.alicleint,
            visible: false
        }],
        colonnesVisibles: [
            {nom: 'tagcodori', largeur: 150},
            {nom: 'tagdscabrori', largeur: 350},
            {nom: 'tagcoddst', largeur: 150},
            {nom: 'tagdscabrdst', largeur: 350}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('alicleint',{
                default: $stateParams.alicleint,
                hidden:true}),
            new FormulaireItem('tagcleintori'),
            new FormulaireItem('tagcleintdst')
        ])
    };
}
