import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPageRe2005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re2005Controller(parametresRecherche: IParametresRechercheService,
                                       ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe2005 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ppacod', largeur: 120},
            {nom: 'aftcod', largeur: 120},
            {nom: 'rcecod', largeur: 120},
            {nom: 'rcedscabr', largeur: 220},
            {nom: 'rsbsalbasanc', largeur: 120},
            {nom: 'rsbsalbasnou', largeur: 120}
        ],colonnesCachees: [
            'rslcleint'
        ]
        
    };
}
