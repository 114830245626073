import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0130 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0130Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         mr0238Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0130 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_MR0238',
                mr0238Route.NAME,
                (data:any) => ({vaetypfnd: 'STR',
                    cleintdet: data.strcleint}),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'strcod', largeur: 72},
            {nom: 'strdsc', largeur: 262},
            {nom: 'strflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('strcod'),
            new FormulaireItem('strdsc', { largeur: 36.66 }),
            new FormulaireItem('strflgact', { default: 1 })
        ], { largeurDefaut: 33.33 })
    };
}
