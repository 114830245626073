import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IMenuItemAction, IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCP0069 extends IComponentController {
    btnDroite: Array<IMenuItemAction>;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    lccMultiOccurrenceOptions: IMultiOccurrenceOptions;
    lccMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Cp0069Controller(rr0503Route: IRoute,
    cp0026Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageCP0069 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            supprime: (data:any) => !(data.lcoflgacc)
        },
        formulaire: new Formulaire('LBL_BLC_CONCILIATION', [
            new FormulaireItem('peccleint', {readonly: (data) => data.lcoflgacc}),
            new FormulaireItem('lcocod', {readonly: (data)=> data.lcocleint}),
            new FormulaireItem('lcoflgacc', {readonly: true}),
            new FormulaireItem('scbcleint', {readonly: (data)=> data.lcocleint}),
            new FormulaireItem('lcodat', {readonly: (data) => data.lcoflgacc})
        ], {largeurDefaut: 33.33})
    };

    vm.btnDroite = [
        new MenuItemAction(
            'LBL_BOU_ACC',
            'BOUACP',
            () => ({}),
            {
                fonction: 'BOUACP',
                saveFirst: true,
                icon: 'settings',
                iconButton: false,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                disabled: () => vm.monoOccurrence.data.lcoflgacc
            }
        ),
        new MenuItemActionImportation(
            'LBL_BOU_PAS',
            'BOUIMPORT',
            () => ({}),
            {
                fonction: 'BOUPAS',
                saveFirst: true,
                icon: 'file_download',
                iconButton: false,
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_IMPORT_FICHIER',
                    largeurFormulaire: 25,
                    formulaire: new Formulaire([
                        new FormulaireItem('lcocleint', { default: () => vm.monoOccurrence.id, hidden: true }),
                    ])
                },
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                typesFichiers: ['.csv']
            }
        )
    ];

    vm.lccMultiOccurrenceOptions = {
        bloc: 'lcc',
        fonctions: {
            importation: true,
            nouveau: () => !(vm.monoOccurrence.data.lcoflgacc),
            edition: () => !(vm.monoOccurrence.data.lcoflgacc),
            supprime: () => !(vm.monoOccurrence.data.lcoflgacc)
        },
        colonnesVisibles: [
            { nom: 'chqnum', largeur: 120 },
            { nom: 'vaelcctyp_dsc', largeur: 110 },
            { nom: 'chqnumdoc', largeur: 110, menu: [
                new MenuItemForage(
                    'LBL_FORAGE_RR0503',
                    rr0503Route.NAME,
                    (rowData: any) => {
                        return {
                            id: rowData.lcccleintref
                        };
                    },
                    { hidden: (rowData: any) => !(rowData.vaelcctyp === 'PAM') }
                ),
                new MenuItemForage(
                    'LBL_FORAGE_CP0026',
                    cp0026Route.NAME,
                    (rowData: any) => {
                        return {
                            id: rowData.lcccleintref
                        };
                    },
                    { hidden: (rowData: any) => !(rowData.vaelcctyp === 'REG') }
                )

            ] },
            { nom: 'lccmnt', largeur: 140, total: true },
            { nom: 'diff', largeur: 140 },
            { nom: 'lccdat', largeur: 140 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('lcccleintref', {
                resourceParams: (data: any) => ({
                    scbcleint: data.$ancetre.scbcleint,
                    peccleint: data.$ancetre.peccleint
                })
            }),
            new FormulaireItem('lccmnt', {required: true}),
            new FormulaireItem('lccdat'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaelcctyp_dsc', {readonly: true}),
            new FormulaireItem('chqnumdoc', {readonly: true}),
            new FormulaireItem('chqmnt', {readonly: true}),
            new FormulaireItem('diff', {readonly: true}),
            new FormulaireItem('vaelcctyp', {hidden: true})
        ], { largeurDefaut: 25 }),
        colonnesCachees: ['vaelcctyp',
        'lcccleintref']
    };
}


