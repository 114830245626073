import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCC0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0014Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageCC0014 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pagcleint', { largeur: 30, required: true }),
            new FormulaireItem('urgcleint', { largeur: 30 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unocleint', { largeur: 30, required: true }),
            new FormulaireItem('pagdatpredeb', { largeur: 20 }),
            new FormulaireItem('pagdatprefin', { largeur: 10 })
        ]),
        colonnesVisibles: [
            { nom: 'trmcod', largeur: 150 },
            { nom: 'crscod', largeur: 150 },
            { nom: 'crsdsc', largeur: 400 },
            { nom: 'cofgrp', largeur: 100 },
            { nom: 'pffflghabprf', largeur: 80 },
            { nom: 'pffflgrsqann', largeur: 80 },
            { nom: 'pffflgexcaff', largeur: 80 },
            { nom: 'flgaffpre', largeur: 80 },
            { nom: 'pffnbrhre', largeur: 100 },
            { nom: 'pffcom', largeur: 400 },
            { nom: 'flgatr', largeur: 80 }
        ]
    };
}
