import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IInfosMonetaires } from '../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemActionImportationClass } from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageDb0056 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDbp: IFormulaire;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    ddeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    geeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Db0056Controller(
    db0019Route: IRoute,
    $state: IStateService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    Operateur: IOperateurService
) {
    const vm: IPageDb0056 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true,
            boutonDupliquer:false
        },
        menus: [
            new MenuItemAction('LBL_BTN_DET', 'BOUDET', () => ({}), {
                saveFirst: true,
                iconButton: false,
                class: 'md-raised ex-button-action',
                successAction: () => {
                    $state.go(db0019Route.NAME);
                }
            }),
            new MenuItemActionImportation('LBL_BTN_CHARGER', 'BOUCHAR', () => ({}), {
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_CHARGER',
                    largeurFormulaire: 15,
                    formulaire: new Formulaire([
                        new FormulaireItem('dbpnumchg', {
                            largeur: 100,
                            default: (data: any) => data.monoOccurrence.dbpnumchg
                        })
                    ])
                },
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                typesFichiers: ['.csv'],
                iconButton: false,
                class: 'md-raised ex-button-action',
                hidden: () => vm.monoOccurrence.data.aut__nbrenr
            }),
            new MenuItemAction('LBL_BTN_IMP', 'BOUIMP', () => ({}), {
                saveFirst: true,
                iconButton: false,
                class: 'md-raised ex-button-action',
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                hidden: () => !vm.monoOccurrence.data.aut__nbrenr,
                disabled: () => vm.monoOccurrence.data.aut__nbrenr && vm.monoOccurrence.data.dbpflg
            })
        ]
    };

        vm.menuBandeau = new Menu([]);

        // Information monétaire
        vm.monoOccurrenceInfosMonetaire = {
            largeurTexte: 60,

            donnees: [
                { nom: 'dbt', libelle: 'G_LBL_DBT' },
                { nom: 'crt', libelle: 'G_LBL_CRT', lastOfGroup: true },
                { nom: 'ecart', libelle: 'G_LBL_ECART', expectedValue: 0 }
            ]
        };

        vm.formulaireDbp = new Formulaire('LBL_BLC_PEJ', [
            new FormulaireItem('dbpnumchg', { readonly: (data) => data.$id }),
            new FormulaireItem('dbpsrc', { readonly: true }),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('aut__nbrenr', { readonly: true })
        ]);

        vm.ddeMultiOccurrenceOptions = {
            fonctions: {
                rechercheCollapsable: false,
                editionRapide: true,
                edition: (data: any) => !data.$blocs.dbp.dbpflg
            },
            colonnesFixesGauche: [
                { nom: 'ddenumlig', largeur: 100 },
                { nom: 'ddeflgecberr', largeur: 120 },
                { nom: 'ddeflgebverr', largeur: 120 },
                { nom: 'ddeflgdoierr', largeur: 120 }
            ],
            colonnesVisibles: [
                // Écriture
                { nom: 'ddeecbdat', largeur: 100 },
                { nom: 'ddetydcod', largeur: 69 },
                { nom: 'ddepeccod', largeur: 95 },
                { nom: 'ddeecbcat', largeur: 100 },
                { nom: 'ddeecbdsc', largeur: 273 },
                { nom: 'ddeintcod', largeur: 105 },
                { nom: 'ddegrpecb', largeur: 100 },
                // Ventilation
                { nom: 'ddegrpebv', largeur: 120 },
                { nom: 'ddeprucod', largeur: 90 },
                { nom: 'ddeprscod', largeur: 130 },
                { nom: 'ddecifcod', largeur: 130 },
                { nom: 'ddeunacod', largeur: 110 },
                { nom: 'ddecptcod', largeur: 130 },
                { nom: 'ddeel1cod', largeur: 100 },
                { nom: 'ddeel2cod', largeur: 100 },
                { nom: 'ddeel3cod', largeur: 100 },
                { nom: 'ddeebvdsc', largeur: 273 },
                { nom: 'ddedoimntdbt', largeur: 126 },
                { nom: 'ddedoimntcrt', largeur: 126 },
                { nom: 'ddedoiqte', largeur: 132 },
                // Référence
                { nom: 'dder01', largeur: 162 },
                { nom: 'dder02', largeur: 162 },
                { nom: 'dder03', largeur: 162 },
                { nom: 'dder04', largeur: 162 },
                { nom: 'dder05', largeur: 162 },
                { nom: 'dder06', largeur: 162 },
                { nom: 'dder07', largeur: 162 },
                { nom: 'dder08', largeur: 162 },
                { nom: 'dder09', largeur: 162 },
                { nom: 'dder10', largeur: 162 }
            ],
            colonnesCachees: ['ddemess'],
            colonnesEditionRapide: [
                // Écriture
                { nom: 'ddeecbdat', largeur: 100 },
                { nom: 'ddetydcod', largeur: 120 },
                { nom: 'ddepeccod', largeur: 120 },
                { nom: 'ddeecbcat', largeur: 120 },
                { nom: 'ddeecbdsc', largeur: 250 },
                { nom: 'ddeintcod', largeur: 150 },
                { nom: 'ddegrpecb', largeur: 100 },
                // Ventilation
                { nom: 'ddegrpebv', largeur: 160 },
                { nom: 'ddeprucod', largeur: 130 },
                { nom: 'ddeprscod', largeur: 130 },
                { nom: 'ddecifcod', largeur: 130 },
                { nom: 'ddeunacod', largeur: 130 },
                { nom: 'ddecptcod', largeur: 130 },
                { nom: 'ddeel1cod', largeur: 130 },
                { nom: 'ddeel2cod', largeur: 130 },
                { nom: 'ddeel3cod', largeur: 130 },
                { nom: 'ddeebvdsc', largeur: 250 },
                { nom: 'ddedoimntdbt', largeur: 126 },
                { nom: 'ddedoimntcrt', largeur: 126 },
                { nom: 'ddedoiqte', largeur: 126 },
                // Référence
                { nom: 'dder01', largeur: 120 },
                { nom: 'dder02', largeur: 120 },
                { nom: 'dder03', largeur: 120 },
                { nom: 'dder04', largeur: 120 },
                { nom: 'dder05', largeur: 120 },
                { nom: 'dder06', largeur: 120 },
                { nom: 'dder07', largeur: 120 },
                { nom: 'dder08', largeur: 120 },
                { nom: 'dder09', largeur: 120 },
                { nom: 'dder10', largeur: 120 }
            ],
            formulaire: new Formulaire(
                [
                    new FormulaireItem('ddenumlig', { readonly: true }),
                    new FormulaireItemDivider('LBL_DIV_ECJ'),
                    new FormulaireItem('ddeecbdat'),
                    new FormulaireItem('ddetydcod'),
                    new FormulaireItem('ddepeccod'),
                    new FormulaireItem('ddeecbcat'),
                    new FormulaireItem('ddeecbdsc'),
                    new FormulaireItem('ddeintcod'),
                    new FormulaireItem('ddegrpecb'),
                    new FormulaireItemDivider('LBL_DIV_ECV'),
                    new FormulaireItem('ddegrpebv'),
                    new FormulaireItem('ddeprucod'),
                    new FormulaireItem('ddeprscod'),
                    new FormulaireItem('ddecifcod'),
                    new FormulaireItem('ddeunacod'),
                    new FormulaireItem('ddecptcod'),
                    new FormulaireItem('ddeel1cod'),
                    new FormulaireItem('ddeel2cod'),
                    new FormulaireItem('ddeel3cod'),
                    new FormulaireItem('ddeebvdsc'),
                    new FormulaireItem('ddedoimntdbt'),
                    new FormulaireItem('ddedoimntcrt'),
                    new FormulaireItem('ddedoiqte'),
                    new FormulaireItemDivider('LBL_DIV_REF'),
                    new FormulaireItem('dder01'),
                    new FormulaireItem('dder02'),
                    new FormulaireItem('dder03'),
                    new FormulaireItem('dder04'),
                    new FormulaireItem('dder05'),
                    new FormulaireItem('dder06'),
                    new FormulaireItem('dder07'),
                    new FormulaireItem('dder08'),
                    new FormulaireItem('dder09'),
                    new FormulaireItem('dder10')
                ],
                { largeurDefaut: 33 }
            )
        };

        vm.geeMultiOccurrenceOptions = {
            fonctions: {
                suiviModification: false
            },
            colonnesVisibles: [{ nom: 'ddemess', largeur: 500 }]
        };
}
