import {IComponentController} from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import {IInfosMonetaires} from "../../../../../core/components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller";
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IMenuItemActionImportationClass} from "../../../../../core/services/menu/menu-item-action-importation.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0163 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireFfp: IFormulaire;
    formulaireLignedetails: IFormulaire;
    monoOccurrenceInfosMonetaire: IInfosMonetaires;
    ffdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0163Controller(Formulaire: IFormulaireClass,
                                         rr0077Route: IRoute,
                                         $state: IStateService,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemActionImportation: IMenuItemActionImportationClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Operateur: IOperateurService) {
    const vm: IPageRr0163 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true,
            boutonDupliquer: false
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    disabled: () => !((vm.monoOccurrence.data.ffpflgfin && !vm.monoOccurrence.data.ffpnbrlig) ||
                                   (!vm.monoOccurrence.data.ffpflgfin && vm.monoOccurrence.data.ffpnbrlig))
                }
                ),
            new MenuItemAction(
                'LBL_BTN_GEN',
                'BOUTRT',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    disabled: () => !(vm.monoOccurrence.data.ffpflgval && vm.monoOccurrence.data.ffpflgfin)
                }
                ),
            new MenuItemAction(
                'LBL_BTN_SUP',
                'BOUSUP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    successAction: (data: any) => {
                        $state.go(rr0077Route.NAME, {
                        });
                    }
                }
            )
        ]
    };
                
    vm.formulaireLignedetails = new Formulaire('LBL_BLC_LIG', [
        new FormulaireItem('radselffd', {default: 'TOUS',  largeur: 100})
    ]);

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireFfp = new Formulaire('LBL_BLC_FFP', [            
            new FormulaireItem('ffpnumcha',{largeur: 33}),
            new FormulaireItem('ffpficsou',{largeur: 33}),
            new FormulaireItem('ffpnbrlig',{largeur: 33}),
            new FormulaireItemDivider('LBL_DIV_SOM', {hidden: (data) => !data.ffpnbrlig}),            
            new FormulaireItemSautDeLigne(),   
            new FormulaireItem('unpcleint'),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('unpcod'),
            new FormulaireItem('ppacleint'),
            new FormulaireItem('ppacod'),
            new FormulaireItem('ppacleintori'),
            new FormulaireItem('ppacodori'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('urgcod'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('strcod'),
            new FormulaireItem('unocleint'),
            new FormulaireItem('unocod'),
            new FormulaireItem('tagcleint'),
            new FormulaireItem('tagcod'),
            
        ]);

        vm.ffdMultiOccurrenceOptions = {
            fonctions: {
                rechercheCollapsable: false,
                criteresSuggeresVisibles: false,
                edition: true,
                editionRapide: true                
            },
            criteresSuggeres: vm.formulaireLignedetails,
                     
            colonnesFixesGauche: [                
                {nom: 'eplnummat'   , largeur: 120}
            ],
            colonnesFixesDroite:[
                {nom: 'flgerreur'   , largeur: 75},
                {nom: 'ffdnbrerr'   , largeur: 90} 
            ],
            colonnesVisibles: [                
                {nom: 'eplnomprn'  , largeur: 200},
                {nom: 'temcod'     , largeur: 115},
                {nom: 'ffdtauhor'  , largeur: 100},
                {nom: 'ppacodori'  , largeur: 100},
                {nom: 'tagcod'     , largeur: 89},
                {nom: 'ffdqte01'   , largeur: 115},
                {nom: 'ffdqte02'   , largeur: 115},
                {nom: 'ffdqte03'   , largeur: 115},
                {nom: 'ffdqte04'   , largeur: 115},
                {nom: 'ffdqte05'   , largeur: 115},
                {nom: 'ffdqte06'   , largeur: 115},
                {nom: 'ffdqte07'   , largeur: 115},
                {nom: 'ffdqte08'   , largeur: 115},
                {nom: 'ffdqte09'   , largeur: 115},
                {nom: 'ffdqte10'   , largeur: 115},
                {nom: 'ffdqte11'   , largeur: 115},
                {nom: 'ffdqte12'   , largeur: 115},
                {nom: 'ffdqte13'   , largeur: 115},
                {nom: 'ffdqte14'   , largeur: 115},
                {nom: 'ffdimp'     , largeur: 400},
                {nom: 'ffdflgforaftlem'   , largeur: 140},
                {nom: 'aftcod'   , largeur: 115},
                {nom: 'lemcod'   , largeur: 150},
                {nom: 'esvcod1'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv1aff},
                {nom: 'esvcod2'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv2aff},
                {nom: 'esvcod3'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv3aff},
                {nom: 'esvcod4'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv4aff}
            ],
            colonnesCachees:['ffderr'], 
            colonnesEditionRapide: [
                {nom: 'eplnomprn'  , largeur: 200},
                {nom: 'temcod'     , largeur: 115},
                {nom: 'ffdtauhor'  , largeur: 100},
                {nom: 'ppacodori'  , largeur: 100},
                {nom: 'tagcod'     , largeur: 89},
                {nom: 'ffdqte01'   , largeur: 115},
                {nom: 'ffdqte02'   , largeur: 115},
                {nom: 'ffdqte03'   , largeur: 115},
                {nom: 'ffdqte04'   , largeur: 115},
                {nom: 'ffdqte05'   , largeur: 115},
                {nom: 'ffdqte06'   , largeur: 115},
                {nom: 'ffdqte07'   , largeur: 115},
                {nom: 'ffdqte08'   , largeur: 115},
                {nom: 'ffdqte09'   , largeur: 115},
                {nom: 'ffdqte10'   , largeur: 115},
                {nom: 'ffdqte11'   , largeur: 115},
                {nom: 'ffdqte12'   , largeur: 115},
                {nom: 'ffdqte13'   , largeur: 115},
                {nom: 'ffdqte14'   , largeur: 115},
                {nom: 'ffdimp'     , largeur: 400},
                {nom: 'ffdflgforaftlem'   , largeur: 140},
                {nom: 'aftcod'   , largeur: 115},
                {nom: 'lemcod'   , largeur: 150},
                {nom: 'esvcod1'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv1aff},
                {nom: 'esvcod2'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv2aff},
                {nom: 'esvcod3'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv3aff},
                {nom: 'esvcod4'   , largeur: 120, hidden: (data) => data.$valeurs.flgesv4aff}
            ],     
            formulaire: new Formulaire([
                new FormulaireItem('demcleint', {resourceParams: (data: any) => ({
                        unpcleint: vm.monoOccurrence.data.unpcleint
                    })}),
                new FormulaireItem('temcleint', {resourceParams: (data: any) => ({
                        urgcleint: vm.monoOccurrence.data.urgcleint
                    })}),
                new FormulaireItem('ffdtauhor'),
                new FormulaireItem('ppacleintori', {resourceParams: (data: any) => ({
                        unpcleint: vm.monoOccurrence.data.unpcleint
                    })}),
                new FormulaireItem('tagcleint'),
                new FormulaireItem('eplnummat'),
                new FormulaireItem('eplnomprn'),
                new FormulaireItem('temcod'),
                new FormulaireItem('esvcod1'),
                new FormulaireItem('esvcod2'),
                new FormulaireItem('esvcod3'),
                new FormulaireItem('esvcod4'),
                new FormulaireItem('esvcod5'),
                new FormulaireItem('ffdqte01'),
                new FormulaireItem('ffdqte02'),
                new FormulaireItem('ffdqte03'),
                new FormulaireItem('ffdqte04'),
                new FormulaireItem('ffdqte05'),
                new FormulaireItem('ffdqte06'),
                new FormulaireItem('ffdqte07'),
                new FormulaireItem('ffdqte08'),
                new FormulaireItem('ffdqte09'),
                new FormulaireItem('ffdqte10'),
                new FormulaireItem('ffdqte11'),
                new FormulaireItem('ffdqte12'),
                new FormulaireItem('ffdqte13'),
                new FormulaireItem('ffdqte14'),
                new FormulaireItem('ffdimp'),
                new FormulaireItem('ffdflgforaftlem'),
                new FormulaireItem('aftcleint'),
                new FormulaireItem('lemcleint'),
                new FormulaireItem('esvcleint1'),
                new FormulaireItem('esvcleint2'),
                new FormulaireItem('esvcleint3'),
                new FormulaireItem('esvcleint4')
            ], {largeurDefaut: 33})
        };
        
    }
}
