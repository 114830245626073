import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageAC0088 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0088Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageAC0088 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                },
                required: true
            }),
            new FormulaireItem('vecsyscatcou', {
                required: false
            }),
            new FormulaireItem('flgconcil', {
                default: 'T',
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_DATE'),
            new FormulaireItem('datdeb', {
                required: true
            }),
            new FormulaireItem('datfin', {
                required: true
            })
        ]),
        // Colonnes fixes à gauche
        colonnesFixesGauche: [
            {nom: 'refcod',largeur: 120},
            {nom: 'refnom',largeur: 215}
        ],
        colonnesFixesDroite: [
            {nom: 'copmntaju',largeur: 126}
        ],
        colonnesVisibles: [
            {nom: 'copcod',largeur: 120},
            {nom: 'copdat',largeur: 100},
            {nom: 'cnccod',largeur: 168},
            {nom: 'meldsc',largeur: 140},
            {nom: 'vecsysquamel_dsc',largeur: 131},
            {nom: 'copqteach',largeur: 140},
            {nom: 'copprcbit',largeur: 140},
            {nom: 'copqtebit',largeur: 160},
            {nom: 'copprxann',largeur: 140},
            {nom: 'copprxmoi',largeur: 140},
            {nom: 'vecsyscatcou_cod',largeur: 100},
            {nom: 'vecsyscatcou_dsc',largeur: 165},
            {nom: 'vecsyscodarr',largeur: 140},
            {nom: 'vecsyscodarr_dsc',largeur: 165}
        ]
    };
}
