import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCb0035 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    cptMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tymMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0035Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Pagination: IPaginationClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cb2004Route: IRoute,
    $stateParams: IStateParamsService
) {
    const vm: IPageCb0035 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.tymMultiOccurrenceOptions = {
        bloc: 'tym',
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 250 },
            { nom: 'ctvmntsum', largeur: 130 },
            { nom: 'ctvmntimp', largeur: 120, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        })
    };

    vm.cptMultiOccurrenceOptions = {
        bloc: 'cpt',
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccleintdebcri', {
                default: vm.valeursEcran.peccleintdebcri,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A'),
                readonly: true
            }),
            new FormulaireItem('peccleintfincri', {
                default: vm.valeursEcran.peccleintfincri,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A'),
                readonly: true
            }),
            new FormulaireItem('peccleintcivdeb', {
                default: vm.valeursEcran.peccleintcivdeb,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P'),
                readonly: true
            }),
            new FormulaireItem('peccleintcivfin', {
                default: vm.valeursEcran.peccleintcivfin,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P'),
                readonly: true
            }),
            new FormulaireItem('indcleintcri', {
                default: vm.valeursEcran.indcleintcri,
                readonly: true
            }),
            new FormulaireItem('vaesysnatctbcri', {
                default: vm.valeursEcran.vaesysnatctb,
                readonly: true
            })
        ]),
        colonnesVisibles: [
            { nom: 'cptcod', largeur: 100 },
            { nom: 'cptdsc', largeur: 250 },
            { nom: 'mntsum', largeur: 130, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        multiOccurrenceOptionsEnfant: vm.tymMultiOccurrenceOptions
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cb2004Route.NAME,
                (rowData: any) => ({
                    type: 'CPT',
                    prucleint: $stateParams.prucleint,
                    cptcleint: rowData.cptcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    tymcleint: rowData.tymcleint,
                    tydcleint: rowData.tydcleint,
                    peccleintdeb: rowData.peccleintdeb,
                    peccleintfin: rowData.peccleintfin
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 130 },
            { nom: 'ctvmntsum', largeur: 120 }
        ],
        colonnesCachees: [
            'prucleint',
            'cptcleint',
            'vaesysnatctb',
            'tymcleint',
            'tydcleint',
            'peccleintdeb',
            'peccleintfin'
        ]
    };
}
