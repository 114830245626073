import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from "angular-ui-router";
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRR2003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr2003Controller(rr2004Route: IRoute,
                                         $stateParams: IStateParamsService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageRR2003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            selectionCycle: false
        },
        navigatePage: rr2004Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.aftcleint,
                trncleint: rowData.trncleint
            };
        },
        filtres: parametresRecherche(ecranDetails),
        // Colonnes fixes à gauche
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [            
            {nom: 'aftdatdeb', largeur: 100},
            {nom: 'aftdatfin', largeur: 100},
            {nom: 'temcod', largeur: 110},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'temhreheb', largeur: 140},
            {nom: 'posnumdoc', largeur: 90},
            {nom: 'postitabr', largeur: 262},
            {nom: 'aftcod', largeur: 100},
            {nom: 'tafcod', largeur: 90},
            {nom: 'tafdsc', largeur: 262},
            {nom: 'aftflgpri', largeur: 72},
            {nom: 'aftflgttu', largeur: 65},
            {nom: 'tafflgprb', largeur: 72},
            {nom: 'tafflgaft', largeur: 72},
            {nom: 'tafflgaccanc', largeur: 72},
            {nom: 'aftdatdebadm', largeur: 140},
            {nom: 'aftdatfinadm', largeur: 130},
            {nom: 'aftflgpro', largeur: 72},
            {nom: 'aftflgfincon', largeur: 122},
            {nom: 'aftdatfinpai', largeur: 120},
            {nom: 'poscodsup', largeur: 130},
            {nom: 'postitabrsup', largeur: 262},
            {nom: 'nomsup', largeur: 262},
            {nom: 'lemcod', largeur: 120},
            {nom: 'lemdsc', largeur: 262},
            {nom: 'lemdatdeb', largeur: 110},
            {nom: 'lemdatfin', largeur: 110},
            {nom: 'aftdsc', largeur: 262},
            {nom: 'unocodrh', largeur: 160},
            {nom: 'unodscabrrh', largeur: 262},
            {nom: 'unocodvac', largeur: 170},
            {nom: 'unodscabrvac', largeur: 272},
            {nom: 'litcod', largeur: 110},
            {nom: 'litdsc', largeur: 262},
            {nom: 'aftpostitfer', largeur: 236},
            {nom: 'aftcom', largeur: 236}
        ],
        colonnesCachees: [
            'trncleint'
        ]
    };
}
