import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGR0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gr0002Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGR0002 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('recherche', {
                required: false,
                largeur: 50
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gsicleint', {
                required: false,
                largeur: 50
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('art', {
                required: false,
                largeur: 25
            }),
            new FormulaireItem('souart', {
                required: false,
                largeur: 25
            }),
            new FormulaireItem('aln', {
                required: false,
                largeur: 25
            }),
            new FormulaireItem('soualn', {
                required: false,
                largeur: 25
            })
        ]),
        colonnesVisibles: [
            {nom: 'artcod', largeur: 72},
            {nom: 'artsoucod', largeur: 72},
            {nom: 'artaln', largeur: 92},
            {nom: 'artsoualn', largeur: 100},
            {nom: 'artdscabr', largeur: 262},
            {nom: 'gsicod', largeur: 100},
            {nom: 'gsicodori', largeur: 100},
            {nom: 'artcodori', largeur: 168},
            {nom: 'artflgnouart', largeur: 91},
            {nom: 'cvtcod', largeur: 105},
            {nom: 'cvtdsc', largeur: 262},
            {nom: 'artdsc', largeur: 262},
            {nom: 'artmotcle', largeur: 273},
            {nom: 'gsidsc', largeur: 262},
            {nom: 'gsidscori', largeur: 262},
            {nom: 'artdatsig', largeur: 100},
            {nom: 'artdatvig', largeur: 100},
            {nom: 'vaccod_typ', largeur: 89},
            {nom: 'vacdsc_typ', largeur: 262},
            {nom: 'nbr_grf', largeur: 72},
            {nom: 'nbr_gai', largeur: 55},
            {nom: 'nbr_pjb', largeur: 55}        ]
    };
}
