import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCo0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function co0027Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageCo0027 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 260},
            {nom: 'comcod', largeur: 120},
            {nom: 'ppacod',largeur: 80},
            {nom: 'coydatdeb',largeur: 100},
            {nom: 'coydatfin',largeur: 100},
            {nom: 'coyqtepj',largeur: 110},
            {nom: 'coyqteabs',largeur: 110},
            {nom: 'coyflgatt', largeur: 80}
        ],

        formulaire: new Formulaire([
            new FormulaireItem('eplcleint'),
            new FormulaireItem('comcleint'),
            new FormulaireItem('ppacleint',{largeur: 50}),
            new FormulaireItem('coydatdeb',{largeur: 25}),
            new FormulaireItem('coydatfin',{largeur: 25}),
            new FormulaireItem('coyqtepj',{largeur: 33.33}),
            new FormulaireItem('coyqteabs',{largeur: 33.33}),
            new FormulaireItem('coyflgatt',{largeur: 33.33})
        ])
    };
}
