import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGs0039 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0039Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0039 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'gprcleint', largeur: 100},
            {nom: 'gprdscabr', largeur: 350},
            {nom: 'gprvaldev', largeur: 300},
            {nom: 'gprdsc', largeur: 600}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gprcleint'),
            new FormulaireItem('gprdscabr'),
            new FormulaireItem('gprvaldev'),
            new FormulaireItem('gprdsc', {largeur: 50, enableCount: true})
        ], {largeurDefaut: 33})
    };
}
