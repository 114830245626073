import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0077 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0077Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0077 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tymdsc', largeur: 150 },
            { nom: 'tyhdsc', largeur: 250 },
            { nom: 'tyhflgana', largeur: 90 },
            { nom: 'tyhflgimpgl', largeur: 90 },
            { nom: 'tyhflgfinann', largeur: 110 },
            { nom: 'tyhflgrec', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tymcleint', { largeur: 50 }),
            new FormulaireItem('tyhdsc', { largeur: 50 }),
            new FormulaireItem('tyhflgana', { largeur: 25 }),
            new FormulaireItem('tyhflgimpgl', { largeur: 25 }),
            new FormulaireItem('tyhflgfinann', { largeur: 25 }),
            new FormulaireItem('tyhflgrec', { largeur: 25 })
        ])
    };
}
