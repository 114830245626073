import { IComponentController, IFilterDate } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAc0041 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dfiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dffMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0041Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    dateFilter: IFilterDate) {

    const vm: IPageAc0041 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_DPF', [
            new FormulaireItem('dpfnumdoc', { largeur: 33.33 }),
            new FormulaireItem('dpfnumdocseq', { readonly: true, largeur: 33.33 }),
            new FormulaireItem('dpfflgpij', { largeur: 33.33 }),
            new FormulaireItem('tydcleint', { modifAllow: false }),
            new FormulaireItem('dpfdat', { default: dateFilter(new Date(), 'yyyy-MM-dd') }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintreq'),
            new FormulaireItem('intcleintach', { required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aut__docaffnumdoc01', { readonly: true }),
            new FormulaireItem('vecdpfcat'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dpfdsc', { enableCount: true }),
            new FormulaireItem('dpfdscimp', { enableCount: true }),
            new FormulaireItem('dpfjus', { enableCount: true, largeur: 100 })
        ], {largeurDefaut:50})
    };

    vm.dfiMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'dfilig', largeur: 80},
            {nom: 'procod', largeur: 150, hidden: () => (!vm.valeursEcran.cieflggesproapp)},
            {nom: 'dfidsc', largeur: 300},
            {nom: 'dfidsclng', largeur: 350},
            {nom: 'dfiqte', largeur: 126},
            {nom: 'forcod', largeur: 100},
            {nom: 'fordsc', largeur: 200},
            {nom: 'dfiprxfou1', largeur: 126},
            {nom: 'dfiprxfou2', largeur: 126},
            {nom: 'dfiprxfou3', largeur: 126},
            {nom: 'dfiprxfou4', largeur: 126},
            {nom: 'dfiprxfou5', largeur: 126}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dfilig', { largeur: 25 }),
            new FormulaireItem('procleint', { largeur: 25, hidden: () => (!vm.valeursEcran.cieflggesproapp)}),
            new FormulaireItem('dfidsc', { largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dfiqte', { largeur: 25 }),
            new FormulaireItem('forcleint', {largeur: 25, resourceParams: (data:any) => ({ procleint: data.procleint }) }),
            new FormulaireItem('dfidsclng', { largeur: 50, enableCount: true }),
            new FormulaireItem('dfiprxfou1'),
            new FormulaireItem('dfiprxfou2'),
            new FormulaireItem('dfiprxfou3'),
            new FormulaireItem('dfiprxfou4'),
            new FormulaireItem('dfiprxfou5'),
        ], {largeurDefaut: 20})
    };

    vm.dffMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'dfflig', largeur: 80},
            {nom: 'refcod', largeur: 100},
            {nom: 'radcod', largeur: 100},
            {nom: 'dfffounom', largeur: 200},
            {nom: 'motcod', largeur: 120},
            {nom: 'motdsc', largeur: 200},
            {nom: 'dffflgret', largeur: 120},
            {nom: 'dffflgnonfou', largeur: 120},
        ], 
        actionsRangeeDroite: new Menu([
            new MenuItemAction(
                'LBL_BTN_MODREF',
                'BOUMODREF',
                () => ({}),
                {
                    hidden: (rowData:any) => !(rowData.dffflgmodref),
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_MODREF',
                        largeurFormulaire: 40,
                        formulaire: new Formulaire([
                            new FormulaireItem('refcleintnew', {required: true, largeur: 100})
                        ])
                    },
                    icon: 'sync',
                    iconButton: true
                })
        ]),
        colonnesCachees: ['dffflgmodref'],
        formulaire: new Formulaire([
            new FormulaireItem('dfflig'),
            new FormulaireItem('refcleint'),
            new FormulaireItem('radcleint', {
                resourceParams: (rowData:any) => ({
                    refcleint: rowData.refcleint
                })
            }),
            new FormulaireItem('dfffounom'),
            new FormulaireItem('motcleint'),
            new FormulaireItem('dffflgret'),
            new FormulaireItem('dffflgnonfou'),
            new FormulaireItemDivider('LBL_ADR'),
            new FormulaireItem('dffadrl01'),
            new FormulaireItem('dffadrl02'),
            new FormulaireItem('dffvil'),
            new FormulaireItem('dffpro'),
            new FormulaireItem('dffpay'),
            new FormulaireItem('dffcodpos'),
            new FormulaireItem('dffnumtel'),
            new FormulaireItem('dffnumtelpos'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dfffaxcodspe'),
            new FormulaireItem('dffnumfax'),
            new FormulaireItem('dffflgfaxint'),
            new FormulaireItem('dffnomcon'),
            new FormulaireItem('dffadrcou')
        ], {largeurDefaut:33.33})
    };
}
