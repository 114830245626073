import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageCp0042 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0042Controller(Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         mc0046Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCp0042 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cpacod', largeur: 105},
            {nom: 'cpadsc', largeur: 331},
            {nom: 'aapcod', largeur: 126},
            {nom: 'aapdsc', largeur: 126},
            {nom: 'cpaprx', largeur: 126},
            {nom: 'cpamntfor', largeur: 126},
            {nom: 'taxcod', largeur: 105},
            {nom: 'taxdscfed', largeur: 150},
            {nom: 'taxdscpro', largeur: 150},
            {nom: 'remcod', largeur: 105},
            {nom: 'remdscfed', largeur: 150},
            {nom: 'remdscpro', largeur: 150},
            {nom: 'cpaflgact', largeur: 72},
            {nom: 'vaesysdevcod', largeur: 105},
            {nom: 'cpaflgsaiqte', largeur: 105}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cpacod', {required: true, largeur: 33}),
            new FormulaireItem('cpadsc', {required: true, largeur: 67,  enableCount: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aapcleint'),
            new FormulaireItem('cpaprx'),
            new FormulaireItem('cpamntfor'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('taxcleint'),
            new FormulaireItem('remcleint'),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireItem('cpaflgact', {default: 1}),
            new FormulaireItem('cpaflgsaiqte')
        ], {largeurDefaut: 30}),

        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => ({
                    vaeusient: 'CPA',
                    uvicleintref: data.cpacleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),
    };
}
