import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageMc0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0006Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0006 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'inscod'       , largeur: 120},
            {nom: 'insdsc'       , largeur: 300},
            {nom: 'insdscabr'    , largeur: 300},
            {nom: 'vaeinsval_dsc', largeur: 200}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('inscod'),
            new FormulaireItem('vaeinsval'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('insdsc'   , {largeur: 50}),
            new FormulaireItem('insdscabr', {largeur: 50})
        ], {largeurDefaut: 33})
    };
}
