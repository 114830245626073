import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageGS0066 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IGs0066Params {
    usrcleintref: string;
}

/* @ngInject */
export default function Gs0066Controller($stateParams: IGs0066Params,
    Operateur: IOperateurService,
    gs0067Route: IRoute) {
    const vm: IPageGS0066 = this;

    const usrFiltresParametres = [];

    if ($stateParams.usrcleintref) {
        usrFiltresParametres.push(
            ...[
                {
                    colonne: 'usrcleintref',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.usrcleintref
                }
            ]
        );
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtresParametres: usrFiltresParametres,
        navigatePage: gs0067Route.NAME,
        colonnesFixesGauche: [
            { nom: 'gsunumdoc', largeur: 130 },
        ],
        colonnesVisibles: [
            { nom: 'ttccod', largeur: 80 },
            { nom: 'ttcdsc', largeur: 100 },
            { nom: 'gsudat', largeur: 120 },
            { nom: 'gsutypanc', largeur: 120 },
            { nom: 'gsutypnou', largeur: 120 },
            { nom: 'ecldsceta', largeur: 80 },
            { nom: 'gsucom', largeur: 200 }
        ]
    };
}
