import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0260 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePdtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0260Controller(Menu: IMenuClass,
                                         $stateParams: any,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMr0260 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesFixesGauche: [
            {nom: 'colcod', largeur: 120},
            {nom: 'coldsc', largeur: 250},
            {nom: 'cdtdatvig', largeur: 110}
        ],
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 100},
            {nom: 'tagdscabr', largeur: 250},
            {nom: 'tagprical_vue', largeur: 108},
            {nom: 'admflgmnt', largeur: 74},
            {nom: 'admflgqte', largeur: 74},
            {nom: 'cdtdoc', largeur: 250},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('colcleint', {largeur: 40}),
            new FormulaireItem('cdtdatvig', {largeur: 20}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tagcleint', {largeur: 40}),
            new FormulaireItem('tagprical', {largeur: 10}),
            new FormulaireItem('admflgmnt', {largeur: 10, default: 0}),
            new FormulaireItem('admflgqte', {largeur: 10, default: 0})
        ],{largeurDefaut: 10}),
    };
}
