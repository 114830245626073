import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0004Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0004 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'gracod', largeur: 89 },
            { nom: 'gradsc', largeur: 262 },
            { nom: 'graseqaff', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gracod', { required: true }),
            new FormulaireItem('gradsc', { required: true }),
            new FormulaireItem('graseqaff')
        ]),
        fonctions: Object.assign({
            rechercheCollapsable: false
        })
    };
}
