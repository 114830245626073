import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageDo0058 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceGRLOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0058Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0058 = this;

    vm.multiOccurrenceGRLOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        bloc: 'grl',
        colonnesVisibles: [
            { nom: 'temcod', largeur: 135 },
            { nom: 'temdscabr', largeur: 305 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('temcleint')
        ])
    };


    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'grtcod', largeur: 135 },
            { nom: 'grtdsc', largeur: 305 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('grtcod'),
            new FormulaireItem('grtdsc')
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceGRLOptions
    };
}
