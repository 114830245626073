import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';
import IStateService = angular.ui.IStateService;

interface IPageRe0004 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
    eecMultiOccurrence: IMultiOccurrence;
    eecMultiOccurrenceOptions: IMultiOccurrenceOptions;       
}

/* @ngInject */
export default function Re0004Controller(re0004Route: IRoute,
                                         exLibelleFilter: IFilterLibelle,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $state: IStateService) {

    const vm: IPageRe0004 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true,
                afficherMenusDansEntete: true
            },
            menus: [  
                new MenuItemAction(
                    'LBL_BTN_COP',
                    'BOUCOP',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: (rowData:any) => {
                            $state.go(re0004Route.NAME, {id: rowData.esacleintn});
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_FORMULAIRE_COP',
                            lblConfirm: exLibelleFilter('LBL_COPIER', vm.libelles),
                            largeurFormulaire: 25,
                            formulaire: new Formulaire([
                                new FormulaireItem('daedatvig', {largeur: 100, required: true, resourceParams: (data: any) => ({
                                    t_cvt: data.t_cvt,
                                    geccleint: data.geccleint,
                                    rtocleint: data.rtocleint
                                }) }),
                                new FormulaireItem('prcaug', {largeur: 100}),
                                new FormulaireItem('nbr_dec', {largeur: 100, default: '2'})                               
                            ], { largeurDefaut: 40 })
                        }
                    }
                )
            ],
        };

        vm.formulaire = new Formulaire('LBL_ETAT_RTO', [
            new FormulaireItem('rtocleint', {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('geccleint', {largeur: 40}),
            new FormulaireItem('vaesysprvret', {largeur: 20, readonly: true, default: 'RETR'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_cla', {largeur: 20}),            
            new FormulaireItem('t_vaegectypech', {largeur: 20}),  
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_cvt', {largeur: 20}), 
            new FormulaireItem('t_cvtdsc', {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('esadatvig', {largeur: 20}),
            new FormulaireItem('esaprcaug', {largeur: 20}), 
            new FormulaireItem('esaflgina', {largeur: 20, default: 0, readonly: (data) => data.vaesysprvret === 'RETR'}),
            new FormulaireItemSautDeLigne(),           
            new FormulaireItem('esaflgact', {largeur: 20, readonly: true, default: 1}),
            new FormulaireItem('esasalbas', {largeur: 20})                       
        ]);

        // Onglets échelon
        vm.eecMultiOccurrenceOptions = {
            bloc: 'eec',
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'eeccod', largeur: 100},
                {nom: 'eecsal', largeur: 120},
                {nom: 'eecsalmin', largeur: 120},
                {nom: 'eecsalmax', largeur: 120},
                {nom: 'eecfct', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('eeccod'   , {largeur: 10, readonly: (data) => data.vaertotyp === '02'  || vm.monoOccurrence.data.vaesysprvret === 'PROD'}),
                new FormulaireItem('eecsal'   , {largeur: 41, readonly: (data) => data.vaertotyp === '02'  || vm.monoOccurrence.data.vaesysprvret === 'PROD'}),
                new FormulaireItem('eecsalmin', {largeur: 10, readonly: (data) => data.vaertotyp === '02'  || vm.monoOccurrence.data.vaesysprvret  === 'PROD'}),
                new FormulaireItem('eecsalmax', {largeur: 10, readonly: (data) => data.vaertotyp === '02'  || vm.monoOccurrence.data.vaesysprvret  === 'PROD'}),
                new FormulaireItem('eecfct'   , {largeur: 10, readonly: (data) => data.vaertotyp === '02'  || vm.monoOccurrence.data.vaesysprvret  === 'PROD'})
            ])
        }

        
      

    }
}
