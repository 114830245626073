import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageTF0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0026Controller(
    Formulaire: IFormulaireClass, 
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageTF0026 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('annee', {  largeur: 10}),
            new FormulaireItem('regime', {  largeur: 40})
        ]),
        colonnesVisibles: [            
            { nom: 'parametre_client', largeur: 75 },
            { nom: 'desc_parametre_client', largeur: 200 },
            { nom: 'unite_calcul', largeur: 140 },
            { nom: 'valeur', largeur: 140 },
            { nom: 'ajustement', largeur: 140 },
            { nom: 'total', largeur: 126 }
        ]
    };
}
