import { IComponentController } from 'angular';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageSp0008 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    evqMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Sp0008Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageSp0008 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_EVF', [
            new FormulaireGroupe('LBL_TEV', new Formulaire([
                new FormulaireItem('tevcleint'),
                new FormulaireItem('grecleint')
            ]), { largeur: 50 }
            ),
            new FormulaireGroupe('LBL_EVF', new Formulaire([
                new FormulaireItem('evfcod', { largeur: 50 }),
                new FormulaireItem('evfflgact', { largeur: 50, default: 1 }),
                new FormulaireItem('evfdsc', { largeur: 100 })
            ]), { largeur: 50 }),
            new FormulaireGroupe('LBL_PERS', new Formulaire([
                new FormulaireItem('temcleint'),
                new FormulaireItem('unocleint')
            ]))

        ])
    };

    vm.evqMultiOccurrenceOptions = {
        bloc: 'evq',
        colonnesVisibles: [
            { nom: 'vaeevqsec_dsc', largeur: 130 },
            { nom: 'evqseqaff', largeur: 100 },
            { nom: 'evqtit', largeur: 200 },
            { nom: 'evqtxt', largeur: 500 },
            { nom: 'evqnbrlig', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeevqsec'),
            new FormulaireItem('evqseqaff'),
            new FormulaireItem('evqtit', { disabled: (data: any) => (data.vaeevqsec === 'S0') }),
            new FormulaireItem('evqtxt', { largeur: 66.66 }),
            new FormulaireItem('evqnbrlig', { disabled: (data: any) => (data.vaeevqsec === 'S0' || data.vaeevqsec === 'S1') })
        ], { largeurDefaut: 33.33 })
    };
}
