import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRr0126 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDle: IFormulaire;
    xptMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0126Controller($stateParams: any,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0126 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireDle = new Formulaire('LBL_BLC_DLE', [
            new FormulaireItem('urgcleint',{default: $stateParams.urgcleint}),
            new FormulaireItem('strcleint'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tlecleint'),
            ],{largeurDefaut: 50}
        );
    }
}
