import {IComponentController} from 'angular';
import {IMenuActionsRhService} from '../../services/menu/menu-actions-rh.service';
import {IMenuActionsFinanceService} from '../../services/menu/menu-actions-finance.service';
import {IMenu} from '../../../../core/services/menu/menu.service';
import {IMultiOccurrence} from '../../../../core/services/multi-occurrence.service';
import {IEcranContextController} from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';

interface IComposantButtonFabCycle extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    actions: IMenu;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function ButtonFabCycleController(MenuActionsRh: IMenuActionsRhService,
                                              MenuActionsFinance: IMenuActionsFinanceService) {
    const vm: IComposantButtonFabCycle = this;

    vm.$onInit = $onInit;

    function $onInit() {
        if (vm.multiOccurrence.fonctions.selectionCycle) {
            if (vm.multiOccurrence.initialized) {
                initMenuActions();
            } else {
                vm.multiOccurrence.once('ready', () => {
                    initMenuActions()
                });
            }
        }
    }

    function initMenuActions() {
        // On ajoute les actions selon si le schéma contient un cycle
        const menuActions = getMenuActions();

        const nomFonction = (vm.multiOccurrence.bloc || vm.multiOccurrence.mnemonique).toUpperCase();
        const fonction = vm.ecranContextCtrl.ecranDetails.fonctions[`${nomFonction}.BOUACT`];

        if (menuActions && (!fonction || fonction.flgacc)) {
            vm.actions = menuActions;
            vm.multiOccurrence.rangeesSelectionnables = true;
            vm.multiOccurrence.fonctions.selectionnerToutesLesRangees = true;
        } else if (!vm.multiOccurrence.actionsSelection) {
            vm.multiOccurrence.rangeesSelectionnables = false;
            vm.multiOccurrence.fonctions.selectionnerToutesLesRangees = false;
        }
    }

    function getMenuActions() {
        if (vm.multiOccurrence.schema['doccleint']) {
            return getMenuActionsFinance();
        } else if (vm.multiOccurrence.schema['trncleint']) {
            return getMenuActionsRh();
        }
    }

    function getMenuActionsFinance() {
        vm.multiOccurrence.colonnesCachees.push('doccleint');
        const menuActionsFinance = new MenuActionsFinance(vm.multiOccurrence);
        return menuActionsFinance.getMenu();
    }

    function getMenuActionsRh() {
        vm.multiOccurrence.colonnesCachees.push('trncleint', 'eclcleint');
        const menuActionsRh = new MenuActionsRh(vm.multiOccurrence);
        return menuActionsRh.getMenu();
    }
}
