import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc0500 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0500Controller(Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0500 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        formulaire: new Formulaire([
            new FormulaireItem('vecvalcle', { required: true }),
            new FormulaireItem('vecdsc', { required: true, largeur: 50 }),
            new FormulaireItem('vecdsclng', { required: true, largeur: 50 }),
            new FormulaireItem('vecflgact', { default: 1 })
        ], { largeurDefaut: 25 }),
        colonnesVisibles: [
            { nom: 'vecvalcle', largeur: 75 },
            { nom: 'vecdsc', largeur: 175 },
            { nom: 'vecdsclng', largeur: 262 },
            { nom: 'vecflgact', largeur: 70 },
        ]
    };
}
