import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";

interface IPageMc0168 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulairePec: IFormulaire;
    pemMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0168Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc0168 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulairePec = new Formulaire('LBL_BLC_PEC', [
            new FormulaireItem('peccod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pecann'),
            new FormulaireItem('pecper'),
            new FormulaireItem('pecflgreg'),
            new FormulaireItem('pecdatdeb'),
            new FormulaireItem('pecdatfin')
        ], { largeurDefaut: 33 });

        vm.pemMultiOccurrenceOptions = {
            colonnesVisibles: [
                { nom: 'vaesysmodcod_dsc', largeur: 150 },
                { nom: 'pemdatfer', largeur: 200 },
                { nom: 'pemdatfertmp', largeur: 200 },
                { nom: 'vaepemstu_dsc', largeur: 120 }
            ],

            formulaire: new Formulaire([
                new FormulaireItem('pemdatfertmp'),
                new FormulaireItem('vaepemstu')
            ], { largeurDefaut: 25 })
        };
    }
}
