import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePT0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt0001Controller(Menu: IMenuClass) {
    const vm: IPagePT0001 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'pdvcod', largeur: 200},
            {nom: 'pdvdsc', largeur: 200},
            {nom: 'pdvdatdeb', largeur: 200},
            {nom: 'pdvdatfin', largeur: 200},
            {nom: 'pdvhreremfer', largeur: 200},
            {nom: 'asgcod', largeur: 200},
            {nom: 'asgdsc', largeur: 200},
            {nom: 'bqecod', largeur: 200},
            {nom: 'bqedsc', largeur: 200},
            {nom: 'pdvflgdis', largeur: 200},
            {nom: 'pdvflgtmp', largeur: 200},
            {nom: 'pdvcom', largeur: 200}
        ]
    };
}
