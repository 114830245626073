import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {INotificationHandler} from '../../../../../core/services/utils/notification-handler.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageIN0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function In0022Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         notificationHandler: INotificationHandler) {
    const vm: IPageIN0022 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    if (vm.valeursEcran.mesmag) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesmag
        });
        vm.multiOccurrence.disabledRow = () => true;
    }

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('magcleint', { largeur: 20, required:true, supprimable:false }),
            new FormulaireItemDivider('LBL_LOCALISATION', { hidden: (data: any) => !data.magcleint }),
            new FormulaireItem('malcleint_de', {
                largeur: 20,
                hidden: (data: any) => !data.magcleint,
                resourceParams: (data:any) => {
                    return {
                        magcleint: data.magcleint
                    };
                },
            }),
            new FormulaireItem('malcleint_a', {
                largeur: 20,
                hidden: (data: any) => !data.magcleint,
                resourceParams: (data:any) => {
                    return {
                        magcleint: data.magcleint
                    };
                },
            }),

            // // En attente de la jira Sos-3024 LOV de recherche des produits
            // new FormulaireGroupeAccordeon('LBL_PRODUIT_DE', new Formulaire([
            //     new FormulaireGroupe('LBL_STRUC_PRODUIT',
            //         new Formulaire([
            //             new FormulaireItem('st1cleint'),
            //             new FormulaireItem('st2cleint')
            //         ], { largeurDefaut: 100 })
            //     ),
            //     new FormulaireItem('procleint')
            // ]), { hidden: (data: any) => !data.magcleint, opened: false }),

            new FormulaireGroupe('LBL_STRUCTURE_PRODUIT',
                new Formulaire([
                    new FormulaireItem('st1cleint', { largeur: 20 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('st2cleint', { largeur: 20 }),
                    new FormulaireItem('st3cleint', { largeur: 20 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('st4cleint', { largeur: 20 }),
                    new FormulaireItem('st5cleint', { largeur: 20 })
                ]),
                { hidden: (data: any) => !data.magcleint }
            )
        ]),
        colonnesFixesGauche: [
            {nom: 'malcoddsc', largeur: 420}
        ],
        colonnesVisibles: [
            {nom: 'procod', largeur: 200},
            {nom: 'prodsc', largeur: 300},
            {nom: 'forcoddsc', largeur: 250},
            {nom: 'cqlqteinv', largeur: 200},
            {nom: 'cqmprxmoy', largeur: 200}
        ]
    };
}
