import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMC0067 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0067Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         mc0028Route: IRoute) {
    const vm: IPageMC0067 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mc0028Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0028Route.NAME) 
        ]),
        colonnesVisibles: [
            {nom: 'redcod', largeur: 120},
            {nom: 'reddsc', largeur: 300},
            {nom: 'etdcodann', largeur: 90},
            {nom: 'etddscann', largeur: 200},
            {nom: 'etdcodfer', largeur: 90},
            {nom: 'etddscfer', largeur: 200},
            {nom: 'cycflgfinann', largeur: 110}
        ]
    };
}
