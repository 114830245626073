import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IData} from "../../../../../core/services/data-linker.service";

interface IPageRr0127 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function rr0127Controller(Formulaire: IFormulaireClass,
                                         Operateur: IOperateurService,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         rr0036Route: IRoute,
                                         $stateParams: any
                                         ) {
    const vm: IPageRr0127 = this;

    // Forage pour la compétence
    const forageDaf = new MenuItemForage(
        rr0036Route.NAME ,
        (data: any) => ({
            id: data.dafcleint_trn
        }),
        {
            hidden: (data: any) => !data.dafcleint_trn
        }
    );
    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('anranncri', {
                required: true,
                default: $stateParams.anrann,
                ignoreEtat: $stateParams.anrann,
                readonly: (data: IData) => data.$params.anrann
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 200},
        ],
        colonnesFixesDroite: [
            {nom: 'dmaflgtrt', largeur: 120},
            {nom: 'dmaflgdaf', largeur: 180, menu: [forageDaf]}
        ],
        colonnesCachees: [
            'dafcleint_trn',
            'vaesystypavi'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('anrann', {default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.anranncri}),
            new FormulaireItem('demcleint' ),
            new FormulaireItem('dmaflgtrt')
        ], {largeurDefaut: 50} )
    };
}
