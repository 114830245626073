import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IComponentController, IScope } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IFiltre, IFiltreClass } from '../../../../../core/services/filtre.service';
interface IPageAs0016 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
    menuBandeau: IMenu;
    segMultiOccurrenceOptions: IMultiOccurrenceOptions;
    segMultiOccurrence: IMultiOccurrence;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function As0016Controller(
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireItem: IFormulaireItemClass,
    Formulaire: IFormulaireClass,
    $scope: IScope,
    as0091Route: IRoute,
    as0007Route: IRoute,
    Pagination: IPaginationClass,
    $stateParams: IStateParamsService,
    Operateur: IOperateurService,
    Filtre: IFiltreClass
) {
    const vm: IPageAs0016 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.$onInit = $oninit;
    function $oninit() {

        initMultiSeg()

        vm.multiOccurrenceOptions = {
            navigatePage: as0007Route.NAME,
            autoFetch: true,
            fonctions: {
                pagination: true,
                suiviModification: true,
                importation: true,
                rechercheCollapsable: false,
                criteresSuggeresVisibles: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                { nom: 'sutcod', largeur: 94 },
                { nom: 'sutdsc', largeur: 280 },
                { nom: 'vaesuttyp', largeur: 130 },
                { nom: 'sutdatdeb', largeur: 100 },
                { nom: 'sutdatfin', largeur: 100 },
                { nom: 'vaesuteva', largeur: 130 },
                { nom: 'sutflgact', largeur: 130 }
            ],
            colonnesCachees: ['vaesegdec', 'sutcleint', 'affsegcod1', 'affsegcod2', 'affsegcod3', 'affsegcod4', 'affsegcod5'],
            multiOccurrenceOptionsEnfant: vm.segMultiOccurrenceOptions
        };


        $scope.$watch('vm.multiOccurrence.initialized', (initialized: boolean) => {
            if (initialized) {
                setFiltres()
            }
        });

        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.vaesegdec', (newValue) => {
            if (newValue) {
                if (!$stateParams?.vaesegdec && !$stateParams?.sutcod) {
                    setFiltres()
                }
            }
        });

        $scope.$watch('vm.multiOccurrence.activeRowCleint', (newValue) => {
            if (newValue) {
                if (vm.multiOccurrence.multiOccurrenceEnfant) {
                    vm.multiOccurrence.multiOccurrenceEnfant.fetchSourceDetails(vm.segMultiOccurrenceOptions)
                }
            }
        });

        function setFiltres() {
            vm.multiOccurrence.reinitialiser().then(() => {
                getFiltres()
                vm.multiOccurrence.fetchDataList();
            });
        }

        function getFiltres() {
            vm.multiOccurrence.etatSelected.filtres = []
            vm.multiOccurrence.criteresSuggeres = undefined
            let vaesegdec: any = (!vm?.valeursEcran?.vaesegdec) ? "ACT" : vm.valeursEcran.vaesegdec;
            let vaesutmodx: any = "vaesutmodimp";
            const filtres: Array<IFiltre> = [];
            let criteresSuggeres: any;
            vm.multiOccurrenceOptions.criteresSuggeres = undefined

            //il n'y a pas de parametres alors on ajoute un critere suggere et un filtre afin d'eviter un error dans la requete "sans données"
            if (!$stateParams?.vaesegdec && !$stateParams?.sutcod) {
                if (vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.vaesegdec) {
                    vaesutmodx = (vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.vaesegdec == 'ACT') ? "vaesutmodimp" : "vaesutmodint"
                }
                //on ajoute le critere suggere afin de filtrer vaesegdec dans la vue en utilisant la fonction pab_as0016_s2.fub_get_vaesegdec
                criteresSuggeres = new Formulaire([
                    new FormulaireItem('vaesegdec',
                        {
                            ignoreEtat: true,
                            default: vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.vaesegdec
                        }),
                ])
                //on ajoute un filtre pour l'ajouter dans la section where dans le backend
                filtres.push(
                    new Filtre({
                        colonne: vaesutmodx,
                        operateur: Operateur.EGALE,
                        valeur: "DEC",
                        visible: false
                    })
                )
                vm.multiOccurrence.criteresSuggeres = criteresSuggeres;
                if (!vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.vaesegdec) {
                    vm.multiOccurrence.etatSelected.criteresSuggeresData.vaesegdec = vaesegdec
                }
                vm.multiOccurrence.criteresSuggeres.initFormData(criteresSuggeres, vm.multiOccurrence.dataTypes);
                vm.multiOccurrence.emit('etatUpdate')

            } else {

                //on ajoute juste filtres lorqu'on arriver par un forage
                vaesegdec = (!$stateParams?.vaesegdec) ? vaesegdec : $stateParams?.vaesegdec
                if ($stateParams.sutcod) {
                    filtres.push(
                        new Filtre({
                            colonne: "sutcod",
                            operateur: Operateur.EGALE,
                            valeur: $stateParams.sutcod
                        })
                    )
                }

                filtres.push(
                    new Filtre({
                        colonne: "vaesegdec",
                        operateur: Operateur.EGALE,
                        valeur: vaesegdec,
                        readOnly: true,
                    }),
                    new Filtre({
                        colonne: vaesegdec == "ACT" ? "vaesutmodimp" : "vaesutmodint",
                        operateur: Operateur.EGALE,
                        valeur: "DEC",
                        visible: false
                    })
                )

                vm.multiOccurrence.criteresSuggeres = undefined
            }
            vm.multiOccurrence.etatSelected.filtres = filtres;
        }
    }
    function initMultiSeg() {
        vm.segMultiOccurrenceOptions = {
            fonctions: {
                libellesDynamiques: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_BTN_NOUVEAU',
                    as0091Route.NAME,
                    () =>
                    ({
                        sutcleint: vm.multiOccurrence.activeRowCleint,//data.sutcleint,
                        vaesegdec: ($stateParams?.vaesegdec) ? $stateParams?.vaesegdec : vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.vaesegdec//data.vaesegdec
                    })
                    ,
                    {
                        fonction: 'BOUNOU',
                        onClose: () => {
                            if (vm?.segMultiOccurrence) {
                                vm.segMultiOccurrence.fetchDataList();
                            }
                        }
                    }
                )
            ]),
            colonnesVisibles: [
                { nom: 'segcod01', largeur: 310, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod1 } },
                { nom: 'segdsc01', largeur: 310, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod1 } },
                { nom: 'segcod02', largeur: 230, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod2 } },
                { nom: 'segdsc02', largeur: 310, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod2 } },
                { nom: 'segcod03', largeur: 230, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod3 } },
                { nom: 'segdsc03', largeur: 310, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod3 } },
                { nom: 'segcod04', largeur: 230, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod4 } },
                { nom: 'segdsc04', largeur: 310, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod4 } },
                { nom: 'segcod05', largeur: 230, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod5 } },
                { nom: 'segdsc05', largeur: 310, hidden: () => { return vm.multiOccurrence && !vm.multiOccurrence.getDataActiveRow().affsegcod5 } }
            ],
            colonnesFixesDroite: [
                { nom: 'actcod', largeur: 141, hidden: (data) => data.$ancetre.vaesegdec === 'TAG' },
                { nom: 'actdsc', largeur: 280, hidden: (data) => data.$ancetre.vaesegdec === 'TAG' },
                { nom: 'tagcod', largeur: 86, hidden: (data) => data.$ancetre.vaesegdec === 'ACT' },
                { nom: 'tagdsc', largeur: 280, hidden: (data) => data.$ancetre.vaesegdec === 'ACT' },
                {
                    nom: '', largeur: 130,
                    menu: [
                        new MenuItemEcran(
                            'LBL_DECOUPAGE',
                            as0091Route.NAME,
                            (data: any) => ({
                                id: data.segcleint,
                                vaesegdec: ($stateParams?.vaesegdec) ? $stateParams.vaesegdec : 'ACT',
                                sutcleint: data.sutcleint
                            }),
                            {
                                icon: 'launch',
                                fonction: 'BOUCOMREQ',
                                iconButton: true,
                                onClose: () => {
                                    if (vm?.segMultiOccurrence) {
                                        vm.segMultiOccurrence.fetchDataList();
                                    }
                                }
                            }
                        )
                    ]
                }
            ],
            colonnesCachees: ['sutcleint', 'segcleint'],
            bloc: 'seg',
            resourceParamsDynamique: () => ({
                sutcleint: vm.multiOccurrence && vm.multiOccurrence.activeRowCleint,
                vaesegdec: ($stateParams?.vaesegdec) ? $stateParams?.vaesegdec : vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.vaesegdec//vm.multiOccurrence.etatSelected.criteresSuggeresData.vaesegdec
            }),
        };
    }
}
