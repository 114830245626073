import IStateService = angular.ui.IStateService;
import { IRootScopeService, IScope } from 'angular';
import { IRoute } from '../../../../core/interfaces/route.interface';

interface IErreurChargementEcran {
    reload(): void;
    reloading: boolean;
}

interface IErreurChargementEcranParams {
    route: IRoute;
    params: any;
}

/* @ngInject */
export default function ErreurChargementEcranController($state: IStateService,
    $stateParams: IErreurChargementEcranParams,
    $rootScope: IRootScopeService,
    $scope: IScope) {
    const vm: IErreurChargementEcran = this;

    const stopStateChangeErrorListener = $rootScope.$on('$stateChangeError', () => {
        vm.reloading = false;
    });

    $scope.$on('$destroy', () => {
        stopStateChangeErrorListener();
    });

    vm.reload = function reload() {
        vm.reloading = true;
        $state.go($stateParams.params.route.NAME, $stateParams.params);
    }
}
