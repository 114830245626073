import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pr0007Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPagePr0007 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'caocod', largeur: 65 },
            { nom: 'caodsc', largeur: 230 },
            { nom: 'pfocod', largeur: 110 },
            { nom: 'pfodsc', largeur: 230 },
            { nom: 'caoflgstu', largeur: 74 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('caocod', { largeur: 15 }),
            new FormulaireItem('caodsc', { largeur: 35 }),
            new FormulaireItem('pfocleint', { largeur: 35 }),
            new FormulaireItem('caoflgstu', { largeur: 15, default: 1 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
