import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageTF0044 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0044Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageTF0044 = this;

    const filtres = [];

    if (ecranDetails.valeurs.tfhann) {
        filtres.push({
            colonne: 'tfhann',
            valeur: ecranDetails.valeurs.tfhann,
            readOnly: true
        });
    }

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres,
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'tfhann', largeur: 75},
            {nom: 'fpncod', largeur: 100},
            {nom: 'tckcod', largeur: 100},
            {nom: 'tqcajs', largeur: 139},
            {nom: 'fpndsc', largeur: 262},
            {nom: 'tckdsc', largeur: 262},
            {nom: 'tqccom', largeur: 262},
            {nom: 'tqcflggen', largeur: 80},
            {nom: 'tqcflguti', largeur: 75}
        ]
    };
}
