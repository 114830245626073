import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController, IFilterDate} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";

interface IPageMc0160 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireCpt: IFormulaire;
    formulaireIndicateurs: IFormulaire;
    valeursEcran: any;
    usaimp: IMenuItem;
    cpsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0160Controller(mc0046Route: IRoute,
                                         dateFilter: IFilterDate,
                                         Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass ) {
    const vm: IPageMc0160 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Usage d'imputation
        vm.usaimp = new MenuItemEcran('LBL_BLC_UVI', mc0046Route.NAME, () => {
            return {
                vaeusient: 'CPT',
                uvicleintref: vm.monoOccurrence.data.cptcleint
            };
        }, {
            icon: 'view_list',
            iconButton: false,
            secondaire: true
        });
        vm.monoOccurrenceOptions = {
            fonctions: {importation: true}
        };        
        vm.formulaireCpt = new Formulaire('LBL_BLC_CPT', [
            new FormulaireItem('cptcod'),
            new FormulaireItem('naccleint'),
            new FormulaireItem('aut__vaesysnatctb'),
            new FormulaireItem('aut__vaesysnatctb_dsc'),
            new FormulaireItem('cptdatact', {default: dateFilter(new Date(), 'yyyy-MM-dd')}),
            new FormulaireItem('cptdatina'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cptdsc', {largeur: 50}),
            new FormulaireItem('cptdscabr', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaecpttypcpt'),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireItemDivider('LBL_DIV_AUT'),
            new FormulaireItem('remcleint'),
            new FormulaireItem('cptcleintlie', { resourceParams: (data:any) => ({
                    vaesysnatctb: data.aut__vaesysnatctb,
                    vaesysdevcod: data.vaesysdevcod,
                    cptcleint: data.cptcleint
                })
            }),
            new FormulaireItem('soncleint'),
            new FormulaireItem('unmcleint')            
        ]
    );

        vm.formulaireIndicateurs = new Formulaire('LBL_BLC_IND', [
            new FormulaireItem('cptflgdet'),
            new FormulaireItem('cptflgdis'),
            new FormulaireItem('cptflgits'),
            new FormulaireItem('cptflggeb'),
            new FormulaireItem('cptflgt4a'),
            new FormulaireGroupeAccordeon('LBL_REG_ADM', new Formulaire([
                new FormulaireItem('rgaind01', { titre: vm.valeursEcran.rgalbl01 }),
                new FormulaireItem('rgaind02', { titre: vm.valeursEcran.rgalbl02 }),
                new FormulaireItem('rgaind03', { titre: vm.valeursEcran.rgalbl03 }),
                new FormulaireItem('rgaind04', { titre: vm.valeursEcran.rgalbl04 }),
                new FormulaireItem('rgaind05', { titre: vm.valeursEcran.rgalbl05 }),
                new FormulaireItem('rgaind06', { titre: vm.valeursEcran.rgalbl06 }),
                new FormulaireItem('rgaind07', { titre: vm.valeursEcran.rgalbl07 }),
                new FormulaireItem('rgaind08', { titre: vm.valeursEcran.rgalbl08 }),
                new FormulaireItem('rgaind09', { titre: vm.valeursEcran.rgalbl09 }),
                new FormulaireItem('rgaind10', { titre: vm.valeursEcran.rgalbl10 })
            ], {largeurDefaut: 20}))
        ]);

        vm.cpsMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'cpsann'       , largeur: 80},
                {nom: 'sfucod'       , largeur: 100},
                {nom: 'vaesfutyp_dsc', largeur: 140},
                {nom: 'sfudsc'       , largeur: 160}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cpsann'),
                new FormulaireItem('sfucleint')
            ])
        };
    }
}
