import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IStateParamsService} from 'angular-ui-router';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageTs0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Ts0017Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageTs0017 = this;


    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'gidcleint', valeur:$stateParams.gidcleint, visible: false}
        ],
        colonnesFixesGauche: [
            {nom: 'aftcod', largeur: 110}, // min sinon ...
            {nom: 'aftdsc', largeur: 170}
        ],
        colonnesFixesDroite: [
            {nom: 'gcgflgexc', largeur: 120} // min sinon ...
        ],
        colonnesVisibles: [
            {nom: 'tafcod', largeur: 90},
            {nom: 'tafdsc', largeur: 170},
            {nom: 'temcod', largeur: 80},
            {nom: 'temdsc', largeur: 252},
            {nom: 'vacvalcle', largeur: 130}, // min sinon ...
            {nom: 'vacdsc', largeur: 150},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodsc', largeur: 170}
        ],
        colonnesCachees:[
            'gidcleint',
            'gcgcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('aftcleint'),
            new FormulaireItem('gidcleint',{ default: $stateParams.gidcleint}),
            new FormulaireItem('tafcleint'),
            new FormulaireItem('gcgflgexc'),
            new FormulaireItem('temcleint'),
            new FormulaireItem('vacreglit'),
            new FormulaireItem('unocleint',{ required: true})
        ])
    };
}
