import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0022 {

    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0022Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0022 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaesysmodcod', largeur: 100},
            {nom: 'vaesysmodcod_dsc', largeur: 200},
            {nom: 'vaemoddepmod_dsc', largeur: 200},
            {nom: 'moddefperdoc', largeur: 100},
            {nom: 'moddefpersys', largeur: 100},
            {nom: 'moddefpercou', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesysmodcod', {
                largeur: 50,  
                resourceParams: (data: any) => {
                return {origine: 'MOD'};
            }}),
            new FormulaireItem('vaemoddepmod', {
                largeur: 50, 
                resourceParams: (data: any) => {
                return {origine: 'DEP',
                        vaesysmodcod:data.vaesysmodcod};
            }}),
            new FormulaireItem('moddefper', {largeur: 50, default:3})
        ])
    };
}
