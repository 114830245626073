import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGl0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0013Controller(gl0041Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGl0013 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gl0041Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gl0041Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'ceecod', largeur: 150},
            {nom: 'ceedsc', largeur: 400}
        ]
    };
}
