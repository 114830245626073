import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0026 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0026Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0026 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'etdcod', largeur: 100 },
            { nom: 'etddsc', largeur: 270 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('etdcod', { largeur: 30 }),
            new FormulaireItem('etddsc', { largeur: 70 })
        ])
    };
}
