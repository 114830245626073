import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageAC0096 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0096Controller(Menu: IMenuClass) {
    const vm: IPageAC0096 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'defcod', largeur: 100},
            {nom: 'defflgact', largeur: 80},
            {nom: 'defflgpub', largeur: 80},
            {nom: 'defdsc', largeur: 260},
            {nom: 'defcom', largeur: 260},
            {nom: 'egocod', largeur: 100},
            {nom: 'egodsc', largeur: 195}
        ]
    };
}
