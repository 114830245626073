import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateService } from 'angular-ui-router';

interface IPageAc0040 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IAc0040Params {
    menuId: string;
}

/* @ngInject */
export default function Ac0040Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         ac0041Route: IRoute,
                                         $state: IStateService,
                                         $stateParams: IAc0040Params
                                         ) {
    const vm: IPageAc0040 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_COPIE',
                'BOUCOP',
                () => ({}),
                {   icon: 'content_copy',
                    fonction: 'BTN_COPIE',
                    successAction: (retour:any) => {
                        $state.go(ac0041Route.NAME, {
                            id: retour.doccleintnew,
                            menuId: $stateParams.menuId
                        });
                    }
                }
            )
        ]),
        navigatePage: ac0041Route.NAME,
        navigateParams: (rowData: any) => ({
            id: (rowData.doccleintref)
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage(ac0041Route.NAME)
        ]),
        colonnesFixesGauche: [
            {nom: 'dpfnumdoc', largeur: 130},
            {nom: 'dpfnumdocseq', largeur: 80}
        ],
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 80},
            {nom: 'dpfdsc', largeur: 220},
            {nom: 'etddsc', largeur: 180},
            {nom: 'docaffnumdoc01', largeur: 180},
            {nom: 'intcodreq', largeur: 110},
            {nom: 'intnomreq', largeur: 262},
            {nom: 'intcodach', largeur: 110},
            {nom: 'intnomach', largeur: 262}
        ],
        colonnesCachees: ['doccleintref']
    };
}
