import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGs2003 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ttaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    icoMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs2003Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    gs0024Route: IRoute) {

    const vm: IPageGs2003 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_TIC', [
            new FormulaireItem('ticcod'),
            new FormulaireItem('ticdsc')
        ])
    };

    vm.ttaMultiOccurrenceOptions = {
        bloc: 'tta',
        colonnesVisibles: [
            {nom: 'stadsc', largeur: 100},
            {nom: 'stanom', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('stacleint', {largeur: 100})
        ])
    };

    vm.icoMultiOccurrenceOptions = {
        bloc: 'ico',
        colonnesVisibles: [
            {nom: 'icoordaff', largeur: 100},
            {nom: 'icodsc', largeur: 250},
            {nom: 'icolib', largeur: 150},
            {nom: 'icoflguni', largeur: 100},
            {nom: 'icolng', largeur: 100},
            {nom: 'icoflgfix', largeur: 100},
            {nom: 'icoflgjus', largeur: 100},
            {nom: 'vaesystypdon_dsc', largeur: 130},
            {nom: 'vaesystyplet_dsc', largeur: 130},
            {nom: 'icoflglov', largeur: 100, menu: [
                new MenuItemForage(
                    'LBL_FORAGE_GS0024',
                    gs0024Route.NAME,
                    (rowData: any) => {
                        return {
                            icocleint: rowData.icocleint
                        };
                    }
                )] 
            }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('icoordaff'),
            new FormulaireItem('icodsc'),
            new FormulaireItem('icolib'),
            new FormulaireItem('icolng'),
            new FormulaireItem('vaesystypdon', {direction: 'vertical'}),
            new FormulaireItem('vaesystyplet', {direction: 'vertical'}),
            new FormulaireItem('icoflguni'),
            new FormulaireItem('icoflgfix'),
            new FormulaireItem('icoflgjus'),
            new FormulaireItem('icoflglov')
        ], {largeurDefaut: 33.33})
    }
}
