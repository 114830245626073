import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageMc2015Params {
    dccleint: string
}

interface IPageMc2015 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIne: IFormulaire;    
    inhMultiOccurrence: IMultiOccurrence;
    inhMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc2015Controller(Formulaire: IFormulaireClass, 
    Operateur: IOperateurService,
    $stateParams: IPageMc2015Params,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc2015 = this;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'dccleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.dccleint,
                visible: false
            }
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'imp', largeur: 150 },
            { nom: 'tyhdsc', largeur: 150 },
            { nom: 'trbmnt', largeur: 100 },
            { nom: 'prudsc101', largeur: 180 },
            { nom: 'cptdsc', largeur: 180 }
        ]
    };


}
