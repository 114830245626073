import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageDO0025 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0025Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService) {
    const vm: IPageDO0025 = this;

    const champsCriteresSuggeres = [
        new FormulaireItem('rglcleint', {
            required: false,
            default: $stateParams.rglcleint
        })
    ];

    if (!$stateParams.employe) {
        champsCriteresSuggeres.push(new FormulaireItem('demcleint', {
            required: false
        }));
    }

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: !$stateParams.employe
        },
        criteresSuggeres: new Formulaire(champsCriteresSuggeres),
        colonnesVisibles: [
            {nom: 'rglcod', largeur: 200},
            {nom: 'rgldscabr', largeur: 200},
            {nom: 'eplnummat', largeur: 200, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 200, hidden: $stateParams.employe},
            {nom: 'rdddatvig', largeur: 200},
            {nom: 'rddflgdsp', largeur: 200},
            {nom: 'rddcom', largeur: 200}
        ]
    };
}
