import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0035 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0035Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0035 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'etycod', largeur: 120 },
            { nom: 'etydsc', largeur: 180 },
            { nom: 'chgcod', largeur: 100 },
            { nom: 'chgdsc', largeur: 301 },
            { nom: 'etyflgpai', largeur: 100 },
            { nom: 'etyflgweb', largeur: 100 },
            { nom: 'etyflgdft', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('etycod', { largeur: 25 }),
            new FormulaireItem('etydsc', { largeur: 75 }),
            new FormulaireItem('chgcleint', { largeur: 25 }),
            new FormulaireItem('etyflgpai', { largeur: 25 }),
            new FormulaireItem('etyflgweb', { largeur: 25 }),
            new FormulaireItem('etyflgdft', { largeur: 25 })
        ]),
        fonctions: {
            rechercheCollapsable: false            
        }
    };
}
