import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageMR0320 extends IComponentController {
    valeursEcran: any;
    formulairePfp: IFormulaire;
    formulairePnl: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ppbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0320Params {
    province: string;
    pafcleint: number;
}

/* @ngInject */
export default function Mr0320Controller($stateParams: IMr0320Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMR0320 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulairePfp = new Formulaire('LBL_BLC_PFP', [
            new FormulaireItem('vaesyscodprv', { default: $stateParams.province }),
            new FormulaireItem('pfptd1mntbas', { largeur: 10 }),
            new FormulaireItem('pfpimftaut3', { largeur: 10 }),
            new FormulaireItem('pafcleint', { default: $stateParams.pafcleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_ASSURANCE_EMP',
                new Formulaire([
                    new FormulaireItem('pfpasemga'),
                    new FormulaireItem('pfpasetau'),
                    new FormulaireItem('pfpasefctemrreg'),
                    new FormulaireItem('pfpasefctemrred')
                ], { largeurDefaut: 25 }), { largeur: 100 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_CSSIAT',
                new Formulaire([
                    new FormulaireItem('pfpcssmga')
                ]), { largeur: 50 }
            ),
            new FormulaireGroupeAccordeon('LBL_GRATIFIC',
                new Formulaire([
                    new FormulaireItem('pfpgrarevmin'),
                    new FormulaireItem('pfpgrataumin')
                ]), { largeur: 50 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_PARAM',
                new Formulaire([
                    new FormulaireGroupe('LBL_V1',
                        new Formulaire([
                            new FormulaireItem('pnlmnt001v1', { titre: ' ' }),
                            new FormulaireItemSautDeColonne(),
                            new FormulaireItem('pnlmnt002v1', { titre: ' ',
                                libellesContour: {
                                    gauche: {
                                        largeur: 55,
                                        texte: 'LBL_T4INFEGA',
                                        alignment: 'left'
                                    },
                                    droite: 'LBL_OU'
                                }
                            }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('pnlprc001v1', { titre: ' ' }),
                            new FormulaireItem('t_pnlmnt002v1_1', { titre: ' ',
                                libellesContour: {
                                    gauche: 'LBL_OPEDEB',
                                    droite: 'LBL_OPEFIN'
                                },
                                default: () => vm.monoOccurrence.data.pnlmnt002v1
                            }),
                            new FormulaireItem('t_pnlmnt002v1_2', { titre: ' ',
                                libellesContour: {
                                    gauche: {
                                        largeur: 55,
                                        texte: 'LBL_T4SUP',
                                        alignment: 'left'
                                    }
                                },
                                default: () => vm.monoOccurrence.data.pnlmnt002v1
                            }),
                            new FormulaireItemSautDeColonne(),
                            new FormulaireItemSautDeLigne()
                        ], { largeurDefaut: 20 }), { largeur: 60 }
                    ),
                    new FormulaireGroupe('LBL_LCP',
                        new Formulaire([
                            new FormulaireItem('pnllimlcp'),
                            new FormulaireItem('pnlprclcp', { libellesContour: { gauche: 'LBL_OU' } })
                        ], { largeurDefaut: 5 }), { largeur: 40 }
                    ),
                    new FormulaireGroupeAccordeon('LBL_V2',
                        new Formulaire([
                            new FormulaireGroupe('',
                                new Formulaire([
                                    // Ligne 1
                                    new FormulaireItem('pnlmnt001v2', { titre: ' ', minWidth: 200 }),
                                    new FormulaireItemSautDeColonne({ largeur: 40, minWidth: 200 }),
                                    new FormulaireItem('pnlmnt002v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_AINFEGA' } }),
                                    new FormulaireItemSautDeColonne(),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 2
                                    new FormulaireItem('pnlmnt003v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'} }),
                                    new FormulaireItem('pnlmntv2', { titre: ' ', minWidth: 200 }),
                                    new FormulaireItem('t_pnlmnt002v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt002v2
                                    }),
                                    new FormulaireItem('pnlmnt004v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' } }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 3
                                    new FormulaireItem('pnlmnt005v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt004v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt004v2
                                    }),
                                    new FormulaireItem('pnlmnt006v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 4
                                    new FormulaireItem('pnlmnt007v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt006v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt006v2
                                    }),
                                    new FormulaireItem('pnlmnt008v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 5
                                    new FormulaireItem('pnlmnt009v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt008v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt008v2
                                    }),
                                    new FormulaireItem('pnlmnt010v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 6
                                    new FormulaireItem('pnlmnt011v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt010v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt010v2
                                    }),
                                    new FormulaireItem('pnlmnt012v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 7
                                    new FormulaireItem('pnlmnt013v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt012v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt012v2
                                    }),
                                    new FormulaireItem('pnlmnt014v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 8
                                    new FormulaireItem('pnlmnt015v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt014v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt014v2
                                    }),
                                    new FormulaireItem('pnlmnt016v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 9
                                    new FormulaireItem('pnlmnt017v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt016v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt016v2
                                    }),
                                    new FormulaireItem('pnlmnt018v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 10
                                    new FormulaireItem('pnlmnt019v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt018v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt018v2
                                    }),
                                    new FormulaireItem('pnlmnt020v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 11
                                    new FormulaireItem('pnlmnt021v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt020v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt020v2
                                    }),
                                    new FormulaireItem('pnlmnt022v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 12
                                    new FormulaireItem('pnlmnt023v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt022v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt022v2
                                    }),
                                    new FormulaireItem('pnlmnt024v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 13
                                    new FormulaireItem('pnlmnt025v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt024v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt024v2
                                    }),
                                    new FormulaireItem('pnlmnt026v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 14
                                    new FormulaireItem('pnlmnt027v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt026v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt026v2
                                    }),
                                    new FormulaireItem('pnlmnt028v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 15
                                    new FormulaireItem('pnlmnt029v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt028v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt028v2
                                    }),
                                    new FormulaireItem('pnlmnt030v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 16
                                    new FormulaireItem('pnlmnt031v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt030v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt030v2
                                    }),
                                    new FormulaireItem('pnlmnt032v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 17
                                    new FormulaireItem('pnlmnt033v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt032v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt032v2
                                    }),
                                    new FormulaireItem('pnlmnt034v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 18
                                    new FormulaireItem('pnlmnt035v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt034v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt034v2
                                    }),
                                    new FormulaireItem('pnlmnt036v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_INFEGA' }  }),
                                    new FormulaireItemSautDeLigne(),
                                    // Ligne 19
                                    new FormulaireItem('pnlmnt037v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEPLS'} }),
                                    new FormulaireItem('t_pnlprc001v2', { titre: ' ', minWidth: 200, libellesContour: { droite: 'LBL_OPEMUL'},
                                        default: () => vm.monoOccurrence.data.pnlprc001v2
                                    }),
                                    new FormulaireItem('t_pnlmntv2', { titre: ' ', minWidth: 200, default: () => vm.monoOccurrence.data.pnlmntv2 }),
                                    new FormulaireItem('t_pnlmnt036v2', { titre: ' ', minWidth: 200, libellesContour: { gauche: 'LBL_ASUP' },
                                        default: () => vm.monoOccurrence.data.pnlmnt036v2
                                    }),
                                    new FormulaireItemSautDeColonne({ largeur: 20, minWidth: 200 })
                                ], { largeurDefaut: 10 })
                            )
                        ])
                    )
                ]), { largeur: 100 }
            )
        ], { largeurDefaut: 25 });

        // Borne
        vm.ppbMultiOccurrenceOptions = {
            bloc: 'ppb',
            colonnesVisibles: [
                {nom: 'ppbborinf', largeur: 120},
                {nom: 'ppbtau'   , largeur: 85},
                {nom: 'ppbval'   , largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ppbborinf'),
                new FormulaireItem('ppbtau'),
                new FormulaireItem('ppbval')
            ], { largeurDefaut: 33.33 })
        };

        // Taux AE réduit
        vm.pfsMultiOccurrenceOptions = {
            bloc: 'pfs',
            colonnesVisibles: [
                {nom: 'rfecod'      , largeur: 150},
                {nom: 'rfenomabr'   , largeur: 241},
                {nom: 'pfsfctemrred', largeur: 135},
                {nom: 'pfsflg512'   , largeur: 105},
                {nom: 'bqecod'      , largeur: 89},
                {nom: 'bqedscabr'   , largeur: 262}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rfecleint', { largeur: 50 }),
                new FormulaireItem('pfsfctemrred'),
                new FormulaireItem('pfsflg512'),
                new FormulaireItem('bqecleint', { largeur: 50,
                    resourceParams: (data: any) => ({ pfsflg512: data.pfsflg512 })
                })
            ], { largeurDefaut: 25 })
        };
    }
}
