import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageTf0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0031Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTf0031 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'tfwannee', largeur: 110 },
            { nom: 'vaecodfedpro_dsc', largeur: 140 },
            { nom: 'tfwcptfic', largeur: 140 },
            { nom: 'tfwurlxsd', largeur: 265 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tfwannee', { largeur: 15 }),
            new FormulaireItem('vaecodfedpro', { largeur: 20}),
            new FormulaireItem('tfwcptfic', { largeur: 15 }),
            new FormulaireItem('tfwurlxsd', { largeur: 50 })
        ]),

        fonctions: {
            rechercheCollapsable: false
        }
    };
}
