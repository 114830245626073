import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePD0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0003Controller(Menu: IMenuClass) {
    const vm: IPagePD0003 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'pmicod', largeur: 200},
            {nom: 'pminumref', largeur: 200},
            {nom: 'pmidsc', largeur: 200},
            {nom: 'vaesyspdatyp_dsc', largeur: 200},
            {nom: 'vaepmistu_dsc', largeur: 200},
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'vecpmitit_dsc', largeur: 200},
            {nom: 'unacod', largeur: 200},
            {nom: 'unadsc', largeur: 200},
            {nom: 'emploc', largeur: 200},
            {nom: 'vecpmiendaut', largeur: 200},
            {nom: 'vecdsc', largeur: 200},
            {nom: 'pmidatdeb', largeur: 200},
            {nom: 'pmidatfin', largeur: 200},
            {nom: 'nbrintaut', largeur: 200},
            {nom: 'pmiflgeli', largeur: 200}
        ]
    };
}
