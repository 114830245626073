import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageTF0008 {
   multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function TF0008Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
) {
    const vm: IPageTF0008 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('folcleint', {
                required: true,
                largeur: 33
            }),
            new FormulaireItem('typform', {
                largeur: 33,
                default: 'A'
            }),
            new FormulaireItem('typ_req', {
                largeur: 33,
                default: 'M'
            })
        ]),
        pagination: {
            nombreElementPage: 25
        },
        colonnesFixesGauche: [{ nom: 'efacod', largeur: 103 }],
        colonnesVisibles: [
            { nom: 'eplnomprn', largeur: 262 },
            { nom: 'efanas', largeur: 105 },
            { nom: 'cincod', largeur: 133 },
            { nom: 'cindsc', largeur: 280 },
            { nom: 'rfecod', largeur: 179 },
            { nom: 'vaeindpai', largeur: 114 },
            { nom: 'vaeemftyp', largeur: 90 },
            { nom: 'c_1', largeur: 140 },
            { nom: 'c_2', largeur: 140 },
            { nom: 'c_3', largeur: 140 },
            { nom: 'c_4', largeur: 140 },
            { nom: 'c_5', largeur: 140 },
            { nom: 'c_6', largeur: 140 },
            { nom: 'c_7', largeur: 140 },
            { nom: 'c_8', largeur: 140 },
            { nom: 'c_9', largeur: 140 },
            { nom: 'c_10', largeur: 140 },
            { nom: 'c_11', largeur: 140 },
            { nom: 'c_12', largeur: 140 },
            { nom: 'c_13', largeur: 140 },
            { nom: 'c_14', largeur: 140 },
            { nom: 'c_15', largeur: 140 },
            { nom: 'c_16', largeur: 140 },
            { nom: 'c_17', largeur: 140 },
            { nom: 'c_18', largeur: 140 },
            { nom: 'c_19', largeur: 140 },
            { nom: 'c_20', largeur: 140 },
            { nom: 'c_21', largeur: 140 },
            { nom: 'c_22', largeur: 140 },
            { nom: 'c_23', largeur: 140 },
            { nom: 'c_24', largeur: 140 },
            { nom: 'c_25', largeur: 140 },
            { nom: 'c_26', largeur: 140 },
            { nom: 'c_27', largeur: 140 },
            { nom: 'c_28', largeur: 140 },
            { nom: 'c_29', largeur: 140 },
            { nom: 'c_30', largeur: 140 },
            { nom: 'c_31', largeur: 140 },
            { nom: 'c_32', largeur: 140 },
            { nom: 'c_33', largeur: 140 },
            { nom: 'c_34', largeur: 140 },
            { nom: 'c_35', largeur: 140 },
            { nom: 'c_36', largeur: 140 },
            { nom: 'c_37', largeur: 140 },
            { nom: 'c_38', largeur: 140 },
            { nom: 'c_39', largeur: 140 },
            { nom: 'c_40', largeur: 140 },
            { nom: 'c_41', largeur: 140 },
            { nom: 'c_42', largeur: 140 },
            { nom: 'c_43', largeur: 140 },
            { nom: 'c_44', largeur: 140 },
            { nom: 'c_45', largeur: 140 },
            { nom: 'c_46', largeur: 140 },
            { nom: 'c_47', largeur: 140 },
            { nom: 'c_48', largeur: 140 },
            { nom: 'c_49', largeur: 140 },
            { nom: 'c_50', largeur: 140 }
        ],
        colonnesCachees: [
            'efacleint',
            'emfcleint',
            'c_folann',
            'c_folcleint',
            'rfecleint',
            'vaecodprv',
            'emfflgimp',
            'emfseqtyp',
            'vaeemftyp_cod',
            'vaeemftyp_lbl',
            'foacod1',
            'foacod2',
            'foacod3',
            'foacod4',
            'foacod5',
            'foacod6',
            'foacod7',
            'foacod8',
            'foacod9',
            'foacod10',
            'foacod11',
            'foacod12',
            'foacod13',
            'foacod14',
            'foacod15',
            'foacod16',
            'foacod17',
            'foacod18',
            'foacod19',
            'foacod20',
            'foacod21',
            'foacod22',
            'foacod23',
            'foacod24',
            'foacod25',
            'foacod26',
            'foacod27',
            'foacod28',
            'foacod29',
            'foacod30',
            'foacod31',
            'foacod32',
            'foacod33',
            'foacod34',
            'foacod35',
            'foacod36',
            'foacod37',
            'foacod38',
            'foacod39',
            'foacod40',
            'foacod41',
            'foacod42',
            'foacod43',
            'foacod44',
            'foacod45',
            'foacod46',
            'foacod47',
            'foacod48',
            'foacod49',
            'foacod50'
        ],
    };

}
