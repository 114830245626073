import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'zz0003',
    ecrans: [
        '00-2'
    ],
    params: [
        'zlicleint',
        '?reference'
    ]
});

