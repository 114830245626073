import { IComponentController, IFilterDate, IWindowService } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IFormulaireGroupeConditionnelClass } from '../../../../../core/services/formulaire/formulaire-groupe-conditionnel.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuSectionClass } from '../../../../../core/services/menu/menu-section.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemActionTelechargementClass } from '../../../../../core/services/menu/menu-item-action-telechargement.service';

interface IPagePF0029 extends IComponentController {
    monoOccurrence: IMonoOccurrence
    monoOccurrenceOptions: IMonoOccurrenceOptions
    formulaireDpm: IFormulaire;
    formulairePoremp: IFormulaire;
    formulaireAutres: IFormulaire;
    gemMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gpeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dicMultiOccurrenceOptions: IMultiOccurrenceOptions;
    zmiMultiOccurrenceOptions: IMultiOccurrenceOptions
    menus: { [key: string]: Array<IMenuItem> };
    // Forage
    foragePerception: IMenuItem;
    foragePerceptionItem: IMenuItem;
    forageCompteRecevoirMenuItem: IMenuItem;
    forageRetenueMenuItem: IMenuItem;
}

/* @ngInject */
export default function Pf0029Controller(dateFilter: IFilterDate,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    Pagination: IPaginationClass,
    pf0010Route: IRoute,
    pf0012Route: IRoute,
    pf0018Route: IRoute,
    pf0030Route: IRoute,
    pf0031Route: IRoute,
    pf0032Route: IRoute,
    rr0003Route: IRoute,
    rr0129Route: IRoute,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuSection: IMenuSectionClass,
    Operateur: IOperateurService,
    $window: IWindowService,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass) {
    const vm: IPagePF0029 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    // Forage pour l'employé
    const forageDem = new MenuItemForage(
        rr0003Route.NAME,
        (data: any) => ({
            id: data.trncleint_epl
        }),
        { fonction: 'EMPLOYE' }

    );

    // Forage pour l'inscription
    const forageDic = new MenuItemForage(
        pf0010Route.NAME,
        (data: any) => ({
            id: data.trncleint
        })
    );

    // Forage du groupe de perfectionnement
    const forageGpe = new MenuItemForage(
        pf0012Route.NAME,
        (data: any) => ({
            id: data.gpecleint,
            trnclent: data.trncleint
        }),
        { fonction: 'GROUPE' }
    );

    // Forage pour les dépenses totales
    const forageDep = new MenuItemEcran(
        'BTN_FORAGE_DEPREE',
        pf0032Route.NAME,
        (data: any) => ({
            dpmcleint: data.dpmcleint,
            id: data.trncleint_dpm,
            axe: 'GDP',
            gpecleint: data.gpecleint,
            demcleint: data.demcleint
        }),
        {
            icon: 'launch',
            iconButton: true,
            fonction: 'DEPREE',
            onClose: () => { $window.location.reload(); }
        }
    );

    // Forage pour les dépenses annuelles totales
    const forageDepAnn = new MenuItemEcran(
        'BTN_FORAGE_DEPREE',
        pf0031Route.NAME,
        (data: any) => ({
            annfin: vm.monoOccurrence.data.dpmannfin,
            demcleint: data.demcleint
        }),
        {
            icon: 'launch',
            iconButton: true,
            fonction: 'DEPREEANN'
        }
    );

    // Pour l'appel de l'écran standardisé des fornulaires
    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        cycleErrorReloadBlocs: ['gem'],
        actionsMore: new Menu([
            new MenuItemActionTelechargement(
                'LBL_GENERE_ICS',
                'BOUICS',
                () => ({}),
                {
                    iconButton: true,
                    icon: 'event'
                }
            )
        ])
    };

    vm.menus = {
        aut__dpmmntpre: [
            new MenuItemAction(
                'BTN_ACTION_DEPREE',
                'BOUPRE',
                () => ({}),
                {
                    icon: 'edit',
                    iconButton: true,
                    hidden: (data: any) => (
                        !data.$valeurs.flgsofe2
                    ),
                    champsRechargement: ['aut__dpmmntpre'],
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_GESTION_MNT_PREVISION',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('dpmmntpre', {
                                largeur: 100,
                                required: true,
                                default: (data: any) => data.aut__dpmmntpre
                            })
                        ])
                    }
                }
            )
        ],
        aut__dpmmntree: [
            new MenuItemEcran(
                'BTN_FORAGE_DEPREE',
                pf0032Route.NAME,
                (data: any) => ({
                    dpmcleint: data.dpmcleint,
                    id: data.trncleint,
                    axe: 'GDP'
                }),
                {
                    icon: 'launch',
                    iconButton: true,
                    fonction: 'DEPREE',
                    onClose: () => { $window.location.reload(); }
                }
            )
        ]
    };

    vm.$onInit = $onInit;

    function $onInit() {
        // Activité de perfectionnement
        vm.formulaireDpm = new Formulaire('LBL_BLC_DPM', [
            new FormulaireItem('acecleint', { largeur: 50 }),
            new FormulaireItem('tpccleint', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dpmacedsc', { largeur: 50 }),
            new FormulaireItem('dpmlieu', { largeur: 50, enableCount: true }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('piscleint', { largeur: 50 }),
            new FormulaireItem('dpmpisdsc', { largeur: 50, enableCount: true }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dpmcomcen', { largeur: 75, enableCount: true }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dpmdatdeb'),
            new FormulaireItem('dpmhredeb'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dpmdatfin'),
            new FormulaireItem('dpmhrefin', { largeur: 25 }),
            new FormulaireItem('dpmdurhtt', { largeur: 25 }),
            new FormulaireItem('dpmdurhht', { largeur: 25 }),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS', new Formulaire([

                new FormulaireItem('unocleintrh', { largeur: 50 }),
                new FormulaireItem('poscleintsup', {
                    largeur: 50,
                    resourceParams: (data: any) => ({ unocleintrh: data.unocleintrh })
                }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('cabcleint', { largeur: 50 }),
                new FormulaireItem('rbucleint', { largeur: 50 }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('pfgcleint', { largeur: 50 }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItemDivider('LBL_DIV_TRN'),
                new FormulaireItem('dpmnumdoc'),
                new FormulaireItem('dpmdat', {
                    default: dateFilter(new Date(), 'yyyy-MM-dd')
                }),
                new FormulaireItem('ttccleint', {
                    nombreLibellesDetailsCacher: 1
                })
            ], { largeurDefaut: 33.33 }))

        ], { largeurDefaut: 25 });

        // Carte Portail employé
        vm.formulairePoremp = new Formulaire('LBL_BLC_POREMP', [
            new FormulaireGroupeConditionnel('dpmflginspub', '', new Formulaire([
                new FormulaireItem('dpmdatdebins', { default: dateFilter(new Date(), 'yyyy-MM-dd') }),
                new FormulaireItem('dpmdatfinins', { default: dateFilter(new Date(), 'yyyy-MM-dd') }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('dpmflgchxgrpins', {
                    default: 0
                }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('pbjcleintpubins', { largeur: 100 }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('dpmcomins', { largeur: 100, enableCount: true })
            ], { largeurDefaut: 50 }), {
                default: 0, switch: true
            })
        ]);

        // Employés inscrits
        vm.gemMultiOccurrenceOptions = {
            bloc: 'gem',
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_ANCIENETE',
                    rr0129Route.NAME,
                    (data: any) => ({
                        demcleint: data.demcleint,
                        flgperfect: 1
                    }),
                    {
                        icon: 'assessment',
                        fonction: 'ANCIENNETE',
                        iconButton: false
                    }
                ),
                new MenuItemEcran(
                    'LBL_LEM_AFT',
                    pf0030Route.NAME,
                    (data: any) => ({ id: data.demcleint }),
                    {
                        icon: 'view_list',
                        fonction: 'AFT',
                        iconButton: false
                    }
                )
            ]),
            fonctions: {
                editionRapide: true,
                importation: true
            },
            actionsSelection: new Menu([
                new MenuSection('LBL_MARQUER'),
                new MenuItemSelection('LBL_BTN_PRE', 'BOUPRE', {
                    selection: { nom: 'presence' }
                }),
                new MenuItemSelection('LBL_BTN_REU', 'BOUREU', {
                    selection: { nom: 'reussite' }
                }),
                new MenuItemSelection('LBL_BTN_PREREU', 'BOUPREREU', {
                    selection: { nom: 'prereu' }
                }),
                new MenuSection('LBL_DEPLACER'),
                new MenuItemSelection('LBL_BTN_DEPLACER', 'BOUDEPLACER', {
                    selection: {
                        nom: 'deplacer',
                        lblConfirmationTitre: 'LBL_DEPLACER_GROUPE',
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('gpecleintnou'),
                            new FormulaireItem('dpmcleint', {
                                default: () => {
                                    return vm.monoOccurrence.data.dpmcleint;
                                },
                                hidden: true
                            })
                        ])
                    }
                })
            ], { disabled: () => vm.monoOccurrence.champsTransactionnels.vaeceptypmod === "AUC" }),
            colonnesCachees: [
                'trncleint_dpm',
                'diccleint',
                'demcleint',
                'trncleint_epl',
                'gpecleint',
                'dpmcleint',
                'gmemsg'
            ],
            colonnesFixesGauche: [
                { nom: 'eplnummat', largeur: 100 },
                { nom: 'eplnomprn', largeur: 250, menu: [forageDem] }
            ],
            colonnesVisibles: [
                { nom: 'gpenumdoc', largeur: 130, menu: [forageGpe] },
                { nom: 'gemflgpre', largeur: 96 },
                { nom: 'gemflgreu', largeur: 90 },
                { nom: 'nbrpcs', largeur: 108 },
                { nom: 'gemcom', largeur: 325 },
                { nom: 'gemdurhtt', largeur: 100 },
                { nom: 'gemdurhht', largeur: 100 },
                { nom: 'gemtauhre', largeur: 100 },
                { nom: 'depense_tot', largeur: 126, menu: [forageDep] },
                { nom: 'unocod', largeur: 124 },
                { nom: 'unodscabr', largeur: 250 },
                { nom: 'depense_ann_tot', largeur: 126, menu: [forageDepAnn] },
                { nom: 'epladrcou', largeur: 250 }
            ],
            colonnesDisponibles: [
                { nom: 'taux_classif', largeur: 120, hidden: (data: any) => !data.$valeurs.flgvisibilite }
            ],
            colonnesFixesDroite: [
                { nom: 'gemflgmsg', largeur: 130 }
            ],
            colonnesEditionRapide: [
                { nom: 'gemcleintref', largeur: 360, hidden: true, default: () => vm.monoOccurrence.data.dpmcleint },
                { nom: 'demcleint', largeur: 360 },
                { nom: 'gpecleint', largeur: 360 },
                { nom: 'unocleintrh', largeur: 360 },
                { nom: 'gemtauhre', largeur: 140 },
                { nom: 'aut__taux_classif', largeur: 140, hidden: (data: any) => !data.$valeurs.flgvisibilite },
                { nom: 'aut__depense_tot', largeur: 0, hidden: true }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gemcleintref', {
                    hidden: true,
                    default: () => vm.monoOccurrence.data.dpmcleint
                }),

                new FormulaireItem('demcleint', { largeur: 50 }),
                new FormulaireItem('gemtauhre'),
                new FormulaireItem('aut__taux_classif'/*,{default : aut__taux_classif}*/),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('gpecleint', { largeur: 50 }),
                new FormulaireItem('unocleintrh', { largeur: 50 }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('gemflgpre', { default: 0 }),
                new FormulaireItem('gemflgreu', { default: 0 }),
                new FormulaireItem('gemdurhtt', { default: () => vm.monoOccurrence.data.dpmdurhtt }),
                new FormulaireItem('gemdurhht', { default: () => vm.monoOccurrence.data.dpmdurhht }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('gemcom', { largeur: 75, enableCount: true })

            ], { largeurDefaut: 25 })
        };

        // Demande d'inscription
        vm.dicMultiOccurrenceOptions = {
            bloc: 'DIC',
            actionsNouveaux: new Menu([
                new MenuItemForage(pf0010Route.NAME, () => {
                    return {
                        dpmcleint: vm.monoOccurrence.data.dpmcleint
                    };
                })
            ]),
            actionsMoreLigne: new Menu([
                new MenuItemEcran(
                    'LBL_LEM_AFT',
                    pf0030Route.NAME,
                    (data: any) => ({ id: data.demcleint }),
                    {
                        icon: 'view_list',
                        iconButton: false
                    }
                )
            ]),
            fonctions: {
                criteresRechercheOpened: false,
                nouveau: ecranDetails.valeurs.flgsofe2
            },
            filtres: [
                {
                    colonne: 'trnflgstaref',
                    operateur: Operateur.EGALE,
                    valeur: '1'
                },
                {
                    colonne: 'trnflgstafer',
                    operateur: Operateur.EGALE_PAS,
                    valeur: '1'
                },
                {
                    colonne: 'trnflgstaann',
                    operateur: Operateur.EGALE_PAS,
                    valeur: '1'
                }
            ],
            colonnesCachees: [
                'trncleint_epl',
                'trncleint',
                'demcleint'
            ],
            colonnesFixesGauche: [
                { nom: 'eplnummat', largeur: 100 },
                { nom: 'eplnomprn', largeur: 250, menu: [forageDem] }
            ],
            colonnesVisibles: [
                { nom: 'gpenumdocsou', largeur: 140 },
                { nom: 'diccomdem', largeur: 250 },
                { nom: 'gpenumdocreel', largeur: 106 },
                { nom: 'ecldsceta', largeur: 250, menu: [forageDic] },
                { nom: 'unocod', largeur: 124 },
                { nom: 'unodscabr', largeur: 250 },
                { nom: 'depense_ann_tot', largeur: 160, menu: [forageDepAnn] }
            ]
        };

        // GROUPE PERFECTIONNEMENT (PM2)
        vm.gpeMultiOccurrenceOptions = {
            bloc: 'gpe',
            navigatePage: pf0012Route.NAME,
            navigateParams: () => {
                return {
                    dpmcleint: vm.monoOccurrence.data.dpmcleint,
                    trncleint: vm.monoOccurrence.data.trncleint
                };
            },
            actionsNouveaux: new Menu([
                new MenuItemForage(pf0012Route.NAME, () => {
                    return {
                        dpmcleint: vm.monoOccurrence.data.dpmcleint,
                        trncleint: vm.monoOccurrence.data.trncleint
                    };
                })
            ]),
            actionsMoreLigne: new Menu([
                new MenuItemEcran(
                    'LBL_DEP_TOT',
                    pf0032Route.NAME,
                    (data: any) => ({
                        dpmcleint: data.dpmcleint,
                        id: vm.monoOccurrence.data.trncleint,
                        axe: 'GDP',
                        gpecleint: data.gpecleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        fonction: 'DEPTOT'
                    }
                )
            ]),
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesCachees: [
                'dpmcleint',
                'gpecleint'
            ],
            colonnesVisibles: [
                { nom: 'gpenumdoc', largeur: 100 },
                { nom: 'heure', largeur: 100 },
                { nom: 'formateur', largeur: 100 }
            ]
        };

        vm.zmiMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'zmi',
            navigatePage: pf0018Route.NAME,
            navigateParams: (data: any) => ({ id: data.suacleint }),
            colonnesVisibles: [
                { nom: 'suacod', largeur: 80 },
                { nom: 'suadsc', largeur: 200 }
            ],
            colonnesDisponibles: [
                { nom: 'suadatdeb', largeur: 100 },
                { nom: 'suadatfin', largeur: 100 },
                { nom: 'suaflgact', largeur: 100 },
                { nom: 'suacom', largeur: 295 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('suacleint', { largeur: 75 })
            ]),
            colonnesCachees: ['suacleint']
        };
    }
}
