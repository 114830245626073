import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc0062 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0062Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0062 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true,
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleintaprcri'),
            new FormulaireItem('intcleintreqcri'),
            new FormulaireItem('intcleintequcri'),
            new FormulaireItem('etdcleintcri'),
            new FormulaireItem('datappdecri', { required: true }),
            new FormulaireItem('datappacri', { required: true }),
            new FormulaireItem('mntmincri'),
            new FormulaireItem('mntmaxcri')
        ], { largeurDefaut: 33.33 }),
        colonnesFixesGauche: [
            { nom: 'intcodreq', largeur: 100 },
            { nom: 'intnomreq', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'intcodequ', largeur: 100 },
            { nom: 'intnomequ', largeur: 180 },
            { nom: 'intcodapr', largeur: 100 },
            { nom: 'intnomapr', largeur: 180 },
            { nom: 'tydcod', largeur: 100 },
            { nom: 'tyddsc', largeur: 230 },
            { nom: 'etdcod', largeur: 120 },
            { nom: 'docaffnumdoc01', largeur: 130 },
            { nom: 'docaffdsc01', largeur: 250 },
            { nom: 'docrefcod02', largeur: 120 },
            { nom: 'docrefnom02', largeur: 180 },
            { nom: 'docaffdat01', largeur: 126 },
            { nom: 'datappro', largeur: 150 },
            { nom: 'docaffmnt01', largeur: 150 }
        ]
    };
}
