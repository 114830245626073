import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageGS0045 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceApmOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Gs0045Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Pagination: IPaginationClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageGS0045 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceApmOptions = {
        bloc: 'apm',
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [5, 10, 25]
        }),
        colonnesVisibles: [
            {nom: 'vaesysmodsofdsc', largeur: 190}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesysmodsof',{largeur: 25})
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'aplcod', largeur: 130},
            {nom: 'apldsc', largeur: 290}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('aplcod'),
            new FormulaireItem('apldsc')
        ],{largeurDefaut: 25}),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceApmOptions
    };
}
