import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr2002 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dtiMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr2002Params {
    ppacleint: string;
    ppacod: string;
    vaedtitypvnt: string;
    typvnt_dsc: string;
    dtidatfineng: string;
    imp: string;
    prucleint: string;
    prscleint: string;
    cifcleint: string;
    unacleint: string;
    cptcleint: string;
    el1cleint: string;
    el2cleint: string;
    el3cleint: string;
}

/* @ngInject */
export default function Mr2002Controller(mr2003Route: IRoute,
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    $stateParams: IMr2002Params,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr2002 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_VENT', [
                new FormulaireItem('ppacod', { largeur: 25, default: $stateParams.ppacod }),
                new FormulaireItem('typvnt_dsc', { largeur: 25, default: $stateParams.typvnt_dsc }),
                new FormulaireItem('imp', { largeur: 25, default: $stateParams.imp }),
                new FormulaireItem('dtidatfineng', { largeur: 25, default: $stateParams.dtidatfineng })
            ]),
        };

        vm.dtiMultiOccurrenceOptions = {
            resourceParams: {
                ppacleinturl: $stateParams.ppacleint,
                vaedtitypvnturl: $stateParams.vaedtitypvnt,
                prucleinturl: $stateParams.prucleint,
                prscleinturl: $stateParams.prscleint,
                cifcleinturl: $stateParams.cifcleint,
                unacleinturl: $stateParams.unacleint,
                cptcleinturl: $stateParams.cptcleint,
                el1cleinturl: $stateParams.el1cleint,
                el2cleinturl: $stateParams.el2cleint,
                el3cleinturl: $stateParams.el3cleint
            },
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_MSG',
                    mr2003Route.NAME,
                    (data: any) => ({
                        dnecleint: data.dnecleint,
                        dticleint: data.dticleint
                    }), {
                    iconButton: false,
                    icon: "",
                    hidden: (data: any) => !data.flg_err
                })
            ]),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('grpvnt', { default: 'TOUS' }),
            ]),
            colonnesCachees: [
                'dnecleint'
            ],
            colonnesVisibles: [
                { nom: 'tagcod', largeur: 120 },
                { nom: 'tagdscabr', largeur: 200 },
                { nom: 'rcecod', largeur: 120 },
                { nom: 'rcedscabr', largeur: 200 },
                { nom: 'aftcod', largeur: 120 },
                { nom: 'aftdsc', largeur: 200 },
                { nom: 'tafcod', largeur: 140 },
                { nom: 'tafdsc', largeur: 200 },
                { nom: 'posnumdoc', largeur: 140 },
                { nom: 'postitabr', largeur: 200 },
                { nom: 'temcod', largeur: 140 },
                { nom: 'temdscabr', largeur: 200 },
                { nom: 'lemcod', largeur: 140 },
                { nom: 'lemdsc', largeur: 200 },
                { nom: 'dernumdoc', largeur: 140 },
                { nom: 'drpnumdoc', largeur: 140 },
                { nom: 'urgcod', largeur: 140 },
                { nom: 'urgdscabr', largeur: 200 },
                { nom: 'strcod', largeur: 140 },
                { nom: 'strdsc', largeur: 200 },
                { nom: 'unocod', largeur: 140 },
                { nom: 'unodscabr', largeur: 200 },
                { nom: 'prfcod', largeur: 140 },
                { nom: 'prfdscabr', largeur: 200 },
                { nom: 'prfdatdeb', largeur: 110 }
            ],
            colonnesFixesDroite: [
                { nom: 'dtimnt', largeur: 120, total: true },
                { nom: 'dtimntposexe', largeur: 170, total: true },
                { nom: 'flg_err', largeur: 100 }
            ]
        };
    }
}
