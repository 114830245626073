import { IComponentController } from 'angular';
import { IMonoOccurrenceOptions, IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCc0017 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    chcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    chcMultiOccurrence: IMultiOccurrence;
    qutMultiOccurrenceOptions: IMultiOccurrenceOptions;
    qutMultiOccurrence: IMultiOccurrence;
    nctMultiOccurrenceOptions: IMultiOccurrenceOptions;
    nctMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Cc0017Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {

    const vm: IPageCc0017 = this;
    vm.monoOccurrenceOptions = {
        fonctions: {
        },
        formulaire: new Formulaire(
            '', [
            new FormulaireItem('cadcod', { largeur: 33 }),
            new FormulaireItem('pagcleint', { largeur: 33 }),
            new FormulaireItem('urgcleint', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eplcleint', { largeur: 33 }),
            new FormulaireItem('dmenomprn', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cadcom', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('anc', { largeur: 33 }),
            new FormulaireItem('munnomfra', { largeur: 33 })
        ]
        )
    };

    vm.chcMultiOccurrenceOptions = {
        bloc: 'chc',
        fonctions: {
            recherche: false,
            pagination: false
        },
        colonnesVisibles: [
            { nom: 'trmcleint', largeur: 40 },
            { nom: 'ccspri', largeur: 20 },
            { nom: 'crscleint', largeur: 50 },
            { nom: 'gofcleint', largeur: 30 },
            { nom: 'unocod', largeur: 40 },
            { nom: 'flgqua', largeur: 10 },
            { nom: 'anc', largeur: 40 },
            { nom: 'flgnot', largeur: 10 },
            { nom: 'indatrcad', largeur: 40 },
            { nom: 'indatraut', largeur: 40 },
            { nom: 'flgatrprf', largeur: 10 }
        ]
    };

    vm.qutMultiOccurrenceOptions = {
        bloc: 'qut',
        fonctions: {
            recherche: false,
            pagination: false
        },
        colonnesVisibles: [
            { nom: 'test', largeur: 40 } // je n'ai pas les valeurs - err S1
        ]
    };

    vm.nctMultiOccurrenceOptions = {
        bloc: 'nct',
        fonctions: {
            recherche: false,
            pagination: false
        },
        colonnesVisibles: [
            { nom: 'test', largeur: 40 } // je n'ai pas les valeurs - err S1
        ]
    };
}
