import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IPaginationClass} from "../../../../../core/services/pagination.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";

interface IPageMr0108 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePpaOptions: IMultiOccurrenceOptions;
    valeursAnr: any;
}
/* @ngInject */
export default function Mr0108Controller(mr0107Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Pagination: IPaginationClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         Menu: IMenuClass) {
    const vm: IPageMr0108 = this;

    // Forage vers la technique de démarcation
    const forageTecDem = new MenuItemEcran(
        'LBL_FOR_TECDEM',
        mr0107Route.NAME,
        (rowData: any) => (
            {
                id: rowData.ppacleint
            }
        ),
        {
            icon: 'launch',
            iconButton: true,
            onClose() { vm.multiOccurrence.fetchDataList(); }
        }
    );

    vm.valeursAnr = ecranDetails.valeurs;

    vm.multiOccurrencePpaOptions = {
        bloc: 'ppa',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 12,
            nbElementsPossibles: [12, 24]
        }),
        colonnesFixesGauche: [
            {nom: 'ppaper'          , largeur: 100},
            {nom: 'ppacod'          , largeur: 100},
            {nom: 'ppadatdeb'       , largeur: 140},
            {nom: 'ppadatfin'       , largeur: 140},
            {nom: 'ppaflgantpam'    , largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'ppadatpai'       , largeur: 150},
            {nom: 'vaeppatecdem_dsc', largeur: 160, menu:[forageTecDem]},
            {nom: 'ppaflgptd'       , largeur: 120},
            {nom: 'vaeppacal01_dsc' , largeur: 140, hidden: !vm.valeursAnr.flgcal01},
            {nom: 'vaeppacal02_dsc' , largeur: 140, hidden: !vm.valeursAnr.flgcal02},
            {nom: 'vaeppacal03_dsc' , largeur: 140, hidden: !vm.valeursAnr.flgcal03},
            {nom: 'vaeppacal04_dsc' , largeur: 140, hidden: !vm.valeursAnr.flgcal04},
            {nom: 'vaeppacal05_dsc' , largeur: 140, hidden: !vm.valeursAnr.flgcal05}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ppaflgantpam'),
            new FormulaireItem('ppadatpai'),
            new FormulaireItem('vaeppatecdem'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeppacal01'),
            new FormulaireItem('vaeppacal02'),
            new FormulaireItem('vaeppacal03'),
            new FormulaireItem('vaeppacal04'),
            new FormulaireItem('vaeppacal05')
        ], {largeurDefaut: 33})
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_GEN',
                'BOUGENVAL',
                (data: any) => ({
                    anrcleint: data.anrcleint
                }),
                {
                    icon: 'sync',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_DATE',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_datpai')
                        ], {largeurDefaut: 100})
                    },
                    successAction: (retour: any) => {
                        if (!retour.err) {
                            let messageConfirmation;
                            if (retour.flgexe) {
                                messageConfirmation = 'BOUGEN_CONFIRM';
                            }

                            const menuItemGen = new MenuItemAction(
                                'LBL_BTN_GENECR',
                                'BOUGEN',
                                () => ({
                                    id: retour.anrcleint,
                                    anrcleint: retour.anrcleint,
                                    t_datpai: retour.t_datpai
                                }),
                                {
                                    messageConfirmation
                                }
                            );

                            menuItemGen.action(null, null, vm.multiOccurrence);
                        }
                    }
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'percod'   , largeur: 130},
            {nom: 'perdsc'   , largeur: 300},
            {nom: 'anrann'   , largeur: 120},
            {nom: 'anrdatdeb', largeur: 130},
            {nom: 'anrdatfin', largeur: 130},
            {nom: 'anrnbrper', largeur: 180}
        ],
        colonnesCachees: [
            'anrflgmodper'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('percleint'),
            new FormulaireItem('anrann'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('anrdatdeb'),
            new FormulaireItem('anrdatfin'),
            new FormulaireItem('anrnbrper')
        ], {largeurDefaut: 33}),
        multiOccurrenceOptionsEnfant: vm.multiOccurrencePpaOptions
    };
}
