import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cp2008',
    ecrans: ['00-2'],
    params: ['id',
             'lotcleint',
             'vuetyp',
             '?refcleint',
             '?mopcleint',
             '?radcleint',
             '?empcleintdep',
             '?paycleint'
    ]
});
