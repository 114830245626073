import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr2010 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    iruMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function mr2010Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr2010 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('intcodnom'),
            new FormulaireItem('rolcoddsc')
        ])
    };

    vm.iruMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'unocod', largeur: 100},
            {nom: 'unodscabr', largeur: 250},
            {nom: 'irudatdeb', largeur: 120},
            {nom: 'irudatfin', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('unocleint', {
                resourceParams: (data:any) => {
                    return {
                        inrcleint: vm.monoOccurrence.data.inrcleint
                    };
                }}),
            new FormulaireItem('irudatdeb'),
            new FormulaireItem('irudatfin')
        ], {largeurDefaut: 33.33})
    };
}