import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";

interface IPageSp0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Sp0007Controller(Menu: IMenuClass,
    sp0008Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageSp0007 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(sp0008Route.NAME,
                () => ({}),
                {
                    fonction: 'BOUNOUV'
                })
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                sp0008Route.NAME,
                (rowData: any) => ({
                    id: rowData.evfcleint
                }),
                {
                    fonction: 'BOUOUV'
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'evfcod', largeur: 100 },
            { nom: 'evfdsc', largeur: 200 },
            { nom: 'tevcod', largeur: 100 },
            { nom: 'tevdsc', largeur: 200 },
            { nom: 'grecod', largeur: 100 },
            { nom: 'gredsc', largeur: 200 },
            { nom: 'evfflgact', largeur: 100 }
        ]
    };
}
