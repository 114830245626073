import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPagePt0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt0018Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPagePt0018 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 180},
            {nom: 'mdhnumdoc', largeur: 120},
            {nom: 'ecldsceta', largeur: 140},
            {nom: 'ttccod', largeur: 160},
            {nom: 'ttcdsc', largeur: 140},
            {nom: 'pdvcod', largeur: 120},
            {nom: 'pdvdsc', largeur: 160},
            {nom: 'hopnumdoc', largeur: 100},
            {nom: 'mdhdsc', largeur: 160},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
