import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMR0240 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0240Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0240 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vaedsc', largeur: 180},
            {nom: 'pmrflgexcann', largeur: 120},
            {nom: 'pmrflgexcfer', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaepmrsta', {largeur: 40}),
            new FormulaireItem('pmrflgexcann', {largeur: 20}),
            new FormulaireItem('pmrflgexcfer', {largeur: 20})
        ])
    };
}
