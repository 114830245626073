import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageDb0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0013Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass ) {


    const vm: IPageDb0013 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pecdebcri', {
                required: true
            }),
            new FormulaireItem('pecfincri', {
                required: true
            }),
            new FormulaireItem('cifcleintcri'),
            new FormulaireItem('prucledebcri'),
            new FormulaireItem('pruclefincri'),
            new FormulaireItemSautDeLigne,
            new FormulaireItem('nbravacri'),
            new FormulaireItem('nbrradcri'),
            new FormulaireItem('nbrdepcri'),
            new FormulaireItem('nbrboccri'),
            new FormulaireItem('nbrecjcri'),
            new FormulaireItem('nbrecbcri')

        ], {largeurDefaut: 33.33}),
        colonnesVisibles: [
            {nom: 'pecann', largeur: 100},
            {nom: 'cifcod', largeur: 100},
            {nom: 'unacod', largeur: 100},
            {nom: 'nbr_ava', largeur: 120},
            {nom: 'nbr_rad', largeur: 120},
            {nom: 'nbr_dep', largeur: 120},
            {nom: 'nbr_boc', largeur: 120},
            {nom: 'nbr_ecj', largeur: 120},
            {nom: 'nbr_ecb', largeur: 120}
        ]
    };
}
