import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0009Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0009 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'cencod', largeur: 125 },
            { nom: 'cendsc', largeur: 200 },
            { nom: 'vaecentyprec_dsc', largeur: 165 },
            { nom: 'censrucod', largeur: 115 },
            { nom: 'etbcod', largeur: 145 },
            { nom: 'etbnom', largeur: 300 },
            { nom: 'unacod', largeur: 145 },
            { nom: 'unadsc', largeur: 180 },
            { nom: 'cenflgstu', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cencod'),
            new FormulaireItem('cendsc', { largeur: 50 }),
            new FormulaireItem('vaecentyprec'),
            new FormulaireItem('censrucod'),
            new FormulaireItem('etbcleint'),
            new FormulaireItem('unacleint'),
            new FormulaireItem('cenflgstu', { default: 1 })
        ],
            {
                largeurDefaut: 25
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
