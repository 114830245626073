import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRe0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re0005Controller(Menu: IMenuClass,
                                         re0006Route: IRoute,
                                         parametresRecherche: IParametresRechercheService,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe0005 = this;

    vm.multiOccurrenceOptions = {
        filtres: parametresRecherche(ecranDetails),
        navigatePage: re0006Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(re0006Route.NAME)
        ]), 
        colonnesVisibles: [
            {nom: 'rtocod', largeur: 120},
            {nom: 'rtodsc', largeur: 180},
            {nom: 'tagcod', largeur: 120},
            {nom: 'tagdscabr', largeur: 180},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 180},
            {nom: 'strcod', largeur: 120},
            {nom: 'strdsc', largeur: 160},
            {nom: 'tpmdatvig', largeur: 126},
            {nom: 'vaesysprvret_dsc', largeur: 120},
            {nom: 'tpmflgina', largeur: 100}
        ]
    };
}
