import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePR0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0014Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePR0014 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'prjcod', largeur: 140 },
            { nom: 'prjdsc', largeur: 200 },
            { nom: 'pdfcod', largeur: 140 },
            { nom: 'prhflgindver', largeur: 120 },
            { nom: 'prucod', largeur: 140 },
            { nom: 'orgcod', largeur: 140 },
            { nom: 'orgnomabr', largeur: 200 },
            { nom: 'orgcodnou', largeur: 140 },
            { nom: 'orgnomabrnou', largeur: 200 },
            { nom: 'orpcod', largeur: 140 },
            { nom: 'orpdscabr', largeur: 200 },
            { nom: 'orpcodnou', largeur: 140 },
            { nom: 'orpdscabrnou', largeur: 200 },
            { nom: 'datcre', largeur: 110 },
            { nom: 'usrcodmod', largeur: 120 },
            { nom: 'usrnommod', largeur: 160 },
            { nom: 'datmod', largeur: 110 },
            { nom: 'usrcodver', largeur: 140 },
            { nom: 'usrnomver', largeur: 140 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('prhflgindver', { largeur: 15, readonly: (data: any) => data.prhflgindver === 1 })
        ]),
    };
}
