import {IComponentController, IFormController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMR0144 extends IComponentController {
    valeursEcran: any;
    formCtrl: IFormController;
    formulaireRcp: IFormulaire;
    formulaireDat: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rvaMultiOccurrenceOptions: IMultiOccurrenceOptions; // Valeurs
    rcfMultiOccurrenceOptions: IMultiOccurrenceOptions; // Formule
    rboMultiOccurrenceOptions: IMultiOccurrenceOptions; // Assurance facultative
    appMultiOccurrenceOptions: IMultiOccurrenceOptions; // Applicabilité
}

interface IMr0144Params {
    rcecleint: number;
}

/* @ngInject */
export default function Mr0144Controller(mr0144Route: IRoute,
                                         mr0336Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IMr0144Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0144 = this;

    const forageRce = new MenuItemForage(
        'G_LBL_RCE',
        mr0144Route.NAME,
        (data: any) => ({
            id: data.t_rcpcleint,
            rcpcleint: data.t_rcpcleint,
            rcecleint: data.t_rcecleint
        }),
        {
            icon: 'refresh',
            disabled: (rowData: any) => !rowData.t_rcedatvig
        }
    );

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemEcran(
                    'G_LBL_MR0336',
                    mr0336Route.NAME,
                    (data:any) => ({
                        id: data.$id,
                        rcpcleint: data.$id
                    }),
                    {
                        icon: 'settings',
                        iconButton: false
                    }
                ),
                new MenuItemAction(
                    'LBL_COPIE',
                    'BOUCOP',
                    (data: any) => ({ rcpcleint: data.rcpcleint }),
                    {
                        icon: 'file_copy',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE',
                            lblConfirm: 'G_LBL_BTN_COPIER',
                            largeurFormulaire: 20,
                            formulaire: new Formulaire([
                                new FormulaireItem('t_rcpdatvig', { largeur: 100 })
                            ])
                        }
                    }
                ),
                new MenuItemAction(
                    'LBL_COPIE_2',
                    'BOUCOP2',
                    () => ({ rcecleint: $stateParams.rcecleint }),
                    {
                        icon: 'file_copy',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE_2',
                            lblConfirm: 'G_LBL_BTN_COPIER',
                            largeurFormulaire: 20,
                            formulaire: new Formulaire([
                                new FormulaireItem('t_rcpdatvig_2', { largeur: 100, resourceParams: { rcecleint: $stateParams.rcecleint } }),
                                new FormulaireItem('t_rcecleint_cop', { largeur: 100, resourceParams: { rcecleint: $stateParams.rcecleint } })
                            ])
                        }
                    }
                )
            ])
        };

        vm.formulaireRcp = new Formulaire('LBL_BLC_RCE', [
            new FormulaireItem('aut__rcecod', { largeur: 25,  default: vm.valeursEcran.aut__rcecod }),
            new FormulaireItem('aut__rcedscabr', { largeur: 75, default: vm.valeursEcran.aut__rcedscabr}),
            new FormulaireItem('rcpdatvig'   , { largeur: 25 }),
            new FormulaireItem('rcpflgbrninc', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_EXC_ABS',
                new Formulaire([
                    new FormulaireItem('cotcleint', { largeur: 100 })
                ]), { largeur: 50 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_GOUV',
                new Formulaire([
                    new FormulaireItem('colcleintimp', { largeur: 50 }),
                    new FormulaireItemSautDeColonne(),
                    new FormulaireItem('rcpfctconemr'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('colcleintmntsup', { largeur: 50 }),
                    new FormulaireItem('rcpflgnbrperreg'),
                    new FormulaireItem('rcpflgaftpriann'),                    
                ], { largeurDefaut: 25 }),
                { hidden: (data: any) => data.aut__flgaffgouapp === 0 }
            )
        ]);

        vm.formulaireDat = new Formulaire('LBL_BLC_DAT', [
            new FormulaireItem('t_rcedatvig', {
                largeur: 10,
                resourceParams: () => ({ rcecleint: $stateParams.rcecleint }),
                menu: [forageRce]
            }),
            new FormulaireItem('t_rcecleint', { largeur: 10 }),
            new FormulaireItem('t_rcpcleint', { largeur: 10 })
        ]);

        // Valeur
        vm.rvaMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'rvanumval'    , largeur: 110},
                {nom: 'rvadsc'       , largeur: 345},
                {nom: 'rvaval'       , largeur: 140},
                {nom: 'vaervatyp_dsc', largeur: 150},
                {nom: 'rvaflgoblemp' , largeur: 110}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rvanumval'),
                new FormulaireItem('rvadsc', { largeur: 30 }),
                new FormulaireItem('rvaval'),
                new FormulaireItem('vaervatyp'),
                new FormulaireItem('rvaflgoblemp')
            ], {largeurDefaut: 10})
        };

        // Formule
        vm.rcfMultiOccurrenceOptions = {
            colonnesFixesGauche: [
                {nom: 'rcfnumlig'       , largeur: 74},
                {nom: 'vaercftypope_dsc', largeur: 160}
            ],
            colonnesVisibles: [
                {nom: 'tdrvaldsc'       , largeur: 270},
                {nom: 'rcfflgemp'       , largeur: 85},
                {nom: 'rcfflgemr'       , largeur: 90},
                {nom: 'rcfflgsaladm'    , largeur: 74},
                {nom: 'rcfflgqteadm'    , largeur: 74},
                {nom: 'rcfcom'          , largeur: 441},
                {nom: 'rcfflgdbg'       , largeur: 100},
                {nom: 'rcflibdbg'       , largeur: 257}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rcfnumlig'),
                new FormulaireItem('vaercftypope'),
                new FormulaireItem('tdrcleint', { largeur: 50 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rcfvalusr', { largeur: 75, enableCount: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rcfflgemp'),
                new FormulaireItem('rcfflgemr'),
                new FormulaireItem('rcfflgsaladm'),
                new FormulaireItem('rcfflgqteadm'),
                new FormulaireItem('rcfcom', { largeur: 50, enableCount: true }),
                new FormulaireItem('rcfflgdbg'),
                new FormulaireItem('rcflibdbg')
            ], { largeurDefaut: 25 }),
            details: {
                lblTitre: 'LBL_BLC_RPA', multiOccurrenceOptions: {
                    bloc: 'rpa',
                    colonnesVisibles: [
                        {nom: 'tdddsc'          , largeur: 352},
                        {nom: 'vaetddtyppar_dsc', largeur: 205},
                        {nom: 'rpacodref'       , largeur: 100},
                        {nom: 'rpadscref'       , largeur: 300}
                    ],
                    formulaire: new Formulaire([
                        new FormulaireItem('tddcleint'),
                        new FormulaireItem('rpacleintref')
                    ])
                }
            }
        };

        // Assurance facultative
        vm.rboMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'rboborinf', largeur: 110},
                {nom: 'rbotau001', largeur: 140},
                {nom: 'rbotau002', largeur: 140},
                {nom: 'rbotau003', largeur: 140},
                {nom: 'rbotau004', largeur: 140},
                {nom: 'rbomaxpro', largeur: 140}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rboborinf', { maxlength: 2 }),
                new FormulaireItem('rbotau001'),
                new FormulaireItem('rbotau002'),
                new FormulaireItem('rbotau003'),
                new FormulaireItem('rbotau004'),
                new FormulaireItem('rbomaxpro')
            ], { largeurDefaut: 10 })
        };

        // Applicabilité
        vm.appMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'ppacod'   , largeur: 82},
                {nom: 'ppadatdeb', largeur: 110},
                {nom: 'ppadatfin', largeur: 110},
                {nom: 'ppadatpai', largeur: 130},
                {nom: 'unpcod'   , largeur: 130},
                {nom: 'unpdsc'   , largeur: 450}
            ]
        };
    }
}
