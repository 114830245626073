import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageAC0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceACLOptions: IMultiOccurrenceOptions;
}

interface IAc0053Params {
    agacleint: string;
}

/* @ngInject */
export default function Ac0053Controller(
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IAc0053Params,
                                         Operateur: IOperateurService,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageAC0053 = this;

    const filtresParametres = [];

    if ($stateParams.agacleint) {
        filtresParametres.push(
            ...[
                {
                    colonne: 'agacleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.agacleint,
                    affichage: false
                }
            ]
        );
    }

    vm.multiOccurrenceACLOptions = {
        bloc: 'acl',
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'acgseq', largeur: 140 },
            { nom: 'aclcod', largeur: 140 },
            { nom: 'acldsc', largeur: 300 },
            { nom: 'vecsyslng', largeur: 90 },
            { nom: 'vecdsc', largeur: 90 },
            { nom: 'acgflgstu', largeur: 10 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('acgseq', { largeur: 25 }),
            new FormulaireItem('aclcleint', { largeur: 25 }),
            new FormulaireItem('acgflgstu', { largeur: 25, default: 1 }),
        ]),
    };

    vm.multiOccurrenceOptions = {
        filtresParametres,
        fonctions: {
        },
        colonnesVisibles: [
            {nom: 'agacod',largeur: 40},
            {nom: 'agadsc',largeur: 60},
            {nom: 'vecagacat',largeur: 60},
            {nom: 'vecdsc',largeur: 10},
            {nom: 'agaflgstu',largeur: 10},
        ],
        colonnesCachees: [
            'agacleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('agacod', { largeur: 20 }),
            new FormulaireItem('agadsc', { largeur: 40 }),
            new FormulaireItem('vecagacat', { largeur: 20 }),
            new FormulaireItem('agaflgstu', { largeur: 20,  default: 1 })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceACLOptions
    };
}