import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';


interface IPageGL0018 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Gl0018Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    dateFilter: IFilterDate,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageGL0018 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: localStorage.getItem('forageFromMenu') === 'oui'
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_GENERATION',
                'BOUGEN',
                (data: any) => ({
                    peccodconf__peccod: vm.multiOccurrence.etatSelected.criteresSuggeresData.peccoddeb__peccod,
                    peccodconf: vm.multiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                }),
                {
                    icon: 'library_add',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_GENERE_AUTO',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('nochargement', {
                                default: dateFilter(new Date(), 'yyyyMMdd')
                            }),
                            new FormulaireItem('peccodconf'),
                            new FormulaireItem('datecr', {
                                default: dateFilter(new Date(), 'yyyy-MM-dd')
                            }),
                            new FormulaireItem('intcleintreq', { default: vm.valeursEcran.intcleintreq })
                        ], { largeurDefaut: 100 })
                    }
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_PERIODE'),
            new FormulaireItem('peccoddeb', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('peccodfin', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cptcleint', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleint', {
                required: false
            }),
            new FormulaireItemDivider('LBL_DATE'),
            new FormulaireItem('datdeb', {
                required: false
            }),
            new FormulaireItem('datfin', {
                required: false
            })
        ]),
        colonnesFixesDroite: [
            { nom: 'getmntdbt', largeur: 150 },
            { nom: 'getmntcrt', largeur: 150 }
        ],
        colonnesVisibles: [
            { nom: 'getnumdoc', largeur: 230 },
            { nom: 'gettydcod', largeur: 89 },
            { nom: 'getpeccod', largeur: 80 },
            { nom: 'getmnttot', largeur: 126 },
            { nom: 'getdevtaucou', largeur: 126 },
            { nom: 'getmnteca', largeur: 126 },
            { nom: 'getnumdocref', largeur: 230 },
            { nom: 'gettydcodref', largeur: 89 },
            { nom: 'getdevtaucouref', largeur: 126 },
            { nom: 'getmntref', largeur: 126 },
            { nom: 'getprucod', largeur: 89 },
            { nom: 'getprscod', largeur: 89 },
            { nom: 'getcifcod', largeur: 89 },
            { nom: 'getunacod', largeur: 110 },
            { nom: 'getcptcod', largeur: 89 },
            { nom: 'getcptcodeca', largeur: 89 },
            { nom: 'getel1cod', largeur: 89 },
            { nom: 'getel2cod', largeur: 89 },
            { nom: 'getel3cod', largeur: 89 }
        ]
    };
}
