import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPagePb0030 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireVld: IFormulaire;
    annbdgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    preMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pbjMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pb0030Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPagePb0030 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false
            },
            successActionSauvegarde: () => vm.monoOccurrence.fetchData()

        };

        // Prévisionnel / Compte
        vm.formulaireVld = new Formulaire('LBL_BLC_VLD', [
            new FormulaireItem('prucoddsc'),
            new FormulaireItem('pvlseq'),
            new FormulaireItem('cptcoddsc'),
            new FormulaireItem('prscoddsc'),
            new FormulaireItem('unacoddsc'),
            new FormulaireItem('cifcoddsc'),
            new FormulaireItem('taxcoddsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vldmntnet'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vldcom', {enableCount: true, largeur: 100})
        ]);

        // Montants prévisionnels de l'année en cours
        vm.preMultiOccurrenceOptions = {
            bloc: 'pre',
            colonnesVisibles: [
                {nom: 'pvlannseq', largeur: 100},
                {nom: 'vldmntnet', largeur: 100}
            ]
        };

        // Montants de disponibilité de l'année précédente de l'année budgétaire en cours
        vm.annbdgMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tyhdsc'   , largeur: 100},
                {nom: 'ctvmntsum', largeur: 100}
            ]
        };

        // Justification de la PB
        vm.pbjMultiOccurrenceOptions = {
            bloc: 'pbj',
            entetesReplacedValues: {
                ent_bdg: vm.valeursEcran.vldlblent
            },
            colonnesVisibles: [
                {nom: 'pbjdsc'   , largeur: 600},
                {nom: 'el1cod'   , largeur: 130, hidden: (data:any) => !data.$valeurs.flgel1sai},
                {nom: 'el1dsc'   , largeur: 200, hidden: (data:any) => !data.$valeurs.flgel1sai},
                {nom: 'pbjmnt'   , largeur: 150},
                {nom: 'pbjmntnet', largeur: 150},
                {nom: 'pbjflgobl', largeur: 120}
            ]
        }
    }
}
