import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCb0015 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    bloMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bloMultiOccurrence: IMultiOccurrence;
    tymMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0015Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Pagination: IPaginationClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: IStateParamsService,
    cb2006Route: IRoute,
    cb0012Route: IRoute
) {
    const vm: IPageCb0015 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.tymMultiOccurrenceOptions = {
        bloc: 'tym',
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 250 },
            { nom: 'mntsum', largeur: 130 },
            { nom: 'mntimp', largeur: 120, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        })
    };

    vm.bloMultiOccurrenceOptions = {
        bloc: 'blo',
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem( 'niveaucri', {
                default: $stateParams.niveau,
                hidden: true
            }),
            new FormulaireItem( 'typpercri', {
                default: $stateParams.anncivfin,
                hidden: true
            }),
            new FormulaireItem('peccleintdebcri', {
                default: vm.valeursEcran.peccleintdeb,
                readonly: true
            }),
            new FormulaireItem('peccleintfincri', {
                default: vm.valeursEcran.peccleintfin,
                readonly: true
            }),
            new FormulaireItem('indcleintcri', {
                default: vm.valeursEcran.indcleintcri,
                readonly: true
            }),
            new FormulaireItem('vaesysnatctbcri', {
                default: vm.valeursEcran.vaesysnatctbcri,
                readonly: true
            })
        ]),
        colonnesVisibles: [
            { nom: 'blocod', largeur: 100 },
            { nom: 'blodsc', largeur: 250 },
            { nom: 'mntsum', largeur: 130, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        multiOccurrenceOptionsEnfant: vm.tymMultiOccurrenceOptions
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cb2006Route.NAME,
                (rowData: any) => ({
                    niveau: $stateParams.niveau,
                    type: 'BLO',
                    elecleint: $stateParams.elecleint,
                    blocleint: rowData.blocleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    tymcleint: rowData.tymcleint,
                    tydcleint: rowData.tydcleint,
                    peccleintdeb: vm.bloMultiOccurrence.etatSelected.criteresSuggeresData.peccleintdebcri,
                    peccleintfin: vm.bloMultiOccurrence.etatSelected.criteresSuggeresData.peccleintfincri
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 130 },
            { nom: 'mntsum', largeur: 120 }
        ],
        colonnesCachees: [
            'blocleint',
            'tymcleint',
            'tydcleint'
        ]
    };
}
