import { IComponentController, IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageAs0012 extends IComponentController {
    menus: { [key: string]: Array<IMenuItem> },
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    satMultiOccurrenceOptions: IMultiOccurrenceOptions;
    acjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    atfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
    valeursEcran: any;
}

/* @ngInject */
export default function As0012Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    dateFilter: IFilterDate,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemEcran: IMenuItemEcranClass,
    Menu: IMenuClass,
    as0041Route: IRoute,
    as0017Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAs0012 = this;

    vm.monoOccurrenceOptions = {
        cycleErrorReloadBlocs: [
            'sat'
        ]
    }
    const actionsMoreLigne = new Menu([
        new MenuItemForage(
            'BTN_FOR_JRS',
            as0041Route.NAME,
            (rowData: any) => (
                {
                    demcleint: rowData.demcleint,
                    datdeb: rowData.satdat
                }
            ),
            {
                icon: 'launch_app',
                iconButton: false
            }
        )
    ])
    vm.valeursEcran = ecranDetails.valeurs;
    vm.formulaireFac = new Formulaire([
        new FormulaireItem('altnumdoc', {
            largeur: 33,
            modifAllow: false,
            required: false
        }),
        new FormulaireItem('ttccleint', {
            largeur: 33,
            modifAllow: false,
            required: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('asgcleint', {
            largeur: 33,
            modifAllow: false,
            required: true
        }),
        new FormulaireItem('ptacleint', {
            largeur: 33,
            modifAllow: false,
            required: true,
            resourceParams: (data: any) => ({ asgcleint: data.asgcleint })
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('aeqcleint', {
            largeur: 33,
            modifAllow: false,
            required: false,
            resourceParams: (data: any) => ({ asgcleint: data.asgcleint })
        }),
        new FormulaireItem('frscleint', {
            largeur: 33,
            modifAllow: false,
            required: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('altcom', {
            largeur: 100,
            enableCount: true,
            required: false
        })
    ], { largeurDefaut: 100 });

    vm.satMultiOccurrenceOptions = {
        fonctions: {
            importation: true,
            editionRapide: true
        },
        actionsMoreLigne,
        navigatePage: as0041Route.NAME,
        navigateParams: (data: any) => ({
            demcleint: data.demcleint,
            datdeb: data.$ancetre.ptadatdeb
        }),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'satdat', largeur: 130 },
            { nom: 'occdsc', largeur: 130 },
            { nom: 'aftcod', largeur: 130 },
            { nom: 'dernumdoc', largeur: 130 },
            { nom: 'drpnumdoc', largeur: 130 },
            { nom: 'actcod', largeur: 130 },
            { nom: 'satqteact', largeur: 130 },
            { nom: 'satcmt', largeur: 130 }
        ],
        colonnesFixesDroite: [
            { nom: 'flgremaut', largeur: 130 },
            { nom: 'flgerr', largeur: 130 },
            { nom: 'almmsg', largeur: 130 }
        ],
        colonnesEditionRapide: [
            { nom: 'demcleint', largeur: 50, resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint, aeqcleint: data.$ancetre.aeqcleint, ptacleint: data.$ancetre.ptacleint }) },
            { nom: 'satdat', largeur: 20 },
            { nom: 'occcleint', largeur: 33.33 },
            { nom: 'aftcleint', largeur: 100, resourceParams: (data: any) => ({ satdat: data.satdat, occcleint: data.occcleint, asgcleint: data.$ancetre.asgcleint, demcleint: data.demcleint, usrcleint: data.usrcleint }) },
            { nom: 'dercleint', largeur: 33.33, resourceParams: (data: any) => ({ demcleint: data.demcleint, asgcleint: data.$ancetre.asgcleint, ptacleint: data.$ancetre.ptacleint, satdat: data.satdat }), },
            { nom: 'drpcleint', largeur: 33.33, resourceParams: (data: any) => ({ dercleint: data.dercleint, ptacleint: data.$ancetre.ptacleint }) },
            { nom: 'actcleint', largeur: 33.33, resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint, satdat: data.satdat }) },
            { nom: 'satqteact', largeur: 15 }
        ],
        colonnesCachees: ['demcleint',
            'vaeocctem',
            'vaeocclem',
            'vaeoccaft',
            'vaeoccpos',
            'vaeoccder',
            'vaeoccuno',
            'vaefrsaft',
            'vaefrsder'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint', {
                required: true,
                resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint, aeqcleint: data.$ancetre.aeqcleint, ptacleint: data.$ancetre.ptacleint })
            }),
            new FormulaireItem('satdat', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('occcleint', {
                resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint })
            }),
            new FormulaireItem('aftcleint', {
                resourceParams: (data: any) => ({ satdat: data.satdat, occcleint: data.occcleint, asgcleint: data.$ancetre.asgcleint, demcleint: data.demcleint, usrcleint: data.usrcleint }),
                hidden: (data: any) => (data.vaefrsaft !== 'OBL'),
                disabled: (data: any) => (data.vaefrsaft !== 'OBL'),
                required: (data: any) => (data.vaefrsaft === 'OBL')
            }),
            new FormulaireItem('dercleint', {
                resourceParams: (data: any) => ({ demcleint: data.demcleint, asgcleint: data.$ancetre.asgcleint, ptacleint: data.$ancetre.ptacleint, satdat: data.satdat }),
                hidden: (data: any) => (data.vaefrsder !== 'OBL'),
                disabled: (data: any) => (data.vaefrsder !== 'OBL'),
                required: (data: any) => (data.vaefrsder === 'OBL')
            }),
            new FormulaireItem('actcleint', {
                resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint, satdat: data.satdat })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('drpcleint', {
                disabled: (data: any) => (data.vaefrsder !== 'OBL'),
                hidden: (data: any) => (data.vaefrsder !== 'OBL'),
                resourceParams: (data: any) => ({ dercleint: data.dercleint, ptacleint: data.$ancetre.ptacleint })
            }),
            new FormulaireItem('satqteact'),
            new FormulaireItem('satcmt'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgremaut', {
                disabled: true
            }),
            new FormulaireItem('flgerr', {
                disabled: true
            })
        ], { largeurDefaut: 33 })
    }

    vm.acjMultiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 130 },
            { nom: 'acjseqaff', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'ent_dtjdatjr1', largeur: 75 },
            { nom: 'ent_dtjdatjr2', largeur: 75 },
            { nom: 'ent_dtjdatjr3', largeur: 75 },
            { nom: 'ent_dtjdatjr4', largeur: 75 },
            { nom: 'ent_dtjdatjr5', largeur: 75 },
            { nom: 'ent_dtjdatjr6', largeur: 75 },
            { nom: 'ent_dtjdatjr7', largeur: 75 }
        ]
    };


    vm.atfMultiOccurrenceOptions = {
        navigatePage: as0017Route.NAME,
        navigateParams: (data: any) => ({
            atfcleint: data.$ancetre.atfcleint,
            vaeatftyp: 'JR'
        }),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'atfnumdoc', largeur: 130 },
            { nom: 'ecldsceta', largeur: 130 },
            { nom: 'vaeatftyp', largeur: 130 },
            { nom: 'ttccod', largeur: 130 },
            { nom: 'ttcdsc', largeur: 130 },
            { nom: 'flgmsg', largeur: 130 },
            { nom: 'trnflgstaref', largeur: 130 },
            { nom: 'trnflgstafin', largeur: 130 },
            { nom: 'trnflgstaann', largeur: 130 },
            { nom: 'trnflgstafer', largeur: 130 }
        ]
    };
}
