import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemTexteClass } from '../../../../../core/services/formulaire/formulaire-item-texte.service';

interface IPageCr0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0018Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemTexte: IFormulaireItemTexteClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass) {
    const vm: IPageCr0023 = this;

    const [paddingLeft, cw1, cw2, cw3] = [30, 10, 15, 15];
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'crfcod', largeur: 90 },
            { nom: 'crfdsc', largeur: 262 },
            { nom: 'vaecrftypdat', largeur: 85 },
        ],

        formulaire: new Formulaire([
            new FormulaireItem('crfcod', { required: true, largeur: 20 }),
            new FormulaireItem('crfdsc', { required: true, largeur: 20 }),
            new FormulaireItem('vaecrftypdat', { required: true, largeur: 20 }),
            new FormulaireGroupe('LBL_INTERVALLE_EN_JOUR'
                , new Formulaire([
                    new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                    new FormulaireItemSautDeColonne({ largeur: cw1 }),
                    new FormulaireItemTexte('LBL_LIBELLE', { largeur: cw2, customClass: 'ex-texte-bold' }),
                    new FormulaireItemTexte('LBL_JUSQUAU', { largeur: cw3, customClass: 'ex-texte-bold' }),

                    new FormulaireItemSautDeLigne(),
                    new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                    new FormulaireItemTexte('LBL_COL1', { largeur: cw1, customClass: 'ex-texte-bold' }),
                    new FormulaireItem('crflibcol01', { largeur: cw2, titre: ' ' }),
                    new FormulaireItem('crfmaxcol01', { largeur: cw3, titre: ' ' }),

                    new FormulaireItemSautDeLigne(),
                    new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                    new FormulaireItemTexte('LBL_COL2', { largeur: cw1, customClass: 'ex-texte-bold' }),
                    new FormulaireItem('crflibcol02', { largeur: cw2, titre: ' ' }),
                    new FormulaireItem('crfmaxcol02', { largeur: cw3, titre: ' ' }),

                    new FormulaireItemSautDeLigne(),
                    new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                    new FormulaireItemTexte('LBL_COL3', { largeur: cw1, customClass: 'ex-texte-bold' }),
                    new FormulaireItem('crflibcol03', { largeur: cw2, titre: ' ' }),
                    new FormulaireItem('crfmaxcol03', { largeur: cw3, titre: ' ' }),

                    new FormulaireItemSautDeLigne(),
                    new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                    new FormulaireItemTexte('LBL_COL4', { largeur: cw1, customClass: 'ex-texte-bold' }),
                    new FormulaireItem('crflibcol04', { largeur: cw2, titre: ' ' }),
                    new FormulaireItem('crfmaxcol04', { largeur: cw3, titre: ' ' }),

                    new FormulaireItemSautDeLigne(),
                    new FormulaireItemSautDeColonne({ largeur: paddingLeft }),
                    new FormulaireItemTexte('LBL_COL5', { largeur: cw1, customClass: 'ex-texte-bold' }),
                    new FormulaireItem('crflibcol05', { largeur: cw2, titre: ' ' })
                ])
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
