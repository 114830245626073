import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IMenuItemSelectionClass} from "../../../../../core/services/menu/menu-item-selection.service";
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageIF0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface If0018Params {
    doccleint: string;
    itscleint: number;
}

/* @ngInject */
export default function If0018Controller(if0004Route: IRoute,
                                         Operateur: IOperateurService,
                                         $stateParams: If0018Params,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {

    const vm: IPageIF0018 = this;

    const forageImm = new MenuItemForage(
        'LBL_FOR_IMM',
        if0004Route.NAME,
        (data: any) => ({ id: data.immcleint })
    );

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'doccleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.doccleint,
                visible: false
            }
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_ASSOCIER',
                'BOUASS', {
                    selection: {
                        nom: 'associer',
                        lblConfirm: 'G_LBL_EXECUTER',
                        lblConfirmationTitre: 'LBL_BTN_ASSOCIER',
                        largeurFormulaire: 50,
                        confirmationFormulaire: new Formulaire([
                            new FormulaireItem('itscleintnew', {default: $stateParams.itscleint}),
                            new FormulaireItem('itddsc', {largeur: 66}),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('itsmnt'),
                            new FormulaireItem('datmes'),
                            new FormulaireItem('flgeffact')
                        ],  {largeurDefaut: 33}),
                        icon: 'sync'
                    }
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'immcod', largeur: 140, menu: [forageImm]},
            {nom: 'immdsc', largeur: 250},
            {nom: 'catdsc', largeur: 230},
            {nom: 'methode', largeur: 230},
            {nom: 'statut', largeur: 200}
        ],
        colonnesCachees: [
            'immcleint',
            'doccleint'
        ]
    };
}
