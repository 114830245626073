import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0126',
    ecrans: ['00-2'],
    params: [
        'id',
        'urgcleint',
        'dercleint'

    ]
});
