import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IOperateurService } from "../../../../../core/services/operateur.service";

interface IPageRr0078 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0078Controller(rr2018Route: IRoute,
    Menu: IMenuClass,
    Operateur: IOperateurService,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0078 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([new MenuItemForage(rr2018Route.NAME)]),
        navigatePage: rr2018Route.NAME,
        colonnesVisibles: [
            { nom: 'franumcha', largeur: 136 },
            { nom: 'fraficsou', largeur: 300 },
            { nom: 'franbrlig', largeur: 120 },
            { nom: 'franbrerr', largeur: 120 },
            { nom: 'fraflgval', largeur: 120 },
            { nom: 'fraflgtrt', largeur: 120 }
        ]
    };
}
