import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireItemTexteClass} from '../../../../../core/services/formulaire/formulaire-item-texte.service';
import {IDialogFormulaireOptions} from '../../../../../core/dialogs/dialog-formulaire/dialog-formulaire.controller';
import {IDialog} from '../../../../../core/services/dialog.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IData} from '../../../../../core/services/data-linker.service';

interface IPageAs0080 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence
}

/* @ngInject */
export default function As0080Controller(DialogFormulaire: IDialog,
                                         Formulaire: IFormulaireClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemTexte: IFormulaireItemTexteClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageAs0080 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            axesAffichageVisible: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('origine', {required: true, default: 'DER'}),
            new FormulaireItem('statut', {required: true, default: 'TOUS'})
        ], {largeurDefaut: 33}),
        colonnesVisibles: [
            {nom: 'hdtcod', largeur: 100},
            {nom: 'hdtdsc', largeur: 140},
            {nom: 'vaesyslng__vaedsc', largeur: 140},
            {nom: 'vaehdtmod_dsc', largeur: 180},
            {nom: 'hdtdec', largeur: 160},
            {nom: 'hdtflgact', largeur: 100},
            {nom: 'hdsdat', largeur: 140},
            {nom: 'hdsstu', largeur: 160}
        ],
        colonnesCachees: [
            'hdsdscerr',
            'vaehdtmod',
            'vaesyslng',
            'gfscod'
        ],
        formulaire: new Formulaire([
                new FormulaireItem('hdtcod', {largeur: 20, required: true}),
                new FormulaireItem('hdtdsc', {required: true}),
                new FormulaireItem('hdtdec',{largeur: 20}),
                new FormulaireItem('hdtflgact', {largeur: 20, default: 1}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaesyslng', {required: true}),
                new FormulaireItem('vaehdtmod', {required: true, default: 'LBC'}),
                new FormulaireGroupe('LBL_CONFIG', new Formulaire([
                    new FormulaireItemTexte('LBL_AVERTISSEMENT_TAG_CREATION', {
                        hidden: (data: IData) => Boolean(data.$id),
                        icon: 'warning',
                    }),
                    new FormulaireItemTexte('LBL_GENERER_CODE', {
                        largeur: 100,
                        menu: [new MenuItemAction(
                            'BOUGENTOK',
                            (data: any) => ({
                                hdtcleint: data.hdtcleint
                            }),
                            {
                                icon: 'sync',
                                disabled: (data: IData) => !data.$id,
                                successAction: (data: any) => {
                                    const dialogOptions: IDialogFormulaireOptions = {
                                        lblTitre: 'LBL_CONFIRM_CODE',
                                        icon: 'sync',
                                        occurrence: vm.multiOccurrence,
                                        showConfirmAction: false,
                                        largeur: 30,
                                        lblCancel: 'G_LBL_BTN_FERMER',
                                        data,
                                        formulaire: new Formulaire([
                                            new FormulaireItemTexte('LBL_CONFIRM_CODE_INFO',{
                                                icon:'warning'
                                            }),
                                            new FormulaireItemSautDeLigne(),
                                            new FormulaireItem('motpas', {readonly: true})
                                        ], {largeurDefaut: 100})
                                    };
                                    return DialogFormulaire.show({locals: dialogOptions, multiple: true});
                                },
                                formulaireConfirmationAction: {
                                    lblTitre: 'LBL_GENERER_CODE',
                                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                                    largeurFormulaire: 30,
                                    formulaire: new Formulaire([
                                        new FormulaireItemTexte('LBL_GENERER_CODE_QUESTION'),
                                        new FormulaireItem('hdtcleint', {hidden: true})
                                    ])
                                }
                            }
                        )]
                    }),
                ]), {largeur: 33, hidden: (data: IData) => data.vaehdtmod !== 'TAG'}),
            ], {
                largeurDefaut: 33
            }
        )
    };
}
