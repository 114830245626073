import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'pb0004',
    ecrans: ['00-2'],
    params: ['pvecleint',
             'pvpcleint',
             'pppcleint'
    ]
});
