import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageGs0061 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    eppMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0061Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0061 = this;

    vm.$onInit = function $onInit() {
        vm.eppMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'ppodsc', largeur: 240},
                {nom: 'vaeppotyp_dsc', largeur: 150},
                {nom: 'eppvalcli', largeur: 260},
                {nom: 'ppovaldev', largeur: 430},
                {nom: 'ppodsclng', largeur: 400}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('eppvalcli')
            ], {largeurDefaut: 50})
        };

        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_ECT', [
                new FormulaireItem('ecrcleint', {largeur: 50 })
            ])
        };
    }
}
