import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IStateService } from 'angular-ui-router';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
interface IPageCR0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface ICr0011Params {
    lcrcleint: string;
    menuId: string;
}

/* @ngInject */
export default function Cr0011Controller(cr0007Route: IRoute,
    $state: IStateService,
    $stateParams: ICr0011Params,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService) {
    const vm: IPageCR0011 = this;
    vm.valeursEcran = ecranDetails;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup', { hidden: () => vm.valeursEcran.valeurs.vaehldtypimp !== 1 }),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a'),
            new FormulaireItem('lcrcleint', {
                default: $stateParams.lcrcleint,
                hidden: false,
                disabled: (data: any) => data.$params.lcrcleint
            })
        ]),
        navigatePage: cr0007Route.NAME,
        navigateParams: () => (
            {
                fonction: 'BOUOUV'
            }
        ),
        actionsNouveaux: new Menu([
            new MenuItemForage(cr0007Route.NAME, () => {
                return {
                    lcrcleint: $stateParams.lcrcleint,
                    fonction: 'BOUNOUV'
                };
            })
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_COPIE_FACTURE',
                'BOUCOP',
                (rowData: any) => ({ faccleint: rowData.faccleint }),
                {
                    icon: 'content_copy',
                    fonction: 'BOUACT',
                    hidden: !(vm?.valeursEcran?.fonctions['FAC.BOUCOP']?.flgacc),
                    successAction: (rowData: any) => {
                        $state.go(cr0007Route.NAME, { id: rowData.doccleintnew, menuId: $stateParams.menuId });
                    }
                }
            )]),
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/cr0011/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            }),
            new MenuItem('TELECHARGER_PATRON_FAV', () => {
                downloadManager.directDownload('/cr0011/patronfav', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            }),
            new MenuItem('TELECHARGER_PATRON_PSA', () => {
                downloadManager.directDownload('/cr0011/patronfas', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        colonnesVisibles: [
            { nom: 'facnumdoc', largeur: 100 },
            { nom: 'facnumdocseq', largeur: 80 },
            { nom: 'sysfacnum', largeur: 100 },
            { nom: 'tydcod', largeur: 100 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'radcod', largeur: 100 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'etddsc', largeur: 150 },
            { nom: 'facnumref', largeur: 120 },
            { nom: 'facmnt', largeur: 100 },
            { nom: 'facmntenc', largeur: 100 },
            { nom: 'facmntcrt', largeur: 100 },
            { nom: 'mntres', largeur: 100 },
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 180 },
            { nom: 'vecfaccat', largeur: 100 },
            { nom: 'lcrcod', largeur: 100 },
            { nom: 'lcrdsc', largeur: 200 },
            { nom: 'cifcod', largeur: 80 },
            { nom: 'pdfcod', largeur: 120 },
            { nom: 'prjdscabr', largeur: 200 },
            { nom: 'facdsc', largeur: 350 },
            { nom: 'usrcod', largeur: 100 }
        ],
        colonnesCachees: [
            'lcrcleint'
        ]
    };
}
