import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0034 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0034Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0034 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'vecfseintprv', largeur: 145 },
            { nom: 'vecdsc', largeur: 200 },
            { nom: 'vecpififr', largeur: 115 },
            { nom: 'vecpififrt', largeur: 185 },
            { nom: 'vecmoppif', largeur: 155 },
            { nom: 'vecmoppift', largeur: 190 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vecfseintprv'),
            new FormulaireItem('vecpififr', { required: true }),
            new FormulaireItem('vecmoppif')
        ],
            {
                largeurDefaut: 33
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
