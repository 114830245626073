import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRR0052 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Rr0052Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRR0052 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'pfrdsc',
                valeur: vm.valeursEcran.pfrdsc,
                affichage: true,
                readOnly: true
            }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pfrcleint', {
                default: vm.valeursEcran.pfrcleint
            }),
            new FormulaireItem('rcecod'),
            new FormulaireItem('rcecleint'),
            new FormulaireItem('tssflgadd'),
            new FormulaireItem('tssmntemp', {
                largeur: 33.33
            }),
            new FormulaireItem('tssmntmaxemp', {
                largeur: 33.33
            }),
            new FormulaireItem('tssmntemr', {
                largeur: 33.33
            })
        ]),
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 121},
            {nom: 'rcedsc', largeur: 260},
            {nom: 'tssflgadd', largeur: 91},
            {nom: 'tssmntemp', largeur: 131},
            {nom: 'tssmntmaxemp', largeur: 131},
            {nom: 'tssmntemr', largeur: 131}
        ]
    };
}
