import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IPaginationClass } from "../../../../../core/services/pagination.service";

interface IPageMc0099 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0099Controller(mr0176Route: IRoute,
    mr0208Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    isForageValide: IIsForageValideService,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    Pagination: IPaginationClass) {
    const vm: IPageMc0099 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },
        menus: [
            new MenuItemForage(
                'G_LBL_IMPACT_BUD',
                mr0208Route.NAME,
                (data: any) => ({
                    id: data.docaffdemcleint01,
                    doccleintgrh: data.doccleint,
                    typtri: 1
                }),
                {
                    icon: 'equalizer',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'G_LBL_IMPACT_BU',
                (data: any) => (`secure.${data.ecrcod2}.ecran`),
                (data: any) => ({
                    id: data.docaffcleint01,
                    stamnetab: data.tydmnedoc
                }),
                {
                    icon: 'equalizer',
                    iconButton: false,
                    hidden: (data: any) => !isForageValide.isForageValide('mc0099', data.ecrcod2)
                }
            )
        ],
        infosFinancieres: { disponibilite: true },
        formulaire: new Formulaire('G_LBL_DOC_FIN', [
            new FormulaireItem('docaffnumdoc01', {
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE',
                        (data: any) => `secure.${data.ecrcod}.ecran`,
                        (data: any) => ({ id: data.mobcleintref }),
                        {
                            hidden: (data: any) => !isForageValide.isForageValide('mc0099', data.ecrcod)
                        }
                    )
                ]
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eplnummat'),
            new FormulaireItem('eplnomprn'),
            new FormulaireItem('docmatetd05'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcod'),
            new FormulaireItem('urgdscabr'),
            new FormulaireItem('strcod'),
            new FormulaireItem('strdsc'),
            new FormulaireItem('temcod'),
            new FormulaireItem('temdscabr'),
            new FormulaireItem('posnumdoc', {
                menu: [
                    new MenuItemForage(
                        'LBL_MR0238',
                        mr0176Route.NAME,
                        (data: any) => ({
                            ovdcleintref: data.docaffposcleint01,
                            stamnetab: 'POS'
                        }),
                        {
                            disabled: (data: any) => !data.posnumdoc_sup
                        }
                    )
                ]
            }),
            new FormulaireItem('postit'),
            new FormulaireItem('posnumdoc_sup'),
            new FormulaireItem('postit_sup'),
            new FormulaireItem('docreqcod03'),
            new FormulaireItem('docreqnom03'),
            new FormulaireItem('docaffdsc01'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('docaffdatdeb01', { largeur: 33.3 }),
            new FormulaireItem('docaffdatfin01', { largeur: 33.3 }),
            new FormulaireItem('docdatfinpam05', { largeur: 33.3 }),
            new FormulaireItem('docflgarrpam05', { largeur: 33.3 }),
            new FormulaireItem('ecrcod', { hidden: true }),
            new FormulaireItem('mobcleintref', { hidden: true }),
            new FormulaireItem('stamnetab', { hidden: true })
        ])
    }

    vm.ovdMultiOccurrenceOptions = {
        bloc: "ovd",
        resourceParamsDynamique: () => ({
            ovdcleintref: vm.monoOccurrence && vm.monoOccurrence.data && vm.monoOccurrence.data.docaffcleint01,
            stamnetab: vm.monoOccurrence && vm.monoOccurrence.data && vm.monoOccurrence.data.tydmnedoc
        }),
        actionsRangeeDroite: new Menu([
            new MenuItemForage('BTN_MODIFIER', mr0176Route.NAME, (rowData: any) => ({
                id: rowData.ovdcleint,
                ovdcleintref: vm.monoOccurrence && vm.monoOccurrence.data && vm.monoOccurrence.data.docaffcleint01,
                stamnetab: vm.monoOccurrence && vm.monoOccurrence.data && vm.monoOccurrence.data.tydmnedoc
            }))
        ]),
        actionsNouveaux: new Menu([
            new MenuItemForage('G_LBL_BTN_NOUVEAU', mr0176Route.NAME, () => ({
                ovdcleintref: vm.monoOccurrence && vm.monoOccurrence.data && vm.monoOccurrence.data.docaffcleint01,
                stamnetab: vm.monoOccurrence && vm.monoOccurrence.data && vm.monoOccurrence.data.tydmnedoc
            }), {
                disabled: () => !vm.monoOccurrence || !vm.monoOccurrence.data || !vm.monoOccurrence.data.docaffcleint01
            })
        ]),
        fonctions: {
            pagination: true,
            criteresSuggeresVisibles: false,
            criteresRechercheOpened: false
        },
        pagination: new Pagination({
            nombreElementPage: 3,
            nbElementsPossibles: [3, 6, 15]
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('selection', {
                largeur: 100,
                default: 'VF',
                direction: 'vertical'
            })
        ]),
        colonnesVisibles: [
            { nom: 'ovnimp', largeur: 300 },
            { nom: 'rsccoddsc', largeur: 250 },
            { nom: 'ovddatvig', largeur: 126 },
            { nom: 'ovddatfin', largeur: 126 }
        ],
        colonnesCachees: ['ovdcleint']
    }
}
