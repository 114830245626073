import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0332',
    ecrans: ['00-2',
             '01-2'],
    params: ['id',
             'vaeasscat',
             '?plccleint']
});
