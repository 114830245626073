import {module} from 'angular';
import {IFormulaireClass} from '../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../core/services/formulaire/formulaire-item.service';
import {ITexteDataTypeClass} from '../../../../core/services/data-types/texte-data-type.service';
import {IMenuSection, IMenuSectionClass} from '../../../../core/services/menu/menu-section.service';

export interface IMenuSelectionEtapes extends IMenuSectionClass {
    new(): IMenuSection;
}

export default module('app.services.menu-selection-etapes', []).factory('MenuSelectionEtapes', MenuSelectionEtapes);

/* @ngInject */
function MenuSelectionEtapes(Formulaire: IFormulaireClass,
                             FormulaireItem: IFormulaireItemClass,
                             TexteDataType: ITexteDataTypeClass,
                             MenuSection: IMenuSectionClass): IMenuSelectionEtapes {

    return class MenuSelectionEtapes extends MenuSection {
        constructor() {
            super('G_LBL_CHANGER_ETAPE', {
                selection: {
                    nom: 'etapes',
                    lblConfirmationTitre: 'G_LBL_TITRE_CONFIRMATION_TRAITEMENT',
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('com', {
                            titre: 'G_LBL_COMMENTAIRE',
                            dataType: new TexteDataType()
                        })
                    ], {
                        largeurDefaut: 100
                    })
                }
            });
        }
    };
}
