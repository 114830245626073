import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireTemplateSlotClass} from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0115 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    gtrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceUNFOptions: IMultiOccurrenceOptions;
    multiOccurrenceGTROptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0115Controller(Menu: IMenuClass,
                                         mr0238Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0115 = this;

    vm.multiOccurrenceUNFOptions = {
        bloc: 'unf',
        colonnesVisibles: [
            {nom: 'unccod_ori', largeur: 110},
            {nom: 'uncdsc_ori', largeur: 170},
            {nom: 'vaeunfhre_dsc', largeur: 170},
            {nom: 'unffct', largeur: 170}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('unccleintori', {required: true}),
            new FormulaireItem('vaeunfhre', {required: true}),
            new FormulaireItem('unffct', {required: true})
        ],  {largeurDefaut: 33.33} )
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'unccod', largeur: 100},
            {nom: 'uncdsc', largeur: 262},
            {nom: 'ucdcod', largeur: 200},
            {nom: 'ucddsc', largeur: 262},
            {nom: 'uncnbrdec', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('unccod', {largeur: 50, required: true}),
            new FormulaireItem('uncdsc', {largeur: 50, required: true}),
            new FormulaireItem('ucdcleint', {largeur: 50, required: true}),
            new FormulaireItem('uncnbrdec', {largeur: 50})
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceUNFOptions
    };
}
