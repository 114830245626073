import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTH0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0010Controller(Menu: IMenuClass) {
    const vm: IPageTH0010 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rcxcod', largeur: 200},
            {nom: 'rcxdsc', largeur: 200},
            {nom: 'rcxflgabs', largeur: 200},
            {nom: 'rcxflgabspar', largeur: 200},
            {nom: 'rcxflgcom', largeur: 200},
            {nom: 'vaercxtypsai_dsc', largeur: 200},
            {nom: 'rcxdscporemp', largeur: 200},
            {nom: 'rcxexpporemp', largeur: 200},
            {nom: 'rcxseqaff', largeur: 200},
            {nom: 'ttccod', largeur: 200},
            {nom: 'ttcdsc', largeur: 200},
            {nom: 'unccod', largeur: 200},
            {nom: 'uncdsc', largeur: 200},
            {nom: 'pfcdsc', largeur: 200}
        ]
    };
}
