import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageRe0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Re0012Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe0012 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        fonctions: {
            nouveau: false
        },
        colonnesVisibles: [
            {nom: 'rtocod', largeur: 120},
            {nom: 'rtodsc', largeur: 180},
            {nom: 'tagcod', largeur: 120},
            {nom: 'tagdscabr', largeur: 180},
            {nom: 'tglflgexc', largeur: 100},
            {nom: 'tglprcapp', largeur: 100}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('tglflgexc', {
                    disabled: (data: any) => {
                        return (data.vue__vaertostu === 1);
                    }
                }),
                new FormulaireItem('tglprcapp', {
                    disabled: (data: any) => {
                        return (data.vue__maj_prc === 1);
                    }
                })
            ]
        )
    };
}
