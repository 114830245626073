import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';

interface IPageIN0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function In0032Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    notificationHandler: INotificationHandler
    // in0023Route: IRoute,
    // in0035Route: IRoute
) {
    const vm: IPageIN0032 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    // @TODO ajouter la route de forage une fois que l'écran est fait
    const in0023Route = { NAME: 'secure.accueil' };
    const in0035Route = { NAME: 'secure.accueil' };

    if (vm.valeursEcran.mesmag) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesmag
        });
        vm.multiOccurrence.disabledRow = () => true;
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup'),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        navigatePage: in0035Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(in0023Route.NAME)
        ]),
        colonnesFixesGauche: [
            { nom: 'tydcod', largeur: 100 },
            { nom: 'dinnumdoc', largeur: 150 },
            { nom: 'dinnumdocseq', largeur: 80 }
        ],
        colonnesVisibles: [
            { nom: 'magcod', largeur: 90 },
            { nom: 'magnom', largeur: 150 },
            { nom: 'dindsc', largeur: 300 },
            { nom: 'docaffnumdoc01', largeur: 200 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'etddsc', largeur: 120 },
            { nom: 'dinmnt', largeur: 126 },
            { nom: 'intcodreq', largeur: 150 },
            { nom: 'intnomreq', largeur: 200 },
            { nom: 'intcoddem', largeur: 150 },
            { nom: 'intnomdem', largeur: 200 },
            { nom: 'vecdincat_dsc', largeur: 200 },
            { nom: 'dinnumref', largeur: 200 },
            { nom: 'usrcod', largeur: 100 }
        ]
    };
}
