import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'ac0022',
    ecrans: ['00-2'],
    params: [
        '?dhicleintref',
        '?dhityp'
    ]
});
