import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCb0051 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    mntMultiOccurrenceOptions: IMultiOccurrenceOptions;
    totMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0051Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    profil: IProfil,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: IStateParamsService,
    if0008Route: IRoute,
    db0005Route: IRoute,
    db0012Route: IRoute,
    mc0099Route: IRoute,
    mc0101Route: IRoute,
    mc0109Route: IRoute,
    cp2010Route: IRoute,
    mr0212Route: IRoute,
    gl0003Route: IRoute,
    cr0007Route: IRoute,
    ga0007Route: IRoute,
    cr0024Route: IRoute,
    cr0013Route: IRoute,
    cr0021Route: IRoute,
    cr0016Route: IRoute,
    in0043Route: IRoute
) {
    const vm: IPageCb0051 = this;
    const pd0006Route = { NAME: 'secure.accueil' };
    const gl0020Route = { NAME: 'secure.accueil' };
    const cp0029Route = { NAME: 'secure.accueil' };
    const ac0070Route = { NAME: 'secure.accueil' };
    const in0014Route = { NAME: 'secure.accueil' };
    const gt0010Route = { NAME: 'secure.accueil' };
    const cp0040Route = { NAME: 'secure.accueil' };
    const ac0037Route = { NAME: 'secure.accueil' };
    const ac0010Route = { NAME: 'secure.accueil' };
    const in0035Route = { NAME: 'secure.accueil' };
    const cp0018Route = { NAME: 'secure.accueil' };
    const cp0026Route = { NAME: 'secure.accueil' };
    const db0023Route = { NAME: 'secure.accueil' };
    const cp0014Route = { NAME: 'secure.accueil' };

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        id: profil.preferences.ciecleint,
        noId: true,
        fonctions: {
            suiviModification: true
        }
    };
    vm.mntMultiOccurrenceOptions = {
        bloc: 'mnt',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_INFO_FINANC',
                (rowData: any) => getForageItem(rowData),
                (rowData: any) => getParamItem(rowData),
                {
                    icon: 'launch',
                    fonction: 'BOUOUV',
                    disabled: (rowData: any) => rowData.tydflgdocgrh === 1 && rowData.tydcleintref !== 200 && rowData.tydcleintref !== 301 ,
                    hidden :(rowData: any) => rowData.ecrcod === 'CB0053-00'
                }
            )
        ]),
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('tydcleintcri', {
                    ignoreEtat: true,
                    resourceParams: () => {
                        return { pruseq: $stateParams.pruseq, vaesysnivappctr: $stateParams.vaesysnivappctr };
                    }
                }),
                new FormulaireItem('tymcleintcri'),
                new FormulaireItem('vaereftypcri'),
                new FormulaireItem('cptcleintcri', {
                    default: $stateParams.cptcleint ? $stateParams.cptcleint : null,
                    ignoreEtat: !!$stateParams.cptcleint,
                    readonly: $stateParams.cptcleint,
                    resourceParams: () => {
                        return { pruseq: $stateParams.pruseq };
                    }
                }),
                new FormulaireItem('annfindebcri', {
                    default: $stateParams.annfindeb ? $stateParams.annfindeb : null,
                    hidden: true,
                    ignoreEtat: !!$stateParams.annfindeb
                })
            ],
            { largeurDefaut: 33.33 }
        ),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 155 },
            { nom: 'docaffnumdoc', largeur: 120 },
            { nom: 'docaffmnt', largeur: 126 },
            { nom: 'tyhdsc', largeur: 180 },
            { nom: 'docaffnumdocext', largeur: 160 },
            { nom: 'docrefcod', largeur: 130 },
            { nom: 'docrefnom', largeur: 200 },
            { nom: 'docaffdat', largeur: 100 },
            { nom: 'docaffdsc', largeur: 280 },
            { nom: 'docreqcod', largeur: 110 },
            { nom: 'docreqnom', largeur: 157 },
            { nom: 'ubrprucod', largeur: 90, hidden: $stateParams.t_prucleint },
            { nom: 'ubrprudsc', largeur: 280, hidden: $stateParams.t_prucleint }
        ],
        colonnesFixesDroite: [
            { nom: 'impactpos', largeur: 130, total: true },
            { nom: 'impactneg', largeur: 130, total: true }
        ],
        colonnesCachees: ['vaesysnatctb', 'ecrcod', 'docaffcleint', 'doccleintref', 'tydcleintref', 'tydflgdocgrh']
    };
    function getForageItem(rowData: any): string {
        if (rowData.ecrcod === 'PD0006-00') {
            return pd0006Route.NAME;
        } else if (rowData.ecrcod === 'GL0020-00') {
            return gl0020Route.NAME;
        } else if (rowData.ecrcod === 'IF0008-00-2') {
            return if0008Route.NAME;
        } else if (rowData.ecrcod === 'CP0029-00') {
            return cp0029Route.NAME;
        } else if (rowData.ecrcod === 'AC0070-00' || rowData.ecrcod === 'AC0070-01') {
            return ac0070Route.NAME;
        } else if (rowData.ecrcod === 'CR0013-00-2') {
            return cr0013Route.NAME;
        } else if (rowData.ecrcod === 'CR0024-00-2') {
            return cr0024Route.NAME;
        } else if (rowData.ecrcod === 'IN0014-00') {
            return in0014Route.NAME;
        } else if (rowData.ecrcod === 'GT0010-00') {
            return gt0010Route.NAME;
        } else if (rowData.ecrcod === 'GA0007-00-2') {
            return ga0007Route.NAME;
        } else if (rowData.ecrcod === 'DB0005-00-2' || rowData.ecrcod === 'DB0005-01-2') {
            return db0005Route.NAME;
        } else if (rowData.ecrcod === 'CP0014-00') {
            return cp0014Route.NAME;
        } else if (rowData.ecrcod === 'CP0040-00') {
            return cp0040Route.NAME;
        } else if (rowData.ecrcod === 'DB0012-00-2') {
            return db0012Route.NAME;
        } else if (rowData.ecrcod === 'MC0099-00-2') {
            return mc0099Route.NAME;
        } else if (rowData.ecrcod === 'IN0043-00-2') {
            return in0043Route.NAME;
        } else if (rowData.ecrcod === 'AC0037-00') {
            return ac0037Route.NAME;
        } else if (rowData.ecrcod === 'MC0101-00-2') {
            return mc0101Route.NAME;
        } else if (rowData.ecrcod === 'AC0010-02') {
            return ac0010Route.NAME;
        } else if (rowData.ecrcod === 'MC0109-00-2') {
            return mc0109Route.NAME;
        } else if (rowData.ecrcod === 'CP0013-00' || rowData.ecrcod === 'CP0013-01') {
            return cp2010Route.NAME;
        } else if (rowData.ecrcod === 'IN0035-00') {
            return in0035Route.NAME;
        } else if (rowData.ecrcod === 'MR0212-00-2') {
            return mr0212Route.NAME;
        } else if (rowData.ecrcod === 'CR0021-00-2') {
            return cr0021Route.NAME;
        } else if (rowData.ecrcod === 'CR0016-00-2') {
            return cr0016Route.NAME;
        } else if (rowData.ecrcod === 'GL0003-00-2') {
            return gl0003Route.NAME;
        } else if (rowData.ecrcod === 'CP0018-00') {
            return cp0018Route.NAME;
        } else if (rowData.ecrcod === 'CR0007-00-2') {
            return cr0007Route.NAME;
        } else if (rowData.ecrcod === 'CP0026-00') {
            return cp0026Route.NAME;
        } else if (rowData.ecrcod === 'DB0023-00' && rowData.tydcleintref !== 135) {
            return db0023Route.NAME;
        }/* else if (data.ecrcod === 'CB0053-00') {
            return cb0053Route.NAME;
        }*/
    }
    function getParamItem(rowData: any) {
        const MONOS = [
            'PD0006-00',
            'GL0020-00',
            'IF0008-00-2',
            'CP0029-00',
            'AC0070-00',
            'AC0070-01',
            'CR0013-00-2',
            'CR0024-00-2',
            'IN0014-00',
            'GT0010-00',
            'GA0007-00-2',
            'DB0005-01-2',
            'DB0005-00-2',
            'CP0014-00',
            'CP0040-00',
            'DB0012-00-2',
            'IN0043-00-2',
            'AC0037-00',
            'AC0010-02',
            'MC0109-00-2',
            'CP0013-01',
            'CP0013-00',
            'IN0035-00',
            'MR0212-00-2',
            'CR0021-00-2',
            'CR0016-00-2',
            'GL0003-00-2',
            'CP0018-00',
            'CR0007-00-2',
            'CP0026-00',
            'DB0023-00'
        ];
        if (rowData.ecrcod === 'MC0099-00-2') {
            return { id: rowData.docaffcleint, indfor: 1 };
        } else if (rowData.ecrcod === 'CP0013-00') {
            return { id: rowData.docaffcleint };
        } else if (rowData.ecrcod === 'CB0053-00') {
            return { id: rowData.doccleintref, prucleint: rowData.prucleint, vaesysnatctb: rowData.vaesysnatctb };
        } else if (rowData.ecrcod === 'MC0101-00-2') {
            return { id: rowData.doccleintref, doccleintref: rowData.doccleintref };
        } else if (rowData.ecrcod === 'CP0040-00') {
            return { id: rowData.prucleint };
        } else if (MONOS.includes(rowData.ecrcod) && rowData.tydcleintref !== 135) {
            return { id: rowData.doccleintref };
        }
    }
}
