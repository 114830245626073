import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMC2016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc2016Params {
    prbcleint: string;
}

/* @ngInject */
export default function Mc2016Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IMc2016Params
) {
    const vm: IPageMC2016 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('prbcleint', {
                required: true,
                readonly: true,
                ignoreEtat: true,
                default: $stateParams.prbcleint
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'cptcod', largeur: 100},
            {nom: 'cptdsc', largeur: 180}
        ],
        colonnesVisibles: [
            {nom: 'unacod', largeur: 100},
            {nom: 'unadsc', largeur: 180},
            {nom: 'cifcod', largeur: 100},
            {nom: 'cifdsc', largeur: 180},
            {nom: 'blocodori', largeur: 120},
            {nom: 'blodscori', largeur: 180},
            {nom: 'blocoddst', largeur: 120},
            {nom: 'blodscdst', largeur: 180},
            {nom: 'usrnomcre', largeur: 150},
            {nom: 'datcre', largeur: 150}
        ]
    };
}
