import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageRr0504 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0504Controller(Operateur: IOperateurService,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService,
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Pagination: IPaginationClass
) {
    const vm: IPageRr0504 = this;

    vm.multiOccurrenceOptions = {
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_CAL',
                'BOUCAL',
                {
                    selection: { nom: 'calculer' },
                    successAction: () => {
                        vm.multiOccurrence && vm.multiOccurrence.fetchDataList();
                    },
                    fonction: 'BOUCAL'
                }),
            new MenuItemSelection(
                'LBL_BTN_ANN',
                'BOUCANN',
                {
                    selection: { nom: 'annuler' },
                    successAction: () => {
                        vm.multiOccurrence && vm.multiOccurrence.fetchDataList();
                    },
                    fonction: 'BOUCANN'
                })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('unpcleint', { largeur: 33 }),
            new FormulaireItem('ppacleint', { largeur: 33, readonly: true }),
            new FormulaireItem('eplnummatt', { largeur: 33, readonly: $stateParams.employe, hidden: $stateParams.employe, default: $stateParams.eplnummat }),
            new FormulaireItem('rad_epl', { largeur: 100, default: 1 })
        ]),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            criteresSuggeresVisibles: true
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 210 }
        ],
        colonnesVisibles: [
            { nom: 'nbr_fep', largeur: 80 },
            { nom: 'nbr_trt', largeur: 80 },
            { nom: 'nbr_cal', largeur: 80 },
            { nom: 'nbr_pamcal', largeur: 80 },
            { nom: 'nbr_pamoff', largeur: 80 },
            { nom: 'flg_arr', largeur: 80 },
            { nom: 'flg_err', largeur: 80 },
            { nom: 'flg_avr', largeur: 80 }
        ]
    };

}

