import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageRR2014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface Rr2014Params {
    demcleint: number;
    unpcleint: number;
    ppacleint: number;
}

/* @ngInject */
export default function Rr2014Controller(
    $stateParams: Rr2014Params,
    MenuItemSelection: IMenuItemSelectionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageRR2014 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            effaceCriteres: true,
            rechercheCollapsable:false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleint', { default: $stateParams.demcleint, disabled: true }),
            new FormulaireItem('colcodori', {
                required: false
            }),
            new FormulaireItem('colcodcib', {
                required: false
            })
        ]),
        resourceParams: {
            demcleint: $stateParams.demcleint
        },
        colonnesFixesGauche: [{ nom: 'tagcod', largeur: 105 }],
        colonnesVisibles: [
            { nom: 'ppacodcal', largeur: 105 },
            { nom: 'ppacodori', largeur: 105 },
            { nom: 'aftcod', largeur: 262 },
            { nom: 'dernumdoc', largeur: 79 },
            { nom: 'lemcod', largeur: 273 },
            { nom: 'temcod', largeur: 273 },
            { nom: 'temdscabr', largeur: 273 },
            { nom: 'tagdscabr', largeur: 273 },
            { nom: 'aftdsc', largeur: 273 },
            { nom: 'derdsc', largeur: 273 },
            { nom: 'lemdsc', largeur: 273 },
            { nom: 'rpmdatdeb', largeur: 273 },
            { nom: 'rpmdatfin', largeur: 273 }
        ],
        colonnesFixesDroite: [
            { nom: 'rpmqteant', largeur: 180, total: true },
            { nom: 'rpmmntant', largeur: 180, total: true },
            { nom: 'rpmqteren', largeur: 180, total: true },
            { nom: 'rpmmntren', largeur: 180, total: true }
        ]

    };
}
