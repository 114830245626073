import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGl0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceSIAOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0001Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGl0001 = this;

    vm.multiOccurrenceSIAOptions = {
        colonnesFixesGauche: [
            {nom: 'siapecann', largeur: 89}
        ],
        bloc: 'sia',
        colonnesVisibles: [
            {nom: 'sfucleintfd_cod', largeur: 89},
            {nom: 'sfucleintfc_cod', largeur: 89},
            {nom: 'sfucleintse_cod', largeur: 89},
            {nom: 'sfucleintse_dsc', largeur: 150},
            {nom: 'sfucleintua_cod', largeur: 89},
            {nom: 'sfucleintua_dsc', largeur: 150},
            {nom: 'vaedsc', largeur: 128},
            {nom: 'sfucleintc_cod', largeur: 89},
            {nom: 'sfucleintc_dsc', largeur: 150}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('siapecann', { required: true, default: '' }),
            new FormulaireItem('sfucleintfd'),
            new FormulaireItem('sfucleintfc'),
            new FormulaireItem('sfucleintse'),
            new FormulaireItem('sfucleintua'),
            new FormulaireItem('vaesiasfutypcpt'),
            new FormulaireItem('sfucleintc'),
        ]),
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'cifcod', largeur: 89},
            {nom: 'cifdsc', largeur: 247},
            {nom: 'unacod', largeur: 89},
            {nom: 'unadsc', largeur: 247},
            {nom: 'vaesysnatctb_cod', largeur: 89},
            {nom: 'vaesysnatctb_dsc', largeur: 180},
            {nom: 'cptcod', largeur: 89},
            {nom: 'cptdsc', largeur: 247},
            {nom: 'sieflgpri', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('siecifcodvar'),
            new FormulaireItem('sieunacodvar'),
            new FormulaireItem('vaesysnatctb'),
            new FormulaireItem('siecptcodvar'),
            new FormulaireItem('sieflgpri'),
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceSIAOptions
    };

}
