import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0129{
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0129Controller(mr0119Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0129 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0119Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mr0119Route.NAME,
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 110},
            {nom: 'tagdsc', largeur: 262},
            {nom: 'tagdscabr', largeur: 262},
            {nom: 'tagdatdeb', largeur: 100},
            {nom: 'tagdatfin', largeur: 100},
            {nom: 'tagcod_ref', largeur: 90},
            {nom: 'tagdscabr_ref', largeur: 262},
            {nom: 'mtgnummth', largeur: 90},
            {nom: 'mtgdsc', largeur: 262},
            {nom: 'unccod', largeur: 90},
            {nom: 'uncdsc', largeur: 262},
            {nom: 'unccodsal', largeur: 90},
            {nom: 'uncdscsal', largeur: 262},
            {nom: 'vaetagtyp_dsc', largeur: 200},
            {nom: 'tagindimp', largeur: 91},
            {nom: 'vacvalcle', largeur: 95},
            {nom: 'vacdsc', largeur: 262},
            {nom: 'tgncod', largeur: 95},
            {nom: 'tgndsc', largeur: 262},
            {nom: 'colcod', largeur: 90},
            {nom: 'coldsc', largeur: 262},
            {nom: 'rcecod', largeur: 90},
            {nom: 'rcedscabr', largeur: 262},
            {nom: 'carcod', largeur: 90},
            {nom: 'cardsc', largeur: 262},
            {nom: 'colcodsalbas', largeur: 90},
            {nom: 'coldscsalbas', largeur: 262},
            {nom: 'tagprical', largeur: 90},
            {nom: 'tagdoc', largeur: 375},
            {nom: 'datcre', largeur: 100}
        ]
    };
}
