import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageDb0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Db0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItem: IMenuItemClass,
                                         downloadManager: IDownloadManagerService,
                                         $stateParams: IStateParamsService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageDb0001 = this;

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/db0001/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        colonnesVisibles: [
            { nom: 'blocod', largeur: 90 },
            { nom: 'blodsc', largeur: 230 },
            { nom: 'blodscabr', largeur: 157 },
            { nom: 'bloflgdef', largeur: 74 },
            { nom: 'bloflgdet', largeur: 70 },
            { nom: 'bloflgnoncmp', largeur: 116 },
            { nom: 'bloflgcen', largeur: 100 }
        ],
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('blocod', { required: true, maxlength: 5, onBlur: data => data.blocod = data.blocod.toUpperCase() }),
            new FormulaireItem('blodsc', { required: true, largeur: 50 }),
            new FormulaireItem('blodscabr', { required: true }),
            new FormulaireItem('bloflgdef'),
            new FormulaireItem('bloflgdet'),
            new FormulaireItem('bloflgnoncmp'),
            new FormulaireItem('bloflgcen'),
        ], { largeurDefaut: 25 })
    };
}