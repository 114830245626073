import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCP0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0020Controller(Menu: IMenuClass,
    MenuItem: IMenuItemClass) {
    const vm: IPageCP0020 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            edition: false,
            supprime: false
        },
        colonnesVisibles: [
            { nom: 'reqnumdoc', largeur: 136 },
            { nom: 'reqnumdocseq', largeur: 60 },
            { nom: 'tydcod', largeur: 105 },
            { nom: 'refcod', largeur: 105 },
            { nom: 'refnomabr', largeur: 231 },
            { nom: 'radcod', largeur: 63 },
            { nom: 'drenumref', largeur: 136 },
            { nom: 'reqnumphy', largeur: 126 },
            { nom: 'dremnt', largeur: 126 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'etddsc', largeur: 180 },
            { nom: 'flgcou', largeur: 100 },
            { nom: 'flgdu', largeur: 65 },
            { nom: 'nbrajf', largeur: 95 },
            { nom: 'lcpcod', largeur: 65 },
            { nom: 'lcpdsc', largeur: 210 },
            { nom: 'reqmnt', largeur: 126 },
            { nom: 'dccmntpay', largeur: 126 },
            { nom: 'intcodreq', largeur: 102 },
            { nom: 'intnomreq', largeur: 262 },
            { nom: 'intcoddem', largeur: 105 },
            { nom: 'intnomdem', largeur: 262 },
            { nom: 'pdfcod', largeur: 120 },
            { nom: 'prjdscabr', largeur: 220 },
            { nom: 'vecreqcat', largeur: 100 },
            { nom: 'reqnumrescd', largeur: 163 },
            { nom: 'reqnumresca', largeur: 163 },
            { nom: 'reqdsc', largeur: 236 }
        ]
    };
}
