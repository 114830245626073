import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";


interface IPageAC0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}



/* @ngInject */
export default function Ac0027Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         ac0028Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageAC0027 = this;

    vm.multiOccurrenceOptions = {

            fonctions: {
                rechercheCollapsable: false
            },

            navigatePage: ac0028Route.NAME,
            navigateParams: (rowData: any) => ({
                apocleint: rowData.apocleint,
                doccleint: rowData.doccleint
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage(ac0028Route.NAME)
            ]),
        colonnesFixesGauche: [
            {nom: 'aponumdoc',largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 200},
            {nom: 'apodsc', largeur: 200},
            {nom: 'etddsc', largeur: 200},
            {nom: 'aponumref', largeur: 200},
            {nom: 'tydref', largeur: 200},
            {nom: 'docrefere', largeur: 200},
            {nom: 'aponumseao', largeur: 200},
            {nom: 'apodatpub', largeur: 200},
            {nom: 'apodatfer', largeur: 200},
            {nom: 'apodatred', largeur: 200},
            {nom: 'vecapoouvpub', largeur: 200},
            {nom: 'vecapoouvpubdsc', largeur: 200},
            {nom: 'vecapomodadj', largeur: 200},
            {nom: 'vecapomodadjdsc', largeur: 200},
            {nom: 'intcodach', largeur: 200},
            {nom: 'intnomach', largeur: 200},  // Nom acheteur
            {nom: 'intcodreq', largeur: 200},
            {nom: 'intnomreq', largeur: 200},  // Nom requérant
            {nom: 'indmuladj', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnom', largeur: 200},
            {nom: 'founomabr', largeur: 200},
            {nom: 'vecaosinvdsc', largeur: 200},
            {nom: 'vecaosrep', largeur: 200},
            {nom: 'vecaosrepdsc', largeur: 200},
            {nom: 'apototinv', largeur: 100, },   // Fournisseurs invités
            {nom: 'apototfou', largeur: 100},   // Total fournnisseurs AO
            {nom: 'soumissionnaire', largeur: 100},
            {nom: 'apoflgpij', largeur: 100},
            {nom: 'apoindreg10', largeur: 100},
            {nom: 'vaeapoondsc', largeur: 200},
            {nom: 'apomnt', largeur: 200},
            {nom: 'apomntbdg', largeur: 200},
            {nom: 'nibcod', largeur: 200},
            {nom: 'nibnom', largeur: 200}
        ],
        colonnesCachees: [
            'apocleint',
            'doccleint'
        ]
    };

};
