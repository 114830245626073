import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageIf0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function If0009Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         if0013Route: IRoute) {
    const vm: IPageIf0009 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: if0013Route.NAME,
        navigateParams: (rowData: any, multiOccurrence: IMultiOccurrence) => {
            return {
                catcleint: rowData.catcleint,
                catcod: rowData.catcod,
                catdsc: rowData.catdsc,
                peccod: multiOccurrence.etatSelected.criteresSuggeresData.peccod
            };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccod', {
                required: true,
                default: () => {
                    return vm.valeursEcran.peccod;
                },
                nombreLibellesDetailsCacher: 1
            })
        ]),
        colonnesVisibles: [
            { nom: 'catcod', largeur: 120 },
            { nom: 'catdsc', largeur: 300 },
            { nom: 'catamo', largeur: 250 },
            { nom: 'cattauamo', largeur: 80 },
            { nom: 'hitmnttrsini', largeur: 200 },
            { nom: 'hitmnttrsamo', largeur: 200 },
            { nom: 'hitmntnet', largeur: 200 },
            { nom: 'mntina', largeur: 200 }
        ],
        fonctions: {
            suiviModification: false,
            rechercheCollapsable: false
        }
    };
}
