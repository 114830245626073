import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as0009',
    ecrans: ['00-2'],
    params: ['id',
             '?ahtcleint',
             '?flginfor',
             '?demcleint',
             '?unccleint',
             '?ahthresem']
});
