import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageMr2004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr2004Params {
    id: string;
    edxcleint: string;
    vaeemxtyp: string;
}
/* @ngInject */
export default function Mr2004Controller($stateParams: IMr2004Params,
                                         Operateur: IOperateurService) {
    const vm: IPageMr2004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },        
        filtres: [
            {
                colonne: 'edxcleint',
                operateur: $stateParams.edxcleint ? Operateur.EGALE : Operateur.NULL,
                valeur: $stateParams.edxcleint,
                visible: false
            },           
            {
                colonne: 'vaeemxtyp',
                operateur: Operateur.EGALE_PAS,
                valeur: 'DBG',
                visible: false
            }
        ],
        colonnesVisibles: [
            {nom: 'emxmsg', largeur: 400}
        ]
    };
}
