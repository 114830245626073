import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCr1004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr1004Controller(
    $stateParams: any,
    dateFilter: IFilterDate,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr1004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            nouveau: ecranDetails.valeurs.flgins
        },
        filtresParametres: [
            { colonne: 'coccleint', valeur: $stateParams.coccleint, visible: false }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cevcleint'),
            new FormulaireItem('coedat', { default: dateFilter(new Date(), 'yyyy-MM-dd') }),
            new FormulaireItem('intcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('coecom', { largeur: 75 }),
            new FormulaireItem('coccleint', { hidden: true, default: $stateParams.coccleint })
        ], { largeurDefaut: 25 }),
        colonnesVisibles: [
            { nom: 'cevcod', largeur: 110 },
            { nom: 'cevdsc', largeur: 180 },
            { nom: 'coedat', largeur: 110 },
            { nom: 'intcod', largeur: 110 },
            { nom: 'intnom', largeur: 180 },
            { nom: 'coecom', largeur: 200 }
        ]
    };
}
