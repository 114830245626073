import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTP0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tp0008Controller(Menu: IMenuClass) {
    const vm: IPageTP0008 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'unocoddeb', largeur: 200},
            {nom: 'unodscdeb', largeur: 200},
            {nom: 'unocodfin', largeur: 200},
            {nom: 'unodscfin', largeur: 200},
            {nom: 'ktoflgurgdef', largeur: 200},
            {nom: 'urgcod', largeur: 200},
            {nom: 'urgdscabr', largeur: 200},
            {nom: 'ttccod', largeur: 200},
            {nom: 'ttcdsc', largeur: 200}
        ]
    };
}
