import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0078 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0078Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAc0078 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tdocod', largeur: 115 },
            { nom: 'tdodsc', largeur: 272 },
            { nom: 'tdoflgact', largeur: 100 },
            { nom: 'tdoflgini', largeur: 100 },
            { nom: 'tdoflgfin', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tdocod', { required: true }),
            new FormulaireItem('tdodsc', { required: true }),
            new FormulaireItem('tdoflgact', { default: 1 }),
            new FormulaireItem('tdoflgini'),
            new FormulaireItem('tdoflgfin'),
        ])
    };
}
