import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMc2013 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    cypMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cyaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cyvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cysMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc2013Params {
    type: string;
}

/* @ngInject */
export default function Mc2013Controller($stateParams: IMc2013Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc2013 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            boutonDupliquer: false,
            exportation: false
        },
        formulaire: new Formulaire('LBL_BLC_CYE', [
            new FormulaireItem('cyenumeta'),
            new FormulaireItem('etddsc'),
            new FormulaireItem('vaecyeact_dsc')
        ], {largeurDefaut: 33.33})
    };

    vm.cypMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vaecypact_dsc', largeur: 180},
            {nom: 'cypflgstd', largeur: 130},
            {nom: 'cypflgave', largeur: 130},
            {nom: 'cypflgactaccecr', largeur: 130},
            {nom: 'cypflgaccactpre', largeur: 130},
            {nom: 'cypflgcouaut', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaecypact'),
            new FormulaireItem('cypflgstd'),
            new FormulaireItem('cypflgave'),
            new FormulaireItem('cypflgactaccecr'),
            new FormulaireItem('cypflgaccactpre'),
            new FormulaireItem('cypflgcouaut')
        ], {largeurDefaut: 33.33})
    };

    vm.cydMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ttccod', largeur: 120},
            {nom: 'ttcdsc', largeur: 250},
            {nom: 'tytcod', largeur: 120},
            {nom: 'tytdsc', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ttccleint', {
                resourceParams: (data:any) => {
                    return {
                        redcleint: data.$ancetre.redcleint
                    };
                }
            })
        ])
    };

    vm.cyaMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vaesysite_dsc', largeur: 200}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaesysite')
        ])
    };

    vm.cyvMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'cyvimp', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cyvimp')
        ])
    };

    vm.cysMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'orgcod', largeur: 100},
            {nom: 'orgnom', largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('orgcleint')
        ])
    }
}
