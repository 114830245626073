import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFilterDate } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionTelechargementClass } from '../../../../../core/services/menu/menu-item-action-telechargement.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IIsForageValideService } from "../../../../../core/services/is-forage-valide.service";

interface IPageMR0032 {
    valeursEcran: any;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0032Controller(mr0010Route: IRoute,
    dateFilter: IFilterDate,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    isForageValide: IIsForageValideService) {
    const vm: IPageMR0032 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: (data) => data.ecrcod ? isForageValide.isForageValide('gl0008', data.ecrcod) ? `secure.${data.ecrcod}.ecran` : undefined : mr0010Route.NAME,
        navigateParams: (data) => data.ecrcod ? isForageValide.isForageValide('gl0008', data.ecrcod) ? { id: data.trncleint } : undefined : { id: data.avicleint, intcleint: vm.valeursEcran.intcleint },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_DELETE',
                'BOUDEL',
                () => ({
                    avicleint: vm.multiOccurrence.getDataActiveRow() && vm.multiOccurrence.getDataActiveRow().avicleint,
                    intcleint: vm.valeursEcran.intcleint
                }),
                {
                    icon: 'delete',
                    successAction: () => { vm.multiOccurrence.fetchDataList(); },
                    hidden: (data: any) => data.typcri === 'Approbation'
                }
            )
        ]),
        fonctions: {
            selectionCycle: true,
            rechercheCollapsable: false
        },
        classItems: {
            row: (data: any) => {
                if (data.aviflggenerr === 1) {
                    return 'ex-erreur';
                }
            }
        },
        messageErreurSelection: "LBL_ERREUR_SELECTION",
        colonnesCachees: ['aviflggenerr', 'avicleint', 'typcri', 'ecrcod', 'trncleint'],
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 220 },
            { nom: 'typdsc', largeur: 315 },
            { nom: 'date_avis', largeur: 140 },
            { nom: 'avgflglu', largeur: 60 },
            { nom: 'indpcs', largeur: 60 },
            { nom: 'flg_publication', largeur: 70, menu: [new MenuItemActionTelechargement(
                'LBL_TELECHARGER',
                'BOUPDF',
                () => ({}),
                {
                    iconButton: true,
                    icon: 'attach_file',
                    hidden: (data: any) => !data.flg_publication
                }
            )] },
            { nom: 'texref', largeur: 396 },
            { nom: 'unocod', largeur: 110 },
            { nom: 'unodsc', largeur: 291 },
            { nom: 'unpcod', largeur: 110 },
            { nom: 'unpdsc', largeur: 291 },
            { nom: 'tsadatvig', largeur: 110 },
            { nom: 'gredsc', largeur: 262 },
            { nom: 'geccat', largeur: 110 },
            { nom: 'geccodcla', largeur: 110 },
            { nom: 'echcod', largeur: 110 },
            { nom: 'tsasalhre', largeur: 140 },
            { nom: 'tsasalann', largeur: 140 }
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleint', { default: vm.valeursEcran.intcleint, ignoreEtat: true, required: true, readonly: true }),
            new FormulaireItem('rolcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('type', { default: 'Tous', largeur: 100 }),
            new FormulaireItem('ttccleint', { nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('grpcleint'),
            new FormulaireItem('grocleint'),
            new FormulaireItem('demcleint'),
            new FormulaireItem('unpcleint'),
            new FormulaireItem('avidatdeb'),
            new FormulaireItem('avidatfin', { default: dateFilter(new Date(), 'yyyy-MM-dd'), ignoreEtat: true })
        ])
    };
}
