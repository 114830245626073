import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass, IFormulaire} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMenuItemActionTelechargementClass} from '../../../../../core/services/menu/menu-item-action-telechargement.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IStateService } from "angular-ui-router";
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMR0010 extends IComponentController {
    valeursEcran: any;
    marquerLu: IMenuItem;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    aviComFormulaire: IFormulaire;
    menus: {[key: string]: Array<IMenuItem>};
}

interface IMr0010Params {
    intcleint: number;
}

/* @ngInject */
export default function Mr0010Controller(mr0032Route: IRoute,
                                         $stateParams: IMr0010Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         $state: IStateService,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemActionTelechargement: IMenuItemActionTelechargementClass) {
    const vm: IPageMR0010 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.menuItemTelechargement = [
            new MenuItemActionTelechargement(
                'LBL_RÉCUPÉRER',
                'BOUREC',
                () => ({}),
                {
                    iconButton: true,
                    icon: 'attach_file',
                    hidden: () => !vm.valeursEcran.aviflgcsv
                }
            )
        ];

        vm.menuReference = [
            new MenuItemForage(
                'LBL_FOR_REF',
                (`secure.${vm.valeursEcran.ecrcodref}.ecran`),
                () => getParamsForageReference(),
                {   fonction: 'T_BOUREF',
                    hidden: () => !vm.valeursEcran.ecrcodref }
            )
        ];

         vm.menuOrigine = [
            new MenuItemForage(
                'LBL_FOR_ORI',
                (`secure.${vm.valeursEcran.ecrcodori}.ecran`),
                () => getParamsForageOrigine(),
                {   fonction: 'T_BOUORI',
                    hidden: () => !vm.valeursEcran.ecrcodori }
            )
        ];

        // Commentaire
        vm.aviComFormulaire = new Formulaire('', [
            new FormulaireItem('avicom')
        ], { largeurDefaut: 95 });

        vm.monoOccurrenceOptions= {
            fonctions: {
                afficherMenusDansEntete: true
            },
            menus: [
                new MenuItemAction(
                    'BTN_SUPPRIMER',
                    'BOUDEL',
                    () => ({ intcleint: $stateParams.intcleint }),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        lblMessageSuccess: 'G_LBL_MSG_SUPP',
                        successAction: () => {
                            $state.go(mr0032Route.NAME)
                        }
                    }
                ),
                new MenuItemAction(
                    'BTN_MARQUER_LU',
                    'BOULU',
                    () => ({ intcleint: $stateParams.intcleint }),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        lblMessageSuccess: 'G_LBL_MSG_LU',
                        hidden: () => vm.valeursEcran.aviflglu,
                        successAction: (data: any) => vm.valeursEcran.aviflglu = data.aviflglu,
                        champsRechargement: ['aviflglu']
                    }
                ),
                new MenuItemAction(
                    'BTN_MARQUER_NONLU',
                    'BOUNONLU',
                    () => ({ intcleint: $stateParams.intcleint }),
                    {
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        lblMessageSuccess: 'G_LBL_MSG_NONLU',
                        hidden: () => !vm.valeursEcran.aviflglu,
                        successAction: (data: any) => vm.valeursEcran.aviflglu = data.aviflglu,
                        champsRechargement: ['aviflglu']
                    }
                )
            ],
            formulaire: new Formulaire('LBL_BLC_AVI', [])
        };
    }

    function getParamsForageReference() {
        if (vm.valeursEcran.ecrcodref === 'co0010') {
            return {
                id: vm.monoOccurrence.data.avicleintref,
                stamnetab: 'EPL',
                idecleintref: vm.valeursEcran.eplcleint
            };
        } else if ( vm.valeursEcran.ecrcodref === 'cc0002') {
            return {
                trmcleint: vm.monoOccurrence.data.avicleintref
            };
        } else if ( vm.valeursEcran.ecrcodref === 'rr0019') {
            return {
                employe: vm.valeursEcran.cleint
            };            
        } else if ( vm.valeursEcran.ecrcodref === 'rr0014') {
            return {
                id: vm.valeursEcran.cleint,
                demcleint: vm.monoOccurrence.data.demcleint
            };                        
        } else if ( vm.valeursEcran.ecrcodori === 'as0023') {
            return {
                ecran: '01-2',
                id: vm.valeursEcran.cleint
            };
        } else {
            return {
                id: vm.valeursEcran.cleint
            };
        }
    }

    function getParamsForageOrigine() {
        if (vm.valeursEcran.ecrcodori === 'co0010') {
            return {
                id: vm.monoOccurrence.data.avicleintori,
                stamnetab: 'EPL',
                idecleintref: vm.valeursEcran.eplcleint
            };
        } else if ( vm.valeursEcran.ecrcodori === 'cc0002') {
            return {
                id: vm.monoOccurrence.data.avicleintori
            };
        } else if ( vm.valeursEcran.ecrcodori === 'rr0014') {
            return {
                id: vm.valeursEcran.cleint,
                demcleint: vm.monoOccurrence.data.demcleint
            };            
        } else if ( vm.valeursEcran.ecrcodori === 'rr0019') {
            return {
                employe: vm.valeursEcran.cleint
            };            
        } else if ( vm.valeursEcran.ecrcodori === 'mr0007' || vm.valeursEcran.ecrcodori === 'mr0217' || vm.valeursEcran.ecrcodori === 'rr0006' ) {
            return {
                id: vm.valeursEcran.cleintori
            };                        
        } else if ( vm.valeursEcran.ecrcodori === 'as0023') {
            return {
                ecran: '01-2',
                id: vm.valeursEcran.cleint
            };
        } else {
            return {
                id: vm.valeursEcran.cleint
            };
        }
    }
}
