import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMc0060 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0060Controller(Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    mc0044Route: IRoute,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc0060 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            supprime: false,
            edition: false,
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_ACT', 'BOUACT', {
                selection: {
                    nom: 'activer'
                },
                fonction: 'BOUACT'
            }),
            new MenuItemSelection('LBL_BTN_INA', 'BOUINA', {
                selection: {
                    nom: 'inactiver'
                },
                fonction: 'BOUINA'
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaeprutypbdg', { required: false }),
            new FormulaireItem('radio', { default: 2 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cifcleint'),
            new FormulaireItem('unacleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('prucodde'),
            new FormulaireItem('prucoda'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('datdebdeb'),
            new FormulaireItem('datdebfin'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('datfindeb'),
            new FormulaireItem('datfinfin')
        ]),
        colonnesVisibles: [
            { nom: 'prucod', largeur: 136 },
            { nom: 'prudscl01', largeur: 200, menu: [
                new MenuItemForage(mc0044Route.NAME, (data: any) => ({id: data.prucleint}))
            ] },
            { nom: 'prudatdeb', largeur: 110 },
            { nom: 'prudatfin', largeur: 110 },
            { nom: 'pruflgstu', largeur: 110 }
        ],
        colonnesCachees: [
            'prucleint'
        ]
    };
}
