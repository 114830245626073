import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRe0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re0007Controller(Menu: IMenuClass,
                                         re0008Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageRe0007 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: re0008Route.NAME,
        navigateParams: (rowData: any) => (
            {
                id: rowData.elpcleint,
                indfor: 3
            }
        ),
        actionsNouveaux: new Menu([
            new MenuItemForage(re0008Route.NAME)
        ]), 
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'rtocod', largeur: 120},
            {nom: 'rtodsc', largeur: 160},
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 160},
            {nom: 'tagcod', largeur: 120},
            {nom: 'tagdscabr', largeur: 160},
            {nom: 'elpdatdeb', largeur: 126},
            {nom: 'elpdatfin', largeur: 126},
            {nom: 'elpmnt', largeur: 120},
            {nom: 'elppou', largeur: 120},
            {nom: 'elpflgact', largeur: 100},
            {nom: 'vaesysprvret_dsc', largeur: 120},
            {nom: 'elpflgina', largeur: 100},
            {nom: 'aftcod', largeur: 120},
            {nom: 'aftdsc', largeur: 160},
            {nom: 'aftdatdeb', largeur: 126},
            {nom: 'aftdatfin', largeur: 126},
            {nom: 'lemcod', largeur: 100},
            {nom: 'lemdsc', largeur: 160},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 160},
            {nom: 'strcod', largeur: 120},
            {nom: 'strdsc', largeur: 160}
        ]
    };
}
