import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMC0122 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0122Controller(mc0123Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMC0122 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0123Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0123Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'mfonumdoc', largeur: 115},
            {nom: 'mfodsc', largeur: 262},
            {nom: 'empcod', largeur: 115},
            {nom: 'empnom', largeur: 262},
            {nom: 'mfodat', largeur: 100},
            {nom: 'mfodurhrs', largeur: 140},
            {nom: 'mfomnt', largeur: 126},
            {nom: 'mfoflgabs', largeur: 76}
        ]
    };
}
