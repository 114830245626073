import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'gs0115',
    ecrans: ['00-2'],
    params: [
        '?ecrcleintpar',
        '?srccodpar',
        '?cleint',
        '?fonctionTransversaleFormulaire'
    ]});
