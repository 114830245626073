import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc2010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc2010Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2010 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            supprime: false,
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('vecvalcle'),
            new FormulaireItem('vecdsc', { largeur: 50 }),
            new FormulaireItem('vecflgact', { default: 1 }),
            new FormulaireItem('vecflgava')
        ], { largeurDefaut: 25 }),
        colonnesVisibles: [
            { nom: 'vecvalcle', largeur: 100 },
            { nom: 'vecdsc', largeur: 262 },
            { nom: 'vecflgact', largeur: 70 },
            { nom: 'vecflgava', largeur: 70 }
        ]
    };
}