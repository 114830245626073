import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0236 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrencePdtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0236Controller(Menu: IMenuClass,
                                         $stateParams: any,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0236 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'tagcod', largeur: 89}
        ],
        colonnesVisibles: [
            {nom: 'tagdscabr', largeur: 185},
            {nom: 'urgcod', largeur: 130},
            {nom: 'urgdscabr', largeur: 237},
            {nom: 'strcod', largeur: 89},
            {nom: 'strdsc', largeur: 185},
            {nom: 'tgpdatvig', largeur: 130},
            {nom: 'tgpflgact', largeur: 74},
            {nom: 'tgpfct', largeur: 139},
            {nom: 'tgpmnt', largeur: 139},
            {nom: 'unccod', largeur: 89},
            {nom: 'tgpmntmax', largeur: 160},
            {nom: 'bqecodsld', largeur: 130},
            {nom: 'bqedscabrsld', largeur: 210},
            {nom: 'bqecodtauqte', largeur: 140},
            {nom: 'bqedscabrtauqte', largeur: 210},
            {nom: 'bqecodtaumnt', largeur: 140},
            {nom: 'bqedscabrtaumnt', largeur: 210}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tagcleint', {largeur: 25}),
            new FormulaireItem('urgcleint', {largeur: 25}),
            new FormulaireItem('strcleint', {largeur: 25}),
            new FormulaireItem('tgpdatvig', {largeur: 25}),
            new FormulaireItem('tgpflgact', {largeur: 25, default: 1}),
            new FormulaireItem('tgpfct', {largeur: 25}),
            new FormulaireItem('tgpmnt', {largeur: 25}),
            new FormulaireItem('unccleintmnt', {largeur: 20}),
            new FormulaireItem('tgpmntmax', {largeur: 20}),
            new FormulaireItem('bqecleint', {largeur: 20}),
            new FormulaireItem('bqecleinttauqte', {largeur: 20}),
            new FormulaireItem('tgpflgenlqte', {hidden: true , default: 0}),
            new FormulaireItem('tgpflgenltaux', {hidden: true , default: 0}),
            new FormulaireItem('bqecleinttaumnt', {largeur: 20})
        ]),
    };
}
