import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';


interface IPageTf0029 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */
export default function Tf0029Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageTf0029 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        formulaire: new Formulaire('LBL_FOR_AJS', [
            new FormulaireItem('efacleint', {
                largeur: 33.3
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('folann', { largeur: 33.3 }),
            new FormulaireItem('folcleint', {
                largeur: 33.3,
                resourceParams: (data: any) => ({ folann: data.folann })
            }),
            new FormulaireItem('folcod', {
                hidden: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rfecleint', {
                largeur: 33.3,
                resourceParams: (data: any) => ({ folann: data.folann, folcleint: data.folcleint, })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaesyscodprv', { largeur: 33.3 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('foacleint', {
                largeur: 33.3,
                resourceParams: (data: any) => ({ folcleint: data.folcleint })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tfvmnt', { largeur: 33.3 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ctrcodnot', {
                largeur: 33.3,
                resourceParams: (data: any) => ({ folcleint: data.folcleint, foacleint: data.foacleint })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ctrnot', {
                largeur: 33.3,
                resourceParams: (data: any) => ({ folcleint: data.folcleint, foacleint: data.foacleint, ctrcodnot: data.ctrcodnot })
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tfvcom', { largeur: 33.3 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tfvflguti', { largeur: 100 })
        ])
    }
}
