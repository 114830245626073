import IStateService = angular.ui.IStateService;
import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';

interface IAs0023Params {
    poscleint_param: number;
    demcleint: number;
    menuId: string;
}

interface IPageAS0023 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireInfos: IFormulaire;
}
/* @ngInject */
export default function As0023Controller($state: IStateService,
    $stateParams: IAs0023Params,
    as0008Route: IRoute,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageAS0023 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        const formulaireItems = [];
        if (ecranDetails.params.p_stamnetab == 'AFT') {
            formulaireItems.push(
                new FormulaireItem('demcleint', { default: $stateParams.demcleint, largeur: 50 }),
                new FormulaireItem('nomtit', { hidden: !vm.valeursEcran.nomtit,
                                         default: vm.valeursEcran.nomtit, largeur: 50 }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('t_aftcleint', {
                    default: vm.valeursEcran.t_aftcleint, largeur: 50,
                    resourceParams: (data: any) => ({
                        demcleint: data.demcleint,
                        lemcleint: data.lemcleint
                    })
                })
            );
        } else if (ecranDetails.params.p_stamnetab == 'POS') {
            formulaireItems.push(new FormulaireItem('poscleint', { default: $stateParams.poscleint_param, largeur: 80 }),
                new FormulaireItem('demcleint', { default: $stateParams.demcleint, hidden: !$stateParams.demcleint, largeur: 80 }),
                new FormulaireItem('nomtit', { default: vm.valeursEcran.nomtit, largeur: 80 }));
        }

        formulaireItems.push(
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS', new Formulaire([
                new FormulaireItem('ttccleint', {
                    nombreLibellesDetailsCacher: 1
                }),
                new FormulaireItem('hhsnumdoc')

            ])),
            new FormulaireItem('hhscleintref', { default: vm.valeursEcran.t_aftcleint || vm.valeursEcran.poscleint })
        );

        vm.monoOccurrenceOptions = { formulaire: new Formulaire('LBL_RATTACHEMENT_HORAIRE', formulaireItems) };

        vm.formulaireInfos = new Formulaire(('LBL_BLC_INFOS'), [
            new FormulaireItem('hhsdatdeb', { default: vm.valeursEcran.hhsdatdeb, largeur: 25 }),
            new FormulaireItem('hhsdatfin', { largeur: 25 }),
            new FormulaireItem('hhsflgdef', { default: 1, largeur: 50 }),
            new FormulaireItem("aut__trnflgstaref", { hidden: true }),
            new FormulaireItem("aut__trnflgstafer", { hidden: true }),
            new FormulaireItem("aut__trnflgstaann", { hidden: true }),
            new FormulaireItem("aut__trnflgstafin", { hidden: true }),
            new FormulaireItem('asgcleint', {
                default: vm.valeursEcran.asgcleint, largeur: 40, resourceParams: (data: any) => ({
                    hhscleintref: data.hhscleintref,
                    hhsdatdeb: data.hhsdatdeb,
                    ahtcleint: data.ahtcleint,
                    asgcleint: data.asgcleint
                }),
                onValueChange: data => {
                    data.ahtcleint = null
                    data.ahtcleint__ahtcod = null
                    data.ahtcleint__ahtdsc = null
                    data.ahtcleintper = null
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ahtcleint', {
                largeur: 40, readonly: (data: any) => data.ahtcleintper,
                resourceParams: (data: any) => ({
                    hhscleintref: data.hhscleintref,
                    flglov: 1,
                    hhsdatdeb: data.hhsdatdeb,
                    ahtcleint: data.ahtcleint,
                    asgcleint: data.asgcleint
                })
            }),
            new FormulaireItemMenuItem(new MenuItemForage(
                'BTN_FORAGE_AHT',
                as0008Route.NAME,
                (data: any) => ({
                    id: data.ahtcleint,
                    asgcleint: data.asgcleint,
                    flginfor: 1,
                    demcleint: data.demcleint,
                    flg:0
                }),
                {
                    iconButton: false,
                    icon: '',
                    class: 'md-raised ex-button-action',
                    hidden: (data: any) => (!data.ahtcleint)
                }
            )),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('uncdscaff'),
            new FormulaireItemMenuItem(new MenuItemAction(
                'LBL_BTN_AHT_REM',
                'BOUREM',
                () => ({}),
                {
                    iconButton: false,
                    saveFirst: true,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    successAction: (data: any) => {
                        $state.go(as0008Route.NAME, {
                            menuId: $stateParams.menuId,
                            id: data.ahtcleinto,
                            asgcleint: vm.monoOccurrence.data.asgcleint,
                            demcleint: vm.monoOccurrence.data.demcleint,
                            flg:0
                        });
                    },
                    hidden: (data: any) => !data.ahtcleint || data.aut__vaeahtman === 'NON' || data.aut__trnflgstaref || data.aut__trnflgstafer || data.aut__trnflgstafin || data.aut__trnflgstaann,
                    disabled: (data: any) => data.aut__vaeahtman === 'NON' || data.aut__vaeceptypmod === 'AUC'
                }
            )),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aeqcleint', { largeur: 40 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('hhsetc', { default: 1 }),
            new FormulaireItem('aut__etc_calcule', { default: 1, readonly: true }),
            new FormulaireItem('aut__flgtpspar', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('hhsdsc', { largeur: 80 })
        ], { largeurDefaut: 20 });
    }
}
