import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRR0089 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0089Controller(rr0090Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0089 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0090Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                etucleint: rowData.etucleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0090Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprnusu', largeur: 263},
            {nom: 'trmcod', largeur: 100},
            {nom: 'trmdsc', largeur: 263},
            {nom: 'trmdatdeb', largeur: 100},
            {nom: 'trmdatfin', largeur: 100},
            {nom: 'etuflgtmpcom', largeur: 85},
            {nom: 'etudsc', largeur: 263},
            {nom: 'vaenivsco_dsc', largeur: 120},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodsc', largeur: 263},
            {nom: 'etunbrcrt', largeur: 80}
        ],
        colonnesCachees: [
            'etucleint'
        ]
    };
}
