import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';


interface IPageRr0528 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    chiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence:IMultiOccurrence;
}

/* @ngInject */
export default function Rr0528Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass,
                                        MenuItemForage: IMenuItemForageClass,
                                        rr2014Route:IRoute,
                                        Menu: IMenuClass
                                        ) {

    const vm: IPageRr0528 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.multiOccurrenceOptions = {
            criteresSuggeres: new Formulaire([
                new FormulaireItem('unpcleint',{
                    required: true
                }),
                new FormulaireItem('ppacleint',{
                    required: true
                })
            ]),  
            fonctions: {   
                rechercheCollapsable: false,
                edition:true
            },     
            colonnesVisibles: [
                {nom: 'eplnummat', largeur:150},
                {nom: 'eplnomprn', largeur:273},
                {nom: 'rpmqteant', largeur:125},
                {nom: 'rpmmntant', largeur:273}
            ],
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'LBL_DETAIL',
                    rr2014Route.NAME,
                    (rowData: any) => ({
                        demcleint: rowData.demcleint,
                        unpcleint:vm.multiOccurrence.etatSelected.criteresSuggeresData.unpcleint,
                        ppacleint:vm.multiOccurrence.etatSelected.criteresSuggeresData.ppacleint
                    }),
                    {
                        icon: 'exit_to_app',
                        fonction: 'BOUDOC',
                        iconButton: true
                    }
                )
            ])  
        };

        vm.chiMultiOccurrenceOptions = {
            bloc: 'det',
            colonnesFixesGauche: [{nom: 'tagcod', largeur:105 }],
            colonnesVisibles : [
                {nom: 'ppacodcal', largeur:105 },
                {nom: 'ppacodori', largeur:105 },
                {nom: 'aftcod', largeur:262 },
                {nom: 'dernumdoc', largeur:79 },
                {nom: 'lemcod', largeur:273 },
                {nom: 'temcod', largeur:273 },
                {nom: 'temdscabr', largeur:273 },
                {nom: 'tagdscabr', largeur:273 },
                {nom: 'aftdsc', largeur:273 },
                {nom: 'derdsc', largeur:273 },
                {nom: 'lemdsc', largeur:273 },
                {nom: 'rpmdatdeb', largeur:273 },
                {nom: 'rpmdatfin', largeur:273 } 
            ],
            colonnesFixesDroite: [
                {nom: 'rpmqteant', largeur:180 },
                {nom: 'rpmmntant', largeur:180 },
                {nom: 'rpmqteren', largeur:180 },
                {nom: 'rpmmntren', largeur:180 }
            ]
        };
    }
    
}

