import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageGR0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gr0010Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageGR0010 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'gplnumdoc', largeur: 90},
            {nom: 'gpldat', largeur: 100},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262},
            {nom: 'eplnummat_vis', largeur: 100},
            {nom: 'eplnomprn_vis', largeur: 262},
            {nom: 'vaccod_nat', largeur: 90},
            {nom: 'vacdsc_nat', largeur: 262},
            {nom: 'gpldsc', largeur: 262},
            {nom: 'gplcom', largeur: 262},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75},
            {nom: 'flgerr', largeur: 75}
        ]
    };
}
