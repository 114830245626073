import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireGroupeClass } from "../../../../../core/services/formulaire/formulaire-groupe.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageDb0051 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    bdgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bdgMultiOccurrence: IMultiOccurrence;
    pruMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pruMultiOccurrence: IMultiOccurrence;
    refMultiOccurrenceOptions: IMultiOccurrenceOptions;
    refMultiOccurrence: IMultiOccurrence;
    prsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    prsMultiOccurrence: IMultiOccurrence;
    bloMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bloMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Db0051Controller(Formulaire: IFormulaireClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    db2005Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite
) {
    const vm: IPageDb0051 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    const criteresSuggeres = new Formulaire([
        new FormulaireGroupe('LBL_IMPUT',
            new Formulaire([
                new FormulaireItem('peccodcleint_de', { largeur: 33 }),
                new FormulaireItem('peccodcleint_a', { largeur: 33 }),
                new FormulaireItem('naturecri', {
                    largeur: 33
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('prucleint', {
                    largeur: 33
                }),
                new FormulaireItem('cptcleint', {
                    largeur: 33
                }),
                new FormulaireItem('el1cleint', {
                    largeur: 33
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('prscleint', {
                    largeur: 33
                }),
                new FormulaireItem('unacleint', {
                    largeur: 33
                }),
                new FormulaireItem('cifcleint', {
                    largeur: 33
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('prudatfin_de'),
                new FormulaireItem('prudatfin_a')
            ], { largeurDefaut: 50 })
        ),
        new FormulaireGroupe('LBL_STATUT',
            new Formulaire([
                new FormulaireItem('statutcri', {
                    largeur: 50,
                    default: vm.monoOccurrence?.ecranDetails.valeurs.statutcri
                }),
                new FormulaireItem('typbdgcri', {
                    largeur: 50,
                    default: vm.monoOccurrence?.ecranDetails.valeurs.typbdgcri
                }),
                new FormulaireItem('blocleintcri', {
                    largeur: 33
                }),
                new FormulaireItem('intcodnumcri', {
                    largeur: 33
                }),
                new FormulaireItem('orgcleintcri', {
                    largeur: 33
                }),
                new FormulaireItem('sfucleintcri', {
                    largeur: 33
                }),
                new FormulaireItem('vecprucatprocri', {
                    largeur: 33
                }),
                new FormulaireItem('grpcleintcri', {
                    largeur: 33
                }),
                new FormulaireItem('soncleintcri', {
                    largeur: 33
                }),
                new FormulaireItem('flgorg')
            ], { largeurDefaut: 50 })
        )
    ]);

    vm.bdgMultiOccurrenceOptions = {
        bloc: 'bdg',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcodnumcri,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleintcri,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleintcri,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleintcri,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 1
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 150 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', largeur: 150 },
            { nom: 'mntbu', largeur: 150 },
            { nom: 'mntgb', largeur: 150 },
            { nom: 'mntrg', largeur: 150 },
            { nom: 'mntrb', largeur: 150 },
            { nom: 'mntrr', largeur: 150 },
            { nom: 'mntpe', largeur: 150 },
            { nom: 'mnten', largeur: 150 },
            { nom: 'mntre', largeur: 150 },
            { nom: 'mntgs', largeur: 150 },
            { nom: 'mntbr', largeur: 150 },
            { nom: 'mntpb', largeur: 150 },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'prudatdeb', largeur: 130 },
            { nom: 'prudatfin', largeur: 130 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 }
        ],
        colonnesCachees: ['pmtcleint',
            'intcodnumcri',
            'sfucleint',
            'prucleint',
            'prscleint',
            'cifcleint',
            'cptcleint',
            'unacleint',
            'el1cleint',
            'el2cleint',
            'el3cleint',
            'grpcleint',
            'soncleint',
            'orgcleint',
            'blocleint',
            'prudatfin',
            'prudatdeb',
            'unacleint']
    };

    vm.pruMultiOccurrenceOptions = {
        bloc: 'pru',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcodnumcri,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: rowData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleintcri,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleintcri,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleintcri,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 2
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', largeur: 150 },
            { nom: 'mntbu', largeur: 150 },
            { nom: 'mntgb', largeur: 150 },
            { nom: 'mntrg', largeur: 150 },
            { nom: 'mntrb', largeur: 150 },
            { nom: 'mntrr', largeur: 150 },
            { nom: 'mntpe', largeur: 150 },
            { nom: 'mnten', largeur: 150 },
            { nom: 'mntre', largeur: 150 },
            { nom: 'mntgs', largeur: 150 },
            { nom: 'mntbr', largeur: 150 },
            { nom: 'mntpb', largeur: 150 },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vecdsc', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prsdscl01', largeur: 130 }
        ],
        colonnesCachees: ['prscleint']
    };

    vm.refMultiOccurrenceOptions = {
        bloc: 'ref',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcodnumcri,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: rowData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleintcri,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleintcri,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleintcri,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 3,
            blocleint: rowData.blocleint
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', largeur: 150 },
            { nom: 'mntbu', largeur: 150 },
            { nom: 'mntgb', largeur: 150 },
            { nom: 'mntrg', largeur: 150 },
            { nom: 'mntrb', largeur: 150 },
            { nom: 'mntrr', largeur: 150 },
            { nom: 'mntpe', largeur: 150 },
            { nom: 'mnten', largeur: 150 },
            { nom: 'mntre', largeur: 150 },
            { nom: 'mntgs', largeur: 150 },
            { nom: 'mntbr', largeur: 150 },
            { nom: 'mntpb', largeur: 150 },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prscod', largeur: 130 },
            { nom: 'prsdscl01', largeur: 130 },
            { nom: 'blocod', largeur: 130 },
            { nom: 'blodscabr', largeur: 130 }
        ],
        colonnesCachees: ['prscleint', 'blocleint']
    };

    vm.prsMultiOccurrenceOptions = {
        bloc: 'prs',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        navigatePage: db2005Route.NAME,
        navigateParams: (rowData: any) => ({
            peccodcleint_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_de,
            peccodcleint_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.peccodcleint_a,
            intcodnumcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.intcodnumcri,
            sfucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
            prucleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
            prscleint: rowData.prscleint,
            cifcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
            cptcleint: rowData.cptcleint,
            unacleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
            el1cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
            el2cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
            el3cleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
            grpcleint: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
            soncleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.soncleintcri,
            orgcleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.orgcleintcri,
            blocleintcri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.blocleintcri,
            prudatfin_de: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_de,
            prudatfin_a: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.prudatfin_a,
            unacleintsup: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            naturecri: vm.bdgMultiOccurrence.etatSelected.criteresSuggeresData.naturecri,
            det: 4,
            blocleint: rowData.blocleint
        }),
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'solde_deb', largeur: 150 },
            { nom: 'mntbi', largeur: 150 },
            { nom: 'mntbu', largeur: 150 },
            { nom: 'mntgb', largeur: 150 },
            { nom: 'mntrg', largeur: 150 },
            { nom: 'mntrb', largeur: 150 },
            { nom: 'mntrr', largeur: 150 },
            { nom: 'mntpe', largeur: 150 },
            { nom: 'mnten', largeur: 150 },
            { nom: 'mntre', largeur: 150 },
            { nom: 'mntgs', largeur: 150 },
            { nom: 'mntbr', largeur: 150 },
            { nom: 'mntpb', largeur: 150 },
            { nom: 'solde_fin', largeur: 150 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prscod', largeur: 130 },
            { nom: 'prsdscl01', largeur: 130 },
            { nom: 'blocod', largeur: 130 },
            { nom: 'blodscabr', largeur: 130 },
            { nom: 'cptcod', largeur: 130 },
            { nom: 'cptdscabr', largeur: 130 }
        ],
        colonnesCachees: ['prscleint', 'blocleint']
    };

    vm.bloMultiOccurrenceOptions = {
        bloc: 'blo',
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            effaceCriteres: true,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 130 },
            { nom: 'prudscl01', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 130 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 'vaedsc', largeur: 130 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 130 },
            { nom: 'pruflgexcpb', largeur: 130 },
            { nom: 'prscod', largeur: 130 },
            { nom: 'prsdscl01', largeur: 130 },
            { nom: 'blocod', largeur: 130 },
            { nom: 'blodscabr', largeur: 130 },
            { nom: 'cptcod', largeur: 130 },
            { nom: 'cptdscabr', largeur: 130 },
            { nom: 'el1cod', largeur: 130 },
            { nom: 'el1dscabr', largeur: 130 },
            { nom: 'unacod', largeur: 130 },
            { nom: 'unadscabr', largeur: 130 },
            { nom: 'cifcod', largeur: 130 },
            { nom: 'cifdsc', largeur: 130 },
            { nom: 'imp', largeur: 130 },
            { nom: 'type_piece', largeur: 130 },
            { nom: 'docaffnumdoc01', largeur: 130 },
            { nom: 'refcod', largeur: 130 },
            { nom: 'refnom', largeur: 130 },
            { nom: 'docaffdsc01', largeur: 130 },
            { nom: 'pecann', largeur: 130 },
            { nom: 'pecper', largeur: 130 },
            { nom: 'drenumref', largeur: 130 },
            { nom: 'commentaire', largeur: 130 },
            { nom: 'categ', largeur: 130 },
            { nom: 'categ_desc', largeur: 130 },
            { nom: 'mntbi', largeur: 150 },
            { nom: 'mntbu', largeur: 150 },
            { nom: 'mntgb', largeur: 150 },
            { nom: 'mntrg', largeur: 150 },
            { nom: 'mntrb', largeur: 150 },
            { nom: 'mntrr', largeur: 150 },
            { nom: 'mntpe', largeur: 150 },
            { nom: 'mnten', largeur: 150 },
            { nom: 'mntre', largeur: 150 },
            { nom: 'mntgs', largeur: 150 },
            { nom: 'mntbr', largeur: 150 },
            { nom: 'mntpb', largeur: 150 }
        ]
    };
}
