import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageRC0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rc0009Controller(Menu: IMenuClass) {
    const vm: IPageRC0009 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'ltrcod', largeur: 200},
            {nom: 'lrtcodseq', largeur: 200},
            {nom: 'ltrdsc', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'tyddsc', largeur: 200},
            {nom: 'lrtstu', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnom', largeur: 200},
            {nom: 'lipcod', largeur: 200},
            {nom: 'lipnom', largeur: 200}
        ]
    };
}
