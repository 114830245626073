import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageRr2013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr2013Controller($stateParams: any,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr2013 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'cpecleint', valeur: $stateParams.cpecleint, visible: false }
        ],
        colonnesVisibles: [
            { nom: 'avsdsc', largeur: 220 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('avscleint', { largeur: 40 }),
            new FormulaireItem('cpecleint', { hidden: true, default: $stateParams.cpecleint })
        ])
    };
}
