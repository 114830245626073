import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageAc0035 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    menusBouton: Array<IMenuItem>;
}
/* @ngInject */
export default function Ac0035Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageAc0035 = this;
    const xx0000Route = {NAME: 'secure.accueil'};
    const mc1028Route = {NAME: 'secure.accueil'};
    const ac0005Route = {NAME: 'secure.accueil'};
    const ac0036Route = {NAME: 'secure.accueil'};

    vm.menusBouton = [
        new MenuItemForage(
                xx0000Route.NAME
        ),
        new MenuItemForage(
            mc1028Route.NAME
    )];
    
    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([]),
        navigatePage: ac0036Route.NAME,

        colonnesFixesGauche: [
            {nom: 'adpcod', largeur: 120}
        ],

        colonnesVisibles: [
            {nom: 'adpdsc', largeur: 250},
            {nom: 'vaeadptyp', largeur: 80},
            {nom: 'vaedsctyp', largeur: 120},
            {nom: 'adpseqid', largeur: 120},
            {nom: 'vaedscstu', largeur: 120},
            {nom: 'dacnumdoc', largeur: 180, menu: vm.menusBouton},
            {nom: 'sysboncmd', largeur: 120},
            {nom: 'etcnumdoc', largeur: 120},
            {nom: 'etcnumdocseq', largeur: 120, menu: [new MenuItemForage(ac0005Route.NAME)]},
            {nom: 'etcdsc', largeur: 120},
            {nom: 'refcod', largeur: 120},
            {nom: 'refnomabr', largeur: 120},
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 262}
        ],

        colonnesCachees: [
            'vaeadpstu'
        ],
        
        criteresSuggeres: new Formulaire([
            new FormulaireItem('adpcleint',
            {required: false}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('adpseqid'),
            new FormulaireItem('vaevalcle'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etccleint', {required: false}),
            new FormulaireItem('vaeadpstu', {required: false, default: 'CRS'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {required: false}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('magcleint', {required: false})
        ]),

        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_PUNCH_OUT',
                'BOUPUN',
                () => ({}),
                {
                    disabled: (rowData:any) => rowData.vaeadpstu !== 'CRS'
                }),
            new MenuItemAction(
                'LBL_BNT_GENERE',
                'BOUGEN',
                () => ({}),
                {
                    disabled: (rowData:any) => rowData.vaeadpstu !== 'CRS',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_GENERE',
                        formulaire: new Formulaire([
                            new FormulaireItem('usrdac', {required: true, largeur: 55}),
                            new FormulaireItem('daccom', {largeur: 100})
                        ])
                    }
                })
        ])
    };
}