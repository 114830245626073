import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMr0152 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
    formulaireBqe: IFormulaire;
    bpaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    btgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bdaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    btaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    btaMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mr0152Controller(mr0238Route: IRoute,
                                         mr0329Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageMr0152 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            actionsMore: new Menu([
                new MenuItemForage(
                    'G_LBL_MR0238',
                    mr0238Route.NAME,
                    (data:any) => ({
                        vaetypfnd: 'BQE',
                        cleintdet: data.bqecleint
                    }),
                    {
                        icon: 'assessment',
                        iconButton: false
                    }
                ),
                new MenuItemAction(
                    'BTN_ACTIVER',
                    'BOUACTIVER',
                    () => ({
                        bqecleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: 'done',
                        iconButton: false,
                        messageConfirmation: 'ACTIVER_CONFIRM',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        }
                    })

            ])
        };

        // Banques
        vm.formulaireBqe = new Formulaire('LBL_BLC_BQE', [
            new FormulaireItem('bqecod', {largeur:15}),
            new FormulaireItem('bqedscabr', {largeur:35, modelOptions: { updateOn: 'blur' }}),
            new FormulaireItem('bqedatdeb', {largeur:25}),
            new FormulaireItem('bqedatfin', {largeur:25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('bqedsc', {largeur:50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaebqenivges', {largeur:25}),
            new FormulaireItem('esccleint', {largeur:25}),
            new FormulaireItem('unccleint', {largeur:25}),
            new FormulaireGroupeAccordeon('LBL_CALCUL', new Formulaire([
                new FormulaireItem('bqeprical'),
                new FormulaireItem('bqeseqaff'),
                new FormulaireItem('mcbcleintsld'),
                new FormulaireItem('mcbcleinttrs'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bqeflgimp'),
                new FormulaireItem('bqeflgimppas'),
                new FormulaireItem('vaebqetrteva'),
                new FormulaireItem('vaebqetrtuti')
            ],{largeurDefaut: 25})),
            new FormulaireGroupeAccordeon('LBL_AFFIMP', new Formulaire([
                new FormulaireItem('bqeafflib'),         
                new FormulaireItem('mcbcleintaff'),
                new FormulaireItem('bqeaffdec'),
                new FormulaireItem('tdacleintaff'),
                new FormulaireItem('bqeaffdivfix', {largeur: 20}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bqeflgaffann', {largeur: 20}),
                new FormulaireItem('bqeflgaffmoi', {largeur: 20}),
                new FormulaireItem('bqeflgaffjrs', {largeur: 20}),
                new FormulaireItem('bqeflgaffjrsres', {largeur: 20}),
                new FormulaireItemDivider('LBL_IMPRESSION'),
                new FormulaireItem('bqeflgimp'),
                new FormulaireItem('bqeflgporemp'),
                new FormulaireItem('bqeflgporges'),
                new FormulaireItem('bqecleintref')
            ],{largeurDefaut: 25})),
            new FormulaireGroupeAccordeon('LBL_AUTRES', new Formulaire([
                new FormulaireItem('vacbqecat'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bqedoc', {largeur: 100, enableCount: true}),
            ],{largeurDefaut: 25}))
        ]);

        // Paramètres
        vm.bpaMultiOccurrenceOptions = {
            navigatePage: mr0329Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    id: rowData.bpacleint,
                    bqecleint: vm.monoOccurrence.id
                };
            },
            actionsNouveaux: new Menu([
                new MenuItemForage(
                    'LBL_FORAGE_BPA',
                    mr0329Route.NAME, () => {
                        return {
                            bqecleint: vm.monoOccurrence.id
                        }
                    }
                )
            ]),
            bloc: 'bpa',
            fonctions: {
                pagination: true
            },
            colonnesFixesGauche: [
                {nom: 'urgcod', largeur: 140},
                {nom: 'strcod', largeur: 160}
            ],
            colonnesVisibles: [
                {nom: 'mcbnummtheva', largeur: 100},
                {nom: 'mcbdsceva', largeur: 240},
                {nom: 'mcbnummthaug', largeur: 140},
                {nom: 'mcbdscaug', largeur: 240},
                {nom: 'mcbnummthcre', largeur: 100},
                {nom: 'mcbdsccre', largeur: 240},
                {nom: 'mcbnummthmin', largeur: 100},
                {nom: 'mcbdscmin', largeur: 240},
                {nom: 'mcbnummthmax', largeur: 100},
                {nom: 'mcbdscmax', largeur: 240},
                {nom: 'mcbnummthsan', largeur: 140},
                {nom: 'mcbdscsan', largeur: 240},
                {nom: 'mcbnummthuti', largeur: 100},
                {nom: 'mcbdscuti', largeur: 240},
                {nom: 'urgdscabr', largeur: 240},
                {nom: 'strdsc', largeur: 240}
            ],
            colonnesCachees: [
                'bpacleint'
            ]
        };

        // Tag
        vm.btgMultiOccurrenceOptions = {
            bloc: 'btg',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('bqecleint', {default: (data:any) => vm.monoOccurrence.id}),
                new FormulaireItem('tagcleint'),
                new FormulaireItem('vaebtgope'),
                new FormulaireItem('btgfct'),
                new FormulaireItem('btgdatdeb'),
                new FormulaireItem('btgdatfin'),
            ], {largeurDefaut: 25}),
            colonnesVisibles: [
                {nom: 'tagcod', largeur: 100},
                {nom: 'tagdscabr', largeur: 240},
                {nom: 'vaebtgope_dsc', largeur: 180},
                {nom: 'btgfct', largeur: 131},
                {nom: 'btgdatdeb', largeur: 126},
                {nom: 'btgdatfin', largeur: 126}
            ]
        };

        // Date solde
        vm.bdaMultiOccurrenceOptions = {
            bloc: 'bda',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('bqecleint', {default: (data:any) => vm.monoOccurrence.id}),
                new FormulaireItem('urgcleint', {largeur: 66.66}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('bdadatced'),
                new FormulaireItem('bdadatmoijrs'),
                new FormulaireItem('bdajrsmoi')
            ], {largeurDefaut: 33.33}),
            colonnesFixesGauche: [
                {nom: 'urgcod', largeur: 140}
            ],
            colonnesVisibles: [
                {nom: 'bdadatced', largeur: 126},
                {nom: 'bdadatmoijrs', largeur: 140},
                {nom: 'bdajrsmoi', largeur: 120},
                {nom: 'urgdscabr', largeur: 260}
            ]
        };

        // Traitement annuel
        vm.btaMultiOccurrenceOptions = {
            bloc: 'bta',
            actionsMore: new Menu([
                new MenuItemAction(
                    'BTN_CREATINI',
                    'BOUCREATINI',
                    () => ({
                        bqecleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: 'sync',
                        iconButton: false,
                        successAction: () => {
                            vm.btaMultiOccurrence.fetchDataList();
                        }
                    })
            ]),
            fonctions: {
                importation: true
            },
            colonnesFixesGauche: [
                {nom: 'urgcod', largeur: 140}
            ],
            colonnesVisibles: [
                {nom: 'unpcod', largeur: 140},
                {nom: 'ppacod', largeur: 140},
                {nom: 'btadatann', largeur: 126},
                {nom: 'urgdscabr', largeur: 260}
            ]
        };

    }
}
