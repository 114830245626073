import { IComponentController, IHttpParamSerializer, ISCEService } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItem, IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IMenuItemActionTelechargement, IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";
import { IApiConfig } from "../../../../../core/interfaces/api-config.interface";
import { IParametresSecuriteService } from "../../../../../core/services/parametres-securite.service";

interface IPageGs0037 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireBth: IFormulaire;
    surMultiOccurrence: IMultiOccurrence;
    surMultiOccurrenceOptions: IMultiOccurrenceOptions;
    attMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    suuMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menuItemRafraichir: IMenuItem;
    menusSuiviTraitement: { [col: string]: Array<IMenuItem> };
}

/* @ngInject */
export default function Gs0037Controller($stateParams: any,
    gs0114Route: IRoute, ApiConfig: IApiConfig,
    parametresSecurite: IParametresSecuriteService,
    $httpParamSerializer: IHttpParamSerializer, $sce: ISCEService,
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    Operateur: IOperateurService) {
    const vm: IPageGs0037 = this;

    vm.$onInit = $onInit;

    vm.menusRafraichir = {
        bthdatexe: [
            new MenuItem(
                '',
                () => { vm.monoOccurrence.fetchData(); },
                {
                    icon: 'sync',
                    iconButton: true,
                }
            )
        ]
    };

    vm.menusSuiviTraitement = {
        suvsuivi: [new MenuItem(
            'LBL_BTN_SUIVI_RAF',
            (ev: MouseEvent, data: any, occurrence: IMonoOccurrence) => { occurrence.fetchData(); },
            {
                icon: 'sync',
                secondaire: true,
                hidden: (data: any) => data.surflgter
            }
        )],
        suvflgarr: [new MenuItemAction(
            'LBL_BTN_ARRET_TRAIT',
            'BOUARRTRT',
            () => ({}),
            {
                icon: 'cancel',
                secondaire: true,
                hidden: (data: any) => !data.surflgcou
            }
        )]
    };

    function $onInit() {
        vm.monoOccurrenceOptions = {
            id: ecranDetails.valeurs.bthcleint
        };

        const menuItemFichier: IMenuItemActionTelechargement = new MenuItemActionTelechargement(
            'LBL_FICHIER',
            'BOUFIC',
            () => ({}),
            {
                iconButton: true,
                icon: 'attach_file',
                hidden: (data: any) => !data.surflgfic
            }
        );

        vm.formulaireBth = new Formulaire('LBL_BLC_BTH', [
            new FormulaireItem('bthdatexe')
        ]);

        vm.surMultiOccurrenceOptions = {
            bloc: 'sur',
            fonctions: {
                supprime: (data: { surflgatt: boolean, surflgced: boolean }) => data.surflgatt || data.surflgced,
                rechercheCollapsable: false
            },
            actionsMoreLigne: new Menu([
                new MenuItemEcran(
                    'LBL_AFF_ERREUR',
                    gs0114Route.NAME,
                    (data: any) => ({
                        id: data.surcleint,
                        menuId: $stateParams.menuId
                    }),
                    {
                        icon: 'info',
                        iconButton: false,
                        hidden: (data: any) => !data.surflgafferr
                    }
                ),
                new MenuItemAction("LBL_BTN_ANN", "BOUANN", (rowData: any) => ({ surcleint: rowData.surcleint }), {
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => vm.surMultiOccurrence.fetchDataList(),
                    hidden: (data: any) => data.$paramsEcran.p_flgsupusr === '0' || data.surstu != 2
                }),
                new MenuItemAction(
                    'LBL_BTN_REIMPRIM',
                    'BOUREIMP',
                    (data: any) => ({
                        impcleinttmp__impdsc: data.impdsc
                    }),
                    {
                        icon: 'print',
                        saveFirst: true,
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_BTN_REIMPRIM',
                            lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('impcleinttmp', {
                                    required: true,
                                    resourceParams: (data: any) => ({ trlcleint: data.trlcleint }),
                                    default: (data: any) => data.impcleint
                                }),
                                new FormulaireItem('nbrcop', {
                                    required: true,
                                    default: 1
                                })
                            ], {
                                largeurDefaut: 100,
                                securityCycle: false
                            })
                        },
                        hidden: (data: any) => !data.surflgreimp
                    }
                ),

                new MenuItemActionTelechargement(
                    'LBL_BTN_JOURNAL',
                    'BOUJOU',
                    () => ({}),
                    {
                        icon: 'attach_file',
                        saveFirst: true,
                        hidden: (data: any) => !data.surflgter
                    }
                ),
                new MenuItemAction(
                    'LBL_BTN_MODIF_CED',
                    'BOUMOD',
                    () => ({}),
                    {
                        icon: 'edit',
                        saveFirst: true,
                        successAction: () => { vm.monoOccurrence.fetchData(); },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_BTN_MODIF_CED',
                            lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('datjrs', {
                                    required: true,
                                    default: (data: any) => data.surdatdif
                                }),
                                new FormulaireItem('hrejrs', {
                                    required: true,
                                    default: (data: any) => data.surdatdif
                                })
                            ], {
                                largeurDefaut: 50,
                                securityCycle: false
                            })
                        },
                        hidden: (data: any) => !data.surflgatt
                    }
                )
            ]),
            filtres: [
                {
                    colonne: 'surstu',
                    operateur: Operateur.EGALE,
                    valeur: ''
                }
            ],
            classItems: {
                row: (data: any) => data.surflgerr ? 'ex-ligne-erreur'
                    : data.surflgatt ? 'ex-ligne-en-attente'
                        : data.surflgced ? 'ex-ligne-cedule'
                            : data.surflgcou ? 'ex-ligne-en-cours' : ''
            },
            colonnesVisibles: [
                { nom: 'surcle', largeur: 100 },
                { nom: 'surdsceta', largeur: 120 },
                { nom: 'surdsclan', largeur: 340 },
                { nom: 'surdatdif', largeur: 155 },
                { nom: 'surdatdebexe', largeur: 155 },
                { nom: 'surdatfinexe', largeur: 155 },
                { nom: 'surtemexe', largeur: 120 },
                { nom: "fiadsc", largeur: 120 },
                { nom: 'trlcod', largeur: 120 },
                { nom: 'trldsc', largeur: 340 },
                { nom: 'usrcod', largeur: 100 },
                { nom: 'usrnomcre', largeur: 150 },
                { nom: 'ciecod', largeur: 130 }
            ],
            colonnesFixesDroite: [
                {
                    nom: 'surflgsor', largeur: 100, menu: [
                        new MenuItemActionTelechargement(
                            'LBL_SORTIE',
                            'BOUSOR',
                            () => ({}),
                            {
                                iconButton: true,
                                icon: 'attach_file',
                                hidden: (data: any) => !data.surflgsor
                            }
                        ),
                        new MenuItem("LBL_SORTIE", (event: Event, data: any) => {
                            vm.previewUrl = $sce.trustAsResourceUrl(`${ApiConfig.ROOT}/bouton-action/blob/gs0037-sur/BOUSOR/${ecranDetails.valeurs.bthcleint},${data.surcleint}?${$httpParamSerializer({ ...parametresSecurite($stateParams, ecranDetails), screen: true })}&a#toolbar=0&navpanes=0`)
                        }, {
                            iconButton: true,
                            icon: 'search',
                            hidden: (data: any) => !data.surflgsor || data.surtypficimp !== "PDF",
                        })
                    ]
                },
                { nom: 'surflgfic', largeur: 100, menu: [menuItemFichier] }
            ],
            colonnesCachees: [
                'surflgerr', 'surflgcou', 'surflgatt', 'surstu',
                'surflgced', 'surcleint', 'surflgafferr',
                'cedflgaff', 'trlcleint', 'impcleint', 'impdsc',
                'surflgreimp', 'trlflgsui', 'surflgter',
                'surflgsor', 'surflgfic', 'surnbrcop',
                'ceddatdeb', 'ceddatfin', 'cedhrelan', 'ceddscgen',
                'surtypficimp', 'surfintrtdsc'
            ]
        };

        vm.attMultiOccurrenceOptions = {
            bloc: 'att',
            colonnesVisibles: [
                { nom: 'fiadsc', largeur: 150 },
                { nom: 'surdsceta', largeur: 120 },
                { nom: 'surpriexe', largeur: 100 },
                { nom: 'usrcod', largeur: 120 },
                { nom: 'surcle', largeur: 100 },
                { nom: 'trldsc', largeur: 400 },
                { nom: 'surdatdif', largeur: 155 },
                { nom: 'surdatdebexe', largeur: 155 }
            ],
            classItems: {
                row: (data: any) => data.surflgatt ? 'ex-ligne-en-attente' : data.surflgcou ? 'ex-ligne-en-cours' : ''
            },
            colonnesCachees: ['surflgatt', 'surflgcou'],
            formulaire: new Formulaire([
                new FormulaireItem('fiacleint')
            ])
        };

        vm.srpMultiOccurrenceOptions = {
            bloc: 'srp',
            colonnesVisibles: [
                { nom: 'ptllab', largeur: 300 },
                { nom: 'survalsai', largeur: 600 }
            ]
        };
    }
}
