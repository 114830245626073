import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMi0504 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mi0504Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMi0504 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'miicod', largeur: 120},
            {nom: 'miidsc', largeur: 274},
            {nom: 'vaesysppndet_dsc', largeur: 262},
            {nom: 'miiflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('miicod'   , { largeur: 20, required: true}),
            new FormulaireItem('miidsc'   , { largeur: 40, required: true}),
            new FormulaireItem('vaesysppndet',{ largeur: 20, required: true}),
            new FormulaireItem('miiflgact', { largeur: 20, default: 1})
        ])
    };
}


