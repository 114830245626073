import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageSP0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Sp0004Controller(Operateur: IOperateurService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    sp0003Route: IRoute,
    sp0005Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageSP0004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            edition: false,
            supprime: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('originecri', { default: 'TOUS' })
        ]),
        filtres: [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        navigatePage: (rowData: any) => (rowData.origine === 'MES') ? sp0005Route.NAME : sp0003Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(
                'BTN_NOU_EVA',
                sp0003Route.NAME,
                () => {
                    return{ demcleint: $stateParams.employe
                    }  
                },
                {
                    icon: '', 
                    iconButton: false,
                    fonction: 'NOUEVA'
            }),
            new MenuItemForage(
                'BTN_NOU_MES',
                sp0005Route.NAME,
                () => {
                    return{ demcleint: $stateParams.employe
                    }        
                },
                {
                    icon: '', 
                    iconButton: false,
                    fonction: 'NOUMES'
            })
        ]),        
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 120, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 220, hidden: $stateParams.employe },
            { nom: 'typcod', largeur: 160 },
            { nom: 'typdsc', largeur: 270 },
            { nom: 'date_trn', largeur: 120 },
            { nom: 'coocod', largeur: 100, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.originecri === 'MES' },
            { nom: 'coodsc', largeur: 180, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.originecri === 'MES'  },
            { nom: 'numdoc', largeur: 115 },
            { nom: 'raison_motif', largeur: 270 },
            { nom: 'ttccod', largeur: 100 },
            { nom: 'ttcdsc', largeur: 270 },
            { nom: 'ecldsceta', largeur: 195 },
            { nom: 'evadatdeb', largeur: 140, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.originecri === 'MES' },
            { nom: 'evadatenvdoc', largeur: 120, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.originecri === 'MES' },
            { nom: 'vaeevatyp_dsc', largeur: 205, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.originecri === 'MES' },
            { nom: 'tsenumdoc', largeur: 115, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.originecri === 'MES'  },
            { nom: 'tsedsc', largeur: 270, hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.originecri === 'MES'  },
            { nom: 'lemcod', largeur: 115 },
            { nom: 'lemdsc', largeur: 270 },
            { nom: 'aftcod', largeur: 115 },
            { nom: 'aftdsc', largeur: 270 },
            { nom: 'unocod', largeur: 140 },
            { nom: 'unodscabr', largeur: 270 },
            { nom: 'posnumdoc', largeur: 140 },
            { nom: 'postit', largeur: 270 },
            { nom: 'eplnummat_sup', largeur: 130 },
            { nom: 'eplnomprn_sup', largeur: 220 },
            { nom: 'intcod', largeur: 140 },
            { nom: 'intnom', largeur: 270 },
            { nom: 'evaintnumtel', largeur: 160 },
            { nom: 'evaintnumpos', largeur: 76 },
            { nom: 'commentaire', largeur: 270 },
            { nom: 'trnflgstaref', largeur: 100 },
            { nom: 'trnflgstafin', largeur: 90 },
            { nom: 'trnflgstafer', largeur: 90 },
            { nom: 'trnflgstaann', largeur: 90 }
        ],
        colonnesCachees: [
            'origine'
        ]
    };
}
