import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMr0135 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0135Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0135 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vacvalcle', largeur: 133},
            {nom: 'vacbmrcat_dsc', largeur: 180},
            {nom: 'bmrcod', largeur: 153},
            {nom: 'bmrmsg', largeur: 320}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('vacbmrcat', {largeur: 25}),
                new FormulaireItem('bmrcod', {largeur: 25}),
                new FormulaireItem('bmrmsg', {largeur: 100})
            ]
        )
    };
}
