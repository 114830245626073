import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageAS0066 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0066Controller() {
    const vm: IPageAS0066 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        colonnesVisibles: [
            {nom: 'poinumdoc', largeur: 130},
            {nom: 'poidsc', largeur: 315},
            {nom: 'poinomfic', largeur: 158},
            {nom: 'vaepoiforfic_dsc', largeur: 105},
            {nom: 'usrcod', largeur: 100},
            {nom: 'datcre', largeur: 140},
            {nom: 'nbrenr', largeur: 91},
            {nom: 'nbrerr', largeur: 91},
            {nom: 'nbrntra', largeur: 72},
        ]
    };
}
