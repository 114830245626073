import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageTH0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0011Controller(Menu: IMenuClass) {
    const vm: IPageTH0011 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rghcod', largeur: 90},
            {nom: 'rghdsc', largeur: 262},
            {nom: 'rolcod', largeur: 90},
            {nom: 'roldsc', largeur: 210},
            {nom: 'rghflgremaut', largeur: 91},
            {nom: 'rghflgpln', largeur: 91},
            {nom: 'rghflgftp', largeur: 72},
            {nom: 'rghflgtem', largeur: 110},
            {nom: 'rghflgftpgen', largeur: 72},
            {nom: 'rghflgftpsnsrat', largeur: 91},
            {nom: 'rghnbrjrspln', largeur: 90},
            {nom: 'rghflgddp', largeur: 76},
            {nom: 'vaerghaxeempdef_dsc', largeur: 100},
            {nom: 'rghflgconplnemp', largeur: 115},
            {nom: 'rghflgaffempdis', largeur: 100},
            {nom: 'rghflgaffempnondis', largeur: 100},
            {nom: 'vaerghdebpxt_dsc', largeur: 100},
            {nom: 'vaerghdurpxt_dsc', largeur: 100},
            {nom: 'rgharrqteftp', largeur: 91},
            {nom: 'rghtxtempftp', largeur: 540},
            {nom: 'vaerghtypenv_dsc', largeur: 184},
            {nom: 'rolcodenv', largeur: 90},
            {nom: 'roldscabrenv', largeur: 262}
        ]
    };
}
