import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMC0080 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0080Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         mc0081Route: IRoute) {
    const vm: IPageMC0080 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mc0081Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0081Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'cyocod', largeur: 90},
            {nom: 'cyodsc', largeur: 300},
            {nom: 'cyodscabr', largeur: 300},
            {nom: 'vaecyomne_dsc', largeur: 180},
            {nom: 'cetactabr', largeur: 175},
            {nom: 'cetnbreta', largeur: 85}
        ]
    };
}
