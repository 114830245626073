import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0075',
    ecrans: [
        '00-2'
    ],
    params: [
        'id',
        '?dercleint'

    ]
});
