import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCC0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0024Controller(Operateur: IOperateurService,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    parametresRecherche: IParametresRechercheService) {
    const vm: IPageCC0024 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        //filtres: parametresRecherche(ecranDetails),
        filtres: [
            { colonne: 'crscleint', valeur: $stateParams.crscleint ,
              visible: false},
              { colonne: 'trnflgstafer'},
              { colonne: 'trnflgstaann'}
            ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'crscod', largeur: 150 },
            { nom: 'crsdsc', largeur: 300 }
        ],
        colonnesVisibles: [
            { nom: 'exidatlimado', largeur: 120 },
            { nom: 'exidatado', largeur: 140 },
            { nom: 'exidatfer', largeur: 140 },
            { nom: 'exinumdoc', largeur: 115 },
            { nom: 'ecldsceta', largeur: 187 },
            { nom: 'ttccod', largeur: 140 },
            { nom: 'ttcdsc', largeur: 300 },
            { nom: 'exidip', largeur: 272 },
            { nom: 'exiexp', largeur: 272 },
            { nom: 'exiordprf', largeur: 272 },
            { nom: 'trnflgstaann', largeur: 75 },
            { nom: 'trnflgstafer', largeur: 72 }
        ]
    };
}
