import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";
import {IProfil} from "../../../../../core/resources/profil.resource";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import IStateService = angular.ui.IStateService;

interface IPageGS0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    MultiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Gs0005Controller(gs0119Route: IRoute,
                                         $state: IStateService,
                                         Menu: IMenuClass,
                                         profil: IProfil,
                                         Formulaire: IFormulaireClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0005 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_COPIER',
                'BOUCOP',
                () => ({}),
                {
                    icon: 'content_copy',
                    disabled: () => !profil.preferences.itvcleint,
                    successAction: (rowData:any) => {
                        $state.go(gs0119Route.NAME, {id: rowData.srccleintnew});
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_COPIER_SOURCE',
                        lblConfirm: 'G_LBL_BTN_VALIDER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_newsrc', {largeur: 50}),
                            new FormulaireItem('t_newdsc'),
                            new FormulaireItem('t_newapp')
                            ], {largeurDefaut: 100}
                        )
                    }
                }
            )
        ]),
        navigatePage: gs0119Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0119Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'srccod', largeur: 110},
            {nom: 'srcdsc', largeur: 250},
            {nom: 'srctyp_dsc', largeur: 130},
            {nom: 'srcflggra', largeur: 90},
            {nom: 'srcflgsec', largeur: 75},
            {nom: 'srcindgst', largeur: 75},
            {nom: 'srccodori', largeur: 140},
            {nom: 'srcdscori', largeur: 250}
        ],
        colonnesFixesDroite: [
            {nom: 'vaesysapp_dsc', largeur: 110},
            {nom: 'vaesysmodsof_dsc', largeur: 200}
        ]
    };
}
