import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageCO0026 {
    cbdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursObj: any;
}
/* @ngInject */
export default function Co0026Controller( ecranDetails: IEcranDetailsResourcesEntite,
                                          Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass,
) {

    const vm: IPageCO0026 = this;
    vm.valeursObj = ecranDetails.valeurs;

    //bloc détail
    vm.cbdMultiOccurrenceOptions = {
        bloc: 'cbd',
        colonnesVisibles: [
            {nom: 'temcod', largeur: 90,hidden: !vm.valeursObj.flgtem},
            {nom: 'temdscabr', largeur:360,hidden: !vm.valeursObj.flgtem},
            {nom: 'urgcod', largeur:90,hidden:!vm.valeursObj. flgurg},
            {nom: 'urgdscabr', largeur:360,hidden:! vm.valeursObj.flgurg},
            {nom: 'grecod', largeur:90,hidden: !vm.valeursObj.flggre},
            {nom: 'gredsc', largeur:360,hidden: !vm.valeursObj.flggre}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('temcleint'),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('grecleint'),
        ])
    };
}


