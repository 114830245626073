import {IComponentController} from 'angular';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IData} from '../../../../../core/services/data-linker.service';

interface IPageCS0001 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IPageCS0001Params {
    demcleint: number;
    acccleint: number;
    dalcleint: number;
    employe: number;
}

interface IDiagnosticMedical extends IData {
    trncleint_acc: number;
    acccleint: number;
    dalcleint: number;
    dcscleint: number;
    diaflgald: number;
}

/* @ngInject */
export default function Cs0001Controller($stateParams: IPageCS0001Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCS0001 = this;

    // @TODO ajouter la route de forage une fois que l'écran est fait
    const al0002Route = {NAME: 'secure.accueil'};

    vm.$onInit = $onInit;

    function $onInit() {
        vm.multiOccurrenceOptions = {
            filtresParametres: [
                {colonne: 'demcleint', affichage: true},
                {colonne: 'acccleint', affichage: true},
                {colonne: 'dalcleint', affichage: true}
            ],
            colonnesCachees: ['dalcleint',  'acccleint','trncleint_acc'],
            // Colonnes fixe à gauche
            colonnesFixesGauche: [
                {nom: 'diadat', largeur: 100}
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'vacdiarai', largeur: 105},
                {nom: 'vacdiarai_dsc', largeur: 184},            
                {nom: 'diaconmed', largeur: 294},
                {nom: 'diaflgpri', largeur: 100},
                {
                    nom: 'diaflgald',
                    largeur: 100,
                    menu: [
                        new MenuItemForage(
                            'BTN_FORAGE_ABSENCE',
                            al0002Route.NAME,
                            (rowData: IDiagnosticMedical) => ({id: rowData.dalcleint}),
                            {disabled: (rowData: IDiagnosticMedical) => (rowData.diaflgald !== 1),
                                fonction: 'BTN_ABSENCE'}
                        )
                    ]
                },
                {nom: 'diaflgtraleg', largeur: 100},
                {nom: 'nbrprj', largeur: 70}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('diadat'),
                new FormulaireItem('vacdiarai'),
                new FormulaireItem('diaconmed', {largeur: 100}),
                // Champs pour Dossier d'absence
                new FormulaireItem('diaflgpri'),
                new FormulaireItem('diaflgald'),                
                new FormulaireItem('diaflgtraleg'),                
                // Champs cachés
                new FormulaireItem('demcleint', {default: $stateParams.employe || $stateParams.demcleint, hidden: true}),
                new FormulaireItem('acccleint', {default: $stateParams.acccleint, hidden: true}),
                new FormulaireItem('dalcleint', {default: $stateParams.dalcleint, hidden: true})
            ], {
                piecesJointes: true,

            })
        };
    }
}
