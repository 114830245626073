import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr1011 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
/* @ngInject */
export default function Mr1011Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageMr1011 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            //Section principale
            new FormulaireItem('datdeb'),
            new FormulaireItem('duree'),
            new FormulaireItem('datfin'),
            new FormulaireItem('jrs'),
            new FormulaireItem('afecleint', { disabled: (data: any) => data.grpjrs == 0 }),
            new FormulaireItemSautDeLigne(),
            //Options/Jour
            new FormulaireItem('grpjrs', { default: 0 }),
            //Options/Horaire
            new FormulaireItemDivider('LBL_HORAIRE'),
            new FormulaireItem('flgdim', { largeur: 100 / 7, disabled: (data: any) => data.grpjrs == 0 }),
            new FormulaireItem('flglun', { largeur: 100 / 7, default: true, disabled: (data: any) => data.grpjrs == 0 }),
            new FormulaireItem('flgmar', { largeur: 100 / 7, default: true, disabled: (data: any) => data.grpjrs == 0 }),
            new FormulaireItem('flgmer', { largeur: 100 / 7, default: true, disabled: (data: any) => data.grpjrs == 0 }),
            new FormulaireItem('flgjeu', { largeur: 100 / 7, default: true, disabled: (data: any) => data.grpjrs == 0 }),
            new FormulaireItem('flgven', { largeur: 100 / 7, default: true, disabled: (data: any) => data.grpjrs == 0 }),
            new FormulaireItem('flgsam', { largeur: 100 / 7, disabled: (data: any) => data.grpjrs == 0 }),
            //Résultats
            new FormulaireItemDivider('LBL_RESULTATS'),
            new FormulaireItem('datcib', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('jrscal', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('jrsouv', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('jrsfer', { readonly: true }),
            new FormulaireItemSautDeLigne(),
            //Bouton
            new FormulaireItemMenuItem(new MenuItemAction("LBL_BOUCAL", "BOUCAL", () => ({}), {
                iconButton: false,
                class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                successAction: (result, occurrence: IMonoOccurrence, params, data) => {
                    data.datcib = result.datcib;
                    data.jrscal = result.jrscal;
                    data.jrsouv = result.jrsouv;
                    data.jrsfer = result.jrsfer;
                },
            })),
        ]),

        fonctions: {
            boutonEnregistrerHaut: false,
            skipChangementsNonSauvegardes: true
        },
    };
}


