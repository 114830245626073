import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';

interface IPageTF0049 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0049Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService) {
    const vm: IPageTF0049 = this;

    const filtres = [];

    if (ecranDetails.valeurs.tfhann) {
        filtres.push({
            colonne: 'tfhann',
            valeur: ecranDetails.valeurs.tfhann,
            readOnly: true
        });
    }

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres,
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe},
            {nom: 'tfhann', largeur: 75},
            {nom: 'fpncod', largeur: 100},
            {nom: 'fpndsc', largeur: 262},
            {nom: 'unpcalcar', largeur: 100},
            {nom: 'fpnregime', largeur: 90},
            {nom: 'fpngroupe', largeur: 90},
            {nom: 'tqedatdeb', largeur: 100},
            {nom: 'tqedatfin', largeur: 100},
            {nom: 'tqefacquo', largeur: 90},
            {nom: 'tqebasrem', largeur: 90},
            {nom: 'tqetypdec', largeur: 100},
            {nom: 'tqecoremp', largeur: 109 },
            {nom: 'tqenumemp', largeur: 90},
            {nom: 'tqeparprc', largeur: 128},
            {nom: 'tqecotsal', largeur: 126},
            {nom: 'tqesalcot', largeur: 126},
            {nom: 'tqesalannbas', largeur: 126},
            {nom: 'tqesalnoncot', largeur: 126},
            {nom: 'tqecotpat', largeur: 72},
            {nom: 'tqesalpon', largeur: 126},
            {nom: 'tqedatrto', largeur: 115},
            {nom: 'tqehreannpre', largeur: 126},
            {nom: 'vaetqestu_dsc', largeur: 75},
            {nom: 'tqeflgexc', largeur: 76},
            {nom: 'tqeflggen', largeur: 80},
            {nom: 'tqeflgxml', largeur: 75}
        ]
    };
}
