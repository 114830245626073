import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageAS2007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As2007Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService) {
    const vm: IPageAS2007 = this;
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'segcod01', largeur: 105 },
            { nom: 'segdsc01', largeur: 280 },
            { nom: 'segcod02', largeur: 105 },
            { nom: 'segdsc02', largeur: 280 },
            { nom: 'segcod03', largeur: 105 },
            { nom: 'segdsc03', largeur: 280 },
            { nom: 'segcod04', largeur: 200 },
            { nom: 'segdsc04', largeur: 280 },
            { nom: 'segcod05', largeur: 200 },
            { nom: 'segdsc05', largeur: 280 }
        ],
        colonnesFixesDroite: [
            { nom: 'actcod', largeur: 141, hidden: () => $stateParams.vaesegdec === 'TAG' },
            { nom: 'actdsc', largeur: 280, hidden: () => $stateParams.vaesegdec === 'TAG' },
            { nom: 'tagcod', largeur: 86, hidden: () => $stateParams.vaesegdec === 'ACT' },
            { nom: 'tagdsc', largeur: 280, hidden: () => $stateParams.vaesegdec === 'ACT' }
        ],
        colonnesCachees: ['sutcleint', 'segcleint', 'vaesegdec'],
        formulaire: new Formulaire([
            new FormulaireItem('segcleint01', {
                required: true,
                resourceParams: (data: any) => ({ vaesegdec: $stateParams.vaesegdec, sutcleint: $stateParams.sutcleint, stamnetab: data.stamnetab }),
            }),
            new FormulaireItem('tagcleint', {
                required: true,
                hidden: () => $stateParams.vaesegdec === 'ACT'
            }),
            new FormulaireItem('vaesegdec', {
                default: () => $stateParams.vaesegdec === 'TAG' ? 'TAG' : 'ACT',
                hidden: true
            }),
            new FormulaireItem('actcleint', {
                hidden: () => $stateParams.vaesegdec === 'TAG'
            })

        ], { largeurDefaut: 50 })
    };
}

