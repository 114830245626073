import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageMC0172 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0172Controller(mc0010Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMC0172 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0010Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.ciecle
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0010Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'hlddsc', largeur: 160},
            {nom: 'ciecod', largeur: 160},
            {nom: 'cienom', largeur: 240},
            {nom: 'cienomabr', largeur: 200},
            {nom: 'cietel', largeur: 120},
            {nom: 'ciefax', largeur: 120},
            {nom: 'ciedatdeb', largeur: 126},
            {nom: 'ciedatfin', largeur: 126},
            {nom: 'cifcod', largeur: 160},
            {nom: 'cifdscabr', largeur: 280},
            {nom: 'cifcodimm', largeur: 180},
            {nom: 'cifdscabrimm', largeur: 280},
            {nom: 'etbcod', largeur: 120},
            {nom: 'etbnom', largeur: 280}
        ],
        colonnesCachees: [
            'ciecle'
        ]
    };
}
