import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageGs0104 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0104Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageGs0104 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        colonnesFixesGauche: [
            {nom: 'usenom', largeur: 160}
        ],
        colonnesVisibles: [
            {nom: 'useadrele', largeur: 170},
            {nom: 'indinsalerte', largeur: 100},
            {nom: 'useflgrapalerte', largeur: 100},
            {nom: 'vaefreqalerte', largeur: 120},
            {nom: 'crxcod', largeur: 120},
            {nom: 'crxdsc', largeur: 160},
            {nom: 'gcrcod', largeur: 140},
            {nom: 'gcrdsc', largeur: 160},
            {nom: 'useadrl01', largeur: 120},
            {nom: 'useadrl02', largeur: 120},
            {nom: 'useadrl03', largeur: 120},
            {nom: 'usevil', largeur: 120},
            {nom: 'usepro', largeur: 100},
            {nom: 'usepay', largeur: 140},
            {nom: 'usecodpos', largeur: 120},
            {nom: 'usenumtel', largeur: 120},
            {nom: 'usenumtelpos', largeur: 120},
            {nom: 'usenumfax', largeur: 120}
        ]
    };
}
