import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IComponentController} from "angular";
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";


interface IPageTs0007 extends IComponentController {
    formulaireGrr: IFormulaire,
    formulaireAdmin: IFormulaire,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireSolde: IFormulaire;
    formulaireInscription: IFormulaire;
    tgsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    goeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gueMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ts0007Controller(MenuItemForage: IMenuItemForageClass,
                                          Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass,
                                          FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                          FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                          ts0008Route:IRoute) {
    const vm: IPageTs0007 = this;

    vm.$onInit = $onInit;
    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        }
    };

    function $onInit() {

        vm.formulaireGrr = new Formulaire('LBL_TYP_COM', [
                new FormulaireItem('grrcod'),
                new FormulaireItem('vaegrrtyp'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('grrdscabr', {largeur: 25}),
                new FormulaireItem('grrdsc', {largeur: 50,enableCount: true }),
                new FormulaireGroupeAccordeon('LBL_ORDRE',
                    new Formulaire([
                        new FormulaireItem('vaegrrtyptri'),
                        new FormulaireItem('grrflgdatrec'),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('bqecleintanc'),
                        new FormulaireItem('bqecleinttps'),
                        new FormulaireItem('vaegrrorisld')
                    ], { largeurDefaut: 25 }), { largeur: 100 }
                ),
            ]
        );
        vm.formulaireSolde =
            new Formulaire('LBL_BLC_SOLD',[
                new FormulaireItem('bqecleintsld'),
                new FormulaireItem('vaegrrorisldsel'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('grrbqesldmin'),
                new FormulaireItem('grrbqesldmax')
            ]);
        vm.formulaireInscription =
            new Formulaire('LBL_BLC_INSCRIP',[
                new FormulaireItem('grrflginspos',{
                        menu: [
                            new MenuItemForage(
                                'BTN_FORAGE_INSCRIPTIONS',
                                ts0008Route.NAME,
                                (data:any) => ({ecran:'00-2',grrcod:data.grrcod}),
                                { fonction:'BOUINS',disabled: (data:any) => !data.grrflginspos }
                            )
                        ],largeur:66.66
                    }
                ),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('grrflginsdec',),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('grrflgdespos',{
                    menu: [
                        new MenuItemForage(
                            'BTN_FORAGE_DESINSCRIPTIONS',
                            ts0008Route.NAME,
                            (data:any) => ({ ecran: '01-2',grrcod:data.grrcod}),
                        { fonction:'BOUDES',disabled: (data:any) => !data.grrflgdespos}
                        ),
                    ],largeur:66.66
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('grrflgdesdec',)
            ]);
        vm.tgsMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'tgs',
            colonnesVisibles: [
                {nom: 'tgsseq'   , largeur: 100},
                {nom: 'urgcod'   , largeur: 100},
                {nom: 'urgdscabr' , largeur: 100},
                {nom: 'strcod'   , largeur: 100},
                {nom: 'strdsc'   , largeur: 100}

            ],
            formulaire: new Formulaire([
                new FormulaireItem('tgsseq',{ largeur: 33.33,required:true}),
                new FormulaireItem('urgcleint',{ largeur: 33.33 }),
                new FormulaireItem('strcleint',{ largeur: 33.33 })
            ])
        };
        vm.formulaireAdmin =
            new Formulaire(' ',[
                new FormulaireItem('grrflgexcaftsus',{ largeur: 100})
            ]);
        vm.goeMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'goe',
            colonnesVisibles: [
                {nom: 'unocod'   , largeur: 100},
                {nom: 'unodscabr'   , largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('unocleint',{ largeur: 100}),

            ])
        };
        vm.gueMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            bloc: 'gue',
            colonnesVisibles: [
                {nom: 'urgcod'   , largeur: 100},
                {nom: 'urgdscabr'   , largeur: 100},
                {nom: 'strcod'   , largeur: 100},
                {nom: 'strdsc'   , largeur: 100},
                {nom: 'vaeguetmpcomdsc' , largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint',{ largeur: 33.33}),
                new FormulaireItem('strcleint',{ largeur: 33.33}),
                new FormulaireItem('vaeguetmpcom',{ largeur: 33.33})
            ])
        };
    }
}
