import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IComponentController} from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForage, IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireTemplateSlotClass} from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0558 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireUpp: IFormulaire;
    dmfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dmrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dpaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fepMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pamMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menuItemForageImp: IMenuItemForage;

}

/* @ngInject */
export default function Rr0558Controller(Menu: IMenuClass,
                                         rr0501Route: IRoute,
                                         rr0503Route: IRoute,
                                         Pagination: IPaginationClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                         rr0536Route: IRoute,
                                         rr0528Route: IRoute) {
    const vm: IPageRr0558 = this;

    vm.menus = {
        t_flgexccou: [
            new MenuItemAction(
                'BOUPRE',
                () => ({}),
                {
                    icon: 'edit',
                    iconButton: true,
                    champsRechargement: ['t_flgexccou', 't_flgexcanncou'],
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_GESTION_EXC',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_flgexccou', {
                                largeur: 100,
                                default: (data:any) => data.t_flgexccou
                            }),
                            new FormulaireItem('t_flgexcanncou', {
                                largeur: 100,
                                default: (data:any) => data.t_flgexcanncou
                            })
                        ])
                    }
                }
            )
        ]
    };

    vm.menuItemPaiNeg = new MenuItemForage('LBL_POSTE',
        rr0528Route.NAME, (data:any) => {
            return {
                demcleint: data.demcleint,
                ppacleint: data.ppacleint
            }
        },
        {
            iconButton: true,
            secondaire: true
        }
    );

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                suiviModification: false
            },
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_ACTIVE',
                    'BOUFLG',
                    (data: any) => ({}),
                    {
                        saveFirst: true,
                        successAction: (data: any) => {
                            vm.monoOccurrence.fetchData();
                            vm.valeursEcran.flgdbg = data.flgdbg
                        },
                        hidden: () => (
                            vm.valeursEcran.flgaffdbg === 0 ||
                            vm.valeursEcran.flgdbg === 1
                        ),
                        icon: 'bug_report'
                    }
                ),
                new MenuItemAction(
                    'LBL_BTN_DEACTIVE',
                    'BOUFLG',
                    (data: any) => ({}),
                    {
                        successAction: (data: any) => {
                            vm.monoOccurrence.fetchData();
                            vm.valeursEcran.flgdbg = data.flgdbg
                        },
                        hidden: () => (
                            vm.valeursEcran.flgaffdbg === 0 ||
                            vm.valeursEcran.flgdbg === 0
                        ),
                        icon: 'bug_report'
                    }
                )
            ])
        };

        vm.menuItemForageImp = new MenuItemForage(
            'LBL_BTN_FOR_IMP',
            rr0536Route.NAME,
            (data:any) => ({
                demcleint: data.demcleint,
                unpcleint: data.unpcleint,
                statut: '0'
            }),
            { icon: 'exit_to_app' }
        );

        vm.boutonCalculer = new MenuItemAction(
            'LBL_BTN_CALCULER',
            'BOUCAL',
            (data: any) => ({}),
            {
                icon: 'sync',
                saveFirst: true,
                iconButton: false,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                }
            }
        );

        vm.boutonReprise = new MenuItemAction(
            'LBL_BTN_REPRISE',
            'BOUREP',
            (data: any) => ({}),
            {
                icon: 'sync_problem',
                saveFirst: true,
                iconButton: false,
                successAction: () => {
                    vm.monoOccurrence.fetchData();
                },
                hidden: () => (
                    vm.monoOccurrence.data.nbr_fep === 0 ||
                    vm.monoOccurrence.data.nbr_pam === 0
                )
            }
        );

        vm.formulaireUpp = new Formulaire('LBL_BLC_UPP', [
            new FormulaireTemplateSlot('infos')
        ], {largeurDefaut: 20});

        vm.dmfMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'typerrdsc', largeur: 200},
                {nom: 'msgerr', largeur: 500}
            ]
        };

        vm.dpaMultiOccurrenceOptions = {
            bloc:'dpa',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                {nom: 'vaereg_dsc', largeur: 100},
                {nom: 'dpadatvig', largeur: 100}
            ],
            colonnesCachees: ['demcleint', 'unpcleint'],
        };

        vm.dmrMultiOccurrenceOptions = {
            bloc:'dmr',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                {nom: 'dmrmsg', largeur: 100}
            ],
        };

        vm.fepMultiOccurrenceOptions = {
            navigatePage: rr0501Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    id: rowData.fepcleint,
                    typsai: rowData.typsai
                };
            },
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_DETRUIRE',
                    'BOUDEL',
                    (data: any) => ({}),
                    {
                        saveFirst: true,
                        icon: 'bug_report'
                    }
                )
            ]),
            bloc:'fep',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                {nom: 'fepcod', largeur: 100},
                {nom: 'fepflgcal', largeur: 100}
            ],
            colonnesCachees: ['demcleint', 'unpcleint', 'ppacleint'],
        };

        vm.pamMultiOccurrenceOptions = {
            navigatePage: rr0503Route.NAME,
            navigateParams: (rowData: any) => {
                return {
                    id: rowData.pamcleint
                };
            },
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_ANNULER',
                    'BOUANN',
                    (data: any) => ({}),
                    {
                        saveFirst: true,
                        icon: 'bug_report'
                    }
                )
            ]),
            bloc:'pam',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                {nom: 'pamnumdoc', largeur: 100},
                {nom: 'pammntnet', largeur: 100},
                {nom: 'pamflgoff', largeur: 100}
            ],
            colonnesCachees: ['demcleint', 'unpcleint', 'ppacleint'],
        };

    }
}
