import {IComponentController} from 'angular';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';


interface IPageCh0012 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIpm: IFormulaire;

}

/* @ngInject */
export default function Ch0012Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageCh0012 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            id: ecranDetails.valeurs.ipmcleint
        };

        vm.formulaireIpm = new Formulaire('LBL_BLC_IPM', [
            new FormulaireGroupe( new Formulaire([
                new FormulaireItemDivider('LBL_JOUR'),
                new FormulaireItem('ipmhredebjr'),
                new FormulaireItem('ipmhrefinjr')
            ]), {largeur: 33.33}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItemDivider('LBL_SOIR'),
                new FormulaireItem('ipmhredebsr'),
                new FormulaireItem('ipmhrefinsr')
            ]), {largeur: 33.33}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItemDivider('LBL_NUIT'),
                new FormulaireItem('ipmhredebnu'),
                new FormulaireItem('ipmhrefinnu')
            ]), {largeur: 33.33}),
            new FormulaireItem('vaeipmqrt'),
            new FormulaireItem('vaeipmsimcum'),
            new FormulaireItem('bqecleintanc'),
            new FormulaireItem('bqecleintcum'),
            new FormulaireItem('ipmnbrjrsact', {largeur:50}),
            new FormulaireItem('ipmnbrmoiava', {largeur:50}),
        ], { largeurDefaut: 25 });
    }
}
