import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageTF0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0018Controller(tf0080Route: IRoute, Menu: IMenuClass, MenuItemForage: IMenuItemForageClass) {
    const vm: IPageTF0018 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: tf0080Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(tf0080Route.NAME)]),
        colonnesVisibles: [
            { nom: 'tfhann', largeur: 90 },
            { nom: 'fpncod', largeur: 130 },
            { nom: 'fpndsc', largeur: 250 },
            { nom: 'femcod', largeur: 100 },
            { nom: 'femdsc', largeur: 250 }
        ]
    };
}
