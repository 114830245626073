// Module
var code = `<ex-mono-occurrence-ecran formulaire="vm.formulaireImm" mono-occurrence-options="vm.monoOccurrenceOptions" mono-occurrence-out="vm.monoOccurrence" largeur-formulaire="65"> <ex-mono-occurrence-ecran-gauche> <ex-dashboard-card> <ex-dashboard-card-content-left> <ex-card-multi-advanced ex-bloc="imr" lbl-titre="LBL_BLC_IMR" opened="false" multi-occurrence-options="::vm.imrMultiOccurrenceOptions"> </ex-card-multi-advanced> </ex-dashboard-card-content-left> <ex-dashboard-card-content-right> <ex-card-multi-template ex-bloc="iip" lbl-titre="LBL_BLC_IIP" opened="false" multi-occurrence-options="::vm.iipMultiOccurrenceOptions"> <ex-card-multi-template-row> <ex-case cols="::['prucod', 'prudsc']"></ex-case> </ex-card-multi-template-row> </ex-card-multi-template> </ex-dashboard-card-content-right> </ex-dashboard-card> <ex-card-multi-advanced ex-bloc="ire" lbl-titre="LBL_BLC_IRE" opened="false" multi-occurrence-options="::vm.ireMultiOccurrenceOptions"> </ex-card-multi-advanced> </ex-mono-occurrence-ecran-gauche> <ex-mono-occurrence-ecran-droite> <ex-card no-header="true"> <ex-card-content> <ex-data-bar-list data="::vm.valeursEcran" menus="::vm.menus" cols="[{nom: 'mntini', classeCouleur: 'ex-indigo-500-bg'},
                                         {nom: 'mntina', classeCouleur: 'ex-blue-500-bg'},
                                         {nom: 'mntind', classeCouleur: 'ex-amber-900-bg', finGroupe: true},
                                         {nom: 'tot_cout', classeCouleur: 'ex-teal-500-bg'}]" max-value="vm.valeursEcran.max_value"> </ex-data-bar-list> </ex-card-content> </ex-card> <ex-card no-header="true"> <ex-card-content> <ex-data-bar-list data="::vm.valeursEcran" menus="::vm.menus" cols="[{nom: 'mntamo', classeCouleur: 'ex-avertissement-bg'},
                                         {nom: 'mntama', classeCouleur: 'ex-green-600-bg', finGroupe: true},
                                         {nom: 'tot_amo', classeCouleur: 'ex-pink-400-bg'}]" max-value="vm.valeursEcran.max_value"> </ex-data-bar-list> <md-divider></md-divider> <ex-data-list cols="[{nom: 'aut__der_per', alignmentValeur: 'right'},
                                     {nom: 'aut__nbr_per', alignmentValeur: 'right'},
                                     {nom: 'aut__val_nonamo', alignmentValeur: 'right'}]" largeur="50" largeur-texte="67"></ex-data-list> </ex-card-content> </ex-card> <ex-card no-header="true"> <ex-card-content> <ex-data-list data="vm.valeursEcran" cols="[{nom: 'val_net', alignmentValeur: 'right'}]" largeur="100" largeur-texte="67"></ex-data-list> </ex-card-content> </ex-card> <ex-card lbl-titre="LBL_BLC_DISPO" opened="false"> <ex-card-content> <ex-data-list data-types="::vm.monoOccurence.dataTypes" menus="::vm.menus" alignment-valeur="right" cols="::['aut__disdat', 'aut__vecdistypdis', 'aut__mntrev', 'aut__mntgap']"> </ex-data-list> </ex-card-content> </ex-card> <ex-card-menu-list lbl-titre="LBL_BTN" content-layout="row" menu-items="::vm.btnMenus" no-header="true"> </ex-card-menu-list> <ex-card-multi-template ex-bloc="imq" lbl-titre="LBL_BLC_IMQ" opened="false" ng-if="::vm.monoOccurrence.data.vaecatmetamo === 'U'" multi-occurrence-options="::vm.imqMultiOccurrenceOptions"> <ex-card-multi-template-row> <ex-case col="peccod"></ex-case> <ex-case col="imqqteamo" alignment="right"></ex-case> </ex-card-multi-template-row> </ex-card-multi-template> </ex-mono-occurrence-ecran-droite> <ex-mono-occurrence-ecran-bas> <ex-card-multi-advanced ex-bloc="hts" lbl-titre="LBL_BLC_HTS" multi-occurrence-options="::vm.htsMultiOccurrenceOptions" opened="false"> </ex-card-multi-advanced> <ex-card-multi-advanced ex-bloc="eci" lbl-titre="LBL_BLC_ECI" multi-occurrence-options="::vm.eciMultiOccurrenceOptions"> </ex-card-multi-advanced> </ex-mono-occurrence-ecran-bas> </ex-mono-occurrence-ecran> `;
// Exports
module.exports = code;