import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import IStateService = angular.ui.IStateService;

interface IPageAs0029 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePortailEmploye: IFormulaire;
    formulaireSaisie: IFormulaire;
    formulaireRegroupement: IFormulaire;
    multiOccurrenceOptionsAff: IMultiOccurrenceOptions;
    multiOccurrenceOptionsRrh: IMultiOccurrenceOptions;
    multiOccurrenceOptionsTpo: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0029Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    $state: IStateService,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    as0028Route: IRoute) {
    const vm: IPageAs0029 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true,
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_OCC', [
            new FormulaireItem('occcod', { largeur: 30 }),

            new FormulaireItem('occdsc', { largeur: 30 }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_UTILISATION'),
            new FormulaireItem('occflgact', { largeur: 30 }),
            new FormulaireItem('occflgabsact', { largeur: 30 }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('occflgdef', { largeur: 30 }),
            new FormulaireItem('occflgabsdef', { largeur: 30 }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItemDivider('LBL_VALIDATION'),
            new FormulaireItem('occflgegatem', { largeur: 30 }),
            new FormulaireItem('occflgegauno', { largeur: 30 }),
            new FormulaireItem('occflgrestaf', { largeur: 30 })

        ], { largeurDefaut: 50 })
    };

    vm.formulairePortailEmploye = new Formulaire('LBL_POR_EMP', [
        new FormulaireItem('occseqaffemp'),
        new FormulaireItem('occdscemp'),
        new FormulaireItem('occexpemp', { largeur: 100 })
    ], { largeurDefaut: 30 });


    vm.formulaireSaisie = new Formulaire('LBL_BLC_SAI', [
        new FormulaireItem('vaeocctem', { largeur: 30 }),
        new FormulaireItem('vaeoccaft', { largeur: 30 }),
        new FormulaireItem('vaeoccder', { largeur: 30 }),

        new FormulaireItem('vaeocclem', { largeur: 30 }),
        new FormulaireItem('vaeoccpos', { largeur: 30 }),
        new FormulaireItem('vaeoccuno', { largeur: 30 }),
        new FormulaireTemplateSlot('blocRestriction')
    ]);

    vm.multiOccurrenceOptionsTpo = {
        bloc: 'tpo',
        colonnesVisibles: [
            { nom: 'tpocod', largeur: 20 },
            { nom: 'tpodsc', largeur: 20 },
        ],
        colonnesCachees: [
            'ocacleint'
        ],
        formulaire: new Formulaire('LBL_BLC_TPO', [
            new FormulaireItem('tpocleint')
        ], { largeurDefaut: 100 })
    };


    vm.multiOccurrenceOptionsAff = {
        bloc: 'aff',
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_SUPPRIMER',
                'BOUSUP',
                () => ({}),
                {
                    icon: '',
                    iconButton: true,
                    successAction: () => { vm.monoOccurrence.fetchData() },
                    messageConfirmation: 'BOUDET_CONFIRM'
                }
            ),
        ]),

        colonnesVisibles: [
            { nom: 'tafcod', largeur: 30 },
            { nom: 'tafdsc', largeur: 40 },
            { nom: 'ocaflgrestpo', largeur: 20 },
        ],
        colonnesCachees: [
            'occcleint'
        ],
        formulaire: new Formulaire('LBL_BLC_AFF', [
            new FormulaireItem('tafcleint'),
            new FormulaireItem('ocaflgrestpo')
        ], { largeurDefaut: 50 }),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceOptionsTpo
    };

    vm.multiOccurrenceOptionsRrh = {
        bloc: 'rrh',
        colonnesVisibles: [
            { nom: 'asgcod', largeur: 120 },
            { nom: 'asgdsc', largeur: 380 },

        ],

        formulaire: new Formulaire([
            new FormulaireItem('asgcleint', { largeur: 45 }),
        ]),
    };

}
