import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageMc0147 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireHcr: IFormulaire;
    hctMultiOccurrenceOptions: IMultiOccurrenceOptions;
    hloMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc0147Params {
    hldcleintref: number;
}

/* @ngInject */
export default function Mc0147Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IMc0147Params) {
    const vm: IPageMc0147 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireHcr = new Formulaire('LBL_BLC_HCR', [
            new FormulaireItem('elicleint', {
                largeur: 50,
                resourceParams: () => ({hldcleintref: $stateParams.hldcleintref})
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('hcrindobl'),
            new FormulaireItem('hcrindmaj'),
            new FormulaireItem('hcrinduni'),
            new FormulaireItem('hcrindlov')
        ], {largeurDefaut: 25});

        vm.hctMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tydcod', largeur: 130},
                {nom: 'tyddsc', largeur: 190},
                {nom: 'hctindobl', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tydcleint'),
                new FormulaireItem('hctindobl')
            ])
        };

        vm.hloMultiOccurrenceOptions = {
            bloc:'hlo',
            colonnesVisibles: [
                {nom: 'hlocod', largeur: 130},
                {nom: 'hlodsc', largeur: 150},
                {nom: 'hlodatfin', largeur: 120},
                {nom: 'hloflgact', largeur: 75}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('hlocod'),
                new FormulaireItem('hlodsc'),
                new FormulaireItem('hlodatfin'),
                new FormulaireItem('hloflgact')
            ], {largeurDefaut: 50})
        };
    }
}
