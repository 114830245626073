import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0551',
    ecrans: ['00-2'],
    params: ['carcleint',
             'demcleint',
             'socmnt']
    });

