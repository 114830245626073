import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageDO0042 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0042Controller() {
    const vm: IPageDO0042 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        colonnesVisibles: [
            {nom: 'dmpnumdoc', largeur: 250 },
            {nom: 'dmpdat', largeur: 126},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'dmpdatdeb', largeur: 126},
            {nom: 'posnumdoc', largeur: 110},
            {nom: 'postitabr', largeur: 262},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 250},
            {nom: 'vaedmpdec', largeur: 184},
            {nom: 'dmpdatdebdot', largeur: 126},
            {nom: 'dmpflgdotaut', largeur: 110},
            {nom: 'dmpcom', largeur: 250},
            {nom: 'pvanumdoc', largeur: 136},
            {nom: 'pvacom', largeur: 250},
            {nom: 'trnflgstafer', largeur: 80},
            {nom: 'trnflgstaann', largeur: 80}
        ]
    };
}