import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0222 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0222Controller(Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0222 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'lvbcleint', valeur: $stateParams.reference, visible: false}
        ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaelvbtyp_dsc', largeur: 200},
            {nom: 'bqecod'       , largeur: 160},
            {nom: 'bqedscabr'    , largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaelvbtyp'),
            new FormulaireItem('bqecleint')
        ])
    };
}
