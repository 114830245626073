import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCo0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Co0020Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCo0020 = this;

    const hiddenAxe2 = () => vm.multiOccurrence.etatSelected && vm.multiOccurrence.etatSelected.criteresSuggeresData && vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 2

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            importation: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('dmncleint', { required: false }),
            new FormulaireItem('comcleint', { required: false }),
            new FormulaireItem('tyccleint', { required: false }),
            new FormulaireItem('t_nbr_jrs', { required: false }),
            new FormulaireItem('t_a_par_de', { required: false }),
            new FormulaireItem('t_flgact', { required: false }),
            new FormulaireItem('t_flgsanald', { required: false }),
            new FormulaireItem('t_datact', { required: false }),
            new FormulaireItem('axe', { default: '1', required: true })
        ]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 250 },
            { nom: 'comcod', largeur: 150 },
            { nom: 'comdsc', largeur: 190 },
            { nom: 'tycflginfscl', largeur: 74, hidden: hiddenAxe2 },
            { nom: 'tycflginfexp', largeur: 74, hidden: hiddenAxe2 },
            { nom: 'ideval', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'idevalrev', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'nivcod', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'nivdsc', largeur: 160, hidden: hiddenAxe2 },
            { nom: 'nivcodrev', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'nivdscrev', largeur: 190, hidden: hiddenAxe2 },
            { nom: 'idedat', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'idedatrev', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'idedatval', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'ideannobt', largeur: 100 },
            { nom: 'ideannderutl', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'idedatexp', largeur: 100 },
            { nom: 'idecom', largeur: 255, hidden: hiddenAxe2 },
            { nom: 'idecomrev', largeur: 255, hidden: hiddenAxe2 },
            { nom: 'ittcod', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'ittdsc', largeur: 180, hidden: hiddenAxe2 },
            { nom: 'scldscitt', largeur: 155, hidden: hiddenAxe2 },
            { nom: 'dipcod', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'dipdsc', largeur: 180, hidden: hiddenAxe2 },
            { nom: 'scldscdip', largeur: 155, hidden: hiddenAxe2 },
            { nom: 'scldscprg', largeur: 155, hidden: hiddenAxe2 },
            { nom: 'vaccod', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'vacdsc', largeur: 150, hidden: hiddenAxe2 },
            { nom: 'scldsccyc', largeur: 155, hidden: hiddenAxe2 },
            { nom: 'scldipnbrcre', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'sclcom', largeur: 155, hidden: hiddenAxe2 },
            { nom: 'scldatrecrh', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'sclflgverrh', largeur: 100, hidden: hiddenAxe2 },
            { nom: 'sclcomverrh', largeur: 175, hidden: hiddenAxe2 },
            { nom: 'unocod', largeur: 150 },
            { nom: 'unodscabr', largeur: 200 },
            { nom: 'fonc_pri', largeur: 200 },
            { nom: 'fonc_sec', largeur: 200 },
            { nom: 'dmncod', largeur: 150 },
            { nom: 'dmndsc', largeur: 200 },
            { nom: 'tyccod', largeur: 170 },
            { nom: 'tycdsc', largeur: 240 },
            { nom: 'unpcod', largeur: 150 },
            { nom: 'unpdsc', largeur: 230 },
            { nom: 'eplnumref1', largeur: 200 },
            { nom: 'eplnumref4', largeur: 200 },
            { nom: 'posnumdocsup', largeur: 200 },
            { nom: 'postitsup', largeur: 250 },
            { nom: 'flgaldact', largeur: 74 },
            { nom: 'eplnomusu', largeur: 160, hidden: hiddenAxe2 },
            { nom: 'eplprnusu', largeur: 160, hidden: hiddenAxe2 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('idecleintref', { required: true }),
            new FormulaireItem('comcleint', {
                resourceParams: (data: any) => ({ idecleintref: data.idecleintref }),
            }),
            new FormulaireItem('tycflginfscl', { largeur: 25, readonly: true }),
            new FormulaireItem('tycflginfexp', { largeur: 25, readonly: true }),
            new FormulaireItem('ideval', { largeur: 25, maxlength: 10, readonly: data => data.sainiv }),
            new FormulaireItem('idevalrev', { largeur: 25, maxlength: 10, readonly: data => data.sainiv }),
            new FormulaireItem('nivcleint', {readonly: data => !data.sainiv && data.idecleint}),
            new FormulaireItem('nivcleintrev', {readonly: data => !data.sainiv && data.idecleint}),
            new FormulaireItem('idedat', { largeur: 33, readonly: data => data.idecleint }),
            new FormulaireItem('idedatrev', { largeur: 33, readonly: data => data.idecleint }),
            new FormulaireItem('idedatval', { largeur: 33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ideannobt', { largeur: 20, readonly: data => !data.tycflgdatobt && data.idecleint }),
            new FormulaireItem('ideannderutl', { largeur: 20, readonly: data => !data.tycflgdatderutl && data.idecleint }),
            new FormulaireItem('idedatexp', { largeur: 20, readonly: data => !data.tycflgdatexp && data.idecleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('idecom', { enableCount: true }),
            new FormulaireItem('idecomrev', { enableCount: true }),
            new FormulaireItem('ittcleint'),
            new FormulaireItem('scldscitt'),
            new FormulaireItem('dipcleint'),
            new FormulaireItem('scldscdip'),
            new FormulaireItem('scldscprg'),
            new FormulaireItem('scldsccyc'),
            new FormulaireItem('vaccod', { largeur: 33, readonly: true }),
            new FormulaireItem('vacdsc', { largeur: 33, readonly: true }),
            new FormulaireItem('scldipnbrcre', { largeur: 33 }),
            new FormulaireItem('sclcom', { enableCount: true }),
            new FormulaireItem('scldatrecrh'),
            new FormulaireItem('sclflgverrh'),
            new FormulaireItem('sclcomverrh', { enableCount: true })
        ])
    };
}
