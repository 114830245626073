import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0225 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
}

interface IMr0225Params {
    id: number;
    rcecleint: number;
}

/* @ngInject */
export default function Mr0225Controller(
    $stateParams: IMr0225Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,

    Menu: IMenuClass
) {
    const vm: IPageMr0225 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            nouveau: (data: any) => !data.id
        },
    };

    vm.formulaire = new Formulaire('LBL_BLC_RPC', [
        new FormulaireItem('rcecleintsub', { largeur: 56 }),
        new FormulaireItem('rpcflgignimpavs', { largeur: 39 }),
        new FormulaireItem('rcecleint', { hidden: true, default: $stateParams.rcecleint })
    ]);
}
