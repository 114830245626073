import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCo0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCo0001 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'dmncod', largeur: 65},
            {nom: 'dmndsc', largeur: 210},
            {nom: 'dmncod_par', largeur: 91},
            {nom: 'dmndsc_par', largeur: 210}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dmncod', {required: true}),
            new FormulaireItem('dmndsc', {required: true}),
            new FormulaireItem('dmncleintref')
        ], {largeurDefaut: 33.33}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true,
            rechercheCollapsable: false
        }
    };
}
