import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMc0171 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mc0171Controller(mc0038Route: IRoute,
                                         mc0025Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0171 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0038Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mc0038Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_FORAGE_HIE',
                mc0025Route.NAME,
                (data: any) => {
                    return {
                        id: data.intcleint
                    };
                },
                {
                    icon: 'supervisor_account',
                    iconButton: true,
                    hidden: (rowData: any) => (!rowData.intflgreq)
                }
            )
        ]),
        colonnesFixesGauche: [
            {nom: 'intcod', largeur: 110},
            {nom: 'empcod', largeur: 100},
            {nom: 'intnom', largeur: 250}
        ],
        colonnesCachees: [
            'empcleint',
            'etbcleint'
        ],
        colonnesVisibles: [
            {nom: 'intflggesmannom', largeur: 180},
            {nom: 'intflgsecpru', largeur: 130},
            {nom: 'intflgsecuna', largeur: 130},
            {nom: 'intflgsecmag', largeur: 130},
            {nom: 'intflgreq', largeur: 130},
            {nom: 'intflgapr', largeur: 130},
            {nom: 'intflgaprint', largeur: 130},
            {nom: 'intflgappreq', largeur: 130},
            {nom: 'intflgrsp', largeur: 180},
            {nom: 'intflgche', largeur: 130},
            {nom: 'intflgach', largeur: 130},
            {nom: 'intflgachpri', largeur: 130},
            {nom: 'intflgdem', largeur: 130},
            {nom: 'intflgmag', largeur: 130},
            {nom: 'intflggesmanapp', largeur: 130},
            {nom: 'intflgintsys', largeur: 130},
            {nom: 'intsrucod', largeur: 160},
            {nom: 'etbcod', largeur: 160},
            {nom: 'etbnom', largeur: 160}
        ]
    };
}
