import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';

interface IPageMr0140 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceUccOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0140Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0140 = this;

    vm.multiOccurrenceUccOptions = {
        bloc: 'ucc',
        colonnesVisibles: [
            { nom: 'ucdcod', largeur: 60 },
            { nom: 'ucddsc', largeur: 120 },
            { nom: 'uccdsc', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ucdcleintper', {largeur: 30}),
            new FormulaireItem('uccdsc', {largeur: 70})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'ucdcod', largeur: 60},
            {nom: 'ucddsc', largeur: 120},
            {nom: 'ucdflgsal', largeur: 60},
            {nom: 'ucdflgtag', largeur: 60},
            {nom: 'ucdflgbqe', largeur: 60}
        ],

        multiOccurrenceOptionsEnfant: vm.multiOccurrenceUccOptions,

        formulaire: new Formulaire([
            new FormulaireItem('ucdcod', {largeur: 30}),
            new FormulaireItem('ucddsc', {largeur: 60}),
            new FormulaireItem('ucdflgsal', {largeur: 30}),
            new FormulaireItem('ucdflgtag', {largeur: 30}),
            new FormulaireItem('ucdflgbqe', {largeur: 30})
        ])
    };
}
