import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGs001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('vaeelecle'),
            new FormulaireItem('vaevalcle'),
            new FormulaireItem('vaedsc', {largeur: 50})
        ], {largeurDefaut: 25}),
        colonnesVisibles: [
            {nom: 'vaeelecle', largeur: 140},
            {nom: 'vaevalcle', largeur: 80},
            {nom: 'vaedsc', largeur: 260}
        ]
    };
}
