import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageIf0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceUviOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function If0002Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Pagination: IPaginationClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageIf0002 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceUviOptions = {
        bloc: 'uvi',
        fonctions: {
            pagination: true,
            importation: true,
            nouveau: true
        },
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 15, 25]
        }),
        colonnesVisibles: [
            { nom: 'usidsc', largeur: 200 },
            { nom: 'uviimp', largeur: 500 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('usicleint', { largeur: 20 }),
            new FormulaireItem('uviimp', { largeur: 30 })
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions:{
            importation: true
        },
        colonnesVisibles: [
            { nom: 'catcod', largeur: 100 },
            { nom: 'catdsc', largeur: 350 },
            { nom: 'catdscabr', largeur: 200 },
            { nom: 'vaecatmetamo_desc', largeur: 100 },
            { nom: 'cattauamo', largeur: 100 },
            { nom: 'vaecatcalamo_desc', largeur: 100 },
            { nom: 'catnbrper', largeur: 120 },
            { nom: 'catflgdtx', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('catcod', { largeur: 20 }),
            new FormulaireItem('catdsc', {
                largeur: 40,
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('catdscabr', { largeur: 40 }),
            new FormulaireItem('vaecatmetamo', { largeur: 14 }),
            new FormulaireItem('cattauamo', { largeur: 14 }),
            new FormulaireItem('vaecatcalamo', { largeur: 14 }),
            new FormulaireItem('catnbrper', { largeur: 14 }),
            new FormulaireItem('catflgdtx', { largeur: 10 })
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceUviOptions
    };
}
