import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePf0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0019Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePf0019 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cylcod', largeur: 105},
            {nom: 'cyldsc', largeur: 393},
            {nom: 'cylflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cylcod',    {largeur: 20}),
            new FormulaireItem('cyldsc',    {largeur: 60}),
            new FormulaireItem('cylflgact', {largeur: 20, default: 1})
        ])
    };
}
