import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCO0021 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIde: IFormulaire;
}

interface ICo0021Params {
    id: string;
    idecleintref: number;
    stamnetab: string;
}

/* @ngInject */
export default function Co0021Controller($stateParams: ICo0021Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCO0021 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireIde = new Formulaire('LBL_BLC_IDE', [
            new FormulaireItem('comcleint', { largeur: 50, resourceParams: (data: any) =>  ({ tyccleint: data.tyccleint }), nombreLibellesDetailsCacher: 1, readonly: (data) => data.idecleint }),
            new FormulaireItem('idecleintref', { default: $stateParams.idecleintref, hidden: true }),
            new FormulaireItem('stamnetab', { default: $stateParams.stamnetab, hidden: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ittcleint', { largeur: 50 }),
            new FormulaireItem('scldscitt', { largeur: 50 }),
            new FormulaireItem('dipcleint', { largeur: 50 }),
            new FormulaireItem('vacdtpcyc', { largeur: 50, readonly: true }),
            new FormulaireItem('scldscdip', { largeur: 50 }),
            new FormulaireItem('scldsccyc', { largeur: 50 }),
            new FormulaireItem('ideannobt', { readonly: (data) => !data.tycflgdatobt }),
            new FormulaireItem('scldscprg', { largeur: 50 }),
            new FormulaireItem('scldipnbrcre'),
            new FormulaireItem('ideval', { hidden: (data) => data.existe }),
            new FormulaireItem('idevalrev', { hidden: (data) => data.existe }),
            new FormulaireItem('nivcleint', { resourceParams: (data: any) =>  ({ tyccleint: data.tyccleint }), hidden: (data) => !data.existe }),
            new FormulaireItem('nivcleintrev', { resourceParams: (data: any) =>  ({ tyccleint: data.tyccleint }), hidden: (data) => !data.existe }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('idedat'),
            new FormulaireItem('idedatrev'),
            new FormulaireItem('idedatval'),
            new FormulaireItem('idedatexp'),
            new FormulaireItem('sclcom', { largeur: 50 }),
            new FormulaireItem('sclflgverrh'),
            new FormulaireItem('scldatrecrh'),
            new FormulaireItem('sclcomverrh', { largeur: 50 })
        ], { largeurDefaut: 25 });
    }
}
