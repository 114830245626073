import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageRr0106 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function rr0106Controller() {
    const vm: IPageRr0106 = this;

    vm.multiOccurrenceOptions = {

        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 210}
        ],
        colonnesVisibles: [
            {nom: 'ppacod', largeur: 80},
            {nom: 'ppacodcal', largeur: 90},
            {nom: 'rrpdatvis', largeur: 100},
            {nom: 'rrpdatver', largeur: 120},
            {nom: 'rrpmntadm', largeur: 126},
            {nom: 'rrpmntemp', largeur: 126},
            {nom: 'rrpmntemr', largeur: 126},
            {nom: 'rrpmntreg', largeur: 126},
            {nom: 'rrpmnttmp', largeur: 126}
        ]
    }
}
