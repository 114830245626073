import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0010Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0010 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'liecod', largeur: 125 },
            { nom: 'liedsc', largeur: 215 },
            { nom: 'lieflgcorsru', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('liecod'),
            new FormulaireItem('liedsc', { largeur: 40 }),
            new FormulaireItem('lieflgcorsru')
        ],
            {
                largeurDefaut: 25
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
