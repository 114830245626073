import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCC0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function CC0001Controller(
    Menu: IMenuClass,
    cc0007Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageCC0001 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cc0007Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.crscleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(cc0007Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'crscod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'crsdsc', largeur: 200 },
            { nom: 'unocod', largeur: 120 },
            { nom: 'unodscabr', largeur: 200 },
            { nom: 'tarcod', largeur: 120 },
            { nom: 'tardsc', largeur: 200 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdscabr', largeur: 200 },
            { nom: 'statut_ferm', largeur: 200 },
            { nom: 'crscodpoi', largeur: 180 },
            { nom: 'crsdscpoi', largeur: 250 },
            { nom: 'employe_qualif', largeur: 200 }
        ],
    };
}
