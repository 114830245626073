import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageDO0066 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0066Controller() {
    const vm: IPageDO0066 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        colonnesVisibles: [
            {nom: 'caenumdoc', largeur: 115},
            {nom: 'caedatsou', largeur: 100},
            {nom: 'caenom', largeur: 158},
            {nom: 'caeprn', largeur: 158},
            {nom: 'caenummat', largeur: 158},
            {nom: 'caesex_dsc', largeur: 90},
            {nom: 'caeflgimm', largeur: 85},
            {nom: 'nbrofa', largeur: 90}
        ]
    };
}
