import {IComponentOptions, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-infos-financieres', []).component('exInfosFinancieres', InfosFinancieresFactory());

function InfosFinancieresFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MONO_OCCURRENCE,
            {
                infosFinancieres: '<',
                selectedTab: '<'
            }
        ),
        controllerAs: 'vm',
        template: require('./ex-infos-financieres.html')
    };
}
