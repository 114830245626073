import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemActionTelechargementClass } from '../../../../../core/services/menu/menu-item-action-telechargement.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemActionImportationClass } from '../../../../../core/services/menu/menu-item-action-importation.service';
interface IPageAc0055 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface IAc0055Params {
    ajocleintref: string;
}

/* @ngInject */
export default function Ac0055Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: IAc0055Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemActionImportation: IMenuItemActionImportationClass) {
    const vm: IPageAc0055 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.menuItemImage = new MenuItemActionImportation(
        'LBL_AJOUT_DOC',
        'BOUAJO',
        () => ({}),
        {
            formulaireConfirmationAction: {
                lblTitre: 'LBL_AJOUT_DOC',
                formulaire: new Formulaire([
                    new FormulaireItem('mnetabnew', { 
                        default: ecranDetails.params.p_mnetab,
                        hidden: true
                    }),
                    new FormulaireItem('etccleintnew', { 
                        required: () => (ecranDetails.params.p_mnetab === 'ETC'),
                        hidden: () => !(ecranDetails.params.p_mnetab === 'ETC'),
                        largeur: 100
                    }),
                    new FormulaireItem('daccleintnew', { 
                        required: () => (ecranDetails.params.p_mnetab === 'DAC'),
                        hidden: () => !(ecranDetails.params.p_mnetab === 'DAC'),
                        largeur: 100
                    }),
                    new FormulaireItem('ajodscnew', { required: true, largeur: 100 })
                ])
            },
            typesFichiers: ['.pdf'],
            successAction: () => vm.multiOccurrence.fetchDataList(),
            icon: 'add_circle'
        }
    );


    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        actionsNouveaux: new Menu([
            vm.menuItemImage
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemActionTelechargement(
                'LBL_FICHIER',
                'BOUFIC',
                () => ({}),
                {
                    iconButton: true,
                    icon: 'attach_file'
                }
            )
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_SUPPRIMER',
                'BOUSUP',
                () => ({}),
                {
                    icon: '',
                    fonction: 'BOUSUP',
                    iconButton: false,
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        criteresSuggeres:
            new Formulaire([
                new FormulaireItem('mnecri', {
                    default: () => ecranDetails.params.p_mnetab,
                    hidden: true
                }),
                new FormulaireItem('etccleintcri', {
                    required: () => (ecranDetails.params.p_mnetab === 'ETC'),
                    hidden: () => !(ecranDetails.params.p_mnetab === 'ETC')
                }),
                new FormulaireItem('daccleintcri', {
                    readonly: () => (!!$stateParams.ajocleintref),
                    required: () => (ecranDetails.params.p_mnetab === 'DAC'),
                    hidden: () => !(ecranDetails.params.p_mnetab === 'DAC')
                }),
                new FormulaireItem('refcleintcri', { readonly: true })
            ]),
        colonnesVisibles: [
            { nom: 'usrnomcre', largeur: 160 },
            { nom: 'datcre', largeur: 160 },
            { nom: 'ajodsc', largeur: 300 },
            { nom: 'ajoflgori', largeur: 100 },
            { nom: 'ajoaddseq', largeur: 120 }
        ],
        colonnesCachees: [
            'ajomnetabref',
            'ajocleintref'
        ]
    };
}
