import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcran, IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageRr0058 extends IComponentController {
    formulaireEce: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    ecdMultiOccurrence: IMultiOccurrence;
    ecdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    repartition: IMenuItemEcran;
}

interface IRr0058Params {
    demcleint: number;
    rcecleint: number;
}

/* @ngInject */
export default function Rr0058Controller($stateParams: IRr0058Params,
                                         rr0554Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemEcran: IMenuItemEcranClass) {

    const vm: IPageRr0058 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.repartition = new MenuItemEcran(
            'BTN_REPARTITION',
            rr0554Route.NAME,
            () => ({
                id: vm.monoOccurrence.data.$id,
                ececleint: vm.monoOccurrence.data.$id,
                demcleint: $stateParams.demcleint,
                rcecleint: $stateParams.rcecleint
            }),
            {
                icon: 'assessment',
                iconButton: false,
                onClose: () => { vm.monoOccurrence.fetchData(); },
                disabled: () => (vm.monoOccurrence && (vm.monoOccurrence.data.aut__nbrper || !vm.monoOccurrence.data.$id ))
            }
        );

        // Cédule de RCE
        vm.formulaireEce = new Formulaire('LBL_BLC_ECE', [
            new FormulaireItem('demcleint', { largeur: 50, default: $stateParams.demcleint }),
            new FormulaireItem('rcecleint', { largeur: 50, default: $stateParams.rcecleint }),
            new FormulaireItem('ececod'),
            new FormulaireItem('ecedsc'   , { largeur: 65 }),
            new FormulaireItem('ecenumref', { largeur: 65 }),
            new FormulaireItem('eceflgact', { default: 1 })
        ], { largeurDefaut: 35 });

        // DÉTAIL DES RCE
        vm.ecdMultiOccurrenceOptions = {
            bloc:'ecd',
            colonnesVisibles: [
                {nom: 'ppacod'   , largeur: 100},
                {nom: 'ppadatdeb', largeur: 110},
                {nom: 'ppadatfin', largeur: 110},
                {nom: 'ecdmntemp', largeur: 140},
                {nom: 'ecdmntemr', largeur: 140},
                {nom: 'ecdflgact', largeur: 74}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ppacleint', { largeur: 40 }),
                new FormulaireItem('ecdmntemp'),
                new FormulaireItem('ecdmntemr'),
                new FormulaireItem('ecdflgact', { default: 1 })
            ], {largeurDefaut: 20})
        };
    }
}
