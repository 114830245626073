import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPagein0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function in0002Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    notificationHandler: INotificationHandler) {
    const vm: IPagein0002 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    // Si la structure 1 n'a pas été défini
    if (vm.valeursEcran.mesina) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesina
        });
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'st1cod', largeur: 160 },
            { nom: 'st1dsc', largeur: 334 },
            { nom: 'st1dscabr', largeur: 246 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('st1cod', { modifAllow: false }),
            new FormulaireItem('st1dsc', { largeur: 50 }),
            new FormulaireItem('st1dscabr')
        ], { largeurDefaut: 25 })
    };
}
