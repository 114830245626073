import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePb0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Pb0007Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    pb0002Route: IRoute,
    MenuItemAction: IMenuItemActionClass,
    pb0003Route: IRoute,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass) {
    const vm: IPagePb0007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        navigatePage: pb0002Route.NAME,
        navigateParams: (data: any) => {
            return {
                pvecleint: data.pvecleint
            };
        },
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BTN_REP',
                'BOUREP',
                (data: any) => ({
                    pveann: data.pveann,
                    pbscleint: data.pbscleint,
                    pvecleint: data.pvecleint
                }),
                {
                    icon: 'content_copy'
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('percleint', { required: true }),
            new FormulaireItem('pbscleint'),
            new FormulaireItem('intcleint'),
            new FormulaireItem('etat', { default: 'TOUS', titre: ' ', largeur: 50 })
        ]),
        colonnesCachees: ['msgerreur', 'msgerreuravr', 'pvecleint'],
        colonnesVisibles: [
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 236 },
            { nom: 'nbr_pos', largeur: 120 },
            { nom: 'pveflgaut', largeur: 126 },
            { nom: 'flg_avr', largeur: 120 },
            { nom: 'flg_err', largeur: 126 }
        ]
    };


}
