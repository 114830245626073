import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPagePD0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0023Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPagePD0023 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pmicleint', {
                required: false
            }),
            new FormulaireItem('loccleint', {
                required: false
            }),
            new FormulaireItem('raicleint', {
                required: false
            }),
            new FormulaireItem('intcleint', {
                required: false
            }),
            new FormulaireItem('dsclng', {
                required: false
            }),
            new FormulaireItem('vaephitypins', {
                required: false
            }),
            new FormulaireItem('modsou', {
                required: false
            }),
            new FormulaireItem('vaephiori', {
                required: false
            }),
            new FormulaireItem('flgeli', {
                default: '',
                required: true
            }),
            new FormulaireItem('flgdocatt', {
                default: '',
                required: true
            }),
            new FormulaireItemDivider('LBL_DATE_ACQ'),
            new FormulaireItem('datdeb', {
                required: false
            }),
            new FormulaireItem('datfin', {
                required: false
            })
        ]),
        colonnesFixesGauche: [
            {nom: 'phinumdoc', largeur: 200}
        ],

        // Colonnes fixe à droite
        colonnesFixesDroite: [
            {nom: 'phiflgeli', largeur: 200},
            {nom: 'nbrpj', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'loccod', largeur: 200},
            {nom: 'locdsc', largeur: 200},
            {nom: 'raicod', largeur: 200},
            {nom: 'raidsc', largeur: 200},
            {nom: 'phimod', largeur: 200},
            {nom: 'phidsclng', largeur: 200},
            {nom: 'phidatacq', largeur: 200},
            {nom: 'vaephitypins_dsc', largeur: 200},
            {nom: 'vaephiori_dsc', largeur: 200},
            {nom: 'phicieins', largeur: 200},
            {nom: 'phinumcat', largeur: 200},
            {nom: 'phinumser', largeur: 200},
            {nom: 'phifab', largeur: 200},
            {nom: 'pmicod', largeur: 200},
            {nom: 'pmidsc', largeur: 200}
        ]
    };
}
