import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRr0070 extends IComponentController {
    formulaireCvt: IFormulaire,
    eceMultiOccurrence: IMultiOccurrence;
    drdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eceMultiOccurrenceOptions: IMultiOccurrenceOptions;
    draMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rceMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Rr0070Controller($stateParams: IStateParamsService,
                                         rr0056Route: IRoute,
                                         rr0058Route: IRoute,
                                         rr0143Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Pagination: IPaginationClass) {

    const vm: IPageRr0070 = this;

    const forageDar = new MenuItemEcran(
        'LBL_FOR_DAR',
        rr0143Route.NAME,
        (rowData: any) => (
            {
                id: rowData.erccleint,
                demcleint: rowData.demcleint,
                rcecleint: rowData.rcecleint
            }
        ),
        {
            icon: 'launch_app',
            iconButton: true
        }
    );

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {

        // RCE PAR EMPLOYÉ
        vm.formulaireErc = new Formulaire('LBL_BLC_ERC', [
            new FormulaireItem('demcleint', { default: $stateParams.demcleint }),
            new FormulaireItem('erccleint', { hidden: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rcecleint' , {
                largeur : 33.33,
                resourceParams: () => ({flgsaisie: 1})
            }),
            new FormulaireItem('ercnumref' , {largeur : 33.33}),
            new FormulaireItem('flgrcedec' , {largeur : 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('darmntarr' , {largeur : 33.33, default: vm.valeursEcran.darmntarr, menu: [forageDar]})
        ]);

        // DÉTAIL DES RCE
        vm.drdMultiOccurrenceOptions = {
            bloc:'drd',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                {nom: 'drddatdeb', largeur: 100},
                {nom: 'drddatfin', largeur: 100},
                {nom: 'drdmntemp', largeur: 125},
                {nom: 'drdmntmaxemp', largeur: 125},
                {nom: 'mntcumemp', largeur: 125},
                {nom: 'drdmntemr', largeur: 140},
                {nom: 'drdmntexe', largeur: 125},
                {nom: 'drdprcapp', largeur: 125},
                {nom: 'drdflgact', largeur: 70}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('drddatdeb'),
                new FormulaireItem('drddatfin'),
                new FormulaireItem('drdmntemp'),
                new FormulaireItem('drdmntmaxemp'),
                new FormulaireItem('drdmntemr'),
                new FormulaireItem('drdmntexe'),
                new FormulaireItem('drdprcapp'),
                new FormulaireItem('drdflgact',{default : 1})
            ], {largeurDefaut: 25})
        };

        // CÉDULE
        vm.eceMultiOccurrenceOptions = {
            bloc:'ece',
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    rr0058Route.NAME,
                    () => ({
                        demcleint: vm.monoOccurrence.data.demcleint,
                        rcecleint: vm.monoOccurrence.data.rcecleint
                    }),
                    {
                        onClose: () => vm.eceMultiOccurrence.fetchDataList(),
                        disabled: () => !vm.monoOccurrence.data.farflgman || vm.monoOccurrence.data.vaercetyp !== 'R'
                    }
                ),
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'BTN_OUVRIR',
                    rr0058Route.NAME,
                    (data: any) => ({
                        id: data.ececleint,
                        demcleint: vm.monoOccurrence.data.demcleint,
                        rcecleint: vm.monoOccurrence.data.rcecleint
                    }),
                    {
                        icon: 'launch',
                        onClose: () => vm.eceMultiOccurrence.fetchDataList()
                    }
                )
            ]),
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                {nom: 'ececod', largeur: 100},
                {nom: 'ecedsc', largeur: 250},
                {nom: 'ecenumref', largeur: 140},
                {nom: 'ppacoddeb', largeur: 80},
                {nom: 'ppacodfin', largeur: 80},
                {nom: 'nbrppa', largeur: 92},
                {nom: 'ecdmntemptot', largeur: 130},
                {nom: 'ecdmntemrtot', largeur: 130}
            ]
        };

        // AJUSTEMENT
        vm.draMultiOccurrenceOptions = {
            bloc:'dra',
            actionsNouveaux: new Menu([
                new MenuItemForage(rr0056Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint,
                        rcecleintref: vm.monoOccurrence.data.rcecleint
                    };
                })
            ]),
            fonctions: {
                pagination: true,
                nouveau: () => !["090", "130", "131"].includes(vm.monoOccurrence.data.farcod)
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                {nom: 'ppacod', largeur: 90},
                {nom: 'ppadatdeb', largeur: 100},
                {nom: 'ppadatfin', largeur: 100},
                {nom: 'rcecod', largeur: 90},
                {nom: 'rcedscabr', largeur: 200},
                {nom: 'dramntemp', largeur: 130},
                {nom: 'dramntemr', largeur: 130},
                {nom: 'draqteadm', largeur: 130},
                {nom: 'dramntadm', largeur: 150},
                {nom: 'dracom', largeur: 200},
                {nom: 'nbrpij', largeur: 150}
            ],
            colonnesCachees: [
                'dracleint',
                'demcleint',
                'rcecleint'
            ]
        };

        // RCE GÉNÉRÉE
        vm.rceMultiOccurrenceOptions = {
            bloc:'rce',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesVisibles: [
                {nom: 'rcecod', largeur: 100},
                {nom: 'rcedsc', largeur: 400}
            ]
        };

    }
}
