import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0040 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0040Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0040 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'unmcod', largeur: 110},
            {nom: 'unmdsc', largeur: 187}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('unmcod', {required: true}),
            new FormulaireItem('unmdsc', {required: true})
        ]),
        fonctions: Object.assign({
            edition: true,
            nouveau: true,
            importation: true,
            rechercheCollapsable: false
        })
    };
}
