import {IComponentController} from 'angular';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPagePF0004 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePF0004 = this;

    // @TODO ajouter la route de forage une fois que l'écran est fait
    vm.$onInit = $onInit;

    function $onInit() {
        vm.multiOccurrenceOptions = {
            fonctions: {
                importation: true,
                rechercheCollapsable: false
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'tdscod', largeur: 125},
                {nom: 'tdsdsc', largeur: 460},
                {nom: 'tdsflgtypexo', largeur: 130},
                {nom: 'tdsflgtypape', largeur: 145},
                {nom: 'taxcod', largeur: 100},
                {nom: 'remcod', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tdscod', {largeur: 25}),
                new FormulaireItem('tdsdsc', {largeur: 75}),
                new FormulaireItem('tdsflgtypexo', {largeur: 25}),
                new FormulaireItem('tdsflgtypape', {largeur: 25}),
                new FormulaireItem('taxcleint', {largeur: 25}),
                new FormulaireItem('remcleint', {largeur: 25})
            ])
        };
    }
}
