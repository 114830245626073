import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0008Controller(gs0121Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0008 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gs0121Route.NAME,
        navigateParams: (data: any) => (
            {
                id: data.meccleint,
                meccleint: data.meccleint
            }),
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0121Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'mecdsc', largeur: 350},
            {nom: 'mecdscmen', largeur: 250},
            {nom: 'vaesysapp_dsc', largeur: 110},
            {nom: 'vaesysmodsof_dsc', largeur: 190},
            {nom: 'mecflgcen', largeur: 100}
        ]
    };
}
