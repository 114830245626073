import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'ae0004',
    ecrans: [
        '00-2'
    ],
    params: [
        '?reference'
    ]
});
