import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'pf0014',
    ecrans: ['00-2'],
    params: ['?axe',
             '?rbucleint',
             '?cabcleint',
             '?unocleintrh',
             '?demcleint',
             '?comcleint']
});
