import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0003Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'mdicod', largeur: 100 },
            { nom: 'mdidsc', largeur: 330 },
            { nom: 'vacvalcle', largeur: 115 },
            { nom: 'vacdsc', largeur: 155 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mdicod', { required: true }),
            new FormulaireItem('mdidsc', { required: true, largeur: 50 }),
            new FormulaireItem('vacmdityp', { required: true })
        ], { largeurDefaut: 25 })
    };
}
