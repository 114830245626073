import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'gl0007',
    ecrans: ['00-2'],
    params: [
        'cptcleint',
        'unacleint',
        'annfin',
        'perdeb',
        'perfin',
        'tymcleint',
        'vaesysnatctb',
        '?t_imp',
        '?prucleint',
        '?prscleint',
        '?cifcleint',
        '?el1cleint',
        '?el2cleint',
        '?el3cleint',
        '?unmcleint',
        '?soncleint'
    ]
});

