import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePf0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0021Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePf0021 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'pfgcod', largeur: 120},
            {nom: 'pfgdsc', largeur: 525}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pfgcod', {largeur: 25}),
            new FormulaireItem('pfgdsc', {largeur: 75})
        ])
    };
}
