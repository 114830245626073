import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';

interface IPageAs0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0053Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService,
                                         $stateParams: IStateParamsService) {
    const vm: IPageAs0053 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 210, hidden: $stateParams.employe},
            {nom: 'eplnummatsub', largeur: 100},
            {nom: 'eplnomprnsub', largeur: 210},
            {nom: 'ecnnumdoc', largeur: 110},
            {nom: 'ttccod', largeur: 110},
            {nom: 'ttcdsc', largeur: 252},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'asgcod', largeur: 123},
            {nom: 'asgdsc', largeur: 262},
            {nom: 'ecndat', largeur: 100},
            {nom: 'aqtcod', largeur: 105},
            {nom: 'ecndatsub', largeur: 123},
            {nom: 'aqtcodsub', largeur: 123},
            {nom: 'cxpcod', largeur: 103},
            {nom: 'cxpdsc', largeur: 262},
            {nom: 'temcod', largeur: 89},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'trnflgmsg', largeur: 69},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75},
            {nom: 'flgnonhon', largeur: 76}
        ]
    };
}
