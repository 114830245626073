import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0019Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0019 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'toccod', largeur: 125 },
            { nom: 'tocdsc', largeur: 215 },
            { nom: 'vaetocreg_dsc', largeur: 165 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('toccod'),
            new FormulaireItem('tocdsc', {
                largeur: 40
            }),
            new FormulaireItem('vaetocreg')
        ],
            {
                largeurDefaut: 30
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
