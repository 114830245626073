import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';

interface IPageCP0049 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0049Controller(Menu: IMenuClass,
                                         MenuItem: IMenuItemClass) {
    const vm: IPageCP0049 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage:???Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'refcod', largeur: 115},
            {nom: 'refnom', largeur: 262},
            {nom: 'folann', largeur: 75},
            {nom: 'vaecprtyp_dsc', largeur: 134},
            {nom: 'cpiflgmod', largeur: 115},
            {nom: 'cprflgimp', largeur: 125},
            {nom: 'cprflgimpcie', largeur: 115},
            {nom: 'cprnumrel', largeur: 105},
            {nom: 'cprnumrelxml', largeur: 130},
            {nom: 'cprnumrelori', largeur: 130},
            {nom: 'cprnumrelxmlori', largeur: 140},
            {nom: 'cprseqfic', largeur: 65},
            {nom: 'folcod', largeur: 105},
            {nom: 'foldsc', largeur: 262}
        ]
    };
}
