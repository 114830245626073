import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAc0056 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    daiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dniMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0056Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite
) {

    const vm: IPageAc0056 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            supprime: () => (vm.valeursEcran.indfor === 1)
        },
        formulaire: new Formulaire('LBL_BLC_DND', [
            new FormulaireItem('daccleint', { default: () => $stateParams.daccleint }),
            new FormulaireItem('dndrefint', { default: () => $stateParams.dndrefintnew }),
            new FormulaireItem('lolcleint', { default: () => $stateParams.lolcleintnew }),
            new FormulaireItem('dnddst', { default: () => $stateParams.intnomnew })
        ], { largeurDefaut: 25 })
    };

    vm.dniMultiOccurrenceOptions = {
        bloc: 'dni',
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'dnirefint', largeur: 130 },
            { nom: 'lolnumloc', largeur: 120 },
            { nom: 'loldsc'   , largeur: 200 },
            { nom: 'dnidst'   , largeur: 250 },
            { nom: 'dniqte'   , largeur: 126 },
            { nom: 'dniqtedst', largeur: 126 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dnirefint'),
            new FormulaireItem('lolcleint'),
            new FormulaireItem('dnidst'),
            new FormulaireItem('dniqte'),
        ], { largeurDefaut: 25 })
    };

    vm.daiMultiOccurrenceOptions = {
        bloc: 'dai',
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'dailig'   , largeur: 100 },
            {nom: 'daiseqave', largeur: 80 },
            {nom: 'dainumcat', largeur: 150 },
            {nom: 'daidsc'   , largeur: 300 },
            {nom: 'daiprxuni', largeur: 126 },
            {nom: 'dahqtecmd', largeur: 126 },
            {nom: 'dahqtedst', largeur: 126 },
            {nom: 'daiqterst', largeur: 126 }
        ],
        multiOccurrenceOptionsEnfant: vm.dniMultiOccurrenceOptions
    };
}

