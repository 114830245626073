import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCVAOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0023Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0023 = this;

    vm.multiOccurrenceCVAOptions = {
        bloc: 'cva',
        colonnesVisibles: [
            {nom: 'cvaann', largeur: 100},
            {nom: 'cvamntall', largeur: 126},
            {nom: 'cvamntaju', largeur: 126}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cvaann', {required: true}),
            new FormulaireItem('cvamntall', {required: true}),
            new FormulaireItem('cvamntaju'),
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'pcvcod', largeur: 90},
        ],
        colonnesVisibles: [
            {nom: 'pcvdscabr', largeur: 184},
            {nom: 'pcvdsc', largeur: 284},
            {nom: 'vaepcvsrcfin_dsc', largeur: 163},
            {nom: 'pcvcodmels', largeur: 147},
            {nom: 'vaepcvnivcat_dsc', largeur: 105},
            {nom: 'syscod', largeur: 100},
            {nom: 'sysdsc', largeur: 184}
        ],
        colonnesFixesDroite: [
            {nom: 'pcvflguni', largeur: 77},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pcvcod'),
            new FormulaireItem('pcvdscabr', {required: true}),
            new FormulaireItem('pcvdsc', {required: true}),
            new FormulaireItem('vaepcvsrcfin'),
            new FormulaireItem('pcvcodmels'),
            new FormulaireItem('vaepcvnivcat', {required: true}),
            new FormulaireItem('syscleintref'),
            new FormulaireItem('pcvflguni')
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCVAOptions
    };
}
