import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageAl0004 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dhdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Al0004Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageAl0004 = this;

    vm.formulaireDLH = new Formulaire([
        new FormulaireItem('prfcleint', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItem('dlhseqmod', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dlhdatvig', {
            largeur: 33,
            disabled: true
        }),
        new FormulaireItem('dlhflgact', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dlhpctrep', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItem('dlhtauhor', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dlhcom', {
            largeur: 33,
            required: false
        }),
        new FormulaireItem('dlhflgautftp', {
            largeur: 33,
            required: false,
            disabled: true
        })
    ]);
    // Detail Horaire du dossier d'absence
    vm.dhdMultiOccurrenceOptions = {
        bloc: 'dhd',
        colonnesVisibles: [
            { nom: 'aftcod', largeur: 130 },
            { nom: 'aftdscfmt', largeur: 130 },
            { nom: 'vaesysjrssem', largeur: 130 },
            { nom: 'dhddursem', largeur: 130 },
            { nom: 'dhdqtetra', largeur: 130 },
            { nom: 'dhdqteabs', largeur: 130 },
            { nom: 'totqte', largeur: 130 },
            { nom: 'prctra', largeur: 130 }
        ]
    }

}
