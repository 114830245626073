import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMC0105 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function MC0105Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageMC0105 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('tyfcleint'),
            new FormulaireItem('toccleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintrsp'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('el1datdebcri'),
            new FormulaireItem('el1datfincri'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleint'),
            new FormulaireItem('orpcleint')
        ]),
        colonnesVisibles: [
            {nom: 'pdfcod',       largeur: 120},
            {nom: 'el1dsc',       largeur: 200},
            {nom: 'pdfdatdebree', largeur: 130},
            {nom: 'pdfdatfinree', largeur: 130},
            {nom: 'el1datdeb',    largeur: 130},
            {nom: 'el1datfin',    largeur: 130}
        ],
        colonnesCachees: ['el1cleint'],
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_CHANGE_DATE', 'BOUDAT', {
                selection: {
                    nom: 'changementdate',
                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                    lblConfirmationTitre: 'LBL_CHANGEMENT_DATE',
                    largeurFormulaire: 30,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('datnou', {
                            largeur: 80
                        })
                    ]),
                    icon: 'sync'
                }, successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            })
        ])
    };
}
