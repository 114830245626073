import {module} from 'angular';
import DialogMultiOccurrenceController, { IDialogMultiOccurrence } from './dialog-multi-occurrence.controller';
import {IDialogClass} from '../../../../core/services/dialog.service';

export default module('app.dialogs.dialog-multi-occurrence', [
    'core.services.dialog'
]).factory('DialogMultiOccurrence', DialogMultiOccurrenceFactory);

/* @ngInject */
function DialogMultiOccurrenceFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogMultiOccurrenceController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-multi-occurrence.html'),
        focusOnOpen: false,
        onRemoving: (element) => {
            const controller = element.controller() as IDialogMultiOccurrence;

            for (const data of controller.multiOccurrence.dataList) {
                data.$opened = false;
            }

            if (controller.onClose) {
                controller.onClose();
            }
        }
    });
}
