import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageAS0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0027Controller(as0026Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageAS0027 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: as0026Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(as0026Route.NAME)]),

        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 90},
            {nom: 'tagdsc', largeur: 315},
            {nom: 'apgseqaffemp', largeur: 120},
            {nom: 'apgdscemp', largeur: 250},
            {nom: 'apgflgcomobl', largeur: 175}
        ]
    };
}
