import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';

interface IPageMi0506 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceMikOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mi0506Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMi0506 = this;

    vm.multiOccurrenceMikOptions = {
        bloc: 'mik',
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'miknumref', largeur: 186},
            {nom: 'mikdsc', largeur: 328},
            {nom: 'mikflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('miknumref', {largeur:25, required: true}),
            new FormulaireItem('mikdsc'   , {largeur: 50}),
            new FormulaireItem('mikflgact', {largeur: 25, default: 1})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'tyrcod', largeur: 128},
            {nom: 'tyrdsc', largeur: 290},
            {nom: 'tyrnumpri', largeur: 100},
            {nom: 'tyrflglstval', largeur: 100},
            {nom: 'tyrflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyrcod', {largeur: 20, required: true}),
            new FormulaireItem('tyrdsc', {largeur: 80, required: true}),
            new FormulaireItem('tyrnumpri', {required: true}),
            new FormulaireItem('tyrflglstval'),
            new FormulaireItem('tyrflgact', {default: 1})
        ],  {largeurDefaut: 20} ),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceMikOptions
    };
}
