import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPagePF0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0027Controller(pf0006Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPagePF0027 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pf0006Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(pf0006Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'rbuannfin', largeur: 100},
            {nom: 'rbudatdeb', largeur: 100},
            {nom: 'rbudatfin', largeur: 100},
            {nom: 'urgcod'   , largeur: 180},
            {nom: 'urgdscabr', largeur: 250},
            {nom: 'rbumnttot', largeur: 126},
            {nom: 'rbumntuno', largeur: 126},
            {nom: 'rbumntcab', largeur: 126}
        ]
    };

}
