import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPagePI0040 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0040Controller(Operateur: IOperateurService,
                                         Menu: IMenuClass) {
    const vm: IPagePI0040 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesVisibles: [
            {nom: 'pirnumdoc', largeur: 200},
            {nom: 'ttccod', largeur: 200},
            {nom: 'pirdat', largeur: 200},
            {nom: 'ecldsceta', largeur: 200},
            {nom: 'peccoddeb', largeur: 200},
            {nom: 'peccodfin', largeur: 200},
            {nom: 'pirdsc', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnom', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'tyddsc', largeur: 200},
            {nom: 'docaffnumdoc01', largeur: 200},
            {nom: 'trnflgstaref', largeur: 200},
            {nom: 'trnflgstafin', largeur: 200},
            {nom: 'trnflgstafer', largeur: 200},
            {nom: 'trnflgstaann', largeur: 200}
        ]
    };
}
