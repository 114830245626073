import { IComponentController } from 'angular';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IStateService } from 'angular-ui-router';

interface IPageAc0010 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    daiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    daiMultiOccurrence: IMultiOccurrence;
    somMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dcvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    conMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IAc0010Params {
    refcleint: string;
    intcleintreq: string;
    etrcleint: string;
    id: number;
    indfor: number;
    menuId: string;
}

/* @ngInject */
export default function Ac0010Controller(
    $stateParams: IAc0010Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    exDateFilter: IFilterExDate,
    $state: IStateService,
    cp1010Route: IRoute,
    cp2010Route: IRoute,
    ac0032Route: IRoute,
    ac0028Route: IRoute,
    ac0041Route: IRoute,
    mc1012Route: IRoute,
    mc1014Route: IRoute,
    ac0022Route: IRoute,
    ac0055Route: IRoute,
    mc0044Route: IRoute,
    ac0072Route: IRoute,
    ac0076Route: IRoute,
    ac0073Route: IRoute,
    ac0025Route: IRoute, 
    ac0056Route: IRoute) {

    const vm: IPageAc0010 = this;

    const actionAdrRapide = new MenuItemAction(
        'LBL_BTN_ADR_RAPIDE',
        'BOUADRRAP',
        () => ({}),
        {
            icon: 'add_circle',
            fonction: 'BOUADRRAP',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_ADR_RAPIDE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 30,
                formulaire: new Formulaire([
                    new FormulaireItem('radcodnew', { required: true }),
                    new FormulaireItem('radadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('radadrl02new', { maxlength: 40 }),
                    new FormulaireItem('radvilnew', { maxlength: 40 }),
                    new FormulaireItem('radpronew', { maxlength: 40 }),
                    new FormulaireItem('radpaynew', { maxlength: 40 }),
                    new FormulaireItem('radcodposnew', { maxlength: 10 }),
                    new FormulaireItem('radcodcednew', { maxlength: 40 })
                ], {
                    largeurDefaut: 100
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );

    const actionLieuPhyrec = new MenuItemAction(
        'LBL_BTN_LIEU_PHYSIQUE',
        'BOULIEPHY',
        () => ({
            type: 'REC'
        }),
        {
            icon: 'add_circle',
            fonction: 'BOULIEPHY',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('lipcodnew', { required: true }),
                    new FormulaireItem('lipflgadrfacnew'),
                    new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                    new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                    new FormulaireItem('intcleintnew'),
                    new FormulaireItemDivider('LBL_LIP_ADR'),
                    new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                    new FormulaireItem('lipvilnew', { maxlength: 40 }),
                    new FormulaireItem('lippronew', { maxlength: 40 }),
                    new FormulaireItem('lippaynew', { maxlength: 40 }),
                    new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                    new FormulaireItem('lipflgquarecnew'),
                    new FormulaireItemDivider(''),
                    new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                    new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                    new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                    new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                    new FormulaireItem('lipcleintrefnew')
                ], {
                    largeurDefaut: 50
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );

    const actionLieuPhyfac = new MenuItemAction(
        'LBL_BTN_LIEU_PHYSIQUE',
        'BOULIEPHY',
        () => ({
            type: 'FAC'
        }),
        {
            icon: 'add_circle',
            fonction: 'BOULIEPHYFAC',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('lipcodnew', { required: true }),
                    new FormulaireItem('lipflgadrfacnew'),
                    new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                    new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                    new FormulaireItem('intcleintnew'),
                    new FormulaireItemDivider('LBL_LIP_ADR'),
                    new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                    new FormulaireItem('lipvilnew', { maxlength: 40 }),
                    new FormulaireItem('lippronew', { maxlength: 40 }),
                    new FormulaireItem('lippaynew', { maxlength: 40 }),
                    new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                    new FormulaireItem('lipflgquarecnew'),
                    new FormulaireItemDivider(''),
                    new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                    new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                    new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                    new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                    new FormulaireItem('lipcleintrefnew')
                ], {
                    largeurDefaut: 50
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );

    const actionRef = [
        new MenuItemAction(
            'LBL_BTN_CHGREF',
            'BOUCHGREF',
            () => ({}),
            {
                icon: 'change_circle',
                fonction: 'BOUCHGREF',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_CHGREF',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('textaffichage', { 
                            titre: ' ',
                            readonly: true,
                            default: "Cette fonction vous permet d'effectuer le changement d'entité externe de votre document d'achat.  Veuillez noter que les valeurs de défaut provenant de l'entité externe que vous allez spécifier, écraseront celles qui se trouve actuellement sur le document."
                        }),
                        new FormulaireItem('refcleintnew', { 
                            required: true
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (vm.monoOccurrence.data.etrcleint || !vm.monoOccurrence.data.$id )
            }
        )
    ];

    const actionsApo = [
        new MenuItemForage(
            'BTN_FORAGE_APO',
            ac0028Route.NAME,
            (data:any) => ({
                id: data.aut__doccleintapo
            }),
            {
                icon: 'exit_to_app',
                iconButton: true,
                fonction: 'BOUAPO',
                hidden: () => (!vm.monoOccurrence.data.$id ) 
            }
        ),
        new MenuItemAction(
            'LBL_BTN_CHGAPO',
            'BOUCHGAPO',
            () => ({}),
            {
                icon: 'change_circle',
                fonction: 'BOUCHGAPO',
                messageConfirmation: 'BOUAPOCONF',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_CHGAPO',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('apocleintnew', { 
                            required: true,
                            resourceParams: (data: any) => ({
                                refcleint: data.refcleint
                            }),
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.$id ) 
            }
        )
    ];

    const actionMsg = [
        new MenuItemAction(
            'LBL_BTN_DACMSG',
            'BOUDACMSG',
            () => ({}),
            {
                icon: 'description',
                class: (data: any) => data.aut__flgdacmsg === 1 ? 'ex-teal-500-bg' : 'ex-primary-hue-3-fg',
                fonction: 'BOUDACMSG',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_DACMSG',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('dacmsgnew', { 
                            default: (data:any) => data.aut__dacmsg,
                            readonly: (data:any) => (!data.aut__dacflgmodpos),
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.$id ) 
            }
        ),
        new MenuItemAction(
            'LBL_BTN_DACMSGCOU',
            'BOUDACMSGCOU',
            () => ({}),
            {
                icon: 'mail',
                class: (data: any) => data.aut__flgmsgcou === 1 ? 'ex-teal-500-bg' : 'ex-primary-hue-3-fg',
                fonction: 'BOUDACMSGCOU',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_DACMSGCOU',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('dacmsgcounew', { 
                            default: (data:any) => data.aut__dacmsgcou,
                            readonly: (data:any) => (!data.aut__dacflgmodpos),
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.aut__motflgcou)    
            }
        )
    ];

    const forageEnt = [
        new MenuItemForage(
            'BTN_FORAGE_PFL',
            cp1010Route.NAME,
            (data:any) => ({
                entcleint: data.entcleint,
                ecran:'00-2',
            }),
            { 
                hidden: (data:any) => (!data.entcleint || !data.$id),
                fonction:'BOUENTPAI',
                icon:'exit_to_app'
            }
        )
    ];

    const forageDpf = [
        new MenuItemForage(
            'BTN_FORAGE_DPF',
            ac0041Route.NAME,
            (data:any) => ({
                id: data.aut__doccleintdpf
            }),
            { 
                hidden: (data:any) => (!data.aut__doccleintdpf || !data.$id),
                fonction:'BOUDPF',
                icon:'exit_to_app'
            }
        )
    ];

    const docConsomme = [
        new MenuItemForage(
            'BTN_DOC_CONSOMME',
            (rowData: any) => getForageItem(rowData),
            (rowData: any) => getParamItem(rowData),
            {
                icon: 'exit_to_app',
                iconButton: true,
                fonction: 'BOUDOCCON'
            }
        )
    ];
    
    const ajoutFca = [
        new MenuItemAction(
            'LBL_BTN_AJOFCA',
            'BOUFCA',
            () => ({}),
            {
                icon: 'mode_edti',
                fonction: 'BOUFCA',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_AJOFCA',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('fcacleintnew', { 
                            resourceParams: (data: any) => ({
                                dacdat: data.dacdat
                            })
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.$id )    
            }
        )
    ];

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        infosMonetaires: {
            blocs: ['dai', 'dcv'],
            largeurTexte: 150,
            donnees:
                [
                    { nom: 'dai', libelle: 'LBL_MNT_DAI' },
                    { nom: 'dcv', libelle: 'LBL_MNT_DCV' },
                    { nom: 'net', libelle: 'LBL_MNT_NET' },
                    { nom: 'eng', libelle: 'LBL_MNT_ENG' },
                    { nom: 'dev', libelle: 'LBL_TAU_DEV' },
                ]
        },
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: false,
            impactBudgetaire: true
        },
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_GEN_VENT',
                'BOUGENVNT',
                () => ({}),
                {
                    iconButton: false,
                    icon: 'refresh',
                    saveFirst: true,
                    fonction: 'BOUGENVNT',
                    messageConfirmation: 'BOUGENVEN_MESSAGE',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_GEN_VENT',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 40,
                        formulaire: new Formulaire([
                            new FormulaireItem('flgsupvnt', { 
                                default: 1
                            })
                        ], {
                            largeurDefaut: 100
                        })
                    },
                    successAction: () => { vm.monoOccurrence.fetchData(); },
                    hidden: () => (!vm.monoOccurrence.data.aut__dacflggenimp)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_REO',
                'BOUREO',
                () => ({}),
                {
                    iconButton: false,
                    icon: 'file_open',
                    saveFirst: true,
                    fonction: 'BOUREO',
                    successAction: () => { vm.monoOccurrence.fetchData(); },
                    hidden: (data:any) => (vm.monoOccurrence.data.aut__dacflgreo || !data.$id)
                }
            ),
            new MenuItemEcran(
                'LBL_BTN_HISTO_IMP',
                ac0022Route.NAME,
                (data: any) => ({ 
                    dhicleintref: data.doccleint,
                    dhityp: 'DOC'
                }), 
                {
                    icon: 'launch',
                    iconButton: false,
                    fonction: 'BOUHISIMP',
                    hidden: (data:any) => (!data.$id)
                }
            ),
            new MenuItemEcran(
                'LBL_TOLERANCE',
                mc1014Route.NAME,
                (data:any) => ({
                    tolcleintref: data.daccleint,
                    vaeustent: 'DAC'
                }),
                {
                    fonction: 'BOUTOL',
                    icon: 'data_usage',
                    iconButton: false,
                    hidden: (data:any) => (!data.$id)
                }
            ),
            new MenuItemForage(
                'LBL_BTN_CLAUSE',
                ac0055Route.NAME,
                (data:any) => ({
                    ajocleintref: data.daccleint,
                    ecran:'01-2'
                }),
                {
                    fonction: 'BOUCLA',
                    icon: 'exit_to_app',
                    iconButton: false,
                    hidden: (data:any) => (!data.$id)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_DISTRI',
                'BOUDIS',
                () => ({}),
                {
                    fonction:'BOUDIS',
                    icon:'exit_to_app',
                    iconButton: false,
                    hidden: (data:any) => (!data.$id),
                    successAction: (retour:any) => {
                        $state.go(ac0056Route.NAME, {
                            id: retour.docidnew,
                            daccleint: retour.daccleintnew,
                            dndrefintnew: retour.dndrefintnew,
                            intnomnew: retour.intnomnew,
                            lolcleintnew: retour.lolcleintnew,
                            menuId: $stateParams.menuId
                        });
                    }
                }
            )
/*            new MenuItemForage(
                'LBL_BTN_DISTRI',
                ac0056Route.NAME,
                (data:any) => ({
                    id: data.aut__dndcleint,
                    daccleint: data.daccleint
                }),
                { 
                    fonction:'BOUDIS',
                    icon:'exit_to_app',
                    iconButton: false,
                    hidden: (data:any) => (!data.$id)
                }                
            )*/
        ]),
        menus: [
            new MenuItemAction(
                'LBL_BTN_FORMULAIRE_CEN',
                'BOUFORCEN',
                () => ({}),
                {
                    iconButton: false,
                    icon: 'print',
                    fonction: 'BOUFORCEN',
                    secondaire: true,
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_FORMULAIRE_CEN',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('trlcodcen', { required: true })
                        ], {
                            largeurDefaut: 100
                        })
                    }
                }
            ),
            new MenuItemForage(
                'LBL_IMPUTATION',
                ac0072Route.NAME,
                (data: any) => ({ 
                    id: data.daccleint,
                    daccleint: data.daccleint,
                    indfor: $stateParams.indfor
                }),
                {
                    icon: 'exit_to_app',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'BTNIMPUTATION'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_AVENANT',
                ac0076Route.NAME,
                (data:any) => ({
                    daccleint: data.daccleint
                }),
                {
                    fonction: 'BOUAVE',
                    icon: 'exit_to_app',
                    iconButton: false,
                    secondaire: true,
                    hidden: () => (vm.monoOccurrence.data.aut__dacflgave === 1)
                }
            ),
            new MenuItemAction(
                'LBL_BTN_CREER_AVENANT',
                'BOUCREAVE',
                () => ({}),
                {
                    saveFirst: true,
                    icon: 'exit_to_app',
                    iconButton: false,
                    secondaire: true,
                    successAction: (retour: any) => {
                        $state.go(ac0073Route.NAME, {
                            id: retour.dcncleintnew,
                            menuId: $stateParams.menuId
                        });
                    },
                    hidden: () => (vm.monoOccurrence.data.aut__dacflgave !== 1)
                }
            ),
            new MenuItemForage(
                'LBL_BTN_HISTO_RELANCE',
                ac0025Route.NAME,
                (data:any) => ({
                    id: data.daccleint,
                    daccleint: data.daccleint
                }),
                { 
                    fonction:'BOUHISREL',
                    iconButton: false,
                    secondaire: true,
                    icon:'exit_to_app'
                }                
            )
        ],
        formulaire: new Formulaire('LBL_BLC_DAC', [
            new FormulaireItem('dacnumdoc'),
            new FormulaireItem('dacnumdocseq'),
            new FormulaireItem('sysboncmd'),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('dacdatbc'),
            new FormulaireItem('dacseqave'),
            new FormulaireItem('peccleint'),
            new FormulaireItem('dacdat', { default: exDateFilter(new Date()) }),
            new FormulaireItem('dacdatreq'),
            new FormulaireItem('refcleint', { default: $stateParams.refcleint, largeur: 66.66, menu: actionRef }),
            new FormulaireItem('etrcleint', { default: $stateParams.etrcleint }),
            new FormulaireItem('intcleintreq', { default: $stateParams.intcleintreq }),
            new FormulaireItem('intcleintdem'),
            new FormulaireItem('dacrefint'),
            new FormulaireItem('dacdsc', { largeur: 66.66, enableCount: true, menu: actionMsg }),
            new FormulaireItem('dacsou'),
            new FormulaireItem('caacleint', {
                resourceParams: (data: any) => ({
                    refcleint: data.refcleint
                })
            }),
            new FormulaireItem('aut__dpfnumdoc', { menu: forageDpf }),
            new FormulaireItem('dacflgpij'),
            new FormulaireItem('vaesysdevcod'),
            new FormulaireItem('devtaucou'),
            new FormulaireGroupeAccordeon('LBL_GRP_ADR',
                new Formulaire([
                    new FormulaireItem('radcleint', {
                        menu: [actionAdrRapide],
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            dacdat: data.dacdat
                        })
                    }),
                    new FormulaireItem('lipcleint', { menu: [actionLieuPhyrec]}),
                    new FormulaireItem('lipcleintfac', { menu: [actionLieuPhyfac]}),
                    new FormulaireItem('dacattext'),
                    new FormulaireItem('dacatt')
                ], { largeurDefaut: 33.33 })
            ),
            new FormulaireGroupeAccordeon('LBL_GRP_MOD',
                new Formulaire([
                    new FormulaireItemDivider('LBL_DIV_MODACH'),
                    new FormulaireItem('cfncleint', {
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            dacdat: data.dacdat
                        })
                    }),
                    new FormulaireItem('intcleintach'),
                    new FormulaireItem('apocleint', {
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint
                        }),
                        menu: actionsApo
                    }),
                    new FormulaireItem('iaccleint'),
                    new FormulaireItem('intcleintins'),
                    new FormulaireItem('dacins'),
                    new FormulaireItem('motcleint'),
                    new FormulaireItemDivider('LBL_DIV_MODFAC'),
                    new FormulaireItem('vecsysmodtra'),
                    new FormulaireItem('dacindfrsacc', { default: 1 }),
                    new FormulaireItem('teccleint'),
                    new FormulaireItem('aut__fcanum', { menu: ajoutFca }),
                    new FormulaireItem('mopcleint'),
                    new FormulaireItem('entcleint', { menu: forageEnt}),
                    new FormulaireItemDivider('LBL_DIV_AUTRES'),
                    new FormulaireItem('vecdaccat'),
                    new FormulaireItem('dacnumrescd', {
                        menu: [
                            new MenuItemEcran('LBL_HISTO_RESOL',
                                mc1012Route.NAME,
                                (data: any) => ({ doccleintref: data.daccleint }), 
                                {
                                    icon: 'launch',
                                    iconButton: true,
                                    fonction: 'BOUHISRES'
                                }
                            )
                        ]}        
                    ),
                    new FormulaireItem('dacnumresca'),
                    new FormulaireItem('vecsystercom'),
                    new FormulaireItem('dacdes'),
                    new FormulaireItem('lolcleint'),
                    new FormulaireItem('dacdatree'),
                    new FormulaireItem('dacdatdeb'),
                    new FormulaireItem('dacdatfin'),
                    new FormulaireItem('intcleint_averti'),
                    new FormulaireItem('aut__founomabr'),
                    new FormulaireItemSautDeLigne,
                    new FormulaireItem('dacdetexp', {largeur: 100, enableCount: true})        
                ], { largeurDefaut: 33.33 })
            )
        ], { largeurDefaut: 33.33 })
    };
    
    vm.daiMultiOccurrenceOptions = {
        bloc: 'dai',
        actionsMore: new Menu([
            new MenuItemAction(
                'LBL_BTN_RENUM', 
                'BOURENUM', 
                () => ({}), 
                {   
                    iconButton: false, 
                    icon: 'refresh', 
                    fonction: 'BOURENUM',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_RENUM',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('pasde', {
                                required: true,
                                default: 1
                            })
                        ])
                    },
                    successAction: () => { vm.daiMultiOccurrence.fetchDataList(); },
                    hidden: () => !(vm.monoOccurrence.data.aut__dacflgmodpos)
                }
            ),
            new MenuItemForage(
                'LBL_BTN_SOMMAIRE_ITEM',
                ac0032Route.NAME,
                (data:any) => ({
                    daccleint: data.daccleint
                }),
                { 
                    fonction:'BOUSOMITE',
                    icon:'exit_to_app'
                }                
            )
        ]),
        editionRapideActive: true,
        fonctions: {
            editionRapide: true,
            editionStandard: true,
            agrandirMultiOccurrence: true,
        },
        colonnesFixesGauche: [
            { nom: 'dailig', largeur: 100 },
            { nom: 'dailigseq', largeur: 65 },
            { nom: 'daiseqave', largeur: 65 },
            { nom: 'daidsc', largeur: 300 }
        ],
        colonnesVisibles: [
            { nom: 'daiprxuni', largeur: 120 },
            { nom: 'daiqte', largeur: 120 },
            { nom: 'taxcod', largeur: 80 },
            { nom: 'daimnt', largeur: 120 },
            { nom: 'daimnttaxfed', largeur: 130 },
            { nom: 'daimnttaxpro', largeur: 130 },
            { nom: 'caacod', largeur: 140 },
            { nom: 'caadsc', largeur: 180 },
            { nom: 'dainumcat', largeur: 150 },
            { nom: 'forcod', largeur: 120 },
            { nom: 'fordsc', largeur: 180 },
            { nom: 'unmcod', largeur: 130 },
            { nom: 'unmdsc', largeur: 180 },
            { nom: 'daiclash', largeur: 140 },
            { nom: 'daipayori', largeur: 140 },
            { nom: 'lolnumloc', largeur: 120 },
            { nom: 'loldsc', largeur: 180 },
            { nom: 'daiflggeb', largeur: 120 }
        ],
        colonnesFixesDroite: [
            { nom: 'daiflghis', largeur: 80 },
            { nom: 'flgimp', largeur: 80 },
            { nom: 'daiflgimp', largeur: 110 }
        ],
        colonnesEditionRapide: [
            {nom: 'dailig', largeur: 130 },
            {nom: 'daidsc', largeur: 150 },
            {nom: 'edecleint', largeur: 150, hidden: (data:any) => !(data.$ancetre.aut__etcflgitm) },
            {nom: 'daiprxuni', largeur: 120 },
            {nom: 'daiqte', largeur: 120 },
            {nom: 'taxcleint', largeur: 150 },
            {nom: 'daimnt', largeur: 120 },
            {nom: 'daimnttaxfed', largeur: 140 },
            {nom: 'daimnttaxpro', largeur: 140 },
            {nom: 'caacleint', largeur: 150 },
            {nom: 'dainumcat', largeur: 140 },
            {nom: 'forcleint', largeur: 120 },
            {nom: 'daiclash', largeur: 140 },
            {nom: 'daipayori', largeur: 120 },
            {nom: 'lolcleint', largeur: 120 },
            {nom: 'daiflggeb', largeur: 120 },
            {nom: 'daiflghis', largeur: 120 },
            {nom: 'daiflgimp', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dailig'),
            new FormulaireItem('edecleint', { 
                resourceParams: (data:any) => ({ 
                    etrcleint: data.$ancetre.etrcleint,
                    dacdat: data.$ancetre.dacdat
                 }) 
            }),
            new FormulaireItem('daidsc'),
            new FormulaireItem('daidsclng'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('daiprxuni'),
            new FormulaireItem('daiqte'),
            new FormulaireItem('taxcleint'),
            new FormulaireItem('daimnt'),
            new FormulaireItem('daimnttaxfed'),
            new FormulaireItem('daimnttaxpro'),
            new FormulaireItem('daimntnet'),
            new FormulaireItem('caacleint'),
            new FormulaireItem('dainumcat'),
            new FormulaireItem('forcleint'),
            new FormulaireItem('daiclash'),
            new FormulaireItem('daipayori'),
            new FormulaireItem('lolcleint'),
            new FormulaireItem('daiflggeb'),
            new FormulaireItem('daiflghis'),
            new FormulaireItem('daiflgimp', { default: 1 })
        ], { largeurDefaut: 33.33 })
    };

    vm.dcvMultiOccurrenceOptions = {
        bloc: 'dcv',
        colonnesFixesGauche: [
            { nom: 'dcvimp', largeur: 300, menu: [
                new MenuItemEcran(
                    '', 
                    mc0044Route.NAME, 
                    (rowData: any) => ({ 
                        id: rowData.prucleint, 
                        prucleint: rowData.prucleint }),
                    {
                    icon: 'launch',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'BOUPOS'
                })
            ] },
            { nom: 'dcvmnt', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'dcvqte', largeur: 100 },
            { nom: 'taxcod', largeur: 100 },
            { nom: 'remcod', largeur: 100 },
            { nom: 'dcvmnttaxfed', largeur: 130 },
            { nom: 'dcvmnttaxpro', largeur: 130 },
            { nom: 'dcvmntremfed', largeur: 130 },
            { nom: 'dcvmntrempro', largeur: 130 },
            { nom: 'dcvmntdepnet', largeur: 130 },
            { nom: 'dcvseqave', largeur: 100 },
            { nom: 'dcvdsc', largeur: 180 }
        ],
        colonnesFixesDroite: [
            { nom: 'dcvflgmsg', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dcvimp', { hidden: true }),
        ]),
        colonnesCachees: [
            'dcvmsg',
            'prucleint'
        ]
    };

    vm.somMultiOccurrenceOptions = {
        reloadWithBlocs: ['DAI'],
        colonnesFixesGauche: [
            {nom: 'dailig', largeur: 100},
            {nom: 'daidsc', largeur: 250}
        ],
        colonnesVisibles: [
            { nom: 'daidsclng', largeur: 250 },
            { nom: 'daiprxuni', largeur: 120 },
            { nom: 'daiqte', largeur: 120 },
            { nom: 'taxcod', largeur: 80 },
            { nom: 'daimnt', largeur: 120 },
            { nom: 'daimnttaxfed', largeur: 130 },
            { nom: 'daimnttaxpro', largeur: 130 },
            { nom: 'caacod', largeur: 140 },
            { nom: 'caadsc', largeur: 180 },
            { nom: 'dainumcat', largeur: 150 },
            { nom: 'forcod', largeur: 120 },
            { nom: 'fordsc', largeur: 180 },
            { nom: 'unmcod', largeur: 130 },
            { nom: 'unmdsc', largeur: 180 },
            { nom: 'daiclash', largeur: 140 },
            { nom: 'daipayori', largeur: 140 },
            { nom: 'lolnumloc', largeur: 120 },
            { nom: 'loldsc', largeur: 180 },
            { nom: 'daiflggeb', largeur: 120 }
        ]
    };

    vm.conMultiOccurrenceOptions = {
        bloc: 'con',
        colonnesFixesGauche: [
            { nom: 'docnumdoc', largeur: 150, menu: docConsomme }
        ],
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 100 },
            { nom: 'tyddsc', largeur: 200 },
            { nom: 'docdat', largeur: 126 },
            { nom: 'docmnt', largeur: 120 },
            { nom: 'docmntcon', largeur: 120 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'reqcod', largeur: 120 },
            { nom: 'reqnom', largeur: 200 }
        ],
        colonnesCachees: [
            'ecrcod',
            'doccleintcon'
        ]
    };

    function getForageItem(rowData: any): string {
        if (rowData.ecrcod === 'CP0013-00') {
            return cp2010Route.NAME;
        }
    }
    function getParamItem(rowData: any) {
        if (rowData.ecrcod === 'CP0013-00') {
            return { id: rowData.doccleintcon };
        } 
    }

}
