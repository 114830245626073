import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0009Controller(rr0002Route: IRoute,
    rr0004Route: IRoute,
    Menu: IMenuClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService,
    MenuItemForage: IMenuItemForageClass,
    Operateur: IOperateurService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRR0009 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: !$stateParams.employe,
            effaceCriteres: $stateParams.employe,
            criteresSuggeresVisibles: !$stateParams.employe
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datlim'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("eplnummat", { required: false }),
            new FormulaireItem("eplnomprn", { required: false }),
            new FormulaireItem("eclcleint", { required: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("urgcleint", { required: false }),
            new FormulaireItem("strcleint", { required: false }),
            new FormulaireItem("tlecleint", { required: false })
        ]),
        navigatePage: rr0004Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.trncleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0002Route.NAME, () => {
                return {
                    tytcleint: 3,
                    demcleint: $stateParams.employe
                };
            })
        ]),
        filtres: [
            ...parametresRecherche(ecranDetails)
        ],
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe },
            { nom: 'ecldsceta', largeur: 180 },
            { nom: 'lemdatdeb', largeur: 100 },
            { nom: 'lemdatfin', largeur: 100 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdscabr', largeur: 236 },
            { nom: 'strcod', largeur: 100 },
            { nom: 'strdsc', largeur: 262 },
            { nom: 'lemcod', largeur: 105 },
            { nom: 'tlecod', largeur: 100 },
            { nom: 'tledsc', largeur: 320 },
            { nom: 'tleflgpmn', largeur: 100 },
            { nom: 'tleflgpri', largeur: 90 },
            { nom: 'lemflgemb', largeur: 100 },
            { nom: 'lemdsc', largeur: 393 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 76 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'trnflgstaann', largeur: 75 }
        ],
        colonnesCachees: [
            'trncleint'
        ]
    };
}
