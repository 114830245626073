import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageDB0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0008Controller() {
    const vm: IPageDB0008 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'pdfcod', largeur: 200},
            {nom: 'prjdsc', largeur: 200},
            {nom: 'intcod', largeur: 200},
            {nom: 'intnom', largeur: 200},
            {nom: 'orgcod', largeur: 200},
            {nom: 'orgnom', largeur: 200},
            {nom: 'orpcod', largeur: 200},
            {nom: 'orpdscabr', largeur: 200},
            {nom: 'prucod', largeur: 200}
        ]
    };
}
