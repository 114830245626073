import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageDO0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceTCHOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0006Controller(Menu: IMenuClass,
                                         Pagination: IPaginationClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass) {
    const vm: IPageDO0006 = this;

    vm.multiOccurrenceTCHOptions = {
        bloc: 'tch',
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 25, 50]
        }),
        colonnesVisibles: [
            {nom: 'gracod', largeur: 130},
            {nom: 'gradsc', largeur: 202},
            {nom: 'tchseqaff', largeur: 105},
            {nom: 'tchdsc', largeur: 560},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gracleint'),
            new FormulaireItem('tchseqaff'),
            new FormulaireItem('tchdsc', {required: true, largeur: 60})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 25, 50]
        }),
        colonnesCachees: ['temcleint'],
        colonnesVisibles: [
            {nom: 'temcod', largeur: 89},
            {nom: 'temdsc', largeur: 300},
            {nom: 'grecod', largeur: 89},
            {nom: 'gredsc', largeur: 262},
            {nom: 'temdscfon', largeur: 550}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('temcod'),
            new FormulaireItem('temdsc'),
            new FormulaireItem('grecleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temdscfon', { largeur: 90})

        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceTCHOptions
    };
}
