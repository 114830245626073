import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0257 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0257Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0257 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('rolflgact'),
        ]),
        colonnesFixesGauche: [
            {nom: 'unocod', largeur: 130},
            {nom: 'unodscabr', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'tytreg', largeur: 180},
            {nom: 'rolcod', largeur: 110},
            {nom: 'roldscabr', largeur: 180},
            {nom: 'usrcod', largeur: 110},
            {nom: 'usrnom', largeur: 180},
            {nom: 'roldatdeb', largeur: 126},
            {nom: 'roldatfin', largeur: 126},
            {nom: 'rolmneprv_dsc', largeur: 126}
        ]
    };
}
