import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";

interface IPageMc0123 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireMfo: IFormulaire;
    formulaireAutresinfo: IFormulaire;
    df1MultiOccurrenceOptions: IMultiOccurrenceOptions;
    df2MultiOccurrenceOptions: IMultiOccurrenceOptions;
    emvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    emrMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0123Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMc0123 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireMfo = new Formulaire('LBL_BLC_MFO', [
            new FormulaireItem('mfonumdoc'),
            new FormulaireItem('mfoflgabs'),
            new FormulaireItem('mfodsc'),
            new FormulaireItem('empcleintrsp'),
            new FormulaireItem('mfodat', {largeur: 25}),
            new FormulaireItem('mfodurhrs', {largeur: 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('mfolie'),
            new FormulaireItem('mfofororg'),
            new FormulaireItem('mfodsclng', {largeur: 100}),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_ACC_CRI', new Formulaire([
                new FormulaireItem('mfoflgcri1'),
                new FormulaireItem('mfoflgcri2'),
                new FormulaireItem('mfoflgcri3'),
                new FormulaireItem('mfoflgcri4')
            ]))
        ], {largeurDefaut: 50});

        vm.formulaireAutresinfo = new Formulaire('LBL_BLC_AUT', [
            new FormulaireItem('mfomnt'),
            new FormulaireItem('mfoser')
        ], {largeurDefaut: 100});

        vm.df1MultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'df1dat', largeur: 130}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('df1dat')
            ])
        };

        vm.df2MultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'df2datdep', largeur: 130},
                {nom: 'df2datret', largeur: 130}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('df2datdep'),
                new FormulaireItem('df2datret')
            ], {largeurDefaut: 25})
        };

        vm.emvMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'empcod', largeur: 140},
                {nom: 'empnom', largeur: 150},
                {nom: 'emvpos', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('empcleint'),
                new FormulaireItem('emvpos')
            ], {largeurDefaut: 50})
        };

        vm.emrMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'empcodrem', largeur: 150},
                {nom: 'empnomrem', largeur: 230},
                {nom: 'empcod', largeur: 150},
                {nom: 'empnom', largeur: 230}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('empcleintrem'),
                new FormulaireItem('empcleint')
            ])
        };
    }
}
