import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageMC0119 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0119Controller(mc0101Route: IRoute) {
    const vm: IPageMC0119 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mc0101Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'ajfnumdoc', largeur: 100},
            {nom: 'ajfnumdocseq', largeur: 100},
            {nom: 'tydcod', largeur: 100},
            {nom: 'docrefcod02', largeur: 100},
            {nom: 'docrefnom02', largeur: 200},
            {nom: 'tydcodref', largeur: 200},
            {nom: 'docaffnumdoc01', largeur: 200},
            {nom: 'peccod', largeur: 100},
            {nom: 'etddsc', largeur: 200},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 200},
            {nom: 'ajfdsc', largeur: 200},
            {nom: 'usrcod', largeur: 150}
        ]
    };
}
