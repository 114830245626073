import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRR0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0015Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0015 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            importation: true
        },
        formulaire: new Formulaire([
            new FormulaireItem('cincod', {largeur: 25}),
            new FormulaireItem('cindsc', {largeur: 75}),
            new FormulaireItem('cinlig1'),
            new FormulaireItem('cinlig2'),
            new FormulaireItem('cinlig3')
        ]),
        colonnesFixesGauche: [
            {nom: 'cincod', largeur: 90},
            {nom: 'cindsc', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'cinlig1', largeur: 262},
            {nom: 'cinlig2', largeur: 262},
            {nom: 'cinlig3', largeur: 262}
        ]
    };
}
