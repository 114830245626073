import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IFilterExDate} from '../../../../../core/filters/ex-date.filter';

interface IPageAs0072 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    itnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface IAs0072Params {
    id: string;
}

/* @ngInject */
export default function As0072Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         exDateFilter: IFilterExDate,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $stateParams: IAs0072Params,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageAs0072 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemAction(
                '',
                'BOUCREEMP',
                (data: any) => ({
                    unpcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.unpcleint,
                    demcleint: data.demcleint,
                    date: data.date,
                    aftcleint: data.aftcleint
                }),
                {
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_AJOUT_EMPLOYE',
                        lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                        lblConfirmAndNext: 'G_LBL_BTN_ENREGISTRER_SUIVANT',
                        largeurFormulaire: 60,
                        formulaire: new Formulaire([
                            new FormulaireItem('demcleint', {
                                resourceParams: (data: any) => ({unpcleint: data.unpcleint}),
                                required: true
                            }),
                            new FormulaireItem('date', {
                                required: true
                            }),
                            new FormulaireItem('aftcleint', {
                                resourceParams: (data: any) => ({
                                    demcleint: data.demcleint,
                                    date: data.date
                                }),
                                disabled: (data: any) => (!data.demcleint || !data.date)
                            })
                        ], {
                            largeurDefaut: 33
                        })
                    }
                }
            )
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 262}
        ],
        colonnesVisibles: [
            {nom: 'aftcod', largeur: 110},
            {nom: 'temcod', largeur: 100},
            {nom: 'esvcod1', largeur: 105, hidden: () => !vm.valeursEcran.flgesv1aff},
            {nom: 'esvcod2', largeur: 105, hidden: () => !vm.valeursEcran.flgesv2aff},
            {nom: 'esvcod3', largeur: 105, hidden: () => !vm.valeursEcran.flgesv3aff},
            {nom: 'esvcod4', largeur: 105, hidden: () => !vm.valeursEcran.flgesv4aff},
            {nom: 'esvcod5', largeur: 105, hidden: () => !vm.valeursEcran.flgesv5aff},
            {nom: 'imputation', largeur: 300},
            {nom: 'prucod', largeur: 120},
            {nom: 'ptacod', largeur: 90},
            {nom: 'occdsc', largeur: 170},
            {nom: 'postitabr', largeur: 195},
            {nom: 'plage', largeur: 198},
            {nom: 'isdcom', largeur: 395},
            {nom: 'flgmsg', largeur: 125},
            {nom: 'isicleint', largeur: 100}
        ],
        colonnesFixesDroite: [
            {nom: 'isdflgerr', largeur: 81},
            {nom: 'isdflgcor', largeur: 101},
            {nom: 'isddat', largeur: 100},
            {nom: 'actcod', largeur: 90},
            {nom: 'isdqte', largeur: 90}
        ],
        colonnesCachees: [
            'demcleint',
            'asgcleint',
            'urgcleint',
            'aerisderr',
            'vaeasgmodges'
        ],
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_TRANSMETTRE_APPROBATION', 'BOUPRO', {
                selection: {
                    nom: 'approbation'
                }
            })]),
        formulaire: new Formulaire([
            new FormulaireItem('aftcleint', {
                resourceParams: (data: any) => {
                    const row = vm.multiOccurrence.dataList.find(ele => ele.isdcleint === data.isdcleint);
                    return {demcleint: row && row.demcleint, date: data.isddat, asgcleint: row && row.asgcleint};
                }
            }),
            new FormulaireItem('temcleint', {
                resourceParams: (data: any) => {
                    return {urgcleint: data.urgcleint};
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('esvcleint1', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    datref: data.isddat,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5
                })
            }),
            new FormulaireItem('esvcleint2', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    datref: data.isddat,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esvcleint3', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    datref: data.isddat,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esvcleint4', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    datref: data.isddat,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItem('esvcleint5', {
                resourceParams: (data: any) => ({
                    flgsaiesv: 1,
                    datref: data.isddat,
                    esvcleint1: data.esvcleint1,
                    esvcleint2: data.esvcleint2,
                    esvcleint3: data.esvcleint3,
                    esvcleint4: data.esvcleint4,
                    esvcleint5: data.esvcleint5
                }),
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            //New FormulaireItem('prucleint'), ne pas afficher pour le moment (département)
            new FormulaireItem('occcleint', {required: true}),
            new FormulaireItem('actcleint', {
                resourceParams: (data: any) => {
                    const row = vm.multiOccurrence.dataList.find(ele => ele.isdcleint === data.isdcleint);
                    return {
                        asgcleint: data.asgcleint,
                        demcleint: row && row.demcleint,
                        isddat: getDateValue(data.isddat)
                    };
                },
                required: true
            }),
            new FormulaireItem('isdcom'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('isddat', {
                required: true,
                largeur: 25
            }),
            new FormulaireItem('isdhredeb', {
                largeur: 25
            }),
            new FormulaireItem('isdhrefin', {
                largeur: 25
            }),
            new FormulaireItem('isdqte', {
                largeur: 25
            })
        ], {largeurDefaut: 33.33}),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('unpcleint', {required: true}),
            new FormulaireItem('cridat'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ptacleint'),
            //New FormulaireItem('unocod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demcleint'),
            new FormulaireItem('poscleint')
        ], {largeurDefaut: 33.33})
    };

    vm.itnMultiOccurrenceOptions = {
        bloc: 'itn',
        colonnesVisibles: [
            {nom: 'site'      , largeur: 300},
            {nom: 'etiquette' , largeur: 300},
            {nom: 'hre_deb'   , largeur: 125},
            {nom: 'hre_fin'   , largeur: 125},
            {nom: 'itndsc'    , largeur: 300}
        ]
    };

    function getDateValue(dateStr:string): string {
        const date = new Date(dateStr);
        return exDateFilter(date);
    }
}
