import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMonoOccurrence} from "../../../../../core/services/mono-occurrence.service";
import {IStateParamsService} from 'angular-ui-router';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCP0072 extends IComponentController {
    formulaireRad: IFormulaire;
    monoOccurrence: IMonoOccurrence;
}

/* @ngInject */
export default function Cp0072Controller($stateParams: IStateParamsService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageCP0072 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireRad = new Formulaire('LBL_ADRESSE', [
            new FormulaireItem('refcleint',{default: $stateParams.refcleint,hidden:true}),
            new FormulaireItem('radcod'),
            new FormulaireItem('raddatdeb',{required:true}),
            new FormulaireItem('radflgpai'),
            new FormulaireItem('radflgstu',{
                default: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('radadrl01', {largeur: 50}),
            new FormulaireItem('radadrl02', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('radvil'),
            new FormulaireItem('radpro'),
            new FormulaireItem('radpay'),
            new FormulaireItem('radcodpos'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('radadrcou', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('radcodced'),
            new FormulaireItem('radnumtel'),
            new FormulaireItem('radnumtelpos'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('radnumfax'),
            new FormulaireItem('vecfaxcodspe', {largeur: 50}),
            new FormulaireItem('radflgfaxint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('radflgach'),
            new FormulaireItem('radflgdef'),
            new FormulaireItem('radflgret'),
            new FormulaireItem('radflgfac'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('radflgetacpt'),
            new FormulaireItem('radnom'),
            new FormulaireItem('radnumcli')
        ], {
            largeurDefaut: 25
        });
    }
}
