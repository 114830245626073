import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import {
    FormulaireElement, IFormulaire,
    IFormulaireClass
} from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IMenuClass, MenuElement } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageRR0022 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    menusTsm: { [col: string]: Array<FormulaireElement> };
    menusEmp: { [col: string]: Array<MenuElement> };
    menusLem: { [col: string]: Array<MenuElement> };
    tsaMenus: Array<MenuElement>;
    valeursEcran: any;
    formulaireTsa: IFormulaire;
    aftMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tsdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    marMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IPageRR0022Params {
    id: number;
    trncleint: number;
    tsacleintori: number;
    lsacleint: number;
    flgrr0005: number;
    tsecleint: number;
    rrhcleintref: number;
    ecrcleint_appel: number;
}

/* @ngInject */
export default function RR0022Controller(rr0002Route: IRoute,
    rr0003Route: IRoute,
    rr0004Route: IRoute,
    rr0006Route: IRoute,
    rr0022Route: IRoute,
    rr0023Route: IRoute,
    rr0155Route: IRoute,
    Formulaire: IFormulaireClass,
    $stateParams: IPageRR0022Params,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Pagination: IPaginationClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageRR0022 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.tseMenus = [
            new MenuItemForage(
                'LBL_RETOUR_TSE',
                rr0002Route.NAME,
                () => ({ id: vm.valeursEcran.trncleint_tse }),
                {
                    icon: 'keyboard_backspace',
                    iconButton: false,
                    secondaire: true,
                    hidden: () => (
                        vm.valeursEcran.tytcleint_tse === 1 ||
                        vm.valeursEcran.tytcleint_tse === 2 ||
                        vm.valeursEcran.tytcleint_tse === 3 ||
                        vm.valeursEcran.tytcleint_tse === 5 ||
                        vm.valeursEcran.tytcleint_tse === 73
                    )
                }
            ),

            new MenuItemForage(
                'LBL_TSE_EMPLOYE',
                rr0003Route.NAME,
                () => ({ id: vm.valeursEcran.trncleint_dem }),
                {
                    icon: 'create',
                    iconButton: false,
                    hidden: () => (
                        vm.valeursEcran.tytcleint_tse === 3 ||
                        vm.valeursEcran.tytcleint_tse === 4 ||
                        vm.valeursEcran.tytcleint_tse === 5 ||
                        vm.valeursEcran.tytcleint_tse === 73
                    )
                }
            ),
            new MenuItemForage(
                'LBL_TSE_LEMAFT',
                (vm.valeursEcran.tytcleint_tse === 5) ?
                    rr0006Route.NAME :
                    rr0004Route.NAME,
                () => ({
                    id: vm.valeursEcran.trncleint_tse
                }),
                {
                    icon: 'create',
                    iconButton: false,
                    hidden: () => (
                        vm.valeursEcran.tytcleint_tse === 4 ||
                        vm.valeursEcran.tytcleint_tse === 73
                    ),
                    disabled: () => (
                        !vm.valeursEcran.trncleint_lem
                    )
                }
            ),
            new MenuItemForage(
                'LBL_TSE_CLASSIFICATION',
                rr0022Route.NAME,
                () => ({ id: $stateParams.id }),
                {
                    courant: true,
                    icon: 'edit_location',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'LBL_TSE_PROBATION',
                rr0155Route.NAME,
                () => ({
                    id: $stateParams.tsecleint
                }),
                {
                    icon: () => {
                        if (!vm.valeursEcran.tprcleint) {
                            return 'add_circle';
                        } else {
                            return 'create';
                        }
                    },
                    iconButton: false,
                    hidden: vm.valeursEcran.flggespro === 3,
                    disabled: (data: any) => !vm.valeursEcran.tseflgprb && vm.valeursEcran.flggespro != 2
                }
            )
        ];

        // Forage vers l'employé RR0003
        vm.menusEmp = {
            employe: [new MenuItemForage(
                'BTN_FORAGE_',
                rr0003Route.NAME,
                () => ({
                    id: vm.valeursEcran.trncleint_dem
                }),
                {
                    fonction: 'BTN_FORAGE_EMP'
                }
            )]
        };

        // Forage vers lien d'emploi RR0004
        vm.menusLem = {
            lemdscfmt: [new MenuItemForage(
                'BTN_FORAGE_',
                rr0004Route.NAME,
                () => ({
                    id: vm.valeursEcran.trncleint_lem
                }),
                {
                    fonction: 'BTN_FORAGE_LIEN'
                }
            )]
        };

        vm.menusTsm = {
            aut__nbrtsm: [
                new MenuItemForage(
                    rr0023Route.NAME,
                    () => ({
                        ecran: '00-2',
                        cleintref: vm.valeursEcran.lsacleint,
                        demcleint: vm.valeursEcran.demcleint
                    }),
                    { hidden: (data: any) => !data.aut__nbrtsm }
                )
            ]
        };

        // Transaction de modification
        vm.tsaMenus = [
            new MenuItemForage(
                'G_LBL_MODIF_ECHSAL',
                rr0022Route.NAME,
                (data: any) => ({
                    ecran: '02-2',
                    tsacleintori: data.tsacleint
                }), {
                icon: 'add_circle',
                iconButton: false,
                secondaire: true,
                fonction: "BOUECH"
            }),
            new MenuItemForage(
                'G_LBL_MODIF_GRPECH',
                rr0022Route.NAME,
                (data: any) => ({
                    ecran: '01-2',
                    tsacleintori: data.tsacleint
                }), {
                icon: 'add_circle',
                iconButton: false,
                secondaire: true,
                fonction: "BOUGEC"
            })
        ];

        // Affectation(s) applicable(s)
        vm.aftMultiOccurrenceOptions = {
            resourceParams: {
                lsacleint: vm.valeursEcran.lsacleint,
                lemcleint_aft: vm.valeursEcran.lemcleint

            },
            navigatePage: rr0006Route.NAME,
            navigateParams: (data: any) => ({
                id: data.trncleint,
                trncleint: data.trncleint
            }),
            bloc: 'aft',
            fonctions: {
                pagination: true,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'aftcoddsc_fmt', largeur: 260 },
                { nom: 'ecldsceta', largeur: 130 },
                { nom: 'aftdatdeb', largeur: 120 },
                { nom: 'aftdatfin', largeur: 120 }
            ],
            colonnesCachees: [
                'trncleint'
            ]
        };

        vm.formulaireTsa = new Formulaire('LBL_BLC_TSA', [

            // Champs cachés qui doivent être alimenté
            new FormulaireItem('tsecleint', {
                default: $stateParams.tsecleint
            }),
            new FormulaireItem('lemcleint', {
                default: vm.valeursEcran.lemcleint
            }),
            new FormulaireItem('lsacleint', {
                default: vm.valeursEcran.lsacleint
            }),
            new FormulaireItem('t_vaegectypech', {
                default: vm.valeursEcran.vaegectypech
            }),

            new FormulaireItem('tsadatvig', { default: vm.valeursEcran.tsadatvig }),
            new FormulaireItem('t_daedatvig', { default: vm.valeursEcran.daedatvig }),
            new FormulaireItem('lsadataugforcar', {
                menu: [ new MenuItemAction(
                            'LBL_BOU_DATAUG', 
                            'BOUDATAUG', 
                            () => ({}), 
                            {
                                iconButton: true,
                                icon: 'edit',
                                fonction: 'BOUDATAUG',
                                formulaireConfirmationAction: {
                                    lblTitre: 'LBL_BOU_DATAUG',
                                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                                    largeurFormulaire: 20,
                                    formulaire: new Formulaire([
                                        new FormulaireItem('lsadataugformm', {
                                            required: true, 
                                            largeur: 100,
                                            default: (data:any) => data.lsadataugfor
                                        }),
                                        new FormulaireItem('lsadataugforyy', {
                                            required: true, 
                                            largeur: 100,
                                            default: (data:any) => data.lsadataugfor
                                        })
                                    ], {
                                        securityCycle: false
                                    })
                                },
                                successAction: () => {vm.monoOccurrence.fetchData();}
                            })
                        ] 
                    }
                ),
            //new FormulaireItem('lsadataugformm'),
            //new FormulaireItem('lsadataugforyy'),

            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaetsatypsal', {
                default: vm.valeursEcran.vaetsatypsal,
                resourceParams: {
                    vaecvttypech: vm.valeursEcran.vaecvttypech
                }
            }),            
            new FormulaireItem('vaetsatypprv'),
            new FormulaireItem('tsahreheb', { readonly: (data) => data.vaetsatypprv != 'MAN' }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tsadsc', {
                enableCount: true,
                default: vm.valeursEcran.tsadsc,
                largeur: 100
            }),

            new FormulaireItem('geccleint', {
                largeur: 66.66,
                default: vm.valeursEcran.geccleint,
                resourceParams: (data: any) => {
                    return ({
                        urgcleint: vm.valeursEcran.urgcleint,
                        vaetsatypsal: data.vaetsatypsal ? data.vaetsatypsal : vm.valeursEcran.vaetsatypsal,
                        lsacleint: data.lsacleint ? data.lsacleint : vm.valeursEcran.lsacleint,
                        aftcleint: vm.valeursEcran.aftcleint,
                        tsadatvig: data.tsadatvig
                    });
                }
            }),

            new FormulaireItem('unccleint', {
                default: vm.valeursEcran.unccleint
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('echcleint', {
                default: vm.valeursEcran.echcleint,
                largeur: 66.66,
                resourceParams: (data: any) => {
                    return ({
                        geccleint: data.geccleint,
                        tsadatvig: data.tsadatvig
                    });
                }
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tsasal', {
                default: vm.valeursEcran.tsasal
            }),

            new FormulaireItem('echsal', {
                default: vm.valeursEcran.echsal
            }),

            new FormulaireItem('echpoimil', {
                default: vm.valeursEcran.echpoimil
            }),
            new FormulaireItem('t_ratio', { default: vm.valeursEcran.ratio }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('aut__supplement_sal'),
            new FormulaireItem('aut__total'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ttccleint', {
                nombreLibellesDetailsCacher: 1,
                resourceParams: (data: any) => ({
                    ttccleint: vm.monoOccurrence.data.ttccleint,
                    tsacleint:  data.tsacleint
                }),
                largeur: 66.66,
                readonly: (data: any) => data.tsacleint 
            }),
            new FormulaireItem('tsanumdoc'),
            new FormulaireItem('tsacom', {
                enableCount: true,
                default: vm.valeursEcran.tsacom,
                largeur: 100
            })

        ], {
            largeurDefaut: 33.33
        });

        // Supplément salariaux
        vm.tsdMultiOccurrenceOptions = {
            bloc: 'tsd',
            fonctions: {
                pagination: true,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            formulaire: new Formulaire([
                new FormulaireItem('tsdseq'),
                new FormulaireItem('tagcleint', {
                    resourceParams: () => ({
                        tsadatvig: vm.monoOccurrence.data.tsadatvig,
                        urgcleint: vm.valeursEcran.urgcleint,
                        strcleint: vm.valeursEcran.strcleint
                    })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsdsal'),
                new FormulaireItem('tsdpou')
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'tsdseq', largeur: 100 },
                { nom: 'tagcod', largeur: 100 },
                { nom: 'tagdscabr', largeur: 260 },
                { nom: 'tsdsal', largeur: 126 },
                { nom: 'tsdpou', largeur: 126 }
            ]
        };

        // Marché - postes
        vm.marMultiOccurrenceOptions = {
            bloc: 'mar',
            fonctions: {
                pagination: true,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            colonnesFixesGauche: [
                { nom: 'posnumdoc', largeur: 100 }
            ],
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'posdsc', largeur: 240 },
                { nom: 'echsalmin', largeur: 131 },
                { nom: 'echpoimil', largeur: 131 },
                { nom: 'echsalmax', largeur: 131 },
                { nom: 'geccod', largeur: 130 },
                { nom: 'pmgdatdeb', largeur: 120 },
                { nom: 'pmgdatfin', largeur: 120 },
                { nom: 'aftcod', largeur: 120 },
                { nom: 'aftdatdeb', largeur: 120 },
                { nom: 'aftdatfin', largeur: 120 },
                { nom: 'aft_etat', largeur: 180 }
            ],
            colonnesFixesDroite: [
                { nom: 'ratmar', largeur: 131 }
            ]
        };
    }
}
