import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0135 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    formulaireBfi: IFormulaire;
    formulaireCpt: IFormulaire;
    formulaireEcj: IFormulaire;
    bfrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bfdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0135Controller(gl0003Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0135 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireBfi = new Formulaire('LBL_BLC_BFI', [
            new FormulaireItem('peccleint', {largeur: 33.33, required: true}),
            new FormulaireItem('bfiflgspcapprep', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cifcleint', {largeur: 33.33, required: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('el1cleint', {largeur: 33.33, required: true}),
            new FormulaireItem('bfiflgsldfdsnonrst', {largeur: 33.33}),
            new FormulaireItem('peccleintsld', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pdfcom', {largeur: 33.33, readonly: true, default: vm.valeursEcran.pdfcom}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleint', {largeur: 33.33, required: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'CLI'
                },
                largeur: 34,
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unacleint', {largeur: 33.33, required: false})
        ]);
        vm.formulaireCpt = new Formulaire('LBL_BLC_CPT', [
            new FormulaireItem('bficptcleintapprep', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('bficptcleintcarrep', {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('bficptcleintcptrev', {largeur: 33.33})
        ]);
        vm.formulaireEcj = new Formulaire('LBL_BLC_ECJ', [
            new FormulaireItem('bfiecjcleintpre', {largeur: 20, readonly: true,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_ECRITURES',
                        gl0003Route.NAME,
                        (data: any) => ({
                            id: vm.monoOccurrence.data.doccleintpre
                        }),
                        {hidden: () => !vm.monoOccurrence.data.bfiecjcleintpre}
                    )
                ]}),
            new FormulaireItem('bfiflgexcecjpre', {largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('bfiecjcleintcap', {largeur: 20, readonly: true,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_ECRITURES',
                        gl0003Route.NAME,
                        (data: any) => ({
                            id: vm.monoOccurrence.data.doccleintcap
                        }),
                        {hidden: () => !vm.monoOccurrence.data.bfiecjcleintcap}
                    )
                ]}),
            new FormulaireItem('bfiflgexcecjcap', {largeur: 20})
        ]);

        vm.bfrMultiOccurrenceOptions = {
            fonctions:{
                nouveau: false,
                edition: false,
                supprime: false
            },
            colonnesVisibles: [
                {nom: 'cptcod', largeur: 90},
                {nom: 'cptdsc', largeur: 300},
                {nom: 'bfrmntrevtot', largeur: 126},
                {nom: 'bfrmntrevtotann', largeur: 126},
                {nom: 'bfrmntrevren', largeur: 126},
                {nom: 'bfrmntrevrencap', largeur: 126},
                {nom: 'bfrmntdu', largeur: 126}
            ]
        };
        vm.bfdMultiOccurrenceOptions = {
            fonctions: {
                nouveau: false,
                edition: false,
                supprime: false
            },
            colonnesVisibles: [
                {nom: 'cptcod', largeur: 90},
                {nom: 'cptdsc', largeur: 300},
                {nom: 'cptcodact', largeur: 90},
                {nom: 'cptdscact', largeur: 300},
                {nom: 'bfdmntdepcap', largeur: 126}
            ]
        };
    }
}
