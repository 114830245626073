import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageTH0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0028Controller() {
    const vm: IPageTH0028 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        colonnesVisibles: [
            {nom: 'rghcod', largeur: 200},
            {nom: 'rghdsc', largeur: 200},
            {nom: 'plndatdeb', largeur: 200},
            {nom: 'plndatfin', largeur: 200},
            {nom: 'plncom', largeur: 200},
            {nom: 'plnflgpub', largeur: 200}
        ]
    };
}
