import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0178 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0178Controller(mr2012Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0178 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mr2012Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(mr2012Route.NAME)]),
        colonnesVisibles: [
            {nom: 'escseq', largeur: 100},
            {nom: 'escdsc', largeur: 200},
            {nom: 'esclib', largeur: 180},
            {nom: 'escflgcreval', largeur: 130},
            {nom: 'esdcod', largeur: 120},
            {nom: 'esddsc', largeur: 200},
            {nom: 'escdscmai', largeur: 200}
        ]
    };
}
