import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageDB0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0006Controller(db0060Route: IRoute) {
    const vm: IPageDB0006 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: db0060Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.engcleint
            };
        },
        colonnesVisibles: [
            { nom: 'engpecann', largeur: 120 },
            { nom: 'engnumdoc', largeur: 120 },
            { nom: 'tydcod', largeur: 140 },
            { nom: 'tyddsc', largeur: 180 },
            { nom: 'empcod', largeur: 120 },
            { nom: 'empnom', largeur: 180 },
            { nom: 'engdsc', largeur: 200 },
            { nom: 'engdatcre', largeur: 120 }
        ],
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
