import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr2000Controller(mr0165Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr2000 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0165Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0165Route.NAME)
        ]),

        colonnesVisibles: [
            {nom: 'tdadsc', largeur: 236},
            {nom: 'vaetdatyp_desc', largeur: 158},
            {nom: 'tdaflgmsg', largeur: 95}
        ]
    };
}
