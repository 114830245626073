import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";


interface IPageTS0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;

}
/* @ngInject */
export default function ts0019Controller(MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         ts0020Route:IRoute
) {

    const vm: IPageTS0019 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            supprime: false
        },
        colonnesFixesGauche:[
            {nom: 'tpxdathredeb1', largeur: 140},
            {nom: 'tpxdathrefin2', largeur:140}
        ],
        colonnesFixesDroite:[
            {nom: 'nbrdsp', largeur: 100},
            {nom: 'nbrtot', largeur: 100,menu: [new MenuItemForage(
                    'LBL_FORAGE_DECLARATION',
                    ts0020Route.NAME,
                    (data: any) => ({ id: data.tpxcleint }),
                    {
                        fonction:'BTN_DEC',
                        disabled:(data: any) => !data.nbrtot
                    }
                )]}
        ],
        colonnesVisibles: [
            {nom: 'regroup', largeur:210},
            {nom: 'tpxflgaffemp', largeur:120},
            {nom: 'tpxflgact', largeur:70},
            {nom: 'tpxdathredebdec1', largeur: 145},
            {nom: 'tpxdathrefindec2', largeur: 140},
        ],
        formulaire: new Formulaire([
            new FormulaireGroupe( new Formulaire([
                new FormulaireItemDivider('LBL_PERIODE'),
                new FormulaireItem('tpxdathredeb'),
                new FormulaireItem('tpxdathrefin')
            ])),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItemDivider('LBL_DECLARATION'),
                new FormulaireItem('tpxdathredebdec'),
                new FormulaireItem('tpxdathrefindec')
            ])),
            new FormulaireItem('asgcleint',{largeur:33.33}),
            new FormulaireItem('tpxflgaffemp',{largeur:33.33,default:1}),
            new FormulaireItem('tpxflgact',{largeur:33.33,default:1})
        ])
    };
}



