import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0199 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    raxMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0199Params {
    id: number;
    tagcleint: number;
    eldcleint: number;
    eldcod: string;
}

/* @ngInject */
export default function Mr0199Controller(
    $stateParams: IMr0199Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass
) {
    const vm: IPageMr0199 = this;

    vm.menuBandeau = new Menu([]);
    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: false
        },
    };

    vm.raxMultiOccurrenceOptions = {
        bloc: 'rax',
        fonctions: {
            importation: false
        },

        colonnesCachees: ['flgcodtem', 'flgcodstr', 'flgcodgre', 'flgcodtaf', 'flgcodurg'],

        colonnesVisibles: [
           { nom: 'raxcod', largeur: 30 },
           { nom: 'raxdsctem', largeur: 100, hidden: (data) => $stateParams.eldcod !== 'TEM' },
           { nom: 'raxdscstr', largeur: 100, hidden: (data) => $stateParams.eldcod !== 'STR' },
           { nom: 'raxdscgre', largeur: 100, hidden: (data) => $stateParams.eldcod !== 'GRE' },
           { nom: 'raxdsctaf', largeur: 100, hidden: (data) => $stateParams.eldcod !== 'TAF' },
           { nom: 'raxdscurg', largeur: 100, hidden: (data) => $stateParams.eldcod !== 'URG' },
           { nom: 'raxdatvig', largeur: 50 },
           { nom: 'rsccod', largeur: 40 },
           { nom: 'rscdsc', largeur: 150 },
           { nom: 'rsccoddefpri', largeur: 40 },
           { nom: 'raxflgexcact', largeur: 20 }
        ],

        formulaire: new Formulaire([
            new FormulaireItem('raxcleintsegtem', { largeur: 40, hidden: $stateParams.eldcod != 'TEM' }),
            new FormulaireItem('raxcleintsegstr', { largeur: 40, hidden: $stateParams.eldcod != 'STR' }),
            new FormulaireItem('raxcleintseggre', { largeur: 40, hidden: $stateParams.eldcod != 'GRE' }),
            new FormulaireItem('raxcleintsegtaf', { largeur: 40, hidden: $stateParams.eldcod != 'TAF' }),
            new FormulaireItem('raxcleintsegurg', { largeur: 40, hidden: $stateParams.eldcod != 'URG' }),
            new FormulaireItem('raxdatvig', { largeur: 10 }),
            new FormulaireItem('rsccleint', { largeur: 30 }),
            new FormulaireItem('rsccleintdefpri', { largeur: 30 }),
            new FormulaireItem('raxflgexcact', { largeur: 10 }),
            new FormulaireItem('tagcleint', { hidden: true, default: $stateParams.tagcleint }),
            new FormulaireItem('eldcleint', { hidden: true, default: $stateParams.eldcleint }),
        ])
    };
}
