import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireTemplateSlotClass } from "../../../../../core/services/formulaire/formulaire-template-slot.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IOperateurService } from "../../../../../core/services/operateur.service";

interface IPagePf0032 extends IComponentController {
    formulaireDpm: IFormulaire;
    formulaire: IFormulaire;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    gdpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    trgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Pf0032Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Operateur: IOperateurService,
    $stateParams: any) {
    const vm: IPagePf0032 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.$onInit = function $onInit() {
        vm.formulaire = new Formulaire(vm.valeursEcran.tytcleint === 59 ? 'LBL_BLC_REM' : 'LBL_BLC_DPM', [
            new FormulaireTemplateSlot('cartePrincipale')
        ]);

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false,
                suiviModification: false,
                hideActionsCycle: true
            }
        };

        vm.gdpMultiOccurrenceOptions = {
            bloc: 'gdp',
            filtresParametres: [
                { colonne: 'gpecleint', affichage: true },
                { colonne: 'demcleint', affichage: true }
            ],
            forcerEtatPredefinisDepart: $stateParams.axe,
            etatsPredefinis: [
                {
                    nom: 'GGF',
                    filtres: [
                        {
                            // Facture globale
                            colonne: 'axe',
                            operateur: Operateur.EGALE,
                            valeur: 'GGF'
                        }
                    ],
                    colonnesVisibles: [
                        'tdscod',
                        'tdsdsc',
                        'mnttotfac',
                        'flgmntnul',
                        'gdpnumfac',
                        'ggfnomfou',
                        'reqnumdoc',
                        'eplnummat',
                        'eplnomprn',
                        'ggftyprep',
                        'gdpcom'
                    ]
                },
                {
                    nom: 'GDP',
                    filtres: [
                        {
                            // Détail par employé
                            colonne: 'axe',
                            operateur: Operateur.EGALE,
                            valeur: 'GDP'
                        }
                    ],
                    colonnesVisibles: [
                        'eplnummat',
                        'eplnomprn',
                        'tdscod',
                        'tdsdsc',
                        'mntbru',
                        'mntnet',
                        'gpenumdoc',
                        'gdpcom',
                        'gdpnumfac',
                        'reqnumdoc'
                    ]
                }],
            colonnesVisibles: [
                { nom: 'gpenumdoc', largeur: 120 },
                { nom: 'eplnummat', largeur: 130 },
                { nom: 'eplnomprn', largeur: 200 },
                { nom: 'tdscod', largeur: 130 },
                { nom: 'tdsdsc', largeur: 200 },
                { nom: 'mntbru', largeur: 126, total: true },
                { nom: 'mntnet', largeur: 126, total: true },
                { nom: 'mnttotfac', largeur: 126, total: true },
                { nom: 'flgmntnul', largeur: 120 },
                { nom: 'gdpcom', largeur: 300 },
                { nom: 'gdpnumfac', largeur: 150 },
                { nom: 'ggfnomfou', largeur: 200 },
                { nom: 'reqnumdoc', largeur: 150 },
                { nom: 'ggftyprep', largeur: 180 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tdscleint', {
                    largeur: 50,
                    resourceParams: () => ({
                        dpmcleint: $stateParams.dpmcleint,
                        gpecleint: $stateParams.gpecleint
                    })
                }),
                new FormulaireItem('ggfcom', { enableCount: true, largeur: 50 }),
                new FormulaireItem('ggfmnt'),
                new FormulaireItem('ggfflgmntnul'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ggfnumfac'),
                new FormulaireItem('ggfnomfou'),
                new FormulaireItem('reqcleint'),
                new FormulaireItem('ggftyprep', { largeur: 66, default: vm.valeursEcran.typrepdef,  disabled: $stateParams.demcleint }),
                new FormulaireItem('demcleint', {
                    largeur: 33,
                    resourceParams: () => ({
                        dpmcleint: $stateParams.dpmcleint,
                        gpecleint: $stateParams.gpecleint
                    }),
                    default: $stateParams.demcleint,
                    readonly: data => data.ggftyprep !== 2,
                    required: data => data.ggftyprep === 2
                }),
                new FormulaireItem('gpecleint', {
                    largeur: 33,
                    resourceParams: () => ({ dpmcleint: $stateParams.dpmcleint }),
                    default: $stateParams.gpecleint,
                    hidden: data => data.ggftyprep !== 0,
                    required: data => data.ggftyprep === 0
                }),
                new FormulaireItem('flgrep')
            ], { largeurDefaut: 33 })
        }

        vm.trgMultiOccurrenceOptions = {
            bloc: 'trg',
            colonnesVisibles: [
                {nom: 'tdscod', largeur: 140},
                {nom: 'tdsdsc', largeur: 200},
                {nom: 'trgprc', largeur: 120},
                {nom: 'urgcod', largeur: 130},
                {nom: 'urgdscabr', largeur: 180},
                {nom: 'unocod', largeur: 130},
                {nom: 'unodscabr', largeur: 200},
                {nom: 'cabcod', largeur: 130},
                {nom: 'cabdsc', largeur: 200},
                {nom: 'rbuannfin', largeur: 100}
            ],
            colonnesFixesDroite: [
                {nom: 'trgmnt', largeur: 120, total: true}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tdscleint'),
                new FormulaireItem('trgprc'),
                new FormulaireItem('trgmnt'),
                new FormulaireItem('unocleintrh'),
                new FormulaireItem('cabcleint'),
                new FormulaireItem('rbucleint')
            ], {largeurDefaut: 50})
        }
    }
}
