import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageMi0505 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    iimMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mi0505Controller(Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMi0505 = this;

    vm.iimMultiOccurrenceOptions = {
        bloc: 'iim',
        colonnesVisibles: [
            {nom: 'miicod'             , largeur: 120},
            {nom: 'miidsc'             , largeur: 363},
            {nom: 'iimordaff'          , largeur: 100},
            {nom: 'vaeiimimptym_dsc'   , largeur: 100},
            {nom: 'vaeiimori_dsc'      , largeur: 152}
        ],
        formulaire: new Formulaire('LBL_BLC_IIM', [
            new FormulaireItem('miicleint'   , {largeur: 40}),
            new FormulaireItem('iimordaff'   , {largeur: 20}),
            new FormulaireItem('vaeiimimptym', {largeur: 20}),
            new FormulaireItem('vaeiimori'   , {largeur: 20})
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'miecod'          , largeur: 100},
            {nom: 'mienom'          , largeur: 363},
            {nom: 'vaesysppndet_dsc', largeur: 262},
            {nom: 'mieflgact'       , largeur: 100}
        ],
        formulaire: new Formulaire('LBL_BLC_MIE', [
            new FormulaireItem('miecod'      , {largeur: 20}),
            new FormulaireItem('mienom'      , {largeur: 30}),
            new FormulaireItem('vaesysppndet', {largeur: 30}),
            new FormulaireItem('mieflgact'   , {default: 1, largeur: 20})
        ]),
        multiOccurrenceOptionsEnfant: vm.iimMultiOccurrenceOptions
    };
}
