import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageDo0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0014Controller(do0013Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass
                                        ) {
    const vm: IPageDo0014 = this;

    vm.multiOccurrenceOptions = {
        navigatePage:do0013Route.NAME,
        navigateParams:(data:any) => ({
            id: data.rglcleint,
            vaergltyp:data.vaergltyp_code
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage( do0013Route.NAME, () => ({
            }), {
                fonction: 'BOUNRGL'
            }),
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 150 },
            { nom: 'urgdscabr', largeur: 150 },
            { nom: 'rglcod', largeur: 150 },
            { nom: 'vaergltyp', largeur: 150 },
            { nom: 'rgldscabr', largeur: 150 },
            { nom: 'rglflginsdsp', largeur: 150 }
        ],
        colonnesCachees:['rglcleint','vaergltyp_code']
    };
}
