import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageIf0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function If0011Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageIf0011 = this;

    vm.multiOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('iopcod', { largeur: 12 }),
            new FormulaireItem('iopdsc', {
                largeur: 45,
                modelOptions: {
                    updateOn: 'blur'
                }
            }),
            new FormulaireItem('iopdscabr', { largeur: 30 }),
            new FormulaireItem('iopflgact', { largeur: 12, default: 1 })
        ]),
        colonnesVisibles: [
            { nom: 'iopcod', largeur: 105 },
            { nom: 'iopdsc', largeur: 525 },
            { nom: 'iopdscabr', largeur: 263 },
            { nom: 'iopflgact', largeur: 90 }
        ],
        fonctions: {
            importation: true
        }
    };
}
