import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAC0071 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0071Controller(Menu: IMenuClass,
                                        MenuItemAction: IMenuItemActionClass,
                                        Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass,
                                        FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                        MenuItemForage: IMenuItemForageClass,
                                        ecranDetails: IEcranDetailsResourcesEntite) {
    
    const vm: IPageAC0071 = this;
    const Ac0070Route = {NAME: 'secure.accueil'};
    vm.valeursEcran = ecranDetails.valeurs;
    const actions = {
        NOUVEAUX:  new Menu([new MenuItemForage('')]),
        MOREECRAN: new Menu([new MenuItemAction('LBL_BTN_UHC','BOUUHC',() => ({}),{icon: 'history'})]),
        MORELIGNE: new Menu([new MenuItemAction('LBL_BTN_COPIE','BOUCOP',() => ({}),{icon: 'content_copy'})])
    };
    
    vm.multiOccurrenceOptions = {

        navigatePage: Ac0070Route.NAME,
        
        actionsNouveaux:  actions.NOUVEAUX,
        actionsMore:      actions.MOREECRAN,
        actionsMoreLigne: actions.MORELIGNE,
        
        fonctions: {criteresSuggeresVisibles: false},

        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de', {required: (data) => data.pruselsup_a}),
            new FormulaireItem('pruselsup_a', {required: (data) => data.pruselsup_de})
        ], {largeurDefaut: 50}),
        colonnesVisibles: [
            {nom: 'dacnumdoc', largeur: 144},
            {nom: 'dacnumdocseq', largeur: 70},
            {nom: 'sysboncmd', largeur: 160},
            {nom: 'dacseqave', largeur: 90},
            {nom: 'dacsou', largeur: 130},

            {nom: 'tydcod', largeur: 90},
            {nom: 'dacdsc', largeur: 180},
            {nom: 'etddsc', largeur: 120},
            {nom: 'etddatcre', largeur: 180},
            {nom: 'refcod', largeur: 120},

            {nom: 'refnom', largeur: 200},
            {nom: 'founomabr', largeur: 200},
            {nom: 'flgcou', largeur: 90},
            {nom: 'flgnonrec', largeur: 90},
            {nom: 'flgfca', largeur: 120},
            {nom: 'peccod', largeur: 90},
            {nom: 'vaesysdevcod', largeur: 90},
            {nom: 'dacmnt', largeur: 150},

            {nom: 'mntavatax', largeur: 140},
            {nom: 'mntconat', largeur: 180},
            {nom: 'caacod', largeur: 140},
            {nom: 'caadsc', largeur: 140},
            {nom: 'etcnumdoc', largeur: 180},

            {nom: 'etcnumdocseq', largeur: 90},
            {nom: 'etcdsc', largeur: 120},
            {nom: 'intcodach', largeur: 110},
            {nom: 'intnomach', largeur: 170},
            {nom: 'intcoddem', largeur: 120},
            {nom: 'intnomdem', largeur: 200},
            {nom: 'intcodreq', largeur: 100},

            {nom: 'intnomreq', largeur: 180},
            {nom: 'dacrefint', largeur: 160},
            {nom: 'vecdaccat', largeur: 120},
            {nom: 'lipcod', largeur: 150},

            {nom: 'flgerr_b2b', largeur: 90},
            {nom: 'flgadhoc', largeur: 180},
            {nom: 'intcodcib', largeur: 180},
            {nom: 'intnomcib', largeur: 200},
            {nom: 'usrcod', largeur: 150},

            {nom: 'motcod', largeur: 180},
            {nom: 'motdsc', largeur: 220},
            {nom: 'flgtrans', largeur: 100},
            {nom: 'flgimp', largeur: 100},
            {nom: 'nibcod', largeur: 120},

            {nom: 'nibnom', largeur: 200},
            {nom: 'dacdat', largeur: 130},
            {nom: 'dacdatbc', largeur: 180},
            {nom: 'dacdatreq', largeur: 150},
            {nom: 'dacdatree', largeur: 150},

            {nom: 'dacdatdeb', largeur: 150},
            {nom: 'dacdatfin', largeur: 100},
            {nom: 'sldeng', largeur: 150},
            {nom: 'dacnumcon', largeur: 200},
            {nom: 'dpfnumdoc', largeur: 150},

            {nom: 'aaonumdoc', largeur: 150},
            {nom: 'aaotit', largeur: 180},
            {nom: 'docnumref1', largeur: 180, hidden: !vm.valeursEcran.flgref1},
            {nom: 'docnumref2', largeur: 180, hidden: !vm.valeursEcran.flgref2},
            {nom: 'docnumref3', largeur: 180, hidden: !vm.valeursEcran.flgref3},

            {nom: 'docnumref4', largeur: 180, hidden: !vm.valeursEcran.flgref4},
            {nom: 'docnumref5', largeur: 180, hidden: !vm.valeursEcran.flgref5},
            {nom: 'flgcom', largeur: 150}
        ]
    };
}
