import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePF0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0007Controller( Formulaire: IFormulaireClass,
                                          FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                          FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePF0007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'piscod', largeur: 120},
            {nom: 'pisdsc', largeur: 400},
            {nom: 'pyscod', largeur: 65},
            {nom: 'pysnomfra', largeur: 200},
            {nom: 'etacod', largeur: 65},
            {nom: 'etanomfra', largeur: 200},
            {nom: 'pisflgweb', largeur: 90}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('piscod', {largeur: 25}),
            new FormulaireItem('pisdsc', {largeur: 75}),
            new FormulaireItem('pyscleint', {largeur: 33}),
            new FormulaireItem('etacleint', {largeur: 33}),
            new FormulaireItem('pisflgweb', {largeur: 33})
        ])
    };
}
