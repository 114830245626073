import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IData } from '../../../../../core/services/data-linker.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';


interface IPageMr0003 extends IComponentController {
    formulairePla: IFormulaire;
    monoOccurrence: IMonoOccurrence,
    plcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    plcMultiOccurrence:IMultiOccurrence;
    mgmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cauMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pcaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pcaMultiOccurrence:IMultiOccurrence;
    asoMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0003Controller(mr0004Route: IRoute,
                                         mr0005Route: IRoute,
                                         mr0008Route: IRoute,
                                         mr0333Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMr0003 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Plan d'asurance collective
        vm.formulairePla = new Formulaire('LBL_BLC_PLA', [
            new FormulaireItem('placod'),
            new FormulaireItem('plaflgautfac', {
                largeur: 25,
                default:0}),
            new FormulaireItem('plaflgact', {
                largeur: 25,
                default:1}),
            new FormulaireItem('pladsc'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ttccleint',{largeur: 50}),
            new FormulaireItem('plaagemax',{largeur: 25}),
            new FormulaireItem('vaeplaagemax',{largeur: 25}),
            new FormulaireGroupeAccordeon('LBL_GRP_AUTRES_INFOS',
                new Formulaire([
                    new FormulaireItem('plaflgcptbqe',{default:0}),
                    new FormulaireItem('plaflgadmlemtous',{default:0}),

                    new FormulaireItem('planumref'),
                    new FormulaireItem('plaflgchxass',{default:0}),
                    new FormulaireItem('plaflgsaiidt',{default:0}),                    
                    new FormulaireItem('plalblidt'),
                    new FormulaireItem('planumref2'),
                    new FormulaireItem('planumref3'),
                    new FormulaireItem('planumref4'),
                    new FormulaireItem('pladatann'),
                    new FormulaireItemDivider('LBL_DIV_AGE'),
                    new FormulaireItem('plaagelimsal', {largeur: 25}),
                    new FormulaireItem('vaeplaagereg')
                ], {largeurDefaut: 33.33})
            )
        ], {largeurDefaut: 50});

        // Catégorie / Division plan d'assurance
        vm.plcMultiOccurrenceOptions = {
            bloc: 'plc',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_COND_PLC',
                    mr0004Route.NAME, () => {
                        return {
                            placleint: vm.monoOccurrence.data.placleint
                        };
                    },
                    {
                        iconButton: true,
                        icon: "add_circle",
                        onClose: () => { vm.plcMultiOccurrence.fetchDataList(); }
                    })
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_COND_ADM',
                    mr0004Route.NAME,
                    (data: any) => ({
                        id: data.plccleint,
                        placleint: vm.monoOccurrence.data.placleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => { vm.plcMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'plccod', largeur: 100},
                {nom: 'plcdsc', largeur: 100}
            ],
            colonnesCachees: [
                'plccleint'
            ]
        };

        // Groupe médical
        vm.mgmMultiOccurrenceOptions = {
            bloc: 'mgm',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'mgmcod', largeur: 100},
                {nom: 'grecod', largeur: 100},
                {nom: 'gredsc', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('mgmcod'),
                new FormulaireItem('grecleint')
            ], {largeurDefaut: 100})
        };

        // Création automatique
        vm.cauMultiOccurrenceOptions = {
            bloc: 'cau',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'placod', largeur: 100},
                {nom: 'pladsc', largeur: 100}
            ],
            colonnesCachees : ['placleint'],
            formulaire: new Formulaire([
                new FormulaireItem('placleintref', { largeur: 40, resourceParams: (data: IData) => (
                    {
                        placleint: vm.monoOccurrence.data.placleint                       
                    }
                    ) })
            ], {largeurDefaut: 100})
        };
        
        // Condition d'admissibilité
        vm.pcaMultiOccurrenceOptions = {
            bloc: 'pca',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_COND_ADM',
                    mr0005Route.NAME, () => {
                        return {
                            placleint: vm.monoOccurrence.data.placleint
                        };
                    },
                    {
                        iconButton: true,
                        icon: "add_circle",
                        onClose: () => { vm.pcaMultiOccurrence.fetchDataList(); }
                    })
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_COND_ADM',
                    mr0008Route.NAME,
                    (data: any) => ({
                        id: data.apacleint,
                        cleintref:  data.pcacleint,
                        stamnetab:  'PCA',
                        vaesystypavi: 'ASC',
                        supprime: 0,
                        typsai:  data.vaesysconadm === 'IMM' || data.vaesysconadm === 'DEL' ? 'JRS' : 'BQE'
                    }),
                    {
                        icon: 'alarm',
                        iconButton: true,
                        class: 'ex-menu-item-button--secondaire',
                        hidden: (data: any) => data.vaesysconadm === 'EXC' || data.vaesysconadm === 'MAN'
                    }
                ),
                new MenuItemEcran(
                    'LBL_COND_ADM',
                    mr0005Route.NAME,
                    (data: any) => ({
                        id: data.pcacleint,
                        placleint: vm.monoOccurrence.data.placleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => { vm.pcaMultiOccurrence.fetchDataList(); }
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'urgcod', largeur: 100},
                {nom: 'urgdscabr', largeur: 100},
                {nom: 'vaesysconadm_dsc', largeur: 100},
                {nom: 'strcod', largeur: 100},
                {nom: 'strdsc', largeur: 100}
            ],
            colonnesCachees: [
                'pcacleint',
                'apacleint',
                'vaesysconadm'
            ]
        };

        // Options / Sous-options
        vm.asoMultiOccurrenceOptions = {
            bris: 'asocleint',
            brisNiveaux: 'niveau',
            bloc: 'aso',
            colonnesVisibles: [
                'aff_gauche'
            ],
            colonnesFixesDroite: [
                'aff_droite'
            ],
            fonctions: {
                recherche: false,
                suiviModification: (data: any) => data.niveau === 1
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_OPTION',
                    mr0333Route.NAME, () => {
                        return {
                            placleint: vm.monoOccurrence.data.placleint
                        };
                    }, {
                        iconButton: true,
                        icon: "add_circle",
                        onClose: () => vm.asoMultiOccurrence.fetchDataList()
                    })
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_OPTION',
                    mr0333Route.NAME,
                    (data: any) => ({
                        id: data.asocleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        hidden: (data: any) => data.niveau !== 1,
                        onClose: () => { vm.asoMultiOccurrence.fetchDataList(); }
                    }
                )
            ])
        };
    }
}
