import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageGl0042 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
    eiiMultiOccurrence: IMultiOccurrence;
    eiiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    efcMultiOccurrence: IMultiOccurrence;
    efcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eflMultiOccurrence: IMultiOccurrence;
    eflMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gl0042Controller(gl0043Route: IRoute,
                                         gl0044Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass) {

    const vm: IPageGl0042 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulaire = new Formulaire('LBL_ETAT_FIN', [
            new FormulaireItem('eficod', {largeur: 10}),
            new FormulaireItem('efidsc', {largeur: 40}),
            new FormulaireItem('foecleint', {largeur: 40}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('efimntarr', {largeur: 20}),
            new FormulaireItem('efiflgarrsom', {largeur: 20})
        ]);

        // Onglets Présentation
        vm.eiiMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'eiiordaff', largeur: 100},
                {nom: 'deilib', largeur: 150},
                {nom: 'vaeeiitypinf_dsc', largeur: 150},
                {nom: 'eiilar', largeur: 100},
                {nom: 'vaeeiityptri_dsc', largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('eiiordaff', {largeur: 10}),
                new FormulaireItem('vaedeicle', {largeur: 20}),
                new FormulaireItem('vaeeiitypinf', {largeur: 20}),
                new FormulaireItem('eiilar', {largeur: 10}),
                new FormulaireItem('vaeeiityptri', {largeur: 20})
            ], {
                largeurDefaut: 20
            })
        }

        // Onglets Colonnes
        vm.efcMultiOccurrenceOptions = {
            bloc: 'efc',
            fonctions: {
                importation: true
            },
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'BTN_NOU',
                    gl0043Route.NAME,
                    (data: any) => ({
                        id: data.efccleint,
                        eficleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => vm.efcMultiOccurrence.fetchDataList()
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'BTN_NOU',
                    gl0043Route.NAME,
                    (data: any) => ({
                        eficleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: '',
                        iconButton: true,
                        onClose: () => vm.efcMultiOccurrence.fetchDataList()
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'efcnumcol_tri', largeur: 100},
                {nom: 'vaeefctyp_dsc', largeur: 120},
                {nom: 'efcann', largeur: 80},
                {nom: 'vaeefcper_dsc', largeur: 160},
                {nom: 'efcflgcum', largeur: 90},
                {nom: 'tyhdsc', largeur: 120},
                {nom: 'efclarcol', largeur: 100},
                {nom: 'efcdscent', largeur: 380},
                {nom: 'efcfor', largeur: 250}
            ],
            colonnesCachees: [
                'vaeefctyp'
            ]
        }

        // Onglets Ligne
        vm.eflMultiOccurrenceOptions = {
            bloc: 'efl',
            fonctions: {
                importation: true
            },
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'BTN_NOU',
                    gl0044Route.NAME,
                    (data: any) => ({
                        id: data.eflcleint,
                        eficleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => vm.eflMultiOccurrence.fetchDataList()
                    }
                )
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'BTN_NOU',
                    gl0044Route.NAME,
                    (data: any) => ({
                        eficleint: vm.monoOccurrence.id
                    }),
                    {
                        icon: '',
                        iconButton: true,
                        onClose: () => vm.eflMultiOccurrence.fetchDataList()
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'eflnumlig', largeur: 100},
                {nom: 'vaeefltyp_dsc', largeur: 130},
                {nom: 'efltxt', largeur: 200},
                {nom: 'vaeefltypimp_dsc', largeur: 120},
                {nom: 'eflfor', largeur: 200}
            ],
            colonnesCachees: [
                'vaeefltyp'
            ]
        }

    }
}
