import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageCb2004 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Cb2004Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    if0008Route: IRoute,
    db0005Route: IRoute,
    db0012Route: IRoute,
    mc0099Route: IRoute,
    mc0101Route: IRoute,
    mc0109Route: IRoute,
    cp2010Route: IRoute,
    cp2014Route: IRoute,
    mr0212Route: IRoute,
    gl0003Route: IRoute,
    cr0007Route: IRoute,
    ga0007Route: IRoute,
    cr0024Route: IRoute,
    cr0013Route: IRoute,
    cr0021Route: IRoute,
    cr0016Route: IRoute,
    in0043Route: IRoute,
    cp2012Route: IRoute,
    ac2006Route: IRoute,
    cp2015Route: IRoute,
    cb2009Route: IRoute
) {
    const vm: IPageCb2004 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire(
            [
                new FormulaireItem('type', {
                    default: $stateParams.type,
                    hidden: true
                }),
                new FormulaireItem('prucleintcri', {
                    default: $stateParams.prucleint,
                    disabled: true
                }),
                new FormulaireItem('prbcleintcri', {
                    default: $stateParams.prbcleint,
                    disabled: true
                }),
                new FormulaireItem('prvcleintcri', {
                    default: $stateParams.prvcleint,
                    disabled: true
                }),
                new FormulaireItem('el1cleintcri', {
                    default: $stateParams.el1cleint,
                    disabled: true
                }),
                new FormulaireItem('soncleintcri', {
                    default: $stateParams.soncleint,
                    disabled: true
                }),
                new FormulaireItem('cptcleintcri', {
                    default: $stateParams.cptcleint,
                    disabled: true
                }),
                new FormulaireItem('vaesysnatctbcri', {
                    default: $stateParams.vaesysnatctb,
                    disabled: $stateParams.vaesysnatctb
                }),
                new FormulaireItem('tymcleintcri', {
                    default: $stateParams.tymcleint,
                    disabled: $stateParams.tymcleint
                }),
                new FormulaireItem('tydcleintcri', {
                    default: $stateParams.tydcleint,
                    disabled: $stateParams.tydcleint
                }),
                new FormulaireItem('peccleintdebcri', {
                    default: $stateParams.peccleintdeb,
                    disabled: $stateParams.peccleintdeb,
                    hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A')
                }),
                new FormulaireItem('peccleintfincri', {
                    default: $stateParams.peccleintfin,
                    disabled: $stateParams.peccleintfin,
                    hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A')
                }),
                new FormulaireItem('peccleintdebcivcri', {
                    default: $stateParams.peccleintdeb,
                    disabled: $stateParams.peccleintdeb,
                    hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P')
                }),
                new FormulaireItem('peccleintfincivcri', {
                    default: $stateParams.peccleintfin,
                    disabled: $stateParams.peccleintfin,
                    hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P')
                })              
            ], {largeurDefaut: 33}
        ),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_INFO_FINANC',
                (rowData: any) => getForageItem(rowData),
                (rowData: any) => getParamItem(rowData),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUOUV',
                    hidden: (rowData: any) => ( rowData.tydcleintref === 200 )
                }
            ),
            new MenuItemEcran(
                'LBL_DOC_COMPT',
                cb2009Route.NAME,
                (rowData: any) => ({
                    type: $stateParams.type,
                    prucleint: $stateParams.prucleint,
                    prbcleint: $stateParams.prbcleint,
                    prvcleint: $stateParams.prvcleint,
                    el1cleint: $stateParams.el1cleint,
                    soncleint: $stateParams.soncleint,
                    cptcleint: $stateParams.cptcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    tymcleint: $stateParams.tymcleint,
                    doccleintref: rowData.doccleintref,
                    peccleintdeb: $stateParams.peccleintdeb,
                    peccleintfin: $stateParams.peccleintfin
                }),
                {
                    icon: 'launch',
                    hidden: (rowData: any) => ( rowData.tydcleintref !== 200 || $stateParams.el1cleint)
                }
            )
        ]),
        colonnesVisibles: [ 
            { nom: 'tyddsc', largeur: 160 },
            { nom: 'docaffnumdoc', largeur: 130 },
            { nom: 'docaffnumdocext', largeur: 130 },
            { nom: 'docrefcod', largeur: 120 },
            { nom: 'docrefnom', largeur: 200 },
            { nom: 'docmntsum', largeur: 126 },
            { nom: 'docaffmnt', largeur: 126 },
            { nom: 'docaffdsc', largeur: 300 },
            { nom: 'docaffdat', largeur: 120 },
            { nom: 'peccod'   , largeur: 100, hidden: ($stateParams.type !== 'PRU') },
            { nom: 'docreqcod', largeur: 130 },
            { nom: 'docreqnom', largeur: 180 }
        ],
        colonnesCachees: ['vaesysnatctb', 'ecrcod', 'docaffcleint', 'doccleintref', 'tydcleintref', 'tydflgdocgrh']
    };

    function getForageItem(rowData: any): string {
        if (rowData.ecrcod === 'IF0008-00-2') {
            return if0008Route.NAME;
        } else if (rowData.ecrcod === 'CR0013-00-2') {
            return cr0013Route.NAME;
        } else if (rowData.ecrcod === 'CR0024-00-2') {
            return cr0024Route.NAME;
        } else if (rowData.ecrcod === 'GA0007-00-2') {
            return ga0007Route.NAME;
        } else if (rowData.ecrcod === 'DB0005-00-2') {
            return db0005Route.NAME;
        } else if (rowData.ecrcod === 'DB0012-00-2') {
            return db0012Route.NAME;
        } else if (rowData.ecrcod === 'MC0099-00-2') {
            return mc0099Route.NAME;
        } else if (rowData.ecrcod === 'IN0043-00-2') {
            return in0043Route.NAME;
        } else if (rowData.ecrcod === 'MC0101-00-2') {
            return mc0101Route.NAME;
        } else if (rowData.ecrcod === 'MC0109-00-2') {
            return mc0109Route.NAME;
        } else if (rowData.ecrcod === 'CP0013-00-2') {
            return cp2010Route.NAME;
        } else if (rowData.ecrcod === 'CP0021-00') {
            return cp2012Route.NAME;
        } else if (rowData.ecrcod === 'CP0018-00') {
            return cp2015Route.NAME;
        }else if (rowData.ecrcod === 'CP0014-00') {
            return cp2014Route.NAME;
        }  else if (rowData.ecrcod === 'MR0212-00-2') {
            return mr0212Route.NAME;
        } else if (rowData.ecrcod === 'CR0021-00-2') {
            return cr0021Route.NAME;
        } else if (rowData.ecrcod === 'CR0016-00-2') {
            return cr0016Route.NAME;
        } else if (rowData.ecrcod === 'GL0003-00-2') {
            return gl0003Route.NAME;
        } else if (rowData.ecrcod === 'CR0007-00-2') {
            return cr0007Route.NAME;
        }else if (rowData.ecrcod === 'AC0010-00-2') {
            return ac2006Route.NAME;
        }
        /* else if (data.ecrcod === 'CB0053-00') {
            return cb0053Route.NAME;
        }*/
    }
    function getParamItem(rowData: any) {
        const MONOS = [
            'PD0006-00',
            'GL0020-00',
            'IF0008-00-2',
            'CP0029-00',
            'AC0070-00',
            'AC0070-01',
            'CR0013-00-2',
            'CR0024-00-2',
            'IN0014-00',
            'GT0010-00',
            'GA0007-00-2',
            'DB0005-00-2',
            'CP0014-00',
            'CP0040-00',
            'DB0012-00-2',
            'IN0043-00-2',
            'AC0037-00',
            'AC0010-02',
            'AC0010-00-2',
            'MC0109-00-2',
            'CP0013-01',
            'CP0013-00',
            'CP0021-00',
            'CP0014-00',
            'IN0035-00',
            'MR0212-00-2',
            'CR0021-00-2',
            'CR0016-00-2',
            'GL0003-00-2',
            'CP0018-00',
            'CR0007-00-2',
            'CP0026-00',
            'DB0023-00'
        ];
        if (rowData.ecrcod === 'MC0099-00-2') {
            return { id: rowData.doccleintref, indfor: 1 };
        } else if (rowData.ecrcod === 'CP0013-00-2') {
            return { id: rowData.docaffcleint };
        } else if (rowData.ecrcod === 'CP0018-00') {
            return { id: rowData.docaffcleint };
        } else if (rowData.ecrcod === 'CP0021-00') {
            return { id: rowData.docaffcleint };
        } else if (rowData.ecrcod === 'CP0014-00') {
            return { id: rowData.docaffcleint };
        } else if (rowData.ecrcod === 'CB0053-00') {
            return { id: rowData.doccleintref, prucleint: rowData.prucleint, vaesysnatctb: rowData.vaesysnatctb };
        } else if (rowData.ecrcod === 'MC0101-00-2') {
            return { id: rowData.doccleintref, doccleintref: rowData.doccleintref };
        } else if (rowData.ecrcod === 'CP0040-00') {
            return { id: rowData.prucleint };
        }else if (rowData.ecrcod === 'AC0010-00-2') {
            return { id: rowData.docaffcleint };
        }  else if (MONOS.includes(rowData.ecrcod) && rowData.tydcleintref !== 135) {
            return { id: rowData.doccleintref };
        }
    }

}
