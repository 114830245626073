import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
interface IPageMr0195 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}
interface IMr0195Params {
    tagcleint: number;
}
/* @ngInject */
export default function Mr0195Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IMr0195Params
) {
    const vm: IPageMr0195 = this;

        vm.menuBandeau = new Menu([]);
        vm.valeursEcran = ecranDetails.valeurs;
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };
        vm.formulaireTsu = new Formulaire(            
            [
                new FormulaireItem('tagcleintsub', { largeur: 50 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsuflgexcavs', { largeur: 50 }),
                new FormulaireItemDivider('LBL_DIV_TSU'),
                new FormulaireItem('vaetsupalrem', { largeur: 100 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('remcleint', { largeur: 100 }),
                new FormulaireItem('tagcleint', { largeur: 100, default: $stateParams.tagcleint })
            ],
            { largeurDefaut: 25 }
        );
}
