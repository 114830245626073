/**
 * Composant représentant l'historique d'un enregistrement, habituellement utilisé dans les actions d'une carte
 */
import {IComponentOptions, module} from 'angular';
import ButtonHistoriqueController from './ex-button-historique.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-button-historique', []).component('exButtonHistorique', ButtonHistoriqueFactory());

function ButtonHistoriqueFactory(): IComponentOptions {
    return {
        bindings:
            Binding(
                Binding.MULTI_OCCURRENCE_OPTIONS,
                {
                    date: '<',
                    fonction: '@',
                    simple: '<?',
                    lblTitre: '@?',
                    largeurModale: '@?',
                    id: '<?'
                }
            ),
        require: {
            monoOccurrenceCtrl: '?^^exMonoOccurrence',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: ButtonHistoriqueController,
        controllerAs: 'vm',
        template: require('./ex-button-historique.html')
    };
}
