import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageDb0060 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    formulaire: IFormulaire;
}
/* @ngInject */
export default function Db0060Controller(
    db0012Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass) {
    const vm: IPageDb0060 = this;
    vm.menuBandeau = new Menu([]);
    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        }
    };
    vm.formulaire = new Formulaire(
        'LBL_BLC_Eng',
        [
            new FormulaireItem('engnumdoc', { largeur: 25 }),
            new FormulaireItem('tydcoddsc', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('engpecann', { largeur: 25 }),
            new FormulaireItem('engdatcre', { largeur: 25 }),
            new FormulaireItem('engdsc', { largeur: 33 }),
            new FormulaireItem('empcodnom', { largeur: 50 })
        ],
        { largeurDefaut: 30 }
    );
    vm.enpMultiOccurrenceOptions = {
        navigatePage: db0012Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.doccleint
            };
        },
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'enpnumdocseq', largeur: 60 },
            { nom: 'peccod', largeur: 100 },
            { nom: 'enpdatcre', largeur: 100 },
            { nom: 'datmod', largeur: 115 },
            { nom: 'etddsc', largeur: 180 },
            { nom: 'enpmnteng', largeur: 126 },
            { nom: 'enpflgmsg', largeur: 130 },
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 262 },
            { nom: 'enpdsc', largeur: 230 }
        ],
        colonnesCachees: ['doccleint']
    };
}
