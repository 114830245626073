import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPagePD0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0022Controller() {
    const vm: IPagePD0022 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        colonnesFixesGauche: [
            {nom: 'loccod', largeur: 200},
            {nom: 'vecpmiendaut', largeur: 200},
            {nom: 'vecpmiendautdsc', largeur: 200},
            {nom: 'veclocnivdsc', largeur: 200},
            {nom: 'vaesyspdatyp_dsc', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'vaeraitypsrc_dsc', largeur: 200},
            {nom: 'raicod', largeur: 200},
            {nom: 'raidsc', largeur: 200},
            {nom: 'pmpqteactmax', largeur: 200},
            {nom: 'pmpqteliment', largeur: 200},
            {nom: 'codresp', largeur: 200},
            {nom: 'nomresp', largeur: 200},
            {nom: 'locdatderchg', largeur: 200},
            {nom: 'locdatimpr', largeur: 200}
        ]
    };
}
