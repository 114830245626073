import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0056',
    ecrans: ['00-2'],
    params: ['?dracleint',
             '?dcocleint',
             '?dchcleint',
             '?demcleint',
             '?rcecleintref']
});
