import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService, IStateService } from 'angular-ui-router';

interface IPageAC0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0018Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass,
    ac0023Route: IRoute,
    $stateParams: IStateParamsService,
    $state: IStateService,
    exDateFilter: IFilterExDate
) {
    const vm: IPageAC0018 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    const ac0010Route = { NAME: 'secure.accueil' };
    const ac0033Route = { NAME: 'secure.accueil' };

    const docAchat = new MenuItemForage(
        'BTN_FORAGE_ABSENCE',
        ac0010Route.NAME,
        (rowData: any) => ({
            id: rowData.daccleint
        }),
        {
            fonction: 'BTN_DOCACHAT'
        }
    );

    const docReception = new MenuItemForage(
        'BTN_DOCRECEPTION',
        ac0033Route.NAME,
        (rowData: any) => ({
            id: rowData.daccleint
        }),
        {
            fonction: 'BTN_DOCRECEPTION',
            hidden: (rowData: any) => (rowData.indrecu === 'Oui')
        }
    );

    const lancerRelance = new MenuItemAction(
        'LBL_LANCER_RELANCE',
        'BOUREL',
        () => ({}),
        {
            icon: 'add',
            fonction: 'BOUREL',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_LANCER_RELANCE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 40,
                formulaire: new Formulaire([
                    new FormulaireItem('dordatnew', {
                        largeur: 50,
                        required: true,
                        default: exDateFilter(new Date())
                    }),
                    new FormulaireItem('vecdortyprelnew', {
                        largeur: 50,
                        required: true
                    }),
                    new FormulaireItem('dornotnew', {
                        largeur: 100,
                        required: true
                    }),
                    new FormulaireItemDivider('LBL_COURRIEL'),
                    new FormulaireItem('flgdem', {
                        largeur: 50
                    }),
                    new FormulaireItem('flgreq', {
                        largeur: 50
                    }),
                    new FormulaireItem('intcleintcou', {
                        largeur: 50
                    }),
                    new FormulaireItem('adrcou', {
                        largeur: 50
                    })
                ])
            },
            disabled: (rowData: any) => !(rowData.dacflgrel)
        }
    );

    const afficheDacdsc = new MenuItemAction(
        'LBL_INSTRUCTION_ACHAT',
        'BOUIAC',
        () => ({}),
        {
            icon: 'launch',
            fonction: 'BOUIAC',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_INSTRUCTION_ACHAT',
                largeurFormulaire: 30,
                formulaire: new Formulaire([
                    new FormulaireItem('dacinstmp', {
                        largeur: 100,
                        readonly: true,
                        default: (rowData: any) => rowData.dacins
                    })
                ])
            }
        }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('intcleintachcri', {
                        required: true,
                        disabled: () => !vm.valeursEcran.flgmodach
                    }),
                    new FormulaireItem('caacleintcri'),
                    new FormulaireItem('tfocleintcri'),
                    new FormulaireItem('datreqcri'),
                    new FormulaireItem('indreccri'),
                    new FormulaireItem('indiaccri')
                ], { largeurDefaut: 100 }), { largeur: 33.33 }
            ),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('refcleintcri'),
                    new FormulaireItem('vecdaccatcri'),
                    new FormulaireItem('tydcleintcri'),
                    new FormulaireItem('pijcri', {
                        default: 2,
                        required: true
                    }),
                    new FormulaireItem('indnonimpcri')
                ], { largeurDefaut: 100 }), { largeur: 33.33 }
            ),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('etacri', {
                        direction: 'vertical',
                        default: '0110',
                        required: true
                    }),
                    new FormulaireItem('tricri', {
                        direction: 'vertical',
                        default: 1,
                        required: true
                    })
                ], { largeurDefaut: 50 }), { largeur: 33.33 }
            )
        ], { largeurDefaut: 33.33 }),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_BOU_CHANGE_ACH',
                'BOUCHGACH',
                () => ({}),
                {
                    icon: 'change_circle',
                    fonction: 'BOUCHGACH',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BOU_CHANGE_ACH',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('intcleintachnew', { required: true })
                        ], {
                            largeurDefaut: 100
                        })
                    }
                }
            ),
            new MenuItemAction(
                'LBL_ECLATEMENT_DAC',
                'BOUECLDAC',
                () => ({}),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUECLDAC',
                    successAction: (retour: any) => {
                        $state.go(ac0023Route.NAME, {
                            id: retour.dtmcleintnew,
                            daccleint: retour.daccleintnew,
                            dtmcleint: retour.dtmcleintnew,
                            type: 'ECLATEMENT',
                            menuId: $stateParams.menuId
                        });
                    }
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'dacnumdoc', largeur: 160, menu: [docAchat] }
        ],
        colonnesVisibles: [
            { nom: 'sysboncmd', largeur: 120 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 220 },
            { nom: 'dacdat', largeur: 126 },
            { nom: 'dacmnt', largeur: 120 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'dacdatreq', largeur: 120 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 180 },
            { nom: 'inttel', largeur: 120 },
            { nom: 'iacdsc', largeur: 230, menu: [afficheDacdsc] },
            { nom: 'dacdatrec', largeur: 126, menu: [docReception] },
            { nom: 'indrecu', largeur: 100 },
            { nom: 'dacdatrel', largeur: 126 },
            { nom: 'dacnbrrel', largeur: 120, menu: [lancerRelance] },
            { nom: 'dacflgtrm', largeur: 120 },
            { nom: 'dacdatimp', largeur: 126 },
            { nom: 'dacflgpij', largeur: 100 },
        ],
        colonnesCachees: ['daccleint', 'dacflgrel', 'dacins']
    };
}
