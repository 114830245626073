import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0036 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0036Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0036 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'crqcod', largeur: 125 },
            { nom: 'crqdsc', largeur: 215 },
            { nom: 'vaetypcru_dsc', largeur: 135 },
            { nom: 'crqcodref', largeur: 135 },
            { nom: 'crqdscref', largeur: 195 },
            { nom: 'crqflgstu', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('crqcod'),
            new FormulaireItem('crqdsc', {
                largeur: 40
            }),
            new FormulaireItem('vaetypcru', {
                largeur: 30
            }),
            new FormulaireItem('crqcleintref', {
                largeur: 30,
                resourceParams: (data: any) => ({
                    vaetypcru: data.vaetypcru
                })
            }),
            new FormulaireItem('crqflgstu')
        ],
            {
                largeurDefaut: 20
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
