import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemClass} from '../../../../../core/services/menu/menu-item.service';
import {IStateParamsService} from 'angular-ui-router';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRR0500 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0500Controller(Menu: IMenuClass,
                                         rr0501Route: IRoute,
                                         MenuItem: IMenuItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         $stateParams: IStateParamsService,
                                         rr0506Route : IRoute) {
    const vm: IPageRR0500 = this;
    const actionsMoreLigne = new Menu([
        new MenuItemForage(
            "BTN_FOR_NAV",
            rr0506Route.NAME,
            (data: any) => ({
                id: data.fepcleint,
            }),
            {
                icon: "launch_app",
                iconButton: false,
            }
        ),
    ]);
    vm.multiOccurrenceOptions = {
        actionsMoreLigne,
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0501Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.fepcleint,
                typsai: rowData.typsai
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(
                'BTN_FEUILLE',
                rr0501Route.NAME,
                () => ({
                    typsai: 'F',
                    demcleint: $stateParams.employe
                }),
                {
                    icon: '', iconButton: false
                }),
            new MenuItemForage(
                'BTN_AVANCE',
                rr0506Route.NAME,
                () => ({
                    typsai: 'A'
                }),
                {
                    icon: '', iconButton: false
                })
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe},
            {nom: 'tpfdsc', largeur: 170},
            {nom: 'fepflgcal', largeur: 90},
            {nom: 'ppacod', largeur: 90},
            {nom: 'unpcod', largeur: 90},
            {nom: 'unpdsc', largeur: 262},
            {nom: 'fepcod', largeur: 125},
            {nom: 'mopcod', largeur: 94},
            {nom: 'mopdsc', largeur: 262},
            {nom: 'fepflgexccou', largeur: 90},
            {nom: 'fepflgexcanncou', largeur: 90}
        ],
        colonnesCachees: [
            'typsai'
        ]
    };
}
