import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';

interface IPageMc1012 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc1012Controller() {
    const vm: IPageMc1012 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 100 },
            { nom: 'usrnomcre', largeur: 180 },
            { nom: 'datcre', largeur: 126 },
            { nom: 'dhsnumrescd', largeur: 180 },
            { nom: 'dhsnumresca', largeur: 180 }
        ]
    };
}

