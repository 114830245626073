import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0013Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0013 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'atvcod', largeur: 112}
        ],
        colonnesVisibles: [
            {nom: 'atvdscabr', largeur: 200},
            {nom: 'atvdsc', largeur: 280},
            {nom: 'pdicod', largeur: 95},
            {nom: 'pdidsc', largeur: 200},
            {nom: 'cptcod', largeur: 110},
            {nom: 'cptdsc', largeur: 200},
            {nom: 'gaccod', largeur: 135},
            {nom: 'gacdsc', largeur: 220},
            {nom: 'atvindpri', largeur: 92},
            {nom: 'atvflgact', largeur: 74}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('atvcod', {required: true}),
            new FormulaireItem('atvdscabr', {required: true}),
            new FormulaireItem('atvdsc', {required: true}),
            new FormulaireItem('pdicleint'),
            new FormulaireItem('cptcleint'),
            new FormulaireItem('gaccleint', {required: true}),
            new FormulaireItem('atvindpri'),
            new FormulaireItem('atvflgact', {default: 1})
        ], {largeurDefaut: 33.33}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
