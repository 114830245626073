import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2007',
    ecrans: ['00-2'],
    params: [
        '?niveau',
        '?prucleint',
        '?elecleint',
        '?vaesysnatctb',
        '?peccleintdeb',
        '?peccleintfin',
        '?peccoddeb',
        '?peccodfin',
        '?anncivfin',
        '?indcleint']
});
