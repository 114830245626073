import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
interface IPageAs0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    ftpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ftpMultiOccurrence: IMultiOccurrence;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
    menuBandeau: IMenu;
}
/* @ngInject */
export default function As0016Controller(
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItem: IFormulaireItemClass,
    Formulaire: IFormulaireClass,
    Pagination: IPaginationClass
) {
    const vm: IPageAs0016 = this;
    vm.menuBandeau = new Menu([]);
    vm.valeursEcran = ecranDetails.valeurs;
    //Bloc Horaire-type
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'dlcdat', largeur: 105 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'aftdsc', largeur: 105 },
            { nom: 'actcod', largeur: 105 },
            { nom: 'actdsc', largeur: 105 },
            { nom: 'dlcqte', largeur: 105 }
        ],
        multiOccurrenceOptionsEnfant: vm.ftpMultiOccurrenceOptions
    };
    vm.ftpMultiOccurrenceOptions = {
        bloc: 'ftp',
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        colonnesVisibles: [
            { nom: 'ftpnumdoc', largeur: 94 },
            { nom: 'tpscod', largeur: 94 },
            { nom: 'tpsdsc', largeur: 94 },
            { nom: 'quantite', largeur: 94 },
            { nom: 'jour', largeur: 94 },
            { nom: 'ecldsceta', largeur: 94 }
        ]
    };
}
