import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageCP0036 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0036Controller(Menu: IMenuClass) {
    const vm: IPageCP0036 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'connumdoc', largeur: 100},
            {nom: 'connumdocseq', largeur: 65},
            {nom: 'tydcod', largeur: 65},
            {nom: 'tyddsc', largeur: 180},
            {nom: 'etddsc', largeur: 152},
            {nom: 'coaann', largeur: 72},
            {nom: 'coaseqave', largeur: 91},
            {nom: 'refcod', largeur: 105},
            {nom: 'refnom', largeur: 247},
            {nom: 'conflgnonren', largeur: 115},
            {nom: 'intcod', largeur: 116},
            {nom: 'intnom', largeur: 217}
        ],
        colonnesFixesDroite: [
            {nom: 'coamnt', largeur: 126}
        ]
    };
}
