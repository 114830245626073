import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRE0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re0001Controller(Menu: IMenuClass,
                                         re0002Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRE0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },     
        navigatePage: re0002Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(re0002Route.NAME)
        ]),   
        //navigatePage: re0002Route.NAME,
        //navigateParams: (data:any) => {id : data.rtocleint},
        //actionsNouveaux: new Menu([
        //    new MenuItemForage(re0002Route.NAME)
        //]),        
        colonnesVisibles: [
            {nom: 'rtocod', largeur: 200},
            {nom: 'rtodsc', largeur: 200},
            {nom: 'rtodatdeb', largeur: 200},
            {nom: 'rtodatfin', largeur: 200},
            {nom: 'vaertotyp_dsc', largeur: 200},
            {nom: 'vaertostu_dsc', largeur: 200}
        ]
    };
}
