import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0082 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0082Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0082 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'temcod', largeur: 89},
            {nom: 'temdscabr', largeur: 228},
            {nom: 'crxcod', largeur: 125},
            {nom: 'crxdsc', largeur: 228},
            {nom: 'gcrcod', largeur: 125},
            {nom: 'gcrdsc', largeur: 228},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ocrcleintref', {required: true}),
            new FormulaireItem('crxcleint', {required: true})
        ])
    };
}
