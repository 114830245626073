import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0177 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0177Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0177 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('esdcod', { largeur: 25 }),
            new FormulaireItem('esddsc', { largeur: 50 }),
            new FormulaireItem('ecrcleint', { largeur: 25 })
        ]),
        colonnesVisibles: [
            { nom: 'esdcod', largeur: 140 },
            { nom: 'esddsc', largeur: 80 },
            { nom: 'ecrcod', largeur: 260 },
            { nom: 'ecrdsc', largeur: 260 }
        ]
    };
}