import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IStateParamsService } from 'angular-ui-router';
import { IComponentController } from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';

interface IPageMc1029 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireMcm: IFormulaire;
    bamMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc1029Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageMc1029 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        
        vm.formulaireMcm = new Formulaire('LBL_BLC_MCM', [
            new FormulaireItem('mcmcleintref', { default: $stateParams.mcmcleintref, hidden: true }),
            new FormulaireItem('vaemcment', { default: $stateParams.vaemcment, hidden: true }),
            new FormulaireItem('mcmdsc', { largeur: 100, enableCount: true })
        ]);

        vm.bamMultiOccurrenceOptions = {
            bloc: 'bam',
            fonctions: {
                suiviModification: false
            },
            actionsSelection: new Menu([
                new MenuItemSelection('LBL_BTN_INSERER_MSG', 'BOUINSMSG', {
                    selection: {
                        nom: 'inserer'
                    },
                    successAction: () => { vm.monoOccurrence.fetchData(); vm.$onInit() }
                })
            ]),
            colonnesVisibles: [
                {nom: 'bamcod', largeur: 80},
                {nom: 'bammsg', largeur: 700}
            ]
        };
    };
}
