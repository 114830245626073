import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageGS0062 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0062Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGS0062 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            importation: true
        },
        colonnesFixesGauche: [
            {nom: 'usrcod', largeur: 110},
            {nom: 'usrnom', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'usradrele', largeur: 270},
            {nom: 'usrsigaut', largeur: 190},
            {nom: 'usrdatfin', largeur: 110},
            {nom: 'impdsc', largeur: 210},
            {nom: 'usrflgchgpas', largeur: 110},
            {nom: 'usrflgsecpru', largeur: 94},
            {nom: 'usrflgsecuna', largeur: 94},
            {nom: 'usrflgsecmag', largeur: 94},
            {nom: 'usrflgsecsrv', largeur: 94},
            {nom: 'usrflgsecgeb', largeur: 94},
            {nom: 'usrflgsectyd', largeur: 94},
            {nom: 'usrflgsecurg', largeur: 94},
            {nom: 'usrflgsecpos', largeur: 94},
            {nom: 'usrflgsecuno', largeur: 94},
            {nom: 'usrflgverfingrh', largeur: 94},
            {nom: 'ciecod', largeur: 110},
            {nom: 'intcod', largeur: 110},
            {nom: 'empcod', largeur: 110},
            {nom: 'pradsc1', largeur: 170},
            {nom: 'pradsc2', largeur: 170},
            {nom: 'pradsc3', largeur: 170},
            {nom: 'langue', largeur: 94}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('usrcod', {modelOptions:{updateOn: 'blur'}}),
            new FormulaireItem('usrnom'),
            new FormulaireItem('usradrele'),
            new FormulaireItem('usrsigaut'),
            new FormulaireItem('usrdatfin'),
            new FormulaireItem('impcleint'),
            new FormulaireItem('motdepasse',{maxlength: 128}),
            new FormulaireItem('usrflgchgpas'),
            new FormulaireItem('usrflgsecpru'),
            new FormulaireItem('usrflgsecuna'),
            new FormulaireItem('usrflgsecmag'),
            new FormulaireItem('usrflgsecsrv'),
            new FormulaireItem('usrflgsecgeb'),
            new FormulaireItem('usrflgsectyd'),
            new FormulaireItem('usrflgsecurg'),
            new FormulaireItem('usrflgsecpos'),
            new FormulaireItem('usrflgsecuno'),
            new FormulaireItem('usrflgverfingrh'),
            new FormulaireItem('ciecle'),
            new FormulaireItem('intcleint'),
            new FormulaireItem('empcleint', {nombreLibellesDetailsCacher: 2}),
            new FormulaireItem('pracleint1'),
            new FormulaireItem('pracleint2'),
            new FormulaireItem('pracleint3'),
            new FormulaireItem('vaeusrlng')
        ],{largeurDefaut: 25})
    };
}
