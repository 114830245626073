import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCr0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Cr0019Controller(
    cr0016Route: IRoute,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass) {

    const vm: IPageCr0019 = this;
    vm.valeursEcran = ecranDetails;

    vm.multiOccurrenceOptions = {
        navigatePage: cr0016Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(cr0016Route.NAME)]),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup', { hidden: () => vm.valeursEcran.valeurs.vaehldtypimp !== 1 }),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        colonnesVisibles: [
            { nom: 'cclnumdoc', largeur: 120 },
            { nom: 'tydcod', largeur: 80 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 230 },
            { nom: 'ccldatdeb', largeur: 126 },
            { nom: 'ccldatfin', largeur: 126 },
            { nom: 'ccldsc', largeur: 300 },
            { nom: 'cclnumref', largeur: 160 },
            { nom: 'cclmnt', largeur: 110 },
            { nom: 'vaesysdevcod_dsc', largeur: 120 },
            { nom: 'cifcod', largeur: 100 },
            { nom: 'usrcod', largeur: 120 }
        ]
    };
}
