import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0282 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0282Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0282 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'cgncod'   , largeur: 160},
            {nom: 'cgndsc'   , largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cgncod'),
            new FormulaireItem('cgndsc')
        ])
    };
}
