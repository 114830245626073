import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAC0090 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceBIROptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0090Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAC0090 = this;

    vm.multiOccurrenceBIROptions = {
        bloc: 'bir',
        colonnesVisibles: [
            {nom: 'vecsysquamel', largeur: 100},
            {nom: 'vecsysquamel_dsc', largeur: 236},
            {nom: 'birprx', largeur: 140},
            {nom: 'birdatvig', largeur: 140}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vecsysquamel'),
            new FormulaireItem('birprx'),
            new FormulaireItem('birdatvig')
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vaesysrefbit', largeur: 135},
            {nom: 'vaedsc', largeur: 300}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceBIROptions
    };
}
