/**
 * Composant représentant les fonctions transversales Cycle
 *
 */
import {IComponentOptions, module} from 'angular';
import FonctionsTransversalesController from './ex-fonctions-transversales.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('core.components.ex-fonctions-transversales', []).component('exFonctionsTransversales', FonctionsTransversalesFactory());

function FonctionsTransversalesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                monoOccurrence: '<'
            }
        ),
        controller: FonctionsTransversalesController,
        controllerAs: 'vm',
        template: require('./ex-fonctions-transversales.html')
    };
}
