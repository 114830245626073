import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0132 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0132Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0132 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'gsncod'          , largeur: 160},
            {nom: 'gsndsc'          , largeur: 300},
            {nom: 'vaesysnatctb_dsc', largeur: 160}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gsncod', {largeur: 20}),
            new FormulaireItem('gsndsc'),
            new FormulaireItem('vaesysnatctb')
        ], {largeurDefaut: 40})
    };
}
