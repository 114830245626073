import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";

interface IPageMC0183 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTve: IFormulaire;
    veiMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc0183Params {
    id: string;
    tvicleint: string
}

/* @ngInject */
export default function Mc0183Controller($stateParams: IMc0183Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMC0183 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireTve = new Formulaire('LBL_BLC_TVE', [
            new FormulaireItem('tvicleint',{default: $stateParams.tvicleint}),
            new FormulaireItem('empcleint'),

            new FormulaireItem('demcleint',{largeur:33.33}),
            new FormulaireItem('tvenom',{largeur:33.33}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('tvepct'),
            new FormulaireItem('tvemnt'),
            new FormulaireItem('tvecom',{largeur:50, enableCount: true})

        ], {
            largeurDefaut: 25
        });

        // Ventilation
        vm.veiMultiOccurrenceOptions = {
            bloc: 'vei',

            colonnesFixesGauche: [
                {nom: 'tagcod', largeur: 110},
                {nom: 'tagdscabr', largeur: 240}
            ],
            colonnesVisibles: [
                {nom: 'ppacod', largeur: 86},
                {nom: 'ppadatdeb', largeur: 98},
                {nom: 'ppadatfin', largeur: 98},
                {nom: 'veimnt', largeur: 110},
                {nom: 'folcod', largeur: 110},
                {nom: 'foldsc', largeur: 150},
                {nom: 'rfecod', largeur: 150},
                {nom: 'province', largeur: 175}
            ],

            formulaire: new Formulaire([
                new FormulaireItem('tagcleint'),
                new FormulaireItem('ppacleint',{
                    resourceParams: () => ({
                        demcleint: vm.monoOccurrence.data.demcleint
                    })
                }),
                new FormulaireItem('veimnt'),
                new FormulaireItem('folcleint'),
                new FormulaireItem('rfecleint'),
                new FormulaireItem('vaesyscodprv')

            ], {largeurDefaut: 33.33})

        };


    }
}
