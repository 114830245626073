import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGS0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0031Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGS0031 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'usicod', largeur: 30},
            {nom: 'usidsc', largeur: 100},
            {nom: 'vaeusient_dsc', largeur: 100},
            {nom: 'vaeusipru_dsc', largeur: 100},
            {nom: 'vaeusicpt_dsc', largeur: 100},
            {nom: 'vaeusiel1_dsc', largeur: 100},
            {nom: 'vaeusiprs_dsc', largeur: 100},
            {nom: 'vaeusiuna_dsc', largeur: 100},
            {nom: 'vaeusicif_dsc', largeur: 100}
        ],

        formulaire: new Formulaire([
            new FormulaireItem('usicod', {largeur: 30}),
            new FormulaireItem('usidsc', {largeur: 30}),
            new FormulaireItem('vaeusient', {largeur: 30}),
            new FormulaireItem('vaeusipru', {largeur: 30}),
            new FormulaireItem('vaeusicpt', {largeur: 30}),
            new FormulaireItem('vaeusiel1', {largeur: 30}),
            new FormulaireItem('vaeusiprs', {largeur: 30}),
            new FormulaireItem('vaeusiuna', {largeur: 30}),
            new FormulaireItem('vaeusicif', {largeur: 30})
        ])
    };
}
