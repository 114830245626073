import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import IStateService = angular.ui.IStateService;

interface IPageMr0100 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulairePaq: IFormulaire;
    pabMultiOccurrence: IMultiOccurrence;
    pabMultiOccurrenceOptions: IMultiOccurrenceOptions;
    txsMultiOccurrence: IMultiOccurrence;
    txsMultiOccurrenceOptions: IMultiOccurrenceOptions;    

}

/* @ngInject */
export default function Mr0100Controller(mr0100Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Pagination: IPaginationClass,
                                         $state: IStateService,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass) {

    const vm: IPageMr0100 = this;
    vm.$onInit = $onInit;

    function $onInit() {

        // Gestion du bouton pour la copie
        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_COPIE',
                    'BOUCOP',
                    (data: any) => ({ paqcleint: data.paqcleint }),
                    {
                        icon: 'file_copy',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_COPIE',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('t_datvig', {
                                    required: true
                                })
                            ])
                        },
                        successAction: (data: any) => {
                            $state.go(mr0100Route.NAME, {
                                id: data.paqcleintnew
                            });
                        }
                    }
                )
            ])
        };

        // Formulaire principal (Paramètre retenue Québec)
        vm.formulairePaq = new Formulaire('LBL_BLC_PAQ', [
            new FormulaireGroupe('', new Formulaire([
                new FormulaireItem('paqdatvig'),
                new FormulaireItem('paqtp1mntbas'),

                new FormulaireItemDivider('LBL_IMPOT_QC'),
                new FormulaireItem('paqimpfcte01',{largeur: 50}),
                new FormulaireItem('paqimpfctq'  ,{largeur: 50}),
                new FormulaireItem('paqimpfctq01',{largeur: 50}),
                new FormulaireItem('paqimpmaxq'  ,{largeur: 50}),

                new FormulaireItemDivider('LBL_DEDUCTION'),
                new FormulaireItem('paqimpfcth'),
                new FormulaireItem('paqimpmaxh'),

                new FormulaireItemDivider('LBL_GRATIFICATION'),
                new FormulaireItem('paqgrarevmin'),
                new FormulaireItem('paqgrataumin')

            ],{largeurDefaut: 20}), { largeur: 50 }),
            new FormulaireGroupe('LBL_RQAP', new Formulaire([
                new FormulaireItem('paqrqamga'),
                new FormulaireItem('paqrqatauemp'),
                new FormulaireItem('paqrqatauemr'),

                new FormulaireItemDivider('LBL_RRQ'),
                new FormulaireGroupe('LBL_CB', new Formulaire([
                new FormulaireItem('paqrrqmga'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('paqrrqexm', {largeur: 30}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('paqrrqtauemp'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('paqrrqtaubas', {largeur: 60} ),
            ]), {largeur: 60}),
            new FormulaireGroupe('LBL_SUP', new Formulaire([
                new FormulaireItem('paqrrqmaxsup'),                                
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('paqrrqtausup'),
            ]),  {largeur: 40}),
                new FormulaireItemDivider('LBL_CSST'),
                new FormulaireItem('paqcstmga'),
                new FormulaireItem('paqcstprcind'),

                new FormulaireItemDivider('LBL_CNT'),
                new FormulaireItem('paqcntmga'),
                new FormulaireItem('paqcnttauemr'),

                new FormulaireItemDivider('LBL_FSS'),
                new FormulaireItem('paqfsstauemr', { largeur: 50 })

            ],{largeurDefaut: 20}), { largeur: 50 })                
        ]);

        // Borne
        vm.pabMultiOccurrenceOptions = {
            bloc: 'pab',
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 15]
            }),
            colonnesVisibles: [
                {nom: 'pabborinf', largeur: 130},
                {nom: 'pabtauimp', largeur: 120},
                {nom: 'pabval', largeur: 130}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pabborinf', {largeur: 30}),
                new FormulaireItem('pabtauimp', {largeur: 25}),
                new FormulaireItem('pabval', {largeur: 30})
            ])
        };

         // Borne santé
         vm.txsMultiOccurrenceOptions = {
            bloc: 'txs',
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 15]
            }),
            colonnesVisibles: [
                {nom: 'txsborinf', largeur: 130},
                {nom: 'txscontribmax', largeur: 155},
                {nom: 'txspourcentage', largeur: 130}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('txsborinf', {largeur: 30}),
                new FormulaireItem('txscontribmax', {largeur: 25}),
                new FormulaireItem('txspourcentage', {largeur: 30})
            ])
        };

    }

}
