import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPagePc0011 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceSceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pc0011Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass,
                                        FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPagePc0011 = this;

    vm.multiOccurrenceSceOptions = {
        bloc: 'sce',
        colonnesVisibles: [
            { nom: 'scecod', largeur: 60 },
            { nom: 'scelib', largeur: 60 },
            { nom: 'scedsc', largeur: 60 },
            { nom: 'vaecnvide_dsc', largeur: 60 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('scecod', {largeur: 30}),
            new FormulaireItem('scelib', {largeur: 30}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('scedsc', {largeur: 50}),
            new FormulaireItem('vaecnvide', {largeur: 50})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'vaescftypdoc_dsc', largeur: 60},
            {nom: 'scfcod', largeur: 60},
            {nom: 'scfseq', largeur: 20},
            {nom: 'scfdsc', largeur: 60},
            {nom: 'scfflgpri', largeur: 60}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceSceOptions,

        formulaire: new Formulaire([
            new FormulaireItem('vaescftypdoc', {largeur: 30}),
            new FormulaireItem('scfcod', {largeur: 30}),
            new FormulaireItem('scfseq', {largeur: 30}),
            new FormulaireItem('scfdsc', {largeur: 30}),
            new FormulaireItem('scfflgpri', {largeur: 30})
        ])

    };
}
