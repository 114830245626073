import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageAl2003 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tcnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Al2003Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService
) {
    const vm: IPageAl2003 = this;
    //Calcule des avantages

    vm.formulaireRce = new Formulaire('Avantages sociaux', [
        new FormulaireItem('rcecleint', {
            largeur: 50
        }),
        new FormulaireItem('tdeflgres'),
        new FormulaireItem('tdlcleint', {
            default: $stateParams.tdlcleint
        })
    ]);

    vm.tcnMultiOccurrenceOptions = {
        bloc: 'tcn',
        colonnesVisibles: [
            { nom: 'cotcod', largeur: 170 },
            { nom: 'cotdsc', largeur: 170 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cotcleint')
        ])
    };
}
