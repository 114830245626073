import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGs2002 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    gfdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs2002Controller(
    Formulaire: IFormulaireClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageGs2002 = this;

    vm.menuItemGenerer = new MenuItemAction(
        'LBL_BTN_GEN_FORMULAIRE',
        'BOUGENFOR',
        () => ({}),
        {
            iconButton: false,
            icon: 'autorenew',
            saveFirst: true,
            successAction: () => vm.monoOccurrence.fetchData()
        }
    );

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_GFC', [
            new FormulaireItem('ecrcleint')
        ])
    };

    vm.gfdMultiOccurrenceOptions = {
        bloc: 'gfd',
        colonnesVisibles: [
            { nom: 'trlcod', largeur: 130 },
            { nom: 'trldsc', largeur: 250 },
            { nom: 'fomflgcen', largeur: 100 },
            { nom: 'fomflgdec', largeur: 100 },
            { nom: 'fomflgdef', largeur: 100 },
            { nom: 'gfdflgact', largeur: 100 },
            { nom: 'gfdflgdif', largeur: 100 },
            { nom: 'stsdsc', largeur: 180 },
            { nom: 'stsreqsql', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gfdflgact'),
            new FormulaireItem('gfdflgdif'),
            new FormulaireItem('stscleintgfd', {
                resourceParams: (data: any) => ({ ecrcleintref: data.$ancetre.ecrcleint })
            }),
            new FormulaireItem('stsreqsql',{
                readonly:true
            }),
        ], { largeurDefaut: 33.33 })
    };

}
