import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAc0047 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0047Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass ) {
    const vm: IPageAc0047 = this;

    const docAff = new MenuItemForage(
        'LBL_BTN_DOC',
        (rowData: any) => `secure.${rowData.ecrcodoc}.ecran`,
        (rowData: any) => ({
            id: rowData.ecrcledoc
        }),
        {
            icon: 'exit_to_app',
            disabled: (rowData:any) => !(rowData.ecrcodoc)
        }
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleint', { required: false }),            
            new FormulaireItem('redcleint', {
                resourceParams: (data: any) => {
                    return {
                        flginthie: data.flginthie,
                        flgintint: data.flgintint
                    };
                }
            }),            
            new FormulaireItem('tydcleint', {
                required: false, resourceParams: (data: any) => {
                    return {
                        redcleint: data.redcleint
                    };
                }
            }),
            new FormulaireItemDivider('LBL_NIV'),
            new FormulaireItem('flgintreq'),            
            new FormulaireItem('flginthie'),
            new FormulaireItem('flgintint')
        ]),
        colonnesVisibles: [
            { nom: 'intcod', largeur: 136 },
            { nom: 'intnom', largeur: 200 },
            { nom: 'vaedsc', largeur: 200 },
            { nom: 'tydcod', largeur: 110 },
            { nom: 'tyddsc', largeur: 190 },
            { nom: 'docaffnumdoc01', largeur: 190, menu: [docAff] }
        ],
        colonnesCachees: [
            'ecrcodoc', 
            'ecrcledoc'
        ]
    };
}
