import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0071',
    ecrans: ['00-2'],
    params: [
        'id',
        '?demcleint',
        '?lemcleint',
        '?aftcleint',
        '?dercleint',
        '?trncleint'
    ]
});
