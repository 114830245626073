import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from "angular";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IFilterExDate} from "../../../../../core/filters/ex-date.filter";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";

interface IPageCo0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    cozMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function co0023Controller(Formulaire: IFormulaireClass,
                                         Operateur: IOperateurService,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         dateFilter: IFilterDate,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         rr0129Route: IRoute,
                                         co0027Route: IRoute,
                                         exDateFilter: IFilterExDate,
                                         MenuItemAction: IMenuItemActionClass
) {
    const vm: IPageCo0023 = this;

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_BOU_CHARG_INI',
                    co0027Route.NAME,
                    () => ({}),
                    {
                        icon: 'publish',
                        iconButton: false,
                        fonction: 'BTN_BOU_CHARG_INI'
                    })
            ]
        ),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_RENONCE',
                'BOUREN',
                () => ({}),
                {
                    icon: 'clear',
                    hidden: () => (
                        vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'
                    ),
                    messageConfirmation: {
                        message: 'BOUREN_CONFIRM',
                        condition: (data: any) => (!data.coyflgren)
                    },
                    successAction: () => { vm.multiOccurrence.fetchDataList(); }
                }
            ),
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('axe',{default:'ACQ'}),
            new FormulaireItem('en_date_du',{required:true,default: dateFilter(new Date(), 'yyyy-MM-dd')}),
            new FormulaireItem('eplcleint'),
            new FormulaireItem('comcleint'),
            new FormulaireItem('tafcleint_cla'),
            new FormulaireItem('tafcleint_aff'),
            new FormulaireItem('t_toutencours',{default:'A'}),
            new FormulaireItem('donnee',{default:'D'}),
            new FormulaireItemDivider('LBL_CRI_TEL'),
            new FormulaireItem('flgaffetl'),
            new FormulaireItem('ttlcleint'),
            new FormulaireItemDivider('LBL_CRI_STA'),
            new FormulaireItem('t_flgact',{default: 1}),
            new FormulaireItem('t_flgmap'),
            new FormulaireItem('t_flgina'),
        ]),
        colonnesFixesGauche: [
            {nom: 'eplnumref3_tri', largeur: 100},
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 200},
            {nom: 'comcod', largeur: 120,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'ppacod', largeur: 80,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'coydatdeb', largeur: 100,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'coydatfin', largeur: 100,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'coydatacq', largeur: 100,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'nb_total', largeur: 110,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'nb_restant', largeur: 100,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'comauto_cible', largeur: 90,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'coyflgatt', largeur: 80,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'coyflgchrini', largeur: 110,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'coyflgren', largeur: 90,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'RAP'},
            {nom: 'com_acq', largeur: 180,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'ACQ'},
            {nom: 'com_non_acq', largeur: 180,hidden:() => vm.multiOccurrence.etatSelected.criteresSuggeresData.axe === 'ACQ'},
            {nom: 'fonc_cla', largeur: 160},
            {nom: 'fonc_aff', largeur: 160},
            {nom: 'telephone', largeur: 120},
            {nom: 'posnumdoc', largeur: 120},
            {nom: 'statut', largeur: 120}


        ],
        colonnesFixesDroite: [
            {nom: 'coyqtepj', largeur: 100},
            {nom: 'coyvarbqecib', largeur: 140}
        ],
        colonnesCachees:[
            'demcleint',
            'ppadatdeb',
            'ppadatfin',

        ]
    };

    vm.cozMultiOccurrenceOptions = {
        navigatePage: rr0129Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                demcleint: rowData.$ancetre.demcleint,
                flgperfect: 0,
                bqecleint: rowData.bqecleint,
                ppadatdeb: exDateFilter(rowData.$ancetre.ppadatdeb),
                ppadatfin: exDateFilter(rowData.$ancetre.ppadatfin)
            };
        },
        bloc: 'coz',
        colonnesVisibles: [
            {nom: 'temcod', largeur: 100},
            {nom: 'temdsc', largeur: 240},
            {nom: 'urgcod', largeur: 100},
            {nom: 'urgdsc', largeur: 300},
            {nom: 'grecod', largeur: 100},
            {nom: 'gredsc', largeur: 240},
            {nom: 'bqecod', largeur: 100},
            {nom: 'bqedsc', largeur: 240}
        ],
        colonnesFixesDroite: [
            {nom: 'ppacalcod', largeur: 100},
            {nom: 'cozvarbqecib', largeur: 120}
        ],
        colonnesCachees:[
            'bqecleint',
            'eplcleint'
        ]
    }
}
