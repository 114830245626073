import { IComponentController, IFilterDate } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPagePf0011 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireFlag: IFormulaire;
    formulaireDates: IFormulaire;
    formulaireExo: IFormulaire;
    menus: { [key: string]: Array<IMenuItem> };
    dpnMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0011Controller(pf0030Route: IRoute,
    pf0031Route: IRoute,
    pf0032Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    dateFilter: IFilterDate) {
    const vm: IPagePf0011 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        actionsMore: new Menu([
            new MenuItemEcran(
                'LBL_LEM_AFT',
                pf0030Route.NAME,
                (data: any) => ({ id: data.demcleint }),
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),
        formulaire: new Formulaire('LBL_BLC_DPM', [
            new FormulaireItem('demcleint', { resourceParams: () => ({ dpmdatdeb: vm.monoOccurrence.data.dpmdatdeb }) }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tpccleint'),
            new FormulaireItem('tpcflgexo'), // Je dois le mettre pour contrôler l'affichage de la carte d'exonération
            new FormulaireItem('dpmacedsc'),
            new FormulaireItem('dpmlien', { enableCount: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('piscleint'),
            new FormulaireItem('dpmpisdsc'),
            new FormulaireItem('dpmlieu', { enableCount: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dpmcomdem', { enableCount: true }),
            new FormulaireItem('dpmcomcen', { enableCount: true }),
            new FormulaireItem('dpmcomadm', { enableCount: true }),
            // Autres informations
            new FormulaireGroupeAccordeon('LBL_GRP_AUTRES', new Formulaire([
                new FormulaireItem('unocleintrh'),
                new FormulaireItem('poscleintsup', { resourceParams: (data: any) => ({ unocleintrh: data.unocleintrh }) }),
                new FormulaireItem('cabcleint'),
                new FormulaireItem('rbucleint'),
                new FormulaireItem('taucla', { largeur: 25 }),
                new FormulaireItem('dpmtaujrsdem', { largeur: 25 }),
                new FormulaireItemDivider('LBL_DIV_TRN'),
                new FormulaireItem('dpmnumdoc', { largeur: 33 }),
                new FormulaireItem('dpmdat', { largeur: 33, default: dateFilter(new Date(), 'yyyy-MM-dd') }),
                new FormulaireItem('ttccleint', { largeur: 33 }),
            ], { largeurDefaut: 50 })),
        ], { largeurDefaut: 33 })
    };

    vm.menus = {
        aut__dpmdepree: [
            new MenuItemEcran(
                'BTN_FORAGE_DEPREE',
                pf0032Route.NAME,
                (data: any) => ({
                    id: data.trncleint,
                    axe: 'GGF',
                    dpmcleint: data.dpmcleint,
                    demcleint: data.demcleint
                }),
                {
                    icon: 'launch',
                    iconButton: true,
                    onClose() { vm.monoOccurrence.fetchData(); }
                }
            )
        ],
        aut__dpmdepann: [
            new MenuItemEcran(
                'BTN_FORAGE_DEPANN',
                pf0031Route.NAME,
                (data: any) => ({
                    demcleint: data.demcleint,
                    annfin: data.dpmannfin
                }),
                {
                    icon: 'launch',
                    iconButton: true
                }
            )
        ]
    };

    vm.formulaireFlag = new Formulaire('LBL_BLC_INDICATEURS', [
        new FormulaireItem('dpmflgpjbcou'),
        new FormulaireItem('aut__dpmflgrapdep', {
            menu: [
                new MenuItemActionTelechargement(
                    'BTN_ACTION_RAPDEP',
                    'BOURAPDEP',
                    () => ({}),
                    {
                        icon: 'attach_file',
                        iconButton: true,
                        hidden: (data: any) => (!data.aut__dpmflgrapdep)
                    }
                )
            ]
        })
    ], { largeurDefaut: 100 });

    vm.formulaireDates = new Formulaire('LBL_BLC_DATEHEURE', [
        new FormulaireItem('dpmdatdeb'),
        new FormulaireItem('dpmhredeb'),
        new FormulaireItem('dpmdatfin'),
        new FormulaireItem('dpmhrefin'),
        new FormulaireItem('dpmdurhtt'),
        new FormulaireItem('dpmdurhht')
    ]);

    vm.formulaireExo = new Formulaire('LBL_BLC_EXONERATION', [
        new FormulaireItem('vaedpmexo', { largeur: 33 }),
        new FormulaireItem('vaedpmses', { largeur: 33 }),
        new FormulaireItem('cylcleint', { largeur: 33 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dpmdepdsc', { largeur: 66, enableCount: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dpmflgpro', { largeur: 25 }),
        new FormulaireItem('dpmflglib', { largeur: 25 }),
        new FormulaireItem('dpmdscpro', { largeur: 50, enableCount: true }),
        new FormulaireGroupeAccordeon('LBL_GRP_ACTCRE', new Formulaire([
            new FormulaireItem('dpmsig1', { largeur: 25 }),
            new FormulaireItem('dpmdscsig1', { largeur: 50, enableCount: true }),
            new FormulaireItem('dpmnbrcrt1', { largeur: 25 }),
            new FormulaireItem('dpmsig2', { largeur: 25 }),
            new FormulaireItem('dpmdscsig2', { largeur: 50, enableCount: true }),
            new FormulaireItem('dpmnbrcrt2', { largeur: 25 }),
            new FormulaireItem('dpmsig3', { largeur: 25 }),
            new FormulaireItem('dpmdscsig3', { largeur: 50, enableCount: true }),
            new FormulaireItem('dpmnbrcrt3', { largeur: 25 }),
            new FormulaireItem('dpmsig4', { largeur: 25 }),
            new FormulaireItem('dpmdscsig4', { largeur: 50, enableCount: true }),
            new FormulaireItem('dpmnbrcrt4', { largeur: 25 }),
            new FormulaireItem('dpmsig5', { largeur: 25 }),
            new FormulaireItem('dpmdscsig5', { largeur: 50, enableCount: true }),
            new FormulaireItem('dpmnbrcrt5', { largeur: 25 }),
        ]), { opened: false })
    ]);

    vm.dpnMultiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        bloc: 'dpn',
        colonnesFixesGauche: [
            { nom: 'tdscod', largeur: 120 },
            { nom: 'tdsdsc', largeur: 250 }
        ],
        colonnesVisibles: [
            { nom: 'dpnmntdem', largeur: 126, total: true },
            { nom: 'dpnmntacc', largeur: 126, total: true },
            { nom: 'dpncom', largeur: 250 }
        ],
        colonnesFixesDroite: [
            { nom: 'dpnmntree', largeur: 126, total: true }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tdscleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dpnmntdem'),
            new FormulaireItem('dpnmntacc'),
            new FormulaireItem('dpncom', { largeur: 100, enableCount: true })
        ])
    };
}
