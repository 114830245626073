import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPagePR0037 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function PR0037Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePR0037 = this;
    
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('prjcleint'),
            new FormulaireItem('typcleint'),
            new FormulaireItem('pdfcleint',  {required: false,  resourceParams: (data: any) => ({ prjcleint: data.prjcleint })})
        ]),        
        colonnesVisibles: [
            { nom: 'prjcod', largeur: 140},
            { nom: 'prjdsc', largeur: 180},
            { nom: 'vecpddtyprap_dsc', largeur: 140 },
            { nom: 'pdddat', largeur: 110 },
            { nom: 'pdfcod', largeur: 140},
            { nom: 'pdfcom', largeur: 180 },
            { nom: 'pddcom', largeur: 200 },
            { nom: 'pddflgemi', largeur: 186 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pddflgemi', { largeur: 30 })
        ])
        };
}
