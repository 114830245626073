import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageCp0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0002Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass) {
    const vm: IPageCp0002 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tercod', largeur: 105},
            {nom: 'terdsc', largeur: 200},
            {nom: 'vaetercat', largeur: 126},
            {nom: 'vaetertypcal', largeur: 126},
            {nom: 'terjrsdel', largeur: 130},
            {nom: 'terjrsdeladd', largeur: 130},
            {nom: 'terprcesc', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tercod', {maxlength: 5}),
            new FormulaireItem('terdsc', {largeur: 40,  enableCount: true }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaetercat'),
            new FormulaireItem('vaetertypcal'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('terjrsdel'),
            new FormulaireItem('terjrsdeladd'),
            new FormulaireItem('terprcesc'),
            new FormulaireItemSautDeLigne(),

            new FormulaireGroupe('LBL_TEST_CALCUL', new Formulaire([
                new FormulaireItem('t_datcal'),
                new FormulaireItem('t_datres')
            ], {largeurDefaut: 33.33}), {largeur: 61})
        ], {largeurDefaut: 20})
    };
}
