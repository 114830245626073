import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IComponentController } from 'angular';

interface IPageMc0058 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    styMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0058Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0058 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'seddsc', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('seddsc', { largeur: 50 })
        ])
    };

    vm.styMultiOccurrenceOptions = {
        bloc: 'sty',
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 150 },
            { nom: 'tyddsc', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tydcleint')
        ])
    };
}
