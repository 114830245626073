import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireGroupeClass } from "../../../../../core/services/formulaire/formulaire-groupe.service";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMr0156 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireTag: IFormulaire;
    formulaireAutres: IFormulaire;
    tgpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    admMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0156Controller(mr0155Route: IRoute,
    mr0238Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass) {

    const vm: IPageMr0156 = this;

    vm.$onInit = $onInit;

    // Téléchargement du calendrier généré
    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        pageRetourSuppression: mr0155Route.NAME,
        actionsMore: new Menu([
            new MenuItemForage(
                'G_LBL_MR0238',
                mr0238Route.NAME,
                (data: any) => ({
                    vaetypfnd: 'AVI',
                    cleintdet: data.tagcleint
                }),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            )
        ])
    };

    function $onInit() {
        vm.formulaireTag = new Formulaire('LBL_BLC_TAG', [
            new FormulaireGroupe(new Formulaire([
                new FormulaireItem('tagcod', { largeur: 25 }),
                new FormulaireItem('tagdscabr', { largeur: 75 }),
                new FormulaireItem('tagdsc', { largeur: 100 })
            ]), { largeur: 75 }),
            new FormulaireGroupe(new Formulaire([
                new FormulaireItem('tagdatdeb'),
                new FormulaireItem('tagdatfin'),
            ]), { largeur: 25 }),
            new FormulaireItem('mtgcleint'),
            new FormulaireItem('rcecleint'),
            new FormulaireGroupeAccordeon('LBL_GRP_AUTRES_INFOS',
                new Formulaire([
                    new FormulaireItem('tagindimp'),
                    new FormulaireItem('tagseqaff'),
                    new FormulaireItem('vaesyscodprv'),
                    new FormulaireItem('tagcleintref'),
                    new FormulaireItem('vactagcat')
                ]), { opened: (data: any) => !data.tagcleint })
        ]);

        // Paramètres Avantages imposables
        vm.tgpMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'tgpdatvig', largeur: 100 },
                { nom: 'tgpfct', largeur: 100 },
                { nom: 'tgpflgact', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tgpdatvig'),
                new FormulaireItem('tgpfct', { required: true }),
                new FormulaireItem('tgpflgact', { default: 1 })
            ], { largeurDefaut: 33 })
        };

        // Admissibilité
        vm.admMultiOccurrenceOptions = {
            colonnesVisibles: [
                { nom: 'colcod', largeur: 200 },
                { nom: 'coldsc', largeur: 300 },
                { nom: 'cdtdatvig', largeur: 150 },
                { nom: 'maxcdtdatvig', largeur: 150 }
            ]
        }
    }

}
