import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageDb0027 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0027Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass) {
    const vm: IPageDb0027 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true,
            suiviModification: false,
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),        
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccoddeb', { largeur: 16, required: true }),
            new FormulaireItem('peccodfin', { largeur: 17, required: true }),            
            new FormulaireItem('prucleint', { titre: ecranDetails.valeurs.titpru, lovSelectionMultiple: true }),
            new FormulaireItem('intcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('orgcleint'),
            new FormulaireItem('prjcleint'),
            new FormulaireItem('pdfcod'),
            new FormulaireItem('unacleintsup', { hidden: true })
        ], { largeurDefaut: 33.33 }),
        colonnesVisibles: [
            { nom: 'prjcod', largeur: 100 },
            { nom: 'prjdscabr', largeur: 180 },
            { nom: 'pdfcodaff', largeur: 110 },
            { nom: 'orgnomabr', largeur: 180 },
            { nom: 'prucod', largeur: 100, titre: ecranDetails.valeurs.titpru },
            { nom: 'prudscl01', largeur: 400 },
            { nom: 'unacod', largeur: 120 },
            { nom: 'unadsc', largeur: 300 },
            { nom: 'unacodsup', largeur: 120 },
            { nom: 'unadscsup', largeur: 180 },
            { nom: 'prscod', largeur: 120, titre: ecranDetails.valeurs.titprs },
            { nom: 'prsdscl01', largeur: 160 },
            { nom: 'vecprsrerg', largeur: 120 },
            { nom: 'vecdsc', largeur: 150 },
            { nom: 'mntbdgini', largeur: 130 },
            { nom: 'mntbdg', largeur: 130 },
            { nom: 'mntconnet', largeur: 130 },
            { nom: 'mntconbru', largeur: 130 },
            { nom: 'mntavenet', largeur: 130 },
            { nom: 'mntavebru', largeur: 140 },
            { nom: 'mntavatax', largeur: 130 },
            { nom: 'mnttaxfed', largeur: 130 },
            { nom: 'mnttaxpro', largeur: 130 },
            { nom: 'mnttotal', largeur: 150 },
            { nom: 'mntremfed', largeur: 130 },
            { nom: 'mntrempro', largeur: 130 },
            { nom: 'mntdepnet', largeur: 145 }
        ]
    };
}
