import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IStateParamsService} from "angular-ui-router";

interface IPageCo0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0019Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCo0019 = this;

    const criteresSuggeresVisibles = !$stateParams.grccleint;

    vm.multiOccurrenceOptions = {

        fonctions: {
            criteresSuggeresVisibles,
            saveEtatTemporaire: false
        },
        criteresSuggeres: new Formulaire([

            new FormulaireItem('grccleint', {
                required: true,
                readonly: true,
                default: $stateParams.grccleint
            })

        ], {largeurDefaut: 33.33}),
        colonnesCachees: ['grccleint'],
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 160},
            {nom: 'eplnomprn', largeur: 260},
            {nom: 'lemcod', largeur: 130},
            {nom: 'lemdsc', largeur: 190}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('grccleint', {default: (data:any) => $stateParams.grccleint}),
            new FormulaireItem('lemcleint')
        ])
    };
}
