import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
interface IPageMr0190 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rasMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface IMr0190Params {
    id: number;
}
/* @ngInject */
export default function Mr0190Controller(
    $stateParams: IMr0190Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass
) {
    const vm: IPageMr0190 = this;

        vm.menuBandeau = new Menu([]);
        vm.valeursEcran = ecranDetails.valeurs;
        vm.rasMultiOccurrenceOptions = {
            fonctions: {
                importation: true,
                rechercheCollapsable: false
            },
            colonnesVisibles: [
                { nom: 'rasdatvig', largeur: 100 },
                { nom: 'colcod', largeur: 130 },
                { nom: 'coldsc', largeur: 378 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rasdatvig', { largeur: 10 }),
                new FormulaireItem('colcleint', { largeur: 39 }),
                new FormulaireItem('rcecleint', { hidden: true, default: $stateParams.id })
            ])
        };    
}
