import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";

interface IPageMr0125 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulairePci: IFormulaire;
    pfaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0125Controller(Formulaire: IFormulaireClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass) {

    const vm: IPageMr0125 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            noId: true,
        };
        vm.formulairePci = new Formulaire('LBL_BLC_PCI', [
            new FormulaireItem('vaesynemp'),
            new FormulaireItem('vaemajemp'),
            new FormulaireItem('vaepcifctann'),
            new FormulaireItem('vaepcisaippa'),
            new FormulaireItem('pcihreannsfu', {largeur: 33}),
            new FormulaireItem('pciagefinasssal', {largeur: 33}),
            new FormulaireItem('pcidurmaxasssal', {largeur: 33}),
            new FormulaireItemDivider('LBL_DIV_FEP'),
            new FormulaireGroupe( new Formulaire([
                    new FormulaireItem('pciflgsailemfep', {largeur: 25}),
                    new FormulaireItem('pciflgsaiderfep', {largeur: 25})
            ]), {largeur: 25}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pciflgsaitemfep', {largeur: 25}),
                new FormulaireItem('pciflgsaidrpfep', {largeur: 25})
            ]), {largeur: 25}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pciflgsaiposfep', {largeur: 25}),
                new FormulaireItem('pciflgsaiechfep', {largeur: 25})
            ]), {largeur: 25}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pciflgsaiunofep', {largeur: 25})
            ]), {largeur: 25}),
        ]);

        vm.pfaMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'pfadatvig', largeur: 100},
                {nom: 'pfafct', largeur: 115}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('pfadatvig'),
                new FormulaireItem('pfafct')
            ])
        };
    }
}
