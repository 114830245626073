import {IStateService} from 'angular-ui-router';
import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";


interface IPageMC0173 extends IComponentController {
    formulaireFml: IFormulaire;
    fmcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fmpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0173Controller($state: IStateService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMC0173 = this;
    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireFml = new Formulaire('LBL_FORMULAIRE_FORMULAIRE', [
            new FormulaireItem('fmlcod'),
            new FormulaireItem('fmldsc', {enableCount: true}),
            new FormulaireItem('vaefmlpal', {required: true}),
            new FormulaireItem('fmlnumrelpro')
        ]);

        vm.fmcMultiOccurrenceOptions = {
            bloc: 'fmc',
            formulaire: new Formulaire([
                new FormulaireItem('cptcleint'),
                new FormulaireItem('fmcflgimp'),
                new FormulaireItem('fmcdatdeb'),
                new FormulaireItem('fmcdatfin'),
            ], {largeurDefaut: 25}),
            colonnesVisibles: [
                {nom: 'cptcod'         , largeur: 105},
                {nom: 'cptdsc'         , largeur: 402},
                {nom: 'fmcflgimp'      , largeur: 140},
                {nom: 'fmcdatdeb'      , largeur: 105},
                {nom: 'fmcdatfin'      , largeur: 100}
            ]
        };

        vm.fmpMultiOccurrenceOptions = {
            bloc: 'fmp',
            formulaire: new Formulaire([
                new FormulaireItem('cptcleint', { resourceParams: (data:any) => ({
                        fmlcleint: data.$ancetre.fmlcleint
                    })}),
                new FormulaireItem('fmpcas'),
                new FormulaireItem('vaefmptyp'),
                new FormulaireItem('fmpcod'),
                new FormulaireItem('fmpnot')
            ], {largeurDefaut: 25}),
            colonnesVisibles: [
                {nom: 'cptcod'         , largeur: 105},
                {nom: 'cptdsc'         , largeur: 402},
                {nom: 'fmpcas'         , largeur: 70},
                {nom: 'vaefmptyp_desc' , largeur: 118},
                {nom: 'fmpcod'         , largeur: 70},
                {nom: 'fmpnot'         , largeur: 232}
            ]
        };

    }
}
