import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageGs0035 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0035Controller(mc0038Route: IRoute,
    gs0009Route: IRoute,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    parametresRecherche: IParametresRechercheService,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0035 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0009Route.NAME)
        ]),
        navigatePage: gs0009Route.NAME,
        navigateParams: (rowData: any) => ({ id: (rowData.usrcleintref) }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('pracleintcri', { lovSelectionMultiple: true }),
            new FormulaireItem('usrcleintcri', { lovSelectionMultiple: true }),
            new FormulaireItem('reggrpcri', { default: 1 })
        ]),
        fonctions: {
            nouveau: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'usrcod', largeur: 100 },
            { nom: 'usrnom', largeur: 230 },
            { nom: 'eplnummat', largeur: 130 }
        ],
        colonnesCachees: [
            'intcleint',
            'usrcleintref'
        ],
        colonnesVisibles: [
            {
                nom: 'intcod', largeur: 120,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_INT',
                        mc0038Route.NAME,
                        (rowData: any) => ({ id: rowData.intcleint }),
                        { hidden: (rowData: any) => !rowData.intcleint }
                    )
                ]
            },
            { nom: 'intflgreq', largeur: 100 },
            { nom: 'intnom', largeur: 160 },
            { nom: 'unacod', largeur: 130 },
            { nom: 'unadsc', largeur: 300 },
            { nom: 'usrdatfin', largeur: 126 },
            { nom: 'usradrele', largeur: 250 },
            { nom: 'pradsc', largeur: 300 },
            { nom: 'gupdatfin', largeur: 140 },
            { nom: 'impdsc', largeur: 160 },
            { nom: 'ciecod', largeur: 120 },
            { nom: 'cienom', largeur: 160 }
        ]
    };
}
