import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageSO0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0004Controller(so0005Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageSO0004 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: so0005Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                urgcleint: rowData.urgcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(so0005Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'unocod', largeur: 130},
            {nom: 'unodsc', largeur: 262},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'tyulib', largeur: 226},
            {nom: 'unodatdeb', largeur: 100},
            {nom: 'unodatfin', largeur: 100},
            {nom: 'unoadrweb', largeur: 262},
            {nom: 'unoadrcou', largeur: 262},
            {nom: 'unocodsup', largeur: 110},
            {nom: 'unodscabrsup', largeur: 262},
            {nom: 'unacod', largeur: 110},
            {nom: 'unadscabr', largeur: 262},
            {nom: 'prucod', largeur: 116},
            {nom: 'prudscl01', largeur: 226},
            {nom: 'cincod', largeur: 116},
            {nom: 'cindsc', largeur: 226}
        ],
        colonnesCachees: [
            'unocleint'
        ]
    };
}
