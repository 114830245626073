import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IComponentController } from 'angular';

interface IPageMc1041 extends IComponentController{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mc1041Controller(Formulaire: IFormulaireClass,
                                         profil: IProfil,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc1041 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.multiOccurrenceOptions = {
            fonctions: {
                suiviModification: false
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('ciecleint_cie', {
                    resourceParams:{
                        usrcleint: profil.preferences.usrcleint
                    }
                })
            ]),
            colonnesVisibles: [
                {nom: 'vaedsc', largeur: 200},
                {nom: 'peccod', largeur: 200},
                {nom: 'pecdatdeb', largeur: 200},
                {nom: 'pecdatfin', largeur: 200}
            ]
        };
    }

}
