import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0138 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0138Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         mr0238Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,) {
    const vm: IPageMr0138 = this;

    vm.multiOccurrenceOptions = {
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_MR0238',
                mr0238Route.NAME,
                (data:any) => ({vaetypfnd: 'GRE',
                    cleintdet: data.grecleint}),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            )
        ]),
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'grecod', largeur: 89},
            {nom: 'gredsc', largeur: 272},
            {nom: 'grehremin', largeur: 91},
            {nom: 'grehremax', largeur: 95},
            {nom: 'grehordsc', largeur: 262},
            {nom: 'greflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('grecod', {required: true}),
            new FormulaireItem('gredsc', {required: true}),
            new FormulaireItem('grehremin', {largeur: 25}),
            new FormulaireItem('grehremax', {largeur: 25}),
            new FormulaireItem('grehordsc', {largeur: 25}),
            new FormulaireItem('greflgact', {default: 1, largeur: 25})
        ])
    };
}
