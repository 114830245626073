import { IComponentController } from 'angular';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0198 extends IComponentController {
    menuBandeau: IMenu;
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ratMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0198Params {
    id: number;
}

/* @ngInject */
export default function Mr0187Controller(
    $stateParams: IMr0198Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass,
    mr0199Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageMr0198 = this;

    vm.menuBandeau = new Menu([]);
    vm.valeursEcran = ecranDetails.valeurs;

    vm.taeMultiOccurrenceOptions = {
        bloc: 'tae',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                '',
                mr0199Route.NAME,
                (data: any) => {
                    return {
                        tagcleint: $stateParams.id,
                        id: data.eldcleint,
                        eldcleint: data.eldcleint,
                        eldcod: data.eldcod
                    };
                },
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUOUV'
                }
            )
        ]),

        fonctions: {
            importation: false,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'taeord', largeur: 70 },
            { nom: 'eldcod', largeur: 157 },
            { nom: 'eldnom', largeur: 275 },
            { nom: 'taecom', largeur: 275 },
            { nom: 'eldcleint', largeur: 275, hidden: true }
        ],
        colonnesCaches: ['eldcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('taeord', { largeur: 25 }),
            new FormulaireItem('eldcleint', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('taecom', { largeur: 100, enableCount: true }),
            new FormulaireItem('tagcleint', { hidden: true, default: $stateParams.id })
        ])
    };
}
