import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageAc0064 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
}

interface IAc0064Params {
    etccleint: string;
}

/* @ngInject */
export default function Ac0064Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    $stateParams: IAc0064Params
) {

    const vm: IPageAc0064 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_LCOP', [
            new FormulaireItem('etccleint', { default: $stateParams.etccleint }),
            new FormulaireItem('aelnumdoc'),
            new FormulaireItem('aelflgren'),
            new FormulaireItem('aelmnt'),
            new FormulaireItem('aelmntpro'),
            new FormulaireItem('molcleint'),
            new FormulaireItem('vecmotoct'),
            new FormulaireItem('vecchofou'),
            new FormulaireItem('vecregenc'),
            new FormulaireItem('vecattarq'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aelseao'),
            new FormulaireItem('aeldatseao')
        ], { largeurDefaut: 50 })
    };
}

