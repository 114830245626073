import {
    IAugmentedJQuery,
    IComponentController,
    IFilterDate
} from 'angular';
import { IMonoOccurrence } from '../../../../core/services/mono-occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceOptions
} from '../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../core/services/formulaire/formulaire-item.service';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';

interface IComposantNotesFinance extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    resourceParams: any;
    parentIds: Array<number | string>;
    erreurInit: boolean;
    initialized: boolean;
    initializing: boolean;
    isModificationPermise: boolean;
    lblTitre: string;

    init(): void;
    onSaveNewAction(data: any): void;
}

/* @ngInject */
export default function NotesFinanceController(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $element: IAugmentedJQuery,
    dateFilter: IFilterDate) {
    const vm: IComposantNotesFinance = this;

    vm.$onInit = $onInit;
    vm.onSaveNewAction = onSaveNewAction;
    vm.init = init;

    function $onInit() {
        $element.closest('md-content.ex-mono-occurrence-ecran-content').animate({
            scrollTop: $element.prop('offsetTop') - 8
        }, 200);

        vm.init();
    }

    function init() {
        vm.initializing = true;
        vm.monoOccurrence.data.$promise.then(() => {
            vm.parentIds = vm.monoOccurrence.getParentIds();

            if (vm.parentIds.length) {
                vm.parentIds.pop();
            }
        }).then(() => {
            vm.erreurInit = false;
            vm.initialized = true;

            const cleint = vm.monoOccurrence.cleint;

            vm.multiOccurrenceOptions = {
                srccod: 'mc1027',
                stateParams: vm.ecranContextCtrl.stateParams,
                parentId: vm.monoOccurrence.data[cleint],
                colonnesVisibles: [
                    'nofseq',
                    'vecsystypnot_dsc',
                    'usrcodcre',
                    'usrnomcre',
                    'nofnot',
                    'nbrpj',
                    'nofdat'
                ],
                resourceParams: {
                    ecrcleint: vm.ecranContextCtrl.ecranDetails.ecrcleint,
                    srccod: getSrccod(),
                    vaesysnofmne: vm.monoOccurrence.mnemonique,
                    nofcleintref: vm.monoOccurrence.data[vm.monoOccurrence.cleint],
                    doccleint: vm.monoOccurrence.cycleCleint === 'doccleint' ? vm.monoOccurrence.id : null,
                    ...vm.resourceParams
                },
                formulaire: new Formulaire([
                    new FormulaireItem('vecsystypnot', { required: true }),
                    new FormulaireItem('nofnot', { required: true, largeur: 100 }),
                    // La date ici ne fait rien, la date est fixée dans post_insert dans le package
                    new FormulaireItem('nofdat', { required: true, hidden: true, default: dateFilter(new Date(), 'yyyy-MM-ddTHH:mm:ss') })
                ], {
                    piecesJointes: true
                }),
                monoOccurrenceParent: vm.monoOccurrence,
                fonctions: {
                    recherche: true,
                    rechercheParColonne: true,
                    filtrer: true,
                    enregistrerUnEtat: false,
                    selectionnerUnEtat: true,
                    exportation: true,
                    pagination: true,
                    importation: false,
                    selectionnerDesColonnes: false,
                    selectionnerTri: false,
                    reinitialiser: false,
                    saveEtatTemporaire: false,
                    afficherMenuSettingDansEntete: true
                }
            };
        }).catch(() => {
            vm.erreurInit = true;
        }).finally(() => {
            vm.initializing = false;
        });
    }

    function getSrccod() {
        if (vm.srccod) {
            return vm.srccod;
        } else {
            const srccod = (vm.monoOccurrence.bloc) ? `${vm.monoOccurrence.srccod}-${vm.monoOccurrence.bloc}` : vm.monoOccurrence.srccod;

            return vm.monoOccurrence.isEdition ? `${srccod}-edition` : srccod;
        }
    }

    function onSaveNewAction(savedData: any) {
        // Comme il n'est pas possible d'ajouter des pièces jointes durant la création, on ouvre la note après
        // sa création pour qu'on puisse ajouter les pièces jointes aussi facilement que possible
        vm.multiOccurrence.once('dataListUpdate', () => {
            openEditedNote(savedData.id || savedData.$id);
            vm.compteur++
        });
    }

    function toggleRow(rowDetails: any) {
        rowDetails.$opened = !rowDetails.$opened;
    }

    function openEditedNote(id: number) {
        const rowDetails = vm.multiOccurrence.dataList.find((item) => item[vm.multiOccurrence.cleint] === id);
        if (rowDetails) {
            toggleRow(rowDetails);
        }
    }
}
