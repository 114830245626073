import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageDb0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0026Controller(Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass) {
    const vm: IPageDb0026 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true,               
            suiviModification: false,
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),        
        criteresSuggeres: new Formulaire([
            new FormulaireItem('peccoddeb', { largeur: 16, required: true }),
            new FormulaireItem('peccodfin', { largeur: 17, required: true }),
            new FormulaireItem('vecsyscodret', { largeur: 33 }),
            new FormulaireItem('nature', { largeur: 33, default: 'DEP' }),
            new FormulaireItem('prucleint', { largeur: 33, titre: ecranDetails.valeurs.titpru }),
            new FormulaireItem('intcleint', { largeur: 33 }),
            new FormulaireItem('orgcleint', { largeur: 33 }),
            new FormulaireItem('prjcleint', { largeur: 33 }),
            new FormulaireItem('montant', { default: '1' }),
            new FormulaireItem('unacleintsup', { hidden: true })
        ], { largeurDefaut: 33.33 }),
        colonnesCachees: [
            'ecrcod',
            'doccleintaff'
        ],
        colonnesVisibles: [
            { nom: 'prucod', largeur: 100, titre: ecranDetails.valeurs.titpru },
            { nom: 'prudscl01', largeur: 230 },
            { nom: 'unacod', largeur: 120 },
            { nom: 'unadsc', largeur: 300 },
            { nom: 'unacodsup', largeur: 120 },
            { nom: 'unadscsup', largeur: 180 },
            { nom: 'prjcod', largeur: 100 },
            { nom: 'prjdscabr', largeur: 180 },
            { nom: 'pdfcod', largeur: 110 },
            { nom: 'orgnomabr', largeur: 180 },
            { nom: 'prscod', largeur: 120, titre: ecranDetails.valeurs.titprs },
            { nom: 'prsdscl01', largeur: 160 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 180 },
            { nom: 'cptcod', largeur: 120, titre: ecranDetails.valeurs.titcpt },
            { nom: 'cptdscabr', largeur: 280 },
            { nom: 'docrefcod02', largeur: 130 },
            { nom: 'docrefnom02', largeur: 150 },
            { nom: 'tydcod', largeur: 130 },
            {
                nom: 'document', largeur: 200, menu: [
                    new MenuItemForage(
                        '',
                        (data: any) => `secure.${data.ecrcod}.ecran`,
                        (data: any) => ({
                            id: data.doccleintaff
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'docaffdsc01', largeur: 180 },
            { nom: 'devise', largeur: 110 },
            { nom: 'taux', largeur: 110 },
            { nom: 'docaffdat01', largeur: 130 },
            { nom: 'date_report', largeur: 110 },
            { nom: 'peccod', largeur: 110 },
            { nom: 'reqnumref', largeur: 130 },
            { nom: 'reqnumphy', largeur: 130 },
            { nom: 'dacrefint', largeur: 135 },
            { nom: 'sysboncmd', largeur: 135 },
            { nom: 'tydcod_ref', largeur: 115 },
            { nom: 'docaffnumdoc01_ref', largeur: 135 },
            { nom: 'date_doc_ref', largeur: 130 },
            { nom: 'docaffdsc01_ref', largeur: 185 },
            { nom: 'sysboncmd_ref', largeur: 165 },
            { nom: 'tydcod_reg', largeur: 115 },
            { nom: 'regnumdoc', largeur: 135 },
            { nom: 'regdat', largeur: 135 },
            { nom: 'regnumchq', largeur: 135 },
            { nom: 'regmnt', largeur: 135 },
            { nom: 'regdatcci', largeur: 130 },
            { nom: 'mntpreeng', largeur: 160 },
            { nom: 'mnteng', largeur: 135 },
            { nom: 'mntavatax', largeur: 135 },
            { nom: 'mnttaxfed', largeur: 115 },
            { nom: 'mnttaxpro', largeur: 115 },
            { nom: 'mnttotal', largeur: 145 },
            { nom: 'mntremfed', largeur: 135 },
            { nom: 'mntrempro', largeur: 135 },
            { nom: 'mntdepnet', largeur: 135 },
            { nom: 'dccmntret', largeur: 115 },
            { nom: 'mdvdsc', largeur: 225 },
            { nom: 'categ', largeur: 115 },
            { nom: 'categ_desc', largeur: 185 }
        ]
    };
}
