import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IStateParamsService } from "angular-ui-router";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageRr0538 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0538Controller(
    Menu: IMenuClass,
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    rr0003Route: IRoute,
    rr0065Route: IRoute,
    /*   rr0038Route : IRoute, */
    al0002Route: IRoute,
    rr0002Route: IRoute,
    rr0074Route: IRoute,
    rr0075Route: IRoute,
    rr0031Route: IRoute,
    /* th0033Route : IRoute, */
    as0017Route: IRoute,
    as0035Route: IRoute,
    rr0503Route: IRoute,
    rr0060Route: IRoute,
    /*   rr0062Route : IRoute, */
    rr0064Route: IRoute,
    rr0051Route: IRoute,
    rr0018Route: IRoute,
    rr0056Route: IRoute,
    rr0014Route: IRoute,
    so0007Route: IRoute,
    rr0006Route: IRoute,
    rr0071Route: IRoute,
    /*   rr0115Route : IRoute, */
    rr0070Route: IRoute,
    as0023Route: IRoute
) {
    const vm: IPageRr0538 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },

        colonnesFixesGauche: [
            { nom: "eplnummat", largeur: 100 },
            { nom: "eplnomprn", largeur: 210 }
        ],
        colonnesVisibles: [
            { nom: "dpadatvig", largeur: 100 },
            { nom: "ppacodori", largeur: 140 },
            { nom: "ppacodtrt", largeur: 140 },
            { nom: "vaedpaact", largeur: 160 },
            { nom: "vaedpareg", largeur: 160 },
            {
                nom: "reference",
                largeur: 300,
                menu: [
                    new MenuItemForage(
                        "",
                        (rowData: any) => getForageItem(rowData),
                        (rowData: any) => getForageParams(rowData)
                    ),
                ],
            },
            { nom: "usrcod", largeur: 140 },
            { nom: "usrnom", largeur: 80 },
            { nom: "datcre", largeur: 130 },
            { nom: "dpacom", largeur: 160 }
        ],
        colonnesFixesDroite: [
            { nom: "dpaflgman", largeur: 80 },
            { nom: "dpaflgann", largeur: 80 }
        ],
        colonnesCachees: [
            "trncleint_tse",
            "trncleint_atf",
            "trncleint_dal",
            "trncleint_tsa",
            "trncleint_tsm",
            "trncleint_der",
            "trncleint_drp",
            "trncleint_daa",
            "trncleint_ftp",
            "trncleint_afp",
            "ass_for",
            "poscleint",
            "dercleint",
            "lemcleint",
            "erccleint",
            "aftcleint",
            'vaesysmnetab',
            'dpacleintref',
            'demcleint_efd',
            'demcleint_drp'
        ],
    };

    function getForageItem(rowData: any): string {
        switch (rowData.vaesysmnetab) {
            case "DEM":
                return rr0003Route.NAME;
            case "BQA":
                return rr0065Route.NAME;
            case "RRH":
                return rr0065Route.NAME;
            case "DAL":
                return al0002Route.NAME;
            case "TSE":
                return rr0002Route.NAME;
            case "TSA":
                return rr0002Route.NAME;
            case "TSM":
                return rr0002Route.NAME;
            case "HHS":
                return as0023Route.NAME;
            case "DER":
                return rr0074Route.NAME;
            case "DRP":
                return rr0075Route.NAME;
            case "DAA":
                return rr0031Route.NAME;
            case "ATF":
                return (rowData.ass_for === "JR" || rowData.ass_for === "AJR") ? as0017Route.NAME : as0035Route.NAME;
            case "PAM":
                return rr0503Route.NAME;
            case "AFP":
                return rr0006Route.NAME;
            case "AFT":
                return rr0006Route.NAME;
            case "AID":
                return rr0060Route.NAME;
            case "DBE":
                return rr0064Route.NAME;
            case "DCI":
                return rr0051Route.NAME;
            case "EFD":
                return rr0014Route.NAME
            case "DPR":
                return rr0018Route.NAME;
            case "DRA":
                return rr0056Route.NAME;;
            case "POP":
                return so0007Route.NAME;
            case "SUS":
                return rr0006Route.NAME;
            case "DAS":
                return rr0071Route.NAME;
            case "DRD":
                return rr0070Route.NAME;
            case "ECE":
                return rr0070Route.NAME;
            case "ECD":
                return rr0070Route.NAME;
            default:
                // Si aucune correspondance n'est trouvée, vous pouvez renvoyer une valeur par défaut ou gérer le cas en conséquence
                return null;
        }
    }
    function getForageParams(rowData: any): any {
        if (rowData.vaesysmnetab === "DEM") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "BQA") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "RRH") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "HHS") {
            return {
                hhscleint: rowData.dpacleintref,
            };
        }
        if (
            rowData.vaedpareg === "RRH1" ||
            rowData.vaedpareg === "RRH2" ||
            rowData.vaedpareg === "RRH3"
        ) {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "DAL") {
            return {
                id: rowData.trncleint_dal,
            };
        }
        if (rowData.vaesysmnetab === "TSE") {
            return {
                id: rowData.trncleint_tse,
            };
        }
        if (rowData.vaesysmnetab === "TSA") {
            return {
                id: rowData.trncleint_tsa,
            };
        }
        if (rowData.vaesysmnetab === "TSM") {
            return {
                id: rowData.trncleint_tsm,
            };
        }
        if (rowData.vaesysmnetab === "DER") {
            return {
                id: rowData.trncleint_der,
            };
        }
        if (rowData.vaesysmnetab === "DRP") {
            return {
                id: rowData.trncleint_drp,
            };
        }
        if (rowData.vaesysmnetab === "DAA") {
            return {
                id: rowData.trncleint_daa,
            };
        }
        if (rowData.vaesysmnetab === "FTP") {
            return {
                id: rowData.trncleint_ftp,
            };
        }
        if (rowData.vaesysmnetab === "ATF") {
            return {
                id: rowData.trncleint_atf,
            };
        }
        if (rowData.vaesysmnetab === "PAM") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "AFP") {
            return {
                id: rowData.trncleint_afp,
            };
        }
        if (rowData.vaesysmnetab === "AFT") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "AID") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "DAJ") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "DBE") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "DCI") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if ((rowData.vaesysmnetab === "DPR")) {
            return {
                id: rowData.dpacleintref,
                demcleint: rowData.demcleint_drp,
            };
        }
        if (rowData.vaesysmnetab === "DRA") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "EFD") {
            return {
                demcleint: rowData.demcleint_efd,
            };
        }
        if ((rowData.vaesysmnetab === "POP")) {
            return {
                id: rowData.poscleint,
            };
        }
        if (rowData.vaesysmnetab === "SUS") {
            return {
                id: rowData.aftcleint,
            };
        }
        if (rowData.vaesysmnetab === "DAS") {
            return {
                aftcleint: rowData.aftcleint,
                demcleint: rowData.demcleint,
                lemcleint: rowData.lemcleint,
                dercleint: rowData.dercleint,
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "GFA") {
            return {
                id: rowData.dpacleintref,
            };
        }
        if (rowData.vaesysmnetab === "DRD") {
            return {
                demcleint: rowData.demcleint,
                erccleint: rowData.erccleint,
            };
        }
        if (rowData.vaesysmnetab === "ECE") {
            return {
                demcleint: rowData.demcleint,
                erccleint: rowData.erccleint,
            };
        }
        if (rowData.vaesysmnetab === "ECD") {
            return {
                demcleint: rowData.demcleint,
                erccleint: rowData.erccleint,
            };
        }
    }
}
