import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageZz0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Zz0002Controller(zz0003Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageZz0002 = this;
    vm.multiOccurrenceOptions = {
        navigatePage: data => data.nbrerr ? zz0003Route.NAME : undefined,
        navigateParams: data => ({ zlicleint: data.zlicleint }),
        colonnesFixesGauche: [
            { nom: 'nbrerr', largeur: 100 },
            { nom: 'zliexc', largeur: 100 },
            { nom: 'zejecjdat', largeur: 110 },
            { nom: 'zejpeccod', largeur: 110 },
            { nom: 'zejecjcat', largeur: 120 },
            { nom: 'zejdoimntdbt', largeur: 120 },
            { nom: 'zejdoimntcrt', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'zejecjdsc', largeur: 180 },
            { nom: 'zejintcod', largeur: 140 },
            { nom: 'zejgrpecj', largeur: 120 },
            { nom: 'zejprucod', largeur: 120 },
            { nom: 'zejprscod', largeur: 130 },
            { nom: 'zejcifcod', largeur: 130 },
            { nom: 'zejunacod', largeur: 150 },
            { nom: 'zejcptcod', largeur: 145 },
            { nom: 'zejel1cod', largeur: 140 },
            { nom: 'zejel2cod', largeur: 140 },
            { nom: 'zejel3cod', largeur: 140 },
            { nom: 'zejgrpecv', largeur: 145 },
            { nom: 'zejdoiqte', largeur: 120 },
            { nom: 'zejnumlig', largeur: 120 },
            { nom: 'zejecvdsc', largeur: 280 },
            { nom: 'zejnummat', largeur: 130 },
            { nom: 'zejaftcod', largeur: 180 },
            { nom: 'zejr01', largeur: 180 },
            { nom: 'zejr02', largeur: 180 },
            { nom: 'zejr03', largeur: 180 },
            { nom: 'zejr04', largeur: 180 },
            { nom: 'zejr05', largeur: 180 },
            { nom: 'zejr06', largeur: 180 },
            { nom: 'zejr07', largeur: 180 },
            { nom: 'zejr08', largeur: 180 },
            { nom: 'zejr09', largeur: 180 },
            { nom: 'zejr10', largeur: 180 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('nbrerr'),
            new FormulaireItem('zliexc'),
            new FormulaireItem('zejecjdat'),
            new FormulaireItem('zejpeccod'),
            new FormulaireItem('zejecjcat'),
            new FormulaireItem('zejdoimntdbt'),
            new FormulaireItem('zejdoimntcrt'),
            new FormulaireItem('zejecjdsc'),
            new FormulaireItem('zejintcod'),
            new FormulaireItem('zejgrpecj'),
            new FormulaireItem('zejprucod'),
            new FormulaireItem('zejprscod'),
            new FormulaireItem('zejcifcod'),
            new FormulaireItem('zejunacod'),
            new FormulaireItem('zejcptcod'),
            new FormulaireItem('zejel1cod'),
            new FormulaireItem('zejel2cod'),
            new FormulaireItem('zejel3cod'),
            new FormulaireItem('zejgrpecv'),
            new FormulaireItem('zejdoiqte'),
            new FormulaireItem('zejnumlig'),
            new FormulaireItem('zejecvdsc'),
            new FormulaireItem('zejnummat'),
            new FormulaireItem('zejaftcod'),
            new FormulaireItem('zejr01'),
            new FormulaireItem('zejr02'),
            new FormulaireItem('zejr03'),
            new FormulaireItem('zejr04'),
            new FormulaireItem('zejr05'),
            new FormulaireItem('zejr06'),
            new FormulaireItem('zejr07'),
            new FormulaireItem('zejr08'),
            new FormulaireItem('zejr09'),
            new FormulaireItem('zejr10')
        ])
    };
}
