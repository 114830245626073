import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0146 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
    foucleint:number;


}
/* @ngInject */
export default function Mc0146Controller(ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMc0146 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'refcod',
                valeur: vm.valeursEcran.refcod,
                visible: false
            },
            {
                colonne: 'refcoddsc',
                valeur: vm.valeursEcran.refcoddsc,
                affichage: true,
                readOnly: true
            }
        ],
        colonnesVisibles: [
            {nom: 'ciecod'   , largeur: 110},
            {nom: 'cienomabr', largeur: 294},
            {nom: 'dscmntcap', largeur: 139, total: true},
            {nom: 'dsrmntcar', largeur: 139, total: true}
        ],
        colonnesCachees: [
            'refcod',
            'refnomabr',
            'foucleint'
        ]
    };
}
