import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr2007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr2007Controller(mr0171Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr2007 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0171Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0171Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'rsccod', largeur: 150 },
            { nom: 'rscdsc', largeur: 210 },
            { nom: 'rsccom', largeur: 210 }
        ]
    };
}
