import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0111 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IPageMc0111Params {
    sdvcleintref: number,
    stamnetab: string,
    doccleint: number  // facultatif
}

/* @ngInject */
export default function Mc0111Controller($stateParams: IPageMc0111Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0111 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'sujdsc',    largeur: 250},
            {nom: 'sdvdatvig',    largeur: 250},
            {nom: 'imp', largeur: 350}
        ],
        colonnesCachees: [
            'intcleintreq',  // Pour appliquer la sécurité si un doccleint est passé en paramètre
            'sdvcleintref',
            'stamnetab',
            'doccleint'
        ],
        formulaire: new Formulaire('LBL_EDITION', [
            new FormulaireItem('sujcleint', { largeur : 60 }),
            new FormulaireItem('sdvdatvig', { largeur : 60 }),
            new FormulaireItem('imp'),
            new FormulaireItem('sdvcleintref', {
                hidden: true,
                default: $stateParams.sdvcleintref
            })
        ])
    };
}
