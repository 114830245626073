import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0028Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0028 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'juscod', largeur: 90},
            {nom: 'juslib', largeur: 90},
            {nom: 'jusdsc', largeur: 394},
            {nom: 'jusflgmnt', largeur: 84},
            {nom: 'jusflgact', largeur: 64}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('juscod', {required: true}),
            new FormulaireItem('juslib', {required: true}),
            new FormulaireItem('jusdsc', {required: true}),
            new FormulaireItem('jusflgmnt', {largeur: 25}),
            new FormulaireItem('jusflgact', {default: 1, largeur: 25}),
        ])
    };
}
