import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageTs0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ts0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTs0001 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'mrrcod', largeur: 90},
            {nom: 'mrrdsc', largeur: 315},
            {nom: 'mrrflgcpt', largeur: 120},
            {nom: 'mrrflgact', largeur: 74},
            {nom: 'mrrflg1rp', largeur: 95},
            {nom: 'mrrflg2rp', largeur: 90},
            {nom: 'mrrflggenind', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mrrcod', {required: true}),
            new FormulaireItem('mrrdsc', {required: true, largeur: 50}),
            new FormulaireItem('mrrflgact', {default: 1}),
            new FormulaireItem('mrrflgcpt'),
            new FormulaireItem('mrrflg1rp'),
            new FormulaireItem('mrrflg2rp'),
            new FormulaireItem('mrrflggenind')
        ], {largeurDefaut: 25})
    };
}
