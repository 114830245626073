import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0127 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0127Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0127 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche:[
            {nom: 'rfecod', largeur: 231},
        ],
        colonnesVisibles: [
            {nom: 'rfenom', largeur: 263},
            {nom: 'rfenomabr', largeur: 263},
            {nom: 'vaerfetyp', largeur: 192},
            {nom: 'rfecodcietrf', largeur: 58},
            {nom: 'rfenumtrf', largeur: 100},
            {nom: 'vaerfeindtrf', largeur: 158},
            {nom: 'rfeadrteltrf', largeur: 263}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rfecod'),
            new FormulaireItem('rfenom', ),
            new FormulaireItem('rfenomabr'),
            new FormulaireItem('vaerfetyp'),
            new FormulaireItem('rfecodcietrf'),
            new FormulaireItem('rfenumtrf'),
            new FormulaireItem('vaerfeindtrf'),
            new FormulaireItem('rfeadrteltrf')

        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
