import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageMc0076 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    pacMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0076Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0076 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'inddsc', largeur: 300 },
            { nom: 'indflgctl', largeur: 110 },
            { nom: 'indflgpri', largeur: 90 },
            { nom: 'indflgcen', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('inddsc', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('indflgctl', { largeur: 25 }),
            new FormulaireItem('indflgpri', { largeur: 25 }),
            new FormulaireItem('indflgcen', { largeur: 25 })
        ])
    };

    vm.pacMultiOccurrenceOptions = {
        bloc: 'PAC',
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 230 },
            { nom: 'pacordaff', largeur: 100 },
            { nom: 'vaepacimpbdg_dsc', largeur: 90 },
            { nom: 'pacflgprcdep', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyhcleint'),
            new FormulaireItem('pacordaff'),
            new FormulaireItem('vaepacimpbdg'),
            new FormulaireItem('pacflgprcdep')
        ], { largeurDefaut: 25 })
    };

}
