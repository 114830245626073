import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageAc0081 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0081Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ac0028Route: IRoute) {
    const vm: IPageAc0081 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        navigatePage: ac0028Route.NAME,
        navigateParams: (rowData: any) => ({
            apocleint: rowData.apocleint,
            id: rowData.doccleintref
        }),

        criteresSuggeres: new Formulaire([
            new FormulaireItem('aff_ecart_zero_cri', { default: 0, largeur: 50 })
        ]),

        colonnesVisibles: [
            {nom: 'aponumdoc', largeur: 30},
            {nom: 'apodsc', largeur: 120},
            {nom: 'mnt_tot_doc_refere', largeur: 30},
            {nom: 'mnt_ini_declare', largeur: 30},
            {nom: 'mnt_ecart_ini', largeur: 30},
            {nom: 'mnt_tot_declaration', largeur: 30},
            {nom: 'mnt_ecart', largeur: 30},
        ],
        colonnesCachees:[
            'doccleintref'
        ],

    };
}
