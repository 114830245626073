import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";

interface IPageCr0042 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRvsOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0042Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    Menu: IMenuClass,
    mc0046Route: IRoute
) {
    const vm: IPageCr0042 = this;

    vm.multiOccurrenceRvsOptions = {
        bloc: 'rvs',
        fonctions: {
            nouveau: true
        },
        colonnesVisibles: [
            { nom: 'vaetrmsai_dsc', largeur: 25 },
            { nom: 'moisdeb', largeur: 25 },
            { nom: 'moisfin', largeur: 25 },
            { nom: 'moisrev', largeur: 25 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaetrmsai'),
            new FormulaireItem('rvsmoideb'),
            new FormulaireItem('rvsmoifin'),
            new FormulaireItem('rvsmoirev')
        ], { largeurDefaut: 25 })
    };

    vm.multiOccurrenceOptions = {

        colonnesVisibles: [
            { nom: 'rrvcod', largeur: 110 },
            { nom: 'rrvdsc', largeur: 190 }
        ],
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => {
                    return {
                        vaeusient: 'RRV',
                        uvicleintref: data.rrvcleint
                    };
                },
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),
        formulaire: new Formulaire([
            new FormulaireItem('rrvcod', { largeur: 25 }),
            new FormulaireItem('rrvdsc', { largeur: 75 })
        ]),
        fonctions: {
            rechercheCollapsable: false,
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceRvsOptions
    };
}
