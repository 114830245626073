import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2001',
    ecrans: ['00-2'],
    params: ['?type', '?prucleint', '?prbcleint', '?prvcleint', '?vaesysnatctb', '?tymcleint', '?tydcleint', '?pecann',
             '?elecleint', 
             '?niveau', 
             '?annper_deb', 
             '?annper_fin', 
             '?cummntsum',
             '?prov',
            '?blocleint']    
});
