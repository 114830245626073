import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGs0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0003Controller() {
    const vm: IPageGs0003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'usrcod', largeur: 100 },
            { nom: 'usrnom', largeur: 190 },
            { nom: 'suedat', largeur: 130 },
            { nom: 'sueprv', largeur: 310 },
            { nom: 'suedsc', largeur: 780 }
        ]
    };
}
