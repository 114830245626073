import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0163 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    pfdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0163Controller(Menu: IMenuClass,
                                         $stateParams: any,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0163 = this;

    // Détail
    vm.pfdMultiOccurrenceOptions = {
        bloc: 'pfd',
        colonnesVisibles: [
            {nom: 'rcecod'   , largeur: 150},
            {nom: 'rcedscabr', largeur: 300},
            {nom: 'pfdflgobl', largeur: 130},
            {nom: 'pfdflgcpl', largeur: 130}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rcecleint', {largeur: 50}),
            new FormulaireItem('pfdflgobl', {largeur: 25}),
            new FormulaireItem('pfdflgcpl', {largeur: 25})
        ])
    };

    // Profil de RCE
    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'pfrcleint', valeur: $stateParams.reference, visible: false}
        ],
        colonnesVisibles: [
            {nom: 'pfrdsc'   , largeur: 400},
            {nom: 'pfrflgdef', largeur: 150}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pfrdsc'   , {largeur: 75}),
            new FormulaireItem('pfrflgdef', {largeur: 25})
        ]),
        multiOccurrenceOptionsEnfant: vm.pfdMultiOccurrenceOptions
    };
}
