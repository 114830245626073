import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGS0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0028Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGS0028 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'srcdsc', largeur: 262 },
            { nom: 'srccod', largeur: 135 },
            { nom: 'ticcod', largeur: 135 },
            { nom: 'ticdsc', largeur: 262 },
            { nom: 'astordaff', largeur: 100 },
            { nom: 'astflgsai', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem("srccleint"),
            new FormulaireItem("ticcleint", { resourceParams: (data: any) => ({ srccleint: data.srccleint }) }),
            new FormulaireItem("astordaff"),
            new FormulaireItem("astflgsai")
        ])
    };
}
