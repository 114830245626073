// Module
var code = `<ex-mono-occurrence-ecran formulaire="::vm.formulaireDcs" mono-occurrence-out="::vm.monoOccurrence"> <ex-mono-occurrence-ecran-gauche> <ex-card lbl-titre="LBL_BLC_VIC"> <ex-card-content> <ex-data-list largeur="50" menus="::vm.menus" data="::vm.valeursEcran" cols="::[
                                'accdat',
                                'accnumdoc',
                                'eplnummat_nomprn',
                                'lemcod_dsc',
                                'aftcod_dsc',
                                'unocod_dscabr',
                                'dalnumdoc_dsc'
                              ]"> </ex-data-list> </ex-card-content> </ex-card> </ex-mono-occurrence-ecran-gauche> <ex-mono-occurrence-ecran-droite> <ex-card lbl-titre="LBL_DIV_AUT"> <ex-card-content> <ex-data-list menus="::vm.menus" cols="::['aut__nbrdia']"> </ex-data-list> </ex-card-content> </ex-card> <ex-card-saisie name="datcon" no-header="true" formulaire="::vm.formulaireDatcon" class="ex-card-saisie-formulaire"> </ex-card-saisie> </ex-mono-occurrence-ecran-droite> <ex-mono-occurrence-ecran-bas> <ex-dashboard-card> <ex-dashboard-card-content-left> <ex-card-saisie name="doscss" formulaire="::vm.formulaireDoscss"> </ex-card-saisie> </ex-dashboard-card-content-left> <ex-dashboard-card-content-right> <ex-card-saisie name="dpc" formulaire="::vm.formulaireDpc"> </ex-card-saisie> </ex-dashboard-card-content-right> </ex-dashboard-card> <ex-dashboard-card> <ex-dashboard-card-content-left> <ex-card-saisie name="rdcrad" formulaire="::vm.formulaireRdcrad"> </ex-card-saisie> </ex-dashboard-card-content-left> <ex-dashboard-card-content-right> <ex-card-saisie name="rpcrad" formulaire="::vm.formulaireRpcrad"> </ex-card-saisie> </ex-dashboard-card-content-right> </ex-dashboard-card> </ex-mono-occurrence-ecran-bas> <ex-template slot="boutonClassification"> <ex-menu-item menu-item="::vm.recapitulatif"></ex-menu-item> </ex-template> </ex-mono-occurrence-ecran> `;
// Exports
module.exports = code;