import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'ts0008',
    ecrans: ['00-2',
             '01-2'
    ],
    params: [
        'id',
        '?grrcod'
    ]
});
