import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireTemplateSlotClass} from "../../../../../core/services/formulaire/formulaire-template-slot.service";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageMi0009 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireHld: IFormulaire;
    formulaireNiv: IFormulaire;
    formulaireTym: IFormulaire;
    formulaireTyr: IFormulaire;
    formulairePaf: IFormulaire;
}

/* @ngInject */
export default function Mi0009Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMi0009 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Formule dépense
        const ouvrirFdep = new MenuItemAction(
            'LBL_FORMULE_DEP',
            'BOUENRDEP',
            () => ({}),
            {
                //saveFirst: true,
                icon: 'create',
                champsRechargement: [
                    't_formule_dep',
                    'migeledep1',
                    'migsigdep2',
                    'migeledep2',
                    'migsigdep3',
                    'migeledep3',
                    'migsigdep4',
                    'migeledep4',
                    'migsigdep5',
                    'migeledep5',
                    'migdeparr',
                ],
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_FORMULE_DEP',
                    lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItemSautDeColonne({largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeledep1', {largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigdep2', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeledep2', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigdep3', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeledep3', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigdep4', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeledep4', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigdep5', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeledep5', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItemSautDeColonne({largeur: 20, minWidth: 100}),
                        new FormulaireItem('migdeparr', {largeur: 50})
                    ], {largeurDefaut: 10})
                }
            } );

        // Formule à financer
        const ouvrirFda = new MenuItemAction(
            'LBL_FORMULE_DA',
            'BOUENRFIN',
            () => ({}),
            {
                //saveFirst: true,
                icon: 'create',
                champsRechargement: [
                    't_formule_fin',
                    'migelefin1',
                    'migsigfin2',
                    'migelefin2',
                    'migsigfin3',
                    'migelefin3',
                    'migsigfin4',
                    'migelefin4',
                    'migsigfin5',
                    'migelefin5',
                    'migsigfin6',
                    'migelefin6',
                    'migsigfin7',
                    'migelefin7',
                    'migsigfin8',
                    'migelefin8',
                    'migsigfin9',
                    'migelefin9',
                    'migsigfin10',
                    'migelefin10',
                    'migfinarr',
                ],
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_FORMULE_DA',
                    lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItemSautDeColonne({largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin1', {largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin2', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin2', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin3', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin3', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin4', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin4', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin5', {titre:' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin5', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin6', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin6', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin7', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin7', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin8', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin8', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin9', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin9', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigfin10', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migelefin10', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItemSautDeColonne({largeur: 20, minWidth: 100}),
                        new FormulaireItem('migfinarr', {largeur: 50})
                    ], {largeurDefaut: 10})
                }
            } );

        // Formule autre
        const ouvrirFaut = new MenuItemAction(
            'LBL_FORMULE_AUT',
            'BOUENRAUT',
            () => ({}),
            {
                //saveFirst: true,
                icon: 'create',
                champsRechargement: [
                    't_formule_aut',
                    'migeleaut1',
                    'migsigaut2',
                    'migeleaut2',
                    'migsigaut3',
                    'migeleaut3',
                    'migsigaut4',
                    'migeleaut4',
                    'migsigaut5',
                    'migeleaut5',
                    'migautarr',
                ],
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_FORMULE_AUT',
                    lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                    largeurFormulaire: 40,
                    formulaire: new Formulaire([
                        new FormulaireItemSautDeColonne({largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeleaut1', {largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigaut2', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeleaut2', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigaut3', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeleaut3', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigaut4', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeleaut4', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('migsigaut5', {titre: ' ', largeur: 20, minWidth: 100}),
                        new FormulaireItem('migeleaut5', {titre: ' ', largeur: 50}),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItemSautDeColonne({largeur: 20, minWidth: 100}),
                        new FormulaireItem('migautarr', {largeur: 50})
                    ], {largeurDefaut: 10})
                }
            } );

        vm.monoOccurrenceOptions = {
            id: vm.valeursEcran.hlecleint
        };

        vm.formulaireHld = new Formulaire('LBL_BLC_HLD', [
                new FormulaireTemplateSlot('entete')
            ]
        );

        vm.formulaireNiv = new Formulaire('LBL_DIV_NDEC', [
                new FormulaireGroupe(new Formulaire([
                    new FormulaireItem('mifcleintfin'),
                    new FormulaireItem('mifcleintfie')
                ], {largeurDefaut: 100}), {largeur: 50}),
                new FormulaireGroupe(new Formulaire([
                    new FormulaireItem('mifcleintfma'),
                    new FormulaireItem('mifclefmaemp'),
                    new FormulaireItem('mifcleintnma'),
                    new FormulaireItem('mifcleintoma')
                ], {largeurDefaut: 100}), {largeur: 50}),
                new FormulaireItem('mifcleintent', {largeur: 100})
            ]
        );

        vm.formulaireTym = new Formulaire('LBL_DIV_TMNT', [
                new FormulaireItem('miicleintenv', {largeur: 50}),
                new FormulaireItem('miicleintfin', {largeur: 50}),
                new FormulaireItem('miicleintcou', {largeur: 50}),
                new FormulaireItem('miicleintfie', {largeur: 50}),
                new FormulaireItem('miicleintcoa', {largeur: 50}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('miicleintcop', {largeur: 50})
            ]
        );

        vm.formulaireTyr = new Formulaire('LBL_DIV_TREF', [
                new FormulaireItem('tyrcleintpls', {largeur: 75}),
                new FormulaireItem('tyrcleintpol', {largeur: 75}),
                new FormulaireItem('tyrcleintobl', {largeur: 75}),
                new FormulaireItem('tyrcleintnfp', {largeur: 75}),
                new FormulaireItem('tyrcleintsoa', {largeur: 75}),
                new FormulaireItem('tyrcleintnaa', {largeur: 75}),
                new FormulaireItem('tyrcleintgrb', {largeur: 75}),
                new FormulaireItem('tyrcleintgrr', {largeur: 75}),
                new FormulaireItem('tyrcleintsub', {largeur: 75})
            ]
        );

        vm.formulairePaf = new Formulaire('LBL_DIV_PARF', [
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_DEPENSE', new Formulaire([
                new FormulaireItem('midcoldep1', {largeur: 60}),
                new FormulaireItem('midflgimpdep1'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcoldep2', {largeur: 60}),
                new FormulaireItem('midflgimpdep2'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcoldep3', {largeur: 60}),
                new FormulaireItem('midflgimpdep3'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcoldep4', {largeur: 60}),
                new FormulaireItem('midflgimpdep4'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcoldep5', {largeur: 60}),
                new FormulaireItem('midflgimpdep5')
            ], {
                largeurDefaut: 20
            })),
            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRE', new Formulaire([
                new FormulaireItem('midcolaut1', {largeur: 60}),
                new FormulaireItem('midflgimpaut1'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcolaut2', {largeur: 60}),
                new FormulaireItem('midflgimpaut2'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcolaut3', {largeur: 60}),
                new FormulaireItem('midflgimpaut3'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcolaut4', {largeur: 60}),
                new FormulaireItem('midflgimpaut4'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('midcolaut5', {largeur: 60}),
                new FormulaireItem('midflgimpaut5')
            ], {
                largeurDefaut: 20
            })),
            new FormulaireItem('t_formule_dep', {
                default: vm.valeursEcran.formule_dep,
                largeur: 100,
                titre: '',
                menu:[ouvrirFdep]
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_formule_aut', {
                default: vm.valeursEcran.formule_aut,
                largeur: 100,
                titre: '',
                menu:[ouvrirFaut]
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_formule_fin', {
                default: vm.valeursEcran.formule_fin,
                largeur: 100,
                titre: '',
                menu:[ouvrirFda]
            })
        ])
    }
}
