import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageGs0042 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0042Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0042 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'srcdsc', largeur: 262},
            {nom: 'srccod', largeur: 100},
            {nom: 'gnocod', largeur: 120},
            {nom: 'gnodsc', largeur: 262},
            {nom: 'asrordaff', largeur: 75}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('srccleint',{largeur: 40}),
            new FormulaireItem('gnocleint',{largeur: 40}),
            new FormulaireItem('asrordaff',{largeur: 20})
        ])
    };
}
