import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';

interface IPageRR0063 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0063Controller(rr0064Route: IRoute,
                                         Menu: IMenuClass,
                                         Operateur: IOperateurService,
                                         $stateParams: IStateParamsService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0063 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: rr0064Route.NAME,
        navigateParams: (rowData: any) => ({
            id: rowData.dbecleint,
            demcleint: rowData.demcleint
        }),
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0064Route.NAME, () => {
                return {
                    demcleint: $stateParams.employe
                };
            })
        ]),
        filtres: [
            {
                colonne: 'dbeflgact',
                operateur: Operateur.EGALE,
                // La configuration 'annulé' sert à gérer le filtre 'actif'... C'est comme ça dans SOFE 1.
                valeur: ecranDetails.valeurs.pmrflgexcann ? '1' : ''
            }
        ],
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprnusu', largeur: 262, hidden: $stateParams.employe},
            {nom: 'bqecod', largeur: 90},
            {nom: 'bqedscabr', largeur: 262},
            {nom: 'dbedatdeb', largeur: 100},
            {nom: 'dbedatfin', largeur: 100},
            {nom: 'dbeqteeva', largeur: 126},
            {nom: 'dbscod1', largeur: 115},
            {nom: 'dbsdsc1', largeur: 262},
            {nom: 'dbscod2', largeur: 115},
            {nom: 'dbsdsc2', largeur: 262},
            {nom: 'dbecom', largeur: 328},
            {nom: 'dbeflgact', largeur: 65}
        ],
        colonnesCachees: [
            'dbecleint',
            'demcleint'
        ]
    };
}
