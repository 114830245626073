import { IComponentController } from "angular";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPagePb2000 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    csdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pb2000Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPagePb2000 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_CSA', [
            new FormulaireItem('csaann'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('csacom',{largeur: 90})          
        ], { largeurDefaut: 33.33 })
    };

    vm.csdMultiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            edition: ecranDetails.valeurs.flgcbe,
            nouveau: ecranDetails.valeurs.flgcbe,
            supprime: ecranDetails.valeurs.flgcbe,            
            boutonDupliquer: false,
        },
        colonnesVisibles: [  
            { nom: 'urgcod', largeur: 150 },
            { nom: 'grecod', largeur: 140 }, 
            { nom: 'gredsc', largeur: 220 },
            { nom: 'strcod', largeur: 150 },
            { nom: 'strdsc', largeur: 200 },
            { nom: 'prccal', largeur: 140 },
            { nom: 'csdprccalfor', largeur: 120 }
           
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint', {largeur: 33}),
            new FormulaireItem('grecleint',{largeur: 33}),
            new FormulaireItem('strcleint',{largeur: 33}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgcalfor', {default: (data: any) => data.csdprccalfor}),
            new FormulaireItem('csdprccalfor',{largeur: 33})
        ], { largeurDefaut: 25 })
    };
}