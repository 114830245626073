import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAS0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0020Controller(Menu: IMenuClass) {
    const vm: IPageAS0020 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            { nom: 'ahtcod', largeur: 120 },
            { nom: 'ahtdsc', largeur: 170 },
            { nom: 'ahthresem', largeur: 190 },
            { nom: 'unccod', largeur: 140 },
            { nom: 'uncdsc', largeur: 262 },
            { nom: 'vaeahtmandsc', largeur: 155 }
        ]
    };
}
