import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMr0235 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0235Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0235 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cvtcod', largeur: 105},
            {nom: 'cvtdsc', largeur: 210},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 184},
            {nom: 'vaecsstyp_dsc', largeur: 116},
            {nom: 'cssflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
                new FormulaireItem('cvtcleint', {required: true,modifAllow:false, largeur: 25}),
                new FormulaireItem('strcleint', {required: true, modifAllow:false, largeur: 25}),
                new FormulaireItem('vaecsstyp', {required: true,largeur: 25}),
                new FormulaireItem('cssflgact', {required: true, default: 1, largeur: 25})
            ]
        )
    };
}
