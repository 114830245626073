import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageRr0014 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireEfo: IFormulaire;
    vblMultiOccurrenceOptions: IMultiOccurrenceOptions;
    efdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IRr0014Params {
    id: string;
    demcleint: string;
    trncleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0014Controller($stateParams: IRr0014Params,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    Pagination: IPaginationClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageRr0014 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            reloadPage: true,
            formulaire: new Formulaire('LBL_INFOS', [
                new FormulaireItem('efodatvig', {
                    largeur: 50
                }),
                new FormulaireItem('demcleint', {
                    default: $stateParams.demcleint
                }),
                new FormulaireItem('aut__efoflgaprreq', {
                    default: vm.valeursEcran.efoflgaprreq,
                    readonly: true
                })
            ]),
            actionsMore: new Menu([
                new MenuItemAction('Approuver', 'BOUUSRAPR', () => ({}), {
                    icon: 'check',
                    successAction() {
                        vm.monoOccurrence.data.aut__efoflgaprreq = false;
                    },
                    hidden: (data: any) => !data.aut__efoflgaprreq
                })
            ])
        }

        // Infos bancaires
        vm.efdMultiOccurrenceOptions = {
            bloc: 'efd',
            reloadEcran: true,
            reloadWithBlocs: ['EFO'],
            fonctions: {
                pagination: true,
                editionRapide: true,
                exportation: false
            },
            editionRapideActive: vm.valeursEcran.efdflgrec,
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),
            formulaire: new Formulaire([
                new FormulaireItem('inscleint', { largeur: 40 }),
                new FormulaireItem('succleint', { largeur: 20 }),
                new FormulaireItem('efdcptbqe'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('efdprc'),
                new FormulaireItem('efdseq'),
                new FormulaireItem('efdmnt'),
                new FormulaireItem('efdflgtrf', { default: 1 })
            ], { largeurDefaut: 20 }),
            colonnesVisibles: [
                { nom: 'inscod', largeur: 100 },
                { nom: 'insdsc', largeur: 260 },
                { nom: 'succod', largeur: 105 },
                { nom: 'sucdsc', largeur: 230 },
                { nom: 'efdcptbqe', largeur: 125 },
                { nom: 'efdprc', largeur: 80 },
                { nom: 'efdseq', largeur: 80 },
                { nom: 'efdmnt', largeur: 126 },
                { nom: 'efdflgtrf', largeur: 91 }
            ],
            colonnesEditionRapide: [
                { nom: 'inscleint', largeur: 260 },
                { nom: 'succleint', largeur: 260 },
                { nom: 'efdcptbqe', largeur: 125 },
                { nom: 'efdprc', largeur: 90 },
                { nom: 'efdseq', largeur: 80 },
                { nom: 'efdmnt', largeur: 131 },
                { nom: 'efdflgtrf', largeur: 91, default: 1 }
            ]
        };
    }
}
