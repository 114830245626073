interface IPageGS0089 {
    app: string;
    sheet: string;
}
/* @ngInject */
export default function Gs0089Controller($stateParams: any) {
    const vm: IPageGS0089 = this;

    [vm.app, vm.sheet] = $stateParams.id.split(',');
}
