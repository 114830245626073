import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageDo0081 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0081Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0081 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'crxcod', largeur: 140},
            {nom: 'crxdsc', largeur: 360},
            {nom: 'gcrcod', largeur: 160},
            {nom: 'gcrdsc', largeur: 220},
            {nom: 'crxflgact', largeur: 80}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('crxcod', {required: true}),
            new FormulaireItem('crxdsc', {required: true}),
            new FormulaireItem('gcrcleint', {required: true}),
            new FormulaireItem('crxflgact', {default: 1})
        ], {largeurDefaut: 25}),
        fonctions: {
            suiviModification: true
        }
    };
}
