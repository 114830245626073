import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as0025',
    ecrans: ['00-2'],
    params: [
        '?asgcleint',
        '?demcleint'
    ]
});
