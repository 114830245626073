import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageMC0061 extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0061Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemSelection: IMenuItemSelectionClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Menu: IMenuClass
) {
    const vm: IPageMC0061 = this;

    vm.multiOccurrenceOptions = {
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_CREER_IMMO', 'BOUACTCREIMM', {
                selection: {
                    nom: 'actionsCreer',
                    lblConfirmationTitre: 'LBL_BTN_CREER_IMMO',
                    largeurFormulaire: 50,
                    confirmationFormulaire: new Formulaire(
                        [
                            new FormulaireItem('prcdep', { maxlength: 50 }),
                            new FormulaireItem('mntdep', { maxlength: 50 }),
                            new FormulaireItemSautDeLigne(),
                            new FormulaireItem('flgcmp', { maxlength: 50 }),
                            new FormulaireItem('flgadm', { maxlength: 50 })
                        ],
                        { largeurDefaut: 33 }
                    )
                }
            })
        ]),
        colonnesVisibles: [
            { nom: 'prucod', largeur: 89 },
            { nom: 'prudscl01', largeur: 315 },
            { nom: 'prudatfin', largeur: 89 },
            { nom: 'prscod', largeur: 89 },
            { nom: 'prsdscl01', largeur: 278 }
        ],
        colonnesCachees: ['prscleintref'],
        fonctions: {
            rechercheCollapsable: false,
            exportation: true,
            criteresSuggeresVisibles: true,
            reinitialiserOuvrirCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaeprutypbdg', { required: false }),
            new FormulaireItem('flgubr'),
            new FormulaireItem('orgcodnum'),
            new FormulaireItem('cifcod'),
            new FormulaireItemSautDeColonne({ largeur: 33 }),
            new FormulaireItem('orpcodnum', {
                resourceParams: (data: any) => {
                    return {
                        orgcodnum: data.orgcodnum
                    };
            }}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('foncodnum'),
            new FormulaireItemSautDeColonne({ largeur: 33 }),
            new FormulaireItem('prscleintref', { required: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unacoddeb'),
            new FormulaireItem('unacodfin'),
            new FormulaireItem('blocodnum', { required: true }),
            new FormulaireItemDivider('LBL_CREATION'),
            new FormulaireItem('cptcodnum', { required: true })
        ])
    };
}
