import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCb0038 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    prvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tymMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tydMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0038Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Pagination: IPaginationClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cb2004Route: IRoute,
    cb0026Route: IRoute,
    cb0031Route: IRoute,
    $stateParams: IStateParamsService
) {
    const vm: IPageCb0038 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.tymMultiOccurrenceOptions = {
        bloc: 'tym',
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 250 },
            { nom: 'cegmntsum', largeur: 130 },
            { nom: 'cegmntimp', largeur: 120, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        })
    };

    vm.prvMultiOccurrenceOptions = {
        bloc: 'prv',
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0026',
                cb0026Route.NAME,
                (rowData:any) => ({
                    id: $stateParams.prucleint,
                    typapp: 'ELV',
                    typbdg: 'AN',
                    prucleint: $stateParams.prucleint,
                    el1cleint: $stateParams.el1cleint,
                    prbcleint: $stateParams.prbcleint,
                    prvcleint: rowData.prvcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    peccleintdeb: $stateParams.peccleintdeb,
                    peccleintfin: $stateParams.peccleintfin,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0026',
                    icon: 'exit_to_app'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0031',
                cb0031Route.NAME,
                (rowData:any) => ({
                    id: $stateParams.prucleint,
                    typapp: 'ELV',
                    typbdg: 'AN',
                    prucleint: $stateParams.prucleint,
                    el1cleint: $stateParams.el1cleint,
                    prbcleint: $stateParams.prbcleint,
                    prvcleint: rowData.prvcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    peccleintdeb: $stateParams.peccleintdeb,
                    peccleintfin: $stateParams.peccleintfin,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_ACT_FORAGE_CB0031',
                    icon: 'exit_to_app'
                }
            )
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('el1cleintcri', {
                default: $stateParams.el1cleint,
                hidden: true
            }),
            new FormulaireItem('prucleintcri', {
                default: $stateParams.prucleint,
                hidden: true
            }),
            new FormulaireItem('prbcleintcri', {
                default: $stateParams.prbcleint,
                hidden: true
            }),
            new FormulaireItem('peccleintdebcri', {
                default: $stateParams.peccleintdeb,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A'),
                readonly: true
            }),
            new FormulaireItem('peccleintfincri', {
                default: $stateParams.peccleintfin,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'A'),
                readonly: true
            }),
            new FormulaireItem('peccleintcivdeb', {
                default: $stateParams.peccleintdeb,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P'),
                readonly: true
            }),
            new FormulaireItem('peccleintcivfin', {
                default: $stateParams.peccleintfin,
                hidden: () => !(vm.valeursEcran.vaeprutypbdg === 'P'),
                readonly: true
            }),
            new FormulaireItem('indcleintcri', {
                default: $stateParams.indcleint,
                readonly: true
            }),
            new FormulaireItem('vaesysnatctbcri', {
                default: $stateParams.vaesysnatctb,
                readonly: true
            })
        ]),
        colonnesVisibles: [
            { nom: 'cptcod', largeur: 100 },
            { nom: 'cptdsc', largeur: 250 },
            { nom: 'cifcod', largeur: 100 },
            { nom: 'cifdsc', largeur: 250 },
            { nom: 'unacod', largeur: 100 },
            { nom: 'unadsc', largeur: 250 }
        ],
        colonnesFixesDroite: [
            { nom: 'mntsum', largeur: 130, total: true }
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        multiOccurrenceOptionsEnfant: vm.tymMultiOccurrenceOptions
    };

    vm.tydMultiOccurrenceOptions = {
        bloc: 'tyd',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cb2004Route.NAME,
                (rowData: any) => ({
                    type: 'ELV',
                    el1cleint: $stateParams.el1cleint,
                    prucleint: $stateParams.prucleint,
                    prbcleint: rowData.prbcleint,
                    prvcleint: rowData.prvcleint,
                    vaesysnatctb: $stateParams.vaesysnatctb,
                    tymcleint: rowData.tymcleint,
                    tydcleint: rowData.tydcleint,
                    peccleintdeb: rowData.peccleintdeb,
                    peccleintfin: rowData.peccleintfin
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'tyddsc', largeur: 130 },
            { nom: 'cegmntsum', largeur: 120 }
        ],
        colonnesCachees: [
            'prucleint',
            'prbcleint',
            'prvcleint',
            'vaesysnatctb',
            'tymcleint',
            'tydcleint',
            'peccleintdeb',
            'peccleintfin'
        ]
    };
}
