import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IStateParamsService} from 'angular-ui-router';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IMenuItemFactoryClass} from '../../../../../core/services/menu/menu-item-factory.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageRR0153 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    detMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0153Controller(Menu: IMenuClass,
                                         rr0004Route: IRoute,
                                         rr0006Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemFactory: IMenuItemFactoryClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         dateFilter: IFilterDate,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageRR0153 = this;

    // Forage Lien d'emploi
    const forageLienEmploi = new MenuItemForage(
        'LBL_FOR_POSDAT',
        rr0004Route.NAME,
        (data:any) => ({
            id: data.trncleint_lem
        }),
        { hidden: (data: any) => !data.trncleint_lem || data.flglemmul}
    );

    // Forage vers l'affectation
    const forageAft = new MenuItemForage(
        'LBL_FOR_AFT',
        rr0006Route.NAME,
        (data:any) => ({
            id: data.trncleint_aft
        }),
        { hidden: (data: any) => !data.trncleint_aft || data.flgaftmul}
    );

    const forageAftLem = new MenuItemForage(
        'LBL_DETAIL',
        (data: any) => getForage(data),
        (data: any) => getParams(data)
    );

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_datsld', {
                required: true,
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            }),
            new FormulaireItem('t_urgcleint', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeColonne()
        ]),

        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'datvacant', largeur: 160},
            {nom: 'datfin4an', largeur: 100},
            {nom: 'sldbqeant', largeur: 150},
            {nom: 'unccodant', largeur: 90},
            {nom: 'sldbqeacc', largeur: 110},
            {nom: 'unccodacc', largeur: 90},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 275},
            {nom: 'bqecodant', largeur: 170},
            {nom: 'bqedscant', largeur: 200},
            {nom: 'bqecodacc', largeur: 170},
            {nom: 'bqedscacc', largeur: 200},
            {nom: 'lemcod', largeur: 120, menu:[forageLienEmploi]},
            {nom: 'flglemmul', largeur: 70},
            {nom: 'strcod', largeur: 90},
            {nom: 'strdsc', largeur: 200},
            {nom: 'aftcod', largeur: 120, menu:[forageAft]},
            {nom: 'flgaftmul', largeur: 75}

        ],
        colonnesFixesDroite: [
            {nom: 'flgchomul', largeur: 100},
            {nom: 'flgina', largeur: 75}
        ],
        colonnesCachees: ['trncleint_lem',
            'trncleint_aft',
            'demcleint',
            'urgcleint',
            'flglemmul']

    };

    vm.detMultiOccurrenceOptions = {
        bloc: 'mul',
        actionsRangeeDroite: new Menu([forageAftLem]),

        colonnesVisibles: [
            {nom: 'dasprv'     , largeur: 120},
            {nom: 'urgcod'     , largeur: 120},
            {nom: 'lemcod'     , largeur: 122},
            {nom: 'strcod'     , largeur: 120},
            {nom: 'aftcod'     , largeur: 120},
            {nom: 'tafcod'     , largeur: 120},
            {nom: 'datdeb'     , largeur: 137},
            {nom: 'datfin'     , largeur: 122},
            {nom: 'avcdsc'     , largeur: 300},
            {nom: 'dasdatvig'  , largeur: 120}
        ],
        colonnesCachees: ['trncleint_lem', 'trncleint_aft']
    };

    function getForage(data: any): string {
        if (data.trncleint_aft) {
            return rr0006Route.NAME;
        } else if (data.trncleint_lem) {
            return rr0004Route.NAME;
        }
    }

    function getParams(data: any) {
        if (data.trncleint_aft) {
            return { id: data.trncleint_aft };
        } else if (data.trncleint_lem) {
            return { id: data.trncleint_lem };
        }
    }
}
