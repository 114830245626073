import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import IFilterDate = angular.IFilterDate;

interface IPageTp0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tp0012Controller(Formulaire: IFormulaireClass,
                                         Operateur: IOperateurService,
                                         Menu: IMenuClass,
                                         dateFilter: IFilterDate,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTp0012 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('ktacleint')
        ]),

        actionsNouveaux: new Menu([]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'ktaannsco', largeur: 160},
            {nom: 'ktadsc', largeur: 180},
            {nom: 'ktpver', largeur: 140},
            {nom: 'ecldsceta', largeur: 140},
            {nom: 'flg_mod', largeur: 100},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 160},
            {nom: 'vaektptyp_dsc', largeur: 100},
            {nom: 'unocod', largeur: 100},
            {nom: 'unodsc', largeur: 160},
            {nom: 'urgcod', largeur: 100},
            {nom: 'urgdscabr', largeur: 160},
            {nom: 'ktpnumdoc', largeur: 100},
            {nom: 'ktpdattrs', largeur: 100},
            {nom: 'ktpdatappdep', largeur: 100},
            {nom: 'ktpcomappdep', largeur: 160},
            {nom: 'ktpdatappfac', largeur: 100},
            {nom: 'ktpcomappfac', largeur: 160},
            {nom: 'valeur_kte', largeur: 100},
            {nom: 'valeur_kth', largeur: 100},
            {nom: 'valeur_ktv', largeur: 100},
            {nom: 'valeur_kti', largeur: 100},
            {nom: 'valeur_ktx', largeur: 100},
            {nom: 'valeur_tot', largeur: 100},
            {nom: 'flgprv', largeur: 100},
            {nom: 'valeur_att', largeur: 100},
            {nom: 'valeur_ecart', largeur: 100},
            {nom: 'valeur_prec', largeur: 100},
            {nom: 'solde_approb', largeur: 100},
            {nom: 'solde_total', largeur: 100},
            {nom: 'trnflgstaref', largeur: 100},
            {nom: 'trnflgstafin', largeur: 100},
            {nom: 'trnflgstafer', largeur: 100},
            {nom: 'trnflgstaann', largeur: 100}
        ]
    };
}
