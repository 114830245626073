import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMR0273 extends IComponentController {
    formulaireTag: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    mtaMultiOccurrence: IMultiOccurrence;
    mtaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0273Params {
    ciecleint: number;
    ciecleintdest: number;
}

/* @ngInject */
export default function Mr0273Controller($stateParams: IMr0273Params,
                                         MenuItemAction: IMenuItemActionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMR0273 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions= {
            fonctions: {
                suiviModification: false,
                exportation: false,
                boutonEnregistrerHaut: false,
                afficherMenusDansEntete: true
            },
            menus: [
                new MenuItemAction(
                    'BTN_COPIE',
                    'BOUCOP',
                    (data: any) => ({
                        tagcleint: data.$id,
                        ciecleint: $stateParams.ciecleint
                    }),
                    {
                        icon: '',
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: () => { vm.mtaMultiOccurrence.fetchDataList(); }
                    }
                ),
            ]
        };

        vm.formulaireTag = new Formulaire([
            new FormulaireItem('tagcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ciecleint', { default: $stateParams.ciecleint }),
            new FormulaireItem('t_ciecleintdest')
        ]);

        // Paramètres
        vm.mtaMultiOccurrenceOptions = {
            bloc: 'mta',
            colonnesVisibles: [
                {nom: 'mtamsg', largeur: 860}
            ]
        };
    }
}
