import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAS0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0015Controller(Menu: IMenuClass, as0005Route: IRoute, MenuItemForage: IMenuItemForageClass) {
    const vm: IPageAS0015 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: as0005Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                urgcleint: rowData.urgcleint
            };
        },
        actionsNouveaux: new Menu([new MenuItemForage(as0005Route.NAME)]),
        colonnesFixesGauche: [
            { nom: 'actcod', largeur: 130 },
            { nom: 'actdsc', largeur: 262 },
            { nom: 'actcodabr', largeur: 90 },
            { nom: 'actseqaffemp', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'actdatdeb', largeur: 120 },
            { nom: 'actdatfin', largeur: 120 },
            { nom: 'taycod', largeur: 120 },
            { nom: 'taydsc', largeur: 262 },
            { nom: 'unccod', largeur: 140 },
            { nom: 'uncdsc', largeur: 250 }
        ]
    };
}
