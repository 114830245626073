import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";

interface IPageGS0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Gs0023Controller(gs2003Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0023 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage: gs2003Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs2003Route.NAME)
        ]),        
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'ticcod', largeur: 150 },
            { nom: 'ticdsc', largeur: 300 }
        ]
    };
}
