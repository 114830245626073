import { IComponentController, IFilterDate } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IFormulaireItemDividerClass } from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";

interface IPageMr2014 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    tapMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tapMultiOccurrence: IMultiOccurrence;
    aviMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aviMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mr2014Controller(Formulaire: IFormulaireClass, 
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    dateFilter: IFilterDate,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    mr0010Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass) {
    const vm: IPageMr2014 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    };

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('intcleintcri', { default: vm.valeursEcran.intcleintcri, ignoreEtat: true, required: true, readonly: true }),
        new FormulaireItem('rolcleintcri'),
        new FormulaireItem('typavicri', {default: 'AVIS'}),
        new FormulaireItemDivider('LBL_CRI_AVANCE'),
        new FormulaireItem('ttccleintcri', { nombreLibellesDetailsCacher: 1 }),
        new FormulaireItem('urgcleintcri'),
        new FormulaireItem('strcleintcri'),
        new FormulaireItem('grpcleintcri'),
        new FormulaireItem('grocleintcri'),
        new FormulaireItem('demcleintcri'),
        new FormulaireItem('avidatdebcri'),
        new FormulaireItem('avidatfincri', { default: dateFilter(new Date(), 'yyyy-MM-dd'), ignoreEtat: true }),
        new FormulaireItem('infoclass')
    ]);

    vm.tapMultiOccurrenceOptions = {
        navigatePage: (data: any) => (`secure.${data.ecrcod}.ecran`),
        navigateParams: (data) => { id: data.trncleint },
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 150},
            {nom: 'typdsc', largeur: 300},
            {nom: 'trndatmax', largeur: 140},
            {nom: 'texref', largeur: 300},
            {nom: 'indpcs', largeur: 60},
            {nom: 'flg_publication', 
                largeur: 100, 
                menu: [ 
                    new MenuItemActionTelechargement(
                        'LBL_TELECHARGER',
                        'BOUPDF',
                        () => ({}),
                        {
                            iconButton: true,
                            icon: 'attach_file',
                            hidden: (data: any) => !data.flg_publication
                        }
                    )
                ] 
            },
            {nom: 'unocod', largeur: 130},
            {nom: 'unodsc', largeur: 250},
            {nom: 'tsadatvig', largeur: 150},
            {nom: 'gredsc', largeur: 130},
            {nom: 'geccat', largeur: 100},
            {nom: 'geccodcla', largeur: 100},
            {nom: 'echcod', largeur: 100},
            {nom: 'tsasalhre', largeur: 130},
            {nom: 'tsasalann', largeur: 130}
        ],
        colonnesCachees: [
            'ecrcod',
            'demcleint',
            'flg_publication'
        ]
    }

    vm.aviMultiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'LBL_BTN_ACTION',
                mr0010Route.NAME,
                (rowData: any) => ({
                    id: rowData.avicleint, 
                    intcleint: vm.valeursEcran.intcleintcri
                    }),
                {
                    icon: 'launch',
                    fonction: 'BOUDOC',
                    iconButton: true,
                    onClose: () => vm.avimultiOccurrence.fetchDataList()
                }
            )
        ]),        
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_SUPPRIMER', 'BOUSUP', {
                selection: {
                    nom: 'supprimer'
                }
            })]),
        fonctions: {
            customCriteresSuggeres: true,
            rechercheCollapsable: false,
            pagination: true
        },
        criteresSuggeres,
        pagination: {
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        },
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 150},
            {nom: 'typdsc', largeur: 250},
            {nom: 'date_avis', largeur: 120},
            {nom: 'avgflglu', largeur: 100},
            {nom: 'texref', largeur: 250},
            {nom: 'unocod', largeur: 120},
            {nom: 'unodsc', largeur: 200},
            {nom: 'avicom', largeur: 250}
        ],
        colonnesCachees: [
            'demcleint'
        ]
    };
}
