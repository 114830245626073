import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAe0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ae0005Controller(Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAe0005 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'corcleint', valeur: $stateParams.reference, visible: false}
        ],
        colonnesVisibles: [
            {nom: 'stamnetab_cod', largeur: 90},
            {nom: 'stamnetab_dsc', largeur: 325},
            {nom: 'comcod', largeur: 120},
            {nom: 'comdsc', largeur: 380},
            {nom: 'corseqaff', largeur: 80},
            {nom: 'corcom', largeur: 400}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('corcleintref', {largeur: 45}),
            new FormulaireItem('comcleint', {largeur: 35}),
            new FormulaireItem('corseqaff', {largeur: 10}),
            new FormulaireItem('corcom', {largeur: 100})
        ]),
        fonctions: {
            importation: true
        }
    };
}
