import { IComponentController, IFilterDate } from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';//
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAc0057 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0057Controller(Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    dateFilter: IFilterDate
    ) {
    const vm: IPageAc0057 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },

        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 110 },
            { nom: 'sysboncmd', largeur: 110 },
            { nom: 'dnmdatrec', largeur: 110 },
            { nom: 'lolnumloc', largeur: 110 },
            { nom: 'loldsc', largeur: 300 },
            { nom: 'dnmnbrcol', largeur: 110 }
        ],

        formulaire: new Formulaire([
            new FormulaireItem('daccleint', { largeur: 50.00 }),
            new FormulaireItem('dnmdatrec', { largeur: 33.33, default: dateFilter(new Date(), 'yyyy-MM-dd') }),
            new FormulaireItem('lolcleint', { largeur: 33.33 }),
            new FormulaireItem('dnmnbrcol', { largeur: 33.33 })
        ], { largeurDefaut: 100 })
    };
}
