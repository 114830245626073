import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGs0093 extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0093Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
) {
    const vm: IPageGs0093 = this;
    vm.formulaireLod = new Formulaire('LBL_LANC', [
        new FormulaireItem('lobcod', {
            largeur: 33
        }),
        new FormulaireItem('lobdsc', {
            largeur: 33
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('lobdoc', {
            largeur: 33,
            required: false
        })
    ]);
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'lodseqaff', largeur: 150 },
            { nom: 'loddsc', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('lodseqaff', {
                largeur: 33
            }),
            new FormulaireItem('loddsc', {
                largeur: 33
            })
        ])
    };
}
