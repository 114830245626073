import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMi0501 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mi0501Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMi0501 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'vilcod', largeur: 100},
            {nom: 'vilnom', largeur: 384},
            {nom: 'vilsiviq', largeur: 100},
            {nom: 'vilflgnov', largeur: 100},
            {nom: 'vilflgexv', largeur: 100},
            {nom: 'vilflgagg', largeur: 100},
            {nom: 'vilflgpro', largeur: 100},
            {nom: 'vilflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vilcod'   , { largeur: 25, required: true}),
            new FormulaireItem('vilnom'   , { largeur: 50, required: true}),
            new FormulaireItem('vilflgact', { largeur: 25, default: 1}),
            new FormulaireItem('vilsiviq'),
            new FormulaireItem('vilflgnov'),
            new FormulaireItem('vilflgexv'),
            new FormulaireItem('vilflgagg'),
            new FormulaireItem('vilflgpro')
        ], {largeurDefaut: 20})
    };
}


