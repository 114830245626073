import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
interface IPageMr0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0014Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    mr2013Route: IRoute) {
    const vm: IPageMr0014 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr2013Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr2013Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [            
            { nom: 'vclcod', largeur: 100 },
            { nom: 'evsdsc', largeur: 230 },
            { nom: 'vacvclreg_cle', largeur: 100 },
            { nom: 'vacvclreg_dsc', largeur: 200 },
            { nom: 'stsdsc', largeur: 300 },
            { nom: 'stsdscabr', largeur: 300 },
            { nom: 'stsdscmnt', largeur: 300 }
        ]
    };
}
