import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAL0009 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dlpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dldMultiOccurrenceOptions: IMultiOccurrenceOptions;
    resMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
    formulaireMsg: any
}



/* @ngInject */
export default function Al0009Controller(
    Formulaire: IFormulaireClass,
    al0010Route: IRoute,
    FormulaireItem: IFormulaireItemClass,
    al0011Route: IRoute,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemDivider: IFormulaireItemDividerClass) {

    const vm: IPageAL0009 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    //Zone message
    vm.formulaireMsg = new Formulaire('zone_msg', [
        new FormulaireItem('zone_msg', {
            largeur: 100,
            default: vm.valeursEcran.zone_msg,
            enableCount: true,
            disabled: true
        })
    ]);
    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_XFD', [
            new FormulaireItem('dalnumdoc', { largeur: 33 }),
            new FormulaireItem('dalseqmodcou', { largeur: 67 }),
            new FormulaireItem('tdlcleint', { largeur: 33 }),
            new FormulaireItem('ttccleint', { largeur: 67 }),
            new FormulaireItem('demcleint', { largeur: 33 }),
            new FormulaireItem('lemcleint', { largeur: 33 }),
            new FormulaireItem('dalprcdif', { largeur: 27 }),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('daldatouv', { largeur: 33 }),
            new FormulaireItem('daldatretprg', { largeur: 67 }),
            new FormulaireItem('daldatdeb', { largeur: 33 }),
            new FormulaireItem('daldatrec', { largeur: 67 }),
            new FormulaireItem('daldatret', { largeur: 100 }),
            new FormulaireItem('daldatfer', { largeur: 33 }),
            new FormulaireItem('dalcom')
        ])
    }

    vm.dlpMultiOccurrenceOptions = {
        bloc: 'dlp',
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                al0010Route.NAME,
                (rowData: any) => ({
                    id: rowData.dlpcleint
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'prfcod', largeur: 105 },
            { nom: 'prfdscabr', largeur: 250 },
            { nom: 'dlpdatdeb', largeur: 79 },
            { nom: 'dlpdatfin', largeur: 79 },
            { nom: 'nbrjrs', largeur: 100 },
            { nom: 'dlpseqmod', largeur: 100 },
            { nom: 'dlpflgact', largeur: 100 }
        ],
        colonnesCachees: ['dlpcleint']
    }
    vm.dldMultiOccurrenceOptions = {
        bloc: 'dld',
        colonnesFixesGauche: [
            { nom: 'dlddat', largeur: 105 }
        ],
        colonnesVisibles: [
            { nom: 'prfcod', largeur: 105 },
            { nom: 'prfdscabr', largeur: 262 },
            { nom: 'alinbrann', largeur: 79 },
            { nom: 'alinbrmoi', largeur: 70 },
            { nom: 'alinbrjrs', largeur: 70 },
            { nom: 'alidsc', largeur: 273 },
            { nom: 'vacvalcle_code', largeur: 273 },
            { nom: 'vacdsc', largeur: 273 },
            { nom: 'dldcom', largeur: 273 }
        ],
        colonnesFixesDroite: [
            { nom: 'dldflgact', largeur: 100 }
        ]
    }
    vm.resMultiOccurrenceOptions = {
        bloc: 'res',
        fonctions: {
            supprime: false,
            edition: false
        },
        colonnesVisibles: [
            { nom: 'prfcod', largeur: 105 },
            {
                nom: 'prfdscabr', largeur: 160,
                menu: [
                    new MenuItemForage(
                        'LBL_RESERVE',
                        al0010Route.NAME,
                        (rowData: any) => ({
                            id: rowData.dlpcleint
                        }),
                        {
                            fonction: 'RESERVES',
                            icon: 'launch',
                            iconButton: true,
                            disabled: (rowData: any) => rowData.cumul === null
                        }
                    )
                ]
            },
            { nom: 'vaedlitypdatald_des', largeur: 262 },
            { nom: 'dlidatdeb', largeur: 100 },
            { nom: 'dlidatfin', largeur: 100 },
            { nom: 'dlhdatvig', largeur: 100 },
            { nom: 'dlhpctrep', largeur: 100 },
            { nom: 'dhdqteabs', largeur: 100 },
            { nom: 'dhdqtetra', largeur: 100 },
            {
                nom: 'cumul',
                titre: ' ',
                largeur: 15,
                menu: [
                    new MenuItemForage(
                        'LBL_RESERVE',
                        al0011Route.NAME,
                        (rowData: any) => ({
                            id: rowData.dlhcleint,
                        }),
                        {
                            fonction: 'RESERVES',
                            icon: 'launch',
                            iconButton: true,
                            disabled: (rowData: any) => rowData.cumul === null
                        }
                    )
                ]
            },
        ],
        colonnesCachees: ['dlhcleint', 'dlpcleint']

    }
}
