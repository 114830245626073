import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageDb2005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db2005Controller() {
    const vm: IPageDb2005 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'blocleint', largeur: 100 },
            { nom: 'orgcleint', largeur: 100 },
            { nom: 'soncleint', largeur: 100 },
            { nom: 'imp', largeur: 230 },
            { nom: 'tydcod', largeur: 130 },
            { nom: 'docaffnumdoc01', largeur: 150 },
            { nom: 'refcod', largeur: 160 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'docaffdsc01', largeur: 150 },
            { nom: 'pecann', largeur: 80 },
            { nom: 'pecper', largeur: 80 },
            { nom: 'numref', largeur: 130 },
            { nom: 'categ', largeur: 130 },
            { nom: 'categ_desc', largeur: 180 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 130 },
            { nom: 'mntgb', largeur: 130 },
            { nom: 'mntrg', largeur: 130 },
            { nom: 'mntrb', largeur: 130 },
            { nom: 'mntrr', largeur: 130 },
            { nom: 'mntpe', largeur: 130 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 130 },
            { nom: 'mntgs', largeur: 130 },
            { nom: 'mntbr', largeur: 130 },
            { nom: 'mntpb', largeur: 180 }
        ]
    };

}

