import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";

interface IPageMc0013 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireUna: IFormulaire;
    formulaireStatut: IFormulaire;
    formulaireAdresse: IFormulaire;
    valeursEcran: any;
    unsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0013Controller(mc1014Route: IRoute,
                                         mc1044Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass ) {
    const vm: IPageMc0013 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        // Tolerance
        vm.tolerance = new MenuItemEcran(
            'LBL_BTN_TOLERANCE',
            mc1014Route.NAME,
            () => ({
                tolcleintref: vm.monoOccurrence.id, // unacleint
                vaeustent:    'UNA'                 // Unité adm
                // indicateur de forage à developper :  indfor:  0  // On a le droit d'éditer les données
            }),
            {
                fonction: 'BOUTOL',
                icon: 'data_usage',
                iconButton: false,
                secondaire: true
            }
        );

        // Hiérarchie
        vm.hierarchie = new MenuItemEcran(
            'LBL_BTN_HIERARCHIE',
            mc1044Route.NAME,
            (data: any) => ({
                id: data.$id,
                unacleint: data.$id
            }),
            {
                fonction: 'BOUHIE',
                icon: 'featured_play_list',
                iconButton: false,
                secondaire: true,
                hidden: (data: any) => !data.$id
            }
        );

        vm.formulaireUna = new Formulaire('LBL_BLC_UNA', [
            new FormulaireItem('unacod', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unadsc', {largeur: 50}),
            new FormulaireItem('unadscabr', {largeur: 50}),
            new FormulaireItem('unacleintsup', {largeur: 50}),
            new FormulaireItemDivider('LBL_DIV_AUT'),
            new FormulaireItem('poscleint'),
            new FormulaireItem('intcleintrsp'),
            new FormulaireItem('intcleintach'),
            new FormulaireItem('foncleint'),
            new FormulaireItem('grpcleint'),
            new FormulaireItem('gbpcleint'),
            new FormulaireItem('lipcleint'),
            new FormulaireItem('intcleintrspfac')
        ], {largeurDefaut: 33});

        vm.formulaireStatut = new Formulaire('LBL_BLC_STA', [
            new FormulaireItem('unaflgact', {default: 1}),
            new FormulaireItem('unadatfin')
        ], {largeurDefaut: 50});

        vm.formulaireAdresse = new Formulaire('LBL_BLC_ADR', [
            new FormulaireItem('unaadrl01', {largeur: 100}),
            new FormulaireItem('unaadrl02', {largeur: 100}),
            new FormulaireItem('unavil'),
            new FormulaireItem('unapro'),
            new FormulaireItem('unapay'),
            new FormulaireItem('unacodpos'),
            new FormulaireItem('unanumtel'),
            new FormulaireItem('unanumfax'),
        ], {largeurDefaut: 50});

        //section SIFU
        vm.unsMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'unsann'  , largeur: 80},
                {nom: 'sfucodse', largeur: 180},
                {nom: 'sfudscse', largeur: 250},
                {nom: 'sfucodua', largeur: 180},
                {nom: 'sfudscua', largeur: 250}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('unsann'),
                new FormulaireItem('sfucleintse'),
                new FormulaireItem('sfucleintua')
            ], {largeurDefaut: 33})
        };
    }
}
