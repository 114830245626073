import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaire,IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageMR0323 extends IComponentController {
    valeursEcran: any;
    formulairePfp: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ppbMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pfpMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0323Params {
    province: string;
    pafcleint: number;
}

/* @ngInject */
export default function Mr0323Controller($stateParams: IMr0323Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMR0323 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulairePfp = new Formulaire('LBL_BLC_PFP', [
            new FormulaireItem('vaesyscodprv', { default: $stateParams.province }),
            new FormulaireItem('pfptd1mntbas', { largeur: 10 }),
            new FormulaireItem('pfpimftaut3', { largeur: 10 }),
            new FormulaireItem('pafcleint', { default: $stateParams.pafcleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_ASSURANCE_EMP',
                new Formulaire([
                    new FormulaireItem('pfpasemga'),
                    new FormulaireItem('pfpasetau'),
                    new FormulaireItem('pfpasefctemrreg'),
                    new FormulaireItem('pfpasefctemrred')
                ], { largeurDefaut: 25 }), { largeur: 100 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_CSSIAT',
                new Formulaire([
                    new FormulaireItem('pfpcssmga')
                ]), { largeur: 50 }
            ),
            new FormulaireGroupeAccordeon('LBL_GRATIFIC',
                new Formulaire([
                    new FormulaireItem('pfpgrarevmin'),
                    new FormulaireItem('pfpgrataumin')
                ]), { largeur: 50 }
            ),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('LBL_PARAM',
                new Formulaire([
                    new FormulaireGroupe('LBL_LCP',
                        new Formulaire([
                            new FormulaireItem('pnblimlcp'),
                            new FormulaireItem('pnbprclcp', { libellesContour: { gauche: 'LBL_OU' }  })
                        ]), { largeur: 50 }
                    )
                ]), { largeur: 100 }
            )
        ], { largeurDefaut: 25 });

        // Borne
        vm.ppbMultiOccurrenceOptions = {
            bloc: 'ppb',
            colonnesVisibles: [
                {nom: 'ppbborinf', largeur: 120},
                {nom: 'ppbtau'   , largeur: 85},
                {nom: 'ppbval'   , largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ppbborinf'),
                new FormulaireItem('ppbtau'),
                new FormulaireItem('ppbval')
            ], { largeurDefaut: 33.33 })
        };

        // Taux AE réduit
        vm.pfsMultiOccurrenceOptions = {
            bloc: 'pfs',
            colonnesVisibles: [
                {nom: 'rfecod'      , largeur: 150},
                {nom: 'rfenomabr'   , largeur: 241},
                {nom: 'pfsfctemrred', largeur: 135},
                {nom: 'pfsflg512'   , largeur: 105},
                {nom: 'bqecod'      , largeur: 89},
                {nom: 'bqedscabr'   , largeur: 262}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rfecleint', { largeur: 50 }),
                new FormulaireItem('pfsfctemrred'),
                new FormulaireItem('pfsflg512'),
                new FormulaireItem('bqecleint', { largeur: 50,
                    resourceParams: (data: any) => ({ pfsflg512: data.pfsflg512 })
                })
            ], { largeurDefaut: 25 })
        };
    }
}
