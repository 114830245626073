import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";


interface IPageMR0221 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0221Controller(
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    mr0219Route: IRoute,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMR0221 = this;

    vm.multiOccurrenceOptions = {
        actions: new Menu([
            new MenuItemForage('LBL_BTN_CONSULTER', mr0219Route.NAME, () => ({}), {
                iconButton: false,
                class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised'
            })
        ]),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cat', { largeur: 30, required: true })
        ]),
        formulaire: new Formulaire([
            new FormulaireItem('pavval', { largeur: 60 })           
        ]),
        colonnesVisibles: [
            { nom: 'pasdsc', largeur: 150 },
            { nom: 'pavval', largeur: 200 },
            { nom: 'pasdsclng', largeur: 400 }
        ]
    };
}
