import {IComponentOptions, module} from 'angular';
import ButtonFabCycleController from './ex-button-fab-cycle.controller';
import Binding from '../../../../core/helpers/binding.helper';

export default module('app.components.ex-button-fab-cycle', []).component('exButtonFabCycle', ButtonFabCycleFactory());

function ButtonFabCycleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                container: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: ButtonFabCycleController,
        controllerAs: 'vm',
        template: require('./ex-button-fab-cycle.html')
    };
}
