import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPagas2005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As2005Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPagas2005 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        filtres: [
            { colonne: 'libref', valeur: ecranDetails.valeurs.libreffil }
        ],
        colonnesVisibles: [
            {nom: 'codref', largeur: 130},
            {nom: 'dscref', largeur: 280},
            {nom: 'asgcod', largeur: 130, hidden: () => ($stateParams.stamnetab !== 'AHT')},
            {nom: 'asgdsc', largeur: 280, hidden: () => ($stateParams.stamnetab !== 'AHT')}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ramcleint', { default: $stateParams.ramcleint, hidden: true }),
            new FormulaireItem('stamnetab', { default: $stateParams.stamnetab, hidden: true }),
            new FormulaireItem('vamcleintref', { required: true }),
            new FormulaireItem('asgcleint', { readonly: true, hidden: () => ($stateParams.stamnetab !== 'AHT') })
        ])
    };
}

