import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0245 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0245Controller(FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMr0245 = this;

    const colonnesFixesGauche = [];

    vm.valeursEcran = ecranDetails.valeurs;

    if (ecranDetails.params.p_stanom == 'RREMPLOYE') {
        colonnesFixesGauche.push(
            { nom: 'eplnummat', largeur: 105 },
            { nom: 'eplnomprn', largeur: 262 }
        );
    } else if (ecranDetails.params.p_stanom == 'SOPOSTE') {
        colonnesFixesGauche.push(
            { nom: 'posnumdoc', largeur: 105 },
            { nom: 'postitabr', largeur: 262 }
        );
    } else if (ecranDetails.params.p_stanom == 'ALDOSSIER_ALD') {
        colonnesFixesGauche.push(
            { nom: 'dalnumdoc', largeur: 105 },
            { nom: 'tdldsc', largeur: 262 }
        );
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche,
        colonnesVisibles: [
            { nom: 'gnocod', largeur: 105 },
            { nom: 'gnodsc', largeur: 260 },
            { nom: 'tnocod', largeur: 105 },
            { nom: 'tnodsc', largeur: 260 },
            { nom: 'notdat', largeur: 100 },
            { nom: 'notseq', largeur: 65 },
            { nom: 'usrcod', largeur: 100 },
            { nom: 'usrnom', largeur: 260 }
        ],
        colonnesFixesDroite: [
            { nom: 'notdsc', largeur: 328 },
            { nom: 'nbrpj', largeur: 76 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('notcleref', { modifAllow: false, required: true, hidden: (data: any) => data.notcleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('gtacleint', { largeur: 50, modifAllow: false, required: true }),
            new FormulaireItem('tnocleint', {
                resourceParams: (data: any) => {
                    return {
                        gtacleint: data.gtacleint
                    };
                },
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('notdat', { required: true }),
            new FormulaireItem('notdsc', { required: true, largeur: 50 })
        ], { largeurDefaut: 25, piecesJointes: true }
        )
    };
}
