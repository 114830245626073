import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';

interface IPageMc2009 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireIne: IFormulaire;
    inhMultiOccurrence: IMultiOccurrence;
    inhMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc2009Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc2009 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('intcleint'),
            new FormulaireItem('mirflgrej', { default: 0 }),
            new FormulaireItem('mirflgtou', { default: 0 }),
            new FormulaireItem('mirflgdem', { default: 0 })
        ], { largeurDefaut: 25 }),
        colonnesVisibles: [
            { nom: 'intcod', largeur: 100 },
            { nom: 'intnom', largeur: 250 },
            { nom: 'mirflgrej', largeur: 100 },
            { nom: 'mirflgtou', largeur: 100 },
            { nom: 'mirflgdem', largeur: 100 }
        ]
    };


}
