import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from "angular";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageCo0006 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    apaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0006Controller(mr0008Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageCo0006 = this;

    vm.$onInit = function $onInit() {
        vm.apaMultiOccurrenceOptions = {
            bloc: 'apa',
            navigatePage: mr0008Route.NAME,
            navigateParams: (rowData: any) => ({
                id: rowData.apacleint,
                cleintref: vm.monoOccurrence.data.comcleint,
                stamnetab: 'COM',
                vaesystypavi: 'COM',
                supprime: 1,
                vaebmtent: 'COM',
                typsai: 'JRS'
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage(mr0008Route.NAME, () => ({
                    cleintref: vm.monoOccurrence.data.comcleint,
                    stamnetab: 'COM',
                    vaesystypavi: 'COM',
                    supprime: 1,
                    vaebmtent: 'COM',
                    typsai: 'JRS'
                }))
            ]),
            colonnesCachees: [
                'apacleint',
                'stamnetab',
                'apacleintref'
            ],
            colonnesVisibles: [
                { nom: 'apasujavi', largeur: 262 },
                { nom: 'apadelavi', largeur: 80 }
            ]
        };

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire('LBL_TYP_COM', [
                new FormulaireItem('comcod', { largeur: 20, required: true }),
                new FormulaireItem('comdsc', { largeur: 40, required: true }),
                new FormulaireItem('tyccleint', { largeur: 40 }),
                new FormulaireItem('aut__chkniv', { readonly: true }),
                new FormulaireGroupeAccordeon('LBL_GRP_AUTRES_INFOS', new Formulaire([
                    new FormulaireItem('dmncleint', { largeur: 100 / 3, required: true }),
                    new FormulaireItem('comcleintref', { largeur: 200 / 3 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('comver', { largeur: 25 }),
                    new FormulaireItem('comvalmin', { largeur: 25, disabled: data => data.aut__chkniv }),
                    new FormulaireItem('comvalmax', { largeur: 25, disabled: data => data.aut__chkniv }),
                    new FormulaireItem('comflgaffweb', { largeur: 25 }),
                    new FormulaireItem('comdscweb', { enableCount: true, largeur: 100 }),
                    new FormulaireItem('comexp', { enableCount: true, largeur: 100 })
                ]))
            ])
        };
    }
}

