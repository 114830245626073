import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageAS0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0028Controller(
    as0029Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAS0028 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: as0029Route.NAME,
        actionsNouveaux: new Menu([new MenuItemForage(as0029Route.NAME)]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'occcod', largeur: 120 },
            { nom: 'occdsc', largeur: 262 }
        ],

        colonnesVisibles: [
            { nom: 'vaeocctem_dsc', largeur: 130 },
            { nom: 'vaeocclem_dsc', largeur: 130 },
            { nom: 'vaeoccaft_dsc', largeur: 130 },
            { nom: 'vaeoccpos_dsc', largeur: 130 },
            { nom: 'vaeoccder_dsc', largeur: 130 },
            { nom: 'vaeoccuno_dsc', largeur: 130 },
            { nom: 'occflgrestaf', largeur: 140 },
            { nom: 'occflgact', largeur: 76 },
            { nom: 'occflgdef', largeur: 76 },
            { nom: 'occflgabsact', largeur: 91 },
            { nom: 'occflgabsdef', largeur: 91 }
        ],
    };
}