import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageGS0057 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0057Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGS0057 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'gsdcod', largeur: 120},
            {nom: 'gsddsc', largeur: 300},
            {nom: 'gseflgact', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gsdcleint'),
            new FormulaireItem('gseflgact')
        ],{largeurDefaut: 33.33})
    };
}
