import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0303 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0303Controller(mr0102Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0303 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0102Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0102Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'cvtcod'      , largeur: 100},
            {nom: 'cvtdsc'      , largeur: 250},
            {nom: 'vaedsc'      , largeur: 125},
            {nom: 'cvtdscdet'   , largeur: 350},
            {nom: 'cvtflgact'   , largeur: 80}
        ]
    };
}
