import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageCP0034 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0034Controller(cp0069Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass) {
    const vm: IPageCP0034 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cp0069Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cp0069Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'peccod', largeur: 120 },
            { nom: 'cptcod', largeur: 120 },
            { nom: 'cptdscabr', largeur: 170 },
            { nom: 'lcocod', largeur: 100 },
            { nom: 'lcodat', largeur: 150 },
            { nom: 'lconbrchq', largeur: 120 },
            { nom: 'lcoflgacc', largeur: 100 }
        ]
    };
}
