import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';


interface IPageMR0213 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mr0213Controller(mr0212Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageMR0213 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
         actionsRangeeDroite: new Menu([
            new MenuItemEcran('BTN_MODIFIER', mr0212Route.NAME, (rowData: any) => {
                return {
                    id: rowData.doccleint
                };
            }, {
                icon: 'launch',
                iconButton: true,
                onClose: () => vm.multiOccurrence.fetchDataList()
            })
        ]),
        colonnesVisibles: [
            {nom: 'mobnumdoc', largeur: 120},
            {nom: 'tydcod', largeur: 110},
            {nom: 'tyddsc', largeur: 220},
            {nom: 'etddsc', largeur: 140},
            {nom: 'mobdat', largeur: 110},
            {nom: 'mobdsc', largeur: 250}
        ]
    };
}
