import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pr0006Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0006 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'pfocod', largeur: 65 },
            { nom: 'pfodsc', largeur: 230 },
            { nom: 'pfoflgstu', largeur: 74 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pfocod', { required: true, largeur: 25 }),
            new FormulaireItem('pfodsc', { largeur: 50 }),
            new FormulaireItem('pfoflgstu', { largeur: 25, default: 1 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
    };
}
