import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRr0103 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function rr0103Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0103 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datsel', {
                required: true
            }),
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'urgcod', largeur: 120}
        ],
        colonnesVisibles: [
            {nom: 'grecod', largeur: 90},
            {nom: 'strcod', largeur: 90},
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprn', largeur: 210},
            {nom: 'salbas', largeur: 126},
            {nom: 'salpri', largeur: 126},
            {nom: 'saltot', largeur: 126},
            {nom: 'flgald', largeur: 100},
            {nom: 'flglemplu', largeur: 100},
            {nom: 'urgdscabr', largeur: 210},
            {nom: 'gredsc', largeur: 210},
            {nom: 'strdsc', largeur: 210},
            {nom: 'temcod', largeur: 89},
            {nom: 'temdsc', largeur: 300},
            {nom: 'unocodrh', largeur: 160},
            {nom: 'unodscrh', largeur: 300}
        ]
    };
}
