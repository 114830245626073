import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0121 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0121Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         mr0238Route: IRoute,
                                         $stateParams: any,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0121 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'cotcleint', valeur: $stateParams.reference, visible: false}
        ],
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_MR0238',
                mr0238Route.NAME,
                (data:any) => ({vaetypfnd: 'COT',
                    cleintdet: data.cotcleint}),
                {
                    icon: 'assessment',
                    iconButton: false
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('cotcod',            {largeur: 25}),
            new FormulaireItem('cotdsc',            {largeur: 75}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaecotcodemp',      {largeur: 33.33}),
            new FormulaireItem('vaecotcodemr',      {largeur: 33.33}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaecotappsur',      {largeur: 33.33, default: 'MNT'}),
            new FormulaireItem('colcleintexcadm',   {largeur: 33.33}),
            new FormulaireItem('colcleintexcsalbas',{largeur: 33.33}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('cotflginvqteadm',{
                largeur: 25,
                default:0}),
            new FormulaireItem('cotflginvadm',{
                largeur: 25,
                default:0}),

        ]),

        colonnesFixesGauche: [
            {nom: 'cotcod',           largeur: 80},
            {nom: 'cotdsc',           largeur: 275}
        ],
        colonnesVisibles: [
            {nom: 'vaecotcodemp_dsc', largeur: 180},
            {nom: 'vaecotcodemr_dsc', largeur: 180},
            {nom: 'colcod_excadm',    largeur: 110},
            {nom: 'coldsc_excadm',    largeur: 275},
            {nom: 'colcod_salbas',    largeur: 110},
            {nom: 'coldsc_salbas',    largeur: 275},
            {nom: 'cotflginvqteadm',  largeur: 100},
            {nom: 'cotflginvadm',     largeur: 100},
            {nom: 'vaecotappsur_dsc', largeur: 180}
        ]
    };
}
