import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0002Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0002 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'ptycod', largeur: 100},
            {nom: 'ptydsc', largeur: 236},
            {nom: 'tydcleintvirbdg', largeur: 270},
            {nom: 'ptyprcretapp', largeur: 160},
            {nom: 'ptyflgstrlfe', largeur: 138},
            {nom: 'ptyflgact', largeur: 62},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ptycod', {largeur: 50}),
            new FormulaireItem('ptydsc', {largeur: 50, required: true}),
            new FormulaireItem('tydcleintvirbdg'),
            new FormulaireItem('ptyprcretapp'),
            new FormulaireItem('ptyflgstrlfe'),
            new FormulaireItem('ptyflgact', {default: 1}),
        ], {largeurDefaut: 25}),
        fonctions: Object.assign({
            edition: true,
            nouveau: true

        })
    };
}
