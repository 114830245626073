import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IStateParamsService} from 'angular-ui-router';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";

interface IPageTs0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran:any;
}

/* @ngInject */
export default function Ts0008Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         $stateParams: IStateParamsService,
                                         FormulaireItem: IFormulaireItemClass,
                                         ts0017Route:IRoute,
                                         Menu: IMenuClass,
                                         MenuItemEcran: IMenuItemEcranClass
) {
    const vm: IPageTs0008 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemEcran(
                'LBL_CHANGEMENT',
                ts0017Route.NAME,
                (data:any) => ({
                    gidcleint:data.gidcleint
                }),
                {
                    icon: 'launch',
                    fonction:'BOUOUV',
                    hidden: ecranDetails.params.p_vaegidtyp === 'DES'
                }
            )
        ]),
        filtresParametres: [
            {colonne: 'grrcod', valeur:$stateParams.grrcod,readOnly:true}
        ],
        fonctions: {
            importation: true
        },
        colonnesFixesGauche:[
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 200, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'grrcod', largeur: 100},
            {nom: 'grrdscabr', largeur: 200},
            {nom: 'lemcod', largeur: 100},
            {nom: 'lemdsc', largeur: 200},
            {nom: 'giddatdeb', largeur: 100},
            {nom: 'giddatfin', largeur: 100},
            {nom: 'gidcom', largeur: 200},
            {nom: 'gidflgact',largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('grrcleint'),
            new FormulaireItem('lemcleint'),
            new FormulaireItem('giddatdeb',{largeur: 25}),
            new FormulaireItem('giddatfin',{largeur: 25}),
            new FormulaireItem('gidcom',{largeur: 50}),
            new FormulaireItem('gidflgact',{largeur: 25, default: 1})
        ])
    };

}
