import { IComponentController, IScope } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageTF0009 extends IComponentController {
    multiOccurrence: IMultiOccurrence; 
   multiOccurrenceOptions: IMultiOccurrenceOptions;
   valeursEcran: any;
}

/* @ngInject */
export default function TF0009Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    $scope: IScope
) {
    const vm: IPageTF0009 = this;
    vm.$onInit = $oninit;

    vm.multiOccurrenceOptions = {
        resourceParamsDynamique: () => ({
            crifolcleint: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.crifolcleint,
            critypaff: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.critypaff            
        }),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true,
            libellesDynamiques: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('crifolcleint', {
                required: true,
                largeur: 33
            }),
            new FormulaireItem('critypaff', {
                largeur: 33,
                default: 'S'
            })
        ]),
        pagination: {
            nombreElementPage: 25
        },
        colonnesFixesGauche: [{ nom: 'typ', largeur: 103 }],
        colonnesVisibles: [
            { nom: 'refcod', largeur: 262 },
            { nom: 'refdscabr', largeur: 262 },
            { nom: 'var01', largeur: 140 },
            { nom: 'var02', largeur: 140 },
            { nom: 'var03', largeur: 140 },
            { nom: 'var04', largeur: 140 },
            { nom: 'var05', largeur: 140 },
            { nom: 'var06', largeur: 140 },
            { nom: 'var07', largeur: 140 },
            { nom: 'var08', largeur: 140 },
            { nom: 'var09', largeur: 140 },
            { nom: 'var010', largeur: 140 },
            { nom: 'var011', largeur: 140 },
            { nom: 'var012', largeur: 140 },
            { nom: 'var013', largeur: 140 },
            { nom: 'var014', largeur: 140 },
            { nom: 'var015', largeur: 140 },
            { nom: 'var016', largeur: 140 },
            { nom: 'var017', largeur: 140 },
            { nom: 'var018', largeur: 140 },
            { nom: 'var019', largeur: 140 },
            { nom: 'var020', largeur: 140 },
            { nom: 'var021', largeur: 140 },
            { nom: 'var022', largeur: 140 },
            { nom: 'var023', largeur: 140 },
            { nom: 'var024', largeur: 140 },
            { nom: 'var025', largeur: 140 },
            { nom: 'var026', largeur: 140 },
            { nom: 'var027', largeur: 140 },
            { nom: 'var028', largeur: 140 },
            { nom: 'var029', largeur: 140 },
            { nom: 'var030', largeur: 140 },
            { nom: 'var031', largeur: 140 },
            { nom: 'var032', largeur: 140 },
            { nom: 'var033', largeur: 140 },
            { nom: 'var034', largeur: 140 },
            { nom: 'var035', largeur: 140 },
            { nom: 'var036', largeur: 140 },
            { nom: 'var037', largeur: 140 },
            { nom: 'var038', largeur: 140 },
            { nom: 'var039', largeur: 140 },
            { nom: 'var040', largeur: 140 },
            { nom: 'var041', largeur: 140 },
            { nom: 'var042', largeur: 140 },
            { nom: 'var043', largeur: 140 },
            { nom: 'var044', largeur: 140 },
            { nom: 'var045', largeur: 140 },
            { nom: 'var046', largeur: 140 },
            { nom: 'var047', largeur: 140 },
            { nom: 'var048', largeur: 140 },
            { nom: 'var049', largeur: 140 }           
        ],        
        colonnesFixesDroite: [
            { nom: 'var050', largeur: 140 }
        ]
        
    };

    function $oninit() {
       
        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.crifolcleint', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });

        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.critypaff', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });
    }

}
