import { IComponentController } from 'angular';
import { IDefaultsService } from '../../../../core/services/utils/defaults.service';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IMultiOccurrenceOptions } from '../../../../core/services/multi-occurrence.service';
import { ITranscludeSlotFunction } from "../../../../core/interfaces/transclude-function.interface";

export interface IComposantMultiOccurrenceMaitreDetailsEcran extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    codeEcran: string;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    getTitreEcran(): string;
    hasTranscludeHaut(): boolean;
    hasTranscludeSlot(slot: string): boolean;
}

/* @ngInject */
export default function MultiOccurrenceMaitreDetailsEcranController(defaults: IDefaultsService,
    $transclude: ITranscludeSlotFunction) {
    const vm: IComposantMultiOccurrenceMaitreDetailsEcran = this;

    vm.$onInit = $onInit;
    vm.getTitreEcran = getTitreEcran;
    vm.hasTranscludeHaut = hasTranscludeHaut;
    vm.hasTranscludeSlot = hasTranscludeSlot;

    function $onInit() {
        defaults(vm, {
            codeEcran: `${vm.ecranContextCtrl.stateParams.srccod}-${vm.ecranContextCtrl.stateParams.ecran}`,
        });

        ajouterFiltreEmploye();
    }

    function getTitreEcran() {
        return vm.ecranContextCtrl.ecranDetails.titre;
    }

    function hasTranscludeHaut(): boolean {
        return $transclude.isSlotFilled('haut');
    }

    function hasTranscludeSlot(slot: string): boolean {
        return $transclude.isSlotFilled(slot);
    }

    function ajouterFiltreEmploye() {
        if (vm.ecranContextCtrl.stateParams.isProfilEmploye) {
            vm.multiOccurrenceOptions.filtresParametres = vm.multiOccurrenceOptions.filtresParametres || [];
            // Un filtre pour filtrer, l'autre pour l'affichage
            vm.multiOccurrenceOptions.filtresParametres.unshift(
                {
                    colonne: 'G_LBL_EMPLOYE',
                    valeur: `${vm.ecranContextCtrl.ecranDetails.employe.eplnummat} - ${vm.ecranContextCtrl.ecranDetails.employe.eplnomprnusu}`,
                    affichage: true
                },
                {
                    colonne: 'demcleint',
                    valeur: vm.ecranContextCtrl.stateParams.employe,
                    visible: false
                }
            );
        }
    }
}
