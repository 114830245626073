import {module} from 'angular';
import AideTechniqueController from './dialog-aide-technique.controller';
import {IDialogClass} from '../../../../core/services/dialog.service';

export default module('app.dialogs.dialog-aide-technique', [
    'core.services.dialog'
]).factory('DialogAideTechnique', DialogAideTechniqueFactory);

/* @ngInject */
function DialogAideTechniqueFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: AideTechniqueController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-aide-technique.html'),
        locals: {
            icon: 'help_outline',
            lblTitre: 'G_LBL_OUTILS_AIDE_TECH',
            lblCancel: 'G_LBL_BTN_OK'
        }
    });
}
