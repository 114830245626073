import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IPaginationClass} from "../../../../../core/services/pagination.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageMc0153 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceIdlOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mc0153Controller(//mr0038Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Pagination: IPaginationClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMc0153 = this;

    // TODO à corriger quand l'écran sera fait
    const mr0038Route = {NAME: 'secure.accueil'};

    // Forage vers intervenant
    const forageInt = new MenuItemForage(
        'BTN_FORAGE_INT',
        mr0038Route.NAME,
        (rowData: any) => ({
            id: rowData.intcleint
        }),
        {
            icon: 'launch',
            iconButton: true,
            fonction: 'FORINT',
            hidden: ( rowData: any ) => !rowData.flgrh,
            validation(rowData: any) {
                if (!rowData.flgrh) {
                    return 'MSG_PAS_DE_ROLE_DEL';
                }
            }
        }
    );

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceIdlOptions = {
        bloc: 'idl',
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 20]
        }),
        colonnesVisibles: [
            {nom: 'intcod', largeur: 150},
            {nom: 'intnom', largeur: 262},
            {nom: 'idldatdeb', largeur: 100},
            {nom: 'idldatfin', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcleintdel', {
                resourceParams: (data: any) => {
                    return {
                        intflgapr: data.$ancetre.intflgapr
                    };
                }
            }),
            new FormulaireItem('idldatdeb'),
            new FormulaireItem('idldatfin')
        ], {largeurDefaut: 33})
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            pagination: true
        },
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        colonnesVisibles: [
            {nom: 'intcod'   , largeur: 140},
            {nom: 'intnom'   , largeur: 262},
            {
                nom: 'flgrh',
                largeur: 100,
                menu: [forageInt]
            },
            {nom: 'flgreq'   , largeur: 100},
            {nom: 'flghie'   , largeur: 100}
        ],
        colonnesCachees: [
            'intflgapr'
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceIdlOptions
    };
}
