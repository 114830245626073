import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPagePb0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Pb0017Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite
                                         ) {
    const vm: IPagePb0017 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'rcbcod',             largeur: 60},
            {nom: 'rcbdsc',             largeur: 100},
            {nom: 'pbscod',             largeur: 60},
            {nom: 'pbsdsc',             largeur: 100},
            {nom: 'tymdsc',             largeur: 100},
            {nom: 'vaesysnatctb_dsc',   largeur: 100},
            {nom: 'rcbannref',          largeur: 60},
            {nom: 'rcbfctapp',          largeur: 60},
            {nom: 'rcbcom',             largeur: 120}
        ],
       formulaire: new Formulaire([
            new FormulaireItem('rcbcod'),
            new FormulaireItem('rcbdsc'),
            new FormulaireItem('pbscleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tymcleint'),
            new FormulaireItem('vaesysnatctb'),
            new FormulaireItem('rcbannref'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rcbfctapp'),
            new FormulaireItem('rcbcom')
        ], {largeurDefaut: 33.33})
    };
}
