import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCr2001 extends IComponentController {
    formulaireCac: IFormulaire;
    formulaireCcl: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
    cavMultiOccurrenceOptions: IMultiOccurrenceOptions;
    chfMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr2001Controller($stateParams: any,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    mc1014Route: IRoute,
    cr0016Route: IRoute,
    cr0007Route: IRoute,
    cr0013Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCr2001 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            cycleErrorReloadBlocs: ['cav'],
            infosFinancieres: {
                disponibilite: true,
                impactFinancier: true,
                impactBudgetaire: true
            },
            fonctions: {
                supprime: (data: any) => (data.cacindsup)
            },
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_BTN_TOLERANCE',
                    mc1014Route.NAME,
                    () => ({
                        tolcleintref: vm.monoOccurrence.data.caccleint, // unacleint
                        vaeustent: 'CAC'                 // Unité adm
                        // indicateur de forage à developper :  indfor:  0  // On a le droit d'éditer les données
                    }),
                    {
                        fonction: 'BOUTOL',
                        icon: 'data_usage',
                        iconButton: false,
                        secondaire: true
                    }
                )
            ])
        };

        // Entête Contrat client
        vm.formulaireCcl = new Formulaire('LBL_BLC_CCL', [
            new FormulaireItem('refcleint', { readonly: true, default: vm.valeursEcran.refcleint }),
            new FormulaireItem('tydcleint', { readonly: true, default: vm.valeursEcran.tydcleint }),
            new FormulaireItem('cifcleint', { readonly: true, default: vm.valeursEcran.cifcleint }),
            new FormulaireItem('ccldatdeb', { readonly: true, default: vm.valeursEcran.ccldatdeb }),
            new FormulaireItem('ccldatfin', { readonly: true, default: vm.valeursEcran.ccldatfin })
        ], { largeurDefaut: 33.33 }
        );

        // Contrat annuel
        vm.formulaireCac = new Formulaire('LBL_BLC_CAC', [
            new FormulaireItem('cclcleint', { hidden: true, default: $stateParams.cclcleint }),
            new FormulaireItem('cclnumdoc', {
                readonly: true,
                default: vm.valeursEcran.cclnumdoc,
                menu: [new MenuItemForage(
                    cr0016Route.NAME,
                    (data: any) => ({
                        id: data.cclcleint
                    }),
                    {
                        icon: 'exit_to_app',
                        fonction: 'BTN_RET'
                    }
                )]
            }),
            new FormulaireItem('cacnumdocseq', { readonly: true }),
            new FormulaireItem('cacseqave', { readonly: true }),
            new FormulaireItem('cacann', { readonly: (data: any) => (data.cacindave) }),
            new FormulaireItem('intcleintreq', { required: true }),
            new FormulaireItem('cacmnt'),
            new FormulaireItem('cacdateff'),
            new FormulaireItem('peccleint', { readonly: (data: any) => (!(vm.valeursEcran.cclflgsaiperdec) || data.cacindave) }),
        ], { largeurDefaut: 33.33 }
        );

        // Ventilation
        vm.cavMultiOccurrenceOptions = {
            bloc: 'cav',
            reloadWithBlocs: ['CAC'],
            //editionRapideActive: true,
            fonctions: {
                //editionRapide: true,
                //editionStandard: true,
                //agrandirMultiOccurrence: true,
                supprime: (data: any) => (data.cavindave),
                edition: (data: any) => (data.cavindave)
            },
            colonnesFixesGauche: [
                { nom: 'cavimp', largeur: 300 },
                { nom: 'cavmnt', largeur: 140 }
            ],
            colonnesVisibles: [
                { nom: 'cavqte', largeur: 120 },
                { nom: 'taxcod', largeur: 80 },
                { nom: 'cavmnttaxfed', largeur: 140 },
                { nom: 'cavmnttaxpro', largeur: 140 },
                { nom: 'cavseqave', largeur: 100 },
                { nom: 'cavdsc', largeur: 250 },
                { nom: 'cavmntrevnet', largeur: 140 }
            ],
            colonnesFixesDroite: [
                { nom: 'cavflgmsg', largeur: 100 }
            ],
            colonnesEditionRapide: [
                { nom: 'cavimp', largeur: 400 },
                { nom: 'cavmnt', largeur: 150 },
                { nom: 'taxcleint', largeur: 300 },
                { nom: 'cavmnttaxfed', largeur: 140 },
                { nom: 'cavmnttaxpro', largeur: 140 },
                { nom: 'cavmntrevnet', largeur: 126 },
                { nom: 'cavseqave', largeur: 100 },
                { nom: 'cavdsc', largeur: 300 },
                { nom: 'cavqte', largeur: 126 },
                { nom: 'cavflgmsg', largeur: 100 },
                { nom: 'cavmsgrap', largeur: 300 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cavimp', { largeur: 50, isValidatingAtLoad: (data) => data.cavflgmsg, required: true }),
                new FormulaireItem('cavmnt', { required: true }),
                new FormulaireItem('taxcleint', { required: true }),
                new FormulaireItem('cavmnttaxfed'),
                new FormulaireItem('cavmnttaxpro'),
                new FormulaireItem('cavmntrevnet', { readonly: true }),
                new FormulaireItem('cavseqave', { readonly: true }),
                new FormulaireItem('cavdsc', { largeur: 50 }),
                new FormulaireItem('cavqte'),
                new FormulaireItem('cavflgmsg', { readonly: true, required: false, titre: " " })
            ], { largeurDefaut: 25 }),
            colonnesCachees: [
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint',
                'cavmsg',
                'cavindave'
            ]
        };

        vm.chfMultiOccurrenceOptions = {
            bloc: 'chf',
            fonctions: {
                suiviModification: false
            },
            colonnesVisibles: [
                { nom: 'cacnumdocseq', largeur: 60 },
                { nom: 'cacann', largeur: 80 },
                {
                    nom: 'docaffnumdoc01',
                    largeur: 120,
                    menu: [new MenuItemForage(
                        'LBL_FOR_REF',
                        (rowData: any) => getForage(rowData),
                        (rowData: any) => {
                            return {
                                id: rowData.doccleint
                            };
                        }
                    )]
                },
                { nom: 'tydcod', largeur: 100 },
                { nom: 'datcre', largeur: 126 },
                { nom: 'peccod', largeur: 100 },
                { nom: 'etddsc', largeur: 120 },
                { nom: 'chfmnt', largeur: 120 },
                { nom: 'docaffdsc01', largeur: 250 }
            ],
            colonnesCachees: [
                'doccleint',
                'ecrcod'
            ]
        };    
    };

    function getForage(rowData: any): string {
        switch (rowData.ecrcod) {
            case 'cr0013': return cr0013Route.NAME;
            case 'cr0007': return cr0007Route.NAME;
            default: return null;
        }
    };
}
