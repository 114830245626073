import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPagePB0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0018Controller() {
    const vm: IPagePB0018 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'posnumdoc', largeur: 200},
            {nom: 'postitabr', largeur: 200},
            {nom: 'urgcod', largeur: 200},
            {nom: 'urgdscabr', largeur: 200},
            {nom: 'temcod', largeur: 200},
            {nom: 'temdscabr', largeur: 200},
            {nom: 'tpocod', largeur: 200},
            {nom: 'tpodsc', largeur: 200},
            {nom: 'posdatdeb', largeur: 200},
            {nom: 'posdatfin', largeur: 200},
            {nom: 'flgtep', largeur: 200}
        ]
    };
}
