import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import IStateService = angular.ui.IStateService;
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGS0064 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IGs0064Params {
    menuId: string;
}

/* @ngInject */
export default function Gs0064Controller(gs0016Route: IRoute,
                                         MenuItemAction: IMenuItemActionClass,
                                         $stateParams: IGs0064Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $state: IStateService,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass) {
    const vm: IPageGS0064 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0016Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemAction(
                'G_LBL_BTN_NOUVEAU',
                'SIMULER_PROCEDURE',
                () => ({}),
                {
                    saveFirst: true,
                    fonction: 'BOUNOU',
                    successAction: (data: any) => {
                        $state.go(gs0016Route.NAME, {
                            menuId: $stateParams.menuId,
                            id: data.t_trlcleint
                        });
                    },                    
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_TRAIT_LOT',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 50,
                        formulaire: new Formulaire([
                            new FormulaireItem('trlcleint', {
                                required: true, largeur: 100
                            })
                        ])
                    }
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'trccod', largeur: 120},
            {nom: 'trcdsc', largeur: 272},
            {nom: 'frmdsc', largeur: 210},
            {nom: 'trcflgpub', largeur: 140},
            {nom: 'trcflgint', largeur: 130},
            {nom: 'fiadsc', largeur: 207},
            {nom: 'trcfintrtdsc', largeur: 246},
            {nom: 'vaeresreqdsc', largeur: 246},
            {nom: 'impdsc', largeur: 246},
            {nom: 'trcnbrcop', largeur: 130}            
          ]
    };
}
