import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPageGS0100 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IGs0100Params {
    vaeelecle: string;
}

/* @ngInject */
export default function Gs0100Controller($stateParams: IGs0100Params,
                                         Operateur: IOperateurService) {
    const vm: IPageGS0100 = this;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'vaeelecle',
                operateur: Operateur.EGALE,
                valeur: $stateParams.vaeelecle,
                visible: false
            }
        ],
        colonnesVisibles: [
            {nom: 'vaedsc', largeur: 210},
            {nom: 'vaedsclng', largeur: 800}
        ]
    };
}
