import {IComponentController} from 'angular';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IFormulaireTemplateSlotClass} from "../../../../../core/services/formulaire/formulaire-template-slot.service";

interface IPagePf0030 extends IComponentController {
    formulaireDem: IFormulaire;
    formulaire: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    lemaftMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0030Controller(rr0004Route: IRoute,
                                         rr0006Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass) {
    const vm: IPagePf0030 = this;

    // Forage Lien d'emploi
    const forageLienEmploi = new MenuItemForage(
        'LBL_FOR_POSDAT',
        rr0004Route.NAME,
        (data:any) => ({
            id: data.trncleint_lem
        }),
        { hidden: (data: any) => !data.trncleint_lem,fonction:'LIEN' }
    );

    // Forage vers l'affectation
    const forageAft = new MenuItemForage(
        'LBL_FOR_AFT',
        rr0006Route.NAME,
        (data:any) => ({
            id: data.trncleint_aft
        }),
        { hidden: (data: any) => !data.trncleint_aft,
            fonction:'AFFECT' }
    );

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaire = new Formulaire('LBL_BLC_DEM', [
            new FormulaireTemplateSlot('cartePrincipale')
        ]);

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false,
                suiviModification: false
            }
        };

        vm.lemaftMultiOccurrenceOptions = {
            bloc: 'lemaft',
            colonnesVisibles: [
                {nom: 'urgcod'       , largeur: 130},
                {nom: 'strcod'       , largeur: 100},
                {nom: 'lemdatdeb'    , largeur: 100},
                {nom: 'lemdatfin'    , largeur: 100},
                {nom: 'lemcod'       , largeur: 130, menu:[forageLienEmploi]},
                {nom: 'temcod'       , largeur: 130},
                {nom: 'posnumdoc'    , largeur: 130},
                {nom: 'unocod_rh'    , largeur: 140},
                {nom: 'unocod_vac'   , largeur: 140},
                {nom: 'aftdatdeb'    , largeur: 100},
                {nom: 'aftdatfin'    , largeur: 100},
                {nom: 'aftcod'       , largeur: 130, menu:[forageAft]},
                {nom: 'lemdsc'       , largeur: 250},
                {nom: 'urgdscabr'    , largeur: 250},
                {nom: 'strdsc'       , largeur: 250},
                {nom: 'aftdsc'       , largeur: 250},
                {nom: 'temdscabr'    , largeur: 250},
                {nom: 'postitabr'    , largeur: 250},
                {nom: 'unodscabr_rh' , largeur: 250},
                {nom: 'unodscabr_vac', largeur: 250}
            ],
            colonnesCachees: ['trncleint_lem', 'trncleint_aft']
        }
    }
}
