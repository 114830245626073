import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IComponentController} from 'angular';
import IResourceClass = angular.resource.IResourceClass;
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireTemplateSlotClass} from '../../../../../core/services/formulaire/formulaire-template-slot.service';

interface IPageCS0007 extends IComponentController {
    menus: {[key: string]: Array<IMenuItem>},
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    formulaireDcs: IFormulaire;
    formulaireDatcon: IFormulaire;
    formulaireDoscss: IFormulaire;
    formulaireAut: IFormulaire;
    formulaireDpc: IFormulaire;
    formulaireRpcrad: IFormulaire;
    formulaireRdcrad: IFormulaire;
    cs0007InformationComplementaire: IResourceClass<any>;
}

/* @ngInject */
export default function Cs0007Controller(cs0001Route: IRoute,
                                         cs0003Route: IRoute,
                                         rr0003Route: IRoute,
                                         rr0004Route: IRoute,
                                         rr0006Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageCS0007 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.menus = {
            accnumdoc: [
                new MenuItemForage(
                    'BTN_FORAGE_ACCIDENT',
                    cs0003Route.NAME,
                    (data: any) => ({
                        id: vm.valeursEcran.trncleint_acc
                    }),
                    {hidden: () => !vm.valeursEcran.trncleint_acc,
                        fonction: 'BTN_ACCIDENT'}
                )
            ],
            eplnummat_nomprn: [
                new MenuItemForage(
                    'BTN_FORAGE_EMPLOYE',
                    rr0003Route.NAME,
                    (data: any) => ({
                        id: vm.valeursEcran.trncleint_dem
                    }),
                    {hidden: () => !vm.valeursEcran.trncleint_dem,
                        fonction: 'BTN_EMPLOYE'}
                )
            ],
            lemcod_dsc: [
                new MenuItemForage(
                    'BTN_FORAGE_LIEN_EMPLOI',
                    rr0004Route.NAME,
                    (data: any) => ({
                        id: vm.valeursEcran.trncleint_lem
                    }),
                    {hidden: () => !vm.valeursEcran.trncleint_lem,
                        fonction: 'BTN_LIEN_EMPLOI'}
                )
            ],
            aftcod_dsc: [
                new MenuItemForage(
                    'BTN_FORAGE_LIEN_EMPLOI',
                    (vm.valeursEcran.trncleint_lem === vm.valeursEcran.trncleint_aft) ?
                        rr0004Route.NAME :
                        rr0006Route.NAME,
                    (data: any) => ({
                        id: vm.valeursEcran.trncleint_aft
                    }),
                    {hidden: () => !vm.valeursEcran.trncleint_aft,
                        fonction: 'BTN_LIEN_EMPLOI_AFT'}
                )
            ],
            aut__nbrdia: [
                new MenuItemForage(
                    'BTN_FORAGE_DIAGNOSTICS',
                    cs0001Route.NAME,
                    (data: any) => ({
                        demcleint: vm.valeursEcran.demcleint,
                        dcscleint: vm.monoOccurrence.data.dcscleint
                    }),
                    {hidden: () => !vm.monoOccurrence.id,
                        fonction: 'BTN_DIAGNOSTICS'}
                )
            ]
        };

        vm.recapitulatif = new MenuItemAction(
            'BTN_RECAPITULATIF',
            'BOURECAPITULATIF',
            () => ({}),
            {
                icon: 'insert_drive_file',
                // icon: 'print',
                iconButton: false
            }
        );

        vm.formulaireDcs = new Formulaire('LBL_BLC_DCS', [
            new FormulaireItem('dcsnumdoc'),
            new FormulaireItem('ttccleint', {modifAllow: false})
        ]);

        vm.formulaireDatcon = new Formulaire([
            new FormulaireItem('dcsdatcon'),
            new FormulaireTemplateSlot('boutonClassification', {largeur: 50})
        ]);

        vm.formulaireDoscss = new Formulaire('LBL_DIV_DOSCSS', [
            // Section Dossier CSST
            new FormulaireItem('dcscssnum'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dcscssdatenv'),
            new FormulaireItem('dcscssflgopp', {direction: 'horizontal', default: -1}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dcscssdatadm'),
            new FormulaireItem('dcscssflgadm', {direction: 'horizontal', default: -1}),
            new FormulaireItem('dcscssflgavi'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dcscssdiacss', {largeur: 100, enableCount: true}),
            new FormulaireItem('dcscssdatdia'),
            new FormulaireItem('vacdiaraidcscss', {largeur: 66.66}),
            new FormulaireItem('dcscssdiamed', {largeur: 100, enableCount: true})
        ], {largeurDefaut: 33.33});

        vm.formulaireDpc = new Formulaire('LBL_DIV_DPC', [
            // Section Demande de partage de coûts
            new FormulaireItem('dcsdpcdatdem'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dcsdpcdatdec'),
            new FormulaireItem('dcsdpcflgdec', {direction: 'horizontal', default: -1}),
            new FormulaireItem('dcsdpcflgavi'),
            new FormulaireItem('dcsdpccom', {largeur: 100, enableCount: true})
        ], {largeurDefaut: 33.33});

        vm.formulaireRdcrad = new Formulaire('LBL_DIV_RDC', [
            // Section Recours dossier CSST - Révision administrative
            new FormulaireItemDivider('LBL_DIV_RDCRAD'),
            new FormulaireItem('dcsrdcraddatdem'),
            new FormulaireItem('vaedcsorirdcrad', {largeur: 66.66}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dcsrdcraddatdec'),
            new FormulaireItem('dcsrdcradflgdec', {direction: 'horizontal', default: -1}),
            new FormulaireItem('dcsrdcradflgavi'),
            new FormulaireItem('dcsrdcradcom', {largeur: 100, enableCount: true}),

            // Section Recours dossier CSST - Bureau d'évaluation médicale
            new FormulaireItemDivider('LBL_DIV_RDCBEM'),
            new FormulaireItem('dcsrdcbemdatdem'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dcsrdcbemdatdec'),
            new FormulaireItem('dcsrdcbemflgdec', {direction: 'horizontal', default: -1}),
            new FormulaireItem('dcsrdcbemflgconrad'),
            new FormulaireItemSautDeColonne({largeur: 66.66}),
            new FormulaireItem('dcsrdcbemflgconclp'),
            new FormulaireItem('dcsrdcbemcom', {largeur: 100, enableCount: true}),

            // Section Recours dossier CSST - Commission des lésions professionnelles
            new FormulaireItemDivider('LBL_DIV_RDCCLP'),
            new FormulaireItem('dcsrdcclpdatdem'),
            new FormulaireItem('vaedcsorirdcclp', {largeur: 66.66}),
            new FormulaireItem('dcsrdcclpdatdec'),
            new FormulaireItem('dcsrdcclpflgdec', {direction: 'horizontal', default: -1}),
            new FormulaireItem('dcsrdcclpcom', {largeur: 100, enableCount: true}),
        ], {largeurDefaut: 33.33});

        vm.formulaireRpcrad = new Formulaire('LBL_DIV_RPC', [
            // Section Recours Partage coûts - Révision administrative
            new FormulaireItemDivider('LBL_DIV_RPCRAD'),
            new FormulaireItem('dcsrpcraddatdem'),
            new FormulaireItem('vaedcsorirpcrad', {largeur: 66.66}),
            new FormulaireItem('dcsrpcraddatdec'),
            new FormulaireItem('dcsrpcradflgdec', {direction: 'horizontal', default: -1}),
            new FormulaireItem('dcsrpcradflgavi'),
            new FormulaireItem('dcsrpcradcom', {largeur: 100, enableCount: true}),

            // Section Recours Partage coûts - Commission des lésions professionnelles
            new FormulaireItemDivider('LBL_DIV_RPCCLP'),
            new FormulaireItem('dcsrpcclpdatdem'),
            new FormulaireItem('vaedcsorirpcclp', {largeur: 66.66}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dcsrpcclpdatdec'),
            new FormulaireItem('dcsrpcclpflgdec', {direction: 'horizontal', default: -1}),
            new FormulaireItem('dcsrpcclpcom', {largeur: 100, enableCount: true})
        ], {largeurDefaut: 33.33});
    }
}
