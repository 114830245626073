import {IComponentController} from 'angular';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageRr2009 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePva: IFormulaire;
    dbcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}

interface IRr2009Params {
    demcleint: string;
    bqecleint: string,
    dbscleintref1: number,
    dbscleintref2: number,
    datdeb: Date,
    datfin: Date,
    flgfer: number
}

/* @ngInject */
export default function Rr2009Controller(Formulaire: IFormulaireClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         $stateParams: IRr2009Params,
                                         Operateur: IOperateurService,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr2009 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                suiviModification: false
            }            
            
        };

        vm.formulairePva = new Formulaire('LBL_BLC_PVA', [
          new FormulaireGroupe('', new Formulaire([
            new FormulaireItem('eplnummat', {largeur: 25}),
            new FormulaireItem('eplnomprn', {largeur: 50}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('bqecod', {largeur: 25}),
            new FormulaireItem('bqedsc', {largeur: 50}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dbscod1', {largeur: 25}),
            new FormulaireItem('dbsdsc1', {largeur: 50}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('dbscod2', {largeur: 25}),
            new FormulaireItem('dbsdsc2', {largeur: 50})  
          ]), {largeur: 75}),
          new FormulaireGroupe('', new Formulaire([
            new FormulaireItem('datdeb', {default: $stateParams.datdeb ,largeur: 25}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('datfin', {default: $stateParams.datfin, largeur: 25}),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('flgfer', {default: $stateParams.flgfer, largeur: 25}),
            new FormulaireItemSautDeLigne() 
          ]), {largeur: 25})
          
        ]);

        vm.dbcMultiOccurrenceOptions = {
            bloc: 'dbc',
            filtres: [
                { colonne: 'bqecleint', 
                operateur: Operateur.EGALE,
                valeur: $stateParams.bqecleint,
                visible: false }
            ],
            colonnesVisibles: [
                {nom: 'dbcdat', largeur: 110},
                {nom: 'dbcqte', largeur: 120},
                {nom: 'vaesystrsbqe_dsc', largeur: 200},
                {nom: 'vaesysprvbqe_dsc', largeur: 200},
                {nom: 'ppacod', largeur: 150}
            ],
            
        };
    }
}
