import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0073',
    ecrans: ['00-2'],
    params: [
        '?demcleint',
        '?aftcleint',
        "?efface_criteres"
    ]
});
