import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';

interface IMr0333Params {
    id: string;
    menuId: string;
    placleint: number;
}

interface IPageMR0333 extends IComponentController{
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireAso: IFormulaire;
    formulaireFer: IFormulaire;
    aspMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceAsp: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0333Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         mr0334Route: IRoute,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         $stateParams: IMr0333Params,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass) {
    const vm: IPageMR0333 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireAso = new Formulaire("LBL_BLC_ASO", [
            new FormulaireItem('placleint', {default: $stateParams.placleint}),

            new FormulaireItem('asocod', {largeur: 25}),
            new FormulaireItem('asodsc', {largeur: 50}),
            new FormulaireItem('asodatdeb', {largeur: 25}),
            new FormulaireItem('asoflgobl', {largeur: 25, default : 0}),
            new FormulaireItem('asoflgoblcat', {largeur: 25, default : 0} ),
            new FormulaireItem('asoflgcom', {largeur: 25, default : 0} ),            
            new FormulaireItem('asoflgben', {largeur: 25, default : 0}),                        
            new FormulaireGroupeAccordeon('LBL_GRP_DET_OPTION',
                new Formulaire([
                    new FormulaireItem('vaeasosex'),
                    new FormulaireItem('vaeasodatnai'),
                    new FormulaireItem('vaeasofum'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('vacsysoptava'),
                    new FormulaireItem('asoflgtrtautass'),
                    new FormulaireItem('aspcleint')
                ], {largeurDefaut: 33.33})
            )


        ],{largeurDefaut:25} );

        // Sous-option
        vm.aspMultiOccurrenceOptions = {
            bloc: 'asp',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'LBL_SOUS_OPTION',
                   mr0334Route.NAME, () => {
                        return {
                            asocleint: vm.monoOccurrence.data.asocleint
                        };
                    },
                    {
                        onClose: () => { vm.monoOccurrence.fetchData(); }
                        //onClose: () => { vm.aspMultiOccurrence.fetchDataList(); }
                    }),
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'LBL_SOUS_OPTION',
                    mr0334Route.NAME,
                    (data: any) => ({
                        id: data.aspcleint
                        //placleint: vm.monoOccurrence.data.placleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => { vm.monoOccurrence.fetchData(); }
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'aspcod', largeur: 100},
                {nom: 'aspdsc', largeur: 100},
                {nom: 'rcecod', largeur: 100},
                {nom: 'rcedscabr', largeur: 100},
                {nom: 'aspflgact', largeur: 100}
            ]
        };

        vm.formulaireFer = new Formulaire('LBL_BLC_FER', [
            new FormulaireItem('asoagefer'),
            new FormulaireItem('vaeasoagefer'),
            new FormulaireItem('aspcleintfer')
        ]);

    }

}
