import { IComponentController } from 'angular';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';
interface IPageCr0007 extends IComponentController {
    formulaireFac: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireCor: IFormulaire;
    favMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fasMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ICr0007Params {
    lcrcleint: string;
    id: number;
}

/* @ngInject */
export default function Cr0007Controller(Formulaire: IFormulaireClass,
    cr1001Route: IRoute,
    cr1005Route: IRoute,
    mc0102Route: IRoute,
    mc1029Route: IRoute,
    cr0016Route: IRoute,
    cr2005Route: IRoute,
    mc0044Route: IRoute,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    exDateFilter: IFilterExDate,
    $stateParams: ICr0007Params,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    exLibelleFilter: IFilterLibelle) {
    const vm: IPageCr0007 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.monoOccurrenceOptions = {
            cycleErrorReloadBlocs: ['fav'],
            infosMonetaires: {
                blocs: ['fav', 'fas'],
                largeurTexte: 150,
                donnees:
                    [
                        { nom: 'fas', libelle: 'LBL_MNT_FAS' },
                        { nom: 'fav', libelle: 'LBL_MNT_FAV' },
                        { nom: 'enc', libelle: 'LBL_MNT_ENC' },
                        { nom: 'crt', libelle: 'LBL_MNT_CRT' },
                        { nom: 'res', libelle: 'LBL_MNT_RES' },
                    ]
            },
            infosFinancieres: {
                disponibilite: true,
                impactFinancier: true,
                impactBudgetaire: true
            },
            menus: [
                new MenuItemForage(
                    'LBL_CEDULE',
                    cr1001Route.NAME,
                    (data: any) => ({ id: data.faccleint }),
                    {
                        icon: 'settings',
                        iconButton: false,
                        fonction: 'BTN_CEDULE'
                    }
                ),
                new MenuItemForage(
                    'LBL_ENC',
                    cr1005Route.NAME,
                    (data: any) => ({ faccleint: data.faccleint }),
                    {
                        icon: 'settings',
                        iconButton: false
                    }
                ),
                new MenuItemForage(
                    'G_LBL_COR_IMP',
                    mc0102Route.NAME,
                    (data: any) => ({ doccleintref: data.doccleint }),
                    {
                        icon: 'settings',
                        compteur: (data: any) => data.aut__nbrcorimp > 0 ? data.aut__nbrcorimp : undefined,
                        iconButton: false,
                        fonction: 'BOUCORIMP',
                        disabled: (data: any) => !data.$id || ecranDetails.valeurs.flgre
                    }
                )
            ],
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_MSG_IMPRIME',
                    'BOUMSGIMP',
                    (data: any) => ({
                        mcmcleintref: data.faccleint,
                        vaemcment: 'FAC'
                    }),
                    {
                        icon: 'note',
                        iconButton: false,
                        successAction: (retour) => {
                            const menuItemEcran = new MenuItemEcran(
                                'LBL_BTN_REPERER_ETUDIANT',
                                mc1029Route.NAME,
                                () => ({
                                    id: retour.mcmcleintnew
                                })
                            );
                            return menuItemEcran.action(null, {});
                        }
                    }
                )
                , new MenuItemActionImportation(
                    'LBL_BTN_CHARGER_VENTILATION',
                    'BOUIMPVENT',
                    () => ({}),
                    {
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_BTN_CHARGER_VENTILATION',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('cifcleint', {
                                    largeur: 100,
                                    default: (data: any) => { return data.monoOccurrence.cifcleint },
                                    hidden: true

                                })
                            ], { libelleUpload: exLibelleFilter('G_LBL_BTN_UPLOAD_BOX_CSV') })
                        },
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        },
                        typesFichiers: ['.csv'],
                        icon: "file_upload",
                        disabled: () => !ecranDetails.valeurs.flgimpvent
                    }
                )
                , new MenuItemActionImportation(
                    'LBL_BTN_CHARGER_PSA',
                    'BOUIMPPSA',
                    () => ({}),
                    {
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_BTN_CHARGER_PSA',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('cifcleint', {
                                    largeur: 100,
                                    default: (data: any) => { return data.monoOccurrence.cifcleint },
                                    hidden: true

                                })
                            ], { libelleUpload: exLibelleFilter('G_LBL_BTN_UPLOAD_BOX_CSV') })
                        },
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        },
                        typesFichiers: ['.csv'],
                        icon: "file_upload",
                        disabled: () => !ecranDetails.valeurs.flgimpvent
                    }
                )
            ]),
            fonctions:{
                importation:true,
                boutonDupliquer:false 
            }
        };

        vm.formulaireFac = new Formulaire('LBL_BLC_FAC', [
            new FormulaireItem('facnumdoc'),
            new FormulaireItem('facnumdocseq', { readonly: true }),
            new FormulaireItem('sysfacnum', { readonly: true }),
            new FormulaireItem('refcleint', {
                largeur: 66.66,
                resourceParams: {
                    type: 'CLI'
                },
                readonly: (data: any) => (data.tydcleint === 18) || !!data.caccleint
            }),
            new FormulaireItem('radcleint', {
                menu: [
                    new MenuItemAction(
                        'LBL_ADRESSE',
                        'BOUAFFADR',
                        () => ({}),
                        {
                            icon: 'launch',
                            fonction: 'BTN_AFFADR',
                            formulaireConfirmationAction: {
                                lblTitre: 'LBL_ADRESSE',
                                lblConfirm: '',
                                largeurFormulaire: 30,
                                formulaire: new Formulaire([
                                    new FormulaireItem('t_radadrl01', { readonly: true, hidden: (data: any) => !data.t_radadrl01 }),
                                    new FormulaireItem('t_radadrl02', { readonly: true, hidden: (data: any) => !data.t_radadrl02 }),
                                    new FormulaireItem('t_radvil', { readonly: true, hidden: (data: any) => !data.t_radvil, largeur: 50 }),
                                    new FormulaireItem('t_radpro', { readonly: true, hidden: (data: any) => !data.t_radpro, largeur: 50 }),
                                    new FormulaireItem('t_radpay', { readonly: true, hidden: (data: any) => !data.t_radpay, largeur: 50 }),
                                    new FormulaireItem('t_radcodpos', { readonly: true, hidden: (data: any) => !data.t_radcodpos, largeur: 50 }),
                                    new FormulaireItem('t_radnom', { readonly: true, hidden: (data: any) => !data.t_radnom }),
                                    new FormulaireItem('t_radadrcou', { readonly: true, hidden: (data: any) => !data.t_radadrcou })
                                    
                                ], { largeurDefaut: 100 })
                            }
                        }
                    ),
                ]
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint', { modifAllow: false }),
            new FormulaireItem('peccleint'),
            new FormulaireItem('facdat', { default: exDateFilter(new Date()) }),
            new FormulaireItem('intcleintreq'),
            new FormulaireItem('cifcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('facnumref'),
            new FormulaireItem('caccleint', {
                readonly: true, menu: [
                    new MenuItemForage(
                        'LBL_CAC',
                        cr0016Route.NAME,
                        () => ({ id: ecranDetails.valeurs.cclcleint }),
                        {
                            icon: 'exit_to_app',
                            fonction: 'BTN_CAC',
                            hidden: (data: any) => !data.caccleint
                        }
                    )
                ]
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('facdsc', { largeur: 100, enableCount: true, required: () => (ecranDetails.valeurs.flgdscobl) }),
            new FormulaireItem('facmnt', { largeur: 25 }),
            new FormulaireItem('facmntcmp', { largeur: 25 }),
            new FormulaireItem('vaesysdevcod', { readonly: true, largeur: 25 }),
            new FormulaireItem('devtaucou', { readonly: true, largeur: 25 }),
            new FormulaireGroupeAccordeon('LBL_AUTRES_INFOS', new Formulaire([
                new FormulaireItem('vecfaccat', { viderByEntiteExterne: true }),
                new FormulaireItem('lcrcleint', { default: $stateParams.lcrcleint, viderByEntiteExterne: true }),
                new FormulaireItem('facflgpij', { default: 0, viderByEntiteExterne: true }),
                new FormulaireItem('enecleint'),
                new FormulaireItem('moecleint', { viderByEntiteExterne: true }),
                new FormulaireItem('facflgimp', { readonly: true }),
                new FormulaireItem('radcleintfac', {
                    largeur: 66.66,
                    menu: [
                        new MenuItemEcran(
                            'LBL_BTN_MODIF_ADRFAC',
                            cr2005Route.NAME,
                            (data: any) => ({
                                id: data.faccleint
                            }),
                            {
                                icon: 'launch',
                                hidden: () => !(ecranDetails.valeurs.flgdocrep),
                                onClose: () => {
                                    vm.monoOccurrence.fetchData();
                                }
                            }
                        )
                    ]
                }),
                new FormulaireItem('aut__radadrcou', { readonly: true })
            ], { largeurDefaut: 33.33 }))
        ], { largeurDefaut: 33.33 }
        );

        // Ventilation
        vm.favMultiOccurrenceOptions = {
            bloc: 'fav',
            reloadWithBlocs: ['FAC'],
            editionRapideActive: true,
            fonctions: {
                editionRapide: true,
                editionStandard: true,
                agrandirMultiOccurrence: true,
                supprime: (data: any) => !(data.duvcleint),
                importation: false
            },
            colonnesFixesGauche: [
                { nom: 'favflgduv', largeur: 100 },
                { nom: 'favimp', largeur: 310, menu: [
                    new MenuItemEcran(
                        '', 
                        mc0044Route.NAME, 
                        (rowData: any) => ({ 
                            id: rowData.prucleint, 
                            prucleint: rowData.prucleint }),
                        {
                        icon: 'launch',
                        iconButton: false,
                        secondaire: true,
                        fonction: 'BOUPRU'
                    })
                ] },
                { nom: 'favmnt', largeur: 150 }
            ],
            colonnesVisibles: [
                { nom: 'taxcod', largeur: 60 },
                { nom: 'favmnttaxfed', largeur: 126 },
                { nom: 'favmnttaxpro', largeur: 126 },
                { nom: 'favmntrevnet', largeur: 126 },
                { nom: 'favqte', largeur: 126 },
                { nom: 'favdsc', largeur: 300 }
            ],
            colonnesFixesDroite: [
                { nom: 'favflgmsg', largeur: 100 }
            ],
            colonnesEditionRapide: [
                { nom: 'favimp', largeur: 400 },
                { nom: 'favmnt', largeur: 150 },
                { nom: 'taxcleint', largeur: 300 },
                { nom: 'favmnttaxfed', largeur: 140 },
                { nom: 'favmnttaxpro', largeur: 140 },
                { nom: 'favmntrevnet', largeur: 126 },
                { nom: 'favqte', largeur: 126 },
                { nom: 'favdsc', largeur: 300 },
                { nom: 'favflgmsg', largeur: 100 },
                { nom: 'aut__favmsgrap', largeur: 300 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('favimp', { largeur: 50, isValidatingAtLoad: (data) => data.favflgmsg, required: true, readonly: (data: any) => data.duvcleint }),
                new FormulaireItem('favmnt', { readonly: (data: any) => data.duvcleint && !vm.monoOccurrence.data.facnumdocseq }),
                new FormulaireItem('taxcleint', { required: true, readonly: (data: any) => (data.duvcleint || ecranDetails.valeurs.cliflgtaxfix) }),
                new FormulaireItem('favmnttaxfed', { readonly: (data: any) => (data.duvcleint) }),
                new FormulaireItem('favmnttaxpro', { readonly: (data: any) => (data.duvcleint) }),
                new FormulaireItem('favmntrevnet', { readonly: true }),
                new FormulaireItem('favqte', { readonly: (data: any) => (data.duvcleint) }),
                new FormulaireItem('favdsc', { largeur: 50, readonly: (data: any) => (data.duvcleint) }),
                new FormulaireItem('favflgmsg', { readonly: true, required: false, titre: " " })
            ], { largeurDefaut: 25 }),
            colonnesCachees: [
                'duvcleint',
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint',
                'favmsg'
            ]
        };

        // Service
        vm.fasMultiOccurrenceOptions = {
            bloc: 'fas',
            reloadWithBlocs: ['FAC'],
            editionRapideActive: true,
            fonctions: {
                editionRapide: true,
                editionStandard: true,
                importation: false
            },
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_GENERE_IMP',
                    'BOUGENIMP',
                    () => ({}),
                    {
                        icon: 'view_list',
                        fonction: 'BTN_GENIMP',
                        messageConfirmation: 'BOUDET_CONFIRM',
                        successAction: () => {
                            vm.monoOccurrence.fetchData();
                        },

                    }
                )
            ]),
            colonnesFixesGauche: [
                { nom: 'fasnumlig', largeur: 100 },
                { nom: 'sercod', largeur: 100 },
                { nom: 'fasdsc', largeur: 300 }
            ],
            colonnesVisibles: [
                { nom: 'fasprxuni', largeur: 100 },
                { nom: 'fasqtefac', largeur: 100 },
                { nom: 'fasmnt', largeur: 100 },
                { nom: 'taxcod', largeur: 100 },
                { nom: 'fasmnttaxfed', largeur: 100 },
                { nom: 'fasmnttaxpro', largeur: 100 },
                { nom: 'fasmntrevnet', largeur: 100 }
            ],
            colonnesEditionRapide: [
                { nom: 'fasnumlig', largeur: 100 },
                { nom: 'sercleint', largeur: 300 },
                { nom: 'fasdsc', largeur: 250 },
                { nom: 'fasprxuni', largeur: 126 },
                { nom: 'fasqtefac', largeur: 126 },
                { nom: 'fasmnt', largeur: 126 },
                { nom: 'taxcleint', largeur: 300 },
                { nom: 'fasmnttaxfed', largeur: 140 },
                { nom: 'fasmnttaxpro', largeur: 140 },
                { nom: 'fasmntrevnet', largeur: 126 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fasnumlig'),
                new FormulaireItem('sercleint'),
                new FormulaireItem('fasdsc', { largeur: 50 }),
                new FormulaireItem('fasprxuni'),
                new FormulaireItem('fasqtefac'),
                new FormulaireItem('fasmnt', { readonly: true }),
                new FormulaireItem('fasmntrevnet', { readonly: true }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('taxcleint', { required: true }),
                new FormulaireItem('fasmnttaxfed'),
                new FormulaireItem('fasmnttaxpro')
            ], { largeurDefaut: 25 })
        };
    };

}
