import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemClass } from "../../../../../core/services/menu/menu-item.service";

interface IPageRr0002 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireTse: IFormulaire;
    formulaireCreation: IFormulaire;
    formulairetransfert: IFormulaire;
}

interface IRr0002Params {
    id: string;
    eplcleint: string;
    demcleint: string;
    lemcleint: string;
    tytcleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0002Controller(rr0003Route: IRoute,
    rr0002Route: IRoute,
    rr0005Route: IRoute,
    rr0540Route: IRoute,
    rr0004Route: IRoute,
    rr0006Route: IRoute,
    rr0022Route: IRoute,
    rr0155Route: IRoute,
    mr2005Route: IRoute,
    $stateParams: IRr0002Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItem: IMenuItemClass) {
    const vm: IPageRr0002 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            successActionSauvegarde: () => vm.monoOccurrence.fetchData(),
            fonctions: {
                boutonDupliquer: false
            },
            menus: [
                new MenuItemForage(
                    'LBL_REPERER',
                    mr2005Route.NAME,
                    (data: any) => ({ id: data.tsecleint }),
                    {
                        icon: 'add_circle',
                        iconButton: false,
                        hidden: () => !vm.valeursEcran.flgrep,
                        disabled: (data: any) => data.eplcleint 
                    }
                ),
                new MenuItem("LBL_EMPLOYE", (event: Event, data: any) => {
                    new MenuItemForage(
                        'LBL_EMPLOYE',
                        rr0003Route.NAME,
                        () => ({
                            trncleint: data.trncleint,
                            tsecleint: data.tsecleint
                        })
                    ).action(data, $stateParams, true)
                }, {
                    icon: 'add_circle',
                    iconButton: false,
                    hidden: (data: any) => (
                        !data.$id ||
                        data.$valeurs.trnflgstaann ||
                        data.$valeurs.trnflgstafer ||
                        data.eplcleint ||
                        data.$valeurs.tytcod === 'TSEDEM' ||
                        data.$valeurs.tytcod === 'TSELEM' ||
                        data.$valeurs.tytcod === 'TSEAFT' ||
                        data.$valeurs.tytcod === 'TSELSA' ||
                        data.$valeurs.tytcod === 'TSEAFT2'
                    )
                }),
                new MenuItem("LBL_LEMAFT", (event: Event, data: any) => {
                    new MenuItemForage(
                        'LBL_LEMAFT',
                        rr0004Route.NAME,
                        () => ({
                            trncleint: data.trncleint
                        }),
                    ).action(data, $stateParams, true)
                }, {
                    icon: 'add_circle',
                    iconButton: false,
                    hidden: (data: any) => (
                        !data.$id ||
                        data.lemcleint ||
                        data.$valeurs.trnflgstaann ||
                        data.$valeurs.trnflgstafer ||
                        data.$valeurs.tytcod === 'TSEAFT' ||
                        data.$valeurs.tytcod === 'TSELSA' ||
                        data.$valeurs.tytcod === 'TSEAFT2'
                    ),
                    disabled: (data: any) => !data.demcleint
                }),
                new MenuItem("LBL_PROBATION", (event: Event, data: any) => {
                    new MenuItemForage(
                        'LBL_PROBATION',
                        rr0155Route.NAME,
                        () => ({
                            id: data.tsecleint
                        }),
                    ).action(data, $stateParams, true)
                }, {
                    icon: (data) => data.tprcleint ? 'create' : 'add_circle',
                    iconButton: false,
                    fonction: 'BOUTPR',
                    hidden: (data: any) => (
                        !data.$id ||
                        vm.valeursEcran.flggespro === 3 ||
                        data.$valeurs.trnflgstaann ||
                        data.$valeurs.trnflgstafer
                    ),
                    disabled: (data: any) => (!data.lemcleint || !data.demcleint || (!data.tseflgprb && vm.valeursEcran.flggespro === 1))
                }),
                new MenuItem("LBL_AFFECTATION", (event: Event, data: any) => {
                    new MenuItemForage(
                        'LBL_AFFECTATION',
                        rr0006Route.NAME,
                        () => ({
                            trncleint: data.trncleint
                        }),
                    ).action(data, $stateParams, true)
                }, {
                    icon: 'add_circle',
                    iconButton: false,
                    hidden: (data: any) => (
                        !data.$id ||
                        data.aftcleint ||
                        data.$valeurs.trnflgstaann ||
                        data.$valeurs.trnflgstafer
                    ),
                    disabled: (data: any) => !data.lemcleint
                }),
                new MenuItem("LBL_CLASSIFICATION", (event: Event, data: any) => {
                    new MenuItemForage(
                        'LBL_CLASSIFICATION',
                        rr0022Route.NAME,
                        () => ({
                            tsecleint: data.tsecleint,
                            flgrr0005: 0
                        }),
                    ).action(data, $stateParams, true)
                }, {
                    icon: 'add_circle',
                    iconButton: false,
                    hidden: (data: any) => !data.aftcleint || data.lsacleint,
                    disabled: (data: any) => !data.demcleint || !data.lemcleint || !data.aftcleint
                })
            ],
        };

        vm.menus = {
            // Formulaire de temps
            aut__nbratf: [
                new MenuItemForage(
                    'BTN_FORAGE_ATF',
                    rr0540Route.NAME,
                    (data) => ({
                        id: data.tsecleint,
                        demcleint: data.demcleint,
                        aftcleintanc: (data.aut__nbratf !== 0) ? data.tsecleintref : null,
                        aftcleintnou: (data.aut__nbratf !== 0) ? data.aftcleint : null,
                        stamnetab: 'ATF',
                        tsecleint: data.tsecleint
                    }),
                    { hidden: (data: any) => !data.aftcleint }
                )
            ],
            // Demande de rémunération
            aut__nbrder: [
                new MenuItemForage(
                    'BTN_FORAGE_DER',
                    rr0540Route.NAME,
                    (data) => ({
                        id: data.tsecleint,
                        demcleint: data.demcleint,
                        aftcleintanc: (data.aut__nbrder !== 0) ? data.tsecleintref : null,
                        aftcleintnou: (data.aut__nbrder !== 0) ? data.aftcleint : null,
                        stamnetab: 'DER',
                        tsecleint: data.tsecleint
                    }),
                    { hidden: (data: any) => !data.aftcleint }
                )
            ],
            // Absence longue durée
            aut__nbrald: [
                new MenuItemForage(
                    'BTN_FORAGE_ALD',
                    rr0540Route.NAME,
                    (data) => ({
                        id: data.tsecleint,
                        demcleint: data.demcleint,
                        aftcleintanc: (data.aut__nbrald !== 0) ? data.tsecleintref : null,
                        aftcleintnou: (data.aut__nbrald !== 0) ? data.aftcleint : null,
                        stamnetab: 'DAL',
                        tsecleint: data.tsecleint
                    }),
                    { hidden: (data: any) => !data.aftcleint }
                )
            ],
            // Feuille de paie
            aut__nbrfep: [
                new MenuItemForage(
                    'BTN_FORAGE_FEP',
                    rr0540Route.NAME,
                    (data) => ({
                        id: data.tsecleint,
                        demcleint: data.demcleint,
                        aftcleintanc: (data.aut__nbrfep !== 0) ? data.tsecleintref : null,
                        aftcleintnou: (data.aut__nbrfep !== 0) ? data.aftcleint : null,
                        stamnetab: 'FEP',
                        tsecleint: data.tsecleint
                    }),
                    { hidden: (data: any) => !data.aftcleint }
                )
            ],
            // Planification décentralisée
            aut__nbrmpl: [
                new MenuItemForage(
                    'BTN_FORAGE_MPL',
                    rr0540Route.NAME,
                    (data) => ({
                        id: data.tsecleint,
                        demcleint: data.demcleint,
                        aftcleintanc: (data.aut__nbrmpl !== 0) ? data.tsecleintref : null,
                        aftcleintnou: (data.aut__nbrmpl !== 0) ? data.aftcleint : null,
                        stamnetab: 'MPL',
                        tsecleint: data.tsecleint
                    }),
                    { hidden: (data: any) => !data.aftcleint }
                )
            ],
            // Échange de temps
            aut__nbrecn: [
                new MenuItemForage(
                    'BTN_FORAGE_ECN',
                    rr0540Route.NAME,
                    (data) => ({
                        id: data.tsecleint,
                        demcleint: data.demcleint,
                        aftcleintanc: (data.aut__nbrecn !== 0) ? data.tsecleintref : null,
                        aftcleintnou: (data.aut__nbrecn !== 0) ? data.aftcleint : null,
                        stamnetab: 'ECN',
                        tsecleint: data.tsecleint
                    }),
                    { hidden: (data: any) => !data.aftcleint }
                )
            ]
        };

        const forageEmp = [
            new MenuItemForage(
                'BTN_FORAGE_EPL',
                rr0003Route.NAME,
                (data: any) => ({
                    id: data.trncleintdem
                }),
                {
                    fonction: 'BOUEPL',
                    hidden: (data: any) => (!data.eplcleint || !data.trncleintdem), saveFirst: true
                }
            )
        ];

        const forageAft = [
            new MenuItemForage(
                'BTN_FORAGE_AFT',
                rr0006Route.NAME,
                (data: any) => ({
                    id: data.trncleintaft
                }),
                {
                    fonction: 'BOUAFT',
                    hidden: (data: any) => (!data.aftcleint || !data.trncleintaft), saveFirst: true
                }
            )
        ];

        vm.formulaireTse = new Formulaire('LBL_TSE', [
            new FormulaireItem('tprcleint'),
            new FormulaireItem('tsenumdoc', { largeur: 75 }),
            new FormulaireItem('aut__trncleintori', { largeur: 20 }),
            new FormulaireItem('aut__tsenumdocori', { largeur: 20,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE',
                        rr0002Route.NAME,
                        (data: any) => ({ id: data.aut__trncleintori }),
                        { hidden: (data: any) => !data.aut__trncleintori }
                    )
                ]
            }),
            new FormulaireItem('vaetseori', { largeur: 75 }),
            new FormulaireItem('tsecleintref', { largeur: 20,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_',
                        `secure.${vm.valeursEcran.ecrcodref}.ecran`,
                        () => {
                            if (vm.valeursEcran.vaetseori === 'IFT') {
                                return {
                                    fatcleint: vm.valeursEcran.fatcleint,
                                    fdacleint: vm.valeursEcran.fdacleint
                                }
                            } else if (vm.valeursEcran.vaetseori === 'OFC') {
                                return { caecleint: vm.valeursEcran.caecleint }
                            } else if (vm.valeursEcran.vaetseori === 'CAD') {
                                return { cadcleint: vm.valeursEcran.cadcleint }
                            } else if (vm.valeursEcran.vaetseori === 'AFT') {
                                return {
                                    id: vm.valeursEcran.trncleintref,
                                    aftcleint: vm.valeursEcran.aftcleint,
                                    eplcleint: vm.valeursEcran.eplcleint
                                }
                            } else {
                                return { id: vm.valeursEcran.trncleintref }
                            }
                        },
                        { hidden: (data: any) => !data.tsecleintref || !vm.valeursEcran.flgref, saveFirst: true }
                    )
                ]
            }),
            new FormulaireItem('ttccleint', { largeur: 75,
                resourceParams: (data: any) => ({
                    vaetseori: data.vaetseori
                }),
            }),
            new FormulaireItem('tsedatdeb', { largeur: 20 }),
            new FormulaireItem('eplcleint', { largeur: 75,
                menu: forageEmp,
                default: $stateParams.eplcleint || vm.valeursEcran.eplcleint
            }),
            new FormulaireItem('demcleint', { largeur: 75,
                menu: forageEmp,
                default: $stateParams.demcleint
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lemcleint', { largeur: 75,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_LEM',
                        rr0004Route.NAME,
                        (data: any) => ({
                            id: data.trncleintlem
                        }),
                        {
                            fonction: 'BOULEM',
                            hidden: (data: any) => (!data.lemcleint || !data.trncleintlem), saveFirst: true
                        }
                    )
                ],
                default: $stateParams.lemcleint
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aftcleint', { largeur: 75, 
                menu: forageAft
            }),
            new FormulaireItem('tseflgaft', { largeur: 20, titre:vm.valeursEcran.titflgaft, readonly: true , hidden: (data: any) => data.tsecleintaft || !data.aftcleint  }),
            new FormulaireItem('aut__ttseflgaft', { largeur: 20, titre:vm.valeursEcran.titflgaft, readonly: true, hidden: (data: any) => !data.tsecleintaft }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lsacleint', { largeur: 75,
                hidden: () => !vm.valeursEcran.flgtsa,
                resourceParams: (data: any) => {
                    return {
                        aftcleint: data.aftcleint,
                        lemcleint: data.lemcleint
                    };
                },
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_LSA',
                        (vm.valeursEcran.nbrlsa > 1) ?
                            rr0005Route.NAME :
                            rr0022Route.NAME,
                        (data: any) => {
                            if (vm.valeursEcran.nbrlsa > 1) {
                                return {
                                    id: data.lsacleint
                                };
                            } else {
                                return {
                                    id: data.aut__trncleinttsa,
                                    tsecleint: data.tsecleint,
                                    flgrr0005: 0
                                };
                            }
                        },
                        {
                            fonction: 'BOULSA',
                            hidden: (data: any) => !data.lsacleint, saveFirst: true
                        }
                    )
                ]
            }),
            new FormulaireItem('tseflglsa', { largeur: 20, titre:vm.valeursEcran.titflglsa, readonly: true, hidden: (data: any) => !data.lsacleint || vm.valeursEcran.afflsa }),
            new FormulaireItem('aut__ttseflglsa', { largeur: 20, titre:vm.valeursEcran.titflglsa, readonly: true, hidden: !vm.valeursEcran.afflsa}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tseflgcreaft'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tseflgprb', {
                largeur: 25, readonly: vm.valeursEcran.flggespro === 2, hidden: vm.valeursEcran.flggespro === 3,
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_PRB',
                        rr0155Route.NAME,
                        (data: any) => ({ id: data.tsecleint }),
                        {
                            hidden: (data: any) => !data.tseflgprb || !data.lemcleint || !vm.valeursEcran.flgprb,
                            saveFirst: true
                        }
                    )
                ]
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tsedsc', {
                largeur: 100
            }),
        ]);
    }
}
