import IStateService = angular.ui.IStateService;
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IFormulaireGroupeAccordeonClass } from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IMenuItem } from "../../../../../core/services/menu/menu-item.service";
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {
    IMenuItemActionImportation,
    IMenuItemActionImportationClass
} from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";

interface IPageMc0010 extends IComponentController {
    menuItemImage: IMenuItemActionImportation;
    menuItemModifImage: IMenuItemActionImportation;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireGou: IFormulaire;
    formulaireFin: IFormulaire;
    formulaireMsg: IFormulaire;
    formulaireFac: IFormulaire;
    formulairePai: IFormulaire;
    formulaireMod: IFormulaire;
    formulaireAch: IFormulaire;
    formulaireAut: IFormulaire;
    valeursEcran: any;
    cifMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mcoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    imgMultiOccurrenceOptions: IMultiOccurrenceOptions;
    imgMultiOccurrence: IMultiOccurrence;
    usageimp: IMenuItem;
    usaimpdat: IMenuItem;
    tolerance: IMenuItem;
    dossier: IMenuItem;
}

interface IMc0010Params {
    menuId: string;
}

/* @ngInject */
export default function Mc0010Controller(mc0046Route: IRoute,
    mc0111Route: IRoute,
    mc0163Route: IRoute,
    mc0086Route: IRoute,
    mc1014Route: IRoute,
    $state: IStateService,
    Menu: IMenuClass,
    $stateParams: IMc0010Params,
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc0010 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_CIE', [
                new FormulaireItem('hldcle', { largeur: 50 }),
                new FormulaireItem('ciecod', { largeur: 25 }),
                new FormulaireItem('cienom', { largeur: 50 }),
                new FormulaireItem('cienomabr', { largeur: 50 }),
                new FormulaireItem('cietel', { largeur: 25 }),
                new FormulaireItem('ciefax', { largeur: 25 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ciedatdeb'),
                new FormulaireItem('ciedatfin'),
                new FormulaireGroupeAccordeon('LBL_ACCORDEON_COORDONNEES', new Formulaire([
                    new FormulaireItem('cieadr1'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('cieadr2'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('cievil'),
                    new FormulaireItem('ciepro'),
                    new FormulaireItem('ciecodpos', { modelOptions: { updateOn: 'blur' } }),
                    new FormulaireItem('ciepay'),
                    new FormulaireItem('cieadrsitint')
                ], { largeurDefaut: 50 })),
            ], { largeurDefaut: 33.33 })
        };

        vm.menuItemImage = new MenuItemActionImportation(
            'LBL_IMPORT_FICHIER',
            'BOUAJOIMG',
            () => ({}),
            {
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_IMPORT_FICHIER',
                    formulaire: new Formulaire([
                        new FormulaireItem('ciecle', { default: () => vm.monoOccurrence.id, hidden: true }),
                        new FormulaireItem('vaeimgtyp', { required: true, largeur: 100 })
                    ])
                },
                typesFichiers: ['.png', '.gif', '.jpg', '.jpeg', '.bmp'],
                successAction: () => vm.imgMultiOccurrence.fetchDataList(),
                icon: 'add_a_photo'
            }
        );

        vm.menuItemModifImage = new MenuItemActionImportation(
            'LBL_IMPORT_FICHIER',
            'BOUMODIMG',
            () => ({}),
            {
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_IMPORT_FICHIER',
                    formulaire: new Formulaire([])
                },
                typesFichiers: ['.png', '.gif', '.jpg', '.jpeg', '.bmp'],
                icon: 'add_a_photo',
                champsRechargement: ['imgcleint']
            }
        );

        // Usage d'imputation
        vm.usageimp = new MenuItemEcran(
            'LBL_USAGE_IMPUTATION',
            mc0046Route.NAME,
            () => ({
                vaeusient: 'CIE',
                uvicleintref: vm.monoOccurrence.id
            }),
            {
                ecran: '01-2',
                icon: 'view_list',
                iconButton: false,
                secondaire: true
            }
        );

        // Usage d'imputation avec date
        vm.usaimpdat = new MenuItemEcran(
            'LBL_IMPUTATION_DATVIG',
            mc0111Route.NAME,
            () => ({
                stamnetab: 'CIE',
                sdvcleintref: vm.monoOccurrence.id
            }),
            {
                icon: 'view_list',
                iconButton: false,
                secondaire: true
            }
        );

        // Tolerance
        vm.tolerance = new MenuItemEcran(
            'LBL_TOLERANCE',
            mc1014Route.NAME,
            () => ({
                tolcleintref: vm.monoOccurrence.id, // ciecleint
                vaeustent: 'CIE'// Compagnie
                // indicateur de forage à developper :  indfor:  0  // On a le droit d'éditer les données
            }),
            {
                icon: 'data_usage',
                iconButton: false,
                secondaire: true
            }
        );

        // Dossier
        vm.dossier = new MenuItemAction(
            'LBL_DOSSIER_COMPAGNIE',
            'BOUCRECRI',
            () => ({ ciecle: vm.monoOccurrence.data.ciecle }),
            {
                icon: 'folder',
                iconButton: false,
                secondaire: true,
                successAction: (retour: any) => {
                    $state.go(mc0086Route.NAME, {
                        menuId: $stateParams.menuId,
                        id: retour.cricleint
                    });
                }
            }
        );

        // Infos financières
        vm.formulaireFin = new Formulaire('LBL_FIN', [
            new FormulaireItem('cifcleintrspbdg', {
                resourceParams: () => ({ ciecle: vm.monoOccurrence.id })
            }),
            new FormulaireItem('cifcleintimm', {
                resourceParams: () => ({ ciecle: vm.monoOccurrence.id })
            }),
            new FormulaireItem('etbcleint'),
            new FormulaireItem('cieflggesproapp'),
            new FormulaireItem('taxcleint', {
                resourceParams: () => ({ hldcle: vm.monoOccurrence.data.hldcle })
            }),
            new FormulaireItem('remcleint'),
        ]);

        // Fonds financiers
        vm.cifMultiOccurrenceOptions = {
            navigatePage: mc0163Route.NAME,
            navigateParams: (rowData: any) => ({
                id: rowData.cifcleint,
                ciecleintcif: rowData.ciecleint
            }),
            actionsNouveaux: new Menu([
                new MenuItemForage(mc0163Route.NAME, () => ({ ciecleintcif: vm.monoOccurrence.id }))
            ]),
            colonnesVisibles: [
                { nom: 'cifcod', largeur: 70 },
                { nom: 'cifdsc', largeur: 155 },
                { nom: 'cifflgsai', largeur: 125 }
            ],
            colonnesCachees: [
                'cifcleint'
            ]
        };

        // Messages
        vm.formulaireMsg = new Formulaire('LBL_MSG', [
            new FormulaireItem('message', { largeur: 100 }),
            new FormulaireItem('messagecr', { largeur: 100 })
        ]);

        // Courriel
        vm.mcoMultiOccurrenceOptions = {
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('mcoadrcou', { largeur: 50 }),
                new FormulaireItem('mcoflgact', { default: 1 }),
                new FormulaireItem('ciecle', { default: () => vm.monoOccurrence.id })
            ]),
            colonnesVisibles: [
                { nom: 'mcoadrcou', largeur: 220 },
                { nom: 'mcoflgact', largeur: 90 }
            ]
        };

        // Images
        vm.imgMultiOccurrenceOptions = {
            actionsNouveaux: new Menu([
                vm.menuItemImage
            ]),
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                { nom: 'vaeimgtyp_dsc', largeur: 100 }
            ],
            colonnesCachees: [
                'imgcleint',
                'vaeimgext'
            ]
        };

        // Lieu facturation
        vm.formulaireFac = new Formulaire('LBL_FACTURATION', [
            new FormulaireItem('lipcleintfac', {
                largeur: 75,
                resourceParams: () => ({ ciecle: vm.monoOccurrence.id })
            }),
            new FormulaireItem('t_adrfac', { largeur: 100 })
        ]);

        // Lieu paiement
        vm.formulairePai = new Formulaire('LBL_PAIEMENT', [
            new FormulaireItem('lipcleintpai', {
                largeur: 75,
                resourceParams: () => ({ ciecle: vm.monoOccurrence.id })
            }),
            new FormulaireItem('t_adrpai', { largeur: 100 })
        ]);

        // Modalité de facturation
        vm.formulaireMod = new Formulaire('LBL_MODALITE_FAC', [
            new FormulaireItem('vecsysmodtra', {
                resourceParams: () => ({ hldcle: vm.monoOccurrence.data.hldcle })
            }),
            new FormulaireItem('teccleint', {
                resourceParams: () => ({ hldcle: vm.monoOccurrence.data.hldcle })
            })
        ]);

        // Modalité d'achat
        vm.formulaireAch = new Formulaire('LBL_MODALITE_ACH', [
            new FormulaireItem('iaccleint', {
                largeur: 75,
                resourceParams: () => ({ hldcle: vm.monoOccurrence.data.hldcle })
            }),
            new FormulaireItem('motcleint', {
                largeur: 100,
                resourceParams: () => ({ hldcle: vm.monoOccurrence.data.hldcle })
            })
        ]);

        // Autres détails
        vm.formulaireAut = new Formulaire('LBL_AUTRES', [
            new FormulaireItem('lipcleintdac', {
                largeur: 50,
                resourceParams: () => ({ ciecle: vm.monoOccurrence.id })
            }),
            new FormulaireItem('cienbrjrs', { largeur: 50 }),
            new FormulaireItem('t_adrdac', { largeur: 50 }),
        ]);
    }
}
