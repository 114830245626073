import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPagePc0017 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
    purMultiOccurrenceOptions: IMultiOccurrenceOptions;
    putMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pgmMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pc0017Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPagePc0017 = this;

    vm.formulaire = new Formulaire('LBL_PARAM', [
        new FormulaireItem('pgecod', { largeur: 10 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('pyscleint', { largeur: 40 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('vacvclreg', { largeur: 40 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('colcleint', { largeur: 40 })
    ]);

    // Onglets Unité regroup. EPE
    vm.purMultiOccurrenceOptions = {
        bloc: 'pur',
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 130 },
            { nom: 'urgdsc', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint', { largeur: 60 })
        ])
    }

    // Onglets titre d'emploi
    vm.putMultiOccurrenceOptions = {
        bloc: 'put',
        colonnesVisibles: [
            { nom: 'temcod', largeur: 20 },
            { nom: 'temdscabr', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('temcleint', { largeur: 60 })
        ])

    }

    // Onglets type demande rémun
    vm.pgmMultiOccurrenceOptions = {
        bloc: 'pgm',
        colonnesVisibles: [
            { nom: 'tdmcod', largeur: 100 },
            { nom: 'tdmdsc', largeur: 200 },
            { nom: 'vacvalcle', largeur: 130 },
            { nom: 'vacdsc', largeur: 230 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tdmcleint', { largeur: 60 }),
            new FormulaireItem('vaccrecst', { largeur: 40 })
        ])
    }

}
