import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IStateParamsService} from 'angular-ui-router';
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemSelectionClass} from "../../../../../core/services/menu/menu-item-selection.service";

interface IPageAL0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Al0007Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService,
                                         Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPageAL0007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datdeb', {
                required: true
            }),
            new FormulaireItem('datfin', {
                required: true
            }),
            new FormulaireItem('dattraite', {
                required: true
            }),
            new FormulaireItem('radio', {
                default: '1',
                required: true
            })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_TRTCHE',
                'BOUTRAITER',
                {
                    selection: { nom: 'traiter' },
                    successAction: () => {
                        vm.multiOccurrence && vm.multiOccurrence.fetchDataList();
                    },
                    fonction: 'BOUTRAITER'
                })
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe},
            {nom: 'dcqnumref', largeur: 170},
            {nom: 'dcqdat', largeur: 140},
            {nom: 'dcqmnt', largeur: 126},
            {nom: 'dcqdattrt', largeur: 140}
        ]
    };
}
