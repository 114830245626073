import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCB0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cb0008Controller(Menu: IMenuClass) {
    const vm: IPageCB0008 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesFixesGauche: [
            { nom: 'prjcod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'prjdsc', largeur: 180 },
            { nom: 'typdsc', largeur: 150 },
            { nom: 'prjcodpri', largeur: 150 },
            { nom: 'annee_deb', largeur: 90 },
            { nom: 'mois_deb', largeur: 130 },
            { nom: 'annee_fin', largeur: 100 },
            { nom: 'mois_fin', largeur: 130 },
            { nom: 'rspcod', largeur: 150 },
            { nom: 'rspnom', largeur: 150 },
            { nom: 'unacod', largeur: 150 },
            { nom: 'unadsc', largeur: 170 },
            { nom: 'etbcod', largeur: 150 },
            { nom: 'etbnom', largeur: 170 },
            { nom: 'prjflgifr', largeur: 40 },
            { nom: 'prjnumtrfifr', largeur: 200 },
            { nom: 'usrcod', largeur: 100 }
        ]
    };
}
