import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0008',
    ecrans: ['00-2'],
    params: ['id',
             'cleintref',
             'stamnetab',
             'vaesystypavi',
             'typsai',
             'supprime',
             '?vaebmtent',
             '?apacleint',
             '?bqecleint',
             '?tprcleint']
});
