import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";

interface IMr0330Params {
    id: string;
    menuId: string;
    fpncleint: number;
}

interface IPageMr0330 extends IComponentController {
    formulaireFpo: IFormulaire;
    fssMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rvaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0330Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $stateParams: IMr0330Params) {
    const vm: IPageMr0330 = this;

    // Valeurs-RCE
    const rvaMultiOccurrenceOptions = {
        bloc: 'rva',
        colonnesVisibles: [
            {nom: 'rcpdatvig'    , largeur: 150},
            {nom: 'rvanumval'    , largeur: 100},
            {nom: 'rvadsc'       , largeur: 250},
            {nom: 'rvaval'       , largeur: 150},
            {nom: 'vaervatyp_dsc', largeur: 200}
        ]
    };

    vm.$onInit = $onInit;

    function $onInit() {
        // Options
        vm.formulaireFpo = new Formulaire('LBL_BLC_FPO', [
            new FormulaireItem('fpncleint', {default: $stateParams.fpncleint}),
            new FormulaireItem('fpocod'),
            new FormulaireItem('fpodsc', {largeur: 50}),
            new FormulaireItem('fpoflgobl'),
            new FormulaireItem('fpodatdeb'),
            new FormulaireItem('vacsysoptava', {largeur: 50}),
            new FormulaireItem('fpoflgcom'),
            new FormulaireItem('fsscleint')
        ], {largeurDefaut: 25});

        // Sous-options
        vm.fssMultiOccurrenceOptions = {
            bloc: 'fss',
            colonnesVisibles: [
                {nom: 'fsscod'   , largeur: 116},
                {nom: 'fssdsc'   , largeur: 250},
                {nom: 'fssflgact', largeur: 120},
                {nom: 'rcecod'   , largeur: 120},
                {nom: 'rcedscabr', largeur: 250}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fsscod', {largeur: 25}),
                new FormulaireItem('fssdsc'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('fssflgact', {largeur: 25, default: 1}),
                new FormulaireItem('rcecleint')
            ], {largeurDefaut: 75}),
            details: {lblTitre: 'LBL_BLC_RVA', multiOccurrenceOptions: rvaMultiOccurrenceOptions}
        };
    }
}
