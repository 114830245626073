import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMR0172 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0172Controller(mr0171Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass) {
    const vm: IPageMR0172 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0171Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0171Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'rsccod', largeur: 120 }
        ],
        colonnesVisibles: [          
            {nom: 'rscdsc', largeur: 200},
            {nom: 'vaersceleanc', largeur: 120},
            {nom: 'vaersctyp', largeur: 150},
            {nom: 'rscflgimpbas', largeur: 70},
            {nom: 'rscflgpri', largeur: 70},
            {nom: 'rscflgpriadm', largeur: 70},
            {nom: 'rsccom', largeur: 200}
        ]
    };
}
