import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0105 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0105Controller(mr0104Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMr0105 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0104Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                eslcleint: rowData.eslcleint
            };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem("cvtcleint", { required: false }),
            new FormulaireItem("daedatvig", { required: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem("geccleint", { required: false }),
        ]),
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0104Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'geccod', largeur: 100 },
            { nom: 'gecdsc', largeur: 250 },
            { nom: 'daedatvig', largeur: 120 },
            { nom: 'cvtcod', largeur: 120 },
            { nom: 'cvtdsc', largeur: 250 },
            { nom: 'uncdsc', largeur: 240 },
            { nom: 'eslprcaug', largeur: 100 },
            { nom: 'eslsalbas', largeur: 131 },
            { nom: 'eslflgact', largeur: 91 }
        ],
        colonnesCachees: [
            'eslcleint'
        ]
    };
}
