import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCP0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0008Controller(Menu: IMenuClass) {
    const vm: IPageCP0008 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            edition: false,
            supprime: false
        },
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            { nom: 'lcpcod', largeur: 89 },
            { nom: 'peccod', largeur: 89 },
            { nom: 'lcpstu', largeur: 84 },
            { nom: 'usrcod', largeur: 100 },
            { nom: 'lcpflgstu', largeur: 72 },
            { nom: 'lcpindcom', largeur: 100 },
            { nom: 'lcpindrep', largeur: 91 },
            { nom: 'redcod', largeur: 125 },
            { nom: 'reddsc', largeur: 245 },
            { nom: 'lcpdatrep', largeur: 120 },
            { nom: 'nbrtrs', largeur: 91 },
            { nom: 'nbrrep', largeur: 91 },
            { nom: 'mnttrs', largeur: 126 },
            { nom: 'lcpdsc', largeur: 310 }
        ]
    };
}
