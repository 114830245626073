import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";

interface IPageMR0338 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireMcf: IFormulaire;
    mmdMultiOccurrenceOptions: IMultiOccurrenceOptions;

}

/* @ngInject */
export default function Mr0338Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMR0338 = this;

    vm.formulaireMcf = new Formulaire('LBL_BLC_MCF', [
        new FormulaireItem('mcfcod'),
        new FormulaireItem('mcfdsc', { largeur: 75, enableCount: true })
    ], { largeurDefaut: 25 });

    vm.mmdMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 120 },
            { nom: 'tagcod', largeur: 100 },
            { nom: 'actcod', largeur: 120 },
            { nom: 'colcod', largeur: 100 },
            { nom: 'colcodtrv', largeur: 130 },
            { nom: 'colcodfer', largeur: 130 },
            { nom: 'mmdhresem', largeur: 100 },
            { nom: 'bqecod', largeur: 100 },
            { nom: 'mtfnummth', largeur: 100 },
            { nom: 'urgdscabr', largeur: 250 },
            { nom: 'tagdscabr', largeur: 250 },
            { nom: 'actdsc', largeur: 250 },
            { nom: 'coldsc', largeur: 250 },
            { nom: 'coldsctrv', largeur: 250 },
            { nom: 'coldscfer', largeur: 250 },
            { nom: 'bqedsc', largeur: 250 },
            { nom: 'mtfdsc', largeur: 300 }

        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('tagcleint'),
            new FormulaireItem('actcleint'),
            new FormulaireItem('colcleint'),
            new FormulaireItem('colcleinttrv'),
            new FormulaireItem('colcleintfer'),
            new FormulaireItem('mmdhresem'),
            new FormulaireItem('bqecleint'),
            new FormulaireItem('mtfcleint'),
            new FormulaireItem('mtfnummth')
        ], { largeurDefaut: 25 })
    };
}
