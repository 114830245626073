import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IOperateurService} from "../../../../../core/services/operateur.service";

interface IPageLS0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ls0005Controller(Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService,
                                         ls0004Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Operateur: IOperateurService,
                                         $stateParams: IStateParamsService) {
    const vm: IPageLS0005 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: ls0004Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(ls0004Route.NAME)]),
        filtres:  parametresRecherche(ecranDetails),
        fonctions: {
            selectionnerUnEtat:false
        },
        colonnesDisponibles: [
            {nom: 'urgcod', largeur: 90},
            {nom: 'aftcod', largeur: 105}, // si moins ... apparaissent
            {nom: 'temdscabr', largeur: 262},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 225},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'dlsdat', largeur: 100},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 91},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ],
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe},
            {nom: 'dlsnumdoc', largeur: 100},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'dlsdatdeb', largeur: 100},
            {nom: 'dlshredeb', largeur: 70},
            {nom: 'dlsdatfin', largeur: 100},
            {nom: 'dlshrefin', largeur: 70},
            {nom: 'dlsdur', largeur: 70},
            {nom: 'lsxcod', largeur: 97},
            {nom: 'lsxdsc', largeur: 200},
            {nom: 'dlsnomdem', largeur: 262},
            {nom: 'dlscom', largeur: 225}
        ]
    };
}
