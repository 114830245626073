import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
interface IPageTf0028 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Tf0028Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass, ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageTf0028 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'folcod', largeur: 99 },
            { nom: 'foldsc', largeur: 280 },
            { nom: 'vaedsc', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'nbremftot', largeur: 110 },
            { nom: 'nbremfgen', largeur: 110 },
            { nom: 'nbremfnongen', largeur: 111 },
            { nom: 'nbremfzero', largeur: 110 },
            { nom: 'nbrftxcre', largeur: 110 },
            { nom: 'nbrftxuti', largeur: 116 },
            { nom: 'nbrtfscre', largeur: 110 },
            { nom: 'nbrtfsuti', largeur: 155 },
            { nom: 'nbrtfvcre', largeur: 110 },
            { nom: 'nbrtfvuti', largeur: 190 },
            { nom: 'nbremffac', largeur: 116 },
            { nom: 'nbremfxml', largeur: 135 },
            { nom: 'nbremfimp', largeur: 110 },
            { nom: 'nbremfpub', largeur: 110 },
            { nom: 'nbrtfaoff', largeur: 110 },
            { nom: 'nbrtfat4', largeur: 110 },
            { nom: 'nbrptefaxtot', largeur: 190 },
            { nom: 'nbrptefaxdisp', largeur: 130 },
            { nom: 'ptxprofic', largeur: 103 },
            { nom: 'nbrptexmltot', largeur: 190 },
            { nom: 'nbrptexmldisp', largeur: 125 },
            { nom: 'nbremfxmlgen', largeur: 91 },
            { nom: 'nbremfxml01', largeur: 190 },
            { nom: 'nbremfxml02', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot02, hidden: () => !vm.valeursEcran.flgaff02 },
            { nom: 'nbremfxml03', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot03, hidden: () => !vm.valeursEcran.flgaff03 },
            { nom: 'nbremfxml04', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot04, hidden: () => !vm.valeursEcran.flgaff04 },
            { nom: 'nbremfxml05', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot05, hidden: () => !vm.valeursEcran.flgaff05 },
            { nom: 'nbremfxml06', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot06, hidden: () => !vm.valeursEcran.flgaff06 },
            { nom: 'nbremfxml07', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot07, hidden: () => !vm.valeursEcran.flgaff07 },
            { nom: 'nbremfxml08', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot08, hidden: () => !vm.valeursEcran.flgaff08 },
            { nom: 'nbremfxml09', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot09, hidden: () => !vm.valeursEcran.flgaff09 },
            { nom: 'nbremfxml10', largeur: 102, replacedValue: () => vm.valeursEcran.dsclot10, hidden: () => !vm.valeursEcran.flgaff10 }
        ]
    };
}
