import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemRecuperationClass } from '../../../../../core/services/menu/menu-item-recuperation.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageCO0014 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    corMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0014Controller(co0009Route: IRoute,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemRecuperation: IMenuItemRecuperationClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCO0014 = this;

    vm.corMultiOccurrenceOptions = {
        bloc: 'cor',
        navigatePage: co0009Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemEcran(
                'G_LBL_COMREQ',
                co0009Route.NAME,
                () => ({
                    stamnetab: 'TEM',
                    corcleintref: vm.multiOccurrence.getDataActiveRow().temcleint
                }),
                {
                    onClose() {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        actionsMore: new Menu([
            new MenuItemRecuperation({
                nom: 'recuperation_cor',
                criteresSuggeres: new Formulaire([
                    new FormulaireItem('offcleint_cor'),
                    new FormulaireItem('poscleint_cor'),
                    new FormulaireItem('temcleint_cor'),
                    new FormulaireItem('urgcleint_cor')
                ]),
                colonnesVisibles: [
                    { nom: 'grcdsc', largeur: 300 },
                    { nom: 'corseqaff', largeur: 110 },
                    { nom: 'comcod', largeur: 115 },
                    { nom: 'comdsc', largeur: 300 },
                    { nom: 'corflgpae', largeur: 91 }
                ]
            })
        ]),
        colonnesFixesGauche: [
            { nom: 'grccod', largeur: 150 },
            { nom: 'grcdsc', largeur: 274 }
        ],
        colonnesVisibles: [
            { nom: 'corseqaff', largeur: 100 },
            { nom: 'comcod', largeur: 120 },
            { nom: 'comdsc', largeur: 303 },
            { nom: 'vacvalcle', largeur: 120 },
            { nom: 'vacdsc', largeur: 135 },
            { nom: 'corfacimp', largeur: 110 },
            { nom: 'nivcod', largeur: 80 },
            { nom: 'nivdsc', largeur: 236 },
            { nom: 'corvalcib', largeur: 110 },
            { nom: 'corcom', largeur: 315 },
            { nom: 'cordscweb', largeur: 315 },
            { nom: 'corflgaffweb', largeur: 74 },
            { nom: 'corflgidecan', largeur: 88 },
            { nom: 'corflgpae', largeur: 74 }
        ],
        colonnesCachees: ['corcleint', 'temcleint']
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesCachees: ['temcleint'],
        colonnesVisibles: [
            { nom: 'temcod', largeur: 89 },
            { nom: 'temdsc', largeur: 262 },
            { nom: 'grecod', largeur: 89 },
            { nom: 'gredsc', largeur: 262 }
        ],
        multiOccurrenceOptionsEnfant: vm.corMultiOccurrenceOptions
    };
}
