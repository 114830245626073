import { IComponentController } from 'angular';
import { IStateService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageDo2001 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    xddMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Do2001Controller(
    do2000Route: IRoute,
    $state: IStateService,
    MenuItemAction: IMenuItemActionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageDo2001 = this;

    vm.monoOccurrenceOptions = {
        menus: [
            new MenuItemAction(
                'LBL_BTN_SUP',
                'BOUSUP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    successAction: () => $state.go(do2000Route.NAME, {})
                }
            ),
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => vm.monoOccurrence.fetchData(),
                    hidden: () => !((vm.monoOccurrence.data.xfdflggen && !vm.monoOccurrence.data.xfdnbrlig) || (!vm.monoOccurrence.data.xfdflggen && vm.monoOccurrence.data.xfdnbrlig))
                }
            ),
            new MenuItemAction(
                'LBL_BTN_GEN',
                'BOUGEN',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => vm.monoOccurrence.fetchData(),
                    hidden: () => !(vm.monoOccurrence.data.xfdflgval && !vm.monoOccurrence.data.xfdflggen)
                }
            )
        ],
        fonctions: {
            afficherMenusDansEntete: true
        },
        formulaire: new Formulaire('LBL_BLC_XFD', [
            new FormulaireItem('xfdnumcha'),
            new FormulaireItem('xfdficsou'),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('xfdnbrlig')
        ])
    };

    vm.xddMultiOccurrenceOptions = {
        bloc: 'xdd',
        colonnesFixesGauche: [
            { nom: 'xddflgerr', largeur: 100 },
            { nom: 'xddnbrerr', largeur: 100 }
        ],
        colonnesCachees: [
            'xdderrmsg'
        ],
        colonnesVisibles: [
            { nom: 'xddind', largeur: 100 },
            { nom: 'eplnom', largeur: 150 },
            { nom: 'eplprn', largeur: 150 },
            { nom: 'eplini', largeur: 100 },
            { nom: 'eplnas', largeur: 150 },
            { nom: 'epladrcou', largeur: 200 },
            { nom: 'eplnumref1', largeur: 130 },
            { nom: 'eplnumref2', largeur: 130 },
            { nom: 'eplnumref3', largeur: 130 },
            { nom: 'eplnumref4', largeur: 130 },
            { nom: 'xddsex', largeur: 100 },
            { nom: 'xddetaciv', largeur: 150 },
            { nom: 'xdddatnai', largeur: 120 },
            { nom: 'cincod', largeur: 130 },
            { nom: 'tadcod', largeur: 130 },
            { nom: 'eadflgchq', largeur: 100 },
            { nom: 'eadflgfinann', largeur: 100 },
            { nom: 'eadadr1', largeur: 150 },
            { nom: 'eadadr2', largeur: 150 },
            { nom: 'eadadr3', largeur: 150 },
            { nom: 'pyscod', largeur: 130 },
            { nom: 'xddetacod', largeur: 150 },
            { nom: 'munnomfra', largeur: 150 },
            { nom: 'eadcodpos', largeur: 130 },
            { nom: 'ttlcod', largeur: 130 },
            { nom: 'etlnumtel', largeur: 130 },
            { nom: 'etlnumpos', largeur: 100 },
            { nom: 'eplflgimm', largeur: 100 },
            { nom: 'eplnasimm', largeur: 150 },
            { nom: 'pyscodcit', largeur: 130 },
            { nom: 'pyscodnai', largeur: 180 },
            { nom: 'etacodnai', largeur: 180 },
            { nom: 'xdddatobtcit', largeur: 150 },
            { nom: 'epecod', largeur: 130 },
            { nom: 'xdddatper', largeur: 120 },
            { nom: 'xdddatexp', largeur: 120 },
            { nom: 'xdddatexpnas', largeur: 120 },
            { nom: 'epecom', largeur: 300 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('eplnummat'),
            new FormulaireItem('xddind'),
            new FormulaireItem('eplnom'),
            new FormulaireItem('eplprn'),
            new FormulaireItem('eplini'),
            new FormulaireItem('eplnas'),
            new FormulaireItem('epladrcou', { largeur: 40, enableCount: true }),
            new FormulaireItem('eplnumref1'),
            new FormulaireItem('eplnumref2'),
            new FormulaireItem('eplnumref3'),
            new FormulaireItem('eplnumref4'),
            new FormulaireItem('xddsex'),
            new FormulaireItem('xddetaciv'),
            new FormulaireItem('xdddatnai'),
            new FormulaireItem('cincod'),
            new FormulaireItemDivider('LBL_DIV_ADR'),
            new FormulaireItem('tadcod'),
            new FormulaireItem('eadflgchq'),
            new FormulaireItem('eadflgfinann'),
            new FormulaireItem('eadadr1', { largeur: 40 }),
            new FormulaireItem('eadadr2'),
            new FormulaireItem('eadadr3'),
            new FormulaireItem('pyscod'),
            new FormulaireItem('xddetacod'),
            new FormulaireItem('munnomfra'),
            new FormulaireItem('eadcodpos'),
            new FormulaireItemDivider('LBL_DIV_TEL'),
            new FormulaireItem('ttlcod'),
            new FormulaireItem('etlnumtel'),
            new FormulaireItem('etlnumpos'),
            new FormulaireItemDivider('LBL_DIV_IMM'),
            new FormulaireItem('eplflgimm'),
            new FormulaireItem('eplnasimm'),
            new FormulaireItem('pyscodnai'),
            new FormulaireItem('etacodnai'),
            new FormulaireItem('pyscodcit'),
            new FormulaireItem('xdddatobtcit'),
            new FormulaireItem('xdddatper'),
            new FormulaireItem('xdddatexp'),
            new FormulaireItem('xdddatexpnas'),
            new FormulaireItem('epecod'),
            new FormulaireItem('epecom'),
            new FormulaireItemDivider('BLC_QUESTIONNAIRE'),
            new FormulaireItem('qstcleint',{largeur: 50, readonly: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaetypsys1',{largeur: 25}),
            new FormulaireItem('xddpaeon1',{largeur: 25}),
            new FormulaireItem('xddpaepre1',{largeur: 25}),
            new FormulaireItem('xddpaeapr1',{largeur: 25}),
            new FormulaireItem('vaetypsys2',{largeur: 25}),
            new FormulaireItem('xddpaeon2',{largeur: 25}),
            new FormulaireItem('xddpaepre2',{largeur: 25}),
            new FormulaireItem('xddpaeapr2',{largeur: 25}),
            new FormulaireItem('vaetypsys3',{largeur: 25}),
            new FormulaireItem('xddpaeon3',{largeur: 25}),
            new FormulaireItem('xddpaepre3',{largeur: 25}),
            new FormulaireItem('xddpaeapr3',{largeur: 25}),
            new FormulaireItem('vaetypsys4',{largeur: 25}),
            new FormulaireItem('xddpaeon4',{largeur: 25}),
            new FormulaireItem('xddpaepre4',{largeur: 25}),
            new FormulaireItem('xddpaeapr4',{largeur: 25}),
            new FormulaireItem('vaetypsys5',{largeur: 25}),
            new FormulaireItem('xddpaeon5',{largeur: 25}),
            new FormulaireItem('xddpaepre5',{largeur: 25}),
            new FormulaireItem('xddpaeapr5',{largeur: 25}),
            new FormulaireItem('vaetypsys6',{largeur: 25}),
            new FormulaireItem('xddpaeon6',{largeur: 25}),
            new FormulaireItem('xddpaepre6',{largeur: 25}),
            new FormulaireItem('xddpaeapr6',{largeur: 25})
        ], { largeurDefaut: 20 })
    };
}

