import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc0050 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0050Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0050 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('tymcleint'),
            new FormulaireItem('tymcod'),
            new FormulaireItem('tymdsc', { largeur: 50 })
        ], { largeurDefaut: 25 }),
        colonnesVisibles: [
            { nom: 'tymcleint', largeur: 140 },
            { nom: 'tymcod', largeur: 80 },
            { nom: 'tymdsc', largeur: 260 }
        ]
    };
}