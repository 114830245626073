import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';

interface IPageCC0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0022Controller(Operateur: IOperateurService,
    Menu: IMenuClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService) {
    const vm: IPageCC0022 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        //filtres: parametresRecherche(ecranDetails),
        filtres: [
            { colonne: 'crscleint', valeur: $stateParams.crscleint,
            visible: false },
            { colonne: 'trnflgstafer'},
            { colonne: 'trnflgstaann'}
            ],
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 130 },
            { nom: 'eplnomprn', largeur: 262 }
        ],
        colonnesVisibles: [
            { nom: 'cadcod', largeur: 125 },
            { nom: 'crscod', largeur: 150 },
            { nom: 'crsdsc', largeur: 300 },
            { nom: 'trmcod', largeur: 125 },
            { nom: 'trmdsc', largeur: 272 },
            { nom: 'cofgrp', largeur: 115 },
            { nom: 'atrprc', largeur: 140 },
            { nom: 'atrnbrhre', largeur: 150 },
            { nom: 'ecldsceta', largeur: 220 },
            { nom: 'pagcod', largeur: 130 },
            { nom: 'pagdsc', largeur: 300 },
            { nom: 'raacod', largeur: 140 },
            { nom: 'raadsc', largeur: 272 },
            { nom: 'atrnumdoc', largeur: 140 },
            { nom: 'ttccod', largeur: 140 },
            { nom: 'ttcdsc', largeur: 272 },
            { nom: 'flgajs', largeur: 135 },
            { nom: 'atrnumdocori', largeur: 130 },
            { nom: 'tarcod', largeur: 140 },
            { nom: 'tardsc', largeur: 272 },
            { nom: 'atrdatdeb', largeur: 126 },
            { nom: 'atrdatfin', largeur: 126 },
            { nom: 'atrdatimpanc', largeur: 170 },
            { nom: 'unocod', largeur: 140 },
            { nom: 'unodscabr', largeur: 272 },
            { nom: 'urgcod', largeur: 130 },
            { nom: 'urgdscabr', largeur: 272 },
            { nom: 'temcod', largeur: 140 },
            { nom: 'temdscabr', largeur: 272 },
            { nom: 'atrcom', largeur: 272 },
            { nom: 'atrrem', largeur: 272 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'trnflgstaann', largeur: 75 }
        ]
    };
}
