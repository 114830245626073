import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0011 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceSisOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0011Controller(Formulaire: IFormulaireClass,
                                        FormulaireItem: IFormulaireItemClass,
                                        FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMr0011 = this;

    vm.multiOccurrenceSisOptions = {
        bloc: 'sis',
        colonnesVisibles: [
            { nom: 'sisdsc', largeur: 60 },
            { nom: 'sisreqsql', largeur: 60 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('sisdsc', {largeur: 30}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('sisreqsql', {largeur: 100})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'siddsc', largeur: 60},
            {nom: 'ecrcod', largeur: 20},
            {nom: 'ecrdsc', largeur: 60},
            {nom: 'siddscnul', largeur: 60},
            {nom: 'sidinddem', largeur: 20}
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceSisOptions,

        formulaire: new Formulaire([
            new FormulaireItem('siddsc', {largeur: 30}),
            new FormulaireItem('ecrcleint', {largeur: 30}),
            new FormulaireItem('siddscnul', {largeur: 30}),
            new FormulaireItem('sidinddem', {largeur: 30})
        ])
    };
}
