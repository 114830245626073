import { IComponentController } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCb0029 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    depMultiOccurrence: IMultiOccurrence;
    revMultiOccurrence: IMultiOccurrence;
    actMultiOccurrence: IMultiOccurrence;
    pasMultiOccurrence: IMultiOccurrence;
    avoMultiOccurrence: IMultiOccurrence;
    depMultiOccurrenceOptions: IMultiOccurrenceOptions;
    revMultiOccurrenceOptions: IMultiOccurrenceOptions;
    actMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    avoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
    valeursEcran: any;
}

/* @ngInject */
export default function Cb0029Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    cb0030Route: IRoute
) {
    const vm: IPageCb0029 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('prucleint', { default: ($stateParams.id), hidden: true }),
        new FormulaireItem('pecanncri', { disabled: () => (vm.valeursEcran.vaeprutypbdg_dsc === 'P') }),
        new FormulaireItem('cifcleintcri'),
        new FormulaireItem('unacleintcri')
    ])

    // Onglet "Depenses"
    vm.depMultiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0030',
                cb0030Route.NAME,
                (rowData: any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    cifcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.cifcleintcri,
                    unacleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.unacleintcri,
                    pecann: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecanncri,
                    vaesysnatctb: 'DEP',
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0030',
                    icon: 'exit_to_app'
                }
            )
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_BTN_FORAGE_CB0030',
                cb0030Route.NAME,
                (rowData: any) => ({
                    id: rowData.prucleint,
                    prucleint: rowData.prucleint,
                    cifcleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.cifcleintcri,
                    unacleint: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.unacleintcri,
                    pecann: vm.depMultiOccurrence.etatSelected.criteresSuggeresData.pecanncri,
                    vaesysnatctb: 'DEP',
                    tymcleint: rowData.tymcleint,
                    menuId: $stateParams.menuId
                }),
                {
                    fonction: 'BTN_DEP_FORAGE_CB0030',
                    icon: 'exit_to_app'
                }
            )
        ]),
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            effaceCriteres: true
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130 },
            { nom: 'cummntsum', largeur: 120 }
        ],
        colonnesEditionRapide: [
            { nom: 'tyhdsc', largeur: 100 },
            { nom: 'cummntsum', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyhdsc'),
            new FormulaireItem('cummntsum')
        ], { largeurDefaut: 33 }),
        colonnesCachees: ['prucleint']
    }

    // Onglet "Revenus"
    vm.revMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130 },
            { nom: 'cummntsum', largeur: 120 }
        ],
        colonnesEditionRapide: [
            { nom: 'tyhdsc', largeur: 100 },
            { nom: 'cummntsum', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyhdsc'),
            new FormulaireItem('cummntsum')
        ], { largeurDefaut: 33 })
    };

    // Onglet "Actifs"
    vm.actMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130 },
            { nom: 'cummntsum', largeur: 120 }
        ],
        colonnesEditionRapide: [
            { nom: 'tyhdsc', largeur: 100 },
            { nom: 'cummntsum', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyhdsc'),
            new FormulaireItem('cummntsum')
        ], { largeurDefaut: 33 })
    };

    // Onglet "Passifs"
    vm.pasMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130 },
            { nom: 'cummntsum', largeur: 120 }
        ],
        colonnesEditionRapide: [
            { nom: 'tyhdsc', largeur: 100 },
            { nom: 'cummntsum', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyhdsc'),
            new FormulaireItem('cummntsum')
        ], { largeurDefaut: 33 })
    };

    // Onglet "Avoirs"
    vm.avoMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nom: 'tyhdsc', largeur: 130 },
            { nom: 'cummntsum', largeur: 120 }
        ],
        colonnesEditionRapide: [
            { nom: 'tyhdsc', largeur: 100 },
            { nom: 'cummntsum', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyhdsc'),
            new FormulaireItem('cummntsum')
        ], { largeurDefaut: 33 })
    };
}
