import { IComponentController, IFilterDate } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForage, IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageAs0017 extends IComponentController {
    menus: { [key: string]: Array<IMenuItem> },
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dtjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    atfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ttjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bqemultiOccurrenceOptions: IMultiOccurrenceOptions;
    objmultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceOptionsBdc: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
    cpeMenus: Array<IMenuItemForage>;
}

/* @ngInject */
export default function As0017Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    dateFilter: IFilterDate,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    Menu: IMenuClass,
    as0046Route: IRoute,
    as0041Route: IRoute,
    Pagination: IPaginationClass,
    $stateParams: IStateParamsService,
    as0038Route: IRoute,
    rr0006Route: IRoute,
    rr0074Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAs0017 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            avisEmploye: false,
            refreshForageIdWithCycle: true
        },
        cycleErrorReloadBlocs: ['DTJ'],
        infosMonetaires: {
            blocs: ['dtj', 'atf'],
            largeurTexte: 150,
            afficherAuBas: "oui",
            noIcons: true,
            donnees:
                [
                    { nom: 'tothreet', libelle: 'tothreet' },
                    { nom: 'tothreabs', libelle: 'tothreabs' },
                    { nom: 'tothreadd', libelle: 'tothreadd' }
                ]
        },
        actionsMore: new Menu([
            new MenuItemForage(
                'LBL_BTN_ASS',
                as0046Route.NAME,
                (data: any) => ({
                    demcleint: data.demcleint,
                    datdeb: vm.monoOccurrence.ecranDetails.valeurs.datdeb,
                    datfin: vm.monoOccurrence.ecranDetails.valeurs.datfin,
                    criteremultipledeftay: '1',
                    criteremultiplesrccod: "tay",
                    criteremultiplecol: "taycleint"                    
                }),
                {
                    icon: 'launch_app',
                    fonction: 'BOUIMP',
                    iconButton: false,
                    secondaire: false
                }
            ),
            new MenuItemForage(
                'LBL_BTN_STM',
                as0041Route.NAME,
                (data: any) => ({
                    demcleint: data.demcleint,
                    datdeb: data.ptadatdeb
                }),
                {
                    icon: 'launch_app',
                    fonction: 'BOUIMP',
                    iconButton: false,
                    secondaire: false
                }
            )
        ])
    }

    vm.formulaireMsg = new Formulaire('zone_Message', [
        new FormulaireItem('message', {
            largeur: 100,
            enableCount: true,
            readonly: true
        })
    ]);
    // Diagnostics médicaux
    vm.cpeMenus = [
        new MenuItemAction("LBL_BTN_HOR", "BOUGEN", () => ({}),
            {
                iconButton: false,
                icon: 'edit_note',
                secondaire: true,
                successAction: () => { vm.monoOccurrence.fetchData() }
            }),
        new MenuItemEcran(
            'LBL_BTN_INC',
            as0038Route.NAME,
            (data: any) => ({
                atfcleint: data.atfcleint,
                stamnetab: 'ATF'
            }),
            {
                icon: 'warning',
                fonction: 'BOUIMP',
                iconButton: false,
                secondaire: true,
                compteur: (data: any) => (data.inc_nbr > 0) ? data.inc_nbr : undefined,
            }
        ),
        //Générer temps
        new MenuItemAction("LBL_BTN_TMP", "BOUTMP", () => ({}), {
            iconButton: false,
            icon: "edit_note",
            secondaire: true,
            disabled: (data: any) => data.$ancetre.ecldsceta !== "Initial",
            formulaireConfirmationAction: {
                lblTitre: "LBL_BTN_TMP",
                lblConfirm: "G_LBL_LBL_BTN_TMP",
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem("t_occleint", {
                        largeur: 100,
                        required: true,
                        default: ecranDetails.valeurs.occleint,
                        resourceParams: (data: any) => ({
                            asgcleint: data.$ancetre.asgcleint,
                            vaeatftyp: data.$ancetre.vaeatftyp,
                        }),
                    }),
                    new FormulaireItem("t_aftcleint_tmp", {
                        largeur: 100,
                        required: true,
                        resourceParams: (data: any) => ({
                            demcleint: data.$ancetre.demcleint,
                            ptadatdeb: data.$ancetre.ptadatdeb,
                            ptadatfin: data.$ancetre.ptadatfin,
                            usrcleint: data.usrcleint,
                        }),
                    }),
                    new FormulaireItem("t_actcleint", {
                        largeur: 100,
                        required: true,
                        resourceParams: (data: any) => ({
                            demcleint: data.$ancetre.demcleint,
                            ptadatdeb: data.$ancetre.ptadatdeb,
                            ptadatfin: data.$ancetre.ptadatfin,
                            asgcleint: data.$ancetre.asgcleint,
                            vaeatftyp: data.$ancetre.vaeatftyp
                        }),
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem("type_gen_tmp", {
                        largeur: 100,
                        default: "SPE",
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem("t_datedeb", {
                        largeur: 33
                        
                    }),
                    new FormulaireItem("t_datfin", {
                        largeur: 33,
                    }),
                    new FormulaireItem("t_dtjqte", {
                        largeur: 33,
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem("dtjhredeb_jr1", {
                        largeur: 33,
                    }),
                    new FormulaireItem("dtjhrefin_jr1", {
                        largeur: 33,
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem("dtjhredeb_jr2", {
                        largeur: 33,
                    }),
                    new FormulaireItem("dtjhrefin_jr2", {
                        largeur: 33,
                    })
                ]),
            },
            successAction: () => {
                vm.monoOccurrence.fetchData();
            },
        })
    ]


    vm.formulaireAtf = new Formulaire('LBL_ATF', [
        new FormulaireItem('demcleint', {
            largeur: 33,
            default: $stateParams.demcleint,
            required: true,
            readonly: !!$stateParams.demcleint,
            modifAllow: false
        }),
        new FormulaireItem('vaeatftyp', {
            largeur: 33,
            default: $stateParams.vaeatftyp,
            readonly: true,
            required: false
        }),
        new FormulaireItem('ttccleint', {
            largeur: 33,
            required: true,
            resourceParams: (data: any) => ({ vaeatftyp: data.vaeatftyp }),
            modifAllow: false
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('asgcleint', {
            largeur: 33,
            required: true,
            modifAllow: false,
            resourceParams: (data: any) => ({ demcleint: data.demcleint, ptadatdeb: data.ptadatdeb, ptadatfin: data.ptadatfin })
        }),
        new FormulaireItem('ptacleint', {
            largeur: 33,
            required: true,
            modifAllow: false,
            resourceParams: (data: any) => ({ unpcleint: data.unpcleint })
        }),
        new FormulaireItem('ppacod', {
            largeur: 33,
            readonly: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('atfnumdoc', {
            largeur: 33,
            required: false
        }),
        new FormulaireItem('vaeatfori', {
            largeur: 33,
            readonly: true,
            required: false
        }),
        new FormulaireItem('atfnumdocref', {
            largeur: 33,
            readonly: true
        }),
        new FormulaireItem('unpcleint', {
            largeur: 33,
            hidden: true
        }),
        new FormulaireItem('unorhcod', {
            largeur: 33,
            readonly: true
        }),
        new FormulaireItem('posnumdoc', {
            largeur: 33,
            readonly: true
        }),
        new FormulaireItem('nomsupervi', {
            largeur: 33,
            readonly: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('atfcom', {
            largeur: 100,
            enableCount: true
        })
    ]);

    vm.dtjMultiOccurrenceOptions = {
        bloc: 'dtj',
        pagination: {
            nombreElementPage: 50
        },
        fonctions: {
            editionRapide: true,
            avisEmploye: false
        },
        colonnesFixesGauche: [
            { nom: 'flgerr', largeur: 40, changeColor: true },
            { nom: 'icon', largeur: 20, changeColor: true },
            { nom: 'dtjdatjr', largeur: 120, changeColor: true },
            { nom: 'actcod', largeur: 160, changeColor: true },
            { nom: 'qtehor', largeur: 70, changeColor: true },
            { nom: 'dtjqte', largeur: 95, changeColor: true },
            { nom: 'flghre', largeur: 95, changeColor: true },
            { nom: 'comm', largeur: 100, changeColor: true },
        ],
        colonnesVisibles: [
            { nom: 'occupation', largeur: 110, changeColor: true },
            { nom: 'aftcod', largeur: 170, changeColor: true },
            { nom: 'dernumdoc', largeur: 130, changeColor: true },
            { nom: 'drpnumdoc', largeur: 130, changeColor: true },
            { nom: 'imp', largeur: 220, changeColor: true },
            { nom: 'posnumdoc', largeur: 95, changeColor: true },
            { nom: 'unocod', largeur: 95, changeColor: true },
            { nom: 'lemcod', largeur: 105, changeColor: true },
            { nom: 'temcod', largeur: 110, changeColor: true }
        ],
        colonnesEditionRapide: [
            { nom: 'jour', largeur: 10 },
            { nom: 'dtjdat', largeur: 130 },
            { nom: 'occcleint', largeur: 120, resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint, vaeatftyp: data.$ancetre.vaeatftyp }) },
            { nom: 'aftcleint', largeur: 210, resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, dtjdat: data.dtjdat, asgcleint: data.$ancetre.asgcleint, occcleint: data.occcleint }) },
            { nom: 'dercleint', largeur: 190, resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, dtjdat: data.dtjdat, asgcleint: data.$ancetre.asgcleint }) },
            { nom: 'drpcleint', largeur: 160, resourceParams: (data: any) => ({ dercleint: data.dercleint, dtjdat: data.dtjdat }) },
            { nom: 'actcleint', largeur: 210, resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, dtjdat: data.dtjdat, ptadatfin: data.$ancetre.ptadatfin, asgcleint: data.$ancetre.asgcleint, vaeatftyp: data.$ancetre.vaeatftyp }) },
            { nom: 'qtehor', largeur: 10 },
            { nom: 'dtjqte', largeur: 10 },
            { nom: 'dtjcom', largeur: 95 }
        ],
        colonnesCachees: ['dercleint', 'urgcleint', 'ecldsceta', 'flghre', 'color', 'vaeoccaft', 'vaeoccder'],
        formulaire: new Formulaire([
            new FormulaireItem('occcleint', {
                resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint, vaeatftyp: data.$ancetre.vaeatftyp }),
                largeur: 25
            }),
            new FormulaireItem('dtjdat', { largeur: 15 }),
            new FormulaireItem('jour', {
                readonly: true,
                disabled: true, largeur: 15
            }),
            new FormulaireItem('dthhredeb1', { largeur: 20, disabled: (rowData: any) => (rowData.$ancetre.ecldsceta === 'Initial' || rowData.flghre === 0) }),
            new FormulaireItem('dthhrefin1', { largeur: 20, disabled: (rowData: any) => (rowData.$ancetre.ecldsceta === 'Initial' || rowData.flghre === 0) }),
            new FormulaireItem('aftcleint', {
                resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, dtjdat: data.dtjdat, asgcleint: data.$ancetre.asgcleint, occcleint: data.occcleint }),
                hidden: (data: any) => (data.vaeoccaft !== 'OBL'),
                disabled: (data: any) => (data.vaeoccaft !== 'OBL'),
                largeur: 25,
                required: (data: any) => (data.vaeoccaft === 'OBL')
            }),
            new FormulaireItem('dercleint', {
                resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, dtjdat: data.dtjdat, asgcleint: data.$ancetre.asgcleint }),
                largeur: 25,
                hidden: (data: any) => (data.vaeoccder !== 'OBL'),
                disabled: (data: any) => (data.vaeoccder !== 'OBL'),
                required: (data: any) => (data.vaeoccder === 'OBL')
            }),
            new FormulaireItem('actcleint', {
                resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, dtjdat: data.dtjdat, ptadatfin: data.$ancetre.ptadatfin, asgcleint: data.$ancetre.asgcleint, vaeatftyp: data.$ancetre.vaeatftyp }),
                largeur: 30
            }),
            new FormulaireItem('dthhredeb2', { largeur: 20, disabled: (rowData: any) => (rowData.$ancetre.ecldsceta === 'Initial' || rowData.flghre === 0) }),
            new FormulaireItem('dthhrefin2', { largeur: 20, disabled: (rowData: any) => (rowData.$ancetre.ecldsceta === 'Initial' || rowData.flghre === 0) }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('drpcleint', {
                largeur: 25,
                resourceParams: (data: any) => ({ dercleint: data.dercleint, dtjdat: data.dtjdat }),
                disabled: (data: any) => (data.vaeoccder !== 'OBL')
            }),
            new FormulaireItem('qtehor', {
                disabled: true, largeur: 15
            }),
            new FormulaireItem('dtjqte', { largeur: 15 }),
            new FormulaireItem('dthhredeb3', { largeur: 20, disabled: (rowData: any) => (rowData.$ancetre.ecldsceta === 'Initial' || rowData.flghre === 0) }),
            new FormulaireItem('dthhrefin3', { largeur: 20, disabled: (rowData: any) => (rowData.$ancetre.ecldsceta === 'Initial' || rowData.flghre === 0) }),
            new FormulaireItem('imp', { largeur: 25 }),
            new FormulaireItem('dtjcom', {
                largeur: 100
            })
        ])
    }


    vm.ttjMultiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'ttjdat', largeur: 130 },
        ],
        colonnesVisibles: [
            { nom: 'aftcod', largeur: 190 },
            { nom: 'dernumdoc', largeur: 130 },
            { nom: 'drpnumdoc', largeur: 130 },
            { nom: 'lemcod', largeur: 130 },
            { nom: 'temcod', largeur: 130 },
            { nom: 'posnumdoc', largeur: 130 },
            { nom: 'unocod', largeur: 130 },
            { nom: 'imp', largeur: 130 }
        ],
        colonnesFixesDroite: [
            { nom: 'tagcod', largeur: 95 },
            { nom: 'flgcom', largeur: 95 },
            { nom: 'ttjqte', largeur: 95 },
            { nom: 'ttjmnt', largeur: 95 },
            { nom: 'flgerr', largeur: 95 },
            { nom: 'fmamsg', largeur: 200 }
        ],
        colonnesCachees: ['urgcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('ttjdat', {
                required: true,
                largeur: 33
            }),
            new FormulaireItem('aftcleint', {
                resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, ttjdat: data.ttjdat, asgcleint: data.$ancetre.asgcleint }),
                required: false,
                largeur: 33
            }),
            new FormulaireItem('dercleint', {
                resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, ttjdat: data.ttjdat, asgcleint: data.$ancetre.asgcleint }),
                required: false,
                largeur: 33
            }),
            new FormulaireItem('drpcleint', {
                resourceParams: (data: any) => ({ dercleint: data.dercleint, ttjdat: data.ttjdat }),
                required: false,
                largeur: 33
            }),
            new FormulaireItem('lemcleint', {
                resourceParams: (data: any) => ({ demcleint: data.$ancetre.demcleint, ttjdat: data.ttjdat, ptadatfin: data.$ancetre.ptadatfin, ptadatdeb: data.$ancetre.ptadatdeb }),
                required: false,
                largeur: 33
            }),
            new FormulaireItem('temcleint', {
                resourceParams: (data: any) => ({ urgcleint: data.urgcleint, lemcleint: data.lemcleint, asgcleint: data.$ancetre.asgcleint }),
                required: false,
                largeur: 33
            }),
            new FormulaireItem('poscleint', {
                resourceParams: (data: any) => ({ ttjdat: data.ttjdat, demcleint: data.$ancetre.demcleint, aftcleint: data.aftcleint }),
                required: false,
                largeur: 33
            }),
            new FormulaireItem('unocleintrh', {
                resourceParams: (data: any) => ({ ptadatfin: data.$ancetre.ptadatfin, ptadatdeb: data.$ancetre.ptadatdeb }),
                required: false,
                largeur: 33
            }),
            new FormulaireItem('imp', {
                largeur: 33
            }),
            new FormulaireItem('tagcleint', {
                resourceParams: (data: any) => ({ asgcleint: data.$ancetre.asgcleint }),
                required: true,
                largeur: 33
            }),
            new FormulaireItem('ttjcom', {
                largeur: 33
            }),
            new FormulaireItem('ttjqte', {
                largeur: 33
            }),
            new FormulaireItem('ttjmnt', {
                largeur: 33
            }),
            new FormulaireItem('flgerr', {
                largeur: 33,
                disabled: true
            }),
            new FormulaireItem('fmamsg', {
                disabled: true,
                required: false
            })
        ])
    };
    vm.multiOccurrenceOptionsBdc = {
        bloc: 'bdc',
        colonnesVisibles: [
            { nom: 'dbcdat', largeur: 20 },
            { nom: 'dbcqte', largeur: 20 },
            { nom: 'vaesysprvbqe', largeur: 20 },
            { nom: 'vaesystrsbqe_dsc', largeur: 20 },
            { nom: 'dbscod1', largeur: 20 },
            { nom: 'dbsdsc1', largeur: 20 },
            { nom: 'dbscod2', largeur: 20 },
            { nom: 'dbsdsc2', largeur: 20 },

        ],
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 20, 30]
        })
    };
    vm.bqemultiOccurrenceOptions = {
        bloc: 'bqe',
        colonnesFixesGauche: [
            { nom: 'bqecod', largeur: 130 }
        ],
        colonnesVisibles: [
            { nom: 'bqedscabr', largeur: 130 },
            { nom: 'slddebpxt', largeur: 130 },
            { nom: 'sumbqaqte', largeur: 130 },
            { nom: 'sumbqaqteglo', largeur: 130 },
            { nom: 'dbsqte', largeur: 130 },
            { nom: 'totqte', largeur: 130 },
            { nom: 'unccod', largeur: 130 }
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceOptionsBdc
    };

    vm.objmultiOccurrenceOptions = {
        navigatePage: (Data) => getForageItem(Data),
        navigateParams: (rowData: any) => ({
            id: rowData.trncleintref
        }),
        fonctions: {
            edition: false
        },
        colonnesFixesGauche: [
            { nom: 'mnemonique', largeur: 130 },
            { nom: 'code', largeur: 130 },
        ],
        colonnesVisibles: [
            { nom: 'datdeb', largeur: 130 },
            { nom: 'datfin', largeur: 130 },
            { nom: 'temcod', largeur: 130 },
            { nom: 'temdsc', largeur: 130 },
            { nom: 'typcod', largeur: 130 },
            { nom: 'typdsc', largeur: 130 },
            { nom: 'urgcod', largeur: 130 },
            { nom: 'urgdsc', largeur: 130 },
            { nom: 'strcod', largeur: 130 },
            { nom: 'strdsc', largeur: 130 },
            { nom: 'unocod', largeur: 130 },
            { nom: 'unodsc', largeur: 130 },
            { nom: 'unodsc', largeur: 130 },
        ],
        colonnesFixesDroite: [
            { nom: 'mntdisp', largeur: 130 },
            { nom: 'qtedisp', largeur: 130 },
        ],
        colonnesCachees: ['trncleintref']
    }
    function getForageItem(rowData: any): string {
        if (rowData.mnemonique === 'AFT') {
            return rr0006Route.NAME
        }
        if (rowData.mnemonique === 'DER') {
            return rr0074Route.NAME
        }
    }

}
