import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageCH0011 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ityMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ch0011Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCH0011 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Cycles
        vm.ityMultiOccurrenceOptions = {
            bloc: 'ity',
            fonctions: {
                pagination: true,
                editionRapide: true
            },
            editionRapideActive: true,
            colonnesVisibles: [
                {nom: 'semaine', largeur: 300},
                {nom: 'ityflgpubhor', largeur: 91}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('itydatdeb', {
                    largeur: 25
                }),
                new FormulaireItem('itydatfin', {
                    largeur: 25
                }),
                new FormulaireItem('ityflgpubhor', {
                    default: 0,
                    largeur: 25
                })
            ]),
            colonnesEditionRapide: [
                {nom: 'itydatdeb', largeur: 131},
                {nom: 'itydatfin', largeur: 131},
                {nom: 'ityflgpubhor', largeur: 91}
            ],
            colonnesCachees: [
                'itycleint'
            ]
        };
    }
}
