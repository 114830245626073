import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0149 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0149Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0149 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tdadsc'   , largeur: 230},
            {nom: 'vaetdatyp_dsc', largeur: 180}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tdadsc'),
            new FormulaireItem('vaetdatyp')
        ], {largeurDefaut: 50})
    };
}
