import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageDo0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0014Controller() {
    const vm: IPageDo0014 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'urgcod', largeur: 150 },
            { nom: 'urgdscabr', largeur: 150 },
            { nom: 'rglcod', largeur: 150 },
            { nom: 'vaergltyp', largeur: 150 },
            { nom: 'rgldscabr', largeur: 150 },
            { nom: 'rglflginsdsp', largeur: 150 }
        ]
    };
}
