import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageAC0089 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceBIPOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0089Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAC0089 = this;

    vm.multiOccurrenceBIPOptions = {
        bloc: 'bip',
        colonnesVisibles: [
            {nom: 'bipprc', largeur: 110},
            {nom: 'bipdatvig', largeur: 548}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('bipprc'),
            new FormulaireItem('bipdatvig')
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'refcod', largeur: 135},
            {nom: 'refdsc', largeur: 310},
            {nom: 'melcod', largeur: 150},
            {nom: 'meldsc', largeur: 245}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint'),
            new FormulaireItem('melcleint')
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceBIPOptions
    };
}
