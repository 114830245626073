import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IOperateurService} from "../../../../../core/services/operateur.service";
import { IMenuItemActionImportationClass } from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0077 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Rr0077Controller(rr0163Route: IRoute,
                                         Menu: IMenuClass,
                                         Operateur: IOperateurService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemActionImportation: IMenuItemActionImportationClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass ) {
    const vm: IPageRr0077 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemActionImportation(
                'LBL_CHARGEMENT_TXT',
                'BOUCHA',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_TXT',
                        largeurFormulaire: 35,
                        formulaire: new Formulaire([
                            new FormulaireItem('ffpnumchacri', {
                                largeur: 100,
                                default: vm.valeursEcran.ffpnumchanew,
                                maxlength: 20
                            }),
                            new FormulaireItem('unpcleintcri', {
                                largeur: 100                               
                            }),
                            new FormulaireItem('ppacleintcri', {
                                largeur: 100                                
                            })
                        ])
                    },
                    successAction: () => {                      
                        vm.multiOccurrence.fetchDataList();
                    },
                    typesFichiers: ['.csv'],
                    iconButton: true,
                    class: 'md-raised ex-button-action'
                }
            )
        ]),
        navigatePage: rr0163Route.NAME,
        filtres: [
            {
                colonne: 'ffpflgfin',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            }
        ],
        colonnesVisibles: [
            {nom: 'ffpnumcha'   , largeur: 150},
            {nom: 'ffpficsou'      , largeur: 170},
            {nom: 'datcre'      , largeur: 120},
            {nom: 'usrnomcre', largeur: 200},
            {nom: 'ffpnbrlig', largeur: 120},
            {nom: 'ffpnbrerr', largeur: 120},
            {nom: 'ffpnbraftman', largeur: 120},
            {nom: 'ffpnbraftpre', largeur: 120},
            {nom: 'ffpflgval'   , largeur: 100},
            {nom: 'ffpflgfin'   , largeur: 100}
        ]
    };
}
