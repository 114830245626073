import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IStateParamsService} from 'angular-ui-router';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';

interface IPagePF0028 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0028Controller(pf0010Route: IRoute,
                                         $stateParams: IStateParamsService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         parametresRecherche: IParametresRechercheService,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {

    const vm: IPagePF0028 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pf0010Route.NAME,
        navigateParams: (rowData: any) => {
           return {
                demcleint: $stateParams.employe
            };
        },
        fonctions: {
            rechercheCollapsable: false
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(pf0010Route.NAME, () => {
                    return {
                        demcleint: $stateParams.employe
                    }
                },
                { disabled: !ecranDetails.valeurs.flgsofe2 }
            )
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'acecod'        , largeur: 120},
            {nom: 'acedsc'        , largeur: 260},
            {nom: 'dpmacedsc'     , largeur: 260},
            {nom: 'eplnummat'     , largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn'     , largeur: 260, hidden: $stateParams.employe},
            {nom: 'gpenumdoc_sou' , largeur: 100},
            {nom: 'diccomdem'     , largeur: 260},
            {nom: 'gpenumdoc_reel', largeur: 100},
            {nom: 'gpedatdeb'     , largeur: 100},
            {nom: 'gpehredeb'     , largeur: 80},
            {nom: 'gpedatfin'     , largeur: 100},
            {nom: 'gpehrefin'     , largeur: 80},
            {nom: 'gemflgpre'     , largeur: 106},
            {nom: 'gemflgreu'     , largeur: 90},
            {nom: 'dicnumdoc'     , largeur: 100},
            {nom: 'ecldsceta'     , largeur: 200},
            {nom: 'piscod'        , largeur: 100},
            {nom: 'pisdsc'        , largeur: 260},
            {nom: 'dpmpisdsc'     , largeur: 260},
            {nom: 'diccomcen'     , largeur: 260},
            {nom: 'unocod'        , largeur: 100},
            {nom: 'unodscabr'     , largeur: 260},
            {nom: 'posnumdoc'     , largeur: 100},
            {nom: 'postitabr'     , largeur: 260},
            {nom: 'temcod'        , largeur: 100},
            {nom: 'temdscabr'     , largeur: 260},
            {nom: 'urgcod'        , largeur: 100},
            {nom: 'urgdscabr'     , largeur: 260},
            {nom: 'strcod'        , largeur: 100},
            {nom: 'strdsc'        , largeur: 260},
            {nom: 'tpccod'        , largeur: 100},
            {nom: 'tpcdsc'        , largeur: 260},
            {nom: 'ttccod'        , largeur: 110},
            {nom: 'ttcdsc'        , largeur: 260},
            {nom: 'dicdat'        , largeur: 100},
            {nom: 'trnflgstaref'  , largeur: 100},
            {nom: 'trnflgstafin'  , largeur: 90},
            {nom: 'trnflgstafer'  , largeur: 90},
            {nom: 'trnflgstaann'  , largeur: 90}

        ]
    };

}
