import IStateProvider = angular.ui.IStateProvider;
import ErreurChargementEcran from './erreur-chargement-ecran.controller';
import { module } from 'angular';

const route = {
    NAME: 'secure.erreur-chargement-ecran',
};

export default module('app.secure.erreur-chargement-ecran', []).config(config).constant('erreurChargementEcranRoute', route);

/* @ngInject */
function config($stateProvider: IStateProvider) {
    $stateProvider.state(route.NAME, {
        params: {
            route
        },
        views: {
            content: {
                template: require('./erreur-chargement-ecran.html'),
                controllerAs: 'vm',
                controller: ErreurChargementEcran
            }
        }
    });
}
