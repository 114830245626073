import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageDb0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Db0053Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass
    ) {

    const vm: IPageDb0053 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: true,
            effaceCriteres: false,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            criteresSuggeresVisibles: true
        },

        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },

        criteresSuggeres: new Formulaire([
            new FormulaireItem('prucleintcri', { lovSelectionMultiple: true, largeur: 30}),
            new FormulaireItem('intcleintcri', { lovSelectionMultiple: true, largeur: 30}),
            new FormulaireItem('usrcleintref', { lovSelectionMultiple: true, largeur: 30}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('typrech', { default: 1, largeur: 30}),
            new FormulaireItem('excubrina', { default: 1 })
        ]),

        colonnesFixesGauche: [
            { nom: 'prucod', largeur: 100 },
        ],
        colonnesVisibles: [
            { nom: 'prudscl01', largeur: 200},
            { nom: 'intcod', largeur: 100},
            { nom: 'intnom', largeur: 200},
            { nom: 'usrcod', largeur: 100},
            { nom: 'usrnom', largeur: 300},

            { nom: 'indacccon', largeur: 100},
            { nom: 'usrattcon', largeur: 200},
            { nom: 'datattcon', largeur: 100},
            { nom: 'indaccimp', largeur: 100},
            { nom: 'usrattimp', largeur: 200},
            { nom: 'datattimp', largeur: 100},
            { nom: 'intcod_resp', largeur: 100},
            { nom: 'intnom_resp', largeur: 200}
        ],
    };
}
