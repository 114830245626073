import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageMr2003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

export default function Mr2003Controller() {
    const vm: IPageMr2003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },
        colonnesVisibles: [
            {nom: 'dtsmsg', largeur: 400}
        ]
    };
}
