import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageGS0094 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    treMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Gs0094Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                       notificationHandler: INotificationHandler,
                                       gs0093Route: IRoute ,
                                       MenuItemForage: IMenuItemForageClass,
                                       Menu: IMenuClass
) {
    const vm: IPageGS0094 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    if (vm.valeursEcran.message) {
        notificationHandler.erreur({
            lblMessage: vm.valeursEcran.message
        });
    }

    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable:false
        },
        navigatePage: gs0093Route.NAME,
        navigateParams: (rowData: any) => ({
            id : rowData.lobcleint
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage( gs0093Route.NAME, () => ({
            }), {
                fonction: 'BOUNOUJR'
            })
        ]) , 
        colonnesVisibles: [
            { nom: 'lobcod', largeur: 100 },
            { nom: 'lobdsc', largeur: 200 },
            { nom: 'lobdoc', largeur: 80 },
            { nom: 'nbrlod', largeur: 80 }
        ],
    };

}
