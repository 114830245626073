import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";

interface IPageMc0170 extends IComponentController  {
    formulaireRei: IFormulaire;
    formulaireRex: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    rexMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mc0170Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc0170 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
        vm.formulaireRei = new Formulaire('LBL_BLC_REI', [
            new FormulaireItem('reicod'  , { largeur: 20 }),
            new FormulaireItem('reidsc'  , { largeur: 30}),
            new FormulaireItem('reidatdeb'  , { largeur: 25 }),
            new FormulaireItem('reidatfin'  , { largeur: 25 }),

        ]);
        vm.rexMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'reximpcoddeb', largeur: 400},
                {nom: 'reximpcodfin', largeur: 400},
                {nom: 'rexdatdeb', largeur: 225},
                {nom: 'rexdatfin', largeur: 225}
            ],
            formulaire: new Formulaire([
                new FormulaireGroupe('LBL_DEBUT', new Formulaire([
                    new FormulaireItem('rexprucoddeb'),
                    new FormulaireItem('rexprscoddeb'),
                    new FormulaireItem('rexcifcoddeb'),
                    new FormulaireItem('rexunacoddeb'),
                    new FormulaireItem('rexcptcoddeb'),
                    new FormulaireItem('rexel1coddeb'),
                    new FormulaireItem('rexel2coddeb'),
                    new FormulaireItem('rexel3coddeb'),
                ]), {largeur: 33}),
                new FormulaireGroupe('LBL_FIN', new Formulaire([
                    new FormulaireItem('rexprucodfin'),
                    new FormulaireItem('rexprscodfin'),
                    new FormulaireItem('rexcifcodfin'),
                    new FormulaireItem('rexunacodfin'),
                    new FormulaireItem('rexcptcodfin'),
                    new FormulaireItem('rexel1codfin'),
                    new FormulaireItem('rexel2codfin'),
                    new FormulaireItem('rexel3codfin')
                ]), {largeur: 33}),
                new FormulaireItem('rexdatdeb'),
                new FormulaireItem('rexdatfin')
            ])
        };
    }
}
