import { IComponentController, IFilterDate } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { INotificationHandler} from '../../../../../core/services/utils/notification-handler.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire,  IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMonoOccurrenceOptions, IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';

interface IPageDb0005 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrenceImpOptions: IMonoOccurrenceOptions;
    monoOccurrenceImp: IMonoOccurrence;
    ebvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireImp: IFormulaire;
}

/* @ngInject */
export default function Db0005Controller( Formulaire: IFormulaireClass,
                                          FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                          FormulaireItem: IFormulaireItemClass,
                                          dateFilter: IFilterDate,
                                          ecranDetails: IEcranDetailsResourcesEntite,
                                          MenuItemEcran: IMenuItemEcranClass,
                                          MenuItemAction: IMenuItemActionClass,
                                          FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
                                          db0032Route: IRoute,
                                          mc0044Route: IRoute,
                                          mc1012Route: IRoute,
                                          notificationHandler: INotificationHandler)  {
    const vm: IPageDb0005 = this;


    vm.monoOccurrenceOptions = {       
        cycleErrorReloadBlocs: ['ebv'],        
        infosMonetaires: {
            blocs: ['ebv'],
            donnees:
                [
                    { nom: 'dbt', libelle: 'G_LBL_DBT' },
                    { nom: 'crt', libelle: 'G_LBL_CRT', lastOfGroup: true },
                    { nom: 'ecart', libelle: 'G_LBL_ECART', expectedValue: 0 }
                ]
        },          
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true            
        },
        menus: [            
            new MenuItemAction(
                'LBL_IMPRESSION',
                'BOUIMP',
                () => ({
                    ecbcleint: vm.monoOccurrence.data.ecbcleint,
                    ecbnumdoc: vm.monoOccurrence.data.ecbnumdoc,
                    ecbnumdocseq: vm.monoOccurrence.data.ecbnumdocseq,
                    peccleint: vm.monoOccurrence.data.peccleint
                }),
                {
                    iconButton: false,
                    icon: 'print',
                    fonction: 'BOUIMP',
                    secondaire: true,
                    formulaireConfirmationAction: {                        
                        lblTitre: 'LBL_IMPRESSION',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,                                                  
                        formulaire: new Formulaire([                            
                            new FormulaireItem('rapport', { required: true })
                        ], {
                            largeurDefaut: 100, securityCycle: false
                        }),
                        
                    },
                    successAction: (data: any) => {
                        return  notificationHandler.info({
                            lblMessage: data.message
                        })
                    }
                }
            )
        ],        
        formulaire: new Formulaire('LBL_BLC_ECB', [
            new FormulaireItem('ecbnumdoc'),
            new FormulaireItem('ecbnumdocseq', { readonly: true }),
            new FormulaireItem('ecbflgautrep', { default: 1 }),
            new FormulaireItem('tydcleint', { modifAllow: false }),
            new FormulaireItem('vececbcat'),
            new FormulaireItem('ecbflgpij'),
            new FormulaireItem('ecbdat', { default: dateFilter(new Date(), 'yyyy-MM-dd') }),
            new FormulaireItem('peccleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintreq', { longueurFixe: 6 }),
            new FormulaireItem('ecbref'),
            new FormulaireItem('ecbdsc', { largeur: 100, required: ecranDetails.valeurs.flgdscobl }),
            new FormulaireItem('ecbnumrescd', { readonly: true }),
            new FormulaireItem('ecbnumresca', {
                readonly: true, menu: [
                    new MenuItemEcran('LBL_HISTO_RESOL',
                        mc1012Route.NAME,
                        (data: any) => ({ doccleintref: data.doccleint }), {
                        icon: 'launch',
                        iconButton: false
                    })
                ]
            })
        ], 
        {
            largeurDefaut: 33.33
        })
    };

    vm.ebvMultiOccurrenceOptions = {
        bloc: 'ebv',
        editionRapideActive: true,
        fonctions: {
            editionRapide: true,
            editionStandard: true,
            agrandirMultiOccurrence: true
        },
        colonnesFixesGauche: [            
            { nom: 'ebvimp', largeur: 280, menu: [
                new MenuItemEcran('', mc0044Route.NAME, 
                    (data: any) => ({ id:        data.prucleint, 
                                      prucleint: data.prucleint }),
                    {
                    icon: 'launch',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'BOUPOS',
                    onClose: () => {
                        vm.ebvMultiOccurrence.fetchDataList();
                    }
                })
            ] }
        ],
        colonnesVisibles: [
            { nom: 'ebvmntdbt', largeur: 126 },
            { nom: 'ebvmntcrt', largeur: 126 },
            { nom: 'ebvqte', largeur: 126 },
            { nom: 'ebvdsc', largeur: 386 }
        ],
        colonnesFixesDroite: [
            { nom: 'ebvflgpos', largeur: 100, menu: [
                new MenuItemEcran('', db0032Route.NAME, (data: any) => ({ id: data.ebvcleint, ebvcleint: data.ebvcleint }), {
                    icon: 'launch',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'BOUPOS',
                    onClose: () => {
                        vm.ebvMultiOccurrence.fetchDataList();
                    }
                })
            ] },
            { nom: 'ebvflgmsg', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ebvimp', { largeur: 50, isValidatingAtLoad: (data) => data.ebvflgmsg, required: true }),
            new FormulaireItem('ebvmntdbt', { largeur: 25, isValidatingAtLoad: (data) => data.ebvflgmsg }),
            new FormulaireItem('ebvmntcrt', { largeur: 25, isValidatingAtLoad: (data) => data.ebvflgmsg }),
            new FormulaireItem('ebvdsc', { largeur: 50 }),
            new FormulaireItem('ebvqte', { largeur: 25 }),
            new FormulaireItem('ebvflgmsg', { readonly: true, required: false, titre: " " })
        ]),
        colonnesEditionRapide: [
            { nom: 'ebvimp', largeur: 400 },
            { nom: 'ebvmntdbt', largeur: 126 },
            { nom: 'ebvmntcrt', largeur: 126 },
            { nom: 'ebvqte', largeur: 126 },
            { nom: 'ebvdsc', largeur: 300 },
            { nom: 'ebvflgmsg', largeur: 100 }
        ],
        colonnesCachees: [
            'prucleint',
            'prscleint',
            'cifcleint',
            'unacleint',
            'cptcleint',
            'el1cleint',
            'el2cleint',
            'el3cleint',
            'ebvmsg'
        ],
        details: {
            lblTitre: 'LBL_BLC_EBD',
            multiOccurrenceOptions: {
                bloc: 'ebd',
                colonnesVisibles: [
                    { nom: 'posnumdoc', largeur: 100 },
                    { nom: 'postitabr', largeur: 100 },
                    { nom: 'ebdmnt', largeur: 126 },
                    { nom: 'ebdmntavs', largeur: 126 },
                    { nom: 'ebddsc', largeur: 200 },
                ],
                formulaire: new Formulaire([
                    new FormulaireItem('poscleint'),
                    new FormulaireItem('ebdmnt'),
                    new FormulaireItem('ebdmntavs'),
                    new FormulaireItem('ebddsc'),
                ], { largeurDefaut: 25 })
            }
        }
    };
}
