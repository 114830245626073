import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAc0073 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    adrFormulaire: IFormulaire;
    dieMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dnvMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ac0073Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass,
    exDateFilter: IFilterExDate,
    MenuItemEcran: IMenuItemEcranClass,
    ac0028Route: IRoute,
    mc0044Route: IRoute,
    cp1010Route: IRoute) {

    const vm: IPageAc0073 = this;

    const actionAdrRapide = new MenuItemAction(
        'LBL_BTN_ADR_RAPIDE',
        'BOUADRRAP',
        () => ({}),
        {
            icon: 'add_circle',
            fonction: 'BOUADRRAP',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_ADR_RAPIDE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 30,
                formulaire: new Formulaire([
                    new FormulaireItem('radcodnew', { required: true }),
                    new FormulaireItem('radadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('radadrl02new', { maxlength: 40 }),
                    new FormulaireItem('radvilnew', { maxlength: 40 }),
                    new FormulaireItem('radpronew', { maxlength: 40 }),
                    new FormulaireItem('radpaynew', { maxlength: 40 }),
                    new FormulaireItem('radcodposnew', { maxlength: 10 }),
                    new FormulaireItem('radcodcednew', { maxlength: 40 })
                ], {
                    largeurDefaut: 100
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );

    const actionLieuPhyrec = new MenuItemAction(
        'LBL_BTN_LIEU_PHYSIQUE',
        'BOULIEPHY',
        () => ({
            type: 'REC'
        }),
        {
            icon: 'add_circle',
            fonction: 'BOULIEPHY',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('lipcodnew', { required: true }),
                    new FormulaireItem('lipflgadrfacnew'),
                    new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                    new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                    new FormulaireItem('intcleintnew'),
                    new FormulaireItemDivider('LBL_LIP_ADR'),
                    new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                    new FormulaireItem('lipvilnew', { maxlength: 40 }),
                    new FormulaireItem('lippronew', { maxlength: 40 }),
                    new FormulaireItem('lippaynew', { maxlength: 40 }),
                    new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                    new FormulaireItem('lipflgquarecnew'),
                    new FormulaireItemDivider(''),
                    new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                    new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                    new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                    new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                    new FormulaireItem('lipcleintrefnew')
                ], {
                    largeurDefaut: 50
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );

    const actionLieuPhyfac = new MenuItemAction(
        'LBL_BTN_LIEU_PHYSIQUE',
        'BOULIEPHY',
        () => ({
            type: 'FAC'
        }),
        {
            icon: 'add_circle',
            fonction: 'BOULIEPHYFAC',
            formulaireConfirmationAction: {
                lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                largeurFormulaire: 50,
                formulaire: new Formulaire([
                    new FormulaireItem('lipcodnew', { required: true }),
                    new FormulaireItem('lipflgadrfacnew'),
                    new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                    new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                    new FormulaireItem('intcleintnew'),
                    new FormulaireItemDivider('LBL_LIP_ADR'),
                    new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                    new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                    new FormulaireItem('lipvilnew', { maxlength: 40 }),
                    new FormulaireItem('lippronew', { maxlength: 40 }),
                    new FormulaireItem('lippaynew', { maxlength: 40 }),
                    new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                    new FormulaireItem('lipflgquarecnew'),
                    new FormulaireItemDivider(''),
                    new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                    new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                    new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                    new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                    new FormulaireItem('lipcleintrefnew')
                ], {
                    largeurDefaut: 50
                })
            },
            successAction: () => { vm.monoOccurrence.fetchData(); }
        }
    );

    const actionsApo = [
        new MenuItemForage(
            'BTN_FORAGE_APO',
            ac0028Route.NAME,
            (data:any) => ({
                id: data.aut__doccleintapo
            }),
            {
                icon: 'exit_to_app',
                iconButton: true,
                fonction: 'BOUAPO',
                hidden: (data:any) => (!data.aut__doccleintapo || !data.$id)
            }
        )
    ];

    const forageEnt = [
        new MenuItemForage(
            'BTN_FORAGE_PFL',
            cp1010Route.NAME,
            (data:any) => ({
                entcleint: data.entcleint,
                ecran:'00-2',
            }),
            { 
                hidden: (data:any) => (!data.entcleint || !data.$id),
                fonction:'BOUENTPAI',
                icon:'exit_to_app'
            }
        )
    ];

    const actionMsg = [
        new MenuItemAction(
            'LBL_BTN_DCNMSG',
            'BOUDCNMSG',
            () => ({}),
            {
                icon: 'description',
                class: (data: any) => data.aut__flgdcnmsg === 1 ? 'ex-teal-500-bg' : 'ex-primary-hue-3-fg',
                fonction: 'BOUDCNMSG',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_DCNMSG',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('dcnmsgnew', { 
                            default: (data:any) => data.aut__dcnmsg
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.$id ) 
            }
        ),
        new MenuItemAction(
            'LBL_BTN_DCNMSGCOU',
            'BOUDCNMSGCOU',
            () => ({}),
            {
                icon: 'mail',
                class: (data: any) => data.aut__flgmsgcou === 1 ? 'ex-teal-500-bg' : 'ex-primary-hue-3-fg',
                fonction: 'BOUDCNMSGCOU',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_DCNMSGCOU',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('dcnmsgcounew', { 
                            default: (data:any) => data.aut__dcnmsgcou
                        })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => (!vm.monoOccurrence.data.aut__motflgcou)    
            }
        )
    ];

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        infosMonetaires: {
            blocs: ['die', 'dnv'],
            largeurTexte: 150,
            donnees:
                [
                    { nom: 'die', libelle: 'LBL_MNT_DIE' },
                    { nom: 'dnv', libelle: 'LBL_MNT_DNV' },
                    { nom: 'net', libelle: 'LBL_MNT_NET' }
                ]
        },
        formulaire: new Formulaire('LBL_BLC_DCN', [
            new FormulaireItem('aut__dacnumdoc'),
            new FormulaireItem('dcnseqave'),
            new FormulaireItem('peccleint'),
            new FormulaireItem('dcndat'),
            new FormulaireItem('dcndatbc'),
            new FormulaireItem('dcndatreq'),
            new FormulaireItem('dcnsou'),
            new FormulaireItem('dcnrefint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('caacleint', {
                resourceParams: (data: any) => ({
                    refcleint: data.refcleint
                })
            }),
            new FormulaireItem('intcleintreq'),
            new FormulaireItem('intcleintdem'),
            new FormulaireItem('dcndsc', { largeur: 100, menu: actionMsg }),
            new FormulaireGroupeAccordeon('LBL_GRP_MOD',
                new Formulaire([
                    new FormulaireItemDivider('LBL_DIV_MODACH'),
                    new FormulaireItem('cfncleint', {
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            dcndat: data.dcndat
                        })
                    }),
                    new FormulaireItem('intcleintach'),
                    new FormulaireItem('apocleint', {
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint
                        }),
                        menu: actionsApo
                    }),
                    new FormulaireItem('iaccleint'),
                    new FormulaireItem('intcleintins'),
                    new FormulaireItem('dcnins'),
                    new FormulaireItem('motcleint'),
                    new FormulaireItem('aut__radadrcou'),
                    new FormulaireItem('aut__radnumfax'),
                    new FormulaireItemDivider('LBL_DIV_MODFAC'),
                    new FormulaireItem('vecsysmodtra'),
                    new FormulaireItem('dcnindfrsacc'),
                    new FormulaireItem('mopcleint'),
                    new FormulaireItem('entcleint', { menu: forageEnt}),
                    new FormulaireItem('teccleint'),
                    new FormulaireItemDivider('LBL_DIV_AUTRES'),
                    new FormulaireItem('vecdaccat'),
                    new FormulaireItem('vecsystercom'),
                    new FormulaireItem('dcndes'),
                    new FormulaireItem('lolcleint'),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('dcndatree'),
                    new FormulaireItem('dcndatdeb'),
                    new FormulaireItem('dcndatfin'),
                    new FormulaireItem('intcleint_averti'),
                    new FormulaireItem('dcndetexp', {largeur: 66.66, enableCount: true})        
                ], { largeurDefaut: 33.33 })
            )
        ], { largeurDefaut: 33.33 })
    };

    vm.adrFormulaire = new Formulaire('LBL_GRP_ADR', [
        new FormulaireItem('radcleint', {
            menu: [actionAdrRapide],
            resourceParams: (data: any) => ({
                refcleint: data.refcleint,
                dcndat: data.dcndat
            })
        }),
        new FormulaireItem('dcnattext'),
        new FormulaireItem('lipcleint', { menu: [actionLieuPhyrec]}),
        new FormulaireItem('dcnatt'),
        new FormulaireItem('lipcleintfac', { menu: [actionLieuPhyfac]})
    ], { largeurDefaut: 100 });

    vm.dieMultiOccurrenceOptions = {
        bloc: 'die',
        fonctions: {
            editionRapide: true,
            editionStandard: true,
            agrandirMultiOccurrence: true,
            supprime: (rowData:any) => (!rowData.daicleint)
        },
        editionRapideActive: true,
        colonnesFixesGauche: [
            {nom: 'dielig', largeur: 100},
            {nom: 'diedsc', largeur: 300}
        ],
        colonnesVisibles: [
            {nom: 'dieprxuni', largeur: 126},
            {nom: 'dieqte', largeur: 126},
            {nom: 'taxcod', largeur: 100},
            {nom: 'diemnt', largeur: 126},
            {nom: 'diemnttaxfed', largeur: 126},
            {nom: 'diemnttaxpro', largeur: 126},
            {nom: 'caacod', largeur: 120},
            {nom: 'caadsc', largeur: 180},
            {nom: 'dienumcat', largeur: 130},
            {nom: 'forcod', largeur: 120},
            {nom: 'fordsc', largeur: 180},
            {nom: 'unmcod', largeur: 120},
            {nom: 'unmdsc', largeur: 180},
            {nom: 'dieclash', largeur: 140},
            {nom: 'diepayori', largeur: 130},
            {nom: 'lolnumloc', largeur: 120},
            {nom: 'loldsc', largeur: 180},
            {nom: 'dieflggeb', largeur: 120},
            {nom: 'dahqterec', largeur: 130},
            {nom: 'dahqteret', largeur: 130},
            {nom: 'dahqtefac', largeur: 130},
            {nom: 'dieflghis', largeur: 120},
            {nom: 'dieflgimp', largeur: 120}
        ],
        colonnesCachees: [
            'daicleint',
            'dahflg'
        ],
        colonnesEditionRapide: [
            {nom: 'dielig', largeur: 130 },
            {nom: 'diedsc', largeur: 150 },
            {nom: 'dieprxuni', largeur: 120 },
            {nom: 'dieqte', largeur: 120 },
            {nom: 'taxcleint', largeur: 250 },
            {nom: 'diemnt', largeur: 120 },
            {nom: 'diemnttaxfed', largeur: 140 },
            {nom: 'diemnttaxpro', largeur: 140 },
            {nom: 'caacleint', largeur: 150 },
            {nom: 'dienumcat', largeur: 140 },
            {nom: 'forcleint', largeur: 120 },
            {nom: 'dieclash', largeur: 140 },
            {nom: 'diepayori', largeur: 120 },
            {nom: 'lolcleint', largeur: 120 },
            {nom: 'dieflggeb', largeur: 120 },
            {nom: 'dieflghis', largeur: 120 },
            {nom: 'dieflgimp', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dielig'),
            new FormulaireItem('diedsc'),
            new FormulaireItem('diedsclng'),
            new FormulaireItem('dieprxuni'),
            new FormulaireItem('dieqte'),
            new FormulaireItem('taxcleint'),
            new FormulaireItem('diemnt'),
            new FormulaireItem('diemnttaxfed'),
            new FormulaireItem('diemnttaxpro'),
            new FormulaireItem('diemntnet'),
            new FormulaireItem('caacleint'),
            new FormulaireItem('dienumcat'),
            new FormulaireItem('forcleint'),
            new FormulaireItem('dieclash'),
            new FormulaireItem('diepayori'),
            new FormulaireItem('lolcleint'),
            new FormulaireItem('dieflggeb'),
            new FormulaireItem('dieflghis'),
            new FormulaireItem('dieflgimp', { default: 1 })
        ], {largeurDefaut: 33.33})
    };

    vm.dnvMultiOccurrenceOptions = {
        bloc: 'dnv',
        colonnesFixesGauche: [
            {nom: 'dnvimp', largeur: 300, menu: [
                new MenuItemEcran(
                    '', 
                    mc0044Route.NAME, 
                    (rowData: any) => ({ 
                        id: rowData.prucleint, 
                        prucleint: rowData.prucleint }),
                    {
                    icon: 'launch',
                    iconButton: false,
                    secondaire: true,
                    fonction: 'BOUPOS'
                })
            ]},
            {nom: 'dnvmnt', largeur: 140}
        ],
        colonnesVisibles: [
            {nom: 'taxcod', largeur: 80},
            {nom: 'remcod', largeur: 80},
            {nom: 'dnvmnttaxfed', largeur: 130},
            {nom: 'dnvmnttaxpro', largeur: 130},
            {nom: 'dnvmntremfed', largeur: 130},
            {nom: 'dnvmntrempro', largeur: 130},
            {nom: 'dnvmntdepnet', largeur: 130}
        ],
        colonnesFixesDroite: [
            {nom: 'dnvflgmsg', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('dnvimp', { hidden: true }),
        ]),
        colonnesCachees: [
            'prucleint',
            'dnvmsg'
        ]
    };
}
