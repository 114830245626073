import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPagePr0011 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    orpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    oadMultiOccurrenceOptions: IMultiOccurrenceOptions;
    orcMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pr0011Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite
) {
    const vm: IPagePr0011 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.formulaireOrg = new Formulaire('LBL_ORG',[
        new FormulaireItem('orgcod', {
            largeur: 33
        }),
        new FormulaireItem('orgflgstu', {
            largeur: 33
        }),
        new FormulaireItem('orgifrcod', {
            largeur: 33
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('orgnom', {
            largeur: 66
        }),
        new FormulaireItem('orgnomabr', {
            largeur: 33
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('caocleint', {
            largeur: 33
        }),
        new FormulaireItem('sfucleint', {
            largeur: 33
        }),
        new FormulaireItem('mofcleint', {
            largeur: 33
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('refcleint', {
            largeur: 33
        }),
        new FormulaireItem('orgflgsiracr', {
            largeur: 33
        }),
        new FormulaireItem('orgflgoblprg', {
            largeur: 33
        })
    ]);
    vm.formulaireAdr = new Formulaire('Adresse de défaut', [
        new FormulaireItem('oadcleintburrec', {
            resourceParams: (data: any) => ({ orgcleint: data.orgcleint })
        }),
        new FormulaireItem('oadcleintserfin', {
            resourceParams: (data: any) => ({ orgcleint: data.orgcleint })
        })
    ]);
    vm.formulaireFer = new Formulaire('Fermeture', [
        new FormulaireItem('orgmoifin', {
            resourceParams: (data: any) => ({ orgcleint: data.orgcleint })
        }),
        new FormulaireItem('peccod', {
            readonly: true,
            default: vm.valeursEcran.peccod
        }),
    ]);
    vm.formulaireAutr = new Formulaire([
        new FormulaireItem('srvcleint', {
            largeur: 100
        }),
        new FormulaireItem('oppcleint', {
            largeur: 100
        }),
        new FormulaireItem('vaesysindrec', {
            largeur: 100
        })
    ]);
    vm.orpMultiOccurrenceOptions = {
        bloc: 'orp',
        colonnesFixesGauche: [
            { nom: 'orpcod', largeur: 150 },
        ],
        colonnesVisibles: [
            { nom: 'orpdsc', largeur: 130 },
            { nom: 'orpdscabr', largeur: 200 },
            { nom: 'orpnumref', largeur: 130 },
            { nom: 'vaesysindrec_desc', largeur: 130 },
            { nom: 'sfucod', largeur: 130 },
            { nom: 'sfudsc', largeur: 200 },
            { nom: 'oadnum_rec', largeur: 200 },
            { nom: 'oadnum_fin', largeur: 220 },
            { nom: 'orpmoifin', largeur: 130 },
            { nom: 'peccod', largeur: 130 },
            { nom: 'mofcod', largeur: 200 },
            { nom: 'mofdsc', largeur: 220 },
            { nom: 'pficod', largeur: 220 },
            { nom: 'pfidsc', largeur: 220 },
            { nom: 'orpflgsiracr', largeur: 130 },
            { nom: 'orpflgstu', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('orpcod', {
                largeur: 33
            }),
            new FormulaireItem('orpdsc', {
                largeur: 33
            }),
            new FormulaireItem('orpdscabr', {
                largeur: 33
            }),
            new FormulaireItem('orpnumref', {
                largeur: 33
            }),
            new FormulaireItem('vaesysindrec', {
                largeur: 33
            }),
            new FormulaireItem('sfucleint', {
                largeur: 33
            }),
            new FormulaireItem('oadcleintburrec', {
                largeur: 33
            }),
            new FormulaireItem('oadcleintserfin', {
                largeur: 33,
                resourceParams: (data: any) => ({ orgcleint: data.$ancetre.orgcleint })
            }),
            new FormulaireItem('mofcleint', {
                largeur: 33
            }),
            new FormulaireItem('orpmoifin', {
                largeur: 33
            }),
            new FormulaireItem('peccod', {
                largeur: 33,
                readonly:true
            }),
            new FormulaireItem('pficleint', {
                largeur: 33
            }),
            new FormulaireItem('orpflgsiracr', {
                largeur: 33
            }),
            new FormulaireItem('orpflgstu', {
                largeur: 33
            })
        ])
    }


    vm.oadMultiOccurrenceOptions = {
        bloc: 'oad',
        colonnesFixesGauche: [
            { nom: 'oadnum', largeur: 130 },
        ],
        colonnesVisibles: [
            { nom: 'oadcnt', largeur: 130 },
            { nom: 'oadadrl01', largeur: 130 },
            { nom: 'oadadrl02', largeur: 220 },
            { nom: 'oadvil', largeur: 130 },
            { nom: 'oadpro', largeur: 130 },
            { nom: 'oadpay', largeur: 130 },
            { nom: 'oadcodpos', largeur: 130 },
            { nom: 'vecsysregadm', largeur: 220 },
            { nom: 'oadtel', largeur: 130 },
            { nom: 'oadfax', largeur: 130 },
            { nom: 'oadadrele', largeur: 130 },
            { nom: 'oadflgburrec', largeur: 130 },
            { nom: 'oadflgserfin', largeur: 130 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('oadcnt', {
                largeur: 33
            }),
            new FormulaireItem('oadadrl01', {
                largeur: 33
            }),
            new FormulaireItem('oadadrl02', {
                largeur: 33
            }),
            new FormulaireItem('oadvil', {
                largeur: 33
            }),
            new FormulaireItem('oadpro', {
                largeur: 33
            }),
            new FormulaireItem('oadpay', {
                largeur: 33
            }),
            new FormulaireItem('oadcodpos', {
                largeur: 33
            }),
            new FormulaireItem('vecsysregadm', {
                largeur: 33
            }),
            new FormulaireItem('oadtel', {
                largeur: 33
            }),
            new FormulaireItem('oadfax', {
                largeur: 33
            }),
            new FormulaireItem('oadadrele', {
                largeur: 33
            }),
            new FormulaireItem('oadflgburrec', {
                largeur: 33
            }),
            new FormulaireItem('oadflgserfin', {
                largeur: 33
            })
        ])

    };
    vm.orcMultiOccurrenceOptions = {
        bloc: 'orc',
        colonnesVisibles: [
            { nom: 'ciecod', largeur: 130 },
            { nom: 'cienom', largeur: 130 },
            { nom: 'orcannfin', largeur: 130 },
            { nom: 'orcdsc', largeur: 130 }
        ]
    };
}
