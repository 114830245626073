import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAS0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function As0030Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite
) {
    const vm: IPageAS0030 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'frscod', largeur: 90 },
            { nom: 'frsdsc', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'vaefrstem_dsc', largeur: 130 },
            { nom: 'vaefrslem_dsc', largeur: 130 },
            { nom: 'vaefrsaft_dsc', largeur: 130 },
            { nom: 'vaefrspos_dsc', largeur: 130 },
            { nom: 'vaefrsder_dsc', largeur: 180 },
            { nom: 'vaefrsuno_dsc', largeur: 130 },
            { nom: 'vaefrsimp_dsc', largeur: 130 },
            { nom: 'vaefrscmt_dsc', largeur: 130 },
            { nom: 'vaefrstag_dsc', largeur: 130 },
            { nom: 'vaefrsesc1_dsc', largeur: 130, hidden: () => (ecranDetails.valeurs.flgfrsesc1) },
            { nom: 'vaefrsesc2_dsc', largeur: 130, hidden: () => (ecranDetails.valeurs.flgfrsesc2) },
            { nom: 'vaefrsesc3_dsc', largeur: 130, hidden: () => (ecranDetails.valeurs.flgfrsesc3) },
            { nom: 'vaefrsesc4_dsc', largeur: 130, hidden: () => (ecranDetails.valeurs.flgfrsesc4) },
            { nom: 'vaefrsesc5_dsc', largeur: 130, hidden: () => (ecranDetails.valeurs.flgfrsesc5) }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('frscod', { largeur: 50 }),
            new FormulaireItem('frsdsc', { largeur: 50 }),
            new FormulaireItem('vaefrstem'),
            new FormulaireItem('vaefrslem'),
            new FormulaireItem('vaefrsaft'),
            new FormulaireItem('vaefrspos'),
            new FormulaireItem('vaefrsder'),
            new FormulaireItem('vaefrsuno'),
            new FormulaireItem('vaefrsimp'),
            new FormulaireItem('vaefrscmt'),
            new FormulaireItem('vaefrstag'),
            new FormulaireItem('vaefrsesc1', { hidden: () => (ecranDetails.valeurs.flgfrsesc1), required: ecranDetails.valeurs.flgoblesc1, default: 'NON' }),
            new FormulaireItem('vaefrsesc2', { hidden: () => (ecranDetails.valeurs.flgfrsesc2), required: ecranDetails.valeurs.flgoblesc2, default: 'NON' }),
            new FormulaireItem('vaefrsesc3', { hidden: () => (ecranDetails.valeurs.flgfrsesc3), required: ecranDetails.valeurs.flgoblesc3, default: 'NON' }),
            new FormulaireItem('vaefrsesc4', { hidden: () => (ecranDetails.valeurs.flgfrsesc4), required: ecranDetails.valeurs.flgoblesc4, default: 'NON' }),
            new FormulaireItem('vaefrsesc5', { hidden: () => (ecranDetails.valeurs.flgfrsesc5), required: ecranDetails.valeurs.flgoblesc5, default: 'NON' })
        ], { largeurDefaut: 33 })
    };
}
