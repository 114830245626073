import { IAttributInfosComplementaires } from '../../../../core/components/ex-infos-complementaires/ex-infos-complementaires.controller';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../core/services/multi-occurrence.service';
import { IMonoOccurrence } from '../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../core/services/formulaire/formulaire.service';
import { IApiError } from '../../../../core/interfaces/api-error.interface';
import { IDialogAllStatus } from '../../../../core/constants/dialog.constant';
import { ILovDataTypeClass } from '../../../../core/services/data-types/lov-data-type.service';
import { IDateDataTypeClass } from '../../../../core/services/data-types/date-data-type.service';
import { ITexteDataTypeClass } from '../../../../core/services/data-types/texte-data-type.service';
import { INumberDataTypeClass } from '../../../../core/services/data-types/number-data-type.service';
import { IApiConfig } from '../../../../core/interfaces/api-config.interface';
import { IFormulaireItemClass } from '../../../../core/services/formulaire/formulaire-item.service';
import { IDataType } from '../../../../core/services/data-types/data-type.service';
import { INotificationHandler } from '../../../../core/services/utils/notification-handler.service';
import IPromise = angular.IPromise;
import { ISourceDetails } from '../../../../core/resources/source-details.resource';
import { IComposantDialog } from '../../../../core/components/ex-dialog/ex-dialog.controller';

interface IDialogInfosComplementaires extends IComposantDialog {
    lblTitre: string;
    lblCancel: string;
    lblConfirm: string;
    icon: string;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    monoOccurrence: IMonoOccurrence;
    formulaireMonoOccurrence: IFormulaire;
    data: IAttributInfosComplementaires;
    error: IApiError;
    errorCode: string;
    retry: boolean;
    closeOnError: boolean;
    showConfirmAction: boolean;
    confirming: boolean;
    saving: boolean;
    stateParams: any;
    ecranDetails: any;
    ecranSourceDetails: ISourceDetails;
    formData: any;

    confirmMonoOccurrence(): void;
    saveMonoOccurrence(): IPromise<any>;
    retryMono(): void;
}

/* @ngInject */
export default function InfosComplementairesController(DialogStatus: IDialogAllStatus,
    LovDataType: ILovDataTypeClass,
    DateDataType: IDateDataTypeClass,
    TexteDataType: ITexteDataTypeClass,
    NumberDataType: INumberDataTypeClass,
    ApiConfig: IApiConfig,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    notificationHandler: INotificationHandler) {
    const vm: IDialogInfosComplementaires = this;
    const iconDefault = 'settings_ethernet';
    const iconErreur = 'warning';
    const lblReessayer = 'G_LBL_BTN_REESSAYER';
    const lblCancel = 'G_LBL_BTN_ANNULER';

    vm.saveMonoOccurrence = saveMonoOccurrence;
    vm.confirmMonoOccurrence = confirmMonoOccurrence;
    vm.retryMono = retryMono;

    vm.error = null;
    vm.retry = true;
    vm.lblCancel = lblCancel;
    vm.closeOnError = false;
    vm.confirming = false;
    vm.icon = iconDefault;

    vm.$onInit = function $onInit() {
        if (vm.data.flgmulti) {
            initDataMulti();
        } else {
            initDataMono();
        }
    }

    function getDataType(col: string): IDataType {
        const schemaItem = {
            column: col
        };
        let dataType;
        if (!vm.data.flglov) {
            if (vm.data.vaesystypdon === 'N') {
                dataType = new NumberDataType({
                    schemaItem,
                    params: {
                        type: "number"
                    }
                });
            } else if (vm.data.vaesystypdon === 'D') {
                dataType = new DateDataType({
                    schemaItem,
                    params: {
                        format: "yyyy-MM-dd",
                        type: "date"
                    }
                });
            } else {
                dataType = new TexteDataType({
                    schemaItem,
                    params: {
                        type: "texte"
                    }
                });
            }
        } else {
            dataType = new LovDataType({
                schemaItem,
                params: {
                    source: 'ilv',
                    description: ['ilvval'],
                    valeur: 'ilvval'
                }
            });
        }
        return dataType;
    }

    function saveMonoOccurrence() {
        vm.saving = true;
        return vm.monoOccurrence.save(vm.formData).then(() => {
            notificationHandler.succes();
        }).finally(() => {
            vm.saving = false;
        });
    }

    function confirmMonoOccurrence() {
        if (vm.error && !vm.retry || vm.data.flgmulti) {
            return DialogStatus.FERMER;
        }
        vm.data.icvvaluni = vm.formData["icvvaluni"]

        const confirmActionResult = vm.saveMonoOccurrence();
        if (confirmActionResult && confirmActionResult.then) {
            vm.confirming = true;
            return confirmActionResult.then(() => {
                return DialogStatus.APPLIQUER;
            }).catch((error: IApiError) => {
                vm.error = error;
                vm.errorCode = error.data && error.data.code;
                vm.icon = iconErreur;
                vm.lblConfirm = lblReessayer;
                return Promise.reject(error)
            }).finally(() => {
                this.confirming = false;
            });
        } else {
            return DialogStatus.APPLIQUER;
        }
    }

    function initDataMono() {
        vm.monoOccurrenceOptions = {
            stateParams: vm.stateParams,
            ecranDetails: vm.ecranDetails,
            ecranSourceDetails: vm.ecranSourceDetails,
            srccod: 'mcicovaleur',
            id: vm.data.icvcleint,
            nomSourceDetails: 'mcicovaleur',
            resourceUrl: `${ApiConfig.ROOT}/mc1022/:icvcleintref/mcicovaleur`,
            resourceParams: {
                srccod: vm.stateParams.srccod,
                icvcleintref: vm.data.icvcleintref
            },
            isEdition: true,
            champsTransactionnels: {},
            formulaire: new Formulaire('', [new FormulaireItem("icvvaluni", {
                titre: vm.data.icolib,
                dataType: getDataType("icvvaluni"),
                resourceParams: {
                    icocleint: vm.data.icocleint
                }
            })])
        };
    }

    function retryMono() {
        vm.monoOccurrence.fetchData();
    }

    function initDataMulti() {
        vm.multiOccurrenceOptions = {
            srccod: 'mcicvdetail',
            stateParams: vm.stateParams,
            ecranDetails: vm.ecranDetails,
            resourceUrl: `${ApiConfig.ROOT}/mc1022/:icvcleintref/mcicv/:icvcleint/mcicvdetail/:id`,
            resourceParams: {
                icocleint: vm.data.icocleint,
                srccod: vm.stateParams.srccod,
                icvcleintref: vm.data.icvcleintref,
                icvcleint: vm.data.icvcleint
            },
            autoFetch: Boolean(vm.data.valeur),
            fonctions: {
                edition: true,
                supprime: true,
                suiviModification: true,
                exportation: false,
                importation: false,
                recherche: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                filtrer: false,
                enregistrerUnEtat: false,
                reinitialiser: false,
                afficherMenuSettingDansEntete: true,
                pagination: false
            },
            colonnesVisibles: [
                { nom: 'icdval', largeur: 200 }
            ],
            colonnesCachees: [
                'icvcleint'
            ],
            formulaire: new Formulaire(vm.data.icolib, [new FormulaireItem("icdval", {
                titre: vm.data.icolib,
                dataType: getDataType("icdval"),
                resourceParams: {
                    icocleint: vm.data.icocleint
                }
            })])
        };

        vm.showConfirmAction = false;
        vm.lblCancel = 'G_LBL_BTN_FERMER';
    }
}
