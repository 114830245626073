import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0001',
    ecrans: [
        '01-2',
        '02-2',
        '03-2',
        '04-2',
        '05-2',
        '06-2',
        '07-2',
        '08-2',
        '09-2',
        '10-2',
        '11-2',
        '12-2',
        '14-2',
        '16-2',
        '17-2',
        '18-2',
        '19-2',
        '20-2',
        '21-2',
        '22-2',
        '23-2',
        '25-2',
        '26-2',
        '27-2',
        '28-2',
        '29-2',
        '30-2',
        '31-2',
        '32-2',
        '33-2',
        '34-2',
        '35-2',
        '36-2',
        '37-2',
        '38-2',
        '39-2',
        '40-2',
        '41-2',
        '42-2',
        '43-2',
        '44-2',
        '45-2',
        '46-2',
        '47-2',
        '48-2',
        '49-2',
        '50-2',
        '51-2',
        '52-2',
        '53-2',
        '54-2',
        '55-2',
        '56-2',
        '57-2',
        '59-2',
        '60-2',
        '61-2',
        '62-2',
        '63-2',
        '64-2',
        '65-2',
        '66-2',
        '67-2',
        '68-2',
        '69-2',
        '70-2',
        '71-2',
        '74-2',
        '75-2',
        '76-2',
        '77-2',
        '78-2',
        '79-2',
        '80-2',
        '81-2',
        '82-2',
        '83-2',
        '90-2',
        "2000-2"
    ]
});
