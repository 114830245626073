import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
interface IPageRr0067 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0067Controller(Menu: IMenuClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    MenuItem: IMenuItemClass,
    downloadManager: IDownloadManagerService,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0067 = this;

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/rr0067/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        fonctions: {
            importation: true,
            rechercheCollapsable: false,
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe }
        ],
        colonnesVisibles: [
            { nom: 'carcod', largeur: 90 },
            { nom: 'cardsc', largeur: 262 },
            { nom: 'socdattrs', largeur: 100 },
            { nom: 'socnumref', largeur: 196 },
            { nom: 'vaesoctyptrs_dsc', largeur: 196 },
            { nom: 'socmnt', largeur: 126 },
            { nom: 'soccom', largeur: 262 },
            { nom: 'dalnumdoc', largeur: 139 },
            { nom: 'daldsc', largeur: 262 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('carcleint'),
            new FormulaireItem('aut__sld_acj'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('socdattrs'),
            new FormulaireItem('vaesoctyptrs', { default: 'REMB' }),
            new FormulaireItem('socmnt'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('socnumref'),
            new FormulaireItem('dalcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('soccom', { largeur: 66.66, enableCount: true })

        ], {
            largeurDefaut: 33.33
        }
        )
    }
}
