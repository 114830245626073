import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';
import { IOperateurService } from '../../../../../core/services/operateur.service';
interface IPageDb0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Db0032Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    $stateParams: IStateParamsService,
    FormulaireItem: IFormulaireItemClass,
    Operateur: IOperateurService
) {
    const vm: IPageDb0032 = this;
    vm.valeursEcran = ecranDetails.valeurs;
    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            nouveau: ecranDetails.valeurs.etdcleint === 77 || ecranDetails.valeurs.fglupd,
            edition: ecranDetails.valeurs.etdcleint === 77 || ecranDetails.valeurs.fglupd,
            supprime: ecranDetails.valeurs.etdcleint === 77 || ecranDetails.valeurs.fglupd
        },
        filtres: [
            {
                colonne: 'ebvcleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.id,
                visible: false
            }
        ],
        colonnesVisibles: [
            { nom: 'posnumdoc', largeur: 80 },
            { nom: 'postitabr', largeur: 280 },
            { nom: 'ebdmnt', largeur: 140 },
            { nom: 'ebdmntavs', largeur: 140 },
            { nom: 'ebddsc', largeur: 262 }
        ],
        formulaire: new Formulaire(
            [
                new FormulaireItem('ebvcleint', { hidden: true, default: $stateParams.id }),
                new FormulaireItem('poscleint'),
                new FormulaireItem('ebdmnt'),
                new FormulaireItem('ebdmntavs', { enableCount: true }),
                new FormulaireItem('ebddsc')
            ],
            { largeurDefaut: 50 }
        )
    };
}
