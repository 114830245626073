import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc2005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceEtpOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mc2005Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2005 = this;

    vm.multiOccurrenceEtpOptions = {
        bloc: 'etp',
        colonnesVisibles: [
            {nom: 'tydcod', largeur: 130},
            {nom: 'tyddsc', largeur: 462}
        ],
        formulaire: new Formulaire([           
            new FormulaireItem('tydcleint')
        ])
    };

    vm.multiOccurrenceOptions = { 
        fonctions: {
            nouveau: false,
            recherche: false,
            exportation: false,
            selectionnerDesColonnes: false,
            enregistrerUnEtat: false,
            filtrer: false,
            reinitialiser: false,
            pagination: true,
            suiviModification: false
        },
        pagination: {
            nombreEnregistrementInMenuMore: false
        },      
        colonnesVisibles: [
            {nom: 'text', largeur: 780},
            {nom: 'flgact', largeur: 520}
        ],
        formulaire: new Formulaire([
            //new FormulaireItem('text', {readonly: true}),
            new FormulaireItem('flgact')
        ], {largeurDefaut: 50}),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceEtpOptions
    };

}
