import {IMultiOccurrenceOptions} from "../../../../../core/services/multi-occurrence.service";
import {IOperateurService} from "../../../../../core/services/operateur.service";
import {IFormulaireClass} from "../../../../../core/services/formulaire/formulaire.service";
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";


interface IPageMc0089 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0089Controller( Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass,
                                          $stateParams: any,
                                          Operateur: IOperateurService ) {
    const vm: IPageMc0089 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        filtres: [
            {
                colonne: 'elgcleintref',
                operateur: Operateur.EGALE,
                valeur: $stateParams.elgcleintref,
                visible: false
            },
            {
                colonne: 'vaesyselg',
                operateur: Operateur.EGALE,
                valeur: $stateParams.vaesyselg,
                visible: false
            }
        ],
        colonnesVisibles: [
            {nom: 'prucod'   , largeur: 300},
            {nom: 'prudscl01'   , largeur: 450},
            {nom: 'vaesysnatctb'  , largeur: 170},
            {nom: 'eauflgact' , largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('elgcleintref', {default: $stateParams.elgcleintref }),
            new FormulaireItem('vaesyselg', {default: $stateParams.vaesyselg }),
            new FormulaireItem('prucleint'),
            new FormulaireItem('vaesysnatctb'),
            new FormulaireItem('eauflgact'),
        ], {largeurDefaut: 33})
    };
}

