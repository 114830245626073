import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterLibelle} from '../../../../../core/filters/ex-libelle.filter';

interface IPageMr0263 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0263Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         exLibelleFilter: IFilterLibelle,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0263 = this;
    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'ugncod', largeur: 90},
            {nom: 'ugndsc', largeur: 300},
            {nom: 'ugndscabr', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ugncod', {required: true}),
            new FormulaireItem('ugndsc', {largeur: 50, required: true}),
            new FormulaireItem('ugndscabr', {
                required: true,
                onFocus(rowData: any) {
                    if (!rowData.ugndscabr) {
                        rowData.ugndscabr = rowData.ugndsc;
                    }
                }
            })
        ],{largeurDefaut: 25})
    };

}
