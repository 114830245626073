import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IMenuSectionClass } from "../../../../../core/services/menu/menu-section.service";


interface IPageIF0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function If0006Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemSelection: IMenuItemSelectionClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemAction: IMenuItemActionClass,
    MenuSection: IMenuSectionClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Operateur: IOperateurService,
    cp2010Route: IRoute,
    gl0003Route: IRoute,
    if0014Route: IRoute,
    if0017Route: IRoute,
    if0015Route: IRoute,
    if0018Route: IRoute,
    mc0109Route: IRoute) {
    const vm: IPageIF0006 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    const forageIf0014 = new MenuItemEcran(
        'G_LBL_BTN_MODIFIER',
        if0014Route.NAME,
        (rowData: any) => {
            return {
                id: rowData.itscleint,
                itscleint: rowData.itscleint
            };
        }, {
        icon: 'launch',
        fonction: 'MOD_UBR',
        onClose() {
            vm.multiOccurrence.fetchDataList();
        }
    });

    const forageImm = new MenuItemEcran('G_LBL_FOR_IMM', if0015Route.NAME,
        (rowData: any) => ({
            id: rowData.itscleint,
            itscleint: rowData.itscleint,
            prucleint: rowData.prucleint
        }),
        {
            icon: 'launch',
            fonction: 'MOD_REF',
            onClose() {
                vm.multiOccurrence.fetchDataList();
            }
        });

    const forageImmRef = new MenuItemEcran('G_LBL_FOR_IMM_REF', if0018Route.NAME,
        (rowdata: any) => ({
            doccleint: rowdata.doccleint,
            itscleint: rowdata.itscleint
        }),
        {
            icon: 'launch',
            fonction: 'MOD_ACT',
            onClose() {
                vm.multiOccurrence.fetchDataList();
            }
        });

    const forageRef = new MenuItemForage(
        'LBL_FOR_REF',
        (data: any) => getForage(data),
        (data: any) => getParams(data),
        { fonction: 'FOR_NUM' }
    );

    vm.multiOccurrenceOptions = {
        actions: new Menu([
            new MenuItemAction(
                'LBL_BTN_EXECUTER',
                'BOUEXE',
                () => ({}),
                {
                    iconButton: false,
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    formulaireConfirmationAction: {
                        lblTitre: 'G_LBL_EXECUTER',
                        lblConfirm: 'G_LBL_EXECUTER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('peccleintexe', {
                                default: () => {
                                    return vm.valeursEcran.peccleintexe;
                                }
                            })
                        ], { largeurDefaut: 100 })
                    },
                    successAction: () => { vm.multiOccurrence.fetchDataList() }
                }
            )
        ]),
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'itsflgtrt',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            }
        ],
        champsNonSauvergarderEtat: ['peccleintexe'],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('prucleint'),
            new FormulaireItem('cptcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pecdeb'),
            new FormulaireItem('pecfin')
        ]),
        actionsSelection: new Menu([
            new MenuSection('LBL_BTN_ACTIONS_A_PRENDRE'),
            new MenuItemSelection(
                'LBL_BTN_CREER_IMMO',
                'BOUACTCREIMM', {
                selection: {
                    nom: 'actionsCreer',
                    lblConfirmationTitre: 'LBL_BTN_CREER_IMMO',
                    largeurFormulaire: 50,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('immcodnew', { maxlength: 20, viderChamp: true }),
                        new FormulaireItem('catcleintnew', { viderChamp: true }),
                        new FormulaireItem('vecimmtypacqnew', { viderChamp: true }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('itddsc', { largeur: 100, viderChamp: true, maxlength: 60 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('datmes', { viderChamp: true }),
                        new FormulaireItem('iopcleintnew', { viderChamp: true }),
                        new FormulaireItem('flgeffact', { viderChamp: true })
                    ], { largeurDefaut: 33 })
                },
                successAction: (data: any) => {
                    const menuItemEcranSelection = new MenuItemEcran(
                        'G_LBL_ACTPRE',
                        if0017Route.NAME,
                        () => ({
                            id: data.ciecleint,
                            itdcleintgen: data.itdcleintgen,
                            itdcleintnew: data.itdcleintnew
                        }),
                        {
                            icon: 'launch',
                            iconButton: true,
                            onClose() {
                                vm.multiOccurrence.fetchDataList();
                            }
                        }
                    );
                    menuItemEcranSelection.action(null, null);
                }
            }),
            new MenuItemSelection(
                'LBL_BTN_ASSOCIER_IMMO',
                'BOUACTASSIMM', {
                selection: {
                    nom: 'actionsAssocier',
                    lblConfirmationTitre: 'LBL_BTN_ASSOCIER_IMMO',
                    largeurFormulaire: 50,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('immcleintnew', { largeur: 100, viderChamp: true }),
                        new FormulaireItem('itddsc', { largeur: 66, viderChamp: true, maxlength: 60 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('datmes', { viderChamp: true }),
                        new FormulaireItem('flgeffact', { viderChamp: true })
                    ], { largeurDefaut: 33 })
                },
                successAction: (data: any) => {
                    const menuItemEcranSelection = new MenuItemEcran(
                        'G_LBL_ACTPRE',
                        if0017Route.NAME,
                        () => ({
                            id: data.ciecleint,
                            itdcleintgen: data.itdcleintgen,
                            itdcleintnew: data.itdcleintnew
                        }),
                        {
                            icon: 'launch',
                            iconButton: true,
                            onClose() {
                                vm.multiOccurrence.fetchDataList();
                            }
                        }
                    );
                    menuItemEcranSelection.action(null, null);
                }
            }),
            new MenuItemSelection(
                'LBL_BTN_ANNULER',
                'BOUACTANN', {
                selection: {
                    nom: 'actionsAnnuler',
                    lblConfirmationTitre: 'LBL_BTN_ANNULER',
                    largeurFormulaire: 40,
                    confirmationFormulaire: new Formulaire([
                        new FormulaireItem('itddsc', { largeur: 100, maxlength: 60 }),
                        new FormulaireItemSautDeLigne(),
                        new FormulaireItem('flgeffact')
                    ], { largeurDefaut: 50 })
                }
            }),
            new MenuSection('LBL_BTN_MOD_ETAT'),
            // Action Prêt èa traiter
            new MenuItemSelection(
                'LBL_BTN_PRET_A_TRAITER',
                'BOUATRT', {
                selection: {
                    nom: 'pretATraiter',
                    lblConfirm: 'G_LBL_EXECUTER',
                    lblConfirmationTitre: 'G_LBL_PRET_A_TRAITER',
                    icon: 'sync'
                }, successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            }),
            // Action Retour initial
            new MenuItemSelection(
                'LBL_BTN_RETOUR_INITIAL',
                'BOURETINI', {
                selection: {
                    nom: 'retourInitial',
                    lblConfirm: 'G_LBL_EXECUTER',
                    lblConfirmationTitre: 'G_LBL_RETOUR_INITIAL',
                    icon: 'sync'
                }, successAction: () => {
                    vm.multiOccurrence.fetchDataList();
                }
            })
        ]),
        colonnesVisibles: [
            { nom: 'itsdattrs', largeur: 100 },
            { nom: 'vaeitsstutrs_desc', largeur: 80 },
            { nom: 'tydcod', largeur: 80 },
            { nom: 'docaffnumdoc01', largeur: 140, menu: [forageRef] },
            { nom: 'refcodnomref', largeur: 200 },
            { nom: 'itsdatref', largeur: 100 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'imp', largeur: 240 },
            { nom: 'itsmntref', largeur: 100 },
            { nom: 'flgecr', largeur: 100 },
            { nom: 'flghis', largeur: 100 }
        ],
        colonnesFixesDroite: [
            { nom: 'nbrimm', largeur: 100, menu: [forageImm] },
            { nom: 'nbrimm_ref', largeur: 100, menu: [forageImmRef] },
            { nom: 'itsactpre', largeur: 130, menu: [forageIf0014] },
            { nom: 'immcod', largeur: 130 },
            { nom: 'itsflgerr', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeitsstutrs', {
                largeur: 25
            })
        ]),
        colonnesCachees: [
            'vaeitsstutrs',
            'ciecleint',
            'prucleint',
            'doccleint',
            'ecrcod',
            'itsflgtrt',
            'itsmsgerr',
            'docaffcleint01'
        ]
    };

    function getForage(data: any): string {
        if (data.ecrcod === 'CR0021-00-2') {
            // return cr0021Route.NAME;
        } else if (data.ecrcod === 'CP0013-00-2') {
            return cp2010Route.NAME;
        } else if (data.ecrcod === 'CP0026-00-2') {
            // return cp0026Route.NAME;
        } else if (data.ecrcod === 'GL0003-00-2') {
            return gl0003Route.NAME;
        } else if (data.ecrcod === 'CR0007-00-2') {
            // return cr0007Route.NAME;
        } else if (data.ecrcod === 'CP0018-00-2') {
            // return cp0018Route.NAME;
        } else if (data.ecrcod === 'MC0109-00-2') {
             return mc0109Route.NAME;
        }

        return null;
    }

    function getParams(data: any): any {
        // Paramètre à envoyer calculé selon la nature du document
        let param: { [key: string]: any } = {};
        if (data.ecrcod === 'CP0013-00-2') {
            return { id: data.docaffcleint01 }; 
        } else if (data.ecrcod === 'GL0003-00-2') {
            return { id: data.doccleint };
        } else if (data.ecrcod === 'MC0109-00-2') {
            return { id: data.doccleint };
        } else if (data.cleintforage) {
            param[(data.cleintforage).toString()] = data.docaffcleint01;
        }

        return param;
    }
}
