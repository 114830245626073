import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageLs0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ls0001Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageLs0001 = this;

    vm.multiOccurrenceOptions = {

        colonnesVisibles: [
            { nom: 'lsxcod', largeur: 89 },
            { nom: 'lsxdsc', largeur: 315 },
            { nom: 'ttcdsc', largeur: 263 },
            { nom: 'lsxflgact', largeur: 91 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('lsxcod'),
            new FormulaireItem('lsxdsc'),
            new FormulaireItem('ttccleintdls', { nombreLibellesDetailsCacher: 1 }),
            new FormulaireItem('lsxflgact', { default: '1' })
        ])
    };
}