import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageCp1011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp1011Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageCp1011 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable: false,
            effaceCriteres: true,
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('concleintcri', {readonly: true})
        ]),
        colonnesVisibles: [
            {nom: 'datcreaff', largeur: 130},
            {nom: 'usrcod', largeur: 120},
            {nom: 'usrnom', largeur: 200},
            {nom: 'vaeconstudsc', largeur: 200}
        ]
    };
}
