import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0108 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0108Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0108 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'prucod'   , largeur: 130},
            {nom: 'prudscl01', largeur: 250},
            {nom: 'prscod'   , largeur: 130},
            {nom: 'prsdscl01', largeur: 250},
            {nom: 'clecod'   , largeur: 150},
            {nom: 'cledsc'   , largeur: 250}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('prucleint'),
            new FormulaireItem('prscleint'),
            new FormulaireItem('clecleint')
        ], {largeurDefaut: 33})
    };
}
