import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageAN0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function An0001Controller(Menu: IMenuClass) {
    const vm: IPageAN0001 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'scnnumdoc', largeur: 110},
            {nom: 'scnnumdocseq', largeur: 70},
            {nom: 'cvtcod', largeur: 110},
            {nom: 'cvtdsc', largeur: 260},
            {nom: 'scndatdeb', largeur: 120},
            {nom: 'scndatfin', largeur: 120},
            {nom: 'scndsc', largeur: 255}
        ]
    };
}
