import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageAC0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0017Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPageAC0017 = this;

    const ac0070Route = { NAME: 'secure.accueil' };

    const forage = { FORDAC: new MenuItemForage('LBL_FORAGE_DOCUMENT', ac0070Route.NAME, () => ({}), { fonction: 'BOUDAC' }) };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                }
            }),
            new FormulaireItem('etccleint'),
            new FormulaireItem('vecdortyprel', { required: false }),
            new FormulaireItemDivider('LBL_DATE_REL'),
            new FormulaireItem('datdeb', { largeur: 20 }),
            new FormulaireItem('datfin', { largeur: 20 })
        ]),
        colonnesVisibles: [
            { nom: 'dordat', largeur: 100 },
            { nom: 'dornot', largeur: 260 },
            { nom: 'flgcou', largeur: 100 },
            { nom: 'usrcod', largeur: 90 },
            { nom: 'usrnom', largeur: 262 },
            { nom: 'vecdortyprel_dsc', largeur: 160 },
            { nom: 'nbrseq', largeur: 90 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 260 },
            { nom: 'dacnumdoc', largeur: 200, menu: [forage.FORDAC] },
            { nom: 'dacdat', largeur: 150 },
            { nom: 'dacdatreq', largeur: 120 },
            { nom: 'etcnumdoc', largeur: 200 },
            { nom: 'etcnumdocseq', largeur: 200 },
            { nom: 'vaeetctyp_dsc', largeur: 200 }
        ]
    };
}
