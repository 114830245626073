import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAS0034 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function As0034Controller(Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    as0018Route: IRoute,
    al0005Route: IRoute,
    MenuItemForage: IMenuItemForageClass
    ) {

    const vm: IPageAS0034 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            libellesDynamiques: true,
            suiviModification: true,
            effaceCriteres: false,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            avisEmploye: true,
            supprime: false,
		    edition: false
        },

        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },

        navigatePage: as0018Route.NAME,
        navigateParams: (data: any) => ({
            eplnummat: data.eplnummat
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('asgcleint', { lovSelectionMultiple: true,
                largeur: 33
            }),
            new FormulaireItem('aeqcleint', {
                largeur: 33
            }),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('ptacleint', {
                largeur: 33,
                required: false,
                resourceParams: (data: any) => ({ asgcleint: data.asgcleint })
            }),

            new FormulaireItem('ptacleinta', {
                largeur: 33,
                required: false,
                resourceParams: (data: any) => ({ asgcleint: data.asgcleint })
            }),
            new FormulaireItem('radio', {
                largeur: 33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('actcleint', { lovSelectionMultiple: true,
                largeur: 33
            }),
            new FormulaireItem('taycleint', { lovSelectionMultiple: true,
                largeur: 33
            }),
            new FormulaireItem('athcleint', { lovSelectionMultiple: true,
                largeur: 33
            }),
            new FormulaireItem('unocleint', {
                largeur: 33
            }),
            new FormulaireItem('sel', { lovSelectionMultiple: true,
                largeur: 33
            }),
            new FormulaireItem('ttccleint', { lovSelectionMultiple: true,
                largeur: 33
            }),
        ]),

        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 80 },
            { nom: 'eplnomprn', largeur: 140 }
        ],
        colonnesVisibles: [
            { nom: 'qteath1', largeur: 100},
            { nom: 'qteath2', largeur: 100},
            { nom: 'qteath3', largeur: 100},
            { nom: 'qteath4', largeur: 100},
            { nom: 'qteath5', largeur: 100},
            { nom: 'qteath6', largeur: 100},
            { nom: 'qteath7', largeur: 100},
            { nom: 'qteath8', largeur: 100},
            { nom: 'qteath9', largeur: 100},
            { nom: 'qteath10', largeur: 100},

            { nom: 'unocod', largeur: 100 },
            { nom: 'unodscabr', largeur: 200 },
            { nom: 'flgremautaft', largeur: 80 },
            { nom: 'flgannu', largeur: 80 },
            { nom: 'ptacod', largeur: 100 },
            { nom: 'flgabslongduree', largeur: 100,
                menu: [new MenuItemForage(
                    'BTN_FOR_ALD',
                    al0005Route.NAME,
                    (rowData: any) => (
                        {
                            eplnummat: rowData.eplnummat
                        }
                    ),
                    {   fonction: 'BTN_FOR_ALD',
                        hidden: (data: any) => !data.flgabslongduree}
                )]}
        ],
        colonnesCachees: ['demcleint', 'unocleintrh', 'trncleint', 'usrcre'],
        colonnesFixesDroite: [
            { nom: 'nbratf', largeur: 70 },
            { nom: 'nbratfref', largeur: 70 },
            { nom: 'nbratffin', largeur: 70 }
        ]
    };
}
