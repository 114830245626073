import {IComponentOptions, module} from 'angular';
import Binding from '../../../../core/helpers/binding.helper';
import MenuemployeController from './ex-menu-employe.controller';

export default module('app.components.ex-menu-employe', []).component('exMenuEmploye', MenuEmployeComponent());

function MenuEmployeComponent(): IComponentOptions {
    return {
        bindings: Binding(),
        require: {
            monoOccurrenceEcranCtrl: '?^^exMonoOccurrenceEcran',
            multiOccurrenceAnalyseCtrl: '?^^exMultiOccurrenceAnalyse',
            multiOccurrenceMaitreDetailEcranCtrl: '?^^exMultiOccurrenceMaitreDetailEcran',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: MenuemployeController,
        controllerAs: 'vm',
        template: require('./ex-menu-employe.html')
    };
}
