import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0046 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface IPageMc0046Params {
    vaeusient: string,
    uvicleintref: number,
    doccleint: number
}

/* @ngInject */
export default function Mc0046Controller($stateParams: IPageMc0046Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0046 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'usidsc', largeur: 200},
            {nom: 'imp', largeur: 350}
        ],
        formulaire: new Formulaire('LBL_BLC_UVI', [
            new FormulaireItem('usicleint', {
                resourceParams: () => ({ vaeusient: $stateParams.vaeusient })
            }),
            new FormulaireItem('imp'),
            new FormulaireItem('uvicleintref', {default: $stateParams.uvicleintref}),
            new FormulaireItem('doccleint', {default: $stateParams.doccleint}),
            new FormulaireItem('intcleintreq', {default: vm.valeursEcran.reqcleint})
        ])
    };
}
