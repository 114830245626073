import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IController } from 'angular';

interface IPagePc2000 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pc2000Controller(pc0020Route:IRoute ,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPagePc2000 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pc0020Route.NAME,
        navigateParams:(data:any) => ({
                id: data.puqcleint
        }),
        actionsNouveaux: new Menu([
            new MenuItemForage(pc0020Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'puqcodreg', largeur: 60 },
            { nom: 'cincod', largeur: 60 },
            { nom: 'cindsc', largeur: 60 },
            { nom: 'fpncod', largeur: 60 },
            { nom: 'fpndsc', largeur: 60 }
        ]
    };
}
