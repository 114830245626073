import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IProfil } from '../../../../../core/resources/profil.resource';
import { IComponentController } from 'angular';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageMR2019 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    ovnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovnMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mr2019Controller(
    mr0176Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    profil: IProfil,
    Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    Pagination: IPaginationClass
) {
    const vm: IPageMR2019 = this;

        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            noId: true,
            id: profil.preferences.usrcleint
        };

        vm.ovnMultiOccurrenceOptions = {
            bloc: 'ovn',
            pagination: new Pagination({
                nombreElementPage: 6,
                nbElementsPossibles: [3, 6, 15]
            }),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    '',
                    mr0176Route.NAME,
                    (rowData: any) => ({
                        ovdcleintref: rowData.ovdcleintref,
                        stamnetab: 'CIE',
                        id: rowData.ovdcleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        fonction: 'BOUOUV_MR0176',
                        onClose() {
                            vm.ovnMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            fonctions: {
                pagination: true,
                rechercheCollapsable: false
            },
            colonnesVisibles: [
                { nom: 'requerant', largeur: 362 },
                { nom: 'ovddatvig', largeur: 100 },
                { nom: 'ovddatfin', largeur: 100 }
            ],
            colonnesCachees: ['ovdcleint', 'ovdcleintref', 'stamnetab', 'intcleint', 'ovdflgcommob']
        };
}
