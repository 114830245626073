import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0053Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0053 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'intcod', largeur: 125 },
            { nom: 'intnom', largeur: 215 },
            { nom: 'intflgche', largeur: 115 },
            { nom: 'intsrucod', largeur: 135 },
            { nom: 'etbcod', largeur: 155 },
            { nom: 'etbnom', largeur: 185 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcod', { readonly: true }),
            new FormulaireItem('intnom'),
            new FormulaireItem('intflgche', { default: 1 }),
            new FormulaireItem('intsrucod'),
            new FormulaireItem('etbcleint')
        ],
            {
                largeurDefaut: 20
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
