import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemSelectionClass } from "../../../../../core/services/menu/menu-item-selection.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFilterDate } from 'angular';

interface IPageRR2016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface Rr2016Params {
    demcleint: number;
    unpcleint: number;
    ppacleint: number;
}

/* @ngInject */
export default function Rr2016Controller(
    $stateParams: Rr2016Params,
    MenuItemSelection: IMenuItemSelectionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    dateFilter: IFilterDate) {

    const vm: IPageRR2016 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            effaceCriteres: true,
            rechercheCollapsable: false,
            pagination: true,
            supprime: false,
            edition: false
        },
        pagination: {
            nombreElementPage: 100
        },


        criteresSuggeres: new Formulaire([
            new FormulaireItem('adhdatdeb', { required: true, largeur: 100, default: dateFilter(new Date(), 'yyyy-MM-dd')}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('adhdatfin', { required: true, largeur: 100, default: dateFilter(new Date(), 'yyyy-MM-dd')}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('conadmdef', { required: true, largeur: 30}),
            ]),

        colonnesVisibles: [
            { nom: 'nas', largeur: 105 },
            { nom: 'matricule', largeur: 105 },
            { nom: 'nom', largeur: 105 },
            { nom: 'prenom', largeur: 105 },
            { nom: 'adresse1', largeur: 250 },
            { nom: 'adresse2', largeur: 200 },
            { nom: 'adresse3', largeur: 105 },
            { nom: 'pays', largeur: 105 },
            { nom: 'province', largeur: 105 },
            { nom: 'code_postal', largeur: 105 },
            { nom: 'tel_domicile', largeur: 120 },
            { nom: 'tel_cell', largeur: 120 },
            { nom: 'sexe', largeur: 105 },
            { nom: 'etat_civil', largeur: 105 },
            { nom: 'date_naissance', largeur: 105 },
            { nom: 'date_adhesion', largeur: 105 },
            { nom: 'courriel', largeur: 200 },
            { nom: 'courriel_personnel', largeur: 200 },
            { nom: 'etablissement', largeur: 120 },
            { nom: 'sous_etablissement', largeur: 120 },
            { nom: 'date_admissibilite', largeur: 120 },
            { nom: 'condition_admissibilite', largeur: 120 },
            { nom: 'date_embauche', largeur: 120 },
            { nom: 'statut_emploi_rruq', largeur: 120 },
            { nom: 'critere_reemb', largeur: 120 },
            { nom: 'autre_etablissement', largeur: 120 },
            { nom: 'commentaires', largeur: 300 }
        ],
    };
}
