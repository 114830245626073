import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {INotificationHandler} from '../../../../../core/services/utils/notification-handler.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageIN0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function In0012Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         notificationHandler: INotificationHandler
                                         ) {
    const vm: IPageIN0012 = this;
    vm.valeursEcran = ecranDetails.valeurs;


    if (vm.valeursEcran.mesmag) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesmag
        });
        vm.multiOccurrence.disabledRow = () => true;
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('flgfer',{ default: 1, required: true })
        ]),
        colonnesVisibles: [
            {nom: 'procod', largeur: 200},
            {nom: 'prodsc', largeur: 300},
            {nom: 'magcod', largeur: 90},
            {nom: 'magnom', largeur: 170},
            {nom: 'malcod', largeur: 110},
            {nom: 'maldsc', largeur: 300},
            {nom: 'fimcod', largeur: 200},
            {nom: 'st1cod', largeur: 140    , hidden: !vm.valeursEcran.flgst1},
            {nom: 'st1dscabrx', largeur: 250, hidden: !vm.valeursEcran.flgst1},
            {nom: 'st2cod', largeur: 140    , hidden: !vm.valeursEcran.flgst2},
            {nom: 'st2dscabrx', largeur: 250, hidden: !vm.valeursEcran.flgst2},
            {nom: 'st3cod', largeur: 140    , hidden: !vm.valeursEcran.flgst3},
            {nom: 'st3dscabrx', largeur: 250, hidden: !vm.valeursEcran.flgst3},
            {nom: 'st4cod', largeur: 140    , hidden: !vm.valeursEcran.flgst4},
            {nom: 'st4dscabrx', largeur: 250, hidden: !vm.valeursEcran.flgst4},
            {nom: 'st5cod', largeur: 140    , hidden: !vm.valeursEcran.flgst5},
            {nom: 'st5dscabrx', largeur: 250, hidden: !vm.valeursEcran.flgst5},
            {nom: 'prodatdeb', largeur: 120},
            {nom: 'prodatfin', largeur: 120},
            {nom: 'proqtemin', largeur: 120},
            {nom: 'proqtemax', largeur: 120},
            {nom: 'qteinv', largeur: 120},
            {nom: 'proisbn', largeur: 400}
        ]
    };
}
