import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageCr0239 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePej: IFormulaire;
    cesMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0239Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCr0239 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true,
            boutonEnregistrerHaut: ecranDetails.valeurs.flgenr
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_VALIDER',
                'BOUVAL',
                (data: any) => ({ lencleint: data.lencleint }),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    disabled: (data:any) => (data.vaelenstu != 'FERM'),     
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }                                   
                }
            ),
            new MenuItemAction(
                'LBL_BTN_FER',
                'BOUFER',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    disabled: (data: any) => !data.flgouve,
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }
            ),            
            new MenuItemAction(
                'LBL_BTN_ANN',
                'BOUANN',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    disabled: (data: any) => !data.flgouve
                }
            )
        ],
        formulaire: new Formulaire('LBL_BLC_LEN', [
            new FormulaireItem('lencod', { readonly: true, largeur: 25 }),
            new FormulaireItem('lendat', { readonly: true, largeur: 25 }),
            new FormulaireItem('lenstu', { readonly: true, largeur: 25 }),
            new FormulaireItem('lendsc', { readonly: (data: any) => !data.flgouve, largeur: 25 })
        ])
    };

    vm.cesMultiOccurrenceOptions = {
        fonctions: {
            supprime : ecranDetails.valeurs.flgenr
        },
        colonnesVisibles: [
            // Détails
            { nom: 'cesnumdoc', largeur: 130 },
            { nom: 'cldcod', largeur: 120 },
            { nom: 'cldnomprn', largeur: 120 },
            { nom: 'etycod', largeur: 120 },
            { nom: 'cesdsc', largeur: 200 },
            { nom: 'chqdat', largeur: 110 },
            { nom: 'cesmntcres', largeur: 110 },
            { nom: 'cesmntcrch', largeur: 110 },
            { nom: 'cesmntcrdb', largeur: 110 },
            { nom: 'cesmntcraut', largeur: 110 },
            { nom: 'cesmntcrct1', titre: ecranDetails.valeurs.titre1, largeur: 150, hidden: !ecranDetails.valeurs.flgmaicre1 },
            { nom: 'cesmntcrct2', titre: ecranDetails.valeurs.titre2, largeur: 150, hidden: !ecranDetails.valeurs.flgmaicre2 },
            { nom: 'cesmntcrct3', titre: ecranDetails.valeurs.titre3, largeur: 150, hidden: !ecranDetails.valeurs.flgmaicre3 },
            { nom: 'cesmntcrct4', titre: ecranDetails.valeurs.titre4, largeur: 150, hidden: !ecranDetails.valeurs.flgmaicre4 },
            { nom: 'cesmntcrct5', titre: ecranDetails.valeurs.titre5, largeur: 150, hidden: !ecranDetails.valeurs.flgmaicre5 },
            { nom: 'cesmnt', largeur: 110 }
        ]
    };
}
