import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';


interface IPageAl0003 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dliMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dlhMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dpiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireCriteres: IFormulaire;
}

/* @ngInject */
export default function Al0003Controller(
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    mr0176Route: IRoute,
    al2002Route: IRoute,
    al0004Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
) {
    const vm: IPageAl0003 = this;

    vm.formulairedLP = new Formulaire([
        new FormulaireItem('prfcleint', {
            largeur: 33,
            required: false,
            modifAllow: false
        }),
        new FormulaireItem('dlpseqmod', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('dlpdatdeb', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItem('dlpdatfin', {
            largeur: 33,
            required: false,
            disabled: true
        }),
        new FormulaireItem('dlpflgact', {
            largeur: 33,
            required: false
        }),
        new FormulaireGroupeAccordeon('Documentation', new Formulaire([
            new FormulaireItem('alddoc', {
                required: false,
                readonly:true,
                largeur: 100
            })
        ])
        )
    ]);
    //IMPUTATION
    vm.btnImp = [
        new MenuItemForage(
            'LBL_BTN_IMP',
            mr0176Route.NAME,
            (data: any) => ({
             ovdcleintref: vm.monoOccurrence.id,
                stamnetab: 'DLP'
            }),
            {
                icon: '',
                fonction: 'BOUIMP',
                iconButton: false,
                secondaire: true
            }
        )
    ];
    vm.dliMultiOccurrenceOptions = {
        bloc: 'dli',
        colonnesVisibles: [
            { nom: 'vaedlitypdatald', largeur: 50 },
            { nom: 'dlidatdeb', largeur: 50 },
            { nom: 'dlidatfin', largeur: 50 },
            { nom: 'nbrjrs', largeur: 50 },
            { nom: 'dlicom', largeur: 50 },
            { nom: 'dliseqmod', largeur: 50 },
            {
                nom: 'dliflgact', largeur: 50,
                menu: [
                    new MenuItemEcran(
                        'LBL_RESERVE',
                        al2002Route.NAME,
                        (Data: any) => ({

                        }),
                        {
                            fonction: 'RESERVES',
                            icon: 'launch',
                            iconButton: true,
                            disabled: (rowData: any) => (rowData.flghre === 0),
                            hidden: (rowData: any) => (rowData.flghre === 0),
                            optionsDialog: {
                                largeur: 100,
                                hauteur: 100
                            }
                        }
                    )
                ]
            }
        ]
    }

    vm.dlhMultiOccurrenceOptions = {
        bloc: 'dlh',
        navigatePage: al0004Route.NAME,
        navigateParams: (data: any) => ({
            id: data.dlhcleint,
            dlhcleint: data.dlhcleint,
            dlpcleint: data.$ancetre.dlpcleint,
            dalcleint: data.$ancetre.dalcleint

        }),
        actionsNouveaux: new Menu([
            new MenuItemForage(al0004Route.NAME,
                () => {
                    return {
                        prfcleint: vm.monoOccurrence.data.prfcleint,
                        dalcleint: vm.monoOccurrence.data.dalcleint,
                        dlpcleint: vm.monoOccurrence.data.dlpcleint
                    }
                })
        ]),        
        colonnesVisibles: [
            { nom: 'dlhdatvig'   , largeur: 50 },
            { nom: 'dlhpctrep'   , largeur: 50 },
            { nom: 'dlhcom'      , largeur: 50 },
            { nom: 'dlhtauhor'   , largeur: 50 },
            { nom: 'dlhseqmod'   , largeur: 50 },
            { nom: 'dlhflgact'   , largeur: 50 },
            { nom: 'dlhflgautftp', largeur: 50 }
        ],
        colonnesCachees: ['dlhcleint']
    };

    vm.dpiMultiOccurrenceOptions = {
        bloc: 'dpi',
        colonnesVisibles: [
            { nom: 'tagcod', largeur: 50 },
            { nom: 'tagdsc', largeur: 50 },
            { nom: 'dpidatvig', largeur: 50 },
            { nom: 'dpidatfin', largeur: 50 },
            { nom: 'dpiqte', largeur: 50 },
            { nom: 'dpimnt', largeur: 50 },
            { nom: 'dpiseqmod', largeur: 50 },
            { nom: 'dpiflgact', largeur: 50 }
        ]
    };
}
