import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mc0101',
    ecrans: ['00-2'],
    params: ['id',
             '?doccleintref',
             '?ajfcleint']
});

