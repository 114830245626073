import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr0309',
    ecrans: ['00-2'],
    params: ['ovncleintper',
             'ovdcleint',
             'rsccleint',
             'stamnetab',
             'ovddatvig',
             'flgmod',
             '?intcleint',
             '?ovdcleintref']
});
