import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForage, IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IStateParamsService} from "angular-ui-router";
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";

interface IPageIf0004 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireImm: IFormulaire;
    formulaireAutInf: IFormulaire;
    formulaireDispo: IFormulaire;
    iipMultiOccurrenceOptions: IMultiOccurrenceOptions;
    imrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    imqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eciMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ireMultiOccurrenceOptions: IMultiOccurrenceOptions;
    calculer: IMenuItem;
    btnMenus: Array<IMenuItemForage>;
}

/* @ngInject */
export default function If0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         //ac0070Route: IRoute,
                                         if0007Route: IRoute,
                                         if0008Route: IRoute,
                                         cp2010Route: IRoute,
                                         // cp0018Route: IRoute,
                                         // cp0026Route: IRoute,
                                         // cp0029Route: IRoute,
                                         cr0007Route: IRoute,
                                         // cr0021Route: IRoute,
                                         gl0003Route: IRoute,
                                         mc0109Route: IRoute,
                                         $stateParams: IStateParamsService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {

    // @TODO ajouter la route de forage une fois que l'écran est fait
    const ac0070Route = {NAME: 'secure.accueil'};
    const cp0014Route = {NAME: 'secure.accueil'};
    const cp0018Route = {NAME: 'secure.accueil'};
    const cp0026Route = {NAME: 'secure.accueil'};
    const cp0029Route = {NAME: 'secure.accueil'};
    const cr0021Route = {NAME: 'secure.accueil'};

    const vm: IPageIf0004 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = {...ecranDetails.valeurs};

        vm.monoOccurrenceOptions = {
            successActionSauvegarde: () => vm.monoOccurrence.fetchData()
        };
        vm.btnMenus = [
            new MenuItemAction(
                'BTN_CALCULER',
                'BOUCALCULER',
                () => ({}),
                {
                    icon: 'sync',
                    iconButton: false,
                    hidden: () => vm.monoOccurrence.data.vaeimmstu ==='D',
                    successAction: (data: any) => {
                        // Mise à jours des valeurs de Coût et d'amortissement après le Calcul
                        vm.valeursEcran.mntini = data.mntini;
                        vm.valeursEcran.mntina = data.mntina;
                        vm.valeursEcran.mntind = data.mntind;
                        vm.valeursEcran.tot_cout = data.tot_cout;
                        vm.valeursEcran.mntamo = data.mntamo;
                        vm.valeursEcran.mntama = data.mntama;
                        vm.valeursEcran.tot_amo = data.tot_amo;
                        vm.valeursEcran.val_net = data.val_net;
                        //vm.valeursEcran.der_per = data.der_per;
                        vm.valeursEcran.nbr_per = data.nbr_per;
                        vm.valeursEcran.val_nonamo = data.val_nonamo;
                        vm.valeursEcran.max_value = data.max_value;
                        vm.valeursEcran.btn_calcul = data.btn_calcul;

                        vm.monoOccurrence.fetchData();
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CALCULER',
                        lblConfirm: 'G_LBL_BTN_OK',
                        largeurFormulaire: 20,
                        formulaire: new Formulaire([
                            new FormulaireItem('peccleint', {
                                default: () => {
                                    return vm.valeursEcran.peccleint;
                                },
                                largeur: 75
                            })
                        ])
                    },
                    disabled: () => (
                        vm.monoOccurrence.data.vaeimmstu === 'T' ||
                        vm.monoOccurrence.data.vaeimmstu === 'D' ||
                        !vm.valeursEcran.btn_calcul
                    )
                }
            ),
            new MenuItemAction(
                'BTN_DISPOSER',
                'BOUDIS',
                () => ({}),
                {
                    icon: 'archive',
                    iconButton: false,
                    hidden: () => vm.monoOccurrence.data.vaeimmstu ==='D',
                    formulaireConfirmationAction: {
                        lblTitre: 'BTN_FORAGE_DISPOSITION',
                        lblConfirm: 'BTN_EXEC_DISPOSITION',
                        largeurFormulaire: 60,
                        formulaire: new Formulaire([
                            new FormulaireItem('vecdistypdis_def_dispo', { required: true }),
                            new FormulaireItem('t_disdat', { required: true }),
                            new FormulaireItem('t_dismnt', { required: true })
                        ], {largeurDefaut: 33.33})
                    },
                    successAction: () => { vm.monoOccurrence.fetchData() }
                }
            ),
            new MenuItemAction(
                'BTN_RENV_DISPOSITION',
                'BOURENDIS',
                () => ({}),
                {
                    icon: 'unarchive',
                    iconButton: false,
                    hidden: () => vm.monoOccurrence.data.vaeimmstu !=='D',
                    formulaireConfirmationAction: {
                        lblTitre: 'BTN_RENV_DISPOSITION',
                        lblConfirm: 'BTN_EXEC_RENV',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_datren', { required: true })
                        ], {largeurDefaut: 100})
                    },
                    successAction: () => {vm.monoOccurrence.fetchData();}
                }
            )

        ];
        // Forage vers l'écran IF0007 selon la mnémonique du champs
        let menuItemForageIf0007 = (mnemoniqueChamps: string, secFonction: string) => {
            return new MenuItemForage('BTN_FORAGE_' + mnemoniqueChamps,
                                      if0007Route.NAME,
                                      () => ({
                                          immcleint: vm.monoOccurrence.data.immcleint,
                                          immcod: vm.monoOccurrence.data.immcod,
                                          vaesysimmtyptrs: mnemoniqueChamps
                                      }),
                                      {
                                          fonction: secFonction
                                      });
        };

        const forageRef = new MenuItemForage(
            'LBL_FOR_REF',
            (data: any) => getForage(data),
            (data: any) => getParams(data),
            { fonction:'FORREF' }
        );

        vm.menus = {
            aut__mntrev: [
                menuItemForageIf0007('REV','FORREV')
            ],
            aut__mntgap: [
                menuItemForageIf0007('GAP','FORGAP')
            ],
            mntini: [
                menuItemForageIf0007('INI','FORINI')
            ],
            mntina: [
                menuItemForageIf0007('INA','FORINA')
            ],
            mntind: [
                menuItemForageIf0007('IND','FORIND')
            ],
            mntamo: [
                menuItemForageIf0007('AMO','FORAMO')
            ],
            mntama: [
                menuItemForageIf0007('AMA','FORAMA')
            ]
        };

        const forageIf0008 = new MenuItemForage(
            'BTN_FORAGE_IF8',
            if0008Route.NAME,
            (rowData: any) => ({id: rowData.doccleint2}),
            { fonction:'FORMON' }
        ) ;
                // Formulaire principal (Immobilisation)
        vm.formulaireImm = new Formulaire('LBL_BLC_IMM', [
            new FormulaireItem('immcod', {
                largeur: 22.22
            }),
            new FormulaireItem('immdsc', {
                largeur: 44.44
            }),
            new FormulaireItem('vaeimmstu', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('immdsclng', {
                largeur: 66.66
            }),
            new FormulaireItem('vaeimmprv'),
            new FormulaireItem('catcleint', {largeur: 50}),
            new FormulaireItem('cafcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vecimmtypacq', {largeur: 50}),
            new FormulaireItem('vecimmtypimm', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('immnbrperamo'),
            new FormulaireItem('immdatacq'),
            new FormulaireItem('immdatser'),
            new FormulaireItem('vecimmloc'),
            new FormulaireItem('iopcleint'),
            new FormulaireItem('immvalres'),
            new FormulaireGroupeAccordeon('LBL_BLC_AUT_INF', new Formulaire([
                new FormulaireItem('immannfab'),
                new FormulaireItem('immmqe'),
                new FormulaireItem('immmdl'),
                new FormulaireItem('immnumser'),
                new FormulaireItem('immnumeti'),
                new FormulaireItem('immcappds'),
                new FormulaireItem('unmcleint'),
                new FormulaireItem('immqtetot'),
                new FormulaireItem('immdte'),
                new FormulaireItem('immcom', {largeur: 100, enableCount: true})
            ], {largeurDefaut: 33.33 }
            ),
                {hidden: (data: any) => !data.$valeurs.affautinf}
            )
        ], {
            largeurDefaut: 33.33
        });

        // Répartition des montants
        vm.imrMultiOccurrenceOptions = {
            bloc: 'imr',
            fonctions: {
                editionRapide: true
            },
            editionRapideActive: true,
            colonnesVisibles: [
                {nom: 'imrcle', largeur: 61},
                {nom: 'imp', largeur: 223},
                {nom: 'imrprcrep', largeur: 74}
            ],
            colonnesEditionRapide: [
                {nom: 'imrcle', largeur: 70},
                {nom: 'imp', largeur: 233},
                {nom: 'imrprcrep', largeur: 90}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('imrcle', {
                    largeur: 20
                }),
                new FormulaireItem('imp', {
                    largeur: 100
                    // !! il va manquer le forage vers mc0044
                }),
                new FormulaireItem('imrprcrep', {
                    largeur: 20
                })
            ])
        };

        // UBR
        vm.iipMultiOccurrenceOptions = {
            bloc: 'iip',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'prucod', largeur: 120},
                {nom: 'prudsc', largeur: 120},
            ],
            formulaire: new Formulaire([
                new FormulaireItem('prucleint', {largeur: 100})
            ])
        };

        // Disposition
        vm.formulaireDispo = new Formulaire('LBL_BLC_DISPO', [
            new FormulaireItem('aut__disdat'),
            new FormulaireItem('aut__vecdistypdis'),
            new FormulaireItem('aut__mntrev'),
            new FormulaireItem('aut__mntgap')
        ]);

        // Référence interne
        vm.ireMultiOccurrenceOptions = {
            bloc: 'ire',
            colonnesVisibles: [
                {nom: 'docaffnumdoc01', largeur: 160, menu: [forageRef]},
                {nom: 'tydcod'        , largeur: 160},
                {nom: 'tyddsc'        , largeur: 200},
                {nom: 'docaffmnt01'   , largeur: 140},
                {nom: 'sldref'        , largeur: 140}
            ],
            colonnesCachees: [
                'ecrcod',
                'doccleintref'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('doccleintref', {largeur: 100})
            ])
        };

        // Quantité par période
        vm.imqMultiOccurrenceOptions = {
            bloc: 'imq',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'peccod', largeur: 120},
                {nom: 'imqqteamo', largeur: 120}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('peccleint', {largeur: 75}),
                new FormulaireItem('imqqteamo', {largeur: 50})
            ])
        };

        // Historique des transactions
        vm.htsMultiOccurrenceOptions = {
            bloc: 'hts',
            colonnesFixesGauche: [
                {nom: 'docaffdat01', largeur: 110},
                {nom: 'tydcod', largeur: 80},
                {nom: 'docaffnumdoc01', largeur: 140, menu: [forageRef]}
            ],
            colonnesVisibles: [
                {nom: 'docrefcod02', largeur: 90},
                {nom: 'docrefnom02', largeur: 225},
                {nom: 'reqnumref', largeur: 130},
                {nom: 'peccod', largeur: 90},
                {nom: 'imp', largeur: 225},
                {nom: 'docaffdsc01', largeur: 225}
            ],
            colonnesFixesDroite: [
                {nom: 'itdmnt', largeur: 160, menu: [forageIf0008]}
            ],
            colonnesCachees: [
                'erccod',
                'cleintforage',
                'docaffcleint01',
                'doccleint2'
            ]
        };

        // Écriture d'immobilisation
        vm.eciMultiOccurrenceOptions = {
            navigatePage: if0008Route.NAME,
            navigateParams: (data: any) => ({
                id: data.doccleint
            }),
            resourceParams: {
                immcleint: $stateParams.id,
                vaesysimmtyptrs: 'INI'
            },
            actionsNouveaux: new Menu([]),
            colonnesFixesGauche: [
                {nom: 'ecinumdoc', largeur: 120},
                {nom: 'ecinumdocseq', largeur: 100},
                {nom: 'peccod', largeur: 100}
            ],
            colonnesVisibles: [
                {nom: 'tydcod', largeur: 100},
                {nom: 'vececicat', largeur: 100},
                {nom: 'immcod', largeur: 130},
                {nom: 'ecidsc', largeur: 250},
                {nom: 'intcod', largeur: 100},
                {nom: 'intnom', largeur: 200},
                {nom: 'etddsc', largeur: 150},
                {nom: 'etddatcre', largeur: 175},
                {nom: 'flgcou', largeur: 100},
                {nom: 'eciflgref', largeur: 100},
                {nom: 'eciflgverfin', largeur: 120},
                {nom: 'eciflgmsg', largeur: 100},
                {nom: 'flgadhoc', largeur: 120},
                {nom: 'intcodcib', largeur: 120},
                {nom: 'intnomcib', largeur: 200},
                {nom: 'usrcod', largeur: 140},
                {nom: 'eciflgautrep', largeur: 100},
                {nom: 'eciflgpij', largeur: 140}
            ],
            colonnesFixesDroite: [
                {nom: 'montant', largeur: 130}
            ]
        };

        function getForage(data: any): string {
            switch (data.erccod) {
                case 'AC0070-00-2': return ac0070Route.NAME;
                case 'CP0013-00-2': return cp2010Route.NAME;
                case 'CP0014-00-2': return cp0014Route.NAME;
                case 'CP0018-00-2': return cp0018Route.NAME;
                case 'CP0026-00-2': return cp0026Route.NAME;
                case 'CP0029-00-2': return cp0029Route.NAME;
                case 'CR0007-00-2': return cr0007Route.NAME;
                case 'CR0021-00-2': return cr0021Route.NAME;
                case 'GL0003-00-2': return gl0003Route.NAME;
                case 'MC0109-00-2': return mc0109Route.NAME;
                default: return null;
            }
        }

        function getParams(data: any): any {
            switch (data.erccod) {
                case 'CP0013-00-2': return { id: data.docaffcleint01 };
                default: return { id: data.doccleint };
            }
        }

    }
}
