import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageRr0537 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    chiMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0537Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForage: IMenuItemForageClass,
    rr0503Route: IRoute,
    rr0006Route: IRoute,
    rr0074Route: IRoute) {

    const vm: IPageRr0537 = this;

    const foragePam = new MenuItemForage(
        'LBL_BTN_PAM',
        rr0503Route.NAME,
        (rowData: any) => {
            return {
                id: rowData.pamcleint
            };
        },
        { 
            hidden: (rowData:any) => !(rowData.pamcleint),
            fonction: 'BTN_PAM'
        }
    );

    const forageAft = new MenuItemForage(
        'LBL_BTN_AFT',
        rr0006Route.NAME,
        (rowData: any) => {
            return {
                id: rowData.trncleintaft
            };
        },
        { 
            hidden: (rowData:any) => !(rowData.trncleintaft),
            fonction: 'BTN_AFT'
        }
    );

    const forageDer = new MenuItemForage(
        'LBL_BTN_DER',
        rr0074Route.NAME,
        (rowData: any) => {
            return {
                id: rowData.trncleintder
            };
        },
        { 
            hidden: (rowData:any) => !(rowData.trncleintder),
            fonction: 'BTN_DER'
        }
    );

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('demcleintcri'),
            new FormulaireItem('urgcleintcri'),
            new FormulaireItem('tagcleintcri'),
            new FormulaireItem('unpcleintcri'),
            new FormulaireItem('vactagcatcri'),
            new FormulaireItem('regroupcri', { required: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rvlppatypcri', { required: true, default: '0' }),
            new FormulaireItem('ppacleintdebcri', { required: true }),
            new FormulaireItem('ppacleintfincri', { required: true })
        ], { largeurDefaut: 33 }),
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 120 },
            { nom: 'pamnumdoc', largeur: 150, menu: [foragePam] },
            { nom: 'tagcod', largeur: 120 },
            { nom: 'patdatdeb', largeur: 120 },
            { nom: 'patdatfin', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'ppacod_ori', largeur: 100 },
            { nom: 'ppacod_cal', largeur: 100 },
            { nom: 'ppacod_tra', largeur: 100 },
            { nom: 'qte_agg', largeur: 126 },
            { nom: 'mnt_agg', largeur: 126 },
            { nom: 'temcod', largeur: 120 },
            { nom: 'aftcod', largeur: 150, menu: [forageAft] },
            { nom: 'dernumdoc', largeur: 150, menu: [forageDer] },
            { nom: 'strcod', largeur: 120 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'tagdscabr', largeur: 250 },
            { nom: 'temdsc', largeur: 250 },
            { nom: 'aftdsc', largeur: 250 },
            { nom: 'urgdsc', largeur: 250 },
            { nom: 'unocod', largeur: 120 },
            { nom: 'pamflgoff', largeur: 100 },
            { nom: 'patflganc', largeur: 100 },
            { nom: 'vaepadtyp_dsc', largeur: 120 },
            { nom: 'vaepamprv_dsc', largeur: 150 },
            { nom: 'tafcod', largeur: 120 },
            { nom: 'tafdsc', largeur: 250 }
        ],
        colonnesCachees: [
            'pamcleint',
            'trncleintaft',
            'trncleintder'
        ]
    };
}