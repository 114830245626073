import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0104 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0104Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0104 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'inscod', largeur: 162},
            {nom: 'insdsc', largeur: 262},
            {nom: 'succod', largeur: 100}
        ],
        colonnesVisibles: [            
            {nom: 'sucdsc', largeur: 262},
            {nom: 'sucadrl01', largeur: 236},
            {nom: 'sucadrl02', largeur: 236},
            {nom: 'sucvil', largeur: 236},
            {nom: 'sucpro', largeur: 157},
            {nom: 'sucpay', largeur: 157},
            {nom: 'succodpos', largeur: 140}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('inscleint', {largeur: 40}),
            new FormulaireItem('succod', {largeur: 20}),
            new FormulaireItem('sucdsc', {largeur: 40}),
            new FormulaireItem('sucadrl01', {largeur: 50}),
            new FormulaireItem('sucadrl02', {largeur: 50}),
            new FormulaireItem('sucvil', {largeur: 25}),
            new FormulaireItem('sucpro', {largeur: 25}),
            new FormulaireItem('sucpay', {largeur: 25}),
            new FormulaireItem('succodpos', {largeur: 25})
        ])
    };
}
