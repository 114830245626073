import IStateService = angular.ui.IStateService;
import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IFormulaireGroupeAccordeonClass} from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageDO0001 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireOff: IFormulaire;
    formulaireEmp: IFormulaire;
    formulaireAff: IFormulaire;
    formulaireRsp: IFormulaire;
    formulaireCan: IFormulaire;
    formulaireFon: IFormulaire;
    corMultiOccurrence: IMultiOccurrence;
    tchMultiOccurrenceOptions: IMultiOccurrenceOptions;
    corMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pubMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ocrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fpjMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cvcMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IDo0001Params {
    id: string;
    menuId: string;
    offcleint: number;
}

/* @ngInject */
export default function Do0001Controller(co0009Route: IRoute,
                                         do0015Route: IRoute,
                                         do0001Route: IRoute,
                                         do0093Route: IRoute,
                                         //do0040Route: IRoute,
                                         //do0030Route: IRoute,
                                         //do0031Route: IRoute,
                                         //do0088Route: IRoute,
                                         //rr0074Route: IRoute,
                                         $stateParams: IDo0001Params,
                                         $state: IStateService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass) {
    const vm: IPageDO0001 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            confirmationSauvegarde: {
                lblTitre: 'LBL_TITRE_MODALE',
                icon: 'content_copy',
                lblMessageInfo: 'LBL_MESSAGE_COPIE',
                largeur: 40,
                saveOnCancel: true,
                conditionAffichage: (monoOccurrence: IMonoOccurrence, data: any) => {
                    return (
                        (
                            !$stateParams.id ||
                            (
                                monoOccurrence.data.temcleint !== data.temcleint ||
                                monoOccurrence.data.rglcleint !== data.rglcleint ||
                                monoOccurrence.data.poscleint !== data.poscleint
                            )
                        ) &&
                        (
                            data.vaerglcopfon !== null ||
                            data.vaerglcoptch !== null ||
                            data.vaerglcopcor !== null ||
                            data.vaerglcopcrx !== null
                        )
                    )
                },
                formulaire: new Formulaire(
                    'LBL_TITRE_COPIE',
                    [
                        new FormulaireItem('flgcopfon', {
                            default: (data: any) => data.t_flgcopfon,
                            hidden: (data: any) => data.vaerglcopfon === null,
                            disabled: (data: any) => !data.modcopfon
                        }),
                        new FormulaireItem('flgcoptch', {
                            default: (data: any) => data.t_flgcoptch,
                            hidden: (data: any) => data.vaerglcoptch === null,
                            disabled: (data: any) => !data.modcoptch
                        }),
                        new FormulaireItem('flgcopcor', {
                            default: (data: any) => data.t_flgcopcor,
                            hidden: (data: any) => data.vaerglcopcor === null,
                            disabled: (data: any) => !data.modcopcor
                        }),
                        new FormulaireItem('flgcopcrx', {
                            default: (data: any) => data.t_flgcopcrx,
                            hidden: (data: any) => data.vaerglcopcrx === null,
                            disabled: (data: any) => !data.modcopcrx
                        })
                    ], {
                        largeurDefaut: 100
                    })
            },
            menus: [
                new MenuItemForage(
                    'G_LBL_ANALYSE_CANDIDATURE',
                    do0015Route.NAME,
                    (data: any) => ({ecran: '01-2', offcleint: $stateParams.offcleint}),
                    {
                        icon: 'folder_shared',
                        iconButton: false,
                        hidden: (data: any) => (!$stateParams.offcleint)
                    }
                ),
                new MenuItemForage(
                    'G_LBL_SUIVI_CANDIDATURE',
                    do0093Route.NAME,
                    (data: any) => ({ecran: '01-2', offcleint: $stateParams.offcleint}),
                    {
                        icon: 'contact_phone',
                        iconButton: false,
                        hidden: (data: any) => (!$stateParams.offcleint)
                    }
                ),
                /*new MenuItem(
                    'G_LBL_APERCU',
                    getPageLink,
                    {
                        icon: 'visibility',
                        iconButton: false,
                        link: true,
                        hidden: (data: any) => (!$stateParams.offcleint)
                    }
                )*/
            ],
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_COPIER',
                    'BOUCOP',
                    () => ({}),
                    {
                        icon: 'content_copy',
                        saveFirst: true,
                        successAction: (data: any) => {
                            $state.go(do0001Route.NAME, {
                                id: data.trncleintnew,
                                menuId: $stateParams.menuId,
                                offcleint: data.offcleintnew
                            });
                        },
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_BTN_COPIER',
                            lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire([
                                new FormulaireItem('ttccleintnew', {
                                    required: true,
                                    nombreLibellesDetailsCacher: 1
                                })
                            ], {
                                largeurDefaut: 100,
                                securityCycle: false
                            })
                        }
                    }
                )
            ])
        };

        vm.formulaireOff = new Formulaire('LBL_OFFRE_EMPLOI', [
            new FormulaireItem('offnumdoc'),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS', new Formulaire([
                new FormulaireItem('vaeofftyp'),
                new FormulaireItem('ttccleint', {
                    nombreLibellesDetailsCacher: 1
                }),

                new FormulaireItemDivider('LBL_ORIGINE'),
                new FormulaireItem('vaeoffori'),
                new FormulaireItem('offcleintref'),
// Remplacer lorsque les écrans mono seront faits
                /*                new FormulaireItem('offcleintref', {
                                    menu: [
                                        new MenuItemForage(
                                            do0040Route.NAME,
                                            (data: any) => ({
                                                trncleint: data.trncleint_ref
                                            }),
                                            {hidden: (data: any) => data.vaeoffori !== 'DRP'}
                                        ),
                                        new MenuItemForage(
                                            do0030Route.NAME,
                                            (data: any) => ({
                                                trncleint: data.trncleint_ref
                                            }),
                                            {hidden: (data: any) => data.vaeoffori !== 'DMP'}
                                        ),
                                        new MenuItemForage(
                                            do0031Route.NAME,
                                            (data: any) => ({
                                                trncleint: data.trncleint_ref
                                            }),
                                            {hidden: (data: any) => data.vaeoffori !== 'DRM'}
                                        ),
                                        new MenuItemForage(
                                            rr0074Route.NAME,
                                            (data: any) => ({
                                                trncleint: data.trncleint_ref
                                            }),
                                            {hidden: (data: any) => data.vaeoffori !== 'DER'}
                                        ),
                                        new MenuItemForage(
                                            do0088Route.NAME,
                                            (data: any) => ({
                                                trncleint: data.trncleint_ref
                                            }),
                                            {hidden: (data: any) => data.vaeoffori !== 'DPX'}
                                        )
                                    ]
                                }), */
            ])),
        ]);

        vm.formulaireEmp = new Formulaire('LBL_EMPLOI', [
            new FormulaireItem('urgcleint'),
            new FormulaireItem('unocleint', {
                resourceParams: (data:any) => {
                    return {
                        offdatdebaff: data.offdatdebaff,
                        offdatfinaff: data.offdatfinaff
                    };
                }
            }),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('poscleint'),
            new FormulaireItem('poscleintsup'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temcleint'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('strcleint'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('offdsc'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('litcleint'),

            new FormulaireItemDivider('LBL_PERIODE_EMPLOI'),
            new FormulaireItem('offdatdebemp'),
            new FormulaireItem('offdatfinemp'),
        ]);

        vm.formulaireAff = new Formulaire('LBL_AFFICHAGE', [
            new FormulaireItem('gafcleint'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('offdatdebaff'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('offdatfinaff'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('offflgaffint',{largeur: 25}),
            new FormulaireItem('offflgaffext',{largeur: 25}),
            new FormulaireItem('offflgaffoff',{largeur: 25}),
            new FormulaireItem('offflgaffetu',{largeur: 25}),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_DOTATION', new Formulaire([
                new FormulaireItem('rglcleint'),
            ])),
        ]);

        vm.formulaireRsp = new Formulaire('LBL_RESPONSABLE', [
            new FormulaireItem('intcleintrh'),
            new FormulaireItem('offadrcou', {historiqueColonne: true}),
        ]);

        vm.formulaireCan = new Formulaire('LBL_DEMANDER_CANDIDAT', [
            new FormulaireItem('qstcleint',{largeur: 50}),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('offflgesc1'),
            new FormulaireItem('offflgesc2'),
            new FormulaireItem('offflgesc3'),
            new FormulaireItem('offflgesc4'),
            new FormulaireItem('offflgesc5'),
        ]);

        // Fonction
        vm.formulaireFon = new Formulaire('LBL_INFO_ADD', [
            new FormulaireItemDivider('LBL_FONCTION'),
            new FormulaireItem('offdscfnc'),
            new FormulaireItemDivider('LBL_CLASSIFICATION'),
            new FormulaireItem('offdscclatra'),
            new FormulaireItemDivider('LBL_REGIME_HORAIRE'),
            new FormulaireItem('offdscreghor'),
            new FormulaireItemDivider('LBL_FIN_AFFICHAGE'),
            new FormulaireItem('offdscfinaff'),
        ], {
            largeurDefaut: 100,
            menu: new Menu([
                new MenuItemRecuperation({
                    lblTitre: 'LBL_RECUP_FNC',
                    nom: 'recuperation_fnc',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItem('offcleint_fnc', {default: ecranDetails.valeurs.offcleint_fnc}),
                        new FormulaireItem('poscleint_fnc', {default: ecranDetails.valeurs.poscleint_fnc}),
                        new FormulaireItem('temcleint_fnc', {default: ecranDetails.valeurs.temcleint_fnc})
                    ]),
                    colonnesVisibles: [
                        {nom: 'fncdsc', largeur: 500}
                    ]
                })
            ])
        });

        // Tâches
        vm.tchMultiOccurrenceOptions = {
            bloc: 'tch',
            fonctions: {
                pagination: true
            },
            actionsMore: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_tch',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItem('offcleint_tch', {default: ecranDetails.valeurs.offcleint_tch}),
                        new FormulaireItem('poscleint_tch', {default: ecranDetails.valeurs.poscleint_tch}),
                        new FormulaireItem('temcleint_tch', {default: ecranDetails.valeurs.temcleint_tch})
                    ]),
                    colonnesVisibles: [
                        {nom: 'gradsc', largeur: 300},
                        {nom: 'tchseqaff', largeur: 120},
                        {nom: 'tchdsc', largeur: 360}
                    ]
                })
            ]),
            colonnesVisibles: [
                {nom: 'gracod', largeur: 131},
                {nom: 'gradsc', largeur: 300},
                {nom: 'tchseqaff', largeur: 120},
                {nom: 'tchdsc', largeur: 360},
                {nom: 'tchflgpub', largeur: 91},
            ],
            formulaire: new Formulaire([
                new FormulaireItem('gracleint', {
                    largeur: 50
                }),
                new FormulaireItem('tchseqaff', {
                    largeur: 50
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tchdsc', {
                    largeur: 100
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tchflgpub')
            ])
        };

        // Compétences requises
        vm.corMultiOccurrenceOptions = {
            bloc: 'cor',
            fonctions: {
                pagination: true
            },
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    () => ({
                        stamnetab: 'OFF',
                        corcleintref: vm.monoOccurrence.data.offcleint
                    }),
                    {
                        onClose() {
                            vm.corMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    (data: any) => ({
                        id: data.corcleint,
                        stamnetab: 'OFF',
                        corcleintref: vm.monoOccurrence.data.offcleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true
                    }
                )
            ]),
            actionsMore: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_cor',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItem('offcleint_cor', {default: ecranDetails.valeurs.offcleint_cor}),
                        new FormulaireItem('poscleint_cor', {default: ecranDetails.valeurs.poscleint_cor}),
                        new FormulaireItem('temcleint_cor', {default: ecranDetails.valeurs.temcleint_cor}),
                        new FormulaireItem('urgcleint_cor', {default: ecranDetails.valeurs.urgcleint_cor})
                    ]),
                    colonnesVisibles: [
                        {nom: 'grcdsc', largeur: 300},
                        {nom: 'corseqaff', largeur: 300},
                        {nom: 'comcod', largeur: 91},
                        {nom: 'comdsc', largeur: 300},
                        {nom: 'corflgpae', largeur: 91}
                    ]
                })
            ]),
            colonnesVisibles: [
                {nom: 'grcdsc', largeur: 300},
                {nom: 'corseqaff', largeur: 120},
                {nom: 'corflgidecan', largeur: 91},
                {nom: 'comdsc', largeur: 300},
                {nom: 'vacdsc', largeur: 131},
                {nom: 'corfacimp', largeur: 100},
            ],
            formulaire: new Formulaire([
                new FormulaireItem('grccleint', {
                    largeur: 50
                }),
                new FormulaireItem('corseqaff', {
                    largeur: 50
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('comcleint', {
                    largeur: 50
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaccorobl', {
                    largeur: 50
                }),
                new FormulaireItem('corfacimp', {
                    largeur: 50
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('nivcleintcib', {
                    largeur: 50,
                    resourceParams: (data:any) => {
                        return {
                            tyccleint: data.tyccleint
                        };
                    }
                }),
                new FormulaireItem('corvalcib', {
                    largeur: 50
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('corcom', {
                    largeur: 100
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cordscweb', {
                    largeur: 100
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('corexp', {
                    largeur: 100
                }),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('corflgaffweb'),
                new FormulaireItem('corflgidecan'),
                new FormulaireItem('corflgpae'),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('corflgobl'),
                new FormulaireItem('corflgncoepl')
            ], {
                largeurDefaut: 33.33
            })
        };

        // Publication
        vm.pubMultiOccurrenceOptions = {
            bloc: 'pub',
            fonctions: {
                pagination: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'mdidsc', largeur: 131},
                {nom: 'pubsec', largeur: 131},
                {nom: 'pubdatpar', largeur: 328},
                {nom: 'pubcom', largeur: 225}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('mdicleint'),
                new FormulaireItem('pubsec'),
                new FormulaireItem('pubdatpar'),
                new FormulaireItem('pubcom')
            ])
        };

        // Alerte-emploi
        vm.ocrMultiOccurrenceOptions = {
            bloc: 'ocr',
            fonctions: {
                pagination: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'crxcod', largeur: 131},
                {nom: 'crxdsc', largeur: 131},
                {nom: 'gcrcod', largeur: 131},
                {nom: 'gcrdsc', largeur: 328}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('crxcleint'),
                new FormulaireItem('gcrcoddsc')
            ]),
            actionsMore: new Menu([
                new MenuItemRecuperation({
                    nom: 'recuperation_crx',
                    criteresSuggeres: new Formulaire([
                        new FormulaireItem('offcleint_crx', {default: ecranDetails.valeurs.offcleint_crx}),
                        new FormulaireItem('poscleint_crx', {default: ecranDetails.valeurs.poscleint_crx}),
                        new FormulaireItem('temcleint_crx', {default: ecranDetails.valeurs.temcleint_crx})
                    ]),
                    colonnesVisibles: [
                        {nom: 'crxcod', largeur: 500},
                        {nom: 'crxdsc', largeur: 500}
                    ]
                })
            ])
        };

        // Pièces jointes
        vm.fpjMultiOccurrenceOptions = {
            bloc: 'fpj',
            fonctions: {
                pagination: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'dpjcod', largeur: 131},
                {nom: 'dpjdsc', largeur: 425}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('dpjcleint')
            ])
        };

        // Convocation
        vm.cvcMultiOccurrenceOptions = {
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'G_LBL_SUIVI_CANDIDATURE',
                    do0093Route.NAME,
                    (data: any) => ({ecran: '01-2', offcleint: $stateParams.offcleint, ctpcleint: data.cvcctpcleintsel, cvccleint: data.cvccleint}),
                    {
                        icon: 'contact_phone',
                        iconButton: false,
                        hidden: (data: any) => (!$stateParams.offcleint)
                    }
                )
            ]),
            fonctions: {
                pagination: true
            },
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'cvcdsc', largeur: 131},
                {nom: 'cvcplg', largeur: 131},
                {nom: 'cvclie', largeur: 131},
                {nom: 'ctpdscabr', largeur: 328}
            ],
            colonnesCachees: [
                'cvcctpcleintsel',
                'cvccleint'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cvcdsc'),
                new FormulaireItem('cvcplg'),
                new FormulaireItem('cvclie'),

                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cvcctpcleintsel', {
                    resourceParams: (data:any) => {
                        return {
                            cytcleint: vm.monoOccurrence.data.cytcleint
                        };
                    }
                })
            ])
        }
    }
    /*
        function getPageLink(data: any) {
            return $state.href(`http://localhost:3000/sofe-punch/portail-employe/menu/59588/do0302-00-2/${$stateParams.offcleint}`);
        }
    */
}
