import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {INotificationHandler} from '../../../../../core/services/utils/notification-handler.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageIn0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0005Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         notificationHandler: INotificationHandler) {
    const vm: IPageIn0005 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    if (vm.valeursEcran.mesina) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesina
        });
    }

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'st1cod', largeur: 90},
            {nom: 'st2cod', largeur: 90},
            {nom: 'st3cod', largeur: 110},
            {nom: 'st4cod', largeur: 90},
            {nom: 'st4dsc', largeur: 231}
        ],
        colonnesVisibles: [
            {nom: 'st4dscabr', largeur: 231},
            {nom: 'st1dscabrx', largeur: 231},
            {nom: 'st2dscabrx', largeur: 231},
            {nom: 'st3dscabrx', largeur: 252}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('st1coddsc', {readonly: true}),
            new FormulaireItem('st2coddsc', {readonly: true}),
            new FormulaireItem('st3cleint'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('st4cod'),
            new FormulaireItem('st4dsc'),
            new FormulaireItem('st4dscabr'),

        ], {largeurDefaut: 33.33})
    };
}
