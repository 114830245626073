import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageDO0075 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0075Controller(Menu: IMenuClass) {
    const vm: IPageDO0075 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'grgcod', largeur: 90},
            {nom: 'grgdscabr', largeur: 262},
            {nom: 'vaegrgtyptridsc', largeur: 136},
            {nom: 'grgflginsobl', largeur: 76},
            {nom: 'grgflgdespos', largeur: 91}
        ]
    };
}
