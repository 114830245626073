import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageZz0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}
/* @ngInject */
export default function Zz0007Controller(zz0003Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageZz0007 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: zz0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                zlicleint: rowData.zlicleint
            };
        },
        colonnesFixesGauche: [
            { nom: 'nbrerr', largeur: 100 },
            { nom: 'zliexc', largeur: 100 },
            { nom: 'ccmcod', largeur: 150 },
            { nom: 'ccmchgcod', largeur: 150 },
            { nom: 'ccmdat', largeur: 130 },
            { nom: 'ccmmnt', largeur: 130 },
            { nom: 'ccmcodref1', largeur: 150 }
        ],
        colonnesVisibles: [
            { nom: 'ccmcodref2', largeur: 150 },
            { nom: 'ccmcodref3', largeur: 150 },
            { nom: 'ccmcodref4', largeur: 150 },
            { nom: 'ccmcodref5', largeur: 150 },
            { nom: 'ccmcodref6', largeur: 150 },
            { nom: 'ccmcodref7', largeur: 150 },
            { nom: 'ccmcodref8', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('zliexc', { largeur: 20 }),
            new FormulaireItem('ccmcod', { largeur: 20 }),
            new FormulaireItem('ccmchgcod', { largeur: 20 }),
            new FormulaireItem('ccmdat', { largeur: 20 }),
            new FormulaireItem('ccmmnt', { largeur: 20 }),
            new FormulaireItem('ccmcodref1', { largeur: 25 }),
            new FormulaireItem('ccmcodref2', { largeur: 25 }),
            new FormulaireItem('ccmcodref3', { largeur: 25 }),
            new FormulaireItem('ccmcodref4', { largeur: 25 }),
            new FormulaireItem('ccmcodref5', { largeur: 25 }),
            new FormulaireItem('ccmcodref6', { largeur: 25 }),
            new FormulaireItem('ccmcodref7', { largeur: 25 }),
            new FormulaireItem('ccmcodref8', { largeur: 25 })
        ])
    };
}
