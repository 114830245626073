import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'rr0129',
    ecrans: ['00-2'],
    params: ['?demcleint',
        '?flgperfect',
        '?ppadatdeb',
        '?ppadatfin',
        '?bqecleint']
});
