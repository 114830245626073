import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'ac0006',
    ecrans: [
        '00-2',
        '01-2'
    ]
});

