import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0002{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0002Controller(Menu: IMenuClass,
                                         mr0003Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0002 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0003Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0003Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'placod', largeur: 90},
            {nom: 'pladsc', largeur: 262},
            {nom: 'planumref', largeur: 184},
            {nom: 'pladatann', largeur: 100},
            {nom: 'plaagelimsal', largeur: 65},
            {nom: 'vaeplaagereg_dsc', largeur: 178},
            {nom: 'plaflgautfac', largeur: 100},
            {nom: 'plaflgcptbqe', largeur: 91},
            {nom: 'planumref2', largeur: 225},
            {nom: 'planumref3', largeur: 225},
            {nom: 'planumref4', largeur: 225},
            {nom: 'plaflgsaiidt', largeur: 100},
            {nom: 'plalblidt', largeur: 168},
            {nom: 'plaflgact', largeur: 65}
        ]
    };
}
