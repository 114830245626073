import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageGB0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gb0009Controller(
    Formulaire: IFormulaireClass,
    $stateParams: any,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGB0009 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        filtresParametres: [
            { colonne: 'gebcleint', valeur: $stateParams.gebcleint, visible: false }
        ],
        colonnesVisibles: [
            { nom: 'hvmdatvig', largeur: 100 },
            { nom: 'hvmmntval', largeur: 130 },
            { nom: 'hvmcom', largeur: 250 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem("hvmdatvig", { required: true,  largeur: 25 }),
            new FormulaireItem("hvmmntval", { largeur: 25 }),
            new FormulaireItem("hvmcom"),
            new FormulaireItem("gebcleint", { default: $stateParams.gebcleint, hidden: true })
        ])
    }
}
