import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageDo0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0012Controller() {
    const vm: IPageDo0012 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'grgcod', largeur: 150},
            {nom: 'grgdscabr', largeur: 150},
            {nom: 'vaegrgtyp', largeur: 150},
            {nom: 'vaegrgtyptri', largeur: 150},
            {nom: 'grgflginsobl', largeur: 150},
            {nom: 'grgflgdespos', largeur: 150}
            ]
    };
}
