import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import {IComponentController} from "angular";
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemAction, IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";

interface IPageTf0004 extends IComponentController {    
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireEmf: IFormulaire;
    emdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menuItemGenererFormulaire: IMenuItemAction;
    menuItemAnnulerFormulaire: IMenuItemAction;
    menuItemModifierFormulaire:  IMenuItemAction;
}

/* @ngInject */
export default function Tf0004Controller(Formulaire: IFormulaireClass,
                                        FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                        ecranDetails: IEcranDetailsResourcesEntite,
                                        FormulaireItem: IFormulaireItemClass,
                                        tf2001Route: IRoute,                                        
                                        MenuItemAction: IMenuItemActionClass,  
                                        FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
                                        MenuItemActionTelechargement: IMenuItemActionTelechargementClass
                                         ) {
    const vm: IPageTf0004 = this;

        vm.formulaireEmf = new Formulaire('', [
            new FormulaireItem('efacleint', {largeur: 66}),
            new FormulaireItem('emfnumrel'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('folcleint', {largeur: 66}),
            new FormulaireItem('emfnumrelxml'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('folann',{ default: ecranDetails.valeurs.folann }),
            new FormulaireItem('vaeemftyp', { default: ecranDetails.valeurs.vaeemftyp}),
            new FormulaireItem('emfnumrelori',{ default: ecranDetails.valeurs.emfnumrelori }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('emfcleintref', {largeur: 66 }), 
            new FormulaireItem('emfnumrelref',{ default: ecranDetails.valeurs.emfnumrelref }),
            new FormulaireItemSautDeLigne(),            
            new FormulaireItem('vaesyscodprv', {largeur: 30 }),
            new FormulaireItem('emfcptficfor',{ largeur: 20, default: ecranDetails.valeurs.emfcptficfor }),
            new FormulaireItemSautDeLigne(), 
            new FormulaireItem('flgimppj',{ largeur: 20, default: ecranDetails.valeurs.flgimppj }),
            new FormulaireItemMenuItem(new MenuItemActionTelechargement(
                'LBL_BTN_BOUPJ',
                'BOUPJ',
                () => ({}),
                {
                    iconButton: true,
                    class: "md-raised ex-button-secondary",
                    icon: 'attach_file',
                    disabled: !ecranDetails.valeurs.flgimppj
                }
            )), 
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('emfflgimpemr',{ largeur: 20}),
            new FormulaireItem('emfflgimp',{ largeur: 20, disabled: !ecranDetails.valeurs.flgactemfflgimp}),             
            new FormulaireItem('emfindlu',{ largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('flgemfact', {default: ecranDetails.valeurs.flgemfact}),
            new FormulaireItem('emfindgen', {default: 0}), 
            new FormulaireItem('emfseqtyp', {default: 0}),
            new FormulaireItem('emfcptfic')                      
        ], {largeurDefaut: 33},);

        vm.emdMultiOccurrenceOptions = {
            bloc: 'emd',
            fonctions: {
                supprime: ecranDetails.valeurs.flgactblocemd,
                edition: ecranDetails.valeurs.flgactblocemd,
                nouveau: ecranDetails.valeurs.flgactblocemd                
            },
            navigatePage: tf2001Route.NAME,
            colonnesFixesGauche: [
                {nom: 'foacod', largeur: 80},
                {nom: 'foadsc', largeur: 250}  
            ],
            colonnesVisibles: [               
                {nom: 'emdmnt', largeur: 20},
                {nom: 'emdmntcal', largeur: 100},
                {nom: 'emdtxt', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('foacleint', {
                    largeur: 50, resourceParams: () => {
                        return {
                            folcleint: vm.monoOccurrence.data.folcleint
                        }
                    }
                }),
                new FormulaireItem('emdtxt'),
                new FormulaireItem('fovcod'),
                new FormulaireItem('vaefoalis'),
            ])
        };

        vm.menuItemGenererFormulaire = new MenuItemAction(
            'LBL_BTN_GEN_FOR',
            'BOUGENFOR',
            () => ({}),
            {
                iconButton: false,
                icon: '',
                saveFirst: true,
                messageConfirmation: 'BOUGENFOR_MESSAGE',
                successAction: () => { vm.monoOccurrence.fetchData();                                       
                },
                disabled: (rowData: any) => !(rowData.emfcleint)
            }
        );

        vm.menuItemAnnulerFormulaire = new MenuItemAction(
            'LBL_BTN_ANU_FOR',
            'BOUANUFOR',
            () => ({}),
            {
                iconButton: false,
                icon: '',
                saveFirst: true,
                messageConfirmation: 'BOUANUFOR_MESSAGE',
                successAction: () => { vm.monoOccurrence.fetchData();                               
                },
                disabled: (rowData: any) => !(rowData.emfcleint)
            }
        );
        
        vm.menuItemModifierFormulaire = new MenuItemAction(
            'LBL_BTN_MOD_FOR',
            'BOUMODFOR',
            () => ({}),
            {
                iconButton: false,
                icon: '',
                saveFirst: true,
                messageConfirmation: 'BOUMODFOR_MESSAGE',
                successAction: () => { vm.monoOccurrence.fetchData();                                    
                },
                disabled: (rowData: any) => !(rowData.emfcleint)
            }
        );

}