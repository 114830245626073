import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {
    IFormulaire,
    IFormulaireClass
} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IComponentController} from 'angular';

interface IPageCP0035 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    formulaireFoc: IFormulaire;
    fcaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface ICp0035Params {
    id: string;
    refcleint: string;
}

/* @ngInject */
export default function Cp0035Controller($stateParams: ICp0035Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass)  {

    const vm: IPageCP0035 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
        };

        // Carte d'achat
        vm.formulaireFoc = new Formulaire('LBL_FOC', [
            new FormulaireItem('refcleint', {default: $stateParams.refcleint}),
            new FormulaireItem('focnom'),
            new FormulaireItem('focdatexp'),
            new FormulaireItem('intcleint'),
            new FormulaireItem('focflgstu', {default: 0} )
        ], {largeurDefaut: 25});

        // Carte
        vm.fcaMultiOccurrenceOptions = {
            bloc: 'fca',
            fonctions: {
                pagination: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('fcanum'),
                new FormulaireItem('fcanumimp'),
                new FormulaireItem('fcaflgstu', {default: 0}),
                new FormulaireItem('fcanomdet'),
                new FormulaireItem('intcleint'),
            ], {largeurDefaut: 33.33}),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                {nom: 'fcanum', largeur: 140},
                {nom: 'fcanumimp', largeur: 200},
                {nom: 'fcanomdet', largeur: 220},
                {nom: 'intcod', largeur: 110},
                {nom: 'intnom', largeur: 220},
                {nom: 'fcaflgstu', largeur: 91}
            ]
        };
    }
}
