import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";

interface IPageDo0021 extends IComponentController {
    valeursEcran: any;
    formulaireTem: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    menuItemAssurer: IMenuItem;
    menuItemBeneficiaire: IMenuItem;

}

/* @ngInject */
export default function do0037Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageDo0021 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;
       
        vm.formulaireTem = new Formulaire('', [
            new FormulaireItem('temcod', {largeur: 25, readonly: true}),
            new FormulaireItem('temdscabr', {largeur: 50, readonly: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('temdscfon', {largeur: 50 })]);        
    }
}
