import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMenuItemFactoryClass } from '../../../../../core/services/menu/menu-item-factory.service';

interface IPageRc0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}


/* @ngInject */
export default function Rc0012Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    MenuItemFactory: IMenuItemFactoryClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageRc0012 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('refcleint', { required: false, largeur: 110 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('tydcleint', { required: false, largeur: 110 }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('intcleintreq', { required: false, largeur: 110 }),

                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('txmnumref', { required: false, largeur: 110 }),
                    new FormulaireItem('lipcleint', { required: false, largeur: 110 })                    
                ]), { largeur: 30 }
            ),
            new FormulaireGroupe('',
                new Formulaire([
                    new FormulaireItem('docref', {
                        largeur: 100,
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            tydcleint: data.tydcleint
                        })
                    }),
                    new FormulaireItem('sysboncmdctr', {
                        largeur: 100,
                        resourceParams: (data: any) => ({
                            refcleint: data.refcleint,
                            tydcleint: data.tydcleint
                        })
                    }),                    
                    new FormulaireItem('indrupcri'),                    
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('indrupreccri')
                ]), { largeur: 30 }),                
            new FormulaireGroupe('',
                new Formulaire([
                    
                    new FormulaireItem('grpeta', {
                        direction: 'vertical',            
                        default: '-1',
                        required: true
                    }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('grppij', {
                        largeur: 100,
                        default: 2,
                        required: true
                    }),                    
                    new FormulaireItem('datdeb',{largeur: 30}),  
                    new FormulaireItem('datfin',{largeur: 30}),
                ], {largeurDefaut: 40}), { largeur: 40 }
            ),
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]), 
        colonnesCachees: [
            'ecrcodd',
            'ecrcodt',
            'daccleint',
            'txmcleint',
            'doccleintref'
        ],
        colonnesFixesGauche: [
            { nom: 'txmnumdoc', largeur: 100 },
            { nom: 'txmnumdocseq', largeur: 60 }
        ],      
        colonnesVisibles: [
            { nom: 'tydcod', largeur: 100 },
            { nom: 'txmdat', largeur: 110 },
            { nom: 'dacnumdoc', largeur: 140 },            
            { nom: 'sysboncmd', largeur: 146,
                menu: [
                    new MenuItemForage(
                        '',
                        (data: any) => `secure.${data.ecrcodd}.ecran`,
                        (data: any) => ({
                            id: data.daccleint,
                            indfor: 1
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },            
            { nom: 'refcod', largeur: 130 },
            { nom: 'refnom', largeur: 180 },
            { nom: 'txmnumref', largeur: 150 },
            { nom: 'etddsc', largeur: 130 },
            { nom: 'peccod', largeur: 120 },
            { nom: 'intcodreq', largeur: 110 },
            { nom: 'tyddsc', largeur: 190 },
            { nom: 'intnomreq', largeur: 180 },
            { nom: 'lipcod', largeur: 140 },
            { nom: 'lipnom', largeur: 300 },
            { nom: 'txmnomtra', largeur: 160 },
            { nom: 'txmnumemb', largeur: 160 },
            { nom: 'txmnbrcolrec', largeur: 160 },
            { nom: 'txmnbrcolliv', largeur: 160 },
            { nom: 'txmflgpij', largeur: 160 },
            { nom: 'txmflgrup', largeur: 100 },
            { nom: 'txmflgruprec', largeur: 100 }        ],
        colonnesFixesDroite: [
            { nom: 'complet', largeur: 100 },
            { nom: 'txmflgmsg', largeur: 100 }
        ],
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcodt}.ecran`),
            (data: any) => ({
                id: data.doccleintref,
                indfor: 1
            })
        )
    }
}
