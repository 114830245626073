import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IComponentController} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IMc0144Params {
    tsfcleint: number;
}
interface IPageMc0144 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    ecranDetails: IEcranDetailsResourcesEntite,
    formulaireDfb: IFormulaire;
}

/* @ngInject */
export default function Mc0144Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         $stateParams: IMc0144Params) {
    const vm: IPageMc0144 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.formulaireDfb = new Formulaire('LBL_BLC_DFB', [
            new FormulaireItem('tsfcleint', {default: $stateParams.tsfcleint}),
            new FormulaireGroupe('LBL_INS', new Formulaire([
                new FormulaireItem('dfbintins'),
                new FormulaireItem('dfbtrsins'),
                new FormulaireItem('dfbintadrl01'),
                new FormulaireItem('dfbintadrl02'),
                new FormulaireItem('dfbintvil'),
                new FormulaireItem('dfbintpro'),
                new FormulaireItem('dfbintpay'),
                new FormulaireItem('dfbintzip'),
                new FormulaireItem('dfbinttel')
            ], { largeurDefaut: 100 }), { largeur: 50 }),
            new FormulaireGroupe('LBL_BEN', new Formulaire([
                new FormulaireItem('dfbnomben'),
                new FormulaireItem('dfbcptben'),
                new FormulaireItem('dfbadrl01ben'),
                new FormulaireItem('dfbadrl02ben'),
                new FormulaireItem('dfbvilben'),
                new FormulaireItem('dfbproben'),
                new FormulaireItem('dfbpayben'),
                new FormulaireItem('dfbzipben'),
                new FormulaireItem('dfbnumtelben')
            ], { largeurDefaut: 100 }), { largeur: 50 }),
            new FormulaireGroupe('LBL_DON', new Formulaire([
                new FormulaireItem('dfbintbic'),
                new FormulaireItem('dfbintaut', {enableCount: true}),
                new FormulaireItem('dfbintiba')
            ], { largeurDefaut: 50}), { largeur: 100 })
        ]);
    }
}
