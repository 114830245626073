import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0153 extends IComponentController {
    formulairePhl: IFormulaire;
    formulaireMenEmp: IFormulaire;
    formulairePasEmp: IFormulaire;
    formulaireParEmb: IFormulaire;
    formulaireZonMsg: IFormulaire;
    formulaireZonMesFinLem: IFormulaire;
    phrmultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr0153Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0153 = this;

    const mr0012Route = {NAME: 'secure.accueil'};

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        id: ecranDetails.valeurs.phlcleint
    };

    //Employé
    vm.formulairePhl = new Formulaire('LBL_BLC_PHL', [
        //Référence 1
        new FormulaireItemDivider('LBL_REF1'),
        new FormulaireItem('phlflgsai1'),
        new FormulaireItem('phlflgobl1'),
        new FormulaireItem('phlflgmaj1'),
        new FormulaireItem('revref1', { default: vm.valeursEcran.revref1, readonly: true }),
        new FormulaireItem('phlflguni1'),
        new FormulaireItem('revlib1', { default: vm.valeursEcran.revlib1, readonly: true }),
        //Référence 2
        new FormulaireItemDivider('LBL_REF2'),
        new FormulaireItem('phlflgsai2'),
        new FormulaireItem('phlflgobl2'),
        new FormulaireItem('phlflgmaj2'),
        new FormulaireItem('revref2', { default: vm.valeursEcran.revref2, readonly: true }),
        new FormulaireItem('phlflguni2'),
        new FormulaireItem('revlib2', { default: vm.valeursEcran.revlib2, readonly: true }),
        //Référence 3
        new FormulaireItemDivider('LBL_REF3'),
        new FormulaireItem('phlflgsai3'),
        new FormulaireItem('phlflgobl3'),
        new FormulaireItem('phlflgmaj3'),
        new FormulaireItem('revref3', { default: vm.valeursEcran.revref3, readonly: true }),
        new FormulaireItem('phlflguni3'),
        new FormulaireItem('revlib3', { default: vm.valeursEcran.revlib3, readonly: true }),
        //Référence 4
        new FormulaireItemDivider('LBL_REF4'),
        new FormulaireItem('phlflgsai4'),
        new FormulaireItem('phlflgobl4'),
        new FormulaireItem('phlflgmaj4'),
        new FormulaireItem('revref4', { default: vm.valeursEcran.revref4, readonly: true }),
        new FormulaireItem('phlflguni4'),
        new FormulaireItem('revlib4', { default: vm.valeursEcran.revlib4, readonly: true }),
        //Libellé onglet
        new FormulaireItemDivider('LBL_LIB_ONG'),
        new FormulaireItem('phllibong1', { largeur: 33.33 }),
        new FormulaireItem('phllibong2', { largeur: 33.33 }),
        new FormulaireItem('phllibong3', { largeur: 33.33,
            menu: [
                new MenuItemForage(
                    'LBL_BTN_MR0012',
                    mr0012Route.NAME, 
                    () => ({}), 
                    {
                        fonction: 'BTN_MR0012',
                        iconButton: false,
                        secondaire: true
                    }
                )
            ]
        }),
        // Information probation
        new FormulaireItemDivider('LBL_LIB_PRO'),
        new FormulaireItem('vaephlgespro', {largeur: 33.33})
    ], { largeurDefaut: 12 });

    //Menu employé
    vm.formulaireMenEmp = new Formulaire('LBL_MEN_EMP', [
        new FormulaireItemDivider('LBL_DOSEMP'),
        new FormulaireItem('stscleintcen'),
        new FormulaireItem('stscleintdec')
    ], { largeurDefaut: 100 });

    //Zone de message
    vm.formulaireZonMsg = new Formulaire('LBL_ZON_MSG', [
        new FormulaireItem('phlnbrjrsetu', { largeur: 100 }),
        new FormulaireItem('phlflgcouint'),
        new FormulaireItem('phlflgunorh')
    ]);

    vm.formulaireZonMesFinLem = new Formulaire('LBL_ZON_MES_FINLEM', [
        new FormulaireItem('phlphrlibzon', {largeur: 50}),
        new FormulaireTemplateSlot('regleFinLem')
    ]);

    //Passerelle employé
    vm.formulairePasEmp = new Formulaire('LBL_PAS_EMP', [
        new FormulaireItem('ttccleintepl'),
        new FormulaireItem('ttccleintlem'),
        new FormulaireItem('ttccleintlemdeb'),
        new FormulaireItem('ttccleintlemfin'),
        new FormulaireItem('ttccleinttsa'),
        new FormulaireItem('tlecleint'),
    ], { largeurDefaut: 100 });

    //Paramètre d'embauche
    vm.formulaireParEmb = new Formulaire('LBL_PAR_EMB', [
        new FormulaireItem('phltitraplig1'),
        new FormulaireItem('phltitraplig2')
    ], { largeurDefaut: 100 });

    vm.phrmultiOccurrenceOptions = {
        bloc: 'phr',
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 130 },
            {nom: 'urgdsc', largeur: 220 },
            {nom: 'phrflgaffurg', largeur: 130 },
            {nom: 'strcod', largeur: 130 },
            {nom: 'strdsc', largeur: 220 },
            {nom: 'phrflgaffstr', largeur: 130 },
            {nom: 'vactsmrai_cod', largeur: 100 },
            {nom: 'vactsmrai_dsc', largeur: 200 },
            {nom: 'phrflgaffrai', largeur: 100 },
            {nom: 'phrgroupe', largeur: 100 },
            {nom: 'phrpriorite', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcleint'),
            new FormulaireItem('phrflgaffurg'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('phrflgaffstr'),
            new FormulaireItem('vactsmrai'),
            new FormulaireItem('phrflgaffrai'),
            new FormulaireItem('phrgroupe'),
            new FormulaireItem('phrpriorite')
        ])
    }
}
