import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemEcranClass } from "../../../../../core/services/menu/menu-item-ecran.service";
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageCP0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cp0019Controller(Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    MenuItemForage: IMenuItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    cp2010Route: IRoute
) {
    const vm: IPageCP0019 = this;

    const cp0014Route = {NAME: 'secure.accueil'};
    const cp0018Route = {NAME: 'secure.accueil'};
    
    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },
        navigatePage: (rowData) => getForageItem(rowData),
        navigateParams: (rowData) => getParamItem(rowData),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('tydcleintcri'),
            new FormulaireItem('peccleintcri'),
            new FormulaireItem('cifcodselsup'),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        colonnesVisibles: [
            { nom: 'reqnumdoc', largeur: 150 },
            { nom: 'reqnumdocseq', largeur: 60 },
            { nom: 'tydcod', largeur: 63 },
            { nom: 'refcod', largeur: 130 },
            { nom: 'refnomabr', largeur: 160 },
            { nom: 'radcod', largeur: 120 },
            { nom: 'reqnumref', largeur: 180 },
            { nom: 'reqnumphy', largeur: 150, },
            { nom: 'peccod', largeur: 80 },
            { nom: 'reqdat', largeur: 120 },
            { nom: 'etddsc', largeur: 170 },
            { nom: 'reqflgmsg', largeur: 100 },
            { nom: 'flgencours', largeur: 100 },
            { nom: 'flgdu', largeur: 65 },
            { nom: 'nbrajf', largeur: 100 },
            { nom: 'lcpcod', largeur: 65 },
            { nom: 'lcpdsc', largeur: 150 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'reqmnt', largeur: 126 },
            { nom: 'dccmntpay', largeur: 126 },
            { nom: 'mnt_comp', largeur: 150 },
            { nom: 'intcodreq', largeur: 102 },
            { nom: 'intnomreq', largeur: 262 },
            { nom: 'intcoddem', largeur: 105 },
            { nom: 'intnomdem', largeur: 262 },
            { nom: 'pdfcod', largeur: 120 },
            { nom: 'prjdscabr', largeur: 220 },
            { nom: 'vecreqcat', largeur: 100 },
            { nom: 'reqnumrescd', largeur: 163 },
            { nom: 'reqnumresca', largeur: 163 },
            { nom: 'reqdsc', largeur: 236 },
            { nom: 'usrcod', largeur: 110 }
        ],
        colonnesCachees: ['ecrcod']
    };

    function getForageItem(rowData: any): string {
        if (rowData.ecrcod === 'CP0013') {
            return cp2010Route.NAME;
        }
        else if (rowData.ecrcod === 'CP0018') {
            return cp0018Route.NAME;
        }        
        else if (rowData.ecrcod === 'CP0014') {
            return cp0014Route.NAME;
        }        
    };

    function getParamItem(rowData: any) {
        if (rowData.ecrcod === 'CP0013') {
            return { id: rowData.reqcleint };
        }
    }
}
