import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mc0061',
    ecrans: ['00-2'],
    params: [
        '?ecjcleint',
        '?fewcleint',
        '?fxmcleint'
            ]
});
