import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageRe2007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Re2007Controller(Formulaire: IFormulaireClass,
                                         $stateParams: any,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRe2007 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },        
        colonnesVisibles: [
            {nom: 'ppacod', largeur: 120},
            {nom: 'bqecod', largeur: 120},
            {nom: 'bqedscabr', largeur: 220},
            {nom: 'dbscod1', largeur: 120},
            {nom: 'dbscod2', largeur: 120},
            {nom: 'vaesystrsbqe_dsc', largeur: 120},
            {nom: 'rdbqte', largeur: 120},
            {nom: 'rdbflgexc', largeur: 120}
        ],colonnesCachees: [
            'rslcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ppacod'   , {largeur: 10, readonly: true }),
            new FormulaireItem('bqecod'   , {largeur: 10, readonly: true}),
            new FormulaireItem('bqedscabr'   , {largeur: 20, readonly: true}),
            new FormulaireItem('dbscod1'   , {largeur: 10, readonly: true}),
            new FormulaireItem('dbscod2'   , {largeur: 10, readonly: true}),
            new FormulaireItem('vaesystrsbqe_dsc'   , {largeur: 20, readonly: true}),
            new FormulaireItem('rdbqte'   , {largeur: 10, readonly: true}),
            new FormulaireItem('rdbflgexc'   , {largeur: 10, readonly: $stateParams.vaertostu === 'TER' || $stateParams.vaertostu === 'ANN'})
        ])
        
    };
}
