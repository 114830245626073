import { IComponentController } from 'angular';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageCr0041 extends IComponentController {
    formulaireEnn: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    eniMultiOccurrenceOptions: IMultiOccurrenceOptions;
    enmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    nndMultiOccurrenceOptions: IMultiOccurrenceOptions;
    crnMultiOccurrenceOptions: IMultiOccurrenceOptions;
    enhMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rnfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cchMultiOccurrenceOptions: IMultiOccurrenceOptions;
}


/* @ngInject */
export default function Cr0041Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    cr0007Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    exDateFilter: IFilterExDate) {
    const vm: IPageCr0041 = this;

    vm.formulaireEnn = new Formulaire(' ', [
        new FormulaireItem('ennnumdoc', { largeur: 25 }),
        new FormulaireItem('tydcleint', { largeur: 50 }),
        new FormulaireItem('enndat', { default: exDateFilter(new Date()) }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('intcleint', { largeur: 50, readonly: (data) => (ecranDetails.valeurs.flgint && !data.tydcleint) }),
        new FormulaireItem('enntau', { largeur: 25 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('enncmt')
    ], { largeurDefaut: 75 }
    );


    // Entité externes
    vm.eniMultiOccurrenceOptions = {
        bloc: 'eni',
        colonnesVisibles: [
            { nom: 'refcod', largeur: 100 },
            { nom: 'refnom', largeur: 210 },
            { nom: 'enitau', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'CLI'
                },
                largeur: 50
            }),
            new FormulaireItem('enncleint', { hidden: true, default: () => vm.monoOccurrence.data.enncleint }),
            new FormulaireItem('enitau')
        ], { largeurDefaut: 25 }),
        colonnesCachees: [
            'enncleint'
        ]
    };

    // Trimestres
    vm.enmMultiOccurrenceOptions = {
        bloc: 'enm',
        colonnesVisibles: [
            { nom: 'trmcod', largeur: 100 },
            { nom: 'trmann', largeur: 100 },
            { nom: 'trmdsc', largeur: 200 },
            { nom: 'trmdatdeb', largeur: 100 },
            { nom: 'trmdatfin', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('trmcleint'),
            new FormulaireItem('enncleint', { hidden: true, default: () => vm.monoOccurrence.data.enncleint })
        ], { largeurDefaut: 45 })
    };

    // Clients détail
    vm.nndMultiOccurrenceOptions = {
        bloc: 'nnd',
        colonnesVisibles: [
            { nom: 'cldcod', largeur: 100 },
            { nom: 'cldnom', largeur: 160 },
            { nom: 'cldprn', largeur: 170 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cldcleint'),
            new FormulaireItem('enncleint', { hidden: true, default: () => vm.monoOccurrence.data.enncleint })
        ], { largeurDefaut: 45 })
    };

    // Regroupement
    vm.crnMultiOccurrenceOptions = {
        bloc: 'crn',
        colonnesVisibles: [
            { nom: 'crgcod', largeur: 100 },
            { nom: 'crgdsc', largeur: 180 },
            { nom: 'crntau', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('crgcleint'),
            new FormulaireItem('crntau', { largeur: 25 }),
            new FormulaireItem('enncleint', { hidden: true, default: () => vm.monoOccurrence.data.enncleint })
        ], { largeurDefaut: 50 })
    };

    // Charges
    vm.enhMultiOccurrenceOptions = {
        bloc: 'enh',
        colonnesVisibles: [
            { nom: 'chgcod', largeur: 100 },
            { nom: 'chgdsc', largeur: 180 },
            { nom: 'enhtau', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('chgcleint'),
            new FormulaireItem('enhtau', { largeur: 25 }),
            new FormulaireItem('enncleint', { hidden: true, default: () => vm.monoOccurrence.data.enncleint })
        ], { largeurDefaut: 50 })
    };

    // Factures
    vm.rnfMultiOccurrenceOptions = {
        bloc: 'rnf',
        colonnesVisibles: [
            { nom: 'facnumdoc', largeur: 130 },
            {
                nom: 'facnumdocseq', largeur: 110, menu: [
                    new MenuItemForage(
                        'G_LBL_FONC_TRANS_DOCUMENT',
                        cr0007Route.NAME,
                        (rowData: any) => {
                            return {
                                id: rowData.doccleint
                            };
                        }
                    )
                ]
            },
            { nom: 'sysfacnum', largeur: 130 },
            { nom: 'tydcod', largeur: 110 },
            { nom: 'refcod', largeur: 130 },
            { nom: 'refnom', largeur: 170 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('faccleint'),
            new FormulaireItem('enncleint', { hidden: true, default: () => vm.monoOccurrence.data.enncleint })
        ], { largeurDefaut: 60 })
    };

    // Charges générées
    vm.cchMultiOccurrenceOptions = {
        bloc: 'cch',
        colonnesFixesGauche: [
            { nom: 'cchdat', largeur: 100 },
            { nom: 'cldcod', largeur: 110 }
        ],
        colonnesVisibles: [
            { nom: 'cldnom', largeur: 200 },
            { nom: 'cldprn', largeur: 200 },
            { nom: 'chgcod', largeur: 110 },
            { nom: 'chgdsc', largeur: 180 },
            { nom: 'cchmnt', largeur: 110 }
        ]
    };
}
