import { IController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemSautDeColonneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';

interface IPageCr0013 extends IController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    enfMultiOccurrenceOptions: IMultiOccurrenceOptions;
    chqMultiOccurrenceOptions: IMultiOccurrenceOptions;
    evtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    enaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    memMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface ICr0013Params {
    loecleint: string;
    id: number;
    indfor: number;
}

/* @ngInject */
export default function Cr0013Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    cr2002Route: IRoute,
    mc0102Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    exDateFilter: IFilterExDate,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: ICr0013Params,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageCr0013 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        cycleErrorReloadBlocs: ['evt'],
        infosFinancieres: {
            disponibilite: true,
            impactFinancier: true,
            impactBudgetaire: true
        },
        menus: [
            new MenuItemForage(
                'G_LBL_COR_IMP',
                mc0102Route.NAME,
                (data: any) => ({ doccleintref: data.doccleint }),
                {
                    icon: 'settings',
                    compteur: (data: any) => data.aut__nbrcorimp > 0 ? data.aut__nbrcorimp : undefined,
                    iconButton: false,
                    fonction: 'BOUCORIMP',
                    disabled: (data: any) => !data.$id || ecranDetails.valeurs.flgre
                }
            ),
            new MenuItemForage(
                'LBL_DEPOT',
                `secure.${vm.valeursEcran.ecrcod}.ecran`,
                () => ({ id: vm.valeursEcran.docaffcleint01 }),
                {
                    icon: '',
                    iconButton: false,
                    hidden: !vm.valeursEcran.docaffcleint01
                }
            )
        ],
        fonctions: {
            nouveau: ecranDetails.valeurs.flgiud,
            modifier: ecranDetails.valeurs.flgiud
        },
        infosMonetaires: {
            blocs: ['enf', 'evt', 'ena'],
            largeurTexte: 150,
            donnees:
                [
                    { nom: 'enf', libelle: 'LBL_MNT_ENF' },
                    { nom: 'evt', libelle: 'LBL_MNT_EVT' },
                    { nom: 'ena', libelle: 'LBL_MNT_ENA' },
                    { nom: 'sld', libelle: 'LBL_MNT_SLD' }
                ]
        },        
        formulaire: new Formulaire('LBL_BLC_ENC', [
            new FormulaireItem('encnumdoc', { readonly: true }),
            new FormulaireItem('encnumdocseq', { readonly: true }),
            new FormulaireItem('encflgpna', { required: true }),
            new FormulaireItem('tydcleint', { readonly: true, hidden: false, default: ecranDetails.valeurs.tydcleint }),
            new FormulaireItem('tydcod', { hidden: true, readonly: true, default: ecranDetails.valeurs.tydcod }),
            new FormulaireItem('loecleint', { default: $stateParams.loecleint }),
            new FormulaireItem('encflgdep', { required: true, largeur: 10, disabled: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('faccleint', {
                resourceParams: (data: any) => ({ refcleint: data.refcleint }),
                largeur: 33.33,
                hidden: (data: any) => (data.$id)
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'CLI'
                }, largeur: 33.33, modifAllow: false, readonly: (data: any) => (data.faccleint)
                , menu: [
                    new MenuItemAction(
                        'LBL_BTN_EEEVI',
                        'BOUEEVI',
                        () => ({}),
                        {
                            icon: 'edit',
                            hidden: (data: any) => (!data.enccleint),
                            formulaireConfirmationAction: {
                                lblTitre: 'LBL_BTN_EEEVI',
                                lblConfirm: 'G_LBL_BTN_CONFIRMER',
                                largeurFormulaire: 40,
                                formulaire: new Formulaire([
                                    new FormulaireItem('refcleint', {
                                        resourceParams: {
                                            type: 'CLI'
                                        }
                                    }), new FormulaireItem('encmntdevhld', { hidden: true }),
                                ]),
                            }, successAction: (data) => {
                                vm.monoOccurrence.fetchData();
                            }
                        },
                    ),]
            }),
            new FormulaireItemSautDeColonne(),
            new FormulaireItem('moecleint', 
                {                    
                        resourceParams: (data: any) => {
                            return { tydcleint: data.tydcleint,
                                     enccleint: data.enccleint }
                        },
                    required: true,
                    modifAllow: false,
                    menu: [
                        new MenuItemAction(
                            'LBL_BTN_EEEVI',
                            'BOUMOEC',
                            () => ({}),
                            {
                                icon: 'edit',
                                hidden: (data: any) => (!data.$id),
                                formulaireConfirmationAction: {
                                    lblTitre: 'LBL_BTN_MOE',
                                    lblConfirm: 'G_LBL_BTN_CONFIRMER',
                                    largeurFormulaire: 40,
                                    formulaire: new Formulaire([
                                        new FormulaireItem('moecleint',{
                                        resourceParams: (data: any) => {
                                            return { tydcleint: data.tydcleint,
                                                     enccleint: data.enccleint       }
                                        }}
                                        ), 
                                        new FormulaireItem('tydcod', { hidden: true }),
                                    ]),
                                }, successAction: (data) => {
                                    vm.monoOccurrence.fetchData();
                                }

                            },
                        ),]
                }),//moe                
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('peccleint', { default: ecranDetails.valeurs.peccleint, required: true }),
            new FormulaireItem('encdat', { default: exDateFilter(new Date()), required: true }),
            new FormulaireItem('intcleintreq', { required: true, default: ecranDetails.valeurs.intcleintreq }),
            new FormulaireItemDivider('LBL_DIV_REF'),
            new FormulaireItem('encnumref'),
            new FormulaireItem('encdatref'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('encdsc', { largeur: 100, required: () => (ecranDetails.valeurs.flgdscenc) }),
            new FormulaireItem('encmnt', { required: true, largeur: 25 }),
            new FormulaireItem('encmntdevhld', { readonly: data => data.vaesysdevcod === 'CAD' || ecranDetails.valeurs.moeflgdep || (!data.id && data.t_moeflgdep === 'Oui') , largeur: 25 }),
            new FormulaireItem('vaesysdevcod', { readonly: true, largeur: 25 }),
            new FormulaireItem('devtaucou', { readonly: true, largeur: 25 }),
            new FormulaireItem('encflgrec', { largeur: 25, default: 1 }),
            new FormulaireItem('encnumrec', { readonly: true, largeur: 25 }),
            new FormulaireItem('etdcleint', { readonly: true, default: ecranDetails.valeurs.etdcleint, hidden: true }),
            new FormulaireItem('encflgref', { hidden: true })

        ], { largeurDefaut: 33.33 })
    };
    vm.enfMultiOccurrenceOptions = {
        bloc: 'enf',
        fonctions: {
            nouveau: ecranDetails.valeurs.flgiud,
            edition: ecranDetails.valeurs.flgiud,
            supprime: ecranDetails.valeurs.flgiud,
            editionRapide: true
        },
        colonnesCachees: [
            'ecrcod',
            'doccleintref',
            'docaffcleint01'
        ],
        colonnesVisibles: [
            {
                nom: 'sysfacnum', largeur: 200, menu: [
                    new MenuItemForage(
                        '',
                        (data: any) => `secure.${data.ecrcod}.ecran`,
                        (data: any) => ({
                            id: data.doccleintref
                        }),
                        {
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            },
            { nom: 'fcedatdue', largeur: 100 },
            { nom: 'fcesld', largeur: 100 },
            { nom: 'enfmnt', largeur: 100 },
            { nom: 'enfmntesc', largeur: 100 },
            { nom: 'enfmntfrs', largeur: 200 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('fcecleint', {
                largeur: 15, required: true, resourceParams: () => {
                    return { refcleint: vm.monoOccurrence.data.refcleint }
                }
            }),
            new FormulaireItem('fcedatdue', { largeur: 15, readonly: true }),
            new FormulaireItem('fcesld', { largeur: 10, readonly: true }),
            new FormulaireItem('enfmnt', { largeur: 15 }),
            new FormulaireItem('enfmntesc', { largeur: 15 }),
            new FormulaireItem('enfmntfrs', { largeur: 15 })
        ]),
        colonnesEditionRapide: [
            { nom: 'fcecleint', largeur: 200 },
            { nom: 'fcedatdue', largeur: 130 },
            { nom: 'fcesld', largeur: 150 },
            { nom: 'enfmnt', largeur: 150 },
            { nom: 'enfmntesc', largeur: 150 },
            { nom: 'enfmntfrs', largeur: 150 }
        ]
    };

    vm.evtMultiOccurrenceOptions = {
        bloc: 'evt',
        reloadWithBlocs: ['ENA'],
        editionRapideActive: true,
        fonctions: {
            nouveau: ecranDetails.valeurs.flgiud,
            edition: ecranDetails.valeurs.flgiud,
            supprime: (data: any) => !data.enacleint && ecranDetails.valeurs.flgiud,
            editionRapide: true
        },
        colonnesVisibles: [
            { nom: 'docaffnumdoc01', largeur: 200 },
            { nom: 'duvimpcod', largeur: 240 },
            { nom: 'evtmnt', largeur: 200 },
            { nom: 'taxcod', largeur: 100 },
            { nom: 'evtmnttaxfed', largeur: 200 },
            { nom: 'evtmnttaxpro', largeur: 200 },
            { nom: 'evtmntrevnet', largeur: 200 },
            { nom: 'evtqte', largeur: 200 },
            { nom: 'evtdsc', largeur: 200 },
            { nom: 'refcod', largeur: 200 },
            { nom: 'refnom', largeur: 200 }
        ],
        colonnesFixesDroite: [
            { nom: 'evtflgmsg', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('duvcleint', {
                largeur: 50,
                modifAllow: false,
                resourceParams: () => {
                    return { refcleint: vm.monoOccurrence.data.refcleint }
                }
            }),
            new FormulaireItem('caccleint', { hidden: true }),
            new FormulaireItem('evtimp', {
                largeur: 50,
                readonly: (data: any) => (data.duvcleint),
                required: (data: any) => !(data.duvcleint)
            }),
            new FormulaireItem('evtmnt', { readonly: (data: any) => (data.enacleint) }),
            new FormulaireItem('taxcleint', { required: true, readonly: (data: any) => (data.enacleint) }),
            new FormulaireItem('evtmnttaxfed', { readonly: (data: any) => (data.enacleint) }),
            new FormulaireItem('evtmnttaxpro', { readonly: (data: any) => (data.enacleint) }),
            new FormulaireItem('evtmntrevnet', { readonly: true }),
            new FormulaireItem('evtmntnetutl', { readonly: true }),
            new FormulaireItem('evtqte', { readonly: (data: any) => (data.enacleint) }),
            new FormulaireItem('evtdsc', { readonly: (data: any) => (data.enacleint) }),
            new FormulaireItem('refcleint', { readonly: (data: any) => (data.enacleint),
                resourceParams: {
                    type: 'CLI'
                }, largeur: 33.33, disabled: ecranDetails.valeurs.indentmlt
            }),
            new FormulaireItem('evtflgmsg', { readonly: true, required: false, titre: " " })
        ]),
        colonnesEditionRapide: [
            { nom: 'duvcleint', largeur: 400 },
            { nom: 'caccleint', largeur: 400, hidden: true },
            { nom: 'evtimp', largeur: 400 },
            { nom: 'evtmnt', largeur: 150 },
            { nom: 'taxcleint', largeur: 200 },
            { nom: 'evtmnttaxfed', largeur: 140 },
            { nom: 'evtmnttaxpro', largeur: 140 },
            { nom: 'evtmntrevnet', largeur: 126 },
            { nom: 'evtmntnetutl', largeur: 126 },
            { nom: 'evtqte', largeur: 126 },
            { nom: 'evtdsc', largeur: 300 },
            { nom: 'refcleint', largeur: 200 },
            { nom: 'evtflgmsg', largeur: 100 },
            { nom: 'aut__evtmsgrap', largeur: 300 }
        ],
        colonnesCachees: [
            'prucleint',
            'enacleint',
            'prscleint',
            'cifcleint',
            'unacleint',
            'cptcleint',
            'el1cleint',
            'el2cleint',
            'el3cleint',
            'evtmsg'
        ]
    };

    vm.enaMultiOccurrenceOptions = {
        bloc: 'ena',        
        fonctions: {
            editionRapide: true
        },
        navigatePage: cr2002Route.NAME,
        navigateParams: (rowData: any) => (
            {
                id: rowData.enacleint,
                enacleint: rowData.enacleint,
                indfor: ecranDetails.valeurs.flgiud
            }
        ),
        colonnesVisibles: [
            { nom: 'docaffnumdoc01', largeur: 200 },
            { nom: 'sldava', largeur: 200 },
            { nom: 'enamnt', largeur: 200 },
            { nom: 'sldavautl', largeur: 200 },
            { nom: 'enamntutl', largeur: 200 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('doccleint', {
                largeur: 15, resourceParams: () => {
                    return {
                        refcleint: vm.monoOccurrence.data.refcleint,
                        tydcleint: vm.monoOccurrence.data.tydcleint
                    }
                }
            }),
            new FormulaireItem('sldava', { readonly: true, largeur: 10 }),
            new FormulaireItem('enamnt', { largeur: 10 }),
            new FormulaireItem('sldavautl', { readonly: true, largeur: 10 }),
            new FormulaireItem('enamntutl', { largeur: 10, required: true }),
        ]),
        colonnesEditionRapide: [
            { nom: 'doccleint', largeur: 250 },
            { nom: 'sldava', largeur: 150 },
            { nom: 'enamnt', largeur: 150 },
            { nom: 'sldavautl', largeur: 150 },
            { nom: 'enamntutl', largeur: 150 }
        ]
    };

    // Chèque
    vm.chqMultiOccurrenceOptions = {
        bloc: 'chq',
        colonnesFixesGauche: [
            { nom: 'chqdat', largeur: 110 },
            { nom: 'cldcod', largeur: 110 },
            { nom: 'cldnomprn', largeur: 200 }
        ],
        colonnesVisibles: [
            { nom: 'chqmnt', largeur: 110 },
            { nom: 'chqnum', largeur: 120 },
            { nom: 'chqflgnsf', largeur: 120 },
            { nom: 'rrccod', largeur: 120 },
            { nom: 'chqdatnsf', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('chqdat', { readonly: true, largeur: 20 }),
            new FormulaireItem('cldcod', { readonly: true, largeur: 40 }),
            new FormulaireItem('cldnomprn', { readonly: true, largeur: 40 }),
            new FormulaireItem('chqmnt', { readonly: true, largeur: 20 }),
            new FormulaireItem('chqflgnsf', { largeur: 20 }),
            new FormulaireItem('rrccleint', { largeur: 20 }),
            new FormulaireItem('chqdatnsf', { readonly: true, largeur: 25 })
        ])
    };

    // Mode encaissement
    vm.memMultiOccurrenceOptions = {
        bloc: 'mem',
        reloadWithBlocs: ['ENC'],
        fonctions: {
            nouveau: () => vm.monoOccurrence.data.aut__memflgmod,
            edition: () => vm.monoOccurrence.data.aut__memflgmod,
            supprime: () => vm.monoOccurrence.data.aut__memflgmod
        },
        colonnesVisibles: [
            { nom: 'moecod', largeur: 114 },
            { nom: 'moedsc', largeur: 180 },
            { nom: 'memmnt', largeur: 120, total: true },
            { nom: 'memmntdevhld', largeur: 120, total: true }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('moecleint',
                {
                    resourceParams: () => {
                        return { moecleint: vm.monoOccurrence.data.moecleint,
                                 moeflgmul: vm.monoOccurrence.data.aut__memflgmod
                               }
                    }
                }),
            new FormulaireItem('memmnt', { largeur: 15 }),
            new FormulaireItem('memmntdevhld', { largeur: 10 })
        ])
    };
}
