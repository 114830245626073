import IStateService = angular.ui.IStateService;
import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItem, IMenuItemClass} from "../../../../../core/services/menu/menu-item.service";
import {IMenuItemActionClass} from "../../../../../core/services/menu/menu-item-action.service";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPagePb0026 extends IComponentController {
    valeursEcran: any;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulairePbu: IFormulaire;
    pblMultiOccurrenceOptions: IMultiOccurrenceOptions;
    preMultiOccurrenceOptions: IMultiOccurrenceOptions;
    annpreMultiOccurrenceOptions: IMultiOccurrenceOptions;
    anncouMultiOccurrenceOptions: IMultiOccurrenceOptions;
    actionCompScenario1: IMenuItem;
    actionCompScenario2: IMenuItem;
    actionCompScenario3: IMenuItem;
    actionRouvScenario1: IMenuItem;
    actionRouvScenario2: IMenuItem;
    actionRouvScenario3: IMenuItem;
    getLibelles(data:any): string;
    getLibellesRouv(data:any): string;
}

interface IPb0026Params {
    menuId: string;
}

/* @ngInject */
export default function Pb0026Controller(pb0027Route: IRoute,
                                         $state: IStateService,
                                         $stateParams: IPb0026Params,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItem: IMenuItemClass,
                                         Menu: IMenuClass) {
    const vm: IPagePb0026 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false
            }
        };

        // Action Complété version 1
        vm.actionCompScenario1 = new MenuItemAction(
            getLibelles(1),
            'BOUCOMSC1',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                secondaire: true
            }
        );

        // Action Complété version 2
        vm.actionCompScenario2 = new MenuItemAction(
            getLibelles(2),
            'BOUCOMSC2',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                secondaire: true
            }
        );

        // Action Complété version 3
        vm.actionCompScenario3 = new MenuItemAction(
            getLibelles(3),
            'BOUCOMSC3',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                secondaire: true
            }
        );

        // Action Rouvrir version 1
        vm.actionRouvScenario1 = new MenuItemAction(
            getLibellesRouv(1),
            'BOUROUSC1',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                secondaire: true,
                hidden: (data:any) => !vm.monoOccurrence.data.pbgflgsai1
            }
        );

        // Action Rouvrir version 2
        vm.actionRouvScenario2 = new MenuItemAction(
            getLibellesRouv(2),
            'BOUROUSC2',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                secondaire: true,
                hidden: (data:any) => !vm.monoOccurrence.data.pbgflgsai2
            }
        );

        // Action Rouvrir version 3
        vm.actionRouvScenario3 = new MenuItemAction(
            getLibellesRouv(3),
            'BOUROUSC3',
            () => ({}),
            {
                icon: 'done_all',
                iconButton: false,
                successAction: () => { vm.monoOccurrence.fetchData(); },
                secondaire: true,
                hidden: (data:any) => !vm.monoOccurrence.data.pbgflgsai3
            }
        );

        // UBR de la Préparation budgétaire municipale
        vm.formulairePbu = new Formulaire('LBL_BLC_PBU', [
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pbgann', {largeur: 100}),
                new FormulaireItem('prucoddsc', {largeur: 100})
            ]), {largeur: 50}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('pbglblsce1ent', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.pbgflgsai1 === 0 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.pbgflgsai1 === 0 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg'
                        })
                    ]}),
                new FormulaireItem('pbglblsce2ent', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.pbgflgsai2 === 0 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.pbgflgsai2 === 0 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg'
                        })
                    ]}),
                new FormulaireItem('pbglblsce3ent', {titre: ' ', largeur: 100, menuGauche: [
                        new MenuItem(() => {},  {
                            icon: (data: any) => {
                                return data.pbgflgsai3 === 0 ? 'clear' : 'mode_edit';
                            },
                            iconButton: true,
                            noAction: true,
                            class: (data: any) => data.pbgflgsai3 === 0 ? 'ex-rouge-500' : 'ex-primary-hue-3-fg'
                        })
                    ]}),
            ]), {largeur: 50})
        ]);

        // Détail des comptes
        vm.pblMultiOccurrenceOptions = {
            bloc: 'pbl',
            actionsRangeeDroite: new Menu([
                new MenuItemAction(
                    'LBL_ACT_CREATION',
                    'BOUCREPBL',
                    (rowData:any) => ({
                        pbucleint: rowData.pbucleint,
                        prtcleint: rowData.prtcleint
                    }),
                    {
                        icon: 'exit_to_app',
                        successAction: (retour:any) => {
                            $state.go(pb0027Route.NAME, {
                                menuId: $stateParams.menuId,
                                id: retour.pblcleint,
                                pbucleint: retour.pbucleint,
                                prtcleint: retour.prtcleint
                            });
                        }
                    }
                )
            ]),
            entetesReplacedValues: {
                ent_sce1: vm.valeursEcran.pbgentsce1,
                ent_sce2: vm.valeursEcran.pbgentsce2,
                ent_sce3: vm.valeursEcran.pbgentsce3
            },
            colonnesFixesGauche: [
                {nom: 'cptcod', largeur: 240}
            ],
            colonnesVisibles: [
                {nom: 'prscod', largeur: 120},
                {nom: 'prsdscl01', largeur: 220},
                {nom: 'blocod', largeur: 120},
                {nom: 'blodscabr', largeur: 220},
                {nom: 'unacod', largeur: 120},
                {nom: 'unadscabr', largeur: 220},
                {nom: 'cifcod', largeur: 120},
                {nom: 'cifdscabr', largeur: 220}
            ],
            colonnesFixesDroite: [
                {nom: 'pblbdgini'    , largeur: 113},
                {nom: 'pblmntobl'    , largeur: 113},
                {nom: 'pblmntnetsce1', largeur: 113},
                {nom: 'pblmntnetsce2', largeur: 113},
                {nom: 'pblmntnetsce3', largeur: 113}
            ],
            colonnesCachees: [
                'pbucleint',
                'prtcleint'
            ],
            colonnesDisponibles: [
                {nom: 'permntnetsce1', largeur:120 },
                {nom: 'permntnetsce2', largeur:120 },
                {nom: 'permntnetsce3', largeur:120 }
            ]
        };

        // Montants prévisionnels de l'année précédente
        vm.preMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'pvlannseq', largeur: 100},
                {nom: 'vldmntnet', largeur: 100}
            ]
        };

        // Montants de disponibilité de l'année précédente à l'année précédente de l'année budgétaire en cours
        vm.annpreMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tyhdsc'   , largeur: 100},
                {nom: 'ctbmntsum', largeur: 100}
            ]
        };

        // Montants de disponibilité de l'année précédente de l'année budgétaire en cours
        vm.anncouMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'tyhdsc'   , largeur: 100},
                {nom: 'ctbmntsum', largeur: 100}
            ]
        };
    }

    function getLibelles(data:any) {
        if (data === 1) {
            return vm.valeursEcran.pbgcmpsce1;
        } else if (data === 2) {
            return vm.valeursEcran.pbgcmpsce2;
        } else if (data === 3) {
            return vm.valeursEcran.pbgcmpsce3;
        }
    }

    function getLibellesRouv(data:any) {
        if (data === 1) {
            return vm.valeursEcran.pbgrousce1;
        } else if (data === 2) {
            return vm.valeursEcran.pbgrousce2;
        } else if (data === 3) {
            return vm.valeursEcran.pbgrousce3;
        }
    }
}
