import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageSO0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSO0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tyuseq', largeur: 110},
            {nom: 'tyulib', largeur: 262},
            {nom: 'tyuseqoff', largeur: 110},
            {nom: 'tyuindunorh', largeur: 55},
            {nom: 'tyuindunovac', largeur: 100},
            {nom: 'tyuindunoeqi', largeur: 75}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tyuseq', {largeur: 10}),
            new FormulaireItem('tyulib', {required: true, largeur: 30}),
            new FormulaireItem('tyuseqoff', {largeur: 10}),
            new FormulaireItem('tyuindunorh', {required: true, largeur: 10}),
            new FormulaireItem('tyuindunovac', {required: true,largeur: 10}),
            new FormulaireItem('tyuindunoeqi', {required: true, largeur: 10})
        ])
    };
}
