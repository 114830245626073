import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2002',
    ecrans: ['00-2'],
    params: ['id',
            '?prucleint',
            '?vaesysnatctb',
            '?pecann',
            '?indcleint',
            '?elecleint',
            '?niveau',
            '?annper_deb',
            '?annper_fin',
            '?anncivfin']
});
