import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageCP0059 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0059Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCP0059 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_PERIODE'),
            new FormulaireItem('peccod_deb', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('peccod_fin', {
                required: true,
                nombreLibellesDetailsCacher: 1
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('reqclass', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('noreference', {
                default: 'T',
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'FOU'
                },
                required: false
            }),
            new FormulaireItemDivider('LBL_PLAGE'),
            new FormulaireItem('MNTMIN', {
                required: false
            }),
            new FormulaireItem('MNTMAX', {
                required: false
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demreport', {
                default: 1,
                required: true
            })
        ]),
        // Colonnes fixe à gauche
        colonnesFixesGauche: [
            {nom: 'reqnumdoc',largeur: 200},
            {nom: 'reqnumdocseq',largeur: 200},
            {nom: 'tydcod',largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'peccod',largeur: 200},
            {nom: 'refcod',largeur: 200},
            {nom: 'refnomabr',largeur: 200},
            {nom: 'drenumref',largeur: 200},
            {nom: 'reqdsc',largeur: 200},
            {nom: 'reqdat',largeur: 200},
            {nom: 'intcod',largeur: 200},
            {nom: 'intnom',largeur: 200},
            {nom: 'intcoddem',largeur: 200},
            {nom: 'intnomdem',largeur: 200},
            {nom: 'etddsc',largeur: 200},
            {nom: 'reqmnt',largeur: 200},
            {nom: 'vaesysdevcod',largeur: 200},
            {nom: 'drimntutl',largeur: 200},
            {nom: 'count_dri',largeur: 200},
            {nom: 'solde_a_payer',largeur: 200},
            {nom: 'reqmntdepnet',largeur: 200},
            {nom: 'count_nof',largeur: 200},
            {nom: 'reqflgclslcop',largeur: 200}
        ]
    };
}
