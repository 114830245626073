import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageDO0056 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0056Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDO0056 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur: 210},
            {nom: 'rglcod', largeur: 89},
            {nom: 'rgldscabr', largeur: 210},
            {nom: 'rdddatvig', largeur: 100},
            {nom: 'grecod', largeur: 89},
            {nom: 'gredsc', largeur: 210},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vue__demcleint', {required: true, modifAllow: false}),
            new FormulaireItem('vue__rglcleint', {required: true, modifAllow: false}),
            new FormulaireItem('rddcleint', {required: true}),
            new FormulaireItem('grecleint', {required: true})
        ], {largeurDefaut: 25}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
