import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageCR0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ICr0012Params {
    loecleint: string;
    efface_criteres: boolean;
}

/* @ngInject */
export default function Cr0012Controller(Menu: IMenuClass,
    cr0013Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    $stateParams: ICr0012Params,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCR0012 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'encnumdoc', largeur: 120 },
            { nom: 'encnumdocseq', largeur: 60 },
            { nom: 'moecod', largeur: 120 },
            { nom: 'moedsc', largeur: 200 },
            { nom: 'tydcod', largeur: 60 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'encnumref', largeur: 100 },
            { nom: 'encdat', largeur: 100 },
            { nom: 'peccod', largeur: 90 },
            { nom: 'etddsc', largeur: 160 },
            { nom: 'nbrajf', largeur: 100 },
            { nom: 'loecod', largeur: 100 },
            { nom: 'loedsc', largeur: 200 },
            { nom: 'memmnt', largeur: 130 },
            { nom: 'encmnt', largeur: 130 },
            { nom: 'encnumrec', largeur: 110 },
            { nom: 'encdsc', largeur: 350 },
            { nom: 'usrcod', largeur: 105 }
        ],
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false,
            effaceCriteres: $stateParams.efface_criteres
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup', { hidden: () => ecranDetails.valeurs.vaehldtypimp !== 1 }),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a'),
            new FormulaireItem('loecleint', {
                default: $stateParams.loecleint,
                hidden: false,
                disabled: (data: any) => data.$params.loecleint
            })

        ]),
        navigatePage: cr0013Route.NAME,
        navigateParams: (rowData: any) => (
            {
                indfor: 0
            }
        ),
        actionsNouveaux: new Menu([
            new MenuItemForage(cr0013Route.NAME, () => {
                return {
                    loecleint: $stateParams.loecleint,
                    indfor: 0
                };
            })
        ])
    };
}
