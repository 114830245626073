import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 're2008',
    ecrans: ['00-2'],
    params: [
        '?rtocleint',
        '?t_mnt_de',
        '?t_mnt_a'
    ]
});
