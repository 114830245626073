import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuItemRecuperationClass} from '../../../../../core/services/menu/menu-item-recuperation.service';

interface IPageDO0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceTCHOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0007Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemRecuperation: IMenuItemRecuperationClass) {
    const vm: IPageDO0007 = this;

    vm.multiOccurrenceTCHOptions = {
        bloc: 'tch',
        actionsMore: new Menu([
            new MenuItemRecuperation({
                nom: 'recuperation_tch',
                criteresSuggeres: new Formulaire([
                    new FormulaireItem('offcleint_tch'),
                    new FormulaireItem('poscleint_tch'),
                    new FormulaireItem('temcleint_tch')
                ]),
                colonnesVisibles: [
                    {nom: 'gradsc', largeur: 300},
                    {nom: 'tchseqaff', largeur: 120},
                    {nom: 'tchdsc', largeur: 360}
                ]
            })
        ]),
        colonnesVisibles: [
            {nom: 'gracod', largeur: 130},
            {nom: 'gradsc', largeur: 202},
            {nom: 'tchseqaff', largeur: 105},
            {nom: 'tchdsc', largeur: 560},
        ],
        colonnesCachees: ['poscleint', 'temcleint', 'gracleint'],
        formulaire: new Formulaire([
            new FormulaireItem('gracleint'),
            new FormulaireItem('tchseqaff'),
            new FormulaireItem('tchdsc', {required: true}),
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesCachees: ['poscleint', 'temcleint'],
        colonnesVisibles: [
            {nom: 'posnumdoc', largeur: 89},
            {nom: 'postitabr', largeur: 262},
            {nom: 'urgcod', largeur: 125},
            {nom: 'urgdscabr', largeur: 231},
            {nom: 'temcod', largeur: 89},
            {nom: 'temdscabr', largeur: 218},
            {nom: 'posdscfon', largeur: 560},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('posdscfon')
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceTCHOptions
    };
}
