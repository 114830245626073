import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0036 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0036Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0036 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'clycod', largeur: 70},
            {nom: 'clydsc', largeur: 136},
            {nom: 'clyflgdet', largeur: 70},
            {nom: 'clyflgact', largeur: 70}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('clycod'),
            new FormulaireItem('clydsc'),
            new FormulaireItem('clyflgdet'),
            new FormulaireItem('clyflgact', {default: 1})
        ]),
        fonctions:{
            rechercheCollapsable: false
        },
    };
}
