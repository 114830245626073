import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0006Controller(gs0117Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0006 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0117Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0117Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'ecrcod', largeur: 110},
            {nom: 'srccod', largeur: 100},
            {nom: 'ecrdsc', largeur: 300},
            {nom: 'ecrdscmen', largeur: 300},
            {nom: 'clscod', largeur: 150},
            {nom: 'clsnom', largeur: 200}
        ]
    };
}
