import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';

interface IPageMc0079 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0079Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass) {
    const vm: IPageMc0079 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tyhdscori', largeur: 210 },
            { nom: 'tyhdscdes', largeur: 210 },
            { nom: 'vaesysnatctbori_dsc', largeur: 120 },
            { nom: 'vaesysnatctbdes_dsc', largeur: 120 },
            { nom: 'tyifac', largeur: 140 },
            { nom: 'rgaind', largeur: 120 },
            { nom: 'rgadsc', largeur: 200 },
            { nom: 'tyiflgmajtrb', largeur: 100 }
        ],
        formulaire: new Formulaire([
            new FormulaireItemDivider('LBL_DIV_ORI'),
            new FormulaireItem('tymcleintori'),
            new FormulaireItem('vaesysnatctbori'),
            new FormulaireItemDivider('LBL_DIV_DES'),
            new FormulaireItem('tymcleintdes'),
            new FormulaireItem('vaesysnatctbdes'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tyifac', { largeur: 33.33 }),
            new FormulaireItem('rgacleint', { largeur: 33.33 }),
            new FormulaireItem('tyiflgmajtrb', { largeur: 33.33, default: 0 })
        ])
    };
}