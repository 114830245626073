import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IDownloadManagerService } from '../../../../../core/services/download-manager.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';

interface IPageCC0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Cc0006Controller(Menu: IMenuClass,
    MenuItemForage: IMenuItemEcranClass,
    MenuItemSelection: IMenuItemSelectionClass,
    $stateParams: IStateParamsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    downloadManager: IDownloadManagerService,
    MenuItem: IMenuItemClass,
    cc2000Route: IRoute) {
    const vm: IPageCC0006 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            edition: false,
            supprime: false,
            importation: true
        },
        actionsMore: new Menu([
            new MenuItem('TELECHARGER_PATRON', () => {
                downloadManager.directDownload('/cc0006/patron', {}, $stateParams, ecranDetails);
            }, {
                icon: 'file_download'
            })
        ]),
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_TRTCHE',
                'BOUTRAITER',
                {
                    selection: { nom: 'traiter' },
                    successAction: () => {
                        vm.multiOccurrence && vm.multiOccurrence.fetchDataList();
                    },
                    //  fonction: 'BOUTRAITER'
                }),
            new MenuItemSelection('LBL_BTN_VALIDER', 'BOUPRVAL', {
                selection: { nom: 'Valider' }
            }),
            new MenuItemSelection('LBL_BTN_DET', 'BOUPDEL', {
                selection: { nom: 'detruire' }
            })
        ]),
        colonnesVisibles: [
            { nom: 'fcsnumcha', largeur: 89 },
            { nom: 'fcsficsou', largeur: 262 },
            { nom: 'fcsnbrlig', largeur: 100 },
            { nom: 'fcsnbrerr', largeur: 262 },
            { nom: 'fcsflgval', largeur: 60 },
            {
                nom: 'fcsflgtrt', largeur: 76,
                menu: [
                    new MenuItemForage(
                        'LBL_RESERVE',
                        cc2000Route.NAME,
                        (Data: any) => ({
                            id: Data.fcscleint,
                            fcscleint: Data.fcscleint
                        }),
                        {
                            fonction: 'RESERVES',
                            icon: 'launch',
                            iconButton: true
                        }
                    )
                ]
            }
        ],
        colonnesCachees: ['fcscleint']

    };
}
