import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc1038 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc1038Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc1038 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            importation: true
        },
        colonnesVisibles: [
            {nom: 'refcod', largeur: 100},
            {nom: 'refnom', largeur: 220},
            {nom: 'mapcod', largeur: 130},
            {nom: 'mapnom', largeur: 132},
            {nom: 'mapdatvig', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', {required: true}),
            new FormulaireItem('mapcod', {required: true}),
            new FormulaireItem('mapnom', {required: true}),
            new FormulaireItem('mapdatvig', {required: true})
        ], {largeurDefaut: 25})
    };
}
