import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';


interface IPageTh0030 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Th0030Controller(Operateur: IOperateurService,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageTh0030 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 160},
            {nom: 'dcxdatdeb', largeur: 120},
            {nom: 'dcxdatfin', largeur: 120},
            {nom: 'dcxnumdoc', largeur: 120},
            {nom: 'rcxcod', largeur: 120},
            {nom: 'rcxdsc', largeur: 140},
            {nom: 'unocod', largeur: 120},
            {nom: 'unodscabr', largeur: 160},
            {nom: 'ttccod', largeur: 120},
            {nom: 'ttcdsc', largeur: 160},
            {nom: 'ecldsceta', largeur: 120},
            {nom: 'usrcod', largeur: 120},
            {nom: 'rghcod', largeur: 120},
            {nom: 'rghdsc', largeur: 160},
            {nom: 'dcxcom', largeur: 160},
            {nom: 'flgmsg', largeur: 100},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
