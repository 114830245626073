import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageSp0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Sp0001Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSp0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tevcod', largeur: 100 },
            { nom: 'tevdsc', largeur: 220 },
            { nom: 'ttccod', largeur: 100 },
            { nom: 'ttcdsc', largeur: 220 },
            { nom: 'tevmsg', largeur: 350 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tevcod'),
            new FormulaireItem('tevdsc'),
            new FormulaireItem('ttccleint'),
            new FormulaireItem('tevmsg', {largeur: 100, enableCount: true})
        ], {largeurDefaut: 33.33})
    };
}
