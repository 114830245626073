import { IComponentController } from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageGs0130 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    qdsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0130Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageGs0130 = this;

    vm.qdsMultiOccurrenceOptions = {
        bloc: 'qds',
        formulaire: new Formulaire([
            new FormulaireItem('qdsnom'),
            new FormulaireItem('qdsdscfra')
        ]),
        colonnesVisibles: [
            { nom: 'qdsnom', largeur: 200 },
            { nom: 'qdsdscfra', largeur: 200 }
        ]
    };

    vm.multiOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('qadnom'),
            new FormulaireItem('qaddscfra', { required: true })
        ]),
        colonnesVisibles: [
            { nom: 'qadnom', largeur: 200 },
            { nom: 'qaddscfra', largeur: 200 }
        ],
        multiOccurrenceOptionsEnfant: vm.qdsMultiOccurrenceOptions
    };
}
