import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IStateParamsService} from 'angular-ui-router';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';

interface IPageSO0020 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ISo0020Params extends IStateParamsService {
    tuncleint: number;
    tundatvig: Date;
}

/* @ngInject */
export default function So0020Controller(Menu: IMenuClass,
                                         MenuItemSelection: IMenuItemSelectionClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: ISo0020Params) {
    const vm: IPageSO0020 = this;

    vm.multiOccurrenceOptions = {
        actionsSelection: new Menu([
            new MenuItemSelection('LBL_BTN_POSTE', 'BOUSEL', {
                selection: {nom: 'selection'}
            }),
            new MenuItemSelection('LBL_BTN_POSTE_ENFANT', 'BOUSEL2', {
                selection: {nom: 'selection'}
            }),
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('poscleint', {
                resourceParams: {
                    tundatvig: $stateParams.tundatvig
                }
            }),
            new FormulaireItem('unocleintrh'),
            new FormulaireItem('stocleint', {
                resourceParams: {
                    tundatvig: $stateParams.tundatvig
                }
            })
        ]),
        colonnesVisibles: [
            {nom: 'posnumdoc', largeur: 100},
            {nom: 'postitabr', largeur: 260},
            {nom: 'ecldsceta', largeur: 160},
            {nom: 'flgsup'   , largeur: 100},
            {nom: 'tpocod'   , largeur: 100},
            {nom: 'tpodsc'   , largeur: 260}
        ]
    };
}
