import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";

interface IPageMr0220 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    MultiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Mr0220Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0220 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            rechercheCollapsable: false,
            selectionCycle: false
        },
        colonnesVisibles: [
            { nom: 'unpcod', largeur: 160 },
            { nom: 'unpdsc', largeur: 350 },
            { nom: 'ppacod', largeur: 130 },
            { nom: 'uppflgblc', largeur: 90 }
        ],
        colonnesCachees: ['uppcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('uppflgblc')
        ])
    };
}
