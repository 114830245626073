import IStateService = angular.ui.IStateService;
import IDialogOptions = angular.material.IDialogOptions;
import * as angular from 'angular';
import {
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    IPromise, IScope
} from 'angular';
import { IMonoOccurrence, IMonoOccurrenceClass, IMonoOccurrenceOptions } from '../../../../core/services/mono-occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceOptions
} from '../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../core/services/menu/menu.service';
import { IMenuItemClass } from '../../../../core/services/menu/menu-item.service';
import { IDialog } from '../../../../core/services/dialog.service';
import { IFocusService } from '../../../../core/behaviors/ex-focus/ex-focus.behavior';
import { IMenuMoreClass, IMenuMoreTypes, IMenuMoreTypesOptions } from '../../../../core/services/menu/menu-more.service';
import { IApiError } from '../../../../core/interfaces/api-error.interface';
import { INotificationHandler } from '../../../../core/services/utils/notification-handler.service';
import { ITemplateController } from '../../../../core/services/template.service';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IDataLinker } from '../../../../core/services/data-linker.service';
import { IChangementManager } from "../../../../core/services/changement-manager.service";

export interface IComposantMultiOccurrenceAnalyse extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    creationLocale: boolean;
    newMonoOccurrence: IMonoOccurrence;
    newOpened: boolean;
    newFormCtrl: IFormController;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    loading: boolean;
    codeEcran: string;
    titre: string;
    templateCtrl: ITemplateController;
    saving: boolean;
    messagesErreur: IMessagesErreurs;
    interval: IPromise<any>;

    closeNew(): void;
    clickRow(rowDetails: any, event: MouseEvent): void;
    doubleClickRow(event: MouseEvent, data: any): void;
    closeRow(data: any): void;
    saveRowAction(monoOccurrence: IMonoOccurrence, rowData: any): IPromise<any>;
    saveNewAction(savedData: any): IPromise<any>;
    saveNewAndNextAction(savedData: any): IPromise<any>;
    retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence): void;
    retryValeursDefautErreur(monoOccurrence: IMonoOccurrence): void;
    getTitre(): string;
    displayNewFormulaire(): boolean;
    hasTranscludeDetails(): boolean;
}

export interface IMessagesErreurs {
    [index: string]: Array<string>;
}

/* @ngInject */
export default function MultiOccurrenceAnalyseController(dataLinker: IDataLinker,
    MultiOccurrence: IMultiOccurrenceClass,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    $state: IStateService,
    DialogConfirm: IDialog,
    notificationHandler: INotificationHandler,
    exFocus: IFocusService,
    $element: IAugmentedJQuery,
    DialogSuiviModification: IDialog,
    MenuMore: IMenuMoreClass,
    MonoOccurrence: IMonoOccurrenceClass,
    $scope: IScope,
    changementManager: IChangementManager) {
    const vm: IComposantMultiOccurrenceAnalyse = this;

    const creationLocaleAction = new MenuItem('G_LBL_BTN_NOUVEAU', openNew);

    vm.closeNew = closeNew;
    vm.clickRow = clickRow;
    vm.doubleClickRow = doubleClickRow;
    vm.closeRow = closeRow;
    vm.saveRowAction = saveRowAction;
    vm.saveNewAction = saveNewAction;
    vm.saveNewAndNextAction = saveNewAndNextAction;
    vm.retryMonoOccurrenceErreur = retryMonoOccurrenceErreur;
    vm.retryValeursDefautErreur = retryValeursDefautErreur;
    vm.getTitre = getTitre;
    vm.displayNewFormulaire = displayNewFormulaire;
    vm.hasTranscludeDetails = hasTranscludeDetails;

    const stopListening = changementManager.watchStateChange($element);

    vm.$onInit = function $onInit() {
        vm.titre = vm.getTitre();

        if (vm.multiOccurrenceOptions.formulaire &&
            !vm.multiOccurrenceOptions.actionsNouveaux) {
            vm.multiOccurrenceOptions.actionsNouveaux = new Menu([
                creationLocaleAction
            ]);
        }

        if (vm.multiOccurrenceOptions.actionsSelection) {
            vm.multiOccurrenceOptions.rangeesSelectionnables = true;
        }

        vm.multiOccurrenceOptions.fonctions = {
            afficherMenuSettingDansEntete: true,
            selectionCycle: true,
            ...vm.multiOccurrenceOptions.fonctions
        };

        ajouterFiltreEmploye();

        vm.multiOccurrence = new MultiOccurrence({
            hasGridElement: true,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            actions: new Menu([]),
            actionsRangeeDroite: new Menu([]),
            pagination: {
                nbElementsPossibles: [20, 50, 100],
                nombreElementPage: 20
            },
            ...vm.multiOccurrenceOptions
        });

        vm.codeEcran = `${vm.ecranContextCtrl.stateParams.srccod}-${vm.ecranContextCtrl.stateParams.ecran}`;

        vm.multiOccurrence.once('ready', () => {
            initActionsRangeeDroite();
            if (!vm.multiOccurrence.isView) {
                vm.monoOccurrenceOptions = {
                    resourceUrl: vm.multiOccurrence.getResourceUrl()
                }
            } else {
                vm.monoOccurrenceOptions = {}
            }
        });
    }

    vm.$onDestroy = () => {
        stopListening();
        vm.multiOccurrence.removeAllListeners();
    }

    function ajouterFiltreEmploye() {
        if (vm.ecranContextCtrl.stateParams.isProfilEmploye) {
            vm.multiOccurrenceOptions.filtresParametres = vm.multiOccurrenceOptions.filtresParametres || [];
            // Un filtre pour filtrer, l'autre pour l'affichage
            vm.multiOccurrenceOptions.filtresParametres.unshift(
                {
                    colonne: 'G_LBL_EMPLOYE',
                    valeur: `${vm.ecranContextCtrl.ecranDetails.employe.eplnummat} - ${vm.ecranContextCtrl.ecranDetails.employe.eplnomprnusu}`,
                    affichage: true
                },
                {
                    colonne: 'demcleint',
                    valeur: vm.ecranContextCtrl.stateParams.employe,
                    visible: false
                }
            );
        }
    }

    function initActionsRangeeDroite() {
        const menuMoreTypes: IMenuMoreTypes = {};
        const menuMoreTypesOptions: IMenuMoreTypesOptions = {};

        if (vm.multiOccurrence.fonctions.nouveau instanceof Function) {
            const data = {};
            dataLinker.link($element, data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

            if (!vm.multiOccurrence.fonctions.nouveau(data, vm.multiOccurrence)) {
                creationLocaleAction.disabled = true;
            }
        }

        if (vm.multiOccurrence.fonctions.edition) {
            menuMoreTypes.edition = (event: JQueryEventObject, rowDetails: any) => {
                if (vm.multiOccurrence.hasEdition(rowDetails)) {
                    toggleRow(rowDetails);
                }
            };

            menuMoreTypesOptions.edition = {
                disabled: (data: any) => data.$opened
            };

            if (vm.multiOccurrence.fonctions.edition instanceof Function) {
                menuMoreTypesOptions.edition.visible = vm.multiOccurrence.fonctions.edition;
            }
        }

        if (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) {
            menuMoreTypes.suiviModification = (event: any, rowDetails: any) => {
                afficherSuiviModification(event, rowDetails);
            };
        }

        if (vm.multiOccurrence.fonctions.supprime) {
            menuMoreTypes.supprime = (event: MouseEvent, rowDetails: any) => {
                if (vm.multiOccurrence.hasSuppression(rowDetails)) {
                    afficherConfirmerSuppression(event, rowDetails);
                }
            };

            if (vm.multiOccurrence.fonctions.supprime instanceof Function) {
                menuMoreTypesOptions.supprime = {
                    visible: vm.multiOccurrence.fonctions.supprime
                };
            }
        }

        if (vm.multiOccurrence.fonctions.supprime ||
            vm.multiOccurrence.fonctions.edition ||
            (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) ||
            (vm.multiOccurrence.actionsMoreLigne && vm.multiOccurrence.actionsMoreLigne.listeMenuItem.length)) {
            vm.multiOccurrence.creerBoutonDupliquer(() => vm.newMonoOccurrence, () => vm.newOpened = true)
            const menuMore = new MenuMore(menuMoreTypes, vm.multiOccurrence.actionsMoreLigne, menuMoreTypesOptions);
            vm.multiOccurrence.actionsRangeeDroite.add(menuMore);
        }

        if (vm.multiOccurrenceOptions.navigatePage) {
            vm.multiOccurrence.actionsRangeeDroite.add(
                new MenuItem('G_LBL_BTN_OUVRIR', getPageLink, {
                    icon: 'exit_to_app',
                    link: true,
                    fonction: `${vm.multiOccurrence.mnemonique}.BOUOUV`,
                    directionTooltip: 'bottom',
                    hidden: (data: any) => !getPageLink(data)
                })
            );
        }
    }

    function afficherSuiviModification(ev: any, rowDetails: any): void {
        const dialogOptions: IDialogOptions = {
            targetEvent: ev,
            locals: {
                data: rowDetails,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogSuiviModification.show(dialogOptions);
    }

    function afficherConfirmerSuppression(event: MouseEvent, rowDetails: any): void {
        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            locals: {
                lblTitre: 'G_LBL_BTN_SUPP_ENR',
                lblDescription: 'G_LBL_SUPP_ENR',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                lblMessage: 'G_MSG_SUPP_ENR',
                icon: 'delete',
                ecranContext: vm.ecranContextCtrl,
                confirmAction() {
                    if (vm.multiOccurrence.isView) {
                        return deleteRowEdition(rowDetails);
                    } else {
                        return deleteRow(rowDetails);
                    }
                }
            }
        };

        DialogConfirm.show(dialogOptions);
    }

    function deleteRow(rowDetails: any): IPromise<any> {
        vm.saving = true;
        return vm.multiOccurrence.deleteRowDetails(rowDetails)
            .then(() => {
                notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');
            })
            .finally(() => {
                vm.saving = false;
            });
    }

    function deleteRowEdition(rowDetails: any): IPromise<any> {
        const monoOccurrenceSupression = new MonoOccurrence({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            srccod: vm.multiOccurrence.srccod,
            multiOccurrenceParent: vm.multiOccurrence,
            isEdition: true
        });
        return monoOccurrenceSupression.delete(rowDetails[vm.multiOccurrence.cleint]).then(() => {
            notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');
            vm.multiOccurrence.fetchDataList();
        });
    }

    function openNew() {
        vm.newMonoOccurrence.resetData();
        vm.newOpened = true;
        creationLocaleAction.disabled = true;
    }

    function closeNew() {
        vm.newOpened = false;
        creationLocaleAction.disabled = false;
    }

    function clickRow(rowDetails: any, event: MouseEvent) {
        vm.multiOccurrence.activeRowCleint = rowDetails[vm.multiOccurrence.cleint];

        if (vm.multiOccurrence.hasEdition(rowDetails) || vm.multiOccurrence.details || hasTranscludeDetails() || vm.multiOccurrence.rowHasError(rowDetails)) {
            const target = angular.element(event.target);
            // On ne ferme pas si le clique est dans l'accordéon.
            if (!target.closest('.ex-grid-row-more-content').length && target.closest('html').length &&
                !target.closest('.md-button').length) {
                toggleRow(rowDetails);
            }
        }
    }

    function doubleClickRow(event: MouseEvent, data: any) {
        vm.multiOccurrence.doubleClickRow(event, data, vm.ecranContextCtrl.stateParams);
    }

    function toggleRow(rowDetails: any): void {
        initMsgErr(rowDetails);
        rowDetails.$opened = !rowDetails.$opened;
    }

    function initMsgErr(data: any): void {
        vm.messagesErreur = vm.messagesErreur || {};

        const messagesErreurCols = vm.multiOccurrence.getErrorColumnsName();
        const dataTypes = vm.multiOccurrence.dataTypes;

        const lstMessages = messagesErreurCols.map((ele: any) => {
            if (dataTypes[ele].params.err && data[ele]) {
                return data[dataTypes[ele].params.err];
            }
        }).filter((ele) => ele);

        const cleint = vm.multiOccurrence.cleint;
        const key = data[cleint];

        if (lstMessages.length === 0) {
            if (vm.messagesErreur && vm.messagesErreur[key]) {
                delete vm.messagesErreur[key];
            }
        } else {
            const newMessagesError = lstMessages.reduce((messagesErreurs: IMessagesErreurs, message: string) => {
                //On ne peut pas utiliser les données liées pour éviter un bug avec les websockets
                if (!messagesErreurs[key]) {
                    messagesErreurs[key] = [message];
                } else if (!messagesErreurs[key].indexOf(message)) {
                    messagesErreurs[cleint].push(message);
                }

                return messagesErreurs;
            }, {});

            Object.assign(vm.messagesErreur, newMessagesError);
        }
    }

    function closeRow(data: any) {
        data.$opened = false;
    }

    function afficherConfirmerSauvegarde(action: () => {}, error: IApiError): void {
        notificationHandler.erreur({
            error,
            lblTitre: vm.titre,
            lblConfirm: 'G_LBL_BTN_APPLIQUER',
            lblMessage: 'G_MSG_SAUV_ENR',
            confirmAction() {
                return action();
            }
        });
    }

    function saveRowAction(monoOccurrence: IMonoOccurrence, savedData: any) {
        return saveRow(monoOccurrence, savedData).catch((error: IApiError) => {
            const confirmAction = () => saveRow(monoOccurrence, savedData);
            afficherConfirmerSauvegarde(confirmAction, error);
        });
    }

    function saveRow(monoOccurrence: IMonoOccurrence, savedData: any): IPromise<any> {
        return monoOccurrence.save(savedData)
            .then(() => {
                // La liste utilise un service différent, alors il faut toujours rafraichir meme lors des updates
                vm.multiOccurrence.fetchDataList();

                notificationHandler.succes();

                //On doit retrouver la bonne donnée afin de fermer la row
                const data = vm.multiOccurrence.dataList && vm.multiOccurrence.dataList.find(ele => ele[vm.multiOccurrence.cleint] === savedData.$id) || savedData;
                if (data.$opened) {
                    closeRow(data);
                }
            });
    }

    function saveNewAction(savedData: any): IPromise<any> {
        return saveNew(savedData)
            .catch((error: IApiError) => {
                const confirmAction = () => saveNew(savedData);
                afficherConfirmerSauvegarde(confirmAction, error);
            });
    }

    function saveNew(savedData: any): IPromise<any> {
        return saveRow(vm.newMonoOccurrence, savedData).then(() => {
            vm.closeNew();
        });
    }

    function saveNewAndNextAction(savedData: any): IPromise<any> {
        return saveNewAndNext(savedData)
            .catch((error: IApiError) => {
                const confirmAction = () => saveNewAndNext(savedData);
                afficherConfirmerSauvegarde(confirmAction, error);
            });
    }

    function saveNewAndNext(savedData: any): IPromise<any> {
        return saveRow(vm.newMonoOccurrence, savedData)
            .then(() => {
                vm.newFormCtrl.$setPristine();
                vm.newFormCtrl.$setUntouched();
                vm.newMonoOccurrence.resetData();
                exFocus($element.find('ex-edition'));
            });
    }

    function retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchData();
        }
    }

    function retryValeursDefautErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchValeursDefaut();
        }
    }

    function getTitre() {
        return vm.ecranContextCtrl.ecranDetails.titre;
    }

    function getPageLink(data: any) {
        const params = vm.multiOccurrence.navigateParams ?
            vm.multiOccurrence.navigateParams(data, vm.multiOccurrence) :
            {};

        const pageName = vm.multiOccurrence.navigatePage instanceof Function ? vm.multiOccurrence.navigatePage(data) : vm.multiOccurrence.navigatePage;

        if (!pageName) return;

        return $state.href(pageName, {
            id: data[vm.multiOccurrence.forageCleint],
            menuId: vm.ecranContextCtrl.stateParams.menuId,
            ...params
        });
    }

    function displayNewFormulaire() {
        return Boolean(
            vm.newOpened &&
            vm.multiOccurrence.schema &&
            vm.newMonoOccurrence.schema &&
            !vm.newMonoOccurrence.fetchingValeursDefaut &&
            !vm.newMonoOccurrence.valeursDefautError
        );
    }

    function hasTranscludeDetails() {
        const bloc = (vm.multiOccurrence.bloc || '$NONE$').toUpperCase();
        return Boolean(vm.templateCtrl && vm.templateCtrl.templates[bloc] && vm.templateCtrl.templates[bloc].details)
    }
}
