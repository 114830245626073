import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageMr0151{
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0151Controller(mr0152Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0151 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0152Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.bqecleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0152Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'bqecod', largeur: 90},
            {nom: 'bqedsc', largeur: 262},
            {nom: 'bqedscabr', largeur: 262},
            {nom: 'bqedatdeb', largeur: 100},
            {nom: 'bqedatfin', largeur: 100},
            {nom: 'bqeprical', largeur: 90},
            {nom: 'bqeflgimp', largeur: 120},
            {nom: 'bqeflgporemp', largeur: 120},
            {nom: 'bqeflgimppas', largeur: 120},
            {nom: 'vaebqenivges_dsc', largeur: 197},
            {nom: 'escdsc', largeur: 262},
            {nom: 'unccod', largeur: 90},
            {nom: 'uncdsc', largeur: 262},
            {nom: 'mcbnummth_aff', largeur: 100},
            {nom: 'mcbdsc_aff', largeur: 262},
            {nom: 'tdadsc', largeur: 262},
            {nom: 'bqeflgaffann', largeur: 75},
            {nom: 'bqeflgaffmoi', largeur: 72},
            {nom: 'bqeflgaffjrs', largeur: 72},
            {nom: 'bqeflgaffjrsres', largeur: 91},
            {nom: 'bqeafflib', largeur: 210},
            {nom: 'bqecod_ref', largeur: 95},
            {nom: 'bqedscabr_ref', largeur: 262},
            {nom: 'vacvalcle_cat', largeur: 95},
            {nom: 'vacdsc_cat', largeur: 262},
            {nom: 'mcbnummth_sld', largeur: 90},
            {nom: 'mcbdsc_sld', largeur: 262},
            {nom: 'mcbnummth_trs', largeur: 140},
            {nom: 'mcbdsc_trs', largeur: 262},
            {nom: 'vaebqetrteva_dsc', largeur: 197},
            {nom: 'vaebqetrtuti_dsc', largeur: 197},
            {nom: 'bqedoc', largeur: 262}
        ],
        colonnesCachees: [
            'bqecleint'
        ]
    };
}
