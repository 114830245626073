import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageAC0044 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0044Controller(Menu: IMenuClass) {
    const vm: IPageAC0044 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'dacnumdoc', largeur: 160},
            {nom: 'dacnumdocseq', largeur: 70},
            {nom: 'sysboncmd', largeur: 160},
            {nom: 'dacseqave', largeur: 70},
            {nom: 'dacsou', largeur: 160},
            {nom: 'tydcod', largeur: 75},
            {nom: 'dacdsc', largeur: 250},
            {nom: 'refcod', largeur: 125},
            {nom: 'refnom', largeur: 230},
            {nom: 'etddsc', largeur: 140},
            {nom: 'flgcou', largeur: 80},
            {nom: 'flgnonrec', largeur: 80},
            {nom: 'peccod', largeur: 60},
            {nom: 'caacod', largeur: 105},
            {nom: 'caadsc', largeur: 165},
            {nom: 'etcnumdoc', largeur: 130},
            {nom: 'etcnumdocseq', largeur: 50},
            {nom: 'etcdsc', largeur: 165},
            {nom: 'intcodach', largeur: 110},
            {nom: 'intnomach', largeur: 170},
            {nom: 'intcodadem', largeur: 110},
            {nom: 'intnomdem', largeur: 170},
            {nom: 'intcodreq', largeur: 105},
            {nom: 'intnomreq', largeur: 170},
            {nom: 'vecdaccat', largeur: 55},
            {nom: 'lipcod', largeur: 100},
            {nom: 'lipnomabr', largeur: 170},
            {nom: 'lipadrl01', largeur: 210},
            {nom: 'lipadrl02', largeur: 210},
            {nom: 'dacatt', largeur: 210},
            {nom: 'flgerr_b2b', largeur: 80},
            {nom: 'usrcod', largeur: 120},
            {nom: 'motcod', largeur: 115},
            {nom: 'motdsc', largeur: 270},
            {nom: 'flgtrans', largeur: 80}
        ]
    };
}
