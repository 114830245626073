import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IOperateurService} from "../../../../../core/services/operateur.service";

interface IPageMc0094 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0094Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Operateur: IOperateurService,
                                         $stateParams: any) {
    const vm: IPageMc0094 = this;

    vm.multiOccurrenceOptions = {
        filtres: [
            {
                colonne: 'el2cleint',
                operateur: Operateur.EGALE,
                valeur: $stateParams.el2cleint,
                visible: false
            }
        ],
        colonnesVisibles: [
            {nom: 'blocod'   , largeur: 160},
            {nom: 'blodsc'   , largeur: 300},
            {nom: 'e2bprcdep', largeur: 160},
            {nom: 'e2bmntdep', largeur: 160}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('el2cleint', {
                default: $stateParams.el2cleint,
                hidden: true
            }),
            new FormulaireItem('blocleint'),
            new FormulaireItem('e2bprcdep'),
            new FormulaireItem('e2bmntdep'),
        ], {largeurDefaut: 33})
    };
}
