import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageGs0014 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0014Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGs0014 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'frmdsc', largeur: 140},
            {nom: 'frmtyppapdsc', largeur: 140},
            {nom: 'frmoripapdsc', largeur: 140}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('frmdsc'),
            new FormulaireItem('frmtyppap'),
            new FormulaireItem('frmoripap')
        ], {largeurDefaut: 20})
    };
}
