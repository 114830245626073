import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCR0049 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0049Controller(
    cr0050Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCR0049 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cr0050Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cr0050Route.NAME)
        ]),
        colonnesFixesGauche: [
            { nom: 'dofnumetu', largeur: 135 },
            { nom: 'dofann', largeur: 110 }
        ],
        colonnesVisibles: [
            { nom: 'dofnomprnetu', largeur: 250 },
            { nom: 'dofadrl1a', largeur: 180 },
            { nom: 'dofadrl1b', largeur: 180 },
            { nom: 'dofadrl2', largeur: 180 },
            { nom: 'dofadrl3', largeur: 180 },
            { nom: 'dofadrl4', largeur: 180 },
            { nom: 'dofcodpos', largeur: 110 },
            { nom: 'dofnas', largeur: 110 },
            { nom: 'dofmoideb1', largeur: 110 },
            { nom: 'dofmoifin1', largeur: 110 },
            { nom: 'doffra1', largeur: 110 },
            { nom: 'doftc1', largeur: 110 },
            { nom: 'dofmoideb2', largeur: 110 },
            { nom: 'dofmoifin2', largeur: 110 },
            { nom: 'doffra2', largeur: 110 },
            { nom: 'doftc2', largeur: 110 },
            { nom: 'dofmoideb3', largeur: 110 },
            { nom: 'dofmoifin3', largeur: 110 },
            { nom: 'doffra3', largeur: 110 },
            { nom: 'doftc3', largeur: 110 },
            { nom: 'dofmoideb4', largeur: 110 },
            { nom: 'dofmoifin4', largeur: 110 },
            { nom: 'doffra4', largeur: 110 },
            { nom: 'doftc4', largeur: 110 },
            { nom: 'dofmnttotfra', largeur: 130 },
            { nom: 'doftottc', largeur: 110 },
            { nom: 'dofmntpossec', largeur: 150 },
            { nom: 'doftp1', largeur: 80 },
            { nom: 'doftp2', largeur: 80 },
            { nom: 'doftp3', largeur: 80 },
            { nom: 'doftp4', largeur: 80 },
            { nom: 'doftottp', largeur: 90 },
            { nom: 'dofmntdon', largeur: 110 },
            { nom: 'dofcoduni', largeur: 80 },
            { nom: 'doftptc', largeur: 80 },
            { nom: 'dofcoddes', largeur: 80 },
            { nom: 'dofnompro', largeur: 180 },
            { nom: 'dofnumrel', largeur: 140 },
            { nom: 'dofcodperm', largeur: 140 },
            { nom: 'dofnumrelimp', largeur: 140 },
            { nom: 'dofgraetu', largeur: 80 },
            { nom: 'dofnumreltsm', largeur: 140 },
            { nom: 'dofnument', largeur: 140 }
        ],
        colonnesFixesDroite: [
            { nom: 'dofcodrel', largeur: 135 },
            { nom: 'dofdatoff', largeur: 110 }
        ],
    };
}
