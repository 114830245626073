import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0033 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0033Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0033 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'mcpcod', largeur: 125 },
            { nom: 'mcpnom', largeur: 185 },
            { nom: 'mcpprn', largeur: 185 },
            { nom: 'mcpsrucod', largeur: 135 },
            { nom: 'etbcod', largeur: 135 },
            { nom: 'etbnom', largeur: 185 },
            { nom: 'mcpflgact', largeur: 110 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mcpcod'),
            new FormulaireItem('mcpnom'),
            new FormulaireItem('mcpprn'),
            new FormulaireItem('mcpsrucod'),
            new FormulaireItem('etbcleint'),
            new FormulaireItem('mcpflgact', { default: 1 })
        ],
            {
                largeurDefaut: 33
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
