import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';

interface IPageGb0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0006Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass
) {
    const vm: IPageGb0006 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            supprime: false,
            edition: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('doccleint'),
            new FormulaireItem('intcleintdep'),
            new FormulaireItem('gbpcleint'),
            new FormulaireItem('refcleint'),
            new FormulaireItem('grp_eta', {default: 'ATT'})
            //new FormulaireItem('indmsg')
        ]),
        colonnesFixesDroite: [
            { nom: 'vaestugbtdsc', largeur: 110},
            //{ nom: 'indmsg', largeur: 20}
        ],
        colonnesVisibles: [
            { nom: 'dacnumdoc', largeur: 100 },
            { nom: 'dacnumdocseq', largeur: 70 },
            { nom: 'dacdsc', largeur: 240 },
            { nom: 'gebnumref', largeur: 170 },
            { nom: 'gebdsc', largeur: 180 },
            { nom: 'gebflgact', largeur: 60 },
            { nom: 'intcoddepnum', largeur: 120 },
            { nom: 'intnomdep', largeur: 150 },
            { nom: 'lolnumloc', largeur: 90 },
            { nom: 'loldsc', largeur: 200 },
            { nom: 'unacod', largeur: 80 },
            { nom: 'unadsc', largeur: 200 },
            { nom: 'gbpcod', largeur: 130 },
            { nom: 'gbpdsc', largeur: 210 },
            { nom: 'refcodnum', largeur: 110 },
            { nom: 'refnom', largeur: 200 },
            { nom: 'gebfon', largeur: 170 },
            { nom: 'gebmod', largeur: 170 },
            { nom: 'gebnumser', largeur: 180 },
            { nom: 'gbtqte', largeur: 100 },
            { nom: 'gebmnt', largeur: 110 },
            { nom: 'intcodtecnum', largeur: 160 },
            { nom: 'intnomtec', largeur: 200 },
            { nom: 'gcbcod', largeur: 160 },
            { nom: 'gcbdsc', largeur: 180 },
            { nom: 'vecgebfreutl', largeur: 160 },
            { nom: 'fredsc', largeur: 160 },
            { nom: 'gebannacq', largeur: 110 },
            { nom: 'gebflgcon', largeur: 70 },
            { nom: 'connumdocnum', largeur: 90 },
            { nom: 'condsc', largeur: 180 },
            { nom: 'gebdatfin', largeur: 140 },
            { nom: 'gebnomart', largeur: 170 },
            { nom: 'gebprnart', largeur: 170 },
            { nom: 'gebtit', largeur: 190 },
            { nom: 'gebdatcre', largeur: 110 },
            { nom: 'vaedrtaut', largeur: 120 },
            { nom: 'vaedscaut', largeur: 130 },
            { nom: 'vaedrtexp', largeur: 120 },
            { nom: 'vaedscexp', largeur: 130 },
            { nom: 'gebmat', largeur: 140 },
            { nom: 'gebtec', largeur: 140 },
            { nom: 'gebedt', largeur: 140 },
            { nom: 'vecgebacq', largeur: 100 },
            { nom: 'gebmntval', largeur: 130 },
            { nom: 'hvmdatvig', largeur: 80 },
            { nom: 'hvmcom', largeur: 170 },
            { nom: 'gebhaucad', largeur: 150 },
            { nom: 'unmcodhca', largeur: 60 },
            { nom: 'gebloncad', largeur: 150 },
            { nom: 'unmcodlca', largeur: 60 },
            { nom: 'gebprocad', largeur: 150 },
            { nom: 'unmcodpca', largeur: 60 },
            { nom: 'gebhausan', largeur: 150 },
            { nom: 'unmcodhsc', largeur: 60 },
            { nom: 'geblonsan', largeur: 150 },
            { nom: 'unmcodlsc', largeur: 60 },
            { nom: 'gebprosan', largeur: 150 },
            { nom: 'unmcodpsc', largeur: 60 },
            { nom: 'ivdnom', largeur: 140 },
            { nom: 'ivdprn', largeur: 140 },
            { nom: 'ivdmen', largeur: 140 },
            { nom: 'ivedat', largeur: 130 },
            { nom: 'ivecon', largeur: 170 },
            { nom: 'iveindsec', largeur: 120 }
        ],
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_TRANSFERER',
                'BOUTRA',
                {
                    selection: { nom: 'transfer' },
                    fonction: 'BOUTRA'
                }
            ),
            new MenuItemSelection(
                'LBL_BTN_ECLATEMENT',
                'BOUECL',
                {
                    selection: { nom: 'eclatement' },
                    fonction: 'BOUECL'
                }
            )
        ]),
    };
}
