import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IStateParamsService } from 'angular-ui-router';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0019 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr0019Controller(Formulaire: IFormulaireClass,
    $stateParams: IStateParamsService,
    MenuItemAction: IMenuItemActionClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0019 = this;
    vm.multiOccurrenceOptions = {    
        colonnesVisibles: [
            { nom: 'grqnom', largeur: 100 },
            { nom: 'qsdnom', largeur: 180 },
            { nom: 'qsdtxtqst', largeur: 180 },
            { nom: 'qsrflgon_dsc', largeur: 10 },
            { nom: 'qdvtxtval', largeur: 150 },
            { nom: 'qsrtxtaut', largeur: 150 }
        ],
        colonnesCachees: [
            'nbs',
            'qsdflgon',
            'qsdflgaut'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('qstcleint', { hidden: true, default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.qstcleint }),
            new FormulaireItem('qsrcleintref', { hidden: true, default: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.eplcleint }),
            new FormulaireItem('qsdcleint', { resourceParams: () => ({ qstcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.qstcleint }) }),
            new FormulaireItem('flgon', {
                largeur: 75
            }),
            new FormulaireItem('flgnr', {
                largeur: 75
            }),
            new FormulaireItem('flgnrep', {
                largeur: 75
            }),
            new FormulaireItem('qsrflgon', { default: null }),
            new FormulaireItem('qdvcleint'),
            new FormulaireItem('qsrtxtaut', { readonly: (data) => !data.qsdflgaut }),
            new FormulaireItem('qsdflgnepasrep', { hidden: true })
        ]),
        menus: [
            new MenuItemAction(
                'LBL_GEN',
                'BTN_GEN',
                () => ({}),
                {
                    icon: '',
                    iconButton: false,
                    successAction: () => vm.multiOccurrence.fetchDataList()
                }
            )
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('eplcleint', { required: true, default: ecranDetails.valeurs.eplcleint, hidden: $stateParams.employe, ignoreEtat: $stateParams.employe }),
            new FormulaireItem('qstcleint', { required: true, default: ecranDetails.valeurs.qstcleint })
        ]),
        fonctions: {
            edition: true,
            nouveau: true,
            importation: true,
            rechercheCollapsable: false,
            criteresSuggeresVisibles: !$stateParams.employe
        }
    };
}
