import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IStateParamsService} from "angular-ui-router";

interface IPageRr0546 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Rr0546Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService) {
    const vm: IPageRr0546 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'rcecod', largeur: 105},
            {nom: 'rcedscabr', largeur: 210},
            {nom: 'ppacod', largeur: 130},
            {nom: 'ppadatdeb', largeur: 130},
            {nom: 'ppadatfin', largeur: 105},
            {nom: 'cajmntemp', largeur: 140},
            {nom: 'cajmntemr', largeur: 160},
            {nom: 'cajqteadm', largeur: 139},
            {nom: 'cajmntadm', largeur: 139},
            {nom: 'cajcom', largeur: 341}
        ],
        colonnesFixesDroite: [
            {nom: 'cajflgact', largeur: 90}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('demcleint'),
            new FormulaireItem('rcecleint'),
            new FormulaireItem('aut__unpcleint'),
            new FormulaireItem('ppacleint', {resourceParams: (data: any) => ({
                    demcleint: data.demcleint,
                    ciecleint: data.ciecleint
                })}),
            new FormulaireItem('cajmntemp'),
            new FormulaireItem('cajmntemr'),
            new FormulaireItem('cajqteadm'),
            new FormulaireItem('cajmntadm'),
            new FormulaireItem('cajflgact', {default: 1}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cajcom', {largeur: 100, enableCount: true})
        ],{largeurDefaut: 25})
    };
}
