import { IStateParamsService } from "angular-ui-router";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';

interface IPageMr1009 {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    aviMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mr1009Controller(mr1010Route: IRoute,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass,
                                         $stateParams: IStateParamsService) {
    const vm = this as IPageMr1009;

    vm.valeursEcran = ecranDetails.valeurs;
    vm.aviMultiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            avisEmploye: false
        },
        bloc: 'avi',
        navigatePage: mr1010Route.NAME,
        navigateParams: () => {
            return {
                demcleint: $stateParams.demcleint,
                stamnetabref: $stateParams.stamnetabref,
                avicleintref: $stateParams.avicleintref
            }
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(mr1010Route.NAME, () => ({
                demcleint: $stateParams.demcleint,
                stamnetabref: $stateParams.stamnetabref,
                avicleintref: $stateParams.avicleintref,
                stamnetabori: $stateParams.stamnetabref,
                avicleintori: $stateParams.avicleintref
            }))
        ]),
        filtresParametres: [
            { colonne: 'stamnetabref', visible: false },
            { colonne: 'avicleintref', visible: false }
        ],
        colonnesVisibles: [
            { nom: 'avidatavi', largeur: 150 },
            { nom: 'avitxt', largeur: 500 },
            { nom: 'aviflgenv', largeur: 110 }
        ]
    }
}