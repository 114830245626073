import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';

interface IPageCr2005 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */
export default function Cr2005Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr2005 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            exportation: false
        },
        formulaire: new Formulaire('LBL_BLC_FAC', [
            new FormulaireItem('refcod', {largeur: 50, modifAllow: false}), 
            new FormulaireItem('refnom', {largeur: 50, modifAllow: false}), 
            new FormulaireItem('radcleintfac', {largeur: 100})
        ])
    };
}
