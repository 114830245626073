import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageRr0544 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0544Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0544 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 120},
            {nom: 'tagdscabr', largeur: 220},
            {nom: 'ctycodmen', largeur: 120},
            {nom: 'vaecortagmenval_dsc', largeur: 180}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tagcod'),
            new FormulaireItem('ctycodmen'),
            new FormulaireItem('vaecortagmenval')
        ],{largeurDefaut: 25})
    };
}
