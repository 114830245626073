import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'zz0006',
    ecrans: [
        '00-2'
    ],
    params: [
        'zchcleint',
        '?reference'
    ]
});

