import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0162 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
interface IMc0162Params {
    vaesysdevcod: string;

}
/* @ngInject */
export default function Mc0162Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IMc0162Params,) {
    const vm: IPageMc0162 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            {colonne: 'vaesysdevcod', affichage: true},
            {colonne: 'vaedevtyptau', affichage: true}
        ],
        colonnesVisibles: [
            {nom: 'devtaucou', largeur: 162},
            {nom: 'devdateff', largeur: 130}
        ],
        colonnesCachees:['vaesysdevcod'],
        formulaire: new Formulaire([
            new FormulaireItem('devtaucou'),
            new FormulaireItem('devdateff'),
            new FormulaireItem('vaesysdevcod',{ default: $stateParams.vaesysdevcod })
        ], {largeurDefaut: 50})
    };
}
