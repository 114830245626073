import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageCo0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Co0002Controller(Formulaire: IFormulaireClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         co0019Route:IRoute,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCo0002 = this;

    const forageGrc = new MenuItemForage(
        co0019Route.NAME,
        (data: any) => ({
            grccleint: data.grccleint
        }),
        {
            hidden: (data: any) => data.vaegrcapp !== 'SPEC'
        }

    );

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'grcseqaff', largeur: 140},
            {nom: 'grccod', largeur: 110},
            {nom: 'grcdsc', largeur: 350},
            {nom: 'vaegrcapp', largeur: 195, menu: [forageGrc]},
            {nom: 'grcflginfsclobl', largeur: 195}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('grcseqaff'),
            new FormulaireItem('grccod', {required: true}),
            new FormulaireItem('grcdsc', {required: true}),
            new FormulaireItem('vaegrcapp', {required: true}),
            new FormulaireItem('grcflginfsclobl', {required: true})
        ]
        , {
            largeurDefaut: 20
        }),
        fonctions: Object.assign({
            edition: true,
            nouveau: true,
            rechercheCollapsable: false
        })
    };
}
