import {IComponentController} from 'angular';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPagerr0021 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    epeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function rr0021Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass) {

    const vm: IPagerr0021 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };
        
        vm.monoOccurrenceOptions = {
            noId: true,
            formulaire: new Formulaire(' ', [
                new FormulaireItem('eplnummat', {largeur: 10, readonly: true}),
                new FormulaireItem('eplnomprn', {largeur: 80, readonly: true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eplnas', {largeur: 10, readonly: true}),
                new FormulaireItem('eplnasimm', {largeur: 45}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eplflgimm', {largeur: 10}),
                new FormulaireItem('epldatobtcit'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('pyscleint', {largeur: 60}),
                new FormulaireItemSautDeLigne(),
                new FormulaireGroupe('LBL_NAIS', new Formulaire([
                    new FormulaireItem('pyscleintnai', {largeur: 40}),
                    new FormulaireItem('etacleintnai', {largeur: 40})
                ]))
            ])
        }

        vm.epeMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'vaetypepe_dsc', largeur: 110},
                {nom: 'epedatper', largeur: 110},
                {nom: 'epedatexp', largeur: 110},
                {nom: 'epedatexpnas', largeur: 110},
                {nom: 'epecom', largeur: 400}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('eplcleint',  {default: () => vm.monoOccurrence.data.eplcleint, hidden: true } ),
                new FormulaireItem('vaetypepe'),
                new FormulaireItem('epedatper'),
                new FormulaireItem('epedatexp', {required:true}),
                new FormulaireItem('epedatexpnas'),
                new FormulaireItem('epecom')
            ])
        }
    }
}
