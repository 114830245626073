import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageIN0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0015Controller( Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass,
                                          FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                          ecranDetails: IEcranDetailsResourcesEntite
 ) {
    const vm: IPageIN0015 = this;
    vm.valeursEcran = ecranDetails;  

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable:false,            
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup', { hidden: () => vm.valeursEcran.valeurs.vaehldtypimp !== 1 }),           
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')            
        ]),
        colonnesFixesGauche: [
            {nom: 'dinnumdoc',      largeur: 160},
            {nom: 'dinnumdocseq',   largeur: 130},
            {nom: 'tydcod',          largeur: 100}
        ],
        colonnesVisibles: [           
            {nom: 'magcod',          largeur: 200},
            {nom: 'magnom',          largeur: 200},
            {nom: 'dindsc',          largeur: 300},
            {nom: 'peccod',          largeur: 80},
            {nom: 'etddsc',          largeur: 200},
            {nom: 'dinmnt',          largeur: 126},
            {nom: 'intcodreq',       largeur: 150},
            {nom: 'intnomreq',       largeur: 200},
            {nom: 'intcoddem',       largeur: 150},
            {nom: 'intnomdem',       largeur: 200},
            {nom: 'vecdincat',       largeur: 200},
            {nom: 'tyvcod',          largeur: 200},
            {nom: 'tyvdsc',          largeur: 200},
            {nom: 'refcod',          largeur: 150},
            {nom: 'refnom',          largeur: 150},
            {nom: 'dinnumref',       largeur: 200},
            {nom: 'usrcod',          largeur: 150},
            {nom: 'datcre',          largeur: 140}
        ]
    };
}