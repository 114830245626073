import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0042 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0042Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0042 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'pmacod', largeur: 78},
            {nom: 'pmadsc', largeur: 394},
            {nom: 'pmaflgact', largeur: 64}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pmacod', {required: true}),
            new FormulaireItem('pmadsc', {required: true}),
            new FormulaireItem('pmaflgact', {default: 1})
        ], {largeurDefaut: 33.33}),
        fonctions: {
            supprime: false
        }
    };
}
