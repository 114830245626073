import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import IStateService = angular.ui.IStateService;

interface IPageIF0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IIf0007Params {
    immcleint: number;
    immcod: string;
    vaesysimmtyptrs: string;
}

/* @ngInject */
export default function If0007Controller(Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         if0008Route: IRoute,
                                         Operateur: IOperateurService,
                                         $stateParams: IIf0007Params,
                                         $state: IStateService) {
    const vm: IPageIF0007 = this;

    // Applique un filtre si l'écran est appelé par if0004
    let filtreIf0004 = $stateParams.immcleint ? [
        {
            colonne: 'immcod',
            operateur: Operateur.EGALE,
            valeur:  $stateParams.immcod,
            affichage: true,
            readOnly: true
        },
        {
            colonne: 'vaesysimmtyptrs',
            operateur: Operateur.EGALE,
            valeur:  $stateParams.vaesysimmtyptrs,
            affichage: true,
            readOnly: true
        }
    ] : null;

    vm.multiOccurrenceOptions = {
        navigatePage: if0008Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(if0008Route.NAME)
        ]),
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup'),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        filtres: filtreIf0004,
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_COPIE',
                'COPIE_ECI',
                (data: any) => ({
                    id: data.ecicleint
                }),
                {
                    icon: 'content_copy',
                    saveFirst: true,
                    successAction: (data: any) => {
                        $state.go(if0008Route.NAME, {
                            id: data.doccleintnew
                        });
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_COPIE_ECRITURE',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 50,
                        formulaire: new Formulaire([
                            new FormulaireItem('tydcleintnew', {
                                required: true, largeur: 50
                            }),
                            new FormulaireItem('immcleintnew', {
                                required: true, largeur: 50
                            })
                        ], {
                            securityCycle: false
                        })
                    },
                    hidden: (rowData: any) => (!rowData.eciflgcop)
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'ecinumdoc', largeur: 120},
            {nom: 'ecinumdocseq', largeur: 100},
            {nom: 'peccod', largeur: 100},
            {nom: 'tydcod', largeur: 100},
            {nom: 'vececicat', largeur: 100},
            {nom: 'immcod', largeur: 130},
            {nom: 'ecidsc', largeur: 250},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 200},
            {nom: 'etddsc', largeur: 150},
            {nom: 'etddatcre', largeur: 175},
            {nom: 'catcod', largeur: 125},
            {nom: 'catdsc', largeur: 245},
            {nom: 'flgcou', largeur: 100},
            {nom: 'eciflgref', largeur: 100},
            {nom: 'eciflgverfin', largeur: 120},
            {nom: 'eciflgmsg', largeur: 100},
            {nom: 'flgadhoc', largeur: 120},
            {nom: 'intcodcib', largeur: 120},
            {nom: 'intnomcib', largeur: 200},
            {nom: 'usrcod', largeur: 130},
            {nom: 'eciflgautrep', largeur: 100},
            {nom: 'eciflgpij', largeur: 140},
            {nom: 'credit', largeur: 100}
        ],
        colonnesCachees: [
            'ecicleint',
            'eciflgcop'
        ]
    };
}
