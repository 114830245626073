import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageCr0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0001Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageCr0001 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'ctecod', largeur: 115 },
            { nom: 'ctedsc', largeur: 272 },
            { nom: 'vaectecat', largeur: 166 },
            { nom: 'vaectetypcal', largeur: 174 },
            { nom: 'ctejrsdel', largeur: 125 },
            { nom: 'ctejrsdeladd', largeur: 140 },
            { nom: 'cteprc', largeur: 140 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ctecod', { required: true }),
            new FormulaireItem('ctedsc'),
            new FormulaireItem('vaectecat', { required: true }),
            new FormulaireItem('vaectetypcal', { required: true }),
            new FormulaireItem('ctejrsdel'),
            new FormulaireItem('ctejrsdeladd'),
            new FormulaireItem('cteprc'),
            new FormulaireGroupe('TESTCALCUL'
                , new Formulaire([
                    new FormulaireItem('datcal', {menu: [new MenuItemAction("BOUCAL", "BOUCAL", () => ({}), {
                        iconButton: false,
                        icon: "view_day",
                        class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                            successAction: (result, occurrence, params, data) => {
                                data.datres = result.datres;
                            }
                        })] }),
                    new FormulaireItem('datres', {readonly: true})
                ]), { hidden: false })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
