import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0039 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function mr0039Controller(Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         mr0040Route: IRoute)
{
    const vm: IPageMr0039 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0040Route.NAME)
        ]),
        navigatePage: mr0040Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'rolcod', largeur: 120},
            {nom: 'roldscabr', largeur: 213},
            {nom: 'roldsc', largeur: 350},
            {nom: 'rolflgunoobl', largeur: 110},
            {nom: 'rolflgstr', largeur: 110},
            {nom: 'rolflgact', largeur: 110}
        ]
    };
}
