import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPagePf0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePf0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('cabcod', {largeur: 33.33}),
            new FormulaireItem('cabdsc', {largeur: 33.33}),
            new FormulaireItem('urgcleint', {largeur: 33.33})
        ]),
        colonnesVisibles: [
            {nom: 'cabcod', largeur: 150},
            {nom: 'cabdsc', largeur: 350},
            {nom: 'urgcleint', largeur: 350}
        ]
    };
}
