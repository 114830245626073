import { IComponentController, ILocationService, IHttpService } from "angular";
import { IApiConfig } from "../../../../core/interfaces/api-config.interface";
import { IBasePathConstant } from "../../../../core/constants/base-path.constant";
import { IRoute } from "../../../../core/interfaces/route.interface";
import { IStateService } from "angular-ui-router";

interface IPageLoginSso extends IComponentController { }

/* @ngInject */
export default function LoginSsoController($location: ILocationService,
    $http: IHttpService,
    ApiConfig: IApiConfig,
    basePath: IBasePathConstant,
    loginSsoRoute: IRoute,
    erreurAccesEcranRoute: IRoute,
    $state: IStateService) {
    const vm: IPageLoginSso = this;

    vm.$onInit = $onInit;

    function $onInit() {
        const gid = $location.search()["gid"];
        const ticket = $location.search()["ticket"];
        const returnurl = $location.search()["returnurl"] || "/accueil";

        let customPort = '';

        if (($location.protocol() === 'https' && $location.port() !== 443)
            || ($location.protocol() === 'http' && $location.port() !== 80)) {
            customPort = `:${$location.port()}`;
        }

        const ssoReturlUrl = `${$location.protocol()}://${$location.host()}${customPort}${basePath}${ loginSsoRoute.URL}?returnurl=${returnurl}`;
        if (!ticket) {
            $http.get(`${ApiConfig.URL}/login-sso/url?returnurl=${ssoReturlUrl}${gid ? "&gid=" + gid : ""}`).then((result: any) => {
                location.href = result.data.ssoUrl;
            }).catch(() => {
                $state.go(erreurAccesEcranRoute.NAME, { returnurl: ssoReturlUrl });
            });
        } else {
            $http.post(`${ApiConfig.URL}/login-sso`, { ticket, returnurl: ssoReturlUrl }).then(() => {
                location.href = basePath + returnurl.replace(/~~/g, "&");
            }).catch(() => {
                $state.go(erreurAccesEcranRoute.NAME, { returnurl: ssoReturlUrl });
            });
        }
    }
}