import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageZz0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}
/* @ngInject */
export default function Zz0006Controller(zz0003Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageZz0006 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: zz0003Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                zlicleint: rowData.zlicleint
            };
        },
        colonnesVisibles: [
            { nom: 'nbrerr', largeur: 100 },
            { nom: 'zliexc', largeur: 100 },
            { nom: 'enccod', largeur: 150 },
            { nom: 'encmnt', largeur: 130 },
            { nom: 'encdat', largeur: 110 },
            { nom: 'moecod', largeur: 150 },
            { nom: 'cesdsc', largeur: 180 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('zliexc', { largeur: 10 }),
            new FormulaireItem('enccod', { largeur: 10 }),
            new FormulaireItem('encmnt', { largeur: 15 }),
            new FormulaireItem('encdat', { largeur: 10 }),
            new FormulaireItem('moecod', { largeur: 10 }),
            new FormulaireItem('cesdsc', { largeur: 30 })
        ])
    };
}
