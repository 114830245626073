import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0314 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMr0314Params {
    alicleint: string;
}

/* @ngInject */
export default function Mr0314Controller(Formulaire: IFormulaireClass,
                                         $stateParams: IMr0314Params,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0314 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            importation:true,
            boutonDupliquer:false
        },
        filtres: [{
            colonne: 'alicleint',
            valeur: $stateParams.alicleint,
            visible: false
        }],
        colonnesVisibles: [
            {nom: 'tagcod', largeur: 150},
            {nom: 'tagdscabr', largeur: 400},
            {nom: 'atgflginvval', largeur: 170},
            {nom: 'alicleint', largeur: 170},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('alicleint',{
                default: $stateParams.alicleint,
                hidden:true}),
            new FormulaireItem('tagcleint'),
            new FormulaireItem('atgflginvval', {default: 0})
        ])
    };
}
