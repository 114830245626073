import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IStateParamsService} from 'angular-ui-router';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPageRR0135 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0135Controller(parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         Operateur: IOperateurService,
                                         rr0132Route: IRoute,
                                         rr0133Route: IRoute,
                                         rr0134Route: IRoute) {
    const vm: IPageRR0135 = this;

    const colonnesVisibles = [];

    if (ecranDetails.params.p_prv == 'EAD') {
        colonnesVisibles.push(
                {nom: 'eplnummat', largeur: 100, hidden: ($stateParams.employe || $stateParams.eplcleint)},
                {nom: 'eplnomprn', largeur: 262, hidden: ($stateParams.employe || $stateParams.eplcleint)},

                {nom: 'odidatvig', largeur: 100},
                {nom: 'ecldsceta', largeur: 180},
                {nom: 'odiadr1', largeur: 158},
                {nom: 'odiadr2', largeur: 158},
                {nom: 'odiadr3', largeur: 158},
                {nom: 'odicodpos', largeur: 89},
                {nom: 'pyscod', largeur: 89},
                {nom: 'pysnomfra', largeur: 262},
                {nom: 'etacod', largeur: 89},
                {nom: 'etanomfra', largeur: 262},
                {nom: 'muncod', largeur: 89},
                {nom: 'munnomfra', largeur: 262},
                {nom: 'tadcod', largeur: 100},
                {nom: 'taddsc', largeur: 262},
                {nom: 'odiflgcor', largeur: 100},
                {nom: 'odiflgact', largeur: 75},
                {nom: 'odiflgchq', largeur: 91},
                {nom: 'odiflgfinann', largeur: 76},
                {nom: 'odiflgerr', largeur: 91},
                {nom: 'odicom', largeur: 262},
                {nom: 'ttccod', largeur: 65},
                {nom: 'ttcdsc', largeur: 210},
                {nom: 'trnflgstaref', largeur: 91},
                {nom: 'trnflgstafin', largeur: 76},
                {nom: 'trnflgstafer', largeur: 72},
                {nom: 'trnflgstaann', largeur: 75}
            );
    } else if (ecranDetails.params.p_prv == 'ETL') {
                colonnesVisibles.push(
                        {nom: 'eplnummat', largeur: 100, hidden: ($stateParams.employe || $stateParams.eplcleint)},
                        {nom: 'eplnomprn', largeur: 262, hidden: ($stateParams.employe || $stateParams.eplcleint)},
                        {nom: 'odidatvig', largeur: 100},
                        {nom: 'ecldsceta', largeur: 180},
                        {nom: 'odinumtel', largeur: 105},
                        {nom: 'odinumpos', largeur: 100},
                        {nom: 'ttlcod', largeur: 100},
                        {nom: 'ttldsc', largeur: 262},
                        {nom: 'odiflgcor', largeur: 100},
                        {nom: 'odicom', largeur: 262},
                        {nom: 'ttccod', largeur: 65},
                        {nom: 'ttcdsc', largeur: 210},
                        {nom: 'trnflgstaref', largeur: 91},
                        {nom: 'trnflgstafin', largeur: 76},
                        {nom: 'trnflgstafer', largeur: 72},
                        {nom: 'trnflgstaann', largeur: 75}
                    );
            } else if (ecranDetails.params.p_prv == 'EFD') {
                        colonnesVisibles.push(
                                {nom: 'eplnummat', largeur: 100, hidden: ($stateParams.employe || $stateParams.eplcleint)},
                                {nom: 'eplnomprn', largeur: 262, hidden: ($stateParams.employe || $stateParams.eplcleint)},
                                {nom: 'odidatvig', largeur: 100},
                                {nom: 'ecldsceta', largeur: 180},
                                {nom: 'inscod', largeur: 100},
                                {nom: 'insdsc', largeur: 262},
                                {nom: 'succod', largeur: 105},
                                {nom: 'sucdsc', largeur: 262},
                                {nom: 'odicptbqe', largeur: 90},
                                {nom: 'odicom', largeur: 262},
                                {nom: 'ttccod', largeur: 65},
                                {nom: 'ttcdsc', largeur: 262},
                                {nom: 'trnflgstaref', largeur: 91},
                                {nom: 'trnflgstafin', largeur: 76},
                                {nom: 'trnflgstafer', largeur: 72},
                                {nom: 'trnflgstaann', largeur: 75}
                        );
                    }

    vm.multiOccurrenceOptions = {
        navigatePage: (rowData: any) => {
            if ( ecranDetails.params.p_prv === 'EAD') {
                return rr0132Route.NAME
            }
            else if (ecranDetails.params.p_prv === 'ETL') {
                return rr0133Route.NAME
            }
            else if (ecranDetails.params.p_prv === 'EFD') {
                return rr0134Route.NAME
            }
            /*
                else {
                        return ????Route.NAME
                    }
            */
        },
//        navigateParams: (rowData: any) => ({
//            id: rowData.odicleint
//        }),
        fonctions: {
            nouveau: false
        },
        filtres: parametresRecherche(ecranDetails),
        filtresParametres: [{
            colonne: 'eplcleint'
        }],
        colonnesVisibles
    };

}
