import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageGs0021 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0021Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0021 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaepascat', {
                required: false
            })
        ]),
        colonnesCachees: [
            'pasnomeleval'
        ],
        colonnesVisibles: [
            {nom: 'pasdsc', largeur: 300},
            {nom: 'pavval', largeur: 150},
            {nom: 'flgcli', largeur: 100},
            {nom: 'pasdsclng', largeur: 400},
            {nom: 'pasvaldev', largeur: 180}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pavval', { hidden: (data) => data.pasnomeleval, required: (data) => !data.pasnomeleval }),
            new FormulaireItem('pavvalele', {resourceParams: (data:any) => ({ vaeelecle: data.pasnomeleval }), hidden: (data) => !data.pasnomeleval, required: (data) => data.pasnomeleval })
        ])
    };
}
