import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IMenuItemSelectionClass} from '../../../../../core/services/menu/menu-item-selection.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFilterExDate} from '../../../../../core/filters/ex-date.filter';

interface IPagePT0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt031Controller( as0081Route: IRoute,
                                         exDateFilter: IFilterExDate,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemSelection: IMenuItemSelectionClass) {
    const vm: IPagePT0031 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_hopcleint'),
            new FormulaireItem('t_datede', {largeur: 25, default: exDateFilter(new Date())}),
            new FormulaireItem('t_datea', {largeur: 25, default: exDateFilter(new Date())}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_pdvcleint'),
            new FormulaireItem('t_aqtcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_demcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_vaeptexc'),
        ], {largeurDefaut: 50}),
        colonnesFixesGauche: [
            {nom: 'hoddat', largeur: 126},
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 180}
        ],
        colonnesVisibles: [
            {nom: 'hodhredeb', largeur: 100},
            {nom: 'hodhrefin', largeur: 100},
            {nom: 'hodqte', largeur: 90},
            {nom: 'podhreent', largeur: 100},
            {nom: 'podhresor', largeur: 100},
            {nom: 'actcod', largeur: 130},
            {nom: 'actdsc', largeur: 200},
            {nom: 'hodjrs', largeur: 100},
            {nom: 'aqtcod', largeur: 120},
            {nom: 'aftcod', largeur: 120},
            {nom: 'temcod', largeur: 120},
            {nom: 'temdscabr', largeur: 200},
            {nom: 'pdvcod', largeur: 120},
            {nom: 'pdvdsc', largeur: 200},
            {nom: 'hopnumdoc', largeur: 120},
            {nom: 'nbr_pod', largeur: 115,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_AS0081',
                        as0081Route.NAME,
                        (data: any) => {
                            return {
                                eplcleint: data.eplcleint,
                                unpcleint: data.unpcleint,
                                datede: data.hoddat,
                                datea: data.hoddat
                            };
                        },
                        {
                            disabled: (data: any) => {
                                return !data.nbr_pod;
                            }
                        }
                    )
                ]
            },
        ],
        colonnesCachees: [
            'eplcleint',
            'unpcleint'
        ]
    };
}
