import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRE2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;    
}

/* @ngInject */
export default function Re2000Controller(Menu: IMenuClass,                                        
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRE2000 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        
        colonnesVisibles: [
            {nom: 'ppacod', largeur: 100},
            {nom: 'rmsmsg', largeur: 563}
        ]
    };
}
