import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemActionImportationClass } from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IMenuItemActionTelechargementClass } from '../../../../../core/services/menu/menu-item-action-telechargement.service';
import { IFilterLibelle } from '../../../../../core/filters/ex-libelle.filter';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IStateService } from 'angular-ui-router';

interface IPageAc2001 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    eccMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac2001Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    exLibelleFilter: IFilterLibelle,
    Operateur: IOperateurService,
    ac0031Route: IRoute,
    $state: IStateService
) {

    const vm: IPageAc2001 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false,
            exportation: false,
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemAction(
                'LBL_BTN_DET',
                'BOUDET',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        $state.go(ac0031Route.NAME);
                    }
                }
            ),
            new MenuItemActionImportation(
                'LBL_BTN_CHARGER',
                'BOUCHAR',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_CHARGER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('eccnumchg', {
                                largeur: 100,
                                default: (data: any) => data.monoOccurrence.eccnumchg
                            }),
                            new FormulaireItem('flgdel', {
                                default: 1,
                                largeur: 100
                            })
                        ], { libelleUpload: exLibelleFilter('G_LBL_BTN_UPLOAD_BOX_CSV') })
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    typesFichiers: ['.csv'],
                    iconButton: false,
                    class: 'md-raised ex-button-action'/*,
                    hidden: () => (
                        vm.monoOccurrence.data.ecxflgchg
                    )*/
                }
            ),
            new MenuItemAction(
                'LBL_BTN_IMP',
                'BOUIMP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_BTN_IMP',
                        largeurFormulaire: 15,
                        formulaire: new Formulaire([
                            new FormulaireItem('datimp', {
                                largeur: 100,
                                required: true
                            })
                        ])
                    },
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    disabled: () => ( vm.monoOccurrence.data.aut__ecxflgtrt || !vm.monoOccurrence.data.ecxflgchg ),
                }
            )
        ],
        formulaire: new Formulaire('LBL_BLC_ECX', [
            new FormulaireItem('eccnumchg'),
            new FormulaireItem('eccsrc'),
            new FormulaireItemDivider('LBL_DIV_SOM'),
            new FormulaireItem('aut__nbrenr', { readonly: true })
        ])
    };

    vm.eccMultiOccurrenceOptions= {
        bloc: 'ecc',
        filtres: [
            {
                colonne: 'eccflgerr',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesFixesGauche: [
            {nom: 'eccnumlig', largeur: 100,  },
            {nom: 'eccetcnumdoc', largeur: 140 },
            {nom: 'eccetcnumdocseq', largeur: 100 }
        ],
        colonnesVisibles: [
            {nom: 'eccitmdsc', largeur: 300 },
            {nom: 'eccnumcat', largeur: 160 },
            {nom: 'procod', largeur: 140 },
            {nom: 'eccprxneg', largeur: 120 },
            {nom: 'ecccaacod', largeur: 120 },
            {nom: 'eccforcod', largeur: 120 },
            {nom: 'eccitmdatdeb', largeur: 126 },
            {nom: 'eccitmdatfin', largeur: 126 },
            {nom: 'eccmntetr', largeur: 130 },
            {nom: 'eccqteetr', largeur: 130 }
        ],
        colonnesFixesDroite: [
            {nom: 'eccflgerr', largeur: 100 }
        ],
        colonnesCachees: [
            'eccmsg'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('eccnumlig'),
            new FormulaireItemSautDeLigne,
            new FormulaireItem('eccetcnumdoc'),
            new FormulaireItem('eccetcnumdocseq'),
            new FormulaireItem('eccitmdsc'),
            new FormulaireItem('eccnumcat'),
            new FormulaireItem('procod'),
            new FormulaireItem('eccprxneg'),
            new FormulaireItem('ecccaacod'),
            new FormulaireItem('eccforcod'),
            new FormulaireItem('eccitmdatdeb'),
            new FormulaireItem('eccitmdatfin'),
            new FormulaireItem('eccmntetr'),
            new FormulaireItem('eccqteetr')
        ], { largeurDefaut: 33.33 })
    };
}

