import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMc0047 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0047Controller(mc2006Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0047 = this;

    vm.multiOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('pcmdatfer'),
            new FormulaireItem('vaepcmstu', {largeur: 20})
        ], {largeurDefaut: 25}),
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mc2006Route.NAME,
        navigateParams: (data) => ({
            id: data.pcmcleint
        }),
        colonnesVisibles: [
            {nom: 'peccod', largeur: 89},
            {nom: 'pecann', largeur: 110},
            {nom: 'pecper', largeur: 80},
            {nom: 'pecdatdeb', largeur: 100},
            {nom: 'pecdatfin', largeur: 100},
            {nom: 'pecflgreg', largeur: 140},
            {nom: 'pcmdatfer', largeur: 100},
            {nom: 'vaepcmstu_dsc', largeur: 131},
            {nom: 'flgerrmct', largeur: 100}
        ]
    };
}
