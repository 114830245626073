import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mr2004',
    ecrans: ['00-2'],
    params: ['?enxcleint',
             '?edxcleint',
             '?vaeemxtyp']
});

