import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageRr0555 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0555Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageRr0555 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_unpcleint', { resourceParams: (data: any) => ({ t_percleint: data.t_percleint }) }),
            new FormulaireItem('t_ppacleint', { required: true, resourceParams: (data: any) => ({ t_unpcleint: data.t_unpcleint }) }),
            new FormulaireItem('t_percleint', { hidden: true })
        ]),
        colonnesFixesGauche: [
            {nom: 'unpcod'   , largeur: 110},
            {nom: 'unpdsc'   , largeur: 260},
            {nom: 'rcecod'   , largeur: 110}
        ],
        colonnesVisibles: [
            {nom: 'rcedscabr', largeur: 230},
            {nom: 'parmntemp', largeur: 140},
            {nom: 'parmntemr', largeur: 140},
            {nom: 'parqteadm', largeur: 140},
            {nom: 'parmntadm', largeur: 140},
            {nom: 'parqtearr', largeur: 140},
            {nom: 'parmntarr', largeur: 140}
        ]
    };
}
