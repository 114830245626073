import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMR0116 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePrf: IFormulaire;
    formulaireRes: IFormulaire;
    ftdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    pidMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mptMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0116Controller(ecranDetails: IEcranDetailsResourcesEntite,
    Pagination: IPaginationClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMR0116 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulairePrf = new Formulaire('LBL_BLC_PRF', [
            new FormulaireItem('prfcod', {
                largeur: 33
            }),
            new FormulaireItem('prfdsc', {
                modelOptions: {
                    updateOn: 'blur'
                },
                largeur: 33
            }),
            new FormulaireItem('prfdscabr', {
                largeur: 33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeprftyp', {
                largeur: 33
            }),
            new FormulaireItem('prfdatdeb', {
                largeur: 33
            }),
            new FormulaireItem('prfdatfin', {
                largeur: 33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('actcleint', {
                largeur: 33
            }),
            new FormulaireItem('tagcleint', {
                resourceParams: (data: any) => {
                    return {
                        vaeprftyp: data.vaeprftyp
                    };
                },
                largeur: 33
            }),
            new FormulaireItem('tagcleintexc', {
                largeur: 33
            })
        ]);

        // Restriction du type de date
        vm.formulaireRes = new Formulaire('LBL_BLC_RES', [
            new FormulaireItem('prfflgtypdat', { default: 0 })
        ]);

        // PM2 - Type de date
        vm.ftdMultiOccurrenceOptions = {
            bloc: 'ftd',
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 5, 15]
            }),
            fonctions: {
                pagination: true,
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('vaedlitypdatald'),
                new FormulaireItem('ftdflgact', { default: 1 })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                'vaedlitypdatald_dsc',
                'indflgact'
            ]
        };

        // PM2 - TAG indemnité
        vm.pidMultiOccurrenceOptions = {
            bloc: 'pid',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint'),
                new FormulaireItem('pidnbrjrsgen')
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                'tagcod',
                'tagdscabr',
                'pidnbrjrsgen'
            ],
            colonnesCachees: [
                'flgaffgen'
            ]
        };

        // PM2 - Carence
        vm.mptMultiOccurrenceOptions = {
            bloc: 'mpt',
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire([
                new FormulaireItem('actcleint', { largeur: 100 })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                'actcod',
                'actdsc'
            ]
        };

    }

}
