import {IComponentController} from 'angular';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuItemEcran, IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IMr0005Params {
    id: string;
    menuId: string;
    placleint: number;
}

interface IPageMr0005 extends IComponentController {
    formulairePca: IFormulaire;
    formulaireDet: IFormulaire;
    menuItemApa : IMenuItemEcran;
}

/* @ngInject */
export default function Mr0005Controller(mr0008Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         $stateParams: IMr0005Params) {
    const vm: IPageMr0005 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Condition d'admissibilité
        vm.formulairePca = new Formulaire('LBL_BLC_PCA', [

            new FormulaireItem('placleint', {default: $stateParams.placleint}),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItem('vaesysconadm'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('vaepcadebred'),
            new FormulaireItem('vaepcaferreg')
        ], {largeurDefaut: 50});

        // Détail de la condition
        vm.formulaireDet = new Formulaire('LBL_BLC_DET', [
            new FormulaireItem('pcavaldel'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('bqecleint'),
            new FormulaireItemSautDeLigne(),

            new FormulaireItem('pcavalbqe')

        ], {largeurDefaut: 100});

        vm.menuItemApa = new MenuItemEcran(
            'LBL_PARAM_AVI',
            mr0008Route.NAME,
            (data: any) => ({
                id: data.apacleint,
                cleintref:  data.pcacleint,
                stamnetab:  'PCA',
                vaesystypavi: 'ASC',
                supprime: 0,
                typsai:  data.vaesysconadm === 'IMM' || data.vaesysconadm === 'DEL' ? 'JRS' : 'BQE',
            }),
            {
                icon: 'alarm',
                iconButton: false,
                //class: 'ex-menu-item-button--secondaire',
                disabled: (data: any) => data.vaesysconadm === 'EXC' || data.vaesysconadm === 'MAN'
            }
        )
    }
}
