import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageGl0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gl0010Controller(gl0040Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGl0010 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gl0040Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gl0040Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'foedsc', largeur: 250},
            {nom: 'foelngpag', largeur: 110},
            {nom: 'foelarpag', largeur: 110},
            {nom: 'foeespcol', largeur: 110},
            {nom: 'foelardsc', largeur: 140},
            {nom: 'foesiggau', largeur: 90},
            {nom: 'foesigdro', largeur: 90},
            {nom: 'foesepmil', largeur: 90},
            {nom: 'foesepdec', largeur: 90}
        ]
    };
}
