import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IComponentController, IScope } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCr0028 extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Cr0028Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         $scope: IScope) {
    const vm: IPageCr0028 = this;

    vm.$onInit = $oninit;

    vm.multiOccurrenceOptions = {
        resourceParamsDynamique: () => ({
            anndeb: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.anndeb,
            annfin: vm.multiOccurrence && vm.multiOccurrence.etatSelected.criteresSuggeresData.annfin
        }),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: true
        },        
        criteresSuggeres: new Formulaire([
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'CLI'
                },
                required: false,
            }),
            new FormulaireItem('vaereftyp', {
                required: false
            }),
            new FormulaireItem('refeta', {
                default: '',
                required: false
            }),
            new FormulaireItem('annfin'),
            new FormulaireItem('typmnt', {
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('mntmin'),
            new FormulaireItem('mntmax')
        ]),
        colonnesFixesGauche: [
            {nom: 'refnom', largeur:218},
            {nom: 'refcod', largeur:90}
        ],
        colonnesVisibles: [
            {nom:'refmnt01' , largeur:126, total: true},
            {nom:'refmnt02' , largeur:126, total: true},
            {nom:'refmnt03' , largeur:126, total: true},
            {nom:'refmnt04' , largeur:126, total: true},
            {nom:'refmnt05' , largeur:126, total: true},
            {nom:'radadrl01', largeur:244},
            {nom:'radadrl02', largeur:244},
            {nom:'radvil'   , largeur:203},
            {nom:'radpro'   , largeur:102},
            {nom:'radcodpos', largeur:110},
            {nom:'radpay'   , largeur:110},
            {nom:'radnumtel', largeur:110}
        ],
        colonnesFixesDroite:[
            {nom:'refmnttot', largeur:126}
        ]
    };

    function $oninit() {
        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.anndeb', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });

        $scope.$watch('vm.multiOccurrence.etatSelected.criteresSuggeresData.annfin', (newValue) => {
            if (newValue) {
                vm.multiOccurrence.fetchSourceDetails(vm.multiOccurrenceOptions);
            }
        });
    }
}
