import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IRoute } from "../../../../../core/interfaces/route.interface";

interface IPageCP0025 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface ICp0025Params {
    lorcleint: string;
}
/* @ngInject */
export default function Cp0025Controller(Menu: IMenuClass,
    $stateParams: ICp0025Params,
    cp0026Route: IRoute,
    Operateur: IOperateurService
) {
    const vm: IPageCP0025 = this;

    const lorFiltresParametres = [];

    if ($stateParams.lorcleint) {
        lorFiltresParametres.push(
            ...[
                {
                    colonne: 'lorcleint',
                    operateur: Operateur.EGALE,
                    valeur: $stateParams.lorcleint
                }
            ]
        );
    }
    vm.multiOccurrenceOptions = {
        filtresParametres: lorFiltresParametres,
        navigatePage: cp0026Route.NAME,
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'regnumdoc', largeur: 126 },
            { nom: 'regnumdocseq', largeur: 100 },
            { nom: 'regnumchq', largeur: 120 },
            { nom: 'peccod', largeur: 80 },
            { nom: 'lotcod', largeur: 74 },
            { nom: 'lorcodseq', largeur: 76 },
            { nom: 'tydcod', largeur: 65 },
            { nom: 'regdat', largeur: 100 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'regnomleg', largeur: 232 },
            { nom: 'radcod', largeur: 100 },
            { nom: 'regmnt', largeur: 126 },
            { nom: 'etddsc', largeur: 180 },
            { nom: 'vaesysdevcod_dsc', largeur: 144 },
            { nom: 'intcodreq', largeur: 108 },
            { nom: 'intnomreq', largeur: 180 },
            { nom: 'intcoddem', largeur: 108 },
            { nom: 'intnomdem', largeur: 180 },
            { nom: 'regdatcci', largeur: 105 },
            { nom: 'mopcod', largeur: 91 },
            { nom: 'mopdsc', largeur: 185 },
            { nom: 'regdsc', largeur: 245 },
            { nom: 'usrcod', largeur: 100 }
        ],
        colonnesCachees: [
            'lorcleint'
        ]
    };
}
