import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageDo0013 {
    rgrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rgpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    rlrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireCriteres: IFormulaire;
    formulaireRgl: IFormulaire;
    formulaireRgp: IFormulaire;
    formulaireOff: IFormulaire;
}
/* @ngInject */
export default function Do0013Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemForage: IMenuItemForageClass,
    do0025Route: IRoute) {
    const vm: IPageDo0013 = this;
    // Règle de dotation 
    vm.formulaireRgl = new Formulaire('LBL_RGL_DOT', [
        new FormulaireItem('urgcleint', {
            largeur: 33
        }),
        new FormulaireItem('vaergltyp', {
            largeur: 33
        }),
        new FormulaireItem('rglcod', {
            largeur: 33
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('rgldscabr', {
            largeur: 50,
            enableCount: true
        }),
        new FormulaireItem('rglcomaff', {
            largeur: 50,
            enableCount: true
        }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('rgldsc', {
            largeur: 100,
            enableCount: true
        }),
    ]);
    // OFFRE D'emploi
    vm.formulaireOff = new Formulaire('Offre d\'emploi', [
        new FormulaireItem('cytcleint'),
        new FormulaireItem('rglduraff'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('vaerglcopfon'),
        new FormulaireItem('rglflgcopfontem'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('vaerglcoptch'),
        new FormulaireItem('rglflgcoptchtem'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('vaerglcopcor'),
        new FormulaireItem('rglflgcopcortem')
    ]);
    //Groupe de dotation
    vm.rgrMultiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'rgrordpri', largeur: 150 },
            { nom: 'grgcod', largeur: 150 },
            { nom: 'grgdsc', largeur: 150 }
        ],
        colonnesCachees: ['vaergltyp'],
        formulaire: new Formulaire([
            new FormulaireItem('rgrordpri'),
            new FormulaireItem('grgcleint')
        ])
    };

    // Liste de rappel
    vm.formulaireRgp = new Formulaire('Liste de rappel', [
        new FormulaireItem('rglnbrrefmax'),
        new FormulaireItem('rglnbrjrscalanc'),
        new FormulaireItem('rglflgdecdsp', {
            largeur: 33
        }),
        new FormulaireItem('rglflgdisgrpmul', {
            largeur: 33
        }),
        new FormulaireItem('rglflginsdsp', {
            largeur: 33
        }),
        new FormulaireTemplateSlot('blocRgp')
    ]);
    // Liste de rappel multi
    vm.rgpMultiOccurrenceOptions = {
        actions: new Menu([
            new MenuItemForage(
                'LBL_BTN_DO',
                do0025Route.NAME,
                (data: any) => ({
                    rglcleint: data.rglcleint
                }),
                {
                    icon: '',
                    class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                    fonction: 'BOUIMP',
                    iconButton: false,
                    secondaire: true
                }
            )
        ]),
        colonnesVisibles: [
            { nom: 'rgpcod', largeur: 150 },
            { nom: 'rgpdsc', largeur: 150 },
            { nom: 'rgpflgact', largeur: 150 },
            { nom: 'rgpflgpar', largeur: 150 }
        ],
        colonnesCachees: ['rglcleint'],
        formulaire: new Formulaire([
            new FormulaireItem('vacrgpcod', {
                largeur: 33
            }),
            new FormulaireItem('rgpflgact', {
                largeur: 33
            }),
            new FormulaireItem('rgpflgpar', {
                largeur: 33
            })
        ])
    }
    // Liste produits 
    vm.rlrMultiOccurrenceOptions = {
        bloc: 'rlr',
        colonnesVisibles: [
            { nom: 'rlrdat', largeur: 150 },
            { nom: 'rlrdatcalanc', largeur: 150 },
            { nom: 'rlrflgact', largeur: 150 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rlrdat', {
                disabled: true
            }),
            new FormulaireItem('rlrdatcalanc', {
                disabled: true
            }),
            new FormulaireItem('rlrflgact')
        ])
    };
}
