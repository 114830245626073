import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';

interface IPageMr0183 extends IController {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    rsvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Mr0183Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
) {
    const vm: IPageMr0183 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaersceleanc', { default: 'TAG' })
        ]),
        colonnesVisibles: [
            { nom: 'rsccod', largeur: 120 },
            { nom: 'rscdsc', largeur: 190 },
            { nom: 'vaersctyp_dsc', largeur: 180 },
            { nom: 'rscflgimpbas', largeur: 110 },
            { nom: 'rscflgpri', largeur: 110 },
            { nom: 'vaedeicle_dsc', largeur: 180 }
        ],
        colonnesCachees: [
            'flg_prs',
            'vaedeicle',
            'flgord1',
            'flgord2',
            'flgord3',
            'flgord4',
            'flgord5',
            'flgord6',
            'flgord7',
            'flgord8',
            'rsccleint',
            'rsscleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('enecod', { required: true }),
            new FormulaireItem('enedsc', { required: true }),
            new FormulaireItem('eneflgstu', { required: true, default: true }),
        ]),
        fonctions: {
            rechercheCollapsable: false,
        }
    };

    vm.rsvMultiOccurrenceOptions = {
        bloc: 'rsv',
        //resourceParamsDynamique: () => ({
        //  rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
        //  rsccleint: vm.multiOccurrence.getDataActiveRow().rsccleint
        //}),
        colonnesFixesGauche: [
            { nom: 'vaccod', largeur: 60, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'VAC' },
            { nom: 'tagcod', largeur: 60, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'TAG' },
            { nom: 'precod', largeur: 60, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'PRE' },
            { nom: 'carcod', largeur: 60, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'CAR' },
            { nom: 'chgcod', largeur: 60, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'CHG' },
            { nom: 'rcecod', largeur: 60, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'RCE' }
        ],
        colonnesCachees: [
            'rsscleint',
            'rsccleint'
        ],
        colonnesVisibles: [
            { nom: 'rsvcod01', largeur: 100 },
            { nom: 'rsvdsc01', largeur: 150 },
            { nom: 'rsvcod02', largeur: 100 },
            { nom: 'rsvdsc02', largeur: 150 },
            { nom: 'rsvcod03', largeur: 100 },
            { nom: 'rsvdsc03', largeur: 150 },
            { nom: 'rsvcod04', largeur: 100 },
            { nom: 'rsvdsc04', largeur: 150 },
            { nom: 'rsvcod05', largeur: 100 },
            { nom: 'rsvdsc05', largeur: 150 },
            { nom: 'rsvcod06', largeur: 100 },
            { nom: 'rsvdsc06', largeur: 150 },
            { nom: 'rsvcod07', largeur: 100 },
            { nom: 'rsvdsc07', largeur: 150 },
            { nom: 'rsvcod08', largeur: 100 },
            { nom: 'rsvdsc08', largeur: 150 }
        ],
        colonnesFixesDroite: [
            { nom: 'prucod', largeur: 90, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'PRU' },
            { nom: 'unacod', largeur: 90, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'UNA' },
            { nom: 'cptcod', largeur: 90, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'CPT' },
            { nom: 'cifcod', largeur: 90, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'CIF' },
            { nom: 'prscod', largeur: 90, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'CPT' }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaccleint', {
                required: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc === 'VAC',
                largeur: 25, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'VAC'
            }),
            new FormulaireItem('tagcleint', {
                required: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc === 'TAG',
                largeur: 25, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'TAG'
            }),
            new FormulaireItem('precleint', {
                required: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc === 'PRE',
                largeur: 25, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'PRE'
            }),
            new FormulaireItem('carcleint', {
                required: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc === 'CAR',
                largeur: 25, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'CAR'
            }),
            new FormulaireItem('chgcleint', {
                required: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc === 'CHG',
                largeur: 25, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'CHG'
            }),
            new FormulaireItem('rcecleint', {
                required: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc === 'RCE',
                largeur: 25, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.vaersceleanc != 'RCE'
            }),
            new FormulaireItem('rsvcleint01', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord1,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 1
                })
            }),
            new FormulaireItem('rsvcleint02', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord2,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 2
                })
            }),
            new FormulaireItem('rsvcleint03', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord3,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 3
                })
            }),
            new FormulaireItem('rsvcleint04', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord4,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 4
                })
            }),
            new FormulaireItem('rsvcleint05', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord5,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 5
                })
            }),
            new FormulaireItem('rsvcod06', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord6,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 6
                })
            }),
            new FormulaireItem('rsvcleint07', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord7,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 7
                })
            }),
            new FormulaireItem('rsvcleint08', {
                largeur: 25,
                hidden: () => !vm.multiOccurrence.getDataActiveRow().flgord8,
                resourceParams: () => ({
                    rsscleint: vm.multiOccurrence.getDataActiveRow().rsscleint,
                    sdeord: 8
                })
            }),
            new FormulaireItem('prucleint', {
                required: () => vm.multiOccurrence.getDataActiveRow().vaedeicle === 'PRU',
                largeur: 25, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'PRU',
                resourceParams: () => ({
                    type: 'PRU'
                })
            }),
            new FormulaireItem('unacleint', {
                required: () => vm.multiOccurrence.getDataActiveRow().vaedeicle === 'UNA',
                largeur: 25, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'UNA',
                resourceParams: () => ({
                    type: 'UNA'
                })
            }),
            new FormulaireItem('cptcleint', {
                required: () => vm.multiOccurrence.getDataActiveRow().vaedeicle === 'CPT',
                largeur: 25, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'CPT',
                resourceParams: () => ({
                    type: 'CPT'
                })
            }),
            new FormulaireItem('cifcleint', {
                required: () => vm.multiOccurrence.getDataActiveRow().vaedeicle === 'CIF',
                largeur: 25, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'CIF',
                resourceParams: () => ({
                    type: 'CIF'
                })
            }),
            new FormulaireItem('prscleint', { largeur: 25, hidden: () => vm.multiOccurrence.getDataActiveRow().vaedeicle != 'PRU' && vm.multiOccurrence.getDataActiveRow().flg_prs != 1 })
        ])
    };






}
