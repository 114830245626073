import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IPaginationClass} from "../../../../../core/services/pagination.service";

interface IPageRr0559 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulairePam: IFormulaire;
    mopMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0559Controller(Pagination: IPaginationClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0559 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
        };

        vm.formulairePam = new Formulaire('LBL_BLC_PAM', [
            new FormulaireItem('pamnbrpam'),
            new FormulaireItem('pamnetnon0'),
            new FormulaireItem('pamnbrimp'),
            new FormulaireItem('pamnbrtfe'),
            new FormulaireItem('pamnbrann'),
        ], {largeurDefaut: 20});

        vm.mopMultiOccurrenceOptions = {
            bloc:'mop',
            fonctions: {
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            colonnesVisibles: [
                {nom: 'mopcod', largeur: 100},
                {nom: 'test', largeur: 100}
            ],
            colonnesCachees: ['unpcleint', 'ppacleint'],
        };

    }
}
