import {module} from 'angular';
import DialogPlanificationEmployeController from './dialog-planification-employe.controller';
import {IDialogClass} from '../../../../core/services/dialog.service';

export default module('app.dialogs.dialog-planification-employe', [
    'core.services.dialog'
]).factory('DialogPlanificationEmploye', DialogPlanificationEmployeFactory);

/* @ngInject */
function DialogPlanificationEmployeFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogPlanificationEmployeController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-planification-employe.html'),
        focusOnOpen: false
    });
}
