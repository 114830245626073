import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'tf0010',
    ecrans: ['00-2',
        '01-2'],
    params: [
        '?reference'
    ]
});
