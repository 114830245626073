import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGs0121 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    mcdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0121Controller(Menu: IMenuClass,
    gs0121Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGs0121 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_MEC', [
            new FormulaireItem('mecdsc'),
            new FormulaireItem('mecdscmen'),
            new FormulaireItem('vaesysapp', { required: true }),
            new FormulaireItem('vaesysmodsof', { required: true }),
            new FormulaireItem('mecflgcen')
        ], { largeurDefaut: 33.33 })
    };

    vm.mcdMultiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_FOR_TYP',
                gs0121Route.NAME,
                (data: any) => ({ id: data.mcdcleintref }),
                { hidden: (data: any) => data.vaesysenttrt !== 'MEC' }
            )]),
        colonnesCachees: [
            'mcdcleintref',
            'vaesysenttrt'
        ],
        colonnesVisibles: [
            { nom: 'mcdcle', largeur: 100 },
            { nom: 'vaesysenttrt_dsc', largeur: 140 },
            { nom: 'mcddsc', largeur: 300 },
            { nom: 'mcdcod', largeur: 150 },
            { nom: 'mcdvaldev', largeur: 160 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('mcdcle'),
            new FormulaireItem('vaesysenttrt'),
            new FormulaireItem('mcdcleintref', {
                resourceParams: (data: any) => (
                    {
                        vaesysapp: vm.monoOccurrence.data.vaesysapp,
                        meccleint: vm.monoOccurrence.data.meccleint
                    })
            }),
            new FormulaireItem('mcdvaldev')
        ], { largeurDefaut: 25 })
    };

    
    // Bloc hie Affichage hiérarchique parents
    vm.hprMultiOccurrenceOptions = {
        brisNiveaux: 'niveau',
        bloc: 'hpr',
        fonctions: {
            nouveau: false,
            edition: false,
            supprime: false,
            recherche: false
        },
        colonnesVisibles: [
            {
                nom: 'meddscmen',
                largeur: 100
            }
        ],
        colonnesCachees: ['medcleintref', 'vaesysenttrt', 'newline', 'sec','meccleint'],
    };
    // Bloc hie Affichage hiérarchique
    vm.hieMultiOccurrenceOptions = {
        brisNiveaux: 'niveau',
        bloc: 'hie',
        fonctions: {
            nouveau: false,
            edition: false,
            supprime: false,
            recherche: false
        },
        colonnesVisibles: [
            {
                nom: 'meddscmen',
                largeur: 100
            }
        ],
        colonnesCachees: ['medcleintref', 'vaesysenttrt', 'newline', 'sec','meccleint'],
    };
}
