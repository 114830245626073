import { IComponentController } from 'angular';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemForage, IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
interface IPageCr0005 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    formulaireCli: IFormulaire;
    formulaireStatut: IFormulaire;
    valeursEcran: any;
    btnMenus: Array<IMenuItemForage>;
    clcMultiOccurrenceOptions: IMultiOccurrenceOptions;
    radMultiOccurrenceOptions: IMultiOccurrenceOptions;
    scbMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0005Controller(cr0004Route: IRoute,
    cr0002Route: IRoute,
    cr0003Route: IRoute,
    mc0046Route: IRoute,
    cr2000Route: IRoute,
    cr0038Route: IRoute,
    cp0007Route: IRoute,
    mc1010Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    exDateFilter: IFilterExDate,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageCr0005 = this;

    vm.valeursUsr = ecranDetails.valeurs;

    vm.$onInit = function $onInit() {
        vm.forageSolde = new MenuItemEcran(
            'LBL_BTN_SOLDE_CAR',
            cr2000Route.NAME,
            (data: any) => ({ refcleint: data.refcleint }),
            {
                icon: 'launch',
                fonction: 'BOUDSR',
                iconButton: true
            }
        );

        vm.btnMenus = [
            new MenuItemEcran(
                'LBL_BTN_IMPUTATION',
                mc0046Route.NAME,
                (data: any) => ({ vaeusient: 'CLI', uvicleintref: data.refcleint }),
                {
                    icon: '',
                    fonction: 'BOUIMP',
                    iconButton: false,
                    secondaire: true
                }
            ),
            new MenuItemEcran(
                'LBL_BTN_MESS_FACTURE',
                mc1010Route.NAME,
                (data: any) => ({
                    refcleint: data.refcleint,
                    ecran: "00-2"
                }),
                {
                    icon: '',
                    fonction: 'BOUMSG',
                    iconButton: false,
                    secondaire: true
                }
            )
        ];

        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_CLI', [
                new FormulaireItem('clicod'),
                new FormulaireItem('clycleint', {
                    menu: [new MenuItemForage(
                        'LBL_PROFIL_CAP',
                        cr0038Route.NAME,
                        (data: any) => ({ clicleint: data.clicleint }), {
                        icon: 'exit_to_app',
                        fonction: 'BOUPFL',
                        iconButton: true,
                        hidden: () => (!vm.valeursUsr.flgclydet)
                    })]
                }),
                new FormulaireItem('clinom'),
                new FormulaireItem('clinomabr'),
                new FormulaireItem('crpcleint', {
                    menu: [new MenuItemForage(
                        'LBL_PROFIL_CAR',
                        cr0004Route.NAME,
                        (data: any) => ({ crpcleint: data.crpcleint }), {
                        icon: 'exit_to_app',
                        fonction: 'BOUCRP'
                    })]
                }),
                new FormulaireItem('pflcleint', {
                    menu: [new MenuItemForage(
                        'LBL_PROFIL_CAP',
                        cp0007Route.NAME,
                        (data: any) => ({ pflcleint: data.pflcleint }), {
                        icon: 'exit_to_app',
                        fonction: 'BOUPFL'
                    })]
                }),
                new FormulaireItem('vaesysdevcod', { readonly: true }),
                new FormulaireItem('vecsyslng'),
                new FormulaireItem('vectypapp'),
                new FormulaireItem('cliadrcou'),
                new FormulaireGroupe('LBL_BLC_INFO_TAXES',
                    new Formulaire([
                        new FormulaireItem('cliexetaxpro'),
                        new FormulaireItem('cliexetaxfed'),
                        new FormulaireItem('taxcleint', { largeur: 100 }),
                        new FormulaireItem('cliflgtaxfix')
                    ]), { largeur: 50 }
                ),
                new FormulaireGroupe('LBL_BLC_AUTRES_INFOS',
                    new Formulaire([
                        new FormulaireItem('enecleint', {
                            menu: [
                                new MenuItemForage(
                                    'LBL_ENTENTE_ENC',
                                    cr0002Route.NAME,
                                    (data: any) => ({
                                        enecleint: data.enecleint,
                                        indfor: 0
                                    }),
                                    {
                                        icon: 'exit_to_app',
                                        fonction: 'BOUENE',
                                        iconButton: true,
                                        hidden: () => !vm.monoOccurrence.data.enecleint
                                    }
                                )
                            ], largeur: 100
                        }),
                        new FormulaireItem('motcleint', { largeur: 100 }),
                        new FormulaireItem('moecleint', {
                            menu: [
                                new MenuItemForage(
                                    'LBL_ENTENTE_ENC',
                                    cr0003Route.NAME,
                                    (data: any) => ({ moecleint: data.moecleint, indfor: 0 }),
                                    {
                                        icon: 'exit_to_app',
                                        fonction: 'BOUMOE',
                                        iconButton: true,
                                        hidden: () => !vm.monoOccurrence.data.moecleint
                                    }
                                )
                            ], largeur: 100
                        }),
                        new FormulaireItem('cliflgetacpt'),
                        new FormulaireItem('cliindentmlt')
                    ]), { largeur: 50 }
                )
            ])
        };

        vm.formulaireStatut = new Formulaire('LBL_STATUT', [
            new FormulaireItem('clidatdeb', { default: exDateFilter(new Date()) }),
            new FormulaireItem('cliflgstu', { default: 1 }),
            new FormulaireItem('clidatfin'),
        ], { largeurDefaut: 50 });

        vm.clcMultiOccurrenceOptions = {
            bloc: 'clc',
            colonnesFixesGauche: [
                { nom: 'clctit', largeur: 250 },
                { nom: 'clcnom', largeur: 250 }
            ],
            colonnesVisibles: [
                { nom: 'clcnumtel', largeur: 120 },
                { nom: 'clcnumtelpos', largeur: 100 },
                { nom: 'clcnumfax', largeur: 120 },
                { nom: 'vecsyscodtel_dsc', largeur: 150 },
                { nom: 'clcnumtelaut', largeur: 120 },
                { nom: 'clcadrcou', largeur: 250 },
                { nom: 'clcflgstu', largeur: 100 },
                { nom: 'clccom', largeur: 250 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('clctit', { largeur: 35 }),
                new FormulaireItem('clcnom', { largeur: 35 }),
                new FormulaireItem('clcflgstu', { largeur: 30, default: 1 }),
                new FormulaireItem('clcadrcou', { largeur: 50 }),
                new FormulaireItem('clccom', { largeur: 50 }),
                new FormulaireItemDivider('LBL_DIV_TEL'),
                new FormulaireItem('clcnumtel'),
                new FormulaireItem('clcnumtelpos'),
                new FormulaireItem('clcnumfax'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vecsyscodtel'),
                new FormulaireItem('clcnumtelaut')
            ], { largeurDefaut: 25 })
        };

        vm.radMultiOccurrenceOptions = {
            bloc: 'rad',
            colonnesFixesGauche: [
                { nom: 'radcod', largeur: 100 },
                { nom: 'radadrl01', largeur: 250 },
            ],
            colonnesVisibles: [
                { nom: 'radadrl02', largeur: 250 },
                { nom: 'radvil', largeur: 150 },
                { nom: 'radpro', largeur: 150 },
                { nom: 'radcodpos', largeur: 120 },
                { nom: 'radpay', largeur: 150 },
                { nom: 'radnumtel', largeur: 120 },
                { nom: 'radnumtelpos', largeur: 100 },
                { nom: 'radnumfax', largeur: 120 },
                { nom: 'radadrcou', largeur: 250 },
                { nom: 'radflgstu', largeur: 100 },
                { nom: 'raddatdeb', largeur: 126 },
                { nom: 'radflgfac', largeur: 130 },
                { nom: 'radflgetacpt', largeur: 130 },
                { nom: 'radflgpai', largeur: 130 },
                { nom: 'radnom', largeur: 250 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('radcod', { largeur: 25 }),
                new FormulaireItem('radflgstu', { default: 1, largeur: 25 }),
                new FormulaireItem('raddatdeb', { required: true, largeur: 25, default: exDateFilter(new Date()) }),
                new FormulaireItem('radadrl01', { largeur: 25 }),
                new FormulaireItem('radadrl02', { largeur: 25 }),
                new FormulaireItem('radvil', { largeur: 25 }),
                new FormulaireItem('radpro', { largeur: 25 }),
                new FormulaireItem('radcodpos', { largeur: 25 }),
                new FormulaireItem('radpay', { largeur: 25 }),
                new FormulaireItem('radnumtel', { largeur: 25 }),
                new FormulaireItem('radnumtelpos', { largeur: 25 }),
                new FormulaireItem('radnumfax', { largeur: 25 }),
                new FormulaireItem('radadrcou', { largeur: 25 }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('radflgfac', { largeur: 25 }),
                new FormulaireItem('radflgetacpt', { largeur: 25 }),
                new FormulaireItem('radflgpai', { largeur: 25 }),
                new FormulaireItem('radnom', { largeur: 44 })
            ], { largeurDefaut: 50 })
        };

        vm.scbMultiOccurrenceOptions = {
            bloc: 'scb',
            actionsMoreLigne: new Menu([
                new MenuItemAction('LBL_BOU_APPROUVER', 'BOUUSRAPR', () => ({}), {
                    icon: 'check',
                    hidden: (data: any) => !data.scbflgaprreq
                })
            ]),
            colonnesVisibles: [
                { nom: 'scbfmt', largeur: 100 },
                { nom: 'scbdateff', largeur: 100 },
                { nom: 'scbdsc', largeur: 100 },
                { nom: 'scbaprreqdsc', largeur: 100 },
            ],
            colonnesCachees: [
                'scbflgaprreq'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('inscleint', { largeur: 100 }),
                new FormulaireItem('succleint', { largeur: 100 }),
                new FormulaireItem('scbfol'),
                new FormulaireItem('scbdateff')
            ])
        };
    }

}
