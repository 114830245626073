import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../core/services/multi-occurrence.service';
import {IAugmentedJQuery, IPromise, IScope} from 'angular';
import {IFormulaireClass} from '../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireGroupeConditionnelClass} from '../../../../core/services/formulaire/formulaire-groupe-conditionnel.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFilterLibelle} from '../../../../core/filters/ex-libelle.filter';
import {IFilterExDate} from '../../../../core/filters/ex-date.filter';
import {IOperateurService} from '../../../../core/services/operateur.service';
import IDialogService = angular.material.IDialogService;
import {IChangementManager} from "../../../../core/services/changement-manager.service";
import { IComposantDialog } from '../../../../core/components/ex-dialog/ex-dialog.controller';

export interface IDialogPlanificationEmploye extends IComposantDialog {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    libelles: {[col: string]: string};
    data: IDataPlanificationEmploye;
    isCreation: boolean;
    date: string;
    libelleJour: string;
    confirmClose(): IPromise<any>;
}

interface IDataPlanificationEmploye {
    itecleint: number;
    itpcleint: number;
    itnnumjrs: number;
    itycleint: number;
    etqcleint: number;
    demcleint: number;
    etiquette: string;
    eplnummat: string;
    eplnomprn: string;
    ieecleint: string;
    debutPeriode: Date;
}

/* @ngInject */
export default function DialogPlanificationEmployeController(changementManager: IChangementManager,
                                                             $scope: IScope,
                                                             $mdDialog: IDialogService,
                                                             $element: IAugmentedJQuery,
                                                             Formulaire: IFormulaireClass,
                                                             FormulaireItem: IFormulaireItemClass,
                                                             exLibelleFilter: IFilterLibelle,
                                                             exDateFilter: IFilterExDate,
                                                             FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
                                                             FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                                             Operateur: IOperateurService) {
    const vm: IDialogPlanificationEmploye = this;
    vm.$onInit = function $onInit() {
        vm.date = getDateValue();
        vm.confirmClose = confirmClose;

        vm.multiOccurrenceOptions = {
            parentId: vm.data.itpcleint,
            filtres: [
                {
                    colonne: 'ieecleint',
                    operateur: Operateur.EGALE,
                    valeur: vm.data.ieecleint,
                    visible: false
                }
            ],
            resourceParams: {
                itnnumjrs: vm.data.itnnumjrs,
                ieecleint: vm.data.ieecleint
            },
            bloc: 'itn',
            colonnesVisibles: [
                'itndsc',
                'hredebfin',
                'itnflgerrdis'
            ],
            colonnesCachees: [
                'etqcleint',
                'demcleint',
                'itnmsgerrdis',
                'itndat'
            ],
            fonctions: {
                afficherMenuSettingDansEntete: true,
                exportation: true,
                pagination: false
            },
            formulaire: new Formulaire([
                //Champs non Modifiables
                //Cachés
                new FormulaireItem('itecleint', {default: vm.data.itecleint}),
                new FormulaireItem('ieecleint', {default: vm.data.ieecleint}),
                new FormulaireItem('itnnumjrs', {default: vm.data.itnnumjrs}),
                new FormulaireItem('itycleint', {default: vm.data.itycleint}),
                //Visibles
                new FormulaireItem('etqcleint', {largeur: 50, titre: 'etiquette', default: vm.data.etqcleint}),
                new FormulaireItem('etiquette', {largeur: 50, default: vm.data.etiquette}),
                new FormulaireItem('demcleint', {largeur: 50, default: vm.data.demcleint}),
                new FormulaireItem('demcleint__eplnummat', {default: vm.data.eplnummat}),
                new FormulaireItem('demcleint__eplnomprn', {default: vm.data.eplnomprn}),
                new FormulaireItem('itndat', {largeur: 45, default: vm.date}),
                //Champs modifiables
                new FormulaireItem('hredeb', {largeur: 25}),
                new FormulaireItem('hrefin', {largeur: 25}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('itnmsgche', {largeur: 100}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('itndsc', {largeur: 100, enableCount: true}),
                new FormulaireGroupeConditionnel('repete', '', new Formulaire([
                    new FormulaireItem('jrs1', {largeur: 10, titre: vm.libelles.JRS_1_ABR}),
                    new FormulaireItem('jrs2', {largeur: 10, titre: vm.libelles.JRS_2_ABR}),
                    new FormulaireItem('jrs3', {largeur: 10, titre: vm.libelles.JRS_3_ABR}),
                    new FormulaireItem('jrs4', {largeur: 10, titre: vm.libelles.JRS_4_ABR}),
                    new FormulaireItem('jrs5', {largeur: 10, titre: vm.libelles.JRS_5_ABR}),
                    new FormulaireItem('jrs6', {largeur: 10, titre: vm.libelles.JRS_6_ABR}),
                    new FormulaireItem('jrs7', {largeur: 10, titre: vm.libelles.JRS_7_ABR}),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('nbrsem', {largeur: 10, default: 1})
                ]), {default: 0, switch: true})
            ])
        };
    }

    $scope.$watch('::vm.multiOccurrence', () => {
        if (vm.multiOccurrence) {
            vm.multiOccurrence.once('dataListUpdate', () => {
                //On ne veut pas fermer le dialog après le premier fetchDataList.
                vm.multiOccurrence.on('dataListUpdate', () => {
                    if (!vm.multiOccurrence.dataList.length) {
                        $mdDialog.hide();
                    }
                });
            })
        }
    });

    $scope.$on('$destroy', () => {
        vm.multiOccurrence.removeAllListeners()
    });

    const unwatch = $scope.$watch('vm.multiOccurrence.initialized', (initialized: boolean) => {
        if (initialized) {
            vm.libelleJour = getLibelleJour();
            unwatch();
        }
    });

    function getLibelleJour(): string {
        return exLibelleFilter(`JRS_${vm.data.itnnumjrs}`, vm.libelles);
    }

    function getDateValue(): string {
        const date = new Date(vm.data.debutPeriode);
        return exDateFilter(date.setDate(date.getDate() + (vm.data.itnnumjrs - 1)));
    }

    function confirmClose(): IPromise<any> {
        return changementManager.confirmerQuitterPage($element);
    }
}
