import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cp0074',
    ecrans: ['00-2'],
    params: [
        'id',
        'refcleint',
        'vaesysdevcod',
        'vaesysprocom'
    ]
});
