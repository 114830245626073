import IStateProvider = angular.ui.IStateProvider;
import ErreurAccesEcran from './erreur-acces-ecran.controller';
import { module } from 'angular';

const route = {
    NAME: 'secure.erreur-acces-ecran',
};

export default module('app.secure.erreur-acces-ecran', []).config(config).constant('erreurAccesEcranRoute', route);

/* @ngInject */
function config($stateProvider: IStateProvider) {
    $stateProvider.state(route.NAME, {
        params: {
            route
        },
        views: {
            content: {
                template: require('./erreur-acces-ecran.html'),
                controllerAs: 'vm',
                controller: ErreurAccesEcran
            }
        }
    });
}
