import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0124 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0124Controller(mr0116Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0124 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0116Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0116Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'prfcod',        largeur: 104},
            {nom: 'prfdsc',        largeur: 275},
            {nom: 'prfdscabr',     largeur: 275},
            {nom: 'vaeprftyp_dsc', largeur: 200},
            {nom: 'prfdatdeb',     largeur: 100},
            {nom: 'prfdatfin',     largeur: 100},
            {nom: 'actcod',        largeur: 110},
            {nom: 'actdsc',        largeur: 275},
            {nom: 'tagcod',        largeur: 130},
            {nom: 'tagdscabr',     largeur: 275},
            {nom: 'prfflgtypdat',  largeur: 120}
        ]
    };
}
