import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'if0017',
    ecrans: ['00-2'],
    params: [
        'id',
        '?itdcleintgen',
        '?itdcleintnew',
        '?itscleint'
    ]
});
