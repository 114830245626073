import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0038 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0038Controller(Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    mr2009Route: IRoute,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMr0038 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr2009Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr2009Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false,
            nouveau: () => (ecranDetails.flgcen)
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('indint', { default: 'T', largeur: 130 })
        ]),
        colonnesVisibles: [
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 300 },
            { nom: 'intflgsup', largeur: 120 },
            { nom: 'intflgssup', largeur: 120 }
        ]
    };
}
