import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageRr0005 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tsaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0005Controller(rr0022Route: IRoute,
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0005 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_BLC_CRE', []),
        };

        vm.tsaMultiOccurrenceOptions = {
            navigatePage: rr0022Route.NAME,
            navigateParams: (data: any) => ({
                lsacleint: data.lsacleint,
                trncleint: data.trncleint,
                flgrr0005: 1
            }),
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'G_LBL_MODIF_ECHSAL',
                    rr0022Route.NAME,
                    (data: any) => ({
                        ecran: '02-2',
                        tsacleintori: data.tsacleint
                    }), {
                    icon: 'add_circle',
                    iconButton: false,
                    fonction: "BOUECH"
                }),
                new MenuItemForage(
                    'G_LBL_MODIF_GRPECH',
                    rr0022Route.NAME,
                    (data: any) => ({
                        ecran: '01-2',
                        tsacleintori: data.tsacleint
                    }), {
                    icon: 'add_circle',
                    iconButton: false,
                    fonction: "BOUGEC"
                })
            ]),
            colonnesVisibles: [
                { nom: 'tsadatvig', largeur: 126 },
                { nom: 'tsadsc', largeur: 404 },
                { nom: 'tsasal', largeur: 139 },
                { nom: 'tsdsal', largeur: 120 },
                { nom: 'total', largeur: 139 },
                { nom: 'ecldsceta', largeur: 178 }
            ],
            colonnesCachees: [
                'tsacleint',
                'lsacleint',
                'trncleint',
                'tsecleint'
            ]
        };
    }
}
