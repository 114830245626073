import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPageMR0312 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0312Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireGroupe: IFormulaireGroupeClass) {

    const vm: IPageMR0312 = this;

    vm.multiOccurrenceOptions = {
        criteresSuggeres: new Formulaire([
            new FormulaireGroupe('LBL_COMP_A', new Formulaire([
                new FormulaireItem('colcleint_a', { largeur: 60, required: true }),
                new FormulaireItem('cdtdatvig_a', { largeur: 20, readonly: true }),
                new FormulaireItem('cdtcleint_a', { hidden: true })
            ]), { largeur: 50 }),
            new FormulaireGroupe('LBL_COMP_B', new Formulaire([
                new FormulaireItem('colcleint_b', { largeur: 60, required: true }),
                new FormulaireItem('cdtdatvig_b', { largeur: 20, readonly: true }),
                new FormulaireItem('cdtcleint_b', { hidden: true })
            ]), { largeur: 50 }),
            new FormulaireItem('flgdif')
        ]),
        colonnesVisibles: [
            {nom: 'tagcod'   , largeur: 100},
            {nom: 'tagdscabr', largeur: 305},
            {nom: 'flg_a'    , largeur: 130},
            {nom: 'flg_b'    , largeur: 130}
        ]
    };
}
