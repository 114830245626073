import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPagePR0015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0015Controller(Menu: IMenuClass,
    cb0010Route: IRoute,
    MenuItemForage: IMenuItemForageClass
) {
    const vm: IPagePR0015 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([            
            new MenuItemForage(
                'BTN_ANA',
                cb0010Route.NAME,
                (rowData: any) => ({
                    id: rowData.el1cleint,
                    niveau: 1
                }),
                {
                    icon: 'equalizer',
                    iconButton: false,
                    fonction: 'BTNANA'
                }
            )
        ]),      
        colonnesVisibles: [
            {nom: 'prjcod', largeur: 120},
            {nom: 'rspcod', largeur: 120},
            {nom: 'rspnom', largeur: 180},
            {nom: 'pdfcod', largeur: 130},
            {nom: 'prjdsc', largeur: 180},
            {nom: 'typdsc', largeur: 120},
            {nom: 'prjcodpri', largeur: 180},
            {nom: 'cetactabr', largeur: 180},
            {nom: 'datencours', largeur: 130},
            {nom: 'pdfnumref', largeur: 130},
            {nom: 'prjnumdis', largeur: 130},
            {nom: 'pdfnumrescd', largeur: 180},
            {nom: 'orgcod', largeur: 120},
            {nom: 'orgnom', largeur: 220},
            {nom: 'vecsysregadm', largeur: 160},
            {nom: 'orpcod', largeur: 120},
            {nom: 'orpdsc', largeur: 180},
            {nom: 'prucod', largeur: 160},
            {nom: 'intcod', largeur: 160},
            {nom: 'intnom', largeur: 180},
            {nom: 'tyfcod', largeur: 120},
            {nom: 'tyfdsc', largeur: 180},
            {nom: 'pdfnumdossru', largeur: 160},
            {nom: 'pdfannoct', largeur: 130},
            {nom: 'pdfprccouind', largeur: 130}
        ],
        colonnesCachees: [
            'el1cleint'
        ]
    };
}
