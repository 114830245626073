import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageMC0116 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0116Controller(Menu: IMenuClass) {
    const vm: IPageMC0116 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'equcod', largeur: 200},
            {nom: 'equdsc', largeur: 200},
            {nom: 'equdscabr', largeur: 200},
            {nom: 'equflgenp', largeur: 200},
            {nom: 'equflgact', largeur: 200}
        ]
    };
}
