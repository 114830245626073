import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IFilterDate} from 'angular';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';
import {IWindowService} from 'angular';
interface IPageAL0008 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}
/* @ngInject */
export default function Al0008Controller( MenuItemEcran: IMenuItemEcranClass,
                                         Menu:IMenuClass,
                                         $window: IWindowService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         dateFilter: IFilterDate,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         al0009Route: IRoute,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageAL0008 = this;

    // @TODO ajouter la route de forage une fois que l'écran est fait

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        navigatePage:al0009Route.NAME,
        fonctions:{
            rechercheCollapsable:false,
            selectionCycle:false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datref', {
                required: true,
                largeur: 50,
                default: dateFilter(new Date(), 'yyyy-MM-dd')
            }),
            new FormulaireItemDivider('LBL_CRI_PRO'),   // Profil
            new FormulaireItem('prfcleintcri', {largeur: 25}),
            new FormulaireItem('prfdatdebcri', {largeur: 15}),
            new FormulaireItem('prfdatfincri', {largeur: 15}),
            new FormulaireItemDivider('LBL_CRI_TIN'),   // Type d'intervalle
            new FormulaireItem('vaccleintcri', {largeur: 25}),
            new FormulaireItem('vacdatdebcri', {largeur: 15}),
            new FormulaireItem('vacdatfincri', {largeur: 15}),
            new FormulaireItemDivider('LBL_CRI_AVI'),   // Avis
            new FormulaireItem('apccleintcri', {largeur: 25}),
            new FormulaireItem('apcdatdebcri', {largeur: 15}),
            new FormulaireItem('apcdatfincri', {largeur: 15})
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe},
            {nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe}
        ],
        colonnesVisibles: [
            {nom: 'dalnumdoc', largeur: 100},
            {nom: 'tdlcod', largeur: 140},
            {nom: 'tdldsc', largeur: 250},
            {nom: 'ecldsceta', largeur: 250},
            {nom: 'urgcod', largeur: 220},
            {nom: 'urgdscabr', largeur: 270},
            {nom: 'strcod', largeur: 155},
            {nom: 'strdsc', largeur: 250},
            {nom: 'prfcod_dal', largeur: 120},
            {nom: 'prfdscabr', largeur: 250},
            {nom: 'dlddat', largeur: 150},
            {nom: 'alidsc', largeur: 250},
            {nom: 'vacvalcle', largeur: 200},
            {nom: 'vacdsc', largeur: 270},
            {nom: 'daldatouv', largeur: 100},
            {nom: 'daldatdeb', largeur: 100},
            {nom: 'daldatret', largeur: 120},
            {nom: 'daldatretprg', largeur: 120},
            {nom: 'daldatfer', largeur: 100},
            {nom: 'daldatrec', largeur: 100},
            {nom: 'dalprcdif', largeur: 180},
            {nom: 'ttccod', largeur: 110},
            {nom: 'ttcdsc', largeur: 270},
            {nom: 'dalcom', largeur: 500},
            {nom: 'trnflgstaref', largeur: 100},
            {nom: 'trnflgstafin', largeur: 100},
            {nom: 'trnflgstafer', largeur: 100},
            {nom: 'trnflgstaann', largeur: 100}
            ]
    };
}
