import { IComponentController } from 'angular';
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaire, IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../../../../core/services/mono-occurrence.service";
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageDB0050 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence
    aMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dMultiOccurrenceOptions: IMultiOccurrenceOptions;
    eMultiOccurrenceOptions: IMultiOccurrenceOptions;
    fMultiOccurrenceOptions: IMultiOccurrenceOptions;
    gMultiOccurrenceOptions: IMultiOccurrenceOptions;
    titrea: any;
    titreb: any;
    titrec: any;
    titred: any;
    titree: any;
    titref: any;
    titreg: any;
    valeursEcran: any;
    aMultiOccurrence: IMultiOccurrence;
    bMultiOccurrence: IMultiOccurrence;
    cMultiOccurrence: IMultiOccurrence;
    dMultiOccurrence: IMultiOccurrence;
    eMultiOccurrence: IMultiOccurrence;
    fMultiOccurrence: IMultiOccurrence;
    gMultiOccurrence: IMultiOccurrence;
    formulaireCriteres: IFormulaire;
}
/* @ngInject */
export default function Db0050Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    db2002Route: IRoute,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageDB0050 = this;

    vm.titrea = ecranDetails.valeurs.titrea;
    vm.titreb = ecranDetails.valeurs.titreb;
    vm.titrec = ecranDetails.valeurs.titrec;
    vm.titred = ecranDetails.valeurs.titred;
    vm.titree = ecranDetails.valeurs.titree;
    vm.titref = ecranDetails.valeurs.titref;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        noId: true
    }

    const criteresSuggeres = new Formulaire([
        new FormulaireItem('peccoddeb', { largeur: 16, required: true }),
        new FormulaireItem('peccodfin', { largeur: 17, required: true }),
        new FormulaireItem('prucleint', { titre: ecranDetails.valeurs.titpru, lovSelectionMultiple: true }),
        new FormulaireItem('cptcleint', { titre: ecranDetails.valeurs.titcpt }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('prscleint', { titre: ecranDetails.valeurs.titprs }),
        new FormulaireItem('unacleint'),
        new FormulaireItem('cifcleint'),
        new FormulaireItem('el1cleint', { titre: ecranDetails.valeurs.titel1, hidden: !ecranDetails.valeurs.el1flg }),
        new FormulaireItem('el2cleint', { titre: ecranDetails.valeurs.titel2, hidden: !ecranDetails.valeurs.el2flg }),
        new FormulaireItem('el3cleint', { titre: ecranDetails.valeurs.titel3, hidden: !ecranDetails.valeurs.el3flg }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('statut', { default: 1, required: true }),
        new FormulaireItem('type_bdg', { default: 'P', required: true }),
        new FormulaireItem('blocleint'),
        new FormulaireItem('intcleint', { titre: ecranDetails.valeurs.titint }),
        new FormulaireItem('sfucleint'),
        new FormulaireItem('vecprucatpro', { titre: ecranDetails.valeurs.titveccat }),
        new FormulaireItem('grpcleint'),
        new FormulaireItem('soncleint')
    ])

    vm.criMultiOccurrenceOptions = {
        srccod: "db0050",//important afin d'obtenir les datatypes et les libelles
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: false,
            filtrer: false,
            selectionnerDesColonnes: false,
            selectionnerTri: false,
            avisEmploye: false
        },
        criteresSuggeres,
        colonnesVisibles: [
            { nomb: 'peccoddeb', largeur: 16 },
            { nomb: 'peccodfin', largeur: 17 },
            { nomb: 'prucleint' },
            { nomb: 'cptcleint' },
            { nomb: 'prscleint' },
            { nomb: 'unacleint' },
            { nomb: 'cifcleint' },
            { nomb: 'el1cleint' },
            { nomb: 'el2cleint' },
            { nomb: 'el3cleint' },
            { nomb: 'statut' },
            { nomb: 'type_bdg' },
            { nomb: 'blocleint' },
            { nomb: 'intcleint' },
            { nomb: 'sfucleint' },
            { nomb: 'vecprucatpro' },
            { nomb: 'grpcleint' },
            { nomb: 'soncleint' }
        ]
    }

    vm.aMultiOccurrenceOptions = {
        navigatePage: db2002Route.NAME,
        navigateParams: (rowData: any) => {
            return ({
                peccoddeb: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
                cptcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
                el1cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                prscleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
                cifcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 1,
                blocleint: rowData.blocleint,
                intcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            })
        },
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            effaceCriteres: true,
            recherche: true,
            filtrer: true,
            selectionnerDesColonnes: true,
            selectionnerTri: true,
            avisEmploye: false,
            reinitialiser: false,
            reinitialiserPastillefiltre: true

        },
        criteresSuggeres,
        colonnesFixesGauche: [
            { nom: 'vaedsc', largeur: 110 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 150 }
        ],
        colonnesVisibles: [
            { nom: 'mntrap', largeur: 160 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 110 },
            { nom: 'mntgb', largeur: 140 },
            { nom: 'mntrg', largeur: 140 },
            { nom: 'mntrb', largeur: 140 },
            { nom: 'mntrr', largeur: 110 },
            { nom: 'mntpe', largeur: 140 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 110 },
            { nom: 'mntgs', largeur: 110 },
            { nom: 'mntbr', largeur: 140 },
            { nom: 'mntpb', largeur: 170 },
            { nom: 'mnttot', largeur: 110 },
            { nom: 'mntect', largeur: 110 },
            { nom: 'mntprc', largeur: 140 }
        ],
        colonnesCachees: [
            'vaesysnatctb',
            'blocleint'
        ]
    }

    vm.bMultiOccurrenceOptions = {
        navigatePage: db2002Route.NAME,
        navigateParams: (rowData: any) => {
            return ({
                peccoddeb: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.prucleint,
                cptcleint: rowData.cptcleint,
                el1cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                prscleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
                cifcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 2,
                blocleint: rowData.blocleint,
                intcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            })
        },
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            recherche: true,
            filtrer: true,
            selectionnerDesColonnes: true,
            selectionnerTri: true,
            avisEmploye: false,
            criteresSuggeresVisibles: true,
            reinitialiserPastillefiltre: true
        },
        criteresSuggeres,
        entetesReplacedValues: {
            cptcod: ecranDetails.valeurs.titcptabr,
            cptdscabr: ecranDetails.valeurs.dsccptabr
        },
        colonnesFixesGauche: [
            { nom: 'vaedsc', largeur: 110 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 150 },
            { nom: 'cptcod', largeur: 120 },
            { nom: 'cptdscabr', largeur: 180 }
        ],
        colonnesVisibles: [
            { nom: 'mntrap', largeur: 160 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 110 },
            { nom: 'mntgb', largeur: 140 },
            { nom: 'mntrg', largeur: 140 },
            { nom: 'mntrb', largeur: 140 },
            { nom: 'mntrr', largeur: 110 },
            { nom: 'mntpe', largeur: 140 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 110 },
            { nom: 'mntgs', largeur: 110 },
            { nom: 'mntbr', largeur: 140 },
            { nom: 'mntpb', largeur: 170 },
            { nom: 'mnttot', largeur: 110 },
            { nom: 'mntect', largeur: 110 },
            { nom: 'mntprc', largeur: 140 }
        ],
        colonnesCachees: [
            'vaesysnatctb',
            'blocleint',
            'cptcleint'
        ]
    }

    vm.cMultiOccurrenceOptions = {
        navigatePage: db2002Route.NAME,
        navigateParams: (rowData: any) => {
            return ({
                peccoddeb: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: rowData.prucleint,
                el1cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                prscleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.prscleint,
                cifcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 3,
                blocleint: rowData.blocleint,
                cptcleint: rowData.cptcleint,
                intcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup,
            })
        },
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            effaceCriteres: true,
            recherche: true,
            filtrer: true,
            selectionnerDesColonnes: true,
            selectionnerTri: true,
            avisEmploye: false,
            reinitialiserPastillefiltre: true
        },
        criteresSuggeres,
        entetesReplacedValues: {
            cptcod: ecranDetails.valeurs.titcptabr,
            cptdscabr: ecranDetails.valeurs.dsccptabr,
            prucod: ecranDetails.valeurs.titprucod,
            prudscl01: ecranDetails.valeurs.titprudsc,
            vecprucatpro: ecranDetails.valeurs.titveccat
        },
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 110 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 150 },
            { nom: 'cptcod', largeur: 120 },
            { nom: 'cptdscabr', largeur: 180 },
            { nom: 'prucod', largeur: 120 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 160 },
            { nom: 'prudscl01', largeur: 160 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 180 },
            { nom: 'vecprucatpro', largeur: 140 },
            { nom: 't_vecdsc', largeur: 180 },
            { nom: 'pruflgexcpb', largeur: 110 },
            { nom: 'mntrap', largeur: 160 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 110 },
            { nom: 'mntgb', largeur: 140 },
            { nom: 'mntrg', largeur: 140 },
            { nom: 'mntrb', largeur: 140 },
            { nom: 'mntrr', largeur: 110 },
            { nom: 'mntpe', largeur: 140 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 110 },
            { nom: 'mntgs', largeur: 110 },
            { nom: 'mntbr', largeur: 140 },
            { nom: 'mntpb', largeur: 170 },
            { nom: 'mnttot', largeur: 110 },
            { nom: 'mntect', largeur: 110 },
            { nom: 'mntprc', largeur: 140 }
        ],
        colonnesCachees: [
            'vaesysnatctb',
            'blocleint',
            'prucleint',
            'cptcleint'
        ]
    }

    vm.dMultiOccurrenceOptions = {
        navigatePage: db2002Route.NAME,
        navigateParams: (rowData: any) => {
            return ({
                peccoddeb: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: rowData.prucleint,
                el1cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                cifcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 4,
                blocleint: rowData.blocleint,
                prscleint: rowData.prscleint,
                cptcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cptcleint,
                intcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup
            })
        },
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            effaceCriteres: true,
            filtrer: true,
            selectionnerDesColonnes: true,
            selectionnerTri: true,
            avisEmploye: false,
            reinitialiserPastillefiltre: true
        },
        criteresSuggeres,
        entetesReplacedValues: {
            cptcod: ecranDetails.valeurs.titcptabr,
            cptdscabr: ecranDetails.valeurs.dsccptabr,
            prucod: ecranDetails.valeurs.titprucod,
            prudscl01: ecranDetails.valeurs.titprudsc,
            vecprucatpro: ecranDetails.valeurs.titveccat,
            prscod: ecranDetails.valeurs.titprscod,
            prsdscl01: ecranDetails.valeurs.titprsdsc,
        },
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 110 },
            { nom: 'prucod', largeur: 120 },
            { nom: 'intcod', largeur: 120 },
            { nom: 'intnom', largeur: 160 },
            { nom: 'prudscl01', largeur: 160 },
            { nom: 'unacodsup', largeur: 140 },
            { nom: 'unadscsup', largeur: 180 },
            { nom: 'prscod', largeur: 160 },
            { nom: 'prsdscl01', largeur: 160 },
            { nom: 'vecprucatpro', largeur: 140 },
            { nom: 't_vecdsc', largeur: 160 },
            { nom: 'pruflgexcpb', largeur: 110 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 160 },
            { nom: 'mntrap', largeur: 160 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 110 },
            { nom: 'mntgb', largeur: 140 },
            { nom: 'mntrg', largeur: 140 },
            { nom: 'mntrb', largeur: 140 },
            { nom: 'mntrr', largeur: 110 },
            { nom: 'mntpe', largeur: 140 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 110 },
            { nom: 'mntgs', largeur: 110 },
            { nom: 'mntbr', largeur: 140 },
            { nom: 'mntpb', largeur: 170 },
            { nom: 'mnttot', largeur: 110 },
            { nom: 'mntect', largeur: 110 },
            { nom: 'mntprc', largeur: 140 }
        ],
        colonnesCachees: [
            'vaesysnatctb',
            'blocleint',
            'prucleint',
            'prscleint'
        ]
    }
    vm.eMultiOccurrenceOptions = {
        navigatePage: db2002Route.NAME,
        navigateParams: (rowData: any) => {
            return ({
                peccoddeb: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccoddeb,
                peccodfin: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.peccodfin,
                prucleint: rowData.prucleint,
                el1cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el1cleint,
                el2cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el2cleint,
                el3cleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.el3cleint,
                unacleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleint,
                cifcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.cifcleint,
                statut: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.statut,
                type_bdg: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.type_bdg,
                det: 5,
                blocleint: rowData.blocleint,
                prscleint: rowData.prscleint,
                cptcleint: rowData.cptcleint,
                intcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.blocleint,
                sfucleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.sfucleint,
                vecprucatpro: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.vecprucatpro,
                vaesysnatctb: rowData.vaesysnatctb,
                grpcleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.grpcleint,
                soncleint: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.soncleint,
                unacleintsup: vm.aMultiOccurrence.etatSelected.criteresSuggeresData.unacleintsup
            })
        },
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            effaceCriteres: true,
            filtrer: true,
            selectionnerDesColonnes: true,
            selectionnerTri: true,
            avisEmploye: false,
            reinitialiserPastillefiltre: true
        },
        criteresSuggeres,
        entetesReplacedValues: {
            cptcod: ecranDetails.valeurs.titcptabr,
            cptdscabr: ecranDetails.valeurs.dsccptabr,
            prucod: ecranDetails.valeurs.titprucod,
            prudscl01: ecranDetails.valeurs.titprudsc,
            vecprucatpro: ecranDetails.valeurs.titveccat,
            prscod: ecranDetails.valeurs.titprscod,
            prsdscl01: ecranDetails.valeurs.titprsdsc,
        },
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 110 },
            { nom: 'prucod', largeur: 110 },
            { nom: 'intcod', largeur: 140 },
            { nom: 'intnom', largeur: 180 },
            { nom: 'prudscl01', largeur: 160 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 160 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 't_vecdsc', largeur: 150 },
            { nom: 'pruflgexcpb', largeur: 110 },
            { nom: 'prscod', largeur: 120 },
            { nom: 'prsdscl01', largeur: 160 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 150 },
            { nom: 'cptcod', largeur: 130 },
            { nom: 'cptdscabr', largeur: 150 },
            { nom: 'mntrap', largeur: 160 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 110 },
            { nom: 'mntgb', largeur: 140 },
            { nom: 'mntrg', largeur: 140 },
            { nom: 'mntrb', largeur: 140 },
            { nom: 'mntrr', largeur: 110 },
            { nom: 'mntpe', largeur: 140 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 110 },
            { nom: 'mntgs', largeur: 110 },
            { nom: 'mntbr', largeur: 140 },
            { nom: 'mntpb', largeur: 170 },
            { nom: 'mnttot', largeur: 110 },
            { nom: 'mntect', largeur: 110 },
            { nom: 'mntprc', largeur: 140 }
        ],
        colonnesCachees: [
            'vaesysnatctb',
            'blocleint',
            'prucleint',
            'prscleint',
            'cptcleint'
        ]
    }

    vm.fMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            effaceCriteres: true,
            filtrer: true,
            selectionnerDesColonnes: true,
            selectionnerTri: true,
            avisEmploye: false,
            reinitialiserPastillefiltre: true
        },
        criteresSuggeres,
        entetesReplacedValues: {
            cptcod: ecranDetails.valeurs.titcptabr,
            cptdscabr: ecranDetails.valeurs.dsccptabr,
            prucod: ecranDetails.valeurs.titprucod,
            prudscl01: ecranDetails.valeurs.titprudsc,
            vecprucatpro: ecranDetails.valeurs.titveccat,
            prscod: ecranDetails.valeurs.titprscod,
            prsdscl01: ecranDetails.valeurs.titprsdsc,
        },
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 110 },
            { nom: 'prucod', largeur: 110 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 160 },
            { nom: 'prudscl01', largeur: 160 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 160 },
            { nom: 'prscod', largeur: 120 },
            { nom: 'prsdscl01', largeur: 160 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 't_vecdsc', largeur: 150 },
            { nom: 'pruflgexcpb', largeur: 110 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 150 },
            { nom: 'cptcod', largeur: 130 },
            { nom: 'cptdscabr', largeur: 150 },
            { nom: 'el1cod', largeur: 150 },
            { nom: 'el1dscabr', largeur: 190 },
            { nom: 'unacod', largeur: 140 },
            { nom: 'unadscabr', largeur: 170 },
            { nom: 'cifcod', largeur: 140 },
            { nom: 'cifdsc', largeur: 170 },
            { nom: 't_imp', largeur: 230 },
            { nom: 'type_piece', largeur: 130 },
            { nom: 'docaffnumdoc01', largeur: 160 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 160 },
            { nom: 'docaffdsc01', largeur: 160 },
            { nom: 'pecann', largeur: 110 },
            { nom: 'pecper', largeur: 110 },
            { nom: 'drenumref', largeur: 140 },
            { nom: 't_commentaire', largeur: 240 },
            { nom: 'categ', largeur: 140 },
            { nom: 'categ_desc', largeur: 160 },
            { nom: 'mntrap', largeur: 160 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 110 },
            { nom: 'mntgb', largeur: 140 },
            { nom: 'mntrg', largeur: 140 },
            { nom: 'mntrb', largeur: 141 },
            { nom: 'mntrr', largeur: 110 },
            { nom: 'mntpe', largeur: 140 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 110 },
            { nom: 'mntgs', largeur: 110 },
            { nom: 'mntbr', largeur: 140 },
            { nom: 'mntpb', largeur: 170 }
        ]
    }

    vm.gMultiOccurrenceOptions = {
        fonctions: {
            customCriteresSuggeres: true,
            enregistrerUnEtat: false,
            selectionnerUnEtat: false,
            effaceCriteres: true,
            filtrer: true,
            selectionnerDesColonnes: true,
            selectionnerTri: true,
            avisEmploye: false,
            reinitialiserPastillefiltre: true
        },
        criteresSuggeres,
        entetesReplacedValues: {
            cptcod: ecranDetails.valeurs.titcptabr,
            cptdscabr: ecranDetails.valeurs.dsccptabr,
            prucod: ecranDetails.valeurs.titprucod,
            prudscl01: ecranDetails.valeurs.titprudsc,
            vecprucatpro: ecranDetails.valeurs.titveccat,
            prscod: ecranDetails.valeurs.titprscod,
            prsdscl01: ecranDetails.valeurs.titprsdsc,
        },
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 110 },
            { nom: 'prucod', largeur: 110 },
            { nom: 'intcod', largeur: 130 },
            { nom: 'intnom', largeur: 160 },
            { nom: 'prudscl01', largeur: 160 },
            { nom: 'unacodsup', largeur: 130 },
            { nom: 'unadscsup', largeur: 160 },
            { nom: 'prscod', largeur: 120 },
            { nom: 'prsdscl01', largeur: 160 },
            { nom: 'vecprucatpro', largeur: 130 },
            { nom: 't_vecdsc', largeur: 150 },
            { nom: 'pruflgexcpb', largeur: 110 },
            { nom: 'blocod', largeur: 120 },
            { nom: 'blodscabr', largeur: 150 },
            { nom: 'cptcod', largeur: 130 },
            { nom: 'cptdscabr', largeur: 150 },
            { nom: 'el1cod', largeur: 150 },
            { nom: 'el1dscabr', largeur: 190 },
            { nom: 'unacod', largeur: 140 },
            { nom: 'unadscabr', largeur: 170 },
            { nom: 'cifcod', largeur: 140 },
            { nom: 'cifdsc', largeur: 170 },
            { nom: 't_imp', largeur: 230 },
            { nom: 'type_piece', largeur: 130 },
            { nom: 'docaffnumdoc01', largeur: 160 },
            { nom: 'refcod', largeur: 120 },
            { nom: 'refnom', largeur: 160 },
            { nom: 'docaffdsc01', largeur: 160 },
            { nom: 'pecann', largeur: 110 },
            { nom: 'pecper', largeur: 110 },
            { nom: 'drenumref', largeur: 140 },
            { nom: 't_commentaire', largeur: 240 },
            { nom: 'categ', largeur: 140 },
            { nom: 'categ_desc', largeur: 160 },
            { nom: 'mntrap', largeur: 160 },
            { nom: 'mntbi', largeur: 130 },
            { nom: 'mntbu', largeur: 110 },
            { nom: 'mntgb', largeur: 140 },
            { nom: 'mntrg', largeur: 140 },
            { nom: 'mntrb', largeur: 141 },
            { nom: 'mntrr', largeur: 110 },
            { nom: 'mntpe', largeur: 140 },
            { nom: 'mnten', largeur: 130 },
            { nom: 'mntre', largeur: 110 },
            { nom: 'mntgs', largeur: 110 },
            { nom: 'mntpb', largeur: 170 }
        ]
    }
}
