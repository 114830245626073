import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCR0235 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0235Controller(
) {
    const vm: IPageCR0235 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'cchdat', largeur: 110 },
            { nom: 'cldcod', largeur: 150 },
            { nom: 'cldnomprn', largeur: 180 },
            { nom: 'cchmnt', largeur: 110 },
            { nom: 'cchdsc', largeur: 180 },
            { nom: 'chgcod', largeur: 150 },
            { nom: 'chgdsc', largeur: 180 },
            { nom: 'crgcod', largeur: 150 },
            { nom: 'crgdsc', largeur: 180 },
            { nom: 'trimcod', largeur: 150 },
            { nom: 'trimdsc', largeur: 180 },
            { nom: 'ecjnumdoc', largeur: 110 },
            { nom: 'econumdoc', largeur: 130 },
            { nom: 'cesnumdoc', largeur: 110 },
            { nom: 'encnumdoc', largeur: 110 }
        ]
    };
}