import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageRE0011 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Re0011Controller(re2003Route: IRoute,
                                         re2004Route: IRoute,
                                         re2005Route: IRoute,
                                         re2006Route: IRoute,
                                         re2007Route: IRoute,
                                         re2008Route: IRoute,
                                         re2009Route: IRoute,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRE0011 = this;
    
    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: false,
            supprime: false,
            rechercheCollapsable: false
        },  
        menus: [  
            new MenuItemForage(
                'BTN_DET_RSI_TOUS',
                re2009Route.NAME,
                () => (vm.multiOccurrence.etatSelected && {
                    rtocleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.rtocleint,
                    t_mnt_de: vm.multiOccurrence.etatSelected.criteresSuggeresData.t_mnt_de,
                    t_mnt_a: vm.multiOccurrence.etatSelected.criteresSuggeresData.t_mnt_a
                }),
                {
                    iconButton: false,
                    icon: 'chrome_reader_mode',
                    disabled:() => !vm.multiOccurrence.etatSelected || !vm.multiOccurrence.etatSelected.criteresSuggeresData || !vm.multiOccurrence.etatSelected.criteresSuggeresData.rtocleint
                }
            ),
            new MenuItemForage(
                'BTN_DET_RST_TOUS',
                re2008Route.NAME,
                () => (vm.multiOccurrence.etatSelected && {
                    rtocleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.rtocleint,
                    t_mnt_de: vm.multiOccurrence.etatSelected.criteresSuggeresData.t_mnt_de,
                    t_mnt_a: vm.multiOccurrence.etatSelected.criteresSuggeresData.t_mnt_a
                }),
                {
                    icon: 'chrome_reader_mode',
                    disabled: () => !vm.multiOccurrence.etatSelected || !vm.multiOccurrence.etatSelected.criteresSuggeresData || !vm.multiOccurrence.etatSelected.criteresSuggeresData.rtocleint,
                    iconButton: false
                }
            )
        ],     
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_CRI_RTO'),
            new FormulaireItem('rtocleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_mnt_de'),
            new FormulaireItem('t_mnt_a'),            
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'BTN_DET_IMP',
                re2003Route.NAME,
                (data:any) => ({
                 rslcleint: data.rslcleint
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'BTN_DET_TAG',
                re2004Route.NAME,
                (data:any) => ({
                    rslcleint: data.rslcleint
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'BTN_DET_RSB',
                re2005Route.NAME,
                (data:any) => ({
                    rslcleint: data.rslcleint
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'BTN_DET_RIE',
                re2006Route.NAME,
                (data:any) => ({
                    rslcleint: data.rslcleint
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            ),
            new MenuItemForage(
                'BTN_DET_RDB',
                re2007Route.NAME,
                (data:any) => ({
                    rslcleint: data.rslcleint,
                    vaertostu: data.vaertostu
                }),
                {
                    icon: 'launch',
                    iconButton: false
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 245},
            {nom: 'rslmntcal', largeur: 120},
            {nom: 'rslflgexc', largeur: 120}
        ],
        colonnesCachees: [
            'ciecleint',
            'rslcleint',
            'rtocleint',
            'vaertostu',
            'demcleint'
        ],
        formulaire: new Formulaire([
                new FormulaireItem('eplnummat'   , {largeur: 20, readonly: true }),
                new FormulaireItem('eplnomprn'   , {largeur: 40, readonly: true}),
                new FormulaireItem('rslmntcal'   , {largeur: 20, readonly: true}),
                new FormulaireItem('rslflgexc'   , {largeur: 20})
            ])
    };   
    
}
