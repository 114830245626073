import { IMultiOccurrence,IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';


interface IPageMC0032 {
    multiOccurrenceOptions: IMultiOccurrenceOptions; 
    multiOccurrence: IMultiOccurrence;   
}
/* @ngInject */
export default function mc0032Controller(
    cr0007Route: IRoute,
    cr0024Route: IRoute,
    cr0013Route: IRoute,
    mc0052Route: IRoute,
    cr0016Route: IRoute,
    mc2044Route: IRoute,
    gl0003Route: IRoute,
    //gl0020Route: IRoute,
    in0043Route: IRoute,
    mc0109Route: IRoute,
    mc0101Route: IRoute,
    db0005Route: IRoute,
    cp2010Route: IRoute,
    cp2011Route: IRoute,
    cp2012Route: IRoute,
    cp2015Route: IRoute,
    cp2014Route: IRoute,
    ac2006Route: IRoute,
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite
) {
    const vm: IPageMC0032 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            selectionCycle: true
        },
        actions: new Menu([
            new MenuItemEcran('LBL_BTN_DOC_REV', mc2044Route.NAME, (rowData: any) => ({
                id: rowData.doccleint,
            }), {
                iconButton: false,
                class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                fonction: 'BTNDOCREV'
            })
        ]),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('redcleint', {
                largeur: 33.33, required: false
            }),
            new FormulaireItem('lotcod', {
                largeur: 33.33
            }),
            new FormulaireItem('refcleint', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('grpcodselsup', {
                largeur: 33.33
            }),
            new FormulaireItem('cifcleint', {
                largeur: 33.33
            }),
            new FormulaireItem('unacleint', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('docaffnumdoc01', {
                largeur: 25
            }),
            new FormulaireItem('docaffnumdoc01seq', {
                largeur: 25
            }),
            new FormulaireItem('docaffrefnumdoc', {
                largeur: 25
            }),
            new FormulaireItem('docaffrefnumdocseq', {
                largeur: 25
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('peccleintdeb', {
                nombreLibellesDetailsCacher: 1,
                largeur: 15
            }),
            new FormulaireItem('peccleintfin', {
                nombreLibellesDetailsCacher: 1,
                largeur: 15
            }),
            new FormulaireItem('montantde', { largeur: 15 }),
            new FormulaireItem('montantfin', { largeur: 15 }),
            new FormulaireItem('usrnom', {
                largeur: 40
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('docaffflgpij01b', {
                largeur: 33,
                default: 2
            }),
            new FormulaireItem('dapflgintverfinn', { 
                largeur: 33,
                default: 0
            }),
            new FormulaireItem('docflgavaremb', {
                largeur: 33,
                default: 2
            })            
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_CONS_IMPUTS',
                mc0052Route.NAME,
                (rowData: any) => ({
                    id: rowData.doccleint,
                    docaffnumdoc01: rowData.docaffnumdoc01,
                    tydcleint: rowData.tydcleint
                }),
                {
                    icon: 'view_list',
                    fonction: 'BOUIMP',
                    onClose: () => vm.multiOccurrence.fetchDataList(),
                    iconButton: false
                }
            )
        ]),
        navigatePage: (data: any) => getForage(data),
        navigateParams: (data: any) => getParams(data),

        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 131 },
            { nom: 'tydcod', largeur: 80 },
            { nom: 'peccod', largeur: 80 }
        ],
        colonnesVisibles: [
          { nom: 'docaffmntdepnet01', largeur: 126 },
            { nom: 'docaffmnt01', largeur: 126 },
            { nom: 'docaffflgpij01', largeur: 80 },
            { nom: 'docrefcod02', largeur: 120 },
            { nom: 'docrefnom02', largeur: 150 },
            { nom: 'docreqcod03', largeur: 100 },
            { nom: 'docreqnom03', largeur: 150 },
            { nom: 'intcoddem', largeur: 100 },
            { nom: 'intnomdem', largeur: 150 },
            { nom: 'veccatcod', largeur: 150 },
            { nom: 'veccatdsc', largeur: 170 },
            { nom: 'dapflgintverfin', largeur: 90 },            
            { nom: 'cifcod', largeur: 110 },
            { nom: 'unacod', largeur: 110 },
            { nom: 'lotcod', largeur: 110 },
            { nom: 'lotdsc', largeur: 150 },
            { nom: 'docaffnumref01', largeur: 150 },
            { nom: 'usrnom', largeur: 150 }
        ],
        colonnesFixesDroite: [
            { nom: 'docflgavarem', largeur: 150,
                menu: [
                    new MenuItemForage(
                        'LBL_FORAGE_CP2011',
                        cp2011Route.NAME,
                        (rowData: any) => {
                            return {
                                id: rowData.doccleint,
                                doccleint: rowData.doccleint,
                                refcleint: rowData.refcleint,
                                intcleintdem: rowData.intcleintdem
                            };
                        },
                        {
                            hidden: (data:any) => !data.docflgavarem
                        }
                    )
                ]
            },
            { nom: 'dncflgerr', largeur: 150 }
        ],
        colonnesCachees: ['trncleint', 'refcleint', 'tydcleint', 'ecrcod', 'intcleintdem', 'flgverfin', 'docaffcleint01']
    };

    // Note : les routes en commentaire sont pour les écrans non encore
    // développés.  Activer la route seulement lorsque l'écran est développé.

    function getForage(data: any): string {
        if (data.ecrcod === 'GL0003-00') {
            return  gl0003Route.NAME;
        // } else if (data.ecrcod === 'AC0070-00') {
            // return (ac0070Route.NAME;
          } else if (data.ecrcod === 'CP0014-00') {
             return cp2014Route.NAME;
        }  else if (data.ecrcod === 'AC0010-00-2') {
            return ac2006Route.NAME;
        }else if (data.ecrcod === 'CP0013-00') {
            return cp2010Route.NAME;
        } else if (data.ecrcod === 'CP0018-00') {
            return cp2015Route.NAME;
        } else if (data.ecrcod === 'CP0021-00') {
            return cp2012Route.NAME;
        } else if (data.ecrcod === 'CR0024-00') {
           return cr0024Route.NAME;
       // } else if (data.ecrcod === 'CP0018-00') {
        //    return cp0018Route.NAME;
       // } else if (data.ecrcod === 'CP0029-00') {
       //     return cp0029Route.NAME;
        } else if (data.ecrcod === 'CR0007-00') {
            return cr0007Route.NAME;
        } else if (data.ecrcod === 'CR0007_Z-00') {
            return cr0007Route.NAME;
        } else if (data.ecrcod === 'CR0013-00') {
            return cr0013Route.NAME;
        } else if (data.ecrcod === 'CR0016-00') {
            return cr0016Route.NAME;
        } else if (data.ecrcod === 'DB0005-00') {
            return db0005Route.NAME;
        } else if (data.ecrcod === 'GL0003-00') {
            return gl0003Route.NAME;
       // } else if (data.ecrcod === 'GL0020-00') {
       //     return gl0020Route.NAME;
       // } else if (data.ecrcod === 'IN0014-00') {
       //     return in0014Route.NAME;
        } else if (data.ecrcod === 'IN0043-00') {
            return in0043Route.NAME;
        } else if (data.ecrcod === 'MC0101-00') {
            return mc0101Route.NAME;
        } else if (data.ecrcod === 'MC0109-00') {
            return mc0109Route.NAME;
        //} else if (data.ecrcod === 'PD0006-00') {
        //    return pd0006Route.NAME;
        }
    }
    function getParams(data: any): any {
        if (data.ecrcod === 'CP0013-00' ||
            data.ecrcod === 'CP0018-00' ||
            data.ecrcod === 'CP0021-00' ||   data.ecrcod === 'AC0010-00-2' 
        ) {
            return { id: data.docaffcleint01};
        }
        if (data.ecrcod === 'CP0014-00'){
            return { id: data.docaffcleint01};
        }
        return { id: data.doccleint };
    }
}
