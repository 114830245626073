import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPageRR0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0026Controller(rr0017Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0026 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0017Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                etlcleint: rowData.etlcleint,
                eplcleint: rowData.eplcleint
            };
        },
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0017Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 100},
            {nom: 'eplnomprnusu', largeur: 263},
            {nom: 'ttlcod', largeur: 90},
            {nom: 'ttldsc', largeur: 184},
            {nom: 'etlnumtel', largeur: 132},
            {nom: 'etlnumpos', largeur: 90},
            {nom: 'etladrcou', largeur: 263},
            {nom: 'etldatvig', largeur: 100},
            {nom: 'etldsc', largeur: 263},
            {nom: 'etlflgact', largeur: 65}
        ]
    };
}
