import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IController } from 'angular';

interface IPageCr0002 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRtsOptions: IMultiOccurrenceOptions;
}
interface IPageCr0002Params {
    enecleint: string;
    indfor: number;
}

/* @ngInject */
export default function Cr0002Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IPageCr0002Params
) {
    const vm: IPageCr0002 = this;

    vm.$onInit = $onInit;
    function $onInit() {
        vm.multiOccurrenceOptions.fonctions.recherche = !$stateParams.enecleint;
    };

    vm.multiOccurrenceRtsOptions = {
        bloc: 'rts',
        fonctions: {
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor)
        },
        colonnesVisibles: [
            { nom: 'ctecod', largeur: 25 },
            { nom: 'ctedsc', largeur: 25 },
            { nom: 'vaectecat', largeur: 25 },
            { nom: 'rtsdatvig', largeur: 25 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ctecleint', { largeur: 25 }),
            new FormulaireItem('ctedsc', { largeur: 25, readonly: true }),
            new FormulaireItem('vaectecat', { largeur: 25, readonly: true }),
            new FormulaireItem('rtsdatvig', { largeur: 25 })
        ])
    };

    vm.multiOccurrenceOptions = {

        filtresParametres: [{
            colonne: 'enecleint',
            visible: false
        }],

        colonnesVisibles: [
            { nom: 'enecod', largeur: 8 },
            { nom: 'enedsc', largeur: 120 },
            { nom: 'eneflgstu', largeur: 10 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('enecod', { required: true }),
            new FormulaireItem('enedsc', { required: true }),
            new FormulaireItem('eneflgstu', { required: true, default: true }),
        ]),
        fonctions: {
            rechercheCollapsable: false,
            nouveau: !($stateParams.indfor),
            edition: !($stateParams.indfor),
            supprime: !($stateParams.indfor)
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceRtsOptions
    };
}
