import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'as0016',
    ecrans: ['00-2'] ,
    params:['?sutcleint',
            '?vaesegdec',
            '?indfor',
            '?sutcod']
});
