import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageCC2000 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc2000Controller() {
    const vm: IPageCC2000 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            edition: false,
            supprime: false
        },
        colonnesFixesGauche: [
            { nom: 'fcocrscod', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'fcocrsdsc', largeur: 110 },
            { nom: 'fcounocodcrs', largeur: 262 },
            { nom: 'unodscabrcrs', largeur: 262 },
            { nom: 'fcotrmcod', largeur: 150 },
            { nom: 'fcotrmann', largeur: 110 },
            { nom: 'fcotrmdatdeb', largeur: 110 },
            { nom: 'fcotrmdatfin', largeur: 110 },
            { nom: 'fcotrmdsc', largeur: 150 },
            { nom: 'fcocofgrp', largeur: 150 },
            { nom: 'fcocofnbrcre', largeur: 150 },
            { nom: 'fcocofnbretumax', largeur: 150 },
            { nom: 'fcocofnbrhre', largeur: 150 },
            { nom: 'fcounocod', largeur: 150 },
            { nom: 'unodscabr', largeur: 150 },
            { nom: 'fcolitcod', largeur: 150 },
            { nom: 'litdsc', largeur: 150 },
            { nom: 'fcocofdscinfadd', largeur: 150 },
            { nom: 'fcocofdsc', largeur: 150 },
            { nom: 'fcocofflgact', largeur: 150 },
            { nom: 'fcocofflgatrprf', largeur: 150 },
            { nom: 'fcoeplnummat', largeur: 150 },
            { nom: 'eplnomprn', largeur: 150 },
            { nom: 'fcoflgerr', largeur: 150 },
            { nom: 'fceerr', largeur: 150 }
        ]
    };
}
