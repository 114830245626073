// Module
var code = `<ex-mono-occurrence-ecran formulaire="vm.formulairePbg" mono-occurrence-options="vm.monoOccurrenceOptions"> <ex-mono-occurrence-ecran-bas> <ex-dashboard-card> <ex-dashboard-card-content-left> <ex-card lbl-titre="LBL_REVENU"> <ex-card-content> <ex-data-bar-list cols="[
                        {nom: 'pbgmntrevsce1', classeCouleur: 'ex-blue-500-bg'},
                        {nom: 'pbgmntrevsce2', classeCouleur: 'ex-amber-900-bg'},
                        {nom: 'pbgmntrevsce3', classeCouleur: 'ex-rouge-500-bg'}
                        ]"> </ex-data-bar-list> </ex-card-content> </ex-card> <ex-card lbl-titre="LBL_CONCIL"> <ex-card-content> <ex-data-bar-list cols="[
                        {nom: 'pbgmntrgisce1', classeCouleur: 'ex-blue-500-bg'},
                        {nom: 'pbgmntrgisce2', classeCouleur: 'ex-amber-900-bg'},
                        {nom: 'pbgmntrgisce3', classeCouleur: 'ex-rouge-500-bg'}
                        ]"> </ex-data-bar-list> </ex-card-content> </ex-card> </ex-dashboard-card-content-left> <ex-dashboard-card-content-right> <ex-card lbl-titre="LBL_DEPENSE"> <ex-card-content> <ex-data-bar-list cols="[
                        {nom: 'pbgmntdepsce1', classeCouleur: 'ex-blue-500-bg'},
                        {nom: 'pbgmntdepsce2', classeCouleur: 'ex-amber-900-bg'},
                        {nom: 'pbgmntdepsce3', classeCouleur: 'ex-rouge-500-bg'}
                        ]"> </ex-data-bar-list> </ex-card-content> </ex-card> <ex-card lbl-titre="LBL_DIFFERENTIEL"> <ex-card-content> <ex-data-bar-list cols="[
                        {nom: 'pbgmntdifsce1', classeCouleur: 'ex-blue-500-bg'},
                        {nom: 'pbgmntdifsce2', classeCouleur: 'ex-amber-900-bg'},
                        {nom: 'pbgmntdifsce3', classeCouleur: 'ex-rouge-500-bg'}
                        ]"> </ex-data-bar-list> </ex-card-content> </ex-card> </ex-dashboard-card-content-right> </ex-dashboard-card> </ex-mono-occurrence-ecran-bas> </ex-mono-occurrence-ecran> `;
// Exports
module.exports = code;