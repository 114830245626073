import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';


interface IPageMR0179 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    
}
/* @ngInject */
export default function Mr0179Controller(mr0173Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         ) {
    const vm: IPageMR0179 = this;
  
    vm.multiOccurrenceOptions = {
        navigatePage: mr0173Route.NAME,        
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0173Route.NAME)
        ]),       
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'stanom', largeur: 200}
        ],
        colonnesVisibles: [           
            {nom: 'stadsc', largeur: 200},
            {nom: 'vaedexmne', largeur: 70},
            {nom: 'objflgseg', largeur: 70},
            {nom: 'objflgmulocc', largeur: 90},
            {nom: 'objflgprc', largeur: 70},
            {nom: 'objflgmnt', largeur: 70},
            {nom: 'objflgtym', largeur: 70},
            {nom: 'objflgindmthbmg', largeur: 70},
            {nom: 'objelecle', largeur: 200},
            {nom: 'objelecod', largeur: 200},
            {nom: 'objeledsc', largeur: 200},
            {nom: 'objcom', largeur: 200}
        ]
    };
}
