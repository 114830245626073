import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCp0055 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0055Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCp0055 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'mopcod', largeur: 80}
        ],
        colonnesFixesDroite: [
            {nom: 'mopflgweb', largeur: 76},
            {nom: 'mopflgempmod', largeur: 100},
            {nom: 'mopflgpij', largeur: 76},
            {nom: 'mopdscweb', largeur: 242},
            {nom: 'mopdscwebang', largeur: 242}
        ],
        colonnesVisibles: [
            {nom: 'mopdsc', largeur: 189},
            {nom: 'vaetypsorfon_dsc', largeur: 147},
            {nom: 'scbfol', largeur: 131},
            {nom: 'cptcod', largeur: 90},
            {nom: 'vaesysdevcod_dsc', largeur: 105},
            {nom: 'refcod', largeur: 110},
            {nom: 'refnom', largeur: 115},
            {nom: 'etfnumeme', largeur: 210},
            {nom: 'mopnumtrf', largeur: 147},
            {nom: 'mopflggrh', largeur: 120},
            {nom: 'mopflgdefgrh', largeur: 76},
            {nom: 'mopflgdot', largeur: 95}

        ],
        formulaire: new Formulaire('LBL_MODE_PAIEMENT',[
            new FormulaireItem('mopflgweb'),
            new FormulaireItem('mopflgempmod'),
            new FormulaireItem('mopflgpij'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('mopdscweb', {
                largeur: 50
            }),
            new FormulaireItem('mopdscwebang', {
                largeur: 50
            })
        ], {largeurDefaut: 25}),
        fonctions: {
            suiviModification: true
        }
    };
}
