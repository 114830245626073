import {IRoute} from '../../../../core/interfaces/route.interface';
import {ILocationService, module} from 'angular';
import { IStateProvider } from 'angular-ui-router';
import { IAccesManager } from '../../../../core/services/acces-manager.service';
import LoginSsoController from './login-sso.controller';
import { IBasePathConstant } from '../../../../core/constants/base-path.constant';

const route: IRoute = {
    NAME: 'unsecure.login-sso',
    URL: '/login-sso'
};

export default module('app.pages.login-sso', [
    'core.providers.acces-manager',
    'core.layouts.content-only'
]).config(config).constant('loginSsoRoute', route);

/* @ngInject */
function config($stateProvider: IStateProvider) {
    const template = require('./login-sso.html');

    $stateProvider.state(route.NAME, {
        url: route.URL,
        resolve: {
            login: LoginSsoResolver
        },
        params: {
            route
        },
        views: {
            content: {
                template,
                controller: LoginSsoController,
                controllerAs: 'vm'
            }
        }
    });

    /* @ngInject */
    function LoginSsoResolver($location: ILocationService, accesManager: IAccesManager, basePath: IBasePathConstant) {
        return accesManager.verifierAcces().then(() => {
            location.href = `${basePath}${$location.search()["returnurl"].replace(/~~/g, "&") || "/accueil"}`;
        }).catch(() => {
            // C'est ok, on va s'authentifier
        });
    }
}