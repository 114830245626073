import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterDate } from 'angular';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuSectionClass } from '../../../../../core/services/menu/menu-section.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMR0247 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0247Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    dateFilter: IFilterDate,
    MenuItemSelection: IMenuItemSelectionClass,
    Menu: IMenuClass,
    MenuSection: IMenuSectionClass,
    MenuItemAction: IMenuItemActionClass) {
    const vm: IPageMR0247 = this;

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([
            new MenuItemAction(
                'G_LBL_COPIE',
                'BOUCOP',
                () => ({
                    tagcleint_ori: vm.multiOccurrence.etatSelected.criteresSuggeresData.t_tagcleint,
                    datvig: vm.multiOccurrence.etatSelected.criteresSuggeresData.t_datvig
                }),
                {
                    iconButton: true,
                    icon: 'file_copy',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_COPIE_TAG',
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        largeurFormulaire: 30,
                        formulaire: new Formulaire([
                            new FormulaireItem('tagcleint_dst', {
                                largeur: 100,
                                required: true
                            }),
                            new FormulaireItem('flgsub', {
                                largeur: 100,
                                required: true,
                                default: 0
                            })
                        ])
                    },
                    successAction: (data: any) => {
                        vm.multiOccurrence.etatSelected.criteresSuggeresData.t_tagcleint = data.tagcleint_dst;
                        vm.multiOccurrence.etatSelected.criteresSuggeresData.t_tagcleint__tagcod = data.tagcod_dst;
                        vm.multiOccurrence.etatSelected.criteresSuggeresData.t_tagcleint__tagdscabr = data.tagdscabr_dst;

                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        actionsSelection: new Menu([
            new MenuSection('LBL_GESTION'),
            new MenuItemSelection('LBL_BTN_MNT_ADM', 'BOUMNTADM', {
                selection: { nom: 'mntadm' },
                fonction: 'BOUMNTADM'
            }),
            new MenuItemSelection('LBL_BTN_MNT_NONADM', 'BOUMNTNONADM', {
                selection: { nom: 'mntnonadm' },
                fonction: 'BOUMNTNONADM'
            }),
            new MenuItemSelection('LBL_BTN_QTE_ADM', 'BOUQTEADM', {
                selection: { nom: 'qteadm' },
                fonction: 'BOUQTEADM'
            }),
            new MenuItemSelection('LBL_BTN_QTE_NONADM', 'BOUQTENONADM', {
                selection: { nom: 'qtenonadm' },
                fonction: 'BOUQTENONADM'
            }),
            new MenuItemSelection('LBL_BTN_QTEMNT_ADM', 'BOUQTEMNTADM', {
                selection: { nom: 'qtemntadm' },
                fonction: 'BOUQTEMNTADM'
            }),
            new MenuItemSelection('LBL_BTN_QTEMNT_NONADM', 'BOUQTEMNTNONADM', {
                selection: { nom: 'qtemntnonadm' },
                fonction: 'BOUQTEMNTNONADM'
            })
        ]),
        criteresSuggeres: new Formulaire([

            new FormulaireItem('t_tagcleint', {
                required: true,
                largeur: 30
            }),
            new FormulaireItem('t_datvig', {
                required: true,
                default: dateFilter(new Date(), 'yyyy-MM-dd'),
                largeur: 50
            })
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesCachees: ['admcleint',
            'cdtcleint',
            'tagcleint'],

        colonnesVisibles: [
            { nom: 'colcod', largeur: 110 },
            { nom: 'coldsc', largeur: 300 },
            { nom: 'cdtdatvig_prec', largeur: 110 },
            { nom: 'cdtdatvig', largeur: 110 },
            { nom: 'cdtdatvig_suiv', largeur: 110 },
            { nom: 'admflgmnt', largeur: 110 },
            { nom: 'admflgqte', largeur: 110 }
        ]
    };
}
