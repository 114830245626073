import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageRr0158 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireMpa: IFormulaire;
    mreMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mmmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    musMultiOccurrenceOptions: IMultiOccurrenceOptions;

}

/* @ngInject */
export default function Rr0158Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRr0158 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireMpa = new Formulaire('LBL_BLC_MPA', [
            new FormulaireItem('unpcleint'),
            new FormulaireItem('ppacleint'),
            new FormulaireItem('vaempatyp'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('mpamsg', {largeur: 66, enableCount: true}),
            new FormulaireItem('vaempatyprce', {
                default: 'CUM'
            }),
        ], {largeurDefaut: 33});

        vm.mreMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'rcecod', largeur: 200},
                {nom: 'rcedscabr', largeur: 200}

            ],
            formulaire: new Formulaire([
                new FormulaireItem('rcecleint')
            ], {largeurDefaut: 50})
        };

        vm.mmmMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'eplnummat', largeur: 200},
                {nom: 'eplnomprn', largeur: 200}

            ],
            formulaire: new Formulaire([
                new FormulaireItem('demcleint')
            ], {largeurDefaut: 50})
        };
        vm.musMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'urgcod', largeur: 200},
                {nom: 'urgdscabr', largeur: 300},
                {nom: 'strcod', largeur: 200},
                {nom: 'strdsc', largeur: 300}

            ],
            formulaire: new Formulaire([
                new FormulaireItem('urgcleint'),
                new FormulaireItem('strcleint')
            ], {largeurDefaut: 50})
        };
    }
}
