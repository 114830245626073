import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';


interface IPageTs0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ts0013Controller(parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageTs0013 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'tslnumdoc', largeur: 105},
            {nom: 'tslnumeve', largeur: 105},
            {nom: 'tslflgurg', largeur: 75},
            {nom: 'temcod', largeur: 105},
            {nom: 'temdscabr', largeur: 262},
            {nom: 'nbrdemaff', largeur: 318},
            {nom: 'vaetyptsl_dsc', largeur: 126},
            {nom: 'vaeoritsl_dsc', largeur: 173},
            {nom: 'tsldatdeb', largeur: 150},
            {nom: 'tsldatfin', largeur: 150},
            {nom: 'duree', largeur: 100},
            {nom: 'urgcod', largeur: 120},
            {nom: 'urgdscabr', largeur: 262},
            {nom: 'vacvalcle_lit', largeur: 125},
            {nom: 'vacdsc_lit', largeur: 262},
            {nom: 'unocod', largeur: 110},
            {nom: 'unodscabr', largeur: 262},
            {nom: 'intcoddem', largeur: 105},
            {nom: 'tslnomdem', largeur: 262},
            {nom: 'tsldsc', largeur: 394},
            {nom: 'rrlcod', largeur: 105},
            {nom: 'rrldsc', largeur: 262},
            {nom: 'tlsdatdebmin', largeur: 150},
            {nom: 'tlsdatfinmax', largeur: 150},
            {nom: 'tlsqtetausimtot', largeur: 100},
            {nom: 'tlsqtetaudemtot', largeur: 100},
            {nom: 'tlsqtetaudoutot', largeur: 100},
            {nom: 'tlsqtecrttot', largeur: 110},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'ecldsceta', largeur: 180},
            {nom: 'trnflgmsg', largeur: 65},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
