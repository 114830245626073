import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageGa2001 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireDoc: IFormulaire;
    valeursEcran: any;
}

/* @ngInject */
export default function Ga2001Controller(ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageGa2001 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.multiOccurrenceOptions = {            
            colonnesVisibles: [
                {nom: 'imputation'    , largeur: 300},
                {nom: 'tydcod'        , largeur: 110},
                {nom: 'docaffnumdoc01', largeur: 150},
                {nom: 'docrefcod02'   , largeur: 180},
                {nom: 'docrefnom02'   , largeur: 210},
                {nom: 'docaffdsc01'   , largeur: 210},
                {nom: 'pecann'        , largeur: 110},
                {nom: 'pecper'        , largeur: 110},
                {nom: 'mnt_bud_ini'   , largeur: 150},
                {nom: 'mnt_bud_rev'   , largeur: 150},
                {nom: 'mnt_rab'       , largeur: 150},
                {nom: 'mnt_gb'        , largeur: 150 },
                {nom: 'mnt_rar'       , largeur: 150 },
                {nom: 'mnt_re'        , largeur: 150 },
                {nom: 'mnt_pe'        , largeur: 150 },
                {nom: 'mnt_eng'       , largeur: 150 },
                {nom: 'mnt_reel'      , largeur: 150 },
                {nom: 'mnt_gs'        , largeur: 150 },
                {nom: 'mnt_pb'        , largeur: 170 }
            ]
        };
       
    }
}
