import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IFormulaireItemSautDeColonneClass } from "../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service";
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageRc0001 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceDOIOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rc0001Controller(
    mc1014Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    $stateParams: any,
    MenuItemEcran: IMenuItemEcranClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRc0001 = this;


    vm.monoOccurrenceOptions = {
        cycleErrorReloadBlocs: ['rci'],
        infosMonetaires: {
            blocs: ['rci'],
            donnees:
                [
                    { nom: 'txmmntrec', libelle: 'TXMMNTREC' },
                    { nom: 'devtaucou', libelle: 'DEVTAUCOU' }
                ]
        },
        menus: [
            new MenuItemEcran(
                'LBL_BTN_TOLERANCE',
                mc1014Route.NAME,
                () => ({
                    tolcleintref: vm.monoOccurrence.data.txmcleint,
                    vaeustent: 'TXM',
                    indfor: vm.monoOccurrence.data.indfor
                }),
                {
                    fonction: 'BOUTOL',
                    icon: 'data_usage',
                    iconButton: false,
                    secondaire: true
                }
            )
        ]
    };

    vm.formulaireTxm = new Formulaire('LBL_BLC_TXM', [
        new FormulaireItem('txmnumdoc', { largeur: 33.33, readonly: true }),
        new FormulaireItem('txmnumdocseq', { largeur: 33.33, readonly: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('tydcleint', { largeur: 66.66, readonly: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('txmnumref'),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('doccleintref', { largeur: 66.66, readonly: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('refcleint', { largeur: 66.66, readonly: true }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('peccleint'),
        new FormulaireItem('txmdat'),
        new FormulaireItem('txmdatreq'),
        new FormulaireGroupe('', new Formulaire([
            new FormulaireItem('txmnomtra', { largeur: 50 }),
            new FormulaireItem('indfor', { default: ecranDetails.valeurs.indfor, hidden: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('txmnumemb', { largeur: 50 })
        ])),
        new FormulaireGroupe('', new Formulaire([
            new FormulaireItem('txmnbrcolrec'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('txmnbrcolliv'),
        ])),
        new FormulaireGroupe('', new Formulaire([
            new FormulaireItem('txmdsc', { largeur: 50 }),
            new FormulaireItem('vectxmcat', { largeur: 35 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemSautDeColonne({ largeur: 50 }),
            new FormulaireItem('lipcleint', { largeur: 35 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItemSautDeColonne({ largeur: 50 }),
            new FormulaireItem('dacatt', { readonly: true, largeur: 35 })
        ])),
        new FormulaireGroupeAccordeon('LBL_AUTRE', new Formulaire([
            new FormulaireItem('ltrcod', { default: ecranDetails.valeurs.ltrcod, largeur: 33.33, readonly: true }),
            new FormulaireItem('lrtcodseq', { titre: ' ', default: ecranDetails.valeurs.lrtcodseq, largeur: 33.33, readonly: true }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintreq', { largeur: 40 }),
            new FormulaireItem('intcleintdem', { largeur: 40 }),
        ], { largeurDefaut: 33.33 }))
    ], {
        largeurDefaut: 33.33
    });

    vm.rciMultiOccurrenceOptions = {
        bloc: 'rci',
        colonnesFixesGauche: [
            { nom: 'dailig', largeur: 115 },
            { nom: 'daidsc', largeur: 200 }
        ],
        colonnesFixesDroite: [
            { nom: 'rciflgfer', largeur: 100 },
            { nom: 'rciflgmsg', largeur: 100 }
        ],
        colonnesVisibles: [
            { nom: 'daiqte', largeur: 126 },
            { nom: 'rciqte', largeur: 126 },
            { nom: 'rciqterup', largeur: 126 },
            { nom: 'rciqteruprec', largeur: 126 },
            { nom: 'forcod', largeur: 120 },
            { nom: 'fordsc', largeur: 180 },
            { nom: 'rcicom', largeur: 210 },
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rciligref', { required: true, largeur: 40, modifAllow: false, resourceParams: () => ({ doccleint: $stateParams.id }) }),
            new FormulaireItem('rciqte'),
            new FormulaireItem('rciqterup'),
            new FormulaireItem('rciqteruprec'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rcicom', { largeur: 80 }),
            new FormulaireItem('rciflgfer')
        ], { largeurDefaut: 20 })
    };
}
