import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0142 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0142Controller(mr0143Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,) {
    const vm: IPageMR0142 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: mr0143Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0143Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'cytcod', largeur: 89},
            {nom: 'cytdsc', largeur: 262},
            {nom: 'cytcom', largeur: 284},
            {nom: 'stamnetab', largeur: 143},
            {nom: 'etape_ini', largeur: 158}
        ]
    };
}
