import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPagePb0100 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pb0100Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePb0100 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            boutonDupliquer: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('pbscod'),
            new FormulaireItem('pbsdsc'),
            new FormulaireItem('pbsseq',{required: true}), 
            new FormulaireItem('pbsflgini')
        ], {largeurDefaut: 50}),
        colonnesVisibles: [
            {nom: 'pbscod'          , largeur: 150},
            {nom: 'pbsdsc'          , largeur: 350},
            {nom: 'pbsseq'          , largeur: 350},
            {nom: 'pbsflgini'       , largeur: 350}
        ]
    };
}
