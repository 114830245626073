import { IComponentController } from "angular";
import { IRoute } from "../../../../../core/interfaces/route.interface";
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMenuItemForageClass } from "../../../../../core/services/menu/menu-item-forage.service";
import { IMenuClass } from "../../../../../core/services/menu/menu.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IStateService } from "angular-ui-router";

interface IPageCp2004 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

interface ICp2004Params {
    menuId: string;
}
/* @ngInject */
export default function Cp2004Controller(
    cp2005Route: IRoute,
    Menu: IMenuClass,
    $state: IStateService,
    $stateParams: ICp2004Params,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm = this as IPageCp2004

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(
                '',
                cp2005Route.NAME,
                () => {
                    return {
                        ecran: vm.valeursEcran.ecrcod
                    }
                },
            )
        ]),
        navigatePage: cp2005Route.NAME,
        navigateParams: () => vm.valeursEcran.ecrcod === '00-2' ? { ecran: '00-2' } : { ecran: '01-2' },
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: "zranumdoc", largeur: 100 },
            { nom: "zradat", largeur: 100 },
            { nom: "zramnttot", largeur: 100 },
            { nom: "refcod", largeur: 150 },
            { nom: "refnom", largeur: 150 },
            { nom: "zradoc", largeur: 150 },
            { nom: "zranumref", largeur: 150 },
            { nom: "zrarad", largeur: 150, hidden: (ecranDetails.flgcen === 0) ? true : false },
            { nom: "ecldsceta", largeur: 150 },
            { nom: "zradsc", largeur: 300 },
            { nom: "zradscext", largeur: 300 },
            { nom: "zczdsc", largeur: 150 }
        ],
        colonnesDisponibles: [
            { nom: "datini", largeur: 150 },
            { nom: "datder", largeur: 150 }
        ]
    }
}