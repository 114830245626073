import * as angular from 'angular';
import {
    IAugmentedJQuery,
    IComponentController,
    IHttpResponse,
    IHttpService,
    IQService,
    IRootScopeService, IScope
} from 'angular';
import { IAideTechniqueService } from '../services/aide-technique.service';
import { IDonneesEnregistrementService } from '../services/donnees-enregistrement.service';
import { ICouleurThemeService } from '../../../core/services/couleur-theme.service';
import { ICompagnie } from '../../../core/interfaces/compagnie.interface';
import settingGlobal, { SettingGlobal } from '../../../core/services/settingGlobal.service';
import { IDialog } from '../../../core/services/dialog.service';
import { IDefaultsService } from '../../../core/services/utils/defaults.service';
import { IProfil } from '../../../core/resources/profil.resource';
import { IApiConfig } from '../../../core/interfaces/api-config.interface';
import { IRoute } from '../../../core/interfaces/route.interface';
import { IAccesManager } from '../../../core/services/acces-manager.service';
import ISidenavService = angular.material.ISidenavService;
import IDialogOptions = angular.material.IDialogOptions;
import { ISourceDetails } from "../../../core/resources/source-details.resource";
import IResourceService = angular.resource.IResourceService;
import { IEcranDetailsResourcesEntite } from "../../../core/resources/ecran-details.resource";
import { IParametresSecuriteService } from "../../../core/services/parametres-securite.service";
import { detect } from 'detect-browser';
import { IMenuItemForage, IMenuItemForageClass } from '../../../core/services/menu/menu-item-forage.service';
import packageJson from "../../../../package.json";
import { IMenuItem, IMenuItemClass } from "../../../core/services/menu/menu-item.service";
import { IAppTitreHelper, ISelectionTitreColonne } from "../../../core/services/app-titre-helper.service";

interface IUsager {
    usrnom: string
}

interface IToolbarModel extends IComponentController {
    aideTechnique: IAideTechniqueService;
    donneesEnregistrement: IDonneesEnregistrementService;
    couleurTheme: ICouleurThemeService;
    compagnie: ICompagnie;
    settingGlobal: SettingGlobal;
    usager: IUsager;
    busy: boolean;
    settingGlobalMultiOccurrenceDistributionWidths: boolean;
    navigationOpened: boolean;
    hasOutilsSupports: boolean;
    hasMenuDebug: boolean
    hasAccesLovInterventions: boolean;
    isEcranDeveloppement: boolean;
    interventionActive: IIntervention;
    ecranSpinner?: boolean;
    ecranEtatRequetes: IMenuItemForage;
    ecranCalculetteTaxes: IMenuItemForage;
    ecranCalculetteJours: IMenuItemForage;
    selecteurTitreMenuItem: IMenuItem
    appVersion: string;
    apiVersion: string;
    ibddatedonnees: string;
    ibdprovdonnees: string;
    ibdversionapp: string;
    logout(): void;
    setTheme(theme: string): void;
    toggleSidenav(): void;
    toggleOutils(): void;
    setSettingGlobalMultiOccurrenceDistributionWidths(): void;
    switchCompagnie(compagnie: ICompagnie): void;
    ouvrirSelectionIntervention(): void;
    outilsIsOpen(): boolean;
    hasMoreThanOneCompanie(): boolean;
}

interface IIntervention {
    itvcleint?: number;
    itvnum?: string;
    itvsuj?: string;
}

/* @ngInject */
export default function ToolbarController($mdSidenav: ISidenavService,
    $element: IAugmentedJQuery,
    $resource: IResourceService,
    $stateParams: any,
    ecranDetails: IEcranDetailsResourcesEntite,
    accesManager: IAccesManager,
    DialogSelectionIntervention: IDialog, DialogSelecteurTitre: IDialog,
    defaults: IDefaultsService,
    donneesEnregistrement: IDonneesEnregistrementService,
    couleurTheme: ICouleurThemeService,
    aideTechnique: IAideTechniqueService,
    profil: IProfil,
    ApiConfig: IApiConfig, AppTitreHelper: IAppTitreHelper,
    $q: IQService,
    $rootScope: IRootScopeService,
    $scope: IScope,
    MenuItemForage: IMenuItemForageClass, MenuItem: IMenuItemClass,
    accueilRoute: IRoute,
    ecranSourceDetails: ISourceDetails,
    parametresSecurite: IParametresSecuriteService,
    gs0037Route: IRoute,
    mc1016Route: IRoute,
    mr1011Route: IRoute,
    DialogConfirm: IDialog,
    $http: IHttpService,
    $window: angular.IWindowService) {
    const vm: IToolbarModel = this;

    vm.aideTechnique = aideTechnique;
    vm.donneesEnregistrement = donneesEnregistrement;
    vm.couleurTheme = couleurTheme;
    vm.settingGlobal = settingGlobal;
    vm.hasAccesLovInterventions = profil.accesUsager.selectionIntervention;
    vm.isEcranDeveloppement = ecranSourceDetails.sourceDeveloppement;

    vm.$onInit = $onInit;
    vm.logout = logout;
    vm.setTheme = setTheme;
    vm.toggleSidenav = toggleSidenav;
    vm.toggleOutils = toggleOutils;
    vm.setSettingGlobalMultiOccurrenceDistributionWidths = setSettingGlobalMultiOccurrenceDistributionWidths;
    vm.ouvrirSelectionIntervention = ouvrirSelectionIntervention;
    vm.switchCompagnie = switchCompagnie;
    vm.outilsIsOpen = outilsIsOpen;
    vm.hasMoreThanOneCompanie = hasMoreThanOneCompanie
    vm.showMessageNavigateurNonSupporte = showMessageNavigateurNonSupporte;
    vm.window = window as any;

    function $onInit() {
        // Thème
        couleurTheme.changerCouleur(profil.preferences.theme);

        // Setting global
        settingGlobal.setMultiOccurrenceDistributionWidths(profil.preferences.gsfsetglbrepcol == null ? true : Boolean(profil.preferences.gsfsetglbrepcol));

        vm.usager = { usrnom: profil.usecleint ? profil.nomUsager : (profil.nomEmployeACRH) ? profil.nomEmployeACRH : profil.preferences.usrnomcre };

        vm.hasOutilsSupports = profil.parametres.OUTILS_SUPPORT === 'OUI'
        vm.hasMenuDebug = profil.parametres.MENU_DEBUG === 'OUI'
        defaults(vm, {
            accueilRoute: accueilRoute,
            compagnie: profil.compagnie,
            compagnies: profil.compagnies,
            settingGlobalMultiOccurrenceDistributionWidths: vm.settingGlobal.getMultiOccurrenceDistributionWidths(),
            navigationOpened: Boolean(profil.preferences.gsfflgpannav)
        });

        $scope.$watch("vm.window.isLoading", () => {
            if (vm.window.isLoading) {
                vm.ecranSpinner = true;
            } else {
                vm.ecranSpinner = false;
            }
        });

        vm.appVersion = packageJson.version;

        $http.get(ApiConfig.URL + '/versions-app').then((response: IHttpResponse<{ ibddatedonnees: string, ibdprovdonnees: string, ibdversionapp: string, apiVersion: string }>) => {
            vm.apiVersion = response.data.apiVersion;
            vm.ibddatedonnees = response.data.ibddatedonnees;
            vm.ibdprovdonnees = response.data.ibdprovdonnees;
            vm.ibdversionapp = response.data.ibdversionapp;
        })

        listenForInterventionUpdate();
        verifyBrowserVersion();
        $scope.$on('$mdMenuOpen', () => {
            if (vm.outilsIsOpen()) {
                vm.toggleOutils();
            }
        });
    }

    function logout() {
        accesManager.logout();
    }
    function switchCompagnie(compagnie: ICompagnie) {
        profil.preferences.ciecleint = compagnie.ciecleint;
        profil.preferences.$update().then(() => {
            $window.location.reload();
        });
    }

    function hasMoreThanOneCompanie() {
        if (vm?.compagnies?.length) {
            return (vm.compagnies.length > 1) ? true : false
        }
        return false;
    }
    function setSettingGlobalMultiOccurrenceDistributionWidths() {
        vm.settingGlobalMultiOccurrenceDistributionWidths = !vm.settingGlobal.getMultiOccurrenceDistributionWidths();
        vm.settingGlobal.setMultiOccurrenceDistributionWidths(vm.settingGlobalMultiOccurrenceDistributionWidths);

        profil.preferences.gsfsetglbrepcol = vm.settingGlobal.getMultiOccurrenceDistributionWidths() ? 1 : 0;
        profil.preferences.$update();
    }

    function setTheme(theme: string) {
        vm.couleurTheme.changerCouleur(theme);
        profil.preferences.theme = theme;
        profil.preferences.$update();
    }

    function toggleSidenav() {
        profil.preferences.gsfflgpannav = profil.preferences.gsfflgpannav ? 0 : 1;
        vm.navigationOpened = !!profil.preferences.gsfflgpannav;
        profil.preferences.$update()
            .catch(function () {
                // On veut un échec silencieux, ce n'est pas assez important pour aviser l'usager
            });

        $rootScope.$broadcast('exGridReajusterLargeurColonne');
    }

    function toggleOutils() {
        $mdSidenav('outils').toggle();
    }

    function outilsIsOpen() {
        return $mdSidenav('outils').isOpen();
    }

    vm.ecranEtatRequetes = new MenuItemForage(
        'G_LBL_OUTILS_ETAT_REQUETE',
        gs0037Route.NAME,
        () => ({ ecran: '00-2' }),
        {
            icon: 'ondemand_video',
            iconButton: false,
            secondaire: true
        }
    );

    vm.ecranCalculetteTaxes = new MenuItemForage(
        'G_LBL_OUTILS_CALCULETTE_TAXES',
        mc1016Route.NAME,
        () => ({ ecran: '00-2' }),
        {
            icon: 'account_balance',
            iconButton: false,
            secondaire: true,
            linkExterne: true
        }
    );

    vm.ecranCalculetteJours = new MenuItemForage(
        'G_LBL_OUTILS_CALCULETTE_JOURS',
        mr1011Route.NAME,
        () => ({ ecran: '00-2' }),
        {
            icon: 'event',
            iconButton: false,
            secondaire: true,
            linkExterne: true
        }
    );

    let selectionTitre = JSON.parse(localStorage.getItem("uqSelectionTitreNavigateur") || "{}")

    const champsDisponibles = selectionTitre.champsDisponibles || [
        { champ: "cienom", titre: "G_LBL_SELECTION_TITRE_CIENOM" }
    ]
    const champsChoisis: ISelectionTitreColonne[] = selectionTitre.champsChoisis || [
        { champ: "titreEcran", titre: "G_LBL_SELECTION_TITRE_TITRE_ECRAN" },
        { champ: "titreContenu", titre: "G_LBL_SELECTION_TITRE_TITRE_CONTENU" },
        { champ: "codeEcran", titre: "G_LBL_SELECTION_TITRE_CODE_ECRAN" },
        { champ: "titreApp", titre: "G_LBL_SELECTION_TITRE_TITRE_APP" },
        { champ: "cienomabr", titre: "G_LBL_SELECTION_TITRE_CIENOMABR" }
    ]

    AppTitreHelper.setSelectionTitre({ champsDisponibles, champsChoisis })

    vm.selecteurTitreMenuItem = new MenuItem("G_LBL_SELECTEUR_TITRE", (event: MouseEvent) => {
        DialogSelecteurTitre.show({
            targetEvent: event,
            locals: {
                champsDisponibles,
                champsChoisis,
                confirm: () => AppTitreHelper.setSelectionTitre({ champsDisponibles, champsChoisis })
            }
        })
    }, {
        icon: "view_column",
        iconButton: false,
        secondaire: true
    })

    function ouvrirSelectionIntervention() {
        vm.toggleOutils();

        const dialogOptions: IDialogOptions = {
            locals: {
                data: Object.keys(vm.interventionActive).reduce((data: any, col: string) => {
                    if (col === 'itvcleint') {
                        data[col] = vm.interventionActive[col];
                    } else {
                        data[`itvcleint__${col}`] = (<any>vm.interventionActive)[col];
                    }

                    return data;
                }, {})
            }
        };

        DialogSelectionIntervention.show(dialogOptions)
            .then((interventionChoisie) => {
                vm.interventionActive = interventionChoisie;
                updateAffichageIntervention();
            })
    }

    function listenForInterventionUpdate() {
        if (vm.hasAccesLovInterventions) {
            $scope.$watch(() => profil.preferences.itvcleint, updateAffichageIntervention);

            $rootScope.$on('$stateChangeSuccess', updateAffichageIntervention);
        }
    }

    function updateAffichageIntervention() {
        const appContainer = $element.siblings('.app-container').first();
        vm.isEcranDeveloppement = ecranSourceDetails.sourceDeveloppement;
        const bandeauPageElement = appContainer.find('ex-bandeau-page');
        if (vm.isEcranDeveloppement) {
            if (bandeauPageElement.length) {
                validateIntervention(profil.preferences.itvcleint).then(() => injectNumeroIntervention(bandeauPageElement));
            } else {
                const removeWatcher = $scope.$watch(() => appContainer.find('ex-bandeau-page'),
                    (element) => {
                        if (element.length) {
                            validateIntervention(profil.preferences.itvcleint).then(() => injectNumeroIntervention(element));
                            removeWatcher();
                        }
                    })
            }
        }
    }

    function injectNumeroIntervention(bandeauPageElement: IAugmentedJQuery) {
        const bandeauPageCtrl = bandeauPageElement.controller('exBandeauPage');
        bandeauPageCtrl.codeInterventionActive = vm.interventionActive.itvnum;
    }

    function validateIntervention(itvcleint: number) {
        if (!profil.preferences.itvcleint) {
            vm.interventionActive = {};
            return $q.resolve();
        } else {
            const resource = $resource(`${ApiConfig.ROOT}/liste-valeurs/itv`);

            const params = {
                usrcleint: profil.preferences.usrcleint,
                srccodref: $stateParams.srccod,
                filter: `itvcleint{:}${itvcleint}`,
                ...parametresSecurite($stateParams, ecranDetails)
            };

            return resource.query(params).$promise
                .then((rows: Array<any>) => {
                    const intervention = rows.find((row: any) => row.itvcleint === itvcleint);
                    vm.interventionActive = intervention || {};

                    if (vm.interventionActive.itvcleint !== profil.preferences.itvcleint) {
                        profil.preferences.itvcleint = vm.interventionActive.itvcleint || null;
                        profil.preferences.$update();
                    }
                })
                .catch(() => {
                    // En cas d'erreur, on réinitialise l'intervention
                    vm.interventionActive = {};
                    profil.preferences.itvcleint = null;
                    profil.preferences.$update();
                });
        }
    }

    function verifyBrowserVersion() {
        const browser = detect();

        if (browser.name !== 'chrome' && browser.name !== 'firefox' && browser.name !== 'edge-chromium') {
            vm.browserNotOfficialySupported = true;

            if (!profil.preferences.gsfflgnavnonsup) {
                showMessageNavigateurNonSupporte();
            }
        }
    }

    function showMessageNavigateurNonSupporte() {
        DialogConfirm.show({
            locals: {
                lblTitre: 'G_LBL_NAVIGATEUR_NON_SUPPORTE',
                lblMessage: 'G_LBL_NAVIGATEUR_NON_SUPPORTE_MESSAGE',
                error: true,
                retry: false,
                hideCancelAction: true,
                confirmAction: () => {
                    if (!profil.preferences.gsfflgnavnonsup) {
                        profil.preferences.gsfflgnavnonsup = 1;
                        profil.preferences.$update();
                    }
                }
            }
        });
    }
}
