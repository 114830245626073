import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageRe0010 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireRto: IFormulaire;
    tsiMultiOccurrence: IMultiOccurrence;
    tsiMultiOccurrenceOptions: IMultiOccurrenceOptions; 
    tidMultiOccurrence: IMultiOccurrence;
    tidMultiOccurrenceOptions: IMultiOccurrenceOptions;       
}

/* @ngInject */
export default function Re0010Controller(Formulaire: IFormulaireClass,
                                         Pagination: IPaginationClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageRe0010 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }           
        };

        vm.formulaireRto = new Formulaire('', [
            new FormulaireItem('rtocleint', {largeur: 40}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demcleint', {largeur: 40}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lemcleint', {largeur: 40}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('lsacleint', {largeur: 40})                       
        ]);

        // Onglets Supplément salarial
        vm.tidMultiOccurrenceOptions = {
            bloc: 'tid',
            fonctions: {
                importation: true,
                supprime : (data : any) => data.vaesysprvret === 'RETR',
                pagination: true
            },            
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 25, 50]
            }),
            colonnesVisibles: [
                {nom: 'tidseq', largeur: 120},
                {nom: 'tagcod', largeur: 120},
                {nom: 'tagdscabr', largeur: 120},
                {nom: 'tidsal', largeur: 120},
                {nom: 'tidpou', largeur: 120}
            ],
            colonnesCachees: [
                'vaesysprvret'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tidseq'      , {largeur: 10}),
                new FormulaireItem('tagcleint'   , {largeur: 25,  readonly: (data: any) => data.vaesysprvret === 'PROD'}),
                new FormulaireItem('tidsal'      , {largeur: 10,  readonly: (data: any) => data.vaesysprvret === 'PROD'}),
                new FormulaireItem('tidpou'      , {largeur: 10,  readonly: (data: any) => data.vaesysprvret === 'PROD'})
            ])
        };

        // Onglets Salaire
        vm.tsiMultiOccurrenceOptions = {
            bloc: 'tsi',
            fonctions: {
                importation: true,
                supprime : (data : any) => data.vaesysprvret === 'RETR',
                pagination: true
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 25, 50]
            }),
            colonnesVisibles: [
                {nom: 'tsidatvig', largeur: 120},
                {nom: 'geccod', largeur: 220},
                {nom: 'eeccod', largeur: 120},
                {nom: 'tsiflgina', largeur: 120},
                {nom: 'vaedsc', largeur: 120},
                {nom: 'typsal', largeur: 120},
                {nom: 'tsihreheb', largeur: 120},
                {nom: 'tsisal', largeur: 120},
                {nom: 'unccod', largeur: 120},
                {nom: 'tsidsc', largeur: 180},                
                {nom: 'tsicom', largeur: 120},
                {nom: 'ecldsceta', largeur: 140},
                {nom: 'esadatvig', largeur: 120},
                {nom: 'eecsal', largeur: 120},
                {nom: 'eecsalmin', largeur: 120},
                {nom: 'eecsalmax', largeur: 120}
            ],
            colonnesCachees: [
                'vaesysprvret'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tsidatvig'   , {largeur: 10, readonly: (data) => data.vaesysprvret === 'PROD'}),
                new FormulaireItem('geccleint'   , {largeur: 25, 
                       resourceParams: () => ({ecfcleint: vm.monoOccurrence.data.ecfcleint}),
                       readonly: (data) => data.vaesysprvret === 'PROD' || data.vaecvttypech === '0',
                       required: (data: any) => data.vaecvttypech === '1'}),
                new FormulaireItem('eeccleint'   , {largeur: 10, 
                       resourceParams: (data: any) => ({rtocleint: data.rtocleint, tsidatvig: data.tsidatvig}), 
                       readonly: (data) => data.vaesysprvret === 'PROD' || data.vaecvttypech === '0',
                       required: (data: any) => data.vaecvttypech === '1'}),
                new FormulaireItem('tsiflgina'   , {largeur: 10, readonly: (data) => data.vaesysprvret === 'RETR'}),
                new FormulaireItem('vaesysprvret', {largeur: 10, default : 'RETR', readonly: true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('vaetsatypsal', {largeur: 10, readonly: (data) => data.vaesysprvret === 'PROD' || data.vaecvttypech === '0' || data.vaecvttypech === '1'}),
                new FormulaireItem('tsihreheb'   , {largeur: 10, readonly: (data) => data.vaesysprvret === 'PROD'}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsisal'      , {largeur: 10, readonly: (data) => data.vaesysprvret === 'PROD' && data.vaecvttypech != '0' || data.vaegectypech != '2',
                                                    required: (data: any) => data.vaecvttypech === '0' ||data.vaecvttypech === '1' && data.vaegectypech === '2' }),
                new FormulaireItem('unccleint'   , {largeur: 10, readonly: (data) => data.vaesysprvret === 'PROD' || data.vaecvttypech != '0' ,
                                                    required: (data: any) => data.vaecvttypech === '0' || data.geccleint}),
                new FormulaireItem('esadatvig'   , {largeur: 10, readonly: true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsidsc'      , {largeur: 30, readonly: (data) => data.vaesysprvret === 'PROD'}),
                new FormulaireItem('eecsal'      , {largeur: 10, readonly: true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsicom'      , {largeur: 30, readonly: (data) => data.vaesysprvret === 'PROD'}),
                new FormulaireItem('eecsalmin'   , {largeur: 10, readonly: true}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ecldsceta'   , {largeur: 30, readonly: true}),
                new FormulaireItem('eecsalmax'   , {largeur: 10, readonly: true})
            ]),
            multiOccurrenceOptionsEnfant: vm.tidMultiOccurrenceOptions
        }
    }
}
