import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0181 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0181Controller(Menu: IMenuClass,
                                         mr0182Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0181 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0182Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0182Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'tdmcod', largeur: 90},
            {nom: 'tdmdsc', largeur: 262},
            {nom: 'vaetdmmodsai_dsc', largeur: 178},
            {nom: 'ttccod', largeur: 90},
            {nom: 'ttcdsc', largeur: 262},
            {nom: 'ttccodpro', largeur: 120},
            {nom: 'ttcdscpro', largeur: 262},
            {nom: 'tdmflgcen', largeur: 95},
            {nom: 'tdmflgdscobldec', largeur: 75},
            {nom: 'tdmflglem', largeur: 91},
            {nom: 'tdmflgaft', largeur: 105},
            {nom: 'tdmflgtem', largeur: 91},
            {nom: 'tdmflgpossup', largeur: 95},
            {nom: 'tdmflguno', largeur: 90},
            {nom: 'tdmflgintdem', largeur: 105},
            {nom: 'tdmflgintref', largeur: 95},
            {nom: 'tdmflgesc1', largeur: 85},
            {nom: 'tdmflgesc2', largeur: 85},
            {nom: 'tdmflgesc3', largeur: 85},
            {nom: 'tdmflgesc4', largeur: 85},
            {nom: 'tdmflgesc5', largeur: 85},
            {nom: 'tdmflgdal', largeur: 76},
            {nom: 'tdmflgparemb', largeur: 200},
            {nom: 'tpfdsc', largeur: 262},
            {nom: 'vaetdmgenfep_dsc', largeur: 178},
            {nom: 'tdmflgdetjrn', largeur: 91},
            {nom: 'tdmflgpaicnv', largeur: 91},
            {nom: 'atscod', largeur: 110},
            {nom: 'atsdsc', largeur: 262},
            {nom: 'tdmflgact', largeur: 65}
        ]
    };
}
