import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageSp0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Sp0006Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSp0006 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'coocod', largeur: 100 },
            { nom: 'coodsc', largeur: 300 },
            { nom: 'cooflgrlr', largeur: 120 },
            { nom: 'cooflgact', largeur: 120 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('coocod'),
            new FormulaireItem('coodsc'),
            new FormulaireItem('cooflgrlr'),
            new FormulaireItem('cooflgact', {default: 1})
        ], {largeurDefaut: 25})
    };
}
