import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0093 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0093Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAc0093 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'egocod', largeur: 65},
            {nom: 'egodsc', largeur: 210},
            {nom: 'egoflgact', largeur: 65},
            {nom: 'vaeegoflgtyp', largeur: 131},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 233}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('egocod', {required: true}),
            new FormulaireItem('egodsc', {required: true}),
            new FormulaireItem('egoflgact', {default: 1}),
            new FormulaireItem('vaeegoflgtyp'),
            new FormulaireItem('ttccleint')
        ], {largeurDefaut: 33.33}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
