import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0300 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCDIOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function mr0300Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0300 = this;

    vm.multiOccurrenceCDIOptions = {
        bloc: 'cdi',
        colonnesVisibles: [
            {nom: 'vaertomodcaldsc', largeur: 200},
            {nom: 'vaecdfrtodsc', largeur: 300},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaertomodcal' ),
            new FormulaireItem('vaecdfrto')
        ], {largeurDefaut: 50} )
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'cdfcod', largeur: 100},
            {nom: 'cdfdsc', largeur: 200}
        ],
        formulaire: new Formulaire('LBL_TITRE_CDI',[
            new FormulaireItem('cdfcod', {largeur: 20}),
            new FormulaireItem('cdfdsc', {largeur: 40})
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCDIOptions
    };
}
