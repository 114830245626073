import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageRC2004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rc2004Controller($stateParams: any) {
    const vm: IPageRC2004 = this;

    vm.multiOccurrenceOptions = {
        filtresParametres: [
            { colonne: 'txmcleint', valeur: $stateParams.txmcleint, visible: false }
        ],
        colonnesCachees: [
            'tydcleint'
        ],
        colonnesVisibles: [
            { nom: 'dailig', largeur: 100 },
            { nom: 'dainumcat', largeur: 140 },
            { nom: 'daidsc', largeur: 190, hidden: (data: any) => data.tydcleint === 146 },
            { nom: 'procod', largeur: 140, hidden: (data: any) => data.tydcleint != 146 },
            { nom: 'prodsc', largeur: 190, hidden: (data: any) => data.tydcleint != 146 },
            { nom: 'qtecom', largeur: 120 },
            { nom: 'daiprxuni', largeur: 140 },
            { nom: 'qterec', largeur: 120 },
            { nom: 'rciqterup', largeur: 120 },
            { nom: 'rciqteruprec', largeur: 120 },
            { nom: 'qtefac', largeur: 120 }
        ]
    };
}
