import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePc5004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pc5004Controller(pc0017Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPagePc5004 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pc0017Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(pc0017Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'pgecod', largeur: 170},
            {nom: 'pyscod', largeur: 100},
            {nom: 'pysnomfra', largeur: 250},
            {nom: 'vacvalcle', largeur: 150},
            {nom: 'vacdsc', largeur: 200},
            {nom: 'colcod', largeur: 130},
            {nom: 'coldsc', largeur: 200}
        ]
    };
}
