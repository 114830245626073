import { IMultiOccurrenceOptions, IMultiOccurrence } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageRr0539 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Rr0539Controller(rr0531Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageRr0539 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        menus: [
            new MenuItemEcran("LBL_BTN_PARAMETRES", rr0531Route.NAME, () => ({}), {
                iconButton: false,
                class: 'ex-button ex-button-secondary',
                onClose: () => {
                    vm.multiOccurrence.fetchDataList();
                },
                disabled: () => !vm.multiOccurrence,
                hidden: !vm.valeursEcran.flgpar
            })
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('t_trnflgstafer', { largeur: 55, default: 1 }),
            new FormulaireItem('t_trnflgstaann', { largeur: 45, default: 1 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_flganc', { largeur: 55, default: vm.valeursEcran.flganc_def, hidden: !vm.valeursEcran.flganc }),
            new FormulaireItem('t_flgrrh', { largeur: 45, default: vm.valeursEcran.flgrrh_def, hidden: !vm.valeursEcran.flgrrh }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_flgcom', { largeur: 55, default: vm.valeursEcran.flgcom_def, hidden: !vm.valeursEcran.flgcom }),
            new FormulaireItem('t_flgsal', { largeur: 45, default: vm.valeursEcran.flgsal_def, hidden: !vm.valeursEcran.flgsal }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_datdeb', { largeur: 55 }),
            new FormulaireItem('urgcleint', { largeur: 45 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_datfin', { largeur: 55 }),
            new FormulaireItem('demcleint', { largeur: 45 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('t_flghie', { largeur: 55, default: 1 }),
            new FormulaireItem('unocleint', { largeur: 45 })
        ], { largeurDefaut: 100 }),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 110 },
            { nom: 'eplnomprn', largeur: 210 }
        ],
        colonnesVisibles: [
            { nom: 'stadsc', largeur: 125 },
            { nom: 'intcod', largeur: 125 },
            { nom: 'intnom', largeur: 125 },
            { nom: 'eatnumdoc', largeur: 125 },
            { nom: 'eatdatdeb', largeur: 105 },
            { nom: 'eatdatfin', largeur: 105 },
            { nom: 'ecldsceta', largeur: 155 },
            { nom: 'urgcod', largeur: 155 },
            { nom: 'urgdsc', largeur: 225 },
            { nom: 'strcod', largeur: 155 },
            { nom: 'strdsc', largeur: 205 },
            { nom: 'lemcod', largeur: 105 },
            { nom: 'lemdatdeb', largeur: 105 },
            { nom: 'lemdatfin', largeur: 105 },
            { nom: 'eattleflgprideb', largeur: 105 },
            { nom: 'eattleflgprifin', largeur: 105 },
            { nom: 'unocod', largeur: 125 },
            { nom: 'unodscabr', largeur: 205 },
            { nom: 'temcod', largeur: 125 },
            { nom: 'temdscabr', largeur: 205 },
            { nom: 'eatdocori', largeur: 125 },
            { nom: 'eatdocubr', largeur: 145 },
            { nom: 'posnumdoc', largeur: 125 },
            { nom: 'eattypdoc', largeur: 145 },
            { nom: 'esvcod1', largeur: 200, hidden: !vm.valeursEcran.flgesvcod1 },
            { nom: 'esvcod2', largeur: 200, hidden: !vm.valeursEcran.flgesvcod2 },
            { nom: 'esvcod3', largeur: 200, hidden: !vm.valeursEcran.flgesvcod3 },
            { nom: 'esvcod4', largeur: 200, hidden: !vm.valeursEcran.flgesvcod4 },
            { nom: 'esvcod5', largeur: 200, hidden: !vm.valeursEcran.flgesvcod5 },
            { nom: 'eplnumref1', largeur: 180, hidden: !vm.valeursEcran.flgeplnumref1 },
            { nom: 'eplnumref2', largeur: 180, hidden: !vm.valeursEcran.flgeplnumref2 },
            { nom: 'eplnumref3', largeur: 180, hidden: !vm.valeursEcran.flgeplnumref3 },
            { nom: 'eplnumref4', largeur: 180, hidden: !vm.valeursEcran.flgeplnumref4 },
            { nom: 'aftcod', largeur: 125 },
            { nom: 'aftdatdeb', largeur: 105 },
            { nom: 'aftdatfin', largeur: 105 },
            { nom: 'eatflgposvac', largeur: 105 },
            { nom: 'eatflgpossantit', largeur: 105 },
            { nom: 'eataftflgtagautdeb', largeur: 105 },
            { nom: 'eataftflgtagautfin', largeur: 105 },
            { nom: 'eataftflgprideb', largeur: 105 },
            { nom: 'eataftflgprifin', largeur: 105 },
            { nom: 'eatcom', largeur: 155 },
            { nom: 'eatbqesld', largeur: 145 },
            { nom: 'bqeafflib', largeur: 125 },
            { nom: 'eatrrhqtedeb', largeur: 105 },
            { nom: 'eatrrhqtefin', largeur: 105 },
            { nom: 'eatgeccoddeb', largeur: 105 },
            { nom: 'eatgeccodfin', largeur: 105 },
            { nom: 'eatechcoddeb', largeur: 105 },
            { nom: 'eatechcodfin', largeur: 105 },
            { nom: 'eatsaldeb', largeur: 105 },
            { nom: 'eatsalfin', largeur: 105 },
            { nom: 'eattsdsaldeb', largeur: 105 },
            { nom: 'eattsdsalfin', largeur: 105 },
            { nom: 'eatetcdeb', largeur: 105 },
            { nom: 'eatetcfin', largeur: 105 },
            { nom: 'eatetccaldeb', largeur: 105 },
            { nom: 'eatetccalfin', largeur: 105 }
        ]
    };
}
