import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageIf0008 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    multiOccurrence: IMultiOccurrence;
    formulaireEci: IFormulaire;
    formulaireInfoCalcul: IFormulaire;
    eivMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function If0008Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         if0004Route: IRoute,
                                         ecranDetails: IEcranDetailsResourcesEntite,) {

    const vm: IPageIf0008 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            cycleErrorReloadBlocs: ['eiv'],
            infosMonetaires: {
                blocs: ['eiv'],
                donnees:
                    [
                        {nom: 'dbt', libelle: 'G_LBL_DBT'},
                        {nom: 'crt', libelle: 'G_LBL_CRT', lastOfGroup: true},
                        {nom: 'ecart', libelle: 'G_LBL_ECART', expectedValue: 0}
                    ]
            },
            infosFinancieres: {
                disponibilite: true,
                impactFinancier: true,
                impactBudgetaire: true
            },
            menus: [
                new MenuItemAction(
                    'LBL_IMPRESSION',
                    'BOUIMP',
                    () => ({}),
                    {
                        icon: 'print',
                        iconButton: false
                    }
                )
            ]
        };

        // Formulaire principal (Écritures d'immobilisation)
        vm.formulaireEci = new Formulaire('LBL_BLC_ECI', [
            new FormulaireItem('ecinumdoc'),
            new FormulaireItem('ecinumdocseq'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('vececicat'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecidat'),
            new FormulaireItem('peccleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintreq'),
            new FormulaireItem('ecidsc', {required: true, enableCount: true}),
            new FormulaireItem('eciflgautrep', {default: 1}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('immcleint', { menu: [
                new MenuItemForage(
                    if0004Route.NAME,
                    (data: any) => ({
                        id: data.immcleint
                    })
                )
            ] }),
            new FormulaireItem('aut__catcoddsc'),
            new FormulaireItem('eciflgpij', {default: ecranDetails.flgcen ? 0 : 1})
        ], {
            largeurDefaut: 33.33
        });

        // Info. calcul
        vm.formulaireInfoCalcul = new Formulaire('LBL_BLC_INFO_CALCUL', [
            new FormulaireItem('eciqtecalacj', {largeur: 50}),
            new FormulaireItem('ecimntamoacj', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecimntiniacj', {largeur: 50}),
            new FormulaireItem('eciqtetotacj', {largeur: 50}),
            new FormulaireItem('ecivalresacj', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ecinbrperacj', {largeur: 50}),
            new FormulaireItem('ecitaucalacj', {largeur: 50})
        ]);

        // Ventilation
        vm.eivMultiOccurrenceOptions = {
            fonctions: {
                editionRapide: true
            },
            colonnesFixesGauche: [
                {nom: 'peccod', largeur: 100},
                {nom: 'vaesysimmtyptrs_dsc', largeur: 170}
            ],
            colonnesVisibles: [
                {nom: 'imp', largeur: 289},
                {nom: 'eivmntdbt', largeur: 150},
                {nom: 'eivmntcrt', largeur: 150},
                {nom: 'eivdsc', largeur: 300}
            ],
            colonnesFixesDroite: [
                {nom: 'eivflgmsg', largeur: 100}
            ],
            colonnesEditionRapide: [
                {nom: 'peccleintori', largeur: 280},
                {nom: 'vaesysimmtyptrs', largeur: 250},
                {nom: 'imp', largeur: 375},
                {nom: 'eivmntdbt', largeur: 150},
                {nom: 'eivmntcrt', largeur: 150},
                {nom: 'eivdsc', largeur: 300}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('peccleintori', {largeur: 25}),
                new FormulaireItem('vaesysimmtyptrs', {largeur: 25}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('imp', {largeur: 40}),
                new FormulaireItem('eivmntdbt', {largeur: 25}),
                new FormulaireItem('eivmntcrt', {largeur: 25}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eivdsc', {largeur: 40})
            ]),
            colonnesCachees: [
                'eiverrmsg',
                'prucleint',
                'prscleint',
                'cifcleint',
                'unacleint',
                'cptcleint',
                'el1cleint',
                'el2cleint',
                'el3cleint',
                // Pour le flag de message en édition rapide
                'aut__eivflgmsg'
            ]
        };
    }
}
