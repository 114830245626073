import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0070 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0070Controller(gs0071Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass) {
    const vm: IPageGS0070 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0071Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0071Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'vaegaltyp_dsc', largeur: 120},
            {nom: 'galseqaff', largeur: 125},
            {nom: 'galsuj', largeur: 470},
            {nom: 'galtxt', largeur: 470}
        ]
    };
}
