import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenu, IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
interface IPageMc0052 extends IComponentController {
    menuBandeau: IMenu;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    mdvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function Mc0052Controller(Menu: IMenuClass, ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageMc0052 = this;
        vm.menuBandeau = new Menu([]);
        vm.valeursEcran = ecranDetails.valeurs;

        vm.mdvMultiOccurrenceOptions = {
            bloc: 'mdv',
            fonctions: {
                suiviModification: true,
                exportation: true
            },
            colonnesVisibles: [
                { nom: 'vcrimp', largeur: 289 },
                { nom: 'prudscl01', largeur: 192 },
                { nom: 'cptdsc', largeur: 192 },
                { nom: 'mdvmnt', largeur: 126, hidden: () => !vm.valeursEcran.flgmnt },
                { nom: 'mdvmntdbt', largeur: 139, hidden: () => !vm.valeursEcran.flgdbt },
                { nom: 'mdvmntcrt', largeur: 139, hidden: () => !vm.valeursEcran.flgdbt },
                { nom: 'envmnteng', largeur: 139, hidden: () => !vm.valeursEcran.flgeng }
            ]
        };
}
