import { IComponentController, IFormController } from 'angular';
import { IMenuClass, MenuElement } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { FormulaireElement, IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForage, IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireGroupeConditionnelClass } from '../../../../../core/services/formulaire/formulaire-groupe-conditionnel.service';
import { IFormulaireTemplateSlotClass } from '../../../../../core/services/formulaire/formulaire-template-slot.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IData } from '../../../../../core/services/data-linker.service';
import { IIsForageValideService } from "../../../../../core/services/is-forage-valide.service";
import { IFormulaireItemMenuItemClass } from "../../../../../core/services/formulaire/formulaire-item-menu-item.service";
import { IStateService } from "angular-ui-router";
import { IFilterDate } from 'angular';

interface IPageRr0006 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    dataLsaoff: any;
    sectionRatioVisible: boolean;
    formCtrl: IFormController;
    formulaireAft: IFormulaire;
    formulaireLsa: IFormulaire;
    formulaireLsaOff: IFormulaire;
    formulaireRem: IFormulaire;
    formulaireAss: IFormulaire;
    formulaireAssMul: IFormulaire;
    menuItemClassification: IMenuItem;
    menusTsm: { [col: string]: Array<FormulaireElement> };
    tseMenus: Array<IMenuItemForage>;
    menusEmp: Array<MenuElement>;
    menusLem: Array<MenuElement>;
    menuLsadsc: Array<MenuElement>;
    cpeMenus: Array<IMenuItemForage>;
    etuMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dasMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ovdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    susMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aftSusMultiOccurrenceOptions: IMultiOccurrenceOptions;
    afpMultiOccurrenceOptions: IMultiOccurrenceOptions;
    hhsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    aprMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IRr0006Params {
    id: string;
    trncleint: string;
    tsecleint: string;
    menuId: string;
}

/* @ngInject */
export default function Rr0006Controller(rr0002Route: IRoute,
    rr0003Route: IRoute,
    rr0004Route: IRoute,
    rr0005Route: IRoute,
    rr0006Route: IRoute,
    rr0022Route: IRoute,
    rr0023Route: IRoute,
    rr0024Route: IRoute,
    rr0108Route: IRoute,
    mr0176Route: IRoute,
    rr0071Route: IRoute,
    rr0073Route: IRoute,
    rr0155Route: IRoute,
    so0007Route: IRoute,
    as0008Route: IRoute,
    as0023Route: IRoute,
    mr0205Route: IRoute,
    mr0208Route: IRoute,
    mr0212Route: IRoute,
    mr0213Route: IRoute,
    $state: IStateService,
    $stateParams: IRr0006Params,
    dateFilter: IFilterDate,
    exDateFilter: IFilterExDate,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    Pagination: IPaginationClass,
    Menu: IMenuClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    isForageValide: IIsForageValideService) {
    const vm: IPageRr0006 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.dataLsaoff = { ...vm.valeursEcran };
        vm.sectionRatioVisible = (vm.valeursEcran.ratio || vm.valeursEcran.ratmin || vm.valeursEcran.ratmil || vm.valeursEcran.ratmax);

        vm.monoOccurrenceOptions = {
            fonctions: {
                refreshForageIdWithCycle: true,
                boutonDupliquer: false
            }
        };

        vm.tseMenus = [
            new MenuItemForage(
                'LBL_TSE_AFT',
                rr0006Route.NAME,
                () => {
                    if (vm.valeursEcran.lemcleint) {
                        return {
                            id: $stateParams.id
                        };
                    } else {
                        return {
                            trncleint: $stateParams.trncleint
                        };
                    }
                },
                {
                    courant: true,
                    icon: 'edit_location',
                    iconButton: false,
                    hidden: () => (
                        vm.valeursEcran.tytcleint === 4 ||
                        vm.valeursEcran.tytcleint === 73
                    )
                }
            ),
            new MenuItemForage(
                'LBL_TSE_PROBATION',
                rr0155Route.NAME,
                () => ({
                    id: vm.valeursEcran.tsecleint
                }),
                {
                    icon: vm.valeursEcran.tprcleint ? 'create' : 'add_circle',
                    iconButton: false,
                    disabled: (data: any) => !vm.valeursEcran.tseflgprb && vm.valeursEcran.flggespro != 2,
                    hidden: vm.valeursEcran.flggespro === 3
                }
            )
        ];

        vm.cpeMenus = [
            new MenuItemForage(
                'LBL_VENT',
                mr0205Route.NAME,
                () => ({
                    id: vm.monoOccurrence.data.aftcleint,
                    stamnetab: "AFT"
                }),
                {
                    icon: 'subtitles',
                    iconButton: false,
                    disabled: () => !vm.monoOccurrence.data.bouven || !vm.valeursEcran.flgbouven,
                    secondaire: true
                }
            ),

            new MenuItemForage(
                'G_LBL_IMPACT_BUD',
                mr0208Route.NAME,
                (data) => ({
                    id: vm.valeursEcran.demcleint,
                    doccleintgrh: data.doccleint_aft,
                    typtri: 1
                }),
                {
                    icon: 'equalizer',
                    iconButton: false,
                    hidden: !vm.valeursEcran.flgimpbud,
                    secondaire: true
                }
            ),
            new MenuItemForage(
                'LBL_CPE',
                rr0108Route.NAME,
                (data) => ({
                    cpecleintref: vm.valeursEcran.aftcleint,
                    stamnetab: 'AFT',
                    trncleint: data.trncleint
                }),
                {
                    icon: 'subtitles',
                    fonction: 'BOUPAREMB',
                    iconButton: false,
                    secondaire: true,
                    disabled: !vm.valeursEcran.aftcleint
                }
            ),
            new MenuItemForage(
                'LBL_DER',
                rr0073Route.NAME,
                () => ({
                    demcleint: vm.valeursEcran.demcleint,
                    aftcleint: vm.valeursEcran.aftcleint,
                    efface_criteres: true
                }),
                {
                    icon: 'view_list',
                    iconButton: false,
                    compteur: (data: any) => (vm.monoOccurrence.data.nbrremun > 0 && vm.valeursEcran.flgaffder) ? vm.monoOccurrence.data.nbrremun : undefined,
                    fonction: 'BOUDER',
                    secondaire: true,
                    hidden: !vm.valeursEcran.flgbouder,
                    disabled: !vm.valeursEcran.aftcleint || !vm.valeursEcran.flgaffder
                }
            )
        ];

        vm.menus = {
            ttcdscnumdoc: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    rr0002Route.NAME,
                    () => ({
                        id: $stateParams.id
                    }),
                    {
                        fonction: 'BOUREF',
                        hidden: !vm.valeursEcran.ttcdscnumdoc
                    }
                )
            ],

            numdocref: [
                new MenuItemForage(
                    'BTN_FORAGE_',
                    `secure.${vm.valeursEcran.ecrcod}.ecran`,
                    () => {
                        if (vm.valeursEcran.vaetseori === 'IFT') {
                            return {
                                fatcleint: vm.valeursEcran.fatcleint,
                                fdacleint: vm.valeursEcran.fdacleint
                            };
                        } else if (vm.valeursEcran.vaetseori === 'AFT') {
                            return { aftcleint: vm.valeursEcran.aftcleintfor };
                        } else {
                            return { id: vm.valeursEcran.trncleintref };
                        }
                    },
                    { hidden: (data: any) => !data.numdocref || (vm.valeursEcran.ecrcod && !isForageValide.isForageValide('rr0006', vm.valeursEcran.ecrcod)) },
                )
            ]
        };

        vm.menusEmp = [
            new MenuItemForage(
                rr0003Route.NAME,
                () => ({
                    employe: vm.valeursEcran.demcleint
                }),
                {
                    fonction: 'BOUEPL'
                }
            )
        ]
        vm.menusLem = [
            new MenuItemForage(
                rr0004Route.NAME,
                () => ({
                    id: vm.valeursEcran.trncleint_lem
                }),
                {
                    fonction: 'BOULEM'
                }
            )
        ]

        vm.formulaireAft = new Formulaire('LBL_AFFECTATION', [
            new FormulaireItem('demcleint', { default: vm.valeursEcran.demcleint }),
            new FormulaireItem('trncleint', { default: $stateParams.trncleint }),
            new FormulaireItem('tsecleint', { default: vm.valeursEcran.tsecleint }),
            new FormulaireItem('lemcleint', { default: vm.valeursEcran.lemcleint }),
            new FormulaireItem('urgcleint', { default: vm.valeursEcran.urgcleint }),
            new FormulaireItem('strcleint', { default: vm.valeursEcran.strcleint }),
            new FormulaireItem('aftcod'),
            new FormulaireItem('aftdsc', { largeur: 66.66 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('aftdatdeb', {
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '00-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 16
                        }),
                        {
                            icon: 'create',
                            fonction: 'BOUMAJDATDEB',
                            hidden: (data: any) => (!data.aftdatdeb || validateEtat(2, vm.valeursEcran))
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 16
                        }),
                        {
                            icon: 'ballot',
                            fonction: 'BOUHISDATDEB',
                            hidden: (data: any) => (!data.aftdatdeb || validateEtat(1, vm.valeursEcran))
                        }
                    )
                ]
            }),
            new FormulaireItem('aftdatfin', {
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '00-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 17
                        }),
                        {
                            icon: 'create',
                            fonction: 'BOUMAJDATFIN',
                            hidden: validateEtat(2, vm.valeursEcran)
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 17
                        }),
                        {
                            icon: 'ballot',
                            fonction: 'BOUHISDATFIN',
                            hidden: validateEtat(1, vm.valeursEcran)
                        }
                    )
                ]
            }),
            new FormulaireItem('aftflgpro'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tafcleint', {
                largeur: 66.66,
                resourceParams: (data: any) => {
                    return {
                        urgcleint: data.urgcleint,
                        strcleint: data.strcleint
                    };
                },
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '00-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 15
                        }),
                        {
                            icon: 'create',
                            fonction: 'BOUMAJTAF',
                            hidden: validateEtat(2, vm.valeursEcran)
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 15
                        }),
                        {
                            icon: 'ballot',
                            fonction: 'BOUHISTAF',
                            hidden: (data: any) => (!data.tafcleint || validateEtat(1, vm.valeursEcran))
                        }
                    )
                ]
            }),
            new FormulaireItem('aftflgpri', {
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '00-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 28
                        }),
                        {
                            icon: 'create',
                            fonction: 'BOUMAJPRI',
                            hidden: validateEtat(2, vm.valeursEcran)
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 28
                        }),
                        {
                            icon: 'ballot',
                            fonction: 'BOUHISPRI',
                            hidden: validateEtat(1, vm.valeursEcran)
                        }
                    )
                ]
            }),
            new FormulaireItem('temcleint', {
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '02-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 74
                        }),
                        {
                            icon: 'create',
                            fonction: 'BOUMAJTEM',
                            hidden: (data: any) => (!data.temcleint || validateEtat(2, vm.valeursEcran))
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 74
                        }),
                        {
                            icon: 'ballot',
                            fonction: 'BOUHISTEM',
                            hidden: (data: any) => (!data.temcleint || validateEtat(1, vm.valeursEcran))
                        }
                    )
                ],
                largeur: 66.66,
                resourceParams: (data: any) => {
                    return { aftcleint: data.aftcleint }
                }
            }),
            new FormulaireItem('temhreheb', { titre: ' ' }),
            new FormulaireItem('poscleint', {
                resourceParams: (data: any) => {
                    return {
                        aftdatdeb: data.aftdatdeb,
                        aftdatfin: data.aftdatfin
                    };
                },
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_POS',
                        so0007Route.NAME,
                        (data: any) => ({
                            id: vm.valeursEcran.trncleint_pos, demcleint: data.demcleint
                        }),
                        {
                            fonction: 'BOUPOS',
                            hidden: (data: any) => (!data.poscleint || !data.aftcleint)
                        }
                    )
                ],
                largeur: 66.66
            }),
            new FormulaireItem('aftflgttu'),
            new FormulaireItem('aftflgfincon', {
                default: 0
            }),
            new FormulaireItemDivider('LBL_HERITE_POSTE'),
            new FormulaireItem('unocoddscrh', {
                largeur: 60
            }),
            new FormulaireItem('unocoddscvac', {
                largeur: 40
            }),
            new FormulaireItem('dscpos', {
                titre: " ",
                largeur: 100
            }),
            new FormulaireItem('superieur', {
                readonly: true,
                largeur: 60
            }),
            new FormulaireItem('litcoddsc', {
                largeur: 40
            }),
            new FormulaireGroupeConditionnel('saisir_diff', '', new Formulaire([
                new FormulaireItem('unocleintrh', {
                    resourceParams: (data: any) => {
                        return {
                            aftdatdeb: data.aftdatdeb,
                            aftdatfin: data.aftdatfin
                        };
                    },
                    historiqueColonne: true,
                    reloadData: true,
                    required: true
                }),
                new FormulaireItem('unocleintvac'),
                new FormulaireItem('litcleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('poscleintsup'),
                new FormulaireItem('eplnomsupfmt', { titre: ' ', readonly: true })
            ]), {
                default: (data: any) => (data.unocleintrh || data.unocleintvac || data.poscleintsup || data.litcleint), switch: true
            }),
            new FormulaireGroupeAccordeon('LBL_AUTRES_INFOS', new Formulaire([
                new FormulaireItemDivider('LBL_SUITE_AFT'),
                new FormulaireItem('aftpostitfer', { readonly: true }),
                new FormulaireItem('vacmotcre'),
                new FormulaireItem('aftcom', {
                    largeur: 100
                }),
                new FormulaireItemDivider('LBL_SUITE_SUP'),
                new FormulaireItem('intcleintsup', { largeur: 60,
                    resourceParams: (data: any) => {
                        return {
                            aftdatdeb: data.aftdatdeb,
                            aftdatfin: data.aftdatfin
                        };
                    },
                    historiqueColonne: vm.valeursEcran.flghistsup
                }),
                new FormulaireGroupeConditionnel('renouvel', '', new Formulaire([
                    new FormulaireItem('aftdatdebadm'),
                    new FormulaireItem('aftdatfinadm')
                ]), {
                    default: (data: any) => data.aftdatdebadm || data.aftdatfinadm, switch: true
                })
            ], {
                largeurDefaut: 50
            }))
        ], {
            largeurDefaut: 33.33
        });

        // Salaire //
        vm.formulaireLsa = new Formulaire('LBL_LSA', [
            new FormulaireItem('lsacleint', {
                largeur: 100,
                resourceParams: (data: any) => {
                    return {
                        aftcleint: data.aftcleint,
                        lemcleint: data.lemcleint,
                        temcleint: data.temcleint,
                        tafcleint: data.tafcleint,
                        urgcleint: data.urgcleint,
                        strcleint: data.strcleint,
                        aftdatdeb: data.aftdatdeb
                    };
                },
                menu: [
                    new MenuItemForage(
                        'BTN_FORAGE_LSA',
                        (vm.valeursEcran.nbrlsa > 1) ?
                            rr0005Route.NAME :
                            rr0022Route.NAME,
                        () => {
                            if (vm.valeursEcran.nbrlsa > 1) {
                                return {
                                    id: vm.valeursEcran.lsacleint
                                };
                            } else {
                                return {
                                    id: vm.valeursEcran.trncleinttsa
                                };
                            }
                        },
                        { hidden: !vm.valeursEcran.lsacleint }
                    )
                ]
            }),
            new FormulaireTemplateSlot('boutonClassification')
        ]);

        vm.menuLsadsc = [
            new MenuItemForage(
                'BTN_FORAGE_LSA',
                (vm.valeursEcran.nbrlsa > 1) ?
                    rr0005Route.NAME :
                    rr0022Route.NAME,
                (data: any) => {
                    if (vm.valeursEcran.nbrlsa > 1) {
                        return {
                            id: vm.valeursEcran.lsacleint
                        };
                    } else {
                        return {
                            id: vm.valeursEcran.trncleinttsa
                        };
                    }
                },
                {
                    icon: 'exit_to_app',
                    iconButton: true,
                    fonction: 'BOUREMADD',
                    hidden: !vm.valeursEcran.lsacleint
                }
            )
        ];

        // Détail primes suppléments 
        vm.aprMultiOccurrenceOptions = {
            bloc: 'apr',
            fonctions: {
                effaceCriteres: true
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('datref', {
                    largeur: 100,
                    default: dateFilter(new Date(), 'yyyy-MM-dd')
                })
            ]),
            colonnesVisibles: [
                { nom: 'tagcod', largeur: 120 },
                { nom: 'tagdsc', largeur: 180 },
                { nom: 'vaeaprprv_dsc', largeur: 160 },
                { nom: 'aprmnt', largeur: 160, total: true },
                { nom: 'aprmntprctra', largeur: 160, total: true }
            ]
        };

        // Salaire lorsque transaction officiel //
        vm.formulaireLsaOff = new Formulaire('LBL_LSA_OFF', [
            new FormulaireTemplateSlot('autresInfosLsaOff'),
            new FormulaireItemDivider(),
            new FormulaireItem('t_datref', {
                default: exDateFilter(vm.valeursEcran.t_datref),
                securityCycle: false,
                menu: [
                    new MenuItemAction(
                        'LBL_CALCUL_SALAIRE',
                        'CALCUL',
                        () => ({
                            echelon: vm.valeursEcran.echelon,
                            uncdsc: vm.valeursEcran.uncdsc,
                            salclas: vm.valeursEcran.salclas,
                            saltot: vm.valeursEcran.saltot,
                            suppsal: vm.valeursEcran.suppsal,
                            primeaft: vm.valeursEcran.primeaft,
                            primepos: vm.valeursEcran.primepos,
                            avasoc: vm.valeursEcran.avasoc,
                            totsel: vm.valeursEcran.totsel,
                            salhor: vm.valeursEcran.salhor,
                            saljrs: vm.valeursEcran.saljrs,
                            salheb: vm.valeursEcran.salheb,
                            salann: vm.valeursEcran.salann,
                            tmptra: vm.valeursEcran.tmptra,
                            ratio: vm.valeursEcran.ratio,
                            ratmar: vm.valeursEcran.ratmar,
                            ratmin: vm.valeursEcran.ratmin,
                            ratmil: vm.valeursEcran.ratmil,
                            ratmax: vm.valeursEcran.ratmax
                        }),
                        {
                            icon: 'refresh',
                            hidden: !vm.valeursEcran.flgcladatdu,
                            champsRechargement: [
                                'avasoc',
                                't_datref',
                                'echelon',
                                'primeaft',
                                'primepos',
                                'ratio',
                                'ratmar',
                                'ratmax',
                                'ratmil',
                                'ratmin',
                                'salclas',
                                'salann',
                                'salheb',
                                'salhor',
                                'saljrs',
                                'saltot',
                                'suppsal',
                                'tmptra',
                                'totsel',
                                'uncdsc'
                            ]
                        }
                    )
                ]
            })
        ]);

        // Rémun auto //
        vm.formulaireRem = new Formulaire('LBL_REMUN_AUTO', [
            new FormulaireItem('aftflgtagaut', {
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '00-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 38
                        }),
                        {
                            icon: 'create',
                            fonction: 'BOUMAJAUT',
                            hidden: validateEtat(2, vm.valeursEcran)
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 38
                        }),
                        {
                            icon: 'ballot',
                            fonction: 'BOUHISAUT',
                            hidden: validateEtat(1, vm.valeursEcran)
                        }
                    )
                ]
            }),
            new FormulaireItem('aftdecaut')
        ], {
            largeurDefaut: 33.33
        });

        // Assiduité //
        vm.formulaireAss = new Formulaire('LBL_ASSIDUITE', [
            new FormulaireItem('vaesyscaptem', {
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '00-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 50
                        }),
                        {
                            icon: 'create',
                            hidden: (data: any) => validateEtat(2, vm.valeursEcran) || !vm.valeursEcran.flgmajcap
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 50
                        }),
                        {
                            icon: 'ballot',
                            hidden: (data: any) => validateEtat(1, vm.valeursEcran) || !vm.valeursEcran.flghistcap
                        }
                    )
                ]
            }),
            new FormulaireItem('aftdecftp'),
            new FormulaireItemDivider('LBL_HERITE_POSTE'),
            new FormulaireItem('asgcoddsc_pos', { readonly: true }),
            new FormulaireItem('ahtcoddsc_pos', { readonly: true }),
            new FormulaireGroupeConditionnel('horaire', '', new Formulaire([
                new FormulaireItem('asgcleint', {
                    resourceParams: (data: any) => ({
                        urgcleint: data.urgcleint,
                        strcleint: data.strcleint,
                        temcleint: data.temcleint
                    }),
                    onValueChange: data => {
                        data.ahtcleint = null
                        data.ahtcleint__ahtcod = null
                        data.ahtcleint__ahtdsc = null
                        data.ahtcleint__flgahtmanaff = null
                        data.ahtcleint__ahthresem = null
                        data.ahtcleint__vaeahtman = null
                    }
                }),
                new FormulaireItem('ahtcleint', {
                    resourceParams: () => ({
                        aftcleint: vm.monoOccurrence.data.aftcleint,
                        ahtcleint: vm.monoOccurrence.data.ahtcleint,
                        hafcleint: vm.monoOccurrence.data.hafcleint,
                        aftdatdeb: vm.monoOccurrence.data.aftdatdeb,
                        trncleint: $stateParams.trncleint
                    })
                }),
                new FormulaireItem('aeqcleint'),
                new FormulaireItemMenuItem(new MenuItemAction(
                    'LBL_BTN_AHT_REM',
                    'BOUAHTREM',
                    () => ({}),
                    {
                        iconButton: false,
                        saveFirst: true,
                        class: 'ex-button-action ex-accent-hue-1-bg ex-accent-hue-2-bg--hover md-raised',
                        successAction: (data: any) => {
                            if (!data.msg) {
                                $state.go(as0008Route.NAME, {
                                    menuId: $stateParams.menuId,
                                    id: data.ahtcleinto,
                                    asgcleint: vm.monoOccurrence.data.asgcleint,
                                    flginfor: 0,
                                    demcleint: vm.monoOccurrence.data.demcleint
                                });
                            }
                        },
                        fonction: 'BOUAHTREM',
                        hidden: (data: any) => {
                            return !data.ahtcleint || !data.vaeahtman || data.vaeahtman === "NON"
                        },
                    }
                )),
                new FormulaireItem('hhsdatdeb', {
                    largeur: 33.33,
                    default: (data: any) => data.aftdatdeb
                }),
                new FormulaireItem('hhsdatfin', { largeur: 33.33 }),
                new FormulaireItem('hhsetc', { largeur: 33.33 }),
                new FormulaireItem('hhsdsc', { largeur: 100 })
            ]), {
                default: (data: any) => Boolean(data.asgcleint || data.ahtcleint || data.aeqcleint || data.etpcleint || data.hhsdatdeb || data.hhsdatfin || data.hhsetc || data.hhsdsc),
                switch: true
            })
        ]);

        // Assiduité multi //
        vm.formulaireAssMul = new Formulaire('LBL_ASSIDUITE', [
            new FormulaireItem('vaesyscaptem', {
                menu: [
                    new MenuItemForage(
                        rr0024Route.NAME,
                        (data: any) => ({
                            ecran: '00-2',
                            aftcleint: data.aftcleint,
                            tytcleint: 50
                        }),
                        {
                            icon: 'create',
                            hidden: (data: any) => validateEtat(2, vm.valeursEcran) || !vm.valeursEcran.flgmajcap
                        }
                    ),
                    new MenuItemForage(
                        rr0023Route.NAME,
                        (data: any) => ({
                            ecran: '07-2',
                            demcleint: data.demcleint,
                            cleintref: data.aftcleint,
                            tytcleint: 50
                        }),
                        {
                            icon: 'ballot',
                            hidden: (data: any) => validateEtat(1, vm.valeursEcran) || !vm.valeursEcran.flghistcap
                        }
                    )
                ]
            }),
            new FormulaireItem('aftdecftp'),
            new FormulaireTemplateSlot('rattachementHoraire')
        ]);

        // Statut étudiant //
        vm.etuMultiOccurrenceOptions = {
            resourceParams: (data: any) => {
                return {
                    aftcleint: data.aftcleint,
                    demcleint: data.demcleint,
                    aftdatdeb: data.aftdatdeb,
                    aftdatfin: data.aftdatfin
                };
            },
            bloc: 'etu',
            fonctions: {
                pagination: true,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'trimestre', largeur: 300 },
                { nom: 'etuflgtmpcom', largeur: 126 },
                { nom: 'unocoddsc', largeur: 126 },
                { nom: 'trmdatdeb', largeur: 126 },
                { nom: 'trmdatfin', largeur: 126 },
                { nom: 'tvacom', largeur: 126 },
                { nom: 'tvaflgignvalint', largeur: 126 },
                { nom: 'tvaflgignvalimp', largeur: 126 }
            ]
        };

        // Bouton forage Classification
        vm.menuItemClassification = new MenuItemForage(
            'LBL_CLASSIFICATION',
            rr0022Route.NAME,
            () => ({
                tsecleint: vm.monoOccurrence.data.tsecleint,
                flgrr0005: 0
            }),
            {
                icon: 'add_circle',
                iconButton: false,
                saveFirst: true,
                hidden: (data: any) => data.lsacleint
            }
        );

        vm.menusTsm = {
            nbrtsm: [
                new MenuItemForage(
                    rr0023Route.NAME,
                    (data: any) => ({
                        ecran: '02-2',
                        demcleint: data.demcleint,
                        cleintref: data.aftcleint
                    }),
                    {
                        fonction: 'BOUTM'
                    }
                )
            ]
        };

        ///////////////////
        // Imputation - Affectation
        ///////////////////
        vm.ovdMultiOccurrenceOptions = {
            resourceParams: {
                ovdcleintref: vm.valeursEcran.aftcleint,
                stamnetab: 'AFT'
            },
            actionsMore: new Menu([
                new MenuItemForage(
                    'G_LBL_IMPACT_BUD',
                    mr0208Route.NAME,
                    () => ({
                        id: vm.valeursEcran.demcleint,
                        doccleintgrh: vm.monoOccurrence.data.doccleint_aft,
                        typtri: 1
                    }),
                    {
                        icon: 'equalizer',
                        iconButton: false,
                        fonction: 'BOUOUV'
                    }
                ),
                new MenuItemForage(
                    'G_LBL_ENGAGEMENT',
                    mr0212Route.NAME,
                    (data) => ({
                        id: vm.valeursEcran.mobcleint_act,
                        mobcleintref: data.aftcleint,
                        stamnetab: "AFT"
                    }),
                    {
                        icon: 'create',
                        iconButton: false,
                        hidden: !vm.valeursEcran.trnflgstafin,
                        fonction: 'BOUCHEN'
                    }
                ),
                new MenuItemForage(
                    'G_LBL_HISTO_ENG',
                    mr0213Route.NAME,
                    (data) => ({
                        mobcleintref: data.aftcleint,
                        stamnetab: "AFT"
                    }),
                    {
                        icon: 'restore',
                        iconButton: false,
                        hidden: !vm.valeursEcran.trnflgstafin
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('BTN_MODIFIER', mr0176Route.NAME, (rowData: any) => {
                    return {
                        id: rowData.ovdcleint,
                        ovdcleintref: vm.valeursEcran.aftcleint,
                        stamnetab: 'AFT'
                    };
                }, {
                    icon: 'launch',
                    fonction: 'BOUOUV',
                    disabled: !vm.valeursEcran.aftcleint
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('G_LBL_BTN_NOUVEAU', mr0176Route.NAME, (rowData: any) => {
                    return {
                        ovdcleintref: vm.valeursEcran.aftcleint,
                        stamnetab: 'AFT'
                    };
                }, {
                    fonction: 'BOUNOUV',
                    disabled: !vm.valeursEcran.aftcleint
                })
            ]),
            bloc: 'ovd',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'ovnimp', largeur: 300 },
                { nom: 'rsccoddsc', largeur: 100 },
                { nom: 'ovddatvig', largeur: 100 },
                { nom: 'ovddatfin', largeur: 100 }
            ],
            colonnesCachees: [
                'ovdcleint'
            ]
        };

        // Choix avantages sociaux - Affectation
        vm.dasMultiOccurrenceOptions = {
            parentId: vm.valeursEcran.aftcleint,
            // Ouverture en modale
            actionsRangeeDroite: new Menu([
                new MenuItemEcran('G_LBL_BTN_MODIFIER', rr0071Route.NAME, (data: any) => {
                    return {
                        id: data.dascleint
                    };
                }, {
                    icon: 'launch',
                    appliquer: true,
                    fonction: 'BOUOUV',
                    disabled: !vm.valeursEcran.aftcleint,
                    onClose() {
                        vm.dasMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemEcran('', rr0071Route.NAME, () => {
                    return {
                        demcleint: vm.monoOccurrence.data.demcleint,
                        aftcleint: vm.monoOccurrence.data.aftcleint,
                        trncleint: vm.monoOccurrence.data.trncleint
                    };
                }, {
                    appliquer: true,
                    fonction: 'BOUNOU',
                    disabled: !vm.valeursEcran.aftcleint,
                    onClose() {
                        vm.dasMultiOccurrence.fetchDataList();
                    }
                })
            ]),
            bloc: 'das',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'avsdsc', largeur: 300 },
                { nom: 'dasdatvig', largeur: 131 },
                { nom: 'avcdsc', largeur: 131 }
            ]
        };

        // Affectation à suspendre
        vm.susMultiOccurrenceOptions = {
            bloc: 'sus',
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'BTN_FORAGE_SUS',
                    (data: any) => getForage(data),
                    (data: any) => {
                        return {
                            id: data.trncleintref
                        }
                    },
                    {
                        fonction: 'BOUAFT'
                    }
                )
            ]),
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                nouveau: (!vm.valeursEcran.aftcleint) ? false : undefined
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O'
                })
            ]),
            formulaire: new Formulaire([
                new FormulaireItem('aftcleintref', {
                    resourceParams: () => {
                        return {
                            demcleint: vm.monoOccurrence.data.demcleint,
                            aftcleint: vm.monoOccurrence.data.aftcleint,
                            aftdatdeb: vm.monoOccurrence.data.aftdatdeb
                        };
                    },
                    largeur: 75
                }),
                new FormulaireItem('susflgact', {
                    largeur: 25,
                    default: 1
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'aftcoddscfmt', largeur: 300 },
                { nom: 'susflgact', largeur: 200 }
            ],
            colonnesCachees: [
                'trncleintref',
                'tytcleint',
                'tsecleintori'
            ]
        };

        // Suspendue par
        vm.aftSusMultiOccurrenceOptions = {
            bloc: 'aftsus',
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'BTN_FORAGE_AFTSUS',
                    (data: any) => getForage(data),
                    (data: any) => {
                        return {
                            id: data.trncleint
                        }
                    },
                    {
                        fonction: 'BOUAFT'
                    }
                )
            ]),

            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'aftcoddscfmt', largeur: 300 },
                { nom: 'susflgact', largeur: 200 },
                { nom: 'datdeb', largeur: 200 },
                { nom: 'datfin', largeur: 200 }
            ],
            colonnesCachees: [
                'trncleint',
                'tytcleint',
                'tsecleintori'
            ]
        };

        // Primes
        vm.afpMultiOccurrenceOptions = {
            bloc: 'afp',
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false,
                nouveau: (!vm.valeursEcran.aftcleint) ? false : undefined
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('selection', {
                    largeur: 50,
                    default: 'VF',
                    direction: 'vertical'
                }),
                new FormulaireItem('actif', {
                    largeur: 50,
                    default: 'O',
                    direction: 'vertical'
                })
            ]),
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint', {
                    resourceParams: () => {
                        return {
                            aftcleint: vm.monoOccurrence.data.aftcleint
                        };
                    }
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('afpdatdeb'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('afpdatfin'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('afpmnt'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('afppou'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('afpcom'),
                new FormulaireItem('afpsui'),
                new FormulaireItem('afpflgact', {
                    default: 1
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'tagcoddsc', largeur: 300 },
                { nom: 'afpflgact', largeur: 200 },
                { nom: 'tagpar', largeur: 200 },
                { nom: 'afpmnt', largeur: 200 },
                { nom: 'unccoddsc', largeur: 200 },
                { nom: 'afpdatdeb', largeur: 200 },
                { nom: 'afpdatfin', largeur: 200 },
                { nom: 'afpcom', largeur: 200 },
                { nom: 'afpsui', largeur: 200 }
            ]
        };

        // Rattachement horaire lorsqu'officiel //
        vm.hhsMultiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([
                new MenuItemForage('BTN_OUVRIR', as0023Route.NAME, (data: any) => {
                    return {
                        ecran: '01-2',
                        id: data.trncleint,
                        hhscleintref: vm.monoOccurrence.data.aftcleint
                    };
                }, {
                    fonction: 'BOUOUV',
                    hidden: (data: any) => data.indpos
                })
            ]),
            actionsNouveaux: new Menu([
                new MenuItemForage(as0023Route.NAME, () => {
                    return {
                        ecran: '01-2',
                        hhscleintref: vm.monoOccurrence.data.aftcleint,
                        demcleint: vm.valeursEcran.demcleint
                    };
                }, {
                    fonction: 'BOUNOUV'
                })
            ]),
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false,
                criteresRechercheOpened: false
            },
            pagination: new Pagination({
                nombreElementPage: 3,
                nbElementsPossibles: [3, 6, 15]
            }),
            criteresSuggeres: new Formulaire([
                new FormulaireItem('annule', {
                    largeur: 50,
                    default: 'N'
                }),
                new FormulaireItem('selection', {
                    largeur: 100,
                    default: 'VF'
                })
            ]),
            // Affichage des colonnes visibles
            colonnesVisibles: [
                { nom: 'ahtcoddsc', largeur: 300 },
                { nom: 'indpos', largeur: 91 },
                { nom: 'trnflgstafin', largeur: 91 },
                { nom: 'trnflgstaann', largeur: 91 },
                { nom: 'indtmppar', largeur: 91 },
                { nom: 'flgahtman', largeur: 91 },
                { nom: 'asgcoddsc', largeur: 300 },
                { nom: 'hhsdatdeb', largeur: 126 },
                { nom: 'hhsdatfin', largeur: 126 },
                { nom: 'hhsqtetot', largeur: 126 },
                { nom: 'total__qtetot', largeur: 126 },
                { nom: 'aeqcoddsc', largeur: 300 },
                { nom: 'hhsdsc', largeur: 300 }
            ],
            colonnesCachees: [
                'hhscleint',
                'trncleint'
            ]
        };
    }

    function getForage(data: IData) {
        if (data.tsecleintori) {
            return rr0006Route.NAME;
        } else if ([4, 5, 73].includes(data.tytcleint)) {
            return rr0006Route.NAME;
        } else {
            return rr0004Route.NAME;
        }
    }
    /*
    on valida l'etat d'accord à la logique du SIGA-43624
                typeIcon:1 historique
                typeIcon:2 trx modification
    Flag qui s'utilise=> trnflgstaann: annulé, trnflgstafer: fermé, trnflgstafin: final, trnflgstaref: référable
    */
    function validateEtat(typeIcon: number, valeursEcran: any) {
        //référable
        if (valeursEcran.trnflgstaref) {
            return false;
        }
        else {
            if (typeIcon === 1 && valeursEcran.trnflgstafer) {
                return false;
            }
            else {
                return true;
            }
        }
    }
}
