import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageIf0001 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function If0001Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageIf0001 = this;

    vm.multiOccurrenceOptions = {
        fonctions:{
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('cafcod', {largeur: 20}),
            new FormulaireItem('cafdscpro', {largeur: 40}),
            new FormulaireItem('cafdscfed', {largeur: 40})
        ]),
        colonnesVisibles: [
            {nom: 'cafcod', largeur: 100},
            {nom: 'cafdscpro', largeur: 300},
            {nom: 'cafdscfed', largeur: 300}
        ]
    };
}
