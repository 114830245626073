import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageRR0090 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireEtu: IFormulaire;
}

/* @ngInject */
export default function Rr0090Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRR0090 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireEtu = new Formulaire('LBL_STATUT_ETUDIANT', [
            new FormulaireItem('demcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('trmcleint'),
            new FormulaireItem('trmdatdeb', {
                largeur: 25
            }),
            new FormulaireItem('trmdatfin', {
                largeur: 25
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etudsc', {
                largeur: 50
            }),
            new FormulaireItem('etuflgtmpcom'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaenivsco'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unocleint', {
                resourceParams: (data: any) => {
                    return {
                        datdeb: data.trmdatdeb,
                        datfin: data.trmdatfin,
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etunbrcrt')
        ], {
            largeurDefaut: 50
        });
    }
}
