import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IComponentController} from 'angular';

interface IPageCo0009 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    formulaireCor: IFormulaire;
}

interface ICo0009Params {
    stamnetab: string;
    corcleintref: number;
}

/* @ngInject */
export default function Co0009Controller($stateParams: ICo0009Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCo0009 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        //Encaissement
        vm.formulaireCor= new Formulaire('LBL_BLC_COR', [
            new FormulaireItem('grccleint', {largeur: 50}),
            new FormulaireItem('corseqaff', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('comcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaccorobl', {largeur: 50}),
            new FormulaireItem('corfacimp', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('nivcleintcib', {largeur: 50, resourceParams: (data: any) => ({tyccleint: data.tyccleint})}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('corvalcib', {largeur: 50}),
            new FormulaireItem('aide', {largeur: 50, titre: ' ', resourceParams: (data: any) => data.comcleint }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('corcom', {largeur: 100}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cordscweb', {largeur: 100}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('corexp', {largeur: 100}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('corflgaffweb', {titre: ($stateParams.stamnetab === 'XPP' ? 'corflgaffwebxpp' : 'corflgaffweb')}),
            new FormulaireItem('corflgidecan'),
            new FormulaireItem('corflgpae'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('corflgncoepl'),
            new FormulaireItem('corflgseldefdp'),
            new FormulaireItem('stamnetab', {default: $stateParams.stamnetab}),
            new FormulaireItem('corcleintref', {default: $stateParams.corcleintref}),
            new FormulaireItem('corflgact',  {default: 1})
        ], {largeurDefaut: 33.33});
    }
}
