import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageCr0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0018Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCr0018 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'cevcod', largeur: 90 },
            { nom: 'cevdsc', largeur: 262 },
            { nom: 'cevflgdat', largeur: 85 },
            { nom: 'cevflgint', largeur: 110 },
            { nom: 'cevflglib', largeur: 91 },
            { nom: 'cevflgact', largeur: 65 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cevcod', { required: true }),
            new FormulaireItem('cevdsc', { required: true }),
            new FormulaireItem('cevflgdat'),
            new FormulaireItem('cevflgint'),
            new FormulaireItem('cevflglib'),
            new FormulaireItem('cevflgact', { default: 1 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
