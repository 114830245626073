import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireGroupeClass } from "../../../../../core/services/formulaire/formulaire-groupe.service";
import { IMultiOccurrenceOptions } from "../../../../../core/services/multi-occurrence.service";

interface IPageGs0127 extends IComponentController {
    formulaireInfo: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    rnsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0127Controller(Formulaire: IFormulaireClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageGs0127 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        formulaire: new Formulaire('LBL_BLC_REV', [
            new FormulaireItem('elicleint', { largeur: 50, modifAllow: false }),
            new FormulaireItem('revlib', { largeur: 50, readonly: (data) => !data.t_eliflglib }),
            new FormulaireItem('t_eliflglib', { hidden: true }),
            new FormulaireGroupe('LBL_VALIDATIONS', new Formulaire([
                new FormulaireItem('vaerevval', { required: true }),
                new FormulaireItem('vaerevseqaut', { modifAllow: false }),
                new FormulaireItem('revflgfix'),
                new FormulaireItem('revlonele'),
                new FormulaireItem('revmassai'),
                new FormulaireItem('revflgjus'),
                new FormulaireItem('revbormin')
            ], { largeurDefaut: 33.33 }))
        ])
    };

    vm.formulaireInfo =
        new Formulaire([
            new FormulaireItem('vaerevtypdon', { largeur: 60, default: 3, direction: 'vertical' }),
            new FormulaireItem('vaerevtyplet', { largeur: 60, default: 3, direction: 'vertical' })
        ]);

    vm.rnsMultiOccurrenceOptions = {
        bloc: 'rns',
        colonnesVisibles: [
            { nom: 'rnsdsc', largeur: 200 },
            { nom: 'rnsnumseq_dsc', largeur: 200 }
        ]
    };
}
