import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IMenuItemActionTelechargementClass} from "../../../../../core/services/menu/menu-item-action-telechargement.service";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageRr0031 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dcoMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dcmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dcsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    dcoMultiOccurrence: IMultiOccurrence;
    dsaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menuItemAssurer: IMenuItem;
    menuItemBeneficiaire: IMenuItem;
}
interface IRr0031Params {
    id: string;
    demcleint: string;
    type: string;
    ppadatdeb: string;
}

/* @ngInject */
export default function rr0031Controller($stateParams: IRr0031Params,
    rr0164Route: IRoute,
    rr0165Route: IRoute,
    rr0166Route: IRoute,
    rr0033Route: IRoute,
    rr0056Route: IRoute,
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass,
    Operateur: IOperateurService) {
    const vm: IPageRr0031 = this;

    vm.$onInit = function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        // Bouton forage Assurer
        vm.menuItemAssurer = new MenuItemEcran(
            'LBL_ASSURE',
            rr0166Route.NAME,
            (data: any) => ({
                id: data.dcocleint,
                flgstafin: vm.monoOccurrence.etatActuel.flgstafin
            }),
            {
                icon: 'launch',
                iconButton: true,
                fonction: 'BTN_ASSURE',
                onClose: () => vm.dcoMultiOccurrence.fetchDataList()
            }
        );

        // Bouton forage Beneficiaire
        vm.menuItemBeneficiaire = new MenuItemEcran(
            'LBL_BENEFICIAIRE',
            rr0033Route.NAME,
            (data: any) => ({
                id: data.dcocleint
            }),
            {
                icon: 'launch',
                iconButton: true,
                fonction: 'BTN_BENEFICIAIRE',
                onClose: () => vm.dcoMultiOccurrence.fetchDataList()
            }
        );

        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_BTN_SIMULATION_RCE',
                    rr0164Route.NAME,
                    (data: any) => ({
                        id: data.trncleint,
                        demcleint: data.demcleint,
                        type: 'DAA'
                    }),
                    {
                        icon: 'refresh',
                        iconButton: false,
                        fonction: 'BTN_SIMULATION_RCE'
                    }
                ),
                new MenuItemEcran(
                    'LBL_BTN_DER_PERIODE_PAIE',
                    rr0165Route.NAME,
                    (data: any) => ({
                        id: data.trncleint,
                        demcleint: data.demcleint,
                        type: 'DAA'
                    }),
                    {
                        icon: 'attach_money',
                        iconButton: false,
                        fonction: 'BTN_DER_PERIODE_PAIE'
                    }
                )
            ]),
            formulaire: new Formulaire('LBL_BLC_DAA', [
                new FormulaireItem('demcleint', {
                    largeur: 50,
                    default: $stateParams.demcleint
                }),
                new FormulaireItem('aut__demage', { largeur: 50 }),
                new FormulaireItem('placleint', { largeur: 50 }),
                new FormulaireItem('plccleint', {
                    largeur: 50,
                    resourceParams: (data: any) => ({
                        demcleint: data.demcleint
                    })
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('daadatdeb', {
                    default: $stateParams.ppadatdeb
                }),
                new FormulaireItem('daadatadm', {
                    default: $stateParams.ppadatdeb
                }),
                new FormulaireItem('daaflgcot'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('daadatfin'),
                new FormulaireItem('daadatdebrem'),
                new FormulaireItem('daadatfinrem'),
                new FormulaireItem('daaidt', {
                    largeur: 50,
                    titre: (data: any) => data.aut__daalibidt
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('daacom', { largeur: 100, enableCount: true }),
                // Autres informations cachées
                new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS', new Formulaire([
                    new FormulaireItem('daanumdoc', { largeur: 25 }),
                    new FormulaireItem('daaseqmodcou', { largeur: 25 }),
                    new FormulaireItem('ttccleint', {
                        nombreLibellesDetailsCacher: 1,
                        largeur: 50
                    }),
                    new FormulaireItemDivider('LBL_ACCORDEON_COMPTE_BANCAIRE', {
                        hidden: (data: any) => !data.aut__plaflgcptbqe
                    }),
                    new FormulaireItem('aut__efodatvig'),
                    new FormulaireItem('aut__inscod'),
                    new FormulaireItem('aut__succod_dem'),
                    new FormulaireItem('aut__efdcptbqe'),
                    new FormulaireItem('aut__efdprc')
                ], { largeurDefaut: 33 }))
            ], { largeurDefaut: 25 })
        };

        vm.menus = {
            aut__daarelcon: [
                new MenuItemActionTelechargement(
                    '',
                    'BOURELCON',
                    () => ({}),
                    {
                        icon: 'attach_file'
                    }
                )
            ]
        };

        const dcoIsReadonly = (data: any) => data.dcoseqmod < vm.monoOccurrence.data.daaseqmodcou;

        // Onglet Couverture
        vm.dcoMultiOccurrenceOptions = {
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_FORAGE_RR0056',
                    rr0056Route.NAME,
                    (data: any) => {
                        return {
                            rcecleintref: data.rcecleint,
                            demcleint: data.$ancetre.demcleint
                        };
                    },
                    {
                        fonction: 'BTN_AJUSTEMENT',
                        icon: 'view_list'
                    }
                )
            ]),
            filtres: [
                {
                    colonne: 'dcoflgact',
                    operateur: Operateur.EGALE
                },
                {
                    colonne: 'dcoflgdatact',
                    operateur: Operateur.EGALE
                }
            ],
            fonctions: {
                pagination: true,
                criteresSuggeresVisibles: false
            },
            colonnesVisibles: [
                { nom: 'asodsc', largeur: 100 },
                { nom: 'aspdsc', largeur: 100 },
                { nom: 'dcoval', largeur: 100 },
                { nom: 'dcoflgact', largeur: 100 },
                { nom: 'dcodatdeb', largeur: 100 },
                { nom: 'dcodatfin', largeur: 100 },
                { nom: 'dcodscfum', largeur: 100 },
                { nom: 'dcodsccoo', largeur: 100 },
                { nom: 'dconbrass', largeur: 100 },
                { nom: 'dconbrben', largeur: 100 }
            ],
            colonnesCachees: [
                'aspflgasscjt',
                'dcoflgass',
                'dcoflgben',
                'dcocleint',
                'rcecleint',
                'dcodatfinprec',
                'dcoflgseqmod'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('asocleint',
                    { resourceParams: () => ({ placleint: vm.monoOccurrence.data.placleint }), readonly: dcoIsReadonly }
                ),
                new FormulaireItem('aspcleint', { readonly: dcoIsReadonly }),
                new FormulaireItem('dcoval', { readonly: dcoIsReadonly }),
                new FormulaireItem('dcovalchx', { readonly: dcoIsReadonly }),
                new FormulaireItem('dcodatdeb', { readonly: dcoIsReadonly }),
                new FormulaireItem('dcodatfin', { readonly: (rowData) => (rowData.dcodatfinprec && dcoIsReadonly) }),
                new FormulaireItem('dcoseqmod', { default: () => vm.monoOccurrence.data.daaseqmodcou }),
                new FormulaireItem('dcoflgact', { default: 1, readonly: (rowData:any) => (!rowData.dcoflgact) }),
                new FormulaireItem('dcoflgfum', { readonly: dcoIsReadonly }),
                new FormulaireItem('dcoflgcoo', { readonly: dcoIsReadonly }),
                new FormulaireItemDivider('LBL_DIV_CON', { hidden: data => !data.aspflgasscjt && !data.dcoflgcoo }),
                new FormulaireItem('aconumcon', { hidden: data => !data.aspflgasscjt && !data.dcoflgcoo, readonly: dcoIsReadonly }),
                new FormulaireItem('vaeacosta', { hidden: data => !data.aspflgasscjt && !data.dcoflgcoo, readonly: dcoIsReadonly }),
                new FormulaireItem('aconomass', { hidden: data => !data.aspflgasscjt && !data.dcoflgcoo, readonly: dcoIsReadonly })
            ], { largeurDefaut: 33 })
        };

        const dasIsReadonly = (data: any) => data.dcoseqmod < vm.monoOccurrence.data.daaseqmodcou;

        // Salaire assurable
        vm.dsaMultiOccurrenceOptions = {
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                { nom: 'dsasal', largeur: 100 },
                { nom: 'dsaflgact', largeur: 100 },
                { nom: 'dsadatvig', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('dsamodseq', { default: () => vm.monoOccurrence.data.daaseqmodcou }),
                new FormulaireItem('dsadatvig', { readonly: dasIsReadonly }),
                new FormulaireItem('dsasal', { readonly: dasIsReadonly }),
                new FormulaireItem('dsaflgact', { default: 1 })
            ], { largeurDefaut: 50 })
        };

        // Compte bancaire
        vm.dcmMultiOccurrenceOptions = {
            fonctions: {
                pagination: true,
                exportation: false
            },
            colonnesVisibles: [
                { nom: 'inscod', largeur: 100 },
                { nom: 'succod', largeur: 100 },
                { nom: 'dcmcptbqe', largeur: 100 },
                { nom: 'dcmdatvig', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('dcmdatvig'),
                new FormulaireItem('inscleint', { largeur: 100 }),
                new FormulaireItem('succleint', { largeur: 100 }),
                new FormulaireItem('dcmcptbqe'),
            ])
        };

        // Choix ass.
        vm.dcsMultiOccurrenceOptions = {
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                { nom: 'asocod', largeur: 100 },
                { nom: 'asodsc', largeur: 100 },
                { nom: 'aspcod', largeur: 100 },
                { nom: 'aspdsc', largeur: 100 },
                { nom: 'dcsdatvig', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('asocleint', { resourceParams: () => ({ placleint: vm.monoOccurrence.data.placleint }) }),
                new FormulaireItem('aspcleint'),
                new FormulaireItem('dcsdatvig')
            ])
        };
    }
}
