import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';

interface IPageAc2003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac2003Controller(
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
                                            
    const vm: IPageAc2003 = this; 

    vm.multiOccurrenceOptions = {
        fonctions:{
            criteresRechercheCollapsable: false,
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('dhacleint', {
                default: $stateParams.dhacleint,
                disabled: true
            })
        ]),
        colonnesVisibles: [
            {nom: 'vaesysite_dsc', largeur: 180},
            {nom: 'dduancval', largeur: 200},
            {nom: 'ddunouval', largeur: 200}
        ]
    };
}