import * as angular from 'angular';
import {IAugmentedJQuery, IDocumentService, module} from 'angular';
import {IDataTypeMap} from '../../../core/services/data-types/data-type.service';
import {ILibelles} from '../../../core/services/libelles.service';
import {ISchemas} from '../../../core/interfaces/schemas.interface';
import {IInjectStyleService} from '../../../core/services/inject-style.service';
import {IFilterLibelle} from '../../../core/filters/ex-libelle.filter';
import {IDialog} from '../../../core/services/dialog.service';
import IDialogOptions = angular.material.IDialogOptions;

export interface IAideTechniqueService {
    active: boolean;
    activate(): void;
    deactivate(): void;
    toggleActivation(): void;
}

interface IAideTechniqueDetails {
    cleint?: string;
    dataTypes: IDataTypeMap;
    libelles: ILibelles;
    schema: ISchemas;
}

export default module('app.services.aide-technique', []).factory('aideTechnique', AideTechniqueFactory);

/* @ngInject */
function AideTechniqueFactory($document: IDocumentService,
                              injectStyle: IInjectStyleService,
                              exLibelleFilter: IFilterLibelle,
                              DialogAideTechnique: IDialog): IAideTechniqueService {
    injectStyle(`.ex-aide-technique-active *{
        cursor: help !important;
    }
    
    .ex-aide-technique-active ex-formulaire-input input:hover,
    .ex-aide-technique-active ex-formulaire-input md-checkbox:hover,
    .ex-aide-technique-active ex-formulaire-input ex-input-lov:hover,
    .ex-aide-technique-active [multi-occurrence] .ex-grid-head-col:hover,
    .ex-aide-technique-active [multi-occurrence] .ex-grid-col:hover,
    .ex-aide-technique-active ex-data:hover,
    .ex-aide-technique-active ex-multi-occurrence-accordeon ex-data-value:hover {
        background: rgb(255, 255, 200) !important;
    }`);

    class AideTechnique implements IAideTechniqueService {
        active: boolean = false;

        activate() {
            this.active = true;

            angular.element(document.body)
                .addClass('ex-aide-technique-active');

            const selectors = [
                '[multi-occurrence] .ex-grid-head-col',
                '[multi-occurrence] .ex-grid-col',
                '[multi-occurrence] .ex-grid-col-edition-rapide ex-formulaire-input input',
                '[multi-occurrence] .ex-grid-col-edition-rapide ex-formulaire-input ex-input-lov',
                'ex-panneau-page-content ex-data',
                'ex-multi-occurrence-accordeon ex-data-value'
            ].join(',');

            $document.on('click.aideTechnique', selectors, (event: any) => {
                event.preventDefault();
                event.stopPropagation();

                const el: IAugmentedJQuery = angular.element(<HTMLElement>event.currentTarget);
                const ctrl = el.closest('ex-multi-occurrence').controller('exMultiOccurrence');
                const col = el.attr('col') || el.find('[col]').attr('col') || el.attr('name') || el.find('[name]').attr('name');

                this.open(event, col, ctrl.multiOccurrence);
            });

            $document.on('click.aideTechnique', 'input, md-checkbox, ex-input-lov>md-input-container', (event: any) => {
                if (event.isPropagationStopped()) return;
                event.preventDefault();
                event.stopPropagation();

                const el: IAugmentedJQuery = angular.element(<HTMLElement>event.currentTarget);
                const ctrl = el.closest('ex-formulaire-input').controller('exMonoOccurrence');
                const col = el.attr('name') || el.find('[name]').attr('name') || el.closest('ex-input-lov').attr('col') || el.closest('ex-input-lov').find('[col]').attr('col');

                this.open(event, col, ctrl.monoOccurrence);
            });

            $document.on('click.aideTechnique', 'ex-data', (event: any) => {
                event.preventDefault();
                event.stopPropagation();

                const el = angular.element(<HTMLElement>event.currentTarget);
                const ctrl = el.controller('exData');
                const col = el.attr('col');

                this.open(event, col, {
                    libelles: ctrl.libelles,
                    schema: ctrl.schemas,
                    dataTypes: ctrl.dataTypes
                })
            });
        }

        deactivate() {
            $document.off('.aideTechnique');
            angular.element(document.body).removeClass('ex-aide-technique-active');
            this.active = false;
        }

        toggleActivation() {
            if (this.active) {
                this.deactivate();
            } else {
                this.activate();
            }
        }

        private open(event: MouseEvent, col: string, details: IAideTechniqueDetails) {
            const dialogOptions: IDialogOptions = {
                targetEvent: event,
                locals: {
                    dataType: details.dataTypes[col],
                    schemaItem: details.schema[col],
                    libelle: exLibelleFilter(col, details.libelles),
                    col: col
                }
            };

            DialogAideTechnique.show(dialogOptions);

            this.deactivate();
        }
    }

    return new AideTechnique();
}
