import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0007 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0007Controller(gs0118Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0007 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: gs0118Route.NAME,
        navigateParams: (data: any) => (
            {
                id: data.mencleint,
                mencle: data.mencleint
            }),
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0118Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'mendsc', largeur: 250},
            {nom: 'mendscmen', largeur: 250},
            {nom: 'vaesysapp_dsc', largeur: 150},
            {nom: 'vaesysmodsof_dsc', largeur: 140},
            {nom: 'menflgcen', largeur: 100}
        ],
        colonnesCachees:['mencleint']
    };
}
