import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';

interface IPagePI0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0026Controller(Menu: IMenuClass,
                                         Operateur: IOperateurService) {
    const vm: IPagePI0026 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        filtres: [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ],
        colonnesVisibles: [
            {nom: 'pdnnumdoc', largeur: 200},
            {nom: 'pdndsc', largeur: 200},
            {nom: 'pipnumdoc', largeur: 200},
            {nom: 'pipnumref', largeur: 200},
            {nom: 'pipdscabr', largeur: 200},
            {nom: 'pdndat', largeur: 200},
            {nom: 'ecldsceta', largeur: 200},
            {nom: 'pdnnumdocref', largeur: 200},
            {nom: 'ptncod', largeur: 200},
            {nom: 'ptndscabr', largeur: 200},
            {nom: 'pdnnumref', largeur: 200},
            {nom: 'pdnnum', largeur: 200},
            {nom: 'pdnmntavt', largeur: 200},
            {nom: 'pdnmnttti', largeur: 200},
            {nom: 'ttccod', largeur: 200},
            {nom: 'ttcdsc', largeur: 200},
            {nom: 'pplnumdoc', largeur: 200},
            {nom: 'ppldscl01', largeur: 200},
            {nom: 'atvcod', largeur: 200},
            {nom: 'atvdscabr', largeur: 200},
            {nom: 'trnflgstaref', largeur: 200},
            {nom: 'trnflgstafin', largeur: 200},
            {nom: 'trnflgstafer', largeur: 200},
            {nom: 'trnflgstaann', largeur: 200}
        ]
    };
}
