import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMc2004 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTmp: IFormulaire;
    formulaireDroite: IFormulaire;
    sgpdMultiOccurrenceOptions: IMultiOccurrenceOptions;    
    sgpMultiOccurrenceOptions: IMultiOccurrenceOptions;    
}

/* @ngInject */
export default function Mc2004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc2004 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        
        vm.formulaireStg = new Formulaire('LBL_BLC_STG_GAU', [
            new FormulaireItem('stgflgregpru', {hidden: (data: any) => !data.flgpru, largeur: 10, minWidth: 50}),
            new FormulaireItem('stgflgregprs', {hidden: (data: any) => !data.flgprs, largeur: 10, minWidth: 50}),
            new FormulaireItem('stgflgregcif', {hidden: (data: any) => !data.flgcif, largeur: 10, minWidth: 50}),
            new FormulaireItem('stgflgregcpt', {hidden: (data: any) => !data.flgcpt, largeur: 10, minWidth: 50}),
            new FormulaireItem('stgflgreguna', {hidden: (data: any) => !data.flguna, largeur: 10, minWidth: 50}),
            new FormulaireItem('stgflgregel1', {hidden: (data: any) => !data.flgel1, largeur: 10, minWidth: 50}),
            new FormulaireItem('stgflgregel2', {hidden: (data: any) => !data.flgel2, largeur: 10, minWidth: 50}),
            new FormulaireItem('stgflgregel3', {hidden: (data: any) => !data.flgel3, largeur: 10, minWidth: 50}),
            new FormulaireItemDivider('ENT_NONPERM'),
            new FormulaireItem('stgflgnonnul', {largeur: 10, minWidth: 100}),
            new FormulaireItem('stgflgnonzer', {largeur: 10, minWidth: 100}),
            new FormulaireItemDivider(''),
            new FormulaireItem('impdeb', { readonly: true }),
            new FormulaireItem('impfin', { readonly: true })
        ]);

        vm.formulaireDroite = new Formulaire('LBL_BLC_STG_DROITE', [
            new FormulaireItem('stgflgnonnul', {largeur: 10, minWidth: 100}),
            new FormulaireItem('stgflgnonzer', {largeur: 10, minWidth: 100})
        ]);

        vm.sgpMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'impdebsgp'        , largeur: 250},
                {nom: 'impfinsgp'        , largeur: 250}
            ],
            colonnesCachees:['vaestgtyp'],
            formulaire: new Formulaire([
                new FormulaireItem('impdebsgp'),
                new FormulaireItem('impfinsgp')
            ], {largeurDefaut: 50})
        };

        vm.sgpdMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'impdebsgp'        , largeur: 250},
                {nom: 'impfinsgp'        , largeur: 250}
            ],
            colonnesCachees:['vaestgtyp'],
            formulaire: new Formulaire([
                new FormulaireItem('impdebsgp'),
                new FormulaireItem('impfinsgp')
            ], {largeurDefaut: 50})
        };
        
    }
}
