import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";

interface IPageGs0004 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulairePtl: IFormulaire;
    ptvMultiOccurrenceOptions: IMultiOccurrenceOptions;
    srcMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0004Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageGs0004 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            }
        };

        vm.formulairePtl = new Formulaire('LBL_BLC_PTL', [
            new FormulaireItem('ptlcod'),
            new FormulaireItem('ptltyp', {largeur: 25}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ptltypdon', {largeur: 20}),
            new FormulaireItem('ptllngdon', {largeur: 20}),
            new FormulaireItem('ptldecdon', {largeur: 20}),
            new FormulaireItem('ptltypcas', {largeur: 20}),
            new FormulaireItem('ptlflgintexc', {largeur: 20}),
            new FormulaireItem('ptlvaldef', {largeur: 33}),
            new FormulaireItem('ptlpasvaldef', {largeur: 33}),
            new FormulaireItem('ptlmas', {largeur: 33}),
            new FormulaireItem('ptllab', {largeur: 20}),
            new FormulaireItem('ptlaid', {largeur: 80}),
            new FormulaireItem('ptlreqsql', {largeur: 100}),
        ], {largeurDefaut: 50});
        vm.ptvMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'ptvcle', largeur: 100},
                {nom: 'ptvdsc', largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('ptvcle'),
                new FormulaireItem('ptvdsc')
            ], {largeurDefaut: 25})
        };
        vm.srcMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'srccod', largeur: 110},
                {nom: 'srcdsc', largeur: 280}
            ]
        };
    }
}
