import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'mc0046',
    ecrans: ['00-2', '01-2'],
    params: [
        'vaeusient',
        'uvicleintref',
        '?doccleint'
    ]
});
