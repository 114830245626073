import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForage } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageSo0017 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    multiOccurrenceTum: IMultiOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions,
    formulaireTun: IFormulaire;
    tudMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tudMultiOccurrence: IMultiOccurrence;
    menuItemSelectionPoste: IMenuItemForage;
    tumMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function So0017Controller(Formulaire: IFormulaireClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    so0020Route: IRoute,
    MenuItemAction: IMenuItemActionClass,
    MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageSo0017 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.menuItemSelectionPoste = new MenuItemEcran(
            'G_LBL_SELECTION_POSTE',
            so0020Route.NAME,
            (data: any) => ({ ecran: '00-2', tuncleint: data.tuncleint, tundatvig: data.tundatvig }),
            {
                icon: 'search',
                iconButton: false,
                appliquer: true,
                onClose() { vm.tudMultiOccurrence.fetchDataList(); }
            }
        );

        // Message du traitement (PM2)
        vm.tumMultiOccurrenceOptions = {
            bloc: 'tum',
            fonctions: {
                pagination: true,
                recherche: false,
                exportation: false,
                suiviModification: false,
                filtrer: false,
                activeRowOnClick: false
            },
            pagination: new Pagination({
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 25]
            }),

            colonnesVisibles: [
                { nom: 'tummsg', largeur: 100 }
            ]
        };

        vm.monoOccurrenceOptions = {
            fonctions: {
                afficherMenusDansEntete: true
            },
            menus: [
                new MenuItemAction(
                    'LBL_BTN_TRT',
                    'BOUTRT',
                    () => ({}),
                    {
                        saveFirst: true,
                        iconButton: false,
                        class: 'md-raised ex-button-action',
                        successAction: (result: any) => {
                            vm.multiOccurrenceTum.fetchDataList();

                            if (!result.typmsg) {
                                vm.monoOccurrence.fetchData();
                            }
                        },
                        disabled: () => !vm.tudMultiOccurrence || !vm.tudMultiOccurrence.dataList || vm.tudMultiOccurrence.dataList.length < 1 || vm.monoOccurrence.data.tunflgconf
                    }
                )
            ]
        };

        // Transfert un. adm RH
        vm.formulaireTun = new Formulaire('LBL_BLC_TUN', [
            new FormulaireItem('tundatvig', { largeur: 25 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tuncom', { largeur: 100 }),
            new FormulaireItemDivider('LBL_INFOS_A_MODIFIER'),
            new FormulaireItem('unocleintrh'),
            new FormulaireItem('unocleintvac'),
            new FormulaireItem('stocleint', {
                resourceParams: (data: any) => ({ tundatvig: data.tundatvig })
            })
        ], { largeurDefaut: 50 });

        vm.tudMultiOccurrenceOptions = {
            bloc: 'tud',
            colonnesFixesGauche: [
                { nom: 'posnumdoc', largeur: 120 }
            ],
            colonnesVisibles: [
                { nom: 'postitabr', largeur: 250 },
                { nom: 'etat', largeur: 180 },
                { nom: 'tudflgent', largeur: 100, hidden: () => !vm.monoOccurrence.data.stocleint },
                { nom: 'nouposnumdoc', largeur: 120, hidden: () => !vm.monoOccurrence.data.stocleint },
                { nom: 'noupostitabr', largeur: 180, hidden: () => !vm.monoOccurrence.data.stocleint },
                { nom: 'nounirniv', largeur: 120, hidden: () => !vm.monoOccurrence.data.stocleint },
                { nom: 'nounirdscabr', largeur: 250, hidden: () => !vm.monoOccurrence.data.stocleint },
                { nom: 'stodscabr', largeur: 250, hidden: () => !vm.monoOccurrence.data.stocleint },
                { nom: 'unocodrh', largeur: 125 },
                { nom: 'unodscabrrh', largeur: 250 },
                { nom: 'unocodvac', largeur: 120 },
                { nom: 'unodscabrvac', largeur: 250 },
                { nom: 'tpocod', largeur: 130 },
                { nom: 'tpodsc', largeur: 250 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('poscleint', {
                    resourceParams: () => ({ tundatvig: vm.monoOccurrence.data.tundatvig }),
                    required: true,
                    hidden: data => data.tudcleint
                }),
                new FormulaireItem('tudflgent', { largeur: 20, readonly: () => !vm.monoOccurrence.data.stocleint, hidden: data => !data.tudcleint }),
                new FormulaireItem('nouposcleintsup', {
                    resourceParams: (data: any) => ({
                        tundatvig: vm.monoOccurrence.data.tundatvig,
                        poscleint: data.poscleint,
                        stocleint: vm.monoOccurrence.data.stocleint,
                        tuncleint: vm.monoOccurrence.data.tuncleint
                    }),
                    readonly: () => !vm.monoOccurrence.data.stocleint, hidden: data => !data.tudcleint
                }),
                new FormulaireItem('nounircleint', { readonly: () => !vm.monoOccurrence.data.stocleint, hidden: data => !data.tudcleint })
            ], { largeurDefaut: 40 })
        };
    }
}
