import {module} from 'angular';
import InfosComplementairesController from './dialog-infos-complementaires.controller';
import {IDialogClass} from '../../../../core/services/dialog.service';

export default module('app.dialogs.dialog-infos-complementaires', []).factory('DialogInfosComplementaires', DialogInfosComplementairesFactory);

/* @ngInject */
function DialogInfosComplementairesFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: InfosComplementairesController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-infos-complementaires.html')
    });
}
