import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageMR0253 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0253Controller () {
    const vm: IPageMR0253 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'stamnetab', largeur: 66},
            {nom: 'notdscref', largeur: 250},
            {nom: 'notdsc', largeur: 250},
            {nom: 'gnocod', largeur: 200},
            {nom: 'gnodsc', largeur: 250},
            {nom: 'tnocod', largeur: 288},
            {nom: 'tnodsc', largeur: 250},
            {nom: 'notdat', largeur: 100},
            {nom: 'usrcod', largeur: 115},
            {nom: 'gnoflggbl', largeur: 100},
            {nom: 'nbrpj', largeur: 100}
        ]
    };
}
