import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IMenuClass} from "../../../../../core/services/menu/menu.service";
import {IMenuItemFactoryClass} from "../../../../../core/services/menu/menu-item-factory.service";
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';

interface IPageGL0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;

    //Contient la liste des forages à partir de l'écran et indique si c'est valide ou non
    forages: any;
}
/* @ngInject */
export default function Gl0008Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Menu: IMenuClass,
                                         MenuItemFactory: IMenuItemFactoryClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         isForageValide: IIsForageValideService) {
    const vm: IPageGL0008 = this;

    vm.forages = {};

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            suiviModification: false,
            criteresSuggeresVisibles: localStorage.getItem('forageFromMenu') === 'oui'
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItemDivider('LBL_ANNEE_FIN'),
            new FormulaireItem('annfin', {
                required: true
            }),
            new FormulaireItem('perdeb', {
                required: true,
                default: 1
            }),
            new FormulaireItem('perfin', {
                required: true
            }),
            new FormulaireItem('tymcleint', {
                required: true
            }),
            new FormulaireItemDivider('LBL_COMPTE'),
            new FormulaireItem('imput', {default: true}),
            new FormulaireItem('vaesysnatctb', {
                required: true,
                disabled: (data: any) => {
                    return data.cptcleint;
                }
            }),
            new FormulaireItem('soncleint'),
            new FormulaireItemDivider('LBL_REF_EXTERNE'),
            new FormulaireItem('typref'),
            new FormulaireItem('ref'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vectypapp'),
            new FormulaireItem('mapcod'),
            new FormulaireItemDivider('LBL_MONTANT'),
            new FormulaireItem('mntmin'),
            new FormulaireItem('mntmax'),
            new FormulaireItemDivider('LBL_INFORMATIONS_DETAILLES'),
            new FormulaireItem('tydcleint'),
            new FormulaireItem('trfdsc')
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemFactory(getForageItem)
        ]),
        colonnesVisibles: [
            {nom: 'peccod'            , largeur: 80},
            {nom: 'tydcod'            , largeur: 100},
            {nom: 'docaffdat01'       , largeur: 100},
            {nom: 'docaffnumdoc01'    , largeur: 184},
            {nom: 'imputation'        , largeur: 340},
            {nom: 'docrefcod02'       , largeur: 130},
            {nom: 'docrefnom02'       , largeur: 262},
            {nom: 'mapcod_aff'        , largeur: 110},
            {nom: 'mapnom'            , largeur: 226},
            {nom: 'docaffdsc01'       , largeur: 262},
            {nom: 'docreqcod03'       , largeur: 100},
            {nom: 'docreqnom03'       , largeur: 262}
        ],
        colonnesFixesDroite: [
            {nom: 'docaffmnt01'       , largeur: 126},
            {nom: 'impact'            , largeur: 126}
        ],
        colonnesDisponibles:[
            {nom: 'docaffnumdoc01ori' , largeur: 189},
            {nom: 'tydcodori'         , largeur: 89},
            {nom: 'docaffnumdoc01_dac', largeur: 150},
            {nom: 'multi'             , largeur: 65},
            {nom: 'docaffdsc01_dac'   , largeur: 262},
            {nom: 'trfqte'            , largeur: 140}
        ],
        colonnesCachees: [
            'ecrcod',
            'doccleintref'
        ]
    };

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => {
                return `secure.${data.ecrcod}.ecran`;
            },
            (data: any) => ({id: data.doccleintref}),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('gl0008', data.ecrcod);
                }
            }
        )
    }
}
