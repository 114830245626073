import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMenuItem } from '../../../../../core/services/menu/menu-item.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import {
    IMenuItemActionImportation,
    IMenuItemActionImportationClass
} from '../../../../../core/services/menu/menu-item-action-importation.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMr0126 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireUnp: IFormulaire;
    formulaireNoemp: IFormulaire;
    uviMultiOccurrenceOptions: IMultiOccurrenceOptions;
    muiMultiOccurrenceOptions: IMultiOccurrenceOptions;
    muiMultiOccurrence: IMultiOccurrence;
    menuItemImage: IMenuItemActionImportation;
    menuItemModifImage: IMenuItemActionImportation;
    uppMultiOccurrence: IMultiOccurrence;
    uppMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tppMultiOccurrence: IMultiOccurrence;
    tppMultiOccurrenceOptions: IMultiOccurrenceOptions;
    modaleUsageImputation: IMenuItem;
    genperstapai: IMenuItem;
    genperstatmp: IMenuItem;
    genperstat: IMenuItem;
}

interface IMr0126Params {
    id: string;
}

/* @ngInject */
export default function Mr0126Controller(mc0046Route: IRoute,
    mc2048Route: IRoute,
    mc2047Route: IRoute,
    Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    Operateur: IOperateurService,
    $stateParams: IMr0126Params,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass) {

    const vm: IPageMr0126 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    // Générer statuts (paie)
    vm.genperstapai = new MenuItemAction(
        'BTN_GEN_STATUT_PAIE',
        'BOUGENPERPAI',
        () => ({}),
        {
            icon: 'sync',
            iconButton: false,
            successAction: () => {
                vm.uppMultiOccurrence && vm.uppMultiOccurrence.fetchDataList();
            },
            formulaireConfirmationAction: {
                lblTitre: 'LBL_GEN_PER_PAIE',
                lblConfirm: 'G_LBL_BTN_OK',
                largeurFormulaire: 25,
                formulaire: new Formulaire([
                    new FormulaireItem('anrann', {
                        largeur: 100,
                        resourceParams: (data: any) => {
                            return {
                                percleint: data.percleint
                            };
                        }
                    })
                ])
            }
        }
    );

    // Générer statuts (temps)
    vm.genperstatmp = new MenuItemAction(
        'BTN_GEN_STATUT_TEMPS',
        'BOUGENPERTMP',
        () => ({

        }),
        {
            icon: 'sync',
            iconButton: false,
            successAction: () => {
                vm.tppMultiOccurrence && vm.tppMultiOccurrence.fetchDataList();
            },
            formulaireConfirmationAction: {
                lblTitre: 'LBL_GEN_PER_PERIODE',
                lblConfirm: 'G_LBL_BTN_OK',
                largeurFormulaire: 25,
                formulaire: new Formulaire([
                    new FormulaireItem('asgcleint', {
                        largeur: 100,
                        resourceParams: (data: any) => {
                            return {
                                unpcleint: data.unpcleint
                            };
                        }
                    }),
                    new FormulaireItem('snrann', {
                        largeur: 100,
                        resourceParams: (data: any) => {
                            return {
                                percleinttth: data.percleinttth
                            };
                        }
                    })
                ])
            }
        }
    );


    // Modification des statuts
    vm.genperstat = new MenuItemAction(
        'BTN_GEN_STATUT',
        'BOUMODPSTAT',
        () => ({

        }),
        {
            icon: 'sync',
            iconButton: false,
            successAction: () => {
                vm.tppMultiOccurrence && vm.tppMultiOccurrence.fetchDataList();
            },
            formulaireConfirmationAction: {
                lblTitre: 'LBL_GEN_PER_PERIODE',
                lblConfirm: 'G_LBL_BTN_OK',
                largeurFormulaire: 40,
                formulaire: new Formulaire([
                    new FormulaireItem('asgcleint', {
                        largeur: 100,
                        resourceParams: (data: any) => {
                            return {
                                unpcleint: data.unpcleint
                            };
                        }
                    }),
                    new FormulaireItem('ptacleintde', {
                        required: true,
                        largeur: 10
                    }),
                    new FormulaireItem('ptacleinta', {
                        required: true,
                        largeur: 10
                    }),
                    new FormulaireItem('vaetppstu', {
                        required: true,
                        largeur: 10
                    })
                ])
            }
        }
    );

    // Formulaire principal (Unité de paiement)
    vm.formulaireUnp = new Formulaire('LBL_BLC_UNP', [
        new FormulaireItem('unpcod', { largeur: 33 }),
        new FormulaireItem('unpdsc', { largeur: 66 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('percleint'),
        new FormulaireItem('percleinttth'),
        new FormulaireItem('unpflgdef'),
        new FormulaireItem('unpflgact', { default: 1 }),
        new FormulaireItem('vaesyscodprv'),
        new FormulaireItem('vaesysperind', { default: vm.valeursEcran.vaesysperind, readonly: true }),
        // Formulaire
        new FormulaireGroupeAccordeon('LBL_DIV_FORM_FINANN', new Formulaire([
            new FormulaireItem('rfecleintprv', { largeur: 50 }),
            new FormulaireItem('rfecleintcss', { largeur: 50 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unpetacod', { largeur: 5 }),
            new FormulaireItem('ptxnument', { largeur: 5 }),
            new FormulaireItem('lipcleint', { largeur: 44 })
        ]), {
            opened: (data: any) => !data.unpcleint
        }
        )
    ]);

    // No d'employeurs
    vm.formulaireNoemp = new Formulaire('LBL_BLC_NOEMP', [
        new FormulaireItem('rfecleintfedreg', { largeur: 100 }),
        new FormulaireItemSautDeLigne(),
        new FormulaireItem('rfecleintfedred'),
        new FormulaireItem('rfecleintfednonres')
    ]);

    // Statut période de paie
    vm.uppMultiOccurrenceOptions = {
        bloc: 'upp',
        fonctions: {
            rechercheCollapsable: false
        },    
        colonnesVisibles: [
            { nom: 'ppaper', largeur: 85 },
            { nom: 'ppacod', largeur: 100 },
            { nom: 'ppadatdeb', largeur: 140 },
            { nom: 'ppadatfin', largeur: 140 },
            { nom: 'ppadatpai', largeur: 140 },
            { nom: 'vaeppatecdem_dsc', largeur: 175 },
            { nom: 'vaeuppstu_dsc', largeur: 175 },
            { nom: 'uppflgblc', largeur: 110 }
        ],
        colonnesCachees: [
            'ppacleint',
            'doccleint',
            'ppacleintcou',
            'modproflg',
            'stuprec_vaevalcle',
            'stuprec_vaedsc'
        ],
        actionsRangeeDroite: new Menu([
            new MenuItemAction(
                'LBL_BTN_BOUPERCOU',
                'BOUPERCOU',
                (data: any) => ({
                    stuprec__vaevalcle: data.stuprec_vaevalcle,
                    stuprec__vaedsc: data.stuprec_vaedsc
                }),
                {
                    icon: 'where_to_vote',
                    directionTooltip: 'top',
                    successAction: () => {
                        vm.uppMultiOccurrence && vm.uppMultiOccurrence.fetchDataList();
                    },
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_PER_COU_UPP',
                        lblConfirm: 'G_LBL_BTN_CONFIRMER',
                        largeurFormulaire: 60,
                        formulaire: new Formulaire([
                            new FormulaireItem('t_ppaper', {
                                largeur: 10,
                                default: vm.valeursEcran.t_ppaper
                            }),
                            new FormulaireItem('t_ppacod', {
                                largeur: 10,
                                default: vm.valeursEcran.t_ppacod
                            }),
                            new FormulaireItem('t_ppadatdeb', {
                                largeur: 10,
                                default: vm.valeursEcran.t_ppadatdeb
                            }),
                            new FormulaireItem('t_ppadatfin', {
                                largeur: 10,
                                default: vm.valeursEcran.t_ppadatfin
                            }),
                            new FormulaireItem('t_ppadatpai', {
                                largeur: 10,
                                default: vm.valeursEcran.t_ppadatpai
                            }),
                            new FormulaireItem('t_vaedsc', {
                                largeur: 15,
                                default: vm.valeursEcran.t_vaedsc
                            }),
                            new FormulaireItem('t_vaeuppstu', {
                                largeur: 15,
                                default: vm.valeursEcran.t_vaeuppstu
                            }),
                            new FormulaireItem('stuprec', { largeur: 40 })
                        ])
                    }
                }
            )
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemForage('LBL_BLC_TRB', 
                mc2047Route.NAME,
                 (rowData:any) => ({
                  id : rowData.doccleint,
                  tri: 1
            }), {
                icon: 'assessment',
                iconButton: false,
                hidden:(rowdata:any)=> !rowdata?.doccleint
            }),
            new MenuItemForage('LBL_BLC_TRF', 
                mc2048Route.NAME, 
                (rowdata: any) => ({
                id: rowdata.doccleint,
                tri: 1
            }), {
                icon: 'assessment',
                iconButton: false,
                hidden:(rowdata:any)=> !rowdata?.doccleint
            })
        ])
    };

    // Statut période de temps
    vm.tppMultiOccurrenceOptions = {
        bloc: 'tpp',
        colonnesVisibles: [
            { nom: 'ptaper', largeur: 85 },
            { nom: 'ptacod', largeur: 150 },
            { nom: 'ptadatdeb', largeur: 150 },
            { nom: 'vaetppstu_dsc', largeur: 175 }
        ],
        colonnesCachees: [
            'ptacleintcou',
            'modproflg'
        ],
        filtres: [
            {
                colonne: 'asgcleintflt',
                operateur: Operateur.EGALE,
                valeur: '0',
                data: {
                    unpcleint: $stateParams.id
                }
            }
        ],
        fonctions: {
            criteresSuggeresVisibles: false,
            rechercheCollapsable: false
        }
    };

    // Image
    vm.menuItemImage = new MenuItemActionImportation(
        'LBL_IMPORT_FICHIER',
        'BOUAJOIMG',
        () => ({}),
        {
            formulaireConfirmationAction: {
                lblTitre: 'LBL_IMPORT_FICHIER',
                formulaire: new Formulaire([
                    new FormulaireItem('muicleint', { default: () => vm.muiMultiOccurrence.id, hidden: true }),
                    new FormulaireItemSautDeLigne(),
                    new FormulaireItem('vaeimgtyp', { required: true }),
                    new FormulaireItem('vaeimgext', { default: 'JPG', hidden: true })
                ])
            },
            typesFichiers: ['.png', '.gif', '.jpg', '.jpeg', '.bmp'],
            successAction: () => vm.muiMultiOccurrence.fetchDataList(),
            icon: 'add_a_photo'
        }
    );

    vm.menuItemModifImage = new MenuItemActionImportation(
        'LBL_IMPORT_FICHIER',
        'BOUMODIMG',
        () => ({}),
        {
            formulaireConfirmationAction: {
                lblTitre: 'LBL_IMPORT_FICHIER',
                formulaire: new Formulaire([])
            },
            typesFichiers: ['.png', '.gif', '.jpg', '.jpeg', '.bmp'],
            icon: 'add_a_photo',
            champsRechargement: ['muicleint']
        }
    );

    vm.muiMultiOccurrenceOptions = {
        bloc: 'mui',
        actionsNouveaux: new Menu([
            vm.menuItemImage
        ]),
        colonnesVisibles: [
            { nom: 'vaeimgtyp_dsc', largeur: 200 }
        ],
        colonnesCachees: [
            'pigcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeimgtyp', { largeur: 100 })
        ])
    };
}
