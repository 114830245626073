import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from "../../../../../core/resources/ecran-details.resource";

interface IPageRr2012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceDraOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr2012Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,) {
    const vm: IPageRr2012 = this;

    vm.multiOccurrenceDraOptions = {
        bloc: 'dra',
        colonnesVisibles: [
            { nom: 'rcecod', largeur: 25 },
            { nom: 'rcedscabr', largeur: 125 },
            { nom: 'ppacod', largeur: 25 },
            { nom: 'ppadatdeb', largeur: 45 },
            { nom: 'ppadatfin', largeur: 25 },
            { nom: 'dramntemp', largeur: 25 },
            { nom: 'dramntemr', largeur: 25 },
            { nom: 'dernumdoc', largeur: 25 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ctecleint', { largeur: 25 }),
            new FormulaireItem('ctedsc', { largeur: 25, readonly: true }),
            new FormulaireItem('vaectecat', { largeur: 25, readonly: true }),
            new FormulaireItem('rtsdatvig', { largeur: 25 })
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'rcecod', largeur: 160 },
            { nom: 'rcedscabr', largeur: 360 },
            { nom: 'dammnt', largeur: 240 },
            { nom: 'damcom', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rcecleint'),
            new FormulaireItem('dammnt'),
            new FormulaireItem('damcom'),
        ]),
        fonctions: {
            rechercheCollapsable: false,
            nouveau: ecranDetails.valeurs.flgcre,
            edition: ecranDetails.valeurs.flgcre,
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceDraOptions
    };
}
