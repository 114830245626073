import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPageMc0015 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0015Controller(Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0015 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'sfucod', largeur: 100},
            {nom: 'sfudsc', largeur: 236},
            {nom: 'vaesfutyp', largeur: 183},
            {nom: 'sfuhreann', largeur: 110}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('sfucod', {largeur: 20}),
            new FormulaireItem('sfudsc', {largeur: 20}),
            new FormulaireItem('vaesfutyp', {largeur: 40}),
            new FormulaireItem('sfuhreann', {largeur: 40})
        ], { largeurDefaut: 20 })
    };
}
