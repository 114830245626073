import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageCr0016 extends IComponentController {
    formulaireCcl: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    valeursEcran: any;
    cacMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceOptionsCav: IMultiOccurrenceOptions;
    chfMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Cr0016Controller(
    cr0007Route: IRoute,
    cr0013Route: IRoute,
    cr2001Route: IRoute,
    cr1002Route: IRoute,
    MenuItemEcran: IMenuItemEcranClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    Menu: IMenuClass) {
    const vm: IPageCr0016 = this;

    vm.$onInit = $onInit;

    const multiOccurrenceOptionsCav: IMultiOccurrenceOptions = {
        bloc: 'cav',
        colonnesFixesGauche: [
            { nom: 'cavimp', largeur: 300 },
            { nom: 'cavmnt', largeur: 120 }
        ],
        colonnesVisibles: [
            { nom: 'cavqte', largeur: 120 },
            { nom: 'taxcod', largeur: 80 },
            { nom: 'cavmnttaxfed', largeur: 120 },
            { nom: 'cavmnttaxpro', largeur: 120 },
            { nom: 'cavseqave', largeur: 80 },
            { nom: 'cavdsc', largeur: 250 },
            { nom: 'cavmntrevnet', largeur: 120 }
        ]
    };

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            fonctions: {
                supprime: vm.valeursEcran.flgsup === 1
            },
            menus: [
                new MenuItemForage(
                    'LBL_CEDULE',
                    cr1002Route.NAME,
                    (data: any) => ({ id: data.cclcleint }),
                    {
                        icon: 'settings',
                        iconButton: false,
                        fonction: 'BTN_CEDULE',
                        hidden: (data: any) => !data.cclcleint
                    }
                )
            ]

        };

        vm.formulaireCcl = new Formulaire('LBL_BLC_CCL', [
            new FormulaireItem('cclnumdoc'),
            new FormulaireItem('refcleint', {
                resourceParams: {
                    type: 'CLI'
                }, readonly: vm.valeursEcran.flgmodoff === 0
            }),
            new FormulaireItem('radcleint'),
            new FormulaireItem('tydcleint', { modifAllow: false }),
            new FormulaireItem('cifcleint', { readonly: vm.valeursEcran.flgmodoff === 0 }),
            new FormulaireItem('cclflgpij'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cclnumref'),
            new FormulaireItem('ccldatdeb', { required: true, readonly: vm.valeursEcran.flgmod === 0 }),
            new FormulaireItem('ccldatfin'),
            new FormulaireItem('ccldsc', { largeur: 66.66, enableCount: true }),
            new FormulaireGroupeAccordeon('LBL_AUTRES_DETAILS', new Formulaire([
                new FormulaireItem('enecleint'),
                new FormulaireItem('moecleint'),
                new FormulaireItem('veccclcat'),
                new FormulaireItem('radcleintfac', { largeur: 66.66 }),
                new FormulaireItem('ccldscfac', { largeur: 66.66, enableCount: true }),
                new FormulaireItem('vaesysdevcod', { hidden: true })
            ], { largeurDefaut: 33.33 }))
        ], { largeurDefaut: 33.33 });

        // Contrat annuel
        vm.cacMultiOccurrenceOptions = {
            bloc: 'cac',
            actionsNouveaux: new Menu([
                new MenuItemForage('',
                    cr2001Route.NAME,
                    () => ({
                        cclcleint: vm.monoOccurrence.data.cclcleint
                    }),
                    {
                        icon: 'add_circle'
                    }
                )
            ]),
            colonnesFixesGauche: [
                { nom: 'cacnumdocseq', largeur: 100 },
                { nom: 'cacann', largeur: 80 }
            ],
            colonnesVisibles: [
                { nom: 'etddsc', largeur: 120 },
                { nom: 'cacmnt', largeur: 120 },
                { nom: 'cacmntfacacj', largeur: 120 },
                { nom: 'cacseqave', largeur: 80 },
                { nom: 'cacmntrevnet', largeur: 120 },
                { nom: 'intcod', largeur: 100 },
                { nom: 'intnom', largeur: 200 },
                { nom: 'cacdateff', largeur: 126 },
                { nom: 'peccod', largeur: 100 }
            ],
            colonnesCachees: [
                'doccleint'
            ],
            details: { lblTitre: 'LBL_BLC_CAV', multiOccurrenceOptions: multiOccurrenceOptionsCav },
            actionsRangeeDroite: new Menu([
                new MenuItemForage(
                    'LBL_DETAIL',
                    cr2001Route.NAME,
                    (data: any) => ({
                        id: data.doccleint
                    }),
                    {
                        icon: 'exit_to_app',
                        fonction: 'BOUDOC',
                        iconButton: true
                    }
                )
            ])
        };
        // Historique
        vm.chfMultiOccurrenceOptions = {
            bloc: 'chf',
            fonctions: {
                suiviModification: false
            },
            colonnesVisibles: [
                { nom: 'cacnumdocseq', largeur: 60 },
                { nom: 'cacann', largeur: 80 },
                {
                    nom: 'docaffnumdoc01',
                    largeur: 120,
                    menu: [new MenuItemForage(
                        'LBL_FOR_REF',
                        (rowData: any) => getForage(rowData),
                        (rowData: any) => {
                            return {
                                id: rowData.doccleint
                            };
                        }
                    )]
                },
                { nom: 'tydcod', largeur: 100 },
                { nom: 'datcre', largeur: 126 },
                { nom: 'peccod', largeur: 100 },
                { nom: 'etddsc', largeur: 120 },
                { nom: 'chfmnt', largeur: 120 },
                { nom: 'docaffdsc01', largeur: 250 }
            ],
            colonnesCachees: [
                'doccleint',
                'ecrcod'
            ]
        };
    };

    function getForage(rowData: any): string {
        switch (rowData.ecrcod) {
            case 'cr0013': return cr0013Route.NAME;
            case 'cr0007': return cr0007Route.NAME;
            default: return null;
        }
    }

}
