import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IController } from 'angular';
interface IPageCr0047 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCgtOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr0047Controller() {
    const vm: IPageCr0047 = this;

    vm.multiOccurrenceCgtOptions = {
        bloc: 'cgt',
        fonctions: {
            nouveau: true
        },
        colonnesVisibles: [
            { nom: 'cgttrim', largeur: 25 },
            { nom: 'cgtstu', largeur: 25 },
            { nom: 'cgtmoideb', largeur: 25 },
            { nom: 'cgtmoifin', largeur: 25 },
            { nom: 'cgtnbrmoi', largeur: 25 },
            { nom: 'cgtgra', largeur: 25 },
            { nom: 'cgtfra', largeur: 25 }
        ]
    };

    vm.multiOccurrenceOptions = {

        colonnesVisibles: [
            { nom: 'cldcod', largeur: 110 },
            { nom: 'cgeann', largeur: 110 },
            { nom: 'cgegraetu', largeur: 110 },
            { nom: 'cgemtc', largeur: 110 },
            { nom: 'cgemtp', largeur: 110 },
            { nom: 'cgederpro', largeur: 200 },
            { nom: 'cgeflgoff', largeur: 110 }
        ],
        fonctions: {
            rechercheCollapsable: false,
        },
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCgtOptions
    };
}
