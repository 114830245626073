import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageGb0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gb0008Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageGb0008 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'gcbcod', largeur: 10 },
            { nom: 'gcbdsc', largeur: 70 },
            { nom: 'gcbflgoeu', largeur: 10 },
            { nom: 'gcbflgact', largeur: 10 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('gcbcod', { largeur: 5, maxlength: 4 }),
            new FormulaireItem('gcbdsc', { largeur: 40 }),
            new FormulaireItem('gcbflgoeu', { largeur: 10 }),
            new FormulaireItem('gcbflgact', { largeur: 10 })
        ])
    };
}
