import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMr0230 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0230Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass, FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMr0230 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        formulaire: new Formulaire(
            [
                new FormulaireItem('sadcod'),
                new FormulaireItem('saddsc'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('sadflgsal', { default: 0 }),
                new FormulaireItem('sadflgavs', { default: 0 }),
                new FormulaireItem('sadflgact', { default: 1 })
            ],
            { largeurDefaut: 25 }
        ),
        colonnesVisibles: [
            { nom: 'sadcod', largeur: 120 },
            { nom: 'saddsc', largeur: 300 },
            { nom: 'sadflgsal', largeur: 91 },
            { nom: 'sadflgavs', largeur: 91 },
            { nom: 'sadflgact', largeur: 91 }
        ]
    };
}
