import IResourceArray = angular.resource.IResourceArray;
import {IPromise, IQService, module} from 'angular';
import {IMultiOccurrence} from '../../../../core/services/multi-occurrence.service';
import {IMenu, IMenuClass, MenuElement} from '../../../../core/services/menu/menu.service';
import {IMenuItem, IMenuItemClass} from '../../../../core/services/menu/menu-item.service';
import {IMenuMessage, IMenuMessageClass} from '../../../../core/services/menu/menu-message.service';
import {IMenuSectionClass} from '../../../../core/services/menu/menu-section.service';
import {IMenuDividerClass} from '../../../../core/services/menu/menu-divider.service';
import {IMenuErreurClass} from '../../../../core/services/menu/menu-erreur.service';
import {IApiError} from '../../../../core/interfaces/api-error.interface';
import {
    IActionPossibleFinance,
    ICycleFinanceClass,
    IProchainEtatFinance
} from '../../../../core/resources/cycle-finance.resource';
import {IParametresSecuriteService} from '../../../../core/services/parametres-securite.service';
import {IFormulaireClass} from '../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IDialog} from '../../../../core/services/dialog.service';
import {INotificationHandler} from '../../../../core/services/utils/notification-handler.service';
import {IDialogFormulaireOptions} from '../../../../core/dialogs/dialog-formulaire/dialog-formulaire.controller';
import {IMonoOccurrenceClass} from '../../../../core/services/mono-occurrence.service';
import {IDialogAllStatus} from '../../../../core/constants/dialog.constant';

export default module('app.services.menu-actions-finance', []).factory('MenuActionsFinance', MenuActionsFinanceFactory);

export interface IMenuActionsFinanceService {
    new(multiOccurrence: IMultiOccurrence): IMenuActionsFinance;
}

export interface IMenuActionsFinance {
    getMenu(): IMenu;
    fetchProchainEtat(): IPromise<IMenuItem | IMenuMessage>;
    fetchAutresActionsPossibles(): IPromise<Array<IMenuItem | IMenuMessage>>;
}

/* @ngInject */
function MenuActionsFinanceFactory($q: IQService,
                                   MonoOccurrence: IMonoOccurrenceClass,
                                   Menu: IMenuClass,
                                   MenuItem: IMenuItemClass,
                                   MenuSection: IMenuSectionClass,
                                   MenuDivider: IMenuDividerClass,
                                   MenuMessage: IMenuMessageClass,
                                   MenuErreur: IMenuErreurClass,
                                   Formulaire: IFormulaireClass,
                                   FormulaireItem: IFormulaireItemClass,
                                   FormulaireItemDivider: IFormulaireItemDividerClass,
                                   FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                   DialogFormulaire: IDialog,
                                   notificationHandler: INotificationHandler,
                                   CycleFinance: ICycleFinanceClass,
                                   parametresSecurite: IParametresSecuriteService,
                                   DialogStatus: IDialogAllStatus) {

    class MenuActionsFinance implements IMenuActionsFinance {
        private readonly multiOccurrence: IMultiOccurrence;
        private readonly actionsMenuElements: Array<MenuElement> = [];

        private selectedRows: Array<any>;
        private firstSelectedRowsDoccleint: number;
        private selectedRowsDoccleint: Array<number>;
        private prochainEtat: IProchainEtatFinance = null;
        private actionsPossibles: IResourceArray<IActionPossibleFinance> = null;

        constructor(multiOccurrence: IMultiOccurrence) {
            this.multiOccurrence = multiOccurrence;
        }

        getMenu() {
            return new Menu('G_LBL_BTN_ACTIONS', this.actionsMenuElements, {
                icon: 'device_hub',
                // Le menu est bâtit dynamiquement lorsque le menu s'ouvre
                onOpen: () => {
                    return this.batirMenuActions();
                }
            });
        }

        /**
         * Bâtit le menu basé sur la sélection du Multi Occurrence
         */
        private batirMenuActions() {
            this.selectedRows = this.multiOccurrence.getSelectedRows();
            this.selectedRowsDoccleint = this.selectedRows.map(row => row.doccleint);
            this.firstSelectedRowsDoccleint = this.selectedRowsDoccleint[0];

            return this.validerSelection()
                .then(() => {
                    return this.obtenirMenuData();
                })
                .then((result: { prochainEtat: MenuElement, actionsPossibles: Array<MenuElement> }) => {
                    // On met à jour les actions en place
                    const menuItems = this.getMenuElements(result.prochainEtat, result.actionsPossibles);
                    // On met à jour le menu en place
                    this.actionsMenuElements.splice(0, this.actionsMenuElements.length, ...menuItems);
                });
        }

        /**
         * Valide la sélection des rangées
         */
        private validerSelection() {
            const etats = this.selectedRows.map((etat) => etat.doccleint).join(',');

            const params = {
                ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                etats,
                ids: this.multiOccurrence.getParentIds().join(","),
                srccod: this.multiOccurrence.getNomSourceDetails(),
                ...parametresSecurite(this.multiOccurrence.stateParams)
            };

            return CycleFinance.validateEtats(params).$promise
                .catch((error: IApiError) => {
                    return $q.reject({
                        error,
                        lblTitre: 'G_LBL_FSA_CYCLE_EVENT',
                        lblMessage: 'G_MSG_ERREUR_VALIDATION_SELECTION'
                    });
                })
                .then((result: { erreur: string }) => {
                    if (result.erreur) {
                        return $q.reject({
                            error: result,
                            lblTitre: 'G_LBL_FSA_CYCLE_EVENT',
                            lblMessage: result.erreur,
                            // Dans le cas de cette erreur, il ne sert à rien de réessayer puisqu'il y a des erreurs sur la
                            // transaction.
                            confirmAction: null
                        })
                    }
                });
        }

        /**
         * Obtient les données du menu du serveur afin de bâtir le menu
         */
        private obtenirMenuData() {
            return $q.all({
                prochainEtat: this.fetchProchainEtat(),
                actionsPossibles: this.fetchAutresActionsPossibles()
            })
        }

        /**
         * Retourne les éléments du menu
         */
        private getMenuElements(prochainEtat: MenuElement, actionsPossibles: Array<MenuElement>) {
            return [
                new MenuSection('G_LBL_PROCHAIN_ETAT'),
                prochainEtat,
                new MenuDivider(),
                new MenuSection('G_LBL_AUTRES_ACTIONS_POSS'),
                ...actionsPossibles
            ];
        }

        /**
         * Obtient le prochain état du serveur et retourne le menu item
         */
        fetchProchainEtat() {
            const params = {
                ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                doccleint: this.firstSelectedRowsDoccleint,
                srccod: this.multiOccurrence.getNomSourceDetails(),
                ids: this.multiOccurrence.getParentIds().join(","),
                ...parametresSecurite(this.multiOccurrence.stateParams)
            };

            return CycleFinance.getProchainEtat(params).$promise
                .then((prochainEtat: IProchainEtatFinance) => {
                    this.prochainEtat = prochainEtat;

                    return this.getProchainEtatMenuItem();
                })
                .catch(() => new MenuErreur('G_MSG_ERREUR_CHARG_PROCHAIN_ETAT'));
        }

        /**
         * Retourne l'élément du menu pour le prochain état
         */
        private getProchainEtatMenuItem() {
            if (this.prochainEtat && this.prochainEtat.cleintpro && this.prochainEtat.acces !== 0) {
                return new MenuItem(this.prochainEtat.dsceta, (event: MouseEvent, hideMenu: () => void) => {
                    hideMenu();
                    return this.executeProchainEtat();
                }, {emphase: true});
            } else {
                return new MenuMessage('G_MSG_AUCUN_PROCHAIN_ETAT');
            }
        }

        private showModalConfirmation(vaecypact: string, docflgaih: boolean, confirmAction: (data?: any) => IPromise<any>) {
            const dialogFormulaireOptions: IDialogFormulaireOptions = {
                lblTitre: 'LBL_TITRE',
                icon: 'device_hub',
                data: {
                    flgselmul: this.selectedRowsDoccleint.length > 1 ? 1 : 0,
                    vaecypact,
                    docflgaih
                },
                confirmAction: confirmAction,
                occurrence: new MonoOccurrence({
                    bloc: 'NOOP',
                    stateParams: this.multiOccurrence.stateParams,
                    ecranDetails: this.multiOccurrence.ecranDetails,
                    ecranSourceDetails: this.multiOccurrence.ecranSourceDetails,
                    srccod: 'mc1001'
                }),
                showConfirmAction: true,
                largeur: 40,
                formulaire: new Formulaire([
                    new FormulaireItem('peccleint', {largeur: 50}),
                    new FormulaireItemDivider('LBL_DIV_COM'),
                    new FormulaireItem('com', {largeur: 100}),
                    new FormulaireItem('flgdem', {largeur: 100}),
                    new FormulaireItem('flgini'),
                    new FormulaireItem('flgreq'),
                    new FormulaireItem('flgapr'),
                    new FormulaireItem('intcleint', {largeur: 50}),
                    new FormulaireItem('adrcou', {largeur: 50})
                ], {
                    largeurDefaut: 33
                })
            };
            const dialogOptions = {
                locals: dialogFormulaireOptions,
                closeOnError: true,
                multiple: true
            };
            return DialogFormulaire.show(dialogOptions)
                .catch((err: any) => {
                    if (err !== DialogStatus.FERMER) {
                        notificationHandler.erreur(err);
                    }
                });
        }

        private executeProchainEtat() {
            return this.showModalConfirmation(this.prochainEtat.vaecyeact, this.prochainEtat.docflgaih, (formData: any) => {
                const params = {
                    ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                    srccod: this.multiOccurrence.getNomSourceDetails(),
                    ids: this.multiOccurrence.getParentIds().join(","),
                    ...parametresSecurite(this.multiOccurrence.stateParams)
                };

                const action = {
                    doccleint: this.selectedRowsDoccleint,
                    eclcleint: this.prochainEtat.cleintpro,
                    vaecyeact: this.prochainEtat.vaecyeact,
                    ...formData
                };

                return CycleFinance.executeAction(params, action).$promise
                    .then(() => {
                        // On ne retourne pas afin d'éviter de mettre l'action en erreur
                        this.multiOccurrence.fetchDataList();
                    });
            });
        }

        fetchAutresActionsPossibles() {
            const params = {
                ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                doccleint: this.firstSelectedRowsDoccleint,
                srccod: this.multiOccurrence.getNomSourceDetails(),
                ids: this.multiOccurrence.getParentIds().join(","),
                ...parametresSecurite(this.multiOccurrence.stateParams)
            };

            return CycleFinance.queryActionsPossibles(params).$promise
                .then((actionsPossibles: IResourceArray<IActionPossibleFinance>) => {
                    this.actionsPossibles = actionsPossibles;

                    return this.getAutresActionsPossiblesMenuItems();
                })
                .catch(() => [new MenuErreur('G_MSG_ERREUR_CHARG_ACTIONS_POSSIBLES')]);
        }

        /**
         * Retourne les éléments du menu pour les actions possibles
         */
        private getAutresActionsPossiblesMenuItems() {
            if (this.actionsPossibles.length) {
                return this.actionsPossibles.map((actionPossible: IActionPossibleFinance) => {
                    return new MenuItem(actionPossible.vaedsc, (event: MouseEvent, hideMenu: () => void) => {
                        hideMenu();
                        return this.executeAutreActionPossible(actionPossible)
                    });
                });
            } else {
                return [new MenuMessage('G_MSG_AUCUNE_ACTION_POSSIBLE')];
            }
        }

        private executeAutreActionPossible(actionPossible: IActionPossibleFinance) {
            return this.showModalConfirmation(actionPossible.vaecypact, actionPossible.docflgaih, (formData: any) => {
                const params = {
                    ...this.multiOccurrence.etatSelected.criteresSuggeresData,
                    srccod: this.multiOccurrence.getNomSourceDetails(),
                    ids: this.multiOccurrence.getParentIds().join(","),
                    ...parametresSecurite(this.multiOccurrence.stateParams)
                };

                const action = {
                    doccleint: this.selectedRowsDoccleint,
                    vaecypact: actionPossible.vaecypact,
                    ...formData
                };

                return CycleFinance.executeAction(params, action).$promise
                    .then(() => {
                        // On ne retourne pas afin d'éviter de mettre l'action en erreur
                        this.multiOccurrence.fetchDataList();
                    });
            });
        }
    }

    return MenuActionsFinance;
}
