import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageCh0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Ch0004Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageCh0004 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        colonnesVisibles: [
            {nom: 'itecod', largeur: 120},
            {nom: 'itedsc', largeur: 230},
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 230},
            {nom: 'etqcod', largeur: 120},
            {nom: 'etqdsc', largeur: 230}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('itecleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('demcleint', {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etqcleint', {largeur: 50})
            ]
        )
    };
}
