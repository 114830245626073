import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageMR0318 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0318Controller(mr0131Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageMR0318 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: mr0131Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(mr0131Route.NAME)
        ]),
        colonnesFixesGauche: [
            {nom: 'pafdatvig'   , largeur: 130}
        ],
        colonnesVisibles: [
            {nom: 'paftd1mntbas', largeur: 170},
            {nom: 'pafmntrn', largeur: 170},
            {nom: 'paftd1mntrpl', largeur: 170},
            {nom: 'pafmntrnmax', largeur: 170},
            {nom: 'pafimflimlcf', largeur: 140},
            {nom: 'pafimfprclcf', largeur: 140},
            {nom: 'pafrpctau'   , largeur: 140},
            {nom: 'pafrpcexm'   , largeur: 140},
            {nom: 'pafrpcmga'   , largeur: 150},
            {nom: 'pafmntcreemp', largeur: 140}
        ]
    };
}
