import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePT0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt0006Controller(Menu: IMenuClass) {
    const vm: IPagePT0006 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 200},
            {nom: 'eplnomprn', largeur: 200},
            {nom: 'pdvcod', largeur: 200},
            {nom: 'pdvdsc', largeur: 200},
            {nom: 'hopnumdoc', largeur: 200},
            {nom: 'hopcom', largeur: 200},
            {nom: 'dpbdatdeb', largeur: 200},
            {nom: 'dpbdatfin', largeur: 200},
            {nom: 'dpbdattom', largeur: 200},
            {nom: 'dpbflg12h', largeur: 200}
        ]
    };
}
