import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePr0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0008Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0008 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'clacod', largeur: 110},
            {nom: 'cladsc', largeur: 210},
            {nom: 'cladatmod', largeur: 120},
            {nom: 'clasec', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('clacod', {required: true}),
            new FormulaireItem('cladsc'),
            new FormulaireItem('cladatmod'),
            new FormulaireItem('clasec')
        ], {largeurDefaut: 25}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true,
            rechercheCollapsable: false
        }
    };
}
