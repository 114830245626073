import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'cb2009',
    ecrans: ['00-2'],
    params: ['?type', 
             '?prucleint', 
             '?prbcleint', 
             '?prvcleint', 
             '?el1cleint',
             '?soncleint',
             '?cptcleint',
             '?vaesysnatctb', 
             '?tymcleint', 
             '?doccleintref', 
             '?pecann',
             '?elecleint', 
             '?niveau', 
             '?peccleintdeb', 
             '?peccleintfin']    
});
