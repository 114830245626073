import { IComponentController } from 'angular';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageAc0062 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
}

interface IAc0062Params {
    foucleint: string;
}

/* @ngInject */
export default function Ac0062Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IAc0062Params
) {

    const vm: IPageAc0062 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_LCOP', [
            new FormulaireItem('foucleint', { default: $stateParams.foucleint }),
            new FormulaireItem('vecforjur'),
            new FormulaireItem('aflneq'),
            new FormulaireItem('aflflgact', { default: 1 })
        ], { largeurDefaut: 50 })
    };
}

