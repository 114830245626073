import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0218 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0218Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMr0218 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'temcod', largeur: 110 }
        ],
        colonnesVisibles: [
            { nom: 'temdscabr', largeur: 200 },
            { nom: 'temflgetu', largeur: 110 },
            { nom: 'vaenivsco_dsc', largeur: 230 },
            { nom: 'temflguno', largeur: 110 },
            { nom: 'grecod', largeur: 130 },
            { nom: 'gredsc', largeur: 170 },
            { nom: 'temcodref', largeur: 150 },
            { nom: 'temdscabrref', largeur: 200 }
        ],
        colonnesFixesDroite: [
            { nom: 'temflgfdsres', largeur: 94 },
            { nom: 'temflgats', largeur: 104 },
            { nom: 'temflgact', largeur: 74 }
        ],

        formulaire: new Formulaire([
            new FormulaireItem('temcod', { largeur: 15, readonly: true }),
            new FormulaireItem('temdscabr', { largeur: 35, readonly: true }),
            new FormulaireItem('temflgfdsres', { largeur: 35 }),
            new FormulaireItem('temflgats', { largeur: 15 })
        ]),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
