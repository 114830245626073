import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFilterDate} from 'angular';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemSautDeColonneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service';
import {IMenuItemFactoryClass} from '../../../../../core/services/menu/menu-item-factory.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IFilterExDate} from "../../../../../core/filters/ex-date.filter";

interface IPageRR0129 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    bacDetMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

interface IRr0129Params {
    employe: string;
    demcleint: number;
    bqecleint: number;
    ppadatdeb: string;
    ppadatfin: string;
}

/* @ngInject */
export default function Rr0129Controller(Menu: IMenuClass,
                                         rr0065Route: IRoute,
                                         rr0503Route: IRoute,
                                         exDateFilter: IFilterExDate,
                                         Formulaire: IFormulaireClass,
                                         MenuItemFactory: IMenuItemFactoryClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         dateFilter: IFilterDate,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         $stateParams: IRr0129Params,
                                         Operateur: IOperateurService,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageRR0129 = this;
    const criteresSuggeresVisibles = ($stateParams.employe || $stateParams.demcleint) ? false : undefined;
    //const criteresSuggeresVisibles = !$stateParams.employe && !$stateParams.demcleint;
    vm.valeursEcran = ecranDetails.valeurs;

    const forageEcran = new MenuItemForage(
        'LBL_FOR_TYP',
        (data: any) => getForage(data),
        (data: any) => getParams(data),
        { hidden: (data: any) => !data.pamcleint && !data.bqacleint}
    );

    const accueilRoute = {NAME: 'secure.accueil'};

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false,
            criteresSuggeresVisibles
        },
        criteresSuggeres: new Formulaire([
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('t_demcleint', {
                    hidden:  !!$stateParams.employe,
                    ignoreEtat: Boolean($stateParams.employe || $stateParams.demcleint),
                    default: $stateParams.employe || $stateParams.demcleint,readonly:true,
                    largeur: 90
                }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('t_bqecleint',{largeur: 90,
                    ignoreEtat: !!($stateParams.bqecleint),
                    default: $stateParams.bqecleint,readonly:true}),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('t_datdeb',{largeur: 50,
                    ignoreEtat: !!($stateParams.ppadatdeb),
                    default: $stateParams.ppadatdeb,readonly:true}),
                new FormulaireItem('t_datfin',{
                    required: true,
                    largeur: 50,
                    ignoreEtat: !!($stateParams.employe || $stateParams.demcleint),
                    default: exDateFilter($stateParams.ppadatfin || vm.valeursEcran.t_datfin) && $stateParams.ppadatfin,readonly:true
                })

            ]), {largeur: 50}),
            new FormulaireGroupe(new Formulaire([
                new FormulaireItem('t_flgfer',{
                    default: 0
                }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('t_flgrecalcul',{
                    default: 1
                }),
                new FormulaireItemSautDeLigne(),

                new FormulaireItem('t_flgbqedemtous',{
                    default: 0
                })
            ],{largeurDefaut: 90}), {largeur: 50})
        ]),

        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_demcleint || !!$stateParams.employe},
            {nom: 'eplnomprn', largeur: 275, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_demcleint || !!$stateParams.employe},
            {nom: 'bqecod'   , largeur: 110, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_bqecleint},
            {nom: 'bqedscabr', largeur: 275, hidden: () => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_bqecleint},
            {nom: 'dbscod1'  , largeur: 120},
            {nom: 'dbscod2'  , largeur: 120},
            {nom: 'urgcod'   , largeur: 110},
            {nom: 'unpcod'   , largeur: 120},
            {nom: 'dbcqte'   , largeur: 110},
            {nom: 'dbsdsc1'  , largeur: 275},
            {nom: 'dbsdsc2'  , largeur: 275},
            {nom: 'urgdscabr', largeur: 275},
            {nom: 'unpdsc'   , largeur: 275}
        ],
        colonnesFixesDroite: [
            {nom: 'dbcqteaff', largeur: 145},
            {nom: 'bqeafflib', largeur: 145}
        ],
        colonnesCachees: ['bqecleint']

    };

    vm.bacDetMultiOccurrenceOptions = {
        bloc: 'bacdet',
        fonctions: {
            suiviModification: false
        },
        forcerEtatPredefinisDepart: 'PPA',
        etatsPredefinis: [
            {
                nom: 'PPA',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        valeur: 'PPA'
                    }
                ],
                colonnesVisibles: [
                    'ppacod'   ,
                    'vaesystrsbqe_dsc',
                    'stamnetab_dsc',
                    'qtetrs',
                    'sldcum'
                ]
            },
            {
                nom: 'DAT',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        valeur: 'DAT'
                    }
                ],
                colonnesVisibles: [
                    'dattrs'   ,
                    'vaesystrsbqe_dsc',
                    'stamnetab_dsc',
                    'qtetrs',
                    'sldcum'
                ]
            },
            {
                nom: 'DET',
                filtres: [
                    {
                        colonne: 'axe',
                        operateur: Operateur.EGALE,
                        valeur: 'DET'
                    }
                ],
                colonnesVisibles: [
                    'ppacod'   ,
                    'dattrs'   ,
                    'vaesystrsbqe_dsc',
                    'stamnetab_dsc',
                    'qtetrs',
                    'sldcum',
                    'numdoc'
                ]
            }
        ],
        colonnesVisibles: [
            {nom: 'ppacod'           , largeur: 130},
            {nom: 'dattrs'           , largeur: 100},
            {nom: 'vaesystrsbqe_dsc' , largeur: 225},
            {nom: 'stamnetab_dsc'    , largeur: 225},
            {nom: 'qtetrs'           , largeur: 140},
            {nom: 'sldcum'           , largeur: 140},
            {nom: 'numdoc'           , largeur: 165,menu: [forageEcran]}
        ],
        colonnesCachees: [
            'bqacleint',
            'pamcleint'
        ],
    };

    function getForage(data: any): string {
        if (data.bqacleint) {
            return rr0065Route.NAME;
        } else if (data.pamcleint) {
            return rr0503Route.NAME;
        } else {
            return accueilRoute.NAME;
        }
    }

    function getParams(data: any) {
        if (data.bqacleint) {
            return { bqacleint: data.bqacleint };
        } else {
            return { id: data.pamcleint }
        }
    }

}
