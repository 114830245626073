import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPagePr0017 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pr0017Controller(Formulaire: IFormulaireClass,
    cr0005Route: IRoute,
    Menu: IMenuClass, 
    MenuItemForage: IMenuItemForageClass,  
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePr0017 = this;

    vm.multiOccurrenceOptions = {
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                cr0005Route.NAME,
                (rowData:any) => ({
                    id: null,
                    orgcleint: rowData.orgcleint
                }),
                {
                    icon: 'add_box',
                    directionTooltip: 'BOTTOM'
                }
            )            
        ]),
        colonnesVisibles: [
            { nom: 'orgcod', largeur: 125 },
            { nom: 'orgnomabr', largeur: 215 },
            { nom: 'oppdsc', largeur: 165 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', {
                largeur: 66.66,
                resourceParams: {
                    type: 'CLI'
                }})
        ],
            {
                largeurDefaut: 30
            }),
        fonctions: {
            rechercheCollapsable: false
        }
    };
}
