import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0022 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0022Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0022 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'pdicod', largeur: 112},
            {nom: 'pdidscabr', largeur: 330},
            {nom: 'pdidsc', largeur: 330},
            {nom: 'cptcod', largeur: 115},
            {nom: 'cptdsc', largeur: 230},
            {nom: 'pdiflgact', largeur: 64}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pdicod'),
            new FormulaireItem('pdidscabr', {required: true}),
            new FormulaireItem('pdidsc', {required: true, largeur: 50}),
            new FormulaireItem('cptcleint', {required: true}),
            new FormulaireItem('pdiflgact', {default: 1})
        ], {largeurDefaut: 25}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
