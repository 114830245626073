import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePD0005 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pd0005Controller(Menu: IMenuClass) {
    const vm: IPagePD0005 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'dacnumdoc', largeur: 200},
            {nom: 'dacnumdocseq', largeur: 200},
            {nom: 'sysboncmd', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'dacdsc', largeur: 200},
            {nom: 'etddsc', largeur: 200},
            {nom: 'pmicod', largeur: 200},
            {nom: 'pmidsc', largeur: 200},
            {nom: 'refcod', largeur: 200},
            {nom: 'refnom', largeur: 200},
            {nom: 'founomabr', largeur: 200},
            {nom: 'flgcou', largeur: 200},
            {nom: 'flgnonrec', largeur: 200},
            {nom: 'flgfca', largeur: 200},
            {nom: 'peccod', largeur: 200},
            {nom: 'vaesysdevcod', largeur: 200},
            {nom: 'dacmnt', largeur: 200},
            {nom: 'caacod', largeur: 200},
            {nom: 'caadsc', largeur: 200},
            {nom: 'etcnumdoc', largeur: 200},
            {nom: 'etcnumdocseq', largeur: 200},
            {nom: 'etcdsc', largeur: 200},
            {nom: 'intcodach', largeur: 200},
            {nom: 'intnomach', largeur: 200},
            {nom: 'intcoddem', largeur: 200},
            {nom: 'intnomdem', largeur: 200},
            {nom: 'intcodreq', largeur: 200},
            {nom: 'intnomreq', largeur: 200},
            {nom: 'vecdaccat', largeur: 200},
            {nom: 'flgerr_b2b', largeur: 200},
            {nom: 'flgadhoc', largeur: 200},
            {nom: 'intcodcib', largeur: 200},
            {nom: 'intnomcib', largeur: 200},
            {nom: 'usrcod', largeur: 200},
            {nom: 'motcod', largeur: 200},
            {nom: 'motdsc', largeur: 200},
            {nom: 'flgtrans', largeur: 200},
            {nom: 'flgimp', largeur: 200},
            {nom: 'dacdat', largeur: 200},
            {nom: 'dacdatbc', largeur: 200},
            {nom: 'dacdatreq', largeur: 200},
            {nom: 'dacdatree', largeur: 200},
            {nom: 'dacdatdeb', largeur: 200},
            {nom: 'dacdatfin', largeur: 200},
            {nom: 'sldeng', largeur: 200},
            {nom: 'dacnumcon', largeur: 200},
            {nom: 'docnumref1', largeur: 200},
            {nom: 'docnumref2', largeur: 200},
            {nom: 'docnumref3', largeur: 200},
            {nom: 'docnumref4', largeur: 200},
            {nom: 'docnumref5', largeur: 200}
        ]
    };
}
