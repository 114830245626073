import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';


interface IPageRr2015 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function Rr2015Controller(Formulaire: IFormulaireClass, 
    MenuItemAction: IMenuItemActionClass, Menu: IMenuClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr2015 = this;
    let rechargementTableTemporaire = 1;
    const exclusionAction = new MenuItemAction('LBL_BTN_EXC', 'BOUEXCLUS', () => ({}), {
        icon: 'create',
        successAction: () => {
            rechargementTableTemporaire = 0;
            vm.multiOccurrence?.fetchDataList();
            rechargementTableTemporaire = 1;
        }
    });

    vm.multiOccurrenceOptions = {
        fonctions: {
            criteresSuggeresVisibles: true
        },
        actions: new Menu([
            new MenuItemAction('LBL_BTN_GENERER', 'BOUGEN', () => ({}), {
                iconButton: false,
                class: 'ex-button-action md-raised',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_COM',
                    formulaire: new Formulaire([
                        new FormulaireItem('dpzcom', { required: true, enableCount: true }) ])
                },
                disabled:(rowData: any ) =>  !rowData.eplnummat && ( vm?.multiOccurrence?.dataList?.length === 0 || vm?.multiOccurrence?.dataList === undefined) ,
                successAction: () => {
                    rechargementTableTemporaire = 0;
                    vm.multiOccurrence?.fetchDataList();
                    rechargementTableTemporaire = 1;
                }
            })
        ]),
        resourceParamsDynamique: () => {
            return { rechargementtabletemporaire: rechargementTableTemporaire };
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('vaedpztyp', {
                required: true,
                largeur: 25,
                ignoreEtat: true
            }),
            new FormulaireItem('datvig', {
                required: true,
                largeur: 25,
                ignoreEtat: true
            }),
            new FormulaireItem('lov', {
                hidden: (data) => data.vaedpztyp === 'RCE' || data.vaedpztyp === 'TAG' || data.vaedpztyp === 'AVI',
                required: (data) => data.vaedpztyp !== 'RCE' && data.vaedpztyp !== 'TAG' && data.vaedpztyp !== 'AVI',
                largeur: 50
            }),
            new FormulaireItem('tagcleint', {
                hidden: (data) => data.vaedpztyp !== 'AVI', required: (data) => data.vaedpztyp === 'AVI',
                largeur: 50,
                ignoreEtat: true
            }),
            new FormulaireItem('tagcleint1', {
                hidden: (data) => data.vaedpztyp !== 'TAG', required: (data) => data.vaedpztyp === 'TAG',
                largeur: 50,
                ignoreEtat: true
            }),
            new FormulaireItem('rcecleint', {
                hidden: (data) => data.vaedpztyp !== 'RCE', required: (data) => data.vaedpztyp === 'RCE',
                largeur: 50,
                ignoreEtat: true
            }),
            new FormulaireItem('unpcleint', { largeur: 50, ignoreEtat: true }),
            new FormulaireItem('urgcleint', { largeur: 50, ignoreEtat: true })
        ],
        ),        
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100 },
            { nom: 'eplnomprn', largeur: 262 },
            { nom: 'dpzdatvig', largeur: 100 },
            { nom: 'dpzflgexc', hidden: true, largeur: 100, menu: [exclusionAction] },
            { nom: 'dpzflgtrt', hidden: true, largeur: 90 }
        ],
        colonnesCachees: ['dpzcleint', 'demcleint']
    };
    
}
