import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IOperateurService} from '../../../../../core/services/operateur.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IFilterDate} from 'angular';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';

interface IPageAL0013 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Al0013Controller(MenuItemForage: IMenuItemForageClass,
                                         Menu:IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         Operateur: IOperateurService,
                                         dateFilter: IFilterDate,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         $stateParams: IStateParamsService,
                                         parametresRecherche: IParametresRechercheService) {
    const vm: IPageAL0013 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            { nom: 'upatit', largeur: 300 },
            { nom: 'vacalityp_desc', largeur: 300 },
            { nom: 'vaeupaseq_desc', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('upatit', { largeur: 20 }),
            new FormulaireItem('vacalityp', { largeur: 10 }),
            new FormulaireItem('vaeupaseq', { largeur: 10 })
        ])
    };
}
