import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageGS0026 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0026Controller(gs0004Route: IRoute,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageGS0026 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: gs0004Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(gs0004Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'ptlcod', largeur: 185},
            {nom: 'ptltyp', largeur: 185},
            {nom: 'ptltypdon', largeur: 185},
            {nom: 'ptllngdon', largeur: 105},
            {nom: 'ptldecdon', largeur: 97},
            {nom: 'ptllab', largeur: 185},
            {nom: 'ptlaid', largeur: 185}
        ]
    };
}
