import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from "../../../../../core/services/formulaire/formulaire-item.service";
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";

interface IPageMc0086 extends IComponentController{
    formulaireCri: IFormulaire;
    formulaireFedCri: IFormulaire;
    formulaireFedTra: IFormulaire;
    formulaireFedCon: IFormulaire;
    formulaireProCri: IFormulaire;
    formulaireProTra: IFormulaire;
    formulaireProCon: IFormulaire;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */

export default function Mc0086Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {

    const vm: IPageMc0086 = this;
    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireCri = new Formulaire('LBL_BLC_CIE', [
            new FormulaireItem('ciecod'),
            new FormulaireItem('cienom')
        ]);

        vm.formulaireFedCri = new Formulaire('LBL_BLC_CRI', [
            new FormulaireItem('crinument', { largeur: 33}), //nom de l'entreprise
            new FormulaireItem('cricodpay', { largeur: 33 }), //pays
            new FormulaireItem('crinbrperpai', { largeur: 33 }), //nbrs de periode de paye
        ]);

        vm.formulaireFedTra = new Formulaire('LBL_BLC_TRA', [
            new FormulaireItem('crinumtra', { largeur: 33}),
            new FormulaireItem('vaecrityptra', { largeur: 33}),
            new FormulaireItem('critranom', { largeur: 66}),
            new FormulaireItem('critraadrl01'),
            new FormulaireItem('critraadrl02'),
            new FormulaireItem('critravil'),
            new FormulaireItem('critrapro'),
            new FormulaireItem('critracodpos')
        ]);

        vm.formulaireFedCon = new Formulaire('LBL_BLC_CON', [
            new FormulaireItem('criconnom', { largeur: 99}),
            new FormulaireItem('criconcodreg', { largeur: 33}),
            new FormulaireItem('cricontel', { largeur: 40}),
            new FormulaireItem('criconnumpos', { largeur: 33}),
            new FormulaireItem('criconcodlng', { largeur: 66}),
        ]);

        vm.formulaireProCri = new Formulaire('LBL_BLC_CRI', [
            new FormulaireItem('crinumide', { largeur: 33}),
            new FormulaireItem('crineq', { largeur: 33}),
            new FormulaireItem('crinumdos', { largeur: 33}),
            new FormulaireItem('vaecriprorel', { largeur: 40}),
        ]);
        //? check mnemonique
        vm.formulaireProTra = new Formulaire('LBL_BLC_PRE', [
            new FormulaireItem('crinumpre', { largeur: 33}),
            new FormulaireItem('vaecrityppre', { largeur: 33}),
            new FormulaireItem('criprenom', { largeur: 66}),
            new FormulaireItem('cripreadrl01'),
            new FormulaireItem('cripreadrl02'),
            new FormulaireItem('criprevil'),
            new FormulaireItem('criprepro'),
            new FormulaireItem('criprecodpos')
        ]);

        vm.formulaireProCon = new Formulaire('LBL_BLC_CON_INF', [
            new FormulaireItem('criinfnom', { largeur: 99}),
            new FormulaireItem('criinfcodreg', { largeur: 33}),
            new FormulaireItem('criinftel', { largeur: 40}),
            new FormulaireItem('criinfnumpos', { largeur: 33}),
            new FormulaireItem('criinfcodlng', { largeur: 66}),
        ]);

    }
}
