import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPagePf0023 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pf0023Controller(pf0005Route: IRoute,
                                         Menu: IMenuClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPagePf0023 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: pf0005Route.NAME,
        // Contrôle du décentralisé
        actionsNouveaux: new Menu([
            new MenuItemForage(
                pf0005Route.NAME,
                ()=>({}),
                {
                    disabled: !ecranDetails.flgcen
                })
        ]),
        fonctions: {
            //importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            {nom: 'acecod', largeur: 110}
        ],
        colonnesVisibles: [
            {nom: 'acedsc', largeur: 350},
            {nom: 'tpccod', largeur: 120},
            {nom: 'tpcdsc', largeur: 350},
            {nom: 'piscod', largeur: 110},
            {nom: 'pisdsc', largeur: 350},
            {nom: 'cabcod', largeur: 110},
            {nom: 'cabdsc', largeur: 350},
            {nom: 'competence', largeur: 350}
        ]
    };
}
