import {IMultiOccurrenceOptions, IMultiOccurrence} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFilterExDate } from '../../../../../core/filters/ex-date.filter';
import { IMenuItemForageClass, IMenuItemForage } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMenuItemActionClass, IMenuItemAction } from '../../../../../core/services/menu/menu-item-action.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';

interface IPageAs0082 {
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    tpsMultiOccurrenceOptions: IMultiOccurrenceOptions;
    menus : any;
    valeursEcran: any;
    menuPoincon: Array<IMenuItemForage>;
    menuHoraire: Array<IMenuItemForage>;
    menuException: Array<IMenuItemAction>;
}
/* @ngInject */
export default function As0082Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemAction: IMenuItemActionClass,
                                         MenuItem: IMenuItemClass,
                                         exDateFilter: IFilterExDate,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         as0081Route: IRoute) {
    const vm: IPageAs0082 = this;

    vm.valeursEcran = ecranDetails.valeurs;
    const as0008Route = {NAME: 'secure.accueil'};


    // Enlever le hidden une fois que l'ecran AS0008 sera developper dans SOFE 2
    vm.menus = {
        ahtcod: [
            new MenuItemForage(
                '',
                as0008Route.NAME,
                (data:any) => ({
                    id: data.asgcleint
                }),
                {
                    fonction: 'BTN_FORAGE_HORAIRE',
                    hidden: true
                }
            )
        ],
        podnbr: [
            new MenuItemForage(
                'BTN_FORAGE_ANALYSE',
                as0081Route.NAME,
                (data: any) => ({
                    eplcleint: data.eplcleint,
                    unpcleint: data.unpcleint,
                    datede: exDateFilter(data.hordat),
                    datea: exDateFilter(data.hordat)
                }),
                {
                    class: 'ex-blue-grey-500',
                    hidden: (data: any) => !(data.podnbr > 0),
                    fonction: 'BTN_FORAGE_ANALYSE'
                }
            ),
            new MenuItemForage(
                'BTN_FORAGE_ANALYSE_NOPUNCH',
                as0081Route.NAME,
                (data: any) => ({
                    eplcleint: data.eplcleint,
                    unpcleint: data.unpcleint,
                    datede: exDateFilter(data.hordat),
                    datea: exDateFilter(data.hordat),
                    creation: true
                }),
                {
                    icon: 'add_circle',
                    class: 'ex-blue-grey-500',
                    hidden: (data: any) => !!(data.podnbr),
                    fonction: 'BTN_FORAGE_ANALYSE_NOPUNCH'
                }
            )
        ]};

    vm.menuException = [
        new MenuItem(
            () => ({}),
            {
                noAction: true,
                icon: 'check_circle',
                class: 'ex-blue-grey-200 m-t-4-neg',
                hidden: (data: any) => (data.indexc === 'COR' || data.indexc === 'EXC')
            }
        ),
        new MenuItem(
            () => ({}),
            {
                noAction: true,
                icon: 'check_circle',
                class: 'ex-ligne-en-cours m-t-4-neg',
                hidden: (data: any) => (data.indexc === 'EXC' || data.indexc === 'AUC')
            }
        ),
        new MenuItem(
            () => ({}),
            {
                noAction: true,
                icon: 'warning',
                class: 'ex-ligne-erreur m-t-4-neg',
                hidden: (data: any) => (data.indexc === 'COR' || data.indexc === 'AUC')
            }
        )
    ];

    /**
     * Bloc Details des heures
     */
    vm.tpsMultiOccurrenceOptions = {
        bloc: 'tps',
        colonnesVisibles: [
            {nom: 'eplnummat', largeur:100 },
            {nom: 'col1', largeur:100, hidden: !(vm.valeursEcran.flgactcol1)},
            {nom: 'col2', largeur:100, hidden: !(vm.valeursEcran.flgactcol2)},
            {nom: 'col3', largeur:100, hidden: !(vm.valeursEcran.flgactcol3)},
            {nom: 'col4', largeur:100, hidden: !(vm.valeursEcran.flgactcol4)},
            {nom: 'col5', largeur:100, hidden: !(vm.valeursEcran.flgactcol5)},
            {nom: 'col6', largeur:100, hidden: !(vm.valeursEcran.flgactcol6)},
            {nom: 'colaut', largeur:100 },
            {nom: 'daldsc', largeur:100 }
        ],
        colonnesDisponibles: [
            {nom: 'eplnomprn', largeur:262 },
            {nom: 'ahhhredeb', largeur:100 },
            {nom: 'ahhhrefin', largeur:100 },
            {nom: 'ahhqte', largeur:100 },
            {nom: 'podhreent', largeur:100 },
            {nom: 'podhresor', largeur:100 },
            {nom: 'podqte', largeur:100 },
            {nom: 'qtedifhortra', largeur:150 },
            {nom: 'temcod', largeur:200 },
            {nom: 'temdscabr', largeur:250 },
            {nom: 'unocodvac', largeur:150},
            {nom: 'unodscvac', largeur:250},
            {nom: 'aftcod', largeur:150 },
            {nom: 'ahtcod', largeur:150, menu: vm.menus['ahtcod']},
            {nom: 'horjrs', largeur:100 },
            {nom: 'hordat', largeur:100 }
        ],
        colonnesCachees: [
            'employe', 'titemp','uniadm',
            'eplnomprnusu','ahtcleint', 'podnbr',
            'ahhhredeb','ahhhrefin','ahhqte',
            'podhreent','podhresor','podqte',
            'qtedifhortra','temcod','temdscabr',
            'unocodvac','unodscvac','aftcod',
            'ahtcod','horjrs','hordat',
            'unpcleint','wwwcleint','vaeasgmodges'
        ]
    };

    /**
     * Liste des presences
     */
    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'eplnomprn', largeur:262 },
            {nom: 'ahhqte', largeur:100 },
            {nom: 'podqte', largeur:100 },
            {nom: 'qtedifhortra', largeur:150, menu: vm.menuException},
            {nom: 'podnbr', largeur:150, menu: vm.menus['podnbr']},
            {nom: 'temdscabr', largeur:250},
            {nom: 'unodscvac', largeur:250}
        ],
        colonnesDisponibles: [
            {nom: 'eplnummat', largeur:100},
            {nom: 'ahhhredeb', largeur:100 },
            {nom: 'ahhhrefin', largeur:100 },
            {nom: 'podhreent', largeur:100 },
            {nom: 'podhresor', largeur:100 },
            {nom: 'temcod', largeur:150 },
            {nom: 'unocodvac', largeur:150},
            {nom: 'aftcod', largeur:150 },
            {nom: 'col1', largeur:100 , hidden: !(vm.valeursEcran.flgactcol1)},
            {nom: 'col2', largeur:100 , hidden: !(vm.valeursEcran.flgactcol2)},
            {nom: 'col3', largeur:100 , hidden: !(vm.valeursEcran.flgactcol3)},
            {nom: 'col4', largeur:100 , hidden: !(vm.valeursEcran.flgactcol4)},
            {nom: 'col5', largeur:100 , hidden: !(vm.valeursEcran.flgactcol5)},
            {nom: 'col6', largeur:100 , hidden: !(vm.valeursEcran.flgactcol6)},
            {nom: 'colaut', largeur:100 },
            {nom: 'ahtcod', largeur:150, menu: vm.menus['ahtcod']},
            {nom: 'eplnomprnusu', largeur:262 },
            {nom: 'horjrs', largeur:100 },
            {nom: 'hordat', largeur:100 },
            {nom: 'excep_en1', largeur:150 },
            {nom: 'excep_en2', largeur:150 },
            {nom: 'excep_en4', largeur:100 },
            {nom: 'excep_en7', largeur:100 },
            {nom: 'excep_en8', largeur:100 },
            {nom: 'excep_so1', largeur:150 },
            {nom: 'excep_so6', largeur:150 },
            {nom: 'excep_pv7', largeur:200 },
            {nom: 'excep_pv8', largeur:200 }
        ],
        colonnesCachees: [
            'aftcleint','aftcod', 'temcod', 'urgcleint',
            'occcleint','occdsc',
            'eplcleint','eplnummat','eplnomprn',
            'unpcleint', 'demcleint','hordat',
            'indexc','datdeb', 'asgcleint',
            'datfin', 'podnbr', 'wwwcleint',
            'podcleint_ent','podcleint_sor',
            'ahhhredeb','ahhhrefin','vaeasgmodges'
        ],
        criteresSuggeres: new Formulaire([
            new FormulaireItem('datdeb', {
                default: exDateFilter(new Date()), 
                ignoreEtat: true, 
                required: true
            }),
            new FormulaireItem('datfin', {
                default: exDateFilter(new Date()), 
                ignoreEtat: true, 
                required: true
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('poscleint_con'),
            new FormulaireItem('asgcleint', {
                resourceParams: () => {
                    return {
                        aftcleint: vm.valeursEcran.aftcleint
                    };
                }}),
            new FormulaireItem('ahtcleint'),
            new FormulaireItem('radio', {
                largeur:100, 
                default: 'TOU', 
                direction: 'horizontal'
            })
        ], {largeurDefaut: 33}),

        actionsRangeeDroite: new Menu([
            new MenuItemAction(
                '',
                'BOUMODHOR',
                (data: any) => ({
                    wwwcleint: data.wwwcleint
                }),
                {
                    icon: 'create',
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_MODIF_HORAIRE',
                        lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                        lblConfirmAndNext: 'G_LBL_BTN_ENREGISTRER_SUIVANT',
                        formulaire: new Formulaire(
                            'LBL_ASJUST_TEMPS', [
                                new FormulaireItem('eplcleint',{
                                    default: (data: any) => data.eplcleint
                                }),
                                new FormulaireItem('eplcleint__eplnummat',{
                                    default: (data: any) => data.eplnummat, hidden: true
                                }),
                                new FormulaireItem('eplcleint__eplnomprn',{
                                    default: (data: any) => data.eplnomprn, hidden: true
                                }),
                                new FormulaireItem('datepre' ,{
                                    default: (data: any) => data.hordat
                                }),
                                new FormulaireItem('aftcod',{
                                    default: (data: any) => data.aftcod
                                }),
                                new FormulaireItemSautDeLigne(),
                                new FormulaireItem('aftcleint',{
                                    default: (data: any) => data.aftcleint,
                                    resourceParams: (data: any) => {
                                        const row = vm.multiOccurrence.dataList.find(ele => ele.cle_unique === data.cle_unique);
                                        return {
                                            asgcleint: data.asgcleint,
                                            demcleint: row && row.demcleint,
                                            date: data.hordat
                                        };
                                    }
                                }),
                                new FormulaireItem('aftcleint__aftcod',{
                                    default: (data: any) => data.aftcod, 
                                    hidden: true
                                }),
                                new FormulaireItem('aftcleint__temcod',{
                                    default: (data: any) => data.temcod, 
                                    hidden: true
                                }),
                                new FormulaireItem('temcleint',  {
                                    resourceParams: (data: any) => {
                                        return {urgcleint: data.urgcleint};
                                    }}),
                                new FormulaireItemSautDeLigne(),
                                new FormulaireItem('occcleint', {
                                    default: (data: any) => data.occcleint
                                }),
                                new FormulaireItem('occcleint__occdsc', {
                                    default: (data: any) => data.occdsc, 
                                    hidden: true
                                }),
                                new FormulaireItem('actcleint',{
                                    resourceParams: (data: any) => {
                                        const row = vm.multiOccurrence.dataList.find(ele => ele.cle_unique === data.cle_unique);
                                        return {
                                            asgcleint: data.asgcleint,
                                            demcleint: row && row.demcleint
                                        };
                                    }
                                }),
                                new FormulaireItem('isdcom'),
                                new FormulaireItemSautDeLigne(),
                                new FormulaireItem('date', {
                                    default: (data:any) => data.hordat
                                }),
                                new FormulaireItem('horhredeb', {
                                    default: (data:any) => data.ahhhredeb,
                                    hidden: (data:any) => !(data.vaeasgmodges === 'PFX')
                                }),
                                new FormulaireItem('horhrefin', {
                                    default: (data:any) => data.ahhhrefin,
                                    hidden: (data:any) => !(data.vaeasgmodges === 'PFX')
                                }),
                                new FormulaireItem('qte', {
                                    default: (data:any) => data.qtedifhortra
                                }),
                                new FormulaireItem('unpcleint', {
                                    hidden:true, 
                                    default: (data:any) => data.unpcleint
                                })
                            ], {largeurDefaut: 33.33}
                        )
                    },
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemAction(
                'LBL_AJOUT_ENTREE',
                'BOUAJTENTSOR',
                (data: any) => ({
                    eplcleint: data.eplcleint,
                    unpcleint: data.unpcleint,
                    podcleint_ent: data.podcleint_ent,
                    ahhhredeb: data.ahhhredeb,
                    typsai: '1',
                    wwwcleint: data.wwwcleint
                }),
                {
                    icon: 'add_circle',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            ),
            new MenuItemAction(
                'LBL_AJOUT_SORTIE',
                'BOUAJTENTSOR',
                (data: any) => ({
                    eplcleint: data.eplcleint,
                    unpcleint: data.unpcleint,
                    podcleint_sor: data.podcleint_sor,
                    ahhhrefin: data.ahhhrefin,
                    typsai: '2',
                    wwwcleint: data.wwwcleint
                }),
                {
                    icon: 'add_circle',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            ),
            new MenuItemAction(
                'LBL_AJOUT_ENTSOR',
                'BOUAJTENTSOR',
                (data: any) => ({
                    eplcleint: data.eplcleint,
                    unpcleint: data.unpcleint,
                    podcleint_ent: data.podcleint_ent,
                    podcleint_sor: data.podcleint_sor,
                    ahhhredeb: data.ahhhredeb,
                    ahhhrefin: data.ahhhrefin,
                    typsai: '3',
                    wwwcleint: data.wwwcleint
                }),
                {
                    icon: 'add_circle',
                    successAction: () => {
                        vm.multiOccurrence.fetchDataList();
                    }
                }
            )
        ]),
        fonctions: {
            suiviModification: false
        },

        multiOccurrenceOptionsEnfant: vm.tpsMultiOccurrenceOptions
    };
}
