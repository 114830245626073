import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPagePF0018 extends IComponentController{
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireSua: IFormulaire;
    formulaireVig: IFormulaire;
    valeursEcran: any;
    rdmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRdm: IMultiOccurrence;
}

/* @ngInject */
export default function Pf0018Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         pf0024Route: IRoute,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Menu: IMenuClass) {
    const vm: IPagePF0018 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireSua = new Formulaire('LBL_BLC_SUA', [
            new FormulaireItem('suanumseq', {largeur: 10}),
            new FormulaireItem('suacod', {largeur: 10}),
            new FormulaireItem('suadsc', {largeur: 45}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('suacom', {largeur: 100, enableCount: true}),
            new FormulaireItem('vaesuamod', {largeur: 50})
        ] );

        vm.formulaireVig = new Formulaire('LBL_BLC_VIG', [
            new FormulaireItem('suaflgact', {default: '1'}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('suadatdeb'),
            new FormulaireItem('suadatfin')
        ]);

        vm.rdmMultiOccurrenceOptions = {
            actionsNouveaux: new Menu([
                new MenuItemEcran('G_LBL_COMREQ',
                    pf0024Route.NAME,
                    () => ({suacleint: vm.monoOccurrence.data.suacleint}),
                    {
                        onClose: () => vm.multiOccurrenceRdm.fetchDataList()
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'rdmnumseq', largeur: 100},
                {nom: 'vaerdmcon_dsc', largeur: 241},
                {nom: 't_valeur', largeur: 498}
            ],
            colonnesCachees: ['vaerdmcon', 't_indval', 'suadsc', 'vrdcleint'],
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    getForageRoute,
                    getForageParams,
                    {
                        icon: 'launch',
                        fonction:'BOUCOMREQ',
                        iconButton: true,
                        onClose: () => vm.multiOccurrenceRdm.fetchDataList()
                    }
                )
            ]),
            formulaire: new Formulaire([
                new FormulaireItem('rdmnumseq', {largeur: 10}),
                new FormulaireItem('vaerdmcon', {largeur: 55}),
                new FormulaireItem('aut__t_valeur', {
                    menu: [
                        new MenuItemEcran(
                            'G_LBL_COMREQ',
                            getForageRoute,
                            getForageParams,
                            {
                                icon: 'launch',
                                iconButton: true,
                                onClose: () => vm.multiOccurrenceRdm.fetchDataList()
                            }
                        )
                    ]
                    , largeur: 20})
            ])
        };
    }

    function getForageRoute(data: any): string {
        return pf0024Route.NAME;
    }

    function getForageParams(data: any): any {
        return {
            id: data.rdmcleint,
            stamnetab: data.vaerdmcon
        };
    }
}
