import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IComponentController} from 'angular';


interface IPageMc0081 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    valeursEcran: any;
    cetMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0081Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass)  {
    const vm: IPageMc0081 = this;

    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire('LBL_BLC_CYO', [
            new FormulaireItem('cyocod'),
            new FormulaireItem('cyodsc'),
            new FormulaireItem('cyodscabr'),
            new FormulaireItem('vaecyomne', { readonly: ( data ) => data.$id }),
            new FormulaireItem('cetcleintini', {
                disabled: ( data ) => (!data.$id),
                resourceParams: (data:any) => {
                    return {
                        cyocleint: data.cyocleint
                    };
                }
            })
        ], { largeurDefaut: 33.33 })
    };

        // Multi Étapes
        vm.cetMultiOccurrenceOptions = {
            bloc: 'cet',
            fonctions: {
                pagination: true
            },
            colonnesVisibles: [
                {nom: 'cetnum', largeur: 120},
                {nom: 'cetact', largeur: 350},
                {nom: 'cetactabr', largeur: 300},
                {nom: 'vaecetindmod_dsc', largeur: 150}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cetnum', { required: true }),
                new FormulaireItem('cetact'),
                new FormulaireItem('cetactabr'),
                new FormulaireItem('vaecetindmod', { required: true })
            ], {largeurDefaut: 25})
        };

}
