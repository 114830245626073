import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";
import {IFormulaireGroupeClass} from "../../../../../core/services/formulaire/formulaire-groupe.service";
import {IFormulaireItemDividerClass} from "../../../../../core/services/formulaire/formulaire-item-divider.service";
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageRr0158 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence,
    formulaireMpa: IFormulaire;
    mreMultiOccurrenceOptions: IMultiOccurrenceOptions;
    mmmMultiOccurrenceOptions: IMultiOccurrenceOptions;
    musMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr0553Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemDivider: IFormulaireItemDividerClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0158 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            id: ecranDetails.valeurs.cpmcleint
        };

        vm.formulaireCpm = new Formulaire('LBL_BLC_CPM', [
            new FormulaireGroupe( new Formulaire([
                new FormulaireItemDivider('LBL_DIV_POS'),
                new FormulaireItem('vaemodspe', {largeur: 100}),
                new FormulaireItem('cpmpar1', {largeur: 100}),
                new FormulaireItem('cpmpar2', {largeur: 100}),
                new FormulaireItem('cpmpar3', {largeur: 100}),
                new FormulaireItem('cpmpar4', {largeur: 100})
            ]), {largeur: 33}),
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('cpmpro', {largeur: 50}),
                new FormulaireItem('cpmsoupro', {largeur: 50}),
                new FormulaireItem('cpmactcpt', {largeur: 50}),
                new FormulaireItem('cpmnumtra', {largeur: 50}),
                new FormulaireItem('cpmtyptra', {largeur: 50}),
                new FormulaireItem('cpmtyprep', {largeur: 50}),
                new FormulaireItem('cpmnumeqi', {largeur: 50}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('cpmsep', {largeur: 50})
            ]), {largeur: 66})
        ]);


    }
}
