import {IComponentController} from 'angular';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPagePf0034 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaireXpp: IFormulaire;
    xpaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    corMultiOccurrenceOptions: IMultiOccurrenceOptions;
    corMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Pf0034Controller(co0009Route: IRoute,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass) {
    const vm: IPagePf0034 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaireXpp = new Formulaire( 'LBL_BLC_XPP', [
            new FormulaireItem('xppcod'   , {largeur: 25}),
            new FormulaireItem('xppdsc'   , {largeur: 75, enableCount: true}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('rolcleint', {largeur: 75}),
            new FormulaireItem('xppseqaff', {largeur: 25})
        ]);

        vm.xpaMultiOccurrenceOptions = {
            bloc: 'xpa',
            colonnesVisibles: [
                {nom: 'temcod'   , largeur: 100},
                {nom: 'xpaflgact', largeur: 100},
                {nom: 'posnumdoc', largeur: 100},
                {nom: 'litcod'   , largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('temcleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('poscleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('litcleint'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('xpaflgact', {default: 1})
            ], {largeurDefaut: 100})
        };

        vm.corMultiOccurrenceOptions = {
            bloc: 'cor',
            actionsNouveaux: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    () => ({
                        stamnetab: 'XPP',
                        corcleintref: vm.monoOccurrence.data.xppcleint
                    }),
                    {
                        onClose() {
                            vm.corMultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]),
            actionsRangeeDroite: new Menu([
                new MenuItemEcran(
                    'G_LBL_COMREQ',
                    co0009Route.NAME,
                    (data: any) => ({
                        id: data.corcleint,
                        stamnetab: 'XPP',
                        corcleintref: vm.monoOccurrence.data.xppcleint
                    }),
                    {
                        icon: 'launch',
                        iconButton: true,
                        onClose: () => vm.corMultiOccurrence.fetchDataList()
                    }
                )
            ]),
            colonnesVisibles: [
                {nom: 'corseqaff'   , largeur: 120},
                {nom: 'comcod'      , largeur: 160},
                {nom: 'comdsc'      , largeur: 250},
                {nom: 'corvalcib'   , largeur: 130},
                {nom: 'nivcod'      , largeur: 130},
                {nom: 'nivdsc'      , largeur: 250},
                {nom: 'cordscweb'   , largeur: 250},
                {nom: 'corexp'      , largeur: 250},
                {nom: 'corcom'      , largeur: 250},
                {nom: 'corflgaffweb', largeur: 120},
                {nom: 'corflgact'   , largeur: 100}
            ]
        };
    }
}
