import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageAc0085 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Ac0085Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageAc0085 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
           importation: true
        },
        colonnesFixesGauche: [
            {nom: 'refcod', largeur: 90},
            {nom: 'refnom', largeur: 215},
            {nom: 'copcod', largeur: 123}
        ],
        colonnesVisibles: [
            {nom: 'vecsyscatcou_cod', largeur: 95},
            {nom: 'vecsyscatcou_dsc', largeur: 141},
            {nom: 'vecsyscodarr_cod', largeur: 135},
            {nom: 'vecsyscodarr_dsc', largeur: 141},
            {nom: 'copdat', largeur: 100},
            {nom: 'melcod', largeur: 90},
            {nom: 'meldsc', largeur: 141},
            {nom: 'copqteach', largeur: 126},
            {nom: 'cnccod', largeur: 145}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('refcleint', {required: true}),
            new FormulaireItem('copcod', {required: true}),
            new FormulaireItem('vecsyscatcou'),
            new FormulaireItem('vecsyscodarr'),
            new FormulaireItem('copdat'),
            new FormulaireItem('melcleint'),
            new FormulaireItem('copqteach'),
            new FormulaireItem('cnccleint', {readonly: true})
        ], {largeurDefaut: 25})
    };
}
