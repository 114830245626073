import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionTelechargementClass } from "../../../../../core/services/menu/menu-item-action-telechargement.service";

interface IPageRr0519 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0519Controller(rr0162Route: IRoute,
    Menu: IMenuClass,
    MenuItemActionTelechargement: IMenuItemActionTelechargementClass,
    Operateur: IOperateurService,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0519 = this;

    vm.multiOccurrenceOptions = {
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0162Route.NAME)
        ]), navigatePage: rr0162Route.NAME,
        actionsMore: new Menu([
            new MenuItemActionTelechargement(
                'TELECHARGER_PATRON',
                'BOUPAT',
                () => ({}),
                {
                    icon: 'file_download'
                }
            )
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        filtres: [
            {
                colonne: 'fdrflggen',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            }
        ],
        colonnesVisibles: [
            { nom: 'fdrnumcha', largeur: 150 },
            { nom: 'fdrficsou', largeur: 150 },
            { nom: 'datcre', largeur: 120 },
            { nom: 'usrnom', largeur: 200 },
            { nom: 'fdrnbrlig', largeur: 120 },
            { nom: 'fdrnbrerr', largeur: 120 },
            { nom: 'fdrflgval', largeur: 100 },
            { nom: 'fdrflggen', largeur: 100 }
        ]
    };
}
