import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageMr2009 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    inrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    ireMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function mr2009Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    mr2010Route: IRoute,
    mr2011Route: IRoute) {
    const vm: IPageMr2009 = this;

    const forageUno = new MenuItemEcran(
        'BTN_FORAGE_UNORH',
        mr2010Route.NAME,
        (data: any) => ({
            id: data.inrcleint
        }),
        {
            icon: 'launch',
            hidden: (data:any) => !(data.inrflgresuno)
        }
    );

    const forageDocRh = new MenuItemEcran(
        'BTN_FORAGE_DOCRH',
        mr2011Route.NAME,
        (data: any) => ({
            id: data.inrcleint
        }),
        {
            icon: 'launch',
            hidden: (data:any) => !(data.inrflgresdocrh)
        }
    );

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_INT', [
            new FormulaireItem('intcod', {largeur: 20, readonly: () => !(ecranDetails.flgcen)}),
            new FormulaireItem('intnom', {largeur: 40, readonly: () => !(ecranDetails.flgcen)}),
            new FormulaireItem('intflgsup', {largeur: 20, readonly: () => !(ecranDetails.flgcen)}),
            new FormulaireItem('intflgssup', {largeur: 20, readonly: () => !(ecranDetails.flgcen)})
        ])
    };

    vm.inrMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'rolcod', largeur: 100},
            {nom: 'roldsc', largeur: 230},
            {nom: 'roldscabr', largeur: 200},
            {nom: 'inrdatdeb', largeur: 126},
            {nom: 'inrdatfin', largeur: 126},
            {nom: 'inrflgresuno', largeur: 120, menu: [forageUno]},
            {nom: 'inrflgresdocrh', largeur: 120, menu: [forageDocRh]},
            {nom: 'inrflgdel', largeur: 120, hidden: () => !(ecranDetails.flgcen)},
            {nom: 'inrsusavi_dsc', largeur: 150},
            {nom: 'rolflgact', largeur: 120}
        ],
        colonnesCachees: [
            'inrcleint'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rolcleint'),
            new FormulaireItem('inrdatdeb'),
            new FormulaireItem('inrdatfin'),
            new FormulaireItem('inrflgresuno', {hidden: () => !(ecranDetails.flgcen)}),
            new FormulaireItem('inrflgresdocrh'),
            new FormulaireItem('inrflgdel', {hidden: () => !(ecranDetails.flgcen)}),
            new FormulaireItem('inrsusavi')
        ], {largeurDefaut: 33.33})
    };

    vm.ireMultiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'intcod', largeur: 120},
            {nom: 'intnom', largeur: 250},
            {nom: 'iredatdeb', largeur: 126},
            {nom: 'iredatfin', largeur: 126},
            {nom: 'irecom', largeur: 300}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('intcleintref'),
            new FormulaireItem('iredatdeb'),
            new FormulaireItem('iredatfin'),
            new FormulaireItem('irecom', {enableCount: true, largeur: 100})
        ], {largeurDefaut: 33.33})
    };

}