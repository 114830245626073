import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0009Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0009 = this;

    vm.multiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'tylcod', largeur: 72}
        ],
        colonnesVisibles: [
            {nom: 'tyldsc', largeur: 294},
            {nom: 'tylflgact', largeur: 74},
            {nom: 'vaetylref', largeur: 95},
            {nom: 'ttccod', largeur: 105},
            {nom: 'ttcdsc', largeur: 236},
            {nom: 'teccod', largeur: 195},
            {nom: 'tecdsc', largeur: 236},
            {nom: 'srvcod', largeur: 145},
            {nom: 'srvdsc', largeur: 235},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tylcod'),
            new FormulaireItem('tyldsc', {required: true, largeur: 50}),
            new FormulaireItem('tylflgact', {default: 1}),
            new FormulaireItem('vaetylref', {required: true}),
            new FormulaireItem('ttccleint', {required: true}),
            new FormulaireItem('teccleint', {required: true}),
            new FormulaireItem('srvcleint')
        ], {largeurDefaut: 25}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
