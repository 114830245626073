
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IController } from 'angular';

interface IPageMr0013 extends IController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceVttOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0013Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0013 = this;

    vm.multiOccurrenceVttOptions = {
        bloc: 'vtt',
        colonnesVisibles: [
            { nom: 'tytcod', largeur: 120 },
            { nom: 'tytdsc', largeur: 200 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tytcleint', {largeur: 30})
        ])
    };

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'evsdsc', largeur: 150},
            {nom: 'evscom', largeur: 400},
            {nom: 'stamnetab', largeur: 60},
            {nom: 'evsflgtyt', largeur: 30},
            {nom: 'evsflgald', largeur: 30},
            {nom: 'evsflgaft', largeur: 30},
            {nom: 'evsflgtag', largeur: 30}
        ],

        multiOccurrenceOptionsEnfant: vm.multiOccurrenceVttOptions,

        formulaire: new Formulaire([
            new FormulaireItem('evsdsc', {largeur: 30}),
            new FormulaireItem('evscom', {largeur: 60}),
            new FormulaireItem('stamnetab', {largeur: 30}),
            new FormulaireItem('evsflgtyt', {largeur: 30}),
            new FormulaireItem('evsflgald', {largeur: 30}),
            new FormulaireItem('evsflgaft', {largeur: 30}),
            new FormulaireItem('evsflgtag', {largeur: 30})
        ])
    };
}
