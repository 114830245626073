import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageDo0061 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceVTBOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Do0061Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageDo0061 = this;

    vm.multiOccurrenceVTBOptions = {
        bloc: 'vtb',
        colonnesVisibles: [
            {nom: 'strcod', largeur: 95},
            {nom: 'strdsc', largeur: 325},
            {nom: 'bqecod', largeur: 95},
            {nom: 'bqedsc', largeur: 325},
            {nom: 'vtbflgact', largeur: 100}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('strcleint'),
            new FormulaireItem('bqecleint'),
            new FormulaireItem('vtbflgact', {default: 1})
        ], {largeurDefaut: 33.33})
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'vtlcod', largeur: 128},
            {nom: 'vtldsc', largeur: 180},
            {nom: 'bqecod2', largeur: 100},
            {nom: 'bqedsc2', largeur: 180},
            {nom: 'vtldatsem', largeur: 126},
            {nom: 'vtlflgact', largeur: 100},
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vtlcod', {required: true}),
            new FormulaireItem('vtldsc', {required: true}),
            new FormulaireItem('bqecleintref2', {required: true}),
            new FormulaireItem('vtldatsem', {largeur: 25, readonly: true}),
            new FormulaireItem('vtlflgact', {largeur: 25, default: 1})
        ]),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceVTBOptions
    };
}
