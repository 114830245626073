import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';

interface IPageGl0041 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    formulaire: IFormulaire;
    celMultiOccurrence: IMultiOccurrence;
    celMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gl0041Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageGl0041 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.formulaire = new Formulaire('LBL_CEDULE', [
            new FormulaireItem('ceecod', {largeur: 20}),
            new FormulaireItem('ceedsc', {largeur: 80}),
        ]);

        vm.celMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'celnumlig', largeur: 100},
                {nom: 'celdsc', largeur: 350},
                {nom: 'eficod', largeur: 100},
                {nom: 'ciecod', largeur: 140},
                {nom: 't_imp', largeur: 360}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('celnumlig', {largeur: 10}),
                new FormulaireItem('celdsc', {largeur: 50}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('eficleint', {largeur: 20}),
                new FormulaireItem('ciecleintsel', {largeur: 20}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('t_imp', {largeur: 20, default: () =>
                                              { return vm.celMultiOccurrence.getDataActiveRow()
                                                       ? vm.celMultiOccurrence.getDataActiveRow().t_imp
                                                       : null}
                                            })                
            ], {
                largeurDefaut: 20
            })
        }
    }
}
