import {IComponentController} from "angular";
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";

interface IPagePb0010 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

/* @ngInject */
export default function Pb0010Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,                                         
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,) {
    const vm: IPagePb0010 = this;

        vm.monoOccurrenceOptions = {
            fonctions: {
                edition: false                 
            },
            formulaire: new Formulaire('LBL_BLC_TITRE', [
                new FormulaireItem('pveann', ),
                new FormulaireItem('pvedatdeb',{default: ecranDetails.valeurs.pvedatdeb}),
                new FormulaireItem('pvedatfin',{default: ecranDetails.valeurs.pvedatfin}),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('intcod'),
                new FormulaireItem('intnom'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('prucod'),
                new FormulaireItem('prudscl01'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('posnumdoc'),
                new FormulaireItem('postit'),
            ], {largeurDefaut: 33})
        };

        vm.ptcMultiOccurrenceOptions = {
            fonctions: {
                rechercheCollapsable: false                
            },
            formulaire: new Formulaire([
                new FormulaireItem('ptcflgexc', { largeur: 25 }),         
                new FormulaireItem('ptccom', { largeur: 50 })
            ]), 
            colonnesFixesGauche: [
                {nom: 'nummat', largeur: 110},
                {nom: 'nomprn', largeur: 300}            
            ],                       
            colonnesVisibles: [
                {nom: 'ptccousyspre', largeur: 110,total: true},
                {nom: 'ptccoussysrel', largeur: 110, total: true},
                {nom: 'ptccousyseng', largeur: 110, total: true},
                {nom: 'totalecart', largeur: 110, total: true},
                {nom: 'ptcflgexc', largeur: 110},
                {nom: 'flgcom', largeur: 110}         
            ]
        };

}
