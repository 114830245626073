import { IComponentController } from 'angular';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';

interface IPageAC0003 extends IComponentController {
    formulaireCaa: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    mclMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireMcl: IFormulaire;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0003Controller(Menu: IMenuClass,
    MenuItemEcran: IMenuItemEcranClass,
    mc0046Route: IRoute,
    mc1014Route: IRoute,
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageAC0003 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails;
        vm.monoOccurrenceOptions = {
            fonctions: {
                exportation: false
            },
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_IMPUTATION',
                    mc0046Route.NAME,
                    (data: any) => ({
                        vaeusient: 'CAA',
                        uvicleintref: vm.monoOccurrence.data.caacleint
                    }),
                    {
                        icon: 'view_list',
                        iconButton: false
                    }
                ), new MenuItemEcran(
                    'LBL_TOLERANCE',
                    mc1014Route.NAME,
                    () => ({
                        tolcleintref: vm.monoOccurrence.data.caacleint,
                        vaeustent: 'CAT'
                    }),
                    {
                        fonction: 'BOUTOL',
                        icon: 'data_usage',
                        iconButton: false,
                        secondaire: true
                    }
                )])
        }

        vm.formulaireCaa = new Formulaire('LBL_BLC_CAA', [
            new FormulaireItem('caacod', { required: true }),
            new FormulaireItem('caaflgstu'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('caadsc', { required: true, largeur: 50, }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('caadscabr', { largeur: 67 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('caaflggeb'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('taxcleint'),
            new FormulaireItem('remcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintach')
        ], { largeurDefaut: 33.33 }
        );
    }

    vm.mclMultiOccurrenceOptions = {
        bloc: 'mcl',
        colonnesVisibles: [
            { nom: 'cmcmotcle', largeur: 100 }
        ],
        fonctions: {
            exportation: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('cmcmotcle', { maxlength: 30 })
        ])
    };
}
