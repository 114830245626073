import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';


interface IPageMC0030 {
    valeursEcran: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IMc0030Params {
    intcleint: number;
}

/* @ngInject */
export default function mc0030Controller(
    Formulaire: IFormulaireClass,
    Menu: IMenuClass,
    $stateParams: IMc0030Params,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMC0030 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres:true,
            selectionCycle: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('intcleint', {
                largeur: 33.33, 
                required: true, 
                default: vm.valeursEcran.usrintcleint, 
                readonly: () => !(vm.valeursEcran.flgintequ)
            })
        ]),
        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'LBL_BTN_DOC',
                (rowData: any) => `secure.${rowData.ecrcodoc}.ecran`,
                (rowData: any) => ({
                    id: rowData.ecrcledoc
                }),
                {
                    icon: 'launch'
                }
            ),
            new MenuItemForage(
                'LBL_BTN_IMP',
                (rowData: any) => `secure.${rowData.ecrcodimp}.ecran`,
                (rowData: any) => ({
                    id: rowData.doccleint,
                    docaffnumdoc01: rowData.docaffnumdoc01,
                    tydcleint: rowData.tydcleint
                }),
                {
                    icon: 'view_list'
                }
            )
        ]),
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 131 },
            { nom: 'tydcod', largeur: 80 }
        ],
        colonnesVisibles: [
            { nom: 'docaffmntdepnet01', largeur: 126 },
            { nom: 'docaffmnt01', largeur: 126 },
            { nom: 'docreqcod03', largeur: 120 },
            { nom: 'unacod', largeur: 120 },
            { nom: 'docaffdsc01', largeur: 190 }
        ],
        colonnesCachees: [
            'doccleint', 
            'ecrcodoc', 
            'ecrcledoc', 
            'ecrcodimp', 
            'docaffnumdoc01', 
            'tydcleint'
        ]
    };


}
