import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPageGT0009 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gt0009Controller(Menu: IMenuClass) {
    const vm: IPageGT0009 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'empcod', largeur: 200},
            {nom: 'empnom', largeur: 200},
            {nom: 'tydcod', largeur: 200},
            {nom: 'pegcod', largeur: 200},
            {nom: 'feutothre01', largeur: 200},
            {nom: 'feutothre02', largeur: 200},
            {nom: 'feutothre03', largeur: 200},
            {nom: 'feutothre04', largeur: 200},
            {nom: 'feutothre05', largeur: 200},
            {nom: 'feutothre06', largeur: 200},
            {nom: 'feutothre07', largeur: 200},
            {nom: 'feutothresum', largeur: 200},
            {nom: 'etddsc', largeur: 200}
        ]
    };
}
