import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItem} from '../../../../../core/services/menu/menu-item.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageRR0016 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireEad: IFormulaire;
    formulaireErr: IFormulaire;
    forageTelephone: IMenuItem;
    valeursEcran: any;
}

interface IRr0016Params {
    id: string;
    eplcleint: string;
    trncleint: string
}

/* @ngInject */
export default function Rr0016Controller(rr0017Route: IRoute,
                                         $stateParams: IRr0016Params,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         MenuItemEcran: IMenuItemEcranClass) {
    const vm: IPageRR0016 = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.forageTelephone = new MenuItemEcran('BTN_FORAGE_TELEPHONE',
            rr0017Route.NAME,
            (data: any) => (
                {
                    id: data.etl__etlcleint,
                    eplcleint: vm.monoOccurrence.data.eplcleint
                    //eplcleint: $stateParams.eplcleint
                }
            ), {
                icon: 'filter_none',
                appliquer: true,
                onClose() {
                    vm.monoOccurrence.fetchData();
                }
            }
        );

        vm.formulaireEad = new Formulaire('LBL_ADRESSE', [
            new FormulaireItem('eplcleint', {
                default: $stateParams.eplcleint
            }),
            new FormulaireItem('eadflgact', {
                default: 1,
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tadcleint'),
            new FormulaireItem('eaddatvig', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eadflgchq', {
                default: 1,
                largeur: 33.33
            }),
            new FormulaireItem('eadflgfinann', {
                default: 1,
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pyscod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etacleint'),
            new FormulaireItem('etacod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('muncod', {
                largeur: 66.66
            }),
            new FormulaireItem('munnomfra', {
                largeur: 66.66
            }),
            new FormulaireItem('eadcodpos', {
                largeur: 33.33
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eadadr1'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eadadr2'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eadadr3'),
            new FormulaireItem('vaesysprv', {
                default: 'MAN'
            })
        ], {
            largeurDefaut: 66.66
        });
        vm.formulaireErr = new Formulaire('LBL_ERREUR', [
            new FormulaireItem('eadflgerr'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eaddaterr')
        ]);
    }
}
