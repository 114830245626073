import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IRoute} from '../../../../../core/interfaces/route.interface';

interface IPageRR0092 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0092Controller(ecranDetails: IEcranDetailsResourcesEntite,
                                         rr0093Route:IRoute) {
    const vm: IPageRR0092 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            selectionCycle: false,
            rechercheCollapsable: false
        },
        navigatePage: rr0093Route.NAME,
        navigateParams: (rowData: any) => {
            return {
                id: rowData.eplcleint
            };
        },
        colonnesCachees: ['eplcleint'],
        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'eplnom', largeur: 200},
            {nom: 'eplprn', largeur: 200},
            {nom: 'eplnomusu', largeur: 200},
            {nom: 'eplprnusu', largeur: 200},
            {nom: 'eplnomnai', largeur: 200},
            {nom: 'vaedsc', largeur: 115},
            {nom: 'epladrcou', largeur: 250},
            {nom: 'eplsrucod', largeur: 120},
            {nom: 'eplnumref1', largeur: 180, hidden: !ecranDetails.valeurs.flgaffref1},
            {nom: 'eplnumref2', largeur: 180, hidden: !ecranDetails.valeurs.flgaffref2},
            {nom: 'eplnumref3', largeur: 180, hidden: !ecranDetails.valeurs.flgaffref3},
            {nom: 'eplnumref4', largeur: 180, hidden: !ecranDetails.valeurs.flgaffref4},
            {nom: 'unpcod', largeur: 100},
            {nom: 'unpdsc', largeur: 200}
            ]
    };
}
