import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageAs0040 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulairePortailEmploye: IFormulaire;
    formulairePortailSOFE: IFormulaire;
    multiOccurrenceOptionsHac: IMultiOccurrenceOptions;
    multiOccurrenceOptionsUth: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function As0040Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageAs0040 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_HAC', [
            new FormulaireItem('athcod'),
            new FormulaireItem('athdsc'),
            new FormulaireItem('athcom'),
            new FormulaireItem('athflgact')
        ], { largeurDefaut: 50 })
    }

    vm.formulairePortailEmploye = new Formulaire('LBL_POR_EMP', [
        new FormulaireItem('athdscabremp'),
        new FormulaireItem('athdscemp'),
        new FormulaireItem('athexpemp', { largeur: 90 })
    ], { largeurDefaut: 50 });

    vm.formulairePortailSOFE = new Formulaire('LBL_POR_SOF', [
        new FormulaireItem('athdscabrsof'),
        new FormulaireItem('athdscsof'),
        new FormulaireItem('athexpsof', { largeur: 90 })
    ], { largeurDefaut: 50 });

    /* Code de temps */
    vm.multiOccurrenceOptionsHac = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'actcod', largeur: 90 },
            { nom: 'actdsc', largeur: 325 }
        ],
        formulaire: new Formulaire('LBL_BLC_ACT', [
            new FormulaireItem('actcleint')
        ], { largeurDefaut: 50 })
    };

    /* Utilisation */
    vm.multiOccurrenceOptionsUth = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'vivvaedsc', largeur: 200 },
            { nom: 'uthseqaff', largeur: 50 }
        ],
        formulaire: new Formulaire('LBL_BLC_UTH', [
            new FormulaireItem('vaeuthtra'),
            new FormulaireItem('uthseqaff')
        ], { largeurDefaut: 50 })
    };
}
