import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageTF0005 {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    pteMultiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0005Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {

    const vm: IPageTF0005 = this;
    vm.monoOccurrenceOptions = {
        formulaire: new Formulaire([
            new FormulaireItem('folcleint', { largeur: 33.33, modifAllow: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeptxtyppre', { largeur: 33.33, modifAllow: false }),
            new FormulaireItem('ptxcptfic', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaeptxprorel', { largeur: 33.33 }),
            new FormulaireItem('ptxnument', { largeur: 33.33 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ptxmntmin', { largeur: 33.33 }),
            new FormulaireItem('ptxidedec', { largeur: 33.33, modifAllow: false }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ptxinfnom', { largeur: 15 }),
            new FormulaireItem('ptxinfind', { largeur: 15 }),
            new FormulaireItem('ptxinftel', { largeur: 5 }),
            new FormulaireItem('ptxinfpos', { largeur: 5 }),
            new FormulaireItem('ptxinfcou', { largeur: 20 }),
            new FormulaireItem('vaeptxinfcodlng', { largeur: 15 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('ptxctbnom', { largeur: 15 }),
            new FormulaireItem('ptxctbind', { largeur: 15 }),
            new FormulaireItem('ptxctbtel', { largeur: 5 }),
            new FormulaireItem('ptxctbpos', { largeur: 5 }),
            new FormulaireItem('ptxctbcou', { largeur: 20 }),
            new FormulaireItem('vaeptxctbcodlng', { largeur: 15 }),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupeAccordeon('Préparateur', new Formulaire([
                new FormulaireItem('ptxnumpre', {
                    largeur: 33,
                    required: false
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ptxnompre1', {
                    largeur: 33,
                    required: false
                }),
                new FormulaireItem('ptxprepyscod', {
                    largeur: 33,
                    required: false
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ptxnompre2', {
                    largeur: 33,
                    required: false
                }),
                new FormulaireItem('ptxpreetacod', {
                    largeur: 33,
                    resourceParams: (data: any) => ({ pyscleint: data.pyscleint }),
                    required: false
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ptxpreadr1', {
                    largeur: 33,
                    required: false
                }),
                new FormulaireItem('ptxpremunnomfra', {
                    largeur: 33,
                    resourceParams: (data: any) => ({ etacleint: data.etacleint }),
                    required: false
                }),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('ptxpreadr2', {
                    largeur: 50,
                    required: false
                }),
                new FormulaireItem('ptxprecodpos', {
                    largeur: 15,
                    required: false
                })
            ]))
        ]
        )
    };
    vm.pteMultiOccurrenceOptions = {
        bloc: 'pte',
        colonnesVisibles: [
            { nom: 'vaeptetyp', largeur: 33 },
            { nom: 'pteprical', largeur: 33 },
            { nom: 'pteseqmin', largeur: 33 },
            { nom: 'pteseqmax', largeur: 33 },
            { nom: 'pteseqcal', largeur: 33 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaeptetyp', { largeur: 33.33 }),
            new FormulaireItem('pteprical', { largeur: 33.33 }),
            new FormulaireItem('pteseqmin', { largeur: 33.33 }),
            new FormulaireItem('pteseqmax', { largeur: 33.33 }),
            new FormulaireItem('pteseqcal', { largeur: 33.33 })
        ])
    }
}