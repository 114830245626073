import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";
import {IMonoOccurrence, IMonoOccurrenceOptions} from "../../../../../core/services/mono-occurrence.service";
import {IComponentController} from "angular";
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemEcranClass} from "../../../../../core/services/menu/menu-item-ecran.service";
import {IMenuItem} from "../../../../../core/services/menu/menu-item.service";

interface IPageMc0164 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireRem: IFormulaire;
    usaimp: IMenuItem;
    rtaMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mc0164Controller(mc0046Route: IRoute,
                                         Formulaire: IFormulaireClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass ) {
    const vm: IPageMc0164 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Usage d'imputation
        vm.usaimp = new MenuItemEcran('LBL_BLC_UVI', mc0046Route.NAME, () => {
            return {
                vaeusient: 'REM',
                uvicleintref: vm.monoOccurrence.data.remcleint
            };
        }, {
            icon: 'view_list',
            iconButton: false,
            secondaire: true
        });

        vm.formulaireRem = new Formulaire('LBL_BLC_REM', [
            new FormulaireItem('remcod'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('remdscfed'),
            new FormulaireItem('remdscpro')
        ], {largeurDefaut: 50});

        // Taux de remboursement
        vm.rtaMultiOccurrenceOptions = {
            bloc: 'rta',
            colonnesVisibles: [
                {nom: 'rtadatvig'    , largeur: 180},
                {nom: 'rtataufed'    , largeur: 200},
                {nom: 'rtataupro'    , largeur: 200},
                {nom: 'rtataucti'    , largeur: 200},
                {nom: 'rtataurti'    , largeur: 200}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('rtadatvig'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('rtataufed'),
                new FormulaireItem('rtataupro'),
                new FormulaireItem('rtataucti'),
                new FormulaireItem('rtataurti'),
            ], {largeurDefaut: 50})
        };

    }
}
