import { IComponentController } from 'angular';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireGroupeAccordeonClass } from '../../../../../core/services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageAc0005 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    conMultiOccurrenceOptions: IMultiOccurrenceOptions;
    edeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    etrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    edeMultiOccurrence: IMultiOccurrence;
    etcIndFormulaire: IFormulaire;
    mesFormulaire: IFormulaire;
    autFormulaire: IFormulaire;
    modifAchat: IFormulaire;
    valeursEcran: any;
}
/* @ngInject */
export default function Ac0005Controller(
    ecranDetails: IEcranDetailsResourcesEntite,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    Menu: IMenuClass,
    cp0003Route: IRoute,
    mc1012Route: IRoute,
    ac0028Route: IRoute,
    mc1014Route: IRoute,
    ac0007Route: IRoute,
    ac0022Route: IRoute) {

    const vm: IPageAc0005 = this;

        vm.valeursEcran = ecranDetails;
    
        const actionAdrRapide = new MenuItemAction(
            'LBL_BTN_ADR_RAPIDE',
            'BOUADRRAP',
            () => ({}),
            {
                icon: 'add_circle',
                fonction: 'BOUADRRAP',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_ADR_RAPIDE',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 30,
                    formulaire: new Formulaire([
                        new FormulaireItem('radcodnew', { required: true }),
                        new FormulaireItem('radadrl01new', { required: true, maxlength: 40 }),
                        new FormulaireItem('radadrl02new', { maxlength: 40 }),
                        new FormulaireItem('radvilnew', { maxlength: 40 }),
                        new FormulaireItem('radpronew', { maxlength: 40 }),
                        new FormulaireItem('radpaynew', { maxlength: 40 }),
                        new FormulaireItem('radcodposnew', { maxlength: 10 }),
                        new FormulaireItem('radcodcednew', { maxlength: 40 })
                    ], {
                        largeurDefaut: 100
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        );

        const actionLieuPhyrec = new MenuItemAction(
            'LBL_BTN_LIEU_PHYSIQUE',
            'BOULIEPHY',
            () => ({
                type: 'REC'
            }),
            {
                icon: 'add_circle',
                fonction: 'BOULIEPHY',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 50,
                    formulaire: new Formulaire([
                        new FormulaireItem('lipcodnew', { required: true }),
                        new FormulaireItem('lipflgadrfacnew'),
                        new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                        new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                        new FormulaireItem('intcleintnew'),
                        new FormulaireItemDivider('LBL_LIP_ADR'),
                        new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                        new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                        new FormulaireItem('lipvilnew', { maxlength: 40 }),
                        new FormulaireItem('lippronew', { maxlength: 40 }),
                        new FormulaireItem('lippaynew', { maxlength: 40 }),
                        new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                        new FormulaireItem('lipflgquarecnew'),
                        new FormulaireItemDivider(''),
                        new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                        new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                        new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                        new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                        new FormulaireItem('lipcleintrefnew')
                    ], {
                        largeurDefaut: 50
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        );

        const actionLieuPhyfac = new MenuItemAction(
            'LBL_BTN_LIEU_PHYSIQUE',
            'BOULIEPHY',
            () => ({
                type: 'FAC'
            }),
            {
                icon: 'add_circle',
                fonction: 'BOULIEPHYFAC',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_LIEU_PHYSIQUE',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 50,
                    formulaire: new Formulaire([
                        new FormulaireItem('lipcodnew', { required: true }),
                        new FormulaireItem('lipflgadrfacnew'),
                        new FormulaireItem('lipnomnew', { required: true, maxlength: 80 }),
                        new FormulaireItem('lipnomabrnew', { maxlength: 30 }),
                        new FormulaireItem('intcleintnew'),
                        new FormulaireItemDivider('LBL_LIP_ADR'),
                        new FormulaireItem('lipadrl01new', { required: true, maxlength: 40 }),
                        new FormulaireItem('lipadrl02new', { maxlength: 40 }),
                        new FormulaireItem('lipvilnew', { maxlength: 40 }),
                        new FormulaireItem('lippronew', { maxlength: 40 }),
                        new FormulaireItem('lippaynew', { maxlength: 40 }),
                        new FormulaireItem('lipcodposnew', { maxlength: 10 }),
                        new FormulaireItem('lipflgquarecnew'),
                        new FormulaireItemDivider(''),
                        new FormulaireItem('lipnumtelnew', { maxlength: 14 }),
                        new FormulaireItem('lipnumtelposnew', { maxlength: 7 }),
                        new FormulaireItem('lipnumfaxnew', { maxlength: 14 }),
                        new FormulaireItem('lipadrcounew', { maxlength: 60 }),
                        new FormulaireItem('lipcleintrefnew')
                    ], {
                        largeurDefaut: 50
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); }
            }
        );

        const actionModifRef = new MenuItemAction(
            'LBL_BTN_MOD_REF',
            'BOUMODREF',
            () => ({}),
            {
                icon: 'autorenew',
                fonction: 'BOUMODREF',
                formulaireConfirmationAction: {
                    lblTitre: 'LBL_BTN_MOD_REF',
                    lblConfirm: 'G_LBL_BTN_APPLIQUER',
                    largeurFormulaire: 50,
                    formulaire: new Formulaire([
                        new FormulaireItem('refcleint', { required: true }),
                    ], {
                        largeurDefaut: 50
                    })
                },
                successAction: () => { vm.monoOccurrence.fetchData(); },
                hidden: () => !(vm.monoOccurrence.data.etcflgmodref)
            }
        );

        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemAction(
                    'LBL_BTN_MAJ_ITEM',
                    'BOUMAJITM',
                    () => ({}),
                    {
                        iconButton: false,
                        icon: 'refresh',
                        saveFirst: true,
                        messageConfirmation: 'BOUMAJ_MESSAGE',
                        successAction: () => { vm.edeMultiOccurrence.fetchDataList(); },
                        hidden: (data: any) => !data.$id
                    }
                ),
                new MenuItemEcran(
                    'LBL_BTN_HISTO_IMP',
                    ac0022Route.NAME,
                    (data: any) => ({ 
                        dhicleintref: data.etccleint,
                        dhityp: 'ETC'
                    }), 
                    {
                        icon: 'launch',
                        iconButton: false,
                        fonction: 'BOUHISIMP'
                    }
                )
            ]),
            formulaire: new Formulaire('LBL_BLC_ETC', [
                new FormulaireItem('refcleint', { modifAllow: false, menu: [actionModifRef] }),
                new FormulaireItem('etcnumdoc'),
                new FormulaireItem('etcdatdeb'),
                new FormulaireItem('vaeetcstu', { readonly: true }),
                new FormulaireItem('vaeetctyp'),
                new FormulaireItem('etcdatfin'),
                new FormulaireItem('etcnumref'),
                new FormulaireItem('etcrefint'),
                new FormulaireItem('etcdatmax'),
                new FormulaireItem('srvcleint'),
                new FormulaireItem('caacleint'),
                new FormulaireItem('intcleintach'),
                new FormulaireItem('etcdsc', { enableCount: true, largeur: 100 }),
                new FormulaireItem('etcdatimp', { readonly: true }),
                new FormulaireItem('vaesysdevcod', { readonly: true }),
                new FormulaireGroupeAccordeon( 'LBL_GRP_FAC', 
                    new Formulaire([
                        new FormulaireItem('entcleint',
                        {
                            menu: [
                                new MenuItemForage(
                                    'LBL_ENTENTE_ENC',
                                    cp0003Route.NAME,
                                    (data: any) => ({
                                        entcleint: data.entcleint
                                    }),
                                    {
                                        icon: 'exit_to_app',
                                        fonction: 'BOUENT',
                                        iconButton: true,
                                        hidden: () => !vm.monoOccurrence.data.entcleint
                                    }
                                )
                            ]                            
                        }),
                        new FormulaireItem('mopcleint'),
                        new FormulaireItem('teccleint')        
                ], {largeurDefaut: 33.33 })),
                new FormulaireGroupeAccordeon( 'LBL_GRP_ACH',
                    new Formulaire([
                        new FormulaireItem('iaccleint'),
                        new FormulaireGroupe( 'LBL_BLC_ADR',
                            new Formulaire([
                                new FormulaireItem('radcleint', { menu: [actionAdrRapide]}),
                                new FormulaireItem('lipcleint', { menu: [actionLieuPhyrec]}),
                                new FormulaireItem('lipcleintfac', { menu: [actionLieuPhyfac]})        
                            ])
                        ),
                        new FormulaireGroupe( 'LBL_MOD_TRA',
                            new Formulaire([
                                new FormulaireItem('motcleint'),
                                new FormulaireItem('motcleintetc', {required: true}),
                            ])
                        ), {largeur: 50},
                        new FormulaireGroupe( 'LBL_AUTRES',
                            new Formulaire([
                                new FormulaireItem('vecsystercom'),
                                new FormulaireItem('etcdes'),
                                new FormulaireItem('vecsysmodtra'),
                                new FormulaireItem('etcdetexp', {enableCount: true}),
                            ])
                        ), {largeur: 50}
                ], {largeurDefaut: 50})),
            ], {largeurDefaut: 33.33})
        };

        // Section Indicateurs
        vm.etcIndFormulaire = new Formulaire('LBL_BLC_IND', [
            new FormulaireItem('etcflgstu'),
            new FormulaireItem('etcflggenitm'),
            new FormulaireItem('etcflgpij'),
            new FormulaireItem('etcflgitm'),
            new FormulaireItem('etcflgfrsacc'),
            new FormulaireItem('etcflgresitm')
        ], { largeurDefaut: 50 });

        // Message/Description autres
        vm.mesFormulaire = new Formulaire('LBL_BLC_MES', [
            new FormulaireItem('etcmsg'),
            new FormulaireItem('etcdacmsg'),
            new FormulaireItem('etcinsdacmsg'),
            new FormulaireItem('etcdsclng')
        ], { largeurDefaut: 100});

        // Autres info
        vm.autFormulaire = new Formulaire('LBL_BLC_AUT', [
            new FormulaireItem('founomabr', { largeur: 100, readonly: true }),
            new FormulaireItem('etcnumresca'),
            new FormulaireItem('etcnumrescd', {
                menu: [
                    new MenuItemEcran('LBL_HISTO_RESOL',
                        mc1012Route.NAME,
                        (data: any) => ({ doccleintref: data.etccleint }), {
                        icon: 'launch',
                        iconButton: true
                    })
                ]            
            }),
            new FormulaireItem('apocleint', { 
                largeur: 100,
                resourceParams:  (data:any) => ({ refcleint: data.refcleint }),
                menu: [
                    new MenuItemForage(
                        'LBL_APO',
                        ac0028Route.NAME,
                        (data: any) => ({
                            id: data.doccleintapo
                        }),
                        {
                            icon: 'exit_to_app',
                            fonction: 'BOUAPO',
                            iconButton: true,
                            hidden: () => !vm.monoOccurrence.data.apocleint
                        }
                    )
                ]                            
            }),
            new FormulaireItem('cfncleint', { 
                largeur: 100,
                resourceParams: (data:any) => ({
                    refcleint: data.refcleint,
                    etcdatdeb: data.etcdatdeb,
                    etcdatfin: data.etcdatfin
                })
            }),
            new FormulaireItem('etcnumao', { largeur: 100 })
        ]);

        vm.modifAchat = new Formulaire( 'LBL_BLC_MODACH', [
            new FormulaireItem('emoflgdsc'),
            new FormulaireItem('emoflgfor'),
            new FormulaireItem('emoflgcat'),
            new FormulaireItem('emoflgprx')
        ], { largeurDefaut: 50 });

    vm.etrMultiOccurrenceOptions = {
        bloc: 'etr',
        actionsNouveaux: new Menu([
            new MenuItemForage('',
                ac0007Route.NAME,
                () => ({
                    etccleint: vm.monoOccurrence.data.etccleint
                }),
                {
                    icon: 'add_circle'
                }
            )
        ]),
        actionsRangeeDroite: new Menu([
            new MenuItemForage(
                'LBL_DETAIL',
                ac0007Route.NAME,
                (data: any) => ({
                    id: data.doccleint,
                    etccleint: vm.monoOccurrence.data.etccleint
                }),
                {
                    icon: 'exit_to_app',
                    fonction: 'BOUDOC',
                    iconButton: true
                }
            )
        ]),
        colonnesFixesGauche: [
            {nom: 'etcnumdocseq', largeur: 80},
            {nom: 'etrdatdeb', largeur: 126},
            {nom: 'etrdatfin', largeur: 126},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnomreq', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'etrmntetc', largeur: 120, total: true},
            {nom: 'etddsc', largeur: 150},
            {nom: 'taxcod', largeur: 120},
            {nom: 'etrmntcon', largeur: 120},
            {nom: 'etrmntcondoc', largeur: 120},
            {nom: 'etrmntpay', largeur: 120},
            {nom: 'etrflgaut', largeur: 100},
            {nom: 'etrflgact', largeur: 100},
            {nom: 'etrflgref', largeur: 100}
        ]
    }

    vm.edeMultiOccurrenceOptions = {
        bloc: 'ede',
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_TOLERANCE',
                mc1014Route.NAME,
                (rowData:any) => ({
                    tolcleintref: rowData.edecleint,
                    vaeustent: 'EDE'
                }),
                {
                    fonction: 'BOUTOL',
                    icon: 'data_usage',
                    iconButton: false,
                    secondaire: true
                }
            )
        ]),
        colonnesVisibles: [
            {nom: 'procod', largeur: 180, hidden: () => (!vm.valeursEcran.cieflggesproapp)},
            {nom: 'edeitmdsc', largeur: 300},
            {nom: 'edeitmnumcat', largeur: 150},
            {nom: 'edeitmprxneg', largeur: 120},
            {nom: 'ededatdeb', largeur: 126},
            {nom: 'ededatfin', largeur: 126},
            {nom: 'edeitmdsclng', largeur: 300},
            {nom: 'caacod', largeur: 100},
            {nom: 'caadsc', largeur: 200},
            {nom: 'forcod', largeur: 100},
            {nom: 'fordsc', largeur: 200},            
            {nom: 'emoflgdsc', largeur: 140},            
            {nom: 'emoflgfor', largeur: 140},            
            {nom: 'emoflgcat', largeur: 140},            
            {nom: 'emoflgprx', largeur: 140}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('procleint', { hidden: () => (!vm.valeursEcran.cieflggesproapp)}),
            new FormulaireItem('edeitmdsc'),
            new FormulaireItem('edeitmnumcat'),
            new FormulaireItem('edeitmprxneg'),
            new FormulaireItem('ededatdeb'),
            new FormulaireItem('ededatfin'),
            new FormulaireItem('edeitmdsclng', { enableCount: true }),
            new FormulaireItem('caacleint'),
            new FormulaireItem('forcleint', {
                resourceParams: (data:any) => ({ procleint: data.procleint })
            }),
            new FormulaireItem('emoflgdsc'),
            new FormulaireItem('emoflgfor'),
            new FormulaireItem('emoflgcat'),
            new FormulaireItem('emoflgprx'),    
        ], { largeurDefaut: 33.33 })
    }
    vm.conMultiOccurrenceOptions = {
        bloc: 'con',
        colonnesVisibles: [
            {nom: 'connumdoc', largeur: 100},
            {nom: 'tyddsc', largeur: 140},
            {nom: 'connumref', largeur: 130},
            {nom: 'condatdeb', largeur: 126},
            {nom: 'condatfin', largeur: 126},
            {nom: 'coamnt', largeur: 120},
            {nom: 'condsc', largeur: 300}
        ]
    };
}

