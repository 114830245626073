import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageDB0004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Db0004Controller(db0005Route: IRoute,
    Menu: IMenuClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    MenuItemForage: IMenuItemForageClass) {
        
    const vm: IPageDB0004 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: db0005Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(db0005Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup', {hidden: ecranDetails.valeurs.vaehldtypimp !== 1}),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de', {required: (data:any) => data.pruselsup_a}),
            new FormulaireItem('pruselsup_a', {required: (data:any) => data.pruselsup_de})
        ]),
        colonnesVisibles: [
            {nom: 'ecbnumdoc', largeur: 136},
            {nom: 'ecbnumdocseq', largeur: 65},
            {nom: 'peccod', largeur: 80},
            {nom: 'tydcod', largeur: 65},
            {nom: 'vececbcat', largeur: 95},
            {nom: 'ecbref', largeur: 150},
            {nom: 'ecbdsc', largeur: 250},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 180},
            {nom: 'etddsc', largeur: 180},
            {nom: 'flgcou', largeur: 100},
            {nom: 'ecbflgref', largeur: 100},
            {nom: 'ecbflgverfin', largeur: 100},
            {nom: 'ecbflgmsg', largeur: 100},
            {nom: 'usrcodcre', largeur: 150},
            {nom: 'ecbflgautrep', largeur: 100},
            {nom: 'ecbflgpij', largeur: 100},
            {nom: 'ecbmnttot', largeur: 130},
            {nom: 'ecbmntcrt', largeur: 130}
        ]
    };
}
