import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageRr0545 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0545Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageRr0545 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true
        },
        colonnesVisibles: [
            {nom: 'urgcod', largeur: 100},
            {nom: 'urgdscabr', largeur: 200},
            {nom: 'unocod', largeur: 150},
            {nom: 'unodscabr', largeur: 250},
            {nom: 'rumcodtra', largeur: 120},
            {nom: 'rumcodsyn', largeur: 120}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('urgcod'),
            new FormulaireItem('unocod'),
            new FormulaireItem('rumcodtra', {largeur: 20}),
            new FormulaireItem('rumcodsyn', {largeur: 20})
        ],{largeurDefaut: 30})
    };
}
