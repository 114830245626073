import {IComponentController} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';
import {IFormulaireGroupeAccordeonClass} from "../../../../../core/services/formulaire/formulaire-groupe-accordeon.service";
import {IOperateurService} from "../../../../../core/services/operateur.service";

interface IPageRr0036 extends IComponentController {
    valeursEcran: any;
    formulaireDaf: IFormulaire;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dchMultiOccurrenceOptions: IMultiOccurrenceOptions;
    bnrMultiOccurrenceOptions: IMultiOccurrenceOptions;    
}

interface IRr0036Params {    id: string;
    demcleint: string;
}

/* @ngInject */
export default function rr0036Controller($stateParams: IRr0036Params,
                                         rr0056Route: IRoute,
                                         rr0165Route: IRoute,
                                         rr0164Route: IRoute,
                                         Operateur: IOperateurService,
                                         Formulaire: IFormulaireClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         Menu: IMenuClass) {
    const vm: IPageRr0036 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions = {
            actionsMore: new Menu([
                new MenuItemEcran(
                    'LBL_BTN_SIMULATION_RCE',
                    rr0164Route.NAME,
                    (data:any) => ({
                        id: data.trncleint,
                        demcleint: data.demcleint,
                        type: 'DAF'
                    }),
                    {
                        icon: 'refresh',
                        iconButton: false,
                        fonction: 'BTN_SIMULATION_RCE'
                    }
                ),
                new MenuItemEcran(
                    'LBL_BTN_DER_PERIODE_PAIE',
                    rr0165Route.NAME,
                    (data:any) => ({
                        id: data.trncleint,
                        demcleint: data.demcleint,
                        type: 'DAF'
                    }),
                    {
                        icon: 'attach_money',
                        iconButton: false,
                        fonction: 'BTN_DER_PERIODE_PAIE'
                    }
                )
            ])
        };

        vm.formulaireDaf = new Formulaire('LBL_BLC_DAF', [
            new FormulaireItem('demcleint', {
                largeur: 50,
                default: $stateParams.demcleint
            }),
            new FormulaireItem('aut__demage' , {largeur: 50}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('fpncleint', {
                resourceParams: () => ({
                    demcleint: vm.monoOccurrence.data.demcleint
                }),
                largeur: 50
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dafdatdeb'),
            new FormulaireItem('dafflgcot'),
            new FormulaireItem('dafdatadm'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dafdatfin'),
            new FormulaireItem('dafdatreseau'),
            new FormulaireItem('dafdatfinrem'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dafcom', {largeur: 100, enableCount: true}),

            new FormulaireGroupeAccordeon('LBL_ACCORDEON_AUTRES_INFOS', new Formulaire([
                new FormulaireItem('dafnumdoc'),
                new FormulaireItem('dafseqmodcou'),
                new FormulaireItem('ttccleint', {
                    nombreLibellesDetailsCacher: 1,
                    largeur: 50
                })
            ], {largeurDefaut: 25})),
        ], {largeurDefaut: 25});

        const dchIsReadonly = (data: any) => data.dchseqmod < vm.monoOccurrence.data.dafseqmodcou;

        vm.dchMultiOccurrenceOptions = {
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_FORAGE_RR0056',
                    rr0056Route.NAME,
                    (data: any) => {
                        return {
                            dchcleint: data.dchcleint,
                            rcecleintref: data.rcecleint,
                            demcleint: data.$ancetre.demcleint
                        };
                    },
                    {
                        fonction: 'BTN_AJUSTEMENT',
                        icon: 'view_list'
                    }
                )
            ]),
            filtres: [
                {
                    colonne: 'dchflgact',
                    operateur: Operateur.EGALE
                },
                {
                    colonne: 'dchflgcou',
                    operateur: Operateur.EGALE
                }
            ],
            colonnesVisibles: [
                {nom: 'fpocod', largeur: 90},
                {nom: 'fpodsc', largeur: 250},
                {nom: 'fsscod', largeur: 130},
                {nom: 'fssdsc', largeur: 250},               
                {nom: 'dchdatdeb', largeur: 100},
                {nom: 'dchdatfin', largeur: 100},
                {nom: 'dchflgact', largeur: 100},
                {nom: 'dchval', largeur: 126},
                {nom: 'dchseqmod', largeur: 100}
            ],
            colonnesCachees: [
                'dchcleint',
                'rcecleint',
                'dchflgcou'
            ],
            formulaire: new Formulaire([
                new FormulaireItem('fpocleint', {
                    largeur: 50,
                    resourceParams: () => ({
                        fpncleint: vm.monoOccurrence.data.fpncleint
                    }),
                    readonly: dchIsReadonly
                }),
                new FormulaireItem('fsscleint', {largeur: 50, readonly: dchIsReadonly}),                
                new FormulaireItem('dchdatdeb', {readonly: dchIsReadonly}),
                new FormulaireItem('dchdatfin'),
                new FormulaireItem('dchval', {readonly: dchIsReadonly}),
                new FormulaireItem('dchflgact', { default: 1, readonly: (rowData:any) => (!rowData.dchflgact) }),
                new FormulaireItem('dchseqmod', { hidden: true, default: () => vm.monoOccurrence.data.dafseqmodcou })
            ], {largeurDefaut: 25})
        };

        vm.bnrMultiOccurrenceOptions = {
            colonnesVisibles: [
                {nom: 'bnrnom', largeur: 250},
                {nom: 'bnrprn', largeur: 250},
                {nom: 'bnrdatnai', largeur: 130},
                {nom: 'vacvalcle', largeur: 140},
                {nom: 'vacdsc', largeur: 230},
                {nom: 'vaedsc', largeur: 85},
                {nom: 'bnrflgact', largeur: 100}
            ],
            formulaire: new Formulaire([
                new FormulaireItem('bnrnom'),
                new FormulaireItem('bnrprn'),
                new FormulaireItem('bnrdatnai'),
                new FormulaireItem('vacliepar'),
                new FormulaireItem('vaesyssex'),
                new FormulaireItem('bnrseqmod', { readonly: true, default: 0 } ),
                new FormulaireItem('bnrflgact', { default: 1 })
            ],  {largeurDefaut: 33})
        };
        
    }
}
