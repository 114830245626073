import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IComponentController } from 'angular';

interface IPageGs0074 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Gs0074Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageGs0074 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            recherche: true,
            rechercheCollapsable: false,
            supprime: false
        },
        colonnesVisibles: [
            { nom: 'vaegssfctspe_dsc', largeur: 160 },
            { nom: 'usrcod', largeur: 126 },
            { nom: 'usrnom', largeur: 262 },
            { nom: 'ssudatdeb', largeur: 100 },
            { nom: 'ssudatfin', largeur: 100 }
        ],
        formulaire: new Formulaire(
            [
                new FormulaireItem('gsscleint', { nombreLibellesDetailsCacher: 1 }),
                new FormulaireItem('usrcleint', { largeur: 40 }),
                new FormulaireItem('ssudatdeb'),
                new FormulaireItem('ssudatfin')
            ],
            { largeurDefaut: 20 }
        )
    };
}