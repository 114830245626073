import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageMr0193 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTrn: IFormulaire;
    formulaireAug: IFormulaire;
    formulaireBqe: IFormulaire;
    chtMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cdaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    chmMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr0193Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0193 = this;

    vm.$onInit = function $onInit() {
        vm.monoOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            formulaire: new Formulaire('LBL_BLC_CHS', [
                new FormulaireItem('urgcleint'),
                new FormulaireItem('strcleint')
            ])
        };

        vm.formulaireAug = new Formulaire('LBL_BLC_AUG', [
            new FormulaireItem('mchcleint'),
            new FormulaireItem('ttccleintaug'),
            new FormulaireItem('chsnbrperdecaug')
        ]);

        vm.formulaireTrn = new Formulaire('LBL_BLC_TRN', [
            new FormulaireItem('ttccleintech', { nombreLibellesDetailsCacher: 2 })
        ]);

        vm.formulaireBqe = new Formulaire('LBL_BLC_BQE', [
            new FormulaireItem('bqecleintaug', { largeur: 100 }),
            new FormulaireItem('chssldbqeaug'),
            new FormulaireItem('unccleintaug'),
            new FormulaireItem('chsnbrmoi', { largeur: 33.33 }),
            new FormulaireItem('chsnbrechexc2x', { largeur: 33.33 })
        ]);

        vm.chtMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'tagcod', largeur: 89 },
                { nom: 'tagdscabr', largeur: 304 },
                { nom: 'mchnummth', largeur: 95 },
                { nom: 'mchdsc', largeur: 304 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('tagcleint'),
                new FormulaireItem('mchcleint')
            ], { largeurDefaut: 33.33 })
        };

        vm.cdaMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'cdadataug', largeur: 126 },
                { nom: 'cdadatembcon', largeur: 186 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('cdadataug', { largeur: 33.33 }),
                new FormulaireItem('cdadatembcon', { largeur: 33.33 })
            ])
        };

        vm.chmMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                { nom: 'mchnummth', largeur: 95 },
                { nom: 'mchdsc', largeur: 367 },
                { nom: 'chmtxt', largeur: 467 },
                { nom: 'chmflgimp', largeur: 100 }
            ],
            formulaire: new Formulaire([
                new FormulaireItem('mchcleint', { largeur: 30 }),
                new FormulaireItem('chmtxt', { largeur: 50 }),
                new FormulaireItem('chmflgimp', { default: 1, largeur: 20 })
            ])
        };
    }
}
