import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";
import {IFormulaireGroupeClass} from '../../../../../core/services/formulaire/formulaire-groupe.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';
import {IMenuItemEcranClass} from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageMr0276 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceRVAOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0276Controller(mr0134Route: IRoute,
                                         mr0277Route: IRoute,
                                         Menu: IMenuClass,
                                         Pagination: IPaginationClass,
                                         MenuItemForage: IMenuItemForageClass,
                                         MenuItemEcran: IMenuItemEcranClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireGroupe: IFormulaireGroupeClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0276 = this;

    // Forage vers la RCE
    const forageRce = new MenuItemForage(
        'LBL_FOR_RCE',
        mr0134Route.NAME,
        (data: any) => (
            {
                id: data.rcecleint
            }
        )
    );

    vm.multiOccurrenceRVAOptions = {
        bloc: 'rva',
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 25, 50]
        }),
        colonnesVisibles: [
            {nom: 'rvanumval', largeur: 90},
            {nom: 'rvadsc', largeur: 400},
            {nom: 'rvaval', largeur: 175},
            {nom: 'vaervatyp_dsc', largeur: 190},
            {nom: 'rvaflgoblemp', largeur: 170}
        ]
    };

    vm.multiOccurrenceOptions = {
        actionsMoreLigne: new Menu([
            new MenuItemEcran(
                'LBL_COPIE',
                mr0277Route.NAME,
                (data: any) => ({
                    id: data.rcpcleint,
                    rcpcleint: data.rcpcleint
                }),
                {
                    icon: 'file_copy',
                    iconButton: false
                }
            )
        ]),
        fonctions:{
            suiviModification: false,
            rechercheCollapsable: false
        },
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 25, 50]
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('t_datparam',{largeur: 100}),
                new FormulaireItem('t_farcleint',{largeur: 100}),
                new FormulaireItem('t_rcecleint',{largeur: 100})
            ]), {largeur: 40}),

            new FormulaireGroupe( new Formulaire([
                new FormulaireItem('t_vaervatyp', {
                    direction: 'vertical',
                    largeur: 100})
            ]), {largeur: 60}),

        ]),

        colonnesVisibles: [
            {nom: 'rcecod', largeur: 126},
            {nom: 'rcedsc', largeur: 500, menu: [forageRce]},
            {nom: 'rcpdatvig', largeur: 126}
        ],
        colonnesCachees: [
            'rcecleint'
        ],
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceRVAOptions
    };
}
