import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageTf0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Tf0016Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageTf0016 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            boutonDupliquer: false
        },
        colonnesVisibles: [
            {nom: 'rcecod'  , largeur: 105},
            {nom: 'rcedsc'  , largeur: 262},
            {nom: 'tfjnoref', largeur: 185}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rcecleint', {required: true}),
            new FormulaireItem('tfjnoref' , {required: true})
        ])
    };
}
