import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageIn0006 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    valeursEcran: any;
}

/* @ngInject */
export default function In0006Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    notificationHandler: INotificationHandler) {
    const vm: IPageIn0006 = this;
    vm.valeursEcran = ecranDetails.valeurs;

    if (vm.valeursEcran.mesina) {
        notificationHandler.info({
            lblMessage: vm.valeursEcran.mesina
        });
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            importation: true,
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'st1cod', largeur: 110 },
            { nom: 'st2cod', largeur: 110 },
            { nom: 'st3cod', largeur: 110 },
            { nom: 'st4cod', largeur: 90 },
            { nom: 'st5cod', largeur: 90 },
            { nom: 'st5dsc', largeur: 231 }
        ],
        colonnesVisibles: [
            { nom: 'st5dscabr', largeur: 231 },
            { nom: 'st1dscabrx', largeur: 231 },
            { nom: 'st2dscabrx', largeur: 231 },
            { nom: 'st3dscabrx', largeur: 231 },
            { nom: 'st4dscabrx', largeur: 231 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('st1coddsc', { readonly: true }),
            new FormulaireItem('st2coddsc', { readonly: true }),
            new FormulaireItem('st3coddsc', { readonly: true }),
            new FormulaireItem('st4cleint'),

            new FormulaireItemSautDeLigne(),
            new FormulaireItem('st5cod'),
            new FormulaireItem('st5dsc'),
            new FormulaireItem('st5dscabr'),

        ], { largeurDefaut: 33.33 })
    };
}
