import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemSelectionClass } from '../../../../../core/services/menu/menu-item-selection.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IStateParamsService } from 'angular-ui-router';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';

interface IPageCr2004 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cr2004Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItemSelection: IMenuItemSelectionClass,
    $stateParams: IStateParamsService,
    MenuItemForage: IMenuItemForageClass,
    Operateur: IOperateurService,
    cr0013Route: IRoute
) {
    const vm: IPageCr2004 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            effaceCriteres: true
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('loecleintcri', { default: $stateParams.loecleint, disabled: (data: any) => ($stateParams.loecleint) })
        ]),      
        actionsSelection: new Menu([
            new MenuItemSelection(
                'LBL_BTN_SELECTION',
                'BOUSEL',
                {
                    selection: { nom: 'selection' }
                })
        ]),
        colonnesVisibles: [
            {
                nom: 'encnumdoc', largeur: 120,
                menu: [
                    new MenuItemForage(
                        '',
                        cr0013Route.NAME,
                        (data: any) => {
                            return {
                                id: data.doccleintref
                            };
                        }
                    )
                ]
            },
            { nom: 'loecod', largeur: 120 },
            { nom: 'encmnt', largeur: 126 },
            { nom: 'vaesysdevcod', largeur: 100 },
            { nom: 'encmntrevnet', largeur: 126 },
            { nom: 'encdat', largeur: 130 },
            { nom: 'refnom', largeur: 350 }
        ],
        colonnesCachees: ['doccleintref']
    };
}
