import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IFormulaireClass } from "../../../../../core/services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../../../../core/services/formulaire/formulaire-item.service";
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';


interface IPageCP0039 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0039Controller(Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageCP0039 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            supprime: false,
            nouveau: false,
            criteresSuggeresVisibles: false
        },
        criteresSuggeres: new Formulaire([
            new FormulaireItem('cifcodselsup'),
            new FormulaireItem('grpcodselsup'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pruselsup_de'),
            new FormulaireItem('pruselsup_a')
        ]),
        actionsNouveaux: new Menu([]),
        colonnesFixesGauche: [
            { nom: 'connumdoc', largeur: 89 },
            { nom: 'connumdocseq', largeur: 65 }
        ],
        colonnesVisibles: [
            { nom: 'coaann', largeur: 72 },
            { nom: 'coamnt', largeur: 126 },
            { nom: 'coamntutl', largeur: 126 },
            { nom: 'tydcod', largeur: 65 },
            { nom: 'refcod', largeur: 105 },
            { nom: 'refnomabr', largeur: 262 },
            { nom: 'etddsc', largeur: 180 },
            { nom: 'intcod', largeur: 110 },
            { nom: 'intnom', largeur: 220 },
            { nom: 'condatdeb', largeur: 100 },
            { nom: 'condatfin', largeur: 100 },
            { nom: 'founomabr', largeur: 180 },
            { nom: 'srvcod', largeur: 89 },
            { nom: 'srvdscabr', largeur: 210 },
            { nom: 'condsc', largeur: 262 },
            { nom: 'connumref', largeur: 165 },
            { nom: 'conmnt', largeur: 126 },
            { nom: 'vaesysdevcod', largeur: 120 },
            { nom: 'vecconcat_dsc', largeur: 140 },
            { nom: 'flgact', largeur: 100 },
            { nom: 'coaflgini', largeur: 75 },
            { nom: 'conflgnonren', largeur: 115 }
        ]
    };
}
