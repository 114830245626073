import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageSO0003 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function So0003Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSO0003 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'nirniv', largeur: 78},
            {nom: 'nirdscabr', largeur: 315},
            {nom: 'nirdsc', largeur: 395},
            {nom: 'vaesystypniv', largeur: 190}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('nirniv', {required: true, largeur: 15}),
            new FormulaireItem('nirdscabr', {required: true, largeur: 35}),
            new FormulaireItem('nirdsc', {
                required: true,
                onFocus(rowData: any) {
                    if (!rowData.nirdsc) {
                        rowData.nirdsc = rowData.nirdscabr;
                    }
                },
                largeur: 35}),
            new FormulaireItem('vaesystypniv', {required: true, largeur: 15})
        ])
    };
}
