import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IIsForageValideService } from '../../../../../core/services/is-forage-valide.service';
import { IMenuItemFactoryClass } from '../../../../../core/services/menu/menu-item-factory.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';

interface IPageMR0208 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    tfdMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tfdMultiOccurrence: IMultiOccurrence;
    tfdaMultiOccurrenceOptions: IMultiOccurrenceOptions;
    tfdaMultiOccurrence: IMultiOccurrence;
    formulaireCriteres: IFormulaire;
    totMultiOccurrenceOptions: IMultiOccurrenceOptions;
    totMultiOccurrence: IMultiOccurrence;
}

interface IMr0208Params {
    demcleint: string;
    doccleintgrh: string;
    typtri: string;
    tyhcleint: string;
    annfin: string;
}

/* @ngInject */
export default function Mr0208Controller($stateParams: IMr0208Params,
    mc0102Route: IRoute,
    mc0101Route: IRoute,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    isForageValide: IIsForageValideService,
    MenuItemFactory: IMenuItemFactoryClass,
    Operateur: IOperateurService,
    FormulaireItem: IFormulaireItemClass,
    Formulaire: IFormulaireClass) {
    const vm: IPageMR0208 = this;

    vm.$onInit = function $onInit() {
        vm.formulaireCriteres = new Formulaire([
            new FormulaireItem('annfin', { required: false, largeur: 15 }),
            new FormulaireItem('tyhcleint', { required: false, largeur: 45 })
        ]);

        vm.tfdMultiOccurrenceOptions = {
            bloc: 'tfd',
            autoFetch: true,
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: true
            },
            criteresSuggeres: vm.formulaireCriteres,
            actionsMoreLigne: new Menu([
                new MenuItemForage(
                    'LBL_HIST',
                    mc0102Route.NAME,
                    (data: any) => ({
                        doccleintref: data.doccleintgrh
                    }),
                    {
                        icon: 'exit_to_app'
                    }
                ),
                new MenuItemForage(
                    'LBL_COR',
                    mc0101Route.NAME,
                    (data: any) => ({
                        doccleintref: data.doccleintgrh,
                        ajfcleint: data.ajfcleint
                    }),
                    {
                        icon: 'exit_to_app'
                    }
                )
            ]),
            colonnesVisibles: [
                { nom: 'tfdpecann', largeur: 110 },
                { nom: 'imp', largeur: 200 },
                { nom: 'tyhdsc', largeur: 155 },
                { nom: 'tfdmnt', total: true, largeur: 120 },
                { nom: 'tfdmntavs', total: true, largeur: 150 },
                { nom: 'prudscl01', largeur: 200 }
            ],
            colonnesCachees: [
                'doccleintgrh',
                'doccleintref'
            ]
        };
        vm.tfdaMultiOccurrenceOptions = {
            bloc: 'tfda',
            actionsRangeeDroite: new Menu([
                new MenuItemFactory(getForageItem)
            ]),
            autoFetch: true,
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: true
            },
            criteresSuggeres: vm.formulaireCriteres,
            filtres: [
                {
                    colonne: 'doccleintgrh',
                    operateur: Operateur.EGALE_PAS,
                    valeur: $stateParams.doccleintgrh,
                    visible: false
                }
            ],
            colonnesVisibles: [
                { nom: 'tfdpecann', largeur: 110 },
                { nom: 'imp', largeur: 200 },
                { nom: 'tyhdsc', largeur: 155 },
                { nom: 'tfdmnt', total: true, largeur: 120 },
                { nom: 'tfdmntavs', total: true, largeur: 150 },
                { nom: 'docdsc', largeur: 200 }
            ],
            colonnesCachees: [
                'ecrcod',
                'trncleint'
            ]
        };

        vm.totMultiOccurrenceOptions = {
            bloc: 'tot',
            autoFetch: true,
            fonctions: {
                customCriteresSuggeres: true,
                rechercheCollapsable: true,
                nouveau: false
            },
            criteresSuggeres: vm.formulaireCriteres,
            colonnesVisibles: [
                { nom: 'colonne_vide_0', largeur: 110, replacedValue: ' ', titre: ' ' },
                { nom: 'colonne_vide_1', largeur: 200, replacedValue: ' ', titre: ' ' },
                { nom: 'colonne_vide_2', largeur: 155, replacedValue: ' ', titre: ' ' },
                { nom: 'total_mnt', largeur: 120, replacedValue: ' '},
                { nom: 'total_avt', largeur: 150, replacedValue: ' '},
                { nom: 'colonne_vide_3', largeur: 200, replacedValue: ' ', titre: ' ' }
            ]
        };
    }

    function getForageItem() {
        return new MenuItemForage(
            'BTN_FORAGE',
            (data: any) => (`secure.${data.ecrcod}.ecran`),
            (data: any) => ({
                id: data.trncleint
            }),
            {
                hidden: (data: any) => {
                    return !isForageValide.isForageValide('mr0208', data.ecrcod);
                }
            }
        )
    }
}
