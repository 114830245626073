import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";

interface IPagePF0031 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Pf0031Controller(pf0011Route: IRoute) {

    const vm: IPagePF0031 = this;

    const pf0029Route = {NAME: 'secure.accueil'};

    vm.multiOccurrenceOptions = {
        navigatePage: (rowData: any) => (rowData.tytcleint === 59) ? pf0011Route.NAME : pf0029Route.NAME,
        fonctions: {
            selectionCycle: false
        },
        colonnesVisibles: [
            {nom: 'tpccod'      , largeur: 130},
            {nom: 'tpcdsc'      , largeur: 200},
            {nom: 'numdoc'      , largeur: 130},
            {nom: 'cabcod'      , largeur: 130},
            {nom: 'cabdsc'      , largeur: 200}
        ],
        colonnesFixesDroite: [
            {nom: 'mntacc'      , largeur: 126, total: true},
            {nom: 'mntreel'     , largeur: 126, total: true},
            {nom: 'mnttot'      , largeur: 126, total: true}
        ],
        colonnesCachees: ['tytcleint']
    };
}
