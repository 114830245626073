import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMc0078 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0078Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMc0078 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            {nom: 'rgaind', largeur: 110},
            {nom: 'rgadsc', largeur: 288},
            {nom: 'rgadscabr', largeur: 170},
            {nom: 'vaesysnatctb_dsc', largeur: 113},
            {nom: 'vaergaapp_dsc', largeur: 113}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('rgaind', {largeur: 50}),
            new FormulaireItem('rgadsc', {largeur: 50}),
            new FormulaireItem('rgadscabr', {largeur: 50}),
            new FormulaireItem('vaesysnatctb', {largeur: 50, required: true}),
            new FormulaireItem('vaergaapp', {largeur: 50})
        ])
    };
}
