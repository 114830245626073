import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';

interface IPageMc0128 {

    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0128Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageMc0128 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: true,
            effaceCriteres: false,
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: true,
            criteresSuggeresVisibles: true
        },

        pagination: {
            nbElementsPossibles: [100, 200, 300],
            nombreElementPage: 100
        },

        criteresSuggeres: new Formulaire([
            new FormulaireItem('redcleintcri', { largeur: 30}),
            new FormulaireItem('peccleintcri', { largeur: 30}),
            new FormulaireItem('docdatdebcri', { largeur: 20}),
            new FormulaireItem('docdatfincri', { largeur: 20}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('tydcleintcri', { largeur: 30}),
            new FormulaireItem('vecprucatprocri', { largeur: 30}),
            new FormulaireItem('docaffflgconfincri', { default: 0, largeur: 30}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('intcleintcri', { largeur: 30}),
            new FormulaireItem('docaffnumdoc01cri', { largeur: 30, maxlength: 30}),
            new FormulaireItem('refcleintcri', { largeur: 30})
        ]),
        colonnesFixesGauche: [
            { nom: 'docaffnumdoc01', largeur: 100 },
            {nom: 'tydcod', largeur: 100},
            {nom: 'peccod', largeur: 100}
        ],
        colonnesVisibles: [
            {nom: 'docaffnumref01', largeur: 200},
            {nom: 'prucod', largeur: 130},
            {nom: 'prudscl01', largeur: 400},
            {nom: 'docaffdat01', largeur: 100},
            {nom: 'intcod', largeur: 100},
            {nom: 'intnom', largeur: 200},
            {nom: 'docaffmnt01', largeur: 100}
        ],
    };
}
