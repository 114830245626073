import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'do0037',
    ecrans: ['00-2'],
    params: [
        'id',
        '?trncleint',
        '?poscleint'
    ]
});
