import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';

interface IPageMc0049 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mc0049Controller(mc2014Route: IRoute,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemEcran: IMenuItemEcranClass,
    Menu: IMenuClass) {
    const vm: IPageMc0049 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        actionsMoreLigne: new Menu([
            new MenuItemEcran('LBL_DETAIL',
                mc2014Route.NAME,
                (data: any) => ({
                    id: data.tydcleint
                }),
                {
                    icon: 'view_list',
                    iconButton: false
                }
            )
        ]),        
        colonnesVisibles: [
            { nom: 'tydcleint', largeur: 100 },
            { nom: 'tydcod', largeur: 120 },
            { nom: 'tyddsc', largeur: 200 },
            { nom: 'tydflgdocref', largeur: 120 },
            { nom: 'tydflgsof', largeur: 120 },
            { nom: 'tydflgsaisof', largeur: 120 },
            { nom: 'tydflgimppru', largeur: 120 },
            { nom: 'tydflgdocgrh', largeur: 120 },
            { nom: 'tydflgtrspec', largeur: 120 },
            { nom: 'tydmnedoc', largeur: 120 },
            { nom: 'vaesysmodcod_dsc', largeur: 200 },
            { nom: 'vaetypsorfon_dsc', largeur: 200 },
            { nom: 'vaemoeentfon_dsc', largeur: 200 },
            { nom: 'ecrcoddsc', largeur: 230 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tydcleint'),
            new FormulaireItem('tydcod'),
            new FormulaireItem('tyddsc', {largeur: 50}),
            new FormulaireItem('tydmnedoc'),
            new FormulaireItem('vaesysmodcod'),
            new FormulaireItem('vaetypsorfon'),
            new FormulaireItem('vaemoeentfon'),
            new FormulaireItem('ecrcleint'),
            new FormulaireItemDivider('LBL_DIV_INDICATEUR'),
            new FormulaireItem('tydflgdocref'),
            new FormulaireItem('tydflgsof'),
            new FormulaireItem('tydflgsaisof'),
            new FormulaireItem('tydflgimppru'),
            new FormulaireItem('tydflgdocgrh'),
            new FormulaireItem('tydflgtrspec')
        ], {largeurDefaut: 25})
    };
}