import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageSp0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Sp0010Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSp0010 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'veecod', largeur: 108},
            {nom: 'veedsc', largeur: 220},
            {nom: 'vcecod', largeur: 89},
            {nom: 'vcedsc', largeur: 284},
            {nom: 'vceflgact', largeur: 65}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('veecleint', {largeur: 50, required: true}),
            new FormulaireItem('vcecod', {largeur: 25, required: true}),
            new FormulaireItem('vceflgact', {largeur: 25, default: 1}),
            new FormulaireItem('vcedsc', {largeur: 100, required: true}),
        ])
    };
}
