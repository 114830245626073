import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageRR0008 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0008Controller(rr0002Route: IRoute,
    Operateur: IOperateurService,
    Menu: IMenuClass,
    Pagination: IPaginationClass,
    $stateParams: IStateParamsService,
    MenuItemForage: IMenuItemForageClass,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass) {
    const vm: IPageRR0008 = this;

    let filtres;
    if ($stateParams.ecran === '00-2') {
        filtres = [
            {
                colonne: 'trnflgstaann',
                operateur: Operateur.EGALE_PAS,
                valeur: '1'
            },
            {
                colonne: 'trnflgstafer',
                operateur: Operateur.EGALE,
                valeur: ''
            },
            {
                colonne: 'tseflgprb',
                operateur: Operateur.EGALE,
                valeur: ''
            }
        ];
    } else {
        filtres = [{
            colonne: 'tseflgprb',
            operateur: Operateur.EGALE,
            valeur: "1",
            visible: false
        }]
    }

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: !$stateParams.employe
        },
        navigatePage: rr0002Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0002Route.NAME)
        ]),
        filtres,
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        criteresSuggeres: new Formulaire([
            new FormulaireItem('eplnummat'),
            new FormulaireItem('eplnomprn'),
            new FormulaireItem('eplnas'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('unpcleint'),
            new FormulaireItem('ttccleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('urgcleint'),
            new FormulaireItem('strcleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('datau'),
            new FormulaireItem('vaetseori')
        ]),
        colonnesVisibles: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 262, hidden: $stateParams.employe },
            { nom: 'tsenumdoc', largeur: 136 },
            { nom: 'ttccod', largeur: 105 },
            { nom: 'ecldsceta', largeur: 180 },
            { nom: 'vaetseori_dsc', largeur: 158 },
            { nom: 'tsedatdeb', largeur: 100 },
            { nom: 'urgcod', largeur: 120 },
            { nom: 'urgdsc', largeur: 262 },
            { nom: 'strcod', largeur: 91 },
            { nom: 'strdsc', largeur: 262 },
            { nom: 'lemdatdeb', largeur: 110 },
            { nom: 'lemdatfin', largeur: 100 },
            { nom: 'aftdsc', largeur: 420 },
            { nom: 'aftdatdeb', largeur: 105 },
            { nom: 'aftdatfin', largeur: 105 },
            { nom: 'tsedsc', largeur: 420 },
            { nom: 'tseflgprb', largeur: 100 },
            { nom: 'tprcod', largeur: 100 },
            { nom: 'tprdscabr', largeur: 262 },
            { nom: 'tsedatdebprb', largeur: 105 },
            { nom: 'tsedatfinprb', largeur: 100 },
            { nom: 'tsecomprb', largeur: 341 },
            { nom: 'tsesldbaccre', largeur: 126 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 76 },
            { nom: 'trnflgstafer', largeur: 72 },
            { nom: 'trnflgstaann', largeur: 75 },
            { nom: 'tseflggen', largeur: 91 },
            { nom: 'unpcod', largeur: 91 },
            { nom: 'unpdsc', largeur: 91 }
        ]
    };
}
