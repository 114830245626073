import { IAugmentedJQuery, IComponentController, IPromise, IQService, IScope, IWindowService } from 'angular';
import { IDefaultsService } from '../../../../core/services/utils/defaults.service';
import { IMenuItem, IMenuItemClass } from '../../../../core/services/menu/menu-item.service';
import { IMenu, IMenuClass, MenuElement } from '../../../../core/services/menu/menu.service';
import { IMenuSection, IMenuSectionClass } from '../../../../core/services/menu/menu-section.service';
import {
    IActionsSelectionAction,
    IActionsSelectionResource,
    IActionsSelectionResponse
} from '../../../../core/resources/actions-selection.resource';
import { IMultiOccurrence } from '../../../../core/services/multi-occurrence.service';
import { IApiError } from '../../../../core/interfaces/api-error.interface';
import { IFilterLibelle } from '../../../../core/filters/ex-libelle.filter';
import { IMenuErreurClass } from '../../../../core/services/menu/menu-erreur.service';
import { IMenuMessageClass } from '../../../../core/services/menu/menu-message.service';
import { IFilterMessage } from '../../../../core/filters/ex-message.filter';
import { IDialogConfirmOptions } from '../../../../core/dialogs/dialog-confirm/dialog-confirm.controller';
import { IDialogFormulaireOptions } from '../../../../core/dialogs/dialog-formulaire/dialog-formulaire.controller';
import { IDialog } from '../../../../core/services/dialog.service';
import { INotificationHandler } from '../../../../core/services/utils/notification-handler.service';
import { IMenuItemSelection, IMenuItemSelectionClass } from '../../../../core/services/menu/menu-item-selection.service';
import { IComposantCard } from '../../../../core/components/ex-card/ex-card.controller';
import { IEcranContextController } from '../../../../core/behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IParametresSecuriteService } from '../../../../core/services/parametres-securite.service';
import { IDialogAllStatus } from '../../../../core/constants/dialog.constant';
import { IEcranStateParamsService } from '../../../../core/services/utils/ecran-state-params.service';
import { IErrorTypes } from '../../../../core/constants/error-types.constant';

interface IComposantButtonFabSelection extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    action: IMenu | IMenuItem;
    liste: Array<MenuElement>;
    multiOccurrence: IMultiOccurrence;
    cardController: IComposantCard;
    openActionOriginal: Function;
    container: string;
    draggingBoutonActions: boolean;
    draggedBoutonActions: boolean;
    selectionItems: Array<IMenu | IMenuSection | IMenuItemSelection>;
    isDisabled(action: IMenuItem): boolean;
    startDragAction(): void;
    endDragAction(): void;
    enregistrements: any;
}

/* @ngInject */
export default function ButtonFabSelectionController(defaults: IDefaultsService,
    $element: IAugmentedJQuery,
    $mdMenu: ng.material.IMenuService,
    $scope: IScope,
    $window: IWindowService,
    $mdUtil: any,
    MenuSection: IMenuSectionClass,
    MenuItem: IMenuItemClass,
    MenuItemSelection: IMenuItemSelectionClass,
    MenuErreur: IMenuErreurClass,
    MenuMessage: IMenuMessageClass,
    Menu: IMenuClass,
    ActionsSelectionResource: IActionsSelectionResource,
    $q: IQService,
    notificationHandler: INotificationHandler,
    exLibelleFilter: IFilterLibelle,
    exMessageFilter: IFilterMessage,
    DialogFormulaire: IDialog,
    parametresSecurite: IParametresSecuriteService,
    DialogStatus: IDialogAllStatus,
    ErrorTypes: IErrorTypes,
    ecranStateParams: IEcranStateParamsService) {
    const vm: IComposantButtonFabSelection = this;
    const debouncedRestaurerPositionBoutonActions = $mdUtil.debounce(restaurerPositionBoutonActions, 500);

    vm.$onInit = $onInit;
    vm.isDisabled = isDisabled;
    vm.startDragAction = startDragAction;
    vm.endDragAction = endDragAction;

    function $onInit(): void {
        defaults(vm, {
            draggingBoutonActions: false,
            draggedBoutonActions: false,
            // La liste des sélections dynamiques a obtenir du backend
            selectionItems: [],
            container: 'ex-multi-occurrence'
        });

        initBoutonActions();
    }

    function initBoutonActions(): void {
        if (vm.action instanceof Menu) {
            // On garde la liste originale en mémoire
            vm.liste = vm.action.listeMenuItem.concat();

            vm.openActionOriginal = vm.action.onOpen;

            // Si le menu a une sélection, il est dynamique dans son entièreté. Dans le cas contraire, on vérifie
            // s'il y a des sections dynamiques
            if (vm.action.selection) {
                vm.selectionItems.push(vm.action);
            } else {
                vm.action.listeMenuItem.forEach((menuElement: MenuElement) => {
                    if ((menuElement instanceof MenuSection || menuElement instanceof MenuItemSelection) && menuElement.selection) {
                        vm.selectionItems.push(menuElement);
                    }
                });
            }

            if (vm.selectionItems.length) {
                vm.action.onOpen = onOpenAction;
            }
        }

        $scope.$watch('vm.multiOccurrence.getSelectedRows().length', (newValue: number, oldValue: number) => {
            if (vm.multiOccurrence.dataList && !vm.draggedBoutonActions) {
                vm.enregistrements=vm.multiOccurrence.getSelectedRows().length;
                positionBoutonActions(newValue, oldValue);
            }
        });

        // Lorsque la fenêtre est redimentionnée, il faut repositionner le bouton
        $window.addEventListener('resize', debouncedRestaurerPositionBoutonActions);

        $scope.$on('$destroy', () => {
            $window.removeEventListener('resize', debouncedRestaurerPositionBoutonActions);
        });
    }

    function onOpenAction() {
        const listeVisible = getListeVisible();
        const actions = vm.selectionItems
            .filter((item: IMenuItemSelection) => {
                return listeVisible.find((itemVisible: IMenuItemSelection) => itemVisible.fonction === item.fonction);
            })
            .map((selectionItem: IMenuItemSelection) => validerSelection(selectionItem));
        (<IMenu>vm.action).listeMenuItem.splice(0, (<IMenu>vm.action).listeMenuItem.length, ...listeVisible);

        if (vm.openActionOriginal) {
            actions.push(vm.openActionOriginal());
        }

        return $q.all(actions).catch((error: IApiError) => {
            const exception: IDialogConfirmOptions = {
                error,
                lblTitre: 'G_LBL_ERREUR',
                lblMessage: 'G_MSG_ERREUR_VALIDATION_SELECTION'
            };

            if (error && error.data && error.data.message.startsWith('APP-01156')) {
                // Dans le cas d'une mauvaise sélection, rien ne sert de réessayer
                exception.confirmAction = null;
            }

            return $q.reject(exception);
        });
    }

    /**
     * Valide la sélection dynamique
     */
    function validerSelection(selectionItem: IMenu | IMenuSection | IMenuItemSelection) {
        const selectionDetails = vm.multiOccurrence.selections.find((selection) => selection.nom === selectionItem.selection.nom);

        if (!selectionDetails) {
            throw new Error(`Sélection ${selectionItem.selection.nom} introuvable`);
        }

        const ids = vm.multiOccurrence.getSelectedRows().map((rowData: any) => rowData[vm.multiOccurrence.cleint]);
        const idsParent = vm.multiOccurrence.getParentIds();
        //On enlève volontairement le dernier id (qui représenterait la ligne sélectionnée)
        idsParent.pop();

        const params = {
            srccod: vm.multiOccurrence.getNomSourceDetails(),
            selection: selectionDetails.nom,
            ids: idsParent.join(','),
            selections: ids.join(','),
            ...vm.multiOccurrence.etatSelected.criteresSuggeresData,
            ...vm.multiOccurrence.getResourceParamsValues(),
            ...ecranStateParams(vm.ecranContextCtrl.stateParams),
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        if (selectionDetails.isDynamique) {
            //Si la selection est dynamique, on fait une requête pour obtenir les actions
            return ActionsSelectionResource.getActions(params).$promise
                .then((result: IActionsSelectionResponse) => {
                    const menuItems: Array<MenuElement> = result.actions.map((action) => {
                        return new MenuItem(action.ctpdscabr, () => {
                            executerAction(params, action, selectionItem);
                        });
                    });

                    if (!menuItems.length) {
                        menuItems.push(new MenuMessage('G_LBL_AUCUNE_VALEUR'));
                    }

                    addMenuItemToMenu(selectionItem, ...menuItems);
                })
                .catch((error: IApiError) => {
                    if (selectionItem instanceof Menu) {
                        // Les erreurs au niveau du menu seront prises en charge dans "onOpenAction"
                        return $q.reject(error);
                    } else {
                        const errorMessage = (error && error.data && error.data.message);
                        const lblMessage = exMessageFilter(errorMessage, 'G_MSG_ERREUR_VALIDATION_SELECTION');

                        addMenuItemToMenu(selectionItem, new MenuErreur(lblMessage));
                    }
                });
        } else if (selectionItem instanceof MenuItemSelection) {
            params.fonction = (<IMenuItemSelection>selectionItem).fonction;
            //On s'assure d'avoir accès à l'item.
            return ActionsSelectionResource.getActions(params).$promise
                .then((result) => {
                    selectionItem.selection.confirmationData = { ...(selectionItem.selection.confirmationData || {}), ...result };
                    const menuItem = new MenuItem((<IMenuItemSelection>selectionItem).lblTitre, () => {
                        executerActionStatic(params, selectionItem);
                    }, { fonction: (<IMenuItemSelection>selectionItem).fonction });

                    addMenuItemToMenu(selectionItem, menuItem);
                })
                .catch((error) => {
                    const errorMessage = (error && error.data && error.data.message);
                    const lblMessage = exMessageFilter(errorMessage, 'G_MSG_ERREUR_VALIDATION_SELECTION');

                    addMenuItemToMenu(selectionItem, new MenuErreur(lblMessage));
                });

        }
    }

    function addMenuItemToMenu(selectionItem: IMenu | IMenuSection | IMenuItemSelection, ...items: Array<MenuElement>) {
        const isMenuItem = selectionItem instanceof MenuItemSelection;
        const replace = (isMenuItem) ? 1 : 0;
        const position = (!isMenuItem) ? getMenuItemPosition(selectionItem) : getMenuItemPosition(selectionItem) - 1;
        // On doit clôner pour ne pas que les éléments s'ajoutent à chaque ouverture
        const newListe = (<IMenu>vm.action).listeMenuItem.concat();
        newListe.splice(position, replace, ...items);

        const liste = (<IMenu>vm.action).listeMenuItem;
        (<IMenu>vm.action).listeMenuItem.splice(0, liste.length, ...newListe);
    }

    function getMenuItemPosition(selectionItem: IMenu | IMenuSection) {
        const liste = (<IMenu>vm.action).listeMenuItem;
        return (vm.action === selectionItem) ?
            0 :
            liste.findIndex((section: IMenuSection) => section === selectionItem) + 1;
    }

    function executerAction(paramsDefaut: any, action: IActionsSelectionAction, selectionItem: IMenu | IMenuSection) {
        return confirmerAction(selectionItem)
            .then((data) => {
                const params = {
                    ...paramsDefaut,
                    ctpcleint: action.ctpcleint,
                    ...parametresSecurite(vm.ecranContextCtrl.stateParams)
                };

                return ActionsSelectionResource.executeAction(params, data).$promise
            })
            .then(() => {
                notificationHandler.succes('G_MSG_ACTION_SUCCES');

                vm.multiOccurrence.fetchDataList();
            })
            .catch((error: IApiError) => {
                return $q.reject({
                    error,
                    lblTitre: 'G_LBL_ERREUR',
                    lblMessage: 'G_MSG_ERREUR_ACTION'
                });
            });
    }

    function executerActionStatic(paramsDefaut: any, selectionItem: IMenuItemSelection) {
        return confirmerAction(selectionItem)
            .then((data) => {
                const params = {
                    ...paramsDefaut,
                    fonction: selectionItem.fonction,
                    ...parametresSecurite(vm.ecranContextCtrl.stateParams)
                };

                return ActionsSelectionResource.executeStaticAction(params, data).$promise
            })
            .then(async (result: any) => {
                let successActionError;
                if (selectionItem.successAction) {
                    successActionError = await selectionItem.successAction(result);
                }
                const msg = result.msg || exLibelleFilter('G_MSG_ACTION_SUCCES');

                if (!successActionError) {
                    if (!result.typmsg) {
                        notificationHandler.succes(msg);
                    } else if (result.typmsg === ErrorTypes.INFORMATION) {
                        notificationHandler.info({
                            lblMessage: msg
                        });
                    } else if (result.typmsg === ErrorTypes.AVERTISSEMENT) {
                        notificationHandler.avertissement({
                            lblMessage: msg
                        });
                    } else if (result.typmsg === ErrorTypes.ERREUR) {
                        notificationHandler.erreur({
                            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                            lblMessage: msg
                        });
                    }
                }

                vm.multiOccurrence.fetchDataList({flagFab:true});
            })
            .catch((error: any) => {
                if (error !== DialogStatus.FERMER) {
                    notificationHandler.erreur({
                        error,
                        lblTitre: vm.titre,
                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                        lblMessage: 'G_MSG_SAUV_ENR'
                    });
                }

                return $q.reject({
                    error,
                    lblTitre: 'G_LBL_ERREUR',
                    lblMessage: 'G_MSG_ERREUR_ACTION'
                });
            });
    }

    function confirmerAction(selectionItem: IMenu | IMenuSection) {
        let queueConfirmation: IPromise<any> = $q.resolve({});

        if (selectionItem.selection.confirmationData && selectionItem.selection.confirmationData.msg) {
            switch (selectionItem.selection.confirmationData.typmsg) {
                case 'A':
                    queueConfirmation = queueConfirmation
                        .then(() => notificationHandler.avertissement({ lblMessage: selectionItem.selection.confirmationData.msg }))
                        //On continue à la fermeture du dialog.
                        .catch(() => $q.resolve({}));
                    break;
                case 'E':
                    queueConfirmation = queueConfirmation.then(() => notificationHandler.erreur({
                        lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                        lblMessage: selectionItem.selection.confirmationData.msg
                    }));
                    break;
                default:
                    queueConfirmation = queueConfirmation
                        .then(() => notificationHandler.info({ lblMessage: selectionItem.selection.confirmationData.msg }))
                        //On continue à la fermeture du dialog.
                        .catch(() => $q.resolve({}));
                    break;

            }
        }

        if (selectionItem.selection.confirmationFormulaire) {
            const dialogOptions: IDialogFormulaireOptions = {
                lblTitre: exLibelleFilter(selectionItem.selection.lblConfirmationTitre, vm.multiOccurrence.libelles),
                lblConfirm: selectionItem.selection.lblConfirm || 'G_LBL_BTN_CONFIRMER',
                formulaire: selectionItem.selection.confirmationFormulaire,
                data: selectionItem.selection.confirmationData,
                occurrence: selectionItem.selection.confirmationOccurrence || vm.multiOccurrence,
                ecranContext: vm.ecranContextCtrl,
                icon: selectionItem.selection.icon,
                largeur: selectionItem.selection.largeurFormulaire
            };

            queueConfirmation = queueConfirmation.then(() => DialogFormulaire.show({ locals: dialogOptions, multiple: true }));
        }

        return queueConfirmation;
    }

    function isDisabled(): boolean {
        let isDisabled = false;
        // Désactive le menu lorsqu'aucune rangée n'est sélectionnée
        if (!vm.multiOccurrence.getSelectedRows().length) {
            isDisabled = true;
        } else if (typeof vm.action.disabled === 'function') {
            isDisabled = (<Function>vm.action.disabled)({});
        } else {
            isDisabled = vm.action.disabled;
        }

        if (isDisabled && vm.draggedBoutonActions) {
            restaurerPositionBoutonActions();
        }

        return isDisabled;
    }

    function startDragAction(): void {
        vm.draggingBoutonActions = true;
        vm.draggedBoutonActions = true;
        $mdMenu.hide();
    }

    function endDragAction(): void {
        vm.draggingBoutonActions = false;
    }

    function positionBoutonActions(newValue: number, oldValue: number): void {
        const MULTI_OCCURRENCES_SUPPORTES = [
            'ex-multi-occurrence-analyse',
            'ex-multi-occurrence-advanced',
            'ex-multi-occurrence-maitre-details'
        ];
        const MOITIE_HAUTEUR_BOUTON = 26;
        const MARGIN_DEFAUT_CARTE = 8;
        const multiOccurrenceSelecteur = MULTI_OCCURRENCES_SUPPORTES.join(',');
        const elementParent = $element.closest(multiOccurrenceSelecteur);
        let elementAlignement;

        if (oldValue === 0 && newValue === 1) {
            const elementSelectionnes = vm.multiOccurrence.getSelectedRows();
            const indexElement = vm.multiOccurrence.dataList.findIndex((element) => element === elementSelectionnes[0]);
            elementAlignement = elementParent.find(`.ex-grid-row:nth-child(${indexElement + 1}) md-checkbox .md-container`);
        } else if (oldValue === 0 && vm.multiOccurrence.toutSelectionne) {
            elementAlignement = elementParent.find('.ex-grid-head md-checkbox .md-container');
        }

        if (elementAlignement !== undefined) {
            const elementAlignementPosition = elementAlignement.offset();
            const elementParentPosition = elementParent.offset();
            const elementAlignementHeight = elementAlignement.height();
            const positionContainer = $element.offset().top;
            const top = (vm.cardController) ?
                elementAlignementPosition.top - elementParentPosition.top - (elementAlignementHeight / 2) - MARGIN_DEFAUT_CARTE :
                elementAlignementPosition.top - positionContainer + (elementAlignementHeight / 2) - MOITIE_HAUTEUR_BOUTON;

            $element.find('.ex-button-fab-selection').css({
                top,
                left: ''
            });
        }
    }

    function restaurerPositionBoutonActions(): void {
        $mdMenu.hide();
        vm.draggedBoutonActions = false;
        // Quand on resize, on doit repositionner le bouton car il pourrait sinon être décalé là où on ne le veut pas
        positionBoutonActions(vm.multiOccurrence.getSelectedRows().length, 0);
    }

    function getListeVisible(): Array<MenuElement> {
        return vm.liste.filter((menuItem: MenuElement) => {
            if (!(menuItem instanceof MenuItem)) {
                return true;
            }

            if ((<IMenuItem>menuItem).hidden instanceof Function) {
                return !((<Function>(<IMenuItem>menuItem).hidden)());
            } else {
                return !((<IMenuItem>menuItem).hidden);
            }
        });
    }
}
