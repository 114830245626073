import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IRoute} from "../../../../../core/interfaces/route.interface";
import {IMenuItemForageClass} from "../../../../../core/services/menu/menu-item-forage.service";

interface IPageRR0517 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Rr0517Controller(Menu: IMenuClass,
                                         rr0548Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRR0517 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        navigatePage: rr0548Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(rr0548Route.NAME)
        ]),
        colonnesVisibles: [
            {nom: 'usrcod'      , largeur: 130},
            {nom: 'usrnom'      , largeur: 130},
            {nom: 'exacodref'   , largeur: 250},
            {nom: 'exadatenv'   , largeur: 130},
            {nom: 'exaflgchq'   , largeur: 130},
            {nom: 'exaflgfinann', largeur: 130}
        ]
    };
}
