import {IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IComponentController} from "angular";
import {IFormulaireItemSautDeColonneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-colonne.service";


interface IPageCo0025 extends IComponentController {
    formulaireTyc: IFormulaire,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    nivMultiOccurrenceOptions: IMultiOccurrenceOptions;

}
/* @ngInject */
export default function Co0025Controller(Formulaire: IFormulaireClass,
                                          FormulaireItem: IFormulaireItemClass,
                                          FormulaireItemSautDeColonne: IFormulaireItemSautDeColonneClass,) {
    const vm: IPageCo0025 = this;

    vm.$onInit = $onInit;

    function $onInit() {

        vm.formulaireTyc = new Formulaire('LBL_TYP_COM', [
                new FormulaireItem('tyccod', {required: true, largeur: 25}),
                new FormulaireItem('tycdsc', {required: true, largeur: 50}),
                new FormulaireItem('vactycunm', {largeur: 25}),
                new FormulaireItem('tycflgdatobt', {largeur: 25}),
                new FormulaireItem('tycflgdatderutl', {largeur: 25}),
                new FormulaireItem('tycflgdatexp', {largeur: 25}),
                new FormulaireItem('tycflginfscl', {largeur: 25}),
                new FormulaireItem('tycflginfexp', {largeur: 25}),
                new FormulaireItem('tycflgcomobl', {largeur: 25})
            ]
        );

        vm.nivMultiOccurrenceOptions = {
            colonnesFixesGauche: [
                {nom: 'nivcod', largeur: 70},
                {nom: 'nivdsc', largeur: 200},
                {nom: 'nivpds', largeur: 90},
                {nom: 'nivseqaff', largeur: 90}
            ],
            colonnesVisibles: [               
                {nom: 'nivflgmdi', largeur: 80},
                {nom: 'mdicod', largeur: 100},
                {nom: 'mdidsc', largeur: 215}
            ],

            formulaire: new Formulaire([
                new FormulaireItem('nivcod', {largeur: 25}),
                new FormulaireItem('nivdsc', {largeur: 50}),
                new FormulaireItem('nivpds', {largeur: 25}),
                new FormulaireItem('nivseqaff',{largeur: 25}),
                new FormulaireItemSautDeColonne({largeur: 25}),
                new FormulaireItem('nivflgmdi',{largeur: 25}),
                new FormulaireItem('mdicleint',{largeur: 50}),
                new FormulaireItemSautDeColonne({largeur: 25})
            ])
        };
    }
}
