import ecran from '../../../../../core/injectors/ecran.injector';

export default ecran({
    source: 'pf0032',
    ecrans: ['00-2'],
    params: [
        'id',
        'axe',
        'dpmcleint',
        '?gpecleint',
        '?demcleint'
    ]
});
