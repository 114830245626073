import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import IResourceService = angular.resource.IResourceService;
import { IMenuItemClass } from '../../../../../core/services/menu/menu-item.service';
import { IApiConfig } from '../../../../../core/interfaces/api-config.interface';
import { INotificationHandler } from '../../../../../core/services/utils/notification-handler.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IApiError } from '../../../../../core/interfaces/api-error.interface';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageGs0129 extends IComponentController {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    qcsMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Gs0129Controller(Formulaire: IFormulaireClass,
    $resource: IResourceService,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    ApiConfig: IApiConfig,
    notificationHandler: INotificationHandler,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass) {

    const vm: IPageGs0129 = this;
    const menuItemMajApps = new MenuItem(
        'LBL_MAJ_APPS',
        executerMAJ,
        {
            icon: 'sync'
        }
    );
    vm.qcsMultiOccurrenceOptions = {
        bloc: 'qcs',
        actionsMoreLigne: new Menu([menuItemMajApps]),
        formulaire: new Formulaire([
            new FormulaireItem('qcsnom', { readonly: (data) => data.qdscleint }),
            new FormulaireItem('qcsid'),
            new FormulaireItem('qcsdscfra')
        ], { largeurDefaut: 33.33 }),
        pagination: new Pagination({
            nombreElementPage: 15,
            nbElementsPossibles: [15, 30, 50]
        }),
        colonnesVisibles: [
            { nom: 'qcsnom', largeur: 200 },
            { nom: 'qcsdscfra', largeur: 200 },
            { nom: 'qcsid', largeur: 200 }
        ],
        colonnesCachees: ['qdscleint', 'qpccleint']
    };

    vm.multiOccurrenceOptions = {
        actionsMore: new Menu([menuItemMajApps]),
        actionsMoreLigne: new Menu([menuItemMajApps]),
        formulaire: new Formulaire([
            new FormulaireItem('qpcnom', { readonly: (data) => data.qadcleint }),
            new FormulaireItem('qpcid'),
            new FormulaireItem('qpcdscfra')
        ], { largeurDefaut: 33.33 }),
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 25, 50]
        }),
        colonnesVisibles: [
            { nom: 'qpcnom', largeur: 200 },
            { nom: 'qpcdscfra', largeur: 200 },
            { nom: 'qpcid', largeur: 200 }
        ],
        colonnesCachees: ['qadcleint'],
        multiOccurrenceOptionsEnfant: vm.qcsMultiOccurrenceOptions
    };

    function executerMAJ(ev: MouseEvent, data: any, occurrence: IMultiOccurrence) {
        const params = getParams(data);
        const resource = $resource(`${ApiConfig.ROOT}/ecran-qlik/maj-config/apps/:appname/:sheetname`, params);
        return resource.save().$promise
            .then(() => {
                notificationHandler.succes();
                occurrence.fetchDataList();
            })
            .catch((error: IApiError) => {
                notificationHandler.erreur({ error, confirmAction: () => executerMAJ(ev, data, occurrence) });
            });
    };

    function getParams(data: any) {
        const params: any = {};
        if (data.qcscleint) {
            params.sheetname = data.qcsnom;
        }

        if (data.qpccleint) {
            params.appname = data.qpcnom ? data.qpcnom : data.$ancetre.qpcnom;
        }

        return params;
    };
}
