import { IComponentController } from 'angular';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemMenuItemClass } from '../../../../../core/services/formulaire/formulaire-item-menu-item.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0155 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
}

interface IRr0155Params {
    id: string;
}

/* @ngInject */
export default function Rr0155Controller(rr0037Route: IRoute,
    rr0004Route: IRoute,
    rr0006Route: IRoute,
    sp0003Route: IRoute,
    $stateParams: IRr0155Params,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    Formulaire: IFormulaireClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    MenuItemForage: IMenuItemForageClass) {
    const vm: IPageRr0155 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.monoOccurrenceOptions = {
            formulaire: new Formulaire('LBL_PROBATION', [
                new FormulaireItem('tprcleint', {
                    resourceParams: () => ({
                        tsecleint: $stateParams.id
                    }),
                    menu: [
                        new MenuItemForage(
                            'BTN_FORAGE_POS',
                            rr0037Route.NAME,
                            (data: any) => ({ tprcleint: data.tprcleint }),
                            { hidden: (data: any) => !data.tprcleint }
                        )
                    ],
                }),
                new FormulaireItem('tsesldbaccre'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsedatdebprb'),
                new FormulaireItem('tsedatfinprb'),
                new FormulaireItemSautDeLigne(),
                new FormulaireItem('tsecomprb', {
                    largeur: 100
                }),
                new FormulaireItemMenuItem(new MenuItemForage(
                    'BTN_FORAGE_EVA',
                    sp0003Route.NAME,
                    (data: any) => ({
                        tsecleint: data.tsecleint
                    }),
                    {
                    iconButton: false,
                    hidden: () => ecranDetails.valeurs.flgprob === 1,
                    icon: '',
                    class: 'md-raised ex-button-action',
                    fonction: 'BOUEVA'
                }
                ))
            ])
        }

        vm.tseMenus = [
            new MenuItemForage(
                'LBL_TSE_LEM',
                rr0004Route.NAME,
                (data) => ({ id: data.trncleint }),
                {
                    icon: 'create',
                    iconButton: false,
                    hidden: (data: any) => !data.tseflglem
                }
            ),
            new MenuItemForage(
                'LBL_TSE_AFT',
                rr0006Route.NAME,
                (data) => data.tseflgaft ? { id: data.trncleint } : { trncleint: data.trncleint },
                {
                    icon: (data) => data.tseflgcreaft ? 'create' : 'add_circle',
                    iconButton: false,
                    hidden: (data: any) => !data.tseflgcreaft,
                    disabled: (data: any) => !data.lemcleint
                }
            )
        ];
    }
}
