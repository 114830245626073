import {IComponentController, IScope} from 'angular';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';
import {IMenuItemActionClass} from '../../../../../core/services/menu/menu-item-action.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageRr0540 extends IComponentController {
    valeursEcran: any;
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireTse: IFormulaire;
    ftrMultiOccurrence: IMultiOccurrence;
    aftMultiOccurrence: IMultiOccurrence;
    ftrMultiOccurrenceOptions: IMultiOccurrenceOptions;
    afmMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

interface IRr0540Params {
    demcleint: string;
    aftcleintanc: number;
    aftcleintnou: number;
    stamnetab: string;
}

/* @ngInject */
export default function Rr0540Controller($stateParams: IRr0540Params,
                                         $scope: IScope,
                                         rr0074Route: IRoute,
                                         rr0075Route: IRoute,
                                         MenuItemForage: IMenuItemForageClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Menu: IMenuClass,
                                         MenuItemAction: IMenuItemActionClass) {
    const vm: IPageRr0540 = this;

    // à enlever quand tous les appels seront présent
    const accueilRoute = {NAME: 'secure.accueil'};

    const forageAft = new Menu([]);
    const menuItemMaj = new MenuItemAction(
        '',
        'BOUMAJ',
        () => ({
            id: vm.monoOccurrence.id,
            cpttrn: vm.valeursEcran.cpttrn,
            cpttrntot: vm.valeursEcran.cpttrntot,
        }),
        { champsRechargement: [
                'cpttrn',
                'cpttrntot',
                'tseflgcre',
                'tseflgofi'
            ]
        }
    );

    // Forage vers les anciennes transaction
    const forageAncTrn = new MenuItemForage(
        'LBL_FOR_ANC_TRN',
        (data: any) => getForage(data),
        (data: any) => (
            {
                id: data.trncleint_anc
            }),
            {
                disabled: (data:any) => !data.trncleint_anc
            }
    );

    // Forage vers les nouvelles transactions
    const forageNouTrn = new MenuItemForage(
        'LBL_FOR_NOU_TRN',
        (data: any) => getForage(data),
        (data: any) => (
            {
                id: data.trncleint_nou
            }),
            {
                disabled: (data:any) => !data.trncleint_nou
            }
    );

    vm.$onInit = $onInit;

    function $onInit() {
        $scope.$watch('vm.ftrMultiOccurrence.etatSelected.criteresSuggeresData.vaeftrmne', (newValue: string, oldValue: string) => {
            if (newValue && oldValue && newValue !== oldValue) {
                menuItemMaj.action(null, {vaeftrmne: newValue}, vm.monoOccurrence)
            }
        });

        vm.valeursEcran = ecranDetails.valeurs;

        vm.monoOccurrenceOptions= {
            fonctions: {
                afficherMenusDansEntete: true
            },
            menus: [
                new MenuItemAction( 'LBL_BTN_TRANS',
                    'BOUTRANS',
                    () => ({}),
                    {
                        iconButton: false,
                        icon: '',
                        class: 'md-raised ex-button-secondary',
                        lblMessageSuccess: 'LBL_MSG_TRANS',
                        successAction: () => {
                            vm.aftMultiOccurrence.fetchDataList() && vm.ftrMultiOccurrence.fetchDataList();
                        },
                        disabled: (data: any) => !data.tseflgcre,
                        hidden: () => vm.monoOccurrence && (
                            vm.ftrMultiOccurrence &&
                            (
                                !vm.ftrMultiOccurrence.etatSelected ||
                                vm.ftrMultiOccurrence.etatSelected.criteresSuggeresData.vaeftrmne === 'DAL'
                            )
                        ),
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_CEDULE',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 40,
                            formulaire: new Formulaire([
                                new FormulaireItem('option', { default: 'MNT' }),
                                new FormulaireItem('datdif'),
                                new FormulaireItem('hredif')
                            ])
                        }
                    }
                ),
                new MenuItemAction( 'LBL_BTN_ALD',
                    'BOUTRANS',
                    () => ({}),
                    {
                        iconButton: false,
                        icon: '',
                        class: 'md-raised ex-button-secondary',
                        lblMessageSuccess: 'LBL_MSG_TRANS',
                        successAction: () => {
                            vm.aftMultiOccurrence.fetchDataList() && vm.ftrMultiOccurrence.fetchDataList();
                        },
                        disabled: (data: any) => !data.tseflgcre,
                        hidden: () => vm.monoOccurrence && (
                            vm.ftrMultiOccurrence &&
                            (
                                !vm.ftrMultiOccurrence.etatSelected ||
                                vm.ftrMultiOccurrence.etatSelected.criteresSuggeresData.vaeftrmne !== 'DAL'
                            )
                        ),
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_CEDULE',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 40,
                            formulaire: new Formulaire([
                                new FormulaireItem('option', { default: 'MNT' }),
                                new FormulaireItem('datdif'),
                                new FormulaireItem('hredif')
                            ])
                        }
                    }
                ),
                new MenuItemAction('LBL_BTN_TRN',
                    'BOUTRN',
                    () => ({}),
                    {
                        iconButton: false,
                        icon: '',
                        class: 'md-raised ex-button-action',
                        lblMessageSuccess: 'LBL_MSG_TRN',
                        successAction: () => {
                            vm.aftMultiOccurrence.fetchDataList() && vm.ftrMultiOccurrence.fetchDataList();
                        },
                        disabled: (data: any) => (!data.tseflgofi && vm.valeursEcran.mnemonique === 'DAL'),
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_CEDULE',
                            lblConfirm: 'G_LBL_BTN_APPLIQUER',
                            largeurFormulaire: 40,
                            formulaire: new Formulaire([
                                new FormulaireItem('option', { default: 'MNT' }),
                                new FormulaireItem('datdif'),
                                new FormulaireItem('hredif')
                            ])
                        }
                    }
                ),
                new MenuItemAction('LBL_BTN_FINAFT',
                    'BOUFINAFT',
                    () => ({}),
                    {
                        iconButton: false,
                        icon: '',
                        class: 'md-raised ex-button-secondary',
                        successAction: () => {
                            vm.aftMultiOccurrence.fetchDataList() && vm.ftrMultiOccurrence.fetchDataList();
                        },
                        disabled: () => vm.monoOccurrence.data.tseflgbtnfinaft === 0
                    }
                )
            ]
        };

        vm.formulaireTse = new Formulaire('LBL_TSE', [
            new FormulaireItem('demcleint'),
            new FormulaireItem('aftcleintanc', { default: $stateParams.aftcleintanc }),
            new FormulaireItem('aftcleintnou', { default: $stateParams.aftcleintnou }),
            new FormulaireItem('stamnetab', { default: $stateParams.stamnetab }),
            new FormulaireItem('tseflgcre', { default: vm.valeursEcran.tseflgcre }),
            new FormulaireItem('tseflgofi', { default: vm.valeursEcran.tseflgofi })
        ], { largeurDefaut: 33.33 });

        vm.afmMultiOccurrenceOptions = {
            bloc: 'afm',
            fonctions: {
                recherche: false,
                afficherMenuSettingDansEntete: false
            },
            colonnesVisibles: [
                {nom: 'afmmsg'   , largeur: 328}
            ]
        };

        vm.ftrMultiOccurrenceOptions = {
            bloc: 'ftr',
            fonctions: {
                criteresSuggeresVisibles: false
            },
            criteresSuggeres: new Formulaire([
                new FormulaireItem('vaeftrmne', { required: true, ignoreEtat: true, default: $stateParams.stamnetab }),
                new FormulaireItem('vaeftrmne__vaedsc', { hidden: true, ignoreEtat: true })
            ]),
            colonnesCachees: ['vaeftgtrn', 'ftgmsg', 'ftgmsg1', 'ftgmsg2','trncleint_anc','trncleint_nou','stamnetab'],
            colonnesFixesGauche: [
                {nom: 'numdocanc'   , largeur: 130, menu: [forageAncTrn]}
            ],
            colonnesVisibles: [
                {nom: 'trndscanc'   , largeur: 328, menu: [forageAft]},
                {nom: 'ftrflgfinanc', largeur: 64},
                {nom: 'numdocnou'   , largeur: 130, menu: [forageNouTrn]},
                {nom: 'ftrflgfinnou', largeur: 64},
                {nom: 'typetrn'     , largeur: 150},
                {nom: 'aftcodanc'   , largeur: 180},
                {nom: 'aftdscanc'   , largeur: 210},
                {nom: 'aftcodnou'   , largeur: 180},
                {nom: 'aftdscnou'   , largeur: 210}
            ],
            colonnesFixesDroite: [
                {nom: 'flgerr1'     , largeur: 100},
                {nom: 'flgerr2'     , largeur: 110},
                {nom: 'ftrflgexc'   , largeur: 64}
            ]
        }
    }

    function getForage(data: any): string {
        if (data.stamnetab === 'DER') {
            return rr0074Route.NAME;
        } else if (data.stamnetab === 'DRP') {
            return rr0075Route.NAME;
        } else {
            return accueilRoute.NAME;
        }
    }

}
