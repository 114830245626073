import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IComponentController } from 'angular';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemEcranClass } from '../../../../../core/services/menu/menu-item-ecran.service';
import { IMenuItemActionClass } from '../../../../../core/services/menu/menu-item-action.service';

interface IPageMc0028 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    cyeMultiOccurrenceOptions: IMultiOccurrenceOptions;
    cyemultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function Mc0028Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemAction: IMenuItemActionClass,
    mc2013Route: IRoute) {

    const vm: IPageMc0028 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            exportation: false  // L'exportation Excel est faite dans MC0174
        },
        formulaire: new Formulaire('LBL_BLC_CYC',[
            new FormulaireItem('redcleint', {modifAllow: false}),
            new FormulaireItem('etdcleintann', {modifAllow: false}),
            new FormulaireItem('etdcleintfer'),
            new FormulaireItem('cycflgfinann')
        ], {largeurDefaut: 25})
    };

    vm.cyeMultiOccurrenceOptions = {
        colonnesFixesGauche: [
            {nom: 'cyenumeta', largeur: 100},
            {nom: 'etddsc', largeur: 200}
        ],
        colonnesVisibles: [
            {nom: 'vaecyeact_dsc', largeur: 230, menu: [
                new MenuItemEcran(
                    'LBL_BTN_CYP',
                    mc2013Route.NAME,
                    (rowData:any) => ({
                        id: rowData.cyecleint
                    }),
                    {
                        icon: 'launch'
                    }
                )
            ]},
            {nom: 'cyeflgactaccecr', largeur: 80},
            {nom: 'vaecyeindmod_dsc', largeur: 140},
            {nom: 'tymdsc', largeur: 160},
            {nom: 'cyenbrcyp', largeur: 100},
            {nom: 'cyenbrcyd', largeur: 100},
            {nom: 'cyenbrcya', largeur: 100},
            {nom: 'cyenbrcyv', largeur: 100},
            {nom: 'cyenbrcys', largeur: 100},
            {nom: 'tmodsc', largeur: 180},
            {nom: 'etddsccen', largeur: 200},
            {nom: 'etddscdec', largeur: 200},
            {nom: 'cyeflgval', largeur: 120},
            {nom: 'cyeflgaprref', largeur: 120},
            {nom: 'cyeflgreqsupmnt', largeur: 120},
            {nom: 'cyeflgdouaut', largeur: 120},
            {nom: 'trlcod', largeur: 140},
            {nom: 'trldsc', largeur: 250},
            {nom: 'trlcodspe', largeur: 140},
            {nom: 'trldscspe', largeur: 250},
            {nom: 'cyeflgcouautaff', largeur: 100},
            {nom: 'cyejrsdelaff', largeur: 120},
            {nom: 'cyeflginiaff', largeur: 120},
            {nom: 'cyeflgintaff', largeur: 120},
            {nom: 'cyeflgapraff', largeur: 120},
            {nom: 'intcod', largeur: 130},
            {nom: 'intnom', largeur: 220},
            {nom: 'cmsmsg', largeur: 300, menu: [
                new MenuItemAction(
                    '',
                    'BOUMSG',
                    (data: any) => ({
                        cyecleint: data.cyecleint
                    }),
                    {
                        icon: 'launch',
                        formulaireConfirmationAction: {
                            lblTitre: 'LBL_MESSAGE',
                            lblConfirm: 'G_LBL_BTN_ENREGISTRER',
                            largeurFormulaire: 30,
                            formulaire: new Formulaire(
                                'LBL_MESSAGE', [
                                    new FormulaireItem('cmsmsgnew',{
                                        default: (data: any) => data.cmsmsg
                                    })
                                ], {largeurDefaut: 100}
                            )
                        },
                        successAction: () => {
                            vm.cyemultiOccurrence.fetchDataList();
                        }
                    }
                )
            ]}
        ],
        colonnesCachees: [
            'cyeflgcyd',
            'cyeflgcya',
            'cyeflgcyvcys'
        ],
        formulaire: new Formulaire([
            new FormulaireItem('cyenumeta', {maxlength: 2}),
            new FormulaireItem('etdcleint'),
            new FormulaireItem('vaecyeact'),
            new FormulaireItem('cyeflgactaccecr', {default: 1}),
            new FormulaireItem('vaecyeindmod'),
            new FormulaireItem('tymcleint'),
            new FormulaireItem('tmocleint'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('etdcleintcen', {
                resourceParams: (data:any) => {
                    return {
                        etdcleint: data.$ancetre.etdcleintann
                    };
                }
            }),
            new FormulaireItem('etdcleintdec', {
                resourceParams: (data:any) => {
                    return {
                        etdcleint: data.$ancetre.etdcleintann
                    };
                }
            }),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cyeflgval'),
            new FormulaireItem('cyeflgaprref'),
            new FormulaireItem('cyeflgreqsupmnt'),
            new FormulaireItem('cyeflgdouaut'),
            new FormulaireItem('trlcleint'),
            new FormulaireItem('trlcleintspe'),
            new FormulaireItemDivider('LBL_DIV_NOTCOU'),
            new FormulaireItem('cyeflgcouaut', {largeur: 20}),
            new FormulaireItem('cyejrsdel', {largeur: 20}),
            new FormulaireItem('cyeflgini', {largeur: 20}),
            new FormulaireItem('cyeflgint', {largeur: 20}),
            new FormulaireItem('cyeflgapr', {largeur: 20}),
            new FormulaireItem('intcleintrel'),
        ], {largeurDefaut: 25})
    };
}
