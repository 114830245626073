import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageMC0084 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrenceCDDOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function MC0084Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         Pagination: IPaginationClass) {
    const vm: IPageMC0084 = this;

    vm.multiOccurrenceCDDOptions = {
        bloc: 'cdd',
        colonnesVisibles: [
            {nom: 'cddseq', largeur: 130},
            {nom: 'cddtxt', largeur: 800},
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('cddseq', {largeur: 5}),
            new FormulaireItem('cddtxt', {largeur: 85})
        ])
    };

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'cndcod', largeur: 130},
            {nom: 'cnddsc', largeur: 350},
            {nom: 'vaecndtyp_dsc', largeur: 200},
            {nom: 'vecsyslng_dsc', largeur: 200}
        ],
        pagination: new Pagination({
            nombreElementPage: 5,
            nbElementsPossibles: [5, 10, 25]
        }),
        formulaire: new Formulaire([
            new FormulaireItem('cndcod', {largeur: 33}),
            new FormulaireItem('cnddsc', {largeur: 66}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaecndtyp'),
            new FormulaireItem('vecsyslng')
        ], {largeurDefaut: 33.33}),
        multiOccurrenceOptionsEnfant: vm.multiOccurrenceCDDOptions
    };
}
