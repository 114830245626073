import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IStateParamsService } from 'angular-ui-router';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';

interface IPageRr0513 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}
/* @ngInject */
export default function Rr0513Controller(Formulaire: IFormulaireClass,
    MenuItemForage: IMenuItemForageClass,
    rr0536Route: IRoute,
    rr0514Route: IRoute,
    rr0538Route: IRoute,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItem: IFormulaireItemClass,
    $stateParams: IStateParamsService) {
    const vm: IPageRr0513 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    const champsCriteresSuggeres = [
        new FormulaireItem("unpcleint", {
            required: true
        }),
    ];

    if (!$stateParams.employe) {
        champsCriteresSuggeres.push(
            new FormulaireItem("demcleint", { required: false }),
            new FormulaireItem("typ", { required: false, default: "1" }),
            new FormulaireItem("flgoff", { required: true, default: 1 })
        );
    } else {
        champsCriteresSuggeres.push(
            new FormulaireItem("typ", { required: false, default: "1" }),
            new FormulaireItem("flgoff", { required: true, default: 1 })
        );
    }

    const actionsMoreLigne = new Menu([
        new MenuItemForage(
            "BTN_FOR_PERCOUR",
            rr0536Route.NAME,
            (data: any) => ({
                unpcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.unpcleint,
                demcleint: data.demcleint,
                statut: '1'
            }),
            {
                icon: "launch_app",
                iconButton: false,
            }
        ),
        new MenuItemForage(
            "BTN_FOR_HIST",
            rr0538Route.NAME,
            (data: any) => ({
                unpcleint: vm.multiOccurrence.etatSelected.criteresSuggeresData.unpcleint,
                demcleint: data.demcleint
            }),
            {
                icon: "launch_app",
                iconButton: false,
            }
        )
    ]);

    vm.multiOccurrenceOptions = {
        actionsMoreLigne,
        navigatePage: rr0514Route.NAME,
        navigateParams: (rowData: any) => ({ demcleint: rowData.demcleint }),
        criteresSuggeres: new Formulaire(champsCriteresSuggeres),
        colonnesVisibles: [
            { nom: "eplnummat", largeur: 100, hidden: $stateParams.employe },
            { nom: "eplnomprn", largeur: 262, hidden: $stateParams.employe },
            { nom: "dpadatvig", largeur: 100 },
            { nom: "vaedscreg", largeur: 230 },
            { nom: "usrnom", largeur: 210 },
            { nom: "datcre", largeur: 100 }
        ],
        colonnesCachees: [
            'demcleint',
            'eplcleint',
            'unpcleint'
        ],
        fonctions: {
            edition: false,
            nouveau: false,
            supprime: false,
            suiviModification: false,
            effaceCriteres: true,
            criteresSuggeresVisibles: !$stateParams.employe,
            rechercheCollapsable: false
        }
    };
}
