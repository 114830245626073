import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';

interface IPageMr0237 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0237Controller(Formulaire: IFormulaireClass, FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0237 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('vaetcttra'),
            new FormulaireItem('ttccleint', {
                resourceParams: (data: any) => {
                    return {
                        vaetcttra: data.vaetcttra
                    };
                }
            })
        ]),
        colonnesVisibles: [
            { nom: 'vaedsc', largeur: 200 },
            { nom: 'ttccod', largeur: 139 },
            { nom: 'ttcdsc', largeur: 315 }
        ]
    };
}
