import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPageMr0145 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Mr0145Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageMr0145 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'pyscod', largeur: 65},
            {nom: 'pyscod3', largeur: 80},
            {nom: 'pysnomfra', largeur: 262},
            {nom: 'pysnomang', largeur: 262},
            {nom: 'pysflgdef', largeur: 76},
            {nom: 'pysflgprv', largeur: 95},
            {nom: 'pysflgcodpos', largeur: 105}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('pyscod', {required: true}),
            new FormulaireItem('pyscod3', {required: true}),
            new FormulaireItem('pysnomfra', {required: true}),
            new FormulaireItem('pysnomang'),
            new FormulaireItem('pysflgdef'),
            new FormulaireItem('pysflgprv'),
            new FormulaireItem('pysflgcodpos')
        ]),
        fonctions: Object.assign({
            edition: true,
            nouveau: true,
            importation: true,
            rechercheCollapsable: false
        })
    };
}
