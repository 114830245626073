import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';

interface IPageCC0012 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cc0012Controller(Menu: IMenuClass, cc0013Route: IRoute, MenuItemForage: IMenuItemForageClass) {
    const vm: IPageCC0012 = this;

    vm.multiOccurrenceOptions = {
        navigatePage: cc0013Route.NAME,
        actionsNouveaux: new Menu([
            new MenuItemForage(cc0013Route.NAME)
        ]),
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesFixesGauche: [
            { nom: 'pagcod', largeur: 89 }
        ],
        colonnesVisibles: [
            { nom: 'pagdsc', largeur: 250 },
            { nom: 'pagdsclng', largeur: 350 },
            { nom: 'pagflgpubporemp', largeur: 130 },
            { nom: 'pagdatpredeb', largeur: 100 },
            { nom: 'pagdatprefin', largeur: 100 },
            { nom: 'pagdatprefincen', largeur: 110 },
            { nom: 'pagflgpreexcatr', largeur: 110 },
            { nom: 'pagdataffdeb', largeur: 100 },
            { nom: 'pagdatafffin', largeur: 100 },
            { nom: 'pagdatatrdeb', largeur: 100 },
            { nom: 'pagdatatrpub', largeur: 105 },
            { nom: 'pagdatatrlimref', largeur: 100 },
            { nom: 'pagdatanc', largeur: 120 }
        ]
    };
}
