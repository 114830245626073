import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';

interface IPageSp0002 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Sp0002Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IPageSp0002 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'tmecod', largeur: 100 },
            { nom: 'tmedsc', largeur: 220 },
            { nom: 'ttccod', largeur: 100 },
            { nom: 'ttcdsc', largeur: 220 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('tmecod'),
            new FormulaireItem('tmedsc'),
            new FormulaireItem('ttccleint')
        ], {largeurDefaut: 33.33})
    };
}
