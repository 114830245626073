import {IComponentController} from 'angular';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IMonoOccurrence} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireGroupeClass } from '../../../../../core/services/formulaire/formulaire-groupe.service';

interface IPagerr0040 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    multiOccurrence: IMultiOccurrence;
    formulaire: IFormulaire;
    epeMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function rr0040Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireGroupe: IFormulaireGroupeClass) {

    const vm: IPagerr0040 = this;

    vm.$onInit = $onInit;

    function $onInit() {
        
        vm.formulaire = new Formulaire('', [
            new FormulaireItem('eplnumnomprn', {largeur: 100}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('epldatnai', {largeur: 10}),
            new FormulaireItem('eplnasimm', {largeur: 80}),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('eplflgimm', {largeur: 10}),
            new FormulaireItem('epldatobtcit'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('pyscod', {largeur: 10}),
            new FormulaireItem('pysnomfra', {largeur: 80}),
            new FormulaireItemSautDeLigne(),
            new FormulaireGroupe('LBL_NAIS', new Formulaire([
                new FormulaireItem('pyscodnai', {largeur: 10}),
                new FormulaireItem('pysnomfranai', {largeur: 30}),
                new FormulaireItem('etacod', {largeur: 10}),
                new FormulaireItem('etanomfra', {largeur: 30})
            ]))
            
        ]);

        vm.epeMultiOccurrenceOptions = {
            fonctions: {
                importation: true
            },
            colonnesVisibles: [
                {nom: 'vaetypepe_dsc', largeur: 110},
                {nom: 'epedatper', largeur: 110},
                {nom: 'epedatexp', largeur: 110},
                {nom: 'epedatexpnas', largeur: 110},
                {nom: 'epecom', largeur: 400}
            ]
        }
    }
}
