import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IStateParamsService} from 'angular-ui-router';
import {IFormulaireItemSautDeLigneClass} from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import {IRoute} from '../../../../../core/interfaces/route.interface';
import {IMenuItemForageClass} from '../../../../../core/services/menu/menu-item-forage.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPagePF0036 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    valeursEcran: any;
}

/* @ngInject */
export default function Pf0036Controller(Menu: IMenuClass,
                                         Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         $stateParams: IStateParamsService,
                                         MenuItemForage: IMenuItemForageClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         ecranDetails: IEcranDetailsResourcesEntite,
                                         co0010Route:IRoute,
                                         pf0014Route:IRoute,
                                         pf0034Route:IRoute) {
    const vm: IPagePF0036 = this;

    vm.valeursEcran = ecranDetails.valeurs;

    // Forage pour la compétence
    const forageCom = new MenuItemForage(
        co0010Route.NAME,
        (data: any) => ({
            id: data.idecleint,
            idecleintref: data.eplcleint,
            stamnetab: 'EPL',
            comcleint: data.comcleint

        }),
        { fonction:'COMP' }
    );

    // Forage pour le profil d eperfecitonnement
    const forageXpp = new MenuItemForage(
        pf0034Route.NAME,
        (data: any) => ({
            id: data.xppcleint
        }),
        { fonction:'CODE' }
    );

    //criteresSuggeres: new Formulaire([
    const champsCriteresSuggeres = [
        new FormulaireItem('t_xppcleint', {required: isRequired}),
        new FormulaireItem('t_comcleint',{
            required: isRequired,
            resourceParams: (data:any) => ({
                xppcleint: data.t_xppcleint
            })
        }),
        new FormulaireItemSautDeLigne(),

        new FormulaireItem('t_urgcleint', {required: isRequired}),
        new FormulaireItem('t_strcleint', {required: isRequired}),
        new FormulaireItem('t_temcleint',{
            required: isRequired,
            resourceParams: (data:any) => ({
                urgcleint: data.t_urgcleint
            })
        }),
        new FormulaireItemSautDeLigne(),

        new FormulaireItem('t_crisel', {
            default: 'TOUS',
            required: true,
            direction: 'vertical'
        }),
        new FormulaireItem('t_nbrjrs', {required: isRequired}),
        new FormulaireItemSautDeLigne()
    ];

    if (!$stateParams.employe) {
        champsCriteresSuggeres.push(new FormulaireItem('t_demcleint'));
    };

    const criteresSuggeresVisibles = $stateParams.employe ? false : undefined;

    vm.multiOccurrenceOptions = {

        actionsMoreLigne: new Menu([
            new MenuItemForage(
                'G_LBL_FORMATION_EMP',
                pf0014Route.NAME,
                (data: any) => (
                    {
                        axe: 'EMP',
                        demcleint: data.demcleint,
                        comcleint: data.comcleint
                    }
                ),
                {
                    icon: 'view_list',
                    iconButton: false,
                    fonction:'BOUEMP'
                }
            ),
            new MenuItemForage(
                'G_LBL_FORMATION_A_VENIR',
                pf0014Route.NAME,
                (data: any) => (
                    {
                        axe: 'ACT',
                        comcleint: data.comcleint
                    }
                ),
                {
                    icon: 'view_list',
                    iconButton: false,
                    fonction:'BOUFORAVEN'
                }
            )
        ]),

        fonctions: {
            criteresSuggeresVisibles,
            rechercheCollapsable: false
        },
        criteresSuggeres: new Formulaire(champsCriteresSuggeres),

        colonnesCachees:['idecleint',
            'comcleint',
            'eplcleint',
            'demcleint',
            'xppcleint'],

        colonnesFixesGauche: [
            {nom: 'eplnummat', largeur: 110, hidden: (data:any) => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_demcleint || $stateParams.employe},
            {nom: 'eplnomprn', largeur: 275, hidden: (data:any) => vm.multiOccurrence.etatSelected.criteresSuggeresData.t_demcleint || $stateParams.employe},
        ],
        colonnesVisibles: [
            {nom: 'comcod', largeur: 130, menu: [forageCom]},
            {nom: 'comdsc', largeur: 275},
            {nom: 'flg_detenue', largeur: 95},
            {nom: 'idedatexp', largeur: 100},
            {nom: 'flg_expiree', largeur: 80},
            {nom: 'idedatval', largeur: 100},
            {nom: 'xppcod', largeur: 130,menu: [forageXpp]},
            {nom: 'xppdsc', largeur: 263},
            {nom: 'litcod', largeur: 110},
            {nom: 'litdsc', largeur: 263},
            {nom: 'flg_respos', largeur: 80},
            {nom: 'temcod', largeur: 110},
            {nom: 'temdscabr', largeur: 275},
            {nom: 'urgcod', largeur: 110},
            {nom: 'urgdscabr', largeur: 275},
            {nom: 'strcod', largeur: 110},
            {nom: 'strdsc', largeur: 275},
            {nom: 'posnumdoc', largeur: 110},
            {nom: 'postitabr', largeur: 275},
            {nom: 'eplnumref1', largeur: 185, hidden: !ecranDetails.valeurs.flgaffref1},
            {nom: 'eplnumref2', largeur: 185, hidden: !ecranDetails.valeurs.flgaffref2},
            {nom: 'eplnumref3', largeur: 185, hidden: !ecranDetails.valeurs.flgaffref3},
            {nom: 'eplnumref4', largeur: 185, hidden: !ecranDetails.valeurs.flgaffref4}
        ],

        colonnesFixesDroite: [
            {nom: 'flg_formation', largeur: 100}
        ],
    };
    function isRequired(data: any) {
        return !data.t_xppcleint && !data.t_comcleint && !data.t_urgcleint && !data.t_strcleint && !data.t_temcleint && !data.t_nbrjrs && !data.t_demcleint;
    }
}
