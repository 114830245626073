import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';

interface IPagePT0024 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt0024Controller(Menu: IMenuClass) {
    const vm: IPagePT0024 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ????Route.NAME,
        actionsNouveaux: new Menu([]),
        colonnesVisibles: [
            {nom: 'rplcod', largeur: 200},
            {nom: 'rpldsc', largeur: 200}
        ]
    };
}
