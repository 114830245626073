import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IPaginationClass} from '../../../../../core/services/pagination.service';

interface IPageAc0102 {
    multiOccurrenceOptions: IMultiOccurrenceOptions
}

/* @ngInject */
export default function Ac0102Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         Pagination: IPaginationClass) {
    const vm: IPageAc0102 = this;

    // @TODO ajouter la route de forage une fois que l'écran est fait
    const ac0070Route = {NAME: 'secure.accueil'};
    const cp0029Route = {NAME: 'secure.accueil'};

    vm.multiOccurrenceOptions = {
        fonctions: {
            suiviModification: false
        },
        navigatePage: (rowData: any) => getForage(rowData),
        navigateParams: (rowData: any) => ({
            id: (rowData.docaffcleint01)
        }),
        colonnesVisibles: [
            {nom: 'docaffnumdoc01', largeur: 130},
            {nom: 'tydcod', largeur: 150},
            {nom: 'tyddsc', largeur: 200},
            {nom: 'peccod', largeur: 100},
            {nom: 'docaffdat01', largeur: 100},
            {nom: 'etcnumdoc', largeur: 120},
            {nom: 'docaffmnt01', largeur: 100},
        ],
        colonnesCachees: [
            'tydcleint',
            'docaffcleint01'
        ],
        pagination: new Pagination({
            nombreElementPage: 10,
            nbElementsPossibles: [5, 10, 25]
        })
    };

    // Fonction d'identification de l'écran pour le forage
    function getForage(data: any): string {
        if (data.vaedahtyp === 'COSE' ||
            data.vaedahtyp === 'COAE' ||
            data.vaedahtyp === 'ACOS' ||
            data.vaedahtyp === 'COCN' ||
            data.vaedahtyp === 'COCX') {
            return cp0029Route.NAME;
        } else if (data.vaedahtyp === 'ACCA' ||
                   data.vaedahtyp === 'ACDS' ||
                   data.vaedahtyp === 'ACDE' ||
                   data.vaedahtyp === 'ACCE') {
            return ac0070Route.NAME;
        }

        return null;
    }

}
