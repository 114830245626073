import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IMenuClass} from '../../../../../core/services/menu/menu.service';
import {IParametresRechercheService} from '../../../../../core/services/parametres-recherche.service';
import {IEcranDetailsResourcesEntite} from '../../../../../core/resources/ecran-details.resource';

interface IPagePt0010 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pt0010Controller(Menu: IMenuClass,
                                         parametresRecherche: IParametresRechercheService,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPagePt0010 = this;

    vm.multiOccurrenceOptions = {
        //navigatePage: ???Route.NAME,
        filtres: parametresRecherche(ecranDetails),
        colonnesVisibles: [
            {nom: 'eplnummat', largeur: 120},
            {nom: 'eplnomprn', largeur: 140},
            {nom: 'dmdnumdoc', largeur: 120},
            {nom: 'drscleint_dsc', largeur: 140},
            {nom: 'ecldsceta', largeur: 140},
            {nom: 'dmddatdeb', largeur: 126},
            {nom: 'dmddatfin', largeur: 126},
            {nom: 'ttccod', largeur: 120},
            {nom: 'ttcdsc', largeur: 140},
            {nom: 'aftcodref', largeur: 120},
            {nom: 'aftdscref', largeur: 120},
            {nom: 'dalnumdoc', largeur: 120},
            {nom: 'dalcom', largeur: 140},
            {nom: 'dmdflgauc', largeur: 100},
            {nom: 'dmddsc', largeur: 140},
            {nom: 'flgmsg', largeur: 100},
            {nom: 'trnflgstaref', largeur: 91},
            {nom: 'trnflgstafin', largeur: 76},
            {nom: 'trnflgstafer', largeur: 72},
            {nom: 'trnflgstaann', largeur: 75}
        ]
    };
}
