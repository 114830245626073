import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageCP0053 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0053Controller(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Operateur: IOperateurService,
    Menu: IMenuClass) {
    const vm: IPageCP0053 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        colonnesVisibles: [
            { nom: 'ctxcod', largeur: 10 },
            { nom: 'ctxdsc', largeur: 150 },
            { nom: 'vaectxnat', largeur: 20 },
            { nom: 'cptcod', largeur: 10 },
            { nom: 'cptdsc', largeur: 150 },
            { nom: 'taxcod', largeur: 20 },
            { nom: 'taxdscfed', largeur: 80 },
            { nom: 'taxdscpro', largeur: 80 }
        ],
        formulaire: new Formulaire([
            new FormulaireItem('ctxcod', { maxlength: 8, largeur: 20 }),
            new FormulaireItem('ctxdsc', { largeur: 50 }),
            new FormulaireItem('vaectxnat', { largeur: 30 }),
            new FormulaireItem('cptcleint', { largeur: 50 }),
            new FormulaireItem('taxcleint', { largeur: 50 })
        ])
    };
}
