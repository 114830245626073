import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireItemSautDeLigneClass} from "../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service";

interface IPageCP0016 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Cp0016Controller(Formulaire: IFormulaireClass,
                                         FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPageCP0016 = this;

    vm.multiOccurrenceOptions = {
        fonctions: {
            rechercheCollapsable: false
        },
        formulaire: new Formulaire([
            new FormulaireItem('cpfcod'),
            new FormulaireItem('cpfdsc'),
            new FormulaireItem('vaecpftypdat'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cpflibcol01'),
            new FormulaireItem('cpfmaxcol01'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cpflibcol02'),
            new FormulaireItem('cpfmaxcol02'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cpflibcol03'),
            new FormulaireItem('cpfmaxcol03'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cpflibcol04'),
            new FormulaireItem('cpfmaxcol04'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('cpflibcol05'),

        ], {largeurDefaut: 25}),
        colonnesVisibles: [
            {nom: 'cpfcod', largeur: 65},
            {nom: 'cpfdsc', largeur: 300},
            {nom: 'vaecpftypdat', largeur: 138},
            {nom: 'cpflibcol01', largeur: 138},
            {nom: 'cpfmaxcol01', largeur: 100},
            {nom: 'cpflibcol02', largeur: 138},
            {nom: 'cpfmaxcol02', largeur: 100},
            {nom: 'cpflibcol03', largeur: 138},
            {nom: 'cpfmaxcol03', largeur: 100},
            {nom: 'cpflibcol04', largeur: 138},
            {nom: 'cpfmaxcol04', largeur: 100},
            {nom: 'cpflibcol05', largeur: 138},
        ]
    };
}

