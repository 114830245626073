import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import { IOperateurService } from '../../../../../core/services/operateur.service';
import { IMenuClass } from '../../../../../core/services/menu/menu.service';
import { IParametresRechercheService } from '../../../../../core/services/parametres-recherche.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IStateParamsService } from 'angular-ui-router';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IMenuItemForageClass } from '../../../../../core/services/menu/menu-item-forage.service';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IPaginationClass } from '../../../../../core/services/pagination.service';

interface IPageAS0018 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
}
/* @ngInject */
export default function As0018Controller(Operateur: IOperateurService,
    Menu: IMenuClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    parametresRecherche: IParametresRechercheService,
    $stateParams: IStateParamsService,
    Formulaire: IFormulaireClass,
    as0017Route: IRoute,
    as0035Route: IRoute,
    as0041Route: IRoute,
    as0046Route: IRoute,
    MenuItemForage: IMenuItemForageClass,
    FormulaireItem: IFormulaireItemClass,
    Pagination: IPaginationClass
) {
    const vm: IPageAS0018 = this;
    const actionsMoreLigne = new Menu([
        new MenuItemForage(
            'BTN_FOR_JRS',
            as0041Route.NAME,
            (rowData: any) => (
                {
                    demcleint: rowData.demcleint,
                    datdeb: rowData.ptadatdeb
                }
            ),
            {
                icon: 'launch_app',
                iconButton: false
            }
        ),
        new MenuItemForage(
            'BTN_FOR_ASD',
            as0046Route.NAME,
            (rowData: any) => (
                {
                    demcleint: rowData.demcleint,
                    datdeb: vm.multiOccurrence.ecranDetails.valeurs.datdeb,
                    datfin: vm.multiOccurrence.ecranDetails.valeurs.datfin
                }
            ),
            {
                icon: 'launch_app',
                iconButton: false
            }
        )
    ])
    vm.multiOccurrenceOptions = {
        actionsMoreLigne,
        criteresSuggeres: new Formulaire([
            new FormulaireItem('trnflghist'),
            new FormulaireItem('trnflgfttrt'),
            new FormulaireItem('t_cri', { default: 'TOUS' }),
            new FormulaireItem('eplnummat', { largeur: 15, readonly: $stateParams.employe, hidden: $stateParams.employe, default: $stateParams.eplnummat }),
            new FormulaireItem('eplnomprn', { readonly: $stateParams.employe, hidden: $stateParams.employe }),
            new FormulaireItem('asgcleint', { lovSelectionMultiple: true, largeur: 33})
        ]),
        fonctions: {
            rechercheCollapsable: false,
            reinitialiserOuvrirCriteres: (!$stateParams.employe),
            effaceCriteres: ($stateParams.employe) || ($stateParams.eplnummat),
            criteresSuggeresVisibles: (!$stateParams.employe)
        },
        pagination: new Pagination({
            nombreElementPage: 100,
            nbElementsPossibles: [100, 250, 500]
        }),
        navigatePage: (Data) => getForageItem(Data),
        actionsNouveaux: new Menu([
            new MenuItemForage('LBL_NV_JR', as0017Route.NAME, () => ({
                demcleint: $stateParams.employe,
                vaeatftyp: 'JR'
            }), {
                fonction: 'BOUNOUJR'
            }),
            new MenuItemForage('LBL_NV_PLH', as0035Route.NAME,
                () => ({
                    demcleint: $stateParams.employe,
                    vaeatftyp: 'PHR'
                }), {
                fonction: 'BOUNOUPLH'
            }
            ),
            new MenuItemForage('LBL_NV_AJR', as0017Route.NAME, () => ({
                demcleint: $stateParams.employe,
                vaeatftyp: 'AJR'
            }), {
                fonction: 'BOUNOUAJR'
            }),
            new MenuItemForage('LBL_NV_APH', as0035Route.NAME,
                () => ({
                    demcleint: $stateParams.employe,
                    vaeatftyp: 'APHR'
                }), {
                fonction: 'BOUNOUAPH'
            }),
        ]),
        filtres: parametresRecherche(ecranDetails),
        colonnesFixesGauche: [
            { nom: 'eplnummat', largeur: 100, hidden: $stateParams.employe },
            { nom: 'eplnomprn', largeur: 210, hidden: $stateParams.employe }
        ],
        colonnesVisibles: [
            { nom: 'atfnumdoc', largeur: 110 },
            { nom: 'vaeatftyp_desc', largeur: 240 },
            { nom: 'ecldsceta', largeur: 240 },
            { nom: 'ptacod', largeur: 200 },
            { nom: 'ptadsc', largeur: 300 },
            { nom: 'ppacod', largeur: 200 },
            { nom: 'ppadsc', largeur: 262 },
            { nom: 'vaeatfori_desc', largeur: 240 },
            { nom: 'atfnumdocref', largeur: 200 },
            { nom: 'usrcod', largeur: 200 },
            { nom: 'asgcod', largeur: 200 },
            { nom: 'asgdsc', largeur: 300 },
            { nom: 'ttccod', largeur: 200 },
            { nom: 'ttcdsc', largeur: 300 },
            { nom: 'unocod', largeur: 200 },
            { nom: 'unodscabr', largeur: 300 },
            { nom: 'posnumdoc', largeur: 200 },
            { nom: 'postitabr', largeur: 262 },
            { nom: 'lemcod', largeur: 150 },
            { nom: 'aftcod', largeur: 105 },
            { nom: 'temcod', largeur: 150 },
            { nom: 'drnumdoc', largeur: 210, },
            { nom: 'derpnumdoc', largeur: 210, },
            { nom: 'atfcom', largeur: 490 },
            { nom: 'flgmsg', largeur: 91 },
            { nom: 'flgdb', largeur: 91 },
            { nom: 'trnflgstaref', largeur: 91 },
            { nom: 'trnflgstafin', largeur: 76 },
            { nom: 'trnflgstaann', largeur: 75 },
            { nom: 'trnflgstafer', largeur: 72 }
        ],
        colonnesCachees: ['vaeatftyp', 'ptadatdeb', 'ptadatfin']
    };
    function getForageItem(rowData: any): string {
        if (rowData.vaeatftyp === 'JR' || rowData.vaeatftyp === 'AJR') {
            return as0017Route.NAME
        }
        if (rowData.vaeatftyp === 'PHR' || rowData.vaeatftyp === 'APHR') {
            return as0035Route.NAME
        }
    }
}

