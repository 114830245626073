import { IComponentController } from 'angular';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireItemSautDeLigneClass } from '../../../../../core/services/formulaire/formulaire-item-saut-de-ligne.service';
import { IEcranDetailsResourcesEntite } from '../../../../../core/resources/ecran-details.resource';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';

interface IPageIn0001 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    dspMultiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaireComportement: IFormulaire;
}

/* @ngInject */
export default function In0001Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    ecranDetails: IEcranDetailsResourcesEntite,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
) {
    const vm: IPageIn0001 = this;

    vm.monoOccurrenceOptions = {
        id: ecranDetails.valeurs.ciecleintdnp,
        formulaire: new Formulaire([
            new FormulaireItem('dnplngseq'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dnpflggennum'),
            new FormulaireItem('dnpflgsymficmai')
        ])
    };

    vm.formulaireComportement = new Formulaire('LBL_BLC_COMPORTEMENT', [
        new FormulaireItem('vaesysinvdat'),
        new FormulaireItem('vaesysinvfor'),
        new FormulaireItem('vaesysinvqte'),
        new FormulaireItem('vaesysinvqteper')
    ]);

    vm.dspMultiOccurrenceOptions = {
        bloc: 'dsp',
        colonnesVisibles: [
            {nom: 'vaedspcle_dsc', largeur: 130},
            {nom: 'dsplib', largeur: 180},
            {nom: 'dspordaff', largeur: 100},
            {nom: 'dspflgdep', largeur: 120},
            {nom: 'dspflgseq', largeur: 120},
            {nom: 'vaedspval_dsc', largeur: 130},
            {nom: 'dsplibabr', largeur: 120},
            {nom: 'dsplng', largeur: 120},
            {nom: 'dspflglngfix', largeur: 120},
            {nom: 'dspflgcplzer', largeur: 120},
            {nom: 'dsptypdon_dsc', largeur: 150},
            {nom: 'dsptyplet_dsc', largeur: 150}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('vaedspcle'),
            new FormulaireItem('dsplib'),
            new FormulaireItem('dspordaff'),
            new FormulaireItem('dspflgdep', {readonly: (rowData:any) => (rowData.vaedspcle === 'ST1')}),
            new FormulaireItem('dspflgseq'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('vaedspval', {required: true, default: '0000'}),
            new FormulaireItem('dsplibabr'),
            new FormulaireItem('dsplng'),
            new FormulaireItem('dspflglngfix'),
            new FormulaireItem('dspflgcplzer'),
            new FormulaireItemSautDeLigne(),
            new FormulaireItem('dsptypdon', {default: '3'}),
            new FormulaireItem('dsptyplet', {default: '3'})
        ], {largeurDefaut: 33.33})
    };
}
