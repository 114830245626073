import { IComponentController } from 'angular';
import { IRoute } from '../../../../../core/interfaces/route.interface';
import { IFormulaireItemDividerClass } from '../../../../../core/services/formulaire/formulaire-item-divider.service';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaire, IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';
import IStateService = angular.ui.IStateService;
import { IOperateurService } from "../../../../../core/services/operateur.service";
import { IMenuItemActionImportationClass } from "../../../../../core/services/menu/menu-item-action-importation.service";
import { IMenuItemActionClass } from "../../../../../core/services/menu/menu-item-action.service";
import { IMenuClass } from '../../../../../core/services/menu/menu.service';

interface IPageRr2018 extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireFra: IFormulaire;
    frdMultiOccurrenceOptions: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Rr2018Controller(Formulaire: IFormulaireClass,
    rr0078Route: IRoute,
    $state: IStateService,
    Menu: IMenuClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    MenuItemAction: IMenuItemActionClass,
    MenuItemActionImportation: IMenuItemActionImportationClass,
    Operateur: IOperateurService) {
    const vm: IPageRr2018 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            afficherMenusDansEntete: true
        },
        menus: [
            new MenuItemActionImportation(
                'LBL_CHARGEMENT_TXT',
                'BOUCHA',
                () => ({}),
                {
                    formulaireConfirmationAction: {
                        lblTitre: 'LBL_CHARGEMENT_TXT',
                        largeurFormulaire: 15,
                        formulaire: new Formulaire([
                            new FormulaireItem('franumcha', {
                                largeur: 100,
                                default: (data: any) => data.monoOccurrence.franumcha
                            })
                        ])
                    },
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                    typesFichiers: ['.csv'],
                    iconButton: false
                }
            ),
            new MenuItemAction(
                'LBL_BTN_VAL',
                'BOUVAL',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    }
                }
            ),
            new MenuItemAction(
                'LBL_BTN_TRT',
                'BOUTRT',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-action',
                    successAction: () => {
                        vm.monoOccurrence.fetchData();
                    },
                }
            ),
            new MenuItemAction(
                'LBL_BTN_SUP',
                'BOUSUP',
                () => ({}),
                {
                    saveFirst: true,
                    iconButton: false,
                    class: 'md-raised ex-button-secondary',
                    successAction: (data: any) => {
                        $state.go(rr0078Route.NAME, {
                        });
                    }
                }
            )
        ]
    };

    vm.formulaireFra = new Formulaire(' ', [
        new FormulaireItem('franumcha'),
        new FormulaireItem('fraficsou'),
        new FormulaireItemDivider(''),
        new FormulaireItem('franbrlig')
    ]);

    vm.frdMultiOccurrenceOptions = {
        colonnesFixesGauche: [
            { nom: 'vaefrdtyp_dsc', largeur: 150 },
            { nom: 'eplnummat', largeur: 120 },
            { nom: 'eplnomprn', largeur: 190 },
            { nom: 'rcecod', largeur: 150 }
        ],
        colonnesVisibles: [
            { nom: 'rcedsc', largeur: 200 },
            { nom: 'ppacod', largeur: 150 },
            { nom: 'frdmntemp', largeur: 150 },
            { nom: 'frdmntemr', largeur: 100 },
            { nom: 'frdnbrper', largeur: 160 },
            { nom: 'frdmntemptot', largeur: 160 },
            { nom: 'frdmntemrtot', largeur: 160 }
        ],
        colonnesFixesDroite: [
            { nom: 'frdflgerr', largeur: 90 }
        ],
        colonnesCachees: ['frdmsgerr']
    };

}
