import {IComponentController} from 'angular';
import {IMonoOccurrence, IMonoOccurrenceOptions} from '../../../../../core/services/mono-occurrence.service';
import {IFormulaire, IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IEcranDetailsResourcesEntite} from "../../../../../core/resources/ecran-details.resource";

interface IPageGa2000 extends IComponentController {
    monoOccurrence: IMonoOccurrence,
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaireEau: IFormulaire;
    valeursEcran: any;
}

/* @ngInject */
export default function Ga2000Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass,
                                         ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IPageGa2000 = this;

    vm.$onInit = $onInit;
    vm.valeursEcran = ecranDetails.valeurs;

    function $onInit() {
        vm.formulaireEau = new Formulaire(' ', [
            new FormulaireItem('elgcleintref', { largeur: 20 }),
            new FormulaireItem('prucleint', { largeur: 40 }),
            new FormulaireItem('vaesysnatctb', { largeur: 20 }),
            new FormulaireItem('eauflgact', { largeur: 20, default: 1 })
        ]);
    }
}
