import { IComponentController } from 'angular';
import { IFormulaireItemClass } from '../../../../../core/services/formulaire/formulaire-item.service';
import { IFormulaireClass } from '../../../../../core/services/formulaire/formulaire.service';
import { IMonoOccurrenceOptions } from '../../../../../core/services/mono-occurrence.service';
import { IMultiOccurrenceOptions } from '../../../../../core/services/multi-occurrence.service';



interface IPageMr2022 extends IComponentController {
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrenceOptionsTdd: IMultiOccurrenceOptions;
    multiOccurrenceOptionsTvf: IMultiOccurrenceOptions;
}

/* @ngInject */
export default function Mr2022Controller(
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass
) {
    const vm: IPageMr2022 = this;

    vm.monoOccurrenceOptions = {
        fonctions: {
            boutonDupliquer: false
        },
        formulaire: new Formulaire('LBL_BLC_TDR', [
            new FormulaireItem('tdrcod'),
            new FormulaireItem('tdrdsc'),
            new FormulaireItem('vaetdrsaitst'),
            new FormulaireItem('tdrdscpar'),
            new FormulaireItem('tdrdsclng'),
        ], { largeurDefaut: 50 })
    }


    /* Type de donnees RCE detail */
    vm.multiOccurrenceOptionsTdd = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'tddnumpar', largeur: 90 },
            { nom: 'vaetddtyppar_dsc', largeur: 150 },
            { nom: 'tdddsc', largeur: 150 }
        ],
        formulaire: new Formulaire('LBL_BLC_TDD', [
            new FormulaireItem('tddnumpar'),
            new FormulaireItem('vaetddtyppar'),
            new FormulaireItem('tdddsc')
        ], { largeurDefaut: 50 })
    };

    /* Type de donnees RCE valeur fixe */
    vm.multiOccurrenceOptionsTvf = {
        fonctions: {
            boutonDupliquer: false
        },
        colonnesVisibles: [
            { nom: 'tvfval', largeur: 100 },
            { nom: 'tvfdsc', largeur: 200 }
        ],
        formulaire: new Formulaire('LBL_BLC_TVF', [
            new FormulaireItem('tvfval'),
            new FormulaireItem('tvfdsc')
        ], { largeurDefaut: 50 })
    };
}
