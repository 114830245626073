import {IMultiOccurrenceOptions} from '../../../../../core/services/multi-occurrence.service';
import {IFormulaireItemClass} from '../../../../../core/services/formulaire/formulaire-item.service';
import {IFormulaireClass} from '../../../../../core/services/formulaire/formulaire.service';

interface IPagePi0037 {
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}
/* @ngInject */
export default function Pi0037Controller(Formulaire: IFormulaireClass,
                                         FormulaireItem: IFormulaireItemClass) {
    const vm: IPagePi0037 = this;

    vm.multiOccurrenceOptions = {
        colonnesVisibles: [
            {nom: 'slucod', largeur: 89},
            {nom: 'sludsc', largeur: 250},
            {nom: 'cptcod', largeur: 95},
            {nom: 'cptdsc', largeur: 250},
            {nom: 'sluflgact', largeur: 62}
        ],
        formulaire: new Formulaire([
            new FormulaireItem('slucod', {required: true}),
            new FormulaireItem('sludsc', {required: true}),
            new FormulaireItem('cptcleint'),
            new FormulaireItem('sluflgact', {default: 1})
        ], {largeurDefaut: 25}),
        fonctions: {
            edition: true,
            nouveau: true,
            supprime: true,
            suiviModification: true
        }
    };
}
